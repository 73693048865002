<template>
  <iui-container-new type="table" theme="bullet">
    <i-row>
      <i-col-header>계약금액</i-col-header>
      <i-col rowspan="5">
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col width="60px">공급가액</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.poAmt" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.amtKor }} 원 정)</label>
            </i-col>
          </i-row>
          <i-row>
            <i-col>부가세</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.vatAmt" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.vatAmtKor }} 원 정)</label>
            </i-col>
          </i-row>
          <i-row>
            <i-col>합계</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.totAmt" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.totAmtKor }} 원 정)</label>
            </i-col>
          </i-row>
          <i-row>
            <i-col>노무비</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.labAmt" :enable="false" />
              <iui-checkbox-group :items="labItems" :checkedValues="[poInfo.labYn]" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col>&nbsp;</i-col>
            <i-col>
              <span class="fontWeightBold font8pt">
                [*건설산업기본법시행령` 제 84조 규정에 의한 노무비]
              </span>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
      <i-spacer />
      <i-col-header>대금의 지급</i-col-header>
      <i-col />
    </i-row>
    <i-row>
      <i-col />
      <i-spacer />
      <i-col rowspan="8" colspan="2">
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col width="60px">- 선급금</i-col>
            <i-col>
              <span>(1) 계약체결후</span>
              <iui-text type="number" width="30px" :value="poInfo.prepayDay1" :enable="false" />
              <span>일 이내에</span>
              <iui-text type="amount" width="100px" :value="poInfo.prepayAmt" :enable="false" />
              <pmis-file-list
                id="prepayFlNo"
                flNoCol="prepayFlNo"
                title="첨부파일"
                title-visible
                :modal="true"
                :toolbar-visible="true"
                :modalBtnEnable="true"
                :fileNumber="poInfo.prepayFlNo"
                :allow-add="poStatus.isPoSend"
                :save-auth="true"
                @fileModal_closed="onClose"
                @delete-complete="v => onDeleteCompleteFile('prepayFlNo', v)"
              />
            </i-col>
          </i-row>
          <i-row>
            <i-col>&nbsp;</i-col>
            <i-col>
              <span>(2) 발주자로부터 지급받은 날 또는 계약일로부터</span>
              <iui-text type="number" width="30px" :value="poInfo.prepayDay2" :enable="false" />
              <span>일 이내 그 내용과 비율에 따름</span>
            </i-col>
          </i-row>
          <i-row>
            <i-col>- 기성금</i-col>
            <i-col>
              <span>(1)</span>
              <iui-text type="number" width="30px" :value="poInfo.gisungMonth" :enable="false" />
              <span>월</span>
              <iui-text type="number" width="30px" :value="poInfo.gisungCount" :enable="false" />
              <span>회</span>
            </i-col>
          </i-row>
          <i-row>
            <i-col>&nbsp;</i-col>
            <i-col>
              <div>(2) 목적물 수령일로부터</div>
              <iui-text type="number" width="30px" :value="poInfo.gisungDay" :enable="false" />
              <div>일 이내</div>
            </i-col>
          </i-row>
          <i-row>
            <i-col>&nbsp;</i-col>
            <i-col>
              <div>(3) 지급방법 :</div>
              <iui-radio-group
                :p-code="$getConstants('PAY_COND_RMRK_CD').code"
                :exclude-items="$getConstants('PAY_COND_RMRK_CD_RESERVATION').code"
                :value="poInfo.payCashYn"
                :enable="false"
              />
            </i-col>
          </i-row>
          <i-row>
            <i-col>&nbsp;</i-col>
            <i-col>
              <template v-if="poStatus.isPayCash">
                <label>(현금</label>
                <iui-text type="rate" suffix="" width="60px" :value="poInfo.payCashRatio" :enable="false" />
                <label>%, 어음</label>
                <iui-text type="rate" suffix="" width="60px" :value="poInfo.payCheckRatio" :enable="false" />
                <label>%, 어음대체결제수단</label>
                <iui-text type="rate" suffix="" width="60px" :value="poInfo.payCheck2Ratio" :enable="false" />
                <label>%)</label>
              </template>
            </i-col>
          </i-row>
          <i-row>
            <i-col colspan="2">
              - 설계변경, 경제상황변동 등에 따른 대금조정 및 지급
            </i-col>
          </i-row>
          <i-row>
            <i-col>&nbsp;</i-col>
            <i-col>
              <div>(1) 발주자로부터 조정받은 날로부터</div>
              <iui-text type="number" width="30px" :value="poInfo.negoDay1" :enable="false" />
              <div>일 이내 그 내용과 비율에 따라 조정</div>
            </i-col>
          </i-row>
          <i-row>
            <i-col>&nbsp;</i-col>
            <i-col>
              <div>(2) 발주자로부터 지급받은 날로부터</div>
              <iui-text type="number" width="30px" :value="poInfo.negoDay2" :enable="false" />
              <div>일 이내 지급</div>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
    <i-row>
      <i-col />
      <i-spacer />
    </i-row>
    <i-row>
      <i-col />
      <i-spacer />
    </i-row>
    <i-row>
      <i-col />
      <i-spacer />
    </i-row>
    <i-row>
      <i-col-header required>과세유형</i-col-header>
      <i-col>
        <iui-radio-group :p-code="$getConstants('TAX_TYPE_CD').code" :value="poInfo.taxTypecd" :enable="false" />
        <iui-select
          v-show="poStatus.isTaxDirect"
          width="55px"
          :p-code="$getConstants('TAX_DIRECT_DS').code"
          :value="poInfo.taxDirectDs"
          :enable="false"
        />
        <iui-text
          v-show="poStatus.isTaxDirect"
          type="rate"
          width="55px"
          :value="poInfo.taxTypeDirectPercent"
          :enable="false"
        />
        <iui-text
          v-show="poStatus.isTaxDirect"
          type="amount"
          width="90px"
          :value="poInfo.taxTypeDirectAmt"
          :enable="false"
        />
      </i-col>
      <i-spacer />
    </i-row>
    <i-row>
      <i-col-header required>인지세유무</i-col-header>
      <i-col>
        <iui-checkbox-group
          :checkedValues="[poInfo.stampTaxYn]"
          :items="[{label: $getConstants('EXTERNAL_Y').name, value: $getConstants('EXTERNAL_Y').code}]"
          :enable="false"
        />
        <template v-if="poStatus.isStamp">
          <iui-text class="mr10" type="amount" suffix="원" width="100px" :value="poInfo.stampTaxAmt" :enable="false" />
          <iui-text
            type="rate"
            prefix="당사부담"
            suffix="%"
            width="110px"
            :value="poInfo.stampTaxOwnRate"
            :enable="false"
          />
        </template>
      </i-col>
      <i-spacer />
    </i-row>
    <i-row>
      <i-col-header required>계약 보증금</i-col-header>
      <i-col>
        <span class="ml5">계약금액의</span>
        <iui-text type="rate" width="60px" :value="poInfo.prfmInsrRate" :enable="false" />
        <span>(</span>
        <iui-text type="amount" suffix="" width="100px" :value="poInfo.prfmInsrAmt" :enable="false" />
        <span>원)</span>
        <pmis-file-list
          id="prfmInsrFlNo"
          flNoCol="prfmInsrFlNo"
          title="첨부파일"
          title-visible
          :modal="true"
          :toolbar-visible="true"
          :modalBtnEnable="true"
          :fileNumber="poInfo.prfmInsrFlNo"
          :allow-add="poStatus.isPoSend"
          :save-auth="true"
          @fileModal_closed="onClose"
          @delete-complete="v => onDeleteCompleteFile('prfmInsrFlNo', v)"
        />
      </i-col>
      <i-spacer />
    </i-row>
    <i-row>
      <i-col-header required>지체상금율</i-col-header>
      <i-col>
        <span class="ml5">계약금액의</span>
        <iui-text type="rate" width="60px" :value="poInfo.delayRate" :enable="false" />
        <span>(</span>
        <iui-text type="amount" suffix="" width="100px" :value="poInfo.delayAmt" :enable="false" />
        <span>원)</span>
        <pmis-file-list
          id="delayFlNo"
          flNoCol="delayFlNo"
          title="첨부파일"
          title-visible
          :modal="true"
          :toolbar-visible="true"
          :modalBtnEnable="true"
          :fileNumber="poInfo.delayFlNo"
          :allow-add="poStatus.isPoSend"
          :save-auth="true"
          @fileModal_closed="onClose"
          @delete-complete="v => onDeleteCompleteFile('delayFlNo', v)"
        />
      </i-col>
      <i-spacer />
    </i-row>
    <i-row>
      <i-col rowspan="4" colspan="2">
        <iui-container-new type="table" theme="bullet" header-width="150px">
          <i-row>
            <i-col-header required>하도급대금 지급보증금</i-col-header>
            <i-col>
              <iui-checkbox-group :checkedValues="[poInfo.scpayInsrYn]" :items="scpayInsrItems1" :enable="false" />
              <template v-if="poStatus.isScpayInsrY">
                <span>(</span>
                <iui-text type="amount" suffix="" width="100px" :value="poInfo.scpayInsrAmt" :enable="false" />
                <span>원)</span>
              </template>
              <template v-if="poStatus.isScpayInsrN">
                <span>(</span>
                <iui-checkbox-group
                  :items="scpayInsrIncItems"
                  :checkedValues="[poInfo.scpayInsrIncYn]"
                  :enable="false"
                />
              </template>
              <pmis-file-list
                v-if="poStatus.isScpayInsrY"
                id="scpayFlNo"
                flNoCol="scpayFlNo"
                title="첨부파일"
                title-visible
                :modal="true"
                :allow-add="true"
                :save-auth="true"
                :toolbar-visible="true"
                :modal-btn-enable="true"
                :file-number="poInfo.scpayFlNo"
                @fileModal_closed="onClose"
                @delete-complete="v => onDeleteCompleteFile('scpayFlNo', v)"
              />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header required>하자보수보증금</i-col-header>
            <i-col>
              <span class="ml5">계약금액의</span>
              <iui-text type="rate" width="60px" :value="poInfo.flawInsrRate" :enable="false" />
              <span>(</span>
              <iui-text type="amount" suffix="" width="100px" :value="poInfo.flawInsrAmt" :enable="false" />
              <span>원)</span>
            </i-col>
          </i-row>
          <i-row>
            <i-col-header required>하자담보책임기간</i-col-header>
            <i-col>
              <iui-radio-group :items="flawInsrItems1" :value="poInfo.flawInsrYn" :enable="false" />
              <iui-text
                v-if="poStatus.isFlawInsrYear"
                type="number"
                align="center"
                width="30px"
                :value="poInfo.flawInsrYear"
                :enable="false"
              />
              <iui-radio-group :items="flawInsrItems2" :value="poInfo.flawInsrYn" :enable="false" />
              <template v-if="poStatus.isFlawInsrMonth">
                <label>담보기간</label>
                <iui-datepicker :value="poInfo.flawInsrFrDt" :enable="false" />
                <span>~</span>
                <iui-datepicker :value="poInfo.flawInsrToDt" :enable="false" />
              </template>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
      <i-spacer />
    </i-row>
    <i-row>
      <i-spacer />
      <i-col-header>특약사항</i-col-header>
      <i-col rowspan="3">
        <iui-text type="multi" :value="poInfo.poRmrk" :enable="false" />
      </i-col>
    </i-row>
    <i-row>
      <i-spacer />
      <i-col />
    </i-row>
    <i-row>
      <i-spacer />
      <i-col />
    </i-row>
  </iui-container-new>
</template>

<script>
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import {saveInsrFile} from '@/view/bidding/po/sub/api/subPo';

export default {
  data() {
    return {
      labItems: [{label: $getConstants('LAB_ITEM1').name, value: $getConstants('LAB_ITEM1').code}],
      stampTaxItems: [
        {label: '없음', value: BIDDING_CONSTANTS.CODES.STAMP_TAX_N},
        {label: '있음', value: BIDDING_CONSTANTS.CODES.STAMP_TAX_Y},
      ],

      scpayInsrItems1: [{label: '해당', value: BIDDING_CONSTANTS.CODES.SCPAY_INSR_Y}],
      scpayInsrItems2: [{label: '해당없음', value: BIDDING_CONSTANTS.CODES.SCPAY_INSR_N}],
      scpayInsrIncItems: [
        {label: $getConstants('SCPAY_INSR_INC_ITEM1').name, value: $getConstants('SCPAY_INSR_INC_ITEM1').code},
      ],

      flawInsrItems1: [{label: '년', value: BIDDING_CONSTANTS.CODES.FLAW_INSR_YEAR}],
      flawInsrItems2: [{label: '월', value: BIDDING_CONSTANTS.CODES.FLAW_INSR_MONTH}],
    };
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'poStatus']);
  },
  methods: {
    onClose() {
      saveInsrFile({
        poNo: this.poInfo.poNo,
        contSeq: this.poInfo.contSeq,
        delayFlNo: this.poInfo.delayFlNo,
        prfmInsrFlNo: this.poInfo.prfmInsrFlNo,
        scpayFlNo: this.poInfo.scpayFlNo,
        prepayFlNo: this.poInfo.prepayFlNo,
      });
    },
    onDeleteCompleteFile(key, flNo) {
      const obj = {
        poNo: this.poInfo.poNo,
        contSeq: this.poInfo.contSeq,
      };
      obj[key] = flNo;
      saveInsrFile(obj);
    },
  },
};
</script>

<style></style>
