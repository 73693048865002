<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text type="search" name="cmnUNm" label="코드명" :value.sync="searchInfo.cmnUNm" @enter="onSearch"></iui-text>
      <iui-button value="검색" class="btn" @click="onSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onRenderFirstFinish,
        onDataLoad: this.grid_onDataLoad,
        onClick: this.grid_onClick,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/UpperCodeSheet.js';
import {selectUpperCodeList} from '@/view/sysManage/stdCodeManage/cmmnCodeRegist/api/cmmnCodeRegist.js';
export default {
  data() {
    return {
      Options: options(this),
      loadSearchData: [],
      searchInfo: {
        cmnUNm: '',
      },
    };
  },
  created() {
    this.addFuncSearch(this.onSearch);
  },
  methods: {
    async onSearch() {
      const response = await selectUpperCodeList({...this.searchInfo, pgmCd: this.pgmCd});
      if (response.status == 200) this.loadSearchData = response.data;
    },
    grid_onDataLoad() {
      this.onEmitUpperCode(this.sheet.getFirstRow());
    },
    grid_onClick(e) {
      if (e.row.Kind == 'Data') {
        this.onEmitUpperCode(e.row);
      }
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    onEmitUpperCode(row) {
      this.$emit('emitUpperCode', row);
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped></style>
