export default that => {
  return {
    Cfg: {
      CanEdit: 1,
      HeaderMerge: 4,
      IgnoreFocused: !that.autoFocus,
    },
    Cols: [
      {
        Header: '현장코드',
        Name: 'prjCd',
        Type: 'Text',
        Width: 70,
        CanEdit: 0,
        Align: 'center',
      },
      {
        Header: '최종차수',
        Name: 'ctrtChgTo',
        Width: 70,
        CanEdit: 0,
        Align: 'center',
        Visible: 0,
      },
      {
        Header: '현장명',
        Name: 'prjNm',
        Type: 'Text',
        CanEdit: 0,
        RelWidth: 1,
        MaxWidth: 300,
        AlignFormula: function(param) {
          if (!param.Row['prjCd']) return 'Center';
        },
      },
      {
        Header: '종료여부',
        Name: 'conCmplYn',
        Width: 70,
        CanEdit: 0,
        Align: 'center',
      },
    ],
  };
};
