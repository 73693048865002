export default that => {
  return {
    Cfg: {
      SearchMode: 2,
    },
    Cols: [
      {
        Header: '아이디',
        Name: 'empNo',
        Type: 'Text',
        Align: 'Center',
        MinWidth: 150,
        RelWidth: 1,
        Visible: 1,
        CanEdit: 0,
      },
      {
        Header: '성명',
        Name: 'name',
        Type: 'Text',
        Align: 'Center',
        MinWidth: 150,
        RelWidth: 1,
        Visible: 1,
        CanEdit: 0,
      },
      {
        Header: '전화번호',
        Name: 'mobileTlno',
        Type: 'Text',
        Align: 'Center',
        MinWidth: 150,
        RelWidth: 1,
        Visible: 1,
        CanEdit: 0,
        CustomFormat: value => value.toString().replace(/(^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/g, '$1-$2-$3'),
      },
      {Name: 'pgmCd', Visible: false},
    ],
  };
};
