<template>
  <pmis-tab-box>
    <template v-slot:title>노무팀목록</template>
    <pmis-content-box>
      <template v-slot:header-left>
        <iui-text type="search" label="노무팀명" name="searchLbrcTeam" :value.sync="searchLbrcTeam" @enter="onSearch" />
        <iui-button value="검색" @click="onSearch" />
        <iui-checkbox-group
          name="inclsDelTeam"
          :items="checkboxItems"
          @change="
            event => {
              inclsDelTeam = event.target.checked ? 'Y' : 'N';
              onSearch();
            }
          "
        />
      </template>
      <ib-sheet
        :uid="_uid"
        :options="Options"
        :loadSearchData="loadSearchData"
        :events="{
          onRowLoad,
          onSearchFinish,
          onClick,
          onDblClick,
        }"
        @loadSheet="loadSheet"
      />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
import options from './sheetOption/lbrcTeamListSheet.js';
import {selectLbrcTeam} from '../api/lbrcTeamRegist.js';
export default {
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    uid: {
      type: Number,
    },
  },
  data() {
    return {
      Options: options(this),
      loadSearchData: [],
      checkboxItems: [{value: false, label: '종료팀포함'}],
      inclsDelTeam: '',
      searchLbrcTeam: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, ['focusKey']);
    $mapMutations(this, ['setLbrcTeamInfo', 'initLbrcTeamInfo', 'initFocusKey']);
  },
  created() {
    this.addEvent([{name: 'LbrcTeamList_onSearch', func: this.onSearch}]);
    this.addEvent([{name: 'onFocusedLbrcRowData', func: this.onFocusedRowData}]);
  },
  mounted() {
    this.onSearch();
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.setLbrcTeamInfo(e.row); //row의 데이터를 기본정보란에 바인딩
        this.callEvent({name: 'teamMemberStatus_onSearch'});
      }
    },
    onDblClick(e) {
      if (this.isPopup && e.row.Kind == 'Data') {
        this.callEvent({name: 'onHideModal_' + this.uid});
      }
    },
    onRowLoad(e) {
      if (e.row.Kind === 'Data' && e.row.delYn == $getConstants('DEL_YN_Y').code) {
        e.row['TextColor'] = '#FF0000';
      }
    },
    onSearchFinish(e) {
      let focusRow = e.sheet.getFirstRow();
      if (this.focusKey) {
        focusRow = e.sheet.getDataRows().find(row => row.labCode == this.focusKey);
        this.initFocusKey();
      }
      e.sheet.focus(focusRow);

      this.setLbrcTeamInfo(focusRow); //row의 데이터를 기본정보란에 바인딩
      this.callEvent({name: 'teamMemberStatus_onSearch'});
    },
    async onSearch() {
      let param = {pgmCd: this.pgmCd, labName: this.searchLbrcTeam};
      let response = await selectLbrcTeam(param);
      if (this.inclsDelTeam !== 'Y') {
        response.data = response.data.filter(el => el.delYn != $getConstants('DEL_YN_Y').code);
      }
      this.loadSearchData = response.data;
      if (!this.sheet.getTotalRowCount()) this.initLbrcTeamInfo();
    },
    onFocusedRowData(param) {
      //팝업창 확인버튼
      let row = this.getFocusedRow();
      if (typeof param == 'function') {
        param(row);
      }
    },
  },
};
</script>

<style></style>
