import {submitQuot} from '@/view/bidding/rfqCustomer/mat/api/rfqCustomer.js';
import {submitQuot as subSumbitQuot} from '@/view/bidding/rfqCustomer/sub/api/subRfqCustomer.js';
import {getServerKmCertPerm} from '@/view/bidding/rfq/common/api/pkiToolkit.js';

export default {
  props: {
    propsRfqNo: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      quotAmt: 0,
      quotVatAmt: 0,
      quotTotAmt: 0,
      quotRmrk: '',

      certNo: '',
      svrKmCertPerm: '',
      signedData: '',
      userDN: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, ['rfqInfo', 'rfqItemList', 'rfqStatus', 'quotInfo']);
  },
  async mounted() {
    // 한국정보인증 툴킷
    await this.$secuKit.checkReady();

    // pki 서버인증서 정보
    const response = await getServerKmCertPerm();
    this.certNo = response.data.certNo;
    this.svrKmCertPerm = response.data.svrKmCertPerm;

    if (this.rfqStatus.isTaxDirect && this.rfqStatus.isTaxDirectAmt) {
      this.quotVatAmt = this.rfqInfo.vatAmt;
    }
    if (this.rfqStatus.isTaxExemption) {
      this.quotVatAmt = 0;
    }
  },
  methods: {
    sheet_onBeforeChange(e) {
      const unitPrcCols = ['planUnitPrc', 'matUprc', 'labUprc', 'equipUprc', 'costUprc'];

      if (unitPrcCols.includes(e.col)) {
        if (Number.isInteger(Number(e.val))) {
          return null;
        } else {
          this.$alert({title: '견적금액', message: '견적금액은 정수만 입력가능합니다.'});
          return 0;
        }
      }
    },
    sheet_onAfterChange(e) {
      this.onCalcAmt(e); // 공급가액, 부가세액, 합계금액 계산
    },
    onCalcAmt(e) {
      // 공급가액, 부가세액, 합계금액 계산
      const sumCol = {mat: 'amt', sub: 'sumAmt'};
      this.quotAmt = e.sheet
        .getDataRows()
        .reduce((accumulator, currentValue) => accumulator + currentValue[sumCol[this.bidType]], 0);

      if (this.rfqStatus.isTax || (this.rfqStatus.isTaxDirect && this.rfqStatus.isTaxDirectPercent)) {
        this.quotVatAmt = (this.quotAmt * this.rfqInfo.taxRate) / 100;
      }
      this.quotTotAmt = this.quotAmt + this.quotVatAmt;
    },
    async onSign(callback) {
      try {
        // 1-1. 인증서 선택창
        await this.$secuKit.showDialog();

        // 1-2. 인증서 정보 추출
        let res = await this.$secuKit.viewCertInfomationWithVID({
          certType: 'SignCert',
          certID: this.$secuKit.certListInfo.getCertID(),
          isViewVID: '1',
        });
        this.userDN = res.userDN;

        // 1-3. 신원확인 검증
        let userDN = String(res.userDN);
        let certdn = String(JSON.parse(sessionStorage.getItem('userInfo')).certdn);

        userDN = userDN.replace(/\s/gi, '').toUpperCase();
        certdn = certdn.replace(/\s/gi, '').toUpperCase();

        if (userDN != certdn) {
          this.$alert({
            title: '인증서 검증',
            message: '시스템에 등록된 인증서와 선택하신 인증서가 다릅니다.<br/> 다시 한 번 확인하시기 바랍니다.',
          });
          return;
        }

        // 1-4. 인증서 유효기간
        let date1 = new Date(res.detailValidateTo).getTime();
        let date2 = new Date().getTime();
        if (date1 < date2) {
          this.$alert({
            title: '인증서 검증',
            message: '인증서의 유효기간이 만료되었습니다.<br/> 인증서를 확인하시기 바랍니다.',
          });
          return;
        }

        // 1-5. 견적정보 전자서명
        res = await this.$secuKit.generatePKCS7SignedData({
          certType: 'SignCert',
          sourceString: this.makeQuotData(),
          certID: this.$secuKit.certListInfo.getCertID(),
        });

        // 1-6. 견적정보 암호화
        res = await this.$secuKit.generatePKCS7EnvelopedData({
          targetCert: removePEMHeader(removeCRLF(this.svrKmCertPerm)),
          sourceString: res.generatePKCS7SignedData,
        });

        // 1-7. 견적정보 전자서명
        res = await this.$secuKit.generatePKCS7SignedData({
          certType: 'SignCert',
          sourceString: res.generatePKCS7EnvelopedData,
          certID: this.$secuKit.certListInfo.getCertID(),
        });
        this.signedData = res.generatePKCS7SignedData;

        this.onSubmit(callback);
      } catch (e) {
        console.error(e);
        if (typeof e !== 'string') {
          this.$alert({
            title: '견적서 제출',
            message: `견적정보 전자서명을 실패했습니다. [${e.ERROR_CODE}]<br/>- ${e.ERROR_MESSAGE}`,
          });
        }
      }
    },
    onSubmit(callback) {
      // 견적서 제출
      const param = {
        rfqNo: this.rfqInfo.rfqNo,
        cusCd: this.userInfo.bsnNo,
        signedData: this.signedData,
        certNo: this.certNo,
        userDN: this.userDN,
        etcFlNo: this.quotInfo.etcFlNo,
      };

      const submitQuotApi = {mat: submitQuot, sub: subSumbitQuot};
      submitQuotApi[this.bidType](param)
        .then(async response => {
          if (response.status == 200) {
            const title = '견적서 제출';
            const message = '정상적으로 견적서가 제출되었습니다.<br/>제출한 견적은 개봉전까지 보안이 유지됩니다.';

            await this.$alert({title, message});
            callback(response.data.rfqNo);
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            const title = ex.title;
            const message = ex.description ? `${ex.message}<br/>- ${ex.description}` : ex.message;
            this.$alert({title, message});
          }
        });
    },
  },
};
