export default that => {
  return {
    Cfg: {
      CanEdit: false,
    },
    Cols: [
      {
        Header: '현장명',
        Name: 'prjNm',
        Type: 'Text',
        Visible: false,
      },
      {
        Header: 'ID',
        Name: 'empNo',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 100,
      },
      {
        Header: '성명',
        Name: 'name',
        Type: '',
        RelWidth: 1,
        MinWidth: 100,
        Align: 'Center',
      },
      {
        Header: '주민번호',
        Name: 'regiNo',
        Type: 'Text',
        Align: 'Center',
        RelWidth: 1,
        MinWidth: 100,
        CustomFormat: '######-#######',
      },
    ],
  };
};
