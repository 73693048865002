export default that => {
  return {
    Cfg: {
      CanEdit: 0,
      DataMerge: 1,
    },
    Cols: [
      {
        Header: ['NO'],
        Name: 'rownum',
        Type: 'Text',
        Align: 'center',
        Width: '50',
      },
      {
        Header: ['국적코드'],
        Name: 'nationNm',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['주민번호/여권번호'],
        Name: 'regiOrPassportNo',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['아이디'],
        Name: 'empNo',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['성명'],
        Name: 'name',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['소속구분'],
        Name: 'blnDepPartNm',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['소속코드'],
        Name: 'blnDepNoNm',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['근로자구분'],
        Name: 'laborDsNm',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['직책'],
        Name: 'dtsNm',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['직급'],
        Name: 'rollNm',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['담당업무'],
        Name: 'wbusinessNm',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['전화번호'],
        Name: 'tlno',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['휴대폰번호'],
        Name: 'mobileTlno',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['SMS 수신동의'],
        Name: 'useSms',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['팩스번호'],
        Name: 'rpsvFaxnoa',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['이메일'],
        Name: 'email',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['거래은행'],
        Name: 'bankNm',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['계좌번호'],
        Name: 'depositNo',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['예금주'],
        Name: 'depositor',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['체류자격'],
        Name: 'visaKindNm',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['체류기간'],
        Name: 'stayDuration',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['입국일자'],
        Name: 'entryDate',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['장애유형'],
        Name: 'handicapKindNm',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['장애등급'],
        Name: 'handicapGrade',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['장애판정일'],
        Name: 'handicapDate',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['시스템 사용여부'],
        Name: 'useDs',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['노무단가'],
        Name: 'priceLAmt',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['노무직종'],
        Name: 'jkdNm',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['국민연금제외'],
        Name: 'pensionDs',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['건강보험제외'],
        Name: 'healthInsurDs',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['장기요양 30% 경감대상'],
        Name: 'healthInsurMDs',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
      {
        Header: ['고용보험제외'],
        Name: 'employInsurDs',
        Type: 'Text',
        Align: 'center',
        ColMerge: 0,
      },
    ],
  };
};
