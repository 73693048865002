export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Def: {Row: {NoColor: 2}},
    Cols: [
      {
        Header: '명칭',
        Name: 'itemNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 220,
        CanEdit: 0,
        TextStyle: 5,
        TextColor: '#FF0000',
        Cursor: 'Pointer',
      },
      {Header: '규격', Name: 'sizeNm', Type: 'Text', RelWidth: 1, MinWidth: 220, CanEdit: 0},
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        Width: 80,
        Format: $getConstants('UNIT').code,
        CanEdit: 0,
      },
      {Header: '수량', Name: 'qty', Width: 100, Extend: preset.quantity, CanEdit: 0},
      {Header: '단가', Name: 'planUnitPrc', Width: 100, Extend: preset.unitAmount, Color: '#E0E0F8'},
      {
        Header: '금액',
        Name: 'amt',
        Width: 100,
        Extend: preset.amount,
        Formula: fr => fr.Row.qty * fr.Row.planUnitPrc,
        CanEdit: 0,
      },
      {Name: 'rfqSeq', Visible: 0},
      {Name: 'itemCd', Visible: 0},
      {Name: 'unit', Visible: 0},
      {Name: 'itemRmrk', Visible: 0},
    ],
  };
};
