<template>
  <pmis-page ref="page" :search-project-visible="false" @search-click="onSearch">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-content-box>
            <pmis-tab-box>
              <template v-slot:title>
                노임대장
              </template>
              <template v-slot:title-right>
                <iui-button value="지급명세서 출력" @click="onReportViewer" />
              </template>
              <pmis-content-box>
                <template v-slot:header-left>
                  <div>출역년월</div>
                  <iui-datepicker
                    type="month"
                    name="investMm"
                    :value="searchInfo.investMm"
                    :isIconClear="false"
                    @change="searchInfo.investMm = $event"
                  />
                  <i class="prev-arrow" @click="onClickDate('PREV')" />
                  <i class="post-arrow" @click="onClickDate('POST')" />
                  <div>성명</div>
                  <iui-text
                    type="search"
                    :value="searchInfo.searchNm"
                    @change="searchInfo.searchNm = $event.target.value"
                    @enter="onSearch()"
                  />
                  <iui-button value="검색" @click="onSearch" />
                </template>
                <ib-sheet
                  :uid="_uid"
                  :options="Options"
                  :loadSearchData="loadSearchData"
                  :events="{
                    onSearchFinish,
                    onRenderFirstFinish,
                  }"
                  @loadSheet="loadSheet"
                />
              </pmis-content-box>
            </pmis-tab-box>
          </pmis-content-box>
          <iui-modal name="ReportViewerModal" title="지급명세서 출력" size-type="size6">
            <report-viewer :fomNo="fomNo" :ozContent="ozContent" />
          </iui-modal>
        </i-col>
      </i-row>
    </iui-container-new>
    <iui-modal name="PayrollViewerModal" title="급여 지급 명세서" size-type="size6">
      <report-viewer :fomNo="fomNo" :ozContent="ozContent" />
    </iui-modal>
  </pmis-page>
</template>

<script>
import ReportViewer from '@/view/bidding/components/ReportViewer.vue';
import PrjList from '@/view/Project/components/PrjList.vue';
import options from './components/sheetOption/WagesLedger.js';
import {selectLastInvestMm, selectList, selectPayStateList} from './api/WagesLeder.js';
import FOM_NO from '@/constants/fomNoConstants.js';
import moment from 'moment';

export default {
  components: {
    PrjList,
    ReportViewer,
  },
  data() {
    return {
      loadSearchData: [],
      Options: options(this),
      searchInfo: {
        investMm: '', //  출역년월
        searchSe: 'name',
        searchNm: '',
      },
      fomNo: 0,
      ozContent: {},
    };
  },
  created() {
    this.addFuncPrj(this.onSearchLastCloseMM);
    this.addFuncPrint(this.callReport);
  },
  methods: {
    onSearchFinish(e) {
      let count = e.sheet.getTotalRowCount();

      for (var i = 0; i < count; i = i + 2) {
        let row1 = e.sheet.getRowByIndex(i + 1);
        let row2 = e.sheet.getRowByIndex(i + 2);

        // 병합 설정
        e.sheet.setMergeRange(row1, 'empNo', row2, 'empNo');
        e.sheet.setMergeRange(row1, 'deductSum', row2, 'deductSum');
        e.sheet.setMergeRange(row1, 'actPayAmt', row2, 'actPayAmt');
        e.sheet.setMergeRange(row1, 'blnDepNm', row2, 'blnDepNm');
        e.sheet.setMergeRange(row2, 'name', row2, 'jkdNm');

        // 정렬 설정
        e.sheet.setAttribute(row2, 'name', 'Align', 'left', 1);
      }
    },
    onRenderFirstFinish() {
      this.sheet.setAutoMerge(null, 6);
    },
    async onSearchLastCloseMM() {
      const response = await selectLastInvestMm(this.searchInfo);
      this.searchInfo.investMm = response.data.investMm ? response.data.investMm : $_getYearMonth();
      this.onSearch();
    },
    async onSearch() {
      if (this.searchInfo.investMm == '') {
        this.$alert({title: '출역년월', message: '출역년월을 선택해 주세요.'});
        return;
      }

      const response = await selectList(this.searchInfo);
      this.loadSearchData = response.data;
    },
    async onClickDate(searchDateType) {
      let param = {
        ...this.searchInfo,
        searchDateType,
      };
      const response = await selectLastInvestMm(param);
      if (response.data.investMm) {
        this.searchInfo.investMm = response.data.investMm;
        this.onSearch();
      } else {
        this.$alert({
          title: '노임대장',
          message: `${searchDateType == 'PREV' ? '이전' : '다음'} 노임대장이 없습니다.`,
        });
      }
    },
    async onReportViewer() {
      if (this.searchInfo.investMm == '') {
        this.$alert({title: '출역년월', message: '출역년월을 선택해 주세요.'});
        return;
      }
      let response = await selectPayStateList(this.searchInfo);
      const data = response.data;
      for (let key in data) {
        data[key].monthlyDay = $_numberFormat(data[key].monthlyDay, true, null, null);
        data[key].actPayAmt = $_numberFormat(data[key].actPayAmt, true, null, null);
        data[key].incometax = $_numberFormat(data[key].incometax, true, null, null);
        data[key].resitax = $_numberFormat(data[key].resitax, true, null, null);
      }
      let laborList = JSON.stringify({laborList: data});

      this.fomNo = FOM_NO.PAYMENT_STATEMENT;
      this.ozContent = {
        laborList: laborList,
      };

      this.$modal.show('ReportViewerModal');
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    callReport() {
      if (this.loadSearchData.length > 0) {
        this.fomNo = FOM_NO.PAYROLL;

        const PrjInfo = {
          title: `${moment(this.searchInfo.investMm).format('M월')} 지급명세서`,
          prjNm: `현장명 : ${this.getPrjInfo().prjNm}`,
        };

        const Summary = {
          monthlyDay: '0',
          priceLAmt: '0',
          monthlyAmt: '0',
          incometax: '0',
          resitax: '0',
          pensionAmt: '0',
          healthInsurAmt: '0',
          employInsurAmt: '0',
          careAmt: '0',
          deductSum: '0',
          actPayAmt: '0',
        };

        const LaborList = [];
        for (let i = 0; i < this.loadSearchData.length / 2; i++) {
          const prev = this.loadSearchData[i * 2];
          const next = this.loadSearchData[i * 2 + 1];

          const obj = {
            empNo: prev.empNo,
            name: prev.name,
            regiNo: prev.regiNo,
            jkdNm: prev.jkdNm,
            adr1: prev.adr1,
            monthlyDay: String(prev.monthlyDay),
            priceLAmt: !prev.priceLAmt ? '0' : $_numberFormat(prev.priceLAmt, true),
            monthlyAmt: !next.priceLAmt ? '0' : $_numberFormat(next.priceLAmt, true),
            incometax: !prev.incometax ? '0' : $_numberFormat(prev.incometax, true),
            resitax: !next.incometax ? '0' : $_numberFormat(next.incometax, true),
            pensionAmt: !prev.pensionAmt ? '0' : $_numberFormat(prev.pensionAmt, true),
            healthInsurAmt: !next.pensionAmt ? '0' : $_numberFormat(next.pensionAmt, true),
            employInsurAmt: !prev.employInsurAmt ? '0' : $_numberFormat(prev.employInsurAmt, true),
            careAmt: !next.careAmt ? '0' : $_numberFormat(next.careAmt, true),
            deductSum: !prev.deductSum ? '0' : $_numberFormat(prev.deductSum, true),
            actPayAmt: !prev.actPayAmt ? '0' : $_numberFormat(prev.actPayAmt, true),
            blnDepNm: prev.blnDepNm,
          };

          Summary.monthlyDay = Number(Summary.monthlyDay) + Number($_numberFormat(obj.monthlyDay, false));
          Summary.priceLAmt = Number(Summary.priceLAmt) + Number($_numberFormat(obj.priceLAmt, false));
          Summary.monthlyAmt = Number(Summary.monthlyAmt) + Number($_numberFormat(obj.monthlyAmt, false));
          Summary.incometax = Number(Summary.incometax) + Number($_numberFormat(obj.incometax, false));
          Summary.resitax = Number(Summary.resitax) + Number($_numberFormat(obj.resitax, false));
          Summary.pensionAmt = Number(Summary.pensionAmt) + Number($_numberFormat(obj.pensionAmt, false));
          Summary.healthInsurAmt = Number(Summary.healthInsurAmt) + Number($_numberFormat(obj.healthInsurAmt, false));
          Summary.employInsurAmt = Number(Summary.employInsurAmt) + Number($_numberFormat(obj.employInsurAmt, false));
          Summary.careAmt = Number(Summary.careAmt) + Number($_numberFormat(obj.careAmt, false));
          Summary.deductSum = Number(Summary.deductSum) + Number($_numberFormat(obj.deductSum, false));
          Summary.actPayAmt = Number(Summary.actPayAmt) + Number($_numberFormat(obj.actPayAmt, false));

          for (let i = 1; i <= 31; i++) {
            const value = i < 16 ? prev[`d${i}`] : next[`d${i - 15}`];
            obj[`d${i}`] = !value ? '' : Number(value).toFixed(1);
          }
          LaborList.push(obj);
        }

        Summary.monthlyDay = $_numberFormat(Summary.monthlyDay, true);
        Summary.priceLAmt = $_numberFormat(Summary.priceLAmt, true);
        Summary.monthlyAmt = $_numberFormat(Summary.monthlyAmt, true);
        Summary.incometax = $_numberFormat(Summary.incometax, true);
        Summary.resitax = $_numberFormat(Summary.resitax, true);
        Summary.pensionAmt = $_numberFormat(Summary.pensionAmt, true);
        Summary.healthInsurAmt = $_numberFormat(Summary.healthInsurAmt, true);
        Summary.employInsurAmt = $_numberFormat(Summary.employInsurAmt, true);
        Summary.careAmt = $_numberFormat(Summary.careAmt, true);
        Summary.deductSum = $_numberFormat(Summary.deductSum, true);
        Summary.actPayAmt = $_numberFormat(Summary.actPayAmt, true);

        this.ozContent = {
          PrjInfo: JSON.stringify({PrjInfo}),
          Summary: JSON.stringify({Summary}),
          LaborList: JSON.stringify({LaborList}),
        };
        this.$modal.show('PayrollViewerModal');
      }
    },
  },
};
</script>
