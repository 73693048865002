<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-select
        :p-code="$getConstants('FRM_GB').code"
        :exclude-items="`${$getConstants('FRM_GB_0').code},${$getConstants('FRM_GB_4').code}`"
        :value.sync="searchFrmGb"
        defaultCd="A"
        @change="selectCompanyNotConfirmList"
      />
      <iui-text type="search" :value.sync="searchNm" @enter="selectCompanyNotConfirmList" />
      <iui-button value="검색" @click="selectCompanyNotConfirmList" />
      <iui-checkbox-group
        name="closeAt"
        width="120px"
        :items="[{value: true, label: '종료포함'}]"
        @change="closeAt = $event.target.checked"
        v-if="!isApproval"
      />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onRenderFirstFinish,
        onSearchFinish,
        onFocus,
      }"
    />
  </pmis-content-box>
</template>

<script>
import {mapGetters} from 'vuex';
import options from './sheetOption/CompanyListSheet.js';
import {selectCompanyNotConfirmList, selectCompanyList} from '../api/member.js';
export default {
  props: {
    callbackFocus: {
      type: Function,
      default: () => {},
    },
    isApproval: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Options: this.isApproval ? $addColCheckbox(options(this)) : options(this),
      loadSearchData: [],
      searchFrmGb: '',
      searchSe: '',
      searchNm: '',
      searchSeItems: [
        {text: '사업자번호', value: 'bsnNo'},
        {text: '업체명', value: 'frmNm'},
        {text: '대표자명', value: 'rpsvNm'},
      ],
      closeAt: false,
    };
  },

  computed: {
    ...mapGetters(['externalData']),
  },

  watch: {
    searchInfo: {
      handler() {
        if (!this.isApproval) {
          this.callbackFocus();
        } else {
          this.callEvent({name: 'JoinInfo_selectCompanyInfo'});
        }
      },
      deep: true,
    },
    closeAt() {
      this.selectCompanyNotConfirmList();
    },
  },

  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'searchInfo']);
    $mapMutations(this, ['setSearchInfo']);
  },

  created() {
    this.addEvent([
      {name: 'companySelectCompanyNotConfirmList', func: this.selectCompanyNotConfirmList},
      {name: 'companyListGetRowsByChecked', func: this.grid_getRowsByChecked},
    ]);
  },
  activated() {
    if (this.sheet) {
      this.onSearchFinish();
    }
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
    onRenderFirstFinish() {
      this.selectCompanyNotConfirmList();
    },
    async selectCompanyNotConfirmList() {
      //검색
      let param = {
        frmGb: this.searchFrmGb,
        searchSe: this.searchSe,
        searchNm: this.searchNm,
        closeAt: this.closeAt,
      };
      const response = this.isApproval ? await selectCompanyNotConfirmList(param) : await selectCompanyList(param);
      if (response.status == 200) {
        if (!this.isApproval) {
          response.data.forEach(item => {
            item.closeAt = item.closeAt ? 'Y' : '';
          });
        }
        this.loadSearchData = response.data;
      }
    },
    onFocus(e) {
      if (e.row.Kind == 'Data') {
        this.$store.commit('setPgmCd', e.row.pgmCd);
        this.$recompute('pgmCd');
        this.setSearchInfo({pgmCd: e.row.pgmCd, frmGb: e.row.frmGb});
      }
    },
    onSearchFinish() {
      this.callEvent({name: 'companyRegistOnDetailLoading', param: false});
      if (this.externalData.pgmCd) {
        let focusPgmCd = this.externalData.pgmCd;
        let focusRow = this.sheet.getDataRows().find(row => row.pgmCd == focusPgmCd);
        setTimeout(() => {
          this.sheet.focus(focusRow);
          this.$store.dispatch('setExternalData', {});
        }, 10);
      }
    },
    grid_getRowsByChecked(callback) {
      if (typeof callback !== 'function') {
        return;
      }
      const rows = this.sheet.getRowsByChecked('chk');
      callback(rows.length ? rows : [this.sheet.getFocusedRow()]);
    },
  },
  beforeDestroy() {
    this.sheet.dispose();
  },
};
</script>

<style scoped></style>
