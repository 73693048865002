<template>
  <pmis-page
    ref="page"
    :search-company-visible="true"
    :search-project-visible="false"
    @search-company-changed="search_company_changed"
    @search-click="search_click"
    :search-company-code="searchData.pgmCd"
    :search-company-name="searchData.frmNm"
  >
    <list
      title="문서관리"
      :pgmCd="searchData.pgmCd"
      :frmNm="searchData.frmNm"
      :sncSs="searchData.sncSs"
      :search.sync="trigger.search"
    />
  </pmis-page>
</template>

<script>
import List from '../../approval/components/AppList.vue';

export default {
  components: {
    List,
  },
  data() {
    return {
      searchData: {
        pgmCd: '',
        frmNm: '',
        sncSs: 'ALL',
      },
      trigger: {
        search: false,
      },
    };
  },
  methods: {
    search_company_changed(e) {
      this.searchData.pgmCd = e.pgmCd;
      this.searchData.frmNm = e.frmNm;
      this.trigger.search = true;
    },
    search_click() {
      this.trigger.search = true;
    },
  },
};
</script>

<style></style>
