<template>
  <div class="header-multi-box">
    <slot />
  </div>
</template>

<script>
export default {
  mounted() {
    this.$el.childNodes.forEach(element => {
      element.setAttribute('class', 'header-multi-item');
    });
  },
};
</script>

<style></style>
