<template>
  <div class="header-box" :style="headerBoxStyle">
    <div v-if="$slots['header-left'] !== undefined" class="header-left">
      <slot name="header-left" />
    </div>
    <div v-if="$slots['header-center'] !== undefined" class="header-center">
      <slot name="header-center" />
    </div>
    <div v-if="$slots['header-right'] !== undefined" class="header-right">
      <slot name="header-right" />
    </div>
    <slot name="header-custom" v-if="$slots['header-custom'] !== undefined" />
  </div>
</template>

<script>
import {BUTTON_TYPE} from '@/components/Iui/const.js';

export default {
  name: 'iui-content-box-header-box',
  props: {},
  provide() {
    return {
      defaultButtonType: BUTTON_TYPE.SMALL,
    };
  },
  data() {
    return {
      headerBoxStyle: {},
    };
  },
  mounted() {
    let headerBoxHeight = '30px';

    let multiLeftCount = this.$el.querySelectorAll('.header-left > .header-multi-box > div').length;
    let multiCenterCount = this.$el.querySelectorAll('.header-center > .header-multi-box > div').length;
    let multiRightCount = this.$el.querySelectorAll('.header-right > .header-multi-box > div').length;
    let maxMultiCount = Math.max(multiLeftCount, multiCenterCount, multiRightCount);

    if (0 < maxMultiCount) {
      let blankCount1 = maxMultiCount - multiLeftCount;
      let blankCount2 = maxMultiCount - multiCenterCount;
      let blankCount3 = maxMultiCount - multiRightCount;

      if (0 < blankCount1) this.onAddBlankDiv('left', blankCount1);
      if (0 < blankCount2) this.onAddBlankDiv('center', blankCount2);
      if (0 < blankCount3) this.onAddBlankDiv('right', blankCount3);

      headerBoxHeight = `${30 * (maxMultiCount || 1)}px`;
    }

    this.headerBoxStyle = {height: headerBoxHeight};
  },
  methods: {
    onAddBlankDiv(className, count) {
      let targetObj = this.$el.querySelector(`.header-${className} > .header-multi-box`);
      if (targetObj) {
        for (let i = 0; i < count; i++) {
          let blankDiv = document.createElement('div');
          blankDiv.setAttribute('class', 'header-multi');
          targetObj.appendChild(blankDiv);
        }
      }
    },
  },
};
</script>

<style></style>
