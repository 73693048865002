import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
export default that => {
  return {
    Cfg: {
      HeaderCheck: 1,
    },
    Cols: [
      {
        Name: 'custName',
        Header: '거래처명',
        Width: 150,
        Type: 'text',
        CanEdit: false,
      },
      {
        Name: 'matCode',
        Header: '자재코드',
        Width: 130,
        Type: 'Text',
        Align: 'Center',
        CanEdit: false,
      },
      {
        Name: 'itemNm',
        Header: '품명',
        RelWidth: 1,
        MinWidth: 150,
        Type: 'Text',
        CanEdit: false,
      },
      {
        Name: 'sizeNm',
        Header: '규격',
        RelWidth: 1,
        MinWidth: 150,
        Type: 'Text',
        CanEdit: false,
      },
      {
        Name: 'unit',
        Header: '단위',
        Width: 60,
        Type: 'Text',
        CanEdit: false,
        Align: 'Center',
        Format: $getConstants('UNIT').code,
      },
      {
        Name: 'qty',
        Header: '입고수량',
        Extend: that.$ibsheetHelper.preset.quantity,
        RelWidth: 1,
        MinWidth: 100,
        CanEditFormula: e => that.monPjtCloseAt == 'N' && !e.Row.closeYn, // 마감 && 정산시 수정불가
      },
      {
        Name: 'price',
        Header: '단가',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        RelWidth: 1,
        MinWidth: 120,
        CanEditFormula: e => that.monPjtCloseAt == 'N' && !e.Row.closeYn && !e.Row.poNo, // 마감 && 정산 && 발주시 수정불가
      },
      {
        Name: 'amt',
        Header: '금액',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
        MinWidth: 140,
        CanEdit: false,
        Formula: e => e.Row.qty * e.Row.price,
      },
      {
        Name: 'poNo',
        Header: '계약(발주)번호',
        Type: 'Text',
        Width: 140,
        Align: 'Center',
        CanEdit: false,
        TextStyle: 4,
        CustomFormat: v => COMMON_FUNCTION.getMakeFormat(v),
      },
      {
        Name: 'ttl',
        Header: '계약(발주)명',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 160,
        CanEdit: false,
      },
      {Name: 'poSeq', Visible: false},
      {Name: 'flNo', Visible: false},
      {Name: 'revId', Visible: false},
      {Name: 'lclFlNm', Visible: false},
      {
        Name: 'contQty',
        Header: '계약수량',
        Extend: that.$ibsheetHelper.preset.quantity,
        Visible: false,
      },
      {
        Name: 'inputQty',
        Header: '기입고수량',
        Extend: that.$ibsheetHelper.preset.quantity,
        Visible: false,
      },
      {Name: 'pgmCd', Visible: false}, //orgCd
      {Name: 'prjCd', Visible: false}, //spotDeptcd
      {Name: 'ioGb', Visible: false},
      {Name: 'ioDate', Visible: false},
      {Name: 'seq', Visible: false},
      {Name: 'custCode', Visible: false}, //cusCd
      {Name: 'closeYn', Visible: false},
      {Name: 'reqDegree', Visible: false}, // 차수
      {Name: 'cud', Visible: false},
      {Name: 'inputQtyAfter', Visible: false, Extend: that.$ibsheetHelper.preset.quantity}, // 입고일자 이후 기입고수량
      {Name: 'qtyBefore', Visible: false, Extend: that.$ibsheetHelper.preset.quantity}, // 입력하기전 입고수량(원본)
      {Name: 'outputQty', Visible: false, Extend: that.$ibsheetHelper.preset.quantity}, // 투입수량
      {Name: 'inputQtySum', Visible: false, Extend: that.$ibsheetHelper.preset.quantity}, // 같은코드로 입고된 자재의 수량
    ],
  };
};
