export default that => {
  return {
    Cfg: {
      CanEdit: 1,
      UseButton: 1,
      IgnoreFocused: 1,
    },
    Cols: [
      {
        Header: '공종명',
        Name: 'mgName',
        Type: 'Text',
        Width: 200,
        CanEdit: 0,
      },
      {
        Header: '내역코드',
        Name: 'itemSeq',
        Type: 'Text',
        Width: 80,
        CanEdit: 0,
      },
      {
        Header: '도급코드',
        Name: 'itemCode',
        Type: 'Text',
        Width: 150,
        CanEdit: 0,
      },
      {
        Header: '내역명',
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: '규격',
        Name: 'ssize',
        Type: 'Text',
        Width: 90,
        CanEdit: 0,
      },
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        Width: 100,
        CanEdit: 0,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: '수량',
        Name: 'qty',
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 80,
        CanEdit: 0,
      },
      {
        Header: '금액',
        Name: 'amt',
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 120,
        CanEdit: 0,
      },
      {
        Name: 'wcode',
        Type: 'Text',
        CanEdit: 0,
        Visible: 0,
      },
      {
        Name: 'headCode',
        Type: 'Text',
        CanEdit: 0,
        Visible: 0,
      },
      {
        Name: 'midCode',
        Type: 'Text',
        CanEdit: 0,
        Visible: 0,
      },
      {
        Name: 'seq',
        Type: 'Text',
        CanEdit: 0,
        Visible: 0,
      },
    ],
  };
};
