import {selectBreakdownCostList} from '../api/BreakdownCost.js';
const state = {
  breakdownCostCl: {
    pgmCd: '',
    code: '',
    name: '',
    upCode: '',
    upName: '',
    level: '',
    sctmLcode: '',
    sctmLcodeNm: '',
    sctmMcode: '',
    sctmMcodeNm: '',
    sctmScode: '',
    sctmScodeNm: '',
    sctmSlcodeYn: '',
  },
  detailInfo: {
    pgmCd: '', //회사코드
    code: '',
    name: '',
    itemCode: '', //일위대가코드
    itemName: '', //일위대가명
    ssize: '', //규격
    unit: '', //단위
    unitName: '', //단위명
    amt: '', //금액
    matAmt: '', //자재비금액
    labAmt: '', //노무비금액
    equipAmt: '', //경비금액
    useDs: '', //사용여부
    sctmLcode: '', // 일위대가대분류코드
    sctmLcodeNm: '', // 일위대가대분류명칭
    sctmMcode: '', // 일위대가중분류코드
    sctmMcodeNm: '', // 일위대가중분류코드명칭
    sctmScode: '', // 일위대가소분류코드
    sctmScodeNm: '', // 일위대가소분류코드명칭
    sctmSlcodeYn: '', // 하위분류존재
  },
  detailInfoDtls: {
    costType: '',
    itemCode1: '',
    itemCode2: '',
    itemName: '',
    ssize: '',
    unit: '',
    unitName: '',
    qty: '',
    formula: '',
  },
  listLoadSearchData: [],
  focusClKey: '',
  focusItemCodeKey: '',
  isFormula: false,
  searchText: '',
};

const getters = {
  breakdownCostCl: state => state.breakdownCostCl,
  detailInfo: state => state.detailInfo,
  focusClKey: state => state.focusClKey,
  focusItemCodeKey: state => state.focusItemCodeKey,
  isFormula: state => state.isFormula,
  detailInfoDtls: state => state.detailInfoDtls,
  listLoadSearchData: state => state.listLoadSearchData,
  searchText: state => state.searchText,
};

const mutations = {
  setBreakdownCostCl(state, payload) {
    for (let key in state.breakdownCostCl) {
      if (payload && payload.hasOwnProperty(key)) {
        state.breakdownCostCl[key] = payload[key];
      }
    }
  },

  initDetailInfo(state) {
    for (let key in state.detailInfo) {
      state.detailInfo[key] = '';
    }
  },

  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = payload[key] || '';
      }
    }
  },

  initFocusClKeye(state) {
    state.focusClKey = '';
  },

  initFocusItemCodeKey(state) {
    state.focusItemCodeKey = '';
  },

  setFocusClKey(state, payload) {
    state.focusClKey = payload;
  },

  setFocusItemCodeKey(state, payload) {
    state.focusItemCodeKey = payload;
  },

  setFormula(state, payload) {
    state.isFormula = payload;
  },

  setDetailInfoDtls(state, payload) {
    for (let key in state.detailInfoDtls) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfoDtls[key] = payload[key];
      }
    }
  },

  setListLoadSearchData(state, payload) {
    state.listLoadSearchData = payload;
  },

  setSearchText(state, payload) {
    state.searchText = payload;
  },
};
const actions = {
  async onSearchList({commit, state}) {
    commit('initDetailInfo');
    const param = {
      code: state.breakdownCostCl.level == 1 ? '' : state.breakdownCostCl.code || '',
      searchText: state.searchText,
    };
    const response = await selectBreakdownCostList(param);
    commit('setListLoadSearchData', response.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
