export default that => {
  that.loading = true;
  const prjInfo = that.getPrjInfo();
  let s = prjInfo ? String(prjInfo?.ctrtStrdate) : undefined;
  let e = prjInfo ? String(prjInfo?.ctrtEnddate) : undefined;
  if (!s) s = $_getYearMonthFirstDay();
  if (!e) e = $_getYearMonthLastDay();
  const sdate = new Date(s.slice(0, 4), s.slice(4, 6) - 1, s.slice(6, 8));
  that.psd = new Date(s.slice(0, 4), s.slice(4, 6) - 1, s.slice(6, 8)); // 그래프의 시작일
  const ped = new Date(e.slice(0, 4), e.slice(4, 6) - 1, e.slice(6, 8)); // 그래프의 종료일
  //화면에 표시되는 총 기간 일
  that.pdays = (ped - that.psd) / 1000 / 60 / 60 / 24;
  const header = {};
  const Cols = [];
  for (let i = 0; i < that.pdays + 1; i++) {
    const ymd = $_dateToStringYYMMDD(sdate);
    const year = ymd.slice(0, 4);
    const month = ymd.slice(4, 6);
    const date = ymd.slice(6, 8);
    const key = year + ' ' + month;

    if (!header[key]) {
      header[key] = [];
    }
    header[key] = header[key].concat(ymd.slice(6, 8));
    sdate.setDate(sdate.getDate() + 1);

    const j = i + 1;

    const _date = new Date(year, month - 1, date);
    const currentDate = $_getCurrentDate();
    const y = Number(currentDate.slice(0, 4));
    const m = Number(currentDate.slice(4, 6));
    const d = Number(currentDate.slice(6, 8));
    if (y == _date.getFullYear() && m == _date.getMonth() + 1 && d == _date.getDate()) {
      that.currentIndex = j;
    }

    Cols.push({
      Header: [
        key,
        {
          Value: date,
          TextColor: _date.getDay() == 6 ? '#33ACFF' : _date.getDay() == 0 ? '#FF3333' : '',
        },
      ],
      Type: 'Html',
      Name: 'gant' + j,
      Align: 'Center',
      CanEdit: 0,
      Width: that.gantWidth,
    });
  }

  return {
    Cfg: {
      MainCol: 'fldrNm',
      HeaderMerge: 3,
      PrevColumnMerge: 0,
      ShifHint: false,
      CanSort: false,
    },
    LeftCols: [
      {Header: ['작업명', '작업명'], Type: 'Text', Name: 'fldrNm', Width: '250', Align: 'Center', CanEdit: 0},
      {Header: ['공기', '공기'], Type: 'Text', Name: 'workCnt', Width: '50', Align: 'Center', CanEdit: 0},
    ],
    Def: {
      Spacer: {CanFocus: 0, Width: 5},
      Row: {Height: 35, ShowHint: 0},
    },
    Cols: Cols,
  };
};
