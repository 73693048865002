export default that => {
  const preset = that.$ibsheetHelper.preset;

  return {
    Cfg: {CanEdit: 0, SpaceForDefaultValue: 1},
    Def: {
      Row: {NoColor: 1},
    },
    Cols: [
      {
        Header: '변경차수',
        Name: 'contSeq',
        Type: 'Text',
        Align: 'center',
        Width: 90,
        CustomFormat: v => (parseInt(v) === 0 ? '당초' : `${parseInt(v)}차`),
      },
      {Header: '계약일자', Name: 'poDt', Type: 'Text', Align: 'center', RelWidth: 1, MinWidth: 120},
      {Header: '납품일자', Name: 'dlvPeriod', Type: 'Text', Align: 'center', RelWidth: 1, MinWidth: 140},
      {
        Header: '발주자서명일자',
        Name: 'chrgUsrSignDt',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        MinWidth: 120,
        DefaultValue: '-',
      },
      {
        Header: '계약자서명일자',
        Name: 'cusUsrSignDt',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        MinWidth: 120,
        DefaultValue: '-',
      },
      {Header: '계약금액', Name: 'totAmt', Type: 'Text', RelWidth: 1, MinWidth: 120, Extend: preset.amount},
      {
        Header: '진행상태',
        Name: 'poSt',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        MinWidth: 120,
        Format: $getConstants('BIDDING_ST').code,
      },
    ],
  };
};
