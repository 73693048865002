<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col min-width="1300px">
        <form onsubmit="return false;" style="height: 100%">
          <iui-container-new type="css-flex">
            <i-row height="315px" style="overflow: visible">
              <i-col>
                <iui-container-new type="table" theme="bullet">
                  <colgroup>
                    <col style="width:80px" />
                    <col style="width:530px" />
                    <col style="width:90px" />
                    <col style="width:350px" />
                    <col />
                  </colgroup>
                  <i-row>
                    <i-col colspan="2">
                      <pmis-content-box>
                        <template v-slot:title>근로자정보</template>
                      </pmis-content-box>
                    </i-col>
                    <i-col></i-col>
                    <i-col></i-col>
                    <i-col rowspan="9" align="right" verticalAlign="top">
                      <iui-carousel width="200px" height="250px">
                        <pmis-image-file
                          title="사진"
                          id="photo"
                          flNoCol="phoFlNo"
                          imageWidth="200px"
                          imageHeight="250px"
                          :file-number.sync="workerInfo.phoFlNo"
                          :allowAdd="addStatus === 0"
                          :allowDelete="addStatus === 0"
                          :saveButtonVisible="false"
                          @delete-complete="onDeleteCompleteFile('phoFlNo', $event)"
                          :clear.sync="clearFlNo1"
                        />
                        <pmis-image-file
                          title="싸인"
                          id="sign"
                          flNoCol="sgntFlNo"
                          imageWidth="200px"
                          imageHeight="250px"
                          :file-number.sync="workerInfo.sgntFlNo"
                          :allowAdd="addStatus === 0"
                          :allowDelete="addStatus === 0"
                          :saveButtonVisible="false"
                          @delete-complete="onDeleteCompleteFile('sgntFlNo', $event)"
                          :clear.sync="clearFlNo2"
                        />
                      </iui-carousel>
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header required>국적</i-col-header>
                    <i-col>
                      <iui-text
                        type="text"
                        name="nationNm"
                        :value="workerInfo.nationNm"
                        max-length="6"
                        required
                        :error-message="{title: '국적', message: '국적을 선택해주세요.'}"
                        width="110px"
                        :enable="addStatus === 1"
                        :readonly="addStatus === 1"
                      />
                      <iui-button v-if="addStatus === 1" value="검색" @click="onShowModal('nation')" />
                    </i-col>
                    <i-col-header required>{{ isKorean ? '주민번호' : '여권번호' }}</i-col-header>
                    <i-col :style="`display:${isKorean ? '' : 'none'};`">
                      <iui-regi-no
                        :value1="workerInfo.regiNo1"
                        :value2="workerInfo.regiNo2"
                        @change1="onDuplicateRegiNo"
                        @change2="onDuplicateRegiNo"
                        :enable="addStatus === 1 || useMst"
                        :required="workerInfo && isKorean"
                      />
                    </i-col>
                    <i-col :style="`display:${!isKorean ? '' : 'none'};`">
                      <iui-text
                        name="passportNo"
                        :value="workerInfo.passportNo"
                        @change="onDuplicateRegiNo"
                        max-length="15"
                        :required="workerInfo && !isKorean"
                        :error-message="{title: '여권번호', message: '여권번호를 입력해주세요.'}"
                        width="130px"
                        :enable="addStatus === 1 && !isKorean"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>ID</i-col-header>
                    <i-col>
                      <iui-text
                        type="empNo"
                        name="empNo"
                        :value="workerInfo.empNo"
                        @change="onChangeEmpNo($event.target.value)"
                        width="110px"
                        max-length="8"
                        :enable="addStatus === 0 && isNew"
                      />
                      <iui-button value="중복확인" @click="onDuplicateEmpNo" v-if="addStatus === 0 && isNew" />
                      <div>영문숫자(5~8)</div>
                    </i-col>
                    <i-col-header required>성명</i-col-header>
                    <i-col>
                      <iui-text
                        name="name"
                        type="text"
                        :value="workerInfo.name"
                        @change="setWorkerInfo({name: $event.target.value})"
                        max-length="50"
                        required
                        :error-message="{title: '성명', message: '성명을 입력해주세요.'}"
                        width="130px"
                        :enable="addStatus === 0"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header required>소속</i-col-header>
                    <i-col>
                      <!-- 소속이 외주 or 노무팀이면, 근로자구분은 일용직으로 고정 -->
                      <iui-select
                        :p-code="$getConstants('BLN_DEP_PART_CD').code"
                        name="blnDepPart"
                        :value="workerInfo.blnDepPart"
                        @change="value => setWorkerInfo({blnDepPart: value})"
                        required
                        :error-message="{title: '소속', message: '소속을 선택해주세요.'}"
                        defaultCd="S"
                        width="110px"
                        :enable="addStatus === 0"
                      />
                      <input
                        type="button"
                        class="i_search2"
                        @click="onShowModal('blnDepPart')"
                        v-if="workerInfo.blnDepPart"
                      />
                      <iui-text
                        width="350px"
                        name="blnDepNm"
                        :value="workerInfo.blnDepNm"
                        @change="setWorkerInfo({blnDepNm: $event.target.value})"
                        :enable="addStatus === 0"
                        :readonly="addStatus === 0"
                      />
                    </i-col>
                    <i-col-header required>근로자구분</i-col-header>
                    <i-col>
                      <!-- 근로자구분 laborDs = “000003”(일용직) 이면 단가구분,노무단가,노무직종은 필수 -->
                      <iui-select
                        :p-code="$getConstants('LABOR_DS_CD').code"
                        name="laborDs"
                        :value="workerInfo.laborDs"
                        @change="
                          value => {
                            setWorkerInfo({laborDs: value});
                            systemUse();
                          }
                        "
                        required
                        :error-message="{title: '근로자구분', message: '근로자구분 선택해주세요.'}"
                        defaultCd="S"
                        width="130px"
                        :enable="
                          workerInfo.blnDepPart == $getConstants('BLN_DEP_PART_CD_COMPANY').code && addStatus === 0
                        "
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>직책</i-col-header>
                    <i-col>
                      <iui-select
                        :p-code="$getConstants('DTS_NO').code"
                        name="dtsNo"
                        :value="workerInfo.dtsNo"
                        @change="value => setWorkerInfo({dtsNo: value})"
                        defaultCd="S"
                        width="110px"
                        :enable="workerInfo.laborDs == $getConstants('LABOR_DS1').code && addStatus === 0"
                      />
                    </i-col>
                    <i-col-header>직급</i-col-header>
                    <i-col>
                      <iui-select
                        :p-code="$getConstants('ROLL_NO').code"
                        name="rollNo"
                        :value="workerInfo.rollNo"
                        @change="value => setWorkerInfo({rollNo: value})"
                        defaultCd="S"
                        width="130px"
                        :enable="workerInfo.laborDs == $getConstants('LABOR_DS1').code && addStatus === 0"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>담당업무</i-col-header>
                    <i-col>
                      <iui-select
                        :p-code="$getConstants('W_BUSINESS').code"
                        name="wbusiness"
                        :value="workerInfo.wbusiness"
                        @change="value => setWorkerInfo({wbusiness: value})"
                        defaultCd="S"
                        width="110px"
                        :enable="
                          (workerInfo.laborDs == $getConstants('LABOR_DS1').code ||
                            workerInfo.laborDs == $getConstants('LABOR_DS2').code) &&
                            addStatus === 0
                        "
                      />
                    </i-col>
                    <i-col-header>전화번호</i-col-header>
                    <i-col>
                      <iui-text
                        type="phone"
                        name="tlno"
                        :value="workerInfo.tlno"
                        @change="setWorkerInfo({tlno: $event.target.value})"
                        width="130px"
                        :enable="addStatus === 0"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header required>휴대폰번호</i-col-header>
                    <i-col>
                      <iui-text
                        type="phone"
                        name="mobileTlno"
                        :value="workerInfo.mobileTlno"
                        @change="setWorkerInfo({mobileTlno: $event.target.value})"
                        :enable="addStatus === 0"
                        width="110px"
                        required
                        :error-message="{title: '휴대폰번호', message: '휴대폰번호를 입력해주세요.'}"
                      />
                      <iui-checkbox-group
                        name="useSms"
                        :items="[{label: 'SMS수신동의', value: $getConstants('Y').code}]"
                        :checkedValues="[workerInfo.useSms]"
                        :enable="addStatus === 0"
                        @change="
                          setWorkerInfo({
                            useSms: $event.target.checked ? $event.target.value : '',
                          })
                        "
                      />
                    </i-col>
                    <i-col-header>팩스번호</i-col-header>
                    <i-col>
                      <iui-text
                        type="phone"
                        name="rpsvFaxno"
                        :value="workerInfo.rpsvFaxno"
                        @change="setWorkerInfo({rpsvFaxno: $event.target.value})"
                        :enable="addStatus === 0"
                        width="130px"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>주소</i-col-header>
                    <i-col>
                      <iui-searchbox
                        :type="'addr'"
                        name="postNo"
                        :idValue="workerInfo.postNo"
                        :nameValue="workerInfo.adr1"
                        nameEleName="adr1"
                        :callback="getAddr"
                        width="505px"
                        :isSearchBtn="addStatus === 0"
                      />
                    </i-col>
                    <i-col-header>이메일</i-col-header>
                    <i-col>
                      <iui-text
                        name="email1"
                        type="email1"
                        width="110px"
                        max-length="50"
                        :value="workerInfo.email1"
                        @change="setWorkerInfo({email1: $event.target.value})"
                        :enable="addStatus === 0"
                      />
                      <div class="mr5">@</div>
                      <iui-select
                        width="100px"
                        :p-code="$getConstants('EMAIL_CD').code"
                        :value="isEmail2Code ? '' : workerInfo.email2"
                        defaultCd="직접입력"
                        @change="value => setWorkerInfo({email2: value})"
                        :enable="addStatus === 0"
                      />
                      <iui-text
                        type="email2"
                        width="100px"
                        max-length="50"
                        :value="workerInfo.email2"
                        v-if="isEmail2Code"
                        @change="setWorkerInfo({email2: $event.target.value})"
                        :enable="addStatus === 0"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col />
                    <i-col>
                      <iui-text
                        name="adr2"
                        :value="workerInfo.adr2"
                        @change="setWorkerInfo({adr2: $event.target.value})"
                        max-length="100"
                        width="390px"
                        style="margin-left: 115px"
                        :enable="addStatus === 0"
                      />
                    </i-col>
                    <i-col-header>근무종료여부</i-col-header>
                    <i-col>
                      <iui-checkbox-group
                        name="labtClose"
                        :p-code="$getConstants('LABT_CLOSE').code"
                        :checkedValues="[workerInfo.labtClose]"
                        @change="setWorkerInfo({labtClose: $event.target.checked ? $event.target.value : ''})"
                        :enable="(!isNew && addStatus === 0) || (addStatus === -1 && workerInfo.canEnableLabtClose)"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col colspan="4">
                      <iui-container-new type="table" theme="bullet">
                        <colgroup>
                          <col style="width:80px" />
                          <col style="width:120px" />
                          <col style="width:80px" />
                          <col style="width:120px" />
                          <col style="width:80px" />
                          <col style="width:130px" />
                          <col style="width:90px" />
                          <col style="width:130px" />
                          <col style="width:80px" />
                          <col style="width:120px" />
                          <col style="width:80px" />
                          <col />
                        </colgroup>
                        <i-row>
                          <i-col-header>거래은행</i-col-header>
                          <i-col>
                            <iui-select
                              :p-code="$getConstants('BANK_CODE').code"
                              name="bankCode"
                              :value="workerInfo.bankCode"
                              @change="value => setWorkerInfo({bankCode: value})"
                              defaultCd="S"
                              width="110px"
                              :enable="addStatus === 0"
                            />
                          </i-col>
                          <i-col-header>계좌번호</i-col-header>
                          <i-col>
                            <iui-text
                              name="depositNo"
                              max-length="20"
                              :value="workerInfo.depositNo"
                              @change="setWorkerInfo({depositNo: $event.target.value})"
                              width="110px"
                              :enable="addStatus === 0"
                            />
                          </i-col>
                          <i-col-header>예금주</i-col-header>
                          <i-col>
                            <iui-text
                              name="depositor"
                              max-length="50"
                              :value="workerInfo.depositor"
                              width="90px"
                              @change="setWorkerInfo({depositor: $event.target.value})"
                              :enable="addStatus === 0"
                            />
                          </i-col>
                          <i-col-header :required="addStatus === 0 && !isKorean">체류자격</i-col-header>
                          <i-col>
                            <iui-select
                              :p-code="$getConstants('VISA_KIND').code"
                              name="visaKind"
                              :value="workerInfo.visaKind"
                              @change="value => setWorkerInfo({visaKind: value})"
                              defaultCd="S"
                              width="120px"
                              :enable="addStatus === 0 && !isKorean"
                              :required="!isKorean"
                              :error-message="{title: '체류자격', message: '체류자격을 선택해주세요.'}"
                            />
                          </i-col>
                          <i-col-header>체류기간</i-col-header>
                          <i-col>
                            <iui-text
                              name="stayDuration"
                              max-length="50"
                              :value="workerInfo.stayDuration"
                              @change="setWorkerInfo({stayDuration: $event.target.value})"
                              width="100px"
                              :enable="addStatus === 0 && !isKorean"
                            />
                          </i-col>
                          <i-col-header>입국일자</i-col-header>
                          <i-col>
                            <iui-datepicker
                              name="entryDate"
                              :value="workerInfo.entryDate"
                              @change="setWorkerInfo({entryDate: $event})"
                              :enable="addStatus === 0 && !isKorean"
                            />
                          </i-col>
                        </i-row>
                      </iui-container-new>
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col colspan="5">
                      <iui-container-new type="table" theme="bullet">
                        <colgroup>
                          <col style="width:80px" />
                          <col style="width:120px" />
                          <col style="width:80px" />
                          <col style="width:120px" />
                          <col style="width:80px" />
                          <col style="width:130px" />
                          <col style="width:90px" />
                          <col style="width:130px" />
                          <col style="width:110px" />
                          <col />
                        </colgroup>
                        <i-row>
                          <i-col-header>장애유형</i-col-header>
                          <i-col>
                            <iui-select
                              :p-code="$getConstants('HANDICAP_KIND').code"
                              name="handicapKind"
                              :value="workerInfo.handicapKind"
                              @change="value => setWorkerInfo({handicapKind: value})"
                              defaultCd="S"
                              width="110px"
                              :enable="addStatus === 0"
                            />
                          </i-col>
                          <i-col-header>장애등급</i-col-header>
                          <i-col>
                            <iui-text
                              name="handicapGrade"
                              max-length="50"
                              :value="workerInfo.handicapGrade"
                              @change="setWorkerInfo({handicapGrade: $event.target.value})"
                              width="110px"
                              :enable="addStatus === 0"
                            />
                          </i-col>
                          <i-col-header>장애판정일</i-col-header>
                          <i-col>
                            <iui-datepicker
                              name="handicapDate"
                              :value="workerInfo.handicapDate"
                              @change="setWorkerInfo({handicapDate: $event})"
                              :enable="addStatus === 0"
                            />
                          </i-col>
                          <i-col-header>관리자여부</i-col-header>
                          <i-col>
                            <iui-checkbox-group
                              name="useMst"
                              :p-code="$getConstants('USE_MST').code"
                              :checkedValues="[workerInfo.useMst]"
                              @change="setWorkerInfo({useMst: $event.target.value})"
                              :enable="false"
                            />
                          </i-col>
                          <i-col-header>시스템사용여부</i-col-header>
                          <i-col>
                            <iui-checkbox-group
                              name="useDs"
                              :checkedValues="[workerInfo.useDs]"
                              :items="[{label: '사용', value: $getConstants('Y').code}]"
                              @change="
                                setWorkerInfo({
                                  useDs: $event.target.checked ? $event.target.value : $getConstants('N').code,
                                })
                              "
                              :enable="addStatus === 0"
                            />
                          </i-col>
                        </i-row>
                      </iui-container-new>
                    </i-col>
                  </i-row>
                </iui-container-new>
              </i-col>
            </i-row>
            <i-row height="65px" v-if="!isFullTimeEmployee">
              <pmis-content-box>
                <template v-slot:title>노무정보</template>
                <iui-container-new type="table" theme="bullet">
                  <colgroup>
                    <col style="width:80px" />
                    <col style="width:90px" />
                    <col style="width:80px" />
                    <col style="width:110px" />
                    <col style="width:80px" />
                    <col style="width:170px" />
                    <col style="width:90px" />
                    <col style="width:35px" />
                    <col style="width:90px" />
                    <col style="width:35px" />
                    <col style="width:135px" />
                    <col style="width:35px" />
                    <col style="width:90px" />
                    <col />
                  </colgroup>
                  <i-row>
                    <i-col-header :required="isDailyLabor">단가구분</i-col-header>
                    <i-col>
                      <iui-select
                        :p-code="$getConstants('PRICE_L_GB').code"
                        name="priceLGb"
                        :value="workerInfo.priceLGb"
                        @change="value => setWorkerInfo({priceLGb: value})"
                        defaultCd="S"
                        :enable="false"
                      />
                    </i-col>
                    <i-col-header :required="isDailyLabor">노무단가</i-col-header>
                    <i-col>
                      <iui-text
                        name="priceLAmt"
                        type="amount"
                        :value="workerInfo.priceLAmt"
                        @change="setWorkerInfo({priceLAmt: $event.target.value})"
                        width="110px"
                        :enable="addStatus === 0 && isDailyLabor"
                        :required="isDailyLabor"
                        :error-message="{title: '노무단가', message: '노무단가를 입력해주세요.'}"
                      />
                    </i-col>
                    <i-col-header :required="isDailyLabor">노무직종</i-col-header>
                    <i-col>
                      <iui-text
                        type="text"
                        name="jkdNm"
                        :value="workerInfo.jkdNm"
                        @change="setWorkerInfo({jkdNm: $event.target.value})"
                        :required="isDailyLabor"
                        :error-message="{title: '노무직종', message: '노무직종을 선택해주세요.'}"
                        width="100px"
                        :enable="false"
                      />
                      <iui-button v-if="isDailyLabor && addStatus === 0" value="검색" @click="onShowModal('jkd')" />
                    </i-col>
                    <i-col-header>국민연금제외</i-col-header>
                    <i-col>
                      <iui-checkbox-group
                        name="pensionDs"
                        :p-code="$getConstants('PENSION_DS_CD').code"
                        :checkedValues="[workerInfo.pensionDs]"
                        @change="
                          setWorkerInfo({
                            pensionDs: $event.target.checked ? $event.target.value : '',
                          })
                        "
                        :enable="addStatus === 0 && !isFullTimeEmployee"
                        :showLabel="false"
                      />
                    </i-col>
                    <i-col-header>건강보험제외</i-col-header>
                    <i-col>
                      <iui-checkbox-group
                        name="healthInsurDs"
                        :p-code="$getConstants('HEALTH_INSUR_DS_CD').code"
                        :checkedValues="[workerInfo.healthInsurDs]"
                        @change="
                          setWorkerInfo({
                            healthInsurDs: $event.target.checked ? $event.target.value : '',
                          })
                        "
                        :enable="addStatus === 0 && !isFullTimeEmployee"
                        :showLabel="false"
                      />
                    </i-col>
                    <i-col-header>장기요양30%경감대상</i-col-header>
                    <i-col>
                      <iui-checkbox-group
                        name="healthInsurMDs"
                        :p-code="$getConstants('HEALTH_INSUR_M_DS_CD').code"
                        :checkedValues="[workerInfo.healthInsurMDs]"
                        @change="
                          setWorkerInfo({
                            healthInsurMDs: $event.target.checked ? $event.target.value : '',
                          })
                        "
                        :enable="addStatus === 0 && !isFullTimeEmployee"
                        :showLabel="false"
                      />
                    </i-col>
                    <i-col-header>고용보험제외</i-col-header>
                    <i-col>
                      <iui-checkbox-group
                        name="employInsurDs"
                        :p-code="$getConstants('EMPLOY_INSUR_DS_CD').code"
                        :checkedValues="[workerInfo.employInsurDs]"
                        @change="
                          setWorkerInfo({
                            employInsurDs: $event.target.checked ? $event.target.value : '',
                          })
                        "
                        :enable="addStatus === 0 && !isFullTimeEmployee"
                        :showLabel="false"
                      />
                    </i-col>
                  </i-row>
                </iui-container-new>
              </pmis-content-box>
            </i-row>
            <i-row height="10px">
              <i-col />
            </i-row>
            <i-row minHeight="200px">
              <i-col>
                <iui-tab :comps="tabComps" @after-active-tab="afterActiveBottomTab">
                  <template #tab-right v-if="addStatus === 0">
                    <iui-button value="추가" @click="bottomAdd" />
                    <iui-button value="저장" @click="bottomSave" v-if="!isNew" />
                    <iui-button value="삭제" @click="bottomDelete" />
                  </template>
                </iui-tab>
              </i-col>
            </i-row>
            <iui-modal name="nationListPopup" title="국가검색" :btns="modalBtn" sizeType="size1">
              <NationList :uid="_uid" :paramObj="{code: $getConstants('NATION_CD').code}" />
            </iui-modal>
            <iui-modal name="deptListPopup" title="부서선택" :btns="modalBtn" sizeType="size1">
              <DeptList :uid="_uid" :frmNm="workerInfo.frmNm" />
            </iui-modal>
            <iui-modal name="lbrcCodeListPopup" title="노무직종선택" :btns="modalBtn" sizeType="size1">
              <LbrcCodeList :uid="_uid" />
            </iui-modal>
            <iui-modal name="lbrcTeamListPopup" title="노무팀선택" :btns="modalBtn" sizeType="size1">
              <LbrcTeamList :uid="_uid" />
            </iui-modal>
            <iui-modal name="subconListPopup" title="외주선택" :btns="modalBtn" sizeType="size3">
              <SubconListPopup :uid="_uid" />
            </iui-modal>
          </iui-container-new>
        </form>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
/**
 * 사원등록(건설사) > 상세정보
 * components :
 * ------------- 팝업 ---------------
 *   NationList : 국가목록 팝업
 *   LbrcCodeList : 노무직종 팝업
 *   DeptList : 부서정보 팝업
 *   SubconListPopup : 외주 팝업
 *   LbrcTeamList : 노무팀 조회 팝업
 * ----------------------------------
 *   InputPrj : 투입현장정보
 *   LicenseList : 자격면허정보
 * */
import NationList from '@/view/user/Worker/components/NationListPopup.vue';
import LbrcCodeList from '@/view/user/Worker/components/LbrcCodeListPopup.vue';
import DeptList from '@/view/user/Worker/components/DeptListPopup.vue';
import SubconListPopup from '@/view/user/Worker/components/SubconListPopup.vue';
import LbrcTeamList from '@/view/user/Worker/components/LbrcTeamListPopup.vue';
import {
  selectWorkerInfo,
  selectWorkerExistEmpNo,
  selectContUserCount,
  saveWorkerInfo,
  selectWorkerInfoList,
  deleteWorkerInfo,
  updateWorkerFlNo,
  deleteInputPrjList,
} from '../api/worker.js';

export default {
  components: {NationList, LbrcCodeList, DeptList, LbrcTeamList, SubconListPopup},
  data() {
    return {
      clearFlNo1: false,
      clearFlNo2: false,
      email2: '',
      modalBtn: [{name: '확인', callback: this.onHideModal}],
      modalNm: '',
      tabIndex: 0,
      activeTab: 'InputPrj',
      regiNoDupChkComplete: false, // 주민번호 중복확인 완료
      tabComps: [
        {
          tabNm: '투입현장정보',
          compNm: 'InputPrj',
          path: '@/view/user/Worker/components/InputPrj.vue',
        },
        {
          tabNm: '자격면허정보',
          compNm: 'LicenseList',
          path: '@/view/user/Worker/components/LicenseList.vue',
        },
      ],
    };
  },
  computed: {
    isEmail2Code() {
      return String(this.workerInfo.email2).indexOf('0000') == -1;
    },
    valueEmail2() {
      return this.workerInfo.email2?.indexOf('0000') == -1 ? '' : this.workerInfo.email2;
    },
    isNew() {
      return this.workerInfo.cud === 1;
    },
    isKorean() {
      return this.workerInfo.nationCd == 'KR';
    },
    isFullTimeEmployee() {
      // 자사 && 정직
      return (
        this.workerInfo.blnDepPart === $getConstants('BLN_DEP_PART_CD_COMPANY').code &&
        this.workerInfo.laborDs === $getConstants('LABOR_DS1').code
      );
    },
    isDailyLabor() {
      // 외주 && 일용직
      return (
        this.workerInfo.laborDs == $getConstants('LABOR_DS2').code ||
        this.workerInfo.laborDs == $getConstants('LABOR_DS3').code
      );
    },
    addStatus() {
      let status = 0;
      if (this.isNew) {
        if (
          !this.workerInfo.nationCd ||
          ((!this.workerInfo.regiNo1 || !this.workerInfo.regiNo2) && !this.workerInfo.passportNo) ||
          !this.regiNoDupChkComplete
        ) {
          status = 1; // 국가 및 주민번호, 여권번호 입력 상태
        }
      } else if (this.workerInfo.labtClose === $getConstants('LABT_CLOSE_Y').code) {
        status = -1;
      }
      return status;
    },
    useMst() {
      return (
        (this.workerInfo.regiNo1 == '' ||
          this.workerInfo.regiNo2 == '' ||
          this.workerInfo.regiNo1 == null ||
          this.workerInfo.regiNo2 == null) &&
        this.workerInfo.useMst == $getConstants('USE_MST_Y').code
      );
    },
  },
  beforeCreate() {
    $mapGetters(this, ['workerInfo', 'fileFlag', 'removeRow']);
    $mapMutations(this, ['setWorkerInfo', 'initWorkerInfo', 'setFileFlag', 'initFileFlag', 'initRemoveRow']);
  },
  created() {
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
    this.addEvent([
      {name: 'WorkerInfo_onSearch', func: this.onSearch},
      {name: 'onHideModal_' + this._uid, func: this.onHideModal},
    ]);
  },
  methods: {
    onChangeEmpNo(value) {
      this.setWorkerInfo({idDupChk: 0, empNo: value});
    },
    async onSearch(empNo) {
      this.initWorkerInfo();
      this.clearFlNo1 = true;
      this.clearFlNo2 = true;
      this.regiNoDupChkComplete = false;
      let param = {
        empNo: empNo,
      };
      const response = await selectWorkerInfo(param);
      if (response.status == 200) {
        response.data.email2 = response.data.originEmail2;
        this.setWorkerInfo(response.data);
        this.callEvent({name: 'IuiCarousel_SetIndex', param: 0});
        this.callEvent({name: 'setActiveTab', param: this.tabIndex});
        this.tabIndex = 0;
        this.callEvent({name: `InputPrj_onSearch`});
        this.callEvent({name: `LicenseList_onSearch`});
      }
    },
    onAdd() {
      this.callEvent({name: 'InputPjt_initSheet'});
      this.callEvent({name: 'LicenseList_initSheet'});
      this.callEvent({name: 'IuiCarousel_SetIndex', param: 0});
      this.callEvent({name: 'setActiveTab', param: 0});
      let frmNm = this.workerInfo.frmNm;

      this.initWorkerInfo();
      this.clearFlNo1 = true;
      this.clearFlNo2 = true;
      this.setWorkerInfo({
        pgmCd: this.pgmCd,
        useDs: $getConstants('USE_YN_Y').code,
        useSms: $getConstants('Y').code,
        rgprId: this.userInfo.empNo,
        frmNm: frmNm,
        cud: 1,
      });
    },
    async onSave() {
      if (this.isNew && this.workerInfo.empNo && this.workerInfo.idDupChk !== 1) {
        this.$alert({title: 'ID 중복확인', message: 'ID 중복확인을 해주세요.'});
        return;
      }
      if (this.removeRow.prjCd) {
        let removeList = [];
        removeList.push({
          empNo: this.workerInfo.empNo,
          prjCd: this.removeRow.prjCd,
          poNoSubc: this.removeRow.poNoSubc,
        });
        const response = await deleteInputPrjList({inputPrjSaveList: removeList});
        if (response.status == 200) {
          this.initRemoveRow();
        }
      }

      let inputPrjSaveList = []; // 투입현장정보 저장 정보 조회
      let workerSkillSaveList = []; // 자격면허정보 저장 정보 조회
      let inputPrjObj = {isValid: true, list: []};
      let workerObj = {isValid: true, list: []};
      this.callEvent({name: 'InputPrj_getSaveList', param: data => (inputPrjObj = data)});
      this.callEvent({name: 'LicenseList_getSaveList', param: data => (workerObj = data)});
      if (!workerObj.isValid || !inputPrjObj.isValid) {
        return;
      }
      inputPrjSaveList = inputPrjObj.list;
      workerSkillSaveList = workerObj.list;
      let param = {
        ...this.workerInfo,
        inputPrjSaveList,
        workerSkillSaveList,
      };
      const response = await saveWorkerInfo(param);
      if (response.status == 200) {
        this.callEvent({name: 'WorkerList_onSearch', param: response.data});
      }
    },
    async onDelete() {
      if (await this.$confirm({title: '근로자 삭제', message: '삭제하시겠습니까?'})) {
        if (this.workerInfo.useMst == $getConstants('USE_MST_Y').code) {
          this.$alert({title: '삭제 불가', message: '관리자는 삭제할 수 없습니다.'});
          return;
        }
        if (this.isNew) {
          this.$alert({title: '삭제 불가', message: '신규 작성중인 정보는 삭제할 수 없습니다.'});
          return;
        }
        if (this.workerInfo.workingDays > 0) {
          this.$alert({
            title: '삭제 불가',
            message: '해당 근로자는 출역 정보가 등록되어 있어<br/>삭제 할 수 없습니다.',
          });
          return;
        }
        if (!this.isFullTimeEmployee) {
          let param = {
            empNo: this.workerInfo.empNo,
            laborDs: this.workerInfo.laborDs,
          };
          const response = await selectContUserCount(param);
          if (response.status == 200) {
            if (response.data) {
              this.$alert({
                title: '삭제 불가',
                message: '해당 근로자는 근로 계약정보가 존재하므로<br/>삭제 할 수 없습니다.',
              });
              return;
            }
          }
        }
        let param = {
          empNo: this.workerInfo.empNo,
          phoFlNo: this.workerInfo.phoFlNo,
          sgntFlNo: this.workerInfo.sgntFlNo,
        };
        const response = await deleteWorkerInfo(param);
        if (response.status == 200) {
          this.callEvent({name: 'WorkerList_onSearch'});
        }
      }
    },
    getAddr(data) {
      this.setWorkerInfo({
        postNo: data.zipNo,
        adr1: `${data.roadAddrPart1} ${data.roadAddrPart2}`,
        adr2: data.addrDetail,
      });
    },
    onShowModal(type) {
      if (type == 'nation') {
        this.modalNm = 'nationListPopup';
      } else if (type == 'blnDepPart') {
        if (this.workerInfo.blnDepPart == $getConstants('BLN_DEP_PART_CD_COMPANY').code) {
          // 건설사 - 자사 || 운영사 || 공급사
          this.modalNm = 'deptListPopup';
        } else if (this.workerInfo.blnDepPart == $getConstants('BLN_DEP_PART_CD_CONSTRUCTION_SERVICE').code) {
          // 외주
          this.modalNm = 'subconListPopup';
        } else if (this.workerInfo.blnDepPart == $getConstants('BLN_DEP_PART_CD_LABOR').code) {
          // 노무팀
          this.modalNm = 'lbrcTeamListPopup';
        }
      } else if (type == 'jkd') {
        this.modalNm = 'lbrcCodeListPopup';
      }

      this.$modal.show(this.modalNm);
    },
    onHideModal() {
      if (this.modalNm == 'nationListPopup') {
        // 국가코드
        this.callEvent({
          name: 'nationList_callbackData',
          param: data => {
            this.setWorkerInfo({
              nationCd: data.code,
              nationNm: data.codeNm,
            });
            this.$modal.hide(this.modalNm);
          },
        });
      } else if (this.modalNm == 'deptListPopup') {
        // 건설사 - 자사 || 운영사 || 공급사
        let isLstNode = true;
        this.callEvent({
          name: 'DeptListPopup_callbackData',
          param: data => {
            if (data.fldrCd == 'OBS') {
              isLstNode = false;
              return;
            }
            this.setWorkerInfo({blnDepNo: data.fldrNo, blnDepNm: data.fldrNm});
            this.$modal.hide(this.modalNm);
          },
        });
        if (!isLstNode) return;
      } else if (this.modalNm == 'subconListPopup') {
        // 외주
        this.callEvent({
          name: 'SubconListPopup_callbackData',
          param: data => {
            this.setWorkerInfo({blnDepNo: data.cusCd, blnDepNm: data.cusNm});
            setTimeout(() => {
              this.callEvent({name: 'InputPrj_setSubconListCallbackData', param: data});
            }, 100);
            this.$modal.hide(this.modalNm);
          },
        });
      } else if (this.modalNm == 'lbrcTeamListPopup') {
        // 노무팀
        this.callEvent({
          name: 'LbrcTeamListPopup_callbackData',
          param: data => {
            this.setWorkerInfo({blnDepNo: data.labCode, blnDepNm: data.labName});
            this.$modal.hide(this.modalNm);
          },
        });
      } else if (this.modalNm == 'lbrcCodeListPopup') {
        // 노무직종
        this.callEvent({
          name: 'LbrcCodeList_callbackData',
          param: data => {
            this.setWorkerInfo({jkdNo: data.laborCode, jkdNm: data.itemName});
            this.$modal.hide(this.modalNm);
          },
        });
      }
    },
    onDuplicateRegiNo(event) {
      const name = event.target.name;
      const value = event.target.value;
      if (name == 'regiNo1') {
        if (value.length < 6) {
          this.$alert({title: '주민번호', message: '주민번호 앞자리는 6자리로 입력하여 주세요.'}, () => {
            event.target.value = '';
          });
          return;
        }
        this.setWorkerInfo({[name]: value});
      }
      if (name == 'regiNo2') {
        if (value.length < 7) {
          this.$alert({title: '주민번호', message: '주민번호 뒷자리는 7자리로 입력하여 주세요.'}, () => {
            event.target.value = '';
          });
          return;
        }
        this.setWorkerInfo({[name]: value});
      }
      if (name == 'passportNo') {
        if (value.length < 15) {
          return;
        }
        this.setWorkerInfo({passportNo: value});
      }

      if (this.workerInfo.useMst == $getConstants('USE_MST_Y').code) {
        this.regiNoDupChkComplete = true;
        return;
      }

      this.onSelectWorkerExist();
    },
    async onSelectWorkerExist() {
      const regiNo = String(this.workerInfo.regiNo);
      const passportNo = String(this.workerInfo.passportNo);
      if (regiNo.length != 13 && passportNo.length != 15) return;
      const searchGb = this.isKorean ? 'regiNo' : 'passportNo';
      const response = await selectWorkerInfoList({regiNo, passportNo, searchGb});
      if (response.status == 200) {
        if (response.data) {
          if (
            await this.$confirm({
              title: '기등록 근로자',
              message: '이미 등록되어 있는 근로자 입니다.<br/>새로운 사번을 부여하시겠습니까?',
            })
          ) {
            this.setWorkerInfo(response.data);
            this.setWorkerInfo({
              empNo: '',
              cud: 1,
              createNewEmpNo: true,
              canEnableLabtClose: true,
              labtClose: '',
            });
            this.regiNoDupChkComplete = true;
            this.workerInfo.canEnableLabtClose = true;
          } else {
            this.setWorkerInfo(response.data);
            this.regiNoDupChkComplete = false;
            this.workerInfo.canEnableLabtClose = false;
          }
        } else {
          this.setWorkerInfo({cud: 1});
          this.regiNoDupChkComplete = true;
        }
      }
    },
    async onDuplicateEmpNo() {
      if (this.workerInfo.empNo) {
        const empNo = String(this.workerInfo.empNo);
        const regType1 = /^[A-Za-z0-9+]{5,8}$/;
        if (!regType1.test(empNo)) {
          this.$alert({title: 'ID 중복확인', message: 'ID는 5~8자리의 영문 또는 숫자만 입력 가능합니다.'});
          return;
        }
        const response = await selectWorkerExistEmpNo({empNo});
        if (response.status == 200) {
          if (0 === response.data) {
            this.$alert({title: 'ID 사용가능', message: '등록 가능한 아이디입니다.'});
            this.setWorkerInfo({idDupChk: 1});
          } else {
            this.$alert(
              {title: 'ID 중복확인', message: '이미 사용중인 ID입니다. <br/> 새로운 ID를 입력하여 주세요.'},
              () => {
                this.setWorkerInfo({idDupChk: 2});
                this.$refs.empNo.$refs.input.focus();
              }
            );
            return;
          }
        }
      } else {
        this.$alert({title: 'ID 중복확인', message: 'ID를 입력해주세요.'});
        return;
      }
    },
    async onDeleteCompleteFile(key, flNo) {
      if (flNo == 0) {
        this.setWorkerInfo({[key]: flNo});
        let param = {
          empNo: this.workerInfo.empNo,
          phoFlNo: this.workerInfo.phoFlNo,
          sgntFlNo: this.workerInfo.sgntFlNo,
        };
        const response = await updateWorkerFlNo(param);
      }
    },
    afterActiveBottomTab(index) {
      this.activeTab = index == 0 ? 'InputPrj' : 'LicenseList';
      this.tabIndex = index;
    },
    bottomAdd() {
      this.callEvent({name: `${this.activeTab}_onAdd`});
    },
    bottomSave() {
      this.callEvent({name: `${this.activeTab}_onSave`});
    },
    bottomDelete() {
      this.callEvent({name: `${this.activeTab}_onDelete`});
    },
    systemUse() {
      // 정직 || 상용직
      if (
        this.workerInfo.laborDs == $getConstants('LABOR_DS1').code ||
        this.workerInfo.laborDs == $getConstants('LABOR_DS2').code
      ) {
        this.setWorkerInfo({useDs: $getConstants('USE_YN_Y').code});
      }
    },
  },
};
</script>

<style scoped></style>
