<template>
  <pmis-tab-box>
    <template #title>{{ isWrite ? '작성목록' : '계약목록' }}</template>
    <pmis-content-box>
      <template #header-left>
        <i-header-multi-box>
          <div>
            <label class="mr10">검색구분</label>
            <iui-select :items="searchItems" :value.sync="searchOptions.searchTextType" />
            <iui-text type="search" :value.sync="searchOptions.searchText" @enter="onSearch" />
            <iui-button value="검색" @click="onSearch" />
          </div>
          <div>
            <label class="mr10">{{ isWrite ? '작성일자' : '계약일자' }}</label>
            <iui-datepicker :value.sync="searchOptions.searchFrDt" @change="onSearch" :group="searchDateGroup.seq1" />
            <label>~</label>
            <iui-datepicker :value.sync="searchOptions.searchToDt" @change="onSearch" :group="searchDateGroup.seq2" />
            <span class="ml20" />
            <template v-if="!isComplete">
              <label class="mr10">진행상태</label>
              <iui-select
                defaultCd="S"
                :items="progressStItems"
                :value.sync="searchOptions.searchSelect2"
                @change="onSearch"
              />
            </template>
          </div>
        </i-header-multi-box>
      </template>
      <ib-sheet
        :uid="_uid"
        :options="sheetOpt"
        :loadSearchData="poList"
        @loadSheet="sheet = $event"
        :events="{
          onRenderFirstFinish: () => onSearch(focusKey),
          onSearchFinish: sheet_onSearchFinish,
          onClick: sheet_onClick,
        }"
      />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import sheetOpt from './sheetOptions/contractList.js';

export default {
  props: {
    process: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),

      focusKey: '',

      searchItems: [
        {text: '계약명', value: 'ContractNm'},
        {text: '협력사명', value: 'CustomerNm'},
        {text: '현장명', value: 'SpotDeptNm'},
      ],
      progressStItems: [], // 진행상태

      searchOptions: {
        searchTextType: 'ContractNm',
        searchSelect2: '',
        searchText: '',
        searchFrDt: '',
        searchToDt: '',
      },

      searchDateGroup: {
        seq1: {grpNm: 'searchDate', seq: 1},
        seq2: {grpNm: 'searchDate', seq: 2},
      },
    };
  },
  computed: {
    isWrite() {
      return this.process === 'write';
    },
    isComplete() {
      return this.process === 'complete';
    },
  },
  beforeCreate() {
    $mapGetters(this, ['poList', 'bidType']);
    $mapActions(this, ['searchPoList']);
  },
  async created() {
    this.addFuncSearch(this.onClick_cmnSearch);
    this.addEvent([{name: 'ContractList_Search', func: this.onSearch}]);

    const STATE = BIDDING_CONSTANTS.PROGRESS_STATE;
    const poStScope = {
      write: {from: STATE.PO_READY, to: STATE.PO_APPROVAL_COMPLETE},
      progress: {from: STATE.PO_SIGN_READY, to: STATE.PO_SIGN_COMPLETE},
      complete: {from: STATE.PO_COMPLETE, to: STATE.PO_COMPLETE},
    };

    // 완료된 계약에서는 진행상태 제거
    const from = poStScope[this.process].from;
    const to = poStScope[this.process].to;

    // 계약 진행상태 코드명
    this.progressStItems = (await $getCode($getConstants('BIDDING_ST').code))
      .filter(item => from <= item.code && item.code <= to)
      .map(item => ({text: item.codeNm, value: item.code}));
  },
  methods: {
    onClick_cmnSearch() {
      for (const key in this.searchOptions) {
        this.searchOptions[key] = '';
      }
      this.searchOptions.searchTextType = 'ContractNm';
      this.onSearch();
    },

    onSearch(focusKey = '') {
      this.focusKey = typeof focusKey === 'string' ? focusKey : '';
      this.searchPoList({orgCd: this.pgmCd, process: this.process, ...this.searchOptions});
    },
    setRowData(row) {
      if (row) {
        this.callEvent({name: 'PoContSeqList_onSearch', param: row.poNo});
      }
    },
    sheet_onSearchFinish(e) {
      if (e.sheet.getTotalRowCount()) {
        const focusRow = e.sheet.getDataRows().find(row => row.poNo === this.focusKey);
        if (focusRow === undefined) {
          this.setRowData(e.sheet.getFirstRow());
        } else {
          e.sheet.focus(focusRow);
          this.setRowData(focusRow);
        }
      } else {
        this.setRowData(undefined);
      }
      this.focusKey = '';
    },
    sheet_onClick(e) {
      if (e.row.Kind == 'Data') {
        this.setRowData(e.row);
      }
    },
  },
};
</script>

<style></style>
