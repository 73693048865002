const state = {
  searchInfo: {
    searchIoDate: '', // 검색 입고일자
    searchCustCode: '', //검색 거래처코드
    searchSelect: '', // 검색구분
    searchText: '', // 검색text
    searchFrDt: '', // 검색시작일
    searchToDt: '', // 검색종료일
    custCode: '', //거래처코드
    custName: '', //거래처명
    poNo: '', // 계약번호
    contSeq: '', // 계약차수
  },
  detailInfo: {
    pgmCd: '', // 회사코드
    prjCd: '', // 현장코드
    ioGb: '', // 입출고구분
    ioDate: '', // 일자
    seq: '', // 순번
    custName: '', // 거래처명
    custCode: '', // 거래처코드
    poNo: '', // 계약번호
    poNoSubc: '', // 계약번호(외주)
    matCode: '', // 자재코드
    qty: '', // 수량
    price: '', // 단가
    amt: '', // 금액
    closeYn: '', // 정산여부(구분)
    investMm: '', // 정산년월
    reqDegree: '', // 차수
  },
  monPjtCloseAt: 'Y', // 월 마감 상태
  isOrder: '', //발주 미발주 구분
};

const getters = {
  searchInfo: state => state.searchInfo,
  detailInfo: state => state.detailInfo,
  monPjtCloseAt: state => state.monPjtCloseAt,
  isOrder: state => state.isOrder,
};

const actions = {};

const mutations = {
  setSearchInfo(state, payload) {
    for (let key in state.searchInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = payload[key];
      }
    }
  },
  setMomPjtCloseAt(state, payload) {
    state.monPjtCloseAt = payload;
  },
  setIsOrder(state, payload) {
    state.isOrder = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
