export default that => {
  return {
    Cfg: {
      HeaderMerge: 3,
    },
    Def: {
      Row: {
        CanFocus: 0,
        NoColor: 0,
      },
    },
    Cols: [
      {
        Header: '자재단가',
        Name: 'matUcstName',
        Type: 'Text',
        Align: 'Center',
        RelWidth: 1,
        CanEdit: false,
      },
      {
        Header: '노무단가',
        Name: 'labUcstName',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CanEdit: false,
      },
      {
        Header: '장비단가',
        Name: 'equtUcstName',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CanEdit: false,
      },
      {
        Header: '경비단가',
        Name: 'oprUcstName',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CanEdit: false,
      },
      {Name: 'matUcstCode', Visible: false},
      {Name: 'labUcstCode', Visible: false},
      {Name: 'equtUcstCode', Visible: false},
      {Name: 'oprUcstCode', Visible: false},
    ],
  };
};
