<template>
  <pmis-content-box>
    <template v-slot:title>{{ title }}</template>
    <template v-slot:title-right>
      <iui-button class="btn" value="신규" @click="onAdd()" v-show="enable" />
      <iui-button class="btn" value="저장" @click="onSave()" v-if="companyInfo.pgmCd" v-show="enable" />
      <iui-button class="btn" value="삭제" @click="onDelete()" v-show="enable" />
    </template>
    <iui-container-new type="css-flex">
      <i-row :height="enable ? '150px' : `${height}px`">
        <i-col>
          <ib-sheet
            :uid="_uid"
            :options="Options"
            :loadSearchData="areaList"
            :events="{onRenderFirstFinish}"
            @loadSheet="loadSheet"
            :autoRender="false"
            :init="initSheet"
          />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/AreaInfoSheet.js';
import {selectAreaList, saveAreaList, deleteAreaList} from '../api/member.js';
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    enable: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '100',
    },
  },
  data() {
    return {
      Options: {},
      code100: [],
      initSheet: false,
    };
  },

  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'areaList']);
    $mapMutations(this, ['setAreaList']);
  },

  created() {
    this.addEvent([{name: 'areaInfo_getSaveJson', func: this.getSaveJson}]);
    this.createSheet();
  },

  mounted() {},

  methods: {
    async createSheet() {
      $getCode($getConstants('USE_YN').code).then(async response => {
        let nameInKey = {};
        nameInKey[$getConstants('USE_YN_Y').code] = '예';
        nameInKey[$getConstants('USE_YN_N').code] = '아니오';
        response.forEach(code => {
          this.code100.push({code: code.code, name: nameInKey[code.code]});
        });
        this.Options = this.enable ? $addColCheckbox(await options(this)) : await options(this);
        this.initSheet = true;
      });
    },

    onRenderFirstFinish() {
      this.onSearchAreaList();
    },
    onAdd() {
      let row = this.sheet.addRow();
      if (this.sheet.getTotalRowCount() == 1) row.areaFDs = $getConstants('AREA_F_DS_Y').code;
      else row.areaFDs = $getConstants('AREA_F_DS_N').code;
      this.sheet.refreshRow(row);
    },
    onAreaFdsChange(e) {
      this.sheet.getDataRows().forEach((el, index) => {
        if (e.row.HasIndex != index + 1) {
          el.areaFDs = $getConstants('AREA_F_DS_N').code;
          el.cud = 2;
          this.sheet.refreshRow(el);
        }
      });
    },
    async onSave() {
      if (this.sheet.getTotalRowCount() == 0) {
        this.$alert({title: `${this.area}정보 저장`, message: `저장할 정보가 존재하지 않습니다.`});
        return;
      }
      if (!this.sheet.hasChangedData()) {
        this.$alert({title: `${this.area}정보 저장`, message: '수정된 내용이 없습니다.'});
        return;
      }
      let saveJson = this.sheet.getSaveJson(0).data;
      let validItem = saveJson.find(item => !item.siteArea2);
      if (validItem !== undefined) {
        this.$alert({title: `${this.area}정보 저장`, message: `${this.area}을 선택 해야합니다.`});
        return;
      }

      saveJson.forEach(el => {
        if (!el.pgmCd) el.pgmCd = this.companyInfo.pgmCd;
      });
      let param = {areaList: $_statusToCud(saveJson)};
      const response = await saveAreaList(param);
      if (response.status == 200) {
        this.onSearchAreaList();
      }
    },
    async onDelete() {
      if (this.sheet.getTotalRowCount() == 0) {
        this.$alert({title: `${this.area}정보 삭제`, message: `삭제할 정보가 존재하지 않습니다.`});
        return;
      }
      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({title: `${this.area}정보 삭제`, message: '선택된 항목이 없습니다.'});
        return;
      }
      const areaList = checkedRows.map(item => {
        return item.seq;
      });
      if (await this.$confirm({title: `${this.area}정보 삭제`, message: '삭제하시겠습니까?'})) {
        const response = await deleteAreaList({areaList});
        if (response.status == 200) {
          this.onSearchAreaList();
        }
      }
    },
    getSaveJson(callback) {
      if (typeof callback == 'function') callback(this.sheet.getSaveJson().data);
    },
    async onSearchAreaList() {
      let param = {pgmCd: this.companyInfo.pgmCd};
      const response = await selectAreaList(param);
      if (response.status == 200) {
        this.setAreaList(response.data);
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },

  computed: {
    area() {
      return this.companyInfo.frmGb == $getConstants('FRM_GB_2').code ? '공사지역' : '납품지역';
    },
  },

  beforeDestroy() {
    this.sheet.dispose();
  },
};
</script>

<style></style>
