export default that => {
  const Cols = [
    {
      Header: '공종명',
      Name: 'mgName',
      Type: 'Text',
      RelWidth: 1,
      CanEdit: 0,
    },
    {
      Header: '내역코드',
      Name: 'itemSeq',
      Type: 'Text',
      Width: '70',
      CanEdit: 0,
    },
    {
      Header: '내역명',
      Name: 'itemName',
      Type: 'Text',
      RelWidth: 1,
      CanEdit: 0,
    },
    {
      Header: '규격',
      Name: 'ssize',
      Type: 'Text',
      RelWidth: 1,
      CanEdit: 0,
    },
    {
      Header: '수량',
      Name: 'qty',
      Extend: that.$ibsheetHelper.preset.quantity,
      Width: 80,
      CanEdit: 0,
    },
    {
      Header: '금액',
      Name: 'amt',
      Extend: that.$ibsheetHelper.preset.amount,
      RelWidth: 1,
      CanEdit: 0,
    },
    {Name: 'wcode', Visible: 0},
    {Name: 'headCode', Visible: 0},
    {Name: 'midCode', Visible: 0},
    {Name: 'unit', Visible: 0},
  ];
  const ColsPopup = [
    {
      Header: ['공종명', '공종명'],
      Name: 'mgName',
      Type: 'Text',
      RelWidth: 1,
      CanEdit: 0,
    },
    {
      Header: ['내역코드', '내역코드'],
      Name: 'itemSeq',
      Type: 'Text',
      Width: '60',
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['자원구분', '자원구분'],
      Name: 'costType',
      Type: 'Text',
      Align: 'Center',
      Width: '70',
      CanEdit: 0,
      Format: $getConstants('COST_TYPE').code,
    },
    {
      Header: ['실행코드', '실행코드'],
      Name: 'itemCode',
      Type: 'Text',
      RelWidth: 1,
      CanEdit: 0,
    },
    {
      Header: ['내역명', '내역명'],
      Name: 'itemName',
      Type: 'Text',
      RelWidth: 1,
      CanEdit: 0,
    },
    {
      Header: ['규격', '규격'],
      Name: 'ssize',
      Type: 'Text',
      RelWidth: 1,
      CanEdit: 0,
    },
    {
      Header: ['단위', '단위'],
      Name: 'unit',
      Type: 'Text',
      Width: '50',
      Align: 'Center',
      CanEdit: 0,
      Format: $getConstants('UNIT').code,
    },
    {
      Header: ['합계', '합계'],
      Name: 'amt',
      Type: 'Text',
      Extend: that.$ibsheetHelper.preset.amount,
      RelWidth: 1,
      CanEdit: 0,
    },
    {
      Header: ['수량', '수량'],
      Name: 'qty',
      Extend: that.$ibsheetHelper.preset.quantity,
      RelWidth: 1,
      CanEdit: 0,
    },
    {
      Header: ['재료비', {Value: '단가', Color: '#dff9fb'}],
      Name: 'matPrice',
      Extend: that.$ibsheetHelper.preset.unitAmount,
      RelWidth: 1,
      CanEdit: 0,
      Visible: 1,
    },
    {
      Header: ['재료비', {Value: '금액', Color: '#dff9fb'}],
      Name: 'matAmt',
      Extend: that.$ibsheetHelper.preset.amount,
      RelWidth: 1,
      CanEdit: 0,
      Visible: 1,
    },
    {
      Header: ['노무비', {Value: '단가', Color: '#dff9fb'}],
      Name: 'labPrice',
      Extend: that.$ibsheetHelper.preset.unitAmount,
      RelWidth: 1,
      CanEdit: 0,
      Visible: 1,
    },
    {
      Header: ['노무비', {Value: '금액', Color: '#dff9fb'}],
      Name: 'labAmt',
      Extend: that.$ibsheetHelper.preset.amount,
      RelWidth: 1,
      CanEdit: 0,
      Visible: 1,
    },
    {
      Header: ['장비비', {Value: '단가', Color: '#dff9fb'}],
      Name: 'equipPrice',
      Extend: that.$ibsheetHelper.preset.unitAmount,
      RelWidth: 1,
      CanEdit: 0,
      Visible: 1,
    },
    {
      Header: ['장비비', {Value: '금액', Color: '#dff9fb'}],
      Name: 'equipAmt',
      Extend: that.$ibsheetHelper.preset.amount,
      RelWidth: 1,
      CanEdit: 0,
      Visible: 1,
    },
    {
      Header: ['경비', {Value: '단가', Color: '#dff9fb'}],
      Name: 'oprPrice',
      Extend: that.$ibsheetHelper.preset.unitAmount,
      RelWidth: 1,
      CanEdit: 0,
      Visible: 1,
    },
    {
      Header: ['경비', {Value: '금액', Color: '#dff9fb'}],
      Name: 'oprAmt',
      Extend: that.$ibsheetHelper.preset.amount,
      RelWidth: 1,
      CanEdit: 0,
      Visible: 1,
    },
    {Name: 'wcode', Visible: 0},
    {Name: 'headCode', Visible: 0},
    {Name: 'midCode', Visible: 0},
  ];

  if (!that.visiblePrice) {
    const priceCols = [
      'amt',
      'matPrice',
      'matAmt',
      'labPrice',
      'labAmt',
      'equipPrice',
      'equipAmt',
      'oprPrice',
      'oprAmt',
    ];
    ColsPopup.forEach(col => {
      if (priceCols.includes(col.Name)) {
        col.Visible = 0;
      }
    });
  }

  return {
    Cfg: {
      CanEdit: 1,
      HeaderMerge: that.isPopup ? 6 : 0,
      HeaderCheck: 1,
      HeaderCheckMode: 1,
    },
    Cols: that.isPopup ? ColsPopup : Cols,
  };
};
