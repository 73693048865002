export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 0},
    Def: {Row: {NoColor: 2, CanFocus: 0}},
    Cols: [
      {
        Header: '자재코드',
        Name: 'itemCd',
        Type: 'Text',
        Align: 'Center',
        Width: 140,
        TextStyle: 5,
        TextColor: '#FF0000',
        Cursor: 'Pointer',
      },
      {Header: '명칭', Name: 'itemNm', Type: 'Text', RelWidth: 1, MinWidth: 160},
      {Header: '규격', Name: 'sizeNm', Type: 'Text', RelWidth: 1, MinWidth: 160},
      {Header: '단위', Name: 'unitNm', Type: 'Text', Align: 'Center', Width: 50},
      {Header: '금회요청수량', Name: 'qty', Extend: preset.quantity, Width: 100},
      {Header: '단가', Name: 'planUnitPrc', Extend: preset.unitAmount, Width: 100},
      {
        Header: '금액',
        Name: 'amt',
        Extend: preset.amount,
        Width: 120,
        Formula: fr => fr.Row['qty'] * fr.Row['planUnitPrc'],
      },
      {Header: '비고', Name: 'itemRmrk', Type: 'Text', RelWidth: 1, MinWidth: 80},

      {Name: 'unit', Visible: false},
      {Name: 'planQty', Visible: false},
      {Name: 'preQty', Visible: false},
      {Name: 'remQty', Visible: false},
    ],
  };
};
