export default that => {
  return {
    Cfg: {
      CanEdit: false,
      FitWidth: true,
      HeaderMerge: 6,
    },
    LeftCols: [
      {Name: 'materialType', Header: ['구분', '구분'], Width: 70, Type: 'Text'},
      {Name: 'matCode', Header: ['자재코드', '자재코드'], Width: 130, Type: 'Text', Visible: 0},
      {Name: 'matName', Header: ['자재명', '자재명'], RelWidth: 1, MinWidth: 250, Type: 'Text'},
      {Name: 'matSize', Header: ['규격', '규격'], Width: 120, Type: 'Text'},
      {
        Name: 'matUnit',
        Header: ['단위', '단위'],
        Width: 100,
        Type: 'Text',
        Format: $getConstants('UNIT').code,
        FormulaRow: '합계',
      },
    ],
    Cols: [
      {
        Name: 'dayQty',
        Header: ['금일투입', '수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 90,
      },
      {
        Name: 'dayAmt',
        Header: ['금일투입', '금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 90,
        FormulaRow: 'Sum',
      },
      {
        Name: 'monthQty',
        Header: ['금월투입', '수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 90,
      },
      {
        Name: 'monthAmt',
        Header: ['금월투입', '금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 90,
        FormulaRow: 'Sum',
      },
      {
        Name: 'totQty',
        Header: ['누계투입', '수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 90,
      },
      {
        Name: 'totAmt',
        Header: ['누계투입', '금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 90,
        FormulaRow: 'Sum',
      },
      {Name: 'subCodeName', Header: ['투입외주', '투입외주'], Width: 90, Type: 'Text'},
    ],
  };
};
