<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="600px">
          <ContractList process="write" />
        </i-col>
        <i-spacer />
        <i-col min-width="1100px">
          <ContractDetail />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from '../store/po.js';

import ContractList from '@/view/bidding/po/common/components/ContractList.vue';
import ContractDetail from '../components/ContractDetail.vue';

export default {
  components: {ContractList, ContractDetail},
  beforeCreate() {
    $init(this, store);
  },
};
</script>

<style></style>
