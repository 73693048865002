<template>
  <div class="contents">
    <div class="join_step03">
      <span class="blind">step03 회원정보 작성</span>
    </div>
    <form onsubmit="return false;">
      <JoinInfo :enable="true" :frmGbCd="frmGb" ref="join" />
      <div class="btn_area">
        <button type="button" class="btn_default" @click="prevStep">취소</button>
        <input type="button" class="btn_confirm ml10" value="가입하기" @click="join" />
      </div>
    </form>
  </div>
  <!-- contents end -->
</template>

<script>
import JoinInfo from '@/view/member/components/step/JoinInfo.vue';

export default {
  components: {
    JoinInfo,
  },
  props: {
    frmGb: {
      type: [String],
    },
  },
  methods: {
    join() {
      this.$refs.join.onSaveCompany();
    },
    prevStep() {
      this.$refs.join.initCompanyInfo();
      this.$parent.nextStep('02');
    },
    nextStep() {
      this.$parent.nextStep('04');
    },
  },
};
</script>

<style scoped></style>
