export default that => {
  return {
    Cfg: {
      CanEdit: 1,
      HeaderMerge: 6,
    },
    Cols: [
      {
        Header: ['내역\n번호', '내역\n번호'],
        Name: 'itemSeq',
        Type: 'Text',
        Align: 'Center',
        Width: 60,
        CanEdit: 0,
      },
      {
        Header: ['자원\n구분', '자원\n구분'],
        Name: 'costType',
        Type: 'Text',
        Align: 'Center',
        Width: 60,
        CanEdit: 0,
      },
      {
        Header: ['코드', '코드'],
        Name: 'itemCode2',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: ['명칭', '명칭'],
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
        Align: 'Center',
      },
      {
        Header: ['규격', '규격'],
        Name: 'ssize',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
        Align: 'Center',
      },
      {
        Header: ['단위', '단위'],
        Name: 'unit',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
        Align: 'Center',
        Format: $getConstants('UNIT').code,
      },
      {
        Header: ['수량', '수량'],
        Name: 'qty',
        Extend: that.$ibsheetHelper.preset.quantity,
        RelWidth: 1,
        CanEditFormula: function(fr) {
          return !fr.Row.itemCode2 || !!fr.Row.formula ? 0 : 1;
        },
        OnChange: e => {
          if (e.row.costType == 'M') {
            e.sheet.setValue(e.row, 'matAmt', e.row.qty * e.row.matPrice, 1);
          } else if (e.row.costType == 'L') {
            e.sheet.setValue(e.row, 'labAmt', e.row.qty * e.row.labPrice, 1);
          } else if (e.row.costType == 'E' || e.row.costType == 'A') {
            e.sheet.setValue(e.row, 'equipAmt', e.row.qty * e.row.equipPrice, 1);
          } else if (e.row.costType == 'I') {
            e.sheet.setValue(e.row, 'matAmt', e.row.qty * e.row.matPrice, 1);
            e.sheet.setValue(e.row, 'labAmt', e.row.qty * e.row.labPrice, 1);
            e.sheet.setValue(e.row, 'equipAmt', e.row.qty * e.row.equipPrice, 1);
          }
          e.sheet.setValue(e.row, 'totalAmt', e.row.qty * e.row.totalPrice, 1);
          if (e.row.itemCode2 && !e.row.formula) {
            that.onFormula();
          }
        },
      },
      {
        Header: ['재료비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'matPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        RelWidth: 1,
        CanEditFormula: function(fr) {
          return fr.Row['formula'] ? 0 : fr.Row['costType'] == $getConstants('COST_TYPE_M').code ? 1 : 0;
        },
        OnChange: e => {
          e.sheet.setValue(e.row, 'matAmt', e.row.qty * e.row.matPrice, 1);
          e.sheet.setValue(e.row, 'totalPrice', e.row.matPrice + e.row.labPrice + e.row.equipPrice, 1);
          e.sheet.setValue(e.row, 'totalAmt', e.row.qty * e.row.totalPrice, 1);
          if (e.row.itemCode2 && !e.row.formula) {
            that.onFormula();
          }
        },
      },
      {
        Header: ['재료비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'matAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: ['노무비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'labPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        RelWidth: 1,
        CanEditFormula: function(fr) {
          return fr.Row['formula'] ? 0 : fr.Row['costType'] == $getConstants('COST_TYPE_L').code ? 1 : 0;
        },
        OnChange: e => {
          e.sheet.setValue(e.row, 'labAmt', e.row.qty * e.row.labPrice, 1);
          e.sheet.setValue(e.row, 'totalPrice', e.row.matPrice + e.row.labPrice + e.row.equipPrice, 1);
          e.sheet.setValue(e.row, 'totalAmt', e.row.qty * e.row.totalPrice, 1);
          if (e.row.itemCode2 && !e.row.formula) {
            that.onFormula();
          }
        },
      },
      {
        Header: ['노무비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'labAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: ['경비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'equipPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        RelWidth: 1,
        CanEditFormula: function(fr) {
          return fr.Row['formula']
            ? 0
            : fr.Row['costType'] == $getConstants('COST_TYPE_E').code ||
              fr.Row['costType'] == $getConstants('COST_TYPE_A').code
            ? 1
            : 0;
        },
        OnChange: e => {
          e.sheet.setValue(e.row, 'equipAmt', e.row.qty * e.row.equipPrice, 1);
          e.sheet.setValue(e.row, 'totalPrice', e.row.matPrice + e.row.labPrice + e.row.equipPrice, 1);
          e.sheet.setValue(e.row, 'totalAmt', e.row.qty * e.row.totalPrice, 1);
          if (e.row.itemCode2 && !e.row.formula) {
            that.onFormula();
          }
        },
      },
      {
        Header: ['경비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'equipAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: ['합계', {Value: '단가', Color: '#dff9fb'}],
        Name: 'totalPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: ['합계', {Value: '금액', Color: '#dff9fb'}],
        Name: 'totalAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Name: 'itemCode1',
        Visible: 0,
      },
      {
        Name: 'formula',
        Visible: 0,
      },
    ],
  };
};
