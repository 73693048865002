export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 0},
    Def: {
      Row: {NoColor: 2, CanFocus: 0},
    },
    Cols: [
      {
        Header: '명칭',
        Name: 'itemNm',
        Type: 'Text',
        RelWidth: 1.5,
        MinWidth: 180,
        TextStyle: 5,
        TextColor: '#FF0000',
        Cursor: 'Pointer',
      },
      {Header: '규격', Name: 'sizeNm', Type: 'Text', RelWidth: 1.5, MinWidth: 180},
      {Header: '단위', Name: 'unit', Type: 'Text', Align: 'center', Width: 80, Format: $getConstants('UNIT').code},
      {Header: '수량', Name: 'qty', Width: 130, Extend: preset.quantity},
      {Header: '비고', Name: 'itemRmrk', Type: 'Text', Width: 230},
      {Header: '단가', Name: 'unitPrc', RelWidth: 1, MinWidth: 150, Visible: false, Extend: preset.unitAmount},
      {
        Header: '금액',
        Name: 'amt',
        RelWidth: 1,
        MinWidth: 150,
        Visible: false,
        Extend: preset.amount,
        Formula: fr => fr.Row.qty * fr.Row.unitPrc,
      },
      {Name: 'itemCd', Visible: false},
      {Name: 'unit', Visible: false},
    ],
  };
};
