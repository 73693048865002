<template>
  <iui-portlet-container type="multi" :comps="comps" :activeTab.sync="tabIndex">
    <template #title>{{ titleNm }}</template>
    <template #link>
      <a href="#self" class="goto_top_link" @click="goShortCut">
        <span class="blind">바로가기</span>
      </a>
    </template>
    <template #content>
      <notice-board v-show="tabIndex == 0" />
      <mat-bid-notice v-show="tabIndex == 1" />
      <sub-bid-notice v-show="tabIndex == 2" />
    </template>
  </iui-portlet-container>
</template>

<script>
import PortletMainMixin from '@/components/portlet/mixin/portletMainMixin.js';
export default {
  mixins: [PortletMainMixin],
  data() {
    return {
      comps: [
        {tabNm: '공지사항', compNm: 'notice-board', shortCut: 'NOTICE'},
        {tabNm: '자재입찰공고', compNm: 'mat-bid-notice', shortCut: 'RFQ_MAT_NOTICE'},
        {tabNm: '외주입찰공고', compNm: 'sub-bid-notice', shortCut: 'RFQ_SUB_NOTICE'},
      ],
    };
  },
  methods: {
    goShortCut() {
      this.goMenu(this.comps[this.tabIndex].shortCut);
    },
  },
};
</script>

<style></style>
