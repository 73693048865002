export default {
  props: {
    unchkedCnt: {
      type: Number,
    },
  },
  data() {
    return {
      tblWidth: 0,
    };
  },
  created() {
    window.addEventListener('resize', this.setWidth);
  },
  mounted() {
    this.setWidth();
    this.onSearch();
  },
  activated() {
    this.setWidth();
    this.onSearch();
  },
  methods: {
    setWidth() {},
    onSearch() {},
  },
};
