import padStart from 'lodash/padStart';

const state = {
  searchInfo: {
    searchText: '',
    labtClose: '',
    searchGubun2: '',
    searchGubun3: '',
  },

  rowInfo: {
    empNo: '',
    laborDs: '',
    makeDate: '',
    sendDate: '',
    contSignDate: '',
    compSignDate: '',
  },

  laborInfo: {
    empNo: '',
    laborDs: '',

    name: '',
    jkdNm: '',
    blnDepPartNm: '',

    contStDate: '', // 계약시작일
    contEdDate: '', // 계약종료일

    ndayStTime: '', // 평일근로시작시간
    ndayEnTime: '', // 평일근로종료시간
    nrst1StTime: '', // 평일휴게1시작시간
    nrst1EdTime: '', // 평일휴게1종료시간
    nrst2StTime: '', // 평일휴게2시작시간
    nrst2EdTime: '', // 평일휴게2종료시간

    sdayStTime: '', // 토요일근로시작시간
    sdayEnTime: '', // 토요일근로종료시간
    srst1StTime: '', // 토요일휴게1시작시간
    srst1EdTime: '', // 토요일휴게1종료시간
    srst2StTime: '', // 토요일휴게2시작시간
    srst2EdTime: '', // 토요일휴게2종료시간

    wworkDay: '', // 주간근무일수
    wrstWeekday: '', // 주간휴무요일
    salary: '', // 임금
    bonusGb: '', // 상여금유무
    bonus: '', // 상여금

    overTimePay: '', // 시간외수당
    overTimeRt: '',
    nightTimePay: '', // 야간수당
    nightTimeRt: '',
    holidayTimePay: '', // 휴일수당
    holidayTimeRt: '',
    totPay: '', // 제수당

    salaryDate: '', // 월지급일자
    salaryMethod: '', // 지급방법구분
    priceLAmt: '', //노무단가

    pensionDs: '', // 국민연금적용제외여부 ('000001' : 제외,  '' : 적용)
    healthInsurDs: '', // 건강보험적용제외여부
    employInsurDs: '', // 고용보험적용제외여부
    disasterInsurDs: '', // 산재보험적용제외여부

    processStat: '', // 진행상태구분

    detailWork: '', // 업무내용
    etcGb: '', // 기타급여유무
    etcPay1Nm: '', // 기타급여1명칭
    etcPay2Nm: '', // 기타급여2명칭
    etcPay3Nm: '', // 기타급여3명칭
    etcPay4Nm: '', // 기타급여4명칭
    etcPay1: '', // 기타급여1
    etcPay2: '', // 기타급여2
    etcPay3: '', // 기타급여3
    etcPay4: '', // 기타급여4

    flNo: 0, // 계약서원본파일번호
  },

  timeInfo: {
    ndayStTime: '', // 평일근로시작시간
    ndayEnTime: '', // 평일근로종료시간
    nrst1StTime: '', // 평일휴게1시작시간
    nrst1EdTime: '', // 평일휴게1종료시간
    nrst2StTime: '', // 평일휴게2시작시간
    nrst2EdTime: '', // 평일휴게2종료시간
    sdayStTime: '', // 토요일근로시작시간
    sdayEnTime: '', // 토요일근로종료시간
    srst1StTime: '', // 토요일휴게1시작시간
    srst1EdTime: '', // 토요일휴게1종료시간
    srst2StTime: '', // 토요일휴게2시작시간
    srst2EdTime: '', // 토요일휴게2종료시간
  },
};

const getters = {
  searchInfo: state => state.searchInfo,
  rowInfo: state => state.rowInfo,
  laborInfo: state => state.laborInfo,
  timeInfo: state => state.timeInfo,
  isFixedTerm: state => state.rowInfo.laborDs === $getConstants('LABOR_DS2').code, // 상용직
  isDaily: state => state.rowInfo.laborDs === $getConstants('LABOR_DS3').code, // 일용직
  isNew: state => !!state.laborInfo.empNo && !state.laborInfo.processStat, // 신규

  isProcessWritten: state => state.laborInfo.processStat === $getConstants('PROCESS_GB_WRITING').code, // 작성완료
  isProcessSended: state => state.laborInfo.processStat === $getConstants('PROCESS_GB_SEND').code, // 송부완료
  isProcessWorkerSigned: state => state.laborInfo.processStat === $getConstants('PROCESS_GB_WORKER_SIGNED').code, // 근로자서명완료
  isProcessCompanySigned: state => state.laborInfo.processStat === $getConstants('PROCESS_GB_COMPANY_SIGNED').code, // 회사서명완료

  isBeforeWrite: state => state.laborInfo.processStat === null, //미작성
  isAfterWritten: state => state.laborInfo.processStat > $getConstants('PROCESS_GB_WRITING').code, // 작성완료 이후
};

const mutations = {
  setSearchInfo(state, payload) {
    for (let key in state.searchInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  initSearchInfo(state) {
    for (let key in state.searchInfo) {
      state.searchInfo[key] = '';
    }
  },
  setRowInfo(state, payload) {
    for (let key in state.rowInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.rowInfo[key] = payload[key];
      }
    }
  },
  initRowInfo(state) {
    for (let key in state.rowInfo) {
      state.rowInfo[key] = '';
    }
  },
  setLaborInfo(state, payload) {
    for (let key in state.laborInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.laborInfo[key] = payload[key];
      }
    }
  },
  initLaborInfo(state) {
    for (let key in state.laborInfo) {
      if (key === 'flNo') {
        state.laborInfo[key] = 0;
      } else if (['pensionDs', 'healthInsurDs', 'employInsurDs', 'disasterInsurDs'].includes(key)) {
        if (key == 'pensionDs') {
          state.laborInfo[key] = $getConstants('PENSION_DS').code;
        } else if (key == 'healthInsurDs') {
          state.laborInfo[key] = $getConstants('HEALTH_INSUR_DS').code;
        } else if (key == 'employInsurDs') {
          state.laborInfo[key] = $getConstants('EMPLOY_INSUR_DS').code;
        } else if (key == 'disasterInsurDs') {
          state.laborInfo[key] = $getConstants('DISASTER_INSUR_DS').code;
        }
      } else {
        state.laborInfo[key] = '';
      }
    }
  },
  setTimeInfo(state, payload) {
    for (let key in state.timeInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.timeInfo[key] = payload[key];
      }
    }
  },
  initTimeInfo(state) {
    for (let key in state.timeInfo) {
      state.timeInfo[key] = '';
    }
  },
};

const actions = {
  setLaborTime(context, payload) {
    context.commit('setTimeInfo', payload);
    context.commit('setLaborInfo', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
