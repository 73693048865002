<template>
  <pmis-content-box>
    <iui-radio-group :items="radio" :value="infoIndex" @change="onChange"> </iui-radio-group>
  </pmis-content-box>
</template>
<script>
export default {
  props: {
    uid: {
      type: Number,
    },
    workerInfoList: {
      type: Array,
    },
  },
  data() {
    return {
      radio: [],
      infoIndex: 0,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['workerInfo']);
    $mapMutations(this, ['setWorkerInfo']);
  },
  created() {
    this.addEvent([{name: 'WorkerInfoPopup_callbackData', func: this.callbackData}]);
    let index = 0;
    this.workerInfoList.forEach(el => {
      this.radio.push({
        label: el.pgmCd,
        value: index++,
        isChecked: index === 0 ? true : false,
      });
    });
  },
  methods: {
    onChange(e) {
      this.infoIndex = e.target.value;
    },
    callbackData(callbackFunction) {
      if (typeof callbackFunction == 'function') {
        callbackFunction(this.setWorkerInfo(this.workerInfoList[this.infoIndex]));
      }
    },
  },
};
</script>
<style></style>
