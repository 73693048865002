<template>
  <pmis-page
    ref="page"
    :search-company-visible="false"
    :search-project-visible="false"
    @search-click="onSearch"
    @new-click="onAdd"
    @save-click="onSave"
    @delete-click="onDel"
  >
    <pmis-tab-box no-border-box>
      <template v-slot:title>분류목록</template>
      <ib-sheet
        :uid="_uid"
        :options="Options"
        :loadSearchData="loadSearchData"
        @loadSheet="loadSheet"
        :events="{onSearchFinish}"
      />
    </pmis-tab-box>
  </pmis-page>
</template>

<script>
import options from './stdClRegistSheet.js';
import {selectStdClList, saveStdClInfo, deleteStdClInfo} from './api/baseStdClRegist.js';
export default {
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this)),
      loadSearchData: [],
      focusKeyCode: '',
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    async onSearch() {
      let response = await selectStdClList();
      this.loadSearchData = response.data;
    },
    onSearchFinish() {
      if (0 < this.sheet.getTotalRowCount()) {
        for (let row of this.sheet.getDataRows()) {
          if (row.basicDataYn == 'Y') {
            this.sheet.setAttribute(row, 'chk', 'CanEdit', '0', 1);
            this.sheet.setAttribute(row, 'csNm', 'CanEdit', '0', 1);
          }
        }

        if (this.focusKeyCode !== '') {
          let focusRow = this.sheet.getDataRows().find(row => this.focusKeyCode === row.csNo);
          this.sheet.focus(focusRow);
          this.focusKeyCode = '';
        }
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onAdd() {
      this.sheet.addRow();
    },
    async onSave() {
      if (!this.sheet.hasChangedData()) {
        this.$alert({title: '수정사항 없음', message: '수정된 내용이 없습니다.'});
        return;
      }

      for (let row of this.sheet.getRowsByStatus('Added,Changed')) {
        if (!row.Deleted && String(row.csNm).trim() == '') {
          this.$alert({title: '명칭 없음', message: '명칭은 필수입력 항목입니다.'});
          return;
        }
      }

      const param = {
        stdClList: $_statusToCud(this.sheet.getSaveJson().data),
      };
      const response = await saveStdClInfo(param);
      if (response.data) {
        this.focusKeyCode = response.data;
        this.onSearch();
      }
    },
    async onDel() {
      let checkedRow = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRow.length) {
        this.$alert({title: '선택된 항목 없음', message: '선택된 항목이 없습니다.'});
        return;
      }
      if (!(await this.$confirm({title: '표준분류 삭제', message: '삭제하시겠습니까?'}))) {
        return;
      }
      let csNoList = [];
      checkedRow.map(item => {
        if (item.csNo) {
          csNoList.push(item.csNo);
        }
      });
      const param = {
        csNoList,
      };
      let response = await deleteStdClInfo(param);
      if (response.data) {
        this.onSearch();
      }
    },
  },
};
</script>
