<template>
  <iui-container-new type="css-flex">
    <i-row height="90px">
      <i-col>
        <pmis-content-box>
          <template #title>{{ poOrOrder }}서 반송정보</template>
          <iui-container-new type="table" theme="bullet">
            <colgroup>
              <col width="80px" />
              <col width="100px" />
              <col width="10px" />
              <col width="80px" />
              <col />
            </colgroup>
            <i-row>
              <i-col-header>{{ poOrOrder }}번호</i-col-header>
              <i-col>
                <iui-text :value="info.poNo2" :enable="false" width="100px" />
              </i-col>
              <i-spacer />
              <i-col-header>{{ poOrOrder }}명</i-col-header>
              <i-col>
                <iui-text :value="info.ttl" :enable="false" />
              </i-col>
            </i-row>
            <i-spacer />
            <i-row>
              <i-col-header>반송일자</i-col-header>
              <i-col>
                <iui-text :value="rejectInfo.rgsDttm" :enable="false" width="100px" />
              </i-col>
              <i-spacer />
              <i-col-header>반송담당자</i-col-header>
              <i-col>
                <iui-text :value="rejectInfo.rgprNm" :enable="false" width="100px" />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box>
          <template #title>반송사유</template>
          <iui-text type="multi" :value="rejectInfo.reason" :enable="false" />
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import {selectPoRejectInfo} from '@/view/bidding/po/mat/api/po.js';
import {selectSubPoRejectInfo} from '@/view/bidding/po/sub/api/subPo.js';
export default {
  props: {
    isOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rejectInfo: {
        seq: '',
        type: '',
        reason: '',
        rgprId: '',
        rgprNm: '',
        rgsDttm: '',
      },
      info: {},
    };
  },
  computed: {
    poOrOrder() {
      return this.isOrder ? '발주' : '계약';
    },
  },
  beforeCreate() {
    $mapGetters(this, ['bidType', 'poInfo']);
  },
  created() {
    if (this.isOrder) {
      this.info = this.$store.getters[this.$store.getters.currentUrl + '/orderInfo'];
    } else {
      this.info = this.poInfo;
    }
    this.onSearch();
  },
  methods: {
    onSearch() {
      const selectPoRejectInfoApi = {mat: selectPoRejectInfo, sub: selectSubPoRejectInfo};

      selectPoRejectInfoApi[this.bidType](this.info.poNo, this.info.contSeq).then(response => {
        if (response.status === 200) {
          for (const key in this.rejectInfo) {
            if (response.data.hasOwnProperty(key)) {
              this.rejectInfo[key] = response.data[key];
            }
          }
        }
      });
    },
  },
};
</script>

<style></style>
