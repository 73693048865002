<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-radio-group :items="searchPublicItems" :value="searchPublic" @change="onChangePublic" />
    </template>
    <template v-slot:header-right>
      <iui-button value="회수" @click="onRetrieve" :enable="!!retrieveList.length" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onAfterClick: onAfterClick}"
    />
  </pmis-content-box>
</template>

<script>
import {selectDocPublicList, saveRetrieve} from '../api/appDoc.js';
import options from '../components/sheetOption/DocPublicInfoPopSheet.js';
export default {
  props: {
    sncNo: {
      type: Number,
    },
  },
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      searchPublicItems: [
        {label: '전체', value: ''},
        {label: '공람완료', value: 'publicComplete'},
        {label: '미완료', value: 'publicIncomplete'},
      ],
      searchPublic: '',
      retrieveList: [],
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onChangePublic(e) {
      this.searchPublic = e.target.value;
      this.onSearch();
    },
    async onRetrieve() {
      if (!(await this.$confirm({title: '회수', message: '선택된 공람자를 회수 하시겠습니까?'}))) {
        return;
      }
      const param = {sncNo: this.sncNo, retrieveList: this.retrieveList};
      const response = await saveRetrieve(param);
      if (response.data) {
        this.onSearch();
      }
    },
    async onAfterClick(e) {
      if (e.col === 'chk') {
        this.retrieveList = this.sheet.getRowsByChecked('chk').map(row => ({empNo: row.empNo, depCd: row.blnDepNo}));
      }
    },
    async onSearch() {
      const param = {
        sncNo: this.sncNo,
        docInfo: true,
      };
      param[this.searchPublic] = true;
      const response = await selectDocPublicList(param);
      this.loadSearchData = response.data.map((item, index) => ({...item, seq: index + 1}));
    },
  },
};
</script>

<style></style>
