<template>
  <span class="bsn-box">
    <iui-text
      regExp="[^0-9]"
      align="center"
      width="40px"
      max-length="3"
      :value.sync="internalValue1"
      @change="onChange1"
      @keyup="onKeyup1"
      :enable="enable"
      :required="required"
      :error-message="{title: '사업자번호', message: '사업자번호를 입력해야 합니다.'}"
    />
    <div>-</div>
    <iui-text
      ref="bnsNo2"
      regExp="[^0-9]"
      align="center"
      width="35px"
      max-length="2"
      :value.sync="internalValue2"
      @change="onChange2"
      @keyup="onKeyup2"
      :enable="enable"
      :required="required"
      :error-message="{title: '사업자번호', message: '사업자번호를 입력해야 합니다.'}"
    />
    <div>-</div>
    <iui-text
      ref="bnsNo3"
      regExp="[^0-9]"
      align="center"
      width="50px"
      max-length="5"
      :value.sync="internalValue3"
      @change="onChange3"
      :enable="enable"
      :required="required"
      :error-message="{title: '사업자번호', message: '사업자번호를 입력해야 합니다.'}"
    />
  </span>
</template>

<script>
export default {
  props: {
    value1: {
      type: [String, Number],
    },
    value2: {
      type: [String, Number],
    },
    value3: {
      type: [String, Number],
    },
    enable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: [Boolean, String, Number],
    },
  },
  data() {
    return {
      internalValue1: this.value1,
      internalValue2: this.value2,
      internalValue3: this.value3,
    };
  },
  watch: {
    value1(v) {
      this.internalValue1 = v;
    },
    value2(v) {
      this.internalValue2 = v;
    },
    value3(v) {
      this.internalValue3 = v;
    },
    internalValue1(newValue) {
      this.$emit('update:value1', newValue);
    },
    internalValue2(newValue) {
      this.$emit('update:value2', newValue);
    },
    internalValue3(newValue) {
      this.$emit('update:value3', newValue);
    },
  },
  methods: {
    onKeyup1(e) {
      if (e.target.value.length === 3) {
        this.$refs.bnsNo2.$refs.input.focus();
      }
    },
    onKeyup2(e) {
      if (e.target.value.length === 2) {
        this.$refs.bnsNo3.$refs.input.focus();
      }
    },
    onChange1(e) {
      this.$emit('change1', e);
    },
    onChange2(e) {
      this.$emit('change2', e);
    },
    onChange3(e) {
      this.$emit('change3', e);
    },
  },
};
</script>

<style scoped>
.bsn-box {
  display: flex;
  align-items: center;
}
.bsn-box > * {
  margin-right: 5px;
}
</style>
