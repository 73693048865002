<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col>
        <pmis-content-box>
          <template v-slot:header-left>
            <iui-datepicker type="month" label="정산년월" :value="investMm" :enable="false">
              <i slot="icon-calendar"></i>
            </iui-datepicker>
            <iui-text label="차수" width="80px" :enable="false" :value="reqDegree" />
            <span class="ml5" />
            <iui-datepicker
              :label="dateLabelObj[costType]"
              suffix="~"
              :value.sync="repayfrDt"
              :group="{grpNm: 'inputDate', seq: 1}"
            />
            <iui-datepicker :value.sync="repaytoDt" :group="{grpNm: 'inputDate', seq: 2}" />
            <iui-button value="검색" @click="onSearch" />
          </template>
          <pmis-tab-box>
            <template v-slot:title>
              거래처목록
            </template>
            <AnyTimeRegisterPopSubMat
              v-if="costType === 'mat'"
              :investMm.sync="investMm"
              :reqDegree.sync="reqDegree"
              :repayfrDt.sync="repayfrDt"
              :repaytoDt.sync="repaytoDt"
              :search.sync="search"
            />
            <AnyTimeRegisterPopSubLab
              v-if="costType === 'lab'"
              :investMm.sync="investMm"
              :reqDegree.sync="reqDegree"
              :repayfrDt.sync="repayfrDt"
              :repaytoDt.sync="repaytoDt"
              :search.sync="search"
            />
            <AnyTimeRegisterPopSubEqut
              v-if="costType === 'equip'"
              :investMm.sync="investMm"
              :reqDegree.sync="reqDegree"
              :repayfrDt.sync="repayfrDt"
              :repaytoDt.sync="repaytoDt"
              :search.sync="search"
            />
            <AnyTimeRegisterPopSubSub
              v-if="costType === 'sub'"
              :investMm.sync="investMm"
              :reqDegree.sync="reqDegree"
              :repayfrDt.sync="repayfrDt"
              :repaytoDt.sync="repaytoDt"
              :search.sync="search"
            />
          </pmis-tab-box>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import AnyTimeRegisterPopSubMat from './AnyTimeRegisterPopSubMat.vue';
import AnyTimeRegisterPopSubLab from './AnyTimeRegisterPopSubLab.vue';
import AnyTimeRegisterPopSubEqut from './AnyTimeRegisterPopSubEqut.vue';
import AnyTimeRegisterPopSubSub from './AnyTimeRegisterPopSubSub.vue';
export default {
  components: {
    AnyTimeRegisterPopSubMat,
    AnyTimeRegisterPopSubLab,
    AnyTimeRegisterPopSubEqut,
    AnyTimeRegisterPopSubSub,
  },
  props: {
    costType: {
      type: String,
    },
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      investMm: '',
      reqDegree: '',
      repayfrDt: '',
      repaytoDt: '',
      search: false,
      dateLabelObj: {mat: '입고기간', lab: '출역기간', equip: '가동기간', sub: '작업기간'},
    };
  },
  created() {},
  methods: {
    onSearch() {
      this.search = true;
    },
  },
};
</script>

<style></style>
