<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row min-height="200px">
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>기성내역</template>
            <pmis-content-box>
              <template v-slot:header-left>
                <label>기성횟수</label>
                <iui-text type="search" :value.sync="reqCnt" width="40px" :enable="false" align="right"></iui-text>
                <i class="prev-arrow" @click="onChangeReqCnt('PREV')" />
                <i class="post-arrow" @click="onChangeReqCnt('NEXT')" />
                <label>기성년월</label>
                <iui-text :value.sync="reqYm" width="80px" :enable="false"></iui-text>
              </template>
              <ib-sheet
                :uid="_uid"
                :options="Options"
                :loadSearchData="loadSearchData"
                :events="{
                  onRenderFirstFinish,
                  onSearchFinish,
                }"
                @loadSheet="loadSheet"
              />
            </pmis-content-box>
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import PrjList from '@/view/Project/components/PrjList.vue';
import options from './sheetOption/pxcondHghWtnncInquireSheet.js';
import {selectDetailList, selectList} from '../api/pxcondRqestManage.js';

export default {
  components: {
    PrjList,
  },
  data() {
    return {
      loadSearchData: [],
      Options: options(this),
      reqList: [],
      reqCnt: '',
      reqYm: '',
      nowCnt: '',
    };
  },
  created() {
    this.addFuncPrj(this.onSearch);
  },
  methods: {
    onChangeReqCnt(e) {
      let cha = e == 'PREV' ? -1 : 1;
      if (this.nowCnt + cha > 0 && this.nowCnt + cha < this.reqList.length + 1) {
        this.nowCnt = this.nowCnt + cha;
        this.reqCnt = this.reqList[this.nowCnt - 1].reqCnt;
        this.reqYm = dateFormat(this.reqList[this.nowCnt - 1].reqYm).slice(0, 7);
        this.onSearchDetail();
      }
    },
    async onSearch() {
      this.reqList = [];
      const param = {};
      const response = await selectList(param);
      response.data.forEach(e => {
        this.reqList.push({reqCnt: e.reqCnt, reqYm: e.reqYm});
      });
      this.nowCnt = this.reqList.length;
      if (this.reqList.length) {
        this.reqCnt = this.reqList[this.nowCnt - 1].reqCnt;
        this.reqYm = dateFormat(this.reqList[this.nowCnt - 1].reqYm).slice(0, 7);
        this.onSearchDetail();
      } else {
        this.reqCnt = '';
        this.reqYm = '';
        this.sheet.loadSearchData();
      }
    },
    async onSearchDetail(row) {
      const param = {reqCnt: this.reqCnt};
      const response = await selectDetailList(param);
      this.sheet.loadSearchData(response.data);
    },
    onRenderFirstFinish(e) {
      this.onSearchDetail();
    },
    onSearchFinish(e) {
      e.sheet.makeSubTotal([
        {
          stdCol: 'total',
          sumCols: 'contAmt|preFixAmt|curFixAmt|totFixAmt',
          captionCol: [{col: 'midName', val: '전체합계'}],
        },
        {
          stdCol: 'midCode',
          sumCols: 'contAmt|preFixAmt|curFixAmt|totFixAmt',
          captionCol: [{col: 'midName', val: '합계'}],
        },
      ]);
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped></style>
