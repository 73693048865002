<template>
  <pmis-content-box>
    <form onsubmit="return false;">
      <iui-container-new type="css-flex">
        <i-row height="260px">
          <i-col min-width="530px">
            <iui-container-new type="table" theme="bullet">
              <i-row>
                <i-col-header :required="isEditableLP">대분류</i-col-header>
                <i-col>
                  <iui-text
                    name="lpCode"
                    type="code"
                    :value="lpCode"
                    :enable="isNew && isEditableLP"
                    @change="onChangeLpCode"
                    @input="onInputLpCode"
                    @keyup="onKeyupLpCode"
                    min-length="6"
                    max-length="6"
                    width="60px"
                    :required="isNew && isEditableLP"
                    :errorMessage="{title: '대분류', message: '대분류를 입력해주세요.'}"
                  />
                  <span style="width:115px" />
                  <iui-text
                    name="lpName"
                    :value.sync="lpName"
                    :enable="isEditableLP"
                    :required="isEditableLP"
                    :errorMessage="{title: '대분류', message: '대분류명을 입력해주세요.'}"
                    max-length="100"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header :required="isEditableMP">중분류</i-col-header>
                <i-col colspan="">
                  <iui-text
                    name="lpCode"
                    type="smallcode"
                    :value="level > 2 ? lpCode : ''"
                    :enable="false"
                    width="60px"
                  />
                  <iui-text
                    regExp="[^0-9]"
                    name="mpCode"
                    type="smallcode"
                    :value.sync="mpCode"
                    :enable="isNew && isEditableMP"
                    min-length="2"
                    max-length="2"
                    width="30px"
                    :required="isNew && isEditableMP"
                    :errorMessage="{title: '중분류', message: '중분류를 입력해주세요.'}"
                  />
                  <span style="width:80px" />
                  <iui-text
                    name="mpName"
                    :value.sync="mpName"
                    :enable="isEditableMP"
                    :required="isEditableMP"
                    max-length="100"
                  />
                </i-col>
              </i-row>
              <i-row><i-col></i-col></i-row>
              <i-row>
                <i-col-header :required="isEditableOpr">경비코드</i-col-header>
                <i-col>
                  <iui-text name="lpCode" type="smallcode" :value="lpCodeInOprCode" :enable="false" width="60px" />
                  <iui-text name="mpCode" type="smallcode" :value="mpCodeInOprCode" :enable="false" width="30px" />
                  <iui-text
                    regExp="[^0-9]"
                    type="largecode"
                    :value="String(this.oprCodeInfo.oprCode || '').slice(8)"
                    @change="setOprCodeInfo({oprCode: String(internalOprClInfo.mpCode).concat($event.target.value)})"
                    :enable="isNew && isEditableOpr"
                    max-length="8"
                    min-length="8"
                    width="80px"
                    :required="isNew && isEditableOpr"
                    :errorMessage="{title: '경비코드', message: '경비코드를 입력해주세요.'}"
                  />
                  <iui-text
                    name="itemName"
                    :value="oprCodeInfo.itemName"
                    @change="setOprCodeInfo({itemName: $event.target.value})"
                    :enable="isEditableOpr"
                    max-length="100"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>규격</i-col-header>
                <i-col>
                  <iui-text
                    name="size"
                    :value="oprCodeInfo.size"
                    @change="setOprCodeInfo({size: $event.target.value})"
                    :enable="isEditableOpr"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col colspan="2">
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header>단위</i-col-header>
                      <i-col>
                        <iui-text name="unitName" type="smallcode" :value="unitName" :enable="false" />
                        <iui-button value="검색" @click="onUnitPopup" :enable="isEditableOpr" />
                      </i-col>
                      <i-col></i-col>
                      <i-col></i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
              <i-row>
                <i-col colspan="2">
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header>사용여부</i-col-header>
                      <i-col>
                        <iui-checkbox-group
                          name="useDs"
                          :items="[{label: '사용', value: $getConstants('Y').code}]"
                          width="120px"
                          :checkedValues="[oprCodeInfo.useDs]"
                          :enable="isEditableOpr"
                          @change="setOprCodeInfo({useDs: $event.target.checked ? $event.target.value : ''})"
                        />
                      </i-col>
                      <i-col></i-col>
                      <i-col></i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
            </iui-container-new>
          </i-col>
          <i-col></i-col>
          <i-spacer />
          <i-col width="220px" height="300px"> </i-col>
        </i-row>
      </iui-container-new>
    </form>
    <iui-modal name="unitModal" title="단위 검색" width="1000px" height="500px" :btns="modalBtns">
      <UnitPopup />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import UnitPopup from '@/view/sysManage/stdCodeManage/components/UnitPopup.vue';
import {saveOprInfo} from '../api/OprCodeRegist.js';

export default {
  components: {UnitPopup},
  props: {
    base: {
      type: Boolean,
      default: false,
    },
    oprClInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      modalBtns: [{name: '확인', callback: this.onUnitPopupConfirm}],
      internalOprClInfo: {},
      oprCodeInfo: {},
      unitCodeList: [],
    };
  },
  computed: {
    isEditableLP() {
      return this.internalOprClInfo?.level === (this.isNew ? 1 : 2);
    },
    isEditableMP() {
      return this.internalOprClInfo?.level === (this.isNew ? 2 : 3);
    },
    isEditableOpr() {
      return !!this.oprCodeInfo.oprCode || this.internalOprClInfo?.level === 3;
    },
    isNew() {
      return this.cud === 1;
    },
    level() {
      return this.oprCodeInfo.level ?? this.internalOprClInfo?.level + (this.isNew ? 1 : 0);
    },
    lpCode() {
      return this.level > (this.isNew ? 2 : 1)
        ? this.oprCodeInfo.lpCode ?? this.internalOprClInfo?.lpCode
        : this.isNew
        ? $getConstants('COST_TYPE_E').code
        : '';
    },
    lpName: {
      set(value) {
        this.internalOprClInfo.lpName = value;
        this.internalOprClInfo.name = value;
      },
      get() {
        return this.level > (this.isNew ? 2 : 1) ? this.oprCodeInfo.lpName ?? this.internalOprClInfo?.lpName : '';
      },
    },
    mpCode: {
      set(value) {
        if (!this.internalOprClInfo.lpCode) {
          return;
        }
        const fullValue = String(this.internalOprClInfo.lpCode).concat(value);
        this.internalOprClInfo.code = fullValue;
        this.internalOprClInfo.mpCode = fullValue;
      },
      get() {
        return String(
          this.level > (this.isNew ? 3 : 2) ? this.oprCodeInfo.mpCode ?? this.internalOprClInfo?.mpCode ?? '' : ''
        ).slice(6);
      },
    },
    mpName: {
      set(value) {
        if (!this.internalOprClInfo.mpCode) {
          return;
        }
        this.internalOprClInfo.mpName = value;
        this.internalOprClInfo.name = value;
      },
      get() {
        return this.level > (this.isNew ? 3 : 2) ? this.oprCodeInfo.mpName ?? this.internalOprClInfo?.mpName : '';
      },
    },
    lpCodeInOprCode() {
      return String(
        this.isNew && this.internalOprClInfo?.level === 3
          ? this.internalOprClInfo?.lpCode
          : this.oprCodeInfo.lpCode ?? ''
      );
    },
    mpCodeInOprCode() {
      return String(
        this.isNew && this.internalOprClInfo?.level === 3
          ? this.internalOprClInfo?.mpCode
          : this.oprCodeInfo.mpCode ?? ''
      ).slice(6);
    },
    unitName() {
      const codeInfo = this.unitCodeList.find(item => item.code === String(this.oprCodeInfo.unit));
      return codeInfo?.codeNm;
    },
  },
  watch: {
    oprClInfo: {
      handler(value) {
        this.internalOprClInfo = value;
      },
      deep: true,
    },
  },
  created() {
    this.addEvent([{name: 'onUnitPopupConfirm', func: this.onUnitPopupConfirm}]);
    this.internalOprClInfo = this.oprClInfo;
    this.setUnitCodeList();
  },
  methods: {
    async setUnitCodeList() {
      this.unitCodeList = await $getCode($getConstants('UNIT').code);
    },
    async onSave() {
      try {
        const param = {...this.oprCodeInfo, ...this.internalOprClInfo, base: this.base};
        const response = await saveOprInfo(param);
        if (response.data) {
          if (this.internalOprClInfo.lpCode) {
            this.oprCodeInfo['lpCode'] = this.internalOprClInfo.lpCode;
          }
          if (this.internalOprClInfo.mpCode) {
            this.oprCodeInfo['mpCode'] = this.internalOprClInfo.mpCode;
          }
          return {oprCodeInfo: this.oprCodeInfo, oprClInfo: this.internalOprClInfo};
        }
      } catch (error) {
        this.$alert({title: '저장', message: error.message});
      }
    },
    setOprCodeInfo(data) {
      this.oprCodeInfo = {...this.oprCodeInfo, ...data};
    },
    onUnitPopup() {
      this.$modal.show('unitModal');
    },
    onUnitPopupConfirm() {
      this.callEvent({
        name: 'UnitPopupp_popConfirm',
        param: data => {
          this.setOprCodeInfo({unit: data.unitCode});
          this.$modal.hide('unitModal');
        },
      });
    },
    onChangeLpCode(e) {
      e.target.value = this.setPrefixLpCode(e.target.value);
    },
    onInputLpCode(e) {
      e.target.value = this.setPrefixLpCode(e.target.value);
    },
    onKeyupLpCode(e) {
      let value = this.setPrefixLpCode(e.target.value);
      if (value.length > 6) {
        value = value.substring(0, 6);
      }
      e.target.value = value;
      this.internalOprClInfo.code = e.target.value;
      this.internalOprClInfo.lpCode = e.target.value;
    },
    setPrefixLpCode(code) {
      const re = RegExp('[^0-9]', 'g');
      code = code.toString().replace(re, '');
      if (!code.startsWith($getConstants('COST_TYPE_E').code)) {
        code = `${$getConstants('COST_TYPE_E').code}${code}`;
      }
      return code;
    },
  },
};
</script>
