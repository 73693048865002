import {selectRegisterMatDetail, selectMatAnmonList, deleteMatAnmon} from '../api/anyTimeMaterial.js';
const state = {
  searchInfo: {
    investMm: '',
    reqDegree: '',
    custName: '',
  },
  detailInfo: {
    custCode: '',
    poNo: '',
    amt: 0,
    vatAmt: 0,
    taxexe: 0,
    sumAmt: 0,
    billNo: '',
    billTaxNo: '',
    flNo: 0,
    flTaxNo: 0,
    repayfrDt: '',
    repaytoDt: '',
  },
  matAnmonList: [],
  calculateList: [],
  closeList: [],
  closeYn: '',
};

const getters = {
  searchInfo: state => state.searchInfo,
  detailInfo: state => state.detailInfo,
  matAnmonList: state => state.matAnmonList,
  calculateList: state => state.calculateList,
  closeYn: state => state.closeYn,
  anyTimeMatSaveDataReqDto: state => {
    const keys = [
      'investMm',
      'reqDegree',
      'repayfrDt',
      'repaytoDt',
      'custCode',
      'poNo',
      'amt',
      'vatAmt',
      'taxexe',
      'sumAmt',
      'billNo',
      'billTaxNo',
      'flNo',
      'flTaxNo',
    ];
    let param = {};
    for (let key in state.searchInfo) {
      if (keys.findIndex(item => item === key) !== -1) {
        param[key] = state.searchInfo[key];
      }
    }
    for (let key in state.detailInfo) {
      if (keys.findIndex(item => item === key) !== -1) {
        param[key] = state.detailInfo[key];
      }
    }
    param['anyTimeMatSaveItemList'] = state.closeList;
    return param;
  },
};

const mutations = {
  setSearchInfo(state, payload) {
    for (let key in payload) {
      if (state.searchInfo.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  initDetailInfo(state) {
    let NumberKeys = ['amt', 'vatAmt', 'taxexe', 'sumAmt', 'flNo', 'flTaxNo'];
    for (let key in state.detailInfo) {
      state.detailInfo[key] = NumberKeys.indexOf(key) !== -1 ? 0 : '';
    }
  },

  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = payload[key];
      }
    }
  },
  setMatAnmonList(state, payload) {
    state.matAnmonList = payload;
  },
  initCalculateList(state) {
    state.calculateList = [];
  },
  setCalculateList(state, payload) {
    state.calculateList = payload;
  },
  setSumAmt(state) {
    state.detailInfo.sumAmt = $_getSumAmt(state.detailInfo.amt, state.detailInfo.vatAmt);
  },
  setCloseList(state, payload) {
    state.closeList = payload;
  },
  setCloseYn(state, closeYn) {
    state.closeYn = closeYn;
  },
};

const actions = {
  async selectRegisterMatDetail({state, commit}) {
    const param = {
      reqDegree: state.searchInfo.reqDegree,
      custCode: state.detailInfo.custCode,
      poNo: state.detailInfo.poNo,
      repayfrDt: state.detailInfo.repayfrDt,
      repaytoDt: state.detailInfo.repaytoDt,
    };
    const response = await selectRegisterMatDetail(param);
    commit('setCalculateList', response.data);
  },

  async selectMatAnmonList({state, commit}) {
    commit('initDetailInfo');
    commit('initCalculateList');
    const response = await selectMatAnmonList(state.searchInfo);
    const matAnmonList = response.data;
    commit('setCloseYn', !!matAnmonList.find(item => item.closeYn === $getConstants('Y').code));
    commit('setMatAnmonList', matAnmonList);
  },

  setCalculateAmt({state, commit}, originAmt) {
    let amt = originAmt;
    let vatAmt = $_getVatAmt(originAmt, state.detailInfo.taxexe);
    commit('setDetailInfo', {amt, vatAmt});
    commit('setSumAmt');
  },
  setCalculateVatAmt({commit}, vatAmt) {
    vatAmt = Number(vatAmt || 0);
    commit('setDetailInfo', {vatAmt});
    commit('setSumAmt');
  },
  setCalculateTaxexe({state, commit}, taxexe) {
    taxexe = Number(taxexe || 0);
    let vatAmt = $_getVatAmt(state.detailInfo.amt, taxexe);
    commit('setDetailInfo', {vatAmt, taxexe});
    commit('setSumAmt');
  },
  async deleteMatAnmon({state, dispatch}) {
    const param = {
      investMm: state.searchInfo.investMm,
      reqDegree: state.searchInfo.reqDegree,
      custCode: state.detailInfo.custCode,
      poNo: state.detailInfo.poNo,
    };
    const response = await deleteMatAnmon(param);
    if (response.data > 0) {
      dispatch('selectMatAnmonList');
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
