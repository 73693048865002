<template>
  <pmis-content-box>
    <template #header-left>
      <iui-text type="amount" width="180px" prefix="공급가액" :enable="false" :value="poInfo.poAmt" />
      <iui-text type="amount" width="180px" prefix="부가세액" :enable="false" :value="poInfo.vatAmt" />
      <iui-text type="amount" width="180px" prefix="합계금액" :enable="false" :value="poInfo.totAmt" />
    </template>
    <template #header-right v-if="enable">
      <iui-button v-if="!isModal" value="크게보기" @click="openExpandedModal" />
      <iui-button value="단가적용" @click="onPopUntpcApplc" v-if="isSimpleExec && rowCount" />
      <iui-button :value="isSimpleExec ? '자재선택' : '자재실행예산'" @click="openTargetMatModal" />
      <iui-button value="삭제" @click="onDeletePoItem" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="poItemList"
      @loadSheet="sheet = $event"
      :events="{
        onSearchFinish: sheet_onSearchFinish,
        onClick: sheet_onClick,
        onBeforeChange: sheet_onBeforeChange,
        onAfterChange: sheet_onAfterChange,
      }"
    />

    <iui-modal :name="`standardCodePopup_${_uid}`" title="자재선택" :btns="standardCodePopupBtns" size-type="size6">
      <StandardCodePopup type="mat" :base="false" />
    </iui-modal>

    <iui-modal :name="`matBudgetModal_${_uid}`" title="자재예산선택" :btns="matBudgetModalBtns" size-type="size3">
      <MatBudgetPopup :propsPrjCd="poInfo.spotDeptcd" />
    </iui-modal>

    <iui-modal :name="`matCodeInfoModal_${_uid}`" title="자재코드" size-type="size4">
      <MatCodeInfo :propsMatCode="propsMatCode" />
    </iui-modal>

    <iui-modal :name="`UntpcApplcPop_${_uid}`" title="단가적용" :btns="untpcApplcBtns" sizeType="size1">
      <UntpcApplcPop isMat />
    </iui-modal>

    <iui-modal name="expandedModal" title="계약내역" size-type="size6">
      <PoItemList isModal />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/poItemList.js';
import PoInputMixin from '@/view/bidding/js/poInputMixin.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import {selectMatUntpc} from '@/view/sysManage/stdCodeManage/api/untpcRegist.js';

import StandardCodePopup from '@/view/sysManage/stdCodeManage/components/StandardCodePopup.vue';
import MatBudgetPopup from '@/view/bidding/prManage/components/popup/MatBudgetPopup.vue';
import MatCodeInfo from '@/view/sysManage/stdCodeManage/matCodeRegist/popup/MatCodeInfo.vue';
import UntpcApplcPop from '@/view/estmtManage/estmtDtlsManage/popup/UntpcApplcPop.vue';

export default {
  name: 'PoItemList', // 재귀 컴포넌트에서는 name 필수
  components: {StandardCodePopup, MatBudgetPopup, MatCodeInfo, UntpcApplcPop},
  mixins: [PoInputMixin],
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sheetOpt: $addColCheckbox(sheetOpt(this)),
      standardCodePopupBtns: [{name: '확인', callback: this.onConfirm_standardCodePopup}],
      matBudgetModalBtns: [{name: '확인', callback: this.onConfirm_matBudgetModal}],
      untpcApplcBtns: [{name: '확인', callback: this.onPopConfirmUntpcApplcPop}],

      propsMatCode: '',
      rowCount: 0,
    };
  },
  computed: {
    enable() {
      // (신규 && 수기계약) || (계약작성중 && (수기계약 || 변경계약??))
      return (
        (this.poIsNew && this.poStatus.isExistOnlyPo) ||
        (this.poStatus.isPoReady && (this.poStatus.isExistOnlyPo || this.poStatus.isChangeContSeq))
      );
    },
  },
  beforeCreate() {
    $mapGetters(this, ['poItemList', 'poInfo', 'poIsNew', 'poStatus']);
    $mapMutations(this, ['setPoItemList', 'setPoInfo']);
  },
  created() {
    if (!this.isModal) {
      this.addEvent([
        {name: 'PoItemList_OpenTargetMatModal', func: this.openTargetMatModal},
        {name: 'PoItemList_CalculateAmt', func: this.calculateAmt},
        {name: 'PoItemList_GetSaveJson', func: this.getSaveJson},
        {name: 'PoItemList_MoveTopInValidRow', func: this.moveTopInvalidRow},
      ]);
    }
  },
  beforeDestroy() {
    if (!this.isModal) {
      return;
    }
    const poItemList = this.sheet.getSaveJson(0).data.map(row => ({
      itemCd: row.itemCd,
      itemNm: row.itemNm,
      sizeNm: row.sizeNm,
      unit: row.unit,
      qty: row.qty,
      unitPrc: row.unitPrc,
      amt: row.amt,
      itemRmrk: row.itemRmrk,
      planQty: row.planQty,
    }));
    this.setPoItemList(poItemList);
  },
  methods: {
    async openTargetMatModal() {
      if (!this.poInfo.spotDeptcd) {
        await this.$alert({title: '현장', message: '현장을 선택해주세요.'});
        this.callEvent({name: 'PoInfo_OpenPrjModal'});
        return;
      }

      if (this.isSimpleExec) {
        this.$modal.show(`standardCodePopup_${this._uid}`);
      } else {
        this.$modal.show(`matBudgetModal_${this._uid}`);
      }
    },
    onConfirm_standardCodePopup() {
      this.callEvent({
        name: 'StandardCodePopup_confirm',
        param: data => {
          const addItemList = data.map(item => ({
            itemCd: item.matCode,
            itemNm: item.itemName,
            sizeNm: item.size,
            unit: item.unit,
            qty: 0,
          }));
          this.loadAppendItemList(addItemList);
          this.$modal.hide(`standardCodePopup_${this._uid}`);
        },
      });
    },
    onConfirm_matBudgetModal() {
      this.callEvent({
        name: 'MatBudgetPopup_GetCheckedRows',
        param: data => {
          if (!data.length) {
            this.$alert({title: '자재예산선택', message: '자재예산내역을 선택해주세요.'});
            return;
          }

          const addItemList = data.map(item => ({
            itemCd: item.itemCode,
            itemNm: item.itemName,
            sizeNm: item.size,
            unit: item.unit,
            planQty: item.qty,
            qty: 0,
            unitPrc: item.price,
          }));
          this.loadAppendItemList(addItemList);

          this.$modal.hide(`matBudgetModal_${this._uid}`);
        },
      });
    },
    loadAppendItemList(appendList = []) {
      if (!appendList.length) {
        return;
      }
      const curPoItemCds = this.sheet.getSaveJson(0).data.map(item => item.itemCd);
      appendList
        .filter(item => !curPoItemCds.includes(String(item.itemCd)))
        .forEach(item => {
          const addRow = this.sheet.addRow();
          Object.keys(item).forEach(col => {
            this.sheet.setValue(addRow, col, item[col], true);
          });
        });
      if (this.isModal) {
        this.setEnableSheet();
      }
    },
    onPopUntpcApplc() {
      if (!this.sheet.getTotalRowCount()) {
        this.$alert({title: '계약내역', message: '계약내역을 등록해주세요.'});
        return;
      }
      this.$modal.show(`UntpcApplcPop_${this._uid}`);
    },
    onPopConfirmUntpcApplcPop() {
      this.callEvent({
        name: 'UntpcApplcPop_popConfirm',
        param: async data => {
          const {applyOpt, matUcstCode} = data;
          const getDataRows = this.sheet.getDataRows();
          let matList = [];
          if (applyOpt === '1') {
            matList = getDataRows.map(item => item.itemCd);
          } else {
            matList = getDataRows.filter(item => !item.unitPrc).map(item => item.itemCd);
          }
          const response = await selectMatUntpc({matUcstCode, matList});
          matList.forEach(matCd => {
            const row = getDataRows.find(row => row.itemCd === matCd);
            this.sheet.setValue(row, 'unitPrc', response.data.find(item => item.itemCode == row.itemCd)?.price ?? 0, 1);
          });
          this.calculateAmt();
          this.$modal.hide(`UntpcApplcPop_${this._uid}`);
        },
      });
    },
    onDeletePoItem() {
      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (!checkedRows.length) {
        this.$alert({title: '계약내역 삭제', message: '선택된 항목이 없습니다.'});
        return;
      }

      this.sheet.removeRows(checkedRows);
      this.rowCount = this.sheet.getTotalRowCount();
      this.calculateAmt();
    },
    setEnableSheet() {
      const color = this.enable ? '#E0E0F8' : undefined;

      this.sheet.setAttribute(null, 'chk', 'Visible', this.enable, 1);

      this.sheet.setAttribute(null, 'qty', 'CanEdit', this.enable, 1);
      this.sheet.setAttribute(null, 'unitPrc', 'CanEdit', this.enable, 1);
      this.sheet.setAttribute(null, 'itemRmrk', 'CanEdit', this.enable, 1);

      this.sheet.setAttribute(null, 'qty', 'Color', color, 1);
      this.sheet.setAttribute(null, 'unitPrc', 'Color', color, 1);
      this.sheet.setAttribute(null, 'itemRmrk', 'Color', color, 1);

      // 계약작성중 && 변경계약
      if (this.poStatus.isPoReady && this.poStatus.isChangeContSeq) {
        // 단가계약
        this.sheet.setAttribute(null, 'inputQty', 'Visible', !this.poStatus.isPotypeUnit, 1); // 기입고수량
        this.sheet.setAttribute(null, 'orderPreTotQty', 'Visible', this.poStatus.isPotypeUnit, 1); // 기발주수량
      } else {
        this.sheet.setAttribute(null, 'inputQty', 'Visible', 0, 1);
        this.sheet.setAttribute(null, 'orderPreTotQty', 'Visible', 0, 1);
      }

      this.sheet.Def.Row.CanFocus = this.enable || this.poIsNew;
    },
    calculateAmt() {
      const itemList = this.sheet.getDataRows();
      const amt = itemList.reduce((acc, curr) => acc + curr['amt'], 0);

      const {taxTypecd, taxDirectDs, taxRate, taxTypeDirectPercent, taxTypeDirectAmt} = this.poInfo;
      const calResult = COMMON_FUNCTION.onCalculateTaxAndTotAmt({
        amt,
        taxTypecd,
        taxDirectDs,
        taxRate,
        taxTypeDirectPercent,
        taxTypeDirectAmt,
      });
      this.setPoInfo({poAmt: calResult.amt, ...calResult});
    },
    getSaveJson(callback) {
      if (typeof callback === 'function') {
        callback(this.sheet.getSaveJson(0).data);
      }
    },
    moveTopInvalidRow() {
      this.sheet
        .getDataRows()
        .filter(row => !Number(row.amt))
        .reverse()
        .forEach(row => this.sheet.moveRow({row, next: this.sheet.getFirstRow(), render: 0}));
      this.sheet.rerender();
      this.sheet.focus(this.sheet.getFirstRow());
      this.sheet.setScrollTop(0);
    },
    sheet_onSearchFinish(e) {
      this.rowCount = e.sheet.getTotalRowCount();
      this.setEnableSheet();
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data' && e.col === 'itemNm') {
        this.propsMatCode = e.row.itemCd.toString();
        this.$modal.show(`matCodeInfoModal_${this._uid}`);
      }
    },
    sheet_onBeforeChange(e) {
      if (e.col == 'qty') {
        if (Number.isNaN(Number(e.val))) {
          return e.oldval;
        }

        const decimal = e.val.split('.')[1];
        if (5 < decimal?.length) {
          this.$alert({title: '금회요청수량', message: '소수점 이하 5자리까지 입력가능합니다.'});
          return e.oldval;
        }

        if (this.poStatus.isChangeContSeq) {
          const inputValue = e.val || 0;
          const compareValue = e.row[this.poStatus.isPotypeUnit ? 'orderPreTotQty' : 'inputQty'] || 0;
          const compareText = this.poStatus.isPotypeUnit ? '기발주' : '기입고';
          if (inputValue < compareValue) {
            this.$alert({
              title: `변경계약 ${compareText}수량`,
              message: `${compareText} 수량보다 적을 수 없습니다.`,
            });
            return e.oldval;
          }
        }
        const qty = e.val; // 입력한 금회요청수량

        if (0 > qty) {
          this.$alert({title: '금회요청수량', message: '금회요청수량은 0 이상이어야 합니다.'});
          return e.oldval;
        }
        return qty;
      }
    },
    sheet_onAfterChange(e) {
      if (e.col === 'qty' || e.col === 'unitPrc') {
        this.calculateAmt();
      }
    },
    async openExpandedModal() {
      if (!this.poInfo.spotDeptcd) {
        await this.$alert({title: '현장', message: '현장을 선택해주세요.'});
        this.callEvent({name: 'PoInfo_OpenPrjModal'});
        return;
      }

      this.setPoItemList(this.sheet.getSaveJson(0).data);

      this.$modal.show('expandedModal');
    },
  },
};
</script>

<style></style>
