import store from '@/store';

const state = {
  menu: [],
};

const getters = {
  menu: state => state.menu,
  Items: state =>
    state.menu.filter(item => item.isSelected).length ? state.menu.filter(item => item.isSelected)[0].Items : [],
  selectedMenuNm: state =>
    state.menu.filter(item => item.isSelected).length ? state.menu.filter(item => item.isSelected)[0].mnuNm : '',
  selectedViewNm: state => {
    const currentUrls = store.getters['mdi/currentUrls'];

    if (store.getters.currentUrl == '/' || store.getters.currentUrl == '/Home') {
      state.viewNm = '';
    } else {
      state.viewNm = currentUrls.mnuNm;
    }

    return state.viewNm;
  },

  getMenu: state => mnuCd => {
    let keyword1 = mnuCd.substr(0, 3); // ex) BID
    let keyword2 = mnuCd.substr(0, 4); // ex) BID3
    let keyword3 = mnuCd; // ex) BID306

    let top = state.menu.find(menu => menu.mnuCd.indexOf(keyword1) > -1); // menu > top
    let side = top.items.find(menu => menu.mnuCd.indexOf(keyword2) > -1); // top > side
    let sub = side.items.find(menu => menu.mnuCd == keyword3); // side > sub
    return sub;
  },
};

const actions = {
  //1depth 메뉴 선택
  onSelectMenu({state}, e) {
    for (let sibling of Array.from(e.target.closest('ul').querySelectorAll('li'))) {
      sibling.classList.remove('on');
    }
    e.target.closest('li').classList.add('on');

    for (let menu of state.menu) {
      if (menu.mnuCd == e.target.dataset.mnuCd) {
        if (menu.isSelected) break; // 선택중이던 메뉴 클릭시 메뉴로직 안탐
        menu.isSelected = true;
        //메뉴 2depth 기본 선택 설정
        menu.Items.map((Items, i) => {
          if (i == 0) {
            Items.isSelected = true;
          } else {
            Items.isSelected = false;
          }

          //메뉴 3depth 기본 선택 모두 제거
          if (!Items.Items) Items.Items = [];
          Items.Items.map(sItems => {
            sItems.isSelected = false;
          });
        });
      } else {
        menu.isSelected = false;
      }
    }

    if (!store.getters['layout/isSideOpen']) {
      // Left 메뉴 닫혀있으면 열어줌
      store.dispatch('layout/sideToggle');
    }
  },
  //2depth 메뉴선택
  onSelectItems({state}, e) {
    for (let sibling of Array.from(e.target.closest('ul').querySelectorAll('li'))) {
      sibling.classList.remove('open');
    }
    e.target.closest('li').classList.add('open');

    const Items = state.menu.filter(item => item.isSelected)[0].Items;
    for (let menu of Items) {
      if (menu.mnuCd == e.target.dataset.mnuCd) {
        if (menu.isSelected) break; // 선택중이던 메뉴 클릭시 메뉴로직 안탐
        menu.isSelected = true;
        //메뉴 3depth 기본 선택 모두 제거
        menu.Items.map(Items => {
          Items.isSelected = false;
        });
      } else {
        menu.isSelected = false;
      }
    }
  },
  setMenu({state}, menu) {
    state.menu = menu;
  },
  // 특정메뉴 열기
  onOpenMenu(context, mnuCd) {
    let targetMenu = context.getters.getMenu(mnuCd);
    store.dispatch('mdi/addMDI', targetMenu);
  },
};

const mutations = {
  selectedMenu(state) {
    const currentUrls = store.getters['mdi/currentUrls'];
    const oneLevel = `${String(currentUrls.uppMnuCd).substr(0, 3)}0000`;
    const twoLevel = `${currentUrls.uppMnuCd}`;
    const threeLevel = `${currentUrls.mnuCd}`;
    state.menu.forEach(menu => {
      if (menu.mnuCd == oneLevel) {
        menu.isSelected = true;
        menu.Items.forEach(item => {
          if (item.mnuCd == twoLevel) {
            item.isSelected = true;
            item.Items.forEach(sItem => {
              if (sItem.mnuCd == threeLevel) {
                sItem.isSelected = true;
              } else {
                sItem.isSelected = false;
              }
            });
          } else {
            item.isSelected = false;
          }
        });
      } else {
        menu.isSelected = false;
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
