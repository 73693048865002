<template>
  <iui-container-new type="css-flex">
    <form onsubmit="return false;">
      <i-row min-height="160px">
        <i-col>
          <CntrctChangeDtls />
        </i-col>
      </i-row>
      <i-row height="175px">
        <i-col>
          <pmis-content-box :loading="loading">
            <template v-slot:title>현장정보</template>
            <iui-container-new type="table" theme="bullet" header-width="95px">
              <i-row>
                <i-col-header required>현장코드</i-col-header>
                <i-col>
                  <iui-text name="prjCd" :value="ctrctInfo.prjCd" :bindArray="bindArray" :enable="false" width="90px" />
                  <iui-text
                    name="prjNm"
                    :value="ctrctInfo.prjNm"
                    :bindArray="bindArray"
                    required
                    :errorMessage="{title: '현장코드', message: '현장코드는 필수입력입니다'}"
                    :enable="canEditPrjNm"
                    max-length="100"
                  />
                </i-col>
                <i-spacer />
                <i-col-header required>도급종류</i-col-header>
                <i-col>
                  <iui-radio-group
                    name="ctrctWorkKind"
                    :bindArray="bindArray"
                    :value="ctrctInfo.workKind"
                    :p-code="$getConstants('WORK_KIND').code"
                    @change="onChangeWorkKind($event)"
                    :enable="isLastChgTo"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>현장주소</i-col-header>
                <i-col>
                  <iui-searchbox
                    :idValue="ctrctInfo.postNo"
                    :nameValue="ctrctInfo.adr1"
                    name="postNo"
                    nameEleName="adr1"
                    :type="'addr'"
                    :callback="getAddr"
                    :idBindArray="bindArray"
                    :nameBindArray="bindArray"
                    :isSearchBtn="isLastChgTo"
                    required
                    :errorMessage="{title: '현장주소', message: '현장주소는 필수입력입니다'}"
                  />
                </i-col>
                <i-spacer />
                <i-col-header required>현장위치</i-col-header>
                <i-col>
                  <div>위도</div>
                  <iui-text
                    width="180px"
                    name="prjLocLati"
                    :value="ctrctInfo.prjLocLati"
                    :bindArray="bindArray"
                    :enable="false"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col></i-col>
                <i-col>
                  <iui-text
                    name="adr2"
                    :value="ctrctInfo.adr2"
                    max-length="100"
                    :bindArray="bindArray"
                    :enable="isLastChgTo"
                  />
                </i-col>
                <i-col></i-col>
                <i-spacer />
                <i-col>
                  <div>경도</div>
                  <iui-text
                    width="180px"
                    name="prjLocLong"
                    :value="ctrctInfo.prjLocLong"
                    :bindArray="bindArray"
                    :enable="false"
                  />
                  <div>승인거리</div>
                  <iui-text
                    type="number"
                    width="80px"
                    name="prjLocAppro"
                    :value="ctrctInfo.prjLocAppro"
                    :bindArray="bindArray"
                    suffix="미터"
                    required
                    :errorMessage="{title: '승인거리', message: '승인거리를 입력하세요'}"
                    :enable="isLastChgTo"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>현장대리인</i-col-header>
                <i-col>
                  <div class="iui-searchbox">
                    <iui-text
                      name="empNo1Name"
                      width="118px"
                      required
                      :errorMessage="{title: '현장대리인', message: '현장대리인은 필수입력입니다'}"
                      :value="ctrctInfo.empNo1Name"
                      :bindArray="bindArray"
                      :enable="false"
                    />
                    <iui-button value="선택" @click="onShowModal('WorkerListModal', '1')" v-if="isLastChgTo" />
                  </div>
                </i-col>
                <i-spacer />
                <i-col colspan="2"></i-col>
              </i-row>
              <i-row>
                <i-col-header>안전관리자</i-col-header>
                <i-col>
                  <iui-text
                    name="empNo2Name"
                    width="118px"
                    :value="ctrctInfo.empNo2Name"
                    :bindArray="bindArray"
                    :enable="false"
                  />
                  <iui-button value="선택" @click="onShowModal('WorkerListModal', '2')" v-if="isLastChgTo" />
                </i-col>
                <i-spacer />
                <i-col-header>품질관리자</i-col-header>
                <i-col>
                  <iui-text
                    name="empNo3Name"
                    width="118px"
                    :value="ctrctInfo.empNo3Name"
                    :bindArray="bindArray"
                    :enable="false"
                  />
                  <iui-button value="선택" @click="onShowModal('WorkerListModal', '3')" v-if="isLastChgTo" />
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row height="205px">
        <i-col>
          <pmis-content-box>
            <template v-slot:title>계약정보</template>
            <iui-container-new type="table" theme="bullet">
              <colgroup>
                <col width="95px" />
                <col />
                <col width="10px" />
                <col width="95px" />
                <col />
              </colgroup>
              <i-row>
                <i-col-header>발주구분</i-col-header>
                <i-col>
                  <iui-radio-group
                    name="ctrctOrderGroup"
                    :bindArray="bindArray"
                    :value="ctrctInfo.orderGroup"
                    :p-code="$getConstants('ORDER_GROUP').code"
                    :enable="isLastChgTo"
                  />
                </i-col>
                <i-spacer />
                <i-col colspan="2">
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col style="font-weight: bold;">※ 퇴직공제가입 {{ deduction }}</i-col>
                      <i-col style="font-weight: bold;">※ 키스콘통보 {{ kiscon }}</i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>발주처명</i-col-header>
                <i-col>
                  <iui-searchbox
                    type="customer"
                    :idValue="ctrctInfo.orderCode"
                    :nameValue="ctrctInfo.orderNm"
                    name="orderCode"
                    nameEleName="orderNm"
                    :idBindArray="bindArray"
                    :nameBindArray="bindArray"
                    :paramObj="{frmDs: $getConstants('FRM_DS_ORDER').code}"
                    :isSearchBtn="isLastChgTo"
                    required
                    :errorMessage="{title: '발주처명', message: '발주처명은 필수입력입니다'}"
                  />
                </i-col>
                <i-spacer />
                <i-col-header
                  :required="ctrctInfo.workKind == $getConstants('WORK_KIND_SCPAY').code"
                  v-if="ctrctInfo.workKind == $getConstants('WORK_KIND_SCPAY').code"
                >
                  원도급사
                </i-col-header>
                <i-col>
                  <iui-searchbox
                    v-if="ctrctInfo.workKind == $getConstants('WORK_KIND_SCPAY').code"
                    type="customer"
                    :idValue="ctrctInfo.contcompCode"
                    :nameValue="ctrctInfo.contcompNm"
                    name="contcompCode"
                    nameEleName="contcompNm"
                    :idBindArray="bindArray"
                    :nameBindArray="bindArray"
                    :paramObj="{frmDs: $getConstants('FRM_DS_CONT_COMP').code}"
                    :isSearchBtn="isLastChgTo"
                    :required="ctrctInfo.workKind == $getConstants('WORK_KIND_SCPAY').code"
                    :errorMessage="{title: '원도급사', message: '원도급사는 필수입력입니다'}"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>계약금액</i-col-header>
                <i-col>
                  <iui-text
                    ref="ctrtSumAmt"
                    type="amount"
                    :value="ctrctInfo.ctrtSumAmt"
                    @change="onChangeAmt('ctrtSumAmt', $event)"
                    required
                    :errorMessage="{title: '계약금액', message: '계약금액은 필수입력입니다'}"
                    :enable="isLastChgTo"
                  />
                  <span>(VAT포함)</span>
                </i-col>
                <i-spacer />
                <i-col-header>비과세액</i-col-header>
                <i-col>
                  <iui-text
                    type="unitAmount"
                    :value="ctrctInfo.ctrtTaxexe"
                    @change="onChangeAmt('ctrtTaxexe', $event)"
                    :enable="isLastChgTo"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>과세액</i-col-header>
                <i-col>
                  <iui-text
                    type="unitAmount"
                    name="ctrtAmt"
                    :value="ctrctInfo.ctrtAmt"
                    :bindArray="bindArray"
                    :enable="false"
                  />
                </i-col>
                <i-spacer />
                <i-col-header width="120px">부가세액</i-col-header>
                <i-col>
                  <iui-text
                    type="unitAmount"
                    name="ctrtVat"
                    :value="ctrctInfo.ctrtVat"
                    :bindArray="bindArray"
                    max-length="26"
                    :enable="false"
                  />
                </i-col>
              </i-row>

              <i-row>
                <i-col-header required>공사계약일자</i-col-header>
                <i-col>
                  <iui-datepicker
                    name="ctrtDate"
                    :value="ctrctInfo.ctrtDate"
                    :bindArray="bindArray"
                    required
                    :errorMessage="{title: '공사계약일자', message: '공사계약일자는 필수입력입니다'}"
                    :enable="isLastChgTo"
                    @change="dateCheck(1)"
                  />
                </i-col>
                <i-spacer />
                <i-col-header required>공사착공일자</i-col-header>
                <i-col>
                  <iui-datepicker
                    name="ctrtStrdate"
                    :value="ctrctInfo.ctrtStrdate"
                    :bindArray="bindArray"
                    required
                    :errorMessage="{title: '공사착공일자', message: '공사착공일자는 필수입력입니다'}"
                    :enable="isLastChgTo"
                    @change="dateCheck(2)"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>준공예정일자</i-col-header>
                <i-col>
                  <iui-datepicker
                    name="ctrtEnddate"
                    :value="ctrctInfo.ctrtEnddate"
                    :bindArray="bindArray"
                    required
                    :errorMessage="{title: '준공예정일자', message: '준공예정일자는 필수입력입니다'}"
                    :enable="isLastChgTo"
                    @change="dateCheck(3)"
                  />
                </i-col>
                <i-spacer />
                <i-col-header>준공일자</i-col-header>
                <i-col>
                  <iui-datepicker
                    name="conCmplDate"
                    :value="ctrctInfo.conCmplDate"
                    :bindArray="bindArray"
                    :enable="isLastChgTo"
                  />
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row height="160px">
        <i-col>
          <pmis-content-box>
            <template v-slot:title>공사대금지급조건</template>
            <iui-container-new type="table" theme="theme">
              <colgroup>
                <col width="60px" />
                <col />
                <col width="10px" />
                <col width="60px" />
                <col />
              </colgroup>
              <i-row>
                <i-col>- 선급금</i-col>
                <i-col>
                  <div>(1) 계약체결후</div>
                  <iui-text
                    type="number"
                    width="30px"
                    name="prepayDay1"
                    :value="ctrctInfo.prepayDay1"
                    :bindArray="bindArray"
                    :numberFormat="false"
                    max-length="2"
                    align="center"
                    :enable="isLastChgTo"
                  />
                  <div>일 이내에</div>
                  <iui-text
                    type="amount"
                    suffix=""
                    width="100px"
                    name="prepayAmt"
                    :value="ctrctInfo.prepayAmt"
                    :bindArray="bindArray"
                    :enable="isLastChgTo"
                  />
                  <div>원</div>
                </i-col>
                <i-spacer />
                <i-col colspan="2">
                  <div>(2) 발주자로부터 지급받은 날 또는 계약일로부터</div>
                  <iui-text
                    type="number"
                    width="30px"
                    name="prepayDay2"
                    :value="ctrctInfo.prepayDay2"
                    :bindArray="bindArray"
                    :numberFormat="false"
                    max-length="2"
                    align="center"
                    :enable="isLastChgTo"
                  />
                  <div>일 이내 그 내용과 비율에 따름</div>
                </i-col>
              </i-row>
              <i-row>
                <i-col>- 기성금</i-col>
                <i-col>
                  <div>(1)</div>
                  <iui-text
                    type="number"
                    width="30px"
                    name="gisungMonth"
                    :value="ctrctInfo.gisungMonth"
                    :bindArray="bindArray"
                    :numberFormat="false"
                    max-length="2"
                    align="center"
                    :enable="isLastChgTo"
                  />
                  <div>월</div>
                  <iui-text
                    type="number"
                    width="30px"
                    name="gisungCount"
                    :value="ctrctInfo.gisungCount"
                    :bindArray="bindArray"
                    :numberFormat="false"
                    max-length="2"
                    align="center"
                    :enable="isLastChgTo"
                  />
                  <div>회</div>
                </i-col>
                <i-spacer />
                <i-col colspan="2">
                  <div>(2) 목적물 수령일로부터</div>
                  <iui-text
                    type="number"
                    width="30px"
                    name="gisungDay"
                    :value="ctrctInfo.gisungDay"
                    :bindArray="bindArray"
                    :numberFormat="false"
                    max-length="2"
                    align="center"
                    :enable="isLastChgTo"
                  />
                  <div>일 이내</div>
                </i-col>
              </i-row>
              <i-row height="26px">
                <i-col></i-col>
                <i-col colspan="4">
                  <div>(3) 지급방법 :</div>
                  <iui-radio-group
                    :p-code="$getConstants('PAY_COND_RMRK_CD').code"
                    :exclude-items="$getConstants('PAY_COND_RMRK_CD_RESERVATION').code"
                    name="payCashYn"
                    :value="ctrctInfo.payCashYn"
                    :bindArray="bindArray"
                    :enable="isLastChgTo"
                  />
                  <div v-if="isPayCash && isLastChgTo">
                    (현금
                  </div>
                  <iui-text
                    type="rate"
                    suffix=""
                    width="60px"
                    :value="ctrctInfo.payCashRatio"
                    v-if="isPayCash && isLastChgTo"
                    @change="onChangePaymentRatio('payCashRatio', $event)"
                  />
                  <div v-if="isPayCash && isLastChgTo">%, 어음</div>
                  <iui-text
                    type="rate"
                    suffix=""
                    width="60px"
                    :value="ctrctInfo.payCheckRatio"
                    v-if="isPayCash && isLastChgTo"
                    @change="onChangePaymentRatio('payCheckRatio', $event)"
                  />
                  <div v-if="isPayCash && isLastChgTo">%, 어음대체결제수단</div>
                  <iui-text
                    type="rate"
                    suffix=""
                    width="60px"
                    :value="ctrctInfo.payCheck2Ratio"
                    v-if="isPayCash && isLastChgTo"
                    @change="onChangePaymentRatio('payCheck2Ratio', $event)"
                  />
                  <div v-if="isPayCash && isLastChgTo">%)</div>
                </i-col>
              </i-row>
              <i-row height="26px">
                <i-col colspan="5">
                  - 설계변경, 경제상황변동 등에 따른 대금조정 및 지급
                </i-col>
              </i-row>
              <i-row>
                <i-col></i-col>
                <i-col>
                  <div>(1) 발주자로부터 조정받은 날로부터</div>
                  <iui-text
                    type="number"
                    width="30px"
                    name="negoDay1"
                    :value="ctrctInfo.negoDay1"
                    :bindArray="bindArray"
                    :numberFormat="false"
                    max-length="2"
                    align="center"
                    :enable="isLastChgTo"
                  />
                  <div>일 이내 그 내용과 비율에 따라 조정</div>
                </i-col>
                <i-spacer />
                <i-col colspan="2">
                  <div>(2) 발주자로부터 지급받은 날로부터</div>
                  <iui-text
                    type="number"
                    width="30px"
                    name="negoDay2"
                    :value="ctrctInfo.negoDay2"
                    :bindArray="bindArray"
                    :numberFormat="false"
                    max-length="2"
                    align="center"
                    :enable="isLastChgTo"
                  />
                  <div>일 이내 지급</div>
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row height="90px">
        <i-col>
          <pmis-content-box>
            <template v-slot:title>보증내역</template>
            <iui-container-new type="table" theme="bullet">
              <colgroup>
                <col width="120px" />
                <col />
                <col width="10px" />
                <col width="120px" />
                <col />
              </colgroup>
              <i-row>
                <i-col-header required>계약 보증금</i-col-header>
                <i-col>
                  <div>계약금액의</div>
                  <iui-text
                    type="rate"
                    suffix=""
                    width="60px"
                    :value="ctrctInfo.prfmInsrRate"
                    @change="onCalculatePrfmInsrAmt"
                    required
                    :errorMessage="{title: '계약 보증금', message: '계약 보증율은 필수입력입니다'}"
                    :enable="isLastChgTo"
                  />
                  <div>% (</div>
                  <iui-text
                    type="amount"
                    suffix=""
                    width="100px"
                    name="prfmInsrAmt"
                    :value="ctrctInfo.prfmInsrAmt"
                    :bindArray="bindArray"
                    :enable="false"
                  />
                  <div>원)</div>
                </i-col>
                <i-spacer />
                <i-col-header required>지체상금율</i-col-header>
                <i-col>
                  <div>계약금액의</div>
                  <iui-text
                    type="rate"
                    suffix=""
                    width="60px"
                    :value="ctrctInfo.delayRate"
                    @change="onCalculateDelayAmt"
                    required
                    :errorMessage="{title: '지체상금율', message: '지체상금율은 필수입력입니다'}"
                    :enable="isLastChgTo"
                  />
                  <div>% (</div>
                  <iui-text
                    type="amount"
                    suffix=""
                    width="100px"
                    name="delayAmt"
                    :value="ctrctInfo.delayAmt"
                    :bindArray="bindArray"
                    :enable="false"
                  />
                  <div>원)</div>
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>하자보수보증금</i-col-header>
                <i-col>
                  <div>계약금액의</div>
                  <iui-text
                    type="rate"
                    suffix=""
                    width="60px"
                    :value="ctrctInfo.flawInsrRate"
                    @change="onCalculateFlawInsrAmt"
                    required
                    :errorMessage="{title: '하자보수보증금', message: '하자보수 보증율은 필수입력입니다'}"
                    :enable="isLastChgTo"
                  />
                  <div>% (</div>
                  <iui-text
                    type="amount"
                    suffix=""
                    width="100px"
                    name="flawInsrAmt"
                    :value="ctrctInfo.flawInsrAmt"
                    :bindArray="bindArray"
                    :enable="false"
                  />
                  <div>원)</div>
                </i-col>
                <i-spacer />
                <i-col-header required>하자담보책임기간</i-col-header>
                <i-col>
                  <iui-radio-group
                    :items="flawInsrItems1"
                    name="flawInsrYn"
                    :value="ctrctInfo.flawInsrYn"
                    :bindArray="bindArray"
                    :enable="isLastChgTo"
                  />
                  <iui-text
                    type="number"
                    align="center"
                    width="30px"
                    name="flawInsrYear"
                    :value="ctrctInfo.flawInsrYear"
                    max-length="2"
                    :bindArray="bindArray"
                    :enable="isFlawInsrYear && isLastChgTo"
                    :required="isFlawInsrYear && isLastChgTo"
                    :errorMessage="{title: '하자담보책임기간', message: '하자담보 책임기간(년)은 필수입력입니다'}"
                  />
                  <div class="mr5"></div>
                  <iui-radio-group
                    :items="flawInsrItems2"
                    name="flawInsrYn"
                    :value="ctrctInfo.flawInsrYn"
                    :bindArray="bindArray"
                  />
                  <div>담보기간</div>
                  <iui-datepicker
                    name="flawInsrFrDt"
                    :value="ctrctInfo.flawInsrFrDt"
                    :bindArray="bindArray"
                    :enable="!isFlawInsrYear && isLastChgTo"
                    :required="!isFlawInsrYear && isLastChgTo"
                    :errorMessage="{title: '하자담보책임기간', message: '하자담보 책임기간(월)은 필수입력입니다'}"
                    :group="flawInsrDate.seq1"
                  />
                  <div>~</div>
                  <iui-datepicker
                    name="flawInsrToDt"
                    :value="ctrctInfo.flawInsrToDt"
                    :bindArray="bindArray"
                    :enable="!isFlawInsrYear && isLastChgTo"
                    :required="!isFlawInsrYear && isLastChgTo"
                    :errorMessage="{title: '하자담보책임기간', message: '하자담보 책임기간(월)은 필수입력입니다'}"
                    :group="flawInsrDate.seq2"
                  />
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row height="65px">
        <i-col>
          <pmis-content-box>
            <template v-slot:title>인지세</template>
            <iui-container-new type="table" theme="bullet">
              <colgroup>
                <col width="90px" />
                <col />
                <col width="10px" />
                <col width="90px" />
                <col />
              </colgroup>
              <i-row>
                <i-col-header required>인지세유무</i-col-header>
                <i-col colspan="4">
                  <iui-checkbox-group
                    :checkedValues="[ctrctInfo.stampTaxYn]"
                    :items="[{label: $getConstants('EXTERNAL_Y').name, value: $getConstants('EXTERNAL_Y').code}]"
                    @change="onChangeStampTaxYn"
                    :enable="isLastChgTo"
                  />
                  <iui-text
                    type="rate"
                    width="80px"
                    name="stampTaxOwnRate"
                    :value="ctrctInfo.stampTaxOwnRate"
                    :enable="isStampTax && isLastChgTo"
                    :required="isStampTax && isLastChgTo"
                    :errorMessage="{title: '인지세율', message: '인지세율은 필수입력입니다'}"
                    @change="onChange_stampTaxOwnRate($event.target.value)"
                  />
                  <div>(당사부담)</div>
                  <div>인지세액</div>
                  <iui-text
                    type="amount"
                    suffix=""
                    width="100px"
                    name="stampTaxAmt"
                    :value="ctrctInfo.stampTaxAmt"
                    :enable="false"
                  />
                  <div>원)</div>
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row min-height="200px">
        <i-col>
          <pmis-content-box>
            <template v-slot:title>특약사항</template>
            <iui-text
              type="multi"
              name="rmk"
              :value="ctrctInfo.rmk"
              :bindArray="bindArray"
              :enable="isLastChgTo"
              max-length="50"
            />
          </pmis-content-box>
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-file-list
            id="cntrctFile"
            title="서류목록"
            title-visible
            :toolbarVisible="isLastChgTo"
            :company-code="ctrctInfo.pgmCd"
            :file-number="ctrctInfo.flNo"
            :projectCode="ctrctInfo.prjCd"
            :fbsNo="fbsNo"
            @delete-complete="onDeleteComplete"
          />
        </i-col>
      </i-row>
      <i-row height="5px"></i-row>

      <iui-modal name="WorkerListModal" title="근로자정보" sizeType="size1" :btns="modalBtns">
        <WorkerListPopup :uid="_uid" :laborDsList="laborDsList" />
      </iui-modal>
    </form>
  </iui-container-new>
</template>

<script>
/**
 * 계약정보등록(tab0) > 상세정보
 *   components :
 *     CntrctChangeDtls : 계약차수(변경계약) 목록
 *     WorkerListPopup : 사원조회 팝업
 * */
import CntrctChangeDtls from './CntrctChangeDtls.vue';
import WorkerListPopup from '@/view/Resource/components/WorkerListPopup.vue';
import EDMS_CONSTANTS from '@/view/edms/common/EdmsConstatns.js';
import {deleteCntrctInfo, saveCntrctInfo, selectStampTaxAmt, updateFileNoAndRgprInfo} from '../api/cntrctInfoRegist.js';

export default {
  components: {
    CntrctChangeDtls,
    WorkerListPopup,
  },
  data() {
    return {
      bindArray: ['SET_CTRCT_INFO'],
      kiscon: '',
      deduction: '',

      modalBtns: [{name: '확인', callback: this.onHideModal}],
      modalName: '',
      modalType: '',

      laborDsList: [$getConstants('LABOR_DS1').code, $getConstants('LABOR_DS2').code],
      frmDs: '',
      fbsNo: EDMS_CONSTANTS.CONTRACT.FBS_NO,

      flawInsrDate: {
        seq1: {grpNm: 'flawInsrDate', seq: 1},
        seq2: {grpNm: 'flawInsrDate', seq: 2},
      },
      flawInsrItems1: [{label: $getConstants('FLAW_INSR_YEAR').name, value: $getConstants('FLAW_INSR_YEAR').code}],
      flawInsrItems2: [{label: $getConstants('FLAW_INSR_MONTH').name, value: $getConstants('FLAW_INSR_MONTH').code}],
      delYn: '',
      save: false,
      loading: false,
      isSearchingStampTaxAmt: false,
    };
  },
  computed: {
    isPayCash() {
      return this.ctrctInfo.payCashYn == $getConstants('PAY_COND_RMRK_CD_CASH').code; // 대금지급방법 - 현금,어음
    },
    isFlawInsrYear() {
      return this.ctrctInfo.flawInsrYn == $getConstants('FLAW_INSR_YEAR').code; // 하자담보책임기간 - 년
    },
    isStampTax() {
      return this.ctrctInfo.stampTaxYn == $getConstants('EXTERNAL_Y').code; // 인지세 - 있음
    },
    isLastChgTo() {
      return this.ctrctInfo.ctrtChgTo == this.ctrctInfo.lastChgTo;
    },
  },
  watch: {
    ctrctInfo: {
      deep: true,
      handler() {
        this.onKiscon();
        this.onDeduction();
      },
    },
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'ctrctInfo', 'canEditPrjNm']);
    $mapMutations(this, ['SET_CTRCT_INFO']);
    $mapActions(this, ['setCtrctInfo', 'initCtrctInfo', 'setCtrctCud']);
  },
  created() {
    this.addEvent([
      {name: 'DetailInfo_onHideModal', func: this.onHideModal},
      {name: 'DetailInfo_onSave', func: this.onSave},
      {name: 'DetailInfo_onDelete', func: this.onDelete},
    ]);

    $getCode($getConstants('DEL_YN').code).then(response => (this.delYn = response[0].code));
  },
  updated() {
    if (this.save && !this.isSearchingStampTaxAmt) {
      this.onSave();
    }
  },
  methods: {
    onSave() {
      if ($_numberFormat(this.$refs.ctrtSumAmt.$refs.input.value) !== $_numberFormat(this.ctrctInfo.ctrtSumAmt)) {
        this.save = true;
        return;
      }

      if (this.ctrctInfo.cud == 0) this.setCtrctCud(2);

      const param = {...this.ctrctInfo, prjFlag: $getConstants('PRJ_FLAG1').code, fbsNo: this.fbsNo};
      saveCntrctInfo(param).then(response => {
        if (response.status == 200) {
          this.setCtrctCud(0);
          this.callEvent({name: 'prjList_onSearch'});
        }
        if (response.status == 999) {
          if (this.ctrctInfo.cud == 2) this.setCtrctCud(0);
        }
      });

      this.save = false;
    },
    async onDelete() {
      if (this.ctrctInfo.cud == 1) {
        this.$alert({title: '계약정보 삭제', message: '신규 계약정보는 삭제할 수 없습니다.'});
        return;
      }
      if (this.ctrctInfo.cud == 0) this.setCtrctCud(3);

      let param = {
        pgmCd: this.ctrctInfo.pgmCd,
        prjCd: this.ctrctInfo.prjCd,
        ctrtChgTo: this.ctrctInfo.ctrtChgTo,
        delYn: this.delYn,
      };
      if (await this.$confirm({title: '계약정보 삭제', message: '계약정보를 삭제하시겠습니까?'})) {
        const response = await deleteCntrctInfo(param);
        this.callEvent({name: 'prjList_onSearch'});
      }
      if (this.ctrctInfo.cud == 3) this.setCtrctCud(0);
    },
    getAddr(rData) {
      const postNo = rData.zipNo;
      const adr1 = `${rData.roadAddrPart1} ${rData.roadAddrPart2}`;
      const adr2 = rData.addrDetail;
      const prjLocLati = rData.entY; //위도
      const prjLocLong = rData.entX; //경도
      const prjLocAppro = '50'; //승인거리
      this.setCtrctInfo({postNo, adr1, adr2, prjLocLong, prjLocLati, prjLocAppro});
    },
    dateCheck(checkType) {
      const ctrtDate = this.ctrctInfo.ctrtDate;
      const ctrtStrdate = this.ctrctInfo.ctrtStrdate;
      const ctrtEnddate = this.ctrctInfo.ctrtEnddate;

      let title, message;
      if (ctrtDate && ctrtStrdate && ctrtDate > ctrtStrdate) {
        if (checkType == 1) {
          title = '공사계약일자';
          message = '공사계약일자는 공사착공일자보다 이전 이여야 합니다. 다시 확인해주세요.';
        }
        if (checkType == 2) {
          title = '공사착공일자';
          message = '공사착공일자는 공사계약일자보다 이후 이여야 합니다. 다시 확인해주세요.';
        }
      } else if (ctrtStrdate && ctrtEnddate && ctrtStrdate > ctrtEnddate) {
        if (checkType == 2) {
          title = '공사착공일자';
          message = '공사착공일자는 준공예정일자보다 이전 이여야 합니다. 다시 확인해주세요.';
        }
        if (checkType == 3) {
          title = '준공예정일자';
          message = '준공예정일자는 공사착공일자보다 이후 이여야 합니다. 다시 확인해주세요.';
        }
      } else if (ctrtDate && ctrtEnddate && ctrtDate > ctrtEnddate) {
        if (checkType == 1) {
          title = '공사계약일자';
          message = '공사계약일자는 준공예정일자보다 이전 이여야 합니다. 다시 확인해주세요.';
        }
        if (checkType == 3) {
          title = '준공예정일자';
          message = '준공예정일자는 공사계약일자보다 이후 이여야 합니다. 다시 확인해주세요.';
        }
      }

      if (title && message) {
        this.$alert({title, message}, () => {
          if (checkType == 1) this.setCtrctInfo({ctrtDate: ''});
          if (checkType == 2) this.setCtrctInfo({ctrtStrdate: ''});
          if (checkType == 3) this.setCtrctInfo({ctrtEnddate: ''});
        });
      }
    },
    onKiscon() {
      if (this.ctrctInfo.workKind == $getConstants('WORK_KIND_CONTCOMP').code) {
        // 원도급
        this.kiscon = this.ctrctInfo.ctrtSumAmt >= 100000000 ? '대상' : '비대상';
      } else if (this.ctrctInfo.workKind == $getConstants('WORK_KIND_SCPAY').code) {
        // 하도급
        this.kiscon = this.ctrctInfo.ctrtSumAmt >= 40000000 ? '대상' : '비대상';
      }
    },
    onDeduction() {
      if (this.ctrctInfo.orderGroup == $getConstants('ORDER_GROUP_PUBLIC').code) {
        // 공공기관
        this.deduction = this.ctrctInfo.ctrtSumAmt >= 300000000 ? '대상' : '비대상';
      } else if (this.ctrctInfo.orderGroup == $getConstants('ORDER_GROUP_PRIVATE').code) {
        // 민관기관
        this.deduction = this.ctrctInfo.ctrtSumAmt >= 10000000000 ? '대상' : '비대상';
      }
    },
    onShowModal(modalName, modalType) {
      this.modalName = modalName;
      this.modalType = modalType;
      this.$modal.show(modalName);
    },
    onHideModal() {
      if (this.modalName == 'WorkerListModal') {
        this.callEvent({
          name: 'WorkerListPopup_callbackData',
          param: data => {
            this.setCtrctInfo({[`empNo${this.modalType}`]: data.empNo, [`empNo${this.modalType}Name`]: data.name});
            this.$modal.hide(this.modalName);
          },
        });
      }
    },
    onChangeWorkKind(e) {
      if (e.target.value == $getConstants('WORK_KIND_CONTCOMP').code) {
        this.setCtrctInfo({contcompCode: '', contcompNm: ''});
      }
    },
    onChangeAmt(target, event) {
      let value = event.target.value;
      if (target == 'ctrtSumAmt') {
        if (value) {
          value = Math.floor(value);
          const ctrtSumAmt = value;
          const ctrtTaxexe = this.ctrctInfo.ctrtTaxexe;

          const ctrtVat = $_getVatAsSumAmt(ctrtSumAmt, ctrtTaxexe);
          const ctrtAmt = $_getAmt(ctrtSumAmt, ctrtTaxexe, ctrtVat);
          this.onSetCtrctInfo({ctrtSumAmt, ctrtVat, ctrtAmt, ctrtTaxexe});
        } else {
          this.onSetCtrctInfo({ctrtSumAmt: '', ctrtVat: '', ctrtAmt: '', ctrtTaxexe: ''});
        }
      }
      if (target == 'ctrtTaxexe') {
        const ctrtSumAmt = this.ctrctInfo.ctrtSumAmt;
        const ctrtTaxexe = value;

        const ctrtVat = $_getVatAsSumAmt(ctrtSumAmt, ctrtTaxexe);
        const ctrtAmt = $_getAmt(ctrtSumAmt, ctrtTaxexe, ctrtVat);

        this.onSetCtrctInfo({ctrtSumAmt, ctrtVat, ctrtAmt, ctrtTaxexe});
      }
    },
    onChangePaymentRatio(target, event) {
      const value = event.target.value;
      if (target == 'payCashRatio') {
        if (!value || 100 < Number(value)) {
          this.onSetCtrctInfo({payCashRatio: '', payCheckRatio: '', payCheck2Ratio: ''});
        } else {
          const payCashRatio = parseFloat(value).toFixed(2);
          const payCheckRatio = parseFloat(100 - payCashRatio).toFixed(2);
          const payCheck2Ratio = 0;
          this.onSetCtrctInfo({payCashRatio, payCheckRatio, payCheck2Ratio});
        }
      }
      if (target == 'payCheckRatio') {
        if (!value || 100 < Number(value)) {
          this.onSetCtrctInfo({payCheckRatio: '', payCheck2Ratio: ''});
        } else {
          const payCashRatio = Number(this.ctrctInfo.payCashRatio);
          const payCheckRatio = parseFloat(value).toFixed(2);
          const payCheck2Ratio = parseFloat(100 - payCashRatio - payCheckRatio).toFixed(2);
          this.onSetCtrctInfo({payCheckRatio, payCheck2Ratio});
        }
      }
      if (target == 'payCheck2Ratio') {
        if (!value || 100 < Number(value)) {
          this.onSetCtrctInfo({payCheck2Ratio: ''});
        } else {
          const payCashRatio = Number(this.ctrctInfo.payCashRatio);
          const payCheckRatio = Number(this.ctrctInfo.payCheckRatio);
          const payCheck2Ratio = Number(parseFloat(value).toFixed(2));
          const totalRatio = payCashRatio + payCheckRatio + payCheck2Ratio;
          if (totalRatio == 100) {
            this.onSetCtrctInfo({payCheck2Ratio});
          } else {
            this.onSetCtrctInfo({payCheck2Ratio: parseFloat(100 - payCashRatio - payCheckRatio).toFixed(2)});
          }
        }
      }
    },
    onCalculatePrfmInsrAmt(event) {
      const ctrtSumAmt = Number(this.ctrctInfo.ctrtSumAmt);
      const prfmInsrRate = event === undefined ? this.ctrctInfo.prfmInsrRate : event.target.value;
      if (!prfmInsrRate || 100 < Number(prfmInsrRate)) {
        this.onSetCtrctInfo({prfmInsrRate: '', prfmInsrAmt: ''});
      } else {
        const prfmInsrAmt = (ctrtSumAmt * prfmInsrRate) / 100;
        this.onSetCtrctInfo({prfmInsrRate, prfmInsrAmt});
      }
    },
    onCalculateDelayAmt(event) {
      const ctrtSumAmt = Number(this.ctrctInfo.ctrtSumAmt);
      const delayRate = event === undefined ? this.ctrctInfo.delayRate : event.target.value;
      if (!delayRate || 100 < Number(delayRate)) {
        this.onSetCtrctInfo({delayRate: '', delayAmt: ''});
      } else {
        const delayAmt = (ctrtSumAmt * delayRate) / 100;
        this.onSetCtrctInfo({delayRate, delayAmt});
      }
    },
    onCalculateFlawInsrAmt(event) {
      const ctrtSumAmt = Number(this.ctrctInfo.ctrtSumAmt);
      const flawInsrRate = event === undefined ? this.ctrctInfo.flawInsrRate : event.target.value;
      if (!flawInsrRate || 100 < Number(flawInsrRate)) {
        this.onSetCtrctInfo({flawInsrRate: '', flawInsrAmt: ''});
      } else {
        const flawInsrAmt = (ctrtSumAmt * flawInsrRate) / 100;
        this.onSetCtrctInfo({flawInsrRate, flawInsrAmt});
      }
    },
    onChangeStampTaxYn(event) {
      const stampTaxYn = $_externalCheckboxValue(event.target.checked);
      this.setCtrctInfo({stampTaxYn, stampTaxOwnRate: '', stampTaxAmt: ''});

      if (stampTaxYn === $getConstants('EXTERNAL_Y').code) {
        this.onSearchStampTaxAmt(false);
      }
    },
    onChange_stampTaxOwnRate(value) {
      if (100 < value) {
        this.$alert({title: '인지세', message: '인지세율이 100보다 클 수 없습니다.'});
        value = '100';
      }
      this.setCtrctInfo({stampTaxOwnRate: value});
    },
    async onSearchStampTaxAmt(isChangeStampTaxYn) {
      this.isSearchingStampTaxAmt = true;
      if (this.ctrctInfo.ctrtSumAmt > 0) {
        let param = {ctrtSumAmt: this.ctrctInfo.ctrtSumAmt};
        const response = await selectStampTaxAmt(param);

        const obj = {stampTaxAmt: response.data > 0 ? response.data : ''};
        if (isChangeStampTaxYn) {
          obj.stampTaxYn = response.data > 0 ? $getConstants('EXTERNAL_Y').code : $getConstants('EXTERNAL_N').code;
        }

        this.setCtrctInfo(obj);
        this.isSearchingStampTaxAmt = false;
      }
    },
    onSetCtrctInfo(obj) {
      setTimeout(() => {
        this.setCtrctInfo(obj);

        if (obj.hasOwnProperty('ctrtSumAmt')) {
          this.onCalculatePrfmInsrAmt();
          this.onCalculateDelayAmt();
          this.onCalculateFlawInsrAmt();
          this.onSearchStampTaxAmt(true);
        }
      }, 100);
    },
    onDeleteComplete(flNo) {
      if (flNo == 0) {
        let param = {
          flNo: flNo,
          ctrtChgTo: this.searchInfo.ctrtChgTo,
        };
        updateFileNoAndRgprInfo(param).then(response => {
          this.callEvent({name: 'cntrctChangeDtls_onSearch'});
        });
      }
    },
  },
};
</script>

<style></style>
