export default {
  /** 입찰 차수조회 */
  RFQ_DEGREE_PRINT: 4,
  /** 입찰 견적대비표 */
  RFQ_COMPARE_PRINT: 5,
  /** 계약서 */
  PO_CONTRACT: 8,
  /** 발주서 */
  PO_ORDER_PRINT: 10,
  /** 입찰 차수조회 */
  SUB_RFQ_DEGREE_PRINT: 17,
  /** 입찰 견적대비표 */
  SUB_RFQ_COMPARE_PRINT: 18,
  /** 계약서 */
  SUB_PO_CONTRACT: 20,
  /** 입찰정보 */
  RFQ_INFO_PRINT1: 27,
  /** 입찰정보 */
  SUB_RFQ_INFO_PRINT1: 28,
  /** 입찰정보(입찰참여사) */
  RFQ_INFO_PRINT2: 33,
  /** 입찰정보(입찰참여사) */
  SUB_RFQ_INFO_PRINT2: 34,
  /** 근로소득지급명세서 */
  PAYMENT_STATEMENT: 30,
  /** 근로계약서 (일용직) */
  LABOR_CONTRACT_DAILY: 31,
  /** 근로계약서 (상용직) */
  LABOR_CONTRACT_FIXED_TERM: 32,
  /** 기성 대 투입현황 */
  PXCOND_INPUT: 37,
  /** 급여 지급 명세서 */
  PAYROLL: 39,
  /** 자재 정기정산 */
  SETTLEMENT_FIXED_TIME_MAT: 41,
  /** 노무비 정기정산 */
  SETTLEMENT_FIXED_TIME_LABOR: 42,
  /** 장비비 정기정산 */
  SETTLEMENT_FIXED_TIME_EQUIPMENT: 43,
  /** 경비 정기정산 */
  SETTLEMENT_FIXED_TIME_EXPENSE: 44,
  /** 외주 정기정산 */
  SETTLEMENT_FIXED_TIME_SUBCON: 45,
  /** 기안문 */
  DRAFT: 999,
};
