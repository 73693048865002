/** 구매요청목록 */
export const selectPrList = param => axios.post('/pr/selectPrList', param);
/** 구매요청정보 */
export const selectPrInfo = prNo => axios.get(`/pr/selectPrInfo/${prNo}`);
/** 구매요청내역 */
export const selectPrItemList = prNo => axios.get(`/pr/selectPrItemList/${prNo}`);
/** 구매요청 품의정보 */
export const selectPrApprovalInfos = prNo => axios.get(`/pr/selectPrApprovalInfos/${prNo}`);
/** 구매요청 저장 */
export const savePrInfo = (saveInfo, afterValidation) => $save('/pr/savePrInfo', saveInfo, {afterValidation});
/** 구매요청 삭제 */
export const deletePrInfo = prNo => axios.delete(`/pr/deletePrInfo/${prNo}`);
/** 구매요청 파일번호삭제 */
export const deletePrFlNo = prNo => axios.post(`/pr/deletePrFlNo/${prNo}`, {});
