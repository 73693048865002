import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
export default that => {
  return {
    Cfg: {CanSort: 0},
    Def: {
      Row: {NoColor: 1, CanFocus: 0},
    },
    Cols: [
      {
        Header: '낙찰여부',
        Name: 'resultYn',
        Type: 'Text',
        Align: 'center',
        Width: 80,
        DefaultValue: $getConstants('DEFAULT').code,
        CustomFormat: v => (v === BIDDING_CONSTANTS.CODES.RESULT_Y ? '낙찰' : '탈락'),
      },
      {
        Header: '협력사명',
        Name: 'frmNm',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CanEdit: 0,
        TextStyle: 5,
        TextColor: '#FF0000',
        Cursor: 'Pointer',
      },
      {
        Header: '현설참가신청여부',
        Name: 'spotDscrApplyYn',
        Type: 'Text',
        Align: 'center',
        Width: 120,
        DefaultValue: $getConstants('DEFAULT').code,
        CustomFormat: v => {
          return that.isExistSpotDscr ? (v === BIDDING_CONSTANTS.CODES.SPOT_DSCR_APPLY_Y ? 'O' : 'X') : '-';
        },
      },
      {
        Header: '현설참여여부',
        Name: 'spotDscrChkYn',
        Type: 'Text',
        Align: 'center',
        Width: 100,
        DefaultValue: $getConstants('DEFAULT').code,
        CustomFormat: v => {
          return that.isExistSpotDscr ? (v === BIDDING_CONSTANTS.CODES.SPOT_DSCR_CHK_Y ? 'O' : 'X') : '-';
        },
      },
      {
        Header: '응찰여부',
        Name: 'quotSbmtYn',
        Type: 'Text',
        Align: 'center',
        Width: 90,
        DefaultValue: $getConstants('DEFAULT').code,
        CustomFormat: v => (that.isBeforeRfqBidding ? '-' : v === BIDDING_CONSTANTS.CODES.QUOT_SBMT_Y ? 'O' : 'X'),
      },
      {Header: '견적합계금액(VAT포함)', Name: 'quotAmtText', Type: 'Text', Align: 'right'},
    ],
  };
};
