<template>
  <pmis-content-box>
    <template #title>발주내역</template>
    <template #header-left>
      <iui-text type="amount" width="180px" prefix="공급가액" :enable="false" :value="orderInfo.poAmt" />
      <iui-text type="amount" width="180px" prefix="부가세액" :enable="false" :value="orderInfo.vatAmt" />
      <iui-text type="amount" width="180px" prefix="합계금액" :enable="false" :value="orderInfo.totAmt" />
    </template>
    <ib-sheet :uid="_uid" :options="sheetOpt" :loadSearchData="orderItemList" :events="{}" />
  </pmis-content-box>
</template>

<script>
import sheetOpt from '@/view/bidding/po/mat/order/components/sheetOptions/poOrderItemList.js';
export default {
  data() {
    return {sheetOpt: sheetOpt(this)};
  },
  beforeCreate() {
    $mapGetters(this, ['orderInfo', 'orderItemList']);
  },
  methods: {},
};
</script>

<style></style>
