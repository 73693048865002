<template>
  <pmis-content-box :loading="loading">
    <template v-slot:title>기본 데이터 복제</template>
    <template v-slot:title-right>
      <iui-button value="복제" @click="onCopy" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onRenderFirstFinish,
        onDataLoad: grid_onDataLoad,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/BaseInfoSheet.js';
import {selectBaseInfoList, selectMenuListCount, saveCopyBaseInfoList} from '../api/member.js';
export default {
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'companyInfo']);
  },
  created() {
    this.addEvent([{name: 'baseInfoSearch', func: this.onBeforeSearch}]);
  },
  data() {
    return {
      curPgmCd: '',
      focusKeyCode: '',
      loading: false,
      Options: $addColCheckbox(options(this)),
      loadSearchData: [],
    };
  },
  mounted() {},
  methods: {
    onBeforeSearch() {
      if (this.curPgmCd == this.searchInfo.pgmCd) return;
      this.curPgmCd = this.searchInfo.pgmCd;
      this.focusKeyCode = '';
      this.onSearch();
    },
    async onSearch() {
      this.loading = true;
      const response = await selectBaseInfoList(this.searchInfo);
      if (response.status == 200) {
        this.sheet.loadSearchData(response.data);
        this.loading = false;
      }
    },
    async onCopy() {
      let copyList = this.sheet.getSaveJson(0).data.filter(item => item.chk === '1');
      if (copyList.length == 0) {
        this.$alert({title: '복제', message: '복제할 대상이 선택되지 않았습니다.'});
        return;
      }
      if (copyList.find(row => row.cmnDCd == $getConstants('N').code) !== undefined) {
        const response = await selectMenuListCount({pgmCd: this.searchInfo.pgmCd});
        if (response.data == 0) {
          this.$alert({
            title: '복제',
            message: '시스템 관리자를 복제하려는 경우에는 프로그램 메뉴가<br/>먼저 생성되어 있어야 합니다.',
          });
          return;
        }
      }

      if (await this.$confirm({title: '복제', message: '선택한 항목들을 복제하시겠습니까?'})) {
        const response = await saveCopyBaseInfoList({copyList});
        if (response.status == 200) {
          this.focusKeyCode = response.data;
          this.onSearch();
        } else {
          this.$alert({title: '복제', message: '복제를 실패했습니다.'});
        }
      }
    },
    grid_onDataLoad() {
      if (this.focusKeyCode) {
        let focusRow = this.sheet.getDataRows().find(row => row.cmnDCd == this.focusKeyCode);
        if (focusRow !== undefined) {
          setTimeout(() => this.sheet.focus(focusRow), 100);
        }
      }
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style></style>
