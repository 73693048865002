const state = {
  pageData: {
    level: '',
    pageId: '',
    name: '',
    pageName: '',
    webName: '',
    seq: '',
    size: '',
    webLocation: '',
  },
  new: false,
  edit: false,
  focusKeyCode: {
    focusKey1: '',
    focusKey2: '',
  },
};

const getters = {
  pageData: state => state.pageData,
  new: state => state.new,
  edit: state => state.edit,
  focusKeyCode: state => state.focusKeyCode,
};

const actions = {};

const mutations = {
  setPageData(state, payload) {
    for (let key in state.pageData) {
      if (payload && payload.hasOwnProperty(key)) {
        state.pageData[key] = payload[key];
      }
    }
  },
  initPageData(state) {
    for (let key in state.pageData) {
      state.pageData[key] = '';
    }
  },
  updatePageData(state, event) {
    state.pageData[event.target.name] = event.target.value;
  },
  setNewState(state) {
    state.new = true;
    state.edit = false;
  },
  setEditState(state) {
    state.new = false;
    state.edit = true;
  },
  setFocusKeyCode(state, payload) {
    for (let key in state.focusKeyCode) {
      if (payload && payload.hasOwnProperty(key)) {
        state.focusKeyCode[key] = payload[key];
      }
    }
  },
  initFocusKeyCode(state) {
    for (let key in state.focusKeyCode) {
      state.focusKeyCode[key] = '';
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
