<template>
  <pmis-page ref="page" :search-project-visible="false" @search-click="onSearch">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px"><PrjList :autoFocus="false"/></i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <pmis-tab-box>
                  <template v-slot:title>Bar-Chart</template>
                  <status />
                </pmis-tab-box>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import PrjList from '@/view/Project/components/PrjList.vue';
import store from './store/status.js';
import Status from './components/Status.vue';
export default {
  components: {
    Status,
    PrjList,
  },
  beforeCreate() {
    $init(this, store);
  },

  created() {
    this.addFuncPrj(this.onSearch);
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'initGantChart', param: true});
    },
  },
};
</script>
