export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      ReqStatusName: 'status',
      CanEdit: false,
    },
    Cols: [
      {
        Name: 'workDt',
        Header: '투입일자',
        Extend: preset.date,
      },
      {
        Name: 'subCodeName',
        Header: '외주명',
        Align: 'left',
        RelWidth: 1,
        Type: 'Text',
      },
      {
        Name: 'mgName',
        Header: '중공종명',
        Align: 'left',
        RelWidth: 1,
        Type: 'Text',
      },
      {
        Name: 'itemSeq',
        Header: '내역코드',
        Align: 'center',
        Width: 80,
        Type: 'Text',
      },
      {
        Name: 'itemName',
        Header: '내역명',
        Align: 'left',
        RelWidth: 1,
        Type: 'Text',
      },
      {
        Name: 'ssize',
        Header: '규격',
        Align: 'left',
        RelWidth: 1,
        Type: 'Text',
      },
      {
        Name: 'unit',
        Header: '단위',
        Align: 'center',
        RelWidth: 1,
        Type: 'Text',
        Format: $getConstants('UNIT').code,
      },
      {
        Name: 'qty',
        Header: '투입수량',
        Extend: preset.quantity,
        RelWidth: 1,
      },
      {
        Name: 'amt',
        Header: '투입금액',
        Extend: preset.amountFormula,
        RelWidth: 1,
      },
    ],
  };
};
