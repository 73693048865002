<template>
  <pmis-page ref="page" :search-project-visible="false">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px" rowspan="2"><PrjList /></i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <div class="flex" style="height: 100%;">
                  <iui-tab :comps="tabList" @click="onTabClick"></iui-tab>
                </div>
              </i-col>
              <i-spacer />
              <i-col>
                <MatAnmonMDtl v-if="tabIndex == 0" />
                <LabtAnmonMDtl v-if="tabIndex == 1" />
                <EqutAnmonMDtl v-if="tabIndex == 2" />
                <SubAnmonMDtl v-if="tabIndex == 3" />
              </i-col>
            </i-row>
            <i-spacer />
            <i-row>
              <i-col>
                <MatTargetList v-if="tabIndex == 0" />
                <LabtTargetList v-if="tabIndex == 1" />
                <EqutTargetList v-if="tabIndex == 2" />
                <SubTargetList v-if="tabIndex == 3" />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
    <iui-modal name="anyTimeRegisterPop" :title="costTypeTitle" :btns="registerBtns" sizeType="size1">
      <AnyTimeRegisterPop :costType="costType" />
    </iui-modal>
  </pmis-page>
</template>

<script>
// 수시정산등록
import PrjList from '@/view/Project/components/PrjList.vue';
import MatAnmonMDtl from './components/MatAnmonMDtl.vue';
import MatTargetList from './components/MatTargetList.vue';
import LabtAnmonMDtl from './components/LabtAnmonMDtl.vue';
import LabtTargetList from './components/LabtTargetList.vue';
import EqutAnmonMDtl from './components/EqutAnmonMDtl.vue';
import EqutTargetList from './components/EqutTargetList.vue';
import SubAnmonMDtl from './components/SubAnmonMDtl.vue';
import SubTargetList from './components/SubTargetList.vue';
import AnyTimeRegisterPop from './components/AnyTimeRegisterPopM.vue';
import store from './store/anyTime.js';
export default {
  components: {
    PrjList,
    MatAnmonMDtl,
    MatTargetList,
    LabtAnmonMDtl,
    LabtTargetList,
    EqutAnmonMDtl,
    EqutTargetList,
    SubAnmonMDtl,
    SubTargetList,
    AnyTimeRegisterPop,
  },

  data() {
    return {
      tabList: [
        {
          tabNm: '재료비',
          compNm: 'Material',
          path: '@/view/closing/anyTime/components/MatAnmonMListM.vue',
        },
        {
          tabNm: '노무비',
          compNm: 'Labor',
          path: '@/view/closing/anyTime/components/LabtAnmonMListM.vue',
        },
        {
          tabNm: '장비비',
          compNm: 'Equipment',
          path: '@/view/closing/anyTime/components/EqutAnmonMListM.vue',
        },
        {
          tabNm: '외주',
          compNm: 'Subcon',
          path: '@/view/closing/anyTime/components/SubAnmonMListM.vue',
        },
      ],
      tabIndex: 0,
      costTypeObj: {0: 'mat', 1: 'lab', 2: 'equip', 3: 'sub'},
      costTypeTitleObj: {0: '재료비', 1: '노무비', 2: '장비비', 3: '외주비'},
      costTypeLoadSearchDataObj: {
        0: this.setMatAnmonList,
        1: this.setLabAnmonList,
        2: this.setEqutAnmonList,
        3: this.setSubAnmonList,
      },
      registerBtns: [{name: '확인', callback: this.onRegisterConfirm}],
    };
  },
  computed: {
    costType() {
      return this.costTypeObj[this.tabIndex];
    },
    costTypeTitle() {
      return `수시정산등록 - ${this.costTypeTitleObj[this.tabIndex]}`;
    },
  },
  async beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['searchInfo']);
    $mapGetters(this, 'material', ['anyTimeMatSaveDataReqDto']);
    $mapMutations(this, 'material', ['setMatAnmonList']);
    $mapMutations(this, 'labor', ['setLabAnmonList']);
    $mapMutations(this, 'equipment', ['setEqutAnmonList']);
    $mapMutations(this, 'subcon', ['setSubAnmonList']);
  },
  created() {
    this.addFuncNew(this.onAdd);
  },
  methods: {
    onAdd() {
      this.$modal.show('anyTimeRegisterPop');
    },
    onRegisterConfirm() {
      this.callEvent({name: 'anyTimeRegister_getRows', param: this.getAnyTimeRegisterData});
    },
    getAnyTimeRegisterData(rows) {
      if (!rows.length) {
        return;
      }

      this.callEvent({name: 'changeType', param: 'ADD'});

      this.costTypeLoadSearchDataObj[this.tabIndex](rows);
      this.$modal.hide('anyTimeRegisterPop');
    },
    onTabClick(e) {
      if (this.tabIndex === e) return;
      this.tabIndex = e;
    },
  },
};
</script>

<style scoped></style>
