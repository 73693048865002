<template>
  <pmis-page :search-project-visible="false">
    <pmis-content-box title-type="tab" no-border-box no-padding-box>
      <template v-slot:title>표준세율</template>
      <template v-slot:title-right>
        <iui-button value="표준세율복제" @click="copyTaxRateBtn" />
      </template>
      <ib-sheet
        :uid="_uid"
        :options="sheetOpt"
        :loadSearchData="loadSearchData"
        @loadSheet="loadSheet"
        :events="{
          onRenderFirstFinish: sheet_onRenderFirstFinish,
          onBeforeChange: sheet_onBeforeChange,
        }"
      />
    </pmis-content-box>
  </pmis-page>
</template>

<script>
import sheetOpt from './components/sheetOption/standardTaxRate.js';
import {selectTaxRateList, copyTaxRate, saveTaxRate, deleteTaxRate} from './api/standardTaxRate.js';

export default {
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],
    };
  },
  created() {
    this.addFuncSearch(this.onSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },
  methods: {
    async copyTaxRateBtn() {
      if (
        !(await this.$confirm({
          title: '표준세율복제',
          message: '표준세율 복제 시 기존 데이터는 모두 삭제됩니다. 정말 복제하시겠습니까?',
        }))
      )
        return;

      copyTaxRate().then(response => {
        if (response.status == 200) {
          this.onSearch();
        }
      });
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.onSearch();
    },
    sheet_onRenderFirstFinish(e) {
      e.sheet.setAutoMerge({headerMerge: 6});
    },
    sheet_onBeforeChange(e) {
      if (e.col != 'entryDt') return;

      const val = e.val;
      return isNaN(parseInt(val)) ? '' : parseInt(val);
    },
    onSearch() {
      selectTaxRateList().then(response => (this.loadSearchData = response.data));
    },
    onAdd() {
      this.sheet.addRow({init: {pgmCd: this.pgmCd}});
    },
    async onSave() {
      if (!this.sheet.hasChangedData()) {
        this.$alert({title: '표준세율 저장', message: '변경된 내용이 없습니다.'});
        return;
      }
      let duplicateRows = this.sheet.getRowsByDup('applyDt', false, false, false);
      if (duplicateRows.length > 0) {
        this.$alert({title: '표준세율 저장', message: '적용일자는 중복될 수 없습니다.'});
        return;
      }

      const saveRows = this.sheet.getSaveJson().data;
      const validCols = this.sheet.getShownCols().filter(col => col !== 'cbx');

      let validObj = undefined;
      saveRows.every(row => {
        validCols.every(col => {
          if ((String(col).endsWith('Dt') && !row[col]) || row[col] <= 0) {
            validObj = {row, col};
            return false;
          }
          return true;
        });
        return validObj === undefined;
      });

      if (validObj !== undefined) {
        const row = this.sheet.getRowById(validObj.row.id);
        const col = validObj.col;

        this.sheet.focus(row, col);
        return;
      }

      if (saveRows.length) {
        if (!(await this.$confirm({title: '표준세율등록', message: '표준세율을 저장하시겠습니까?'}))) {
          return;
        }
      }

      const param = {list: $_statusToCud(saveRows)};
      const response = await saveTaxRate(param);

      if (response.status === 200) {
        this.onSearch();
      }
    },
    async onDelete() {
      let checkedRows = this.sheet.getRowsByChecked('cbx');
      if (0 == checkedRows.length) {
        await this.$alert({title: '표준세율 삭제', message: '삭제할 대상을 선택해야합니다.'});
        return;
      }

      let deleteList = [];
      checkedRows.forEach(row => {
        if (row.Added) {
          this.sheet.removeRow(row);
        } else {
          deleteList.push({pgmCd: row.pgmCd, applyDt: IBSheet.dateToString(row.applyDt, 'yyyyMMdd')});
        }
      });

      let length = deleteList.length;
      let title = '표준세율 삭제';
      let message = '표준세율정보를 삭제하시겠습니까?';

      if (0 == length) return;
      if (1 == length) {
        const date = deleteList[0].applyDt.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
        message = `[${date}] ${message}`;
      } else {
        message = `총 ${length}개의 ${message}`;
      }

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      const param = {list: deleteList};
      const response = await deleteTaxRate(param);

      if (response.status === 200) {
        this.onSearch();
      }
    },
  },
};
</script>

<style></style>
