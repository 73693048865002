import {deletePo as deletePo_mat, selectCompletedRfqList} from '@/view/bidding/po/mat/api/po.js';
import {deletePo as deletePo_sub, selectCompletedSubRfqList} from '@/view/bidding/po/sub/api/subPo.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants';

export default {
  name: 'ContractInfoMixin',
  data() {
    return {
      PoTargetRfqListModalBtns: [
        {name: '새 계약서 작성', callback: this.beforeSetAddPoData},
        {name: '확인', callback: this.onConfirm_PoTargetRfqListModal},
      ],
    };
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'poStatus', 'bidType']);
    $mapMutations(this, ['setPoIsNew', 'setPoInfo', 'setRfqInfo', 'setPoItemList']);
    $mapActions(this, ['initPo']);
  },
  created() {
    this.addFuncNew(this.onAdd);
    this.addFuncDelete(this.onDelete);
  },
  methods: {
    async onAdd() {
      const selectCompletedRfqListApi = {mat: selectCompletedRfqList, sub: selectCompletedSubRfqList}[this.bidType];
      const response = await selectCompletedRfqListApi({orgCd: this.pgmCd});
      if (response.data.length) {
        this.$modal.show('PoTargetRfqListModal');
      } else {
        this.beforeSetAddPoData();
      }
    },
    onConfirm_PoTargetRfqListModal() {
      // 계약등록 - 입찰연계 팝업
      this.callEvent({
        name: 'PoTargetRfqList_Confirm',
        param: data => {
          data.rfqInfo.taxTypeDirectPercent = data.rfqInfo.taxRate;
          data.rfqInfo.taxTypeDirectAmt = data.rfqInfo.lastVatAmt;
          this.beforeSetAddPoData(data);
        },
      });
    },
    beforeSetAddPoData(data) {
      this.initPo();
      this.setPoIsNew(true);
      this.canSave = true;
      this.canDelete = false;

      this.setAddPoData(data);

      this.$modal.hide('PoTargetRfqListModal');
    },
    async onDelete() {
      if (this.poStatus.isPoApprovalRequest) {
        this.$alert({
          title: '계약정보 삭제',
          message: "'계약품의중'인 계약정보는 품의서를 회수한 후 삭제가 가능합니다.",
        });
        return;
      }

      if (this.poInfo.poSt > BIDDING_CONSTANTS.PROGRESS_STATE.PO_APPROVAL_REQUEST) {
        this.$alert({title: '계약정보 삭제', message: '해당 계약정보는 삭제할 수 없습니다.'});
        return;
      }

      const contSeqTxt = this.poStatus.isChangeContSeq ? `${parseInt(this.poInfo.contSeq)}차 ` : '';
      const title = '계약정보 삭제';
      const message = `${contSeqTxt}계약정보를 삭제하시겠습니까?`;

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      const {poNo, contSeq} = this.poInfo;
      const deletePoApi = {mat: deletePo_mat, sub: deletePo_sub}[this.bidType];

      deletePoApi(poNo, contSeq)
        .then(response => {
          if (response.status == 200) {
            this.callEvent({name: 'ContractList_Search', param: response.data.poNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
  },
};
