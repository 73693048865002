<template>
  <pmis-content-box>
    <form onsubmit="return false;">
      <iui-container-new type="css-flex">
        <i-row height="260px">
          <i-col min-width="530px">
            <iui-container-new type="table" theme="bullet">
              <i-row>
                <i-col-header :required="isEditableLE">대분류</i-col-header>
                <i-col>
                  <iui-text
                    name="leCode"
                    type="code"
                    regExp="[^0-9]"
                    :value.sync="leCode"
                    :enable="isNew && isEditableLE"
                    min-length="6"
                    max-length="6"
                    width="60px"
                    :required="isNew && isEditableLE"
                    :errorMessage="{title: '대분류', message: '대분류를 입력해주세요.'}"
                  />
                  <span style="width:115px" />
                  <iui-text
                    name="leName"
                    :value.sync="leName"
                    :enable="isEditableLE"
                    :required="isEditableLE"
                    :errorMessage="{title: '대분류', message: '대분류명을 입력해주세요.'}"
                    max-length="100"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header :required="isEditableME">중분류</i-col-header>
                <i-col colspan="">
                  <iui-text
                    name="leCode"
                    type="smallcode"
                    :value="level > 2 ? leCode : ''"
                    :enable="false"
                    max-length="6"
                    width="60px"
                  />
                  <iui-text
                    name="meCode"
                    type="smallcode"
                    regExp="[^0-9]"
                    :value.sync="meCode"
                    :enable="isNew && isEditableME"
                    min-length="2"
                    max-length="2"
                    width="30px"
                    :required="isNew && isEditableME"
                    :errorMessage="{title: '중분류', message: '중분류를 입력해주세요.'}"
                  />
                  <span style="width:80px" />
                  <iui-text
                    name="meName"
                    :value.sync="meName"
                    :enable="isEditableME"
                    :required="isEditableME"
                    max-length="100"
                  />
                </i-col>
              </i-row>
              <i-row><i-col></i-col></i-row>
              <i-row>
                <i-col-header :required="isEditableEquip">장비코드</i-col-header>
                <i-col>
                  <iui-text name="leCode" type="smallcode" :value="leCodeInEquipCode" :enable="false" width="60px" />
                  <iui-text name="meCode" type="smallcode" :value="meCodeInEquipCode" :enable="false" width="30px" />
                  <iui-text
                    type="largecode"
                    regExp="[^0-9]"
                    :value="String(this.equipCodeInfo.equipCode || '').slice(8)"
                    @change="
                      setEquipCodeInfo({equipCode: String(internalEquipClInfo.meCode).concat($event.target.value)})
                    "
                    :enable="isNew && isEditableEquip"
                    max-length="8"
                    min-length="8"
                    width="80px"
                    :required="isNew && isEditableEquip"
                    :errorMessage="{title: '장비코드', message: '장비코드를 입력해주세요.'}"
                  />
                  <iui-text
                    name="itemName"
                    :value="equipCodeInfo.itemName"
                    @change="setEquipCodeInfo({itemName: $event.target.value})"
                    :enable="isEditableEquip"
                    max-length="100"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>규격</i-col-header>
                <i-col>
                  <iui-text
                    name="size"
                    :value="equipCodeInfo.size"
                    @change="setEquipCodeInfo({size: $event.target.value})"
                    :enable="isEditableEquip"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col colspan="2">
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header>단위</i-col-header>
                      <i-col>
                        <iui-text name="unitName" type="smallcode" :value="unitName" :enable="false" />
                        <iui-button value="검색" @click="onUnitPopup" :enable="isEditableEquip" />
                      </i-col>
                      <i-col></i-col>
                      <i-col></i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
              <i-row>
                <i-col colspan="2">
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header>사용여부</i-col-header>
                      <i-col>
                        <iui-checkbox-group
                          name="useDs"
                          :items="[{label: '사용', value: $getConstants('Y').code}]"
                          width="120px"
                          :checkedValues="[equipCodeInfo.useDs]"
                          :enable="isEditableEquip"
                          @change="setEquipCodeInfo({useDs: $event.target.checked ? $event.target.value : ''})"
                        />
                      </i-col>
                      <i-col></i-col>
                      <i-col></i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
            </iui-container-new>
          </i-col>
          <i-col></i-col>
          <i-spacer />
          <i-col width="220px" height="300px">
            <pmis-image-file
              :file-number.sync="flNo"
              :saveAuth="isEditableEquip"
              @delete-complete="image_delete_complete"
              @delete-error="image_delete_error"
              id="equipImage"
              :initInternalImageSource="initInternalImageSource"
              :clear.sync="clearFlNo"
            />
          </i-col>
        </i-row>
      </iui-container-new>
    </form>
    <iui-modal name="unitModal" title="단위 검색" width="1000px" height="500px" :btns="modalBtns">
      <UnitPopup />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import UnitPopup from '@/view/sysManage/stdCodeManage/components/UnitPopup.vue';
import {saveEqutInfo, saveImageFileNo} from '../api/EquipCodeRegist.js';

export default {
  components: {UnitPopup},
  props: {
    base: {
      type: Boolean,
      default: false,
    },
    equipClInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      modalBtns: [{name: '확인', callback: this.onUnitPopupConfirm}],
      internalEquipClInfo: {},
      equipCodeInfo: {},
      unitCodeList: [],
      initInternalImageSource: false,
      clearFlNo: false,
    };
  },
  computed: {
    flNo: {
      set(value) {
        this.equipCodeInfo.flNo = value;
      },
      get() {
        return this.equipCodeInfo.flNo || 0;
      },
    },
    isEditableLE() {
      return this.internalEquipClInfo?.level === (this.isNew ? 1 : 2);
    },
    isEditableME() {
      return this.internalEquipClInfo?.level === (this.isNew ? 2 : 3);
    },
    isEditableEquip() {
      return !!this.equipCodeInfo.equipCode || this.internalEquipClInfo?.level === 3;
    },
    isNew() {
      return this.cud === 1;
    },
    level() {
      return this.equipCodeInfo.level ?? this.internalEquipClInfo?.level + (this.isNew ? 1 : 0);
    },
    leCode: {
      set(value) {
        this.internalEquipClInfo.code = value;
        this.internalEquipClInfo.leCode = value;
      },
      get() {
        return this.level > (this.isNew ? 2 : 1) ? this.equipCodeInfo.leCode ?? this.internalEquipClInfo?.leCode : '';
      },
    },
    leName: {
      set(value) {
        this.internalEquipClInfo.leName = value;
        this.internalEquipClInfo.name = value;
      },
      get() {
        return this.level > (this.isNew ? 2 : 1) ? this.equipCodeInfo.leName ?? this.internalEquipClInfo?.leName : '';
      },
    },
    meCode: {
      set(value) {
        const fullValue = String(this.internalEquipClInfo.leCode).concat(value);
        this.internalEquipClInfo.code = fullValue;
        this.internalEquipClInfo.meCode = fullValue;
      },
      get() {
        return String(
          this.level > (this.isNew ? 3 : 2) ? this.equipCodeInfo.meCode ?? this.internalEquipClInfo?.meCode ?? '' : ''
        ).slice(6);
      },
    },
    meName: {
      set(value) {
        this.internalEquipClInfo.meName = value;
        this.internalEquipClInfo.name = value;
      },
      get() {
        return this.level > (this.isNew ? 3 : 2) ? this.equipCodeInfo.meName ?? this.internalEquipClInfo?.meName : '';
      },
    },
    leCodeInEquipCode() {
      return String(
        this.isNew && this.internalEquipClInfo?.level === 3
          ? this.internalEquipClInfo?.leCode
          : this.equipCodeInfo.leCode ?? ''
      );
    },
    meCodeInEquipCode() {
      return String(
        this.isNew && this.internalEquipClInfo?.level === 3
          ? this.internalEquipClInfo?.meCode
          : this.equipCodeInfo.meCode ?? ''
      ).slice(6);
    },
    unitName() {
      const codeInfo = this.unitCodeList.find(item => item.code === String(this.equipCodeInfo.unit));
      return codeInfo?.codeNm;
    },
  },
  watch: {
    flNo(val) {
      this.setEquipCodeInfo({flNo: val});
    },
    equipClInfo: {
      handler(value) {
        this.internalEquipClInfo = value;
      },
      deep: true,
    },
  },
  created() {
    this.addEvent([{name: 'onUnitPopupConfirm', func: this.onUnitPopupConfirm}]);
    this.addEvent([{name: 'clearImage', func: this.clearImage}]);
    this.internalEquipClInfo = this.equipClInfo;
    this.setUnitCodeList();
  },
  methods: {
    clearImage() {
      this.clearFlNo = true;
    },
    async setUnitCodeList() {
      this.unitCodeList = await $getCode($getConstants('UNIT').code);
    },
    async onSave() {
      try {
        const param = {...this.equipCodeInfo, ...this.internalEquipClInfo, base: this.base};
        const response = await saveEqutInfo(param);
        if (response.data) {
          if (this.internalEquipClInfo.leCode) {
            this.equipCodeInfo['leCode'] = this.internalEquipClInfo.leCode;
          }
          if (this.internalEquipClInfo.meCode) {
            this.equipCodeInfo['meCode'] = this.internalEquipClInfo.meCode;
          }
          if (this.flNo !== 0) {
            this.initInternalImageSource = true;
          }
          return {equipCodeInfo: this.equipCodeInfo, equipClInfo: this.internalEquipClInfo};
        }
      } catch (error) {
        this.$alert({title: '저장', message: error.message});
      }
    },
    setEquipCodeInfo(data) {
      this.equipCodeInfo = {...this.equipCodeInfo, ...data};
    },
    onUnitPopup() {
      this.$modal.show('unitModal');
    },
    onUnitPopupConfirm() {
      this.callEvent({
        name: 'UnitPopupp_popConfirm',
        param: data => {
          this.setEquipCodeInfo({unit: data.unitCode});
          this.$modal.hide('unitModal');
        },
      });
    },
    image_save_complete() {
      if (this.cud != 1) {
        this.setEquipCodeInfo({flNo: this.flNo});
        this.onSaveImageFileNo();
      }
    },
    image_delete_complete() {
      this.setEquipCodeInfo({flNo: 0});
      this.onSaveImageFileNo();
    },
    image_delete_error() {
      this.$alert({title: '파일삭제', message: '파일 삭제를 실패했습니다.'});
    },
    async onSaveImageFileNo() {
      let param = {
        equipCode: this.equipCodeInfo.equipCode,
        flNo: this.equipCodeInfo.flNo,
      };
      let response = await saveImageFileNo(param);
      if (response.data) {
        this.callEvent({name: 'EquipCode_DetailList_changeFlNo', param: this.equipCodeInfo.flNo});
      }
    },
  },
};
</script>
