<template>
  <iui-content-box>
    <div class="blank_pg">
      <div class="bg"><!-- 여기에 내용을 넣지 마십시오. --></div>
      <div class="fg">
        <span class="txt">{{ text }}</span>
      </div>
    </div>
  </iui-content-box>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
};
</script>

<style></style>
