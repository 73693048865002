<template>
  <pmis-content-box>
    <template v-slot:title>공동대표정보</template>
    <template v-slot:title-right>
      <iui-button class="btn" value="신규" @click="onAdd" v-show="enable" />
      <iui-button class="btn" value="저장" @click="onSave" v-if="companyInfo.pgmCd" v-show="enable" />
      <iui-button class="btn" value="삭제" @click="onDelete" v-show="enable" />
    </template>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <ib-sheet
            :uid="_uid"
            :options="Options"
            :loadSearchData="ceoList"
            :events="{
              onRenderFirstFinish,
            }"
            @loadSheet="loadSheet"
          />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/CeoInfoSheet.js';
import {selectCeoList, saveCeoList, deleteCeoList} from '../api/member.js';
export default {
  props: {
    enable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Options: this.enable ? $addColCheckbox(options(this)) : options(this),
    };
  },

  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'ceoList']);
    $mapMutations(this, ['setCeoList']);
  },

  created() {
    this.addEvent([
      {name: 'ceoInfo_getSaveJson', func: this.getSaveJson},
      {name: 'ceoInfo_saveCeoList', func: this.onSave},
    ]);
  },

  mounted() {},

  methods: {
    onAdd() {
      this.sheet.addRow();
    },
    onSave() {
      const title = '공동대표 저장';

      if (this.sheet.getTotalRowCount() == 0) {
        this.$alert({title, message: '저장할 공동대표정보가 존재하지 않습니다.'});
        return;
      }
      if (!this.sheet.hasChangedData()) {
        this.$alert({title, message: '수정된 내용이 없습니다.'});
        return;
      }

      const saveJson = this.sheet.getSaveJson().data;
      const validItem = saveJson.find(item => !item.rpsvNm);
      if (validItem !== undefined) {
        this.$alert({title, message: '대표자명을 입력해야 합니다.'});
        return;
      }

      saveJson.forEach(el => {
        if (!el.pgmCd) el.pgmCd = this.companyInfo.pgmCd;
      });
      saveCeoList({ceoList: $_statusToCud(saveJson)}).then(response => {
        if (response.status == 200) {
          this.onSearchCeoList(this.companyInfo.pgmCd);
        }
      });
    },
    async onDelete() {
      const title = '공동대표 삭제';
      if (this.sheet.getTotalRowCount() == 0) {
        this.$alert({title, message: '삭제할 공동대표 정보가 존재하지 않습니다.'});
        return;
      }

      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({title, message: '선택된 항목이 없습니다.'});
        return;
      }

      const ceoList = checkedRows.map(item => {
        return item.seq;
      });
      if (await this.$confirm({title, message: '삭제하시겠습니까?'})) {
        const response = await deleteCeoList({ceoList});
        if (response.status == 200) {
          this.onSearchCeoList();
        }
      }
    },
    getSaveJson(callback) {
      this.sheet.getDataRows().forEach(row => {
        if (row.Added && !row.rpsvNm) {
          this.sheet.removeRow(row);
        }
      });
      if (typeof callback == 'function') callback(this.sheet.getSaveJson(0).data);
      else {
        return this.sheet.getSaveJson().data;
      }
    },

    async onSearchCeoList(pgmCd) {
      const response = await selectCeoList({pgmCd: pgmCd});
      if (response.status == 200) {
        const data = response.data;
        this.copyData = Object.assign([], data);
        this.setCeoList(response.data);
      }
    },
    onRenderFirstFinish() {},

    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  }, //methods
};
</script>

<style></style>
