export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      CanEdit: false,
    },
    Def: {
      Row: {
        CanFocus: false,
        CanFormula: true,
      },
    },
    LeftCols: [
      {
        Name: 'subCode',
        Visible: false,
      },
      {
        Header: ['가동일자', '가동일자'],
        Name: 'runDt',
        Extend: preset.date,
        Width: 90,
        VAlign: 'Top',
      },
      {
        Header: ['장비구분', '장비구분'],
        Name: 'useGb',
        Type: 'Text',
        Align: 'Center',
        Width: 80,
        Format: $getConstants('USE_GB').code,
      },
      {
        Header: ['장비코드', '장비코드'],
        Name: 'equipCode',
        Type: 'Text',
        Align: 'Center',
        Width: 130,
        ColMerge: false,
      },
      {
        Header: ['장비명', '장비명'],
        Name: 'equipName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
        ColMerge: false,
      },
      {
        Header: ['차량번호', '차량번호'],
        Name: 'carNo',
        Type: 'Text',
        Align: 'Center',
        Width: 100,
        ColMerge: false,
      },
      {
        Header: ['투입외주', '투입외주'],
        Name: 'subCodeName',
        Type: 'Text',
        Align: 'Center',
        Width: 90,
      },
    ],
    Cols: [
      {
        Header: ['가동정보', {Value: '시간', Color: '#dff9fb'}],
        Name: 'runQty',
        Extend: preset.number,
        Align: 'Center',
        Width: 50,
        ColMerge: false,
      },
      {
        Header: ['가동정보', {Value: '단가', Color: '#dff9fb'}],
        Name: 'runPrice',
        Extend: preset.unitAmountFormula,
        Width: 80,
        ColMerge: false,
      },
      {
        Header: ['가동정보', {Value: '금액', Color: '#dff9fb'}],
        Name: 'runAmt',
        Extend: preset.amountFormula,
        Width: 100,
        ColMerge: false,
      },
      {
        Header: ['가동정보', {Value: '유류대', Color: '#dff9fb'}],
        Name: 'oilAmt',
        Extend: preset.amountFormula,
        Width: 120,
        ColMerge: false,
      },

      {
        Header: ['실행내역', '공종명'],
        Name: 'mgName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
        VAlign: 'Top',
      },
      {
        Header: ['실행내역', '내역코드'],
        Name: 'itemSeq',
        Type: 'Text',
        Align: 'Center',
        Width: 70,
        ColMerge: false,
      },
      {
        Header: ['실행내역', '내역명'],
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
        ColMerge: false,
      },
      {
        Header: ['실행내역', '규격'],
        Name: 'itemSize',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 100,
        ColMerge: false,
      },
      {
        Header: ['실행내역', '단위'],
        Name: 'unit',
        Type: 'Text',
        Align: 'Center',
        Width: 50,
        ColMerge: false,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: ['실행내역', '수량'],
        Name: 'itemQty',
        Extend: preset.quantityFormula,
        Width: 60,
        ColMerge: false,
      },
      {
        Header: ['실행내역', '단가'],
        Name: 'itemPrice',
        Extend: preset.unitAmountFormula,
        Width: 100,
        ColMerge: false,
      },
      {
        Header: ['실행내역', '금액'],
        Name: 'itemAmt',
        Extend: preset.amountFormula,
        Width: 100,
        ColMerge: false,
      },
    ],
  };
};
