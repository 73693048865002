// CustomerList.vue
export const selectCustomerList = param => axios.post('/customer/selectCustomerList', param);
export const deleteCustomerInfo = param => $save('/customer/deleteCustomerInfo', param);
// CustomerDetailInfo.vue
export const selectCompanyInfo = param => axios.post('/companyRegist/selectCompanyInfo', param);
export const selectCompanyInfoByBsnNo = param => axios.post('/companyRegist/selectCompanyInfoByBsnNo', param);
export const selectDuplicateCustomerInfo = param => axios.post('/customer/selectDuplicateCustomerInfo', param);
export const saveCustomerInfo = param => $save('/customer/saveCustomerInfo', param);
export const updateCustomerFlNo = param => $save('/customer/updateCustomerFlNo', param);
// CeoInfo.vue
export const selectCustomerCeoList = param => axios.post('/customer/selectCustomerCeoList', param);
export const saveCustomerCeoList = param => $save('/customer/saveCustomerCeoList', param);
export const deleteCustomerCeoList = param => axios.post('/customer/deleteCustomerCeoList', param);

export const selectCustomerListPopup = param => axios.post('/customer/selectCustomerListPopup', param);
