<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <label>실적일자</label>
      <div class="ml5"></div>
      <iui-datepicker name="resultDate" :value.sync="resultDate" @change="onResultDateChange" />
      <i class="prev-arrow" @click="onClickPrev" v-if="resultDate" />
      <i class="post-arrow" @click="onClickNext" v-if="resultDate" />
      <div class="ml15"></div>
      <label>예정기간</label>
      <div class="ml5"></div>
      <iui-datepicker :value.sync="planStrdate" :group="{grpNm: 'date', seq: 0}" />
      <span class="ml5 mr5">~</span>
      <iui-datepicker :value.sync="planEnddate" :group="{grpNm: 'date', seq: 1}" />
      <iui-button value="검색" @click="initSheet" v-if="resultDate" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="Options"
      :autoRender="false"
      :init.sync="init"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onRenderFirstFinish,
      }"
    />
  </pmis-content-box>
</template>

<script>
import {
  selectContWplanExecSheetDataList,
  selectContWplanExecResultDate,
} from '@/view/workAcmsltVolmRegist/api/acmsltVolmRegist.js';
import options from './sheetOptions/UnitOpertExecInqSheet.js';
export default {
  data() {
    return {
      sheet: undefined,
      init: false,
      Options: {},
      loadSearchData: [],
      resultDateList: [],
      resultDate: '',
      planStrdate: '',
      planEnddate: '',
    };
  },

  created() {
    this.addFuncPrj(this.getResultDate);
  },

  methods: {
    async getResultDate() {
      const response = await selectContWplanExecResultDate({});
      this.resultDateList = response.data;
      this.initSheet();
    },
    async initSheet() {
      this.Options = await options(this);
      this.init = true;
    },
    async loadSheet(sheet) {
      this.sheet = sheet;
      this.loadSearchData = [];
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    async onSearch() {
      const param = {
        resultDate: this.resultDate.replace(/-/g, ''),
        planStrdate: this.planStrdate.replace(/-/g, ''),
        planEnddate: this.planEnddate.replace(/-/g, ''),
      };
      const sheetData = await selectContWplanExecSheetDataList(param);
      this.planStrdate = sheetData.data.planStrdate ?? '';
      this.planEnddate = sheetData.data.planEnddate ?? '';
      this.loadSearchData = sheetData.data.sheetDataList;
      this.loading = false;
    },
    sheetSearch() {
      let filter = document.querySelector(`#workType${this._uid}`).value;
      if (filter) {
        this.sheet.setFilter({
          name: 'midCode',
          filter: "midCode=='" + filter + "'?1:0",
          render: 1,
        });
      } else {
        this.sheet.clearFilter();
      }
    },
    onResultDateChange(date) {
      this.resultDate = date;
      this.planStrdate = '';
      this.planEnddate = '';
      this.initSheet();
    },
    onClickPrev() {
      if (!this.resultDateList.length) {
        return;
      }
      const resultList = this.resultDateList.filter(resultDate => Number(resultDate) < Number(this.resultDate));
      const resultDate = resultList[resultList.length - 1];
      if (resultDate) {
        this.onResultDateChange(resultDate);
      }
    },
    onClickNext() {
      if (!this.resultDateList.length) {
        return;
      }
      const resultList = this.resultDateList.filter(resultDate => Number(resultDate) > Number(this.resultDate));
      const resultDate = resultList[0];
      if (resultDate) {
        this.onResultDateChange(resultDate);
      }
    },
  },
};
</script>
