export default that => {
  return {
    Cfg: {
      CanEdit: 3,
    },
    Cols: [
      {
        Name: 'tl',
        Header: '결재선명',
        RelWidth: 1,
      },
      {Name: 'snclNo', Visible: false},
      {Name: 'snclDs', Visible: false},
    ],
  };
};
