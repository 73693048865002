<template>
  <div>
    <select v-model="searchInfo.natDs">
      <option value="">전체</option>
      <option value="000001">일반공지</option>
      <option value="000002">입찰공지</option>
    </select>
    <input type="text" v-model="searchInfo.tl" @keyup.enter="onSearch" />
    <button type="button" @click="onSearch">검색</button>
    <div id="notice_tb">
      <table>
        <colgroup>
          <col width="80px" />
          <col width="150px" />
          <col width="100px" />
          <col width="400px" />
          <col width="80px" />
        </colgroup>
        <thead>
          <tr>
            <th>번호</th>
            <th>구분</th>
            <th>첨부</th>
            <th>제목</th>
            <th>조회수</th>
          </tr>
        </thead>
        <tbody v-for="(item, rowIndex) in items" :key="rowIndex">
          <tr>
            <td>{{ item.natNo }}</td>
            <td>{{ item.natDs }}</td>
            <td><button type="button" v-if="item.flNo">첨부파일</button></td>
            <td style="text-align: left; cursor: pointer;" @click="toggleContent(rowIndex, item)">{{ item.tl }}</td>
            <td>{{ item.inqCnt }}</td>
          </tr>
          <tr class="tb_contents_row" :id="`content_${rowIndex}`">
            <td colspan="5">
              <div class="tb_contents" v-html="item.cts" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <Pagination
      :records="records"
      :per-page="searchInfo.pageSize"
      v-model="searchInfo.pageNo"
      :options="paginationOpt"
      @paginate="onPaginate"
      style="text-align: center;"
    />
  </div>
</template>

<script>
import Pagination from 'vue-pagination-2'; // https://github.com/matfish2/vue-pagination-2
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      records: 0,
      items: [],
      searchInfo: {
        natDs: '',
        tl: '',
        pageNo: 1,
        pageSize: 5, // 한 페이지당 row 개수
      },
      paginationOpt: {
        chunk: 10, // 최대 10 페이지 씩(default: 10)
      },
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      if (this.searchInfo.natDs) this.searchInfo.pageNo = 1; // 게시물 구분 검색조건이 있을 경우, pageNo = 1
      document.querySelectorAll('.tb_contents_row').forEach(item => (item.style.display = 'none'));
      axios.post('/board/selectBoardListHomePage', this.searchInfo).then(response => {
        this.records = response.data.records;
        this.items = response.data.noticeList;
      });
    },
    toggleContent(rowIndex, row) {
      let display = document.querySelector(`#content_${rowIndex}`).style.display;
      if (display == '') display = 'none';
      document.querySelector(`#content_${rowIndex}`).style.display = display == 'none' ? 'table-row' : 'none';

      if (display == 'none') {
        axios.post('/board/updateBoardIncreaseHits', row);
      }
    },
    onPaginate() {
      this.onSearch();
    },
  },
};
</script>

<style scoped>
#notice_tb {
  text-align: center;
  height: 300px;
  border: 1px solid #444444;
  border-collapse: collapse;
}
#notice_tb th,
#notice_tb td {
  border: 1px solid #444444;
}
.tb_contents_row {
  text-align: left;
  display: none;
}
.tb_contents {
  margin: 15px;
}
</style>
<style>
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}
[role='button'] {
  cursor: pointer;
}
.VuePagination__count {
  display: none;
}
</style>
