<template>
  <pmis-page>
    <pmis-tab-box>
      <template v-slot:title>
        양식목록
      </template>
      <Draft />
    </pmis-tab-box>
  </pmis-page>
</template>

<script>
import Draft from './components/Draft.vue';
export default {
  components: {
    Draft,
  },
  created() {
    this.addFuncSearch(this.onSearch);
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'formList_selectFormList'});
    },
  },
};
</script>

<style></style>
