export default that => {
  return {
    Cfg: {
      CanEdit: 0,
    },
    Cols: [
      {Header: '소속명', Name: 'blnDepName', Type: 'Text', RelWidth: 1, MinWidth: 150},
      {Header: 'ID', Name: 'empNo', Type: 'Text', RelWidth: 1, MinWidth: 150},
      {Header: '성명', Name: 'name', Type: 'Text', RelWidth: 1, MinWidth: 150},
      {Name: 'monthlyDay', Type: 'Text', Visible: 0},
      {Name: 'monthlyQty', Type: 'Text', Visible: 0},
      {Name: 'monthlyAmt', Type: 'Text', Visible: 0},
      {Name: 'healthInsurAmt', Type: 'Text', Visible: 0},
      {Name: 'careAmt', Type: 'Text', Visible: 0},
      {Name: 'pensionAmt', Type: 'Text', Visible: 0},
      {Name: 'employInsurAmt', Type: 'Text', Visible: 0},
      {Name: 'incometax', Type: 'Text', Visible: 0},
      {Name: 'resitax', Type: 'Text', Visible: 0},
      {Name: 'deductSum', Type: 'Text', Visible: 0},
      {Name: 'actPayAmt', Type: 'Text', Visible: 0},
    ],
  };
};
