<template>
  <pmis-content-box>
    <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" />
  </pmis-content-box>
</template>

<script>
/** 발송현황 - 발송정보 팝업 */
import options from './sheetOption/DocSendInfoPopSheet.js';
import {selectReceiptStateList} from '../line/api/receipt.js';
export default {
  props: {
    sncNo: {
      type: Number,
    },
    sndNo: {
      type: Number,
    },
  },
  data() {
    return {
      Options: options(this),
      loadSearchData: [],
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    async onSearch() {
      const param = {
        sncNo: this.sncNo,
        sndNo: this.sndNo,
      };
      const response = await selectReceiptStateList(param);
      const list = response.data.map((item, index) => ({...item, seq: index + 1}));
      this.loadSearchData = list;
    },
  },
};
</script>

<style></style>
