const profile = {
  isLocal: () => {
    return process.env.NODE_ENV === 'local';
  },
  isDev: () => {
    return process.env.NODE_ENV === 'development';
  },
  isProd: () => {
    return process.env.NODE_ENV === 'production';
  },
};

export default profile;
