export default that => {
  return {
    Cfg: {
      MainCol: 'fldrNm', // 트리가 표시될 열이름
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      CanEdit: 0, // 1: 전체 편집 가능 (default)
      NoTreeLines: 0, // 트리를 사용하는 시트 생성시 노드와 노드 사이의 연결선을 표시할 지 여부를 설정
    },
    Def: {
      Header: {
        Hidden: 1,
      },
    },
    Cols: [
      {
        Header: '단위명',
        Name: 'fldrNm',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
      },
      {Name: 'wbsCd', Visible: 0},
      {Name: 'wbsId', Visible: 0},
      {Name: 'isPlnWgh', Type: 'Bool', Visible: 0},
    ],
  };
};
