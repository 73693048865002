<template>
  <pmis-content-box>
    <template v-slot:title>관리자 정보</template>
    <iui-container-new type="table" theme="bullet">
      <i-row>
        <i-col-header>이름</i-col-header>
        <i-col>
          <iui-text width="150px" :value="worker.name" :enable="false" />
          <iui-button value="변경" v-if="adminEnable" @click="onWorkerInfoShow" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>전화번호</i-col-header>
        <i-col>
          <iui-text type="phone" width="150px" :value="worker.tlno" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>이메일</i-col-header>
        <i-col>
          <iui-text type="email1" width="150px" :value="worker.email1" :enable="false" />
          <div class="mr5">@</div>
          <iui-text type="email2" width="200px" :value="email2" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>휴대폰번호</i-col-header>
        <i-col>
          <iui-text type="phone" width="150px" :value="worker.mobileTlno" :enable="false" />
          <iui-checkbox-group :items="checkboxItems" :checkedValues="[worker.useSms]" :enable="false" />
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal name="workerInfo" title="사원정보" sizeType="size3" :btns="workerInfoModalBtns">
      <WorkerInfo />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import WorkerInfo from '@/view/member/components/popup/WorkerInfo.vue';
export default {
  props: {
    adminEnable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    WorkerInfo,
  },
  data() {
    return {
      enable: false,
      email2: '',
      checkboxItems: [{label: 'SMS수신동의', value: $getConstants('Y').code}],
      workerInfoModalBtns: [{name: '확인', callback: this.getWorkerInfoData}],
    };
  },
  watch: {
    worker: {
      handler(obj) {
        $getCodeNm($getConstants('EMAIL_CD').code, obj.email2).then(response => {
          this.email2 = response;
        });
      },
      deep: true,
    },
  },
  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'worker']);
    $mapMutations(this, ['setWorker', 'setDupEmpNoStatus']);
  },
  created() {
    $getCodeNm($getConstants('EMAIL_CD').code, this.worker.email2).then(response => {
      this.email2 = response;
    });
  },
  methods: {
    async onWorkerInfoShow() {
      this.$modal.show('workerInfo');
    },
    getWorkerInfoData() {
      this.callEvent({
        name: 'workerInfo_getData',
        param: data => {
          this.setWorker({...data, useMst: $getConstants('USE_MST_Y').code});
          this.$modal.hide('workerInfo');
        },
      });
    },
  },
};
</script>

<style scoped></style>
