<template>
  <pmis-content-box>
    <template #title>공동대표정보</template>
    <ib-sheet :uid="_uid" :options="sheetOpt" @loadSheet="sheet = $event" />
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/customerCeoInfo.js';

export default {
  props: {
    propsPgmCd: {
      type: [String, Number],
      default: undefined,
    },
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
      ceoList: [],
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    async onSearch() {
      const response = await axios.post('/companyRegist/selectCeoList', {pgmCd: this.propsPgmCd});
      this.ceoList = response.data;
    },
  },
};
</script>

<style></style>
