const EDMS_CONSTANTS = {
  /**
   * [도급정보]
   * CONTRACT : 계약정보
   * WORKDAILY : 작업일보
   * INPUT : 투입현황
   */
  CONTRACT: {
    FBS_CD: 'A0101',
    FBS_NO: 6,
  },
  WORKDAILY: {
    FBS_CD: 'A0102',
    FBS_NO: 13,
    FOM_NO: 38,
    TASK_CD: 'WORKDAILY',
  },
  INPUT: {
    FBS_CD: 'A0103',
    FBS_NO: 14,
    FOM_NO: 40,
    TASK_CD: 'INPUT',
  },
  /**
   * [자재정보]
   * MAT_PR : 구매요청
   * MAT_RFQ : 입찰정보
   * MAT_RFQ_UNIT : 입찰정보
   * MAT_RFQ_CHOICE : 입찰정보
   * MAT_PO : 계약정보
   * MAT_PO_CHANGE : 계약정보
   */
  MAT_PR: {
    FBS_CD: 'A0501',
    FBS_NO: 7,
    FOM_NO: 1,
    TASK_CD: 'MAT_PR',
  },
  MAT_RFQ: {
    FBS_CD: 'A0502',
    FBS_NO: 8,
    FOM_NO: 2,
    TASK_CD: 'MAT_RFQ',
  },
  MAT_RFQ_UNIT: {
    FBS_CD: 'A0502',
    FBS_NO: 8,
    FOM_NO: 3,
    TASK_CD: 'MAT_RFQ',
  },
  MAT_RFQ_CHOICE: {
    FBS_CD: 'A0502',
    FBS_NO: 8,
    FOM_NO: 35,
    TASK_CD: 'MAT_RFQ_CHOICE',
  },
  MAT_PO: {
    FBS_CD: 'A0503',
    FBS_NO: 9,
    FOM_NO: 7,
    TASK_CD: 'MAT_PO',
  },
  MAT_PO_CHANGE: {
    FBS_CD: 'A0503',
    FBS_NO: 9,
    FOM_NO: 11,
    TASK_CD: 'MAT_PO',
  },
  /**
   * [외주정보]
   * SUB_PR : 시행요청
   * SUB_RFQ : 입찰정보
   * SUB_RFQ_CHOICE : 입찰정보
   * SUB_PO : 계약정보
   * SUB_PO_CHANGE : 계약정보
   */
  SUB_PR: {
    FBS_CD: 'A1001',
    FBS_NO: 10,
    FOM_NO: 15,
    TASK_CD: 'SUB_PR',
  },
  SUB_RFQ: {
    FBS_CD: 'A1002',
    FBS_NO: 11,
    FOM_NO: 16,
    TASK_CD: 'SUB_RFQ',
  },
  SUB_RFQ_CHOICE: {
    FBS_CD: 'A1002',
    FBS_NO: 11,
    FOM_NO: 36,
    TASK_CD: 'SUB_RFQ_CHOICE',
  },
  SUB_PO: {
    FBS_CD: 'A1003',
    FBS_NO: 12,
    FOM_NO: 19,
    TASK_CD: 'SUB_PO',
  },
  SUB_PO_CHANGE: {
    FBS_CD: 'A1003',
    FBS_NO: 12,
    FOM_NO: 21,
    TASK_CD: 'SUB_PO',
  },
};

export default EDMS_CONSTANTS;
