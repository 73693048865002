export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 1},
    Def: {
      Row: {
        NoColor: 2,
        CanFocus: 0,
        CanFormula: 1,
        CalcOrder: 'matAmt,labAmt,equipAmt,costAmt,sumUprc,sumAmt',
      },
    },
    LeftCols: [
      {
        Header: [{Value: '선택', TextColor: '#000000', HeaderCheck: 1}, '선택'],
        Name: 'chk',
        Type: 'Bool',
        Align: 'center',
        Width: 60,
        Visible: false,
      },
      {
        Header: ['공종명', '공종명'],
        Name: 'srvNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 140,
        CanEdit: false,
      },
      {
        Header: ['내역명', '내역명'],
        Name: 'itemNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 160,
        CanEdit: false,
      },
      {
        Header: ['규격', '규격'],
        Name: 'sizeNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 160,
        CanEdit: false,
      },
      {
        Header: ['단위', '단위'],
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        Width: 50,
        CanEdit: false,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: ['수량', '수량'],
        Name: 'qty',
        Type: 'Text',
        Width: 70,
        Extend: preset.quantity,
        CanEdit: false,
      },
    ],
    Cols: [
      {
        Header: ['재료비', '단가'],
        Name: 'matUprc',
        Type: 'Text',
        Width: 90,
        Extend: preset.unitAmount,
        CanEdit: false,
      },
      {
        Header: ['재료비', '금액'],
        Name: 'matAmt',
        Type: 'Text',
        Width: 100,
        Extend: preset.amount,
        Formula: fr => fr.Row['qty'] * fr.Row['matUprc'],
        CanEdit: false,
      },
      {
        Header: ['노무비', '단가'],
        Name: 'labUprc',
        Type: 'Text',
        Width: 90,
        Extend: preset.unitAmount,
        CanEdit: false,
      },
      {
        Header: ['노무비', '금액'],
        Name: 'labAmt',
        Type: 'Text',
        Width: 100,
        Extend: preset.amount,
        Formula: fr => fr.Row['qty'] * fr.Row['labUprc'],
        CanEdit: false,
      },
      {
        Header: ['장비비', '단가'],
        Name: 'equipUprc',
        Type: 'Text',
        Width: 90,
        Extend: preset.unitAmount,
        CanEdit: false,
      },
      {
        Header: ['장비비', '금액'],
        Name: 'equipAmt',
        Type: 'Text',
        Width: 100,
        Extend: preset.amount,
        Formula: fr => fr.Row['qty'] * fr.Row['equipUprc'],
        CanEdit: false,
      },
      {
        Header: ['경비', '단가'],
        Name: 'costUprc',
        Type: 'Text',
        Width: 90,
        Extend: preset.unitAmount,
        CanEdit: false,
      },
      {
        Header: ['경비', '금액'],
        Name: 'costAmt',
        Type: 'Text',
        Width: 100,
        Extend: preset.amount,
        Formula: fr => fr.Row['qty'] * fr.Row['costUprc'],
        CanEdit: false,
      },
      {
        Header: ['합계', '단가'],
        Name: 'sumUprc',
        Type: 'Text',
        Width: 90,
        Extend: preset.unitAmount,
        Formula: fr => fr.Row['matUprc'] + fr.Row['labUprc'] + fr.Row['equipUprc'] + fr.Row['costUprc'],
        CanEdit: false,
      },
      {
        Header: ['합계', '금액'],
        Name: 'sumAmt',
        Type: 'Text',
        Width: 120,
        Extend: preset.amount,
        Formula: fr => fr.Row['matAmt'] + fr.Row['labAmt'] + fr.Row['equipAmt'] + fr.Row['costAmt'],
        CanEdit: false,
      },
      {
        Header: ['비고', '비고'],
        Name: 'srvRmrk',
        Type: 'Text',
        MinWidth: 120,
        RelWidth: 1,
        CanEdit: false,
      },
      {Name: 'wcode', Visible: false},
      {Name: 'headCode', Visible: false},
      {Name: 'midCode', Visible: false},
      {Name: 'itemSeq', Visible: false},
      {Name: 'itemCode', Visible: false},
      {Name: 'costType', Visible: false},
      {Name: 'qtyBefore', Visible: false, NoChanged: 1},
      {Name: 'totQty', Visible: false},
    ],
  };
};
