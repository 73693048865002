<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px"><PrjList /></i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>입고목록</template>
            <pmis-content-box>
              <template v-slot:header-left>
                <label>입고일자</label>
                <iui-datepicker
                  :value="searchInfo.searchIoDate"
                  @change="onChangeIoDate($event)"
                  :open.sync="searchIoDateOpen"
                  :disabledDate="disabledDate"
                />
                <i class="prev-arrow" v-if="!isNew" v-on:click="onChangeDate('PREV')" />
                <i class="post-arrow" v-if="!isNew" v-on:click="onChangeDate('POST')" />
              </template>
              <ib-sheet
                :uid="_uid"
                :options="Options"
                :loadSearchData="loadSearchData"
                :events="{
                  onRenderFirstFinish,
                  onSearchFinish,
                }"
                @loadSheet="loadSheet"
              />
            </pmis-content-box>
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
    <iui-modal name="standardCodePopup" title="자재선택" :btns="standardCodePopupBtns" size-type="size6">
      <StandardCodePopup type="mat" :base="false" />
    </iui-modal>
  </pmis-page>
</template>

<script>
/**
 * 지급자재입고등록
 *   components :
 *     PrjList : 현장목록
 * */
import PrjList from '@/view/Project/components/PrjList.vue';
import options from './components/sheetOptions/PymntMtrilWrhousngRegistSheet.js';
import {selectMonthClosePossibleAt} from '@/view/closing/expitmCloseRegist/api/expitmCloseRegist.js';
import {selectPymntMtrilWrhousngList} from '@/view/Resource/GMaterial/PymntMtrilWrhousngRegist/api/pymntMtrilWrhousngRegist.js';
import {
  selectPymntMtrilWrhousngDetailList,
  selectLastIoDate,
  deletePymntMtrilWrhousng,
  savePymntMtrilWrhousngRegist,
} from './api/pymntMtrilWrhousngRegist.js';
import StandardCodePopup from '@/view/sysManage/stdCodeManage/components/StandardCodePopup.vue';
export default {
  components: {
    PrjList,
    StandardCodePopup,
  },
  data() {
    return {
      Options: {},
      loadSearchData: [],
      modalNm: '',
      modalBtns: [{title: '자재코드', name: '확인', callback: this.onHideModal}],
      searchInfo: {
        searchIoDate: '',
      },
      searchIoDateOpen: false,
      monPjtCloseAt: '',
      focusKey: {matCode: '', seq: ''},
      standardCodePopupBtns: [{name: '확인', callback: this.onStandardCodePopup}],
    };
  },
  computed: {
    isNew() {
      return this.cud === 1;
    },
    isMonthClose() {
      return this.monPjtCloseAt == 'Y'; // 월마감 가능여부
    },
  },
  created() {
    this.addFuncSearch(this.onSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
    this.addFuncPrj(this.onSearchLastIoDate);
    this.Options = $addColCheckbox(options(this), 1, 'chk', {
      CanEditFormula: () => this.monPjtCloseAt == 'N', //마감 수정불가
    });
  },
  methods: {
    disabledDate(date) {
      return $_getCurrentDate() < $_dateToStringYYMMDD(date);
    },
    async onSearchLastIoDate() {
      const response = await selectLastIoDate({});
      const lastIoDate = response.data ? response.data : $_getCurrentDate();

      this.searchInfo.searchIoDate = lastIoDate;
      this.onSearchMonthCloseAt(lastIoDate);
      this.onSearch();
    },
    async onSearch() {
      const param = {...this.searchInfo};
      const response = await selectPymntMtrilWrhousngDetailList(param);
      this.loadSearchData = response.data;
      this.cud = 0;
    },
    onChangeIoDate(v) {
      // 월마감 체크
      this.onSearchMonthCloseAt(v);

      // 재조회
      this.sheet.removeAll();
      this.searchInfo.searchIoDate = v;

      if (v) {
        this.onSearch();
      } else {
        this.searchIoDateOpen = true;
      }
    },
    async onSearchMonthCloseAt(ioDate) {
      if (!ioDate) return;

      const param = {pgmCd: this.pgmCd, closeMm: String(ioDate).substring(0, 6)};
      const response = await selectMonthClosePossibleAt(param);

      if (response.data.CHECK1 == 'N') {
        this.monPjtCloseAt = 'Y';
      } else {
        this.monPjtCloseAt = 'N';
      }
    },
    async onChangeDate(searchDateType) {
      const param = {...this.searchInfo, searchDateType};
      const response = await selectLastIoDate(param);

      const lastIoDate = response.data;
      if (lastIoDate) {
        this.onSearchMonthCloseAt(lastIoDate);
        this.searchInfo.searchIoDate = lastIoDate;
        this.onSearch();
      } else {
        const title = '입고일자';
        const message = `${searchDateType == 'PREV' ? '이전' : '다음'} 입고일이 없습니다.`;
        this.$alert({title, message}, () => {
          if (!this.searchInfo.searchIoDate) {
            this.searchInfo.searchIoDate = $_getCurrentDate();
          }
        });
      }
    },
    onAdd() {
      if (this.isMonthClose) {
        this.$alert({title: '마감완료', message: '월마감 상태이므로 입고등록은 불가합니다.'});
        return;
      }
      if (!this.searchInfo.searchIoDate) {
        this.$alert({title: '입고일자', message: '입고일자를 선택해 주세요.'});
        return;
      }

      if (this.isSimpleExec) {
        this.$modal.show('standardCodePopup');
      } else {
        let param = {
          searchIoDate: this.searchInfo.searchIoDate,
        };
        selectPymntMtrilWrhousngList(param).then(response => {
          this.loadSearchData = response.data;
        });
      }
    },
    onStandardCodePopup() {
      this.callEvent({
        name: 'StandardCodePopup_confirm',
        param: data => {
          const sheetRowsMatCodes = this.sheet.getDataRows().map(row => row.matCode);

          data
            .filter(item => !sheetRowsMatCodes.includes(item.matCode))
            .forEach(item => {
              const addRow = this.sheet.addRow();
              this.sheet.setValue(addRow, 'matCode', item.matCode, true);
              this.sheet.setValue(addRow, 'itemName', item.itemName, true);
              this.sheet.setValue(addRow, 'size', item.size, true);
              this.sheet.setValue(addRow, 'unit', item.unit, true);
            });

          this.$modal.hide('standardCodePopup');
        },
      });
    },
    onSave() {
      if (this.isMonthClose) {
        this.$alert({title: '마감완료', message: '월마감 상태이므로 입고수정은 불가합니다.'});
        return;
      }
      if (!this.sheet.hasChangedData()) {
        this.$alert({title: '입고내역저장', message: '변경된 내용이 없습니다.'});
        return;
      }

      const saveMatGitemList = this.sheet.getSaveJson().data;
      const validRow = saveMatGitemList.find(row => !row.matCode || !row.qty);
      if (validRow !== undefined) {
        if (!validRow.qty) this.$alert({title: '입고내역저장', message: '수량을 입력해야 합니다.'});
        if (!validRow.matCode) this.$alert({title: '입고내역저장', message: '자재코드를 선택해야 합니다.'});
        return;
      }
      saveMatGitemList.forEach(e => {
        e.ioDate = this.searchInfo.searchIoDate;
        e.ioGb = $getConstants('IO_GB1').code;
      });

      const param = {matGitemList: saveMatGitemList, ioDate: this.searchInfo.searchIoDate};
      savePymntMtrilWrhousngRegist(param).then(response => {
        if (response.data.resultCode == '1') {
          this.$alert({title: '마감완료', message: '월마감 상태이므로 입고등록은 불가합니다'});
        } else {
          this.focusKey.matCode = response.data.matCode;
          this.focusKey.seq = response.data.seq;
        }
        this.onSearch();
      });
    },
    async onDelete() {
      if (this.isMonthClose) {
        this.$alert({title: '마감완료', message: '월마감 상태이므로 입고삭제는 불가합니다.'});
        return;
      }

      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({title: '입고내역삭제', message: '삭제할 입고내역을 선택해 주세요.'});
        return;
      }

      const deleteMatGitemList = [];
      checkedRows.forEach(row => {
        if (row.cud == '1') {
          this.sheet.removeRow(row);
        } else {
          deleteMatGitemList.push({
            pgmCd: row.pgmCd,
            prjCd: row.prjCd,
            ioGb: row.ioGb,
            ioDate: row.ioDate,
            seq: row.seq,
            matCode: row.matCode,
          });
        }
      });
      if (deleteMatGitemList.length == 0) return;

      const param = {matGitemList: deleteMatGitemList};
      const title = '입고내역삭제';
      const message = '선택한 입고내역을 삭제하시겠습니까?';

      if (await this.$confirm({title, message})) {
        deletePymntMtrilWrhousng(param).then(response => {
          if (response.data == '1') {
            this.$alert({title: '마감완료', message: '월마감 상태이므로 입고삭제는 불가합니다'});
          }
          this.onSearch();
        });
      }
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    onSearchFinish() {
      const matCode = this.focusKey.matCode;
      const seq = this.focusKey.seq;
      if (matCode && seq) {
        const focusedRow = this.sheet.getDataRows().find(row => row.matCode == matCode && row.seq == seq);
        if (focusedRow !== undefined) {
          this.sheet.focus(focusedRow);
          this.focusKey.matCode = '';
          this.focusKey.seq = '';
        }
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped></style>
