export default that => {
  return {
    Cfg: {
      MainCol: 'name', // 트리가 표시될 열이름
      CanEdit: 0, // 1: 전체 편집 가능 (default)
    },
    Def: {
      Header: {
        Hidden: 1,
      },
    },
    Cols: [
      {
        Header: 'OBS명칭',
        Name: 'name',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
        Cursor: 'Pointer',
      },
      {Name: 'orgFlag', Visible: false},
      {Name: 'orgFlagDtl', Visible: false},
    ],
  };
};
