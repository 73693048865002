<template>
  <pmis-content-box>
    <template #header-left>
      <label>현장</label>
      <iui-text type="search" :value.sync="searchPrjNm" @enter="onSearch" />

      <label>요청기간</label>
      <iui-datepicker :value.sync="searchFrDt" @change="onSearch" :group="searchDateGroup.seq1" />
      <label>~</label>
      <iui-datepicker :value.sync="searchToDt" @change="onSearch" :group="searchDateGroup.seq2" />

      <iui-button value="검색" @click="onSearch" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="prList"
      @loadSheet="sheet = $event"
      :events="{
        onRenderFirstFinish: onSearch,
        onDblClick: e => $modalConfirm(e, 1),
        onClick: sheet_onClick,
      }"
    />

    <iui-modal :name="`prInfoModal_${_uid}`" title="구매요청정보" size-type="size3">
      <PrInfo :prNo="propsPrNo" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/rfqTargetPrList.js';
import {selectApprovedPrList, selectRfqByPrInfo} from '@/view/bidding/rfq/mat/api/rfq.js';
import PrInfo from '@/view/bidding/rfq/mat/common/components/PrInfo.vue';
export default {
  components: {PrInfo},
  data() {
    return {
      sheetOpt: sheetOpt(this),
      prList: [],
      searchPrjNm: '',
      searchFrDt: '',
      searchToDt: '',
      searchDateGroup: {
        seq1: {grpNm: 'searchDate', seq: 1},
        seq2: {grpNm: 'searchDate', seq: 2},
      },
      propsPrNo: '',
    };
  },
  created() {
    this.addEvent([{name: 'RfqTargetPrList_Confirm', func: this.onConfirm}]);
  },
  methods: {
    async onSearch() {
      const param = {
        orgCd: this.pgmCd,
        searchPrjNm: this.searchPrjNm,
        searchFrDt: this.searchFrDt,
        searchToDt: this.searchToDt,
      };
      this.prList = (await selectApprovedPrList(param)).data;
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data' && e.col === 'prNo') {
        this.propsPrNo = e.row.prNo;
        this.$modal.show(`prInfoModal_${this._uid}`);
      }
    },
    onConfirm(callback) {
      selectRfqByPrInfo(this.sheet.getFocusedRow().prNo).then(response => {
        callback(response.data);
      });
    },
  },
};
</script>

<style></style>
