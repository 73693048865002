<template>
  <div id="wrap">
    <iui-side></iui-side>
    <iui-header></iui-header>
    <iui-main></iui-main>
  </div>
</template>

<script>
import IuiHeader from './IuiHeader.vue';
import IuiSide from './IuiSide.vue';
import IuiMain from './IuiMain.vue';
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'Home',
  components: {
    'iui-header': IuiHeader,
    'iui-side': IuiSide,
    'iui-main': IuiMain,
  },
  computed: {
    ...mapGetters('layout', {
      isSideOpen: 'isSideOpen',
    }),
    ...mapGetters(['subFrmGb']),
  },
  watch: {
    isSideOpen(sideOpen) {
      if (sideOpen) {
        this.$el.querySelector('#header').classList.remove('side_close');
        this.$el.querySelector('#contents').classList.remove('side_close');
        this.$el.querySelector('#lnb').classList.remove('side_close');
        this.$el
          .querySelector('#wrap .nav_wrap')
          .querySelector('a')
          .classList.remove('btn_big_open');
        this.$el
          .querySelector('#wrap .nav_wrap')
          .querySelector('a')
          .classList.add('btn_big_close');
        this.$el.querySelector('#wrap .nav_wrap').classList.remove('side_close');

        this.$el.querySelector('#header').style.transition = 'all 0.5s ease';
        this.$el.querySelector('#contents').style.transition = 'all 0.5s ease';
        this.$el.querySelector('#lnb').style.transition = 'all 0.5s ease';
        this.$el.querySelector('#wrap .nav_wrap').querySelector('a').style.transition = 'all 0.5s ease';
        this.$el.querySelector('#wrap .nav_wrap').style.transition = 'all 0.5s ease';
      } else {
        this.$el.querySelector('#header').classList.add('side_close');
        this.$el.querySelector('#contents').classList.add('side_close');
        this.$el.querySelector('#lnb').classList.add('side_close');
        this.$el
          .querySelector('#wrap .nav_wrap')
          .querySelector('a')
          .classList.remove('btn_big_close');
        this.$el
          .querySelector('#wrap .nav_wrap')
          .querySelector('a')
          .classList.add('btn_big_open');
        this.$el.querySelector('#wrap .nav_wrap').classList.add('side_close');
      }
    },
  },
  created() {
    this.setSubFrmGb(
      this.userInfo.frmGb == $getConstants('FRM_GB_4').code ? $getConstants('FRM_GB_1').code : this.userInfo.frmGb
    );
    this.setBaseDataInfo();
  },
  beforeMount() {},
  mounted() {
    if (this.isSideOpen) {
      this.$el.classList.add('nav_c');
    } else {
      this.$el.classList.remove('nav_c');
    }
  },
  methods: {
    ...mapActions('menu', ['setMenu']),
    ...mapMutations(['setSubFrmGb', 'setStdCl']),
    async setBaseDataInfo() {
      let param = {pgmCd: this.pgmCd};
      let response = await axios.post('/common/baseDataInfo', param).catch(e => console.error('ERR!!! : ', e));
      // 표준분류
      const stdCl = response.data.stdClList;
      this.setStdCl(stdCl);
      sessionStorage.setItem('stdCl', JSON.stringify(stdCl));
      // 메뉴
      const menuList = response.data.menuList;
      let _menuList = await $_treeModel(Array.from(menuList), 'PGM000', 'mnuCd', 'uppMnuCd');
      _menuList[0].isSelected = true;
      _menuList[0].Items[0].isSelected = true;
      this.setMenu(_menuList);
    },
  },
};
</script>
