/** 입찰목록 */
export const selectSubRfqList = searchOptions => axios.post('/subRfq/selectSubRfqList', searchOptions);
/** 입찰 종합정보 */
export const selectSubRfqInfos = rfqNo => axios.post('/subRfq/selectSubRfqInfos', {rfqNo});
/** 입찰정보 */
export const selectSubRfqInfo = rfqNo => axios.post('/subRfq/selectSubRfqInfo', {rfqNo});
/** 승인된 시행요청리스트 */
export const selectApprovedSubPrList = searchOptions => axios.post('/subRfq/selectApprovedSubPrList', searchOptions);
/** 공고데이터 (from 시행요청정보) */
export const selectSubRfqByPrInfo = prNo => axios.post('/subRfq/selectSubRfqByPrInfo', {prNo});

/** 입찰정보 생성 */
export const createRfq = (param, afterValidation) => $save('/subRfq/createRfq', param, {afterValidation});
/** 입찰정보 저장 */
export const saveRfq = (param, afterValidation) => $save('/subRfq/saveRfq', param, {afterValidation});
/** 입찰정보 삭제 */
export const deleteRfq = rfqNo => axios.post('/subRfq/deleteRfq', {rfqNo});
/** 업무 파일번호 저장 */
export const updateSubRfqFlNo = (rfqNo, flNo) => axios.post('/subRfq/updateSubRfqFlNo', {rfqNo, flNo});

/** 입찰예정가 저장 */
export const saveBidPlanAmt = (rfqNo, lastBidRnd, encBidPlanAmt, certNo) =>
  axios.post('/subRfq/saveBidPlanAmt', {rfqNo, lastBidRnd, encBidPlanAmt, certNo});
/** 현설결과등록 */
export const completeSpotDscr = param => $save('/subRfq/completeSpotDscr', param);
/** 현설결과취소 */
export const cancelSpotDscr = rfqNo => axios.post('/subRfq/cancelSpotDscr', {rfqNo});

export const extend = (rfqNo, extendYmd, extendHh, extendMm) =>
  $save('/subRfq/extend', {rfqNo, extendYmd, extendHh, extendMm});

/** 입찰공고 */
export const noticeRfq = (rfqNo, lastBidRnd) => axios.post('/subRfq/notice', {rfqNo, lastBidRnd});
/** 입찰공고취소 */
export const cancelNoticeRfq = rfqNo => axios.post('/subRfq/noticeCancel', {rfqNo});
/** 입찰 공고차수정보 조회 */
export const selectRfqDegreeInfo = (rfqNo, lastBidRnd) =>
  axios.post('/subRfq/selectRfqDegreeInfo', {rfqNo, lastBidRnd});

/** 입찰공고품의 정보 조회 */
export const selectSubRfqApprovalInfos = rfqNo => axios.post('/subRfq/selectSubRfqApprovalInfos', {rfqNo});

/** 입찰개봉 */
export const openQuot = rfqNo => axios.post('/subRfq/openQuot', {rfqNo});
/** 재입찰 */
export const reBid = param => axios.post('/subRfq/reBid', param);
/** 유찰 */
export const fail = (rfqNo, nonbidRsltRmrk) => axios.post('/subRfq/fail', {rfqNo, nonbidRsltRmrk});

/** 차수조회 */
export const selectDegreeInfos = (rfqNo, bidRnd) => axios.post('/subRfq/selectDegreeInfos', {rfqNo, bidRnd});
/** 견적제출 회사리스트 조회 */
export const selectQuotCompanyList = (rfqNo, bidRnd) => axios.post('/subQuot/selectQuotCompanyList', {rfqNo, bidRnd});
/** 견적대비표 */
export const selectSubRfqCompareInfos = (rfqNo, bidRnd) =>
  axios.post('/subRfq/selectSubRfqCompareInfos', {rfqNo, bidRnd});
/** 낙찰사선정 */
export const choiceRfq = (rfqNo, cusCd, choiceNotiFlag, choiceRsltRmrk) =>
  axios.post('/subRfq/choiceRfq', {rfqNo, cusCd, choiceNotiFlag, choiceRsltRmrk});
/** 입찰취소 */
export const updateRfqCancelYn = (rfqNo, cancelYn) => axios.post('/subRfq/updateRfqCancelYn', {rfqNo, cancelYn});

/** 현설마감, 입찰마감 (테스트용) */
export const updateExpireDtForTest = (rfqNo, lastBidRnd) =>
  axios.post('/subRfq/updateExpireDtForTest', {rfqNo, lastBidRnd});
/** 현설일자변경 (테스트용) */
export const updateSpotDscrDtForTest = (rfqNo, lastBidRnd, spotDscrDt) =>
  axios.post('/subRfq/updateSpotDscrDtForTest', {rfqNo, lastBidRnd, spotDscrDt});
