<template>
  <iui-dialog-modal mode="alert" :name="name" :title="title" @closed="closed" @confirm="onConfirm">
    <p v-html="message"></p>
  </iui-dialog-modal>
</template>

<script>
import Alert from '@/plugins/alert';
export default {
  data() {
    return {
      message: '',
      title: '',
      name: 'iuiAlert',
      callback: undefined,
      resolve: undefined,
      reject: undefined,
    };
  },
  created() {
    Alert.event.$on('show', this.handleShowEvent);
  },
  methods: {
    handleShowEvent(msg, resolve, reject, callback) {
      if (typeof msg == 'string') {
        this.message = msg;
      }
      if (typeof msg == 'object') {
        this.title = msg.title || 'Alert!';
        this.message = msg.message;
      }

      this.resolve = resolve;
      this.reject = reject;
      this.callback = callback;

      this.$modal.show(this.name, {isDefault: true});
    },

    onConfirm() {
      this.$modal.hide(this.name, {}, true);
    },
    closed() {
      this.message = '';
      this.title = '';

      if (typeof this.callback == 'function') {
        try {
          this.callback();
        } catch (error) {
          this.resolve(error);
        }
      }
      this.resolve();
    },
  },
};
</script>

<style scoped>
p {
  padding: 10px 20px;
  font-size: 16px;
}
</style>
