const state = () => ({
  contSearchMap: {},
  contWSearchList: [],
  contClSearchList: [],
  contExecContactMap: {},
  contContexecPlanDList: [],
  contExeccontPlanDList: [],
  deleteContContexecPlanDList: [],
  deleteContExeccontPlanDList: [],
  dgDtlsLinkList: [],
  linked: false,
});

const getters = {
  contWSearchList: state => state.contWSearchList,
  contClSearchList: state => state.contClSearchList,
  contExecContactMap: state => state.contExecContactMap,
  contContexecPlanDList: state => state.contContexecPlanDList,
  contExeccontPlanDList: state => state.contExeccontPlanDList,
  deleteContContexecPlanDList: state => state.deleteContContexecPlanDList,
  deleteContExeccontPlanDList: state => state.deleteContExeccontPlanDList,
  dgDtlsLinkList: state => state.dgDtlsLinkList,
  linked: state => state.linked,
};

const mutations = {
  setContWSearchList(state, searchMap) {
    state.contSearchMap = searchMap;
    state.contWSearchList = [];
    for (let key in state.contSearchMap) {
      state.contWSearchList.push({
        text: state.contSearchMap[key].name,
        value: key,
      });
    }
  },
  setContClSearchList(state, key) {
    state.contClSearchList = [];
    if (state.contSearchMap[key]) {
      state.contSearchMap[key].clList.forEach(function(o) {
        for (let key in o) {
          state.contClSearchList.push({text: o[key], value: key});
        }
      });
    }
  },
  setContExecContactMap(state, payload) {
    state.contExecContactMap = payload;
  },
  setContContexecPlanDList(state, payload) {
    state.contContexecPlanDList = payload;
  },
  setContExeccontPlanDList(state, payload) {
    state.contExeccontPlanDList = payload;
  },
  setDeleteContContexecPlanDList(state, payload) {
    state.deleteContContexecPlanDList = payload;
  },
  setDeleteContExeccontPlanDList(state, payload) {
    state.deleteContExeccontPlanDList = payload;
  },

  setDgDtlsLinkList(state, payload) {
    state.dgDtlsLinkList = payload;
  },

  setLinked(state, payload) {
    state.linked = payload;
  },
  setIsEstmtPrj(state, payload) {
    state.isEstmtPrj = payload;
  },
};

const actions = {
  setContContexecPlanDList({commit}, payload) {
    let dgUniqueList = payload.reduce((prev, now) => {
      if (!prev.some(obj => obj.midCodeDg == now.midCodeDg && obj.itemSeqDg == now.itemSeqDg)) {
        prev.push(now);
      }
      return prev;
    }, []);
    commit('setContContexecPlanDList', payload);
    commit('setDgDtlsLinkList', dgUniqueList);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
