<template>
  <iui-container-new type="table" theme="bullet">
    <i-row>
      <i-col-header>변경 후 금액</i-col-header>
      <i-col rowspan="3">
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col width="60px">공급가액</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.poAmt" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.amtKor }} 원 정)</label>
            </i-col>
          </i-row>
          <i-row>
            <i-col>부가세</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.vatAmt" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.vatAmtKor }} 원 정)</label>
            </i-col>
          </i-row>
          <i-row>
            <i-col>합계</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.totAmt" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.totAmtKor }} 원 정)</label>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
      <i-spacer />
      <i-col-header>변경 전 금액</i-col-header>
      <i-col rowspan="3">
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col width="60px">공급가액</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.beforePoAmt" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.beforePoAmtKor }} 원 정)</label>
            </i-col>
          </i-row>
          <i-row>
            <i-col>부가세</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.beforeVatAmt" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.beforeVatAmtKor }} 원 정)</label>
            </i-col>
          </i-row>
          <i-row>
            <i-col>합계</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.beforeTotAmt" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.beforeTotAmtKor }} 원 정)</label>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
    <i-row v-for="i in 2" :key="i">
      <i-col />
      <i-spacer />
      <i-col />
    </i-row>
    <i-row>
      <i-col-header required>과세유형</i-col-header>
      <i-col colspan="3">
        <iui-radio-group :p-code="$getConstants('TAX_TYPE_CD').code" :value="poInfo.taxTypecd" :enable="false" />
        <iui-select v-show="poStatus.isTaxDirect" p-code="0291" :value="poInfo.taxDirectDs" :enable="false" />
        <iui-text
          v-show="poStatus.isTaxDirect"
          type="rate"
          suffix="%"
          width="80px"
          :value="poInfo.taxTypeDirectPercent"
          :enable="false"
        />
        <iui-text
          v-show="poStatus.isTaxDirect"
          type="amount"
          suffix="원"
          width="120px"
          :value="poInfo.taxTypeDirectAmt"
          :enable="false"
        />
      </i-col>
      <i-spacer />
    </i-row>
    <i-row>
      <i-col-header>인지세유무</i-col-header>
      <i-col>
        <iui-radio-group :items="stampTaxItems" :value="poInfo.stampTaxYn" :enable="false" />
        <template v-if="poStatus.isStamp">
          <iui-text class="mr10" suffix="원" width="100px" :value="poInfo.stampTaxAmt" :enable="false" />
          <iui-text
            type="rate"
            prefix="당사부담"
            suffix="%"
            width="110px"
            :value="poInfo.stampTaxOwnRate"
            :enable="false"
          />
        </template>
      </i-col>
      <i-spacer />
      <i-col-header>대금지급방법</i-col-header>
      <i-col :rowspan="poStatus.isPayCash ? 5 : 3">
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col width="100px">
              <iui-radio-group :items="payCashItem1" :value="poInfo.payCashYn" :enable="false" />
            </i-col>
            <i-col />
          </i-row>
          <i-row>
            <i-col>
              <iui-radio-group :items="payCashItem2" :value="poInfo.payCashYn" :enable="false" />
            </i-col>
            <i-col v-show="poStatus.isPayCash">
              <label>물품수령 후</label>
              <iui-text type="number" width="30px" suffix="" :value="poInfo.payTerm" :enable="false" />
              <label>일 이내</label>
            </i-col>
          </i-row>
          <i-row v-show="poStatus.isPayCash">
            <i-col />
            <i-col>
              <label>현금 :</label>
              <iui-text type="rate" suffix="%" width="60px" :value="poInfo.payCashRatio" :enable="false" />
              <label>어음 :</label>
              <iui-text type="rate" suffix="%" width="60px" :value="poInfo.payCheckRatio" :enable="false" />
              <label>어음대체수단</label>
              <iui-text type="rate" suffix="%" width="60px" :value="poInfo.payCheck2Ratio" :enable="false" />
            </i-col>
          </i-row>
          <i-row v-show="poStatus.isPayCash">
            <i-col />
            <i-col>
              <label>비고 :</label>
              <iui-text :value="poInfo.payCashRmrk" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col>
              <iui-radio-group :items="payCashItem3" :value="poInfo.payCashYn" :enable="false" />
            </i-col>
            <i-col v-show="poStatus.isPayDefer">
              <iui-text :value="poInfo.payDefer" :enable="poStatus.isEnable && poStatus.isPayDefer" />
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
    <i-row>
      <i-col-header>납품방법</i-col-header>
      <i-col>
        <iui-text :value="poInfo.dlvCondRmrkNm" :enable="false" />
      </i-col>
      <i-spacer />
      <i-col />
    </i-row>
    <i-row>
      <i-col-header required>{{ !poStatus.isPotypeUnit ? '납품일자' : '납품기간' }}</i-col-header>
      <i-col>
        <template v-if="!poStatus.isPotypeUnit">
          <iui-datepicker v-show="!poStatus.isPotypeUnit" :value="poInfo.dlvReqDt" :enable="false" />
        </template>
        <template v-if="poStatus.isPotypeUnit">
          <iui-datepicker :value="poInfo.dlvFrDt" :group="dlvPeriod.frDt" :enable="false" />
          <label>~</label>
          <iui-datepicker :value="poInfo.dlvToDt" :group="dlvPeriod.toDt" :enable="false" />
        </template>
      </i-col>
      <i-spacer />
      <i-col />
    </i-row>
    <i-row>
      <i-col-header>변경사유</i-col-header>
      <i-col>
        <iui-text :value="poInfo.chresonRmrk" :enable="false" />
      </i-col>
      <i-spacer />
      <i-col />
    </i-row>
    <i-row>
      <i-col-header>특약사항</i-col-header>
      <i-col>
        <iui-text :value="poInfo.poRmrk" :enable="false" />
      </i-col>
      <i-spacer />
      <i-col />
    </i-row>
  </iui-container-new>
</template>

<script>
import PoInputMixin from '@/view/bidding/js/poInputMixin.js';

export default {
  mixins: [PoInputMixin],
  props: {
    poInfo: {
      type: Object,
      default: () => ({}),
    },
    poStatus: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
