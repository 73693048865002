export const selectInvestMmList = () => axios.post('/subBill/selectInvestMmList', {});

export const selectSubBillTargetList = param => axios.post('/subBill/selectSubBillTargetList', param);

export const selectSubBillXmlList = billNos => axios.post('/subBill/selectSubBillXmlList', {billNos});

export const selectBillInfos = param => axios.post('/subBill/selectBillInfos', param);

export const cancel = billList => axios.post('/subBill/cancel', {billList});
/** 세금계산서 뷰어 정보 */
export const selectTaxInvoiceViewerInfos = param => axios.post('/subBill/selectTaxInvoiceViewerInfos', param);

export const selectSubBillList = param => axios.post('/subBill/selectSubBillList', param);
