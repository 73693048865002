<template>
  <pmis-content-box>
    <iui-container-new type="table" theme="bullet">
      <colgroup>
        <col style="width:110px;" />
        <col />
        <col style="width:110px;" />
        <col />
      </colgroup>
      <i-row>
        <i-col-header>회사코드</i-col-header>
        <i-col>
          <iui-text :value="cmpInfo.pgmCd" :enable="false" />
        </i-col>
        <i-col-header>회사구분</i-col-header>
        <i-col>
          <iui-text :value="cmpInfo.frmGb" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>회사명</i-col-header>
        <i-col>
          <iui-text :value="cmpInfo.name" :enable="false" />
        </i-col>
        <i-col-header>회사명(영문)</i-col-header>
        <i-col>
          <iui-text :value="cmpInfo.frmEnNm" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>사업자번호</i-col-header>
        <i-col>
          <iui-bsn-no :value1.sync="bsnNo1" :value2.sync="bsnNo2" :value3.sync="bsnNo3" :enable="false" />
        </i-col>
        <i-col-header>주민/법인번호</i-col-header>
        <i-col>
          <iui-regi-no :value1.sync="regiNo1" :value2.sync="regiNo2" size="short" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>대표자명</i-col-header>
        <i-col>
          <iui-text :value="cmpInfo.rpsvNm" :enable="false" />
          <iui-button value="공동대표" />
        </i-col>
        <i-col-header>대표자명(영문)</i-col-header>
        <i-col>
          <iui-text :value="cmpInfo.rpsvEnNm" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>대표전화</i-col-header>
        <i-col>
          <iui-text :value="cmpInfo.rpsvPhone" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header rowspan="2">주소</i-col-header>
        <i-col colspan="3">
          <iui-text width="70px" :value="cmpInfo.postNo" :enable="false" />
          <iui-text :value="cmpInfo.adr1" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col colspan="3">
          <iui-text :value="cmpInfo.adr2" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>홈페이지</i-col-header>
        <i-col>
          <iui-text :value="cmpInfo.homepage" :enable="false" />
        </i-col>
        <i-col></i-col>
        <i-col></i-col>
      </i-row>
      <i-row>
        <i-col-header>업태</i-col-header>
        <i-col>
          <iui-text :value="cmpInfo.bcoNm" :enable="false" />
        </i-col>
        <i-col-header>종목</i-col-header>
        <i-col>
          <iui-text :value="cmpInfo.itemNm" :enable="false" />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
/**
 * 계약정보등록(tab2) > 조직도 > 자사정보(건설사)
 *
 * */
export default {
  beforeCreate() {
    $mapGetters(this, ['cmpInfo']);
  },
  computed: {
    bsnNo1() {
      return String(this.cmpInfo.bsnNo).substr(0, 3);
    },
    bsnNo2() {
      return String(this.cmpInfo.bsnNo).substr(3, 2);
    },
    bsnNo3() {
      return String(this.cmpInfo.bsnNo).substr(5);
    },
    regiNo1() {
      return String(this.cmpInfo.regiNo).substr(0, 6);
    },
    regiNo2() {
      return String(this.cmpInfo.regiNo).substr(6);
    },
  },
};
</script>

<style></style>
