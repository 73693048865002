<template>
  <iui-container-new type="css-flex">
    <i-row height="35px">
      <i-col>
        <pmis-content-box>
          <template v-slot:header-left>
            <iui-searchbox
              label="현장"
              type="prj"
              :idValue="searchInfo.searchPrjCd"
              :nameValue="searchInfo.searchPrjNm"
              :callback="onCallbackPrjCd"
              :idReadonly="true"
              :nameReadonly="true"
              :idEnable="true"
              :nameEnable="true"
            />
          </template>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box border-box>
          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <iui-container-new type="table" theme="bullet">
                  <i-row>
                    <i-col-header>
                      현장명
                    </i-col-header>
                    <i-col>
                      <iui-text :value.sync="prjInfoDtl.prjNm" width="632px" />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>
                      현장대리인
                    </i-col-header>
                    <i-col><iui-text width="200px" :value.sync="prjInfoDtl.empNo1"/></i-col>
                  </i-row>
                  <i-row>
                    <i-col colspan="2">
                      <iui-container-new type="table" theme="bullet">
                        <i-row>
                          <i-col-header>
                            계약일자
                          </i-col-header>
                          <i-col>
                            <iui-text width="200px" :value.sync="prjInfoDtl.ctrtDate" />
                          </i-col>
                          <i-spacer />
                          <i-col-header>
                            공사기간
                          </i-col-header>
                          <i-col>
                            <iui-text width="200px" :value.sync="prjInfoDtl.conPeriodCnt" />
                          </i-col>
                        </i-row>
                        <i-row>
                          <i-col-header>
                            계약금액
                          </i-col-header>
                          <i-col>
                            <iui-text width="200px" :value.sync="prjInfoDtl.ctrtSumAmt" />
                          </i-col>
                          <i-spacer />
                          <i-col-header>
                            공정률
                          </i-col-header>
                          <i-col>
                            <iui-text width="200px" :value.sync="prjInfoDtl.empNo1" />
                          </i-col>
                        </i-row>
                      </iui-container-new>
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>
                      발주처
                    </i-col-header>
                    <i-col>
                      <iui-text :value.sync="prjInfoDtl.orderNm" width="632px" />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>
                      원도급사
                    </i-col-header>
                    <i-col>
                      <iui-text :value.sync="prjInfoDtl.contcompNm" width="632px" />
                    </i-col>
                  </i-row>
                </iui-container-new>
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
export default {
  data() {
    return {
      searchInfo: {
        searchPrjCd: '',
        searchPrjNm: '',
      },
      prjInfoDtl: {
        prjCd: '',
        prjNm: '',
        empNo1: '', // 현장대리인
        ctrtDate: '', // 계약일자
        conPeriodCnt: '', // 공사기간
        ctrtSumAmt: '', // 계약금액
        orderCode: '', // 발주처코드
        orderGroup: '', // 발주처구분
        orderNm: '', // 발주처명
        contcompCode: '', // 원도급사코드
        contcompNm: '', // 원도급사명
      },
    };
  },
  beforeCreate() {
    $mapGetters(this, ['prjInfo']);
    $mapMutations(this, ['setPrjInfo']);
  },
  mounted() {
    this.onSearch();
  },
  activated() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      if (this.prjInfo.prjCd) {
        axios
          .post('/cntrctInfoRegist/selectCntrctInfoList', {
            pgmCd: this.pgmCd,
            prjCd: this.prjInfo.prjCd,
            ctrtChgTo: this.prjInfo.ctrtChgTo,
          })
          .then(response => {
            if (response.status == 200) {
              this.searchInfo.searchPrjCd = response.data[0].prjCd;
              this.searchInfo.searchPrjNm = response.data[0].prjNm;
              this.onCallbackPrjCd(response.data[0]);
            }
          });
      }
    },
    onCallbackPrjCd(data) {
      for (let key in this.prjInfoDtl) {
        if (data && data.hasOwnProperty(key)) {
          this.prjInfoDtl[key] = data[key];
        }
      }
      this.setPrjInfo({prjCd: this.prjInfoDtl.prjCd});
    },
  },
};
</script>

<style scoped></style>
