/**
 * ValidatorRule
 */
class ValidatorRule {
  constructor(required, min, max, minLength, maxLength, pattern) {
    this.required = required;
    this.min = Number(min);
    this.max = Number(max);
    this.minLength = Number(minLength);
    this.maxLength = Number(maxLength);
    this.pattern = pattern;
  }
}

export default ValidatorRule;
