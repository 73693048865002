<template>
  <!-- 질의응답-포틀릿 -->
  <iui-portlet-container>
    <template #title>질의응답{{ `(${count})` }}</template>
    <template #link>
      <a href="#" class="goto_top_link" @click="goShortCut">
        <span class="blind">바로가기</span>
      </a>
    </template>
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr height="30px">
          <th>회사명</th>
          <th>제목</th>
          <th>질의자</th>
          <th>요청일시</th>
        </tr>
      </thead>
    </table>
    <div class="inner" style="height:calc(100% - 40px);">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="(qna, index) in tableData" :key="index">
            <td style="text-align:left;">{{ qna.frmNm }}</td>
            <td style="text-align:left;">
              <a @click="goShortCut(qna)">{{ qna.tl }}</a>
            </td>
            <td>{{ qna.qnm }}</td>
            <td>{{ qna.qdttm }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </iui-portlet-container>
</template>

<script>
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
import {selectQnaList} from '@/components/portlet/api/portletData.js';
export default {
  mixins: [PortletMixin],
  data() {
    return {
      cnt: 4,
      count: 0,
      tableData: [],
    };
  },
  methods: {
    setWidth() {
      this.tblWidth = this.$el.offsetWidth - 28;
    },
    async onSearch() {
      const response = await selectQnaList();
      this.tableData = response.data;
      this.count = response.data.length;
    },
    goShortCut(data) {
      const param = {pgmCd: data.pgmCd, empNo: data.qid, natNo: data.natNo};
      this.goMenu('QnA_ANSWER', param);
    },
  },
};
</script>

<style></style>
