import * as util from '@/components/Iui/util.js';
import {BUTTON_TYPE} from '@/components/Iui/const.js';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import IuiCssFlexMixin from '@/components/Iui/mixins/IuiCssFlexMixin';

export default {
  name: 'i-col',
  mixins: [IuiLayoutMixin, IuiCssFlexMixin],
  inject: ['containerType', 'containerHeaderWidth'],
  props: {
    header: {
      type: Boolean,
      default: false,
    },
    colspan: {
      type: String,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
    depth: {
      type: [Number, String],
    },
  },
  provide() {
    return {
      defaultButtonType: BUTTON_TYPE.SMALL,
    };
  },
  render(h) {
    /**
     * header 처리를 위한 변수
     * header 속성이 있거나, 태그명이 i-col-header일 경우
     */
    let isHeader = this.header || this.$options._componentTag === 'i-col-header';

    let data = {};

    /**
     * tag 처리
     */
    let tag = 'tr';

    switch (this.containerType) {
      case 'table': {
        tag = isHeader ? 'th' : 'td';
        break;
      }
      case 'css-table': {
        tag = 'div';
        break;
      }
      case 'css-flex-table': {
        tag = 'div';
        break;
      }
      case 'css-flex': {
        tag = 'div';
        break;
      }
      case 'css-grid': {
        tag = 'div';
        break;
      }
      default: {
        tag = 'td';
        break;
      }
    }

    /**
     * class 처리
     */
    let classList = [];
    let defaultClassName = 'col';

    if (isHeader) {
      defaultClassName += '-header';
    }

    const depth = {};
    depth[`depth${this.depth}`] = this.depth !== undefined;
    classList.push(depth);

    classList.push({[defaultClassName]: true});
    classList.push({required: this.required});

    util.appendArrayToObject(data, 'class', classList);

    /**
     * style 처리
     */
    let styleList = [];

    if (this.containerType === 'css-flex') {
      if (this.minWidth !== undefined) {
        styleList.push({'min-width': this.minWidth});
      }
      if (this.maxWidth !== undefined) {
        styleList.push({'max-width': this.maxWidth});
      }
      if (this.width !== undefined) {
        styleList.push({flex: `0 0 ${this.width}`});
      } else if (this.flex !== undefined) {
        styleList.push({flex: this.flex});
      }
    }

    if (this.containerType === 'css-flex-table') {
      if (this.colspan !== undefined) {
        //   styleList.push({flex: '1'});
        // } else {
        styleList.push({flex: `${this.colspan}`});
      }

      if (this.width !== undefined) {
        styleList.push({flex: `0 0 ${this.width}`});
      } else {
        if (this.flex !== undefined) {
          styleList.push({flex: this.flex});
        } else {
          styleList.push({flex: '1'});
        }
      }
    }

    if (this.containerType === 'table') {
      if (this.width !== undefined) {
        styleList.push({width: this.width});
      } else if (isHeader && this.containerHeaderWidth !== undefined) {
        styleList.push({width: this.containerHeaderWidth});
        styleList.push({'min-width': this.containerHeaderWidth});
      }

      if (this.minWidth !== undefined) {
        styleList.push({'min-width': this.minWidth});
      }
      if (this.maxWidth !== undefined) {
        styleList.push({'max-width': this.maxWidth});
      }

      if (this.minHeight !== undefined) {
        styleList.push({'min-height': this.minHeight});
      }
      if (this.height !== undefined) {
        styleList.push({height: this.height});
      }
      // if (this.align !== undefined) {
      //   styleList.push({'text-align': this.align});
      // }
    }

    util.appendArrayToObject(data, 'style', styleList);

    /**
     * attrs 처리
     */
    let attrList = [];

    if (this.containerType === 'table') {
      if (this.rowspan !== undefined && this.rowspan > 1) {
        attrList.push({rowspan: this.rowspan});
      }
      if (this.colspan !== undefined && this.colspan > 1) {
        attrList.push({colspan: this.colspan});
      }
    }

    util.appendArrayToObject(data, 'attrs', attrList);

    /**
     * childNode 처리
     */
    let childNodes = [];

    let justifyContent = '';
    let alignContent = '';

    switch (this.align) {
      case 'left':
        justifyContent = 'flex-start';
        break;
      case 'center':
        justifyContent = 'center';
        break;
      case 'right':
        justifyContent = 'flex-end';
        break;
      default:
        justifyContent = this.align;
        break;
    }

    switch (this.verticalAlign) {
      case 'top':
        alignContent = 'flex-start';
        break;
      case 'middle':
        alignContent = 'center';
        break;
      case 'bottom':
        alignContent = 'flex-end';
        break;
      default:
        alignContent = this.verticalAlign;
        break;
    }

    if (this.containerType === 'css-flex-table') {
      let cellBox = h(
        'div',
        {
          class: {'border-box': true, 'cell-box': true},
          style: {'justify-content': justifyContent, 'align-content': alignContent},
        },
        [this.$slots.default]
      );
      childNodes.push(cellBox);
    } else if (this.containerType === 'table') {
      let cellBox = h(
        'div',
        {class: {'cell-box': true}, style: {'justify-content': justifyContent, 'align-content': alignContent}},
        [this.$slots.default]
      );
      childNodes.push(cellBox);
    } else {
      if (justifyContent || alignContent) {
        let obj = {};
        obj['justify-content'] = justifyContent || false;
        obj['align-content'] = alignContent || false;
        obj['display'] = 'flex';
        obj['flex-flow'] = 'row';
        styleList.push(obj);
        util.appendArrayToObject(data, 'style', styleList);
      }

      childNodes.push(this.$slots.default);
    }

    /**
     * VNode 생성
     */
    let vnode = null;
    vnode = h(tag, data, childNodes);

    return vnode;
  },
};
