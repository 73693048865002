<template>
  <pmis-page ref="page" @new-click="showOfflineAppDoc" @search-click="onSearch">
    <AppList :title="title" :appReceiptStandby="appReceiptStandby" :options="Options" :search.sync="search" />
    <iui-modal name="offlineAppDocRegPop" title="비전자문서 등록" sizeType="size2" :btns="offlineAppDocBtns">
      <OfflineAppDocPop :confirm.sync="confirm" :callback="callbackOfflineAppDoc" />
    </iui-modal>
  </pmis-page>
</template>

<script>
import options from '../components/sheetOption/appReceiptStandbySheet.js';
import AppList from '../components/AppList.vue';
import OfflineAppDocPop from '../components/OfflineAppDocPop.vue';
import {saveOfflineAppDoc} from '../api/appDoc.js';
export default {
  components: {
    AppList,
    OfflineAppDocPop,
  },
  data() {
    return {
      title: '접수대기',
      appReceiptStandby: true,
      Options: $addColCheckbox(options(this)),
      search: false,
      confirm: false,
      offlineAppDocBtns: [
        {
          name: '확인',
          callback: this.confirmOfflineAppDoc,
        },
      ],
    };
  },
  created() {
    this.onSearch();
    this.canSave = false;
  },

  methods: {
    onSearch() {
      this.search = true;
    },
    showOfflineAppDoc() {
      this.$modal.show('offlineAppDocRegPop');
    },
    confirmOfflineAppDoc() {
      this.confirm = true;
    },
    async callbackOfflineAppDoc(data) {
      const callback = (fileInfo, param) => {
        if (fileInfo.offlineFile) {
          param[fileInfo.offlineFile.flNoCol] = fileInfo.offlineFile.flNo;
          return param;
        }
        this.$alert({title: '첨부파일', message: '파일을 첨부해주세요.'});
        return false;
      };
      const param = data;
      const response = await saveOfflineAppDoc(param, {callback});
      if (response.data) {
        this.$modal.hide('offlineAppDocRegPop');
        this.search = true;
      }
    },
  },
};
</script>

<style></style>
