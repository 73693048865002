<template>
  <modal
    :name="name"
    width="400px"
    height="auto"
    :classes="['v--topPosition']"
    :scrollable="false"
    :clickToClose="false"
    style="z-index: 100;"
    @before-open="beforeOpen"
    @before-close="beforeClose"
    @opened="opened"
    @closed="closed"
    :adaptive="true"
  >
    <div class="popup_modal">
      <div class="fg" :style="{width: '100%', height: '100%'}">
        <div class="popup_header">
          <div>{{ title }}</div>
        </div>
        <div class="popup_contents">
          <slot></slot>
        </div>
        <div class="popup_footer">
          <div class="footer-left"></div>
          <div class="footer-center">
            <iui-button normal ref="confirm" @click="onConfirm">확인</iui-button>
            <iui-button normal color="gray" v-if="mode == 'confirm'" @click="onCancel">취소</iui-button>
          </div>
          <div class="footer-right"></div>
        </div>
      </div>
    </div>
    <!-- <input type="hidden" ref="fakeInput" /> -->
  </modal>
</template>

<script>
import {modalOpen, afterModalClose} from '@/modalEvent';

export default {
  props: {
    name: {
      type: String,
    },
    title: {
      type: String,
      default: undefined,
    },
    mode: {
      type: String,
      default: 'alert',
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    beforeOpen(event) {
      this.$emit('beforeOpen', event);
      if (event.params) {
        this.internalTitle = event.params.title;
      }
      modalOpen(this);
    },
    opened() {
      this.$emit('opened');
      this.$refs.confirm.$refs.button.addEventListener('keyup', event => {
        if (event.keyCode === 27) {
          this.$modal.hide(this.name, {}, true);
        }
      });
      this.$refs.confirm.$refs.button.addEventListener('blur', event => {
        event.target.focus();
      });
      this.$refs.confirm.$refs.button.focus();
    },
    beforeClose(e) {
      this.$emit('beforeClose', e);
    },
    closed() {
      this.$emit('closed');
      afterModalClose(this);
    },
    onConfirm() {
      this.$emit('confirm');
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
<style>
.v--topPosition {
  top: 150px !important;
}
</style>
