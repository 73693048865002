<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <label>상담기간</label>
      <iui-datepicker
        :group="{grpNm: 'consDt', seq: 1}"
        :value="searchInfo.consDtFrom"
        @change="setSearchInfo1('consDtFrom', $event)"
        :suffix="'~'"
      />
      <iui-datepicker
        :group="{grpNm: 'consDt', seq: 2}"
        :value="searchInfo.consDtTo"
        @change="setSearchInfo1('consDtTo', $event)"
      />
      <span style="width:50px"></span>
      <iui-radio-group
        :items="compltDs"
        :value="searchInfo.compltDs"
        @change="setSearchInfo1('compltDs', $event.target.value)"
      />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="loadSearchData"
      @loadSheet="sheet_loadSheet"
      :events="{onSearchFinish: grid_onSearchFinish, onFocus: grid_onFocus}"
    />
  </pmis-content-box>
</template>

<script>
import {mapGetters} from 'vuex';
import sheetOpt from './sheetOption/PurchsCnsltSttus.js';
export default {
  data() {
    return {
      loadSearchData: [],
      sheetOpt: sheetOpt(this),

      compltDs: [
        {label: '전체', value: ''},
        {label: $getConstants('COMPLT_DS_Y').name, value: $getConstants('COMPLT_DS_Y').code},
        {label: $getConstants('COMPLT_DS_N').name, value: $getConstants('COMPLT_DS_N').code},
      ],
      focusKey: {},
    };
  },
  computed: {
    ...mapGetters(['externalData']),
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'consultInfo']);
    $mapMutations(this, ['setSearchInfo', 'setConsultInfo', 'initConsultInfo']);
  },
  created() {
    this.addEvent([{name: 'PurchsCnsltSttus_onSearch', func: this.onSearch}]);
    this.setSearchInfo({compltDs: $getConstants('COMPLT_DS_Y').code});
    this.setExternalData();
    this.onSearch();
  },
  activated() {
    if (this.sheet) {
      this.setExternalData();
      this.grid_onSearchFinish();
    }
  },
  methods: {
    setSearchInfo1(key, value) {
      this.setSearchInfo({[key]: value});
      this.onSearch();
    },
    sheet_loadSheet(sheet) {
      this.sheet = sheet;
    },
    // that = this
    // group = list:group=['consDtFrom', 'consDtTo'] ||
    //                    [{consDtFrom :this.consDtFrom}, {consDtTo:this.consDtTo}]
    // index = number || key = string
    // value = event
    // setDuration1(that, state, group, key, value) {
    //   let index = group.indexOf(key);
    //   if (index >= 0) {
    //     let beforeVar = state ? that[state][group[index - 1]] : that[group[index - 1]];
    //     let nextVar = state ? that[state][group[index + 1]] : that[group[index + 1]];

    //     if (
    //       (group[index - 1] && beforeVar && value && value < beforeVar) ||
    //       (group[index + 1] && nextVar && value && value > nextVar)
    //     ) {
    //       value = '';
    //     }
    //     return value;
    //   }
    // },
    // setDuration(that, param) {
    //   let index = param.group.indexOf(param.key);
    //   if (index >= 0) {
    //     let beforeVar = param.state ? that[param.state][param.group[index - 1]] : that[param.group[index - 1]];
    //     let nextVar = param.state ? that[param.state][param.group[index + 1]] : that[param.group[index + 1]];

    //     if (
    //       (param.group[index - 1] && beforeVar && param.value && param.value < beforeVar) ||
    //       (param.group[index + 1] && nextVar && param.value && param.value > nextVar)
    //     ) {
    //       param.value = '';
    //     }
    //     return param.value;
    //   }
    // },
    // setPeriod(key, value) {
    //   let param = {
    //     state: 'searchInfo',
    //     group: ['consDtFrom', 'consDtTo'],
    //     key: key,
    //     value: value,
    //   };

    //   value = this.setDuration(this, param);

    //   this.setSearchInfo({[key]: value});
    //   this.onSearch();
    // },
    onSearch() {
      axios.post('/purchsCnslt/selectPurchsCnsltInfo', this.searchInfo).then(response => {
        if (response.status == 200) {
          this.loadSearchData = response.data;
        }
      });
    },
    grid_onSearchFinish() {
      if (this.sheet.getDataRows().length > 0 && Object.keys(this.focusKey).length > 0) {
        let focusRow = this.sheet
          .getDataRows()
          .find(row => row.reqDt == this.focusKey.reqDt && row.seq == this.focusKey.seq);
        setTimeout(() => {
          this.sheet.focus(focusRow);
        }, 10);
      } else if (!this.sheet.getDataRows().length) {
        this.initConsultInfo();
      }
    },
    grid_onFocus(e) {
      this.setConsultInfo(e.row);
    },
    setExternalData() {
      if (Object.keys(this.externalData).length > 0) {
        this.focusKey = this.externalData;
        this.$store.dispatch('setExternalData', {});
      }
    },
  },
};
</script>

<style></style>
