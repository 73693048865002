<template>
  <pmis-content-box>
    <template v-slot:title>
      문서정보
    </template>
    <iui-container-new type="table" theme="bullet">
      <colgroup>
        <col width="90px" />
        <col />
        <col width="90px" />
        <col width="200px" />
      </colgroup>
      <i-row>
        <i-col-header required>
          문서번호
        </i-col-header>
        <i-col>
          <iui-text
            :value.sync="docNo1"
            required
            width="250px"
            max-length="100"
            :error-message="{title: '문서번호', message: '문서번호를 입력해주세요.'}"
          />
        </i-col>
        <i-col-header required>
          시행일자
        </i-col-header>
        <i-col>
          <iui-datepicker
            :value.sync="enfcDt"
            required
            :error-message="{title: '시행일자', message: '시행일자를 입력해주세요.'}"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>
          제목
        </i-col-header>
        <i-col colspan="3">
          <iui-text
            :value.sync="tl"
            max-length="100"
            required
            :error-message="{title: '제목', message: '제목을 입력해주세요.'}"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>
          발신처명
        </i-col-header>
        <i-col colspan="3">
          <iui-text
            :value.sync="sndNmn"
            required
            max-length="200"
            :error-message="{title: '발신처명', message: '발신처명을 입력해주세요.'}"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>
          기안부서
        </i-col-header>
        <i-col>
          <iui-text
            :value.sync="draftDepNm"
            required
            max-length="100"
            width="250px"
            :error-message="{title: '기안부서', message: '기안부서를 입력해주세요.'}"
          />
        </i-col>
        <i-col-header required>
          기안자
        </i-col-header>
        <i-col>
          <iui-text
            :value.sync="draftNm"
            max-length="50"
            required
            :error-message="{title: '기안자', message: '기안자를 입력해주세요.'}"
          />
        </i-col>
      </i-row>
      <i-row height="300px">
        <i-col colspan="4">
          <pmis-content-box>
            <pmis-file-list
              id="offlineFile"
              :fileNumber="fileNumber"
              title="첨부파일"
              theme="bullet"
              :saveAuth="true"
              required
            />
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row height="45px">
        <i-col-header required>수신일자</i-col-header>
        <i-col>
          <iui-datepicker
            required
            :value.sync="rcvDttm"
            :error-message="{title: '수신일자', message: '수신일자를 입력해주세요.'}"
        /></i-col>
        <i-col-header required>시행종류</i-col-header>
        <i-col>
          <iui-select
            :p-code="$getConstants('DOC_CS_DS').code"
            :value.sync="docCsDs"
            :exclude-items="`${$getConstants('DOC_CS_DS_IN_DEP').code}`"
            :error-message="{title: '시행종류', message: '시행종류를 입력해주세요.'}"
          />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import APPROVAL from '../js/approvalConstants.js';
export default {
  props: {
    sncNo: {
      type: Number,
    },
    callback: {
      type: Function,
    },
    confirm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileNumber: 0,
      docNo1: '',
      enfcDt: '',
      tl: '',
      sndNmn: '',
      draftDepNm: '',
      draftNm: '',
      rcvDttm: '',
      docCsDs: APPROVAL.DOC_CS_DS_EXTERNAL,
    };
  },
  watch: {
    confirm(value) {
      if (value) {
        const param = {
          docNo1: this.docNo1,
          enfcDt: dateFormat(this.enfcDt),
          tl: this.tl,
          sndNmn: this.sndNmn,
          draftDepNm: this.draftDepNm,
          draftNm: this.draftNm,
          rcvDttm: dateFormat(this.rcvDttm),
          docCsDs: this.docCsDs,
        };
        this.callback(param);
        this.$emit('update:confirm', false);
      }
    },
  },
};
</script>

<style></style>
