export default that => {
  return {
    Cfg: {
      SearchMode: 2,
    },
    Cols: [
      {
        Header: '아이디',
        Name: 'empNo',
        Type: 'Text',
        Align: 'Center',
        MinWidth: 150,
        RelWidth: 1,
        Visible: 1,
        CanEdit: 0,
      },
      {
        Header: '성명',
        Name: 'empNm',
        Type: 'Text',
        Align: 'Center',
        MinWidth: 150,
        RelWidth: 1,
        Visible: 1,
        CanEdit: 0,
      },
      {
        Header: '업무구분',
        Name: 'bizTypeNm',
        Type: 'Text',
        Align: 'Center',
        MinWidth: 150,
        RelWidth: 1,
        Visible: 1,
        CanEdit: 0,
      },
      {
        Header: '내용',
        Name: 'message',
        Type: 'Text',
        Align: 'Center',
        MinWidth: 150,
        RelWidth: 1,
        Visible: 1,
        CanEdit: 0,
      },
      {
        Header: '진행상태',
        Name: 'msgStateNm',
        Type: 'Text',
        Align: 'Center',
        MinWidth: 150,
        RelWidth: 1,
        Visible: 1,
        CanEdit: 0,
      },
      {
        Header: '결과 코드',
        Name: 'rsltCode',
        Type: 'Text',
        Align: 'Center',
        MinWidth: 150,
        RelWidth: 1,
        Visible: 1,
        CanEdit: 0,
      },
      {
        Header: '결과 메세지',
        Name: 'rsltExpla',
        Type: 'Text',
        Align: 'Center',
        MinWidth: 150,
        RelWidth: 1,
        Visible: 1,
        CanEdit: 0,
      },
      {
        Header: '발송 방법',
        Name: 'rsltTypeNm',
        Type: 'Text',
        Align: 'Center',
        MinWidth: 150,
        RelWidth: 1,
        Visible: 1,
        CanEdit: 0,
      },
      {
        Header: '발송일시',
        Name: 'rgsDttm',
        Align: 'Center',
        MinWidth: 150,
        RelWidth: 1,
        Visible: 1,
        CanEdit: 0,
      },
    ],
  };
};
