export default that => {
  return {
    Cfg: {
      CanEdit: 3,
      IgnoreFocused: true,
    },
    Cols: [
      {
        Name: 'tl',
        Header: '그룹명',
        RelWidth: 1,
      },
      {Name: 'grpNo', Visible: false},
      {Name: 'inoDs', Visible: false},
    ],
  };
};
