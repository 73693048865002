<template>
  <iui-container-new type="table" theme="bullet">
    <i-row>
      <i-col-header>계약금액</i-col-header>
      <i-col rowspan="5">
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col width="60px">공급가액</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.poAmt" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.amtKor }} 원 정)</label>
            </i-col>
          </i-row>
          <i-row>
            <i-col>부가세</i-col>
            <i-col>
              <iui-text
                type="amount"
                prefix="\"
                width="130px"
                :value="poInfo.vatAmt"
                :enable="poStatus.isEnable && !poStatus.isExistRfq"
                @change="onChange_taxType('taxTypeDirectAmt', $event.target.value)"
              />
              <label class="font8pt">(일금 {{ poInfo.vatAmtKor }} 원 정)</label>
            </i-col>
          </i-row>
          <i-row>
            <i-col>합계</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.totAmt" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.totAmtKor }} 원 정)</label>
            </i-col>
          </i-row>
          <i-row>
            <i-col>노무비</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.labAmt" :enable="false" />
              <iui-checkbox-group
                :items="labItems"
                :checkedValues="[poInfo.labYn]"
                @change="setPoInfo({labYn: $event.target.checked ? $event.target.value : ''})"
                :enable="poStatus.isEnable"
              />
            </i-col>
          </i-row>
          <i-row>
            <i-col>&nbsp;</i-col>
            <i-col>
              <span class="fontWeightBold font8pt">
                [*건설산업기본법시행령` 제 84조 규정에 의한 노무비]
              </span>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
      <i-spacer />
      <i-col-header>대금의 지급</i-col-header>
      <i-col />
    </i-row>
    <i-row>
      <i-col />
      <i-spacer />
      <i-col rowspan="8" colspan="2">
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col width="60px">- 선급금</i-col>
            <i-col>
              <span>(1) 계약체결후</span>
              <iui-text
                type="number"
                width="30px"
                :value="poInfo.prepayDay1"
                @change="setPoInfo({prepayDay1: $event.target.value})"
                :enable="poStatus.isEnable"
              />
              <span>일 이내에</span>
              <iui-text
                type="amount"
                width="100px"
                :value="poInfo.prepayAmt"
                @change="setPoInfo({prepayAmt: $event.target.value})"
                :enable="poStatus.isEnable"
              />
              <pmis-file-list
                id="prepayFlNo"
                flNoCol="prepayFlNo"
                title="첨부파일"
                title-visible
                :modal="true"
                :toolbar-visible="true"
                :modalBtnEnable="true"
                :company-code="poInfo.cusPgmCd"
                :fileNumber="poInfo.prepayFlNo"
                :allow-add="false"
                :hidden="
                  !(
                    poStatus.isPoSend ||
                    poStatus.isPoSignComplete ||
                    poStatus.isAfterPoSignComplete ||
                    poStatus.isPoComplete
                  )
                "
              />
            </i-col>
          </i-row>
          <i-row>
            <i-col>&nbsp;</i-col>
            <i-col>
              <span>(2) 발주자로부터 지급받은 날 또는 계약일로부터</span>
              <iui-text
                type="number"
                width="30px"
                :value="poInfo.prepayDay2"
                @change="setPoInfo({prepayDay2: $event.target.value})"
                :enable="poStatus.isEnable"
              />
              <span>일 이내 그 내용과 비율에 따름</span>
            </i-col>
          </i-row>
          <i-row>
            <i-col>- 기성금</i-col>
            <i-col>
              <span>(1)</span>
              <iui-text
                type="number"
                width="30px"
                :value="poInfo.gisungMonth"
                @change="setPoInfo({gisungMonth: $event.target.value})"
                :enable="poStatus.isEnable"
              />
              <span>월</span>
              <iui-text
                type="number"
                width="30px"
                :value="poInfo.gisungCount"
                @change="setPoInfo({gisungCount: $event.target.value})"
                :enable="poStatus.isEnable"
              />
              <span>회</span>
            </i-col>
          </i-row>
          <i-row>
            <i-col>&nbsp;</i-col>
            <i-col>
              <span>(2) 목적물 수령일로부터</span>
              <iui-text
                type="number"
                width="30px"
                :value="poInfo.gisungDay"
                :enable="poStatus.isEnable"
                @change="setPoInfo({gisungDay: $event.target.value})"
              />
              <span>일 이내</span>
            </i-col>
          </i-row>
          <i-row>
            <i-col>&nbsp;</i-col>
            <i-col>
              <span>(3) 지급방법 :</span>
              <iui-radio-group
                :p-code="$getConstants('PAY_COND_RMRK_CD').code"
                :exclude-items="$getConstants('PAY_COND_RMRK_CD_RESERVATION').code"
                :enable="poStatus.isEnable"
                :value="poInfo.payCashYn"
                @change="
                  setPoInfo({
                    payCashYn: $event.target.value,
                    payCashRatio: '',
                    payCheckRatio: '',
                    payCheck2Ratio: '',
                    payDefer: '',
                  })
                "
              />
            </i-col>
          </i-row>
          <i-row>
            <i-col>&nbsp;</i-col>
            <i-col>
              <template v-if="poStatus.isPayCash">
                <label>(현금</label>
                <iui-text
                  type="rate"
                  suffix=""
                  width="60px"
                  :value="poInfo.payCashRatio"
                  :enable="poStatus.isEnable"
                  :required="poStatus.isEnable && poStatus.isPayCash"
                  :errorMessage="{title: '대금지급방법 (현금, 어음) - 현금', message: '필수입력입니다.'}"
                  @change="onChange_payRatio('payCashRatio', $event.target.value)"
                />
                <label>%, 어음</label>
                <iui-text
                  type="rate"
                  suffix=""
                  width="60px"
                  :value="poInfo.payCheckRatio"
                  :enable="poStatus.isEnable"
                  :required="poStatus.isEnable && poStatus.isPayCash"
                  :errorMessage="{title: '대금지급방법 (현금, 어음) - 어음', message: '필수입력입니다.'}"
                  @change="onChange_payRatio('payCheckRatio', $event.target.value)"
                />
                <label>%, 어음대체결제수단</label>
                <iui-text
                  type="rate"
                  suffix=""
                  width="60px"
                  :value="poInfo.payCheck2Ratio"
                  :enable="poStatus.isEnable"
                  :required="poStatus.isEnable && poStatus.isPayCash"
                  :errorMessage="{title: '대금지급방법 (현금, 어음) - 어음대체결제수단', message: '필수입력입니다.'}"
                  @change="onChange_payRatio('payCheck2Ratio', $event.target.value)"
                />
                <label>%)</label>
              </template>
            </i-col>
          </i-row>
          <i-row>
            <i-col colspan="2">
              - 설계변경, 경제상황변동 등에 따른 대금조정 및 지급
            </i-col>
          </i-row>
          <i-row>
            <i-col>&nbsp;</i-col>
            <i-col>
              <span>(1) 발주자로부터 조정받은 날로부터</span>
              <iui-text
                type="number"
                width="30px"
                :value="poInfo.negoDay1"
                @change="setPoInfo({negoDay1: $event.target.value})"
                :enable="poStatus.isEnable"
              />
              <span>일 이내 그 내용과 비율에 따라 조정</span>
            </i-col>
          </i-row>
          <i-row>
            <i-col>&nbsp;</i-col>
            <i-col>
              <span>(2) 발주자로부터 지급받은 날로부터</span>
              <iui-text
                type="number"
                width="30px"
                :value="poInfo.negoDay2"
                @change="setPoInfo({negoDay2: $event.target.value})"
                :enable="poStatus.isEnable"
              />
              <span>일 이내 지급</span>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
    <i-row>
      <i-col />
      <i-spacer />
    </i-row>
    <i-row>
      <i-col />
      <i-spacer />
    </i-row>
    <i-row>
      <i-col />
      <i-spacer />
    </i-row>
    <i-row>
      <i-col-header required>과세유형</i-col-header>
      <i-col>
        <iui-radio-group
          :p-code="$getConstants('TAX_TYPE_CD').code"
          :value="poInfo.taxTypecd"
          :enable="poStatus.isEnable && !poStatus.isExistRfq"
          @change="onChange_taxType('taxTypecd', $event.target.value)"
        />
        <template v-if="poStatus.isTaxDirect">
          <iui-select
            width="55px"
            :p-code="$getConstants('TAX_DIRECT_DS').code"
            :value="poInfo.taxDirectDs"
            :enable="poStatus.isEnable && !poStatus.isExistRfq && poStatus.isTaxDirect"
            @change="value => onChange_taxType('taxDirectDs', value)"
          />
          <iui-text
            type="rate"
            width="55px"
            :value="poInfo.taxTypeDirectPercent"
            :enable="poStatus.isEnable && !poStatus.isExistRfq && poStatus.isTaxDirect && poStatus.isTaxDirectPercent"
            :required="poStatus.isEnable && !poStatus.isExistRfq && poStatus.isTaxDirect && poStatus.isTaxDirectPercent"
            :errorMessage="{title: '과세율', message: '필수입력입니다.'}"
            @change="onChange_taxType('taxTypeDirectPercent', $event.target.value)"
          />
          <iui-text
            type="amount"
            width="90px"
            :value="poInfo.taxTypeDirectAmt"
            :enable="poStatus.isEnable && !poStatus.isExistRfq && poStatus.isTaxDirect && !poStatus.isTaxDirectPercent"
            :required="
              poStatus.isEnable && !poStatus.isExistRfq && poStatus.isTaxDirect && !poStatus.isTaxDirectPercent
            "
            :errorMessage="{title: '과세금액', message: '필수입력입니다.'}"
            @change="onChange_taxType('taxTypeDirectAmt', $event.target.value)"
          />
        </template>
      </i-col>
      <i-spacer />
    </i-row>
    <i-row>
      <i-col-header required>인지세유무</i-col-header>
      <i-col>
        <iui-checkbox-group
          :checkedValues="[poInfo.stampTaxYn]"
          :items="[{label: $getConstants('EXTERNAL_Y').name, value: $getConstants('EXTERNAL_Y').code}]"
          @change="onChange_stampTaxYn"
          :enable="poStatus.isEnable && poStatus.isStampTarget"
        />
        <template v-if="poStatus.isStamp">
          <iui-text class="mr10" type="amount" suffix="원" width="100px" :value="poInfo.stampTaxAmt" :enable="false" />
          <iui-text
            type="rate"
            prefix="당사부담"
            suffix="%"
            width="110px"
            :value="poInfo.stampTaxOwnRate"
            @change="onChange_stampTaxOwnRate($event.target.value)"
            :enable="poStatus.isEnable && poStatus.isStamp"
            :required="poStatus.isEnable && poStatus.isStamp"
            :errorMessage="{title: '인지세율', message: '필수입력입니다.'}"
          />
        </template>
      </i-col>
      <i-spacer />
    </i-row>
    <i-row>
      <i-col-header required>계약 보증금</i-col-header>
      <i-col>
        <span class="ml5">계약금액의</span>
        <iui-text
          type="rate"
          width="60px"
          :value="poInfo.prfmInsrRate"
          @change="onChange_rate('prfmInsrRate', $event.target.value)"
          :enable="poStatus.isEnable"
          :required="poStatus.isEnable"
          :errorMessage="{title: '계약 보증율', message: '필수입력입니다.'}"
        />
        <span>(</span>
        <iui-text type="amount" suffix="" width="100px" :value="poInfo.prfmInsrAmt" :enable="false" />
        <span>원)</span>
        <pmis-file-list
          id="prfmInsrFlNo"
          flNoCol="prfmInsrFlNo"
          title="첨부파일"
          title-visible
          :modal="true"
          :toolbar-visible="true"
          :modalBtnEnable="true"
          :company-code="poInfo.cusPgmCd"
          :fileNumber="poInfo.prfmInsrFlNo"
          :allow-add="false"
          :hidden="
            !(poStatus.isPoSend || poStatus.isPoSignComplete || poStatus.isAfterPoSignComplete || poStatus.isPoComplete)
          "
        />
      </i-col>
      <i-spacer />
    </i-row>
    <i-row>
      <i-col-header required>지체상금율</i-col-header>
      <i-col>
        <span class="ml5">계약금액의</span>
        <iui-text
          type="rate"
          width="60px"
          :value="poInfo.delayRate"
          @change="onChange_rate('delayRate', $event.target.value)"
          :enable="poStatus.isEnable"
          :required="poStatus.isEnable"
          :errorMessage="{title: '지체상금율', message: '필수입력입니다.'}"
        />
        <span>(</span>
        <iui-text type="amount" suffix="" width="100px" :value="poInfo.delayAmt" :enable="false" />
        <span>원)</span>
        <pmis-file-list
          id="delayFlNo"
          flNoCol="delayFlNo"
          title="첨부파일"
          title-visible
          :modal="true"
          :toolbar-visible="true"
          :modalBtnEnable="true"
          :company-code="poInfo.cusPgmCd"
          :fileNumber="poInfo.delayFlNo"
          :allow-add="false"
          :hidden="
            !(poStatus.isPoSend || poStatus.isPoSignComplete || poStatus.isAfterPoSignComplete || poStatus.isPoComplete)
          "
        />
      </i-col>
      <i-spacer />
    </i-row>
    <i-row>
      <i-col rowspan="3" colspan="2">
        <iui-container-new type="table" theme="bullet" header-width="150px">
          <i-row>
            <i-col-header required>하도급대금 지급보증금</i-col-header>
            <i-col>
              <iui-checkbox-group
                :checkedValues="[poInfo.scpayInsrYn]"
                :items="scpayInsrItems1"
                :enable="poStatus.isEnable"
                @change="onChange_scpayInsrYn"
              />
              <template v-if="poStatus.isScpayInsrY">
                <span>(</span>
                <iui-text
                  type="amount"
                  suffix=""
                  width="100px"
                  :value="poInfo.scpayInsrAmt"
                  :enable="poStatus.isEnable"
                  :required="poStatus.isEnable"
                  :errorMessage="{title: '하도급대금 지급보증금', message: '필수입력입니다.'}"
                  @change="setPoInfo({scpayInsrAmt: $event.target.value})"
                />
                <span>원)</span>
                <iui-button v-if="poStatus.isEnable" value="보증금액계산기" @click="openScpayInsrCaculatorModal" />
              </template>
              <template v-if="poStatus.isScpayInsrN">
                <span>(</span>
                <iui-checkbox-group
                  :items="scpayInsrIncItems"
                  :checkedValues="[poInfo.scpayInsrIncYn]"
                  :enable="poStatus.isEnable"
                  @change="setPoInfo({scpayInsrIncYn: $event.target.checked ? $event.target.value : ''})"
                />
              </template>
              <pmis-file-list
                id="scpayFlNo"
                flNoCol="scpayFlNo"
                title="첨부파일"
                title-visible
                :modal="true"
                :allow-add="false"
                :hidden="
                  !(
                    poStatus.isPoSend ||
                    poStatus.isPoSignComplete ||
                    poStatus.isAfterPoSignComplete ||
                    poStatus.isPoComplete
                  )
                "
                :toolbar-visible="true"
                :modal-btn-enable="true"
                :file-number="poInfo.scpayFlNo"
                :company-code="poInfo.cusPgmCd"
              />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header required>하자보수보증금</i-col-header>
            <i-col>
              <span class="ml5">계약금액의</span>
              <iui-text
                type="rate"
                width="60px"
                :value="poInfo.flawInsrRate"
                @change="onChange_rate('flawInsrRate', $event.target.value)"
                :enable="poStatus.isEnable"
                :required="poStatus.isEnable"
                :errorMessage="{title: '하자보수 보증율', message: '필수입력입니다.'}"
              />
              <span>(</span>
              <iui-text type="amount" suffix="" width="100px" :value="poInfo.flawInsrAmt" :enable="false" />
              <span>원)</span>
            </i-col>
          </i-row>
          <i-row>
            <i-col-header required>하자담보책임기간</i-col-header>
            <i-col>
              <iui-radio-group
                :items="flawInsrItems1"
                :enable="poStatus.isEnable"
                :value="poInfo.flawInsrYn"
                @change="setPoInfo({flawInsrYn: $event.target.value, flawInsrFrDt: '', flawInsrToDt: ''})"
              />
              <iui-text
                v-if="poStatus.isFlawInsrYear"
                type="number"
                align="center"
                width="30px"
                :value="poInfo.flawInsrYear"
                @change="setPoInfo({flawInsrYear: $event.target.value})"
                :enable="poStatus.isEnable"
                :required="poStatus.isEnable"
                max-length="3"
                :errorMessage="{title: '하자담보책임기간(년)', message: '필수입력입니다.'}"
              />
              <iui-radio-group
                :items="flawInsrItems2"
                :enable="poStatus.isEnable"
                :value="poInfo.flawInsrYn"
                @change="setPoInfo({flawInsrYn: $event.target.value, flawInsrYear: ''})"
              />
              <template v-if="poStatus.isFlawInsrMonth">
                <label>담보기간</label>
                <iui-datepicker
                  :value="poInfo.flawInsrFrDt"
                  @change="setPoInfo({flawInsrFrDt: $event})"
                  :enable="poStatus.isEnable"
                  :required="poStatus.isEnable"
                  :errorMessage="{title: '하자담보책임기간(월)', message: '필수입력입니다.'}"
                />
                <span>~</span>
                <iui-datepicker
                  :value="poInfo.flawInsrToDt"
                  @change="setPoInfo({flawInsrToDt: $event})"
                  :enable="poStatus.isEnable"
                  :required="poStatus.isEnable"
                  :errorMessage="{title: '하자담보책임기간(월)', message: '필수입력입니다.'}"
                />
              </template>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
      <i-spacer />
    </i-row>
    <i-row>
      <i-spacer />
      <i-col-header>특약사항</i-col-header>
      <i-col rowspan="3">
        <iui-text
          type="multi"
          :enable="poStatus.isEnable"
          :value="poInfo.poRmrk"
          @change="setPoInfo({poRmrk: $event.target.value})"
          max-length="2500"
        />
      </i-col>
    </i-row>
    <i-row>
      <i-spacer />
      <i-col />
    </i-row>
    <i-row>
      <i-spacer />
      <i-col />
    </i-row>

    <iui-modal name="scpayInsrCaculatorModal" :title="'하도급대금 지급보증금액 계산기'" size-type="size2">
      <ScpayInsrCaculator />
    </iui-modal>
  </iui-container-new>
</template>

<script>
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import PoInputMixin from '@/view/bidding/js/poInputMixin.js';
import ScpayInsrCaculator from './ScpayInsrCaculator.vue';

export default {
  components: {ScpayInsrCaculator},
  mixins: [PoInputMixin],
  data() {
    return {
      labItems: [{label: $getConstants('LAB_ITEM1').name, value: $getConstants('LAB_ITEM1').code}],

      scpayInsrItems1: [{label: '해당', value: BIDDING_CONSTANTS.CODES.SCPAY_INSR_Y}],
      scpayInsrItems2: [{label: '해당없음', value: BIDDING_CONSTANTS.CODES.SCPAY_INSR_N}],
      scpayInsrIncItems: [
        {label: $getConstants('SCPAY_INSR_INC_ITEM1').name, value: $getConstants('SCPAY_INSR_INC_ITEM1').code},
      ],

      flawInsrItems1: [{label: '년', value: BIDDING_CONSTANTS.CODES.FLAW_INSR_YEAR}],
      flawInsrItems2: [{label: '월', value: BIDDING_CONSTANTS.CODES.FLAW_INSR_MONTH}],
    };
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'poStatus']);
    $mapMutations(this, ['setPoInfo']);
  },
  methods: {
    onChange_scpayInsrYn(e) {
      this.setPoInfo({
        scpayInsrYn: e.target.checked ? BIDDING_CONSTANTS.CODES.SCPAY_INSR_Y : BIDDING_CONSTANTS.CODES.SCPAY_INSR_N,
      });
    },
    openScpayInsrCaculatorModal() {
      this.$modal.show('scpayInsrCaculatorModal');
    },
    onChange_rate(type, value) {
      const payload = {[type]: value};
      this.setPoInfo(payload);

      this.callEvent({name: 'PoItemList_CalculateAmt'});
    },
  },
};
</script>

<style scoped>
.fontWeightBold {
  font-weight: bold;
}

.font8pt {
  font-size: 8pt;
}
</style>
