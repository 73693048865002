<template>
  <pmis-content-box>
    <template #title>{{ contentBoxTitle }}</template>
    <iui-container-new type="table" theme="bullet" header-width="80px">
      <i-row>
        <i-col-header>현장</i-col-header>
        <i-col>
          <iui-text :value="spotDeptcd" :enable="false" width="120px" />
          <iui-text :value="spotDeptnm" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>입찰제목</i-col-header>
        <i-col>
          <iui-text :value="rfqNo2" :enable="false" width="120px" />
          <iui-text :value="ttl" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>예정금액</i-col-header>
        <i-col>
          <iui-text type="amount" width="145px" :value="planTotAmt" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>입찰예정가</i-col-header>
        <i-col>
          <iui-text
            ref="bidPlanAmt"
            width="145px"
            type="amount"
            :value.sync="bidPlanAmt"
            @change="onVerify_bidPlanAmt"
            required
            :errorMessage="{title: '입찰예정가', message: '필수입력입니다.'}"
          />
          <b style="color: red;">(* 부가세포함)</b>
        </i-col>
      </i-row>
      <i-row>
        <i-col colspan="2">
          <h4>※ 입찰예정가는 <b style="color: red;">암호화</b>되어 저장됩니다.</h4>
        </i-col>
      </i-row>
      <i-row>
        <i-col colspan="2">
          <h4>※ <b style="color: red;">개봉 전</b>까지 수정하실 수 있습니다.</h4>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import {selectRfqInfo, saveBidPlanAmt} from '@/view/bidding/rfq/mat/api/rfq.js';
import {selectSubRfqInfo, saveBidPlanAmt as saveSubBidPlanAmt} from '@/view/bidding/rfq/sub/api/subRfq.js';
import {getServerKmCertPerm} from '@/view/bidding/rfq/common/api/pkiToolkit.js';

const selectRfqInfoApi = {mat: selectRfqInfo, sub: selectSubRfqInfo};
const saveBidPlanAmtApi = {mat: saveBidPlanAmt, sub: saveSubBidPlanAmt};

export default {
  props: {
    bidType: {
      type: String,
      required: true,
    },
    rfqNo: {
      type: String,
      default: undefined,
    },
    contentBoxTitle: {
      type: String,
      default: '입찰예정가 변경',
    },
  },
  created() {
    this.addEvent({name: 'BidPlanAmt_Save', func: this.onSave});
  },
  data() {
    return {
      spotDeptcd: '', // 현장코드
      spotDeptnm: '', // 현장명
      rfqNo2: '', // 입찰계획서번호(조회용)
      ttl: '', // 입찰제목
      planTotAmt: '', // 예정금액
      bidPlanAmt: '', // 입찰예정가
      lastBidRnd: '', // 입찰공고차수

      certNo: '', // 서버 인증서 번호
      svrKmCertPerm: '', // 서버 인증서 문자열
    };
  },
  async mounted() {
    await this.$secuKit.checkReady();
    this.$refs.bidPlanAmt.$refs.input.focus();
    this.onSearch();
  },
  methods: {
    async onSearch() {
      // 입찰정보
      selectRfqInfoApi[this.bidType](this.rfqNo).then(response => {
        const rfq = response.data;
        this.spotDeptcd = rfq.spotDeptcd;
        this.spotDeptnm = rfq.spotDeptnm;
        this.rfqNo2 = COMMON_FUNCTION.getMakeFormat(rfq.rfqNo);
        this.ttl = rfq.ttl;
        this.lastBidRnd = rfq.lastBidRnd;
        this.planTotAmt = rfq.planTotAmt;
      });

      // pki 서버인증서 정보
      getServerKmCertPerm().then(response => {
        this.certNo = response.data.certNo;
        this.svrKmCertPerm = response.data.svrKmCertPerm;
      });
    },
    onVerify_bidPlanAmt(e) {
      const value = e?.target.value || this.bidPlanAmt;
      if (0 >= value || value === 'NaN' || Number.isNaN(value)) {
        this.bidPlanAmt = 0;
      }
      if (!this.bidPlanAmt) {
        this.$alert({title: '입찰예정가', message: '입찰예정가는 유효한 금액으로 입력해주세요.'});
        this.$refs.bidPlanAmt.$refs.input.focus();
        return false;
      }
      return true;
    },
    async onSave(callback) {
      if (!this.onVerify_bidPlanAmt()) {
        return;
      }

      let title, message, key, targetComponent;

      const valid = await this.$validator.validate(this);
      valid.validators.some(item => {
        if (!item.isValid) {
          title = item.errorMessage.title;
          message = item.errorMessage.defaultMessage;
          key = Object.keys(item.targetComponent.$refs)[0];
          targetComponent = item.targetComponent;
          return true;
        }
      });
      if (title || message) {
        this.$alert({title, message}, () => {
          setTimeout(() => {
            if (key) targetComponent.$refs[key].focus();
          }, 100);
        });
        return false;
      }

      try {
        // 1-1. 인증서 선택창
        await this.$secuKit.showDialog();

        // 1-2. 인증서 정보 추출
        const res = await this.$secuKit.viewCertInfomationWithVID({
          certType: 'SignCert',
          certID: this.$secuKit.certListInfo.getCertID(),
          isViewVID: '1',
        });
        this.userDN = res.userDN;

        // 1-3. 신원확인 검증
        let userDN = String(res.userDN);
        let certdn = String(this.userInfo.certdn);

        userDN = userDN.replace(/\s/gi, '').toUpperCase();
        certdn = certdn.replace(/\s/gi, '').toUpperCase();

        if (userDN != certdn) {
          this.$alert({
            title: '인증서 검증',
            message: '시스템에 등록된 인증서와 선택하신 인증서가 다릅니다.<br/> 다시 한 번 확인하시기 바랍니다.',
          });
          return;
        }

        // 1-4. 인증서 유효기간
        const date1 = new Date(res.detailValidateTo).getTime();
        const date2 = new Date().getTime();
        if (date1 < date2) {
          this.$alert({
            title: '인증서 검증',
            message: '인증서의 유효기간이 만료되었습니다.<br/> 인증서를 확인하시기 바랍니다.',
          });
          return;
        }

        // 2. 입찰예정가 암호화
        const data = {
          targetCert: removePEMHeader(removeCRLF(this.svrKmCertPerm)), // Envelop 를 위한 서버 인증서 문자열
          sourceString: String(this.bidPlanAmt), // Envelop 할 데이터 원문
        };
        const r1 = await this.$secuKit.generatePKCS7EnvelopedData(data);
        const encBidPlanAmt = r1.generatePKCS7EnvelopedData;

        saveBidPlanAmtApi[this.bidType](this.rfqNo, this.lastBidRnd, encBidPlanAmt, this.certNo)
          .then(response => {
            if (response.status == 200) {
              callback();
            }
          })
          .catch(ex => {
            if (ex.pmisError) {
              const msg = ex.description ? `${ex.message}<br/>- ${ex.description}` : ex.message;
              this.$alert({title: ex.title, message: msg});
            }
          });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style></style>
