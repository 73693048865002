<template>
  <pmis-tab-box>
    <template #title>공고정보</template>
    <template #title-right>
      <iui-button
        value="현설참가신청"
        v-if="rfqStatus.isSpotDscr && !rfqCusStatus.isApplySpotDscr"
        @click="updateApplySpotDscr(true)"
      />
      <iui-button
        value="현설참가신청취소"
        v-if="rfqStatus.isSpotDscr && rfqCusStatus.isApplySpotDscr"
        @click="updateApplySpotDscr(false)"
      />
      <iui-button
        value="견적서작성"
        v-if="rfqStatus.isRfqBidding && rfqCusStatus.canQuotSubmit"
        @click="openQuotSubmitPopup"
      />
      <iui-button value="입찰정보" @click="openBiddingInfoReport" />
    </template>

    <iui-container-new type="css-flex" ref="rfqInfoContainer">
      <i-row :height="rfqStatus.isExistSpotDscr ? '410px' : '360px'">
        <i-col>
          <pmis-content-box>
            <template #title>기본정보</template>
            <iui-container-new type="table" theme="data">
              <colgroup>
                <col width="100%" />
                <col width="100%" />
                <col width="90px" />
                <col width="90px" />
                <col width="100%" />
                <col width="90px" />
                <col width="100%" />
                <col width="90px" />
              </colgroup>
              <i-row>
                <i-col-header rowspan="2">공고일시</i-col-header>
                <i-col-header colspan="3">현설</i-col-header>
                <i-col-header rowspan="2">마감일시</i-col-header>
                <i-col-header rowspan="2">응찰여부</i-col-header>
                <i-col-header colspan="2">개봉</i-col-header>
              </i-row>
              <i-row>
                <i-col-header>현설일시</i-col-header>
                <i-col-header>참가신청여부</i-col-header>
                <i-col-header>참여여부</i-col-header>
                <i-col-header>개봉일시</i-col-header>
                <i-col-header>낙찰여부</i-col-header>
              </i-row>
              <i-row>
                <!-- 공고일시 -->
                <i-col align="center">{{ rfqInfo.bidStartDt }}</i-col>
                <!-- 현설일시 -->
                <i-col align="center">{{ rfqInfo.spotDscrDt || '-' }}</i-col>
                <!-- 참가신청여부 -->
                <i-col align="center">
                  {{ rfqStatus.isExistSpotDscr ? (rfqCusStatus.isApplySpotDscr ? '신청' : '미신청') : '-' }}
                </i-col>
                <!-- 참여여부 -->
                <i-col align="center">
                  {{ rfqStatus.isExistSpotDscr ? (rfqCusStatus.isChkSpotDscr ? '참여' : '미참여') : '-' }}
                </i-col>
                <!-- 마감일시 -->
                <i-col align="center">{{ rfqInfo.bidExpireDt }}</i-col>
                <!-- 응찰여부 -->
                <i-col align="center">
                  {{ rfqStatus.isBeforeBidding ? '-' : rfqCusStatus.isQuotSubmit ? '응찰' : '미응찰' }}
                </i-col>
                <!-- 개봉일시 -->
                <i-col align="center">{{ rfqInfo.bidOpenDt || '-' }}</i-col>
                <!-- 낙찰여부 -->
                <i-col align="center">
                  {{ rfqStatus.isAfterRfqComplete ? (rfqCusStatus.isSuccess ? '낙찰' : '탈락') : '-' }}
                </i-col>
              </i-row>
            </iui-container-new>
            <hr style="border:0;" />
            <iui-container-new type="table" theme="bullet">
              <colgroup>
                <col width="90px" />
                <col />
                <col width="10px" />
                <col width="90px" />
                <col width="200px" />
              </colgroup>
              <i-row>
                <i-col-header>공고회사</i-col-header>
                <i-col colspan="4">
                  <iui-text :value="rfqInfo.orgCd" :enable="false" width="100px" />
                  <iui-text :value="rfqInfo.orgNm" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>공고번호</i-col-header>
                <i-col>
                  <iui-text :value="rfqInfo.rfqNo2" :enable="false" width="100px" />
                  <iui-text :value="rfqInfo.ttl" :enable="false" />
                </i-col>
                <i-spacer />
                <i-col-header>입찰차수</i-col-header>
                <i-col>
                  <iui-text
                    :value="rfqInfo.lastBidRnd.length ? `${rfqInfo.lastBidRnd}차` : ''"
                    :enable="false"
                    width="100px"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>현장명</i-col-header>
                <i-col>
                  <iui-text :value="rfqInfo.spotDeptnm" :enable="false" />
                </i-col>
              </i-row>
              <i-row v-if="rfqStatus.isExistSpotDscr">
                <i-col colspan="5">
                  <iui-container-new type="table" theme="bullet">
                    <colgroup>
                      <col width="90px" />
                      <col width="75px" />
                      <col width="200px" />
                      <col width="10px" />
                      <col width="75px" />
                      <col />
                    </colgroup>
                    <i-row>
                      <i-col-header>현장설명</i-col-header>
                      <i-col-header>현설일자</i-col-header>
                      <i-col>
                        <iui-text :value="rfqInfo.spotDscrYmd" :enable="false" width="75px" />
                        <iui-text :value="rfqInfo.spotDscrHh" :enable="false" width="30px" align="center" />
                        <label>:</label>
                        <iui-text :value="rfqInfo.spotDscrMm" :enable="false" width="30px" align="center" />
                      </i-col>
                      <i-spacer />
                      <i-col-header>현설장소</i-col-header>
                      <i-col>
                        <iui-text :value="rfqInfo.spotDscrLoc" :enable="false" />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col />
                      <i-col-header>현설담당</i-col-header>
                      <i-col colspan="4">
                        <iui-text :value="rfqInfo.spotDscrUsrnm" :enable="false" width="100px" />
                        <iui-text type="phone" :enable="false" :value="rfqInfo.spotDscrUsrTlno" width="100px" />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>지급조건</i-col-header>
                <i-col>
                  <label>현금</label>
                  <iui-text type="rate" width="50px" suffix="" :value="rfqInfo.payCashRatio" :enable="false" />
                  <label>% 어음</label>
                  <iui-text type="rate" width="50px" suffix="" :value="rfqInfo.payCheckRatio" :enable="false" />
                  <label>%</label>
                  <iui-text type="number" width="50px" :value="rfqInfo.payTerm" :enable="false" />
                  <label>일 이내</label>
                </i-col>
                <i-spacer />
                <i-col-header>과세유형</i-col-header>
                <i-col>
                  <iui-text :value="rfqInfo.taxTypenm" :enable="false" width="100px" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>공사기간</i-col-header>
                <i-col>
                  <iui-text :value="rfqInfo.constrFrDt" :enable="false" width="100px" />
                  <label>~</label>
                  <iui-text :value="rfqInfo.constrToDt" :enable="false" width="100px" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>공사명</i-col-header>
                <i-col>
                  <iui-text :value="rfqInfo.itemNm" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>입찰담당</i-col-header>
                <i-col>
                  <iui-text :value="rfqInfo.chrgUsrnm" :enable="false" width="100px" />
                  <iui-text type="phone" :value="rfqInfo.chrgUsrTlno" :enable="false" width="100px" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>입찰기타사항</i-col-header>
                <i-col colspan="4">
                  <iui-text :value="rfqInfo.rfqRmrk" :enable="false" />
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-spacer />
      <i-row min-height="200px">
        <i-col>
          <pmis-content-box>
            <template #title>{{ rfqStatus.isAfterChoice ? '견적내역' : '입찰내역' }}</template>
            <template #title-left>
              <template v-if="rfqStatus.isAfterChoice">
                <label>(</label>
                <iui-text
                  type="amount"
                  prefix="공급가액"
                  :value="quotAmt"
                  width="200px"
                  height="20px"
                  :enable="false"
                />
                <iui-text
                  type="amount"
                  prefix="부가세액"
                  :value="quotVatAmt"
                  width="200px"
                  height="20px"
                  :enable="false"
                />
                <iui-text
                  type="amount"
                  prefix="합계금액"
                  :value="quotTotAmt"
                  width="200px"
                  height="20px"
                  :enable="false"
                />
                <label>)</label>
              </template>
            </template>
            <ib-sheet
              :uid="_uid"
              :options="sheetOpt"
              :loadSearchData="rfqStatus.isAfterChoice ? quotItemList : rfqItemList"
              @loadSheet="sheet = $event"
              :events="{onSearchFinish: sheet_onSearchFinish}"
            />
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-spacer />
      <i-row min-height="170px">
        <i-col>
          <pmis-file-list
            id="rfqFile"
            title="첨부파일"
            :toolbar-visible="false"
            :company-code="rfqInfo.orgCd"
            :file-number="rfqInfo.flNo"
          />
        </i-col>

        <i-spacer v-show="rfqStatus.isAfterChoice" />
        <i-col v-show="rfqStatus.isAfterChoice">
          <pmis-file-list
            id="quotFile"
            title="견적서파일"
            :toolbar-visible="false"
            :company-code="pgmCd"
            :file-number="quotInfo.estiFlNo"
          />
        </i-col>
      </i-row>
      <i-spacer v-show="rfqStatus.isAfterChoice" />
      <i-row v-show="rfqStatus.isAfterChoice" min-height="250px">
        <i-col>
          <RfqCusResultList />
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal name="quotSubmitModal" title="견적서" :btns="quotSubmitModalBtns" width="1500px" height="800px">
      <QuotSubmit ref="quotSubmit" />
    </iui-modal>

    <iui-modal name="rfqPrintModal" title="입찰정보" size-type="size6">
      <ReportViewer :propsPgmCd="rfqInfo.orgCd" :fomNo="fomNo" :ozContent="ozContent" />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/rfqInfoCustomer.js';
import rfqCustomerMixin from '@/view/bidding/rfqCustomer/common/rfqCustomerMixin.js';

import QuotSubmit from '@/view/bidding/rfqCustomer/sub/components/QuotSubmit.vue';
export default {
  components: {QuotSubmit},
  mixins: [rfqCustomerMixin],
  data() {
    return {
      bidType: 'sub',
      sheetOpt: sheetOpt(this),
    };
  },
};
</script>

<style></style>
