export default that => {
  return {
    Cfg: {
      CanEdit: 0,
      DataMerge: 1,
    },
    Cols: [
      {
        Header: ['NO'],
        Name: 'rownum',
        Type: 'Text',
        Align: 'center',
        Width: '50',
      },
      {
        Header: ['투입일자'],
        Name: 'ioDate',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['실행중공종코드'],
        Name: 'midCode',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['실행내역코드'],
        Name: 'itemSeq',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['자원코드'],
        Name: 'itemCode',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['단위작업분류'],
        Name: 'wbsCd',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['단위작업ID'],
        Name: 'wbsId',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['자재코드'],
        Name: 'matCode',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['수량'],
        Name: 'qty',
        Type: 'Text',
        Align: 'Right',
        Width: 85,
        ColMerge: 0,
      },
      {
        Header: ['단가'],
        Name: 'price',
        Type: 'Text',
        Align: 'Right',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['외주 계약번호'],
        Name: 'poNoSubc',
        Type: 'Text',
        Align: 'Right',
        RelWidth: 1,
        ColMerge: 0,
      },
    ],
  };
};
