<template>
  <pmis-tab-box>
    <template v-slot:title>페이지목록</template>
    <iui-container-new type="ibsheet" :id="elId" />
  </pmis-tab-box>
</template>

<script>
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
    };
  },
  beforeCreate() {
    $mapMutations(this, ['setPageData', 'setEditState']);
  },
  created() {
    this.addEvent([{name: 'pageList_selectPageList', func: this.selectPageList}]);
  },
  mounted() {
    this.initSheet();
  },
  methods: {
    async initSheet() {
      const OPT = {
        Cfg: {
          MainCol: 'webName', // 트리가 표시될 열이름
          SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
        },
        Cols: [
          {
            Header: '페이지이름',
            Name: 'webName',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: 0,
          },
          {
            Header: '페이지ID',
            Name: 'pageId',
            Type: 'Text',
            Width: 80,
            CanEdit: 0,
          },
          {
            Name: 'seq',
            Visible: false,
          },
          {
            Name: 'lvl',
            Visible: false,
          },
          {
            Name: 'size',
            Visible: false,
          },
          {
            Name: 'webLocation',
            Visible: false,
          },
          {
            Name: 'pageName',
            Visible: false,
          },
        ],
        Events: {
          onRenderFirstFinish: this.selectPageList,
        },
      };

      this.sheet = await PMISSheet.createSheet({el: this.elId, options: OPT});

      this.sheet.bind('onFocus', e => {
        this.setPageData(e.row);
        this.setEditState();
      });
    },
    async selectPageList() {
      const response = await axios.post('/page/selectPageList', {});
      this.sheet.loadSearchData(response.data, 0);
    },
  },
};
</script>

<style></style>
