<template>
  <pmis-content-box :loading="false">
    <template v-slot:header-left>
      <label>제목</label>
      <iui-text type="search" :value.sync="searchText" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
      <iui-radio-group class="pl25" :items="answerDsItems" @click="onSearch" :value.sync="answerDs" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onClick: this.grid_onClick}"
    />
    <pmis-file-list
      ref="file"
      :modal="true"
      :hidden="true"
      :companyCode="paramObj.pgmCd"
      :fileNumber="paramObj.flNo"
      :allowAdd="false"
      :id="'fileComp' + _uid"
      :saveAuth="false"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/qnaAnswerBoardList.js';
export default {
  props: {
    boardType: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      Options: options(this),
      loadSearchData: [],

      searchText: '',
      answerDsItems: [
        {label: '전체', value: 'all'},
        {label: '미답변', value: 'noAnswered'},
        {label: '답변완료', value: 'answered'},
      ],
      subVue: [
        {
          name: `qnaBoardView${this._uid}`,
          url: `/qnaBoardView${this._uid}`,
          path: './view/board/qna/components/QnaBoardView.vue',
        },
      ],
      paramObj: {
        // 첨부파일
        pgmCd: '',
        flNo: 0,
        rgprId: '',
      },
      answerDs: 'noAnswered',
      natDs: $getConstants('NAT_DS_QNA').code,
      boardStatus: 'A',
      externalParam: {},
    };
  },
  watch: {
    answerDs(newVal, oldVal) {
      if (newVal != oldVal) {
        this.onSearch();
      }
    },
  },
  created() {
    this.addEvent({name: 'QnA_Answer_BoardList_Search', func: this.onSearch});
  },
  activated() {
    if (this.sheet) {
      this.setExternalData();
    }
  },
  methods: {
    loadSheet(e) {
      this.sheet = e;
      this.setExternalData();
    },
    async onSearch() {
      this.loading = true;
      let response = await axios.post('/qna/selectQnaAnswerList', {
        natDs: this.natDs,
        answerDs: this.answerDs,
        searchText: this.searchText.trim(),
      });
      this.loadSearchData = response.data;
      this.loading = false;
    },
    grid_onClick(e) {
      if (e.row.Kind == 'Data') {
        if (e.col == 'files' && e.cellType == 'Button') {
          this.paramObj.pgmCd = e.row.pgmCd;
          this.paramObj.flNo = e.row.flNo;
          this.paramObj.rgprId = e.row.rgprId;

          this.$refs.file.openModal();
        } else if (e.col == 'tl') {
          this.onGoVue({
            pgmCd: e.row.pgmCd,
            empNo: e.row.rgprId,
            natNo: e.row.natNo,
          });
        }
      }
    },
    onGoVue(param) {
      let routeName = this.$route.name;
      this.goVue(`/qnaBoardView${this._uid}`, {
        ...param,
        routeName,
        natDs: this.natDs,
        boardStatus: this.boardStatus,
      });
    },
    setExternalData() {
      if (this.$store.getters['externalData'].natNo) {
        this.externalParam = this.$store.getters['externalData'];
        this.$store.dispatch('setExternalData', {});
        this.onGoVue(this.externalParam);
      } else {
        this.onSearch();
      }
    },
  },
};
</script>

<style></style>
