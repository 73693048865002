const state = {
  portletData: {
    portletGroup: '1',
    portletId: '2',
    portletName: '3',
    mnuCd: '4',
  },
  dragCheck: false,
  pageConfig: {
    [$getConstants('PORTLET_SIZE_A').code]: {
      rowspan: '1',
      className: $getConstants('PORTLET_SIZE_A').name,
    },
    [$getConstants('PORTLET_SIZE_B').code]: {
      rowspan: '1',
      className: $getConstants('PORTLET_SIZE_B').name,
    },
    [$getConstants('PORTLET_SIZE_C').code]: {
      rowspan: '2',
      className: $getConstants('PORTLET_SIZE_C').name,
    },
    [$getConstants('PORTLET_SIZE_D').code]: {
      rowspan: '4',
      className: $getConstants('PORTLET_SIZE_D').name,
    },
  },
  pagePortletParam: [],
};

const getters = {
  portletData: state => state.portletData,
  dragCheck: state => state.dragCheck,
  pageConfig: state => state.pageConfig,
  pagePortletParam: state => state.pagePortletParam,
};

const actions = {};

const mutations = {
  setPortletData(state, payload) {
    for (let key in state.portletData) {
      if (payload && payload.hasOwnProperty(key)) {
        state.portletData[key] = payload[key];
      }
    }
  },
  setDragTrue(state) {
    state.dragCheck = true;
  },
  setDragFalse(state) {
    state.dragCheck = false;
  },
  setPagePortletParam(state, payload) {
    state.pagePortletParam.push({
      pageId: payload.pageId,
      portletGroup: payload.portletGroup,
      seq: payload.seq,
      portletId: payload.portletId,
      mnuCd: payload.mnuCd,
      // mnuPth: payload.mnuPth != null ? `@${payload.mnuPth}/index` : null,
    });
  },
  changePagePortletParam(state, payload) {
    const row = state.pagePortletParam.find(element => {
      return element.seq == payload.seq;
    });
    row.portletId = payload.portletId;
    row.mnuCd = payload.mnuCd;
  },
  clearPagePortletParam(state) {
    state.pagePortletParam = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
