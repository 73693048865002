export default that => {
  return {
    Cfg: {
      CanEdit: false,
    },
    Cols: [
      {
        Header: '집행일자',
        Name: 'workDt',
        Align: 'center',
        Width: 100,
        CustomFormat: v => `${v.substr(0, 4)}-${v.substr(4, 2)}-${v.substr(6)}`,
      },
      {
        Header: '순번',
        Name: 'seq',
        Type: 'Text',
        Align: 'center',
        Width: 50,
      },
      {
        Header: '거래처',
        Name: 'custName',
        Type: 'Text',
        MinWidth: 100,
        RelWidth: 1,
      },
      {
        Header: '공급가',
        Name: 'amt',
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 120,
      },
      {
        Header: '부가세',
        Name: 'vatAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 120,
      },
      {
        Header: '비과세',
        Name: 'taxexe',
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 120,
      },
      {
        Header: '합계',
        Name: 'sumAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 140,
      },
      {
        Header: '공종명',
        Name: 'mgName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
      },

      {
        Header: '내역코드',
        Name: 'itemSeq',
        Type: 'Text',
        Align: 'center',
        Width: 70,
      },
      {
        Header: '내역명',
        Name: 'itemName',
        Type: 'Text',
        MinWidth: 120,
        RelWidth: 1,
      },
      {
        Header: '외주',
        Name: 'subCodeName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 100,
      },
      {Name: 'closeYn', Visible: false},
      {Name: 'poNoSubc', Visible: false},
      {Name: 'subCode', Visible: false},
    ],
  };
};
