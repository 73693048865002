import pick from 'lodash/pick';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import FOM_NO from '@/constants/fomNoConstants.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import {applySpotDscr} from '@/view/bidding/rfqCustomer/mat/api/rfqCustomer.js';
import {applySpotDscr as applySpotDscrSub} from '@/view/bidding/rfqCustomer/sub/api/subRfqCustomer.js';

import RfqCusResultList from '@/view/bidding/rfqCustomer/common/RfqCusResultList.vue';
import ReportViewer from '@/view/bidding/components/ReportViewer.vue';

export default {
  components: {RfqCusResultList, ReportViewer},
  data() {
    return {
      quotAmt: 0,
      quotVatAmt: 0,
      quotTotAmt: 0,

      ozContent: {},
      apis: {},

      quotSubmitModalBtns: [{name: '견적서 제출', callback: this.quotSubmit}],
    };
  },
  computed: {
    fomNo() {
      const fomNo = {
        mat: FOM_NO.RFQ_INFO_PRINT2,
        sub: FOM_NO.SUB_RFQ_INFO_PRINT2,
      };
      return fomNo[this.bidType];
    },
  },
  beforeCreate() {
    $mapGetters(this, [
      'rfqInfo',
      'rfqCusInfo',
      'quotInfo',
      'rfqStatus',
      'rfqCusStatus',
      'rfqItemList',
      'quotItemList',
      'rfqCusResultList',
    ]);
    $mapMutations(this, ['initRfqInfo', 'initRfqCusInfo']);
    $mapActions(this, ['searchRfqInfosCustomer']);
  },
  created() {
    this.addEvent([{name: 'RfqInfoCustomer_onSearch', func: this.onSearch}]);
    this.getApis();
  },
  methods: {
    async getApis() {
      const apiList = ['selectCheckStateBeforeQuotSubmit'];
      if (this.bidType === 'mat') {
        this.apis = pick(await import('@/view/bidding/rfqCustomer/mat/api/rfqCustomer.js'), apiList);
      }
      if (this.bidType === 'sub') {
        this.apis = pick(await import('@/view/bidding/rfqCustomer/sub/api/subRfqCustomer.js'), apiList);
      }
    },
    onSearch(param) {
      if (!param) {
        this.initRfqInfo();
        this.initRfqCusInfo();
        this.sheet.removeAll();
        return;
      }

      this.searchRfqInfosCustomer({...param, cusPgmCd: this.pgmCd});
      this.$refs.rfqInfoContainer.$el.closest('.grid_inner_scroll').scrollTo(0, 0);
    },
    sheet_onSearchFinish(e) {
      const bigoCol = {mat: 'itemRmrk', sub: 'srvRmrk'}[this.bidType];
      e.sheet.hideCol(bigoCol);
      e.sheet.hideCol('unitPrc');
      e.sheet.hideCol('amt');

      if (this.rfqStatus.isAfterChoice) {
        e.sheet.showCol('unitPrc');
        e.sheet.showCol('amt');

        this.quotAmt = e.sheet.getDataRows().reduce((acc, curr) => acc + curr['amt'], 0);

        if (this.rfqStatus.isTax || (this.rfqStatus.isTaxDirect && this.rfqStatus.isTaxDirectPercent)) {
          this.quotVatAmt = (this.quotAmt * this.rfqInfo.taxRate) / 100;
        } else if (this.rfqStatus.isTaxDirect && this.rfqStatus.isTaxDirectAmt) {
          this.quotVatAmt = this.rfqInfo.vatAmt;
        }

        this.quotTotAmt = this.quotAmt + this.quotVatAmt;
      } else {
        e.sheet.showCol(bigoCol);
      }
    },
    async updateApplySpotDscr(isApply) {
      const cancelMsg = isApply ? '' : '취소';
      const title = `현설참가신청${cancelMsg}`;
      const message = `현장설명 참가신청을 ${cancelMsg}하시겠습니까?`;

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      const rfqNo = this.rfqInfo.rfqNo;
      const lastBidRnd = this.rfqInfo.lastBidRnd;
      const cusCd = this.rfqCusInfo.cusCd;

      const updateApplySpotDscrApi = {mat: applySpotDscr, sub: applySpotDscrSub}[this.bidType];
      await updateApplySpotDscrApi(rfqNo, lastBidRnd, cusCd, isApply).catch(ex => {
        if (ex.pmisError) {
          const title = ex.title;
          const message = ex.description ? `${ex.message}<br/>- ${ex.description}` : ex.message;
          this.$alert({title, message});
        }
      });
      this.callEvent({name: 'RfqListCustomer_Search', param: rfqNo});
    },

    async openBiddingInfoReport() {
      if (!this.rfqInfo.rfqNo) {
        this.$alert({title: '입찰정보', message: '입찰정보가 존재하지 않습니다.'});
        return;
      }

      const rfqInfo = this.rfqInfo;
      const rfqItemList = this.rfqItemList;
      const regex = /\B(?=(\d{3})+(?!\d))/g;
      const isPotypeUnit = rfqInfo.poTypecd && rfqInfo.poTypecd == BIDDING_CONSTANTS.CODES.POTYPE_UNIT;

      const RfqInfo = {
        ...pick(rfqInfo, [
          'rfqNo',
          'ttl',
          'spotDeptnm',
          'chrgUsrnm',
          'spotDscrDt',
          'spotDscrLoc',
          'spotDscrUsrnm',
          'rfqRmrk',
          'bidStartDt',
          'bidExpireDt',
          'bidOpenDt',
          'taxTypenm',
          'bidPlanAmt',
          'taxTypenm',
          'orgNm',
          'dlvCondRmrkNm',
          'poTypenm',
        ]),
        lastBidRnd: `${rfqInfo.lastBidRnd}차`,
        bidPlanAmt: rfqInfo.bidOpenDt ? String(rfqInfo.bidPlanAmt).replace(regex, ',') : '***,***,***',
        cusCount: `${rfqInfo.quotCusCount} / ${rfqInfo.totCusCount}`,
        payText: `현금 ${rfqInfo.payCashRatio}% 어음 ${rfqInfo.payCheckRatio}% ${rfqInfo.payTerm}일 이내`,

        // text1: isPotypeUnit ? '계약기간' : '납품요구일',
        // text2: isPotypeUnit ? '계약조건' : '납품장소',
        // value1: isPotypeUnit ? `${rfqInfo.contFrDt}~${rfqInfo.contToDt}` : rfqInfo.dlvCondRmrkNm,
        // value2: isPotypeUnit ? rfqInfo.contCondRmrk : rfqInfo.dlvLoc,
      };

      const ItemList = JSON.stringify({
        ItemList: rfqItemList.map(item => {
          const qty = String(item.qty).replace(regex, ',');
          const planUnitPrc = String(item.planUnitPrc).replace(regex, ',');
          const amt = String(item.amt).replace(regex, ',');

          if (this.bidType === 'mat') {
            return {
              ...pick(item, ['itemCd', 'itemNm', 'sizeNm', 'unitNm', 'itemRmrk']),
              qty,
              planUnitPrc,
              amt,
            };
          }
          if (this.bidType === 'sub') {
            return {
              ...pick(item, ['srvNm', 'itemNm', 'sizeNm', 'unitNm', 'srvRmrk']),
              qty,
            };
          }
        }),
      });

      this.ozContent = {...RfqInfo, ItemList};
      this.$modal.show('rfqPrintModal');
    },
    /** 견적서 작성팝업 */
    async openQuotSubmitPopup() {
      const checkMessage = (await this.apis['selectCheckStateBeforeQuotSubmit'](this.rfqInfo.rfqNo)).data;
      if (checkMessage) {
        this.$alert({title: '견적서 작성', message: `견적서를 작성할 수 없습니다.<br/>- ${checkMessage}`});
        this.callEvent({name: 'RfqListCustomer_Search'});
        return;
      }

      if (await COMMON_FUNCTION.checkCertdnRegistered(this, this.bidType)) {
        /** 인증서 등록여부 확인 */
        this.$modal.show('quotSubmitModal');
      }
    },
    /** 견적서제출 */
    quotSubmit() {
      this.$refs.quotSubmit['onSign'](rfqNo => {
        this.$modal.hide('quotSubmitModal');
        this.callEvent({name: 'RfqListCustomer_Search', param: rfqNo});
      });
    },
  },
};
