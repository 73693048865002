<template>
  <pmis-tab-box ref="prjList">
    <div class="prj_nav_wrap">
      <a href="#" class="btn_big_close" @click.prevent.self @click="openToggle" />
      <span class="blind">열고/닫기</span>
    </div>
    <template v-slot:title>현장목록</template>
    <pmis-content-box>
      <template v-slot:header-left>
        <iui-text type="search" :value.sync="searchNm" @enter="onSearch" />
        <iui-button value="검색" @click="onSearch" />
        <iui-checkbox-group
          name="cbxCloseSiteYN"
          :items="[{label: '종료포함', value: 'Y'}]"
          @change="
            event => {
              endCntrInc = event.target.checked ? 'Y' : 'N';
              onSearch();
            }
          "
        />
      </template>
      <ib-sheet
        :uid="_uid"
        :options="Options"
        :loadSearchData="loadSearchData"
        @loadSheet="loadSheet"
        :events="{
          onSearchFinish,
          onClick,
        }"
      />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
import options from './sheetOption/PrjListSheet.js';
import {selectPrjAllList} from '../api/prjWorkTypeRegist.js';
import {validSheet} from '@/util/validUtil';

export default {
  props: {
    excludePrjCd: {
      type: String,
    },
    callback: {
      type: Function,
      default: () => {},
    },
    popup: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      searchNm: '',
      bidStat: '',
      bidStatItems: [],
      focusKey: '',
      defaultColCssText: '',
      endCntrInc: 'N',
    };
  },

  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'detailInfo']);
    $mapMutations(this, ['setDetailInfo']);
  },

  created() {
    this.addEvent([{name: 'prjList_onSearch', func: this.onInitSeach}]);
    this.onSearch();
  },
  activated() {
    this.$nextTick(() => this.onActivatedFocus());
  },

  mounted() {
    if (this.$refs.prjList.$parent.$parent.$el.className == 'col') {
      this.defaultColCssText = this.$refs.prjList.$parent.$parent.$el.style.cssText;
    }
  },
  methods: {
    onInitSeach() {
      this.$store.dispatch('project/init');
      this.$store.commit('setPrjCd', '');

      this.onSearch();
    },
    async onSearch() {
      let param = {
        pgmCd: this.pgmCd,
        endCntrInc: this.endCntrInc,
        searchNm: this.searchNm,
      };

      const response = await selectPrjAllList(param);
      this.loadSearchData = response.data.filter(item => (this.excludePrjCd ? item.prjCd != this.excludePrjCd : true));
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onSearchFinish(e) {
      let focusRow;
      if (!this.excludePrjCd) {
        if (this.prjCd) {
          focusRow = e.sheet.getDataRows().find(row => String(row.prjCd) === String(this.prjCd));
        } else {
          focusRow = e.sheet.getFirstRow();
        }
      } else {
        focusRow = e.sheet.getFirstRow();
      }
      if (!this.popup) {
        this.sheet.focus(focusRow);
        this.setPrjData(focusRow);
        this.$store.dispatch('project/runFunc');
      }
      this.callback(focusRow);
    },
    onClick(e) {
      e.row.CanFocus = 0;
      if (e.row.Kind === 'Data') {
        if (!this.popup && validSheet()) {
          this.$confirm({
            title: '현장',
            message: '변경된 사항이 있습니다.<br/>다른 현장을 조회하시겠습니까?',
          }).then(isConfirm => {
            if (isConfirm) {
              e.row.CanFocus = 1;
              e.sheet.focus(e.row);
              this.setPrjData(e.row);
              this.callEvent({name: 'setCud', param: 0});
              this.$store.dispatch('project/runFunc');
            }
          });
        } else {
          e.row.CanFocus = 1;
          e.sheet.focus(e.row);
          if (!this.popup) {
            this.setPrjData(e.row);
            this.callEvent({name: 'setCud', param: 0});
            this.$store.dispatch('project/runFunc');
          } else {
            this.callback(e.row);
          }
        }
      }
    },
    openToggle(e) {
      if (e.target.classList == 'btn_big_close') {
        e.target.classList = 'btn_big_open';
        e.target.closest('.col').style['flex-grow'] = '0';
        e.target.closest('.col').style['flex-shrink'] = '0';
        e.target.closest('.col').style['flex-basis'] = '0px';
        e.target.closest('.col').style.transition = 'all 0.5s ease';

        if (e.target.closest('.col').nextElementSibling.className == 'spacer') {
          e.target.closest('.col').nextElementSibling.style = 'flex: 0 0 0px;';
        }
      } else {
        e.target.classList = 'btn_big_close';
        e.target.closest('.col').style = this.defaultColCssText + ' transition: all 0.5s ease';
        if (e.target.closest('.col').nextElementSibling.className == 'spacer') {
          e.target.closest('.col').nextElementSibling.style = '';
        }
      }
    },
    onActivatedFocus() {
      if (this.popup || validSheet()) {
        return;
      }
      const prjCd = this.prjCd;

      if (prjCd && this.sheet && prjCd != this.sheet?.getFocusedRow().prjCd) {
        const focusRow = this.sheet.getDataRows().find(row => row.prjCd == prjCd);
        this.sheet.focus(focusRow);
        this.setPrjData(focusRow);
        this.$store.dispatch('project/runFunc');
        this.callback(focusRow);
      }
    },
    setPrjData(row) {
      this.$store.commit('setPrjCd', row?.prjCd ? String(row.prjCd) : undefined);
      this.$store.dispatch('project/setLoadPrj', row);
    },
  },
};
</script>

<style scoped>
.prj_nav_wrap {
  position: absolute;
  top: 229px;
  right: -15px;
  z-index: 100;
  transition: all 0.5s ease;
}

.prj_nav_wrap .btn_big_close {
  display: block;
  width: 39px;
  height: 39px;
  background: url('../../../../assets/img/btn_nav_close.png') no-repeat;
}

.prj_nav_wrap .btn_big_open {
  display: block;
  width: 39px;
  height: 39px;
  background: url('../../../../assets/img/btn_nav_open.png') no-repeat;
}
</style>
