import cloneDeep from 'lodash/cloneDeep';
import qs from 'qs';
import store from '@/store/index.js';

const getComponent = componentPath => {
  let component;
  const components = require.context(
    // 컴포넌트들이 있는 폴더
    '@',
    // 하위 폴더까지 포함할 지 여부
    true,
    // 기본 컴포넌트를 찾는데 사용할 정규표현식
    /\.(vue)$/
  );
  components.keys().some(fileName => {
    if (fileName.indexOf('./components') === 0) return false;
    if (fileName == componentPath || fileName == componentPath + '.vue' || fileName == componentPath + '/index.vue') {
      const componentConfig = components(fileName);
      component = cloneDeep(componentConfig.default || componentConfig);
      return true;
    }
  });

  return component;
};

const getRefreshToken = async () => {
  const response = await axios.post('/auth/refreshToken', {
    accessToken: store.getters['jwt/accessToken'].replace('Bearer ', ''),
    refreshToken: store.getters['jwt/refreshToken'],
  });

  const accessToken = response.data.accessToken;
  const refreshToken = response.data.refreshToken;
  const grantType = response.data.grantType;

  store.commit('jwt/setAccessToken', `${grantType} ${accessToken}`);
  store.commit('jwt/setRefreshToken', refreshToken);

  return `${grantType} ${accessToken}`;
};

export {getComponent, getRefreshToken};
