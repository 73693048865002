<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <pmis-tab-box>
                  <template v-slot:title>장비목록</template>
                  <template v-slot:title-right>
                    <iui-button @click="goEquipReg" value="장비코드등록" />
                  </template>
                  <iui-container-new type="css-flex">
                    <i-row>
                      <i-col width="300px">
                        <EquipCl ref="equipCl" :search="false" :callback="onFocusCallback" />
                      </i-col>
                      <i-spacer />
                      <i-col>
                        <EqutCodeList />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </pmis-tab-box>
              </i-col>
            </i-row>
            <i-spacer />
            <i-row>
              <pmis-tab-box>
                <template v-slot:title>보유장비목록</template>
                <HoldEqupmnList />
              </pmis-tab-box>
            </i-row>
          </iui-container-new>
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>상세정보</template>
            <DetailInfo />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
/**
 * 보유장비등록
 * components :
 *   EquipCl 장비분류
 *   MasterEqupmnList 장비목록
 *   HoldEqupmnList 보유장비목록
 *   DetailInfo 상세정보
 *
 * */
import store from './store/holdEqpmnRegist';
import EquipCl from '@/view/sysManage/stdCodeManage/equipCodeRegist/components/EquipCl.vue';
import EqutCodeList from './components/MasterEqupmnList.vue';
import HoldEqupmnList from './components/HoldEqupmnList.vue';
import DetailInfo from './components/DetailInfo.vue';
import {selectEqutCompanyCodeCount, selectCountEqutPjtCode, saveEqutCompanyCode} from './api/HoldEqpmnRegist.js';
export default {
  components: {EquipCl, EqutCodeList, HoldEqupmnList, DetailInfo},
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['detailInfo']);
    $mapMutations(this, ['initDetailInfo', 'setDetailInfo', 'setFocusKey']);
  },
  created() {
    this.canSearch = true;
    this.canNew = true;
    this.canSave = true;
    this.canDelete = true;
    this.addFuncSearch(this.onSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },
  methods: {
    onSearch() {
      this.$refs.equipCl.onSearch();
    },
    onAdd() {
      //store detailInfo 초기화
      this.callEvent({
        name: 'MasterEqupmnList_getFocusedRow',
        param: baseRow => {
          if (!baseRow) {
            this.$alert({title: '장비 선택', message: '장비목록에서 장비를 선택해주세요.'});
            return;
          }
          this.initDetailInfo();
          this.setDetailInfo({...baseRow, cud: 1});
          this.callEvent({name: 'InputHist_Init'});
        },
      });
    },
    async onSaveValidator() {
      if (this.detailInfo.cud == 1) {
        let param = {
          pgmCd: this.detailInfo.pgmCd,
          equipCode: this.detailInfo.equipCode,
          carNo: this.detailInfo.carNo,
        };
        const response = await selectEqutCompanyCodeCount(param);

        if (response.status == 200) {
          if (response.data > 0) {
            this.$alert({title: '중복 확인', message: '이미 등록된 장비입니다.<br/>다시 한 번 확인하시기 바랍니다.'});
            return false;
          }
          return true;
        }
      } else {
        return true;
      }
    },
    async onSave() {
      if (0 == this.detailInfo.cud && !this.detailInfo.equipCode) {
        this.$alert({title: '보유장비 저장', message: '저장할 보유장비정보가 존재하지 않습니다.'});
        return;
      }

      const param = {pgmCd: this.pgmCd, ...this.detailInfo};
      if (await this.onSaveValidator()) {
        const response = await saveEqutCompanyCode(param);
        if (response.status == 200) {
          this.onSearch();
          this.setFocusKey(this.detailInfo.carNo);
        }
      }
    },
    onDelete() {
      this.callEvent({
        name: 'HoldEqupmnList_getCheckedRows',
        param: checkedRows => {
          if (0 == checkedRows.length) {
            this.$alert({title: '보유장비 삭제', message: '보유장비목록에서 삭제할 장비를 선택하십시요'});
            return;
          }
          const deleteList = checkedRows.map(row => ({pgmCd: row.pgmCd, equipCode: row.equipCode, carNo: row.carNo}));
          this.onDeleteProcess(deleteList);
        },
      });
    },
    async onDeleteValidator(deleteList) {
      const response = await selectCountEqutPjtCode({deleteList});
      if (response.status == 200) {
        if (response.data.STATUS == 'OPERATING_EXIST') {
          this.$alert({title: '삭제불가', message: '현재 가동중인 장비가 포함되어 있습니다.<br/>삭제할 수 없습니다.'});
          return false;
        }
        return true;
      }
      return false;
    },
    async onDeleteProcess(deleteList) {
      const param = {deleteList, cud: this.cud};
      const title = '보유장비 삭제';
      const message = '선택된 장비를 삭제하시겠습니까?';

      if (await this.onDeleteValidator(deleteList)) {
        if (await this.$confirm({title, message})) {
          const response = await saveEqutCompanyCode(param);
          if (response.status == 200) {
            this.onSearch();
          }
        }
      }
    },
    onFocusCallback(row) {
      this.callEvent({name: 'MasterEqupmnList_onSearch', param: row.code});
    },
    goEquipReg() {
      this.goMenu('EQUIP_CODE_REGIST');
    },
  },
};
</script>

<style scoped></style>
