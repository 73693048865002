export default that => {
  return {
    Cfg: {
      CanEdit: 0,
    },
    Cols: [
      {Header: '거래처코드', Name: 'custCode', Type: 'Text', RelWidth: 1, MinWidth: 200},
      {Header: '거래처명', Name: 'custName', Type: 'Text', RelWidth: 1, MinWidth: 200},
      {Name: 'amt', Type: 'Text', Visible: 0},
      {Name: 'vatAmt', Type: 'Text', Visible: 0},
      {Name: 'taxexe', Type: 'Text', Visible: 0},
      {Name: 'sumAmt', Type: 'Text', Visible: 0},
    ],
  };
};
