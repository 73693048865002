export default that => {
  return {
    Cfg: {
      CanEdit: 0,
      SearchMode: 0,
    },
    Cols: [
      {
        Header: '문서번호',
        Name: 'dpDocNo',
        Type: 'Text',
        Width: 160,
      },
      {
        Header: '시행종류',
        Name: 'docCsDs',
        Type: 'Text',
        Align: 'Center',
        Format: $getConstants('DOC_CS_DS').code,
        Width: 100,
      },
      {
        Header: '문서제목',
        Name: 'tl',
        Type: 'Text',
        RelWidth: 1,
      },
      {
        Name: 'sndDttm',
        Header: '발송일시',
        Extend: that.$ibsheetHelper.preset.date,
        Width: 100,
      },
      {
        Name: 'name',
        Header: '기안자',
        Type: 'Text',
        Width: 100,
      },
      {
        Name: 'enfcDt',
        Header: '결재완료일시',
        Extend: that.$ibsheetHelper.preset.date,
        Width: 100,
      },
    ],
  };
};
