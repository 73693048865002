<template>
  <iui-container-new type="css-flex">
    <i-row height="155px">
      <i-col>
        <PoContSeqList />
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box>
          <template #title>기본정보</template>
          <iui-container-new type="table" theme="bullet">
            <i-row>
              <i-col-header required>거래처명</i-col-header>
              <i-col>
                <iui-text :value="poInfo.orgNm" :enable="false" />
              </i-col>
              <i-spacer />
              <i-col-header required>현장</i-col-header>
              <i-col>
                <iui-text :value="poInfo.spotDeptnm" :enable="false" />
              </i-col>
              <i-spacer />
            </i-row>
            <i-row>
              <i-col-header required>계약번호</i-col-header>
              <i-col>
                <iui-text :value="poInfo.poNo2" width="100px" :enable="false" />
                <iui-text :value="poInfo.ttl" :enable="false" />
              </i-col>
              <i-spacer />
              <i-col-header required>납품장소</i-col-header>
              <i-col>
                <iui-text :value="poInfo.dlvLoc" :enable="false" />
              </i-col>
              <i-spacer />
            </i-row>
            <i-row>
              <i-col-header required>계약내역</i-col-header>
              <i-col :rowspan="poItemListRowspan" colspan="4">
                <PoItemList />
              </i-col>
            </i-row>
            <i-row v-for="i in poItemListRowspan - 1" :key="i">
              <i-col />
            </i-row>
            <i-row>
              <i-col-header required>계약일자</i-col-header>
              <i-col>
                <iui-datepicker :value="poInfo.poDt" :enable="false" />
              </i-col>
            </i-row>
          </iui-container-new>
          <!-- 당초계약 -->
          <PoFirstContSeq v-if="!poStatus.isChangeContSeq" />
          <!-- 변경계약 -->
          <PoChangeContSeq v-else />
          <iui-container-new type="table" theme="bullet">
            <i-row>
              <i-col-header>계약이행</i-col-header>
              <i-col>
                <iui-text
                  type="rate"
                  prefix="보증율 계약금액의"
                  width="185px"
                  :value="poInfo.prfmInsrRate"
                  :enable="false"
                />
                <iui-text type="amount" prefix="보증금" width="160px" :value="poInfo.prfmInsrAmt" :enable="false" />
                <pmis-file-list
                  id="prfmInsrFlNo"
                  flNoCol="prfmInsrFlNo"
                  title="첨부파일"
                  title-visible
                  :modal="true"
                  :toolbar-visible="true"
                  :modalBtnEnable="true"
                  :fileNumber="poInfo.prfmInsrFlNo"
                  :allow-add="poStatus.isPoSend"
                  :save-auth="true"
                  @fileModal_closed="onClose"
                  @delete-complete="v => onDeleteCompleteFile('prfmInsrFlNo', v)"
                />
              </i-col>
              <i-spacer />
              <i-col-header>지체상금율</i-col-header>
              <i-col>
                <iui-checkbox-group
                  :checkedValues="[poInfo.delayRateYn]"
                  :items="[{label: $getConstants('EXTERNAL_Y').name, value: $getConstants('EXTERNAL_Y').code}]"
                  :enable="false"
                />
                <iui-text
                  v-if="poStatus.isDelayRateY"
                  type="rate"
                  width="80px"
                  :value="poInfo.delayRate"
                  :enable="false"
                />
                <pmis-file-list
                  id="delayFlNo"
                  flNoCol="delayFlNo"
                  title="첨부파일"
                  title-visible
                  :modal="true"
                  :toolbar-visible="true"
                  :modalBtnEnable="true"
                  :fileNumber="poInfo.delayFlNo"
                  :allow-add="poStatus.isPoSend"
                  :save-auth="true"
                  @fileModal_closed="onClose"
                  @delete-complete="v => onDeleteCompleteFile('delayFlNo', v)"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>하자보수</i-col-header>
              <i-col colspan="4">
                <iui-text
                  type="rate"
                  prefix="보증율 계약금액의"
                  width="185px"
                  :value="poInfo.flawInsrRate"
                  :enable="false"
                />
                <iui-text type="amount" prefix="보증금" width="160px" :value="poInfo.flawInsrAmt" :enable="false" />
                <label class="ml10 mr10">책임기간</label>
                <iui-radio-group :items="flawInsrItems1" :value="poInfo.flawInsrYn" :enable="false" />
                <iui-text
                  v-if="poStatus.isFlawInsrYear"
                  type="number"
                  width="40px"
                  :value="poInfo.flawInsrYear"
                  :enable="false"
                />
                <iui-radio-group class="ml20" :items="flawInsrItems2" :value="poInfo.flawInsrYn" :enable="false" />
                <template v-if="poStatus.isFlawInsrMonth">
                  <label>담보기간</label>
                  <iui-datepicker :value="poInfo.flawInsrFrDt" :enable="false" />
                  <label>~</label>
                  <iui-datepicker :value="poInfo.flawInsrToDt" :enable="false" />
                </template>
                <pmis-file-list
                  id="scpayFlNo"
                  flNoCol="scpayFlNo"
                  title="첨부파일"
                  title-visible
                  :modal="true"
                  :allow-add="true"
                  :save-auth="true"
                  :toolbar-visible="true"
                  :modal-btn-enable="true"
                  :file-number="poInfo.scpayFlNo"
                  @fileModal_closed="onClose"
                  @delete-complete="v => onDeleteCompleteFile('scpayFlNo', v)"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col />
            </i-row>
          </iui-container-new>

          <iui-container-new type="css-flex" height="200px">
            <i-row>
              <i-col>
                <PoSubmitDocList />
              </i-col>
              <i-spacer />
              <i-col>
                <pmis-file-list
                  id="poContractFile"
                  title="전자서명 파일"
                  title-visible
                  :toolbar-visible="false"
                  :companyCode="poInfo.orgCd"
                  :fileNumber="poInfo.flNo"
                />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import PoInputMixin from '@/view/bidding/js/poInputMixin.js';

import PoContSeqList from './PoContSeqList.vue';
import PoItemList from './PoItemList.vue';
import PoFirstContSeq from './PoFirstContSeq.vue';
import PoChangeContSeq from './PoChangeContSeq.vue';
import PoSubmitDocList from '@/view/bidding/poCustomer/common/components/PoSubmitDocList.vue';
import {saveInsrFile} from '@/view/bidding/po/mat/api/po';

export default {
  components: {PoContSeqList, PoItemList, PoFirstContSeq, PoChangeContSeq, PoSubmitDocList},
  mixins: [PoInputMixin],
  data() {
    return {poItemListRowspan: 6};
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'poStatus']);
  },
  methods: {
    onClose() {
      saveInsrFile({
        poNo: this.poInfo.poNo,
        contSeq: this.poInfo.contSeq,
        delayFlNo: this.poInfo.delayFlNo,
        prfmInsrFlNo: this.poInfo.prfmInsrFlNo,
        scpayFlNo: this.poInfo.scpayFlNo,
      });
    },
    onDeleteCompleteFile(key, flNo) {
      const obj = {
        poNo: this.poInfo.poNo,
        contSeq: this.poInfo.contSeq,
      };
      obj[key] = flNo;
      saveInsrFile(obj);
    },
  },
};
</script>

<style></style>
