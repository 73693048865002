const state = {
  searchInfo: {
    pgmCd: '', // 회사코드
    searchSe: '1',
    searchNm: '',
  },
};

const getters = {
  searchInfo: state => state.searchInfo,
};

const actions = {};

const mutations = {
  setSearchInfo(state, payload) {
    for (let o in payload) {
      if (payload && payload.hasOwnProperty(o)) {
        state.searchInfo[o] = String(payload[o]);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
