export const validSheet = () => {
  let isChangeSheet = false;
  Array.from(document.querySelectorAll('div[id*=sheetDiv]')).forEach(sheet => {
    IBSheet.some(ibsheet => {
      if (sheet.id == ibsheet?.MainTag.id) {
        isChangeSheet = !!JSON.parse(ibsheet.getChangedData()).Changes.length;
        if (isChangeSheet) {
          return true;
        }
      }
    });
  });
  return isChangeSheet;
};

export const validParameter = (url = null) => {
  if (url && location.pathname !== url) {
    return false;
  }

  return document.querySelectorAll('form [data-changed]').length > 0;
};
