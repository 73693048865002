export default that => {
  return {
    Cfg: {
      CanEdit: 0,
    },
    Cols: [
      {
        Header: '순번',
        Name: 'issueSeq',
        Type: 'Int',
        Width: 50,
        Align: 'center',
      },
      {
        Header: '보증종류',
        Name: 'guarKind',
        Type: 'Text',
        RelWidth: 1,
        Align: 'center',
        Format: $getConstants('GUAR_KIND').code,
      },
      {
        Header: '발급구분',
        Name: 'issueDs',
        Type: 'Text',
        RelWidth: 1,
        Align: 'center',
        Format: $getConstants('ISSUE_DS').code,
      },
      {
        Header: '보증금액',
        Name: 'guarAmt',
        Type: 'Int',
        RelWidth: 1,
        Align: 'right',
        Extend: that.$ibsheetHelper.preset.amount,
      },
      {
        Header: '보증금율',
        Name: 'guarRt',
        Type: 'Text',
        RelWidth: 1,
        Align: 'right',
        Extend: that.$ibsheetHelper.preset.rate,
      },
      {
        Header: '발급일자',
        Name: 'issueDate',
        Type: 'Text',
        Width: 105,
        Align: 'center',
        Extend: that.$ibsheetHelper.preset.date,
      },
      {
        Header: '보증기관',
        Name: 'issueOrgNm',
        Type: 'Text',
        RelWidth: 1,
        Align: 'center',
      },
      {
        Header: '보증서번호',
        Name: 'billNo',
        Type: 'Text',
        RelWidth: 1,
        Align: 'center',
      },
      {Name: 'guarStrdate', Visible: false},
      {Name: 'guarEnddate', Visible: false},
      {Name: 'guarName', Visible: false},
      {Name: 'guarNameNm', Visible: false},
    ],
  };
};
