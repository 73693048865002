<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <div>투입일자</div>
      <iui-datepicker :value="inputDate" @change="onInputDateChange" />
      <i class="prev-arrow" @click.prevent.self v-on:click="onClickPrev" />
      <i class="post-arrow" @click.prevent.self v-on:click="onClickNext" />
      <div>예정기간</div>
      <iui-datepicker
        :group="{grpNm: 'planDate', seq: 1}"
        :value="displayPlanStrdate"
        @change="setData('displayPlanStrdate', $event)"
      />
      <div>~</div>
      <iui-datepicker
        :group="{grpNm: 'planDate', seq: 2}"
        :value="displayPlanEnddate"
        @change="setData('displayPlanEnddate', $event)"
      />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <pmis-tab-box>
      <template v-slot:title>단위작업목록</template>
      <UnitOpertList
        ref="unitOpertList"
        :isCheck="true"
        :costType="costType"
        :matGiveYn="matGiveYn"
        :inputDate="inputDate"
        :planStrdate="planStrdate"
        :planEnddate="planEnddate"
        @onPlanStrdate="onPlanStrdate"
        @onPlanEnddate="onPlanEnddate"
      />
    </pmis-tab-box>
  </pmis-content-box>
</template>

<script>
import {selectMonthClosePossibleAt} from '@/view/closing/expitmCloseRegist/api/expitmCloseRegist.js';
import UnitOpertList from './UnitOpertList.vue';
export default {
  components: {
    UnitOpertList,
  },
  props: {
    costType: {
      type: String,
    },
    matGiveYn: {
      type: String,
    },
  },
  data() {
    return {
      inputDate: $_getCurrentDate(),
      displayPlanStrdate: '',
      displayPlanEnddate: '',
      planStrdate: '',
      planEnddate: '',
      monPjtCloseAt: '',
    };
  },
  watch: {
    inputDate: function() {
      this.planStrdate = '';
      this.planEnddate = '';
    },
    displayPlanStrdate: function() {
      this.planStrdate = this.displayPlanStrdate;
      this.planEnddate = this.displayPlanEnddate;
    },
    displayPlanEnddate: function() {
      this.planStrdate = this.displayPlanStrdate;
      this.planEnddate = this.displayPlanEnddate;
    },
  },
  created() {
    this.addEvent({name: 'getRowsByCheckedAndInputDate', func: this.getRowsByCheckedAndInputDate});
  },
  methods: {
    onPlanStrdate(planStrdate) {
      this.displayPlanStrdate = planStrdate;
    },
    onPlanEnddate(planEnddate) {
      this.displayPlanEnddate = planEnddate;
    },
    setData(key, value) {
      this[key] = value;
    },
    async onInputDateChange(date) {
      if (!date) {
        date = $_getCurrentDate();
      }
      if (!(await this.inputDateValidate(date))) {
        return;
      }
      this.setData('inputDate', date);
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      sheet.util.onTreeCheck('chk');
    },
    async onSearch() {
      this.$refs.unitOpertList.onSearch();
    },
    getRowsByCheckedAndInputDate(callback) {
      const rows = this.sheet.getRowsByChecked('chk');
      if (this.monPjtCloseAt !== 'Y' && callback instanceof Function) {
        callback({inputDate: this.inputDate, rows: rows.filter(row => !row.folder)});
      }
    },
    onClickPrev: function() {
      let s = this.inputDate;
      let i = new Date(s.substr(0, 4), s.substr(4, 2) - 1, s.substr(6, 2));
      i.setDate(i.getDate() - 1);
      this.inputDate = $_dateToStringYYMMDD(i);
    },
    onClickNext: function() {
      let s = this.inputDate;
      let i = new Date(s.substr(0, 4), s.substr(4, 2) - 1, s.substr(6, 2));
      i.setDate(i.getDate() + 1);
      const nextDate = $_dateToStringYYMMDD(i);
      if (!this.inputDateValidate(nextDate)) {
        return;
      }
      this.inputDate = nextDate;
    },
    async inputDateValidate(date) {
      const curentDate = $_getCurrentDate();
      if (date > curentDate) {
        this.$alert({title: '투입등록', message: '투입일자는 금일 이후 날짜는 선택할 수 없습니다.'});
        this.inputDate = curentDate;
        return false;
      }
      const response = await this.onSearchMonthCloseAt(date);

      if (response.data.CHECK1 === 'N') {
        this.monPjtCloseAt = 'Y';
        this.$alert({title: '투입등록', message: '월마감 상태이므로 투입등록은 불가합니다.'});
        return false;
      } else {
        this.monPjtCloseAt = 'N';
      }
      return true;
    },
    async onSearchMonthCloseAt(ioDate) {
      if (!ioDate) return;
      const param = {closeMm: String(ioDate).substring(0, 6)};
      const response = await selectMonthClosePossibleAt(param);
      return response;
    },
  },
};
</script>

<style></style>
