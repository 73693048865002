<template>
  <pmis-tab-box>
    <template v-slot:title>정산금액</template>
    <form onsubmit="return false;">
      <p class="bu_title">금월정산금액</p>
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header>
            당월투입금액
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.curInputAmt" />
          </i-col>
          <i-col width="90px"></i-col>
          <i-col></i-col>
        </i-row>
        <i-row>
          <i-col-header>
            전월미지급액
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.prePayable" />
          </i-col>
          <i-col-header>
            기타지급액
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :enable="cud === 1"
              :value="detailInfo.curEtcinputAmt"
              @change="setCurEtcinputAmt(Number($event.target.value))"
            />
          </i-col>
        </i-row>
      </iui-container-new>
      <hr />
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header>
            당월공제금액
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :value="detailInfo.curDeductAmt"
              @change="setCurDeductAmt(Number($event.target.value))"
              :enable="cud === 1"
            />
          </i-col>
          <i-col-header>
            당월유보금액
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :value="detailInfo.curReserveAmt"
              :enable="cud === 1"
              @change="setCurReserveAmt(Number($event.target.value))"
            />
          </i-col>
        </i-row>
      </iui-container-new>
      <hr />

      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header>
            공급가액
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.amt" />
          </i-col>
          <i-col-header>
            부가세
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :enable="cud === 1"
              :value="detailInfo.vatAmt"
              @change="setVatAmt(Number($event.target.value))"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            비과세
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :enable="cud === 1"
              :value="detailInfo.taxexe"
              @change="setTaxexe(Number($event.target.value))"
            />
          </i-col>
          <i-col-header>
            합계
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.sumAmt" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            세금계산서
          </i-col-header>
          <i-col>
            <iui-text
              type="text"
              :value="detailInfo.billNo"
              width="150px"
              max-length="20"
              :readonly="true"
              :css="{cursor: 'pointer', 'text-decoration': 'underline', 'text-align': 'center'}"
              @click="onClick_billNo"
            />
          </i-col>
          <i-col></i-col>
          <i-col></i-col>
        </i-row>
        <i-row>
          <i-col-header>
            계산서
          </i-col-header>
          <i-col>
            <iui-text
              type="text"
              :value="detailInfo.billTaxNo"
              width="150px"
              max-length="20"
              :readonly="true"
              :css="{cursor: 'pointer', 'text-decoration': 'underline', 'text-align': 'center'}"
              @click="onClick_billNo"
            />
          </i-col>
          <i-col></i-col>
          <i-col></i-col>
        </i-row>
      </iui-container-new>
      <tax-invoice-pop :billNo="billNo" :open.sync="propsOpen" pageGubun="sub" />
    </form>
  </pmis-tab-box>
</template>

<script>
import TaxInvoicePop from '@/view/bidding/components/TaxInvoicePop.vue';

export default {
  components: {TaxInvoicePop},
  data: function() {
    return {
      propsOpen: false,
      billNo: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, 'subcon', ['detailInfo']);
    $mapActions(this, 'subcon', ['setCurEtcinputAmt', 'setCurDeductAmt', 'setCurReserveAmt', 'setVatAmt', 'setTaxexe']);
  },
  methods: {
    onClick_billNo(e) {
      if (e.target.value) {
        this.billNo = e.target.value;
        this.propsOpen = true;
      }
    },
  },
};
</script>

<style scoped>
hr {
  height: 1px;
  border: 0;
  background-color: #ccc;
  margin: 10px 5px;
}
</style>
