import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import {PR_COLUMNS, RFQ_COLUMNS} from '@/view/bidding/js/BiddingColumns.js';
import {selectRfqList, selectRfqInfos, selectRfqCompareInfos} from '@/view/bidding/rfq/mat/api/rfq.js';

const BIDDING_STATE = BIDDING_CONSTANTS.PROGRESS_STATE;
const BIDDING_CODES = BIDDING_CONSTANTS.CODES;

const state = {
  rfqList: [],

  prInfo: {...PR_COLUMNS},
  rfqInfo: {...RFQ_COLUMNS},

  rfqItemList: [],
  rfqCusList: [],
  compareQuotList: [], // 견적대비정보

  rfqIsNew: false,
};

const getters = {
  bidType: () => 'mat',
  rfqList: state => state.rfqList,

  prInfo: state => state.prInfo,
  rfqInfo: state => state.rfqInfo,
  rfqStatus: state => getRfqStatus(state),

  rfqItemList: state => state.rfqItemList,
  rfqCusList: state => state.rfqCusList,
  compareQuotList: state => state.compareQuotList,

  rfqIsNew: state => state.rfqIsNew,
};

const mutations = {
  setRfqList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.rfqList = payload;
    }
  },

  setRfqInfo(state, payload) {
    const rfqInfo = state.rfqInfo;

    for (let key in rfqInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        rfqInfo[key] = payload[key];
      }
    }

    rfqInfo.rfqNo2 = state.rfqIsNew ? BIDDING_CODES.AUTO : COMMON_FUNCTION.getMakeFormat(rfqInfo.rfqNo);
  },
  initRfqInfo(state) {
    state.rfqInfo = {...RFQ_COLUMNS};
  },
  setPrInfo(state, payload) {
    for (let key in state.prInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.prInfo[key] = payload[key];
      }
    }
    state.prInfo.prNo2 = COMMON_FUNCTION.getMakeFormat(state.prInfo.prNo);
  },
  initPrInfo(state) {
    state.prInfo = {...PR_COLUMNS};
  },

  setRfqItemList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.rfqItemList = payload;
    }
  },
  setRfqCusList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.rfqCusList = payload;
    }
  },
  setCompareQuotList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.compareQuotList = payload;
    }
  },
  setRfqIsNew(state, payload) {
    if (typeof payload === 'boolean') {
      state.rfqIsNew = payload;
    }
  },
};

const actions = {
  initRfq({commit}) {
    commit('setRfqIsNew', false);
    commit('initPrInfo');
    commit('initRfqInfo');
    commit('setRfqItemList', []);
    commit('setRfqCusList', []);
  },

  async searchRfqList({commit, dispatch}, searchOptions) {
    const rfqList = (await selectRfqList(searchOptions)).data;
    commit('setRfqList', rfqList);

    if (!rfqList.length) {
      dispatch('initRfq');
    }
  },

  async searchRfqInfos({getters, commit, dispatch}, rfqNo) {
    dispatch('initRfq');

    const response = await selectRfqInfos(rfqNo);
    commit('setRfqInfo', response.data.rfqInfo);
    commit('setRfqItemList', response.data.rfqItemList);
    commit('setRfqCusList', response.data.rfqCusList);

    const prInfo = response.data.prInfo;
    prInfo ? commit('setPrInfo', prInfo) : commit('initPrInfo');

    if (getters.rfqStatus.isAfterRfqOpen) {
      dispatch('searchCompareQuotList');
    }
  },

  async searchCompareQuotList(context) {
    const rfqInfo = context.getters.rfqInfo;
    const response = await selectRfqCompareInfos(rfqInfo.rfqNo, rfqInfo.bidRnd);
    const compareQuotList = response.data.compareQuotList;
    context.commit('setCompareQuotList', compareQuotList || []);
  },
};

function getRfqStatus(state) {
  const prInfo = state.prInfo;
  const rfqInfo = state.rfqInfo;
  const rfqSt = rfqInfo.rfqSt;

  const basicStatus = {
    isExistOnlyPr: !!prInfo.prNo && !rfqInfo.rfqNo, // 구매요청만 존재
    isNotExistPr: !prInfo.prNo, // 구매요청 부재
    isExistRfqNo: !!rfqInfo.rfqNo,
    isReBid: 0 < rfqInfo.lastBidRnd,

    // 입찰 진행상태
    isRfqReady: rfqSt === BIDDING_STATE.RFQ_READY, // 공고작성중
    isRfqApprovalComplete: rfqSt === BIDDING_STATE.RFQ_APPROVAL_COMPLETE, // 공고품의완료
    isRfqSpotDscr: rfqSt === BIDDING_STATE.RFQ_SPOT_DSCR, // 현설통보
    isRfqSpotDscrExpired: rfqSt === BIDDING_STATE.RFQ_SPOT_DSCR_EXPIRED, // 현설마감
    isRfqSpotDscrClose: rfqInfo.isCloseSpot, // 현설마감여부
    isRfqBidding: rfqSt === BIDDING_STATE.RFQ_BIDDING, // 입찰중
    isRfqBiddingExpired: rfqSt === BIDDING_STATE.RFQ_BIDDING_EXPIRED, // 입찰마감
    isBeforeRfqOpen: !!rfqSt && rfqSt < BIDDING_STATE.RFQ_OPEN, // 개봉 전
    isRfqOpen: rfqSt === BIDDING_STATE.RFQ_OPEN, // 개봉
    isAfterRfqOpen: !!rfqSt && rfqSt >= BIDDING_STATE.RFQ_OPEN, // 개봉 이후
    isRfqChoice: rfqSt === BIDDING_STATE.RFQ_CHOICE, // 낙찰사등록
    isRfqApprovalChoice: rfqSt === BIDDING_STATE.RFQ_APPROVAL_CHOICE, // 낙찰사선정품의
    isRfqFail: rfqSt === BIDDING_STATE.RFQ_FAIL, // 유찰
    isRfqComplete: rfqSt === BIDDING_STATE.RFQ_COMPLETE, // 입찰완료
    isCancel: rfqInfo.cancelYn === BIDDING_CODES.RFQ_CANCEL_Y, // 입찰취소

    isPotypeUnit: rfqInfo.poTypecd === BIDDING_CODES.POTYPE_UNIT, // 단가계약여부
    isExistSpotDscr: rfqInfo.spotDscrYn === BIDDING_CODES.SPOT_DSCR_Y, // 현설유무 - 있음
    isTaxExemption: rfqInfo.taxTypecd === BIDDING_CODES.TAX_EXEMPTION, // 과세유형 : 비과세
    isTaxDirect: rfqInfo.taxTypecd === BIDDING_CODES.TAX_DIRECT, // 과세유형 - 직접 입력
    isTaxDirectPercent: rfqInfo.taxDirectDs === BIDDING_CODES.TAX_DIRECT_PERCENT, // 과세유형 - 직접 입력(%)
    isTaxDirectAmt: rfqInfo.taxDirectDs === BIDDING_CODES.TAX_DIRECT_AMOUNT, // 과세유형 : 직접입력(원)
  };
  const computedStatus = {
    isExistOnlyRfq: basicStatus.isNotExistPr && !!rfqInfo.rfqNo,
    isEnable: state.rfqIsNew || basicStatus.isExistOnlyPr || basicStatus.isRfqReady,

    isEnableSpotCancel:
      basicStatus.isExistSpotDscr &&
      basicStatus.isRfqSpotDscrClose &&
      basicStatus.isRfqBidding &&
      !basicStatus.isReBid &&
      rfqInfo.quotCusCount === 0, // 현설결과취소 가능여부

    isEnableChoice: basicStatus.isRfqOpen || basicStatus.isRfqChoice, // 낙찰사등록(변경) 가능
  };

  return {...basicStatus, ...computedStatus};
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
