<template>
  <iui-container-new type="table" theme="bullet">
    <i-row>
      <i-col-header width="60px">평일</i-col-header>
      <i-col width="60px">
        근로시간
      </i-col>
      <i-col>
        <iui-timepicker
          :group="{grpNm: 'ndayTime', seq: 1}"
          suffix="~"
          :value="stdInfo.ndayStTime"
          @change="setInitTime('ndayStTime', $event)"
        />
        <iui-timepicker
          :group="{grpNm: 'ndayTime', seq: 2}"
          :value="stdInfo.ndayEnTime"
          @change="setInitTime('ndayEnTime', $event)"
        />
      </i-col>
      <i-col width="60px">
        휴게시간
      </i-col>
      <i-col>
        <iui-timepicker
          :group="{grpNm: 'nrst1Time', seq: 1}"
          suffix="~"
          :value="stdInfo.nrst1StTime"
          @change="setInitTime('nrst1StTime', $event)"
        />
        <iui-timepicker
          :group="{grpNm: 'nrst1Time', seq: 2}"
          :value="stdInfo.nrst1EdTime"
          @change="setInitTime('nrst1EdTime', $event)"
        />
      </i-col>
    </i-row>
    <i-row>
      <i-col colspan="3">&nbsp;</i-col>
      <i-col>
        휴게시간
      </i-col>
      <i-col>
        <iui-timepicker
          :group="{grpNm: 'nrst2Time', seq: 1}"
          suffix="~"
          :value="stdInfo.nrst2StTime"
          @change="setInitTime('nrst2StTime', $event)"
        />
        <iui-timepicker
          :group="{grpNm: 'nrst2Time', seq: 2}"
          :value="stdInfo.nrst2EdTime"
          @change="setInitTime('nrst2EdTime', $event)"
        />
      </i-col>
    </i-row>
    <i-row>
      <i-col-header>토요일</i-col-header>
      <i-col>
        근로시간
      </i-col>
      <i-col>
        <iui-timepicker
          :group="{grpNm: 'sdayTime', seq: 1}"
          suffix="~"
          :value="stdInfo.sdayStTime"
          @change="setInitTime('sdayStTime', $event)"
        />
        <iui-timepicker
          :group="{grpNm: 'sdayTime', seq: 2}"
          :value="stdInfo.sdayEnTime"
          @change="setInitTime('sdayEnTime', $event)"
        />
      </i-col>
      <i-col>
        휴게시간
      </i-col>
      <i-col>
        <iui-timepicker
          :group="{grpNm: 'srst1Time', seq: 1}"
          suffix="~"
          :value="stdInfo.srst1StTime"
          @change="setInitTime('srst1StTime', $event)"
        />
        <iui-timepicker
          :group="{grpNm: 'srst1Time', seq: 2}"
          :value="stdInfo.srst1EdTime"
          @change="setInitTime('srst1EdTime', $event)"
        />
      </i-col>
    </i-row>
    <i-row>
      <i-col colspan="3">&nbsp;</i-col>
      <i-col>
        휴게시간
      </i-col>
      <i-col>
        <iui-timepicker
          :group="{grpNm: 'srst2Time', seq: 1}"
          suffix="~"
          :value="stdInfo.srst2StTime"
          @change="setInitTime('srst2StTime', $event)"
        />
        <iui-timepicker
          :group="{grpNm: 'srst2Time', seq: 2}"
          :value="stdInfo.srst2EdTime"
          @change="setInitTime('srst2EdTime', $event)"
        />
      </i-col>
    </i-row>
    <i-row>
      <i-col-header>근무일</i-col-header>
      <i-col colspan="2">
        <div>매주</div>
        <iui-text type="number" width="40px" :value.sync="stdInfo.wworkDay" />
        <div>일</div>
      </i-col>
      <i-col-header>휴무일</i-col-header>
      <i-col>
        <iui-text width="25px" align="center" max-length="1" :value.sync="stdInfo.wrstWeekday" />
        <div>요일</div>
      </i-col>
    </i-row>
    <i-row>
      <i-col-header>지급일</i-col-header>
      <i-col colspan="2">
        <div>매월</div>
        <iui-text type="number" width="40px" :value.sync="stdInfo.salaryDate" />
        <div>일</div>
      </i-col>
    </i-row>
    <i-row>
      <i-col-header>제수당</i-col-header>
      <i-col colspan="4" rowspan="2">
        <iui-container-new type="css-flex">
          <i-row>
            <i-col style="border: 2px solid #808080; border-radius: 3px; padding: 5px;">
              <iui-container-new type="table" theme="bullet">
                <i-row>
                  <i-col width="50px">시간외</i-col>
                  <i-col>
                    <iui-text type="rate" width="80px" :value.sync="stdInfo.overTimeRt" />
                  </i-col>
                  <i-col width="50px">야간</i-col>
                  <i-col>
                    <iui-text type="rate" width="80px" :value.sync="stdInfo.nightTimeRt" />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col>휴일</i-col>
                  <i-col>
                    <iui-text type="rate" width="80px" :value.sync="stdInfo.holidayTimeRt" />
                  </i-col>
                  <i-col colspan="2">기준금액 : 통상임금(일급)</i-col>
                </i-row>
              </iui-container-new>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
    <i-row>
      <i-col>&nbsp;</i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
export default {
  data() {
    return {
      stdInfo: {
        ndayStTime: '', // 평일근로시작시간
        ndayEnTime: '', // 평일근로종료시간

        nrst1StTime: '', // 평일휴게1시작시간
        nrst1EdTime: '', // 평일휴게1종료시간
        nrst2StTime: '', // 평일휴게2시작시간
        nrst2EdTime: '', // 평일휴게2종료시간

        sdayStTime: '', // 토요일근로시작시간
        sdayEnTime: '', // 토요일근로종료시간

        srst1StTime: '', // 토요일휴게1시작시간
        srst1EdTime: '', // 토요일휴게1종료시간
        srst2StTime: '', // 토요일휴게2시작시간
        srst2EdTime: '', // 토요일휴게2종료시간

        wworkDay: '', // 주간근무일수
        wrstWeekday: '', // 주간휴무요일
        salaryDate: '', // 월지급일자

        overTimeRt: '', // 시간외수당
        nightTimeRt: '', // 야간수당
        holidayTimeRt: '', // 휴일수당
      },
    };
  },
  created() {
    this.addEvent({name: 'DailyStandardRegist_Save', func: this.onSave});
  },
  mounted() {
    this.onSearch();
  },
  methods: {
    setInitTime(key, value) {
      this.stdInfo[key] = value;
    },
    onSearch() {
      axios
        .post('/laborContract/selectDailyLaborStandardInfo', {
          pgmCd: this.pgmCd,
          prjCd: this.prjCd,
        })
        .then(response => {
          if (response.data.pgmCd && response.data.prjCd) {
            for (let key in this.stdInfo) {
              this.stdInfo[key] = response.data[key];
            }
          } else {
            this.stdInfo.overTimeRt = '150';
            this.stdInfo.nightTimeRt = '150';
            this.stdInfo.holidayTimeRt = '150';
          }
        });
    },
    async onSave(callback) {
      const response = await this.$save({
        url: '/laborContract/saveDailyLaborStandardInfo',
        param: {
          pgmCd: this.pgmCd,
          prjCd: this.prjCd,
          ...this.stdInfo,
        },
        title: '근무기준등록',
        message: '근무기준등록 정보를 저장하시겠습니까?',
      }).catch(ex => {
        if (ex.pmisError) {
          this.$alert(ex.message);
        }
      });

      if (response.status == 200) {
        callback();
      }
    },
  },
};
</script>

<style></style>
