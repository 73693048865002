export default async that => {
  return {
    Cfg: {
      SearchMode: 0,
      CanDrag: 1,
    },
    Cols: [
      {
        Header: '포틀릿그룹',
        Name: 'portletGroup',
        Type: 'Enum',
        Width: 260,
        RelWidth: 1,
        CanEdit: 0,
        EnumKeys: await $getCodeIBSheetEnum('KEY', $getConstants('PORTLET_GROUP').code),
        Enum: await $getCodeIBSheetEnum('VALUE', $getConstants('PORTLET_GROUP').code),
      },
      {
        Header: '포틀릿ID',
        Name: 'portletId',
        Type: 'Text',
        Width: 180,
        CanEdit: 0,
      },
      {
        Header: '포틀릿명',
        Name: 'portletName',
        Type: 'Text',
        Width: 250,
        CanEdit: 0,
      },
      {
        Header: '포틀릿사이즈',
        Name: 'size',
        Type: 'Enum',
        Width: 100,
        CanEdit: 0,
        EnumKeys: await $getCodeIBSheetEnum('KEY', $getConstants('PORTLET_SIZE').code),
        Enum: await $getCodeIBSheetEnum('VALUE', $getConstants('PORTLET_SIZE').code),
      },
    ],
  };
};
