export function number(value) {
  let formatter = new Intl.NumberFormat('ko-KR');

  let numberValue = Number(value);
  let formattedString = '';

  if (!Number.isNaN(numberValue)) {
    formattedString = formatter.format(numberValue);
  }

  return formattedString;
}

export function currency(value) {
  // let formatter = new Intl.NumberFormat('ko-KR');

  // let numberValue = Number(value);
  let formattedString = number(value);

  if (formattedString.length > 0) {
    formattedString += ' 원';
  }

  // if (!Number.isNaN(numberValue)) {
  //   formattedString = formatter.format(numberValue);
  // }

  return formattedString;
}
