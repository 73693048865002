import material from './material.js';
import labor from './labor.js';
import equipment from './equipment.js';
import subcon from './subcon.js';

const state = {
  /* 조회정보 */
  searchInfo: {
    pgmCd: '',
    prjCd: '',
    investMm: '', // 정산년월
    reqDegreeList: [], // 차수 목록
    reqDegree: '', // 차수
    repayfrDt: '', // 기간 시작
    repaytoDt: '', // 기간 종료
  },
};

const getters = {
  searchInfo: state => state.searchInfo,
};

const mutations = {
  setSearchInfo(state, payload) {
    for (let o in payload) {
      if (payload && payload.hasOwnProperty(o)) {
        state.searchInfo[o] = String(payload[o]);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  modules: {
    material,
    labor,
    equipment,
    subcon,
  },
};
