export default that => {
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
    },
    Cols: [
      {
        Header: '지시일자',
        Name: 'instDt',
        Type: 'Text',
        Align: 'center',
        RelWidth: 2,
        CanEdit: 0,
        CustomFormat: v => (v.length > 0 ? v.substr(0, 4) + '-' + v.substr(4, 2) + '-' + v.substr(6, 2) : ''),
      },
      {
        Header: '순번',
        Name: 'seq',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: '지시자',
        Name: 'instName',
        Type: 'Text',
        Align: 'center',
        RelWidth: 2,
        CanEdit: 0,
      },
      {
        Header: '지시내용',
        Name: 'instCts',
        Type: 'Text',
        Align: 'left',
        RelWidth: 4,
        CanEdit: 0,
      },
      {
        Header: '처리기한',
        Name: 'prcDate',
        Type: 'Text',
        Align: 'center',
        RelWidth: 2,
        CanEdit: 0,
        CustomFormat: v => (v.length > 0 ? v.substr(0, 4) + '-' + v.substr(4, 2) + '-' + v.substr(6, 2) : ''),
      },
      {
        Header: '조치사항',
        Name: 'cortFcts',
        Type: 'Text',
        Align: 'left',
        RelWidth: 4,
        CanEdit: 0,
      },
      {
        Header: '조치일자',
        Name: 'actionDate',
        Type: 'Text',
        Align: 'center',
        RelWidth: 2,
        CanEdit: 0,
        CustomFormat: v => (v.length > 0 ? v.substr(0, 4) + '-' + v.substr(4, 2) + '-' + v.substr(6, 2) : ''),
      },
      {
        Header: '조치자',
        Name: 'actionName',
        Type: 'Text',
        Align: 'center',
        RelWidth: 2,
        CanEdit: 0,
      },
    ],
  };
};
