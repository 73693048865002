export default that => {
  let preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      CanEdit: 0,
    },
    Cols: [
      {
        Header: '입고일자',
        Name: 'ioDate',
        Type: 'Text',
        Width: 90,
        Align: 'Center',
        CustomFormat: '####-##-##',
        CanEdit: 0,
      },
      {Header: '순번', Name: 'seq', Type: 'Text', Width: 60, Align: 'Center', CanEdit: 0},
      {Header: '자재코드', Name: 'matCode', Type: 'Text', Width: 130, CanEdit: 0},
      {Header: '명칭', Name: 'itemName', Type: 'Text', RelWidth: 1, MinWidth: 150, CanEdit: 0},
      {Header: '규격', Name: 'size', Type: 'Text', RelWidth: 1, MinWidth: 150, CanEdit: 0},
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Width: 100,
        Align: 'Center',
        Format: $getConstants('UNIT').code,
        CanEdit: 0,
      },
      {Header: '수량', Name: 'qty', Extend: preset.quantity, Width: 100, CanEdit: 0},
      {Header: '단가', Name: 'price', Extend: preset.unitAmount, Width: 100, CanEdit: 0},
      {Header: '금액', Name: 'amt', Extend: preset.amount, Width: 100, CanEdit: 0},
    ],
  };
};
