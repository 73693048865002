<template>
  <pmis-content-box>
    <template #title>자재내역</template>
    <template #header-left>
      <iui-text type="amount" width="180px" prefix="공급가액" :enable="false" :value="orderInfo.poAmt" />
      <iui-text type="amount" width="180px" prefix="부가세액" :enable="false" :value="orderInfo.vatAmt" />
      <iui-text type="amount" width="180px" prefix="합계금액" :enable="false" :value="orderInfo.totAmt" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      @loadSheet="sheet = $event"
      :loadSearchData="orderItemList"
      :events="{
        onSearchFinish: sheet_onSearchFinish,
        onBeforeChange: sheet_onBeforeChange,
        onAfterChange: sheet_onAfterChange,
      }"
    />
  </pmis-content-box>
</template>

<script>
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import sheetOpt from './sheetOptions/poOrderItemList.js';

export default {
  data() {
    return {
      sheetOpt: sheetOpt(this),
    };
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'orderInfo', 'orderItemList', 'canEditOrder']);
    $mapMutations(this, ['setOrderInfo']);
  },
  created() {
    this.addEvent([{name: 'OrderItemList_getSaveJson', func: this.getSaveJson}]);
  },
  methods: {
    sheet_onSearchFinish(e) {
      e.sheet.getDataRows().forEach(row => {
        const canEditColor = !!row.remQty && this.canEditOrder ? '#E0E0F8' : undefined;

        this.sheet.setAttribute(row, 'qty', 'CanEdit', !!canEditColor);
        this.sheet.setAttribute(row, 'qty', 'Color', canEditColor);
      });
    },
    sheet_onBeforeChange(e) {
      if (e.col === 'qty') {
        if (0 > e.val) {
          this.$alert({title: '금회요청수량', message: '금회요청수량은 0 이상이어야 합니다.'});
          return e.oldval;
        }

        if (e.val > e.row.remQty) {
          this.$alert({
            title: '금회요청수량',
            message: `해당 내역의 요청 가능한 수량은 ${Number(e.row.remQty).toFixed(4)}입니다.<br/>
                      금회요청수량을 다시 입력하시기 바랍니다.`,
          });
          return e.oldval;
        }
      }
    },
    sheet_onAfterChange(e) {
      if (e.col === 'qty') {
        this.calculateAmt();
      }
    },
    calculateAmt() {
      const itemList = this.sheet.getDataRows();
      const amt = itemList.reduce((acc, curr) => acc + curr['amt'], 0);

      const {taxTypecd, taxDirectDs, taxRate, taxTypeDirectPercent, taxTypeDirectAmt} = this.poInfo;
      const calResult = COMMON_FUNCTION.onCalculateTaxAndTotAmt({
        amt,
        taxTypecd,
        taxDirectDs,
        taxRate,
        taxTypeDirectPercent,
        taxTypeDirectAmt,
      });

      this.setOrderInfo({poAmt: calResult.amt, ...calResult});
    },
    getSaveJson(callback) {
      const getSaveJson = this.sheet.getSaveJson(0).data;
      if (typeof callback === 'function') {
        callback(getSaveJson);
      }
    },
  },
};
</script>

<style></style>
