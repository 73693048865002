<template>
  <iui-content-box :loading="loading">
    <form onsubmit="return false;">
      <iui-container-new type="css-flex">
        <i-row>
          <i-col flex="5">
            <i-row>
              <i-col>
                <CompanyInfo :enable="enable" />
              </i-col>
            </i-row>
            <i-row>
              <i-col>
                <iui-container-new type="css-flex">
                  <i-row v-if="frmGb1 || frmGb2 || frmGb3 || frmGb4" height="180px">
                    <i-col>
                      <HoldLcnsInfo v-if="frmGb1 || frmGb4" :enable="enable" />
                      <AreaInfo v-else :title="frmGb2 ? '공사지역정보' : '납품지역정보'" :enable="enable" />
                    </i-col>
                  </i-row>
                  <i-row v-if="frmGb2 || frmGb3" height="190px">
                    <i-col>
                      <WorkTypeInfo v-if="frmGb2" :enable="enable" />
                      <MtrilInfo v-if="frmGb3" :enable="enable" />
                    </i-col>
                  </i-row>
                </iui-container-new>
              </i-col>
            </i-row>
            <i-row>
              <i-col>
                <SystemUserInfo v-if="frmGb2 || frmGb3" :enable="!enable" />
                <AdminInfo v-else-if="frmGb1 || frmGb4" :adminEnable="enable" />
              </i-col>
              <i-col v-if="frmGb0">
                <AdminInfo :adminEnable="enable" />
                <SystemUserInfo :enable="!enable" />
              </i-col>
            </i-row>
          </i-col>
          <i-spacer />
          <i-col flex="3">
            <i-row>
              <DocManageInfo :enable="enable" />
            </i-row>
            <i-row>
              <AdminInfo v-if="frmGb2 || frmGb3" :adminEnable="enable" />
              <SystemUserInfo v-else-if="frmGb1 || frmGb4" :enable="!enable" />
            </i-row>
          </i-col>
          <i-spacer />
        </i-row>
      </iui-container-new>
    </form>
  </iui-content-box>
</template>

<script>
import CompanyInfo from '@/view/member/components/CompanyInfo.vue';
import DocManageInfo from '@/view/member/components/DocManageInfo.vue';
import HoldLcnsInfo from '@/view/member/components/HoldLcnsInfo.vue';
import AreaInfo from '@/view/member/components/AreaInfo.vue';
import WorkTypeInfo from '@/view/member/components/WorkTypeInfo.vue';
import MtrilInfo from '@/view/member/components/MtrilInfo.vue';
import SystemUserInfo from '@/view/member/components/SystemUserInfo.vue';
import AdminInfo from '@/view/member/components/AdminInfo.vue';
import {selectCompanyInfo} from '../api/member.js';
export default {
  components: {
    CompanyInfo,
    DocManageInfo,
    HoldLcnsInfo,
    AreaInfo,
    WorkTypeInfo,
    MtrilInfo,
    SystemUserInfo,
    AdminInfo,
  },
  props: {
    enable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'worker', 'dupBsnNoStatus', 'docnmList']);
    $mapMutations(this, [
      'setCompanyInfo',
      'setWorker',
      'setAreaList',
      'setMatList',
      'setGcodeList',
      'setLicenseList',
      'setDocnmList',
      'setCeoList',
    ]);
  },
  created() {
    this.addEvent([{name: 'selectCompanyInfo', func: this.selectCompanyInfo}]);
    this.addFuncSearch(this.selectCompanyInfo);
  },
  methods: {
    async selectCompanyInfo() {
      this.loading = true;
      this.$recompute('pgmCd');
      const response = await selectCompanyInfo({pgmCd: this.pgmCd});
      this.companyData = response.data;
      this.setCompanyInfo(this.companyData);
      this.setWorker(this.companyData.worker);
      if (this.companyData.licenseList) {
        this.setLicenseList(this.companyData.licenseList);
      }
      if (this.companyData.areaList) {
        this.setAreaList(this.companyData.areaList);
      }
      if (this.companyData.gcodeList) {
        this.setGcodeList(this.companyData.gcodeList);
      }
      if (this.companyData.matList) {
        this.setMatList(this.companyData.matList);
      }
      if (this.companyData.docnmList) {
        this.setDocnmList(this.companyData.docnmList);
      }
      if (this.companyData.ceoList) {
        this.setCeoList(this.companyData.ceoList);
      }
      this.loading = false;
    },
  },
  computed: {
    frmGb1() {
      return this.companyInfo.frmGb == $getConstants('FRM_GB_1').code; // 건설사
    },
    frmGb2() {
      return this.companyInfo.frmGb == $getConstants('FRM_GB_2').code; // 공사용역
    },
    frmGb3() {
      return this.companyInfo.frmGb == $getConstants('FRM_GB_3').code; // 물품자재
    },
    frmGb4() {
      return this.companyInfo.frmGb == $getConstants('FRM_GB_4').code; // 건설사 + 공사용역
    },
    frmGb0() {
      return this.companyInfo.frmGb == $getConstants('FRM_GB_0').code; // 운영사
    },
  },
};
</script>

<style></style>
