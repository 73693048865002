<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-col>
          <iui-container-new type="css-flex">
            <i-row minHeight="500px">
              <i-col>
                <pmis-tab-box>
                  <template #title><b>도급내역</b></template>
                  <template #title-left>
                    <span class="ellipsis">
                      (자원구분[M:재료비 L:노무비 E:장비비 A:경비 I:일위대가])
                    </span>

                    <span class="mr15"></span>
                    <iui-select
                      name="degree"
                      :items="degreeListAsSelect"
                      defaultCd="S"
                      :value.sync="degree"
                      @change="onChangeDegree"
                      label="차수"
                      width="85px"
                      required
                    ></iui-select>
                    <iui-button value="내역확정" @click="onDtlsDcns" v-if="confirm && maxDgree"></iui-button>
                    <iui-button value="차수삭제" @click="onDeleteChgDegree" v-if="confirm && maxDgree"></iui-button>
                    <iui-button
                      value="확정취소"
                      @click="onDcnsCancl"
                      v-if="!confirm && maxDgree && currentDegree == maxDgree"
                    ></iui-button>
                    <iui-button
                      value="차수생성"
                      @click="onPopChgDegree"
                      v-if="$store.getters['prjCd'] && (!confirm || masterInfo.chgDegree == 0)"
                    />
                    <div style="flex:1"></div>
                    <iui-button value="공종추가" @click="onPopAddPrjWork" v-if="confirm && maxDgree"></iui-button>
                    <iui-button
                      value="엑셀등록"
                      @click="onPopAddExcel"
                      v-if="confirm && maxDgree && maxDgree == 1"
                    ></iui-button>
                    <iui-button
                      value="타현장도급내역복사"
                      @click="onPopOtherPrjCopy"
                      v-if="confirm && maxDgree && maxDgree == 1"
                    />
                  </template>
                  <DetailList />
                </pmis-tab-box>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
    <iui-modal name="prjWorkTypePop" title="현장공종추가" sizeType="size3" :btns="prjWorkTypeBtns">
      <PrjWorkTypePop />
    </iui-modal>
    <iui-modal name="excelUploadPop" title="엑셀등록" :btns="excelBtns" sizeType="size1">
      <ExcelUpload uri="/outsrcDtls/excelUpload" title="도급내역" :rows="excelRows" @error="onExcelUploadError" />
    </iui-modal>
    <iui-modal name="ExcelUploadResultPop" title="엑셀 업로드 실패 사유" sizeType="size6" @closed="resultList = []">
      <ExcelUploadResult :sheet-opt="excelSheetOpt" :list="resultList" />
    </iui-modal>
    <iui-modal name="chgDegree" title="차수생성" :btns="chgDegreeBtns" width="500px" height="200px">
      <ChgDegree :chgDegree="maxDgree ? Number(maxDgree) : undefined" ref="chgDegree" />
    </iui-modal>
    <iui-modal name="OtherPrjCopyPop" title="타현장도급내역복사" :btns="otherPrjCopyBtns" sizeType="size1">
      <OtherPrjCopyPop :isCopyOpt="true" />
    </iui-modal>
    <iui-modal name="UntpcMdacPop" title="실행내역생성" :btns="untpcMdacBtns" sizeType="size1">
      <UntpcMdacPop />
    </iui-modal>
  </pmis-page>
</template>

<script>
import store from './store/outsrcDtls.js';
import PrjList from '@/view/Project/components/PrjList.vue';
import DetailList from './components/DetailList.vue';
import PrjWorkTypePop from '@/view/estmtManage/resrceManage/popup/PrjWorkTypePop.vue';
import ChgDegree from '@/view/common/popup/ChgDegreePop.vue';
import OtherPrjCopyPop from '@/view/outsrcDtls/components/popup/OtherPrjCopyPop.vue';
import UntpcMdacPop from '@/view/executDtls/components/popup/UntpcMdacPop.vue';
import {
  insertContContexecPlan,
  copyOtherPjtCont,
  dtlsDcns,
  dcnsCancl,
  deleteContContexecPlan,
  saveContExecAsContCont,
} from './api/outsrcDtls.js';
import ExcelUpload from '@/components/popup/ExcelUpload.vue';
import excelOptions from '@/view/outsrcDtls/components/sheetOption/ExcelUploadResultSheet';
import ExcelUploadResult from '@/components/popup/ExcelUploadResult.vue';
import EXCEL_CONSTANTS from '@/constants/excelConstants.js';

export default {
  components: {
    ExcelUploadResult,
    PrjList,
    DetailList,
    PrjWorkTypePop,
    ChgDegree,
    OtherPrjCopyPop,
    UntpcMdacPop,
    ExcelUpload,
  },

  data() {
    return {
      prjWorkTypeBtns: [{name: '확인', callback: this.onConfirmPrjWorkType}],
      excelBtns: [{name: '확인', callback: this.onPopConfirmExcel}],
      chgDegreeBtns: [{name: '확인', callback: this.onPopConfirmChgDegree}],
      otherPrjCopyBtns: [{name: '확인', callback: this.onPopConfirmOtherPrjCopyPop}],
      untpcMdacBtns: [{name: '확인', callback: this.onPopConfirmUntpcMdacPop}],
      code0265: '',
      resultList: [],
      excelSheetOpt: excelOptions(this),
      excelRows: [
        {
          value: [
            {value: '공사코드'},
            {value: '대공종코드'},
            {value: '중공종코드'},
            {value: '도급코드'},
            {value: '품명', style: {align: 'CENTER', width: 7000}},
            {value: '규격'},
            {
              value: '단위코드',
              validate: {
                excelEnum: EXCEL_CONSTANTS.COMMON_CODE,
                pCode: $getConstants('UNIT').code,
                withDropdown: false,
                checksName: false,
              },
            },
            {value: '수량'},
            {value: '재료비단가'},
            {value: '노무비단가'},
            {value: '경비단가'},
            {
              value: '지급자재여부',
              validate: {
                checkList: [
                  {name: 'Y', value: $getConstants('Y').code},
                  {name: '', value: ''},
                ],
              },
            },
          ],
        },
      ],
    };
  },

  computed: {
    confirm() {
      return this.masterInfo.prjFlag != this.code0265 && this.currentDegree == this.maxDgree;
    },
    degree: {
      set(value) {
        this.setCurrentDegree(value);
      },
      get() {
        return this.currentDegree;
      },
    },
  },

  watch: {
    masterInfo: {
      handler(val) {
        if (!this._activated) {
          return;
        }
        if (val.prjFlag == this.code0265 || this.currentDegree != this.maxDgree) {
          this.canNew = false;
          this.canSave = false;
          this.canDelete = false;
        } else {
          this.canNew = true;
          this.canSave = true;
          this.canDelete = true;
        }
      },
      deep: true,
    },
  },

  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['currentDegree', 'maxDgree', 'masterInfo', 'degreeListAsSelect']);
    $mapMutations(this, ['setCurrentDegree']);

    $getCode($getConstants('CONFIRM_FLAG').code).then(response => (this.code0265 = response[0].code));
  },

  methods: {
    onPopAddPrjWork() {
      this.$modal.show('prjWorkTypePop');
    },
    onConfirmPrjWorkType() {
      this.$modal.hide('prjWorkTypePop');
      this.callEvent({name: 'onSearch'});
    },
    onPopAddExcel() {
      this.$modal.show('excelUploadPop');
    },
    onPopConfirmExcel() {
      this.callEvent({
        name: 'excelUpload',
        param: () => {
          this.callEvent({name: 'onSearch'});
          this.$modal.hide('excelUploadPop');
        },
      });
    },
    onExcelUploadError(error, list) {
      if (error === 'server') {
        this.$alert({title: '엑셀업로드', message: '도급내역 엑셀파일을 확인하십시오.'});
      } else {
        this.$alert({title: '엑셀업로드', message: error});
        if (list && list.length > 0) {
          this.resultList = list;
          this.$modal.show('ExcelUploadResultPop');
        }
      }
    },
    onChangeDegree() {
      this.callEvent({name: 'onSearch'});
    },
    async onDtlsDcns() {
      if (!this.currentDegree) {
        this.$alert({title: '내역확정', message: '최종차수를 선택해주세요.'});
        return;
      }
      if (
        !(await this.$confirm({title: '내역확정', message: `${this.currentDegree}차 도급내역을 확정하시겠습니까?`}))
      ) {
        return;
      }

      const param = {
        chgDegree: this.masterInfo.chgDegree,
        prjFlag: this.code0265,
      };
      const response = await dtlsDcns(param);
      if (response.data) {
        this.callEvent({name: 'onSearch'});
        if (this.isSimpleExec) {
          this.$modal.show('UntpcMdacPop');
        }
      }
    },
    async onDcnsCancl() {
      if (!(await this.$confirm({title: '확정취소', message: '확정취소 하시겠습니까?'}))) {
        return;
      }
      const param = {
        chgDegree: this.masterInfo.chgDegree,
        prjFlag1: '',
      };
      const response = await dcnsCancl(param);
      if (response.data) {
        this.callEvent({name: 'onSearch'});
      }
    },
    async onDeleteChgDegree() {
      if (this.maxDgree) {
        if (
          !(await this.$confirm({title: '차수삭제', message: `${this.currentDegree}차 도급내역을 삭제하시겠습니까?`}))
        ) {
          return;
        }

        try {
          const param = {chgDegree: this.masterInfo.chgDegree};
          const response = await deleteContContexecPlan(param);
          if (response.data) {
            this.callEvent({name: 'onInitSearch'});
          }
        } catch (ex) {
          this.$alert({title: '차수삭제', message: ex.message});
        }
      }
    },
    onPopChgDegree() {
      this.$modal.show('chgDegree');
    },
    async onPopConfirmChgDegree() {
      const param = {
        ...this.$refs.chgDegree.getData(),
      };
      const response = await insertContContexecPlan(param);
      if (response.data) {
        this.callEvent({name: 'onInitSearch'});
        this.$modal.hide('chgDegree');
      }
    },
    onPopOtherPrjCopy() {
      this.$modal.show('OtherPrjCopyPop');
    },

    onPopConfirmOtherPrjCopyPop() {
      this.callEvent({
        name: 'getFocusedRowByOtherPrjCopyPop',
        param: async row => {
          const param = {
            targetPrjCd: row.prjCd,
            targetChgDegree: row.chgDegree,
            matPriceRt: row.matPriceRt,
            labPriceRt: row.labPriceRt,
            oprPriceRt: row.oprPriceRt,
            thousandDn: row.thousandDn,
            tenThousandDn: row.tenThousandDn,
            tenThousandUp: row.tenThousandUp,
            chgDegree: this.maxDgree,
          };
          const response = await copyOtherPjtCont(param);
          if (response.data) {
            this.$modal.hide('OtherPrjCopyPop');
            this.callEvent({name: 'onSearch'});
          }
        },
      });
    },
    onPopConfirmUntpcMdacPop() {
      this.callEvent({
        name: 'UntpcMdacPop_popConfirm',
        param: async data => {
          if (
            !(await this.$confirm({
              title: '실행내역생성',
              message: `${this.maxDgree}차의 실행내역이 존재할경우 기존 실행내역은 모두 삭제가 되며, 새롭게 실행내역이 생성됩니다. <br/> 생성하시겠습니까?`,
            }))
          ) {
            return;
          }

          let param = {
            wcode: data.wcode,
            headCode: data.lgCode,
            midCode: data.mgCode,
            matPriceRt: data.matPriceRt,
            labPriceRt: data.labPriceRt,
            equipPriceRt: data.equipPriceRt,
            oprPriceRt: data.oprPriceRt,
            thousandDn: data.thousandDn,
            tenThousandDn: data.tenThousandDn,
            tenThousandUp: data.tenThousandUp,
            chgDegree: this.maxDgree,
          };

          const response = await saveContExecAsContCont(param);
          if (response.data) {
            this.$modal.hide('UntpcMdacPop');
          }
        },
      });
    },
  },
};
</script>
