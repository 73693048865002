<template>
  <pmis-page
    :search-project-visible="false"
    @search-click="onSearch"
    @new-click="onAdd"
    @save-click="onSave"
    @delete-click="onDelete"
  >
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="600px">
          <pmis-tab-box>
            <template v-slot:title>목록</template>
            <CustomerList @setCustomerInfo="onSetCustomerInfo" />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col min-width="1000px">
          <pmis-tab-box>
            <template v-slot:title>상세정보</template>
            <CustomerDetailInfo />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from '../store/customer.js';
import CustomerList from '../components/CustomerList.vue';
import CustomerDetailInfo from '../components/CustomerDetailInfo.vue';
export default {
  components: {
    CustomerList,
    CustomerDetailInfo,
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['detailInfo']);
    $mapMutations(this, ['initDetailInfo', 'setDetailInfo', 'initCompanyInfo']);
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'CustomerList_search'});
    },
    onSetCustomerInfo(row) {
      if (row == null) {
        this.onInit();
      } else {
        this.setDetailInfo({...row, isNew: false, isNewCompanyData: false, isCheckDuplicateBsnNo: false});

        if (this.detailInfo.vndrPgmCd) {
          this.callEvent({name: 'CustomerDetailInfo_SearchCompanyInfo'});
        }
      }
    },
    onInit() {
      this.initDetailInfo();
      this.initCompanyInfo();
    },
    onAdd() {
      let frmDs = '';
      const frmGb = this.userInfo.frmGb;
      if (frmGb == $getConstants('FRM_GB_2').code || frmGb == $getConstants('FRM_GB_3').code) {
        frmDs = $getConstants('FRM_DS_CONSTRUCTION').code;
      }
      this.onInit();
      this.setDetailInfo({
        pgmCd: this.pgmCd,
        frmGb: $getConstants('FRM_GB_1').code,
        frmDs: frmDs,
        nationCd: $getConstants('NATION_CD_KR').code,
        isNew: true,
        isNewCompanyData: false,
        isCheckDuplicateBsnNo: false,
      });
    },
    onSave() {
      this.callEvent({name: 'CustomerDetailInfo_Save'});
    },
    onDelete() {
      this.callEvent({name: 'CustomerList_delete'});
    },
  },
};
</script>

<style></style>
