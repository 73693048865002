<template>
  <div style="display:flex">
    <div><Left @onMenuId="onMenuId" /></div>
    <div><Main :menuId="menuId" /></div>
    <div><Right /></div>
  </div>
</template>

<script>
import Left from '@/homepage/Left';
import Main from '@/homepage/Main';
import Right from '@/homepage/Right';

export default {
  components: {
    Left,
    Main,
    Right,
  },
  data() {
    return {
      menuId: '1',
    };
  },
  methods: {
    onMenuId(menuId) {
      this.menuId = menuId;
    },
  },
};
</script>

<style></style>
