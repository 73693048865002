<template>
  <pmis-tab-box :loading="loading">
    <template v-slot:title>상세정보</template>
    <template v-slot:header-left>
      <label>계획년도</label>
      <div class="ml5"></div>
      <iui-datepicker name="reqYm" type="year" :value="year" @update:value="onYearChange($event)" />
      <i class="prev-arrow" @click.prevent.self @click="onClickArrow('PREVIOUS')" />
      <i class="post-arrow" @click.prevent.self @click="onClickArrow('NEXT')" />
      <label>도급금액</label>
      <iui-text type="amount" width="130px" :value="totalContAmt" :enable="false" />
      <label>실행금액</label>
      <iui-text type="amount" width="130px" :value="totalExecAmt" :enable="false" />
    </template>
    <template #header-right>
      금액단위(원)
    </template>
    <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" @loadSheet="sheet = $event" />
  </pmis-tab-box>
</template>

<script>
import options from './sheetOptions/executPxcondPlanSheet.js';
import {selectYearList, selectDetailList} from '@/view/workTypePxcondPlan/api/workTypePxcondPlan';

export default {
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      year: '',
      yearList: [],
      loading: false,
      totalContAmt: 0,
      totalExecAmt: 0,
      wCode: '',
      lgCode: '',
      mgCode: '',
    };
  },
  created() {
    this.addEvent([
      {name: 'getYearList', func: this.getYearList},
      {name: 'detailClear', func: this.detailClear},
    ]);
  },
  methods: {
    async getYearList(param) {
      this.setRequest(param.wCode, param.lgCode, param.mgCode);

      const request = this.getRequest();
      request.prjCd = this.prjCd;
      const response = await selectYearList(request);

      const array = [];
      response.data.forEach(x => {
        array.push(x.year);
      });
      this.yearList = array;

      if (array.length > 0) {
        this.year = array[0];
        this.onSearch();
      } else {
        this.year = '';
        this.loadSearchData = [];
      }
    },
    onYearChange(year) {
      this.year = year;
      if (year) {
        this.onSearch();
      }
    },
    onClickArrow(type) {
      const index = this.yearList.indexOf(this.year);

      if (index === -1) {
        this.year = this.yearList[this.yearList.length - 1];
        this.onSearch();
      } else {
        if ((type === 'PREVIOUS' && index === 0) || (type === 'NEXT' && index + 1 === this.yearList.length)) {
          const message = type === 'PREVIOUS' ? '이전 계획년도가 없습니다.' : '다음 계획년도가 없습니다.';
          this.$alert({title: '작업년월', message: message});
          return false;
        }

        this.year = type === 'PREVIOUS' ? this.yearList[index - 1] : this.yearList[index + 1];
        this.onSearch();
      }
    },
    getRequest() {
      return {
        wCode: this.wCode,
        lgCode: this.lgCode,
        mgCode: this.mgCode,
      };
    },
    setRequest(wCode, lgCode, mgCode) {
      this.wCode = wCode;
      this.lgCode = lgCode;
      this.mgCode = mgCode;
    },
    async onSearch() {
      this.loading = true;

      const request = this.getRequest();
      request.year = this.year;
      request.prjCd = this.prjCd;

      const response = await selectDetailList(request);
      this.loadSearchData = response.data.list;
      this.totalContAmt = response.data.totalContAmt;
      this.totalExecAmt = response.data.totalExecAmt;
      this.loading = false;
    },
    detailClear() {
      this.setRequest('', '', '');
      this.year = '';
      this.yearList = [];
      this.loadSearchData = [];
      this.totalContAmt = 0;
      this.totalExecAmt = 0;
    },
  },
};
</script>
