export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: false},
    Def: {
      Row: {NoColor: 2, CanFocus: 0},
    },
    Cols: [
      {
        Header: '명칭',
        Name: 'itemNm',
        RelWidth: 1,
        MinWidth: 80,
        TextStyle: 5,
        TextColor: '#FF0000',
        Cursor: 'Pointer',
      },
      {Header: '규격', Name: 'sizeNm', RelWidth: 1, MinWidth: 180},
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        Width: 70,
        CanEdit: false,
        Format: $getConstants('UNIT').code,
      },
      {Header: '수량', Name: 'qty', Width: 90, Extend: preset.quantity, CanEdit: false},
      {Header: '단가', Name: 'unitPrc', Width: 120, Extend: preset.unitAmount, CanEdit: true, DefaultValue: 0},
      {Header: '금액', Name: 'amt', Width: 140, Extend: preset.amount, CanEdit: false},
      {Header: '비고', Name: 'itemRmrk', Type: 'Text', Width: 100, CanEdit: false},
      {Name: 'itemCd', Visible: false},
      {Name: 'planQty', Visible: false},
      {Name: 'preQty', Visible: false},
      {Name: 'remQty', Visible: false},
    ],
  };
};
