<template>
  <div class="upload">
    <file-upload
      ref="upload"
      name="files"
      class="btn btn-primary"
      v-model="files"
      :headers="postHeader"
      :post-action="postAction"
      :accept="accept"
      :multiple="false"
      @input-filter="inputFilter"
      @input-file="inputFile"
      style="display: none;"
    >
    </file-upload>

    <div style="display: flex; align-items: center;">
      <span style="flex:1" v-if="isInput" @click="onClick" class="mr5">
        <iui-text :enable="false" :value="selectedFileName" />
      </span>
      <iui-button value="파일선택" @click="onClick" />
      <span v-if="selectedFileName && !isInput" class="ml5">{{ selectedFileName }}</span>
      <template v-else-if="flNo">
        <a href="#" @click="onDownload">{{ lclFlNm }}</a>
      </template>
    </div>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component';
import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import {getRefreshToken} from '@/common';

export default {
  mixins: [IuiBaseMixin, IuiLayoutMixin],
  components: {
    FileUpload: FileUpload,
  },
  props: {
    accept: {
      type: String,
      default: undefined,
    },
    save: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    flNo: {
      type: [String, Number],
      default: 0,
    },
    revId: {
      type: String,
      default: '',
    },
    svrFlNm: {
      type: String,
      default: '',
    },
    lclFlNm: {
      type: String,
      default: '',
    },
    postAction: {
      type: String,
      default: `${
        location.protocol.indexOf('https') !== -1 ? process.env.VUE_APP_FILE_URLS : process.env.VUE_APP_FILE_URL
      }/file/saveFile`,
    },
    param: {
      type: Object,
      default: () => {},
    },
    isInput: {
      type: Boolean,
      default: false,
    },
    temp: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      FILE_URL:
        location.protocol.indexOf('https') !== -1 ? process.env.VUE_APP_FILE_URLS : process.env.VUE_APP_FILE_URL,
      token: this.$store.getters['jwt/accessToken'],
      // accept: 'image/png,image/gif,image/jpeg,image/webp',
      files: [],
      selectedFileName: '',

      internalAction: {
        add: false,
        save: false,
        clear: false,
      },
    };
  },
  computed: {
    postHeader: {
      get: function() {
        return {Authorization: this.token};
      },
      set: function(token) {
        this.token = token;
      },
    },
  },
  methods: {
    inputFilter() {},
    async inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        newFile.data = {pgmCd: this.pgmCd, ...this.param, temp: this.temp};
        this.selectedFileName = newFile.name;
        this.$emit('change', newFile);
        this.$emit('update:changed', true);
        this.$emit('update:value', newFile);
      }
      if (newFile && oldFile) {
        // update
        this.selectedFileName = newFile.name;
        this.$emit('change', newFile);
        this.$emit('update:changed', true);
        this.$emit('update:value', newFile);
      }
      if (!newFile && oldFile) {
        // remove
      }

      if (this.internalAction.save) {
        if (newFile.success && !oldFile.success) {
          // success

          this.internalAction.save = false;
          this.$emit('update:changed', false);
          this.$emit('update:save', false);
          if (newFile.response.pmisError) {
            this.$emit('save-error', newFile.response.description || newFile.response.message, newFile.response.list);
          } else {
            this.$emit('save-complete', newFile.response.flNo, newFile.response);
          }
        }

        if (newFile.error && !oldFile.error) {
          if (
            newFile.error == 'denied' &&
            newFile.response.error_description &&
            newFile.response.error_description.indexOf('expired') != -1
          ) {
            this.postHeader = await getRefreshToken();
            newFile.headers.Authorization = this.postHeader.Authorization;
            this.$refs.upload.update(newFile, {active: true, error: '', progress: '0.00'});
          } else {
            // error
            this.internalAction.save = false;
            this.$emit('update:changed', false);
            this.$emit('update:save', false);
            this.$emit('save-error', newFile.error);
          }
        }
      }
    },
    onClick() {
      this.$refs.upload.$children[0].$el.click();
    },
    onSave() {
      this.$refs.upload.update(this.files[0].file, {active: true, error: '', progress: '0.00'});
      this.$refs.upload.active = true;
    },
    onClear() {
      this.files = [];
      this.selectedFileName = '';
      this.internalAction.clear = false;
      this.$emit('update:clear', false);
      this.$emit('update:changed', false);
    },
    async onDownload() {
      const ext = this.svrFlNm.substr(this.svrFlNm.lastIndexOf('.') + 1);
      let response = await axios.get(`/file/download?pgmCd=${this.pgmCd}&flNo=${this.flNo}&revId=${this.revId}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data], {type: $getMineType(ext)}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.lclFlNm);
      document.body.appendChild(link);
      link.click();
    },
  },
  watch: {
    save(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.internalAction.save = true;
          this.onSave();
        }
      }
    },
    clear(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.internalAction.clear = true;
          this.onClear();
        }
      }
    },
  },
};
</script>

<style></style>
