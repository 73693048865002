<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col width="350px">
        <pmis-tab-box no-border-box no-padding-box>
          <template v-slot:title>{{ title[type] }}분류</template>
          <pmis-content-box>
            <template v-slot:header-left>
              <iui-text type="search" name="searchText1" @change="setValue" @enter="onFocusGrid" />
              <iui-button value="검색" @click="onFocusGrid" />
            </template>
            <ib-sheet
              :uid="_uid"
              :options="Options1"
              :loadSearchData="loadSearchData1"
              :events="{
                onClick: onClick1,
                onSearchFinish: onSearchFinish1,
                onRenderFirstFinish: onSearch,
              }"
              @loadSheet="loadSheet1"
            />
          </pmis-content-box>
        </pmis-tab-box>
      </i-col>
      <i-spacer />
      <i-col>
        <iui-container-new type="css-flex">
          <i-row>
            <i-col>
              <pmis-tab-box no-border-box no-padding-box>
                <template v-slot:title>표준{{ title[type] }}정보</template>
                <template v-slot:title-right><iui-button value="선택" @click="onSelect"/></template>
                <pmis-content-box>
                  <template v-slot:header-left>
                    <iui-text type="search" name="searchText2" @change="setValue" @enter="onSearchCodeList(true)" />
                    <iui-button value="검색" @click="onSearchCodeList(true)" />
                  </template>
                  <ib-sheet
                    :uid="_uid"
                    :options="Options2"
                    :loadSearchData="loadSearchData2"
                    @loadSheet="loadSheet2"
                    :append="true"
                  />
                </pmis-content-box>
              </pmis-tab-box>
            </i-col>
          </i-row>
          <i-spacer />
          <i-row>
            <i-col>
              <pmis-tab-box no-border-box no-padding-box>
                <template v-slot:title>{{ base ? '복제' : '선택' }}{{ title[type] }}코드</template>
                <template v-slot:title-right>
                  <iui-button value="행삭제" @click="onDelRow" />
                </template>
                <pmis-content-box>
                  <ib-sheet
                    :uid="_uid"
                    :options="Options2"
                    :loadSearchData="loadSearchData3"
                    @loadSheet="loadSheet3"
                    :append="true"
                  />
                </pmis-content-box>
              </pmis-tab-box>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import {selectMaterialClList, selectMaterialCodeList} from '../matCodeRegist/api/MatCodeRegist.js';
import {selectLbrcClList, selectLbrcCodeList} from '../lbrcCodeRegist/api/LbrcCodeRegist.js';
import {selectEqutClList, selectEqutCodeList} from '../equipCodeRegist/api/EquipCodeRegist.js';
import {selectOprClList, selectOprCodeList} from '../oprCodeRegist/api/OprCodeRegist.js';
import options1 from './sheetOption/StandardCodePopupSheet1.js';
import options2 from './sheetOption/StandardCodePopupSheet2.js';
export default {
  props: {
    type: {
      type: String,
      default: 'mat',
    },
    base: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sheet1: undefined,
      sheet2: undefined,
      sheet3: undefined,

      loadSearchData1: [],
      loadSearchData2: [],
      loadSearchData3: [],
      searchText1: '',
      searchText2: '',
      keyInfoCommon: {level: '', code: ''},
      keyInfo: {
        mat: {
          lmCode: '',
          mmCode: '',
          smCode: '',
          ...this.keyInfoCommon,
        },
        equip: {
          leCode: '',
          meCode: '',
          ...this.keyInfoCommon,
        },
        lbrc: {
          llCode: '',
          mlCode: '',
          ...this.keyInfoCommon,
        },
        opr: {
          lpCode: '',
          mpCode: '',
          ...this.keyInfoCommon,
        },
      },
      isMat: this.type == 'mat',
      clUrl: {
        mat: selectMaterialClList,
        equip: selectEqutClList,
        lbrc: selectLbrcClList,
        opr: selectOprClList,
      },
      codeUrl: {
        mat: selectMaterialCodeList,
        equip: selectEqutCodeList,
        lbrc: selectLbrcCodeList,
        opr: selectOprCodeList,
      },
      code: {
        mat: {lcode: 'lmCode', mcode: 'mmCode', scode: 'smCode', code: 'matCode'},
        equip: {lcode: 'leCode', mcode: 'meCode', scode: '', code: 'equipCode'},
        lbrc: {lcode: 'llCode', mcode: 'mlCode', scode: '', code: 'laborCode'},
        opr: {lcode: 'lpCode', mcode: 'mpCode', scode: '', code: 'oprCode'},
      },
      sheetUtil1: undefined,
      sheetUtil2: undefined,
    };
  },
  computed: {
    title() {
      return {mat: '자재', equip: '장비', lbrc: '노무', opr: '경비'};
    },
    Options1() {
      return options1(this);
    },
    Options2() {
      return $addColCheckbox(options2(this));
    },
  },
  created() {
    this.addEvent({name: 'StandardCodePopup_confirm', func: this.onConfirm});
    Object.assign(this.keyInfo[this.type], this.keyInfoCommon);
  },
  methods: {
    loadSheet1(sheet) {
      this.sheet1 = sheet;
      this.sheetUtil1 = sheet.util;
    },
    loadSheet2(sheet) {
      this.sheet2 = sheet;
      this.sheetUtil2 = sheet.util;
      this.sheetUtil2.scrollPaging(this.onSearchCodeList);
    },
    loadSheet3(sheet) {
      this.sheet3 = sheet;
    },
    async onSearch() {
      let response = await this.clUrl[this.type]({base: this.base});
      let treeList = Array.from(response.data)[0];
      const code = treeList.code;
      treeList.code = '';
      treeList.Items = $_treeModel(
        Array.from(response.data).filter((e, i) => i != 0),
        code,
        'code',
        'upCode'
      );
      this.loadSearchData1 = new Array(treeList);
    },
    setValue(e) {
      this[e.target.name] = e.target.value;
      if (!e.target.value) {
        this.onFocusGrid();
      }
    },
    onFocusGrid() {
      if (this.searchText1) {
        this.sheetUtil1.treeSearch(['code', 'name'], this.searchText1);
        this.setKeyInfo(this.sheet1.getFocusedRow());
        this.onSearchCodeList();
      }
    },
    onClick1(e) {
      this.setKeyInfo(e.row);
      this.onSearchCodeList(true);
    },
    setKeyInfo(row) {
      for (const key in this.keyInfo[this.type]) {
        if (row && row.hasOwnProperty(key)) {
          this.keyInfo[this.type][key] = row[key];
        }
      }
    },
    initSheet2() {
      this.sheet2.removeAll();
    },
    async onSearchCodeList(init) {
      if (init) this.initSheet2();

      let param = {
        base: this.base,
        ...this.keyInfo[this.type],
        searchNm: this.searchText2,
        ...this.sheetUtil2.pagingData(),
      };

      let response = await this.codeUrl[this.type](param);
      this.loadSearchData2 = response.data;
    },
    async onConfirm(callback) {
      let checkedRows = this.sheet3.getDataRows();
      if (0 == checkedRows.length) {
        this.$alert({
          title: `${this.title[this.type]}표준코드`,
          message: `${this.title[this.type]}가 선택되지 않았습니다.`,
        });
        return;
      }

      if (this.base) {
        if (
          await this.$confirm({
            title: `${this.title[this.type]}표준코드`,
            message: `선택한 ${this.title[this.type]}코드들을 복제하시겠습니까?`,
          })
        ) {
          callback(checkedRows);
        }
      } else {
        callback(checkedRows);
      }
    },
    onSearchFinish1() {
      this.sheet1.showTreeLevel(2);
      this.setKeyInfo(this.sheet1.getFirstRow());
      this.onSearchCodeList();
    },
    onSelect() {
      let checkedRows = this.sheet2.getDataRows().filter(row => row.chk === 1);
      if (0 == checkedRows.length) {
        this.$alert({
          title: `${this.title[this.type]}표준코드`,
          message: `${this.title[this.type]}가 선택되지 않았습니다.`,
        });
        return;
      }

      let isDup = false;
      let dupRmCheckedRows = [];
      checkedRows.forEach(checkRow => {
        this.sheet3.getDataRows().some(row => {
          if (checkRow[this.code[this.type]['code']] == row[this.code[this.type]['code']]) {
            isDup = true;
            return true;
          }
        });
        if (isDup) {
          isDup = false;
          return false;
        } else {
          const row = {};
          row[this.code[this.type]['lcode']] = checkRow[this.code[this.type]['lcode']];
          row[this.code[this.type]['mcode']] = checkRow[this.code[this.type]['mcode']];
          row[this.code[this.type]['scode']] = checkRow[this.code[this.type]['scode']];
          row[this.code[this.type]['code']] = checkRow[this.code[this.type]['code']];
          row['level'] = checkRow.level;
          row['itemName'] = checkRow.itemName;
          row['size'] = checkRow.size;
          row['matStyle'] = checkRow.matStyle;
          row['unit'] = checkRow.unit;
          row['matGubun'] = checkRow.matGubun;
          row['flNo'] = checkRow.flNo;
          row['useDs'] = checkRow.useDs;

          dupRmCheckedRows.push(row);
        }
      });
      if (dupRmCheckedRows.length) {
        this.loadSearchData3 = dupRmCheckedRows;
      }
    },
    async onDelRow() {
      let checkedRows = this.sheet3.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({title: `행삭제`, message: `삭제할 행이 선택되지 않았습니다.`});
        return;
      }

      this.sheet3.removeRows(checkedRows);
    },
  },
};
</script>
