export const selectSubPrList = param => axios.post('/subPr/selectSubPrList', param);

export const selectSubPrInfo = prNo => axios.post('/subPr/selectSubPrInfo', {prNo});

export const selectSubPrItemList = prNo => axios.post('/subPr/selectSubPrItemList', {prNo});

export const selectSubPrApprovalInfos = prNo => axios.post('/subPr/selectSubPrApprovalInfos', {prNo});

export const deleteSubPrFlNo = prNo => axios.post('/subPr/deleteSubPrFlNo', {prNo});

/** 시행요청 저장 */
export const saveSubPrInfo = (saveInfo, afterValidation) => $save('/subPr/saveSubPrInfo', saveInfo, {afterValidation});
/** 시행요청 삭제 */
export const deleteSubPrInfo = prNo => axios.post('/subPr/deleteSubPrInfo', {prNo});
