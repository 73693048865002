export default that => {
  return {
    Cfg: {CanEdit: 0},
    Cols: [
      {
        Header: '사업자번호',
        Name: 'bsnNo',
        Type: 'Text',
        Align: 'center',
        Width: 105,
        CanEdit: 0,
        CustomFormat: 'SaupNo',
      },
      {Header: '업체명', Name: 'frmNm', Type: 'Text', Align: 'left', RelWidth: 1, MinWidth: 120, CanEdit: 0},
      {Header: '대표자명', Name: 'rpsvNm', Type: 'Text', Align: 'center', Width: 100, CanEdit: 0},
      {Header: '대표전화번호', Name: 'rpsvPhone', Type: 'Text', Align: 'center', Width: 100, CanEdit: 0},
      {Header: '거래처유형', Name: 'frmDs', Type: 'Text', Align: 'center', Width: 100, CanEdit: 0, Visible: 0},
      {Header: '거래처유형', Name: 'frmDsNm', Type: 'Text', Align: 'center', Width: 100, CanEdit: 0},
    ],
  };
};
