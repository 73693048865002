/** 입찰목록 */
export const selectRfqList = searchOptions => axios.post('/rfq/selectRfqList', searchOptions);
/** 입찰 종합정보 */
export const selectRfqInfos = rfqNo => axios.get(`/rfq/selectRfqInfos/${rfqNo}`);
/** 입찰정보 */
export const selectRfqInfo = rfqNo => axios.get(`/rfq/selectRfqInfo/${rfqNo}`);
/** 승인된 구매요청리스트 */
export const selectApprovedPrList = searchOptions => axios.post('/rfq/selectApprovedPrList', searchOptions);
/** 공고데이터 (from 구매요청정보) */
export const selectRfqByPrInfo = prNo => axios.get(`/rfq/selectRfqByPrInfo/${prNo}`);
/** 협력사 리스트 */
export const selectCustomerListForRfq = param => axios.post('/rfq/selectCustomerListForRfq', param);

/** 입찰정보 생성 */
export const createRfq = (param, afterValidation) => $save('/rfq/createRfq', param, {afterValidation});
/** 입찰정보 저장 */
export const saveRfq = (param, afterValidation) => $save('/rfq/saveRfq', param, {afterValidation});
/** 입찰정보 삭제 */
export const deleteRfq = rfqNo => axios.delete(`/rfq/deleteRfq/${rfqNo}`);
/** 업무 파일번호 저장 */
export const updateRfqFlNo = (rfqNo, flNo) => axios.post('/rfq/updateRfqFlNo', {rfqNo, flNo});

/** 입찰예정가 저장 */
export const saveBidPlanAmt = (rfqNo, lastBidRnd, encBidPlanAmt, certNo) =>
  axios.post('/rfq/saveBidPlanAmt', {rfqNo, lastBidRnd, encBidPlanAmt, certNo});
/** 현설결과등록 */
export const completeSpotDscr = param => $save('/rfq/completeSpotDscr', param);
/** 현설결과취소 */
export const cancelSpotDscr = rfqNo => axios.post('/rfq/cancelSpotDscr', {rfqNo});

/** 입찰공고 */
export const noticeRfq = (rfqNo, lastBidRnd) => axios.post('/rfq/noticeRfq', {rfqNo, lastBidRnd});
/** 입찰공고취소 */
export const cancelNoticeRfq = rfqNo => axios.post('/rfq/cancelNoticeRfq', {rfqNo});
/** 입찰 공고차수정보 */
export const selectRfqDegreeInfo = (rfqNo, lastBidRnd) => axios.post('/rfq/selectRfqDegreeInfo', {rfqNo, lastBidRnd});

export const extend = (rfqNo, extendYmd, extendHh, extendMm) =>
  $save('/rfq/extend', {rfqNo, extendYmd, extendHh, extendMm});

/** 입찰개봉 */
export const openQuot = rfqNo => axios.post('/rfq/openQuot', {rfqNo});
/** 재입찰 */
export const reBid = param => axios.post('/rfq/reBid', param);
/** 유찰 */
export const fail = (rfqNo, nonbidRsltRmrk) => axios.post('/rfq/fail', {rfqNo, nonbidRsltRmrk});

/** 낙찰사선정 */
export const choiceRfq = (rfqNo, cusCd, choiceNotiFlag, choiceRsltRmrk) =>
  axios.post('/rfq/choiceRfq', {rfqNo, cusCd, choiceNotiFlag, choiceRsltRmrk});
/** 견적제출 회사리스트 조회 */
export const selectQuotCompanyList = (rfqNo, bidRnd) => axios.post('/quot/selectQuotCompanyList', {rfqNo, bidRnd});
/** 견적대비표 */
export const selectRfqCompareInfos = (rfqNo, bidRnd) => axios.post('/rfq/selectRfqCompareInfos', {rfqNo, bidRnd});
/** 입찰공고품의 정보 조회 */
export const selectRfqApprovalInfos = rfqNo => axios.get(`/rfq/selectRfqApprovalInfos/${rfqNo}`);

/** 차수조회 */
export const selectDegreeInfos = (rfqNo, bidRnd) => axios.post('/rfq/selectDegreeInfos', {rfqNo, bidRnd});
/** 투찰정보조회 */
export const selectQuotInfos = (rfqNo, bidRnd, cusPgmCd) =>
  axios.post('/rfq/selectQuotInfos', {rfqNo, bidRnd, cusPgmCd});
/** 입찰취소 */
export const updateRfqCancelYn = (rfqNo, cancelYn) => axios.post('/rfq/updateRfqCancelYn', {rfqNo, cancelYn});

/** 현설마감, 입찰마감 (테스트용) */
export const updateExpireDtForTest = (rfqNo, lastBidRnd) =>
  axios.post('/rfq/updateExpireDtForTest', {rfqNo, lastBidRnd});
/** 현설일자변경 (테스트용) */
export const updateSpotDscrDtForTest = (rfqNo, lastBidRnd, spotDscrDt) =>
  axios.post('/rfq/updateSpotDscrDtForTest', {rfqNo, lastBidRnd, spotDscrDt});
