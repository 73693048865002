export default that => {
  return {
    Cfg: {
      CanEdit: 0,
      DataMerge: 1,
    },
    Cols: [
      {
        Header: ['NO'],
        Name: 'rownum',
        Type: 'Text',
        Align: 'center',
        Width: '50',
      },
      {
        Header: ['집행일자'],
        Name: 'workDt',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['실행중공종코드'],
        Name: 'midCode',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['실행내역코드'],
        Name: 'itemSeq',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['자원코드'],
        Name: 'itemCode',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['거래구분'],
        Name: 'accountGb',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['거래처'],
        Name: 'custCode',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['외주 계약번호'],
        Name: 'poNoSubc',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['공급가'],
        Name: 'amt',
        Type: 'Text',
        Align: 'Right',
        Width: 85,
        ColMerge: 0,
      },
      {
        Header: ['부가세액'],
        Name: 'vatAmt',
        Type: 'Text',
        Align: 'Right',
        Width: 85,
        ColMerge: 0,
      },
      {
        Header: ['비과세액'],
        Name: 'taxexe',
        Type: 'Text',
        Align: 'Right',
        Width: 85,
        ColMerge: 0,
      },
      {
        Header: ['적요'],
        Name: 'rmk',
        Type: 'Text',
        Align: 'Right',
        RelWidth: 1,
        ColMerge: 0,
      },
    ],
  };
};
