<template>
  <pmis-page
    ref="page"
    :search-project-visible="false"
    @search-click="onSearch"
    @new-click="onAdd"
    @save-click="onSave"
    @delete-click="onDelete"
  >
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px"><PrjList /></i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <pmis-tab-box>
                  <template v-slot:title>지시조치현황</template>
                  <WorkOrderSttusList />
                </pmis-tab-box>
              </i-col>
            </i-row>
            <i-spacer />
            <i-row height="260px">
              <i-col>
                <pmis-tab-box>
                  <template v-slot:title>상세정보</template>
                  <DetailInfo />
                </pmis-tab-box>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
/**
 * 지시조치사항등록
 * components :
 *   PrjList 현장목록
 *   WorkOrderSttusList 지시조치현황
 *   DetailInfo 상세정보
 * */
import WorkOrderSttusList from './components/WorkOrderSttusList.vue';
import DetailInfo from './components/DetailInfo.vue';
import PrjList from '@/view/Project/components/PrjList.vue';
import store from './store/WorkOrder.js';

export default {
  components: {PrjList, WorkOrderSttusList, DetailInfo},
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['searchInfo', 'detailInfo']);
    $mapMutations(this, ['setSearchInfo', 'setDetailInfo', 'initDetailInfo', 'setFocuskeycode', 'initFocuskeycode']);
  },
  created() {
    this.addFuncPrj(this.onSearch);
  },
  data() {
    return {
      checkboxItem: [{label: '종료계약포함', value: '1', isChecked: false}],
    };
  },
  methods: {
    onSearch() {
      this.initFocuskeycode();
      this.callEvent({name: 'WorkOrderSttusList_onSearch'});
    },
    onReSearch() {
      this.callEvent({name: 'WorkOrderSttusList_onSearch'});
    },
    onAdd() {
      if (!this.prjCd) {
        this.$alert({message: '선택된 현장이 없습니다.', title: '현장'});
        return;
      }
      this.initDetailInfo();
      this.setDetailInfo({
        seq: '',
        cud: 1,
        instDt: $_getCurrentDate(),
      });
    },
    async onSave() {
      if (!this.prjCd) {
        this.$alert({message: '선택된 현장이 없습니다.', title: '현장'});
        return;
      }
      const args = {
        url: '/workOrder/saveWorkOrderList',
        title: this.detailInfo.cud != 3 ? '저장' : '삭제',
        message: (this.detailInfo.cud != 3 ? '저장' : '삭제') + '하시겠습니까?',
        beforeValidator() {
          if (!this.param.actionDate) {
            this.param.actionName = '';
            this.param.cortFcts = '';
          }
          return true;
        },
        param: {
          ...this.detailInfo,
        },
      };

      const response = await this.$save(args);
      if (response && response.status == 200) {
        this.setSearchInfo({compSe: this.detailInfo.actionDate == '' ? '0' : '1'});
        this.setFocuskeycode({...response.data});
        this.onReSearch();
      }
    },
    onDelete() {
      if (!this.prjCd) {
        this.$alert({message: '선택된 현장이 없습니다.', title: '현장'});
        return;
      }
      if (this.detailInfo.actionDate != '') {
        this.$alert({title: '삭제', message: '조치완료된 지시사항은 삭제가 불가능 합니다.'});
        return;
      }

      this.setDetailInfo({cud: 3});
      this.onSave();
      // this.$confirm({title: '삭제', message: '삭제하시겠습니까?'}, () => {
      //   axios
      //     .post('/workOrder/deleteWorkOrderList', this.detailInfo)
      //     .then(response => {
      //       if (response.status == 200) {
      //         this.onSearch();
      //       } else {
      //         this.$alert({title: '삭제', message: '삭제를 실패하였습니다.'});
      //       }
      //     })
      //     .catch(ex => console.log('삭제를 실패하였습니다. : ', ex));
      // });
    },
  },
};
</script>

<style scoped></style>
