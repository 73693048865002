export default {
  AMOUNT_UNIT: {code: '0711', name: '금액단위'},
  FRM_GB: {code: '0279', name: '회사구분'},
  FRM_GB_0: {code: '000000', name: '이노가드'},
  FRM_GB_1: {code: '000001', name: '건설사'},
  FRM_GB_2: {code: '000002', name: '공사용역'},
  FRM_GB_3: {code: '000003', name: '물품자재'},
  FRM_GB_4: {code: '000004', name: '건설사+공사용역'},
  DEFAULT: {code: '000000', name: ''},
  Y: {code: '000001', name: '예'},
  N: {code: '000002', name: '아니오'},
  UNIT: {code: '0070', name: '단위'},
  UNIT_EXPRESSION: {code: '500060', name: '식'},
  USE_OIL: {code: '0105', name: '사용유류'},
  USE_OIL_GASOLINE: {code: '000001', name: '휘발유'},
  ACCOUNT_GB: {code: '0005', name: '거래구분'},
  BANK_CODE: {code: '0145', name: '은행코드'},
  NAT_DS: {code: '0272', name: '게시물구분'},
  NAT_DS_NOTICE: {code: '000001', name: '일반공지'},
  NAT_DS_RFQ: {code: '000002', name: '입찰공지'},
  NAT_DS_QNA: {code: '000003', name: '질의응답'},
  NAT_DS_FAQ: {code: '000004', name: 'FAQ'},
  PROJECT_PROGRESS_GB_IN_PROGRESS: {code: '000001', name: '진행'},
  PROJECT_PROGRESS_GB_DONE: {code: '000002', name: '완료'},
  PORTLET_GROUP: {code: '0661', name: '포틀릿그룹'},
  PORTLET_GROUP_0: {code: '000000', name: ''},
  PORTLET_GROUP_OPERATION: {code: '000001', name: '운영팀'},
  PORTLET_GROUP_HEAD_OFFICE: {code: '000002', name: '건설사본사'},
  PORTLET_GROUP_CONSTRUCTION_SITE: {code: '000003', name: '건설사현장'},
  PORTLET_GROUP_CONSTRUCTION_SERVICE: {code: '000004', name: '공사용역'},
  PORTLET_GROUP_MATERIAL: {code: '000005', name: '자재조달'},
  PORTLET_SIZE: {code: '0662', name: '포틀릿사이즈'},
  PORTLET_SIZE_A: {code: '000001', name: 'A'},
  PORTLET_SIZE_B: {code: '000002', name: 'B'},
  PORTLET_SIZE_C: {code: '000003', name: 'C'},
  PORTLET_SIZE_D: {code: '000004', name: 'D'},
  FL_DS_NORMAL: {code: '000000', name: '일반'},
  FL_DS_DOC: {code: '000008', name: '문서'},
  FL_DS_TMP: {code: '000010', name: '임시'},
  FL_DS_APP: {code: '000011', name: '전자결재'},
  FL_DS_REF: {code: '000012', name: '참조'},
  FL_DS_IMAGE: {code: '000100', name: '이미지 원본'},
  FL_DS_THUMBNAIL: {code: '000101', name: '썸네일'},
  FL_DS_CONTRACT_PDF: {code: '000400', name: '계약서 PDF파일'},
  COST_TYPE: {code: '0165', name: '자원구분'},
  COST_TYPE_M: {code: 'M', name: '자재'},
  COST_TYPE_L: {code: 'L', name: '노무'},
  COST_TYPE_E: {code: 'E', name: '장비'},
  COST_TYPE_A: {code: 'A', name: '경비'},
  COST_TYPE_I: {code: 'I', name: '일위대가'},
  COST_TYPE_T: {code: 'T', name: '산식'},
  INO_DS: {code: '0435', name: '내외부구분'},
  INO_DS_IN: {code: '000001', name: '대내시행'},
  INO_DS_OUT: {code: '000002', name: '대외시행'},
  DOC_CS_DS: {code: '0450', name: '문서분류구분'},
  DOC_CS_DS_IN_DEP: {code: '000001', name: '내부문서'},
  DOC_CS_DS_IN_CMP: {code: '000002', name: '대내시행문서'},
  DOC_CS_DS_IN_EXTERNAL: {code: '000003', name: '대외시행문서'},
  SNC_TP: {code: '0415', name: '결재유형'},
  SEARCH_GB: {code: '000001', name: '검색구분'},
  SEARCH_GB1: {code: '000001', name: '기안완료'},
  SEARCH_GB2: {code: '000002', name: '결재완료'},
  SEARCH_GB3: {code: '000005', name: '접수완료'},
  NOW_SNC_SS: {code: '0420', name: '결재상태'},
  RCV_SS_DS: {code: '0550', name: '수신상태구분'},
  DTS_NO: {code: '0278', name: '직책'},
  ROLL_NO: {code: '0389', name: '직급'},
  RNG_DS_IN_COMPANY: {code: '000001', name: '사내공지'},
  RNG_DS_CHOOSE_COMPANY: {code: '000002', name: '선택'},
  GUAR_KIND: {code: '0095', name: '보증종류'},
  GUAR_KIND_CONTRACT: {code: '000001', name: '계약'},
  GUAR_KIND_DEFECT: {code: '000002', name: '하자이행'},
  GUAR_KIND_ADVANCED: {code: '000003', name: '선급금'},
  ISSUE_DS: {code: '0490', name: '발급구분'},
  ISSUE_DS_NEW: {code: '000001', name: '신규발급'},
  ISSUE_DS_RE: {code: '000002', name: '재발급'},
  WORK_KIND: {code: '0080', name: '도급종류'},
  WORK_KIND_CONTCOMP: {code: '000001', name: '원도급'},
  WORK_KIND_SCPAY: {code: '000002', name: '하도급'},
  ORDER_GROUP: {code: '0085', name: '발주처구분'},
  ORDER_GROUP_PUBLIC: {code: '000001', name: '공공기관'},
  ORDER_GROUP_PRIVATE: {code: '000002', name: '민간기관'},
  FRM_DS: {code: '0015', name: '거래처유형'},
  FRM_DS_CONSTRUCTION: {code: '000001', name: '건설사'},
  FRM_DS_WORK_GROUP: {code: '000002', name: '공사용역'},
  FRM_DS_MAT: {code: '000003', name: '물품자재'},
  FRM_DS_CONSTRUCTION_AND_WORK_GROUP: {code: '000004', name: '건설사+공사용역'},
  FRM_DS_EQUIP: {code: '000005', name: '장비'},
  FRM_DS_OPR: {code: '000006', name: '경비'},
  FRM_DS_ORDER: {code: '000007', name: '발주처'},
  FRM_DS_CONT_COMP: {code: '000008', name: '원도급사'},
  FRM_DS_GUAR: {code: '000009', name: '보증보험사'},
  FRM_DS_ETC: {code: '000010', name: '기타'},
  PAY_COND_RMRK_CD: {code: '0365', name: '대금지급방법'},
  PAY_COND_RMRK_CD_DIRECT: {code: '000001', name: '발주처직접지급'},
  PAY_COND_RMRK_CD_CASH: {code: '000002', name: '현금,어음'},
  PAY_COND_RMRK_CD_RESERVATION: {code: '000003', name: '유보'},
  LABOR_DS_CD: {code: '0045', name: '근로자구분'},
  LABOR_DS1: {code: '000001', name: '정규직'},
  LABOR_DS2: {code: '000002', name: '상용직'},
  LABOR_DS3: {code: '000003', name: '일용직'},
  FLAW_INSR_YEAR: {code: '000001', name: '년'},
  FLAW_INSR_MONTH: {code: '000002', name: '월'},
  STAMP_TAX_ITEM1: {code: '000001', name: '없음'},
  STAMP_TAX_ITEM2: {code: '000002', name: '있음'},
  DEL_YN: {code: '0110', name: '삭제여부'},
  DEL_YN_Y: {code: '000001', name: '삭제'},
  PRJ_FLAG1: {code: '000001', name: '확정'},
  EMAIL_CD: {code: '0275', name: '이메일구분'},
  EMAIL_CD_DIRECT: {code: '000099', name: '직접입력'},
  USE_CLOSE_DS: {code: '0010', name: '거래종료여부'},
  USE_CLOSE_DS1: {code: '000001', name: '종료'},
  NATION_CD: {code: '0030', name: '국가코드'},
  NATION_CD_KR: {code: 'KR', name: '대한민국'},
  LABT_CLOSE: {code: '0050', name: '종료'},
  LABT_CLOSE_Y: {code: '000001', name: '근무종료'},
  UNIT_OPERT_CL: {code: '999901', name: '단위작업분류'},
  WORK_TYPE_CL: {code: '999902', name: '공종'},
  MAT_CL: {code: '999903', name: '자재분류'},
  EQUIP_CL: {code: '999904', name: '장비분류'},
  DATA_CL: {code: '999905', name: '자료분류'},
  BREAKDOWN_CL: {code: '999907', name: '일위대가'},
  LBRC_CL: {code: '999908', name: '노무분류'},
  OPR_CL: {code: '999909', name: '경비분류'},
  DECIMAL_DS1: {code: '000001', name: '버림'},
  EMPLOY_INSUR_DS_CD: {code: '0025', name: '고용보험적용제외여부'},
  EMPLOY_INSUR_DS: {code: '000001', name: '적용제외'},
  HEALTH_INSUR_DS_CD: {code: '0020', name: '건강보험적용제외여부'},
  HEALTH_INSUR_DS: {code: '000001', name: '적용제외'},
  HEALTH_INSUR_M_DS_CD: {code: '0175', name: '장기요양30%경감대상여부'},
  HEALTH_INSUR_M_DS: {code: '000001', name: '경감대상'},
  PENSION_DS_CD: {code: '0035', name: '국민연금적용제외여부'},
  PENSION_DS: {code: '000001', name: '적용제외'},
  DISASTER_INSUR_DS: {code: '000001', name: '적용제외'},
  F_EMPLOY_INSUR_DS: {code: '000001', name: '산출'},
  VISA_KIND: {code: '0245', name: '체류자격'},
  VISA_F2: {code: '000030', name: 'F-2(거주)'},
  VISA_F5: {code: '000033', name: 'F-5(영주)'},
  INVEST_KIND: {code: '0210', name: '정산여부(구분)'},
  INVEST_KIND_ROUTINE: {code: '000001', name: '정기정산'},
  INVEST_KIND_NOT_ROUTINE: {code: '000002', name: '비정기정산'},
  W_CODE: {code: '0200', name: '전문공종'},
  DOC_SEARCH_ITEM1: {code: '000001', name: '문서명칭'},
  DOC_SEARCH_ITEM2: {code: '000002', name: '등록자'},
  DOC_SEARCH_SE: {code: '000001', name: '문서검색조건'},
  CONFIRM_FLAG: {code: '0265', name: '확정Flag'},
  BID_STAT: {code: '0390', name: '입찰결과'},
  BID_STAT_SUCCESS: {code: '000001', name: '낙찰'},
  CO_YN: {code: '0028', name: '공동도급여부'},
  CO_YN_Y: {code: '000001', name: '단독'},
  CO_YN_N: {code: '000002', name: '공동'},
  SITE_AREA1_CD: {code: '0117', name: '공사지역(시도)'},
  SITE_AREA2_CD: {code: '0118', name: '공사지역(시군구)'},
  CODE_GB1: {code: '000001', name: '도급내역'},
  CODE_GB2: {code: '000002', name: '실행내역'},
  MAT_GIVE_Y: {code: '000001', name: '지급자재여부'},
  CON_SP_DS: {code: '0215', name: '종합전문구분'},
  USE_YN: {code: '0100', name: '사용여부'},
  USE_YN_Y: {code: '000001', name: '사용'},
  USE_YN_N: {code: '000002', name: '미사용'},
  AREA_F_DS_Y: {code: '000001', name: '대표지역'},
  AREA_F_DS_N: {code: '000002', name: ''},
  LG_CODE: {code: '0205', name: '전문세부공종'},
  CHG_GB_CHANGE: {code: '000001', name: '변경'},
  REQ_DS: {code: '0240', name: '기성구분'},
  REQ_DS_PXCOND: {code: '000001', name: '기성금'},
  REQ_DS_PREPAY: {code: '000002', name: '선급금'},
  TAX_READY: {code: '000001', name: '작성중'},
  TAX_CREATED: {code: '000002', name: '서명중(거래처)'},
  TAX_SIGNED: {code: '000003', name: '서명중(구매팀)'},
  USE_GB: {code: '0530', name: '사용구분'},
  USE_GB_PRIVATE: {code: '000001', name: '사유장비'},
  USE_GB_RENT: {code: '000002', name: '임대장비'},
  OIL_DEDUCT_DS: {code: '0140', name: '유류대공제여부'},
  IO_GB1: {code: '000001', name: '입고'},
  IO_GB2: {code: '000002', name: '출고'},
  BLN_DEP_PART_CD: {code: '0116', name: '소속구분'},
  BLN_DEP_PART_CD_COMPANY: {code: '000001', name: '자사'},
  BLN_DEP_PART_CD_CONSTRUCTION_SERVICE: {code: '000002', name: '공사용역'},
  BLN_DEP_PART_CD_LABOR: {code: '000003', name: '노무팀'},
  WAREHOUSING_SEARCH_ITEM1: {code: '000001', name: '현장명'},
  WAREHOUSING_SEARCH_ITEM2: {code: '000002', name: '계약번호'},
  WAREHOUSING_SEARCH_ITEM3: {code: '000003', name: '계약명'},
  CUSTOM_BILL_SEARCH_ITEM1: {code: '000001', name: '미생성'},
  CUSTOM_BILL_SEARCH_ITEM2: {code: '000002', name: '생성'},
  CUSTOM_BILL_SEARCH_ITEM3: {code: '000003', name: '전자서명'},
  CUSTOM_BILL_SEARCH_ITEM4: {code: '000004', name: '첨부'},
  STATE_BILL_READY: {code: '000040', name: '서명대기'},
  TAX_TYPE_EXIST: {code: '000001', name: '과세'},
  TAX_TYPE_FREE: {code: '000002', name: '비과세'},
  TAX_TYPE_DIRECT: {code: '000003', name: '직접입력'},
  MD_YN: {code: '0555', name: '수정가능여부'},
  DOC_TP1: {code: '000001', name: '기안양식'},
  DOC_TP2: {code: '000002', name: '표준양식'},
  COMPLT_DS_Y: {code: '000001', name: '미완료'},
  COMPLT_DS_N: {code: '000002', name: '완료'},
  SCTM_SLCODE_YN: {code: '0257', name: '하위분류유무'},
  BONUS_GB: {code: '0112', name: '상여금유무'},
  BONUS_GB_Y: {code: '000001', name: '있음'},
  SALARY_METHOD: {code: '0227', name: '지급방법구분'},
  SALARY_METHOD_DIRECT: {code: '000001', name: '직접지급'},
  SALARY_METHOD_BANK: {code: '000002', name: '통장입금'},
  ETC_GB: {code: '0276', name: '기타급여유무'},
  ETC_GB_Y: {code: '000001', name: '있음'},
  PROCESS_GB: {code: '0282', name: '진행상태구분'},
  PROCESS_GB_NONE: {code: '000000', name: '미작성'},
  PROCESS_GB_WRITING: {code: '000001', name: '작성중'},
  PROCESS_GB_SEND: {code: '000002', name: '송부'},
  PROCESS_GB_WORKER_SIGNED: {code: '000003', name: '근로자서명'},
  PROCESS_GB_COMPANY_SIGNED: {code: '000004', name: '회사서명'},
  LABOR_SEARCH_GB0: {code: '000000', name: ''},
  LABOR_SEARCH_GB1: {code: '000001', name: ''},
  LABOR_SEARCH_GB2: {code: '000002', name: ''},
  LABOR_SEARCH_GB3: {code: '000003', name: ''},
  LABOR_SEARCH_GB4: {code: '000004', name: ''},
  RISK: {code: '0135', name: '위험도'},
  LABOR_LARGE_CODE_JOB: {code: 'L00101', name: '공사직종'},
  LABOR_MID_CODE_NORMAL: {code: 'L0010101', name: '일반공사 직종'},
  HANDICAP_KIND: {code: '0190', name: '장애유형'},
  USE_MST: {code: '0274', name: '시스템관리자여부'},
  USE_MST_Y: {code: '000001', name: '관리자'},
  PRICE_L_GB: {code: '0060', name: '노무단가구분'},
  PRICE_L_GB_DAY: {code: '000001', name: '일당'},
  PRICE_L_GB_MONTH: {code: '000002', name: '월당'},
  BIDDING_ST: {code: '0560', name: '진행상태'},
  TAX_TYPE_CD: {code: '0290', name: '과세유형'},
  TAX_TYPE_CD_EXIST: {code: '000001', name: '과세'},
  TAX_TYPE_CD_NONE: {code: '000002', name: '비과세'},
  TAX_TYPE_CD_DIRECT: {code: '000003', name: '직접입력'},
  TAX_DIRECT_DS: {code: '0291', name: '부가세 직접입력 유형'},
  DLV_COND_RMRK_CD: {code: '0345', name: '납품조건 코드'},
  DLV_COND_RMRK_CD_CONSTRUCTION: {code: '000006', name: '공사'},
  DLV_COND_RMRK_CD_SERVICE: {code: '000007', name: '용역'},
  PO_TYPE_CD: {code: '0340', name: '계약서구분'},
  PO_TYPE_CD_ORDER: {code: '000003', name: '발주서'},
  PO_TYPE_CD_SCPAY: {code: '000004', name: '건설공사표준하도급계약'},
  LAB_ITEM1: {code: '000001', name: '표시안함'},
  SCPAY_INSR_INC_ITEM1: {code: '000001', name: '계약서에 항목 표시 안함 )'},
  SPOT_DSCR_YN: {code: '0310', name: '현설여부'},
  CUSTOMER_CHOICE_SEARCH_ITEM1: {code: '000001', name: '협력사명'},
  CUSTOMER_CHOICE_SEARCH_ITEM2: {code: '000002', name: '사업자번호'},
  CUSTOMER_CHOICE_SEARCH_ITEM3: {code: '000003', name: '담당자명'},
  CUSTOMER_CHOICE_SEARCH_ITEM4: {code: '000004', name: '대표자명'},
  TAX_DS: {code: '0115', name: '소득세산출방식'},
  DECIMAL_DS: {code: '0150', name: '일노무비소숫점관리방법'},
  F_EMPLOY_INSUR_DS_CD: {code: '0125', name: '외국인고용보험산출여부'},
  W_BUSINESS: {code: '0075', name: '담당업무'},
  WEBPAGE_CLS: {code: '999910', name: '웹페이지분류'},
  SYSTEM_MANAGEMENT: {code: 'SYS0000', name: '시스템관리'},
  OPERATION_MANAGEMENT: {code: 'SYS0400', name: '운영관리'},
  MENU_SETTING: {code: 'SYS0407', name: '메뉴설정'},
  EXTERNAL_N: {code: '000001', name: '없음'},
  EXTERNAL_Y: {code: '000002', name: '있음'},
  RSLT_SUCCESS: {code: '1000', name: '성공'},
  RSLT_FAIL: {code: '000001', name: '실패'},
  EXPECTED_Y: {code: '000001', name: '계획'},
  EXPECTED_N: {code: '000002', name: '실적'},
};
