<template>
  <form onsubmit="return false;">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>지시내용</template>
            <iui-container-new type="table" theme="bullet">
              <colgroup>
                <col width="80px" />
                <col />
                <col width="80px" />
                <col />
              </colgroup>
              <i-row>
                <i-col-header required>지시일자</i-col-header>
                <i-col colspan="3">
                  <iui-datepicker
                    name="instDt"
                    :value="detailInfo.instDt"
                    @change="onDetailInfoChange('instDt', $event)"
                    :enable="isReadonlyInstDt"
                    required
                    :errorMessage="{title: '지시일자', message: '지시일자는 필수입력입니다'}"
                    :isIconClear="false"
                  />
                  <iui-text
                    name="seq"
                    prefix="-"
                    :value="detailInfo.seq"
                    :bindArray="bindArray"
                    :enable="false"
                    width="33px"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>지시자</i-col-header>
                <i-col>
                  <iui-text
                    name="instName"
                    :value="detailInfo.instName"
                    :bindArray="bindArray"
                    :enable="isEnableInstructions"
                    max-length="50"
                    required
                    :errorMessage="{title: '지시자', message: '지시자는 필수입력입니다'}"
                    width="128px"
                  />
                </i-col>
                <i-col-header>처리기한</i-col-header>
                <i-col>
                  <iui-datepicker
                    name="prcDate"
                    :value="detailInfo.prcDate"
                    @change="onDetailInfoChange('prcDate', $event)"
                    :enable="isEnableInstructions"
                    :isIconClear="false"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header rowspan="2" required>지시내용</i-col-header>
                <i-col>
                  <pmis-file-list
                    name="flNo"
                    id="file0"
                    flNoCol="flNo1"
                    title="첨부파일"
                    title-visible
                    :save-auth="isEnableInstructions"
                    :file-number.sync="detailInfo.flNo1"
                    :modal="true"
                    @delete-complete="onDeleteComplete('flNo1', $event)"
                  />
                  <span class="ml10">등록 파일수 : {{ detailInfo.flCnt1 }}</span>
                </i-col>
              </i-row>
              <i-row height="95px">
                <i-col colspan="3">
                  <iui-text
                    type="multi"
                    name="instCts"
                    :value="detailInfo.instCts"
                    :bindArray="bindArray"
                    :enable="isEnableInstructions"
                    required
                    :errorMessage="{title: '지시내용', message: '지시내용은 필수입력입니다'}"
                  />
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-content-box>
            <template v-slot:title>조치내용</template>
            <iui-container-new type="table" theme="bullet">
              <colgroup>
                <col width="80px" />
                <col />
                <col width="80px" />
                <col />
              </colgroup>
              <i-row>
                <i-col-header>조치일자</i-col-header>
                <i-col colspan="3">
                  <iui-datepicker
                    name="actionDate"
                    :value="detailInfo.actionDate"
                    @change="onDetailInfoChange('actionDate', $event)"
                    :enable="isEnableAction"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>조치자</i-col-header>
                <i-col>
                  <iui-text
                    name="actionName"
                    :value="detailInfo.actionName"
                    :bindArray="bindArray"
                    :enable="isEnableAction"
                    max-length="50"
                    width="128px"
                  />
                </i-col>
                <i-col></i-col>
                <i-col></i-col>
              </i-row>
              <i-row>
                <i-col-header rowspan="2">조치내용</i-col-header>
                <i-col>
                  <pmis-file-list
                    name="flNo2"
                    id="file2"
                    flNoCol="flNo2"
                    title="첨부파일"
                    title-visible
                    :save-auth="detailInfo.cud != 1"
                    :file-number.sync="detailInfo.flNo2"
                    :modal="true"
                    :modalBtnEnable="isEnableAction"
                    @delete-complete="onDeleteComplete('flNo2', $event)"
                  />
                  <span class="ml10">등록 파일수 : {{ detailInfo.flCnt2 }}</span>
                </i-col>
              </i-row>
              <i-row height="95px">
                <i-col colspan="3">
                  <iui-text
                    type="multi"
                    name="cortFcts"
                    :value="detailInfo.cortFcts"
                    :bindArray="bindArray"
                    :enable="isEnableAction"
                  />
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </form>
</template>

<script>
/**
 * 지시조치사항등록
 * */
import {saveWorkOrderList} from '@/view/Cooperation/Order/WorkOrder/api/workOrder.js';

export default {
  data: function() {
    return {
      bindArray: ['setDetailInfo'],
      modalBtns: [{name: '확인', callback: this.onHideModal}],
      modalNm: '',
      modalTitle: '',
      pFlNo: '',
    };
  },
  computed: {
    isReadonlyInstDt() {
      return this.detailInfo.cud == 1;
    },
    isEnableInstructions() {
      return this.detailInfo.cud == 1 || this.detailInfo.canEditInstructions;
    },
    isEnableAction() {
      return this.detailInfo.cud != 1;
    },
  },
  beforeCreate() {
    $mapGetters(this, ['detailInfo']);
    $mapMutations(this, ['setDetailInfo']);
  },
  methods: {
    onDetailInfoChange(k, v) {
      let payload = {};
      if ((k == 'instDt' || k == 'actionDate') && v != '') {
        if ($_bNowdateOverCheck(v)) {
          payload[k] = v;
        } else {
          this.$alert({
            title: `${k == 'instDt' ? '지시일자' : '조치일자'}`,
            message: '금일 이후 일자는 등록할 수 없습니다.',
          });
        }
      } else if (k == 'prcDate') {
        if (this.detailInfo.instDt && v && this.detailInfo.instDt > v) {
          this.$alert({title: '처리기한', message: '처리기한은 지시일자 이후 일자를<br/>등록하십시오.'});
        } else {
          let date = this.dataCheck(this.detailInfo.instDt, v);
          payload[k] = date;
        }
      } else {
        payload[k] = v;
      }
      this.setDetailInfo(payload);
    },
    dataCheck(d1, d2) {
      //지시일 이전일자 이면 지시일반환, 아니면 입력일 반환
      let chkDt1 = new Date(d1.substr(0, 4), d1.substr(4, 2) - 1, d1.substr(6, 2));
      let chkDt2 = new Date(d2.substr(0, 4), d2.substr(4, 2) - 1, d2.substr(6, 2));

      if (chkDt1 > chkDt2) {
        return d1;
      } else {
        return d2;
      }
    },
    onDeleteComplete(target, flNo) {
      if (flNo == 0) {
        this.detailInfo[target] = flNo;
        saveWorkOrderList(this.detailInfo).then(response => {
          this.callEvent({name: 'WorkOrderSttusList_onSearch'});
        });
      }
    },
  },
};
</script>

<style scoped></style>
