export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 0},
    Def: {Row: {NoColor: 2, CanFocus: 0}},
    Cols: [
      {Header: '구분', Name: 'rowName', Type: 'Text', RelWidth: 1, Align: 'center'},
      {Header: '공급가', Name: 'wamt', RelWidth: 1, Extend: preset.amount},
      {Header: '부가세', Name: 'wvatAmt', RelWidth: 1, Extend: preset.amount},
      {Header: '비과세', Name: 'wtaxexe', RelWidth: 1, Extend: preset.amount},
      {Header: '합계', Name: 'wsumAmt', RelWidth: 1, Extend: preset.amount},
    ],
  };
};
