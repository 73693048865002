var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],class:_vm.rootClass,style:(_vm.rootStyle)},[(_vm.label)?_c('label',{staticClass:"label",style:(_vm.labelStyle),attrs:{"id":_vm.labelId,"for":_vm.comboBoxId}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.prefix)?_c('span',{staticClass:"prefix",style:(_vm.prefixStyle),attrs:{"id":_vm.prefixId}},[_vm._v(_vm._s(_vm.prefix))]):_vm._e(),_c('v-select',{ref:"vSelect",class:("iui__select " + _vm.comboBoxClass),style:(_vm.comboBoxStyle),attrs:{"id":_vm.comboBoxId,"reduce":function (item) { return item.code; },"multiple":_vm.multiple,"disabled":!_vm.enable,"clearable":false,"name":_vm.name,"required":_vm.required,"data-error-title":_vm.errTitle,"data-error-message":_vm.errMessage,"data-value":_vm.dataValue,"options":_vm.options,"selectable":_vm.checkSelectable,"placeholder":_vm.placeholder,"searchable":!_vm.readonly,"dropdown-should-open":function (ref) {
        var noDrop = ref.noDrop;
        var open = ref.open;
        var loading = ref.loading;

        if (_vm.readonly) {
          return !_vm.readonly;
        }

        return noDrop ? false : open && !loading;
      },"append-to-body":true,"calculate-position":_vm.calculate,"tabindex":3},on:{"option:selected":_vm.change,"search":function (v, loading) { return _vm.onSearch(v); }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.noOptionsText)+" ")]},proxy:true}]),model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}}),(_vm.suffix)?_c('span',{staticClass:"suffix",style:(_vm.suffixStyle),attrs:{"id":_vm.suffixId}},[_vm._v(_vm._s(_vm.suffix))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }