export default that => {
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      CanEdit: true, // 1: 전체 편집 가능 (default)
      CanSort: false,
    },
    Cols: [
      {
        Header: '기본정보',
        Name: 'cmnDNm',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CanEdit: false,
      },
      {
        Header: '최초복제일시',
        Name: 'firstRgsDttm',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CanEdit: false,
      },
      {
        Header: '최종복제일시',
        Name: 'lastRgsDttm',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CanEdit: false,
      },
      {Name: 'cmnDCd', Visible: false},
      {Name: 'pgmCd', Visible: false},
      {Name: 'frmGb', Visible: false},
    ],
  };
};
