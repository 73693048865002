export default that => {
  return {
    Cfg: {
      CanEdit: false,
      HeaderMerge: 2,
    },
    Def: {Row: {CanFocus: 0}},
    Cols: [
      {Name: 'prjNm', Header: '현장명', RelWidth: 1, MinWidth: 200, Type: 'Text'},
      {Name: 'instCts', Header: '지시내용', Width: 350, Type: 'Text'},
      {
        Name: 'instDt',
        Header: '지시일자',
        Extend: that.$ibsheetHelper.preset.date,
      },
      {Name: 'instName', Header: '지시자', Width: 70, Type: 'Text', Align: 'Center'},
      {
        Name: 'prcDate',
        Header: '처리기한',
        Extend: that.$ibsheetHelper.preset.date,
      },
      {
        Name: 'actionDate',
        Header: '조치일자',
        Extend: that.$ibsheetHelper.preset.date,
      },
      {
        Name: 'cortFcts',
        Header: '조치내용',
        Width: 350,
        Type: 'Text',
      },
      {
        Name: 'actionName',
        Header: '조치자',
        Width: 70,
        Type: 'Text',
        Align: 'Center',
      },
    ],
  };
};
