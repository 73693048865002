import {selectQuotCompanyList} from '@/view/bidding/rfq/mat/api/rfq.js';

export default async that => {
  const preset = that.$ibsheetHelper.preset;
  const Cols = [];

  const compareCusList = (await selectQuotCompanyList(that.rfqNo, that.bidRnd)).data;
  const cusNmList = compareCusList.map(cus => cus['cusNm']);

  cusNmList.forEach((cusNm, i) => {
    Cols.push(
      {Header: [cusNm, '단가'], Name: `unitPrc_${i + 1}`, Width: 80, Extend: preset.unitAmount},
      {Header: [cusNm, '금액'], Name: `amt_${i + 1}`, Width: 100, Extend: preset.amount}
    );
  });

  Cols.push({Header: ['비고', '비고'], Name: 'itemRmrk', Width: 120});

  return {
    Cfg: {CanEdit: 0, HeaderMerge: 5},
    Def: {Row: {NoColor: 2, CanFocus: 0}},
    LeftCols: [
      {
        Header: ['명칭', '명칭'],
        Name: 'itemNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
        TextStyle: 5,
        TextColor: '#FF0000',
        Cursor: 'Pointer',
      },
      {Header: ['규격', '규격'], Name: 'sizeNm', Type: 'Text', RelWidth: 1, MinWidth: 120},
      {
        Header: ['단위', '단위'],
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        Width: 60,
        Format: $getConstants('UNIT').code,
      },
      {Header: ['수량', '수량'], Name: 'qty', Width: 80, Extend: preset.quantity},
      {Header: ['실행내역', '단가'], Name: 'planUnitPrc', Width: 80, Extend: preset.unitAmount},
      {Header: ['실행내역', '금액'], Name: 'planAmt', Width: 100, Extend: preset.amount},

      {Name: 'itemCd', Visible: false},
    ],
    Cols,
  };
};
