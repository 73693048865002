<template>
  <iui-container-new type="css-flex">
    <i-row height="155px">
      <i-col>
        <PoContSeqList :poNo="poNo" :contSeq.sync="contSeq" />
      </i-col>
    </i-row>
    <i-spacer />
    <i-row>
      <i-col>
        <PoInfo :poNo="poNo" :contSeq="contSeq" />
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import PoContSeqList from './PoContSeqList.vue';
import PoInfo from './PoInfo.vue';
export default {
  components: {PoContSeqList, PoInfo},
  props: {
    poNo: {type: String, default: ''},
  },
  data() {
    return {contSeq: ''};
  },
};
</script>

<style></style>
