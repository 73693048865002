<template>
  <pmis-content-box>
    <template #title>기본정보</template>
    <iui-container-new type="table" theme="bullet">
      <colgroup>
        <col width="90px" />
        <col width="100%" />
        <col width="10px" />
        <col width="80px" />
        <col width="60%" />
        <col width="10px" />
        <col width="80px" />
        <col width="30%" />
      </colgroup>
      <i-row>
        <i-col-header>현장</i-col-header>
        <i-col>
          <iui-text :value="poInfo.spotDeptcd" :enable="false" width="100px" />
          <iui-text :value="poInfo.spotDeptnm" :enable="false" />
        </i-col>
        <i-spacer />
        <i-col-header>계약자</i-col-header>
        <i-col>
          <iui-text :value="poInfo.cusCd" :enable="false" width="100px" />
          <iui-text :value="poInfo.cusNm" :enable="false" />
        </i-col>
        <i-spacer />
        <i-col-header>계약구분</i-col-header>
        <i-col>
          <iui-text :value="poInfo.poTypenm" :enable="false" width="100px" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>계약번호</i-col-header>
        <i-col>
          <iui-text :value="poInfo.poNo2" :enable="false" width="100px" />
          <iui-text :value="poInfo.ttl" :enable="false" />
        </i-col>
        <i-spacer />
        <i-col-header>납품장소</i-col-header>
        <i-col colspan="4">
          <iui-text :value="poInfo.dlvLoc" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>계약내역</i-col-header>
        <i-col :rowspan="poItemListRowspan" colspan="7">
          <PoItemList :poInfo="poInfo" :poItemList="poItemList" />
        </i-col>
      </i-row>
      <i-row v-for="i in poItemListRowspan - 1" :key="i">
        <i-col />
      </i-row>
      <i-row>
        <i-col-header>계약일자</i-col-header>
        <i-col>
          <iui-datepicker :value="poInfo.poDt" :enable="false" />
        </i-col>
      </i-row>
    </iui-container-new>

    <!-- 당초계약 -->
    <PoFirstContSeq :poInfo="poInfo" :poStatus="poStatus" v-if="!poStatus.isChangeContSeq" />
    <!-- 변경계약 -->
    <PoChangeContSeq :poInfo="poInfo" :poStatus="poStatus" v-else />

    <iui-container-new type="table" theme="bullet">
      <i-row>
        <i-col-header>계약이행</i-col-header>
        <i-col>
          <iui-text
            type="rate"
            prefix="보증율 계약금액의"
            suffix="%"
            width="185px"
            :value="poInfo.prfmInsrRate"
            :enable="false"
          />
          <iui-text type="amount" prefix="보증금" width="160px" :value="poInfo.prfmInsrAmt" :enable="false" />
        </i-col>
        <i-spacer />
        <i-col-header>지체상금율</i-col-header>
        <i-col>
          <iui-radio-group :items="delayRateItems" :value="poInfo.delayRateYn" :enable="false" />
          <iui-text
            v-if="poStatus.isDelayRate"
            type="rate"
            suffix="%"
            width="80px"
            :value="poInfo.delayRate"
            :enable="false"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>하자보수</i-col-header>
        <i-col colspan="4">
          <iui-text
            type="rate"
            prefix="보증율 계약금액의"
            suffix="%"
            width="185px"
            :value="poInfo.flawInsrRate"
            :enable="false"
          />
          <iui-text type="amount" prefix="보증금" width="160px" :value="poInfo.flawInsrAmt" :enable="false" />
          <label class="ml10 mr10">책임기간</label>
          <iui-radio-group :items="flawInsrItems1" :value="poInfo.flawInsrYn" :enable="false" />
          <iui-text
            v-if="poStatus.isFlawInsrYear"
            type="number"
            width="40px"
            :value="poInfo.flawInsrYear"
            :enable="false"
          />
          <iui-radio-group class="ml20" :items="flawInsrItems2" :value="poInfo.flawInsrYn" :enable="false" />
          <template v-if="!poStatus.isFlawInsrYear">
            <iui-datepicker :value="poInfo.flawInsrFrDt" :enable="false" />
            <label>~</label>
            <iui-datepicker :value="poInfo.flawInsrToDt" :enable="false" />
          </template>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import {numToKr} from 'vue-number-to-kor';
import {PO_COLUMNS} from '@/view/bidding/js/BiddingColumns.js';
import {selectPoInfos} from '@/view/bidding/po/mat/api/po.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import PoInputMixin from '@/view/bidding/js/poInputMixin.js';

import PoItemList from './PoItemList.vue';
import PoFirstContSeq from './PoFirstContSeq.vue';
import PoChangeContSeq from './PoChangeContSeq.vue';

export default {
  components: {PoItemList, PoFirstContSeq, PoChangeContSeq},
  mixins: [PoInputMixin],
  props: {
    poNo: {type: String, default: ''},
    contSeq: {type: String, default: ''},
  },
  data() {
    return {
      poItemListRowspan: 6,
      poInfo: {...PO_COLUMNS},
      poItemList: [],
    };
  },
  computed: {
    poStatus() {
      const poInfo = this.poInfo;
      const BIDDING_CODES = BIDDING_CONSTANTS.CODES;
      const status = {
        isChangeContSeq: poInfo.contSeq > BIDDING_CODES.FIRST_CONT_SEQ,
        isPotypeUnit: poInfo.poTypecd === BIDDING_CODES.POTYPE_UNIT,
        isPayCash: poInfo.payCashYn === BIDDING_CODES.PAY_CASH,
        isPayDefer: poInfo.payCashYn === BIDDING_CODES.PAY_DEFER,
        isTaxDirect: poInfo.taxTypecd === BIDDING_CODES.TAX_DIRECT,
        isStamp: poInfo.stampTaxYn === BIDDING_CODES.STAMP_TAX_Y,
        isFlawInsrYear: poInfo.flawInsrYn === BIDDING_CODES.FLAW_INSR_YEAR,
      };
      return status;
    },
  },
  watch: {
    contSeq(v) {
      if (this.poNo && v) {
        this.onSearch();
      }
    },
  },
  methods: {
    async onSearch() {
      this.poInfo = {...PO_COLUMNS};

      const response = await selectPoInfos(this.poNo, this.contSeq);
      this.setPoInfo(response.data.poInfo);
      this.poItemList = response.data.poItemList;
    },
    setPoInfo(poInfo) {
      for (const key in this.poInfo) {
        if (poInfo && poInfo.hasOwnProperty(key)) {
          this.poInfo[key] = poInfo[key];
        }
      }
      this.poInfo.poNo2 = COMMON_FUNCTION.getMakeFormat(poInfo.poNo);
      this.poInfo.amtKor = poInfo.poAmt ? numToKr(poInfo.poAmt.toFixed(0)) : numToKr(0);
      this.poInfo.vatAmtKor = poInfo.vatAmt ? numToKr(poInfo.vatAmt.toFixed(0)) : numToKr(0);
      this.poInfo.totAmtKor = poInfo.totAmt ? numToKr(poInfo.totAmt.toFixed(0)) : numToKr(0);
      this.poInfo.beforePoAmtKor = poInfo.beforePoAmt ? numToKr(poInfo.beforePoAmt.toFixed(0)) : numToKr(0);
      this.poInfo.beforeVatAmtKor = poInfo.beforeVatAmt ? numToKr(poInfo.beforeVatAmt.toFixed(0)) : numToKr(0);
      this.poInfo.beforeTotAmtKor = poInfo.beforeTotAmt ? numToKr(poInfo.beforeTotAmt.toFixed(0)) : numToKr(0);
    },
  },
};
</script>

<style></style>
