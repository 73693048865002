<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col>
        <pmis-content-box>
          <template v-slot:title>발송정보</template>
          <iui-container-new type="table" theme="bullet" header-width="95px">
            <i-row>
              <i-col-header required>업무구분</i-col-header>
              <i-col>
                <iui-select
                  width="110px"
                  :value="bizType"
                  :items="bizTypeList"
                  @change="value => (bizType = value)"
                  required
                  :error-message="{title: '업무구분', message: '업무구을 선택해주세요.'}"
                />
              </i-col>
            </i-row>
          </iui-container-new>
          <iui-container-new type="css-flex" height="520px">
            <i-row>
              <i-col>
                <pmis-tab-box>
                  <template #title>근로자목록</template>
                  <pmis-content-box>
                    <template #header-left>
                      <iui-text type="search" :value.sync="searchText" @enter="onSearch" />
                      <iui-button value="검색" @click="onSearch" />
                    </template>
                    <ib-sheet
                      :uid="_uid"
                      :options="sheetOpt"
                      :loadSearchData="loadSearchData"
                      @loadSheet="sheet = $event"
                    />
                  </pmis-content-box>
                </pmis-tab-box>
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import {selectDirectBizType, selectWorkerList, sendDirect} from '@/view/sysManage/notification/api/notification';
import options from './sheetOption/workerSheet.js';

export default {
  data() {
    return {
      sheetOpt: $addColCheckbox(options(this)),
      loadSearchData: [],
      searchText: '',
      sheet: null,
      bizType: '',
      bizTypeList: [],
    };
  },
  created() {
    this.selectDirectBizType();
    this.onSearch();
    this.addEvent([{name: 'send_onConfirm', func: this.onConfirm}]);
  },
  methods: {
    async selectDirectBizType() {
      const response = await selectDirectBizType();

      const array = [];
      response.data.map(x => array.push({text: x.bizTypeNm, value: x.bizType}));
      this.bizType = array[0].value;
      this.bizTypeList = array;
    },
    async onSearch() {
      const response = await selectWorkerList({pgmCd: this.pgmCd, searchText: this.searchText});
      this.loadSearchData = response.data;
    },
    async onConfirm(callback) {
      const array = this.sheet.getRowsByChecked('chk');
      if (array.length === 0) {
        this.$alert({title: '오류', message: '근로자를 선택해주세요.'});
        return;
      }
      const workerList = array.map(x => {
        return {
          empNo: x.empNo,
          pgmCd: x.pgmCd,
        };
      });
      const param = {workerList: $_statusToCud(workerList), bizType: this.bizType};
      await sendDirect(param);
      callback();
    },
  },
};
</script>

<style></style>
