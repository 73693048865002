import {getWbsHeaderList} from '@/view/workPlanRegist/api/workPlanRegist.js';
const getCols = async that => {
  const headerList = await getWbsHeaderList({});
  let headerNmList = headerList.data;
  let Cols = [
    {
      Header: [{Value: '단위작업내역', Color: '#7ed6df'}, '누계실적', '누계실적', '누계실적'],
      Name: 'totalResultQty',
      Extend: that.$ibsheetHelper.preset.quantity,
      Width: 100,
      CanEdit: 0,
      Visible: 1,
    },
    {
      Header: [{Value: '단위작업내역', Color: '#7ed6df'}, '공정율', '공정율', '공정율'],
      Name: 'rate',
      Extend: that.$ibsheetHelper.preset.rate,
      Width: 100,
      CanEdit: 0,
      Visible: 1,
      Formula: e => (e.Row['totalResultQty'] / e.Row['qty']) * 100,
    },
  ];
  for (let headerNm of headerNmList) {
    let headerObj = {};
    headerObj['Header'] = [
      {Value: '단위작업내역', Color: '#7ed6df'},
      headerNm.headGrpName,
      {Value: headerNm.wbsNm},
      {Value: '계획', Color: '#dff9fb'},
    ];
    headerObj['Name'] = headerNm.wbsId + '_' + headerNm.wbsCd + '_plan';
    headerObj['Extend'] = that.$ibsheetHelper.preset.quantity;
    headerObj['MinWidth'] = 100;
    Cols.push(headerObj);

    headerObj = {};
    headerObj['Header'] = [
      {Value: '단위작업내역', Color: '#7ed6df'},
      headerNm.headGrpName,
      {Value: headerNm.wbsNm},
      {Value: '실적', Color: '#dff9fb'},
    ];
    headerObj['Name'] = headerNm.wbsId + '_' + headerNm.wbsCd;
    headerObj['Extend'] = that.$ibsheetHelper.preset.quantity;
    headerObj['MinWidth'] = 100;
    Cols.push(headerObj);
  }
  return Cols;
};
export default async that => {
  return {
    Cfg: {
      CanEdit: 0,
      HeaderMerge: 6,
    },
    LeftCols: [
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '공종명', '공종명', '공종명'],
        Name: 'midCode',
        Type: 'Text',
        CanEdit: 0,
        Visible: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '공종명', '공종명', '공종명'],
        Name: 'mgName',
        Type: 'Text',
        Width: 100,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '내역코드', '내역코드', '내역코드'],
        Name: 'itemSeq',
        Type: 'Text',
        Width: 100,
        CanEdit: 0,
        Visible: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '내역명', '내역명', '내역명'],
        Name: 'itemName',
        Type: 'Text',
        Width: 100,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '규격', '규격', '규격'],
        Name: 'ssize',
        Type: 'Text',
        Width: 100,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '단위', '단위', '단위'],
        Name: 'unit',
        Type: 'Text',
        Width: 100,
        CanEdit: 0,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '수량', '수량', '수량'],
        Name: 'qty',
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 100,
        CanEdit: 0,
      },
      {
        Name: 'itemCode',
        CanEdit: 0,
        Visible: 0,
      },
    ],
    Cols: await getCols(that),
  };
};
