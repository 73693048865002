<template>
  <pmis-content-box :loading="loading">
    <template v-slot:header-left>
      <iui-text type="search" :value.sync="searchText" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
      <iui-checkbox-group :items="checkboxItems" :value.sync="labtCloses" @change="onChangeCheckbox" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onSearchFinish,
        onFocus,
      }"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/UserListsheet.js';
import {selectUserList} from '../api/userAuth.js';
export default {
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      loading: false,
      searchText: '',
      labtCloses: [],
      checkboxItems: [{value: $getConstants('Y').code, label: '근무종료자포함'}],
    };
  },
  created() {
    this.addEvent([
      {name: 'UserList_selectUserList', func: this.onSearch},
      {name: 'getUserListSaveJson', func: this.getUserListSaveJson},
    ]);
    this.onSearch();
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onSearchFinish(e) {
      if (0 === e.sheet.getTotalRowCount()) {
        this.$emit('click', undefined);
        return;
      }
      e.sheet.getDataRows().forEach(row => {
        if (row.labtClose == $getConstants('LABT_CLOSE_Y').code)
          e.sheet.setAttribute({row, attr: 'TextColor', val: '#FF0000'});
      });
    },
    onFocus(e) {
      this.$emit('click', e.row.empNo);
    },
    async onSearch() {
      this.loading = true;
      const param = {
        searchText: this.searchText,
        labtClose: !this.labtCloses.length,
      };
      const response = await selectUserList(param);
      this.loadSearchData = response.data;
      this.loading = false;
    },
    onChangeCheckbox(e) {
      this.onSearch();
    },
    getUserListSaveJson(callback) {
      if (callback) {
        callback(this.sheet.getSaveJson().data);
      }
    },
  },
};
</script>
