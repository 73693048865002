<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-select :items="selSearchItems" :value.sync="searchSe"> </iui-select>
      <iui-text type="search" :value.sync="searchNm" @enter="selectDeptUserList" />
      <iui-button value="검색" @click="selectDeptUserList"> </iui-button>
    </template>
    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="loadSearchData"
      @loadSheet="sheet_loadSheet"
      :events="{onClick: sheet_onClick}"
    />
    <iui-modal name="WorkerInfoPopup" title="사원정보" sizeType="size1">
      <WorkerInfo :uid="_uid"></WorkerInfo>
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import WorkerInfo from './popup/WorkerInfo.vue';
import sheetOpt from './sheetOption/workerInfo.js';
import {selectDeptUserList} from '../api/deptRegist.js';

export default {
  components: {WorkerInfo},
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],

      searchSe: 'name',
      searchNm: '',
      selSearchItems: [
        {text: '사용자ID', value: 'empNo'},
        {text: '이름', value: 'name'},
      ],
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'deptInfo']);
  },
  created() {
    this.addEvent([{name: 'WorkerNm_selectDeptUserList', func: this.selectDeptUserList}]);

    this.unwatch = this.$store.watch(
      (state, getters) => getters[`${this.$store.getters.getStoreNm}/deptInfo`],
      value => {
        if (value) {
          this.selectDeptUserList();
        }
      },
      {deep: true}
    );
  },
  beforeDestroy() {
    if (this.unwatch) this.unwatch();
  },
  activated() {
    this.canNew = false;
    this.canSave = false;
    this.canDelete = false;
  },
  methods: {
    sheet_loadSheet(sheet) {
      this.sheet = sheet;
      this.selectDeptUserList();
    },
    sheet_onClick(e) {
      this.grid_onClick(e);
    },
    async selectDeptUserList() {
      const param = {
        fldrNo: this.deptInfo.fldrNo,
        searchSe: this.searchSe,
        searchNm: this.searchNm,
      };
      const response = await selectDeptUserList(param);
      this.loadSearchData = response.data;
    },
    async grid_onClick(e) {
      if (e.row.Kind == 'Data') {
        this.$store.commit('setModalParam', {empNo: e.row.empNo});
        this.$modal.show('WorkerInfoPopup');
      }
    },
  },
};
</script>

<style></style>
