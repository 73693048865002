const IuiValidatorMixin = {
  props: {
    validationGroup: {
      type: String,
    },
    required: {
      type: [Boolean, String, Number],
      default: undefined,
      validator: v => {
        if (typeof v === 'string' && isNaN(parseInt(v)) && v <= 0) {
          console.error(`IuiValidatorMixin > props > required > validator > string (required value : '${v}')`);
          return false;
        }
        if (typeof v === 'number' && v <= 0) {
          console.error(`IuiValidatorMixin > props > required > validator > number (required value : '${v}')`);
          return false;
        }
        return true;
      },
    },
    min: {
      type: [String, Number],
    },
    max: {
      type: [String, Number],
    },
    minLength: {
      type: [String, Number],
    },
    maxLength: {
      type: [String, Number],
    },
    pattern: {
      type: String,
    },
    customValidator: {
      type: Function,
    },
    errorMessage: {
      type: [String, Object],
    },
    lazyValidation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
       * 생성된 validator
       */
      validator: null,
      /**
       * validator가 참조할 data 명칭
       */
      validatorTargetDataProp: null,
      /**
       * validator가 리스닝할 이벤트명
       */
      validationTriggerEventName: [],
    };
  },
  computed: {
    isValid: {
      get: function() {
        if (typeof this.validator === undefined || this.validator === null) {
          return true;
        } else {
          return this.validator.isValid;
        }
      },
    },
  },
  beforeMount() {
    this.initValidator();
  },
  mounted() {
    if (typeof this.required === 'string' && isNaN(parseInt(this.required)) && this.required <= 0) {
      let key = Object.keys(this.$refs)[0];
      this.$refs[key].classList.remove('required');
    }
  },
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {
    this.destroyValidator();
  },
  methods: {
    initValidator() {
      let _required = false;
      if (typeof this.required === 'string') {
        _required = !isNaN(parseInt(this.required));
      } else if (typeof this.required === 'number') {
        _required = this.required > 0;
      } else {
        _required = this.required;
      }

      if (_required && !this.validator) {
        this.validator = this.$validator.createValidator(
          this.$options._VPath,
          this.$options.propsData.id,
          this.validationGroup,
          this,
          this.validatorTargetDataProp,
          {
            required: typeof this.required === 'boolean' ? 99 : parseInt(this.required),
            min: this.min,
            max: this.max,
            minLength: this.minLength,
            maxLength: this.maxLength,
            pattern: this.pattern,
          },
          this.customValidator,
          this.errorMessage
        );
        this.setValidEvent(true);
      } else {
        if (!_required && this.validator) {
          this.destroyValidator();
        }
      }
    },
    destroyValidator() {
      if (!this.validator) return;
      this.$validator.validators = this.$validator.validators.filter(
        validator => this.validator.VPath != validator.VPath
      );
      this.validator = null;
      this.setValidEvent(false);
    },
    setValidEvent(on) {
      if (!this.lazyValidation) {
        if (Array.isArray(this.validationTriggerEventName)) {
          this.validationTriggerEventName.forEach(eventName => {
            if (on) {
              this.$on(eventName, () => {
                this.validator.validate();
              });
            } else {
              this.$off(eventName);
            }
          });
        }
        if (typeof this.validationTriggerEventName === 'string') {
          if (on) {
            this.$on(this.validationTriggerEventName, () => {
              this.validator.validate();
            });
          } else {
            this.$off(this.validationTriggerEventName);
          }
        }
      }
    },
  },
};

export default IuiValidatorMixin;
