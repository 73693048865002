<template>
  <pmis-tab-box :loading="loading">
    <template v-slot:title>권한목록</template>
    <template v-slot:title-right>
      <iui-button value="신규" @click="onAdd" />
      <iui-button value="저장" @click="onSave" v-if="focusSeq !== 1" />
      <iui-button value="삭제" @click="onDelete" v-if="focusSeq !== 1" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onSearchFinish, onClick}"
    />
  </pmis-tab-box>
</template>

<script>
import options from './sheetOptions/GrpAuthListSheet.js';
import {selectGroupList, saveGroupAuth} from '../api/grpAuth.js';
export default {
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      loading: false,
      focusSeq: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, ['grpAuthInfo', 'userAuthInfo']);
    $mapMutations(this, ['setGrpAuthInfo', 'setUserAuthInfo']);
  },
  created() {
    this.addEvent([
      {name: 'onAdd', func: this.onAdd},
      {
        name: 'selectGroupList',
        func: () => {
          this.focusSeq = '';
          this.onSearch();
        },
      },
    ]);
    this.onSearch();
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    async onSearch() {
      this.loading = true;
      const response = await selectGroupList({});
      this.loadSearchData = response.data;
      this.loading = false;
    },
    onAdd() {
      let addRow = this.sheet.addRow();
      this.sheet.setAttribute(addRow, 'seq', 'CanEdit', 1, 1);
      this.setGrpAuthInfo(addRow);
      this.$emit('onAdd');
    },
    async onSave() {
      let isValid = true;
      await this.sheet.getSaveJson().data.some((item, index) => {
        if (!item.grpNm) {
          this.$alert({title: '필수입력', message: `${index + 1}번째 그룹명을 입력해주세요.`});
          isValid = false;
          return;
        }
      });
      if (!isValid) {
        return;
      }
      const param = {
        grpAuthList: $_statusToCud(this.sheet.getSaveJson().data),
      };
      const response = await saveGroupAuth(param);
      this.focusSeq = response.data;
      this.onSearch();
    },
    async onDelete() {
      if (await this.$confirm({title: '권한그룹삭제', message: '삭제하시겠습니까?'})) {
        this.sheet.deleteRow(this.sheet.getFocusedRow());
        let saveJson = this.sheet.getSaveJson().data;
        saveJson = $_statusToCud(saveJson).filter(item => item.cud == 3);
        saveJson[0]['pgmCd'] = this.pgmCd;
        const param = {grpAuthList: saveJson};

        const response = await saveGroupAuth(param);
        this.onSearch();
      }
    },
    onSearchFinish(e) {
      const row = e.sheet.getFirstRow();
      this.setGrpAuthInfo(e.sheet.getFirstRow());
      this.focusSeq = row.seq;
      this.$emit('click');
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.setGrpAuthInfo(e.row);
        this.focusSeq = e.row.seq;
        this.$emit('click');
      }
    },
  },
};
</script>

<style></style>
