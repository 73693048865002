<template>
  <pmis-page ref="page">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px"><PrjList /></i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>상세조회</template>
            <template v-slot:title-right>금액단위: 원</template>
            <DetailInfo />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import DetailInfo from './components/DetailInfo.vue';
import PrjList from '@/view/Project/components/PrjList.vue';

export default {
  components: {
    PrjList,
    DetailInfo,
  },
};
</script>

<style scoped></style>
