import BiddingApprovalMixin from '@/view/bidding/js/biddingApprovalMixin.js';
import {createPo, savePo, updatePoFlNo} from '@/view/bidding/po/mat/api/po.js';
import {createPo as createSubPo, savePo as saveSubPo, updateSubPoFlNo} from '@/view/bidding/po/sub/api/subPo.js';

import PoSubmitDocList from '@/view/bidding/po/common/components/PoSubmitDocList.vue';
export default {
  components: {PoSubmitDocList},
  mixins: [BiddingApprovalMixin],
  data() {
    return {
      ttlKey: 0,
      clickable_inputStyle: {
        cursor: 'pointer',
        color: '#FF0000',
        'text-align': 'center',
        'text-decoration': 'underline',
        'font-weight': 'bold',
        'background-color': '#F0F0F0',
      },
    };
  },
  beforeCreate() {
    $mapGetters(this, ['bidType', 'poInfo', 'poIsNew', 'poStatus', 'poItemList', 'rfqInfo']);
    $mapMutations(this, ['setPoInfo', 'initPoItemList']);
  },
  created() {
    this.addFuncSave(this.onSave);
    this.addEvent({name: 'PoInfo_OpenPrjModal', func: this.openPrjModal});
  },
  methods: {
    openPrjModal() {
      this.$refs.prjSearchbox.$refs.searchButton.button_click();
    },
    onCallbackPrjCd(e) {
      // 현장선택 후
      if (this.poInfo.spotDeptcd !== e.prjCd) {
        this.initPoItemList();
      }
      this.setPoInfo({spotDeptcd: e.prjCd, spotDeptnm: e.prjNm, ttl: `[${e.prjCd}] `});
    },
    onInput_ttl(e) {
      let value = e.target.value;
      const ttlPrefix = `[${this.poInfo.spotDeptcd}] `;

      const updateTtlKey = () => (10 < this.ttlKey ? (this.ttlKey = 0) : this.ttlKey++);

      if (value.length < ttlPrefix.length) {
        value = ttlPrefix;
        updateTtlKey();
      } else if (!value.startsWith(ttlPrefix)) {
        value = this.poInfo.ttl + e.data;
        updateTtlKey();
      }

      setTimeout(() => {
        this.$refs.ttl.$refs.input.focus();
      }, 10);

      this.setPoInfo({ttl: value});
    },

    /**
     * 계약정보 생성 및 저장
     */
    async onSave() {
      if (this.poStatus.isPoApprovalRequest) {
        this.$alert({
          title: '계약정보 저장',
          message: "'계약품의중'인 계약정보는 품의서를 회수한 후 수정이 가능합니다.",
        });
        return;
      }
      // 계약명
      if (this.poInfo.ttl === `[${this.poInfo.spotDeptcd}] `) {
        this.$alert({title: '계약명', message: '계약명을 입력해주세요.'});
        return;
      }

      let poItemList, poSubmitDocList;
      this.callEvent({name: 'PoItemList_GetSaveJson', param: data => (poItemList = data)});
      this.callEvent({name: 'PoSubmitDocList_GetSaveJson', param: data => (poSubmitDocList = data)});

      const saveApiObj = {
        mat: {createPo: createPo, savePo: savePo},
        sub: {createPo: createSubPo, savePo: saveSubPo},
      };

      const saveApi =
        this.poIsNew || this.poStatus.isExistOnlyRfq
          ? saveApiObj[this.bidType].createPo
          : saveApiObj[this.bidType].savePo;

      const param = {
        ...this.poInfo,
        itemList: poItemList,
        needDocumentList: poSubmitDocList,
        isNew: this.isNew,
      };

      saveApi(param, () => this.onValidateSave(poItemList))
        .then(response => {
          if (response.status === 200) {
            this.callEvent({name: 'ContractList_Search', param: response.data.poNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    /**
     * 파일삭제 후 업무 파일번호 초기화 및 저장
     *
     * @param {Number} flNo
     */
    onDeleteCompleteFile(flNo) {
      if (flNo) return;

      const saveApi = {mat: updatePoFlNo, sub: updateSubPoFlNo};

      saveApi[this.bidType](this.poInfo.poNo, this.poInfo.contSeq, flNo)
        .then(response => {
          if (response.status == 200) {
            this.setPoInfo({flNo: flNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    showPopup_RfqInfo() {
      if (this.rfqInfo.rfqNo) {
        this.$modal.show('rfqInfoModal');
      }
    },
  },
};
