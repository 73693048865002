const name = '/COP101';
const state = {
  searchInfo: {
    instDtFrom: '', // 지시기간(from)
    instDtTo: '', // 지시기간(to)
    compSe: '', // 완료구분
  },
  detailInfo: {
    instDt: '', // 지시일자
    instDtOrg: '', // 지시일자(원본)
    seq: '', // 순번
    instName: '', // 지시자
    prcDate: '', // 처리기한
    instCts: '', // 지시내용
    actionDate: '', // 조치일자
    actionName: '', // 조치자
    cortFcts: '', // 조치내용
    flNo1: 0, // 지시내용 파일번호
    flNo2: 0, // 조치내용 파일번호
    flCnt1: 0, // 지시내용 파일개수
    flCnt2: 0, // 조치내용 파일개수
    cud: 0,
    canEditInstructions: false, // 지시내용 수정가능여부
  },
  focuskeycode: {
    instDt: '',
    seq: '',
  },
};

const getters = {
  searchInfo: state => state.searchInfo,
  detailInfo: state => state.detailInfo,
  focuskeycode: state => state.focuskeycode,
};

const actions = {};

const mutations = {
  setSearchInfo(state, payload) {
    for (let key in state.searchInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  initDetailInfo(state) {
    for (let key in state.detailInfo) {
      if (key == 'flNo1' || key == 'flCnt1' || key == 'flNo2' || key == 'flCnt2') state.detailInfo[key] = 0;
      else if (key === 'canEditInstructions') state.detailInfo[key] = false;
      else state.detailInfo[key] = '';
    }
  },
  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        if (key == 'instDt' || key == 'prcDate' || key == 'actionDate') {
          state.detailInfo[key] = String(payload[key]);
        } else {
          state.detailInfo[key] = payload[key];
        }
      }
    }
  },
  initFocuskeycode() {
    for (let key in state.focuskeycode) {
      state.focuskeycode[key] = '';
    }
  },
  setFocuskeycode(state, payload) {
    for (let key in state.focuskeycode) {
      if (payload && payload.hasOwnProperty(key)) {
        state.focuskeycode[key] = payload[key];
      }
    }
  },
};

export default {
  name,
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
