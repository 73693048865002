export default async that => {
  return {
    Cols: [
      {
        Name: 'sn',
        Header: '순번',
        CanEdit: false,
        Width: 70,
      },
      {
        Name: 'blnDepNm',
        Header: '부서명',
        CanEdit: false,
        Width: 120,
      },
      {
        Name: 'name',
        Header: '성명',
        CanEdit: false,
        RelWidth: 1,
      },
      {
        Name: 'empNo',
        Visible: false,
      },
      {
        Name: 'blnDepNo',
        Visible: false,
      },
    ],
  };
};
