export default that => {
  return {
    Cfg: {
      CanEdit: 0,
    },
    Def: {
      Row: {NoColor: 1, CanFormula: 1},
    },
    Cols: [
      {
        Header: '현장코드',
        Name: 'spotDeptcd',
        Visible: 0,
      },
      {
        Header: '현장명',
        Name: 'spotDeptnm',
        Visible: 1,
        RelWidth: 1,
        MinWidth: 150,
      },
      {
        Header: '외주명',
        Name: 'cusNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
        CanEdit: false,
      },
      {
        Header: '계약번호',
        Name: 'poNo',
        Type: 'Text',
        Align: 'Center',
        Width: 120,
        CustomFormat: v => `${v.substr(8, 8)}-${v.substr(16, 4)}`,
        CanEdit: false,
      },
      {
        Header: '계약명',
        Name: 'ttl',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
        CanEdit: false,
      },
      {
        Header: '계약일자',
        Name: 'poDt',
        Type: 'Text',
        Align: 'Center',
        Width: 90,
        CanEdit: false,
      },
      {
        Header: '공사기간',
        Name: 'contPeriod',
        Type: 'Text',
        Align: 'Center',
        Width: 170,
        Formula: fr => fr.Row['contFrDt'] + ' ~ ' + fr.Row['contToDt'],
        CanEdit: false,
      },
      {
        Header: '계약금액',
        Name: 'totAmt',
        Width: 120,
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: false,
      },
      {Name: 'contFrDt', Visible: false},
      {Name: 'contToDt', Visible: false},
      {Name: 'taxTypecd', Visible: false},
      {Name: 'taxRate', Visible: false},
      {Name: 'cusCd', Visible: false},
    ],
  };
};
