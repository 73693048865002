import Validator from './Validator.js';
import ValidatorRule from './ValidatorRule.js';
import ValidatorErrorMessage from './ValidatorErrorMessage';

/**
 * String.prototype.startsWith polyfill
 *
 * https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
 */
if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(search, pos) {
    return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
  };
}

/**
 * String.prototype.endsWith polyfill
 *
 * https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/String/endsWith
 */
if (!String.prototype.endsWith) {
  String.prototype.endsWith = function(searchString, position) {
    var subjectString = this.toString();
    if (
      typeof position !== 'number' ||
      !isFinite(position) ||
      Math.floor(position) !== position ||
      position > subjectString.length
    ) {
      position = subjectString.length;
    }
    position -= searchString.length;
    var lastIndex = subjectString.indexOf(searchString, position);
    return lastIndex !== -1 && lastIndex === position;
  };
}

/**
 * IuiValidator Plugin
 */
const IuiValidatorPlugin = {
  install(Vue) {
    Vue.prototype.$validator = {
      // debug: options.debug || false,
      validators: [],
      /**
       * ValidatorRule을 생성
       * @param {*} required
       * @param {*} min
       * @param {*} max
       * @param {*} minLength
       * @param {*} maxLength
       * @param {*} pattern
       */
      createValidatorRule(required, min, max, minLength, maxLength, pattern) {
        return new ValidatorRule(required, min, max, minLength, maxLength, pattern);
      },
      /**
       * Validator를 생성
       * @param {*} vPath
       * @param {*} id
       * @param {*} validationGroup
       * @param {*} targetComponent
       * @param {*} targetDataNameProp
       * @param {*} rule
       * @param {*} customValidator
       * @param {*} errorMessage
       */
      createValidator(
        vPath,
        id,
        validationGroup,
        targetComponent,
        targetDataNameProp,
        rule,
        customValidator,
        errorMessage
      ) {
        const validationRule = this.createValidatorRule(
          rule.required,
          rule.min,
          rule.max,
          rule.minLength,
          rule.maxLength,
          rule.pattern
        );

        const validator = new Validator(
          vPath,
          id,
          validationGroup,
          targetComponent,
          targetDataNameProp,
          validationRule,
          customValidator,
          new ValidatorErrorMessage(errorMessage)
        );

        this.add(validator);

        return validator;
      },
      /**
       * Validator를 추가합니다.
       * @param {*} validator
       */
      add(validator) {
        this.validators.push(validator);
        return validator;
      },
      /**
       * Validators를 정렬합니다.
       */
      sort() {
        this.validators.sort((a, b) => {
          let topA = parseInt(a.targetComponent.$el.getBoundingClientRect().top);
          let topB = parseInt(b.targetComponent.$el.getBoundingClientRect().top);
          let leftA = parseInt(a.targetComponent.$el.getBoundingClientRect().left);
          let leftB = parseInt(b.targetComponent.$el.getBoundingClientRect().left);

          let r1 = topA - topB;
          let r2 = leftA - leftB;
          return r1 != 0 ? r1 : r2;
        });

        this.validators.sort((a, b) => {
          return a.rules.required - b.rules.required;
        });
      },
      /**
       * Validator를 가져옵니다.
       * @param {(Vue|VueComponent)} vm Validator를 가져올 기준이 되는 Vue 또는 VueComponent. 일반적으로 this를 설정.
       * @param {String} validationGroup Validator를 가져올 기준이 되는 validation group명.
       * @param {String} id Validator를 가져올 기준이 되는 id. * 와일드카드 지원
       * @returns {Object[]} 기준에 부합하는 Validator
       * @example
       *
       * // 현재 Vue와 하위 VueComponent에 포함된 모든 Validator를 가져온다.
       * const validators = this.$validator.getValidator(this);
       * const validators = this.$validator.getValidator(this, '*');
       *
       * // 현재 Vue와 하위 VueComponent에 포함된 모든 Validator 중 id가 searchIdPrefix로 시작하는 Validator를 가져온다.
       * const validators = this.$validator.getValidator(this, 'searchIdPrefix*');
       *
       * // 현재 Vue와 하위 VueComponent에 포함된 모든 Validator 중 id가 searchId와 동일한 Validator를 가져온다.
       * const validators = this.$validator.getValidator(vm, 'searchId');
       *
       * // 현재 Vue와 하위 VueComponent에 포함된 모든 Validator 중 validationGroup이 groupId와 동일하고 id가 searchIdPrefix로 시작하는 Validator를 가져온다.
       * const validators = this.$validator.getValidator(vm, 'groupId', 'idPrefix*');
       *
       * // 현재 Vue와 하위 VueComponent에 포함된 모든 Validator 중 validationGroup이 groupId와 동일하고 id가 searchId와 동일한 Validator를 가져온다.
       * const validators = this.$validator.getValidator(vm, 'groupId', 'id')
       *
       */
      getValidator() {
        let filteredValidators = [];
        let vm = arguments[0];
        let vPath = vm.$options._VPath;

        if (arguments.length === 1) {
          // getValidator(vm)
          filteredValidators = this.validators.filter(validator => validator.VPath.startsWith(vPath));
        } else if (arguments.length === 2) {
          // getValidator(vm, 'idPrefix*')
          // getValidator(vm, 'id')
          let id = arguments[1];

          if (id.endsWith('*')) {
            filteredValidators = this.validators.filter(
              validator => validator.VPath.startsWith(vPath) && validator.id.startsWith(id.substring(0, id.length - 1))
            );
          } else {
            filteredValidators = this.validators.filter(
              validator => validator.VPath.startsWith(vPath) && validator.id === id
            );
          }
        } else if (arguments.length === 3) {
          // getValidator(vm, 'groupId', 'idPrefix*')
          // getValidator(vm, 'groupId', 'id')
          let validationGroup = arguments[1];
          let id = arguments[2];

          if (id === '*') {
            filteredValidators = this.validators.filter(
              validator => validator.VPath.startsWith(vPath) && validator.validationGroup === validationGroup
            );
          } else {
            if (id.endsWith('*')) {
              filteredValidators = this.validators.filter(
                validator =>
                  validator.VPath.startsWith(vPath) &&
                  validator.validationGroup === validationGroup &&
                  validator.id.startsWith(id.substring(0, id.length - 1))
              );
            } else {
              filteredValidators = this.validators.filter(
                validator =>
                  validator.VPath.startsWith(vPath) &&
                  validator.validationGroup === validationGroup &&
                  validator.id === id
              );
            }
          }
        }

        return filteredValidators;
      },
      getValidatorGroup(vm, validationGroup) {
        return this.getValidator(vm, validationGroup, '*');
      },
      /**
       * Validator의 존재여부를 확인합니다.
       */
      exists() {
        return this.getValidator(...arguments).length > 0;
      },
      /**
       * Validator를 validate합니다.
       * @param {(Vue|VueComponent)} vm Validator를 가져올 기준이 되는 Vue 또는 VueComponent. 일반적으로 this를 설정.
       * @param {String} validationGroup Validator를 가져올 기준이 되는 validation group명.
       * @param {String} id Validator를 가져올 기준이 되는 id. * 와일드카드 지원
       * @returns {Promise} Promise
       * @example
       *
       * // 현재 Vue와 하위 VueComponent에 포함된 모든 Validator를 validate한다.
       * await promise = this.$validator.validate(this);
       * await promise = this.$validator.validate(this, '*');
       *
       * // 현재 Vue와 하위 VueComponent에 포함된 모든 Validator 중 id가 searchIdPrefix로 시작하는 Validator를 validate한다.
       * await promise = this.$validator.validate(this, 'searchIdPrefix*');
       *
       * // 현재 Vue와 하위 VueComponent에 포함된 모든 Validator 중 id가 searchId와 동일한 Validator를 validate한다.
       * await promise = this.$validator.validate(vm, 'searchId');
       *
       * // 현재 Vue와 하위 VueComponent에 포함된 모든 Validator 중 validationGroup이 groupId와 동일하고 id가 searchIdPrefix로 시작하는 Validator를 validate한다.
       * await promise = this.$validator.validate(vm, 'groupId', 'idPrefix*');
       *
       * // 현재 Vue와 하위 VueComponent에 포함된 모든 Validator 중 validationGroup이 groupId와 동일하고 id가 searchId와 동일한 Validator를 validate한다.
       * await promise = this.$validator.validate(vm, 'groupId', 'id')
       *
       */
      validate() {
        const myArguments = arguments;

        let p = new Promise((resolve, reject) => {
          try {
            const filteredValidators = this.getValidator(...myArguments);

            let valid = true;

            filteredValidators.forEach(validator => {
              valid &= validator.validate();
            });

            resolve({arguments: myArguments, isValid: Boolean(valid), validators: filteredValidators});
          } catch (ex) {
            reject(ex);
          }
        });

        return p;
      },
      validateGroup() {
        const myArguments = arguments;

        let p = new Promise((resolve, reject) => {
          try {
            const filteredValidators = this.getValidatorGroup(...myArguments);

            let valid = true;

            filteredValidators.forEach(validator => {
              valid &= validator.validate();
            });

            resolve({arguments: myArguments, isValid: Boolean(valid), validators: filteredValidators});
          } catch (ex) {
            reject(ex);
          }
        });

        return p;
      },
    };

    Vue.mixin({
      methods: {
        validate() {
          return this.$validator.validate(this);
        },
      },
    });
  },
};

export default IuiValidatorPlugin;
