<template>
  <!--회원가입요청-포틀릿-->
  <div style="height:100%">
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr height="30px">
          <th>회사명</th>
          <th>대표자명</th>
          <th class="p_last">요청일시</th>
        </tr>
      </thead>
    </table>
    <div class="inner" style="height:calc(100% - 40px);">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="(join, index) in tableData" :key="index">
            <td style="text-align:left;">
              <a @click="goShourCut(join)">{{ join.frmNm }}</a>
            </td>
            <td>{{ join.rpsvNm }}</td>
            <td class="p_last">{{ join.rgDt }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
import {selectJoinRequestList} from '@/components/portlet/api/portletData.js';

export default {
  mixins: [PortletMixin],
  data() {
    return {
      cnt: 3,
      tableData: [],
    };
  },
  methods: {
    setWidth() {
      this.tblWidth = this.$parent.$el.offsetWidth - 28;
    },
    onSearch() {
      selectJoinRequestList().then(response => {
        const obj = {};
        obj[$getConstants('FRM_GB_1').code] = $getConstants('FRM_GB_1').name;
        obj[$getConstants('FRM_GB_2').code] = $getConstants('FRM_GB_2').name;
        obj[$getConstants('FRM_GB_3').code] = $getConstants('FRM_GB_3').name;
        response.data.forEach(item => {
          const prefix = `[${obj[item.frmGb]}]`;
          item.frmNm = `${prefix} ${item.frmNm}`;
        });
        this.tableData = response.data;

        this.$emit('update:unchkedCnt', response.data.length);
      });
    },
    goShourCut(data) {
      this.goMenu('JOIN_APPROVAL', {pgmCd: data.pgmCd});
    },
  },
};
</script>

<style></style>
