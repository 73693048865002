<template>
  <iui-container-new type="css-flex">
    <i-row height="145px">
      <i-col>
        <pmis-content-box>
          <template #title>입찰정보</template>
          <iui-container-new type="table" theme="bullet">
            <i-row>
              <i-col-header>현장</i-col-header>
              <i-col>
                <iui-text :value="rfq.spotDeptcd" :enable="false" width="130px" />
                <iui-text :value="rfq.spotDeptnm" :enable="false" />
              </i-col>
              <i-spacer />
              <i-col-header>차수</i-col-header>
              <i-col>
                <iui-select :items="degreeItems" :value.sync="degree" @change="onSearch" width="130px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header width="100px">입찰공고번호</i-col-header>
              <i-col>
                <iui-text :value="rfq.rfqNo2" :enable="false" width="130px" />
                <iui-text :value="rfq.ttl" :enable="false" />
              </i-col>
              <i-spacer />
              <i-col-header>응찰/공고</i-col-header>
              <i-col>
                <iui-text :value="rfq.totCusCountText" :enable="false" width="130px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>공고일</i-col-header>
              <i-col><iui-text :value="rfq.notiDt" :enable="false" width="130px"/></i-col>
              <i-spacer />
              <i-col-header>마감일</i-col-header>
              <i-col><iui-text :value="rfq.expireDt" :enable="false" width="130px"/></i-col>
            </i-row>
            <i-row>
              <i-col-header>개봉일</i-col-header>
              <i-col><iui-text :value="rfq.openDt" :enable="false" width="130px"/></i-col>
              <i-spacer />
              <i-col-header>입찰예정가</i-col-header>
              <i-col>
                <iui-text type="amount" :value="rfq.bidPlanAmt" :enable="false" width="130px" suffix="" />
                <div>(부가세포함)</div>
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box>
          <template #title>참여협력사</template>
          <ib-sheet
            :uid="_uid"
            :options="sheetOpt1"
            :loadSearchData="attendeeList"
            @loadSheet="sheet1 = $event"
            :events="{onClick: sheet1_onClick}"
          />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box>
          <template #title>상세내역</template>
          <ib-sheet
            :uid="_uid"
            :options="sheetOpt2"
            :loadSearchData="compareQuotList"
            @loadSheet="sheet2 = $event"
            :events="{
              onRenderFirstFinish: e => e.sheet.setAutoMerge({headerMerge: 5}),
              onClick: sheet2_onClick,
            }"
          />
        </pmis-content-box>
      </i-col>
    </i-row>

    <iui-modal name="Degree_CustomerInfoModal" title="협력사정보" size-type="size2">
      <CustomerInfo :propsPgmCd="propsCusPgmCd" />
    </iui-modal>

    <!-- <iui-modal name="Degree_QuotInfoModal" title="견적서" size-type="size3">
      <quot-info :propsRfqNo="rfqNo" :propsBidRnd="propsBidRnd" :propsCusPgmCd="propsCusPgmCd" />
    </iui-modal> -->

    <iui-modal name="matCodeInfoModal" title="자재정보" size-type="size4">
      <MatCodeInfo :propsMatCode="propsMatCode" />
    </iui-modal>

    <iui-modal name="Degree_ReportViewerModal" title="차수조회" size-type="size6">
      <report-viewer :fomNo="fomNo" :ozContent="ozContent" />
    </iui-modal>
  </iui-container-new>
</template>

<script>
import padStart from 'lodash/padStart';
import sheetOpt1 from './sheetOptions/degree1.js';
import sheetOpt2_mat from '@/view/bidding/rfq/mat/common/components/sheetOptions/compareSheet.js';
import sheetOpt2_sub from '@/view/bidding/rfq/sub/common/components/sheetOptions/compareSheet.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import FOM_NO from '@/constants/fomNoConstants.js';
import {selectRfqCompareInfos} from '@/view/bidding/rfq/mat/api/rfq.js';
import {selectSubRfqCompareInfos} from '@/view/bidding/rfq/sub/api/subRfq.js';

import CustomerInfo from '@/view/bidding/rfq/common/components/CustomerInfo.vue';
import QuotInfo from '@/view/bidding/rfq/mat/common/components/QuotInfo.vue';
import MatCodeInfo from '@/view/sysManage/stdCodeManage/matCodeRegist/popup/MatCodeInfo.vue';
import ReportViewer from '@/view/bidding/components/ReportViewer.vue';
export default {
  components: {
    CustomerInfo,
    // , QuotInfo
    MatCodeInfo,
    ReportViewer,
  },
  data() {
    return {
      sheetOpt1: sheetOpt1(this),
      sheetOpt2: null,

      degree: '',
      degreeItems: [],

      rfq: {
        orgCd: '',
        spotDeptcd: '',
        spotDeptnm: '',
        rfqNo2: '',
        ttl: '',
        lastBidRnd: '',
        notiDt: '',
        expireDt: '',
        openDt: '',
        quotCusCount: '',
        totCusCount: '',
        totCusCountText: '',
        bidPlanAmt: '',
        chrgUsrnm: '',
      },

      attendeeList: [],
      compareQuotList: [],

      propsCusPgmCd: '',
      propsMatCode: '',
      ozContent: {},
    };
  },
  computed: {
    fomNo() {
      const fomNo = {
        mat: FOM_NO.RFQ_DEGREE_PRINT,
        sub: FOM_NO.SUB_RFQ_DEGREE_PRINT,
      };
      return fomNo[this.bidType];
    },
  },
  beforeCreate() {
    $mapGetters(this, ['bidType', 'rfqInfo', 'rfqStatus']);
  },
  async created() {
    this.addEvent({name: 'Degree_Print', func: this.onPrint});
    this.sheetOpt2 = {mat: sheetOpt2_mat, sub: sheetOpt2_sub}[this.bidType](this);

    // 차수정보
    let lastBidRnd = parseInt(this.rfqInfo.lastBidRnd);
    if (this.rfqStatus.isBeforeRfqOpen) {
      lastBidRnd--;
    }

    for (let i = lastBidRnd; i >= 0; i--) {
      const degree = padStart(i, 2, '0');
      this.degreeItems.push({text: `${degree}차`, value: degree});
    }
    this.degree = this.degreeItems[0].value;
  },
  mounted() {
    this.onSearch();
  },
  methods: {
    async onSearch() {
      const rfqCompareInfoApi = {mat: selectRfqCompareInfos, sub: selectSubRfqCompareInfos};
      const response = await rfqCompareInfoApi[this.bidType](this.rfqInfo.rfqNo, this.degree);

      const degreeInfo = response.data.rfqDegreeInfo;

      // 1. 입찰정보
      this.rfq.orgCd = degreeInfo.orgCd;
      this.rfq.spotDeptcd = degreeInfo.spotDeptcd;
      this.rfq.spotDeptnm = degreeInfo.spotDeptnm;
      this.rfq.rfqNo2 = COMMON_FUNCTION.getMakeFormat(degreeInfo.rfqNo);
      this.rfq.ttl = degreeInfo.ttl;
      this.rfq.lastBidRnd = degreeInfo.lastBidRnd;
      this.rfq.notiDt = degreeInfo.notiDt;
      this.rfq.expireDt = degreeInfo.expireDt;
      this.rfq.openDt = degreeInfo.openDt;
      this.rfq.quotCusCount = degreeInfo.quotCusCount;
      this.rfq.totCusCount = degreeInfo.totCusCount;
      this.rfq.totCusCountText = `${degreeInfo.quotCusCount} / ${degreeInfo.totCusCount}`;
      this.rfq.bidPlanAmt = degreeInfo.bidPlanAmt;
      this.rfq.chrgUsrnm = degreeInfo.chrgUsrnm;

      this.degree = degreeInfo.bidRnd;

      // 2. 참여업체정보
      this.attendeeList = response.data.compareCusList;

      // 3. 상세내역
      this.compareQuotList = response.data.compareQuotList;
    },
    onPrint() {
      const RfqInfo = {
        spotDeptcd: this.rfq.spotDeptcd,
        spotDeptnm: this.rfq.spotDeptnm,
        rfqNo: this.rfq.rfqNo2,
        ttl: this.rfq.ttl,
        bidRnd: `${this.degree}차`,
        bidStartDt: this.rfq.notiDt,
        bidExpireDt: this.rfq.expireDt,
        bidOpenDt: this.rfq.openDt,
        chrgUsrnm: this.rfq.chrgUsrnm,
        cusCount: this.rfq.totCusCountText,
        bidPlanAmt: `${this.rfq.bidPlanAmt} (원) 부가세포함`,
      };

      const CusList = [];
      this.sheet1.getDataRows().forEach(row => {
        CusList.push({
          frmNm: row.frmNm,
          icprNm: row.icprNm || '',
          icprPhone: $_mobileFormat(row.icprPhone).join?.('-'),
          quotData: $_numberFormat(row.totAmt, true),
        });
      });
      this.ozContent = {...RfqInfo, CusList: JSON.stringify({CusList})};
      this.$modal.show('Degree_ReportViewerModal');
    },
    sheet1_onClick(e) {
      if (e.row.Kind === 'Data') {
        if (e.col === 'cusNm') {
          this.propsCusPgmCd = e.row.cusPgmCd;
          this.$modal.show('Degree_CustomerInfoModal');
        }
      }
    },
    sheet2_onClick(e) {
      if (e.row.Kind === 'Data') {
        if (this.bidType === 'mat' && e.col === 'itemNm') {
          this.propsMatCode = e.row.itemCd.toString();
          this.$modal.show('matCodeInfoModal');
        }
      }
    },
  },
};
</script>

<style></style>
