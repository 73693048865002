export default that => {
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
    },
    Cols: [
      {
        Header: '회사구분',
        Name: 'frmGb',
        Type: 'Text',
        Width: 70,
        Format: $getConstants('FRM_GB').code,
        CanEdit: 0,
      },
      {
        Header: '회사명',
        Name: 'frmNm',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: '대표자명',
        Name: 'rpsvNm',
        Type: 'Text',
        Width: 70,
        Align: 'center',
        CanEdit: 0,
      },
      {
        Header: '요청일시',
        Name: 'rgDt',
        Type: 'Text',
        Width: 110,
        Align: 'center',
        Visible: that.isApproval,
        CanEdit: 0,
      },
      {
        Header: '종료여부',
        Name: 'closeAt',
        Type: 'Text',
        Width: 70,
        Align: 'center',
        Visible: !that.isApproval,
        CanEdit: 0,
      },
      {
        Header: '실행간소화',
        Name: 'simpleExec',
        Type: 'Bool',
        Width: 80,
        Align: 'center',
        Visible: that.isApproval,
        CanEditFormula: fr => (fr.Row.frmGb === $getConstants('FRM_GB_1').code ? 1 : 0),
      },
      {
        Name: 'bsnNo',
        Type: 'Text',
        Visible: false,
        CanEdit: false,
      },
    ],
  };
};
