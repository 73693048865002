export default that => {
  return {
    Cfg: {
      CanEdit: 1,
    },
    Def: {
      Row: {
        NoColor: 2,
        CanFormula: 1,
        CalcOrder: 'bsnNoButton,bsnNoAlign',
      },
    },
    Cols: [
      {
        Header: '선택',
        Type: 'Bool',
        Name: 'cbx',
        Width: '60',
      },
      {
        Header: '업체코드',
        Name: 'bsnNo',
        Width: 130,
        AlignFormula: e => (e.Row.cud == 1 ? '' : 'Center'),
        ButtonFormula: e => (e.Row.cud == 1 ? 'Html' : ''),
        ButtonText: '<input type="button" class="i_search"/>',
        WidthPad: 37,
        CanEdit: false,
        OnClickSide: () => that.$modal.show('JointCustomerListModal'),
        CustomFormat: 'SaupNo',
      },
      {
        Header: '업체명',
        Name: 'frmNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 200,
        CanEdit: false,
      },
      {
        Header: '당초지분(%)',
        Name: 'wshare1',
        Extend: that.$ibsheetHelper.preset.rate,
        RelWidth: 1,
        MinWidth: 120,
      },
      {
        Header: '최종지분(%)',
        Name: 'wshare2',
        Extend: that.$ibsheetHelper.preset.rate,
        RelWidth: 1,
        MinWidth: 120,
      },
      {Name: 'pgmCd', Visible: false},
      {Name: 'prjCd', Visible: false},
      {Name: 'cud', Visible: false},
    ],
  };
};
