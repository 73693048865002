<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-select
        label="공사구분"
        :value="searchInfo.wcode"
        :items="wCodeList"
        defaultCd="S"
        @change="value => onSetValue('wcode', value)"
      />
      <iui-select
        label="공종분류"
        :value="searchInfo.clCode"
        :items="clWorkFilter"
        defaultCd="S"
        @change="value => onSetValue('clCode', value)"
      />
      <iui-text
        type="search"
        label="내역명칭"
        :value="searchInfo.searchNm"
        @change="onSetValue('searchNm', $event.target.value)"
        @enter="onSearchExecDtlsList"
      />
      <iui-button value="검색" @click="onSearchExecDtlsList" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onSearchFinish,
        onClick,
        onDblClick,
      }"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOptions/ExecDtlsListSheet.js';
import {selectExecDtlsAsUnitOpertList} from '../api/unitOpert.js';
import {selectWcodeBYExe, selectClWorkBYExe} from '@/view/executDtls/api/executDtls.js';
export default {
  props: {
    wbsCd: {
      type: Number,
    },
    wbsId: {
      type: String,
    },
    costType: {
      type: String,
    },
  },
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      searchInfo: {
        wcode: '',
        clCode: '',
        searchNm: '',
      },

      wCodeList: [],
      clWorkList: [],
      clWorkFilter: [],
    };
  },
  async mounted() {
    this.onSearchWCodeList(); //공사구분
    this.onSearchClWorkList(); //공종분류
  },
  methods: {
    onSearchWCodeList() {
      //공사구분
      const param = {...this.searchInfo, costType: this.costType};
      selectWcodeBYExe(param).then(response => {
        this.wCodeList = response.data.map(e => ({text: e.wname, value: e.wcode}));
      });
    },
    onSearchClWorkList() {
      //공종분류
      const param = {...this.searchInfo, costType: this.costType};
      selectClWorkBYExe(param).then(response => {
        this.clWorkList = response.data.map(e => {
          return {wcode: e.wcode, headCode: e.headCode, midCode: e.midCode, value: e.clCd, text: e.clNm};
        });
      });
    },
    onSetValue(key, value) {
      this.searchInfo[key] = value;

      if (key == 'wcode') {
        this.clWorkFilter = this.clWorkList.filter(r => r.wcode == value);
        this.searchInfo['clCode'] = '';
        this.searchInfo['searchNm'] = '';
      }
      if (key == 'wcode' || key == 'clCode') {
        this.onSearchExecDtlsList();
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onSearchFinish(e) {
      if (!e.sheet.getFirstRow()) {
        return;
      }
      this.$emit('onRow', e.sheet.getFirstRow());
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.$emit('onRow', e.row);
      }
    },
    onDblClick() {
      this.$modalConfirm();
    },
    onSearch() {
      this.searchInfo.wcode = '';
      this.searchInfo.clCode = '';
      this.searchInfo.searchNm = '';
      this.onSearchExecDtlsList();
    },
    async onSearchExecDtlsList() {
      let headCode = this.searchInfo.clCode.split('::')[0];
      let midCode = this.searchInfo.clCode.split('::')[1];
      const param = {
        wbsCd: this.wbsCd,
        wbsId: this.wbsId,
        costType: this.costType,
        wcode: this.searchInfo.wcode,
        searchNm: this.searchInfo.searchNm,
        headCode,
        midCode,
      };
      const response = await selectExecDtlsAsUnitOpertList(param);
      this.loadSearchData = response.data;
    },
  },
};
</script>
