import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
export default that => {
  return {
    Cfg: {
      CanEdit: 0,
    },
    Cols: [
      {
        Name: 'prjNm',
        Header: '현장명',
        RelWidth: 1,
      },
      {
        Name: 'poNo',
        Header: '계약번호',
        Width: '200',
        Align: 'center',
        CustomFormat: v => COMMON_FUNCTION.getMakeFormat(v),
      },
      {
        Name: 'ttl',
        Header: '계약명',
        Width: '200',
      },
      {
        Name: 'contSeq',
        Header: '계약차수',
        Visible: false,
      },
    ],
  };
};
