<template>
  <iui-container-new type="css-flex">
    <i-row minHeight="100px">
      <i-col>
        <ib-sheet
          :uid="_uid"
          :options="Options"
          :loadSearchData="loadSearchData"
          :events="{
            onSearchFinish: onSearchFinish,
            onClick: onClick,
          }"
        />
      </i-col>
    </i-row>
    <i-spacer />
    <i-row height="100px">
      <i-col>
        <iui-container-new type="table">
          <i-row>
            <i-col-header>결재자 의견</i-col-header>
          </i-row>
          <i-row>
            <i-col>
              <iui-text type="multi" :value="rmk" :enable="false" />
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import options from '../components/sheetOption/ApprovalStatusPopSheet.js';
import {selectApprovalStatusList} from '../../api/appDoc.js';
export default {
  props: {
    sncNo: {
      type: Number,
    },
  },
  data() {
    return {
      Options: options(this),
      loadSearchData: [],
      rmk: '',
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    async onSearch() {
      const param = {
        sncNo: this.sncNo,
      };
      const response = await selectApprovalStatusList(param);
      this.loadSearchData = response.data;
    },
    onSearchFinish(e) {
      this.rmk = e.sheet.getFirstRow().rmk;
    },
    onClick(e) {
      this.rmk = e.row.rmk;
    },
  },
};
</script>

<style></style>
