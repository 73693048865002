export default that => {
  return {
    Def: {
      Row: {
        VAlign: 'TOP',
      },
    },
    Cfg: {
      HeaderMerge: 3,
    },
    Cols: [
      {Name: 'total', Visible: 1, Width: 1, CanFocus: 0, ShowHint: 0, Class: '_total'},
      {Name: 'lgName', Visible: 1, Width: 1, CanFocus: 0, ShowHint: 0, Class: '_total'},
      {
        Header: ['도급내역', {Value: '공종명', Color: '#dff9fb'}],
        Name: 'mgName',
        Type: 'Text',
        Width: 200,
        CanEdit: 0,
      },
      {
        Header: ['도급내역', {Value: '내역명', Color: '#dff9fb'}],
        Name: 'itemName',
        Type: 'Text',
        Width: 200,
        CanEdit: 0,
      },
      {
        Header: ['도급내역', {Value: '규격', Color: '#dff9fb'}],
        Name: 'ssize',
        Type: 'Text',
        Width: 120,
        CanEdit: 0,
        Align: 'Center',
      },
      {
        Header: ['도급내역', {Value: '단위', Color: '#dff9fb'}],
        Name: 'unit',
        Type: 'Text',
        Width: 100,
        CanEdit: 0,
        Align: 'Center',
        Format: $getConstants('UNIT').code,
      },
      {
        Header: ['도급내역', {Value: '수량', Color: '#dff9fb'}],
        Name: 'moqty',
        Extend: that.$ibsheetHelper.preset.quantity,
        CanEdit: 0,
      },
      {
        Header: ['도급내역', {Value: '단가', Color: '#dff9fb'}],
        Name: 'price',
        Extend: that.$ibsheetHelper.preset.unitAmountFormula,
        CanEdit: 0,
      },
      {
        Header: ['도급내역', {Value: '금액', Color: '#dff9fb'}],
        Name: 'amt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        CanEdit: 0,
      },
      {
        Header: ['기성계획', {Value: '전회누계', Color: '#dff9fb'}],
        Name: 'beforePlanContAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        CanEdit: 0,
      },
      {
        Header: ['기성계획', {Value: '금회', Color: '#dff9fb'}],
        Name: 'planContAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        CanEdit: 0,
      },
      {
        Header: ['기성계획', {Value: '누계', Color: '#dff9fb'}],
        Name: 'totalPlanContAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        CanEdit: 0,
      },
      {
        Header: ['기성계획', {Value: '잔여', Color: '#dff9fb'}],
        Name: 'leftPlanContAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        CanEdit: 0,
      },
      {
        Header: ['기성실적', {Value: '전회누계', Color: '#dff9fb'}],
        Name: 'beforeFixAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        CanEdit: 0,
      },
      {
        Header: ['기성실적', {Value: '금회', Color: '#dff9fb'}],
        Name: 'curFixAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        CanEdit: 0,
      },
      {
        Header: ['기성실적', {Value: '누계', Color: '#dff9fb'}],
        Name: 'totalFixAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        CanEdit: 0,
      },
      {
        Header: ['기성실적', {Value: '잔여', Color: '#dff9fb'}],
        Name: 'leftFixAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        CanEdit: 0,
      },
      {
        Header: ['기성실적', {Value: '기성율', Color: '#dff9fb'}],
        Name: 'fixContRate',
        Extend: that.$ibsheetHelper.preset.rateFormula,
        CanEdit: 0,
      },
      {
        Header: ['청구기성실적', {Value: '전회누계', Color: '#dff9fb'}],
        Name: 'preContAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        CanEdit: 0,
      },
      {
        Header: ['청구기성실적', {Value: '금회', Color: '#dff9fb'}],
        Name: 'curContAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        CanEdit: 0,
      },
      {
        Header: ['청구기성실적', {Value: '누계', Color: '#dff9fb'}],
        Name: 'totalContAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        CanEdit: 0,
      },
      {
        Header: ['청구기성실적', {Value: '잔여', Color: '#dff9fb'}],
        Name: 'leftContAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        CanEdit: 0,
      },
      {
        Header: ['청구기성실적', {Value: '기성율', Color: '#dff9fb'}],
        Name: 'contRate',
        Extend: that.$ibsheetHelper.preset.rateFormula,
        CanEdit: 0,
      },
      {Name: 'headCode', Visible: 0},
      {Name: 'midCode', Visible: 0},
    ],
  };
};
