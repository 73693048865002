<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>부서목록</template>
            <deptList></deptList>
          </pmis-tab-box>
        </i-col>
        <i-col>
          <iui-tab :comps="tabList" :keepAlive="true"></iui-tab>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import deptList from './components/deptList.vue';
import store from './store/deptRegist.js';

export default {
  components: {
    deptList,
  },
  data() {
    return {
      tabList: [
        {
          tabNm: '부서정보',
          compNm: 'DeptInfo',
          path: '@/view/sysManage/userManage/deptRegist/components/deptInfo',
        },
        {
          tabNm: '사원정보',
          compNm: 'WorkerNm',
          path: '@/view/sysManage/userManage/deptRegist/components/workerNm',
        },
      ],
      modalBtn: [{name: '확인', callback: this.onConfirm}],
      isPopup: false,
    };
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['deptInfo']);
    $mapMutations(this, ['initDeptInfo', 'setDeptInfo', 'setSearchInfo']);
  },
  created() {
    this.setSearchInfo({pgmCd: this.pgmCd, frmNm: this.userInfo.frmNm, frmGb: this.userInfo.frmGb});
    this.addFuncSearch(this.onSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'DeptList_onSearch'});
    },
    onAdd() {
      const fldrCd = this.deptInfo.fldrCd;
      const fldrNm = this.deptInfo.fldrNm;
      const fldrNo = this.deptInfo.fldrNo;
      if (this.deptInfo.cud != '1') {
        this.initDeptInfo();
        this.setDeptInfo({
          cud: '1',
          uppFldrCd: fldrCd,
          uppFldrNm: fldrNm,
          uppFldrNo: fldrNo,
        });
      }
    },
    onSave() {
      this.callEvent({name: 'DeptInfo_saveDeptInfo'});
    },
    async onDelete() {
      this.callEvent({name: 'DeptList_onDelete'});
    },
  },
};
</script>

<style></style>
