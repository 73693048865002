export default that => {
  return {
    Cfg: {
      CanEdit: false,
      HeaderMerge: 6,
    },
    LeftCols: [
      {Name: 'midName', Header: ['공종명', '공종명'], Width: 150, Type: 'Text'},
      {Name: 'itemSeq', Header: ['내역코드', '내역코드'], Width: 70, Type: 'Text', Visible: 0},
      {Name: 'itemName', Header: ['내역명', '내역명'], Width: 150, Type: 'Text', FormulaRow: '합계'},
    ],
    Cols: [
      {
        Name: 'dayAmt',
        Header: ['금일투입', '공급가'],
        Extend: that.$ibsheetHelper.preset.amount,
        MinWidth: 90,
        RelWidth: 1,
        FormulaRow: 'Sum',
      },
      {
        Name: 'dayVatAmt',
        Header: ['금일투입', '부가세'],
        Extend: that.$ibsheetHelper.preset.amount,
        MinWidth: 90,
        RelWidth: 1,
        FormulaRow: 'Sum',
      },
      {
        Name: 'daySum',
        Header: ['금일투입', '합계'],
        Extend: that.$ibsheetHelper.preset.amount,
        MinWidth: 90,
        RelWidth: 1,
        FormulaRow: 'Sum',
      },
      {
        Name: 'monthAmt',
        Header: ['금월투입', '공급가'],
        Extend: that.$ibsheetHelper.preset.amount,
        MinWidth: 90,
        RelWidth: 1,
        FormulaRow: 'Sum',
      },
      {
        Name: 'monthVatAmt',
        Header: ['금월투입', '부가세'],
        Extend: that.$ibsheetHelper.preset.amount,
        MinWidth: 90,
        RelWidth: 1,
        FormulaRow: 'Sum',
      },
      {
        Name: 'monthSum',
        Header: ['금월투입', '합계'],
        Extend: that.$ibsheetHelper.preset.amount,
        MinWidth: 90,
        RelWidth: 1,
        FormulaRow: 'Sum',
      },
      {
        Name: 'totAmt',
        Header: ['누계투입', '공급가'],
        Extend: that.$ibsheetHelper.preset.amount,
        MinWidth: 90,
        RelWidth: 1,
        FormulaRow: 'Sum',
      },
      {
        Name: 'totVatAmt',
        Header: ['누계투입', '부가세'],
        Extend: that.$ibsheetHelper.preset.amount,
        MinWidth: 90,
        RelWidth: 1,
        FormulaRow: 'Sum',
      },
      {
        Name: 'totSum',
        Header: ['누계투입', '합계'],
        Extend: that.$ibsheetHelper.preset.amount,
        MinWidth: 90,
        RelWidth: 1,
        FormulaRow: 'Sum',
      },
      {Name: 'subCodeName', Header: ['투입외주', '투입외주'], Width: 150, Type: 'Text'},
    ],
  };
};
