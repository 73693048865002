<template>
  <div id="h_wrap">
    <div id="h_container_join">
      <header id="h_header" class="header_join">
        <div class="inner_l">
          <h1 class="logo"><span class="blind">빌려쓰는 건설정보 솔루션 EASYCON</span></h1>
        </div>
        <div class="inner_r">
          <a href="#" @click.prevent.self class="join ml10" @click="goLogin">로그인</a>
        </div>
      </header>
      <!-- h_header end -->
      <div class="contents_join">
        <component :is="stepView" :frmGb="frmGb"></component>
      </div>
    </div>
    <!-- h_container_join end -->

    <div id="h_footer_join">
      <div class="copy">COPYRIGHT Ⓒ INOGARD. ALL RIGHTS RESERVED.</div>
    </div>
  </div>
  <!-- h_wrap end -->
</template>

<script>
import joinStep01 from './JoinStep01.vue';
import joinStep02 from './JoinStep02.vue';
import joinStep03 from './JoinStep03.vue';
import joinStep04 from './JoinStep04.vue';
export default {
  data() {
    return {
      frmGb: '',
      stepView: joinStep01,
    };
  },

  methods: {
    goLogin() {
      this.$router.push({path: '/loginHome', params: {}});
    },
    nextStep(step, frmGb) {
      switch (step) {
        case '01':
          this.stepView = joinStep01;
          break;
        case '02':
          this.stepView = joinStep02;
          break;
        case '03':
          this.frmGb = frmGb;
          this.stepView = joinStep03;
          break;
        case '04':
          this.stepView = joinStep04;
          break;
        default:
          this.stepView = joinStep01;
      }
    },
  },
};
</script>

<style></style>
