import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import {SUB_PR_COLUMNS, SUB_RFQ_COLUMNS} from '@/view/bidding/js/BiddingColumns.js';
import {selectSubRfqList, selectSubRfqInfos, selectSubRfqCompareInfos} from '@/view/bidding/rfq/sub/api/subRfq.js';

const BIDDING_STATE = BIDDING_CONSTANTS.PROGRESS_STATE;
const BIDDING_CODES = BIDDING_CONSTANTS.CODES;

const state = {
  subRfqList: [],

  subPrInfo: {...SUB_PR_COLUMNS},
  subRfqInfo: {...SUB_RFQ_COLUMNS},

  subRfqItemList: [],
  subRfqCusList: [],
  compareQuotList: [], // 견적대비정보

  rfqIsNew: false,
};

const getters = {
  bidType: () => 'sub',
  rfqList: state => state.subRfqList,

  prInfo: state => state.subPrInfo,
  rfqInfo: state => state.subRfqInfo,
  rfqStatus: state => getSubRfqStatus(state),

  rfqItemList: state => state.subRfqItemList,
  rfqCusList: state => state.subRfqCusList,
  compareQuotList: state => state.compareQuotList,

  rfqIsNew: state => state.rfqIsNew,
};

const mutations = {
  setRfqList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.subRfqList = payload;
    }
  },

  setRfqInfo(state, payload) {
    const subRfqInfo = state.subRfqInfo;

    for (const key in subRfqInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        subRfqInfo[key] = payload[key];
      }
    }

    subRfqInfo.rfqNo2 = state.rfqIsNew ? BIDDING_CODES.AUTO : COMMON_FUNCTION.getMakeFormat(subRfqInfo.rfqNo);
  },
  initRfqInfo(state) {
    state.subRfqInfo = {...SUB_RFQ_COLUMNS};
  },
  setPrInfo(state, payload) {
    for (let key in state.subPrInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.subPrInfo[key] = payload[key];
      }
    }
    state.subPrInfo.prNo2 = COMMON_FUNCTION.getMakeFormat(state.subPrInfo.prNo);
  },
  initPrInfo(state) {
    state.subPrInfo = {...SUB_PR_COLUMNS};
  },

  setRfqItemList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.subRfqItemList = payload;
    }
  },
  setRfqCusList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.subRfqCusList = payload;
    }
  },
  setCompareQuotList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.compareQuotList = payload;
    }
  },
  setRfqIsNew(state, payload) {
    if (typeof payload === 'boolean') {
      state.rfqIsNew = payload;
    }
  },
};

const actions = {
  initRfq({commit}) {
    commit('setRfqIsNew', false);
    commit('initPrInfo');
    commit('initRfqInfo');
    commit('setRfqItemList', []);
    commit('setRfqCusList', []);
  },
  async searchRfqList({commit, dispatch}, searchOptions) {
    const subRfqList = (await selectSubRfqList(searchOptions)).data;
    commit('setRfqList', subRfqList);

    if (!subRfqList.length) {
      dispatch('initRfq');
    }
  },
  async searchRfqInfos({getters, commit, dispatch}, rfqNo) {
    dispatch('initRfq');

    const response = await selectSubRfqInfos(rfqNo);
    commit('setRfqInfo', response.data.subRfqInfo);
    commit('setRfqItemList', response.data.subRfqItemList);
    commit('setRfqCusList', response.data.subRfqCusList);

    const subPrInfo = response.data.subPrInfo;
    subPrInfo ? commit('setPrInfo', subPrInfo) : commit('initPrInfo');

    if (getters.rfqStatus.isAfterRfqOpen) {
      dispatch('searchCompareQuotList');
    }
  },
  async searchCompareQuotList(context) {
    const rfqInfo = context.getters.rfqInfo;
    const response = await selectSubRfqCompareInfos(rfqInfo.rfqNo, rfqInfo.bidRnd);
    const compareQuotList = response.data.compareQuotList;
    context.commit('setCompareQuotList', compareQuotList || []);
  },
};

function getSubRfqStatus(state) {
  const prInfo = state.subPrInfo;
  const rfqInfo = state.subRfqInfo;
  const rfqSt = rfqInfo.rfqSt;

  const status = {
    isExistOnlyPr: !!prInfo.prNo && !rfqInfo.rfqNo, // 구매요청만 존재
    isNotExistPr: !prInfo.prNo, // 구매요청 부재
    isExistRfqNo: !!rfqInfo.rfqNo,
    isReBid: 0 < rfqInfo.lastBidRnd,

    // 입찰 진행상태
    isRfqReady: rfqSt === BIDDING_STATE.RFQ_READY, // 공고작성중
    isRfqApprovalComplete: rfqSt === BIDDING_STATE.RFQ_APPROVAL_COMPLETE, // 공고품의완료
    isRfqSpotDscr: rfqSt === BIDDING_STATE.RFQ_SPOT_DSCR, // 현설통보
    isRfqSpotDscrExpired: rfqSt === BIDDING_STATE.RFQ_SPOT_DSCR_EXPIRED, // 현설마감
    isRfqSpotDscrClose: rfqInfo.isCloseSpot, // 현설마감여부
    isRfqBidding: rfqSt === BIDDING_STATE.RFQ_BIDDING, // 입찰중
    isRfqBiddingExpired: rfqSt === BIDDING_STATE.RFQ_BIDDING_EXPIRED, // 입찰마감
    isBeforeRfqOpen: !!rfqSt && rfqSt < BIDDING_STATE.RFQ_OPEN, // 개봉 전
    isRfqOpen: rfqSt === BIDDING_STATE.RFQ_OPEN, // 개봉
    isAfterRfqOpen: !!rfqSt && rfqSt >= BIDDING_STATE.RFQ_OPEN, // 개봉 이후
    isRfqChoice: rfqSt === BIDDING_STATE.RFQ_CHOICE, // 낙찰사등록
    isRfqApprovalChoice: rfqSt === BIDDING_STATE.RFQ_APPROVAL_CHOICE, // 낙찰사선정품의
    isRfqFail: rfqSt === BIDDING_STATE.RFQ_FAIL, // 유찰
    isRfqComplete: rfqSt === BIDDING_STATE.RFQ_COMPLETE, // 입찰완료
    isCancel: rfqInfo.cancelYn === BIDDING_CODES.RFQ_CANCEL_Y, // 입찰취소

    isExistSpotDscr: rfqInfo.spotDscrYn === BIDDING_CODES.SPOT_DSCR_Y, // 현설유무 - 있음
    isTaxExemption: rfqInfo.taxTypecd === BIDDING_CODES.TAX_EXEMPTION, // 과세유형 : 비과세
    isTaxDirect: rfqInfo.taxTypecd === BIDDING_CODES.TAX_DIRECT, // 과세유형 - 직접 입력
    isTaxDirectPercent: rfqInfo.taxDirectDs === BIDDING_CODES.TAX_DIRECT_PERCENT, // 과세유형 - 직접 입력(%)
    isTaxDirectAmt: rfqInfo.taxDirectDs === BIDDING_CODES.TAX_DIRECT_AMOUNT, // 과세유형 : 직접입력(원)
  };

  status['isExistOnlyRfq'] = status.isNotExistPr && !!rfqInfo.rfqNo;
  status['isEnable'] = state.rfqIsNew || status.isExistOnlyPr || status.isRfqReady;
  status['isEnableChoice'] = status.isRfqOpen || status.isRfqChoice; // 낙찰사등록(변경) 가능
  status['isEnableSpotCancel'] =
    status.isExistSpotDscr &&
    status.isRfqSpotDscrClose &&
    status.isRfqBidding &&
    !status.isReBid &&
    rfqInfo.quotCusCount === 0; // 현설결과취소 가능여부

  return status;
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
