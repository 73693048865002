<template>
  <iui-content-box :loading="loading">
    <iui-container-new type="css-flex">
      <i-row :height="frmGb1 || frmGb4 ? '350px' : '300px'">
        <i-col>
          <CompanyInfo2 :enable="enable" />
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <i-row>
            <HoldLcnsInfo v-if="frmGb1 || frmGb4" :enable="enable" />
            <AreaInfo v-else-if="frmGb2 || frmGb3" :title="frmGb2 ? '공사지역정보' : '납품지역정보'" :enable="enable" />
            <WorkTypeInfo v-if="frmGb2" :enable="enable" />
            <MtrilInfo v-if="frmGb3" :enable="enable" />
            <SystemUserInfo :enable="!enable" />
            <AdminInfo :enable="enable" />
          </i-row>
        </i-col>
        <i-spacer />
        <i-col>
          <i-row>
            <DocManageInfo :enable="enable" />
          </i-row>
        </i-col>
      </i-row>
    </iui-container-new>
  </iui-content-box>
</template>

<script>
import CompanyInfo2 from './CompanyInfo2.vue';
import HoldLcnsInfo from './HoldLcnsInfo.vue';
import AreaInfo from './AreaInfo.vue';
import MtrilInfo from './MtrilInfo.vue';
import WorkTypeInfo from './WorkTypeInfo.vue';
import DocManageInfo from './DocManageInfo.vue';
import SystemUserInfo from './SystemUserInfo.vue';
import AdminInfo from './AdminInfo.vue';
import {selectCompanyInfo} from '../api/member.js';
export default {
  components: {
    CompanyInfo2,
    HoldLcnsInfo,
    AreaInfo,
    MtrilInfo,
    WorkTypeInfo,
    DocManageInfo,
    SystemUserInfo,
    AdminInfo,
  },
  props: {
    enable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'worker', 'dupBsnNoStatus', 'docnmList']);
    $mapMutations(this, [
      'setCompanyInfo',
      'setWorker',
      'setAreaList',
      'setMatList',
      'setGcodeList',
      'setLicenseList',
      'setDocnmList',
      'setCeoList',
    ]);
  },
  created() {
    this.addEvent([{name: 'selectCompanyInfo', func: this.selectCompanyInfo}]);
    this.addFuncSearch(this.selectCompanyInfo);
  },
  methods: {
    async selectCompanyInfo() {
      this.loading = true;
      this.$recompute('pgmCd');
      const response = await selectCompanyInfo({pgmCd: this.pgmCd});
      this.companyData = response.data;
      this.setCompanyInfo(this.companyData);
      this.setWorker(this.companyData.worker);
      if (this.companyData.licenseList) {
        this.setLicenseList(this.companyData.licenseList);
      }
      if (this.companyData.areaList) {
        this.setAreaList(this.companyData.areaList);
      }
      if (this.companyData.gcodeList) {
        this.setGcodeList(this.companyData.gcodeList);
      }
      if (this.companyData.matList) {
        this.setMatList(this.companyData.matList);
      }
      if (this.companyData.docnmList) {
        this.setDocnmList(this.companyData.docnmList);
      }
      if (this.companyData.ceoList) {
        this.setCeoList(this.companyData.ceoList);
      }
      this.loading = false;
    },
  },
  computed: {
    frmGb1() {
      return this.companyInfo.frmGb == $getConstants('FRM_GB_1').code; // 건설사
    },
    frmGb2() {
      return this.companyInfo.frmGb == $getConstants('FRM_GB_2').code; // 공사용역
    },
    frmGb3() {
      return this.companyInfo.frmGb == $getConstants('FRM_GB_3').code; // 물품자재
    },
    frmGb4() {
      return this.companyInfo.frmGb == $getConstants('FRM_GB_4').code; // 건설사 + 공사용역
    },
    frmGb0() {
      return this.companyInfo.frmGb == $getConstants('FRM_GB_0').code; // 운영사
    },
  },
};
</script>

<style scoped></style>
