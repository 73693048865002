<template>
  <pmis-content-box>
    <template #title>인지세납부정보</template>
    <template #title-right>
      <iui-button value="홈택스 바로가기" @click="openHometaxSite" />
      <iui-button value="전자수입인지 사이트 바로가기" @click="openEtsSite" />
    </template>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <iui-container-new type="table" theme="bullet">
            <colgroup>
              <col width="110px" />
              <col />
              <col width="10px" />
              <col width="90px" />
              <col />
            </colgroup>
            <i-row>
              <i-col-header>납부당사자</i-col-header>
              <i-col colspan="4">
                <iui-text :value="stampInfo.stampBsnNo" :enable="false" width="120px" />
                <iui-text :value="stampInfo.payer" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>현장명</i-col-header>
              <i-col colspan="4">
                <iui-text :value="poInfo.spotDeptcd" :enable="false" width="120px" />
                <iui-text :value="poInfo.spotDeptnm" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>계약번호</i-col-header>
              <i-col colspan="4">
                <iui-text :value="poInfo.poNo2" :enable="false" width="120px" />
                <iui-text :value="poInfo.ttl" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>계약금액</i-col-header>
              <i-col>
                <iui-text type="amount" :value="poInfo.totAmt" :enable="false" width="145px" />
              </i-col>
              <i-spacer />
              <i-col-header>인지세액</i-col-header>
              <i-col>
                <iui-text type="amount" :value="poInfo.stampTaxAmt" :enable="false" width="145px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header required>납부금액</i-col-header>
              <i-col colspan="4">
                <iui-text
                  type="amount"
                  :value.sync="stampInfo.stampPaymentAmt"
                  width="145px"
                  required
                  :errorMessage="{title: '납부금액', message: '필수입력입니다.'}"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header required>인지세 고유번호</i-col-header>
              <i-col colspan="4">
                <iui-text
                  :value.sync="stampInfo.stampNo"
                  @change="isDuplicateCheck = false"
                  max-length="20"
                  width="200px"
                  required
                  :errorMessage="{title: '인지세 고유번호', message: '필수입력입니다.'}"
                />
                <iui-button value="중복체크" @click="onDuplicateStampNo" />
                <h4>({{ stampNoCharCount }} 자리 입력됨)</h4>
              </i-col>
            </i-row>
            <i-row>
              <i-col colspan="5">
                <label class="ml15">
                  ※ 접수번호 또는 수입인지 고유식별번호 - (기호제외)
                </label>
              </i-col>
            </i-row>
            <i-row>
              <i-col colspan="5">
                <label class="ml15">
                  ※ 16자리_전자수입인지 / 20자리_국세청홈택스
                </label>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row height="140px">
              <i-col>
                <pmis-file-list
                  id="stampFile"
                  theme="bullet"
                  title="첨부파일"
                  :companyCode="poInfo.orgCd"
                  :file-number="stampInfo.stampFlNo"
                  flNoCol="stampFlNo"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col>
                <iui-container-new type="table" theme="bullet">
                  <i-row>
                    <i-col>- 홈택스에서 납부한 경우 : 접수증과 납부확인서 스캔한 후 업로드해주세요.</i-col>
                  </i-row>
                  <i-row>
                    <i-col>- 전자수입인지의 경우 : 전자수입인지를 스캔한 후 업로드해주세요.</i-col>
                  </i-row>
                </iui-container-new>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import {OFFLINE_STAMP_COLUMNS} from '@/view/bidding/js/BiddingColumns';
import {insertOfflineStampInfo, selectCheckStampNo, updateOfflineStampInfo} from '@/view/bidding/po/mat/api/po';
import {
  insertOfflineStampInfo as insertOfflineStampInfo_sub,
  selectCheckStampNo as selectCheckStampNo_sub,
  updateOfflineStampInfo as updateOfflineStampInfo_sub,
} from '@/view/bidding/po/sub/api/subPo';
import IRow from '@/components/Iui/components/IuiContainer/IRow';
import ICol from '@/components/Iui/components/IuiContainer/ICol';

export default {
  components: {ICol, IRow},
  data() {
    return {
      isDuplicateCheck: false, // 중복체크여부
      stampInfo: {...OFFLINE_STAMP_COLUMNS},
    };
  },
  computed: {
    stampTaxOwnAmt() {
      const frmGb = this.userInfo.frmGb;
      const stampTaxOwnRate = this.poInfo[
        frmGb === $getConstants('FRM_GB_1').code ? 'stampTaxOwnRate' : 'stampTaxOwnRate2'
      ];
      return (this.poInfo.stampTaxAmt * stampTaxOwnRate) / 100;
    },
    stampNoCharCount() {
      return this.stampInfo.stampNo.length;
    },
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'bidType']);
  },
  created() {
    this.stampInfo.poNo = this.poInfo.poNo;
    this.stampInfo.poNo2 = this.poInfo.poNo2;
    this.stampInfo.contSeq = this.poInfo.contSeq;
    this.stampInfo.stampContNo = this.poInfo.poNo.substring(8);
    this.stampInfo.stampContTtl = this.poInfo.ttl;
    this.stampInfo.stampBsnNo = this.userInfo.bsnNo; // 납부당사자
    this.stampInfo.payer = this.userInfo.frmNm;
    this.stampInfo.stampContAmt = this.poInfo.totAmt;

    this.stampInfo.stampTaxAmt = this.poInfo.stampTaxAmt;
    this.stampInfo.stampPaymentAmt = ''; // 납부금액
    this.stampInfo.stampNo = ''; // 인지세 고유번호
    this.stampInfo.stampFlNo = 0;
    this.stampInfo.rgprId = this.userInfo.empNo;
  },
  methods: {
    openHometaxSite() {
      window.open('http://www.hometax.go.kr');
    },
    openEtsSite() {
      window.open('http://www.e-revenuestamp.or.kr');
    },
    async onDuplicateStampNo() {
      const stampNo = this.stampInfo.stampNo;
      if (!stampNo) {
        this.$alert({title: '인지세 고유번호 중복체크', message: '인지세 고유번호를 입력해야합니다.'});
        return;
      }
      if (/\D/.test(stampNo)) {
        this.$alert({title: '인지세 고유번호 중복체크', message: '인지세 고유번호는 숫자만 입력가능합니다.'});
        return;
      }
      if (stampNo.length != 16 && stampNo.length != 20) {
        this.$alert({
          title: '인지세 고유번호 중복체크',
          message: '인지세 고유번호는 16자리 또는 20자리로 입력해야 합니다. 다시 한 번 확인하시기 바랍니다.',
        });
        return;
      }

      const selectCheckStampNoApi = {mat: selectCheckStampNo, sub: selectCheckStampNo_sub};
      const response = await selectCheckStampNoApi[this.bidType](stampNo);
      if (0 === response.data) {
        this.isDuplicateCheck = true;
        this.$alert({title: '인지세 고유번호 중복체크', message: '등록가능한 인지세 고유번호 입니다.'});
      } else {
        this.isDuplicateCheck = false;
        this.$alert({
          title: '인지세 고유번호 중복체크',
          message: '중복된 인지세 고유번호입니다.<br/>다시 한 번 확인하시기 바랍니다.',
        });
      }
    },
    async onSaveStamp(callback) {
      const afterValidation = () => {
        if (!this.isDuplicateCheck) {
          this.$alert({title: '인지세 고유번호 중복체크', message: '인지세 고유번호 중복체크를 해야합니다.'});
          return false;
        }
        return true;
      };

      const insertApi = {mat: insertOfflineStampInfo, sub: insertOfflineStampInfo_sub};
      const updateApi = {mat: updateOfflineStampInfo, sub: updateOfflineStampInfo_sub};
      const saveApi = insertApi[this.bidType];
      saveApi(this.stampInfo, afterValidation)
        .then(response => {
          if (response.data) {
            callback();
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
  },
};
</script>
