export const selectPrjBreakdownCostList = param => axios.post('/prjBreakdownCost/selectPrjBreakdownCostList', param);
export const selectPrjBreakdownCostListDtls = param =>
  axios.post('/prjBreakdownCost/selectPrjBreakdownCostListDtls', param);
export const saveContSctm = param => axios.post('/prjBreakdownCost/saveContSctm', param);
export const saveContSctmD = param => axios.post('/prjBreakdownCost/saveContSctmD', param);
export const deleteContSctm = param => axios.post('/prjBreakdownCost/deleteContSctm', param);
export const deleteContSctmD = param => axios.post('/prjBreakdownCost/deleteContSctmD', param);
export const updateCalcContSctm = param => axios.post('/prjBreakdownCost/updateCalcContSctm', param);
export const copyContSctmD = param => axios.post('/prjBreakdownCost/copyContSctmD', param);
export const copyContSctm = param => axios.post('/prjBreakdownCost/copyContSctm', param);
export const selectOtherPrjBreakDown = param => axios.post('/prjBreakdownCost/selectOtherPrjBreakDown', param);
export const copyPrjContSctm = param => axios.post('/prjBreakdownCost/copyPrjContSctm', param);
export const updateContSctmByUcstApply = param => axios.post('/prjBreakdownCost/updateContSctmByUcstApply', param);
