<template>
  <div style="height:100%">
    <div class="p_head">
      <slot name="title" />
      <slot name="link" />
    </div>

    <div class="p_content">
      <template v-if="type === 'single'">
        <slot />
      </template>
      <template v-else-if="type === 'multi'">
        <div class="p_tab">
          <ul>
            <li
              v-for="(comp, index) in comps"
              :key="index"
              :class="index == activeTab ? 'select' : ''"
              @click="changeTab(index)"
            >
              <a href="#">{{ comp.tabNm }} </a>
              <a v-if="comp.unchkedCnt !== undefined">({{ comp.unchkedCnt }})</a>
            </li>
          </ul>
          <div class="middle">
            <slot name="tab" />
          </div>
        </div>

        <div class="port_srch" v-if="headerVisible">
          <div v-if="headerLeftVisible" class="header-left">
            <slot name="header-left" />
          </div>
          <div v-if="headerCenterVisible" class="header-center">
            <slot name="header-center" />
          </div>
          <div v-if="headerRightVisible" class="header-right">
            <slot name="header-right" />
          </div>
        </div>

        <div :style="innerStyle">
          <slot name="content" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'single',
    },
    comps: {
      type: [Array, Object],
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      innerStyle: '',
    };
  },
  computed: {
    headerVisible() {
      return this.headerLeftVisible || this.headerCenterVisible || this.headerRightVisible;
    },
    headerLeftVisible() {
      return this.$slots['header-left'] !== undefined;
    },
    headerCenterVisible() {
      return this.$slots['header-center'] !== undefined;
    },
    headerRightVisible() {
      return this.$slots['header-right'] !== undefined;
    },
  },
  methods: {
    changeTab(index) {
      this.$emit('update:activeTab', index);
    },
  },
  mounted() {
    const innerHeight = this.headerVisible ? 'calc(100% - 81px)' : 'calc(100% - 38px)';

    this.innerStyle = {height: innerHeight};
  },
};
</script>

<style></style>
