<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <label>실적일자</label>
      <div class="ml5"></div>
      <iui-datepicker name="resultDate" :value="searchInfo.resultDate" @change="onResultDateChange" />
      <i class="prev-arrow" @click="onClickPrev" v-if="searchInfo.resultDate" />
      <i class="post-arrow" @click="onClickNext" v-if="searchInfo.resultDate" />
      <div class="ml15"></div>
      <label>예정기간</label>
      <div class="ml5"></div>
      <iui-datepicker
        :value="searchInfo.planStrdate"
        :group="{grpNm: 'date', seq: 0}"
        @change="setSearchInfo({planStrdate: $event})"
      />
      <span class="ml5 mr5">~</span>
      <iui-datepicker
        :value="searchInfo.planEnddate"
        :group="{grpNm: 'date', seq: 1}"
        @change="setSearchInfo({planEnddate: $event})"
      />
      <iui-button value="검색" @click="initSheet" v-if="searchInfo.resultDate" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="Options"
      :autoRender="false"
      :init.sync="init"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onRenderFirstFinish}"
    />
  </pmis-content-box>
</template>

<script>
import {selectContWplanExecSheetDataList, selectContWplanExecResultDate} from '../api/acmsltVolmRegist.js';
import options from './sheetOptions/UnitOpertExecCntSheet.js';
export default {
  data() {
    return {
      sheet: undefined,
      init: false,
      Options: {},
      loadSearchData: [],
      resultDateList: [],
    };
  },

  watch: {
    searchInfo: {
      handler() {
        this.setButton();
      },
      deep: true,
    },
  },

  beforeCreate() {
    $mapGetters(this, ['searchInfo']);
    $mapMutations(this, ['setSearchInfo']);
  },

  created() {
    this.addEvent([
      {name: 'getChangedDatas', func: this.getChangedDatas},
      {name: 'unitOpertExecCntSheetInq', func: this.initSheet},
      {name: 'sheetDeleteRed', func: this.sheetDeleteRed},
      {name: 'getResultDate', func: this.getResultDate},
    ]);
    this.addFuncPrj(this.getResultDate);
  },

  mounted() {
    if (Object.keys(this.$store.getters['externalData']).length > 0) {
      let externalParam = this.$store.getters['externalData'];
      this.setSearchInfo(externalParam);
      this.onResultDateChange(externalParam.date);
      externalParam = undefined;
      this.$store.dispatch('setExternalData', {});
    }
    this.setButton();
  },

  methods: {
    async getResultDate() {
      const response = await selectContWplanExecResultDate({});
      this.resultDateList = response.data;
    },
    async initSheet() {
      this.Options = await options(this);
      this.init = true;
    },
    async loadSheet(sheet) {
      this.sheet = sheet;
      this.loadSearchData = [];
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    async onSearch() {
      const param = {
        resultDate: this.searchInfo.resultDate.replace(/-/g, ''),
        planStrdate: this.searchInfo.planStrdate.replace(/-/g, ''),
        planEnddate: this.searchInfo.planEnddate.replace(/-/g, ''),
      };
      const sheetData = await selectContWplanExecSheetDataList(param);
      if (sheetData.data.planStrdate) {
        this.setSearchInfo({planStrdate: sheetData.data.planStrdate});
      }
      if (sheetData.data.planEnddate) {
        this.setSearchInfo({planEnddate: sheetData.data.planEnddate});
      }
      this.loadSearchData = sheetData.data.sheetDataList;
      this.loading = false;
    },
    getChangedDatas(callback) {
      if (callback) {
        let datas = [];
        for (let obj of this.sheet.getSaveJson().data) {
          let cols = this.sheet.getCols('Visible');
          let row = this.sheet.getRowById(obj.id);
          for (let key of cols) {
            if (row[key + 'Changed']) {
              datas.push({
                pgmCd: this.pgmCd,
                prjCd: this.prjCd,
                wbsId: key.split('_')[0],
                wbsCd: key.split('_')[1],
                wcode: row.wcode,
                headCode: row.headCode,
                midCode: row.midCode,
                itemSeq: row.itemSeq,
                itemCode: row.itemCode,
                resultQty: row[key],
                resultDate: this.searchInfo.resultDate.replace(/-/g, ''),
              });
            }
          }
        }

        callback(datas);
      }
    },
    sheetDeleteRed() {
      this.sheet.getDataRows().forEach(row => {
        for (let key in row) {
          if (key.indexOf('CanEdit') != -1 && row[key]) {
            this.sheet.setAttribute(row, key.replace('CanEdit', ''), 'Color', '#fc0303');
          }
        }
      });
    },
    sheetSearch() {
      let filter = document.querySelector(`#workType${this._uid}`).value;
      if (filter) {
        this.sheet.setFilter({
          name: 'midCode',
          filter: "midCode=='" + filter + "'?1:0",
          render: 1,
        });
      } else {
        this.sheet.clearFilter();
      }
    },
    onResultDateChange(date) {
      this.setSearchInfo({resultDate: date, planStrdate: '', planEnddate: ''});
      this.initSheet();
    },
    setButton() {
      if (!this.searchInfo.resultDate) {
        this.canSave = false;
        this.canDelete = false;
      } else {
        this.canSave = true;
        this.canDelete = true;
      }
    },
    onClickPrev() {
      if (!this.resultDateList.length) {
        return;
      }
      const resultList = this.resultDateList.filter(
        resultDate => Number(resultDate) < Number(this.searchInfo.resultDate)
      );
      const resultDate = resultList[resultList.length - 1];
      if (resultDate) {
        this.onResultDateChange(resultDate);
      }
    },
    onClickNext() {
      if (!this.resultDateList.length) {
        return;
      }
      const resultList = this.resultDateList.filter(
        resultDate => Number(resultDate) > Number(this.searchInfo.resultDate)
      );
      const resultDate = resultList[0];
      if (resultDate) {
        this.onResultDateChange(resultDate);
      }
    },
  },
};
</script>
