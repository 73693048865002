export default that => {
  return {
    Def: {Row: {VAlign: 'TOP'}},
    Cfg: {HeaderMerge: 3, CanEdit: 0},
    Cols: [
      {Name: 'total', Visible: 1, Width: 1, CanFocus: 0, ShowHint: 0, Class: '_total'},
      {Name: 'lgName', Visible: 1, Width: 1, CanFocus: 0, ShowHint: 0, Class: '_total'},
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, {Value: '공종명'}],
        Name: 'mgName',
        Type: 'Text',
        Width: 200,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, {Value: '내역명'}],
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, {Value: '규격'}],
        Name: 'ssize',
        Type: 'Text',
        Width: 120,

        Align: 'Center',
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, {Value: '단위'}],
        Name: 'unit',
        Type: 'Text',
        Width: 100,

        Align: 'Center',
        Format: $getConstants('UNIT').code,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, {Value: '수량'}],
        Name: 'contQty',
        Extend: that.$ibsheetHelper.preset.quantity,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, {Value: '단가'}],
        Name: 'contPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, {Value: '금액'}],
        Name: 'contAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: [{Value: '도급기성계획', Color: '#7ed6df'}, {Value: '수량'}],
        Name: 'planContQty',
        Extend: that.$ibsheetHelper.preset.quantity,
      },
      {
        Header: [{Value: '도급기성계획', Color: '#7ed6df'}, {Value: '금액'}],
        Name: 'planContAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {Name: 'headCode', Visible: 0},
      {Name: 'midCode', Visible: 0},
    ],
  };
};
