class ValidatorErrorMessage {
  constructor(error) {
    if (!error) error = {};
    this.title = error.title || '';
    this.defaultMessage = error.message || '';
    this.required = error.required || error.message;
    this.min = error.min;
    this.max = error.max;
    this.minLength = error.minLength;
    this.maxLength = error.maxLength;
    this.pattern = error.pattern;
    this.success = '';
    this.currentErrorMessage = '';
  }

  getErrorMessage(rule) {
    let errorMessage = this[rule] || this.defaultMessage;
    return errorMessage;
  }
}

export default ValidatorErrorMessage;
