<template>
  <div>
    <!-- 작업현황-포틀릿 -->
    <div class="title">
      <label>직영투입비</label>
      <label>금액단위: 원</label>
    </div>
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr>
          <th>구분</th>
          <th>재료비</th>
          <th>노무비</th>
          <th>장비비</th>
          <th>경비</th>
          <th>합계</th>
        </tr>
      </thead>
    </table>
    <div class="inner">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="data in summaryList" :key="data.rowTypeName">
            <td>{{ data.rowTypeName }}</td>
            <td>{{ data.materialAmt }}</td>
            <td>{{ data.laborAmt }}</td>
            <td>{{ data.equipmentAmt }}</td>
            <td>{{ data.expenseAmt }}</td>
            <td class="p_last">{{ data.sumAmt }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="title">
      <label>외주투입비</label>
      <label>금액단위: 원</label>
    </div>
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr>
          <th>외주명</th>
          <th>계약금액</th>
          <th>금일</th>
          <th>금월</th>
          <th>누계</th>
          <th>잔여</th>
        </tr>
      </thead>
    </table>
    <div class="inner">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt1})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="data in workGroupList" :key="data.subCode">
            <td>{{ data.subCodeName }}</td>
            <td>{{ data.lstCtrtTotAmt }}</td>
            <td>{{ data.dayAmt }}</td>
            <td>{{ data.monthAmt }}</td>
            <td>{{ data.totAmt }}</td>
            <td class="p_last">{{ data.remainAmt }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="title">
      <label>작업내용</label>
    </div>
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt2} * 3)`" />
        <col :style="`width:calc(${tblWidth}px/${cnt2})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr>
          <th>작업명</th>
          <th>예정수량</th>
          <th>실적수량</th>
        </tr>
      </thead>
    </table>
    <div class="inner">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt2} * 3)`" />
          <col :style="`width:calc(${tblWidth}px/${cnt2})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="(data, index) in unitWorkExecList" :key="index">
            <td>{{ data.wbsNm }}</td>
            <td>{{ data.planCnt || 0 }}</td>
            <td class="p_last">{{ data.resultQty || 0 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
import {selectWorkSummary} from '@/components/portlet/api/portletData.js';

export default {
  mixins: [PortletMixin],
  props: {
    curDate: {
      Type: String,
    },
  },
  data() {
    return {
      cnt1: 6,
      cnt2: 5,

      summaryList: [],
      workGroupList: [],
      unitWorkExecList: [],
    };
  },
  watch: {
    curDate() {
      this.onSearch();
    },
  },
  methods: {
    setWidth() {
      this.tblWidth = this.$parent.$el.offsetWidth - 28;
    },
    async onSearch() {
      const response = await selectWorkSummary({prjCd: this.prjCd, ymd: this.curDate});
      const summary = response.data;
      const summaryList = [];
      summaryList.push({
        rowTypeName: '금일',
        materialAmt: summary.materialDayAmt,
        laborAmt: summary.laborDayAmt,
        equipmentAmt: summary.equipmentDayAmt,
        expenseAmt: summary.expenseDayAmt,
        sumAmt: summary.sumDayAmt,
        sumRatePerCont: summary.sumDayRatePerCont,
        sumRatePerExec: summary.sumDayRatePerExec,
      });
      summaryList.push({
        rowTypeName: '금월',
        materialAmt: summary.materialMonthAmt,
        laborAmt: summary.laborMonthAmt,
        equipmentAmt: summary.equipmentMonthAmt,
        expenseAmt: summary.expenseMonthAmt,
        sumAmt: summary.sumMonthAmt,
        sumRatePerCont: summary.sumMonthRatePerCont,
        sumRatePerExec: summary.sumMonthRatePerExec,
      });
      summaryList.push({
        rowTypeName: '누계',
        materialAmt: summary.materialTotAmt,
        laborAmt: summary.laborTotAmt,
        equipmentAmt: summary.equipmentTotAmt,
        expenseAmt: summary.expenseTotAmt,
        sumAmt: summary.sumTotAmt,
        sumRatePerCont: summary.sumTotRatePerCont,
        sumRatePerExec: summary.sumTotRatePerExec,
      });

      this.summaryList = summaryList;
      this.workGroupList = response.data.workGroupList;
      this.unitWorkExecList = response.data.unitWorkExecList;
    },
  },
};
</script>

<style scoped>
.title {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 5px 5px;
}
</style>
