const state = {
  modalNames: [],
};
const getters = {
  modalName: state => (state.modalNames.length ? state.modalNames[state.modalNames.length - 1] : null),
  modalNames: state => state.modalNames,
};
const mutations = {
  addModalNames(state, payload) {
    state.modalNames.push(payload);
  },
  removeModalNames(state, payload) {
    const modalNames = state.modalNames.filter(modalName => modalName != payload);
    state.modalNames = [...modalNames];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
