const state = {
  prjList: [],
  loadPrj: {},
  runFunc: {},
};
const getters = {
  prjList: state => state.prjList,
  loadPrj: state => state.loadPrj,
  currentUrl: rootState => rootState.currentUrl,
};
const mutations = {
  setPrjList(state, payload) {
    state.prjList = payload;
  },
  setLoadPrj(state, payload) {
    state.loadPrj[payload.currentUrl] = payload.prjInfo;
  },
  addFuncPrj(state, payload) {
    const index = state.runFunc[payload.currentUrl]?.findIndex(func => func.name === payload.func.name) ?? -1;
    if (index !== -1) {
      state.runFunc[payload.currentUrl][index] = payload.func;
      return;
    }
    state.runFunc[payload.currentUrl] = [...(state.runFunc[payload.currentUrl] || []), payload.func];
  },
  init(state, currentUrl) {
    delete state.loadPrj[currentUrl];
  },
  destroy(state, currentUrl) {
    delete state.runFunc[currentUrl];
    delete state.loadPrj[currentUrl];
  },
  destroyAll(state) {
    state.runFunc = {};
    state.loadPrj = {};
  },
};
const actions = {
  setLoadPrj({commit, rootState}, payload) {
    if (payload?.prjEstCd) {
      payload.prjCd = String(payload.prjEstCd);
    }
    if (payload?.prjEstNm) {
      payload.prjNm = String(payload.prjEstNm);
    }
    if (payload?.prjCd) {
      payload.prjCd = String(payload.prjCd);
    }
    commit('setLoadPrj', {currentUrl: rootState.currentUrl, prjInfo: payload});
  },

  addFuncPrj({commit, rootState}, func) {
    commit('addFuncPrj', {currentUrl: rootState.currentUrl, func: func});
  },
  runFunc({state, rootState}) {
    if (state.runFunc[rootState.currentUrl] instanceof Array) {
      state.runFunc[rootState.currentUrl].forEach(func => func());
    }
  },
  async search({commit}, payload) {
    try {
      const response = await axios.post('/cntrctInfoRegist/selectCntrctInfoList', payload);
      if (response.status == 200) {
        const prjList = response.data;
        commit('setPrjList', prjList);
      }
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }
  },
  async loadPrj({getters, rootState}) {
    return new Promise((resolve, reject) => {
      let timeIndex = 0;
      let load = setInterval(() => {
        timeIndex++;
        const prjCd = getters.loadPrj[rootState.currentUrl];
        if (prjCd) {
          resolve(prjCd);
          clearInterval(load);
        }
        if (timeIndex > 2000) {
          clearInterval(load);
        }
      }, 10);
    });
  },
  init({commit, rootState}) {
    commit('init', rootState.currentUrl);
    commit('setPrjList', []);
  },
  destroy({commit}, url) {
    commit('destroy', url);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
