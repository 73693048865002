<template>
  <iui-tab :comps="tabComps" :keepAlive="true" @click="setTabIndex"></iui-tab>
</template>

<script>
export default {
  props: {
    itemCode: {
      type: String,
    },
  },
  data() {
    return {
      tabComps: [
        {
          tabNm: '자재',
          compNm: 'ResourceList1',
          paramObj: {costType: $getConstants('COST_TYPE_M').code},
          path: '@/view/estmtManage/resrceManage/popup/components/ResourceList.vue',
        },
        {
          tabNm: '노무',
          compNm: 'ResourceList2',
          paramObj: {costType: $getConstants('COST_TYPE_L').code},
          path: '@/view/estmtManage/resrceManage/popup/components/ResourceList.vue',
        },
        {
          tabNm: '장비비',
          compNm: 'ResourceList3',
          paramObj: {costType: $getConstants('COST_TYPE_E').code},
          path: '@/view/estmtManage/resrceManage/popup/components/ResourceList.vue',
        },
        {
          tabNm: '경비',
          compNm: 'ResourceList4',
          paramObj: {costType: $getConstants('COST_TYPE_A').code},
          path: '@/view/estmtManage/resrceManage/popup/components/ResourceList.vue',
        },
        {
          tabNm: '일위대가',
          compNm: 'ResourceList5',
          paramObj: {costType: $getConstants('COST_TYPE_I').code, itemCode: this.itemCode},
          path: '@/view/estmtManage/resrceManage/popup/components/ResourceList.vue',
        },
      ],
      costTypes: [
        $getConstants('COST_TYPE_M').code,
        $getConstants('COST_TYPE_L').code,
        $getConstants('COST_TYPE_E').code,
        $getConstants('COST_TYPE_A').code,
        $getConstants('COST_TYPE_I').code,
      ],
    };
  },

  created() {},

  methods: {
    setTabIndex(index) {
      this.$emit('costType', this.costTypes[index]);
    },
  },
};
</script>

<style></style>
