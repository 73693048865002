<template>
  <!-- 자재투입-포틀릿 -->
  <div>
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr>
          <th>자재명</th>
          <th>규격</th>
          <th>단위</th>
          <th>수량</th>
          <th>금액</th>
        </tr>
      </thead>
    </table>
    <div class="inner">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="(item, index) in matInputList" :key="index">
            <td>{{ item.matNm }}</td>
            <td>{{ item.matSize }}</td>
            <td>{{ item.matUnit }}</td>
            <td>{{ item.mdQty }}</td>
            <td class="p_last">{{ item.mdAmt }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
import {selectMatInput} from '@/components/portlet/api/portletData.js';
export default {
  mixins: [PortletMixin],
  props: {
    curDate: {
      Type: String,
    },
  },
  data() {
    return {
      cnt: 5,
      matInputList: [],
    };
  },
  watch: {
    curDate() {
      this.onSearch();
    },
  },
  methods: {
    setWidth() {
      this.tblWidth = this.$el.offsetWidth - 28;
    },
    onSearch() {
      selectMatInput({prjCd: this.prjCd, ioDate: this.curDate}).then(response => (this.matInputList = response.data));
    },
  },
};
</script>

<style></style>
