<template>
  <pmis-content-box>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onRenderFirstFinish,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/LicenseListSheet.js';
import {selectWorkerSkill, deleteWorkerSkill, saveWorkerSkill} from '../api/worker.js';
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      loadSearchData: [],
      Options: $addColCheckbox(options(this)),
    };
  },
  beforeCreate() {
    $mapGetters(this, ['workerInfo']);
  },
  created() {
    this.addEvent([
      {name: 'LicenseList_onSearch', func: this.onSearch},
      {name: 'LicenseList_onAdd', func: this.onAdd},
      {name: 'LicenseList_onDelete', func: this.onDelete},
      {name: 'LicenseList_onSave', func: this.onSave},
      {name: 'LicenseList_getSaveList', func: this.getSaveList},
      {name: 'LicenseList_initSheet', func: this.initSheet},
    ]);
  },
  methods: {
    async onSearch() {
      let param = {
        pgmCd: this.workerInfo.pgmCd,
        empNo: this.workerInfo.empNo,
      };
      const response = await selectWorkerSkill(param);
      if (response.status == 200) {
        this.sheet.loadSearchData(response.data);
      }
    },
    onAdd() {
      let row = this.sheet.addRow();
      this.sheet.setValue(row, 'pgmCd', this.workerInfo.pgmCd, 1);
      this.sheet.setValue(row, 'empNo', this.workerInfo.empNo, 1);
    },
    getSaveList(callback) {
      let saveJson = $_statusToCud(this.sheet.getSaveJson().data);
      const callbackParam = {
        isValid: false,
        list: [],
      };
      const title = '자격면허정보';
      let message = '';
      if (saveJson.length == 0) {
        callbackParam.isValid = true;
      } else {
        saveJson.some(row => {
          if (!row.wpart) {
            message = '자격면허종류를 입력해주세요.';
          } else if (!row.wpartGrade) {
            message = '자격면허등급을 입력해주세요.';
          } else if (!row.wcertNo) {
            message = '자격면허번호를 입력해주세요.';
          } else if (!row.wcertDate) {
            message = '취득일자를 입력해주세요.';
          } else if (!row.wcertToDate) {
            message = '유효일자를 입력해주세요.';
          } else if (!row.wcertOrgan) {
            message = '발행기관을 입력해주세요.';
          }
          if (message) {
            return true;
          }
        });
        saveJson.forEach(row => {
          row.wcertDate = row.wcertDate.replace(/\//g, '');
          row.wcertToDate = row.wcertToDate.replace(/\//g, '');
        });
        if (message) {
          callbackParam.isValid = false;
          this.$alert({title, message});
        } else {
          callbackParam.isValid = true;
          callbackParam.list = saveJson;
        }
      }
      if (typeof callback === 'function') {
        callback(callbackParam);
      } else {
        callbackParam.list = saveJson;
        return callbackParam;
      }
    },
    async onSave() {
      if (this.getSaveList().isValid) {
        let workerSkillSaveList = this.getSaveList().list;
        const response = await saveWorkerSkill({workerSkillSaveList});
        if (response.status == 200) {
          this.onSearch();
        }
      }
    },
    async onDelete() {
      let checkedRows = this.sheet.getRowsByChecked('chk');
      let workerSkillSaveList = [];
      let removeRows = [];

      if (0 == checkedRows.length) {
        this.$alert({title: '선택항목 없음', message: '선택된 항목이 없습니다'});
        return;
      }

      if (!(await this.$confirm({title: '자격면허정보 삭제', message: '삭제하시겠습니까?'}))) return;

      for (let row of checkedRows) {
        workerSkillSaveList.push({
          pgmCd: row.pgmCd,
          empNo: row.empNo,
          skillSeq: row.skillSeq,
          cud: 3,
        });
      }
      this.sheet.removeRows(removeRows);
      const response = await deleteWorkerSkill({workerSkillSaveList});
      if (response.status == 200) {
        this.onSearch();
      }
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
    initSheet() {
      this.sheet.removeAll();
    },
  },
};
</script>

<style scoped></style>
