<template>
  <pmis-page ref="page" @search-click="onSearch">
    <AppList :title="title" :appSendStandby="appSendStandby" :options="Options" :search.sync="search" />
  </pmis-page>
</template>

<script>
import options from '../components/sheetOption/appSendStandbySheet.js';
import AppList from '../components/AppList.vue';
export default {
  components: {
    AppList,
  },
  data() {
    return {
      title: '발송대기',
      appSendStandby: true,
      Options: options(this),
      search: false,
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      this.search = true;
    },
  },
};
</script>

<style></style>
