<template>
  <ib-sheet
    :uid="_uid"
    :options="sheetOpt"
    :loadSearchData="loadSearchData"
    @loadSheet="sheet = $event"
    :events="{
      onRenderFirstFinish: () => onSearch(),
      onSearchFinish: sheet_onSearchFinish,
      onClick: sheet_onClick,
      onDblClick: sheet_onDblClick,
    }"
  />
</template>

<script>
import sheetOpt from './sheetOptions/DataClassificationListSheet.js';
import {
  selectBaseDataClassificationList,
  checkDuplicate as checkDuplicateBase,
  saveBaseDataClassificationDetailInfo,
  deleteBaseDataClassificationDetailInfo,
} from '@/view/DataClassificationRegist/api/baseDataClassificationRegist.js';
import {
  selectDataClassificationList,
  checkDuplicate,
  saveDataClassificationDetailInfo,
  deleteDataClassificationDetailInfo,
} from '@/view/DataClassificationRegist/api/dataClassificationRegist.js';

const CAN_ADD_FLDR_CD = ['PRJ_CD', 'A01', 'A05', 'A10']; // 하위분류추가가능 항목 (계약명, 도급정보, 자재정보, 외주정보)
export default {
  props: {
    uid: {
      type: Number,
    },
    isBasePage: {
      type: Boolean,
      default: true,
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],
      focusKey: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, ['detailInfo', 'isNew', 'isStandard']);
    $mapMutations(this, ['setDetailInfo', 'initDetailInfo', 'setIsNew']);
  },
  created() {
    this.addEvent([{name: 'dataCl_setData', func: this.callbackData}]);
    if (!this.isPopup) {
      this.addFuncSearch(this.onSearch);
      this.addFuncNew(this.onAdd);
      this.addFuncSave(this.onSave);
      this.addFuncDelete(this.onDelete);
    }
  },
  methods: {
    async onSearch(focusKey = '') {
      this.focusKey = focusKey;
      const searchApi = this.isBasePage ? selectBaseDataClassificationList : selectDataClassificationList;

      const response = await searchApi();

      const treeList = response.data[0];
      if (treeList) {
        treeList.Items = $_treeModel(
          Array.from(response.data).filter((e, i) => i !== 0),
          'FBS',
          'fldrCd',
          'uppFldrCd'
        );
        this.loadSearchData = new Array(treeList);
      }
    },
    onAdd() {
      const focusedRow = this.sheet.getFocusedRow();
      if (this.isStandard && !CAN_ADD_FLDR_CD.includes(focusedRow.fldrCd)) {
        this.$alert({title: '하위분류 추가 불가', message: '하위분류 추가 불가능한 항목입니다.'});
        return;
      }

      this.setIsNew(true);
      const baseData = {
        pgmCd: focusedRow.pgmCd,
        csNo: focusedRow.csNo,
        uppFldrCd: focusedRow.fldrCd || 'FBS',
        uppFldrNm: focusedRow.fldrNm || '자료분류',
        uppFldrNo: focusedRow.fldrNo || 0,
      };
      this.initDetailInfo();
      this.setDetailInfo(baseData);

      this.canSave = true;
      this.canDelete = false;
    },
    async onSave() {
      if (this.isNew) {
        const checkDuplicateApi = this.isBasePage ? checkDuplicateBase : checkDuplicate;
        const isDup = (await checkDuplicateApi({fldrCd: this.detailInfo.fldrCd})).data;
        if (isDup) {
          this.$alert({title: '저장', message: '이미 사용중인 코드입니다.'});
          return;
        }
      }

      const saveApi = this.isBasePage ? saveBaseDataClassificationDetailInfo : saveDataClassificationDetailInfo;

      saveApi({...this.detailInfo, cud: this.isNew ? 1 : 2})
        .then(response => {
          if (response.data) {
            this.onSearch(response.data);
          }
        })
        .catch(e => {
          this.$alert({title: '저장', message: `저장을 실패했습니다. : ${e}`});
        });
    },
    async onDelete() {
      const title = '삭제';
      const currentRow = this.sheet.getFocusedRow();

      if (this.isNew) {
        this.$alert({title, message: '신규정보는 삭제할 수 없습니다.'});
        return;
      }

      if (currentRow.Level == 0) {
        this.$alert({title, message: '최상위 분류는 삭제할 수 없습니다.'});
        return;
      }

      if (!this.isBasePage) {
        // 하위분류 자료존재여부 확인
        let childIsUsedFbsNo = false;
        const checkChildRows = rows => {
          if (!rows || !rows.length) {
            return;
          }
          rows.some(row => {
            if (row.isUsedFbsNo) {
              childIsUsedFbsNo = true;
              return true;
            }
            checkChildRows(this.sheet.getChildRows(rows));
          });
        };

        checkChildRows(this.sheet.getChildRows(currentRow));

        if (currentRow.isUsedFbsNo || childIsUsedFbsNo) {
          this.$alert({title, message: '해당분류 내 자료가 존재합니다.<br/>삭제할 수 없습니다.'});
          return;
        }
      }

      if (this.isStandard) {
        this.$alert({title, message: '해당분류는 삭제할 수 없습니다.'});
        return;
      }

      if (this.sheet.getChildRows(currentRow).length) {
        const message = '하위의 자료분류가 일괄삭제됩니다.<br/>삭제하시겠습니까?';
        if (!(await this.$confirm({title, message}))) {
          return;
        }
      } else {
        const message = '자료분류를 삭제하시겠습니까?';
        if (!(await this.$confirm({title, message}))) {
          return;
        }
      }

      const deleteApi = this.isBasePage ? deleteBaseDataClassificationDetailInfo : deleteDataClassificationDetailInfo;
      deleteApi(this.detailInfo)
        .then(response => {
          if (response.data) {
            this.onSearch(response.data.uppFldrNo);
          }
        })
        .catch(e => {
          this.$alert({title: '삭제', message: `삭제를 실패했습니다. : ${e}`});
        });
    },
    setRowData(row) {
      if (this.isPopup) {
        return;
      }

      this.setIsNew(false);
      this.setDetailInfo(row);

      this.canSave = !this.isStandard;
      this.canDelete = !this.isStandard;
    },
    callbackData(callback) {
      const row = this.sheet.getFocusedRow();
      if (row.level === 1) {
        this.$alert('최상위 항목은 선택할 수 없습니다.');
        return;
      }
      callback(row);
    },
    sheet_onSearchFinish(e) {
      if (this.focusKey) {
        const focusRow = e.sheet.util.treeFocus('fldrNo', this.focusKey);
        this.setRowData(focusRow);
      } else {
        this.setRowData(e.sheet.getFirstRow());
      }
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data') {
        this.setRowData(e.row);
      }
    },
    sheet_onDblClick() {
      if (this.isPopup) {
        this.callEvent({name: `dataClSearchOnConfirm${this.uid}`}); // iuiSearchbox.vue
      }
    },
  },
};
</script>

<style></style>
