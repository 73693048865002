<template>
  <pmis-tab-box>
    <template #title>상세정보</template>
    <pmis-content-box>
      <template #title>{{ isDraft ? '기안' : '표준' }}양식 정보</template>
      <iui-container-new type="table" theme="bullet">
        <i-row v-if="isDraft">
          <i-col-header required>자료분류</i-col-header>
          <i-col>
            <template v-if="isBasePage">
              <iui-searchbox
                type="dataCl"
                width="200px"
                :value="detailInfo.fldrCd"
                :nameValue="detailInfo.fldrNm"
                :callback="setDataCl"
                :paramObj="{isBasePage}"
                :required="isBasePage"
                :error-message="{title: '자료분류', message: '자료분류를 선택하세요'}"
              />
            </template>
            <template v-else>
              <iui-text width="90px" :value="detailInfo.fldrCd" :enable="false" />
              <iui-text width="155px" :value="detailInfo.fldrNm" :enable="false" />
            </template>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header required>양식제목</i-col-header>
          <i-col>
            <iui-text
              width="250px"
              :value.sync="detailInfo.tl"
              max-length="100"
              :enable="isBasePage"
              :required="isBasePage"
              :error-message="{title: '양식제목', message: '양식제목을 입력하세요'}"
            />
          </i-col>
        </i-row>
        <i-row v-if="isBasePage">
          <i-col-header>리포트</i-col-header>
          <i-col>
            <iui-text :value.sync="detailInfo.rptNm" :enable="isBasePage" max-length="300" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>양식</i-col-header>
          <i-col><iui-button value="미리보기" v-if="isBasePage && isNew && detailInfo.rptNm" @click="ozInit"/></i-col>
        </i-row>
        <i-row>
          <i-col colspan="2">
            <div id="OZViewer" style="height:calc(100vh - 355px); width:100%;"></div>
          </i-col>
        </i-row>
      </iui-container-new>
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
import {
  deleteBaseTemplateInfo,
  saveBaseTemplateInfo,
  selectBaseTemplateInfo,
} from '@/view/sysManage/baseSysManage/StandardTemplateRegist/apis/baseTemplate.js';
import {selectTemplateInfo} from '@/view/sysManage/baseSysManage/StandardTemplateRegist/apis/template.js';

export default {
  props: {
    isBasePage: {
      type: Boolean,
      default: false,
    },
    docTp: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      detailInfo: {
        fomNo: '',
        pgmCd: '',
        csNo: '',
        fldrNo: '',
        fldrCd: '',
        fldrNm: '',
        tl: '',
        rmk: '',
        rptNm: '',
        flNo: '', // 파일번호
        docTp: '',
      },
      isNew: false,
    };
  },
  computed: {
    isDraft() {
      return this.docTp === $getConstants('DOC_TP1').code;
    },
    isExistFomNo() {
      return 0 < this.detailInfo.fomNo;
    },
  },
  created() {
    this.addEvent([
      {name: 'TemplateDetail_Search', func: this.onSearch},
      {name: 'TemplateDetail_Init', func: this.onInit},
    ]);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
    this.detailInfo.docTp = this.docTp;
  },

  methods: {
    onSearch(row) {
      this.isNew = false;

      const fomNo = row.fomNo;
      const searchApi = this.isBasePage ? selectBaseTemplateInfo : selectTemplateInfo;
      const param = this.isBasePage ? fomNo : {fomNo};

      searchApi(param).then(response => {
        for (const key in this.detailInfo) {
          this.detailInfo[key] = response.data[key];
        }

        this.ozInit();
      });
    },
    onInit() {
      for (const key in this.detailInfo) this.detailInfo[key] = '';
      this.detailInfo.fomNo = 0;
      this.isNew = false;
      document.querySelector('#OZViewer').innerHTML = '';
    },
    setDataCl(data) {
      this.detailInfo.csNo = data.csNo;
      this.detailInfo.fldrCd = data.fldrCd;
      this.detailInfo.fldrNm = data.fldrNm;
      this.detailInfo.fldrNo = data.fldrNo;
    },
    onAdd() {
      if (!this.isBasePage) {
        return;
      }

      this.isNew = true;

      for (let key in this.detailInfo) this.detailInfo[key] = '';
      this.detailInfo.fomNo = 0;
      this.detailInfo.csNo = Number($getConstants('DATA_CL').code);
      this.detailInfo.pgmCd = this.pgmCd;
      this.detailInfo.docTp = this.docTp;
    },
    async onSave() {
      if (!this.isBasePage) {
        return;
      }

      const title = '양식 저장';
      if (!this.isNew && !this.isExistFomNo) {
        this.$alert({title, message: '저장할 양식정보가 존재하지 않습니다.'});
        return;
      }

      saveBaseTemplateInfo({isNew: this.isNew, ...this.detailInfo})
        .then(response => {
          if (response.status == 200) {
            this.callEvent({name: 'TemplateList_Search', param: response.data.fomNo});
          }
        })
        .catch(e => this.$alert('저장을 실패했습니다.<br/> - ' + e));
    },
    async onDelete() {
      if (!this.isBasePage) {
        return;
      }

      const title = '양식 삭제';
      if (!this.isNew && !this.isExistFomNo) {
        this.$alert({title, message: '삭제할 양식정보가 존재하지 않습니다.'});
        return;
      }
      if (this.isNew) {
        this.$alert({title, message: '신규 양식정보 입니다. 삭제할 수 없습니다.'});
        return;
      }

      if (!(await this.$confirm({title, message: '삭제하시겠습니까?'}))) {
        return;
      }

      deleteBaseTemplateInfo(this.detailInfo.fomNo)
        .then(response => {
          if (response.status == 200) {
            this.callEvent({name: 'TemplateList_Search', param: response.data.fomNo});
          }
        })
        .catch(e => this.$alert('삭제를 실패했습니다.<br/> - ' + e));
    },
    setParam() {
      this.ozParamStr = '';
      let ozSap = ';OZ;'; //뷰어패러미터 구분자
      let ozServer = '/eform/server'; //대상 오즈서버
      let pcount = 0;

      this.ozParamStr += ozSap + 'export.mode=false';
      this.ozParamStr += ozSap + 'connection.servlet=' + ozServer;
      this.ozParamStr += ozSap + `connection.reportname=${this.detailInfo.rptNm}`;
      this.ozParamStr += ozSap + 'connection.pcount=' + pcount; //ozr의 입력컴포넌트에 값을 세팅

      this.ozParamStr += ozSap + 'viewer.progresscommand=true';
      this.ozParamStr += ozSap + 'viewer.ignore_disable_color_inputcomponent=true';
      this.ozParamStr += ozSap + 'viewer.usetoolbar=false';
      this.ozParamStr += ozSap + 'viewer.viewmode=fittowidth';
    },
    ozInit() {
      if (OZViewer && OZViewer.Script) {
        OZViewer.Script('closeall');
        document.querySelector('#OZViewer').innerHTML = '';
      }

      // let ozServer = 'http://rd.apterp.co.kr/oz80/server'; //대상 오즈서버
      let ozSap = ';OZ;'; //뷰어패러미터 구분자
      // this.ozParamStr = '';

      window.SetOZParamters_OZViewer = () => {
        //뷰어패러미터
        this.setParam();
        let ozParamA = this.ozParamStr.split(ozSap);

        let oz;
        oz = document.getElementById('OZViewer');

        for (let key in ozParamA) {
          let paramStr = ozParamA[key];
          if (paramStr.indexOf('=') > -1) {
            let paramK = paramStr.substring(0, paramStr.indexOf('='));
            let paramV = paramStr.substring(paramStr.indexOf('=') + 1);
            oz.sendToActionScript(paramK, paramV);
          }
        }

        return true;
      };

      window.OZProgressCommand_OZViewer = (step, state) => {
        if (step == 4 && state == 2) {
          //보고서바인딩이 완료된 시점
          OZViewer.Script('disable_input_all'); //모든 보고서의 모든 입력 컴포넌트를 비활성화 상태로 변경(e-form에서만 지원됨)
        }
      };
      start_ozjs('OZViewer', '/eform/ozhviewer/');
    },
  },
};
</script>

<style></style>
