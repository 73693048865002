<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-select
        :label="'공사구분'"
        :value="searchInfo.wcode"
        :items="wCodeList"
        :defaultCd="'S'"
        @change="value => onSetValue('wcode', value)"
      />
      <iui-select
        :label="'공종분류'"
        :value="searchInfo.clCode"
        :items="clWorkFilter"
        :defaultCd="'S'"
        @change="value => onSetValue('clCode', value)"
      />
      <iui-text
        type="search"
        :label="'내역명칭'"
        :value="searchInfo.searchNm"
        @change="onSetValue('searchNm', $event.target.value)"
        @enter="onSearchExecutionDetailList"
      />
      <iui-button value="검색" @click="onSearchExecutionDetailList" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onDblclick}"
    />
  </pmis-content-box>
</template>

<script>
/**
 * 실행내역 조회 팝업
 * 사용 :
 *   집행경비등록 > 상세정보
 *
 * */
import options from '../sheetOption/ExecutionDetailPopupSheet.js';
export default {
  props: {
    costType: {
      type: String,
    },
  },
  async created() {
    this.addEvent({name: 'executionDetailPopup_callbackData', func: this.callbackData});
  },
  data() {
    return {
      sheet: undefined,
      loadSearchData: [],
      Options: options(this),
      searchInfo: {
        pgmCd: '',
        prjCd: '',
        wcode: '',
        clCode: '',
        searchNm: '',
        costType: '',
      },
      wCodeList: [],
      clWorkList: [],
      clWorkFilter: [],
    };
  },
  async mounted() {
    this.onSearchExecutionDetailList();

    this.onInit();
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onDblclick() {
      this.$modalConfirm();
    },
    async onInit() {
      this.searchInfo.pgmCd = this.pgmCd;
      this.searchInfo.prjCd = this.$store.getters['modalParam'].prjCd;
      this.searchInfo.poNoSubc = this.$store.getters['modalParam'].poNoSubc;
      this.searchInfo.costType = this.costType;

      this.onSearchWCodeList();
      this.onSearchClWorkList();
    },
    onSearchWCodeList() {
      axios.post('/executDtls/selectWcodeBYExe', {...this.searchInfo}).then(response => {
        this.wCodeList = response.data.map(e => ({text: e.wname, value: e.wcode}));
      });
    },
    onSearchClWorkList() {
      axios.post('/executDtls/selectClWorkBYExe', {...this.searchInfo}).then(response => {
        this.clWorkList = response.data.map(e => {
          return {wcode: e.wcode, headCode: e.headCode, midCode: e.midCode, value: e.clCd, text: e.clNm};
        });
      });
    },
    async onSearchExecutionDetailList() {
      try {
        let headCode = this.searchInfo.clCode.split('::')[0];
        let midCode = this.searchInfo.clCode.split('::')[1];
        const response = await axios.post('/executDtls/selectExecutionDetailList', {
          ...this.searchInfo,
          headCode: headCode,
          midCode: midCode,
        });
        this.loadSearchData = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    onSetValue(key, value) {
      this.searchInfo[key] = value;

      if (key == 'wcode') {
        this.clWorkFilter = this.clWorkList.filter(r => r.wcode == value);
        this.searchInfo['clCode'] = '';
      }

      if (key == 'wcode' || key == 'clCode') {
        this.onSearchExecutionDetailList();
      }
    },
    callbackData(returnValue) {
      let row = this.sheet.getFocusedRow();
      if (row) {
        returnValue(row);
      } else {
        this.$alert({title: '대상선택', message: '내역이 존재하지 않습니다.'});
      }
    },
  },
};
</script>

<style></style>
