<template>
  <pmis-content-box>
    <template v-slot:title>
      노무팀정보
    </template>
    <form onsubmit="return false;">
      <iui-container-new type="table" theme="bullet">
        <colgroup>
          <col style="width:100px" />
          <col />
          <col />
          <col style="width:100px" />
          <col />
        </colgroup>
        <i-row>
          <i-col-header>노무팀코드</i-col-header>
          <i-spacer />
          <i-col>
            <iui-text
              name="labCode"
              :value="lbrcTeamInfo.labCode"
              :bindArray="bindArray"
              :enable="false"
              width="100px"
              align="center"
            />
          </i-col>
          <i-col-header required>노무팀명</i-col-header>
          <i-spacer />
          <i-col>
            <iui-text
              name="labName"
              :value="lbrcTeamInfo.labName"
              :bindArray="bindArray"
              width="200px"
              max-length="100"
              :enable="enable"
              required
              :error-message="{title: '필수입력', message: '노무팀명이 입력되지 않았습니다.'}"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header required>팀장명</i-col-header>
          <i-spacer />
          <i-col>
            <iui-text
              type="text"
              name="labMNm"
              :value="lbrcTeamInfo.labMNm"
              :bindArray="bindArray"
              width="100px"
              max-length="50"
              :enable="enable"
              required
              :error-message="{title: '필수입력', message: '팀장명이 입력되지 않았습니다.'}"
            />
          </i-col>
          <i-col-header required>팀장주민번호</i-col-header>
          <i-spacer />
          <i-col>
            <iui-regi-no
              :value1="lbrcTeamInfo.labMRegiNo1"
              :value2="lbrcTeamInfo.labMRegiNo2"
              @change1="regiNoCheck"
              @change2="regiNoCheck"
              :enable="enable"
              :size="'short'"
              required
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>팀장휴대폰번호</i-col-header>
          <i-spacer />
          <i-col>
            <iui-text
              name="labMPhone"
              :value="lbrcTeamInfo.labMPhone"
              :bindArray="bindArray"
              type="phone"
              width="100px"
              max-length="20"
              :enable="enable"
            />
          </i-col>

          <i-col-header>팀장 이메일</i-col-header>
          <i-spacer />
          <i-col>
            <iui-text
              name="email1"
              type="email1"
              :value="lbrcTeamInfo.email1"
              :bindArray="bindArray"
              width="100px"
              max-length="50"
              :enable="enable"
            />
            <div class="mr5">@</div>
            <iui-select
              name="email2"
              :value="String(lbrcTeamInfo.email2).indexOf('0000') == -1 ? '' : lbrcTeamInfo.email2"
              @change="value => (lbrcTeamInfo.email2 = value)"
              :p-code="$getConstants('EMAIL_CD').code"
              defaultCd="직접입력"
              :enable="enable"
            />
            <iui-text
              v-if="String(lbrcTeamInfo.email2).indexOf('0000') == -1"
              name="email2"
              type="email2"
              width="100px"
              :value="lbrcTeamInfo.email2"
              :bindArray="bindArray"
              max-length="50"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>사업자번호</i-col-header>
          <i-spacer />
          <i-col>
            <iui-bsn-no
              :value1.sync="lbrcTeamInfo.bsnNo1"
              :value2.sync="lbrcTeamInfo.bsnNo2"
              :value3.sync="lbrcTeamInfo.bsnNo3"
              @change1="setBsnNo"
              @change2="setBsnNo"
              @change3="setBsnNo"
              :enable="enable"
              :required="false"
            />
          </i-col>
          <i-col-header>종료여부</i-col-header>
          <i-spacer />
          <i-col>
            <iui-checkbox-group
              name="delYn"
              :items="[{label: '종료', value: $getConstants('DEL_YN_Y').code}]"
              :checkedValues="[lbrcTeamInfo.delYn]"
              @change="
                setLbrcTeamInfo({
                  delYn: $event.target.checked ? $event.target.value : '',
                })
              "
              :enable="enable"
            />
          </i-col>
        </i-row>
        <!-- DB 필드 삭제 안함(일단 보류) -->
        <!-- <i-col-header required>팀장전화번호</i-col-header>
        <i-col>
          <iui-text
            name="labMPhone"
            :value="lbrcTeamInfo.labMPhone"
            :bindArray="bindArray"
            :valid="'phone'"
            width="120px"
            max-length="20"
            :enable="enable"
            required
            :error-message="{title: '필수입력', message: '팀장전화번호가 입력되지 않았습니다.'}"
          />
        </i-col> -->
        <!-- <i-col-header>이메일</i-col-header>
        <i-col>
          <iui-text
            name="email1"
            :value="lbrcTeamInfo.email1"
            :bindArray="bindArray"
            width="90px"
            max-length="50"
            :enable="enable"
          />
          <div class="mr5">@</div>
          <iui-select
            name="email2"
            :value="String(lbrcTeamInfo.email2).indexOf('0000') == -1 ? '' : lbrcTeamInfo.email2"
            :bindArray="bindArray"
            p-code="0275"
            defaultCd="직접입력"
            :enable="enable"
          />
          <iui-text
            v-if="String(lbrcTeamInfo.email2).indexOf('0000') == -1"
            name="email2"
            width="150px"
            :value="lbrcTeamInfo.email2"
            :bindArray="bindArray"
          />
        </i-col> -->
      </iui-container-new>
    </form>
  </pmis-content-box>
</template>

<script>
/**
 * 노무팀 정보 :
 * 사용 : 노무팀등록 > 기본정보
 *        계약정보등록(tab2) > 조직도 > 노무팀정보
 * */
import {saveLbrcTeam} from '../api/lbrcTeamRegist.js';
export default {
  props: {
    pageType: {
      type: String,
      default: 'regist',
    },
  },
  data() {
    return {
      bindArray: ['setLbrcTeamInfo'],
    };
  },
  computed: {
    enable() {
      return this.pageType != 'orgCht';
    },
  },
  beforeCreate() {
    $mapGetters(this, ['lbrcTeamInfo']);
    $mapMutations(this, ['setLbrcTeamInfo', 'initLbrcTeamInfo', 'setFocusKey']);
  },
  created() {
    this.addEvent([
      {name: 'basicInfo_onSave', func: this.onSave},
      {name: 'basicInfo_onDelete', func: this.onDelete},
    ]);
  },
  methods: {
    regiNoCheck(event) {
      const name = event.target.name;
      const value = event.target.value;
      if (name === 'regiNo1') {
        if (value.length < 6) {
          this.$alert({title: '주민번호', message: '주민번호 앞자리는 6자리로 입력하여 주세요.'}, () => {
            event.target.value = '';
          });
          return;
        }
        this.setLbrcTeamInfo({labMRegiNo1: value});
      } else if (name === 'regiNo2') {
        if (value.length < 7) {
          this.$alert({title: '주민번호', message: '주민번호 뒷자리는 7자리로 입력하여 주세요.'}, () => {
            event.target.value = '';
          });
          return;
        }
        this.setLbrcTeamInfo({labMRegiNo2: value});
      }
    },
    setBsnNo() {
      this.setLbrcTeamInfo({bsnNo: this.lbrcTeamInfo.bsnNo1 + this.lbrcTeamInfo.bsnNo2 + this.lbrcTeamInfo.bsnNo3});
    },
    async onSave() {
      this.setLbrcTeamInfo({pgmCd: this.pgmCd});

      if (this.lbrcTeamInfo.labMRegiNo1.length != 6 || this.lbrcTeamInfo.labMRegiNo2.length != 7) {
        this.$alert({title: '노무팀등록', message: '주민번호 형식이 맞지 않습니다.'});
        return false;
      } else if (
        this.lbrcTeamInfo.bsnNo &&
        (this.lbrcTeamInfo.bsnNo1.length != 3 ||
          this.lbrcTeamInfo.bsnNo2.length != 2 ||
          this.lbrcTeamInfo.bsnNo3.length != 5)
      ) {
        this.$alert({title: '노무팀등록', message: '사업자번호 형식이 맞지 않습니다.'});
        return false;
      }

      let param = {...this.lbrcTeamInfo, cud: this.lbrcTeamInfo.labCode ? 2 : 1};
      const response = await saveLbrcTeam(param);
      this.setFocusKey(this.lbrcTeamInfo.labCode);
      this.callEvent({name: 'LbrcTeamList_onSearch'});
    },
    async onDelete() {
      this.setLbrcTeamInfo({pgmCd: this.pgmCd});

      if (!this.lbrcTeamInfo.labCode) {
        this.$alert({title: '노무팀 삭제', message: '신규 건은 삭제할 수 없습니다.'});
        return false;
      }
      if (this.lbrcTeamInfo.teamMemberCnt > 0) {
        this.$alert({
          title: '노무팀 삭제',
          message: '팀원이 존재합니다.(삭제 불가능합니다.)',
        });
        return false;
      }

      let param = {...this.lbrcTeamInfo, cud: 3};
      const response = await saveLbrcTeam(param);
      this.callEvent({name: 'LbrcTeamList_onSearch'});
    },
  },
};
</script>

<style></style>
