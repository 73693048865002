<template>
  <pmis-content-box>
    <template v-slot:title>공급자재정보</template>
    <template v-slot:title-right>
      <iui-button class="btn" value="신규" @click="onAdd()" v-show="enable" />
      <iui-button class="btn" value="저장" @click="onSave()" v-if="companyInfo.pgmCd" v-show="enable" />
      <iui-button class="btn" value="삭제" @click="onDelete()" v-show="enable" />
    </template>
    <iui-container-new type="css-flex">
      <i-row :height="enable ? '150px' : `${height}px`">
        <i-col>
          <ib-sheet
            :uid="_uid"
            :options="Options"
            :loadSearchData="matList"
            :events="{onRenderFirstFinish}"
            @loadSheet="loadSheet"
          />
        </i-col>
      </i-row>
    </iui-container-new>
    <iui-modal name="matList" title="자재분류선택" width="400px" height="500px" :btns="modalBtns">
      <MatList />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import MatList from '@/view/member/components/popup/MatList.vue';
import options from './sheetOption/MtrilInfoSheet.js';
import {selectMatList, saveMatList, deleteMatList} from '../api/member.js';
export default {
  components: {
    MatList,
  },

  props: {
    enable: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '200',
    },
  },

  data() {
    return {
      modalBtns: [{name: '확인', callback: this.onConfirm}],
      Options: this.enable ? $addColCheckbox(options(this)) : options(this),
    };
  },

  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'matList']);
    $mapMutations(this, ['setCompanyInfo', 'setMatList']);
  },

  created() {
    this.addEvent([{name: 'mtrilInfo_getSaveJson', func: this.getSaveJson}]);
  },

  mounted() {},

  methods: {
    onAdd() {
      this.$modal.show('matList');
    },
    onConfirm() {
      this.callEvent({
        name: 'matList_onFocusedRowData',
        param: data => {
          if (data.Level != 3) {
            this.$alert({title: '자재분류선택', message: '3레벨 분류를 선택해주세요.'});
            return;
          }
          let row = this.sheet.addRow();
          this.sheet.setValue(row, 'lmCode', data.lmCode, 1);
          this.sheet.setValue(row, 'mmCode', data.mmCode, 1);
          this.sheet.setValue(row, 'smCode', data.smCode, 1);
          this.sheet.setValue(row, 'lmName', data.lmName, 1);
          this.sheet.setValue(row, 'mmName', data.mmName, 1);
          this.sheet.setValue(row, 'smName', data.smName, 1);
          this.$modal.hide('matList');
        },
      });
    },
    async onSave() {
      if (this.sheet.getTotalRowCount() == 0) {
        this.$alert({title: '공급자재정보 저장', message: '저장할 정보가 존재하지 않습니다.'});
        return;
      }
      if (!this.sheet.hasChangedData()) {
        this.$alert({title: '공급자재정보 저장', message: '수정된 내용이 없습니다.'});
        return;
      }

      let saveJson = this.sheet.getSaveJson().data;
      saveJson.forEach(el => {
        if (!el.pgmCd) el.pgmCd = this.companyInfo.pgmCd;
      });
      let param = {matList: $_statusToCud(saveJson)};
      const response = await saveMatList(param);
      if (response.status == 200) {
        this.onSearchMatList();
      }
    },
    async onDelete() {
      if (this.sheet.getTotalRowCount() == 0) {
        this.$alert({title: '공급자재정보 삭제', message: '삭제할 정보가 존재하지 않습니다.'});
        return;
      }

      let checkedRows = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({title: '공급자재정보 삭제', message: '선택된 항목이 없습니다.'});
        return;
      }

      let param = checkedRows.map(item => {
        return item.seq;
      });
      if (await this.$confirm({title: '공급자재정보 삭제', message: '삭제하시겠습니까?'})) {
        const response = await deleteMatList({matList: param});
        if (response.status == 200) {
          this.onSearchMatList();
        }
      }
    },
    getSaveJson(callback) {
      if (typeof callback == 'function') callback(this.sheet.getSaveJson().data);
    },

    async onSearchMatList() {
      let param = {pgmCd: this.companyInfo.pgmCd};
      const response = await selectMatList(param);
      if (response.status == 200) {
        this.setMatList(response.data);
      }
    },

    onRenderFirstFinish() {
      this.onSearchMatList();
    },

    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
  beforeDestroy() {
    this.sheet.dispose();
  },
};
</script>

<style></style>
