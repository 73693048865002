const state = {
  runInitFuncs: [],
};
const getters = {};
const mutations = {
  addFuncInit(state, payload) {
    state.runInitFuncs = [...(state.runInitFuncs || []), payload];
  },
  clearRunFunc(state) {
    state.runInitFuncs = [];
  },
};
const actions = {
  addFuncInit({commit}, func) {
    commit('addFuncInit', func);
  },
  runFuncInit({state}) {
    if (state.runInitFuncs instanceof Array) {
      state.runInitFuncs.forEach(func => func());
    }
  },
  clearRunFunc({commit}) {
    commit('clearRunFunc');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
