<!--
Name : iui-html-2-pdf
Description :
  -html2canvas : https://www.npmjs.com/package/html2canvas (HTML to IMAGE)
  -jspdf : https://www.npmjs.com/package/jspdf (IMAGE to PDF && Print && Download)
-->
<template>
  <div ref="pdfContent" style="position: fixed; top: -9999px; left: -9999px;">
    <slot />
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
export default {
  props: {
    download: {
      // 다운로드 기능
      type: Boolean,
      default: false,
    },
    print: {
      // 인쇄 기능
      type: Boolean,
      default: false,
    },
    generate: {
      // 다운로드 및 인쇄 실행
      type: Boolean,
      default: false,
    },
    pdfFileNm: {
      // 다운로드 받을 PDF 파일명
      type: [String, Number],
      default: new Date().getTime(),
    },
    width: {
      // 인쇄 팝업 사이즈
      type: String,
      default: undefined,
    },
    height: {
      // 인쇄 팝업 사이즈
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      canvas: '',
      imageData: '',
    };
  },
  methods: {
    async onGenerate() {
      this.$emit('update:generate', false);

      const el = this.$refs.pdfContent;
      const response = await html2canvas(el);
      this.canvas = response;
      this.imageData = response.toDataURL('image/jpeg', 1.0);
      this.onPDF();
    },
    onPDF() {
      let imgWidth = 190; // 이미지 가로 길이(mm) / A4 기준 210mm
      let pageHeight = imgWidth * 1.414; // 출력 페이지 세로 길이 계산 A4 기준
      let imgHeight = (this.canvas.height * imgWidth) / this.canvas.width;
      let heightLeft = imgHeight;
      let margin = 5; // 출력 페이지 여백설정
      let position = 10;
      const pdfDocument = new jsPDF('p', 'mm');

      // 첫 페이지 출력
      pdfDocument.addImage(this.imageData, 'PNG', margin, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      // 한 페이지 이상일 경우 루프 돌면서 출력
      while (heightLeft >= 20) {
        position = heightLeft - imgHeight;
        pdfDocument.addPage();
        pdfDocument.addImage(this.imageData, 'PNG', margin, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      if (this.print && this.download) {
        this.onDownload(pdfDocument);
        this.onPrint(pdfDocument);
      } else if (this.download) {
        this.onDownload(pdfDocument);
      } else if (this.print) {
        this.onPrint(pdfDocument);
      }
      this.$emit('generate-complete');
    },
    onDownload(_pdfDocument) {
      _pdfDocument.save(`${this.pdfFileNm}.pdf`);
    },
    onPrint(_pdfDocument) {
      _pdfDocument.autoPrint();
      const url = _pdfDocument.output('bloburl');
      const windowName = '_blank';
      const windowFeatures = `toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes,width=${this.width},height=${this.height}`;
      window.open(url, windowName, windowFeatures);
    },
  },
  watch: {
    generate: {
      handler(newValue) {
        if (newValue) {
          setTimeout(() => this.onGenerate(), 500);
        }
      },
    },
  },
};
</script>

<style></style>
