<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col style="text-align:center">
        <img :src="photos.imgUrl" height="100%" @click="clickImage" />
      </i-col>
    </i-row>
    <hr v-show="photos.tImgs.length" width="99%" style="border-bottom: 0px;" />
    <i-row height="200px">
      <i-col>
        <iui-card-carousel
          :showViewer="false"
          :items="photos.tImgs"
          :imageHeight="170"
          :imageWidth="170"
          @inited="cardCarousel_viewerInited"
          @click-card="clickCard"
        />
      </i-col>
    </i-row>
    <i-row height="120px">
      <i-col>
        <iui-container-new type="table" theme="bullet">
          <colgroup>
            <col width="100px" />
            <col width="100px" />
            <col width />
          </colgroup>
          <i-row>
            <i-col-header>
              단위작업명
            </i-col-header>
            <i-col colspan="2">
              <iui-text :value="detailInfo.unitPathNm" readonly />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>
              촬영일자
            </i-col-header>
            <i-col>
              <iui-text :value="detailInfo.phoDt" readonly />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>
              사진설명
            </i-col-header>
            <i-col colspan="2">
              <iui-text type="multi" :value="detailInfo.rmk" readonly />
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
/**
 * 공정사진조회 > 상세사진정보
 *
 * */
export default {
  beforeCreate() {
    $mapGetters(this, ['detailInfo', 'photos']);
    $mapMutations(this, ['setPhotos']);
  },
  methods: {
    cardCarousel_viewerInited(viewer) {
      this.$viewer = viewer;
    },
    clickImage() {
      this.$viewer.view(this.photos.index);
    },
    clickCard(index) {
      this.setPhotos({index: index, imgUrl: this.photos.mImgs[index].imgSrc});
    },
  },
};
</script>

<style></style>
