<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col>
        <pmis-content-box>
          <template v-slot:title>
            사원정보
          </template>
          <iui-container-new type="table" theme="bullet">
            <i-row>
              <i-col-header>
                ID
              </i-col-header>
              <i-col>
                <iui-text width="120px" :value="workerData.empNo" :enable="false" />
              </i-col>
              <i-col rowspan="7" align="right">
                <div style="width:100%">
                  <pmis-image-file
                    rgprId=""
                    id="worker"
                    title="사진"
                    :file-number="workerData.phoFlNo"
                    :allowAdd="false"
                    :allowDelete="false"
                  />
                </div>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>
                성명
              </i-col-header>
              <i-col>
                <iui-text width="120px" :value="workerData.name" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>
                부서
              </i-col-header>
              <i-col>
                <iui-text width="120px" :value="workerData.blnDepNm" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>
                직책
              </i-col-header>
              <i-col>
                <iui-text width="120px" :value="workerData.dtsNm" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>
                직급
              </i-col-header>
              <i-col>
                <iui-text width="120px" :value="workerData.rollNm" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>
                전화번호
              </i-col-header>
              <i-col>
                <iui-text type="phone" width="120px" :value="workerData.tlno" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>
                팩스번호
              </i-col-header>
              <i-col>
                <iui-text type="phone" width="120px" :value="workerData.rpsvFaxno" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>
                휴대폰번호
              </i-col-header>
              <i-col>
                <iui-text type="phone" width="120px" :value="workerData.mobileTlno" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>
                주소
              </i-col-header>
              <i-col colspan="2">
                <iui-text width="80px" :value="workerData.postNo" :enable="false" />
                <iui-text width="200px" :value="workerData.adr1" :enable="false" />
                <iui-text :value="workerData.adr2" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>
                이메일
              </i-col-header>
              <i-col colspan="2">
                <iui-text type="email1" width="120px" :value="workerData.email1" :enable="false" />
                <div class="mr5">@</div>
                <iui-text type="email2" width="100px" :value="workerData.email2" :enable="false" />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row v-if="showInputPrj" height="180px">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>
            사원정보
          </template>
          <iui-container-new type="ibsheet" :id="elId" />
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
export default {
  props: {
    workerData: {
      type: Object,
      detaulf: () => {},
    },
    inputPrjList: {
      type: Array,
      detaulf: () => [],
    },
    showInputPrj: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
    };
  },
  mounted() {
    if (this.showInputPrj) {
      this.initSheet();
    }
  },
  methods: {
    initSheet() {
      const OPT = {
        Cols: [
          {
            Header: '현장명',
            Name: 'prjNm',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: false,
          },
          {
            Header: '투입일자',
            Name: 'rlseDate',
            Extend: this.$ibsheetHelper.preset.date,
            RelWidth: 1,
            CanEdit: false,
          },
          {
            Header: '철수(예정)일자',
            Name: 'outDate',
            Extend: this.$ibsheetHelper.preset.date,
            RelWidth: 1,
            CanEdit: false,
          },
        ],
      };

      PMISSheet.createSheet({
        el: this.elId,
        options: OPT,
        data: this.inputPrjList,
      }).then(sheet => (this.sheet = sheet));
    },
  },
};
</script>

<style></style>
