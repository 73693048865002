<template>
  <div>
    <div>
      <ul>
        <li @click="onClick(1)">제품소개</li>
        <ul>
          <li @click="onClick('abc')">원가관리</li>
          <li @click="onClick('abc')">스마트폰관리</li>
          <li @click="onClick('abc')">노무관리</li>
          <li @click="onClick('abc')">전자조달</li>
          <li @click="onClick('abc')">그룹웨어</li>
        </ul>
        <li @click="onClick(2)">서비스안내</li>
        <li @click="onClick('Notice')">공지사항</li>
        <li @click="onClick('FAQ')">자주 묻는 질문(FAQ)</li>
        <li @click="onClick(5)">원격지원</li>
        <li @click="onClick(6)">공인인증서 안내</li>
        <li @click="onClick('PurchsCnsltRqst')">구매상담신청</li>
      </ul>
    </div>
    <div>
      <select @change="goFamilySite">
        <option value="">Family Site</option>
        <option value="http://www.ebiz4u.co.kr/home.do">이비즈포유</option>
        <option value="https://admin.aptbid4u.co.kr/home.do">아파트비드포유</option>
        <option value="">아파트ERP</option>
        <option value="https://www.perfectpay.co.kr/cmm/login.do">퍼펙트페이</option>
        <option value="http://www.eepss.co.kr/home.do">eePSS</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onClick(menuId) {
      this.$emit('onMenuId', menuId);
    },
    goFamilySite(e) {
      location.href = e.target.value;
    },
  },
};
</script>

<style></style>
