const state = {
  searchBtn: {},
  newBtn: {},
  saveBtn: {},
  deleteBtn: {},
  printBtn: {},
  closeBtn: {},
  canSearch: {},
  canNew: {},
  canSave: {},
  canDelete: {},
  canPrint: {},
  runFuncSearch: {},
  runFuncNew: {},
  runFuncSave: {},
  runFuncDelete: {},
  runFuncPrint: {},
  btns: [
    {
      name: '조회',
      class: 'btn_01 mr5',
      type: 'search',
      auth: ['vwYn', 'userVwYn'],
      cud: 0,
    },
    {
      name: '신규',
      class: 'btn_02 mr5',
      type: 'new',
      auth: ['svYn', 'userSvYn'],
      cud: 1,
    },
    {
      name: '저장',
      class: 'btn_03 mr5',
      type: 'save',
      auth: ['svYn', 'userSvYn'],
      cud: 2,
    },
    {
      name: '삭제',
      class: 'btn_04 mr5',
      type: 'delete',
      auth: ['delYn', 'userSvYn'],
      cud: 3,
    },
    {
      name: '출력',
      class: 'btn_05 mr5',
      type: 'print',
      auth: ['prnYn', 'userPrnYn'],
      cud: undefined,
    },
  ],
};

const getters = {
  currentUrl: (state, getters, rootState, rootGetters) => rootGetters.currentUrl,
  currentUrls: (state, getters, rootState, rootGetters) => rootGetters['mdi/currentUrls'],
  searchBtn: (state, getters) => state.searchBtn[getters.currentUrl],
  newBtn: (state, getters) => state.newBtn[getters.currentUrl],
  saveBtn: (state, getters) => state.saveBtn[getters.currentUrl],
  deleteBtn: (state, getters) => state.deleteBtn[getters.currentUrl],
  printBtn: (state, getters) => state.printBtn[getters.currentUrl],
  closeBtn: (state, getters) => state.closeBtn[getters.currentUrl],
  canSearch: (state, getters) =>
    state.canSearch[getters.currentUrl] === undefined ? true : state.canSearch[getters.currentUrl],
  canNew: (state, getters) =>
    state.canNew[getters.currentUrl] === undefined ? true : state.canNew[getters.currentUrl],
  canSave: (state, getters) =>
    state.canSave[getters.currentUrl] === undefined ? true : state.canSave[getters.currentUrl],
  canDelete: (state, getters) =>
    state.canDelete[getters.currentUrl] === undefined ? true : state.canDelete[getters.currentUrl],
  canPrint: (state, getters) =>
    state.canPrint[getters.currentUrl] === undefined ? true : state.canPrint[getters.currentUrl],
  btns: (state, getters) => {
    const firstUpperCase = str => str.charAt(0).toUpperCase() + str.slice(1);
    const getAuth = (type, auth) => {
      let isAuth = getters[`can${firstUpperCase(type)}`];
      if (isAuth && type === 'new') {
        isAuth = (getters.currentUrls['newYn'] ?? $getConstants('Y').code) === $getConstants('Y').code;
      }
      return isAuth && !(auth.map(item => getters.currentUrls[item] === $getConstants('Y').code).indexOf(false) !== -1);
    };

    return state.btns.filter(btn => getAuth(btn.type, btn.auth));
  },
};

const mutations = {
  addFuncSearch(state, payload) {
    const index = state.runFuncSearch[payload.currentUrl]?.findIndex(func => func.name === payload.func.name) ?? -1;
    if (index !== -1) {
      state.runFuncSearch[payload.currentUrl][index] = payload.func;
      return;
    }
    state.runFuncSearch[payload.currentUrl] = [...(state.runFuncSearch[payload.currentUrl] || []), payload.func];
  },
  addFuncNew(state, payload) {
    const index = state.runFuncNew[payload.currentUrl]?.findIndex(func => func.name === payload.func.name) ?? -1;
    if (index !== -1) {
      state.runFuncNew[payload.currentUrl][index] = payload.func;
      return;
    }
    state.runFuncNew[payload.currentUrl] = [...(state.runFuncNew[payload.currentUrl] || []), payload.func];
  },
  addFuncSave(state, payload) {
    const index = state.runFuncSave[payload.currentUrl]?.findIndex(func => func.name === payload.func.name) ?? -1;
    if (index !== -1) {
      state.runFuncSave[payload.currentUrl][index] = payload.func;
      return;
    }
    state.runFuncSave[payload.currentUrl] = [...(state.runFuncSave[payload.currentUrl] || []), payload.func];
  },
  addFuncDelete(state, payload) {
    const index = state.runFuncDelete[payload.currentUrl]?.findIndex(func => func.name === payload.func.name) ?? -1;
    if (index !== -1) {
      state.runFuncDelete[payload.currentUrl][index] = payload.func;
      return;
    }
    state.runFuncDelete[payload.currentUrl] = [...(state.runFuncDelete[payload.currentUrl] || []), payload.func];
  },
  addFuncPrint(state, payload) {
    const index = state.runFuncPrint[payload.currentUrl]?.findIndex(func => func.name === payload.func.name) ?? -1;
    if (index !== -1) {
      state.runFuncPrint[payload.currentUrl][index] = payload.func;
      return;
    }
    state.runFuncPrint[payload.currentUrl] = [...(state.runFuncPrint[payload.currentUrl] || []), payload.func];
  },
  setSearchBtn(state, payload) {
    state.searchBtn = payload;
  },

  setNewBtn(state, payload) {
    state.newBtn = payload;
  },

  setSaveBtn(state, payload) {
    state.saveBtn = payload;
  },

  setDeleteBtn(state, payload) {
    state.deleteBtn = payload;
  },

  setPrintBtn(state, payload) {
    state.printBtn = payload;
  },

  setCloseBtn(state, payload) {
    state.closeBtn = payload;
  },
  setSearch(state, payload) {
    state.canSearch = payload;
  },
  setNew(state, payload) {
    state.canNew = payload;
  },

  setSave(state, payload) {
    state.canSave = payload;
  },

  setDelete(state, payload) {
    state.canDelete = payload;
  },

  setPrint(state, payload) {
    state.canPrint = payload;
  },
  destroy(state, currentUrl) {
    delete state.searchBtn[currentUrl];
    delete state.newBtn[currentUrl];
    delete state.saveBtn[currentUrl];
    delete state.deleteBtn[currentUrl];
    delete state.printBtn[currentUrl];
    delete state.closeBtn[currentUrl];
    delete state.runFuncSearch[currentUrl];
    delete state.runFuncNew[currentUrl];
    delete state.runFuncSave[currentUrl];
    delete state.runFuncDelete[currentUrl];
    delete state.runFuncPrint[currentUrl];
    delete state.canSearch[currentUrl];
    delete state.canNew[currentUrl];
    delete state.canSave[currentUrl];
    delete state.canDelete[currentUrl];
    delete state.canPrint[currentUrl];
  },
  destroyAll(state) {
    state.searchBtn = {};
    state.newBtn = {};
    state.saveBtn = {};
    state.deleteBtn = {};
    state.printBtn = {};
    state.closeBtn = {};
    state.runFuncSearch = {};
    state.runFuncNew = {};
    state.runFuncSave = {};
    state.runFuncDelete = {};
    state.runFuncPrint = {};
    state.canSearch = {};
    state.canNew = {};
    state.canSave = {};
    state.canDelete = {};
    state.canPrint = {};
  },
};

const actions = {
  addFuncSearch({commit, rootState}, func) {
    commit('addFuncSearch', {currentUrl: rootState.currentUrl, func: func});
  },
  runFuncSearch({state, rootState}) {
    if (state.runFuncSearch[rootState.currentUrl] instanceof Array) {
      state.runFuncSearch[rootState.currentUrl].forEach(func => func());
    }
  },
  removeFuncSearch({commit, rootState}) {
    commit();
    if (state.runFuncSearch[rootState.currentUrl] instanceof Array) {
      state.runFuncSearch[rootState.currentUrl].forEach(func => func());
    }
  },

  addFuncNew({commit, rootState}, func) {
    commit('addFuncNew', {currentUrl: rootState.currentUrl, func: func});
  },
  runFuncNew({state, rootState}) {
    if (state.runFuncNew[rootState.currentUrl] instanceof Array) {
      state.runFuncNew[rootState.currentUrl].forEach(func => func());
    }
  },
  addFuncSave({commit, rootState}, func) {
    commit('addFuncSave', {currentUrl: rootState.currentUrl, func: func});
  },
  runFuncSave({state, rootState}) {
    if (state.runFuncSave[rootState.currentUrl] instanceof Array) {
      state.runFuncSave[rootState.currentUrl].forEach(func => func());
    }
  },
  addFuncDelete({commit, rootState}, func) {
    commit('addFuncDelete', {currentUrl: rootState.currentUrl, func: func});
  },
  runFuncDelete({state, rootState}) {
    if (state.runFuncDelete[rootState.currentUrl] instanceof Array) {
      state.runFuncDelete[rootState.currentUrl].forEach(func => func());
    }
  },
  addFuncPrint({commit, rootState}, func) {
    commit('addFuncPrint', {currentUrl: rootState.currentUrl, func: func});
  },
  runFuncPrint({state, rootState}) {
    if (state.runFuncPrint[rootState.currentUrl] instanceof Array) {
      state.runFuncPrint[rootState.currentUrl].forEach(func => func());
    }
  },
  setSearch({state, rootState, commit}, canSearch) {
    const searchObj = {};
    searchObj[rootState.currentUrl] = canSearch;
    commit('setSearch', {...state.canSearch, ...searchObj});
  },
  setNew({state, rootState, commit}, canNew) {
    const newObj = {};
    newObj[rootState.currentUrl] = canNew;
    commit('setNew', {...state.canNew, ...newObj});
  },

  setSave({state, rootState, commit}, canSave) {
    const saveObj = {};
    saveObj[rootState.currentUrl] = canSave;
    commit('setSave', {...state.canSave, ...saveObj});
  },

  setDelete({state, rootState, commit}, canDelete) {
    const delObj = {};
    delObj[rootState.currentUrl] = canDelete;
    commit('setDelete', {...state.canDelete, ...delObj});
  },

  setPrint({state, rootState, commit}, canPrint) {
    const printObj = {};
    printObj[rootState.currentUrl] = canPrint;
    commit('setPrint', {...state.canPrint, ...printObj});
  },

  setSearchBtn({state, rootState, commit}, searchBtn) {
    const searchBtnObj = {};
    searchBtnObj[rootState.currentUrl] = searchBtn instanceof Array ? searchBtn[0] : searchBtn;
    commit('setSearchBtn', {...state.searchBtn, ...searchBtnObj});
  },
  setNewBtn({state, rootState, commit}, newBtn) {
    const newBtnObj = {};
    newBtnObj[rootState.currentUrl] = newBtn instanceof Array ? newBtn[0] : newBtn;
    commit('setNewBtn', {...state.newBtn, ...newBtnObj});
  },
  setSaveBtn({state, rootState, commit}, saveBtn) {
    const saveBtnObj = {};
    saveBtnObj[rootState.currentUrl] = saveBtn instanceof Array ? saveBtn[0] : saveBtn;
    commit('setSaveBtn', {...state.saveBtn, ...saveBtnObj});
  },
  setDeleteBtn({state, rootState, commit}, deleteBtn) {
    const deleteBtnObj = {};
    deleteBtnObj[rootState.currentUrl] = deleteBtn instanceof Array ? deleteBtn[0] : deleteBtn;
    commit('setDeleteBtn', {...state.deleteBtn, ...deleteBtnObj});
  },
  setPrintBtn({state, rootState, commit}, printBtn) {
    const printBtnObj = {};
    printBtnObj[rootState.currentUrl] = printBtn instanceof Array ? printBtn[0] : printBtn;
    commit('setPrintBtn', {...state.printBtn, ...printBtnObj});
  },
  setCloseBtn({state, rootState, commit}, closeBtn) {
    const closeBtnObj = {};
    closeBtnObj[rootState.currentUrl] = closeBtn;
    commit('setCloseBtn', {...state.closeBtn, ...closeBtnObj});
  },
  destroy({commit}, url) {
    commit('destroy', url);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
