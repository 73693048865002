<template>
  <div class="iui-content-box">
    <!-- title-box -->
    <iui-content-box-title-box
      v-if="
        $slots['title'] !== undefined ||
          $slots['title-bullet'] !== undefined ||
          $slots['title-bullet-required'] !== undefined ||
          $slots['title-left'] !== undefined ||
          $slots['title-center'] !== undefined ||
          $slots['title-right'] !== undefined ||
          isHeader
      "
      :title-type="titleType"
    >
      <template #title>
        <slot name="title" />
      </template>
      <template #title-bullet>
        <slot name="title-bullet" />
      </template>
      <template #title-bullet-required>
        <slot name="title-bullet-required" />
      </template>
      <template #title-left>
        <slot name="title-left" />
      </template>
      <template #title-center>
        <slot name="title-center" />
      </template>
      <template #title-right>
        <slot name="title-right" />
      </template>
    </iui-content-box-title-box>
    <!-- title-box -->
    <!-- outer-content-box -->
    <div
      :class="{'outer-content-box': true, grid_box: borderBox && !noBorderBox, 'iui-loading-overlay-parent': true}"
      :style="{
        height: titleBoxVisible || isHeader ? 'calc(100% - 30px)' : '100%',
        'border-top': noBorderBox ? '2px solid #3378c1' : '',
        padding: noPaddingBox ? '0px' : '',
      }"
    >
      <iui-loading-overlay :visible="loading" />
      <!-- header-box -->
      <iui-content-box-header-box
        v-if="
          $slots['header-left'] !== undefined ||
            $slots['header-center'] !== undefined ||
            $slots['header-right'] !== undefined ||
            $slots['header-custom'] !== undefined
        "
      >
        <template #header-left>
          <slot name="header-left" />
        </template>
        <template #header-center>
          <slot name="header-center" />
        </template>
        <template #header-right>
          <slot name="header-right" />
        </template>
        <template #header-custom>
          <slot name="header-custom" />
        </template>
      </iui-content-box-header-box>
      <!-- header-box -->
      <!-- inner-content-box -->
      <div :class="{grid_inner_scroll: true}" :style="contentBoxStyle">
        <slot />
      </div>
    </div>
    <!-- outer-content-box -->
  </div>
</template>

<script>
export default {
  name: 'iui-content-box',
  props: {
    titleType: {
      type: String,
      default: undefined,
    },
    borderBox: {
      type: Boolean,
      default: false,
    },
    noBorderBox: {
      type: Boolean,
      default: false,
    },
    noPaddingBox: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contentBoxStyle: {},
    };
  },
  mounted() {
    let contentBoxHeight = '100%';
    if (this.headerBoxVisible) {
      let multiLeftCount = this.$el.querySelectorAll('.header-left > .header-multi-box > div').length;
      let multiCenterCount = this.$el.querySelectorAll('.header-center > .header-multi-box > div').length;
      let multiRightCount = this.$el.querySelectorAll('.header-right > .header-multi-box > div').length;

      let maxMultiCount = Math.max(multiLeftCount, multiCenterCount, multiRightCount);
      if (0 < maxMultiCount) {
        contentBoxHeight = `calc(100% - ${30 * maxMultiCount + 11}px)`;
      } else {
        contentBoxHeight = 'calc(100% - 35px)';
      }
    }
    this.contentBoxStyle = {height: contentBoxHeight};
  },
  //TODO computed에서 상태를 체크하면 외부에서 참조된 데이터로 변경시 $recompute를 다시 해줘야함. 번거로움으로 일단 사용안함
  computed: {
    titleBoxVisible() {
      return (
        this.titleVisible ||
        this.titleBulletVisible ||
        this.titleBulletRequiredVisible ||
        this.titleLeftVisible ||
        this.titleCenterVisible ||
        this.titleRightVisible
      );
    },
    titleVisible() {
      return this.$slots['title'] !== undefined;
    },
    titleBulletVisible() {
      return this.$slots['title-bullet'] !== undefined;
    },
    titleBulletRequiredVisible() {
      return this.$slots['title-bullet-required'] !== undefined;
    },
    titleLeftVisible() {
      return this.$slots['title-left'] !== undefined;
    },
    titleCenterVisible() {
      return this.$slots['title-center'] !== undefined;
    },
    titleRightVisible() {
      return this.$slots['title-right'] !== undefined;
    },
    headerBoxVisible() {
      return this.headerLeftVisible || this.headerCenterVisible || this.headerRightVisible || this.headerCustomVisible;
    },
    headerLeftVisible() {
      let vnodes = this.$slots['header-left'];
      if (vnodes !== undefined) {
        return Array.from(vnodes).filter(vnode => vnode.tag !== undefined).length > 0;
      } else {
        return false;
      }
    },
    headerCenterVisible() {
      let vnodes = this.$slots['header-center'];
      if (vnodes !== undefined) {
        return Array.from(vnodes).filter(vnode => vnode.tag !== undefined).length > 0;
      } else {
        return false;
      }
    },
    headerRightVisible() {
      let vnodes = this.$slots['header-right'];
      if (vnodes !== undefined) {
        return Array.from(vnodes).filter(vnode => vnode.tag !== undefined).length > 0;
      } else {
        return false;
      }
    },
    headerCustomVisible() {
      let vnodes = this.$slots['header-custom'];
      if (vnodes !== undefined) {
        return Array.from(vnodes).filter(vnode => vnode.tag !== undefined).length > 0;
      } else {
        return false;
      }
    },
  },
};
</script>

<style></style>
