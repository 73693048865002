<template>
  <iui-container-new type="ibsheet" :id="elId" />
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  props: {
    rngDsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
    };
  },
  computed: {
    ...mapGetters(['subFrmGb']),
  },
  created() {
    this.addEvent([{name: 'RngDsPopup_getRowsByChecked', func: this.getRowsByChecked}]);
  },
  mounted() {
    this.initSheet();
  },
  methods: {
    initSheet() {
      const OTP = {
        Cfg: {
          SearchMode: 2,
          MainCol: 'frmNm',
          CanDrag: 1,
        },
        Cols: [
          {
            Header: '대상',
            Type: 'Text',
            Name: 'frmNm',
            MinWidth: '200',
            Align: 'Left',
            RelWidth: 1,
            CanEdit: 0,
          },
          {
            Header: '선택',
            Type: 'Bool',
            Name: 'chk',
            Width: '50',
            Align: 'Center',
            CanEdit: 1,
          },
        ],
        Events: {
          onSearchFinish: this.grid_onSearchFinish,
          onAfterChange: this.grid_onAfterChange,
        },
      };
      PMISSheet.createSheet({
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OTP, // 초기화 구문 변수
      }).then(sheet => {
        this.sheet = sheet;
        this.sheet.util.onTreeCheck('chk');
        this.selectRngDsList();
      });
    },

    getRowsByChecked(param) {
      let rows = this.sheet.getRowsByChecked('chk');
      if (typeof param.callback == 'function') {
        param.callback(rows);
      }
    },

    async selectRngDsList() {
      const pgmCd = this.userInfo.pgmCd;
      const frmGb = this.subFrmGb;
      const response = await axios.post('/board/selectRngDsList', {pgmCd: pgmCd, frmGb: frmGb});
      const rngDsList = response.data;
      if (rngDsList.length) {
        let frmGbAll = {frmNm: '전체', frmGb: '', pgmCd: null, Items: []};
        let frmGbMe = {frmNm: '자사', frmGb: frmGb, pgmCd: this.pgmCd, Items: []};
        let frmGbRel = {frmNm: '협력사', frmGb: '', pgmCd: null, Items: []};
        let frmGb1 = {frmNm: '건설사', frmGb: '', pgmCd: null, Items: []};
        let frmGb2 = {frmNm: '외주', frmGb: '', pgmCd: null, Items: []};
        let frmGb3 = {frmNm: '물품자재', frmGb: '', pgmCd: null, Items: []};

        rngDsList.forEach(el => {
          if ([$getConstants('FRM_GB_1').code, $getConstants('FRM_GB_4').code].includes(el.frmGb)) {
            frmGb1.Items.push(el);
          }
          if ([$getConstants('FRM_GB_2').code].includes(el.frmGb)) {
            frmGb2.Items.push(el);
          }
          if ([$getConstants('FRM_GB_3').code].includes(el.frmGb)) {
            frmGb3.Items.push(el);
          }
        });

        if ([$getConstants('FRM_GB_0').code].includes(frmGb)) {
          // 운영사
          frmGbRel.Items.push(frmGb1);
          frmGbRel.Items.push(frmGb2);
          frmGbRel.Items.push(frmGb3);
          frmGbAll.Items.push(frmGbMe);
          frmGbAll.Items.push(frmGbRel);
        }

        if ([$getConstants('FRM_GB_1').code, $getConstants('FRM_GB_4').code].includes(frmGb)) {
          //건설사
          frmGbRel.Items.push(frmGb2);
          frmGbRel.Items.push(frmGb3);
          frmGbAll.Items.push(frmGbMe);
          frmGbAll.Items.push(frmGbRel);
        }
        if (
          [$getConstants('FRM_GB_2').code, $getConstants('FRM_GB_3').code, $getConstants('FRM_GB_4').code].includes(
            frmGb
          )
        ) {
          //외주 or 자재
          frmGbAll.Items.push(frmGbMe);
          frmGbAll.Items.push(frmGb1);
        }

        this.sheet.loadSearchData([frmGbAll]);
      }
    },
    grid_onAfterChange(e) {
      if (e.row.Kind === 'Data' && e.col === 'chk') {
        if (e.row.childNodes.length) {
          e.sheet.getChildRows(e.row).forEach(row => e.sheet.setValue(row, 'chk', e.val, 1));
        }
      }
    },
    grid_onSearchFinish() {
      this.sheet.getDataRows().forEach(row => {
        if (this.rngDsList.indexOf(String(row.pgmCd)) != -1) {
          this.sheet.setCheck(row, 'chk', 1);
        }
      });
    },
  },
};
</script>

<style></style>
