<template>
  <!-- 인력투입정보-포틀릿 -->
  <div style="height:100%">
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr>
          <th rowspan="2">직종</th>
          <th colspan="3">금일({{ date.substring(4, 6) }}월 {{ date.substring(6) }}일)</th>
          <th colspan="3">금월({{ date.substring(4, 6) }}월)</th>
        </tr>
        <tr>
          <th>인원</th>
          <th>공수</th>
          <th>노무비</th>
          <th>인원</th>
          <th>공수</th>
          <th class="p_last">노무비</th>
        </tr>
      </thead>
    </table>
    <div class="inner" style="height:calc(100% - 52px);">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="(item, index) in laborInputList" :key="index">
            <td>{{ item.jkdNm }}</td>
            <td class="num">{{ $_numberFormat(item.mdWorkerCnt, true) }} 명</td>
            <td class="num">{{ $_numberFormat(item.mdInvestQty, true) }} 명</td>
            <td class="num">{{ $_numberFormat(item.mdAmt, true) }} 원</td>
            <td class="num">{{ $_numberFormat(item.mmWorkerCnt, true) }} 명</td>
            <td class="num">{{ $_numberFormat(item.mmInvestQty, true) }} 명</td>
            <td class="p_last num">{{ $_numberFormat(item.mmAmt, true) }} 원</td>
          </tr>
          <tr>
            <td>합계</td>
            <td class="num">{{ $_numberFormat(total.mdWorkerCnt, true) }} 명</td>
            <td class="num">{{ $_numberFormat(total.mdInvestQty, true) }} 명</td>
            <td class="num">{{ $_numberFormat(total.mdAmt, true) }} 원</td>
            <td class="num">{{ $_numberFormat(total.mmWorkerCnt, true) }} 명</td>
            <td class="num">{{ $_numberFormat(total.mmInvestQty, true) }} 명</td>
            <td class="p_last num">{{ $_numberFormat(total.mmAmt, true) }} 원</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
import {selectLaborInput} from '@/components/portlet/api/portletData.js';
export default {
  mixins: [PortletMixin],
  props: {
    portletGroup: {
      type: String,
    },
    date: {
      type: String,
    },
    search: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cnt: 7,
      laborInputList: [],
      total: {
        mdWorkerCnt: 0,
        mdInvestQty: 0,
        mdAmt: 0,
        mmWorkerCnt: 0,
        mmInvestQty: 0,
        mmAmt: 0,
      },
    };
  },
  watch: {
    date(v) {
      if (v) {
        this.onSearch();
      }
    },
    search(v) {
      if (v) {
        this.onSearch();
      }
    },
  },
  methods: {
    $_numberFormat,
    setWidth() {
      this.tblWidth = this.$parent.$parent.$parent.$el.offsetWidth - 28;
    },
    async onSearch() {
      const param = {
        prjCd: this.portletGroup === $getConstants('PORTLET_GROUP_CONSTRUCTION_SITE').code ? this.prjCd : '',
        searchDt: this.date.replaceAll('-', ''),
      };
      const response = await selectLaborInput(param);
      this.laborInputList = response.data;

      for (const key in this.total) {
        this.total[key] = this.laborInputList.reduce((acc, cur) => acc + Number(cur[key]), 0);
      }

      this.$emit('update:search', false);
    },
  },
};
</script>

<style></style>
