const state = function() {
  return {
    searchInfo: {
      prjNm: '',
      ymd: '',
    },
    activeResource: 'WorkSummary',
    summary: {
      sncNo: 0,

      materialDayAmt: 0,
      materialMonthAmt: 0,
      materialTotAmt: 0,

      laborDayAmt: 0,
      laborMonthAmt: 0,
      laborTotAmt: 0,

      equipmentDayAmt: 0,
      equipmentMonthAmt: 0,
      equipmentTotAmt: 0,

      expenseDayAmt: 0,
      expenseMonthAmt: 0,
      expenseTotAmt: 0,

      sumDayAmt: 0,
      sumMonthAmt: 0,
      sumTotAmt: 0,

      sumDayRatePerCont: 0,
      sumMonthRatePerCont: 0,
      sumTotRatePerCont: 0,

      sumDayRatePerExec: 0,
      sumMonthRatePerExec: 0,
      sumTotRatePerExec: 0,
    },
  };
};

const getters = {
  searchInfo: state => state.searchInfo,
  activeResource: state => state.activeResource,
  summary: state => state.summary,
};

const mutations = {
  SET_SEARCH_INFO(state, payload) {
    for (let o in payload) {
      state.searchInfo[o] = String(payload[o]);
    }
  },
  SET_ACTIVE_RESOURCE(state, payload) {
    state.activeResource = payload;
  },
  INIT_SUMMARY(state) {
    for (let o in state.summary) {
      state.summary[o] = 0;
    }
  },
  SET_SUMMARY(state, payload) {
    state.summary = payload;
  },
};

const actions = {
  setSearchInfo(context, payload) {
    context.commit('SET_SEARCH_INFO', payload);
  },
  setActiveResource(context, payload) {
    context.commit('SET_ACTIVE_RESOURCE', payload);
  },
  setSummary(context, payload) {
    context.commit('SET_SUMMARY', payload);
  },
  async initSummary(context) {
    context.commit('INIT_SUMMARY');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
