<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px"><PrjList /></i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex" flex-direction="row">
            <i-col>
              <i-row>
                <iui-tab :comps="comps" :keepAlive="true" @after-active-tab="afterActiveTab"></iui-tab>
              </i-row>
            </i-col>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
/**
 * 가동일보등록
 * components :
 *   EqutDaily 가동일보
 *   EqutOil   유류정보
 * */
import store from './store/operationJournal';
import PrjList from '@/view/Project/components/PrjList.vue';
export default {
  components: {PrjList},
  data() {
    return {
      comps: [
        {
          tabNm: '가동일보',
          compNm: 'EqutDaily',
          path: '@/view/Resource/Equipment/OperationJournal/components/EqutDaily.vue',
        },
        {
          tabNm: '유류정보',
          compNm: 'EqutOil',
          path: '@/view/Resource/Equipment/OperationJournal/components/EqutOil.vue',
        },
      ],
      tabIndex: 0,
    };
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['searchInfo']);
    $mapMutations(this, ['setSearchInfo']);
  },
  created() {
    this.addFuncPrj(this.onSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },
  methods: {
    onSearch() {
      if (this.tabIndex == 0) {
        this.callEvent({name: 'EqutDaily_onSearchLastRunDate'});
      } else {
        this.callEvent({name: 'EqutOil_onSearch'});
      }
    },
    onAdd() {
      this.callEvent({name: 'EqutDaily_onAdd'});
    },
    onSave() {
      if (this.tabIndex == 0) {
        this.callEvent({name: 'EqutDaily_onSave'});
      } else {
        this.callEvent({name: 'EqutOil_onSave'});
      }
    },
    onDelete() {
      if (this.tabIndex == 0) {
        this.callEvent({
          name: 'EqutDaily_onDelete',
        });
      } else {
        this.callEvent({
          name: 'EqutOil_onDelete',
        });
      }
    },
    afterActiveTab(index) {
      if (this.tabIndex == index) return;
      this.tabIndex = index;

      if (this.tabIndex == 0) {
        this.callEvent({name: 'EqutDaily_onSearch'});
      } else {
        this.callEvent({name: 'EqutOil_onSearch'});
      }
    },
  },
};
</script>
