export const selectPxcondList = () => axios.post('/pxcondDtlsRegist/selectPxcondList', {});

export const selectPrepaymentInfo = () => axios.post('/pxcondDtlsRegist/selectPrepaymentInfo', {});

export const selectPxcondDetailInfo = param => axios.post('/pxcondDtlsRegist/selectPxcondDetailInfo', param);

export const updateFlNo = param => axios.post('/pxcondDtlsRegist/updateFlNo', param);

export const selectTaxInvoiceInfos = param => axios.post('/pxcondDtlsRegist/selectTaxInvoiceInfos', param);

export const createTaxInvoice = param => axios.post('/pxcondDtlsRegist/createTaxInvoice', param);

export const selectPxcondDetailDtlsList = param => axios.post('/pxcondDtlsRegist/selectPxcondDetailDtlsList', param);

export const savePxcondDetailInfo = param => axios.post('/pxcondDtlsRegist/savePxcondDetailInfo', param);

export const deletePxcondDetailInfos = param => axios.post('/pxcondDtlsRegist/deletePxcondDetailInfos', param);
