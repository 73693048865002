export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      SearchMode: 2,
      CanEdit: false,
      FitWidth: true,
    },
    Def: {
      Row: {
        NoColor: 1,
        CanFormula: 1,
      },
    },
    LeftCols: [
      {
        Header: ['출역일자', '출역일자'],
        Type: 'Text',
        Align: 'center',
        VAlign: 'Top',
        Name: 'investDt',
        Width: 85,
        ColMerge: true,
        CustomFormat: v => v.substr(0, 4) + '-' + v.substr(4, 2) + '-' + v.substr(6),
      },
      {
        Header: ['ID', 'ID'],
        Type: 'Text',
        Align: 'center',
        VAlign: 'Top',
        Name: 'empNo',
        Width: 100,
        ColMerge: true,
      },
      {
        Header: ['이름', '이름'],
        Type: 'Text',
        Align: 'center',
        VAlign: 'Top',
        Name: 'name',
        Width: 80,
        ColMerge: true,
      },
      {
        Header: ['소속', '소속'],
        Type: 'Text',
        Align: 'center',
        VAlign: 'Top',
        Name: 'blnDepNm',
        Width: 100,
        ColMerge: true,
      },
      {
        Header: ['일 공수', '일 공수'],
        Type: 'Float',
        Align: 'center',
        Name: 'investQty',
        Width: 80,
        ColMerge: true,
      },
      {
        Header: ['단가', '단가'],
        Type: 'Text',
        Align: 'center',
        Name: 'price',
        Extend: preset.unitAmount,
        Width: 100,
        ColMerge: true,
      },
      {
        Header: ['금액', '금액'],
        Type: 'Text',
        Align: 'center',
        Name: 'amt',
        Extend: preset.amount,
        Width: 100,
        ColMerge: true,
      },
    ],
    Cols: [
      {
        Header: ['투입내역', '공종명'],
        Type: 'Text',
        Align: 'center',
        Name: 'midName',
        RelWidth: 1,
        MinWidth: 150,
        ColMerge: false,
      },
      {
        Header: ['투입내역', '내역명'],
        Type: 'Text',
        Align: 'center',
        Name: 'itemName',
        RelWidth: 1,
        MinWidth: 150,
        ColMerge: false,
      },
      {
        Header: ['투입내역', '규격'],
        Name: 'ssize',
        Type: 'Text',
        Align: 'left',
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: ['투입내역', '단위'],
        Name: 'unitNm',
        Type: 'Text',
        Align: 'center',
        Width: 50,
        CanEdit: 0,
      },
      {
        Header: ['투입내역', '수량'],
        Name: 'moqty',
        Extend: preset.quantity,
        Width: 100,
        CanEdit: 0,
        ColMerge: false,
      },
      {
        Header: ['투입내역', '단가'],
        Type: 'Text',
        Align: 'center',
        Name: 'labPrice',
        Extend: preset.unitAmount,
        Width: 100,
        ColMerge: false,
      },
      {
        Header: ['투입내역', '금액'],
        Type: 'Text',
        Align: 'center',
        Name: 'labAmt',
        Extend: preset.amount,
        Width: 100,
        ColMerge: false,
      },
    ],
  };
};
