<template>
  <pmis-content-box>
    <template v-slot:title>반송의견</template>
    <iui-container-new type="css-flex" header-width="70px">
      <i-row height="370px">
        <i-col>
          <iui-text type="multi" name="reSndgRsn" :value="reSndgRsn" @change="reSndgRsn = $event.target.value" />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
export default {
  data() {
    return {
      reSndgRsn: '',
    };
  },
  created() {
    this.addEvent({name: 'sendReturn_onConfirm', func: this.onConfirm});
  },
  methods: {
    onConfirm(func) {
      if (typeof func == 'function') {
        func(this.reSndgRsn);
      }
    },
  },
};
</script>

<style scoped>
p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
</style>
