<template>
  <pmis-page ref="page">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col width="300px">
          <pmis-tab-box no-border-box no-padding-box>
            <template v-slot:title>공종분류</template>
            <PrjWorkTypeCl
                :callback="onSearchCallback"
            ></PrjWorkTypeCl>
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <DetailList />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import PrjList from '@/view/Project/components/PrjList.vue';
import PrjWorkTypeCl from '@/view/estmtManage/prjWorkTypeRegist/components/PrjWorkTypeCl.vue';
import DetailList from '@/view/workTypePxcondPlan/components/DetailList.vue';
import store from "@/view/estmtManage/prjWorkTypeRegist/store/ContWorkTypeRegist";
export default {
  components: {
    PrjList,
    PrjWorkTypeCl,
    DetailList
  },
  data() {
    return {
    };
  },
  beforeCreate() {
    $init(this, store, 'workType');
  },
  created() {
    this.addFuncPrj(this.onSearch);
  },
  methods: {
    onSearch(){
      this.callEvent({name: 'prjWorkTypeCl_onSearch'});
      this.callEvent({name: 'detailClear'});
    },
    onSearchCallback(row){
      this.callEvent({name: 'getYearList', param: {wCode: row.wcode, lgCode: row.lgCode, mgCode: row.mgCode}});
    }
  },
};
</script>
