import {selectPrePayable, selectRegisterSubDetail, selectSubAnmonList, deleteSubAnmon} from '../api/anyTimeSubcon.js';
const state = {
  searchInfo: {
    investMm: '',
    reqDegree: '',
    custName: '',
  },
  detailInfo: {
    custCode: '',
    poNoSubc: '',
    repayfrDt: '',
    repaytoDt: '',
    curInputAmt: 0,
    curDeductAmt: 0,
    curEtcinputAmt: 0,
    prePayable: 0,
    curReserveAmt: 0,
    amt: 0,
    vatAmt: 0,
    taxexe: 0,
    sumAmt: 0,
    billNo: '',
    flNo: 0,
    billTaxNo: '',
    flTaxNo: 0,
  },
  subAnmonList: [],
  calculateList: [],
  closeList: [],
  closeYn: '',
};

const getters = {
  searchInfo: state => state.searchInfo,
  detailInfo: state => state.detailInfo,
  subAnmonList: state => state.subAnmonList,
  calculateList: state => state.calculateList,
  closeYn: state => state.closeYn,
  anyTimeSubSaveDataReqDto: state => {
    const keys = [
      'investMm',
      'reqDegree',
      'repayfrDt',
      'repaytoDt',
      'custCode',
      'poNoSubc',
      'curInputAmt',
      'curDeductAmt',
      'curEtcInputAmt',
      'prePayable',
      'curReserveAmt',
      'amt',
      'vatAmt',
      'taxexe',
      'sumAmt',
    ];
    let param = {};
    for (let key in state.searchInfo) {
      if (keys.findIndex(item => item === key) !== -1) {
        param[key] = state.searchInfo[key];
      }
    }
    for (let key in state.detailInfo) {
      if (keys.findIndex(item => item === key) !== -1) {
        param[key] = state.detailInfo[key];
      }
    }
    param['anyTimeSubSaveItemList'] = state.closeList;
    return param;
  },
};

const mutations = {
  setSearchInfo(state, payload) {
    for (let key in payload) {
      if (state.searchInfo.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  initDetailInfo(state) {
    let NumberKeys = [
      'curInputAmt',
      'curDeductAmt',
      'curEtcinputAmt',
      'prePayable',
      'curReserveAmt',
      'amt',
      'vatAmt',
      'taxexe',
      'sumAmt',
      'flNo',
      'flTaxNo',
    ];
    for (let key in state.detailInfo) {
      state.detailInfo[key] = NumberKeys.indexOf(key) !== -1 ? 0 : '';
    }
  },
  initDetailAsAmt(state) {
    let NumberKeys = ['curInputAmt', 'curDeductAmt', 'curEtcinputAmt', 'amt', 'vatAmt', 'taxexe', 'sumAmt'];
    for (let key in state.detailInfo) {
      if (NumberKeys.indexOf(key) !== -1) {
        state.detailInfo[key] = 0;
      }
    }
  },
  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = payload[key];
      }
    }
  },
  setSubAnmonList(state, payload) {
    state.subAnmonList = payload;
  },
  initCalculateList(state) {
    state.calculateList = [];
  },
  setCalculateList(state, payload) {
    state.calculateList = payload;
  },
  setSumAmt(state) {
    state.detailInfo.sumAmt = $_getSumAmt(state.detailInfo.amt, state.detailInfo.vatAmt);
  },
  setCloseList(state, payload) {
    state.closeList = payload;
  },
  setCloseYn(state, closeYn) {
    state.closeYn = closeYn;
  },
};

const actions = {
  async selectPrePayable({state, commit}) {
    const param = {
      investMm: state.searchInfo.investMm,
      custCode: state.detailInfo.custCode,
      poNoSubc: state.detailInfo.poNoSubc,
    };
    const response = await selectPrePayable(param);
    commit('setDetailInfo', {prePayable: response.data});
  },
  async selectRegisterSubDetail({state, commit}) {
    const param = {
      reqDegree: state.searchInfo.reqDegree,
      custCode: state.detailInfo.custCode,
      poNoSubc: state.detailInfo.poNoSubc,
      repayfrDt: state.detailInfo.repayfrDt,
      repaytoDt: state.detailInfo.repaytoDt,
    };
    const response = await selectRegisterSubDetail(param);
    commit('setCalculateList', response.data);
  },

  async selectSubAnmonList({state, commit}) {
    commit('initDetailInfo');
    commit('initCalculateList');
    const response = await selectSubAnmonList(state.searchInfo);
    const subAnmonList = response.data;
    commit('setCloseYn', !!subAnmonList.find(item => item.closeYn === $getConstants('Y').code));
    commit('setSubAnmonList', subAnmonList);
  },

  setCalculateAmt({state, commit}, curInputAmt) {
    commit('initDetailAsAmt');
    let amt =
      curInputAmt +
      state.detailInfo.prePayable +
      state.detailInfo.curEtcinputAmt -
      state.detailInfo.curDeductAmt -
      state.detailInfo.curReserveAmt;
    let vatAmt = $_getVatAmt(amt, state.detailInfo.taxexe);
    commit('setDetailInfo', {curInputAmt, amt, vatAmt});
    commit('setSumAmt');
  },
  setCurEtcinputAmt({state, commit}, curEtcinputAmt) {
    let amt =
      state.detailInfo.curInputAmt +
      state.detailInfo.prePayable +
      curEtcinputAmt -
      state.detailInfo.curDeductAmt -
      state.detailInfo.curReserveAmt;
    let vatAmt = $_getVatAmt(amt, state.detailInfo.taxexe);
    commit('setDetailInfo', {curEtcinputAmt, amt, vatAmt});
    commit('setSumAmt');
  },
  setCurDeductAmt({state, commit}, curDeductAmt) {
    let amt =
      state.detailInfo.curInputAmt +
      state.detailInfo.prePayable +
      state.detailInfo.curEtcinputAmt -
      curDeductAmt -
      state.detailInfo.curReserveAmt;
    let vatAmt = $_getVatAmt(amt, state.detailInfo.taxexe);
    commit('setDetailInfo', {curDeductAmt, amt, vatAmt});
    commit('setSumAmt');
  },
  setCurReserveAmt({state, commit}, curReserveAmt) {
    let amt =
      state.detailInfo.curInputAmt +
      state.detailInfo.prePayable +
      state.detailInfo.curEtcinputAmt -
      state.detailInfo.curDeductAmt -
      curReserveAmt;
    let vatAmt = $_getVatAmt(amt, state.detailInfo.taxexe);
    commit('setDetailInfo', {curReserveAmt, amt, vatAmt});
    commit('setSumAmt');
  },
  setVatAmt({commit}, vatAmt) {
    commit('setDetailInfo', {vatAmt});
    commit('setSumAmt');
  },
  setTaxexe({state, commit}, taxexe) {
    let vatAmt = $_getVatAmt(state.detailInfo.amt, taxexe);
    commit('setDetailInfo', {vatAmt, taxexe});
    commit('setSumAmt');
  },
  async deleteSubAnmon({state, dispatch}) {
    const param = {
      investMm: state.searchInfo.investMm,
      reqDegree: state.searchInfo.reqDegree,
      custCode: state.detailInfo.custCode,
      poNoSubc: state.detailInfo.poNoSubc,
    };
    const response = await deleteSubAnmon(param);
    if (response.data > 0) {
      dispatch('selectSubAnmonList');
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
