export default that => {
  return {
    Cfg: {
      CanEdit: 0,
    },
    Cols: [
      {
        Header: '변경차수',
        Name: 'ctrtChgTo',
        Type: 'Text',
        Width: 70,
        Align: 'center',
      },
      {
        Header: '계약일자',
        Name: 'ctrtDate',
        Type: 'Text',
        Width: 100,
        Align: 'center',
        // Extend: that.$ibsheetHelper.preset.date,
        CustomFormat: v => (v.length > 0 ? v.substr(0, 4) + '-' + v.substr(4, 2) + '-' + v.substr(6, 2) : ''),
      },
      {
        Header: '계약공사기간',
        Name: 'conPeriod',
        Type: 'Text',
        Width: 180,
        Align: 'center',
      },
      {
        Header: '착공일자',
        Name: 'ctrtStrdate',
        Type: 'Text',
        Width: 100,
        Align: 'center',
        // Extend: that.$ibsheetHelper.preset.date,
        CustomFormat: v => (v.length > 0 ? v.substr(0, 4) + '-' + v.substr(4, 2) + '-' + v.substr(6, 2) : ''),
      },
      {
        Header: '준공예정일자',
        Name: 'ctrtEnddate',
        Type: 'Text',
        Width: 100,
        Align: 'center',
        // Extend: that.$ibsheetHelper.preset.date,
        CustomFormat: v => (v.length > 0 ? v.substr(0, 4) + '-' + v.substr(4, 2) + '-' + v.substr(6, 2) : ''),
      },
      {
        Header: '과세액(원)',
        Name: 'ctrtAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
        MinWidth: 120,
      },
      {
        Header: '부가세액(원)',
        Name: 'ctrtVat',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
        MinWidth: 120,
      },
      {
        Header: '비과세액(원)',
        Name: 'ctrtTaxexe',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
        MinWidth: 120,
      },
      {
        Header: '계약금액(원)',
        Name: 'ctrtSumAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
        MinWidth: 120,
      },
      {Name: 'pgmCd', Visible: false},
      {Name: 'prjCd', Visible: false},
      {Name: 'lstSumAmt', Visible: false},
    ],
  };
};
