<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="500px">
          <PoList process="complete" />
        </i-col>
        <i-spacer />
        <i-col>
          <iui-tab :comps="tabComps" :visibles="tabVisibles" :isAuto="false" @click="onClickTab" />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from '../store/subPoCustomer.js';

import PoList from '../components/PoList.vue';

export default {
  components: {PoList},
  data() {
    return {
      tabIndex: 0,
      tabComps: [
        {
          tabNm: '계약정보',
          compNm: 'PoInfo',
          path: '@/view/bidding/poCustomer/sub/components/PoInfo.vue',
        },
        {
          tabNm: '인지세',
          compNm: 'ContractStampList',
          path: '@/view/bidding/poManage/contract/components/stamp/ContractStampInfo.vue',
        },
      ],
    };
  },
  computed: {
    tabVisibles() {
      const isStamp = this.poStatus.isStamp;
      if (!isStamp) {
        this.onClickTab(0);
      }
      return {ContractStampList: isStamp};
    },
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['poStatus']);
  },
  methods: {
    onClickTab(index) {
      if (this.tabIndex === index) {
        return;
      }
      this.tabIndex = index;
      this.callEvent({name: 'setActiveTab', param: this.tabIndex});
    },
  },
};
</script>

<style></style>
