<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col width="400px">
        <pmis-content-box>
          <template v-slot:header-left>
            <label>성명</label>
            <iui-text type="search" :value.sync="searchNm" @enter="onSearch()" />
            <iui-button value="검색" @click="onSearch()" />
          </template>
          <ib-sheet
            :uid="_uid"
            :options="Options"
            :loadSearchData="loadSearchData"
            :events="{
              onFocus: gridFocus,
              onDblClick: () => this.$modalConfirm(),
            }"
            @loadSheet="loadSheet"
          />
        </pmis-content-box>
      </i-col>
      <i-spacer />
      <i-col>
        <iui-worker-info :workerData.sync="workerData" />
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import options from '@/view/member/components/sheetOption/WorkerInfoSheet.js';
import {selectWorkerList} from '@/view/user/Worker/api/worker.js';
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      searchNm: '',
      loadSearchData: [],
      Options: options(this),
      workerData: {
        empNo: '',
        name: '',
        blnDepNm: '',
        dtsNm: '',
        rollNm: '',
        tlno: '',
        mobileTlno: '',
        rpsvFaxno: '',
        postNo: '',
        adr1: '',
        adr2: '',
        email1: '',
        email2: '',
        phoFlNo: 0,
        useMsg: '',
      },
    };
  },
  created() {
    this.addEvent([{name: 'workerInfo_getData', func: this.getData}]);
    this.onSearch();
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
    async onSearch() {
      const response = await selectWorkerList({searchNm: this.searchNm, laborDs: $getConstants('LABOR_DS1').code});
      this.loadSearchData = response.data;
    },
    gridFocus(e) {
      $getCodeNm($getConstants('DTS_NO').code, e.row.dtsNo).then(response => {
        this.workerData['dtsNm'] = response;
      });

      $getCodeNm($getConstants('ROLL_NO').code, e.row.rollNo).then(response => {
        this.workerData['rollNm'] = response;
      });

      $getCodeNm($getConstants('EMAIL_CD').code, e.row.email2).then(response => {
        this.workerData['email2'] = response;
      });

      this.workerData['empNo'] = e.row.empNo;
      this.workerData['name'] = e.row.name;
      this.workerData['blnDepNm'] = e.row.blnDepNm;
      this.workerData['tlno'] = e.row.tlno;
      this.workerData['mobileTlno'] = e.row.mobileTlno;
      this.workerData['rpsvFaxno'] = e.row.rpsvFaxno;
      this.workerData['postNo'] = e.row.postNo;
      this.workerData['adr1'] = e.row.adr1;
      this.workerData['adr2'] = e.row.adr2;
      this.workerData['email1'] = e.row.email1;
      this.workerData['phoFlNo'] = e.row.phoFlNo;
      this.workerData['useMst'] = e.row.useMst;
    },
    getData(callback) {
      callback(this.workerData);
    },
  },
};
</script>

<style></style>
