<template>
  <iui-content-box :loading="loading">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <ib-sheet
            :uid="_uid"
            :options="Options"
            :loadSearchData="loadSearchData"
            :events="{
              onRenderFirstFinish,
              onClick,
              onSearchFinish,
            }"
            @loadSheet="loadSheet"
          />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row height="250px">
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>상세정보</template>
            <pmis-content-box>
              <form onsubmit="return false;">
                <i-row>
                  <i-col>
                    <iui-container-new type="table" theme="bullet">
                      <i-row>
                        <i-col-header>현장명</i-col-header>
                        <i-col>
                          <iui-text type="code" name="prjCd" :value="guarantyInfo.prjCd" :enable="false" />
                          <iui-text type="text" name="prjNm" :value="guarantyInfo.prjNm" :enable="false" />
                        </i-col>
                        <i-spacer />
                        <i-col-header>순번</i-col-header>
                        <i-col>
                          <iui-text type="code" name="issueSeq" :value="guarantyInfo.issueSeq" :enable="false" />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header required>보증종류</i-col-header>
                        <i-col>
                          <iui-select
                            name="guarKind"
                            width="90px"
                            :p-code="$getConstants('GUAR_KIND').code"
                            :value="guarantyInfo.guarKind"
                            :enable="isEnable"
                            :required="isEnable"
                            :errorMessage="{title: '보증종류', message: '보증종류는 필수입력입니다'}"
                            :defaultCd="'S'"
                            @change="value => onChangeGuarKind(value)"
                          />
                        </i-col>
                        <i-spacer />
                        <i-col-header required>발급구분</i-col-header>
                        <i-col>
                          <iui-select
                            name="issueDs"
                            width="90px"
                            :p-code="$getConstants('ISSUE_DS').code"
                            defaultCd="S"
                            :value.sync="guarantyInfo.issueDs"
                            :enable="isEnable"
                            :required="isEnable"
                            :errorMessage="{title: '발급구분', message: '발급구분은 필수입력입니다'}"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header required>보증금율</i-col-header>
                        <i-col>
                          <iui-text
                            type="rate"
                            name="guarRt"
                            :value="guarantyInfo.guarRt"
                            :bindArray="bindArray"
                            :enable="false"
                            :required="isEnable"
                            :errorMessage="{title: '보증금율', message: '보증금율은 필수입력입니다'}"
                            @change="onChangeGuarRt"
                          />
                        </i-col>
                        <i-spacer />
                        <i-col-header required>보증금액</i-col-header>
                        <i-col>
                          <iui-text
                            type="amount"
                            name="guarAmt"
                            max-length="25"
                            :value="guarantyInfo.guarAmt"
                            :bindArray="bindArray"
                            :enable="isPrepay"
                            :required="isEnable"
                            :errorMessage="{title: '보증금액', message: '보증금액은 필수입력입니다'}"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header required>보증서번호</i-col-header>
                        <i-col>
                          <iui-text
                            type="text"
                            name="billNo"
                            width="120px"
                            max-length="50"
                            :value="guarantyInfo.billNo"
                            :bindArray="bindArray"
                            :enable="isEnable"
                            :required="isEnable"
                            :errorMessage="{title: '보증서번호', message: '보증서번호는 필수입력입니다'}"
                            @change="onChangeData"
                          />
                        </i-col>
                        <i-spacer />
                        <i-col-header required>발급일자</i-col-header>
                        <i-col>
                          <iui-datepicker
                            name="issueDate"
                            :value="String(guarantyInfo.issueDate)"
                            :bindArray="bindArray"
                            :enable="isEnable"
                            :required="isEnable"
                            :errorMessage="{title: '발급일자', message: '발급일자는 필수입력입니다'}"
                            @change="onChangeData"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header required>보증채권자</i-col-header>
                        <i-col>
                          <iui-searchbox
                            type="customer"
                            :idValue="guarantyInfo.guarName"
                            :nameValue="guarantyInfo.guarNameNm"
                            name="guarName"
                            nameEleName="guarNameNm"
                            :idBindArray="bindArray"
                            :nameBindArray="bindArray"
                            :paramObj="{frmDs: $getConstants('FRM_DS_ORDER').code}"
                            :isSearchBtn="isEnable"
                            required
                            :errorMessage="{title: '보증채권사', message: '보증채권사는 필수입력입니다'}"
                            width="350px"
                            @change="onChangeData"
                          />
                        </i-col>
                        <i-spacer />
                        <i-col-header required>보증기간</i-col-header>
                        <i-col>
                          <iui-datepicker
                            name="guarStrdate"
                            :value="String(guarantyInfo.guarStrdate)"
                            :suffix="'~'"
                            :bindArray="bindArray"
                            :enable="isEnable"
                            :required="isEnable"
                            :errorMessage="{title: '보증기간', message: '보증기간시작일은 필수입력입니다'}"
                            :group="guaranteeDate.seq1"
                            @change="onChangeData"
                          />
                          <iui-datepicker
                            name="guarEnddate"
                            :value="String(guarantyInfo.guarEnddate)"
                            :bindArray="bindArray"
                            :enable="isEnable"
                            :required="isEnable"
                            :errorMessage="{title: '보증기간', message: '보증기간종료일은 필수입력입니다'}"
                            :group="guaranteeDate.seq2"
                            @change="onChangeData"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header required>보증기관</i-col-header>
                        <i-col>
                          <iui-searchbox
                            type="customer"
                            :idValue="guarantyInfo.issueOrg"
                            :nameValue="guarantyInfo.issueOrgNm"
                            name="issueOrg"
                            nameEleName="issueOrgNm"
                            :idBindArray="bindArray"
                            :nameBindArray="bindArray"
                            :paramObj="{frmDs: $getConstants('FRM_DS_GUAR').code}"
                            :isSearchBtn="isEnable"
                            required
                            :errorMessage="{title: '보증기관', message: '보증기관은 필수입력입니다'}"
                            width="350px"
                            @change="onChangeData"
                          />
                        </i-col>
                      </i-row>
                    </iui-container-new>
                  </i-col>
                </i-row>
              </form>
            </pmis-content-box>
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </iui-content-box>
</template>

<script>
/**
 * 계약정보등록(tab1) > 보증내역
 *
 * */
import options from './sheetOption/GuarantyDtlsSheet.js';
import {deleteGuarantyInfo, saveGuarantyInfo, selectGuarantyList} from '../api/cntrctInfoRegist.js';
import IuiContainerNew from '@/components/Iui/components/IuiContainer/IuiContainerNew';

export default {
  components: {IuiContainerNew},
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'guarantyInfo']);
    $mapMutations(this, ['SET_GUARANTY_INFO']);
    $mapActions(this, ['initGuarantyInfo', 'setGuarantyInfo']);
  },
  created() {
    this.addEvent([
      {name: 'GuarantyDtls_onSearch', func: this.onSearch},
      {name: 'GuarantyDtls_onAdd', func: this.onAdd},
      {name: 'GuarantyDtls_onSave', func: this.onSave},
      {name: 'GuarantyDtls_onDelete', func: this.onDelete},
    ]);
  },
  data() {
    return {
      loadSearchData: [],
      Options: options(this),
      bindArray: ['SET_GUARANTY_INFO'],

      guaranteeDate: {
        seq1: {grpNm: 'guaranteeDate', seq: 1},
        seq2: {grpNm: 'guaranteeDate', seq: 2},
      },

      cntrctInfo: {
        pgmCd: '',
        prjCd: '',
        prjNm: '', // 현장명
        ctrtSumAmt: '', // 계약금액
        prepayAmt: '', // 선급금액
        prfmInsrRate: '', // 계약보증금 비율
        prfmInsrAmt: '', // 계약보증금액
        flawInsrRate: '', // 하자보수보증금율
        flawInsrAmt: '', // 하자보수보증금액
      },
      focusKey: '',

      isPrepay: false,
      loading: false,
    };
  },
  computed: {
    isEnable() {
      return '' !== String(this.guarantyInfo.issueSeq);
    },
  },
  methods: {
    async onSearch() {
      this.loading = true;
      const param = {...this.searchInfo};
      try {
        const response = await selectGuarantyList(param);
        this.loadSearchData = response.data.guarantyList;
        this.cntrctInfo = response.data.cntrctInfo;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    onAdd() {
      this.isPrepay = false;
      this.initGuarantyInfo();
      this.setGuarantyInfo({
        pgmCd: this.cntrctInfo.pgmCd,
        prjCd: this.cntrctInfo.prjCd,
        prjNm: this.cntrctInfo.prjNm,
        issueSeq: '자동',
        cud: 1,
      });
    },
    onChangeData() {
      if (this.guarantyInfo.cud == 0) {
        this.guarantyInfo.cud = 2;
      }
    },
    onChangeGuarKind(guarKind) {
      const issueSeq = this.guarantyInfo.issueSeq;
      const findRow = this.sheet.getDataRows().find(row => row.guarKind == guarKind && row.issueSeq != issueSeq);
      const issueDs = findRow === undefined ? $getConstants('ISSUE_DS_NEW').code : $getConstants('ISSUE_DS_RE').code;
      this.isPrepay = false;
      let guarRt = '';
      let guarAmt = '';
      if (guarKind == $getConstants('GUAR_KIND_CONTRACT').code) {
        guarRt = this.cntrctInfo.prfmInsrRate;
        guarAmt = this.cntrctInfo.prfmInsrAmt;
      }
      if (guarKind == $getConstants('GUAR_KIND_DEFECT').code) {
        guarRt = this.cntrctInfo.flawInsrRate;
        guarAmt = this.cntrctInfo.flawInsrAmt;
      }
      if (guarKind == $getConstants('GUAR_KIND_ADVANCED').code) {
        guarAmt = ''; // 기성에서 추후 가져오기
        guarRt = 100;
        this.isPrepay = true;
      }

      this.setGuarantyInfo({guarKind, issueDs, guarRt, guarAmt});
    },
    onChangeGuarRt(e) {
      const guarRt = 100 < Number(e.target.value) ? '' : e.target.value;
      const guarAmt = guarRt ? (this.cntrctInfo.ctrtSumAmt * guarRt) / 100 : '';
      setTimeout(() => this.setGuarantyInfo({guarRt, guarAmt}), 100);
    },
    async onSave() {
      if (!this.isEnable) {
        this.$alert({title: '저장', message: '저장할 보증정보가 없습니다.'});
        return;
      }
      let param = {...this.guarantyInfo};
      param.issueSeq = param.cud === 1 ? '' : param.issueSeq;
      param.issueDate =
        String(param.issueDate).length === 13
          ? dateFormat(new Date(Number(param.issueDate))).replace(/-/g, '')
          : param.issueDate;
      const response = await saveGuarantyInfo(param);
      if (response.status !== 999) {
        this.focusKey = response.data;
        this.onSearch();
      }
    },
    async onDelete() {
      if (String(this.guarantyInfo.issueSeq) == '') {
        this.$alert({title: '삭제', message: '삭제할 보증정보가 없습니다.'});
        return;
      }
      if (this.guarantyInfo.cud == 1) {
        this.$alert({title: '삭제', message: '신규정보는 삭제할 수 없습니다.'});
        return;
      }
      let param = {
        pgmCd: this.guarantyInfo.pgmCd,
        prjCd: this.guarantyInfo.prjCd,
        issueSeq: this.guarantyInfo.issueSeq,
      };
      if (await this.$confirm({title: '보증내역 삭제', message: '보증내역을 삭제 하시겠습니까?'})) {
        deleteGuarantyInfo(param).then(() => this.onSearch());
      }
    },
    onSearchFinish(e) {
      if (0 < e.sheet.getTotalRowCount()) {
        const focusRow = e.sheet.getDataRows().find(row => this.focusKey !== '' && row.issueSeq == this.focusKey);
        if (focusRow !== undefined) {
          this.sheet.focus(focusRow);
          this.setGuarantyInfo(focusRow);
        } else {
          this.setGuarantyInfo(e.sheet.getFocusedRow());
        }
      } else {
        this.initGuarantyInfo();
      }
      this.focusKey = '';
      this.isPrepay = this.guarantyInfo.guarKind === $getConstants('GUAR_KIND_ADVANCED').code;
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.setGuarantyInfo(e.row);
        this.isPrepay = this.guarantyInfo.guarKind === $getConstants('GUAR_KIND_ADVANCED').code;
      }
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped></style>
