export default that => {
  return {
    Cfg: {
      CanEdit: false,
    },
    Cols: [
      {
        Header: '외주명',
        Name: 'cusNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 180,
      },
      {
        Header: '계약번호',
        Name: 'poNo',
        Type: 'Text',
        Align: 'Center',
        Width: 140,
        CustomFormat: v => `${v.substr(8, 8)}-${v.substr(16, 4)}`,
      },
      {
        Header: '계약명',
        Name: 'ttl',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 180,
      },
      {Name: 'wbsCd', Visible: false},
      {Name: 'wbsId', Visible: false},
    ],
  };
};
