import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
export default that => {
  return {
    Cfg: {CanEdit: 0},
    Def: {
      Row: {
        NoColor: 1,
        CanFormula: true,
        CalcOrder: 'TextColor,TextStyle',
        TextColorFormula(param) {
          if (param.Row['cancelYn'] === BIDDING_CONSTANTS.CODES.RFQ_CANCEL_Y) {
            return '#FF0000';
          }
        },
        TextStyleFormula(param) {
          if (param.Row['cancelYn'] === BIDDING_CONSTANTS.CODES.RFQ_CANCEL_Y) {
            return 8; // 취소선 (strike)
          }
        },
      },
    },
    Cols: [
      {Header: '현장명', Name: 'spotDeptnm', Type: 'Text', RelWidth: 1, MinWidth: 120},
      {
        Header: '입찰번호',
        Name: 'rfqNo',
        Type: 'Text',
        Align: 'center',
        Width: 110,
        CustomFormat: v => `${v.substr(8, 8)}-${v.substr(16)}`,
      },
      {Header: '입찰제목', Name: 'ttl', Type: 'Text', RelWidth: 1, MinWidth: 150},
      {
        Header: '공고일',
        Name: 'bidStartDt',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        MinWidth: 140,
        DefaultValue: '-',
        Visible: 0,
      },
      {Header: '마감일', Name: 'bidExpireDt', Type: 'Text', Align: 'center', RelWidth: 1, MinWidth: 140, Visible: 0},
      {Header: '입찰담당', Name: 'chrgUsrnm', Type: 'Text', Align: 'center', RelWidth: 1, MinWidth: 120, Visible: 0},
      {
        Header: '진행상태',
        Name: 'rfqSt',
        Type: 'Text',
        Align: 'center',
        Width: 100,
        Format: $getConstants('BIDDING_ST').code,
      },

      {Name: 'spotDeptcd', Visible: 0},
      {Name: 'cancelYn', Visible: 0},
    ],
  };
};
