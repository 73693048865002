export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 0},
    Def: {
      Row: {NoColor: 2, CanFocus: 0},
    },
    Cols: [
      {Header: '공종명', Name: 'srvNm', RelWidth: 0.8},
      {Header: '내역명', Name: 'itemNm', RelWidth: 1},
      {Header: '규격', Name: 'sizeNm', RelWidth: 0.8},
      {Header: '단위', Name: 'unit', Type: 'Text', Align: 'center', Width: 80, Format: $getConstants('UNIT').code},
      {Header: '수량', Name: 'qty', Width: 100, Extend: preset.quantity},
      {Header: '비고', Name: 'srvRmrk', RelWidth: 0.8},
      {Header: '단가', Name: 'unitPrc', RelWidth: 1, Visible: false, Extend: preset.unitAmount},
      {
        Header: '금액',
        Name: 'amt',
        RelWidth: 1,
        Visible: false,
        Extend: preset.amount,
        Formula: fr => fr.Row.qty * fr.Row.unitPrc,
      },
    ],
  };
};
