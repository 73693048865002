<template>
  <!-- 결재대기-포틀릿 -->
  <div style="height:100%;">
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr height="30px">
          <th>문서제목</th>
          <th>기안자</th>
          <th class="p_last">기안일</th>
        </tr>
      </thead>
    </table>
    <div class="inner" style="height:calc(100% - 40px);">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="(doc, index) in standbyDocList" :key="index">
            <td style="text-align:left;">
              <a @click="onClickDoc(doc.sncNo)">{{ doc.tl }}</a>
            </td>
            <td>{{ doc.drafterName }}</td>
            <td class="p_last">{{ doc.draftDt }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {selectStandbyDoc} from '@/components/portlet/api/portletData.js';
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
export default {
  mixins: [PortletMixin],
  data() {
    return {
      cnt: 3,
      standbyDocList: [],
    };
  },
  methods: {
    setWidth() {
      this.tblWidth = this.$parent.$el.offsetWidth - 28;
    },
    async onSearch() {
      const response = await selectStandbyDoc();
      this.standbyDocList = response.data;
      this.$emit('update:unchkedCnt', response.data.length);
    },
    onClickDoc(sncNo) {
      this.goMenu('APP_STANDBY', {sncNo});
    },
  },
};
</script>

<style></style>
