export default that => {
  return {
    Cfg: {
      IgnoreFocused: true,
    },
    Cols: [
      {
        Header: '순번',
        Name: 'seq',
        Type: 'Text',
        Width: 50,
        CanEdit: false,
      },
      {
        Header: that.companyInfo?.frmGb == $getConstants('FRM_GB_2').code ? '공사지역' : '납품지역',
        Name: 'siteArea2',
        Type: 'Enum',
        RelWidth: 1,
        EnumKeys: $getConstants('SITE_AREA1_CD').code,
        Enum: $getConstants('SITE_AREA1_CD').code,
        CanEdit: that.enable,
      },
      {
        Header: '대표지역여부',
        Name: 'areaFDs',
        Type: 'Enum',
        RelWidth: 1,
        EnumKeys: `|${that.code100.map(code => String(code.code)).join('|')}`,
        Enum: `|${that.code100.map(code => String(code.name)).join('|')}`,
        CanEdit: that.enable,
        OnChange: that.onAreaFdsChange,
      },
      {
        Name: 'cud',
        Type: 'Text',
        Visible: 0,
      },
    ],
  };
};
