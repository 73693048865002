// WorkerList 근로자목록
export const selectWorkerList = param => axios.post('/worker/selectWorkerList', param);
// WorkerInfo 상세정보
export const selectWorkerInfo = param => axios.post('/worker/selectWorkerInfo', param);
export const selectWorkerExistEmpNo = param => axios.post('/worker/selectWorkerExistEmpNo', param);
export const selectContUserCount = param => axios.post('/worker/selectContUserCount', param);
export const selectWorkerExistRegiNo = param => axios.post('/worker/selectWorkerExistRegiNo', param);
export const saveWorkerInfo = param => $save('/worker/saveWorkerInfo', param);
export const selectWorkerInfoList = param => axios.post('/worker/selectWorkerInfoList', param);
export const deleteWorkerInfo = param => axios.post('/worker/deleteWorkerInfo', param);
export const updateWorkerFlNo = param => $save('/worker/updateWorkerFlNo', param);
export const updateWorkerInfo = param => axios.post('/worker/updateWorkerInfo', param);
// InputPrj 투입현장
export const selectInputPrjList = param => axios.post('/worker/selectInputPrjList', param);
export const deleteInputPrjList = param => axios.post('/worker/deleteInputPrjList', param);
export const saveInputPrjList = param => axios.post('/worker/saveInputPrjList', param);
// LicenseList 자격면허정보
export const selectWorkerSkill = param => axios.post('/worker/selectWorkerSkill', param);
export const deleteWorkerSkill = param => axios.post('/worker/deleteWorkerSkill', param);
export const saveWorkerSkill = param => axios.post('/worker/saveWorkerSkill', param);
