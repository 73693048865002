import {selectRegisterEqutDetail, selectEqutAnmonList, deleteEqutAnmon} from '../api/anyTimeEquipment';
const state = {
  searchInfo: {
    investMm: '',
    reqDegree: '',
    custName: '',
  },
  detailInfo: {
    custCode: '',
    equipCode: '',
    carNo: '',
    repayfrDt: '',
    repaytoDt: '',
    monthlyDay: 0,
    monthlyQty: 0,
    monthlyAmt: 0,
    oilAmt: 0,
    oilDeductAmt: 0,
    etcDeductAmt: 0,
    amt: 0,
    vatAmt: 0,
    taxexe: 0,
    sumAmt: 0,
    billNo: '',
    flNo: 0,
    billTaxNo: '',
    flTaxNo: 0,
  },
  investMmList: [],
  equtClosingList: [],
  equtAnmonList: [],
  calculateList: [],
  closeList: [],
  closeYn: '',
};
const getters = {
  searchInfo: state => state.searchInfo,
  detailInfo: state => state.detailInfo,
  investMmList: state => state.investMmList,
  equtClosingList: state => state.equtClosingList,
  equtAnmonList: state => state.equtAnmonList,
  calculateList: state => state.calculateList,
  closeYn: state => state.closeYn,
  anyTimeEqutSaveDataReqDto: state => {
    const keys = [
      'investMm',
      'reqDegree',
      'custCode',
      'equipCode',
      'carNo',
      'repayfrDt',
      'repaytoDt',
      'monthlyDay',
      'monthlyQty',
      'monthlyAmt',
      'oilAmt',
      'oilDeductAmt',
      'amt',
      'vatAmt',
      'taxexe',
      'sumAmt',
    ];
    let param = {};
    for (let key in state.searchInfo) {
      if (keys.findIndex(item => item === key) !== -1) {
        param[key] = state.searchInfo[key];
      }
    }
    for (let key in state.detailInfo) {
      if (keys.findIndex(item => item === key) !== -1) {
        param[key] = state.detailInfo[key];
      }
    }
    param['anyTimeEqutSaveItemList'] = state.closeList;
    return param;
  },
};
const mutations = {
  setSearchInfo(state, payload) {
    for (let key in payload) {
      if (state.searchInfo.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  initDetailInfo(state) {
    let stringKeys = ['custCode', 'equipCode', 'carNo', 'repayfrDt', 'repaytoDt', 'billNo', 'billTaxNo'];
    for (let key in state.detailInfo) {
      state.detailInfo[key] = stringKeys.indexOf(key) === -1 ? 0 : '';
    }
  },
  setDetailInfo(state, payload) {
    let stringKeys = ['custCode', 'equipCode', 'carNo', 'repayfrDt', 'repaytoDt', 'billNo', 'billTaxNo'];
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = stringKeys.indexOf(key) === -1 ? Number(payload[key]) : payload[key];
      }
    }
  },
  setEqutClosingList(state, payload) {
    state.equtClosingList = payload;
  },
  setEqutAnmonList(state, payload) {
    state.equtAnmonList = payload;
  },
  initCalculateList(state) {
    state.calculateList = [];
  },
  setCalculateList(state, payload) {
    state.calculateList = payload;
  },
  setSumAmt(state) {
    state.detailInfo.sumAmt = $_getSumAmt(state.detailInfo.amt, state.detailInfo.vatAmt);
  },
  setCloseList(state, payload) {
    state.closeList = payload;
  },
  setCloseYn(state, closeYn) {
    state.closeYn = closeYn;
  },
};
const actions = {
  async selectEqutAnmonList({state, commit}) {
    commit('initDetailInfo');
    commit('initCalculateList');
    const response = await selectEqutAnmonList(state.searchInfo);
    const equtAnmonList = response.data;
    commit('setCloseYn', !!equtAnmonList.find(item => item.closeYn === $getConstants('Y').code));
    commit('setEqutAnmonList', equtAnmonList);
  },
  async selectRegisterEqutDetail({state, commit, dispatch}) {
    const param = {
      reqDegree: state.searchInfo.reqDegree,
      equipCode: state.detailInfo.equipCode,
      carNo: state.detailInfo.carNo,
      custCode: state.detailInfo.custCode,
      repayfrDt: state.detailInfo.repayfrDt,
      repaytoDt: state.detailInfo.repaytoDt,
    };
    const response = await selectRegisterEqutDetail(param);
    commit('setEqutClosingList', response.data);
    let investMmList = [];
    state.equtClosingList.forEach(e => {
      if (!investMmList || !investMmList.includes(e.runDt.substr(0, 6))) {
        investMmList.push(e.runDt.substr(0, 6));
      }
    });
    investMmList.forEach(e => {
      state.investMmList.push({value: e, text: e});
    });

    dispatch('changeCalculateList', state.investMmList[0].text);
  },
  async changeCalculateList({state, commit}, date) {
    let arrayDate = $_getArrayDate(date);

    let list = [];
    arrayDate.forEach((date, index) => {
      if (index % 2 === 0) {
        let map = {};
        map['runDt1'] = date;
        map['runQty1'] = 0;
        map['runPrice1'] = 0;
        map['runAmt1'] = 0;
        map['oilAmt1'] = 0;
        map['oilDeductAmt1'] = 0;
        list.push(map);
      } else {
        let lastList = list[list.length - 1];
        lastList['runDt2'] = date;
        lastList['runQty2'] = 0;
        lastList['runPrice2'] = 0;
        lastList['runAmt2'] = 0;
        lastList['oilAmt2'] = 0;
        lastList['oilDeductAmt2'] = 0;
      }

      const validData = state.equtClosingList.find(item => item.runDt === date);
      for (const [key, value] of Object.entries(validData ?? {})) {
        let no = Number(validData.runDt.substr(-2)) % 2 !== 0 ? '1' : '2';
        list[list.length - 1][`${key}${no}`] = value;
      }
    });
    commit('setCalculateList', list);
  },
  setCalculateAmt({state, commit}, payload) {
    let amt = payload.monthlyAmt + payload.oilAmt - payload.oilDeductAmt;
    let taxexe = 0;
    let vatAmt = $_getVatAmt(amt, taxexe);
    let sumAmt = $_getSumAmt(amt, vatAmt);
    commit('setDetailInfo', {...payload, amt, vatAmt, taxexe, sumAmt});
  },
  async deleteEqutAnmon({state, dispatch}) {
    const param = {
      investMm: state.searchInfo.investMm,
      reqDegree: state.searchInfo.reqDegree,
      custCode: state.detailInfo.custCode,
      equipCode: state.detailInfo.equipCode,
      carNo: state.detailInfo.carNo,
    };
    const response = await deleteEqutAnmon(param);
    if (response.data > 0) {
      dispatch('selectEqutAnmonList');
    }
  },
  setEtcDeductAmt({state, commit}, etcDeductAmt) {
    let amt = state.detailInfo.monthlyAmt + state.detailInfo.oilAmt - state.detailInfo.oilDeductAmt - etcDeductAmt;
    let vatAmt = $_getVatAmt(amt, state.detailInfo.taxexe);
    commit('setDetailInfo', {
      etcDeductAmt,
      amt,
      vatAmt,
    });
    commit('setSumAmt');
  },
  setVatAmt({commit}, vatAmt) {
    vatAmt = Number(vatAmt || 0);
    commit('setDetailInfo', {vatAmt});
    commit('setSumAmt');
  },
  setTaxexe({state, commit}, taxexe) {
    taxexe = Number(taxexe || 0);
    let vatAmt = $_getVatAmt(state.detailInfo.amt, taxexe);
    commit('setDetailInfo', {vatAmt, taxexe});
    commit('setSumAmt');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
