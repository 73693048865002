<template>
  <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" />
</template>

<script>
import options from './sheetOptions/WorkSheet.js';
import {selectWorkList} from '../api/workdaily.js';

export default {
  data() {
    return {
      Options: options(this),
      loadSearchData: [],
      responseData: {
        sheet: [],
      },
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo']);
  },
  created() {
    this.addEvent([
      {name: 'Work_onSearch', func: this.onSearch},
      {
        name: 'Work_onAmountChange',
        func: this.convertPriceUnit,
      },
    ]);
  },
  methods: {
    async onSearch(amountUnit) {
      const response = await selectWorkList({...this.searchInfo});
      this.responseData.sheet = response.data;
      this.convertPriceUnit(amountUnit);
    },
    convertPriceUnit(value) {
      this.loadSearchData = $_priceUnitFormat(value, this.responseData.sheet);
    },
  },
};
</script>
