<template>
  <pmis-tab-box>
    <template v-slot:title>사용자목록</template>
    <pmis-content-box>
      <template v-slot:header-left>
        <iui-select
          name="searchSe"
          :items="searchSeList"
          :value="searchInfo.searchSe"
          @change="value => setSearchInfo({searchSe: value})"
        />
        <iui-text
          type="search"
          @change="setSearchInfo({searchNm: $event.target.value})"
          @enter="selectLoginUserList"
          width="150px"
        />
        <iui-button @click="selectLoginUserList" value="검색" />
      </template>
      <ib-sheet
        :uid="_uid"
        :options="Options"
        :loadSearchData="loadSearchData"
        :events="{
          onFocus,
          onRenderFirstFinish,
        }"
        @loadSheet="loadSheet"
      />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
import options from './sheetOption/UserListSheet.js';
import {selectLoginUserList} from '../api/LoginLog.js';

export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      loadSearchData: [],
      Options: options(this),
      searchSeList: [
        {value: '1', text: '성명'},
        {value: '2', text: '아이디'},
      ],
    };
  },

  beforeCreate() {
    $mapGetters(this, ['searchInfo']);
    $mapMutations(this, ['setSearchInfo']);
  },

  created() {
    this.addEvent([{name: 'userList_selectLoginUserList', func: this.selectLoginUserList}]);
  },

  methods: {
    onFocus(e) {
      if (e.row.Kind == 'Data') {
        this.callEvent({name: 'log_selectLoginLogList', param: {pgmCd: this.pgmCd, empNo: e.row.empNo}});
      }
    },
    onRenderFirstFinish() {
      this.selectLoginUserList();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    async selectLoginUserList() {
      let param = this.searchInfo;
      const response = await selectLoginUserList(param);
      if (response.data.length == 0) {
        this.callEvent({name: 'log_onClearGrid', param: null});
      }
      this.loadSearchData = response.data;
    },
  },
};
</script>

<style scoped></style>
