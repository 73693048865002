<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-col>
          <iui-container-new type="css-flex">
            <i-row minHeight="500px">
              <i-col>
                <pmis-tab-box>
                  <template #title><b>실행내역</b></template>
                  <template #title-left>
                    <span class="mr15"></span>
                    <iui-select
                      name="degree"
                      :items="degreeListAsSelect"
                      defaultCd="S"
                      :value.sync="degree"
                      @change="onChangeDegree"
                      label="차수"
                      width="85px"
                      required
                    ></iui-select>
                    <iui-button value="내역확정" @click="dtlsDcns" v-if="confirm && maxDgree"></iui-button>
                    <iui-button value="차수삭제" @click="onDeleteChgDegree" v-if="confirm && maxDgree"></iui-button>
                    <iui-button
                      value="확정취소"
                      @click="dcnsCancl"
                      v-if="!confirm && maxDgree && currentDegree == maxDgree"
                    ></iui-button>
                    <iui-button
                      value="차수생성"
                      @click="onPopChgDegree"
                      v-if="$store.getters['prjCd'] && (!confirm || masterInfo.chgDegree == 0)"
                    />
                  </template>
                  <template #title-right>
                    <iui-button @click="onPopAddPrjWork" v-if="confirm && maxDgree">공종추가</iui-button>
                    <iui-button value="단가조정" @click="onPopUntpcMdac" v-if="confirm && maxDgree" />
                    <iui-button value="도급내역복사" @click="onPopContractCopy" v-if="confirm && maxDgree" />
                  </template>
                  <SimpleExecList ref="execDtls" />
                </pmis-tab-box>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
    <iui-modal name="prjWorkTypePop" title="현장공종추가" sizeType="size3" :btns="prjWorkTypeBtns">
      <PrjWorkTypePop />
    </iui-modal>
    <iui-modal name="chgDegree" title="차수생성" :btns="chgDegreeBtns" width="500px" height="200px">
      <ChgDegree :chgDegree="maxDgree ? Number(maxDgree) : undefined" ref="chgDegree" />
    </iui-modal>

    <iui-modal name="UntpcMdacPop" :title="untpcMdacTitle" :btns="untpcMdacBtns" sizeType="size1">
      <UntpcMdacPop />
    </iui-modal>
  </pmis-page>
</template>

<script>
import store from '@/view/executDtls/store/executDtls.js';
import PrjList from '@/view/Project/components/PrjList.vue';
import SimpleExecList from '@/view/executDtls/components/SimpleExecList.vue';
import PrjWorkTypePop from '@/view/estmtManage/resrceManage/popup/PrjWorkTypePop.vue';
import ChgDegree from '@/view/common/popup/ChgDegreePop.vue';
import UntpcMdacPop from '@/view/executDtls/components/popup/UntpcMdacPop.vue';
import SIMPLE_CODE from '@/constants/simpleExecConstant.js';
import {
  dtlsDcns,
  dcnsCancl,
  insertContContexecPlan,
  deleteContContexecPlan,
  updateContExecPlanByUcstMdac,
  saveContractCopy,
} from '../api/executDtls.js';
export default {
  components: {
    PrjList,
    SimpleExecList,
    PrjWorkTypePop,
    ChgDegree,
    UntpcMdacPop,
  },

  data() {
    return {
      code0265: '',
      prjWorkTypeBtns: [{name: '확인', callback: this.onConfirmPrjWorkType}],
      chgDegreeBtns: [{name: '확인', callback: this.onPopConfirmChgDegree}],
      untpcMdacBtns: [{name: '확인', callback: this.onPopConfirmUntpcMdacPop}],
      arithmeticBtns: [{name: '확인', callback: this.onPopConfirmArithmetic}],
      isContractCopy: false,
      untpcMdacTitle: '단가조정',
    };
  },

  computed: {
    confirm() {
      return this.masterInfo.prjFlag != this.code0265 && this.currentDegree == this.maxDgree;
    },
    degree: {
      set(value) {
        this.setCurrentDegree(value);
      },
      get() {
        return this.currentDegree;
      },
    },
  },

  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['detailInfo', 'currentDegree', 'masterInfo', 'maxDgree', 'degreeListAsSelect']);
    $mapMutations(this, ['setCurrentDegree']);
    $getCode($getConstants('CONFIRM_FLAG').code).then(response => (this.code0265 = response[0].code));
  },
  created() {
    this.canNew = this.isSimpleExec;
  },
  methods: {
    setCommonBtn() {
      this.canSave = this.confirm;
      this.canDelete = this.confirm;
    },
    onPopAddPrjWork() {
      this.$modal.show('prjWorkTypePop');
    },
    onConfirmPrjWorkType() {
      this.$modal.hide('prjWorkTypePop');
      this.$refs.execDtls.onSearch();
    },
    onChangeDegree() {
      this.$refs.execDtls.onSearch();
    },
    async dtlsDcns() {
      if (!this.currentDegree) {
        this.$alert({title: '내역확정', message: '최종차수를 선택해주세요.'});
        return;
      }
      if (!this.$refs.execDtls.validation()) {
        return;
      }
      if (
        !(await this.$confirm({title: '내역확정', message: `${this.currentDegree}차 실행내역을 확정하시겠습니까?`}))
      ) {
        return;
      }

      try {
        const param = {
          chgDegree: this.masterInfo.chgDegree,
          prjFlag: this.code0265,
        };
        const response = await dtlsDcns(param);
        this.$refs.execDtls.onSearch();
        if (response.data === SIMPLE_CODE.LINK_SUCCESS) {
          this.$alert({title: '내역확정', message: '도급내역과 실행내역이 자동으로 연계되었습니다.'});
        }
        if (response.data === SIMPLE_CODE.LINK_FAIL) {
          this.$alert({
            title: '내역확정',
            message:
              '도급내역과 실행내역의 상이하여 자동연계되지 않았습니다.<br/> 도급실행연계등록 메뉴를 이용하여 연계를 진행해주세요.',
          });
        }
      } catch (error) {
        this.$alert({title: '내역확정', message: error.message});
      }
    },
    async dcnsCancl() {
      if (!(await this.$confirm({title: '확정취소', message: '확정취소 하시겠습니까?'}))) {
        return;
      }
      const param = {
        chgDegree: this.masterInfo.chgDegree,
        prjFlag1: '',
      };
      const response = await dcnsCancl(param);
      if (response.data) {
        this.$refs.execDtls.onSearch();
      }
    },
    async onDeleteChgDegree() {
      if (this.maxDgree) {
        if (
          !(await this.$confirm({title: '차수삭제', message: `${this.currentDegree}차 실행내역을 삭제하시겠습니까?`}))
        ) {
          return;
        }

        try {
          const param = {chgDegree: this.masterInfo.chgDegree};
          const response = await deleteContContexecPlan(param);
          if (response.data) {
            this.$refs.execDtls.onInitSearch();
          }
        } catch (ex) {
          this.$alert({title: '차수삭제', message: ex.message});
        }
      }
    },
    onPopChgDegree() {
      this.$modal.show('chgDegree');
    },
    async onPopConfirmChgDegree() {
      const param = {
        ...this.$refs.chgDegree.getData(),
      };
      const response = await insertContContexecPlan(param);
      if (response.data) {
        this.$refs.execDtls.onInitSearch();
        this.$modal.hide('chgDegree');
      }
    },
    onPopUntpcMdac() {
      this.isContractCopy = false;
      this.untpcMdacTitle = '단가조정';
      this.openUntpcMdacPop();
    },
    onPopContractCopy() {
      this.isContractCopy = true;
      this.untpcMdacTitle = '도급내역복사';
      this.openUntpcMdacPop();
    },
    openUntpcMdacPop() {
      this.$modal.show('UntpcMdacPop');
    },
    onPopConfirmUntpcMdacPop() {
      this.callEvent({
        name: 'UntpcMdacPop_popConfirm',
        param: async data => {
          if (
            this.isContractCopy &&
            !(await this.$confirm({
              title: '도급내역복사',
              message: '도급내역을 복사하면 기존 내역이 모두 삭제됩니다. <br/> 복사하시겠습니까?',
            }))
          ) {
            return;
          }

          let param = {
            wcode: data.wcode,
            headCode: data.lgCode,
            midCode: data.mgCode,
            matPriceRt: data.matPriceRt,
            labPriceRt: data.labPriceRt,
            equipPriceRt: data.equipPriceRt,
            oprPriceRt: data.oprPriceRt,
            thousandDn: data.thousandDn,
            tenThousandDn: data.tenThousandDn,
            tenThousandUp: data.tenThousandUp,
            chgDegree: this.maxDgree,
          };

          const response = !this.isContractCopy
            ? await updateContExecPlanByUcstMdac(param)
            : await saveContractCopy(param);
          if (response.data) {
            this.$refs.execDtls.onSearch();
          } else if (this.isContractCopy) {
            this.$alert({title: '도급내역복사', message: '복사되지 않았습니다. 도급내역이 확정상태인지 확인해주세요.'});
          }
          this.$modal.hide('UntpcMdacPop');
        },
      });
    },
    onSave() {
      this.$refs.execDtls.save();
    },
    onPopDtls(modalNm) {
      this.callEvent({name: 'onPop', param: modalNm});
    },

    onPop(modalNm) {
      this.modalNm = modalNm;
      const title = '타현장견적복사';
      this.modalNm = modalNm;
      this.$modal.show(this.modalNm, {
        title: title,
      });
    },
  },
};
</script>

<style scoped></style>
