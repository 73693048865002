<template>
  <pmis-page :search-company-visible="false" :search-project-visible="false" @search-click="onSearch">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="550px">
          <pmis-tab-box>
            <template v-slot:title>목록</template>
            <CompanyList :isApproval="true" />
          </pmis-tab-box>
        </i-col>
        <i-col>
          <pmis-tab-box :loading="loading">
            <template v-slot:title>상세정보</template>
            <template v-slot:title-right>
              <iui-button value="승인" @click="onApproval" />
            </template>
            <JoinInfo />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import CompanyList from '@/view/member/components/CompanyList.vue';
import JoinInfo from '@/view/member/regist/JoinInfo.vue';
import store from '@/view/member/store/member.js';
import {updateCompanyUseFlag} from '../api/member';
export default {
  components: {
    CompanyList,
    JoinInfo,
  },

  data() {
    return {
      loading: false,
    };
  },

  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['companyInfo', 'worker']);
    $mapMutations(this, ['initCompanyInfo']);
  },

  created() {
    this.addEvent([{name: 'companyRegistOnDetailLoading', func: this.onDetailLoading}]);
  },

  methods: {
    onSearch() {
      this.callEvent({name: 'companySelectCompanyNotConfirmList'});
    },

    async onApproval() {
      let param = {companyList: []};
      this.callEvent({
        name: 'companyListGetRowsByChecked',
        param: data => {
          if (data.length) {
            data.forEach(item => {
              param.companyList.push({
                pgmCd: item.pgmCd,
                useStrdate: $_getCurrentDate(),
                frmGb: item.frmGb,
                bsnNo: item.bsnNo,
                simpleExec: $_getBoolToValue(item.simpleExec, $getConstants('Y').code, ''),
              });
            });
          }
        },
      });

      if (!param.companyList.length) {
        this.$alert({title: '승인대상 없음', message: '선택된 회사가 없습니다.'});
        return;
      }

      if (!(await this.$confirm({title: '회원가입 승인', message: '회원가입을 승인하시겠습니까?'}))) return;

      updateCompanyUseFlag(param).then(response => {
        if (response.status == 200) {
          this.initCompanyInfo();
          this.callEvent({name: 'companySelectCompanyNotConfirmList'});
        }
      });
    },

    onDetailLoading(isLoading) {
      this.loading = isLoading;
    },
  },
};
</script>

<style scoped></style>
