export const selectDataClassificationList = () =>
  axios.post('/dataClassificationRegist/selectDataClassificationList', {});

export const checkDuplicate = param => axios.post('/dataClassificationRegist/checkDuplicate', param);

export const saveDataClassificationDetailInfo = param =>
  $save('/dataClassificationRegist/saveDataClassificationDetailInfo', param);

export const deleteDataClassificationDetailInfo = param =>
  axios.post('/dataClassificationRegist/deleteDataClassificationDetailInfo', param);
