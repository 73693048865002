export default that => {
  return {
    Cfg: {
      Hover: 1,
      CanEdit: false,
      IgnoreFocused: true,
    },
    Def: {
      Row: {
        CanFocus: false,
      },
    },
    Cols: [
      {
        Header: '번호',
        Name: 'natNo',
        Type: 'Text',
        Width: 80,
        Align: 'center',
        Cursor: 'pointer',
      },
      {
        Header: '구분',
        Name: 'natDs',
        Width: 140,
        Align: 'center',
        Type: 'Text',
        Format: $getConstants('NAT_DS').code,
        Cursor: 'pointer',
      },
      {
        Header: '제목',
        Name: 'tl',
        Type: 'Text',
        RelWidth: 1,
        TextStyle: 4, // 밑줄
        Align: 'Left',
        Cursor: 'pointer',
      },
      {
        Header: '등록자',
        Name: 'rgprNm',
        Type: 'Text',
        Width: 140,
        Align: 'Center',
        Cursor: 'pointer',
      },
      {
        Header: '등록일',
        Name: 'rgsDttm',
        Type: 'Text',
        Width: 140,
        Align: 'Center',
        Cursor: 'pointer',
      },
      {
        Header: '조회',
        Name: 'inqCnt',
        Type: 'Text',
        Width: 140,
        Align: 'Center',
        Cursor: 'pointer',
      },
      {Name: 'pgmCd', Visible: false},
      {Name: 'rgprId', Visible: false},
    ],
  };
};
