<template>
  <pmis-page :search-project-visible="false" @search-click="onSearch" @save-click="onSave">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <PortletList />
        </i-col>
        <i-spacer />
        <i-col>
          <PagePortletRegist />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from '../store/portlet.js';
import PortletList from '@/view/portletManage/components/portletList.vue';
import PagePortletRegist from '@/view/portletManage/components/pagePortletRegist.vue';
import {savePagePortlet} from '../api/portletManage.js';
export default {
  components: {
    PortletList,
    PagePortletRegist,
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['pagePortletParam']);
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'pagePortletRegist_selectPageInfo'});
    },
    async onSave() {
      const response = await savePagePortlet({tbPagePortletBean: this.pagePortletParam});
      if (response.status == 200) {
        this.onSearch();
      }
    },
  },
};
</script>

<style></style>
