<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <pmis-tab-box>
            <template #title>분류목록</template>
            <DataCl :isBasePage="false" />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <DetailInfo :isBasePage="false" />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from './store/DataClassificationRegist.js';
import DataCl from './components/DataClassificationList.vue';
import DetailInfo from './components/DetailInfo.vue';
export default {
  components: {DataCl, DetailInfo},
  beforeCreate() {
    $init(this, store);
  },
};
</script>

<style></style>
