import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import BiddingApprovalMixin from '@/view/bidding/js/biddingApprovalMixin.js';

import RfqCusList from '@/view/bidding/rfq/common/components/NoticeRfqCusList.vue';
import UserListPopup from '@/view/Resource/components/WorkerListPopup.vue';
import BidPlanAmt from '@/view/bidding/rfq/common/components/BidPlanAmt.vue';

export default {
  name: 'RfqInfoMixin',
  components: {RfqCusList, UserListPopup, BidPlanAmt},
  mixins: [BiddingApprovalMixin],
  data() {
    return {
      ttlKey: 0,
      prFiles: [],
      fileClear: false,
      rfqTargetPrListModalBtns: [
        {name: '새 입찰서 작성', callback: this.setAddData},
        {name: '확인', callback: this.onConfirm_RfqTargetPrList},
      ],
      chrgUserListModalBtns: [{name: '확인', callback: this.onConfirm_userModal('chrg')}],
      spotUserListModalBtns: [{name: '확인', callback: this.onConfirm_userModal('spotDscr')}],
      bidPlanAmtModalBtns: [{name: '저장', callback: this.saveBidPlanAmt}],

      clickable_inputStyle: {
        cursor: 'pointer',
        color: '#FF0000',
        'text-align': 'center',
        'text-decoration': 'underline',
        'font-weight': 'bold',
        'background-color': '#F0F0F0',
      },
    };
  },
  computed: {
    canSaveBidPlanAmt() {
      const a1 = BIDDING_CONSTANTS.PROGRESS_STATE.RFQ_APPROVAL_COMPLETE <= this.rfqInfo.rfqSt;
      const a2 = this.rfqInfo.rfqSt < BIDDING_CONSTANTS.PROGRESS_STATE.RFQ_BIDDING_EXPIRED;

      return a1 && a2; // 공고품의완료 ~ 입찰마감전
    },
    canRfqNoticeCancel() {
      const b1 = this.rfqStatus.isRfqSpotDscr && !this.rfqStatus.isRfqSpotDscrClose;
      const b2 = this.rfqStatus.isRfqBidding && !this.rfqStatus.isExistSpotDscr;
      const b3 = !this.rfqStatus.isRfqBiddingExpired;
      const b4 = !this.rfqStatus.isReBid;

      // ((현설통보 && 현설마감전) || (입찰중 && 현설없음)) && 입찰마감전 && 당초입찰
      return (b1 || b2) && b3 && b4;
    },
  },
  beforeCreate() {
    $mapGetters(this, ['rfqInfo', 'rfqStatus', 'rfqIsNew', 'rfqCusList', 'prInfo', 'bidType']);
    $mapMutations(this, ['setRfqInfo', 'setPrInfo', 'setRfqItemList', 'setRfqIsNew']);
    $mapActions(this, ['initRfq']);
  },
  async created() {
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
    this.addEvent([
      {name: 'RfqInfo_onBeforeSetRowData', func: this.onInit},
      {name: 'RfqInfo_onSetRowData', func: this.onSetRowData},
      {name: 'RfqInfo_openPrjSearchbox', func: this.openPrjSearchbox},
    ]);
  },
  methods: {
    isBeforeToday(date) {
      return $_getCurrentDate() > $_dateToStringYYMMDD(date);
    },
    disabledBidExpireDate(date) {
      const cond = this.rfqInfo.spotDscrYmd > $_dateToStringYYMMDD(date);
      return this.rfqStatus.isExistSpotDscr && this.rfqInfo.spotDscrYmd ? cond : this.isBeforeToday(date);
    },
    onInit() {
      this.prFiles = [];
      this.fileClear = true;
    },
    onSetRowData() {
      this.canSave = this.rfqStatus.isRfqReady;
      this.canDelete = this.rfqStatus.isRfqReady;
    },
    async onAdd() {
      if ((await this.apis['selectApprovedPrList']({orgCd: this.pgmCd})).data.length) {
        // 요청품의완료 상태인 요청정보가 있을 때
        this.$modal.show('rfqTargetPrListModal');
      } else {
        this.setAddData();
      }
    },
    setAddData(callbackData) {
      // 신규 데이터 세팅
      this.onInit();
      this.initRfq();
      this.setRfqIsNew(true);
      this.canSave = true;
      this.canDelete = false;

      this.setRfqInfo({
        orgCd: this.pgmCd,
        spotDscrYn: BIDDING_CONSTANTS.CODES.SPOT_DSCR_Y,
        spotDscrUsrcd: this.userInfo.empNo,
        spotDscrUsrnm: this.userInfo.name,
        spotDscrUsrTlno: this.userInfo.tlNo,
        poTypecd: BIDDING_CONSTANTS.CODES.POTYPE_NORMAL,
        taxTypecd: BIDDING_CONSTANTS.CODES.TAX,
        taxDirectDs: BIDDING_CONSTANTS.CODES.TAX_DIRECT_PERCENT,
        chrgUsrcd: this.userInfo.empNo,
        chrgUsrnm: this.userInfo.name,
        rgsDate: dateFormat($_getCurrentDate()),
      });

      if (typeof callbackData === 'object') {
        const {prInfo, prItem, rfqInfo, prFiles} = {...callbackData};

        if (prInfo) {
          this.setPrInfo(prInfo);

          prInfo['rfqRmrk'] = prInfo['prRmrk'];
          prInfo['flNo'] = 0;

          this.setRfqInfo(prInfo);
          this.setRfqItemList(prItem);

          let count = 0;
          const interval = setInterval(() => {
            if (!this.fileClear || ++count > 10) {
              clearInterval(interval);
              this.prFiles = prFiles || [];
            }
          }, 50);

          if (this.bidType === 'sub') {
            const labAmt = prItem.reduce((acc, curr) => acc + curr['qty'] * curr['planLabUprc'], 0);
            this.setRfqInfo({labAmt});
          }
        }
        if (rfqInfo) {
          this.setRfqInfo(rfqInfo);
        }
      }

      this.$modal.hide('rfqTargetPrListModal');
    },
    onConfirm_RfqTargetPrList() {
      this.callEvent({
        name: 'RfqTargetPrList_Confirm',
        param: data => this.setAddData(data),
      });
    },
    onConfirm_userModal(type) {
      // 현설담당자, 입찰담당자 선택
      this.callEvent({
        name: 'WorkerListPopup_callbackData',
        param: data => {
          this.setRfqInfo({
            [`${type}Usrcd`]: data.empNo,
            [`${type}Usrnm`]: data.name,
          });
          this.$modal.hide(`${type}UserListModal`);
        },
      });
    },
    openPrjSearchbox() {
      this.$refs.prjSearchbox.$refs.searchButton.button_click();
    },
    onCallbackPrjCd(e) {
      // 현장선택 후
      if (this.rfqInfo.spotDeptcd !== e.prjCd) {
        this.setRfqItemList([]);
        this.setRfqInfo({spotDeptcd: e.prjCd, spotDeptnm: e.prjNm, ttl: `[${e.prjCd}] `});
      }
    },
    onInput_ttl(e) {
      let value = e.target.value;
      const ttlPrefix = `[${this.rfqInfo.spotDeptcd}] `;

      const updateTtlKey = () => (10 < this.ttlKey ? (this.ttlKey = 0) : this.ttlKey++);

      if (value.length < ttlPrefix.length) {
        value = ttlPrefix;
        updateTtlKey();
      } else if (!value.startsWith(ttlPrefix)) {
        value = this.rfqInfo.ttl + e.data;
        updateTtlKey();
      }

      setTimeout(() => {
        this.$refs.ttl.$refs.input.focus();
      }, 10);

      this.setRfqInfo({ttl: value});
    },
    onChange_spotDscrYn(e) {
      const value = $_externalCheckboxValue(e.target.checked);
      this.setRfqInfo({
        spotDscrYn: value,
        spotDscrYmd: '',
        spotDscrHh: '00',
        spotDscrMm: '00',
        spotDscrUsrcd: value === BIDDING_CONSTANTS.CODES.SPOT_DSCR_Y ? this.userInfo.empNo : '',
        spotDscrUsrnm: value === BIDDING_CONSTANTS.CODES.SPOT_DSCR_Y ? this.userInfo.name : '',
        spotDscrLoc: '',
      });
    },
    onChange_spotDscrYmd(date) {
      const payload = {spotDscrYmd: date};
      if (date > this.rfqInfo.bidExpireYmd) {
        payload['bidExpireYmd'] = '';
      }
      this.setRfqInfo(payload);
    },
    onChange_bidExpireYmd(e) {
      this.setRfqInfo({bidExpireYmd: e});
    },
    onChangeTime(key, value) {
      const hour = value.split(':')[0];
      const minute = value.split(':')[1];
      this.setRfqInfo({[key + 'Hh']: hour, [key + 'Mm']: minute});
    },
    onChange_payRatio(type, value) {
      const types = ['Cash', 'Check'];
      value = Number(value);

      if (0 > value) {
        value = 0;
      } else if (100 < value) {
        value = 100;
      }

      this.setRfqInfo({
        [`pay${type}Ratio`]: value,
        [`pay${types.find(item => item !== type)}Ratio`]: 100 - value,
      });
    },
    onChange_taxType(type, value) {
      const BIDDING_CODES = BIDDING_CONSTANTS.CODES;
      const payloads = {
        taxTypecd: {
          taxDirectDs: value === BIDDING_CODES.TAX_DIRECT ? BIDDING_CODES.TAX_DIRECT_PERCENT : '',
          taxTypeDirectPercent: '',
          taxTypeDirectAmt: '',
        },
        taxDirectDs: {
          taxTypeDirectPercent: value === BIDDING_CODES.TAX_DIRECT_PERCENT ? 0 : '',
          taxTypeDirectAmt: value === BIDDING_CODES.TAX_DIRECT_AMOUNT ? 0 : '',
        },
      };

      this.setRfqInfo({[type]: value, ...payloads[type]});
      this.callEvent({name: 'RfqItemList_CalculateAmt'});
    },
    async openBidPlanAmtModal() {
      // 입찰예정가 팝업
      if (await COMMON_FUNCTION.checkCertdnRegistered(this, this.bidType)) {
        this.$modal.show('bidPlanAmtModal');
      }
    },
    saveBidPlanAmt() {
      // 입찰예정가 저장
      this.callEvent({
        name: 'BidPlanAmt_Save',
        param: () => {
          this.$modal.hide('bidPlanAmtModal');

          this.$alert({title: '입찰예정가 저장', message: '입찰예정가가 저장되었습니다.'});
        },
      });
    },
    onValidateSaveCommon() {
      const spotYmd = this.rfqInfo.spotDscrYmd.replace(/-/g, '');
      const spotYear = spotYmd.substring(0, 4);
      const spotMonth = parseInt(spotYmd.substring(4, 6)) - 1;
      const spotDay = spotYmd.substring(6, 8);
      const spotHour = this.rfqInfo.spotDscrHh;
      const spotMinute = this.rfqInfo.spotDscrMm;
      const spotDate = new Date(spotYear, spotMonth, spotDay, spotHour, spotMinute);

      const bidYmd = this.rfqInfo.bidExpireYmd.replace(/-/g, '');
      const bidYear = bidYmd.substring(0, 4);
      const bidMonth = parseInt(bidYmd.substring(4, 6)) - 1;
      const bidDay = bidYmd.substring(6, 8);
      const bidHour = this.rfqInfo.bidExpireHh;
      const bidMinute = this.rfqInfo.bidExpireMm;
      const bidDate = new Date(bidYear, bidMonth, bidDay, bidHour, bidMinute);
      if (this.rfqInfo.ttl === `[${this.rfqInfo.spotDeptcd}] `) {
        this.$alert({title: '입찰제목', message: '입찰제목을 입력해주세요'});
        return false;
      }

      // 현설체크
      if (this.rfqStatus.isExistSpotDscr) {
        if (0 > Number(spotHour) || 23 < Number(spotHour) || 0 > Number(spotMinute) || 59 < Number(spotMinute)) {
          this.$alert({title: '현설일자', message: '현설시간이 잘못되었습니다.<br/>다시 한 번 확인하시기 바랍니다.'});
          return false;
        }
        if (spotDate.getTime() <= new Date().getTime()) {
          this.$alert({title: '현설일자', message: '현설일자는 현재시간 이후로만 가능합니다.'});
          return false;
        }
      }

      // 입찰마감체크
      if (0 > Number(bidHour) || 23 < Number(bidHour) || 0 > Number(bidMinute) || 59 < Number(bidMinute)) {
        this.$alert({
          title: '입찰마감일시',
          message: '입찰마감시간이 잘못되었습니다.<br/>다시 한 번 확인하시기 바랍니다.',
        });
        return false;
      }
      if (bidDate.getTime() <= new Date().getTime()) {
        this.$alert({title: '입찰마감일시', message: '입찰마감일시는 현재시간 이후로만 가능합니다.'});
        return false;
      }

      // 현설, 입찰마감체크
      if (this.rfqStatus.isExistSpotDscr) {
        if (spotDate.getTime() >= bidDate.getTime()) {
          this.$alert({title: '입찰마감일시', message: '입찰마감일시는 현설일자 이후로만 입력이 가능합니다.'});
          return false;
        }
      }

      return true;
    },
    async onSave() {
      let rfqItemList, rfqCusList;
      this.callEvent({name: 'RfqItemList_GetSaveJson', param: data => (rfqItemList = data)});
      this.callEvent({name: 'RfqCusList_GetSaveJson', param: data => (rfqCusList = data)});

      const saveApi = this.rfqIsNew || this.rfqStatus.isExistOnlyPr ? this.apis['createRfq'] : this.apis['saveRfq'];
      const param = {...this.rfqInfo, rfqItemList, rfqCusList};

      if (this.rfqIsNew && this.prFiles.length) {
        const prFiles = {
          pgmCd: this.pgmCd,
          flNo: this.prInfo.flNo,
          grpNoList: this.prFiles.map(item => item.grpNo),
        };

        param['prFiles'] = prFiles;
      }
      saveApi(param, this.onValidateSave)
        .then(response => {
          if (response.status == 200) {
            this.callEvent({name: 'RfqList_Search', param: response.data.rfqNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert({title: '입찰정보 저장', message: ex});
          }
        });
    },
    async onDelete() {
      const title = '입찰정보 삭제';
      const message = `입찰정보를 삭제하시겠습니까?<br/> - 입찰번호 : ${this.rfqInfo.rfqNo2}`;

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      this.apis['deleteRfq'](this.rfqInfo.rfqNo)
        .then(response => {
          if (response.status == 200) {
            this.callEvent({name: 'RfqList_Search'});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    /** 입찰공고 */
    async onNoticeRfq() {
      const rfqNo = this.rfqInfo.rfqNo;
      const lastBidRnd = this.rfqInfo.lastBidRnd;

      const response = await this.apis['selectRfqDegreeInfo'](rfqNo, lastBidRnd);
      if (!response.data.encBidPlanAmt) {
        const title = '입찰공고';
        const message = '입찰예정가가 입력되지 않았습니다.<br/>입찰예정가를 먼저 입력하시기 바랍니다.';

        await this.$alert({title, message});
        this.openBidPlanAmtModal();
        return;
      }

      const title = '입찰공고';
      let message = '입찰공고를 하시겠습니까?';

      if (this.rfqStatus.isExistSpotDscr) {
        message += "<br/><b style='color: red;'>'현설통보'</b> 상태로 변경됩니다.";
      } else {
        message += "<br/><b style='color: red;'>'입찰중'</b> 상태로 변경됩니다.";
      }

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      this.apis['noticeRfq'](rfqNo, lastBidRnd)
        .then(response => {
          if (response.data.rfqNo) {
            this.callEvent({name: 'RfqList_Search', param: response.data.rfqNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            const msg = ex.description ? `${ex.message}<br/>- ${ex.description}` : ex.message;
            this.$alert({title: ex.title, message: msg});
          }
        });
    },
    /** 입찰공고취소 */
    async onCancelNoticeRfq() {
      const response = await this.apis['selectRfqInfo'](this.rfqInfo.rfqNo);
      if (0 < response.data.quotCusCount) {
        this.$alert({
          title: '입찰공고취소',
          message: '견적서를 제출한 업체가 있습니다.<br/>입찰공고를 취소할 수 없습니다.',
        });
        return;
      }

      const title = '입찰공고취소';
      const message = `입찰공고를 취소하시겠습니까?
                <br/> - 입찰계획서번호 : ${COMMON_FUNCTION.getMakeFormat(this.rfqInfo.rfqNo)}`;

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      this.apis['cancelNoticeRfq'](this.rfqInfo.rfqNo)
        .then(response => {
          if (response.status == 200) {
            this.callEvent({name: 'RfqList_Search', param: response.data.rfqNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    onDeleteCompleteFile(flNo) {
      if (flNo) return;

      this.apis['updateRfqFlNo'](this.rfqInfo.rfqNo, flNo)
        .then(response => {
          if (response.status == 200) {
            this.setRfqInfo({flNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    openPopup(modalNm) {
      this.$modal.show(modalNm);
    },
    onApprovalEmit() {
      this.callEvent({name: 'RfqList_Search', param: this.rfqInfo.rfqNo});
    },
    getTime(hour, minute) {
      hour = hour ? hour : '00';
      minute = minute ? minute : '00';
      return `${hour}:${minute}`;
    },
  },
};
