<template>
  <iui-modal name="ExecutionListModal" title="실행내역" width="1300px" :btns="modalBtns">
    <Execution
      :costType="costType"
      isPopup
      isCheck
      :modalBtns="modalBtns"
      :param="{prjCd: prjCd_me}"
      :visiblePrice="visiblePrice"
    />
  </iui-modal>
</template>

<script>
/**
 * 실행내역에 대해 잔여수량 체크하기 위한 팝업 (SUB_PR, SUB_RFQ, SUB_PO에서 쓰이고 있음)
 */
import Execution from '@/view/executDtls/components/Execution.vue';
export default {
  components: {Execution},
  props: {
    costType: {
      type: String,
      default: undefined,
    },
    visiblePrice: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modalBtns: [{name: '확인', callback: this.onConfirm}],

      prjCd_me: '',
      validSheet: [],
    };
  },
  created() {
    this.addEvent({name: 'ExecutionList_ShowModal', func: this.onShowModal});
  },
  methods: {
    onShowModal(param) {
      if (param !== undefined) {
        this.prjCd_me = param.prjCd;
        this.validSheet = param.validSheet;

        this.$store.commit('setModalParam', {pgmCd: this.pgmCd, prjCd: this.prjCd_me});
      }
      this.$modal.show('ExecutionListModal');
    },
    onConfirm() {
      this.callEvent({
        name: 'getExecRowsByChecked',
        param: rData => {
          if (0 == rData.length) {
            this.$alert({title: '실행내역', message: '실행내역이 선택되지 않았습니다.'});
            return;
          }

          if (0 < this.validSheet.length) {
            let isDuplicate = false;
            Array.from(rData).every(item => {
              const dupicateRow = this.validSheet.find(
                row => row.midCode == item.midCode && row.itemSeq == item.itemSeq
              );
              if (dupicateRow !== undefined) {
                isDuplicate = true;
                return false;
              }
            });
            if (isDuplicate) {
              this.$alert({title: '실행내역', message: '중복되는 실행내역이 존재합니다.'});
              return;
            }
          }

          const executionList = rData.map(item => {
            const mgName = item.mgName;
            const itemName = item.itemName;
            const ssize = item.ssize;
            const unitName = item.unitName;
            const unit = item.unit;

            const planMatUprc = item.matPrice || 0;
            const planLabUprc = item.labPrice || 0;
            const planEquipUprc = item.equipPrice || 0;
            const planCostUprc = item.oprPrice || 0;
            const unitAmt = planMatUprc + planLabUprc + planEquipUprc + planCostUprc;

            const wcode = item.wcode;
            const headCode = item.headCode;
            const midCode = item.midCode;
            const itemSeq = item.itemSeq;
            const itemCode = item.itemCode;
            const costType = item.costType;

            return {
              mgName: mgName,
              itemName: itemName,
              ssize: ssize,
              unitName: unitName,
              unit: unit,
              planMatUprc: planMatUprc,
              planLabUprc: planLabUprc,
              planEquipUprc: planEquipUprc,
              planCostUprc: planCostUprc,
              unitAmt: unitAmt,
              wcode: wcode,
              headCode: headCode,
              midCode: midCode,
              itemSeq: itemSeq,
              itemCode: itemCode,
              costType: costType,
            };
          });

          this.onSearchExecItemInfos(executionList);
        },
      });
    },
    async onSearchExecItemInfos(executionList) {
      const executionItemList = executionList.map(row => {
        return {
          pgmCd: this.pgmCd,
          prjCd: this.prjCd || this.prjCd_me,
          wcode: row.wcode,
          headCode: row.headCode,
          midCode: row.midCode,
          itemSeq: row.itemSeq,
          itemCode: row.itemCode,
          costType: row.costType,
        };
      });

      const response = await axios.post('/subPr/selectExecItemInfos', {executionItemList});
      const executionItemInfos = response.data;

      executionList.forEach(row => {
        const midCode = row.midCode;
        const itemSeq = row.itemSeq;
        const findRow = executionItemInfos.find(execRow => execRow.midCode == midCode && execRow.itemSeq == itemSeq);
        if (findRow !== undefined) {
          row.totQty = findRow.totQty; // 실행수량
          row.preQty = findRow.preQty; // 기시행수량
          row.remQty = findRow.remQty; // 잔여수량
        }
      });

      this.$emit('executionList', executionList);
      this.$modal.hide('ExecutionListModal');
    },
  },
};
</script>

<style></style>
