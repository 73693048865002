<template>
  <pmis-content-box>
    <template v-slot:title>정산금액</template>
    <ib-sheet :uid="_uid" :options="options" :loadSearchData="calculateList" />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/SubTargetListSheet.js';

export default {
  data() {
    return {
      options: options(this),
    };
  },
  beforeCreate() {
    $mapGetters(this, ['calculateList']);
  },
};
</script>

<style scoped></style>
