<template>
  <div>
    <div class="join_step04 mb30"><span class="blind">step04 가입완료</span></div>
    <div class="join_end"><span class="blind">회원가입을 축하드립니다.</span></div>
    <div class="btn_area"><button type="button" class="btn_confirm" @click="goLogin">로그인 화면으로</button></div>
  </div>
</template>

<script>
export default {
  methods: {
    goLogin() {
      this.$router.push({path: '/loginHome', params: {}});
    },
  },
};
</script>

<style scoped></style>
