const state = () => ({
  detailInfo: {},
  prjFlag: '',
  created: false,
});

const getters = {
  detailInfo: state => state.detailInfo,
  prjFlag: state => state.prjFlag,
  isCreated: state => state.created,
};

const actions = {};

const mutations = {
  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = String(payload[key] || '');
      }
    }
  },

  initDetailInfo(state) {
    for (let key in state.detailInfo) {
      state.detailInfo[key] = '';
    }
  },
  setPrjFlag(state, prjFlag) {
    state.prjFlag = prjFlag;
  },
  setCreated(state, payload) {
    state.created = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
