export default that => {
    const preset = that.$ibsheetHelper.preset;
    return {
      Cfg: {
        SearchMode: 2,
        CanEdit: 1,
        CanSort: 0,
        HeaderMerge: 4,
      },
      Def: {
        Row: {
          NoColor: 1,
        },
      },
      Cols: [
        {
          Header: [{Value: '선택', TextColor: '#000000', Align: 'center', HeaderCheck: 1}, '선택'],
          Name: 'cbx',
          Type: 'Bool',
          Width: 60,
          CanEdit: false,
        },
        {
          Header: ['소속명', '소속명'],
          Name: 'blnDepPartNm',
          Align: 'center',
          RelWidth: 1,
          MinWidth: 100,
          CanEdit: false,
        },
        {
          Header: ['근로자구분', '근로자구분'],
          Name: 'laborDsNm',
          Align: 'center',
          Width: 100,
          CanEdit: false,
        },
        {
          Header: ['성명', '성명'],
          Name: 'name',
          Align: 'center',
          Width: 120,
          CanEdit: false,
        },
        {
          Header: ['주민등록번호', '주민등록번호'],
          Name: 'regiNo',
          Align: 'center',
          Width: 130,
          CustomFormat: 'IdNoMask',
          CanEdit: false,
        },
        {
          Header: ['직종', '직종'],
          Name: 'jkdNm',
          Align: 'center',
          RelWidth: 1,
          MinWidth: 120,
          CanEdit: false,
        },
        {
          Header: ['노무단가', '노무단가'],
          Name: 'priceLAmt',
          Width: 120,
          Extend: preset.unitAmount,
          CanEdit: false,
        },
        {
          Header: ['진행상태', '작성일시'],
          Name: 'makeDate',
          Align: 'center',
          Width: 120,
          CanEdit: false,
        },
        {
          Header: ['진행상태', '송부일시'],
          Name: 'sendDate',
          Align: 'center',
          Width: 120,
          CanEdit: false,
        },
        {
          Header: ['진행상태', '근로자서명일시'],
          Name: 'contSignDate',
          Align: 'center',
          Width: 120,
          CanEdit: false,
        },
        {
          Header: ['진행상태', '회사서명일시'],
          Name: 'compSignDate',
          Align: 'center',
          Width: 120,
          CanEdit: false,
        },
        ]
    }
}