export default that => {
  return {
    Cfg: {
      CanEdit: 1,
      HeaderCheck: 1,
      HeaderMerge: 2,
    },
    Cols: [
      {
        Header: '투입일자',
        Name: 'ioDate',
        Type: 'Text',
        Align: 'center',
        CanEdit: 0,
        CustomFormat: '####-##-##',
      },
      {
        Header: '작업분류',
        Name: 'wbsPath',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 180,
        CanEdit: false,
      },
      {
        Header: '공종명',
        Name: 'mgName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 180,
        CanEdit: 0,
      },
      {
        Header: '내역코드',
        Name: 'itemSeq',
        Type: 'Text',
        Align: 'center',
        Width: 90,
        CanEdit: 0,
      },
      {
        Header: '자재코드',
        Name: 'matCode',
        Type: 'Text',
        Align: 'center',
        Width: 140,
        CanEdit: 0,
        ButtonFormula: e => (that.isSimpleExec ? 'Html' : ''),
        ButtonText: '<input type="button" class="i_search" />',
        WidthPad: 50,
        OnClickSide: that.onShowInventory,
      },
      {
        Header: '자재명',
        Name: 'matName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 180,
        CanEdit: 0,
      },
      {
        Header: '규격',
        Name: 'matSize',
        Type: 'Text',
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: '단위',
        Name: 'matUnit',
        Type: 'Text',
        Align: 'center',
        Width: 80,
        CanEdit: 0,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: '순번',
        Name: 'rowNo',
        Type: 'Int',
        Align: 'center',
        Width: 50,
        CanEdit: 0,
      },
      {
        Header: '수량',
        Extend: that.$ibsheetHelper.preset.quantity,
        Name: 'qty',
        Width: 120,
        CanEditFormula: () => that.monPjtCloseAt == 'N', //마감시 수정불가
      },
      {
        Header: '외주',
        Name: 'custName',
        Type: 'Text',
        Align: 'center',
        Width: 130,
        CanEdit: 0,
        ButtonFormula: e => ((e.Row['cud'] == '0' || e.Row['cud'] == '1') && that.monPjtCloseAt == 'N' ? 'Html' : ''),
        ButtonText: '<input type="button" class="i_search" />',
        WidthPad: 50,
        OnClickSide: that.onShowPoCompleteList,
      },
      {
        Header: '추가/삭제',
        Name: 'add',
        TypeFormula: e => (that.monPjtCloseAt == 'N' ? 'Button' : 'Text'),
        Align: 'center',
        Width: 40,
        CanEdit: false,
        ButtonText: '<input type="button" class="i_plus" />',
        OnClick: that.matAdd,
      },
      {
        Header: '추가/삭제',
        Name: 'remove',
        TypeFormula: e => (that.monPjtCloseAt == 'N' ? 'Button' : 'Text'),
        Align: 'center',
        Width: 40,
        CanEdit: false,
        ButtonText: '<input type="button" class="i_minus" />',
        OnClick: that.matRemove,
      },
      {Name: 'ioGb', Visible: false},
      {Name: 'seq', Visible: false},
      {Name: 'wbsCd', Visible: false},
      {Name: 'wcode', Visible: false},
      {Name: 'headCode', Visible: false},
      {Name: 'midCode', Visible: false},
      {Name: 'itemCode', Visible: false},
      {Name: 'remQty', Visible: false, NoChanged: 1}, // 재고수량은 계산으로 값만 변경되게 처리
      {Name: 'remQtyBefore', Visible: false}, // 재고수량 원본
      {Name: 'monPjtCloseAt', Visible: false},
      {Name: 'poNoSubc', Visible: false},
      {Name: 'custCode', Visible: false},
      {Name: 'wbsCd', Visible: false},
      {Name: 'wbsId', Visible: false},
    ],
  };
};
