<template>
  <header id="header">
    <div class="util">
      <span class="company_name"
        >{{ userInfo.frmNm }} <font color="#006ebe">{{ $store.getters['mdi/currentUrls'].mnuPth }}</font></span
      >
      <!-- <a href="#" @click="btnNavigationToggle_click()">예전 상단 메뉴 토글</a> -->
      <a
        href="#"
        v-if="userInfo.frmGb == $getConstants('FRM_GB_4').code"
        @click="changePortal"
        style="color:red; background-color:yellow; padding:7px 10px 8px 10px"
        >{{ goPortalNm }}</a
      >
      <a href="#" @click.prevent.self @click="onPopup('MyPage')">마이페이지</a>
      <!-- <a href="#">현장선택</a> -->
      <a href="#" class="logout" @click.prevent.self @click="onLogout">{{ userInfo.name }}님 로그아웃</a>
    </div>
    <nav class="gnb">
      <ul id="headerMenu">
        <li v-for="m in menu" v-bind:key="m.mnuCd">
          <a href="#" @click.prevent.self @click="onSelectMenu" :data-mnu-cd="m.mnuCd" :class="{on: m.isSelected}">
            {{ m.mnuNm }}
          </a>
        </li>
      </ul>
    </nav>
    <iui-modal name="MyPageModal" title="마이페이지" width="600px" height="390px" :btns="modalBtns" :isDefault="true">
      <MyPagePopup />
    </iui-modal>
  </header>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import MyPagePopup from '@/view/sysManage/userManage/myPage/components/MyPagePopup.vue';

export default {
  name: 'iui-header',
  components: {MyPagePopup},
  data() {
    return {
      modalBtns: [{name: '확인', callback: this.onPopupConfirm}],
      modalNm: '',
    };
  },
  computed: {
    ...mapGetters('menu', {
      menu: 'menu',
    }),
    ...mapGetters('layout', {
      isUserMenu: 'isUserMenu',
    }),
    ...mapGetters(['subFrmGb']),
    goPortalNm() {
      if (this.userInfo.frmGb != $getConstants('FRM_GB_4').code) return;
      return this.subFrmGb == $getConstants('FRM_GB_1').code ? '외주포탈' : '건설사포탈';
    },
  },
  methods: {
    ...mapActions('menu', ['onSelectMenu']),
    ...mapActions('layout', ['userMenuToggle']),
    ...mapMutations(['setSubFrmGb']),
    onPopup(modalNm) {
      this.modalNm = `${modalNm}Modal`;
      this.$modal.show(this.modalNm, {isDefault: true});
    },
    onPopupConfirm() {
      this.callEvent({
        name: 'MyPage_confirm',
        param: result => {
          if (!result) {
            this.$alert({title: '마이페이지', message: '저장되지 않았습니다.'});
          }
        },
      });
    },
    changePortal() {
      if (this.userInfo.frmGb != $getConstants('FRM_GB_4').code) return;
      this.setSubFrmGb(
        this.subFrmGb == $getConstants('FRM_GB_1').code
          ? $getConstants('FRM_GB_2').code
          : $getConstants('FRM_GB_1').code
      );
    },
    onLogout() {
      //로그아웃 이력 남기기
      let LoginLogVo = {
        pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
        empNo: JSON.parse(sessionStorage.getItem('userInfo')).empNo,
      };
      axios.post('/loginLog/updateLogoutHistory', LoginLogVo);

      sessionStorage.clear();

      location.replace('/login');
    },
  },
};
</script>

<style scoped></style>
