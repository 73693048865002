<template>
  <pmis-content-box>
    <iui-container-new type="css-flex">
      <i-row height="115px">
        <i-col>
          <iui-container-new type="table" theme="bullet" header-width="100px">
            <i-row>
              <i-col-header>현장</i-col-header>
              <i-col>
                <iui-text :value="rfqInfo.spotDeptcd" :enable="false" width="100px" />
                <iui-text :value="rfqInfo.spotDeptnm" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>입찰공고번호</i-col-header>
              <i-col>
                <iui-text :value="rfqInfo.rfqNo2" :enable="false" width="100px" />
                <iui-text :value="rfqInfo.ttl" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header required>입찰마감일시</i-col-header>
              <i-col>
                <iui-datepicker
                  :value.sync="bidExpireYmd"
                  @change="bidExpireYmd = $event"
                  required
                  :errorMessage="{title: '입찰마감일시', message: '필수입력입니다.'}"
                />
                <iui-timepicker
                  width="30px"
                  :value="getTime(bidExpireHh, bidExpireMm)"
                  @change="onChangeTime('bidExpire', $event)"
                  required
                  :errorMessage="{title: '입찰마감일시', message: '필수입력입니다.'}"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header required>입찰예정가</i-col-header>
              <i-col>
                <iui-text
                  type="amount"
                  @change="bidPlanAmt = $event.target.value.trim()"
                  suffix=""
                  width="120px"
                  required
                  :errorMessage="{title: '입찰예정가', message: '필수입력입니다.'}"
                />
                <div>(부가세포함)</div>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template #title>참여협력사</template>
            <ib-sheet
              :uid="_uid"
              :options="sheetOpt"
              :loadSearchData="rfqCusList"
              @loadSheet="sheet = $event"
              :events="{onClick: sheet_onClick}"
            />
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-spacer />
      <i-row height="90px">
        <i-col>
          <iui-container-new type="table" theme="bullet" header-width="90px">
            <i-row>
              <i-col-header required>재입찰사유</i-col-header>
              <i-col rowspan="3">
                <iui-text
                  type="multi"
                  @change="rebidRsltRmrk = $event.target.value"
                  required
                  :errorMessage="{title: '재입찰사유', message: '필수입력입니다.'}"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col>&nbsp;</i-col>
            </i-row>
            <i-row>
              <i-col>&nbsp;</i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>

      <iui-modal name="ReBid_CustomerInfoModal" title="협력사정보" size-type="size2">
        <CustomerInfo />
      </iui-modal>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/reBid.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import {getServerKmCertPerm} from '@/view/bidding/rfq/common/api/pkiToolkit.js';
import {reBid} from '@/view/bidding/rfq/mat/api/rfq.js';
import {reBid as subReBid} from '@/view/bidding/rfq/sub/api/subRfq.js';

import CustomerInfo from '@/view/bidding/rfq/common/components/CustomerInfo.vue';
import IuiTimepicker from '@/components/common/IuiTimepicker.vue';

export default {
  components: {IuiTimepicker, CustomerInfo},
  props: {
    bidType: {type: String, required: true},
  },
  data() {
    return {
      sheetOpt: $addColCheckbox(sheetOpt(this), undefined, undefined, {
        CanEditFormula: fr =>
          fr.Row.spotDscrYn === BIDDING_CONSTANTS.CODES.SPOT_DSCR_N
            ? 1
            : fr.Row.spotDscrChkYn == BIDDING_CONSTANTS.CODES.SPOT_DSCR_CHK_Y
            ? 1
            : 0,
      }),

      bidExpireYmd: '',
      bidExpireHh: '00',
      bidExpireMm: '00',
      bidPlanAmt: '',
      rebidRsltRmrk: '',

      certNo: '', // 서버 인증서 번호
      svrKmCertPerm: '', // 서버 인증서 문자열
    };
  },
  beforeCreate() {
    $mapGetters(this, ['rfqInfo', 'rfqCusList']);
  },
  created() {
    this.addEvent({name: 'ReBid_Save', func: this.onSave});
  },
  async mounted() {
    await this.$secuKit.checkReady();

    // pki 서버인증서 정보
    getServerKmCertPerm().then(response => {
      this.certNo = response.data.certNo;
      this.svrKmCertPerm = response.data.svrKmCertPerm;
    });
  },
  methods: {
    onChangeTime(key, value) {
      const hour = value.split(':')[0];
      const minute = value.split(':')[1];
      this[key + 'Hh'] = hour;
      this[key + 'Mm'] = minute;
    },
    getTime(hour, minute) {
      hour = hour ? hour : '00';
      minute = minute ? minute : '00';
      return `${hour}:${minute}`;
    },
    onValidation() {
      const year = this.bidExpireYmd.substr(0, 4);
      const month = parseInt(this.bidExpireYmd.substr(4, 2)) - 1;
      const day = this.bidExpireYmd.substr(6, 2);
      const hour = this.bidExpireHh;
      const minute = this.bidExpireMm;

      if (!($_isNumberBetween(hour, 0, 24, true) && $_isNumberBetween(minute, 0, 60, true))) {
        this.$alert({
          title: '입찰마감일시',
          message: '입찰마감시간이 잘못되었습니다.<br/>다시 한 번 확인하시기 바랍니다.',
        });
        return false;
      }

      const date1 = new Date(year, month, day, hour, minute);
      const date2 = new Date();
      if (date1.getTime() <= date2.getTime()) {
        this.$alert({title: '입찰마감일시', message: '입찰마감일시는 현재시간 이후로만 가능합니다.'});
        return false;
      }

      if (!this.sheet.getRowsByChecked('chk').length) {
        this.$alert({title: '협력사', message: '협력사를 선택해야 합니다.'});
        return false;
      }

      return true;
    },
    async onSave(callback) {
      let title, message, key, targetComponent;

      const valid = await this.$validator.validate(this);
      valid.validators.some(item => {
        if (!item.isValid) {
          title = item.errorMessage.title;
          message = item.errorMessage.defaultMessage;
          key = Object.keys(item.targetComponent.$refs)[0];
          targetComponent = item.targetComponent;
          return true;
        }
      });
      if (title || message) {
        this.$alert({title, message}, () => {
          setTimeout(() => {
            if (key) targetComponent.$refs[key].focus();
          }, 100);
        });
        return false;
      }

      if (!this.onValidation()) return;

      try {
        // 1-1. 인증서 선택창
        await this.$secuKit.showDialog();

        // 1-2. 인증서 정보 추출
        const res = await this.$secuKit.viewCertInfomationWithVID({
          certType: 'SignCert',
          certID: this.$secuKit.certListInfo.getCertID(),
          isViewVID: '1',
        });
        this.userDN = res.userDN;

        // 1-3. 신원확인 검증
        let userDN = String(res.userDN);
        let certdn = String(JSON.parse(sessionStorage.getItem('userInfo')).certdn);

        userDN = userDN.replace(/\s/gi, '').toUpperCase();
        certdn = certdn.replace(/\s/gi, '').toUpperCase();

        if (userDN != certdn) {
          this.$alert({
            title: '인증서 검증',
            message: '시스템에 등록된 인증서와 선택하신 인증서가 다릅니다.<br/> 다시 한 번 확인하시기 바랍니다.',
          });
          return;
        }

        // 1-4. 인증서 유효기간
        const date1 = new Date(res.detailValidateTo).getTime();
        const date2 = new Date().getTime();
        if (date1 < date2) {
          this.$alert({
            title: '인증서 검증',
            message: '인증서의 유효기간이 만료되었습니다.<br/> 인증서를 확인하시기 바랍니다.',
          });
          return;
        }

        // 2. 입찰예정가 암호화
        const data = {
          targetCert: removePEMHeader(removeCRLF(this.svrKmCertPerm)), // Envelop 를 위한 서버 인증서 문자열
          sourceString: String(this.bidPlanAmt), // Envelop 할 데이터 원문
        };
        const r1 = await this.$secuKit.generatePKCS7EnvelopedData(data);
        const encBidPlanAmt = r1.generatePKCS7EnvelopedData;

        const param = {
          rfqNo: this.rfqInfo.rfqNo,
          bidExpireYmd: this.bidExpireYmd,
          bidExpireHh: this.bidExpireHh,
          bidExpireMm: this.bidExpireMm,
          cusCds: this.sheet.getRowsByChecked('chk').map(row => row.cusCd),
          rebidRsltRmrk: this.rebidRsltRmrk,
          encBidPlanAmt: encBidPlanAmt,
          certNo: this.certNo,
        };

        const reBidApi = {mat: reBid, sub: subReBid};
        reBidApi[this.bidType](param)
          .then(response => {
            if (response.status == 200) {
              callback(response.data.rfqNo);
            }
          })
          .catch(ex => {
            if (ex.pmisError) {
              this.$alert(ex);
            }
          });
      } catch (e) {
        console.error(e);
      }
    },
    sheet_onClick(e) {
      if (e.row.Kind == 'Data' && e.col == 'frmNm') {
        this.$store.commit('setModalParam', {pgmCd: e.sheet.getValue(e.row, 'cusPgmCd')});
        this.$modal.show('ReBid_CustomerInfoModal');
      }
    },
  },
};
</script>

<style></style>
