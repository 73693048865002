<template>
  <iui-container-new type="table" theme="bullet">
    <i-row>
      <i-col-header required>계약금액</i-col-header>
      <i-col rowspan="3">
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col width="60px">공급가액</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" :value="poInfo.poAmt" width="130px" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.amtKor }} 원 정)</label>
            </i-col>
          </i-row>
          <i-row>
            <i-col>부가세</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" :value="poInfo.vatAmt" width="130px" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.vatAmtKor }} 원 정)</label>
            </i-col>
          </i-row>
          <i-row>
            <i-col>합계</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" :value="poInfo.totAmt" width="130px" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.totAmtKor }} 원 정)</label>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
      <i-spacer />
      <i-col-header required>대금지급방법</i-col-header>
      <i-col :rowspan="poStatus.isPayCash ? 5 : 3">
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col width="100px">
              <iui-radio-group :items="payCashItem1" :value="poInfo.payCashYn" :enable="false" />
            </i-col>
            <i-col />
          </i-row>
          <i-row>
            <i-col>
              <iui-radio-group :items="payCashItem2" :value="poInfo.payCashYn" :enable="false" />
            </i-col>
            <i-col v-if="poStatus.isPayCash">
              <label>물품수령 후</label>
              <iui-text type="number" width="30px" :value="poInfo.payTerm" :enable="false" />
              <label>일 이내</label>
            </i-col>
          </i-row>
          <template v-if="poStatus.isPayCash">
            <i-row>
              <i-col />
              <i-col>
                <label>현금 :</label>
                <iui-text type="rate" suffix="%" :value="poInfo.payCashRatio" width="60px" :enable="false" />
                <label>어음 :</label>
                <iui-text type="rate" suffix="%" :value="poInfo.payCheckRatio" width="60px" :enable="false" />
                <label>어음대체수단</label>
                <iui-text type="rate" suffix="%" :value="poInfo.payCheck2Ratio" width="60px" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col />
              <i-col>
                <label>비고 :</label>
                <iui-text :value="poInfo.payCashRmrk" :enable="false" />
              </i-col>
            </i-row>
          </template>
          <i-row>
            <i-col>
              <iui-radio-group :items="payCashItem3" :value="poInfo.payCashYn" :enable="false" />
            </i-col>
            <i-col>
              <iui-text v-if="poStatus.isPayDefer" :value="poInfo.payDefer" :enable="false" />
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
    <i-row>
      <i-col />
      <i-spacer />
      <i-col />
    </i-row>
    <i-row>
      <i-col />
      <i-spacer />
      <i-col />
    </i-row>
    <i-row>
      <i-col-header required>과세유형</i-col-header>
      <i-col>
        <iui-radio-group :p-code="$getConstants('TAX_TYPE_CD').code" :value="poInfo.taxTypecd" :enable="false" />
        <template v-if="poStatus.isTaxDirect">
          <iui-select :p-code="$getConstants('TAX_DIRECT_DS').code" :value="poInfo.taxDirectDs" :enable="false" />
          <iui-text type="rate" suffix="%" :value="poInfo.taxTypeDirectPercent" width="80px" :enable="false" />
          <iui-text type="amount" suffix="원" :value="poInfo.taxTypeDirectAmt" width="120px" :enable="false" />
        </template>
      </i-col>
      <i-spacer />
      <i-col />
    </i-row>
    <i-row>
      <i-col-header required>인지세유무</i-col-header>
      <i-col>
        <iui-checkbox-group
          :checkedValues="[poInfo.stampTaxYn]"
          :items="[{label: $getConstants('EXTERNAL_Y').name, value: $getConstants('EXTERNAL_Y').code}]"
          :enable="false"
        />
        <template v-if="poStatus.isStamp">
          <iui-text class="mr10" suffix="원" width="100px" :value="poInfo.stampTaxAmt" :enable="false" />
          <iui-text
            type="rate"
            prefix="당사부담"
            suffix="%"
            width="110px"
            :value="poInfo.stampTaxOwnRate2"
            :enable="false"
          />
        </template>
      </i-col>
      <i-spacer />
      <i-col />
    </i-row>
    <i-row>
      <i-col-header>납품방법</i-col-header>
      <i-col>
        <iui-text :value="poInfo.dlvCondRmrkNm" :enable="false" />
      </i-col>
      <i-spacer />
      <i-col-header>특약사항</i-col-header>
      <i-col rowspan="2">
        <iui-text type="multi" :value="poInfo.poRmrk" :enable="false" />
      </i-col>
    </i-row>
    <i-row>
      <i-col-header required>{{ !poStatus.isPotypeUnit ? '납품일자' : '납품기간' }}</i-col-header>
      <i-col>
        <template v-if="!poStatus.isPotypeUnit">
          <iui-datepicker :value="poInfo.dlvReqDt" :enable="false" />
        </template>
        <template v-else>
          <iui-datepicker :value="poInfo.dlvFrDt" :enable="false" />
          <label>~</label>
          <iui-datepicker :value="poInfo.dlvToDt" :enable="false" />
        </template>
      </i-col>
      <i-spacer />
      <i-col />
    </i-row>
  </iui-container-new>
</template>

<script>
import PoInputMixin from '@/view/bidding/js/poInputMixin.js';
export default {
  mixins: [PoInputMixin],
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'poStatus']);
  },
};
</script>

<style></style>
