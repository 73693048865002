<template>
  <iui-file
    :accept.sync="accept"
    :save.sync="save"
    :clear.sync="clear"
    :postAction="postAction"
    :param="param"
    @save-error="saveError"
    @save-complete="saveComplete"
    :isInput="true"
  />
</template>

<script>
export default {
  props: {
    accept: {
      type: String,
      default: undefined,
    },
    clear: {
      type: Boolean,
      default: false,
    },

    postAction: {
      type: String,
      required: true,
    },
    param: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      save: false,
    };
  },
  created() {
    this.addEvent([{name: 'excelUpload', func: this.onUpload}]);
  },
  methods: {
    onUpload(callback) {
      this.save = true;
      if (typeof callback == 'function') {
        this.callback = callback;
      }
    },
    saveError(error, list) {
      this.$emit('error', error, list);
    },
    saveComplete(flNo, response) {
      if (this.callback) this.callback(response);
    },
  },
};
</script>

<style></style>
