<template>
  <!-- 외주입찰공고-포틀릿 -->
  <div style="height:100%;">
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr height="30px">
          <th>현장명</th>
          <th>입찰제목</th>
          <th>공고일</th>
          <th>마감일</th>
          <th class="p_last">입찰담당</th>
        </tr>
      </thead>
    </table>
    <div class="inner" style="height:calc(100% - 40px);">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="subRfq in subRfqList" :key="subRfq.rfqNo">
            <td style="text-align: left;">{{ subRfq.spotDeptnm }}</td>
            <td style="text-align: left;">
              <a @click="onClickRfq(subRfq.rfqNo)">{{ subRfq.ttl }}</a>
            </td>
            <td>{{ dateFormat(subRfq.bidStartDt) }}</td>
            <td>{{ dateFormat(subRfq.bidExpireDt) }}</td>
            <td class="p_last">{{ subRfq.chrgUsrnm }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
import {selectSubRfqList} from '@/components/portlet/api/portletData.js';
export default {
  mixins: [PortletMixin],
  data() {
    return {
      cnt: 5,
      subRfqList: [],
    };
  },
  methods: {
    dateFormat,
    setWidth() {
      this.tblWidth = this.$parent.$el.offsetWidth - 28;
    },
    async onSearch() {
      const response = await selectSubRfqList({orgCd: this.pgmCd, isPortletPage: true});
      this.subRfqList = response.data;
    },
    onClickRfq(rfqNo) {
      this.goMenu('RFQ_SUB_NOTICE', {rfqNo});
    },
  },
};
</script>

<style></style>
