<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text type="search" prefix="거래처명" :value.sync="searchFrmNm" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
      <iui-select
        v-if="frmGb1 || frmGb4 || frmGb0"
        :p-code="$getConstants('FRM_DS').code"
        defaultCd="A"
        prefix="거래처유형"
        :value="searchSelect"
        @change="value => onChange('searchSelect', value)"
      />
      <iui-checkbox-group
        ref="useCloseDs"
        :items="searchItems"
        :checkedValues="[searchUseCloseDs]"
        @change="onChange('searchUseCloseDs', $event)"
      />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onRenderFirstFinish,
        onSearchFinish,
        onClick,
      }"
      @loadSheet="loadSheet"
      :autoRender="false"
      :init="initSheet"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/CustomerListSheet.js';
import {deleteCustomerInfo, selectCustomerList} from '../api/customer.js';
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      searchFrmNm: '',
      searchSelect: '',
      searchUseCloseDs: '',
      searchItems: [{label: '종료포함', value: $getConstants('Y').code}],
      Options: {},
      loadSearchData: [],
      initSheet: false,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['focusKeyCode', 'detailInfo']);
    $mapMutations(this, ['initFocusKeyCode']);
  },
  created() {
    this.addEvent([
      {name: 'CustomerList_search', func: this.onSearch},
      {name: 'CustomerList_delete', func: this.onDelete},
      {name: 'CustomerList_setData', func: this.onSetData},
      {name: 'CustomerList_research', func: this.onReSearch},
    ]);
    this.createSheet();
    this.searchSelect = this.frmGb1 || this.frmGb4 || this.frmGb0 ? '' : $getConstants('Y').code;
  },
  computed: {
    frmGb1() {
      return this.userInfo.frmGb == $getConstants('FRM_GB_1').code; // 건설사
    },
    frmGb2() {
      return this.userInfo.frmGb == $getConstants('FRM_GB_2').code; // 공사용역
    },
    frmGb3() {
      return this.userInfo.frmGb == $getConstants('FRM_GB_3').code; // 물품자재
    },
    frmGb4() {
      return this.userInfo.frmGb == $getConstants('FRM_GB_4').code; // 건설사 + 공사용역
    },
    frmGb0() {
      return this.userInfo.frmGb == $getConstants('FRM_GB_0').code; // 운영사
    },
  },
  methods: {
    async createSheet() {
      this.Options = await options(this);
      this.initSheet = true;
    },
    onSearch() {
      const param = {
        pgmCd: this.pgmCd,
        frmNm: this.searchFrmNm,
        frmDs: this.searchSelect,
        useCloseDs: this.searchUseCloseDs,
      };
      selectCustomerList(param).then(response => {
        if (response.status == 200) {
          this.sheet.loadSearchData(response.data);
        }
      });
    },
    onReSearch(isClosedCustomer) {
      if (isClosedCustomer) {
        this.searchUseCloseDs = this.searchItems[0].value;
      } else {
        this.searchUseCloseDs = '';
      }
      this.onSearch();
    },
    onSetCustomerInfo(row) {
      this.$emit('setCustomerInfo', row);
    },
    onChange(target, event) {
      if (target == 'searchSelect') this.searchSelect = event;
      if (target == 'searchUseCloseDs') this.searchUseCloseDs = event.target.checked ? event.target.value : '';
      this.onSearch();
    },
    async onDelete() {
      const focusedRow = this.sheet.getFocusedRow();
      if (focusedRow == null) {
        this.$alert({title: '협력업체삭제', message: '삭제할 협력업체가 존재하지 않습니다.'});
        return;
      }

      const param = {
        pgmCd: this.pgmCd,
        bsnNo: focusedRow.bsnNo,
        flNo: this.detailInfo.flNo,
      };
      const title = '협력업체삭제';
      const message = `협력업체를 삭제하시겠습니까?<br/><br/><div style="font-size: 10pt;"> - 업체명 : ${focusedRow.frmNm}</div>`;

      if (await this.$confirm({title, message})) {
        const response = await deleteCustomerInfo(param);
        if (response.status == 200) {
          this.onSearch();
        }
      }
    },
    onSetData(param) {
      param(this.sheet.getFocusedRow());
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    onSearchFinish() {
      this.sheet.getDataRows().forEach(row => {
        if (row.useCloseDs == $getConstants('USE_CLOSE_DS1').code)
          this.sheet.setAttribute({row, attr: 'TextColor', val: '#FF0000'});
      });

      const focusRow = this.sheet.getDataRows().find(row => row.bsnNo == this.focusKeyCode);
      if (focusRow !== undefined) {
        this.sheet.focus(focusRow);
        this.onSetCustomerInfo(focusRow);
      } else {
        this.onSetCustomerInfo(this.sheet.getFirstRow());
      }
      this.initFocusKeyCode();
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.onSetCustomerInfo(e.row);
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped></style>
