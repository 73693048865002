import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 0},
    Def: {Row: {NoColor: 1}},
    Cols: [
      {Header: '거래처', Name: 'frmNm', Type: 'Text', RelWidth: 1, MinWidth: 150, Visible: false},
      {Header: '현장명', Name: 'spotDeptnm', Type: 'Text', RelWidth: 1, MinWidth: 150},
      {
        Header: '협력사명',
        Name: 'cusNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
        TextStyle: 5,
        TextColor: '#FF0000',
        Cursor: 'Pointer',
        Visible: false,
      },
      {
        Header: '계약/발주번호',
        Name: 'poNo',
        Type: 'Text',
        Align: 'center',
        Width: 140,
        CustomFormat: v => COMMON_FUNCTION.getMakeFormat(v),
      },
      {Header: '계약/발주명', Name: 'poTtl', Type: 'Text', RelWidth: 1, MinWidth: 150},
      {
        Header: '정산년월',
        Name: 'investMm',
        Type: 'Text',
        Width: 100,
        Align: 'center',
        CustomFormat: v => `${v.substring(0, 4)}-${v.substring(4)}`,
      },
      {
        Header: '정산구분',
        Name: 'investKind',
        Type: 'Text',
        Width: 100,
        Align: 'center',
        CustomFormat: v => {
          const obj = {
            [$getConstants('INVEST_KIND_ROUTINE').code]: '정기',
            [$getConstants('INVEST_KIND_NOT_ROUTINE').code]: '수시',
          };
          return obj[v];
        },
      },
      {Header: '정산차수', Name: 'reqDegree', Align: 'center', Width: 70, CustomFormat: v => (v ? `${v}차` : '')},
      {Header: '작성일자', Name: 'regDt', Align: 'center'},
      {
        Header: '(세금)계산서번호',
        Name: 'billNo',
        Type: 'Text',
        Align: 'center',
        Width: 140,
        TextStyle: 5,
        TextColor: '#FF0000',
        Cursor: 'Pointer',
        CanFocus: 0,
        CustomFormat: v => COMMON_FUNCTION.getMakeFormat(v),
      },
      {Header: '공급가', Name: 'suppAmt', Type: 'Text', Width: 120, CanFocus: 0, Extend: preset.amount},
      {Header: '세액', Name: 'vatAmt', Type: 'Text', Width: 120, CanFocus: 0, Extend: preset.amount},
      {Header: '합계금액', Name: 'totAmt', Type: 'Text', Width: 120, CanFocus: 0, Extend: preset.amount},

      {Name: 'contSeq', Visible: false},
      {Name: 'pgmCd', Visible: false},
      {Name: 'prjCd', Visible: false},
      {Name: 'custCode', Visible: false},
      {Name: 'billSt', Visible: false},
      {Name: 'billTaxNo', Visible: false},
      {Name: 'etaxTypeCd', Visible: false},
    ],
  };
};
