const state = () => ({
  searchInfo: {
    pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
    frmNm: JSON.parse(sessionStorage.getItem('userInfo')).frmNm,
    empNo: '',
    searchNm: '',
    searchLabtCloseYn: 'N', //근무종료자포함
  },
  workerInfo: {
    pgmCd: '', //회사코드
    frmNm: '', //회사명
    empNo: '', //사원번호(ID사번)
    name: '', //성명
    regiNo: '', //주민번호
    regiNo1: '', //주민번호1
    regiNo2: '', //주민번호2
    tlno: '', //전화번호
    mobileTlno: '', //휴대폰번호
    rpsvFaxno: '', //팩스번호
    postNo: '', //우편번호
    adr1: '', //주소
    adr2: '', //상세주소
    email1: '', //이메일1
    email2: '', //이메일2
    phoFlNo: 0, //사진파일번호
    laborDs: '', //근로자구분
    blnDepPart: '', //소속구분
    blnDepNo: '', //소속코드
    blnDepNm: '', //소속명
    blnDepNoSeq: '', //소속_순번
    dtsNo: '', //직책
    wBusiness: '', //담당업무
    rollNo: '', //직급
    useDs: '', //시스템사용여부
    useMst: '', //시스템관리자여부
    useSms: '', //SMS수신동의여부
    pwd: '', //비밀번호
    sgntFlNo: 0, //사진파일번호
    labtClose: '', //근무종료
    priceLGb: '', //노무단가구분
    priceLAmt: '', //노무단가
    jkdNo: '', //노무직종
    jkdNm: '', //노무직종명
    pensionDs: '', //국민연금적용제외여부
    healthInsurDs: '', //건강보험적용제외여부
    employInsurDs: '', //고용보험적용제외여부
    healthInsurMDs: '', //장기요양30%경감대상여부
    bankCode: '', //은행코드
    depositNo: '', //계좌번호
    depositor: '', //예금주
    handicapKind: '', //장애유형
    handicapGrade: '', //장애등급
    handicapDate: '', //장애판정일
    nationCd: '', //국가코드
    nationNm: '', //국가코드
    passportNo: '', //여권번호
    visaKind: '', //체류자격
    entryDate: '', //입국일자
    stayDuration: '', //체류기간
    cud: 0,
    idDupChk: 0,
    workingDays: 0,
    rgprId: '', //등록자아이디
    createNewEmpNo: false,
    canEnableLabtClose: false,
  },

  //파일 업로드 여부 체크를 위한 obj
  fileFlag: {
    phoFl: false,
    sgntFl: false,
  },
});

const getters = {
  searchInfo: state => state.searchInfo,
  focusKeyCode: state => state.focusKeyCode,
  workerInfo: state => state.workerInfo,
  fileFlag: state => state.fileFlag,
};

const actions = {};

const mutations = {
  setSearchInfo(state, payload) {
    for (let key in state.searchInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  setWorkerInfo(state, payload) {
    for (let key in state.workerInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.workerInfo[key] = payload[key];

        if (key == 'nationCd') {
          if (state.workerInfo[key] == $getConstants('NATION_CD_KR').code) {
            state.workerInfo.passportNo = '';
            state.workerInfo.visaKind = '';
            state.workerInfo.entryDate = '';
          } else {
            state.workerInfo.regiNo = '';
            state.workerInfo.regiNo1 = '';
            state.workerInfo.regiNo2 = '';
          }
        } else if (key == 'regiNo1' || key == 'regiNo2') {
          state.workerInfo.regiNo = state.workerInfo.regiNo1 + state.workerInfo.regiNo2;
        }
      }
    }
  },
  initWorkerInfo(state) {
    for (let key in state.workerInfo) {
      if (key == 'nationCd') {
        state.workerInfo[key] = $getConstants('NATION_CD_KR').code; // KR : 대한민국
        state.workerInfo['nationNm'] = '대한민국'; // KR : 대한민국
      } else if (key == 'nationNm') {
        state.workerInfo[key] = '대한민국'; // KR : 대한민국
      } else if (key == 'pwd' || key == 'pwd2') {
        state.workerInfo[key] = null;
      } else if (key == 'phoFlNo' || key == 'sgntFlNo') {
        state.workerInfo[key] = 0;
      } else if (key == 'cud') {
        state.workerInfo[key] = '1';
      } else if (key == 'blnDepPart') {
        state.workerInfo[key] = $getConstants('BLN_DEP_PART_CD_COMPANY').code; //소속 자사
      } else if (key == 'laborDs') {
        state.workerInfo[key] = $getConstants('LABOR_DS1').code; //근로자구분 정직
      } else {
        state.workerInfo[key] = '';
      }
    }
  },
  initFileFlag(state) {
    for (let key in state.fileFlag) {
      state.fileFlag[key] = false;
    }
  },
  setFileFlag(state, payload) {
    for (let key in state.fileFlag) {
      if (payload && payload.hasOwnProperty(key)) {
        state.fileFlag[key] = payload[key];
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
