const state = {
  accessToken: '',
  refreshToken: '',
};

const getters = {
  accessToken: state => state.accessToken,
  refreshToken: state => state.refreshToken,
};

const mutations = {
  setAccessToken(state, payload) {
    state.accessToken = payload;
  },
  setRefreshToken(state, payload) {
    state.refreshToken = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
