export default that => {
  return {
    Cfg: {
      MainCol: 'name', // 트리가 표시될 열이름
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      CanEdit: 0, // 1: 전체 편집 가능 (default)
      NoTreeLines: 0, // 트리를 사용하는 시트 생성시 노드와 노드 사이의 연결선을 표시할 지 여부를 설정
      FitWidth: 1, //더미 헤더를 추가하여 스크롤바를 우측 끝에 붙인다.
    },
    Def: {
      Header: {
        Hidden: true,
      },
    },
    Cols: [
      {
        Header: '명칭',
        Name: 'name',
        Type: 'Text',
        RelWidth: 1,
      },
      {
        Header: '코드',
        Name: 'code',
        Type: 'Text',
        Width: 65,
        Align: 'center',
      },
    ],
  };
};
