export default that => {
  let preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      CanEdit: 0,
    },
    Def: {
      Row: {
        CanFormula: 1,
      },
    },
    Cols: [
      {
        Header: '집행일자',
        Name: 'workDt',
        Type: 'Text',
        Width: 90,
        Align: 'Center',
        CustomFormat: '####-##-##',
      },
      {Header: '순번', Name: 'seq', Type: 'Text', Width: 60, Align: 'Center'},
      {Header: '내역명', Name: 'itemName', Type: 'Text', RelWidth: 1, MinWidth: 150},
      {
        Header: '거래구분',
        Name: 'accountGb',
        Type: 'Text',
        Width: 150,
        Align: 'Center',
        Format: $getConstants('ACCOUNT_GB').code,
      },
      {Header: '적요', Name: 'rmk', Type: 'Text', Width: 180},
      {Header: '공급가', Name: 'amt', Extend: preset.amount, Width: 100},
      {Header: '부가세', Name: 'vatAmt', Extend: preset.amount, Width: 100},
      {Header: '비과세', Name: 'taxexe', Extend: preset.amount, Width: 100},
      {
        Header: '합계',
        Name: 'sumAmt',
        Extend: preset.amount,
        Width: 100,
        Formula: fr => fr.Row.amt + fr.Row.vatAmt + fr.Row.taxexe,
      },
      {
        Header: '증빙',
        Name: 'filePopup',
        Type: 'Button',
        Button: 'Html',
        ButtonText: '<input type="button" class="i_search2"/>',
        Width: 50,
      },
      {Name: 'flNo', Visible: false},
    ],
  };
};
