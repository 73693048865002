<template>
  <iui-portlet-container>
    <template #title>회사주간일정</template>
    <template #link>
      <a href="#self" class="goto_top_link" @click="goShortCut({date: cutDate, tabIndex: 0})">
        <span class="blind">바로가기</span>
      </a>
    </template>

    <table class="tbl_list" height="100%">
      <thead>
        <tr>
          <th v-for="day in thisWeek" :key="day.date">{{ day.dateView }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-for="day in thisWeek" :key="day.date">
            <ul>
              <div>
                <li class="todo" v-for="schedule in day.schedules" :key="`${day.date}_${schedule.scdNo}`">
                  <a @click="goShortCut({date: day.date, scdNo: schedule.scdNo, tabIndex: 2})">{{ schedule.tl }}</a>
                </li>
              </div>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </iui-portlet-container>
</template>

<script>
import {weeklySchedule} from '@/components/portlet/api/portletData.js';
export default {
  data() {
    return {
      comps: {tabNm: '회사주간일정'},
      thisWeek: [],
      cutDate: '',
    };
  },
  created() {
    this.initSchedule();
  },
  mounted() {
    this.onSearch();
  },
  activated() {
    this.initSchedule();
    this.onSearch();
  },
  methods: {
    initSchedule() {
      this.thisWeek = [];

      const today = new Date();
      const getYear = today.getFullYear();
      const getMonth = today.getMonth();
      const getDate = today.getDate();
      const getDay = today.getDay();

      const strYear = String(getYear);
      const strMonth = String(getMonth + 1);
      this.cutDate = strYear + (strMonth.length === 1 ? `0${strMonth}` : strMonth);

      const weekFirstDay = new Date(getYear, getMonth, getDate - getDay);
      for (let i = 0; i < 7; i++) {
        const dateObj = new Date(weekFirstDay.getFullYear(), weekFirstDay.getMonth(), weekFirstDay.getDate() + i);

        const date =
          String(dateObj.getFullYear()) +
          String(dateObj.getMonth() + 1 < 10 ? `0${dateObj.getMonth() + 1}` : dateObj.getMonth() + 1) +
          String(dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate());

        const dayArr = ['(일)', '(월)', '(화)', '(수)', '(목)', '(금)', '(토)'];
        const value = `${dateObj.getMonth() + 1}/${dateObj.getDate()} ${dayArr[dateObj.getDay()]}`;
        this.thisWeek.push({date: date, dateView: value, schedules: []});
      }
    },
    async onSearch() {
      const param = {pgmCd: this.pgmCd, stDt: this.thisWeek[0].date, edDt: this.thisWeek[6].date};
      const response = await weeklySchedule(param);
      if (response.status == 200) {
        response.data.forEach(schd => {
          const index = this.thisWeek.findIndex(day => schd.dt == day.date);
          this.thisWeek[index].schedules.push({tl: schd.tl, scdNo: schd.scdNo});
        });
      }
    },
    goShortCut(param) {
      this.goMenu('SCHEDULE', param);
    },
  },
};
</script>

<style></style>
