<template>
  <iui-container-new type="css-flex">
    <i-row height="155px">
      <i-col>
        <!-- 계약차수 -->
        <PoContSeqList />
      </i-col>
    </i-row>
    <i-spacer />
    <i-row>
      <i-col>
        <PoInfo />
      </i-col>
    </i-row>

    <iui-modal name="PoTargetRfqListModal" :btns="PoTargetRfqListModalBtns" title="입찰완료목록" sizeType="size3">
      <PoTargetRfqList :bidType="bidType" />
    </iui-modal>
  </iui-container-new>
</template>

<script>
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import ContractInfoMixin from '@/view/bidding/po/common/mixins/contractInfoMixin.js';

import PoContSeqList from '@/view/bidding/po/common/components/PoContSeqList.vue';
import PoInfo from './PoInfo.vue';
import PoTargetRfqList from '@/view/bidding/po/common/components/PoTargetRfqList.vue';

export default {
  components: {PoContSeqList, PoInfo, PoTargetRfqList},
  mixins: [ContractInfoMixin],
  methods: {
    setAddPoData(callbackData) {
      if (callbackData) {
        const {rfqInfo, quotItem, poInfo} = {...callbackData};

        if (rfqInfo) {
          const labAmt = quotItem.reduce((acc, curr) => acc + curr['labUprc'] * curr['qty'], 0);
          const contFrDt = rfqInfo.constrFrDt;
          const contToDt = rfqInfo.constrToDt;

          this.setRfqInfo(rfqInfo);
          this.setPoInfo({...rfqInfo, labAmt, contFrDt, contToDt});
          this.setPoItemList(quotItem);
        }
        if (poInfo) {
          this.setPoInfo(poInfo);
        }
      } else {
        this.setPoInfo({
          orgCd: this.pgmCd,
          poNo2: BIDDING_CONSTANTS.CODES.AUTO,
          contSeq: BIDDING_CONSTANTS.CODES.FIRST_CONT_SEQ,
          taxTypecd: BIDDING_CONSTANTS.CODES.TAX,
          taxDirectDs: BIDDING_CONSTANTS.CODES.TAX_DIRECT_PERCENT,
          payCashYn: BIDDING_CONSTANTS.CODES.PAY_CASH,
          payCheck2Ratio: 0,
          stampTaxYn: BIDDING_CONSTANTS.CODES.STAMP_TAX_N,
          dlvYn: BIDDING_CONSTANTS.CODES.DLV_DATE,
          delayRateYn: BIDDING_CONSTANTS.CODES.DELAY_RATE_N,
          flawInsrYn: BIDDING_CONSTANTS.CODES.FLAW_INSR_YEAR,
          scpayInsrYn: BIDDING_CONSTANTS.CODES.SCPAY_INSR_N,
          poDt: dateFormat($_getCurrentDate()),
          rgprId: this.userInfo.empNo,
        });
      }
    },
  },
};
</script>

<style></style>
