<template>
  <div :class="{'pmis-page': true, 'iui-loading-overlay-parent': true}">
    <iui-loading-overlay :visible="internalLoading" />
    <section
      :class="{
        n_search_wrap: hasSearchboxSlot,
        no_search_wrap: !hasSearchboxSlot,
        middle_h: true,
        'header-box': true,
      }"
    >
      <h3 class="sta">{{ currentViewName }}</h3>
      <div
        class="search-box ml110"
        :style="`width: ${userInfo.frmGb == $getConstants('FRM_GB_0') ? '700' : '450'}px; min-width: 450px`"
      >
        <iui-searchbox
          v-if="searchCompanyVisible && isCompanySearchBtn"
          label="회사"
          :value="searchCompanyCode"
          :nameValue="searchCompanyName"
          :callback="sbxCompany_selected"
          :type="'companyCode'"
        />
        <iui-searchbox
          v-if="searchEstimateProjectVisible"
          label="현장"
          :value="searchEstimateProjectCode"
          :nameValue="searchEstimateProjectName"
          :callback="sbxEstimateProject_selected"
          :type="'prjEst'"
        />
        <iui-searchbox
          v-if="searchProjectVisible"
          label="현장"
          :value="searchProjectCode"
          :nameValue="searchProjectName"
          :callback="sbxProject_selected"
          :type="'prj'"
        />
        <slot name="searchbox" />
      </div>
      <div class="search_btn df">
        <iui-button
          v-for="(btn, index) in btns"
          :key="index"
          :ref="`${btn.type}Btn`"
          :btn-class="btn.class"
          @click="onBtnClick($event, btn.type, btn.cud)"
        >
          {{ btn.name }}
        </iui-button>
        <iui-button
          btn-class="btn_06 mr5"
          ref="closeBtn"
          v-if="$store.getters.currentUrl != '/Home'"
          @click="btnClose_click"
        >
          닫기
        </iui-button>
      </div>
    </section>

    <div class="scroll_wrap">
      <div class="data_container h_100">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {validSheet} from '@/util/validUtil.js';

export default {
  props: {
    // 회사 검색
    searchCompanyVisible: {
      type: Boolean,
      default: () =>
        JSON.parse(sessionStorage.getItem('userInfo'))
          ? JSON.parse(sessionStorage.getItem('userInfo')).frmGb == $getConstants('FRM_GB_0').code
          : false,
    },
    searchCompanyCode: {
      type: [String, Number],
      default: () =>
        JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).pgmCd : '',
    },
    searchCompanyName: {
      type: String,
      default: () =>
        JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).frmNm : '',
    },
    // 견적현장 검색
    searchEstimateProjectVisible: {
      type: Boolean,
      default: false,
    },
    searchEstimateProjectCode: {
      type: [String, Number],
      default: '',
    },
    searchEstimateProjectName: {
      type: String,
      default: '',
    },
    // 현장 검색
    searchProjectVisible: {
      type: Boolean,
      default: false,
    },
    searchProjectCode: {
      type: [String, Number],
      default: '',
    },
    searchProjectName: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    prjListSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      internalLoading: false,
      funcObj: {
        search: this.runFuncSearch,
        new: this.runFuncNew,
        save: this.runFuncSave,
        delete: this.runFuncDelete,
        print: this.runFuncPrint,
      },
    };
  },

  computed: {
    ...mapGetters('commonButton', ['btns']),
    ...mapGetters('menu', {
      currentViewName: 'selectedViewNm',
    }),

    ...mapGetters('mdi', {
      currentMenu: 'currentUrls',
    }),

    hasSearchboxSlot: {
      get() {
        return (
          (this.isCompanySearchBtn && this.searchCompanyVisible) ||
          this.searchEstimateProjectVisible ||
          this.searchProjectVisible ||
          this.$slots['searchbox'] !== undefined
        );
      },
    },
    isCompanySearchBtn: {
      get() {
        return JSON.parse(sessionStorage.getItem('userInfo'))
          ? JSON.parse(sessionStorage.getItem('userInfo')).frmGb == $getConstants('FRM_GB_0').code
          : false;
      },
    },
  },
  watch: {
    loading(value) {
      this.internalLoading = value;
    },
  },
  created() {
    this.addEvent([
      {
        name: 'showLoading',
        func: () => {
          this.internalLoading = true;
        },
      },
      {
        name: 'hideLoading',
        func: () => {
          this.internalLoading = false;
        },
      },
      {name: 'setCud', func: this._setCud},
    ]);
  },
  mounted() {
    this.setBtns();
  },
  activated() {
    this.setBtns();
  },
  methods: {
    ...mapActions('commonButton', [
      'runFuncSearch',
      'runFuncNew',
      'runFuncSave',
      'runFuncDelete',
      'runFuncPrint',
      'setSearchBtn',
      'setNewBtn',
      'setSaveBtn',
      'setDeleteBtn',
      'setPrintBtn',
      'setCloseBtn',
    ]),
    onBtnClick(event, type, cud) {
      this._setCud(cud);
      if (type === 'search' && this.prjListSearch) {
        this.callEvent({name: 'prjList_onSearch'});
      }
      this.$emit(`${type}-click`, event);
      this.funcObj[type]();
    },
    setBtns() {
      this.setSearchBtn(this.$refs.searchBtn);
      this.setNewBtn(this.$refs.newBtn);
      this.setSaveBtn(this.$refs.saveBtn);
      this.setDeleteBtn(this.$refs.deleteBtn);
      this.setPrintBtn(this.$refs.printBtn);
      this.setCloseBtn(this.$refs.closeBtn);
    },
    /**
     * 회사 검색 이벤트 핸들러
     */
    sbxCompany_selected(e) {
      this.$store.commit('setPgmCd', e.pgmCd);
      if (this.$parent) {
        this.$parent.$recompute('pgmCd');
      }
      this.$recompute('pgmCd');
      this.deepChildren(this);
      this.$emit('search-company-changed', e);

      if (this.searchProjectVisible) {
        this.$children.forEach(el => {
          if (el.type == 'prj') {
            el.initSearch();
          }
        });
        this.sbxProject_selected({pgmCd: e.pgmCd, prjCd: '', prjNm: ''});
      }
    },

    deepChildren(component) {
      if (component.$children.length) {
        component.$children.forEach(el => {
          el.$recompute('pgmCd');
          el.$recompute('cud');
          this.deepChildren(el);
        });
      } else {
        return false;
      }
    },

    txtTempCompanyCode_change(e) {
      this.$emit('search-company-changed', {
        code: e.target.value,
        name: e.target.value,
      });
    },

    /**
     * 견적현장 검색 이벤트 핸들러
     */
    sbxEstimateProject_selected(e) {
      this.$emit('search-estimate-project-changed', e);
    },

    /**
     * 현장 검색 이벤트 핸들러
     */
    sbxProject_selected(e) {
      this.$emit('search-project-changed', e);
    },

    /**
     * 닫기 버튼 이벤트 핸들러
     */
    async btnClose_click() {
      if (
        validSheet() &&
        !(await this.$confirm({title: '종료', message: '변경된 사항이 있습니다.<br/>종료하시겠습니까?'}))
      ) {
        return;
      }
      this.$store.commit('destroy', this.$store.getters.currentUrl);
    },

    _setCud(cud) {
      if ((this.cud != 0 && cud == 2) || cud === undefined) {
        return;
      }
      this.$store.commit('setCud', cud);
      if (this.$parent) {
        this.$parent.$recompute('cud');
      }
      this.$recompute('cud');
      this.deepChildren(this);
    },
  },
};
</script>

<style lang="scss">
@mixin box {
  align-items: center;
  display: flex;
}

@mixin box-item-margin {
  > * {
    margin-right: 15px;
  }

  > *:last-child {
    margin-right: unset;
  }

  > label {
    margin-right: 5px;
  }
}

@mixin box-items {
  a {
    display: inline-block;
    padding: 3px 6px 3px 8px;
    color: #666;
    font-weight: bold;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  button {
    display: inline-block;
    padding: 3px 6px 3px 8px;
    color: #666;
    font-weight: bold;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
}

.pmis-page {
  width: 100%;
  height: 100%;

  .header-box {
    .search-box {
      @include box;
      @include box-item-margin;
    }
  }

  .debug {
    border: 1px dashed #aaa;
    padding: 5px;
  }

  .debug > * {
    border: 1px dashed #aaa;
    padding: 5px;
  }

  a.disabled {
    pointer-events: none;
    cursor: default;
  }
}
</style>
