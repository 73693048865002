export default that => {
  return {
    Cfg: {
      CanEdit: false,
    },
    Def: {
      Row: {
        CanFocus: 0,
        CanFormula: 1,
      },
    },
    Cols: [
      {
        Name: 'gb',
        Header: '구분',
        Width: 150,
        Format: {PLN: '계획공정률(%)', EXE: '실정공정률(%)', GAP: '차이(%)'},
        Align: 'Center',
      },
      {
        Name: 'preRate',
        Header: '전월누계',
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.rate,
      },
      {
        Name: 'curRate',
        Header: '당월',
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.rate,
      },
      {
        Name: 'totRate',
        Header: '누계',
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.rate,
      },
    ],
  };
};
