<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col width="345px">
        <PrjAllList :callback="onPrjSearch" :popup="true" :excludePrjCd="String(prjCd)" />
      </i-col>
      <i-spacer />
      <i-col>
        <pmis-tab-box no-border-box no-padding-box>
          <template v-slot:title>공종분류</template>
          <PrjWorkTypeCl
            :checkbox="true"
            :eventNms="{
              prjWorkTypeCl_onSearch: 'prjWorkTypeCl_onSearch_modal',
              prjWorkTypeCl_selectClickData: 'prjWorkTypeCl_selectClickData_modal',
              prjWorkTypeCl_onDelete: 'prjWorkTypeCl_onDelete_modal',
            }"
          ></PrjWorkTypeCl>
        </pmis-tab-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import PrjWorkTypeCl from './PrjWorkTypeCl.vue';
import PrjAllList from '@/view/estmtManage/prjWorkTypeRegist/components/PrjAllList.vue';

export default {
  components: {
    PrjWorkTypeCl,
    PrjAllList,
  },
  data() {
    return {
      otherPrjCd: '',
    };
  },
  created() {
    this.addEvent([{name: 'otherPrjCd', func: callback => callback(this.otherPrjCd)}]);
  },
  methods: {
    onPrjSearch(row) {
      if (row) {
        this.otherPrjCd = row.prjCd;
        this.onSearch({prjCd: row.prjCd, prjNm: row.prjNm});
      }
    },
    onSearch(param) {
      this.callEvent({name: 'prjWorkTypeCl_onSearch_modal', param: param});
    },
  },
};
</script>

<style scoped></style>
