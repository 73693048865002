<template>
  <pmis-page
    ref="page"
    :search-project-visible="false"
    @search-click="onSearch"
    @new-click="onAdd"
    @save-click="onSave"
    @delete-click="onDelete"
  >
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>
              현장목록
            </template>
            <iui-container-new type="css-flex">
              <i-row>
                <i-col>
                  <PrjList />
                </i-col>
              </i-row>
              <i-spacer />
              <i-row>
                <i-col>
                  <PhotoInfoList />
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <div class="flex" style="height: 100%;">
            <iui-tab :comps="tabList" :keepAlive="true" @after-active-tab="afterActiveTab"></iui-tab>
          </div>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
/**
 * 공정사진조회
 *
 * */
import store from '../store/photo.js';
import PrjList from '../components/PrjList.vue';
import PhotoInfoList from '../components/PhotoInfoList.vue';
export default {
  components: {PrjList, PhotoInfoList},
  data() {
    return {
      tabList: [
        {
          tabNm: '사진상세정보',
          compNm: 'ImageList',
          path: '@/view/bsnsManage/Process/Photo/components/ImageList.vue',
        },
        {
          tabNm: '사진등록',
          compNm: 'ImageReg',
          path: '@/view/bsnsManage/Process/Photo/components/ImageReg.vue',
        },
      ],
    };
  },
  beforeCreate() {
    $init(this, store);
    $mapMutations(this, ['setDetailInfo', 'initSearchInfo', 'initDetailInfo', 'initPhotos']);
  },
  methods: {
    afterActiveTab(index) {
      if (index) {
        this.canNew = true;
        this.canSave = true;
        this.canDelete = true;
      } else {
        this.canNew = false;
        this.canSave = false;
        this.canDelete = false;
      }
    },
    onSearch() {
      this.initSearchInfo();
      this.initDetailInfo();
      this.initPhotos();
      this.callEvent({name: 'prjListOnSearch'});
    },
    onAdd() {
      this.callEvent({name: 'imageRegOnInit'});
      this.setDetailInfo({
        cud: 1,
        rgprId: this.userInfo.empNo,
        phoDt: $_getCurrentDate(),
      });
    },
    onSave() {
      this.callEvent({name: 'imageRegOnSave'});
    },
    async onDelete() {
      if (await this.$confirm({title: '삭제', message: '사진정보를 삭제하시겠습니까?'})) {
        this.setDetailInfo({cud: 3});
        this.callEvent({name: 'imageRegOnDelete'});
      } else {
        this.setDetailInfo({cud: 2});
      }
    },
  },
};
</script>

<style></style>
