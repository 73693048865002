export default that => {
  return {
    Cols: [
      {
        Header: 'ID',
        Name: 'empNo',
        Type: 'Text',
        Align: 'Center',
        Width: 130,
        CanEdit: false,
      },
      {
        Header: '성명',
        Name: 'name',
        Type: 'Text',
        Align: 'Center',
        Width: 200,
        CanEdit: false,
      },
      {
        Header: '부서명',
        Name: 'fldrNm',
        Type: 'Text',
        Align: 'Center',
        RelWidth: 1,
        CanEdit: false,
      },
    ],
  };
};
