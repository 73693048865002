<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text :value.sync="searchText" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <iui-container-new type="table">
      <i-row v-for="(items, index) in unitArrayList" :key="index">
        <i-col v-for="(items, index) in items" :key="index">
          <span
            :ref="`unit_${items.unitCode}`"
            :id="`unit_${items.unitCode}`"
            @click="onClick(items.unitCode, items.unitName)"
            @dblclick="onDblClick"
            style="cursor: pointer;width:100%;"
            >{{ items.unitName }}</span
          >
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import unitConstants from '@/view/sysManage/stdCodeManage/js/unitConstants.js';
import {selectUnitCodeList} from '@/view/sysManage/stdCodeManage/matCodeRegist/api/MatCodeRegist.js';
export default {
  created() {
    this.addEvent({name: 'UnitPopupp_popConfirm', func: this.onPopupConfirm});
    this.onSearchCodeList();
  },
  data() {
    return {
      columnCount: 12,
      searchText: '',
      unitList: [],
      unitArrayList: [],
      returnArray: [],
    };
  },
  methods: {
    async onSearchCodeList() {
      let response = await selectUnitCodeList();
      this.unitList = response.data;

      let tempArray = [];
      this.unitArrayList = this.unitList.reduce((prev, currValue, currIndex) => {
        tempArray.push(currValue);
        if ((currIndex + 1) % this.columnCount == 0 || this.unitList.length == currIndex + 1) {
          prev.push(tempArray);
          tempArray = [];
        }
        return prev;
      }, []);
    },
    onSearch() {
      this.onSearchUnit(this.searchText.trim());
    },
    onSearchUnit(targetCode) {
      targetCode = targetCode.toLowerCase();
      let targetList = this.unitList.filter(
        obj =>
          targetCode &&
          (obj.unitName.toLowerCase().indexOf(targetCode) > -1 ||
            (unitConstants[obj.unitName] !== undefined &&
              unitConstants[obj.unitName].toLowerCase().indexOf(targetCode) > -1))
      );

      this.returnArray = [];
      this.onRemoveAllStyle();
      targetList.forEach(item => {
        this.onSetStyle(item.unitCode, false);
        this.returnArray.push({unitCode: item.unitCode, unitName: item.unitName});
      });
    },
    onClick(unitCode, unitName) {
      this.returnArray = [];
      this.returnArray.push({unitCode, unitName});
      this.onSetStyle(unitCode, true);
    },
    onDblClick() {
      this.$modalConfirm();
    },
    onRemoveAllStyle() {
      this.$el.querySelectorAll('table td').forEach(td => td.removeAttribute('style'));
    },
    onSetStyle(unitCode, clickYn) {
      if (clickYn) {
        this.onRemoveAllStyle();
      }
      this.$el
        .querySelector(`#unit_${unitCode}`)
        .closest('td')
        .setAttribute('style', 'font-weight: bold; background-color: powderblue;');
    },
    onPopupConfirm(callback) {
      if (this.returnArray.length == 0) {
        this.$alert({title: '단위', message: '단위가 선택되지 않았습니다.'});
      } else if (this.returnArray.length > 1) {
        this.$alert({title: '단위', message: '검색된 단위 중 한 가지를 선택해야 합니다.'});
      } else {
        callback(this.returnArray[0]);
      }
    },
  },
};
</script>

<style></style>
