<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px"><PrjList /></i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <pmis-tab-box no-border-box no-padding-box>
                  <template v-slot:title>가동장비목록</template>
                  <OperationEquipmentList />
                </pmis-tab-box>
              </i-col>
            </i-row>
            <i-spacer />
            <i-row height="335px">
              <i-col>
                <pmis-tab-box>
                  <template v-slot:title>상세정보</template>
                  <DetailInfo />
                </pmis-tab-box>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
/**
 * 가동장비등록
 * components :
 *   PrjList 현장목록
 *   OperationEquipmentList 가동장비목록
 *   DetailInfo 상세정보
 *
 * */
import store from './store/operationEquipment.js';
import PrjList from '@/view/Project/components/PrjList.vue';
import OperationEquipmentList from './components/OperationEquipmentList.vue';
import DetailInfo from './components/DetailInfo.vue';
import {saveEqutPrjCodeList, deleteEqutPjtCode} from './api/OperationEquipment.js';
export default {
  components: {
    PrjList,
    OperationEquipmentList,
    DetailInfo,
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['detailInfo', 'oriDetailInfo']);
    $mapMutations(this, ['initDetailInfo', 'setDetailInfo']);
  },
  created() {
    this.canNew = true;
    this.canSave = true;
    this.canDelete = true;

    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
    this.addFuncPrj(this.onSearch);
  },
  methods: {
    onAdd() {
      this.initDetailInfo();
      this.setDetailInfo({cud: '1'});
    },
    onSearch() {
      this.callEvent({name: 'OperationEquipmentList_onSearch'});
    },
    async onSave() {
      let duplicate = false;
      this.callEvent({name: 'duplicateCarNo', param: data => (duplicate = data)});
      if (duplicate && this.detailInfo.cud == 1) {
        this.$alert({title: '차량번호 중복', message: '차량번호가 중복됩니다.'});
        return;
      }

      const param = {...this.detailInfo, ...this.oriDetailInfo};
      const response = await saveEqutPrjCodeList(param);
      if (response.status == 200) {
        this.onSearch();
      }
    },
    onDelete() {
      this.callEvent({
        name: 'OperationEquipmentList_getCheckedRows',
        param: checkedRows => {
          const deleteList = checkedRows.map(element => {
            return {
              equipCode: element.equipCode,
              carNo: element.carNo,
              useGb: element.useGb,
              isExistEquipDaily: element.isExistEquipDaily,
            };
          });
          this.onDeleteProcess(deleteList);
        },
      });
    },
    onDeleteValidator(deleteList) {
      const validRow = deleteList.find(element => element.isExistEquipDaily);
      if (validRow !== undefined) {
        this.$alert({
          title: '가동장비삭제',
          message: `가동일보에 등록된 장비가 존재합니다.<br/><br/>- 장비코드 : ${validRow.equipCode}<br/>- 차량번호 : ${validRow.carNo}`,
        });
        return false;
      }
      return true;
    },
    async onDeleteProcess(deleteList) {
      if (0 == deleteList.length) {
        this.$alert({title: '가동장비삭제', message: '삭제할 항목이 없습니다.'});
        return;
      }
      const param = {deleteList};
      const title = '가동장비삭제';
      const message = '선택한 항목을 삭제하시겠습니까?';

      if (this.onDeleteValidator(deleteList)) {
        if (await this.$confirm({title, message})) {
          const response = await deleteEqutPjtCode(param);
          if (response.status == 200) {
            this.onSearch();
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.box_w1 {
  min-height: 200px !important;
  height: 210px !important;
}
.box_w2 {
  height: calc(100vh - 508px) !important;
}
</style>
