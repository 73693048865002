import APPROVAL from '../../../js/approvalConstants.js';
export default async that => {
  return {
    Def: {
      Row: {
        CanFormula: true,
        CalcOrder: 'sncTpType,sncTpCanEdit',
      },
    },
    Cols: [
      {
        Name: 'sn',
        Header: '결재순번',
        CanEdit: false,
        Width: 70,
      },
      {
        Name: 'sncTp',
        Header: '결재유형',
        TypeFormula: e =>
          [APPROVAL.SNC_TP_DRAFT, APPROVAL.SNC_TP_RECEIPT].indexOf(e.Row.sncTp) !== -1 ? 'Text' : 'Enum',
        EnumKeys: await that.getCodeIBSheetEnum('KEY', $getConstants('SNC_TP').code, {
          excludeKeys: [
            APPROVAL.SNC_TP_DRAFT,
            APPROVAL.SNC_TP_RECEIPT,
            !that.isInformat ? APPROVAL.SNC_TP_COOPERATION : '',
          ],
        }),
        Enum: await that.getCodeIBSheetEnum('VALUE', $getConstants('SNC_TP').code, {
          excludeKeys: [
            APPROVAL.SNC_TP_DRAFT,
            APPROVAL.SNC_TP_RECEIPT,
            !that.isInformat ? APPROVAL.SNC_TP_COOPERATION : '',
          ],
        }),
        Format: $getConstants('SNC_TP').code,
        CanEditFormula: e =>
          [APPROVAL.SNC_TP_DRAFT, APPROVAL.SNC_TP_RECEIPT].indexOf(e.Row.sncTp) !== -1 ? false : true,
        Width: 100,
      },
      {
        Name: 'dtsNo',
        Header: '직책',
        CanEdit: false,
        Width: 90,
        Format: $getConstants('DTS_NO').code,
      },
      {
        Name: 'rollNo',
        Header: '직위',
        CanEdit: false,
        Width: 90,
        Format: $getConstants('ROLL_NO').code,
      },
      {
        Name: 'name',
        Header: '결재자명',
        CanEdit: false,
        RelWidth: 1,
      },
      {
        Name: 'empNo',
        Visible: false,
      },
      {
        Name: 'snclSn',
        Visible: false,
      },
    ],
  };
};
