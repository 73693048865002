<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text
        type="search"
        :value="searchInfo.searchNm"
        @change="setValue('searchNm', $event)"
        @enter="selectWorkerList()"
      />
      <iui-button value="검색" @click="selectWorkerList()" />
    </template>
    <iui-container-new type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    uid: {
      type: Number,
    },
    isExtend: {
      type: Boolean,
      default: false,
    },
  },
  beforeCreate() {},
  created() {
    this.addEvent([
      {name: 'selectWorkerList', func: this.selectWorkerList},
      {name: 'onFocusedRowDataOnWorker', func: this.onFocusedRowData},
    ]);
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      //pgmCd: this.$store.getters['modalParam'].pgmCd,
      prjCd: this.$store.getters['modalParam'].prjCd,
      searchInfo: {
        searchNm: '',
      },
    };
  },
  async mounted() {
    const me = this;
    const OPT = {
      Cfg: {
        HeaderCheck: 1,
      },
      Cols: [
        {
          Header: '선택',
          Type: 'Bool',
          Name: 'cbx',
          Width: 50,
          CanEdit: 1,
          Visible: !me.isPopup,
        },
        {
          Header: '근로자구분',
          Name: 'laborDs',
          Type: 'Text',
          RelWidth: 1,
          CanEdit: 0,
          Align: 'center',
          Format: await $getCodeIBSheetFormat($getConstants('LABOR_DS_CD').code),
        },
        {
          Header: 'ID',
          Name: 'empNo',
          Type: 'Text',
          RelWidth: 1,
          CanEdit: 0,
          Align: 'left',
        },
        {
          Header: '근로자명',
          Name: 'name',
          Type: 'Text',
          RelWidth: 1,
          CanEdit: 0,
          Align: 'left',
        },
        {
          Header: '성별',
          Name: 'sex',
          Type: 'Text',
          RelWidth: 1,
          CanEdit: 0,
          Align: 'center',
        },
        {
          Header: '생년월일',
          Name: 'birthday',
          Type: 'Text',
          RelWidth: 1,
          CanEdit: 0,
          Align: 'center',
        },
        {
          Header: '소속구분',
          Name: 'blnDepPart',
          Type: 'Text',
          RelWidth: 1,
          CanEdit: 0,
          Align: 'center',
          Format: await $getCodeIBSheetFormat($getConstants('BLN_DEP_PART_CD').code),
        },
        {
          Header: '소속명',
          Name: 'blnDepNm',
          Type: 'Text',
          RelWidth: 1,
          CanEdit: 0,
          Align: 'left',
        },
        {
          Header: '투입일자',
          Name: 'rlseDate',
          Type: 'Text',
          RelWidth: 1,
          CanEdit: 0,
          Align: 'center',
        },
        {
          Header: '철수(예정)일자1',
          Name: 'outDate',
          Type: 'Text',
          RelWidth: 1,
          CanEdit: 0,
          Align: 'center',
        },
        {Name: 'custCode', Visible: 0},
        {Name: 'dtsNo', Visible: 0},
        {Name: 'jkdNo', Visible: 0},
        {Name: 'priceLGb', Visible: 0},
      ],
      Events: {
        onDataLoad: me.grid_onDataLoad,
        onRenderFirstFinish: this.selectWorkerList,
      },
    };

    me.sheet = await PMISSheet.createSheet({
      el: me.elId,
      options: OPT,
    });
  },
  methods: {
    async selectWorkerList() {
      const me = this;
      this.response2 = [];

      let param = {};
      param['pgmCd'] = this.pgmCd;
      param['prjCd'] = this.prjCd;
      param['searchNm'] = this.searchInfo.searchNm;

      await axios.post('/worker/selectWorkerByPrjListPopup', param).then(response => {
        me.sheet.loadSearchData(response.data);
      });
    },
    onFocusedRowData(param) {
      if (typeof param == 'function') {
        param(this.sheet.getDataRows());
      }
    },
    grid_onDataLoad() {
      let pSheet = this.$store.getters['modalParam'].pSheet;
      for (let pRow of pSheet) {
        for (let row of this.sheet.getDataRows()) {
          if (pRow.empNo == row.empNo) {
            this.sheet.setCheck(row, 'cbx', 1);
            if (pRow.newYn != 'Y') {
              this.sheet.setAttribute(row, 'cbx', 'CanEdit', false, 1);
            }
          }
        }
      }
    },
    setValue(t, e) {
      this.searchInfo[t] = e.target.value;
    },
  },
};
</script>

<style scoped></style>
