<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>투입현황</template>
            <template v-slot:title-right>
              <iui-button v-if="!sncNo" value="결재" @click="showDocument" />
              <iui-amount-unit :amount-unit="amountUnit" @change="onAmountChange" />
            </template>
            <ib-sheet
              :uid="_uid"
              :options="Options"
              :loadSearchData="loadSearchData"
              :events="{
                onSearch,
                onSearchFinish,
              }"
              @loadSheet="loadSheet"
            />
          </pmis-tab-box>
        </i-col>
        <pop :isModal="true" :parentId="_uid" :title="'투입현황'" :offline="false" />
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import APPROVAL from '@/view/approval/js/approvalConstants.js';
import EDMS_CONSTANTS from '@/view/edms/common/EdmsConstatns.js';
import pop from '@/view/approval/draft/popup/pop.vue';
import PrjList from '@/view/Project/components/PrjList.vue';
import options from '@/view/bsnsManage/Process/Input/components/sheetOption/input.js';
import {selectInput} from '@/view/bsnsManage/Process/Input/api/input.js';
import IuiAmountUnit from '@/components/common/IuiAmountUnit.vue';

export default {
  components: {
    pop,
    PrjList,
    IuiAmountUnit,
  },
  data() {
    return {
      inputList: [],
      loadSearchData: [],
      Options: options(this),
      monthList: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      sncNo: null,
      responseData: {
        sheet: [],
      },
      amountUnit: '',
    };
  },
  created() {
    this.amountUnit = this.userInfo?.amountUnit
      ? this.userInfo.amountUnit
      : this.$store.state.code2.codeAlias.AMOUNT_UNIT_ONE_THOUSAND_WON.code;
    this.canSearch = true;
    this.addFuncPrj(this.onSearch);
    this.addFuncPrint(this.showDocument);
  },
  watch: {
    sncNo(v) {
      this.canPrint = v;
    },
  },
  methods: {
    async showDocument() {
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1;
      let excuteExternal = {};
      let baseInfo = {
        pgmNm: this.userInfo.frmNm,
        prjNm: this.getPrjInfo().prjNm,
      };

      const array = [...this.inputList];
      array.forEach(x => {
        Object.keys(x).map(key => {
          if (key.toLowerCase().indexOf('amt') > -1 || key.toLowerCase().indexOf('price') > -1) {
            x[key] = $_numberFormat(x[key], true);
          }
        });
      });

      excuteExternal['inputList'] = `{"inputList":${JSON.stringify(array)}}`;
      excuteExternal['baseInfo'] = `{"baseInfo":${JSON.stringify(baseInfo)}}`;
      excuteExternal['amountUnit'] = await $getCodeNm($getConstants('AMOUNT_UNIT').code, this.amountUnit);

      this.callEvent({
        name: `draft_pop${this._uid}`,
        param: {
          fbsCd: EDMS_CONSTANTS.INPUT.FBS_CD,
          fbsNo: EDMS_CONSTANTS.INPUT.FBS_NO,
          fomNo: EDMS_CONSTANTS.INPUT.FOM_NO,
          taskCd: EDMS_CONSTANTS.INPUT.TASK_CD,
          taskNo: `${this.pgmCd}${this.prjCd}${year}${month.toString().length > 1 ? month : '0' + month}`,
          signCnt: APPROVAL.FORMATTED_LINE_CNT,
          isMulti: false,
          tl: `[${month}월 투입현황]`,
          sncNo: this.sncNo,
          excuteExternal,
          canToggle: true,
          withoutLine: true,
        },
      });
    },
    async onSearch() {
      if (!this.prjCd) {
        this.loadSearchData = [];
        return;
      }
      this.inputList = [];
      let date = new Date();
      let param = {
        lastYear: date.getFullYear() - 1,
        nowYear: date.getFullYear(),
        monthList: this.monthList,
      };
      const response = await selectInput(param);
      this.responseData.sheet = response.data;
      this.convertPriceUnit();

      let sncNo = null;
      response.data.forEach(e => {
        if (e.month == date.getMonth() + 1 && e.sncNo) {
          sncNo = e.sncNo;
        }
      });
      this.sncNo = sncNo;
    },
    onSearchFinish() {
      if (0 < this.sheet.getTotalRowCount()) {
        this.inputList = [];
        let matAmt = 0;
        let labAmt = 0;
        let equipAmt = 0;
        let oprAmt1 = 0;
        let oprAmt2 = 0;
        let subAmt = 0;
        let sumAmt = 0;
        this.sheet.getDataRows().forEach(row => {
          matAmt += row.matAmt;
          labAmt += row.labAmt;
          equipAmt += row.equipAmt;
          oprAmt1 += row.oprAmt1;
          oprAmt2 += row.oprAmt2;
          subAmt += row.subAmt;
          sumAmt += row.sumAmt;
          this.inputList.push({
            month: row.month,
            matAmt: $_numberFormat(row.matAmt, true, null, 'floor', 1),
            labAmt: $_numberFormat(row.labAmt, true, null, 'floor', 1),
            equipAmt: $_numberFormat(row.equipAmt, true, null, 'floor', 1),
            oprAmt1: $_numberFormat(row.oprAmt1, true, null, 'floor', 1),
            oprAmt2: $_numberFormat(row.oprAmt2, true, null, 'floor', 1),
            subAmt: $_numberFormat(row.subAmt, true, null, 'floor', 1),
            sumAmt: $_numberFormat(row.sumAmt, true, null, 'floor', 1),
          });
        });
        this.sheet.makeSubTotal([
          {
            stdCol: 'total',
            captionCol: [
              {col: 'month', val: '소계'},
              {
                col: 'matAmt',
                val: $_numberFormat(matAmt, true, null, 'floor', 1),
              },
              {
                col: 'labAmt',
                val: $_numberFormat(labAmt, true, null, 'floor', 1),
              },
              {
                col: 'equipAmt',
                val: $_numberFormat(equipAmt, true, null, 'floor', 1),
              },
              {
                col: 'oprAmt1',
                val: $_numberFormat(oprAmt1, true, null, 'floor', 1),
              },
              {
                col: 'oprAmt2',
                val: $_numberFormat(oprAmt2, true, null, 'floor', 1),
              },
              {
                col: 'subAmt',
                val: $_numberFormat(subAmt, true, null, 'floor', 1),
              },
              {
                col: 'sumAmt',
                val: $_numberFormat(sumAmt, true, null, 'floor', 1),
              },
            ],
          },
        ]);
        this.inputList.push({
          month: '소계',
          matAmt: $_numberFormat(matAmt, true, null, 'floor', 1),
          labAmt: $_numberFormat(labAmt, true, null, 'floor', 1),
          equipAmt: $_numberFormat(equipAmt, true, null, 'floor', 1),
          oprAmt1: $_numberFormat(oprAmt1, true, null, 'floor', 1),
          oprAmt2: $_numberFormat(oprAmt2, true, null, 'floor', 1),
          subAmt: $_numberFormat(subAmt, true, null, 'floor', 1),
          sumAmt: $_numberFormat(sumAmt, true, null, 'floor', 1),
        });
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onAmountChange(value) {
      this.amountUnit = value;
      this.convertPriceUnit();
    },
    convertPriceUnit() {
      this.loadSearchData = $_priceUnitFormat(this.amountUnit, this.responseData.sheet);
    },
  },
};
</script>

<style></style>
