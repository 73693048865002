export default that => {
  return {
    Cfg: {
      CanEdit: 0,
    },
    Def: {Row: {CanFormula: 1}},
    Cols: [
      {
        Header: '신청일자',
        Name: 'reqDt',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CustomFormat: v => dateFormat(v),
      },
      {
        Header: '순번',
        Name: 'seq',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
      },
      {
        Header: '회사명',
        Name: 'frmNm',
        Type: 'Text',
        RelWidth: 1,
      },
      {
        Header: '신청자',
        Name: 'name',
        Type: 'Text',
        RelWidth: 1,
      },
      {
        Header: '연락처',
        Name: 'phone',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CustomFormat: v => {
          let phoneArr = $_mobileFormat(v);
          return phoneArr ? `${phoneArr[0]}-${phoneArr[1]}-${phoneArr[2]}` : '';
        },
      },
      {
        Header: '접수일자',
        Name: 'receDt',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CustomFormat: v => dateFormat(v),
      },
      {Name: 'receId', Visible: false},
      {
        Header: '접수자',
        Name: 'receNm',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
      },
      {
        Header: '상담완료여부',
        Name: 'compltDs',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
      },
    ],
  };
};
