<template>
  <pmis-page ref="page" :search-project-visible="false">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row height="410px">
              <i-col width="690px">
                <PrList />
              </i-col>
              <i-spacer />
              <i-col min-width="695px">
                <PrDetailInfo />
              </i-col>
            </i-row>
            <i-spacer />
            <i-row min-height="250px">
              <i-col>
                <PrItemList />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from './store/pr.js';

import PrjList from '@/view/Project/components/PrjList.vue';
import PrList from './components/PrList.vue';
import PrDetailInfo from './components/PrDetailInfo.vue';
import PrItemList from './components/PrItemList.vue';

export default {
  components: {
    PrjList,
    PrList,
    PrDetailInfo,
    PrItemList,
  },
  beforeCreate() {
    $init(this, store);
  },
  created() {
    this.canSave = false;
    this.canDelete = false;
  },
};
</script>

<style></style>
