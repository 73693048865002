<template>
  <ib-sheet
    :uid="_uid"
    :options="Options"
    :loadSearchData="loadSearchData"
    @loadSheet="loadSheet"
    :events="{onSearchFinish, onAfterChange}"
  />
</template>

<script>
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import options from './sheetOption/ContExecContactSheet.js';
import {selectContContexecPlanD} from '../api/dtlsCntc.js';
export default {
  beforeCreate() {
    $mapGetters(this, ['contExecContactMap', 'contContexecPlanDList', 'linked']);

    $mapMutations(this, [
      'setContExecContactMap',
      'setContExeccontPlanDList',
      'setDeleteContContexecPlanDList',
      'setDeleteContExeccontPlanDList',
      'setLinked',
    ]);
    $mapActions(this, ['setContContexecPlanDList']);
  },
  created() {
    let event = [
      {name: 'contExecContactAddrow', func: this.contExecContactAddrow},
      {name: 'selectContContexecPlanD', func: this.onSearch},
      {name: 'contexecContactFocus', func: this.contexecContactFocus},
      {name: 'initContContexecPlanD', func: this.initContContexecPlanD},
    ];
    this.addEvent(event);
  },
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
    };
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onSearchFinish() {
      this.sheetMerge();
      const result = this.validate();
      this.setLinked(result);
      this.sheet.setAttribute(null, 'chk', 'CanEdit', !result, 1);
    },
    onAfterChange(e) {
      let row;
      if (e.col == 'chk') {
        if (e.val) {
          // 체크시
          row = e.row;
          if (!this.contExecContactMap[row.midCodeSi + row.itemSeqSi])
            this.contExecContactMap[row.midCodeSi + row.itemSeqSi] = [];
          this.contExecContactMap[row.midCodeSi + row.itemSeqSi] = [].concat(
            this.contExecContactMap[row.midCodeSi + row.itemSeqSi],
            [row]
          );
        } else {
          row = e.row;
          delete this.contExecContactMap[row.midCodeSi + row.itemSeqSi];
        }
        this.setContExecContactMap(this.contExecContactMap);
      }
    },
    async onSearch(param) {
      const response = await selectContContexecPlanD(param);
      this.setContContexecPlanDList(response.data);
      this.loadSearchData = response.data;
    },

    contExecContactAddrow(data) {
      let sData = [];
      for (let execData of data.execData) {
        sData.push({
          pgmCd: this.pgmCd,
          prjCd: this.prjCd,
          wcodeDg: data.contData.wcode,
          headCodeDg: data.contData.headCode,
          midCodeDg: data.contData.midCode,
          mgNameDg: data.contData.mgName,
          chgDegreeDg: data.contData.chgDegree,
          itemSeqDg: data.contData.itemSeq,
          itemCodeDg: data.contData.itemCode,
          itemNameDg: data.contData.itemName,
          ssizeDg: data.contData.ssize,
          unitDg: data.contData.unit,
          qtyDg: data.contData.qty,
          amtDg: data.contData.amt,
          priceDg: data.contData.price,
          matGiveYn: data.contData.matGiveYn,

          wcodeSi: execData.wcode,
          headCodeSi: execData.headCode,
          midCodeSi: execData.midCode,
          mgNameSi: execData.mgName,
          chgDegreeSi: execData.chgDegree,
          itemSeqSi: execData.itemSeq,
          itemCodeSi: execData.itemCode,
          itemNameSi: execData.itemName,
          ssizeSi: execData.ssize,
          unitSi: execData.unit,
          execPrice: execData.price,
          execQty: execData.qty,
          execAmt: execData.amt,
          costType: execData.costType,
          cud: 1,
        });
      }

      let oriSheetDataRows = [];
      this.sheet.getDataRows().forEach(row => {
        oriSheetDataRows.push({
          pgmCd: this.pgmCd,
          prjCd: this.prjCd,
          wcodeDg: row.wcodeDg,
          headCodeDg: row.headCodeDg,
          midCodeDg: row.midCodeDg,
          mgNameDg: row.mgNameDg,
          chgDegreeDg: row.chgDegreeDg,
          itemSeqDg: row.itemSeqDg,
          itemCodeDg: row.itemCodeDg,
          itemNameDg: row.itemNameDg,
          ssizeDg: row.ssizeDg,
          unitDg: row.unitDg,
          qtyDg: row.qtyDg,
          priceDg: row.priceDg,
          amtDg: row.amtDg,
          matGiveYn: row.matGiveYn,

          wcodeSi: row.wcodeSi,
          headCodeSi: row.headCodeSi,
          midCodeSi: row.midCodeSi,
          mgNameSi: row.mgNameSi,
          chgDegreeSi: row.chgDegreeSi,
          itemSeqSi: row.itemSeqSi,
          itemCodeSi: row.itemCodeSi,
          itemNameSi: row.itemNameSi,
          ssizeSi: row.ssizeSi,
          unitSi: row.unitSi,
          execPrice: row.execPrice,
          execQty: row.execQty,
          execAmt: row.execAmt,
          cud: row.cud,
        });
      });

      let mergeArray = oriSheetDataRows.concat(sData);
      mergeArray = sortBy(mergeArray, ['wcodeDg', 'headCodeDg', 'midCodeDg', 'chgDegreeDg', 'itemSeqDg']);
      mergeArray = uniqBy(
        mergeArray,
        row =>
          row.prjCd +
          row.wcodeDg +
          row.headCodeDg +
          row.midCodeDg +
          row.chgDegreeDg +
          row.itemSeqDg +
          row.wcodeSi +
          row.headCodeSi +
          row.midCodeSi +
          row.chgDegreeSi +
          row.itemSeqSi
      );
      this.sheet.loadSearchData(mergeArray, 0);
      this.sheetMerge();

      const _contExecContactMap = {};
      if (this.sheet.getDataRows().length) {
        this.sheet.getDataRows().forEach(row => {
          if (row.cud == 1) {
            if (!_contExecContactMap[row.midCodeDg + row.itemSeqDg])
              _contExecContactMap[row.midCodeDg + row.itemSeqDg] = [];
            _contExecContactMap[row.midCodeDg + row.itemSeqDg] = [].concat(
              _contExecContactMap[row.midCodeDg + row.itemSeqDg],
              [row]
            );
          }
        });
      }

      this.setContExecContactMap(_contExecContactMap);

      if (typeof data.callback == 'function') {
        data.callback();
      }
    },
    sheetMerge() {
      let Rows = this.sheet.getDataRows();
      let key = '';
      let sRow;
      let eRow;
      let mergeInfo = {};
      Rows.forEach(row => {
        if (key != String(row.midCodeDg) + String(row.itemSeqDg)) {
          key = String(row.midCodeDg) + String(row.itemSeqDg);
          sRow = row.id;
          mergeInfo[sRow] = sRow;
        } else {
          eRow = row.id;
          mergeInfo[sRow] = eRow;
        }
      });

      for (let m in mergeInfo) {
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'mgNameDg', this.sheet.getRowById(mergeInfo[m]), 'mgNameDg');
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'itemSeqDg',
          this.sheet.getRowById(mergeInfo[m]),
          'itemSeqDg'
        );
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'itemNameDg',
          this.sheet.getRowById(mergeInfo[m]),
          'itemNameDg'
        );
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'ssizeDg', this.sheet.getRowById(mergeInfo[m]), 'ssizeDg');
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'amtDg', this.sheet.getRowById(mergeInfo[m]), 'amtDg');
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'qtyDg', this.sheet.getRowById(mergeInfo[m]), 'qtyDg');
      }
      this.sheet.acceptChangedData();
    },
    validate() {
      return this.sheet.getDataRows().filter(row => !row.itemYul).length === 0;
    },
    contexecContactFocus(row) {
      this.sheet.util.listSearch(['itemSeqDg'], row.itemSeq, true);
    },
    initContContexecPlanD() {
      this.sheet.removeAll();
    },
  },
};
</script>
