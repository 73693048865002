export const getScopeInRows = (sheet, clCode = 'midCode', focusRow) => {
  let isScope = false;
  const scopedList = [];
  const focusedRow = focusRow ? focusRow : sheet.getFocusedRow();
  if (clCode === 'midCode' && !focusedRow[clCode]) {
    return scopedList;
  }
  const startRow = !focusedRow.itemSeq
    ? focusedRow
    : sheet.getDataRows().find(row => row[clCode] === focusedRow[clCode] && !row.itemSeq);
  sheet.getDataRows().some(row => {
    if (row.id === startRow.id) {
      isScope = true;
      return false;
    }
    if (isScope) {
      if (startRow[clCode] !== row[clCode]) {
        return true;
      }
      scopedList.push(row);
    }
  });
  return scopedList;
};

export const getDuplicateRow = (sheet, key, value) => {
  let isDup = false;
  let rows = getScopeInRows(sheet);
  rows.some(row => {
    if (row[key] === value) {
      isDup = true;
      return true;
    }
  });
  return isDup;
};

export const getNextRow = sheet => {
  const costTypeRows = sheet.getDataRows().filter(row => !row.itemSeq);
  const nextIndex = costTypeRows.findIndex(item => item.midCode === sheet.getFocusedRow().midCode) + 1;
  return costTypeRows[nextIndex];
};

export const incrementValue = 10;

export const nextItemSeq = sheet => {
  const rows = getScopeInRows(sheet);
  const maxItemSeq =
    Number(
      rows.length &&
        (rows.sort((a, b) => {
          const aItemSeq = a.itemSeq ?? 0;
          const bItemSeq = b.itemSeq ?? 0;
          if (Number(aItemSeq) > Number(bItemSeq)) {
            return -1;
          }
          if (Number(aItemSeq) < Number(bItemSeq)) {
            return 1;
          }
          return 0;
        })[0]?.itemSeq ??
          0)
    ) + 1 || 0;

  return $getMultiple(maxItemSeq, incrementValue);
};

export const onFormula = (sheet, isBrkDw) => {
  sheet.getDataRows().some(row => {
    if (row.formula) {
      let amtObj = {};
      let formula;
      let typeMap = {};
      typeMap[$getConstants('COST_TYPE_M').code] = 1;
      typeMap[$getConstants('COST_TYPE_L').code] = 2;
      typeMap[$getConstants('COST_TYPE_E').code] = 3;
      sheet
        .getDataRows()
        .filter(row2 => (isBrkDw ? true : row.midCode === row2.midCode && !!row2.itemSeq))
        .some(row2 => {
          if (isBrkDw) {
            if (!row2.itemCode2 || String(row2.itemCode2).startsWith('F')) {
              return false;
            }
          } else {
            if (!row2.itemCode || String(row2.itemCode).startsWith('S')) {
              return false;
            }
          }

          let formatArray = [];
          let format;
          formula = row.formula.replace(/ /g, '');
          do {
            const pattern = `[${$getConstants('COST_TYPE_M').code}|${$getConstants('COST_TYPE_L').code}|${
              $getConstants('COST_TYPE_E').code
            }|${$getConstants('COST_TYPE_A').code}|${$getConstants('COST_TYPE_T').code}]`;
            format = RegExp(`${pattern}"([^"])*"|${pattern}`).exec(formula);
            if (format) {
              formatArray.push(format[0]);
              formula = formula.replace(formatArray[formatArray.length - 1], `{${typeMap[format[0].slice(0, 1)]}}`);
            }
          } while (format);
          formatArray.forEach(e1 => {
            if (e1.indexOf('"') != -1) {
              let costType = e1.slice(0, 1);
              let costFormat = e1.slice(1).replace(/"/g, '');
              if (row2.costType == costType) {
                costFormat.split(',').forEach(e2 => {
                  if (e2.indexOf('-') != -1) {
                    if (
                      Number(row2.itemSeq) >= Number(e2.split('-')[0]) &&
                      Number(row2.itemSeq) <= Number(e2.split('-')[1])
                    ) {
                      if (costType == $getConstants('COST_TYPE_M').code) {
                        amtObj[e1] = (amtObj[e1] || 0) + (row2.matAmt || 0);
                      } else if (costType == $getConstants('COST_TYPE_L').code) {
                        amtObj[e1] = (amtObj[e1] || 0) + (row2.labAmt || 0);
                      } else if (
                        costType == $getConstants('COST_TYPE_E').code ||
                        costType == $getConstants('COST_TYPE_A').code
                      ) {
                        amtObj[e1] = (amtObj[e1] || 0) + (row2.amtPrice || 0);
                      } else {
                        amtObj[e1] = (amtObj[e1] || 0) + (row2.matAmt || 0) + (row2.labAmt || 0) + (row2.amtPrice || 0);
                      }
                    }
                  } else {
                    if (row2.itemSeq == e2) {
                      if (costType == $getConstants('COST_TYPE_M').code) {
                        amtObj[e1] = (amtObj[e1] || 0) + (row2.matAmt || 0);
                      } else if (costType == $getConstants('COST_TYPE_L').code) {
                        amtObj[e1] = (amtObj[e1] || 0) + (row2.labAmt || 0);
                      } else if (
                        costType == $getConstants('COST_TYPE_E').code ||
                        costType == $getConstants('COST_TYPE_A').code
                      ) {
                        amtObj[e1] = (amtObj[e1] || 0) + (row2.amtPrice || 0);
                      } else {
                        amtObj[e1] = (amtObj[e1] || 0) + (row2.matAmt || 0) + (row2.labAmt || 0) + (row2.amtPrice || 0);
                      }
                    }
                  }
                });
              }
            } else {
              let costType = e1;
              if (row2.costType == e1 || e1 == $getConstants('COST_TYPE_T').code) {
                if (costType == $getConstants('COST_TYPE_M').code) {
                  amtObj[e1] = (amtObj[e1] || 0) + (row2.matAmt || 0);
                } else if (costType == $getConstants('COST_TYPE_L').code) {
                  amtObj[e1] = (amtObj[e1] || 0) + (row2.labAmt || 0);
                } else if (
                  costType == $getConstants('COST_TYPE_E').code ||
                  costType == $getConstants('COST_TYPE_A').code
                ) {
                  amtObj[e1] = (amtObj[e1] || 0) + (row2.amtPrice || 0);
                } else {
                  amtObj[e1] = (amtObj[e1] || 0) + (row2.matAmt || 0) + (row2.labAmt || 0) + (row2.amtPrice || 0);
                }
              }
            }
          });

          formatArray = [];
          formula = row.formula.replace(/ /g, '');
          do {
            const pattern = `[${$getConstants('COST_TYPE_M').code}|${$getConstants('COST_TYPE_L').code}|${
              $getConstants('COST_TYPE_E').code
            }|${$getConstants('COST_TYPE_A').code}|${$getConstants('COST_TYPE_T').code}]`;
            format = RegExp(`${pattern}"([^"])*"|${pattern}`).exec(formula);
            if (format) {
              formatArray.push(format[0]);
              formula = formula.replace(formatArray[formatArray.length - 1], amtObj[format[0]]);
            }
          } while (format);

          let price;
          if (!formula) {
            price = 0;
          } else {
            price = Number($_strToCalculator(formula).toFixed(3));
          }

          if (row.costType == $getConstants('COST_TYPE_M').code) {
            sheet.setValue(row, 'matPrice', price, 1);
            sheet.setValue(row, 'matAmt', row.qty * price, 1);
          } else if (row.costType == $getConstants('COST_TYPE_L').code) {
            sheet.setValue(row, 'labPrice', price, 1);
            sheet.setValue(row, 'labAmt', row.qty * price, 1);
          } else if (
            row.costType == $getConstants('COST_TYPE_E').code ||
            row.costType == $getConstants('COST_TYPE_A').code
          ) {
            sheet.setValue(row, 'equipPrice', price, 1);
            sheet.setValue(row, 'equipAmt', row.qty * price, 1);
          }
        });
    }
  });
};

export const onCalculator = (sheet, prjCd) => {
  const sumObj = {};
  sheet
    .getDataRows()
    .filter(row => !row.workType)
    .some(row => {
      if (!sumObj[prjCd]) {
        sumObj[prjCd] = {
          qty: 0,
          matPrice: 0,
          matAmt: 0,
          labPrice: 0,
          labAmt: 0,
          equipPrice: 0,
          equipAmt: 0,
          oprPrice: 0,
          oprAmt: 0,
          price: 0,
          amt: 0,
        };
      }
      if (!sumObj[row.wcode]) {
        sumObj[row.wcode] = {
          qty: 0,
          matPrice: 0,
          matAmt: 0,
          labPrice: 0,
          labAmt: 0,
          equipPrice: 0,
          equipAmt: 0,
          oprPrice: 0,
          oprAmt: 0,
          price: 0,
          amt: 0,
        };
      }
      if (!sumObj[row.headCode]) {
        sumObj[row.headCode] = {
          qty: 0,
          matPrice: 0,
          matAmt: 0,
          labPrice: 0,
          labAmt: 0,
          equipPrice: 0,
          equipAmt: 0,
          oprPrice: 0,
          oprAmt: 0,
          price: 0,
          amt: 0,
        };
      }
      if (!sumObj[row.midCode]) {
        sumObj[row.midCode] = {
          qty: 0,
          matPrice: 0,
          matAmt: 0,
          labPrice: 0,
          labAmt: 0,
          equipPrice: 0,
          equipAmt: 0,
          oprPrice: 0,
          oprAmt: 0,
          price: 0,
          amt: 0,
        };
      }

      if (sumObj[prjCd]) {
        sumObj[prjCd]['qty'] += row.qty;
        sumObj[prjCd]['matPrice'] += row.matPrice;
        sumObj[prjCd]['matAmt'] += row.matAmt;
        sumObj[prjCd]['labPrice'] += row.labPrice;
        sumObj[prjCd]['labAmt'] += row.labAmt;
        sumObj[prjCd]['equipPrice'] += row.equipPrice;
        sumObj[prjCd]['equipAmt'] += row.equipAmt;
        sumObj[prjCd]['oprPrice'] += row.oprPrice;
        sumObj[prjCd]['oprAmt'] += row.oprAmt;
        sumObj[prjCd]['price'] += row.price;
        sumObj[prjCd]['amt'] += row.amt;
      }

      if (sumObj[row.wcode]) {
        sumObj[row.wcode]['qty'] += row.qty;
        sumObj[row.wcode]['matPrice'] += row.matPrice;
        sumObj[row.wcode]['matAmt'] += row.matAmt;
        sumObj[row.wcode]['labPrice'] += row.labPrice;
        sumObj[row.wcode]['labAmt'] += row.labAmt;
        sumObj[row.wcode]['equipPrice'] += row.equipPrice;
        sumObj[row.wcode]['equipAmt'] += row.equipAmt;
        sumObj[row.wcode]['oprPrice'] += row.oprPrice;
        sumObj[row.wcode]['oprAmt'] += row.oprAmt;
        sumObj[row.wcode]['price'] += row.price;
        sumObj[row.wcode]['amt'] += row.amt;
      }

      if (sumObj[row.headCode]) {
        sumObj[row.headCode]['qty'] += row.qty;
        sumObj[row.headCode]['matPrice'] += row.matPrice;
        sumObj[row.headCode]['matAmt'] += row.matAmt;
        sumObj[row.headCode]['labPrice'] += row.labPrice;
        sumObj[row.headCode]['labAmt'] += row.labAmt;
        sumObj[row.headCode]['equipPrice'] += row.equipPrice;
        sumObj[row.headCode]['equipAmt'] += row.equipAmt;
        sumObj[row.headCode]['oprPrice'] += row.oprPrice;
        sumObj[row.headCode]['oprAmt'] += row.oprAmt;
        sumObj[row.headCode]['price'] += row.price;
        sumObj[row.headCode]['amt'] += row.amt;
      }

      if (sumObj[row.midCode]) {
        sumObj[row.midCode]['qty'] += row.qty;
        sumObj[row.midCode]['matPrice'] += row.matPrice;
        sumObj[row.midCode]['matAmt'] += row.matAmt;
        sumObj[row.midCode]['labPrice'] += row.labPrice;
        sumObj[row.midCode]['labAmt'] += row.labAmt;
        sumObj[row.midCode]['equipPrice'] += row.equipPrice;
        sumObj[row.midCode]['equipAmt'] += row.equipAmt;
        sumObj[row.midCode]['oprPrice'] += row.oprPrice;
        sumObj[row.midCode]['oprAmt'] += row.oprAmt;
        sumObj[row.midCode]['price'] += row.price;
        sumObj[row.midCode]['amt'] += row.amt;
      }
    });

  sheet
    .getDataRows()
    .filter(row => row.workType)
    .forEach(row => {
      if (sumObj[row.itemCode]) {
        for (let [key, value] of Object.entries(sumObj[row.itemCode])) {
          sheet.setValue(row, key, value, 1);
        }
      }
    });
};

export const updatePriceAsSameItem = e => {
  if (!e.col.toLowerCase().includes('price')) {
    return;
  }
  e.sheet
    .getDataRows()
    .filter(row => row.itemCode === e.row.itemCode && row.id !== e.row.id)
    .forEach(row => {
      e.sheet.setValue(row, e.col, e.val, 1);
    });
};
