var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pmis-content-box',{attrs:{"loading":_vm.loading}},[_c('ib-sheet',{attrs:{"uid":_vm._uid,"options":_vm.Options,"loadSearchData":_vm.loadSearchData,"autoRender":false,"init":_vm.init,"events":{
      onSearchFinish: _vm.onSearchFinish,
      onMouseOver: _vm.onMouseOver,
      onRenderFirstFinish: _vm.onRenderFirstFinish,
    }},on:{"update:init":function($event){_vm.init=$event},"loadSheet":_vm.loadSheet}}),(_vm.planInfo.show)?_c('div',{staticClass:"planInfo",style:({top: _vm.planInfo.top, left: _vm.planInfo.left, width: _vm.planInfo.width, height: _vm.planInfo.height})},[_c('iui-container-new',{attrs:{"type":"table","theme":"bullet"}},[_c('i-row',[_c('i-col-header',[_vm._v("시작예정일")]),_c('i-col',{attrs:{"colspan":"5"}},[_c('iui-datepicker',{attrs:{"name":"planStrdate","value":String(_vm.detailInfo.planStrdate),"readonly":true}})],1),_c('i-col-header',[_vm._v("실제시작일")]),_c('i-col',{attrs:{"colspan":"5"}},[_c('iui-datepicker',{attrs:{"name":"strResultDate","value":String(_vm.detailInfo.strResultDate),"readonly":true}})],1)],1),_c('i-row',[_c('i-col-header',[_vm._v("종료예정일")]),_c('i-col',{attrs:{"colspan":"5"}},[_c('iui-datepicker',{attrs:{"name":"planEnddate","value":String(_vm.detailInfo.planEnddate),"readonly":true}})],1),_c('i-col-header',[_vm._v("실제종료일")]),_c('i-col',{attrs:{"colspan":"5"}},[_c('iui-datepicker',{attrs:{"name":"endResultDate","value":_vm.detailInfo.planCnt == _vm.detailInfo.resultQty ? String(_vm.detailInfo.endResultDate) : '',"readonly":true}})],1)],1),_c('i-row',[_c('i-col-header',[_vm._v("작업일수")]),_c('i-col',{attrs:{"colspan":"5"}},[_c('iui-text',{attrs:{"type":"number","name":"workCnt","value":_vm.detailInfo.workCnt,"suffix":' 일',"readonly":true,"width":"60px"}})],1),_c('i-col-header',[_vm._v("공정율")]),_c('i-col',{attrs:{"colspan":"5"}},[_c('iui-text',{attrs:{"name":"rt","value":_vm.detailInfo.rt
                ? parseFloat(_vm.detailInfo.rt.toFixed(2)) <= 100
                  ? parseFloat(_vm.detailInfo.rt.toFixed(2))
                  : 100
                : 0,"suffix":' %',"readonly":true,"width":"70px"}})],1)],1),_c('i-row',[_c('i-col-header',[_vm._v("잔여일수")]),_c('i-col',{attrs:{"colspan":"5"}},[_c('iui-text',{attrs:{"type":"number","name":"remndrCnt","value":_vm.detailInfo.remndrCnt > 0 ? _vm.detailInfo.remndrCnt : 0,"suffix":' 일',"readonly":true,"width":"60px"}})],1),_c('i-col-header',[_vm._v("지연일수")]),_c('i-col',{attrs:{"colspan":"5"}},[_c('iui-text',{attrs:{"type":"number","name":"delayCnt","value":_vm.detailInfo.delayCnt,"suffix":' 일',"readonly":true,"width":"60px"}})],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }