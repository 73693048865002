<template>
  <iui-tab
    :comps="tabComps"
    :visibles="tabVisibles"
    :isAuto="false"
    @click="onClickTab"
    @after-active-tab="onAfterActiveTab"
  >
    <template #tab-right v-if="tabIndex === 0">
      <!-- 계약작성중 -->
      <template v-if="poStatus.isPoReady">
        <iui-button value="계약서보기" @click="openContract" />
        <iui-button value="계약품의" @click="openApproval" />
      </template>

      <!-- 계약품의완료 -->
      <template v-if="poStatus.isPoApprovalComplete">
        <iui-button value="계약서수정" @click="mdfyContract" />
        <iui-button :value="`계약서${poStatus.isReturnY ? '재' : ''}송부`" @click="sendContract" />
      </template>

      <!-- 거래처전자서명중 -->
      <template v-if="poStatus.isPoSend">
        <iui-button value="계약서송부취소" @click="onChkPoState2('sendCancel', poInfo)" />
      </template>

      <!-- 거래처전자서명 -->
      <template v-if="poStatus.isPoSignComplete">
        <iui-button value="계약서전자서명" @click="onAddSign" v-if="poStatus.isSuFileAllPass" />
      </template>

      <!-- 계약완료 -->
      <template v-if="poStatus.isPoComplete">
        <iui-button value="차수복사" @click="onCopy" v-if="!poStatus.isPoOrder" />
      </template>

      <ApprovalPop :parentId="_uid" @approvalEmit="onApprovalEmit" />

      <iui-modal name="ReportViewerModal" title="계약서" size-type="size6" @closed="ozContent = {}">
        <ReportViewer :fomNo="fomNo" :ozContent="ozContent" />
      </iui-modal>

      <iui-modal name="poRejectModal" :title="'계약서 반송'" :btns="poRejectModalBtns" size-type="size4">
        <PoRejectBuyer />
      </iui-modal>
    </template>
  </iui-tab>
</template>

<script>
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import PoActionMixin from '@/view/bidding/js/poActionMixin.js';
import BiddingApprovalMixin from '@/view/bidding/js/biddingApprovalMixin.js';
import {
  selectContractContentsForPrint,
  selectCheckPoState,
  mdfyContract,
  sendPo,
  sendCancel,
  addSign,
  copyPo,
} from '@/view/bidding/po/mat/api/po.js';

import PoRejectBuyer from '@/view/bidding/po/common/components/PoRejectBuyer.vue';

export default {
  components: {PoRejectBuyer},
  mixins: [PoActionMixin, BiddingApprovalMixin],
  data() {
    return {
      tabIndex: 0,
      tabComps: [
        {
          tabNm: '계약정보',
          compNm: 'ContractInfo',
          path: '@/view/bidding/po/mat/components/ContractInfo.vue',
        },
        {
          tabNm: '인지세',
          compNm: 'ContractStampList',
          path: '@/view/bidding/poManage/contract/components/stamp/ContractStampInfo.vue',
        },
      ],

      poRejectModalBtns: [{name: '반송', callback: this.rejectPo}],
      fomNo: '',
      ozContent: {},
    };
  },
  computed: {
    tabVisibles() {
      const isVisibleStamp = this.poStatus.isStamp && this.poStatus.isExistPo;
      if (!isVisibleStamp) {
        this.onClickTab(0);
      }
      return {ContractStampList: isVisibleStamp};
    },
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'poItemList', 'poStatus', 'bidType']);
    $mapActions(this, ['searchPoContSeqList']);
  },
  methods: {
    onClickTab(index) {
      if (this.tabIndex === index) {
        return;
      }
      if (index === 1 && !this.poStatus.isExistPo) {
        this.$alert({title: '인지세', message: '계약정보를 먼저 생성해야 합니다.'});
        return;
      }
      this.tabIndex = index;
      this.callEvent({name: 'setActiveTab', param: this.tabIndex});
    },
    onAfterActiveTab(index) {
      const isStampTab = index === 1;
      this.canNew = !isStampTab;
      this.canSave = !isStampTab && this.poStatus.isPoReady;
      this.canDelete = !isStampTab && this.poStatus.isPoReady;
    },
    async openContract() {
      selectContractContentsForPrint(this.poInfo.poNo, this.poInfo.contSeq).then(response => {
        this.fomNo = response.data.fomNo;
        this.ozContent = JSON.parse(response.data.contents);
        this.$modal.show('ReportViewerModal');
      });
    },
    openApproval() {
      // 변경사유
      if (this.poStatus.isChangeContSeq && !this.poInfo.chresonRmrk) {
        this.$alert({title: '변경사유', message: '변경사유를 입력 및 저장을 해야 합니다.'});
        return false;
      }

      this.openPoApproval();
    },
    async sendContract() {
      const title = '계약서 송부';
      const message = '계약서를 송부하시겠습니까?';

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      sendPo(this.poInfo.poNo, this.poInfo.contSeq)
        .then(response => {
          if (response.status == 200) {
            this.callEvent({name: 'ContractList_Search', param: response.data.poNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            let msg = ex.message;
            if (ex.description) {
              msg += `<br/>- ${ex.description}`;
            }
            this.$alert({title: ex.title, message: msg});
          }
        });
    },
    /**
     * 계약서 송부취소 (회수)
     */
    async sendCancel() {
      const title = '계약서 송부취소';
      const message = `계약서 송부를 취소하는 기능입니다.
      <br/><br/>협력사에 <font color="#FF0000">이미 계약서가 통보</font>되었으므로
      <br/>가능한 협력사에게 취소 사유를 전화 등으로
      <br/>미리 통지하시기 바랍니다.
      <br/><br/>계약서 송부를 취소하시겠습니까?`;

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      sendCancel(this.poInfo.poNo, this.poInfo.contSeq)
        .then(response => {
          if (response.status == 200) {
            this.callEvent({name: 'ContractList_Search', param: response.data.poNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            let msg = ex.message;
            if (ex.description) {
              msg += `<br/>- ${ex.description}`;
            }
            this.$alert({title: ex.title, message: msg});
          }
        });
    },
    /**
     * 전자서명
     */
    async onAddSign() {
      const response = await selectCheckPoState({
        poNo: this.poInfo.poNo,
        contSeq: this.poInfo.contSeq,
        isSupplierPage: false,
        processCode: 'addSign',
      });
      const checkMessage = response.data;
      if (checkMessage) {
        this.$alert({title: '전자서명', message: checkMessage});
        return;
      }

      if (!(await COMMON_FUNCTION.checkCertdnRegistered(this, this.bidType))) return;

      try {
        await this.$secuKit.checkReady();
        await this.$secuKit.showDialog();

        const certType = 'SignCert';
        const certID = this.$secuKit.certListInfo.getCertID();
        const sourceString = removePEMHeader(removeCRLF(this.poInfo.signedData));

        // 1. 전자서명
        const r1 = await this.$secuKit.addPKCS7SignedData({certType, certID, sourceString});

        // 2. 인증서 정보 추출
        const r2 = await this.$secuKit.viewCertInfomationWithVID({certType, certID, isViewVID: '1'});

        // 3. 신원확인 검증
        const userDN = String(r2.userDN)
          .replace(/\s/gi, '')
          .toUpperCase();
        const certdn = String(this.userInfo.certdn)
          .replace(/\s/gi, '')
          .toUpperCase();

        if (userDN !== certdn) {
          this.$alert({
            title: '인증서 검증',
            message: '시스템에 등록된 인증서와 선택하신 인증서가 다릅니다.<br/> 다시 한 번 확인하시기 바랍니다.',
          });
          return;
        }

        // 4. 인증서 유효기간
        const date1 = new Date(r2.detailValidateTo).getTime();
        const date2 = new Date().getTime();
        if (date1 < date2) {
          this.$alert({
            title: '인증서 검증',
            message: '인증서의 유효기간이 만료되었습니다.<br/> 인증서를 확인하시기 바랍니다.',
          });
          return;
        }

        const param = {
          poNo: this.poInfo.poNo,
          contSeq: this.poInfo.contSeq,
          addSignedData: r1.addPKCS7SignedData,
          chrgUsrCertdn: r2.userDN,
        };

        addSign(param)
          .then(response => {
            if (response.status === 200) {
              this.callEvent({name: 'ContractList_Search', param: response.data.poNo});
            }
          })
          .catch(ex => {
            if (ex.pmisError) {
              let msg = ex.message;
              if (ex.description) {
                msg += `<br/>- ${ex.description}`;
              }
              this.$alert({title: ex.title, message: msg});
            }
          });
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * 계약서 반송
     */
    openPoRejectModal() {
      this.$modal.show('poRejectModal');
    },
    rejectPo() {
      this.callEvent({
        name: 'PoRejectBuyer_Reject',
        param: poNo => {
          this.$modal.hide('poRejectModal');

          this.callEvent({name: 'ContractList_Search', param: poNo});
        },
      });
    },
    async mdfyContract() {
      const title = '계약서수정';
      const message = `진행상태를 <b style="color: #FF0000">계약작성중</b> 상태로 변경합니다.
      <br/><br/>수정된 계약서는 <b style="color: #FF0000">전자결재 재품의</b>를 거친 이후
      <br/>송부하실 수 있습니다.
      <br/><br/>계약서를 수정하시겠습니까?`;

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      mdfyContract(this.poInfo.poNo, this.poInfo.contSeq)
        .then(response => {
          if (0 < response.data) {
            this.callEvent({name: 'ContractList_Search'});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            let msg = ex.message;
            if (ex.description) {
              msg += `<br/>- ${ex.description}`;
            }
            this.$alert({title: ex.title, message: msg});
          }
        });
    },
    /**
     * 차수복사
     */
    async onCopy() {
      const title = '차수복사';

      if (!this.poInfo.isFullPaymentStampTax) {
        const message = '인지세 납부를 완료해야 합니다.<br/>전자수입인지인 경우 인지발급까지 완료해야 합니다.';
        this.$alert({title, message});
        return;
      }

      if (this.poStatus.isPotypeUnit && 0 < this.poInfo.orderCount) {
        const message = '해당 계약의 발주가 존재하여 차수복사가 불가능합니다.';
        this.$alert({title, message});
        return;
      }

      if (!(await this.$confirm({title, message: '차수복사를 하시겠습니까?'}))) {
        return;
      }

      copyPo(this.poInfo.poNo, this.poInfo.contSeq)
        .then(response => {
          if (response.status == 200) {
            this.searchPoContSeqList(response.data.poNo);
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            let msg = ex.message;
            if (ex.description) {
              msg += `<br/>- ${ex.description}`;
            }
            this.$alert({title: ex.title, message: msg});
          }
        });
    },
    onApprovalEmit() {
      this.callEvent({name: 'ContractList_Search', param: this.poInfo.poNo});
    },
  },
};
</script>

<style></style>
