import {selectCheckPoState as selectCheckPoState_mat} from '@/view/bidding/po/mat/api/po.js';
import {selectCheckPoState as selectCheckPoState_sub} from '@/view/bidding/po/sub/api/subPo.js';
export default {
  methods: {
    /**
     * 다음 프로세스를 진행하기 전 계약상태 체크 (Supplier)
     * @param {*} processCode
     * @param {*} poInfo
     * @param {*} reSearchEventNm 재검색 이벤트명
     */
    onChkPoState1(processCode, poInfo, reSearchEventNm = '') {
      this.onCheckPoState(true, processCode, poInfo, reSearchEventNm);
    },

    /**
     * 다음 프로세스를 진행하기 전 계약상태 체크 (Buyer)
     * @param {*} processCode
     * @param {*} poInfo
     * @param {*} reSearchEventNm 재검색 이벤트명
     */
    onChkPoState2(processCode, poInfo, reSearchEventNm = '') {
      this.onCheckPoState(false, processCode, poInfo, reSearchEventNm);
    },

    /**
     * 다음 프로세스를 진행하기 전 계약상태 체크
     * @param {*} isSupplierPage
     * @param {*} processCode
     * @param {*} poInfo
     * @param {*} reSearchEventNm 재검색 이벤트명
     * @returns
     */
    async onCheckPoState(isSupplierPage, processCode, poInfo, reSearchEventNm) {
      const title = {
        reject: '반송',
        need: '구비서류제출',
        sign: '전자서명',
        confirmOrder: '발주서 확인',
        sendCancel: '송부취소',
        retrieveOrder: '발주서 회수 불가',
      };

      const param = {poNo: poInfo.poNo, contSeq: poInfo.contSeq, isSupplierPage, processCode};

      const selectCheckPoStateApi = {mat: selectCheckPoState_mat, sub: selectCheckPoState_sub}[this.bidType];
      const response = await selectCheckPoStateApi(param);
      const checkMessage = response.data;
      if (checkMessage) {
        this.$alert({title: title[processCode], message: `${checkMessage}`}, () => {
          this.callEvent({name: reSearchEventNm, param: poInfo.poNo});
        });
        return;
      }

      if (isSupplierPage) {
        if (processCode == 'reject') this.onOpenPopup('poRejectSupplierModal');
        if (processCode == 'need') this.onSubmitNeedFile();
        if (processCode == 'sign') this.onSign();
        if (processCode == 'confirmOrder') this.onConfirmOrder();
      } else {
        if (processCode == 'sendCancel') this.sendCancel();
        if (processCode == 'retrieveOrder') this.retrieveOrder();
      }
    },
  },
};
