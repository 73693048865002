<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px"><PrjList /></i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>입고목록</template>
            <pmis-content-box>
              <template v-slot:header-left>
                <label>입고기간</label>
                <iui-datepicker
                  name="searchIoDateFrom"
                  :value="searchInfo.searchIoDateFrom"
                  :suffix="'~'"
                  @change="searchInfo.searchIoDateFrom = $event"
                  :disabledDate="disabledDate"
                />
                <iui-datepicker
                  name="searchIoDateTo"
                  :value="searchInfo.searchIoDateTo"
                  @change="searchInfo.searchIoDateTo = $event"
                  :disabledDate="disabledDate"
                />
                <iui-select
                  :items="selectItems"
                  :value="searchInfo.searchSe"
                  @change="value => (searchInfo.searchSe = value)"
                />
                <iui-text
                  type="search"
                  :value="searchInfo.searchNm"
                  @change="searchInfo.searchNm = $event.target.value"
                  @enter="onSearch"
                />
                <iui-button value="검색" @click="onSearch" />
              </template>
              <ib-sheet
                :uid="_uid"
                :options="Options"
                :loadSearchData="loadSearchData"
                :events="{
                  onRenderFirstFinish,
                  onSearchFinish,
                }"
                @loadSheet="loadSheet"
              />
            </pmis-content-box>
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
/**
 * 자재입고조회
 *   components :
 *     PrjList : 현장목록
 *
 * */
import PrjList from '@/view/Project/components/PrjList.vue';
import options from './components/sheetOptions/WarehousingViewSheet.js';
import {selectWarehousingDetailList} from './api/warehousingView.js';

export default {
  components: {
    PrjList,
  },
  data() {
    return {
      Options: options(this),
      loadSearchData: [],
      searchInfo: {
        pgmCd: '',
        prjCd: '',
        searchIoDateFrom: '',
        searchIoDateTo: '',
        searchSe: 'matNm',
        searchNm: '',
      },
      selectItems: [
        {value: 'matNm', text: '자재명'},
        {value: 'matCd', text: '자재코드'},
        {value: 'custNm', text: '거래처명'},
      ],
    };
  },
  created() {
    this.addFuncSearch(this.onInitSearchDate);
    this.addEvent([{name: 'set_searchInfo', func: this.onPrjChange}]);
  },
  methods: {
    disabledDate(date) {
      return $_getCurrentDate() < $_dateToStringYYMMDD(date);
    },
    onPrjChange(e) {
      this.searchInfo.pgmCd = this.pgmCd;
      this.searchInfo.prjCd = e.prjCd;
      this.onSearch();
    },
    onSearch() {
      const param = {...this.searchInfo};
      selectWarehousingDetailList(param).then(response => (this.loadSearchData = response.data));
    },
    onInitSearchDate() {
      const currDate = $_getCurrentDate();
      const frDate = new Date(currDate.substr(0, 4), currDate.substr(4, 2) - 1, currDate.substr(6, 2));
      const toDate = new Date(currDate.substr(0, 4), currDate.substr(4, 2) - 1, currDate.substr(6, 2));
      frDate.setMonth(frDate.getMonth() - 1);

      this.searchInfo.searchIoDateFrom = $_dateToStringYYMMDD(frDate);
      this.searchInfo.searchIoDateTo = $_dateToStringYYMMDD(toDate);
    },
    onRenderFirstFinish() {
      this.onInitSearchDate();
    },
    onSearchFinish(e) {
      if (0 < e.sheet.getTotalRowCount()) {
        this.sheet.makeSubTotal([
          {
            stdCol: 'ioDate',
            sumCols: 'amt',
            captionCol: [{col: 'ioDate', val: '소계'}],
          },
        ]);
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped></style>
