import {selectEqutMonDetailList, selectEqutMonList} from '../api/fixedtimeEquipment.js';
const state = {
  searchInfo: {
    investMm: '',
    custName: '',
  },
  detailInfo: {
    custCode: '',
    equipCode: '',
    carNo: '',
    monthlyDay: 0,
    monthlyQty: 0,
    monthlyAmt: 0,
    oilAmt: 0,
    oilDeductAmt: 0,
    etcDeductAmt: 0,
    amt: 0,
    vatAmt: 0,
    taxexe: 0,
    sumAmt: 0,
    billNo: '',
    flNo: 0,
    billTaxNo: '',
    flTaxNo: 0,
  },
  equtMonList: [],
  calculateList: [],
  closeYn: '',
};
const getters = {
  searchInfo: state => state.searchInfo,
  detailInfo: state => state.detailInfo,
  equtMonList: state => state.equtMonList,
  calculateList: state => state.calculateList,
  closeYn: state => state.closeYn,
  fixedTimeEqutSaveDataReqDto: state => {
    const keys = [
      'investMm',
      'reqDegree',
      'custCode',
      'equipCode',
      'carNo',
      'etcDeductAmt',
      'amt',
      'vatAmt',
      'taxexe',
      'sumAmt',
      'billNo',
      'flNo',
      'billTaxNo',
      'flTaxNo',
    ];
    let param = {};
    for (let key in state.searchInfo) {
      if (keys.findIndex(item => item === key) !== -1) {
        param[key] = state.searchInfo[key];
      }
    }
    for (let key in state.detailInfo) {
      if (keys.findIndex(item => item === key) !== -1) {
        param[key] = state.detailInfo[key];
      }
    }
    return param;
  },
};
const mutations = {
  setSearchInfo(state, payload) {
    for (let key in payload) {
      if (state.searchInfo.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  initDetailInfo(state) {
    let stringKeys = ['custCode', 'equipCode', 'carNo', 'repayfrDt', 'repaytoDt', 'billNo', 'billTaxNo'];
    for (let key in state.detailInfo) {
      state.detailInfo[key] = stringKeys.indexOf(key) === -1 ? 0 : '';
    }
  },
  setDetailInfo(state, payload) {
    let stringKeys = ['custCode', 'equipCode', 'carNo', 'repayfrDt', 'repaytoDt', 'billNo', 'billTaxNo'];
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = stringKeys.indexOf(key) === -1 ? Number(payload[key]) : payload[key];
      }
    }
  },
  setEqutMonList(state, payload) {
    state.equtMonList = payload;
  },
  initCalculateList(state) {
    state.calculateList = [];
  },
  setCalculateList(state, payload) {
    state.calculateList = payload;
  },
  setSumAmt(state) {
    state.detailInfo.sumAmt = $_getSumAmt(state.detailInfo.amt, state.detailInfo.vatAmt);
  },
  setCloseYn(state, closeYn) {
    state.closeYn = closeYn;
  },
};
const actions = {
  async selectEqutMonList({state, commit}) {
    commit('initDetailInfo');
    commit('initCalculateList');
    const response = await selectEqutMonList(state.searchInfo);
    const equtMonList = response.data;
    commit('setEqutMonList', equtMonList);
  },
  async selectEqutMonDetailList({state, commit}) {
    const param = {
      investMm: state.searchInfo.investMm,
      equipCode: state.detailInfo.equipCode,
      carNo: state.detailInfo.carNo,
      custCode: state.detailInfo.custCode,
    };
    const response = await selectEqutMonDetailList(param);
    let arrayDate = $_getArrayDate(state.searchInfo.investMm);
    let data = response.data;
    let list = [];
    arrayDate.forEach((date, index) => {
      if (index % 2 === 0) {
        let map = {};
        map['runDt1'] = date;
        map['runQty1'] = 0;
        map['runPrice1'] = 0;
        map['runAmt1'] = 0;
        map['oilAmt1'] = 0;
        map['oilDeductAmt1'] = 0;
        list.push(map);
      } else {
        let lastList = list[list.length - 1];
        lastList['runDt2'] = date;
        lastList['runQty2'] = 0;
        lastList['runPrice2'] = 0;
        lastList['runAmt2'] = 0;
        lastList['oilAmt2'] = 0;
        lastList['oilDeductAmt2'] = 0;
      }

      const validData = data.find(item => item.runDt === date);
      for (const [key, value] of Object.entries(validData ?? {})) {
        let no = Number(validData.runDt.substr(-2)) % 2 !== 0 ? '1' : '2';
        list[list.length - 1][`${key}${no}`] = value;
      }
    });
    commit('setCalculateList', list);
  },
  setEtcDeductAmt({state, commit}, etcDeductAmt) {
    let amt = state.detailInfo.monthlyAmt + state.detailInfo.oilAmt - state.detailInfo.oilDeductAmt - etcDeductAmt;
    let vatAmt = $_getVatAmt(amt, state.detailInfo.taxexe);
    commit('setDetailInfo', {
      etcDeductAmt,
      amt,
      vatAmt,
    });
    commit('setSumAmt');
  },
  setVatAmt({commit}, vatAmt) {
    vatAmt = Number(vatAmt || 0);
    commit('setDetailInfo', {vatAmt});
    commit('setSumAmt');
  },
  setTaxexe({state, commit}, taxexe) {
    taxexe = Number(taxexe || 0);
    let vatAmt = $_getVatAmt(state.detailInfo.amt, taxexe);
    commit('setDetailInfo', {vatAmt, taxexe});
    commit('setSumAmt');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
