import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';

export default that => {
  const preset = that.$ibsheetHelper.preset;
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const frmGb = userInfo.frmGb;
  const isConstructor = frmGb === $getConstants('FRM_GB_1').code;
  const custPrefix = isConstructor ? '협력사' : '거래처';

  return {
    Cfg: {CanEdit: 0},
    Def: {
      Row: {
        NoColor: 1,
        CalcOrder: 'stampTaxAmt,stampTaxOwnRate2,stampTaxOwnAmt,stampTaxOwnAmt2',
      },
    },
    Cols: [
      {
        Header: '변경차수',
        Name: 'contSeq',
        Type: 'Text',
        Align: 'center',
        Width: 90,
        CustomFormat: v => (parseInt(v) === 0 ? '당초' : `${parseInt(v)}차`),
      },
      {Header: '계약일자', Name: 'poDt', Width: 120, Extend: preset.date},
      {Header: '계약금액(VAT포함)', Name: 'totAmt', RelWidth: 1, Extend: preset.amount},
      {
        Header: '인지세(원)',
        Name: 'stampTaxAmt',
        RelWidth: 1,
        Formula: fr => COMMON_FUNCTION.onCalculateStampTaxAmt(fr.Row['totAmt']).stampTaxAmt,
        Extend: preset.amount,
      },
      {Header: '당사부담률(%)', Name: 'stampTaxOwnRate', Width: 120, Extend: preset.rate},
      {
        Header: '당사부담금(원)',
        Name: 'stampTaxOwnAmt',
        Formula: fr => fr.Row['stampTaxAmt'] * (fr.Row['stampTaxOwnRate'] / 100) || '-',
        RelWidth: 1,
        Extend: preset.amount,
        DefaultValue: 0,
      },
      {
        Header: `${custPrefix}부담률(%)`,
        Name: 'stampTaxOwnRate2',
        Width: 120,
        Extend: preset.rate,
      },
      {
        Header: `${custPrefix}부담금(원)`,
        Name: 'stampTaxOwnAmt2',
        Formula: fr => fr.Row['stampTaxAmt'] * (fr.Row['stampTaxOwnRate2'] / 100) || '-',
        RelWidth: 1,
        Extend: preset.amount,
        DefaultValue: 0,
      },
    ],
  };
};
