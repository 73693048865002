export default that => {
  return {
    Cfg: {
      FitWidth: 1,
      CanSort: 1,
    },
    Def: {
      Row: {
        CanFormula: 1,
        CalcOrder: 'TextColor',
        TextColorFormula: param => (param.Row['labtClose'] ? '#FF0000' : ''),
      },
    },
    Cols: [
      {
        Header: 'ID',
        Name: 'empNo',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
        Align: 'left',
      },
      {
        Header: '성명',
        Name: 'name',
        Type: 'Text',
        RelWidth: 2,
        CanEdit: 0,
        Align: 'left',
      },
      {
        Header: '주민번호',
        Name: 'regiNo',
        Type: 'Text',
        CanEdit: 0,
        Align: 'center',
        CustomFormat: 'IdNo' /* IdNo, IdNoMask (ibSheet - CustomFormat) */,
        Visible: false,
      },
      {
        Name: 'labtClose',
        Visible: false,
      },
    ],
  };
};
