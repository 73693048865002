export default that => {
  return {
    Cfg: {
      CanEdit: 0,
    },
    Cols: [
      {
        Header: '현장코드',
        Name: 'prjCd',
        Type: 'Text',
        Width: 80,
        Align: 'Center',
      },
      {
        Header: '현장명',
        Name: 'prjNm',
        Type: 'Text',
        RelWidth: 1,
      },
    ],
  };
};
