import Vue from 'vue';

/**
 *
 * @param {*} args.beforeValidator 유효성검사 (전)
 * @param {*} args.validator 유효성검사 (후)
 * @param {*} args.title confirm title
 * @param {*} args.message confirm message
 * @param {*} args.mode
 *  simple: 파일저장후 args.param에 flNo추가하여 저장
 *  step: callback이 필수로 필요하며 callback의 param을 통해 파일정보를 받은후 재가공하여 return 하면 가공한 데이터로 저장
 *  onlySave 파일업로드 없이 저장
 * @param {*} callback
 * @returns
 */
const save = async function(args = {}, callback) {
  args.mode = args.mode || 'simple';
  args.isValidate = args.isValidate === undefined ? true : args.isValidate;

  const promiseCallback = async resolve => {
    if (!saveValidation(args, callback)) return false;

    if (args.beforeValidator && !(await args.beforeValidator())) {
      resolve({status: 999});
      return;
    }
    if (args.isValidate) {
      this.$validator.sort();
      const valid = await this.$validator.validate(this);
      let title;
      let message;
      let key;
      let targetComponent;
      valid.validators.some(item => {
        if (!item.isValid) {
          title = item.errorMessage.title;
          message = item.errorMessage.defaultMessage;
          key = Object.keys(item.targetComponent.$refs)[0];
          targetComponent = item.targetComponent;
          return true;
        }
      });
      if (title || message) {
        await this.$alert({title: title, message: message}, () => {
          setTimeout(() => {
            if (key) targetComponent.$refs[key].focus();
          }, 100);
        });
        resolve({status: 999});
        return;
      }
      if (!valid.isValid) {
        resolve({status: 999});
        return;
      }
    }

    if (args.validator && !(await args.validator())) {
      resolve({status: 999});
      return;
    }

    const saveProc = async () => {
      return new Promise((resolve, reject) => {
        if (!args.param.hasOwnProperty('cud')) {
          args.param['cud'] = this.cud;
        }

        this.callEvent({name: 'showLoading'});

        axios
          .post(args.url, args.param)
          .then(response => {
            if (Object.keys(fileInfo).length === 0) {
              this.callEvent({name: 'hideLoading'});
              return resolve(response);
            } else {
              this.callEvent({name: 'hideLoading'});
              return resolve({...response, fileInfo: fileInfo});
            }
          })
          .catch(error => {
            this.callEvent({name: 'hideLoading'});
            reject(error);
          });
      });
    };

    if (args.message) {
      if (
        !(await this.$confirm({title: args.title, message: args.message}, () => {
          if (args.beforeSave) args.beforeSave(args.param);
        }))
      ) {
        resolve({status: 999});
        return;
      }
    } else {
      if (args.beforeSave) args.beforeSave(args.param);
    }

    let fileInfo = {};
    if (args.mode == 'step') {
      fileInfo = await this.$fileSave();
      const param = await callback(fileInfo);
      args.param = param;
      return resolve(saveProc());
    } else if (args.mode == 'simple') {
      fileInfo = await this.$fileSave();
      for (let key in fileInfo) {
        args.param[fileInfo[key].flNoCol] = fileInfo[key].flNo;
      }
      return resolve(saveProc());
    } else if (args.mode == 'onlySave') {
      return resolve(saveProc());
    }
  };

  return new Promise(promiseCallback);
};

const saveValidation = (args, callback) => {
  if (!args.url) {
    console.error('args... url이 필요합니다.');
    return false;
  }
  if (args.mode != 'step') {
    if (!args.param) {
      console.error('args... param이 필요합니다.');
      return false;
    }
  } else {
    if (!callback) {
      console.error('callback 함수가 필요합니다.');
      return false;
    }
  }
  return true;
};

const fileSave = function() {
  let totalCnt = 0;
  let curruentCnt = 0;
  let totalProgressObj = {};
  const fileComps = this.$store.getters['fileCompMap'];
  if (!fileComps) {
    return {};
  }
  fileComps.forEach(item => {
    totalCnt += Object.keys(item.interface.progressInfo).length;
  });

  return new Promise((resolve, reject) => {
    const setProgress = fileInterface => {
      if (fileInterface.progressInfo[fileInterface.currentId]) {
        return (totalProgressObj[fileInterface.currentId] = parseFloat(
          fileInterface.progressInfo[fileInterface.currentId].progress
        ));
      } else {
        return 0.0;
      }
    };
    const getFileNm = fileInterface => {
      if (fileInterface.progressInfo[fileInterface.currentId]) {
        return fileInterface.progressInfo[fileInterface.currentId].name;
      } else {
        return '';
      }
    };
    const sumTotalProgress = totalProgressObj => {
      let total = 0;
      for (let key in totalProgressObj) {
        total += totalProgressObj[key];
      }
      return total;
    };
    const upload = (index = 0, fileResultMap = {}) => {
      try {
        const next = () => {
          if (index < fileComps.length - 1) {
            upload(++index, fileResultMap);
          } else {
            setTimeout(() => {
              this.callEvent({name: 'hideFileProgress', isDefault: true});
              resolve(fileResultMap);
            }, 500);
          }
        };
        const fileComponent = fileComps[index];
        const fileInterface = fileComps[index].interface;
        fileInterface.state = state => {
          try {
            const progress = setProgress(fileInterface);
            const totalProgress = sumTotalProgress(totalProgressObj);
            const fileNm = getFileNm(fileInterface);
            switch (state) {
              case -1: //에러
                reject(fileInterface.error);
                setTimeout(() => {
                  this.callEvent({name: 'hideFileProgress', isDefault: true});
                }, 500);

                break;
              case 0: //
                next();
                break;
              case 1:
                this.callEvent({
                  name: 'fileProgress',
                  param: {
                    currentCnt: curruentCnt,
                    totalCnt: totalCnt,
                    progress: progress,
                    totalProgress: totalProgress,
                    fileNm: fileNm,
                  },
                  isDefault: true,
                });
                break;
              case 2:
                curruentCnt++;
                break;
              case 3:
                fileResultMap[fileComponent.id] = {
                  flNo: fileInterface.flNo,
                  files: fileInterface.files,
                  flNoCol: fileComponent.flNoCol,
                };

                this.callEvent({
                  name: 'fileProgress',
                  param: {
                    currentCnt: curruentCnt,
                    totalCnt: totalCnt,
                    progress: progress,
                    totalProgress: totalProgress,
                    fileNm: fileNm,
                  },
                  isDefault: true,
                });
                fileComponent.initInterface();
                next();
                break;
            }
          } catch (error) {
            this.callEvent({name: 'hideFileProgress', isDefault: true});
            reject(error);
          }
        };
        fileInterface.save();
      } catch (ex) {
        this.callEvent({name: 'hideFileProgress', isDefault: true});
        reject(ex);
      }
    };
    if (totalCnt) {
      this.callEvent({name: 'showFileProgress', isDefault: true});
      upload();
    } else {
      resolve({});
    }
  });
};

const addFileComponent = function(component) {
  this.$store.dispatch('addFileComponent', component);
};

const removeFileComponent = function(component) {
  this.$store.dispatch('removeFileComponent', component);
};

const saveUtil = function(Vue) {
  Object.defineProperties(Vue.prototype, {
    $save: {
      get() {
        return save;
      },
    },
    $fileSave: {
      get() {
        return fileSave;
      },
    },
    $addFileComponent: {
      get() {
        return addFileComponent;
      },
    },
    $removeFileComponent: {
      get() {
        return removeFileComponent;
      },
    },
  });
};
Vue.use(saveUtil);

export default saveUtil;
