const state = () => ({
  searchInfo: {
    resultDate: '', //실적일자
    planStrdate: '',
    planEnddate: '',
  },
});

const getters = {
  searchInfo: state => state.searchInfo,
};

const mutations = {
  setSearchInfo: (state, payload) => {
    for (let key in state.searchInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
