const state = {
  detailInfo: {
    equipCode: '', //장비코드
    itemName: '', //장비명
    size: '', //규격
    unit: '', //단위
    carNo: '', //차량번호
    useGb: '', //사용구분
    runPrice: '', //가동단가
    useOil: '', //사용유류
    oilDeductDs: '', //유류대공제여부
    rlseDate: '', //투입일자
    outDate: '', //철수일자
    custCode: '', //거래처코드
    frmNm: '', //거래처명
    rpsvNm: '', //대표자명
    rpsvPhone: '', //거래처전화번호
    lentDate: '', //임차일자
    insuStDt: '', //보험기간(시작일)
    insuEtDt: '', //보험기간(종료일)
    mainOperatorId: '', //기사_id
    mainOperatorNm: '', //기사_id
    name: '', //기사_성명
    mobileTlno: '', //기사_휴대폰번호
    mainOperatorNo: '', //기사_면허번호
    mainOperatorStrdate: '', //기사_면허기간_시작일
    mainOperatorEnddate: '', //기사_면허기간_종료일
    postNo: '', //우편번호
    adr1: '', //주소
    adr2: '', //상세주소
    rgprId: '', //등록자아이디
    rgsDttm: '', //등록일시
  },
  oriDetailInfo: {
    originCarNo: '', //수정전차량번호
  },
};

const getters = {
  searchInfo: state => state.searchInfo,
  detailInfo: state => state.detailInfo,
  oriDetailInfo: state => state.oriDetailInfo,
};

const actions = {};

const mutations = {
  initDetailInfo(state) {
    for (let detail in state.detailInfo) {
      state.detailInfo[detail] = '';
    }
  },

  setDetailInfo(state, payload) {
    for (let o in state.detailInfo) {
      if (payload && payload.hasOwnProperty(o)) {
        if (
          [
            'rlseDate',
            'outDate',
            'lentDate',
            'insuStDt',
            'insuEtDt',
            'mainOperatorStrdate',
            'mainOperatorEnddate',
            'subOperatorStrdate',
            'subOperatorEnddate',
          ].indexOf(o) != -1
        ) {
          if (String(payload[o]).length > 8) {
            state.detailInfo[o] = dateFormat(new Date(payload[o]));
          } else {
            state.detailInfo[o] = dateFormat(String(payload[o]));
          }
        } else {
          state.detailInfo[o] = payload[o];
        }
      }
    }
  },

  setSearchInfo(state, payload) {
    for (let o in payload) {
      state.searchInfo[o] = String(payload[o]);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
