<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="400px">
          <PrjEstCdList />
        </i-col>
        <i-spacer />
        <i-col>
          <DetailInfo />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
/**
 * 견적현장등록
 */
import store from './store/estmtPrjRegist.js';
import PrjEstCdList from './components/PrjEstCdList.vue';
import DetailInfo from './components/DetailInfo.vue';

export default {
  components: {PrjEstCdList, DetailInfo},
  beforeCreate() {
    $init(this, store);
  },
};
</script>

<style></style>
