<template>
  <div class="header-left">
    <iui-datepicker type="month" label="정산년월" :value="investMm" @change="onChangeAtInvestMm" />
    <iui-select label="차수" defaultCd="S" :value.sync="reqDegree" :items="reqDegreeSelectItems"></iui-select>
    <i class="prev-arrow" @click.prevent.self @click="prev" />
    <i class="post-arrow" @click.prevent.self @click="next" />
    <span class="ml5"></span>
    <iui-text type="search" label="외주명" :value.sync="custName" @keyup.enter="onSearch" />
    <iui-button value="검색" @click="onSearch" />
  </div>
</template>

<script>
import {selectAnyTimeSubSearchData} from '../api/anyTimeSubcon.js';
export default {
  props: {},
  data() {
    return {
      investMm: $_getYearMonth(),
      reqDegreeSelectItems: [],
      reqDegree: '',
      searchIndex: -1,
      custName: '',
      searchDatas: [],
    };
  },
  beforeCreate() {
    $mapGetters(this, 'subcon', ['closeYn']);
    $mapMutations(this, 'subcon', ['setSearchInfo']);
  },
  activated() {
    this.addFuncPrj(this.onSearchData);
  },
  methods: {
    onSearchData() {
      this.selectAnyTimeSubSearchData();
    },
    prev() {
      if (this.searchIndex < 1) {
        return;
      }
      this.searchIndex--;
      this.setSearchData();
    },
    next() {
      if (this.searchIndex >= this.searchDatas.length - 1) {
        return;
      }
      this.searchIndex++;
      this.setSearchData();
    },
    setSearchData() {
      const searchData = this.searchDatas[this.searchIndex];
      this.investMm = searchData?.investMm || '';
      this.reqDegree = searchData?.reqDegree || '';
      this.setReqDegreeSelectItems();
      this.onSearch();
    },
    onChangeAtInvestMm(investMm) {
      this.investMm = investMm;
      this.setReqDegreeSelectItems();
      this.initReqDegress();
      this.initSearchIndex();
    },
    initReqDegress() {
      let index = this.reqDegreeSelectItems.length - 1;
      this.reqDegree = this.reqDegreeSelectItems[index]?.value ?? '';
    },
    initSearchIndex() {
      const searchDatalength = this.searchDatas.length;
      if (!searchDatalength) {
        return;
      }
      if (this.investMm < this.searchDatas[0].investMm) {
        this.searchIndex = -1;
      } else if (this.investMm > this.searchDatas[searchDatalength - 1].investMm) {
        this.searchIndex = searchDatalength;
      } else {
        this.searchIndex = this.searchDatas.indexOf(
          this.searchDatas.filter(data => data.investMm === this.investMm).sort((a, b) => b.reqDegree - a.reqDegree)[0]
        );
      }
    },
    async selectAnyTimeSubSearchData() {
      const response = await selectAnyTimeSubSearchData();
      this.searchDatas = response.data;
      this.searchIndex = this.searchDatas.length - 1;
      this.setReqDegreeSelectItems();
      this.initSearchData();
      this.setSearchData();
    },
    initSearchData() {
      this.investMm = '';
      this.reqDegree = '';
      this.custName = '';
    },
    setReqDegreeSelectItems() {
      this.reqDegreeSelectItems = this.searchDatas
        .filter(item => item.investMm === this.investMm)
        .map(item => ({text: `${item.reqDegree}차`, value: `${item.reqDegree}`}));
    },
    onSearch() {
      this.setSearchInfo({
        investMm: this.investMm,
        reqDegree: this.reqDegree,
        custName: this.custName,
      });
      const lastSearchData = this.searchDatas[this.searchDatas.length - 1];
      this.canDelete =
        lastSearchData?.investMm === this.investMm && lastSearchData?.reqDegree === this.reqDegree && !this.closeYn;

      this.$emit('search');
    },
  },
};
</script>

<style scoped></style>
