<template>
  <iui-container-new type="css-flex">
    <pmis-content-box>
      <template v-slot:title>구성원정보</template>
      <iui-container-new type="table" theme="bullet">
        <colgroup>
          <col />
          <col />
          <col />
        </colgroup>
        <i-row>
          <i-col-header>ID</i-col-header>
          <i-col>
            <iui-text name="empNo" type="text" :value="workerInfo.empNo" width="150px" :readonly="true" />
          </i-col>
          <i-col rowspan="8">
            <pmis-content-box>
              <pmis-image-file
                id="photo"
                flNoCol="phoFlNo"
                :file-number.sync="workerInfo.phoFlNo"
                :allowAdd="false"
                :saveButtonVisible="false"
                :allowDelete="false"
              />
            </pmis-content-box>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>성명</i-col-header>
          <i-col>
            <iui-text name="name" type="text" :value="workerInfo.name" width="150px" :readonly="true" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>부서</i-col-header>
          <i-col>
            <iui-text name="blnDepNm" :value="workerInfo.blnDepNm" width="150px" :readonly="true" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>직책</i-col-header>
          <i-col>
            <iui-text :value="dtsNm" width="150px" :readonly="true" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>직급</i-col-header>
          <i-col>
            <iui-text :value="rollNm" width="150px" :readonly="true" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>전화번호</i-col-header>
          <i-col>
            <iui-text type="phone" name="tlno" :value="workerInfo.tlno" width="150px" :readonly="true" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>팩스번호</i-col-header>
          <i-col>
            <iui-text type="phone" name="rpsvFaxno" :value="workerInfo.rpsvFaxno" width="150px" :readonly="true" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>휴대폰번호</i-col-header>
          <i-col>
            <iui-text type="phone" name="mobileTlno" :value="workerInfo.mobileTlno" width="150px" :readonly="true" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header rowspan="2">주소</i-col-header>
          <i-col colspan="2">
            <iui-text name="postNo" :value="workerInfo.postNo" width="100px" :readonly="true" />
            <iui-text name="adr1" :value="workerInfo.adr2" style="width:calc(100%-100px)" :readonly="true" />
          </i-col>
        </i-row>
        <i-row>
          <i-col colspan="2">
            <iui-text name="adr2" :value="workerInfo.adr2" width="100%" :readonly="true" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>이메일</i-col-header>
          <i-col colspan="2">
            <iui-text type="email1" name="email1" :value="workerInfo.email1" width="110px" :readonly="true" />
            <div class="mr5">@</div>
            <iui-text type="email2" name="eMail2" :value="workerInfo.email2" width="110px" :readonly="true" />
          </i-col>
        </i-row>
      </iui-container-new>
    </pmis-content-box>
    <pmis-content-box
      v-show="userInfo.frmGb == $getConstants('FRM_GB_1').code || userInfo.frmGb == $getConstants('FRM_GB_4').code"
    >
      <template v-slot:title>투입현장정보</template>
      <ib-sheet
        :uid="_uid"
        :options="sheetOpt"
        :loadSearchData="loadSearchData"
        @loadSheet="sheet_loadSheet"
        :events="{onRenderFirstFinish: sheet_onRenderFirstFinish}"
      />
    </pmis-content-box>
  </iui-container-new>
</template>
<script>
/**
 * 사원정보/근로자정보 팝업
 * 사용 :
 *     노무팀등록
 *     부서등록
 *
 * components :
 *    InputPrj 투입현장정보 (건설사/건설+외주의 경우만 )
 * */
import sheetOpt from '../sheetOption/workerInfoPopup.js';

export default {
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],

      workerInfo: {
        pgmCd: '', //회사코드
        empNo: '', //사원번호(ID사번)
        name: '', //성명
        regiNo: '', //주민번호
        regiNo1: '', //주민번호1
        regiNo2: '', //주민번호2
        tlno: '', //전화번호
        mobileTlno: '', //휴대폰번호
        rpsvFaxno: '', //팩스번호
        postNo: '', //우편번호
        adr1: '', //주소
        adr2: '', //상세주소
        email1: '', //이메일1
        email2: '', //이메일2
        phoFlNo: '', //사진파일번호
        laborDs: '', //근로자구분
        blnDepPart: '', //소속구분
        blnDepNo: '', //소속코드
        blnDepNm: '', //소속명
        blnDepNoSeq: '', //소속_순번
        dtsNo: '', //직책
        wBusiness: '', //담당업무
        rollNo: '', //직급
        useDs: '', //시스템사용여부
        useMst: '', //시스템관리자여부
        useSms: '', //SMS수신동의여부
        pwd: '', //비밀번호
        sgntFlNo: '', //사진파일번호
        labtClose: '', //근무종료
        priceLGb: '', //노무단가구분
        priceLAmt: '', //노무단가
        jkdNo: '', //노무직종
        jkdNm: '', //노무직종명
        pensionDs: '', //국민연금적용제외여부
        healthInsurDs: '', //건강보험적용제외여부
        employInsurDs: '', //고용보험적용제외여부
        healthInsurMDs: '', //장기요양30%경감대상여부
        bankCode: '', //은행코드
        depositNo: '', //계좌번호
        depositor: '', //예금주
        handicapKind: '', //장애유형
        handicapGrade: '', //장애등급
        handicapDate: '', //장애판정일
        nationCd: '', //국가코드
        nationNm: '', //국가코드
        passportNo: '', //여권번호
        visaKind: '', //체류자격
        entryDate: '', //입국일자
        stayDuration: '', //체류기간
        cud: 0,
        rgprId: '', //등록자아이디
      },
      dtsNm: '',
      rollNm: '',
    };
  },
  watch: {
    workerInfo: {
      handler() {
        $getCodeNm($getConstants('DTS_NO').code, this.workerInfo.dtsNo).then(response => (this.dtsNm = response));
        $getCodeNm($getConstants('ROLL_NO').code, this.workerInfo.rollNo).then(response => (this.rollNm = response));
      },
      deep: true,
    },
  },
  methods: {
    sheet_loadSheet(sheet) {
      this.sheet = sheet;
      this.onSearch();
    },
    sheet_onRenderFirstFinish() {
      this.onInputPujSearch();
    },
    async onSearch() {
      await axios
        .post('/worker/selectWorkerInfo', {pgmCd: this.pgmCd, empNo: this.$store.getters['modalParam'].empNo})
        .then(response => {
          if (response.status == 200) {
            let data = response.data;
            for (let key in this.workerInfo) {
              if (data && data.hasOwnProperty(key)) {
                this.workerInfo[key] = data[key];
              }
            }
          }
        });
    },
    onInputPujSearch() {
      axios
        .post('/worker/selectInputPrjList', {pgmCd: this.pgmCd, empNo: this.$store.getters['modalParam'].empNo})
        .then(response => {
          if (response.status == 200) {
            this.loadSearchData = response.data;
          }
        });
    },
  },
};
</script>
