<template>
  <pmis-page ref="page" :search-project-visible="false" @search-click="onSearch">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px"><PrjList /></i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>투입목록</template>
            <pmis-content-box>
              <template v-slot:header-left>
                <label>투입기간</label>
                <iui-datepicker
                  name="ioDateFrom"
                  :value="searchInfo.ioDateFrom"
                  @change="
                    event => {
                      searchInfo.ioDateFrom = event;
                      onSearch();
                    }
                  "
                />
                <div>~</div>
                <iui-datepicker
                  name="ioDateTo"
                  :value="searchInfo.ioDateTo"
                  @change="
                    event => {
                      searchInfo.ioDateTo = event;
                      onSearch();
                    }
                  "
                />
                <div>자재명</div>
                <iui-text
                  type="search"
                  :value="searchInfo.searchTxt"
                  @change="searchInfo.searchTxt = $event.target.value"
                  @enter="onSearch"
                />
                <iui-button value="검색" @click="onSearch" />
              </template>
              <ib-sheet
                :uid="_uid"
                :options="sheetOpt"
                :loadSearchData="loadSearchData"
                @loadSheet="loadSheet"
                :events="{onSearchFinish: sheetMerge}"
              />
            </pmis-content-box>
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

/** * 지급자재투입조회 * **/

<script>
import PrjList from '@/view/Project/components/PrjList.vue';
import sheetOpt from './components/sheetOption/PymntMtrilInputInqire.js';

export default {
  components: {
    PrjList,
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],

      searchInfo: {
        pgmCd: '',
        prjCd: '',
        ioDateFrom: '',
        ioDateTo: '',
        searchTxt: '',
      },
    };
  },
  created() {
    this.addEvent([{name: 'set_searchInfo', func: this.onPrjChange}]);
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onPrjChange(e) {
      this.searchInfo.pgmCd = this.pgmCd;
      this.searchInfo.prjCd = e.prjCd;

      if (this.searchInfo.prjCd) {
        this.onInitSearch();
        this.onSearch();
      } else {
        this.sheet.removeAll();
      }
    },
    onSearch() {
      const url = '/pymntMtrilInputInqire/selectPymntMtrilInputInqireList';
      const param = {...this.searchInfo};
      axios.post(url, param).then(response => (this.loadSearchData = response.data));
    },
    onInitSearch() {
      this.searchInfo.searchTxt = '';

      const currDate = $_getCurrentDate();
      const frDate = new Date(currDate.substr(0, 4), currDate.substr(4, 2) - 1, currDate.substr(6, 2));
      const toDate = new Date(currDate.substr(0, 4), currDate.substr(4, 2) - 1, currDate.substr(6, 2));
      frDate.setMonth(frDate.getMonth() - 1);

      this.searchInfo.ioDateFrom = $_dateToStringYYMMDD(frDate);
      this.searchInfo.ioDateTo = $_dateToStringYYMMDD(toDate);
    },
    sheetMerge() {
      let rows = this.sheet.getDataRows();
      let key = null;
      let mergeInfo = {};
      let sRow;
      let eRow;
      rows.forEach(r => {
        if (key != r.ioDate) {
          key = r.ioDate;
          sRow = r.id;
          mergeInfo[sRow] = sRow;
        } else {
          eRow = r.id;
          mergeInfo[sRow] = eRow;
        }
      });

      for (let m in mergeInfo) {
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'ioDate', this.sheet.getRowById(mergeInfo[m]), 'ioDate');
      }
    },
  },
};
</script>

<style scoped></style>
