<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-select
        :p-code="$getConstants('FRM_DS').code"
        name="searchFrmDs"
        :value.sync="searchFrmDs"
        @change="onSearch"
        :defaultCd="'A'"
        width="110px"
      />
      <iui-text type="search" label="거래처명" ref="searchNm" :value.sync="searchNm" @enter="onFocusByNm" />
      <iui-button value="검색" @click="onSearch" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="loadSearchData"
      @loadSheet="sheet = $event"
      :events="{
        onRenderFirstFinish: () => onSearch(),
        onDblClick: sheet_onDblClick,
      }"
    />
  </pmis-content-box>
</template>
<script>
import sheetOpt from './sheetOptions/customerListPopupSheet.js';
import {selectCustomerListPopup} from '@/view/customer/api/customer.js';
export default {
  /**
   * 거래처 조회 팝업
   * 사용 : 공통팝업(<iui-searchbox type='customer'/>)
   *        계약정보등록(tab3) > 공동도급사
   *        가동일보등록(tab2) > 유류정보
   **/
  props: {
    uid: {
      type: Number,
    },
    frmDs: {
      type: String,
      default: undefined,
    },
    paramObj: {
      type: Object,
      default: undefined,
    },
    bsnNoList: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],
      searchNm: '',
      searchFrmDs: '',
    };
  },
  created() {
    this.addEvent([{name: 'CustomerListPopup_callbackData', func: this.onFocusedRowData}]);
    this.searchFrmDs = this.paramObj ? this.paramObj.frmDs : this.frmDs;
  },
  methods: {
    onFocusByNm() {
      if (this.sheet.getTotalRowCount() > 0) {
        let searchCnt = this.sheet.util.listSearch(['bsnNo', 'frmNm'], this.searchNm);
        if (searchCnt == 0) {
          this.$alert({title: '거래처 검색', message: '해당 거래처 정보가 존재하지 않습니다'});
        }
        this.$refs.searchNm.$refs.input.focus();
      }
    },
    onSearch() {
      const param = {
        pgmCd: this.pgmCd,
        searchNm: this.searchNm,
        frmDs: this.searchFrmDs,
        bsnNoList: this.bsnNoList,
      };
      selectCustomerListPopup(param).then(response => {
        if (response.status == 200) {
          this.loadSearchData = response.data;
        }
      });
    },
    onEmitCustomerInfo(row) {
      this.$emit('emitCustomerInfo', row);
    },
    onFocusedRowData(callbackFunction) {
      if (typeof callbackFunction == 'function') {
        const focusedRow = this.sheet.getFocusedRow();
        if (focusedRow == null) {
          const title = this.$parent.$parent.internalTitle || '업체정보';
          const message = '선택된 항목이 없습니다.';
          this.$alert({title, message});
          return;
        }
        callbackFunction(focusedRow);
      }
    },
    sheet_onDblClick(e) {
      if (e.row.Kind == 'Data') {
        this.callEvent({name: `customerSearchOnConfirm${this.uid}`});
        this.$modalConfirm();
      }
    },
  },
};
</script>

<style scoped></style>
