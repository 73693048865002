export default that => {
  let preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      CanEdit: 0,
    },
    Def: {
      Row: {
        CanFormula: 1,
      },
    },
    LeftCols: [
      {
        Header: '정산년월',
        Name: 'investMm',
        Width: 100,
        Align: 'center',
        CustomFormat: '####-##',
      },
      {
        Header: '정산구분',
        Name: 'investKind',
        Width: 100,
        Align: 'center',
        Format: $getConstants('INVEST_KIND').code,
        CustomFormat: v => {
          const obj = {};
          obj[$getConstants('INVEST_KIND_ROUTINE').code] = '정기';
          obj[$getConstants('INVEST_KIND_NOT_ROUTINE').code] = '수시';
          return obj[v];
        },
      },
      {
        Header: '차수',
        Name: 'reqDegree',
        Width: 50,
        Align: 'center',
        Formula: fr => (fr.Row.closeYn == $getConstants('Y').code ? '' : fr.Row.reqDegree),
      },
    ],
    Cols: [
      {Header: '외주명', Name: 'subCodeName', Width: 100, Align: 'left'},
      {Header: '계약번호', Name: 'poNoSubc', Width: 100, Align: 'center'},
      {Header: '계약명', Name: 'ttl', Width: 100, Align: 'center'},
      {
        Header: '당월투입금액',
        Name: 'curInputAmt',
        Extend: preset.amount,
        Width: 90,
      },
      {
        Header: '전월미지급액',
        Name: 'prePayable',
        Extend: preset.amount,
        Width: 90,
      },
      {
        Header: '기타지급액',
        Name: 'curEtcinputAmt',
        Extend: preset.amount,
        Width: 90,
      },
      {
        Header: '당월공제금액',
        Name: 'curDeductAmt',
        Extend: preset.amount,
        Width: 90,
      },

      {
        Header: '당월유보금액',
        Name: 'curReserveAmt',
        Extend: preset.amount,
        Width: 90,
      },
      {
        Header: '정산금액',
        Name: 'calcAmt',
        Extend: preset.amount,
        Width: 90,
        Formula: fr =>
          fr.Row.curInputAmt + fr.Row.prePayable + fr.Row.curEtcinputAmt - fr.Row.curDeductAmt - fr.Row.curReserveAmt,
      },
      {Header: '공급가', Name: 'amt', Extend: preset.amount, Width: 90},
      {Header: '부가세', Name: 'vatAmt', Extend: preset.amount, Width: 90},
      {Header: '비과세', Name: 'taxexe', Extend: preset.amount, Width: 90},
      {Header: '합계', Name: 'sumAmt', Extend: preset.amount, Width: 90},
      {
        Header: '세금계산서',
        Name: 'billNo',
        Align: 'center',
        Width: 90,
        TextStyle: 4, // 밑줄
        Cursor: 'pointer',
      },
      {
        Header: '계산서',
        Name: 'billTaxNo',
        Align: 'center',
        Width: 90,
        TextStyle: 4, // 밑줄
        Cursor: 'pointer',
      },
    ],
    Events: {
      onClick: that.openBillPopup,
    },
  };
};
