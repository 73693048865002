<template>
  <pmis-tab-box>
    <template #title>발주정보</template>
    <template #title-right>
      <iui-button value="발주서송부" @click="sendOrder" v-if="!isNewOrder && !orderInfo.sendDt" />
      <iui-button value="발주서회수" @click="beforeRetrieveOrder" v-if="isOrderSent" />
    </template>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <OrderList />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row height="180px">
        <i-col>
          <form onsubmit="return false;">
            <pmis-content-box>
              <template #title>기본정보</template>
              <iui-container-new type="table" theme="bullet">
                <i-row>
                  <i-col-header>현장</i-col-header>
                  <i-col>
                    <iui-text :value="poInfo.spotDeptcd" :enable="false" width="90px" />
                    <iui-text :value="poInfo.spotDeptnm" :enable="false" />
                  </i-col>
                  <i-spacer />
                  <i-col-header required>발주번호</i-col-header>
                  <i-col>
                    <iui-text :value="orderInfo.poNo2" :enable="false" width="100px" />
                    <iui-text
                      :value="orderInfo.ttl"
                      @change="setOrderInfo({ttl: $event.target.value})"
                      :enable="canEditOrder"
                      max-length="500"
                      required
                      :errorMessage="{title: '발주명', message: '발주명을 입력해주세요.'}"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header required>발주일자</i-col-header>
                  <i-col>
                    <iui-datepicker
                      :value="orderInfo.poDt"
                      @change="setOrderInfo({poDt: $event})"
                      :enable="canEditOrder"
                      required
                      :errorMessage="{title: '발주일자', message: '발주일자를 입력해주세요.'}"
                    />
                  </i-col>
                  <i-spacer />
                  <i-col-header required>납품기일</i-col-header>
                  <i-col>
                    <iui-datepicker
                      :value="orderInfo.dlvReqDt"
                      @change="setOrderInfo({dlvReqDt: $event})"
                      :enable="canEditOrder"
                      required
                      :errorMessage="{title: '납품기일', message: '납품기일을 입력해주세요.'}"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>대급지급방법</i-col-header>
                  <i-col>
                    <iui-text :value="poInfo.payCondRmrk" :enable="false" />
                  </i-col>
                  <i-spacer />
                  <i-col-header>납품방법</i-col-header>
                  <i-col>
                    <iui-text :value="poInfo.dlvCondRmrkNm" :enable="false" />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>납품장소</i-col-header>
                  <i-col>
                    <iui-text :value="poInfo.dlvLoc" :enable="false" />
                  </i-col>
                  <i-spacer />
                  <i-col-header>특약사항</i-col-header>
                  <i-col rowspan="2">
                    <iui-text type="multi" :value="poInfo.poRmrk" :enable="false" />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col colspan="2">
                    <iui-container-new type="table" theme="bullet">
                      <i-row>
                        <i-col-header>지체상금율</i-col-header>
                        <i-col>
                          <iui-checkbox-group
                            :checkedValues="[poInfo.delayRateYn]"
                            :items="[
                              {label: $getConstants('EXTERNAL_Y').name, value: $getConstants('EXTERNAL_Y').code},
                            ]"
                            :enable="false"
                          />
                          <iui-text
                            v-if="isDelayRateY"
                            type="rate"
                            width="80px"
                            :value="poInfo.delayRate"
                            :enable="false"
                          />
                        </i-col>
                        <i-col-header>첨부파일</i-col-header>
                        <i-col>
                          <pmis-file-list
                            id="id"
                            :modal="true"
                            :file-number="orderInfo.flNo"
                            :toolbarVisible="canEditOrder"
                            :clear.sync="clearFile"
                            @delete-complete="onDeleteComplete"
                          />
                        </i-col>
                      </i-row>
                    </iui-container-new>
                  </i-col>
                </i-row>
              </iui-container-new>
            </pmis-content-box>
          </form>
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col>
          <OrderItemList />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-tab-box>
</template>

<script>
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import PoActionMixin from '@/view/bidding/js/poActionMixin.js';
import {createOrder, saveOrder, deleteOrder, sendOrder, retrieveOrder} from '@/view/bidding/po/mat/api/order.js';
import {updatePoFlNo} from '@/view/bidding/po/mat/api/po.js';
import OrderList from './OrderList.vue';
import OrderItemList from './OrderItemList.vue';

export default {
  components: {OrderList, OrderItemList},
  mixins: [PoActionMixin],
  data() {
    return {
      clearFile: false,
      delayRateItems: [
        {label: '없음', value: BIDDING_CONSTANTS.CODES.DELAY_RATE_N},
        {label: '있음', value: BIDDING_CONSTANTS.CODES.DELAY_RATE_Y},
      ],
    };
  },
  computed: {
    isDelayRateY() {
      return this.poInfo.delayRateYn === BIDDING_CONSTANTS.CODES.DELAY_RATE_Y;
    },
    canSendOrder() {
      return this.orderInfo.poSt === BIDDING_CONSTANTS.PROGRESS_STATE.PO_READY;
    },
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'orderInfo', 'orderItemList', 'isNewOrder', 'canEditOrder', 'isOrderSent', 'bidType']);
    $mapMutations(this, ['setOrderInfo']);
    $mapActions(this, ['onAddOrder']);
  },
  created() {
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
    this.addEvent([{name: 'PoOrderInfo_onAdd', func: this.onAdd}]);
  },
  methods: {
    async onAdd() {
      const errorInfo = await this.onAddOrder();
      if (errorInfo?.pmisError && errorInfo?.code === 'ORDER_NO_REMAINS') {
        this.$alert({title: '발주서', message: errorInfo.message});
        return;
      }

      this.clearFile = true;
      this.canSave = true;
      this.canDelete = false;
    },
    async onSave(param) {
      let orderItemList;
      this.callEvent({name: 'OrderItemList_getSaveJson', param: data => (orderItemList = data)});

      const afterValidation = () => {
        const poDt = String(this.orderInfo.poDt).replace(/-/g, '');
        const dlvReqDt = String(this.orderInfo.dlvReqDt).replace(/-/g, '');
        if (poDt > dlvReqDt) {
          this.$alert({title: '납품기일', message: '납품기일이 발주일자보다 이전일 수 없습니다.'});
          return;
        }

        if (!this.orderInfo.totAmt) {
          this.$alert({title: '발주내역', message: '발주내역 수량을 입력해주세요'});
          return false;
        }
        return true;
      };

      const saveApi = this.isNewOrder ? createOrder : saveOrder;
      const saveParam = {...this.orderInfo, orderItemList};
      saveApi(saveParam, afterValidation)
        .then(response => {
          if (response.data) {
            if (param?.isSend) {
              param.sendOrdercallback();
            } else {
              this.callEvent({name: 'OrderList_ReSearch', param: this.orderInfo.poNo});
            }
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    async onDelete() {
      const title = '발주서 삭제';
      const message = '발주서를 삭제하시겠습니까?';

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      deleteOrder(this.orderInfo.poNo)
        .then(response => {
          if (response.data) {
            this.callEvent({name: 'OrderList_ReSearch'});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    async sendOrder() {
      const title = '발주서 송부';
      const message = '발주서를 협력사에게 송부하시겠습니까?';

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      const param = {
        isSend: true,
        sendOrdercallback: () => {
          sendOrder(this.orderInfo.poNo, this.orderInfo.contSeq)
            .then(response => {
              if (response.data) {
                this.callEvent({name: 'OrderList_ReSearch', param: this.orderInfo.poNo});
              }
            })
            .catch(ex => {
              if (ex.pmisError) {
                this.$alert(ex);
              }
            });
        },
      };
      this.onSave(param);
    },
    beforeRetrieveOrder() {
      this.onChkPoState2('retrieveOrder', this.orderInfo, 'OrderList_ReSearch');
    },
    async retrieveOrder() {
      const title = '발주서 회수';
      const message = '송부된 발주서를 회수하시겠습니까?';

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      retrieveOrder(this.orderInfo.poNo, this.orderInfo.contSeq)
        .then(response => {
          if (response.data) {
            this.callEvent({name: 'OrderList_ReSearch', param: this.orderInfo.poNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    async onDeleteComplete(flNo) {
      if (flNo == 0) {
        this.orderInfo.flNo = flNo;
        updatePoFlNo(this.orderInfo.poNo, this.orderInfo.contSeq, this.orderInfo.flNo);
      }
    },
  },
};
</script>

<style></style>
