<template>
  <pmis-content-box>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :append="true"
      :events="sheetEvents"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import {selectMaterialCodeList, deleteMaterialCode} from '../api/MatCodeRegist.js';
import options from './sheetOption/DetailListSheet.js';
export default {
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    base: {
      type: Boolean,
      default: false,
    },
    uid: {
      type: Number,
    },
    callback: {
      type: Function,
      default: undefined,
    },
    paramObj: {
      type: Object,
      default: () => {},
    },
    focusKey: {
      type: String,
    },
  },
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this)),
      loadSearchData: [],
      isMultiSelect: false,
      sheetUtil: null,
      searchNm: '',
      matClInfo: {},
      matCodeInfo: {
        flNo: 0,
        itemName: '',
        level: 5,
        lmCode: '',
        lmName: '',
        matCode: '',
        matGubun: null,
        matStyle: null,
        mmCode: '',
        mmName: '',
        size: '',
        smCode: '',
        smName: '',
        unit: '',
        useDs: '',
      },
      sheetEvents: {
        onDblClick: this.isPopup ? this.onDblClick : undefined,
        onClick: this.isPopup ? undefined : this.onClick,
        onFocus: this.isPopup ? undefined : this.onFocus,
        onSearchFinish: this.isPopup ? undefined : this.onSearchFinish,
      },
    };
  },
  created() {
    let param = [{name: 'changeFlNo', func: this.changeFlNo}];
    this.addEvent(param);

    if (this.paramObj) {
      this.isMultiSelect = this.paramObj.isMultiSelect;
    }

    if (this.isPopup) this.onSearch();
  },

  methods: {
    async onSearch() {
      const param = {
        base: this.base,
        code: this.matClInfo.level === 1 ? '' : this.matClInfo.code ?? '',
        searchNm: this.searchNm,
        ...this.sheetUtil.pagingData(),
      };
      let response = await selectMaterialCodeList(param);
      if (response.status == 200) {
        this.loadSearchData = response.data;
      }
    },
    async onDelete() {
      let checkedRows = this.sheet.getRowsByChecked('chk');

      if (0 == checkedRows.length) {
        this.$alert({title: '삭제', message: '선택된 항목이 없습니다.'});
        return;
      }
      if (!(await this.$confirm({title: '삭제', message: '선택된 자재를 삭제하시겠습니까?'}))) {
        return;
      }
      try {
        let deletedList = checkedRows.map(row => ({matCode: row.matCode, flNo: row.flNo}));
        const response = await deleteMaterialCode({base: this.base, deletedList: deletedList});
        if (response.data) {
          this.removeAll();
          this.onSearch();
          return deletedList;
        }
      } catch (error) {
        this.$alert({title: '삭제', message: error.description ?? error.message});
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
      this.sheetUtil.scrollPaging(this.onSearch);
    },
    onDblClick(e) {
      e.row.Kind == 'Data' ? this.callEvent({name: `matCodeSearchOnConfirm${this.uid}`}) : '';
    },
    async onFocus(e) {
      await this.callEvent({name: 'clearImage'});
      if (e.row.Kind == 'Data') {
        this.setMatCodeInfo(e.row);
        if (typeof this.callback == 'function') this.callback();
      }
    },
    onSearchFinish(e) {
      if (this.focusKey) {
        const row = e.sheet.getDataRows().find(row => row.matCode === this.focusKey);
        e.sheet.focus(row);
        this.$emit('update:focusKey', undefined);
      }
    },
    setMatCodeInfo(row) {
      for (let key in this.matCodeInfo) {
        this.matCodeInfo[key] = row[key];
      }
      this.$emit('matCodeInfo', this.matCodeInfo);
    },

    onClick(e) {
      this.$emit('onMatClSearch', e.row.smCode);
    },
    onReloadSheet() {
      if (this.cud == 1) {
        let addRow = this.sheet.addRow({
          init: {
            lmCode: this.matCodeInfo.lmCode,
            lmName: this.matCodeInfo.lmName,
            mmCode: this.matCodeInfo.mmCode,
            mmName: this.matCodeInfo.mmName,
            smCode: this.matCodeInfo.smCode,
            smName: this.matCodeInfo.smName,
            matCode: this.matCodeInfo.matCode,
            itemName: this.matCodeInfo.itemName,
            size: this.matCodeInfo.size,
            matStyle: this.matCodeInfo.matStyle,
            unit: this.matCodeInfo.unit,
            flNo: this.matCodeInfo.flNo,
            useDs: this.matCodeInfo.useDs,
          },
        });
        this.sheet.acceptChangedData(addRow);
      } else if (this.cud == 2) {
        let focusedRow = this.sheet.getFocusedRow();
        this.sheet.setValue(focusedRow, 'itemName', this.matCodeInfo.itemName, 1);
        this.sheet.setValue(focusedRow, 'size', this.matCodeInfo.size, 1);
        this.sheet.setValue(focusedRow, 'matStyle', this.matCodeInfo.matStyle, 1);
        this.sheet.setValue(focusedRow, 'unit', this.matCodeInfo.unit, 1);
        this.sheet.setValue(focusedRow, 'useDs', this.matCodeInfo.useDs, 1);
        this.sheet.setValue(focusedRow, 'flNo', this.matCodeInfo.flNo, 1);
        this.sheet.acceptChangedData(focusedRow);
      }
    },
    onSetData(param) {
      if (this.isMultiSelect) {
        let checkedRows = this.sheet.getRowsByChecked('chk');
        if (0 == checkedRows.length) {
          this.$alert({title: '선택', message: '선택된 항목이 없습니다.'});
          return false;
        }
        param(checkedRows);
      } else {
        param(this.sheet.getFocusedRow());
      }
    },
    removeAll() {
      this.sheet.removeAll();
      Object.assign(this.$data.matCodeInfo, this.$options.data.call(this).matCodeInfo);
    },
    changeFlNo(flNo) {
      let focusedRow = this.sheet.getFocusedRow();
      this.sheet.setValue(focusedRow, 'flNo', flNo, 1);
      this.sheet.acceptChangedData(focusedRow);
    },
  },
};
</script>

<style scoped></style>
