<template>
  <pmis-tab-box>
    <template v-slot:title>
      {{ title }}
    </template>
    <template #header-left>
      <iui-datepicker
        v-if="isSearchData"
        :prefix="searchDataTl"
        suffix="~"
        :value.sync="fromDate"
        :group="{grpNm: 'searchDt', seq: 1}"
      />
      <iui-datepicker v-if="isSearchData" :value.sync="toDate" :group="{grpNm: 'searchDt', seq: 2}" />
      <label v-if="!searchGbItems.length">문서제목</label>
      <iui-select v-else :value.sync="searchGb" :items="searchGbItems" />
      <iui-text type="search" :value.sync="searchName" @keyup.enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
      <div class="mr10" />
      <iui-radio-group
        :items="searchPublicItems"
        :value="searchPublic"
        @change="onChangePublic"
        v-if="appPublicState"
      />
    </template>
    <template #header-right>
      <iui-button v-if="appSendState" @click="showDocSendInfo">발송정보</iui-button>
      <iui-button v-if="appPublicState" @click="onPublicConfirm">일괄확인</iui-button>
      <iui-button v-if="appSendPublic" @click="onPublicInfo">공람정보</iui-button>

      <iui-button v-if="appProgress" @click="showApprovalStatus">결재현황</iui-button>
      <iui-button @click="showDocument">문서보기</iui-button>
    </template>

    <ib-sheet
      :uid="_uid"
      :options="options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onDblClick,
        onSearchFinish,
      }"
    />

    <pop :parentId="_uid" @approvalEmit="onApprovalEmit" :title="title" />
    <pop :parentId="_uid + 'offline'" @approvalEmit="onApprovalEmit" :offline="true" :title="title" />
    <iui-modal name="docSendInfo" title="발송정보" sizeType="size4">
      <DocSendInfoPop :sncNo="sncNo" :sndNo="sndNo" />
    </iui-modal>
    <iui-modal name="docPublicInfo" title="공람정보" sizeType="size4">
      <DocPublicInfoPop :sncNo="sncNo" />
    </iui-modal>
    <iui-modal name="AppListApprovalStatusPop" :title="'결재현황'" sizeType="size4">
      <ApprovalStatusPop :sncNo="sncNo" />
    </iui-modal>
    <!-- <iui-modal name="offlineAppDocPop" title="접수문서" sizeType="size2">
      <OfflineAppDocPop :param="offlineParam" />
    </iui-modal> -->
  </pmis-tab-box>
</template>

<script>
import pop from '@/view/approval/draft/popup/pop.vue';
import DocSendInfoPop from './DocSendInfoPop.vue';
import DocPublicInfoPop from './DocPublicInfoPop.vue';
// import OfflineAppDocPop from '../components/OfflineAppDocPop.vue';
import ApprovalStatusPop from '@/view/approval/draft/popup/ApprovalStatusPop.vue';
import {savePublicConfirm, selectAppDocList} from '../api/appDoc.js';
import APPROVAL from '../js/approvalConstants.js';

export default {
  components: {
    pop,
    DocSendInfoPop,
    DocPublicInfoPop,
    // OfflineAppDocPop,
    ApprovalStatusPop,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    appProgress: {
      type: Boolean,
      default: false,
    },
    appStandby: {
      type: Boolean,
      default: false,
    },
    appSendStandby: {
      type: Boolean,
      default: false,
    },
    appSendState: {
      type: Boolean,
      default: false,
    },
    appReceiptStandby: {
      type: Boolean,
      default: false,
    },
    appPublicState: {
      type: Boolean,
      default: false,
    },
    appSendPublic: {
      type: Boolean,
      default: false,
    },
    appTemp: {
      type: Boolean,
      default: false,
    },
    appDraftComplete: {
      type: Boolean,
      default: false,
    },
    appComplete: {
      type: Boolean,
      default: false,
    },
    appReceiptComplete: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      sheet: undefined,
      sncNo: 0,
      sndNo: 0,
      loadSearchData: [],
      fromDate: '',
      toDate: '',
      searchGb: 'tl',
      searchName: '',
      searchPublicItems: [
        {label: '전체', value: ''},
        {label: '공람완료', value: 'publicComplete'},
        {label: '미완료', value: 'publicIncomplete'},
      ],
      searchPublic: '',
      offlineParam: {},
      offline: false,
      focusKey: 0,
      publicList: [],
    };
  },
  computed: {
    isSearchData() {
      return ![this.appPublicState || this.appSendPublic].find(item => item);
    },
    searchDataTl() {
      if (this.appSendState) {
        return '발송일자';
      }
      if (this.appReceiptStandby || this.appReceiptComplete) {
        return '수신일자';
      }
      if (this.appComplete) {
        return '기안/접수일';
      }
      return '기안일자';
    },
    searchGbItems() {
      if (this.appStandby || this.appProgress || this.appReceiptStandby || this.appComplete) {
        return [
          {key: 'tl', text: '문서제목', value: 'tl'},
          {key: 'empNm', text: this.appComplete ? '기안/접수자' : '기안자', value: 'empNm'},
          {key: 'depNm', text: this.appComplete ? '기안/접수부서' : '기안부서', value: 'depNm'},
        ];
      }
      if (this.appSendPublic) {
        return [
          {key: 'tl', text: '문서제목', value: 'tl'},
          {key: 'empNm', text: '기안자', value: 'empNm'},
        ];
      }
      return [];
    },
  },
  watch: {
    search(value) {
      if (value) {
        this.onSearch();
      }
    },
  },
  created() {
    if (this.search) {
      this.onSearch();
    }
  },
  async activated() {
    const sncNo = this.$store.getters.externalData.sncNo;
    this.$store.dispatch('setExternalData', {});
    if (sncNo) {
      this.focusKey = sncNo;

      if (this.sheet) {
        await this.$nextTick();
        this.setFocus();
      }
    }
  },
  methods: {
    async onSearch() {
      const param = {
        appProgress: this.appProgress,
        appStandby: this.appStandby,
        appSendStandby: this.appSendStandby,
        appSendState: this.appSendState,
        appReceiptStandby: this.appReceiptStandby,
        appPublicState: this.appPublicState,
        appSendPublic: this.appSendPublic,
        appTemp: this.appTemp,
        appDraftComplete: this.appDraftComplete,
        appComplete: this.appComplete,
        appReceiptComplete: this.appReceiptComplete,
        searchGb: this.searchGb,
        searchName: this.searchName,
        fromDate: this.fromDate,
        toDate: this.toDate,
      };
      if (this.searchPublic) {
        param[this.searchPublic] = true;
      }
      const response = await selectAppDocList(param);
      this.loadSearchData = response.data;
      this.$emit('update:search', false);
    },
    onChangePublic(e) {
      this.searchPublic = e.target.value;
      this.onSearch();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onSearchFinish(e) {
      e.sheet.getDataRows().forEach(row => {
        if (
          (this.appProgress && ![APPROVAL.SNC_SS_REJECT, APPROVAL.SNC_SS_ROLLBACK].includes(row.nowSncSs)) ||
          (this.appReceiptStandby && !row.offlineYn)
        ) {
          this.sheet.setAttribute(row, 'chk', 'CanEdit', false, 1);
        }
      });
      this.setFocus();
    },
    onDblClick(e) {
      if (e.kind == 'Data') {
        this.showDocument();
      }
    },
    async showDocument() {
      const row = this.sheet.getFocusedRow();
      if (row) {
        this.offline = !!row.offlineYn;

        this.callEvent({
          name: `draft_pop${this._uid}${this.offline ? 'offline' : ''}`,
          param: {
            sncNo: row.sncNo,
            sncKd: this.sncKd,
            appSendStandby: this.appSendStandby,
            appSendState: this.appSendState,
            appReceiptStandby: this.appReceiptStandby,
            appPublicState: this.appPublicState,
            appSendPublic: this.appSendPublic,
            publicEmpNo: row.publicEmpNo,
            publicDepCd: row.publicDepCd,
            offline: this.offline,
            docCsDs: row.docCsDs,
            rcvDttm: row.rcvDttm,
          },
        });
      }
    },
    async setFocus() {
      if (this.focusKey) {
        await this.sheet.util.listSearch('sncNo', this.focusKey, true);
        this.focusKey = 0;
        this.showDocument();
      }
    },
    showDocSendInfo() {
      const row = this.sheet.getFocusedRow();
      if (row) {
        this.sncNo = row.sncNo;
        this.sndNo = row.sndNo;
        this.$modal.show('docSendInfo');
      }
    },
    async onPublicConfirm() {
      if (!(await this.$confirm({title: '일괄확인', message: '선택한 문서를 일괄확인 하시겠습니까?'}))) {
        return;
      }
      const rows = this.sheet
        .getRowsByChecked('chk')
        .map(row => ({sncNo: row.sncNo, empNo: row.publicEmpNo, depCd: row.publicDepCd}));
      if (!rows.length) {
        return;
      } else if (this.sheet.getRowsByChecked('chk').filter(row => row.rcvDttm).length > 0) {
        this.$alert({title: '일괄확인', message: '공람완료 문서가 있습니다.'});

        this.sheet
          .getRowsByChecked('chk')
          .filter(row => row.rcvDttm)
          .forEach(row => {
            this.sheet.setCheck(row, 'chk', '0');
          });

        return;
      }

      const param = {publicSaveList: rows};
      await savePublicConfirm(param);
      this.onSearch();
    },
    onPublicInfo() {
      const row = this.sheet.getFocusedRow();
      this.sncNo = row.sncNo;
      this.$modal.show('docPublicInfo');
    },
    onApprovalEmit() {
      this.onSearch();
    },
    showApprovalStatus() {
      const row = this.sheet.getFocusedRow();
      this.sncNo = row.sncNo;
      this.$modal.show(`AppListApprovalStatusPop`);
    },
  },
};
</script>

<style></style>
