<template>
  <pmis-tab-box>
    <template #title>요청내역</template>
    <template #title-right>
      <iui-button value="자재선택" @click="openStandardCodePopup" v-if="prStatus.isEnable && isSimpleExec" />
      <iui-button value="자재실행예산" @click="openMatBudgetModal" v-if="prStatus.isEnable && !isSimpleExec" />
      <iui-button value="삭제" @click="onRemovePrItem" v-if="prStatus.isEnable" />
    </template>
    <pmis-content-box>
      <template #header-left>
        <iui-text prefix="공급가액" type="amount" :value="prInfo.amt" :enable="false" width="200px" />
        <iui-text prefix="부가세액" type="amount" :value="prInfo.vatAmt" :enable="false" width="200px" />
        <iui-text prefix="합계금액" type="amount" :value="prInfo.totAmt" :enable="false" width="200px" />
      </template>

      <ib-sheet
        :uid="_uid"
        :options="sheetOpt"
        :loadSearchData="prItemList"
        @loadSheet="sheet = $event"
        :events="{
          onSearchFinish: sheet_onSearchFinish,
          onBeforeChange: sheet_onBeforeChange,
          onAfterChange: sheet_onAfterChange,
          onClick: sheet_onClick,
        }"
      />
    </pmis-content-box>

    <iui-modal name="standardCodePopup" title="자재선택" :btns="standardCodePopupBtns" size-type="size6">
      <standard-code-popup type="mat" :base="false" />
    </iui-modal>

    <iui-modal name="matBudgetModal" title="자재예산선택" :btns="matBudgetModalBtns" size-type="size3">
      <mat-budget-popup :propsPrjCd="prInfo.spotDeptcd" :visiblePrice="false" />
    </iui-modal>

    <iui-modal name="matCodeInfoModal" title="자재코드" size-type="size4">
      <mat-code-info :propsMatCode="propsMatCode" />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/prItemList.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';

import StandardCodePopup from '@/view/sysManage/stdCodeManage/components/StandardCodePopup.vue';
import MatBudgetPopup from '@/view/bidding/prManage/components/popup/MatBudgetPopup.vue';
import MatCodeInfo from '@/view/sysManage/stdCodeManage/matCodeRegist/popup/MatCodeInfo.vue';

export default {
  components: {MatBudgetPopup, StandardCodePopup, MatCodeInfo},
  data() {
    return {
      standardCodePopupBtns: [{name: '확인', callback: this.onConfirm_standardCodePopup}],
      matBudgetModalBtns: [{name: '확인', callback: this.onConfirm_matBudgetModal}],

      propsMatCode: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, ['prInfo', 'prItemList', 'prStatus']);
    $mapMutations(this, ['setPrInfo']);
    $mapActions(this, ['searchPrItemList']);
  },
  created() {
    this.sheetOpt = $addColCheckbox(sheetOpt(this));
    this.addEvent([
      {name: 'PrItemList_CalculateAmt', func: this.calculateAmt},
      {name: 'PrItemList_OpenMatBudgetModal', func: this.openMatBudgetModal},
      {name: 'PrItemList_GetSaveJson', func: this.getSaveJson},
    ]);
  },
  methods: {
    openStandardCodePopup() {
      this.$modal.show('standardCodePopup');
    },
    onConfirm_standardCodePopup() {
      const curPrItemsCd = this.sheet.getSaveJson(0).data.map(item => item.itemCd);

      this.callEvent({
        name: 'StandardCodePopup_confirm',
        param: data => {
          data
            .filter(item => !curPrItemsCd.includes(String(item.matCode)))
            .forEach(item => {
              const addRow = this.sheet.addRow();

              this.sheet.setValue(addRow, 'itemCd', item.matCode, true);
              this.sheet.setValue(addRow, 'itemNm', item.itemName, true);
              this.sheet.setValue(addRow, 'sizeNm', item.size, true);
              this.sheet.setValue(addRow, 'unit', item.unit, true);
              this.sheet.setValue(addRow, 'qty', 0, true);
            });
          this.$modal.hide('standardCodePopup');
        },
      });
    },
    openMatBudgetModal() {
      this.$modal.show('matBudgetModal');
    },
    onConfirm_matBudgetModal() {
      const curPrItemsCd = this.sheet.getSaveJson(0).data.map(item => item.itemCd);

      this.callEvent({
        name: 'MatBudgetPopup_GetCheckedRows',
        param: data => {
          if (!data.length) {
            this.$alert({title: '요청내역', message: '요청내역이 선택되지 않았습니다.'});
            return;
          }

          data
            .filter(item => !curPrItemsCd.includes(String(item.itemCode)))
            .forEach(item => {
              const addRow = this.sheet.addRow();

              this.sheet.setValue(addRow, 'itemCd', item.itemCode, true);
              this.sheet.setValue(addRow, 'itemNm', item.itemName, true);
              this.sheet.setValue(addRow, 'sizeNm', item.size, true);
              this.sheet.setValue(addRow, 'unit', item.unit, true);

              this.sheet.setValue(addRow, 'planQty', item.qty, true);
              this.sheet.setValue(addRow, 'preQty', item.preQty, true);
              this.sheet.setValue(addRow, 'remQty', item.remQty, true);
              this.sheet.setValue(addRow, 'qty', 0, true);
              this.sheet.setValue(addRow, 'planUnitPrc', item.price, true);
            });

          this.$modal.hide('matBudgetModal');
        },
      });
    },
    calculateAmt() {
      const itemList = this.sheet.getDataRows();
      const amt = itemList.reduce((acc, curr) => acc + curr['amt'], 0);

      const {taxTypecd, taxDirectDs, taxRate, taxTypeDirectPercent, taxTypeDirectAmt} = this.prInfo;
      this.setPrInfo(
        COMMON_FUNCTION.onCalculateTaxAndTotAmt({
          amt,
          taxTypecd,
          taxDirectDs,
          taxRate,
          taxTypeDirectPercent,
          taxTypeDirectAmt,
        })
      );
      if (100 < this.prInfo.taxRate) {
        this.$alert({title: '과세율', message: '100%를 초과할 수 없습니다.'});
      }
    },
    onRemovePrItem() {
      if (!this.sheet.getTotalRowCount()) {
        this.$alert({title: '구매요청내역 삭제', message: '요청내역이 존재하지 않습니다.'});
        return;
      }

      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (!checkedRows.length) {
        this.$alert({title: '구매요청내역 삭제', message: '선택된 항목이 없습니다.'});
        return;
      }

      this.sheet.removeRows(checkedRows);
      this.calculateAmt();
    },
    getSaveJson(callback) {
      const saveJson = this.sheet.getSaveJson(0).data.sort((a, b) => a.itemCd - b.itemCd);

      if (typeof callback === 'function') {
        callback(saveJson);
      }
    },
    setEnableSheet() {
      const isEnable = this.prStatus.isEnable;

      this.sheet.setAttribute(null, 'chk', 'Visible', isEnable, 1);

      this.sheet.setAttribute(null, 'qty', 'CanEdit', isEnable, 1);
      this.sheet.setAttribute(null, 'itemRmrk', 'CanEdit', isEnable, 1);

      this.sheet.setAttribute(null, 'qty', 'Color', isEnable ? '#E0E0F8' : '', 1);
      this.sheet.setAttribute(null, 'itemRmrk', 'Color', isEnable ? '#E0E0F8' : '', 1);

      this.sheet.Def.Row.CanFocus = isEnable;
    },

    sheet_onSearchFinish() {
      this.setEnableSheet();
      this.calculateAmt();
    },
    sheet_onClick(e) {
      if (e.row.Kind == 'Data' && e.col == 'itemCd') {
        this.propsMatCode = e.row.itemCd.toString();
        this.$modal.show('matCodeInfoModal');
      }
    },
    sheet_onBeforeChange(e) {
      if (e.col === 'qty') {
        if (Number.isNaN(Number(e.val))) {
          return e.oldval;
        }

        const decimal = e.val.split('.')[1];
        if (5 < decimal?.length) {
          this.$alert({title: '금회요청수량', message: '소수점 이하 5자리까지 입력가능합니다.'});
          return e.oldval;
        }

        if (0 > e.val) {
          this.$alert({title: '금회요청수량', message: '금회요청수량은 0 이상이어야 합니다.'});
          return e.oldval;
        }
        return e.val;
      }
    },
    sheet_onAfterChange(e) {
      if (e.col === 'qty') {
        this.calculateAmt();
      }
    },
  },
};
</script>

<style></style>
