<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <ExpenseList />
              </i-col>
            </i-row>
            <i-spacer />
            <i-row height="240px">
              <i-col>
                <DetailInfo />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import PrjList from '@/view/Project/components/PrjList.vue';
import ExpenseList from './components/ExpenseList.vue';
import DetailInfo from './components/DetailInfo.vue';
export default {
  components: {
    PrjList,
    ExpenseList,
    DetailInfo,
  },
};
</script>

<style></style>
