export default that => {
  return {
    Cfg: {
      CanEdit: 1,
      HeaderMerge: 6,
      SearchMode: 0,
    },
    Cols: [
      {
        Header: ['내역\n번호', '내역\n번호'],
        Name: 'itemSeq',
        Type: 'Text',
        Align: 'Center',
        Width: 60,
        CanEditFormula: fr => (fr.Row['costType'] ? that.confirm : 0),
      },
      {
        Header: ['자원\n구분', '자원\n구분'],
        Name: 'costType',
        Type: 'Text',
        Align: 'Center',
        Width: 60,
        CanEdit: 0,
      },
      {
        Header: ['코드', '코드'],
        Name: 'itemCode',
        Type: 'Text',
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: ['품명', '품명'],
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 200,
        CanEdit: 0,
      },
      {
        Header: ['규격', '규격'],
        Name: 'ssize',
        Type: 'Text',
        Width: 110,
        CanEdit: 0,
      },
      {
        Header: ['단위', '단위'],
        Name: 'unit',
        Type: 'Text',
        Align: 'Center',
        Width: 100,
        CanEdit: 0,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: ['수량', '수량'],
        Name: 'qty',
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 85,
        CanEditFormula: fr => (fr.Row['formula'] ? 0 : fr.Row['costType'] ? that.confirm : 0),
        OnChange: e => {
          if ([$getConstants('COST_TYPE_M').code, $getConstants('COST_TYPE_I').code].indexOf(e.row.costType) !== -1) {
            e.sheet.setValue(e.row, 'matAmt', e.row.qty * e.row.matPrice, 1);
          }
          if ([$getConstants('COST_TYPE_L').code, $getConstants('COST_TYPE_I').code].indexOf(e.row.costType) !== -1) {
            e.sheet.setValue(e.row, 'labAmt', e.row.qty * e.row.labPrice, 1);
          }
          if (
            [
              $getConstants('COST_TYPE_E').code,
              $getConstants('COST_TYPE_A').code,
              $getConstants('COST_TYPE_I').code,
            ].indexOf(e.row.costType) !== -1
          ) {
            e.sheet.setValue(e.row, 'equipAmt', e.row.qty * e.row.equipPrice, 1);
          }
          e.sheet.setValue(e.row, 'amt', e.row.qty * e.row.price, 1);
        },
      },
      {
        Header: ['재료비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'matPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEditFormula: fr =>
          fr.Row['formula'] ? 0 : that.confirm && fr.Row['costType'] === $getConstants('COST_TYPE_M').code,
        OnChange: e => {
          e.sheet.setValue(e.row, 'matAmt', e.row.qty * e.row.matPrice, 1);
          e.sheet.setValue(e.row, 'price', e.row.matPrice + e.row.labPrice + e.row.equipPrice, 1);
          e.sheet.setValue(e.row, 'amt', e.row.qty * e.row.price, 1);
        },
      },
      {
        Header: ['재료비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'matAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: ['노무비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'labPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEditFormula: fr =>
          fr.Row['formula'] ? 0 : that.confirm && fr.Row['costType'] === $getConstants('COST_TYPE_L').code,
        OnChange: e => {
          e.sheet.setValue(e.row, 'labAmt', e.row.qty * e.row.labPrice, 1);
          e.sheet.setValue(e.row, 'price', e.row.matPrice + e.row.labPrice + e.row.equipPrice, 1);
          e.sheet.setValue(e.row, 'amt', e.row.qty * e.row.price, 1);
        },
      },
      {
        Header: ['노무비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'labAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: ['경비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'equipPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEditFormula: fr =>
          fr.Row['formula']
            ? 0
            : that.confirm &&
              (fr.Row['costType'] === $getConstants('COST_TYPE_A').code ||
                fr.Row['costType'] === $getConstants('COST_TYPE_E').code),
        OnChange: e => {
          e.sheet.setValue(e.row, 'equipAmt', e.row.qty * e.row.equipPrice, 1);
          e.sheet.setValue(e.row, 'price', e.row.matPrice + e.row.labPrice + e.row.equipPrice, 1);
          e.sheet.setValue(e.row, 'amt', e.row.qty * e.row.price, 1);
        },
      },
      {
        Header: ['경비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'equipAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: ['합계', {Value: '단가', Color: '#dff9fb'}],
        Name: 'price',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEdit: 0,
      },
      {
        Header: ['합계', {Value: '금액', Color: '#dff9fb'}],
        Name: 'amt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Name: 'wcode',
        Visible: 0,
      },
      {
        Name: 'headCode',
        Visible: 0,
      },
      {
        Name: 'midCode',
        Visible: 0,
      },
      {
        Name: 'formula',
        Visible: 0,
      },
      {
        Name: 'chgDegree',
        Visible: 0,
      },
      {
        Name: 'originItemSeq',
        Visible: 0,
      },
    ],
  };
};
