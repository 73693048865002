export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      HeaderMerge: 5, // 5 : 열 우선 사방 병합
      CanEdit: false,
    },
    Cols: [
      {
        Header: '투입일자',
        Name: 'ioDate',
        Type: 'Text',
        Align: 'center',
        MinWidth: 150,
      },
      {
        Header: '공종명',
        Name: 'mgName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 200,
      },
      {
        Header: '내역코드',
        Name: 'itemSeq',
        Type: 'Text',
        Align: 'center',
        Width: 100,
      },
      {
        Header: '자재코드',
        Name: 'matCode',
        Type: 'Text',
        Align: 'center',
        MinWidth: 160,
      },
      {
        Header: '자재명',
        Name: 'matName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 200,
      },
      {
        Header: '규격',
        Name: 'matSize',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 200,
      },
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Format: $getConstants('UNIT').code,
        Align: 'center',
        Width: 80,
      },
      {
        Header: '투입수량',
        Name: 'qty',
        Extend: preset.quantity,
        Width: 120,
      },
      {
        Header: '외주',
        Name: 'custName',
        Type: 'Text',
        Width: 170,
        Align: 'center',
      },
    ],
  };
};
