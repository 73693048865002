<template>
  <pmis-content-box>
    <template #title>발주목록</template>
    <template #title-left>
      <b v-if="poInfo.isOrderComplete" class="ml10" style="color:#FF0000;">해당 계약은 발주가 완료되었습니다.</b>
    </template>
    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="orderList"
      :events="{
        onSearchFinish: sheet_onSearchFinish,
        onClick: sheet_onClick,
      }"
    />

    <iui-modal name="orderRejectInfoModal" title="협력사반송" size-type="size4">
      <OrderRejectInfo isOrder />
    </iui-modal>

    <iui-modal name="ReportViewerModal" title="발주서" size-type="size6" @closed="ozContent = {}">
      <report-viewer :fomNo="fomNo" :ozContent="ozContent" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import FOM_NO from '@/constants/fomNoConstants.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import sheetOpt from './sheetOptions/orderList.js';

import OrderRejectInfo from '@/view/bidding/po/common/components/PoRejectInfo.vue';
import ReportViewer from '@/view/bidding/components/ReportViewer.vue';
export default {
  components: {OrderRejectInfo, ReportViewer},
  data() {
    return {
      sheetOpt: sheetOpt(this),
      fomNo: FOM_NO.PO_ORDER_PRINT,
      ozContent: {},
      focusKey: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'orderList', 'orderInfo', 'canEditOrder']);
    $mapActions(this, ['searchOrderList', 'searchOrderInfo']);
  },
  created() {
    this.addEvent([
      {name: 'OrderList_onSearch', func: this.onSearch},
      {name: 'OrderList_ReSearch', func: this.onReSearch},
    ]);
  },
  methods: {
    onReSearch() {
      if (this.poInfo.isOrderComplete) {
        this.callEvent({name: 'UnitContractList_onSearch', param: this.poInfo.poNo});
      } else {
        this.onSearch();
      }
    },
    onSearch(focusKey = '') {
      this.focusKey = focusKey;
      this.searchOrderList(this.poInfo.poNo);
    },
    async setRowData(row) {
      if (row) {
        await this.searchOrderInfo(row.poNo);
      }

      this.canSave = this.canEditOrder;
      this.canDelete = this.canEditOrder;
    },
    sheet_onSearchFinish(e) {
      if (e.sheet.getTotalRowCount()) {
        e.sheet.getDataRows().forEach(row => {
          if (row.poSt === 'returnSupplier') {
            e.sheet.setAttribute(row, 'poSt', 'TextStyle', 4);
            e.sheet.setAttribute(row, 'poSt', 'Cursor', 'pointer');
          }
        });

        const focusRow = e.sheet.getDataRows().find(row => row.poNo === this.focusKey);
        if (!focusRow) {
          this.setRowData(e.sheet.getFirstRow());
        } else {
          e.sheet.focus(focusRow);
          this.setRowData(focusRow);
        }
      } else {
        this.callEvent({name: 'PoOrderInfo_onAdd'});
      }
      this.focusKey = '';
    },
    async sheet_onClick(e) {
      if (e.row.Kind == 'Data') {
        await this.setRowData(e.row);

        if (e.col === 'poSt') {
          if (e.row.poSt === 'returnSupplier') {
            this.$modal.show('orderRejectInfoModal');
          }
        }

        if (e.col === 'poNo') {
          this.ozContent = JSON.parse(e.row.contractContentscontents);
          this.$modal.show('ReportViewerModal');
        }
      }
    },
  },
};
</script>

<style></style>
