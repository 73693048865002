import pick from 'lodash/pick';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import RfqCusList from '@/view/bidding/rfq/common/components/RfqCusList.vue';
import BidPlanAmt from '@/view/bidding/rfq/common/components/BidPlanAmt.vue';
import Extend from '@/view/bidding/rfq/common/components/Extend.vue';
import profile from '@/util/profileUtil';

const extendSpotDscr = {
  spotDscrYmd: $_getCurrentDate(),
  spotDscrHhMm: `${new Date().getHours() + 1}:${new Date().getMinutes()}`,
};
export default {
  name: 'BeforeOpenMixin',
  components: {RfqCusList, BidPlanAmt, Extend},
  data() {
    return {
      bidPlanAmtModalBtn: [{name: '저장', callback: this.changeBidPlanAmt}],
      extendModalBtn: [{name: '저장', callback: this.extendBidExpireDt}],
      degreeModalBtn: [{name: '인쇄', callback: this.onPrintDegree}],

      // TODO 테스트 컨텍스트 개발완료후 삭제
      visibleContext: false,
      showContext: false,
      extendSpotDscr,
      apis: {},
    };
  },
  beforeCreate() {
    $mapGetters(this, ['rfqInfo', 'rfqStatus', 'bidType']);
  },
  created() {
    this.getApis();

    this.canSave = this.rfqStatus.isRfqSpotDscrExpired && !this.rfqStatus.isCancel;
    this.addFuncSave(this.onSave);
  },
  updated() {
    this.canSave = this.rfqStatus.isRfqSpotDscrExpired && !this.rfqStatus.isCancel;
  },
  methods: {
    async getApis() {
      const apiList = [
        'selectRfqDegreeInfo',
        'openQuot',
        'updateExpireDtForTest',
        'updateSpotDscrDtForTest',
        'updateRfqCancelYn',
      ];
      if (this.bidType === 'mat') {
        this.apis = pick(await import('@/view/bidding/rfq/mat/api/rfq.js'), apiList);
      }
      if (this.bidType === 'sub') {
        this.apis = pick(await import('@/view/bidding/rfq/sub/api/subRfq.js'), apiList);
      }
    },
    async openBidPlanAmtModal() {
      if (!(await COMMON_FUNCTION.checkCertdnRegistered(this, this.bidType))) {
        return;
      }
      this.$modal.show('bidPlanAmtModal');
    },
    /** 입찰예정가 변경 */
    changeBidPlanAmt() {
      this.callEvent({
        name: 'BidPlanAmt_Save',
        param: () => {
          this.$modal.hide('bidPlanAmtModal');
          this.$alert({title: '입찰예정가 변경', message: '입찰예정가가 변경되었습니다.'});
        },
      });
    },
    /** 현설결과등록 */
    onSave() {
      if (this.rfqStatus.isRfqSpotDscrExpired) {
        this.callEvent({name: 'RfqCusList_SaveSpotDscrChkYn'});
      }
    },
    /** 현설결과취소 */
    onCancelSpotDscr() {
      this.callEvent({name: 'RfqCusList_CancelSpotDscr'});
    },
    openExtendModal() {
      this.$modal.show('extendModal');
    },
    openDegreeModal() {
      this.$modal.show('degreeModal');
    },
    /** 입찰마감시간 연장 */
    extendBidExpireDt() {
      this.callEvent({
        name: 'Extend_Save',
        param: rfqNo => {
          this.$modal.hide('extendModal');
          this.callEvent({name: 'RfqList_Search', param: rfqNo});
        },
      });
    },
    onPrintDegree() {
      this.callEvent({name: 'Degree_Print'});
    },
    /** 입찰개봉 */
    async openQuot() {
      const response = await this.apis['selectRfqDegreeInfo'](this.rfqInfo.rfqNo, this.rfqInfo.lastBidRnd);
      if (!response.data.encBidPlanAmt) {
        this.$alert({
          title: '입찰개봉',
          message: '입찰예정가가 입력되지 않았습니다.<br/>입찰예정가를 먼저 입력하시기 바랍니다.',
        });
        return;
      }

      const title = '입찰개봉';
      const message = '입찰개봉을 하시겠습니까?';
      if (!(await this.$confirm({title, message}))) {
        return;
      }

      this.apis['openQuot'](this.rfqInfo.rfqNo)
        .then(response => {
          this.callEvent({name: 'RfqList_Search', param: response.data.rfqNo});
        })
        .catch(ex => {
          if (ex.pmisError) {
            let err = ex;
            if (ex.description) {
              err += `<br/>- ${ex.description}`;
            }
            this.$alert(err);
          }
        });
    },
    async cancelRfq() {
      const title = '입찰취소';
      const message = '입찰을 취소하시겠습니까?<br/>취소된 입찰정보는 완료된 입찰에서 확인하실 수 있습니다.';

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      this.apis['updateRfqCancelYn'](this.rfqInfo.rfqNo, BIDDING_CONSTANTS.CODES.RFQ_CANCEL_Y).then(response => {
        this.callEvent({name: 'RfqList_Search'});
      });
    },
    /**
     * 테스트용
     */
    async showContextTest(e) {
      if (profile.isProd()) {
        return false;
      }

      if (!(this.rfqStatus.isRfqSpotDscr || this.rfqStatus.isRfqBidding || this.rfqStatus.isRfqSpotDscrExpired)) {
        this.showContext = false;
        return;
      }

      this.showContext = true;
      await this.$nextTick();
      const testContext = this.$refs.testContext;

      testContext.style.top = `${e.clientY}px`;
      testContext.style.left = `${e.clientX + 15}px`;
      e.preventDefault();
    },
    /**
     * 테스트용
     */
    hideContextTest() {
      this.showContext = false;
    },
    /**
     * 현설마감, 입찰마감 (테스트용)
     */
    async onExpireDtForTest() {
      const type = this.rfqStatus.isRfqSpotDscr ? '현설' : '입찰';
      const title = `${type}마감 [테스트용]`;
      const message = `${type}마감을 합니다.`;

      if (!(await this.$confirm({title, message}))) return;

      this.apis['updateExpireDtForTest'](this.rfqInfo.rfqNo, this.rfqInfo.lastBidRnd)
        .then(response => {
          if (response.status == 200) {
            this.callEvent({name: 'RfqList_Search', param: this.rfqInfo.rfqNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(err);
          }
        });
    },
    /**
     * 테스트용
     */
    showExtendSpotDscrDtModal() {
      this.$modal.show('extendSpotDscrDtModal');
    },
    /**
     * 현설일자변경 (테스트용)
     */
    async onExtendSpotDscrDt() {
      const spotDscrYmd = this.extendSpotDscr.spotDscrYmd;
      const spotDscrHhMm = this.extendSpotDscr.spotDscrHhMm;
      const spotDscrDt = `${dateFormat(spotDscrYmd)} ${spotDscrHhMm}:00`;

      const title = '현설날짜 수정 [테스트용]';
      const message = `현설날짜를 수정합니다<br/>>>> ${spotDscrDt}`;
      if (!(await this.$confirm({title, message}))) return;

      this.apis['updateSpotDscrDtForTest'](this.rfqInfo.rfqNo, this.rfqInfo.lastBidRnd, spotDscrDt).then(() => {
        this.callEvent({name: 'RfqList_Search', param: this.rfqInfo.rfqNo});

        this.extendSpotDscr = extendSpotDscr;
        this.$modal.hide('extendSpotDscrDtModal');
      });
    },
  },
};
