<template>
  <pmis-tab-box :loading="loading">
    <template #title>자료분류</template>
    <template #header-right>
      <iui-checkbox-group :items="checkboxItems" :checkedValues="[includeClosedPrj]" @change="onChange" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="loadSearchData"
      @loadSheet="sheet = $event"
      :events="{
        onRenderFirstFinish: () => onSearch(),
        onSearchFinish: sheet_onSearchFinish,
        onClick: sheet_onClick,
      }"
    />
  </pmis-tab-box>
</template>

<script>
import groupBy from 'lodash/groupBy';
import sheetOpt from './sheetOptions/DataClassificationListSheet.js';
import {selectDataClassificationList} from '@/view/edms/api/edms.js';
export default {
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],
      loading: false,

      checkboxItems: [{label: '종료사업포함'}],
      includeClosedPrj: false,
    };
  },
  computed: {
    focusKey() {
      return this.focusKeys.fbsNo;
    },
  },
  beforeCreate() {
    $mapGetters(this, ['sheetData', 'focusKeys']);
    $mapMutations(this, ['onSetSheetData', 'setFocusKeys']);
  },
  created() {
    this.addFuncSearch(this.onSearch);
    this.addEvent([{name: 'DataClassification_onSearch', func: this.onSearch}]);
  },
  methods: {
    async onSearch() {
      this.loading = true;

      const response = await selectDataClassificationList({includeClosedPrj: this.includeClosedPrj});
      this.loading = false;

      const treeList = Array.from(response.data).sort((a, b) => a.fldrNo - b.fldrNo);
      const treeRoot = treeList.find(item => item.fldrCd == 'FBS');
      const treeItems = treeList.filter((e, i) => i != 0);
      treeRoot.Items = $_treeModel(treeItems, 'FBS', 'fldrCd2', 'uppFldrCd2');
      this.loadSearchData = new Array(treeRoot);
    },
    onSearchDetail(row) {
      const currentRow = row;
      const nextRow = this.sheet.getNextRow(currentRow);

      const currentLevel = currentRow.Level;
      const nextLevel = nextRow == null ? currentLevel : nextRow.Level;

      this.onSetSheetData({...row, currentLevel, nextLevel});
      this.callEvent({name: 'DocList_Search', param: this.includeClosedPrj});
      this.callEvent({name: 'DetailInfo_InitCud'});

      this.canNew = this.sheetData.stYn !== $getConstants('Y').code;
      this.canSave = this.sheetData.stYn !== $getConstants('Y').code;
      this.canDelete = this.sheetData.stYn !== $getConstants('Y').code;
    },
    onChange(event) {
      this.includeClosedPrj = event.target.checked;
      this.onSearch();
    },
    sheet_onSearchFinish(e) {
      if (e.sheet.getTotalRowCount()) {
        const maxLevel = Math.max(...this.sheet.getDataRows().map(row => row.Level));
        for (let i = maxLevel; i > 0; i--) {
          const group = groupBy(
            this.sheet.getDataRows().filter(row => row.Level === i),
            'uppFldrCd2'
          );
          Object.values(group).forEach(children => {
            const parentRow = children[0].parentNode;
            const accCount = children.reduce((acc, curr) => acc + curr.dataCount, 0);
            this.sheet.setValue(parentRow, 'dataCount', accCount, 0);
          });
        }

        this.sheet.acceptChangedData();
        this.sheet.rerender();

        if (this.focusKey) {
          const focusRow = e.sheet.getDataRows().find(row => row.fldrNo === this.focusKey);
          if (focusRow) {
            e.sheet.focus(focusRow);
            this.onSearchDetail(focusRow);
            this.setFocusKeys({fbsNo: 0});
          }
        } else {
          this.onSearchDetail(e.sheet.getFirstRow());
        }
      } else {
        this.onSearchDetail(undefined);
      }
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data') {
        this.onSearchDetail(e.row);
      }
    },
  },
};
</script>

<style></style>
