export default that => {
  return {
    Cols: [
      {
        Header: {Value: '선택', TextColor: '#000000', HeaderCheck: 1},
        Name: 'cbx',
        Type: 'Bool',
      },
      {
        Header: '장비코드',
        Name: 'equipCode',
        Type: 'Text',
        Width: 140,
        CanEdit: false,
        Align: 'Center',
      },
      {
        Header: '장비명',
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
        CanEdit: false,
      },
      {
        Header: '규격',
        Name: 'size',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
        CanEdit: false,
      },
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Width: 60,
        CanEdit: false,
        Format: $getConstants('UNIT').code,
        Align: 'Center',
      },
      {
        Header: '차량번호',
        Name: 'carNo',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
        CanEdit: false,
      },
    ],
  };
};
