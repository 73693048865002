<template>
  <iui-container-new type="css-flex">
    <i-row height="320px">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>
            신청정보
          </template>
          <iui-container-new type="table" theme="bullet">
            <i-row>
              <i-col-header>
                신청일자
              </i-col-header>
              <i-col>
                <iui-text :value="reqDt" width="80px" :readonly="true" />
                <iui-text prefix="-" :value="consultInfo.seq" width="40px" :readonly="true" />
              </i-col>
              <i-col />
              <i-col />
            </i-row>
            <i-row>
              <i-col colspan="4">
                <iui-container-new type="table" theme="bullet">
                  <i-row>
                    <i-col-header>
                      사업자번호
                    </i-col-header>
                    <i-col width="200px">
                      <iui-bsn-no :value1="bsnNo1" :value2="bsnNo2" :value3="bsnNo3" :enable="false" />
                    </i-col>
                    <i-col-header>
                      회사명
                    </i-col-header>
                    <i-col>
                      <iui-text :value="consultInfo.frmNm" :readonly="true" />
                    </i-col>
                  </i-row>
                </iui-container-new>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>
                회사주소
              </i-col-header>
              <i-col>
                <iui-text :value="consultInfo.adr" :readonly="true" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>
                성명
              </i-col-header>
              <i-col colspan="3">
                <iui-text :value="consultInfo.name" width="100px" :readonly="true" />
                <iui-checkbox-group :items="persYnItem" :checkedValues="[consultInfo.persYn]" :readonly="true" />
                <iui-checkbox-group :items="maktYnItem" :checkedValues="[consultInfo.maktYn]" :readonly="true" />
              </i-col>
            </i-row>
            <i-row>
              <i-col colspan="3">
                <iui-container-new type="table" theme="bullet">
                  <i-row>
                    <i-col-header>
                      전화번호
                    </i-col-header>
                    <i-col width="200px">
                      <iui-text type="phone" :value="consultInfo.phone" width="100px" :readonly="true" />
                    </i-col>
                    <i-col-header>이메일</i-col-header>
                    <i-col>
                      <iui-text :value="consultInfo.email" :readonly="true" />
                    </i-col>
                  </i-row>
                </iui-container-new>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>
                문의제품
              </i-col-header>
              <i-col colspan="3">
                <iui-checkbox-group :items="costYnItem" :checkedValues="[consultInfo.costYn]" :readonly="true" />
                <iui-checkbox-group :items="laboYnItem" :checkedValues="[consultInfo.laboYn]" :readonly="true" />
                <iui-checkbox-group :items="grouYnItem" :checkedValues="[consultInfo.grouYn]" :readonly="true" />
                <iui-checkbox-group :items="eproYnItem" :checkedValues="[consultInfo.eproYn]" :readonly="true" />
              </i-col>
            </i-row>
            <i-row height>
              <i-col-header>
                문의내용
              </i-col-header>
              <i-col colspan="3">
                <iui-text type="multi" :value="consultInfo.askDetail" height="120px" :readonly="true" />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
      <i-spacer />
      <i-col>
        <pmis-content-box>
          <template v-slot:title>
            상담정보
          </template>
          <iui-container-new type="table" theme="bullet">
            <i-row>
              <i-col-header>
                접수일자
              </i-col-header>
              <i-col>
                <iui-text :value="receDt" width="90px" :readonly="true" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>
                접수자
              </i-col-header>
              <i-col>
                <iui-text :value="receNm" width="90px" :readonly="true" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>
                상담일자
              </i-col-header>
              <i-col>
                <iui-datepicker :value="String(consultInfo.consDt)" @change="setConsultInfo({consDt: $event})" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>
                상담내용
              </i-col-header>
              <i-col>
                <iui-text
                  type="multi"
                  :value="consultInfo.consultDetail"
                  @change="setConsultInfo({consultDetail: $event.target.value})"
                  height="200px"
                />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
export default {
  data() {
    return {
      persYnItem: [{label: '개인정보 수집 및 이용 동의', value: $getConstants('Y').code}],
      maktYnItem: [{label: '마케팅 활용 동의', value: $getConstants('Y').code}],
      costYnItem: [{label: '원가관리', value: $getConstants('Y').code}],
      laboYnItem: [{label: '노무관리', value: $getConstants('Y').code}],
      grouYnItem: [{label: '그룹웨어', value: $getConstants('Y').code}],
      eproYnItem: [{label: '전자조달', value: $getConstants('Y').code}],
    };
  },
  computed: {
    bsnNo1() {
      return String(this.consultInfo.bsnNo).substr(0, 3);
    },
    bsnNo2() {
      return String(this.consultInfo.bsnNo).substr(3, 2);
    },
    bsnNo3() {
      return String(this.consultInfo.bsnNo).substr(5, 5);
    },
    reqDt() {
      return dateFormat(this.consultInfo.reqDt);
    },
    receDt() {
      return this.consultInfo.receDt ? dateFormat(this.consultInfo.receDt) : dateFormat($_getCurrentDate());
    },
    receNm() {
      return this.consultInfo.receNm ? this.consultInfo.receNm : this.userInfo.name;
    },
  },
  beforeCreate() {
    $mapGetters(this, ['consultInfo']);
    $mapMutations(this, ['setConsultInfo']);
  },
  created() {
    this.addEvent({name: 'PurchsCnsltDetailInfo_onSave', func: this.onSave});
  },
  methods: {
    async onSave() {
      if (!(await this.$confirm({title: '상담정보 저장', message: '상담정보를 저장하시겠습니까?'}))) return;

      this.setConsultInfo({receDt: $_getCurrentDate(), receId: this.userInfo.empNo, receNm: this.userInfo.name});

      axios.post('/purchsCnslt/savePurchsCnsltInfo', this.consultInfo).then(response => {
        if (response.status == 200) {
          this.callEvent({name: 'PurchsCnsltSttus_onSearch'});
        }
      });
    },
  },
};
</script>

<style></style>
