export default that => {
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      CanEdit: false,
    },
    Cols: [
      {
        Header: '자료번호',
        Name: 'docNoTl',
        Type: 'Text',
        Width: 140,
        Align: 'center',
      },
      {
        Header: '자료명칭',
        Name: 'tl',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
      },
      {
        Header: '등록자',
        Name: 'rgprNm',
        Type: 'Text',
        Width: 90,
        Align: 'center',
      },
      {
        Header: '등록일자',
        Name: 'rgsDttm',
        Type: 'Text',
        Width: 90,
        Align: 'center',
      },
      {
        Header: '첨부',
        Name: 'fileCount',
        Type: 'Text',
        Width: 90,
        Align: 'center',
      },
      {Name: 'pgmCd', Visible: false},
      {Name: 'fbsNo', Visible: false},
      {Name: 'fbsCd', Visible: false},
      {Name: 'fbsNm', Visible: false},
      {Name: 'docNo', Visible: false},
      {Name: 'revNo', Visible: false},
      {Name: 'sncNo', Visible: false},
      {Name: 'rmk', Visible: false},
      {Name: 'flNo', Visible: false},
      {Name: 'rgprId', Visible: false},
      {Name: 'stYn', Visible: false},
      {Name: 'stnDocNo', Visible: false},
    ],
  };
};
