import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 0, SpaceForDefaultValue: 1},
    Def: {
      Row: {NoColor: 2, CanFocus: 1},
    },
    Cols: [
      {Header: '순위', Name: 'rowNum', Type: 'Text', Align: 'Center', Width: 45},
      {
        Header: '협력사명',
        Name: 'cusNm',
        Type: 'Text',
        Align: 'Center',
        RelWidth: 1,
        MinWidth: 150,
        TextStyle: 5,
        TextColor: 'red',
        Cursor: 'pointer',
      },
      {
        Header: '담당자명 (연락처)',
        Name: 'name',
        Type: 'Text',
        Align: 'Center',
        DefaultValue: '-',
        Width: 180,
        Formula: fr => `${fr.Row['icprNm']}${fr.Row['icprPhone'] ? ` (${fr.Row['icprPhone']})` : ''}`,
      },
      {
        Header: '현설참가신청여부',
        Name: 'spotDscrApplyAt',
        Type: 'Text',
        Align: 'Center',
        Width: 120,
        DefaultValue: '-',
        Formula: fr =>
          fr.Row['spotDscrYn'] == BIDDING_CONSTANTS.CODES.SPOT_DSCR_N ? '-' : fr.Row['spotDscrApplyYn'] ? 'O' : 'X',
      },
      {
        Header: '현설참여여부',
        Name: 'spotDscrAt',
        Type: 'Text',
        Align: 'Center',
        Width: 100,
        DefaultValue: '-',
        Formula: fr =>
          fr.Row['spotDscrYn'] == BIDDING_CONSTANTS.CODES.SPOT_DSCR_N ? '-' : fr.Row['spotDscrChkYn'] ? 'O' : 'X',
      },
      {
        Header: '응찰여부',
        Name: 'quotSbmtYn',
        Type: 'Text',
        Align: 'Center',
        Width: 100,
        CustomFormat: v => (v == BIDDING_CONSTANTS.CODES.QUOT_SBMT_Y ? 'O' : 'X'),
      },
      {
        Header: '예가대비(%)',
        Name: 'bidPlanAmtRate',
        Type: 'Text',
        Width: 120,
        Extend: preset.rate,
      },
      {
        Header: '최저가대비(%)',
        Name: 'minTotAmtRate',
        Type: 'Text',
        Width: 120,
        Extend: preset.rate,
      },
      {
        Header: '총합계금액\n(공급가+부가세)',
        Name: 'totAmt',
        Type: 'Text',
        Width: 120,
        Extend: preset.amount,
      },
      {Name: 'rfqNo', Visible: false},
      {Name: 'cusCd', Visible: false},
      {Name: 'cusPgmCd', Visible: false},
      {Name: 'icprNm', Visible: false},
      {Name: 'icprPhone', Visible: false},
      {Name: 'bidRnd', Visible: false},
      {Name: 'spotDscrYn', Visible: false},
      {Name: 'spotDscrApplyYn', Visible: false},
      {Name: 'spotDscrChkYn', Visible: false},
      {Name: 'amt', Visible: false},
      {Name: 'vatAmt', Visible: false},
    ],
  };
};
