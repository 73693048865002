const state = () => ({
  detailInfo: {
    fldrNo: '', // 폴더번호
    fldrCd: '', // 폴더코드
    fldrNm: '', // 폴더명
    uppFldrNo: '', // 상위폴더번호
    uppFldrCd: '', // 상위폴더코드
    uppFldrNm: '', // 상위폴더명
    plnWgh: '', // 물량분개비율
    tempPlnWgh: 0,
    rmk: '', // 기타사항(메모)
    isPlnWgh: true,

    childNodes: {},
  },
  isRoot: false,
  sumPlnWgh: 0,
  prjFlag: '',
});

const getters = {
  detailInfo: state => state.detailInfo,
  isRoot: state => state.isRoot,
  sumPlnWgh: state => state.sumPlnWgh,
  prjFlag: state => state.prjFlag,
};

const actions = {};

const mutations = {
  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        if (key === 'childNodes') {
          state.detailInfo[key] = payload[key].length;
          continue;
        }
        state.detailInfo[key] = String(payload[key] || '');
      }
    }
    state.isRoot = payload.isRoot ? true : false;
  },

  initDetailInfo(state) {
    for (let key in state.detailInfo) {
      state.detailInfo[key] = '';
    }
  },

  setSumPlnWgh(state, sumPlnWgh) {
    state.sumPlnWgh = sumPlnWgh;
  },

  setPrjFlag(state, prjFlag) {
    state.prjFlag = prjFlag;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
