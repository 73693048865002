<template>
  <pmis-page
    :search-project-visible="false"
    @search-click="onSearch"
    @new-click="onAdd"
    @save-click="onSave"
    @delete-click="onDelete"
  >
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="450px">
          <PageList />
        </i-col>
        <i-spacer />
        <i-col>
          <PageDetailInfo />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from '../store/page.js';
import PageList from '@/view/portletManage/components/pageList';
import PageDetailInfo from '@/view/portletManage/components/pageDetailInfo';

export default {
  components: {
    PageList,
    PageDetailInfo,
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['pageData', 'new', 'edit']);
    $mapMutations(this, ['setNewState', 'setPageData', 'initPageData', 'setFocusKeyCode']);
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'pageList_onSearch', param: null});
    },
    onAdd() {
      this.setNewState();

      if (this.pageData.level == 1) {
        this.initPageData();
        this.setPageData({level: 2});
      } else {
        let pageId = this.pageData.pageId;
        let pageName = this.pageData.pageName;
        this.initPageData();
        this.setPageData({level: 3, pageId: pageId, pageName: pageName});
      }

      // if (1 == this.pageData.level) {
      //   this.pageData.seq = null;
      //   this.pageData.size = null;
      // } else {
      //   this.pageData.pageId = null;
      //   this.pageData.webLocation = null;
      // }
    },
    async onSave() {
      if (this.pageData.level == 1) {
        this.$alert({title: '저장', message: '최상위 항목은 수정할 수 없습니다.'});
        return;
      } else if (this.pageData.level == 2) {
        if (!this.pageData.pageId || !this.pageData.pageName) {
          this.$alert({title: '저장', message: '상위 페이지 정보를 입력해야 합니다.'});
          return;
        }
      } else {
        if (2 == this.pageData.level) {
          if (!this.pageData.seq || !this.pageData.webName) {
            this.$alert({title: '저장', message: '페이지 정보를 입력해야 합니다.'});
            return;
          }
        }
        if (!this.pageData.webLocation) {
          this.$alert({title: '저장', message: '페이지 경로를 입력해야 합니다.'});
          return;
        }
      }

      if (await this.$confirm({title: '저장', message: '저장하시겠습니까?'})) {
        let url = '';
        if (this.new) {
          url = '/page/insertPageList';
        } else if (this.edit) {
          url = '/page/updatePageList';
        }

        let response = await axios.post(url, this.pageData).catch(e => console.error('저장을 실패했습니다. ' + e));
        if (response.status == 200) {
          this.setFocusKeyCode(response.data);
          this.onSearch();
        } else {
          this.$alert({title: '오류', message: '저장을 실패하였습니다.'});
        }
      }
    },
    async onDelete() {
      if (this.pageData.level == 1) {
        this.$alert({title: '삭제', message: '최상위 항목은 삭제할 수 없습니다.'});
        return;
      }
      if (await this.$confirm({title: '삭제', message: '삭제하시겠습니까?'})) {
        let response = await axios
          .post('/page/deletePageList', this.pageData)
          .catch(e => console.error('삭제에 실패했습니다. ' + e));
        if (response.status == 200) {
          this.onSearch();
        } else {
          this.$alert({title: '오류', message: '삭제에 실패하였습니다.'});
        }
      }
    },
  },
};
</script>

<style></style>
