<template>
  <!-- 장비투입정보-포틀릿 -->
  <div style="height:100%">
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr>
          <th rowspan="2">장비명<br />(차량번호)</th>
          <th colspan="3">금일({{ date.substring(4, 6) }}월 {{ date.substring(6) }}일)</th>
          <th colspan="3">금월({{ date.substring(4, 6) }}월)</th>
        </tr>
        <tr>
          <th>가동시간</th>
          <th>단가</th>
          <th>장비비</th>
          <th>가동시간</th>
          <th>단가</th>
          <th class="p_last">장비비</th>
        </tr>
      </thead>
    </table>

    <div class="inner" style="height:calc(100% - 52px);">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="(item, index) in equipInputList" :key="index">
            <td>{{ item.equipNm }}</td>
            <td class="num">{{ $_numberFormat(item.mdRunQty, true) }}</td>
            <td class="num">{{ $_numberFormat(item.mdRunPrice, true) }} 원</td>
            <td class="num">{{ $_numberFormat(item.mdRunAmt, true) }} 원</td>
            <td class="num">{{ $_numberFormat(item.mmRunQty, true) }}</td>
            <td class="num">{{ $_numberFormat(item.mmRunPrice, true) }} 원</td>
            <td class="p_last num">{{ $_numberFormat(item.mmRunAmt, true) }} 원</td>
          </tr>
          <tr>
            <td>합계</td>
            <td class="num">{{ $_numberFormat(total.mdRunQty, true) }}</td>
            <td class="num">{{ $_numberFormat(total.mdRunPrice, true) }} 원</td>
            <td class="num">{{ $_numberFormat(total.mdRunAmt, true) }} 원</td>
            <td class="num">{{ $_numberFormat(total.mmRunQty, true) }}</td>
            <td class="num">{{ $_numberFormat(total.mmRunPrice, true) }} 원</td>
            <td class="p_last num">{{ $_numberFormat(total.mmRunAmt, true) }} 원</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
import {selectEquipInput} from '@/components/portlet/api/portletData.js';
export default {
  mixins: [PortletMixin],
  props: {
    portletGroup: {
      type: String,
    },
    date: {
      type: String,
    },
    search: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cnt: 7,
      equipInputList: [],
      total: {
        mdRunQty: 0,
        mdRunPrice: 0,
        mdRunAmt: 0,
        mmRunQty: 0,
        mmRunPrice: 0,
        mmRunAmt: 0,
      },
    };
  },
  watch: {
    date(v) {
      if (v) {
        this.onSearch();
      }
    },
    search(v) {
      if (v) {
        this.onSearch();
      }
    },
  },
  methods: {
    $_numberFormat,
    setWidth() {
      this.tblWidth = this.$parent.$parent.$parent.$el.offsetWidth - 28;
    },
    async onSearch() {
      const param = {
        prjCd: this.portletGroup === $getConstants('PORTLET_GROUP_CONSTRUCTION_SITE').code ? this.prjCd : '',
        searchDt: this.date.replaceAll('-', ''),
      };
      const response = await selectEquipInput(param);
      this.equipInputList = response.data;

      for (const key in this.total) {
        this.total[key] = this.equipInputList.reduce((acc, cur) => acc + Number(cur[key]), 0);
      }

      this.$emit('update:search', false);
    },
  },
};
</script>

<style></style>
