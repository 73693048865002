<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px"><PrjList /></i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>입고목록</template>
            <pmis-content-box>
              <template v-slot:header-left>
                <label>입고일자</label>
                <iui-datepicker
                  :value="searchInfo.searchIoDate"
                  @change="onChangeIoDate($event)"
                  :open.sync="searchIoDateOpen"
                  :disabledDate="disabledDate"
                />
                <i class="prev-arrow" v-if="!isNew" v-on:click="onChangeDate('PREV')" />
                <i class="post-arrow" v-if="!isNew" v-on:click="onChangeDate('POST')" />
              </template>
              <template v-slot:header-right v-if="!isOrder">
                <iui-button value="추가" @click="onAddUnOrdered" />
              </template>
              <ib-sheet
                :uid="_uid"
                :options="Options"
                :loadSearchData="loadSearchData"
                :events="{
                  onBeforeChange,
                  onSearchFinish,
                  onRenderFirstFinish,
                  onClick,
                }"
                @loadSheet="loadSheet"
              />
            </pmis-content-box>
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal name="wearhousingPopup" title="입고등록" :btns="warehousingPopupBtns" size-type="size2">
      <WearhousingPopup />
    </iui-modal>
    <iui-modal name="standardCodePopup" title="자재선택" :btns="standardCodePopupBtns" size-type="size6">
      <StandardCodePopup type="mat" :base="false" />
    </iui-modal>
    <iui-modal name="matBudgetPopup" title="자재예산선택" :btns="matBudgetPopupBtns" size-type="size3">
      <MatBudgetPopup />
    </iui-modal>
  </pmis-page>
</template>

<script>
/**
 * 입고등록
 *
 * */
import store from './store/warehousing.js';
import PrjList from '@/view/Project/components/PrjList.vue';
import options from './components/sheetOptions/Warehousing.js';
import WearhousingPopup from './components/WarehousingPopup.vue';
import StandardCodePopup from '@/view/sysManage/stdCodeManage/components/StandardCodePopup.vue';
import MatBudgetPopup from '@/view/bidding/prManage/components/popup/MatBudgetPopup.vue';
import {selectMonthClosePossibleAt} from '@/view/closing/expitmCloseRegist/api/expitmCloseRegist.js';
import {selectLastIoDate, selectDetailList, selectDetailListByConst, saveWarehousing} from './api/warehousing.js';
export default {
  components: {
    PrjList,
    WearhousingPopup,
    StandardCodePopup,
    MatBudgetPopup,
  },
  data() {
    return {
      searchIoDateOpen: false,
      loadSearchData: [],
      Options: $addColCheckbox(options(this), 1, 'chk', {
        NoChanged: 1,
        CanEditFormula: e => this.monPjtCloseAt == 'N' && !e.Row.closeYn,
      }),

      warehousingPopupBtns: [{name: '확인', callback: this.onWarehousingPopup}],
      standardCodePopupBtns: [{name: '확인', callback: this.onStandardCodePopup}],
      matBudgetPopupBtns: [{name: '확인', callback: this.onMatBudgetPopupBtns}],

      unOrderedRow: {},

      isOrder: true, // 발주구분
      isNew: false, // 신규여부
      FILE_URL:
        location.protocol.indexOf('https') !== -1 ? process.env.VUE_APP_FILE_URLS : process.env.VUE_APP_FILE_URL,
    };
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['searchInfo', 'detailInfo', 'monPjtCloseAt']);
    $mapMutations(this, ['setSearchInfo', 'setDetailInfo', 'setMomPjtCloseAt']);
  },
  created() {
    this.addFuncSearch(this.onSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
    this.addFuncPrj(this.onSearchLastIoDate);
    this.addEvent([{name: 'onHideWarehousingPopup', func: this.onWarehousingPopup}]);
  },
  methods: {
    disabledDate(date) {
      return $_getCurrentDate() < $_dateToStringYYMMDD(date);
    },
    async onWarehousingPopup() {
      await this.onSearchMonthCloseAt(this.searchInfo.searchIoDate);
      if (this.isMonthClose) {
        this.$alert({title: '마감완료', message: '월마감 상태이므로 입고등록은 불가합니다.'});
        return;
      }
      if (!this.searchInfo.custCode) {
        this.$alert({title: '계약업체', message: '계약업체를 선택해 주세요.'});
        return;
      }
      this.callEvent({
        name: 'getWarehousingPopUp',
        param: data => {
          this.isOrder = data.poNo ? true : false;
          this.isNew = true;
          if (this.isOrder) {
            const param = {
              searchIoDate: data.searchIoDate,
              searchCustCode: data.custCode,
              poNo: data.poNo,
            };
            selectDetailListByConst(param).then(response => (this.loadSearchData = response.data));
          } else {
            let param = {
              custName: data.custName,
              custCode: data.custCode,
            };
            this.setDetailInfo(param);
            this.loadSearchData = [];
            this.unOrderedRow = {...this.detailInfo};
          }
          this.searchIoDate = data.searchIoDate;
        },
      });
      this.$modal.hide('wearhousingPopup');
    },
    onAddUnOrdered() {
      if (this.isSimpleExec) {
        this.$modal.show('standardCodePopup');
      } else {
        this.$modal.show('matBudgetPopup');
      }
    },
    onStandardCodePopup() {
      this.callEvent({
        name: 'StandardCodePopup_confirm',
        param: data => {
          const sheetRowsMatCodes = this.sheet.getDataRows().map(row => row.matCode);

          data
            .filter(item => !sheetRowsMatCodes.includes(item.matCode))
            .forEach(item => {
              const addRow = this.sheet.addRow();
              this.sheet.setValue(addRow, 'custName', this.unOrderedRow.custName, true);
              this.sheet.setValue(addRow, 'custCode', this.unOrderedRow.custCode, true);
              this.sheet.setValue(addRow, 'matCode', item.matCode, true);
              this.sheet.setValue(addRow, 'itemNm', item.itemName, true);
              this.sheet.setValue(addRow, 'sizeNm', item.size, true);
              this.sheet.setValue(addRow, 'unit', item.unit, true);
            });

          this.$modal.hide('standardCodePopup');
        },
      });
    },
    onMatBudgetPopupBtns() {
      this.callEvent({
        name: 'MatBudgetPopup_GetCheckedRows',
        param: data => {
          const sheetRowsMatCodes = this.sheet.getDataRows().map(row => row.matCode);

          data
            .filter(item => !sheetRowsMatCodes.includes(item.matCode))
            .forEach(item => {
              const addRow = this.sheet.addRow();
              this.sheet.setValue(addRow, 'custName', this.unOrderedRow.custName, true);
              this.sheet.setValue(addRow, 'custCode', this.unOrderedRow.custCode, true);
              this.sheet.setValue(addRow, 'matCode', item.itemCode, true);
              this.sheet.setValue(addRow, 'itemNm', item.itemName, true);
              this.sheet.setValue(addRow, 'sizeNm', item.size, true);
              this.sheet.setValue(addRow, 'unit', item.unit, true);
            });

          this.$modal.hide('matBudgetPopup');
        },
      });
    },
    async onSearchLastIoDate() {
      this.searchInfo.searchIoDate = '';
      this.searchInfo.searchCustCode = '';
      const response = await selectLastIoDate({});
      const lastIoDate = response.data.ioDate ? response.data.ioDate : $_getCurrentDate();
      this.searchInfo.searchIoDate = lastIoDate;
      this.onSearchMonthCloseAt(lastIoDate);
      this.onSearch();
    },
    onSearch() {
      this.isNew = false;
      this.isOrder = true;
      const param = {...this.searchInfo};
      selectDetailList(param).then(response => this.sheet.loadSearchData(response.data));
    },
    onChangeIoDate(v) {
      // 월마감 체크
      this.onSearchMonthCloseAt(v);

      // 재조회
      this.sheet.removeAll();
      this.searchInfo.searchIoDate = v;

      if (v) {
        this.onSearch();
      } else {
        this.searchIoDateOpen = true;
      }
    },
    async onSearchMonthCloseAt(ioDate) {
      if (!ioDate) return;

      const param = {pgmCd: this.pgmCd, closeMm: String(ioDate).substring(0, 6)};
      const response = await selectMonthClosePossibleAt(param);

      if (response.data.CHECK1 == 'N') {
        this.setMomPjtCloseAt('Y');
      } else {
        this.setMomPjtCloseAt('N');
      }
    },
    async onChangeDate(searchDateType) {
      const param = {...this.searchInfo, searchDateType};
      const response = await selectLastIoDate(param);

      const lastIoDate = response.data.ioDate;
      if (lastIoDate) {
        this.onSearchMonthCloseAt(lastIoDate);
        this.searchInfo.searchIoDate = lastIoDate;
        this.onSearch();
      } else {
        const title = '입고일자';
        const message = `${searchDateType == 'PREV' ? '이전' : '다음'} 입고일이 없습니다.`;
        this.$alert({title, message}, () => {
          if (!this.searchInfo.searchIoDate) {
            this.searchInfo.searchIoDate = $_getCurrentDate();
          }
        });
      }
    },
    onAdd() {
      this.setSearchInfo({custCode: '', custName: ''});
      this.$modal.show('wearhousingPopup');
    },
    onSave() {
      if (this.isMonthClose) {
        this.$alert({title: '마감완료', message: '월마감 상태이므로 입고수정은 불가합니다.'});
        return;
      }
      if (!this.sheet.hasChangedData()) {
        this.$alert({title: '입고내역저장', message: '변경된 내용이 없습니다.'});
        return;
      }

      let saveMatItemList = this.sheet.getSaveJson().data;
      if (saveMatItemList.length == 0) {
        this.$alert({title: '자재입고등록', message: '저장할 정보가 존재하지 않습니다.'});
        return;
      }
      let validCheck = true;
      let message = '';
      if (!this.isOrder) {
        saveMatItemList.forEach(row => {
          row.ioDate = this.searchInfo.searchIoDate;
          row.cud = 1;
          if (row.matCode == '') {
            validCheck = false;
            message = '자재코드가 입력되지 않았습니다.';
          } else if (row.qty == '' || row.qty == 0) {
            validCheck = false;
            message = '입고수량이 입력되지 않았습니다.';
          } else if (row.price == '' || row.price == 0) {
            validCheck = false;
            message = '단가가 입력되지 않았습니다.';
          }
        });
      }

      if (!validCheck) {
        this.$alert({title: '자재입고등록', message});
        return;
      }

      let param = {saveMatItemList};
      saveWarehousing(param)
        .then(response => {
          if (response.data == '1') {
            this.$alert({title: '마감완료', message: '월마감 상태이므로 입고등록은 불가합니다'});
          }
          this.onSearch();
        })
        .catch(e => {
          this.$alert({title: '저장실패', message: '저장을 실패했습니다 : ' + e});
        });
    },
    async onDelete() {
      if (this.isMonthClose) {
        this.$alert({title: '마감완료', message: '월마감 상태이므로 입고삭제는 불가합니다.'});
        return;
      }

      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({title: '입고내역삭제', message: '삭제할 입고내역을 선택해 주세요.'});
        return;
      }

      if (this.isNew && this.isOrder) {
        this.$alert({title: '신규등록', message: '신규등록중 삭제는 불가합니다.'});
        return;
      }

      if (this.isNew && !this.isOrder) {
        checkedRows.forEach(item => {
          this.sheet.removeRow(item);
        });
        return;
      }

      const saveMatItemList = checkedRows.map(row => {
        return {
          pgmCd: row.pgmCd,
          prjCd: row.prjCd,
          ioDate: row.ioDate,
          seq: row.seq,
          matCode: row.matCode,
          poNo: row.poNo,
          cud: 3,
        };
      });

      const param = {saveMatItemList};
      let title = '입고내역삭제';
      let message = '선택한 입고내역을 삭제하시겠습니까?';

      const validRow = checkedRows.find(row => {
        return row.outputQty !== 0 ? true : false; // 투입수량
      });

      if (validRow !== undefined) {
        await this.$alert({title, message: '투입된 정보가 존재합니다.'});
      }

      const valid = checkedRows.find(row => {
        return row.inputQtySum - row.qty < row.outputQty;
      });
      if (valid) {
        await this.$alert({title, message: '입고수량이 출고 수량보다 작을수 없습니다.'});
        return;
      }

      if (await this.$confirm({title, message})) {
        saveWarehousing(param).then(response => {
          if (response.data == '1') {
            this.$alert({title: '마감완료', message: '월마감 상태이므로 입고삭제는 불가합니다.'});
          }
          this.onSearch();
        });
      }
    },
    onBeforeChange(e) {
      if (e.col === 'qty' && e.val < 0) {
        return e.row.qtyBefore;
      }
      if (e.col === 'qty' && e.row.inputQtySum - e.row.qtyBefore + Number(e.val) < e.row.outputQty) {
        this.$alert({title: '입고수량', message: '투입된 수량보다 낮게 수정할수 없습니다.'});
        return e.row.qtyBefore;
      }
      if (e.col === 'qty' && this.isOrder && e.row.poNo != undefined) {
        const qty = Number(e.val); // 입력한 입고수량
        const qtyBefore = Number(e.row.qtyBefore); // 입력하기전 입고수량(원본)
        const contQty = Number(e.row.contQty); // 계약수량
        const inputQty = Number(e.row.inputQty); // 기입고수량
        const inputQtyAfter = Number(e.row.inputQtyAfter); // 입고일자 이후 기입고수량
        /**
         * 입고일자 이후 기입고수량 체크(inputQtyAfter)
         *   현재 기입고수량은 선택한 입고일자 기준으로 이전 입고일자들의 합계수량임
         *   선택한 입고일자 이후의 입고정보가 존재하는 경우 이후 일자의 기입고수량도 포함해서 체크해야함
         */

        let title = '입고수량';
        let message = '기입고수량과 입고수량의 합이 계약수량보다 클 수 없습니다.';

        let inventoryQty = inputQty;
        if (0 < qtyBefore) {
          inventoryQty = inventoryQty - qtyBefore;
        }
        if (0 < inputQtyAfter) {
          inventoryQty = inventoryQty + inputQtyAfter;
          message = '해당 입고일자 이후의 날짜로 이미 입고된 자재가 존재합니다.';
          message += '<br/>입고수량을 다시 한 번 확인하시기 바랍니다.';
        }

        const checkQty = inventoryQty + qty; // 계산된 기입고수량 + 입력한 입고수량
        if (contQty < checkQty) {
          this.$alert({title, message});
          return e.row.cud == 1 ? 0 : qtyBefore;
        } else {
          return qty;
        }
      }
    },
    onSearchFinish() {
      this.sheet.getDataRows().forEach(row => {
        this.sheet.setValue(row, 'qtyBefore', row.qty, true); // 입력하기전 입고수량(원본)
        this.sheet.acceptChangedData(row);

        if (!this.isOrder) {
          // 미발주시 자재코드 버튼 활성화
          this.sheet.setAttribute(row, 'matCode', 'Button', 'Html', 1);
          this.sheet.setAttribute(row, 'matCode', 'ButtonText', '<button type="button" class="i_search"></button>', 1);
          this.sheet.setAttribute(
            row,
            'matCode',
            'OnClickSide',
            () => {
              this.$modal.show('matBudgetPopup');
            },
            1
          );
        }
      });
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    onClick(e) {
      if (e.row.Kind == 'Data' && e.col == 'poNo' && e.row.poNo != null && e.row.poNo != '') {
        this.callEvent({
          name: 'showPdf',
          param: {src: this.getImageUrl(e.row.flNo, e.row.revId), fileName: e.row.lclFlNm},
          isDefault: true,
        });
      }
    },
    getImageUrl(flNo, revId) {
      return `${this.FILE_URL}/file/getImage?pgmCd=${this.pgmCd}&flNo=${flNo}&revId=${revId}`;
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
  computed: {
    isMonthClose() {
      return this.monPjtCloseAt == 'Y'; // 월마감 가능여부
    },
  },
};
</script>

<style></style>
