export default that => {
  return {
    Cols: [
      {
        Header: '문서번호',
        Name: 'docNo',
        Type: 'Text',
      },
      {
        Header: '제목',
        Name: 'tl',
        Type: 'Text',
        RelWidth: 1,
      },
      {
        Header: '기안자',
        Name: 'empNm',
        Type: 'Text',
      },
      {
        Header: '최종결재자',
        Name: 'lastApprovalEmpNm',
        Type: 'Text',
      },
      {
        Header: '결재완료일시',
        Name: 'enfcDt',
        Type: 'Text',
      },
      {
        Header: '수신자',
        Name: 'receiptEmpNm',
        Type: 'Text',
      },
      {
        Name: 'sncNo',
        Visible: false,
      },
      {
        Name: 'flNo',
        Visible: false,
      },
      {
        Name: 'lclFlNm',
        Visible: false,
      },
      {
        Name: 'flSz',
        Visible: false,
      },
      {
        Name: 'svrFlPth',
        Visible: false,
      },
      {
        Name: 'svrFlNm',
        Visible: false,
      },
      {
        Name: 'lclFlNm',
        Visible: false,
      },
    ],
  };
};
