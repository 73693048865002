export const selectAnyTimeMatSearchData = async () =>
  await axios.post('/closeAnyTimeMat/selectAnyTimeMatSearchData', {});

export const selectMatAnmonList = async param => await axios.post('/closeAnyTimeMat/selectMatAnmonList', param);

export const selectRegisterMatDetail = async param =>
  await axios.post('/closeAnyTimeMat/selectRegisterMatDetail', param);

export const selectRegisterMatInfo = async param => await axios.post('/closeAnyTimeMat/selectRegisterMatInfo', param);

export const deleteMatAnmon = async param => await axios.post('/closeAnyTimeMat/deleteMatAnmon', param);
