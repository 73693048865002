<template>
  <div :style="theme != 'bullet' ? 'border: 1px solid #ccc' : ''">
    <file-upload
      extensions="gif,jpg,jpeg,png,webp,jfif"
      accept="image/png,image/gif,image/jpeg,image/webp"
      :headers="postHeader"
      :post-action="internalPostAction"
      ref="upload"
      v-model="internalFiles"
      name="files"
      :drop="allowAdd"
      :multiple="false"
      @input="fileUpload_input"
      @input-filter="fileUpload_inputFilter"
      @input-file="fileUpload_inputFile"
      style="display: none;"
    ></file-upload>
    <div v-if="theme == 'bullet'">
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header width="30%">
            {{ title }}
          </i-col-header>
          <i-col>
            <div style="width:calc(100% - 7px); display: flex;justify-content: flex-end;">
              <iui-button v-if="allowAdd && isAuth" :enable="buttonEnable" @click="btnAdd_click"
                >{{ addButtonText === '추가' && fileNumber ? updateButtonText : addButtonText }}
              </iui-button>
              <iui-button v-if="allowDelete && isAuth && fileNumber" :enable="buttonEnable" @click="btnDelete_click"
                >{{ deleteButtonText }}
              </iui-button>
              <template v-if="fileNumber != 0">
                <iui-button @click="onDownload">다운로드</iui-button>
              </template>
              <!-- <iui-button
                v-if="saveButtonVisible && isAuth && fileNumber != 0"
                :enable="buttonEnable"
                @click="btnSave_click"
              >
                {{ saveButtonText }}
              </iui-button> -->
            </div>
          </i-col>
        </i-row>
        <i-row>
          <i-col colspan="2">
            <div
              v-if="!internalImageSource && (internalFileNumber == 0 || !internalFileNumber)"
              class="no_image"
              ref="img"
              :style="{
                display: 'block',
                margin: '1px auto',
                width: imageWidth,
                height: calcHeight,
                maxWidth: imageMaxWidth,
                maxHeight: imageMaxHeight,
                overflow: 'hidden',
                border: '1px solid #ccc',
              }"
            ></div>
            <img
              ref="img"
              v-else
              :src="imageSource"
              :style="{
                display: 'block',
                margin: '1px auto',
                width: imageWidth,
                height: calcHeight,
                maxWidth: imageMaxWidth,
                maxHeight: imageMaxHeight,
                overflow: 'hidden',
                border: '1px solid #ccc',
              }"
              :onerror="imageError"
            />
          </i-col>
        </i-row>
      </iui-container-new>
    </div>
    <div v-else>
      <iui-content-box>
        <template v-slot:header-left v-if="title">
          <label>{{ title }}</label>
        </template>
        <template v-slot:header-right v-if="allowAdd || allowDelete || saveButtonVisible">
          <iui-button v-if="allowAdd && isAuth" :enable="buttonEnable" @click="btnAdd_click"
            >{{ addButtonText === '추가' && fileNumber ? updateButtonText : addButtonText }}
          </iui-button>
          <iui-button v-if="allowDelete && isAuth && fileNumber" :enable="buttonEnable" @click="btnDelete_click"
            >{{ deleteButtonText }}
          </iui-button>
          <template v-if="fileNumber != 0">
            <iui-button @click="onDownload">다운로드</iui-button>
          </template>
          <!-- <iui-button
            v-if="saveButtonVisible && isAuth && fileNumber != 0"
            :enable="buttonEnable"
            @click="btnSave_click"
          >
            {{ saveButtonText }}
          </iui-button> -->
        </template>
        <!-- <img :src="imageSource" style="width: 190px; height: auto; display: block; margin: 1px auto;" /> -->
        <div
          v-if="!internalImageSource && (internalFileNumber == 0 || !internalFileNumber)"
          class="no_image"
          ref="img"
          :style="{
            display: 'block',
            margin: '1px auto',
            width: imageWidth,
            height: calcHeight,
            //maxWidth: imageMaxWidth,
            maxHeight: imageMaxHeight,
            overflow: 'hidden',
          }"
        ></div>
        <img
          v-else
          ref="img"
          :src="imageSource"
          :style="{
            display: 'block',
            margin: '1px auto',
            width: imageWidth,
            height: calcHeight,
            //maxWidth: imageMaxWidth,
            maxHeight: imageMaxHeight,
            overflow: 'hidden',
          }"
          :onerror="imageError"
        />
      </iui-content-box>
    </div>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component';
import {getRefreshToken} from '@/common';
import {mapGetters} from 'vuex';
import FILE_CONSTATNS from '@/components/js/fileConstatns.js';

export default {
  name: 'pmis-image-file',
  components: {
    FileUpload: FileUpload,
  },
  props: {
    companyCode: {
      type: [Number, String],
    },
    title: {
      type: [Number, String],
    },
    fileNumber: {
      type: [Number, String],
      default: 0,
    },
    groupNumber: {
      type: [Number, String],
      default: 0,
    },
    projectCode: {
      type: String,
      default: undefined,
    },
    fbsNo: {
      type: String,
      default: undefined,
    },
    thumbnail: {
      type: Boolean,
      default: false,
    },
    allowAdd: {
      type: Boolean,
      default: true,
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
    addButtonText: {
      type: String,
      default: '추가',
    },
    updateButtonText: {
      type: String,
      default: '변경',
    },
    deleteButtonText: {
      type: String,
      default: '삭제',
    },
    saveButtonText: {
      type: String,
      default: '저장',
    },
    saveButtonVisible: {
      type: Boolean,
      default: true,
    },
    save: {
      type: Boolean,
      default: false,
    },
    autoSave: {
      type: Boolean,
      default: true,
    },
    imageWidth: {
      type: String,
      default: '100%',
    },
    imageHeight: {
      type: String,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    initInternalImageSource: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: undefined,
    },
    saveAuth: {
      type: Boolean,
      default: undefined,
    },
    temp: {
      type: Boolean,
      default: true,
    },
    id: {
      required: true,
      type: [String, Number],
    },
    flNoCol: {
      type: String,
      default: 'flNo',
    },
    widthRatio: {
      type: Number,
      default: 3,
    },
    heightRatio: {
      type: Number,
      default: 3,
    },
    isLogo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FILE_URL:
        location.protocol.indexOf('https') !== -1 ? process.env.VUE_APP_FILE_URLS : process.env.VUE_APP_FILE_URL,
      token: this.$store.getters['jwt/accessToken'],
      internalFileNumber: 0,
      internalGroupNumber: 0,
      internalTicks: new Date().getTime(),
      internalImageSource: undefined,
      internalName: `file${this._uid}`,
      internalFiles: [],
      internalStatus: '',
      internalLclFlNm: '',
      internalRevId: '',
      maxFileSize_mb: 50, // 50MB
      megaByte: 1024 * 1024,
      isUpdate: false,

      internalAction: {
        search: false,
        add: false,
        delete: false,
        save: false,
        clear: false,
      },

      imageMaxWidth: '',
      imageMaxHeight: '',

      interface: {
        flNo: 0,
        error: '',
        save: this.saveProc,
        progressInfo: {},
        currentId: `${this._uid}_image`,
      },
      calcHeight: '',
      imageError: `this.src="${require('@/assets/img/img_no_image.png')}"`,
    };
  },
  computed: {
    ...mapGetters('mdi', ['currentUrls']),
    internalPgmCd() {
      return this.companyCode || this.pgmCd;
    },
    internalPostAction() {
      return `${this.FILE_URL}/file/saveFile`;
    },
    buttonEnable() {
      return (
        !this.internalAction.search &&
        !this.internalAction.add &&
        !this.internalAction.delete &&
        !this.internalAction.save &&
        !this.internalAction.clear
      );
    },
    postHeader: {
      get: function() {
        return {Authorization: this.token};
      },
      set: function(token) {
        this.token = token;
      },
    },
    imageSource() {
      let src = undefined;
      if (this.internalImageSource === undefined) {
        let flDs = this.thumbnail
          ? this.$store.state.code2.codeAlias.FLDS_IMAGE_THUMBNAIL.code
          : this.$store.state.code2.codeAlias.FLDS_IMAGE.code;

        src = `${this.FILE_URL}/file/getImage?pgmCd=${this.internalPgmCd}&flNo=${this.internalFileNumber}&grpNo=${this.internalGroupNumber}&flDs=${flDs}&ticks=${this.internalTicks}`;
      } else {
        src = this.internalImageSource;
      }

      return src;
    },
    showHeaderRight() {
      return this.allowAdd || this.allowDelete || this.saveButtonVisible;
    },
    isAuth() {
      return this.saveAuth !== undefined ? this.saveAuth : this.currentUrls.userSvYn == $getConstants('Y').code;
    },
  },
  watch: {
    fileNumber(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalFileNumber = newValue;
        this.internalAction.search = true;
        this._search();
      }
    },
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalAction.search = newValue;

        if (newValue) {
          this._search();
        }
      }
    },
    add(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalAction.add = newValue;

        if (newValue) {
          this.btnAdd_click();
        }
      }
    },
    delete(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalAction.delete = newValue;

        if (newValue) {
          this.btnDelete_click();
        }
      }
    },
    save(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalAction.save = newValue;

        if (newValue) {
          this.btnSave_click();
        }
      }
    },
    clear(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalAction.clear = newValue;

        if (newValue) {
          this._clear();
        }
      }
    },
    initInternalImageSource(value) {
      if (value) {
        this.internalImageSource = undefined;
      }
    },
  },
  created() {
    this.$addFileComponent(this);

    this.internalFileNumber = this.fileNumber;
    this.internalGroupNumber = this.groupNumber;

    this.$on('update:search', e => {
      this.internalAction.search = e;
    });
    this.$on('update:add', e => {
      this.internalAction.add = e;
    });
    this.$on('update:delete', e => {
      this.internalAction.delete = e;
    });
    this.$on('update:save', e => {
      this.internalAction.save = e;
    });
    this.$on('update:clear', e => {
      this.internalAction.clear = e;
    });

    this._search();
  },
  mounted() {
    this.initImageSize();
    window.addEventListener('resize', this.initImageSize);
  },
  methods: {
    initImageSize() {
      this.imageMaxWidth = `${this.$parent.$el.getBoundingClientRect().width - 5}px`;
      this.imageMaxHeight = `${this.$parent.$el.getBoundingClientRect().height - 40}px`;
      this.calcHeight = this.imageHeight
        ? this.imageHeight
        : `${(parseFloat(this.$refs.img.getBoundingClientRect().width / this.widthRatio) * this.heightRatio).toFixed(
            3
          )}px`;
    },
    initInterface() {
      this.interface.flNo = 0;
      this.interface.error = '';
      this.interface.progressInfo = {};
    },
    btnAdd_click(e) {
      if (this.fileNumber !== 0) {
        this.isUpdate = true;
      }
      if (e !== undefined) {
        this.internalAction.add = true;
      }

      this.$refs.upload.$children[0].$el.click();

      this.$emit('update:add', false);
    },
    async btnDelete_click(e) {
      if (!this.fileNumber) return;
      if (!(await this.$confirm({title: '삭제', message: '삭제하시겠습니까?'}))) return;
      if (e !== undefined) {
        this.internalAction.delete = true;
      }

      await this.deleteFile();

      this.$emit('update:delete', false);
    },
    saveProc() {
      if (this.internalFiles.length === 0) {
        if (this.interface.state) this.interface.state(0);
        this.$emit('update:save', false);
        return;
      }
      this.$emit('save-start');
      this.$emit('upload-start');
      this.$refs.upload.update(this.internalFiles[0], {active: true, error: '', progress: '0.00'});
    },
    async btnSave_click(e) {
      if (e !== undefined) {
        this.internalAction.save = true;
      }
      this.saveProc();
    },
    async _search() {
      if (this.internalFileNumber !== 0) {
        const data = {
          pgmCd: this.internalPgmCd,
          flNo: this.internalFileNumber,
          flDs: this.thumbnail
            ? this.$store.state.code2.codeAlias.FLDS_IMAGE_THUMBNAIL.code
            : this.$store.state.code2.codeAlias.FLDS_IMAGE.code,
        };

        try {
          const response = await axios.post(`${this.FILE_URL}/file/selectFileList`, data);
          this.internalGroupNumber = response.data[0].grpNo;
          this.internalLclFlNm = response.data[0].lclFlNm;
          this.internalRevId = response.data[0].revId;
          this.$emit('update:groupNumber', this.internalGroupNumber);
        } catch (ex) {
          // error
        }
      }

      this.$emit('update:changed', false);
      this.$emit('update:search', false);
    },
    async deleteFile() {
      const data = [
        {
          pgmCd: this.internalPgmCd,
          flNo: this.internalFileNumber,
          grpNo: this.internalGroupNumber,
        },
      ];

      this.$emit('delete-start');

      try {
        await axios.post('/file/deleteFile', data);
        this.internalImageSource = undefined;
        this.internalFileNumber = 0;
        if (this.internalAction.delete) {
          this.$emit('delete-complete', this.internalFileNumber);
        }
      } catch (ex) {
        this.$emit('delete-error');
      }
    },
    fileUpload_input() {},
    fileUpload_inputFilter(newFile, oldFile, prevent) {
      if (newFile) {
        let fileLength = newFile.name.length;
        let fileDot = newFile.name.lastIndexOf('.');
        let fileExtension = newFile.name.substr(fileDot + 1, fileLength).toLowerCase();

        if (this.isLogo && FILE_CONSTATNS.LOGO_IMAGE_REGEXP.test(fileExtension)) {
          this.$alert({title: '확장자 제한', message: `로고는 png 파일만 등록가능합니다.`});
          return prevent();
        }
        if (!FILE_CONSTATNS.IMAGE_REGEXP.test(fileExtension)) {
          this.$alert({title: '확장자 제한', message: `${fileExtension} 확장자는 등록이 불가능합니다.`});
          return prevent();
        }
        if (newFile.size > this.maxFileSize_mb * this.megaByte) {
          this.$alert({title: '파일크기 제한', message: `총 ${this.maxFileSize_mb}MB 이하로 등록가능합니다.`});
          return prevent();
        }
      }
      if (newFile && !oldFile) {
        // Add file
        this.$emit('update:changed', true);
      }

      if (newFile && oldFile) {
        // Update file
      }

      if (!newFile && oldFile) {
        // Remove file
        // Refused to remove the file
        // return prevent()
      }
    },
    async fileUpload_inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // Add file
        const data = {
          pgmCd: this.internalPgmCd,
          flNo: this.internalFileNumber,
          flDs: this.$store.state.code2.codeAlias.FLDS_IMAGE.code,
          prjCd: this.projectCode,
          fbsNo: this.fbsNo,
          lclFlNm: newFile.name,
          // svrFlNm: newFile.name,
          flSz: newFile.size,
          temp: this.isUpdate ? false : this.temp,
          tempFile: this.isUpdate ? '' : null,
          updateStatus: this.fileNumber != 0,
        };

        newFile.data = data;

        // 미리 보기
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          this.internalImageSource = URL.createObjectURL(newFile.file);
        }

        this.interface.progressInfo[`${this._uid}_image`] = {
          progress: 0.0,
          name: newFile.name,
          size: newFile.size,
          speed: newFile.speed,
        };

        if (this.autoSave && this.fileNumber) {
          this.internalAction.save = true;
          this.btnSave_click();
        }
      }

      if (newFile && oldFile) {
        // Update file
        if (newFile.xhr) {
          if (!(newFile.xhr.status == 401 && newFile.xhr.response.indexOf('expired') != -1)) {
            if (String(newFile.xhr.status).substr(0, 1) != '2' && newFile.xhr.response) {
              if (this.interface.state) this.interface.state(-1);
              this.interface.error = newFile.xhr.response;
              this.$emit('update:save', false);
              this.$emit('upload-error', newFile.name);
              this.$emit('save-error');
            }
          }
        }

        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          // if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
          //   this.$refs.upload.update(newFile, {error: 'size'});
          // }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
          this.interface.progressInfo[`${this._uid}_image`].progress = newFile.progress;
          this.interface.progressInfo[`${this._uid}_image`].speed = newFile.speed;
        }
        if (newFile.error && !oldFile.error) {
          if (newFile.error == 'denied' && newFile.response.error_description.indexOf('expired') != -1) {
            this.postHeader = await getRefreshToken();
            newFile.headers.Authorization = this.postHeader.Authorization;
            this.$refs.upload.update(newFile, {active: true, error: '', progress: '0.00'});
          } else {
            // error
            if (this.interface.state) this.interface.state(-1);
            this.interface.error = newFile.error;
            this.$emit('update:save', false);
            this.$emit('upload-error', newFile.name);
            this.$emit('save-error');
          }
        }
        if (newFile.success && !oldFile.success) {
          // success
          // 파일번호가 초기값이면 업로드 후 생성된 파일번호를 신규 데이터에 설정한다.
          // if (this.internalFileNumber !== 0) {
          //   await this.deleteFile();
          // }
          this.internalFileNumber = newFile.response.flNo;
          this.$emit('update:fileNumber', this.internalFileNumber);

          this.internalGroupNumber = newFile.response.grpNo;
          this.$emit('update:groupNumber', this.internalGroupNumber);

          this.internalRevId = newFile.response.revId;

          // this.internalStatus = '업로드 완료';
          if (this.isUpdate) {
            this.internalImageSource = undefined;
          }
          this.$emit('upload-complete', newFile.name);
          this.$refs.upload.remove(newFile);

          this.$emit('update:save', false);
          this.$emit('update:changed', false);
          this.$emit('save-complete', this.internalFileNumber);
          this.updateTicks();

          this.interface.flNo = this.internalFileNumber;
          if (this.interface.state) this.interface.state(2);
          if (this.interface.state) this.interface.state(3);
        }
      }

      if (!newFile && oldFile) {
        // Remove file
      }
    },
    updateTicks() {
      this.internalTicks = new Date().getTime();
    },
    _clear() {
      this.initInterface();
      this.internalImageSource = undefined;
      this.$emit('update:fileNumber', 0);
      this.$emit('update:clear', false);
    },
    getFileDownUrl(flNo, revId) {
      return `${this.FILE_URL}/file/download?pgmCd=${this.internalPgmCd}&flNo=${flNo}&revId=${revId}`;
    },
    async onDownload() {
      const lclFlNm = this.internalLclFlNm;
      const ext = lclFlNm.substr(lclFlNm.lastIndexOf('.') + 1);
      const response = await axios.get(this.getFileDownUrl(this.internalFileNumber, this.internalRevId), {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data], {type: $getMineType(ext)}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', lclFlNm);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
  },
  destroyed() {
    this.$removeFileComponent(this);
    window.removeEventListener('resize', this.initImageSize);
  },
};
</script>

<style></style>
