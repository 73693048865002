export default that => {
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      CanEdit: 0, // 1: 전체 편집 가능 (default)
      MainCol: 'fldrNm', //트리구조로 만들 필드
    },
    Cols: [
      {
        Header: '부서',
        Name: 'fldrNm',
        Type: 'Text',
        Align: 'Left',
        RelWidth: 1,
      },
      {
        Header: '인원',
        Name: 'belongEmpCnt',
        Type: 'Text',
        Align: 'Center',
        Width: 100,
      },
      {
        Header: '코드',
        Name: 'fldrCd',
        Visible: 0,
      },
    ],
  };
};
