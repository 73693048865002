/** 단가계약목록 */
export const selectUnitContractList = searchOptions => axios.post('/order/selectUnitContractList', searchOptions);
/** 발주목록 */
export const getOrderList = poNo => axios.get(`/order/orderList/${poNo}`);
/** 발주정보 */
export const getOrderInfo = poNo => axios.get(`/order/orderInfo/${poNo}`);
/** 발주정보 생성을 위한 게약내역 */
export const getPoItemListForOrder = poNo => axios.get(`/order/poItemListForOrder/${poNo}`);

/** 발주정보 생성 */
export const createOrder = (param, afterValidation) => $save('/order/createOrder', param, {afterValidation});
/** 발주정보 저장 */
export const saveOrder = (param, afterValidation) => $save('/order/saveOrder', param, {afterValidation});
/** 발주정보 삭제 */
export const deleteOrder = poNo => axios.delete(`/order/deleteOrder/${poNo}`);
/** 발주서 송부 */
export const sendOrder = (poNo, contSeq) => axios.post('/order/sendOrder', {poNo, contSeq});
/** 발주서 회수 */
export const retrieveOrder = (poNo, contSeq) => axios.post('/order/retrieveOrder', {poNo, contSeq});
/** 발주서 접수(공급사) */
export const confirmOrder = (poNo, contSeq) => axios.post('/order/confirmOrder', {poNo, contSeq});
/** 발주서 반송(공급사) */
export const rejectOrder = param => axios.post('/order/rejectOrder', param);
