<template>
  <div id="app" class="h_100">
    <Popup />
    <iui-alert />
    <iui-confirm />
    <iui-pdf-viewer />
    <pmis-file-progress />
  </div>
</template>

<script>
import {modalClose} from '@/modalEvent';
import Popup from '@/view/Popup.vue';

export default {
  components: {
    Popup,
  },
  name: 'app',
  beforeCreate() {
    window.addEventListener('keyup', event => {
      if (event.keyCode === 27) {
        modalClose(this);
      }
    });
  },
};
</script>

<style></style>
