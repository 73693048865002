export default that => {
  return {
    Cfg: {
      CanEdit: 0,
      DataMerge: 1,
    },
    Cols: [
      {
        Header: ['NO'],
        Name: 'rownum',
        Type: 'Text',
        Align: 'center',
        Width: '50',
      },
      {
        Header: ['공사코드'],
        Name: 'wcode',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['대공종코드'],
        Name: 'headCode',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['중공종코드'],
        Name: 'midCode',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['도급코드'],
        Name: 'itemCode',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['품명'],
        Name: 'itemName',
        Type: 'Text',
        Align: 'center',
        Width: 160,
        ColMerge: 0,
      },
      {
        Header: ['규격'],
        Name: 'ssize',
        Type: 'Text',
        Align: 'center',
        Width: 110,
        ColMerge: 0,
      },
      {
        Header: ['단위'],
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['수량'],
        Name: 'qty',
        Type: 'Text',
        Align: 'Right',
        Width: 85,
        ColMerge: 0,
      },
      {
        Header: ['재료비단가'],
        Name: 'matPrice',
        Type: 'Text',
        Align: 'Right',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['노무비단가'],
        Name: 'labPrice',
        Type: 'Text',
        Align: 'Right',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['경비단가'],
        Name: 'oprPrice',
        Type: 'Text',
        Align: 'Right',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['지급자재여부'],
        Name: 'matGiveYn',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
    ],
  };
};
