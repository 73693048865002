<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text
        type="search"
        :value="searchInfo.searchNm"
        @change="searchInfo.searchNm = $event.target.value"
        @enter="onSearch"
      />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onRenderFirstFinish,
        onDblClick,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>
<script>
/**
 * 장비코드 조회 팝업
 * 보유장비 또는 임차장비를 조회
 * 사용 :
 *        가동장비등록
 */
import options from './sheetOptions/HoldEquipmentListPopupSheet.js';
import {selectEqutCompanyCodeMList, selectEqutCodeMList} from '../api/OperationEquipment.js';
export default {
  props: {
    uid: {
      type: Number,
    },
  },
  beforeCreate() {},
  created() {
    this.addEvent([{name: 'HoldEquipmentListPopup_callbackData', func: this.onFocusedRowData}]);
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      loadSearchData: [],
      Options: options(this),
      searchInfo: {
        searchSe: '',
        searchNm: '',
      },
      useGb: this.$store.getters['modalParam'].useGb,
    };
  },
  methods: {
    onRenderFirstFinish() {
      this.onSearch();
    },
    onDblClick(e) {
      this.$modalConfirm(e);
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    async onSearch() {
      let param = {
        pgmCd: this.pgmCd,
        prjCd: this.$store.getters['modalParam'].prjCd,
        searchSe: this.searchInfo.searchSe,
        searchNm: this.searchInfo.searchNm,
      };
      let response;
      if (this.useGb == $getConstants('USE_GB_PRIVATE').code) {
        response = await selectEqutCompanyCodeMList(param);
      } else {
        response = await selectEqutCodeMList(param);
      }
      this.loadSearchData = response.data;
    },
    onFocusedRowData(callbackFunction) {
      if (typeof callbackFunction == 'function') {
        let focusedRow = this.sheet.getFocusedRow();
        if (focusedRow == null) {
          this.$alert({title: '선택확인', message: '장비를 선택해 주세요.'});
          return;
        }
        callbackFunction(focusedRow);
      }
    },
    setValue(t, e) {
      this.searchInfo[t] = e.target.value;
    },
  },
};
</script>

<style scoped></style>
