export default that => {
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      HeaderMerge: 6,
      CanEdit: true,
    },
    Def: {
      Row: {
        CanFocus: true,
      },
    },
    Cols: [
      {
        Header: ['대공종명', '대공종명'],
        Name: 'headName',
        Type: 'Text',
        RelWidth: 2,
        CanEdit: false,
      },
      {
        Header: ['중공종명', '중공종명'],
        Name: 'midName',
        Type: 'Text',
        RelWidth: 2,
        CanEdit: false,
      },
      {
        Header: ['내역\n코드', '내역\n코드'],
        Name: 'itemSeq',
        Type: 'Text',
        Width: 60,
        Align: 'center',
        CanEdit: false,
      },
      {
        Header: ['내역명', '내역명'],
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 2,
        CanEdit: false,
      },
      {
        Header: ['규격', '규격'],
        Name: 'ssize',
        Type: 'Text',
        RelWidth: 2,
        CanEdit: false,
      },
      {
        Header: ['단위', '단위'],
        Name: 'unit',
        Type: 'Text',
        RelWidth: 1,
        Align: 'center',
        CanEdit: false,
      },
      {
        Header: ['수량', '수량'],
        Name: 'moqty',
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.quantity,
        CanEdit: false,
      },
      {
        Header: ['금액', '금액'],
        Name: 'amt',
        RelWidth: 2,
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: false,
      },
      {Name: 'pgmCd', Visible: false},
      {Name: 'prjCd', Visible: false},
      {Name: 'wcode', Visible: false},
      {Name: 'headCode', Visible: false},
      {Name: 'midCode', Visible: false},
    ],
  };
};
