<template>
  <form onsubmit="return false;">
    <pmis-tab-box>
      <template v-slot:title>정산금액</template>
      <p class="bu_title">금월정산금액</p>
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header required>
            월출역일수
          </i-col-header>
          <i-col>
            <iui-text type="number" width="120px" :enable="false" :value="detailInfo.monthlyDay" />
          </i-col>
          <i-col-header required>
            월투입공수
          </i-col-header>
          <i-col>
            <iui-text type="number" width="120px" :enable="false" :value="detailInfo.monthlyQty" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header required>
            월투입금액
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.monthlyAmt" />
          </i-col>
          <i-col> </i-col>
          <i-col></i-col>
        </i-row>
      </iui-container-new>
      <hr />
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header>
            건강보험
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :value="detailInfo.healthInsurAmt"
              @change="setHealthInsurAmt(Number($event.target.value))"
              :enable="cud === 1"
            />
          </i-col>
          <i-col-header>
            장기요양
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :value="detailInfo.careAmt"
              @change="setCareAmt(Number($event.target.value))"
              :enable="cud === 1"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            국민연금
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :value="detailInfo.pensionAmt"
              @change="setPensionAmt(Number($event.target.value))"
              :enable="cud === 1"
            />
          </i-col>
          <i-col-header>
            고용보험
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :value="detailInfo.employInsurAmt"
              @change="setEmployInsurAmt(Number($event.target.value))"
              :enable="cud === 1"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            소득세
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.incometax" />
          </i-col>
          <i-col-header>
            지방소득세
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.resitax" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            공제계
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.deductSum" />
          </i-col>
          <i-col> </i-col>
          <i-col> </i-col>
        </i-row>
      </iui-container-new>
      <hr />
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header>
            실지급액
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.actPayAmt" />
          </i-col>
          <i-col> </i-col>
          <i-col> </i-col>
        </i-row>
      </iui-container-new>
    </pmis-tab-box>
  </form>
</template>

<script>
export default {
  beforeCreate() {
    $mapGetters(this, 'labor', ['detailInfo']);
    $mapActions(this, 'labor', ['setEmployInsurAmt', 'setPensionAmt', 'setHealthInsurAmt', 'setCareAmt']);
  },
};
</script>

<style scoped>
hr {
  height: 1px;
  border: 0;
  background-color: #ccc;
  margin: 10px 5px;
}
</style>
