<template>
  <iui-container-new type="css-flex">
    <i-row height="150px">
      <i-col>
        <p class="bu_title">상세정보</p>
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col-header required> 단위작업명 </i-col-header>
            <i-col>
              <div class="iui-searchbox">
                <iui-text
                  required
                  type="text"
                  name="wbsNm"
                  width="150px"
                  :value="detailInfo.wbsNm"
                  :bindArray="bindArray"
                  :enable="false"
                />
                <iui-button v-if="detailInfo.cud == '1'" value="선택" @click="onShowModal" />
              </div>
            </i-col>
          </i-row>
          <i-row>
            <i-col-header required> 촬영일자 </i-col-header>
            <i-col>
              <iui-datepicker
                name="phoDt"
                :value="detailInfo.phoDt"
                :bindArray="bindArray"
                required
                errorMessage="필수입력입니다"
                :enable="detailInfo.cud == 1"
              />
              <iui-text
                type="text"
                name="seq"
                width="55px"
                :value="detailInfo.seq"
                :bindArray="bindArray"
                :enable="false"
              />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header> 사진설명 </i-col-header>
            <i-col>
              <iui-text type="multi" name="rmk" :value="detailInfo.rmk" :bindArray="bindArray" max-length="2000" />
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box>
          <pmis-file-list
            id="file1"
            flNoCol="flNo"
            title="사진정보"
            title-visible
            regExpType="image"
            :toolbar-visible="true"
            :file-number.sync="detailInfo.flNo"
            v-model="files"
            @save-complete="onSaveCompleteFile"
            @delete-complete="onDeleteCompleteFile"
          />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <iui-card-carousel
          v-if="detailInfo.cud != 1"
          :showViewer="true"
          :items="photos.tImgs"
          :imageWidth="170"
          :imageHeight="170"
        />
      </i-col>
    </i-row>
    <iui-modal name="UnitOpertListModal" :btns="modalBtns" title="단위작업명 선택" sizeType="size1">
      <UnitOpertListPopup />
    </iui-modal>
  </iui-container-new>
</template>

<script>
import {deleteConstructPhoto, saveConstructPhoto, updateFlNo0} from '../api/photo';
/**
 * 공정사진 등록
 * */
import UnitOpertListPopup from './UnitOpertListPopup.vue';
export default {
  components: {
    UnitOpertListPopup,
  },
  data: function() {
    return {
      bindArray: ['setDetailInfo'],
      modalBtns: [{name: '확인', callback: this.onHideModal}],
      isVisible: true,
      modalNm: 'UnitOpertListModal',
      files: [],
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'detailInfo', 'photos']);
    $mapMutations(this, [
      'setSearchInfo',
      'initSearchInfo',
      'initDetailInfo',
      'setDetailInfo',
      'setPhotos',
      'initPhotos',
    ]);
  },
  created() {
    this.addEvent([
      {name: 'imageRegOnSave', func: this.onSave},
      {name: 'imageRegOnDelete', func: this.onDelete},
      {name: 'imageRegOnInit', func: this.onInit},
      {name: 'onHideModal', func: this.onHideModal},
    ]);
  },
  methods: {
    onInit() {
      this.initDetailInfo();
      this.initPhotos();
      this.setDetailInfo({
        pgmCd: this.pgmCd,
        prjCd: this.searchInfo.prjCd,
      });
    },
    onShowModal() {
      this.$modal.show('UnitOpertListModal');
    },
    onHideModal() {
      let bSelect = false;
      if (this.modalNm == 'UnitOpertListModal') {
        this.callEvent({
          name: 'unitOpertListPopupCallbackData',
          param: data => {
            let payload = {};

            if (!data.wbsCd) {
              this.$alert({title: '단위작업', message: '단위작업을 선택해 주세요.'});
              return;
            }

            payload['wbsCd'] = data.wbsCd;
            payload['wbsNm'] = data.fldrNm;
            payload['wbsId'] = data.wbsId;

            this.setDetailInfo(payload);

            bSelect = true;
          },
        });
      }

      if (bSelect) this.$modal.hide(this.modalNm);
    },
    async onSave() {
      if (this.files.length === 0 && this.detailInfo.flNo === 0) {
        this.$alert({title: '사진파일', message: '사진파일이 업로드되지 않았습니다.'});
        return;
      }
      if (!this.detailInfo.wbsNm) {
        this.$alert({title: '단위작업명', message: '단위작업명을 선택해 주세요.'});
        return;
      }
      if (!this.detailInfo.phoDt) {
        this.$alert({title: '촬영일자', message: '촬영일자를 선택해 주세요.'});
        return;
      }
      const response = await saveConstructPhoto({...this.detailInfo, prjCd: this.searchInfo.prjCd});
      this.callEvent({name: 'prjListOnSearch', param: this.searchInfo.prjCd});
    },
    async onDelete() {
      const response = await deleteConstructPhoto({...this.detailInfo, prjCd: this.searchInfo.prjCd});
      this.callEvent({name: 'prjListOnSearch'});
    },
    onSaveCompleteFile() {
      if (!this.detailInfo.seq) return;
      this.callEvent({name: 'photoInfoListSetPhotosList'});
    },
    async onDeleteCompleteFile(flNo) {
      if (flNo == 0) {
        this.setDetailInfo({flNo: flNo});
        const response = await updateFlNo0({...this.detailInfo, prjCd: this.searchInfo.prjCd});
        this.callEvent({name: 'photoInfoListOnSearch'});
      } else {
        this.callEvent({name: 'photoInfoListSetPhotosList'});
      }
    },
  },
};
</script>

<style scoped></style>
