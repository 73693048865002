export default that => {
  return {
    Cfg: {CanEdit: 1},
    Def: {
      Row: {
        NoColor: 1,
        CanFormula: 1,
        CalcOrder: 'cbxCanEdit,signCertDerExpireDt,kmCertDerExpireDt',
      },
    },
    Cols: [
      {
        Header: ['선택', '선택'],
        Name: 'cbx',
        Type: 'Bool',
        BoolIcon: 0,
        Align: 'center',
        Width: 60,
        CanEditFormula: fr => fr.Row['certNo'] == fr.Row['lastCertNo'],
      },
      {
        Header: ['인증서 번호', '인증서 번호'],
        Name: 'certNo',
        Type: 'Text',
        Align: 'center',
        Width: 100,
        CanEdit: 0,
      },
      {
        Header: ['서명용 공개키', '파일명'],
        Name: 'signCertDerFlNm',
        RelWidth: 1,
        MinWidth: 200,
        CanEdit: 0,
        Align: 'center',
      },
      {
        Header: ['서명용 공개키', '유효기간'],
        Name: 'signCertDerExpireDt',
        RelWidth: 1,
        MinWidth: 200,
        CanEdit: 0,
        Align: 'center',
        Formula: fr => `${fr.Row['signCertDerValidStartDt']} ~ ${fr.Row['signCertDerValidEndDt']}`,
      },
      {
        Header: ['암호화 공개키', '파일명'],
        Name: 'kmCertDerFlNm',
        RelWidth: 1,
        MinWidth: 200,
        CanEdit: 0,
        Align: 'center',
      },
      {
        Header: ['암호화 공개키', '유효기간'],
        Name: 'kmCertDerExpireDt',
        RelWidth: 1,
        MinWidth: 200,
        CanEdit: 0,
        Align: 'center',
        Formula: fr => `${fr.Row['kmCertDerValidStartDt']} ~ ${fr.Row['kmCertDerValidEndDt']}`,
      },
      {
        Header: ['서명용 개인키 파일명', '서명용 개인키 파일명'],
        Name: 'signCertKeyFlNm',
        RelWidth: 1,
        MinWidth: 200,
        CanEdit: 0,
        Align: 'center',
      },
      {
        Header: ['암호화 개인키 파일명', '암호화 개인키 파일명'],
        Name: 'kmCertKeyFlNm',
        RelWidth: 1,
        MinWidth: 200,
        CanEdit: 0,
        Align: 'center',
      },
      {
        Header: ['등록일시', '등록일시'],
        Name: 'rgsDttm',
        RelWidth: 1,
        MinWidth: 130,
        CanEdit: 0,
        Align: 'center',
      },
      {Name: 'signCertDerValidStartDt', Visible: false},
      {Name: 'signCertDerValidEndDt', Visible: false},
      {Name: 'kmCertDerValidStartDt', Visible: false},
      {Name: 'kmCertDerValidEndDt', Visible: false},
      {Name: 'lastCertNo', Visible: false},
    ],
  };
};
