export default that => {
  let preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      CanEdit: 1,
    },
    Def: {
      Row: {
        CanFormula: 1,
        CalcOrder: 'closeYnCanEdit',
      },
    },
    Cols: [
      {
        Header: '작업일자',
        Name: 'workDt',
        Type: 'Text',
        Width: 90,
        Align: 'Center',
        CustomFormat: '####-##-##',
        CanEdit: 0,
      },
      {Header: '공종명', Name: 'mgName', Type: 'Text', Width: 100, CanEdit: 0},
      {Header: '내역코드', Name: 'itemSeq', Type: 'Text', Width: 90, Align: 'Center', CanEdit: 0},
      {Header: '내역명', Name: 'itemName', Type: 'Text', RelWidth: 1, MinWidth: 150, CanEdit: 0},
      {Header: '규격', Name: 'ssize', Type: 'Text', Width: 120, CanEdit: 0},
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Width: 100,
        Align: 'Center',
        Format: $getConstants('UNIT').code,
        CanEdit: 0,
      },
      {Header: '투입수량', Name: 'qty', Extend: preset.quantity, Width: 100, CanEdit: 0},
      {Header: '투입금액', Name: 'amt', Extend: preset.amount, Width: 100, CanEdit: 0},
      {
        Header: {Value: '정산', TextColor: '#444', HeaderCheck: 1},
        Name: 'closeYn',
        Type: 'Bool',
        Width: 60,
        CustomFormat: v => {
          const obj = {};
          obj[$getConstants('Y').code] = 1;
          return obj[v];
        },
        CanEditFormula: () => that.cud === 1,
      },
    ],
  };
};
