<template>
  <pmis-page ref="page">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>마감정보</template>
            <CloseList />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col flex="3">
          <pmis-tab-box no-border-box no-padding-box>
            <template v-slot:title>상세정보</template>
            <template v-slot:title-right>금액단위: 원</template>
            <DetailList />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
/**
 * 월마감등록
 * components :
 *   CloseList 마감목록
 *   DetailList 상세정보 (투입정보조회, 재료비의 경우 출고정보이다.)
 *
 * */
import store from './store/ExpitmCloseRegist.js';
import CloseList from './components/CloseList.vue';
import DetailList from './components/DetailList.vue';
export default {
  components: {CloseList, DetailList},
  beforeCreate() {
    $init(this, store);
    $mapMutations(this, ['setSearchInfo']);
  },
  created() {
    this.canSave = false;
    this.setSearchInfo({pgmCd: this.pgmCd});
  },
};
</script>

<style></style>
