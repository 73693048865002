<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text name="name" type="search" :value.sync="searchNm" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
      <iui-checkbox-group
        name="labtCloseYn"
        :items="[{value: 'Y', label: '근무종료자포함'}]"
        @change="labtCloseYn = $event.target.checked ? $event.target.value : ''"
      />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onSearchFinish: grid_onSearchFinish,
        onRenderFirstFinish,
        onClick,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
/**
 * 사용처:
 * - 사원등록 (@/view/user/Worker/index.vue)
 * - 사원등록2(운영사, 협력사) (@/view/user/Worker2/index.vue)
 * - 공통팝업(IuiSearchmodal) (@/components/popup)
 */
import options from './sheetOption/WorkerListSheet.js';
import {selectWorkerList} from '../api/worker.js';
export default {
  data() {
    return {
      Options: options(this),
      loadSearchData: [],
      searchNm: '',
      labtCloseYn: '',
      focusEmpNo: '',
    };
  },
  watch: {
    labtCloseYn() {
      this.onSearch();
    },
  },
  beforeCreate() {
    $mapMutations(this, ['initWorkerInfo']);
  },
  created() {
    this.addEvent([
      {name: 'WorkerList_onSearch', func: this.onSearch},
      {name: 'WorkerList_onSearchSheet', func: this.onSearchSheet},
      {name: 'WorkerList_onSearchFinish', func: this.grid_onSearchFinish},
    ]);
  },
  methods: {
    grid_onSearchFinish(e) {
      //조회할 사원이 있으면 해당 로우 포커스 조회 없으면 첫 로우로 조회
      let searchRow = this.focusEmpNo
        ? e.sheet.getDataRows().find(row => row.empNo == this.focusEmpNo)
        : e.sheet.getFirstRow();

      if (searchRow) {
        e.sheet.focus(searchRow);
        this.callEvent({name: 'WorkerInfo_onSearch', param: searchRow.empNo});
      } else {
        this.initWorkerInfo();
        this.callEvent({name: 'InputPjt_initSheet'});
        this.callEvent({name: 'LicenseList_initSheet'});
      }
    },
    onClick(e) {
      if (e.row.Kind == 'Data' && e.row.empNo) {
        this.callEvent({name: 'WorkerInfo_onSearch', param: e.row.empNo});
      }
    },
    onSearch(focusEmpNo = '') {
      this.focusEmpNo = focusEmpNo;
      let param = {
        pgmCd: this.pgmCd,
        searchNm: this.searchNm,
        labtCloseYn: this.labtCloseYn,
      };
      selectWorkerList(param).then(response => {
        if (response.status == 200) {
          this.sheet.loadSearchData(response.data);
        }
      });
    },
    onSearchSheet(param) {
      //기 등록자 조회
      const empNo = typeof param === 'object' ? param.empNo : param;
      const callback = typeof param === 'object' && typeof param.callback === 'function' ? param.callback : undefined;

      let searchRow = this.sheet.getDataRows().find(row => row.empNo == empNo);

      if (searchRow) {
        if (callback) {
          callback(searchRow);
        } else {
          if (searchRow == this.sheet.getFocusedRow()) {
            this.callEvent({name: 'WorkerInfo_onSearch', param: searchRow.empNo});
          } else {
            this.sheet.focus(searchRow);
          }
        }
      }
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped></style>
