<template>
  <pmis-page ref="page">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px"><PrjList /></i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row>
              <i-col minWidth="500px"><MatMonMListM /></i-col>
              <i-spacer />
              <i-col><MatMonMDtl /></i-col>
            </i-row>
            <i-spacer />
            <i-row>
              <i-col><MatTargetList /></i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from './store/material.js';
import MatMonMListM from './components/MatMonMListM.vue';
import MatTargetList from './components/MatTargetList.vue';
import MatMonMDtl from './components/MatMonMDtl.vue';
import PrjList from '@/view/Project/components/PrjList.vue';

export default {
  components: {PrjList, MatMonMListM, MatTargetList, MatMonMDtl},
  beforeCreate() {
    $init(this, store);
  },
  created() {
    this.canNew = false;
    this.canDelete = false;
  },
};
</script>

<style scoped></style>
