<template>
  <pmis-page ref="page">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col>
          <PxcondCost />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import PrjList from '@/view/Project/components/PrjList.vue';
import PxcondCost from '@/view/Project/pxcondStatus/cost/component/PxcondCost.vue';

export default {
  components: {
    PrjList,
    PxcondCost
  },
  data(){
    return {
    }
  },
  created(){
    this.addFuncPrj(this.onSearch);
    this.addFuncSearch(this.onSearch);
    this.onSearch();
  },
  methods: {
    async onSearch(){
      this.callEvent({name: 'getYmList', param: {}});
    }
  }
}
</script>
