<template>
  <pmis-tab-box>
    <template #title>상세정보</template>
    <template #title-right>
      <iui-button value="시행요청품의" @click="onApproval" v-if="subPrStatus.isPrReady" />
    </template>

    <form onsubmit="return false;">
      <iui-container-new type="css-flex">
        <i-row height="285px">
          <i-col>
            <iui-container-new type="table" theme="bullet">
              <colgroup>
                <col width="90px" />
                <col width="70px" />
                <col />
                <col width="10px" />
                <col width="70px" />
                <col />
              </colgroup>
              <i-row>
                <i-col colspan="6">
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header>요청번호</i-col-header>
                      <i-col width="120px">
                        <iui-text :value="subPrInfo.prNo2" width="120px" :enable="false" />
                      </i-col>
                      <i-spacer />
                      <i-col-header required>요청제목</i-col-header>
                      <i-col>
                        <iui-text
                          ref="ttl"
                          :key="`ttlKey_${ttlKey}`"
                          :value="subPrInfo.ttl"
                          @input="onInput_ttl"
                          :enable="subPrStatus.isEnable"
                          :required="subPrStatus.isEnable"
                          :errorMessage="{title: '요청제목', message: '필수입력입니다.'}"
                          max-length="200"
                        />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>과세유형</i-col-header>
                <i-col colspan="5">
                  <iui-radio-group
                    :p-code="$getConstants('TAX_TYPE_CD').code"
                    :value="subPrInfo.taxTypecd"
                    @change="onChange_taxType('taxTypecd', $event.target.value)"
                    :enable="subPrStatus.isEnable"
                  />
                  <template v-if="subPrStatus.isTaxDirect">
                    <iui-select
                      :p-code="$getConstants('TAX_DIRECT_DS').code"
                      :value="subPrInfo.taxDirectDs"
                      @change="value => onChange_taxType('taxDirectDs', value)"
                      :enable="subPrStatus.isEnable && subPrStatus.isTaxDirect"
                    />
                    <iui-text
                      type="rate"
                      :value="subPrInfo.taxTypeDirectPercent"
                      @change="onChange_taxType('taxTypeDirectPercent', $event.target.value)"
                      :enable="subPrStatus.isEnable && subPrStatus.isTaxDirectPercent"
                      :required="subPrStatus.isTaxDirectPercent"
                      :errorMessage="{title: '과세율', message: '필수입력입니다.'}"
                    />
                    <iui-text
                      type="amount"
                      :value="subPrInfo.taxTypeDirectAmt"
                      @change="onChange_taxType('taxTypeDirectAmt', $event.target.value)"
                      :enable="subPrStatus.isEnable && subPrStatus.isTaxDirectAmount"
                      :required="subPrStatus.isTaxDirectAmount"
                      :errorMessage="{title: '과세금액', message: '필수입력입니다.'}"
                    />
                  </template>
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>현설유무</i-col-header>
                <i-col colspan="5">
                  <iui-checkbox-group
                    :checkedValues="[subPrInfo.spotDscrYn]"
                    :items="[{label: $getConstants('EXTERNAL_Y').name, value: $getConstants('EXTERNAL_Y').code}]"
                    @change="onChange_spotDscrYn"
                    :enable="subPrStatus.isEnable"
                  />
                </i-col>
              </i-row>
              <i-row>
                <template v-if="subPrStatus.isExistSpotDscr">
                  <i-col>&nbsp;</i-col>
                  <i-col-header required>현설일자</i-col-header>
                  <i-col>
                    <iui-datepicker
                      :value="subPrInfo.spotDscrYmd"
                      @change="setSubPrInfo({spotDscrYmd: $event})"
                      :disabledDate="isBeforeToday"
                      :enable="subPrStatus.isEnable && subPrStatus.isExistSpotDscr"
                      :required="subPrStatus.isEnable && subPrStatus.isExistSpotDscr"
                      :errorMessage="{title: '현설일자', message: '필수입력입니다.'}"
                    />
                    <iui-timepicker
                      width="30px"
                      :value="getTime(subPrInfo.spotDscrHh, subPrInfo.spotDscrMm)"
                      @change="onChangeTime('spotDscr', $event)"
                      :enable="subPrStatus.isEnable && subPrStatus.isExistSpotDscr"
                      :required="subPrStatus.isEnable && subPrStatus.isExistSpotDscr"
                      :errorMessage="{title: '현설시간', message: '필수입력입니다.'}"
                    />
                  </i-col>
                  <i-spacer />
                  <i-col-header required>현설담당</i-col-header>
                  <i-col>
                    <div class="iui-searchbox">
                      <iui-text
                        width="90px"
                        :value="subPrInfo.spotDscrUsrcd"
                        :enable="false"
                        :required="subPrStatus.isEnable && subPrStatus.isExistSpotDscr"
                        :errorMessage="{title: '현설담당', message: '필수입력입니다.'}"
                      />
                      <iui-button
                        class="flex-0"
                        btn-class="search-button"
                        @click="onOpenPopup('spot')"
                        v-if="subPrStatus.isEnable && subPrStatus.isExistSpotDscr"
                      />
                      <iui-text width="100px" :value="subPrInfo.spotDscrUsrnm" :enable="false" />
                    </div>
                  </i-col>
                </template>
                <template v-else>
                  <i-col colspan="6">&nbsp;</i-col>
                </template>
              </i-row>
              <i-row>
                <template v-if="subPrStatus.isExistSpotDscr">
                  <i-col>&nbsp;</i-col>
                  <i-col-header required>현설장소</i-col-header>
                  <i-col colspan="4">
                    <iui-text
                      :value="subPrInfo.spotDscrLoc"
                      @change="setSubPrInfo({spotDscrLoc: $event.target.value})"
                      :enable="subPrStatus.isEnable && subPrStatus.isExistSpotDscr"
                      :required="subPrStatus.isEnable && subPrStatus.isExistSpotDscr"
                      :errorMessage="{title: '현설장소', message: '필수입력입니다.'}"
                      max-length="500"
                    />
                  </i-col>
                </template>
                <template v-else>
                  <i-col colspan="6">&nbsp;</i-col>
                </template>
              </i-row>
              <i-row>
                <i-col-header>입찰기타사항</i-col-header>
                <i-col colspan="5">
                  <iui-text
                    :value="subPrInfo.prRmrk"
                    @change="setSubPrInfo({prRmrk: $event.target.value})"
                    :enable="subPrStatus.isEnable"
                    max-length="1400"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>요청자</i-col-header>
                <i-col colspan="2">
                  <div class="iui-searchbox">
                    <iui-text width="90px" :value="subPrInfo.chrgUsrcd" :enable="false" />
                    <iui-button
                      class="flex-0"
                      btn-class="search-button"
                      @click="onOpenPopup('chrg')"
                      v-if="subPrStatus.isEnable"
                    />
                    <iui-text :value="subPrInfo.chrgUsrnm" :enable="false" />
                  </div>
                </i-col>
                <i-spacer />
                <i-col-header>요청일자</i-col-header>
                <i-col>
                  <iui-datepicker
                    :value="subPrInfo.prDate"
                    @change="setSubPrInfo({prDate: $event})"
                    :enable="subPrStatus.isEnable"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>공사명</i-col-header>
                <i-col colspan="5">
                  <iui-text
                    :value="subPrInfo.itemNm"
                    @change="setSubPrInfo({itemNm: $event.target.value})"
                    :enable="subPrStatus.isEnable"
                    :required="subPrStatus.isEnable"
                    :errorMessage="{title: '공사명', message: '필수입력입니다.'}"
                    max-length="200"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>공사기간</i-col-header>
                <i-col colspan="5">
                  <iui-datepicker
                    :value="subPrInfo.constrFrDt"
                    @change="onChange_constrFrDt"
                    :disabledDate="isBeforeToday"
                    :enable="subPrStatus.isEnable"
                    :required="subPrStatus.isEnable"
                    :errorMessage="{title: '공사용역기간', message: '필수입력입니다.'}"
                  />
                  <label>~</label>
                  <iui-datepicker
                    :value="subPrInfo.constrToDt"
                    @change="setSubPrInfo({constrToDt: $event})"
                    :disabledDate="disabledConstrToDt"
                    :enable="subPrStatus.isEnable"
                    :required="subPrStatus.isEnable"
                    :errorMessage="{title: '공사용역기간', message: '필수입력입니다.'}"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>비고</i-col-header>
                <i-col colspan="5">
                  <iui-text
                    :value="subPrInfo.itemRmrk"
                    @change="setSubPrInfo({itemRmrk: $event.target.value})"
                    :enable="subPrStatus.isEnable"
                    max-length="500"
                  />
                </i-col>
              </i-row>
            </iui-container-new>
          </i-col>
        </i-row>
        <i-row>
          <i-col>
            <pmis-file-list
              id="subPrFile"
              theme="bullet"
              title-visible
              title="첨부파일"
              :toolbar-visible="subPrStatus.isEnable"
              :company-code="pgmCd"
              :project-code="subPrInfo.spotDeptcd"
              :fbsNo="FBS_NO"
              :file-number="subPrInfo.flNo"
              :clear.sync="fileClear"
              @delete-complete="onDeleteCompleteFile"
            />
          </i-col>
        </i-row>
      </iui-container-new>
    </form>

    <iui-modal name="userListModal" :btns="userListModalBtns" title="사원정보" sizeType="size1">
      <UserListPopup :uid="_uid" :laborDs="$getConstants('LABOR_DS1').code" @onDblClick="onConfirm_UserListPopup" />
    </iui-modal>

    <ApprovalPop :parentId="_uid" @approvalEmit="onApprovalEmit" />
  </pmis-tab-box>
</template>

<script>
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import EDMS_CONSTANTS from '@/view/edms/common/EdmsConstatns.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import BiddingApprovalMixin from '@/view/bidding/js/biddingApprovalMixin.js';
import {saveSubPrInfo, deleteSubPrInfo, deleteSubPrFlNo} from '@/view/bidding/pr/sub/api/subPr.js';

import UserListPopup from '@/view/Resource/components/WorkerListPopup.vue';
import IuiTimepicker from '@/components/common/IuiTimepicker.vue';

export default {
  components: {IuiTimepicker, UserListPopup},
  mixins: [BiddingApprovalMixin],
  data() {
    return {
      FBS_NO: EDMS_CONSTANTS.SUB_PR.FBS_NO,
      userListModalBtns: [{name: '확인', callback: this.onConfirm_UserListPopup}],
      modalNm: '',
      fileClear: false,
      ttlKey: 0,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['subPrInfo', 'subPrStatus', 'subPrIsNew']);
    $mapMutations(this, ['setSubPrInfo', 'initSubPrInfo', 'setSubPrItemList', 'setSubPrIsNew']);
  },
  created() {
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
    this.addEvent([{name: 'SubPrDetailInfo_ClearFile', func: this.clearFile}]);
  },
  methods: {
    timeFormat: COMMON_FUNCTION.timeFormat,
    isBeforeToday(date) {
      return $_getCurrentDate() > $_dateToStringYYMMDD(date);
    },
    disabledConstrToDt(date) {
      return this.subPrInfo.constrFrDt
        ? $_dateToStringYYMMDD(date) < dateFormat(this.subPrInfo.constrFrDt).replace(/-/gi, '')
        : this.isBeforeToday(date);
    },
    clearFile() {
      this.fileClear = true;
    },
    onAdd() {
      this.clearFile();
      this.setSubPrIsNew(true);
      this.initSubPrInfo();
      this.setSubPrItemList([]);
      this.canSave = true;
      this.canDelete = false;

      if (!this.prjCd) {
        this.$alert({title: '현장', message: '선택된 현장이 없습니다.'});
        return;
      }

      this.setSubPrInfo({
        orgCd: this.pgmCd,
        spotDeptcd: this.prjCd,
        ttl: `[${this.prjCd}] `,
        spotDscrYn: BIDDING_CONSTANTS.CODES.SPOT_DSCR_Y,
        taxTypecd: BIDDING_CONSTANTS.CODES.TAX,
        chrgUsrcd: this.userInfo.empNo,
        chrgUsrnm: this.userInfo.name,
        spotDscrUsrcd: this.userInfo.empNo,
        spotDscrUsrnm: this.userInfo.name,
        spotDscrUsrTlno: this.userInfo.tlNo,
        rgprId: this.userInfo.empNo,
        prDate: $_getCurrentDate(),
      });
    },
    /*** 요청제목 입력 ***/
    onInput_ttl(e) {
      let value = e.target.value;

      const updateTtlKey = () => {
        if (10 < this.ttlKey) {
          this.ttlKey = 0;
        } else {
          this.ttlKey++;
        }
      };

      const ttlPrefix = `[${this.subPrInfo.spotDeptcd}] `;
      if (value.length < ttlPrefix.length) {
        value = ttlPrefix;
        updateTtlKey();
      } else if (value.indexOf(ttlPrefix) !== 0) {
        value = this.subPrInfo.ttl + e.data;
        updateTtlKey();
      }
      setTimeout(() => {
        this.$refs.ttl.$refs.input.focus();
      }, 10);

      this.setSubPrInfo({ttl: value});
    },
    /*** 과세유형 변경 ***/
    onChange_taxType(key, value) {
      const BIDDING_CODES = BIDDING_CONSTANTS.CODES;
      const payloads = {
        taxTypecd: {
          taxDirectDs: value === BIDDING_CODES.TAX_DIRECT ? BIDDING_CODES.TAX_DIRECT_PERCENT : '',
          taxTypeDirectPercent: '',
          taxTypeDirectAmt: '',
        },
        taxDirectDs: {
          taxTypeDirectPercent: value === BIDDING_CODES.TAX_DIRECT_PERCENT ? 0 : '',
          taxTypeDirectAmt: value === BIDDING_CODES.TAX_DIRECT_AMOUNT ? 0 : '',
        },
      };

      this.setSubPrInfo({[key]: value, ...payloads[key]});
      this.callEvent({name: 'SubPrItemList_CalculateAmt'});
    },
    /*** 현설유무 변경 ***/
    onChange_spotDscrYn(e) {
      const BIDDING_CODES = BIDDING_CONSTANTS.CODES;
      const spotDscrYn = $_externalCheckboxValue(e.target.checked);
      const [spotDscrUsrcd, spotDscrUsrnm, spotDscrUsrTlno] =
        spotDscrYn === BIDDING_CODES.SPOT_DSCR_Y
          ? [this.userInfo.empNo, this.userInfo.name, this.userInfo.tlNo]
          : ['', '', ''];

      this.setSubPrInfo({
        spotDscrYn,
        spotDscrLoc: '',
        spotDscrDt: '',
        spotDscrYmd: '',
        spotDscrHh: '00',
        spotDscrMm: '00',
        spotDscrUsrcd,
        spotDscrUsrnm,
        spotDscrUsrTlno,
      });
    },
    /*** 날짜 변경 ***/
    onChange_constrFrDt(date) {
      const payload = {constrFrDt: date};
      if (date && date > this.subPrInfo.constrToDt) {
        payload['constrToDt'] = '';
      }
      this.setSubPrInfo(payload);
    },
    onOpenPopup(modalType) {
      this.modalNm = `${modalType}Modal`;
      this.$modal.show('userListModal');
    },
    onConfirm_UserListPopup() {
      this.callEvent({
        name: 'WorkerListPopup_callbackData',
        param: data => {
          if (this.modalNm == 'spotModal') {
            this.setSubPrInfo({spotDscrUsrcd: data.empNo, spotDscrUsrnm: data.name});
          } else if (this.modalNm == 'chrgModal') {
            this.setSubPrInfo({chrgUsrcd: data.empNo, chrgUsrnm: data.name});
          }
          this.$modal.hide('userListModal');
        },
      });
    },
    onSave() {
      let itemList;
      this.callEvent({name: 'SubPrItemList_GetSaveJson', param: data => (itemList = data)});

      if (this.subPrInfo.ttl === `[${this.subPrInfo.spotDeptcd}] `) {
        this.$alert({title: '요청제목', message: '요청제목을 입력해주세요.'});
        return;
      }

      const saveInfo = {orgCd: this.pgmCd, ...this.subPrInfo, itemList, isNew: this.subPrIsNew};

      saveSubPrInfo(saveInfo, () => this.onAfterSaveValidation(itemList))
        .then(response => {
          this.callEvent({name: 'SubPrList_Search', param: response.data.prNo});
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    onAfterSaveValidation(itemList) {
      // 현설체크
      if (this.isExistSpotDscr) {
        const spotYmd = this.pr.spotDscrYmd.replace(/-/g, '');
        const spotYear = spotYmd.substr(0, 4);
        const spotMonth = parseInt(spotYmd.substr(4, 2)) - 1;
        const spotDay = spotYmd.substr(6, 2);

        const spotHour = this.pr.spotDscrHh;
        const spotMinute = this.pr.spotDscrMm;
        if (0 > Number(spotHour) || 23 < Number(spotHour) || 0 > Number(spotMinute) || 59 < Number(spotMinute)) {
          this.$alert({title: '현설일자', message: '현설시간이 잘못되었습니다.<br/>다시 한 번 확인하시기 바랍니다.'});
          return false;
        }

        const spotDate = new Date(spotYear, spotMonth, spotDay, spotHour, spotMinute);
        if (spotDate.getTime() <= new Date().getTime()) {
          this.$alert({title: '현설일자', message: '현설일자는 현재시간 이후로만 가능합니다.'});
          return false;
        }
      }

      // 시행요청내역 체크
      if (!itemList.length) {
        this.$alert({
          title: '시행요청내역',
          message: '시행요청내역을 추가해주세요.',
        }).then(() => this.callEvent({name: 'SubPrItemList_OpenExecutionListModal'}));
        return false;
      }

      if (itemList.some(row => !Number(row.qty))) {
        this.$alert({
          title: '시행요청정보 저장',
          message: '금회요청수량이 입력되지 않은 항목이 존재합니다.<br/>다시 한 번 확인하시기 바랍니다.',
        });
        return false;
      }
      return true;
    },
    onDeleteCompleteFile(flNo) {
      if (flNo) return;

      deleteSubPrFlNo(this.subPrInfo.prNo)
        .then(response => {
          if (response.status == 200) {
            this.setSubPrInfo({flNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    async onDelete() {
      const title = '시행요청정보 삭제';
      const message = '시행요청정보를 삭제하시겠습니까?';

      if (!(await this.$confirm({title, message}))) return;

      deleteSubPrInfo(this.subPrInfo.prNo)
        .then(response => {
          if (0 < response.data) {
            this.callEvent({name: 'SubPrList_Search'});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    onApproval() {
      let itemList = [];
      this.callEvent({name: 'SubPrItemList_GetSaveJson', param: data => (itemList = data)});

      if (!itemList.length) {
        this.$alert({title: '시행요청품의', message: '시행요청내역이 존재하지 않습니다.'});
        return false;
      }

      if (itemList.some(item => !item.prSrvSeq)) {
        this.$alert({title: '시행요청품의', message: '저장되지 않은 시행요청내역이 존재합니다.'});
        return false;
      }

      this.openSubPrApproval();
    },
    onApprovalEmit() {
      this.callEvent({name: 'SubPrList_Search', param: this.subPrInfo.prNo});
    },
    getTime(hour, minute) {
      hour = hour ? hour : '00';
      minute = minute ? minute : '00';
      return `${hour}:${minute}`;
    },
    onChangeTime(key, value) {
      const hour = value.split(':')[0];
      const minute = value.split(':')[1];
      this.setSubPrInfo({[key + 'Hh']: hour, [key + 'Mm']: minute});
    },
  },
};
</script>

<style></style>
