const state = {
  codeAlias: {
    UNIT_EXPRESSION: {code: '500060', codeNm: '식'},
    AMOUNT_UNIT_ONE_THOUSAND_WON: {code: '000003', codeNm: '천원'},
    FLDS_NORMAL: {key: 'FLDS-000000', code: '000000', codeNm: '일반'},
    FLDS_IMAGE: {key: 'FLDS-000100', code: '000100', codeNm: '이미지(원본)'},
    FLDS_IMAGE_THUMBNAIL: {key: 'FLDS-000101', code: '000101', codeNm: '이미지(썸네일)'},
    FLDS_REF: {key: 'FLDS-000012', code: '000012', codeNm: '참조'},
  },
  dCodeObj: {
    /*
    '0006': {
      isLoad: true,
      isExist: true,
      message: '',
      dCodeList: [
        {code:=='000001', codeNm: '8일이상'},
        {code: '000002', codeNm: '20일이상'},
      ]
    }
    */
  },
};

const getters = {
  getCode: state => (code, excludeKeys) => {
    if (excludeKeys === undefined) {
      if (state.dCodeObj[code] === undefined) {
        return undefined;
      } else {
        return state.dCodeObj[code].dCodeList;
      }
    } else {
      if (state.dCodeObj[code] === undefined) {
        return undefined;
      } else {
        return state.dCodeObj[code].dCodeList.filter(item => excludeKeys.indexOf(item.code) === -1);
      }
    }
  },
  getCodeByAlias: state => alias => {
    return state.codeAlias.find(item => item.keyAlias === alias);
  },
};

const actions = {
  async searchCode(context, payload) {
    let url = sessionStorage.getItem('userInfo')
      ? 'cmmnCodeRegist/selectLowerCodeList'
      : 'baseCmmnCodeRegist/selectLowerCodeList';
    let pgmCd = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')).pgmCd : '';
    let response = await axios.post(url, {
      pgmCd: pgmCd,
      cmnUCd: payload,
    });
    context.commit('SET_CODE', {uCode: payload, dCodeList: response.data, status: response.status});
  },
  init(context) {
    context.commit('INIT');
  },
};

const mutations = {
  SET_CODE(state, payload) {
    if (payload.status == 200) {
      let array = [];
      for (let obj of payload.dCodeList) {
        array.push({code: obj.cmnDCd, codeNm: obj.cmnDNm});
      }
      state.dCodeObj[payload.uCode] = {
        isLoad: true,
        isExist: payload.dCodeList.length > 0,
        message:
          payload.dCodeList.length > 0
            ? `하위코드 조회 [${payload.uCode}]`
            : `하위코드 존재하지 않음 [${payload.uCode}]`,
        dCodeList: array,
      };
    } else {
      state.dCodeObj[payload.uCode] = {
        isLoad: false,
        isExist: false,
        message: `하위코드 조회 실패 [${payload.uCode}]`,
        dCodeList: [],
      };
    }
  },
  INIT(state) {
    state.dCodeObj = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
