export default that => {
  return {
    Def: {
      Row: {
        VAlign: 'TOP',
      },
    },
    Cfg: {
      CanEdit: 1,
      HeaderMerge: 6,
      CanSort: 0,
      CanColMove: 0,
    },
    LeftCols: [
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '공종명'],
        Name: 'mgNameDg',
        Type: 'Text',
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '내역코드'],
        Name: 'itemSeqDg',
        Type: 'Text',
        Width: 70,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '내역명'],
        Name: 'itemNameDg',
        Type: 'Text',
        Width: 150,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '규격'],
        Name: 'ssizeDg',
        Type: 'Text',
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '수량'],
        Name: 'qtyDg',
        Extend: that.$ibsheetHelper.preset.quantity,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '금액'],
        Name: 'amtDg',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {Name: 'priceDg', Visible: false},
    ],
    Cols: [
      {
        Header: [
          {Value: '실행배분내역', Color: '#7ed6df'},
          {Value: '선택', TextColor: '#444', HeaderCheck: 1},
        ],
        Type: 'Bool',
        Name: 'chk',
        Width: '60',
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '공종명'],
        Name: 'mgNameSi',
        Type: 'Text',
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '내역코드'],
        Name: 'itemSeqSi',
        Type: 'Text',
        Width: 70,
        CanEdit: 0,
        RowMerge: false,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '내역명'],
        Name: 'itemNameSi',
        Type: 'Text',
        Width: 150,
        CanEdit: 0,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '규격'],
        Name: 'ssizeSi',
        Type: 'Text',
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '배분율(%)'],
        Name: 'itemYul',
        Extend: that.$ibsheetHelper.preset.rate,
        Width: 70,
        CanEdit: 0,
        Visible: 1,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '배분수량'],
        Name: 'execItemQty',
        Extend: that.$ibsheetHelper.preset.quantity,
        CanEdit: 0,
        OnChange: fr => {
          fr.sheet.setValue(fr.row, 'itemYul', (fr.row.execItemQty / fr.row.execQty) * 100, 1);
          fr.sheet.setValue(fr.row, 'execItemAmt', fr.row.execItemQty * fr.row.execPrice, 1);
        },
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '배분금액'],
        Name: 'execItemAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {Name: 'chgDegreeDg', Visible: 0},
      {Name: 'wcodeDg', Visible: 0},
      {Name: 'headCodeDg', Visible: 0},
      {Name: 'midCodeDg', Visible: 0},
      {Name: 'chgDegreeSi', Visible: 0},
      {Name: 'wcodeSi', Visible: 0},
      {Name: 'headCodeSi', Visible: 0},
      {Name: 'midCodeSi', Visible: 0},
    ],
  };
};
