<template>
  <pmis-tab-box>
    <template #title>현장목록</template>
    <pmis-content-box>
      <template #header-left>
        <i-header-multi-box>
          <div>
            <label>입찰결과</label>
            <iui-radio-group
              :p-code="$getConstants('BID_STAT').code"
              defaultCd="A"
              :value.sync="bidStat"
              @change="onSearch"
            />
          </div>
          <div>
            <iui-text type="search" width="300px" :value.sync="searchNm" @enter="onSearch" />
            <iui-button value="검색" @click="onSearch" />
          </div>
        </i-header-multi-box>
      </template>

      <ib-sheet
        :uid="_uid"
        :options="sheetOpt"
        :loadSearchData="loadSearchData"
        :events="{
          onRenderFirstFinish: () => onSearch(),
          onSearchFinish: sheet_onSearchFinish,
          onFocus: sheet_onFocus,
        }"
      />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
/**
 * 견적현장등록 리스트
 */
import sheetOpt from './sheetOption/prjEstCdListSheet.js';
import {selectPrjEstMList} from '@/view/estmtManage/estmtPrjManage/api/estmtPrjRegist.js';
export default {
  props: {
    visibleCols: {
      type: Array,
      default: () => ['prjEstCd', 'prjEstNm'],
    },
    uid: {
      type: Number,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],
      searchNm: '',
      bidStat: '',
      bidStatItems: [],
      focusKey: '',
    };
  },

  beforeCreate() {
    $mapMutations(this, ['setDetailInfo', 'initDetailInfo']);
  },

  created() {
    this.addFuncSearch(this.onSearch);
    this.addEvent([{name: 'PrjEstCdList_onSearch', func: this.onSearch}]);
  },

  methods: {
    async onSearch(focusKey = '') {
      this.focusKey = focusKey;

      try {
        this.initDetailInfo();
        const param = {searchNm: this.searchNm, bidStat: this.bidStat};
        const response = await selectPrjEstMList(param);
        this.loadSearchData = response.data;
      } catch (error) {
        this.$alert({title: '조회실패', message: error.message});
      }
    },

    sheet_onFocus(e) {
      this.setCud(0);
      this.initDetailInfo();
      this.setDetailInfo(e.row);
      if (e.row.siteArea1) {
        $getCode($getConstants('SITE_AREA1_CD').code).then(response => {
          response.some(code => {
            if (e.row.siteArea1 == code.code) {
              this.setDetailInfo({siteArea1Nm: code.codeNm});
              return true;
            }
          });
        });
      }
      if (e.row.siteArea2) {
        $getCode($getConstants('SITE_AREA2_CD').code).then(response => {
          response.some(code => {
            if (e.row.siteArea2 == code.code) {
              this.setDetailInfo({siteArea2Nm: code.codeNm});
              return true;
            }
          });
        });
      }
      for (let key in e.row) {
        if (key.endsWith('Time')) {
          let splitData = String(e.row[key]).split(':');
          this.setDetailInfo({[`${key}Hh`]: splitData[0]});
          this.setDetailInfo({[`${key}Mm`]: splitData[1]});
        }
      }
    },
    sheet_onSearchFinish(e) {
      if (e.sheet.getTotalRowCount()) {
        if (this.focusKey) {
          e.sheet.getDataRows().some(row => {
            if (row.prjEstCd == this.focusKey) {
              e.sheet.focus(row);
              return true;
            }
          });
        }
      } else {
        this.cud = 1;
      }
    },
  },
};
</script>

<style></style>
