<template>
  <pmis-content-box>
    <template v-slot:title>{{ title }}</template>
    <template v-slot:title-right>
      <iui-button class="btn" value="신규" @click="onAdd" v-show="enable" />
      <iui-button class="btn" value="저장" @click="onSave" v-if="companyInfo.pgmCd" v-show="enable" />
      <iui-button class="btn" value="삭제" @click="onDelete" v-show="enable" />
    </template>
    <iui-container-new type="css-flex">
      <i-row :height="enable ? '150px' : `${height}px`">
        <i-col>
          <ib-sheet
            :uid="_uid"
            :options="Options"
            :loadSearchData="gcodeList"
            :events="{onRenderFirstFinish, onSearchFinish}"
            @loadSheet="loadSheet"
            :autoRender="false"
            :init="initSheet"
          />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/WorkTypeInfoSheet.js';
import {selectGcodeList, saveGcodeList, deleteGcodeList} from '../api/member.js';
export default {
  props: {
    enable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '보유면허정보(세부공종)',
    },
    height: {
      type: String,
      default: '100',
    },
  },
  data() {
    return {
      Options: {},
      loadSearchData: [],
      initSheet: false,
      codeObj: {},
    };
  },

  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'gcodeList']);
    $mapMutations(this, ['setGcodeList']);
  },

  created() {
    this.addEvent([{name: 'workTypeInfo_getSaveJson', func: this.getSaveJson}]);
    this.initCode();
    this.createSheet();
  },

  mounted() {},

  methods: {
    async initCode() {
      let wCodeList = [];
      let lgCodeList = [];
      wCodeList = await $getCode($getConstants('W_CODE').code);
      lgCodeList = await $getCode($getConstants('LG_CODE').code);
      wCodeList.forEach(item => {
        this.codeObj[`${item.code}`] = lgCodeList.filter(
          code => Math.floor(Number(code.code) / 10) == Number(item.code)
        );
      });
    },

    async createSheet() {
      this.Options = this.enable ? $addColCheckbox(await options(this)) : await options(this);
      this.initSheet = true;
    },

    onAdd() {
      this.sheet.addRow();
    },
    async onSave() {
      if (this.sheet.getTotalRowCount() == 0) {
        this.$alert({title: '보유면허정보(세부공종) 삭제', message: '저장할 보유면허정보가 존재하지 않습니다.'});
        return;
      }
      if (!this.sheet.hasChangedData()) {
        this.$alert({title: '보유면허정보(세부공종) 삭제', message: '수정된 내용이 없습니다.'});
        return;
      }
      let saveJson = this.sheet.getSaveJson().data;

      let validItem = saveJson.find(item => !item.wcode);
      if (validItem !== undefined) {
        this.$alert({title: '보유면허정보(세부공종) 삭제', message: '전문공종을 선택 해야합니다.'});
        return;
      }

      saveJson.forEach(el => {
        if (!el.pgmCd) el.pgmCd = this.companyInfo.pgmCd;
      });

      let param = {gcodeList: $_statusToCud(saveJson)};
      const response = await saveGcodeList(param);
      if (response.status == 200) {
        this.onSearchGcodeList();
      }
    },
    async onDelete() {
      if (this.sheet.getTotalRowCount == 0) {
        this.$alert({title: '보유면허정보(세부공종) 삭제', message: '삭제할 정보가 없습니다.'});
        return;
      }
      let checkedRows = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({title: '보유면허정보(세부공종) 삭제', message: '선택된 항목이 없습니다.'});
        return;
      }

      const gcodeList = checkedRows.map(item => {
        return item.seq;
      });
      if (await this.$confirm({title: '보유면서정보(세부공종) 삭제', message: '삭제하시겠습니까?'})) {
        const response = await deleteGcodeList({gcodeList});
        if (response.status == 200) {
          this.onSearchGcodeList();
        }
      }
    },
    getSaveJson(callback) {
      if (typeof callback == 'function') callback(this.sheet.getSaveJson().data);
    },
    async onSearchGcodeList() {
      let param = {pgmCd: this.companyInfo.pgmCd};
      const response = await selectGcodeList(param);
      if (response.status == 200) {
        this.setGcodeList(response.data);
      }
    },

    onRenderFirstFinish() {
      this.onSearchGcodeList();
    },
    onSearchFinish(e) {
      e.sheet.getDataRows().forEach((row, index) => e.sheet.setValue(row, 'lgCode', this.gcodeList[index].lgCode, 1));
      e.sheet.acceptChangedData();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  }, //methods

  beforeDestroy() {
    this.sheet.dispose();
  },
};
</script>

<style></style>
