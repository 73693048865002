<template>
  <pmis-tab-box :loading="loading">
    <template #title>기성원가현황</template>
    <template v-slot:header-left>
      <label>작업년월</label>
      <div class="ml5"></div>
      <iui-datepicker name="reqYm" type="month" :value="reqYm" @update:value="onReqYmChange($event)" />
      <i class="prev-arrow" @click.prevent.self @click="onClickArrow('PREVIOUS')" />
      <i class="post-arrow" @click.prevent.self @click="onClickArrow('NEXT')" />
    </template>
    <template #header-right>
      <iui-amount-unit :amount-unit="amountUnit" @change="onAmountChange" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="sheet = $event"
      :events="{onSearchFinish: onSearchFinish}"
    />
  </pmis-tab-box>
</template>

<script>
import options from './sheetOptions/PxcondCostSheet.js';
import {selectYmList, selectPxcondCostList} from '@/view/Project/pxcondStatus/cost/api/cost';
import IuiAmountUnit from '@/components/common/IuiAmountUnit.vue';

export default {
  components: {IuiAmountUnit},
  data() {
    return {
      loading: false,
      workType: '',
      Options: options(this),
      loadSearchData: [],
      reqYm: '',
      reqYmList: [],
      sheet: undefined,
      responseData: {
        sheet: [],
      },
      amountUnit: '',
    };
  },
  created() {
    this.amountUnit = this.userInfo?.amountUnit
      ? this.userInfo.amountUnit
      : this.$store.state.code2.codeAlias.AMOUNT_UNIT_ONE_THOUSAND_WON.code;

    this.addEvent([{name: 'getYmList', func: this.getYmList}]);
  },
  methods: {
    async onSearch() {
      this.loading = true;
      try {
        const response = await selectPxcondCostList({reqYm: this.reqYm, prjCd: this.prjCd});
        this.responseData.sheet = response.data;
        this.convertPriceUnit();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    onSearchFinish(e) {
      e.sheet.setAutoMerge({dataMerge: 1, headerMerge: 3, fixPrevColumnMerge: 'itemNameDg', PrevColumnMergeMode: 1});
    },
    onReqYmChange(reqYm) {
      this.reqYm = reqYm;
      if (reqYm) {
        this.onSearch();
      }
    },
    async getYmList() {
      const response = await selectYmList({prjCd: this.prjCd, type: 'COST'});

      const array = [];
      response.data.forEach(x => {
        array.push(x.reqYm);
      });
      this.reqYmList = array;

      if (array.length > 0) {
        this.reqYm = array[0];
        this.onSearch();
      } else {
        this.reqYm = '';
        this.loadSearchData = [];
      }
    },
    onClickArrow(type) {
      const index = this.reqYmList.indexOf(this.reqYm);

      if (index === -1) {
        this.reqYm = this.reqYmList[this.reqYmList.length - 1];
        this.onSearch();
      } else {
        if ((type === 'PREVIOUS' && index === 0) || (type === 'NEXT' && index + 1 === this.reqYmList.length)) {
          const message = type === 'PREVIOUS' ? '이전 작업년월이 없습니다.' : '다음 작업년월이 없습니다.';
          this.$alert({title: '작업년월', message: message});
          return false;
        }

        this.reqYm = type === 'PREVIOUS' ? this.reqYmList[index - 1] : this.reqYmList[index + 1];
        this.onSearch();
      }
    },
    onAmountChange(value) {
      this.amountUnit = value;
      this.convertPriceUnit();
    },
    convertPriceUnit() {
      this.loadSearchData = $_priceUnitFormat(this.amountUnit, this.responseData.sheet);
    },
  },
};
</script>
