<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <History />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import History from './components/History.vue';

export default {
  components: {
    History,
  },
};
</script>

<style scoped></style>
