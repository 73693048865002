<template>
  <div :id="elId" style="width:100%;height:100%;"></div>
</template>

<script>
export default {
  props: {
    uid: {
      type: Number,
    },
  },

  data() {
    return {
      elId: 'sheetDiv' + this._uid,
    };
  },

  created() {
    this.addEvent([
      {name: 'AccountGb_data', func: this.AccountGb_data},
      {name: 'AccountGbFilter', func: this.AccountGbFilter},
    ]);
  },

  mounted() {
    this.initSheet();
  },

  methods: {
    initSheet() {
      const OPT = {
        //각 컬럼에 대한 정의 (컬럼의 이름,유형(Type),포맷(Format)등을 설정)
        //컬럼의 "Type"과 "Name" 속성은 반드시 설정되어야 합니다.
        Cols: [
          {
            Header: '거래구분코드',
            Name: 'accountGb',
            Type: 'Text',
            Width: 100,
            CanEdit: 0,
          },
          {
            Header: '거래구분명',
            Name: 'accountGbName',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: 0,
          },
        ],
        Cfg: {
          CanEdit: 0,
        },
        Events: {
          onDblClick: e => {
            if (e.row.Kind == 'Data') {
              this.callEvent({
                name: `accountGbSearchOnConfirm${this.uid}`,
              });
            }
          },
        },
      };

      PMISSheet.createSheet({
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
        data: this.$store.getters['code/accountGbSheetData'],
      }).then(sheet => (this.sheet = sheet));
    },
    AccountGb_data(callback) {
      let row = this.sheet.getFocusedRow();

      if (typeof callback == 'function') {
        callback(row);
      }
    },
    AccountGbFilter(filter) {
      if (filter.searchSe && filter.searchNm) {
        this.sheet.setFilter({
          name: filter.searchSe,
          filter: filter.searchSe + "=='" + filter.searchNm + "'?1:0",
          render: 1,
        });
      } else {
        this.sheet.clearFilter();
      }
    },
  },
};
</script>

<style></style>
