<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col width="500px">
        <form onsubmit="return false;">
          <iui-container-new type="table" theme="bullet" header-width="120px">
            <i-row>
              <i-col-header :required="isEditableW">공사코드</i-col-header>
              <i-col width="100px">
                <iui-text
                  type="code"
                  regExp="[^a-zA-z]"
                  :value.sync="wcode"
                  :enable="isNew && isEditableW"
                  max-length="1"
                  width="20px"
                />
              </i-col>
              <i-col>
                <iui-text name="wname" :value.sync="wname" :enable="isEditableW" max-length="100" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header :required="isEditableLG">대공종</i-col-header>
              <i-col>
                <iui-text
                  type="code"
                  :value="isEditableLG || isEditableMG ? wcode : ''"
                  :enable="false"
                  max-length="1"
                  width="20px"
                />
                <iui-text
                  type="code"
                  regExp="[^a-zA-z]"
                  :value.sync="lgCode"
                  :enable="isNew && isEditableLG"
                  max-length="1"
                  width="20px"
                />
              </i-col>
              <i-col>
                <iui-text name="lgName" :value.sync="lgName" :enable="isEditableLG" max-length="100" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header :required="isEditableMG">중공종</i-col-header>
              <i-col>
                <iui-text
                  type="code"
                  name="wcode"
                  :value="isEditableMG ? wcode : ''"
                  :enable="false"
                  max-length="1"
                  width="20px"
                />
                <iui-text
                  type="code"
                  name="lgCode"
                  :value="isEditableMG ? lgCode : ''"
                  :enable="false"
                  max-length="1"
                  width="20px"
                />
                <iui-text
                  type="code"
                  regExp="[^a-zA-z]"
                  name="mgCode"
                  :value.sync="mgCode"
                  :enable="isNew && isEditableMG"
                  max-length="1"
                  width="20px"
                />
              </i-col>
              <i-col>
                <iui-text name="mgName" :value.sync="mgName" :enable="isEditableMG" max-length="100" />
              </i-col>
            </i-row>
          </iui-container-new>
        </form>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import {saveWorkTypeClDetailInfo} from '../api/StdWorkTypeRegist.js';
export default {
  props: {
    workTypeClInfo: {
      type: Object,
      default: () => ({}),
    },
    base: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalWorkTypeClInfo: {},
    };
  },
  computed: {
    isEditableW() {
      return this.internalWorkTypeClInfo?.level === (this.isNew ? 1 : 2);
    },
    isEditableLG() {
      return this.internalWorkTypeClInfo?.level === (this.isNew ? 2 : 3);
    },
    isEditableMG() {
      return this.internalWorkTypeClInfo?.level === (this.isNew ? 3 : 4);
    },
    isNew() {
      return this.cud === 1;
    },
    wcode: {
      set(value) {
        this.internalWorkTypeClInfo.code = value;
        this.internalWorkTypeClInfo.wcode = value;
      },
      get() {
        return this.internalWorkTypeClInfo?.wcode;
      },
    },
    wname: {
      set(value) {
        this.internalWorkTypeClInfo.name = value;
        this.internalWorkTypeClInfo.wname = value;
      },
      get() {
        return this.internalWorkTypeClInfo?.wname;
      },
    },
    lgCode: {
      set(value) {
        const fullValue = String(this.internalWorkTypeClInfo.wcode).concat(value);
        this.internalWorkTypeClInfo.code = fullValue;
        this.internalWorkTypeClInfo.lgCode = fullValue;
      },
      get() {
        return this.internalWorkTypeClInfo?.lgCode?.slice(1);
      },
    },
    lgName: {
      set(value) {
        this.internalWorkTypeClInfo.name = value;
        this.internalWorkTypeClInfo.lgName = value;
      },
      get() {
        return this.internalWorkTypeClInfo?.lgName;
      },
    },
    mgCode: {
      set(value) {
        const fullValue = String(this.internalWorkTypeClInfo.lgCode).concat(value);
        this.internalWorkTypeClInfo.code = fullValue;
        this.internalWorkTypeClInfo.mgCode = fullValue;
      },
      get() {
        return this.internalWorkTypeClInfo?.mgCode?.slice(2);
      },
    },
    mgName: {
      set(value) {
        this.internalWorkTypeClInfo.name = value;
        this.internalWorkTypeClInfo.mgName = value;
      },
      get() {
        return this.internalWorkTypeClInfo?.mgName;
      },
    },
  },
  watch: {
    workTypeClInfo: {
      handler(value) {
        this.internalWorkTypeClInfo = value;
      },
      deep: true,
    },
  },
  created() {
    this.internalWorkTypeClInfo = this.WorkTypeClInfo;
    this.addFuncSave(this.onSave);
  },
  methods: {
    async onSave() {
      if (!this.isValid()) {
        return;
      }
      try {
        const param = {
          ...this.internalWorkTypeClInfo,
          base: this.base,
        };
        const response = await saveWorkTypeClDetailInfo(param);
        if (response.data) {
          this.$emit('update:workTypeClInfo', this.internalWorkTypeClInfo);
          this.$emit('saveComplete');
        }
      } catch (error) {
        this.$alert({title: '저장실패', message: error.message});
      }
    },
    isValid() {
      if (this.isEditableW) {
        if (this.internalWorkTypeClInfo.wcode == '' || this.internalWorkTypeClInfo.wname == '') {
          this.$alert('공사코드 & 공사명을 모두 입력해주시기 바랍니다.');
          return false;
        }
      } else if (this.isEditableLG) {
        if (this.internalWorkTypeClInfo.lgCode == '' || this.internalWorkTypeClInfo.lgName == '') {
          this.$alert('대공종코드 & 대공종명을 모두 입력해주시기 바랍니다.');
          return false;
        }
      } else if (this.isEditableMG) {
        if (this.internalWorkTypeClInfo.mgCode == '' || this.internalWorkTypeClInfo.mgName == '') {
          this.$alert('중공종코드 & 중공종명을 모두 입력해주시기 바랍니다.');
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style scoped></style>
