<template>
  <pmis-content-box>
    <template #title>세금계산서</template>
    <template #title-right>
      <iui-button value="인쇄" @click="onPrint" />
      <iui-button value="다운로드" @click="onDownload" />
    </template>
    <iui-container-new type="table" theme="bullet">
      <i-row>
        <i-col>
          <div v-html="billHtml" class="taxInvoiceXml" />
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-html-2-pdf
      print
      :width="width"
      :height="height"
      :generate.sync="isPrint"
      :pdfFileNm="billNo"
      @generate-complete="onGenerateComplete"
    >
      <div v-html="billHtml" class="taxInvoiceXml" />
    </iui-html-2-pdf>

    <iui-html-2-pdf
      download
      :width="width"
      :height="height"
      :generate.sync="isDownload"
      :pdfFileNm="billNo"
      @generate-complete="onGenerateComplete"
    >
      <div v-html="billHtml" class="taxInvoiceXml" />
    </iui-html-2-pdf>
  </pmis-content-box>
</template>

<script>
require('@/view/bidding/components/css/taxInvoice_v3.scss');
import {selectTaxInvoiceViewerInfos as matApi} from '@/view/bidding/billManage/api/bill.js';
import {selectTaxInvoiceViewerInfos as subApi} from '@/view/subBidding/billManage/api/subBill.js';
export default {
  props: {
    billNo: {
      type: String,
      default: undefined,
    },
    pageGubun: {
      type: String,
      default: 'mat',
      // mat : 자재
      // sub : 외주
    },
  },
  data() {
    return {
      billHtml: '',

      isPrint: false,
      isDownload: false,
      width: '665px',
      height: '850px',
    };
  },
  mounted() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      if (!this.billNo) {
        this.$alert({title: '세금계산서 조회', message: '세금계산서 번호가 없습니다.'});
        return;
      }

      const selectTaxInvoiceViewerInfosApi = {mat: matApi, sub: subApi};
      const param = {billNo: this.billNo};

      selectTaxInvoiceViewerInfosApi[this.pageGubun](param).then(response => {
        this.billHtml = response.data.html;
      });
    },
    onPrint() {
      this.callEvent({name: 'showLoading'});
      this.isPrint = true;
    },
    onDownload() {
      this.callEvent({name: 'showLoading'});
      this.isDownload = true;
    },
    onGenerateComplete() {
      this.callEvent({name: 'hideLoading'});
    },
  },
};
</script>

<style scoped></style>
