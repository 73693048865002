export default that => {
  return {
    Cfg: {
      FitWidth: 1,
    },
    Def: {
      Row: {
        CanFormula: true,
        CalcOrder: 'TextColor',
        TextColorFormula: function(param) {
          if (param.Row['labtClose']) {
            return '#FF0000';
          }
        },
      },
    },
    Cols: [
      {
        Header: 'ID',
        Name: 'empNo',
        Type: 'Text',
        Width: 100,
        CanEdit: 0,
      },
      {
        Header: '성명',
        Name: 'name',
        Type: 'Text',
        Width: 100,
        CanEdit: 0,
      },
      {
        Header: '부서명',
        Name: 'blnDepNm',
        Type: 'Text',
        MinWidth: 140,
        RelWidth: 1,
        CanEdit: 0,
      },
    ],
  };
};
