<template>
  <div class="search_box" style="display: flex;">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'pmis-searchbox',
};
</script>

<style></style>
