<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="450px">
          <pmis-tab-box>
            <template v-slot:title>공종분류</template>
            <WorkTypeCl
              :search.sync="search"
              :reload.sync="reload"
              :workTypeClInfo="workTypeClInfo"
              :callback="onCallbackWorkTypeCl"
              :base="isBase"
            ></WorkTypeCl>
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>상세정보</template>
            <template v-slot:title-right v-if="!isBase">
              <iui-button value="표준공종복제" @click="showWorkTypeChoice" />
            </template>
            <DetailInfo :workTypeClInfo="workTypeClInfo" @saveComplete="saveComplete" :base="isBase" />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
    <iui-modal name="workTypeChoice" title="표준공종복제" :btns="modalBtns" sizeType="size1">
      <WorkTypeCl
        :checkbox="true"
        :base="true"
        :popup="true"
        :confirm.sync="confirm"
        :callback="onCallbackBaseWorkTypeCl"
      />
    </iui-modal>
  </pmis-page>
</template>

<script>
import WorkTypeCl from './components/WorkTypeCl.vue';
import DetailInfo from './components/DetailInfo.vue';
import {copyWorkCl} from './api/StdWorkTypeRegist.js';
export default {
  components: {
    WorkTypeCl,
    DetailInfo,
  },

  data() {
    return {
      modalBtns: [{title: '공종선택', name: '확인', callback: this.confirmBaseWorkTypeCl}],
      workTypeClInfo: {},
      search: false,
      reload: false,
      confirm: false,
      isBase: this.$store.getters['mdi/currentUrls'].para === 'base',
    };
  },

  created() {
    this.canSearch = true;
    this.canSave = true;
    this.canDelete = true;
    this.addFuncSearch(this.onSearch);
  },

  methods: {
    onCallbackWorkTypeCl(row) {
      this.workTypeClInfo = row;
      this.canNew = row.level !== 4;
    },
    async onCallbackBaseWorkTypeCl(rows) {
      if (!rows.length) {
        this.$alert({title: '표준공종복제', message: '복제할 항목을 선택해 주세요.'});
        return;
      }

      const wcodeList = new Set();
      const lgCodeList = new Set();
      const mgCodeList = new Set();
      rows.forEach(row => {
        if (row['wcode']) wcodeList.add(row['wcode']);
        if (row['lgCode']) lgCodeList.add(row['lgCode']);
        if (row['mgCode']) mgCodeList.add(row['mgCode']);
      });

      const response = await copyWorkCl({
        wcodeList: Array.from(wcodeList),
        lgCodeList: Array.from(lgCodeList),
        mgCodeList: Array.from(mgCodeList),
      });

      if (response.data) {
        this.onSearch();
        this.$modal.hide('workTypeChoice');
      } else {
        this.$alert({title: '표준공종복제', message: '복제된 항목이 없습니다.'});
      }
    },
    onSearch() {
      this.search = true;
    },
    saveComplete() {
      this.reload = true;
    },
    showWorkTypeChoice() {
      this.$modal.show('workTypeChoice');
    },
    confirmBaseWorkTypeCl() {
      this.confirm = true;
    },
  },
};
</script>
