export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 0},
    Cols: [
      {Name: 'total', Visible: 0, Width: 0},
      {
        Header: '수금횟수',
        Name: 'repCnt',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        MinWidth: 120,
      },
      {
        Header: '수금일자',
        Name: 'collectDate',
        Extend: preset.date,
        Align: 'center',
        RelWidth: 1,
        MinWidth: 120,
      },
      {
        Header: '수금금액',
        Name: 'sumAmt',
        Extend: preset.amount,
        RelWidth: 1,
        MinWidth: 120,
      },
      {
        Header: '미수금',
        Name: 'uncollectAmt',
        Extend: preset.amount,
        RelWidth: 1,
        MinWidth: 120,
      },
      {Name: 'total', Visible: false},
      {Name: 'pgmCd', Visible: false},
      {Name: 'prjCd', Visible: false},
      {Name: 'reqCnt', Visible: false},
    ],
  };
};
