export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      SearchMode: 2,
      CanEdit: 1,
      IgnoreFocused: true,
    },
    Cols: [
      {
        Header: {Value: '선택', TextColor: '#444', HeaderCheck: 1},
        Name: 'cbx',
        Type: 'Bool',
        BoolIcon: 0,
        Align: 'center',
        Width: 70,
      },
      {
        Header: '순번',
        Name: 'rowNum',
        Type: 'int',
        Align: 'center',
        Width: 50,
        CanEdit: false,
      },
      {
        Header: '금액',
        Name: 'deductAmt',
        Extend: preset.amount,
        Width: 120,
      },
      {
        Header: '내역',
        Name: 'deductRmk',
        Type: 'Text',
        RelWidth: 1,
      },
      {Name: 'pgmCd', Visible: false},
      {Name: 'prjCd', Visible: false},
      {Name: 'reqCnt', Visible: false},
      {Name: 'seq', Visible: false},
      {Name: 'orgDeductAmt', Visible: false, NoChanged: true},
    ],
  };
};
