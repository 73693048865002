export default that => {
  return {
    Cols: [
      {
        Header: '장비코드',
        Name: 'equipCode',
        Type: 'Text',
        Align: 'center',
        Width: 150,
        CanEdit: 0,
      },
      {
        Header: '장비명',
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: '규격',
        Name: 'size',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
        Visible: that.isPopup ? 0 : 1,
      },
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        CanEdit: 0,
        Visible: that.isPopup ? 0 : 1,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: '차량번호',
        Name: 'carNo',
        Type: 'Text',
        Width: 150,
        CanEdit: 0,
      },
      {
        Header: '사용구분',
        Name: 'useGb',
        Type: 'Text',
        Align: 'center',
        CanEdit: 0,
        Visible: that.isPopup ? 0 : 1,
        Format: $getConstants('USE_GB').code,
      },
      {
        Header: '투입일자',
        Name: 'rlseDate',
        Type: 'Text',
        CanEdit: 0,
        Extend: that.$ibsheetHelper.preset.date,
        Visible: that.isPopup ? 0 : 1,
      },
      {
        Header: '철수일자',
        Name: 'outDate',
        Type: 'Text',
        CanEdit: 0,
        Extend: that.$ibsheetHelper.preset.date,
        Visible: that.isPopup ? 0 : 1,
      },
      {
        Header: '시간당가동단가',
        Name: 'runPrice',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
        Extend: that.$ibsheetHelper.preset.unitAmount,
      },
      {Name: 'cud', Visible: false},
      {Name: 'pgmCd', Visible: false},
      {Name: 'prjCd', Visible: false},
      {Name: 'useOil', Visible: false}, //사용유류
      {Name: 'oilDeductDs', Visible: false}, //유류대공제여부
      {Name: 'custCode', Visible: false}, //거래처코드
      {Name: 'frmNm', Visible: false}, //거래처명
      {Name: 'rpsvNm', Visible: false}, //대표자명
      {Name: 'rpsvPhone', Visible: false}, //거래처전화번호
      {Name: 'lentDate', Visible: false}, //임차일자
      {Name: 'insuStDt', Visible: false}, //보험기간(시작일)
      {Name: 'insuEtDt', Visible: false}, //보험기간(종료일)
      {Name: 'mainOperatorId', Visible: false}, //기사_id
      {Name: 'mainOperatorNm', Visible: false}, //기사_id
      {Name: 'name', Visible: false}, //기사_성명
      {Name: 'mobileTlno', Visible: false}, //기사_휴대폰번호
      {Name: 'mainOperatorNo', Visible: false}, //기사_면허번호
      {Name: 'mainOperatorStrdate', Visible: false}, //기사_면허기간_시작일
      {Name: 'mainOperatorEnddate', Visible: false}, //기사_면허기간_종료일
      {Name: 'postNo', Visible: false}, //우편번호
      {Name: 'adr1', Visible: false}, //주소
      {Name: 'adr2', Visible: false}, //상세주소
      {Name: 'isExistEquipDaily', Visible: false},
    ],
  };
};
