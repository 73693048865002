<template>
  <pmis-content-box>
    <template v-slot:title>{{ title }}</template>
    <iui-container-new type="css-flex">
      <i-row height="330px">
        <i-col>
          <iui-container-new type="table" theme="bullet" header-width="70px">
            <i-row height="300px">
              <i-col-header>결재의견</i-col-header>
              <i-col>
                <iui-text type="multi" name="rmk" :value="rmk" @change="rmk = $event.target.value" max-length="4000" />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <p>{{ subTitle }}</p>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    alert: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rmk: '',
    };
  },
  created() {
    this.addEvent({name: 'alarm_onConfirm', func: this.onConfirm});
  },
  methods: {
    onConfirm(func) {
      if (typeof func == 'function') {
        func(this.rmk);
      }
    },
  },
};
</script>

<style scoped>
p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
</style>
