export default that => {
  return {
    Cfg: {
      CanEdit: false,
    },
    Def: {Row: {CanFocus: 0}},
    Cols: [
      {
        Name: 'subCodeName',
        Header: '외주명',
        Width: 150,
        Type: 'Text',
        Align: 'Center',
      },
      {
        Name: 'lstCtrtTotAmt',
        Header: '계약금액',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
      },
      {
        Name: 'dayAmt',
        Header: '금일투입',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
      },
      {
        Name: 'monthAmt',
        Header: '금월투입',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
      },
      {
        Name: 'totAmt',
        Header: '누계투입',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
      },
      {
        Name: 'progressRate',
        Header: '진행율(%)',
        Extend: that.$ibsheetHelper.preset.rate,
        RelWidth: 1,
      },
      {
        Name: 'remainAmt',
        Header: '잔여금액',
        Extend: that.$ibsheetHelper.preset.rate,
        RelWidth: 1,
      },
    ],
  };
};
