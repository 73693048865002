<template>
  <form onsubmit="return false;">
    <pmis-content-box>
      <iui-container-new type="css-flex">
        <i-row height="260px">
          <i-col :min-width="workSe == 'matM' ? '600px' : '530px'">
            <iui-container-new type="table" theme="bullet">
              <i-row>
                <i-col-header :required="isEditableLM">대분류</i-col-header>
                <i-col>
                  <iui-text
                    name="lmCode"
                    type="smallcode"
                    regExp="[^0-9]"
                    :value.sync="lmCode"
                    :enable="isNew && isEditableLM"
                    max-length="2"
                    min-length="2"
                    width="30px"
                    :required="isNew && isEditableLM"
                    :errorMessage="{title: '대분류', message: '대분류코드를 입력해주세요.'}"
                  />
                  <span style="width:170px" />
                  <iui-text
                    name="lmName"
                    :value.sync="lmName"
                    :enable="isEditableLM"
                    :required="isEditableLM"
                    max-length="100"
                    :errorMessage="{title: '대분류', message: '대분류명을 입력해주세요.'}"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header :required="isEditableMM">중분류</i-col-header>
                <i-col>
                  <iui-text
                    name="lmCode"
                    type="smallcode"
                    :value="level > 2 ? lmCode : ''"
                    :enable="false"
                    max-length="2"
                    min-length="2"
                    width="30px"
                  />
                  <iui-text
                    name="mmCode"
                    type="smallcode"
                    regExp="[^0-9]"
                    :value.sync="mmCode"
                    :enable="isNew && isEditableMM"
                    max-length="2"
                    min-length="2"
                    width="30px"
                    :required="isNew && isEditableMM"
                    :errorMessage="{title: '중분류', message: '중분류코드를 입력해주세요.'}"
                  />
                  <span style="width:135px" />
                  <iui-text
                    name="mmName"
                    :value.sync="mmName"
                    :enable="isEditableMM"
                    :required="isEditableMM"
                    max-length="100"
                    :errorMessage="{title: '대분류', message: '중분류명을 입력해주세요.'}"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header :required="isEditableSM">소분류</i-col-header>
                <i-col>
                  <iui-text
                    name="lmCode"
                    type="smallcode"
                    :value="level > 3 ? lmCode : ''"
                    :enable="false"
                    max-length="2"
                    min-length="2"
                    width="30px"
                  />
                  <iui-text
                    name="mmCode"
                    type="smallcode"
                    :value="level > 3 ? mmCode : ''"
                    :enable="false"
                    max-length="2"
                    min-length="2"
                    width="30px"
                  />
                  <iui-text
                    name="smCode"
                    type="smallcode"
                    regExp="[^0-9]"
                    :value.sync="smCode"
                    :enable="isNew && isEditableSM"
                    max-length="2"
                    min-length="2"
                    width="30px"
                    :required="isNew && isEditableSM"
                    :errorMessage="{title: '소분류', message: '소분류코드를 입력해주세요.'}"
                  />
                  <span style="width:100px" />
                  <iui-text
                    name="smName"
                    :value.sync="smName"
                    :enable="isEditableSM"
                    :required="isEditableSM"
                    max-length="100"
                    :errorMessage="{title: '소분류', message: '소분류명을 입력해주세요.'}"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col></i-col>
              </i-row>
              <i-row>
                <i-col-header :required="isEditableMat">자재코드</i-col-header>
                <i-col>
                  <iui-text
                    name="lmCode"
                    type="smallcode"
                    :value="lmCodeInMatCode"
                    :enable="false"
                    max-length="2"
                    width="30px"
                  />
                  <iui-text
                    name="mmCode"
                    type="smallcode"
                    :value="mmCodeInMatCode"
                    :enable="false"
                    max-length="2"
                    width="30px"
                  />
                  <iui-text
                    name="smCode"
                    type="smallcode"
                    :value="smCodeInMatCode"
                    :enable="false"
                    max-length="2"
                    width="30px"
                  />
                  <iui-text
                    name="matCode"
                    type="largecode"
                    :value="String(this.matCodeInfo.matCode || '').slice(6)"
                    @change="setMatCodeInfo({matCode: String(internalMatClInfo.smCode).concat($event.target.value)})"
                    :enable="isNew && isEditableMat"
                    max-length="10"
                    min-length="10"
                    width="100px"
                    :required="isNew && isEditableMat"
                    :errorMessage="{title: '자재코드', message: '자재코드를 입력해주세요.'}"
                  />
                  <iui-text
                    name="itemName"
                    :value="matCodeInfo.itemName"
                    @change="setMatCodeInfo({itemName: $event.target.value})"
                    :enable="isEditableMat"
                    max-length="300"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>규격</i-col-header>
                <i-col>
                  <iui-text
                    name="size"
                    :value="matCodeInfo.size"
                    @change="setMatCodeInfo({size: $event.target.value})"
                    :enable="isEditableMat"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>형식</i-col-header>
                <i-col>
                  <iui-text
                    name="matStyle"
                    :value="matCodeInfo.matStyle"
                    @change="setMatCodeInfo({matStyle: $event.target.value})"
                    :enable="isEditableMat"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col colspan="2">
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header>단위</i-col-header>
                      <i-col>
                        <iui-text name="unitName" type="smallcode" :value="unitName" :enable="false" />
                        <iui-button value="검색" @click="onUnitPopup" :enable="isEditableMat" />
                      </i-col>
                      <i-col-header>사용여부</i-col-header>
                      <i-col>
                        <iui-checkbox-group
                          name="useDs"
                          :items="[{label: '사용', value: $getConstants('Y').code}]"
                          width="120px"
                          :checkedValues="[matCodeInfo.useDs]"
                          :enable="isEditableMat"
                          @change="setMatCodeInfo({useDs: $event.target.checked ? $event.target.value : ''})"
                        />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
            </iui-container-new>
          </i-col>
          <i-col></i-col>
          <i-spacer />
          <i-col width="220px" height="300px">
            <pmis-image-file
              :file-number.sync="flNo"
              :saveAuth="isEditableMat"
              @delete-complete="image_delete_complete"
              @delete-error="image_delete_error"
              id="matImage"
              :clear.sync="clearFlNo"
              :initInternalImageSource="initInternalImageSource"
            />
          </i-col>
        </i-row>
      </iui-container-new>
      <iui-modal name="unitModal" title="단위 검색" width="1000px" height="500px" :btns="modalBtns">
        <UnitPopup />
      </iui-modal>
    </pmis-content-box>
  </form>
</template>

<script>
import UnitPopup from '@/view/sysManage/stdCodeManage/components/UnitPopup.vue';
import {saveImageFileNo, saveMaterialInfo} from '../api/MatCodeRegist.js';

export default {
  components: {UnitPopup},
  props: {
    workSe: {
      type: String,
      default: 'matM',
    },
    base: {
      type: Boolean,
      default: false,
    },
    matClInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      modalBtns: [{name: '확인', callback: this.onUnitPopupConfirm}],
      internalMatClInfo: {},
      matCodeInfo: {},
      unitCodeList: [],
      clearFlNo: false,
      initInternalImageSource: false,
    };
  },
  computed: {
    flNo: {
      set(value) {
        this.matCodeInfo.flNo = value;
      },
      get() {
        return this.matCodeInfo.flNo || 0;
      },
    },
    isEditableLM() {
      return this.internalMatClInfo?.level === (this.isNew ? 1 : 2);
    },
    isEditableMM() {
      return this.internalMatClInfo?.level === (this.isNew ? 2 : 3);
    },
    isEditableSM() {
      return this.internalMatClInfo?.level === (this.isNew ? 3 : 4);
    },
    isEditableMat() {
      return !!this.matCodeInfo.matCode || this.internalMatClInfo?.level === 4;
    },
    isNew() {
      return this.cud === 1;
    },
    level() {
      return this.matCodeInfo.level ?? this.internalMatClInfo?.level + (this.isNew ? 1 : 0);
    },
    lmCode: {
      set(value) {
        this.internalMatClInfo.code = value;
        this.internalMatClInfo.lmCode = value;
      },
      get() {
        return this.level > (this.isNew ? 2 : 1) ? this.matCodeInfo.lmCode ?? this.internalMatClInfo?.lmCode : '';
      },
    },
    lmName: {
      set(value) {
        this.internalMatClInfo.lmName = value;
        this.internalMatClInfo.name = value;
      },
      get() {
        return this.level > (this.isNew ? 2 : 1) ? this.matCodeInfo.lmName ?? this.internalMatClInfo?.lmName : '';
      },
    },
    mmCode: {
      set(value) {
        const fullValue = String(this.internalMatClInfo.lmCode).concat(value);
        this.internalMatClInfo.code = fullValue;
        this.internalMatClInfo.mmCode = fullValue;
      },
      get() {
        return String(
          this.level > (this.isNew ? 3 : 2) ? this.matCodeInfo.mmCode ?? this.internalMatClInfo?.mmCode ?? '' : ''
        ).slice(2);
      },
    },
    mmName: {
      set(value) {
        this.internalMatClInfo.mmName = value;
        this.internalMatClInfo.name = value;
      },
      get() {
        return this.level > (this.isNew ? 3 : 2) ? this.matCodeInfo.mmName ?? this.internalMatClInfo?.mmName : '';
      },
    },
    smCode: {
      set(value) {
        const fullValue = String(this.internalMatClInfo.mmCode).concat(value);
        this.internalMatClInfo.code = fullValue;
        this.internalMatClInfo.smCode = fullValue;
      },
      get() {
        return String(
          this.level > (this.isNew ? 4 : 3) ? this.matCodeInfo.smCode ?? this.internalMatClInfo?.smCode ?? '' : ''
        ).slice(4);
      },
    },
    smName: {
      set(value) {
        this.internalMatClInfo.smName = value;
        this.internalMatClInfo.name = value;
      },
      get() {
        return this.level > (this.isNew ? 4 : 3) ? this.matCodeInfo.smName ?? this.internalMatClInfo?.smName : '';
      },
    },
    lmCodeInMatCode() {
      return String(
        this.isNew && this.internalMatClInfo?.level === 4
          ? this.internalMatClInfo?.lmCode
          : this.matCodeInfo.lmCode ?? ''
      );
    },
    mmCodeInMatCode() {
      return String(
        this.isNew && this.internalMatClInfo?.level === 4
          ? this.internalMatClInfo?.mmCode
          : this.matCodeInfo.mmCode ?? ''
      ).slice(2);
    },
    smCodeInMatCode() {
      return String(
        this.isNew && this.internalMatClInfo?.level === 4
          ? this.internalMatClInfo?.smCode
          : this.matCodeInfo.smCode ?? ''
      ).slice(4);
    },
    unitName() {
      const codeInfo = this.unitCodeList.find(item => item.code === String(this.matCodeInfo.unit));
      return codeInfo?.codeNm;
    },
  },
  watch: {
    flNo(val) {
      this.setMatCodeInfo({flNo: val});
    },
    matClInfo: {
      handler(value) {
        this.internalMatClInfo = value;
      },
      deep: true,
    },
  },
  beforeCreate() {
    $mapGetters(this, ['status']);
    $mapMutations(this, ['setFocusKeyCode']);
  },
  created() {
    this.addEvent([{name: 'onUnitPopupConfirm', func: this.onUnitPopupConfirm}]);
    this.addEvent([{name: 'clearImage', func: this.clearImage}]);
    this.internalMatClInfo = this.matClInfo;
    this.setUnitCodeList();
  },
  methods: {
    clearImage() {
      this.clearFlNo = true;
    },
    async setUnitCodeList() {
      this.unitCodeList = await $getCode($getConstants('UNIT').code);
    },
    async onSave() {
      try {
        const param = {...this.matCodeInfo, ...this.internalMatClInfo, base: this.base};
        const response = await saveMaterialInfo(param);
        if (response.data) {
          if (this.internalMatClInfo.lmCode) {
            this.matCodeInfo['lmCode'] = this.internalMatClInfo.lmCode;
          }
          if (this.internalMatClInfo.mmCode) {
            this.matCodeInfo['mmCode'] = this.internalMatClInfo.mmCode;
          }
          if (this.internalMatClInfo.smCode) {
            this.matCodeInfo['smCode'] = this.internalMatClInfo.smCode;
          }
          if (this.flNo !== 0) {
            this.initInternalImageSource = true;
          }
          return {matCodeInfo: this.matCodeInfo, matClInfo: this.internalMatClInfo};
        }
      } catch (error) {
        this.$alert({title: '저장', message: error.message});
      }
    },
    setMatCodeInfo(data) {
      this.matCodeInfo = {...this.matCodeInfo, ...data};
    },
    onUnitPopup() {
      this.$modal.show('unitModal');
    },
    onUnitPopupConfirm() {
      this.callEvent({
        name: 'UnitPopupp_popConfirm',
        param: data => {
          this.setMatCodeInfo({unit: data.unitCode});
          this.$modal.hide('unitModal');
        },
      });
    },
    image_save_complete() {
      if (this.cud != 1) {
        this.setMatCodeInfo({flNo: this.flNo});
        this.onSaveImageFileNo();
      }
    },
    image_delete_complete() {
      this.setMatCodeInfo({flNo: 0});
      this.onSaveImageFileNo();
    },
    image_delete_error() {
      this.$alert({title: '파일삭제', message: '파일 삭제를 실패했습니다.'});
    },
    async onSaveImageFileNo() {
      let param = {
        matCode: this.matCodeInfo.matCode,
        flNo: this.matCodeInfo.flNo,
      };
      let response = await saveImageFileNo(param);
      if (response.data) {
        this.callEvent({name: 'changeFlNo', param: this.matCodeInfo.flNo});
      }
    },
  },
};
</script>

<style scoped></style>
