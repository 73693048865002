<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text type="search" ref="search" :value.sync="searchFocusNm" @enter="onFindRow" />
      <iui-button value="검색" @click="onFindRow" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onSearchFinish: grid_onSearchFinish,
        onFocus: grid_onFocus,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOptions/MasterEqupmnListSheet.js';
import {selectEqutCodeList} from '@/view/sysManage/stdCodeManage/equipCodeRegist/api/EquipCodeRegist.js';
export default {
  data() {
    return {
      loadSearchData: [],
      Options: options(this),
      elId: `sheetDiv${this._uid}_eq1`,
      searchFocusNm: '',
      autoFocus: false,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['detailInfo']);
    $mapMutations(this, ['setDetailInfo']);
  },
  created() {
    this.addEvent([
      {name: 'MasterEqupmnList_onSearch', func: this.onSearch},
      {name: 'MasterEqupmnList_getFocusedRow', func: this.getFocusedRow},
    ]);
  },
  methods: {
    onFindRow() {
      let searchCnt = this.sheetUtil.listSearch(['equipCode', 'itemName'], this.searchFocusNm);
      if (!searchCnt) {
        this.$alert({title: '검색 결과 없음', message: '검색 결과가 없습니다.'});
      }
      this.$refs.search.$refs.input.focus();
    },
    async onSearch(code) {
      this.autoFocus = code === 4 ? false : true;

      let response = await selectEqutCodeList({
        pgmCd: this.pgmCd,
        code: code === 4 ? '' : code,
      });

      if (response.status == 200) {
        this.loadSearchData = response.data;
      }
    },
    grid_onSearchFinish() {
      if (this.autoFocus) {
        this.sheet.focus(this.sheet.getFirstRow());
      } else {
        this.callEvent({name: 'HoldEqupmnList_onSearch'});
      }
    },
    grid_onFocus(e) {
      if (e.row.Kind == 'Data') {
        // this.setDetailInfo({...e.row, cud: 2});
        this.callEvent({name: 'HoldEqupmnList_onSearch', param: e.row.equipCode});
      }
    },
    getFocusedRow(callback) {
      if (typeof callback == 'function') {
        callback(this.sheet.getFocusedRow());
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped></style>
