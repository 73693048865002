import pick from 'lodash/pick';
import FOM_NO from '@/constants/fomNoConstants.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import BiddingApprovalMixin from '@/view/bidding/js/biddingApprovalMixin.js';

import RfqCusResultList from '@/view/bidding/rfq/common/components/RfqCusResultList.vue';
import ReBid from '@/view/bidding/rfq/common/components/ReBid.vue';
import Fail from '@/view/bidding/rfq/common/components/Fail.vue';
import Degree from '@/view/bidding/rfq/common/components/Degree.vue';

export default {
  components: {RfqCusResultList, ReBid, Fail, Degree},
  mixins: [BiddingApprovalMixin],
  data() {
    return {
      choiceNotiFlagItems: [
        {label: '낙찰업체', value: '1'},
        {label: '낙찰금액', value: '2'},
        {label: '순위공개', value: '3'},
      ],

      reBidModalBtn: [{name: '재입찰', callback: this.reBid}],
      failModalBtn: [{name: '유찰', callback: this.failBid}],
      compareModalBtn: [{name: '인쇄', callback: this.printCompareInfo}],
      degreeModalBtn: [{name: '인쇄', callback: this.onPrintDegree}],
    };
  },
  computed: {
    quotTotRate() {
      return `${this.rfqInfo.quotCusCount} / ${this.rfqInfo.totCusCount}`;
    },
    compareFomNo() {
      return {
        mat: FOM_NO.RFQ_COMPARE_PRINT,
        sub: FOM_NO.SUB_RFQ_COMPARE_PRINT,
      }[this.bidType];
    },
  },
  beforeCreate() {
    $mapGetters(this, ['rfqInfo', 'rfqStatus', 'bidType', 'rfqCusList', 'compareQuotList']);
    $mapMutations(this, ['setRfqInfo']);
  },
  created() {
    this.getApis();

    this.canSave = this.rfqStatus.isRfqOpen || this.rfqStatus.isRfqChoice;
    this.addFuncSave(this.onSave);
  },
  updated() {
    this.canSave = this.rfqStatus.isRfqOpen || this.rfqStatus.isRfqChoice;
  },
  methods: {
    async getApis() {
      const apiList = ['choiceRfq'];
      if (this.bidType === 'mat') {
        this.apis = pick(await import('@/view/bidding/rfq/mat/api/rfq.js'), apiList);
      }
      if (this.bidType === 'sub') {
        this.apis = pick(await import('@/view/bidding/rfq/sub/api/subRfq.js'), apiList);
      }
    },
    async onSave() {
      const selectedRow = this.rfqCusResultListSheet.getRowsByChecked('rdo')[0];

      if (!selectedRow) {
        this.$alert({title: '낙찰사 선정', message: '낙찰할 협력사를 선택해야 합니다.'});
        return;
      }

      const title = '낙찰정보 저장';
      const message = '낙찰정보를 저장하시겠습니까?';

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      const rfqNo = this.rfqInfo.rfqNo;
      const cusCd = selectedRow.cusCd;
      const choiceNotiFlag = this.rfqInfo.choiceNotiFlag;
      const choiceRsltRmrk = this.rfqInfo.choiceRsltRmrk;

      this.apis['choiceRfq'](rfqNo, cusCd, choiceNotiFlag, choiceRsltRmrk)
        .then(response => {
          if (response.status == 200) {
            this.callEvent({name: `RfqList_Search`, param: rfqNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    openReBidModal() {
      this.$modal.show('reBidModal');
    },
    openFailModal() {
      this.$modal.show('failModal');
    },
    openDegreeModal() {
      this.$modal.show('degreeModal');
    },
    /** 재입찰 */
    reBid() {
      this.callEvent({
        name: 'ReBid_Save',
        param: rfqNo => {
          this.$modal.hide('reBidModal');
          this.callEvent({name: `RfqList_Search`, param: rfqNo});
        },
      });
    },
    /** 유찰 */
    failBid() {
      this.callEvent({
        name: 'Fail_Save',
        param: rfqNo => {
          this.$modal.hide('failModal');
          this.callEvent({name: `RfqList_Search`, param: rfqNo});
        },
      });
    },
    onApprovalEmit() {
      this.callEvent({name: 'RfqList_Search', param: this.rfqInfo.rfqNo});
    },
    async openCompareViewer() {
      const regex = /\B(?=(\d{3})+(?!\d))/g;

      // 입찰정보
      const RfqInfo = {
        spotDeptcd: this.rfqInfo.spotDeptcd,
        spotDeptnm: this.rfqInfo.spotDeptnm,
        rfqNo: this.rfqInfo.rfqNo2,
        ttl: this.rfqInfo.ttl,
        bidRnd: `${this.rfqInfo.lastBidRnd}차`,
        totCusCountText: this.quotTotRate,
        bidStartDt: this.rfqInfo.bidStartDt,
        bidExpireDt: this.rfqInfo.bidExpireDt,
        bidOpenDt: this.rfqInfo.bidOpenDt,
        bidPlanAmt: `${this.rfqInfo.bidPlanAmt.toString().replace(regex, ',')} (부가세포함)`,
      };

      // 참여업체목록
      const CusList = JSON.stringify({
        CusList: await Promise.all(
          this.rfqCusList.map(async row => {
            const b1 = row.spotDscrYn == BIDDING_CONSTANTS.CODES.SPOT_DSCR_N; // 현설없음
            const b2 = row.quotSbmtYn == BIDDING_CONSTANTS.CODES.QUOT_SBMT_Y; // 견적서 제출함

            const spotDscrApplyAt = b1 ? '-' : row.spotDscrApplyYn ? 'O' : 'X'; // 현설참가신청여부
            const spotDscrAt = b1 ? '-' : row.spotDscrChkYn ? 'O' : 'X'; // 현설참여여부
            const quotSbmtYn = b2 ? 'O' : 'X';

            const bidPlanAmtRate = isNaN(row.bidPlanAmtRate) ? row.bidPlanAmtRate : row.bidPlanAmtRate.toFixed(2);
            const minTotAmtRate = isNaN(row.minTotAmtRate) ? row.minTotAmtRate : row.minTotAmtRate.toFixed(2);
            const totAmt = isNaN(row.totAmt) ? row.totAmt : String(row.totAmt).replace(regex, ',');
            const icprPhone = (await $_phoneFormat(row.icprPhone ?? '')).join('-');

            return {
              rowNum: row.rowNum,
              cusNm: row.frmNm,
              name: `${row.icprNm}${icprPhone ? ` (${icprPhone})` : ''}`,

              spotDscrApplyAt,
              spotDscrAt,
              quotSbmtYn,

              bidPlanAmtRate,
              minTotAmtRate,
              totAmt,
            };
          })
        ),
      });

      // 상세내역
      const cusCount =
        this.rfqCusList.filter(cus => cus.quotSbmtYn === BIDDING_CONSTANTS.CODES.QUOT_SBMT_Y).length > 3
          ? 3
          : this.rfqCusList.filter(cus => cus.quotSbmtYn === BIDDING_CONSTANTS.CODES.QUOT_SBMT_Y).length;

      const QuotCusInfo = {
        cusCount,
        cusNm1: this.compareQuotList[0]?.cusNm_1 || '',
        cusNm2: this.compareQuotList[0]?.cusNm_2 || '',
        cusNm3: this.compareQuotList[0]?.cusNm_3 || '',
      };
      const QuotList = JSON.stringify({
        QuotList: this.compareQuotList.map(row => {
          const unitPrc0 = String(row.planUnitPrc || '').replace(regex, ',');
          const unitPrc1 = String(row.unitPrc_1 || '').replace(regex, ',');
          const unitPrc2 = String(row.unitPrc_2 || '').replace(regex, ',');
          const unitPrc3 = String(row.unitPrc_3 || '').replace(regex, ',');

          const amt0 = String(row.planAmt || '').replace(regex, ',');
          const amt1 = String(row.amt_1 || '').replace(regex, ',');
          const amt2 = String(row.amt_2 || '').replace(regex, ',');
          const amt3 = String(row.amt_3 || '').replace(regex, ',');

          const returnObj = {
            itemNm: row.itemNm,
            sizeNm: row.sizeNm,
            unitNm: row.unitNm,
            qty: row.qty,

            unitPrc0,
            unitPrc1,
            unitPrc2,
            unitPrc3,

            amt0,
            amt1,
            amt2,
            amt3,
          };
          if (this.bidType === 'sub') {
            returnObj['srvNm'] = row.srvNm;
          }

          return returnObj;
        }),
      });

      const outList = [];
      if (this.compareQuotList[0]?.cusCd_4) {
        let start = 4;
        let min = start;
        let max = min;
        while (this.compareQuotList[0][`cusCd_${start}`]) {
          max = start;
          start++;
        }

        for (min; min <= max; min++) {
          const amt = this.compareQuotList.reduce((prev, next) => {
            return prev + next[`amt_${min}`];
          }, 0);
          outList.push({
            rownum: min - 3,
            cusNm: this.compareQuotList[0][`cusNm_${min}`],
            amt: $_numberFormat(amt, true),
          });
        }
      }
      const OutList = JSON.stringify({OutList: outList});

      this.ozContent = {...RfqInfo, ...QuotCusInfo, CusList, QuotList, OutList};
      this.$modal.show('Compare_ReportViewerModal');
    },
    /** 견적대비표 인쇄 */
    printCompareInfo() {
      this.callEvent({name: 'Compare_Print'});
    },
    onPrintDegree() {
      this.callEvent({name: 'Degree_Print'});
    },
  },
};
