var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('iui-container-new',{attrs:{"type":"css-flex"}},[_c('i-row',{attrs:{"height":"200px"}},[_c('i-col',[_c('ib-sheet',{attrs:{"uid":_vm._uid,"options":_vm.Options1,"loadSearchData":_vm.loadSearchData1,"events":{
          onSearchFinish: _vm.onSearchFinish1,
          onClick: _vm.onClick1,
        }},on:{"loadSheet":_vm.loadSheet1}})],1)],1),_c('i-row',[_c('i-col',[_c('ib-sheet',{attrs:{"uid":_vm._uid,"options":_vm.Options2,"loadSearchData":_vm.loadSearchData2,"events":{
          onSearchFinish: _vm.onSearchFinish2,
          onClick: _vm.onClick2,
          onDblClick: _vm.onDblClick,
        }},on:{"loadSheet":_vm.loadSheet2}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }