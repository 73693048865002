export default that => {
  return {
    Cfg: {
      CanEdit: false,
    },
    Def: {
      Row: {
        NoColor: 1,
      },
    },
    Cols: [
      {
        Header: '대공종명',
        Name: 'headName',
        Type: 'Text',
        Width: 140,
      },
      {
        Header: '중공종명',
        Name: 'srvNm',
        Type: 'Text',
        Width: 140,
      },
      {
        Header: '내역코드',
        Name: 'itemSeq',
        Type: 'Text',
        Align: 'Center',
        Width: 90,
      },
      {
        Header: '내역명',
        Name: 'itemNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 140,
      },
      {
        Header: '규격',
        Name: 'sizeNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 140,
      },
      {
        Header: '단위',
        Name: 'unitNm',
        Type: 'Text',
        Align: 'Center',
        Width: 60,
      },
      {
        Header: '수량',
        Name: 'qty',
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 80,
      },
      {
        Header: '금액',
        Name: 'amt',
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 110,
      },
      {Name: 'poNo', Visible: false},
      {Name: 'cusCd', Visible: false},
      {Name: 'wcode', Visible: false},
      {Name: 'headCode', Visible: false},
      {Name: 'midCode', Visible: false},
      {Name: 'itemCode', Visible: false},
      {Name: 'unit', Visible: false},
    ],
  };
};
