export default that => {
  return {
    Cfg: {
      CanEdit: true,
    },
    Cols: [
      {
        Header: '자격면허종류',
        Name: 'wpart',
        Type: 'Text',
        RelWidth: 1.8,
        Align: 'center',
      },
      {
        Header: '자격면허등급',
        Name: 'wpartGrade',
        Type: 'Text',
        RelWidth: 1.3,
        Align: 'center',
      },
      {
        Header: '자격면허번호',
        Name: 'wcertNo',
        Type: 'Text',
        RelWidth: 1.3,
        Align: 'center',
      },
      {
        Header: '취득일자',
        Name: 'wcertDate',
        Type: 'Date',
        RelWidth: 1.3,
      },
      {
        Header: '유효일자',
        Name: 'wcertToDate',
        Type: 'Date',
        RelWidth: 1.3,
      },
      {
        Header: '발행기관',
        Name: 'wcertOrgan',
        Type: 'Text',
        RelWidth: 1.3,
        Align: 'center',
      },
      {Name: 'pgmCd', Visible: 0},
      {Name: 'empNo', Visible: 0},
      {Name: 'skillSeq', Visible: 0},
    ],
  };
};
