<template>
  <pmis-content-box :loading="loading">
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onAfterChange, onSearchFinish}"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOptions/GrpMenuAuthListSheet.js';
import {selectGrpMenuAuthList} from '../api/grpAuth.js';
export default {
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      sheetUtil: undefined,
      focusMnuCd: undefined,
      loading: false,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['grpAuthInfo', 'userAuthInfo']);
  },
  created() {
    this.addEvent([
      {name: 'selectGrpMenuAuthList', func: this.onSearch},
      {name: 'grpMenuAuthSaveJson', func: this.grpMenuAuthSaveJson},
    ]);
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
    onSearchFinish(e) {
      if (this.focusMnuCd) {
        this.sheetUtil.treeFocus('mnuCd', this.focusMnuCd);
        this.focusMnuCd = undefined;
      }
    },
    onAfterChange(e) {
      this.focusMnuCd = e.row.mnuCd;
      if (e.col == 'vwYn' && e.val == $getConstants('USE_YN_N').code) {
        this.sheet.setValue(e.row, 'svYn', $getConstants('USE_YN_N').code, 1);
        this.sheet.setValue(e.row, 'prnYn', $getConstants('USE_YN_N').code, 1);
      }

      e.sheet.getChildRows(e.row).some(item => {
        if (e.row.Level == 1) {
          if (e.row.mnuCd != item.uppMnuCd) {
            return true;
          }
        }

        if (e.col == 'vwYn') {
          if (e.val == $getConstants('USE_YN_N').code) {
            e.sheet.setValue(item, 'vwYn', e.val, 1);
            e.sheet.setValue(item, 'svYn', e.val, 1);
            e.sheet.setValue(item, 'delYn', e.val, 1);
            e.sheet.setValue(item, 'prnYn', e.val, 1);
          } else {
            e.sheet.setValue(item, 'vwYn', e.val, 1);
          }
        } else {
          e.sheet.setValue(item, e.col, e.val, 1);
        }
      });
    },

    async onSearch() {
      if (!this.grpAuthInfo.seq) {
        this.sheet?.removeAll();
        return;
      }
      this.loading = true;
      const response = await selectGrpMenuAuthList({seq: this.grpAuthInfo.seq});
      let items = $_treeModel(response.data, 'PGM000', 'mnuCd', 'uppMnuCd');
      this.loadSearchData = items;
      this.loading = false;
    },

    grpMenuAuthSaveJson(callback) {
      if (callback) {
        callback(this.sheet.getSaveJson().data);
      }
    },
  },
};
</script>

<style></style>
