<template>
  <iui-container-new type="css-flex">
    <i-row min-height="200px">
      <i-col>
        <pmis-tab-box :loading="loading">
          <template #title>기성목록</template>
          <template #title-right>금액단위(원)</template>

          <ib-sheet
            :uid="_uid"
            :options="sheetOpt"
            :loadSearchData="loadSearchData"
            @loadSheet="sheet = $event"
            :events="{
              onSearchFinish: sheet_onSearchFinish,
              onClick: sheet_onClick,
            }"
          />
        </pmis-tab-box>
      </i-col>
    </i-row>
    <i-spacer />
    <i-row height="490px">
      <i-col>
        <iui-tab :comps="tabComps" :visibles="tabVisibles" @click="onClickTab" :isAuto="false" :keepAlive="true" />
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import sheetOpt from './sheetOptions/PxcondDtlsRegistSheet.js';
import {selectPxcondList} from '@/view/pxcondRqestManage/pxcondDtlsRegist/api/pxcondDtlsRegist.js';

export default {
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],
      tabComps: [
        {
          tabNm: '기성정보',
          compNm: 'PxcondInfo',
          path: '@/view/pxcondRqestManage/components/PxcondInfo.vue',
        },
        {
          tabNm: '기성내역',
          compNm: 'PxcondDetailInfo',
          path: '@/view/pxcondRqestManage/components/PxcondDetailInfo.vue',
        },
      ],
      loading: false,
    };
  },
  computed: {
    tabVisibles() {
      const isVisibleDetailList = this.pxcondInfo.reqDs === $getConstants('REQ_DS_PXCOND').code;
      if (!isVisibleDetailList) {
        this.onClickTab(0);
      }
      return {PxcondDetailInfo: isVisibleDetailList};
    },
  },
  watch: {
    status: {
      handler(v) {
        if (0 === v.tabIndex) {
          this.canNew = true;
          this.canSave = v.isEnable;
          this.canDelete = v.isEnable && !v.isNew;
        } else {
          this.canNew = false;
          this.canSave = false;
          this.canDelete = false;
        }
      },
      deep: true,
    },
  },
  beforeCreate() {
    $mapGetters(this, ['pxcondInfo', 'status']);
    $mapMutations(this, [
      'setOrderNm',
      'setMaxReqYm',
      'setMaxReqCnt',
      'setPxcondInfo',
      'setIsNew',
      'setTabIndex',
      'setPrepaymentInfo',
    ]);
    $mapActions(this, ['init', 'searchPxcondInfos']);
  },
  created() {
    this.addEvent([
      {name: 'PxcondDtlsRegist_search', func: this.onSearch},
      {name: 'PxcondDtlsRegist_moveTab', func: this.onClickTab},
    ]);
    this.addFuncPrj(this.onSearch);
  },
  methods: {
    onClickTab(index) {
      if (!this.pxcondInfo.reqYm && index === 1) {
        this.$alert({title: '기성년월', message: '기성년월을 먼저 선택해야 합니다.'});
        return;
      }
      this.setTabIndex(index);
      this.callEvent({name: 'setActiveTab', param: this.status.tabIndex});
    },
    async onSearch() {
      this.loading = true;

      this.setOrderNm(this.getPrjInfo().orderNm);
      this.setMaxReqYm('');
      this.setMaxReqCnt(0);
      this.setIsNew(false);

      try {
        const response = await selectPxcondList();
        this.loadSearchData = response.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    onSearchDetail(row) {
      if (row !== undefined) {
        this.setMaxReqCnt(row.maxReqCnt);
        this.setPxcondInfo({reqDs: row.reqDs});
      }

      this.searchPxcondInfos({reqCnt: row.reqCnt});
    },
    sheet_onSearchFinish(e) {
      if (0 === e.sheet.getTotalRowCount()) {
        this.init();
      } else {
        const maxReqYm = e.sheet
          .getDataRows()
          .map(row => row.reqYm)
          .reduce((acc, curr) => (acc < curr ? curr : acc), 0);
        this.setMaxReqYm(maxReqYm);

        this.onSearchDetail(e.sheet.getFirstRow());
      }
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data') {
        this.setIsNew(false);
        this.onSearchDetail(e.row);
      }
    },
  },
};
</script>

<style></style>
