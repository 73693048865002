<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text type="search" @update:value="setSearchText" width="200px" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <iui-container-new type="css-flex" flex-direction="row">
      <i-col>
        <i-row>
          <pmis-content-box>
            <template v-slot:title>현장정보</template>
            <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" @loadSheet="loadSheet" />
          </pmis-content-box>
        </i-row>
        <i-spacer v-if="isCopyOpt" />
        <i-row v-if="isCopyOpt" height="160px">
          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <pmis-content-box>
                  <template v-slot:title>복사옵션</template>
                  <iui-container-new type="table" theme="bullet" header-width="60px">
                    <i-row>
                      <i-col-header>재료비</i-col-header>
                      <i-col>
                        <iui-text
                          type="rate"
                          :value="matPriceRt"
                          @update:value="
                            value => {
                              matPriceRt = value;
                            }
                          "
                        ></iui-text>
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>노무비</i-col-header>
                      <i-col>
                        <iui-text
                          type="rate"
                          :value="labPriceRt"
                          @update:value="
                            value => {
                              labPriceRt = value;
                            }
                          "
                        ></iui-text>
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>장비비</i-col-header>
                      <i-col>
                        <iui-text
                          type="rate"
                          :value="equipPriceRt"
                          @update:value="
                            value => {
                              equipPriceRt = value;
                            }
                          "
                        ></iui-text>
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>경비</i-col-header>
                      <i-col>
                        <iui-text
                          type="rate"
                          :value="oprPriceRt"
                          @update:value="
                            value => {
                              oprPriceRt = value;
                            }
                          "
                        ></iui-text>
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </pmis-content-box>
              </i-col>
              <i-col width="280px">
                <pmis-content-box>
                  <template v-slot:title>절삭옵션</template>
                  <iui-container-new type="table" theme="bullet" header-width="100%">
                    <i-row>
                      <i-col-header>
                        <iui-text
                          type="number"
                          label="100원 이상 ~ 1,000원 미만"
                          suffix="자리 절삭"
                          label-width="160px"
                          width="260px"
                          :value="thousandDn"
                          @update:value="
                            value => {
                              thousandDn = value;
                            }
                          "
                          max-length="1"
                        ></iui-text>
                      </i-col-header>
                    </i-row>
                    <i-row>
                      <i-col-header>
                        <iui-text
                          type="number"
                          label="1,000원 이상 ~ 10,000원 미만"
                          suffix="자리 절삭"
                          label-width="160px"
                          width="260px"
                          :value="tenThousandDn"
                          @update:value="
                            value => {
                              tenThousandDn = value;
                            }
                          "
                          max-length="1"
                        ></iui-text>
                      </i-col-header>
                    </i-row>
                    <i-row>
                      <i-col-header>
                        <iui-text
                          type="number"
                          label="10,000원 이상"
                          suffix="자리 절삭"
                          label-width="160px"
                          width="260px"
                          :value.sync="tenThousandUp"
                          max-length="1"
                        ></iui-text>
                      </i-col-header>
                    </i-row>
                  </iui-container-new>
                </pmis-content-box>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-row>
      </i-col>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import options from '../sheetOption/OtherPrjCopyPopSheet.js';
import {selectOtherPjtContList} from '../../api/executDtls.js';
export default {
  props: {
    isCopyOpt: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      matPriceRt: 100,
      labPriceRt: 100,
      equipPriceRt: 100,
      oprPriceRt: 100,
      thousandDn: 0,
      tenThousandDn: 0,
      tenThousandUp: 0,
      searchText: '',
    };
  },

  created() {
    this.addEvent([{name: 'getFocusedRowByOtherPrjCopyPop', func: this.getFocusedRow}]);
    this.onSearch();
  },

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    async onSearch() {
      const response = await selectOtherPjtContList({searchText: this.searchText});
      response.data.forEach(obj => {
        Object.defineProperty(obj, 'Items', Object.getOwnPropertyDescriptor(obj, 'items'));
        delete obj['items'];
      });
      this.loadSearchData = response.data;
    },

    getFocusedRow(callback) {
      if (typeof callback == 'function') {
        let obj = {
          ...this.sheet.getFocusedRow(),
          matPriceRt: this.matPriceRt,
          labPriceRt: this.labPriceRt,
          equipPriceRt: this.equipPriceRt,
          oprPriceRt: this.oprPriceRt,
          thousandDn: this.posNumber(this.thousandDn),
          tenThousandDn: this.posNumber(this.tenThousandDn),
          tenThousandUp: this.posNumber(this.tenThousandUp),
        };

        callback(obj);
      }
    },
    posNumber(pos) {
      let value = '1';
      if (pos == 0) {
        return 0;
      }
      for (let i = 0; i < pos; i++) {
        value += '0';
      }
      return Number(value);
    },
    setSearchText(value) {
      this.searchText = value;
    },
  },
};
</script>

<style scoped>
.box_w {
  height: 230px !important;
}
.inlineCss {
  display: inline-block;
}
.search_box {
  margin: 0px 20px;
  border: none;
}
.popcon {
  padding: 0px 20px 20px 20px;
}
</style>
