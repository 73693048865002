/* 보유장비 등록 - 투입이력 */

<template>
  <iui-container-new type="css-flex">
    <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" @loadSheet="loadSheet" />
  </iui-container-new>
</template>

<script>
import options from './sheetOptions/InptHistSheet.js';
import {selectEqutCompanyMcodeList} from '../api/HoldEqpmnRegist.js';
export default {
  data() {
    return {
      loadSearchData: [],
      Options: options(this),
      elId: 'sheetDiv' + this._uid,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['detailInfo', 'oriDetailInfo']);
  },
  created() {
    this.addEvent({name: 'InputHist_onSearch', func: this.onSearch});
    this.addEvent({name: 'InputHist_Init', func: this.onInit});
  },
  methods: {
    async initSheet() {
      const OPT = {
        Cfg: {
          CanEdit: false,
        },
        Def: {
          Row: {
            CanFocus: false,
          },
        },
        Cols: [
          {
            Header: '현장코드',
            Name: 'prjCd',
            Type: 'Text',
            Width: 100,
            Align: 'center',
          },
          {
            Header: '현장명',
            Name: 'prjNm',
            Type: 'Text',
            RelWidth: 1,
            MinWidth: 150,
          },
          {
            Header: '투입일자',
            Name: 'rlseDate',
            Extend: this.$ibsheetHelper.preset.date,
          },
          {
            Header: '철수일자',
            Name: 'outDate',
            Extend: this.$ibsheetHelper.preset.date,
          },
        ],
      };

      this.sheet = await PMISSheet.createSheet({el: this.elId, options: OPT});
    },
    async onSearch() {
      let param = {
        pgmCd: this.detailInfo.pgmCd,
        equipCode: this.detailInfo.equipCode,
        carNo: this.detailInfo.carNo,
      };
      const response = await selectEqutCompanyMcodeList(param);
      this.loadSearchData = response.data;
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onInit() {
      this.sheet.removeAll();
    },
  },
};
</script>

<style scoped></style>
