export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 0},
    Cols: [
      {Header: '기성횟수', Name: 'reqCnt', Type: 'Text', Align: 'center', Width: 100},
      {
        Header: '기성년월',
        Name: 'reqYm',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        MinWidth: 120,
        CustomFormat: v => v.substr(0, 4) + '-' + v.substr(4, 2),
      },
      {
        Header: '기성구분',
        Name: 'reqDs',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        MinWidth: 120,
        Format: $getConstants('REQ_DS').code,
      },

      {Header: '기성금액', Name: 'wamt', RelWidth: 2, MinWidth: 140, Extend: preset.amount},
      {Header: '선급금잔액', Name: 'remainPsumAmt', RelWidth: 2, MinWidth: 140, Extend: preset.amount},
      {Header: '선급금정산금액', Name: 'psumAmt', RelWidth: 2, MinWidth: 140, Extend: preset.amount},
      {Header: '기성청구금액', Name: 'rsumAmt', RelWidth: 2, MinWidth: 140, Extend: preset.amount},

      // {Header: '공급가', Name: 'wamt', RelWidth: 2, MinWidth: 140, Extend: preset.amount},
      // {Header: '부가세액', Name: 'wvatAmt', RelWidth: 2, MinWidth: 140, Extend: preset.amount},
      // {Header: '비과세액', Name: 'wtaxexe', RelWidth: 2, MinWidth: 140, Extend: preset.amount},
      // {Header: '기성금액', Name: 'wsumAmt', RelWidth: 2, MinWidth: 140, Extend: preset.amount},

      {Name: 'ramt', Visible: false},
      {Name: 'rvatAmt', Visible: false},
      {Name: 'rtaxexe', Visible: false},
      {Name: 'maxReqCnt', Visible: false},
      {Name: 'isExistBillFlNo', Visible: false},
      {Name: 'isExistTaxexeFlNo', Visible: false},
    ],
  };
};
