<template>
  <pmis-tab-box>
    <template v-slot:title>상세정보</template>
    <iui-container-new type="table" theme="bullet" width="70%">
      <i-row>
        <i-col-header required>페이지ID</i-col-header>
        <i-col width="100px">
          <iui-text
            name="pageId"
            type="code"
            width="20px"
            max-length="1"
            :value="pageData.level == 1 ? '' : pageData.pageId"
            :enable="this.pageData.level == 2 && this.new"
            @input="updateStore"
          />
        </i-col>
        <i-col>
          <iui-text
            name="pageName"
            max-length="50"
            :value="pageData.pageName"
            :enable="this.pageData.level == 2"
            @input="updateStore"
          />
        </i-col>
      </i-row>
      <i-row v-if="pageData.level === 3">
        <i-col-header required>항목순번</i-col-header>
        <i-col width="100px">
          <iui-text
            name="seq"
            type="code"
            width="20px"
            max-length="1"
            :value="pageData.seq"
            @input="updateStore"
            :enable="this.new"
          />
        </i-col>
        <i-col>
          <iui-text name="webName" max-length="50" :value="pageData.webName" @input="updateStore" />
        </i-col>
      </i-row>
      <i-row v-if="pageData.level === 3">
        <i-col-header required>포틀릿사이즈</i-col-header>
        <i-col>
          <iui-select
            name="size"
            :p-code="$getConstants('PORTLET_SIZE').code"
            :value="pageData.size"
            @change="updateStore"
          />
        </i-col>
      </i-row>
      <i-row v-if="pageData.level === 3">
        <i-col-header required>페이지경로</i-col-header>
        <i-col colspan="2">
          <iui-text name="webLocation" max-length="50" :value="pageData.webLocation" @input="updateStore" />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-tab-box>
</template>

<script>
export default {
  beforeCreate() {
    $mapGetters(this, ['pageData', 'new']);
    $mapMutations(this, ['updatePageData']);
  },
  methods: {
    updateStore(e) {
      if (e) {
        this.updatePageData(e);
      }
    },
  },
};
</script>

<style></style>
