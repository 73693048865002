import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';

export default {
  data() {
    return {
      payCashItem1: [],
      payCashItem2: [],
      payCashItem3: [],

      stampTaxItems: [
        {label: '없음', value: BIDDING_CONSTANTS.CODES.STAMP_TAX_N},
        {label: '있음', value: BIDDING_CONSTANTS.CODES.STAMP_TAX_Y},
      ],

      delayRateItems: [
        {label: '없음', value: BIDDING_CONSTANTS.CODES.DELAY_RATE_N},
        {label: '있음', value: BIDDING_CONSTANTS.CODES.DELAY_RATE_Y},
      ],

      flawInsrItems1: [{label: '년', value: BIDDING_CONSTANTS.CODES.FLAW_INSR_YEAR}],
      flawInsrItems2: [{label: '월', value: BIDDING_CONSTANTS.CODES.FLAW_INSR_MONTH}],

      dlvPeriod: {
        frDt: {grpNm: 'dlvPeriod', seq: 1},
        toDt: {grpNm: 'dlvPeriod', seq: 2},
      },
    };
  },
  async created() {
    // 대금지급방법 코드
    const payCashCodes = await $getCode($getConstants('PAY_COND_RMRK_CD').code);
    this.payCashItem1.push({label: payCashCodes[0].codeNm, value: payCashCodes[0].code});
    this.payCashItem2.push({label: payCashCodes[1].codeNm, value: payCashCodes[1].code});
    this.payCashItem3.push({label: payCashCodes[2].codeNm, value: payCashCodes[2].code});
  },
  methods: {
    /**
     * 인지세 당사부담률 변경
     *
     * @param {*} e
     */
    onChange_stampTaxOwnRate(value) {
      if (100 < value) {
        this.$alert({title: '인지세', message: '인지세율이 100보다 클 수 없습니다.'});
        value = '';
      }
      this.setPoInfo({stampTaxOwnRate: value});
    },
    /**
     * 과세유형 변경
     *
     * @param {*} type
     * @param {*} value
     * @returns
     */
    onChange_taxType(type, value) {
      const BIDDING_CODES = BIDDING_CONSTANTS.CODES;
      const payloads = {
        taxTypecd: {
          taxDirectDs: value === BIDDING_CODES.TAX_DIRECT ? BIDDING_CODES.TAX_DIRECT_PERCENT : '',
          taxTypeDirectPercent: '',
          taxTypeDirectAmt: '',
        },
        taxTypeDirectAmt: {
          taxTypecd: BIDDING_CODES.TAX_DIRECT,
          taxDirectDs: BIDDING_CODES.TAX_DIRECT_AMOUNT,
        },
      };

      if (type === 'taxTypeDirectPercent' && 100 < value) {
        this.$alert({title: '과세율', message: '과세율이 100%를 초과할 수 없습니다.'});
        this.setPoInfo({taxTypeDirectPercent: ''});
        return;
      }
      if (type === 'taxTypeDirectAmt' && this.poInfo.poAmt < value) {
        this.$alert({title: '부가세', message: '부가세가 공급가액을 초과할 수 없습니다.'});
        this.setPoInfo({taxTypeDirectAmt: ''});
        return;
      }

      this.setPoInfo({[type]: value, ...payloads[type]});
      this.callEvent({name: 'PoItemList_CalculateAmt'});
    },
    /**
     * 계약이행, 하자보수 보증금액 계산
     *
     * @param {*} type
     * @param {*} rate
     */
    onChange_insrRate(type, rate) {
      const amt = rate ? (this.poInfo.totAmt * rate) / 100 : '';
      this.setPoInfo({[`${type}Rate`]: rate, [`${type}Amt`]: amt});
    },
    /**
     * 대금지급방법 변경
     *
     * @param {*} value
     */
    onChange_payCashYn(value) {
      this.setPoInfo({
        payCashYn: value,
        payTerm: '',
        payCashRatio: '',
        payCheckRatio: '',
        payCheck2Ratio: '',
        payCashRmrk: '',
        payDefer: '',
      });
    },
    /**
     * 대금지급방법(현금, 어음) 비율 계산
     *
     * @param {*} key
     * @param {*} value
     */
    onChange_payRatio(key, value) {
      const payload = {[key]: value};

      switch (key) {
        case 'payCashRatio':
          payload['payCheckRatio'] = 100 - value;
          payload['payCheck2Ratio'] = 0;
          break;
        case 'payCheckRatio':
          payload['payCheck2Ratio'] = 100 - this.poInfo.payCashRatio - value;
          break;
        case 'payCheck2Ratio':
          payload['payCheckRatio'] = 100 - this.poInfo.payCashRatio - value;
          break;
      }
      this.setPoInfo(payload);
    },
    onChange_stampTaxYn(e) {
      const stampTaxYn = $_externalCheckboxValue(e.target.checked);
      const payload = {stampTaxYn};
      if (stampTaxYn === BIDDING_CONSTANTS.CODES.STAMP_TAX_N) {
        payload['stampTaxAmt'] = '';
        payload['stampTaxOwnRate'] = '';
      }
      if (stampTaxYn === BIDDING_CONSTANTS.CODES.STAMP_TAX_Y) {
        payload['stampTaxAmt'] = COMMON_FUNCTION.onCalculateStampTaxAmt(this.poInfo.totAmt).stampTaxAmt;
        payload['stampTaxOwnRate'] = 0;
      }
      this.setPoInfo(payload);
    },
  },
};
