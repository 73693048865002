<template>
  <iui-container-new type="css-flex">
    <i-row height="380px">
      <i-col>
        <pmis-content-box>
          <iui-container-new type="table" theme="bullet">
            <colgroup>
              <col width="110px" />
              <col />
              <col width="10px" />
              <col width="110px" />
              <col />
            </colgroup>
            <i-row>
              <i-col-header>거래처유형</i-col-header>
              <i-col>
                <iui-text :value="frmDsNm[custInfo.frmDs]" width="130px" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>사업자번호</i-col-header>
              <i-col>
                <iui-bsn-no :value1="bsnNo1" :value2="bsnNo2" :value3="bsnNo3" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>주민/법인번호</i-col-header>
              <i-col>
                <iui-regi-no :value1="regiNo1" :value2="regiNo2" size="short" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>회사명</i-col-header>
              <i-col>
                <iui-text :value="custInfo.name" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>회사명(영문)</i-col-header>
              <i-col>
                <iui-text :value="custInfo.enName" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>대표자명</i-col-header>
              <i-col>
                <iui-text :value="custInfo.rpsvNm" :enable="false" width="90px" />
                <iui-text :value="custInfo.rpsvEnNm" :enable="false" />
                <iui-button value="공동대표" @click="onClickCeo" v-if="custInfo.ceoCount > 0" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>대표전화</i-col-header>
              <i-col>
                <iui-text :value="custInfo.rpsvPhone" :enable="false" width="90px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>주소</i-col-header>
              <i-col>
                <iui-text :value="custInfo.postNo" :enable="false" width="90px" />
                <iui-text :value="custInfo.adr1" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col></i-col>
              <i-col>
                <iui-text :value="custInfo.adr2" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>홈페이지</i-col-header>
              <i-col>
                <iui-text :value="custInfo.homepage" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>업태</i-col-header>
              <i-col>
                <iui-text max-length="33" :value="custInfo.bcoNm" :enable="false" width="200px" />
              </i-col>
              <i-spacer />
              <i-col-header>종목</i-col-header>
              <i-col>
                <iui-text max-length="33" :value="custInfo.itemNm" :enable="false" width="200px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>계좌정보</i-col-header>
              <i-col>
                <iui-text
                  :p-code="$getConstants('BANK_CODE').code"
                  :value="custInfo.bankName"
                  :enable="false"
                  width="90px"
                />
                <iui-text :value="custInfo.depositNo" :enable="false" />
              </i-col>
              <i-spacer />
              <i-col-header>예금주</i-col-header>
              <i-col>
                <iui-text :value="custInfo.depositor" :enable="false" width="200px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>국가명</i-col-header>
              <i-col>
                <iui-text :value="nationNm[custInfo.nationCd]" :enable="false" width="250px" />
              </i-col>
              <i-spacer />
              <i-col-header>거래종료구분</i-col-header>
              <i-col>
                <iui-checkbox-group
                  :p-code="$getConstants('USE_CLOSE_DS').code"
                  :value="custInfo.useCloseDs"
                  :enable="false"
                />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row height="150px">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>담당자 정보</template>
          <iui-container-new type="table" theme="bullet" headerWidth="110px">
            <i-row>
              <i-col-header>담당자명</i-col-header>
              <i-col>
                <iui-text :value="custInfo.icprNm" :enable="false" width="90px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>담당자 전화번호</i-col-header>
              <i-col>
                <iui-text type="phone" :value="custInfo.icprPhone" :enable="false" width="90px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>담당자 이메일</i-col-header>
              <i-col>
                <iui-text type="email1" :value="custInfo.email1" :enable="false" width="90px" />
                <div class="mr5">@</div>
                <iui-text type="email2" :value="custInfo.email2" :enable="false" width="100px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>담당자 팩스번호</i-col-header>
              <i-col>
                <iui-text type="phone" :value="custInfo.icprFaxno" :enable="false" width="90px" />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>

    <iui-modal name="ceoModal" title="공동대표" width="600px" height="400px">
      <CeoList :bsnNo="custInfo.bsnNo" />
    </iui-modal>
  </iui-container-new>
</template>

<script>
/**
 * 계약정보등록(tab2) > 조직도 > 조직정보(발주처, 원도급사, 외주)
 *
 * */
import CeoList from './CeoList.vue';

export default {
  data() {
    return {
      frmDsNm: {},
      nationNm: {},
    };
  },
  beforeCreate() {
    $mapGetters(this, ['custInfo']);
  },

  created() {
    $getCode($getConstants('FRM_DS').code).then(response => {
      const obj = {};
      response.forEach(item => (obj[item.code] = item.codeNm));
      this.frmDsNm = obj;
    });
    $getCode($getConstants('NATION_CD').code).then(response => {
      const obj = {};
      response.forEach(item => (obj[item.code] = item.codeNm));
      this.nationNm = obj;
    });
  },
  components: {
    CeoList,
  },
  methods: {
    onClickCeo() {
      this.$modal.show('ceoModal');
    },
  },
  computed: {
    bsnNo1() {
      return String(this.custInfo.bsnNo).substr(0, 3);
    },
    bsnNo2() {
      return String(this.custInfo.bsnNo).substr(3, 2);
    },
    bsnNo3() {
      return String(this.custInfo.bsnNo).substr(5);
    },
    regiNo1() {
      return String(this.custInfo.regiNo).substr(0, 6);
    },
    regiNo2() {
      return String(this.custInfo.regiNo).substr(6);
    },
  },
};
</script>

<style></style>
