<template>
  <span class="regi-box">
    <iui-text
      name="regiNo1"
      :width="size == 'short' ? '70px' : '130px'"
      type="number"
      align="center"
      max-length="6"
      :numberFormat="false"
      :enable="enable"
      :value.sync="internalValue1"
      @change="onChange1"
      @keyup="onKeyup1"
      :required="required"
      :error-message="{title: '주민번호', message: '주민번호 앞자리를 입력해주세요.'}"
    />
    <div>-</div>
    <iui-text
      ref="regiNo2"
      name="regiNo2"
      :width="size == 'short' ? '70px' : '130px'"
      type="number"
      align="center"
      max-length="7"
      :numberFormat="false"
      :enable="enable"
      :value.sync="internalValue2"
      @change="onChange2"
      :required="required"
      :error-message="{title: '주민번호', message: '주민번호 뒷자리를 입력해주세요.'}"
    />
  </span>
</template>

<script>
export default {
  props: {
    value1: {
      type: [String, Number],
    },
    value2: {
      type: [String, Number],
    },
    size: {
      type: String,
      default: 'long',
    },
    enable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: [Boolean, String, Number],
    },
  },
  data() {
    return {
      internalValue1: this.value1,
      internalValue2: this.value2,
    };
  },
  watch: {
    value1(v) {
      this.internalValue1 = v;
    },
    value2(v) {
      this.internalValue2 = v;
    },
    internalValue1(newValue) {
      this.$emit('update:value1', newValue);
    },
    internalValue2(newValue) {
      this.$emit('update:value2', newValue);
    },
  },
  methods: {
    onKeyup1(e) {
      if (e.target.value.length === 6) {
        this.$refs.regiNo2.$refs.input.focus();
      }
    },
    onChange1(e) {
      this.$emit('change1', e);
    },
    onChange2(e) {
      this.$emit('change2', e);
    },
  },
};
</script>

<style scoped>
.regi-box {
  display: flex;
  align-items: center;
}
.regi-box > * {
  margin-right: 5px;
}
</style>
