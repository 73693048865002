<template>
  <iui-container-new type="ibsheet" :id="elId" />
</template>

<script>
export default {
  props: {
    uid: {
      type: Number,
    },
    byPrjcd: {
      // 현장코드 기준 검색
      type: Boolean,
      default: false,
    },
    byPgmcd: {
      // 회사코드 기준 검색
      type: Boolean,
      default: false,
    },
    byCuscd: {
      // 거래처 기준 검색
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.addEvent({name: 'PoCompleteList_Search', func: this.onSearch});
    this.addEvent({name: 'PoCompleteList_Select', func: this.onSelect});
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
    };
  },
  async mounted() {
    let preset = this.$ibsheetHelper.preset;
    const OPT = {
      Cfg: {
        SearchMode: 2,
        CanEdit: 1,
        CanSort: 0,
      },
      Def: {
        Row: {NoColor: 1, CanFormula: 1},
      },
      Cols: [
        {
          Header: '선택',
          Name: 'cbx',
          Type: 'Bool',
          BoolIcon: 0,
          Align: 'center',
          Width: 55,
          CanEdit: true,
          Visible: this.byCuscd,
        },
        {
          Header: '현장코드',
          Name: 'spotDeptcd',
          Type: 'Text',
          Align: 'Center',
          Width: 100,
          CanEdit: false,
          Visible: this.byPgmcd || this.byCuscd,
        },
        {
          Header: '현장명',
          Name: 'spotDeptnm',
          Type: 'Text',
          RelWidth: 1,
          MinWidth: 180,
          CanEdit: false,
          Visible: this.byPgmcd || this.byCuscd,
        },
        {
          Header: this.byPrjcd ? '계약번호' : '외주 계약코드',
          Name: 'poNo',
          Type: 'Text',
          Align: 'Center',
          Width: 140,
          CustomFormat: v => `${v.substr(8, 8)}-${v.substr(16, 4)}`,
          CanEdit: false,
        },
        {
          Header: '계약명',
          Name: 'ttl',
          Type: 'Text',
          RelWidth: 1,
          MinWidth: 180,
          CanEdit: false,
          Visible: this.byPrjcd || this.byCuscd,
        },
        {
          Header: this.byPrjcd ? '거래처명' : '외주명',
          Name: 'cusNm',
          Type: 'Text',
          RelWidth: 1,
          MinWidth: 180,
          CanEdit: false,
        },
        {
          Header: '계약일자',
          Name: 'poDt',
          Type: 'Text',
          Align: 'Center',
          Width: 90,
          CanEdit: false,
          Visible: this.byPrjcd,
        },
        {
          Header: '공사기간',
          Name: 'contPeriod',
          Type: 'Text',
          Align: 'Center',
          Width: 170,
          Formula: fr => fr.Row['contFrDt'] + ' ~ ' + fr.Row['contToDt'],
          CanEdit: false,
          Visible: this.byPrjcd,
        },
        {
          Header: '공급가',
          Name: 'poAmt',
          Width: 120,
          Extend: preset.amount,
          CanEdit: false,
          Visible: this.byPrjcd,
        },
        {
          Header: '부가세',
          Name: 'vatAmt',
          Width: 120,
          Extend: preset.amount,
          CanEdit: false,
          Visible: this.byPrjcd,
        },
        {
          Header: '면세',
          Name: 'taxTypenm',
          Type: 'Text',
          Align: 'Right',
          Width: 100,
          Formula: fr =>
            fr.Row['taxTypecd'] == $getConstants('TAX_TYPE_FREE').code ? '비과세' : `과세 ${fr.Row['taxRate']}%`,
          CanEdit: false,
          Visible: this.byPrjcd,
        },
        {
          Header: '합계금액',
          Name: 'totAmt',
          Width: 120,
          Extend: preset.amount,
          CanEdit: false,
          Visible: this.byPrjcd,
        },
        {Name: 'contFrDt', Visible: false},
        {Name: 'contToDt', Visible: false},
        {Name: 'taxTypecd', Visible: false},
        {Name: 'taxRate', Visible: false},
        {Name: 'cusCd', Visible: false},
      ],
    };

    this.sheet = await PMISSheet.createSheet({el: this.elId, options: OPT});

    this.sheet.bind('onDblClick', e => {
      if (e.row.Kind == 'Data') {
        this.callEvent({name: `workGroupSearchOnConfirm${this.uid}`});
      }
    });

    this.onSearch();
  },
  methods: {
    async onSearch(searchModalParam) {
      let param = {orgCd: this.pgmCd, ...searchModalParam};
      if (this.byPrjcd) {
        let spotDeptcd = this.$store.getters['modalParam'] ? this.$store.getters['modalParam'].prjCd : '';
        param = {...param, spotDeptcd};
      } else if (this.byCuscd) {
        let cusCd = this.$store.getters['modalParam'] ? this.$store.getters['modalParam'].cusCd : '';
        param = {...param, cusCd};
      }
      let response = await axios.post('/subPo/selectSubPoCompleteList', param);
      this.sheet.loadSearchData(response.data);
    },
    onSelect(callback) {
      if (this.byPrjcd) {
        let row = this.sheet.getFocusedRow();
        if (row == null) {
          this.$alert('선택된 계약내역이 존재하지 않습니다.');
          return;
        } else {
          callback({
            poNo: row.poNo,
            ttl: row.ttl,
            cusCd: row.cusCd,
            cusNm: row.cusNm,
            amt: row.poAmt,
            vatAmt: row.vatAmt,
            totAmt: row.totAmt,
          });
        }
      } else if (this.byPgmcd) {
        var rows = this.sheet.getRowsByChecked('cbx');
        if (0 == rows.length) {
          this.$alert('외주 계약내역을 선택해야 합니다.');
          return;
        }

        if (1 < rows.length) {
          this.$alert('하나의 외주 계약내역만 선택 가능합니다.');
          return;
        }
        let callbackObj = {
          prjCd: rows[0].spotDeptcd,
          prjNm: rows[0].spotDeptnm,
          cusCd: rows[0].cusCd,
          cusNm: rows[0].cusNm,
        };
        callback(callbackObj);
      } else {
        let row = this.sheet.getFocusedRow();
        if (row == null) {
          this.$alert('선택된 계약내역이 존재하지 않습니다.');
          return;
        } else {
          callback({
            poNo: row.poNo,
            ttl: row.ttl,
            spotDeptcd: row.spotDeptcd,
            spotDeptnm: row.spotDeptnm,
            cusCd: row.cusCd,
          });
        }
      }
    },
  },
};
</script>

<style></style>
