<template>
  <pmis-page ref="page" :search-project-visible="false" @search-click="onSearch">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px"><PrjList /></i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>투입목록</template>

            <pmis-content-box>
              <template v-slot:header-left>
                <label>투입기간</label>
                <iui-datepicker
                  name="workDtFrom"
                  :group="{grpNm: 'workDt', seq: 1}"
                  :value="searchInfo.workDtFrom"
                  :bindArray="['setSearchInfo']"
                  @change="onSearch"
                />
                <span>~</span>
                <iui-datepicker
                  name="workDtTo"
                  :group="{grpNm: 'workDt', seq: 2}"
                  :value="searchInfo.workDtTo"
                  :bindArray="['setSearchInfo']"
                  @change="onSearch"
                />
                <div class="ml15"></div>
                <iui-text
                  type="search"
                  label="외주"
                  name="searchNm"
                  :value="searchInfo.searchNm"
                  :bindArray="['setSearchInfo']"
                  @enter="onSearch"
                />
                <iui-button value="검색" @click="onSearch" />
                <div class="ml15"></div>
                <iui-checkbox-group
                  name="cbxIncludeConCmplCtrtYN"
                  :items="cbxIncludeConCmplCtrtYNItems"
                  :value="searchInfo.includeCmplCtrtYN"
                  @change="
                    event => {
                      searchInfo.includeCmplCtrtYN = event.target.checked ? event.target.value : '';
                      onSearch();
                    }
                  "
                />
              </template>
              <ib-sheet
                :uid="_uid"
                :options="sheetOpt"
                :loadSearchData="loadSearchData"
                @loadSheet="sheet_loadSheet"
                :events="{onClick: grid_onClick, onDataLoad: sheet_onDataLoad}"
              />
            </pmis-content-box>
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
/**
 * 투입정보조회(외주)
 *
 * */
import store from './store/index.js';
import PrjList from '@/view/Project/components/PrjList.vue';
import sheetOpt from './components/sheetOption/InputView.js';
import {selectList} from './api/InputView.js';

export default {
  components: {PrjList},
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],

      cbxIncludeConCmplCtrtYNItems: [{label: '만료계약포함', value: 'Y'}],
      bindArray: ['setSearchInfo'],
      grdSort: 'desc',
    };
  },
  beforeCreate() {
    $init(this, store);

    $mapGetters(this, {
      searchInfo: 'searchInfo',
    });

    $mapActions(this, ['setSearchInfo']);
  },
  created() {
    this.addFuncPrj(this.onPrjChange);
  },
  methods: {
    sheet_loadSheet(sheet) {
      this.sheet = sheet;
    },
    sheet_onDataLoad() {
      if (0 < this.sheet.getTotalRowCount()) {
        this.sheet.makeSubTotal([{stdCol: 'workDt', sumCols: 'amt', captionCol: [{col: 'workDt', val: '소계'}]}]);
      }
    },
    onPrjChange() {
      this.onInitSearchDate();
      this.onSearch();
    },
    async onSearch() {
      const response = await selectList({...this.searchInfo});

      let data = Array.from(response.data);

      this.loadSearchData = data;
    },
    onInitSearchDate() {
      const currDate = $_getCurrentDate();
      const frDate = new Date(currDate.slice(0, 4), currDate.slice(4, 6) - 1, currDate.slice(6, 8));
      const toDate = new Date(currDate.slice(0, 4), currDate.slice(4, 6) - 1, currDate.slice(6, 8));
      frDate.setMonth(frDate.getMonth() - 1);

      this.setSearchInfo({workDtFrom: $_dateToStringYYMMDD(frDate), workDtTo: $_dateToStringYYMMDD(toDate)});
    },
    grid_onClick(e) {
      if (e.kind == 'Header' && e.col == 'workDt') {
        // 헤더(날짜) 클릭 시 asc/desc 정렬(임시)
        // makeSubTotal 사용 시에 기존 CanSort 사용안됨.
        this.grdSort = this.grdSort === 'asc' ? 'desc' : 'asc';
        this.sheet.makeSubTotal([{stdCol: 'workDt', sumCols: 'amt', sort: this.grdSort}]);
        this.sheet.focus(this.sheet.getFirstRow());
      }
    },
  },
};
</script>

<style scoped></style>
