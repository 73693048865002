<template>
  <iui-container-new type="css-flex">
    <i-row height="100px">
      <i-col>
        <iui-content-box>
          <template #title>계약서 수정요청</template>
          <div>
            º 계약서를 원청사에 수정요청합니다. (계약번호 : {{ poInfo.poNo2 }})
            <br />
            º 수정 요청된 계약서는 원청사 담당자가 확인 후 처리됩니다.
            <br />
            º 수정 요청된 계약정보는 목록에서 제외됩니다.
          </div>
        </iui-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <iui-content-box>
          <template #title>수정요청 사유</template>
          <iui-text
            ref="reason"
            type="multi"
            :value.sync="reason"
            required
            :error-message="{title: '수정요청 사유', message: '수정요청 사유를 입력해야합니다.'}"
          />
        </iui-content-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import {rejectSupplier as rejectMat} from '@/view/bidding/po/mat/api/po.js';
import {rejectSupplier as rejectSub} from '@/view/bidding/po/sub/api/subPo.js';
export default {
  data() {
    return {reason: ''};
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'bidType']);
  },
  created() {
    this.addEvent({name: 'PoRejectSupplier_Reject', func: this.onReject});
  },
  mounted() {
    this.$refs.reason.$refs.textarea.focus();
  },
  methods: {
    async onReject(callback) {
      this.reason = this.reason.trim();

      const comfirmMsg = {title: '계약서 수정요청', message: '계약서를 수정 요청하시겠습니까?'};
      if (!(await this.$confirm(comfirmMsg))) {
        return;
      }

      const saveApi = {mat: rejectMat, sub: rejectSub};
      saveApi[this.bidType](this.poInfo.poNo, this.poInfo.contSeq, this.reason)
        .then(response => {
          if (response.status === 200) {
            callback(response.data.poNo);
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
  },
};
</script>

<style></style>
