export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 1},
    Def: {Row: {NoColor: 2, CanFocus: 0}},
    Cols: [
      {
        Header: '자재코드',
        Name: 'itemCd',
        Type: 'Text',
        Align: 'center',
        Width: 160,
        CanEdit: 0,
        TextStyle: 5,
        TextColor: '#FF0000',
        Cursor: 'Pointer',
        Visible: 0,
      },
      {
        Header: '자재명칭',
        Name: 'itemNm',
        Type: 'Text',
        TextStyle: 5,
        RelWidth: 1,
        MinWidth: 180,
        Cursor: 'Pointer',
        CanEdit: 0,
      },
      {Header: '규격', Name: 'sizeNm', Type: 'Text', RelWidth: 1, MinWidth: 180, CanEdit: 0},
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        Width: 80,
        CanEdit: 0,
        Format: $getConstants('UNIT').code,
      },
      {Header: '수량', Name: 'qty', Width: 100, Extend: preset.quantity, CanEdit: 0},
      {Header: '단가', Name: 'planUnitPrc', Width: 130, Extend: preset.unitAmount, CanEdit: 0},
      {
        Header: '금액',
        Name: 'amt',
        Width: 130,
        Extend: preset.amount,
        CanEdit: 0,
        Formula: fr => fr.Row['qty'] * fr.Row['planUnitPrc'] || 0,
      },
      {Header: '비고', Name: 'itemRmrk', Type: 'Text', Width: 180, CanEdit: 0},

      {Name: 'prNo', Visible: 0},
      {Name: 'prSeq', Visible: 0},
      {Name: 'planQty', Visible: 0},
    ],
  };
};
