<template>
  <pmis-content-box>
    <iui-container-new type="table" theme="bullet">
      <colgroup>
        <col style="width:100px" />
        <col />
        <col style="width:100px" />
        <col />
      </colgroup>
      <i-row>
        <i-col-header>노무팀코드</i-col-header>
        <i-col>
          <iui-text name="labCode" :value="lbrcTeamInfo.labCode" :bindArray="bindArray" :enable="false" width="90px" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>노무팀명</i-col-header>
        <i-col>
          <iui-text
            name="labName"
            :value="lbrcTeamInfo.name"
            :bindArray="bindArray"
            width="200px"
            max-length="33"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>사업자번호</i-col-header>
        <i-col>
          <iui-text
            name="bsnNo1"
            :value="lbrcTeamInfo.bsnNo1"
            :bindArray="bindArray"
            width="40px"
            max-length="3"
            :enable="enable"
          />

          <div class="mr5">-</div>

          <iui-text
            name="bsnNo2"
            :value="lbrcTeamInfo.bsnNo2"
            :bindArray="bindArray"
            width="40px"
            max-length="2"
            :enable="enable"
          />

          <div class="mr5">-</div>

          <iui-text
            name="bsnNo3"
            :value="lbrcTeamInfo.bsnNo3"
            :bindArray="bindArray"
            width="90px"
            max-length="5"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>팀장명</i-col-header>
        <i-col>
          <iui-text
            type="text"
            name="labMNm"
            :value="lbrcTeamInfo.labMNm"
            :bindArray="bindArray"
            width="200px"
            max-length="16"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>팀장주민번호</i-col-header>
        <i-col>
          <iui-regi-no
            :value1="lbrcTeamInfo.labMRegiNo1"
            :value2="lbrcTeamInfo.labMRegiNo2"
            :enable="enable"
            :size="'short'"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>전화번호</i-col-header>
        <i-col>
          <iui-text
            type="phone"
            name="labMPhone"
            :value="lbrcTeamInfo.labMPhone"
            :bindArray="bindArray"
            :valid="'phone'"
            width="120px"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>이메일</i-col-header>
        <i-col colspan="3">
          <iui-text
            name="email1"
            :value="lbrcTeamInfo.email1"
            :bindArray="bindArray"
            width="120px"
            max-length="50"
            :enable="enable"
          />
          <div class="mr5">@</div>
          <iui-text type="email2" :value="lbrcTeamInfo.email2" :enable="false" width="120px" />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
/**
 * 노무팀 정보 :
 * 사용 : 노무팀등록 > 기본정보
 *        계약정보등록(tab2) > 조직도 > 노무팀정보
 * */
export default {
  props: {
    pageType: {
      type: String,
      default: 'regist',
    },
  },
  data: function() {
    return {
      bindArray: ['setLbrcTeamInfo'],
      email3: '',
    };
  },

  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'lbrcTeamInfo']);
    $mapMutations(this, ['setLbrcTeamInfo', 'initLbrcTeamInfo']);
  },

  created() {
    this.addEvent([{name: 'basicInfo_onSave', func: this.onSave}]);
    this.addEvent([{name: 'basicInfo_focusTeamList', func: this.focusTeamList}]);
    this.addEvent([{name: 'basicInfo_onDelete', func: this.onDelete}]);
  },

  methods: {
    setEmail2() {
      this.setLbrcTeamInfo({email2: this.email3});
    },
    setEmail3() {
      if (this.lbrcTeamInfo.email2.slice(0, 3) != '000') {
        //email2의 값이 코드가 아닐 시(도메인이면)
        this.email3 = this.lbrcTeamInfo.email2; //email3에 email2의 값을 입력
        this.setLbrcTeamInfo({email2: $getConstants('EMAIL_CD_DIRECT').code}); //email2는 '직접입력'으로 변경
      } else this.email3 = ''; //email2 값이 코드이면 email3 초기화
    },
    focusTeamList: function() {
      this.setEmail3(); //email3 세팅
    },
  },
  computed: {
    enable: function() {
      return this.pageType != 'orgCht';
    },
  },
};
</script>

<style></style>
