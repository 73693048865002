export const selectInputCount = param => axios.post('/executDtls/selectInputCount', param);
export const selectContContexecPlanOfDegreeList = param =>
  axios.post('/executDtls/selectContContexecPlanOfDegreeList', param);
export const selectContExecPlanListAndInfo = param => axios.post('/executDtls/selectContExecPlanListAndInfo', param);
export const selectContExecPlanList = param => axios.post('/executDtls/selectContExecPlanList', param);
export const dtlsDcns = param => axios.post('/executDtls/dtlsDcns', param);
export const dcnsCancl = param => axios.post('/executDtls/dcnsCancl', param);
export const insertContContexecPlan = param => axios.post('/executDtls/insertContContexecPlan', param);
export const deleteContContexecPlan = param => axios.post('/executDtls/deleteContContexecPlan', param);
export const selectOtherPjtContList = param => axios.post('/executDtls/selectOtherPjtContList', param);
export const copyOtherPjtCont = param => axios.post('/executDtls/copyOtherPjtCont', param);
export const otherCopyContEmPlanD = param => axios.post('/executDtls/otherCopyContEmPlanD', param);
export const updateContExecPlanByUcstApply = param => axios.post('/executDtls/updateContExecPlanByUcstApply', param);
export const updateContExecPlanByUcstMdac = param => axios.post('/executDtls/updateContExecPlanByUcstMdac', param);
export const saveContContPlan = param => axios.post('/executDtls/saveContContPlan', param);
export const deleteContExecPlanValidList = param => axios.post('/executDtls/deleteContExecPlanValidList', param);
export const deleteContExecPlanList = param => axios.post('/executDtls/deleteContExecPlanList', param);
export const selectNextItemSeq = param => axios.post('/executDtls/selectNextItemSeq', param);
export const selectWcodeBYExe = param => axios.post('/executDtls/selectWcodeBYExe', param);
export const selectClWorkBYExe = param => axios.post('/executDtls/selectClWorkBYExe', param);
export const saveContractCopy = param => axios.post('/executDtls/saveContractCopy', param);
