<template>
  <div>
    <div align="right">
      <span>HOME</span>
      <span>|</span>
      <span @click="goLogin">로그인</span>
      <span>|</span>
      <span>회원가입</span>
    </div>
    <component v-bind:is="currentView"></component>
  </div>
</template>

<script>
import TwoMenu from './TwoMenu';
import Notice from './notice';
import FAQ from './faq';
export default {
  props: {
    menuId: {
      type: [String, Number],
      default: '1',
    },
  },
  data() {
    return {
      currentView: null,
    };
  },
  watch: {
    menuId() {
      this.selectMenu();
    },
  },
  created() {},
  mounted() {
    this.selectMenu();
  },
  methods: {
    selectMenu() {
      switch (String(this.menuId)) {
        case 'Notice':
          this.currentView = Notice;
          break;
        case 'FAQ':
          this.currentView = FAQ;
          break;
        case '2':
          this.currentView = TwoMenu;

          break;
      }
    },
    goLogin() {
      location.href = '/main';
    },
  },
};
</script>

<style></style>
