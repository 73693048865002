<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col>
        <pmis-content-box>
          <template #title>세금계산서</template>
          <template #title-right>
            <template v-if="param.enable">
              <template v-if="isReady">
                <iui-button value="생성" @click="onCreate" />
              </template>
              <template v-if="isCreated">
                <iui-button value="생성취소" @click="onCancel" />
                <iui-button value="전자서명" @click="onSign" />
              </template>
              <template v-if="isSigned">
                <iui-button value="인쇄" @click="onPrint" />
                <iui-button value="다운로드" @click="onDownload" />
              </template>
            </template>
          </template>
          <iui-container-new type="table" theme="bullet">
            <i-row>
              <i-col>
                <div v-html="billHtml" class="taxInvoiceXml" />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>

    <iui-html-2-pdf
      print
      :width="width"
      :height="height"
      :generate.sync="isPrint"
      :pdfFileNm="billNo"
      @generate-complete="onGenerateComplete"
    >
      <div v-html="billHtml" class="taxInvoiceXml" />
    </iui-html-2-pdf>

    <iui-html-2-pdf
      download
      :width="width"
      :height="height"
      :generate.sync="isDownload"
      :pdfFileNm="billNo"
      @generate-complete="onGenerateComplete"
    >
      <div v-html="billHtml" class="taxInvoiceXml" />
    </iui-html-2-pdf>
  </iui-container-new>
</template>

<script>
require('@/view/bidding/components/css/taxInvoice_v3.scss');

import {SECUKIT_SERVICE_TYPE} from '@inogard-dev/vue-secukitnx-helper';
import {selectTaxInvoiceInfos} from '@/view/pxcondRqestManage/pxcondDtlsRegist/api/pxcondDtlsRegist.js';
import {getServerKmCertPerm} from '@/view/bidding/rfq/common/api/pkiToolkit.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';

export default {
  created() {
    this.param.pgmCd = this.$store.getters['modalParam'].pgmCd;
    this.param.prjCd = this.$store.getters['modalParam'].prjCd;
    this.param.reqCnt = this.$store.getters['modalParam'].reqCnt;
    this.param.orderCode = this.$store.getters['modalParam'].orderCode;
    this.param.taxType = this.$store.getters['modalParam'].taxType;
    this.param.enable = this.$store.getters['modalParam'].enable;

    this.onSearch();
  },
  data() {
    return {
      param: {
        pgmCd: '',
        prjCd: '',
        reqCnt: '',
        orderCode: '',
        taxType: '',
        enable: false,
      },

      billNo: '',
      billXml: '',
      billHtml: '',
      billState: '',
      nts: {
        transferMethod: '',
        transferMethodNm: '',
        regDate: '',
        submitId: '',
        statusCode: '',
        statusCodeNm: '',
        resultStatusCode: '',
        resultStatusMsg: '',
        receiptId: '',
      },
      isPrint: false,
      isDownload: false,
      width: '665px',
      height: '850px',

      svrKmCertPerm: '', // 서버 인증서 문자열
    };
  },
  computed: {
    isReady() {
      return this.billState == $getConstants('TAX_READY').code;
    },
    isCreated() {
      return this.billState == $getConstants('TAX_CREATED').code;
    },
    isSigned() {
      return this.billState == $getConstants('TAX_SIGNED').code;
    },
  },
  methods: {
    onSearch() {
      selectTaxInvoiceInfos(this.param).then(response => {
        this.billNo = response.data.billNo;
        this.billXml = response.data.billXml;
        this.billHtml = response.data.billHtml;
        this.billState = response.data.billState;

        if (this.isCreated) {
          this.onSearchServerKmCertPerm();
        }
        if (this.isSigned) {
          const ntsInfo = response.data.ntsInfo;
          this.nts.transferMethod = ntsInfo.transferMethod;
          this.nts.transferMethodNm = ntsInfo.transferMethodNm;
          this.nts.regDate = ntsInfo.regDate;
          this.nts.submitId = ntsInfo.submitId;
          this.nts.statusCode = ntsInfo.statusCode;
          this.nts.statusCodeNm = ntsInfo.statusCodeNm;
          this.nts.resultStatusCode = ntsInfo.resultStatusCode;
          this.nts.resultStatusMsg = ntsInfo.resultStatusMsg;
          this.nts.receiptId = ntsInfo.receiptId;
        }
      });
    },
    onSearchServerKmCertPerm() {
      getServerKmCertPerm().then(response => {
        this.svrKmCertPerm = response.data.svrKmCertPerm;
      });
    },
    onCreate() {
      let url = '/ntsCommon/createBill';
      let param = {
        ...this.param,
        billType: BIDDING_CONSTANTS.BILL_TYPE.CON,
      };
      const title = `전자${this.param.taxType == 1 ? '세금' : ''}계산서`;
      const message = `전자${this.param.taxType == 1 ? '세금' : ''}계산서를 생성하시겠습니까?`;

      this.$save({url, param, title, message})
        .then(response => {
          if (response.status == 200) {
            this.$emit('changeState');
            this.onSearch();
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            let msg = ex.message;
            if (ex.description) msg += `<br/>- ${ex.description}`;
            this.$alert({title: ex.title, message: msg});
          }
        });
    },
    onCancel() {
      const url = '/pxcondDtlsRegist/deleteTaxInvoice';
      const param = {...this.param, billNo: this.billNo};
      const title = `전자${this.param.taxType == 1 ? '세금' : ''}계산서`;
      const message = `생성된 전자${this.param.taxType == 1 ? '세금' : ''}계산서를 취소하시겠습니까?`;

      this.$save({url, param, title, message})
        .then(response => {
          if (response.status == 200) {
            this.$emit('changeState');
            this.onSearch();
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            let msg = ex.message;
            if (ex.description) msg += `<br/>- ${ex.description}`;
            this.$alert({title: ex.title, message: msg});
          }
        });
    },
    onSign() {
      const certdn = JSON.parse(sessionStorage.getItem('userInfo')).certdn;
      if (!certdn) {
        const title = '인증서 등록';
        const message =
          'PMIS를 이용하기 위해서는 발급받은 인증서 및 보유하고 있는 공동인증서(구 공인인증서)를 등록해야 합니다.<br/><br/>인증서를 등록하시겠습니까?';
        this.$confirm({title, message}, () => {
          this.$modalConfirm();
          this.goMenu('CERT_REGIST_CTR');
        });
        return false;
      }

      this.onSignProcess();
    },
    async onSignProcess() {
      try {
        await this.$secuKit.checkReady();
        await this.$secuKit.showDialog(SECUKIT_SERVICE_TYPE.SECUTAX);

        const originalText = this.billXml;
        const certID = this.$secuKit.certListInfo.getCertID();

        const r1 = await this.$secuKit.signXMLDocumentForTax({
          originalText: originalText,
          certID: certID,
        });
        const origCntn = originalText;
        const signCntn = r1.signXMLDocumentForTax;

        const r2 = await this.$secuKit.getKeyRandom({
          certString: removePEMHeader(removeCRLF(this.svrKmCertPerm)),
          certID: certID,
        });
        const encRvalue = r2.getKeyRandom;

        // const res = await this.$secuKit.viewCertInfomationWithVID({
        //   certType: 'SignCert',
        //   certID: certID,
        //   isViewVID: '1',
        // });
        // const userDN = String(res.userDN);
        // const certdn = String(JSON.parse(sessionStorage.getItem('userInfo')).certdn);
        // if (userDN != certdn) {
        //   this.$alert({
        //     title: '인증서 검증',
        //     message: '시스템에 등록된 인증서와 선택하신 인증서가 다릅니다.<br/> 다시 한 번 확인하시기 바랍니다.',
        //   });
        //   return;
        // }

        const url = '/ntsCommon/signBill';
        let param = {
          signedXmlList: [
            {
              billNo: this.billNo,
              origCntn: origCntn,
              signCntn: signCntn,
            },
          ],
          encRvalue: encRvalue,
          billType: BIDDING_CONSTANTS.BILL_TYPE.CON,
        };

        this.$save({url, param})
          .then(response => {
            if (response.status == 200) {
              this.$emit('changeState');
              this.onSearch();
            }
          })
          .catch(ex => {
            if (ex.pmisError) {
              const title = ex.title;
              const message = ex.description ? `${ex.message}<br/>- ${ex.description}` : ex.message;
              this.$alert({title, message});
            }
          });
      } catch (e) {
        console.error(e);
      }
    },
    onPrint() {
      this.callEvent({name: 'showLoading'});
      this.isPrint = true;
    },
    onDownload() {
      this.callEvent({name: 'showLoading'});
      this.isDownload = true;
    },
    onGenerateComplete() {
      this.callEvent({name: 'hideLoading'});
    },
  },
};
</script>

<style></style>
