export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      HeaderMerge: 5, // 5 : 열 우선 사방 병합
      CanEdit: 0, // 1 : 전체 편집 가능 (default)
      CustomScroll: 0,
    },
    LeftCols: [
      {
        Header: ['투입일자', '투입일자'],
        Name: 'ioDate',
        Type: 'Text',
        Align: 'center',
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: ['자재코드', '자재코드'],
        Name: 'matCode',
        Type: 'Text',
        Align: 'center',
        Width: 150,
        CanEdit: 0,
      },
      {
        Header: ['자재명', '자재명'],
        Name: 'matName',
        Type: 'Text',
        Align: 'left',
        Width: 150,
        CanEdit: 0,
      },
    ],
    Cols: [
      {
        Header: ['규격', '규격'],
        Name: 'size',
        Type: 'Text',
        Align: 'left',
        Width: 160,
        CanEdit: 0,
      },
      {
        Header: ['단위', '단위'],
        Name: 'matUnitName',
        Type: 'Text',
        Align: 'center',
        Width: 50,
        CanEdit: 0,
      },
      {
        Header: ['투입수량', '투입수량'],
        Name: 'qty',
        Extend: preset.quantityFormula,
        Width: 100,
        CanEdit: 0,
      },
      {
        Header: ['단가', '단가'],
        Name: 'price',
        Extend: preset.unitAmountFormula,
        Width: 100,
        CanEdit: 0,
      },
      {
        Header: ['투입금액', '투입금액'],
        Name: 'amt',
        Extend: preset.amountFormula,
        Width: 100,
        CanEdit: 0,
      },
      {
        Header: ['투입\n외주', '투입\n외주'],
        Name: 'subCodeName',
        Type: 'Text',
        Align: 'center',
        MinWidth: 140,
        CanEdit: 0,
      },
      {
        Header: ['실행내역', '공종명'],
        Name: 'mgName',
        Type: 'Text',
        Align: 'left',
        Width: 150,
        CanEdit: 0,
      },
      {
        Header: ['실행내역', '내역코드'],
        Name: 'itemSeq',
        Type: 'Text',
        Align: 'center',
        Width: 80,
        CanEdit: 0,
      },
      {
        Header: ['실행내역', '내역명'],
        Name: 'itemName',
        Type: 'Text',
        Align: 'left',
        Width: 150,
        CanEdit: 0,
      },
      {
        Header: ['실행내역', '규격'],
        Name: 'ssize',
        Type: 'Text',
        Align: 'left',
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: ['실행내역', '단위'],
        Name: 'unitName',
        Type: 'Text',
        Align: 'center',
        Width: 50,
        CanEdit: 0,
      },
      {
        Header: ['실행내역', '수량'],
        Name: 'moqty',
        Extend: preset.quantity,
        Width: 100,
        CanEdit: 0,
      },
      {
        Header: ['실행내역', '단가'],
        Name: 'matPrice',
        Extend: preset.unitAmount,
        Width: 100,
        CanEdit: 0,
      },
      {
        Header: ['실행내역', '금액'],
        Name: 'matAmt',
        Extend: preset.amount,
        Width: 100,
        CanEdit: 0,
      },
      {Name: 'seq', Visible: false},
    ],
  };
};
