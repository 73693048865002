<template>
  <iui-content-box
    :title-type="titleType"
    :border-box="borderBox"
    :no-border-box="noBorderBox"
    :no-padding-box="noPaddingBox"
    :loading="loading"
  >
    <template #title>
      <slot name="title" />
    </template>
    <template #title-bullet>
      <slot name="title-bullet" />
    </template>
    <template #title-bullet-required>
      <slot name="title-bullet-required" />
    </template>
    <template #title-left>
      <slot name="title-left" />
    </template>
    <template #title-center>
      <slot name="title-center" />
    </template>
    <template #title-right>
      <slot name="title-right" />
    </template>
    <template #header-left>
      <slot name="header-left" />
    </template>
    <template #header-center>
      <slot name="header-center" />
    </template>
    <template #header-right>
      <slot name="header-right" />
    </template>
    <template #header-custom>
      <slot name="header-custom" />
    </template>
    <slot />
  </iui-content-box>
</template>

<script>
export default {
  props: {
    titleType: {
      type: String,
      default: 'bullet',
    },
    borderBox: {
      type: Boolean,
      default: false,
    },
    noBorderBox: {
      type: Boolean,
      default: false,
    },
    noPaddingBox: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
