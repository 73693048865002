<template>
  <pmis-content-box :loading="loading">
    <template v-if="search" v-slot:header-left>
      <iui-text type="search" :value.sync="searchNm" @enter="searchByItemNm" />
      <iui-button value="검색" @click="searchByItemNm" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onSearchFinish,
        onClick,
        onDblClick,
      }"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/PrjWorkTypeClSheet.js';
import {deleteWorkTypeCl, selectWorkTypeClList} from '../api/prjWorkTypeRegist.js';

export default {
  props: {
    search: {
      type: Boolean,
      default: true,
    },
    initSearchYn: {
      type: String,
      default: 'Y',
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  beforeCreate() {
    $mapGetters(this, 'workType', ['sheetData', 'focusKeyCode']);
    $mapMutations(this, 'workType', ['initSheetData', 'initFocusKeyCode']);
  },
  created() {
    this.addEvent([
      {name: 'prjWorkTypeCl_onSearch', func: this.onSearch},
      {name: 'prjWorkTypeCl_selectClickData', func: this.onSelectClickData},
      {name: 'prjWorkTypeCl_onDelete', func: this.onDelete},
    ]);
  },
  data() {
    return {
      sheet: undefined,
      sheetUtil: undefined,
      Options: this.checkbox ? $addColCheckbox(options(this)) : options(this),
      loadSearchData: [],
      loading: false,
      searchNm: '',
    };
  },
  methods: {
    async onSearch(param) {
      this.initSearchNm();
      this.initSheetData();
      if (!param) {
        param = {};
      }

      this.loading = true;
      let response = await selectWorkTypeClList(param);
      let tree = $_treeModelStdCl(
        response.data,
        $getConstants('WORK_TYPE_CL').code,
        'code',
        'upCode',
        undefined,
        undefined,
        'name'
      );
      this.loadSearchData = tree({level: 1, name: '공종분류'});
      this.loading = false;
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
      if (this.checkbox) {
        sheet.util.onTreeCheck('chk');
      }
    },
    onSearchFinish() {
      if (0 < this.sheet.getTotalRowCount()) {
        this.sheet.showTreeLevel(2, 0, 1);
        let row;
        if (this.focusKeyCode) {
          row = this.sheet.getDataRows().find(row => row.code === this.focusKeyCode);
          this.sheetUtil.treeSearch('code', this.focusKeyCode);
          this.initFocusKeyCode();
        } else {
          row = this.sheet.getFirstRow();
        }
        this.callback(row);
      }
    },
    onClick(e) {
      this.initSheetData();
      this.initSearchNm();
      this.callback(e.row);
    },
    onDblClick() {
      this.$modalConfirm();
    },
    getMessage(level, name) {
      const msg = '공종';
      const workLevel = level === 2 ? '공사구분' : '대공종';
      return `* 삭제대상 : ${name} [${workLevel}]<br/>해당 ${msg} 삭제 시 하위 ${msg}들도 모두 삭제됩니다.<br/>삭제하시겠습니까?`;
    },
    async onDelete() {
      const row = this.sheet.getFocusedRow();
      if (row == null) {
        this.$alert({title: '삭제', message: '삭제대상이 존재하지 않습니다.'});
        return;
      }

      const level = Number(row.level);
      if (level === 1) {
        this.$alert({title: '삭제', message: '최상위 항목은 삭제할 수 없습니다.'});
        return;
      }

      if (
        level < 4 &&
        !(await this.$confirm({
          title: '삭제',
          message: this.getMessage(level, String(row.name)),
        }))
      ) {
        return;
      }

      if (
        level === 4 &&
        !(await this.$confirm({
          title: '삭제',
          message: '선택한 중공종을 삭제하시겠습니까?',
        }))
      ) {
        return;
      }

      try {
        const response = await deleteWorkTypeCl({
          pgmCd: row.pgmCd,
          prjCd: row.prjCd,
          level: row.level,
          code: row.code,
          upCode: row.upCode,
          wcode: row.wcode,
          lgCode: row.lgCode,
          mgCode: row.mgCode,
        });
        if (response.data) {
          this.sheet.removeRow(row);
          this.initSheetData();
          const upRow = this.sheet.getDataRows().find(x => x.code === row.upCode);
          if (upRow) {
            this.sheet.focus(upRow);
            this.callback(upRow);
          }
        }
      } catch (error) {
        this.$alert({title: '현장공종분류삭제', message: error.message});
      }
    },
    onSelectClickData(callback) {
      let checkedRows = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({title: '선택', message: '선택된 항목이 없습니다.'});
        return;
      }

      callback(checkedRows);
    },
    searchByItemNm() {
      this.$emit('searchByItemNm', this.searchNm);
      this.sheetUtil.treeSearch(['name', 'code'], this.searchNm);
      setTimeout(() => {
        this.callback(this.sheet.getFocusedRow());
      }, 100);
    },
    initSearchNm() {
      this.searchNm = '';
    },
  },
};
</script>

<style scoped></style>
