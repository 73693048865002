<template>
  <div>
    <h2 class="j_title">회사정보</h2>
    <table class="join_list mt10" style="margin:0 auto">
      <caption>
        회사기본정보
      </caption>
      <colgroup>
        <col style="width:140px" />
        <col style="width:*" />
        <col style="width:140px" />
        <col style="width:*" />
      </colgroup>
      <tr>
        <th colspan="4"><span class="j_bu02 fwb">표시는 필수 입력 항목이오니 반드시 입력해 주세요.</span></th>
      </tr>
      <tr>
        <th scope="col"><span class="j_bu01">회원사구분</span></th>
        <td colspan="3">
          <input type="text" v-model="companyInfo.frmGbNm" disabled="disabled" style="width: 182px" />
        </td>
      </tr>
      <tr>
        <th scope="col"><span class="j_bu02">사업자번호</span></th>
        <td colspan="3">
          <input
            type="text"
            id="bsnNo1"
            name="bsnNo1"
            style="width:50px"
            minlength="3"
            maxlength="3"
            v-model="bsnNo1"
            @change="setBsnNo"
            required
          />
          -
          <input
            type="text"
            id="bsnNo2"
            style="width:30px"
            minlength="2"
            maxlength="2"
            v-model="bsnNo2"
            @change="setBsnNo"
            required
          />
          -
          <input
            type="text"
            id="bsnNo3"
            style="width:70px"
            minlength="5"
            maxlength="5"
            v-model="bsnNo3"
            @change="setBsnNo"
            required
          />
          <button type="button" class="button_input_srch ml5" @click="onDupBsnNo">중복확인</button>
        </td>
      </tr>
      <tr>
        <th scope="col"><span class="j_bu02">회사명</span></th>
        <td colspan="3">
          <input
            type="text"
            style="width:288px"
            name="frmNm"
            v-model="companyInfo.frmNm"
            maxlength="100"
            placeholder="사업자등록증 상호 등록"
            required
          />
        </td>
      </tr>
      <tr>
        <th scope="col"><span class="j_bu01">회사명(영문)</span></th>
        <td colspan="3">
          <input type="text" style="width:288px" v-model="companyInfo.frmEnNm" maxlength="100" />
        </td>
      </tr>
      <tr>
        <th scope="col"><span class="j_bu02">대표자명</span></th>
        <td colspan="3">
          <input type="text" style="width:288px" name="rpsvNm" v-model="companyInfo.rpsvNm" maxlength="16" required />
        </td>
      </tr>
      <tr>
        <th scope="col"><span class="j_bu01">대표자명(영문)</span></th>
        <td colspan="3">
          <input type="text" style="width:288px" v-model="companyInfo.rpsvEnNm" maxlength="16" />
        </td>
      </tr>
      <tr>
        <th scope="col"><span class="j_bu02">대표전화</span></th>
        <td colspan="3">
          <input
            type="text"
            style="width:50px"
            v-model="phone1"
            @change="setRpsvPhone('phone1', $event.target.value)"
            maxlength="3"
            required
          />
          -
          <input
            type="text"
            style="width:50px"
            v-model="phone2"
            @change="setRpsvPhone('phone2', $event.target.value)"
            maxlength="4"
            required
          />
          -
          <input
            type="text"
            style="width:50px"
            v-model="phone3"
            @change="setRpsvPhone('phone3', $event.target.value)"
            maxlength="4"
            required
          />
        </td>
      </tr>
      <tr>
        <th scope="col"><span class="j_bu02">주소</span></th>
        <td colspan="3">
          <input type="text" v-model="companyInfo.postNo" disabled="disabled" style="width:85px" />
          <button type="button" class="button_input_srch ml5" @click="onAddr">우편번호 찾기</button>
          <p>
            <input
              type="text"
              class="mt5"
              disabled="disabled"
              style="width:470px"
              v-model="companyInfo.adr1"
              placeholder="사업자등록증 주소"
              required
            />
          </p>
          <p>
            <input
              type="text"
              class="mt5"
              style="width:470px"
              v-model="companyInfo.adr2"
              maxlength="100"
              placeholder="상세주소"
              required
            />
          </p>
        </td>
      </tr>
      <tr>
        <th scope="col"><span class="j_bu01">홈페이지</span></th>
        <td colspan="3">
          <input type="text" style="width:470px" v-model="companyInfo.homepage" maxlength="300" />
        </td>
      </tr>
      <tr>
        <th scope="col">
          <span class="j_bu02">업태</span>
        </th>
        <td>
          <input type="text" style="width:100%" v-model="companyInfo.bcoNm" maxlength="33" required />
        </td>
        <th scope="col">
          <span class="j_bu02">종목</span>
        </th>
        <td>
          <input type="text" style="width:100%" v-model="companyInfo.itemNm" maxlength="33" required />
        </td>
      </tr>
    </table>

    <h2 class="j_title mt30">관리자정보</h2>
    <table class="join_list mt10" style="margin:0 auto">
      <caption>
        회원정보 작성
      </caption>
      <colgroup>
        <col style="width:140px" />
        <col style="width:*" />
        <col style="width:140px" />
        <col style="width:*" />
      </colgroup>
      <tr>
        <th scope="col">
          <span class="j_bu02">아이디(사번)</span>
        </th>
        <td colspan="3">
          <input type="text" style="width:189px" v-model="worker.empNo" @input="onValidation" maxlength="8" required />
        </td>
      </tr>
      <tr>
        <th scope="col">
          <span class="j_bu02">이름</span>
        </th>
        <td colspan="3">
          <input type="text" style="width:189px" v-model="worker.name" maxlength="16" required />
        </td>
      </tr>
      <tr>
        <th scope="col"><span class="j_bu02">휴대폰번호</span></th>
        <td colspan="3">
          <input type="text" v-model="mobileTlno1" @change="setMobTlNo" maxlength="3" style="width:50px" required />
          -
          <input type="text" v-model="mobileTlno2" @change="setMobTlNo" maxlength="4" style="width:50px" required />
          -
          <input
            type="text"
            v-model="mobileTlno3"
            @change="setMobTlNo"
            maxlength="4"
            style="width:50px"
            class="mr5"
            required
          />
          <input type="checkbox" id="c1" v-model="worker.useSms" @change="setUseSms($event)" />
          <label for="c1"><span></span>SMS 수신동의</label>
        </td>
      </tr>
      <tr>
        <th scope="col"><span class="j_bu01">전화번호</span></th>
        <td>
          <input type="text" v-model="tlno1" @change="setTlNo" maxlength="3" style="width:50px" />
          -
          <input type="text" v-model="tlno2" @change="setTlNo" maxlength="4" style="width:50px" />
          -
          <input type="text" v-model="tlno3" @change="setTlNo" maxlength="4" style="width:50px" />
        </td>
        <th scope="col"><span class="j_bu01">팩스번호</span></th>
        <td>
          <input type="text" maxlength="3" v-model="faxNo1" @change="setFaxNo" style="width:50px" />
          -
          <input type="text" maxlength="4" v-model="faxNo2" @change="setFaxNo" style="width:50px" />
          -
          <input type="text" maxlength="4" v-model="faxNo3" @change="setFaxNo" style="width:50px" class="mr5" />
        </td>
      </tr>
      <tr>
        <th scope="col"><span class="j_bu02">이메일</span></th>
        <td colspan="3">
          <input type="text" v-model="worker.email1" maxlength="25" style="width:100px" required />
          <span class="vam_dash">&commat;</span>
          <input type="text" class="email2" v-show="email3 === ''" v-model="worker.email2" maxlength="25" required />
          <select style="width:130px" v-model="email3" @change="setEmail">
            <option selected="selected" v-for="email3 in emailOpt" :value="email3.value" v-bind:key="email3.key">
              {{ email3.label }}
            </option>
          </select>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    frmGbCd: {
      type: [String],
    },
  },
  data() {
    return {
      //사업자번호 중복확인 0: 확인필요, 1: 중복없음, 2: 중복있음
      dupBsnNoStatus: 0,

      dupBsnNo: '',

      bsnNo1: '',
      bsnNo2: '',
      bsnNo3: '',
      phone1: '',
      phone2: '',
      phone3: '',
      mobileTlno1: '',
      mobileTlno2: '',
      mobileTlno3: '',
      tlno1: '',
      tlno2: '',
      tlno3: '',
      faxNo1: '',
      faxNo2: '',
      faxNo3: '',
      email2: '',
      email3: '',

      emailOpt: [
        {label: '직접입력', value: ''},
        {label: 'naver.com', value: '000001'},
        {label: 'hanmail.net', value: '000002'},
        {label: 'daum.net', value: '000003'},
        {label: 'nate.com', value: '000004'},
        {label: 'gmail.com', value: '000005'},
        {label: 'hotmail.com', value: '000006'},
      ],
      companyInfo: {
        pgmCd: '', // 회사코드
        frmNm: '', // 회사명
        frmEnNm: '', // 회사명(영문)
        bsnNo: '', // 사업자번호
        frmGb: this.frmGbCd, // 회사구분
        frmGbNm: '', //회사구분명
        rpsvNm: '', // 대표자명
        rpsvEnNm: '', // 대표자명(영문)
        rpsvPhone: '', // 대표전화번호
        postNo: '', // 우편번호
        adr1: '', // 주소
        adr2: '', // 상세주소
        homepage: '', // 홈페이지
        bcoNm: '', // 업태
        itemNm: '', // 종목
        useFlag: '', // 회사정보승인Flag
      },
      //관리자 정보
      worker: {
        empNo: '', // 아이디(사번)
        name: '', // 이름
        mobileTlno: '', // 휴대폰번호
        useSms: '000001', // SMS수신동의여부
        tlno: '', // 전화번호
        rpsvFaxno: '', //팩스번호
        email1: '', //이메일
        email2: '',
        createNewEmpNo: false,
      },
    };
  },
  watch: {
    params: {
      handler(val) {
        if (Object.keys(val).length == 0) return;
        this.companyInfo.postNo = val.zipNo;
        this.companyInfo.adr1 = `${val.roadAddrPart1} ${val.roadAddrPart2}`;
        this.companyInfo.adr2 = val.addrDetail;
      },
      deep: true,
    },
    bsnNo1: {
      handler(val) {
        this.bsnNo1 = this.setNumberFormat(val);
      },
    },
    bsnNo2: {
      handler(val) {
        this.bsnNo2 = this.setNumberFormat(val);
      },
    },
    bsnNo3: {
      handler(val) {
        this.bsnNo3 = this.setNumberFormat(val);
      },
    },
    phone1: {
      handler(val) {
        this.phone1 = this.setNumberFormat(val);
      },
    },
    phone2: {
      handler(val) {
        this.phone2 = this.setNumberFormat(val);
      },
    },
    phone3: {
      handler(val) {
        this.phone3 = this.setNumberFormat(val);
      },
    },
    mobileTlno1: {
      handler(val) {
        this.mobileTlno1 = this.setNumberFormat(val);
      },
    },
    mobileTlno2: {
      handler(val) {
        this.mobileTlno2 = this.setNumberFormat(val);
      },
    },
    mobileTlno3: {
      handler(val) {
        this.mobileTlno3 = this.setNumberFormat(val);
      },
    },
    tlno1: {
      handler(val) {
        this.tlno1 = this.setNumberFormat(val);
      },
    },
    tlno2: {
      handler(val) {
        this.tlno2 = this.setNumberFormat(val);
      },
    },
    tlno3: {
      handler(val) {
        this.tlno3 = this.setNumberFormat(val);
      },
    },
    faxNo1: {
      handler(val) {
        this.faxNo1 = this.setNumberFormat(val);
      },
    },
    faxNo2: {
      handler(val) {
        this.faxNo2 = this.setNumberFormat(val);
      },
    },
    faxNo3: {
      handler(val) {
        this.faxNo3 = this.setNumberFormat(val);
      },
    },
  },

  created() {
    const obj = {
      ['000001']: '건설사',
      ['000002']: '공사용역협력업체',
      ['000003']: '물품자재협력업체',
    };

    this.companyInfo.frmGbNm = obj[this.companyInfo.frmGb];
  },

  methods: {
    initCompanyInfo() {
      for (let key in this.companyInfo) {
        this.companyInfo[key] = '';
      }
      for (let key in this.worker) {
        if (key === 'useSms') {
          this.worker[key] = '000001';
        } else {
          this.worker[key] = '';
        }
      }
    },
    setRpsvPhone() {
      this.companyInfo.rpsvPhone = this.phone1.trim() + this.phone2.trim() + this.phone3;
    },
    setBsnNo() {
      this.dupBsnNoStatus = 0;
      this.companyInfo.bsnNo = this.bsnNo1 + this.bsnNo2 + this.bsnNo3;
    },
    setMobTlNo() {
      this.worker.mobileTlno = this.mobileTlno1 + this.mobileTlno2.trim() + this.mobileTlno3;
    },
    setTlNo() {
      this.worker.tlno = this.tlno1.trim() + this.tlno2.trim() + this.tlno3;
    },
    setFaxNo() {
      this.worker.rpsvFaxno = this.faxNo1.trim() + this.faxNo2.trim() + this.faxNo3;
    },
    setUseSms(event) {
      this.worker.useSms = event.target.checked ? '000001' : '';
    },
    setEmail() {
      if (this.email3 !== '직접입력') {
        this.worker.email2 = this.email3;
      } else {
        this.worker.email2 = '';
      }
    },
    setNumberFormat(val) {
      const re = new RegExp('[^0-9]', 'g');
      return val.toString().replace(re, '');
    },
    onAddr() {
      window.openUid = this._uid;
      window.open(
        process.env.VUE_APP_API_URL + `/address/jusoPopup?frontendUrl=${location.origin}`,
        'jusoPop',
        'width=570, height=420, scrollbars=yes, resizable=yes'
      );
    },
    onValidation() {
      this.worker.empNo = String(this.worker.empNo).replace(/[^0-9a-zA-z]/g, '');
    },
    onDupBsnNo() {
      if (this.companyInfo.bsnNo.length !== 10) {
        alert('사업자번호 10자리를 정확히 입력해주세요.');
        return;
      }
      axios.post('/companyRegist/selectCompanyExistBsnNo', {bsnNo: this.companyInfo.bsnNo}).then(response => {
        //사업자번호 중복확인
        if (response.status === 200) {
          if (response.data) {
            this.dupBsnNoStatus = 2;
          } else {
            this.dupBsnNoStatus = 1;
            alert('등록 가능한 사업자번호입니다.');
            return;
          }
          this.validator();
        }
      });
    },
    async onSaveCompany() {
      if (!this.validator()) {
        return;
      }
      let response = await axios.post('/companyRegist/noAuthSaveCompanyInfo', {
        ...this.companyInfo,
        worker: this.worker,
        cud: 1,
      });
      if (response.status == 200) {
        this.initCompanyInfo();
        this.$parent.nextStep();
      }
    },
    validator() {
      if (!this.bsnNo1 || !this.bsnNo2 || !this.bsnNo3) {
        alert('사업자번호를 입력해주세요.');
        return;
      } else if (this.dupBsnNoStatus == 0) {
        alert('사업자번호 중복확인을 해주세요.');
        document.getElementById('bsnNo1').focus();
        return;
      } else if (this.dupBsnNoStatus == 2) {
        alert('이미 등록한 사업자번호입니다.\n운영팀으로 문의 바랍니다.');
        return;
      } else if (!this.companyInfo.frmNm) {
        alert('회사명을 입력해주세요.');
        return;
      } else if (!this.companyInfo.rpsvNm) {
        alert('대표자명을 입력해주세요.');
        return;
      } else if (!this.phone1 || !this.phone2 || !this.phone3) {
        alert('대표전화를 입력해주세요.');
        return;
      } else if (!this.companyInfo.postNo || !this.companyInfo.adr1) {
        alert('주소를 입력해주세요.');
        return;
      } else if (!this.companyInfo.bcoNm) {
        alert('업태를 입력해주세요.');
        return;
      } else if (!this.companyInfo.itemNm) {
        alert('종목을 입력해주세요.');
        return;
      } else if (!this.worker.empNo) {
        alert('아이디를 입력해주세요.');
        return;
      } else if (!this.worker.name) {
        alert('이름을 입력해주세요.');
        return;
      } else if (!this.mobileTlno1 || !this.mobileTlno2 || !this.mobileTlno3) {
        alert('휴대폰번호를 입력해주세요.');
        return;
      } else if (!this.worker.email1 || !this.worker.email2) {
        alert('이메일을 입력해주세요.');
        return;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.email2 {
  width: 130px;
  margin-right: 6px;
}

td > * {
  vertical-align: middle !important;
}
</style>
