export default that => {
  return {
    Cfg: {
      CanEdit: 0,
      SearchMode: 0,
    },
    Cols: [
      {
        Header: '문서번호',
        Name: 'dpDocNo',
        Type: 'Text',
        Width: 160,
      },
      {
        Header: '시행종류',
        Name: 'docCsDs',
        Type: 'Text',
        Align: 'Center',
        Format: $getConstants('DOC_CS_DS').code,
        Width: 100,
      },
      {
        Header: '문서제목',
        Name: 'tl',
        Type: 'Text',
        RelWidth: 1,
      },
      {
        Header: '첨부',
        Name: 'flCnt',
        Type: 'Text',
        Align: 'Center',
        Width: 60,
      },
      {
        Header: '결재완료일시',
        Name: 'enfcDt',
        Extend: that.$ibsheetHelper.preset.date,
        Width: 100,
      },
      {
        Header: '기안/접수부서',
        Name: 'blnDepNm',
        Type: 'Text',
        Width: 100,
      },
      {
        Header: '기안/접수자',
        Name: 'name',
        Type: 'Text',
        Width: 100,
      },
      {
        Header: '기안/접수일',
        Name: 'rgsDttm',
        Extend: that.$ibsheetHelper.preset.date,
        Width: 100,
      },
      {
        Name: 'sncNo',
        Visible: 0,
      },
    ],
  };
};
