<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text type="search" name="name" ref="searchNm" @change="searchValueChange($event)" @enter="onFilter()" />
      <iui-button value="검색" @click="onFilter()" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onDblClick: grid_onDblClick,
        onRenderFirstFinish,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/NationListPopupSheet.js';
export default {
  props: {
    paramObj: {
      type: Object,
      default: () => {},
    },
    uid: {
      type: Number,
    },
  },
  created() {
    this.addEvent([
      {name: 'CommonCode_search', func: this.onSearch},
      {name: 'nationList_callbackData', func: this.callbackData},
    ]);
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      searchNm: '',
      Options: options(this),
      loadSearchData: [],
    };
  },
  methods: {
    searchValueChange(e) {
      this.searchNm = e.target.value;
    },
    async onSearch() {
      this.codes = await $getCode(this.paramObj.code);
      this.sheet.loadSearchData(this.codes);
    },
    onFilter() {
      if (this.sheet.getTotalRowCount() > 0) {
        let searchCnt = this.sheetUtil.listSearch(['codeNm'], this.searchNm);
        if (searchCnt == 0) {
          this.$alert({title: '국가검색', message: '검색결과가 없습니다.'});
        }
        this.$refs.searchNm.$refs.input.focus();
      }
    },
    callbackData(returnValue) {
      let row = this.sheet.getFocusedRow();
      if (row) {
        returnValue(row);
      } else {
        this.$alert('선택된 공통코드가 없습니다.');
      }
    },
    grid_onDblClick(e) {
      if (e.row.Kind == 'Data') {
        this.callEvent({name: 'onHideModal_' + this.uid});
      }
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style></style>
