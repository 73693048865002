<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <label>현장명</label>
      <iui-text width="60%" :value="searchInfo.prjNm" :enable="false" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onDblClick: this.grid_onDblClick,
        onRenderFirstFinish,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import {selectUnitOpertListPopup} from '../api/photo.js';
import options from './sheetOption/UnitOpertListPopupSheet.js';
export default {
  /**
   * 공정사진조회 > 단위작업명선택 팝업
   * 사용 : 자재입고등록
   *        가동장비등록
   **/
  data() {
    return {
      loadSearchData: [],
      Options: options(this),
    };
  },
  beforeCreate() {
    $mapGetters(this, {
      searchInfo: 'searchInfo',
    });
  },
  created() {
    this.addEvent([{name: 'unitOpertListPopupCallbackData', func: this.onFocusedRowData}]);
  },
  methods: {
    async onSearch() {
      let param = {
        ...this.searchInfo,
        pgmCd: this.pgmCd,
      };
      const response = await selectUnitOpertListPopup(param);
      let treeData = $_treeModel(response.data, $getConstants('UNIT_OPERT_CL').code, 'fldrNo', 'uppFldrNo');
      this.loadSearchData = treeData;
    },
    async onFocusedRowData(callbackFunction) {
      if (typeof callbackFunction == 'function') {
        callbackFunction(this.sheet.getFocusedRow());
      }
    },
    grid_onDblClick(e) {
      if (e.row.Kind == 'Data') {
        this.callEvent({name: 'onHideModal'});
      }
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style></style>
