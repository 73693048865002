<template>
  <pmis-content-box :loading="loading">
    <template #header-left>
      <label>공종</label>
      <iui-text type="search" :value.sync="workType" @enter="sheetSearch" />
      <iui-button value="검색" @click="sheetSearch" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="Options"
      :autoRender="false"
      :init.sync="init"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import {selectContWplanPlanSheetDataList} from '../api/workPlanRegist.js';
import options from './sheetOptions/UnitOpertPlanCntSheet.js';
export default {
  data() {
    return {
      sheet: undefined,
      init: false,
      Options: {},
      loadSearchData: [],
      workType: '',
      loading: false,
    };
  },
  beforeCreate() {
    $mapMutations(this, ['setCreated']);
  },
  created() {
    this.addEvent([
      {name: 'getChangedDatas', func: this.getChangedDatas},
      {name: 'unitWorkPlanCntSheetInq', func: this.initSheet},
      {name: 'unitOpertplanCnt_setLoading', func: this.setLoading},
    ]);
  },
  methods: {
    setLoading(payload) {
      this.loading = payload;
    },
    async initSheet() {
      this.Options = await options(this);
      this.init = true;
    },
    async loadSheet(sheet) {
      this.sheet = sheet;
      const sheetData = await selectContWplanPlanSheetDataList({});
      this.setCreated(sheetData.data.length > 0);
      this.loadSearchData = sheetData.data;
      this.loading = false;
    },
    getChangedDatas(callback) {
      if (callback) {
        let isValid = true;
        let messages = [];
        let datas = [];
        for (let obj of this.sheet.getSaveJson().data) {
          let cols = this.sheet.getCols('Visible');
          let row = this.sheet.getRowById(obj.id);
          let totalPlanCnt = 0;
          for (let key of cols) {
            //planCnt 합계를 위한 col구분으로 wbsCd_wbsId 로 이름을 만들었고 wbsCd는 5자리이므로 index 5로 구분함.
            if (key.indexOf('_') == 5) {
              totalPlanCnt += row[key] || 0;
            }
            if (row[key + 'Changed']) {
              datas.push({
                pgmCd: row.pgmCd,
                prjCd: row.prjCd,
                wbsId: key.split('_')[0],
                wbsCd: key.split('_')[1],
                midCode: row.midCode,
                itemSeq: row.itemSeq,
                planCnt: row[key],
              });
            }
          }
          if (row.qty != totalPlanCnt) {
            messages.push(`${row.itemName}(${row.itemSeq}) 수량 [${row.qty}] 예정물량 [${totalPlanCnt}]`);
            isValid = false;
          }
        }

        if (isValid) {
          callback(datas);
        } else {
          this.$alert({
            title: '예정물량저장',
            message: `수량과 예정물량이 일치하지 않습니다. <br /> ${messages.join(',<br />')}`,
          });
        }
      }
    },
    sheetSearch() {
      if (this.workType) {
        this.sheet.setFilter('mgNameFilter', obj => {
          return obj.Row.mgName.indexOf(this.workType) > -1 || obj.Row.midCode.indexOf(this.workType) > -1;
        });
      } else {
        this.sheet.clearFilter();
      }
    },
    workTypeCallback(data) {
      this.workType = data.code;
    },
  },
};
</script>
