import * as util from '@/components/Iui/util.js';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import IuiCssFlexMixin from '@/components/Iui/mixins/IuiCssFlexMixin';

export default {
  name: 'i-spacer',
  mixins: [IuiLayoutMixin, IuiCssFlexMixin],
  inject: ['containerType'],
  render(h) {
    let data = {};

    /**
     * tag 처리
     */
    let tag = 'td';

    switch (this.containerType) {
      case 'table':
        tag = 'td';
        break;
      case 'css-table':
        tag = 'div';
        break;
      case 'css-flex':
        tag = 'div';
        break;
      case 'css-flex-table':
        tag = 'div';
        break;
      case 'css-grid':
        tag = 'div';
        break;
      default:
        tag = 'td';
        break;
    }

    /**
     * class 처리
     */
    let classList = [];
    let defaultClassName = 'spacer';
    classList.push({[defaultClassName]: true});

    util.appendArrayToObject(data, 'class', classList);

    /**
     * childNode 처리
     */
    let childNodes = [];

    childNodes.push(this.$slots.default);

    /**
     * VNode 생성
     */
    let vnode = null;
    vnode = h(tag, data, childNodes);

    return vnode;
  },
};
