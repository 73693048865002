<template>
  <pmis-content-box>
    <iui-container-new type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    paramObj: {
      type: Object,
      default: () => {},
    },
    uid: {
      type: Number,
    },
    CanEdit: {
      type: Boolean,
      default: true,
    },
    alertMessage: {
      type: Object,
      default: () => ({
        title: '공통코드',
        message: '선택된 공통코드가 없습니다.',
      }),
    },
    filter: {
      type: Function,
      default: () => true,
    },
  },
  created() {
    this.addEvent([
      {name: 'CommonCode_search', func: this.onSearch},
      {name: 'CommonCode_setData', func: this.onSetData},
    ]);
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      codes: [],
    };
  },
  mounted() {
    const OPT = {
      Cols: [
        {Header: '코드', Name: 'code', Type: 'Text', Align: 'center', Width: 120, CanEdit: this.CanEdit},
        {Header: '코드명', Name: 'codeNm', Type: 'Text', Align: 'center', RelWidth: 1, CanEdit: this.CanEdit},
      ],
      Events: {
        onDblClick: this.grid_onDblClick,
      },
    };
    PMISSheet.createSheet({el: this.elId, options: OPT}).then(sheet => {
      this.sheet = sheet;
      this.getCodeList();
    });
  },
  methods: {
    async getCodeList() {
      this.codes = await $getCode(this.paramObj.code);
      this.sheet.loadSearchData(this.codes.filter(this.filter));
    },
    onSearch(param) {
      this.sheet.removeAll();
      if (param.searchSe && param.searchNm) {
        this.sheet.loadSearchData(this.codes.filter(row => row[param.searchSe].indexOf([param.searchNm]) > -1));
      } else if (!param.searchSe && param.searchNm) {
        this.sheet.loadSearchData(
          this.codes.filter(row => row.code.indexOf([param.searchNm]) > -1 || row.codeNm.indexOf([param.searchNm]) > -1)
        );
      } else {
        this.sheet.loadSearchData(this.codes.filter(this.filter));
      }
    },
    onSetData(param) {
      let row = this.sheet.getFocusedRow();
      if (row) {
        param(row);
      } else {
        this.$alert({title: this.alertMessage.title, message: this.alertMessage.message});
      }
    },
    grid_onDblClick(e) {
      if (e.row.Kind == 'Data') {
        this.callEvent({name: `commonCodeSearchOnConfirm${this.uid}`}); // iuiSearchbox.vue => ${this.type}SearchOnConfirm${this._uid}
        this.$emit('dblClick', this.sheet.getFocusedRow());
      }
    },
  },
};
</script>

<style></style>
