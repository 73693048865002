const EXCEL_CONSTANTS = {
  // excel 코드 타입

  //공통코드
  COMMON_CODE: 'COMMON_CODE',
  //노무코드
  LBRC_CODE: 'LBRC_CODE',
  //부서
  DEPT_CODE: 'DEPT_CODE',
  //공사용역
  WORK_GROUP_CODE: 'WORK_GROUP_CODE',
  //노무팀
  LBRC_TEAM_CODE: 'LBRC_TEAM_CODE',
};

export default EXCEL_CONSTANTS;
