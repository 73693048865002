<template>
  <pmis-content-box>
    <template v-slot:header-right>
      <iui-button @click="onAdd">추가</iui-button>
      <iui-button @click="onDel">삭제</iui-button>
      <iui-button @click="onSave">저장</iui-button>
    </template>

    <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" @loadSheet="loadSheet" />
  </pmis-content-box>
</template>

<script>
import {selectUcstCodeMList, saveUcstCode} from '../api/untpcRegist.js';
import options from './sheetOption/UntpcNameSheet.js';
export default {
  props: {
    costType: {
      type: String,
    },
    base: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this)),
      loadSearchData: [],
      deleteRows: [],
    };
  },

  beforeCreate() {
    $mapGetters(this, {untpcList: 'untpcList'});
  },

  created() {
    this.onSearch();
    this.addEvent([{name: 'getUntpcNameData', func: this.getUntpcNameData}]);
  },

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onAdd() {
      let code = 0;
      this.sheet.getDataRows().forEach(row => (code < row.ucstCode ? (code = row.ucstCode) : null));
      code++;
      this.sheet.addRow({init: {ucstCode: code}});
    },
    async onDel() {
      let checkedRows = this.sheet.getRowsByChecked('chk');
      if (!checkedRows.length) {
        this.$alert({title: '삭제', message: '삭제할 항목을 선택해주세요'});
        return;
      }
      const delName = [];
      checkedRows.forEach(row => {
        if (!row.Added) {
          delName.push(row.ucstName);
        }
        this.deleteRows.push(row.ucstCode);
      });

      if (
        !(await this.$confirm({
          title: '삭제',
          message: `[${delName.join(',')}] 으로 설정된 모든 단가가 삭제 됩니다. <br /> 삭제하시겠습니까?`,
        }))
      ) {
        return;
      }
      checkedRows.forEach(row => this.sheet.removeRow(row));
      const response = await this.onSave();
      if (response.data) {
        this.onSearch();
      }
    },
    getUntpcNameData(callback) {
      if (callback) {
        callback({rows: this.sheet.getSaveJson(0).data, deleteRows: this.deleteRows});
      }
    },
    async onSearch() {
      const param = {costType: this.costType, base: this.base};
      const response = await selectUcstCodeMList(param);
      this.loadSearchData = response.data;
    },
    async onSave() {
      let isValid = true;
      let isDup = false;
      let ucstCodeList = [];
      this.sheet.getSaveJson(0).data.forEach(item => {
        if (!item.ucstCode || !item.ucstName) {
          isValid = false;
        }
        ucstCodeList.forEach(list => {
          if (item.ucstCode == list.ucstCode) {
            isDup = true;
          }
        });

        ucstCodeList.push({
          ucstCode: item.ucstCode,
          costType: this.costType,
          ucstName: item.ucstName,
          ucstPrice: item.ucstPrice,
          pgmCd: this.pgmCd,
        });
      });

      if (!isValid) {
        this.$alert({title: '단가명칭', message: '단가코드와 단가명을 모두 입력해주세요.'});
        return;
      }

      if (isDup) {
        this.$alert({title: '단가명칭', message: '단가코드가 중복되었습니다.'});
        return;
      }

      const param = {
        costType: this.costType,
        ucstCodeList: ucstCodeList,
        deleteUscdCodeList: this.deleteRows,
        base: this.base,
      };
      const response = await saveUcstCode(param);
      if (response.data) {
        this.sheet.acceptChangedData();
      }
      return response;
    },
  },
};
</script>
