import CODE from '@/constants/codeConstants.js';

export default that => {
  const options = {
    Cfg: {
      CanEdit: 1,
      HeaderMerge: 6,
    },
    Def: {
      Row: {
        VAlign: 'TOP',
      },
    },
    LeftCols: [
      {
        Header: [{Value: '공종명', Color: '#7ed6df'}, '공종명', '공종명'],
        Name: 'mgNameDg',
        Type: 'Text',
        Width: 100,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '내역코드', '내역코드'],
        Name: 'itemSeqDg',
        Type: 'Text',
        Width: 70,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '내역명', '내역명'],
        Name: 'itemNameDg',
        Type: 'Text',
        Width: 200,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '규격', '규격'],
        Name: 'ssizeDg',
        Type: 'Text',
        Width: 120,
        CanEdit: 0,
        Align: 'Center',
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '단위', '단위'],
        Name: 'unitDg',
        Type: 'Text',
        Width: 100,
        CanEdit: 0,
        Align: 'Center',
        Format: '0070',
      },
    ],
    Cols: [
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '합계\n금액', '합계\n금액'],
        Name: 'amtDg',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '수량', '수량'],
        Name: 'qtyDg',
        Extend: that.$ibsheetHelper.preset.quantity,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '재료비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'matPriceDg',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '재료비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'matAmtDg',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '노무비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'labPriceDg',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '노무비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'labAmtDg',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '경비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'oprPriceDg',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '경비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'oprAmtDg',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '내역코드', '내역코드'],
        Name: 'itemSeqSi',
        Type: 'Text',
        Width: 70,
        CanEdit: 0,
        RowMerge: false,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '내역명', '내역명'],
        Name: 'itemNameSi',
        Type: 'Text',
        Width: 200,
        CanEdit: 0,
        RowMerge: false,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '규격', '규격'],
        Name: 'ssizeSi',
        Type: 'Text',
        Width: 80,
        CanEdit: 0,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '단위', '단위'],
        Name: 'unitSi',
        Type: 'Text',
        Width: '80',
        CanEdit: 0,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '합계\n금액', '합계\n금액'],
        Name: 'execItemAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '수량', '수량'],
        Name: 'execItemQty',
        Extend: that.$ibsheetHelper.preset.quantity,
        CanEdit: 0,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '재료비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'matPriceSi',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEdit: 0,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '재료비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'matAmtSi',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '노무비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'labPriceSi',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEdit: 0,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '노무비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'labAmtSi',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '장비비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'equipPriceSi',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEdit: 0,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '장비비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'equipAmtSi',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '경비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'oprPriceSi',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEdit: 0,
      },
      {
        Header: [{Value: '실행배분내역', Color: '#7ed6df'}, '경비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'oprAmtSi',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
    ],
  };
  if (that.isSimpleExec) {
    options.RightCols = [
      {
        Header: [{Value: '도급실행내역 GAP', Color: '#7ed6df'}, '도급합계금액', '도급합계금액'],
        Name: 'dgSumAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
        Visible: that.isSimpleExec,
      },
      {
        Header: [{Value: '도급실행내역 GAP', Color: '#7ed6df'}, '실행합계금액', '실행합계금액'],
        Name: 'siSumAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
        Visible: that.isSimpleExec,
      },
      {
        Header: [{Value: '도급실행내역 GAP', Color: '#7ed6df'}, '대비', '대비'],
        Name: 'dgSiRate',
        Extend: that.$ibsheetHelper.preset.rate,
        CanEdit: 0,
        Visible: that.isSimpleExec,
      },
    ];
  }
  return options;
};
