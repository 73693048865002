export default that => {
  let preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      CanEdit: 1,
    },
    LeftCols: [
      {Header: '공수', Name: 'investQty1', Extend: preset.quantity, Width: 60, Align: 'Center', CanEdit: 0},
      {Header: '단가', Name: 'price1', Extend: preset.unitAmount, RelWidth: 1, MinWidth: 150, CanEdit: 0},
      {Header: '금액', Name: 'amt1', Extend: preset.amount, RelWidth: 1, MinWidth: 150, CanEdit: 0},
    ],
    Cols: [
      {
        Name: ' ',
        Type: 'Text',
        Width: 5,
        Align: 'Center',
        CanEdit: 0,
      },
    ],
    RightCols: [
      {Header: '공수', Name: 'investQty2', Extend: preset.quantity, Width: 60, Align: 'Center', CanEdit: 0},
      {Header: '단가', Name: 'price2', Extend: preset.unitAmount, RelWidth: 1, MinWidth: 150, CanEdit: 0},
      {Header: '금액', Name: 'amt2', Extend: preset.amount, RelWidth: 1, MinWidth: 150, CanEdit: 0},
    ],
  };
};
