export default that => {
  return {
    Cfg: {
      CanEdit: false,
    },
    Cols: [
      {
        Header: '중공종명',
        Name: 'mgName',
        Type: 'Text',
        Width: 140,
      },
      {
        Header: '내역코드',
        Name: 'itemSeq',
        Type: 'Text',
        Align: 'Center',
        Width: 90,
      },
      {
        Header: '내역명',
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 140,
      },
      {
        Header: '규격',
        Name: 'ssize',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 140,
      },
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Align: 'Center',
        Width: 60,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: '수량',
        Name: 'qty',
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 80,
      },
      {
        Header: '금액',
        Name: 'amt',
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 110,
      },
      {Name: 'wbsCd', Visible: false},
      {Name: 'wbsId', Visible: false},
    ],
  };
};
