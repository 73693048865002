import CODE from '@/constants/codeConstants.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
export default that => {
  return {
    Cfg: {CanEdit: 0, SpaceForDefaultValue: 1},
    Def: {
      Row: {NoColor: 1},
    },
    Cols: [
      {Header: '공고회사', Name: 'orgNm', Type: 'Text', RelWidth: 0.8, MinWidth: 100},
      {
        Header: '공고번호',
        Name: 'rfqNo2',
        Type: 'Text',
        Align: 'center',
        Width: 110,
        Formula: fr => COMMON_FUNCTION.getMakeFormat(fr.Row['rfqNo']),
      },
      {Header: '공고명', Name: 'ttl', Type: 'Text', RelWidth: 1, MinWidth: 100},
      {Header: '현장명', Name: 'spotDeptnm', Type: 'Text', RelWidth: 1, MinWidth: 100},
      {
        Header: '진행상태',
        Name: 'rfqSt',
        Type: 'Text',
        Align: 'center',
        Width: 70,
        Format: $getConstants('BIDDING_ST').code,
        Formula: fr =>
          fr.Row[fr.Col] === BIDDING_CONSTANTS.PROGRESS_STATE.RFQ_COMPLETE
            ? fr.Row['resultYn'] === BIDDING_CONSTANTS.CODES.RESULT_Y
              ? '낙찰'
              : '탈락'
            : fr.Row[fr.Col],
      },
      {Header: '일시', Name: 'stateDt', Type: 'Text', Align: 'center', Width: 140},
      {
        Header: '현설일시',
        Name: 'spotDscrDt',
        Type: 'Text',
        Align: 'center',
        Width: 140,
        DefaultValue: '-',
        Visible: false,
      },
      {Header: '마감일시', Name: 'bidExpireDt', Type: 'Text', Align: 'center', Width: 140, Visible: false},
    ],
  };
};
