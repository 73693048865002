<template>
  <pmis-content-box :loading="loading">
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onAfterChange}"
    />
  </pmis-content-box>
</template>
<script>
import options from './sheetOption/GrpMenuAuthListSheet.js';
import {selectUserMenuAuthList} from '../api/userAuth.js';
export default {
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      loading: false,
    };
  },

  beforeCreate() {
    $mapGetters(this, ['empNo']);
  },

  created() {
    this.addEvent([
      {name: 'GrpMenuAuthList_selectUserMenuAuthList', func: this.onSearch},
      {name: 'GrpMenuAuthList_grpMenuAuthSaveJson', func: this.grpMenuAuthSaveJson},
    ]);
  },

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onAfterChange(e) {
      if (e.col == 'vwYn' && e.val == $getConstants('USE_YN_N').code) {
        e.sheet.setValue(e.row, 'svYn', $getConstants('USE_YN_N').code, 1);
        e.sheet.setValue(e.row, 'prnYn', $getConstants('USE_YN_N').code, 1);
      }

      e.sheet.getChildRows(e.row).some(item => {
        if (e.row.Level == 1) {
          if (e.row.mnuCd != item.uppMnuCd) {
            return true;
          }
        }

        if (e.col == 'vwYn') {
          if (e.val == $getConstants('USE_YN_N').code) {
            e.sheet.setValue(item, 'vwYn', e.val, 1);
            e.sheet.setValue(item, 'svYn', e.val, 1);
            e.sheet.setValue(item, 'delYn', e.val, 1);
            e.sheet.setValue(item, 'prnYn', e.val, 1);
          } else {
            e.sheet.setValue(item, 'vwYn', e.val, 1);
          }
        } else {
          e.sheet.setValue(item, e.col, e.val, 1);
        }
      });
    },
    async onSearch() {
      this.loading = true;
      const response = await selectUserMenuAuthList({empNo: this.empNo});
      let items = $_treeModel(response.data, 'PGM000', 'mnuCd', 'uppMnuCd');
      this.loadSearchData = items;
      this.loading = false;
    },
    grpMenuAuthSaveJson(callback) {
      if (callback) {
        callback(this.sheet.getSaveJson().data);
      }
    },
  },
};
</script>

<style></style>
