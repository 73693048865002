<template>
  <pmis-tab-box ref="prjList">
    <div class="prj_nav_wrap">
      <a href="#" class="btn_big_close" @click.prevent.self @click="openToggle" />
      <span class="blind">열고/닫기</span>
    </div>
    <template v-slot:title>현장목록</template>
    <pmis-content-box>
      <template v-slot:header-left>
        <iui-text type="search" :value.sync="searchInfo.searchNm" @enter="onInitSeach" />
        <iui-button @click="onInitSeach" value="검색" />
        <iui-checkbox-group
          name="cbxCloseSiteYN"
          :items="[{label: '종료포함', value: 'Y'}]"
          @change="
            event => {
              searchInfo.endCntrInc = event.target.checked ? 'Y' : 'N';
              onInitSeach();
            }
          "
        />
      </template>
      <ib-sheet
        :uid="_uid"
        :options="Options"
        :loadSearchData="loadSearchData"
        @loadSheet="loadSheet"
        :events="{
          onClick,
          onSearchFinish,
          onRenderFirstFinish,
        }"
      />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import options from './sheetOptions/PrjListSheet.js';
import {validSheet} from '@/util/validUtil';

export default {
  props: {
    isAll: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      searchInfo: {
        searchNm: '',
        endCntrInc: 'N',
      },
      defaultColCssText: '',
    };
  },
  created() {
    this.addEvent([{name: 'prjList_onSearch', func: this.onInitSeach}]);
  },
  activated() {
    this.$nextTick(() => this.onActivatedFocus());
  },
  mounted() {
    if (this.$refs.prjList.$parent.$parent.$el.className == 'col') {
      this.defaultColCssText = this.$refs.prjList.$parent.$parent.$el.style.cssText;
    }
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    async onSearch() {
      this.callEvent({name: 'showLoading'});
      try {
        await this.$store.dispatch('project/search', {
          ...this.searchInfo,
          pgmCd: this.pgmCd,
          isAll: this.isAll,
        });
        this.callEvent({name: 'hideLoading'});
      } catch (error) {
        this.callEvent({name: 'hideLoading'});
        console.error(error);
      }
    },
    async onRenderFirstFinish() {
      const prjList = this.$store.getters['project/prjList'];
      if (!prjList.length) {
        this.onInitSeach();
      } else {
        this.onLoad();
      }
    },
    onLoad() {
      const prjList = cloneDeep(this.$store.getters['project/prjList']);

      if (this.isAll) {
        prjList.unshift({prjCd: '', prjNm: '전체'});
      }
      this.loadSearchData = prjList;
    },
    async onInitSeach() {
      this.$store.dispatch('project/init');
      this.$store.commit('setPrjCd', '');

      await this.onSearch();
      this.onLoad();
    },
    onClick(e) {
      e.row.CanFocus = 0;
      if (e.row.Kind === 'Data') {
        if (validSheet()) {
          this.$confirm({
            title: '현장',
            message: '변경된 사항이 있습니다.<br/>다른 현장을 조회하시겠습니까?',
          }).then(isConfirm => {
            if (isConfirm) {
              e.row.CanFocus = 1;
              e.sheet.focus(e.row);
              this.setPrjData(e.row);
              this.callEvent({name: 'setCud', param: 0});
              this.$store.dispatch('project/runFunc');
            }
          });
        } else {
          e.row.CanFocus = 1;
          e.sheet.focus(e.row);
          this.setPrjData(e.row);
          this.callEvent({name: 'setCud', param: 0});
          this.$store.dispatch('project/runFunc');
        }
      }
    },
    onSearchFinish(e) {
      if (!this.autoFocus) {
        return;
      }
      const prjCd = this.$store.getters['prjCd'];
      let focusRow;
      if (prjCd) {
        focusRow = e.sheet.getDataRows().find(row => row.prjCd == prjCd);
      } else {
        focusRow = e.sheet.getFirstRow();
      }
      e.sheet.focus(focusRow);
      const rowData = this.rowData(focusRow);
      this.$store.commit('setPrjCd', focusRow?.prjCd ? String(focusRow.prjCd) : undefined);
      this.$store.dispatch('project/setLoadPrj', rowData);
      this.$store.dispatch('project/runFunc');

      //최초 현장 로드
      this.callEvent({
        name: 'set_searchInfo',
        param: rowData,
      });
    },
    rowData(row) {
      if (!row) return {};
      const arry = [
        'pgmCd',
        'prjCd',
        'ctrtChgTo',
        'prjNm',
        'postNo',
        'adr1',
        'adr2',
        'wcode1',
        'wcode2',
        'contcompCode',
        'contcompNm',
        'orderGroup',
        'orderCode',
        'orderNm',
        'workKind',
        'ctrtDate',
        'ctrtAmt',
        'ctrtVat',
        'ctrtTaxexe',
        'ctrtStrdate',
        'ctrtEnddate',
        'conCmplDate',
        'taxRt',
        'acptCnt',
        'acptDay1',
        'acptDay2',
        'prjLocLong',
        'prjLocLati',
        'prjLocAppro',
        'empNo1',
        'empNo2',
        'empNo3',
        'flNo',
        'delYn',
        'prjFlag',
        'rmk',
        'rgprId',
        'rgsDttm',
        'ctrtSumAmt',
        'lstSumAmt',
        'lstCtrtDateFrom',
        'lstCtrtDateTo',
        'conPeriod',
        'conPeriodCnt',
        'endCntrInc',
        'searchSe',
        'searchNm',
        'empNo1Name',
        'empNo2Name',
        'empNo3Name',
        'conCmplYn',
        'lastChgTo',
        'chgListYn',
      ];
      const obj = {};
      arry.forEach(
        item =>
          (obj[item] = ['pgmCd', 'prjCd', 'orderCode', 'adr2'].indexOf(item) != -1 ? String(row[item]) : row[item])
      );
      return obj;
    },
    onChangeClose(event) {
      this.searchInfo.endCntrInc = event.target.checked ? 'Y' : 'N';
      this.onSearch();
    },

    openToggle(e) {
      if (e.target.classList == 'btn_big_close') {
        e.target.classList = 'btn_big_open';
        e.target.closest('.col').style['flex-grow'] = '0';
        e.target.closest('.col').style['flex-shrink'] = '0';
        e.target.closest('.col').style['flex-basis'] = '0px';
        e.target.closest('.col').style.transition = 'all 0.5s ease';

        if (e.target.closest('.col').nextElementSibling.className == 'spacer') {
          e.target.closest('.col').nextElementSibling.style = 'flex: 0 0 0px;';
        }
      } else {
        e.target.classList = 'btn_big_close';
        e.target.closest('.col').style = this.defaultColCssText + ' transition: all 0.5s ease';
        if (e.target.closest('.col').nextElementSibling.className == 'spacer') {
          e.target.closest('.col').nextElementSibling.style = '';
        }
      }
    },

    setPrjData(row) {
      const rowData = this.rowData(row);
      this.$store.commit('setPrjCd', String(row.prjCd));
      this.$store.dispatch('project/setLoadPrj', rowData);
      this.callEvent({
        name: 'set_searchInfo',
        param: rowData,
      });
    },

    onActivatedFocus() {
      if (validSheet()) {
        return;
      }
      const prjCd = this.$store.getters['prjCd'] ?? '';

      if (prjCd != this.sheet?.getFocusedRow()?.prjCd) {
        const focusRow = this.sheet?.getDataRows().find(row => row.prjCd == prjCd);
        if (!focusRow) {
          return;
        }
        this.sheet?.focus(focusRow);
        this.setPrjData(focusRow);
        this.$store.dispatch('project/runFunc');
      }
    },
  },
};
</script>

<style scoped>
.prj_nav_wrap {
  position: absolute;
  top: 229px;
  right: -15px;
  z-index: 100;
  transition: all 0.5s ease;
}

.prj_nav_wrap .btn_big_close {
  display: block;
  width: 39px;
  height: 39px;
  background: url('../../../assets/img/btn_nav_close.png') no-repeat;
}

.prj_nav_wrap .btn_big_open {
  display: block;
  width: 39px;
  height: 39px;
  background: url('../../../assets/img/btn_nav_open.png') no-repeat;
}
</style>
