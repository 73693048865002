<template>
  <pmis-content-box>
    <template #title>제출서류목록</template>

    <template #title-right>
      <template v-if="poStatus.isEnable">
        <iui-button value="추가" @click="openNeedDocumentModal" />
        <iui-button value="삭제" @click="onDeleteNeedDocument" />
      </template>

      <template v-if="poStatus.isPoSignComplete && !poStatus.isSuFileAllPass">
        <iui-button value="심사결과등록" @click="onSaveFileApprovalYn" />
      </template>
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="poSubmitDocList"
      @loadSheet="sheet = $event"
      :events="{onSearchFinish: sheet_onSearchFinish}"
    />

    <iui-file-interface :enable="poStatus.isAfterPoSignComplete" :enableUpload="false" mappedBy="fileCd" />

    <iui-modal name="needDocumentModal" :title="'제출서류목록'" size-type="size2">
      <PoNeedDocument />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/poSubmitDocList.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import {updateSuFileApprovalResult} from '@/view/bidding/po/mat/api/po.js';
import {updateSuFileApprovalResult as updateSubSuFileApprovalResult} from '@/view/bidding/po/sub/api/subPo.js';

import PoNeedDocument from './PoNeedDocument.vue';
export default {
  components: {PoNeedDocument},
  data() {
    return {sheetOpt: $addColCheckbox(sheetOpt(this))};
  },
  watch: {
    poStatus: {
      handler(v, ov) {
        if (v.isEnable !== ov.isEnable) {
          this.sheet.setAttribute(null, 'chk', 'Visible', v.isEnable, 1);
        }
      },
      deep: true,
    },
  },
  beforeCreate() {
    $mapGetters(this, ['bidType', 'poInfo', 'poSubmitDocList', 'poStatus']);
    $mapMutations(this, ['setPoSubmitDocList']);
    $mapActions(this, ['searchPoInfos']);
  },
  created() {
    this.addEvent({name: 'PoSubmitDocList_GetSaveJson', func: this.getSaveJson});
  },
  methods: {
    openNeedDocumentModal() {
      this.$modal.show('needDocumentModal');
    },
    onDeleteNeedDocument() {
      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (!checkedRows.length) {
        this.$alert({title: '제출서류 삭제', message: '선택된 항목이 없습니다.'});
        return;
      }

      this.sheet.removeRows(checkedRows);
      this.setPoSubmitDocList(this.sheet.getDataRows().map(item => ({fileNm: item.fileNm})));
    },
    getSaveJson(callback) {
      if (typeof callback === 'function') {
        callback(this.sheet.getSaveJson(0).data);
      }
    },
    async onSaveFileApprovalYn() {
      const rows = this.sheet.getDataRows();

      let isFail = false;
      let validationCheck = true;

      rows.forEach(row => {
        if (!row.approvalYn) {
          validationCheck = false;
        }
        if (row.approvalYn === BIDDING_CONSTANTS.CODES.PO_SUFILE_APPROVAL_N) {
          isFail = true;
        }
      });

      if (!validationCheck) {
        this.$alert({title: '서류심사', message: '심사결과가 입력되지 않은 항목이 있습니다.'});
        return;
      }

      const passMessage = '서류심사결과를 등록하시겠습니까?';
      const failMessage = `심사결과 부적합 항목이 있습니다.
      <br/><br/>해당 항목에 대하여 협력회사에게
      <br/>서류 재등록을 요청합니다.
      <br/><br/>모든 항목이 적합으로 등록되면
      <br/>전자서명이 가능합니다.
      <br/><br/>${passMessage}`;

      const title = '서류심사결과 등록';
      const message = isFail ? failMessage : passMessage;

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      const approvalResult = rows.map(row => ({
        poNo: row.poNo,
        contSeq: row.contSeq,
        fileSeq: row.fileSeq,
        approvalYn: row.approvalYn,
      }));

      const updateSuFileApprovalResultApi = {mat: updateSuFileApprovalResult, sub: updateSubSuFileApprovalResult};
      updateSuFileApprovalResultApi[this.bidType]({approvalResult}).then(response => {
        this.searchPoInfos({poNo: this.poInfo.poNo, contSeq: this.poInfo.contSeq});
      });
    },
    sheet_onSearchFinish(e) {
      this.callEvent({name: 'IuiFileInterface_init', param: e.sheet});

      if (this.poStatus.isPoSignComplete && !this.poStatus.isSuFileAllPass) {
        e.sheet.addCol({
          name: 'approvalYn',
          pos: -1,
          param: {
            Header: '서류심사',
            Type: 'Radio',
            Enum: '|적합|부적합',
            EnumKeys: `|${BIDDING_CONSTANTS.CODES.PO_SUFILE_APPROVAL_Y}|${BIDDING_CONSTANTS.CODES.PO_SUFILE_APPROVAL_N}`,
            Width: 150,
          },
          visible: 1,
        });
      } else {
        e.sheet.removeCol('approvalYn');
      }
    },
  },
};
</script>

<style></style>
