<template>
  <pmis-content-box>
    <template #title>기본정보</template>
    <form onsubmit="return false;">
      <iui-container-new type="table" theme="bullet">
        <colgroup>
          <col width="90px" />
          <col width="100%" />
          <col width="10px" />
          <col width="80px" />
          <col width="60%" />
          <col width="10px" />
          <col width="80px" />
          <col width="60%" />
        </colgroup>
        <i-row>
          <template v-if="poIsNew && !poStatus.isExistRfq">
            <i-col-header required>현장</i-col-header>
            <i-col>
              <iui-searchbox
                ref="prjSearchbox"
                type="prj"
                :idValue="poInfo.spotDeptcd"
                :nameValue="poInfo.spotDeptnm"
                :callback="onCallbackPrjCd"
                required
                :errorMessage="{title: '현장', message: '필수입력입니다.'}"
              />
            </i-col>
            <i-spacer />
            <i-col-header required>계약자</i-col-header>
            <i-col colspan="4">
              <iui-searchbox
                type="customer"
                :paramObj="{frmDs: $getConstants('FRM_DS_WORK_GROUP').code}"
                :callback="e => setPoInfo({cusCd: e.bsnNo})"
                required
                :errorMessage="{title: '계약자', message: '필수입력입니다.'}"
              />
            </i-col>
          </template>
          <template v-else>
            <i-col-header required>현장</i-col-header>
            <i-col>
              <iui-text :value="poInfo.spotDeptcd" :enable="false" width="100px" />
              <iui-text :value="poInfo.spotDeptnm" :enable="false" />
            </i-col>
            <i-spacer />
            <i-col-header required>계약자</i-col-header>
            <i-col colspan="4">
              <iui-text :value="poInfo.cusCd" :enable="false" width="80px" />
              <iui-text :value="poInfo.cusNm" :enable="false" />
            </i-col>
          </template>
        </i-row>
        <i-row>
          <i-col-header required>계약번호</i-col-header>
          <i-col>
            <iui-text :value="poInfo.poNo2" :enable="false" width="100px" />
            <iui-text
              ref="ttl"
              :key="`ttlKey_${ttlKey}`"
              :value="poInfo.ttl"
              @input="onInput_ttl"
              :enable="poStatus.isEnable && !!poInfo.spotDeptcd && poStatus.isFirstContSeq"
              :required="poStatus.isEnable"
              :errorMessage="{title: '계약명', message: '필수입력입니다.'}"
              :placeholder="poIsNew && !poInfo.spotDeptcd ? '현장을 선택해주세요' : ''"
              max-length="500"
            />
          </i-col>
          <i-spacer />
          <i-col-header required>계약일자</i-col-header>
          <i-col>
            <iui-datepicker
              :value="poInfo.poDt"
              :enable="poStatus.isEnable"
              :required="poStatus.isEnable"
              :errorMessage="{title: '계약일자', message: '필수입력입니다.'}"
              @change="setPoInfo({poDt: $event})"
            />
          </i-col>
          <i-spacer />
          <i-col-header required>공사기간</i-col-header>
          <i-col>
            <iui-datepicker
              :value="poInfo.contFrDt"
              :enable="poStatus.isEnable"
              :required="poStatus.isEnable"
              :errorMessage="{title: '공사기간', message: '필수입력입니다.'}"
              @change="setPoInfo({contFrDt: $event})"
            />
            <label>~</label>
            <iui-datepicker
              :value="poInfo.contToDt"
              :enable="poStatus.isEnable"
              :required="poStatus.isEnable"
              :errorMessage="{title: '공사기간', message: '필수입력입니다.'}"
              @change="setPoInfo({contToDt: $event})"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>계약내역</i-col-header>
          <i-col :rowspan="poItemListRowspan" colspan="7">
            <PoItemList />
          </i-col>
        </i-row>
        <i-row v-for="i in poItemListRowspan - 1" :key="i">
          <i-col />
        </i-row>
      </iui-container-new>
    </form>

    <!-- 당초계약 -->
    <PoFirstContSeq v-if="!poStatus.isChangeContSeq" />
    <!-- 변경계약 -->
    <PoChangeContSeq v-else />

    <iui-container-new type="css-flex" height="200px">
      <i-spacer />
      <i-row>
        <i-col :width="poStatus.isPoSignComplete ? '60%' : '50%'">
          <!-- 제출서류목록 -->
          <PoSubmitDocList />
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-file-list
            id="subPoContractFile"
            title="전자서명 파일"
            title-visible
            :toolbar-visible="poStatus.isEnable"
            :company-code="poInfo.orgCd"
            :project-code="poInfo.spotDeptcd"
            :fbsNo="FBS_NO"
            :fileNumber="poInfo.flNo"
            :searchData="{flDsOrderList: [$getConstants('FL_DS_CONTRACT_PDF').code]}"
            @delete-complete="onDeleteCompleteFile"
          />
        </i-col>
      </i-row>
    </iui-container-new>

    <pmis-content-box v-if="poStatus.isExistRfq" style="height: 100px;">
      <template #title>입찰정보</template>
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header>입찰공고번호</i-col-header>
          <i-col width="30%">
            <iui-text
              width="120px"
              :value="rfqInfo.rfqNo2"
              @click="showPopup_RfqInfo"
              :css="clickable_inputStyle"
              readOnly
            />
            <iui-text :value="rfqInfo.ttl" :enable="false" />
          </i-col>
          <i-spacer />
          <i-col-header>입찰차수</i-col-header>
          <i-col>
            <iui-text
              align="center"
              width="80px"
              :value="rfqInfo.bidRnd ? rfqInfo.bidRnd + '차' : ''"
              :enable="false"
            />
          </i-col>
          <i-spacer />
          <i-col-header>공고품의서</i-col-header>
          <i-col>
            <iui-text
              width="80px"
              :value="rfqInfo.rfqSncNo"
              @click="openCompletedRfqApproval"
              :css="clickable_inputStyle"
              readOnly
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>낙찰일자</i-col-header>
          <i-col>
            <iui-text align="center" width="100px" :value="rfqInfo.endDt" :enable="false" />
          </i-col>
          <i-spacer />
          <i-col-header>낙찰금액</i-col-header>
          <i-col>
            <iui-text type="amount" width="150px" :value="rfqInfo.lastTotAmt" :enable="false" />
            <label class="font8pt">(부가세포함)</label>
          </i-col>
        </i-row>
      </iui-container-new>
    </pmis-content-box>

    <iui-modal name="rfqInfoModal" title="입찰정보" size-type="size6">
      <RfqInfo :rfqNo="rfqInfo.rfqNo" />
    </iui-modal>
    <ApprovalPop :parentId="_uid" />
  </pmis-content-box>
</template>

<script>
import PoInfoMixin from '@/view/bidding/po/common/mixins/poInfoMixin.js';
import EDMS_CONSTANTS from '@/view/edms/common/EdmsConstatns.js';

import PoItemList from './PoItemList.vue';
import PoFirstContSeq from './PoFirstContSeq.vue';
import PoChangeContSeq from './PoChangeContSeq.vue';
import RfqInfo from './RfqInfo.vue';

export default {
  components: {PoItemList, PoFirstContSeq, PoChangeContSeq, RfqInfo},
  mixins: [PoInfoMixin],
  data() {
    return {
      FBS_NO: EDMS_CONSTANTS.SUB_PO.FBS_NO,
      poItemListRowspan: 8,
    };
  },
  methods: {
    onValidateSave(poItemList = []) {
      // 계약내역
      if (!poItemList.length) {
        this.$alert({title: '계약내역', message: '계약내역이 존재하지 않습니다.'}, () => {
          this.callEvent({name: 'PoItemList_OpenExecutionModal'});
        });
        return false;
      }

      // 대금지급방법
      if (this.poStatus.isPayCash) {
        const r1 = parseFloat(this.poInfo.payCashRatio);
        const r2 = parseFloat(this.poInfo.payCheckRatio);
        const r3 = parseFloat(this.poInfo.payCheck2Ratio);
        const ratio = r1 + r2 + r3;
        if (ratio != 100) {
          this.$alert({
            title: '대금지급방법',
            message: '대금지급방법(현금, 어음, 어음대체수단)의 비율의 합은 100% 이어야 합니다.',
          });
          return false;
        }
      }

      // 담보기간
      if (this.poStatus.isFlawInsrMonth) {
        if (this.poInfo.flawInsrFrDt > this.poInfo.flawInsrToDt) {
          this.$alert({title: '책임기간', message: '책임기간을 다시 한 번 확인하시기 바랍니다.'});
          return false;
        }
      }

      return true;
    },
  },
};
</script>

<style></style>
