<template>
  <pmis-content-box>
    <iui-container-new type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    visibleCols: {
      type: Array,
      default: () => ['prjEstCd', 'prjEstNm'],
    },
    uid: {
      type: Number,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      searchNm: '',
    };
  },

  beforeCreate() {
    $mapGetters(this, {
      searchInfo: 'searchInfo',
    });
  },

  created() {
    this.addEvent([
      {name: 'selectPrjEstMList', func: this.selectPrjEstMList},
      {name: 'onFocusedRowData', func: this.onFocusedRowData},
    ]);
  },

  mounted() {
    this.initSheet();
  },
  methods: {
    initSheet() {
      const OPT = {
        //각 컬럼에 대한 정의 (컬럼의 이름,유형(Type),포맷(Format)등을 설정)
        //컬럼의 "Type"과 "Name" 속성은 반드시 설정되어야 합니다.
        Cols: [
          {
            Header: '현장코드',
            Name: 'prjEstCd',
            Type: 'Text',
            Width: 70,
            Align: 'Center',
            Visible: 1,
          },
          {
            Header: '현장명',
            Name: 'prjEstNm',
            Type: 'Text',
            RelWidth: 1,
            Visible: 1,
          },
        ],
        Cfg: {
          CanEdit: 0,
        },
        Events: {
          onDblClick: e => {
            if (e.row.Kind == 'Data') {
              this.callEvent({name: `prjEstSearchOnConfirm${this.uid}`});
            }
          },
        },
      };

      PMISSheet.createSheet({
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      }).then(sheet => (this.sheet = sheet));
    },

    selectPrjEstMList(obj) {
      let param = {
        pgmCd: this.pgmCd,
        searchNm: this.searchNm,
      };
      if (typeof obj == 'object') param = obj;

      axios
        .post('/estmtPrjRegist/selectPrjEstMList', param)
        .then(response => {
          this.sheet.loadSearchData(Array.from(response.data), 0);
        })
        .catch(ex => {
          console.log('ERR!!!!! : ', ex);
        });
    },

    onFocusedRowData(param) {
      setTimeout(() => {
        let row = this.sheet.getFocusedRow();
        if (typeof param == 'function') {
          param(row);
        }
      }, 10);
    },
    setSearchNm(value) {
      this.searchNm = value;
    },
  },
};
</script>

<style></style>
