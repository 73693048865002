/**
 * ex) this.$store.getters['code/함수명']('공통코드명', '파라미터...')
 * 장비단가구분 : priceEGb
 * 사용구분 : useGb
 * 거래구분 : accountGb
 * 지급자재여부 : matGiveYn
 * 변경구분 : chgGb
 */
const state = {
  priceEGb: {
    '000001': '[O] 시간당',
    '000002': '[O] 일당',
    '000003': '[O] 월당',
    '000004': '[O] 횟수',
  },
  useGb: {
    '000001': '[O] 임차장비',
    '000002': '[O] 보유장비',
  },
  accountGb: {
    '000601': '[O] [원재료비]',
    '000602': '[O] 외주비',
    '000603': '[O] 잡급',
    '000604': '[O] 임금',
    '000605': '[O] 상여금',
    '000606': '[O] 제수당',
    '000607': '[O] 퇴직급여',
    '000608': '[O] 퇴직보험충당금전입',
    '000609': '[O] ',
    '000610': '[O] 중기및운반비',
    '000611': '[O] 복리후생비',
    '000612': '[O] 여비교통비',
    '000613': '[O] 접대비',
    '000614': '[O] 통신비',
    '000615': '[O] 가스수도료',
    '000616': '[O] 전력비',
    '000617': '[O] 세금과공과금',
    '000618': '[O] 감가상각비',
    '000619': '[O] 임차료',
    '000620': '[O] 수선비',
    '000621': '[O] 보험료',
    '000622': '[O] 차량유지비',
    '000623': '[O] 운반비',
    '000624': '[O] 잡자재대',
    '000625': '[O] 교육훈련비',
    '000626': '[O] 도서인쇄비',
    '000627': '[O] 하자보수비',
    '000628': '[O] 포장비',
    '000629': '[O] 사무용품비',
    '000630': '[O] 소모품비',
    '000631': '[O] 지급수수료',
    '000632': '[O] 보관료',
    '000633': '[O] 외주가공비',
    '000634': '[O] 장비사용료',
    '000635': '[O] 설계용역비',
    '000636': '[O] 광고선전비',
    '000637': '[O] 소모공구비',
    '000638': '[O] 외주공사비',
    '000639': '[O] 협회비',
    '000640': '[O] 잡비',
    '000641': '[O] 공사손실충당금전입',
    '000642': '[O] 공사손실충당금환입',
    '000643': '[O] ',
    '000644': '[O] ',
    '000645': '[O] 소모재료비',
    '000646': '[O] 소모공구비',
    '000647': '[O] 임차료',
    '000648': '[O] 중기임차료',
    '000649': '[O] 명예퇴직금',
    '000650': '[O] 퇴직연금충당금전입',
  },
  matGiveYn: {
    '000001': '[O] 지급자재',
  },
  chgGb: {
    '000001': '[O] 변경',
    '000002': '[O] 추가',
  },
  wcode1: {
    '000061': '[O] 실내건축',
    '000062': '[O] 토공',
    '000063': '[O] 미장∙방수∙조적',
    '000064': '[O] 석공사',
    '000065': '[O] 도장',
    '000067': '[O] 비계구조물',
    '000068': '[O] 금속구호물∙창호',
    '000069': '[O] 지붕∙판금∙건출물조립',
    '000070': '[O] 철근∙콘크리트',
    '000071': '[O] 철물',
    '000072': '[O] 기곛설비',
    '000073': '[O] 상∙하수도설비',
    '000074': '[O] 보링그라우팅',
    '000075': '[O] 철도궤도',
    '000076': '[O] 포장',
    '000077': '[O] 수중',
    '000078': '[O] 조경식재',
    '000079': '[O] 조경시설물',
    '000081': '[O] 강구조물',
    '000083': '[O] 철강재',
    '000084': '[O] 삭도설치',
    '000085': '[O] 준설',
    '000086': '[O] 승강기설치',
    '000087': '[O] 가스시설 1종',
    '000088': '[O] 가스시설 2종',
    '000089': '[O] 가스시설 3종',
    '000090': '[O] 난방 1종',
    '000091': '[O] 난방 2종',
    '000092': '[O] 난방 3종',
    '000093': '[O] 시설물유지관리',
  },
  wcode2: {
    '000610': '[O] 일반실내건축공사',
    '000611': '[O] 목재창호∙목재구조물공사',
    '000620': '[O] 일반토공사',
    '000621': '[O] 발파공사',
    '000630': '[O] 미장공사',
    '000631': '[O] 타일공사',
    '000632': '[O] 방수공사',
    '000633': '[O] 조적공사',
    '000640': '[O] 석공사',
    '000650': '[O] 일반도장공사',
    '000651': '[O] 재도장공사',
    '000652': '[O] 차선도색공사',
    '000670': '[O] 비계공사',
    '000671': '[O] 파일공사',
    '000677': '[O] 구조물해체공사',
    '000680': '[O] 창호공사',
    '000681': '[O] 금속구조물공사',
    '000682': '[O] 온실설치공사',
    '000690': '[O] 지붕판금공사',
    '000691': '[O] 건출물조립공사',
    '000700': '[O] 철근콘크리트공사',
    '000720': '[O] 건출기계설비공사',
    '000721': '[O] 플랜트기계설비공사',
    '000722': '[O] 자동제어공사',
    '000730': '[O] 상수도설비공사',
    '000731': '[O] 하수도설비공사',
    '000740': '[O] 일반보링그라우팅공사',
    '000741': '[O] 착정공사',
    '000750': '[O] 철도궤도공사',
    '000760': '[O] 일반포장공사',
    '000761': '[O] 포장유리관리공사',
    '000770': '[O] 수중공사',
    '000780': '[O] 일반조경식재공사',
    '000781': '[O] 조경유지관리공사',
    '000790': '[O] 조경시설물설치공사',
    '000810': '[O] 일반강구조물공사',
    '000811': '[O] 인도전용 강재육교철치공사',
    '000830': '[O] 일반철강재설치공사',
    '000831': '[O] 교량강철구조물제작공사',
    '000840': '[O] 삭도설치공사',
    '000841': '[O] 삭도유지관리공사',
    '000850': '[O] 준설공사',
    '000860': '[O] 일반승강기설치공사',
    '000861': '[O] 기계식주차기설치공사',
    '000870': '[O] 가스시설1종',
    '000880': '[O] 가스시설2종',
    '000890': '[O] 가스시설3종',
    '000900': '[O] 난방시공1종',
    '000910': '[O] 난방시공2종',
    '000920': '[O] 난방시공3종',
    '000930': '[O] 시설물유지관리',
  },
};

const getters = {
  priceEGb: state => state.priceEGb,
  useGb: state => state.useGb,
  accountGbSheetData: state => {
    let accountGbArr = [];
    for (let key in state.accountGb) {
      let accountGbObj = {};
      accountGbObj['accountGb'] = key;
      accountGbObj['accountGbName'] = state.accountGb[key];
      accountGbArr.push(accountGbObj);
    }
    return accountGbArr;
  },
  matGiveYn: state => state.matGiveYn,
  chgGb: state => state.chgGb,

  /**
   * state 값을 가져오는 경우 사용.
   * @param codeNm : 공통코드명
   * @param key : 공통코드 중 특정값만 가져오는 경우에 사용
   */
  getCode: state => (codeNm, key) => {
    if (key === undefined) {
      return state[codeNm];
    } else {
      return state[codeNm][key];
    }
  },

  /**
   * Iui 컴포넌트에서 쓰이는 경우 사용. (key, value명 지정)
   * @param codeNm : 공통코드명
   * @param keyNm : 키값 지정(ex. text, label...)
   */
  getCodeItems: state => (codeNm, keyNm) => {
    keyNm = keyNm === undefined ? 'text' : keyNm;

    let objArray = [];
    for (let key in state[codeNm]) {
      let obj = {};
      obj['value'] = key;
      obj[keyNm] = state[codeNm][key];
      objArray.push(obj);
    }
    return objArray;
  },
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
