<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text type="search" ref="searchNm" :value.sync="searchNm" @enter="onFocusByNm" />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onDblClick,
        onDataLoad,
        onRenderFirstFinish,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>
<script>
/**
 * 외주 거래처 목록 조회 팝업
 * 사용 : 근로자등록
 * */
import options from './sheetOption/SubconListPopupSheet.js';
import {selectSubPoCompleteList} from '@/view/bidding/po/sub/api/subPo.js';
export default {
  props: {
    uid: {
      type: Number,
    },
  },
  data() {
    return {
      Options: options(this),
      loadSearchData: [],
      searchNm: '',
    };
  },
  created() {
    this.addEvent([{name: 'SubconListPopup_callbackData', func: this.onFocusedRowData}]);
  },
  methods: {
    onFocusByNm() {
      if (this.sheet.getTotalRowCount() > 0) {
        let searchCnt = this.sheetUtil.listSearch(['cusNm'], this.searchNm);
        if (searchCnt == 0) {
          this.$alert({title: '외주', message: '해당 외주 정보가 존재하지 않습니다'});
        }
        this.$refs.searchNm.$refs.input.focus();
      }
    },
    async onSearch() {
      let param = {
        orgCd: this.pgmCd,
      };
      const response = await selectSubPoCompleteList(param);
      if (response.status == 200) {
        this.sheet.loadSearchData(response.data);
      }
    },
    onFocusedRowData(callbackFunction) {
      if (typeof callbackFunction == 'function') {
        let row = this.sheet.getFocusedRow();
        if (row == null) {
          this.$alert({title: '외주선택', message: '선택된 외주이 없습니다.'});
          return;
        }
        callbackFunction(this.sheet.getFocusedRow());
      }
    },
    onDblClick(e) {
      if (e.row.Kind == 'Data') {
        this.callEvent({name: 'onHideModal_' + this.uid});
      }
    },
    onDataLoad(e) {
      if (0 < e.sheet.getTotalRowCount()) {
        setTimeout(() => {
          this.onFocusByNm();
        }, 100);
      }
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped></style>
