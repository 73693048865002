<template>
  <pmis-tab-box>
    <template #title>입찰정보</template>
    <template #title-right>
      <iui-button value="입찰공고품의" v-if="rfqStatus.isRfqReady" @click="openRfqApproval" />
      <iui-button value="입찰예정가" v-if="canSaveBidPlanAmt" @click="openBidPlanAmtModal" />

      <iui-button value="입찰공고" v-if="rfqStatus.isRfqApprovalComplete" @click="onNoticeRfq" />
      <iui-button value="입찰공고취소" v-if="canRfqNoticeCancel" @click="onCancelNoticeRfq" />
    </template>

    <form onsubmit="return false;">
      <iui-container-new type="css-flex">
        <i-row height="315px" style="overflow: visible">
          <i-col>
            <pmis-content-box>
              <template #title>입찰정보</template>
              <iui-container-new
                type="table"
                theme="bullet"
                headerWidth="100px"
                style="position: absolute; overflow: visible"
              >
                <i-row>
                  <i-col-header required>현장</i-col-header>
                  <i-col>
                    <template v-if="rfqIsNew">
                      <iui-searchbox
                        ref="prjSearchbox"
                        type="prj"
                        :idValue="rfqInfo.spotDeptcd"
                        :nameValue="rfqInfo.spotDeptnm"
                        :callback="onCallbackPrjCd"
                        :isSearchBtn="!rfqInfo.prNo"
                        :required="rfqIsNew"
                        :errorMessage="{title: '현장', message: '필수입력입니다.'}"
                      />
                    </template>
                    <template v-else>
                      <iui-text :value="rfqInfo.spotDeptcd" :enable="false" width="100px" />
                      <iui-text :value="rfqInfo.spotDeptnm" :enable="false" />
                    </template>
                  </i-col>
                  <i-spacer />
                  <i-col-header>계약구분</i-col-header>
                  <i-col>
                    <template v-if="rfqStatus.isEnable">
                      <iui-select
                        width="90px"
                        :p-code="$getConstants('PO_TYPE_CD').code"
                        :excludeItems="[$getConstants('PO_TYPE_CD_ORDER').code, $getConstants('PO_TYPE_CD_SCPAY').code]"
                        :value="rfqInfo.poTypecd"
                        @change="value => onChange_poTypecd(value)"
                      />
                    </template>
                    <template v-else>
                      <iui-text :value="rfqInfo.poTypenm" width="90px" :enable="false" />
                    </template>
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header required>입찰제목</i-col-header>
                  <i-col>
                    <iui-text :value="rfqInfo.rfqNo2" :enable="false" :width="rfqIsNew ? '90px' : '100px'" />
                    <iui-text
                      ref="ttl"
                      :key="`ttlKey_${ttlKey}`"
                      :value="rfqInfo.ttl"
                      @input="onInput_ttl"
                      :enable="rfqStatus.isEnable && !!rfqInfo.spotDeptcd"
                      :required="rfqStatus.isEnable"
                      :errorMessage="{title: '입찰제목', message: '필수입력입니다.'}"
                      max-length="500"
                      :placeholder="rfqIsNew && !rfqInfo.spotDeptcd ? '현장을 선택해주세요' : ''"
                    />
                  </i-col>
                  <i-spacer />
                  <i-col-header required>입찰담당</i-col-header>
                  <i-col>
                    <div class="iui-searchbox">
                      <iui-text
                        width="90px"
                        :value="rfqInfo.chrgUsrcd"
                        :enable="false"
                        :required="rfqStatus.isEnable"
                        :errorMessage="{title: '입찰담당', message: '필수입력입니다.'}"
                      />
                      <iui-button
                        class="flex-0"
                        btn-class="search-button"
                        @click="openPopup('chrgUserListModal')"
                        v-if="rfqStatus.isEnable"
                      />
                      <iui-text
                        width="100px"
                        :value="rfqInfo.chrgUsrnm"
                        :enable="false"
                        :required="rfqStatus.isEnable"
                        :errorMessage="{title: '입찰담당', message: '필수입력입니다.'}"
                      />
                    </div>
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header required>현장설명유무</i-col-header>
                  <i-col>
                    <iui-checkbox-group
                      :checkedValues="[rfqInfo.spotDscrYn]"
                      :items="[{label: $getConstants('EXTERNAL_Y').name, value: $getConstants('EXTERNAL_Y').code}]"
                      @change="onChange_spotDscrYn"
                      :enable="rfqStatus.isEnable"
                    />
                  </i-col>
                  <i-spacer />
                  <i-col-header required>과세유형</i-col-header>
                  <i-col>&nbsp;</i-col>
                </i-row>
                <i-row>
                  <i-col>&nbsp;</i-col>
                  <i-col rowspan="3">
                    <iui-container-new type="table" theme="bullet" headerWidth="70px" v-if="rfqStatus.isExistSpotDscr">
                      <i-row>
                        <i-col-header required>현설일자</i-col-header>
                        <i-col>
                          <iui-datepicker
                            :value="rfqInfo.spotDscrYmd"
                            @change="onChange_spotDscrYmd"
                            :disabledDate="isBeforeToday"
                            :enable="rfqStatus.isExistSpotDscr && rfqStatus.isEnable"
                            :required="rfqStatus.isExistSpotDscr && rfqStatus.isEnable"
                            :errorMessage="{title: '현설일자', message: '필수입력입니다.'}"
                          />
                          <iui-timepicker
                            width="30px"
                            :value="getTime(rfqInfo.spotDscrHh, rfqInfo.spotDscrMm)"
                            @change="onChangeTime('spotDscr', $event)"
                            :enable="rfqStatus.isExistSpotDscr && rfqStatus.isEnable"
                            :required="rfqStatus.isExistSpotDscr && rfqStatus.isEnable"
                            :errorMessage="{title: '현설일자', message: '필수입력입니다.'}"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header required>현설장소</i-col-header>
                        <i-col>
                          <iui-text
                            :value="rfqInfo.spotDscrLoc"
                            :enable="rfqStatus.isExistSpotDscr && rfqStatus.isEnable"
                            :required="rfqStatus.isExistSpotDscr && rfqStatus.isEnable"
                            :errorMessage="{title: '현설장소', message: '필수입력입니다.'}"
                            @change="setRfqInfo({spotDscrLoc: $event.target.value})"
                            max-length="500"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header required>현설담당</i-col-header>
                        <i-col>
                          <div class="iui-searchbox">
                            <iui-text
                              width="90px"
                              :value="rfqInfo.spotDscrUsrcd"
                              :enable="false"
                              :required="rfqStatus.isExistSpotDscr && rfqStatus.isEnable"
                              :errorMessage="{title: '현설담당', message: '필수입력입니다.'}"
                            />
                            <iui-button
                              class="flex-0"
                              btn-class="search-button"
                              @click="openPopup('spotDscrUserListModal')"
                              v-if="rfqStatus.isExistSpotDscr && rfqStatus.isEnable"
                            />
                            <iui-text
                              width="100px"
                              :value="rfqInfo.spotDscrUsrnm"
                              :enable="false"
                              :required="rfqStatus.isExistSpotDscr && rfqStatus.isEnable"
                              :errorMessage="{title: '현설담당', message: '필수입력입니다.'}"
                            />
                          </div>
                        </i-col>
                      </i-row>
                    </iui-container-new>
                  </i-col>
                  <i-spacer />
                  <i-col colspan="2">
                    <!-- 과세/비과세 -->
                    <iui-radio-group
                      class="ml30"
                      :p-code="$getConstants('TAX_TYPE_CD').code"
                      :excludeItems="[$getConstants('TAX_TYPE_CD_DIRECT').code]"
                      :value="rfqInfo.taxTypecd"
                      @change="onChange_taxType('taxTypecd', $event.target.value)"
                      :enable="rfqStatus.isEnable"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col>&nbsp;</i-col>
                  <i-spacer />
                  <i-col colspan="2">
                    <!-- 직접입력 -->
                    <iui-radio-group
                      class="ml30"
                      :p-code="$getConstants('TAX_TYPE_CD').code"
                      :excludeItems="[$getConstants('TAX_TYPE_CD_EXIST').code, $getConstants('TAX_TYPE_CD_NONE').code]"
                      :value="rfqInfo.taxTypecd"
                      @change="onChange_taxType('taxTypecd', $event.target.value)"
                      :enable="rfqStatus.isEnable"
                    />
                    <template v-if="rfqStatus.isTaxDirect">
                      <iui-select
                        :p-code="$getConstants('TAX_DIRECT_DS').code"
                        :value="rfqInfo.taxDirectDs"
                        :enable="rfqStatus.isTaxDirect && rfqStatus.isEnable"
                        @change="value => onChange_taxType('taxDirectDs', value)"
                      />
                      <iui-text
                        type="rate"
                        :value="rfqInfo.taxTypeDirectPercent"
                        :enable="rfqStatus.isTaxDirect && rfqStatus.isTaxDirectPercent && rfqStatus.isEnable"
                        :required="rfqStatus.isTaxDirect && rfqStatus.isTaxDirectPercent && rfqStatus.isEnable"
                        :errorMessage="{title: '과세율', message: '필수입력입니다.'}"
                        @change="onChange_taxType('taxTypeDirectPercent', $event.target.value)"
                        width="80px"
                      />
                      <iui-text
                        type="amount"
                        :value="rfqInfo.taxTypeDirectAmt"
                        :enable="rfqStatus.isTaxDirect && !rfqStatus.isTaxDirectPercent && rfqStatus.isEnable"
                        :required="rfqStatus.isTaxDirect && !rfqStatus.isTaxDirectPercent && rfqStatus.isEnable"
                        :errorMessage="{title: '과세금액', message: '필수입력입니다.'}"
                        @change="onChange_taxType('taxTypeDirectAmt', $event.target.value)"
                        width="120px"
                      />
                    </template>
                  </i-col>
                </i-row>
                <i-row>
                  <i-col>&nbsp;</i-col>
                  <i-spacer />
                  <i-col-header required>납품조건</i-col-header>
                  <i-col>
                    <iui-select
                      :p-code="$getConstants('DLV_COND_RMRK_CD').code"
                      :excludeItems="[
                        $getConstants('DLV_COND_RMRK_CD_CONSTRUCTION').code,
                        $getConstants('DLV_COND_RMRK_CD_SERVICE').code,
                      ]"
                      defaultCd="S"
                      :value="rfqInfo.dlvCondRmrk"
                      @change="setRfqInfo({dlvCondRmrk: $event})"
                      :enable="rfqStatus.isEnable"
                      :required="rfqStatus.isEnable"
                      :errorMessage="{title: '납품조건', message: '필수입력입니다.'}"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header required>입찰마감일시</i-col-header>
                  <i-col>
                    <iui-datepicker
                      :value="rfqInfo.bidExpireYmd"
                      @change="setRfqInfo({bidExpireYmd: $event})"
                      :disabledDate="disabledBidExpireDate"
                      :enable="rfqStatus.isEnable"
                      :required="rfqStatus.isEnable"
                      :errorMessage="{title: '입찰마감일시', message: '필수입력입니다.'}"
                    />
                    <iui-timepicker
                      width="30px"
                      :value="getTime(rfqInfo.bidExpireHh, rfqInfo.bidExpireMm)"
                      @change="onChangeTime('bidExpire', $event)"
                      :enable="rfqStatus.isEnable"
                      :required="rfqStatus.isEnable"
                      :errorMessage="{title: '입찰마감일시', message: '필수입력입니다.'}"
                    />
                  </i-col>
                  <i-spacer />
                  <i-col-header>등록일자</i-col-header>
                  <i-col>
                    <iui-text :value="rfqInfo.rgsDate" width="90px" :enable="false" />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header required>지급조건</i-col-header>
                  <i-col>
                    <label>현금</label>
                    <iui-text
                      type="rate"
                      suffix=""
                      width="60px"
                      :value="rfqInfo.payCashRatio"
                      @change="onChange_payRatio('Cash', $event.target.value)"
                      :enable="rfqStatus.isEnable"
                      :required="rfqStatus.isEnable"
                      :errorMessage="{title: '지급조건', message: '필수입력입니다.'}"
                    />
                    <label class="mr15">%</label>
                    <label>어음</label>
                    <iui-text
                      type="rate"
                      suffix=""
                      width="60px"
                      :value="rfqInfo.payCheckRatio"
                      @change="onChange_payRatio('Check', $event.target.value)"
                      :enable="rfqStatus.isEnable"
                      :required="rfqStatus.isEnable"
                      :errorMessage="{title: '지급조건', message: '필수입력입니다.'}"
                    />
                    <label class="mr15">%</label>
                    <iui-text
                      type="number"
                      width="60px"
                      :value="rfqInfo.payTerm"
                      max-length="3"
                      @change="setRfqInfo({payTerm: $event.target.value})"
                      :enable="rfqStatus.isEnable"
                      :required="rfqStatus.isEnable"
                      :errorMessage="{title: '지급조건', message: '필수입력입니다.'}"
                    />
                    <label>일 이내</label>
                  </i-col>
                  <i-spacer />
                  <i-col-header>비고</i-col-header>
                  <i-col rowspan="2">
                    <iui-text
                      type="multi"
                      :value="rfqInfo.rfqRmrk"
                      @change="setRfqInfo({rfqRmrk: $event.target.value})"
                      :enable="rfqStatus.isEnable"
                      max-length="1400"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <template v-if="!rfqStatus.isPotypeUnit">
                    <i-col-header required>납품일자</i-col-header>
                    <i-col>
                      <iui-datepicker
                        key="dlvReqDt"
                        :value="rfqInfo.dlvReqDt"
                        @change="setRfqInfo({dlvReqDt: $event})"
                        :disabledDate="isBeforeToday"
                        :enable="!rfqStatus.isPotypeUnit && rfqStatus.isEnable"
                        :required="!rfqStatus.isPotypeUnit && rfqStatus.isEnable"
                        :errorMessage="{title: '납품요구일', message: '필수입력입니다.'}"
                      />
                    </i-col>
                  </template>
                  <template v-else>
                    <i-col-header required>납품기간</i-col-header>
                    <i-col>
                      <iui-datepicker
                        key="contFrDt"
                        :value="rfqInfo.contFrDt"
                        @change="onChange_contFrDt"
                        :disabledDate="isBeforeToday"
                        :enable="rfqStatus.isPotypeUnit && rfqStatus.isEnable"
                        :required="rfqStatus.isPotypeUnit && rfqStatus.isEnable"
                        :errorMessage="{title: '계약기간', message: '필수입력입니다.'}"
                      />
                      <label>~</label>
                      <iui-datepicker
                        key="contToDt"
                        :value="rfqInfo.contToDt"
                        @change="setRfqInfo({contToDt: $event})"
                        :disabledDate="disabledContToDt"
                        :enable="rfqStatus.isPotypeUnit && rfqStatus.isEnable"
                        :required="rfqStatus.isPotypeUnit && rfqStatus.isEnable"
                        :errorMessage="{title: '계약기간', message: '필수입력입니다.'}"
                      />
                    </i-col>
                  </template>
                  <i-spacer />
                  <i-col>&nbsp;</i-col>
                </i-row>
                <i-row>
                  <template v-if="!rfqStatus.isPotypeUnit">
                    <i-col-header>납품장소</i-col-header>
                    <i-col>
                      <iui-text
                        :value="rfqInfo.dlvLoc"
                        @change="setRfqInfo({dlvLoc: $event.target.value})"
                        :enable="rfqStatus.isEnable"
                        max-length="250"
                      />
                    </i-col>
                  </template>
                  <template v-else>
                    <i-col-header required>계약조건</i-col-header>
                    <i-col>
                      <iui-text
                        :value="rfqInfo.contCondRmrk"
                        @change="setRfqInfo({contCondRmrk: $event.target.value})"
                        :enable="rfqStatus.isEnable"
                        max-length="250"
                        required
                        :errorMessage="{title: '계약조건', message: '필수입력입니다.'}"
                      />
                    </i-col>
                  </template>
                  <i-spacer />
                  <i-col-header>공고품의문서</i-col-header>
                  <i-col>
                    <iui-text
                      :value="rfqInfo.rfqSncNo || ''"
                      :readonly="true"
                      :enable="!!rfqInfo.rfqSncNo"
                      @click="openRfqApproval"
                      :css="clickable_inputStyle"
                      width="100px"
                    />
                  </i-col>
                </i-row>
              </iui-container-new>
            </pmis-content-box>
          </i-col>
        </i-row>
        <i-spacer />
        <i-row minHeight="200px" maxHeight="500px">
          <i-col>
            <!-- 자재내역 -->
            <RfqItemList />
          </i-col>
        </i-row>
        <i-spacer />
        <i-row minHeight="180px" maxHeight="250px">
          <i-col width="55%">
            <!-- 협력사목록 -->
            <RfqCusList />
          </i-col>
          <i-spacer />
          <i-col>
            <pmis-file-list
              id="rfqNoticeFile"
              title-visible
              title="첨부파일"
              :toolbar-visible="rfqStatus.isEnable"
              :company-code="rfqInfo.orgCd"
              :project-code="rfqInfo.spotDeptcd"
              :fbsNo="FBS_NO"
              :file-number="rfqInfo.flNo"
              :copyFiles="prFiles"
              :clear.sync="fileClear"
              @delete-complete="onDeleteCompleteFile"
            />
          </i-col>
        </i-row>

        <template v-if="!!prInfo.prNo">
          <i-spacer />
          <i-row height="100px">
            <i-col>
              <pmis-content-box>
                <template #title>구매요청정보</template>
                <iui-container-new type="table" theme="bullet">
                  <i-row>
                    <i-col-header>요청번호</i-col-header>
                    <i-col>
                      <iui-text
                        :value="prInfo.prNo2"
                        width="120px"
                        :readonly="true"
                        :enable="!!prInfo.prNo"
                        @click="openPopup('prInfoModal')"
                        :css="clickable_inputStyle"
                      />
                      <iui-text :value="prInfo.ttl" :enable="false" />
                    </i-col>
                    <i-spacer />
                    <i-col-header>구매요청문서</i-col-header>
                    <i-col>
                      <iui-text
                        :value="prInfo.sncNo || ''"
                        width="90px"
                        :readonly="true"
                        :enable="!!prInfo.sncNo"
                        @click="openPrApproval"
                        :css="clickable_inputStyle"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>구매요청자</i-col-header>
                    <i-col>
                      <iui-text :value="prInfo.chrgUsrnm" width="120px" :enable="false" />
                    </i-col>
                    <i-spacer />
                    <i-col-header>요청일자</i-col-header>
                    <i-col>
                      <iui-datepicker :value="prInfo.prDate" :enable="false" />
                    </i-col>
                  </i-row>
                </iui-container-new>
              </pmis-content-box>
            </i-col>
          </i-row>
        </template>
      </iui-container-new>
    </form>

    <iui-modal name="rfqTargetPrListModal" :btns="rfqTargetPrListModalBtns" title="구매요청목록" sizeType="size3">
      <RfqTargetPrList />
    </iui-modal>

    <iui-modal name="chrgUserListModal" :btns="chrgUserListModalBtns" title="사원정보" sizeType="size1">
      <UserListPopup :uid="_uid" :laborDs="$getConstants('LABOR_DS1').code" @onDblClick="onConfirm_userModal('chrg')" />
    </iui-modal>

    <iui-modal name="spotDscrUserListModal" :btns="spotUserListModalBtns" title="사원정보" sizeType="size1">
      <UserListPopup
        :uid="_uid"
        :laborDs="$getConstants('LABOR_DS1').code"
        @onDblClick="onConfirm_userModal('spotDscr')"
      />
    </iui-modal>

    <iui-modal name="bidPlanAmtModal" title="입찰예정가" :btns="bidPlanAmtModalBtns" width="600px" height="300px">
      <BidPlanAmt :bidType="bidType" :rfqNo="rfqInfo.rfqNo" contentBoxTitle="입찰예정가" />
    </iui-modal>

    <iui-modal name="prInfoModal" title="구매요청정보" size-type="size3">
      <PrInfo :prNo="prInfo.prNo" />
    </iui-modal>

    <ApprovalPop :parentId="_uid" @approvalEmit="onApprovalEmit" />
  </pmis-tab-box>
</template>

<script>
import EDMS_CONSTANTS from '@/view/edms/common/EdmsConstatns.js';
import RfqInfoMixin from '@/view/bidding/rfq/common/mixins/rfqInfoMixin.js';
import {
  selectRfqInfo,
  selectApprovedPrList,
  createRfq,
  saveRfq,
  deleteRfq,
  updateRfqFlNo,
  selectRfqDegreeInfo,
  noticeRfq,
  cancelNoticeRfq,
} from '@/view/bidding/rfq/mat/api/rfq.js';

import RfqItemList from '@/view/bidding/rfq/mat/common/components/RfqItemList.vue';
import RfqTargetPrList from './RfqTargetPrList.vue';
import PrInfo from '@/view/bidding/rfq/mat/common/components/PrInfo.vue';
import IuiTimepicker from '@/components/common/IuiTimepicker.vue';

export default {
  components: {IuiTimepicker, RfqItemList, RfqTargetPrList, PrInfo},
  mixins: [RfqInfoMixin],
  data() {
    return {
      FBS_NO: EDMS_CONSTANTS.MAT_RFQ.FBS_NO,
      apis: {
        selectRfqInfo,
        selectApprovedPrList,
        updateRfqFlNo,
        createRfq,
        saveRfq,
        deleteRfq,
        selectRfqDegreeInfo,
        noticeRfq,
        cancelNoticeRfq,
      },
    };
  },
  methods: {
    disabledContToDt(date) {
      return this.rfqInfo.contFrDt ? this.rfqInfo.contFrDt > $_dateToStringYYMMDD(date) : this.isBeforeToday(date);
    },
    onChange_contFrDt(date) {
      const payload = {contFrDt: date};
      if (date && date > this.rfqInfo.contToDt) {
        payload['contToDt'] = '';
      }
      this.setRfqInfo(payload);
    },
    onChange_poTypecd(value) {
      this.setRfqInfo({
        poTypecd: value,
        dlvReqDt: '',
        contFrDt: '',
        contToDt: '',
        dlvLoc: '',
        contCondRmrk: '',
      });
    },
    async onValidateSave() {
      if (!this.onValidateSaveCommon()) {
        return false;
      }

      if (this.rfqStatus.isPotypeUnit) {
        if (this.rfqInfo.contFrDt > this.rfqInfo.contToDt) {
          this.$alert({title: '계약기간', message: '계약기간을 다시 한 번 확인하시기 바랍니다.'});
          return false;
        }
      }

      let rfqItemList, rfqCusList;
      this.callEvent({name: 'RfqItemList_GetSaveJson', param: data => (rfqItemList = data)});
      this.callEvent({name: 'RfqCusList_GetSaveJson', param: data => (rfqCusList = data)});

      if (!rfqItemList.length) {
        await this.$alert({title: '자재내역', message: '자재내역이 존재하지 않습니다.'});
        this.callEvent({name: 'RfqItemList_OpenTargetMatModal'});
        return false;
      } else if (rfqItemList.some(row => !Number(row.qty))) {
        this.$alert({
          title: '자재내역',
          message: '금회요청수량이 입력되지 않은 항목이 있습니다.<br/>다시 한 번 확인하시기 바랍니다.',
        });
        return false;
      }

      if (!rfqCusList.length) {
        await this.$alert({title: '협력사', message: '협력사를 선택해야 합니다.'});
        this.callEvent({name: 'RfqCusList_OpenCustomerChoicePopup'});
        return false;
      }
      return true;
    },
  },
};
</script>

<style></style>
