<template>
  <pmis-content-box :loading="loading">
    <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" @loadSheet="loadSheet" />
    <iui-modal title="도급내역" name="contract" :btns="modalBtns" sizeType="size3">
      <contract :isSearch="true" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import Contract from '@/view/executDtls/components/Contract.vue';
import options from './sheetOptions/ContDtlsListSheet.js';
import {selectContWcontplanMList, saveContWcontplanM, deleteContWcontplanM} from '../api/task.js';
export default {
  components: {
    Contract,
  },
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this), undefined, undefined, {
        CanEdit: this.prjFlag != $getConstants('PRJ_FLAG1').code,
      }),
      loadSearchData: [],
      modalBtns: [
        {
          title: '공종선택',
          name: '확인',
          callback: this.contractConfirm,
        },
      ],
      loading: false,
    };
  },

  beforeCreate() {
    $mapGetters(this, ['detailInfo', 'prjFlag']);
  },

  created() {
    this.addEvent([
      {
        name: 'selectContWcontplanMList',
        func: this.selectContWcontplanMList,
      },
      {name: 'getDataRows', func: this.getDataRows},
      {name: 'getContractData', func: this.getContractData},
      {name: 'deleteRow', func: this.deleteRow},
      {name: 'initSheetList', func: this.initSheetList},
    ]);
  },

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    initSheetList() {
      if (this.sheet) {
        this.sheet.removeAll();
      }
    },
    async selectContWcontplanMList(param) {
      const response = await selectContWcontplanMList(param);
      this.loadSearchData = response.data;
    },

    contractConfirm() {
      this.callEvent({
        name: 'getContFocusedRow',
        param: this.getContractData,
      });
      this.$modal.hide('contract');
    },
    async getContractData(row) {
      this.loading = true;
      setTimeout(async () => {
        let hasRow = true;
        if (row.constructor.name == 'Array') {
          let rows = this.sheet.getDataRows();
          let isDuplArray = [];

          row.some(obj => {
            if (!obj) {
              hasRow = false;
              return true;
            }
            let isDupl = false;
            for (let item of rows) {
              if (obj.midCode == item.midCode && obj.itemSeq == item.itemSeq) {
                isDuplArray.push(item.itemName);
                isDupl = true;
                break;
              }
            }

            if (!isDupl) {
              this.sheet.addRow();
              let fucusRow = this.sheet.getFocusedRow();
              this.sheet.setValue(fucusRow, 'wcode', obj.wcode);
              this.sheet.setValue(fucusRow, 'headCode', obj.headCode);
              this.sheet.setValue(fucusRow, 'midCode', obj.midCode);
              this.sheet.setValue(fucusRow, 'mgName', obj.mgName, 1);
              this.sheet.setValue(fucusRow, 'itemCd', obj.itemCd);
              this.sheet.setValue(fucusRow, 'itemSeq', obj.itemSeq, 1);
              this.sheet.setValue(fucusRow, 'itemCode', obj.itemCode);
              this.sheet.setValue(fucusRow, 'itemName', obj.itemName, 1);
              this.sheet.setValue(fucusRow, 'ssize', obj.ssize, 1);
              this.sheet.setValue(fucusRow, 'unit', obj.unit, 1);
            }
          });
        } else {
          let rows = this.sheet.getDataRows();
          let isDupl = false;
          for (let item of rows) {
            if (row.midCode == item.midCode && row.itemSeq == item.itemSeq) {
              isDupl = true;
              break;
            }
          }

          if (isDupl) {
            this.$alert({title: '내역코드', message: '내역코드가 존재합니다. 다시 선택해주세요.'});
            return;
          }

          let fucusRow = this.sheet.getFocusedRow();
          this.sheet.setValue(fucusRow, 'wcode', row.wcode);
          this.sheet.setValue(fucusRow, 'headCode', row.headCode);
          this.sheet.setValue(fucusRow, 'midCode', row.midCode);
          this.sheet.setValue(fucusRow, 'mgName', row.mgName, 1);
          this.sheet.setValue(fucusRow, 'itemCd', row.itemCd);
          this.sheet.setValue(fucusRow, 'itemSeq', row.itemSeq, 1);
          this.sheet.setValue(fucusRow, 'itemCode', row.itemCode);
          this.sheet.setValue(fucusRow, 'itemName', row.itemName, 1);
          this.sheet.setValue(fucusRow, 'ssize', row.ssize, 1);
          this.sheet.setValue(fucusRow, 'unit', row.unit, 1);
        }
        if (!hasRow) {
          return;
        }

        let unitWork;
        this.callEvent({
          name: 'unitOpertListGetFocusedRow',
          param: row => {
            unitWork = row;
          },
        });

        let wplanList = $_statusToCud(this.sheet.getSaveJson(0).data).map(row => ({
          wcode: row.wcode,
          headCode: row.headCode,
          midCode: row.midCode,
          itemSeq: row.itemSeq,
          itemCode: row.itemCode,
        }));
        const param = {
          wbsCd: unitWork.wbsCd,
          wbsId: unitWork.wbsId,
          wplanList,
        };

        try {
          const response = await saveContWcontplanM(param);
          if (response.data) {
            this.selectContWcontplanMList({
              wbsCd: param.wbsCd,
              wbsId: param.wbsId,
            });
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }, 10);
    },
    getDataRows(callback) {
      if (callback) {
        callback(this.sheet.getDataRows());
      }
    },
    async deleteRow() {
      let unitWork;
      this.callEvent({
        name: 'unitOpertListGetFocusedRow',
        param: row => {
          unitWork = row;
        },
      });
      let seqList = $_statusToCud(this.sheet.getSaveJson(0).data.filter(item => item.chk === '1')).map(row => row.seq);
      const param = {
        wbsCd: unitWork.wbsCd,
        wbsId: unitWork.wbsId,
        seqList,
      };
      const response = await deleteContWcontplanM(param);
      if (response.data) {
        this.selectContWcontplanMList({
          wbsCd: unitWork.wbsCd,
          wbsId: unitWork.wbsId,
        });
      }
    },
  },
};
</script>
