<template>
  <pmis-content-box>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :append="true"
      :events="sheetEvents"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import {selectOprCodeList, deleteOprCode} from '../api/OprCodeRegist.js';
import options from './sheetOption/DetailListSheet.js';
export default {
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    base: {
      type: Boolean,
      default: false,
    },
    uid: {
      type: Number,
    },
    callback: {
      type: Function,
      default: undefined,
    },
    paramObj: {
      type: Object,
      default: () => {},
    },
    focusKey: {
      type: String,
    },
  },
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this)),
      loadSearchData: [],
      isMultiSelect: false,
      sheetUtil: null,
      searchNm: '',
      oprClInfo: {},
      oprCodeInfo: {
        itemName: '',
        level: 4,
        lpCode: '',
        lpName: '',
        oprCode: '',
        mpCode: '',
        mpName: '',
        size: '',
        unit: '',
        useDs: '',
      },
      sheetEvents: {
        onClick: this.isPopup ? undefined : this.onClick,
        onFocus: this.isPopup ? undefined : this.onFocus,
        onSearchFinish: this.isPopup ? undefined : this.onSearchFinish,
      },
    };
  },
  created() {
    if (this.paramObj) {
      this.isMultiSelect = this.paramObj.isMultiSelect;
    }

    if (this.isPopup) this.onSearch();
  },

  methods: {
    async onSearch() {
      const param = {
        base: this.base,
        code: this.oprClInfo.level === 1 ? '' : this.oprClInfo.code ?? '',
        searchNm: this.searchNm,
        ...this.sheetUtil.pagingData(),
      };
      let response = await selectOprCodeList(param);
      if (response.status == 200) {
        this.loadSearchData = response.data;
      }
    },
    async onDelete() {
      let checkedRows = this.sheet.getRowsByChecked('chk');

      if (0 == checkedRows.length) {
        this.$alert({title: '삭제', message: '선택된 항목이 없습니다.'});
        return;
      }
      if (!(await this.$confirm({title: '삭제', message: '선택된 경비를 삭제하시겠습니까?'}))) {
        return;
      }
      try {
        let deletedList = checkedRows.map(row => ({oprCode: row.oprCode}));
        const response = await deleteOprCode({base: this.base, deletedList: deletedList});
        if (response.data) {
          this.removeAll();
          this.onSearch();
          return deletedList;
        }
      } catch (error) {
        this.$alert({title: '삭제', message: error.description ?? error.message});
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
      this.sheetUtil.scrollPaging(this.onSearch);
    },
    onDblClick(e) {
      e.row.Kind == 'Data' ? this.callEvent({name: `matCodeSearchOnConfirm${this.uid}`}) : '';
    },
    onFocus(e) {
      if (e.row.Kind == 'Data') {
        this.setOprCodeInfo(e.row);
        if (typeof this.callback == 'function') this.callback();
      }
    },
    onSearchFinish(e) {
      if (this.focusKey) {
        const row = e.sheet.getDataRows().find(row => row.oprCode === this.focusKey);
        e.sheet.focus(row);
        this.$emit('update:focusKey', undefined);
      }
    },
    setOprCodeInfo(row) {
      for (let key in this.oprCodeInfo) {
        this.oprCodeInfo[key] = row[key];
      }
      this.$emit('oprCodeInfo', this.oprCodeInfo);
    },

    onClick(e) {
      this.$emit('onOprClSearch', e.row.mpCode);
    },
    onReloadSheet() {
      if (this.cud == 1) {
        let addRow = this.sheet.addRow({
          init: {
            lpCode: this.oprCodeInfo.lpCode,
            lpName: this.oprCodeInfo.lpName,
            mpCode: this.oprCodeInfo.mpCode,
            mpName: this.oprCodeInfo.mpName,
            oprCode: this.oprCodeInfo.oprCode,
            itemName: this.oprCodeInfo.itemName,
            size: this.oprCodeInfo.size,
            unit: this.oprCodeInfo.unit,
            useDs: this.oprCodeInfo.useDs,
          },
        });
        this.sheet.acceptChangedData(addRow);
      } else if (this.cud == 2) {
        let focusedRow = this.sheet.getFocusedRow();
        this.sheet.setValue(focusedRow, 'itemName', this.oprCodeInfo.itemName, 1);
        this.sheet.setValue(focusedRow, 'size', this.oprCodeInfo.size, 1);
        this.sheet.setValue(focusedRow, 'unit', this.oprCodeInfo.unit, 1);
        this.sheet.setValue(focusedRow, 'useDs', this.oprCodeInfo.useDs, 1);
        this.sheet.acceptChangedData(focusedRow);
      }
    },
    onSetData(param) {
      if (this.isMultiSelect) {
        let checkedRows = this.sheet.getRowsByChecked('chk');
        if (0 == checkedRows.length) {
          this.$alert({title: '선택', message: '선택된 항목이 없습니다.'});
          return false;
        }
        param(checkedRows);
      } else {
        param(this.sheet.getFocusedRow());
      }
    },
    removeAll() {
      this.sheet.removeAll();
      Object.assign(this.$data.oprCodeInfo, this.$options.data.call(this).oprCodeInfo);
    },
  },
};
</script>

<style scoped></style>
