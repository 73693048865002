<template>
  <pmis-tab-box>
    <template #title>상세정보</template>
    <template #title-right>
      <iui-button value="구매요청품의" @click="onApproval" v-if="prStatus.isPrReady" />
    </template>

    <form onsubmit="return false;">
      <iui-container-new type="css-flex">
        <i-row height="230px">
          <i-col>
            <iui-container-new type="table" theme="bullet">
              <colgroup>
                <col width="80px" />
                <col width="80px" />
                <col />
                <col width="10px" />
                <col width="80px" />
                <col />
              </colgroup>
              <i-row>
                <i-col colspan="6">
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header>요청번호</i-col-header>
                      <i-col width="120px">
                        <iui-text :value="prInfo.prNo2" width="120px" :enable="false" />
                      </i-col>
                      <i-spacer />
                      <i-col-header required>요청제목</i-col-header>
                      <i-col>
                        <iui-text
                          ref="ttl"
                          :key="`ttlKey_${ttlKey}`"
                          :value="prInfo.ttl"
                          @input="onInput_ttl"
                          :enable="prStatus.isEnable"
                          :required="prStatus.isEnable"
                          :errorMessage="{title: '요청제목', message: '필수입력입니다.'}"
                          max-length="200"
                        />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>과세유형</i-col-header>
                <i-col colspan="5">
                  <iui-radio-group
                    :p-code="$getConstants('TAX_TYPE_CD').code"
                    :value="prInfo.taxTypecd"
                    :enable="prStatus.isEnable"
                    @change="onChange_taxType('taxTypecd', $event.target.value)"
                  />
                  <template v-if="prStatus.isTaxDirect">
                    <iui-select
                      :p-code="$getConstants('TAX_DIRECT_DS').code"
                      :value="prInfo.taxDirectDs"
                      @change="value => onChange_taxType('taxDirectDs', value)"
                      :enable="prStatus.isEnable && prStatus.isTaxDirect"
                    />
                    <iui-text
                      type="rate"
                      :value="prInfo.taxTypeDirectPercent"
                      @change="onChange_taxType('taxTypeDirectPercent', $event.target.value)"
                      :enable="prStatus.isEnable && prStatus.isTaxDirectPercent"
                      :required="prStatus.isTaxDirectPercent"
                      :errorMessage="{title: '과세율', message: '필수입력입니다.'}"
                    />
                    <iui-text
                      type="amount"
                      :value="prInfo.taxTypeDirectAmt"
                      @change="onChange_taxType('taxTypeDirectAmt', $event.target.value)"
                      :enable="prStatus.isEnable && prStatus.isTaxDirectAmount"
                      :required="prStatus.isTaxDirectAmount"
                      :errorMessage="{title: '과세금액', message: '필수입력입니다.'}"
                    />
                  </template>
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>현설유무</i-col-header>
                <i-col colspan="5">
                  <iui-checkbox-group
                    :checkedValues="[prInfo.spotDscrYn]"
                    :items="[{label: $getConstants('EXTERNAL_Y').name, value: $getConstants('EXTERNAL_Y').code}]"
                    @change="onChange_spotDscrYn"
                    :enable="prStatus.isEnable"
                  />
                </i-col>
              </i-row>
              <i-row>
                <template v-if="prStatus.isExistSpotDscr">
                  <i-col>&nbsp;</i-col>
                  <i-col-header required>현설일자</i-col-header>
                  <i-col>
                    <iui-datepicker
                      :value="prInfo.spotDscrYmd"
                      @change="setPrInfo({spotDscrYmd: $event})"
                      :disabledDate="isBeforeToday"
                      :enable="prStatus.isEnable && prStatus.isExistSpotDscr"
                      :required="prStatus.isEnable && prStatus.isExistSpotDscr"
                      :errorMessage="{title: '현설일자', message: '필수입력입니다.'}"
                    />
                    <iui-timepicker
                      width="30px"
                      :value="getTime(prInfo.spotDscrHh, prInfo.spotDscrMm)"
                      @change="onChangeTime('spotDscr', $event)"
                      :enable="prStatus.isEnable && prStatus.isExistSpotDscr"
                      :required="prStatus.isEnable && prStatus.isExistSpotDscr"
                      :errorMessage="{title: '현설시간', message: '필수입력입니다.'}"
                    />
                  </i-col>
                  <i-spacer />
                  <i-col-header required>현설담당</i-col-header>
                  <i-col>
                    <div class="iui-searchbox">
                      <iui-text
                        width="90px"
                        :value="prInfo.spotDscrUsrcd"
                        :enable="false"
                        :required="prStatus.isEnable && prStatus.isExistSpotDscr"
                        :errorMessage="{title: '현설담당', message: '필수입력입니다.'}"
                      />
                      <iui-button
                        class="flex-0"
                        btn-class="search-button"
                        @click="onOpenPopup('spot')"
                        v-if="prStatus.isEnable && prStatus.isExistSpotDscr"
                      />
                      <iui-text width="100px" :value="prInfo.spotDscrUsrnm" :enable="false" />
                    </div>
                  </i-col>
                </template>
                <template v-else>
                  <i-col colspan="6">&nbsp;</i-col>
                </template>
              </i-row>
              <i-row>
                <template v-if="prStatus.isExistSpotDscr">
                  <i-col>&nbsp;</i-col>
                  <i-col-header required>현설장소</i-col-header>
                  <i-col colspan="4">
                    <iui-text
                      :value="prInfo.spotDscrLoc"
                      @change="setPrInfo({spotDscrLoc: $event.target.value})"
                      :enable="prStatus.isEnable && prStatus.isExistSpotDscr"
                      :required="prStatus.isEnable && prStatus.isExistSpotDscr"
                      :errorMessage="{title: '현설장소', message: '필수입력입니다.'}"
                      max-length="500"
                    />
                  </i-col>
                </template>
                <template v-else>
                  <i-col colspan="6">&nbsp;</i-col>
                </template>
              </i-row>
              <i-row>
                <i-col-header required>납품요구일</i-col-header>
                <i-col colspan="2">
                  <iui-datepicker
                    :value="prInfo.dlvReqDt"
                    @change="setPrInfo({dlvReqDt: $event})"
                    :disabledDate="isBeforeToday"
                    :enable="prStatus.isEnable"
                    :required="prStatus.isEnable"
                    :errorMessage="{title: '납품요구일', message: '필수입력입니다.'}"
                  />
                </i-col>
                <i-spacer />
                <i-col-header>구매요청자</i-col-header>
                <i-col>
                  <div class="iui-searchbox">
                    <iui-text width="90px" :value="prInfo.chrgUsrcd" :enable="false" />
                    <iui-button
                      class="flex-0"
                      btn-class="search-button"
                      @click="onOpenPopup('chrg')"
                      v-if="prStatus.isEnable"
                    />
                    <iui-text width="100px" :value="prInfo.chrgUsrnm" :enable="false" />
                  </div>
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>납품장소</i-col-header>
                <i-col colspan="2">
                  <iui-text
                    :value="prInfo.dlvLoc"
                    @change="setPrInfo({dlvLoc: $event.target.value})"
                    :enable="prStatus.isEnable"
                    :required="prStatus.isEnable"
                    :errorMessage="{title: '납품장소', message: '필수입력입니다.'}"
                    max-length="500"
                  />
                </i-col>
                <i-spacer />
                <i-col-header>요청일자</i-col-header>
                <i-col>
                  <iui-datepicker
                    :value="prInfo.prDate"
                    @change="setPrInfo({prDate: $event})"
                    :enable="prStatus.isEnable"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>비고</i-col-header>
                <i-col colspan="5">
                  <iui-text
                    :value="prInfo.prRmrk"
                    @change="setPrInfo({prRmrk: $event.target.value})"
                    :enable="prStatus.isEnable"
                    max-length="1400"
                  />
                </i-col>
              </i-row>
            </iui-container-new>
          </i-col>
        </i-row>
        <i-row>
          <i-col>
            <pmis-file-list
              id="matPrFile"
              theme="bullet"
              title-visible
              title="첨부파일"
              :toolbar-visible="prStatus.isEnable"
              :company-code="pgmCd"
              :project-code="prInfo.spotDeptcd"
              :fbsNo="FBS_NO"
              :file-number="prInfo.flNo"
              :clear.sync="fileClear"
              @delete-complete="onDeleteCompleteFile"
            />
          </i-col>
        </i-row>
      </iui-container-new>
    </form>

    <iui-modal name="userListModal" :btns="userListModalBtns" title="사원정보" sizeType="size1">
      <UserListPopup :uid="_uid" :laborDs="$getConstants('LABOR_DS1').code" @onDblClick="onConfirm_UserListPopup" />
    </iui-modal>

    <ApprovalPop :parentId="_uid" @approvalEmit="onApprovalEmit" />
  </pmis-tab-box>
</template>

<script>
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import EDMS_CONSTANTS from '@/view/edms/common/EdmsConstatns.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import BiddingApprovalMixin from '@/view/bidding/js/biddingApprovalMixin.js';
import {savePrInfo, deletePrInfo, deletePrFlNo} from '@/view/bidding/pr/mat/api/pr.js';

import UserListPopup from '@/view/Resource/components/WorkerListPopup.vue';
import IuiTimepicker from '@/components/common/IuiTimepicker.vue';

export default {
  components: {IuiTimepicker, UserListPopup},
  mixins: [BiddingApprovalMixin],
  data() {
    return {
      FBS_NO: EDMS_CONSTANTS.MAT_PR.FBS_NO,
      userListModalBtns: [{name: '확인', callback: this.onConfirm_UserListPopup}],
      modalNm: '',
      fileClear: false,
      ttlKey: 0,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['prInfo', 'prStatus', 'prIsNew']);
    $mapMutations(this, ['setPrInfo', 'initPrInfo', 'setPrItemList', 'setPrIsNew']);
  },
  created() {
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
    this.addEvent([{name: 'PrDetailInfo_ClearFile', func: this.clearFile}]);
  },
  methods: {
    timeFormat: COMMON_FUNCTION.timeFormat,
    isBeforeToday(date) {
      return $_getCurrentDate() > $_dateToStringYYMMDD(date);
    },
    clearFile() {
      this.fileClear = true;
    },
    onAdd() {
      this.clearFile();
      this.setPrIsNew(true);
      this.initPrInfo();
      this.setPrItemList([]);
      this.canSave = true;
      this.canDelete = false;

      if (!this.prjCd) {
        this.$alert({title: '현장', message: '선택된 현장이 없습니다.'});
        return;
      }

      this.setPrInfo({
        orgCd: this.pgmCd,
        spotDeptcd: this.prjCd,
        ttl: `[${this.prjCd}] `,
        spotDscrYn: BIDDING_CONSTANTS.CODES.SPOT_DSCR_Y,
        taxTypecd: BIDDING_CONSTANTS.CODES.TAX,
        chrgUsrcd: this.userInfo.empNo,
        chrgUsrnm: this.userInfo.name,
        spotDscrUsrcd: this.userInfo.empNo,
        spotDscrUsrnm: this.userInfo.name,
        spotDscrUsrTlno: this.userInfo.tlNo,
        rgprId: this.userInfo.empNo,
        prDate: $_getCurrentDate(),
      });
    },
    /*** 요청제목 입력 ***/
    onInput_ttl(e) {
      let value = e.target.value;

      const updateTtlKey = () => {
        if (10 < this.ttlKey) {
          this.ttlKey = 0;
        } else {
          this.ttlKey++;
        }
      };

      const ttlPrefix = `[${this.prInfo.spotDeptcd}] `;
      if (value.length < ttlPrefix.length) {
        value = ttlPrefix;
        updateTtlKey();
      } else if (value.indexOf(ttlPrefix) !== 0) {
        value = this.prInfo.ttl + e.data;
        updateTtlKey();
      }
      setTimeout(() => {
        this.$refs.ttl.$refs.input.focus();
      }, 10);

      this.setPrInfo({ttl: value});
    },
    /*** 과세유형 변경 ***/
    onChange_taxType(key, value) {
      const BIDDING_CODES = BIDDING_CONSTANTS.CODES;
      const payloads = {
        taxTypecd: {
          taxDirectDs: value === BIDDING_CODES.TAX_DIRECT ? BIDDING_CODES.TAX_DIRECT_PERCENT : '',
          taxTypeDirectPercent: '',
          taxTypeDirectAmt: '',
        },
        taxDirectDs: {
          taxTypeDirectPercent: value === BIDDING_CODES.TAX_DIRECT_PERCENT ? 0 : '',
          taxTypeDirectAmt: value === BIDDING_CODES.TAX_DIRECT_AMOUNT ? 0 : '',
        },
      };

      this.setPrInfo({[key]: value, ...payloads[key]});
      this.callEvent({name: 'PrItemList_CalculateAmt'});
    },
    /*** 현설유무 변경 ***/
    onChange_spotDscrYn(e) {
      const BIDDING_CODES = BIDDING_CONSTANTS.CODES;
      const spotDscrYn = $_externalCheckboxValue(e.target.checked);
      const [spotDscrUsrcd, spotDscrUsrnm, spotDscrUsrTlno] =
        spotDscrYn === BIDDING_CODES.SPOT_DSCR_Y
          ? [this.userInfo.empNo, this.userInfo.name, this.userInfo.tlNo]
          : ['', '', ''];

      this.setPrInfo({
        spotDscrYn,
        spotDscrLoc: '',
        spotDscrDt: '',
        spotDscrYmd: '',
        spotDscrHh: '',
        spotDscrMm: '',
        spotDscrUsrcd,
        spotDscrUsrnm,
        spotDscrUsrTlno,
      });
    },
    onOpenPopup(modalType) {
      this.modalNm = `${modalType}Modal`;
      this.$modal.show('userListModal');
    },
    onConfirm_UserListPopup() {
      this.callEvent({
        name: 'WorkerListPopup_callbackData',
        param: data => {
          if (this.modalNm == 'spotModal') {
            this.setPrInfo({
              spotDscrUsrcd: data.empNo,
              spotDscrUsrnm: data.name,
              spotDscrUsrTlno: data.tlNo,
            });
          } else if (this.modalNm == 'chrgModal') {
            this.setPrInfo({chrgUsrcd: data.empNo, chrgUsrnm: data.name});
          }
          this.$modal.hide('userListModal');
        },
      });
    },
    onSave() {
      let itemList;
      this.callEvent({name: 'PrItemList_GetSaveJson', param: data => (itemList = data)});

      if (this.prInfo.ttl === `[${this.prInfo.spotDeptcd}] `) {
        this.$alert({title: '요청제목', message: '요청제목을 입력해주세요.'});
        return;
      }

      const saveInfo = {orgCd: this.pgmCd, ...this.prInfo, itemList, isNew: this.prIsNew};

      savePrInfo(saveInfo, () => this.onAfterSaveValidation(itemList))
        .then(response => {
          this.callEvent({name: 'PrList_Search', param: response.data.prNo});
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    onAfterSaveValidation(itemList) {
      // 과세율
      if (100 < this.prInfo.taxRate) {
        this.$alert({title: '과세율', message: '100%를 초과할 수 없습니다.'});
        return false;
      }

      // 현설체크
      if (this.prStatus.isExistSpotDscr) {
        const spotYmd = this.prInfo.spotDscrYmd.replace(/-/g, '');
        const spotYear = spotYmd.substr(0, 4);
        const spotMonth = parseInt(spotYmd.substr(4, 2)) - 1;
        const spotDay = spotYmd.substr(6, 2);

        const spotHour = this.prInfo.spotDscrHh;
        const spotMinute = this.prInfo.spotDscrMm;
        if (!($_isNumberBetween(spotHour, 0, 24, true) && $_isNumberBetween(spotMinute, 0, 60, true))) {
          this.$alert({title: '현설일자', message: '현설시간이 잘못되었습니다.<br/>다시 한 번 확인하시기 바랍니다.'});
          return false;
        }

        const spotDate = new Date(spotYear, spotMonth, spotDay, spotHour, spotMinute);
        if (spotDate.getTime() <= new Date().getTime()) {
          this.$alert({title: '현설일자', message: '현설일자는 현재시간 이후로만 가능합니다.'});
          return false;
        }
      }

      // 구매요청내역 체크
      if (!itemList.length) {
        this.$alert({
          title: '구매요청내역',
          message: '구매요청내역을 추가해주세요.',
        }).then(() => this.callEvent({name: 'PrItemList_OpenMatBudgetModal', param: 'matBudgetModal'}));
        return false;
      } else if (itemList.some(row => !Number(row.qty))) {
        this.$alert({
          title: '구매요청내역',
          message: '금회요청수량이 입력되지 않은 항목이 있습니다.<br/>다시 한 번 확인하시기 바랍니다.',
        });
        return false;
      }
      return true;
    },
    onDeleteCompleteFile(flNo) {
      if (flNo) return;

      deletePrFlNo(this.prInfo.prNo)
        .then(response => {
          if (0 < response.data) {
            this.setPrInfo({flNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    async onDelete() {
      const title = '구매요청정보 삭제';
      const message = '구매요청정보를 삭제하시겠습니까?';

      if (!(await this.$confirm({title, message}))) return;

      deletePrInfo(this.prInfo.prNo)
        .then(response => {
          if (0 < response.data) {
            this.callEvent({name: 'PrList_Search'});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    onApproval() {
      let itemList = [];
      this.callEvent({name: 'PrItemList_GetSaveJson', param: data => (itemList = data)});

      if (!itemList.length) {
        this.$alert({title: '구매요청품의', message: '구매요청내역이 존재하지 않습니다.'});
        return false;
      }
      if (itemList.some(item => !item.prSeq)) {
        this.$alert({title: '구매요청품의', message: '저장되지 않은 구매요청내역이 존재합니다.'});
        return false;
      }
      this.openPrApproval();
    },
    onApprovalEmit() {
      this.callEvent({name: 'PrList_Search', param: this.prInfo.prNo});
    },
    getTime(hour, minute) {
      hour = hour ? hour : '00';
      minute = minute ? minute : '00';
      return `${hour}:${minute}`;
    },
    onChangeTime(key, value) {
      const hour = value.split(':')[0];
      const minute = value.split(':')[1];
      this.setPrInfo({[key + 'Hh']: hour, [key + 'Mm']: minute});
    },
  },
};
</script>

<style></style>
