<template>
  <pmis-page :loading="loading">
    <iui-container-new type="css-flex">
      <i-row height="450px">
        <i-col width="450px">
          <pmis-tab-box>
            <template v-slot:title>일위대가분류</template>
            <template v-slot:title-right>
              <iui-button @click="breakdownCostClDelete">대가분류삭제</iui-button>
            </template>
            <BreakdownCostCl :eventNms="{search: 'searchInCl'}" :callback="onSearchDetail"></BreakdownCostCl>
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row height="240px">
              <i-col>
                <DetailInfo :loading.sync="loading" />
              </i-col>
            </i-row>
            <i-spacer />
            <i-row>
              <i-col>
                <DetailList />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
      <i-spacer />
      <i-row minHeight="200px">
        <i-col>
          <pmis-content-box>
            <template v-slot:title
              >일위대가내역 <b>자원구분[M:재료비 L:노무비 E:장비비 A:경비 I:일위대가]</b>
            </template>
            <template v-slot:title-right>
              <iui-button @click="onDelByitemDtls">행삭제</iui-button>
              <iui-button @click="onPop('breakdownCostCopyPop')">대가내역복사</iui-button>
              <iui-button @click="onPop('resourceCopyPop')">자원복사</iui-button>
              <iui-button @click="onPop('untpcApplcPop')">단가적용</iui-button>
              <iui-button @click="onPop('arithmeticPop')" v-if="isFormula">산식변경</iui-button>
              <iui-button @click="onPop('arithmeticPop')" v-else>산식작성</iui-button>
            </template>
            <DetailListDtls :eventNms="{removeAll: 'removeAllInDtls'}" />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>
<script>
import store from './store/breakdownCost.js';
import BreakdownCostCl from './components/BreakdownCostCl.vue';
import DetailInfo from './components/DetailInfo.vue';
import DetailList from './components/DetailList.vue';
import DetailListDtls from './components/DetailListDtls.vue';
import {
  deleteSystemComcode,
  deleteSystemSctm,
  deleteSystemSctmDList,
  saveSystemSctm,
  saveSystemSctmD,
} from './api/BreakdownCost.js';

export default {
  components: {
    BreakdownCostCl,
    DetailInfo,
    DetailList,
    DetailListDtls,
  },
  data() {
    return {
      loading: false,
    };
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['detailInfo', 'breakdownCostCl', 'isFormula']);
    $mapMutations(this, ['initDetailInfo', 'setDetailInfo', 'setFocusClKey', 'setFocusItemCodeKey']);
    $mapActions(this, ['onSearchList']);
  },
  created() {
    let event = [{name: 'onSearch', func: this.onSearch}];
    this.addEvent(event);
    this.addFuncSearch(this.onSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDel);
  },
  methods: {
    onSearch() {
      this.callEvent({
        name: 'searchInCl',
      });
    },
    onSearchDetail(row) {
      this.setCud(0);
      this.callEvent({name: 'removeAllInDtls'});
      this.onSearchList();
    },
    onAdd() {
      this.initDetailInfo();
      this.callEvent({name: 'initForm', param: 'add'});
    },
    async onSave() {
      let clSave = true;
      this.callEvent({
        name: 'getSaveJsonAsBreakDownDtl',
        param: async data => {
          if (data.length) {
            clSave = false;
            const response = await saveSystemSctmD({breakdownCostDtlsList: data});
            if (response.data) {
              this.setFocusItemCodeKey(this.detailInfo['itemCode']);
              this.onSearchList();
            }
          }
        },
      });
      if (!clSave) return;

      const focus = this.cud === 1 ? this.detailInfo : this.breakdownCostCl;
      this.setFocusClKey(focus['sctmScode'] || focus['sctmMcode'] || focus['sctmLcode']);
      this.setFocusItemCodeKey(this.detailInfo['itemCode']);

      const detailInfo = {};
      for (let [key, value] of Object.entries(this.detailInfo)) {
        if (value) {
          detailInfo[key] = value;
        }
      }
      try {
        const param = {
          ...this.breakdownCostCl,
          ...detailInfo,
        };

        const response = await saveSystemSctm(param);
        if (response.data) {
          this.callEvent({name: 'reloadSheet'});
          if (param.itemCode) {
            this.onSearchList();
          }
        }
      } catch (error) {
        this.$alert({title: '저장', message: error.message});
      }
    },
    onDel() {
      let obj = {
        name: 'getRowsByCheckedFromBreakDown',
        param: async data => {
          if (!data.length) {
            this.$alert({title: '삭제', message: '삭제할 일위대가를 선택해주세요'});
            return;
          }
          if (!(await this.$confirm({title: '삭제', message: '일위대가를 삭제하시겠습니까?'}))) {
            return;
          }

          try {
            const param = {
              breakdownCostDeleteReqDtoList: data.map(item => ({itemCode: item.itemCode})),
            };
            const response = await deleteSystemSctm(param);
            if (response.data) {
              this.callEvent({name: 'removeAllInDtls'});
              this.onSearchList();
            }
          } catch (error) {
            this.$alert({title: '일위대가삭제', message: error.description});
          }
        },
      };
      this.callEvent(obj);
    },
    async breakdownCostClDelete() {
      if (this.breakdownCostCl.level === 1) {
        this.$alert({title: '삭제', message: '최상위 분류는 삭제할 수 없습니다.'});
        return;
      }
      if (
        !(await this.$confirm({
          title: '삭제',
          message: '선택한 분류를 삭제하시겠습니까? <br /> 하위 분류 및 일위대가가 모두 삭제 됩니다.',
        }))
      ) {
        return;
      }

      try {
        this.cud = 3;
        this.setFocusClKey(this.breakdownCostCl.upCode);
        let param = {
          itemCode: this.breakdownCostCl.code,
        };
        const response = await deleteSystemComcode(param);
        if (response.data) {
          this.callEvent({name: 'reloadSheet'});
        }
      } catch (error) {
        this.$alert({title: '대가분류삭제', message: error.message});
      }
    },
    onPop(modalNm) {
      this.callEvent({name: 'onPop', param: modalNm});
    },
    onDelByitemDtls() {
      this.callEvent({
        name: 'getRowsByCheckedAsBreakDownDtl',
        param: async data => {
          if (!data.length) {
            this.$alert({title: '삭제', message: '삭제할 내역을 선택해주세요.'});
            return;
          }

          let itemCode2List = [];
          data.some(row => {
            itemCode2List.push({
              itemSeq: row.itemSeq,
              itemCode2: row.itemCode2,
            });
          });

          if (!(await this.$confirm({title: '삭제', message: '선택된 내역을 삭제하시겠습니까?'}))) {
            return;
          }

          const param = {
            itemCode1: this.detailInfo.itemCode,
            itemCode2List: itemCode2List,
          };
          const response = await deleteSystemSctmDList(param);
          if (response.data) {
            this.callEvent({
              name: 'selectSystemSctmD',
            });
          }
        },
      });
    },
  },
};
</script>
