export default that => {
  return {
    Cfg: {
      FitWidth: 1,
      HeaderCheck: 1,
    },
    Cols: [
      {
        Header: '장비코드',
        Name: 'equipCode',
        Type: 'Text',
        CanEdit: 0,
        Align: 'center',
      },
      {
        Header: '장비명칭',
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
        Align: 'left',
      },
      {
        Header: '사용구분',
        Name: 'useGb',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
        Align: 'left',
        Format: $getConstants('USE_GB').code,
      },
      {
        Header: '차량번호',
        Name: 'carNo',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
        Align: 'left',
      },
      {
        Header: '규격',
        Name: 'size',
        Type: 'Text',
        Visible: 0,
      },
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Visible: 0,
      },
      {
        Header: '거래처명',
        Name: 'frmNm',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
        Align: 'left',
      },
      {Name: 'custCode', Visible: 0},
      {Name: 'runPrice', Visible: 0},
    ],
  };
};
