<template>
  <pmis-content-box>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              복제원본
            </template>
            <template v-slot:header-left>
              <label>회사구분</label
              ><iui-select
                :p-code="$getConstants('FRM_GB').code"
                :exclude-items="$getConstants('FRM_GB_4').code"
                :value.sync="frmGb"
                @change="onFrmGbChange('origin')"
              />
            </template>

            <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData1" @loadSheet="loadSheet1" />
          </pmis-content-box>
        </i-col>
        <i-col width="50px" style="display: flex; justify-content: center; align-items: center">
          <iui-button value="→" @click="onCopy" />
          <i-spacer />
          <iui-button value="←" @click="onDelete" />
        </i-col>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              복제대상
            </template>
            <template v-slot:header-left v-if="isBase">
              <label>회사구분</label
              ><iui-select
                :p-code="$getConstants('FRM_GB').code"
                :exclude-items="`${$getConstants('FRM_GB_0').code},${$getConstants('FRM_GB_4').code}`"
                :value.sync="frmGb2"
                defaultCd="S"
                @change="onFrmGbChange('target')"
              />
            </template>
            <template v-slot:header-left v-else>
              <label>[{{ pgmCd }}] {{ userInfo.frmNm }}</label>
            </template>

            <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData2" @loadSheet="loadSheet2" />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import {
  selectMenuList as baseSelectMenuList,
  deleteMenuList as baseDeleteMenuList,
  copyMenu as baseCopyMenu,
} from '../../api/baseMenu.js';
import {selectMenuList, deleteMenuList, copyMenu} from '../../api/menu.js';
import options from '../sheetOption/progHedofcMenuSheet.js';
export default {
  props: {
    isBase: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sheet1: undefined,
      sheet2: undefined,
      Options: $addColCheckbox(options(this)),
      loadSearchData1: [],
      loadSearchData2: [],
      frmGb: $getConstants('FRM_GB_0').code,
      frmGb2: '',
    };
  },

  created() {
    this.onSearch('origin');
    if (!this.isBase) this.onSearch('target');
  },

  methods: {
    loadSheet1(sheet) {
      this.sheet1 = sheet;
      this.sheet1.util.onTreeCheck('chk');
    },
    loadSheet2(sheet) {
      this.sheet2 = sheet;
      this.sheet2.util.onTreeCheck('chk', 'child');
    },
    async onSearch(target) {
      let response;

      if (target === 'origin') {
        response = await baseSelectMenuList({frmGb: this.frmGb});
        let items = $_treeModel(response.data, '!0!', 'mnuCd', 'uppMnuCd');
        this.loadSearchData1 = items;
      } else {
        if (this.isBase) {
          response = await baseSelectMenuList({frmGb: this.frmGb2});
        } else {
          response = await selectMenuList({});
        }
        let items = $_treeModel(response.data, '!0!', 'mnuCd', 'uppMnuCd');
        this.loadSearchData2 = items;
      }
    },
    onFrmGbChange(target) {
      this.onSearch(target);
    },
    async onCopy() {
      if (this.isBase && !this.frmGb2) {
        this.$alert('복제대상 회사구분을 선택해 주세요.');
        return;
      }
      let rows = this.sheet1.getRowsByChecked('chk');
      if (!rows.length) {
        this.$alert('복제할 프로그램을 선택해 주세요.');
        return;
      }
      let items = [];
      rows.forEach(el => {
        items.push({
          mnuCd: el.mnuCd,
          mnuNm: el.mnuNm,
          mnuNmEng: el.mnuNmEng,
          mnuPth: el.mnuPth,
          para: el.para,
          malPth: el.malPth,
          vwYn: el.vwYn,
          svYn: el.svYn,
          delYn: el.delYn,
          prnYn: el.prnYn,
          mnuSqn: el.mnuSqn,
          uppMnuCd: el.uppMnuCd,
          useDs: el.useDs,
          ...(this.isBase ? {frmGb: this.frmGb2, copyFrmGb: this.frmGb} : {pgmCd: this.pgmCd, frmGb: this.frmGb}),
        });
      });

      try {
        const param = {
          items,
        };
        const response = this.isBase ? await baseCopyMenu(param) : await copyMenu(param);
        if (response.data) {
          this.onSearch('target');
        }
      } catch (error) {
        this.$alert({title: '복제', message: error.message});
      }
    },

    async onDelete() {
      let rows = this.sheet2.getRowsByChecked('chk');
      if (!rows.length) {
        this.$alert('삭제할 프로그램을 선택해 주세요.');
        return;
      }
      let items = [];
      rows.forEach(el => {
        items.push({
          mnuCd: el.mnuCd,
          ...(this.isBase ? {frmGb: this.frmGb2} : {pgmCd: this.pgmCd}),
        });
      });

      let response;
      let param = {items};
      if (this.isBase) {
        response = await baseDeleteMenuList(param);
      } else {
        response = await deleteMenuList(param);
      }
      if (response.data) {
        this.onSearch('target');
      }
    },
  },
};
</script>

<style></style>
