<template>
  <pmis-tab-box>
    <template v-slot:title>상세정보</template>
    <form onsubmit="return false;">
      <iui-container-new type="table" theme="bullet" header-width="120px">
        <colgroup>
          <col />
          <col width="400px" />
          <col />
          <col />
          <col />
        </colgroup>
        <i-row>
          <i-col-header :required="lEnable">
            대분류
          </i-col-header>
          <i-col>
            <iui-text
              regExp="[^a-zA-z]"
              width="30px"
              :value="breakdownCostCl.sctmLcode || detailInfo.sctmLcode"
              @change="setDetailInfo({sctmLcode: $event.target.value})"
              :enable="lEnable"
              max-length="1"
              :required="lEnable"
              :errorMessage="{title: '대분류', message: '대분류 코드를 입력해주세요.'}"
            />
          </i-col>
          <i-spacer />
          <i-col>
            <iui-text
              :value="breakdownCostCl.sctmLcodeNm || detailInfo.sctmLcodeNm"
              @change="setDetailInfo({sctmLcodeNm: $event.target.value})"
              :enable="lNmEnable"
              :required="lEnable"
              max-length="100"
              :errorMessage="{title: '대분류', message: '대분명을 입력해주세요.'}"
            />
          </i-col>
          <i-col></i-col>
        </i-row>
        <i-row>
          <i-col-header :required="mEnable">
            중분류
          </i-col-header>
          <i-col>
            <iui-text width="30px" :value="twoLevelSctmLcode" :enable="false" />
            <iui-text
              regExp="[^a-zA-z]"
              width="30px"
              :value="twoLevelSctmMcode"
              @change="
                setDetailInfo({
                  sctmMcode: breakdownCostCl.sctmLcode + $event.target.value,
                })
              "
              :enable="mEnable"
              max-length="1"
              :required="mEnable"
              :errorMessage="{title: '중분류', message: '중분류 코드를 입력해주세요.'}"
            />
          </i-col>
          <i-spacer />
          <i-col>
            <iui-text
              :value="breakdownCostCl.sctmMcodeNm || detailInfo.sctmMcodeNm"
              @change="setDetailInfo({sctmMcodeNm: $event.target.value})"
              :enable="mNmEnable"
              :required="mEnable"
              max-length="100"
              :errorMessage="{title: '중분류', message: '중분류명을 입력해주세요.'}"
            />
          </i-col>
          <i-col>
            <iui-container-new type="table" theme="bullet">
              <i-row>
                <i-spacer />
                <i-col-header> 하위분류존재</i-col-header>
                <i-col>
                  <iui-checkbox-group
                    :p-code="$getConstants('SCTM_SLCODE_YN').code"
                    :checkedValues="[breakdownCostCl.sctmSlcodeYn]"
                    @change="onChangeSctmSlcodeYn"
                    :enable="mEnable"
                  />
                </i-col>
              </i-row>
            </iui-container-new>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header :required="sEnable">
            소분류
          </i-col-header>
          <i-col>
            <iui-text width="30px" :value="threeLevelSctmLcode" :enable="false" />
            <iui-text width="30px" :value="threeLevelSctmMcode" :enable="false" />
            <iui-text
              regExp="[^a-zA-z]"
              width="30px"
              :value="threeLevelSctmScode"
              @change="
                setDetailInfo({
                  sctmScode: detailInfo.sctmMcode + $event.target.value,
                })
              "
              :enable="sEnable"
              max-length="1"
              :required="sEnable"
              :errorMessage="{title: '소분류', message: '소분류 코드를 입력해주세요.'}"
            />
          </i-col>
          <i-spacer />
          <i-col>
            <iui-text
              :value="breakdownCostCl.sctmScodeNm || detailInfo.sctmScodeNm"
              @change="setDetailInfo({sctmScodeNm: $event.target.value})"
              :enable="sNmEnable"
              :required="sEnable"
              max-length="100"
              :errorMessage="{title: '소분류', message: '소분명을 입력해주세요.'}"
            />
          </i-col>
          <i-col></i-col>
        </i-row>
        <i-row> <i-col></i-col><i-col></i-col><i-col></i-col><i-col></i-col><i-col></i-col> </i-row>
        <i-row>
          <i-col-header :required="cud === 1 && itemEnable"> 일위대가코드 </i-col-header>
          <i-col>
            <iui-text width="30px" :enable="false" :value="getItemCode(0, 1)" />
            <iui-text width="30px" :enable="false" :value="getItemCode(1, 2)" />
            <iui-text width="30px" :enable="false" :value="getItemCode(2, 3)" />
            <iui-text
              :value="getItemCode(3, 12)"
              @change="setItemCode"
              :enable="cud === 1 && itemEnable"
              :required="cud === 1 && itemEnable"
              :errorMessage="{title: '일위대가', message: '일위대가 코드를 입력해주세요.'}"
              max-length="9"
              min-length="9"
              width="70px"
            />
          </i-col>
          <i-spacer />
          <i-col>
            <iui-text
              :value="detailInfo.itemName"
              @change="setDetailInfo({itemName: $event.target.value})"
              :enable="itemEnable"
              :required="cud === 1 && itemEnable"
              max-length="50"
              :errorMessage="{title: '일위대가', message: '일위대가명을 입력해주세요.'}"
            />
          </i-col>
          <i-col></i-col>
        </i-row>
        <i-row>
          <i-col-header>
            규격
          </i-col-header>
          <i-col>
            <iui-text
              :value="detailInfo.ssize"
              @change="setDetailInfo({ssize: $event.target.value})"
              :enable="itemEnable"
            />
          </i-col>
          <i-spacer />
          <i-col>
            <iui-container-new type="table" theme="bullet" header-width="60px">
              <colgroup>
                <col />
                <col />
              </colgroup>
              <i-row>
                <i-col-header>
                  단위
                </i-col-header>
                <i-col>
                  <iui-text name="unitName" type="smallcode" :value="unitName" :enable="false" />
                  <iui-button value="검색" @click="onUnitPopup" v-if="itemEnable" />
                </i-col>
              </i-row>
            </iui-container-new>
          </i-col>
          <i-col></i-col>
        </i-row>
        <i-row>
          <i-col-header>
            사용여부
          </i-col-header>
          <i-col>
            <iui-checkbox-group
              :checkedValues="[detailInfo.useDs]"
              :items="[{label: '사용', value: $getConstants('Y').code}]"
              @change="setDetailInfo({useDs: $event.target.checked ? $event.target.value : ''})"
              :enable="itemEnable"
            />
          </i-col>
          <i-spacer />
          <i-col> </i-col>
        </i-row>
      </iui-container-new>
    </form>
    <iui-modal name="unitModal" title="단위 검색" width="1000px" height="500px" :btns="modalBtns">
      <UnitPopup />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import UnitPopup from '@/view/sysManage/stdCodeManage/components/UnitPopup.vue';

export default {
  components: {UnitPopup},
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lEnable: false,
      lNmEnable: false,
      mEnable: false,
      mNmEnable: false,
      sEnable: false,
      sNmEnable: false,
      itemEnable: false,
      modalBtns: [{name: '확인', callback: this.onUnitPopupConfirm}],
      unitCodeList: [],
    };
  },

  computed: {
    twoLevelSctmLcode() {
      return this.cud == 1
        ? this.breakdownCostCl.sctmLcode
        : String(this.breakdownCostCl.sctmMcode).slice(0, 1) || String(this.detailInfo.sctmMcode).slice(0, 1);
    },
    twoLevelSctmMcode() {
      return String(this.detailInfo.sctmMcode).slice(1, 2) || String(this.breakdownCostCl.sctmMcode).slice(1, 2);
    },
    threeLevelSctmLcode() {
      return this.cud == 1 && this.breakdownCostCl.level === 3 && this.detailInfo.sctmSlcodeYn
        ? this.detailInfo.sctmLcode
        : String(this.breakdownCostCl.sctmScode).slice(0, 1) || String(this.detailInfo.sctmScode).slice(0, 1);
    },
    threeLevelSctmMcode() {
      return this.cud == 1 && this.breakdownCostCl.level === 3 && this.detailInfo.sctmSlcodeYn
        ? String(this.detailInfo.sctmMcode).slice(1, 2)
        : String(this.breakdownCostCl.sctmScode).slice(1, 2) || String(this.detailInfo.sctmScode).slice(1, 2);
    },
    threeLevelSctmScode() {
      return this.cud == 1 && this.detailInfo.sctmSlcodeYn
        ? String(this.detailInfo.sctmScode).slice(2, 3)
        : String(this.breakdownCostCl.sctmScode).slice(2, 3) || String(this.detailInfo.sctmScode).slice(2, 3);
    },
    unitName() {
      const codeInfo = this.unitCodeList.find(item => item.code === String(this.detailInfo.unit));
      return codeInfo?.codeNm;
    },
  },

  beforeCreate() {
    $mapGetters(this, ['detailInfo', 'breakdownCostCl']);
    $mapMutations(this, ['setDetailInfo', 'setFocusItemCodeKey']);
    $mapActions(this, ['onSearchList']);
  },

  created() {
    this.addEvent([{name: 'initForm', func: this.initForm}]);
    this.setUnitCodeList();
  },

  methods: {
    async setUnitCodeList() {
      this.unitCodeList = await $getCode($getConstants('UNIT').code);
    },
    initForm(param) {
      if (param == 'inq') {
        this.lEnable = false;
        this.lNmEnable = this.breakdownCostCl.level === 2;
        this.mEnable = false;
        this.mNmEnable = this.breakdownCostCl.level === 3;
        this.sEnable = false;
        this.sNmEnable = this.breakdownCostCl.level === 4;
        if (this.detailInfo.itemCode) {
          this.itemEnable = true;
        } else {
          this.itemEnable = false;
        }
      } else {
        if (this.breakdownCostCl.level == 1) {
          this.lEnable = true;
          this.lNmEnable = true;
          this.mEnable = false;
          this.mNmEnable = false;
          this.sEnable = false;
          this.sNmEnable = false;
          this.itemEnable = false;
        } else if (this.breakdownCostCl.level == 2) {
          this.lEnable = false;
          this.lNmEnable = true;
          this.mEnable = true;
          this.mNmEnable = true;
          this.sEnable = false;
          this.itemEnable = false;
          this.setDetailInfo({
            sctmLcode: this.breakdownCostCl.sctmLcode,
          });
        } else if (this.breakdownCostCl.level == 3) {
          this.lEnable = false;
          this.lNmEnable = false;
          this.mEnable = false;
          this.mNmEnable = true;
          if (this.breakdownCostCl.sctmSlcodeYn) {
            this.sEnable = true;
            this.sNmEnable = true;
            this.itemEnable = false;
          } else {
            this.sEnable = false;
            this.sNmEnable = false;
            this.itemEnable = true;
          }
          this.setDetailInfo({
            sctmLcode: this.breakdownCostCl.sctmLcode,
            sctmLcodeNm: this.breakdownCostCl.sctmLcodeNm,
            sctmMcode: this.breakdownCostCl.sctmMcode,
            sctmMcodeNm: this.breakdownCostCl.sctmMcodeNm,
            sctmSlcodeYn: this.breakdownCostCl.sctmSlcodeYn,
            unit: !this.breakdownCostCl.sctmSlcodeYn ? $getConstants('UNIT_EXPRESSION').code : undefined,
            useDs: !this.breakdownCostCl.sctmSlcodeYn ? $getConstants('USE_YN_Y').code : undefined,
          });
        } else {
          this.lEnable = false;
          this.lNmEnable = false;
          this.mEnable = false;
          this.mNmEnable = false;
          this.sEnable = false;
          this.sNmEnable = true;
          this.itemEnable = true;
          this.setDetailInfo({
            sctmLcode: this.breakdownCostCl.sctmLcode,
            sctmLcodeNm: this.breakdownCostCl.sctmLcodeNm,
            sctmMcode: this.breakdownCostCl.sctmMcode,
            sctmMcodeNm: this.breakdownCostCl.sctmMcodeNm,
            sctmScode: this.breakdownCostCl.sctmScode,
            sctmScodeNm: this.breakdownCostCl.sctmScodeNm,
            sctmSlcodeYn: this.breakdownCostCl.sctmSlcodeYn,
            itemCode: this.breakdownCostCl.code,
            unit: $getConstants('UNIT_EXPRESSION').code,
            useDs: $getConstants('USE_YN_Y').code,
          });
        }
      }
    },
    onChangeSctmSlcodeYn(e) {
      this.setDetailInfo({
        sctmSlcodeYn: e.target.checked ? e.target.value : '',
      });
    },
    getItemCode(sIndex, eIndex) {
      if (this.cud === 1 && this.itemEnable) {
        const prefix = this.breakdownCostCl.code + (String(this.breakdownCostCl.code).length === 2 ? '0' : '');
        const itemCode = this.detailInfo.itemCode.slice(3, 12);
        return String(prefix + itemCode).slice(sIndex, eIndex);
      } else {
        return this.detailInfo.itemCode.slice(sIndex, eIndex);
      }
    },
    setItemCode(e) {
      if (this.breakdownCostCl.level == 3) {
        // 중분류 일경우 3번째자리를 zero padding 처리
        this.setDetailInfo({itemCode: this.breakdownCostCl.code + '0' + e.target.value});
      } else {
        // 소분류
        this.setDetailInfo({itemCode: this.breakdownCostCl.code + e.target.value});
      }
    },
    onUnitPopup() {
      this.$modal.show('unitModal');
    },
    onUnitPopupConfirm() {
      this.callEvent({
        name: 'UnitPopupp_popConfirm',
        param: data => {
          this.setDetailInfo({unit: data.unitCode, unitName: data.unitName});
          this.$modal.hide('unitModal');
        },
      });
    },
  },
};
</script>

<style scoped></style>
