import {getWbsHeaderList} from '../../api/workPlanRegist.js';
const getCols = async that => {
  const headerList = await getWbsHeaderList({});
  let headerNmList = headerList.data;
  let Cols = [];
  for (let headerNm of headerNmList) {
    let headerObj = {};
    headerObj['Header'] = [
      {Value: '단위작업예정물량', Color: '#7ed6df'},
      headerNm.headGrpName,
      {Value: headerNm.wbsNm, Color: '#dff9fb'},
    ];
    headerObj['Name'] = headerNm.wbsId + '_' + headerNm.wbsCd;
    headerObj['Type'] = 'Text';
    headerObj['MinWidth'] = 120;
    headerObj['Extend'] = that.$ibsheetHelper.preset.quantity;
    headerObj['DefaultValue'] = '';
    headerObj['CanEdit'] = false;
    headerObj['ColorFormula'] = function(fr) {
      return fr.Value !== '' && !isNaN(fr.Value) ? '' : '#dddddd';
    };
    Cols.push(headerObj);
  }
  return Cols;
};

export default async that => {
  return {
    //각 컬럼에 대한 정의 (컬럼의 이름,유형(Type),포맷(Format)등을 설정)
    //컬럼의 "Type"과 "Name" 속성은 반드시 설정되어야 합니다.
    LeftCols: [
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '공종명', '공종명'],
        Name: 'midCode',
        Type: 'Text',
        Width: 100,
        CanEdit: 0,
        Visible: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '공종명', '공종명'],
        Name: 'mgName',
        Type: 'Text',
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '내역\n코드', '내역\n코드'],
        Name: 'itemSeq',
        Type: 'Text',
        Width: 70,
        CanEdit: 0,
        WidthPad: 37,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '내역명', '내역명'],
        Name: 'itemName',
        Type: 'Text',
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '규격', '규격'],
        Name: 'ssize',
        Type: 'Text',
        Width: 100,
        CanEdit: 0,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '단위', '단위'],
        Name: 'unit',
        Type: 'Text',
        Width: 60,
        CanEdit: 0,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: [{Value: '도급내역', Color: '#7ed6df'}, '수량', '수량'],
        Name: 'qty',
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 100,
        CanEdit: 0,
      },
    ],
    Cols: await getCols(that),
    Cfg: {
      CanEdit: 1,
      HeaderMerge: 6,
    },
  };
};
