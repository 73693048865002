<template>
  <div class="tit_w">
    <div class="fl">
      <h3>
        <slot />
      </h3>
    </div>
    <p style="padding: 0px; padding-right: 3px;">
      <slot name="button"></slot>
    </p>
    <p v-if="isUnit" class="unit">[단위 : 원]</p>
  </div>
</template>

<script>
export default {
  props: {
    isUnit: {
      type: Boolean,
      default: false,
    },
  },
  name: 'pmis-subtitle',
};
</script>

<style scoped>
.unit {
  text-align: right;
  /* font-weight: bold;
  margin-top: 10px;
  margin-right: 5px; */
}
</style>
