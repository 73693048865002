import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
export default that => {
  return {
    Cfg: {CanEdit: 0},
    Def: {Row: {NoColor: 1}},
    Cols: [
      {Header: '현장명', Name: 'spotDeptnm', Type: 'Text', RelWidth: 1},
      {
        Header: '계약번호',
        Name: 'poNo',
        Type: 'Text',
        Align: 'center',
        Width: 120,
        CustomFormat: v => COMMON_FUNCTION.getMakeFormat(v),
      },
      {Header: '계약명', Name: 'ttl', Type: 'Text', RelWidth: 1},
      {
        Header: '진행상태',
        Name: 'poSt',
        Type: 'Text',
        Align: 'center',
        Width: 120,
        Format: $getConstants('BIDDING_ST').code,
      },
    ],
  };
};
