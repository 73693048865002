const IuiItemsMixin = {
  props: {
    pCode: {
      type: String,
      default: undefined,
    },
    items: {
      type: [Array, Object],
      default: undefined,
    },
    excludeItems: {
      type: [Array, Object, String],
      default: undefined,
    },
    itemKeyAlias: {
      type: String,
      default: undefined,
    },
    itemTextAlias: {
      type: String,
      default: undefined,
    },
    itemValueAlias: {
      type: String,
      default: undefined,
    },
    itemEnableAlias: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      internalItems: undefined,
      internalExcludeItems: undefined,
      internalItemKeyAlias: 'value',
      internalItemTextAlias: 'text',
      internalItemValueAlias: 'value',
      internalItemEnableAlias: 'enable',
    };
  },
  watch: {
    items(newValue) {
      this.internalItems = newValue;
      this.init();
    },
    excludeItems(newValue) {
      this.internalExcludeItems = newValue;
      this.init();
    },
    itemKeyAlias(newValue) {
      this.internalItemKeyAlias = newValue;
    },
    itemTextAlias(newValue) {
      this.internalItemTextAlias = newValue;
    },
    itemValueAlias(newValue) {
      this.internalItemValueAlias = newValue;
    },
    itemEnableAlias(newValue) {
      this.internalItemEnableAlias = newValue;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.excludeItems !== undefined) {
        if (typeof this.excludeItems === 'string') {
          try {
            if (this.internalExcludeItems === undefined) {
              this.internalExcludeItems = this.excludeItems.split(',');
            }
          } catch (ex) {
            console.error(ex);
          }
        } else {
          this.internalExcludeItems = this.excludeItems;
        }
      }

      if (this.pCode !== undefined) {
        $getCode(this.pCode, this.internalExcludeItems).then(codes => {
          this.internalItems = codes;
        });

        this.internalItemKeyAlias = 'code';
        this.internalItemTextAlias = 'codeNm';
        this.internalItemValueAlias = 'code';
      } else {
        if (this.items !== undefined) {
          this.internalItems = this.items;
        }
      }

      if (this.itemKeyAlias !== undefined) {
        this.internalItemKeyAlias = this.itemKeyAlias;
      }
      if (this.itemTextAlias !== undefined) {
        this.internalItemTextAlias = this.itemTextAlias;
      }
      if (this.itemValueAlias !== undefined) {
        this.internalItemValueAlias = this.itemValueAlias;
      }
      if (this.itemEnableAlias !== undefined) {
        this.internalItemEnableAlias = this.itemEnableAlias;
      }
    },
  },
};

export default IuiItemsMixin;
