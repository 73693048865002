import {selectPrePayable, selectSubMonDetailList, selectSubMonList} from '../api/fixedTimeSubcon.js';

const state = {
  searchInfo: {
    investMm: '',
    custName: '',
  },
  detailInfo: {
    custCode: '',
    poNoSubc: '',
    curInputAmt: 0,
    curDeductAmt: 0,
    curEtcinputAmt: 0,
    prePayable: 0,
    curReserveAmt: 0,
    amt: 0,
    vatAmt: 0,
    taxexe: 0,
    sumAmt: 0,
    billNo: '',
    flNo: 0,
    billTaxNo: '',
    flTaxNo: 0,
  },
  subMonList: [],
  calculateList: [],
  closeYn: '',
};

const getters = {
  searchInfo: state => state.searchInfo,
  detailInfo: state => state.detailInfo,
  subMonList: state => state.subMonList,
  calculateList: state => state.calculateList,
  closeYn: state => state.closeYn,
  fixedTimeSubSaveDataReqDto: state => {
    const keys = [
      'investMm',
      'custCode',
      'poNoSubc',
      'curDeductAmt',
      'curEtcinputAmt',
      'curReserveAmt',
      'amt',
      'vatAmt',
      'taxexe',
      'sumAmt',
    ];
    let param = {};
    for (let key in state.searchInfo) {
      if (keys.findIndex(item => item === key) !== -1) {
        param[key] = state.searchInfo[key];
      }
    }
    for (let key in state.detailInfo) {
      if (keys.findIndex(item => item === key) !== -1) {
        param[key] = state.detailInfo[key];
      }
    }
    return param;
  },
};

const mutations = {
  setSearchInfo(state, payload) {
    for (let key in payload) {
      if (state.searchInfo.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  initDetailInfo(state) {
    let NumberKeys = [
      'curInputAmt',
      'curDeductAmt',
      'curEtcinputAmt',
      'prePayable',
      'curReserveAmt',
      'amt',
      'vatAmt',
      'taxexe',
      'sumAmt',
      'flNo',
      'flTaxNo',
    ];
    for (let key in state.detailInfo) {
      state.detailInfo[key] = NumberKeys.indexOf(key) !== -1 ? 0 : '';
    }
  },
  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = payload[key];
      }
    }
  },
  setSubMonList(state, payload) {
    state.subMonList = payload;
  },
  initCalculateList(state) {
    state.calculateList = [];
  },
  setCalculateList(state, payload) {
    state.calculateList = payload;
  },
  setSumAmt(state) {
    state.detailInfo.sumAmt = state.detailInfo.amt + state.detailInfo.vatAmt + state.detailInfo.taxexe;
  },
  setCloseYn(state, closeYn) {
    state.closeYn = closeYn;
  },
};

const actions = {
  async selectPrePayable({state, commit}) {
    const param = {
      investMm: state.searchInfo.investMm,
      custCode: state.detailInfo.custCode,
      poNoSubc: state.detailInfo.poNoSubc,
    };
    const response = await selectPrePayable(param);
    commit('setDetailInfo', {prePayable: response.data});
  },
  async selectSubMonDetailList({state, commit}) {
    const param = {
      investMm: state.searchInfo.investMm,
      custCode: state.detailInfo.custCode,
      poNoSubc: state.detailInfo.poNoSubc,
    };
    const response = await selectSubMonDetailList(param);
    commit('setCalculateList', response.data);
  },

  async selectSubMonList({state, commit}) {
    commit('initDetailInfo');
    commit('initCalculateList');
    const response = await selectSubMonList(state.searchInfo);
    const subMonList = response.data;
    commit('setSubMonList', subMonList);
  },

  setCurEtcinputAmt({state, commit}, curEtcinputAmt) {
    let amt =
      state.detailInfo.curInputAmt +
      state.detailInfo.prePayable +
      curEtcinputAmt -
      state.detailInfo.curDeductAmt -
      state.detailInfo.curReserveAmt;
    let vatAmt = $_getVatAmt(amt, state.detailInfo.taxexe);
    commit('setDetailInfo', {curEtcinputAmt, amt, vatAmt});
    commit('setSumAmt');
  },
  setCurDeductAmt({state, commit}, curDeductAmt) {
    let amt =
      state.detailInfo.curInputAmt +
      state.detailInfo.prePayable +
      state.detailInfo.curEtcinputAmt -
      curDeductAmt -
      state.detailInfo.curReserveAmt;
    let vatAmt = $_getVatAmt(amt, state.detailInfo.taxexe);
    commit('setDetailInfo', {curDeductAmt, amt, vatAmt});
    commit('setSumAmt');
  },
  setCurReserveAmt({state, commit}, curReserveAmt) {
    let amt =
      state.detailInfo.curInputAmt +
      state.detailInfo.prePayable +
      state.detailInfo.curEtcinputAmt -
      state.detailInfo.curDeductAmt -
      curReserveAmt;
    let vatAmt = $_getVatAmt(amt, state.detailInfo.taxexe);
    commit('setDetailInfo', {curReserveAmt, amt, vatAmt});
    commit('setSumAmt');
  },
  setVatAmt({commit}, vatAmt) {
    commit('setDetailInfo', {vatAmt});
    commit('setSumAmt');
  },
  setTaxexe({state, commit}, taxexe) {
    taxexe = Number(taxexe || 0);
    if (state.detailInfo.amt < taxexe) {
      taxexe = state.detailInfo.amt;
    }
    const vatAmt = state.detailInfo.amt * 0.1 - taxexe < 0 ? 0 : state.detailInfo.amt * 0.1 - taxexe;
    commit('setDetailInfo', {vatAmt, taxexe});
    commit('setSumAmt');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
