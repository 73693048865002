<template>
  <div style="width:100%; height: 100%" id="OZViewerDiv">
    <div id="OZViewer"></div>
  </div>
</template>

<script>
import {selectTemplateInfo} from '@/view/sysManage/baseSysManage/StandardTemplateRegist/apis/template.js';
export default {
  props: {
    fomNo: {
      type: [Number, String],
      default: undefined,
    },
    ozContent: {
      type: Object,
      default: undefined,
    },
    ozInputJsonStr: {
      type: Object,
      default: undefined,
    },
  },
  created() {
    this.addEvent({name: 'LaborContractViewer_GetSignData', func: this.getSignData});
  },
  data() {
    return {
      OZViewer: '',
    };
  },
  mounted() {
    this.onSearch();
  },
  methods: {
    async onSearch() {
      let param = {pgmCd: this.pgmCd, fomNo: this.fomNo};
      let response = await selectTemplateInfo(param);
      this.onInitOZ(response.data.rptNm);
    },
    onInitOZ(rptNm) {
      window.SetOZParamters_OZViewer = () => {
        let ozParamStr = '';
        let ozSap = ';OZ;';
        let ozServer = '/eform/server';

        let content = this.ozContent ? this.ozContent : {};
        let contentKeys = Object.keys(content);

        for (let i in contentKeys) {
          ozParamStr += `${ozSap}connection.args${Number(i) + 1}=${contentKeys[i]}=${content[contentKeys[i]]}`;
        }

        ozParamStr += `${ozSap}export.mode=false`;
        ozParamStr += `${ozSap}connection.servlet=${ozServer}`;
        ozParamStr += `${ozSap}connection.reportname=${rptNm}`;
        ozParamStr += `${ozSap}connection.pcount=${contentKeys.length}`; //ozr의 입력컴포넌트에 값을 세팅
        ozParamStr += `${ozSap}connection.inputjson=${JSON.stringify(this.ozInputJsonStr)}`; //ozr의 입력컴포넌트에 값을 세팅

        ozParamStr += `${ozSap}viewer.progresscommand=true`;
        ozParamStr += `${ozSap}viewer.ignore_disable_color_inputcomponent=true`;

        let ozParams = ozParamStr.split(ozSap);
        this.OZViewer = document.getElementById('OZViewer');

        for (let key in ozParams) {
          let paramStr = ozParams[key];
          if (paramStr.indexOf('=') > -1) {
            let paramK = paramStr.substring(0, paramStr.indexOf('='));
            let paramV = paramStr.substring(paramStr.indexOf('=') + 1);
            this.OZViewer.sendToActionScript(paramK, paramV);
          }
        }

        this.OZViewer.sendToActionScript('eform.signpad_viewtype', 'scrollable');
        this.OZViewer.sendToActionScript('eform.signpad_type', 'dialog');
        this.OZViewer.sendToActionScript('eform.signpad_keepbackgroundcolor', 'true');
        this.OZViewer.sendToActionScript('eform.signpad_iconposition', 'viewer_top');

        return true;
      };

      start_ozjs('OZViewer', '/eform/ozhviewer/');
    },

    getSignData(callback) {
      let eformData = JSON.parse(this.OZViewer.GetInformation('INPUT_JSON_ALL'));
      callback(eformData.compSignData);
    },
  },
};
</script>

<style></style>
