<template>
  <div class="iuiCarousel">
    <div class="main" ref="main">
      <ul>
        <li v-for="(node, index) in $slots.default" :key="index">
          <input
            type="radio"
            :name="`node${_uid}`"
            :id="`node${_uid}_${index}`"
            :value="index"
            :checked="index == 0 ? true : false"
            @change="onChange"
            :ref="`radio_${_uid}`"
          />
          <label :for="`node${_uid}_${index}`"><span></span></label>
        </li>
      </ul>
      <div class="contents">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
  },
  data() {
    return {};
  },
  created() {
    this.addEvent({name: 'IuiCarousel_SetIndex', func: this.onSetIndex});
  },
  mounted() {
    this.init();
    this.setShowNode(0);
  },
  methods: {
    init() {
      this.$refs.main.style.width = this.width;
      this.$refs.main.style.height = this.height;
    },
    onChange(e) {
      this.setShowNode(e.target.value);
    },
    setShowNode(index) {
      this.$slots.default.forEach((node, _index) => {
        node.elm.style.display = index == _index ? '' : 'none';
      });
    },
    onSetIndex(index) {
      if (index === undefined) index = 0;

      this.$refs[`radio_${this._uid}`].forEach((el, _index) => (el.checked = _index == index));
      this.setShowNode(index);
    },
  },
};
</script>

<style scoped>
.iuiCarousel {
  height: 100%;
  margin: 0 !important;
}
.iuiCarousel > * {
  margin: 0;
  padding: 0;
}
.iuiCarousel ul {
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
}
.iuiCarousel ul > li {
  float: left;
}
.iuiCarousel ul > li label {
  padding: 0 !important;
}

.iuiCarousel .contents {
  height: calc(100% - 30px);
  overflow: hidden;
}

.iuiCarousel .contents > * {
  height: 100%;
}
</style>
