<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="500px">
          <PoList process="progress" />
        </i-col>
        <i-spacer />
        <i-col>
          <iui-tab :comps="tabComps" :visibles="tabVisibles" :isAuto="false" @click="onClickTab">
            <template #tab-right>
              <template v-if="poStatus.isPoSend">
                <iui-button value="계약서 수정요청" @click="onCheckPoState('reject')" />
                <iui-button value="전자서명" @click="onCheckPoState('sign')" />
              </template>
            </template>
          </iui-tab>
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal name="poRejectModal" :title="'계약서 수정요청'" :btns="modalRejectBtns" size-type="size4">
      <PoRejectSupplier />
    </iui-modal>
  </pmis-page>
</template>

<script>
import store from '../store/subPoCustomer.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import {selectCheckPoState, sign} from '@/view/bidding/po/sub/api/subPo.js';

import PoList from '../components/PoList.vue';
import PoRejectSupplier from '@/view/bidding/poCustomer/common/components/PoRejectSupplier.vue';

export default {
  components: {PoList, PoRejectSupplier},
  data() {
    return {
      tabIndex: 0,
      tabComps: [
        {
          tabNm: '계약정보',
          compNm: 'PoInfo',
          path: '@/view/bidding/poCustomer/sub/components/PoInfo.vue',
        },
        {
          tabNm: '인지세',
          compNm: 'ContractStampList',
          path: '@/view/bidding/poManage/contract/components/stamp/ContractStampInfo.vue',
        },
      ],
      modalRejectBtns: [{name: '계약서 수정요청', callback: this.onReject}],
    };
  },
  computed: {
    tabVisibles() {
      const isStamp = this.poStatus.isStamp;
      if (!isStamp) {
        this.onClickTab(0);
      }
      return {ContractStampList: isStamp};
    },
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['poInfo', 'poStatus', 'bidType']);
  },
  methods: {
    onClickTab(index) {
      if (this.tabIndex === index) {
        return;
      }
      this.tabIndex = index;
      this.callEvent({name: 'setActiveTab', param: this.tabIndex});
    },
    async onCheckPoState(processCode) {
      const processTypeObj = {
        reject: ['계약서 수정요청', () => this.$modal.show('poRejectModal')],
        sign: ['전자서명', this.onSign],
      };
      const [title, processFunc] = processTypeObj[processCode];

      const param = {
        poNo: this.poInfo.poNo,
        contSeq: this.poInfo.contSeq,
        isSupplierPage: true,
        processCode,
      };

      const response = await selectCheckPoState(param);
      const checkMessage = response.data;

      if (checkMessage) {
        await this.$alert({title, message: checkMessage});
        this.callEvent({name: 'PoList_onSearch', param: param.poNo});
      } else {
        processFunc();
      }
    },
    onCheckNeedFileBeforeSign() {
      /** 전자서명 전, 구비서류체크 */
      if (this.poInfo.needFileCnt !== this.poInfo.submitFileCnt) {
        this.$alert({
          title: '전자서명',
          message: '제출되지 않은 서류가 존재합니다.<br/>구비서류 제출을 먼저 하시기 바랍니다.',
        });
        return false;
      }
      return true;
    },
    async onSign() {
      if (!this.onCheckNeedFileBeforeSign()) return;
      if (!(await COMMON_FUNCTION.checkCertdnRegistered(this, this.bidType))) return;

      try {
        await this.$secuKit.checkReady();
        await this.$secuKit.showDialog();

        const certType = 'SignCert';
        const certID = this.$secuKit.certListInfo.getCertID();
        const sourceString = this.poInfo.origFileHash;

        // 1. 전자서명
        const r1 = await this.$secuKit.generatePKCS7SignedData({certType, sourceString, certID});

        // 2. 인증서 정보 추출
        const r2 = await this.$secuKit.viewCertInfomationWithVID({certType, certID, isViewVID: '1'});

        // 3. 신원확인 검증
        const userDN = String(r2.userDN)
          .replace(/\s/gi, '')
          .toUpperCase();
        const certdn = String(this.userInfo.certdn)
          .replace(/\s/gi, '')
          .toUpperCase();

        if (userDN != certdn) {
          this.$alert({
            title: '인증서 검증',
            message: '시스템에 등록된 인증서와 선택하신 인증서가 다릅니다.<br/> 다시 한 번 확인하시기 바랍니다.',
          });
          return;
        }

        // 4. 인증서 유효기간
        const date1 = new Date(r2.detailValidateTo).getTime();
        const date2 = new Date().getTime();
        if (date1 < date2) {
          this.$alert({
            title: '인증서 검증',
            message: '인증서의 유효기간이 만료되었습니다.<br/> 인증서를 확인하시기 바랍니다.',
          });
          return;
        }

        const param = {
          poNo: this.poInfo.poNo,
          contSeq: this.poInfo.contSeq,
          signedData: r1.generatePKCS7SignedData,
          cusUsrCertdn: r2.userDN,
        };

        sign(param)
          .then(response => {
            if (response.status == 200) {
              this.callEvent({name: 'ContractList_onSearch', param: param.poNo});
            }
          })
          .catch(ex => {
            if (ex.pmisError) {
              let msg = ex.message;
              if (ex.description) {
                msg += `<br/>- ${ex.description}`;
              }
              this.$alert({title: ex.title, message: msg});
            }
          });
      } catch (e) {
        console.error(e);
      }
    },
    onReject() {
      this.callEvent({
        name: 'PoRejectSupplier_Reject',
        param: () => {
          this.$modal.hide('poRejectModal');
          this.callEvent({name: 'ContractList_onSearch'});
        },
      });
    },
  },
};
</script>

<style></style>
