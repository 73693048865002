<template>
  <pmis-content-box :loading="loading">
    <DtlsSearch
      slot="header-custom"
      :codeGb="$getConstants('CODE_GB2').code"
      @loadChgDegree="loadChgDegree"
      :workTypeCl="workTypeCl"
      @filter="filter"
      @search="onSearch"
      :propsWcode.sync="wcode"
      :propsLgCode.sync="lgCode"
      :propsMgCode.sync="mgCode"
      :isPopup="isPopup"
      :param="param"
      @maxDegree="setMaxDegree"
      @chgDegree="setChgDegree"
      @changeChgDegree="$emit('changeChgDegree')"
    />
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onAfterChange}"
    />
  </pmis-content-box>
</template>

<script>
import DtlsSearch from './DtlsSearch.vue';
import options from './sheetOption/ExecutionSheet.js';
import {selectContExecPlanList} from '@/view/executDtls/api/executDtls.js';
export default {
  components: {
    DtlsSearch,
  },
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    costType: {
      type: String,
    },
    isCheck: {
      type: Boolean,
      default: false,
    },
    visiblePrice: {
      type: Boolean,
      default: true,
    },
    param: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      sheet: undefined,
      Options: this.isPopup
        ? this.isCheck
          ? $addColCheckbox(options(this), 2)
          : options(this)
        : $addColCheckbox(options(this)),
      loadSearchData: [],
      workTypeCl: {},
      wcode: '',
      lgCode: '',
      mgCode: '',
      loading: false,
    };
  },

  beforeCreate() {
    $mapGetters(this, ['dgDtlsLinkList', 'contContexecPlanDList']);
  },
  created() {
    let event = [
      {name: 'getExecRowsByChecked', func: this.getExecRowsByChecked},
      {name: 'onFocusedRowData', func: this.onFocusedRowData},
      {name: 'excution_setWcode', func: this.setWcode},
      {name: 'excution_setLgCode', func: this.setLgCode},
      {name: 'excution_setMgCode', func: this.setMgCode},
      {name: 'excution_getSaveJson', func: this.getSaveJson},
      {name: 'excution_dgLinkCheck', func: this.onDgLinkCheck},
    ];
    this.addEvent(event);
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onAfterChange(e) {
      if (e.col == 'chk') {
        this.sheet.acceptChangedData(e.row);
      }
    },
    loadChgDegree(chgDegree) {
      this.$emit('loadChgDegree', chgDegree);
    },

    getExecRowsByChecked(callback) {
      callback(this.sheet.getRowsByChecked('chk'));
    },
    onFocusedRowData(param) {
      let row = this.sheet.getFocusedRow();
      if (typeof param == 'function') {
        param(row);
      }
    },
    setWcode(code) {
      this.wcode = code;
    },
    setLgCode(code) {
      this.lgCode = code;
    },
    setMgCode(code) {
      this.mgCode = code;
    },
    getSaveJson(callback) {
      callback(this.sheet.getSaveJson(0).data);
    },
    setAllUnCheck() {
      this.sheet.getDataRows().forEach(row => {
        this.sheet.setAttribute({row: row, attr: 'CanEdit', val: 1, render: 1});
        this.sheet.setCheck(row, 'chk', 0, 0);
      });
    },
    onDgLinkCheck(dgCode) {
      if (!dgCode) {
        return;
      }
      this.setAllUnCheck();
      this.contContexecPlanDList.forEach(si => {
        if (dgCode.midCode == si.midCodeDg && dgCode.itemSeq == si.itemSeqDg) {
          this.sheet.getDataRows().some(row => {
            if (row.Visible && row.midCode == si.midCodeSi && row.itemSeq == si.itemSeqSi) {
              this.sheet.setAttribute({row: row, attr: 'CanEdit', val: 0, render: 1});
              this.sheet.setCheck(row, 'chk', 1, 0);

              return true;
            }
          });
        }
      });
      this.sheet.acceptChangedData();
    },
    filter(obj) {
      this.sheet.clearFilter();
      this.loading = true;
      setTimeout(() => {
        if (obj.itemName) {
          this.sheet.setFilter({
            name: 'itemName',
            filter: "String(itemName).indexOf('" + obj.itemName + "') !== -1 ?1:0",
            render: 1,
          });
        }
        if (obj.mgCode) {
          this.sheet.setFilter({
            name: 'midCode',
            filter: "midCode=='" + obj.mgCode + "'?1:0",
            render: 1,
          });

          this.callEvent({
            name: 'getContFocusedRow',
            param: this.onDgLinkCheck,
          });
          this.loading = false;
          return;
        }
        if (obj.lgCode) {
          this.sheet.setFilter({
            name: 'headCode',
            filter: "headCode=='" + obj.lgCode + "'?1:0",
            render: 1,
          });
          this.callEvent({
            name: 'getContFocusedRow',
            param: this.onDgLinkCheck,
          });
          this.loading = false;
          return;
        }
        if (obj.wcode) {
          this.sheet.setFilter({
            name: 'wcode',
            filter: "wcode=='" + obj.wcode + "'?1:0",
            render: 1,
          });
          this.loading = false;
          return;
        }
        this.loading = false;
      }, 100);
    },
    async onSearch(chgDegree) {
      if (!chgDegree) {
        this.sheet?.removeAll();
        return;
      }
      try {
        this.loading = true;
        const param = {
          ...this.param,
          chgDegree,
        };
        const response = await selectContExecPlanList(param);
        this.sheet.clearFilter();
        const wcodeList = [];
        const lgCodeList = [];
        const mgCodeList = [];
        response.data.forEach(item => {
          //공종
          if (wcodeList.findIndex(obj => obj.value === item.wcode) === -1) {
            wcodeList.push({
              text: item.wname,
              value: item.wcode,
            });
          }
          //대분류
          if (lgCodeList.findIndex(obj => obj.value === item.headCode) === -1) {
            lgCodeList.push({
              text: item.lgName,
              value: item.headCode,
            });
          }
          //중분류
          if (mgCodeList.findIndex(obj => obj.value === item.midCode) === -1) {
            mgCodeList.push({
              text: item.mgName,
              value: item.midCode,
            });
          }
        });
        const obj = {};
        obj['wcodeList'] = wcodeList;
        obj['lgCodeList'] = lgCodeList;
        obj['mgCodeList'] = mgCodeList;

        this.workTypeCl = obj;
        this.loadSearchData = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    setMaxDegree(isMaxDegree) {
      this.$emit('maxDegree', isMaxDegree);
    },
    setChgDegree(chgDegree) {
      this.$emit('chgDegree', chgDegree);
    },
  },
};
</script>
