const state = {
  detailInfo: {
    equipcode: '', //장비코드
    itemName: '', //장비명
    size: '', //규격
    unit: '', //단위
    carNo: '', //차량번호
    registerNo: '', //등록번호
    makeFrmNo: '', //제조회사
    buyDate: '', //구입일자
    buyAmt: '', //구입금액
    buyCompany: '', //구입처
    buyTel: '', //구입처전화번호
    useOil: '', //사용유류
    cud: '0',
  },
  sheetData: {
    equipCode: '', //장비코드
    itemName: '', //장비명
    size: '', //규격
    unit: '', //단위
  },
  focusKey: '', //차량번호
};

const getters = {
  detailInfo: state => state.detailInfo,
  sheetData: state => state.sheetData,
  focusKey: state => state.focusKey,
};

const actions = {};

const mutations = {
  initDetailInfo(state) {
    for (let detail in state.detailInfo) {
      state.detailInfo[detail] = '';
    }
    state.detailInfo.cud = '0';
  },
  setDetailInfo(state, payload) {
    for (let o in payload) {
      if (o == 'buyDate') {
        state.detailInfo[o] = dateFormat(String(payload[o]));
      } else {
        state.detailInfo[o] = String(payload[o]);
      }
    }
  },
  setFocusKey(state, payload) {
    state.focusKey = payload;
  },
  initFocusKey(state) {
    state.focusKey = '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
