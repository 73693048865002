<template>
  <pmis-content-box>
    <template v-slot:title>부서상세정보</template>
    <form onsubmit="return false;">
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header required>코드</i-col-header>
          <i-col v-if="deptInfo.cud != 1">
            <iui-text type="code" name="fldrCd" :value="deptInfo.fldrCd" :enable="false" width="100px" />
          </i-col>
          <i-col v-else>
            <iui-text type="code" :value="deptInfo.uppFldrCd" :enable="false" width="100px" />
            <iui-text
              type="code"
              name="fldrCd"
              :enable="deptInfo.cud == 1"
              :bindArray="['setDeptInfo']"
              max-length="30"
              required
              width="100px"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header required>명칭</i-col-header>
          <i-col>
            <iui-text
              name="fldrNm"
              :value="deptInfo.fldrNm"
              :bindArray="['setDeptInfo']"
              required
              max-length="100"
              width="400px"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header required>상위코드</i-col-header>
          <i-col>
            <iui-text type="code" name="uppFldrCd" :value="deptInfo.uppFldrCd" :enable="false" width="100px" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header required>상위명칭</i-col-header>
          <i-col>
            <iui-text name="uppFldrNm" :value="deptInfo.uppFldrNm" :enable="false" width="400px" />
          </i-col>
        </i-row>
        <i-row>
          <i-col colspan="2">
            <hr style="width: 100%; border-bottom: 0px;" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>메모</i-col-header>
          <i-col>
            <iui-text
              type="multi"
              name="rmk"
              :value="deptInfo.rmk"
              :bindArray="['setDeptInfo']"
              height="200px"
              max-length="400"
            />
          </i-col>
        </i-row>
      </iui-container-new>
    </form>
  </pmis-content-box>
</template>

<script>
import {saveDept} from '../api/deptRegist.js';

export default {
  beforeCreate() {
    $mapGetters(this, {deptInfo: 'deptInfo', workerInfo: 'workerInfo', searchInfo: 'searchInfo'});
    $mapMutations(this, ['setDeptInfo', 'setWorkerInfo', 'setFocusKeyCode']);
  },
  created() {
    this.addEvent([{name: 'DeptInfo_saveDeptInfo', func: this.saveDeptInfo}]);
  },
  activated() {
    this.canNew = true;
    this.canSave = true;
    this.canDelete = true;
  },
  methods: {
    onChangeFldrCdSeq(e) {
      if (this.deptInfo.uppFldrCd) {
        this.setDeptInfo({fldrCd: this.deptInfo.uppFldrCd + e.target.value});
      }
    },
    async saveDeptInfo() {
      let validate = await this.validate();
      if (!validate.isValid) {
        this.$alert('필수입력값이 입력되지 않았습니다.');
        return;
      }

      let param = {
        ...this.searchInfo,
        ...this.deptInfo,
      };
      const response = await saveDept(param);
      if (response.data.hasOwnProperty('STATUS') && response.data.STATUS == 'DUPLICATED') {
        this.$alert({
          title: '부서 중복',
          message: '이미 등록된 부서입니다.',
        });
      } else {
        if (response.data.hasOwnProperty('fldrNo')) {
          this.setFocusKeyCode(response.data.fldrNo);
        }
        this.callEvent({name: 'DeptList_onSearch'});
      }
    },
  },
};
</script>

<style></style>
