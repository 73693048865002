<template>
  <pmis-content-box>
    <template #header-left>
      <iui-text type="amount" width="180px" prefix="공급가액" :enable="false" :value="poInfo.poAmt" />
      <iui-text type="amount" width="180px" prefix="부가세액" :enable="false" :value="poInfo.vatAmt" />
      <iui-text type="amount" width="180px" prefix="합계금액" :enable="false" :value="poInfo.totAmt" />
    </template>

    <ib-sheet :uid="_uid" :options="sheetOpt" :loadSearchData="poItemList" :events="{onClick: sheet_onClick}" />

    <iui-modal name="matCodeInfoModal" title="자재정보" size-type="size4">
      <mat-code-info-customer :propsMatCode="propsMatCode" :propsPgmCd="poInfo.orgCd" :useDsVisible="false" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/poItemList.js';
import MatCodeInfoCustomer from '@/view/sysManage/stdCodeManage/matCodeRegist/popup/MatCodeInfoCustomer.vue';

export default {
  components: {MatCodeInfoCustomer},

  data() {
    return {
      sheetOpt: sheetOpt(this),
      propsMatCode: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'poItemList']);
  },
  methods: {
    sheet_onClick(e) {
      if (e.row.Kind == 'Data' && e.col == 'itemNm') {
        this.propsMatCode = e.sheet.getValue(e.row, 'itemCd');
        this.$modal.show('matCodeInfoModal');
      }
    },
  },
};
</script>

<style></style>
