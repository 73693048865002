export default that => {
  return {
    Cfg: {
      MainCol: 'name',
      SearchMode: 0,
      CanEdit: 0,
      NoTreeLines: 0,
      FitWidth: 1,
    },
    Def: {
      Header: {
        Hidden: true,
      },
    },
    Cols: [
      {
        Header: '명칭',
        Name: 'name',
        Type: 'Text',
        RelWidth: 1,
      },
      {
        Header: '코드',
        Name: 'code',
        Type: 'Text',
        Width: 65,
        Align: 'center',
      },
    ],
  };
};
