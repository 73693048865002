/** PrjList 현장목록 */
export const selectPjtList = param => axios.post('/photo/selectPjtList', param);

/** PhotoInfoList 사진목록 */
export const selectConstructPhotoList = param => axios.post('/photo/selectConstructPhotoList', param);
export const selectPhotoFileInfoList = param => axios.post('/photo/selectPhotoFileInfoList', param);

/** ImageReg 사진등록 */
export const deleteConstructPhoto = param => axios.post('/photo/deleteConstructPhoto', param);
export const updateFlNo0 = param => axios.post('/photo/updateFlNo0', param);
export const saveConstructPhoto = param => $save('/photo/saveConstructPhoto', param);

/** UnitOpertListPopup 단위작업선택 */
export const selectUnitOpertListPopup = param => axios.post('/photo/selectUnitOpertListPopup', param);
