<template>
  <iui-dialog-modal mode="confirm" :name="name" :title="title" @confirm="onConfirm" @closed="closed" @cancel="onCancel">
    <p v-html="message"></p>
  </iui-dialog-modal>
</template>
<script>
import Confirm from '@/plugins/confirm';
export default {
  data() {
    return {
      message: '',
      title: '',
      name: 'iuiConfirm',
      confirmCallback: undefined,
      cancelCallback: undefined,
      resolve: undefined,
      reject: undefined,
      isConfirm: false,
    };
  },
  created() {
    Confirm.event.$on('show', this.handleShowEvent);
  },
  methods: {
    handleShowEvent(msg, resolve, reject, confirmCallback, cancelCallback) {
      if (typeof msg == 'string') {
        this.message = msg;
      }
      if (typeof msg == 'object') {
        this.title = msg.title || 'Confirm!';
        this.message = msg.message || this.message;
      }
      this.resolve = resolve;
      this.reject = reject;
      this.confirmCallback = confirmCallback;
      this.cancelCallback = cancelCallback;
      this.$modal.show(this.name, {isDefault: true});
    },
    onConfirm() {
      this.isConfirm = true;
      this.$modal.hide(this.name, {}, true);
    },
    closed() {
      if (this.isConfirm) {
        if (typeof this.confirmCallback == 'function') {
          try {
            this.confirmCallback();
          } catch (error) {
            this.resolve(error);
          }
        }
        this.resolve(true, this.confirmCallback);
      }
      this.message = '';
      this.title = '';
      this.isConfirm = false;
    },
    onCancel() {
      this.message = '';
      this.title = '';

      if (typeof this.cancelCallback == 'function') {
        try {
          this.cancelCallback();
        } catch (error) {
          this.resolve(error);
        }
      }
      this.resolve(false, this.cancelCallback);

      this.$modal.hide(this.name, {}, true);
    },
  },
};
</script>

<style scoped>
p {
  padding: 10px 20px;
  font-size: 16px;
}
</style>
