<template>
  <pmis-tab-box>
    <template v-slot:title>페이지목록</template>
    <iui-container-new type="ibsheet" :id="elId" />
  </pmis-tab-box>
</template>

<script>
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['focusKeyCode']);
    $mapMutations(this, ['setPageData', 'setEditState', 'initFocusKeyCode']);
  },
  created() {
    this.addEvent([{name: 'pageList_onSearch', func: this.onSearch}]);
  },
  mounted() {
    this.initSheet();
  },
  methods: {
    async initSheet() {
      const OPT = {
        Cfg: {
          MainCol: 'name', // 트리가 표시될 열이름
          SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
          CanSort: 0,
          CanEdit: 0,
        },
        Def: {
          Header: {
            Hidden: true,
          },
        },
        Cols: [
          {
            Header: '페이지이름',
            Name: 'name',
            Type: 'Text',
            RelWidth: 1,
          },
          {
            Header: '페이지ID',
            Name: 'pageId',
            Type: 'Text',
            Width: 80,
          },
        ],
        Events: {
          onFocus: e => {
            this.setPageData(e.row);
            this.setEditState();
          },

          onSearchFinish: () => {
            this.sheet.setValue(this.sheet.getFirstRow(), 'pageId', 'ID', 1);
            this.sheet.acceptChangedData();
            if (this.focusKeyCode.focusKey1) {
              let focusRow = this.sheet
                .getDataRows()
                .find(row => row.pageId == this.focusKeyCode.focusKey1 && row.seq == this.focusKeyCode.focusKey2);
              this.sheet.focus(focusRow);
              this.initFocusKeyCode();
            }
          },
        },
      };

      PMISSheet.createSheet({el: this.elId, options: OPT}).then(sheet => {
        this.sheet = sheet;
        this.onSearch();
      });
    },
    async onSearch() {
      const response = await axios.post('/page/selectPageList', {});
      let treeList = await Array.from(response.data)[0];
      if (treeList) {
        treeList.Items = await $_treeModel(
          Array.from(response.data).filter((e, i) => i != 0),
          $getConstants('WEBPAGE_CLS').code,
          'pageId',
          'upPageId'
        );
        this.sheet.loadSearchData(new Array(treeList));
      }
    },
  },
};
</script>

<style></style>
