<template>
  <pmis-page>
    <pmis-tab-box>
      <template v-slot:title>상세정보</template>
      <iui-container-new type="css-flex">
        <i-row height="170px">
          <i-col>
            <iui-container-new type="table" theme="bullet" width="800px">
              <i-row>
                <i-col-header>
                  구분
                </i-col-header>
                <i-col>
                  <iui-text :value="'FAQ'" :enable="false" width="100px" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>
                  업무구분
                </i-col-header>
                <i-col>
                  <iui-select
                    v-if="isAdmin"
                    name="tskDs"
                    :value="detailInfo.tskDs"
                    @change="onChangeTskDs"
                    :items="menuItems"
                    defaultCd="S"
                    required
                    :error-message="{title: '업무구분', message: '업무구분을 선택해주세요.'}"
                  />
                  <iui-text v-else :value="tskDsNm" :enable="false" width="100px" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>
                  제목
                </i-col-header>
                <i-col colspan="3">
                  <iui-text
                    name="tl"
                    :value="detailInfo.tl"
                    @input="setDetailInfo({tl: $event.target.value})"
                    max-length="100"
                    :enable="params.empNo == userInfo.empNo"
                    width="400px"
                    required
                    :error-message="{title: '제목', message: '제목을 입력해주세요.'}"
                  />
                </i-col>
              </i-row>
              <i-row rowspan="2">
                <i-col colspan="3">
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header>
                        첨부파일
                      </i-col-header>
                      <i-col>
                        <pmis-file-list
                          :modal="true"
                          :companyCode="params.pgmCd"
                          :fileNumber="detailInfo.flNo"
                          :id="'fileComp' + _uid"
                          :modalBtnEnable="isAdmin || detailInfo.flNo !== 0"
                          @delete-complete="file_deleteComplete"
                        />
                      </i-col>
                      <i-col-header>조회수</i-col-header>
                      <i-col>{{ detailInfo.inqCnt }}</i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>
                        등록자
                      </i-col-header>
                      <i-col>
                        <iui-text width="100px" :value="detailInfo.rgprNm" :enable="false" />
                      </i-col>
                      <i-col-header>
                        등록일시
                      </i-col-header>
                      <i-col>
                        <iui-text width="120px" :value="detailInfo.rgsDttm" :enable="false" />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>
                  상세내용
                </i-col-header>
              </i-row>
            </iui-container-new>
          </i-col>
        </i-row>
        <i-row>
          <i-col>
            <iui-container-new type="css-flex">
              <i-row height="500px">
                <i-col width="1200px">
                  <Editor v-if="isAdmin" initialEditType="wysiwyg" ref="Editor" :height="'500px'" />
                  <Viewer v-else ref="Viewer" :height="'100%'" @load="onViewerLoad" />
                </i-col>
              </i-row>
            </iui-container-new>
          </i-col>
        </i-row>
      </iui-container-new>
    </pmis-tab-box>
  </pmis-page>
</template>

<script>
import {mapGetters} from 'vuex';
import store from '@/view/board/store/board.js';

import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import {Editor, Viewer} from '@toast-ui/vue-editor';
import {saveFaqInfo, selectFaqDetailInfo, selectTskDsList, updateFlNo} from '../api/faq.js';
import {deleteBoardDetailInfo} from '@/view/board/api/board.js';

export default {
  components: {
    Editor,
    Viewer,
  },
  data() {
    return {
      keyInfo: {},
      menuItems: [],
      boardStatus: '',
      paramObj: {},
    };
  },
  computed: {
    ...mapGetters('menu', {menu: 'menu'}),
    isAdmin() {
      return this.userInfo.frmGb === $getConstants('FRM_GB_0').code && this.params.routeName === 'SYS0406';
    },
    tskDsNm() {
      let item = this.menuItems.find(item => item.value == this.detailInfo.tskDs);
      return item ? item.text : '';
    },
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['detailInfo']);
    $mapMutations(this, ['setDetailInfo', 'initDetailInfo']);
  },
  created() {
    this.params = {...this.$route.params};
    this.canNew = false;
    this.canSave = true;
    this.canDelete = true;
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
    if (!this.isAdmin) {
      this.canSave = false;
    }
  },
  async mounted() {
    await this.initData();
    this.boardStatus = this.params.boardStatus;
    if (this.boardStatus == 'I') {
      this.canDelete = false;
      this.setDetailInfo({rgprNm: this.userInfo.name, tskDs: this.params.tskDs});
    } else if (this.boardStatus == 'E') {
      this.canDelete = true;
      this.onSearch();
    }
  },
  methods: {
    onViewerLoad(e) {
      e.options.el.style = 'height:100%; padding: 10px 25px 0px 25px; border: 1px solid #e5e5e5; overflow:auto;';
    },
    async initData() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.keyInfo.pgmCd = this.params.pgmCd || this.pgmCd;
      this.keyInfo.natNo = this.params.natNo;
      this.keyInfo.natDs = this.params.natDs;

      this.setDetailInfo({
        pgmCd: this.params.pgmCd,
        natNo: this.params.natNo || 0,
        natDs: this.params.natDs,
        rgprId: this.params.empNo,
      });

      this.menuItems = [];
      const response = await selectTskDsList({frmGb: $getConstants('FRM_GB_0').code});
      response.data.forEach(el => {
        this.menuItems.push({
          text: el.mnuNm,
          value: el.mnuCd,
        });
      });
    },
    async onSearch() {
      let response = await selectFaqDetailInfo(this.keyInfo);
      this.setDetailInfo(response.data);
      if (this.isAdmin) {
        this.$refs.Editor.invoke('setHtml', this.detailInfo.cts);
      } else {
        this.$refs.Viewer.invoke('setMarkdown', this.detailInfo.cts);
      }
    },
    async onSave() {
      this.setDetailInfo({cts: this.$refs.Editor.invoke('getHtml').trim()});

      if (!this.detailInfo.cts) {
        this.$alert({title: '상세내용', message: '입력된 내용이 없습니다.'});
        return false;
      }

      let param = {...this.detailInfo, cud: this.boardStatus == 'E' ? 2 : 1};
      const response = await saveFaqInfo(param);
      this.onGoList();
    },
    async onDelete() {
      if (!(await this.$confirm({title: 'FAQ 삭제', message: '삭제하시겠습니까?'}))) return;
      let param = {pgmCd: this.detailInfo.pgmCd, natNo: this.detailInfo.natNo};
      const response = await deleteBoardDetailInfo(param);
      this.onGoList();
    },
    onGoList() {
      this.back();
    },
    async file_deleteComplete(flNo) {
      if (flNo === 0) {
        this.setDetailInfo({flNo: flNo});
        await updateFlNo(this.detailInfo);
        this.onSearch();
      }
    },
    onChangeTskDs(value) {
      this.setDetailInfo({tskDs: value});
    },
  },
};
</script>

<style></style>
