export const selectContEmPlanListAndInfo = param => axios.post('/estmtDtlsRegist/selectContEmPlanListAndInfo', param);
export const selectContEmPlanOfDgreeList = param => axios.post('/estmtDtlsRegist/selectContEmPlanOfDgreeList', param);
export const insertContEmPlanM = param => axios.post('/estmtDtlsRegist/insertContEmPlanM', param);
export const deleteContEmPlanM = param => axios.post('/estmtDtlsRegist/deleteContEmPlanM', param);
export const updateContEmPlanM = param => axios.post('/estmtDtlsRegist/updateContEmPlanM', param);
export const saveContEmPlanD = param => axios.post('/estmtDtlsRegist/saveContEmPlanD', param);
export const deleteContEmPlanD = param => axios.post('/estmtDtlsRegist/deleteContEmPlanD', param);
export const updateContEmPlanByUcstApply = param => axios.post('/estmtDtlsRegist/updateContEmPlanByUcstApply', param);
export const updateContEmPlanByUcstMdac = param => axios.post('/estmtDtlsRegist/updateContEmPlanByUcstMdac', param);
export const selectOtherPrjContEmPlanList = param => axios.post('/estmtDtlsRegist/selectOtherPrjContEmPlanList', param);
export const copyOtherPjtCont = param => axios.post('/estmtDtlsRegist/copyOtherPjtCont', param);
