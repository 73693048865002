<template>
  <iui-container-new type="css-flex">
    <i-row height="80px">
      <i-col>
        <iui-content-box>
          <template #title>발주서 반송</template>
          <font>º 발주서를 원청사에 반송합니다. (계약번호 : {{ getMakeFormat(orderInfo.poNo) }})</font>
          <br />
          <font>º 반송된 발주서는 원청사 담당자 확인후 처리됩니다.</font>
        </iui-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <iui-content-box>
          <template #title>반송사유 입력</template>
          <iui-text ref="reason" type="multi" :value.sync="reason" />
        </iui-content-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import {rejectOrder} from '@/view/bidding/po/mat/api/order.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';

export default {
  data() {
    return {reason: ''};
  },
  beforeCreate() {
    $mapGetters(this, ['orderInfo']);
  },
  created() {
    this.addEvent({name: 'PoRejectOrder_Reject', func: this.onReject});
  },
  mounted() {
    this.$refs.reason.$refs.textarea.focus();
  },
  methods: {
    getMakeFormat: poNo => COMMON_FUNCTION.getMakeFormat(poNo),
    async onReject(callback) {
      if (!this.reason.trim()) {
        await this.$alert({title: '반송사유', message: '반송사유를 입력해야합니다.'});
        this.$refs.reason.$refs.textarea.focus();
        return;
      }
      const param = {poNo: this.orderInfo.poNo, contSeq: this.orderInfo.contSeq, reason: this.reason};

      await rejectOrder(param);

      if (typeof callback === 'function') {
        callback();
      }
    },
  },
};
</script>

<style></style>
