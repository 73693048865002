export const selectWorkSummary = param => axios.post('/workdaily/selectWorkSummary', param);
export const selectWorkSummaryAll = param => axios.post('/workdaily/selectWorkSummaryAll', param);
export const selectWorkList = param => axios.post('/workdaily/selectWorkList', param);
export const selectMaterialList = param => axios.post('/workdaily/selectMaterialList', param);
export const selectLaborList = param => axios.post('/workdaily/selectLaborList', param);
export const selectEquipmentList = param => axios.post('/workdaily/selectEquipmentList', param);
export const selectExpenseList = param => axios.post('/workdaily/selectExpenseList', param);
export const selectWorkGroupList = param => axios.post('/workdaily/selectWorkGroupList', param);
/* 결재품의정보 */
export const selectApprovalInfos = param => axios.post('/workdaily/selectApprovalInfos', param);
