<template>
  <pmis-content-box>
    <template #header-left>
      <label>검색구분</label>
      <iui-select defaultCd="S" :items="searchItems" :value.sync="search.searchGubun" />
      <iui-text type="search" width="200px" :value.sync="search.searchText" @enter="onSearch" />
      <label>발행일</label>
      <iui-datepicker :value.sync="search.searchFrDt" @change="onSearch" />
      <span>~</span>
      <iui-datepicker :value.sync="search.searchToDt" @change="onSearch" />
      <iui-button value="검색" @click="onSearch" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="loadSearchData"
      @loadSheet="sheet = $event"
      :events="{
        onRenderFirstFinish: sheet_onRenderFirstFinish,
        onSearchFinish: sheet_onSearchFinish,
        onAfterClick: sheet_onAfterClick,
      }"
    />

    <iui-modal name="customerInfoModal" title="협력사정보" width="700px" height="800px">
      <customer-info :propsPgmCd="propsPgmCd" />
    </iui-modal>

    <tax-invoice-pop :billNo="propsBillNo" :open.sync="propsOpen" pageGubun="sub" />
  </pmis-content-box>
</template>

<script>
import sheetOpt from '@/view/subBidding/billManageCustomer/components/sheetOptions/customerBillList.js';
import {selectSubBillList} from '@/view/subBidding/billManage/api/subBill.js';

import CustomerInfo from '@/view/bidding/rfq/common/components/CustomerInfo.vue';
import TaxInvoicePop from '@/view/bidding/components/TaxInvoicePop.vue';
export default {
  components: {CustomerInfo, TaxInvoicePop},
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],

      propsPgmCd: '',
      propsBillNo: '',
      propsOpen: false,

      search: {
        searchGubun: '',
        searchText: '',
        searchFrDt: '',
        searchToDt: '',
      },
      searchItems: [
        {text: '현장명', value: 'PRJ_NM'},
        {text: '협력사명', value: 'CUS_NM'},
        {text: '계약명', value: 'PO_TTL'},
        {text: '세금계산서번호', value: 'BILL_NO'},
        {text: '세금계산서명', value: 'BILL_TTL'},
      ],
      billHtml: '',
    };
  },
  created() {
    this.addFuncSearch(this.onClick_cmnSearch);
  },
  methods: {
    onClick_cmnSearch() {
      this.search.searchGubun = '';
      this.search.searchText = '';
      this.search.searchFrDt = '';
      this.search.searchToDt = '';
      this.onSearch();
    },
    onSearch() {
      selectSubBillList({orgCd: this.pgmCd, ...this.search}).then(response => (this.loadSearchData = response.data));
    },
    onSetRow(row) {
      if (row) {
        this.callEvent({name: 'CustomerBillDetail_SetData', param: row});
      } else {
        this.callEvent({name: 'CustomerBillDetail_Init'});
      }
    },
    sheetMerge() {
      let key = '';
      let sRow;
      let eRow;
      let mergeInfo = {};
      this.sheet.getDataRows().forEach(row => {
        if (key !== row.spotDeptcd + row.cusCd + row.poNo + row.investMm + row.reqDegree) {
          key = row.spotDeptcd + row.cusCd + row.poNo + row.investMm + row.reqDegree;
          sRow = row.id;
          mergeInfo[sRow] = sRow;
        } else {
          eRow = row.id;
          mergeInfo[sRow] = eRow;
        }
      });

      for (const m in mergeInfo) {
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'spotDeptnm',
          this.sheet.getRowById(mergeInfo[m]),
          'spotDeptnm'
        );
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'cusNm', this.sheet.getRowById(mergeInfo[m]), 'cusNm');
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'poNo', this.sheet.getRowById(mergeInfo[m]), 'poNo');
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'poTtl', this.sheet.getRowById(mergeInfo[m]), 'poTtl');
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'investMm', this.sheet.getRowById(mergeInfo[m]), 'investMm');
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'investKind',
          this.sheet.getRowById(mergeInfo[m]),
          'investKind'
        );
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'reqDegree',
          this.sheet.getRowById(mergeInfo[m]),
          'reqDegree'
        );
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'regDt', this.sheet.getRowById(mergeInfo[m]), 'regDt');
      }
    },
    sheet_onRenderFirstFinish(e) {
      e.sheet.setAttribute(null, 'cusNm', 'Visible', 1, 1);
      this.onSearch();
    },
    sheet_onSearchFinish(e) {
      if (!e.sheet.getTotalRowCount()) {
        this.callEvent({name: 'CustomerBillDetail_Init'});
      }
      this.sheetMerge();
    },
    sheet_onAfterClick(e) {
      if (e.row.Kind == 'Data') {
        this.onSetRow(e.row);

        if (e.col == 'cusNm') {
          this.propsPgmCd = e.row.cusPgmCd;
          this.$modal.show('customerInfoModal');
        }
        if (e.col == 'billNo' && e.row.billNo) {
          this.propsBillNo = e.row.billNo;
          this.propsOpen = true;
        }
      }
    },
  },
};
</script>

<style></style>
