<template>
  <div class="work_box name_site">
    <span class="pl20 pr10">현장명</span>
    <iui-text width="350px" :value="prjNm" readonly />
    <input type="button" class="button_s" @click="openPopup" />

    <iui-modal name="projectList" title="현장정보" size-type="size2">
      <project-status-list isModal />
    </iui-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      prjNm: '',
    };
  },
  created() {
    this.addEvent([{name: 'ProjectSearchBar_ChangePrj', func: this.onChangePrj}]);
    this.prjNm = this.getPrjInfo().prjNm;
  },
  methods: {
    openPopup() {
      this.$modal.show('projectList');
    },
    onChangePrj({prjCd, prjNm = ''}) {
      this.prjNm = prjNm;
      this.$store.commit('setPrjCd', String(prjCd));
      this.$store.dispatch('portlet/runFuncInit');
      this.$modal.hide('projectList');
    },
  },
};
</script>

<style></style>
