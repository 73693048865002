<template>
  <iui-portlet-container type="multi" :comps="comps" :activeTab.sync="tabIndex">
    <template #title>{{ titleNm }}</template>
    <template #header-center>
      <div class="port_srch">
        <i class="prev-arrow" @click="setDate('prev')" />
        <span>
          <div class="_headerDate">
            <div @click="datePicker_opened = true">{{ dateText }}</div>
            <div class="_calendar">
              <iui-datepicker
                :type="tabIndex == 0 ? 'month' : 'date'"
                @change="onDatePicker_change"
                :open.sync="datePicker_opened"
                :value="curDate"
              />
            </div>
          </div>
        </span>
        <i class="post-arrow" @click="setDate('next')" />
      </div>
    </template>
    <template #content>
      <component :is="comps[tabIndex].compNm" :curDate="curDate" />
    </template>
  </iui-portlet-container>
</template>

<script>
import PortletMainMixin from '@/components/portlet/mixin/portletMainMixin.js';
export default {
  mixins: [PortletMainMixin],
  data() {
    return {
      datePicker_opened: false,
      curDate: $_getCurrentDate(),
      comps: [
        {tabNm: '현장사진', compNm: 'pjt-photo'},
        {tabNm: '작업현황', compNm: 'work-summary'},
        {tabNm: '작업내역', compNm: 'work-list'},
        {tabNm: '자재투입', compNm: 'material-input'},
        {tabNm: '노무투입', compNm: 'labor-input'},
        {tabNm: '장비투입', compNm: 'equip-input'},
        {tabNm: '경비투입', compNm: 'expense-input'},
        {tabNm: '외주투입', compNm: 'work-group-input'},
      ],
    };
  },
  computed: {
    dateText() {
      const y = this.curDate.substring(0, 4);
      const M = this.curDate.substring(4, 6);
      const d = this.curDate.substring(6, 8);

      const yMText = `${y}년 ${M}월`;
      const dText = ` ${d}일`;

      return this.tabIndex === 0 ? yMText : yMText + dText;
    },
  },
  methods: {
    init() {
      this.tabIndex = 0;
      this.curDate = $_getCurrentDate();
      this.callEvent({name: 'PjtPhoto_onSearch'});
    },
    onDatePicker_change(e) {
      this.curDate = this.tabIndex == 0 ? e + this.curDate.substr(6) : e;
    },
    setDate(direction) {
      let year = this.curDate.substr(0, 4);
      let month = this.curDate.substr(4, 2) - 1;
      let date = Number(this.curDate.substr(6, 2));
      let dt;
      if (this.tabIndex == 0) {
        if (direction == 'prev') {
          dt = new Date(year, month - 1, date);
        } else {
          dt = new Date(year, month + 1, date);
        }
      } else {
        if (direction == 'prev') {
          dt = new Date(year, month, date - 1);
        } else {
          dt = new Date(year, month, date + 1);
        }
      }
      this.curDate = $_dateToStringYYMMDD(dt);
    },
  },
};
</script>

<style scoped>
._headerDate {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 30px;
  width: 115px;
  cursor: pointer;
}
._headerDate ._calendar {
  height: 100%;
  table-layout: auto;
  position: absolute;
  top: 0px;
  left: 90px;
  z-index: -99;
}
</style>
