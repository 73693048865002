<template>
  <iui-container-new type="css-flex">
    <i-row height="315px">
      <i-col>
        <pmis-content-box>
          <template #title>회사기본정보</template>
          <iui-container-new type="table" theme="bullet" header-width="100px">
            <i-row>
              <i-col-header>회사코드</i-col-header>
              <i-col><iui-text :value="company.pgmCd" :enable="false" width="60px" align="center"/></i-col>
              <i-spacer />
              <i-col-header>회사구분</i-col-header>
              <i-col><iui-text :value="company.frmGbNm" :enable="false" width="140px"/></i-col>
            </i-row>
            <i-row>
              <i-col-header>회사명</i-col-header>
              <i-col><iui-text :value="company.frmNm" :enable="false"/></i-col>
              <i-spacer />
              <i-col-header>사업자번호</i-col-header>
              <i-col>
                <iui-bsn-no :value1="bsnNoArr[0]" :value2="bsnNoArr[1]" :value3="bsnNoArr[2]" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>회사명(영문)</i-col-header>
              <i-col><iui-text :value="company.frmEnNm" :enable="false"/></i-col>
              <i-spacer />
              <i-col-header>주민/법인번호</i-col-header>
              <i-col>
                <iui-regi-no :value1="regiNoArr[0]" :value2="regiNoArr[1]" size="short" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>대표자명</i-col-header>
              <i-col>
                <iui-text :value="company.rpsvNm" :enable="false" width="120px" />
                <iui-button value="공동대표" @click="onClickCeo" v-if="isExistCeo" />
              </i-col>
              <i-spacer />
              <i-col-header>대표자영문명</i-col-header>
              <i-col><iui-text :value="company.rpsvEnNm" :enable="false" width="140px"/></i-col>
            </i-row>
            <i-row>
              <i-col-header>대표전화</i-col-header>
              <i-col><iui-text type="phone" :value="company.rpsvPhone" :enable="false" width="120px"/></i-col>
            </i-row>
            <i-row>
              <i-col-header>주소</i-col-header>
              <i-col colspan="4">
                <iui-text width="50px" :value="company.postNo" :enable="false" align="center" />
                <iui-text :value="company.adr1" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col>&nbsp;</i-col>
              <i-col colspan="4"><iui-text :value="company.adr2" :enable="false"/></i-col>
            </i-row>
            <i-row>
              <i-col-header>홈페이지</i-col-header>
              <i-col colspan="4"><iui-text :value="company.homepage" :enable="false"/></i-col>
            </i-row>
            <i-row>
              <i-col-header>업태</i-col-header>
              <i-col><iui-text :value="company.bcoNm" :enable="false" width="200px"/></i-col>
              <i-spacer />
              <i-col-header>종목</i-col-header>
              <i-col><iui-text :value="company.itemNm" :enable="false" width="200px"/></i-col>
            </i-row>
            <i-row>
              <i-col-header>{{ isMat ? '대표납품지역' : '대표공사지역' }}</i-col-header>
              <i-col colspan="4">
                <iui-text :value="company.areas" :enable="false" />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-spacer />
    <i-row>
      <i-col>
        <pmis-content-box>
          <template #title>
            {{ isMat ? '공급물품(자재)정보' : '보유면허(세부공종)정보' }}
          </template>
          <ib-sheet
            :uid="_uid"
            :options="isMat ? sheetOpt11 : sheetOpt12"
            :autoLoad="false"
            :init.sync="initSheet"
            @loadSheet="onLoadSheet"
          />
        </pmis-content-box>
      </i-col>
    </i-row>

    <iui-modal name="ceoModal" title="공동대표" size-type="size1">
      <CustomerCeoInfo :propsPgmCd="searchPgmCd" />
    </iui-modal>
  </iui-container-new>
</template>

<script>
import sheetOpt11 from './sheetOptions/customerInfoSheet11.js';
import sheetOpt12 from './sheetOptions/customerInfoSheet12.js';
import {selectCompanyInfo} from '@/view/member/api/member.js';

import CustomerCeoInfo from './CustomerCeoInfo.vue';
import IuiRegiNo from '@/components/common/IuiRegiNo.vue';

export default {
  components: {IuiRegiNo, CustomerCeoInfo},
  props: {
    propsPgmCd: {
      type: [String, Number],
      default: undefined,
    },
  },
  data() {
    return {
      initSheet: false,
      sheetOpt11: sheetOpt11(this),
      sheetOpt12: sheetOpt12(this),
      loadSearchData: [],
      areaCodes: {},

      company: {
        pgmCd: '', // 회사코드
        frmGb: '', // 회사구분
        frmGbNm: '', // 회사구분명
        frmNm: '', // 회사명
        frmEnNm: '', // 회사명(영문)
        bsnNo: '', // 사업자번호
        regiNo: '', // 주민/법인번호
        rpsvNm: '', // 대표자명
        rpsvEnNm: '', // 대표자명(영문)
        rpsvPhone: '', // 대표전화번호
        postNo: '', // 우편번호
        adr1: '', // 주소
        adr2: '', // 상세주소
        homepage: '', // 홈페이지
        bcoNm: '', // 업태
        itemNm: '', // 종목
        areas: '', // 납품/공사지역
      },
      isExistCeo: false,
      searchPgmCd: '',
    };
  },
  computed: {
    isMat() {
      return this.company.frmGb === $getConstants('FRM_GB_3').code;
    },
    bsnNoArr() {
      const bsnNo = String(this.company.bsnNo);
      const bsnNo1 = bsnNo.substring(0, 3) || '';
      const bsnNo2 = bsnNo.substring(3, 5) || '';
      const bsnNo3 = bsnNo.substring(5) || '';
      return [bsnNo1, bsnNo2, bsnNo3];
    },
    regiNoArr() {
      const regiNo = String(this.company.regiNo);
      const regiNo1 = regiNo.substring(0, 6) || '';
      const regiNo2 = regiNo.substring(6) || '';
      return [regiNo1, regiNo2];
    },
  },
  created() {
    // 공사지역(시도)
    $getCode($getConstants('SITE_AREA1_CD').code).then(codes =>
      codes.forEach(item => (this.areaCodes[item.code] = item.codeNm))
    );
    this.onSearch();
  },
  methods: {
    async onSearch() {
      this.searchPgmCd = this.$store.getters['modalParam']?.pgmCd || this.propsPgmCd;
      this.$store.commit('initModalParam');

      const response = await selectCompanyInfo({pgmCd: this.searchPgmCd});
      this.onSetValue(response.data);
    },
    onSetValue(data) {
      for (const key in this.company) {
        this.company[key] = data[key];
      }

      this.initSheet = true;

      const areaList = data.areaList ?? [];
      areaList.forEach((item, index) => {
        const areaNm = this.areaCodes[item.siteArea2];
        const areaFDs = item.areaFDs == $getConstants('AREA_F_DS_Y').code ? '(대표지역)' : '';
        if (0 == index) {
          this.company.areas = `${areaNm}${areaFDs}`;
        } else {
          this.company.areas += `, ${areaNm}${areaFDs}`;
        }
      });

      this.loadSearchData = this.isMat ? data.matList : data.gcodeList;
      this.isExistCeo = !!data.ceoList.length;
    },
    onLoadSheet(sheet) {
      sheet.loadSearchData(this.loadSearchData);
    },
    onClickCeo() {
      this.$modal.show('ceoModal');
    },
  },
};
</script>

<style></style>
