<template>
  <div class v-show="visible">
    <span v-if="defaultCd" class="_rdoWrap">
      <input
        type="radio"
        ref="radio"
        v-model="vItem"
        :id="`${radioId}_0`"
        :name="name || radioId"
        value=""
        @click="click"
        @change="change"
        :class="{_target_: true}"
        :disabled="!enable"
        :readonly="readonly"
        :required="required"
        :data-error-title="errTitle"
        :data-error-message="errMessage"
      />
      <label :id="`${labelId}_0`" :for="`${radioId}_0`" :class="{'is-valid-fail': !isValid}">
        <span></span>
        {{ defaultNm }}
      </label>
    </span>
    <span v-for="(item, index) in internalItems" v-bind:key="index" class="_rdoWrap">
      <input
        v-if="type === 'radio'"
        type="radio"
        ref="radio"
        v-model="vItem"
        :id="`${radioId}_${index + 1}`"
        :name="name || radioId"
        :value="item[internalItemValueAlias]"
        @click="click"
        @change="change"
        :class="{_target_: true}"
        :disabled="!enable"
        :readonly="readonly"
        :required="required"
        :data-error-title="errTitle"
        :data-error-message="errMessage"
      />
      <label
        v-if="item[internalItemTextAlias]"
        :id="`${labelId}_${index + 1}`"
        :for="`${radioId}_${index + 1}`"
        :class="{'is-valid-fail': !isValid}"
      >
        <span></span>
        {{ item[internalItemTextAlias] && showLabel ? item[internalItemTextAlias] : '' }}
      </label>
    </span>
  </div>
</template>

<script>
import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import IuiItemsMixin from '@/components/Iui/mixins/IuiItemsMixin';
import {IuiValidatorMixin} from '@/plugins/IuiValidator';
import rootStore from '@/store';

export default {
  name: 'iui-radio-group',
  mixins: [IuiBaseMixin, IuiItemsMixin, IuiValidatorMixin],
  model: {
    prop: 'value',
    event: 'update:value',
  },
  props: {
    type: {
      type: String,
      default: 'radio',
    },
    formObj: {
      type: String,
      default: 'none!',
    },
    value: {
      type: [String, Number],
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    defaultCd: {
      type: String,
      default: undefined,
      validator: value => {
        return ['S', 'A'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      radioValues: '',
      vItem: this.value || '',
      originalValue: null,
      isUser: false,
    };
  },
  computed: {
    errTitle() {
      return this.errorMessage instanceof Object ? this.errorMessage.title : 'Confirm';
    },
    errMessage() {
      return this.errorMessage instanceof Object ? this.errorMessage.message : this.errorMessage;
    },
    radioId: {
      get() {
        return this.getId();
      },
    },
    labelId: {
      get() {
        return this.getId('label');
      },
    },
    defaultNm: {
      get() {
        return this.defaultCd == 'S' ? '선택' : this.defaultCd == 'A' ? '전체' : '';
      },
    },
  },
  watch: {
    vItem(item) {
      this.setBindArray(item);
    },
    value(value) {
      this.vItem = value;
    },
  },
  created() {
    this.initRadio();
  },
  updated() {
    this.initRadio();
    this.initValidator();
    this.checkParameter();
  },
  mounted() {
    //이벤트 주입
    for (let e of this.event) {
      for (let el of Array.from(this.$el.querySelectorAll('span'))) {
        el.querySelector('input[type=radio]').addEventListener(e.name, e.callback);
      }
    }
  },
  methods: {
    initRadio() {
      if (this.itemKeyAlias === undefined && this.internalItemTextAlias === 'text') {
        this.internalItemTextAlias = 'label';
      }

      this.setBindArray();

      // validator 설정
      this.validatorTargetDataProp = 'vItem';
      this.validationTriggerEventName = ['valid'];
    },
    click(e) {
      this.$emit('click', e);
    },
    change(e) {
      this.isUser = true;
      this.$emit('update:value', e.target.value);
      this.$emit('change', e);
      this.$emit('valid', e);
    },
    setBindArray(value) {
      if (typeof value != 'undefined') {
        this.bindArray.forEach(o => {
          let payload = {};
          payload[this.name] = value;
          rootStore.commit(rootStore.getters.currentUrl + '/' + o, payload);
        });
      }
    },
    checkParameter() {
      if (!this.isUser) {
        this.originalValue = this.vItem;
      }

      if (this.originalValue !== this.vItem) {
        this.$refs.radio.forEach(x => x.setAttribute('data-changed', ''));
      } else {
        this.$refs.radio.forEach(x => x.removeAttribute('data-changed'));
      }
      this.isUser = false;
    },
  },
};
</script>

<style scoped>
.is-valid-fail {
  border-bottom: 1px solid red;
}
</style>
