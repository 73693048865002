<template>
  <pmis-tab-box>
    <template #title>발송이력</template>
    <template #header-left>
      <iui-select prefix="업무구분" defaultCd="S" :items="bizTypeArray" :value.sync="search.bizType" />
      <iui-select prefix="결과" defaultCd="S" :items="rsltCodeArray" :value.sync="search.result" />
      <label>검색어</label>
      <iui-text type="search" width="200px" :value.sync="search.searchNm" @enter="onSearchHistory" />
      <label>발송일</label>
      <iui-datepicker :value.sync="search.searchFrDt" @change="onSearchHistory" />
      <span>~</span>
      <iui-datepicker :value.sync="search.searchToDt" @change="onSearchHistory" />
      <iui-button value="검색" @click="onSearchHistory" class="z-index-1" />
    </template>
    <template #header-right>
      <iui-button value="발송" @click="openModal" />
    </template>
    <pmis-content-box>
      <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" @loadSheet="loadSheet" />
    </pmis-content-box>
    <iui-modal name="NotificationModal" title="발송" size-type="size3" :btns="button">
      <Notification />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import options from './sheetOption/historySheet.js';
import {selectBizType, selectHistory} from '../api/notification.js';
import moment from 'moment';
import Notification from '@/view/sysManage/notification/components/Notification.vue';

export default {
  components: {Notification},
  data() {
    return {
      loadSearchData: [],
      Options: options(this),
      button: [{name: '확인', callback: this.onConfirm}],
      bizTypeArray: [],
      rsltCodeArray: [
        {text: $getConstants('RSLT_SUCCESS').name, value: $getConstants('RSLT_SUCCESS').code},
        {text: $getConstants('RSLT_FAIL').name, value: $getConstants('RSLT_FAIL').code},
      ],
      bizType: '',
      search: {
        searchFrDt: '',
        searchToDt: '',
        bizType: '',
        searchNm: '',
        result: '',
      },
    };
  },
  created() {
    this.getBizType();
    this.onSearchHistory();
    this.addFuncSearch(this.onSearchHistory);
  },

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    async getBizType() {
      const response = await selectBizType();
      this.bizTypeArray = response.data.map(x => ({text: x.bizTypeNm, value: x.bizType}));
    },
    async onSearchHistory() {
      const response = await selectHistory({
        pgmCd: this.userInfo.pgmCd,
        bizType: this.search.bizType,
        searchNm: this.search.searchNm,
        result: this.search.result,
        searchFrDt: this.search.searchFrDt,
        searchToDt: this.search.searchToDt,
      });
      response.data.forEach(x => (x.rgsDttm = moment(x.rgsDttm).format('YYYY-MM-DD HH:mm:ss')));
      this.loadSearchData = response.data;
    },
    openModal() {
      this.$modal.show('NotificationModal');
    },
    onConfirm() {
      this.callEvent({
        name: 'send_onConfirm',
        param: () => {
          this.$modal.hide('NotificationModal');
          this.onSearchHistory();
        },
      });
    },
  },
};
</script>
