<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>투입목록</template>
            <pmis-content-box>
              <template v-slot:header-left>
                <div>투입일자</div>
                <iui-datepicker
                  :value="searchInfo.ioDate"
                  @change="onChangeSearchInfo('ioDate', $event)"
                  :open.sync="searchRunDtOpen"
                />
                <i class="prev-arrow" v-on:click="onChangeIoDate('PREV')" />
                <i class="post-arrow" v-on:click="onChangeIoDate('POST')" />

                <iui-select
                  label="공사구분"
                  :value="searchInfo.wcode"
                  :items="wCodeList"
                  defaultCd="S"
                  @change="value => onSetValue('wcode', value)"
                />
                <iui-select
                  label="공종분류"
                  :value="searchInfo.clCode"
                  :items="clWorkFilter"
                  defaultCd="S"
                  @change="value => onSetValue('clCode', value)"
                />
                <iui-text
                  type="search"
                  label="내역명칭"
                  :value="searchInfo.searchNm"
                  @change="onSetValue('searchNm', $event.target.value)"
                  @enter="onExecSearch"
                />
              </template>
              <template v-slot:header-right>
                <iui-button @click="onPopAddExcel" value="엑셀등록" />
              </template>
              <ib-sheet
                :uid="_uid"
                :options="Options"
                :loadSearchData="loadSearchData"
                @loadSheet="loadSheet"
                :events="{
                  onBeforeChange,
                  onAfterChange,
                }"
              />
            </pmis-content-box>
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal name="UnitOpertListModal" title="투입등록" :btns="unitOpertListBtns" sizeType="size1">
      <UnitOpertList :costType="costType" />
    </iui-modal>

    <iui-modal name="InventoryModal" title="투입자재선택" :btns="inventoryModalBtns" sizeType="size3">
      <InventoryList :ioDate="searchInfo.ioDate" :itemCode="itemCode" />
    </iui-modal>

    <iui-modal name="excelUploadPop" title="엑셀등록" :btns="excelBtns" sizeType="size1">
      <ExcelUpload uri="/delivery/excelUpload" title="자재투입등록" :rows="excelRows" @error="onExcelUploadError" />
    </iui-modal>
    <iui-modal name="ExcelUploadResultPop" title="엑셀 업로드 실패 사유" sizeType="size6" @closed="resultList = []">
      <ExcelUploadResult :sheet-opt="excelSheetOpt" :list="resultList" />
    </iui-modal>

    <iui-modal name="PoCompleteListModal" title="외주선택" :btns="poCompleteListBtns" sizeType="size3">
      <SubCustListPopup
        :costType="costType"
        :itemSeq="itemSeq"
        :midCode="midCode"
        :itemCode="matCode"
        callback-type="2"
      />
    </iui-modal>
  </pmis-page>
</template>

<script>
/**
 * 자재투입등록
 *
 * */
import PrjList from '@/view/Project/components/PrjList.vue';
import UnitOpertList from '@/view/Resource/components/UnitOpertListPopup.vue';
import InventoryList from '@/view/Resource/components/InputMatListPopup.vue';
import SubCustListPopup from '@/view/Resource/components/SubCustListPopup.vue';
import {
  deleteDeliveryDetailList,
  saveDeliveryDetailList,
  selectDeliveryDetailList,
  selectLastIoDate,
  selectMaterialExecDetails,
} from './api/delivery.js';
import {selectMonthClosePossibleAt} from '@/view/closing/expitmCloseRegist/api/expitmCloseRegist.js';
import options from './sheetOptions/DeleverySheet.js';
import excelOptions from '@/view/Resource/Material/Delivery/sheetOptions/ExcelUploadResultSheet';
import ExcelUploadResult from '@/components/popup/ExcelUploadResult.vue';
import ExcelUpload from '@/components/popup/ExcelUpload.vue';
import {selectClWorkBYExe, selectWcodeBYExe} from '@/view/executDtls/api/executDtls.js';

export default {
  components: {
    ExcelUploadResult,
    ExcelUpload,
    PrjList,
    UnitOpertList,
    InventoryList,
    SubCustListPopup,
  },
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this), 2, 'chk', {CanEditFormula: e => e.Row.cud !== 1}),
      loadSearchData: [],
      searchInfo: {
        ioDate: '',
        wbsIdList: [],
        wcode: '',
        clCode: '',
        searchNm: '',
        headCode: '',
        midCode: '',
      },
      taskList: [],
      costType: '', // 자원구분

      monPjtCloseAt: '',
      currentPjtClseAt: '',
      currentDate: $_getCurrentDate(),
      isNew: false, // 신규여부
      searchRunDtOpen: false,

      modalBtns: [{name: '확인', callback: this.onHideModal}],
      inventoryModalBtns: [{name: '확인', callback: this.onHideInventory}],
      poCompleteListBtns: [{name: '확인', callback: this.onHidePoCompleteList}],
      unitOpertListBtns: [{name: '확인', callback: this.onHideUnitOpertList}],

      itemCode: '',
      itemSeq: '',
      midCode: '',
      matCode: undefined,
      excelBtns: [{name: '확인', callback: this.onPopConfirmExcel}],
      resultList: [],
      excelRows: [
        {
          value: [
            {value: '투입일자', style: {type: 'TEXT'}},
            {value: '실행중공종코드'},
            {value: '실행내역코드', style: {type: 'TEXT'}},
            {value: '자원코드', style: {type: 'TEXT'}},
            {value: '단위작업분류', style: {type: 'TEXT'}},
            {value: '단위작업ID', style: {type: 'TEXT'}},
            {value: '자재코드', style: {type: 'TEXT'}},
            {value: '수량'},
            {value: '단가'},
            {value: '외주 계약번호'},
          ],
        },
      ],
      excelSheetOpt: excelOptions(this),
      wCodeList: [],
      clWorkList: [],
      clWorkFilter: [],
    };
  },
  computed: {
    isMonthClose() {
      return this.monPjtCloseAt == 'Y'; // 월마감 가능여부
    },
  },
  created() {
    this.costType = this.isSimpleExec ? '' : 'M';
    this.addFuncPrj(this.onInitSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },
  methods: {
    onSearchWCodeList() {
      //공사구분
      const param = {};
      selectWcodeBYExe(param).then(response => {
        this.wCodeList = response.data.map(e => ({text: e.wname, value: e.wcode}));
      });
    },
    onSearchClWorkList() {
      //공종분류
      const param = {};
      selectClWorkBYExe(param).then(response => {
        this.clWorkList = response.data.map(e => {
          return {wcode: e.wcode, headCode: e.headCode, midCode: e.midCode, value: e.clCd, text: e.clNm};
        });
      });
    },
    onSetValue(key, value) {
      this.searchInfo[key] = value;

      if (key == 'wcode') {
        this.clWorkFilter = this.clWorkList.filter(r => r.wcode == value);
        this.searchInfo['clCode'] = '';
        this.searchInfo['headCode'] = '';
        this.searchInfo['midCode'] = '';
      }

      if (key == 'wcode' || key == 'clCode') {
        let headCode = this.searchInfo.clCode.split('::')[0];
        let midCode = this.searchInfo.clCode.split('::')[1];
        this.searchInfo['headCode'] = headCode;
        this.searchInfo['midCode'] = midCode;

        this.onExecSearch();
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    matAdd(e) {
      function reOrder(row) {
        const nextRow = row.nextSibling;
        if (nextRow && row.midCode == nextRow.midCode && row.itemSeq == nextRow.itemSeq) {
          e.sheet.setValue(nextRow, 'rowNo', row.rowNo + 1, 1);
          reOrder(nextRow);
        }
      }

      if (this.monPjtCloseAt == 'N') {
        const addRow = e.sheet.copyRow(e.row, e.row.nextSibling);
        e.sheet.setValue(addRow, 'qty', '');
        e.sheet.setValue(addRow, 'amt', '');
        e.sheet.setValue(addRow, 'seq', 0);
        e.sheet.setValue(addRow, 'cud', '1');
        if (this.isSimpleExec || e.row.costType == $getConstants('COST_TYPE_I').code) {
          e.sheet.setValue(addRow, 'matCode', '');
          e.sheet.setValue(addRow, 'matName', '');
          e.sheet.setValue(addRow, 'matSize', '');
          e.sheet.setValue(addRow, 'matUnitName', '');
          e.sheet.setValue(addRow, 'price', '');
          e.sheet.setValue(addRow, 'custName', '');
          e.sheet.setValue(addRow, 'poNoSubc', '');
        }
        reOrder(e.row);
        return addRow;
      }
    },
    async onDelete() {
      if (this.isMonthClose) {
        this.$alert({title: '마감완료', message: '월마감 상태이므로 삭제는 불가합니다.'});
        return;
      }

      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({title: '투입내역삭제', message: '삭제할 투입내역을 선택해 주세요.'});
        return;
      }

      const matItemList = checkedRows
        .filter(item => item.cud !== 1)
        .map(row => {
          return {
            pgmCd: row.pgmCd,
            prjCd: row.prjCd,
            ioDate: row.ioDate,
            seq: row.seq,
            matCode: row.matCode,
            poNoSubc: row.poNoSubc,
            custCode: row.custCode,
            wcode: row.wcode,
            headCode: row.headCode,
            midCode: row.midCode,
            itemSeq: row.itemSeq,
            itemCode: row.itemCode,
          };
        });

      if (!(await this.$confirm({title: '투입내역삭제', message: '선택한 투입내역을 삭제하시겠습니까?'}))) {
        return;
      }

      if (matItemList.length) {
        const param = {ioDate: this.searchInfo.ioDate, matItemList};
        const response = await this.deleteDelevery(param);
        if (response?.data) {
          this.onSearch();
        }
      }
    },
    async matRemove(e) {
      function reOrder(row) {
        const nextRow = row.nextSibling;
        if (nextRow && row.midCode == nextRow.midCode && row.itemSeq == nextRow.itemSeq) {
          e.sheet.setValue(nextRow, 'rowNo', nextRow.rowNo - 1);
          reOrder(nextRow);
        }
      }

      if (this.monPjtCloseAt == 'N') {
        if (!(await this.$confirm({title: '투입내역삭제', message: '선택한 투입내역을 삭제하시겠습니까?'}))) {
          return;
        }

        if (!e.row.Added) {
          const matItemList = [
            {
              ioDate: e.row.ioDate,
              seq: e.row.seq,
              matCode: e.row.matCode,
              poNoSubc: e.row.poNoSubc,
              custCode: e.row.custCode,
              wcode: e.row.wcode,
              headCode: e.row.headCode,
              midCode: e.row.midCode,
              itemSeq: e.row.itemSeq,
              itemCode: e.row.itemCode,
            },
          ];

          const param = {ioDate: this.searchInfo.ioDate, matItemList};
          const response = await this.deleteDelevery(param);
          if (response?.data) {
            this.onSearch();
          }
        } else {
          reOrder(e.row);
          e.sheet.removeRow(e.row, null);
          e.sheet.renderBody();
        }
      }
    },
    async deleteDelevery(param) {
      try {
        const response = await deleteDeliveryDetailList(param);
        return response;
      } catch (error) {
        this.$alert({title: '저장', message: error.message});
      }
    },
    async onInitSearch() {
      this.onSearchWCodeList(); //공사구분
      this.onSearchClWorkList(); //공종분류
      const response = await selectLastIoDate(this.searchInfo);
      const lastIoDate = response.data ? response.data : this.currentDate;

      this.onSetIoDate(lastIoDate);
      this.onSearchMonthCloseAt(this.currentDate);
      this.onSearch();
    },
    onSetIoDate(ioDate) {
      this.searchInfo.ioDate = String(ioDate);
    },
    async onSearchMonthCloseAt(ioDate) {
      if (!ioDate) return;
      const closeMm = String(ioDate).substring(0, 6);
      const param = {closeMm};
      const response = await selectMonthClosePossibleAt(param);

      if (response.data.CHECK1 == 'N') {
        this.monPjtCloseAt = 'Y';
        if (closeMm === this.currentDate.substring(0, 6)) {
          this.currentPjtClseAt = 'Y';
        }
      } else {
        this.monPjtCloseAt = 'N';
        this.currentPjtClseAt = 'N';
      }
    },
    async onSearch() {
      if (!this.searchInfo.ioDate) {
        this.$alert({title: '투입일자', message: '투입일자를 선택해 주세요.'});
        return;
      }

      this.taskList = [];
      this.isNew = false;

      const param = {
        ioDate: this.searchInfo.ioDate,
        wcode: this.wcode,
        headCode: this.searchInfo.clCode.split('::')[0] ?? '',
        midCode: this.searchInfo.clCode.split('::')[1] ?? '',
        searchNm: this.searchInfo.searchNm,
      };
      const response = await selectDeliveryDetailList(param);
      response.data.forEach(item => (item.remQtyBefore = item.remQty));
      this.loadSearchData = response.data;
      this.cud = 0;
    },
    onExecSearch() {
      if (this.cud === 1) {
        this.onSearchMaterialExecDetails();
      } else {
        this.onSearch();
      }
    },
    onChangeSearchInfo(key, event) {
      const value = typeof event == 'string' ? event : event.target.value;
      this.searchInfo[key] = value;

      if (key == 'ioDate') {
        this.onSetIoDate(value);
        this.onSearchMonthCloseAt(value);

        this.taskList = [];
        this.sheet.removeAll();

        if (value) {
          this.onSearch();
        } else {
          this.searchRunDtOpen = true;
        }
      }
    },
    async onChangeIoDate(searchDateType) {
      const param = {...this.searchInfo, searchDateType};
      const response = await selectLastIoDate(param);

      const lastIoDate = response.data;
      if (lastIoDate) {
        this.onSearchMonthCloseAt(lastIoDate);
        this.onSetIoDate(lastIoDate);
        this.onSearch();
      } else {
        const title = '투입일자';
        const message = `${searchDateType == 'PREV' ? '이전' : '다음'} 투입일이 없습니다.`;
        this.$alert({title, message}, () => {
          if (!this.searchInfo.ioDate) {
            this.onSetIoDate($_getCurrentDate());
          }
        });
      }
    },
    onAdd() {
      if (this.currentPjtClseAt == 'Y') {
        this.$alert({title: '마감완료', message: '월마감 상태이므로 투입등록은 불가합니다.'});
        return;
      }

      this.isNew = true;
      this.onShowUnitOpertList();
    },
    async onSearchMaterialExecDetails() {
      const param = {
        ioDate: this.searchInfo.ioDate,
        wcode: this.wcode,
        headCode: this.searchInfo.clCode.split('::')[0] ?? '',
        midCode: this.searchInfo.clCode.split('::')[1] ?? '',
        searchNm: this.searchInfo.searchNm,
        costType: this.costType,
        wbsIdList: this.searchInfo.wbsIdList,
      };
      const response = await selectMaterialExecDetails(param);
      this.loadSearchData = response.data.map(item => {
        item.cud = 1;
        item.Added = true;
        return item;
      });
    },
    async onSave() {
      if (this.isMonthClose) {
        this.$alert({title: '마감완료', message: '월마감 상태이므로 수정은 불가합니다.'});
        return;
      }
      if (!this.sheet.hasChangedData()) {
        this.$alert({title: '투입내역저장', message: '변경된 내용이 없습니다.'});
        return;
      }

      const matItemList = this.sheet.getSaveJson().data;
      const validRow = matItemList.find(row => !row.matCode || !row.qty);

      if (validRow !== undefined) {
        const idx = this.sheet.getRowIndex(this.sheet.getRowById(validRow.id));
        const message = !validRow.matCode ? '자재가 선택되지 않았습니다.' : '수량 입력되지 않았습니다.';
        this.$alert({title: '투입내역저장', message: `${idx}행의 ${message} `});
        return;
      }

      try {
        const param = {ioDate: this.searchInfo.ioDate, matItemList};
        const response = await saveDeliveryDetailList(param);
        if (response.data) {
          this.onSearch();
        }
      } catch (error) {
        this.$alert({title: '저장', message: error.message});
      }
    },

    onShowUnitOpertList() {
      this.$modal.show('UnitOpertListModal');
    },
    onHideUnitOpertList() {
      this.callEvent({
        name: 'getRowsByCheckedAndInputDate',
        param: data => {
          if (data.rows.length) {
            this.searchInfo.ioDate = data.inputDate;
            this.searchInfo.wbsIdList = data.rows.map(row => row.fldrCd);
            this.onSearchMaterialExecDetails();
          }
          this.$modal.hide('UnitOpertListModal');
        },
      });
    },
    onShowInventory(e) {
      this.itemCode = String(e.row.itemCode);
      this.$modal.show('InventoryModal');
    },
    onHideInventory() {
      this.callEvent({
        name: 'InputMatListPopup_callbackData',
        param: data => {
          let focusedRow = this.sheet.getFocusedRow();

          // 자재변경시 변경 전의 값 존재하는 경우 변경 전 자재의 재고수량 원복
          const qtyBefore = focusedRow.qty;
          const matCodeBefore = focusedRow.matCode;
          if (qtyBefore && matCodeBefore) {
            const filterRow = this.sheet.getDataRows().filter(row => row.matCode && row.matCode == matCodeBefore);
            filterRow.forEach(row => this.sheet.setValue(row, 'remQty', row.remQty + qtyBefore, true));
          }

          if (data instanceof Array) {
            const tempArray = [];
            data.forEach((row, index) => {
              if (index > 0) {
                if (focusedRow) {
                  focusedRow = this.matAdd({sheet: this.sheet, row: focusedRow});
                }
              }
              tempArray.push({
                focusedRow,
                row,
              });
            });
            setTimeout(() => {
              tempArray.forEach(obj => {
                this.setRowAsMatInfo(obj.focusedRow, obj.row);
              });
            }, 100);
          } else {
            this.setRowAsMatInfo(focusedRow, data);
          }

          this.$modal.hide('InventoryModal');
        },
      });
    },

    setRowAsMatInfo(focusedRow, data) {
      // 동일한 자재가 존재하는 경우 기존 자재의 재고정보로 SET => 기존 자재의 수량이 변경되었을 수 있기 때문
      const findRow = this.sheet.getDataRows().find(row => row.matCode && row.matCode == data.matCode);
      if (findRow !== undefined) {
        this.sheet.setValue(focusedRow, 'remQty', findRow.remQty, true);
        this.sheet.setValue(focusedRow, 'remQtyBefore', findRow.remQtyBefore, true);
      } else {
        this.sheet.setValue(focusedRow, 'remQty', data.remQty, true);
        this.sheet.setValue(focusedRow, 'remQtyBefore', data.remQty, true);
      }

      // SET VALUE
      this.sheet.setValue(focusedRow, 'matCode', data.matCode, true);
      this.sheet.setValue(focusedRow, 'matName', data.matName, true);
      this.sheet.setValue(focusedRow, 'matSize', data.matSize, true);
      this.sheet.setValue(focusedRow, 'matUnit', data.matUnit, true);
      this.sheet.setValue(focusedRow, 'matUnitName', data.matUnitName, true);
      this.sheet.setValue(focusedRow, 'price', data.avrgPrice, true);
      this.sheet.setValue(focusedRow, 'matInoutYn', '', true);
      this.sheet.setValue(focusedRow, 'qty', 0, true);
      this.sheet.setValue(focusedRow, 'cud', 1, true);
      this.sheet.setAttribute(focusedRow, 'qty', 'CanEdit', true);
    },
    onShowPoCompleteList(e) {
      this.itemSeq = String(e.row.itemSeq);
      this.midCode = String(e.row.midCode);
      this.matCode = e.row.matCode ? String(e.row.matCode) : undefined;
      this.$modal.show('PoCompleteListModal');
    },
    onHidePoCompleteList() {
      this.callEvent({
        name: 'SubCustListPopup_callbackData',
        param: data => {
          const focusedRow = this.sheet.getFocusedRow();
          this.sheet.setValue(focusedRow, 'poNoSubc', data.poNo, true);
          this.sheet.setValue(focusedRow, 'custName', data.cusNm, true);
          this.sheet.setValue(focusedRow, 'custCode', data.cusCd, true);
          this.sheet.setValue(focusedRow, 'cud', 1, true);
          this.$modal.hide('PoCompleteListModal');
        },
      });
    },
    onBeforeChange(e) {
      const colNm = e.col;
      const qty = Number(e.val); // 입력한 수량
      const qtyBefore = e.oldval; // 변경전 수량
      const remQty = e.row.remQty; // 재고수량
      const matCode = e.row.matCode;

      if (colNm === 'qty') {
        if (!matCode && 0 < qty) {
          this.$alert({title: '자재코드', message: '자재코드를 먼저 선택해야 합니다.'});
          return qtyBefore;
        }
        if (0 > qty) {
          return qtyBefore;
        }
        if (remQty + qtyBefore < qty || isNaN(qty)) {
          //재고수량 + 변경전 수량 < 입력한 수량
          this.$alert({
            title: '수량',
            message: `해당 자재의 재고수량은 ${remQty}입니다.<br/>수량을 다시 입력하시기 바랍니다.`,
          });
          return qtyBefore;
        }
        return qty;
      }
    },
    onAfterChange(e) {
      if (e.col == 'qty') {
        const qty = e.val; // 입력한 수량
        const qtyBefore = Number(e.row.qtyBeforeVal ? e.row.qtyBeforeVal : e.row.qtyOrig); // 변경전 수량
        const remQty = e.row.remQty; // 현재 재고수량
        const matCode = e.row.matCode;

        // 동일한 자재들의 재고수량 변경
        const filterRows = this.sheet.getDataRows().filter(row => row.matCode && row.matCode == matCode);
        filterRows.forEach(row => this.sheet.setValue(row, 'remQty', remQty + qtyBefore - qty, true));
      }
    },
    onPopAddExcel() {
      this.$modal.show('excelUploadPop');
    },
    onPopConfirmExcel() {
      this.callEvent({
        name: 'excelUpload',
        param: () => {
          this.onSearch();
          this.$modal.hide('excelUploadPop');
        },
      });
    },
    onExcelUploadError(error, list) {
      if (error === 'server') {
        this.$alert({title: '엑셀업로드', message: '자재투입등록 엑셀파일을 확인하십시오.'});
      } else {
        this.$alert({title: '엑셀업로드', message: error});
        if (list && list.length > 0) {
          this.resultList = list;
          this.$modal.show('ExcelUploadResultPop');
        }
      }
    },
  },
};
</script>
