export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 0},
    Cols: [
      {Header: '기성횟수', Name: 'reqCnt', Type: 'Text', RelWidth: 1, MinWidth: 120, Align: 'center'},
      {
        Header: '기성년월',
        Name: 'reqYm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
        Align: 'center',
        CustomFormat: v => v.substr(0, 4) + '-' + v.substr(4, 2),
      },
      {
        Header: '기성구분',
        Name: 'reqDs',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
        Align: 'center',
        Format: $getConstants('REQ_DS').code,
      },
      {Header: '기성청구금액', Name: 'rsumAmt', Extend: preset.amount, RelWidth: 1, MinWidth: 120},
      {Header: '공제금액', Name: 'deductAmt', Extend: preset.amount, RelWidth: 1, MinWidth: 120},
      {Header: '직불금액', Name: 'directAmt', Extend: preset.amount, RelWidth: 1, MinWidth: 120},
      {Header: '수금예정액', Name: 'actIncomAmt', Extend: preset.amount, RelWidth: 1, MinWidth: 120},
      {Header: '수금금액', Name: 'repSumAmt', Extend: preset.amount, RelWidth: 1, MinWidth: 120},
      {
        Header: '미수금액',
        Name: 'uncollectAmt',
        Extend: preset.amount,
        RelWidth: 1,
        MinWidth: 120,
        Formula: fr => fr.Row['actIncomAmt'] - fr.Row['repSumAmt'],
      },
    ],
  };
};
