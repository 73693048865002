<template>
  <pmis-content-box>
    <template v-slot:title>
      공동대표정보
    </template>
    <iui-container-new type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
/**
 * 계약정보등록(tab2) > 조직도 > 조직정보 > 공동대표정보(팝업)
 *
 * */
export default {
  props: {
    bsnNo: {
      type: [String, Number],
      default: undefined,
    },
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
    };
  },
  async mounted() {
    const OPT = {
      Cfg: {
        SearchMode: 2,
        CanEdit: 0,
        CanSort: 0,
      },
      Def: {
        Row: {
          NoColor: 1,
          CanFocus: 0,
        },
      },
      Cols: [
        {
          Header: '순번',
          Name: 'seq',
          Type: 'Text',
          Align: 'center',
          Width: 55,
        },
        {
          Header: '대표자명',
          Name: 'rpsvNm',
          Type: 'Text',
          Align: 'center',
          RelWidth: 1,
          MinWidth: 120,
        },
        {
          Header: '대표자명(영문)',
          Name: 'rpsvEnNm',
          Type: 'Text',
          Align: 'center',
          RelWidth: 1,
          MinWidth: 120,
        },
      ],
    };

    PMISSheet.createSheet({el: this.elId, options: OPT}).then(sheet => {
      this.sheet = sheet;

      this.onSearch();
    });
  },
  methods: {
    onSearch() {
      axios
        .post('/customer/selectCustomerCeoList', {
          pgmCd: this.pgmCd,
          bsnNo: this.bsnNo,
        })
        .then(response => this.sheet.loadSearchData(response.data));
    },
  },
};
</script>

<style></style>
