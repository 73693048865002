export default that => {
  const preset = that.$ibsheetHelper.preset;

  const sheetOption = {
    Cfg: {CanEdit: 1, CanSort: 0},
    Def: {
      Row: {NoColor: 2, CanFocus: 0},
    },
    Cols: [
      {
        Header: '자재코드',
        Name: 'itemCd',
        Type: 'Text',
        Align: 'Center',
        Width: 140,
        CanEdit: false,
        TextStyle: 5,
        TextColor: '#FF0000',
        Cursor: 'Pointer',
      },
      {Header: '명칭', Name: 'itemNm', Type: 'Text', RelWidth: 1, MinWidth: 180, CanEdit: false},
      {Header: '규격', Name: 'sizeNm', Type: 'Text', RelWidth: 1, MinWidth: 200, CanEdit: false},
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Align: 'Center',
        Width: 80,
        CanEdit: false,
        Format: $getConstants('UNIT').code,
      },
      {Header: '예산수량', Name: 'planQty', Extend: preset.quantity, Width: 90, CanEdit: false},
      {Header: '기청구수량', Name: 'preQty', Extend: preset.quantity, Width: 90, CanEdit: false},
      {Header: '잔여수량', Name: 'remQty', Extend: preset.quantity, Width: 90, CanEdit: false},
      {
        Header: '금회요청수량',
        Name: 'qty',
        Extend: preset.quantity,
        Width: 95,
        CanEdit: true,
        Color: '#E0E0F8',
      },
      {
        Header: '비고',
        Name: 'itemRmrk',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 80,
        CanEdit: true,
        Color: '#E0E0F8',
      },
      {Name: 'prSeq', Visible: false},
      {Header: '단가', Name: 'planUnitPrc', Visible: false},
      {Header: '금액', Name: 'amt', Visible: false, Formula: fr => fr.Row['qty'] * fr.Row['planUnitPrc'] || 0},
    ],
  };

  if (that.isSimpleExec) {
    const excludeColInSimpleExec = ['planQty', 'remQty', 'planUnitPrc', 'amt']; // 실행내역 간소화 시 제외 필드
    sheetOption.Cols = sheetOption.Cols.filter(col => !excludeColInSimpleExec.includes(col.Name));
  }

  return sheetOption;
};
