const VueIBSheetHelper = {
  install: function(Vue) {
    Vue.prototype.$ibsheetHelper = {
      /**
       * IBSheet 열 정의
       */
      preset: {
        // TODO: 현장코드, 외주코드, 내역코드, 자재코드
        // TODO: 명칭, 규격, 단위
        // TODO: 날짜-기간
        /** 날짜 */
        date: {
          Align: 'center',
          Width: 120,
          Type: 'Date',
          CanEdit: false,
          DataFormat: 'yyyyMMdd',
          Format: 'yyyy-MM-dd',
          EditFormat: 'yyyy-MM-dd',
        },
        /** 숫자 */
        number: {
          Align: 'right',
          Type: 'Int',
          Format: '#,##0',
          EditFormat: '#,##0',
          DefaultValue: 0,
        },
        /** 수량 */
        quantity: {
          Align: 'right',
          Type: 'Text',
          Class: 'sheet_quantity',
          ResultMask: '^[(-\\d)*$|\\d*$]',
          DefaultValue: 0,
          Width: 85,
          CustomFormat: function(value) {
            const decemalCnt = value.split('.')[1]?.length > 4 ? 4 : value.split('.')[1]?.length;
            return $_numberFormat((Math.floor(value * 10000) / 10000).toFixed(decemalCnt ?? 0), true);
          },
        },
        /** 단가 */
        unitAmount: {
          Align: 'right',
          Type: 'Text',
          Class: 'sheet_unitAmount',
          ResultMask: '^[(-\\d)*$|\\d*$]',
          DefaultValue: 0,
          Width: 110,
          CustomFormat: function(value) {
            const decemalCnt = value.split('.')[1]?.length ?? 0;
            return $_numberFormat((Math.floor(value * 10) / 10).toFixed(decemalCnt), true);
          },
        },
        /** 금액 */
        amount: {
          Align: 'right',
          Type: 'Text',
          Class: 'sheet_amount',
          ResultMask: '^[(-\\d)*$|\\d*$]',
          DefaultValue: 0,
          Width: 110,
          CustomFormat: function(value) {
            return $_numberFormat(Math.floor(value), true);
          },
        },
        /** 비율 */
        rate: {
          Align: 'right',
          Type: 'Float',
          Format: '#,##0.##',
          EditFormat: '#,##0.##',
          Class: 'sheet_rate',
          ResultMask: '^[(-\\d)*$|\\d*$]',
          DefaultValue: 0,
          CustomFormat: function(value) {
            const decemalCnt = value.split('.')[1]?.length ?? 0;
            return $_numberFormat((Math.floor(value * 100) / 100).toFixed(decemalCnt), true);
          },
        },
        rate3: {
          Align: 'right',
          Type: 'Float',
          Format: '#,##0.###',
          EditFormat: '#,##0.###',
          Class: 'sheet_rate',
          ResultMask: '^[(-\\d)*$|\\d*$]',
          DefaultValue: 0,
          CustomFormat: function(value) {
            const decemalCnt = value.split('.')[1]?.length ?? 0;
            return $_numberFormat((Math.floor(value * 1000) / 1000).toFixed(decemalCnt), true);
          },
        },

        /** 수량 */
        quantityFormula: {
          Align: 'right',
          Type: 'Float',
          Format: '#,##0.####',
          EditFormat: '#,##0.####',
          ResultMask: '^[(-\\d)*$|\\d*$]',
          DefaultValue: 0,
          Width: 85,
          Formula: function(fr) {
            const value = fr.Row[fr.Col];
            const decemalCnt = String(value).split('.')[1]?.length ?? 0;
            return (Math.floor(value * 10000) / 10000).toFixed(decemalCnt);
          },
        },
        /** 단가 */
        unitAmountFormula: {
          Align: 'right',
          Type: 'Float',
          Format: '#,##0.#',
          EditFormat: '#,##0.#',
          ResultMask: '^[(-\\d)*$|\\d*$]',
          DefaultValue: 0,
          Width: 110,
          Formula: function(fr) {
            const value = fr.Row[fr.Col];
            const decemalCnt = String(value).split('.')[1]?.length ?? 0;
            return (Math.floor(value * 10) / 10).toFixed(decemalCnt);
          },
        },
        /** 금액 */
        amountFormula: {
          Align: 'right',
          Type: 'Int',
          Format: '#,##0',
          EditFormat: '#,##0',
          ResultMask: '^[(-\\d)*$|\\d*$]',
          DefaultValue: 0,
          Width: 110,
          Formula: function(fr) {
            const value = fr.Row[fr.Col];
            return Math.floor(value);
          },
        },
        /** 비율 */
        rateFormula: {
          Align: 'right',
          Type: 'Float',
          Format: '#,##0.##',
          EditFormat: '#,##0.##',
          ResultMask: '^[(-\\d)*$|\\d*$]',
          DefaultValue: 0,
          Formula: function(fr) {
            const value = fr.Row[fr.Col];
            const decemalCnt = String(value).split('.')[1]?.length ?? 0;
            return (Math.floor(value * 100) / 100).toFixed(decemalCnt);
          },
        },
      },

      /**
       * 상태 컬럼을 삽입한다.
       * @param {*} options IBSheet options 객체
       * @param {*} index 상태 컬럼을 삽입할 인덱스
       */
      insertStatusColumn: function(options, index) {
        let statusCol = {
          Name: 'Status',
          Header: '상태',
          Type: 'Text',
          Format: {C: '입력', R: '', U: '수정', D: '삭제'},
          Formula: `Row.Deleted ? "D" : Row.Added ? "C" : Row.Changed ? "U" : "R"`,
        };

        index = index || 1;

        options.Cols = options.Cols || [];
        options.Cols.splice(index, 0, statusCol);

        options.Def = options.Def || {};
        options.Def.Row = options.Def.Row || {};
        options.Def.Row.CanFormula = 1;
      },

      bindDefaultDeleteEvent: function(sheet, boolColumnName) {
        boolColumnName = boolColumnName || 'cbx';

        sheet.bind('onAfterClick', function(e) {
          if (e.row.Kind == 'Data' && e.col == boolColumnName) {
            if (!e.row[boolColumnName]) {
              sheet.deleteRow({row: e.row, del: 0});
            }
          }
        });

        sheet.bind('onBeforeRowDelete', function(e) {
          // 삭제
          if (e.type == 0 && e.row.Added) {
            sheet.removeRow({row: e.row});
            return false;
          }

          // 삭제 취소
          if (e.type == 1) {
            sheet.setAttribute(e.row, '', 'TextStyle', '', 1);
          }
        });

        sheet.bind('onAfterRowDelete', function(e) {
          sheet.setAttribute(e.row, '', 'TextStyle', '9', 1);
        });
      },
    };
  },
};

export default VueIBSheetHelper;
