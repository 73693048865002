<template>
  <form onsubmit="return false;">
    <iui-container-new type="css-flex">
      <i-row height="430px">
        <i-col>
          <pmis-content-box>
            <template v-slot:title>업체기본정보</template>
            <iui-container-new type="table" theme="bullet" header-width="110px">
              <colgroup>
                <col width="110px" />
                <col />
                <col width="10px" />
                <col width="110px" />
                <col />
              </colgroup>
              <i-row>
                <i-col-header required>사업자번호</i-col-header>
                <i-col>
                  <iui-bsn-no
                    :value1.sync="bsnNo1"
                    :value2.sync="bsnNo2"
                    :value3.sync="bsnNo3"
                    @change1="onSetBsnNo"
                    @change2="onSetBsnNo"
                    @change3="onSetBsnNo"
                    :enable="isNew"
                    required
                  />
                  <iui-button v-if="isNew" value="중복확인" @click="onDuplicateBsnNo" />
                </i-col>
                <i-spacer />
                <i-col-header>주민/법인번호</i-col-header>
                <i-col>
                  <iui-regi-no
                    :value1.sync="regiNo1"
                    :value2.sync="regiNo2"
                    @change1="onSetRegiNo"
                    @change2="onSetRegiNo"
                    :enable="isNew"
                    :required="false"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>회사명</i-col-header>
                <i-col>
                  <iui-text
                    name="frmNm"
                    placeholder="(사업자등록증 상호 등록)"
                    :value="detailInfo.frmNm"
                    :bindArray="bindArray"
                    max-length="100"
                    width="330px"
                    required
                    :error-message="{title: '회사명', message: '회사명을 입력해야 합니다.'}"
                  />
                </i-col>
                <i-spacer />
                <i-col-header>회사명(영문)</i-col-header>
                <i-col>
                  <iui-text
                    name="frmEnNm"
                    :value="detailInfo.frmEnNm"
                    :bindArray="bindArray"
                    max-length="100"
                    width="330px"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>대표자명</i-col-header>
                <i-col>
                  <iui-text
                    name="rpsvNm"
                    :value="detailInfo.rpsvNm"
                    :bindArray="bindArray"
                    max-length="50"
                    width="95px"
                    required
                    :error-message="{title: '대표자명', message: '대표자명을 입력해야 합니다.'}"
                  />
                  <iui-button value="공동대표" @click="ceoListPopUp" />
                </i-col>
                <i-spacer />
                <i-col-header>대표자(영문)</i-col-header>
                <i-col>
                  <iui-text
                    name="rpsvEnNm"
                    placeholder="(영문명)"
                    :value="detailInfo.rpsvEnNm"
                    :bindArray="bindArray"
                    max-length="50"
                    width="330px"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>주소</i-col-header>
                <i-col>
                  <iui-searchbox
                    :idValue="detailInfo.postNo"
                    :nameValue="detailInfo.adr1"
                    name="postNo"
                    nameEleName="adr1"
                    :type="'addr'"
                    :idBindArray="bindArray"
                    :nameBindArray="bindArray"
                    required
                    :errorMessage="{title: '주소', message: '현장주소는 필수입력입니다'}"
                  />
                  <!-- <iui-text
                    name="postNo"
                    :value="detailInfo.postNo"
                    :bindArray="bindArray"
                    width="60px"
                    :enable="false"
                    required
                    :error-message="{title: '주소', message: '주소를 입력해야 합니다.'}"
                  />
                  <iui-button value="검색" @click="onOpenAddress" />
                  <iui-text
                    name="adr1"
                    placeholder="(사업자등록증 주소 등록)"
                    :value="detailInfo.adr1"
                    :bindArray="bindArray"
                    :enable="false"
                    required
                    :error-message="{title: '주소', message: '주소를 입력해야 합니다.'}"
                  /> -->
                </i-col>
                <i-spacer />
                <i-col-header required>대표전화</i-col-header>
                <i-col>
                  <iui-text
                    type="phone"
                    align="left"
                    name="rpsvPhone"
                    placeholder="(숫자만 입력)"
                    :value="detailInfo.rpsvPhone"
                    :bindArray="bindArray"
                    width="95px"
                    required
                    :error-message="{title: '대표전화', message: '대표전화를 입력해야 합니다.'}"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-spacer />
                <i-col>
                  <i-col width="105px"></i-col>
                  <iui-text
                    name="adr2"
                    placeholder="(상세주소)"
                    :value="detailInfo.adr2"
                    :bindArray="bindArray"
                    max-length="100"
                    required
                    :error-message="{title: '주소', message: '주소를 입력해야 합니다.'}"
                  />
                </i-col>
                <i-spacer />
                <i-col-header>홈페이지</i-col-header>
                <i-col>
                  <iui-text
                    name="homepage"
                    :value="detailInfo.homepage"
                    :bindArray="bindArray"
                    max-length="300"
                    width="330px"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>업태</i-col-header>
                <i-col>
                  <iui-text
                    name="bcoNm"
                    :value="detailInfo.bcoNm"
                    :bindArray="bindArray"
                    max-length="100"
                    width="330px"
                    required
                    :error-message="{title: '업태', message: '업태를 입력해야 합니다.'}"
                  />
                </i-col>
                <i-spacer />
                <i-col-header required>종목</i-col-header>
                <i-col>
                  <iui-text
                    name="itemNm"
                    :value="detailInfo.itemNm"
                    :bindArray="bindArray"
                    max-length="100"
                    width="330px"
                    required
                    :error-message="{title: '종목', message: '종목을 입력해야 합니다.'}"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>계좌정보</i-col-header>
                <i-col>
                  <iui-select
                    name="bankCode"
                    :p-code="$getConstants('BANK_CODE').code"
                    defaultCd="S"
                    :value="detailInfo.bankCode"
                    @change="(value) => setDetailInfo({bankCode: value})"
                    width="140px"
                  />
                  <iui-text
                    name="depositNo"
                    :value="detailInfo.depositNo"
                    :bindArray="bindArray"
                    max-length="20"
                    width="185px"
                  />
                </i-col>
                <i-spacer />
                <i-col-header>예금주</i-col-header>
                <i-col>
                  <iui-text
                    name="depositor"
                    :value="detailInfo.depositor"
                    :bindArray="bindArray"
                    max-length="50"
                    width="95px"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col colspan="2">
                  <iui-container-new type="table" theme="bullet" header-width="110px">
                    <i-row v-if="frmGb1 || frmGb4 || frmGb0">
                      <i-col-header required>거래처유형</i-col-header>
                      <i-col>
                        <iui-select
                          name="frmDs"
                          :p-code="$getConstants('FRM_DS').code"
                          width="140px"
                          :value="detailInfo.frmDs"
                          @change="(value) => setDetailInfo({frmDs: value})"
                          defaultCd="S"
                          required
                          :error-message="{title: '거래처유형', message: '거래처유형을 입력해야 합니다.'}"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>거래종료구분</i-col-header>
                      <i-col>
                        <iui-checkbox-group
                          name="useCloseDs"
                          :p-code="$getConstants('USE_CLOSE_DS').code"
                          :value="detailInfo.useCloseDs"
                          :checkedValues="[detailInfo.useCloseDs]"
                          @change="onChange"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col colspan="2">
                        <pmis-content-box>
                          <template v-slot:title>담당자정보</template>
                          <iui-container-new type="table" theme="bullet" header-width="110px">
                            <i-row>
                              <i-col-header required>담당자명</i-col-header>
                              <i-col>
                                <iui-text
                                  name="icprNm"
                                  :value="detailInfo.icprNm"
                                  :bindArray="bindArray"
                                  max-length="50"
                                  width="95px"
                                  required
                                  :error-message="{title: '담당자정보', message: '담당자정보를 입력해야 합니다.'}"
                                />
                              </i-col>
                            </i-row>
                            <i-row>
                              <i-col-header required>담당자 전화번호</i-col-header>
                              <i-col>
                                <iui-text
                                  name="icprPhone"
                                  type="phone"
                                  :value="detailInfo.icprPhone"
                                  :bindArray="bindArray"
                                  max-length="20"
                                  width="95px"
                                  required
                                  :error-message="{
                                    title: '담당자 전화번호',
                                    message: '담당자 전화번호를 입력해야 합니다.',
                                  }"
                                />
                              </i-col>
                            </i-row>
                            <i-row>
                              <i-col-header required>담당자 이메일</i-col-header>
                              <i-col>
                                <iui-text
                                  type="email1"
                                  width="95px"
                                  max-length="50"
                                  :value="detailInfo.email1"
                                  @change="setDetailInfo({email1: $event.target.value})"
                                  required
                                  :error-message="{title: '담당자 이메일', message: '담당자 이메일을 입력해야 합니다.'}"
                                />
                                <div>@</div>
                                <iui-select
                                  :p-code="$getConstants('EMAIL_CD').code"
                                  defaultCd="직접입력"
                                  :value="isDirectInputEmail ? '' : detailInfo.email2"
                                  @change="(value) => setDetailInfo({email2: value})"
                                />
                                <iui-text
                                  type="email2"
                                  max-length="50"
                                  :value="detailInfo.email2"
                                  @change="setDetailInfo({email2: $event.target.value})"
                                  v-if="isDirectInputEmail"
                                  :required="isDirectInputEmail"
                                  :error-message="{title: '담당자 이메일', message: '담당자 이메일을 입력해야 합니다.'}"
                                />
                              </i-col>
                            </i-row>
                            <i-row>
                              <i-col-header>담당자 팩스번호</i-col-header>
                              <i-col>
                                <iui-text
                                  type="phone"
                                  name="icprFaxno"
                                  :value="detailInfo.icprFaxno"
                                  :bindArray="bindArray"
                                  width="95px"
                                />
                              </i-col>
                            </i-row>
                          </iui-container-new>
                        </pmis-content-box>
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
                <i-spacer />
                <i-col colspan="2">
                  <pmis-content-box>
                    <iui-container-new type="table" theme="bullet" header-width="110px">
                      <i-row>
                        <i-col-header>국가명</i-col-header>
                        <i-col>
                          <iui-select
                            name="nationCd"
                            :p-code="$getConstants('NATION_CD').code"
                            :value="detailInfo.nationCd"
                            @change="(value) => setDetailInfo({nationCd: value})"
                            :defaultCd="'S'"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col colspan="2">
                          <iui-container-new type="css-flex">
                            <i-row height="145px">
                              <i-col>
                                <pmis-file-list
                                  title="첨부파일"
                                  title-visible
                                  theme="bullet"
                                  id="customerFile"
                                  :company-code="detailInfo.vndrPgmCd"
                                  :fileNumber="detailInfo.flNo"
                                  @delete-complete="onDeleteComplete"
                                />
                              </i-col>
                            </i-row>
                          </iui-container-new>
                        </i-col>
                      </i-row>
                    </iui-container-new>
                  </pmis-content-box>
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row v-if="!isNew && isExistVndrPgmCd">
        <i-col>
          <iui-container-new type="css-flex">
            <template v-if="frmDs1">
              <i-row min-height="150px">
                <i-col>
                  <HoldLcnsInfo title="면허상세정보" :enable="false" :height="sheetheight" />
                </i-col>
              </i-row>
            </template>
            <template v-if="frmDs2">
              <i-row min-height="150px">
                <i-col>
                  <WorkTypeInfo title="보유면허정보" :enable="false" :height="sheetheight" />
                </i-col>
                <i-spacer />
                <i-col>
                  <AreaInfo title="공사지역정보" :enable="false" :height="sheetheight" />
                </i-col>
              </i-row>
            </template>
            <template v-if="frmDs3">
              <i-row min-height="150px">
                <i-col>
                  <MtrilInfo :enable="false" :height="sheetheight" />
                </i-col>
                <i-spacer />
                <i-col>
                  <AreaInfo title="납품지역정보" :enable="false" :height="sheetheight" />
                </i-col>
              </i-row>
            </template>
            <template v-if="frmDs4">
              <i-row min-height="150px">
                <i-col>
                  <HoldLcnsInfo :enable="false" title="면허상세정보" :height="sheetheight" />
                </i-col>
              </i-row>
              <i-row min-height="150px">
                <i-col>
                  <WorkTypeInfo title="보유면허정보" :enable="false" :height="sheetheight" />
                </i-col>
                <i-spacer />
                <i-col>
                  <AreaInfo title="공사지역정보" :enable="false" :height="sheetheight" />
                </i-col>
              </i-row>
            </template>
          </iui-container-new>
        </i-col>
      </i-row>

      <iui-modal name="ceoListPopUp" title="공동대표" width="800px" height="500px" @beforeClose="onBeforeClose">
        <CeoInfo />
      </iui-modal>
    </iui-container-new>
  </form>
</template>

<script>
import {mapGetters} from 'vuex';
import CeoInfo from '@/view/customer/components/CeoInfo.vue';
import HoldLcnsInfo from '@/view/member/components/HoldLcnsInfo.vue';
import AreaInfo from '@/view/member/components/AreaInfo.vue';
import WorkTypeInfo from '@/view/member/components/WorkTypeInfo.vue';
import MtrilInfo from '@/view/member/components/MtrilInfo.vue';
import {
  saveCustomerInfo,
  selectCompanyInfo,
  selectCompanyInfoByBsnNo,
  selectDuplicateCustomerInfo,
  updateCustomerFlNo,
} from '../api/customer.js';

export default {
  components: {
    CeoInfo,
    HoldLcnsInfo,
    AreaInfo,
    WorkTypeInfo,
    MtrilInfo,
  },
  beforeCreate: function () {
    $mapGetters(this, ['detailInfo']);
    $mapMutations(this, [
      'setDetailInfo',
      'setFocusKeyCode',
      'setLicenseList',
      'setAreaList',
      'setMatList',
      'setGcodeList',
      'setCompanyInfo',
    ]);
  },
  created() {
    this.addEvent([
      {name: 'CustomerDetailInfo_SearchCompanyInfo', func: this.onSearchCompanyInfo},
      {name: 'CustomerDetailInfo_Save', func: this.onSave},
    ]);
  },
  data: function () {
    return {
      bindArray: ['setDetailInfo'],
      bsnNo1: '',
      bsnNo2: '',
      bsnNo3: '',
      regiNo1: '',
      regiNo2: '',
      deleteButton: false,
      sheetheight: '200',
    };
  },
  methods: {
    onChange(e) {
      this.setDetailInfo({useCloseDs: e.target.checked ? e.target.value : ''});
    },
    onSearchCompanyInfo() {
      let param = {
        pgmCd: this.detailInfo.vndrPgmCd,
      };
      this.setCompanyInfo(param);
      selectCompanyInfo(param).then((response) => {
        if (response.status == 200) {
          const companyData = response.data;
          this.setLicenseList(companyData.licenseList);
          this.setAreaList(companyData.areaList);
          this.setMatList(companyData.matList);
          this.setGcodeList(companyData.gcodeList);
        }
      });
    },
    onSetBsnNo() {
      this.setDetailInfo({bsnNo: `${this.bsnNo1}${this.bsnNo2}${this.bsnNo3}`, isCheckDuplicateBsnNo: false});
    },
    onSetRegiNo() {
      this.setDetailInfo({regiNo: `${this.regiNo1}${this.regiNo2}`});
    },
    async onDuplicateBsnNo() {
      const bsnNo = this.detailInfo.bsnNo;
      if (bsnNo.length != 10) {
        this.$alert({title: '사업자번호', message: '사업자번호를 입력해주세요.'});
        return;
      }

      // 1. 중복되는 거래처정보 조회 (TB_SYSTEM_CUSTOMER_M)
      const responseCustomer = await selectDuplicateCustomerInfo({
        pgmCd: this.pgmCd,
        bsnNo: bsnNo,
      });

      const isExistBsnNo = responseCustomer.data.bsnNo;
      const isClosedCustomer = responseCustomer.data.useCloseDs != ''; // 거래종료여부

      // 2. 업체정보 존재하는 경우 업체정보로 재조회
      if (isExistBsnNo) {
        this.$alert({title: '사업자번호 중복확인', message: '기등록된 협력사입니다.'}, () => {
          this.setFocusKeyCode(bsnNo);
          this.callEvent({name: 'CustomerList_research', param: isClosedCustomer});
        });
        return;
      }

      // 3. 회사정보 조회 (TB_SYSTEM_COMPANY_M)
      const responseCompany = await selectCompanyInfoByBsnNo({bsnNo: bsnNo, pgmCd: this.pgmCd});
      const companyInfo = responseCompany.data;

      // 4. 회사정보 조회하는 경우 회사정보로 재조회
      if (companyInfo) {
        this.$alert({title: '사업자번호 중복확인', message: '기등록된 회사입니다.'}, () => {
          this.setDetailInfo({
            frmDs:
              companyInfo.frmGb == $getConstants('FRM_GB_0').code
                ? $getConstants('FRM_DS_ETC').code
                : companyInfo.frmGb,
            regiNo: companyInfo.regiNo,
            frmNm: companyInfo.frmNm,
            frmEnNm: companyInfo.frmEnNm,
            rpsvNm: companyInfo.rpsvNm,
            rpsvEnNm: companyInfo.rpsvEnNm,
            rpsvPhone: companyInfo.rpsvPhone,
            postNo: companyInfo.postNo,
            adr1: companyInfo.adr1,
            adr2: companyInfo.adr2,
            homepage: companyInfo.homepage,
            bcoNm: companyInfo.bcoNm,
            itemNm: companyInfo.itemNm,
            bankCode: companyInfo.bankCode,
            depositNo: companyInfo.depositNo,
            depositor: companyInfo.depositor,
            nationCd: companyInfo.nationCd,
            vndrPgmCd: companyInfo.pgmCd,

            icprNm: companyInfo.worker.name,
            icprPhone: companyInfo.worker.mobileTlno,
            email1: companyInfo.worker.email1,
            email2: companyInfo.worker.email2,

            isNew: false,
            isNewCompanyData: true,
          });
          this.onSearchCompanyInfo();
        });
        return;
      }

      // 5. 업체정보, 회사정보 둘 다 없는 경우
      this.$alert({title: '사업자번호 중복확인', message: '등록 가능한 업체입니다.'}, () => {
        this.setDetailInfo({isCheckDuplicateBsnNo: true});
      });
    },
    ceoListPopUp() {
      this.$modal.show('ceoListPopUp');
    },
    async onBeforeClose(e) {
      // 취소버튼
      let flag = false;
      this.callEvent({
        name: 'ceoInfo_getSaveJson',
        param: (data) => {
          const valid = data.find((el) => el.status);
          if (valid !== undefined) {
            flag = true;
            e.stop();
          }
        },
      });
      if (flag) {
        const title = '공동대표정보';
        const message = '공동대표정보가 변경되었습니다.<br>저장하시겠습니까?';
        if (await this.$confirm({title, message})) {
          this.callEvent({name: 'ceoInfo_saveCeoList'});
        }
      }
    },
    onOpenAddress() {
      window.open(
        process.env.VUE_APP_API_URL + '/address/jusoPopup',
        'jusoPop',
        'width=570,height=420, scrollbars=yes, resizable=yes'
      );
    },
    onSave() {
      if (!this.detailInfo.isNew && !this.detailInfo.bsnNo) {
        this.$alert({title: '협력업체등록', message: '신규버튼을 클릭해 거래처 정보를 입력해야합니다.'});
        return false;
      }
      if (this.isNew && !this.isCheckDuplicateBsnNo) {
        this.$alert({title: '사업자번호 중복확인', message: '사업자번호 중복확인을 해주세요.'});
        return false;
      }
      if (this.detailInfo.regiNo && String(this.detailInfo.regiNo).length != 13) {
        this.$alert({title: '주민/법인번호', message: '주민/법인번호를 정확히 입력해주세요.'});
        return false;
      }

      const param = {
        ...this.detailInfo,
        cud: this.isNew || this.isNewCompanyData ? 1 : 2,
      };

      saveCustomerInfo(param).then((response) => {
        if (response.status == 200) {
          const bsnNo = this.detailInfo.bsnNo;
          const isClosedCustomer = this.detailInfo.useCloseDs != ''; // 거래종료여부

          this.setFocusKeyCode(bsnNo);
          this.callEvent({name: 'CustomerList_research', param: isClosedCustomer});
        }
      });
    },
    async onDeleteComplete(flNo) {
      if (flNo) return;

      this.setDetailInfo({flNo});

      let param = {
        pgmCd: this.detailInfo.pgmCd,
        bsnNo: this.detailInfo.bsnNo,
        flno: this.detailInfo.flno,
      };
      const response = await updateCustomerFlNo(param);
      this.callEvent({name: 'CustomerList_search'});
    },
  },
  computed: {
    ...mapGetters(['externalData']),
    frmDs1() {
      return this.detailInfo.frmDs == $getConstants('FRM_DS_CONSTRUCTION').code; // 협력업체 건설사
    },
    frmDs2() {
      return this.detailInfo.frmDs == $getConstants('FRM_DS_WORK_GROUP').code; // 협력업체 공사용역
    },
    frmDs3() {
      return this.detailInfo.frmDs == $getConstants('FRM_DS_MAT').code; // 협력업체 물품자재
    },
    frmDs4() {
      return this.detailInfo.frmDs == $getConstants('FRM_DS_CONSTRUCTION_AND_WORK_GROUP').code; // 협력업체 건설사 + 공사용역
    },
    frmGb1() {
      return this.userInfo.frmGb == $getConstants('FRM_GB_1').code; // 건설사
    },
    frmGb2() {
      return this.userInfo.frmGb == $getConstants('FRM_GB_2').code; // 공사용역
    },
    frmGb3() {
      return this.userInfo.frmGb == $getConstants('FRM_GB_3').code; // 물품자재
    },
    frmGb4() {
      return this.userInfo.frmGb == $getConstants('FRM_GB_4').code; // 건설사 + 공사용역
    },
    frmGb0() {
      return this.userInfo.frmGb == $getConstants('FRM_GB_0').code; // 운영사
    },
    isExistVndrPgmCd() {
      return this.detailInfo.vndrPgmCd != '';
    },
    isNew() {
      return this.detailInfo.isNew;
    },
    isNewCompanyData() {
      return this.detailInfo.isNewCompanyData;
    },
    isCheckDuplicateBsnNo() {
      return this.detailInfo.isCheckDuplicateBsnNo;
    },
    isDirectInputEmail() {
      return String(this.detailInfo.email2).indexOf('0000') == -1;
    },
  },
  watch: {
    detailInfo: {
      handler(obj) {
        if (obj.hasOwnProperty('bsnNo')) {
          if (obj.bsnNo) {
            this.bsnNo1 = String(obj.bsnNo).substring(0, 3);
            this.bsnNo2 = String(obj.bsnNo).substring(3, 5);
            this.bsnNo3 = String(obj.bsnNo).substring(5);
          } else {
            this.bsnNo1 = '';
            this.bsnNo2 = '';
            this.bsnNo3 = '';
          }
          if (obj.regiNo) {
            this.regiNo1 = String(obj.regiNo).substring(0, 6);
            this.regiNo2 = String(obj.regiNo).substring(6);
          } else {
            this.regiNo1 = '';
            this.regiNo2 = '';
          }
        }
      },
      deep: true,
    },
    externalData: {
      handler(val) {
        if (!this._activated) {
          return;
        }
        if (Object.keys(val).length == 0) return;

        this.setDetailInfo({
          postNo: val.zipNo,
          adr1: `${val.roadAddrPart1} ${val.roadAddrPart2}`,
          adr2: val.addrDetail,
        });
        this.$store.dispatch('setExternalData', {});
      },
      deep: true,
    },
  },
};
</script>

<style></style>
