import CODE from '@/constants/codeConstants.js';
export default that => {
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      HeaderCheck: 1,
      CanEdit: true,
    },
    Cols: [
      {
        Header: '자재코드',
        Name: 'matCode',
        Type: 'Text',
        Align: 'center',
        Width: 140,
        CanEdit: false,
      },
      {
        Header: '명칭',
        Name: 'matName',
        Type: 'Text',
        Align: 'left',
        RelWidth: 1,
        MinWidth: 140,
        CanEdit: false,
      },
      {
        Header: '규격',
        Name: 'matSize',
        Type: 'Text',
        Align: 'left',
        RelWidth: 1,
        MinWidth: 200,
        CanEdit: false,
      },
      {
        Header: '단위',
        Name: 'matUnitName',
        Type: 'Text',
        Align: 'center',
        Width: 80,
        CanEdit: false,
      },
      {
        Header: '재고수량',
        Name: 'remQty',
        Width: 100,
        Extend: that.$ibsheetHelper.preset.quantity,
        CanEdit: false,
      },
      {
        Header: '평균단가',
        Name: 'avrgPrice',
        Width: 120,
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEdit: false,
        Visible: !(that.matGiveYn === CODE.Y),
      },
      {Name: 'remAmt', Visible: false},
      {Name: 'matUnit', Visible: false},
      {Name: 'inputQty', Visible: false},
      {Name: 'inputAmt', Visible: false},
      {Name: 'outputQty', Visible: false},
      {Name: 'outputAmt', Visible: false},
    ],
  };
};
