export default that => {
  return {
    Def: {
      Row: {
        VAlign: 'TOP',
      },
    },
    Cols: [
      {
        Header: ['공종명', '공종명'],
        Name: 'mgName',
        Type: 'Text',
        Width: 200,
        CanEdit: 0,
        ColMerge: true,
      },
      {
        Header: ['도급내역', {Value: '내역명', Color: '#dff9fb'}],
        Name: 'itemNameDg',
        Type: 'Text',
        Width: 200,
        CanEdit: 0,
        ColMerge: true,
      },
      {
        Header: ['도급내역', {Value: '규격', Color: '#dff9fb'}],
        Name: 'ssizeDg',
        Type: 'Text',
        Width: 120,
        CanEdit: 0,
        Align: 'Center',
        ColMerge: true,
      },
      {
        Header: ['도급내역', {Value: '단위', Color: '#dff9fb'}],
        Name: 'unitDg',
        Type: 'Text',
        Width: 100,
        CanEdit: 0,
        Align: 'Center',
        Format: $getConstants('UNIT').code,
        ColMerge: true,
      },
      {
        Header: ['도급내역', {Value: '수량', Color: '#dff9fb'}],
        Name: 'qtyDg',
        Extend: that.$ibsheetHelper.preset.quantity,
        CanEdit: 0,
        ColMerge: true,
      },
      {
        Header: ['도급내역', {Value: '금액', Color: '#dff9fb'}],
        Name: 'amtDg',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
        ColMerge: true,
      },
      {
        Header: ['도급기성', {Value: '수량', Color: '#dff9fb'}],
        Name: 'actQty',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
        ColMerge: true,
      },
      {
        Header: ['도급기성', {Value: '금액', Color: '#dff9fb'}],
        Name: 'actAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
        ColMerge: true,
      },
      {
        Header: ['실행내역', {Value: '내역명', Color: '#dff9fb'}],
        Name: 'itemNameSi',
        Type: 'Text',
        Width: 200,
        CanEdit: 0,
      },
      {
        Header: ['실행내역', {Value: '규격', Color: '#dff9fb'}],
        Name: 'ssizeSi',
        Type: 'Text',
        Width: 120,
        CanEdit: 0,
        Align: 'Center',
      },
      {
        Header: ['실행내역', {Value: '단위', Color: '#dff9fb'}],
        Name: 'unitSi',
        Type: 'Text',
        Width: 100,
        CanEdit: 0,
        Align: 'Center',
        Format: $getConstants('UNIT').code,
      },
      {
        Header: ['실행내역', {Value: '수량', Color: '#dff9fb'}],
        Name: 'qtySi',
        Extend: that.$ibsheetHelper.preset.quantity,
        CanEdit: 0,
      },
      {
        Header: ['실행내역', {Value: '금액', Color: '#dff9fb'}],
        Name: 'amtSi',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: ['실행기성', {Value: '수량', Color: '#dff9fb'}],
        Name: 'planExecQty',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: ['실행기성', {Value: '금액', Color: '#dff9fb'}],
        Name: 'planExecAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: ['원가내역', {Value: '합계', Color: '#dff9fb'}],
        Name: 'totalForceAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: ['원가내역', {Value: '재료비', Color: '#dff9fb'}],
        Name: 'forceMAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: ['원가내역', {Value: '노무비', Color: '#dff9fb'}],
        Name: 'forceLAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: ['원가내역', {Value: '장비비', Color: '#dff9fb'}],
        Name: 'forceEAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: ['원가내역', {Value: '경비', Color: '#dff9fb'}],
        Name: 'forceOAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: ['원가내역', {Value: '외주비', Color: '#dff9fb'}],
        Name: 'curAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {Name: 'headCode', Visible: 0},
      {Name: 'midCode', Visible: 0},
    ],
  };
};
