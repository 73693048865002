export default async that => {
  return {
    Cfg: {
      SearchMode: 0,
      MainCol: 'mnuNm',
      CanEdit: true,
    },
    Cols: [
      {
        Header: '프로그램명',
        Name: 'mnuNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 250,
        CanEdit: false,
      },
      {
        Header: '조회',
        Name: 'vwYn',
        Type: 'Enum',
        EnumKeys: await $getCodeIBSheetEnum('KEY', $getConstants('USE_YN').code),
        Enum: await $getCodeIBSheetEnum('VALUE', $getConstants('USE_YN').code),
        Width: 180,
        CanEditFormula: () => that.grpAuthInfo.seq !== 1,
      },
      {
        Header: '저장',
        Name: 'svYn',
        Type: 'Enum',
        EnumKeys: await $getCodeIBSheetEnum('KEY', $getConstants('USE_YN').code),
        Enum: await $getCodeIBSheetEnum('VALUE', $getConstants('USE_YN').code),
        Width: 180,
        CanEditFormula: fr => that.grpAuthInfo.seq !== 1 && fr.Row.vwYn == $getConstants('USE_YN_Y').code,
      },
      {
        Header: '출력',
        Name: 'prnYn',
        Type: 'Enum',
        EnumKeys: await $getCodeIBSheetEnum('KEY', $getConstants('USE_YN').code),
        Enum: await $getCodeIBSheetEnum('VALUE', $getConstants('USE_YN').code),
        Width: 180,
        CanEditFormula: fr => that.grpAuthInfo.seq !== 1 && fr.Row.vwYn == $getConstants('USE_YN_Y').code,
      },
      {Name: 'mnuCd', Visible: false},
    ],
  };
};
