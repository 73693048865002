<template>
  <pmis-content-box>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onFocus,
        onSearchFinish,
      }"
    />
    <iui-modal :name="'breakdownCostCopyPop' + _uid" :btns="modalBtns" sizeType="size3">
      <BreakDownCostCopyPop />
    </iui-modal>

    <iui-modal :name="'resourceCopyPop' + _uid" :btns="modalBtns" sizeType="size3" @beforeClose="onSave">
      <ResourceCopyPop :itemCode="String(detailInfo.itemCode)" @costType="setCostType" />
    </iui-modal>

    <iui-modal :name="'untpcApplcPop' + _uid" :btns="modalBtns" sizeType="size1">
      <UntpcApplcPop />
    </iui-modal>

    <iui-modal :name="'arithmeticPop' + _uid" :btns="modalBtns" sizeType="size1">
      <ArithmeticPop :param="isFormula ? detailInfoDtls : {}" :costTypeItems="costTypeItems" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import UntpcApplcPop from '@/view/estmtManage/resrceManage/popup/UntpcApplcPop.vue';
import ArithmeticPop from '@/view/estmtManage/resrceManage/popup/ArithmeticPop.vue';
import ResourceCopyPop from '@/view/estmtManage/resrceManage/popup/ResourceCopyPop.vue';
import BreakDownCostCopyPop from '@/view/estmtManage/resrceManage/popup/BreakDownCostCopyPop.vue';
import options from './sheetOptions/DetailListDtlsSheet.js';
import {
  copySystemSctmD,
  saveSystemSctmD,
  selectSystemSctmD,
  updateSystemSctmByUcstApply,
} from '../api/BreakdownCost.js';

export default {
  components: {
    UntpcApplcPop,
    ResourceCopyPop,
    ArithmeticPop,
    BreakDownCostCopyPop,
  },

  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this), 2),
      loadSearchData: [],
      modalBtns: [{name: '확인', callback: this.onPopConfirm}],
      costType: '',
      costTypeItems: [
        {text: '재료비', value: $getConstants('COST_TYPE_M').code},
        {text: '노무비', value: $getConstants('COST_TYPE_L').code},
        {text: '장비비', value: $getConstants('COST_TYPE_E').code},
        {text: '경비', value: $getConstants('COST_TYPE_A').code},
      ],
      targetItemCode1List: [],
      focusKey: undefined,
    };
  },

  beforeCreate() {
    $mapGetters(this, ['detailInfo', 'breakdownCostCl', 'isFormula', 'detailInfoDtls']);
    $mapMutations(this, ['setDetailInfo', 'setFormula', 'setDetailInfoDtls']);
  },

  created() {
    this.addEvent([
      {name: 'selectSystemSctmD', func: this.onSearch},
      {name: 'onPop', func: this.onPop},
      {name: 'removeAll', func: this.removeAll},
      {name: 'getSaveJsonAsBreakDownDtl', func: this.getSaveJson},
      {name: 'getRowsByCheckedAsBreakDownDtl', func: this.getRowsByChecked},
    ]);
  },

  methods: {
    async onSearch() {
      const param = {
        itemCode1: this.detailInfo.itemCode,
      };
      const response = await selectSystemSctmD(param);
      this.loadSearchData = response.data;
    },
    async onSave() {
      const breakdownCostDtlsList = $_statusToCud(this.sheet.getSaveJson().data);
      if (!breakdownCostDtlsList.length) {
        return;
      }
      try {
        const param = {
          breakdownCostDtlsList,
        };
        const response = await saveSystemSctmD(param);
        if (response.data) {
          this.focusKey = response.data;
        }
      } catch (error) {
        this.$alert({title: '일위대가내역', message: error.message});
      }
      this.onSearch();
    },
    setCostType(costType) {
      this.costType = costType;
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    removeAll() {
      this.sheet.removeAll();
    },
    onFocus(e) {
      if (e.row.Kind == 'Data') {
        this.setDetailInfoDtls(e.row);
        this.setFormula(!!e.row.formula);
      }
    },
    onSearchFinish() {
      if (this.focusKey) {
        const row = this.sheet.getDataRows().find(row => row.itemCode2 === this.focusKey);
        this.sheet.focus(row);
        this.focusKey = undefined;
      }
    },
    onPop(modalNm) {
      this.modalNm = modalNm + this._uid;
      const title =
        this.modalNm.indexOf('untpcApplcPop') != -1
          ? '단가적용'
          : this.modalNm.indexOf('arithmeticPop') != -1
          ? '산식작성'
          : this.modalNm.indexOf('resourceCopyPop') != -1
          ? '자원복사'
          : this.modalNm.indexOf('breakdownCostCopyPop') != -1
          ? '대가내역복사'
          : '';
      if (!this.detailInfo.itemCode) {
        this.$alert({title: title, message: '일위대가를 선택해주세요.'});
        return;
      }
      if (this.modalNm.indexOf('untpcApplcPop') != -1) {
        this.targetItemCode1List = [];
        let isValid = true;
        this.callEvent({
          name: 'getRowsByCheckedFromBreakDown',
          param: rows => {
            if (!rows.length) {
              isValid = false;
            } else {
              rows.forEach(row => this.targetItemCode1List.push(row.itemCode));
            }
          },
        });
        if (!isValid) {
          this.$alert({title: title, message: '단가 적용할 일위대가를 체크해주세요.'});
          return;
        }
      }

      this.$modal.show(this.modalNm, {
        title: title,
      });
    },
    onPopConfirm() {
      if (this.modalNm.indexOf('resourceCopyPop') != -1) {
        this.setResourceCopy();
      } else if (this.modalNm.indexOf('breakdownCostCopyPop') != -1) {
        this.setBreakdownCostCopy();
      } else if (this.modalNm.indexOf('untpcApplcPop') != -1) {
        this.setUntpcApplc();
      } else if (this.modalNm.indexOf('arithmeticPop') != -1) {
        this.setArithmetic();
      }
    },
    setUntpcApplc() {
      this.callEvent({
        name: 'UntpcApplcPop_popConfirm',
        param: async data => {
          let param = {
            matUcstCode: data.matUcstCode,
            labUcstCode: data.labUcstCode,
            equtUcstCode: data.equtUcstCode,
            oprUcstCode: data.oprUcstCode,
            targetItemCode1List: this.targetItemCode1List,
          };
          const response = await updateSystemSctmByUcstApply(param);
          if (response.data) {
            this.onSearch();
          }
        },
      });
    },
    setArithmetic() {
      this.callEvent({
        name: 'ArithmeticPop_popConfirm',
        param: data => {
          const amtObj = {}; // 자원구분별 금액
          let formula; // 산식
          const typeMap = {
            [$getConstants('COST_TYPE_M').code]: 1,
            [$getConstants('COST_TYPE_L').code]: 2,
            [$getConstants('COST_TYPE_E').code]: 3,
            [$getConstants('COST_TYPE_A').code]: 4,
            [$getConstants('COST_TYPE_T').code]: 5,
          };
          this.sheet
            .getDataRows()
            .filter(row => row.costType !== 'I')
            .some(row => {
              if (!row.itemCode2 || !!row.formula) {
                return false;
              }

              let formatArray = [];
              let format;
              formula = data.formula.replace(/ /g, '');
              do {
                const pattern = `[${$getConstants('COST_TYPE_M').code}|${$getConstants('COST_TYPE_L').code}|${
                  $getConstants('COST_TYPE_E').code
                }|${$getConstants('COST_TYPE_A').code}|${$getConstants('COST_TYPE_T').code}]`;
                format = RegExp(`${pattern}"([^"])*"|${pattern}`).exec(formula);
                if (format) {
                  formatArray.push(format[0]);
                  formula = formula.replace(formatArray[formatArray.length - 1], `{${typeMap[format[0].slice(0, 1)]}}`);
                }
              } while (format);
              formatArray.forEach(e1 => {
                if (e1.indexOf('"') != -1) {
                  let costType = e1.slice(0, 1);
                  let costFormat = e1.slice(1).replace(/"/g, '');
                  if (row.costType == costType) {
                    costFormat.split(',').forEach(e2 => {
                      if (e2.indexOf('-') != -1) {
                        if (
                          Number(row.itemSeq) >= Number(e2.split('-')[0]) &&
                          Number(row.itemSeq) <= Number(e2.split('-')[1])
                        ) {
                          if (costType == $getConstants('COST_TYPE_M').code) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0);
                          } else if (costType == $getConstants('COST_TYPE_L').code) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.labAmt || 0);
                          } else if (
                            costType == $getConstants('COST_TYPE_E').code ||
                            costType == $getConstants('COST_TYPE_A').code
                          ) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.equipAmt || 0);
                          } else {
                            amtObj[e1] =
                              (amtObj[e1] || 0) + (row.matAmt || 0) + (row.labAmt || 0) + (row.equipAmt || 0);
                          }
                        }
                      } else {
                        if (row.itemSeq == e2) {
                          if (costType == $getConstants('COST_TYPE_M').code) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0);
                          } else if (costType == $getConstants('COST_TYPE_L').code) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.labAmt || 0);
                          } else if (
                            costType == $getConstants('COST_TYPE_E').code ||
                            costType == $getConstants('COST_TYPE_A').code
                          ) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.equipAmt || 0);
                          } else {
                            amtObj[e1] =
                              (amtObj[e1] || 0) + (row.matAmt || 0) + (row.labAmt || 0) + (row.equipAmt || 0);
                          }
                        }
                      }
                    });
                  }
                } else {
                  let costType = e1;
                  if (row.costType == e1 || e1 == $getConstants('COST_TYPE_T').code) {
                    if (costType == $getConstants('COST_TYPE_M').code) {
                      amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0);
                    } else if (costType == $getConstants('COST_TYPE_L').code) {
                      amtObj[e1] = (amtObj[e1] || 0) + (row.labAmt || 0);
                    } else if (
                      costType == $getConstants('COST_TYPE_E').code ||
                      costType == $getConstants('COST_TYPE_A').code
                    ) {
                      amtObj[e1] = (amtObj[e1] || 0) + (row.equipAmt || 0);
                    } else {
                      amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0) + (row.labAmt || 0) + (row.equipAmt || 0);
                    }
                  }
                }
              });
            });
          let addRow;
          if (this.isFormula) {
            addRow = this.sheet.getFocusedRow();
          } else {
            addRow = this.sheet.addRow();
          }

          this.sheet.setValue(addRow, 'pgmCd', this.pgmCd, 1);
          this.sheet.setValue(addRow, 'itemCode1', this.detailInfo.itemCode, 1);
          this.sheet.setValue(addRow, 'costType', data.costType, 1);
          this.sheet.setValue(addRow, 'itemName', data.itemName, 1);
          this.sheet.setValue(addRow, 'ssize', data.ssize, 1);
          this.sheet.setValue(addRow, 'unit', data.unit, 1);
          this.sheet.setValue(addRow, 'unitName', data.unitName, 1);
          this.sheet.setValue(addRow, 'qty', data.qty, 1);
          this.sheet.setValue(addRow, 'formula', data.formula, 1);

          let formatArray = [];
          let format;
          formula = data.formula.replace(/ /g, '');
          do {
            const pattern = `[${$getConstants('COST_TYPE_M').code}|${$getConstants('COST_TYPE_L').code}|${
              $getConstants('COST_TYPE_E').code
            }|${$getConstants('COST_TYPE_A').code}|${$getConstants('COST_TYPE_T').code}]`;
            format = RegExp(`${pattern}"([^"])*"|${pattern}`).exec(formula);
            if (format) {
              formatArray.push(format[0]);
              formula = formula.replace(formatArray[formatArray.length - 1], amtObj[format[0]]);
            }
          } while (format);

          let price;
          if (!formula) {
            price = 0;
          } else {
            price = Number($_strToCalculator(formula).toFixed(3));
          }

          if (data.costType == $getConstants('COST_TYPE_M').code) {
            this.sheet.setValue(addRow, 'matPrice', price, 1);
            this.sheet.setValue(addRow, 'matAmt', data.qty * price, 1);
          } else if (data.costType == $getConstants('COST_TYPE_L').code) {
            this.sheet.setValue(addRow, 'labPrice', price, 1);
            this.sheet.setValue(addRow, 'labAmt', data.qty * price, 1);
          } else if (
            data.costType == $getConstants('COST_TYPE_E').code ||
            data.costType == $getConstants('COST_TYPE_A').code
          ) {
            this.sheet.setValue(addRow, 'equipPrice', price, 1);
            this.sheet.setValue(addRow, 'equipAmt', data.qty * price, 1);
          }

          if (this.isFormula) {
            this.setDetailInfoDtls(addRow);
          }
          this.focusKey = addRow.itemCode2;
        },
      });
    },
    setResourceCopy() {
      this.callEvent({
        name: `ResourceListPop_popConfirm_${this.costType}`,
        param: data => {
          data.forEach(item => {
            let isDup = false;
            this.sheet.getDataRows().some(row => {
              if (row.itemCode2 === item.itemCode2) {
                isDup = true;
                return true;
              }
            });
            if (isDup) {
              return;
            }
            let addRow = this.sheet.addRow();
            this.sheet.setValue(addRow, 'pgmCd', this.pgmCd, 1);
            this.sheet.setValue(addRow, 'itemCode1', this.detailInfo.itemCode, 1);
            this.sheet.setValue(addRow, 'costType', item.costType, 1);
            this.sheet.setValue(addRow, 'itemCode2', item.itemCode2, 1);
            this.sheet.setValue(addRow, 'itemName', item.itemName, 1);
            this.sheet.setValue(addRow, 'ssize', item.ssize, 1);
            this.sheet.setValue(addRow, 'unit', item.unit, 1);
            this.sheet.setValue(addRow, 'unitName', item.unitName, 1);
            this.sheet.setValue(addRow, 'qty', 0, 1);
            this.sheet.setValue(addRow, 'matPrice', item.matAmt, 1);
            this.sheet.setValue(addRow, 'labPrice', item.labAmt, 1);
            this.sheet.setValue(addRow, 'equipPrice', item.equipOprAmt, 1);
            this.sheet.setValue(addRow, 'price', item.amt, 1);
          });
        },
      });
    },
    setBreakdownCostCopy() {
      this.callEvent({
        name: 'BreakDownCostCopyPop_popConfirm',
        param: async data => {
          if (!data.targetCode) {
            return;
          }
          const param = {
            itemCode1: data.copyCode,
            targetCode: data.targetCode,
          };
          const response = await copySystemSctmD(param);
          if (response.data) {
            this.onSearch();
          }
        },
      });
    },

    getSaveJson(callback) {
      if (callback) {
        callback($_statusToCud(this.sheet.getSaveJson().data));
      }
    },

    getRowsByChecked(callback) {
      if (callback) {
        callback(this.sheet.getRowsByChecked('chk'));
      }
    },

    onFormula() {
      this.sheet.getDataRows().some(row => {
        if (row.formula) {
          let amtObj = {};
          let formula;
          let typeMap = {M: 1, L: 2, E: 3};
          this.sheet.getDataRows().some(row2 => {
            if (!row2.itemCode2 || !!row2.formula) {
              return false;
            }
            let formatArray = [];
            let format;
            formula = row.formula.replace(/ /g, '');
            do {
              const pattern = `[${$getConstants('COST_TYPE_M').code}|${$getConstants('COST_TYPE_L').code}|${
                $getConstants('COST_TYPE_E').code
              }|${$getConstants('COST_TYPE_A').code}|${$getConstants('COST_TYPE_T').code}]`;
              format = RegExp(`${pattern}"([^"])*"|${pattern}`).exec(formula);
              if (format) {
                formatArray.push(format[0]);
                formula = formula.replace(formatArray[formatArray.length - 1], `{${typeMap[format[0].slice(0, 1)]}}`);
              }
            } while (format);
            formatArray.forEach(e1 => {
              if (e1.indexOf('"') != -1) {
                let costType = e1.slice(0, 1);
                let costFormat = e1.slice(1).replace(/"/g, '');
                if (row2.costType == costType) {
                  costFormat.split(',').forEach(e2 => {
                    if (e2.indexOf('-') != -1) {
                      if (
                        Number(row2.itemSeq) >= Number(e2.split('-')[0]) &&
                        Number(row2.itemSeq) <= Number(e2.split('-')[1])
                      ) {
                        if (costType == $getConstants('COST_TYPE_M').code) {
                          amtObj[e1] = (amtObj[e1] || 0) + (row2.matAmt || 0);
                        } else if (costType == $getConstants('COST_TYPE_L').code) {
                          amtObj[e1] = (amtObj[e1] || 0) + (row2.labAmt || 0);
                        } else if (
                          costType == $getConstants('COST_TYPE_E').code ||
                          costType == $getConstants('COST_TYPE_A').code
                        ) {
                          amtObj[e1] = (amtObj[e1] || 0) + (row2.amtPrice || 0);
                        } else {
                          amtObj[e1] =
                            (amtObj[e1] || 0) + (row2.matAmt || 0) + (row2.labAmt || 0) + (row2.amtPrice || 0);
                        }
                      }
                    } else {
                      if (row2.itemSeq == e2) {
                        if (costType == $getConstants('COST_TYPE_M').code) {
                          amtObj[e1] = (amtObj[e1] || 0) + (row2.matAmt || 0);
                        } else if (costType == $getConstants('COST_TYPE_L').code) {
                          amtObj[e1] = (amtObj[e1] || 0) + (row2.labAmt || 0);
                        } else if (
                          costType == $getConstants('COST_TYPE_E').code ||
                          costType == $getConstants('COST_TYPE_A').code
                        ) {
                          amtObj[e1] = (amtObj[e1] || 0) + (row2.amtPrice || 0);
                        } else {
                          amtObj[e1] =
                            (amtObj[e1] || 0) + (row2.matAmt || 0) + (row2.labAmt || 0) + (row2.amtPrice || 0);
                        }
                      }
                    }
                  });
                }
              } else {
                let costType = e1;
                if (row2.costType == e1 || e1 == $getConstants('COST_TYPE_T').code) {
                  if (costType == $getConstants('COST_TYPE_M').code) {
                    amtObj[e1] = (amtObj[e1] || 0) + (row2.matAmt || 0);
                  } else if (costType == $getConstants('COST_TYPE_L').code) {
                    amtObj[e1] = (amtObj[e1] || 0) + (row2.labAmt || 0);
                  } else if (
                    costType == $getConstants('COST_TYPE_E').code ||
                    costType == $getConstants('COST_TYPE_A').code
                  ) {
                    amtObj[e1] = (amtObj[e1] || 0) + (row2.amtPrice || 0);
                  } else {
                    amtObj[e1] = (amtObj[e1] || 0) + (row2.matAmt || 0) + (row2.labAmt || 0) + (row2.amtPrice || 0);
                  }
                }
              }
            });

            formatArray = [];
            formula = row.formula.replace(/ /g, '');
            do {
              const pattern = `[${$getConstants('COST_TYPE_M').code}|${$getConstants('COST_TYPE_L').code}|${
                $getConstants('COST_TYPE_E').code
              }|${$getConstants('COST_TYPE_A').code}|${$getConstants('COST_TYPE_T').code}]`;
              format = RegExp(`${pattern}"([^"])*"|${pattern}`).exec(formula);
              if (format) {
                formatArray.push(format[0]);
                formula = formula.replace(formatArray[formatArray.length - 1], amtObj[format[0]]);
              }
            } while (format);

            let price;
            if (!formula) {
              price = 0;
            } else {
              price = Number($_strToCalculator(formula).toFixed(3));
            }

            if (row.costType == $getConstants('COST_TYPE_M').code) {
              this.sheet.setValue(row, 'matPrice', price, 1);
              this.sheet.setValue(row, 'matAmt', row.qty * price, 1);
            } else if (row.costType == $getConstants('COST_TYPE_L').code) {
              this.sheet.setValue(row, 'labPrice', price, 1);
              this.sheet.setValue(row, 'labAmt', row.qty * price, 1);
            } else if (
              row.costType == $getConstants('COST_TYPE_E').code ||
              row.costType == $getConstants('COST_TYPE_A').code
            ) {
              this.sheet.setValue(row, 'equipPrice', price, 1);
              this.sheet.setValue(row, 'equipAmt', row.qty * price, 1);
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped></style>
