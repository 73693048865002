<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text type="search" name="cmnUNm" label="코드명" :value.sync="searchInfo.cmnUNm" @enter="onSearch"></iui-text>
      <iui-button value="검색" class="btn" @click="onSearch" />
    </template>
    <iui-container-new type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
export default {
  created() {
    this.addEvent([
      {name: 'upperCode_search', func: this.onSearch},
      {name: 'upperCode_add', func: this.onAdd},
      {name: 'upperCode_save', func: this.onSave},
      {name: 'upperCode_delete', func: this.onDelete},
    ]);
  },
  mounted() {
    this.initSheet();
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      searchInfo: {
        cmnUNm: '',
      },
    };
  },
  methods: {
    async initSheet() {
      const OPT = {
        Cfg: {
          ReqStatusName: 'status',
        },
        Cols: [
          {
            Header: {Value: '선택', TextColor: '#444', HeaderCheck: 1},
            Name: 'cbx',
            Type: 'Bool',
            BoolIcon: 0,
            Align: 'center',
            Width: 80,
          },
          {
            Header: '코드',
            Name: 'cmnUCd',
            Type: 'Text',
            ResultMask: '^[A-Za-z0-9]{0,4}$' /* 영문(대/소문자), 숫자 4자리 */,
            ResultMessage: '영문, 숫자 조합으로 4자리까지만 입력가능합니다.',
            Align: 'center',
            Width: 100,
            TextColor: '#0000FF',
            Cursor: 'pointer',
            CanEdit: 0,
            Size: 4,
          },
          {
            Header: '코드명',
            Name: 'cmnUNm',
            Type: 'Text',
            Align: 'left',
            RelWidth: 1,
          },
          {
            Header: '수정가능여부',
            Name: 'cmnMdyn',
            Type: 'Enum',
            EnumKeys: await $getCodeIBSheetEnum('KEY', $getConstants('MD_YN').code, {defaultKey: ''}),
            Enum: await $getCodeIBSheetEnum('VALUE', $getConstants('MD_YN').code, {defaultKey: '수정불가'}),
            RelWidth: 0.5,
          },
          {
            Header: '비고',
            Name: 'rmk',
            Type: 'Text',
            Align: 'left',
            RelWidth: 1,
          },
          {Name: 'isNew', Visible: false},
        ],
        Events: {
          onDataLoad: this.grid_onDataLoad,
          onClick: this.grid_onClick,
        },
      };

      this.sheet = await PMISSheet.createSheet({el: this.elId, options: OPT});
      this.$ibsheetHelper.bindDefaultDeleteEvent(this.sheet); // 삭제 이벤트
      this.onSearch();
    },
    async onSearch() {
      let response = await axios.post('/baseCmmnCodeRegist/selectUpperCodeList', {...this.searchInfo});
      this.sheet.loadSearchData(response.data);
    },
    grid_onDataLoad() {
      this.onEmitUpperCode(this.sheet.getFirstRow());
    },
    grid_onClick(e) {
      if ((e.col == 'cmnUCd' || e.col == 'cmnUNm') && !e.row.isNew) {
        this.onEmitUpperCode(e.row);
      }
    },
    onEmitUpperCode(row) {
      this.$emit('emitUpperCode', row);
    },
    onAdd() {
      let addRow = this.sheet.addRow();
      this.sheet.setValue(addRow, 'isNew', true, 1);
      this.sheet.setAttribute(addRow, 'cmnUCd', 'CanEdit', 1, 1);
      this.onEmitUpperCode(addRow);
    },
    async onSave() {
      if (!this.sheet.hasChangedData()) {
        this.$alert({title: '수정사항 없음', message: '수정된 내용이 없습니다.'});
        return;
      }
      for (let row of this.sheet.getRowsByStatus('Added,Changed')) {
        if (String(row.cmnUCd).trim() == '') {
          this.$alert({title: '필수입력', message: '상위코드는 필수입력 항목입니다.'});
          return;
        }
        if (String(row.cmnUNm).trim() == '') {
          this.$alert({title: '필수입력', message: '상위코드명은 필수입력 항목입니다.'});
          return;
        }
      }

      let param = {...this.searchInfo, cmmUpperCodeList: $_statusToCud(this.sheet.getSaveJson().data)};
      let response = await axios.post('/baseCmmnCodeRegist/selectUpperCodeDuplicationCheck', param);
      let duplicationList = Array.from(response.data);
      if (0 < duplicationList.length) {
        this.$alert({title: '상위코드 중복', message: `중복되는 상위 공통코드가 있습니다.<br/>[${duplicationList}]`});
        return;
      }

      if (await this.$confirm({title: '상위코드 저장', message: '저장하시겠습니까?'})) {
        let focusedRow = this.sheet.getFocusedRow();

        response = await axios
          .post('/baseCmmnCodeRegist/updateUpperCodeInfo', param)
          .catch(e => console.error('저장을 실패했습니다. :', e));

        if (response.status == 200) {
          if (focusedRow.isNew) this.onEmitUpperCode(focusedRow);

          for (let row of this.sheet.getRowsByStatus('Added')) {
            this.sheet.setValue(row, 'isNew', false, 1);
            this.sheet.setAttribute(row, 'cmnUCd', 'CanEdit', 0, 1);
          }
          this.sheet.acceptChangedData();
        } else {
          this.$alert({title: '저장 실패', message: '저장을 실패했습니다.'});
        }
      }
    },
    async onDelete() {
      const message = '상위코드 삭제시 하위코드도 모두 삭제됩니다.<br/>삭제하시겠습니까?';
      let checkedRow = this.sheet.getRowsByChecked('cbx');
      if (0 == checkedRow.length) {
        this.$alert({title: '선택된 항목 없음', message: '선택된 항목이 없습니다.'});
        return;
      }
      if (!(await this.$confirm({title: '상위코드 삭제', message: message}))) {
        return;
      }

      let cmmUpperCodeList = [];
      checkedRow.map(row => {
        if (row.cmnUCd) {
          cmmUpperCodeList.push({cmnUCd: row.cmnUCd});
        }
      });
      let response = await axios.post('/baseCmmnCodeRegist/deleteUppercodeInfo', {cmmUpperCodeList: cmmUpperCodeList});

      if (response.status == 200) {
        checkedRow.map(row => {
          this.sheet.removeRow(row);
        });
        this.callEvent({name: 'lowerCode_onRemoveAll'});
      } else {
        this.$alert({title: '저장 실패', message: '저장을 실패하였습니다.'});
      }
    },
  },
};
</script>

<style scoped></style>
