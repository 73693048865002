<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <label v-if="!isPopup" class="ml5 mr10">부서명</label>
      <iui-text type="search" :value.sync="searchText" @enter="onSearchGrid" />
      <iui-button value="검색" @click="onSearchGrid" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="loadSearchData"
      @loadSheet="sheet_loadSheet"
      :events="{
        onFocus: sheet_onFocus,
        onDblClick: sheet_onDblClick,
        onSearchFinish: sheet_onSearchFinish,
      }"
    />
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOption/deptRegist.js';
import {selectDeptList, deleteDeptAll, deleteDept} from '../api/deptRegist.js';
export default {
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],

      searchText: '',
      sheetUtil: null,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['deptInfo', 'searchInfo', 'focusKeyCode']);
    $mapMutations(this, ['setDeptInfo', 'setWorkerInfo']);
  },
  created() {
    this.addEvent([
      {name: 'DeptList_onSearch', func: this.onSearch},
      {name: 'DeptList_onDelete', func: this.onDelete},
      {name: 'onFocusedDeptListRowData', func: this.onFocusedRowData},
    ]);
  },
  methods: {
    sheet_loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = this.sheet.util;
      this.onSearch();
    },
    sheet_onFocus(e) {
      if (e.row.Kind == 'Data') {
        this.setDeptInfo(e.row);
        if (e.row.Level == 0) {
          this.setDeptInfo({uppFldrCd: '', uppFldrNm: '', rmk: '', cud: '2'});
        } else {
          this.setDeptInfo({uppFldrCd: e.row.parentNode.fldrCd, uppFldrNm: e.row.parentNode.fldrNm, cud: '2'});
        }
      }
    },
    sheet_onDblClick(e) {
      if (this.isPopup && e.row.Kind == 'Data') {
        this.callEvent({name: 'WorkerInfo_onConfirm'});
      }
    },
    sheet_onSearchFinish(e) {
      this.setSumCnt(e.sheet.getFirstRow());
      if (this.focusKeyCode) {
        this.sheetUtil.treeFocus('fldrNo', this.focusKeyCode);
      }
    },
    setSumCnt(row) {
      if (!row || !row.childNodes.length) return;

      let deep = (sheet, row) => {
        if (row.childNodes) {
          let subChildRow;
          let sumCnt = 0;
          for (let i = 0; i < row.childNodes.length; i++) {
            if (i == 0) subChildRow = row.firstChild;
            else subChildRow = subChildRow.nextSibling;

            let _row = {};
            if (subChildRow) {
              _row = deep(sheet, subChildRow);
              sumCnt += _row.belongEmpCnt;
            } else {
              sumCnt = subChildRow.belongEmpCnt;
            }
            sheet.setValue(row, 'belongEmpCnt', sumCnt);
          }
        }
        return row;
      };

      let childRow;
      let sumCnt = 0;

      for (let i = 0; i < row.childNodes.length; i++) {
        if (i == 0) childRow = row.firstChild;
        else childRow = childRow.nextSibling;

        if (childRow) {
          deep(this.sheet, childRow);
          sumCnt += childRow.belongEmpCnt || 0;
        } else {
          sumCnt += row.belongEmpCnt || 0;
        }
      }

      this.sheet.setValue(row, 'belongEmpCnt', sumCnt);
      this.sheet.acceptChangedData();
    },
    async onSearch() {
      const response = await selectDeptList({});

      let items = $_treeModel(response.data, 'OBS', 'fldrCd', 'uppFldrCd', 'fldrNo', 'uppFldrNo');
      let data = [
        {
          fldrCd: 'OBS',
          fldrNm: this.searchInfo.frmNm,
          fldrNo: '0',
          Items: items,
        },
      ];
      this.loadSearchData = data;
    },
    async onDelete() {
      let msg = '';
      if (this.sheet.getFocusedRow().childNodes.length) {
        msg += '삭제 시 하위부서들도 모두 삭제됩니다.<br />정말 ';
      }
      msg += '삭제하시겠습니까?';
      if (await this.$confirm({title: '부서 삭제', message: msg})) {
        let response;
        if (this.deptInfo.fldrCd == 'OBS') {
          response = await deleteDeptAll({});
        } else {
          this.setDeptInfo({cud: '3'});
          let param = this.deptInfo;
          response = await deleteDept({...param});
        }
        if (response.status == 200) {
          let deletedRow = this.sheet.getFocusedRow();
          this.sheet.focus(this.sheet.getPrevRow(deletedRow));
          this.sheet.removeRow(deletedRow);
        }
      }
    },
    onFocusedRowData(param) {
      //팝업창 확인버튼
      let row = this.sheet.getFocusedRow();
      if (typeof param == 'function') {
        param(row);
      }
    },
    onSearchGrid() {
      this.sheetUtil.treeSearch('fldrNm', this.searchText);
    },
  },
};
</script>

<style></style>
