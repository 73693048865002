<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <label>제목</label>
      <iui-text type="search" :value.sync="searchText" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="loadSearchData"
      @loadSheet="sheet_loadSheet"
      :events="{
        onClick: grid_onClick,
        onAfterExpand: onIncreaseHits,
      }"
    />
  </pmis-content-box>
</template>

<script>
import {mapGetters} from 'vuex';
import sheetOpt from './sheetOption/Faq.js';
import {selectFaqList, increaseInqCnt} from '../api/faq.js';
export default {
  props: {
    paramObj: {
      /** iui-tab 으로부터 넘겨받는 props */
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loadSearchData: [],
      sheetOpt: sheetOpt(this),

      searchText: '',
      subVue: [
        {
          name: `faqBoardView${this._uid}`,
          url: `/faqBoardView${this._uid}`,
          path: './view/board/faq/components/FaqBoardView.vue',
        },
      ],
      natDs: $getConstants('NAT_DS_FAQ').code,
    };
  },
  computed: {
    ...mapGetters('menu', {menu: 'menu'}),
    tskDs() {
      return this.paramObj.tskDs;
    },
  },
  created() {
    this.addEvent([
      {name: `FAQ_BoardList_${this.tskDs}_onSearch`, func: this.onSearch},
      {name: 'FAQ_BoardList_onAdd', func: this.onAdd},
    ]);
  },
  async mounted() {
    this.onSearch();
  },
  methods: {
    sheet_loadSheet(sheet) {
      this.sheet = sheet;
    },
    async onSearch() {
      let response = await selectFaqList({
        pgmCd: '000000',
        natDs: this.natDs,
        tskDs: this.tskDs,
        searchText: this.searchText,
      });
      this.loadSearchData = response.data;
    },
    onAdd() {
      if (this.userInfo.frmGb != $getConstants('FRM_GB_0').code) return;
      this.onGoVue({
        pgmCd: this.pgmCd,
        empNo: this.userInfo.empNo,
        natDs: this.natDs,
        tskDs: this.tskDs,
        boardStatus: 'I',
      });
    },
    grid_onClick(e) {
      if (e.row.Kind == 'Data') {
        if (e.col == 'tl') {
          this.onGoVue({
            pgmCd: e.row.pgmCd,
            empNo: e.row.rgprId,
            natNo: e.row.natNo,
            natDs: e.row.natDs,
            boardStatus: 'E',
          });
          this.onIncreaseHits(e);
        }
      }
    },
    onGoVue(param) {
      let routeName = this.$route.name;
      this.goVue(`/faqBoardView${this._uid}`, {...param, routeName});
    },
    async onIncreaseHits(e) {
      if (this.userInfo.frmGb != $getConstants('FRM_GB_0').code) {
        let param = {pgmCd: e.row.pgmCd, natNo: e.row.natNo};
        const response = await increaseInqCnt(param);
        this.sheet.setValue(e.row, 'inqCnt', response.data);
        this.sheet.acceptChangedData();
      }
    },
  },
};
</script>

<style></style>
