/** 계약목록 */
export const selectSubPoListBySpotDeptcd = searchOptions =>
  axios.post('/subPo/selectSubPoListBySpotDeptcd', searchOptions);
/** 계약차수목록 */
export const selectSubPoSeqList = poNo => axios.post('/subPo/selectSubPoSeqList', {poNo});
/** 계약정보 */
export const selectSubPoInfos = (poNo, contSeq) => axios.post('/subPo/selectSubPoInfos', {poNo, contSeq});

/** 입찰완료 리스트 */
export const selectCompletedSubRfqList = searchOptions => axios.post('/subPo/selectCompletedSubRfqList', searchOptions);
export const selectSubPoByRfqInfo = rfqNo => axios.post('/subPo/selectSubPoByRfqInfo', {rfqNo});

/** 인지세 정보 조회 */
export const selectStampTaxInfos = (poNo, contSeq) => axios.post('/subPo/selectStampTaxInfos', {poNo, contSeq});
/** 오프라인 인지세 고유번호 체크 */
export const selectCheckStampNo = stampNo => axios.post('/subPo/selectCheckStampNo', {stampNo});
/** 오프라인 인지세 등록 */
export const insertOfflineStampInfo = (param, afterValidation) =>
  $save('/subPo/insertOfflineStampInfo', param, {afterValidation});
/** 오프라인 인지세 저장 */
export const updateOfflineStampInfo = (param, afterValidation) =>
  $save('/subPo/updateOfflineStampInfo', param, {afterValidation});
/** 오프라인 인지세 삭제 */
export const deleteOfflineStampInfo = deleteStampList => axios.post('/subPo/deleteOfflineStampInfo', {deleteStampList});

/** 계약정보 생성 */
export const createPo = (param, afterValidation) => $save('/subPo/createPo', param, {afterValidation});
/** 계약정보 생성 */
export const savePo = (param, afterValidation) => $save('/subPo/savePo', param, {afterValidation});
/** 계약정보(차수) 삭제 */
export const deletePo = (poNo, contSeq) => axios.post('/subPo/deletePo', {poNo, contSeq});
/** 파일삭제 후 업무 파일번호 초기화 */
export const updateSubPoFlNo = (poNo, contSeq, flNo) => axios.post('/subPo/updateSubPoFlNo', {poNo, contSeq, flNo});

/** 다음 프로세스 처리하기 전 계약상태 체크 */
export const selectCheckPoState = param => axios.post('/subPo/selectCheckPoState', param);

/** 계약서 수정중 상태로 변경 */
export const mdfyContract = (poNo, contSeq) => axios.post('/subPo/mdfyContract', {poNo, contSeq});
/** 계약서 송부 */
export const sendPo = (poNo, contSeq) => axios.post('/subPo/send', {poNo, contSeq});
/** 계약서 송부취소 */
export const sendCancel = (poNo, contSeq) => axios.post('/subPo/sendCancel', {poNo, contSeq});
/** 계약서반송(건설사) */
export const rejectBuyer = (poNo, contSeq, reason) => $save('/subPo/rejectBuyer', {poNo, contSeq, reason});
/** 계약서반송(협력사) */
export const rejectSupplier = (poNo, contSeq, reason) => $save('/subPo/rejectSupplier', {poNo, contSeq, reason});
/** 계약서 반송정보 */
export const selectSubPoRejectInfo = (poNo, contSeq) => axios.post('/subPo/selectSubPoRejectInfo', {poNo, contSeq});

/** 구비서류제출 */
export const submitNeedFile = (poNo, contSeq, submitFileList) =>
  axios.post('/subPo/submitNeedFile', {poNo, contSeq, submitFileList});
/** 서류심사결과 등록 */
export const updateSuFileApprovalResult = param => axios.post('/subPo/updateSuFileApprovalResult', param);

/** 전자서명(협력사) */
export const sign = param => axios.post('/subPo/sign', param);
/** 전자서명(건설사) */
export const addSign = param => axios.post('/subPo/addSign', param);
/** 리포트용 계약서 정보 조회 */
export const selectContractContentsForPrint = (poNo, contSeq) =>
  axios.post('/subPo/selectContractContentsForPrint', {poNo, contSeq});
/** 계약품의 정보 조회 */
export const generateApprovalContent = (poNo, contSeq) => axios.post('/subPo/generateApprovalContent', {poNo, contSeq});
/** 차수복사 */
export const copyPo = (poNo, contSeq) => axios.post('/subPo/copy', {poNo, contSeq});
/** 계약완료목록 조회 (팝업용) */
export const selectSubPoCompleteList = param => axios.post('/subPo/selectSubPoCompleteList', param);
/** 계약완료내역목록 조회 (팝업용) */
export const selectSubPoItemCompleteList = param => axios.post('/subPo/selectSubPoItemCompleteList', param);
/** 인지세납부관리대장 */
export const selectStampTaxRegistList = param => axios.post('/subPo/selectStampTaxRegistList', param);
export const saveInsrFile = param => $save('/subPo/saveInsrFile', param);
