<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px"><PrjList :isAll="true"/></i-col>
        <i-spacer />
        <i-col>
          <pmis-content-box title-type="tab" border-box :loading="loading">
            <template #title>총괄현황</template>
            <template #header-left>
              <iui-datepicker
                type="month"
                label="작업년월"
                :value="reqYm"
                @update:value="reqYm_update"
                required
                error-message="필수입력"
              />
              <i class="prev-arrow" @click.prevent.self @click="prev" />
              <i class="post-arrow" @click.prevent.self @click="next" />
            </template>
            <template #header-right>
              <iui-amount-unit :amount-unit="amountUnit" @change="onAmountChange" />
            </template>

            <iui-container-new type="css-flex" flex-direction="row">
              <i-col minWidth="1000px">
                <i-row height="160px" v-show="prjCd">
                  <iui-container-new type="css-flex">
                    <i-row>
                      <i-col>
                        <pmis-content-box>
                          <iui-container-new type="table" theme="bullet">
                            <colgroup>
                              <col width="80px" />
                              <col />
                            </colgroup>
                            <i-row>
                              <i-col-header>현장명</i-col-header>
                              <i-col>
                                <iui-text type="code" :value="prjCd" readonly />
                                <iui-text :value="prjInfo.prjNm" readonly />
                              </i-col>
                            </i-row>
                            <i-row>
                              <i-col-header>발주처명</i-col-header>
                              <i-col>
                                <iui-text :value="prjInfo.orderNm" readonly />
                              </i-col>
                            </i-row>
                            <i-row v-if="prjInfo.workKind == $getConstants('WORK_KIND_SCPAY').code">
                              <i-col-header>원도급사명</i-col-header>
                              <i-col>
                                <iui-text :value="prjInfo.contcompNm" readonly />
                              </i-col>
                            </i-row>
                            <i-row>
                              <i-col-header>계약기간</i-col-header>
                              <i-col>
                                <iui-text :value="prjInfo.ctrtStrdate" width="90px" readonly />
                                <label>~</label>
                                <iui-text :value="prjInfo.ctrtEnddate" width="90px" readonly />
                              </i-col>
                            </i-row>
                            <i-row>
                              <i-col-header>잔여일수</i-col-header>
                              <i-col>
                                <iui-text
                                  type="number"
                                  suffix="일"
                                  width="83px"
                                  suffix-width="20px"
                                  :value="prjInfo.remainder"
                                  readonly
                                />
                              </i-col>
                            </i-row>
                          </iui-container-new>
                        </pmis-content-box>
                      </i-col>
                      <i-spacer />
                      <i-col>
                        <pmis-content-box>
                          <template #title>
                            공정현황
                          </template>
                          <ib-sheet :uid="_uid" :options="Options1" :loadSearchData="loadSearchData1" />
                        </pmis-content-box>
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </i-row>
                <i-row height="200px">
                  <iui-container-new type="css-flex" height="auto">
                    <i-row>
                      <i-col>
                        <pmis-content-box>
                          <template #title>
                            매출 및 수금현황
                          </template>
                          <ib-sheet :uid="_uid" :options="Options2" :loadSearchData="loadSearchData2" />
                        </pmis-content-box>
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </i-row>
                <i-spacer />
                <i-row height="250px">
                  <pmis-content-box>
                    <template #title>
                      공사손익현황
                    </template>
                    <ib-sheet :uid="_uid" :options="Options3" :loadSearchData="loadSearchData3" />
                  </pmis-content-box>
                </i-row>
                <i-spacer />
                <i-row height="220px">
                  <pmis-content-box>
                    <template #title>
                      원가투입현황
                    </template>
                    <ib-sheet :uid="_uid" :options="Options4" :loadSearchData="loadSearchData4" />
                  </pmis-content-box>
                </i-row>
              </i-col>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
/**
 * 현장별총괄손익현황
 * components :
 *   PrjList 현장목록
 *
 * */
import PrjList from '@/view/Project/components/PrjList.vue';
import options1 from './sheetOptions/SummarySheet1.js';
import options2 from './sheetOptions/SummarySheet2.js';
import options3 from './sheetOptions/SummarySheet3.js';
import options4 from './sheetOptions/SummarySheet4.js';
import {selectMonCloseList} from '@/view/closing/expitmCloseRegist/api/expitmCloseRegist.js';
import {selectSummary} from '../api/incomestatus.js';
import IuiAmountUnit from '@/components/common/IuiAmountUnit.vue';

export default {
  components: {PrjList, IuiAmountUnit},
  data() {
    return {
      Options1: options1(this),
      Options2: options2(this),
      Options3: options3(this),
      Options4: options4(this),
      loadSearchData1: [],
      loadSearchData2: [],
      loadSearchData3: [],
      loadSearchData4: [],
      reqYm: '',
      prjInfo: {
        prjNm: '',
        conPeriod: '',
        orderNm: '',
        workKind: '',
        contcompNm: '',
        ctrtStrdate: '',
        ctrtEnddate: '',
        remainder: '',
      },
      summary: {},
      loading: false,
      searchIndex: -1,
      searchDatas: [],
      responseData: {
        sheet: [],
        processStateList: [],
        salesAndCollectStateList: [],
        contProfitStateList: [],
        forceAmtStateList: [],
      },
      amountUnit: '',
    };
  },
  computed: {
    ctrtStrdate_dateFormat() {
      return dateFormat(this.summary.ctrtStrdate);
    },
    ctrtEnddate_dateFormat() {
      return dateFormat(this.summary.ctrtEnddate);
    },
  },
  created() {
    this.amountUnit = this.userInfo?.amountUnit
      ? this.userInfo.amountUnit
      : this.$store.state.code2.codeAlias.AMOUNT_UNIT_ONE_THOUSAND_WON.code;
    this.addFuncPrj(this.onInitSearch);
  },
  methods: {
    _numberFormat(value) {
      let n = Number(value);
      if (!Number.isNaN(n)) {
        return $_numberFormat(n);
      } else {
        return value;
      }
    },
    ceil(value, minimumFractionDigits) {
      let n = Number(value);

      if (!Number.isNaN(n)) {
        minimumFractionDigits = Number(minimumFractionDigits) || 0;

        let x = Math.pow(10, minimumFractionDigits);
        n = Math.trunc(n * x) / x;
      }

      return n;
    },
    setPrjInfo(e) {
      for (let key in this.prjInfo) {
        if (e && e.hasOwnProperty(key)) {
          this.prjInfo[key] = String(e[key]);
        }
      }
      if (this.prjInfo['conPeriod']) {
        const conPeriods = this.prjInfo['conPeriod'].split('~');
        this.prjInfo['ctrtStrdate'] = conPeriods[0];
        this.prjInfo['ctrtEnddate'] = conPeriods[1];
        if (this.prjInfo['ctrtStrdate'].replace(/-/g, '') <= $_getCurrentDate()) {
          this.prjInfo['remainder'] = $_calGapCount($_getCurrentDate(), this.prjInfo['ctrtEnddate'], 'd');
        } else {
          this.prjInfo['remainder'] = e['conPeriodCnt'];
        }
      }
    },
    prev() {
      if (this.searchIndex < 1) {
        this.$alert({title: '작업년월', message: '이전 작업년월이 없습니다.'});
        return;
      }
      this.searchIndex--;
      this.setSearchData();
    },
    next() {
      if (this.searchIndex >= this.searchDatas.length - 1) {
        this.$alert({title: '작업년월', message: '다음 작업년월이 없습니다.'});
        return;
      }
      this.searchIndex++;
      this.setSearchData();
    },
    setSearchData() {
      const searchData = this.searchDatas[this.searchIndex];
      this.reqYm = searchData?.closeMm || '';
      this.onSearch();
    },
    async onInitSearch() {
      this.setPrjInfo(this.getPrjInfo());
      const response = await selectMonCloseList({});
      this.searchDatas = response.data;
      this.searchIndex = this.searchDatas.length - 1;
      this.setSearchData();
    },
    async onSearch() {
      if (!this.reqYm) {
        this.reqYm = String($_getCalDate($_getYearMonthFirstDay(), -1)).substring(0, 6);
      }

      this.loading = true;
      try {
        const param = {
          reqYm: this.reqYm,
        };
        const summaryResponse = await selectSummary(param);
        this.summary = summaryResponse.data;
        this.responseData.processStateList = this.summary.processStateList;
        this.responseData.salesAndCollectStateList = this.summary.salesAndCollectStateList;
        this.responseData.contProfitStateList = this.summary.contProfitStateList;
        this.responseData.forceAmtStateList = this.summary.forceAmtStateList;

        this.convertPriceUnit();
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    reqYm_update(e) {
      this.reqYm = e;
      if (e) this.onSearch();
    },
    onAmountChange(value) {
      this.amountUnit = value;
      this.convertPriceUnit();
    },
    convertPriceUnit() {
      this.loadSearchData1 = $_priceUnitFormat(this.amountUnit, this.responseData.processStateList);
      this.loadSearchData2 = $_priceUnitFormat(this.amountUnit, this.responseData.salesAndCollectStateList);
      this.loadSearchData3 = $_priceUnitFormat(this.amountUnit, this.responseData.contProfitStateList);
      this.loadSearchData4 = $_priceUnitFormat(this.amountUnit, this.responseData.forceAmtStateList);
    },
  },
};
</script>
