<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-select :p-code="$getConstants('FRM_DS').code" defaultCd="A" :value="frmDs" @change="onChange" />
      <iui-text type="search" label="거래처명" ref="searchNm" :value.sync="searchNm" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onRenderFirstFinish,
        onClick,
        onDblClick,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOptions/CustomerPopupSheet.js';
import {selectCustomerPopup} from '../api/warehousing.js';
export default {
  data() {
    return {
      frmDs: $getConstants('FRM_DS_MAT').code,
      searchNm: '',

      Options: options(this),
      loadSearchData: [],

      custCode: '',
      custName: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'monPjtCloseAt']);
    $mapMutations(this, ['setSearchInfo', 'setMomPjtCloseAt']);
  },
  created() {
    this.addEvent([{name: 'unOrder', func: this.unOrder}]);
  },

  methods: {
    onSearch() {
      let param = {
        pgmCd: this.pgmCd,
        prjCd: this.prjCd,
        frmDs: this.frmDs,
        searchNm: this.searchNm,
      };
      selectCustomerPopup(param).then(response => {
        this.loadSearchData = response.data;
      });
    },
    onChange(value) {
      this.frmDs = value;
      this.onSearch();
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.custCode = e.row.bsnNo;
        this.custName = e.row.frmNm;
      }
    },
    onDblClick(e) {
      if (e.row.Kind == 'Data') {
        this.callEvent({name: 'customerPopup'});
      }
    },
    unOrder(callback) {
      if (typeof callback == 'function') {
        let param = {
          poNo: '',
          contSeq: '',
          custCode: this.custCode,
          custName: this.custName,
        };
        callback(param);
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style></style>
