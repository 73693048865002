/** 보유장비목록과 표준장비목록 기준 */
<template
  ><pmis-content-box>
    <template v-slot:header-left>
      <iui-text
        type="search"
        :value="searchInfo.searchNm"
        @change="setValue('searchNm', $event)"
        @enter="selectEqutCodeList"
      />
      <iui-button value="검색" @click="selectEqutCodeList" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onDblClick}"
    />
  </pmis-content-box>
</template>

<script>
import {selectEqutCodeListPop} from '../api/operationJournal.js';
import options from './sheetOptions/OperationEquipmentListPopupSheet.js';
export default {
  props: {
    uid: {
      type: Number,
    },
    isExtend: {
      type: Boolean,
      default: false,
    },
    itemCode: {
      type: [String, Number],
    },
  },
  beforeCreate() {},
  created() {
    this.addEvent([{name: 'OperationEquipmentListPopup_onCallback', func: this.onCallback}]);
    this.selectEqutCodeList();
  },
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this)),
      loadSearchData: [],
      searchInfo: {
        searchSe: '',
        searchNm: '',
      },
    };
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    async selectEqutCodeList() {
      this.response2 = [];

      let param = {
        searchSe: this.searchInfo.searchSe,
        searchNm: this.searchInfo.searchNm,
        itemCode: this.itemCode,
      };

      const response = await selectEqutCodeListPop(param);
      this.loadSearchData = response.data;
    },
    onCallback(callback) {
      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        callback(this.sheet.getFocusedRow());
      } else {
        callback(checkedRows);
      }
    },
    onDblClick(e) {
      if (e.row.Kind == 'Data') {
        this.$modalConfirm();
      }
    },
    setValue(t, e) {
      this.searchInfo[t] = e.target.value;
    },
  },
};
</script>

<style scoped></style>
