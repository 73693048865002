const state = () => ({
  detailInfo: {
    pgmCd: '',
    bsnNo: '',
    frmNm: '',
    frmEnNm: '', //거래처명(영문)
    frmGb: '',
    regiNo: '',
    rpsvNm: '',
    rpsvEnNm: '', //대표자명(영문)
    rpsvPhone: '',
    icprNm: '',
    icprPhone: '',
    email1: '',
    email2: '',
    icprFaxno: '',
    postNo: '',
    adr1: '',
    adr2: '',
    homepage: '', // 홈페이지
    bcoNm: '',
    itemNm: '',
    frmDs: '',
    bankCode: '',
    bankName: '',
    depositNo: '',
    depositor: '',
    nationCd: '',
    nationNm: '',
    useCloseDs: '',
    vndrPgmCd: '', // 거래처회사코드
    flNo: 0,
    rgprId: '',

    isNew: false,
    isNewCompanyData: false,
    isCheckDuplicateBsnNo: false,
  },

  //회사정보
  companyInfo: {
    pgmCd: '', // 회사코드
    frmNm: '', // 회사명
    frmEnNm: '', // 회사명(영문)
    bsnNo: '', // 사업자번호
    frmGb: '', // 회사구분
    regiNo: '', // 주민/법인번호
    rpsvNm: '', // 대표자명
    rpsvEnNm: '', // 대표자명(영문)
    rpsvPhone: '', // 대표전화번호
    postNo: '', // 우편번호
    adr1: '', // 주소
    adr2: '', // 상세주소
    homepage: '', // 홈페이지
    bcoNm: '', // 업태
    itemNm: '', // 종목
    bankCode: '', // 은행코드
    depositNo: '', // 계좌번호
    depositor: '', // 예금주
    lgFlNo: 0, // 회사로고파일번호
    djFlNo: 0, // 관인파일번호
    smFlNo: 0, // 회사심볼파일번호
    flNo: 0, // 파일번호
    slogan: '', // 회사슬로건
    femployInsurDs: '', // 외국인고용보험산출여부
    decimalDs: '', // 일노무비소숫점관리방법
    taxDs: '', // 소득세산출방식
    useFlag: '', // 회사정보승인Flag
    useStrdate: '', // 시작(계약)일자
    useEnddate: '', // 종료일자
  },

  areaList: [],
  matList: [],
  gcodeList: [],
  licenseList: [],
  docnmList: [],
  focusKeyCode: '',
  searchFrmDetailItemsWcode: [],
  searchFrmDetailItemsMatCode: [],
});

const getters = {
  detailInfo: state => state.detailInfo,
  companyInfo: state => state.companyInfo,

  areaList: state => state.areaList,
  matList: state => state.matList,
  gcodeList: state => state.gcodeList,
  licenseList: state => state.licenseList,
  docnmList: state => state.docnmList,
  focusKeyCode: state => state.focusKeyCode,
  searchFrmDetailItemsWcode: state => state.searchFrmDetailItemsWcode,
  searchFrmDetailItemsMatCode: state => state.searchFrmDetailItemsMatCode,
};

const mutations = {
  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = payload[key];
      }
    }
  },
  initDetailInfo(state) {
    for (let key in state.detailInfo) {
      state.detailInfo[key] = '';
    }
    state.detailInfo.flNo = 0;
    state.detailInfo.rgprId = JSON.parse(sessionStorage.getItem('userInfo')).empNo;
    state.detailInfo.isNew = false;
  },

  initCompanyInfo(state) {
    for (let key in state.companyInfo) {
      if (['lgFlNo', 'djFlNo', 'smFlNo', 'flNo'].indexOf(key) == -1) state.companyInfo[key] = '';
    }
    for (let key in state.worker) {
      state.worker[key] = '';
    }
    state.areaList = [];
    state.matList = [];
    state.gcodeList = [];
    state.licenseList = [];
    state.docnmList = [];
  },

  setCompanyInfo(state, payload) {
    for (let key in state.companyInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.companyInfo[key] = payload[key];
      }
    }
  },

  setAreaList(state, payload) {
    state.areaList = payload;
  },
  setMatList(state, payload) {
    state.matList = payload;
  },
  setGcodeList(state, payload) {
    state.gcodeList = payload;
  },
  setLicenseList(state, payload) {
    state.licenseList = payload;
  },
  setDocnmList(state, payload) {
    state.docnmList = payload;
  },
  setFocusKeyCode(state, payload) {
    state.focusKeyCode = payload;
  },

  initFocusKeyCode(state) {
    state.focusKeyCode = '';
  },
  setSearchFrmDetailItemsWcode(state, payload) {
    state.searchFrmDetailItemsWcode = payload;
  },
  setSearchFrmDetailItemsMatCode(state, payload) {
    state.searchFrmDetailItemsMatCode = payload;
  },
};

const actions = {
  async initSearchFrmDetailItems(context, payload) {
    let searchFrmDetailItemsWcode = [];
    let searchFrmDetailItemsMatCode = [];

    const codeList = await $getCode($getConstants('W_CODE').code);
    codeList.map(item => {
      searchFrmDetailItemsWcode.push({value: item.code, text: item.codeNm});
    });

    const response = await axios.post('/baseMatCodeRegist/selectLmCodeList');
    response.data.map(item => {
      searchFrmDetailItemsMatCode.push({value: item.lmCode, text: item.lmName});
    });

    context.commit('setSearchFrmDetailItemsWcode', searchFrmDetailItemsWcode);
    context.commit('setSearchFrmDetailItemsMatCode', searchFrmDetailItemsMatCode);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
