<template>
  <!-- 공지사항- 포틀릿 -->
  <div style="height:100%">
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt} * 3`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr height="30px">
          <th>제목</th>
          <th>등록자</th>
          <th class="p_last">등록일시</th>
        </tr>
      </thead>
    </table>
    <div class="inner" style="height:calc(100% - 40px);">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt} * 3`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="(notice, index) in tableData" :key="index">
            <td style="text-align: left;">
              <a @click="onClickTitle(notice)">{{ notice.tl }}</a>
            </td>
            <td>{{ notice.rgprNm }}</td>
            <td class="p_last">{{ notice.rgsDttm }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
import {selectNoticeList} from '@/components/portlet/api/portletData.js';
export default {
  mixins: [PortletMixin],
  data() {
    return {
      cnt: 5,
      tableData: [],
    };
  },
  methods: {
    setWidth() {
      this.tblWidth = this.$parent.$el.offsetWidth - 28;
    },
    async onSearch() {
      const response = await selectNoticeList();

      response.data.forEach(item => {
        let prefix = '';
        switch (item.natDs) {
          case $getConstants('NAT_DS_NOTICE').code:
            if (item.pgmCd === this.pgmCd) {
              prefix = '[공지] ';
            } else {
              prefix = `[일반] [${item.frmNm}] `;
            }
            break;
          case $getConstants('NAT_DS_RFQ').code:
            prefix = '[입찰] ';
            break;
        }
        item.tl = prefix + item.tl;
        item.rgsDttm = item.rgsDttm ? item.rgsDttm.replace(/-/g, '-') : '';
      });
      this.tableData = response.data;
    },
    onClickTitle(data) {
      this.goMenu('NOTICE', {pgmCd: data.pgmCd, empNo: data.rgprId, natNo: data.natNo, natDs: data.natDs});
    },
  },
};
</script>

<style></style>
