<template>
  <pmis-content-box>
    <iui-container-new type="table" theme="bullet" header-width="110px">
      <i-row>
        <i-col-header>현장</i-col-header>
        <i-col>
          <iui-text :value="rfqInfo.spotDeptcd" :enable="false" width="100px" />
          <iui-text :value="rfqInfo.spotDeptnm" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>입찰공고번호</i-col-header>
        <i-col>
          <iui-text :value="rfqInfo.rfqNo2" :enable="false" width="100px" />
          <iui-text :value="rfqInfo.ttl" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>유찰사유</i-col-header>
        <i-col rowspan="3">
          <iui-text ref="nonbidRsltRmrk" type="multi" :value.sync="nonbidRsltRmrk" />
        </i-col>
      </i-row>
      <i-row>
        <i-col>&nbsp;</i-col>
      </i-row>
      <i-row>
        <i-col>&nbsp;</i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import {fail} from '@/view/bidding/rfq/mat/api/rfq.js';
import {fail as subFail} from '@/view/bidding/rfq/sub/api/subRfq.js';

const failApi = {mat: fail, sub: subFail};

export default {
  props: {
    bidType: {type: String, required: true},
  },
  data() {
    return {nonbidRsltRmrk: ''};
  },
  beforeCreate() {
    $mapGetters(this, ['rfqInfo']);
  },
  created() {
    this.addEvent({name: 'Fail_Save', func: this.onSave});
  },
  mounted() {
    this.$refs.nonbidRsltRmrk.$refs.textarea.focus();
  },
  methods: {
    async onSave(callback) {
      if (!this.nonbidRsltRmrk.trim()) {
        await this.$alert({title: '유찰', message: '유찰사유를 입력해야합니다.'});
        this.$refs.nonbidRsltRmrk.$refs.textarea.focus();
        return;
      }

      if (!(await this.$confirm({title: '유찰', message: '유찰하시겠습니까?'}))) {
        return;
      }

      failApi[this.bidType](this.rfqInfo.rfqNo, this.nonbidRsltRmrk)
        .then(response => {
          if (response.data === 'RFQ_FAIL_COMPLETE') {
            callback(this.rfqInfo.rfqNo);
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
  },
};
</script>

<style></style>
