<template>
  <pmis-content-box>
    <template #title>자재내역</template>
    <template #title-left>
      <label>(</label>
      <iui-text type="amount" prefix="공급가액" width="200px" height="20px" :value="rfqInfo.amt" :enable="false" />
      <iui-text type="amount" prefix="부가세액" width="200px" height="20px" :value="rfqInfo.vatAmt" :enable="false" />
      <iui-text type="amount" prefix="합계금액" width="200px" height="20px" :value="rfqInfo.totAmt" :enable="false" />
      <label>)</label>
    </template>
    <template #title-right>
      <iui-button v-if="!isModal" value="크게보기" @click="openExpandedModal" />
      <iui-button v-if="enable && isSimpleExec && rowCount" value="단가적용" @click="onPopUntpcApplc" />
      <iui-button v-if="enable" :value="isSimpleExec ? '자재선택' : '자재실행예산'" @click="openTargetMatModal" />
      <iui-button v-if="enable" value="삭제" @click="onDeleteRfqItem" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="rfqItemList"
      @loadSheet="sheet = $event"
      :events="{
        onSearchFinish: sheet_onSearchFinish,
        onClick: sheet_onClick,
        onBeforeChange: sheet_onBeforeChange,
        onAfterChange: sheet_onAfterChange,
      }"
    />

    <iui-modal :name="`standardCodePopup_${_uid}`" title="자재선택" :btns="standardCodePopupBtns" size-type="size6">
      <StandardCodePopup type="mat" :base="false" />
    </iui-modal>

    <iui-modal :name="`matBudgetModal_${_uid}`" title="자재예산선택" :btns="matBudgetModalBtns" size-type="size3">
      <MatBudgetPopup :propsPrjCd="rfqInfo.spotDeptcd" />
    </iui-modal>

    <iui-modal :name="`matCodeInfoModal_${_uid}`" title="자재코드" size-type="size4">
      <MatCodeInfo :propsMatCode="propsMatCode" />
    </iui-modal>

    <iui-modal :name="`UntpcApplcPop_${_uid}`" title="단가적용" :btns="untpcApplcBtns" sizeType="size1">
      <UntpcApplcPop isMat />
    </iui-modal>

    <iui-modal name="expandedModal" title="자재내역" size-type="size6">
      <RfqItemList isModal />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/rfqItemList.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import {selectMatUntpc} from '@/view/sysManage/stdCodeManage/api/untpcRegist.js';

import StandardCodePopup from '@/view/sysManage/stdCodeManage/components/StandardCodePopup.vue';
import MatBudgetPopup from '@/view/bidding/prManage/components/popup/MatBudgetPopup.vue';
import MatCodeInfo from '@/view/sysManage/stdCodeManage/matCodeRegist/popup/MatCodeInfo.vue';
import UntpcApplcPop from '@/view/estmtManage/estmtDtlsManage/popup/UntpcApplcPop.vue';

export default {
  name: 'RfqItemList', // 재귀 컴포넌트에서는 name 필수
  components: {StandardCodePopup, MatBudgetPopup, MatCodeInfo, UntpcApplcPop},
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sheetOpt: $addColCheckbox(sheetOpt(this)),
      standardCodePopupBtns: [{name: '확인', callback: this.onConfirm_standardCodePopup}],
      matBudgetModalBtns: [{name: '확인', callback: this.onConfirm_matBudgetModal}],
      untpcApplcBtns: [{name: '확인', callback: this.onPopConfirmUntpcApplcPop}],

      propsMatCode: '',
      rowCount: 0,
    };
  },
  computed: {
    enable() {
      return this.rfqIsNew || (this.rfqStatus.isExistOnlyRfq && this.rfqStatus.isRfqReady);
    },
  },
  beforeCreate() {
    $mapGetters(this, ['rfqInfo', 'rfqStatus', 'rfqItemList', 'rfqIsNew']);
    $mapMutations(this, ['setRfqInfo', 'setRfqItemList']);
  },
  created() {
    if (!this.isModal) {
      this.addEvent([
        {name: 'RfqItemList_OpenTargetMatModal', func: this.openTargetMatModal},
        {name: 'RfqItemList_CalculateAmt', func: this.calculateAmt},
        {name: 'RfqItemList_GetSaveJson', func: this.getSaveJson},
      ]);
    }
  },
  beforeDestroy() {
    if (!this.isModal) {
      return;
    }
    const rfqItemList = this.sheet.getSaveJson(0).data.map(row => ({
      itemCd: row.itemCd,
      itemNm: row.itemNm,
      sizeNm: row.sizeNm,
      unit: row.unit,
      qty: row.qty,
      planUnitPrc: row.planUnitPrc,
      amt: row.amt,
      itemRmrk: row.itemRmrk,
      prNo: row.prNo,
      prSeq: row.prSeq,
      planQty: row.planQty,
    }));
    this.setRfqItemList(rfqItemList);
  },
  methods: {
    async openTargetMatModal() {
      if (!this.rfqInfo.spotDeptcd) {
        await this.$alert({title: '현장', message: '현장을 선택해주세요.'});
        this.callEvent({name: 'RfqInfo_openPrjSearchbox'});
        return;
      }

      if (this.isSimpleExec) {
        this.$modal.show(`standardCodePopup_${this._uid}`);
      } else {
        this.$modal.show(`matBudgetModal_${this._uid}`);
      }
    },
    onConfirm_standardCodePopup() {
      this.callEvent({
        name: 'StandardCodePopup_confirm',
        param: data => {
          const addItemList = data.map(item => ({
            itemCd: item.matCode,
            itemNm: item.itemName,
            sizeNm: item.size,
            unit: item.unit,
            qty: 0,
          }));
          this.loadAppendItemList(addItemList);
          this.$modal.hide(`standardCodePopup_${this._uid}`);
        },
      });
    },
    onConfirm_matBudgetModal() {
      this.callEvent({
        name: 'MatBudgetPopup_GetCheckedRows',
        param: data => {
          if (!data.length) {
            this.$alert({title: '자재예산선택', message: '자재예산내역을 선택해주세요.'});
            return;
          }

          const addItemList = data.map(item => ({
            itemCd: item.itemCode,
            itemNm: item.itemName,
            sizeNm: item.size,
            unit: item.unit,
            planQty: item.qty,
            qty: 0,
            planUnitPrc: item.price,
          }));
          this.loadAppendItemList(addItemList);
          this.$modal.hide(`matBudgetModal_${this._uid}`);
        },
      });
    },
    loadAppendItemList(appendList = []) {
      if (!appendList.length) {
        return;
      }
      const curRfqItemCds = this.sheet.getSaveJson(0).data.map(item => item.itemCd);
      appendList
        .filter(item => !curRfqItemCds.includes(String(item.itemCd)))
        .forEach(item => {
          const addRow = this.sheet.addRow();
          Object.keys(item).forEach(col => {
            this.sheet.setValue(addRow, col, item[col], true);
          });
        });
      if (this.isModal) {
        this.setEnableSheet();
      }
    },
    onPopUntpcApplc() {
      if (!this.sheet.getTotalRowCount()) {
        this.$alert({title: '자재내역', message: '자재내역을 등록해주세요.'});
        return;
      }
      this.$modal.show(`UntpcApplcPop_${this._uid}`);
    },
    onPopConfirmUntpcApplcPop() {
      this.callEvent({
        name: 'UntpcApplcPop_popConfirm',
        param: async data => {
          const {applyOpt, matUcstCode} = data;
          const getDataRows = this.sheet.getDataRows();
          let matList = [];
          if (applyOpt === '1') {
            matList = getDataRows.map(item => item.itemCd);
          } else {
            matList = getDataRows.filter(item => !item.planUnitPrc).map(item => item.itemCd);
          }
          const response = await selectMatUntpc({matUcstCode, matList});
          matList.forEach(matCd => {
            const row = getDataRows.find(row => row.itemCd === matCd);
            this.sheet.setValue(
              row,
              'planUnitPrc',
              response.data.find(item => item.itemCode == row.itemCd)?.price ?? 0,
              1
            );
          });
          this.calculateAmt();
          this.$modal.hide(`UntpcApplcPop_${this._uid}`);
        },
      });
    },
    calculateAmt() {
      const itemList = this.sheet.getDataRows();
      const amt = itemList.reduce((acc, curr) => acc + curr['amt'], 0);

      const {taxTypecd, taxDirectDs, taxRate, taxTypeDirectPercent, taxTypeDirectAmt} = this.rfqInfo;
      this.setRfqInfo(
        COMMON_FUNCTION.onCalculateTaxAndTotAmt({
          amt,
          taxTypecd,
          taxDirectDs,
          taxRate,
          taxTypeDirectPercent,
          taxTypeDirectAmt,
        })
      );
    },
    onDeleteRfqItem() {
      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (!checkedRows.length) {
        this.$alert({title: '자재내역 삭제', message: '선택된 항목이 없습니다.'});
        return;
      }

      this.sheet.removeRows(checkedRows);
      this.rowCount = this.sheet.getTotalRowCount();
      this.calculateAmt();
    },
    setEnableSheet() {
      const canFocus = this.rfqIsNew || (this.rfqStatus.isExistOnlyRfq && this.rfqStatus.isRfqReady);
      const canEdit = this.rfqIsNew || (this.rfqStatus.isExistOnlyRfq && this.rfqStatus.isRfqReady);
      const color =
        this.rfqIsNew || (this.rfqStatus.isExistOnlyRfq && this.rfqStatus.isRfqReady) ? '#E0E0F8' : undefined;
      const showOrHide = this.rfqIsNew || (this.rfqStatus.isExistOnlyRfq && this.rfqStatus.isRfqReady);

      this.sheet.Def.Row.CanFocus = canFocus;
      this.sheet.setAttribute(null, 'chk', 'Visible', showOrHide, 1);
      this.sheet.setAttribute(null, 'qty', 'CanEdit', canEdit, 1);
      this.sheet.setAttribute(null, 'itemRmrk', 'CanEdit', canEdit, 1);

      this.sheet.setAttribute(null, 'qty', 'Color', color, 1);
      this.sheet.setAttribute(null, 'itemRmrk', 'Color', color, 1);

      if (this.isSimpleExec) {
        this.sheet.setAttribute(null, 'planUnitPrc', 'CanEdit', canEdit, 1);
        this.sheet.setAttribute(null, 'planUnitPrc', 'Color', color, 1);
      }

      if (this.isSimpleExec) {
        this.sheet.setAttribute(null, 'planUnitPrc', 'CanEdit', canEdit, 1);
        this.sheet.setAttribute(null, 'planUnitPrc', 'Color', color, 1);
      }
    },
    getSaveJson(callback) {
      if (typeof callback === 'function') {
        const saveJson = this.sheet.getSaveJson(0).data.sort((a, b) => a.itemCd - b.itemCd);
        callback(saveJson);
      }
    },

    sheet_onSearchFinish(e) {
      this.rowCount = e.sheet.getTotalRowCount();
      this.setEnableSheet();
      this.calculateAmt();
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data' && e.col === 'itemNm') {
        this.propsMatCode = e.row.itemCd.toString();
        this.$modal.show(`matCodeInfoModal_${this._uid}`);
      }
    },
    sheet_onBeforeChange(e) {
      if (e.col === 'qty') {
        if (Number.isNaN(Number(e.val))) {
          return e.oldval;
        }

        const decimal = e.val.split('.')[1];
        if (5 < decimal?.length) {
          this.$alert({title: '금회요청수량', message: '소수점 이하 5자리까지 입력가능합니다.'});
          return e.oldval;
        }

        if (0 > e.val) {
          this.$alert({title: '금회요청수량', message: '금회요청수량은 0 이상이어야 합니다.'});
          return e.oldval;
        }
        return e.val;
      }
    },
    sheet_onAfterChange(e) {
      if (e.col === 'qty') {
        this.calculateAmt();
      }
    },
    async openExpandedModal() {
      if (!this.rfqInfo.spotDeptcd) {
        await this.$alert({title: '현장', message: '현장을 선택해주세요.'});
        this.callEvent({name: 'RfqInfo_openPrjSearchbox'});
        return;
      }
      this.setRfqItemList(this.sheet.getSaveJson(0).data);
      this.$modal.show('expandedModal');
    },
  },
};
</script>

<style></style>
