<template>
  <pmis-content-box>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onSearchFinish, onFocus, onBeforeChange}"
    />
    <div class="flex_w" style="height: 0px;">
      <iui-modal :name="'breakdownCostCopyDtlsPop' + _uid" :btns="modalBtns" sizeType="size3">
        <BreakDownCostCopyPop />
      </iui-modal>

      <iui-modal :name="'resourceCopyDtlsPop' + _uid" :btns="modalBtns" sizeType="size3">
        <ResourceCopyPop :itemCode="String(detailInfo.itemCode)" @costType="setCostType" />
      </iui-modal>

      <iui-modal :name="'untpcApplcDtlsPop' + _uid" :btns="modalBtns" sizeType="size1">
        <UntpcApplcPop />
      </iui-modal>

      <iui-modal :name="'arithmeticDtlsPop' + _uid" :btns="modalBtns" sizeType="size1">
        <ArithmeticPop
          :param="isFormula ? {...detailInfoDtls, ...{itemCode: detailInfoDtls.itemCode2}} : {}"
          :costTypeItems="costTypeItems"
        />
      </iui-modal>
    </div>
  </pmis-content-box>
</template>

<script>
import UntpcApplcPop from '@/view/estmtManage/resrceManage/popup/UntpcApplcPop.vue';
import ArithmeticPop from '@/view/estmtManage/resrceManage/popup/ArithmeticPop.vue';
import ResourceCopyPop from '@/view/estmtManage/resrceManage/popup/ResourceCopyPop.vue';
import BreakDownCostCopyPop from '@/view/estmtManage/resrceManage/popup/BreakDownCostCopyPop.vue';
import options from './sheetOption/DetailListDtlsSheet.js';
import {
  copyContSctmD,
  deleteContSctmD,
  saveContSctmD,
  selectPrjBreakdownCostListDtls,
  updateContSctmByUcstApply,
} from '../api/prjBreakdownCost.js';

export default {
  components: {
    UntpcApplcPop,
    ResourceCopyPop,
    ArithmeticPop,
    BreakDownCostCopyPop,
  },

  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this), 2, 'chk', {NoChanged: 1}),
      loadSearchData: [],
      modalBtns: [{name: '확인', callback: this.onPopConfirm}],
      costType: '',
      costTypeItems: [
        {text: '재료비', value: $getConstants('COST_TYPE_M').code},
        {text: '노무비', value: $getConstants('COST_TYPE_L').code},
        {text: '장비비', value: $getConstants('COST_TYPE_E').code},
        {text: '경비', value: $getConstants('COST_TYPE_A').code},
      ],
      targetItemCode1List: [],
      focusKey: undefined,
    };
  },

  beforeCreate() {
    $mapGetters(this, ['detailInfo', 'isFormula', 'detailInfoDtls']);
    $mapMutations(this, ['setFormula', 'setDetailInfoDtls']);
  },

  created() {
    this.addEvent([
      {name: 'selectPrjBreakdownCostListDtls', func: this.onSearch},
      {name: 'onDtlsPop', func: this.onPop},
      {name: 'getSaveJsonAsBreakDownDtl', func: this.getSaveJson},
      {name: 'getRowsByCheckedAsPrjBreakDownDtl', func: this.getRowsByChecked},
      {name: 'deleteRowAsPrjBreakDownDtl', func: this.deleteRow},
      {name: 'initDtlsGrid', func: this.initGrid},
    ]);
  },

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onSearchFinish(e) {
      if (this.focusKey) {
        const row = this.sheet.getDataRows().find(row => row.itemCode2 === this.focusKey);
        this.sheet.focus(row);
        this.focusKey = undefined;
      }
    },
    onFocus(e) {
      if (e.row.Kind == 'Data') {
        this.setDetailInfoDtls(e.row);
        this.setFormula(String(e.row.itemCode2).indexOf('F0') == 0);
      }
    },
    onBeforeChange(e) {
      if (this.cud !== 1) {
        return;
      }
      this.setSumAmt();
    },
    setSumAmt() {
      const rows = this.sheet.getDataRows();
      const matAmt = rows.reduce((acc, cur) => acc + cur.matAmt, 0);
      const labAmt = rows.reduce((acc, cur) => acc + cur.labAmt, 0);
      const equipAmt = rows.reduce((acc, cur) => acc + cur.equipAmt, 0);
      this.callEvent({
        name: 'setSumAmt',
        param: {
          matAmt,
          labAmt,
          equipAmt,
        },
      });
    },
    async onSearch(isNew = false) {
      if (isNew) {
        this.sheet.removeAll();
      } else {
        const response = await selectPrjBreakdownCostListDtls({itemCode1: this.detailInfo.itemCode});
        this.loadSearchData = response.data;
      }
    },
    async onSave() {
      if (this.cud === 1) {
        return;
      }
      let prjBreakdownCostDtlsList = $_statusToCud(this.sheet.getSaveJson().data);
      const param = {prjBreakdownCostDtlsList};
      const response = await saveContSctmD(param);
      if (response.data) {
        this.focusKey = response.data;
      }
      this.onSearch();
    },
    setCostType(costType) {
      this.costType = costType;
    },
    onPop(modalNm) {
      this.modalNm = modalNm + this._uid;
      const title =
        this.modalNm.indexOf('untpcApplcDtlsPop') != -1
          ? '단가적용'
          : this.modalNm.indexOf('arithmeticDtlsPop') != -1
          ? '산식작성'
          : this.modalNm.indexOf('resourceCopyDtlsPop') != -1
          ? '자원복사'
          : this.modalNm.indexOf('breakdownCostCopyDtlsPop') != -1
          ? '대가내역복사'
          : '';
      if (!this.detailInfo.itemCode) {
        this.$alert({title: title, message: '현장일위대가를 선택해주세요.'});
        return;
      }

      if (this.modalNm.indexOf('untpcApplcDtlsPop') != -1) {
        this.targetItemCode1List = [];
        let isValid = true;
        this.callEvent({
          name: 'getRowsByCheckedFromPrjBreakDown',
          param: rows => {
            if (!rows.length) {
              isValid = false;
            } else {
              rows.forEach(row => this.targetItemCode1List.push(row.itemCode));
            }
          },
        });
        if (!isValid) {
          this.$alert({title: title, message: '단가 적용할 현장일위대가를 체크해주세요.'});
          return;
        }
      }

      this.$modal.show(this.modalNm, {
        title: title,
      });
    },
    onPopConfirm() {
      if (this.modalNm.indexOf('untpcApplcDtlsPop') != -1) {
        this.setContSctmByUcstApply();
      } else if (this.modalNm.indexOf('arithmeticDtlsPop') != -1) {
        this.setArithmetic();
      } else if (this.modalNm.indexOf('resourceCopyDtlsPop') != -1) {
        this.setResourceCopy();
      } else if (this.modalNm.indexOf('breakdownCostCopyDtlsPop') != -1) {
        this.setBreakdownCostCopy();
      }
    },
    setContSctmByUcstApply() {
      this.callEvent({
        name: 'UntpcApplcPop_popConfirm',
        param: async data => {
          let param = {
            matUcstCode: data.matUcstCode,
            labUcstCode: data.labUcstCode,
            equtUcstCode: data.equtUcstCode,
            oprUcstCode: data.oprUcstCode,
            targetItemCode1List: this.targetItemCode1List,
          };
          const response = await updateContSctmByUcstApply(param);
          if (response.data) {
            this.onSearch();
          }
        },
      });
    },
    changeArithmetic() {
      const arithmeticArray = this.sheet.getDataRows().filter(x => !x.itemCode2 || !!x.formula);
      const costArray = this.sheet.getDataRows().filter(x => !x.formula);

      arithmeticArray.forEach(x => {
        const amtObj = {};
        const typeMap = {
          [$getConstants('COST_TYPE_M').code]: 1,
          [$getConstants('COST_TYPE_L').code]: 2,
          [$getConstants('COST_TYPE_E').code]: 3,
          [$getConstants('COST_TYPE_A').code]: 4,
          [$getConstants('COST_TYPE_T').code]: 5,
        };
        let formula;
        costArray.forEach(row => {
          let formatArray = [];
          let format;
          formula = x.formula.replace(/ /g, '');
          do {
            const pattern = `[${$getConstants('COST_TYPE_M').code}|${$getConstants('COST_TYPE_L').code}|${
              $getConstants('COST_TYPE_E').code
            }|${$getConstants('COST_TYPE_A').code}|${$getConstants('COST_TYPE_T').code}]`;
            format = RegExp(`${pattern}"([^"])*"|${pattern}`).exec(formula);
            if (format) {
              formatArray.push(format[0]);
              formula = formula.replace(formatArray[formatArray.length - 1], `{${typeMap[format[0].slice(0, 1)]}}`);
            }
          } while (format);
          formatArray.forEach(e1 => {
            if (e1.indexOf('"') !== -1) {
              const costType = e1.slice(0, 1);
              const costFormat = e1.slice(1).replace(/"/g, '');
              if (row.costType === costType) {
                costFormat.split(',').forEach(e2 => {
                  if (e2.indexOf('-') !== -1) {
                    if (
                      Number(row.itemSeq) >= Number(e2.split('-')[0]) &&
                      Number(row.itemSeq) <= Number(e2.split('-')[1])
                    ) {
                      if (costType === $getConstants('COST_TYPE_M').code) {
                        amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0);
                      } else if (costType === $getConstants('COST_TYPE_L').code) {
                        amtObj[e1] = (amtObj[e1] || 0) + (row.labAmt || 0);
                      } else if (
                        costType === $getConstants('COST_TYPE_E').code ||
                        costType === $getConstants('COST_TYPE_A').code
                      ) {
                        amtObj[e1] = (amtObj[e1] || 0) + (row.equipAmt || 0);
                      } else {
                        amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0) + (row.labAmt || 0) + (row.equipAmt || 0);
                      }
                    }
                  } else {
                    if (row.itemSeq === e2) {
                      if (costType === $getConstants('COST_TYPE_M').code) {
                        amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0);
                      } else if (costType === $getConstants('COST_TYPE_L').code) {
                        amtObj[e1] = (amtObj[e1] || 0) + (row.labAmt || 0);
                      } else if (
                        costType === $getConstants('COST_TYPE_E').code ||
                        costType === $getConstants('COST_TYPE_A').code
                      ) {
                        amtObj[e1] = (amtObj[e1] || 0) + (row.equipAmt || 0);
                      } else {
                        amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0) + (row.labAmt || 0) + (row.equipAmt || 0);
                      }
                    }
                  }
                });
              }
            } else {
              let costType = e1;
              if (row.costType === e1 || e1 === $getConstants('COST_TYPE_T').code) {
                if (costType === $getConstants('COST_TYPE_M').code) {
                  amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0);
                } else if (costType === $getConstants('COST_TYPE_L').code) {
                  amtObj[e1] = (amtObj[e1] || 0) + (row.labAmt || 0);
                } else if (
                  costType === $getConstants('COST_TYPE_E').code ||
                  costType === $getConstants('COST_TYPE_A').code
                ) {
                  amtObj[e1] = (amtObj[e1] || 0) + (row.equipAmt || 0);
                } else {
                  amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0) + (row.labAmt || 0) + (row.equipAmt || 0);
                }
              }
            }
          });
        });
        let formatArray = [];
        let format;
        formula = x.formula.replace(/ /g, '');
        do {
          const pattern = `[${$getConstants('COST_TYPE_M').code}|${$getConstants('COST_TYPE_L').code}|${
            $getConstants('COST_TYPE_E').code
          }|${$getConstants('COST_TYPE_A').code}|${$getConstants('COST_TYPE_T').code}]`;
          format = RegExp(`${pattern}"([^"])*"|${pattern}`).exec(formula);
          if (format) {
            formatArray.push(format[0]);
            formula = formula.replace(formatArray[formatArray.length - 1], amtObj[format[0]]);
          }
        } while (format);

        let price;
        if (!formula) {
          price = 0;
        } else {
          price = Number($_strToCalculator(formula).toFixed(3));
        }

        const row = this.sheet.getRowById(x.id);
        if (x.costType == $getConstants('COST_TYPE_M').code) {
          this.sheet.setValue(row, 'matPrice', price, 1);
          this.sheet.setValue(row, 'matAmt', x.qty * price, 1);
        } else if (x.costType == $getConstants('COST_TYPE_L').code) {
          this.sheet.setValue(row, 'labPrice', price, 1);
          this.sheet.setValue(row, 'labAmt', x.qty * price, 1);
        } else if (x.costType == $getConstants('COST_TYPE_E').code || x.costType == $getConstants('COST_TYPE_A').code) {
          this.sheet.setValue(row, 'equipPrice', price, 1);
          this.sheet.setValue(row, 'equipAmt', x.qty * price, 1);
        }
      });
    },
    setArithmetic() {
      this.callEvent({
        name: 'ArithmeticPop_popConfirm',
        param: data => {
          const amtObj = {}; // 자원구분별 금액
          let formula; // 산식
          const typeMap = {
            [$getConstants('COST_TYPE_M').code]: 1,
            [$getConstants('COST_TYPE_L').code]: 2,
            [$getConstants('COST_TYPE_E').code]: 3,
            [$getConstants('COST_TYPE_A').code]: 4,
            [$getConstants('COST_TYPE_T').code]: 5,
          };
          this.sheet
            .getDataRows()
            .filter(row => row.costType !== 'I')
            .some(row => {
              if (!row.itemCode2 || !!row.formula) {
                return false;
              }

              let formatArray = [];
              let format;
              formula = data.formula.replace(/ /g, '');
              do {
                const pattern = `[${$getConstants('COST_TYPE_M').code}|${$getConstants('COST_TYPE_L').code}|${
                  $getConstants('COST_TYPE_E').code
                }|${$getConstants('COST_TYPE_A').code}|${$getConstants('COST_TYPE_T').code}]`;
                format = RegExp(`${pattern}"([^"])*"|${pattern}`).exec(formula);
                if (format) {
                  formatArray.push(format[0]);
                  formula = formula.replace(formatArray[formatArray.length - 1], `{${typeMap[format[0].slice(0, 1)]}}`);
                }
              } while (format);
              formatArray.forEach(e1 => {
                if (e1.indexOf('"') != -1) {
                  let costType = e1.slice(0, 1);
                  let costFormat = e1.slice(1).replace(/"/g, '');
                  if (row.costType == costType) {
                    costFormat.split(',').forEach(e2 => {
                      if (e2.indexOf('-') != -1) {
                        if (
                          Number(row.itemSeq) >= Number(e2.split('-')[0]) &&
                          Number(row.itemSeq) <= Number(e2.split('-')[1])
                        ) {
                          if (costType == $getConstants('COST_TYPE_M').code) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0);
                          } else if (costType == $getConstants('COST_TYPE_L').code) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.labAmt || 0);
                          } else if (
                            costType == $getConstants('COST_TYPE_E').code ||
                            costType == $getConstants('COST_TYPE_A').code
                          ) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.equipAmt || 0);
                          } else {
                            amtObj[e1] =
                              (amtObj[e1] || 0) + (row.matAmt || 0) + (row.labAmt || 0) + (row.equipAmt || 0);
                          }
                        }
                      } else {
                        if (row.itemSeq == e2) {
                          if (costType == $getConstants('COST_TYPE_M').code) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0);
                          } else if (costType == $getConstants('COST_TYPE_L').code) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.labAmt || 0);
                          } else if (
                            costType == $getConstants('COST_TYPE_E').code ||
                            costType == $getConstants('COST_TYPE_A').code
                          ) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.equipAmt || 0);
                          } else {
                            amtObj[e1] =
                              (amtObj[e1] || 0) + (row.matAmt || 0) + (row.labAmt || 0) + (row.equipAmt || 0);
                          }
                        }
                      }
                    });
                  }
                } else {
                  let costType = e1;
                  if (row.costType == e1 || e1 == $getConstants('COST_TYPE_T').code) {
                    if (costType == $getConstants('COST_TYPE_M').code) {
                      amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0);
                    } else if (costType == $getConstants('COST_TYPE_L').code) {
                      amtObj[e1] = (amtObj[e1] || 0) + (row.labAmt || 0);
                    } else if (
                      costType == $getConstants('COST_TYPE_E').code ||
                      costType == $getConstants('COST_TYPE_A').code
                    ) {
                      amtObj[e1] = (amtObj[e1] || 0) + (row.equipAmt || 0);
                    } else {
                      amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0) + (row.labAmt || 0) + (row.equipAmt || 0);
                    }
                  }
                }
              });
            });
          let addRow;
          if (this.isFormula) {
            addRow = this.sheet.getFocusedRow();
          } else {
            addRow = this.sheet.addRow();
          }
          if (!addRow) {
            return;
          }

          this.sheet.setValue(addRow, 'pgmCd', this.pgmCd, 1);
          this.sheet.setValue(addRow, 'itemCode1', this.detailInfo.itemCode, 1);
          this.sheet.setValue(addRow, 'costType', data.costType, 1);
          this.sheet.setValue(addRow, 'itemName', data.itemName, 1);
          this.sheet.setValue(addRow, 'ssize', data.ssize, 1);
          this.sheet.setValue(addRow, 'unit', data.unit, 1);
          this.sheet.setValue(addRow, 'unitName', data.unitName, 1);
          this.sheet.setValue(addRow, 'qty', data.qty, 1);
          this.sheet.setValue(addRow, 'formula', data.formula, 1);

          let formatArray = [];
          let format;
          formula = data.formula.replace(/ /g, '');
          do {
            const pattern = `[${$getConstants('COST_TYPE_M').code}|${$getConstants('COST_TYPE_L').code}|${
              $getConstants('COST_TYPE_E').code
            }|${$getConstants('COST_TYPE_A').code}|${$getConstants('COST_TYPE_T').code}]`;
            format = RegExp(`${pattern}"([^"])*"|${pattern}`).exec(formula);
            if (format) {
              formatArray.push(format[0]);
              formula = formula.replace(formatArray[formatArray.length - 1], amtObj[format[0]]);
            }
          } while (format);

          let price;
          if (!formula) {
            price = 0;
          } else {
            price = Number($_strToCalculator(formula).toFixed(3));
          }

          if (data.costType == $getConstants('COST_TYPE_M').code) {
            this.sheet.setValue(addRow, 'matPrice', price, 1);
            this.sheet.setValue(addRow, 'matAmt', data.qty * price, 1);
          } else if (data.costType == $getConstants('COST_TYPE_L').code) {
            this.sheet.setValue(addRow, 'labPrice', price, 1);
            this.sheet.setValue(addRow, 'labAmt', data.qty * price, 1);
          } else if (
            data.costType == $getConstants('COST_TYPE_E').code ||
            data.costType == $getConstants('COST_TYPE_A').code
          ) {
            this.sheet.setValue(addRow, 'equipPrice', price, 1);
            this.sheet.setValue(addRow, 'equipAmt', data.qty * price, 1);
          }

          if (this.isFormula) {
            this.setDetailInfoDtls(addRow);
          }
        },
      });
    },
    setResourceCopy() {
      this.callEvent({
        name: `ResourceListPop_popConfirm_${this.costType}`,
        param: data => {
          data.forEach(item => {
            let isDup = false;
            this.sheet.getDataRows().some(row => {
              if (row.itemCode2 === item.itemCode2) {
                isDup = true;
                return true;
              }
            });
            if (isDup) {
              return;
            }
            let addRow = this.sheet.addRow();
            this.sheet.setValue(addRow, 'pgmCd', this.pgmCd, 1);
            this.sheet.setValue(addRow, 'itemCode1', this.detailInfo.itemCode, 1);
            this.sheet.setValue(addRow, 'costType', item.costType, 1);
            this.sheet.setValue(addRow, 'itemCode2', item.itemCode2, 1);
            this.sheet.setValue(addRow, 'itemName', item.itemName, 1);
            this.sheet.setValue(addRow, 'ssize', item.ssize, 1);
            this.sheet.setValue(addRow, 'unit', item.unit, 1);
            this.sheet.setValue(addRow, 'qty', 0, 1);
            this.sheet.setValue(addRow, 'matPrice', item.matAmt, 1);
            this.sheet.setValue(addRow, 'labPrice', item.labAmt, 1);
            this.sheet.setValue(addRow, 'equipPrice', item.equipOprAmt, 1);
            this.sheet.setValue(addRow, 'price', item.amt, 1);
          });
        },
      });
    },
    setBreakdownCostCopy() {
      this.callEvent({
        name: 'BreakDownCostCopyPop_popConfirm',
        param: async data => {
          if (!data.targetCode) {
            return;
          }
          const param = {
            itemCode: data.copyCode,
            targetCode: data.targetCode,
          };
          const response = await copyContSctmD(param);
          if (response.data) {
            this.onSearch();
          }
        },
      });
    },

    getSaveJson(callback) {
      if (callback) {
        callback(this.sheet.getSaveJson().data);
      }
    },

    getRowsByChecked(callback) {
      if (callback) {
        callback(this.sheet.getRowsByChecked('chk'));
      }
    },

    async deleteRow() {
      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (!checkedRows.length) {
        this.$alert({title: '삭제', message: '삭제할 내역을 선택해주세요.'});
        return;
      }
      if (!(await this.$confirm({title: '삭제', message: '선택된 내역을 삭제하시겠습니까?'}))) {
        return;
      }

      if (checkedRows.filter(x => !x.Added).length > 0 && this.sheet.getDataRows().filter(x => x.Added).length > 0) {
        this.$alert({title: '삭제', message: '저장하지 않은 데이터가 있어 삭제할 수 없습니다.'});
        return;
      }

      let itemCode2List = [];
      checkedRows.forEach(row => {
        if (row.Added) {
          this.sheet.removeRow(row);
        } else {
          itemCode2List.push({
            itemSeq: row.itemSeq,
            itemCode2: row.itemCode2,
          });
        }
      });

      if (itemCode2List.length) {
        const param = {
          itemCode1: this.detailInfo.itemCode,
          itemCode2List,
        };
        const response = await deleteContSctmD(param);
        if (response.data) {
          this.onSearch();
        }
      }
    },
    initGrid() {
      this.sheet.removeAll();
    },
  },
};
</script>

<style scoped></style>
