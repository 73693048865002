<template>
  <div v-show="visible" :data-type="type" :class="rootClass" :style="rootStyle">
    <label v-if="label" :id="labelId" :for="textBoxId" class="label" :style="labelStyle">{{ label }}</label>
    <span v-if="prefix" :id="prefixId" class="prefix" :style="prefixStyle">{{ prefix }}</span>
    <input
      v-if="type !== 'multi'"
      ref="input"
      type="text"
      :value="formattedValue"
      :id="textBoxId"
      :name="name"
      :placeholder="placeholder"
      @click="input_click"
      @focus="input_focus"
      @blur="input_blur"
      @keydown="input_keydown"
      @keyup="input_keyup"
      @input="input_input"
      @keypress.enter="input_keypress_enter"
      @keypress="input_keypress"
      @change="input_change"
      :disabled="!enable"
      :readonly="readonly"
      :class="textBoxClass"
      :style="textBoxStyle"
      :required="required"
      :data-error-title="errTitle"
      :data-error-message="errMessage"
    />
    <textarea
      v-if="type === 'multi'"
      ref="textarea"
      v-model="dataValue"
      :id="textBoxId"
      :name="name"
      :placeholder="placeholder"
      @blur="input_blur"
      @change="input_change"
      @keyup="input_keyup"
      :disabled="!enable"
      :readonly="readonly"
      :class="textBoxClass"
      :style="textBoxStyle"
      :data-error-title="errTitle"
      :data-error-message="errMessage"
    ></textarea>
    <span v-if="internalSuffix" v-bind:id="suffixId" class="suffix" :style="suffixStyle">{{ internalSuffix }}</span>
  </div>
</template>

<script>
import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import IuiLabelPrefixSuffixMixin from '@/components/Iui/mixins/IuiLabelPrefixSuffixMixin';
import {IuiValidatorMixin} from '@/plugins/IuiValidator';
import rootStore from '@/store';

const ITEM_MARGIN_RIGHT = '3px';
const SMALL_CODE_TEXTBOX_WIDTH = '60px';
const CODE_TEXTBOX_WIDTH = '90px';
const LARGE_CODE_TEXTBOX_WIDTH = '120px';
const NUMBER_SUFFIX = '원';
const NUMBER_TEXTBOX_WIDTH = '120px';
const NUMBER_SUFFIX_WIDTH = '20px';
const RATE_SUFFIX = '%';
const RATE_TEXTBOX_WIDTH = '60px';
const RATE_SUFFIX_WIDTH = '20px';
const PHONE_MAX_LENGTH = 13;

export default {
  name: 'iui-text',
  mixins: [IuiBaseMixin, IuiLayoutMixin, IuiLabelPrefixSuffixMixin, IuiValidatorMixin],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number],
    },

    formObj: {
      type: String,
      default: 'none!',
    },
    event: {
      type: Array,
      default: () => [],
    },
    numberFormat: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    decimalCeil: {
      // 소수점 올림
      type: Boolean,
      default: false,
    },
    decimalFloor: {
      // 소수점 버림
      type: Boolean,
      default: true,
    },
    decimalRound: {
      // 소수점 반올림
      type: Boolean,
      default: false,
    },
    decimalPlaces: {
      // 소수점 자릿수
      type: Number,
      default: 2,
    },
    regExp: {
      type: String,
    },
  },
  data() {
    return {
      /**
       * 데이터값
       */
      dataValue: '',
      // /**
      //  * 데이터의 정규식 패턴
      //  */
      // dataPattern: null,
      // /**
      //  * 기존 데이터값
      //  */
      // oldDataValue: null,
      // /**
      //  * 기존 SelectionStart 위치
      //  */
      // oldSelectionStart: null,
      // /**
      //  * 기존 SelectionEnd 위치
      //  */
      // oldSelectionEnd: null,
      hasFocus: false,
      selection: {
        keydown: {
          start: null,
          end: null,
        },
        input: {
          start: null,
          end: null,
        },
        old: {
          start: null,
          end: null,
        },
      },
      eventName: '',
      originalValue: '',
      isUser: false,
    };
  },
  computed: {
    errTitle() {
      return this.errorMessage instanceof Object ? this.errorMessage.title : 'Confirm';
    },
    errMessage() {
      return this.errorMessage instanceof Object ? this.errorMessage.message : this.errorMessage;
    },
    // id
    textBoxId: {
      get() {
        return this.getId();
      },
    },

    // class
    rootClass: {
      get() {
        let obj = {
          'iui-textbox': true,
          'iui-layout-debug': this.layoutDebug,
        };

        return obj;
      },
    },
    textBoxClass: {
      get() {
        let obj = {
          textbox: true,
          'text-align-right': this.internalAlign ? this.internalAlign : this._isNumberFormat && this.numberFormat,
          required: this.required,
          'is-valid-fail': !this.isValid,
        };

        return obj;
      },
    },

    // style
    rootStyle: {
      get() {
        let obj = {
          width: undefined,
          height: undefined,
          'flex-grow': undefined,
          'flex-shrink': undefined,
          'flex-basis': undefined,
        };

        if (this.internalWidth !== undefined) {
          obj.width = this.internalWidth;
          obj['flex-grow'] = '0 !important';
          obj['flex-shrink'] = '0 !important';
          obj['flex-basis'] = `${this.internalWidth} !important`;
        } else {
          obj['min-width'] = this.minWidth ?? '140px';
        }
        if (this.maxWidth !== undefined) {
          obj['max-width'] = this.maxWidth;
        }
        if (this.internalHeight !== undefined) {
          obj.height = this.internalHeight;
        }

        return obj;
      },
    },
    textBoxStyle: {
      get() {
        let obj = {
          width: undefined,
          flex: undefined,
        };

        if (this.internalAlign !== undefined) {
          obj.textAlign = this.internalAlign;
        }
        if (this.internalHeight !== undefined) {
          obj.height = this.internalHeight;
        }

        return obj;
      },
    },

    // TODO: etc
    _isNumberFormat: {
      get() {
        return (
          this.type === 'number' ||
          this.type === 'currency' ||
          this.type === 'amount' ||
          this.type === 'unitAmount' ||
          this.type === 'quantity' ||
          this.type === 'rate'
        );
      },
    },
    _isPhoneFormat: {
      get() {
        return this.type == 'phone';
      },
    },
    _isEmailFormat1: {
      get() {
        return this.type == 'email1';
      },
    },
    _isEmailFormat2: {
      get() {
        return this.type == 'email2';
      },
    },
    _isIdFormat: {
      get() {
        return this.type == 'empNo';
      },
    },

    formattedValue: {
      get() {
        return this.formatValue(this.dataValue);
      },

      set(newValue) {
        this.dataValue = this.unformatValue(newValue);
      },
    },
  },
  watch: {
    value(newValue) {
      // props가 변경될 경우 formattedValue에 해당 값을 설정
      this.formattedValue = newValue;
    },
  },
  created() {
    if (this.value !== undefined) {
      if (this.value !== null) {
        if (
          (this.type === 'number' ||
            this.type === 'currency' ||
            this.type === 'amount' ||
            this.type === 'unitAmount' ||
            this.type === 'quantity' ||
            this.type === 'rate') &&
          isNaN(this.value)
        ) {
          this.dataValue = '';
        } else {
          this.dataValue = this.value.toString();
        }
      }
    }

    // validator 설정
    this.validatorTargetDataProp = 'dataValue';
    this.validationTriggerEventName = ['valid'];
  },
  mounted() {
    this.initText();
    if (this.type === 'search' || this.type === 'password') {
      this.$refs.input.setAttribute('type', this.type);
    }

    //속성 주입
    for (let k in this.$attrs) {
      this.$refs.input.setAttribute(k, this.$attrs[k]);
      this.$el.removeAttribute(k);
      if (k == 'id' && this.$el.querySelector('label')) {
        this.$el.querySelector('label').setAttribute('for', this.$attrs[k]);
      }
    }

    //이벤트 주입
    for (let e of this.event) {
      this.$refs.input.addEventListener(e.name, e.callback);
    }

    //스타일 주입
    for (let k in this.css) {
      if (k == 'width') {
        this.$el.style[k] = this.css[k];
        if (this.suffix) {
          let swith = this.$el.querySelector('._suffix').offsetWidth + 6;
          this.$refs.input.style[k] = 'calc(100% - ' + swith + 'px)';
        } else {
          this.$refs.input.style[k] = '100%';
        }
        this.$refs.input.style['padding'] = '2px 0px';
      } else {
        this.$refs.input.style[k] = this.css[k];
      }
    }
  },
  updated() {
    this.initLayout();
    this.initText();
    this.initValidator();
    this.checkParameter();
  },
  methods: {
    initText() {
      if (this.width !== undefined) {
        this.internalWidth = this.width;
      }
      if (this.type === 'smallcode') {
        if (this.internalWidth === undefined) {
          this.internalWidth = SMALL_CODE_TEXTBOX_WIDTH;
        }
      }
      if (this.type === 'code') {
        if (this.internalWidth === undefined) {
          this.internalWidth = CODE_TEXTBOX_WIDTH;
        }
      }
      if (this.type === 'largecode') {
        if (this.internalWidth === undefined) {
          this.internalWidth = LARGE_CODE_TEXTBOX_WIDTH;
        }
      }
      if (this.type === 'quantity') {
        if (this.internalWidth === undefined && this.internalaSuffixWidth === undefined) {
          this.internalWidth = NUMBER_TEXTBOX_WIDTH;
        }
      }
      if (this.type === 'currency' || this.type === 'amount' || this.type === 'unitAmount') {
        if (this.internalWidth === undefined && this.internalaSuffixWidth === undefined) {
          this.internalWidth = `calc(${NUMBER_TEXTBOX_WIDTH} + ${NUMBER_SUFFIX_WIDTH} + ${ITEM_MARGIN_RIGHT})`;
        }
        if (this.internalSuffix === undefined) {
          this.internalSuffix = NUMBER_SUFFIX;
        }
        if (this.internalaSuffixWidth === undefined) {
          this.internalSuffixWidth = NUMBER_SUFFIX_WIDTH;
        }
      }

      if (this.type === 'rate') {
        if (this.internalWidth === undefined && this.internalaSuffixWidth === undefined) {
          this.internalWidth = `calc(${RATE_TEXTBOX_WIDTH} + ${RATE_SUFFIX_WIDTH} + ${ITEM_MARGIN_RIGHT})`;
        }
        if (this.internalSuffix === undefined) {
          this.internalSuffix = RATE_SUFFIX;
        }
        if (this.internalSuffixWidth === undefined) {
          this.internalSuffixWidth = RATE_SUFFIX_WIDTH;
        }
      }

      if (this.type === 'multi') {
        this.$refs.textarea.removeAttribute('maxlength');
      } else {
        if (this.$refs.input !== undefined) {
          this.$refs.input.removeAttribute('maxlength');
        }
      }
      if (this.maxLength !== undefined) {
        if (this.type === 'multi') {
          this.$refs.textarea.setAttribute('maxlength', this.maxLength);
        } else {
          this.$refs.input.setAttribute('maxlength', this.maxLength);
        }
      }
      if (this.minLength !== undefined) {
        if (this.type === 'multi') {
          this.$refs.textarea.setAttribute('minlength', this.minLength);
        } else {
          this.$refs.input.setAttribute('minlength', this.minLength);
        }
      }
      if (this._isPhoneFormat && this.$refs.input !== undefined) {
        this.$refs.input.setAttribute('maxlength', PHONE_MAX_LENGTH);
      }

      if (this.$refs.input !== undefined) {
        if (this.type === 'search' || this.type === 'password') {
          this.$refs.input.setAttribute('type', this.type);
        }
      }

      this.setBindArray();
    },
    /**
     * computed를 v-model로 설정했을 경우
     * 1. keydown > keypress
     * 2. computed.formattedValue.set > input(데이터변경)
     * 3. beforeUpdate > computed.formattedValue.get > updated
     * 4. keyup > change
     *
     * 1. keydown
     * 2. computed.formattedValue.set > input(데이터변경)
     * 3. beforeUpdate > computed.formattedValue.get > updated
     * 4. keyup > change
     *
     * data를 v-model로 설정했을 경우
     * 1. keydown > keypress
     * 2. input(데이터변경)
     * 3. beforeUpdate > updated
     * 4. keyup > change
     *
     * 1. keydown
     * 2. input(데이터변경)
     * 3. beforeUpdate > updated
     * 4. keyup > change
     *
     * computed를 v-bind로 설정하고 $refs로 값을 변경했을 경우
     * data를 v-bind로 설정하고 $refs로 값을 변경했을 경우
     * 1. keydown > keypress
     * 2. input(데이터변경)
     * 3. keyup > change
     *
     *
     * 처리 순서
     * keydown
     * backspace, delete 등 키를 눌렀을 경우 selection 저장
     *
     * input
     * 변경된 데이터를 unformatValue
     * unformat된 데이터를 패턴 점검
     * formatValue
     * format된 데이터를 패턴 점검
     * format된 데이터를 input에 설정
     * selection 조정
     *
     */
    formatValue(value) {
      if (value === undefined || value === null) {
        value = '';
      }

      if (value) {
        if (this.regExp) {
          const re = new RegExp(this.regExp, 'g');
          value = value.toString().replace(re, '');
        } else if (this._isNumberFormat) {
          let decimalType = this.decimalCeil
            ? 'ceil'
            : this.decimalFloor
            ? 'floor'
            : this.decimalRound
            ? 'round'
            : undefined;

          let digit =
            this.type === 'unitAmount' ? 10 : this.type === 'quantity' ? 10000 : this.type === 'rate' ? 100 : 1;
          if (value !== '-') {
            value = $_numberFormat(value, this.numberFormat, this.eventName, decimalType, digit);
          }
        } else if (this._isPhoneFormat) {
          // 1. 숫자 외의 값 제거
          value = value.toString().replace(/[^0-9]/g, '');

          // 2. 전화번호 하이픈 처리
          value = value.toString().replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/g, '$1-$2-$3');
        } else if (this._isEmailFormat1) {
          value = value.toString().replace(/[^a-zA-z0-9._%+-]+/g, '');
        } else if (this._isEmailFormat2) {
          value = value.toString().replace(/[^a-zA-z0-9-_.]/g, '');
        } else if (this._isIdFormat) {
          value = value.toString().replace(/[^a-zA-z0-9]/g, '');
        }
      }
      return value;
    },
    unformatValue(value) {
      if (value === undefined || value === null) {
        value = '';
      }

      if (value) {
        if (this._isNumberFormat) {
          if (isNaN(value)) {
            value = value.toString().replace(/[^-0-9.]/g, '');
          } else {
            value.toString().replace(/,/g, '');
          }
        } else if (this._isPhoneFormat) {
          value = value.toString().replace(/-/g, '');
        }
      }

      return value;
    },

    /**
     * 아래 이밴드 핸들러들은 이밴드 발생 순서에 따라 코드가 작성되어 있다.
     */
    /**
     * input의 focus 이밴트 핸들러
     * @param {Object} FocusEvent
     */
    input_focus(e) {
      this.hasFocus = true;
      this.$emit('focus', e);
    },
    /**
     * input의 click 이벤트 핸들러
     * @param {Object} MouseEvent
     */
    input_click(e) {
      this.$emit('click', e);
    },
    /**
     * input의 blur 이밴트 핸들러
     * @param {Object} FocusEvent
     */
    input_blur(e) {
      this.isUser = false;
      this.hasFocus = false;
      this.$emit('blur', e);
    },
    /**
     * input의 keydown 이벤트 핸들러
     * @param {Object} KeyboardEvent
     */
    input_keydown(e) {
      this.isUser = true;
      this.eventName = e.type;
      e.target.value = this.formatValue(e.target.value);
      this.selection.keydown.start = this.$refs.input.selectionStart;
      this.selection.keydown.end = this.$refs.input.selectionEnd;
      this.$emit('keydown', e);
    },
    input_keyup(e) {
      this.$emit('keyup', e);
    },
    input_keypress(e) {
      this.$emit('keypress', e);
    },
    input_keypress_enter(e) {
      this.eventName = e.type;
      setTimeout(() => {
        e.target.value = this.formattedValue;
        this.$emit('enter', e);
      }, 10);
    },
    /**
     * input의 input 이벤트 핸들러
     * @param {Object} InputEvent
     */
    input_input(e) {
      this.eventName = e.type;
      e.target.value = this.limitByType(e.target.value);
      e.target.value = this.formatValue(e.target.value);
      this.selection.input.start = this.$refs.input.selectionStart;
      this.selection.input.end = this.$refs.input.selectionEnd;
      this.formattedValue = e.target.value;

      this.$emit('input', e);
      this.$emit('valid', e);
      this.$emit('update:value', this.dataValue);
      this.setBindArray(this.dataValue);
    },
    /**
     * input의 change 이벤트 핸들러
     * @param {Object} Event
     */
    input_change(e) {
      this.eventName = e.type;
      this.formattedValue = e.target.value;
      e.target.value = this.dataValue;
      this.$emit('change', e);
      this.$emit('valid', e);

      this.eventName = e.type;
      e.target.value = this.formatValue(e.target.value);

      this.$emit('update:value', this.dataValue);
      this.setBindArray(this.dataValue);
    },
    /**
     * TODO: 정리 필요
     */
    setBindArray(value) {
      if (typeof value != 'undefined') {
        this.bindArray.forEach(o => {
          let payload = {};
          payload[this.name] = this._isNumberFormat ? String(value).replace(/,/g, '') : value;

          if (this._isNumberFormat) {
            payload[this.name] = value.toString().replace(/,/g, '');
          } else if (this._isPhoneFormat) {
            payload[this.name] = value.toString().replace(/-/g, '');
          } else {
            payload[this.name] = value;
          }

          rootStore.commit(rootStore.getters.currentUrl + '/' + o, payload);
        });
      }
    },
    limitByType(value) {
      const AMOUNT = 999999999999999.99999; // 단가, 금액 NUMERIC(20, 5)
      const QUANTITY = 9999999999.99999; // 수량 NUMERIC(15, 5)
      const RATE = 99999.99999; // 비율 NUMERIC(10, 5)
      value = value.replaceAll(',', '');

      let values = value.toString().split('.');
      let numberValue = String(values[0]);
      let decimalValue = values[1] ? String(values[1]) : undefined;

      if (this.type === 'unitAmount' || this.type === 'amount') {
        if (-AMOUNT > Number(value) || Number(value) > AMOUNT || (decimalValue && decimalValue.length > 5)) {
          value = this.cutValue(numberValue, decimalValue, 15, 5);
        }
      }
      if (this.type === 'quantity') {
        if (-QUANTITY > Number(value) || Number(value) > QUANTITY || (decimalValue && decimalValue.length > 5)) {
          value = this.cutValue(numberValue, decimalValue, 10, 5);
        }
      }
      if (this.type === 'rate') {
        if (-RATE > Number(value) || Number(value) > RATE || (decimalValue && decimalValue.length > 5)) {
          value = this.cutValue(numberValue, decimalValue, 5, 5);
        }
      }
      return value;
    },
    cutValue(numberValue, decimalValue, numValid, decValid) {
      let value;
      numberValue = numberValue.slice(0, numValid);
      if (decimalValue !== undefined) {
        decimalValue = decimalValue.slice(0, decValid);
        value = `${numberValue}.${decimalValue}`;
      } else {
        value = numberValue;
      }
      return value;
    },
    checkParameter() {
      if (!this.isUser) {
        this.originalValue = this.dataValue;
      }

      if (this.type === 'multi') {
        if (this.originalValue !== this.dataValue) {
          this.$refs.textarea.setAttribute('data-changed', '');
        } else {
          this.$refs.textarea.removeAttribute('data-changed');
        }
      } else {
        if (this.originalValue !== this.dataValue) {
          this.$refs.input.setAttribute('data-changed', '');
        } else {
          this.$refs.input.removeAttribute('data-changed');
        }
      }
    },
  },
};
</script>
