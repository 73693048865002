<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <PxcondList />
              </i-col>
            </i-row>
            <i-spacer />
            <i-row height="220px">
              <i-col>
                <PxcondCollectList />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from './store/PxcondBilclctRegist.js';
import PrjList from '@/view/Project/components/PrjList.vue';
import PxcondList from './components/PxcondList.vue';
import PxcondCollectList from './components/PxcondCollectList.vue';
export default {
  components: {PrjList, PxcondList, PxcondCollectList},
  beforeCreate() {
    $init(this, store);
  },
};
</script>

<style></style>
