<template>
  <iui-container-new type="table" theme="bullet">
    <i-row>
      <i-col-header>현장</i-col-header>
      <i-col>
        <iui-text type="code" name="prjCd" :value="searchInfo.prjCd" :enable="false"></iui-text>
        <iui-text type="text" name="prjNm" :value="searchInfo.prjNm" :enable="false"></iui-text>
      </i-col>
    </i-row>
    <i-row>
      <i-col colspan="2">
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col-header>
              최종차수
            </i-col-header>
            <i-col width="190px">
              <iui-text
                type="number"
                name="maxDgree"
                suffix="차"
                width="107px"
                :value="maxDgree"
                :enable="false"
              ></iui-text>
            </i-col>
            <i-col-header>변경차수</i-col-header>
            <i-col>
              <iui-text
                type="number"
                name="chgDegree"
                suffix="차"
                width="107px"
                :value="String(Number(masterInfo.chgDegree) + 1)"
                :enable="false"
              ></iui-text>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
    <i-row>
      <i-col-header>변경사유</i-col-header>
      <i-col>
        <iui-text type="text" name="bldRmk" :value="masterInfo.bldRmk" :bindArray="bindArray"></iui-text>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
export default {
  beforeCreate: function() {
    $mapGetters(this, {
      searchInfo: 'searchInfo',
      detailInfo: 'detailInfo',
      maxDgree: 'maxDgree',
      masterInfo: 'masterInfo',
    });
  },
  created: function() {},
  data: function() {
    return {
      bindArray: ['setMasterInfo'],
    };
  },
  computed: {},
};
</script>

<style scoped></style>
