export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 0},
    Def: {
      Row: {NoColor: 1},
    },
    Cols: [
      {Header: '납부번호', Name: 'stampContNo', Type: 'Text', RelWidth: 1, Align: 'center', FormulaRow: '합계'},
      {Header: '납부자', Name: 'payer', Type: 'Text', RelWidth: 1, Align: 'center'},
      {
        Header: '고유번호',
        Name: 'stampNo',
        Type: 'Text',
        RelWidth: 1,
        Align: 'center',
        CustomFormat: v => (v ? v : '-'),
      },
      {Header: '납부정보', Name: 'paymentInfo', Type: 'Text', RelWidth: 1, Align: 'center'},
      {
        Header: '납부금액(원)',
        Name: 'stampPaymentAmt',
        RelWidth: 1,
        Extend: preset.amount,
        Type: 'Int', // FormulaRow: 'Sum' 을 사용하기 위해서 Type: 'Int' 필요함
        FormulaRow: 'Sum',
      },
      {Name: 'stampSeq', Visible: false},
      {Name: 'stampBsnNo', Visible: false},
      {Name: 'issueFlag', Visible: false},
      {Name: 'flNo', Visible: false},
      {Name: 'lastFlNo', Visible: false},
      {Name: 'stampFlNo', Visible: false},
      {Name: 'rgprId', Visible: false},
    ],
  };
};
