<template>
  <pmis-page
    ref="page"
    :search-project-visible="false"
    @search-click="onSearch"
    @new-click="onAdd"
    @save-click="onSave"
    @delete-click="onDelete"
  >
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="450px">
          <LbrcTeamList />
        </i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row height="190px">
              <i-col>
                <pmis-tab-box>
                  <template v-slot:title>기본정보</template>
                  <BasicInfo />
                </pmis-tab-box>
              </i-col>
            </i-row>
            <i-spacer />
            <i-row minHeight="300px">
              <i-col>
                <TeamMemberStatus />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
/**
 * 노무팀 등록
 *  components :
 *   lbrcTeamList.vue 노무팀 목록
 *   basicInfo.vue 기본정보
 *   teamMemberStatus.vue 팀원현황
 *
 * */
import LbrcTeamList from './components/lbrcTeamList.vue';
import BasicInfo from './components/basicInfo.vue';
import TeamMemberStatus from './components/teamMemberStatus.vue';
import store from './store/lbrcTeamRegist.js';
export default {
  components: {
    LbrcTeamList,
    TeamMemberStatus,
    BasicInfo,
  },
  beforeCreate() {
    $init(this, store);
    $mapMutations(this, ['initLbrcTeamInfo']);
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'LbrcTeamList_onSearch'});
    },
    onAdd() {
      this.initLbrcTeamInfo();
      this.callEvent({name: 'teamMemberStatus_onInit'});
    },
    onSave() {
      this.callEvent({name: 'basicInfo_onSave'});
    },
    onDelete() {
      this.callEvent({name: 'basicInfo_onDelete'});
    },
  },
};
</script>

<style></style>
