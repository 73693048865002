<template>
  <pmis-content-box>
    <template #header-left>
      <iui-text :value="searchName" @change="searchName = $event.target.value" @keyup.enter="onFilterSearch" />
      <iui-button value="검색" @click="onFilterSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onRenderFirstFinish: onRenderFirstFinish,
      }"
    />
  </pmis-content-box>
</template>

<script>
import {selectRegisterEqutInfo} from '../api/anyTimeEquipment.js';
import options from './sheetOption/EqutAnmonMListMSheet.js';
export default {
  props: {
    investMm: {
      type: String,
    },
    reqDegree: {
      type: String,
    },
    repayfrDt: {
      type: String,
    },
    repaytoDt: {
      type: String,
    },
    search: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: $addColCheckbox(options(this)),
      loadSearchData: [],
      sheet: undefined,
      returnCols: ['custCode', 'custName', 'equipCode', 'equipName', 'carNo'],
      searchName: '',
    };
  },
  watch: {
    search(value) {
      if (value) {
        this.selectRegisterInfo();
      }
    },
  },
  beforeCreate() {
    $mapMutations(this, 'equipment', ['setSearchInfo']);
  },
  created() {
    this.addEvent([
      {
        name: 'anyTimeRegister_getRows',
        func: this.getRows,
      },
      {
        name: 'anyTimeRegister_search',
        func: this.selectRegisterInfo,
      },
    ]);
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    getRows(callback) {
      if (callback instanceof Function) {
        let rows = this.sheet.getRowsByChecked('chk');
        if (!rows.length) {
          rows = this.sheet.getFocusedRow() ? [this.sheet.getFocusedRow()] : [];
        }

        this.setSearchInfo({
          investMm: this.investMm,
          reqDegree: this.reqDegree,
        });

        callback(this.rowsFilter(rows));
      }
    },
    rowsFilter(rows) {
      const resultRow = [];
      rows.map(row => {
        let rowObj = {};
        for (let key in row) {
          if (this.returnCols.indexOf(key) != -1) {
            rowObj[key] = row[key];
          }
          rowObj['repayfrDt'] = this.repayfrDt;
          rowObj['repaytoDt'] = this.repaytoDt;
          rowObj['cud'] = '1';
        }

        if (Object.keys(rowObj).length) {
          resultRow.push(rowObj);
        }
      });
      return resultRow;
    },
    onRenderFirstFinish() {
      this.selectRegisterInfo();
    },
    async selectRegisterInfo() {
      const param = {
        repayfrDt: this.repayfrDt,
        repaytoDt: this.repaytoDt,
      };
      const response = await selectRegisterEqutInfo(param);
      const data = response.data;
      this.$emit('update:investMm', data.investMm);
      this.$emit('update:repayfrDt', data.repayfrDt);
      this.$emit('update:repaytoDt', data.repaytoDt);
      this.$emit('update:reqDegree', data.reqDegree);
      this.loadSearchData = data.anyTimeEqutCustomerList;
      this.$emit('update:search', false);
    },
    onFilterSearch() {
      if (this.searchName) {
        this.sheet.setFilter(
          'workerFilter',
          obj => {
            return (
              obj.Row.custName.indexOf(this.searchName) != -1 ||
              obj.Row.equipName.indexOf(this.searchName) != -1 ||
              obj.Row.carNo.indexOf(this.searchName) != -1
            );
          },
          1
        );
      } else {
        this.sheet.clearFilter();
      }
    },
  },
};
</script>

<style></style>
