<template>
  <pmis-content-box>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onSearch,
      }"
      @loadSheet="loadSheet"
    />
    <iui-modal name="prjModal" :title="'현장선택'" :btns="modalBtns" sizeType="size1">
      <iui-searchmodal :type="'prj'" :uid="_uid" :isEndCntrInc="false"></iui-searchmodal>
    </iui-modal>
    <iui-modal name="subconListModal" title="외주선택" :btns="modalBtns" sizeType="size3">
      <SubconListPopup :uid="_uid" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import SubconListPopup from './SubconListPopup.vue';
import options from './sheetOption/InputPrjSheet.js';
import {selectInputPrjList, deleteInputPrjList, saveInputPrjList} from '../api/worker.js';
export default {
  components: {SubconListPopup},
  beforeCreate() {
    $mapGetters(this, ['workerInfo', 'removeRow']);
    $mapMutations(this, ['setRemoveRow', 'initRemoveRow']);
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      modalBtns: [{name: '확인', callback: this.onConfirmModal}],
      Options: $addColCheckbox(options(this)),
      loadSearchData: [],
    };
  },
  computed: {
    isWorkGroup() {
      return this.workerInfo.blnDepPart === $getConstants('BLN_DEP_PART_CD_CONSTRUCTION_SERVICE').code;
    },
    modalNm() {
      return `${this.isWorkGroup ? 'subconList' : 'prj'}Modal`;
    },
  },
  watch: {
    isWorkGroup(val) {
      this.sheet.setAttribute(null, 'poNoSubc', 'Visible', val, 0);
      this.sheet.setAttribute(null, 'ttl', 'Visible', val, 0);
      this.sheet.rerender();
    },
  },
  created() {
    this.addEvent([
      {name: 'InputPrj_onSearch', func: this.onSearch},
      {name: 'InputPrj_onAdd', func: this.onShowModal},
      {name: 'InputPrj_onDelete', func: this.onDelete},
      {name: 'InputPrj_onSave', func: this.onSave},
      {name: 'InputPjt_initSheet', func: this.initSheet},
      {name: 'InputPrj_getSaveList', func: this.getSaveList},
      //현장정보 콜백
      {name: 'prjSearchOnConfirm' + this._uid, func: this.onConfirmModal},
      //외주정보 콜백
      {name: 'onHideModal_' + this._uid, func: this.onConfirmModal},
      {name: 'InputPrj_setSubconListCallbackData', func: this.setSubconListCallbackData},
    ]);
  },
  methods: {
    async onSearch() {
      let param = {
        empNo: this.workerInfo.empNo,
      };
      const response = await selectInputPrjList(param);
      if (response.status == 200) {
        this.loadSearchData = response.data;
      }
    },
    getSaveList(callback) {
      if (this.workerInfo.blnDepPart != $getConstants('BLN_DEP_PART_CD_CONSTRUCTION_SERVICE').code) {
        let data = this.sheet.getDataRows();
        data.forEach(row => {
          if (row.poNoSubc != 0) {
            this.sheet.setValue(row, 'poNoSubc', '', 0);
            this.sheet.setValue(row, 'ttl', '', 0);
          }
        });
      }
      let saveJson = $_statusToCud(this.sheet.getSaveJson().data);
      const callbackParam = {
        isValid: false,
        list: [],
      };
      const title = '투입현장정보';
      let message = '';
      if (saveJson.length == 0) {
        callbackParam.isValid = true;
      } else {
        saveJson.some(row => {
          if (!row.rlseDate) {
            message = '투입일자를 선택해주세요.';
          } else if (!row.outDate) {
            message = '철수(예정)일자를 선택해주세요.';
          } else if (!row.eduDate) {
            message = '교육이수일자를 선택해주세요.';
          } else if (!row.safetyGearYn) {
            message = '안전장구수령여부를 선택해주세요.';
          }
          if (message) {
            return true;
          }
        });
        saveJson.forEach(row => {
          row.rlseDate = row.rlseDate.replace(/\//g, '');
          row.outDate = row.outDate.replace(/\//g, '');
          row.eduDate = row.eduDate.replace(/\//g, '');
        });
        if (message) {
          callbackParam.isValid = false;
          this.$alert({title, message});
        } else {
          callbackParam.isValid = true;
          callbackParam.list = saveJson;
        }
      }
      if (typeof callback === 'function') {
        callback(callbackParam);
      } else {
        callbackParam.list = saveJson;
        return callbackParam;
      }
    },
    async onSave() {
      if (this.getSaveList().isValid) {
        if (this.removeRow.prjCd) {
          let removeList = [];
          removeList.push({
            empNo: this.workerInfo.empNo,
            prjCd: this.removeRow.prjCd,
            poNoSubc: this.removeRow.poNoSubc,
          });
          const resposne = await deleteInputPrjList({inputPrjSaveList: removeList});
          this.initRemoveRow();
        }
        let inputPrjSaveList = this.getSaveList().list;
        const response = await saveInputPrjList({inputPrjSaveList});
        if (response.status == 200) {
          this.onSearch();
        }
      }
    },
    async onDelete() {
      let checkedRows = this.sheet.getRowsByChecked('chk');
      let inputPrjSaveList = [];
      let removeRows = [];

      if (0 == checkedRows.length) {
        this.$alert({title: '선택항목 없음', message: '선택된 항목이 없습니다'});
        return;
      }

      if (!(await this.$confirm({title: '투입현장 삭제', message: '삭제하시겠습니까?'}))) return;

      for (let row of checkedRows) {
        if (row.labtDailyReportCount > 0) {
          this.$alert({
            title: '삭제불가',
            message: `해당 근로자의 출역정보가 존재합니다.<br/>삭제할 수 없습니다.<br/>${row.prjNm}`,
          });
          return;
        } else {
          inputPrjSaveList.push({
            empNo: row.empNo,
            prjCd: row.prjCd,
            poNoSubc: row.poNoSubc,
            cud: 3,
          });
        }
      }
      this.sheet.removeRows(removeRows);

      const resposne = await deleteInputPrjList({inputPrjSaveList});
      if (resposne.status == 200) {
        this.onSearch();
      }
    },
    initSheet() {
      this.sheet.removeAll();
    },
    onShowModal() {
      if (!this.workerInfo.blnDepNo) {
        this.$alert({title: '소속 입력', message: '소속을 입력해주세요'});
        return;
      }
      if (this.modalNm == 'subconListModal') {
        this.$store.commit('setModalParam', {cusCd: this.workerInfo.blnDepNo});
      }
      this.$modal.show(this.modalNm);
    },
    onConfirmModal() {
      if (this.modalNm == 'prjModal') {
        this.callEvent({
          name: 'onFocusedRowDataOnContract',
          param: data => {
            //중복현장 검색
            //findText( 열이름, 찾을문자열, 검색시작 로우, 문자열매칭모드(-1: 전체가 동일한 값), 대소문자구별 );
            let sRow = this.sheet.getFirstRow();
            setTimeout(() => {
              let sdata = this.sheet.findText('prjCd', String(data.prjCd), sRow, -1, false);

              if (sdata != null) {
                this.$alert({title: '투입현장', message: '이미 등록된 현장입니다.'}, () => {
                  this.onShowModal();
                });
                return;
              }

              let row = this.sheet.addRow();
              this.sheet.setValue(row, 'prjCd', data.prjCd, 1);
              this.sheet.setValue(row, 'prjNm', data.prjNm, 1);
              this.sheet.setValue(row, 'pgmCd', this.workerInfo.pgmCd, 1);
              this.sheet.setValue(row, 'empNo', this.workerInfo.empNo, 1);
              this.sheet.setValue(row, 'subCode', this.workerInfo.blnDepNo, 1);
              this.sheet.setValue(row, 'blnDepPart', this.workerInfo.blnDepPart, 1);
            }, 10);
          },
        });
      } else if (this.modalNm == 'subconListModal') {
        this.callEvent({
          name: 'SubconListPopup_callbackData',
          param: this.setSubconListCallbackData,
        });
      }
      this.$modal.hide(this.modalNm);
    },
    async setSubconListCallbackData(data) {
      this.sheet.getDataRows().forEach(row => {
        if (row.prjCd == data.spotDeptcd && row.poNoSubc != data.poNo) {
          this.sheet.removeRow(row);
          this.setRemoveRow({prjCd: row.prjCd, poNoSubc: row.poNoSubc});
        }
      });
      let duplicateRow = this.sheet.getDataRows().find(row => row.poNoSubc == data.poNo);
      if (duplicateRow !== undefined) {
        this.$alert({title: '투입현장', message: '이미 등록된 현장 계약정보 입니다.'});
        return;
      }

      let row = this.sheet.addRow();
      this.sheet.setValue(row, 'prjCd', data.spotDeptcd, 1);
      this.sheet.setValue(row, 'prjNm', data.spotDeptnm, 1);
      this.sheet.setValue(row, 'poNoSubc', data.poNo, 1);
      this.sheet.setValue(row, 'ttl', data.ttl, 1);
      this.sheet.setValue(row, 'pgmCd', this.workerInfo.pgmCd, 1);
      this.sheet.setValue(row, 'empNo', this.workerInfo.empNo, 1);
      this.sheet.setValue(row, 'subCode', this.workerInfo.blnDepNo, 1);
      this.sheet.setValue(row, 'blnDepPart', this.workerInfo.blnDepPart, 1);
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped></style>
