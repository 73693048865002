export default that => {
  return {
    Cfg: {
      Hover: 1, // 셀 단위
      CanEdit: false,
    },
    Def: {
      Row: {
        CanFormula: 1,
        CalcOrder: 'filesType,filesButton',
        CanFocus: false,
      },
    },
    Cols: [
      {
        Header: '번호',
        Name: 'natNo',
        Type: 'Text',
        Width: 70,
        Align: 'center',
      },

      {
        Header: '회사명',
        Name: 'frmNm',
        Type: 'Text',
        Width: 150,
        Align: 'center',
      },
      {
        Header: '업무구분',
        Name: 'tskDsNm',
        Type: 'Text',
        Width: 120,
        Align: 'Center',
      },
      {
        Header: '첨부',
        Name: 'files',
        Align: 'center',
        TypeFormula: fr => (fr.Row['flNo'] != 0 ? 'Button' : 'Text'),
        ButtonFormula: fr => (fr.Row['flNo'] != 0 ? 'Button' : ''),
        ButtonText: '첨부파일',
        ButtonWidth: 80,
        Width: 100,
      },
      {
        Header: '제목',
        Name: 'tl',
        Type: 'Text',
        RelWidth: 1,
        TextStyle: 4,
        Align: 'Left',
        Cursor: 'pointer',
      },
      {
        Header: '등록자',
        Name: 'qnm',
        Type: 'Text',
        Width: 100,
        Align: 'Center',
        Visible: true,
      },
      {
        Header: '등록일',
        Name: 'qdttm',
        Type: 'Text',
        Width: 100,
        Align: 'Center',
        Visible: true,
      },
      {
        Header: '답변자',
        Name: 'rqNm',
        Type: 'Text',
        Width: 100,
        Align: 'Center',
      },
      {
        Header: '답변일',
        Name: 'rqDttm',
        Type: 'Text',
        Width: 100,
        Align: 'Center',
      },
      {Name: 'pgmCd', Visible: false},
      {Name: 'flNo', Visible: false},
      {Name: 'rgprId', Visible: false},
    ],
  };
};
