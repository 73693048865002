<template>
  <pmis-content-box>
    <template v-slot:title>
      회사기본정보
    </template>
    <iui-container-new type="table" theme="bullet">
      <colgroup>
        <col width="100px" />
        <col width="250px" />
        <col width="100px" />
        <col width="250px" />
        <col width="70px" />
        <col width="80px" />
      </colgroup>
      <i-row>
        <i-col-header>회사코드</i-col-header>
        <i-col>
          <iui-text width="100px" :value="companyInfo.pgmCd" :enable="false" name="pgmCd" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header :required="enable">회사명</i-col-header>
        <i-col colspan="6">
          <iui-text
            max-length="100"
            :value="companyInfo.frmNm"
            :placeholder="enable ? '(사업자등록증 상호 등록)' : ''"
            @change="setCompanyInfo({frmNm: $event.target.value})"
            :enable="enable"
            :required="enable"
            :errorMessage="{title: '회사명', message: '회사명을 입력해주세요.'}"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>회사명(영문)</i-col-header>
        <i-col colspan="6">
          <iui-text
            max-length="100"
            :value="companyInfo.frmEnNm"
            @change="setCompanyInfo({frmEnNm: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header :required="enable">사업자번호</i-col-header>
        <i-col>
          <iui-bsn-no
            :value1.sync="bsnNo1"
            :value2.sync="bsnNo2"
            :value3.sync="bsnNo3"
            @change1="setBsnNo"
            @change2="setBsnNo"
            @change3="setBsnNo"
            :enable="false"
            :required="enable"
          />
        </i-col>
        <i-col-header>주민/법인번호</i-col-header>
        <i-col>
          <iui-regi-no
            :value1.sync="regiNo1"
            :value2.sync="regiNo2"
            @change1="setRegiNo"
            @change2="setRegiNo"
            size="short"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header :required="enable">대표자명</i-col-header>
        <i-col>
          <iui-text
            width="100px"
            max-length="50"
            :value="companyInfo.rpsvNm"
            @change="setCompanyInfo({rpsvNm: $event.target.value})"
            :enable="enable"
            :required="enable"
            :errorMessage="{title: '대표자명', message: '대표자명을 입력해주세요.'}"
          />
          <iui-button value="공동대표" @click="ceoListPopUp" />
        </i-col>
        <i-col-header>대표자명(영문)</i-col-header>
        <i-col>
          <iui-text
            max-length="50"
            :value="companyInfo.rpsvEnNm"
            @change="setCompanyInfo({rpsvEnNm: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header :required="enable">대표전화</i-col-header>
        <i-col>
          <iui-text
            type="phone"
            width="100px"
            max-length="16"
            :value="companyInfo.rpsvPhone"
            @change="setCompanyInfo({rpsvPhone: $event.target.value})"
            :enable="enable"
            :required="enable"
            :errorMessage="{title: '대표전화', message: '대표전화를 입력해주세요.'}"
          />
        </i-col>
        <i-col-header>홈페이지</i-col-header>
        <i-col>
          <iui-text
            max-length="300"
            :value="companyInfo.homepage"
            @change="setCompanyInfo({homepage: $event.target.value})"
            :enable="enable"
          />
        </i-col>
        <!-- 외주||물품자재공급사 -->
        <i-spacer />
        <i-col-header v-if="frmGb2 || frmGb3 || frmGb9">
          첨부파일
        </i-col-header>
        <i-col v-if="frmGb2 || frmGb3 || frmGb9">
          <pmis-file-list
            :company-code="companyInfo.pgmCd"
            id="file0"
            flNoCol="flNo"
            title="파일선택"
            :file-number.sync="companyInfo.flNo"
            :modal="true"
          />
        </i-col>
        <!-- //외주||물품자재공급사 -->
      </i-row>
      <i-row>
        <i-col-header :required="enable">주소</i-col-header>
        <i-col colspan="3">
            <iui-searchbox
              :type="'addr'"
              name="postNo"
              :idValue="companyInfo.postNo"
              :nameValue="companyInfo.adr1"
              nameEleName="adr1"
              :callback="getAddr"
            />
        </i-col>
        <i-col colspan="3">
          <i-spacer />
          <iui-text :enable="enable" :value="companyInfo.adr2" max-length="100" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header :required="enable">업태</i-col-header>
        <i-col>
          <iui-text
            width="200px"
            max-length="100"
            :value="companyInfo.bcoNm"
            @change="setCompanyInfo({bcoNm: $event.target.value})"
            :enable="enable"
            :required="enable"
            :errorMessage="{title: '업태', message: '업태를 입력해주세요.'}"
          />
        </i-col>
        <i-col-header :required="enable">종목</i-col-header>
        <i-col>
          <iui-text
            max-length="100"
            :value="companyInfo.itemNm"
            @change="setCompanyInfo({itemNm: $event.target.value})"
            :enable="enable"
            :required="enable"
            :errorMessage="{title: '종목', message: '종목를 입력해주세요.'}"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header width="100px">거래은행</i-col-header>
        <i-col>
          <iui-select
            width="150px"
            :p-code="$getConstants('BANK_CODE').code"
            :value="companyInfo.bankCode"
            @change="value => setCompanyInfo({bankCode: value})"
            defaultCd="S"
            :enable="enable"
          />
        </i-col>
        <i-col-header width="80px">계좌번호</i-col-header>
        <i-col>
          <iui-text
            max-length="20"
            :value="companyInfo.depositNo"
            @change="setCompanyInfo({depositNo: $event.target.value})"
            :enable="enable"
          />
        </i-col>
        <i-spacer />
        <i-col-header>예금주</i-col-header>
        <i-col>
          <iui-text
            max-length="50"
            :value="companyInfo.depositor"
            @change="setCompanyInfo({depositor: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>

      <!-- 건설사 -->
      <i-row v-if="frmGb1 || frmGb4">
        <i-col-header colspan="2">
          <div class="mr10">소득세 산출방식</div>
          <iui-radio-group
            name="taxDs"
            :value="companyInfo.taxDs"
            :p-code="$getConstants('TAX_DS').code"
            :enable="false"
            @change="setCompanyInfo({taxDs: $event.target.value})"
          />
        </i-col-header>
        <i-col-header colspan="2">
          <div class="mr10">일 노무비 소수점 관리</div>
          <iui-radio-group
            name="decimalDs"
            :value="companyInfo.decimalDs"
            :p-code="$getConstants('DECIMAL_DS').code"
            :enable="false"
            @change="setCompanyInfo({decimalDs: $event.target.value})"
          />
        </i-col-header>
      </i-row>
      <i-row v-if="frmGb1 || frmGb4">
        <i-col-header colspan="2">
          <div class="mr10">외국인 체류자격에 의한 고용보험 산출</div>
          <iui-radio-group
            name="femployInsurDs"
            :value="companyInfo.femployInsurDs"
            :p-code="$getConstants('F_EMPLOY_INSUR_DS_CD').code"
            :enable="false"
            @change="setCompanyInfo({femployInsurDs: $event.target.value})"
          />
        </i-col-header>
        <i-col-header>
          첨부파일
        </i-col-header>
        <i-col>
          <pmis-file-list
            :company-code="companyInfo.pgmCd"
            id="file0"
            flNoCol="flNo"
            title="파일선택"
            :file-number.sync="companyInfo.flNo"
            :modal="true"
            @delete-complete="onDeleteComplete"
          />
        </i-col>
      </i-row>
      <!-- //건설사 -->

      <!-- 외주||물품자재공급사 -->

      <!-- //외주||물품자재공급사 -->
    </iui-container-new>
    <iui-modal
      name="ceoListPopUp"
      title="공동대표"
      :btns="!companyInfo.pgmCd ? ceoModalBtn : []"
      @beforeClose="beforeClose"
      width="800px"
      height="500px"
    >
      <CeoInfo :enable="enable" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import CeoInfo from '@/view/member/components/CeoInfo.vue';
import {saveCompanyInfo} from '@/view/member/api/member.js';
export default {
  components: {
    CeoInfo,
  },
  props: {
    enable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bsnNo1: '',
      bsnNo2: '',
      bsnNo3: '',
      regiNo1: '',
      regiNo2: '',
      frmGbNm: '',
      ceoModalBtn: [{name: '확인', callback: this.onConfirmCeoModal}],
    };
  },
  computed: {
    frmGb1() {
      return this.companyInfo.frmGb == $getConstants('FRM_GB_1').code; // 건설사
    },
    frmGb2() {
      return this.companyInfo.frmGb == $getConstants('FRM_GB_2').code; // 공사용역
    },
    frmGb3() {
      return this.companyInfo.frmGb == $getConstants('FRM_GB_3').code; // 물품자재
    },
    frmGb4() {
      return this.companyInfo.frmGb == $getConstants('FRM_GB_4').code; // 건설사 + 공사용역
    },
    frmGb9() {
      return this.companyInfo.frmGb == $getConstants('FRM_GB_0').code; // 운영사
    },
  },
  watch: {
    companyInfo: {
      handler(obj) {
        if (obj.frmGb) {
          $getCodeNm($getConstants('FRM_GB').code, obj.frmGb).then(response => {
            this.frmGbNm = response;
          });
        }

        if (obj.bsnNo) {
          this.bsnNo1 = obj.bsnNo.substr(0, 3);
          this.bsnNo2 = obj.bsnNo.substr(3, 2);
          this.bsnNo3 = obj.bsnNo.substr(5, 5);
        }
        if (obj.regiNo) {
          this.regiNo1 = obj.regiNo.substr(0, 6);
          this.regiNo2 = obj.regiNo.substr(6, 7);
        }

        if (!obj.pgmCd) {
          this.frmGbNm = '';
          this.bsnNo1 = '';
          this.bsnNo2 = '';
          this.bsnNo3 = '';
          this.regiNo1 = '';
          this.regiNo2 = '';
        }
      },
      deep: true,
    },
  },
  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'ceoList', 'worker']);
    $mapMutations(this, ['setCompanyInfo', 'setDupBsnNoStatus', 'setCeoList']);
  },
  created() {},
  methods: {
    setBsnNo() {
      this.setDupBsnNoStatus(0);
      this.setCompanyInfo({bsnNo: this.bsnNo1 + this.bsnNo2 + this.bsnNo3});
    },
    setRegiNo() {
      this.setCompanyInfo({regiNo: this.regiNo1 + this.regiNo2});
    },
    ceoListPopUp() {
      this.$modal.show('ceoListPopUp');
    },
    onConfirmCeoModal() {
      this.callEvent({
        name: 'ceoInfo_getSaveJson',
        param: data => {
          this.setCeoList(data);
        },
      });
      this.$modal.hide('ceoListPopUp');
    },
    async beforeClose(e) {
      // 취소버튼
      if (this.companyInfo.pgmCd) {
        let flag = false;
        this.callEvent({
          name: 'ceoInfo_getSaveJson',
          param: data =>
            data.forEach(async el => {
              if (el.status != '') {
                flag = true;
                e.stop();
              }
            }),
        });
        if (flag) {
          const title = '공동대표정보';
          const message = '공동대표정보가 변경되었습니다.<br>저장하시겠습니까?';
          if (await this.$confirm({title, message})) {
            this.callEvent({name: 'ceoInfo_saveCeoList'});
          }
        }
      }
    },
    getAddr(data) {
      this.setCompanyInfo({
        postNo: data.zipNo,
        adr1: `${data.roadAddrPart1} ${data.roadAddrPart2}`,
        adr2: data.addrDetail,
      });
    },
    onDeleteComplete(flNo) {
      if (flNo == 0) {
        this.setCompanyInfo({flNo: 0});
        saveCompanyInfo({...this.companyInfo}, {}).then(response => {
          this.callEvent({name: 'selectCompanyInfo'});
        });
      }
    },
  },
};
</script>

<style></style>
