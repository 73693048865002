export const selectAnyTimeSubSearchData = async () =>
  await axios.post('/closeAnyTimeSub/selectAnyTimeSubSearchData', {});

export const selectPrePayable = async param => await axios.post('/closeAnyTimeSub/selectPrePayable', param);

export const selectSubAnmonList = async param => await axios.post('/closeAnyTimeSub/selectSubAnmonList', param);

export const selectRegisterSubDetail = async param =>
  await axios.post('/closeAnyTimeSub/selectRegisterSubDetail', param);

export const selectRegisterSubInfo = async param => await axios.post('/closeAnyTimeSub/selectRegisterSubInfo', param);

export const deleteSubAnmon = async param => await axios.post('/closeAnyTimeSub/deleteSubAnmon', param);
