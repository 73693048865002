const APPROVAL = {
  //결재상태
  SNC_SS_WAIT: '000000', //결재대기
  SNC_SS_APPROVAL: '000001', //진행문서
  SNC_SS_REJECT: '000002', //반려문서
  SNC_SS_ROLLBACK: '000003', //회수문서
  SNC_SS_COMPLETE: '000004', //완료문서
  SNC_SS_RETURN_SEND: '000005', //반송문서

  //결재종류
  SNC_KD_WAIT: '000001', //결재대기
  SNC_KD_APPROVAL: '000002', //결재승인
  SNC_KD_REJECT: '000003', //결재반려

  //결재선구분
  SNCL_DS_FIX: '000001', //고정결재선
  SNCL_DS_OBS: '000002', //조직도

  //문서분류구분
  DOC_CS_DS_IN_DEP: '000001', //내부문서
  DOC_CS_DS_IN_CMP: '000002', //대내시행문서
  DOC_CS_DS_EXTERNAL: '000003', //대외시행문서

  //결재유형
  SNC_TP_DRAFT: '000001', //기안
  SNC_TP_APPROVAL: '000002', //결재
  SNC_TP_DECIDE: '000003', // 전결
  SNC_TP_COOPERATION: '000004', //협조
  SNC_TP_RECEIPT: '000005', //접수

  //문서 접수 여부
  DOC_ACP_DS_RECEIPT: '000001', //접수

  //내외부구분
  INO_DS_IN: '000001', //내부
  INO_DS_OUT: '000002', //외부

  OFFLINE_Y: '000001',
  FORMATTED_LINE_CNT: 5, // 정형문서 결재선 지정 가능 인원수
};

export default APPROVAL;
