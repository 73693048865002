export default that => {
  return {
    Cfg: {
      CanEdit: 0,
      CanSort: 0,
    },
    Def: {
      Row: {
        CanFocus: 0,
      },
    },
    Cols: [
      {
        Header: '대공종',
        Name: 'headName',
        Align: 'center',
        MinWidth: 140,
      },
      {
        Header: '중공종',
        Name: 'midName',
        Align: 'center',
        MinWidth: 140,
      },
      {
        Header: '내역코드',
        Name: 'itemSeq',
        Align: 'center',
        Width: 80,
      },
      {
        Header: '명칭',
        Name: 'itemName',
        RelWidth: 1,
        MinWidth: 160,
      },
      {
        Header: '규격',
        Name: 'ssize',
        RelWidth: 1,
        MinWidth: 160,
      },
      {
        Header: '단위',
        Name: 'unit',
        Align: 'center',
        Width: 80,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: '수량',
        Name: 'qty',
        Align: 'center',
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 120,
      },
      {
        Header: '단가',
        Name: 'price',
        Align: 'center',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        Width: 120,
      },
      {
        Header: '금액',
        Name: 'amt',
        Align: 'center',
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 120,
      },
      {Name: 'itemCode', Visible: false},
    ],
  };
};
