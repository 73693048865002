<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="500px">
          <GrpAuthList @click="onClickGrpAuthList" @onAdd="onAdd" />
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-content-box>
            <iui-tab :comps="comps" :isAuto="false" @click="onSetTabIndex" @after-active-tab="onAfterActiveTab">
              <template v-slot:tab-right>
                <iui-button value="저장" @click="onSave" v-if="1 < grpAuthInfo.seq || tabIndex === 1" />
              </template>
            </iui-tab>
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import GrpAuthList from './components/GrpAuthList.vue';
import store from './store/grpAuthRegist.js';
import {saveSystemGroupMenuAuth} from './api/grpAuth.js';
import {saveUserGroupR} from '../userAuthRegist/api/userAuth.js';
export default {
  components: {GrpAuthList},

  data() {
    return {
      comps: [
        {
          tabNm: '메뉴정보',
          compNm: 'menuInfo',
          path: '@/view/sysManage/authManage/grpAuthRegist/components/GrpMenuAuthList.vue',
        },
        {
          tabNm: '사용자정보',
          compNm: 'userInfo',
          path: '@/view/sysManage/authManage/grpAuthRegist/components/GrpUserList.vue',
        },
      ],
      tabIndex: 0,
    };
  },

  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['grpAuthInfo']);
    $mapMutations(this, ['setGrpAuthInfo']);
  },

  created() {
    this.canSearch = true;
    this.addFuncSearch(this.onSearch);
    this.setGrpAuthInfo({});
  },

  methods: {
    onCompanyChanged(e) {
      this.onSearch();
    },
    onSearch() {
      this.callEvent({name: 'selectGroupList'});
    },
    onClickGrpAuthList() {
      this.onSetTabIndex(this.tabIndex);
    },
    onSetTabIndex(index) {
      this.tabIndex = index;
      this.callEvent({name: 'setActiveTab', param: this.tabIndex});
    },
    onAfterActiveTab() {
      if (this.tabIndex == 0) this.callEvent({name: 'selectGrpMenuAuthList'});
      if (this.tabIndex == 1) this.callEvent({name: 'selectGrpAuthUserList'});
    },
    onAdd() {
      this.onSetTabIndex(0);
    },
    onSave() {
      if (this.tabIndex == 0) {
        this.saveMenuAuth();
      } else {
        this.saveUserAuth();
      }
    },
    saveMenuAuth() {
      const save = async param => {
        const grpMenuAuthList = param.map(item => {
          return {
            pgmCd: this.pgmCd,
            seq: this.grpAuthInfo.seq,
            mnuCd: item.mnuCd,
            vwYn: item.vwYn,
            svYn: item.svYn,
            delYn: item.delYn,
            prnYn: item.prnYn,
          };
        });

        const response = await saveSystemGroupMenuAuth({grpMenuAuthList});
        if (response.data) {
          this.callEvent({name: 'selectGrpMenuAuthList'});
        }
      };
      this.callEvent({
        name: 'grpMenuAuthSaveJson',
        param: save,
      });
    },
    saveUserAuth() {
      const save = async param => {
        param.forEach(el => {
          el.pgmCd = this.pgmCd;
          el.auth = el.auth == 1 ? true : false;
          el.seq = this.grpAuthInfo.seq;
        });

        const response = await saveUserGroupR({userAuthList: param});
        if (response.data) {
          this.callEvent({name: 'selectGrpAuthUserList'});
        }
      };
      this.callEvent({
        name: 'GrpUserList_getSaveJson',
        param: save,
      });
    },
  },
};
</script>

<style></style>
