export default that => {
  return {
    Cfg: {
      CanEdit: 0,
      HeaderMerge: 4,
      IgnoreFocused: 1,
    },
    Def: {
      Row: {
        CanFocus: false,
      },
    },
    Cols: [
      {
        Header: ['직종', '직종'],
        Name: 'jkdNm',
        FormulaRow: '합계',
        RelWidth: 1,
        Align: 'Center',
      },

      {
        Header: ['금일', {Value: '인원', Color: '#dff9fb'}],
        Name: 'mdWorkerCnt',
        FormulaRow: 'Sum',
        Extend: that.$ibsheetHelper.preset.number,
        RelWidth: 1,
      },
      {
        Header: ['금일', {Value: '공수', Color: '#dff9fb'}],
        Name: 'mdInvestQty',
        FormulaRow: 'Sum',
        Extend: that.$ibsheetHelper.preset.quantity,
        RelWidth: 1,
      },
      {
        Header: ['금일', {Value: '노무비', Color: '#dff9fb'}],
        Name: 'mdAmt',
        FormulaRow: 'Sum',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
      },
      {
        Header: ['금월', {Value: '인원', Color: '#dff9fb'}],
        Name: 'mmWorkerCnt',
        FormulaRow: 'Sum',
        Extend: that.$ibsheetHelper.preset.number,
        RelWidth: 1,
      },
      {
        Header: ['금월', {Value: '공수', Color: '#dff9fb'}],
        Name: 'mmInvestQty',
        FormulaRow: 'Sum',
        Extend: that.$ibsheetHelper.preset.quantity,
        RelWidth: 1,
      },
      {
        Header: ['금월', {Value: '노무비', Color: '#dff9fb'}],
        Name: 'mmAmt',
        FormulaRow: 'Sum',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
      },
    ],
  };
};
