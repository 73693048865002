<template>
  <div id="h_wrap">
    <div id="h_container_join">
      <header id="h_header" class="header_join">
        <div class="inner_l">
          <h1 class="logo"><span class="blind">빌려쓰는 건설정보 솔루션 EASYCON</span></h1>
        </div>
        <div class="inner_r">
          <a href="#" @click.prevent.self class="join ml10" @click="goLogin">로그인</a>
        </div>
      </header>

      <div class="contents_join">
        <ul class="tap_find">
          <li>
            <a href="#" id="idTab" @click="changeTab('id')" @click.prevent.self>아이디 찾기</a>
          </li>
          <li>
            <a href="#" id="pwTab" @click="changeTab('pw')" @click.prevent.self>비밀번호 찾기</a>
          </li>
        </ul>
        <div v-if="!noUser" class="find_box">
          <template v-if="searchType === 'id'">
            <div class="inner_id">
              <template v-if="findIdStep == 1">
                <p class="box_id01">고객님의 회사명을 입력해주세요.</p>
                <p class="box_id02">
                  <v-select
                    ref="vSelect"
                    class="v-select"
                    key="searchId"
                    :options="companyList"
                    placeholder="고객님의 회사명 입력"
                    :clearable="false"
                    :noDrop="noDrop"
                    @search="input_companySelectBox"
                    @option:selected="companySelected"
                  >
                    <template #no-options>
                      일치하는 회사정보가 없습니다.
                    </template>
                  </v-select>
                </p>
              </template>
              <template v-else-if="findIdStep === 2">
                <p class="box_id01">
                  본인인증 절차를 진행중입니다.
                </p>
              </template>
              <template v-else-if="findIdStep == 3">
                <label style="font-size: larger">
                  고객님의 회사코드 :
                  <font style="color:#0080FF; font-weight: bold;">{{ searchParam.pgmCd }}</font>
                </label>
                <br />
                <label style="font-size: larger">
                  고객님의 아이디 :
                  <font style="color:#0080FF; font-weight: bold;">{{ searchParam.empNo }}</font>
                </label>
              </template>
            </div>

            <div class="btn_find01">
              <button class="btn_default" @click="goLogin" @click.prevent.self>로그인으로 이동</button>
              <button
                class="btn_confirm ml10"
                @click="findIdStep == 3 ? changeTab('pw') : onNiceCertify()"
                @click.prevent.self
              >
                {{ findIdStep == 3 ? '비밀번호 찾기' : '다음 단계' }}
              </button>
            </div>
          </template>

          <!-- 비밀번호 찾기 -->
          <template v-else>
            <div class="inner_pw">
              <template v-if="findPwStep == 1">
                <p class="box_pw01">아이디를 입력하시면, 찾을 수 있는 방법을 알려드려요.</p>
                <p class="box_pw02">
                  <v-select
                    ref="vSelect"
                    class="v-select"
                    key="searchPwd"
                    :options="companyList"
                    placeholder="고객님의 회사명 입력"
                    :clearable="false"
                    :noDrop="noDrop"
                    @search="input_companySelectBox"
                    @option:selected="companySelected"
                  >
                    <template #no-options>
                      일치하는 회사정보가 없습니다.
                    </template>
                  </v-select>
                  <input
                    type="text"
                    class="input_txt"
                    v-model="searchParam.empNo"
                    @keyup.enter="onSearchId"
                    placeholder="고객님의 아이디 입력"
                  />
                </p>
              </template>
              <template v-else-if="findPwStep == 2">
                <p class="box_pw01">
                  본인인증 절차를 진행중입니다.
                </p>
              </template>
              <template v-else-if="findPwStep == 3">
                <p class="box_pw01">
                  새로운 비밀번호를 설정합니다.
                </p>
                <p class="box_pw02">
                  <input
                    type="password"
                    class="input_txt"
                    v-model="newPwd1"
                    @keyup="chkCapsLock"
                    maxlength="12"
                    placeholder="새 비밀번호  (최대 12자, 대소문자 구분)"
                  />
                  <!-- <font v-if="isCapsLock" style="color:#0080FF; font-weight: bold;"
                    ><br />Caps Lock이 활성화 되어있습니다.</font
                  > -->
                  <input
                    type="password"
                    class="input_txt"
                    v-model="newPwd2"
                    @keyup="chkCapsLock"
                    maxlength="12"
                    placeholder="새 비밀번호 확인  (최대 12자, 대소문자 구분)"
                  />
                  <font v-if="newPwd2 && newPwd1 != newPwd2" style="color:#FF6666; font-weight: bold;"
                    ><br />비밀번호가 일치하지 않습니다.</font
                  >
                </p>
              </template>
            </div>
            <div class="btn_find02">
              <button class="btn_default" @click="goLogin">로그인으로 이동</button>
              <button
                class="btn_confirm ml10"
                @click="
                  findPwStep == 1 ? onSearchId() : findPwStep == 2 ? onNiceCertify() : findPwStep == 3 ? changePw() : ''
                "
                @click.prevent.self
              >
                {{ findPwStep == 3 ? '비밀번호 변경' : '다음 단계' }}
              </button>
            </div>
          </template>
        </div>
        <!-- find_box end -->
        <div v-else class="find_box">
          <div class="inner_pw" style="padding: 8px 0 0 70px;">
            <label style="font-size: larger">회원정보가 존재하지 않습니다.</label><br />
          </div>
          <div class="btn_find01">
            <button class="btn_default" @click="changeTab('id')">아이디 찾기</button>
            <button class="btn_confirm ml10" @click="changeTab('pw')">비밀번호 찾기</button>
          </div>
        </div>
      </div>
      <!-- contents_join end -->
    </div>
    <!-- h_container_join end -->
    <div id="h_footer_join">
      <div class="copy">COPYRIGHT Ⓒ INOGARD. ALL RIGHTS RESERVED.</div>
    </div>

    <!-- 본인인증 팝업 -->
    <form name="form_chk" method="post" id="form_chk">
      <input type="hidden" name="m" value="checkplusService" />
      <input type="hidden" id="EncodeData" name="EncodeData" />
      <input type="hidden" id="param_r1" name="param_r1" />
      <input type="hidden" id="param_r2" name="param_r2" />
      <input type="hidden" id="param_r3" name="param_r3" />
    </form>
  </div>
  <!-- h_wrap end -->
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import vSelect from 'vue-select'; // https://vue-select.org/
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      certiMethod: 'checkPlus',
      certificationItems: [{label: '본인 명의 휴대전화로 인증', value: 'checkPlus'}],
      searchParam: {
        pgmCd: '',
        empNo: '',
        name: '',
        mobileTlno: '',
        birthday: '',
      },
      findIdStep: 1,
      findPwStep: 1,
      newPwd1: '',
      newPwd2: '',
      noUser: false,
      searchTxt: '',
      isCapsLock: false,
      companyList: [],

      searchType: '',
      URL: `${
        location.protocol.indexOf('https') !== -1 ? process.env.VUE_APP_EXTERNAL_URLS : process.env.VUE_APP_EXTERNAL_URL
      }/external/findIdPw`,
    };
  },
  computed: {
    noDrop() {
      return this.searchTxt.length > 1 ? false : true; // 검색어가 2자 이상일 때 드랍다운
    },
  },
  watch: {
    params: {
      async handler(data) {
        if (data.errorCode != null && data.errorCode != '') {
          this.$alert('본인인증에 실패하였습니다.');
        } else {
          const copyObj = await cloneDeep(this.searchParam);
          this.searchParam.name = data.name;
          this.searchParam.mobileTlno = data.mobileNo;
          this.searchParam.birthday = data.birthday.substr(2);
          if (this.searchType === 'id') {
            this.onSearchId();
          } else if (this.searchType === 'pw') {
            if (copyObj.name == this.searchParam.name && copyObj.birthday == this.searchParam.birthday) {
              this.findPwStep = 3;
            } else {
              this.noUser = true;
            }
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.searchType = this.$route.query.searchType;
    this.initTab(this.searchType);
  },
  methods: {
    initTab(type) {
      document.querySelector(`#${type}Tab`).classList.add('on');
      document.querySelector(`#${type == 'id' ? 'pw' : 'id'}Tab`).classList.remove('on');
    },
    changeTab(type) {
      this.initData();
      this.searchType = type;
      this.initTab(type);
    },
    initData() {
      this.findIdStep = 1;
      this.findPwStep = 1;
      this.newPwd1 = '';
      this.newPwd2 = '';
      this.noUser = false;
      for (let key in this.searchParam) {
        this.searchParam[key] = '';
      }
    },
    onNiceCertify() {
      // Nice 본인인증
      if (this.searchType === 'id') {
        if (!this.searchParam.pgmCd) {
          alert('입력되지 않은 항목이 있습니다.');
          return;
        } else {
          this.findIdStep = 2;
        }
      }
      const param = {
        rnaType: this.certiMethod,
        searchType: this.searchType,
        returnUrl: `${this.URL}/doCertify?frontendUrl=${location.origin}`,
        errorUrl: `${this.URL}/doCertify`,
      };
      window.openUid = this._uid;

      axios.post(`${this.URL}/getEncodeData`, param).then(response => {
        window.open(
          '',
          'popupChk',
          'width=500, height= 500, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
        );
        document.querySelector('#EncodeData').value = response.data.encodeData;
        document.querySelector('#param_r1').value = response.data.searchType;
        document.querySelector('#param_r2').value = response.data.rnaType;
        document.querySelector('#param_r3').value = response.data.requestNumber;
        document.querySelector('#form_chk').action = response.data.niceUrl;
        document.querySelector('#form_chk').target = 'popupChk';
        document.querySelector('#form_chk').submit();
      });
    },
    async onSearchId() {
      if (this.searchType === 'pw' && (!this.searchParam.pgmCd || !this.searchParam.empNo)) {
        alert('입력되지 않은 항목이 있습니다.');
        return;
      }

      const response = await axios.post(`${this.URL}/findId`, this.searchParam);
      if (response.status == 200) {
        if (response.data.empNo) {
          for (let key in this.searchParam) {
            this.searchParam[key] = response.data[key];
          }
          this.findIdStep = 3;
          if (this.searchType == 'pw') {
            this.findPwStep = 2;
            this.onNiceCertify();
          }
        } else {
          this.noUser = true;
        }
      }
    },
    input_companySelectBox(e) {
      // 회사입력
      this.companyList = [];
      if (e.length > 1) {
        this.searchTxt = e;
        axios.post(`${this.URL}/selectCompanyList`, {frmNm: this.searchTxt}).then(response => {
          this.companyList = response.data;
        });
      }
    },
    companySelected(e) {
      this.searchParam.pgmCd = e.code;
    },
    chkCapsLock(e) {
      // 새 비밀번호 입력 시 CapsLock 켜짐 상태
      this.isCapsLock = e.getModifierState('CapsLock') ? true : false;
    },
    async changePw() {
      if (this.newPwd1 != this.newPwd2) {
        alert('비밀번호가 일치하지 않습니다.\n다시 확인해주시기 바랍니다.');
        return;
      }

      if (this.newPwd1 == '' || this.newPwd2 == '') {
        alert('새 비밀번호가 입력되지 않았습니다.');
        return;
      }

      if (!confirm('새로운 비밀번호로 변경하시겠습니까?')) {
        return;
      }

      axios
        .post(`${this.URL}/changePwd`, {...this.searchParam, pwd: this.newPwd1})
        .then(() => {
          alert('비밀번호가 변경되었습니다.');
          this.goLogin();
        })
        .catch(ex => {
          alert('비밀번호 변경을 실패하였습니다.');
          console.error(ex);
        });
    },
    goLogin() {
      this.$router.push({path: '/loginHome', params: {}});
    },
  },
};
</script>

<style scoped></style>
