<template>
  <iui-container-new type="css-flex">
    <i-row height="200px">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>인력투입정보</template>
          <ib-sheet :uid="_uid" :options="Options1" :loadSearchData="loadSearchData1" @loadSheet="loadSheet1" />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row height="230px">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>장비투입정보</template>
          <ib-sheet :uid="_uid" :options="Options2" :loadSearchData="loadSearchData2" @loadSheet="loadSheet2" />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row height="200px">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>직영투입비</template>
          <ib-sheet :uid="_uid" :options="Options3" :loadSearchData="loadSearchData3" />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row height="230px">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>외주투입비</template>
          <ib-sheet :uid="_uid" :options="Options4" :loadSearchData="loadSearchData4" />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row height="230px">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>지시조치내용</template>
          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <ib-sheet :uid="_uid" :options="Options5" :loadSearchData="loadSearchData5" />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import options1 from './sheetOptions/WorkSummaryAllSheetLab.js';
import options2 from './sheetOptions/WorkSummaryAllSheetEquip.js';
import options3 from './sheetOptions/WorkSummaryAllSheetSum.js';
import options4 from './sheetOptions/WorkSummaryAllSheetSub.js';
import options5 from './sheetOptions/WorkSummaryAllSheetOrder.js';
import {selectWorkSummaryAll} from '../api/workdaily.js';

export default {
  data() {
    return {
      Options1: options1(this),
      loadSearchData1: [],
      Options2: options2(this),
      loadSearchData2: [],
      Options3: options3(this),
      loadSearchData3: [],
      Options4: options4(this),
      loadSearchData4: [],
      Options5: options5(this),
      loadSearchData5: [],
      responseData: {
        sheet1: [],
        sheet2: [],
        sheet3: [],
        sheet4: [],
        sheet5: [],
      },
    };
  },
  beforeCreate() {
    $mapGetters(this, {
      searchInfo: 'searchInfo',
      activeResource: 'activeResource',
      summary: 'summary',
    });

    $mapActions(this, ['setSummary', 'initSummary']);
  },
  created() {
    this.addEvent([
      {name: 'WorkSummaryAll_onSearch', func: this.onSearch},
      {
        name: 'WorkSummaryAll_onAmountChange',
        func: this.convertPriceUnit,
      },
    ]);
  },
  methods: {
    loadSheet1(sheet) {
      sheet.setFormulaRowPosition(0);
    },
    loadSheet2(sheet) {
      sheet.setFormulaRowPosition(0);
      sheet.setMergeRange(sheet.FormulaRow, 'equipNm', sheet.FormulaRow, 'size');
    },

    async onSearch(amountUnit) {
      this.init();
      const response = await selectWorkSummaryAll({...this.searchInfo});
      const summary = response.data;
      this.setSummary(summary);
      const summaryList = [];
      summaryList.push({
        rowTypeName: '금일투입',
        materialAmt: summary.materialDayAmt,
        laborAmt: summary.laborDayAmt,
        equipmentAmt: summary.equipmentDayAmt,
        expenseAmt: summary.expenseDayAmt,
        sumAmt: summary.sumDayAmt,
        sumRatePerCont: summary.sumDayRatePerCont,
        sumRatePerExec: summary.sumDayRatePerExec,
      });
      summaryList.push({
        rowTypeName: '금월투입',
        materialAmt: summary.materialMonthAmt,
        laborAmt: summary.laborMonthAmt,
        equipmentAmt: summary.equipmentMonthAmt,
        expenseAmt: summary.expenseMonthAmt,
        sumAmt: summary.sumMonthAmt,
        sumRatePerCont: summary.sumMonthRatePerCont,
        sumRatePerExec: summary.sumMonthRatePerExec,
      });
      summaryList.push({
        rowTypeName: '누계투입',
        materialAmt: summary.materialTotAmt,
        laborAmt: summary.laborTotAmt,
        equipmentAmt: summary.equipmentTotAmt,
        expenseAmt: summary.expenseTotAmt,
        sumAmt: summary.sumTotAmt,
        sumRatePerCont: summary.sumTotRatePerCont,
        sumRatePerExec: summary.sumTotRatePerExec,
      });

      this.responseData.sheet1 = summary.laborInputList ?? [];
      this.responseData.sheet2 = summary.equipInputList ?? [];
      this.responseData.sheet3 = summaryList ?? [];
      this.responseData.sheet4 = summary.workGroupList ?? [];
      this.responseData.sheet5 = summary.workOrderResultList ?? [];

      this.convertPriceUnit(amountUnit);
    },
    init() {
      this.initSummary();
    },
    convertPriceUnit(value) {
      this.loadSearchData1 = $_priceUnitFormat(value, this.responseData.sheet1);
      this.loadSearchData2 = $_priceUnitFormat(value, this.responseData.sheet2);
      this.loadSearchData3 = $_priceUnitFormat(value, this.responseData.sheet3);
      this.loadSearchData4 = $_priceUnitFormat(value, this.responseData.sheet4);
      this.loadSearchData5 = $_priceUnitFormat(value, this.responseData.sheet5);
    },
  },
};
</script>

<style scoped></style>
