<template>
  <pmis-tab-box>
    <template v-slot:title>정산금액</template>
    <p class="bu_title">금월정산금액</p>
    <form onsubmit="return false;">
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header required width="110px">공급가</i-col-header>
          <i-col>
            <iui-text name="oprOamt" type="amount" :value="detailInfo.oprOamt" width="145px" :enable="false" required />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>부가세</i-col-header>
          <i-col>
            <iui-text
              name="oprVat"
              type="amount"
              :value="detailInfo.oprVat"
              @change="setCalculateVatAmt(Number($event.target.value) || 0)"
              width="145px"
              :enable="!closeYn"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>비과세</i-col-header>
          <i-col>
            <iui-text
              name="oprFreeAmt"
              type="amount"
              :value="detailInfo.oprFreeAmt"
              @change="setCalculateTaxexe(Number($event.target.value) || 0)"
              width="145px"
              :enable="!closeYn"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header required>합계</i-col-header>
          <i-col>
            <iui-text name="sumAmt" type="amount" :value="detailInfo.sumAmt" width="145px" :enable="false" readonly />
          </i-col>
        </i-row>
      </iui-container-new>
    </form>

    <iui-modal name="PrintModal" title="출력" size-type="size6">
      <report-viewer :fomNo="fomNo" :ozContent="ozContent" />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import FOM_NO from '@/constants/fomNoConstants.js';
import {selectReportData} from '@/view/closing/fixedTime/expense/api/fixedtimeExpense.js';
import ReportViewer from '@/view/bidding/components/ReportViewer.vue';
export default {
  components: {ReportViewer},
  data() {
    return {
      fomNo: FOM_NO.SETTLEMENT_FIXED_TIME_EXPENSE,
      ozContent: {},
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'detailInfo', 'closeYn']);
    $mapActions(this, ['setCalculateVatAmt', 'setCalculateTaxexe']);
  },
  created() {
    this.addFuncPrint(this.onPrint);
  },
  methods: {
    async onPrint() {
      const investMm = this.searchInfo.investMm;
      const reportData = (await selectReportData({investMm})).data;

      this.ozContent = {
        investMm: `${investMm.substring(0, 4)}년 ${investMm.substring(4)}월`,
        prjNm: this.getPrjInfo().prjNm,
        settlementList: JSON.stringify({settlementList: reportData}),
      };
      this.$modal.show('PrintModal');
    },
  },
};
</script>

<style scoped></style>
