export default {
  '㎝': 'cm',
  '㎠': 'cm2',
  '㎏': 'kg',
  ℓ: 'l',
  '㎡': 'm2',
  '㎥': 'm3',
  '㎝ø': 'cmo',
  '㎝/T': 'cm/T',
  '㎟': 'mm2',
  '㎖': 'ml',
  '㎜': 'mm',
  ø: 'o',
  '㎞': 'km',
};
