<template>
  <pmis-content-box>
    <template #title>참여업체</template>
    <ib-sheet :uid="_uid" :options="sheetOpt" :loadSearchData="rfqCusResultList" />
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/rfqCusResultListSheet.js';
export default {
  beforeCreate() {
    $mapGetters(this, ['rfqCusResultList']);
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
    };
  },
};
</script>

<style></style>
