export default that => {
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      CanEdit: 1, // 1: 전체 편집 가능 (default)
    },
    Cols: [
      {
        Header: '아이디(사번)',
        Name: 'empNo',
        Type: 'Text',
        Align: 'Center',
        Width: 100,
        CanEdit: false,
      },
      {
        Header: '이름',
        Name: 'name',
        Type: '',
        Align: 'Center',
        TextStyle: 4, // 밑줄
        RelWidth: 1,
        CanEdit: false,
        Cursor: 'pointer',
      },
      {
        Header: '부서명',
        Name: 'fldrNm',
        Type: 'Text',
        Align: 'Center',
        RelWidth: 1,
        CanEdit: false,
      },
      {Name: 'pgmCd', Visible: false},
      {Name: 'fldrCd', Visible: false},
      {Name: 'fldrNo', Visible: false},
    ],
  };
};
