export default that => {
  return {
    Cfg: {CanEdit: 0, CanSort: 0},
    Def: {
      Row: {NoColor: 1},
    },
    Cols: [
      {Header: '번호', Name: 'rowNum', Type: 'Text', Align: 'center', Width: 45},
      {Header: '요청제목', Name: 'ttl', Type: 'Text', RelWidth: 1, MinWidth: 180},
      {Header: '요청일', Name: 'prDate', Type: 'Text', Align: 'center', Width: 90},
      {Header: '공사기간', Name: 'constrPeriod', Type: 'Text', Align: 'center', Width: 170},
      {
        Header: '진행상태',
        Name: 'prSt',
        Type: 'Text',
        Align: 'center',
        Width: 100,
        Format: $getConstants('BIDDING_ST').code,
      },
      {
        Header: '결재문서',
        Name: 'sncNo',
        Type: 'Text',
        Align: 'center',
        Width: 70,
        TextColor: '#FF0000',
        TextStyle: 5,
        CursorFormula: fr => (fr.Row.sncNo ? 'pointer' : ''),
      },
      {Name: 'prNo', Visible: false},
    ],
  };
};
