import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 0},
    Def: {Row: {NoColor: 2, CanFocus: 0}},
    Cols: [
      {
        Header: '순위',
        Name: 'rowNum',
        Type: 'Text',
        Align: 'Center',
        Width: 50,
        Formula: fr => (fr.Row['choiceNotiFlag'].includes('3') ? fr.Row[fr.Col] : '***'),
      },
      {
        Header: '업체명',
        Name: 'frmNm',
        Type: 'Text',
        Align: 'Center',
        RelWidth: 2,
        MinWidth: 140,
        Formula: fr => (fr.Row['choiceNotiFlag'].includes('1') ? fr.Row[fr.Col] : '***'),
      },
      {
        Header: '총합계금액\n(공급가액+부가세)',
        Name: 'totAmt',
        Extend: preset.amount,
        RelWidth: 1,
        MinWidth: 130,
        Formula: fr => (fr.Row['choiceNotiFlag'].includes('2') ? fr.Row[fr.Col] : '***'),
        CustomFormat: v => (v.includes('*') ? v : preset.amount.CustomFormat(v)),
      },
      {
        Header: '낙찰여부',
        Name: 'resultYn',
        Type: 'Text',
        Align: 'Center',
        RelWidth: 1,
        MinWidth: 130,
        CustomFormat: v => (v === BIDDING_CONSTANTS.CODES.RESULT_Y ? '낙찰' : ''),
      },
      {Name: 'choiceNotiFlag', DefaultValue: '', Visible: false}, // 정보공개여부 (1:낙찰업체, 2:낙찰금액, 3:순위)
    ],
  };
};
