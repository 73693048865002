import EDMS_CONSTANTS from '@/view/edms/common/EdmsConstatns.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import APPROVAL_CONSTANTS from '@/view/approval/js/approvalConstants.js';

import {selectPrApprovalInfos} from '@/view/bidding/pr/mat/api/pr.js';
import {selectSubPrApprovalInfos} from '@/view/bidding/pr/sub/api/subPr.js';
import {selectRfqApprovalInfos} from '@/view/bidding/rfq/mat/api/rfq.js';
import {selectSubRfqApprovalInfos} from '@/view/bidding/rfq/sub/api/subRfq.js';
import {generateApprovalContent} from '@/view/bidding/po/mat/api/po.js';
import {generateApprovalContent as generateSubApprovalContent} from '@/view/bidding/po/sub/api/subPo.js';
import {selectAppDocInfo} from '@/view/approval/api/appDoc.js';

import ApprovalPop from '@/view/approval/draft/popup/pop.vue';
import ReportViewer from '@/view/bidding/components/ReportViewer.vue';

export default {
  components: {ApprovalPop, ReportViewer},
  data() {
    return {
      fileSaveAuth: false,
      edmsInfo: {
        edmsType: '',
        tl: '',
        taskNo: 0,
        sncNo: 0,
        flNo: 0,
        refNo: undefined,
      },
      ozContent: {},
    };
  },
  methods: {
    /*** 구매요청 품의서 ***/
    openPrApproval() {
      this.edmsInfo.edmsType = 'MAT_PR';

      this.edmsInfo.taskNo = this.prInfo.prNo;
      this.edmsInfo.sncNo = this.prInfo.sncNo;

      this.fileSaveAuth = this.prStatus?.isEnable;

      if (this.prStatus?.isPrReady) {
        this.edmsInfo.tl = `${this.prInfo.ttl} 구매요청 품의서`;
        this.edmsInfo.flNo = this.prInfo.flNo;

        selectPrApprovalInfos(this.prInfo.prNo)
          .then(response => this.onCallApproval(response.data))
          .catch(ex => this.alertErrorMessage(ex));
      } else {
        this.onCallApproval();
      }
    },

    /*** 시행요청 품의서 ***/
    openSubPrApproval() {
      this.edmsInfo.edmsType = 'SUB_PR';
      const prInfo = this.subPrInfo || this.prInfo;

      this.edmsInfo.taskNo = prInfo.prNo;
      this.edmsInfo.sncNo = prInfo.sncNo;

      this.fileSaveAuth = this.subPrStatus?.isEnable;

      if (this.subPrStatus?.isPrReady) {
        this.edmsInfo.tl = `${prInfo.ttl} 시행요청 품의서`;
        this.edmsInfo.flNo = prInfo.flNo;
        selectSubPrApprovalInfos(prInfo.prNo)
          .then(response => this.onCallApproval(response.data))
          .catch(ex => this.alertErrorMessage(ex));
      } else {
        this.onCallApproval();
      }
    },

    /*** 자재 입찰 or 낙찰사선정 품의서 ***/
    openRfqApproval() {
      const rfqStatus = this.rfqStatus;

      this.edmsInfo.edmsType = rfqStatus.isAfterRfqOpen
        ? 'MAT_RFQ_CHOICE'
        : rfqStatus.isPotypeUnit
        ? 'MAT_RFQ_UNIT'
        : 'MAT_RFQ';

      this.edmsInfo.taskNo = this.rfqInfo.rfqNo;
      this.edmsInfo.sncNo = rfqStatus.isAfterRfqOpen ? this.rfqInfo.rfqSncNo2 : this.rfqInfo.rfqSncNo;

      this.fileSaveAuth = this.rfqStatus?.isEnable;

      if (rfqStatus.isRfqReady || rfqStatus.isRfqChoice) {
        const suffix = rfqStatus.isRfqReady ? ' 입찰공고 품의서' : rfqStatus.isRfqChoice ? ' 낙찰사선정 품의서' : '';
        this.edmsInfo.tl = this.rfqInfo.ttl + suffix;
        this.edmsInfo.flNo = rfqStatus.isRfqReady ? this.rfqInfo.flNo : 0;
        selectRfqApprovalInfos(this.rfqInfo.rfqNo)
          .then(response => {
            if (!rfqStatus.isAfterRfqOpen) {
              this.edmsInfo.edmsType = response.data.poTypenm.indexOf('단가') > -1 ? 'MAT_RFQ_UNIT' : 'MAT_RFQ';
            }
            this.onCallApproval(response.data);
          })
          .catch(ex => this.alertErrorMessage(ex));
      } else {
        this.onCallApproval();
      }
    },

    /*** 외주 입찰 or 낙찰사선정 품의서 ***/
    openSubRfqApproval() {
      const rfqStatus = this.rfqStatus;

      this.edmsInfo.edmsType = rfqStatus.isAfterRfqOpen ? 'SUB_RFQ_CHOICE' : 'SUB_RFQ';

      this.edmsInfo.taskNo = this.rfqInfo.rfqNo;
      this.edmsInfo.sncNo = rfqStatus.isAfterRfqOpen ? this.rfqInfo.rfqSncNo2 : this.rfqInfo.rfqSncNo;

      this.fileSaveAuth = this.rfqStatus?.isEnable;

      if (rfqStatus.isRfqReady || rfqStatus.isRfqChoice) {
        const suffix = rfqStatus.isRfqReady ? ' 입찰공고 품의서' : rfqStatus?.isRfqChoice ? ' 낙찰사선정 품의서' : '';
        this.edmsInfo.tl = this.rfqInfo.ttl + suffix;
        this.edmsInfo.flNo = rfqStatus.isRfqReady ? this.rfqInfo.flNo : 0;
        selectSubRfqApprovalInfos(this.rfqInfo.rfqNo)
          .then(response => this.onCallApproval(response.data))
          .catch(ex => this.alertErrorMessage(ex));
      } else {
        this.onCallApproval();
      }
    },

    openRfqApproval2() {
      const isPotypeUnit = this.rfqInfo.isPotypeUnit === BIDDING_CONSTANTS.CODES.POTYPE_UNIT;
      this.edmsInfo.edmsType = isPotypeUnit ? 'MAT_RFQ_UNIT' : 'MAT_RFQ';
      this.edmsInfo.sncNo = this.rfqInfo.rfqSncNo;

      this.fileSaveAuth = this.rfqStatus?.isEnable;

      if (this.rfqStatus?.isRfqReady) {
        this.edmsInfo.tl = this.rfqInfo.ttl;
        this.edmsInfo.flNo = this.rfqInfo.flNo;
        selectRfqApprovalInfos(this.rfqInfo.raqNo)
          .then(response => this.onCallApproval(response.data))
          .catch(ex => this.alertErrorMessage(ex));
      } else {
        this.onCallApproval();
      }
    },

    openCompletedRfqApproval() {
      const edmsType = {
        mat: this.rfqInfo.isPotypeUnit === BIDDING_CONSTANTS.CODES.POTYPE_UNIT ? 'MAT_RFQ_UNIT' : 'MAT_RFQ',
        sub: 'SUB_RFQ',
      };

      this.edmsInfo.edmsType = edmsType[this.bidType];
      this.edmsInfo.sncNo = this.rfqInfo.rfqSncNo;
      this.fileSaveAuth = false;
      this.onCallApproval();
    },

    /*** 자재 계약 품의서 ***/
    async openPoApproval() {
      this.edmsInfo.edmsType = this.poStatus.isFirstContSeq ? 'MAT_PO' : 'MAT_PO_CHANGE';
      this.edmsInfo.taskNo = `${this.poInfo.poNo}-${this.poInfo.contSeq}`;
      this.edmsInfo.sncNo = this.poInfo.poSncNo;

      this.fileSaveAuth = this.poStatus?.isEnable;

      if (this.poStatus.isPoReady) {
        if (this.poStatus.isReturnY) {
          this.edmsInfo.sncNo = 0;
          this.edmsInfo.refNo = (
            await selectAppDocInfo({
              isOffline: false,
              sncNo: this.poInfo.poSncNo,
            })
          )?.data.docNo;
        }
        this.edmsInfo.tl = `${this.poInfo.ttl} 구매 품의서`;
        this.edmsInfo.flNo = this.poInfo.beforeFlNo || this.poInfo.flNo;
        generateApprovalContent(this.poInfo.poNo, this.poInfo.contSeq)
          .then(response => this.onCallApproval(response.data))
          .catch(ex => this.alertErrorMessage(ex));
      } else {
        this.onCallApproval();
      }
    },

    /*** 외주 계약 품의서 ***/
    async openSubPoApproval() {
      this.edmsInfo.edmsType = this.poStatus.isFirstContSeq ? 'SUB_PO' : 'SUB_PO_CHANGE';
      this.edmsInfo.taskNo = `${this.poInfo.poNo}-${this.poInfo.contSeq}`;
      this.edmsInfo.sncNo = this.poInfo.poSncNo;

      this.fileSaveAuth = this.poStatus?.isEnable;

      if (this.poStatus.isPoReady) {
        if (this.poStatus.isReturnY) {
          this.edmsInfo.sncNo = 0;
          this.edmsInfo.refNo = (
            await selectAppDocInfo({
              isOffline: false,
              sncNo: this.poInfo.poSncNo,
            })
          )?.data.docNo;
        }
        this.edmsInfo.tl = `${this.poInfo.ttl} 계약 품의서`;
        this.edmsInfo.flNo = this.poInfo.beforeFlNo || this.poInfo.flNo;
        generateSubApprovalContent(this.poInfo.poNo, this.poInfo.contSeq)
          .then(response => this.onCallApproval(response.data))
          .catch(ex => this.alertErrorMessage(ex));
      } else {
        this.onCallApproval();
      }
    },

    async onCallApproval(excuteExternal = {}) {
      const [fbsCd, fbsNo, fomNo, taskCd] = Object.values(EDMS_CONSTANTS[this.edmsInfo.edmsType]);

      let fileList = [];
      if (this.edmsInfo.flNo) {
        const response = await axios.post('/file/selectFileList', {
          pgmCd: this.pgmCd,
          flNo: this.edmsInfo.flNo,
          flDs: $getConstants('FL_DS_IMAGE').code,
        });
        if (response.data.length > 0) {
          fileList = response.data.map(x => {
            const obj = {...x};
            obj.flNo = 0;
            obj.lclFlNmTemp = obj.lclFlNm;
            return obj;
          });
        }
      }

      this.callEvent({
        name: `draft_pop${this._uid}`,
        param: {
          pgmCd: this.pgmCd,
          frmNm: this.frmNm,
          signCnt: APPROVAL_CONSTANTS.FORMATTED_LINE_CNT,
          isMulti: false, // 결재선 여러 행 표현
          userEmpNo: this.userInfo.empNo,
          userDepNo: this.userInfo.blnDepNo,
          userDepNm: this.userInfo.blnDepNm,
          taskNo: this.edmsInfo.taskNo || 0,
          sncNo: this.edmsInfo.sncNo || 0,
          tl: this.edmsInfo.tl || '',
          refNo: this.edmsInfo.refNo,
          taskCd,
          fbsNo,
          fbsCd,
          fomNo,
          docAttrDs: '',
          fileSaveAuth: this.fileSaveAuth,
          excuteExternal,
          canToggle: true,
          withoutLine: true,
          fileList: fileList,
        },
      });
    },
    alertErrorMessage(ex) {
      if (ex.pmisError) {
        let msg = ex.message;
        if (ex.description) {
          msg += `<br/>- ${ex.description}`;
        }
        this.$alert({title: ex.title, message: msg});
      }
    },
  },
};
