<template>
  <iui-container-new type="css-flex">
    <i-row height="160px">
      <i-col>
        <pmis-content-box>
          <template #title>보증금액 계산기</template>
          <iui-container-new type="table" theme="bullet">
            <i-row>
              <i-col>- 아래의 해당하는 부분을 선택 후 입력하시면 보증금액이 자동으로 계산됩니다.</i-col>
            </i-row>
            <i-row>
              <i-col>- 일자수로 계산하실 경우, 일자를 개월수로 변경하여 소수점으로 입력하시면 됩니다. (ex. 371일 = 12.4개월, 68일 = 2.3개월)</i-col>
            </i-row>
            <i-row>
              <i-col>- 산출된 금액은 실제 보증기관에서 발급하는 보증금액과 다를 수 있으니, 참고용으로만 활용하시기 바랍니다.</i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row height="120px">
      <i-col>
        <pmis-content-box>
          <template #title>공사기간이 4개월 이하인 경우</template>
          <iui-container-new type="table" theme="bullet">
            <i-row>
              <i-col width="60px">보증금액 :</i-col>
              <i-col>
                <iui-text type="amount" width="220px" :value.sync="contractAmt1" prefix="하도급 계약금액" />
                <div>-</div>
                <iui-text type="amount" width="170px" :value.sync="prepayAmt1" prefix="선급금" />
                <div>=</div>
                <iui-text type="amount" width="130px" :value="scpayAmt1" :enable="false" />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row height="130px">
      <i-col>
        <pmis-content-box>
          <template #title>공사기간이 4개월을 초과하며, 기성부분에 대한 대가의 지급 주기가 2개월 이내인 경우</template>
          <iui-container-new type="table" theme="bullet">
            <i-row>
              <i-col width="60px">보증금액 :</i-col>
              <i-col>
                <div>{</div>
                <iui-text type="amount" width="220px" :value.sync="contractAmt2" prefix="(하도급계약금액"/>
                <div>-</div>
                <iui-text type="amount" width="170px" :value.sync="prepayAmt2" prefix="선급금"/>
                <div>) / (</div>
                <iui-text type="number" width="100px" :value.sync="constPeriod2" prefix="공사기간"/>
                <div>개월)</div>
                <div>}</div>
              </i-col>
            </i-row>
            <i-row>
              <i-col>&nbsp;</i-col>
              <i-col>
                <div>x 4 =</div>
                <iui-text type="amount" width="130px" :value="scpayAmt2" :enable="false" />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box>
          <template #title>공사기간이 4개월을 초과하며, 기성부분에 대한 대가의 지급 주기가 2개월을 초과하는 경우</template>
          <iui-container-new type="table" theme="bullet">
            <i-row>
              <i-col width="60px">보증금액 :</i-col>
              <i-col>
                <div>{</div>
                <iui-text type="amount" width="220px" :value.sync="contractAmt3" prefix="(하도급계약금액"/>
                <div>-</div>
                <iui-text type="amount" width="170px" :value.sync="prepayAmt3" prefix="선급금"/>
                <div>) / (</div>
                <iui-text type="number" width="100px" :value.sync="constPeriod3" prefix="공사기간"/>
                <div>개월)</div>
                <div>}</div>
              </i-col>
            </i-row>
            <i-row>
              <i-col>&nbsp;</i-col>
              <i-col>
                <div>x</div>
                <iui-text type="number" width="200px" :value.sync="payPeriod3" prefix="기성부분에 대한 지급주기" />
                <div>개월 x 2 =</div>
                <iui-text type="amount" width="130px" :value="scpayAmt3" :enable="false" />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
export default {
  data() {
    return {
      contractAmt1: '',
      prepayAmt1: '',

      contractAmt2: '',
      prepayAmt2: '',
      constPeriod2: '',

      contractAmt3: '',
      prepayAmt3: '',
      constPeriod3: '',
      payPeriod3: '',
    };
  },
  computed:{
    scpayAmt1() {
      const isValid = !!this.contractAmt1 && !!this.prepayAmt1;
      return isValid ? this.contractAmt1 - this.prepayAmt1 : '';
    },
    scpayAmt2() {
      const isValid = !!this.contractAmt2 && !!this.prepayAmt2 && !!this.constPeriod2;
      return isValid ? (this.contractAmt2 - this.prepayAmt2) / this.constPeriod2 * 4 : '';
    },
    scpayAmt3() {
      const isValid = !!this.contractAmt3 && !!this.prepayAmt3 && !!this.constPeriod3 && !!this.payPeriod3;
      return isValid ? ((this.contractAmt3 - this.prepayAmt3) / this.constPeriod3) * this.payPeriod3 * 2 : '';
    }
  },
};
</script>

<style></style>
