<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-datepicker
        name="instDtFrom"
        label="지시기간"
        :group="{grpNm: 'instDt', seq: 1}"
        :value="searchInfo.instDtFrom"
        :bindArray="bindArray"
        :suffix="'~'"
        @change="onSearch"
      />
      <iui-datepicker
        name="instDtTo"
        :group="{grpNm: 'instDt', seq: 2}"
        :value="searchInfo.instDtTo"
        :bindArray="bindArray"
        @change="onSearch"
      />
      <span style="width:50px"></span>
      <label>완료구분</label>
      <iui-radio-group
        name="compSe"
        id="compSe"
        :items="radioItems"
        :value="searchInfo.compSe"
        :bindArray="bindArray"
        @change="onRadioChange"
      ></iui-radio-group>
    </template>
    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="loadSearchData"
      @loadSheet="sheet_loadSheet"
      :events="{onDataLoad: sheet_onDataLoad, onClick: sheet_onClick}"
    />
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOption/workOrderSttusList.js';
import {selectWorkOrderList} from '@/view/Cooperation/Order/WorkOrder/api/workOrder.js';

export default {
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'focuskeycode']);
    $mapMutations(this, ['setSearchInfo', 'setDetailInfo', 'initDetailInfo', 'initFocuskeycode']);
  },
  created() {
    this.addEvent([{name: 'WorkOrderSttusList_onSearch', func: this.onSearch}]);
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],

      radioItems: [
        {label: '전체', value: '', isChecked: true},
        {label: '조치완료', value: '1', isChecked: false},
        {label: '미완료', value: '0', isChecked: false},
      ],
      bindArray: ['setSearchInfo'],
    };
  },
  methods: {
    sheet_loadSheet(sheet) {
      this.sheet = sheet;
    },
    sheet_onDataLoad(e) {
      this.setData(e);
    },
    sheet_onClick(e) {
      this.setData(e);
    },
    async onSearch() {
      this.initDetailInfo();
      this.sheet.removeAll();

      const response = await selectWorkOrderList({...this.searchInfo});
      if (response.data.length > 0) {
        this.loadSearchData = response.data;
      }
    },
    onRadioChange(e) {
      this.setSearchInfo({compSe: e.target.value});
      this.initFocuskeycode();
      this.onSearch();
    },
    setData(e) {
      let row;
      if (e.eventName === 'onDataLoad') {
        row = e.sheet.getFirstRow();
      } else if (e.eventName === 'onClick') {
        if (e.row.Kind == 'Data') {
          row = e.row;
        }
      }
      this.setDetailInfo({...row, canEditInstructions: !row.actionDate});
    },
  },
};
</script>

<style></style>
