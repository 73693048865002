import {selectPxcondDetailInfo} from '@/view/pxcondRqestManage/pxcondDtlsRegist/api/pxcondDtlsRegist.js';
const state = {
  orderNm: '',
  maxReqYm: '',
  maxReqCnt: 0,
  pxcondInfo: {
    pgmCd: '',
    prjCd: '',
    reqCnt: '',
    reqYm: '',
    reqDs: '',
    reqInspectDate: '',
    reqStrDate: '',
    reqEndDate: '',
    wamt: '',
    wvatAmt: '',
    wtaxexe: '',
    wsumAmt: '',
    pamt: '',
    pvatAmt: '',
    ptaxexe: '',
    psumAmt: '',
    ramt: '',
    rvatAmt: '',
    rtaxexe: '',
    rsumAmt: '',
    rmk: '',
    billNo: '',
    taxexeNo: '',
    flNo1: 0,
    flNo2: 0,
    rgprId: '',

    reqCntTemp: '',
    reqYmTemp: '',

    isExistCollect: false, // 수금여부
    isExistBill: false, // 세금계산서 존재여부
    isExistTaxexe: false, // 계산서 존재여부
    isSignBill: false,
    isSignTaxexe: false,
    expected: '000002',
  },
  cumulativePrepaymentInfo: [], // 누계기성정보
  prepaidPaymentsSettlementInfo: [], // 선급금정산정보

  pxcondDetailList: [],
  prepaymentInfo: {
    // 선급금 잔여금액
    remainPamt: '',
    remainPvatAmt: '',
    remainPtaxexe: '',
    remainPsumAmt: '',
  },
  isNew: false, // 신규여부
  isLastReqCnt: false, // 마지막 기성횟수 여부
  tabIndex: 0,
};

const getters = {
  orderNm: state => state.orderNm,
  maxReqYm: state => state.maxReqYm,
  maxReqCnt: state => state.maxReqCnt,
  pxcondInfo: state => state.pxcondInfo,
  cumulativePrepaymentInfo: state => state.cumulativePrepaymentInfo,
  prepaidPaymentsSettlementInfo: state => state.prepaidPaymentsSettlementInfo,
  pxcondDetailList: state => state.pxcondDetailList,
  prepaymentInfo: state => state.prepaymentInfo,
  status: state => getStatus(state),
};

const mutations = {
  setOrderNm(state, payload) {
    state.orderNm = payload;
  },
  setMaxReqYm(state, payload) {
    state.maxReqYm = payload;
  },
  setMaxReqCnt(state, payload) {
    state.maxReqCnt = payload;
  },
  setPxcondInfo(state, payload) {
    for (let key in state.pxcondInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        const value = payload[key];

        if (key == 'reqCnt') {
          const reqCnt = Number(value);
          state.pxcondInfo.reqCnt = reqCnt;
          state.pxcondInfo.reqCntTemp = 0 < reqCnt ? reqCnt : '자동';
        } else if (value && key == 'reqYm') {
          const reqYm = String(value);
          state.pxcondInfo.reqYm = reqYm;
          state.pxcondInfo.reqYmTemp = `${reqYm.substr(0, 4)}-${reqYm.substr(4, 2)}`;
        } else {
          state.pxcondInfo[key] = value;
        }
      }
    }

    const reqCnt = state.pxcondInfo.reqCnt;
    const maxReqCnt = state.maxReqCnt;
    state.isLastReqCnt = Number(reqCnt) == Number(maxReqCnt);
  },
  initPxcondInfo(state) {
    for (let key in state.pxcondInfo) {
      if (['flNo1', 'flNo2'].indexOf(key) != -1) {
        state.pxcondInfo[key] = 0;
      } else if (['isExistCollect', 'isExistBill', 'isExistTaxexe', 'isSignBill', 'isSignTaxexe'].includes(key)) {
        state.pxcondInfo[key] = false;
      } else if ('expected' === key) {
        state.pxcondInfo[key] = '000002';
      } else {
        state.pxcondInfo[key] = '';
      }
    }
    state.isLastReqCnt = false;
  },
  setCumulativePrepaymentInfo(state, payload) {
    if (Array.isArray(payload)) {
      state.cumulativePrepaymentInfo = payload;
    }
  },
  setPrepaidPaymentsSettlementInfo(state, payload) {
    if (Array.isArray(payload)) {
      state.prepaidPaymentsSettlementInfo = payload;
    }
  },
  setPxcondDetailList(state, payload) {
    if (Array.isArray(payload)) {
      state.pxcondDetailList = payload;
    }
  },
  setIsNew(state, payload) {
    state.isNew = payload;
  },
  initAllPxcondAmt(state) {
    state.pxcondInfo.wamt = '';
    state.pxcondInfo.wvatAmt = '';
    state.pxcondInfo.wtaxexe = '';
    state.pxcondInfo.wsumAmt = '';

    state.pxcondInfo.pamt = '';
    state.pxcondInfo.pvatAmt = '';
    state.pxcondInfo.ptaxexe = '';
    state.pxcondInfo.psumAmt = '';

    state.pxcondInfo.ramt = '';
    state.pxcondInfo.rvatAmt = '';
    state.pxcondInfo.rtaxexe = '';
    state.pxcondInfo.rsumAmt = '';
  },
  setPrepaymentInfo(state, payload) {
    state.prepaymentInfo.remainPamt = payload.remainPamt || 0;
    state.prepaymentInfo.remainPvatAmt = payload.remainPvatAmt || 0;
    state.prepaymentInfo.remainPtaxexe = payload.remainPtaxexe || 0;
    state.prepaymentInfo.remainPsumAmt = payload.remainPsumAmt || 0;
  },
  setTabIndex(state, payload) {
    state.tabIndex = payload;
  },
};

const actions = {
  init(context) {
    context.commit('setIsNew', false);
    context.commit('initPxcondInfo');
    context.commit('setCumulativePrepaymentInfo', []);
    context.commit('setPrepaidPaymentsSettlementInfo', []);
    context.commit('setPxcondDetailList', []);
  },
  async searchPxcondInfos(context, param) {
    context.dispatch('init');

    const response = await selectPxcondDetailInfo(param);
    context.commit('setPxcondInfo', response.data);
    context.commit('setCumulativePrepaymentInfo', response.data.pxcondDetailList);
    context.commit('setPrepaidPaymentsSettlementInfo', response.data.pxcondPrepaymentList);
    context.commit('setPxcondDetailList', response.data.pxcondDetailDtlsList);
    const prepaymentInfo = response.data.pxcondPrepaymentList[4];
    const payload = {
      remainPamt: prepaymentInfo.pamt,
      remainPvatAmt: prepaymentInfo.pvatAmt,
      remainPtaxexe: prepaymentInfo.ptaxexe,
      remainPsumAmt: prepaymentInfo.psumAmt,
    };
    context.commit('setPrepaymentInfo', payload);
  },
};

function getStatus(state) {
  const pxcondInfo = state.pxcondInfo;

  const isExistCollect = pxcondInfo.isExistCollect;
  const isExistBill = pxcondInfo.isExistBill;
  const isExistTaxexe = pxcondInfo.isExistTaxexe;
  const isExistBillFlNo = 0 < pxcondInfo.flNo1; // 세금계산서 첨부파일 존재여부
  const isExistTaxexeFlNo = 0 < pxcondInfo.flNo2; // 계산서 첨부파일 존재여부

  return {
    isNew: state.isNew,
    tabIndex: state.tabIndex,
    isPxcond: pxcondInfo.reqDs === $getConstants('REQ_DS_PXCOND').code, // 기성금
    isPrepayment: pxcondInfo.reqDs === $getConstants('REQ_DS_PREPAY').code, // 선급금
    isEnable: state.isNew || (state.isLastReqCnt && !isExistCollect && !isExistBill && !isExistTaxexe),
    isLastReqCnt: state.isLastReqCnt,
    isExistCollect,
    isExistBill,
    isExistTaxexe,
    isExistBillFlNo,
    isExistTaxexeFlNo,
  };
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
