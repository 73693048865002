<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row height="180px">
        <i-col>
          <pmis-content-box>
            <template #title>서버인증서 등록</template>
            <iui-container-new type="css-flex">
              <i-row>
                <i-col width="600px">
                  <iui-container-new type="table" theme="bullet" header-width="220px">
                    <i-row>
                      <i-col-header>서명용 공개키 (signCert.der 파일)</i-col-header>
                      <i-col>
                        <iui-file
                          accept=".der"
                          :save.sync="save1"
                          :changed.sync="changed1"
                          :clear.sync="clear1"
                          @save-complete="onSaveFileComplete"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>서명용 개인키 (signPri.key 파일)</i-col-header>
                      <i-col>
                        <iui-file
                          accept=".key"
                          :save.sync="save2"
                          :changed.sync="changed2"
                          :clear.sync="clear2"
                          @save-complete="onSaveFileComplete"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>암호화 공개키 (kmCert.der 파일)</i-col-header>
                      <i-col>
                        <iui-file
                          accept=".der"
                          :save.sync="save3"
                          :changed.sync="changed3"
                          :clear.sync="clear3"
                          @save-complete="onSaveFileComplete"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>암호화 개인키 (kmPri.key 파일)</i-col-header>
                      <i-col>
                        <iui-file
                          accept=".key"
                          :save.sync="save4"
                          :changed.sync="changed4"
                          :clear.sync="clear4"
                          @save-complete="onSaveFileComplete"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>인증서 비밀번호</i-col-header>
                      <i-col><iui-text type="password" :value.sync="password" max-length="50"/></i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template #title>서버인증서 목록</template>
            <ib-sheet
              :uid="_uid"
              :options="sheetOpt"
              :loadSearchData="loadSearchData"
              @loadSheet="sheet = $event"
              :events="{onRenderFirstFinish}"
            />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import {
  selectCertificateList,
  insertCertificateList,
  selectUsedCertificateCount,
  deleteCertificateInfo,
} from '@/view/sysManage/baseSysManage/certificateRegist/api/certificate.js';
import sheetOpt from '@/view/sysManage/baseSysManage/certificateRegist/sheetOpt/certificate.js';
export default {
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],

      seq: 0,

      save1: false,
      save2: false,
      save3: false,
      save4: false,

      changed1: false,
      changed2: false,
      changed3: false,
      changed4: false,

      clear1: false,
      clear2: false,
      clear3: false,
      clear4: false,

      flNo1: 0,
      flNo2: 0,
      flNo3: 0,
      flNo4: 0,
      password: '',
    };
  },
  created() {
    this.addFuncSearch(this.onSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onPreSave);
    this.addFuncDelete(this.onDelete);
  },
  methods: {
    onSearch() {
      this.onInit();
      selectCertificateList().then(response => this.sheet.loadSearchData(response.data));
    },
    onAdd() {
      this.onInit();
    },
    async onPreSave() {
      if (!this.changed1) {
        this.$alert({
          title: '서버인증서 저장',
          message: '서명용 공개키 파일을 등록해야 합니다.</span>',
        });
        return;
      }
      if (!this.changed2) {
        this.$alert({
          title: '서버인증서 저장',
          message: '서명용 개인키 파일을 등록해야 합니다.</span>',
        });
        return;
      }
      if (!this.changed3) {
        this.$alert({
          title: '서버인증서 저장',
          message: '암호화 공개키 파일을 등록해야 합니다.</span>',
        });
        return;
      }
      if (!this.changed4) {
        this.$alert({
          title: '서버인증서 저장',
          message: '암호화 개인키 파일을 등록해야 합니다.</span>',
        });
        return;
      }
      if (0 == this.password.trim().length) {
        this.$alert({title: '서버인증서 저장', message: '인증서 비밀번호를 입력해야 합니다.'});
        return;
      }
      if (await this.$confirm({title: '서버인증서 저장', message: '서버인증서를 저장하시겠습니까?'})) {
        this.callEvent({name: 'showLoading'});
        this.onSaveFile();
      }
    },
    onSaveFile() {
      if (4 < this.seq) {
        this.seq = 1;
        this.onSave(); // 파일저장 완료 후 데이터 저장
        return;
      }

      this.seq += 1;

      if (this[`changed${this.seq}`]) {
        this[`save${this.seq}`] = true; // 파일저장 callback => onSaveFileComplete
      } else {
        this.onSaveFile();
      }
    },
    onSaveFileComplete(flNo) {
      this[`flNo${this.seq}`] = flNo;
      this.onSaveFile();
    },
    async onSave() {
      const param = {
        signCertDerFlNo: this.flNo1,
        signCertKeyFlNo: this.flNo2,
        kmCertDerFlNo: this.flNo3,
        kmCertKeyFlNo: this.flNo4,
        password: this.password,
      };

      insertCertificateList(param)
        .then(response => {
          this.callEvent({name: 'hideLoading'});
          if (response.status == 200) {
            this.onInit();
            this.onSearch();
          }
        })
        .catch(ex => {
          this.callEvent({name: 'hideLoading'});
          this.$alert({title: '서버인증서 저장', message: '인증서 저장을 실패했습니다.<br/>' + ex});
        });
    },
    onInit() {
      this.seq = 0;
      this.clear1 = true;
      this.clear2 = true;
      this.clear3 = true;
      this.clear4 = true;
      this.flNo1 = 0;
      this.flNo2 = 0;
      this.flNo3 = 0;
      this.flNo4 = 0;
      this.password = '';
    },
    async onDelete() {
      const title = '서버인증서 삭제';

      if (0 == this.sheet.getTotalRowCount()) {
        this.$alert({title, message: '삭제할 서버인증서 정보가 존재하지 않습니다.'});
        return;
      }

      const checkedRows = this.sheet.getRowsByChecked('cbx');
      if (0 == checkedRows.length) {
        this.$alert({title, message: '삭제할 서버인증서를 선택해야 합니다.'});
        return;
      }

      // 사용중인 인증서 체크
      const certNo = this.sheet.getValue(checkedRows[0], 'certNo');
      const response1 = await selectUsedCertificateCount(certNo);
      if (0 < response1.data) {
        this.$alert({title, message: '이미 사용중인 서버인증서입니다.<br/>삭제할 수 없습니다.'});
        return;
      }
      const message = `서버인증서를 삭제하시겠습니까?<br/><span style="font-size:10pt;">[인증서 번호 : ${certNo}]</span>`;

      if (!(await this.$confirm(title, message))) {
        return;
      }

      deleteCertificateInfo(certNo)
        .then(response => {
          if (response.status == 200) {
            this.onSearch();
          }
        })
        .catch(ex => this.$alert({title, message: '인증서 삭제를 실패했습니다.<br/>' + ex}));
    },
    onRenderFirstFinish(e) {
      e.sheet.setAutoMerge({headerMerge: 5});
      this.onSearch();
    },
  },
};
</script>

<style scoped></style>
