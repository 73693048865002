export default that => {
  return {
    Cfg: {CanEdit: 0},
    Cols: [
      {Header: '현장코드', Name: 'prjEstCd', Type: 'Text', Width: 70, Align: 'Center', Visible: 1},
      {Header: '현장명', Name: 'prjEstNm', Type: 'Text', RelWidth: 1, Visible: 1},
      {
        Header: '입찰결과',
        Name: 'bidStat',
        Type: 'Text',
        Width: 90,
        Visible: 1,
        Format: $getConstants('BID_STAT').code,
      },
    ],
  };
};
