export default that => {
  let OnChangeCol = '';
  return {
    Cfg: {
      CanEdit: 1,
      HeaderMerge: 6,
      HeaderCheck: 1,
      SpaceForDefaultValue: 1,
    },
    Cols: [
      {
        Header: ['가동일자', '가동일자'],
        Name: 'runDt',
        Extend: that.$ibsheetHelper.preset.date,
        Width: 100,
        CanEdit: false,
        Align: 'center',
        VAlign: 'Top',
      },
      {
        Header: ['장비투입정보', '순번'],
        Name: 'seq',
        Type: 'Text',
        Width: 50,
        Align: 'center',
        VAlign: 'Top',
        CanEdit: false,
      },
      {
        Header: ['장비투입정보', '장비코드'],
        Name: 'equipCode',
        Type: 'Text',
        Width: 140,
        CanEdit: false,
        Align: 'center',
        VAlign: 'Top',
      },
      {
        Header: ['장비투입정보', '장비명'],
        Name: 'equipName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
        CanEdit: false,
        VAlign: 'Top',
      },
      {
        Header: ['장비투입정보', '차량번호'],
        Name: 'carNo',
        Type: 'Text',
        Width: 100,
        CanEdit: false,
        VAlign: 'Top',
      },
      {
        Header: ['장비투입정보', '사용구분'],
        Name: 'useGb',
        Type: 'Text',
        Width: 90,
        Align: 'center',
        VAlign: 'Top',
        CanEdit: false,
        Format: $getConstants('USE_GB').code,
      },
      {
        Header: ['유류투입정보', '순번'],
        Name: 'seqNo',
        Type: 'Text',
        Width: 50,
        Align: 'center',
        CanEdit: false,
      },
      {
        Header: ['유류투입정보', '유종'],
        Name: 'useOil',
        Type: 'Enum',
        Width: 100,
        CanEdit: true,
        Align: 'Center',
        EnumKeys: $getConstants('USE_OIL').code,
        Enum: $getConstants('USE_OIL').code,
      },
      {
        Header: ['유류투입정보', '유량'],
        Name: 'oilQty',
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 80,
        ColorFormula: e => (!e.Row.closeYn ? '#E0E0F8' : ''), // 마감시 수정불가
        CanEditFormula: e => !e.Row.closeYn, // 마감시 수정불가
      },
      {
        Header: ['유류투입정보', '단가'],
        Name: 'oilPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        Width: 80,
        ColorFormula: e => (!e.Row.closeYn ? '#E0E0F8' : ''), // 마감시 수정불가
        CanEditFormula: e => !e.Row.closeYn, // 마감시 수정불가
      },
      {
        Header: ['유류투입정보', '금액'],
        Name: 'oilAmt',
        Type: 'Text',
        Width: 100,
        Extend: that.$ibsheetHelper.preset.amount,
        ColorFormula: e => (!e.Row.closeYn ? '#E0E0F8' : ''), // 마감시 수정불가
        CanEditFormula: e => !e.Row.closeYn, // 마감시 수정불가
      },
      {
        Header: ['유류투입정보', '주유업체'],
        Name: 'oilFrmNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
        CanEdit: false,
        ColorFormula: e => (!e.Row.closeYn ? '#E0E0F8' : ''), // 마감시 수정불가
        ButtonFormula: e => (!e.Row.closeYn ? 'Html' : ''),
        ButtonText: '<input type="button" class="i_search" />',
        WidthPad: 40,
        OnClickSide: () => that.onShowCustomerModal(2),
      },
      {
        Header: ['유류투입정보', '추가/삭제'],
        Name: 'add',
        TypeFormula: e => (!e.Row.closeYn ? 'Button' : 'Text'),
        Width: 50,
        CanEdit: false,
        Button: '',
        ButtonText: '<input type="button" class="i_plus" />',
        OnClick: that.oilAdd,
      },
      {
        Header: ['유류투입정보', '추가/삭제'],
        Name: 'remove',
        TypeFormula: e => (!e.Row.closeYn ? 'Button' : 'Text'),
        Width: 50,
        CanEdit: false,
        Button: '',
        ButtonText: '<input type="button" class="i_minus" />',
        OnClick: that.oilRemove,
      },
      {Name: 'oilFrmNo', Visible: false},
      {Name: 'oilDeductDs', Visible: false},
      {Name: 'closeYn', Visible: false},
      {Name: 'addYn', Visible: false, DefaultValue: 'N'},
      {Name: 'wcode', Visible: false},
      {Name: 'headCode', Visible: false},
      {Name: 'midCode', Visible: false},
      {Name: 'itemSeq', Visible: false},
    ],
  };
};
