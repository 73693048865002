<template>
  <pmis-tab-box>
    <template #title>상세정보</template>
    <form onsubmit="return false;">
      <iui-container-new type="css-flex">
        <i-row>
          <i-col min-width="500px">
            <iui-container-new type="table" theme="bullet">
              <colgroup>
                <col style="width:80px" />
                <col />
                <col style="width:10px" />
                <col style="width:70px" />
                <col />
              </colgroup>
              <i-row>
                <i-col-header required>자료번호</i-col-header>
                <i-col>
                  <iui-text
                    :value="detailInfo.docNoTl"
                    :enable="!isStandard"
                    required
                    max-length="100"
                    :errorMessage="{title: '필수입력', message: '자료번호를 입력해야 합니다.'}"
                    @change="onSetDetailInfo({docNoTl: $event.target.value})"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>자료명칭</i-col-header>
                <i-col colspan="4">
                  <iui-text
                    :value="detailInfo.tl"
                    :enable="!isStandard"
                    required
                    max-length="150"
                    :errorMessage="{title: '필수입력', message: '자료명칭을 입력해야 합니다.'}"
                    @change="onSetDetailInfo({tl: $event.target.value})"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>메모</i-col-header>
                <i-col colspan="4" rowspan="2">
                  <iui-text
                    type="multi"
                    :value="detailInfo.rmk"
                    max-length="4000"
                    :enable="!isStandard"
                    @change="onSetDetailInfo({rmk: $event.target.value})"
                  />
                </i-col>
              </i-row>
              <i-row><i-col>&nbsp;</i-col></i-row>
              <i-row>
                <i-col-header>자료분류</i-col-header>
                <i-col colspan="4">
                  <iui-text width="100px" :value="detailInfo.fbsCd" :enable="false" />
                  <iui-text :value="detailInfo.fbsNm" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>등록자</i-col-header>
                <i-col>
                  <iui-text :value="detailInfo.rgprNm" :enable="false" />
                </i-col>
                <i-spacer />
                <i-col-header>등록일</i-col-header>
                <i-col>
                  <iui-text :value="detailInfo.rgsDttm" :enable="false" />
                </i-col>
              </i-row>
            </iui-container-new>
          </i-col>
          <i-spacer />
          <i-col min-width="500px">
            <pmis-file-list
              id="edmsFile"
              title="첨부자료"
              theme="bullet"
              v-model="files"
              :toolbar-visible="!isStandard"
              :company-code="detailInfo.pgmCd"
              :file-number="detailInfo.flNo"
              :fbsNo="detailInfo.fbsNo"
              :project-code="detailInfo.prjCd"
              :searchData="{notFlDsList: [$getConstants('FL_DS_TMP').code, $getConstants('FL_DS_THUMBNAIL').code]}"
            />
          </i-col>
        </i-row>
      </iui-container-new>
    </form>
  </pmis-tab-box>
</template>

<script>
import EDMS_CONSTANTS from '@/view/edms/common/EdmsConstatns.js';
import {saveDoc, deleteDoc} from '@/view/edms/api/edms.js';
export default {
  data() {
    return {
      files: [],
    };
  },
  computed: {
    currentLevel() {
      return this.sheetData.currentLevel;
    },
    nextLevel() {
      return this.sheetData.nextLevel;
    },
    isNew() {
      return this.cud == 1;
    },
    isStandard() {
      return this.sheetData.stYn === $getConstants('Y').code;
    },
  },
  beforeCreate() {
    $mapGetters(this, ['detailInfo', 'sheetData']);
    $mapMutations(this, ['onInitDetailInfo', 'onSetDetailInfo', 'setFocusKeys']);
  },
  created() {
    this.addEvent([{name: 'DetailInfo_InitCud', func: this.onInitCud}]);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },
  methods: {
    onInitCud() {
      this.$store.commit('setCud', 0);
      this.$recompute('cud');
    },
    onAdd() {
      if (0 == this.currentLevel) {
        this.$alert({title: '신규', message: '최상위 분류에는 자료등록을 할 수 있습니다.'}, this.onInitCud());
        return false;
      }
      if (this.currentLevel < this.nextLevel) {
        this.$alert({title: '신규', message: '최하위 분류에만 자료등록을 할 수 있습니다.'}, this.onInitCud());
        return false;
      }

      this.onInitDetailInfo();
      const currentDate = $_getCurrentDate();
      this.detailInfo.rgsDttm = `${currentDate.substr(0, 4)}-${currentDate.substr(4, 2)}-${currentDate.substr(6, 2)}`;
      this.detailInfo.rgprNm = this.userInfo.name;
      this.detailInfo.rgprId = this.userInfo.empNo;
      this.detailInfo.fbsNo = this.sheetData.fldrNo;
      this.detailInfo.fbsCd = this.sheetData.fldrCd;
      this.detailInfo.fbsNm = this.sheetData.fldrNm;
      this.detailInfo.pgmCd = this.sheetData.pgmCd;
      this.detailInfo.prjCd = this.sheetData.prjCd;
    },
    onSave() {
      if (!this.detailInfo.fbsNo) {
        this.$alert({title: '저장', message: '저장할 자료정보가 없습니다.'});
        return;
      }
      if (this.isStandard) {
        this.$alert({title: '저장', message: '해당 자료정보는 수정할 수 없습니다.'});
        return;
      }

      const afterValidation = () => {
        if (0 == this.files.length) {
          this.$alert({title: '필수입력', message: '파일을 첨부해야 합니다.'});
          return false;
        }
        return true;
      };

      saveDoc({...this.detailInfo}, afterValidation)
        .then(response => {
          if (response.data) {
            this.setFocusKeys(response.data);
            this.callEvent({name: 'DataClassification_onSearch'});
          }
        })
        .catch(e => {
          this.$alert({title: '저장', message: `저장을 실패했습니다.(${e})`});
        });
    },
    async onDelete() {
      if (!this.detailInfo.fbsNo) {
        this.$alert({title: '삭제', message: '삭제할 자료정보가 없습니다.'});
        return;
      }
      if (this.isStandard) {
        this.$alert({title: '삭제', message: '해당 자료정보는 삭제할 수 없습니다.'});
        return;
      }

      if (!(await this.$confirm({title: '삭제', message: '삭제하시겠습니까?'}))) {
        return;
      }

      deleteDoc({...this.detailInfo})
        .then(response => {
          if (0 < response.data) {
            this.setFocusKeys({fbsNo: this.detailInfo.fbsNo});
            this.callEvent({name: 'DataClassification_onSearch'});
          }
        })
        .catch(e => {
          this.$alert({title: '삭제', message: `삭제를 실패했습니다.(${e})`});
        });
    },
  },
};
</script>

<style></style>
