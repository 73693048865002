<template>
  <pmis-page
    :search-project-visible="false"
    @search-click="onSearch"
    @new-click="onAdd"
    @save-click="onSave"
    @delete-click="onDelete"
  >
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="100%">
          <PortletRegist />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import PortletRegist from '@/view/portletManage/components/portletRegist';
export default {
  components: {
    PortletRegist,
  },
  methods: {
    onSearch() {
      setTimeout(() => {
        this.callEvent({name: 'portletRegist_selectPortletList'});
      }, 100);
    },
    onAdd() {
      this.callEvent({name: 'portletRegist_addRow'});
    },
    async onSave() {
      if (await this.$confirm('저장하시겠습니까?')) {
        this.callEvent({
          name: 'portletRegist_getSaveJson',
          param: data => {
            axios.post('/portlet/savePortlet', {tbPortletBean: data});
          },
        });
        this.onSearch();
      }
    },
    async onDelete() {
      if (await this.$confirm('삭제하시겠습니까?')) {
        this.callEvent({name: 'portletRegist_doDelete'});
        this.callEvent({
          name: 'portletRegist_getSaveJson',
          param: data => {
            axios.post('/portlet/deletePortlet', {tbPortletBean: data});
          },
        });
        this.onSearch();
      }
    },
  },
};
</script>

<style></style>
