import { render, staticRenderFns } from "./IuiPdfViewer.vue?vue&type=template&id=7433a4b9&scoped=true&"
import script from "./IuiPdfViewer.vue?vue&type=script&lang=js&"
export * from "./IuiPdfViewer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7433a4b9",
  null
  
)

export default component.exports