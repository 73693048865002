export default that => {
  return {
    Cfg: {
      CanEdit: 1,
      UseButton: 1,
    },
    Cols: [
      {
        Header: '코드',
        Name: 'wbsId',
        Type: 'Text',
        RelWidth: 1,
        Visible: 1,
        CanEdit: false
      },
      {
        Header: '작업명',
        Name: 'wbsNm',
        Type: 'Text',
        RelWidth: 1,
        CanEditFormula: () => that.prjFlag != $getConstants('PRJ_FLAG1').code && that.detailInfo.isPlnWgh,
      },
      {
        Header: '물량비중',
        Name: 'plnWgh',
        Extend: that.$ibsheetHelper.preset.rate,
        RelWidth: 1,
        CanEditFormula: () => that.prjFlag != $getConstants('PRJ_FLAG1').code && that.detailInfo.isPlnWgh,
      },
      {
        Header: '시작예정일',
        Name: 'planStrdate',
        Type: 'Date',
        RelWidth: 1,
        CanEditFormula: () => that.prjFlag != $getConstants('PRJ_FLAG1').code && that.detailInfo.isPlnWgh,
      },
      {
        Header: '종료예정일',
        Name: 'planEnddate',
        Type: 'Date',
        RelWidth: 1,
        CanEditFormula: () => that.prjFlag != $getConstants('PRJ_FLAG1').code && that.detailInfo.isPlnWgh,
      },
      {
        Header: '위험도',
        Name: 'risk',
        Type: 'Enum',
        RelWidth: 1,
        Align: 'Center',
        EnumKeys: $getConstants('RISK').code,
        Enum: $getConstants('RISK').code,
        // CanEditFormula: () => that.prjFlag != '000001' && that.detailInfo.isPlnWgh,
      },
      {
        Name: 'wcode',
        Type: 'Text',
        CanEdit: 0,
        Visible: 0,
      },
      {
        Name: 'headCode',
        Type: 'Text',
        CanEdit: 0,
        Visible: 0,
      },
      {
        Name: 'midCode',
        Type: 'Text',
        CanEdit: 0,
        Visible: 0,
      },
      {
        Name: 'wbsCd',
        Type: 'Text',
        CanEdit: 0,
        Visible: 0,
      },
    ],
  };
};
