export default that => {
  return {
    Cfg: {
      CanEdit: 1,
    },
    Cols: [
      {
        Header: {Value: '선택', TextColor: '#444', HeaderCheck: 1},
        Name: 'chk',
        Type: 'Bool',
        Width: 70,
        CanEditFormula: fr => (fr.Row.rcvDttm ? 0 : 1),
      },
      {
        Header: '순번',
        Name: 'seq',
        Type: 'Text',
        Width: 80,
        CanEdit: 0,
      },
      {
        Header: '공람지정자명',
        Name: 'rgprNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
        CanEdit: 0,
      },
      {
        Header: '상태',
        Name: 'state',
        Type: 'Text',
        Width: 110,
        Align: 'Center',
        CanEdit: 0,
        Formula: fr => (fr.Row.rcvDttm ? '완료' : '진행'),
      },
      {
        Header: '부서/직위',
        Name: 'depNmAndDtsNm',
        Type: 'Text',
        Width: 110,
        CanEdit: 0,
      },
      {
        Header: '공람자명',
        Name: 'name',
        Type: 'Text',
        Width: 110,
        CanEdit: 0,
      },
      {
        Header: '공람일시',
        Name: 'rcvDttm',
        Extend: that.$ibsheetHelper.preset.date,
        Width: 110,
        CanEdit: 0,
      },
      {Name: 'empNo', Visible: 0},
      {Name: 'blnDepNo', Visible: 0},
    ],
  };
};
