export default that => {
  return {
    Cfg: {
      CanEdit: 0, // 0: 전체 편집 불가능
    },
    Cols: [
      {
        Header: '노무팀코드',
        Name: 'labCode',
        Type: 'Text',
        Align: 'Center',
        Width: 100,
      },
      {
        Header: '노무팀명',
        Name: 'labName',
        Type: '',
        Align: 'left',
        RelWidth: 1,
      },
      {
        Header: '팀장명',
        Name: 'labMNm',
        Type: 'Text',
        Align: 'left',
        Width: 100,
      },
    ],
  };
};
