export default that => {
  return {
    Cfg: {
      CanEdit: 0,
    },
    Cols: [
      {
        Header: '현장코드',
        Name: 'prjCd',
        Type: 'Text',
        Align: 'center',
        Width: 80,
      },
      {
        Header: '현장명',
        Name: 'prjNm',
        Type: 'Text',
        Align: 'left',
        Width: 200,
      },
      {
        Header: '재료비',
        Name: 'matAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 2,
      },
      {
        Header: '노무비',
        Name: 'labAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 2,
      },

      {
        Header: '장비비(유류대포함)',
        Name: 'equipAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 2,
      },
      {
        Header: '경비',
        Name: 'oprAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 2,
      },
      {
        Header: '외주비',
        Name: 'subAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 2,
      },
      {
        Header: '합계',
        Name: 'totAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 2,
        Formula: fr => fr.Row.matAmt + fr.Row.labAmt + fr.Row.subAmt + fr.Row.equipAmt + fr.Row.oprAmt,
      },
    ],
  };
};
