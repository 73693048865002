<template>
  <pmis-content-box>
    <template v-slot:title>
      사원정보
    </template>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="250px">
          <iui-carousel width="200px" height="250px">
            <pmis-image-file
              title="사진"
              id="photo"
              flNoCol="phoFlNo"
              imageWidth="200px"
              imageHeight="190px"
              :file-number.sync="workerInfo.phoFlNo"
              :saveButtonVisible="false"
              @delete-complete="onDeleteCompleteFile('phoFlNo', $event)"
              :clear.sync="clearFlNo1"
            />
            <pmis-image-file
              title="싸인"
              id="sign"
              flNoCol="sgntFlNo"
              imageWidth="200px"
              imageHeight="190px"
              :company-code="pgmCd"
              :file-number.sync="workerInfo.sgntFlNo"
              :saveButtonVisible="false"
              @delete-complete="onDeleteCompleteFile('sgntFlNo', $event)"
              :clear.sync="clearFlNo2"
            />
          </iui-carousel>
        </i-col>
        <i-col>
          <iui-container-new type="table" theme="bullet">
            <colgroup>
              <col width="100px" />
              <col />
            </colgroup>
            <i-row>
              <i-col-header required>국적</i-col-header>
              <i-col>
                <iui-text
                  type="text"
                  name="nationNm"
                  :value="workerInfo.nationNm"
                  max-length="6"
                  required
                  :error-message="{title: '국적', message: '국적을 선택해주세요.'}"
                  width="110px"
                  :enable="isNew"
                  :readonly="isNew"
                />
                <iui-button v-if="addStatus == 1" value="검색" @click="onShowModal('nation')" />
              </i-col>
            </i-row>
            <i-row v-if="isKorean">
              <i-col-header required>주민번호</i-col-header>
              <i-col>
                <iui-regi-no
                  :value1="workerInfo.regiNo1"
                  :value2="workerInfo.regiNo2"
                  @change1="onDuplicateRegiNo"
                  @change2="onDuplicateRegiNo"
                  :required="workerInfo && isKorean"
                  size="short"
                  :enable="addStatus == 1 || useMst"
                />
              </i-col>
            </i-row>
            <i-row v-if="!isKorean">
              <i-col-header required>여권번호</i-col-header>
              <i-col>
                <iui-text
                  name="passportNo"
                  :value="workerInfo.passportNo"
                  @change="onDuplicateRegiNo"
                  max-length="15"
                  :required="workerInfo && !isKorean"
                  :error-message="{title: '여권번호', message: '여권번호를 입력해주세요.'}"
                  width="150px"
                  :enable="addStatus == 1 || useMst"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header required>ID</i-col-header>
              <i-col>
                <iui-text
                  type="text"
                  ref="empNo"
                  name="empNo"
                  :value="workerInfo.empNo"
                  @change="onChangeEmpNo"
                  width="150px"
                  required
                  :error-message="{title: 'ID', message: 'ID를 입력해주세요.'}"
                  max-length="8"
                  :enable="addStatus == 2"
                />
                <iui-button value="중복확인" @click="onDuplicateEmpNo" :visible="addStatus == 2" />
                <div>영문숫자(5~8)</div>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header required>성명</i-col-header>
              <i-col>
                <iui-text
                  name="name"
                  type="text"
                  :value="workerInfo.name"
                  @change="onChangeName"
                  max-length="50"
                  required
                  :error-message="{title: '성명', message: '성명을 입력해주세요.'}"
                  width="150px"
                  :enable="addStatus == 0"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>부서</i-col-header>
              <i-col>
                <!-- 
                  소속이 외주이면 검색내용 외주 검색 노무팀이면 검색내용 노무팀 검색 
                  외주 노무팀이면, 근로자구분은 일용직으로 고정
                -->
                <iui-text
                  width="150px"
                  name="blnDepNm"
                  :value="workerInfo.blnDepNm"
                  @change="setWorkerInfo({blnDepNm: $event.target.value})"
                  :readonly="true"
                  :enable="addStatus == 0"
                />
                <iui-button value="검색" @click="onShowModal('blnDep')" v-if="addStatus == 0" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>전화번호</i-col-header>
              <i-col>
                <iui-text
                  type="phone"
                  name="tlno"
                  :value="workerInfo.tlno"
                  @change="setWorkerInfo({tlno: $event.target.value})"
                  width="150px"
                  :enable="addStatus == 0"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>팩스번호</i-col-header>
              <i-col>
                <iui-text
                  type="phone"
                  name="rpsvFaxno"
                  :value="workerInfo.rpsvFaxno"
                  @change="setWorkerInfo({rpsvFaxno: $event.target.value})"
                  width="150px"
                  :enable="addStatus == 0"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header required>휴대폰번호</i-col-header>
              <i-col>
                <iui-text
                  type="phone"
                  name="mobileTlno"
                  :value="workerInfo.mobileTlno"
                  @change="setWorkerInfo({mobileTlno: $event.target.value})"
                  width="150px"
                  required
                  :error-message="{title: '휴대폰번호', message: '휴대폰번호를 입력해주세요.'}"
                  :enable="addStatus == 0"
                />
                <iui-checkbox-group
                  name="useSms"
                  :items="[{label: 'SMS수신동의', value: $getConstants('Y').code}]"
                  :checkedValues="[workerInfo.useSms]"
                  @change="setWorkerInfo({useSms: $event.target.value})"
                  :enable="addStatus == 0"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>주소</i-col-header>
              <i-col>
                <iui-searchbox
                  :type="'addr'"
                  name="postNo"
                  :idValue="workerInfo.postNo"
                  :nameValue="workerInfo.adr1"
                  nameEleName="adr1"
                  :callback="getAddr"
                  width="300px"
                ></iui-searchbox>
                <iui-text
                  name="adr2"
                  :value="workerInfo.adr2"
                  @change="setWorkerInfo({adr2: $event.target.value})"
                  max-length="100"
                  width="165px"
                  :enable="addStatus == 0"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>이메일</i-col-header>
              <i-col>
                <iui-text
                  name="email1"
                  type="email1"
                  width="110px"
                  max-length="50"
                  :value="workerInfo.email1"
                  @change="setWorkerInfo({email1: $event.target.value})"
                  :enable="addStatus == 0"
                />
                <div class="mr5">@</div>

                <iui-select
                  :p-code="$getConstants('EMAIL_CD').code"
                  :value="isEmail2Code ? '' : workerInfo.email2"
                  defaultCd="직접입력"
                  @change="value => setWorkerInfo({email2: value})"
                  :enable="addStatus == 0"
                />
                <iui-text
                  type="email2"
                  width="110px"
                  max-length="50"
                  :value="workerInfo.email2"
                  v-if="isEmail2Code"
                  @change="setWorkerInfo({email2: $event.target.value})"
                  :enable="addStatus == 0"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>근무종료여부</i-col-header>
              <i-col>
                <iui-checkbox-group
                  name="labtClose"
                  :p-code="$getConstants('LABT_CLOSE').code"
                  :checkedValues="[workerInfo.labtClose]"
                  @change="setWorkerInfo({labtClose: $event.target.checked ? $event.target.value : ''})"
                  :enable="addStatus == 0"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>시스템사용여부</i-col-header>
              <i-col>
                <iui-checkbox-group
                  name="useDs"
                  :checkedValues="[workerInfo.useDs]"
                  :items="[{label: '사용', value: $getConstants('Y').code}]"
                  @change="
                    setWorkerInfo({
                      useDs: $event.target.checked ? $event.target.value : $getConstants('N').code,
                    })
                  "
                  :enable="addStatus == 0"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>관리자여부</i-col-header>
              <i-col>
                <iui-checkbox-group
                  name="useMst"
                  :p-code="$getConstants('USE_MST').code"
                  @change="setWorkerInfo({useMst: $event.target.value})"
                  :checkedValues="[workerInfo.useMst]"
                  :enable="false"
                />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
      <iui-modal name="deptListPopup" title="부서선택" :btns="modalBtn" sizeType="size1">
        <DeptListPopup :uid="_uid" :frmNm="workerInfo.frmNm" />
      </iui-modal>
      <iui-modal name="nationListPopup" title="국가검색" :btns="modalBtn" sizeType="size1">
        <NationList :uid="_uid" :paramObj="{code: $getConstants('NATION_CD').code}" />
      </iui-modal>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import NationList from '@/view/user/Worker/components/NationListPopup.vue';
import DeptListPopup from '@/view/user/Worker/components/DeptListPopup.vue';
import IuiText from '@/components/common/IuiText.vue';
import {
  deleteWorkerInfo,
  saveWorkerInfo,
  selectWorkerExistEmpNo,
  selectWorkerInfo,
  selectWorkerInfoList,
  updateWorkerFlNo,
} from '../../Worker/api/worker.js';
export default {
  components: {NationList, DeptListPopup, IuiText},
  data() {
    return {
      clearFlNo1: false,
      clearFlNo2: false,
      email2: '',
      modalBtn: [{name: '확인', callback: this.onHideModal}],
      modalNm: '',
      phoFlNo: 0,
      sgntFlNo: 0,
      regiNoDupChkComplete: false,
      isNewEmpNo: false,
    };
  },
  computed: {
    isEmail2Code() {
      return String(this.workerInfo.email2).indexOf('0000') == -1;
    },
    valueEmail2() {
      return this.workerInfo.email2?.indexOf('0000') == -1 ? '' : this.workerInfo.email2;
    },
    isNew() {
      return this.workerInfo.cud == 1;
    },
    isKorean() {
      return this.workerInfo.nationCd == $getConstants('NATION_CD_KR').code;
    },
    addStatus() {
      let status = 0;
      if (this.isNew) {
        if (
          !this.workerInfo.nationCd ||
          ((!this.workerInfo.regiNo1 || !this.workerInfo.regiNo2) && !this.workerInfo.passportNo) ||
          !this.regiNoDupChkComplete
        ) {
          status = 1;
        } else if (!this.workerInfo.empNo || this.workerInfo.idDupChk !== 1) {
          status = 2;
        }
      }
      return status;
    },
    useMst() {
      return (
        (this.workerInfo.regiNo1 == '' ||
          this.workerInfo.regiNo2 == '' ||
          this.workerInfo.regiNo1 == null ||
          this.workerInfo.regiNo2 == null) &&
        this.workerInfo.useMst == $getConstants('USE_MST_Y').code
      );
    },
  },
  async created() {
    this.addEvent([
      {name: 'WorkerInfo_onSearch', func: this.onSearch},
      {name: 'WorkerInfo_onSave', func: this.onSave},
      {name: 'WorkerInfo_onAdd', func: this.onAdd},
      {name: 'WorkerInfo_onDelete', func: this.onDelete},
      {name: 'onHideModal_' + this._uid, func: this.onHideModal},
    ]);
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'workerInfo']);
    $mapMutations(this, ['setSearchInfo', 'setWorkerInfo', 'initWorkerInfo']);
  },
  methods: {
    async onSearch(empNo) {
      this.initWorkerInfo();
      this.clearFlNo1 = true;
      this.clearFlNo2 = true;
      this.regiNoDupChkComplete = false;
      const response = await selectWorkerInfo({empNo});
      if (response.status == 200) {
        response.data.email2 = response.data.originEmail2;
        this.setWorkerInfo(response.data);
      }
      this.callEvent({name: 'IuiCarousel_SetIndex', param: 0});
    },
    onAdd() {
      this.callEvent({name: 'IuiCarousel_SetIndex', param: 0});
      let frmNm = this.workerInfo.frmNm;
      this.initWorkerInfo();
      this.setWorkerInfo({rgprId: this.userInfo.empNo, frmNm: frmNm});
    },

    onDuplicateRegiNo(event) {
      const name = event.target.name;
      const value = event.target.value;

      if (name == 'regiNo1') {
        if (value.length < 6) {
          this.$alert({title: '주민번호', message: '주민번호 앞자리는 6자리로 입력하여 주세요.'}, () => {
            event.target.value = '';
          });
          return;
        }
        this.setWorkerInfo({[name]: value});
      }
      if (name == 'regiNo2') {
        if (value.length < 7) {
          this.$alert({title: '주민번호', message: '주민번호 뒷자리는 7자리로 입력하여 주세요.'}, () => {
            event.target.value = '';
          });
          return;
        }
        this.setWorkerInfo({[name]: value});
      }
      if (name == 'passportNo') {
        if (value.length < 15) {
          return;
        }
        this.setWorkerInfo({[name]: value});
      }

      if (this.workerInfo.useMst == $getConstants('USE_MST_Y').code) {
        this.regiNoDupChkComplete = true;
        return;
      }

      this.onSelectWorkerExistRegiNo();
    },
    async onSelectWorkerExistRegiNo() {
      const regiNo = String(this.workerInfo.regiNo);
      const passportNo = String(this.workerInfo.passportNo);
      if (regiNo.length != 13 && passportNo.length != 15) return;
      const searchGb = this.isKorean ? 'regiNo' : 'passportNo';
      const response = await selectWorkerInfoList({regiNo, passportNo, searchGb});
      if (response.status == 200) {
        if (response.data) {
          this.$alert({title: '기등록 근로자', message: '이미 등록되어 있는 근로자 입니다.'});
          return;
        } else {
          this.setWorkerInfo({cud: 1});
          this.regiNoDupChkComplete = true;
        }
      }
    },
    onChangeEmpNo(event) {
      this.setWorkerInfo({empNo: event.target.value, idDupChk: 0});
    },
    async onDuplicateEmpNo() {
      const empNo = String(this.workerInfo.empNo);
      const regType1 = /^[A-Za-z0-9+]{5,8}$/;
      if (!regType1.test(empNo)) {
        this.$alert({title: 'ID', message: 'ID는 5~8자리의 영문 또는 숫자만 입력 가능합니다.'}, () => {
          this.setWorkerInfo({empNo: ''});
        });
        return;
      }
      const response = await selectWorkerExistEmpNo({empNo});
      if (response.status == 200) {
        if (0 == response.data) {
          this.setWorkerInfo({idDupChk: 1});
        } else {
          this.$alert(
            {title: 'ID 중복확인', message: '이미 사용중인 ID입니다. <br/> 새로운 ID를 입력하여 주세요.'},
            () => {
              this.setWorkerInfo({idDupChk: 2, empNo: ''});
              this.$refs.empNo.$refs.input.focus();
            }
          );
        }
      }
    },
    onChangeName(event) {
      if (!this.workerInfo.empNo) {
        this.$alert({title: 'ID', message: 'ID 중복확인을 먼저 하십시오.'}, () => {
          event.target.value = '';
        });
        return;
      }
      this.setWorkerInfo({name: event.target.value});
    },
    async onDeleteCompleteFile(key, flNo) {
      if (flNo == 0) {
        this.setWorkerInfo({[key]: flNo});
        let param = {
          empNo: this.workerInfo.empNo,
          phoFlNo: this.workerInfo.phoFlNo,
          sgntFlNo: this.workerInfo.sgntFlNo,
        };
        const response = await updateWorkerFlNo(param);
      }
    },
    async onSave() {
      this.setWorkerInfo({
        laborDs: $getConstants('LABOR_DS1').code, //정직
        blnDepPart: $getConstants('BLN_DEP_PART_CD_COMPANY').code, //자사
      });
      let param = {
        ...this.workerInfo,
      };
      const response = await saveWorkerInfo(param);
      if (response.status == 200) {
        this.setSearchInfo({empNo: response.data.empNo});
        this.callEvent({name: 'WorkerList_onSearch'});
      }
    },
    async onDelete() {
      if (await this.$confirm({title: '근로자 삭제', message: '삭제하시겠습니까?'})) {
        if (this.workerInfo.useMst == $getConstants('USE_MST_Y').code) {
          this.$alert({title: '삭제 불가', message: '관리자는 삭제할 수 없습니다.'});
          return;
        }
        if (this.isNew) {
          this.$alert({title: '삭제 불가', message: '신규 작성중인 정보는 삭제할 수 없습니다.'});
          return;
        }
        if (this.workerInfo.workingDays > 0) {
          this.$alert({
            title: '삭제 불가',
            message: '해당 근로자는 출역 정보가 등록되어 있어<br/>삭제 할 수 없습니다.',
          });
          return;
        }
        let param = {
          empNo: this.workerInfo.empNo,
          phoFlNo: this.workerInfo.phoFlNo,
          sgntFlNo: this.workerInfo.sgntFlNo,
        };
        const response = await deleteWorkerInfo(param);
        if (response.status == 200) {
          this.callEvent({name: 'WorkerList_onSearch'});
        }
      }
    },
    getAddr(data) {
      this.setWorkerInfo({
        postNo: data.zipNo,
        adr1: `${data.roadAddrPart1} ${data.roadAddrPart2}`,
        adr2: data.addrDetail,
      });
    },
    onShowModal(type) {
      if (type == 'nation') {
        this.modalNm = 'nationListPopup';
      } else if (type == 'blnDep') {
        this.modalNm = 'deptListPopup';
      }
      this.$modal.show(this.modalNm);
    },
    onHideModal() {
      if (this.modalNm == 'nationListPopup') {
        this.callEvent({
          name: 'nationList_callbackData',
          param: data => {
            this.setWorkerInfo({
              nationCd: data.code,
              nationNm: data.codeNm,
            });
            this.$modal.hide(this.modalNm);
          },
        });
      } else if (this.modalNm == 'deptListPopup') {
        let isLstNode = true;
        this.callEvent({
          name: 'DeptListPopup_callbackData',
          param: data => {
            if (data.fldrCd == 'OBS') {
              isLstNode = false;
              return;
            }
            this.setWorkerInfo({blnDepNo: data.fldrNo, blnDepNm: data.fldrNm});
            this.$modal.hide(this.modalNm);
          },
        });
        if (!isLstNode) return;
      }
    },
  },
};
</script>

<style scoped></style>
