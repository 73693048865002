export default {
  name: 'iui-loading-overlay',
  props: {
    iconType: {
      type: String,
      default: 'spinner',
    },
    iconWidth: {
      type: String,
      default: undefined,
    },
    iconHeight: {
      type: String,
      default: undefined,
    },
    iconWeight: {
      type: String,
      default: undefined,
    },
    iconColor: {
      type: String,
      default: undefined,
    },
    iconBackgroundColor: {
      type: String,
      default: undefined,
    },
    backgroundColor: {
      type: String,
      default: undefined,
    },
    opacity: {
      type: String,
      default: undefined,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalVisible: this.visible,
    };
  },
  watch: {
    //visible(newValue, oldValue) {
    visible(newValue) {
      this.internalVisible = newValue;
    },
  },
  // mounted() {
  //   this.$parent.$el.style.position = 'relative';
  // },
  render(h) {
    let childNodes = [];
    let background = h('div', {
      class: {background: true},
      style: {'backgroud-color': this.backgroundColor, opacity: this.opacity},
    });
    let icon = h('div', {
      class: {icon: true},
      style: {
        width: this.iconWidth,
        height: this.iconHeight,
        'border-width': this.iconWeight,
        'border-color': this.iconBackgroundColor,
        'border-top-color': this.iconColor,
      },
    });

    childNodes.push(background);
    childNodes.push(icon);

    let vnode = h(
      'div',
      {class: {'iui-loading-overlay': true}, style: {display: this.internalVisible ? 'flex' : 'none'}},
      childNodes
    );

    return vnode;
  },
};
