<template>
  <pmis-content-box>
    <template #title>제출서류목록</template>
    <template #title-right>
      <iui-button value="구비서류제출" @click="onSubmitNeedFile" v-if="enableUpload" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      @loadSheet="sheet = $event"
      :loadSearchData="poNeedFileList"
      :events="{onSearchFinish: sheet_onSearchFinish}"
    />

    <iui-file-interface
      ref="fileInterface"
      mappedBy="fileCd"
      :enableUpload="enableUpload"
      @complete="onUploadComplete"
    />
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/poSubmitDocList.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import {submitNeedFile, selectCheckPoState} from '@/view/bidding/po/mat/api/po.js';
import {
  submitNeedFile as submitNeedFileSub,
  selectCheckPoState as selectCheckSubPoState,
} from '@/view/bidding/po/sub/api/subPo.js';
export default {
  data() {
    return {sheetOpt: sheetOpt(this), reUpload: false};
  },
  computed: {
    enableUpload() {
      return this.poStatus.isPoSend || this.reUpload;
    },
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'poNeedFileList', 'poStatus', 'bidType']);
  },
  methods: {
    sheet_onSearchFinish(e) {
      this.reUpload = e.sheet
        .getDataRows()
        .some(row => row.approvalYn === BIDDING_CONSTANTS.CODES.PO_SUFILE_APPROVAL_N);

      setTimeout(() => {
        this.callEvent({name: 'IuiFileInterface_init', param: e.sheet});

        if (this.poStatus.isPoSignComplete) {
          const approvalYnColOpt = {
            Header: '심사결과',
            Type: 'Text',
            Align: 'Center',
            Width: 100,
            CanEdit: 0,
            CustomFormat: v =>
              ({
                [BIDDING_CONSTANTS.CODES.PO_SUFILE_APPROVAL_Y]: '적합',
                [BIDDING_CONSTANTS.CODES.PO_SUFILE_APPROVAL_N]: '부적합',
              }[v]),
          };

          e.sheet.addCol('approvalYn', 1, e.sheet.getColIndex('fileUpload', true) - 2, approvalYnColOpt, 1);

          e.sheet.getDataRows().forEach(row => {
            if (row.approvalYn === BIDDING_CONSTANTS.CODES.PO_SUFILE_APPROVAL_N) {
              e.sheet.setAttribute(row, 'approvalYn', 'TextStyle', 1, 1);
              e.sheet.setAttribute(row, 'approvalYn', 'TextColor', '#FF0000', 1);
            }
          });
        } else {
          e.sheet.removeCol('approvalYn');
        }
      }, 50);
    },
    async onSubmitNeedFile() {
      const param = {
        poNo: this.poInfo.poNo,
        contSeq: this.poInfo.contSeq,
        isSupplierPage: true,
        processCode: 'need',
      };
      const checkPoStateApi = {mat: selectCheckPoState, sub: selectCheckSubPoState};
      const response = await checkPoStateApi[this.bidType](param);

      const checkMessage = response.data;

      if (checkMessage) {
        await this.$alert({title, message: checkMessage});
        this.callEvent({name: 'PoList_onSearch', param: param.poNo});
        return;
      }

      if (this.sheet.getDataRows().some(row => !row.lclFlNm)) {
        this.$alert({
          title: '구비서류제출',
          message: '파일이 첨부되지 않은 서류가 존재합니다.<br/>다시 한 번 확인하시기 바랍니다.',
        });
        return;
      }

      if (await this.$confirm({title: '구비서류제출', message: '구비서류를 제출하시겠습니까?'})) {
        this.$refs.fileInterface.onBeforeUpload();
      }
    },
    onUploadComplete() {
      const submitFileList = this.sheet.getSaveJson(0).data.map(row => ({
        poNo: this.poInfo.poNo,
        contSeq: this.poInfo.contSeq,
        cusCd: this.poInfo.cusCd,
        pgmCd: this.pgmCd,
        fileNo: row.flNo,
        fileCd: row.fileCd,
        needFileNm: row.fileNm,
        approvalYn: row.approvalYn ?? '',
      }));

      const submitNeedFileApi = {mat: submitNeedFile, sub: submitNeedFileSub};
      submitNeedFileApi[this.bidType](this.poInfo.poNo, this.poInfo.contSeq, submitFileList)
        .then(response => {
          if (response.status === 200) {
            this.callEvent({name: 'PoList_onSearch', param: this.poInfo.poNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
  },
};
</script>

<style></style>
