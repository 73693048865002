'use strict';

import Vue from 'vue';
import axios from 'axios';
import store from '@/store/index.js';
import {getRefreshToken} from '@/common';

const ignoreBaseData = ['/setDataMap'];

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL:
    ['production'].indexOf(process.env.NODE_ENV) !== -1 && location.protocol.indexOf('https') !== -1
      ? process.env.VUE_APP_API_URLS
      : process.env.VUE_APP_API_URL || '',
  timeout: 60 * 1000, // Timeout
  withCredentials: false, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    const regex = /^(\/pr\/|\/subPr\/|\/po\/|\/poCustomer\/|\/subPo\/|\/subRfqCustomer\/|\/subPoCustomer\/|\/order\/|\/orderCustomer\/|\/bill\/|\/subBill\/|\/rfq\/|\/rfqCustomer\/|\/cert\/|\/sub\/|\/quot\/|\/pkiToolkit\/|\/certificate|\/certificateRegist\/|\/subQuot\/|\/subRfq\/|\/ebiz4u\/|\/certificate\/|\/govCertificate\/|\/ntsCommon\/|\/notification\/).*/;

    if (regex.test(config.url) && config.url.indexOf('portletData') === -1) {
      config.baseURL =
        location.protocol.indexOf('https') !== -1
          ? process.env.VUE_APP_EXTERNAL_URLS
          : process.env.VUE_APP_EXTERNAL_URL;
    }
    if (config.url.startsWith('/file')) {
      config.baseURL =
        location.protocol.indexOf('https') !== -1 ? process.env.VUE_APP_FILE_URLS : process.env.VUE_APP_FILE_URL;
    }

    const accessToken = store.getters['jwt/accessToken'];
    if (accessToken && config.url !== '/auth/refreshToken') {
      config.headers.Authorization = accessToken;
    }

    if (!ignoreBaseData.includes(config.url)) {
      config.data = setBaseData(config.data);
    }

    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

const setBaseData = data => {
  if (data instanceof Object) {
    const prjInfo = store.getters['project/loadPrj'][store.getters['currentUrl']];
    const cud = store.getters['cud'][store.getters['currentUrl']];
    if (!data.pgmCd) {
      data.pgmCd =
        store.getters['pgmCd'][store.getters['currentUrl']] || JSON.parse(sessionStorage.getItem('userInfo')).pgmCd;
    }
    if (prjInfo && !data.prjCd) {
      data.prjCd = prjInfo.prjCd;
    }
    if (cud != undefined && !data.hasOwnProperty('cud')) {
      data.cud = cud;
    }
  }
  return data;
};

let isRefresh = false;
let requestArray = [];
const onTokenRefreshed = () => {
  requestArray.map(callback => {
    callback();
  });
  requestArray = [];
};
const addRefreshSubscriber = callback => {
  requestArray.push(callback);
};

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    if (response.data.pmisError) {
      return Promise.reject(response.data);
    }
    return response;
  },
  async function(error) {
    // Do something with response error
    const {config, response} = error;
    const originalRequest = config;
    if (response.status === 401 && response.data.code === 'ExpiredJwtException') {
      const retryOriginalRequest = new Promise(resolve => {
        addRefreshSubscriber(() => {
          resolve(_axios(originalRequest));
        });
      });

      if (!isRefresh) {
        isRefresh = true;
        originalRequest.headers.Authorization = await getRefreshToken();
        isRefresh = false;

        onTokenRefreshed();
      }

      return retryOriginalRequest;
    }

    if (response.status === 401 && response.config.url === '/auth/refreshToken') {
      isRefresh = false;
      location.replace('/login');
      return;
    }

    Vue.prototype.$alert('서버에서 오류가 발생하였습니다.');
    console.error(error);

    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
