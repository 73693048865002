const state = () => ({
  workerInfo: {
    pgmCd: '', //회사코드
    frmNm: '', //회사명
    empNo: '', //사원번호(ID사번)
    name: '', //성명
    regiNo: '', //주민번호
    regiNo1: '', //주민번호1
    regiNo2: '', //주민번호2
    tlno: '', //전화번호
    mobileTlno: '', //휴대폰번호
    rpsvFaxno: '', //팩스번호
    postNo: '', //우편번호
    adr1: '', //주소
    adr2: '', //상세주소
    email1: '', //이메일1
    email2: '', //이메일2
    phoFlNo: 0, //사진파일번호
    blnDepPart: '', //소속구분
    blnDepNo: '', //소속코드
    blnDepNm: '', //소속명
    blnDepNoSeq: '', //소속_순번
    laborDs: '', //근로자구분
    dtsNo: '', //직책
    wbusiness: '', //담당업무
    rollNo: '', //직급
    useDs: '', //시스템사용여부
    useMst: '', //시스템관리자여부
    useSms: '', //SMS수신동의여부
    pwd: '', //비밀번호
    sgntFlNo: 0, //사인파일번호
    labtClose: '', //근무종료
    priceLGb: '', //노무단가구분
    priceLAmt: '', //노무단가
    jkdNo: '', //노무직종
    jkdNm: '', //노무직종명
    pensionDs: '', //국민연금적용제외여부
    healthInsurDs: '', //건강보험적용제외여부
    employInsurDs: '', //고용보험적용제외여부
    healthInsurMDs: '', //장기요양30%경감대상여부
    bankCode: '', //은행코드
    depositNo: '', //계좌번호
    depositor: '', //예금주
    handicapKind: '', //장애유형
    handicapGrade: '', //장애등급
    handicapDate: '', //장애판정일
    nationCd: '', //국가코드
    nationNm: '', //국가코드
    passportNo: '', //여권번호
    visaKind: '', //체류자격
    entryDate: '', //입국일자
    stayDuration: '', //체류기간
    cud: 0,
    idDupChk: 0, // ID 중복상태 (0: 중복확인 안함, 1: 사용가능, 2: 사용불가(중복존재))
    workingDays: 0,
    rgprId: '', //등록자아이디
    createNewEmpNo: false,
    canEnableLabtClose: false,
  },

  //파일 업로드 여부 체크를 위한 obj
  fileFlag: {
    phoFl: false,
    sgntFl: false,
  },

  removeRow: {
    prjCd: '',
    poNoSubc: '',
  },
});

const getters = {
  workerInfo: state => state.workerInfo,
  fileFlag: state => state.fileFlag,
  removeRow: state => state.removeRow,
};

const mutations = {
  setWorkerInfo(state, payload) {
    for (let key in state.workerInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.workerInfo[key] = payload[key];

        if (key == 'nationCd') {
          if (state.workerInfo[key] == $getConstants('NATION_CD_KR').code) {
            /* 국적이 한국의 경우 */
            state.workerInfo.passportNo = ''; //여권번호
            state.workerInfo.visaKind = ''; //체류자격
            state.workerInfo.entryDate = ''; //입국일자
          } else {
            /* 국적이 외국일 경우 */
            state.workerInfo.regiNo = ''; //주민번호
            state.workerInfo.regiNo1 = ''; //주민번호1
            state.workerInfo.regiNo2 = ''; //주민번호2
          }
        } else if (key == 'regiNo1' || key == 'regiNo2') {
          state.workerInfo.regiNo = state.workerInfo.regiNo1 + state.workerInfo.regiNo2;
        } else if (key == 'blnDepPart') {
          state.workerInfo.blnDepNo = '';
          state.workerInfo.blnDepNm = '';
          if (
            payload[key] == $getConstants('BLN_DEP_PART_CD_CONSTRUCTION_SERVICE').code ||
            payload[key] == $getConstants('BLN_DEP_PART_CD_LABOR').code
          ) {
            /* 소속이 정직이 아니면 근로자 구분은 일용직 적용 */
            state.workerInfo.laborDs = $getConstants('LABOR_DS3').code;
            state.workerInfo.priceLGb = $getConstants('PRICE_L_GB_DAY').code; //노무단가구분
          } else {
            state.workerInfo.laborDs = '';
            state.workerInfo.priceLGb = '';
          }
        } else if (key == 'laborDs') {
          /* 근로자구분이 정직이면 아래 항목 사용안함 */
          if (payload[key] == $getConstants('LABOR_DS1').code) {
            state.workerInfo.priceLGb = ''; //노무단가구분
            state.workerInfo.priceLAmt = ''; //노무단가
            state.workerInfo.jkdNo = ''; //노무직종
            state.workerInfo.jkdNm = ''; //노무직종
            state.workerInfo.pensionDs = ''; //국민연금적용제외여부
            state.workerInfo.healthInsurDs = ''; //건강보험적용제외여부
            state.workerInfo.employInsurDs = ''; //고용보험적용제외여부
            state.workerInfo.healthInsurMDs = ''; //장기요양30%경감대상여부
          } else {
            if (payload[key] == $getConstants('LABOR_DS2').code) {
              /* 근로자구분이 상용직 월당 디폴트 */
              state.workerInfo.priceLGb = $getConstants('PRICE_L_GB_MONTH').code; //노무단가구분
              state.workerInfo.priceLAmt = ''; //노무단가
              state.workerInfo.jkdNo = ''; //노무직종
              state.workerInfo.jkdNm = ''; //노무직종
            } else if (payload[key] == $getConstants('LABOR_DS3').code) {
              /* 근로자구분이 일용직이 일당 디폴트 */
              state.workerInfo.priceLGb = $getConstants('PRICE_L_GB_DAY').code;
            }
            /* 근로자구분이 정직이 아니면 아래 항목 사용안함 */
            state.workerInfo.dtsNo = ''; //직책
            state.workerInfo.rollNo = ''; //직급
            state.workerInfo.wbusiness = ''; //담당업무
          }
        }
      }
    }
  },
  initWorkerInfo(state) {
    for (let key in state.workerInfo) {
      if (key == 'nationCd') {
        state.workerInfo[key] = $getConstants('NATION_CD_KR').code; // KR : 대한민국
      } else if (key == 'nationNm') {
        state.workerInfo[key] = '대한민국';
      } else if (key == 'pwd' || key == 'pwd2') {
        state.workerInfo[key] = null;
      } else if (key == 'phoFlNo' || key == 'sgntFlNo' || key == 'cud' || key == 'idDupChk') {
        state.workerInfo[key] = 0;
      } else if (key == 'createNewEmpNo' || key == 'canEnableLabtClose') {
        state.workerInfo[key] = false;
      } else {
        state.workerInfo[key] = '';
      }
    }
  },
  initFileFlag(state) {
    for (let key in state.fileFlag) {
      state.fileFlag[key] = false;
    }
  },
  setFileFlag(state, payload) {
    for (let key in state.fileFlag) {
      if (payload && payload.hasOwnProperty(key)) {
        state.fileFlag[key] = payload[key];
      }
    }
  },
  setRemoveRow(state, payload) {
    for (let key in state.removeRow) {
      if (payload && payload.hasOwnProperty(key)) {
        state.removeRow[key] = payload[key];
      }
    }
  },
  initRemoveRow(state) {
    for (let key in state.removeRow) {
      state.removeRow[key] = '';
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
