<template>
  <pmis-page
    :search-company-visible="!isBase"
    @search-click="onSearch"
    @save-click="onSave"
    @new-click="onAdd"
    @delete-click="onDel"
    @search-company-changed="onCompanyChange"
  >
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <pmis-tab-box no-border-box no-padding-box>
            <template v-slot:title>목록</template>
            <ProgHedofcMenu :isBase="isBase" :isCont="isCont" />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>상세정보</template>
            <template v-slot:title-right>
              <iui-button value="복제" @click="onProgramCopy" v-if="!isCont" />
            </template>
            <ProgDetailInfo :isBase="isBase" :isCont="isCont" />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
    <iui-modal name="programCopy" title="프로그램메뉴 복제" sizeType="size6" @beforeClose="onProgramCopyClose">
      <programCopy :isBase="isBase" />
    </iui-modal>
  </pmis-page>
</template>

<script>
import ProgHedofcMenu from '@/view/programMenu/components/progHedofcMenu.vue';
import ProgDetailInfo from '@/view/programMenu/components/progDetailInfo.vue';
import programCopy from '@/view/programMenu/components/popup/programCopy.vue';
import store from './store/programMenu.js';
import {saveMenu as baseSaveMenu} from './api/baseMenu.js';
import {saveMenu} from './api/menu.js';
export default {
  components: {ProgDetailInfo, ProgHedofcMenu, programCopy},

  data() {
    return {
      isBase: this.$store.getters['mdi/currentUrls'].para === 'base',
      isCont: this.$store.getters['mdi/currentUrls'].para === 'cont',
      // isBase: false,
    };
  },

  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['detailInfo']);
    $mapMutations(this, ['setDetailInfo', 'initDetailInfo']);
  },
  created() {
    if (this.isCont) {
      this.canNew = false;
    }
  },

  methods: {
    onCompanyChange() {
      this.onSearch();
    },
    onSearch(param) {
      this.callEvent({name: 'onSearch', param: param});
    },
    onAdd: function() {
      const frmGb = this.detailInfo.frmGb;
      let lvl = this.detailInfo.lvl;
      let uppMnuNm;
      let uppMnuCd;

      if (lvl !== 4) {
        uppMnuNm = this.detailInfo.mnuNm;
        uppMnuCd = this.detailInfo.mnuCd;
      } else {
        uppMnuNm = this.detailInfo.uppMnuNm;
        uppMnuCd = this.detailInfo.uppMnuCd;
      }
      if (lvl === 3) {
        lvl = 4;
      }
      this.initDetailInfo();
      this.setDetailInfo({uppMnuNm: uppMnuNm, uppMnuCd: uppMnuCd, frmGb: frmGb, lvl: lvl});
      this.callEvent({name: 'removeAll'});
    },
    async onSave() {
      let isDuplicate = false;
      if (this.cud === 1) {
        this.callEvent({
          name: 'onDuplicate',
          param: param => (isDuplicate = param),
        });
      }

      if (isDuplicate) {
        this.$alert({title: '메뉴코드중복', message: '메뉴코드가 중복되었습니다.'});
        return;
      }

      let authList = [];
      if (this.detailInfo.lvl === 4) {
        this.callEvent({
          name: 'getAuthList',
          param: data => {
            authList = data;
          },
        });
      }

      let param = {
        ...this.detailInfo,
        authList: authList,
      };

      const response = this.isBase ? await baseSaveMenu(param) : await saveMenu(param);
      if (response.data) {
        this.onSearch(this.detailInfo.mnuCd);
      }
    },
    async onDel() {
      this.callEvent({name: 'onDelete'});
    },
    onProgramCopy() {
      this.$modal.show('programCopy');
    },
    onProgramCopyClose() {
      this.onSearch();
    },
  },
};
</script>
