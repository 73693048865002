<template>
  <pmis-content-box>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col min-width="600px">
          <pmis-content-box>
            <template #title>정산정보</template>
            <iui-container-new type="css-flex">
              <i-row height="85px">
                <i-col>
                  <iui-container-new type="table" theme="bullet" header-width="100px">
                    <i-row>
                      <template v-if="userInfo.frmGb === $getConstants('FRM_GB_1').code">
                        <i-col-header>협력사명</i-col-header>
                        <i-col>
                          <iui-text :value="po.cusNm" :enable="false" />
                        </i-col>
                      </template>
                      <template v-if="userInfo.frmGb === $getConstants('FRM_GB_2').code">
                        <i-col-header>거래처명</i-col-header>
                        <i-col>
                          <iui-text :value="po.frmNm" :enable="false" />
                        </i-col>
                      </template>
                    </i-row>
                    <i-row>
                      <i-col-header>계약번호</i-col-header>
                      <i-col>
                        <iui-text :value="po.poNo" :enable="false" width="100px" align="center" />
                        <iui-text :value="po.poTtl" :enable="false" />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header colspan="2">
                        <span>정산내역</span>
                        <span>(공급가:</span>
                        <span>{{ bill.suppAmt || 0 }}원</span>
                        <span class="ml10">부가세:</span>
                        <span>{{ bill.vatAmt || 0 }}원</span>
                        <span class="ml10">비과세:</span>
                        <span>{{ bill.taxexe || 0 }}원</span>
                        <span class="ml10">합계금액:</span>
                        <span>{{ bill.totAmt || 0 }}원)</span>
                      </i-col-header>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
              <i-row>
                <i-col>
                  <ib-sheet :uid="_uid" :options="sheetOpt" :loadSearchData="loadSearchData" />
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
        <i-col width="50px" />
        <i-col min-width="250px" max-width="350px">
          <pmis-content-box>
            <template #title>세금계산서정보</template>
            <iui-container-new type="table" theme="bullet" header-width="105px">
              <i-row>
                <i-col-header>공급가</i-col-header>
                <i-col>
                  <iui-text type="amount" :value="bill.suppAmt" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>부가세</i-col-header>
                <i-col>
                  <iui-text type="amount" :value="bill.vatAmt" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>비과세</i-col-header>
                <i-col>
                  <iui-text type="amount" :value="bill.taxexe" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>합계</i-col-header>
                <i-col>
                  <iui-text type="amount" :value="bill.totAmt" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>작성일자</i-col-header>
                <i-col>
                  <iui-text width="120px" :value="bill.regDt" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>세금계산서번호</i-col-header>
                <i-col>
                  <iui-text
                    width="120px"
                    :value="billNoView"
                    :css="clickable_inputStyle"
                    :enable="!!bill.billNo"
                    readOnly
                    @click="openTaxInvoicePop('billNo')"
                  />
                  <pmis-file-list
                    name="flNo"
                    id="file0"
                    flNoCol="flNo"
                    title="첨부파일"
                    title-visible
                    :modal="true"
                    :hidden="!!bill.billNo"
                    :toolbar-visible="true"
                    :company-code="bill.orgCd"
                    :file-number.sync="bill.flNo"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>계산서번호</i-col-header>
                <i-col>
                  <iui-text
                    width="120px"
                    :value="billTaxNoView"
                    :css="clickable_inputStyle"
                    :enable="!!bill.billTaxNo"
                    readOnly
                    @click="openTaxInvoicePop('billTaxNo')"
                  />
                  <pmis-file-list
                    name="flTaxNo"
                    id="file1"
                    flNoCol="flTaxNo"
                    title="첨부파일"
                    title-visible
                    :modal="true"
                    :hidden="!!bill.billTaxNo"
                    :toolbar-visible="true"
                    :company-code="bill.orgCd"
                    :file-number.sync="bill.flTaxNo"
                  />
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
        <i-col width="50px" />
        <i-col min-width="470px">
          <pmis-content-box>
            <template #title>국세청송수신정보</template>
            <iui-container-new type="table" theme="bullet">
              <i-row>
                <i-col-header>송신방법</i-col-header>
                <i-col>
                  <iui-text :value="nts.transferMethodNm" :enable="false" />
                </i-col>
                <i-spacer />
                <i-col-header>요청일</i-col-header>
                <i-col>
                  <iui-text :value="nts.regDate" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>접수번호</i-col-header>
                <i-col>
                  <iui-text :value="nts.receiptId" :enable="false" />
                </i-col>
                <i-spacer />
                <i-col-header>메시지ID</i-col-header>
                <i-col>
                  <iui-text :value="nts.submitId" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>송수신상태</i-col-header>
                <i-col>
                  <iui-text :value="nts.statusCodeNm" :enable="false" />
                </i-col>
                <i-spacer />
                <i-col-header>결과코드</i-col-header>
                <i-col>
                  <iui-text :value="nts.resultStatusCode" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>결과메세지</i-col-header>
                <i-col>
                  <iui-text :value="nts.resultStatusMsg" :enable="false" />
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>

    <tax-invoice-pop :billNo="propsBillNo" :open.sync="propsOpen" pageGubun="sub" />
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/customerBillDetail.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import {selectBillInfos} from '@/view/subBidding/billManage/api/subBill.js';

import TaxInvoicePop from '@/view/bidding/components/TaxInvoicePop.vue';

export default {
  components: {TaxInvoicePop},
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],
      propsBillNo: '',
      propsOpen: false,

      po: {
        frmNm: '',
        cusNm: '',
        poNo: '',
        poTtl: '',
      },
      bill: {
        billNo: '',
        billTaxNo: '',
        billIssueDt: '',
        suppAmt: '',
        vatAmt: '',
        taxexe: '',
        totAmt: '',
        regDt: '',
        flNo: 0,
        flTaxNo: 0,
        orgCd: '',
      },
      nts: {
        transferMethod: '',
        transferMethodNm: '',
        regDate: '',
        submitId: '',
        statusCode: '',
        statusCodeNm: '',
        resultStatusCode: '',
        resultStatusMsg: '',
        receiptId: '',
      },

      clickable_inputStyle: {
        cursor: 'pointer',
        color: '#FF0000',
        'text-align': 'center',
        'text-decoration': 'underline',
        'font-weight': 'bold',
        'background-color': '#F0F0F0',
      },
    };
  },
  computed: {
    billNoView() {
      return COMMON_FUNCTION.getMakeFormat(this.bill.billNo);
    },
    billTaxNoView() {
      return COMMON_FUNCTION.getMakeFormat(this.bill.billTaxNo);
    },
  },
  created() {
    this.addEvent([
      {name: 'CustomerBillDetail_Init', func: this.onInit},
      {name: 'CustomerBillDetail_SetData', func: this.onSetData},
    ]);
  },
  methods: {
    onInit() {
      for (let key in this.po) this.po[key] = '';
      for (let key in this.bill) this.bill[key] = key === 'flNo' || key === 'flTaxNo' ? 0 : '';
      for (let key in this.nts) this.nts[key] = '';
      this.loadSearchData = [];
    },
    async onSetData(rowData) {
      this.onInit();

      const {pgmCd, prjCd, investMm, custCode, poNo, investKind, reqDegree, billNo, flNo, flTaxNo} = rowData;
      const param = {pgmCd, prjCd, investMm, custCode, poNo, investKind, reqDegree, billNo};
      const response = await selectBillInfos(param);

      if (response.data.subconItemList.length) {
        this.po.frmNm = rowData.frmNm;
        this.po.cusNm = rowData.cusNm;
        this.po.poNo = COMMON_FUNCTION.getMakeFormat(rowData.poNo);
        this.po.poTtl = rowData.poTtl;

        const {subconItemList, billInfo, ntsInfo} = response.data;

        this.loadSearchData = subconItemList;

        if (billInfo) {
          this.propsBillNo = billInfo.billNo;
          this.bill.billNo = billInfo.billNo;
          this.bill.billTaxNo = billInfo.billTaxNo;
          this.bill.suppAmt = billInfo.suppAmt;
          this.bill.vatAmt = billInfo.vatAmt;
          this.bill.taxexe = billInfo.taxexe;
          this.bill.totAmt = billInfo.totAmt;
          this.bill.regDt = billInfo.regDt;
        } else {
          this.bill.suppAmt = rowData.suppAmt;
          this.bill.vatAmt = rowData.vatAmt;
          this.bill.taxexe = rowData.taxexe;
          this.bill.totAmt = rowData.sumAmt;
        }
        this.bill.flNo = flNo;
        this.bill.flTaxNo = flTaxNo;
        this.bill.orgCd = pgmCd;

        if (ntsInfo) {
          this.nts.transferMethod = ntsInfo.transferMethod;
          this.nts.transferMethodNm = ntsInfo.transferMethodNm;
          this.nts.regDate = ntsInfo.regDate;
          this.nts.submitId = ntsInfo.submitId;
          this.nts.statusCode = ntsInfo.statusCode;
          this.nts.statusCodeNm = ntsInfo.statusCodeNm;
          this.nts.resultStatusCode = ntsInfo.resultStatusCode;
          this.nts.resultStatusMsg = ntsInfo.resultStatusMsg;
          this.nts.receiptId = ntsInfo.receiptId;
        }
      }
    },
    openTaxInvoicePop(billNoType) {
      this.propsBillNo = this.bill[billNoType];
      this.propsOpen = true;
    },
  },
};
</script>

<style scoped></style>
