<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <template-list :docTp="$getConstants('DOC_TP2').code" />
        </i-col>
        <i-spacer />
        <i-col>
          <detail-info :docTp="$getConstants('DOC_TP2').code" />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import TemplateList from '@/view/sysManage/baseSysManage/StandardTemplateRegist/components/TemplateList.vue';
import DetailInfo from '@/view/sysManage/baseSysManage/StandardTemplateRegist/components/DetailInfo.vue';
export default {
  components: {TemplateList, DetailInfo},
};
</script>

<style></style>
