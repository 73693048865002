export default that => {
  return {
    Cfg: {
      CanEdit: 0,
    },
    Cols: [
      {
        Header: '서식명',
        Name: 'tl',
        Type: 'Text',
        RelWidth: 1,
        Visible: 1,
      },
      {
        Name: 'fbsCd',
        Type: 'Text',
        Visible: 0,
      },
      {
        Name: 'fbsNo',
        Type: 'Text',
        Visible: 0,
      },
      {
        Name: 'rptNm',
        Type: 'Text',
        Visible: 0,
      },
      {
        Name: 'fomNo',
        Type: 'Text',
        Visible: 0,
      },
    ],
  };
};
