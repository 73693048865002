<template>
  <pmis-tab-box>
    <template #title>발주목록</template>
    <template #header-left>
      <i-header-multi-box>
        <div>
          <label>검색구분</label>
          <iui-select :items="searchTextTypeItems" :value.sync="searchOptions.searchTextType" />
          <iui-text type="search" :value.sync="searchOptions.searchText" @enter="onSearch" />
          <iui-button value="검색" @click="onSearch" />
        </div>
        <div>
          <label>접수구분</label>
          <iui-radio-group defaultCd="A" :items="orderStItems" :value.sync="searchOptions.orderSt" @change="onSearch" />
        </div>
      </i-header-multi-box>
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="orderList"
      :events="{
        onRenderFirstFinish: () => onSearch(),
        onSearchFinish: sheet_onSearchFinish,
        onClick: sheet_onClick,
      }"
    />
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/orderList.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
export default {
  data() {
    return {
      sheetOpt: sheetOpt(this),

      searchTextTypeItems: [
        {text: '거래처명', value: 'CustomerNm'},
        {text: '발주명', value: 'OrderNm'},
      ],
      orderStItems: [
        {label: '접수대기', value: BIDDING_CONSTANTS.PROGRESS_STATE.PO_SIGN_READY},
        {label: '접수', value: BIDDING_CONSTANTS.PROGRESS_STATE.PO_COMPLETE},
      ],

      searchOptions: {
        searchTextType: 'CustomerNm',
        searchText: '',
        orderSt: '',
      },
      focusKey: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, ['orderList']);
    $mapActions(this, ['searchOrderList', 'searchOrderInfos']);
  },
  created() {
    this.addFuncSearch(this.onClick_cmnSearch);
    this.addEvent({name: 'OrderList_onSearch', func: this.onSearch});
  },
  methods: {
    onClick_cmnSearch() {
      for (const key in this.searchOptions) {
        this.searchOptions[key] = '';
      }
      this.searchOptions['searchTextType'] = this.searchTextTypeItems[0]['value'];
      this.onSearch();
    },
    onSearch(focusKey = '') {
      this.focusKey = typeof focusKey === 'string' ? focusKey : '';
      this.searchOrderList(this.searchOptions);
    },
    setRowData(row) {
      if (row) {
        this.searchOrderInfos(row.poNo);
      }
    },
    sheet_onSearchFinish(e) {
      if (e.sheet.getTotalRowCount()) {
        const focusRow = this.focusKey
          ? e.sheet.getDataRows.find(row => row.poNo === this.focusKey)
          : e.sheet.getFirstRow();

        e.sheet.focus(focusRow);
        this.setRowData(focusRow);
      }
      this.focusKey = '';
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data') {
        this.setRowData(e.row);
      }
    },
  },
};
</script>

<style></style>
