import IBSheetLoader from '@ibsheet/loader';
import SheetUtil from '@/util/SheetUtil';
window.APP_BASE_URL = process.env.VUE_APP_FILE_URL;
IBSheetLoader.config({
  registry: [
    {
      name: 'ibsheet',
      baseUrl: './Sheet/js', // ibsheet.js 파일 위치
      theme: 'default', // css 파일 테마  (설정이 없으면 /css/default/main.js 사용)
      //   locales: ['en', 'ko'], // locale 파일 (설정이 없으면 /locale/ko.js 파일 사용)
      plugins: [
        // plugin 파일
        'dialog',
        'common',
        'excel',
      ],

      // 정식 라이센스
      license:
        'W2FtSztPKCJybjY1YjJxbn9SMVxtHyk2ZG0mO3J6LGs4bkN0WC1HZy1ybGd/cyZ0ITdlViJILBt9cHgjanptJT9rISYMZwc6RXAoP35wPWxpcSpzLVdmAjpVJjBwPnFpOiN/eC43EGdOaR00fjBjJWlqNTBpM3NbNARvVnc9eG09N2ZmNWw1Y1h/EDVLKGYnc3IpOXp2K24OGC4XZxRUBF9WKmR6YDNoIi4ZOEU=',
    },
  ],
});

IBSheetLoader.sheetReady(ibsheet => {
  import('@/assets/css/ibsheet.scss');
});

window.PMISSheet = {
  createSheet: async param => {
    let isMask = false;
    const calcOrderItems = ['sheet_quantity', 'sheet_unitAmount', 'sheet_amount', 'sheet_rate'];
    if (param.options.Cols) {
      param.options.Cols.some(el => {
        if (el.Extend && calcOrderItems.indexOf[el.Extend.Class] !== -1) {
          isMask = true;
          return true;
        }
      });
    }

    if (isMask) {
      const onResultMask = e => {
        const val = $_numberFormat(e.val, false);
        e.sheet.setValue(e.row, e.col, val, 1);
        return 2;
      };
      param.options.Events = {...param.options.Events, onResultMask: onResultMask};
    }

    let sheet;
    let _error;
    try {
      sheet = await IBSheetLoader.createSheet(param);
      sheet.util = new SheetUtil(sheet);
    } catch (error) {
      _error = error;
    }

    return new Promise((resolve, reject) => {
      if (_error) {
        reject(_error);
      } else {
        resolve(sheet);
      }
    });
  },
};
