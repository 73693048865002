<template>
  <pmis-content-box :loading="loading">
    <template v-slot:header-left>
      <iui-text type="search" :value.sync="searchNm" @enter="searchByItemNm" />
      <iui-button value="검색" @click="searchByItemNm" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onSearchFinish: onSearchFinish,
        onClick: onClick,
        onDblClick: onDblClick,
      }"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/WorkTypeClSheet.js';
import {selectWorkTypeClList, deleteWorkTypeCl} from '../api/StdWorkTypeRegist.js';
export default {
  props: {
    search: {
      type: Boolean,
      default: true,
    },
    reload: {
      type: Boolean,
      default: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
    base: {
      type: Boolean,
      default: false,
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    confirm: {
      type: Boolean,
      default: false,
    },
    popup: {
      type: Boolean,
      default: false,
    },
    workTypeClInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      sheet: undefined,
      Options: this.checkbox ? $addColCheckbox(options(this)) : options(this),
      loadSearchData: [],
      loading: false,
      searchNm: '',
      returnCols: ['code', 'name', 'wcode', 'wname', 'lgCode', 'lgName', 'mgCode', 'mgName', 'level'],
    };
  },
  watch: {
    search(value) {
      if (value) {
        this.onSearch();
      }
    },
    reload(value) {
      if (value) {
        this.onReloadSheet();
      }
    },
    confirm(value) {
      if (value) {
        this.callback(this.getRowsByChecked());
        this.$emit('update:confirm', false);
      }
    },
  },
  created() {
    this.onSearch();
    if (!this.popup) {
      this.addFuncDelete(this.onDelete);
    }
  },

  methods: {
    async onSearch() {
      const response = await selectWorkTypeClList({base: this.base});
      let tree = $_treeModelStdCl(
        response.data,
        $getConstants('WORK_TYPE_CL').code,
        'code',
        'upCode',
        undefined,
        undefined,
        'name'
      );
      this.loadSearchData = tree({level: 1});
      this.$emit('update:search', false);
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      if (this.checkbox) {
        sheet.util.onTreeCheck('chk');
      }
    },
    onSearchFinish(e) {
      this.loading = false;
      if (0 < e.sheet.getTotalRowCount()) {
        e.sheet.showTreeLevel(3, 0, 1);
      }
      this.onCallback(this.rowsFilter(e.sheet.getFirstRow()));
    },
    rowsFilter(row) {
      let rowObj = {};
      for (let key in row) {
        if (this.returnCols.indexOf(key) != -1) {
          rowObj[key] = row[key];
        }
      }
      return rowObj;
    },
    onClick(e) {
      this.setCud('0');
      this.initSearchNm();
      this.onCallback(this.rowsFilter(e.row));
    },
    onDblClick() {
      this.$modalConfirm();
    },
    async onDelete() {
      let row = this.sheet.getFocusedRow();
      if (row === null) {
        this.$alert({title: '삭제', message: '삭제대상이 존재하지 않습니다.'});
        return;
      }

      let name = String(row.name);
      let level = Number(row.level);
      if (level === 1) {
        this.$alert({title: '삭제', message: '최상위 항목은 삭제할 수 없습니다.'});
        return;
      }

      let workLevel = level == 2 ? '공사구분' : level == 3 ? '대공종' : '중공종'; // 표준공종분류
      let message = '';
      if (level !== 4) {
        message = `* 삭제대상 : ${name} [${workLevel}]<br/>해당 공종 삭제 시 하위 공종들도 모두 삭제됩니다.<br/>삭제하시겠습니까?`;
      } else {
        message = '삭제하시겠습니까?';
      }
      if (
        !(await this.$confirm({
          title: '삭제',
          message: message,
        }))
      ) {
        return;
      }

      let param = {
        pgmCd: row.pgmCd,
        level: row.level,
        code: row.code,
        wcode: row.wcode,
        lgCode: row.lgCode,
        mgCode: row.mgCode,
        base: this.base,
      };

      try {
        const response = await deleteWorkTypeCl(param);
        if (response.data) {
          let focusedRow = this.sheet.getFocusedRow();
          this.sheet.removeRow(focusedRow);
          let upRow = this.sheet.getDataRows().find(row => row.code == focusedRow.upCode);
          if (upRow) {
            this.sheet.focus(upRow);
            this.onCallback(this.rowsFilter(upRow));
          }
        }
      } catch (error) {
        this.$alert({title: '삭제실패', message: error.message});
      }
    },
    onReloadSheet() {
      const row = this.sheet.getFocusedRow();
      if (this.cud === 1) {
        let addRow = this.sheet.addRow({
          parent: row,
          init: {
            ...this.workTypeClInfo,
            pgmCd: row.pgmCd,
            level: row.level + 1,
            code: this.workTypeClInfo.code,
            name: this.workTypeClInfo.name,
            upCode: row.code,
            upName: row.name,
          },
        });
        this.sheet.acceptChangedData(addRow);
        this.onCallback(this.rowsFilter(addRow));
      } else if (this.cud === 2) {
        for (let key in this.workTypeClInfo) {
          if (row[key] !== this.workTypeClInfo[key]) {
            this.sheet.setValue(row, key, this.workTypeClInfo[key], 1);
          }
        }
        this.sheet.acceptChangedData(row);
      }
      this.$emit('update:reload', false);
      this.setCud('0');
    },
    getRowsByChecked() {
      return this.sheet.getRowsByChecked('chk').map(row => this.rowsFilter(row));
    },
    searchByItemNm() {
      this.$emit('searchByItemNm', this.searchNm);
      this.sheet.util.treeSearch(['name', 'code'], this.searchNm);
      setTimeout(() => {
        this.onCallback(this.rowsFilter(this.sheet.getFocusedRow()));
      }, 100);
    },
    initSearchNm() {
      this.searchNm = '';
    },
    onCallback(data) {
      if (!this.popup) {
        this.callback(data);
      }
    },
  },
};
</script>

<style scoped></style>
