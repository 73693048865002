<template>
  <pmis-tab-box>
    <template v-slot:title>정산금액</template>
    <form onsubmit="return false;">
      <p class="bu_title">금월정산금액</p>
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header>
            가동일수
          </i-col-header>
          <i-col>
            <iui-text type="number" width="120px" :enable="false" :value="detailInfo.monthlyDay" />
          </i-col>
          <i-col-header>
            총가동시간
          </i-col-header>
          <i-col>
            <iui-text type="number" width="120px" :enable="false" :value="detailInfo.monthlyQty" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            총가동금액
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.monthlyAmt" />
          </i-col>
          <i-col-header>
            유류대
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.oilAmt" />
          </i-col>
        </i-row>
      </iui-container-new>
      <hr />
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header>
            유류대공제
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :value="detailInfo.oilDeductAmt" :enable="false" />
          </i-col>
          <i-col-header>
            기타공제
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :value="detailInfo.etcDeductAmt"
              :enable="!closeYn"
              @change="setEtcDeductAmt(Number($event.target.value))"
            />
          </i-col>
        </i-row>
      </iui-container-new>
      <hr />
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header>
            공급가
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.amt" />
          </i-col>
          <i-col-header>
            부가세
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :enable="!closeYn"
              :value="detailInfo.vatAmt"
              @change="setVatAmt(Number($event.target.value))"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            비과세
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :enable="!closeYn"
              :value="detailInfo.taxexe"
              @change="setTaxexe(Number($event.target.value))"
            />
          </i-col>
          <i-col-header>
            합계
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.sumAmt" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            세금계산서
          </i-col-header>
          <i-col>
            <pmis-file-list title="세금계산서" id="flNo" :modal="true" :fileNumber="detailInfo.flNo" />
          </i-col>
          <i-col> </i-col>
          <i-col> </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            계산서
          </i-col-header>
          <i-col
            ><pmis-file-list
              title="계산서"
              id="flTaxNo"
              :modal="true"
              :fileNumber="detailInfo.flTaxNo"
              flNoCol="flTaxNo"
            />
          </i-col>
          <i-col> </i-col>
          <i-col> </i-col>
        </i-row>
      </iui-container-new>

      <iui-modal name="PrintModal" title="출력" size-type="size6">
        <report-viewer :fomNo="fomNo" :ozContent="ozContent" />
      </iui-modal>
    </form>
  </pmis-tab-box>
</template>

<script>
import FOM_NO from '@/constants/fomNoConstants.js';
import {selectReportData} from '@/view/closing/fixedTime/equipment/api/fixedtimeEquipment.js';
import ReportViewer from '@/view/bidding/components/ReportViewer.vue';
export default {
  components: {ReportViewer},
  data() {
    return {
      fomNo: FOM_NO.SETTLEMENT_FIXED_TIME_EQUIPMENT,
      ozContent: {},
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'detailInfo', 'closeYn']);
    $mapActions(this, ['setEtcDeductAmt', 'setVatAmt', 'setTaxexe']);
  },
  created() {
    this.addFuncPrint(this.onPrint);
  },
  methods: {
    async onPrint() {
      const investMm = this.searchInfo.investMm;
      const reportData = (await selectReportData({investMm})).data;

      this.ozContent = {
        investMm: `${investMm.substring(0, 4)}년 ${investMm.substring(4)}월`,
        prjNm: this.getPrjInfo().prjNm,
        settlementList: JSON.stringify({settlementList: reportData}),
      };
      this.$modal.show('PrintModal');
    },
  },
};
</script>

<style scoped>
hr {
  height: 1px;
  border: 0;
  background-color: #ccc;
  margin: 10px 5px;
}
</style>
