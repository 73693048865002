export default that => {
  return {
    Cfg: {
      CanEdit: false,
    },
    Cols: [
      {
        Header: '단가코드',
        Name: 'ucstCode',
        Type: 'Text',
        Align: 'Left',
        Size: 2,
        RelWidth: 1,
      },
      {
        Header: '단가명칭',
        Name: 'ucstName',
        Type: 'Text',
        Size: 16,
        RelWidth: 1,
      },
    ],
  };
};
