<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>단위작업실적물량</template>
            <UnitOpertExecInq />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import PrjList from '@/view/Project/components/PrjList.vue';
import UnitOpertExecInq from './components/UnitOpertExecInq.vue';
export default {
  components: {
    PrjList,
    UnitOpertExecInq,
  },

  beforeCreate() {},

  created() {},

  methods: {},
};
</script>
