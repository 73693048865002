<template>
  <div>
    <div class="join_step02 mb30"><span class="blind">step02 건설사 및 협력업체 선택</span></div>
    <div class="choice_desc">건설사이거나 협력업체이신가요?</div>
    <div class="choice_area">
      <a href="#" target="_top" class="choice01" @click="nextStep('000001')"
        ><span class="blind">건설사 가입하기</span></a
      >
      <a href="#" target="_top" class="choice02" @click="nextStep('000002')"
        ><span class="blind">협력업체 공사용역 가입하기</span></a
      >
      <a href="#" target="_top" class="choice03" @click="nextStep('000003')"
        ><span class="blind">협력업체 물품자재 가입하기</span></a
      >
    </div>
  </div>
  <!-- contents end -->
</template>

<script>
export default {
  methods: {
    nextStep(frmGb) {
      this.$parent.nextStep('03', frmGb);
    },
  },
};
</script>

<style scoped></style>
