<template>
  <iui-container-new type="css-flex">
    ebiz4u와 연동하기 위해서 전화번호와 이메일이 필요합니다.
    <i-row>
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header required>전화번호</i-col-header>
          <i-col>
            <iui-text
              required
              type="phone"
              name="tlno"
              :value="internalTlno"
              @change="changeTlno($event)"
              width="130px"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header required>이메일</i-col-header>
          <i-col>
            <iui-text
              required
              name="email1"
              type="email1"
              width="110px"
              max-length="50"
              :value="internalEmail1"
              @change="changeEmail1($event.target.value)"
            />
            <div class="mr5">@</div>
            <iui-select
              width="100px"
              :p-code="$getConstants('EMAIL_CD').code"
              :value="isEmail2Code ? '' : internalEmail2"
              :required="!isEmail2Code"
              defaultCd="직접입력"
              @change="value => changeEmail2(value)"
            />
            <iui-text
              type="email2"
              width="100px"
              max-length="50"
              :value="internalEmail2"
              v-if="isEmail2Code"
              :required="isEmail2Code"
              @change="changeEmail2($event.target.value)"
            />
          </i-col>
        </i-row>
      </iui-container-new>
    </i-row>
  </iui-container-new>
</template>

<script>
import {updateWorkerInfo} from '@/view/user/Worker/api/worker.js';
export default {
  props: {
    tlno: {
      type: String,
      default: null,
    },
    email: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      internalTlno: null,
      internalEmail1: null,
      internalEmail2: null,
    };
  },
  created() {
    this.addEvent({name: 'updateTlno', func: this.updateTlno});
    this.internalTlno = this.tlno;
    this.internalEmail1 = this.email?.split('@')[0];
    this.internalEmail2 = this.email?.split('@')[1];
  },
  computed: {
    isEmail2Code() {
      return String(this.internalEmail2).indexOf('0000') == -1;
    },
    valueEmail2() {
      return this.internalEmail2?.indexOf('0000') == -1 ? '' : this.internalEmail2;
    },
  },
  methods: {
    changeTlno(e) {
      this.internalTlno = e.target.value;
    },
    changeEmail1(e) {
      this.internalEmail1 = e;
    },
    changeEmail2(e) {
      this.internalEmail2 = e;
    },
    async updateTlno() {
      if (!this.internalTlno) {
        this.$alert({title: '전화번호 저장', message: '전화번호를 입력해주세요.'});
        return;
      }
      if (!this.internalEmail1 || !this.internalEmail2) {
        this.$alert({title: '이메일 저장', message: '이메일을 입력해주세요.'});
        return;
      }

      let param = {
        empNo: this.userInfo.empNo,
        tlno: this.internalTlno,
        email1: this.internalEmail1,
        email2: this.internalEmail2,
      };
      const response = await updateWorkerInfo(param);
      $_ebiz4u(this.userInfo.pgmCd, this.userInfo.empNo);
    },
  },
};
</script>

<style></style>
