<!-- 
Name : iui-container 
Description : 

attr [{
    name:               (String)   타이틀제목
    required:           (Boolean)  타이틀 필수항목
    direction:          (String)   가로(row),새로(column)    
    verticalAlign:      (String)   수직정렬 (top, middel, bottom)
    align:              (String)   수평정렬 (left, center, right)
    titFlex:            (Int)      타이틀 비율 flex-grow  default 1
    flex:               (Int)      비율 flex-grow         default 1
    sRow:               (Array)    열 안에 여러행을 넣을때 사용 
        ex {
            name:'도급사코드', // 타이틀
            required: false,  // 필수여부표시
            slot:'s1'         // 슬롯명
        }
}]    

-->
<template>
  <div class="_container" :class="{last: isLast}">
    <div
      class="item"
      v-for="(atr, i) in attr"
      :key="i"
      :class="[
            atr.direction=='column' || (atr.sRow&&atr.sRow.length) ? 'flex-direction-column':'flex-direction-row',
            (!atr.sRow || !atr.sRow.length) && atr.direction=='column'&&atr.verticalAlign=='top' ? 'justify-content-start':'',
            (!atr.sRow || !atr.sRow.length) && atr.direction=='column'&&atr.verticalAlign=='middel' ? 'justify-content-center':'',
            (!atr.sRow || !atr.sRow.length) && atr.direction=='column'&&atr.verticalAlign=='bottom' ? 'justify-content-end':'',
            (!atr.sRow || !atr.sRow.length) && atr.direction=='column'&&atr.align=='left' ? 'align-items-start':'',
            (!atr.sRow || !atr.sRow.length) && atr.direction=='column'&&atr.align=='center' ? 'align-items-center':'',
            (!atr.sRow || !atr.sRow.length) && atr.direction=='column'&atr.align=='right' ? 'align-items-end':'',
            (!atr.sRow || !atr.sRow.length) && atr.direction=='row'&&atr.verticalAlign=='top' ? 'align-items-start':'',
            (!atr.sRow || !atr.sRow.length) && atr.direction=='row'&&atr.verticalAlign=='middel' ? 'align-items-center':'',
            (!atr.sRow || !atr.sRow.length) && atr.direction=='row'&&atr.verticalAlign=='bottom' ? 'align-items-end':'',
            (!atr.sRow || !atr.sRow.length) && atr.direction=='row'&&atr.align=='left' ? 'justify-content-start':'',
            (!atr.sRow || !atr.sRow.length) && atr.direction=='row'&&atr.align=='center' ? 'justify-content-center':'',
            (!atr.sRow || !atr.sRow.length) && atr.direction=='row'&&atr.align=='right' ? 'justify-content-end':'',                    
            atr.name ? 'tit':'slot',
            
        ]"
      :style="{'flex': atr.flex ? atr.flex : 1, background: atr.label ? '#666':'', color: atr.label ? '#fff':'', padding: atr.label ? '5px 0':''}"
    >
      <span class="req" title="필수항목" v-if="atr.required && atr.name">＊</span>
      <span class="nonReq" title v-else-if="!atr.required && atr.name"></span>
      <!-- <span>{{atr.name}}</span> -->
      <span v-if="atr.name" v-html="atr.name"></span>
      <slot v-if="!atr.name && (!atr.sRow || !atr.sRow.length)" :name="i"></slot>

      <div class="item" v-for="(row, j) in atr.sRow" :key="j" style="flex-direction: row;">
        <div
          class="item tit"
          :style="{'flex': row.titFlex ? row.titFlex : 1}"
          :class="[
                    atr.titVerticalAlign=='top' ? 'align-items-start':'',
                    atr.titVerticalAlign=='middel' ? 'align-items-center':'',
                    atr.titVerticalAlign=='bottom' ? 'align-items-end':'',
                    !atr.titVerticalAlign ? 'align-items-center':'',
                    atr.titAlign=='left' ? 'justify-content-start':'',
                    atr.titAlign=='center' ? 'justify-content-center':'',
                    atr.titAlign=='right' ? 'justify-content-end':'',
                    !atr.titAlign ? 'justify-content-start':''
                    
                ]"
        >
          <span class="req" title="필수항목" v-if="row.required && row.name">＊</span>
          <span class="nonReq" title v-else-if="!row.required && row.name"></span>
          <span v-if="row.name" v-html="row.name"></span>
          <slot v-if="!row.name && (!row.sRow || !row.sRow.length)" :name="row.slot"></slot>
        </div>

        <div
          class="item slot"
          :style="{'flex': row.flex ? row.flex : 1}"
          :class="[
                    atr.direction=='column' ? 'flex-direction-column':'flex-direction-row',
                    atr.direction=='column'&&atr.verticalAlign=='top' ? 'justify-content-start':'',
                    atr.direction=='column'&&atr.verticalAlign=='middel' ? 'justify-content-center':'',
                    atr.direction=='column'&&atr.verticalAlign=='bottom' ? 'justify-content-end':'',
                    atr.direction=='column'&&atr.align=='left' ? 'align-items-start':'',
                    atr.direction=='column'&&atr.align=='center' ? 'align-items-center':'',
                    atr.direction=='column'&atr.align=='right' ? 'align-items-end':'',
                    atr.verticalAlign=='top' ? 'align-items-start':'',
                    atr.direction=='row'&&atr.verticalAlign=='middel' ? 'align-items-center':'',
                    atr.direction=='row'&&atr.verticalAlign=='bottom' ? 'align-items-end':'',
                    atr.direction=='row'&&atr.align=='left' ? 'justify-content-start':'',
                    atr.direction=='row'&&atr.align=='center' ? 'justify-content-center':'',
                    atr.direction=='row'&&atr.align=='right' ? 'justify-content-end':'',
                    
                    
                ]"
        >
          <slot :name="row.slot"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "iui-container",
  data: function() {
    return {
      isShow: !true
    };
  },
  created: function() {},
  props: {
    attr: {
      type: Array
    },
    isLast: {
      type: Boolean
    }
  },
  mounted: function() {},
  methods: {},
  computed: {}
};
</script>

<style scoped>
._container {
  margin-bottom: 0px;
  display: flex;
  margin: auto;
  flex-flow: row wrap;
}
.item {
  display: flex;
}

.item.tit {
  border-top: 1px solid #d2d2d2;
  background: #f5f5f5;
}
.item.tit span {
  background: #f5f5f5;
  font-weight: bold;
  padding: 0 5px 0 3px !important;
}
.item.tit span.req {
  color: #ff4e00;
  font-size: 0.9em;
  width: 12px;
  padding: 0 0px 0 3px !important;
  text-align: right;
}
.item.tit span.nonReq {
  width: 12px;
  height: 3px;
  padding: 0 0px 0 3px !important;
  margin: 0;
}

.flex-direction-column {
  flex-direction: column;
}
.flex-direction-column-r {
  flex-direction: column-reverse;
}
.flex-direction-row {
  flex-direction: row;
}
.flex-direction-row-r {
  flex-direction: row-reverse;
}

.align-items-start {
  align-items: flex-start;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}

.justify-content-start {
  justify-content: flex-start;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}

.last {
  border-bottom: 1px solid #d2d2d2;
}
</style>