export default taht => {
  return {
    Cfg: {
      MainCol: 'treeNm', // 트리가 표시될 열이름
      CanEdit: 0, // 1: 전체 편집 가능 (default)
      NoTreeLines: 0, // 트리를 사용하는 시트 생성시 노드와 노드 사이의 연결선을 표시할 지 여부를 설정
    },
    Def: {
      Header: {
        Hidden: 1,
      },
    },
    Cols: [
      {
        Header: '명칭',
        Name: 'treeNm',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
      },
    ],
  };
};
