import padStart from 'lodash/padStart';
import BIDDING_CONSTANTS from './BiddingConstants.js';

const BIDDING_CODES = BIDDING_CONSTANTS.CODES;

export default {
  /**
   * PR_NO, RFQ_NO, PO_NO 조회용 코드로 변환 (00000000-0000)
   *
   * @param {String} key
   */
  getMakeFormat: key => (key ? `${key.substring(8, 16)}-${key.substring(16)}` : ''),

  timeFormat: time => padStart(time.toString().replace(/[^0-9]/g, ''), 2, '0'),

  /**
   * 인증서 등록 확인
   *
   * @returns
   */
  async checkCertdnRegistered(that, bidType) {
    const certdn = that.userInfo.certdn;

    if (!certdn) {
      const title = '인증서 등록';
      const message =
        'PMIS를 이용하기 위해서는 발급받은 인증서 및 보유하고 있는 공동인증서(구 공인인증서)를 등록해야 합니다.<br/><br/>인증서를 등록하시겠습니까?';
      if (await that.$confirm({title, message})) {
        const companyType = that.userInfo.frmGb === '000001' ? 'con' : 'cus';
        const ctrRegistMenu = {
          con: {mat: 'CERT_REGIST_MAT', sub: 'CERT_REGIST_WOK'},
          cus: {mat: 'CERT_REGIST_BMT', sub: 'CERT_REGIST_BWK'},
        };
        that.goMenu(ctrRegistMenu[companyType][bidType]);
      }
      return false;
    }
    return true;
  },

  /**
   * 과세유형에 따른 부가세액, 합계금액 계산
   *
   * @param {*} obj
   * amt, taxTypecd, taxDirectDs,
   * taxRate, taxTypeDirectPercent, taxTypeDirectAmt
   *
   */
  onCalculateTaxAndTotAmt(obj) {
    const amt = parseInt(obj.amt) || 0; // 내역합계 금액
    const taxTypecd = obj.taxTypecd || BIDDING_CODES.TAX; // 과세유형
    const taxDirectDs = obj.taxDirectDs; // 과세 - 직접입력 유형 (% or 원)

    let taxRate = Number(obj.taxRate) || 0; // 부가세율
    let vatAmt = 0; // 부가세

    // 직접입력 부가세율, 부가세액
    let [taxTypeDirectPercent, taxTypeDirectAmt] = [obj.taxTypeDirectPercent, obj.taxTypeDirectAmt].map(num => {
      num = Number(num) || 0;
      return !num ? '' : Number.isInteger(num) ? num : parseFloat(num.toFixed(2));
    });

    if (0 < amt) {
      if (taxTypecd === BIDDING_CODES.TAX_DIRECT) {
        // 과세유형 > 직접 입력
        if (taxDirectDs === BIDDING_CODES.TAX_DIRECT_PERCENT) {
          // 직접 입력(%)
          taxRate = taxTypeDirectPercent; // 부가세율 적용
          if (taxRate) {
            vatAmt = $_getVatAsPercent(amt, 0, taxRate);
            taxTypeDirectPercent = taxRate;
            taxTypeDirectAmt = vatAmt;
          } else {
            taxTypeDirectPercent = '';
            taxTypeDirectAmt = '';
          }
        } else if (taxDirectDs === BIDDING_CODES.TAX_DIRECT_AMOUNT) {
          // 직접 입력(원)
          vatAmt = taxTypeDirectAmt;
          taxTypeDirectPercent = !vatAmt ? '' : ((vatAmt / amt) * 100).toFixed(2) || 0;
          taxRate = taxTypeDirectPercent;
        }
      } else {
        // 과세 || 비과세
        taxRate = taxTypecd === BIDDING_CODES.TAX_EXEMPTION ? 0 : 10; // 과세유형 : 비과세 ? 0 : 10
        vatAmt = $_getVatAsPercent(amt, 0, taxRate);
        taxTypeDirectPercent = '';
        taxTypeDirectAmt = '';
      }
    }

    vatAmt = parseInt(vatAmt || 0);
    const totAmt = $_getSumAmt(amt, vatAmt);
    const stampTaxAmt = this.onCalculateStampTaxAmt(totAmt);

    return {
      amt,
      taxRate,
      vatAmt: parseInt(vatAmt),
      totAmt,
      taxTypeDirectPercent,
      taxTypeDirectAmt,
      ...stampTaxAmt,
    };
  },

  /**
   * 합계금액에 따른 인지세 계산 (2022.01.20 기준)
   *
   * @param {*} obj
   */
  onCalculateStampTaxAmt(totAmt = 0) {
    let stampTaxAmt = 0;

    if (totAmt) {
      switch (true) {
        case $_isNumberBetween(totAmt, 0, 10000000, false, true): // 1천만원 이하(1천만원 포함) : 면제
          stampTaxAmt = 0;
          break;
        case $_isNumberBetween(totAmt, 10000000, 30000000, false, true): // 1천만원 초과 && 3천만원 이하 : 2만원
          stampTaxAmt = 20000;
          break;
        case $_isNumberBetween(totAmt, 30000000, 50000000, false, true): // 3천만원 초과 && 5천만원 이하 : 4만원
          stampTaxAmt = 40000;
          break;
        case $_isNumberBetween(totAmt, 50000000, 100000000, false, true): // 5천만원 초과 && 1억원 이하 : 7만원
          stampTaxAmt = 70000;
          break;
        case $_isNumberBetween(totAmt, 100000000, 1000000000, false, true): // 1억원 초과 && 10억원 이하 : 15만원
          stampTaxAmt = 150000;
          break;
        case 1000000000 < totAmt: // 10억원 초과 : 35만원
          stampTaxAmt = 350000;
          break;
      }
    }
    const stampTaxYn = stampTaxAmt ? BIDDING_CODES.STAMP_TAX_Y : BIDDING_CODES.STAMP_TAX_N;

    return {stampTaxAmt, stampTaxYn};
  },

  /**
   * 지급율 계산 (1) : 현금지급비율 이용한 어음지급비율, 어음대체수단비율 계산
   *                  (참고) 현재 어음대체수단비율은 계약업무에만 있음
   *
   * @param {*} obj
   * payCashRatio : 현금지급비율
   */
  onCalculatePayRatio1(obj) {
    const value = isNaN(obj.payCashRatio) ? 0 : obj.payCashRatio;

    if (100 < value) {
      setTimeout(() => {
        obj.payCashRatio = '';
        obj.payCheckRatio = '';
        if (obj.payCheck2Ratio !== undefined) obj.payCheck2Ratio = '';
      }, 100);
    } else {
      obj.payCashRatio = parseFloat(value).toFixed(2);
      obj.payCheckRatio = parseFloat(100.0 - parseFloat(value)).toFixed(2);
      if (obj.payCheck2Ratio !== undefined) obj.payCheck2Ratio = 0;

      const n = Number(value);
      if (Number.isInteger(n)) {
        obj.payCashRatio = parseInt(n);
        obj.payCheckRatio = 100 - parseInt(n);
      } else {
        obj.payCashRatio = parseFloat(n.toFixed(2));
        obj.payCheckRatio = 100 - parseFloat(n.toFixed(2));
        if (obj.payCheck2Ratio !== undefined) obj.payCheck2Ratio = 0;
      }
    }
  },

  /**
   * 지급율 계산 (2) : 어음지급비율 이용한 현금지급비율 or 어음대체수단비율 계산
   *                  (참고) 현재 어음대체수단비율은 계약업무에만 있음
   *
   * @param {*} obj
   * payCashRatio : 현금지급비율
   * payCheckRatio : 어음지급비율
   * payCheck2Ratio : 어음대체수단비율
   */
  onCalculatePayRatio2(obj) {
    const value = isNaN(obj.payCheckRatio) ? 0 : obj.payCheckRatio;

    if (100 < value) {
      setTimeout(() => {
        obj.payCashRatio = '';
        obj.payCheckRatio = '';
        if (obj.payCheck2Ratio !== undefined) obj.payCheck2Ratio = '';
      }, 100);
    } else {
      if (obj.payCheck2Ratio !== undefined) {
        const v = 100 - obj.payCashRatio - value;
        if (Number.isInteger(v)) {
          obj.payCheck2Ratio = parseInt(v);
        } else {
          obj.payCheck2Ratio = parseFloat(v);
        }
      } else {
        const n = Number(value);
        if (Number.isInteger(n)) {
          obj.payCashRatio = 100 - parseInt(n);
          obj.payCheckRatio = parseInt(n);
        } else {
          obj.payCashRatio = 100 - parseFloat(n.toFixed(2));
          obj.payCheckRatio = parseFloat(n.toFixed(2));
        }
      }
    }
  },
};
