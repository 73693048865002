<template>
  <nav class="sub_tab">
    <li class="home selected">
      <a href="#HOME" class="sub_home_on" @click.prevent.self @click="goHome">
        <span class="blind">닫기</span>
      </a>
    </li>
    <draggable v-model="urls" group="people" class="df" :draggable="'.draggable'" ghost-class="ghost">
      <li
        v-for="url in urls"
        :key="url.mnuCd"
        class="draggable"
        @contextmenu="openContext($event, url)"
        @click="hideContext"
      >
        <a :href="'#' + url.url" :data-url="url.url" @click.prevent.self @click="routePush(url, $event)">
          {{ url.mnuNm }}
        </a>
        <a href="#close" class="btn_close_off" :data-url="url.url" @click.prevent.self @click="closeMdi">
          <span class="blind">닫기</span>
        </a>
      </li>
    </draggable>
    <li class="close">
      <a href="#" class="close_all" v-show="showClsBtn" @click.prevent.self @click="allCloseMdi"></a>
    </li>
    <div id="context" class="context" ref="context" v-if="isOpen">
      <div>
        <a @click="onPopupClick">팝업 열기</a>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapGetters} from 'vuex';
import {validParameter, validSheet} from '@/util/validUtil.js';
import draggable from 'vuedraggable';

export default {
  name: 'iui-mdi',
  components: {
    draggable,
  },
  data() {
    return {
      isOpen: false,
      selectUrl: {},
    };
  },
  computed: {
    ...mapGetters('mdi', {
      history: 'history',
    }),
    urls: {
      get() {
        return this.$store.getters['mdi/urls'];
      },
      set(array) {
        this.$store.dispatch('mdi/changeOrder', array);
      },
    },
    showClsBtn() {
      return this.urls.length > 1;
    },
  },
  mounted() {
    this.$el.querySelector('[href*="#HOME"]').click();
  },
  methods: {
    goHome(e) {
      for (let sibling of Array.from(document.querySelector('.sub_tab').querySelectorAll('li'))) {
        sibling.classList.remove('selected');
      }
      e.target.parentNode.classList.add('selected');
      e.target.parentNode.querySelector('[href="#HOME"]').classList.remove('sub_home_off');
      e.target.parentNode.querySelector('[href="#HOME"]').classList.add('sub_home_on');
      if (this.$router.currentRoute.path != '/Home') {
        this.$router.replace({path: '/Home', params: {}});
      }
      this.callEvent({name: 'MainHome_activated'});
    },
    /** 라우터 이동 */
    routePush(urls, e) {
      for (let sibling of Array.from(document.querySelector('.sub_tab').querySelectorAll('li'))) {
        sibling.classList.remove('selected');
        if (sibling.querySelector('[href="#HOME"]')) {
          sibling.querySelector('[href="#HOME"]').classList.remove('sub_home_on');
          sibling.querySelector('[href="#HOME"]').classList.add('sub_home_off');
        }

        if (sibling.querySelector('[href="#close"]')) {
          sibling.querySelector('[href="#close"]').classList.remove('btn_close_on');
          sibling.querySelector('[href="#close"]').classList.add('btn_close_off');
        }
      }
      e.target.parentNode.classList.add('selected');
      e.target.parentNode.querySelector('[href="#close"]').classList.add('btn_close_on');

      this.$router.replace({path: urls.url, params: {}}, () => {
        this.$store.dispatch('mdi/setUrl', urls);
        const historyLength = this.history[urls.url].length;
        if (historyLength) {
          const urls = this.$store.getters['mdi/urls'];
          const rootUrl = urls.find(url => url.isSelected).url;

          if (window.subVue && window.subVue[rootUrl]) {
            window.subVue[rootUrl].forEach(subVue => {
              subVue.$destroy();
            });
            delete window.subVue[rootUrl];
            this.$store.dispatch('mdi/popHistory', rootUrl);
          }
        }
      });
    },
    async closeMdi(e) {
      if (
        (validSheet() || validParameter(e.target.getAttribute('data-url'))) &&
        !(await this.$confirm({title: '종료', message: '변경된 사항이 있습니다.<br/>종료하시겠습니까?'}))
      ) {
        return;
      }
      this.$store.commit('destroy', e.target.dataset.url);
    },
    allCloseMdi() {
      this.$store.commit('destroyAll');
    },
    async openContext(e, url) {
      e.preventDefault();

      this.isOpen = true;
      this.selectUrl = url;
      await this.$nextTick();
      const context = this.$refs.context;
      context.style.top = `${e.clientY}px`;
      context.style.left = `${e.clientX}px`;
    },
    hideContext() {
      this.isOpen = false;
    },
    onPopupClick() {
      this.isOpen = false;

      window.$open(
        {
          name: this.selectUrl.mnuCd,
          param: this.selectUrl,
        },
        this
      );
    },
  },
};
</script>

<style scoped lang="scss">
.context {
  position: fixed;
  z-index: 9999;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #006ebe;

  div {
    padding: 0 10px 0 10px;
    border-radius: 5px;
    height: 30px;
    line-height: 30px;

    a {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}

::v-deep .ghost {
  background-color: #006ebe !important;
  opacity: 0.3;

  a {
    color: #ffffff !important;
  }
}
</style>
