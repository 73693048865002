<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="600px">
          <RfqList process="progress" />
        </i-col>
        <i-spacer />
        <i-col min-width="1100px">
          <AfterOpen v-if="rfqStatus.isAfterRfqOpen" />
          <BeforeOpen v-else />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from '../store/rfq.js';
import RfqList from '@/view/bidding/rfq/common/components/RfqList.vue';
import BeforeOpen from '@/view/bidding/rfq/mat/common/components/BeforeOpen.vue';
import AfterOpen from '@/view/bidding/rfq/mat/common/components/AfterOpen.vue';

export default {
  components: {RfqList, BeforeOpen, AfterOpen},
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['rfqStatus']);
  },
  created() {
    this.canNew = false;
    this.canDelete = false;
  },
};
</script>

<style></style>
