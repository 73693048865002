<template>
  <div class="card-carousel-wrapper">
    <div class="card-carousel--nav__left" @click="moveCarousel(-1)" :disabled="atHeadOfList"></div>
    <div class="card-carousel" @wheel.prevent.self @wheel="moveCarousel($event.deltaY > 0 ? 1 : -1)">
      <div class="card-carousel--overflow-container">
        <div
          class="card-carousel-cards"
          :style="{transform: 'translateX' + '(' + currentOffset + 'px' + ')'}"
          v-if="items.length"
        >
          <div class="card-carousel--card" v-for="(item, index) in items" :key="index">
            <img
              :src="item.thumbnailSrc"
              :style="{width: imgWidth + 'px', height: imgHeight + 'px'}"
              @click="onClickCard(index)"
            />
            <div class="card-carousel--card--footer">
              <p v-if="item.title">{{ item.title }}</p>
              <p v-if="item.des1">{{ item.des1 }}</p>
              <p v-if="item.des2">{{ item.des2 }}</p>
            </div>
          </div>
        </div>
        <div class="noImage" v-else>
          <slot name="noImage">noimage </slot>
        </div>
      </div>
    </div>
    <div class="card-carousel--nav__right" @click="moveCarousel(1)" :disabled="atEndOfList"></div>

    <viewer :images="images" :options="options" @inited="inited" v-show="false">
      <img v-for="(image, index) in images" :src="image" :key="index" v-show="false" />
    </viewer>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css';
export default {
  props: {
    imageWidth: {
      type: [Number],
      default: 200,
    },
    imageHeight: {
      type: [Number],
      default: 200,
    },
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * showViewer 제공되는 View를 사용한다..
     */
    showViewer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      imgWidth: this.imageWidth,
      imgHeight: this.imageHeight,
      currentOffset: 0,
      windowSize: 3,
      paginationFactor: Number(this.imageWidth + 20),
      images: [],
      idx: 0,
      options: {
        title: [1, i => `${i.alt.replace(/.jpeg|.jpg|.png/, '')}`],
        movable: false,
        loop: false,
        initialViewIndex: 2,
        fullscreen: false,
        // backdrop: false
      },
    };
  },
  computed: {
    atEndOfList() {
      return this.currentOffset <= this.paginationFactor * -1 * (this.items.length - this.windowSize);
    },
    atHeadOfList() {
      return this.currentOffset === 0;
    },
  },
  watch: {
    items(imgs) {
      this.currentOffset = 0;
      this.images = [];
      imgs.forEach(item => (item.imgSrc ? this.images.push(item.imgSrc) : []));
    },
  },
  created() {
    window.addEventListener('resize', this.resize);
    this.items.forEach(item => (item.imgSrc ? this.images.push(item.imgSrc) : []));
  },
  mounted() {
    this.resize();
  },
  methods: {
    moveCarousel(direction) {
      // Find a more elegant way to express the :style. consider using props to make it truly generic
      if (direction === 1 && !this.atEndOfList) {
        this.currentOffset -= this.paginationFactor;
      } else if (direction === -1 && !this.atHeadOfList) {
        this.currentOffset += this.paginationFactor;
      }
    },
    resize() {
      this.currentOffset = 0;
      if (document.querySelector('.card-carousel')) {
        document.querySelector('.card-carousel').style.width = '0px';
        let width = this.$parent.$el.offsetWidth - 130;
        document.querySelector('.card-carousel').style.width = width + 'px';
        let windowSize = width / (this.imgWidth + 10);
        this.windowSize = Math.floor(windowSize);

        if (document.querySelector('.noImage')) {
          document.querySelector('.noImage').style.width = width + 'px';
        }
      }
    },
    inited(viewer) {
      this.$viewer = viewer;
      this.$emit('inited', viewer);
    },
    show() {
      this.$viewer.show();
    },
    onClickCard(idx) {
      this.$emit('click-card', idx);
      if (this.showViewer) {
        this.view(idx);
      }
    },
    view(idx) {
      this.$viewer.view(idx);
    },
  },
};
</script>

<style scoped>
.card-carousel-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0 8px;
  color: #666a73;
}

.card-carousel {
  display: flex;
  justify-content: left;
  /* width: 640px; */
  /* width: 100%; */
}
.card-carousel--overflow-container {
  overflow: hidden;
}
.card-carousel--nav__left,
.card-carousel--nav__right {
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 10px;
  box-sizing: border-box;
  border-top: 2px solid #42b883;
  border-right: 2px solid #42b883;
  cursor: pointer;
  margin: 0 20px;
  transition: transform 150ms linear;
}
.card-carousel--nav__left[disabled],
.card-carousel--nav__right[disabled] {
  opacity: 0.2;
  border-color: black;
}
.card-carousel--nav__left {
  transform: rotate(-135deg);
}
.card-carousel--nav__left:active {
  transform: rotate(-135deg) scale(0.9);
}
.card-carousel--nav__right {
  transform: rotate(45deg);
}
.card-carousel--nav__right:active {
  transform: rotate(45deg) scale(0.9);
}

.card-carousel-cards {
  display: flex;
  transition: transform 150ms ease-out;
  transform: translatex(0px);
}
.card-carousel-cards .card-carousel--card {
  margin: 0 10px;
  cursor: pointer;
  box-shadow: 0 4px 15px 0 rgba(40, 44, 53, 0.06), 0 2px 2px 0 rgba(40, 44, 53, 0.08);
  background-color: #fff;
  border-radius: 4px;
  z-index: 3;
  margin-bottom: 2px;
}
.card-carousel-cards .card-carousel--card:first-child {
  margin-left: 0;
}
.card-carousel-cards .card-carousel--card:last-child {
  margin-right: 0;
}
.card-carousel-cards .card-carousel--card img {
  vertical-align: bottom;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: opacity 150ms linear;
  user-select: none;
}
.card-carousel-cards .card-carousel--card img:hover {
  opacity: 0.5;
}
.card-carousel-cards .card-carousel--card--footer {
  border-top: 0;
  padding: 7px 15px;
}
.card-carousel-cards .card-carousel--card--footer p {
  padding: 3px 0;
  margin: 0;
  margin-bottom: 2px;
  /* font-size: 19px; */
  font-weight: 500;
  color: #2c3e50;
  user-select: none;
}
.card-carousel-cards .card-carousel--card--footer p.tag {
  font-size: 11px;
  font-weight: 300;
  padding: 4px;
  background: rgba(40, 44, 53, 0.06);
  display: inline-block;
  position: relative;
  margin-left: 4px;
  color: #666a73;
}
.card-carousel-cards .card-carousel--card--footer p.tag:before {
  content: '';
  float: left;
  position: absolute;
  top: 0;
  left: -12px;
  width: 0;
  height: 0;
  border-color: transparent rgba(40, 44, 53, 0.06) transparent transparent;
  border-style: solid;
  border-width: 8px 12px 12px 0;
}
.card-carousel-cards .card-carousel--card--footer p.tag.secondary {
  margin-left: 0;
  border-left: 1.45px dashed white;
}
.card-carousel-cards .card-carousel--card--footer p.tag.secondary:before {
  display: none !important;
}
.card-carousel-cards .card-carousel--card--footer p.tag:after {
  content: '';
  position: absolute;
  top: 8px;
  left: -3px;
  float: left;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: white;
  box-shadow: -0px -0px 0px #004977;
}

h1 {
  font-size: 3.6em;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0;
  color: #42b883;
}
.noImage {
  text-align: center;
}
</style>
