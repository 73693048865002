<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="600px">
          <RfqList process="write" />
        </i-col>
        <i-spacer />
        <i-col min-width="1100px">
          <RfqInfo />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from '../store/rfq.js';
import RfqList from '@/view/bidding/rfq/common/components/RfqList.vue';
import RfqInfo from './components/RfqInfo.vue';
export default {
  components: {RfqList, RfqInfo},
  beforeCreate() {
    $init(this, store);
  },
};
</script>

<style></style>
