<template>
  <pmis-content-box>
    <template #header-left>
      <iui-radio-group :p-code="$getConstants('INO_DS').code" :value="inoDs" :enable="false" />
    </template>

    <iui-tab :comps="comps" :visibles="tabVisibles" :lazeLoad="false" :defaultIndex="tabIndex" @click="setTabIndex">
      <template v-slot:tab-content>
        <iui-container-new type="css-flex">
          <i-row>
            <i-col width="400px">
              <pmis-content-box v-show="tabIndex !== 2">
                <template v-slot:header-left>
                  <iui-text :value.sync="searchNm" @enter="onSearch" />
                  <iui-button @click="onSearch">검색</iui-button>
                </template>
                <ib-sheet
                  v-show="tabIndex === 0"
                  :uid="_uid"
                  :options="Options1"
                  :loadSearchData="loadSearchData1"
                  @loadSheet="loadSheet1"
                  :events="{
                    onSearchFinish: onSearchFinish1,
                    onClick: onClick1,
                  }"
                />
                <ib-sheet
                  v-show="tabIndex === 1"
                  :uid="_uid"
                  :options="Options2"
                  :loadSearchData="loadSearchData2"
                  @loadSheet="loadSheet2"
                  :events="{
                    onDblClick: onDblClick2,
                  }"
                />
              </pmis-content-box>
              <pmis-content-box v-show="tabIndex === 2">
                <div class="handBox">
                  <iui-container-new type="css-flex">
                    <i-row>
                      <i-col> </i-col>
                    </i-row>
                    <i-row height="30px">
                      <i-col align="center">
                        <iui-text width="350px" :value.sync="receiptInfo" @keyup.enter="addOfflineReceipt" />
                      </i-col>
                    </i-row>
                    <i-row height="30px">
                      <i-col></i-col>
                    </i-row>
                    <i-row height="25px">
                      <i-col align="center">
                        <span>이름 (우편번호 주소 번지수)</span>
                      </i-col>
                    </i-row>
                    <i-row height="25px">
                      <i-col align="center">
                        <span>예) 홍길동 (123-456 서울시 서초구 ...)</span>
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col> </i-col>
                    </i-row>
                    <i-row>
                      <i-col> </i-col>
                    </i-row>
                  </iui-container-new>
                </div>
              </pmis-content-box>
            </i-col>
            <i-col
              v-if="tabIndex !== 0"
              width="30px"
              style="display: flex; justify-content: center; align-items: center;"
            >
              <iui-button @click="onAdd">→</iui-button>
              <i-spacer />
              <iui-button @click="onRemove">←</iui-button>
            </i-col>
            <i-spacer v-else />

            <i-col>
              <iui-container-new type="css-flex">
                <i-row>
                  <i-col>
                    <pmis-content-box v-show="tabIndex !== 2">
                      <template v-slot:header-left>
                        <label>그룹명</label>
                        <iui-text width="400px" :value.sync="tl" :enable="isObs" max-length="100" />
                        <iui-button value="삭제" v-if="!isObs" @click="onDelete" />
                        <iui-button value="저장" v-if="isObs" @click="onSave" :enable="!!tl" />
                      </template>
                      <ib-sheet
                        :uid="_uid"
                        :options="Options3"
                        :loadSearchData="loadSearchData3"
                        @loadSheet="loadSheet3"
                        :events="{
                          onDblClick: onDblClick3,
                          onSearchFinish: onSearchFinish3,
                        }"
                      />
                    </pmis-content-box>
                    <pmis-content-box v-show="tabIndex === 2">
                      <ib-sheet
                        :uid="_uid"
                        :options="Options4"
                        :loadSearchData="loadSearchData4"
                        @loadSheet="loadSheet4"
                        :events="{
                          onDblClick: onDblClick4,
                          onSearchFinish: onSearchFinish4,
                        }"
                      />
                    </pmis-content-box>
                  </i-col>
                </i-row>
                <i-spacer v-if="!isDistribute" />
                <i-row height="45px" v-if="!isDistribute">
                  <i-col>
                    발신명의
                    <br />
                    <iui-select
                      name="sndNmn"
                      width="100%"
                      :items="sndNmnList"
                      defaultCd="S"
                      :value="internalSndNmnSeq"
                      @change="value => onChangeSndNmn(value)"
                    />
                  </i-col>
                </i-row>
              </iui-container-new>
            </i-col>
            <i-col width="30px" style="display: flex; justify-content: center; align-items: center;">
              <iui-button @click="setOrderUp">↑</iui-button>
              <i-spacer />
              <iui-button @click="setOrderDown">↓</iui-button>
            </i-col>
          </i-row>
        </iui-container-new>
      </template>
    </iui-tab>
  </pmis-content-box>
</template>

<script>
import options1 from './sheetOption/ReceiptSelectSheet1.js';
import options2 from './sheetOption/LineSelectSheet2.js';
import options3 from './sheetOption/ReceiptSelectSheet3.js';
import options4 from './sheetOption/ReceiptSelectSheet4.js';
import {selectRegCustomerList, selectWokerWithDeptList} from '../api/obs.js';
import {
  deleteReceiptGroup,
  saveReceiptGroup,
  selectReceiptGroupDList,
  selectReceiptGroupMList,
} from '../api/receipt.js';
import APPROVAL from '../../js/approvalConstants.js';

const selectObsList = {};
selectObsList[APPROVAL.INO_DS_IN] = selectWokerWithDeptList;
selectObsList[APPROVAL.INO_DS_OUT] = selectRegCustomerList;
export default {
  props: {
    receipt: {
      type: Object,
      default: undefined,
    },
    docCsDs: {
      type: String,
    },
    set: {
      type: Boolean,
      default: false,
    },
    unset: {
      type: Boolean,
      default: false,
    },
    sndNmnList: {
      type: Array,
      default: () => [],
    },
    sndNmn: {
      type: String,
    },
    djFlNo: {
      type: [String, Number],
    },
    isDistribute: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      comps: [
        {
          tabNm: '수신그룹',
          compNm: 'receiptGroup',
        },
        {
          tabNm: '조직도',
          compNm: 'obs',
        },
        {
          tabNm: '수기입력',
          compNm: 'handwriting',
        },
      ],
      tabVisibles: {handwriting: false},
      tabIndex: 0,
      sheet1: undefined,
      Options1: options1(this),
      loadSearchData1: [],
      sheet2: undefined,
      Options2: options2(this),
      loadSearchData2: [],
      sheet3: undefined,
      Options3: options3(this),
      loadSearchData3: [],
      sheet4: undefined,
      Options4: options4(this),
      loadSearchData4: [],
      inoDs: this.docCsDs === APPROVAL.DOC_CS_DS_IN_CMP ? APPROVAL.INO_DS_IN : APPROVAL.INO_DS_OUT,
      tl: '',
      onSearchNm: {'0': this.selectReceiptGroupMList, '1': this.searchDeptNm},
      internalReceipt: {
        inoDs: undefined,
        tabIndex: undefined,
        groupList: [],
        obsList: [],
        offlineList: [],
      },
      objSearch: {'0': '', '1': ''},
      focusGrpNo: 0,
      defaultLoad: false,
      lockTabClick: true,
      internalSndNmnSeq: '',
      internalSndNmn: '',
      internalDjFlNo: '',
      receiptInfo: '',
    };
  },
  computed: {
    isObs() {
      return this.tabIndex === 1;
    },
    isOffline() {
      return this.inoDs === APPROVAL.INO_DS_OUT && this.tabIndex === 2;
    },
    searchNm: {
      set(value) {
        this.objSearch[this.tabIndex] = value;
      },
      get() {
        return this.objSearch[this.tabIndex] ?? '';
      },
    },
    INO_DS_IN() {
      return APPROVAL.INO_DS_IN;
    },
    INO_DS_OUT() {
      return APPROVAL.INO_DS_OUT;
    },
  },
  watch: {
    set(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this._set();
      }
    },
    unset(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this._unset();
      }
    },
  },
  created() {
    this.initReceipt();
  },
  methods: {
    setTabIndex(index) {
      // iui-tab에서 최초 로드시 디폴트값으로 클릭을 하기 때문에 최초에 한번 막음.
      if (this.lockTabClick) {
        this.lockTabClick = false;
        return;
      }
      this.tabIndex = index;
      this.setTabView();
    },
    setTabView() {
      if (this.tabIndex === 0) {
        this.loadSearchData3 = this.internalReceipt.groupList;
        this.setTl();
      }
      if (this.tabIndex === 1) {
        this.tl = '';
        this.loadSearchData3 = this.internalReceipt.obsList;
      }
    },
    setTl() {
      const row = this.sheet1?.getFocusedRow();
      this.tl = row?.tl ?? '';
    },
    initReceipt() {
      this.selectReceiptGroupMList();
      this.selectObsList();
      this.internalReceipt = this.receipt ?? {};
      if (this.internalReceipt.inoDs === this.inoDs && this.internalReceipt.receiptList?.length) {
        this.tabIndex = this.internalReceipt.tabIndex ?? 1;
        if (this.tabIndex === 2) {
          this.loadSearchData4 = this.internalReceipt.receiptList;
        } else if (this.tabIndex === 1) {
          this.loadSearchData3 = this.internalReceipt.receiptList;
        }

        this.defaultLoad = true;
      }

      if (this.sndNmnList.length) {
        this.internalSndNmn = this.sndNmn || '';
        this.internalSndNmnSeq = this.sndNmnList.find(item => item.text === this.internalSndNmn)?.value ?? '';
        this.internalDjFlNo = this.djFlNo || '';
      }
      this.setTabVisible();
    },
    setTabVisible() {
      this.tabVisibles.handwriting = this.inoDs === APPROVAL.INO_DS_OUT;
    },
    async loadSheet1(sheet) {
      this.sheet1 = sheet;
    },
    async selectReceiptGroupMList() {
      const param = {
        tl: this.searchNm,
        inoDs: this.inoDs,
      };
      const response = await selectReceiptGroupMList(param);
      this.loadSearchData1 = response.data;
    },
    async selectReceiptGroupDList(row) {
      if (!row) {
        return;
      }
      const param = {
        grpNo: row.grpNo,
        inoDs: this.inoDs,
      };
      const response = await selectReceiptGroupDList(param);
      this.internalReceipt.groupList = response.data.map(item => ({
        blnDepNo: item.depCd,
        blnDepNm: item.depNm,
        empNo: item.empNo,
        name: item.name,
      }));
      this.loadSearchData3 = this.internalReceipt.groupList;
    },
    loadSheet2(sheet) {
      this.sheet2 = sheet;
    },
    loadSheet3(sheet) {
      this.sheet3 = sheet;
    },
    loadSheet4(sheet) {
      this.sheet4 = sheet;
    },
    onSearchFinish1(e) {
      let row;
      if (this.focusGrpNo) {
        e.sheet.getDataRows().some(_row => {
          if (_row.grpNo === this.focusGrpNo) {
            row = _row;
            return true;
          }
        });
        this.tabIndex = 0;
        this.callEvent({name: 'setActiveTab', param: this.tabIndex});
      } else {
        row = e.sheet.getFirstRow();
      }
      e.sheet.focus(row);
      if (this.defaultLoad) {
        this.defaultLoad = false;
        return;
      }
      this.selectReceiptGroupDList(row);
      this.focusGrpNo = 0;
    },
    onClick1(e) {
      this.setTl();
      this.selectReceiptGroupDList(e.row);
    },
    onSearchFinish3(e) {
      this.setReceiptSeq();
      e.sheet.acceptChangedData();
    },
    onSearchFinish4(e) {
      this.setOfflineReceiptSeq();
      e.sheet.acceptChangedData();
    },

    async selectObsList() {
      const response = await selectObsList[this.inoDs]();
      this.loadSearchData2 = this.setObsTree(response.data);
    },
    setObsTree(obs) {
      if (!obs.length) {
        return;
      }

      const items = obs
        .map(item => {
          if (this.inoDs === APPROVAL.INO_DS_IN) {
            item.obsName = item.blnDepNm;
            item.Items = item.obsWokerTreeList?.map(woker => {
              woker.blnDepNm = item.blnDepNm;
              woker.blnDepNo = item.blnDepNo;
              woker.blnDepCd = woker.empNo;
              woker.obsName = woker.name;
              return woker;
            });
          } else {
            item.obsName = item.frmNm;
            item.blnDepNm = item.frmNm;
            item.blnDepNo = item.pgmCd;
          }

          return item;
        })
        .filter(item => item.blnDepNo !== Number(this.userInfo.blnDepNo));
      const Items = $_treeModel(items, 'OBS', 'blnDepCd', 'uppBlnDepCd', 'blnDepNo', 'uppBlnDepNo');
      return [{blnDepCd: 'OBS', obsName: this.userInfo.frmNm, blnDepNo: '0', Items: Items}];
    },
    onSearch() {
      this.onSearchNm[this.tabIndex]();
    },
    searchDeptNm() {
      this.sheet2.util.treeSearch('blnDepNm', this.searchNm);
    },
    searchLineNm() {
      this.selectApprovalLineMList();
    },
    onDblClick2() {
      this.addReceipt();
    },
    onDblClick3(e) {
      if (e.kind === 'Data') {
        this.removeReceipt();
      }
    },
    onDblClick4(e) {
      if (e.kind === 'Data') {
        this.removeOfflineReceipt();
      }
    },
    onAdd() {
      this.isOffline ? this.addOfflineReceipt() : this.addReceipt();
    },
    onRemove() {
      this.isOffline ? this.removeOfflineReceipt() : this.removeReceipt();
    },
    addReceipt() {
      const obsRow = this.sheet2.getFocusedRow();
      if (obsRow !== undefined && obsRow.empNo) {
        const rows = this.sheet3.getDataRows();

        if (rows.length > 0) {
          // 중복 확인
          let dup = false;
          rows.some(row => {
            if (row.blnDepNo === obsRow.blnDepNo) {
              dup = true;
              return true;
            }
          });

          if (dup) {
            return;
          }
        }

        const data = {
          sn: 0,
          empNo: obsRow.empNo,
          name: obsRow.name,
          blnDepNo: this.inoDs === APPROVAL.INO_DS_IN ? obsRow.uppBlnDepNo : obsRow.blnDepNo,
          blnDepNm: this.inoDs === APPROVAL.INO_DS_IN ? obsRow.uppBlnDepNm : obsRow.blnDepNm,
        };

        this.sheet3.addRow({next: this.sheet3.getFirstRow(), init: data});
        this.setReceiptSeq();
        this.internalReceipt.obsList = this.sheet3.getSaveJson(0).data;
      }
    },
    addOfflineReceipt() {
      const rows = this.sheet4.getDataRows();
      if (rows.length > 0) {
        // 중복 확인
        let dup = false;
        rows.some(row => {
          if (String(row.receiptInfo) === this.receiptInfo) {
            dup = true;
            return true;
          }
        });

        if (dup) {
          return;
        }
      }

      const data = {
        sn: 0,
        receiptInfo: this.receiptInfo,
      };

      this.sheet4.addRow({next: this.sheet4.getFirstRow(), init: data});
      this.setOfflineReceiptSeq();
      this.internalReceipt.offlineList = this.sheet4.getSaveJson(0).data;
      this.receiptInfo = '';
    },
    removeReceipt() {
      const row = this.sheet3.getFocusedRow();
      if (row !== undefined && row !== null) {
        this.sheet3.removeRow(row);
        this.setReceiptSeq();
      }
    },
    removeOfflineReceipt() {
      const row = this.sheet4.getFocusedRow();
      if (row !== undefined && row !== null) {
        this.sheet4.removeRow(row);
        this.setOfflineReceiptSeq();
      }
    },
    setOrderUp() {
      this.isOffline ? this.setOrderUpOfflineReceipt() : this.setOrderUpReceipt();
    },
    setOrderUpReceipt() {
      const row = this.sheet3.getFocusedRow();
      const rowIndex = this.sheet3.getRowIndex(row);
      this.sheet3.moveRow(row, this.sheet3.getRowByIndex(rowIndex - 1));
      this.setReceiptSeq();
    },
    setOrderUpOfflineReceipt() {
      const row = this.sheet4.getFocusedRow();
      const rowIndex = this.sheet4.getRowIndex(row);
      this.sheet4.moveRow(row, this.sheet4.getRowByIndex(rowIndex - 1));
      this.setOfflineReceiptSeq();
    },
    setOrderDown() {
      this.isOffline ? this.setOrderDownOfflineReceipt() : this.setOrderDownReceipt();
    },
    setOrderDownReceipt() {
      const row = this.sheet3.getFocusedRow();
      const rowIndex = this.sheet3.getRowIndex(row);
      const rowCount = this.sheet3.getDataRows().length;

      if (rowIndex < rowCount) {
        this.sheet3.moveRow(row, this.sheet3.getRowByIndex(rowIndex + 2));
        this.setReceiptSeq();
      }
    },
    setOrderDownOfflineReceipt() {
      const row = this.sheet4.getFocusedRow();
      const rowIndex = this.sheet4.getRowIndex(row);
      const rowCount = this.sheet4.getDataRows().length;

      if (rowIndex < rowCount) {
        this.sheet4.moveRow(row, this.sheet4.getRowByIndex(rowIndex + 2));
        this.setOfflineReceiptSeq();
      }
    },

    setReceiptSeq() {
      const rows = this.sheet3.getDataRows();

      if (rows.length > 0) {
        let sn = rows.length;
        rows.forEach(row => {
          this.sheet3.setValue(row, 'sn', sn);
          sn--;
        });
      }
    },
    setOfflineReceiptSeq() {
      const rows = this.sheet4.getDataRows();

      if (rows.length > 0) {
        let sn = rows.length;
        rows.forEach(row => {
          this.sheet4.setValue(row, 'sn', sn);
          sn--;
        });
      }
    },
    async onDelete() {
      if (!(await this.$confirm({title: '삭제', message: '삭제하시겠습니까?'}))) return;

      const param = {
        grpNo: this.sheet1.getFocusedRow().grpNo,
      };
      await deleteReceiptGroup(param);
      this.searchReceiptGroup();
    },
    async onSave() {
      const receiptGroupDList = this.sheet3.getSaveJson(0).data.map(item => ({
        seq: item.sn,
        depCd: item.blnDepNo,
        empNo: item.empNo,
      }));
      const param = {
        inoDs: this.inoDs,
        tl: this.tl,
        receiptGroupDList: receiptGroupDList,
      };
      const response = await saveReceiptGroup(param);
      this.searchReceiptGroup(response.data);
    },
    searchReceiptGroup(focusGrpNo) {
      this.focusGrpNo = focusGrpNo;
      this.selectReceiptGroupMList();
    },
    _set() {
      this.internalReceipt.inoDs = this.inoDs;
      this.internalReceipt.tabIndex = this.tabIndex;
      this.internalReceipt.isOffline = this.isOffline;
      this.isOffline ? this.setOfflineReceiptList() : this.setReceiptList();

      delete this.internalReceipt.obsList;
      delete this.internalReceipt.groupList;
      delete this.internalReceipt.offlineList;
      this.$emit('update:receipt', this.internalReceipt);
      this.$emit('update:sndNmn', this.internalSndNmn);
      this.$emit('update:djFlNo', this.internalDjFlNo);
      this.$emit('update:set', false);
      this.$emit('set-complete', true);
    },
    setReceiptList() {
      this.internalReceipt.receiptList = this.sheet3.getSaveJson(0).data.map(item => ({
        sn: item.sn,
        blnDepNo: item.blnDepNo,
        blnDepNm: item.blnDepNm,
        empNo: item.empNo,
        name: item.name,
      }));
    },
    setOfflineReceiptList() {
      this.internalReceipt.receiptList = this.sheet4.getSaveJson(0).data;
    },
    _unset() {
      this.internalReceipt.receiptList = [];
      this.sheet3.removeAll();
      delete this.internalReceipt.obsList;
      delete this.internalReceipt.groupList;
      delete this.internalReceipt.offlineList;

      this.internalSndNmnSeq = '';
      this.initSndNmn();

      this.$emit('update:receipt', this.internalReceipt);
      this.$emit('update:sndNmn', this.internalSndNmn);
      this.$emit('update:unset', false);
      this.$emit('set-complete', false);
    },
    onChangeSndNmn(value) {
      this.internalSndNmnSeq = value;
      if (this.internalSndNmnSeq) {
        const sndNmn = this.sndNmnList.find(item => item.value === this.internalSndNmnSeq);
        this.internalSndNmn = sndNmn.text;
        this.internalDjFlNo = sndNmn.djFlNo;
      } else {
        this.initSndNmn();
      }
    },
    initSndNmn() {
      this.internalSndNmn = '';
      this.internalDjFlNo = '';
    },
  },
};
</script>

<style scoped>
span {
  width: 350px;
}
.handBox {
  border: 5px solid #eee;
  height: 100%;
}
</style>
