<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <label class="ml5 mr10">현장명</label>
      <iui-text :value="searchInfo.prjNm" width="50%" :readonly="true" />
      <label class="ml15 mr10">촬영일자</label>
      <iui-datepicker name="phoDt" :value.sync="phoDt" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onClick,
        onSearchFinish: this.grid_onSearchFinish,
        onRenderFirstFinish,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import {selectConstructPhotoList, selectPhotoFileInfoList} from '../api/photo.js';
/**
 * 공정사진조회 > 사진목록
 *
 * */
import options from './sheetOption/PhotoInfoListSheet.js';
export default {
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'detailInfo']);
    $mapMutations(this, ['setDetailInfo', 'initDetailInfo', 'setPhotos', 'initPhotos']);
  },
  created() {
    this.addEvent([
      {name: 'photoInfoListOnSearch', func: this.onSearch},
      {name: 'photoInfoListSetPhotosList', func: this.setPhotosList},
    ]);
  },
  data() {
    return {
      phoDt: '',
      Options: options(this),
      loadSearchData: [],
      focusRow: '',
      FILE_URL:
        location.protocol.indexOf('https') !== -1 ? process.env.VUE_APP_FILE_URLS : process.env.VUE_APP_FILE_URL,
    };
  },
  watch: {
    phoDt() {
      this.onSearch();
    },
  },
  methods: {
    async onSearch(focusRow = '') {
      this.focusRow = focusRow;
      let param = {
        prjCd: this.searchInfo.prjCd,
        phoDt: this.phoDt,
      };
      const response = await selectConstructPhotoList(param);
      this.sheet.loadSearchData(response.data);
    },
    grid_onSearchFinish(e) {
      let searchRow = this.focusRow.seq
        ? e.sheet.getDataRows().find(row => row.wbsNm == this.focusRow.wbsNm && row.phoDt == this.focusRow.phoDt)
        : e.sheet.getFirstRow();
      if (searchRow) {
        this.sheet.focus(searchRow);
        this.setDetailInfo(searchRow);
        this.setPhotosList();
      } else {
        this.initDetailInfo();
        this.initPhotos();
      }
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.setDetailInfo(e.row);
        this.setPhotosList();
      }
    },
    async setPhotosList() {
      let photoFileList = [];
      let param = {
        flNo: this.detailInfo.flNo,
      };
      const response = await selectPhotoFileInfoList(param);
      photoFileList = response.data;

      let thumbnails = [];
      let photos = [];
      photoFileList.forEach(item => {
        if (item.flDs == this.$store.state.code2.codeAlias.FLDS_IMAGE.code) {
          // 이미지
          item[
            'imgSrc'
          ] = `${this.FILE_URL}/file/getImage?pgmCd=${this.pgmCd}&flNo=${item.flNo}&grpNo=${item.grpNo}&flDs=${item.flDs}`;
          photos.push(item);
        } else if (item.flDs == this.$store.state.code2.codeAlias.FLDS_IMAGE_THUMBNAIL.code) {
          // 썸네일
          item['imgSrc'] = `${this.FILE_URL}/file/getImage?pgmCd=${this.pgmCd}&flNo=${item.flNo}&grpNo=${item.grpNo}`;
          item[
            'thumbnailSrc'
          ] = `${this.FILE_URL}/file/getImage?pgmCd=${this.pgmCd}&flNo=${item.flNo}&grpNo=${item.grpNo}&flDs=${item.flDs}`;

          thumbnails.push(item);
        }
      });

      if (photos.length > 0) {
        this.setPhotos({imgUrl: photos[0].imgSrc, mImgs: photos, tImgs: thumbnails});
      }
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style></style>
