<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col>
          <PxcondInput />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import PrjList from '@/view/Project/components/PrjList.vue';
import PxcondInput from '@/view/Project/pxcondStatus/input/component/PxcondInput.vue';

export default {
  components: {
    PrjList,
    PxcondInput,
  },
};
</script>
