<template>
  <pmis-content-box>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :append="true"
      :events="sheetEvents"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import {selectLbrcCodeList, deleteLbrcCode} from '../api/LbrcCodeRegist.js';
import options from './sheetOption/DetailListSheet.js';
export default {
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    base: {
      type: Boolean,
      default: false,
    },
    uid: {
      type: Number,
    },
    callback: {
      type: Function,
      default: undefined,
    },
    paramObj: {
      type: Object,
      default: () => {},
    },
    focusKey: {
      type: String,
    },
  },
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this)),
      loadSearchData: [],
      isMultiSelect: false,
      sheetUtil: null,
      searchNm: '',
      lbrcClInfo: {},
      lbrcCodeInfo: {
        itemName: '',
        level: 4,
        llCode: '',
        llName: '',
        laborCode: '',
        mlCode: '',
        mlName: '',
        size: '',
        unit: '',
        useDs: '',
      },
      sheetEvents: {
        onClick: this.isPopup ? undefined : this.onClick,
        onFocus: this.isPopup ? undefined : this.onFocus,
        onSearchFinish: this.isPopup ? undefined : this.onSearchFinish,
      },
    };
  },
  created() {
    if (this.paramObj) {
      this.isMultiSelect = this.paramObj.isMultiSelect;
    }

    if (this.isPopup) this.onSearch();
  },

  methods: {
    async onSearch() {
      const param = {
        base: this.base,
        code: this.lbrcClInfo.level === 1 ? '' : this.lbrcClInfo.code ?? '',
        searchNm: this.searchNm,
        ...this.sheetUtil.pagingData(),
      };
      let response = await selectLbrcCodeList(param);
      if (response.status == 200) {
        this.loadSearchData = response.data;
      }
    },
    async onDelete() {
      let checkedRows = this.sheet.getRowsByChecked('chk');

      if (0 == checkedRows.length) {
        this.$alert({title: '삭제', message: '선택된 항목이 없습니다.'});
        return;
      }
      if (!(await this.$confirm({title: '삭제', message: '선택된 노무를 삭제하시겠습니까?'}))) {
        return;
      }
      try {
        let deletedList = checkedRows.map(row => ({laborCode: row.laborCode}));
        const response = await deleteLbrcCode({base: this.base, deletedList: deletedList});
        if (response.data) {
          this.removeAll();
          this.onSearch();
          return deletedList;
        }
      } catch (error) {
        this.$alert({title: '삭제', message: error.description ?? error.message});
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
      this.sheetUtil.scrollPaging(this.onSearch);
    },
    onDblClick(e) {
      e.row.Kind == 'Data' ? this.callEvent({name: `matCodeSearchOnConfirm${this.uid}`}) : '';
    },
    onFocus(e) {
      if (e.row.Kind == 'Data') {
        this.setLbrcCodeInfo(e.row);
        if (typeof this.callback == 'function') this.callback();
      }
    },
    onSearchFinish(e) {
      if (this.focusKey) {
        const row = e.sheet.getDataRows().find(row => row.laborCode === this.focusKey);
        e.sheet.focus(row);
        this.$emit('update:focusKey', undefined);
      }
    },
    setLbrcCodeInfo(row) {
      for (let key in this.lbrcCodeInfo) {
        this.lbrcCodeInfo[key] = row[key];
      }
      this.$emit('lbrcCodeInfo', this.lbrcCodeInfo);
    },

    onClick(e) {
      this.$emit('onLbrcClSearch', e.row.mlCode);
    },
    onReloadSheet() {
      if (this.cud == 1) {
        let addRow = this.sheet.addRow({
          init: {
            llCode: this.lbrcCodeInfo.llCode,
            llName: this.lbrcCodeInfo.llName,
            mlCode: this.lbrcCodeInfo.mlCode,
            mlName: this.lbrcCodeInfo.mlName,
            laborCode: this.lbrcCodeInfo.laborCode,
            itemName: this.lbrcCodeInfo.itemName,
            size: this.lbrcCodeInfo.size,
            unit: this.lbrcCodeInfo.unit,
            useDs: this.lbrcCodeInfo.useDs,
          },
        });
        this.sheet.acceptChangedData(addRow);
      } else if (this.cud == 2) {
        let focusedRow = this.sheet.getFocusedRow();
        this.sheet.setValue(focusedRow, 'itemName', this.lbrcCodeInfo.itemName, 1);
        this.sheet.setValue(focusedRow, 'size', this.lbrcCodeInfo.size, 1);
        this.sheet.setValue(focusedRow, 'unit', this.lbrcCodeInfo.unit, 1);
        this.sheet.setValue(focusedRow, 'useDs', this.lbrcCodeInfo.useDs, 1);
        this.sheet.acceptChangedData(focusedRow);
      }
    },
    onSetData(param) {
      if (this.isMultiSelect) {
        let checkedRows = this.sheet.getRowsByChecked('chk');
        if (0 == checkedRows.length) {
          this.$alert({title: '선택', message: '선택된 항목이 없습니다.'});
          return false;
        }
        param(checkedRows);
      } else {
        param(this.sheet.getFocusedRow());
      }
    },
    removeAll() {
      this.sheet.removeAll();
      Object.assign(this.$data.lbrcCodeInfo, this.$options.data.call(this).lbrcCodeInfo);
    },
  },
};
</script>

<style scoped></style>
