import store from '@/store';
const ModalStrategy = {
  install(Vue) {
    this.event = new Vue();

    const _modal = this.event.$modal;
    const show = (modal, ...args) => {
      if (typeof modal == 'string' && (!args.length || !args[0].isDefault)) {
        _modal.show(modal + store.getters['currentUrl'], ...args);
      } else {
        _modal.show(modal, ...args);
      }
    };
    const hide = (name, params, isDefault = false) => {
      if (name && name != 'dialog' && name.indexOf(store.getters['currentUrl']) == -1 && !isDefault) {
        name = name + store.getters['currentUrl'];
      }
      _modal.hide(name, params);
    };
    const toggle = (name, params) => {
      _modal.toggle(name + store.getters['currentUrl'], params);
    };

    Vue.prototype.$modal = {
      show,
      hide,
      toggle,
    };
  },
};

export default ModalStrategy;
