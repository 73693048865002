export const selectAnyTimeLabSearchData = async () =>
  await axios.post('/closeAnyTimeLab/selectAnyTimeLabSearchData', {});

export const selectLabAnmonList = async param => await axios.post('/closeAnyTimeLab/selectLabAnmonList', param);

export const selectLabTaxRt = async () => {
  return await axios.post('/closeAnyTimeLab/selectLabTaxRt', {});
};

export const selectRegisterLabDetail = async param =>
  await axios.post('/closeAnyTimeLab/selectRegisterLabDetail', param);

export const selectRegisterLabInfo = async param => await axios.post('/closeAnyTimeLab/selectRegisterLabInfo', param);

export const deleteLabAnmon = async param => await axios.post('/closeAnyTimeLab/deleteLabAnmon', param);
