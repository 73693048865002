const state = {
  detailInfo: {
    pgmCd: '',
    csNo: '',
    fldrNo: '',
    fldrCd: '',
    fldrNm: '',
    uppFldrNo: '',
    uppFldrNm: '',
    uppFldrCd: '',
    rmk: '',
    stYn: '', // 표준구분
    isUsedFbsNo: false, // 분류번호 사용여부 체크
  },
  isNew: false,
};

const getters = {
  detailInfo: state => state.detailInfo,
  isNew: state => state.isNew,
  isStandard: state => state.detailInfo.stYn === $getConstants('Y').code, // 표준분류여부 Y
};

const mutations = {
  setDetailInfo(state, payload) {
    for (const key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = payload[key];
      }
    }
  },
  initDetailInfo(state) {
    for (const key in state.detailInfo) {
      if (['isUsedFbsNo'].includes(key)) {
        state.detailInfo[key] = false;
      } else {
        state.detailInfo[key] = '';
      }
    }
  },
  setIsNew(state, payload) {
    if (typeof payload === 'boolean') {
      state.isNew = payload;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
