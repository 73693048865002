<template>
  <iui-container-new type="css-flex">
    <i-row height="40px">
      <i-col>
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col-header>현장명</i-col-header>
            <i-col>
              <iui-text ref="prjNm" type="text" name="prjNm" :value.sync="prjNm" max-length="100" />
              <iui-button value="현장코드등록" @click="selectNextPrjCd" />
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-tab-box>
          <template v-slot:title>견적현장정보</template>
          <pmis-content-box>
            <template v-slot:header-left>
              <iui-text type="search" @update:value="setSearchNm" @enter="onSearch" />
              <iui-button value="검색" class="btn" @click="onSearch" />
            </template>
            <ib-sheet
              :uid="_uid"
              :options="Options"
              :loadSearchData="loadSearchData"
              :events="{
                onClick,
                ondblclick,
                onSearchFinish,
                onRenderFirstFinish,
              }"
              @loadSheet="loadSheet"
            />
          </pmis-content-box>
        </pmis-tab-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import {selectNextPrjCd, selectPrjEstMList} from '@/view/estmtManage/estmtPrjManage/api/estmtPrjRegist';
import options from '../components/sheetOption/PrjRegistPopSheet.js';

/**
 * 계약정보등록(신규클릭) > 현장코드등록
 *
 * */
export default {
  props: {
    callbackFunction: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loadSearchData: [],
      Options: options(this),
      searchNm: '',

      newPrjCd: '',
      prjNm: '',
      orderGroup: '',
      orderCode: '',
      orderNm: '',
      workKind: '',
      contcompCode: '',
      contcompNm: '',

      prjEstCd: '',
      prjEstNm: '',
      isPrjEstCd: false,
    };
  },
  created() {
    this.addEvent([{name: 'prjRegistPopConfirm', func: this.onPopConfirm}]);
  },
  methods: {
    onSearch() {
      this.isPrjEstCd = true;
      const param = {pgmCd: this.userInfo.pgmCd, searchNm: this.searchNm, isPrjRegistPage: true};
      selectPrjEstMList(param).then(response => this.sheet.loadSearchData(response.data));
    },
    onSetEstPrjInfo(row) {
      this.prjEstCd = row.prjEstCd;
      this.prjEstNm = row.prjEstNm;
      this.orderGroup = row.orderGroup;
      this.orderCode = row.orderCode;
      this.orderNm = row.orderNm;
      this.workKind = row.workKind;
      this.contcompCode = row.contcompCode;
      this.contcompNm = row.contcompNm;
    },
    onInitEstPrjInfo() {
      this.prjEstCd = '';
      this.prjEstNm = '';
      this.orderGroup = '';
      this.orderCode = '';
      this.orderNm = '';
      this.workKind = '';
      this.contcompCode = '';
      this.contcompNm = '';
    },
    setSearchNm(value) {
      this.searchNm = value;
    },
    selectNextPrjCd() {
      if (!this.prjNm) {
        this.$alert({title: '신규현장', message: '현장명을 입력해야 합니다.'}, () => {
          this.$refs.prjNm.$refs.input.focus();
        });
        return;
      }
      selectNextPrjCd({}).then(response => {
        this.newPrjCd = response.data;
        this.isPrjEstCd = false;
        this.callEvent({name: 'prjAddConfirm'});
      });
    },
    onPopConfirm(callback) {
      if (this.isPrjEstCd) {
        if (!this.prjEstCd) {
          this.$alert({title: '견적현장', message: '견적현장을 선택해야 합니다.'});
          return;
        }
      }

      if (typeof callback == 'function') {
        if (this.isPrjEstCd) {
          callback({
            prjCd: this.prjEstCd,
            prjNm: this.prjEstNm,
            orderGroup: this.orderGroup,
            orderCode: this.orderCode,
            orderNm: this.orderNm,
            workKind: this.workKind,
            contcompCode: this.contcompCode,
            contcompNm: this.contcompNm,
          });
        } else {
          callback({
            prjCd: this.newPrjCd,
            prjNm: this.prjNm,
          });
        }
      }
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.onSetEstPrjInfo(e.row);
      }
    },
    ondblclick(e) {
      if (e.row.Kind == 'Data') {
        this.onSetEstPrjInfo(e.row);
        this.callEvent({name: 'prjAddConfirm'});
      }
    },
    onSearchFinish(e) {
      if (0 < e.sheet.getTotalRowCount()) {
        this.onSetEstPrjInfo(e.sheet.getFocusedRow());
      } else {
        this.onInitEstPrjInfo();
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped></style>
