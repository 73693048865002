import Vue from 'vue';
import Vuex from 'vuex';
import mdi from './modules/mdi';
import layout from './modules/layout';
import menu from './modules/menu';
import code from './modules/code';
import code2 from './modules/code2';
import modal from './modules/modal';
import commonButton from './modules/commonButton';
import project from './modules/project';
import portlet from './modules/portlet';
import jwt from './modules/jwt';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    mdi,
    layout,
    menu,
    code,
    code2,
    modal,
    commonButton,
    project,
    portlet,
    jwt,
  },
  state: {
    currentUrl: '/',
    modules: [],
    storeNm: '',
    bus: new Vue(),
    externalData: {},
    modalParam: {},
    pgmCd: {},
    prjCd: '',
    cud: {},
    loadVue: {},
    menuAuthCache: {},
    subFrmGb: '',
    fileCompMap: {},
    datepickerCompMap: {},
    stdCl: [],
  },
  mutations: {
    setCurrentUrl(state, payload) {
      state.currentUrl = payload.path;
      state.storeNm = state.currentUrl;
    },
    setModalParam(state, payload) {
      state.modalParam = payload;
    },
    initModalParam(state) {
      state.modalParam = {};
    },
    addModules(state, module) {
      state.modules.push(module);
    },
    removeModules(state, name) {
      let index = state.modules.findIndex(item => item === name);
      state.modules.splice(index, 1);
    },
    allRemoveModules(state) {
      state.modules = [];
    },
    setPgmCd(state, payload) {
      state.pgmCd[state.currentUrl] = payload;
    },
    setPrjCd(state, payload) {
      state.prjCd = payload;
    },
    setCud(state, payload) {
      state.cud[state.currentUrl] = payload;
    },
    setLoadVue(state, payload) {
      state.loadVue[state.currentUrl] = payload;
    },
    removeLoadVue(state, payload) {
      delete state.loadVue[payload];
    },
    setMenuAuthCache(state, payload) {
      if (!state.menuAuthCache.hasOwnProperty(Object.keys(payload)[0])) {
        state.menuAuthCache = {...state.menuAuthCache, ...payload};
      }
    },
    setSubFrmGb(state, payload) {
      state.subFrmGb = payload;
    },
    setFileCompMap(state, payload) {
      state.fileCompMap = payload;
    },
    setDatepickerCompMap(state, payload) {
      state.datepickerCompMap = payload;
    },
    setStdCl(state, payload) {
      state.stdCl = payload;
    },
    destroy(state, url) {
      this.dispatch('project/destroy', url);
      this.dispatch('commonButton/destroy', url);

      delete state.pgmCd[url];
      delete state.cud[url];

      if (state.loadVue[url]) {
        state.loadVue[url].$destroy();
        delete state.loadVue[url];
      }

      if (state[url] !== undefined) {
        this.unregisterModule(url);
      }

      let index = state.modules.indexOf(url.replace(/\//, ''));
      state.modules.splice(index, 1);

      this.dispatch('mdi/removeMdi', url);
    },
    destroyAll(state) {
      this.commit('project/destroyAll');
      this.commit('commonButton/destroyAll');

      state.pgmCd = {};
      state.cud = {};
      state.mdi.urls.forEach(item => {
        if (state.loadVue[item.url]) {
          state.loadVue[item.url].$destroy();
        }

        if (state[item.url] !== undefined) {
          this.unregisterModule(item.url);
        }
      });

      state.modules = [];
      this.dispatch('mdi/allRemoveMdi');
    },
  },
  actions: {
    addEvent({state}, payload) {
      if (Object.prototype.toString.call(payload).slice(8, -1) == 'Array') {
        for (let key in payload) {
          state.bus.$off(payload[key].name + state.currentUrl);
          state.bus.$on(payload[key].name + state.currentUrl, payload[key].func);
        }
      } else {
        state.bus.$off(payload.name + state.currentUrl);
        state.bus.$on(payload.name + state.currentUrl, payload.func);
      }
    },
    callEvent({state}, payload) {
      state.bus.$emit(payload.name + state.currentUrl, payload.param);
    },
    setExternalData({state}, payload) {
      state.externalData = payload;
    },
    addFileComponent({state, commit}, component) {
      const fileCompMap = state.fileCompMap;
      if (!(fileCompMap[state.currentUrl] instanceof Array)) {
        fileCompMap[state.currentUrl] = [];
      }
      fileCompMap[state.currentUrl].push(component);
      commit('setFileCompMap', fileCompMap);
    },
    removeFileComponent({state, commit}, component) {
      const fileCompMap = state.fileCompMap;
      fileCompMap[state.currentUrl]?.some((item, index) => {
        if (item._uid == component._uid) {
          fileCompMap[state.currentUrl].splice(index, 1);
          return true;
        }
      });
      commit('setFileCompMap', fileCompMap);
    },
  },
  getters: {
    currentState: state => {
      return state;
    },
    currentUrl: state => {
      return state.currentUrl;
    },
    modules: state => state.modules,
    getStoreNm: state => state.storeNm,
    externalData: state => state.externalData,
    modalParam: state => state.modalParam,
    cachedPage: state => state.cachedPage,
    pgmCd: state => state.pgmCd,
    prjCd: state => state.prjCd,
    cud: state => state.cud,
    loadVue: state => state.loadVue,
    menuAuthCache: state => state.menuAuthCache,
    subFrmGb: state => state.subFrmGb,
    fileCompMap: state => state.fileCompMap[state.currentUrl],
    datepickerCompMap: state => state.datepickerCompMap,
    stdCl: state => csNo => state.stdCl.filter(item => item.csNo == csNo)[0],
  },
});
