<template>
  <pmis-content-box>
    <template v-slot:title>자재분류</template>
    <template v-slot:header-left>
      <iui-text name="searchText" :value.sync="searchText" />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{onDblClick}"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import options from '../sheetOption/MatListSheet.js';
import {selectMaterialClList} from '../../api/member.js';
export default {
  data() {
    return {
      Options: options(this),
      loadSearchData: [],
      oldSearchText: '',
      searchText: '',
      searchIndex: 0,
      sheetUtil: null,
    };
  },

  created() {
    this.addEvent([{name: 'matList_onFocusedRowData', func: this.onFocusedRowData}]);
  },

  mounted() {
    this.onSearchCodeList();
  },

  methods: {
    onDblClick() {
      this.$modalConfirm();
    },

    async onSearchCodeList() {
      let param = {
        searchText: this.searchText,
        base: true,
      };
      const response = await selectMaterialClList(param);
      if (response.status == 200) {
        let treeList = Array.from(response.data)[0];
        treeList.Items = await $_treeModel(
          Array.from(response.data).filter((e, i) => i != 0),
          treeList.code,
          'code',
          'upCode'
        );
        this.loadSearchData = new Array(treeList);
      }
    },

    onSearch() {
      this.sheetUtil.treeSearch('name', this.searchText);
    },

    searchInTree() {
      let beforeFocusScroll;
      if (this.oldSearchText != this.searchText) {
        this.oldSearchText = this.searchText;
        this.searchIndex = 0;

        beforeFocusScroll = this.sheet.getScrollTop();

        let regExp = new RegExp(this.searchText, 'gi');
        this.focusRows = this.sheet.getDataRows().filter(row => row.name.match(regExp));
      }
      beforeFocusScroll = this.sheet.getScrollTop();

      let focusRow = this.focusRows[this.searchIndex++];
      if (!focusRow) {
        this.searchIndex = 0;
        focusRow = this.focusRows[this.searchIndex++];
      }

      this.setExpandParentRow(focusRow);
      this.sheet.focus(focusRow);
      setTimeout(() => {
        let afterFocusScroll = this.sheet.getScrollTop();
        if (beforeFocusScroll < afterFocusScroll) {
          this.sheet.setScrollTop(this.sheet.getRowTop(focusRow));
        }
      }, 10);
    },

    setExpandParentRow(row) {
      if (!row || !row.Level) return;

      this.sheet.setExpandRow(row.parentNode, null, true);
      this.setExpandParentRow(row.parentNode);
    },

    onFocusedRowData(param) {
      let row = this.sheet.getFocusedRow();
      if (typeof param == 'function') {
        param(row);
      }
    },

    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped></style>
