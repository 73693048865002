<template>
  <iui-container-new type="css-flex">
    <i-row height="200px">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>직영투입비</template>
          <ib-sheet :uid="_uid" :options="Options1" :loadSearchData="loadSearchData1" />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row height="250px">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>외주투입비</template>

          <ib-sheet :uid="_uid" :options="Options2" :loadSearchData="loadSearchData2" />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row height="230px">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>작업내용</template>

          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <ib-sheet :uid="_uid" :options="Options3" :loadSearchData="loadSearchData3" />
              </i-col>
              <i-spacer />
              <i-col>
                <ib-sheet :uid="_uid" :options="Options4" :loadSearchData="loadSearchData4" />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col></i-col>
    </i-row>
    <i-row height="230px">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>지시조치내용</template>
          <ib-sheet :uid="_uid" :options="Options5" :loadSearchData="loadSearchData5" />
        </pmis-content-box>
      </i-col>
    </i-row>

    <ApprovalPop :parentId="_uid" @approvalEmit="() => onSearch(amountUnit)" />
  </iui-container-new>
</template>

<script>
import ApprovalPop from '@/view/approval/draft/popup/pop.vue';

import APPROVAL_CONSTANTS from '@/view/approval/js/approvalConstants.js';
import EDMS_CONSTANTS from '@/view/edms/common/EdmsConstatns.js';
import options1 from './sheetOptions/WorkSummaryAllSheetSum.js';
import options2 from './sheetOptions/WorkSummaryAllSheetSub.js';
import options3 from './sheetOptions/WorkSummarySheetExec.js';
import options4 from './sheetOptions/WorkSummarySheetPlan.js';
import options5 from './sheetOptions/WorkSummaryAllSheetOrder.js';
import {selectApprovalInfos, selectWorkSummary} from '../api/workdaily.js';

export default {
  components: {ApprovalPop},
  data() {
    return {
      Options1: options1(this),
      loadSearchData1: [],
      Options2: options2(this),
      loadSearchData2: [],
      Options3: options3(this),
      loadSearchData3: [],
      Options4: options4(this),
      loadSearchData4: [],
      Options5: options5(this),
      loadSearchData5: [],
      responseData: {
        sheet1: [],
        sheet2: [],
        sheet3: [],
        sheet4: [],
        sheet5: [],
      },
      code: $getConstants('AMOUNT_UNIT').code,
      amountUnit: '',
    };
  },
  computed: {
    sncNo() {
      return this.summary.sncNo;
    },
  },
  watch: {
    sncNo(v) {
      this.canPrint = 0 < v;
    },
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'activeResource', 'summary']);
    $mapActions(this, ['setSummary', 'initSummary']);
  },
  created() {
    this.addEvent([
      {name: 'WorkSummary_onSearch', func: this.onSearch},
      {name: 'WorkSummary_onApproval', func: this.onApproval},
      {name: 'WorkSummary_onAmountChange', func: this.convertPriceUnit},
    ]);
    this.addFuncPrint(this.onApproval);
  },
  methods: {
    async onSearch(amountUnit) {
      this.init();
      const response = await selectWorkSummary({...this.searchInfo});
      const summary = response.data;
      this.setSummary(summary);
      const summaryList = [];
      summaryList.push({
        rowTypeName: '금일투입',
        materialAmt: summary.materialDayAmt,
        laborAmt: summary.laborDayAmt,
        equipmentAmt: summary.equipmentDayAmt,
        expenseAmt: summary.expenseDayAmt,
        sumAmt: summary.sumDayAmt,
        sumRatePerCont: summary.sumDayRatePerCont,
        sumRatePerExec: summary.sumDayRatePerExec,
      });
      summaryList.push({
        rowTypeName: '금월투입',
        materialAmt: summary.materialMonthAmt,
        laborAmt: summary.laborMonthAmt,
        equipmentAmt: summary.equipmentMonthAmt,
        expenseAmt: summary.expenseMonthAmt,
        sumAmt: summary.sumMonthAmt,
        sumRatePerCont: summary.sumMonthRatePerCont,
        sumRatePerExec: summary.sumMonthRatePerExec,
      });
      summaryList.push({
        rowTypeName: '누계투입',
        materialAmt: summary.materialTotAmt,
        laborAmt: summary.laborTotAmt,
        equipmentAmt: summary.equipmentTotAmt,
        expenseAmt: summary.expenseTotAmt,
        sumAmt: summary.sumTotAmt,
        sumRatePerCont: summary.sumTotRatePerCont,
        sumRatePerExec: summary.sumTotRatePerExec,
      });

      this.responseData.sheet1 = summaryList ?? [];
      this.responseData.sheet2 = response.data.workGroupList ?? [];
      this.responseData.sheet3 = response.data.unitWorkExecList ?? [];
      this.responseData.sheet4 = response.data.unitWorkPlanList ?? [];
      this.responseData.sheet5 = response.data.workOrderResultList ?? [];

      this.convertPriceUnit(amountUnit);
    },
    init() {
      this.initSummary();
    },
    async onApproval() {
      const [fbsCd, fbsNo, fomNo, taskCd] = Object.values(EDMS_CONSTANTS['WORKDAILY']);

      const prjInfo = this.getPrjInfo();
      const basicInfo = {
        prjNm: prjInfo.prjNm,
        rgprNm: this.userInfo.name,
        ymd: dateFormat(this.searchInfo.ymd),
      };

      const res = await selectApprovalInfos({...this.searchInfo}).catch(e =>
        this.$alert({
          title: '오류',
          message: e.message,
        })
      );

      if (res) {
        const excuteExternal = res.data;
        excuteExternal['amountUnit'] = await $getCodeNm(this.code, this.amountUnit);
        excuteExternal['basicInfo'] = `{"basicInfo":${JSON.stringify(basicInfo)}}`;
        const keyList = [
          'equipmentList',
          'expenseList',
          'laborList',
          'materialList',
          'summary',
          'unitWorkExecList',
          'unitWorkPlanList',
          'workGroupItemList',
          'workGroupList',
        ];
        keyList.forEach(x => {
          const obj = {};
          const array = x === 'summary' ? [JSON.parse(excuteExternal[x])[x]] : JSON.parse(excuteExternal[x])[x];
          if (array) {
            const result = $_priceUnitFormat(this.amountUnit, array);
            result.forEach(x => {
              Object.keys(x).map(key => {
                if (key.toLowerCase().indexOf('amt') > -1 || key.toLowerCase().indexOf('price') > -1) {
                  x[key] = $_numberFormat(x[key], true);
                }
              });
            });

            obj[x] = x === 'summary' ? result[0] : result;
            excuteExternal[x] = JSON.stringify(obj);
          }
        });

        this.callEvent({
          name: `draft_pop${this._uid}`,
          param: {
            pgmCd: this.pgmCd,
            sncNo: this.sncNo,
            signCnt: APPROVAL_CONSTANTS.FORMATTED_LINE_CNT,
            isMulti: false, // 결재선 여러 행 표현
            tl: `[${this.prjCd}] ${this.searchInfo.ymd} 작업일보 품의`,
            fbsCd,
            fbsNo,
            fomNo,
            taskCd,
            taskNo: `${this.pgmCd}${prjInfo.prjCd}${this.searchInfo.ymd}`,
            excuteExternal,
            canToggle: true,
            withoutLine: true,
          },
        });
      }
    },
    convertPriceUnit(value) {
      this.loadSearchData1 = $_priceUnitFormat(value, this.responseData.sheet1);
      this.loadSearchData2 = $_priceUnitFormat(value, this.responseData.sheet2);
      this.loadSearchData3 = $_priceUnitFormat(value, this.responseData.sheet3);
      this.loadSearchData4 = $_priceUnitFormat(value, this.responseData.sheet4);
      this.loadSearchData5 = $_priceUnitFormat(value, this.responseData.sheet5);
      this.amountUnit = value;
    },
  },
};
</script>
