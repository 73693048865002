export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      SearchMode: 2,
      CanEdit: 1,
      IgnoreFocused: true,
    },
    Cols: [
      {
        Header: {Value: '선택', TextColor: '#444', HeaderCheck: 1},
        Name: 'cbx',
        Type: 'Bool',
        BoolIcon: 0,
        Align: 'center',
        Width: 70,
      },
      {
        Header: '업체코드',
        Name: 'bsnNo',
        Type: 'Text',
        Width: 120,
        CanEdit: false,
        CustomFormat: 'SaupNo',
        Button: 'Html',
        ButtonText: '<button class="i_search"></button>',
        WidthPad: 26,
        OnClickSide: () => that.onShowModal(),
      },
      {
        Header: '업체명',
        Name: 'frmNm',
        Width: 120,
        CanEdit: false,
      },
      {
        Header: '직불금액',
        Name: 'directAmt',
        Extend: preset.amount,
        Width: 120,
      },
      {
        Header: '내역',
        Name: 'directRmk',
        Type: 'Text',
        RelWidth: 1,
      },
      {Name: 'pgmCd', Visible: false},
      {Name: 'prjCd', Visible: false},
      {Name: 'reqCnt', Visible: false},
      {Name: 'seq', Visible: false},
      {Name: 'orgDirectAmt', Visible: false, NoChanged: true},
    ],
  };
};
