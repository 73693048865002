export default that => {
  let opt = {
    Cfg: {
      HeaderMerge: 6,
      CanEdit: 0,
      Alternate: 4, // 4행 간격으로
      AlternateCount: 2, // 마지막 2개 행에만 배경색 적용
    },
    Def: {
      Row: {
        CanFocus: 0,
        AlternateColor: '#EDEDED', // 배경색 설정
      },
    },
    LeftCols: [
      {
        Name: 'empNo',
        Header: ['인적사항', 'ID', 'ID'],
        Align: 'center',
        Width: 80,
        Type: 'Text',
      },
      {
        Name: 'name',
        Header: ['인적사항', '성명', '주소'],
        Align: 'center',
        Width: 100,
        Type: 'Text',
      },
      {
        Name: 'regiNo',
        Header: ['인적사항', '주민번호', '주소'],
        Align: 'left',
        Width: 120,
        Type: 'Text',
      },
      {
        Name: 'jkdNm',
        Header: ['인적사항', '직종', '주소'],
        Align: 'left',
        Width: 80,
        Type: 'Text',
      },
    ],
    Cols: [],
  };
  for (let i = 1; i < 16; i++) {
    opt.Cols.push({
      Name: 'd' + i.toString(),
      Header: ['출역현황', i.toString(), (i + 15).toString()],
      Align: 'center',
      Width: 40,
      Type: 'Float',
      Format: '#,###.#',
    });
  }

  opt.Cols.push(
    {
      Name: 'd16',
      Header: ['출역현황', '-', '31'],
      Align: 'center',
      Width: 40,
      Type: 'Float',
      Format: '#,###.#',
    },
    {
      Name: 'monthlyDay',
      Header: ['노무비', '출역일수', '투입공수'],
      Align: 'right',
      Width: 70,
      Type: 'Float',
      Format: '#,###.#',
    },
    {
      Name: 'priceLAmt',
      Header: ['노무비', '단가', '총액'],
      Extend: that.$ibsheetHelper.preset.amount,
      Width: 90,
    },
    {
      Name: 'incomeTax',
      Header: ['공제내역', '소득세', '지방소득세'],
      Extend: that.$ibsheetHelper.preset.amount,
      Width: 85,
    },
    {
      Name: 'pensionAmt',
      Header: ['공제내역', '국민연금', '건강보험'],
      Extend: that.$ibsheetHelper.preset.amount,
      Width: 85,
    },
    {
      Name: 'employInsurAmt',
      Header: ['공제내역', '고용보험', '장기요양'],
      Extend: that.$ibsheetHelper.preset.amount,
      Width: 85,
    },
    {
      Name: 'deductSum',
      Header: ['공제내역', '합계', '합계'],
      Extend: that.$ibsheetHelper.preset.amount,
      Width: 85,
    },
    {
      Name: 'actPayAmt',
      Header: ['차감지급액', '차감지급액', '차감지급액'],
      Extend: that.$ibsheetHelper.preset.amount,
      Width: 90,
    },
    {
      Name: 'blnDepNm',
      Header: ['소속', '소속', '소속'],
      Align: 'left',
      Width: 200,
      Type: 'Text',
    }
  );
  return {
    ...opt,
  };
};
