<template>
  <pmis-content-box>
    <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" @loadSheet="loadSheet" />
  </pmis-content-box>
</template>

<script>
import options from '@/view/sysManage/stdCodeManage/lbrcCodeRegist/components/sheetOption/StandardUntpcPopupSheet.js';
import {selectStandardUntpc} from '@/view/sysManage/stdCodeManage/api/untpcRegist.js';
export default {
  data() {
    return {
      loadSearchData: [],
      Options: $addColCheckbox(options(this)),
    };
  },
  created() {
    this.onSearch();
    this.addEvent({name: 'getStandardUntpc', func: this.getStandardUntpc});
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    async onSearch() {
      const response = await selectStandardUntpc();
      this.loadSearchData = response.data;
    },
    getStandardUntpc(callback) {
      if (callback) {
        callback(this.sheet.getRowsByChecked('chk'));
      }
    },
  },
};
</script>

<style></style>
