<template>
  <pmis-content-box :loading="loading">
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onRenderFirstFinish,
      }"
      @loadSheet="loadSheet"
    />

    <iui-modal name="JointCustomerListModal" title="업체정보" :btns="modalBtns" sizeType="size2">
      <CustomerListPopup
        :uid="_uid"
        :frmDs="$getConstants('FRM_DS_CONSTRUCTION').code"
        :bsn-no-list="[ctrctInfo.contcompCode]"
      />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
/**
 * 계약정보등록(tab3) > 공동도급사
 *   controls :
 *     CustomerListPopup 거래처조회 팝업
 * */

import CustomerListPopup from '@/view/Resource/components/CustomerListPopup.vue';
import {deletePjtJointList, savePjtJointList, selectPjtJointList} from '../api/cntrctInfoRegist.js';
import options from './sheetOption/PrjJointSheet.js';

export default {
  components: {CustomerListPopup},
  data() {
    return {
      loadSearchData: [],
      Options: options(this),
      modalBtns: [{name: '확인', callback: this.onHideModal}],
      loading: false,
    };
  },
  beforeCreate() {
    $mapGetters(this, {
      searchInfo: 'searchInfo',
      ctrctInfo: 'ctrctInfo',
    });
  },
  created() {
    this.addEvent([
      {name: 'PrjJoint_onSearch', func: this.onSearch},
      {name: 'PrjJoint_onAdd', func: this.onAdd},
      {name: 'PrjJoint_onSave', func: this.onSave},
      {name: 'PrjJoint_onDelete', func: this.onDelete},
    ]);
  },
  mounted() {
    this.callEvent({name: 'onFocusedRowData', param: '5'});
  },
  activated: function() {
    this.callEvent({name: 'onFocusedRowData', param: '5'});
  },
  methods: {
    async onSearch(param) {
      this.loading = true;

      try {
        const response = await selectPjtJointList(param);
        this.sheet.loadSearchData(response.data);
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    onAdd() {
      const addRow = this.sheet.addRow();
      this.sheet.setValue(addRow, 'pgmCd', this.pgmCd);
      this.sheet.setValue(addRow, 'prjCd', this.prjCd);
      this.sheet.setValue(addRow, 'cud', 1);
    },
    onHideModal() {
      this.callEvent({
        name: 'CustomerListPopup_callbackData',
        param: data => {
          let isDup = false;
          let index = 0;
          this.sheet.getDataRows().forEach((obj, idx) => {
            if (obj.bsnNo == data.bsnNo) {
              isDup = true;
              index = idx;
              return false;
            }
          });

          if (isDup) {
            this.$alert({title: '공동도급사 선택', message: `이미 등록된 업체입니다. [${data.frmNm}]`});
            this.sheet.selectRow(this.sheet.getRowByIndex(index + 1), 1);
            return;
          }

          const focusedRow = this.sheet.getFocusedRow();
          this.sheet.setValue(focusedRow, 'bsnNo', data.bsnNo, 1);
          this.sheet.setValue(focusedRow, 'frmNm', data.frmNm, 1);
          this.$modal.hide('JointCustomerListModal');
        },
      });
    },
    async onSave() {
      if (!this.sheet.hasChangedData()) {
        await this.$alert({title: '저장', message: '수정된 내용이 없습니다.'});
        return;
      }

      const saveList = $_statusToCud(this.sheet.getSaveJson(1).data);
      const validRow = saveList.find(row => !row.bsnNo);
      if (validRow !== undefined) {
        await this.$alert({title: '저장', message: '업체코드가 선택되지 않은 항목이 존재합니다.'});
        return;
      }
      let param = {
        saveList,
      };

      savePjtJointList(param).then(response => this.onSearch({pgmCd: this.pgmCd, prjCd: this.prjCd}));
    },
    async onDelete() {
      const checkedRows = this.sheet.getRowsByChecked('cbx');
      if (checkedRows.length == 0) {
        await this.$alert({title: '삭제', message: '삭제할 공동도급사을 선택해 주세요.'});
        return;
      }

      const deleteList = checkedRows.reduce((rArray, row) => {
        if (row.cud == 1) {
          this.sheet.removeRow(row);
        } else {
          rArray.push({
            pgmCd: row.pgmCd,
            prjCd: this.prjCd,
            bsnNo: row.bsnNo,
          });
        }
        return rArray;
      }, []);

      if (0 == deleteList.length) return;

      let param = {
        deleteList,
        prjCd: this.prjCd,
      };
      if (await this.$confirm({title: '공동도급사 삭제', message: '공동도급사를 삭제하시겠습니까?'}))
        deletePjtJointList(param).then(response => this.onSearch({pgmCd: this.pgmCd, prjCd: this.prjCd}));
    },
    onRenderFirstFinish() {
      this.onSearch({pgmCd: this.pgmCd, prjCd: this.prjCd});
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped></style>
