export const selectInputCount = param => axios.post('/outsrcDtls/selectInputCount', param);
export const selectContContPlanListAndInfo = param => axios.post('/outsrcDtls/selectContContPlanListAndInfo', param);
export const selectContContPlanList = param => axios.post('/outsrcDtls/selectContContPlanList', param);
export const selectContContexecPlanOfDegreeList = param =>
  axios.post('/outsrcDtls/selectContContexecPlanOfDegreeList', param);
export const insertContContexecPlan = param => axios.post('/outsrcDtls/insertContContexecPlan', param);
export const selectOtherPjtContList = param => axios.post('/outsrcDtls/selectOtherPjtContList', param);
export const copyOtherPjtCont = param => axios.post('/outsrcDtls/copyOtherPjtCont', param);
export const dtlsDcns = param => axios.post('/outsrcDtls/dtlsDcns', param);
export const dcnsCancl = param => axios.post('/outsrcDtls/dcnsCancl', param);
export const deleteContContexecPlan = param => axios.post('/outsrcDtls/deleteContContexecPlan', param);
export const saveContContPlan = param => axios.post('/outsrcDtls/saveContContPlan', param);
export const deleteContContPlanList = param => axios.post('/outsrcDtls/deleteContContPlanList', param);
export const selectNextItemSeq = param => axios.post('/outsrcDtls/selectNextItemSeq', param);
export const saveContExecAsContCont = param => axios.post('/outsrcDtls/saveContExecAsContCont', param);
