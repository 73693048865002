<template>
  <pmis-content-box>
    <iui-container-new type="css-flex" flex-direction="row">
      <i-col>
        <i-row>
          <iui-container-new type="table" theme="bullet" header-width="100px">
            <i-row>
              <i-col-header required>자원구분</i-col-header>
              <i-col>
                <iui-select
                  name="costType"
                  :items="costTypeItems"
                  defaultCd="S"
                  @change="value => onSetState('costType', value)"
                  :value="inputData.costType"
                  :enable="enableCostType"
                  width="120px"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>코드 (자동부여)</i-col-header>
              <i-col>
                <iui-text
                  name="itemCode"
                  @change="onSetState('itemCode', $event)"
                  :enable="false"
                  :value="inputData.itemCode"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>명칭</i-col-header>
              <i-col>
                <iui-text name="itemName" @change="onSetState('itemName', $event)" :value="inputData.itemName" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>규격</i-col-header>
              <i-col>
                <iui-text name="ssize" @change="onSetState('ssize', $event)" :value="inputData.ssize" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>단위</i-col-header>
              <i-col>
                <iui-text name="unitName" type="smallcode" :value="inputData.unitName" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>수량</i-col-header>
              <i-col>
                <iui-text
                  name="qty"
                  type="quantity"
                  @change="onSetState('qty', $event)"
                  :value="inputData.qty"
                  :enable="false"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>산식</i-col-header>
              <i-col>
                <iui-text
                  name="formula"
                  @change="onSetState('formula', $event)"
                  @input="formulaInput"
                  :value="inputData.formula || ''"
                />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-row>
        <i-spacer />
        <i-row>
          <pmis-content-box>
            <template v-slot:title>산식작성 방법</template>
            <p>1. 맨앞 : 자재(M), 노무(L), 장비(E), 경비(A), 전체(T)의 한 문자 사용</p>
            <p>2. 자원참조는 내역번호를 사용 (예) M"00010" + M"00030" * 0.0054</p>
            <p>3. 복수참조는 "-"를 사용 (예) M"00010-00030" * 0.0054</p>
            <p>4. 산식 : *, /, +, -</p>
            <p>5. 계산값 : M * 0.0054</p>
          </pmis-content-box>
        </i-row>
      </i-col>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    costTypeItems: {
      type: Array,
    },
    param: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      inputData: {
        costType: '',
        itemCode: '',
        itemName: '',
        ssize: '',
        unit: $getConstants('UNIT_EXPRESSION').code,
        unitName: '식',
        qty: '1',
        formula: '',
        enableCostType: true,
      },
    };
  },
  created() {
    this.addEvent([
      {name: 'ArithmeticPop_popConfirm', func: this.onPopConfirm},
      {name: 'ArithmeticPop_setData', func: this.setData},
    ]);

    this.setData(this.param);
  },
  methods: {
    setData(param) {
      this.enableCostType = !Object.keys(param).length;
      for (let key in this.inputData) {
        if (param && param.hasOwnProperty(key)) {
          this.inputData[key] = param[key];
        }
      }
    },
    onSetState(name, event) {
      this.inputData[name] = typeof event === 'object' ? event.target.value : event;
    },
    onPopConfirm(param) {
      if (this.inputData.costType == '') {
        this.$alert({title: '산식작성', message: '자원구분을 선택해야 합니다.'});
        return;
      }
      param(this.inputData);
    },
    formulaInput(e) {
      setTimeout(() => {
        const pattern = `[^(${$getConstants('COST_TYPE_M').code}|${$getConstants('COST_TYPE_L').code}|${
          $getConstants('COST_TYPE_E').code
        }|${$getConstants('COST_TYPE_A').code}|${$getConstants('COST_TYPE_T').code}|*|/|+|\\-|(|)|.|\\s|0-9|"|,)]`;
        e.target.value = e.target.value.replace(RegExp(pattern, 'gi'), '').toUpperCase();
      }, 10);
    },
  },
};
</script>

<style></style>
