<template>
  <pmis-page
    :search-company-visible="false"
    :search-project-visible="false"
    @search-click="onSearch"
    @save-click="onSave"
  >
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="415px">
          <pmis-tab-box>
            <template v-slot:title>목록</template>
            <CompanyList :callbackFocus="callbackFocus" />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col min-width="1020px">
          <iui-tab
            :comps="tabList"
            :keepAlive="true"
            @before-active-tab="beforeActiveTab"
            @after-active-tab="afterActiveTab"
          ></iui-tab>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import CompanyList from '@/view/member/components/CompanyList.vue';
import store from '@/view/member/store/member.js';
import {saveCompanyInfo} from '../api/member.js';
export default {
  components: {
    CompanyList,
  },

  data() {
    return {
      loading: false,
      index: 0,
      tabList: [
        {
          tabNm: '상세정보',
          compNm: 'member',
          path: '@/view/member/components/CompanyDetail2',
        },
        {
          tabNm: '기초정보',
          compNm: 'baseInfo',
          path: '@/view/member/components/BaseInfo',
        },
      ],
    };
  },

  beforeCreate() {
    if (!this.$store.getters.getStoreNm) {
      this.$store.commit('setCurrentUrl', {path: 'member'});
    }

    $init(this, store);
    $mapGetters(this, ['searchInfo', 'companyInfo']);
  },

  created() {
    this.canNew = false;
  },

  methods: {
    onSearch() {
      this.callEvent({name: 'companySelectCompanyNotConfirmList'});
    },

    async onSave() {
      const param = {
        pgmCd: this.companyInfo.pgmCd,
        frmGb: this.companyInfo.frmGb,
        useStrdate: this.companyInfo.useStrdate,
        useEnddate: this.companyInfo.useEnddate,
        simpleExec: this.companyInfo.simpleExec,
      };
      const response = await saveCompanyInfo(param);
      if (response.status == 200) {
        this.callEvent({name: 'companySelectCompanyNotConfirmList'});
      }
    },

    beforeActiveTab(index) {
      this.index = index;
    },

    callbackFocus() {
      this.callEvent({name: 'setActiveTab', param: 0});
      this.callEvent({name: 'docManageInfoClearImage'});
    },

    afterActiveTab() {
      if (this.searchInfo.pgmCd) {
        if (this.index == 0) {
          this.callEvent({name: 'selectCompanyInfo'});
          // setTimeout(() => {
          //   this.setBtns();
          // }, 1);
        } else if (this.index == 1) {
          this.callEvent({name: 'baseInfoSearch'});
        }
      }
    },
  },
};
</script>

<style scoped></style>
