export const selectFixedTimeSubSearchData = () => axios.post('/closeFixedTimeSub/selectFixedTimeSubSearchData', {});

export const selectPrePayable = param => axios.post('/closeAnyTimeSub/selectPrePayable', param);

export const selectSubMonList = param => axios.post('/closeFixedTimeSub/selectSubMonList', param);

export const selectSubMonDetailList = param => axios.post('/closeFixedTimeSub/selectSubMonDetailList', param);

export const selectReportData = param => axios.post('/closeFixedTimeSub/selectReportData', param);
export const deleteFile = param => axios.post('/closeFixedTimeSub/deleteFile', param);
