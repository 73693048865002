import COMMON_FUCNTION from '@/view/bidding/js/BiddingUtils.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants';
export default that => {
  return {
    Cfg: {CanEdit: 0},
    Def: {Row: {NoColor: 1}},
    Cols: [
      {Header: '거래처명', Name: 'cusNm', Type: 'Text', RelWidth: 1},
      {
        Header: '발주번호',
        Name: 'poNo',
        Type: 'Text',
        Width: 120,
        Align: 'Center',
        CustomFormat: v => COMMON_FUCNTION.getMakeFormat(v),
      },
      {Header: '발주명', Name: 'ttl', Type: 'Text', RelWidth: 1},
      {
        Header: '접수상태',
        Name: 'poSt',
        Type: 'Text',
        RelWidth: 1,
        Align: 'Center',
        CustomFormat: v =>
          ({
            [BIDDING_CONSTANTS.PROGRESS_STATE.PO_SIGN_READY]: '접수대기',
            [BIDDING_CONSTANTS.PROGRESS_STATE.PO_COMPLETE]: '접수',
          }[v]),
      },
    ],
  };
};
