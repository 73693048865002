export default that => {
  return {
    Cfg: {
      SearchMode: 2,
      CanEdit: 1,
      CanSort: 0,
    },
    Def: {
      Row: {
        NoColor: 1,
      },
    },
    Cols: [
      {
        Header: '선택',
        Name: 'cbx',
        Type: 'Bool',
        Align: 'center',
        Width: 50,
      },
      {
        Header: '사업자번호',
        Name: 'bsnNo',
        Type: 'Text',
        Align: 'center',
        Width: 120,
        CanEdit: 0,
        CustomFormat: 'SaupNo',
      },
      {
        Header: '협력사명',
        Name: 'frmNm',
        Type: 'Text',
        RelWidth: 2,
        MinWidth: 120,
        CanEdit: 0,
      },
      {
        Header: '지역',
        Name: 'siteArea2Nm',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        MinWidth: 100,
        CanEdit: 0,
      },
      {
        Header: '전화번호',
        Name: 'icprPhone',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        MinWidth: 100,
        CanEdit: 0,
        CustomFormat: 'PhoneNo',
      },
      {
        Header: '대표자명',
        Name: 'rpsvNm',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        MinWidth: 100,
        CanEdit: 0,
      },
      {
        Header: '담당자명',
        Name: 'icprNm',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        MinWidth: 100,
        CanEdit: 0,
      },
      {Name: 'cusPgmCd', Visible: false},
      {Name: 'email1', Visible: false},
      {Name: 'email2', Visible: false},
    ],
  };
};
