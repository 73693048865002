<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-col>
          <iui-container-new type="css-flex">
            <i-row>
              <i-col width="400px">
                <pmis-tab-box no-border-box no-padding-box>
                  <template v-slot:title>작업분류목록</template>
                  <unit-opert-cl
                    :callback="uniOpertClCallback"
                    :eventNms="{getFocusedRow: 'getFocusedRow_unitOpertCl'}"
                  />
                </pmis-tab-box>
              </i-col>
              <i-spacer />
              <i-col>
                <iui-tab
                  ref="tab"
                  :comps="tabList"
                  :keep-alive="true"
                  :isAuto="false"
                  @click="onTabClick"
                  @after-active-tab="afterActiveTab"
                >
                  <template v-slot:tab-right>
                    <iui-button
                      value="확정"
                      @click="onFlagUpdate($getConstants('PRJ_FLAG1').code)"
                      v-if="prjFlag != $getConstants('PRJ_FLAG1').code"
                    />
                    <iui-button
                      value="확정취소"
                      @click="onFlagUpdate('')"
                      v-if="prjFlag == $getConstants('PRJ_FLAG1').code"
                    />
                  </template>
                </iui-tab>
              </i-col>
            </i-row>
            <i-spacer />
            <i-row v-if="this.tabIndex == 1">
              <i-col>
                <pmis-tab-box no-border-box no-padding-box>
                  <template v-slot:title>도급내역</template>
                  <template v-slot:title-right>
                    <iui-button
                      value="행삭제"
                      @click="onContractDelete"
                      v-if="prjFlag != $getConstants('PRJ_FLAG1').code"
                    />
                    <iui-button
                      value="도급내역선택"
                      @click="onContractPopup"
                      v-if="prjFlag != $getConstants('PRJ_FLAG1').code"
                    />
                  </template>
                  <ContDtlsList
                    :eventNms="{
                      deleteRow: 'contDelRow',
                    }"
                  />
                </pmis-tab-box>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal name="ContractPopup" sizeType="size3" :btns="modalBtns" @closed="onCloseContractPopup">
      <ContractPopup :isPopup="true" :eventNms="{onFocusedRowData: 'onFocusedRowDataContract'}" />
    </iui-modal>
  </pmis-page>
</template>

<script>
import store from '@/view/unitOpertManage/store/unitOpert.js';
import PrjList from '@/view/Project/components/PrjList.vue';
import UnitOpertCl from '@/view/unitOpertManage/components/UnitOpertCl.vue';
import ContDtlsList from '@/view/unitOpertManage/components/ContDtlsList.vue';
import ContractPopup from '@/view/executDtls/components/popup/ContractPopup.vue';
import {
  selectPjtContWcls,
  saveTaskCl,
  deleteContWclsMList,
  selectNotMappingUnitOper,
  updatePjtContWcls,
} from '../api/unitOpertRegist.js';
import {saveContWplanM, deleteContWplanM, selectContWplanMList} from '../api/task.js';
import {selectContContexecPlanOfDegreeList} from '@/view/outsrcDtls/api/outsrcDtls.js';
export default {
  components: {
    PrjList,
    UnitOpertCl,
    ContDtlsList,
    ContractPopup,
  },

  data() {
    return {
      uniOpertClObj: {},
      wbsCd: '',
      tabList: [
        {tabNm: '작업분류', compNm: 'detail', path: '@/view/unitOpertManage/components/DetailInfo.vue'},
        {tabNm: '단위작업', compNm: 'opertList', path: '@/view/unitOpertManage/components/UnitOpertList.vue'},
      ],
      tabIndex: 0,
      modalBtns: [{name: '확인', callback: this.onConfirm}],
      contRows: [],
    };
  },

  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['detailInfo', 'isRoot', 'sumPlnWgh', 'prjFlag']);
    $mapMutations(this, ['setDetailInfo', 'initDetailInfo', 'setPrjFlag']);
  },
  created() {
    this.addFuncPrj(this.onSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDel);
  },

  methods: {
    async onSearch() {
      this.cud = 0;
      this.initDetailInfo();
      const response = await selectPjtContWcls({});
      this.setPrjFlag(response.data ?? '');
      this.setBtnHandler();

      this.callEvent({name: 'selectContWclsMList'});
    },
    onAdd() {
      if (this.tabIndex == 0) {
        let isPlnWgh = this.detailInfo.isPlnWgh && this.detailInfo.plnWgh;

        let _detailInfo = {};
        this.callEvent({
          name: 'getFocusedRow_unitOpertCl',
          param: data => {
            _detailInfo['uppFldrNo'] = isPlnWgh ? data.parentNode.fldrNo : data.fldrNo;
            _detailInfo['uppFldrCd'] = isPlnWgh ? data.parentNode.fldrCd : data.fldrCd;
            _detailInfo['uppFldrNm'] = isPlnWgh ? data.parentNode.fldrNm : data.fldrNm;
          },
        });

        for (let key in this.detailInfo) {
          if (['uppFldrNo', 'uppFldrCd', 'uppFldrNm', 'csNo'].indexOf(key) != -1) continue;
          _detailInfo[key] = '';
        }
        _detailInfo['isPlnWgh'] = true;

        this.setDetailInfo({..._detailInfo});
      } else {
        this.onUnitWorkAdd();
      }
    },
    onUnitWorkAdd() {
      if (!Object.keys(this.uniOpertClObj).length || this.uniOpertClObj.isRoot) {
        this.$alert({title: '신규', message: '단위작업목록을 먼저 선택해주세요'});
        return;
      }
      this.callEvent({name: 'unitOperListAddRow'});
    },
    async onUnitWorkDel() {
      let deleteRows;
      this.callEvent({
        name: 'unitOpertListGetRowsByChecked',
        param: rows => {
          deleteRows = rows;
        },
      });
      if (!deleteRows.length) {
        this.$alert({title: '단위작업삭제', message: '삭제할 단위작업을 선택해 주세요.'});
        return;
      }
      if (
        !(await this.$confirm({
          title: '삭제',
          message: '삭제하시겠습니까? <br/> 삭제된 단위작업의 도급내역도 모두 삭제됩니다.',
        }))
      ) {
        return;
      }

      const param = {
        wplanList: deleteRows.map(row => ({wbsCd: row.wbsCd, wbsId: row.wbsId})),
      };
      const response = await deleteContWplanM(param);
      this.uniOpertClCallback();
    },
    async onSave() {
      // 작업분류 저장
      if (this.tabIndex == 0) {
        let sumPlnWgh =
          this.cud == 2 ? Number(this.sumPlnWgh) - Number(this.uniOpertClObj.plnWgh) : Number(this.sumPlnWgh);
        if (sumPlnWgh + Number(this.detailInfo.plnWgh) > 100) {
          this.$alert({
            title: '저장',
            message: `단위작업목록의 총 물량분개비율이 100을 넘을 수 없습니다. <br /> total: ${this.sumPlnWgh} `,
          });
          return;
        }

        let param = {
          fldrNo: this.detailInfo.fldrNo,
          fldrCd: this.detailInfo.fldrCd,
          fldrNm: this.detailInfo.fldrNm,
          plnWgh: this.detailInfo.isPlnWgh ? this.detailInfo.plnWgh : undefined,
          uppFldrNo: this.detailInfo.uppFldrNo,
          uppFldrCd: this.detailInfo.uppFldrCd,
          rmk: this.detailInfo.rmk,
        };

        const response = await saveTaskCl(param);
        if (response.data) {
          this.cud = 0;
          const focusKey = String(response.data);
          this.callEvent({name: 'selectContWclsMList', param: focusKey});
        }
      } else if (this.tabIndex == 1) {
        //단위작업 저장
        let wplanList = this.getOpertList();
        if (!wplanList) {
          return;
        }
        const response = await saveContWplanM({wplanList: wplanList});
        if (response.data) {
          this.cud = 0;
          this.uniOpertClCallback();
        }
      }
    },
    getOpertList() {
      let wplanList;
      let sumPlnWgh = 0;
      this.callEvent({
        name: 'unitOpertListGetJsonSave',
        param: data => {
          wplanList = $_statusToCud(data);
        },
      });
      wplanList.some(o => {
        o.planStrdate = o.planStrdate.replace(/\//g, '');
        o.planEnddate = o.planEnddate.replace(/\//g, '');
        o.prjCd = this.prjCd;
        sumPlnWgh += o.plnWgh;
        if (!o.wbsCd) {
          o.wbsCd = this.wbsCd;
        }
      });
      if (sumPlnWgh != 100) {
        this.$alert({title: '저장', message: `물량비중의 총 합은 100%가 되어야 합니다.`});
        return;
      }
      return wplanList;
    },
    async onDel() {
      if (this.tabIndex == 0) {
        if (this.isRoot) {
          this.$alert({title: '삭제', message: '삭제할 수 없습니다.'});
          return;
        }
        let message = this.detailInfo.childNodes
          ? '하위 작업목록들도 모두 삭제 됩니다<br>정말 삭제하시겠습니까?'
          : '선택된 작업분류를 삭제하시겠습니까?';
        if (!(await this.$confirm({title: '삭제', message: message}))) {
          return;
        }
        this.deleteContWclsMList();
      } else {
        this.onUnitWorkDel();
      }
    },
    async deleteContWclsMList() {
      const param = {
        fldrNo: this.detailInfo.fldrNo,
        fldrCd: this.detailInfo.fldrCd,
      };
      const response = await deleteContWclsMList(param);
      if (response.data) {
        this.onSearch();
      }
    },
    uniOpertClCallback(row) {
      if (row) {
        this.uniOpertClObj = row;
        this.setDetailInfo(row);
        this.wbsCd = row.fldrNo;
        this.setBtnHandler();
      }

      if (this.tabIndex == 1) {
        this.callEvent({name: 'initSheetList'});
        this.callEvent({name: 'selectContWplanMList'});
      }
    },
    async onFlagUpdate(flag) {
      if (flag === $getConstants('PRJ_FLAG1').code && this.sumPlnWgh != 100) {
        this.$alert({title: '확정', message: '작업분류의 총 물량분개비율이 100%가 되어야 합니다.'});
        return;
      }
      if (flag === $getConstants('PRJ_FLAG1').code) {
        const responseContWplan = await selectContWplanMList({});
        const totalPlnWhg = {};
        responseContWplan.data.forEach(item => {
          totalPlnWhg[item.wbsCd] = Number(totalPlnWhg[item.wbsCd] ?? 0) + item.plnWgh;
        });
        if (Object.values(totalPlnWhg).find(plnWhg => plnWhg != 100)) {
          this.$alert({title: '확정', message: `단위작업의 물량비중 총 합은 100%가 되어야 합니다.`});
          return;
        }

        const responseNotMapping = await selectNotMappingUnitOper({});
        const fldrNmList = responseNotMapping.data.fldrNmList;
        const unitOperNotMappingList = responseNotMapping.data.unitOperNotMappingList;
        if (fldrNmList.length) {
          let message = `단위작업목록 [${fldrNmList.join(', ')}] 단위작업이 없습니다.`;
          this.$alert({title: '확정', message: message});
          return;
        }
        if (unitOperNotMappingList.length) {
          const map = {};
          unitOperNotMappingList.map(item => {
            if (!map[item.wbsCd]) map[item.wbsCd] = {};
            map[item.wbsCd]['fldrNm'] = item.fldrNm;
            if (!map[item.wbsCd]['wbsNmList']) map[item.wbsCd]['wbsNmList'] = [];
            map[item.wbsCd]['wbsNmList'].push(item.wbsNm);
          });

          let message = '';
          for (let key in map) {
            message += `${map[key].fldrNm}[${map[key].wbsNmList.join(', ')}] <br />`;
          }
          message += '단위작업의 도급내역이 연계되지 않았습니다.';
          this.$alert({title: '확정', message: message});
          return;
        }
      }

      let title = '';
      let msg = '';
      if (flag == $getConstants('PRJ_FLAG1').code) {
        title = '확정';
        msg = '확정 하시겠습니까?';
      } else {
        title = '확정취소';
        msg = '확정취소 하시겠습니까? ';
      }

      if (!(await this.$confirm({title: title, message: msg}))) {
        return;
      }

      const response = await updatePjtContWcls({
        prjFlag: flag,
      });
      if (response.data) {
        this.setPrjFlag(flag);
        this.setBtnHandler();
      }
    },
    onTabClick(index) {
      if (index == 1 && this.sumPlnWgh != 100) {
        this.$alert({title: '단위작업', message: '단위작업목록의 총 물량분개비율이 100% 이어야 합니다.'});
        return;
      }
      this.callEvent({
        name: 'setActiveTab',
        param: index,
      });
    },
    async onContractPopup() {
      if (this.tabIndex != 1) {
        this.$alert({title: '도급내역선택', message: '단위작업탭을 선택해주세요'});
        return;
      }

      const response = await selectContContexecPlanOfDegreeList({prjFlag: $getConstants('PRJ_FLAG1').code});
      if (!response.data.length) {
        this.$alert({title: '도급내역', message: '도급내역이 확정되지 않았습니다.<br>도급내역을 먼저 확정해주세요.'});
        return;
      }

      this.callEvent({
        name: 'unitOpertListGetFocusedRow',
        param: data => {
          if (!data || data.Added) {
            this.$alert({title: '도급내역선택', message: '단위작업을 먼저 등록후 선택해주세요'});
            return;
          }
          this.$modal.show('ContractPopup', {title: '도급내역'});
        },
      });
    },
    onConfirm() {
      this.callEvent({
        name: 'getContRowsByChecked',
        param: data => {
          if (!data.length) {
            this.$alert({title: '도급내역선택', message: '도급내역이 선택되지 않았습니다.'});
            return;
          }
          this.contRows = data;
        },
      });
      this.$modal.hide('ContractPopup');
    },
    onCloseContractPopup() {
      if (this.contRows.length) {
        this.callEvent({name: 'getContractData', param: this.contRows});
        this.contRows = [];
      }
    },
    onContractDelete() {
      this.$confirm({title: '삭제', message: '삭제하시겠습니까?'}, () => {
        this.callEvent({name: 'contDelRow'});
      });
    },
    afterActiveTab(index) {
      this.tabIndex = index;
      this.setBtnHandler();
      if (index == 1) {
        this.callEvent({name: 'selectContWplanMList'});
      }
    },
    setBtnHandler() {
      if (this.prjFlag === $getConstants('PRJ_FLAG1').code) {
        this.canNew = false;
        this.canSave = false;
        this.canDelete = false;
      } else {
        this.canNew = true;
        this.canSave = true;
        this.canDelete = true;
      }
    },
  },
};
</script>
