export default that => {
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      CanEdit: 0,
      DataMerge: 1,
      IgnoreFocused: true,
    },
    Cols: [
      {
        Header: '촬영일자',
        Name: 'phoDt',
        Type: 'Text',
        CustomFormat: v => dateFormat(v), // yyyy-MM-dd
        Align: 'center',
        RelWidth: 1,
        VAlign: 'Top',
      },
      {
        Header: '단위작업명',
        Name: 'unitPathNm',
        Type: 'Text',
        RelWidth: 2,
        ColMerge: 0,
      },
      {
        Header: '사진설명',
        Name: 'rmk',
        Type: 'Text',
        RelWidth: 3,
        ColMerge: 0,
      },
    ],
  };
};
