export default that => {
  return {
    Def: {
      Header: {
        Hidden: true,
      },
    },
    Cfg: {
      CanEdit: 0,
      MainCol: 'obsName',
    },
    Cols: [
      {
        Name: 'obsName',
        Header: '조직도',
        RelWidth: 1,
      },
      {
        Name: 'blnDepNm',
        Visible: false,
      },
      {
        Name: 'blnDepNo',
        Visible: false,
      },
      {
        Name: 'blnDepCd',
        Visible: false,
      },
      {
        Name: 'uppBlnDepNo',
        Visible: false,
      },
      {
        Name: 'uppBlnDepCd',
        Visible: false,
      },
      {
        Name: 'uppBlnDepNm',
        Visible: false,
      },
    ],
  };
};
