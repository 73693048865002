export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      HeaderMerge: 1,
      CanEdit: false,
    },
    Cols: [
      {
        Name: 'ioDate',
        Header: '일자',
        Width: 100,
        Type: 'Text',
        Align: 'center',
        Extend: preset.date,
      },
      {
        Name: 'rowNo',
        Header: '순번',
        Type: 'Text',
        Align: 'Center',
        Width: 150,
      },
      {
        Name: 'matCode',
        Header: '자재코드',
        Type: 'Text',
        Align: 'center',
        Width: 140,
      },
      {
        Name: 'itemName',
        Header: '자재명',
        Type: 'Text',
        RelWidth: 2,
        MinWidth: 140,
      },
      {
        Name: 'size',
        Header: '규격',
        Type: 'Text',
        RelWidth: 2,
        MinWidth: 140,
      },
      {
        Name: 'unit',
        Header: '단위',
        Type: 'Text',
        Align: 'center',
        Width: 100,
        Format: $getConstants('UNIT').code,
      },
      {
        Name: 'qty',
        Header: '입고수량',
        Extend: preset.quantity,
        RelWidth: 1,
        MinWidth: 140,
      },
    ],
  };
};
