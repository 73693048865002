const IuiVPathPlugin = {
  install(Vue) {
    Vue.prototype.$setVPath = function(vm) {
      // console.log(`${vm.$vnode.componentOptions.tag}`);
      // console.log(vm);
      // console.log(`propsData.hasOwnProperty('id') - ${vm.$options.propsData.hasOwnProperty('id')}`);
      // console.log(`propsData.id - ${typeof vm.$options.propsData.id}`);
      /**
       * beforeCreate에서 실행하기 위해 $options.propsData를 사용한다
       * created에서 실행한다면 vm.id를 사용해도 무방하다
       */
      let parentVPath = '';
      let vPath = '';

      if (vm.$options && vm.$parent && vm.$parent.$options) {
        // 부모의 VPath를 parentVPath에 설정
        parentVPath = vm.$parent.$options._VPath || '';

        if (parentVPath === '') {
          parentVPath = `Root`;
        }

        if (vm.$options.propsData.id === undefined) {
          vPath = `${parentVPath}/${vm._uid}`;
        } else {
          vPath = `${parentVPath}/${vm.$options.propsData.id}`;
        }

        vm.$options._VPath = vPath;

        vm.internalParentVPath = parentVPath;
        vm.internalVPath = vm.$options._VPath;
      }
    };

    Vue.mixin({
      _VPath: '',

      data() {
        return {
          internalParentVPath: '',
          internalVPath: '',
        };
      },

      // beforeCreate() {
      // },

      created() {
        this.$setVPath(this);
      },
    });
  },
};

export default IuiVPathPlugin;
