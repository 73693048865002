import COMMON_FUCNTION from '@/view/bidding/js/BiddingUtils.js';
export default that => {
  return {
    Cfg: {CanEdit: 0},
    Def: {
      Row: {NoColor: 1},
    },
    Cols: [
      {Header: '거래처', Name: 'cusNm', Type: 'Text', RelWidth: 1, MinWidth: 120},
      {
        Header: '계약번호',
        Name: 'poNo',
        Type: 'Text',
        Align: 'center',
        Width: 120,
        CustomFormat: v => COMMON_FUCNTION.getMakeFormat(v),
      },
      {Header: '계약명', Name: 'ttl', Type: 'Text', RelWidth: 1, MinWidth: 150},
    ],
  };
};
