export default that => {
  return {
    Cfg: {
      CanEdit: false,
    },
    Cols: [
      {
        Header: '코드',
        Name: 'cmnUCd',
        Type: 'Text',
        Align: 'center',
        Width: 100,
        Cursor: 'pointer',
        CanEdit: false,
        Size: 4,
      },
      {
        Header: '코드명',
        Name: 'cmnUNm',
        Type: 'Text',
        RelWidth: 1,
      },
      {
        Header: '비고',
        Name: 'rmk',
        Type: 'Text',
        RelWidth: 1,
      },
      {Name: 'pgmCd', Visible: false},
      {Name: 'cmnMdyn', Visible: false},
    ],
  };
};
