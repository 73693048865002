export default that => {
  return {
    Cfg: {
      CanEdit: 0,
      SearchMode: 0,
    },
    Cols: [
      {
        Header: '문서번호',
        Name: 'dpDocNo',
        Type: 'Text',
        MinWidth: 180,
      },
      {
        Header: '문서제목',
        Name: 'tl',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 220,
      },
      {
        Header: '발신처',
        Name: 'sndNmn',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 160,
      },
      {
        Header: '발신자명',
        Name: 'name',
        Type: 'Text',
        Align: 'Center',
        Width: 90,
      },
      {
        Header: '발신일시',
        Name: 'sndDttm',
        Extend: that.$ibsheetHelper.preset.date,
        Width: 100,
      },
      {
        Header: '수신자명',
        Name: 'rcvEmpNm',
        Type: 'Text',
        Align: 'Center',
        Width: 90,
      },
      {
        Header: '수신일시',
        Name: 'rcvDttm',
        Extend: that.$ibsheetHelper.preset.date,
        Width: 100,
      },
      {
        Header: '상태',
        Name: 'inoDs',
        Type: 'Text',
        Align: 'Center',
        Width: 100,
        Format: $getConstants('INO_DS').code,
        CustomFormat: v => {
          const obj = {};
          obj[$getConstants('INO_DS_IN').code] = '대내도착';
          obj[$getConstants('INO_DS_OUT').code] = '대외도착';
          return obj[v];
        },
      },
      {Name: 'flNo', Visible: false},
    ],
  };
};
