<template>
  <pmis-content-box>
    <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" />
  </pmis-content-box>
</template>

<script>
import {selectMonPjtCloseList} from '../api/expitmCloseRegist.js';
import options from './sheetOptions/DetailListSheet.js';
export default {
  beforeCreate() {
    $mapGetters(this, ['searchInfo']);
  },
  created() {
    this.addEvent([{name: 'DetailList_onSearch', func: this.onSearch}]);
  },
  data() {
    return {
      Options: options(this),
      loadSearchData: [],
    };
  },
  methods: {
    async onSearch() {
      const response = await selectMonPjtCloseList(this.searchInfo);
      this.loadSearchData = response.data;
    },
  },
};
</script>
