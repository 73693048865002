<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="340px">
          <pmis-tab-box>
            <template v-slot:title>근로자목록</template>
            <WorkerList />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col min-width="1350px">
          <pmis-tab-box>
            <template v-slot:title>상세정보</template>
            <template v-slot:title-right>
              <iui-button @click="onPopAddExcel" value="엑셀등록" />
            </template>
            <WorkerInfo />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
    <iui-modal name="excelUploadPop" title="엑셀등록" :btns="excelBtns" sizeType="size1">
      <ExcelUpload uri="/worker/excelUpload" title="근로자등록" :rows="excelRows" @error="onExcelUploadError" />
    </iui-modal>
    <iui-modal name="ExcelUploadResultPop" title="엑셀 업로드 실패 사유" sizeType="size6" @closed="resultList = []">
      <ExcelUploadResult :sheet-opt="excelSheetOpt" :list="resultList" />
    </iui-modal>
  </pmis-page>
</template>

<script>
/**
 * 사원등록(건설사)
 * components :
 *   WorkerList : 사원목록
 *   WorkerInfo : 상세정보
 * */
import store from './store/worker.js';
import WorkerInfo from './components/WorkerInfo.vue';
import WorkerList from './components/WorkerList.vue';
import ExcelUploadResult from '@/components/popup/ExcelUploadResult.vue';
import ExcelUpload from '@/components/popup/ExcelUpload.vue';
import excelOptions from '@/view/user/Worker/components/sheetOption/ExcelUploadWorker.js';
import EXCEL_CONSTANTS from '@/constants/excelConstants.js';
export default {
  components: {
    WorkerList,
    WorkerInfo,
    ExcelUpload,
    ExcelUploadResult,
  },
  data() {
    return {
      excelBtns: [{name: '확인', callback: this.onPopConfirmExcel}],
      resultList: [],
      excelRows: [
        {
          value: [
            {
              value: '국적코드',
              style: {fontStyle: {underline: 'NONE', size: 12, color: 0, bold: true, italic: false}},
              validate: {
                excelEnum: EXCEL_CONSTANTS.COMMON_CODE,
                pCode: $getConstants('NATION_CD').code,
                withDropdown: true,
                checksName: true,
              },
            },
            {
              value: '주민번호여권번호',
              style: {
                type: 'TEXT',
                fontStyle: {underline: 'NONE', size: 12, color: 0, bold: true, italic: false},
              },
              validate: {minLength: 13, maxLength: 15},
            },
            {value: '아이디', style: {type: 'TEXT'}, validate: {minLength: 5, maxLength: 8}},
            {
              value: '성명',
              style: {
                type: 'TEXT',
                fontStyle: {underline: 'NONE', size: 12, color: 0, bold: true, italic: false},
              },
              validate: {maxLength: 50},
            },
            {
              value: '소속구분',
              style: {fontStyle: {underline: 'NONE', size: 12, color: 0, bold: true, italic: false}},
              validate: {
                excelEnum: EXCEL_CONSTANTS.COMMON_CODE,
                pCode: $getConstants('BLN_DEP_PART_CD').code,
                withDropdown: true,
                checksName: true,
              },
            },
            {
              value: '소속코드',
              validate: {
                multi: {
                  conditionExcelEnum: EXCEL_CONSTANTS.COMMON_CODE,
                  pCode: $getConstants('BLN_DEP_PART_CD').code,
                  column: 'E',
                  codeList: [
                    EXCEL_CONSTANTS.DEPT_CODE,
                    EXCEL_CONSTANTS.WORK_GROUP_CODE,
                    EXCEL_CONSTANTS.LBRC_TEAM_CODE,
                  ],
                },
                maxLength: 20,
              },
            },
            {
              value: '근로자구분',
              style: {fontStyle: {underline: 'NONE', size: 12, color: 0, bold: true, italic: false}},
              validate: {
                excelEnum: EXCEL_CONSTANTS.COMMON_CODE,
                pCode: $getConstants('LABOR_DS_CD').code,
                withDropdown: true,
                checksName: true,
              },
            },
            {
              value: '직책',
              validate: {
                excelEnum: EXCEL_CONSTANTS.COMMON_CODE,
                pCode: $getConstants('DTS_NO').code,
                withDropdown: true,
                checksName: true,
              },
            },
            {
              value: '직급',
              validate: {
                excelEnum: EXCEL_CONSTANTS.COMMON_CODE,
                pCode: $getConstants('ROLL_NO').code,
                withDropdown: true,
                checksName: true,
              },
            },
            {
              value: '담당업무',
              validate: {
                excelEnum: EXCEL_CONSTANTS.COMMON_CODE,
                pCode: $getConstants('W_BUSINESS').code,
                withDropdown: true,
                checksName: true,
              },
            },
            {value: '전화번호', style: {type: 'TEXT'}, validate: {maxLength: 20}},
            {
              value: '휴대폰번호',
              style: {
                type: 'TEXT',
                fontStyle: {underline: 'NONE', size: 12, color: 0, bold: true, italic: false},
              },
              validate: {maxLength: 20},
            },
            {
              value: 'SMS수신동의',
              validate: {
                checkList: [
                  {name: 'Y', value: $getConstants('Y').code},
                  {name: '', value: ''},
                ],
              },
            },
            {value: '팩스번호', style: {type: 'TEXT'}, validate: {maxLength: 20}},
            {value: '이메일', style: {type: 'TEXT'}},
            {
              value: '거래은행',
              validate: {
                excelEnum: EXCEL_CONSTANTS.COMMON_CODE,
                pCode: $getConstants('BANK_CODE').code,
                withDropdown: true,
                checksName: true,
              },
            },
            {value: '계좌번호', style: {type: 'TEXT'}, validate: {maxLength: 20}},
            {value: '예금주', style: {type: 'TEXT'}, validate: {maxLength: 50}},
            {
              value: '체류자격',
              validate: {
                excelEnum: EXCEL_CONSTANTS.COMMON_CODE,
                pCode: $getConstants('VISA_KIND').code,
                withDropdown: true,
                checksName: true,
              },
            },
            {value: '체류기간', style: {type: 'TEXT'}, validate: {maxLength: 50}},
            {value: '입국일자', style: {type: 'TEXT'}, validate: {maxLength: 8}},
            {
              value: '장애유형',
              validate: {
                excelEnum: EXCEL_CONSTANTS.COMMON_CODE,
                pCode: $getConstants('HANDICAP_KIND').code,
                withDropdown: true,
                checksName: true,
              },
            },
            {value: '장애등급', style: {type: 'TEXT'}, validate: {maxLength: 50}},
            {value: '장애판정일', style: {type: 'TEXT'}, validate: {maxLength: 8}},
            {
              value: '시스템사용여부',
              validate: {
                checkList: [
                  {name: 'Y', value: $getConstants('Y').code},
                  {name: '', value: ''},
                ],
              },
            },
            {value: '노무단가', style: {type: 'TEXT'}},
            {
              value: '노무직종',
              validate: {excelEnum: EXCEL_CONSTANTS.LBRC_CODE, withDropdown: true, checksName: true},
            },
            {
              value: '국민연금제외',
              validate: {
                checkList: [
                  {name: 'Y', value: $getConstants('Y').code},
                  {name: '', value: ''},
                ],
              },
            },
            {
              value: '건강보험제외',
              validate: {
                checkList: [
                  {name: 'Y', value: $getConstants('Y').code},
                  {name: '', value: ''},
                ],
              },
            },
            {
              value: '장기요양경감대상',
              validate: {
                checkList: [
                  {name: 'Y', value: $getConstants('Y').code},
                  {name: '', value: ''},
                ],
              },
            },
            {
              value: '고용보험제외',
              validate: {
                checkList: [
                  {name: 'Y', value: $getConstants('Y').code},
                  {name: '', value: ''},
                ],
              },
            },
          ],
        },
      ],
      excelSheetOpt: excelOptions(this),
    };
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['workerInfo']);
    $mapMutations(this, ['setWorkerInfo']);
  },
  created() {
    this.canSearch = true;
    this.canNew = true;
    this.canSave = true;
    this.canDelete = true;
    this.canPrint = false;
    this.addFuncSearch(this.onSearch);
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'WorkerList_onSearch'});
    },
    onPopAddExcel() {
      this.$modal.show('excelUploadPop');
    },
    onPopConfirmExcel() {
      this.callEvent({
        name: 'excelUpload',
        param: () => {
          this.onSearch();
          this.$modal.hide('excelUploadPop');
        },
      });
    },
    onExcelUploadError(error, list) {
      if (error === 'server') {
        this.$alert({title: '엑셀업로드', message: '근로자등록 엑셀파일을 확인하십시오.'});
      } else {
        this.$alert({title: '엑셀업로드', message: error});
        if (list && list.length > 0) {
          this.resultList = list;
          this.$modal.show('ExcelUploadResultPop');
        }
      }
    },
  },
};
</script>

<style scoped></style>
