<template>
  <div id="h_wrap" @contextmenu="showContextTest" @click="hideContextTest" height="210px">
    <!-- 테스트를 위한 컨텍스트메뉴 테스트후 삭제-->
    <div id="testContext" class="testContext" ref="testContext" v-if="showContext">
      <ul>
        <li>
          <a href="#none;" @click.prevent.self @click="onLoginHGD"> [const11]홍길동</a>
        </li>
        <li>
          <a href="#none" @click.prevent.self @mouseover="showTestCompany" @mouseout="hideTestCompany">회사목록</a>
        </li>
      </ul>
    </div>
    <div id="companyList" class="testContext" ref="companyList" v-if="showCompanyList">
      <div>
        <input type="search" class="cmp" v-model="searchNm" @keyup="setSearchData" />
        <button class="cmp" @click="sortTestCompany">{{ companySort > 0 ? '내림' : '오름' }}</button>
      </div>
      <ul>
        <li
          v-for="company in companyList
            .filter(item => item.frm_nm.indexOf(searchNm) != -1 || item.pgm_cd.indexOf(searchNm) != -1)
            .sort((a, b) => (a.pgm_cd < b.pgm_cd ? -companySort : a.pgm_cd > b.pgm_cd ? companySort : 0))"
          :key="company.pgm_cd"
        >
          <a href="#none" @click.prevent.self class="cmp" :data-pgm-cd="company.pgm_cd" @mouseover="showTestWorker">{{
            `[${company.pgm_cd}] ${company.frm_nm}(${company.frm_gb})>`
          }}</a>
        </li>
      </ul>
    </div>
    <div id="workerList" class="testContext" ref="workerList" v-if="showWorkerList">
      <ul>
        <li v-for="worker in workerListFilter" :key="worker.emp_no">
          <a href="#none" @click.prevent.self class="wok" @click="testLogin(worker.pgm_cd, worker.emp_no)">{{
            `[${worker.emp_no}] ${worker.name}`
          }}</a>
        </li>
      </ul>
    </div>
    <!-- 테스트를 위한 컨텍스트메뉴 테스트후 삭제-->

    <!-- 로그인 -->
    <div id="h_container">
      <header id="h_header">
        <div class="inner_l">
          <h1 class="logo">TopERP</h1>
        </div>
        <div class="inner_r">
          <router-link href="#" @click.prevent.self class="join ml10" to="join">회원가입</router-link>
        </div>
      </header>
      <form name="form" action="http://127.0.0.1:8101/login">
        <main id="h_contents">
          <div class="login_box">
            <h2 class="login_title"><span class="blind">LOGIN</span></h2>
            <div class="input_st">
              <input
                type="text"
                name="pgmCd"
                v-model="loginPgmCd"
                style="width:380px"
                placeholder="회사 아이디를 입력하세요."
              />
            </div>
            <div class="input_st">
              <input
                type="text"
                name="username"
                v-model="loginId"
                style="width:380px"
                placeholder="개인 아이디를 입력하세요."
              />
            </div>
            <div class="input_st">
              <input
                type="password"
                name="password"
                v-model="loginPassword"
                @keyup.enter="onLogin"
                style="width:380px"
                placeholder="비밀번호를 입력하세요."
              />
            </div>
            <div class="input_st">
              <a href="#" @click.prevent.self class="btn_login" @click="onLogin">LOGIN</a>
            </div>
            <div class="find">
              <a href="#" @click.prevent.self target="_blank" @click="onSearchId" title="회사소개 페이지로 이동"
                >아이디 찾기</a
              >
              <a href="#" @click.prevent.self target="_blank" @click="onSearchPwd" title="이용약관 페이지로 이동"
                >비밀번호 찾기</a
              >
            </div>
          </div>
        </main>
      </form>
      <!-- h_contents end -->
    </div>
    <!-- h_container end -->

    <footer id="h_footer">
      <div class="inner">
        <div class="info_group">
          <div class="link">
            <a href="#" @click.prevent.self target="_blank" title="회사소개 페이지로 이동"
              ><span class="fc_wh">회사소개</span></a
            >
            <a href="#" @click.prevent.self target="_blank" title="이용약관 페이지로 이동">이용약관</a>
            <a href="#" @click.prevent.self target="_blank" title="개인정보취급방침 페이지로 이동">개인정보취급방침</a>
          </div>
          <div class="site">
            <select @change="goFamilySite">
              <option selected="selected">Family Site 바로가기</option>
              <option value="http://www.ebiz4u.co.kr/home.do">이비즈포유</option>
              <option value="https://www.ebizm4u.co.kr/">이비즈몰</option>
              <option value="http://buy.ebiz4u.co.kr/home.do">구매대리</option>
              <option value="http://www.eepss.co.kr/home.do">앱스</option>
            </select>
          </div>
        </div>
        <!-- info_group end -->
        <address>
          <div class="add mt20">
            회사명: 주식회사 이노가드&#40;INOGARD&#41; <span class="mr5">대표이사: 최용기</span>
            <span class="mr5">사업자번호: 113-86-14764</span> <span class="fc_wh mr5">고객센터: 02-869-2730</span>
            <span class="fc_wh">팩스: 02-6499-2729</span>
          </div>
          <div class="add mt20">08377 서울특별시 구로구 디지털로33길50 203호&#40;구로동, 벽산디지털밸리7차&#41;</div>
          <div class="add">Room 203, 50, Digital-ro 33-gil, Guro-gu, Seoul, Korea</div>
        </address>
        <div class="copy mt20">COPYRIGHT <span class="fz20">&copy;</span> INOGARD. ALL RIGHTS RESERVED.</div>
      </div>
      <!-- inner end -->
    </footer>
    <!-- h_footer end -->
  </div>
  <!-- h_wrap end -->
</template>

<script>
import {Base64} from 'js-base64';
import profile from '@/util/profileUtil';

export default {
  data() {
    return {
      loginPgmCd: '',
      loginId: '',
      loginPassword: '',

      // TODO 테스트 컨텍스트 상태 테스트후 삭제
      isDeveloper: false,
      showContext: false,
      showCompanyList: false,
      showWorkerList: false,
      companyList: [],
      workerList: [],
      workerListFilter: [],
      searchNm: localStorage.getItem('searchNm') || '',
      companySort: localStorage.getItem('companySort') || 1,
      apiServerUrl:
        location.protocol.indexOf('https') !== -1 ? process.env.VUE_APP_API_URLS : process.env.VUE_APP_API_URL,
    };
  },
  methods: {
    ///////////// TODO 테스트를 위한 편의성 기능 테스트종료후 아래 메소드 모두 제거
    onLoginHGD() {
      this.loginPgmCd = '210001';
      this.loginId = 'const11';
      this.loginPassword = 'const11';
      setTimeout(() => {
        this.onLogin();
      }, 100);
    },
    async selectTestData() {
      const response = await axios.post('/sample2/selectTestData');

      this.companyList = response.data.company;
      this.workerList = response.data.worker;
    },
    async showContextTest(e) {
      if (profile.isProd()) {
        return false;
      }

      if (!this.isDeveloper) {
        return false;
      }

      if (e.target.tagName == 'INPUT') {
        this.showContext = false;
        return;
      }
      this.showContext = true;
      await this.$nextTick();
      const testContext = this.$refs.testContext;

      testContext.style.top = `${e.clientY}px`;
      testContext.style.left = `${e.clientX}px`;
      e.preventDefault();
    },
    hideContextTest(e) {
      if (e.target.tagName == 'A' || e.target.className == 'cmp') return;
      e.preventDefault();
      this.showContext = false;
      this.showCompanyList = false;
      this.showWorkerList = false;
    },
    async showTestCompany(e) {
      e.preventDefault();
      this.showCompanyList = true;
      await this.$nextTick();
      const companyList = this.$refs.companyList;
      companyList.style.top = `${e.target.getBoundingClientRect().top}px`;
      companyList.style.left = `${e.target.getBoundingClientRect().right}px`;
    },
    hideTestCompany(e) {
      if (e.toElement.className != 'cmp') {
        this.showCompanyList = false;
        this.showWorkerList = false;
      }
    },
    async showTestWorker(e) {
      e.preventDefault();
      this.workerListFilter = this.workerList.filter(item => item.pgm_cd === e.target.dataset.pgmCd);
      this.showWorkerList = true;
      await this.$nextTick();
      const workerList = this.$refs.workerList;
      workerList.style.top = `${e.target.getBoundingClientRect().top}px`;
      workerList.style.left = `${e.target.getBoundingClientRect().right}px`;
    },
    testLogin(pgmCd, empNo) {
      this.loginPgmCd = pgmCd;
      this.loginId = empNo;
      this.loginPassword = empNo;
      setTimeout(() => {
        this.onLogin();
      }, 100);
    },
    sortTestCompany() {
      this.companySort = -this.companySort;
      this.setSearchData();
    },
    setSearchData() {
      localStorage.setItem('companySort', this.companySort);
      localStorage.setItem('searchNm', this.searchNm);
    },
    ///////////// TODO 테스트를 위한 편의성 기능 테스트종료후 위 메소드 모두 제거

    formLogin() {
      document.form.submit();
    },
    async onLogin() {
      try {
        const pgmCd = this.loginPgmCd;
        const empNo = this.loginId;
        const password = this.loginPassword;

        const response = await axios.post('/auth/accessToken', {pgmCd, empNo, password});
        const accessToken = response.data.accessToken;
        const refreshToken = response.data.refreshToken;
        const grantType = response.data.grantType;

        sessionStorage.setItem('accessToken', `${grantType} ${accessToken}`);
        sessionStorage.setItem('refreshToken', refreshToken);
        // sessionStorage.setItem('id', empNo);
        sessionStorage.setItem('userInfo', Base64.decode(accessToken.split('.')[1]));
        sessionStorage.setItem('loginTime', new Date().getTime());

        this.loginHistory();

        localStorage.setItem('cmpId', pgmCd);

        location.replace('/main');
      } catch (ex) {
        if (
          ex.response.status === 401 &&
          ['BadCredentialsException', 'SystemUseDateException'].indexOf(ex.response.data.code) !== -1
        ) {
          alert(ex.response.data.message);
        }
      }
    },
    async loginHistory() {
      //로그인 이력 남기기
      let LoginLogVo = {
        pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
        empNo: JSON.parse(sessionStorage.getItem('userInfo')).empNo,
      };
      await axios.post('/loginLog/updateLoginHistory', LoginLogVo, {
        headers: {
          Authorization: sessionStorage.getItem('accessToken'),
        },
      });
    },
    goFamilySite(e) {
      location.href = e.target.value;
    },
    onSearchId() {
      this.$router.push({path: '/searchIdPw', name: 'searchIdPw', query: {searchType: 'id'}});
    },
    onSearchPwd() {
      this.$router.push({path: '/searchIdPw', name: 'searchIdPw', query: {searchType: 'pw'}});
    },
  },
  async created() {
    const loginPgmCd = localStorage.getItem('cmpId');
    if (loginPgmCd) {
      this.loginPgmCd = loginPgmCd;
    }

    if (!profile.isProd()) {
      const response = await axios.get('https://api64.ipify.org?format=json');
      if (response.data.ip === '112.220.235.234') {
        this.isDeveloper = true;
        this.selectTestData();
      }
    }
  },
};
</script>

<style scoped>
.testContext {
  position: fixed;
  z-index: 9999;
}

.testContext ul {
  background-color: #efefef;
  max-height: 200px;
  overflow: auto;
}

.testContext ul li {
  height: 30px;
  line-height: 30px;
}

.testContext ul li a {
  width: 100%;
  height: 100%;
  display: block;
}

.testContext ul li:not(:last-child) ::after {
  content: '';
  display: block;
  border-bottom: dotted #aaa 2px;
}
</style>
