<template>
  <div ref="progress" class="background">
    <div class="main">
      <span>파일명 : {{ fileNm }}</span>
      <div class="graph">
        <div class="content">{{ `${progress}%` }}</div>
        <transition name="slide">
          <div class="bar" :style="{width: `${progress}%`}"></div>
        </transition>
      </div>

      <div class="graph">
        <div class="content">{{ `${calcTotalProgress}%` }}</div>
        <transition name="slide">
          <div class="bar" :style="{width: `${calcTotalProgress}%`}"></div>
        </transition>
      </div>
      <div>[{{ currentCnt }} / {{ totalCnt }}]</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  data() {
    return {
      currentCnt: 0,
      totalCnt: 0,
      progress: 0.0,
      totalProgress: 0.0,
      fileNm: '',
    };
  },
  computed: {
    calcTotalProgress() {
      let value = this.totalProgress / this.totalCnt;
      if (isNaN(value)) value = 0;
      return Math.round(value);
    },
  },
  created() {
    const that = this;
    const Plugin = {
      install(Vue) {
        Vue.prototype.$fileProgress = {
          setProgress: that.setProgress,
          show: that.showFileProgress,
          hide: that.hide,
        };
      },
    };

    Vue.use(Plugin);
    this.addEvent([
      {name: 'fileProgress', func: this.setProgress, isDefault: true},
      {name: 'showFileProgress', func: this.showFileProgress, isDefault: true},
      {name: 'hideFileProgress', func: this.hide, isDefault: true},
    ]);
  },
  methods: {
    init() {
      this.currentCnt = 0;
      this.totalCnt = 0;
      this.progress = 0.0;
      this.totalProgress = 0.0;
      this.fileNm = '';
    },
    setProgress(obj) {
      this.currentCnt = obj.currentCnt;
      this.totalCnt = obj.totalCnt;
      this.progress = obj.progress;
      this.totalProgress = obj.totalProgress;
      this.fileNm = obj.fileNm;
    },
    showFileProgress() {
      this.init();
      setTimeout(() => {
        this.show();
      }, 500);
    },
    show() {
      this.$refs.progress.style.zIndex = 999;
      this.$refs.progress.style.display = 'flex';
    },
    hide() {
      this.$refs.progress.style.zIndex = -999;
      this.$refs.progress.style.display = 'none';
    },
  },
};
</script>

<style scoped>
.background {
  width: 100vw;
  height: 100vh;
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -999;
}
.main {
  width: 600px;
  height: 300px;
  background: #fff;
  opacity: 1;
  box-shadow: 5px 5px 15px -5px;
  margin-bottom: 200px;
  position: relative;
  padding: 15px;
}

.graph {
  width: 100%;
  height: 14px;
  line-height: 14px;
  border-radius: 5px;
  background-color: #ececec;
  position: relative;
  margin: 5px 0px;
}

.graph .content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 11px;
}

.graph .bar {
  background-color: #74b9ff;
  border-radius: 5px;
  height: 14px;
  line-height: 14px;
  transition: 0.5s;
}
</style>
