<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="800px">
          <RfqListCustomer bidType="sub" process="complete" />
        </i-col>
        <i-spacer />
        <i-col min-width="870px">
          <RfqInfoCustomer />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from '../store/subRfqCustomer.js';
import RfqListCustomer from '@/view/bidding/rfqCustomer/common/RfqListCustomer.vue';
import RfqInfoCustomer from '../components/RfqInfoCustomer.vue';
export default {
  components: {RfqListCustomer, RfqInfoCustomer},
  beforeCreate() {
    $init(this, store);
  },
};
</script>

<style></style>
