<template>
  <pmis-content-box>
    <template #title>협력사목록</template>
    <template #title-left>
      <b v-if="rfqStatus.isRfqSpotDscrExpired" style="color: red;">※ 현설결과등록 시, 입찰이 시작됩니다</b>
    </template>
    <template #title-right>
      <pmis-file-list
        v-if="rfqStatus.isExistSpotDscr && rfqStatus.isRfqSpotDscrClose"
        id="spotDscrFile"
        flNoCol="spotDscrFlNo"
        :modal="true"
        modalBtnNm="현설결과증빙파일"
        :file-number="rfqInfo.spotDscrFlNo"
        :toolbarVisible="rfqStatus.isRfqSpotDscrExpired && !rfqStatus.isCancel"
      />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="rfqCusList"
      @loadSheet="sheet = $event"
      :events="{onSearchFinish: sheet_onSearchFinish, onClick: sheet_onClick}"
    />

    <iui-modal name="customerInfoModal" title="협력사정보" size-type="size2">
      <CustomerInfo :propsPgmCd="propsPgmCd" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/rfqCusList.js';
import {spotDscrChkYnRadioCol, spotDscrChkYnTextCol} from './sheetOptions/rfqCusList.js';
import {completeSpotDscr, cancelSpotDscr} from '@/view/bidding/rfq/mat/api/rfq.js';
import {
  completeSpotDscr as subCompleteSpotDscr,
  cancelSpotDscr as subCancelSpotDscr,
} from '@/view/bidding/rfq/sub/api/subRfq.js';

import CustomerInfo from '@/view/bidding/rfq/common/components/CustomerInfo.vue';

export default {
  components: {CustomerInfo},
  data() {
    return {
      sheetOpt: sheetOpt(this),
      propsPgmCd: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, ['rfqInfo', 'rfqCusList', 'rfqStatus', 'bidType']);
  },
  created() {
    this.addEvent([
      {name: 'RfqCusList_SaveSpotDscrChkYn', func: this.saveSpotDscrChkYn},
      {name: 'RfqCusList_CancelSpotDscr', func: this.cancelSpotDscr},
    ]);
  },
  methods: {
    sheet_onSearchFinish(e) {
      if (this.rfqStatus.isExistSpotDscr) {
        e.sheet.removeCol('spotDscrChkYn');
        e.sheet.showCol('spotDscrApplyYn'); // 현설참가신청여부

        const spotDscrChkYnCol =
          this.rfqStatus.isRfqSpotDscrExpired && !this.rfqStatus.isCancel
            ? spotDscrChkYnRadioCol
            : spotDscrChkYnTextCol;

        e.sheet.addCol({
          name: 'spotDscrChkYn',
          pos: e.sheet.getColIndex('spotDscrApplyYn'),
          param: spotDscrChkYnCol,
          visible: 1,
        }); // 현설참여여부
      } else {
        e.sheet.showCol('icprNm');
        e.sheet.showCol('icprPhone');
        e.sheet.showCol('email');

        e.sheet.hideCol('icprNmPhone');
        e.sheet.hideCol('spotDscrApplyYn');
        e.sheet.removeCol('spotDscrChkYn');
      }
    },
    sheet_onClick(e) {
      if (e.row.Kind == 'Data' && e.col == 'frmNm') {
        this.propsPgmCd = e.row.cusPgmCd;
        this.$modal.show('customerInfoModal');
      }
    },

    /**
     * 현설참여여부 저장
     */
    async saveSpotDscrChkYn() {
      const spotDscrCheckedList = this.sheet.getSaveJson(0).data;

      const title = '현설결과등록';
      const message = '현장설명 결과를 등록하시겠습니까?';
      if (!(await this.$confirm({title, message}))) {
        return;
      }

      const param = {
        rfqNo: this.rfqInfo.rfqNo,
        lastBidRnd: this.rfqInfo.lastBidRnd,
        spotDscrFlNo: this.rfqInfo.spotDscrFlNo,
        spotDscrCheckedList: spotDscrCheckedList,
      };

      const completeSpotDscrApi = {mat: completeSpotDscr, sub: subCompleteSpotDscr};
      completeSpotDscrApi[this.bidType](param)
        .then(response => {
          if (response.status == 200) {
            this.callEvent({name: 'RfqList_Search', param: response.data.rfqNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    /**
     * 현설결과취소 (임시)
     */
    async cancelSpotDscr() {
      const title = '현설결과취소';
      const message = '현설설명 결과를 취소하시겠습니까?';

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      const cancelSpotDscrApi = {mat: cancelSpotDscr, sub: subCancelSpotDscr};
      cancelSpotDscrApi[this.bidType](this.rfqInfo.rfqNo)
        .then(response => {
          if (response.status == 200) {
            this.callEvent({name: 'RfqList_Search', param: response.data.rfqNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
    getSaveJson(callback) {
      if (typeof callback === 'function') {
        callback(this.sheet.getSaveJson(0).data);
      }
    },
  },
};
</script>

<style></style>
