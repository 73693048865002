<template>
  <iui-container-new type="css-flex">
    <i-row height="200px">
      <i-col>
        <ib-sheet
          :uid="_uid"
          :options="Options1"
          :loadSearchData="loadSearchData1"
          @loadSheet="loadSheet1"
          :events="{
            onSearchFinish: onSearchFinish1,
            onClick: onClick1,
          }"
        />
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <ib-sheet
          :uid="_uid"
          :options="Options2"
          :loadSearchData="loadSearchData2"
          @loadSheet="loadSheet2"
          :events="{
            onSearchFinish: onSearchFinish2,
            onClick: onClick2,
            onDblClick,
          }"
        />
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import options1 from './sheetOptions/SubPoListSheet1.js';
import options2 from './sheetOptions/SubPoListSheet2.js';
import {selectSubPoAsUnitOpertList} from '../api/unitOpert.js';
import {selectSubPoItemCompleteList} from '@/view/bidding/po/sub/api/subPo.js';
export default {
  props: {
    wbsCd: {
      type: Number,
    },
    wbsId: {
      type: String,
    },
    costType: {
      type: String,
    },
    jkdNo: {
      type: String,
    },
    poNoSubcList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sheet1: undefined,
      Options1: options1(this),
      loadSearchData1: [],
      sheet2: undefined,
      Options2: options2(this),
      loadSearchData2: [],
      wbsNm: '',
      internalWbsCd: '',
      internalWbsId: '',
    };
  },
  methods: {
    loadSheet1(sheet) {
      this.sheet1 = sheet;
    },
    loadSheet2(sheet) {
      this.sheet2 = sheet;
    },
    onSearchFinish1(e) {
      if (!e.sheet.getFirstRow()) {
        return;
      }
      this.onSearchDetail(e.sheet.getFirstRow());
    },
    onClick1(e) {
      if (e.row.Kind == 'Data') {
        this.onSearchDetail(e.row);
      }
    },
    onSearchFinish2(e) {
      if (!e.sheet.getFirstRow()) {
        return;
      }
      this.$emit('onRow', {
        ...e.sheet.getFirstRow(),
        wbsCd: this.internalWbsCd,
        wbsId: this.internalWbsId,
        wbsNm: this.wbsNm,
      });
    },
    onClick2(e) {
      if (e.row.Kind == 'Data') {
        this.$emit('onRow', {...e.row, wbsCd: this.internalWbsCd, wbsId: this.internalWbsId, wbsNm: this.wbsNm});
      }
    },
    onDblClick() {
      this.$modalConfirm();
    },
    async onSearch() {
      const param = {
        wbsCd: this.wbsCd,
        wbsId: this.wbsId,
        poNoSubcList: this.poNoSubcList,
        itemCode2: this.jkdNo,
        costType: this.costType,
      };
      const response = await selectSubPoAsUnitOpertList(param);
      this.loadSearchData1 = response.data;
    },
    async onSearchDetail(row) {
      this.internalWbsCd = row?.wbsCd ?? '';
      this.internalWbsId = row?.wbsId ?? '';
      this.wbsNm = row.wbsNm;
      const param = {
        poNo: row.poNo,
        costType: this.costType,
        itemCode2: this.jkdNo,
      };
      const response = await selectSubPoItemCompleteList(param);
      this.loadSearchData2 = response.data;
    },
  },
};
</script>
