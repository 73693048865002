<template>
  <ib-sheet
    :uid="_uid"
    :options="Options"
    :loadSearchData="loadSearchData"
    :events="{OnClick: this.onClickShowContButton}"
    @loadSheet="loadSheet"
  />
</template>

<script>
import options from '@/view/sysManage/userManage/laborContract/components/sheetOption/ContractHistorySheet.js';
import {selectLaborHistoryByProject} from '@/view/sysManage/userManage/laborContract/api/laborContract.js';
export default {
  data() {
    return {
      loadSearchData: [],
      Options: options(this),
    };
  },
  beforeCreate() {
    $mapGetters(this, ['rowInfo']);
  },
  created() {
    this.addEvent([{name: 'ContractHistory_Search', func: this.onSearch}]);
    this.onSearch();
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.utIL;
    },
    async onSearch() {
      if (this.rowInfo.empNo) {
        const response = await selectLaborHistoryByProject({empNo: this.rowInfo.empNo});
        this.loadSearchData = response.data;
      } else {
        this.loadSearchData = [];
      }
    },
    onClickShowContButton(e) {
      if (e.row.Kind === 'Data' && e.col === 'contract') {
        this.callEvent({name: 'index_onShowLaborContract'});
      }
    },
  },
};
</script>

<style></style>
