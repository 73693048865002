const state = {
  searchInfo: {
    prjCd: '', // 현장코드
    prjNm: '', // 현장명
  },
  detailInfo: {
    wbsCd: '', //단위작업분류
    wbsId: '', //단위작업 코드
    wbsNm: '', //단위작업 명
    unitPathNm: '', //단위작업명 경로
    phoDt: '', //촬영일자
    seq: '', //순번
    rmk: '', //사진설명
    flNo: 0, //파일번호
    rgprId: '', //등록자아이디
    rgsDttm: '', //등록일시
    cud: '',
  },
  photos: {
    index: 0,
    imgUrl: '', //원본 이미지 Url
    mImgs: [], //원본 이미지들
    tImgs: [], //섬네일 이미지들
  },
};

const getters = {
  searchInfo: state => state.searchInfo,
  detailInfo: state => state.detailInfo,
  photos: state => state.photos,
};

const actions = {};

const mutations = {
  setSearchInfo(state, payload) {
    for (let key in state.searchInfo) {
      if (payload && typeof payload[key] != 'undefined') {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  initSearchInfo(state) {
    for (let key in state.searchInfo) {
      state.searchInfo[key] = '';
    }
  },
  initDetailInfo(state) {
    for (let key in state.detailInfo) {
      if (key === 'flNo') state.detailInfo[key] = 0;
      else state.detailInfo[key] = '';
    }
  },
  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && typeof payload[key] != 'undefined') {
        if (key == 'phoDt') {
          payload[key] = String(payload[key]);
        }
        state.detailInfo[key] = payload[key];
      }
    }
  },
  setPhotos(state, payload) {
    for (let key in state.photos) {
      if (payload && typeof payload[key] != 'undefined') {
        state.photos[key] = payload[key];
      }
    }
  },
  initPhotos(state) {
    state.photos.index = 0;
    state.photos.imgUrl = '';
    state.photos.mImgs = [];
    state.photos.tImgs = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
