<template>
  <pmis-content-box>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :append="true"
      :events="sheetEvents"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import {selectEqutCodeList, deleteEqutCode} from '../api/EquipCodeRegist.js';
import options from './sheetOption/DetailListSheet.js';
export default {
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    base: {
      type: Boolean,
      default: false,
    },
    uid: {
      type: Number,
    },
    callback: {
      type: Function,
      default: undefined,
    },
    paramObj: {
      type: Object,
      default: () => {},
    },
    focusKey: {
      type: String,
    },
  },
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this)),
      loadSearchData: [],
      isMultiSelect: false,
      sheetUtil: null,
      searchNm: '',
      equipClInfo: {},
      equipCodeInfo: {
        flNo: 0,
        itemName: '',
        level: 4,
        leCode: '',
        leName: '',
        equipCode: '',
        meCode: '',
        meName: '',
        size: '',
        unit: '',
        useDs: '',
      },
      sheetEvents: {
        onClick: this.isPopup ? undefined : this.onClick,
        onFocus: this.isPopup ? undefined : this.onFocus,
        onSearchFinish: this.isPopup ? undefined : this.onSearchFinish,
      },
    };
  },
  created() {
    let param = [{name: 'EquipCode_DetailList_changeFlNo', func: this.changeFlNo}];
    this.addEvent(param);

    if (this.paramObj) {
      this.isMultiSelect = this.paramObj.isMultiSelect;
    }

    if (this.isPopup) this.onSearch();
  },

  methods: {
    async onSearch() {
      const param = {
        base: this.base,
        code: this.equipClInfo.level === 1 ? '' : this.equipClInfo.code ?? '',
        searchNm: this.searchNm,
        ...this.sheetUtil.pagingData(),
      };
      let response = await selectEqutCodeList(param);
      if (response.status == 200) {
        this.loadSearchData = response.data;
      }
    },
    async onDelete() {
      let checkedRows = this.sheet.getRowsByChecked('chk');

      if (0 == checkedRows.length) {
        this.$alert({title: '삭제', message: '선택된 항목이 없습니다.'});
        return;
      }
      if (!(await this.$confirm({title: '삭제', message: '선택된 장비를 삭제하시겠습니까?'}))) {
        return;
      }
      try {
        let deletedList = checkedRows.map(row => ({equipCode: row.equipCode, flNo: row.flNo}));
        const response = await deleteEqutCode({base: this.base, deletedList: deletedList});
        if (response.data) {
          this.removeAll();
          this.onSearch();
          return deletedList;
        }
      } catch (error) {
        this.$alert({title: '삭제', message: error.description ?? error.message});
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
      this.sheetUtil.scrollPaging(this.onSearch);
    },
    async onFocus(e) {
      await this.callEvent({name: 'clearImage'});
      if (e.row.Kind == 'Data') {
        this.setEquipCodeInfo(e.row);
        if (typeof this.callback == 'function') this.callback();
      }
    },
    onSearchFinish(e) {
      if (this.focusKey) {
        const row = e.sheet.getDataRows().find(row => row.equipCode === this.focusKey);
        e.sheet.focus(row);
        this.$emit('update:focusKey', undefined);
      }
    },
    setEquipCodeInfo(row) {
      for (let key in this.equipCodeInfo) {
        this.equipCodeInfo[key] = row[key];
      }
      this.$emit('equipCodeInfo', this.equipCodeInfo);
    },

    onClick(e) {
      this.$emit('onEquipClSearch', e.row.meCode);
    },
    onReloadSheet() {
      if (this.cud == 1) {
        let addRow = this.sheet.addRow({
          init: {
            leCode: this.equipCodeInfo.leCode,
            leName: this.equipCodeInfo.leName,
            meCode: this.equipCodeInfo.meCode,
            meName: this.equipCodeInfo.meName,
            equipCode: this.equipCodeInfo.equipCode,
            itemName: this.equipCodeInfo.itemName,
            size: this.equipCodeInfo.size,
            unit: this.equipCodeInfo.unit,
            flNo: this.equipCodeInfo.flNo,
            useDs: this.equipCodeInfo.useDs,
          },
        });
        this.sheet.acceptChangedData(addRow);
      } else if (this.cud == 2) {
        let focusedRow = this.sheet.getFocusedRow();
        this.sheet.setValue(focusedRow, 'itemName', this.equipCodeInfo.itemName, 1);
        this.sheet.setValue(focusedRow, 'size', this.equipCodeInfo.size, 1);
        this.sheet.setValue(focusedRow, 'unit', this.equipCodeInfo.unit, 1);
        this.sheet.setValue(focusedRow, 'useDs', this.equipCodeInfo.useDs, 1);
        this.sheet.setValue(focusedRow, 'flNo', this.equipCodeInfo.flNo, 1);
        this.sheet.acceptChangedData(focusedRow);
      }
    },
    onSetData(param) {
      if (this.isMultiSelect) {
        let checkedRows = this.sheet.getRowsByChecked('chk');
        if (0 == checkedRows.length) {
          this.$alert({title: '선택', message: '선택된 항목이 없습니다.'});
          return false;
        }
        param(checkedRows);
      } else {
        param(this.sheet.getFocusedRow());
      }
    },
    removeAll() {
      this.sheet.removeAll();
      Object.assign(this.$data.equipCodeInfo, this.$options.data.call(this).equipCodeInfo);
    },
    changeFlNo(flNo) {
      let focusedRow = this.sheet.getFocusedRow();
      this.sheet.setValue(focusedRow, 'flNo', flNo, 1);
      this.sheet.acceptChangedData(focusedRow);
    },
  },
};
</script>

<style scoped></style>
