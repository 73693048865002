export default {
  data() {
    return {
      tabIndex: 0,
      comps: [],
    };
  },
  computed: {
    titleNm() {
      return this.comps[this.tabIndex]?.tabNm || '';
    },
  },
  created() {
    this.$store.dispatch('portlet/addFuncInit', this.init);
  },
  methods: {
    init() {
      this.tabIndex = 0;
    },
  },
};
