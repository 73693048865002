<template>
  <iui-container-new type="css-flex" flex-direction="row">
    <i-col>
      <i-row>
        <pmis-content-box>
          <template v-slot:title>공종분류</template>
          <ContEmCodeList :callback="onSearchDetail" :search.sync="search"></ContEmCodeList>
        </pmis-content-box>
      </i-row>
      <i-spacer />
      <i-row height="160px">
        <iui-container-new type="css-flex">
          <i-row>
            <i-col>
              <pmis-content-box>
                <template v-slot:title>단가조정옵션</template>

                <iui-container-new type="table" theme="bullet">
                  <i-row>
                    <i-col-header>재료비</i-col-header>
                    <i-col>
                      <iui-text
                        type="rate"
                        :value="matPriceRt"
                        @update:value="
                          value => {
                            matPriceRt = value;
                          }
                        "
                      ></iui-text>
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>노무비</i-col-header>
                    <i-col>
                      <iui-text
                        type="rate"
                        :value="labPriceRt"
                        @update:value="
                          value => {
                            labPriceRt = value;
                          }
                        "
                      ></iui-text>
                    </i-col>
                  </i-row>
                  <i-row v-if="!this.isSimpleExec">
                    <i-col-header>장비비</i-col-header>
                    <i-col>
                      <iui-text
                        type="rate"
                        :value="equipPriceRt"
                        @update:value="
                          value => {
                            equipPriceRt = value;
                          }
                        "
                      ></iui-text>
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>경비</i-col-header>
                    <i-col>
                      <iui-text
                        type="rate"
                        :value="oprPriceRt"
                        @update:value="
                          value => {
                            oprPriceRt = value;
                          }
                        "
                      ></iui-text>
                    </i-col>
                  </i-row>
                </iui-container-new>
              </pmis-content-box>
            </i-col>
            <i-col width="280px">
              <pmis-content-box>
                <template v-slot:title>절삭옵션</template>

                <iui-container-new type="table" theme="bullet" header-width="100%">
                  <i-row>
                    <i-col></i-col>
                    <i-col-header>
                      <iui-text
                        type="number"
                        label="100원 이상 ~ 1,000원 미만"
                        suffix="자리 절삭"
                        label-width="160px"
                        width="260px"
                        :value="thousandDn"
                        @update:value="
                          value => {
                            thousandDn = value;
                          }
                        "
                        max-length="1"
                      ></iui-text>
                    </i-col-header>
                  </i-row>
                  <i-row>
                    <i-col></i-col>
                    <i-col-header>
                      <iui-text
                        type="number"
                        label="1,000원 이상 ~ 10,000원 미만"
                        suffix="자리 절삭"
                        label-width="160px"
                        width="260px"
                        :value="tenThousandDn"
                        @update:value="
                          value => {
                            tenThousandDn = value;
                          }
                        "
                        max-length="1"
                      ></iui-text>
                    </i-col-header>
                  </i-row>
                  <i-row>
                    <i-col></i-col>
                    <i-col-header>
                      <iui-text
                        type="number"
                        label="10,000원 이상"
                        suffix="자리 절삭"
                        label-width="160px"
                        width="260px"
                        :value.sync="tenThousandUp"
                        max-length="1"
                      ></iui-text>
                    </i-col-header>
                  </i-row>
                </iui-container-new>
              </pmis-content-box>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-row>
    </i-col>
  </iui-container-new>
</template>

<script>
import ContEmCodeList from '@/view/estmtManage/prjWorkTypeRegist/components/ContEmCodeList.vue';
export default {
  components: {
    ContEmCodeList,
  },
  beforeCreate() {
    $mapGetters(this, {searchInfo: 'searchInfo'});
  },

  created() {
    this.addEvent({name: 'UntpcMdacPop_popConfirm', func: this.onPopConfirm});
  },
  data() {
    return {
      matPriceRt: 100,
      labPriceRt: 100,
      equipPriceRt: 100,
      oprPriceRt: 100,
      thousandDn: 0,
      tenThousandDn: 0,
      tenThousandUp: 0,
      focusRow: {},
      search: false,
    };
  },
  async mounted() {
    await this.$nextTick();
    this.search = true;
  },
  methods: {
    onSearchDetail(row) {
      this.focusRow = row;
    },
    onPopConfirm(param) {
      let obj = {
        ...this.focusRow,
        matPriceRt: this.matPriceRt,
        labPriceRt: this.labPriceRt,
        equipPriceRt: this.equipPriceRt,
        oprPriceRt: this.oprPriceRt,
        thousandDn: this.posNumber(this.thousandDn),
        tenThousandDn: this.posNumber(this.tenThousandDn),
        tenThousandUp: this.posNumber(this.tenThousandUp),
      };

      param(obj);
    },
    posNumber(pos) {
      let value = '1';
      if (pos == 0) {
        return 0;
      }
      for (let i = 0; i < pos; i++) {
        value += '0';
      }
      return Number(value);
    },
  },
};
</script>

<style></style>
