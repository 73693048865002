const state = () => ({
  detailInfo: {
    frmGb: '',
    uppMnuCd: '',
    uppMnuNm: '',
    mnuCd: '',
    mnuNm: '',
    mnuNmEng: '',
    mnuSqn: '',
    svYn: '',
    vwYn: '',
    delYn: '',
    prnYn: '',
    mnuPth: '',
    para: '',
    lvl: '',
    useDs: '',
    beforeMnuCd: '',
    beforeUppMnuCd: '',
  },
});
const getters = {
  detailInfo: state => state.detailInfo,
};
const mutations = {
  initDetailInfo(state) {
    for (let key in state.detailInfo) {
      if (['svYn', 'vwYn', 'delYn', 'prnYn'].indexOf(key) != -1) {
        state.detailInfo[key] = $getConstants('USE_YN_Y').code;
      } else {
        state.detailInfo[key] = '';
      }
    }
  },
  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = payload[key];
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
