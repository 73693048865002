<template>
  <pmis-content-box :loading="loading">
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onSearchFinish}"
    />
  </pmis-content-box>
</template>

<script>
import {selectContContexecPlanD} from '../api/dtlsCntc.js';
import options from './sheetOption/ContExecDtlsSheet.js';
export default {
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      loading: false,
    };
  },
  created() {
    this.addEvent([
      {name: 'selectContContexecPlanD', func: this.onSearch},
      {name: 'removeSheet', func: this.removeSheet},
    ]);
  },

  methods: {
    removeSheet() {
      this.sheet.removeAll();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onSearchFinish() {
      this.loading = false;
      this.sheetMerge();
    },
    async onSearch(param) {
      this.loading = true;
      try {
        const response = await selectContContexecPlanD(param);
        this.loadSearchData = response.data;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    sheetMerge() {
      let Rows = this.sheet.getDataRows();
      let key = '';
      let sRow;
      let eRow;
      let mergeInfo = {};
      Rows.forEach(r => {
        if (key != r.midCodeDg + r.itemSeqDg) {
          key = r.midCodeDg + r.itemSeqDg;
          sRow = r.id;
          mergeInfo[sRow] = sRow;
        } else {
          eRow = r.id;
          mergeInfo[sRow] = eRow;
        }
      });

      for (let m in mergeInfo) {
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'mgNameDg', this.sheet.getRowById(mergeInfo[m]), 'mgNameDg');
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'itemSeqDg',
          this.sheet.getRowById(mergeInfo[m]),
          'itemSeqDg'
        );
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'itemNameDg',
          this.sheet.getRowById(mergeInfo[m]),
          'itemNameDg'
        );
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'ssizeDg', this.sheet.getRowById(mergeInfo[m]), 'ssizeDg');
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'unitDg', this.sheet.getRowById(mergeInfo[m]), 'unitDg');
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'amtDg', this.sheet.getRowById(mergeInfo[m]), 'amtDg');
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'qtyDg', this.sheet.getRowById(mergeInfo[m]), 'qtyDg');
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'matPriceDg',
          this.sheet.getRowById(mergeInfo[m]),
          'matPriceDg'
        );
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'matAmtDg', this.sheet.getRowById(mergeInfo[m]), 'matAmtDg');
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'labPriceDg',
          this.sheet.getRowById(mergeInfo[m]),
          'labPriceDg'
        );
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'labAmtDg', this.sheet.getRowById(mergeInfo[m]), 'labAmtDg');
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'oprPriceDg',
          this.sheet.getRowById(mergeInfo[m]),
          'oprPriceDg'
        );
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'oprAmtDg', this.sheet.getRowById(mergeInfo[m]), 'oprAmtDg');
      }
    },
  },
};
</script>

<style></style>
