<template>
  <pmis-content-box>
    <iui-container-new type="css-flex">
      <i-row height="120px">
        <i-col>
          <pmis-content-box>
            <template #title>공고정보</template>
            <iui-container-new type="table" theme="bullet">
              <i-row>
                <i-col-header>공고회사</i-col-header>
                <i-col>
                  <iui-text :value="rfqInfo.orgCd" width="100px" :enable="false" />
                  <iui-text :value="rfqInfo.orgNm" width="500px" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>현장명</i-col-header>
                <i-col>
                  <iui-text :value="rfqInfo.spotDeptnm" width="605px" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>공고번호</i-col-header>
                <i-col>
                  <iui-text :value="rfqInfo.rfqNo2" width="100px" :enable="false" />
                  <iui-text :value="rfqInfo.ttl" width="500px" :enable="false" />
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-spacer />
      <i-row height="150px">
        <i-col>
          <!-- 암호화 되는 파일이므로 이전 파일 삭제 & 새로운 파일번호 생성. 따라서 파일번호는 0  -->
          <pmis-file-list
            id="estiFile"
            title="견적서 첨부파일"
            :companyCode="rfqInfo.orgCd"
            :fileNumber="0"
            flNoCol="signEstiFlNo"
          />
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-file-list
            id="etcFile"
            title="기타 첨부파일"
            :companyCode="rfqInfo.orgCd"
            :fileNumber="quotInfo.etcFlNo"
            flNoCol="etcFlNo"
          />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col>
          <pmis-content-box>
            <template #title>견적정보</template>
            <template #title-left>
              <label>(</label>
              <iui-text type="amount" prefix="공급가액" :value="quotAmt" width="200px" height="20px" :enable="false" />
              <iui-text
                type="amount"
                prefix="부가세액"
                :value="quotVatAmt"
                width="200px"
                height="20px"
                :enable="false"
              />
              <iui-text
                type="amount"
                prefix="합계금액"
                :value="quotTotAmt"
                width="200px"
                height="20px"
                :enable="false"
              />
              <label>)</label>
            </template>
            <template #title-right>
              <iui-button @click="onPopAddExcel" value="엑셀등록" />
            </template>
            <ib-sheet
              :uid="_uid"
              :options="sheetOpt"
              :loadSearchData="rfqItemList"
              @loadSheet="sheet = $event"
              :events="{
                onClick: sheet_onClick,
                onAfterChange: sheet_onAfterChange,
              }"
            />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal name="matCodeInfoModal" title="자재코드" size-type="size4">
      <MatCodeInfoCustomer :propsPgmCd="rfqInfo.orgCd" />
    </iui-modal>

    <iui-modal name="excelUploadPop" title="엑셀등록" :btns="excelBtns" sizeType="size1">
      <ExcelUpload uri="/quot/excelUpload" title="견적서" :rows="excelRows" :data="data" @error="onExcelUploadError" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/quotSubmit.js';
import quotSubmitMixin from '@/view/bidding/rfqCustomer/common/quotSubmitMixin.js';

import MatCodeInfoCustomer from '@/view/sysManage/stdCodeManage/matCodeRegist/popup/MatCodeInfoCustomer.vue';
import ExcelUpload from '@/components/popup/ExcelUpload.vue';

export default {
  components: {ExcelUpload, MatCodeInfoCustomer},
  mixins: [quotSubmitMixin],
  data() {
    return {
      bidType: 'mat',
      sheetOpt: sheetOpt(this),
      excelBtns: [{name: '확인', callback: this.onPopConfirmExcel}],
      excelRows: [
        {
          value: [
            {value: '공고품목순서', style: {type: 'TEXT'}, locked: true},
            {value: '명칭', style: {type: 'TEXT'}, locked: true},
            {value: '규격', style: {type: 'TEXT'}, locked: true},
            {value: '수량', style: {type: 'TEXT'}, locked: true},
            {value: '단가'},
          ],
        },
      ],
      data: [],
    };
  },
  methods: {
    makeQuotData() {
      // 견적정보 생성
      const quotInfo = JSON.stringify({
        amt: this.quotAmt,
        vatAmt: this.quotVatAmt,
        taxTypecd: this.rfqInfo.taxTypecd,
        quotRmrk: encodeURI(this.quotRmrk),
      });
      let quotItems = '';
      Array.from(this.sheet.getSaveJson(0).data).forEach((row, index) => {
        let quotItemObj = {
          rfqSeq: row.rfqSeq,
          itemCd: row.itemCd,
          itemNm: encodeURI(row.itemNm),
          sizeNm: encodeURI(row.sizeNm),
          unit: encodeURI(row.unit),
          qty: row.qty,
          unitPrc: row.planUnitPrc || 0,
          amt: row.amt || 0,
          vatAmt: row.vatAmt || 0,
        };
        quotItems = index == 0 ? JSON.stringify(quotItemObj) : `${quotItems}&&&&&${JSON.stringify(quotItemObj)}`;
      });
      return JSON.stringify({quotInfo, quotItems});
    },
    sheet_onClick(e) {
      if (e.row.Kind == 'Data' && e.col == 'itemNm') {
        this.$store.commit('setModalParam', {matCode: e.row.itemCd});
        this.$modal.show('matCodeInfoModal');
      }
    },
    onPopAddExcel() {
      const array = [];

      this.rfqItemList.forEach(x => {
        array.push({
          value: [
            {
              value: x.rfqSeq,
            },
            {
              value: x.itemNm,
            },
            {
              value: x.sizeNm,
            },
            {
              value: x.qty,
            },
          ],
        });
      });

      this.data = array;

      this.$modal.show('excelUploadPop');
    },
    onPopConfirmExcel() {
      this.callEvent({
        name: 'excelUpload',
        param: res => {
          this.sheet.getSaveJson(0).data.forEach(row => {
            const data = res.filter(x => x.rfqSeq === row.rfqSeq);
            if (data.length > 0) {
              this.sheet.setValue(this.sheet.getRowById(row.id), 'planUnitPrc', data[0].unitPrc);
            }
          });
          this.$modal.hide('excelUploadPop');
        },
      });
    },
    onExcelUploadError(error) {
      if (error === 'server') {
        this.$alert({title: '엑셀업로드', message: '견적서 엑셀파일을 확인하십시오.'});
      }
    },
  },
};
</script>

<style></style>
