export default that => {
  return {
    Def: {
      Row: {
        VAlign: 'TOP',
      },
    },
    Cfg: {
      HeaderMerge: 3,
    },
    Cols: [
      {
        Header: ['계획년월', '계획년월'],
        Name: 'reqYm',
        Type: 'Date',
        Format:'yyyy-MM',
        Width: 200,
        CanEdit: 0,
      },
      {
        Header: ['도급기성계획', {Value: '금액', Color: '#dff9fb'}],
        Name: 'planContAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
        MinWidth: 130,
        CanEdit: 0,
      },
      {
        Header: ['도급기성계획', {Value: '비율', Color: '#dff9fb'}],
        Name: 'planContRate',
        Extend: that.$ibsheetHelper.preset.rate,
        RelWidth: 1,
        MinWidth: 130,
        CanEdit: 0,
      },
      {
        Header: ['실행기성계획', {Value: '금액', Color: '#dff9fb'}],
        Name: 'planExecAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
        MinWidth: 130,
        CanEdit: 0,
      },
      {
        Header: ['실행기성계획', {Value: '비율', Color: '#dff9fb'}],
        Name: 'planExecRate',
        Extend: that.$ibsheetHelper.preset.rate,
        RelWidth: 1,
        MinWidth: 130,
        CanEdit: 0,
      },
      {
        Header: ['매출이익계획', '매출이익계획'],
        Name: 'planProfit',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
        MinWidth: 130,
        CanEdit: 0,
      },
      {Name: 'headCode', Visible: 0},
      {Name: 'midCode', Visible: 0},
    ],
  };
};
