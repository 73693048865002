<template>
  <pmis-tab-box no-border-box no-padding-box>
    <template v-slot:title>단가정보</template>
    <template v-slot:title-right>
      <iui-button v-if="costType == $getConstants('COST_TYPE_L').code && !base" @click="onStandardUntpcPopup"
        >표준단가복제</iui-button
      >
      <iui-button @click="onUntpcCopy">단가복사</iui-button>
      <iui-button @click="onUntpcName">단가명칭</iui-button>
      <iui-button @click="onSave">단가저장</iui-button>
    </template>
    <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" @loadSheet="loadSheet" />

    <iui-modal :name="standardUntpc" title="표준단가복제" sizeType="size1" :btns="standardUntpcBtns">
      <StandarduntpcPopup ref="standardUntpc" />
    </iui-modal>
    <iui-modal :name="untpcCopy" title="단가복사" sizeType="size1" :btns="untpcCopyBtns">
      <UntpcCopy :costType="costType" :base="base" ref="untpcCopy" />
    </iui-modal>
    <iui-modal :name="untpcName" title="단가명칭" sizeType="size1" :btns="untpcNameBtns">
      <UntpcName :costType="costType" :base="base" ref="untpcName" />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import UntpcName from './UntpcName.vue';
import UntpcCopy from './UntpcCopy.vue';
import StandarduntpcPopup from '@/view/sysManage/stdCodeManage/lbrcCodeRegist/components/StandardUntpcPopup.vue';
import {
  deleteUcstPriAsChildren,
  saveStandardUntpc,
  saveUcstPri,
  selectUcstPriMList,
  updateUcstPriceCopy,
} from '../api/untpcRegist.js';
import options from './sheetOption/UntpcListSheet.js';

export default {
  components: {
    UntpcName,
    UntpcCopy,
    StandarduntpcPopup,
  },
  props: {
    costType: {
      type: String,
    },
    base: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      untpcNameBtns: [{name: '확인', callback: this.onUntpcNameConfirm}],
      untpcCopyBtns: [{name: '확인', callback: this.onUntpcCopyConfirm}],
      standardUntpcBtns: [{name: '확인', callback: this.onStandardUntpcConfirm}],
      untpcName: 'untpcName' + this._uid,
      untpcCopy: 'untpcCopy' + this._uid,
      standardUntpc: 'standardUntpc' + this._uid,
      code: '',
      itemCode: '',
      copyList: [],
    };
  },

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onUntpcName() {
      this.$modal.show(this.untpcName);
    },
    onUntpcCopy() {
      this.$modal.show(this.untpcCopy);
    },
    async onStandardUntpcPopup() {
      this.$modal.show(this.standardUntpc);
    },
    async onStandardUntpcConfirm() {
      this.copyList = [];
      await this.callEvent({
        name: 'getStandardUntpc',
        param: data => {
          data.forEach(item => {
            this.copyList.push({
              costType: $getConstants('COST_TYPE_L').code,
              ucstCode: item.ucstCode,
              ucstName: item.ucstName,
            });
          });
        },
      });
      if (
        await this.$confirm({
          title: '표준단가복제',
          message: '선택한 표준단가로 기존 노무코드의 단가정보가 갱신됩니다.\n복제하시겠습니까?',
        })
      ) {
        const response = await saveStandardUntpc({saveList: this.copyList});
        this.$modal.hide(this.standardUntpc);
        this.onSearch();
      }
    },
    async onUntpcNameConfirm() {
      const response = await this.$refs.untpcName.onSave();
      if (response.data) {
        this.onSearch();
      }
      this.$modal.hide(this.untpcName);
    },
    async onUntpcCopyConfirm() {
      const copyData = this.$refs.untpcCopy.getUntpcCopyData();
      if (!copyData.copyUcstCode) {
        this.$alert({title: '단가복사', message: '복사단가를 선택해주세요'});
        return;
      }
      if (!copyData.copyTargetUcstCode) {
        this.$alert({title: '단가복사', message: '복사대상단가를 선택해주세요'});
        return;
      }
      if (!copyData.rate) {
        this.$alert({title: '단가복사', message: '할증을 입력해주세요.'});
        return;
      }
      if (copyData.rate < 0) {
        this.$alert({title: '단가복사', message: '할증을 0 이상으로 입력해주세요.'});
        return;
      }

      const param = {
        costType: this.costType,
        copyUcstCode: copyData.copyUcstCode,
        copyTargetUcstCode: copyData.copyTargetUcstCode,
        rate: copyData.rate,
        applyTarget: copyData.applyTarget,
        code: this.code,
        base: this.base,
      };
      const response = await updateUcstPriceCopy(param);
      if (response.data) {
        this.onSearch();
      }
      this.$modal.hide(this.untpcCopy);
    },
    async onSearch() {
      const param = {
        costType: this.costType,
        itemCode: this.itemCode,
        base: this.base,
      };
      const response = await selectUcstPriMList(param);
      this.loadSearchData = response.data;
    },
    async onSave() {
      if (this.cud == 1) {
        return;
      }
      if (!this.itemCode) {
        this.$alert({title: '단가저장', message: '내역을 선택해주세요'});
        return;
      }

      const saveList = this.sheet.getSaveJson(0).data;

      if (saveList.length === 0) {
        this.$alert({title: '단가저장', message: '저장할 내역이 없습니다.'});
        return;
      }

      const ucstPriList = [];
      saveList.forEach(item => {
        ucstPriList.push({
          costType: this.costType,
          itemCode: this.itemCode,
          ucstCode: item.ucstCode,
          price: item.price,
        });
      });
      const response = await saveUcstPri({ucstPriList: ucstPriList, base: this.base});
      if (response.data) {
        this.onSearch();
      }
    },
    removeAll() {
      this.sheet.removeAll();
    },
    async deleteUcstPriAsChildren(param) {
      const response = await deleteUcstPriAsChildren(param);
      if (response.data) {
        this.removeAll();
      }
    },
  },
};
</script>

<style scoped></style>
