<template>
  <pmis-content-box :loading="loading">
    <template v-slot:header-left>
      <iui-select :items="searchSeItems" :value.sync="searchSe" defaultCd="S"></iui-select>
      <iui-text type="search" :value.sync="searchText" @enter="onSearch" />
      <iui-button @click="onSearch">검색</iui-button>
    </template>

    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onFocus}"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOptions/GrpUserListSheet.js';
import {selectGrpAuthUserList} from '../api/grpAuth.js';
export default {
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this), 1, 'auth'),
      loadSearchData: [],
      searchSeItems: [
        {text: '사용자ID', value: 'id'},
        {text: '이름', value: 'name'},
      ],
      searchText: '',
      searchSe: '',
      loading: false,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['grpAuthInfo', 'userAuthInfo']);
    $mapMutations(this, ['setUserAuthInfo']);
  },
  created() {
    this.addEvent([
      {name: 'selectGrpAuthUserList', func: this.onSearch},
      {name: 'GrpUserList_getSaveJson', func: this.getSaveJson},
    ]);
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onFocus(e) {
      if (e.row.Kind == 'Data') {
        this.setUserAuthInfo(e.row);
      }
    },
    async onSearch() {
      if (!this.grpAuthInfo.seq) {
        this.sheet?.removeAll();
        return;
      }

      this.loading = true;
      const param = {
        seq: this.grpAuthInfo.seq,
        searchSe: this.searchSe,
        searchText: this.searchText,
      };
      const response = await selectGrpAuthUserList(param);
      this.loadSearchData = response.data;
      this.loading = false;
    },
    getSaveJson(callback) {
      if (callback) {
        callback(this.sheet.getSaveJson(0).data);
      }
    },
  },
};
</script>

<style></style>
