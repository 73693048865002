export default that => {
  return {
    Cfg: {
      CanEdit: false,
      FitWidth: true,
      HeaderMerge: 6,
    },
    LeftCols: [
      {Name: 'equipCode', Header: ['장비코드', '장비코드'], Width: 130, Type: 'Text', Visible: 0},
      {Name: 'equipName', Header: ['장비명', '장비명'], Width: 130, Type: 'Text'},
      {Name: 'carNo', Header: ['차량번호', '차량번호'], Width: 100, Type: 'Text'},
      {Name: 'custName', Header: ['거래처명', '거래처명'], Width: 150, Type: 'Text', FormulaRow: '합계'},
    ],
    Cols: [
      {
        Name: 'dayQty',
        Header: ['금일투입', '가동시간'],
        Extend: that.$ibsheetHelper.preset.quantity,
        MinWidth: 80,
        RelWidth: 1,
        FormulaRow: 'Sum',
      },
      {
        Name: 'dayAmt',
        Header: ['금일투입', '가동금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        MinWidth: 90,
        RelWidth: 1,
        FormulaRow: 'Sum',
      },
      {
        Name: 'monthQty',
        Header: ['금월투입', '가동시간'],
        Extend: that.$ibsheetHelper.preset.quantity,
        MinWidth: 80,
        RelWidth: 1,
        FormulaRow: 'Sum',
      },
      {
        Name: 'monthAmt',
        Header: ['금월투입', '가동금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        MinWidth: 90,
        RelWidth: 1,
        FormulaRow: 'Sum',
      },
      {
        Name: 'totQty',
        Header: ['누계투입', '가동시간'],
        Extend: that.$ibsheetHelper.preset.quantity,
        MinWidth: 80,
        RelWidth: 1,
        FormulaRow: 'Sum',
      },
      {
        Name: 'totAmt',
        Header: ['누계투입', '가동금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        MinWidth: 90,
        RelWidth: 1,
        FormulaRow: 'Sum',
      },
      {Name: 'subCodeName', Header: ['투입외주', '투입외주'], Width: 150, Type: 'Text'},
    ],
  };
};
