<template>
  <div v-show="visible">
    <span v-for="(item, index) in internalItems" v-bind:key="index" class="_chkWrap">
      <input
        v-if="type === 'checkbox'"
        ref="checkbox"
        type="checkbox"
        :id="`${checkboxId}_${index}`"
        :name="name || checkboxId"
        :value="item[internalItemValueAlias]"
        @click="click"
        @change="change"
        :class="{_chk_target_: true}"
        :disabled="!enable"
        :checked="values.findIndex(v => v === item[internalItemValueAlias]) !== -1"
        :required="required"
        :data-error-title="errTitle"
        :data-error-message="errMessage"
      />
      <label :id="`${labelId}_${index}`" :for="`${checkboxId}_${index}`" :class="{'is-valid-fail': !isValid}">
        <span></span>
        {{ item[internalItemTextAlias] && showLabel ? item[internalItemTextAlias] : '' }}
      </label>
    </span>
  </div>
</template>

<script>
// import {setTimeout} from 'timers';
import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import IuiItemsMixin from '@/components/Iui/mixins/IuiItemsMixin';
import {IuiValidatorMixin} from '@/plugins/IuiValidator';
import rootStore from '@/store';

export default {
  name: 'iui-checkbox-group',
  mixins: [IuiBaseMixin, IuiItemsMixin, IuiValidatorMixin],
  props: {
    type: {
      type: String,
      default: 'checkbox',
    },
    formObj: {
      type: String,
      default: 'none!',
    },
    checkedValues: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Array, String],
      default: () => [],
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    separator: {
      type: String,
      default: ',',
    },
  },
  data() {
    return {
      values: [],
      arrayValue: Array.isArray(this.value),
      originalValue: [''],
      isUser: false,
    };
  },
  computed: {
    errTitle() {
      return this.errorMessage instanceof Object ? this.errorMessage.title : 'Confirm';
    },
    errMessage() {
      return this.errorMessage instanceof Object ? this.errorMessage.message : this.errorMessage;
    },
    checkboxId: {
      get() {
        return this.getId();
      },
    },
    labelId: {
      get() {
        return this.getId('label');
      },
    },
  },
  watch: {
    value(value) {
      this.setValues(value);
    },
    checkedValues(values) {
      this.changeCheckedValue(values);
    },
  },
  created() {
    this.initCheckbox();
  },
  updated() {
    this.initCheckbox();
    this.initValidator();
    this.checkParameter();
  },
  async mounted() {
    await this.$nextTick();

    //이벤트 주입
    for (let e of this.event) {
      for (let el of Array.from(this.$el.querySelectorAll('span'))) {
        el.querySelector('input[type=checkbox]').addEventListener(e.name, e.callback);
      }
    }

    if (this.value.length) {
      this.setValues(this.value);
    }

    if (this.checkedValues.length) {
      this.changeCheckedValue(this.checkedValues);
    }
  },
  methods: {
    setValues(value) {
      if (this.arrayValue) {
        this.values = value;
      } else {
        if (value === undefined) {
          this.values = [];
          return;
        }
        this.values = String(value).split(this.separator);
      }
    },
    initCheckbox() {
      if (this.itemKeyAlias === undefined && this.internalItemTextAlias === 'text') {
        this.internalItemTextAlias = 'label';
      }

      this.setBindArray();

      // validator 설정
      this.validatorTargetDataProp = 'values';
      this.validationTriggerEventName = ['valid'];
    },
    changeCheckedValue(checkedValues) {
      this.values = checkedValues;
    },
    click(e) {
      if (this.readonly) {
        e.target.checked = !e.target.checked;
        return;
      }
      this.$emit('click', e);
    },
    change(e) {
      this.isUser = true;
      let values;
      if (!e.target.checked) {
        values = this.getValue(this.values.filter(v => v != e.target.value));
      } else {
        values = this.getValue([...this.values, e.target.value]);
      }
      this.$emit('update:value', values);
      e.values = values;
      this.$emit('change', e);
      this.$emit('valid', e);
    },
    getValue(value) {
      let sortValue = [];
      this.internalItems.forEach(item => {
        const index = value.findIndex(val => val === item.value);
        if (index !== -1) {
          sortValue.push(value[index]);
        }
      });

      if (this.arrayValue) {
        return sortValue;
      } else {
        return sortValue.join(this.separator);
      }
    },
    setBindArray(values) {
      if (typeof values != 'undefined') {
        this.bindArray.forEach(o => {
          let payload = {};
          payload[this.name] = values.join(',');
          rootStore.commit(rootStore.getters.currentUrl + '/' + o, payload);
        });
      }
    },
    checkParameter() {
      const array = this.$refs.checkbox;
      const values = [];
      array.forEach(x => {
        if (x.checked) {
          values.push(x.value);
        }
      });
      if (!this.isUser) {
        this.originalValue = values;
      }

      if (JSON.stringify(this.originalValue) !== JSON.stringify(values)) {
        this.$refs.checkbox.forEach(x => x.setAttribute('data-changed', ''));
      } else {
        this.$refs.checkbox.forEach(x => x.removeAttribute('data-changed'));
      }
      this.isUser = false;
    },
  },
};
</script>

<style scoped>
.is-valid-fail {
  border-bottom: 1px solid red;
}
</style>
