const state = {
  deptInfo: {
    fldrCd: '', //코드
    fldrNm: '', //명칭
    uppFldrCd: '', //상위코드
    uppFldrNm: '', //상위명칭,
    fldrNo: '',
    uppFldrNo: '', //상위폴더번호
    rmk: '',
    cud: '',
  },
  workerInfo: {
    empNo: '', //아이디(사원번호)
    name: '', //사원명
    blnDepNo: '', //부서코드
    order: '', //정렬순서
  },
  searchInfo: {
    pgmCd: '',
    frmNm: '',
    frmGb: '',
  },
  focusKeyCode: '',
};

const getters = {
  deptInfo: state => state.deptInfo,
  workerinfo: state => state.workerInfo,
  searchInfo: state => state.searchInfo,
  focusKeyCode: state => state.focusKeyCode,
};
const actions = {};
const mutations = {
  setDeptInfo(state, payload) {
    for (let key in state.deptInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.deptInfo[key] = payload[key];
      }
    }
  },

  setWorkerInfo(state, payload) {
    for (let key in state.workerInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.workerInfo[key] = payload[key];
      }
    }
  },

  initDeptInfo(state) {
    for (let key in state.deptInfo) {
      state.deptInfo[key] = '';
    }
  },

  setSearchInfo(state, payload) {
    for (let key in state.searchInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },

  setFocusKeyCode(state, payload) {
    if (payload) {
      state.focusKeyCode = payload;
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
