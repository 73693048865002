var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pmis-content-box',{scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_c('iui-radio-group',{attrs:{"p-code":"0425","value":_vm.snclDs},on:{"change":_vm.onChangeSnclDs}})]},proxy:true}])},[_c('iui-container-new',{attrs:{"type":"css-flex"}},[_c('i-row',[_c('i-col',{attrs:{"width":"400px"}},[_c('pmis-content-box',{scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_c('iui-text',{attrs:{"value":_vm.searchNm},on:{"update:value":function($event){_vm.searchNm=$event},"enter":_vm.onSearch}}),_c('iui-button',{on:{"click":_vm.onSearch}},[_vm._v("검색")])]},proxy:true}])},[_c('ib-sheet',{directives:[{name:"show",rawName:"v-show",value:(_vm.snclDs === _vm.SNCL_DS_FIX),expression:"snclDs === SNCL_DS_FIX"}],attrs:{"uid":_vm._uid,"options":_vm.Options1,"loadSearchData":_vm.loadSearchData1,"events":{
              onSearchFinish: _vm.onSearchFinish1,
              onClick: _vm.onClick1,
            }},on:{"loadSheet":_vm.loadSheet1}}),_c('ib-sheet',{directives:[{name:"show",rawName:"v-show",value:(_vm.snclDs === _vm.SNCL_DS_OBS),expression:"snclDs === SNCL_DS_OBS"}],attrs:{"uid":_vm._uid,"options":_vm.Options2,"loadSearchData":_vm.loadSearchData2,"events":{
              onDblClick: _vm.onDblClick2,
            }},on:{"loadSheet":_vm.loadSheet2}})],1)],1),(_vm.snclDs === _vm.SNCL_DS_OBS)?_c('i-col',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"},attrs:{"width":"30px"}},[_c('iui-button',{on:{"click":_vm.addApprover}},[_vm._v("→")]),_c('i-spacer'),_c('iui-button',{on:{"click":_vm.removeApprover}},[_vm._v("←")])],1):_c('i-spacer'),_c('i-col',[_c('pmis-content-box',{scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_c('label',[_vm._v("그룹명")]),_c('iui-text',{attrs:{"width":"430px","value":_vm.tl,"enable":!_vm.isFixLine},on:{"update:value":function($event){_vm.tl=$event}}}),(_vm.isFixLine)?_c('iui-button',{attrs:{"value":"삭제"},on:{"click":_vm.onDelete}}):_vm._e(),(!_vm.isFixLine)?_c('iui-button',{attrs:{"value":"저장","enable":!!_vm.tl},on:{"click":_vm.onSave}}):_vm._e()]},proxy:true}])},[_c('ib-sheet',{attrs:{"uid":_vm._uid,"options":_vm.Options3,"loadSearchData":_vm.loadSearchData3,"events":{
              onSearchFinish: _vm.onSearchFinish3,
              onDblClick: _vm.onDblClick3,
              onAfterChange: _vm.onAfterChange3,
            }},on:{"loadSheet":_vm.loadSheet3}})],1)],1),(_vm.snclDs === _vm.SNCL_DS_OBS)?_c('i-col',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"},attrs:{"width":"30px"}},[_c('iui-button',{on:{"click":_vm.setOrderUpApprover}},[_vm._v("↑")]),_c('i-spacer'),_c('iui-button',{on:{"click":_vm.setOrderDownApprover}},[_vm._v("↓")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }