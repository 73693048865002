export default that => {
  return {
    Cfg: {
      CanEdit: 1,
    },
    Cols: [
      {
        Header: '그룹명',
        Name: 'grpNm',
        Type: 'Text',
        RelWidth: 1,
        Visible: 1,
        CanEdit: 0,
      },
      {
        Header: '비고',
        Name: 'rmk',
        Type: 'Text',
        RelWidth: 1,
        Visible: 1,
        CanEdit: 0,
      },
      {
        Name: 'seq',
        Type: 'Text',
        RelWidth: 1,
        Visible: 0,
        CanEdit: 0,
      },
    ],
  };
};
