<template>
  <ul v-show="false">
    <li v-for="item in items" :key="item.mappedBy">
      {{ item.upload }}
      <file-upload
        class="btn btn-primary dropdown-toggle"
        name="files"
        :headers="postHeader"
        :post-action="interPostAction"
        v-model="item.files"
        @input-file="onInputFile"
        :ref="item.upload"
        :data="{mappedBy: item.mappedBy, pgmCd: pgmCd, temp: true}"
      ></file-upload>
    </li>

    <viewer :images="imageInfo.images" :options="options" @inited="inited" v-show="false">
      <img v-for="(image, index) in imageInfo.images" :src="image" :key="index" v-show="false" />
    </viewer>
  </ul>
</template>

<script>
import FileUpload from 'vue-upload-component';
import {getRefreshToken} from '@/common';
export default {
  components: {
    FileUpload: FileUpload,
  },
  props: {
    mappedBy: {
      type: [String, Number],
    },
    search: {
      type: Boolean,
      default: false,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    enableUpload: {
      type: Boolean,
      default: true,
    },
    initSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sheet: {},
      items: [],
      files: [],
      accept: 'image/png,image/gif,image/jpeg,image/webp',
      extensions: 'gif,jpg,jpeg,png,webp',
      // extensions: ['gif', 'jpg', 'jpeg','png', 'webp'],
      // extensions: /\.(gif|jpe?g|png|webp)$/i,
      minSize: 1024,
      size: 1024 * 1024 * 10,
      multiple: false,
      directory: false,
      drop: true,
      dropDirectory: true,
      addIndex: false,
      name: 'file',
      fileUrl: location.protocol.indexOf('https') !== -1 ? process.env.VUE_APP_FILE_URLS : process.env.VUE_APP_FILE_URL,
      token: this.$store.getters['jwt/accessToken'],
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      isOption: false,
      addData: {
        show: false,
        name: '',
        type: '',
        content: '',
      },
      editFile: {
        show: false,
        name: '',
      },
      updateTargetRow: undefined,
      flNos: [],
      uploads: [],
      uploadIndex: 0,

      progress: {
        currentCnt: 0,
        totalCnt: 0,
        progress: 0,
        totalProgress: 0,

        totalProgressObj: {},
      },

      imageInfo: {
        images: [],
        titles: [],
        indexs: {},
      },
      options: {
        title: true,
        movable: false,
        loop: false,
        initialViewIndex: 0,
        fullscreen: false,
        // backdrop: false
      },
    };
  },
  computed: {
    postHeader: {
      get: function() {
        return {Authorization: this.token};
      },
      set: function(token) {
        this.token = token;
      },
    },
    interPostAction() {
      return `${this.fileUrl}/file/saveFile`;
    },
  },
  watch: {
    uploadIndex() {
      this.onUpload();
    },
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.onSearchFile();
          this.$emit('update:search', false);
        }
      }
    },
  },
  created() {
    this.addEvent([{name: 'IuiFileInterface_init', func: this.onInit}]);
  },
  methods: {
    onInit(sheet) {
      this.sheet = sheet;
      this.sheet.addCol('pgmCd', 1, -1, {Header: 'pgmCd', Type: 'Text', Width: 80, Visible: false}, false);
      this.sheet.addCol('flNo', 1, -1, {Header: 'flNo', Type: 'Text', Width: 80, Visible: false}, false);
      this.sheet.addCol('revId', 1, -1, {Header: 'revId', Type: 'Text', Width: 80}, false);
      this.sheet.addCol('svrFlNm', 1, -1, {Header: 'svrFlNm', Type: 'Text', Width: 80}, false);
      this.sheet.addCol('uploadStatus', 1, -1, {Header: 'uploadStatus', Type: 'Text', Width: 80}, false);
      this.sheet.addCol(
        'lclFlNm',
        1,
        -1,
        {
          Header: '파일명',
          Type: 'Text',
          RelWidth: 1,
          Width: 200,
          CanEdit: false,
        },
        this.enable
      );
      this.sheet.addCol(
        'flSz',
        1,
        -1,
        {
          Header: '크기',
          Name: 'flSz',
          Align: 'right',
          Type: 'Int',
          Format: '#,##0',
          CanEdit: false,
          Width: 80,
        },
        this.enable
      );
      this.sheet.addCol(
        'fileUpload',
        1,
        -1,
        {
          Header: '업로드',
          Type: 'Button',
          Name: 'conf_btn',
          Button: 'Button',
          Width: 120,
          ButtonText: '업로드',
          OnClick: this.onClickButton,
        },
        this.enableUpload
      );

      this.sheet.setAttribute(null, 'lclFlNm', 'Visible', this.enable, 1);
      this.sheet.setAttribute(null, 'flSz', 'Visible', this.enable, 1);
      this.sheet.setAttribute(null, 'fileUpload', 'Visible', this.enableUpload, 1);

      this.sheet.bind('onDblClick', e => this.onDlbClick(e.row));

      if (this.initSearch) {
        this.onSearchFile();
      }
    },
    onSearchFile() {
      this.flNos = [];
      this.imageInfo = {images: [], titles: [], indexs: {}};

      this.items = this.sheet.getDataRows();
      this.items.forEach(el => {
        el.files = [];
        el.upload = `upload${el[this.mappedBy] || el.HasIndex}`;
        el.success = false;
        el.mappedBy = el[this.mappedBy] || el.HasIndex;
        el.uploadStatus = '0';
        if (el.flNo) this.flNos.push({pgmCd: el.pgmCd || this.pgmCd, flNo: el.flNo});
      });

      this.flNos.forEach(el => {
        axios.post('/file/selectFileList', {pgmCd: el.pgmCd, flNo: el.flNo}).then(response => {
          if (response.status == 200) {
            if (!response.data.length) return;
            this.items.some((el, index) => {
              if (el.flNo == response.data[0].flNo) {
                el.fileInfo = response.data[0];
                el.lclFlNm = el.fileInfo.lclFlNm;
                el.svrFlNm = el.fileInfo.svrFlNm;
                el.revId = el.fileInfo.revId;
                el.flSz = el.fileInfo.flSz;
                el.uploadStatus = '1';
                this.sheet.refreshRow(el);

                this.onSetImageInfo(el.pgmCd, el.flNo, el.revId, el.lclFlNm, el.id, index);
              }
            });
          }
        });
      });
    },
    onSetImageInfo(pgmCd, flNo, revId, lclFlNm, rowId, index) {
      const ext = lclFlNm.substr(lclFlNm.lastIndexOf('.') + 1);
      if ($getMineType(ext).indexOf('image') > -1) {
        this.imageInfo.images.push(this.getImageUrl(pgmCd, flNo, revId));
        this.imageInfo.titles.push(lclFlNm);
        this.imageInfo.indexs[rowId] = index;
      }
    },

    // add, update, remove File Event
    async onInputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        this.sheet.setValue(this.updateTargetRow, 'lclFlNm', newFile.name, 1);
        this.sheet.setValue(this.updateTargetRow, 'flSz', newFile.size, 1);
        this.sheet.setValue(this.updateTargetRow, 'uploadStatus', '2', 1);
      }
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          // if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
          //   this.$refs.upload.update(newFile, {error: 'size'});
          // }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
          this.onCallFileProgress(false, newFile);
        }
        if (newFile.error && !oldFile.error) {
          if (newFile.error == 'denied' && newFile.response.error_description.indexOf('expired') != -1) {
            this.postHeader = await getRefreshToken();
            newFile.headers.Authorization = this.postHeader.Authorization;
            this.$refs.upload.update(newFile, {active: true, error: '', progress: '0.00'});
          } else {
            // error
          }
        }
        if (newFile.success && !oldFile.success) {
          // success
          this.onCallFileProgress(true, newFile);
          this.onFileUploadComplate(newFile);
        }
      }
      if (!newFile && oldFile) {
        // remove
      }
      // Automatically activate upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
    },

    onCallFileProgress(isSuccess, newFile) {
      const currentCnt = isSuccess ? (this.progress.currentCnt += 1) : this.progress.currentCnt;
      const totalCnt = this.uploads.length;
      const progress = parseFloat(newFile.progress);
      this.progress.totalProgressObj[this.uploadIndex] = progress;

      let total = 0;
      for (let key in this.progress.totalProgressObj) {
        total += this.progress.totalProgressObj[key];
      }
      const totalProgress = total;
      const fileNm = newFile.name;

      this.callEvent({
        name: 'fileProgress',
        param: {
          currentCnt: currentCnt,
          totalCnt: totalCnt,
          progress: progress,
          totalProgress: totalProgress,
          fileNm: fileNm,
        },
        isDefault: true,
      });
    },

    onClickButton(e) {
      this.$refs[`upload${e.row.mappedBy}`][0].$children[0].$el.click();
      this.updateTargetRow = e.row;
    },

    onBeforeUpload() {
      let delFiles = [];
      this.items.forEach(el => {
        if (el.uploadStatus == '2') {
          this.uploads.push(el.mappedBy);
        }

        if (el.fileInfo && el.uploadStatus == '2') {
          delFiles.push({
            pgmCd: el.fileInfo.pgmCd,
            flNo: el.fileInfo.flNo,
            flDs: el.fileInfo.flDs,
          });
          delete el.fileInfo;
        }
      });

      if (delFiles.length) {
        axios.post('/file/deleteFile', delFiles);
      }

      this.onUpload();
    },

    onUpload() {
      if (0 < this.uploads.length) {
        this.callEvent({name: 'showFileProgress', isDefault: true});
        this.$refs[`upload${this.uploads[this.uploadIndex]}`][0].active = true;
      }
    },

    onFileUploadComplate(file) {
      this.items.forEach(el => {
        if (el.mappedBy == file.data.mappedBy) {
          el.success = file.success;
          el.flNo = file.response.flNo;
        }
      });

      let uploadComplete = true;

      this.items.forEach(el => {
        if (el.files.length && !el.success) {
          uploadComplete = false;
        }
      });

      if (this.uploads.length != this.uploadIndex + 1) {
        this.uploadIndex++;
      }

      if (uploadComplete) {
        this.uploads = [];
        this.uploadIndex = 0;
        this.$emit('complete');

        setTimeout(() => {
          this.progress.currentCnt = 0;
          this.progress.totalProgress = 0;
          this.callEvent({name: 'hideFileProgress', isDefault: true});
        }, 500);
      }
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    getImageUrl(pgmCd, flNo, revId) {
      return `${this.fileUrl}/file/getImage?pgmCd=${pgmCd}&flNo=${flNo}&revId=${revId}`;
    },
    getFileDownUrl(pgmCd, flNo, revId) {
      return `${this.fileUrl}/file/download?pgmCd=${pgmCd}&flNo=${flNo}&revId=${revId}`;
    },
    onDlbClick(row) {
      if (row.flNo && row.uploadStatus == '1') {
        const ext = row.svrFlNm.substr(row.svrFlNm.lastIndexOf('.') + 1);
        if ($getMineType(ext).indexOf('image') > -1) {
          this.$viewer.view(this.imageInfo.indexs[row.id]);
        } else if ($getMineType(ext).indexOf('pdf') > -1) {
          this.callEvent({
            name: 'showPdf',
            param: {src: this.getImageUrl(row.pgmCd, row.flNo, row.revId), fileName: row.lclFlNm},
            isDefault: true,
          });
        } else {
          this.onFileDownload(row);
        }
      }
    },
    async onFileDownload(row) {
      const ext = row.svrFlNm.substr(row.svrFlNm.lastIndexOf('.') + 1);
      let response = await axios.get(this.getFileDownUrl(row.pgmCd, row.flNo, row.revId), {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data], {type: $getMineType(ext)}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', row.lclFlNm);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style></style>
