export const modalOpen = vm => {
  const name = getModalName(vm);
  vm.$store.commit('modal/addModalNames', name);
};

export const modalClose = (vm, param) => {
  const modalName = vm.$store.getters['modal/modalName'];
  if (modalName) {
    let _isDefault = modalName.indexOf('_isDefault') != -1;
    vm.$store.commit('setModalParam', undefined);
    vm.$modal.hide(modalName.replace('_isDefault', ''), param, _isDefault ? true : false);
  }
};

export const afterModalClose = vm => {
  const name = getModalName(vm);
  const modalNames = vm.$store.getters['modal/modalNames'];
  const modalName = modalNames.find(nm => nm == name);

  if (modalName) {
    vm.$store.commit('modal/removeModalNames', modalName);
  }
};

export const getModalName = vm => {
  let name;
  if (!vm.isDefault) {
    name = vm.name + vm.$store.getters['currentUrl'];
  } else {
    name = vm.name + '_isDefault';
  }

  return name;
};
