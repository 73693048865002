<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <userList />
        </i-col>
        <i-spacer />
        <i-col>
          <log />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import userList from './components/userList.vue';
import log from './components/log.vue';
import store from './store/loginLog.js';
export default {
  components: {
    userList,
    log,
  },
  beforeCreate() {
    $init(this, store);

    $mapGetters(this, {searchInfo: 'searchInfo'});

    $mapMutations(this, ['setSearchInfo']);
  },

  created() {
    this.canSearch = true;
    this.addFuncSearch(this.search_click);
    this.setSearchInfo({pgmCd: this.userInfo.pgmCd});
  },

  methods: {
    search_click() {
      this.callEvent({name: 'userList_selectLoginUserList', param: this.searchInfo});
    },
  },
};
</script>

<style scoped></style>
