<template>
  <pmis-content-box>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onSearchFinish: onSearchFinish,
        onClick: onClick,
      }"
    />
  </pmis-content-box>
</template>

<script>
import {selectAppFomList} from '../../api/appDoc.js';
import options from './sheetOption/FormListSheet.js';
export default {
  data() {
    return {
      Options: options(this),
      loadSearchData: [],
    };
  },

  created() {
    this.addEvent([{name: 'formList_selectFormList', func: this.onSearch}]);
    this.onSearch();
  },

  methods: {
    async onSearch() {
      const response = await selectAppFomList();
      this.loadSearchData = response.data;
    },
    onSearchFinish(e) {
      const row = e.sheet.getFirstRow();
      this.callEvent({
        name: 'form_selectForm',
        param: row,
      });
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.callEvent({
          name: 'form_selectForm',
          param: e.row,
        });
      }
    },
  },
};
</script>

<style scoped></style>
