<template>
  <pmis-content-box>
    <template v-slot:title> 사용자 개인 설정</template>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <iui-container-new type="table" theme="bullet" headerWidth="140px">
            <colgroup>
              <col width="150px" />
              <col width="150px" />
              <col width="150px" />
            </colgroup>
            <i-row>
              <i-col-header>
                금액 단위
              </i-col-header>
              <i-col>
                <iui-select
                  :p-code="$getConstants('AMOUNT_UNIT').code"
                  defaultCd="S"
                  :value.sync="userConfig.amountUnit"
                />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
export default {
  data() {
    return {
      userConfig: {
        amountUnit: '',
      },
    };
  },
  created() {
    this.addEvent({name: 'UserConfig_confirm', func: this.onConfirm});

    const userInfo = this.userInfo;
    for (let key in this.userConfig) {
      this.userConfig[key] = userInfo[key];
    }
  },
  methods: {
    async onConfirm(callback) {
      if (typeof callback === 'function') {
        try {
          let response = await axios.post('/myPage/updateUserConfig', this.userConfig);
          if (response.data) {
            this.setUserInfo();
            this.$alert({title: '사용자 개인 설정 변경완료', message: '사용자 개인 설정이 변경되었습니다.'});
            callback();
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    setUserInfo() {
      const userInfo = this.userInfo;
      for (let key in this.userConfig) {
        userInfo[key] = this.userConfig[key];
      }
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
    },
  },
};
</script>

<style></style>
