<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <div>가동일자</div>
      <iui-datepicker
        name="runDt"
        :value="searchInfo.runDt"
        @change="onChangeSearchInfo('runDt', $event)"
        :open.sync="searchRunDtOpen"
      />
      <i class="prev-arrow" v-if="cud != 1" v-on:click="onChangeRunDt('PREV')" />
      <i class="post-arrow" v-if="cud != 1" v-on:click="onChangeRunDt('POST')" />

      <iui-select
        label="공사구분"
        :value="searchInfo.wcode"
        :items="wCodeList"
        defaultCd="S"
        @change="value => setSearchInfo({wcode: value})"
      />
      <iui-select
        label="공종분류"
        :value="searchInfo.clCode"
        :items="clWorkFilter"
        defaultCd="S"
        @change="value => setSearchInfo({clCode: value})"
      />
      <iui-text
        type="search"
        label="내역명칭"
        :value="searchInfo.searchNm"
        class="z-index-1"
        @change="setSearchInfo({searchNm: $event.target.value})"
        @enter="onExecSearch"
      />
    </template>
    <template v-slot:header-right>
      <iui-button @click="onPopAddExcel" value="엑셀등록" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onSearchFinish,
        onButtonClick,
      }"
    />

    <iui-modal name="UnitOpertListModal" title="투입등록" :btns="unitOpertListBtns" sizeType="size1">
      <UnitOpertList :costType="costType" />
    </iui-modal>

    <iui-modal name="equipCodeModal" title="투입장비선택" :btns="equipCodeModalBtns" sizeType="size3">
      <OperationEquipmentListPopup :uid="_uid" :itemCode="itemCode" />
    </iui-modal>

    <iui-modal name="PoCompleteListModal" title="외주선택" :btns="poCompleteListBtns" sizeType="size3">
      <SubCustListPopup
        :costType="costType"
        :itemSeq="itemSeq"
        :midCode="midCode"
        :itemCode="equipCode || undefined"
        callback-type="2"
      />
    </iui-modal>
    <iui-modal name="EqutExcelUploadPop" title="엑셀등록" :btns="excelBtns" sizeType="size1">
      <ExcelUpload
        uri="/operationJournal/equtExcelUpload"
        title="가동일보"
        :rows="excelRows"
        @error="onExcelUploadError"
      />
    </iui-modal>
    <iui-modal name="EqutExcelUploadResultPop" title="엑셀 업로드 실패 사유" sizeType="size6" @closed="resultList = []">
      <ExcelUploadResult :sheet-opt="excelSheetOpt" :list="resultList" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
/**
 * 가동일보등록 > 가동일보
 * components :
 *   OperationEquipmentListPopup 가동장비조회 팝업
 *   SubCustListPopup 외주 조회 팝업
 * */

import UnitOpertList from '@/view/Resource/components/UnitOpertListPopup.vue';
import OperationEquipmentListPopup from './OperationEquipmentListPopup.vue';
import SubCustListPopup from '@/view/Resource/components/SubCustListPopup.vue';
import options from './sheetOptions/EqutDailySheet.js';
import {
  deleteEqutDailyReport,
  saveEqutDailyReport,
  selectEquipmentExecDetails,
  selectEqutDailyReportList,
  selectLastRunDate,
} from '../api/operationJournal.js';
import {selectMonthClosePossibleAt} from '@/view/closing/expitmCloseRegist/api/expitmCloseRegist.js';
import excelOptions from '@/view/Resource/Equipment/OperationJournal/components/sheetOptions/EqutExcelUploadResultSheet';
import ExcelUploadResult from '@/components/popup/ExcelUploadResult.vue';
import ExcelUpload from '@/components/popup/ExcelUpload.vue';
import {selectClWorkBYExe, selectWcodeBYExe} from '@/view/executDtls/api/executDtls.js';

export default {
  components: {
    ExcelUploadResult,
    ExcelUpload,
    UnitOpertList,
    OperationEquipmentListPopup,
    SubCustListPopup,
  },
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this), 2, 'chk', {CanEditFormula: e => !e.Row.closeYn}),
      loadSearchData: [],
      unitOpertListBtns: [{name: '확인', callback: this.onHideUnitOpertList}],
      currentDate: $_getCurrentDate(),
      costType: '', // 자원구분

      equipCodeModalBtns: [{name: '확인', callback: this.onHideEquipCodeModal}],
      poCompleteListBtns: [{name: '확인', callback: this.onHidePoCompleteList}],
      modalNm: '',

      monPjtCloseAt: '', //마감여부
      currentPjtClseAt: '',
      searchRunDtOpen: false,
      itemSeq: '',
      midCode: '',
      itemCode: '',
      equipCode: undefined,
      excelBtns: [{name: '확인', callback: this.onPopConfirmExcel}],
      resultList: [],
      excelRows: [
        {
          value: [
            {value: '가동일자', style: {type: 'TEXT'}},
            {value: '실행중공종코드'},
            {value: '실행내역코드', style: {type: 'TEXT'}},
            {value: '자원코드', style: {type: 'TEXT'}},
            {value: '단위작업분류', style: {type: 'TEXT'}},
            {value: '단위작업ID', style: {type: 'TEXT'}},
            {value: '장비코드', style: {type: 'TEXT'}},
            {value: '차량번호', style: {type: 'TEXT'}},
            {value: '시간'},
            {value: '외주 계약번호'},
          ],
        },
      ],
      excelSheetOpt: excelOptions(this),
      wCodeList: [],
      clWorkList: [],
      clWorkFilter: [],
      wcode: '',
      clCode: '',
    };
  },
  watch: {
    searchInfo: {
      handler(value) {
        for (let key in value) {
          if (key === 'wcode') {
            this.clWorkFilter = this.clWorkList.filter(r => r.wcode == value[key]);
            this.wcode = value[key];
          }
          if (key === 'clCode') {
            this.clCode = value[key];
          }
        }
      },
      deep: true,
    },
    wcode() {
      this.onExecSearch();
    },
    clCode() {
      this.onExecSearch();
    },
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'focusKey']);
    $mapMutations(this, ['setSearchInfo', 'setFocusKey', 'initFocusKey']);
  },
  created() {
    this.addFuncPrj(this.initSearch);
    this.costType = this.isSimpleExec ? '' : 'E';
    this.addEvent([
      {name: 'EqutDaily_onSearchLastRunDate', func: this.onSearchLastRunDate},
      {name: 'EqutDaily_onSearch', func: this.onSearch},
      {name: 'EqutDaily_onAdd', func: this.onAdd},
      {name: 'EqutDaily_onSave', func: this.onSave},
      {name: 'EqutDaily_onDelete', func: this.onDelete},
    ]);
  },
  methods: {
    initSearch() {
      this.onSearchWCodeList(); //공사구분
      this.onSearchClWorkList(); //공종분류
    },
    onSearchWCodeList() {
      //공사구분
      const param = {};
      selectWcodeBYExe(param).then(response => {
        this.wCodeList = response.data.map(e => ({text: e.wname, value: e.wcode}));
      });
    },
    onSearchClWorkList() {
      //공종분류
      const param = {};
      selectClWorkBYExe(param).then(response => {
        this.clWorkList = response.data.map(e => {
          return {wcode: e.wcode, headCode: e.headCode, midCode: e.midCode, value: e.clCd, text: e.clNm};
        });
      });
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onSearchFinish(e) {
      e.sheet.getDataRows().forEach(row => {
        if (row.equipCode == this.focusKey) {
          e.sheet.focus(row);
        }
        this.sheet.setValue(row, 'add', '<input type="button" class="i_plus" />', true);
        this.sheet.setValue(row, 'remove', '<input type="button" class="i_minus" />', true);
        this.sheet.acceptChangedData(row);
      });
      this.initFocusKey();
    },
    onButtonClick(e) {
      if (e.col == 'equipCode' || e.col == 'frmNm') {
        const costType = this.costType;
        this.$store.commit('setModalParam', {costType});

        if (e.col == 'equipCode') {
          // 장비코드
          this.modalNm = 'equipCodeModal';
        }
        if (e.col == 'frmNm') {
          // 외주
          this.modalNm = 'subCodeModal';
          this.equipCode = e.row.equipCode ? String(e.row.equipCode) : undefined;
        }

        this.itemCode = e.row.itemCode;
        this.$modal.show(this.modalNm);
      }
    },
    equipAdd(e) {
      function reOrder(row) {
        const nextRow = row.nextSibling;
        if (nextRow && row.midCode == nextRow.midCode && row.itemSeq == nextRow.itemSeq) {
          e.sheet.setValue(nextRow, 'rowNo', row.rowNo + 1, 1);
          reOrder(nextRow);
        }
      }

      if (this.monPjtCloseAt == 'N') {
        const addRow = e.sheet.copyRow(e.row, e.row.nextSibling);
        e.sheet.setValue(
          addRow,
          'equipCode',
          addRow.costType === $getConstants('COST_TYPE_I').code ? '' : addRow.equipCode
        );
        e.sheet.setValue(
          addRow,
          'equipName',
          addRow.costType === $getConstants('COST_TYPE_I').code ? '' : addRow.equipName
        );
        e.sheet.setValue(addRow, 'carNo', addRow.costType === $getConstants('COST_TYPE_I').code ? '' : addRow.carNo);
        e.sheet.setValue(
          addRow,
          'useGbText',
          addRow.costType === $getConstants('COST_TYPE_I').code ? '' : addRow.useGbText
        );
        e.sheet.setValue(
          addRow,
          'rentFrmNm',
          addRow.costType === $getConstants('COST_TYPE_I').code ? '' : addRow.rentFrmNm
        );
        e.sheet.setValue(addRow, 'useGb', addRow.costType === $getConstants('COST_TYPE_I').code ? '' : addRow.useGb);
        e.sheet.setValue(addRow, 'runQty', 0);
        e.sheet.setValue(
          addRow,
          'runPrice',
          addRow.costType === $getConstants('COST_TYPE_I').code ? '' : addRow.runPrice
        );
        e.sheet.setValue(addRow, 'runAmt', 0);
        e.sheet.setValue(addRow, 'seq', 0);
        e.sheet.setValue(addRow, 'cud', 1);

        reOrder(e.row);
        return addRow;
      }
    },
    async equipRemove(e) {
      function reOrder(row) {
        const nextRow = row.nextSibling;
        if (nextRow && row.itemSeq == nextRow.itemSeq) {
          e.sheet.setValue(nextRow, 'rowNo', nextRow.rowNo - 1);
          reOrder(nextRow);
        }
      }

      if (this.monPjtCloseAt == 'N') {
        const title = '가동일보삭제';
        const message = '선택한 가동내역을 삭제 하시겠습니까?';
        if (!(await this.$confirm({title: title, message: message}))) {
          return;
        }

        if (!e.row.cud) {
          const equtDailyReportList = [
            {
              runDt: e.row.runDt,
              seq: e.row.seq,
              equipCode: e.row.equipCode,
              carNo: e.row.carNo,
              wcode: e.row.wcode,
              headCode: e.row.headCode,
              midCode: e.row.midCode,
              itemSeq: e.row.itemSeq,
              itemCode: e.row.itemCode,
              poNoSubc: e.row.poNoSubc,
            },
          ];

          try {
            const param = {runDt: this.searchInfo.runDt, equtDailyReportList};
            await deleteEqutDailyReport(param);
            this.onSearch();
          } catch (error) {
            this.$alert({title: '가동일보삭제', message: error.message});
          }
        } else {
          reOrder(e.row);
          e.sheet.removeRow(e.row, null);
          e.sheet.renderBody();
        }
      }
    },
    onShowUnitOpertList() {
      this.$modal.show('UnitOpertListModal');
    },
    onHideUnitOpertList() {
      this.callEvent({
        name: 'getRowsByCheckedAndInputDate',
        param: data => {
          if (data.rows.length) {
            this.setSearchInfo({runDt: data.inputDate, wbsIdList: data.rows.map(row => row.fldrCd)});
            this.onSearchEquipmentExecDetails();
          }
          this.$modal.hide('UnitOpertListModal');
        },
      });
    },
    onShowEquipCodeModal(e) {
      this.itemCode = e.row.itemCode;
      this.$modal.show('equipCodeModal');
    },
    onHideEquipCodeModal() {
      let focusedRow = this.sheet.getFocusedRow();
      this.callEvent({
        name: 'OperationEquipmentListPopup_onCallback',
        param: data => {
          if (data instanceof Array) {
            const tempArray = [];
            data.forEach((row, index) => {
              if (index > 0) {
                if (focusedRow) {
                  focusedRow = this.equipAdd({sheet: this.sheet, row: focusedRow});
                }
              }
              tempArray.push({
                focusedRow,
                row,
              });
            });
            setTimeout(() => {
              tempArray.forEach(obj => {
                this.setRowAsEquipInfo(obj.focusedRow, obj.row);
              });
            }, 100);
          } else {
            this.setRowAsEquipInfo(focusedRow, data);
          }

          this.sheet.setValue(focusedRow, 'equipCode', data.equipCode, 1);
          this.sheet.setValue(focusedRow, 'equipName', data.itemName, 1);
          this.sheet.setValue(focusedRow, 'carNo', data.carNo, 1);

          this.sheet.setValue(focusedRow, 'useGb', data.useGb, 1);
          this.sheet.setValue(focusedRow, 'useGbNm', data.useGbNm, 1);
          this.sheet.setValue(focusedRow, 'rentFrmNm', data.frmNm, 1);

          this.sheet.setValue(focusedRow, 'runPrice', data.runPrice, 1);
          this.sheet.setValue(focusedRow, 'runAmt', focusedRow.runQty * data.runPrice, 1);

          this.$modal.hide('equipCodeModal');
        },
      });
    },
    setRowAsEquipInfo(focusedRow, data) {
      this.sheet.setValue(focusedRow, 'equipCode', data.equipCode, 1);
      this.sheet.setValue(focusedRow, 'equipName', data.itemName, 1);
      this.sheet.setValue(focusedRow, 'carNo', data.carNo, 1);

      this.sheet.setValue(focusedRow, 'useGb', data.useGb, 1);
      this.sheet.setValue(focusedRow, 'useGbNm', data.useGbNm, 1);
      this.sheet.setValue(focusedRow, 'rentFrmNm', data.frmNm, 1);

      this.sheet.setValue(focusedRow, 'runPrice', data.runPrice, 1);
      this.sheet.setValue(focusedRow, 'runAmt', focusedRow.runQty * data.runPrice, 1);
    },
    onShowPoCompleteList(e) {
      this.itemSeq = e.row.itemSeq;
      this.midCode = e.row.midCode;
      this.$modal.show('PoCompleteListModal');
    },
    onHidePoCompleteList() {
      this.callEvent({
        name: 'SubCustListPopup_callbackData',
        param: data => {
          const focusedRow = this.sheet.getFocusedRow();
          this.sheet.setValue(focusedRow, 'poNoSubc', data.poNo, 1);
          this.sheet.setValue(focusedRow, 'frmNm', data.cusNm, 1);
          this.sheet.setValue(focusedRow, 'custCode', data.cusCd, 1);
          this.$modal.hide('PoCompleteListModal');
        },
      });
    },
    async onSearchLastRunDate() {
      const response = await selectLastRunDate(this.searchInfo);
      const lastRunDt = response.data ? response.data : this.currentDate;

      this.setSearchInfo({runDt: String(lastRunDt)});
      this.onSearchMonthCloseAt(this.currentDate);
      this.onSearch();
    },
    onChangeSearchInfo(key, event) {
      const value = typeof event == 'string' ? event : event.target.value;
      this.setSearchInfo({[key]: value});

      if (key == 'runDt') {
        this.onSearchMonthCloseAt(value);

        this.sheet.removeAll();

        if (value) {
          this.onSearch();
        } else {
          this.searchRunDtOpen = true;
        }
      }
    },
    async onChangeRunDt(searchDateType) {
      const response = await selectLastRunDate({...this.searchInfo, searchDateType});
      const lastRunDt = String(response.data);
      if (lastRunDt) {
        this.onSearchMonthCloseAt(lastRunDt);
        this.setSearchInfo({runDt: lastRunDt});
        this.onSearch();
      } else {
        const title = '가동일자';
        const message = `${searchDateType == 'PREV' ? '이전' : '다음'} 가동일이 없습니다.`;
        this.$alert({title, message}, () => {
          if (!this.searchInfo.runDt) {
            this.setSearchInfo({runDt: $_getCurrentDate()});
          }
        });
      }
    },
    async onSearchMonthCloseAt(runDt) {
      if (!runDt) return;
      const closeMm = String(runDt).substring(0, 6);

      const param = {closeMm: closeMm};
      const response = await selectMonthClosePossibleAt(param);

      if (response.data.CHECK1 == 'N') {
        this.monPjtCloseAt = 'Y';
        if (closeMm === this.currentDate.substring(0, 6)) {
          this.currentPjtClseAt = 'Y';
        }
      } else {
        this.monPjtCloseAt = 'N';
      }
      this.sheet.calculate(1);
    },
    async onSearch() {
      const param = {
        runDt: this.searchInfo.runDt,
        wcode: this.wcode,
        headCode: this.clCode.split('::')[0] ?? '',
        midCode: this.clCode.split('::')[1] ?? '',
        searchNm: this.searchInfo.searchNm,
      };

      const response = await selectEqutDailyReportList(param);
      this.loadSearchData = response.data;
      this.cud = 0;
    },

    onExecSearch() {
      if (this.cud === 1) {
        this.onSearchEquipmentExecDetails();
      } else {
        this.onSearch();
      }
    },
    async onAdd() {
      if (this.currentPjtClseAt == 'Y') {
        this.$alert({title: '마감완료', message: '월마감 상태이므로 가동정보 등록은 불가합니다.'});
        return;
      }

      this.onShowUnitOpertList();
    },
    async onSearchEquipmentExecDetails() {
      const param = {
        runDt: this.searchInfo.runDt,
        wbsIdList: this.searchInfo.wbsIdList,
        wcode: this.wcode,
        headCode: this.clCode.split('::')[0] ?? '',
        midCode: this.clCode.split('::')[1] ?? '',
        searchNm: this.searchInfo.searchNm,
        costType: this.costType,
      };
      const response = await selectEquipmentExecDetails(param);
      this.loadSearchData = response.data.map(item => {
        item.cud = 1;
        return item;
      });
    },
    async onSave() {
      if (this.isMonthClose) {
        this.$alert({title: '마감완료', message: '월마감 상태이므로 가동정보 수정은 불가합니다.'});
        return;
      }
      if (!this.sheet.hasChangedData()) {
        this.$alert({title: '가동일보저장', message: '변경된 내용이 없습니다.'});
        return;
      }

      const equtDailyReportList = this.sheet.getSaveJson().data;
      const validRow = equtDailyReportList.find(row => !row.equipCode || !row.runQty);

      if (validRow !== undefined) {
        const idx = this.sheet.getRowIndex(this.sheet.getRowById(validRow.id));
        const message = !validRow.equipCode ? '장비가 선택되지 않았습니다.' : '가동시간이 입력되지 않았습니다.';
        this.$alert({title: '가동일보저장', message: `${idx}행의 ${message} `});
        return;
      }

      try {
        const param = {runDt: this.searchInfo.runDt, equtDailyReportList};
        const response = await saveEqutDailyReport(param);
        if (response.data) {
          this.setFocusKey(equtDailyReportList[0].equipCode);
          this.onSearch();
        }
      } catch (error) {
        this.$alert({title: '가동일보저장', message: error.message});
      }
    },
    async onDelete() {
      if (this.isMonthClose) {
        this.$alert({title: '마감완료', message: '월마감 상태이므로 가동정보 삭제는 불가합니다.'});
        return;
      }

      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({title: '가동일보삭제', message: '삭제할 가동내역을 선택해 주세요.'});
        return;
      }

      const equtDailyReportList = checkedRows.map(row => {
        return {
          runDt: row.runDt,
          seq: row.seq,
          equipCode: row.equipCode,
          carNo: row.carNo,
          wcode: row.wcode,
          headCode: row.headCode,
          midCode: row.midCode,
          itemSeq: row.itemSeq,
          itemCode: row.itemCode,
          poNoSubc: row.poNoSubc,
        };
      });

      const title = '가동일보삭제';
      const message = '선택한 가동내역을 삭제 하시겠습니까?';
      if (!(await this.$confirm({title: title, message: message}))) {
        return;
      }

      try {
        const param = {runDt: this.searchInfo.runDt, equtDailyReportList};
        const response = await deleteEqutDailyReport(param);
        if (response.data) {
          this.onSearch();
        }
      } catch (error) {
        this.$alert({title: '가동일보삭제', message: error.message});
      }
    },
    onPopAddExcel() {
      this.$modal.show('EqutExcelUploadPop');
    },
    onPopConfirmExcel() {
      this.callEvent({
        name: 'excelUpload',
        param: () => {
          this.onSearch();
          this.$modal.hide('EqutExcelUploadPop');
        },
      });
    },
    onExcelUploadError(error, list) {
      if (error === 'server') {
        this.$alert({title: '엑셀업로드', message: '가동일보 엑셀파일을 확인하십시오.'});
      } else {
        this.$alert({title: '엑셀업로드', message: error});
        if (list && list.length > 0) {
          this.resultList = list;
          this.$modal.show('EqutExcelUploadResultPop');
        }
      }
    },
  },
  computed: {
    isMonthClose() {
      return this.monPjtCloseAt == 'Y'; // 월마감 가능여부
    },
  },
};
</script>
