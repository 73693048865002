const BIDDING_CONSTANTS = {
  // 세금계산서 타입
  BILL_TYPE: {
    CON: 'CON', //기성
    MAT: 'MAT', //자재
    SUB: 'SUB', //외주
  },

  // 진행상태코드
  PROGRESS_STATE: {
    PR_READY: '000001', // 요청작성중
    PR_APPROVAL_REQUEST: '000002', // 요청품의중
    PR_APPROVAL_COMPLETE: '000003', // 요청품의완료

    RFQ_READY: '000010', // 공고작성중
    RFQ_APPROVAL_REQUEST: '000011', // 공고품의중
    RFQ_APPROVAL_COMPLETE: '000012', // 공고품의완료

    RFQ_SPOT_DSCR: '000013', // 현설통보
    RFQ_SPOT_DSCR_EXPIRED: '000014', // 현설마감
    RFQ_BIDDING: '000015', // 입찰중
    RFQ_REBID: '000015', // 재입찰
    RFQ_BIDDING_EXPIRED: '000016', // 입찰마감

    RFQ_OPEN: '000020', // 개봉
    RFQ_CHOICE: '000021', // 낙찰사선정
    RFQ_APPROVAL_CHOICE: '000022', // 낙찰사선정품의
    RFQ_FAIL: '000023', // 유찰
    RFQ_COMPLETE: '000024', // 입찰완료

    PO_READY: '000030', // 계약작성중
    PO_APPROVAL_REQUEST: '000031', // 계약품의중
    PO_APPROVAL_COMPLETE: '000032', // 계약품의완료
    PO_SIGN_READY: '000033', // 거래처전자서명중
    PO_SIGN_COMPLETE: '000034', // 거래처전자서명
    PO_COMPLETE: '000035', // 계약완료
  },

  // 업무코드
  CODES: {
    AUTO: '자동',
    FIRST_CONT_SEQ: '000', // 당초차수

    CONSTR_FLAG_1: '000001', // 공사용역구분 - 공사
    CONSTR_FLAG_2: '000002', // 공사용역구분 - 용역

    POTYPE_NORMAL: '000001', // 계약구분 : 일반계약
    POTYPE_UNIT: '000002', // 계약구분 : 단가계약

    PO_DOC_KIND_CONTRACT: '000001', // 계약서 종류 : 일반계약
    PO_DOC_KIND_CONTRACT_UNIT: '000002', // 계약서 종류 : 단가계약
    PO_DOC_KIND_ORDER: '000003', // 계약서 종류 : 발주서

    TAX: '000001', // 과세유형 : 과세
    TAX_EXEMPTION: '000002', // 과세유형 : 비과세
    TAX_DIRECT: '000003', // 과세유형 : 직접입력
    TAX_DIRECT_PERCENT: '000001', // 과세유형 : 직접입력(%)
    TAX_DIRECT_AMOUNT: '000002', // 과세유형 : 직접입력(원)

    SPOT_DSCR_N: '000001', // 현장설명 : 없음
    SPOT_DSCR_Y: '000002', // 현장설명 : 있음

    SPOT_DSCR_APPLY_Y: '000001', // 현설참가신청
    SPOT_DSCR_APPLY_N: '000002', // 현설참가신청(불참)
    SPOT_DSCR_CHK_Y: '000001', // 현설참여완료

    BID_PRTCP_APPLY_Y: '000001', // 입찰참가신청
    BID_PRTCP_APPLY_N: '000002', // 입찰참가신청(불참)

    QUOT_SBMT_N: '', // 응찰여부 : 미응찰
    QUOT_SBMT_Y: '000001', // 응찰여부 : 응찰

    RESULT_N: '', // 낙찰여부 : 탈락
    RESULT_Y: '000001', // 낙찰여부 : 낙찰

    PAY_DIRECT: '000001', // 대금지급방법 : 발주처 직접지급
    PAY_CASH: '000002', // 대금지급방법 : 현금, 어음
    PAY_DEFER: '000003', // 대금지급방법 : 유보

    STAMP_TAX_N: '000001', // 인지세유무 : 없음
    STAMP_TAX_Y: '000002', // 인지세유무 : 있음

    DLV_DATE: '000001', // 납품일자 : 일자
    DLV_PERIOD: '000002', // 납품일자 : 기간

    DELAY_RATE_N: '000001', // 지체상금율 - 없음
    DELAY_RATE_Y: '000002', // 지체상금율 - 있음

    FLAW_INSR_YEAR: '000001', // 하자보수 담보기간 - 년
    FLAW_INSR_MONTH: '000002', // 하자보수 담보기간 - 월

    DIRECT_Y: '000001', // 수기계약인 경우

    RETURN_Y: '000001', // 반송
    RETURN_TYPE_BUYER: '000001', // 건설사 반송
    RETURN_TYPE_SUPPLIER: '000002', // 협력사 반송
    RETURN_TYPE_SEND_CANCEL: '000003', // 송부취소
    RETURN_TYPE_ORDER_RETRIEVE: '000004', // 발주서 회수
    RETURN_TYPE_ORDER: '000005', // 발주서 반송

    STAMP_BUYER: '000001', // 인지세 납부대상 - 건설사
    STAMP_SUPPLIER: '000002', // 인지세 납부대상 - 협력사

    SCPAY_INSR_Y: '000001', // 하도급대금 지급보증금 - 해당
    SCPAY_INSR_N: '000002', // 하도급대금 지급보증금 - 해당없음

    UNIT_ORDER_Y: '000001', // 발주서여부 - Y

    RFQ_CANCEL_Y: '000001', // 입찰취소

    PO_SUFILE_APPROVAL_Y: '000001', // 제출서류심사결과 - 적합
    PO_SUFILE_APPROVAL_N: '000002', // 제출서류심사결과 - 부적합
  },
};

export default BIDDING_CONSTANTS;
