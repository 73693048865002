<template>
  <div :class="rootClass" :style="rootStyle">
    <label v-if="label" :id="labelId" :for="timePickerId" class="label" :style="labelStyle">{{ label }}</label>
    <span v-if="prefix" :id="prefixId" class="prefix" :style="prefixStyle">{{ prefix }}</span>
    <date-picker
      ref="timePicker"
      v-model="internalValue"
      :class="{
        datepicker: true,
        required: required,
      }"
      type="time"
      :input-class="timePickerInputClass"
      :input-attr="timePickerInputAttr"
      :placeholder="placeholder"
      :format="format"
      value-type="format"
      :disabled="!enable"
      @input="onInput"
      @input-error="onInputError"
      :required="required"
      :disabled-time="getDisabledTime"
    >
      <template #icon-clear>
        <slot name="icon-clear" />
      </template>
    </date-picker>
    <span v-if="suffix" v-bind:id="suffixId" class="suffix" :style="suffixStyle">{{ suffix }}</span>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import IuiLabelPrefixSuffixMixin from '@/components/Iui/mixins/IuiLabelPrefixSuffixMixin';
import {IuiValidatorMixin} from '@/plugins/IuiValidator';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';

export default {
  name: 'iui-timepicker',
  mixins: [IuiBaseMixin, IuiLayoutMixin, IuiLabelPrefixSuffixMixin, IuiValidatorMixin],
  components: {DatePicker},
  props: {
    name: {
      type: String,
    },
    value: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: String,
      default: '00:00',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    disabledTime: {
      type: Function,
      default: () => false,
    },
    hasSecond: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      format: 'HH:mm',
      internalValue: this.value,
      internalDisabledTime: '00:00',
      originalValue: null,
      isUser: false,
    };
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue;
    },
  },
  computed: {
    ...mapGetters(['datepickerCompMap', 'currentUrl']),

    timePickerId: {
      get() {
        return this.getId();
      },
    },

    // class
    rootClass: {
      get() {
        let obj = {
          'iui-datepicker': true,
          'iui-layout-debug': this.layoutDebug,
        };

        return obj;
      },
    },
    timePickerInputClass: {
      get() {
        let obj = {
          'mx-input': true,
          required: this.required,
          'is-valid-fail': !this.isValid,
        };
        if (this.group) {
          obj[`${this.group.grpNm}${this.group.seq}`] = true;
        }

        return obj;
      },
    },

    // style
    rootStyle: {
      get() {
        let obj = {
          width: undefined,
          height: undefined,
          flex: undefined,
        };

        if (this.width !== undefined) {
          obj.width = this.width;
          obj.flex = `0 0 this.width`;
        }
        if (this.height !== undefined) {
          obj.height = this.height;
        }

        return obj;
      },
    },
    timePickerInputAttr: {
      get() {
        return {id: this.timePickerId};
      },
    },
    errTitle() {
      return this.errorMessage instanceof Object ? this.errorMessage.title : 'Confirm';
    },
    errMessage() {
      return this.errorMessage instanceof Object ? this.errorMessage.message : this.errorMessage ?? '';
    },
    iconCalendarSlotVisible() {
      return this.$slots['icon-calendar'] !== undefined;
    },
  },
  created() {
    this.initTimepicker();
    if (this.group) {
      this.addTimepickerComponent();
    }
  },
  updated() {
    this.initLayout();
    this.initTimepicker();
    this.initValidator();
    this.checkParameter();
  },
  mounted() {
    const el = this.$refs.timePicker.$refs.input;
    el.setAttribute('data-error-title', this.errTitle);
    el.setAttribute('data-error-message', this.errMessage);
  },
  methods: {
    initTimepicker() {
      if (this.hasSecond) {
        this.format = this.format += ':ss';
      }

      this.validatorTargetDataProp = 'internalValue';
      this.validationTriggerEventName = ['valid'];
    },
    addTimepickerComponent() {
      const compMap = this.datepickerCompMap;

      if (!compMap[this.currentUrl]) {
        compMap[this.currentUrl] = {};
      }
      if (!compMap[this.currentUrl][this.group.grpNm]) {
        compMap[this.currentUrl][this.group.grpNm] = {};
      }
      if (Object.keys(compMap[this.currentUrl][this.group.grpNm]).indexOf(this.group.seq) != -1) {
        return;
      }
      compMap[this.currentUrl][this.group.grpNm][this.group.seq] = this;

      this.$store.commit('setTimepickerCompMap', compMap);
    },
    checkValidation(val) {
      if (!val || this.group == undefined || !this.group.hasOwnProperty('grpNm')) {
        return val;
      }

      const comps = this.datepickerCompMap[this.currentUrl][this.group.grpNm];
      for (let key in comps) {
        if (key == this.group.seq) {
          continue;
        }

        const compareVal = moment(comps[key].internalValue, this.format);
        const date = moment(val, this.format);
        if (
          compareVal &&
          ((key < this.group.seq && compareVal.isAfter(date)) || (key > this.group.seq && compareVal.isBefore(date)))
        ) {
          comps[key].onInput(val);
        }
      }

      return val;
    },
    async onInput(v) {
      this.isUser = true;
      v = this.checkValidation(v == null ? '' : v);
      this.internalValue = v;

      this.$emit('update:value', v);
      this.$emit('change', v);
      this.$emit('valid', v);
    },
    onInputError(v) {
      if (/^\d{4}$/.test(v)) {
        this.onInput(v.substring(0, 2) + ':' + v.substring(2, 4));
      }
    },
    getDisabledTime(date) {
      if (!this.internalValue) {
        date = new Date(date.setMinutes(0));
      }

      if (this.format === 'HH:mm') {
        date = new Date(date.setSeconds(0));
      }

      return this.disabledTime(date);
    },
    checkParameter() {
      if (!this.isUser) {
        this.originalValue = this.internalValue;
      }

      if (this.originalValue !== this.internalValue) {
        this.$refs.timePicker.$el.setAttribute('data-changed', '');
      } else {
        this.$refs.timePicker.$el.removeAttribute('data-changed');
      }
      this.isUser = false;
    },
  },
};
</script>
<style scoped>
.mx-datepicker {
  min-width: 90px;
}

>>> .mx-input {
  width: 90px !important;
}
</style>
