export default that => {
  return {
    Cfg: {
      CanEdit: 1,
      HeaderMerge: 6,
      IgnoreFocused: 1,
    },
    Cols: [
      {
        Header: ['내역\n번호', '내역\n번호'],
        Name: 'itemSeq',
        Type: 'Text',
        Align: 'Center',
        Width: 60,
        CanEdit: 0,
      },
      {
        Header: ['자원\n구분', '자원\n구분'],
        Name: 'costType',
        Type: 'Text',
        Align: 'Center',
        Width: 60,
        CanEdit: 0,
      },
      {
        Header: ['코드', '코드'],
        Name: 'itemCode2',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 130,
        CanEdit: 0,
      },
      {
        Header: ['명칭', '명칭'],
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
        CanEdit: 0,
      },
      {
        Header: ['규격', '규격'],
        Name: 'ssize',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
        CanEdit: 0,
      },
      {
        Header: ['단위', '단위'],
        Name: 'unit',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 80,
        CanEdit: 0,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: ['수량', '수량'],
        Name: 'qty',
        Extend: that.$ibsheetHelper.preset.quantity,
        RelWidth: 1,
        MinWidth: 100,
        CanEditFormula: function(fr) {
          return fr.Row['formula'] ? 0 : 1;
        },
        OnChange: e => {
          if (e.row.costType == $getConstants('COST_TYPE_M').code) {
            e.sheet.setValue(e.row, 'matAmt', e.row.qty * e.row.matPrice, 1);
          } else if (e.row.costType == $getConstants('COST_TYPE_L').code) {
            e.sheet.setValue(e.row, 'labAmt', e.row.qty * e.row.labPrice, 1);
          } else if (
            e.row.costType == $getConstants('COST_TYPE_E').code ||
            e.row.costType == $getConstants('COST_TYPE_A').code
          ) {
            e.sheet.setValue(e.row, 'equipAmt', e.row.qty * e.row.equipPrice, 1);
          }
          e.sheet.setValue(e.row, 'totalAmt', e.row.qty * e.row.totalPrice, 1);
          that.changeArithmetic();
        },
      },
      {
        Header: ['재료비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'matPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        Type: 'Int',
        RelWidth: 1,
        MinWidth: 100,
        CanEditFormula: fr => (fr.Row['formula'] ? 0 : fr.Row['costType'] === $getConstants('COST_TYPE_M').code),
        OnChange: e => {
          e.sheet.setValue(e.row, 'matAmt', e.row.qty * e.row.matPrice, 1);
          e.sheet.setValue(e.row, 'totalPrice', e.row.matPrice + e.row.labPrice + e.row.equipPrice, 1);
          e.sheet.setValue(e.row, 'totalAmt', e.row.qty * e.row.totalPrice, 1);
          that.changeArithmetic();
        },
      },
      {
        Header: ['재료비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'matAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Type: 'Int',
        RelWidth: 1,
        MinWidth: 100,
        CanEdit: 0,
      },
      {
        Header: ['노무비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'labPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        Type: 'Int',
        RelWidth: 1,
        MinWidth: 100,
        CanEditFormula: fr => (fr.Row['formula'] ? 0 : fr.Row['costType'] === $getConstants('COST_TYPE_L').code),
        OnChange: e => {
          e.sheet.setValue(e.row, 'labAmt', e.row.qty * e.row.labPrice, 1);
          e.sheet.setValue(e.row, 'totalPrice', e.row.matPrice + e.row.labPrice + e.row.equipPrice, 1);
          e.sheet.setValue(e.row, 'totalAmt', e.row.qty * e.row.totalPrice, 1);
          that.changeArithmetic();
        },
      },
      {
        Header: ['노무비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'labAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Type: 'Int',
        RelWidth: 1,
        MinWidth: 100,
        CanEdit: 0,
      },
      {
        Header: ['경비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'equipPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        Type: 'Int',
        RelWidth: 1,
        MinWidth: 100,
        CanEditFormula: fr =>
          fr.Row['formula']
            ? 0
            : fr.Row['costType'] === $getConstants('COST_TYPE_E').code ||
              fr.Row['costType'] === $getConstants('COST_TYPE_A').code,
        OnChange: e => {
          e.sheet.setValue(e.row, 'equipAmt', e.row.qty * e.row.equipPrice, 1);
          e.sheet.setValue(e.row, 'totalPrice', e.row.matPrice + e.row.labPrice + e.row.equipPrice, 1);
          e.sheet.setValue(e.row, 'totalAmt', e.row.qty * e.row.totalPrice, 1);
          that.changeArithmetic();
        },
      },
      {
        Header: ['경비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'equipAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Type: 'Int',
        RelWidth: 1,
        MinWidth: 100,
        CanEdit: 0,
      },
      {
        Header: ['합계', {Value: '단가', Color: '#dff9fb'}],
        Name: 'totalPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        Type: 'Int',
        RelWidth: 1,
        MinWidth: 100,
        CanEdit: 0,
      },
      {
        Header: ['합계', {Value: '금액', Color: '#dff9fb'}],
        Name: 'totalAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Type: 'Int',
        RelWidth: 1,
        MinWidth: 100,
        CanEdit: 0,
      },
      {
        Name: 'itemCode1',
        Visible: 0,
      },
      {
        Name: 'formula',
        Visible: 0,
      },
    ],
  };
};
