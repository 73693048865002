export default that => {
  return {
    Cfg: {CanEdit: 0},
    Def: {Row: {NoColor: 1, CanFocus: 0}},
    Cols: [
      {Header: '순번', Name: 'seq', Type: 'Text', Align: 'center', Width: 45},
      {Header: '전문공종', Name: 'wcode', Type: 'Text', RelWidth: 1, Format: $getConstants('W_CODE').code},
      {Header: '전문세부공종', Name: 'lgCode', Type: 'Text', RelWidth: 1, Format: $getConstants('LG_CODE').code},
      {Header: '비고', Name: 'rmk', Type: 'Text', RelWidth: 1},
    ],
  };
};
