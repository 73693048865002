<template>
  <div class="fr">
    금액단위
    <iui-select
      :p-code="$getConstants('AMOUNT_UNIT').code"
      :value="internalAmountUnit"
      @change="value => onChange(value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    amountUnit: {
      type: String,
    },
  },
  data() {
    return {
      internalAmountUnit: '',
    };
  },
  created() {
    this.internalAmountUnit = this.amountUnit;
  },
  methods: {
    onChange(value) {
      this.internalAmountUnit = value;
      this.$emit('change', value);
    },
  },
};
</script>
