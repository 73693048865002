<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text
        type="search"
        name="searchNm"
        @change="setSearchInfo({searchNm: $event.target.value.replace(/^ /gi, '')})"
        @enter="onSearch()"
      />
      <iui-button value="검색" @click="onSearch()" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onRenderFirstFinish,
        onDblClick: this.grid_onDblClick,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>
<script>
/**
 * 사원조회 팝업
 * 사용 : 가동장비등록
 *        계약정보등록(tab0) > 상세정보
 *        입찰관련 파일등...
 *
 * */
import options from './sheetOptions/WorkerListPopupSheet.js';
import {selectWorkerList} from '@/view/user/Worker/api/worker.js';

export default {
  props: {
    uid: {
      type: Number,
    },
    laborDs: {
      // 근로자구분
      type: String,
      default: undefined,
    },
    laborDsList: {
      type: Array,
      default: undefined,
    },
  },
  created() {
    this.addEvent([{name: 'WorkerListPopup_callbackData', func: this.onFocusedRowData}]);
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid + 'ur01',
      loadSearchData: [],
      Options: options(this),
      searchInfo: {
        searchNm: '',
      },
    };
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    setSearchInfo(payload) {
      for (let key in this.searchInfo) {
        if (payload && payload.hasOwnProperty(key)) {
          this.searchInfo[key] = payload[key];
        }
      }
    },
    async onSearch() {
      let param = {
        pgmCd: this.pgmCd,
        ...this.searchInfo,
        laborDs: this.laborDs,
        laborDsList: this.laborDsList,
      };
      const response = await selectWorkerList(param);
      this.loadSearchData = response.data;
    },
    onFocusedRowData(callbackFunction) {
      if (typeof callbackFunction == 'function') {
        const focusedRow = this.sheet.getFocusedRow();
        if (focusedRow == null) {
          const title = this.$parent.$parent.internalTitle || '근로자정보';
          const message = '선택된 항목이 없습니다.';
          this.$alert({title, message});
          return;
        }
        callbackFunction(focusedRow);
      }
    },
    grid_onDblClick(e) {
      if (e.row.Kind == 'Data') {
        this.callEvent({name: `DetailInfo_onHideModal`});
        this.$emit('onDblClick');
      }
    },
  },
};
</script>

<style></style>
