import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
export default that => {
  const preset = that.$ibsheetHelper.preset;

  return {
    Cfg: {CanSort: 0, SpaceForDefaultValue: 1},
    Def: {
      Row: {NoColor: 2, CanFocus: 0},
    },
    Cols: [
      {Header: '선택', Name: 'rdo', Type: 'Radio', Width: 45, HRadio: 0, CanEdit: false},
      {
        Header: '낙찰여부',
        Name: 'resultYn',
        Type: 'Text',
        Align: 'center',
        Width: 80,
        Visible: false,
        DefaultValue: '-',
        CustomFormat: v => (v === BIDDING_CONSTANTS.CODES.RESULT_Y ? '낙찰' : '탈락'),
      },
      {Header: '순위', Name: 'rowNum', Type: 'Text', Align: 'Center', Width: 45, CanEdit: false},
      {
        Header: '협력사명',
        Name: 'frmNm',
        Type: 'Text',
        RelWidth: 1.2,
        MinWidth: 200,
        CanEdit: false,
        TextStyle: 5,
        TextColor: '#FF0000',
        Cursor: 'Pointer',
      },
      {
        Header: '담당자명 (연락처)',
        Name: 'name',
        Type: 'Text',
        Align: 'Center',
        RelWidth: 0.5,
        MinWidth: 160,
        Formula: fr => `${fr.Row['icprNm']}${fr.Row['icprPhone'] ? ` (${fr.Row['icprPhone']})` : ''}`,
      },
      {
        Header: '현설참여여부',
        Name: 'spotDscrAt',
        Type: 'Text',
        Align: 'Center',
        Width: 100,
        CanEdit: false,
        Formula: fr =>
          fr.Row['spotDscrYn'] === BIDDING_CONSTANTS.CODES.SPOT_DSCR_N ? '-' : fr.Row['spotDscrChkYn'] ? 'O' : 'X',
      },
      {
        Header: '응찰여부',
        Name: 'quotSbmtYn',
        Type: 'Text',
        Align: 'Center',
        Width: 100,
        CanEdit: false,
        DefaultValue: '-',
        CustomFormat: v => (v === BIDDING_CONSTANTS.CODES.QUOT_SBMT_Y ? 'O' : 'X'),
      },
      {Header: '예가대비(%)', Name: 'bidPlanAmtRate', Type: 'Text', Width: 100, Extend: preset.rate, CanEdit: false},
      {Header: '최저가대비(%)', Name: 'minTotAmtRate', Type: 'Text', Width: 100, Extend: preset.rate, CanEdit: false},
      {
        Header: '총합계금액\n(공급가+부가세)',
        Name: 'totAmt',
        Type: 'Text',
        RelWidth: 0.5,
        MinWidth: 120,
        Extend: preset.amount,
        CanEdit: false,
      },
      {Name: 'rfqNo', Visible: false},
      {Name: 'cusCd', Visible: false},
      {Name: 'lastBidRnd', Visible: false},
      {Name: 'amt', Visible: false},
      {Name: 'vatAmt', Visible: false},
      {Name: 'icprNm', Visible: false},
      {Name: 'icprPhone', Visible: false},
      {Name: 'cusPgmCd', Visible: false},
      {Name: 'spotDscrYn', Visible: false},
      {Name: 'spotDscrApplyYn', Visible: false},
      {Name: 'spotDscrChkYn', Visible: false},
      {Name: 'resultYn', Visible: false},
    ],
  };
};
