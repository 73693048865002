<template>
  <div :style="{width: internalWidth, height: internalHeight}" id="OZViewerDiv">
    <div id="OZViewer"></div>
  </div>
</template>

<script>
import {setSign} from '@/view/approval/draft/lineUtil.js';

export default {
  data() {
    return {
      internalWidth: '100vw',
      internalHeight: '100vh',

      ozRptNm: '',
      ozContents: {},
      ozInputJsonStr: {},
      OZViewer: '',

      searchParam: {
        pgmCd: '',
        empNo: '',
        laborDs: '',
        accessToken: '',
      },
    };
  },

  created() {
    window.onResize = this.onResize;
    window.onSign = this.onSign;
    window.onInitOZ = this.onInitOZ;
    window.getInputJson = this.getInputJson;
    window.setAppSign = this.setAppSign;
  },

  methods: {
    onInitOZ(param) {
      window.SetOZParamters_OZViewer = () => {
        let ozParamStr = '';
        let ozSap = ';OZ;';
        let ozServer = '/eform/server';

        let content = param.ozContents ? JSON.parse(param.ozContents) : {};
        let contentKeys = Object.keys(content);

        for (let i in contentKeys) {
          ozParamStr += `${ozSap}connection.args${Number(i) + 1}=${contentKeys[i]}=${content[contentKeys[i]]}`;
        }

        ozParamStr += `${ozSap}export.mode=false`;
        ozParamStr += `${ozSap}connection.servlet=${ozServer}`;
        ozParamStr += `${ozSap}connection.reportname=${param.ozRptNm}`;
        ozParamStr += `${ozSap}connection.pcount=${contentKeys.length}`; //ozr의 입력컴포넌트에 값을 세팅
        ozParamStr += `${ozSap}connection.inputjson=${param.ozInputJsonStr}`; //ozr의 입력컴포넌트에 값을 세팅

        ozParamStr += `${ozSap}viewer.progresscommand=true`;
        ozParamStr += `${ozSap}viewer.ignore_disable_color_inputcomponent=true`;
        ozParamStr += `${ozSap}viewer.usetoolbar=false`;
        ozParamStr += `${ozSap}viewer.viewmode=fittowidth`;

        let ozParams = ozParamStr.split(ozSap);
        this.OZViewer = document.getElementById('OZViewer');

        for (let key in ozParams) {
          let paramStr = ozParams[key];
          if (paramStr.indexOf('=') > -1) {
            let paramK = paramStr.substring(0, paramStr.indexOf('='));
            let paramV = paramStr.substring(paramStr.indexOf('=') + 1);
            this.OZViewer.sendToActionScript(paramK, paramV);
          }
        }

        this.OZViewer.sendToActionScript('eform.signpad_viewtype', 'scrollable');
        this.OZViewer.sendToActionScript('eform.signpad_type', 'dialog');
        this.OZViewer.sendToActionScript('eform.signpad_keepbackgroundcolor', 'true');
        this.OZViewer.sendToActionScript('eform.signpad_iconposition', 'viewer_top');

        this.OZViewer.sendToActionScript('viewer.viewmode', 'fittocontents'); // 너비에 맞추기
        this.OZViewer.sendToActionScript('viewer.smartframesize', 'true'); // 오즈 뷰어 창의 크기를 보고서 한 페이지 크기에 맞추어 표시할지 여부

        this.OZViewer.sendToActionScript('viewer.framewidth', '150px');
        this.OZViewer.sendToActionScript('viewer.frameheight', '300px');

        return true;
      };

      window.OZProgressCommand_OZViewer = (step, state) => {
        if (step == 4 && state == 2) {
          // 보고서바인딩이 완료된 시점
          OZViewer.Script('disable_input_all');
          document.querySelectorAll('textarea').forEach(item => {
            item.readOnly = true;
          });
          document.querySelectorAll('input').forEach(item => {
            item.readOnly = true;
          });
          document.querySelectorAll('select').forEach(item => {
            item.readOnly = true;
          });
        }
      };

      // oz report 팝업 제거
      let interval;
      const removePopup = () => {
        const popup = document.querySelector('.ui-simpledialog-container');
        if (popup) {
          document.querySelector('.ui-simpledialog-container').remove();
          clearInterval(interval);
        }
      };
      interval = window.setInterval(removePopup, 500);

      start_ozjs('OZViewer', '/eform/ozhviewer/', true);
    },
    onResize(width, height) {
      this.internalWidth = `${parseInt(width - 45)}px`;
      this.internalHeight = `${parseInt(height - 45)}px`;
    },

    onSign() {
      let eformData = JSON.parse(this.OZViewer.GetInformation('INPUT_JSON_ALL'));
      window.SignProc.postMessage(eformData.contSignData);
    },

    setAppSign(param) {
      const LINE = setSign(param);
      return JSON.parse(LINE);
    },

    getInputJson() {
      return OZViewer.GetInformation('INPUT_JSON');
    },
  },
};
</script>

<style></style>
