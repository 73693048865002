<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-select :items="searchGbItems" :value="searchGb" @change="onChange" />
      <iui-datepicker prefix="일자" suffix="~" :value.sync="fromDate" :group="{grpNm: 'searchDate', seq: 1}" />
      <iui-datepicker :value.sync="toDate" :group="{grpNm: 'searchDate', seq: 2}" />
      <label>문서제목</label>
      <iui-text type="search" :value.sync="searchText" @keyup.enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onRenderFirstFinish,
        onSearch,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import options from '@/view/approval/draft/components/sheetOption/AttachDocPopSheet.js';
import {selectAttachDocList} from '@/view/approval/api/appDoc.js';

export default {
  data() {
    return {
      Options: $addColCheckbox(options(this)),
      loadSearchData: [],

      fromDate: '',
      toDate: '',
      searchGb: $getConstants('SEARCH_GB').code,
      searchGbItems: [
        {text: $getConstants('SEARCH_GB1').name, value: $getConstants('SEARCH_GB1').code},
        {text: $getConstants('SEARCH_GB2').name, value: $getConstants('SEARCH_GB2').code},
        {text: $getConstants('SEARCH_GB3').name, value: $getConstants('SEARCH_GB3').code},
      ],
      searchText: '',
    };
  },
  created() {
    this.addEvent({name: 'attachDocList', func: this.attachDocList});
  },
  methods: {
    async onSearch() {
      let param = {
        searchGb: this.searchGb,
        fromDate: this.fromDate,
        toDate: this.toDate,
        searchText: this.searchText,
        empNo: this.userInfo.empNo,
      };
      const response = await selectAttachDocList(param);
      this.loadSearchData = response.data;
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    onChange(value) {
      this.searchGb = value;
      this.onSearch();
    },
    attachDocList(callback) {
      let attachList = this.sheet.getRowsByChecked('chk');
      if (typeof callback === 'function') {
        callback(attachList);
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
  },
};
</script>

<style></style>
