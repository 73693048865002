<template>
  <pmis-page
    ref="page"
    :search-company-visible="false"
    :search-project-visible="false"
    @search-click="onSearch"
    @save-click="onSave"
    @delete-click="onDelete"
    ><iui-container-new type="css-flex">
      <i-row min-height="180px">
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>구매상담현황</template>
            <PurchsCnsltSttus />
          </pmis-tab-box>
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>상세정보</template>
            <DetailInfo />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from '../store/PurchsCnslt.js';
import PurchsCnsltSttus from './components/PurchsCnsltSttus.vue';
import DetailInfo from './components/PurchsCnsltDetailInfo.vue';
export default {
  components: {
    PurchsCnsltSttus,
    DetailInfo,
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['consultInfo']);
  },
  created() {
    this.canNew = false;
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'PurchsCnsltSttus_onSearch', param: null});
    },
    onSave() {
      this.callEvent({name: 'PurchsCnsltDetailInfo_onSave', param: null});
    },
    async onDelete() {
      if (!(await this.$confirm({title: '구매상담정보 삭제', message: '구매상담정보를 삭제하시겠습니까?'}))) return;

      let param = {reqDt: this.consultInfo.reqDt, seq: this.consultInfo.seq};

      axios.post('/purchsCnslt/deletePurchsCnsltInfo', param).then(response => {
        if (response.status == 200) {
          this.onSearch();
        }
      });
    },
  },
};
</script>

<style></style>
