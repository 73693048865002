const state = {
  lbrcTeamInfo: {
    pgmCd: '',
    labCode: '', //노무팀코드
    labName: '', //노무팀명
    bsnNo: '', //사업자번호
    labMNm: '', //팀장명
    labMRegiNo: '', //팀장주민번호
    labMPhone: '', //전화번호
    email1: '', //이메일1
    email2: '', //이메일2
    delYn: '', //삭제flag = 000001 : 삭제상태
    bsnNo1: '',
    bsnNo2: '',
    bsnNo3: '',
    labMRegiNo1: '',
    labMRegiNo2: '',
    teamMemberCnt: 0,
  },

  searchInfo: {
    pgmCd: '',
    frmNm: '',
    searchNm: '',
  },

  focusKey: '', // 노무팀코드
};
const getters = {
  lbrcTeamInfo: state => state.lbrcTeamInfo,
  searchInfo: state => state.searchInfo,
  focusKey: state => state.focusKey,
};
const mutations = {
  setLbrcTeamInfo(state, payload) {
    for (let key in state.lbrcTeamInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.lbrcTeamInfo[key] = payload[key];

        if (key == 'labMRegiNo') {
          state.lbrcTeamInfo['labMRegiNo1'] = String(payload[key]).slice(0, 6);
          state.lbrcTeamInfo['labMRegiNo2'] = String(payload[key]).slice(6);
        }
        if (key == 'labMRegiNo1' || key == 'labMRegiNo2') {
          state.lbrcTeamInfo['labMRegiNo'] = state.lbrcTeamInfo['labMRegiNo1'] + state.lbrcTeamInfo['labMRegiNo2'];
        }
        if (key == 'bsnNo') {
          state.lbrcTeamInfo['bsnNo1'] = String(payload[key]).slice(0, 3);
          state.lbrcTeamInfo['bsnNo2'] = String(payload[key]).slice(3, 5);
          state.lbrcTeamInfo['bsnNo3'] = String(payload[key]).slice(5);
        }
        if (key == 'bsnNo1' || key == 'bsnNo2' || key == 'bsnNo3') {
          state.lbrcTeamInfo['bsnNo'] =
            state.lbrcTeamInfo['bsnNo1'] + state.lbrcTeamInfo['bsnNo2'] + state.lbrcTeamInfo['bsnNo3'];
        }
      }
    }
  },
  initLbrcTeamInfo(state) {
    for (let key in state.lbrcTeamInfo) {
      state.lbrcTeamInfo[key] = '';
    }
  },
  setSearchInfo(state, payload) {
    for (let key in state.searchInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  setFocusKey(state, payload) {
    state.focusKey = payload;
  },
  initFocusKey(state) {
    state.focusKey = '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
