export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Def: {
      Row: {NoColor: 2, CanFocus: 1, CanFormula: 1, CalcOrder: 'matAmt,labAmt,equipAmt,costAmt,sumUprc,sumAmt'},
    },
    LeftCols: [
      {Header: ['공종명', '공종명'], Name: 'srvNm', Type: 'Text', RelWidth: 1, MinWidth: 160, CanEdit: 0},
      {Header: ['내역명', '내역명'], Name: 'itemNm', Type: 'Text', RelWidth: 1, MinWidth: 160, CanEdit: 0},
      {Header: ['규격', '규격'], Name: 'sizeNm', Type: 'Text', RelWidth: 1, MinWidth: 160, CanEdit: 0},
      {
        Header: ['단위', '단위'],
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        Width: 50,
        Format: $getConstants('UNIT').code,
        CanEdit: 0,
      },
      {Header: ['수량', '수량'], Name: 'qty', Type: 'Text', Width: 80, Extend: preset.quantity, CanEdit: 0},
    ],
    Cols: [
      {
        Header: ['단가', '재료비'],
        Name: 'matUprc',
        Type: 'Text',
        Width: 90,
        Color: '#E0E0F8',
        Extend: preset.unitAmount,
      },

      {
        Header: ['단가', '노무비'],
        Name: 'labUprc',
        Type: 'Text',
        Width: 90,
        Color: '#E0E0F8',
        Extend: preset.unitAmount,
      },

      {
        Header: ['단가', '장비비'],
        Name: 'equipUprc',
        Type: 'Text',
        Width: 90,
        Color: '#E0E0F8',
        Extend: preset.unitAmount,
      },

      {
        Header: ['단가', '경비'],
        Name: 'costUprc',
        Type: 'Text',
        Width: 90,
        Color: '#E0E0F8',
        Extend: preset.unitAmount,
      },

      {
        Header: ['합계', '단가'],
        Name: 'sumUprc',
        Type: 'Text',
        Width: 90,
        Extend: preset.unitAmount,
        Formula: fr => fr.Row['matUprc'] + fr.Row['labUprc'] + fr.Row['equipUprc'] + fr.Row['costUprc'],
      },
      {
        Header: ['합계', '금액'],
        Name: 'sumAmt',
        Type: 'Text',
        Width: 100,
        Extend: preset.amount,
        Formula: fr => fr.Row['qty'] * fr.Row['sumUprc'],
      },
      {Header: ['비고', '비고'], Name: 'srvRmrk', Type: 'Text', MinWidth: 120, RelWidth: 1, Color: '#E0E0F8'},

      {Name: 'matAmt', Visible: 0, Formula: fr => fr.Row['qty'] * fr.Row['matUprc']},
      {Name: 'labAmt', Visible: 0, Formula: fr => fr.Row['qty'] * fr.Row['labUprc']},
      {Name: 'equipAmt', Visible: 0, Formula: fr => fr.Row['qty'] * fr.Row['equipUprc']},
      {Name: 'costAmt', Visible: 0, Formula: fr => fr.Row['qty'] * fr.Row['costUprc']},
      {Name: 'rfqSeq', Visible: 0},
      {Name: 'wcode', Visible: 0},
      {Name: 'headCode', Visible: 0},
      {Name: 'midCode', Visible: 0},
      {Name: 'itemSeq', Visible: 0},
      {Name: 'itemCode', Visible: 0},
      {Name: 'costType', Visible: 0},
    ],
  };
};
