<template>
  <pmis-tab-box>
    <template v-slot:title>정산금액</template>
    <p class="bu_title">금월정산금액</p>
    <form onsubmit="return false;">
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header required width="110px">공급가</i-col-header>
          <i-col>
            <iui-text name="amt" type="amount" :value="detailInfo.amt" width="145px" :enable="false" required />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>부가세</i-col-header>
          <i-col>
            <iui-text
              name="vatAmt"
              type="amount"
              :value="detailInfo.vatAmt"
              @change="setCalculateVatAmt(Number($event.target.value) || 0)"
              width="145px"
              :enable="cud === 1"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>비과세</i-col-header>
          <i-col>
            <iui-text
              name="taxexe"
              type="amount"
              :value="detailInfo.taxexe"
              @change="setCalculateTaxexe(Number($event.target.value) || 0)"
              width="145px"
              :enable="cud === 1"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header required>합계</i-col-header>
          <i-col>
            <iui-text name="sumAmt" type="amount" :value="detailInfo.sumAmt" width="145px" :enable="false" readonly />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>세금계산서번호</i-col-header>
          <i-col>
            <iui-text
              type="text"
              name="billNo"
              :value="detailInfo.billNo"
              width="150px"
              max-length="20"
              :readonly="true"
              :css="{cursor: 'pointer', 'text-decoration': 'underline', 'text-align': 'center'}"
              @click="onClick_billNo"
            />
            <pmis-file-list
              name="flNo"
              id="file0"
              flNoCol="flNo"
              title="첨부파일"
              title-visible
              :modal="true"
              :hidden="true"
              :toolbar-visible="true"
              :fileNumber="fileNumber"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>계산서번호</i-col-header>
          <i-col>
            <iui-text
              type="text"
              name="billTaxNo"
              :value="detailInfo.billTaxNo"
              width="150px"
              max-length="20"
              :readonly="true"
              :css="{cursor: 'pointer', 'text-decoration': 'underline', 'text-align': 'center'}"
              @click="onClick_billNo"
            />
            <pmis-file-list
              name="flTaxNo"
              id="file1"
              flNoCol="flTaxNo"
              title="첨부파일"
              title-visible
              :modal="true"
              :hidden="true"
              :toolbar-visible="true"
              :fileNumber="fileNumber"
            />
          </i-col>
        </i-row>
      </iui-container-new>
    </form>

    <tax-invoice-pop :billNo="billNo" :open.sync="propsOpen" pageGubun="mat" />
  </pmis-tab-box>
</template>

<script>
import TaxInvoicePop from '@/view/bidding/components/TaxInvoicePop.vue';
export default {
  components: {TaxInvoicePop},
  data: function() {
    return {
      propsOpen: false,
      fileNumber: 0,
      billNo: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, 'material', ['detailInfo']);
    $mapActions(this, 'material', ['setCalculateVatAmt', 'setCalculateTaxexe']);
  },
  created() {},
  methods: {
    onClick_billNo(e) {
      if (e.target.value) {
        this.billNo = e.target.value;
        this.propsOpen = true;
      }
    },
  },
};
</script>

<style scoped></style>
