/** 계약목록 */
export const selectPoListBySpotDeptcd = searchOptions => axios.post('/po/selectPoListBySpotDeptcd', searchOptions);
/** 계약차수목록 */
export const selectPoSeqList = poNo => axios.post('/po/selectPoSeqList', {poNo});
/** 계약정보 */
export const selectPoInfos = (poNo, contSeq) => axios.post('/po/selectPoInfos', {poNo, contSeq});

/** 입찰완료 리스트 */
export const selectCompletedRfqList = searchOptions => axios.post('/po/selectCompletedRfqList', searchOptions);
/** 입찰정보 */
export const selectPoByRfqInfo = rfqNo => axios.post('/po/selectPoByRfqInfo', {rfqNo});

/** 리포트용 계약서 정보 조회 */
export const selectContractContentsForPrint = (poNo, contSeq) =>
  axios.post('/po/selectContractContentsForPrint', {poNo, contSeq});

/** 계약품의 정보 조회 */
export const generateApprovalContent = (poNo, contSeq) => axios.post('/po/generateApprovalContent', {poNo, contSeq});

/** 인지세 정보 조회 */
export const selectStampTaxInfos = (poNo, contSeq) => axios.post('/po/selectStampTaxInfos', {poNo, contSeq});

/** 계약정보 생성 */
export const createPo = (param, afterValidation) => $save('/po/create', param, {afterValidation});
/** 계약정보 저장 */
export const savePo = (param, afterValidation) => $save('/po/save', param, {afterValidation});
/** 계약정보(차수) 삭제 */
export const deletePo = (poNo, contSeq) => axios.post('/po/delete', {poNo, contSeq});
/** 파일삭제 후 업무 파일번호 초기화 */
export const updatePoFlNo = (poNo, contSeq, flNo) => axios.post('/po/updatePoFlNo', {poNo, contSeq, flNo});

/** 다음 프로세스 처리하기 전 계약상태 체크 */
export const selectCheckPoState = param => axios.post('/po/selectCheckPoState', param);

/** 계약서 수정중 상태로 변경 */
export const mdfyContract = (poNo, contSeq) => axios.post('/po/mdfyContract', {poNo, contSeq});
/** 계약서 송부 */
export const sendPo = (poNo, contSeq) => axios.post('/po/send', {poNo, contSeq});
/** 계약서 송부취소 */
export const sendCancel = (poNo, contSeq) => axios.post('/po/sendCancel', {poNo, contSeq});
/** 계약서반송(건설사) */
export const rejectBuyer = (poNo, contSeq, reason) => $save('/po/rejectBuyer', {poNo, contSeq, reason});
/** 계약서반송(공급사) */
export const rejectSupplier = (poNo, contSeq, reason) => $save('/po/rejectSupplier', {poNo, contSeq, reason});
/** 계약서 반송정보 */
export const selectPoRejectInfo = (poNo, contSeq) => axios.post('/po/selectPoRejectInfo', {poNo, contSeq});

/** 구비서류제출 */
export const submitNeedFile = (poNo, contSeq, submitFileList) =>
  axios.post('/po/submitNeedFile', {poNo, contSeq, submitFileList});
/** 서류심사결과 등록 */
export const updateSuFileApprovalResult = param => axios.post('/po/updateSuFileApprovalResult', param);

/** 전자서명(협력사) */
export const sign = param => axios.post('/po/sign', param);
/** 전자서명(건설사) */
export const addSign = param => axios.post('/po/addSign', param);

/** 차수복사 */
export const copyPo = (poNo, contSeq) => axios.post('/po/copy', {poNo, contSeq});

/** 오프라인 인지세 고유번호 체크 */
export const selectCheckStampNo = stampNo => axios.post('/po/selectCheckStampNo', {stampNo});
/** 오프라인 인지세 등록 */
export const insertOfflineStampInfo = (param, afterValidation) =>
  $save('/po/insertOfflineStampInfo', param, {afterValidation});
/** 오프라인 인지세 저장 */
export const updateOfflineStampInfo = (param, afterValidation) =>
  $save('/po/updateOfflineStampInfo', param, {afterValidation});
/** 오프라인 인지세 삭제 */
export const deleteOfflineStampInfo = deleteStampList => axios.post('/po/deleteOfflineStampInfo', {deleteStampList});
/** 인지세납부관리대장 */
export const selectStampTaxRegistList = param => axios.post('/po/selectStampTaxRegistList', param);
export const saveInsrFile = param => $save('/po/saveInsrFile', param);
