<template>
  <aside>
    <div class="nav_wrap">
      <a href="#" class="btn_big_close" @click.prevent.self @click="sideToggle" />
      <span class="blind">열고/닫기</span>
    </div>
    <nav id="lnb">
      <h1>
        <a href="/main" class="logo" v-if="!logoUrl">
          <span class="blind">PMIS</span>
        </a>
        <a href="/main" class="company_logo" v-else>
          <img :src="logoUrl" alt="logo" />
        </a>
      </h1>
      <h2 class="lnb_tit">{{ selectedMenuNm }}</h2>
      <ul v-if="Items.length">
        <li v-for="m in Items" v-bind:key="m.mnuCd" :class="{m_selected: m.isSelected}">
          <a
            href="#"
            @click.prevent.self
            @click="onSelectItems"
            :data-mnu-cd="m.mnuCd"
            :class="[m.isSelected ? 'm_on' : 'm_off']"
            >{{ m.mnuNm }}</a
          >
          <ul v-if="m.Items && m.Items.length && m.isSelected">
            <li v-for="sM in m.Items" v-bind:key="sM.mnucd">
              <a href="#" @click.prevent.self @click="mnu_click(sM)" :class="[sM.isSelected ? 's_on' : 's_off']">
                {{ sM.mnuNm }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <!-- lnb end -->
  </aside>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

const MAX_URLS_LENGTH = 10;

export default {
  name: 'iui-side',
  data() {
    return {
      FILE_URL:
        location.protocol.indexOf('https') !== -1 ? process.env.VUE_APP_FILE_URLS : process.env.VUE_APP_FILE_URL,
      logoUrl: '',
    };
  },
  created() {
    this.getLogo();
  },
  computed: {
    ...mapGetters('menu', ['Items', 'selectedMenuNm']),
    ...mapGetters('mdi', ['urls']),
  },
  methods: {
    ...mapActions('mdi', ['addMDI']),
    ...mapActions('layout', ['sideToggle']),
    ...mapActions('menu', ['onSelectItems']),
    openSide: function() {},
    closeSide: function() {},

    async getLogo() {
      this.logoUrl = this.userInfo.lgFlNo
        ? `${this.FILE_URL}/file/getImage?pgmCd=${this.pgmCd}&flNo=${this.userInfo.lgFlNo}`
        : '';
    },
    async mnu_click(data) {
      if (!this.urls.find(item => item.mnuCd == data.mnuCd) && this.urls.length >= MAX_URLS_LENGTH) {
        await this.$alert(
          `화면은 최대 ${MAX_URLS_LENGTH}개까지 열 수 있습니다.<br /><br />다른 화면을 닫은 후 다시 열어 주십시오.`
        );
        return;
      } else {
        if (!data.authList) {
          const response = await axios.post('/menu/selectMenuAuthList', {pgmCd: this.pgmCd, mnuCd: data.mnuCd});
          data.authList = response.data;
        }
        this.addMDI(data);
      }
    },
  },
};
</script>

<style></style>
