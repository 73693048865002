<template>
  <iui-portlet-container type="multi" :comps="comps" :activeTab.sync="tabIndex">
    <template #title>{{ titleNm }}문서</template>
    <template #link>
      <a href="#self" class="goto_top_link" @click="goShortCut">
        <span class="blind">바로가기</span>
      </a>
    </template>
    <template #content>
      <wait-approval v-show="tabIndex === 0" :unchkedCnt.sync="comps[0].unchkedCnt" />
      <wait-send v-show="tabIndex === 1" :unchkedCnt.sync="comps[1].unchkedCnt" />
      <wait-reception v-show="tabIndex === 2" :unchkedCnt.sync="comps[2].unchkedCnt" />
      <wait-public-reading v-show="tabIndex === 3" :unchkedCnt.sync="comps[3].unchkedCnt" />
    </template>
  </iui-portlet-container>
</template>

<script>
import PortletMainMixin from '@/components/portlet/mixin/portletMainMixin.js';
export default {
  mixins: [PortletMainMixin],
  data() {
    return {
      comps: [
        {tabNm: '결재대기', compNm: 'wait-approval', shortCut: 'APP_STANDBY', unchkedCnt: 0},
        {tabNm: '발송대기', compNm: 'wait-send', shortCut: 'APP_SEND_STANDBY', unchkedCnt: 0},
        {tabNm: '접수대기', compNm: 'wait-reception', shortCut: 'APP_RECEIPT_STANDBY', unchkedCnt: 0},
        {tabNm: '공람대기', compNm: 'wait-public-reading', shortCut: 'APP_PUBLIC_STATE', unchkedCnt: 0},
      ],
    };
  },
  methods: {
    goShortCut() {
      this.goMenu(this.comps[this.tabIndex].shortCut);
    },
  },
};
</script>

<style></style>
