<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px"><PrjList /></i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>재고목록</template>
            <pmis-content-box>
              <template v-slot:header-left>
                <iui-text
                  label="자재명"
                  type="search"
                  :value="searchInfo.searchText"
                  @change="searchInfo.searchText = $event.target.value"
                  @enter="onSearch"
                />
                <iui-button value="검색" @click="onSearch" />
              </template>
              <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" />
            </pmis-content-box>
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
/**
 * 자재재고조회
 *
 * */
import PrjList from '@/view/Project/components/PrjList.vue';
import options from '@/view/Resource/Material/Inventory/components/sheetOptions/InventorySheet.js';
import {selectInventoryDetailList} from '@/view/Resource/Material/Inventory/api/inventory.js';
export default {
  components: {
    PrjList,
  },
  data() {
    return {
      loadSearchData: [],
      Options: options(this),
      searchInfo: {
        pgmCd: '',
        prjCd: '',
        searchText: '',
      },
    };
  },
  created() {
    this.addEvent([{name: 'set_searchInfo', func: this.onPrjChange}]);
    this.addFuncSearch(this.onSearchClick);
  },
  methods: {
    onSearchClick() {
      this.searchInfo.searchText = '';
    },
    onPrjChange(e) {
      this.searchInfo.pgmCd = this.pgmCd;
      this.searchInfo.prjCd = e.prjCd;
      this.onSearch();
    },
    async onSearch() {
      let param = {...this.searchInfo};
      const response = await selectInventoryDetailList(param);
      this.loadSearchData = response.data;
    },
  },
};
</script>

<style scoped></style>
