const getQueryParams = qs => {
  qs = qs.split('+').join(' ');

  let params = {},
    tokens,
    re = /[?&]?([^=]+)=([^&]*)/g;

  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
};

window.params = getQueryParams(document.location.search);

if (Object.keys(params).length) {
  if (opener) {
    opener.$externalData(params);
    window.close();
  }
}
