import {SUB_PR_COLUMNS} from '@/view/bidding/js/BiddingColumns.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import {selectSubPrList, selectSubPrInfo, selectSubPrItemList} from '../api/subPr.js';

const BIDDING_CODES = BIDDING_CONSTANTS.CODES;

const state = {
  subPrList: [],
  subPrInfo: {...SUB_PR_COLUMNS},
  subPrItemList: [],

  subPrIsNew: false,
};

const getters = {
  subPrList: state => state.subPrList,
  subPrInfo: state => state.subPrInfo,
  subPrItemList: state => state.subPrItemList,
  subPrIsNew: state => state.subPrIsNew,
  subPrStatus: state => getSubPrStatus(state),
};

const mutations = {
  setSubPrList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.subPrList = payload;
    }
  },
  setSubPrInfo(state, payload) {
    for (const key in state.subPrInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.subPrInfo[key] = payload[key];
      }
    }

    state.subPrInfo.prNo2 = state.subPrIsNew ? BIDDING_CODES.AUTO : COMMON_FUNCTION.getMakeFormat(state.subPrInfo.prNo);

    if (state.subPrInfo.spotDscrYn === BIDDING_CODES.SPOT_DSCR_N) {
      // 현설정보 초기화
      state.subPrInfo.spotDscrDt = '';
      state.subPrInfo.spotDscrYmd = '';
      state.subPrInfo.spotDscrHh = '';
      state.subPrInfo.spotDscrMm = '';
      state.subPrInfo.spotDscrUsrcd = '';
      state.subPrInfo.spotDscrUsrnm = '';
      state.subPrInfo.spotDscrLoc = '';
    }
  },
  initSubPrInfo(state) {
    state.subPrInfo = {...SUB_PR_COLUMNS};
  },
  setSubPrItemList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.subPrItemList = payload;
    }
  },
  setSubPrIsNew(state, payload) {
    if (typeof payload === 'boolean') {
      state.subPrIsNew = payload;
    }
  },
};

const actions = {
  async searchSubPrList(context, param) {
    const subPrList = (await selectSubPrList(param)).data;
    context.commit('setSubPrList', subPrList);
  },
  async searchSubPrInfo(context, prNo) {
    context.commit('setSubPrIsNew', false);
    context.commit('setSubPrInfo', (await selectSubPrInfo(prNo)).data);
    context.dispatch('searchSubPrItemList', prNo);
  },
  async searchSubPrItemList(context, prNo) {
    context.commit('setSubPrItemList', (await selectSubPrItemList(prNo)).data);
  },
};

function getSubPrStatus(state) {
  const status = {
    isPrReady: state.subPrInfo.prSt === BIDDING_CONSTANTS.PROGRESS_STATE.PR_READY,
    isTaxDirect: state.subPrInfo.taxTypecd === BIDDING_CODES.TAX_DIRECT, // 과세유형 - 직접 입력
    isTaxDirectPercent: state.subPrInfo.taxDirectDs === BIDDING_CODES.TAX_DIRECT_PERCENT, // 과세유형 - 직접 입력(%)
    isTaxDirectAmount: state.subPrInfo.taxDirectDs === BIDDING_CODES.TAX_DIRECT_AMOUNT, // 과세유형 - 직접 입력(원)
    isExistSpotDscr: state.subPrInfo.spotDscrYn === BIDDING_CODES.SPOT_DSCR_Y, // 현설유무 - 있음
  };
  status['isEnable'] = state.subPrIsNew || status.isPrReady;

  return status;
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
