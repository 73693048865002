<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-col>
          <iui-tab ref="tab" :comps="tabList" :keep-alive="true" @after-active-tab="onAfterActiveTab">
            <template #tab-right>
              <iui-button value="작업계획삭제" @click="deleteWorkPlan" v-if="isCreated && tabIndex === 0" />
              <iui-button value="작업계획생성" @click="createWorkPlan" v-if="!isCreated && tabIndex === 0" />
            </template>
          </iui-tab>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import PrjList from '@/view/Project/components/PrjList.vue';
import store from './store/workPlan';
import {selectPjtContWcls} from '@/view/unitOpertManage/api/unitOpertRegist.js';
import {updateContWplanPlanAsPlanCnt, deleteWorkPlan, createWorkPlan} from './api/workPlanRegist.js';
export default {
  components: {
    PrjList,
  },
  data() {
    return {
      load: false,
      tabList: [
        {
          tabNm: '단위작업예정물량',
          compNm: 'UnitOpertPlanCnt',
          path: '@/view/workPlanRegist/components/UnitOpertPlanCnt.vue',
        },
        {
          tabNm: '도급기성계획',
          compNm: 'OutsrcPxcondPlan',
          path: '@/view/workPlanRegist/components/OutsrcPxcondPlan.vue',
        },
        {
          tabNm: '실행기성계획',
          compNm: 'ExecutPxcondPlan',
          path: '@/view/workPlanRegist/components/ExecutPxcondPlan.vue',
        },
      ],
      tabIndex: 0,
    };
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['prjFlag', 'isCreated']);
    $mapMutations(this, ['initDetailInfo', 'setPrjFlag']);
  },
  created() {
    this.addFuncPrj(this.onSearch);
    this.addFuncSave(this.onSave);
  },
  methods: {
    async onSearch() {
      if (this.tabIndex === 0) {
        this.callEvent({name: 'unitWorkPlanCntSheetInq'});
        const response = await selectPjtContWcls({});
        this.setPrjFlag(response.data || '');
      } else if (this.tabIndex === 1) {
        this.callEvent({name: 'selectContContPlanList'});
      } else if (this.tabIndex === 2) {
        this.callEvent({name: 'selectContExecPlanList'});
      }
    },
    async onSave() {
      if (!(await this.$confirm({title: '저장', message: '저장하시겠습니까?'}))) {
        return;
      }
      let contWplanPlanDList;
      this.callEvent({
        name: 'getChangedDatas',
        param: data => {
          if (data.length) {
            contWplanPlanDList = data;
          }
        },
      });
      if (!contWplanPlanDList) {
        return;
      }
      const response = await updateContWplanPlanAsPlanCnt({contWplanPlanDList});
      if (response.data) {
        this.onSearch();
      }
    },
    async deleteWorkPlan() {
      if (!(await this.$confirm({title: '작업계획삭제', message: '작업계획을 삭제하시겠습니까?'}))) {
        return;
      }
      const response = await deleteWorkPlan({});
      if (response.data) {
        this.onSearch();
      }
    },

    async createWorkPlan() {
      if (this.prjFlag != $getConstants('PRJ_FLAG1').code) {
        this.$alert({
          title: '작업계획생성',
          message:
            '단위작업이 확정되지 않아 더 이상 진행할 수 없습니다. <br /> 단위작업등록 메뉴에서 확정을 먼저 해주세요.',
        });
        return;
      }
      if (!(await this.$confirm({title: '작업계획생성', message: '작업계획을 생성하시겠습니까?'}))) {
        return;
      }
      this.callEvent({name: 'unitOpertplanCnt_setLoading', param: true});
      const response = await createWorkPlan({});
      if (response.data) {
        this.onSearch();
      }
    },
    onAfterActiveTab(index) {
      this.tabIndex = index;
      if (!this.load) {
        this.load = true;
        return;
      }
      this.onSearch();
    },
  },
};
</script>
