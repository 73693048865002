<template>
  <pmis-content-box :loading="loading">
    <template v-slot:header-left>
      <label>작업년월</label>
      <div class="ml5"></div>
      <iui-datepicker name="closeMm" type="month" :value="closeMm" @update:value="onCloseMmChange" />
      <i class="prev-arrow" @click.prevent.self @click="onClickArrow('PREVIOUS')" />
      <i class="post-arrow" @click.prevent.self @click="onClickArrow('NEXT')" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="sheet = $event"
      :events="{onSearchFinish}"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOptions/ExecutePxCondResult.js';
import {selectExecutePxcondResultList, selectYmList} from '@/view/Project/pxcondStatus/result/api/result';

export default {
  data() {
    return {
      loading: false,
      workType: '',
      Options: options(this),
      loadSearchData: [],
      closeMm: '',
      closeMmList: [],
      sheet: undefined,
      amountUnit: '',
      responseData: {
        sheet: [],
      },
    };
  },
  created() {
    this.addEvent([
      {name: 'EXECUTE_getYmList', func: this.getYmList},
      {
        name: 'EXECUTE_onAmountChange',
        func: this.convertPriceUnit,
      },
    ]);
  },
  methods: {
    async onSearch() {
      this.loading = true;
      try {
        const response = await selectExecutePxcondResultList({closeMm: this.closeMm, prjCd: this.prjCd});
        this.responseData.sheet = response.data;
        this.convertPriceUnit(this.amountUnit);
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    convertPriceUnit(value) {
      this.amountUnit = value;
      this.loadSearchData = $_priceUnitFormat(value, this.responseData.sheet);
    },
    onSearchFinish() {
      this.sheet.makeSubTotal([
        {
          stdCol: 'mgName',
          sumCols:
            'price|amt|beforePlanExecAmt|planExecAmt|totalPlanExecAmt|leftPlanExecAmt|preExecAmt|curExecAmt|totalExecAmt|leftExecAmt|preForceAmt|curForceAmt|totalForceAmt|leftForceAmt',
          captionCol: [{col: 'mgName', val: '중공종계', span: 6}],
        },
        {
          stdCol: 'lgName',
          sumCols:
            'price|amt|beforePlanExecAmt|planExecAmt|totalPlanExecAmt|leftPlanExecAmt|preExecAmt|curExecAmt|totalExecAmt|leftExecAmt|preForceAmt|curForceAmt|totalForceAmt|leftForceAmt',
          captionCol: [{col: 'lgName', val: '대공종계', span: 6}],
        },
        {
          stdCol: 'total',
          sumCols:
            'price|amt|beforePlanExecAmt|planExecAmt|totalPlanExecAmt|leftPlanExecAmt|preExecAmt|curExecAmt|totalExecAmt|leftExecAmt|preForceAmt|curForceAmt|totalForceAmt|leftForceAmt',
          captionCol: [{col: 'total', val: '현장계', span: 7}],
        },
      ]);
    },
    onCloseMmChange(closeMm) {
      this.closeMm = closeMm;
      if (closeMm) {
        this.onSearch();
      }
    },
    async getYmList(amountUnit) {
      this.amountUnit = amountUnit;
      const response = await selectYmList({prjCd: this.prjCd, type: 'EXECUTE'});
      const array = [];
      response.data.forEach(x => {
        array.push(x.closeMm);
      });
      this.closeMmList = array;

      if (array.length > 0) {
        this.closeMm = array[0];
        this.onSearch();
      } else {
        this.closeMm = '';
        this.loadSearchData = [];
      }
    },
    onClickArrow(type) {
      const index = this.closeMmList.indexOf(this.closeMm);

      if (index === -1) {
        this.closeMm = this.closeMmList[this.closeMmList.length - 1];
        this.onSearch();
      } else {
        if ((type === 'PREVIOUS' && index === 0) || (type === 'NEXT' && index + 1 === this.closeMmList.length)) {
          const message = type === 'PREVIOUS' ? '이전 작업년월이 없습니다.' : '다음 작업년월이 없습니다.';
          this.$alert({title: '작업년월', message: message});
          return false;
        }

        this.closeMm = type === 'PREVIOUS' ? this.closeMmList[index - 1] : this.closeMmList[index + 1];
        this.onSearch();
      }
    },
  },
};
</script>
