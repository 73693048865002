export const selectAppFomList = () => axios.post('appDoc/selectAppFomList', {});
export const selectAppDocInitInfo = param => axios.post('/appDoc/selectAppDocInitInfo', param);
export const selectAppDocInfo = param => axios.post('/appDoc/selectAppDocInfo', param);
export const saveDraft = param => $save('/appDoc/saveDraft', param);
export const saveTemp = param => $save('/appDoc/saveTemp', param);
export const selectAppDocList = param => axios.post('/appDoc/selectAppDocList', param);
export const saveApproval = param => axios.post('/appDoc/saveApproval', param);
export const saveReject = param => axios.post('/appDoc/saveReject', param);
export const deleteAppDoc = param => axios.post('/appDoc/deleteAppDoc', param);
export const saveRollback = param => axios.post('/appDoc/saveRollback', param);
export const saveAppDocSend = param => axios.post('/appDoc/saveAppDocSend', param);
export const deletePublicStateList = param => axios.post('/appDoc/deletePublicStateList', param);
export const savePublicConfirm = param => axios.post('/appDoc/savePublicConfirm', param);
export const selectDocPublicList = param => axios.post('/appDoc/selectDocPublicList', param);
export const saveRetrieve = param => axios.post('/appDoc/saveRetrieve', param);
export const selectApprovalStatusList = param => axios.post('/appDoc/selectApprovalStatusList', param);
export const saveOfflineAppDoc = (param, options) => $save('/appDoc/saveOfflineAppDoc', param, options);
export const saveOffline = (param, options) => $save('/appDoc/saveOffline', param, options);
export const getDocNo2 = param => axios.post('/appDoc/getDocNo2', param);
export const selectAttachDocList = param => axios.post('/appDoc/selectAttachDocList', param);
