<template>
  <pmis-tab-box>
    <template v-slot:title>자재예산</template>
    <template v-slot:title-right>
      <iui-button value="자재예산생성" @click="onCreateMatBudget" v-if="isExecBugetConfirm" />
    </template>
    <iui-container-new type="css-flex">
      <i-row height="480px">
        <i-col>
          <pmis-content-box>
            <template v-slot:header-left>
              <div>대분류</div>
              <iui-select :items="lmItems" :value.sync="lmCode" defaultCd="A" @change="onChangeLm" />
              <div>중분류</div>
              <iui-select :items="mmItems" :value.sync="mmCode" defaultCd="A" @change="onChangeMm" />
              <div>소분류</div>
              <iui-select :items="smItems" :value.sync="smCode" defaultCd="A" />
              <div>명칭</div>
              <iui-text type="search" :value="itemName" @change="itemName = $event.target.value" @enter="onSearch" />
              <iui-button value="검색" @click="onSearch" />
              <div class="ml5 last"></div>
              <label>실행예산차수</label>
              <div class="mr5"></div>
              <iui-text :value="chgDegree" align="right" :enable="false" width="35px" />
            </template>
            <pmis-content-box>
              <template v-slot:title>
                자재예산
              </template>
              <ib-sheet
                :uid="_uid"
                :options="Options1"
                :loadSearchData="loadSearchData1"
                @loadSheet="loadSheet1"
                :events="{
                  onSearchFinish,
                  onClick,
                }"
              />
            </pmis-content-box>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row minHeight="250px">
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              실행예산
            </template>
            <ib-sheet :uid="_uid" :options="Options2" :loadSearchData="loadSearchData2" @loadSheet="loadSheet2" />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-tab-box>
</template>

<script>
import options1 from './sheetOptions/MatBudgetListSheet1.js';
import options2 from './sheetOptions/MatBudgetListSheet2.js';
import {
  selectExecBugetConfirm,
  selectClList,
  selectMatBudgetList,
  selecthMatExecPlanList,
  createMatBudget,
} from '../createMatBudget/api/matBudget.js';
export default {
  data() {
    return {
      sheet1: undefined,
      sheet2: undefined,
      Options1: options1(this),
      Options2: options2(this),
      loadSearchData1: [],
      loadSearchData2: [],

      clItems: [],
      lmItems: [],
      mmItems: [],
      smItems: [],

      lmCode: '',
      mmCode: '',
      smCode: '',
      itemName: '',
      chgDegree: '',
      isExecBugetConfirm: false,
    };
  },
  created() {
    this.addFuncPrj(this.onInitSearch);
  },
  methods: {
    loadSheet1(sheet) {
      this.sheet1 = sheet;
    },
    loadSheet2(sheet) {
      this.sheet2 = sheet;
    },
    onSearchFinish(e) {
      this.onSearchMatExecPlanList(e.sheet.getFirstRow());
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.onSearchMatExecPlanList(e.row);
      }
    },
    async onInitSearch() {
      this.selectExecBugetConfirm();
      await this.onSearchClassificationList();
      this.onSearch();
    },
    onSearch() {
      this.onSearchMatBudgetList();
    },
    async selectExecBugetConfirm() {
      const response = await selectExecBugetConfirm({});
      this.isExecBugetConfirm = response.data;
    },
    async onSearchClassificationList() {
      const response = await selectClList({});
      this.clItems = response.data;
      this.lmItems = this.clItems.lmCodes.map(item => ({text: item.lmName, value: item.lmCode}));
    },
    async onSearchMatBudgetList() {
      const param = {
        lmCode: this.lmCode,
        mmCode: this.mmCode,
        smCode: this.smCode,
        itemName: this.itemName,
      };
      const response = await selectMatBudgetList(param);
      if (response.data.length) {
        this.chgDegree = response.data[0].chgDegree + '차';
      } else {
        this.chgDegree = '';
      }
      this.loadSearchData1 = response.data;
    },
    async onSearchMatExecPlanList(row) {
      if (!row) {
        this.sheet2.removeAll();
        return;
      }
      const param = {
        chgDegree: row.chgDegree,
        itemCode: row.itemCode,
      };
      const response = await selecthMatExecPlanList(param);
      this.loadSearchData2 = response.data;
    },

    onChangeLm() {
      this.mmCode = '';
      this.mmItems = this.clItems.mmCodes
        .filter(item => this.lmCode == item.lmCode)
        .map(item => ({text: item.mmName, value: item.mmCode}));
      this.smCode = '';
      this.smItems = [];
    },
    onChangeMm() {
      this.smCode = '';
      this.smItems = this.clItems.smCodes
        .filter(item => this.mmCode == item.mmCode)
        .map(item => ({text: item.smName, value: item.smCode}));
    },

    async onCreateMatBudget() {
      if (!(await this.$confirm({title: '자재예산생성', message: '자재예산을 생성하시겠습니까?'}))) {
        return;
      }
      try {
        const response = await createMatBudget({});
        if (response.data) {
          this.onInitSearch();
        }
      } catch (error) {
        if (error.pmisError) {
          let msg = error.message;
          if (error.description) {
            msg += `<br/>- ${error.description}`;
          }
          this.$alert({title: error.title, message: msg});
        }
      }
    },
  },
};
</script>
