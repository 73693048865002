/** 로그인한 회사(협력사)를 입찰지명한 회사리스트 */
export const selectRfqCompanyList = process => axios.post('/subRfqCustomer/selectRfqCompanyList', {process});
/** 공고목록조회 */
export const selectRfqList = searchOptions => axios.post('/subRfqCustomer/selectRfqList', searchOptions);
/** 입찰정보 조회 */
export const selectRfqInfos = (rfqNo, bidRnd, cusCd, cusPgmCd) =>
  axios.post('/subRfqCustomer/selectRfqInfos', {rfqNo, bidRnd, cusCd, cusPgmCd});
/** 현장설명 참가신청 */
export const applySpotDscr = (rfqNo, lastBidRnd, cusCd, isApply) =>
  axios.post('/subRfqCustomer/applySpotDscr', {rfqNo, lastBidRnd, cusCd, isApply});
/** 견적서 제출 전 상태체크 */
export const selectCheckStateBeforeQuotSubmit = rfqNo =>
  axios.post('/subRfqCustomer/selectCheckStateBeforeQuotSubmit', {rfqNo});
/** 견적서 제출 */
export const submitQuot = param => $save('/subRfqCustomer/submitQuot', param);
/** 견적서 제출 전 상태체크 */
export const selectRfqResultInfos = rfqNo => axios.post('/subRfqCustomer/selectRfqResultInfos', {rfqNo});
