import {SUB_RFQ_COLUMNS, SUB_RFQCUS_COLUMNS, QUOT_COLUMNS} from '@/view/bidding/js/BiddingColumns.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import {selectRfqInfos, selectRfqResultInfos} from '@/view/bidding/rfqCustomer/sub/api/subRfqCustomer.js';

const state = {
  rfqInfo: {...SUB_RFQ_COLUMNS},
  rfqCusInfo: {...SUB_RFQCUS_COLUMNS},
  quotInfo: {...QUOT_COLUMNS},
  rfqItemList: [],
  quotItemList: [],
  rfqCusResultList: [],
};

const getters = {
  rfqInfo: state => state.rfqInfo,
  rfqCusInfo: state => state.rfqCusInfo,
  quotInfo: state => state.quotInfo,
  rfqItemList: state => state.rfqItemList,
  quotItemList: state => state.quotItemList,
  rfqCusResultList: state => state.rfqCusResultList,

  rfqStatus: state => ({
    isSpotDscr: state.rfqInfo.rfqSt === BIDDING_CONSTANTS.PROGRESS_STATE.RFQ_SPOT_DSCR, // 현설통보
    isBeforeBidding: state.rfqInfo.rfqSt < BIDDING_CONSTANTS.PROGRESS_STATE.RFQ_BIDDING, // 입찰 전
    isRfqBidding: state.rfqInfo.rfqSt === BIDDING_CONSTANTS.PROGRESS_STATE.RFQ_BIDDING, // 입찰 중
    isAfterChoice: state.rfqInfo.rfqSt >= BIDDING_CONSTANTS.PROGRESS_STATE.RFQ_CHOICE, // 낙찰사선정 이후
    isAfterRfqComplete: state.rfqInfo.rfqSt >= BIDDING_CONSTANTS.PROGRESS_STATE.RFQ_COMPLETE, // 입찰완료 이후

    isExistSpotDscr: state.rfqInfo.spotDscrYn === BIDDING_CONSTANTS.CODES.SPOT_DSCR_Y, // 현설유무
    isTax: state.rfqInfo.taxTypecd === BIDDING_CONSTANTS.CODES.TAX, // 과세유형 : 과세
    isTaxExemption: state.rfqInfo.taxTypecd === BIDDING_CONSTANTS.CODES.TAX_EXEMPTION, // 과세유형 : 비과세
    isTaxDirect: state.rfqInfo.taxTypecd === BIDDING_CONSTANTS.CODES.TAX_DIRECT, // 과세유형 - 직접 입력
    isTaxDirectPercent: state.rfqInfo.taxDirectDs === BIDDING_CONSTANTS.CODES.TAX_DIRECT_PERCENT, // 과세유형 - 직접 입력(%)
    isTaxDirectAmt: state.rfqInfo.taxDirectDs === BIDDING_CONSTANTS.CODES.TAX_DIRECT_AMOUNT, // 과세유형 : 직접입력(원)
  }),
  rfqCusStatus: state => ({
    isApplySpotDscr: state.rfqCusInfo.spotDscrApplyYn === BIDDING_CONSTANTS.CODES.SPOT_DSCR_APPLY_Y, // 현설참가신청 - Y
    isChkSpotDscr: state.rfqCusInfo.spotDscrChkYn === BIDDING_CONSTANTS.CODES.SPOT_DSCR_CHK_Y, // 현설참여완료
    isQuotSubmit: state.rfqCusInfo.quotSbmtYn === BIDDING_CONSTANTS.CODES.QUOT_SBMT_Y, // 투찰여부
    isSuccess: state.rfqCusInfo.resultYn === BIDDING_CONSTANTS.CODES.RESULT_Y, // 낙찰여부

    canQuotSubmit:
      state.rfqInfo.spotDscrYn === BIDDING_CONSTANTS.CODES.SPOT_DSCR_N ||
      (state.rfqInfo.spotDscrYn === BIDDING_CONSTANTS.CODES.SPOT_DSCR_Y &&
        state.rfqCusInfo.spotDscrChkYn === BIDDING_CONSTANTS.CODES.SPOT_DSCR_CHK_Y),
  }),
};

const mutations = {
  setRfqInfo(state, payload) {
    for (const key in state.rfqInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.rfqInfo[key] = payload[key];
      }
    }
    state.rfqInfo['rfqNo2'] = COMMON_FUNCTION.getMakeFormat(state.rfqInfo['rfqNo']);
  },
  initRfqInfo(state) {
    state.rfqInfo = {...SUB_RFQ_COLUMNS};
  },
  setRfqCusInfo(state, payload) {
    for (const key in state.rfqCusInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.rfqCusInfo[key] = payload[key];
      }
    }
  },
  initRfqCusInfo(state) {
    state.rfqCusInfo = {...SUB_RFQCUS_COLUMNS};
  },
  setQuotInfo(state, payload) {
    for (let key in state.quotInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.quotInfo[key] = payload[key];
      }
    }
  },
  initQuotInfo(state) {
    state.quotInfo = {...QUOT_COLUMNS};
  },
  setRfqItemList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.rfqItemList = payload;
    }
  },
  setQuotItemList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.quotItemList = payload;
    }
  },
  setRfqCusResultList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.rfqCusResultList = payload;
    }
  },
};

const actions = {
  initInfo({commit}) {
    commit('initRfqInfo');
    commit('initRfqCusInfo');
    commit('initQuotInfo');
    commit('setRfqItemList', []);
    commit('setQuotItemList', []);
    commit('setRfqCusResultList', []);
  },
  async searchRfqInfosCustomer({commit, getters}, param) {
    const {rfqNo, bidRnd, cusCd, cusPgmCd} = param;
    const response = await selectRfqInfos(rfqNo, bidRnd, cusCd, cusPgmCd);

    const {rfqInfo, rfqCusInfo, quotInfo, rfqItemList, quotItemList} = response.data;
    commit('setRfqInfo', rfqInfo);
    commit('setRfqCusInfo', rfqCusInfo);
    commit('setQuotInfo', quotInfo);
    commit('setRfqItemList', rfqItemList);
    commit('setQuotItemList', quotItemList);

    if (getters.rfqStatus.isAfterChoice) {
      const response = await selectRfqResultInfos(rfqNo);
      commit('setRfqCusResultList', response.data.rfqCusResultList);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
