import workType from '@/view/estmtManage/prjWorkTypeRegist/store/ContWorkTypeRegist.js';
const state = {
  masterInfo: {
    chgDegree: '', //변경차수
    bldRmk: '', // 변경사유
    prjFlag1: '', //확정Flag
    prjFlag2: '', //실행내역생성 flag
    amt: '', //금액
  },
  detailInfo: {
    chgDegree: '', //변경차수
    wcode: '', //공종코드
    wname: '', //공종명
    headCode: '', //견적대공종코드
    lgName: '', //견적대공종명
    midCode: '', //견적중공종코드
    mgName: '', //견정중공종명
    itemSeq: '', //내역번호
    itemCode: '', //자원코드
    itemName: '', //명칭
    costType: '', //자우너구분
    ssize: '', //규격
    unit: '', //단위
    formula: '', //산식
    qty: '', //당초수량
    moQty: '', //변경수량
    price: '', //단가
    amt: '', //금액
    amtFormat: '',
    matPrice: '', //자재비단가
    matAmtFormat: '',
    matAmt: '', //자재비금액
    labPrice: '', //노무비단가
    labAmtFormat: '',
    labAmt: '', //노무비금액
    equipPrice: '', //경비단가
    equipAmtFormat: '',
    equipAmt: '', //경비금액
    chgGb: '', //변경구분
  },
  isFormula: false,
  currentDegree: '', //선택차수
  degreeList: [], //차수리스트
};

const getters = {
  masterInfo: (state, getters) => {
    state.masterInfo.chgDegree = String(getters.maxDgree) || '0';
    return state.masterInfo;
  },
  detailInfo: state => {
    state.detailInfo.amtFormat = String(state.detailInfo.amt).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    state.detailInfo.matAmtFormat = String(state.detailInfo.matAmt).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    state.detailInfo.labAmtFormat = String(state.detailInfo.labAmt).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    state.detailInfo.equipAmtFormat = String(state.detailInfo.equipAmt).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    return state.detailInfo;
  },
  isFormula: state => state.isFormula,
  degreeList: state => state.degreeList, //차수정보
  degreeListAsSelect: state =>
    state.degreeList.map(x => {
      return {text: x + '차', value: x};
    }),
  maxDgree: state => {
    if (state.degreeList.slice(-1).pop()) {
      return String(state.degreeList.slice(-1).pop());
    } else {
      return '';
    }
  },
  currentDegree: state => state.currentDegree,
  isWorkTypeCl: state => state.isWorkTypeCl,
};

const mutations = {
  // initMasterInfo(state) {
  //   for (let key in state.masterInfo) {
  //     state.masterInfo[key] = '';
  //   }
  // },
  setMasterInfo(state, payload) {
    for (let key in state.masterInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.masterInfo[key] = payload[key];
      }
    }
  },

  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = payload[key];
      }
    }
  },

  setFormula(state, payload) {
    state.isFormula = payload;
  },

  setDegreeList(state, degreeList) {
    state.degreeList = degreeList;
  },

  setCurrentDegree(state, payload) {
    state.currentDegree = payload;
  },
};

const modules = {
  workType,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  modules,
};
