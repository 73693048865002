<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <BillList />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col>
          <BillInfo />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import BillList from '../components/BillList.vue';
import BillInfo from '@/view/subBidding/billManageCustomer/components/CustomerBillDetail.vue';
export default {
  components: {BillList, BillInfo},
};
</script>

<style></style>
