<template>
  <pmis-content-box>
    <template v-slot:title> 비밀번호변경</template>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <iui-container-new type="table" theme="bullet" headerWidth="140px">
            <colgroup>
              <col width="150px" />
              <col width="150px" />
              <col width="150px" />
            </colgroup>
            <i-row>
              <i-col-header required>
                현재 비밀번호
              </i-col-header>
              <i-col>
                <iui-text
                  type="password"
                  :value.sync="pwdInfo.curPwd"
                  max-length="12"
                  required
                  error-message="필수입력"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header required>
                변경 비밀번호
              </i-col-header>
              <i-col>
                <iui-text
                  type="password"
                  :value.sync="newPwd1"
                  @keyup="chkCapsLock"
                  max-length="12"
                  required
                  error-message="필수입력"
                />
                <font v-if="isCapsLock" style="color:#0080FF; font-weight: bold;"
                  >Caps Lock이 활성화 되어있습니다.</font
                >
              </i-col>
              <i-col>
                <label class="ml5">
                  최대 12자, 대소문자 구분
                </label>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header required>
                변경 비밀번호 확인
              </i-col-header>
              <i-col>
                <iui-text
                  type="password"
                  :value.sync="newPwd2"
                  max-length="12"
                  @keyup="chkCapsLock"
                  required
                  error-message="필수입력"
                />
                <font v-if="newPwd2 && newPwd1 != newPwd2" style="color:#FF6666; font-weight: bold;"
                  >비밀번호가 일치하지 않습니다.</font
                >
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    paramObj: {
      type: Object,
    },
  },
  data() {
    return {
      pwdInfo: {
        pgmCd: '',
        empNo: '',
        curPwd: '',
        newPwd: '',
      },
      newPwd1: '',
      newPwd2: '',
      isCapsLock: false,
    };
  },
  created() {
    this.addEvent({name: 'ChangePwd_confirm', func: this.onConfirm});

    this.pwdInfo.pgmCd = this.paramObj.pgmCd;
    this.pwdInfo.empNo = this.paramObj.empNo;
  },
  methods: {
    chkCapsLock(e) {
      this.isCapsLock = e.getModifierState('CapsLock') ? true : false;
    },
    async onConfirm(callback) {
      if (typeof callback == 'function') {
        let hidePopup = false;
        let validator = await this.$validator.validate(this);
        if (!validator.isValid) {
          this.$alert({
            title: '비밀번호 변경',
            message: `입력되지 않은 필수항목이 존재합니다.<br/>다시 한 번 확인하시기 바랍니다.`,
          });
          return;
        }
        if (this.newPwd1.length < 4) {
          this.$alert({title: '변경 비밀번호', message: '비밀번호를 최소 4자리 이상으로<br/>설정해주시기 바랍니다.'});
          return;
        }
        if (this.newPwd1 != this.newPwd2) {
          this.$alert({title: '변경 비밀번호 확인', message: '변경 비밀번호가 일치하지 않습니다.'});
          return;
        } else {
          this.pwdInfo.newPwd = this.newPwd1;
        }
        if (!(await this.$confirm({title: '비밀번호 변경', message: '비밀번호를 변경하시겠습니까?'}))) return;

        try {
          let response = await axios.post('/myPage/updatePwd', this.pwdInfo);
          if (response.data) {
            this.$alert({title: '비밀번호 변경완료', message: '비밀번호가 변경되었습니다.'});
            hidePopup = true;
          }
        } catch (error) {
          this.$alert({title: '비밀번호 불일치', message: error.message});
        }

        callback(hidePopup);
      }
    },
  },
};
</script>

<style></style>
