var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('iui-container-new',{attrs:{"type":"css-flex"}},[_c('i-row',[_c('i-col',{attrs:{"width":"500px"}},[_c('iui-container-new',{attrs:{"type":"table","theme":"bullet","header-width":"120px"}},[_c('i-row',[_c('i-col-header',{attrs:{"required":_vm.status.isEditableW}},[_vm._v("공사코드")]),_c('i-col',{attrs:{"width":"100px"}},[_c('iui-text',{attrs:{"type":"code","value":_vm.sheetData.wcode,"enable":false,"max-length":"1","width":"20px"},on:{"input":function($event){return _vm.setSheetData({
                  code: $event.target.value,
                  wcode: $event.target.value,
                })}}})],1),_c('i-col',[_c('iui-text',{attrs:{"name":"wname","value":_vm.sheetData.wname,"enable":false,"max-length":"50"},on:{"input":function($event){return _vm.setSheetData({
                  name: $event.target.value,
                  wname: $event.target.value,
                })}}})],1)],1),_c('i-row',[_c('i-col-header',[_vm._v("대공종")]),_c('i-col',[_c('iui-text',{attrs:{"type":"code","value":_vm.status.isEditableLG || _vm.status.isEditableMG ? _vm.sheetData.wcode : '',"enable":false,"max-length":"1","width":"20px"}}),_c('iui-text',{attrs:{"type":"code","name":"lgCode","value":_vm.status.isEditableLG || _vm.status.isEditableMG ? _vm.sheetData.lgCode.slice(1) : '',"enable":false,"max-length":"1","width":"20px"},on:{"input":function($event){_vm.setSheetData({
                  code: String(_vm.sheetData.wcode).concat($event.target.value),
                  lgCode: String(_vm.sheetData.wcode).concat($event.target.value),
                })}}})],1),_c('i-col',[_c('iui-text',{attrs:{"name":"lgName","value":_vm.sheetData.lgName,"enable":false,"max-length":"50"},on:{"input":function($event){return _vm.setSheetData({name: $event.target.value, lgName: $event.target.value})}}})],1)],1),_c('i-row',[_c('i-col-header',[_vm._v("중공종")]),_c('i-col',[_c('iui-text',{attrs:{"type":"code","name":"wcode","value":_vm.status.isEditableMG ? _vm.sheetData.wcode : '',"enable":false,"max-length":"1","width":"20px"}}),_c('iui-text',{attrs:{"type":"code","name":"lgCode","value":_vm.status.isEditableMG ? _vm.sheetData.lgCode.slice(1) : '',"enable":false,"max-length":"1","width":"20px"}}),_c('iui-text',{attrs:{"type":"code","name":"mgCode","value":_vm.sheetData.mgCode.slice(2),"enable":false,"max-length":"1","width":"20px"},on:{"input":function($event){_vm.setSheetData({
                  code: String(_vm.sheetData.lgCode).concat($event.target.value),
                  mgCode: String(_vm.sheetData.lgCode).concat($event.target.value),
                })}}})],1),_c('i-col',[_c('iui-text',{attrs:{"name":"mgName","value":_vm.sheetData.mgName,"enable":false,"max-length":"50"},on:{"input":function($event){return _vm.setSheetData({name: $event.target.value, mgName: $event.target.value})}}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }