<template>
  <pmis-content-box :loading="loading">
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :autoRender="false"
      :init.sync="init"
      @loadSheet="loadSheet"
      :events="{
        onSearchFinish,
        onMouseOver,
        onRenderFirstFinish,
      }"
    />
    <div
      class="planInfo"
      :style="{top: planInfo.top, left: planInfo.left, width: planInfo.width, height: planInfo.height}"
      v-if="planInfo.show"
    >
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header>시작예정일</i-col-header>
          <i-col colspan="5">
            <iui-datepicker name="planStrdate" :value="String(detailInfo.planStrdate)" :readonly="true" />
          </i-col>
          <i-col-header>실제시작일</i-col-header>
          <i-col colspan="5">
            <iui-datepicker name="strResultDate" :value="String(detailInfo.strResultDate)" :readonly="true" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>종료예정일</i-col-header>
          <i-col colspan="5">
            <iui-datepicker name="planEnddate" :value="String(detailInfo.planEnddate)" :readonly="true" />
          </i-col>
          <i-col-header>실제종료일</i-col-header>
          <i-col colspan="5">
            <iui-datepicker
              name="endResultDate"
              :value="detailInfo.planCnt == detailInfo.resultQty ? String(detailInfo.endResultDate) : ''"
              :readonly="true"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>작업일수</i-col-header>
          <i-col colspan="5">
            <iui-text
              type="number"
              name="workCnt"
              :value="detailInfo.workCnt"
              :suffix="' 일'"
              :readonly="true"
              width="60px"
            ></iui-text>
          </i-col>
          <i-col-header>공정율</i-col-header>
          <i-col colspan="5">
            <iui-text
              name="rt"
              :value="
                detailInfo.rt
                  ? parseFloat(detailInfo.rt.toFixed(2)) <= 100
                    ? parseFloat(detailInfo.rt.toFixed(2))
                    : 100
                  : 0
              "
              :suffix="' %'"
              :readonly="true"
              width="70px"
            ></iui-text>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>잔여일수</i-col-header>
          <i-col colspan="5">
            <iui-text
              type="number"
              name="remndrCnt"
              :value="detailInfo.remndrCnt > 0 ? detailInfo.remndrCnt : 0"
              :suffix="' 일'"
              :readonly="true"
              width="60px"
            ></iui-text>
          </i-col>
          <i-col-header>지연일수</i-col-header>
          <i-col colspan="5">
            <iui-text
              type="number"
              name="delayCnt"
              :value="detailInfo.delayCnt"
              :suffix="' 일'"
              :readonly="true"
              width="60px"
            ></iui-text>
          </i-col>
        </i-row>
      </iui-container-new>
    </div>
  </pmis-content-box>
</template>

<script>
import '@/assets/css/ganttChart.scss';
import options from './sheetOptions/StatusSheet.js';
import {selectStatusList} from '../api/status.js';
export default {
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      totalcolor: '#4d9dff',
      ratecolor: '#eb4034',
      psd: undefined,
      pdays: undefined,
      gantWidth: 40,
      currentIndex: undefined,
      planInfo: {
        show: false,
        width: '440px',
        height: '150px',
        top: '0px',
        left: '0px',
      },
      loading: false,
      init: false,
      loadData: false,
    };
  },

  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'detailInfo']);
    $mapMutations(this, ['setDetailInfo']);
  },

  created() {
    this.addEvent([{name: 'initGantChart', func: this.initSheet}]);
    window.addEventListener('click', e => {
      if (!e.target.closest('.ganttBarMain.plan') && !e.target.closest('.planInfo')) this.planInfo.show = false;
    });
  },
  mounted() {
    this.$nextTick(() => this.initSheet());
  },

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    initSheet(loadData) {
      this.Options = options(this);
      this.init = true;
      this.loadData = !!loadData;
      if (this.loadData) {
        this.onSearch();
      }
    },

    drawGantt(row) {
      let planBar = '';
      let resultBar = '';
      //작업시작예정일
      const planS = String(row.planStrdate);
      const planWsd = new Date(planS.slice(0, 4), planS.slice(4, 6) - 1, planS.slice(6, 8));
      const planWpos = (planWsd - this.psd) / 1000 / 60 / 60 / 24;
      const planWposRate = (planWpos / this.pdays) * 100;

      //작업종료예정일
      const planE = String(row.planEnddate);
      const planWed = new Date(planE.slice(0, 4), planE.slice(4, 6) - 1, planE.slice(6, 8));
      const planWdays = (planWed - planWsd) / 1000 / 60 / 60 / 24 + 1;
      const planEposRate = (planWdays / this.pdays) * 100;

      planBar =
        "<div class='ganttBarMain plan'" +
        " style='left:" +
        planWposRate +
        '%;width:' +
        planEposRate +
        '%;background-color:' +
        this.totalcolor +
        "'>" +
        '</div>';

      if (row.strResultDate && row.endResultDate) {
        //작업시작일
        const s = dateFormat(row.strResultDate);
        const wsd = new Date(s);
        const wpos = (wsd - this.psd) / 1000 / 60 / 60 / 24;
        const wposRate = (wpos / Number(this.pdays)) * 100;

        //작업종료일
        const e = dateFormat(row.endResultDate);
        const wed = new Date(e);
        const wdays = (wed - wsd) / 1000 / 60 / 60 / 24 + 1;
        const eposRate = (wdays / this.pdays) * 100;

        resultBar =
          "<div class='ganttBarMain'" +
          "' style='left:" +
          wposRate +
          '%;width:' +
          eposRate +
          '%;background-color:' +
          this.ratecolor +
          ';margin-top: 5px;' +
          "'>" +
          '</div>';
      }

      const gantBar = `<div style='width:100%;height:30px' >${planBar} ${resultBar}</div>`;
      this.sheet.setValue(row, 'gant1', gantBar, 1);
      this.sheet.acceptChangedData(row);
    },
    onSearchFinish(e) {
      const sheet = e.sheet;
      let row = sheet.getRowById('AR1');
      while (row != null) {
        sheet.setMergeRange(row, 'gant1', row, 'gant' + (this.pdays + 1));
        this.drawGantt(row);
        row = sheet.getNextRow(row);
      }

      if (this.currentIndex) {
        this.sheet.setScrollLeft((this.currentIndex - 1) * this.gantWidth);
        this.currentIndex = undefined;
      }

      setTimeout(() => {
        for (const plan of document.querySelectorAll('.ganttBarMain.plan')) {
          plan.removeEventListener('mouseover', e => this.showInfo(e));
          plan.addEventListener('mouseover', e => this.showInfo(e));
          plan.removeEventListener('mouseout', e => (this.planInfo.show = false));
          plan.addEventListener('mouseout', e => (this.planInfo.show = false));
        }
      }, 10);

      this.loading = false;
    },
    onMouseOver(e) {
      if (e.row && e.row.Kind == 'Data') {
        this.setDetailInfo(e.row);
      }
    },
    onRenderFirstFinish(e) {
      if (!this.loadData) {
        this.loading = false;
      }
    },
    async onSearch() {
      try {
        const response = await selectStatusList({});
        let items = $_treeModel(response.data.statusList, '1', 'fldrCd', 'uppFldrCd', 'fldrNo', 'uppFldrNo');
        items = JSON.parse(JSON.stringify(items).replace(/items/gi, 'Items'));
        const root = response.data;
        root.fldrNm = this.getPrjInfo().prjNm;
        root['Items'] = items;
        this.sheet.loadSearchData([root], 0);
      } catch (error) {
        this.currentIndex = undefined;
        this.loading = false;
        this.$alert({
          title: '공정현황조회',
          message: error.message,
        });
      }
    },
    showInfo(e) {
      this.planInfo.show = true;
      let width = Number(this.planInfo.width.replace('px', ''));
      let height = Number(this.planInfo.height.replace('px', ''));
      let pageX;
      let pageY;
      if (e.view.innerWidth - (e.pageX + width) > 0) {
        pageX = e.pageX;
      } else {
        pageX = e.pageX - width;
      }

      if (e.view.innerHeight - (e.pageY + height) > 0) {
        pageY = e.pageY;
      } else {
        pageY = e.pageY - height;
      }

      this.planInfo.left = `${pageX}px`;
      this.planInfo.top = `${pageY}px`;
    },
  },
};
</script>

<style scoped>
.planInfo {
  padding: 20px;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  position: fixed;
  background: #fff;
  z-index: 999;
}
</style>
