const state = {
  searchInfo: {
    consDtFrom: '', // 상담기간(from)
    consDtTo: '', // 상담기간(to)
    compltDs: '', // 완료구분
  },
  consultInfo: {
    reqDt: '', // 신청일자
    seq: '', // 순번
    bsnNo: '', // 사업자번호
    frmNm: '', // 회사명
    adr: '', // 회사주소
    name: '', // 신청자성명
    phone: '', // 신청자전화번호
    email: '', // 신청자이메일
    costYn: '', // 원가관리여부
    laboYn: '', // 노무관리여부
    grouYn: '', // 그룹웨어여부
    eproYn: '', // 전자조달여부
    askDetail: '', // 문의내용
    persYn: '', // 개인정보동의여부
    maktYn: '', // 마케팅동의여부
    receDt: '', // 접수일자
    receId: '', // 접수자ID
    receNm: '', // 접수자
    consDt: '', // 상담일자
    consultDetail: '', // 상담내용
    rgprId: '', // 등록자아이디
    rgsDttm: '', // 등록일시
    compltDs: '', // 상담완료여부
  },
};

const getters = {
  searchInfo: state => state.searchInfo,
  consultInfo: state => state.consultInfo,
};

const mutations = {
  setSearchInfo(state, payload) {
    for (let key in payload) {
      if (payload && payload.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  setConsultInfo(state, payload) {
    for (let key in state.consultInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.consultInfo[key] = payload[key];
      }
    }
  },
  initConsultInfo(state) {
    for (let key in state.consultInfo) {
      state.consultInfo[key] = '';
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
