<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text :value.sync="searchText" @enter="sheetSearch" />
      <iui-button value="검색" @click="sheetSearch" />
    </template>
    <iui-container-new type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
export default {
  data() {
    return {
      elId: 'sheetDiv1' + this._uid,
      searchText: '',
    };
  },

  created() {
    this.addEvent([{name: 'getBankCode', func: this.getBankCode}]);
  },

  mounted() {
    this.initSheet();
  },

  methods: {
    async initSheet() {
      const OPT = {
        Cols: [
          {
            Header: '은행코드',
            Name: 'code',
            Type: 'Text',
            Align: 'Center',
            Width: 150,
            MinWidth: 200,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '명칭',
            Name: 'codeNm',
            Type: 'Text',
            Align: 'Left',
            RelWidth: 1,
            MinWidth: 200,
            Visible: 1,
            CanEdit: 0,
          },
        ],
        Cfg: {
          SearchMode: 2,
        },
        Events: {
          onDblClick: () => {
            this.$modalConfirm();
          },
        },
      };

      if (this.sheet) this.sheet.dispose();
      this.sheet = await PMISSheet.createSheet({
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });
      this.getCode();
    },
    async getCode() {
      const codes = await $getCode($getConstants('BANK_CODE').code);
      this.sheet.loadSearchData(codes, 0);
    },
    getBankCode(callback) {
      const row = this.sheet.getFocusedRow();
      if (typeof callback == 'function') {
        callback({
          code: row.code,
          codeNm: row.codeNm,
        });
      }
    },
    sheetSearch() {
      if (this.searchText) {
        this.sheet.setFilter('bankFilter', obj => {
          return obj.Row.code.indexOf(this.searchText) > -1 || obj.Row.codeNm.indexOf(this.searchText) > -1;
        });
      } else {
        this.sheet.clearFilter();
      }
    },
  },
};
</script>

<style></style>
