<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text type="search" @update:value="setSearchText" @enter="initSearch" />
      <iui-button value="검색" class="btn" @click="initSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :append="append"
      :events="{onDataLoad}"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/ResourceListSheet.js';
import {selectResourceCopyList} from '../api/ResrceManagePop.js';
export default {
  props: {
    paramObj: {
      type: Object,
    },
  },

  data() {
    return {
      sheet: undefined,
      sheetUtil: undefined,
      Options: $addColCheckbox(options(this)),
      loadSearchData: [],
      costType: '',
      searchText: '',
      itemCode: '',
      append: true,
    };
  },

  created() {
    this.costType = this.paramObj.costType;
    this.itemCode = this.paramObj.itemCode;
    this.addEvent({name: `ResourceListPop_popConfirm_${this.costType}`, func: this.onPopConfirm});
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
      this.sheetUtil.scrollPaging(this.onSearch);
      this.initSearch();
    },
    onDataLoad() {
      this.append = true;
    },
    initSearch() {
      this.append = false;
      setTimeout(() => {
        this.onSearch();
      }, 100);
    },
    async onSearch() {
      let param = {
        costType: this.costType,
        searchText: this.searchText,
        ...this.sheetUtil.pagingData(),
      };
      let response = await selectResourceCopyList(param);
      const resourceList = response.data.filter(item => {
        if (item.itemCode2 != this.itemCode) {
          item.costType = this.costType;
          return true;
        }
      });
      this.loadSearchData = resourceList;
    },
    setSearchText(value) {
      this.searchText = value;
    },
    onPopConfirm(param) {
      let checkedRows = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({title: '자원', message: '선택된 항목이 없습니다.'});
        return;
      }
      param(checkedRows);
    },
  },
};
</script>

<style></style>
