const state = {
  detailInfo: {
    wcode: '', // 공종코드
    wname: '', // 공종명
    itemCode: '', //일위대가코드
    itemName: '', //일위대가명
    ssize: '', //규격
    unit: '', //단위
    amt: '', //금액
    matAmt: '', //자재비금액
    labAmt: '', //노무비금액
    equipAmt: '', //경비금액
    useDs: '', //사용여부
    sctmLcode: '', // 일위대가대분류코드
    sctmLcodeNm: '', // 일위대가대분류명칭
    sctmMcode: '', // 일위대가중분류코드
    sctmMcodeNm: '', // 일위대가중분류코드명칭
    sctmScode: '', // 일위대가소분류코드
    sctmScodeNm: '', // 일위대가소분류코드명칭
    sctmMlcodeYn: '', // 하위분류존재
    sctmSlcodeYn: '', // 하위분류존재
  },
  detailInfoDtls: {
    costType: '',
    itemCode1: '',
    itemCode2: '',
    itemName: '',
    ssize: '',
    unit: '',
    qty: '',
    formula: '',
  },
  isFormula: false,
  exsitDtls: false,
};

const getters = {
  detailInfo: state => state.detailInfo,
  detailInfoDtls: state => state.detailInfoDtls,
  isFormula: state => state.isFormula,
  isExsitDtls: state => state.exsitDtls,
};

const mutations = {
  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = payload[key];
      }
    }
  },
  initDetailInfo(state) {
    for (let key in state.detailInfo) {
      state.detailInfo[key] = '';
    }
  },

  setDetailInfoDtls(state, payload) {
    for (let key in state.detailInfoDtls) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfoDtls[key] = payload[key];
      }
    }
  },

  setFormula(state, payload) {
    state.isFormula = payload;
  },
  setExsitDtls(state, payload) {
    state.exsitDtls = payload;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
