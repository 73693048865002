<!--
Name : iui-datapicker
Description :
old1 : https://www.npmjs.com/package/vue2-slot-calendar
old2 : https://www.npmjs.com/package/vuejs-datepicker
new : https://www.npmjs.com/package/vue2-datepicker
-->
<template>
  <div :class="rootClass" :style="rootStyle">
    <label v-if="label" :id="labelId" :for="datePickerId" class="label" :style="labelStyle">{{ label }}</label>
    <span v-if="prefix" :id="prefixId" class="prefix" :style="prefixStyle">{{ prefix }}</span>
    <date-picker
      ref="datePicker"
      v-model="vdata"
      :class="{
        datepicker: true,
        required: required,
      }"
      :type="type"
      :format="vformat"
      :value-type="vvalueType"
      :default-value="ddefaultValue"
      :editable="!readonly"
      :clearable="!readonly && isIconClear"
      :popup-style="{visibility: readonly ? 'hidden' : ''}"
      :disabled="disabled || !enable"
      :lang="lang"
      :input-class="datePickerInputClass"
      :input-attr="datePickerInputAttr"
      :placeholder="placeholder"
      :minute-step="0"
      @input="onInput"
      @blur="onBlur"
      @open="onOpen"
      :open.sync="internalOpen"
      @clear="onClear"
      @close="onClose"
      :required="required"
      :disabled-date="disabledDate"
    >
      <template #icon-calendar>
        <slot v-if="iconCalendarSlotVisible" name="icon-calendar" />
        <input v-else type="button" :class="enable && !disabled ? 'icon-calendar' : 'icon-calendar-off'" />
      </template>
      <template #icon-clear>
        <slot name="icon-clear" />
      </template>
    </date-picker>
    <span v-if="suffix" v-bind:id="suffixId" class="suffix" :style="suffixStyle">{{ suffix }}</span>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import IuiLabelPrefixSuffixMixin from '@/components/Iui/mixins/IuiLabelPrefixSuffixMixin';
import {IuiValidatorMixin} from '@/plugins/IuiValidator';
import rootStore from '@/store';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'iui-datepicker',
  mixins: [IuiBaseMixin, IuiLayoutMixin, IuiLabelPrefixSuffixMixin, IuiValidatorMixin],
  components: {DatePicker},
  props: {
    // TODO: etc
    name: {
      type: String,
    },
    value: {
      type: [String, Date, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'date', // date|datetime|year|month|time|week
    },
    format: {
      type: String,
      default: '',
    },
    valueType: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: [String, Date, Number],
      default: () => new Date(),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    isIconClear: {
      type: Boolean,
      default: true,
    },
    group: {
      type: Object,
      default: () => {},
    },
    disabledDate: {
      type: Function,
      default: () => false,
    },
  },
  data() {
    return {
      lang: {
        months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
      },
      vdata: '',
      vformat: 'YYYY-MM-DD',
      vvalueType: 'date',
      internalOpen: false, // 실질적인 panel-open 상태
      originalValue: '',
      isUser: false,
    };
  },
  watch: {
    value(newValue) {
      this.vdata = this.converter(newValue);
    },
    open(value) {
      this.internalOpen = value;
    },
  },
  computed: {
    ...mapGetters(['datepickerCompMap', 'currentUrl']),

    datePickerId: {
      get() {
        return this.getId();
      },
    },

    // class
    rootClass: {
      get() {
        let obj = {
          'iui-datepicker': true,
          'iui-layout-debug': this.layoutDebug,
        };

        return obj;
      },
    },
    datePickerInputClass: {
      get() {
        let obj = {
          'mx-input': true,
          required: this.required,
          'is-valid-fail': !this.isValid,
        };
        if (this.group) {
          obj[`${this.group.grpNm}${this.group.seq}`] = true;
        }

        return obj;
      },
    },

    // style
    rootStyle: {
      get() {
        let obj = {
          width: undefined,
          height: undefined,
          flex: undefined,
        };

        if (this.width !== undefined) {
          obj.width = this.width;
          obj.flex = `0 0 this.width`;
        }
        if (this.height !== undefined) {
          obj.height = this.height;
        }

        return obj;
      },
    },
    datePickerInputAttr: {
      get() {
        return {id: this.datePickerId};
      },
    },
    ddefaultValue() {
      if (this.defaultValue instanceof Date) return this.defaultValue;

      let defaultValue = String(this.defaultValue).replace(/-/g, '');
      let year = defaultValue.substring(0, 4);
      let month = defaultValue.substring(4, 6);
      let date = defaultValue.substring(6);
      date = date ? date : 1;

      return new Date(year, month - 1, date);
    },
    errTitle() {
      return this.errorMessage instanceof Object ? this.errorMessage.title : 'Confirm';
    },
    errMessage() {
      return this.errorMessage instanceof Object ? this.errorMessage.message : this.errorMessage ?? '';
    },
    iconCalendarSlotVisible() {
      return this.$slots['icon-calendar'] !== undefined;
    },
  },
  created() {
    this.initDatepicker();
    if (this.group) this.addDatepickerComponent();
  },
  updated() {
    this.initLayout();
    this.initDatepicker();
    this.initValidator();
    this.checkParameter();
  },
  mounted() {
    let el = this.$refs.datePicker.$refs.input;
    el.setAttribute('data-error-title', this.errTitle);
    el.setAttribute('data-error-message', this.errMessage);
    el.addEventListener('keyup', () => {
      let v = String(this.value).replace(/[^0-9]/g, '');
      if (this.type == 'date' && v.length >= 8) {
        v = v.slice(0, 4) + '-' + v.slice(4, 6) + '-' + v.slice(6, 8);
      } else if (this.type == 'month' && v.length >= 6) {
        v = v.slice(0, 4) + '-' + v.slice(4, 6);
      } else if (this.type == 'year' && v.length >= 4) {
        v = v.slice(0, 4);
      }
      this.onInput(v);
    });
  },
  methods: {
    converter(value) {
      return String(String(value).length === 13 ? dateFormat(new Date(Number(value))).replace(/-/g, '') : value);
    },
    addDatepickerComponent() {
      const compMap = this.datepickerCompMap;

      if (!compMap[this.currentUrl]) {
        compMap[this.currentUrl] = {};
      }
      if (!compMap[this.currentUrl][this.group.grpNm]) {
        compMap[this.currentUrl][this.group.grpNm] = {};
      }
      if (Object.keys(compMap[this.currentUrl][this.group.grpNm]).indexOf(this.group.seq) != -1) {
        return;
      }
      compMap[this.currentUrl][this.group.grpNm][this.group.seq] = this;

      this.$store.commit('setDatepickerCompMap', compMap);
    },
    initDatepicker() {
      if (this.type == 'date') this.vformat = 'YYYY-MM-DD';
      if (this.type == 'month') this.vformat = 'YYYY-MM';
      if (this.type == 'year') this.vformat = 'YYYY';
      if (this.type == 'time') this.vformat = 'HH:mm';
      this.vformat = this.format == '' ? this.vformat : this.format;

      if (this.type == 'date') this.vvalueType = 'YYYYMMDD';
      if (this.type == 'month') this.vvalueType = 'YYYYMM';
      if (this.type == 'year') this.vvalueType = 'YYYY';
      if (this.type == 'time') this.vvalueType = 'HH:mm';
      this.vvalueType = this.valueType == '' ? this.vvalueType : this.valueType;

      this.vdata = this.converter(this.value);

      this.validatorTargetDataProp = 'vdata';
      this.validationTriggerEventName = ['valid'];
    },
    setBindArray(value) {
      if (typeof value != 'undefined') {
        this.bindArray.forEach(o => {
          let payload = {};
          payload[this.name] = value.replace(/[^0-9]/g, '');
          rootStore.commit(rootStore.getters.currentUrl + '/' + o, payload);
        });
      }
    },
    checkValidation(val) {
      if (!val || this.group == undefined || !this.group.hasOwnProperty('grpNm')) {
        return val;
      }

      const comps = this.datepickerCompMap[this.currentUrl][this.group.grpNm];
      for (let key in comps) {
        if (key == this.group.seq) {
          continue;
        }

        const compareVal = dateFormat(comps[key].value).replace(/-/g, '');
        if (compareVal && ((key < this.group.seq && compareVal > val) || (key > this.group.seq && compareVal < val))) {
          comps[key].onInput(val);
        }
      }

      return val;
    },
    async onInput(v) {
      this.isUser = true;
      v = this.checkValidation(v == null ? '' : v);
      this.setBindArray(v);

      this.vdata = v;
      if (this.type == 'date') {
        this.$emit('update:value', v.replace(/[^0-9]/g, ''));
        this.$emit('change', v.replace(/[^0-9]/g, ''));
        this.$emit('valid', v.replace(/[^0-9]/g, ''));
      } else {
        this.$emit('update:value', v);
        this.$emit('change', v);
        this.$emit('valid', v);
      }
    },
    onBlur() {},
    onOpen(e) {
      if (this.type === 'time') {
        setTimeout(() => {
          document
            .querySelector('[data-type="minute"]')
            .closest('.mx-scrollbar-wrap')
            .scrollTo(0, 0);
        }, 10);
      }
      if (e.target.disabled) {
        setTimeout(() => {
          this.internalOpen = false;
          this.$emit('update:open', false);
        }, 1);
      } else {
        setTimeout(() => {
          this.$emit('update:open', true);
        }, 1);
      }
    },
    onClear() {
      this.$emit('clear');
    },
    onClose() {
      this.$emit('update:open', false);
    },
    checkParameter() {
      if (!this.isUser) {
        this.originalValue = this.vdata;
      }

      if (this.originalValue !== this.vdata) {
        this.$refs.datePicker.$el.setAttribute('data-changed', '');
      } else {
        this.$refs.datePicker.$el.removeAttribute('data-changed');
      }
      this.isUser = false;
    },
  },
};
</script>
<style>
.mx-icon-calendar {
  cursor: pointer;
  right: 6px;
}

.icon-calendar {
  background: url('../../assets/img/bg_calendar_s.png') no-repeat -1px -17px;
  border: none;
  width: 13px;
  height: 14px;
}

.icon-calendar-off {
  background: url('../../assets/img/bg_calendar_s.png') no-repeat -1px -1px;
  border: none;
  width: 13px;
  height: 14px;
}
</style>
