export default that => {
  return {
    Cfg: {
      CanEdit: 0,
    },
    Cols: [
      {Header: '거래처명', Name: 'custName', Type: 'Text', RelWidth: 1, MinWidth: 200},
      {Header: '계약번호', Name: 'poNo', Type: 'Text', Width: 150},
      {Header: '계약명', Name: 'ttl', Type: 'Text', Width: 150},
      {Name: 'amt', Type: 'Text', Visible: 0},
      {Name: 'vatAmt', Type: 'Text', Visible: 0},
      {Name: 'taxexe', Type: 'Text', Visible: 0},
      {Name: 'sumAmt', Type: 'Text', Visible: 0},
      {Name: 'billNo', Type: 'Text', Visible: 0},
      {Name: 'billTaxNo', Type: 'Text', Visible: 0},
      {Name: 'flNo', Type: 'Text', Visible: 0},
      {Name: 'flTaxNo', Type: 'Text', Visible: 0},
    ],
  };
};
