export default that => {
  return {
    Cfg: {CanEdit: 1},
    Def: {
      Row: {NoColor: 1, CanFocus: 0},
    },
    Cols: [
      {Header: '제출 서류명', Name: 'fileNm', Type: 'Text', RelWidth: 1, CanEdit: false, Align: 'center'},
      {Name: 'fileCd', Visible: false},
    ],
  };
};
