var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pmis-content-box',{scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_c('iui-text',{attrs:{"type":"search","width":"200px"},on:{"update:value":_vm.setSearchText,"enter":_vm.onSearch}}),_c('iui-button',{attrs:{"value":"검색"},on:{"click":_vm.onSearch}})]},proxy:true}])},[_c('iui-container-new',{attrs:{"type":"css-flex","flex-direction":"row"}},[_c('i-col',[_c('i-row',[_c('pmis-content-box',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("현장정보")]},proxy:true}])},[_c('ib-sheet',{attrs:{"uid":_vm._uid,"options":_vm.Options,"loadSearchData":_vm.loadSearchData},on:{"loadSheet":_vm.loadSheet}})],1)],1),(_vm.isCopyOpt)?_c('i-spacer'):_vm._e(),(_vm.isCopyOpt)?_c('i-row',{attrs:{"height":"120px"}},[_c('iui-container-new',{attrs:{"type":"css-flex"}},[_c('i-row',[_c('i-col',[_c('pmis-content-box',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("복사옵션")]},proxy:true}],null,false,1009887433)},[_c('iui-container-new',{attrs:{"type":"table","theme":"bullet","header-width":"60px"}},[_c('i-row',[_c('i-col-header',[_vm._v("재료비")]),_c('i-col',[_c('iui-text',{attrs:{"type":"rate","value":_vm.matPriceRt},on:{"update:value":function (value) {
                            _vm.matPriceRt = value;
                          }}})],1)],1),_c('i-row',[_c('i-col-header',[_vm._v("노무비")]),_c('i-col',[_c('iui-text',{attrs:{"type":"rate","value":_vm.labPriceRt},on:{"update:value":function (value) {
                            _vm.labPriceRt = value;
                          }}})],1)],1),_c('i-row',[_c('i-col-header',[_vm._v("경비")]),_c('i-col',[_c('iui-text',{attrs:{"type":"rate","value":_vm.oprPriceRt},on:{"update:value":function (value) {
                            _vm.oprPriceRt = value;
                          }}})],1)],1)],1)],1)],1),_c('i-col',{attrs:{"width":"280px"}},[_c('pmis-content-box',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("절삭옵션")]},proxy:true}],null,false,2150773717)},[_c('iui-container-new',{attrs:{"type":"table","theme":"bullet","header-width":"100%"}},[_c('i-row',[_c('i-col-header',[_c('iui-text',{attrs:{"type":"number","label":"100원 이상 ~ 1,000원 미만","suffix":"자리 절삭","label-width":"160px","width":"260px","value":_vm.thousandDn,"max-length":"1"},on:{"update:value":function (value) {
                            _vm.thousandDn = value;
                          }}})],1)],1),_c('i-row',[_c('i-col-header',[_c('iui-text',{attrs:{"type":"number","label":"1,000원 이상 ~ 10,000원 미만","suffix":"자리 절삭","label-width":"160px","width":"260px","value":_vm.tenThousandDn,"max-length":"1"},on:{"update:value":function (value) {
                            _vm.tenThousandDn = value;
                          }}})],1)],1),_c('i-row',[_c('i-col-header',[_c('iui-text',{attrs:{"type":"number","label":"10,000원 이상","suffix":"자리 절삭","label-width":"160px","width":"260px","value":_vm.tenThousandUp,"max-length":"1"},on:{"update:value":function($event){_vm.tenThousandUp=$event}}})],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }