<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <label>제목</label>
      <iui-text type="search" :value.sync="searchText" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onClick,
      }"
    />
  </pmis-content-box>
</template>

<script>
import {mapGetters} from 'vuex';
import option from './sheetOption/QnaBoardListSheet.js';
import {selectQnaList} from '../api/qna.js';
export default {
  data() {
    return {
      Options: option(this),
      loadSearchData: [],
      searchText: '',
      subVue: [
        {
          name: `qnaBoardView${this._uid}`,
          url: `/qnaBoardView${this._uid}`,
          path: './view/board/qna/components/QnaBoardView.vue',
        },
      ],
      natDs: $getConstants('NAT_DS_QNA').code,
    };
  },
  computed: {
    ...mapGetters('menu', {menu: 'menu'}),
  },
  created() {
    this.addFuncSearch(this.onSearch);
    this.addFuncNew(this.onCreate);
    this.onSearch();
  },
  activated() {
    if (this.$route.params.search) {
      this.onSearch();
    }
  },
  methods: {
    async onSearch() {
      const param = {
        qid: this.userInfo.empNo,
        natDs: this.natDs,
        searchText: this.searchText,
      };
      const response = await selectQnaList(param);
      this.loadSearchData = response.data;
    },
    onCreate() {
      this.onGoVue({
        pgmCd: this.pgmCd,
        empNo: this.userInfo.empNo,
        natDs: this.natDs,
        boardStatus: 'I',
      });
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.onGoVue({
          pgmCd: e.row.pgmCd,
          empNo: e.row.qid,
          natNo: e.row.natNo,
          natDs: e.row.natDs,
          rqId: e.row.rqId,
          boardStatus: 'E',
        });
      }
    },
    onGoVue(param) {
      let routeName = this.$route.name;
      this.goVue(`/qnaBoardView${this._uid}`, {...param, routeName});
    },
  },
};
</script>

<style></style>
