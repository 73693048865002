<template>
  <pmis-tab-box>
    <template v-slot:title>포틀릿목록</template>
    <pmis-content-box>
      <template v-slot:header-left>
        <label>포틀릿그룹</label>
        <iui-select p-code="0661" defaultCd="A" :value.sync="portletGroup" />
        <iui-text width="200px" max-length="50" :value.sync="portletName" />
        <iui-button value="검색" @click="selectPortletList" />
      </template>

      <iui-container-new type="ibsheet" :id="elId" />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      portletGroup: null,
      portletName: null,
    };
  },
  beforeCreate() {
    $mapMutations(this, ['setPortletData', 'setDragTrue', 'setDragFalse']);
  },
  created() {
    this.addEvent([{name: 'portletList_selectPortletList', func: this.selectPortletList}]);
  },
  mounted() {
    this.initSheet();
  },
  methods: {
    async initSheet() {
      const OPT = {
        Cfg: {
          SearchMode: 0,
          CanDrag: 1,
        },
        Cols: [
          {
            Header: '포틀릿그룹',
            Name: 'portletGroup',
            Type: 'Enum',
            Width: 260,
            RelWidth: 1,
            CanEdit: 0,
            EnumKeys: await $getCodeIBSheetEnum('KEY', $getConstants('PORTLET_GROUP').code),
            Enum: await $getCodeIBSheetEnum('VALUE', $getConstants('PORTLET_GROUP').code),
          },
          {
            Header: '포틀릿ID',
            Name: 'portletId',
            Type: 'Text',
            Width: 180,
            CanEdit: 0,
          },
          {
            Header: '포틀릿명',
            Name: 'portletName',
            Type: 'Text',
            Width: 250,
            CanEdit: 0,
          },
          {
            Header: '포틀릿사이즈',
            Name: 'size',
            Type: 'Enum',
            Width: 100,
            CanEdit: 0,
            EnumKeys: await $getCodeIBSheetEnum('KEY', $getConstants('PORTLET_SIZE').code),
            Enum: await $getCodeIBSheetEnum('VALUE', $getConstants('PORTLET_SIZE').code),
          },
          {
            Name: 'mnuCd',
            Visible: false,
          },
        ],
        Events: {
          onRenderFirstFinish: this.selectPortletList,
        },
      };

      this.sheet = await PMISSheet.createSheet({el: this.elId, options: OPT});

      this.sheet.bind('onStartDrag', e => {
        this.setPortletData(e.row);
        this.setDragTrue();
      });
      this.sheet.bind('onEndDrag', e => {
        setTimeout(() => {
          this.setDragFalse();
          e;
        }, 100);
      });
    },
    async selectPortletList() {
      const response = await axios.post('/portlet/selectPortletList', {
        portletGroup: this.portletGroup,
        portletName: this.portletName,
      });
      this.sheet.loadSearchData(response.data, 0);
    },
  },
};
</script>

<style></style>
