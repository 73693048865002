<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col style="text-align:center">
        <img :src="photos.imgUrl" height="100%" @click="clickImage" />
      </i-col>
    </i-row>
    <i-row height="200px">
      <i-col>
        <iui-card-carousel
          :showViewer="false"
          :items="photos.tImgs"
          :imageHeight="170"
          :imageWidth="170"
          @inited="cardCarousel_viewerInited"
          @click-card="clickCard"
        />
      </i-col>
    </i-row>
    <i-row height="120px">
      <i-col>
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col-header>단위작업명</i-col-header>
            <i-col>
              <iui-text :value="detailInfo.wbsNm" readonly />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>촬영일자</i-col-header>
            <i-col>
              <iui-text :value="detailInfo.phoDt" readonly />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>사진설명</i-col-header>
            <i-col>
              <iui-text type="multi" :value="detailInfo.rmk" readonly />
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
/**
 * 건설사 현장포탈 > 현장사진팝업
 *
 * */
import {selectConstructPhotoList, selectPhotoFileInfoList} from '@/view/bsnsManage/Process/Photo/api/photo.js';
export default {
  data() {
    return {
      photoInfoList: [],
      photoFileList: [],
      FILE_URL:
        location.protocol.indexOf('https') !== -1 ? process.env.VUE_APP_FILE_URLS : process.env.VUE_APP_FILE_URL,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['detailInfo', 'photos']);
    $mapMutations(this, ['setDetailInfo', 'setPhotos', 'initPhotos']);
  },
  created() {
    this.addEvent({name: 'PjtPhotoPopup_setPhotoList', func: this.setPhotoList});
  },
  methods: {
    cardCarousel_viewerInited(viewer) {
      this.$viewer = viewer;
    },
    clickImage() {
      this.$viewer.view(this.photos.index);
    },
    clickCard(index) {
      this.setPhotos({index: index, imgUrl: this.photos.mImgs[index].imgSrc});
      let info = this.photoInfoList.find(item => item.flNo == this.photos.tImgs[index].flNo);
      this.setDetailInfo(info);
    },
    getImageUrl(flNo, grpNo, flDs) {
      return `${this.FILE_URL}/file/getImage?pgmCd=${this.pgmCd}&flNo=${flNo}&grpNo=${grpNo}&flDs=${flDs}`;
    },
    async setPhotoList(day) {
      this.initPhotos();

      const flNoArr = [];
      const response = await selectConstructPhotoList({prjCd: this.prjCd, phoDt: day});
      if (response.status == 200) {
        if (response.data.length) {
          this.photoInfoList = response.data;
          this.photoInfoList.forEach(item => {
            flNoArr.push(item.flNo);
          });
          flNoArr.sort((x, y) => x - y);
        }
      }

      this.photoFileList = (
        await Promise.all(
          flNoArr.map(async flNo => {
            const response = await selectPhotoFileInfoList({flNo});
            return response.data;
          })
        )
      ).flat();

      if (this.photoFileList.length) {
        let thumbnails = [];
        let photos = [];
        this.photoFileList.forEach(item => {
          if (item.flDs == this.$store.state.code2.codeAlias.FLDS_IMAGE.code) {
            // 이미지
            item['imgSrc'] = this.getImageUrl(item.flNo, item.grpNo, item.flDs);
            photos.push(item);
          } else if (item.flDs == this.$store.state.code2.codeAlias.FLDS_IMAGE_THUMBNAIL.code) {
            // 썸네일
            item['imgSrc'] = this.getImageUrl(item.flNo, item.grpNo, item.flDs);
            item['thumbnailSrc'] = this.getImageUrl(item.flNo, item.grpNo, item.flDs);

            thumbnails.push(item);
          }
        });

        if (photos.length) {
          this.setPhotos({mImgs: photos, tImgs: thumbnails});
          setTimeout(() => this.clickCard(0), 10);
        }
      }
    },
  },
};
</script>

<style></style>
