export default that => {
  return {
    Cols: [
      {Name: 'total', Visible: false, CanEdit: false},
      {Name: 'sncNo', Visible: false, CanEdit: false},
      {
        Header: '월별',
        Name: 'month',
        RelWidth: 1,
        Align: 'center',
        CanEdit: false,
      },
      {
        Header: '자재비',
        Name: 'matAmt',
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: '노무비',
        Name: 'labAmt',
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: '장비비(유류대포함)',
        Name: 'equipAmt',
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: '경비(직접비)',
        Name: 'oprAmt1',
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: '경비(간접비)',
        Name: 'oprAmt2',
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: '외주비',
        Name: 'subAmt',
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: '합계',
        Name: 'sumAmt',
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
    ],
  };
};
