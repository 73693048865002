export default that => {
  return {
    Cols: [
      {
        Header: '코드',
        Name: 'cmnDCd',
        Type: 'Text',
        ResultMask: '^[A-Za-z0-9]{0,6}$',
        ResultMessage: '영문, 숫자 조합으로 6자리까지만 입력가능합니다.',
        Align: 'center',
        Width: 100,
        CanEdit: false,
        Size: 6,
      },
      {
        Header: '코드명',
        Name: 'cmnDNm',
        Type: 'Text',
        Align: 'left',
        RelWidth: 1,
        CanEdit: true,
      },
      {
        Header: '사용여부',
        Name: 'useDs',
        Type: 'Enum',
        Align: 'left',
        Width: 100,
        EnumKeys: $getConstants('USE_YN').code,
        Enum: $getConstants('USE_YN').code,
        CanEdit: true,
      },
      {
        Header: '비고',
        Name: 'rmk',
        Type: 'Text',
        Align: 'left',
        RelWidth: 1,
        CanEdit: true,
      },
      {Name: 'pgmCd', Visible: false},
      {Name: 'cmnUCd', Visible: false},
    ],
  };
};
