export default that => {
  return {
    Cfg: {
      SearchMode: 0,
    },
    Cols: [
      {
        Header: '아이디',
        Name: 'empNo',
        Type: 'Text',
        Align: 'Center',
        Width: 80,
        Visible: 1,
        CanEdit: 0,
      },
      {
        Header: '성명',
        Name: 'name',
        Type: 'Text',
        Width: 120,
        Visible: 1,
        CanEdit: 0,
      },
      {
        Header: '최종접속시간',
        Name: 'lgnDttm',
        Type: 'Text',
        Align: 'Center',
        RelWidth: 1,
        MinWidth: 150,
        Visible: 1,
        CanEdit: 0,
      },
    ],
  };
};
