/** Index */
export const insertNewCntrctInfo = param => $save('/cntrctInfoRegist/insertNewCntrctInfo', param);
/** DetailInfo */
export const selectStampTaxAmt = param => axios.post('/cntrctInfoRegist/selectStampTaxAmt', param);
export const saveCntrctInfo = param => $save('/cntrctInfoRegist/saveCntrctInfo', param);
export const deleteCntrctInfo = param => $save('/cntrctInfoRegist/deleteCntrctInfo', param);
export const updateFileNoAndRgprInfo = param => axios.post('/cntrctInfoRegist/updateFileNoAndRgprInfo', param);
/** GuarantyDtls */
export const selectGuarantyList = param => axios.post('/cntrctInfoRegist/selectGuarantyList', param);
export const saveGuarantyInfo = param => $save('/cntrctInfoRegist/saveGuarantyInfo', param);
export const deleteGuarantyInfo = param => $save('/cntrctInfoRegist/deleteGuarantyInfo', param);
/** Orght */
export const selectPjtOrgList = param => axios.post('/cntrctInfoRegist/selectPjtOrgList', param);
/** CntrctChangeDtls */
export const selectCntrctInfoList = param => axios.post('/cntrctInfoRegist/selectCntrctInfoList', param);
/** PrjJoint */
export const selectPjtJointList = param => axios.post('/cntrctInfoRegist/selectPjtJointList', param);
export const savePjtJointList = param => $save('/cntrctInfoRegist/savePjtJointList', param);
export const deletePjtJointList = param => $save('/cntrctInfoRegist/deletePjtJointList', param);
