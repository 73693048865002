<template>
  <!-- TODO: 개발완료 후 테스트용 삭제 -->
  <div @contextmenu="showContextTest" @click="hideContextTest" style="height:100%">
    <div id="testContext" class="testContext" ref="testContext" v-if="showContext">
      <ul>
        <li>
          <a href="#none" @click.prevent.self @click="onExpireDtForTest" v-if="rfqStatus.isRfqSpotDscr">
            현설마감처리 [테스트용]
          </a>
          <a href="#none" @click.prevent.self @click="showExtendSpotDscrDtModal" v-if="rfqStatus.isRfqSpotDscrExpired">
            현설일자변경 [테스트용]
          </a>
          <a href="#none" @click.prevent.self @click="onExpireDtForTest" v-if="rfqStatus.isRfqBidding">
            입찰마감처리 [테스트용]
          </a>
        </li>
      </ul>
    </div>

    <pmis-tab-box>
      <template #title>입찰정보</template>
      <template #title-right>
        <template v-if="!rfqStatus.isCancel">
          <iui-button value="입찰예정가변경" @click="openBidPlanAmtModal" />

          <template v-if="rfqStatus.isRfqBidding">
            <iui-button value="마감시간 연장" @click="openExtendModal" />
            <iui-button value="현설결과취소" v-if="rfqStatus.isEnableSpotCancel" @click="onCancelSpotDscr" />
          </template>

          <template v-else-if="rfqStatus.isRfqBiddingExpired">
            <iui-button value="입찰개봉" @click="openQuot" />
            <iui-button value="차수조회" @click="openDegreeModal" v-if="rfqStatus.isReBid" />
          </template>

          <iui-button value="입찰취소" @click="cancelRfq" />
        </template>
      </template>

      <form onsubmit="return false;">
        <iui-container-new type="css-flex">
          <i-row :height="rfqStatus.isExistSpotDscr ? '315px' : '255px'">
            <i-col>
              <pmis-content-box>
                <template #title>입찰정보</template>
                <iui-container-new type="table" theme="bullet">
                  <colgroup>
                    <col width="100px" />
                    <col width="100%" />
                    <col width="10px" />
                    <col width="90px" />
                    <col width="50%" />
                  </colgroup>
                  <i-row>
                    <i-col-header>현장</i-col-header>
                    <i-col>
                      <iui-text :value="rfqInfo.spotDeptcd" :enable="false" width="130px" />
                      <iui-text :value="rfqInfo.spotDeptnm" :enable="false" />
                    </i-col>
                    <i-spacer />
                    <i-col-header>차수</i-col-header>
                    <i-col>
                      <iui-text
                        :value="rfqInfo.lastBidRnd.length ? `${rfqInfo.lastBidRnd}차` : ''"
                        :enable="false"
                        width="130px"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>입찰공고번호</i-col-header>
                    <i-col>
                      <iui-text :value="rfqInfo.rfqNo2" :enable="false" width="130px" />
                      <iui-text :value="rfqInfo.ttl" :enable="false" />
                    </i-col>
                    <i-spacer />
                    <i-col-header>입찰예정가</i-col-header>
                    <i-col>
                      <iui-text
                        :type="rfqInfo.bidOpenDt ? 'amount' : 'text'"
                        :align="rfqInfo.bidOpenDt ? '' : 'right'"
                        :value="rfqInfo.bidOpenDt ? rfqInfo.bidPlanAmt : '***,***,***'"
                        width="130px"
                        :enable="false"
                      />
                      <label>(부가세포함)</label>
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>공고일시</i-col-header>
                    <i-col>
                      <iui-text :value="rfqInfo.bidStartDt" :enable="false" width="130px" />
                    </i-col>
                    <i-spacer />
                    <i-col-header>마감일시</i-col-header>
                    <i-col>
                      <iui-text :value="rfqInfo.bidExpireDt" :enable="false" width="130px" />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>입찰담당</i-col-header>
                    <i-col>
                      <iui-text :value="rfqInfo.chrgUsrnm" :enable="false" width="130px" />
                    </i-col>
                  </i-row>
                  <template v-if="rfqStatus.isExistSpotDscr">
                    <i-row>
                      <i-col-header>현장설명회</i-col-header>
                      <i-col>
                        <iui-container-new type="table" theme="bullet" headerWidth="80px">
                          <i-row>
                            <i-col-header>현설일자</i-col-header>
                            <i-col>
                              <iui-text :value="rfqInfo.spotDscrYmd" :enable="false" align="center" width="80px" />
                              <iui-timepicker
                                width="30px"
                                :value="getTime(rfqInfo.spotDscrHh, rfqInfo.spotDscrMm)"
                                :enable="false"
                              />
                            </i-col>
                          </i-row>
                        </iui-container-new>
                      </i-col>
                      <i-spacer />
                      <i-col-header>현설담당</i-col-header>
                      <i-col>
                        <iui-text :value="rfqInfo.spotDscrUsrnm" :enable="false" width="130px" />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col>&nbsp;</i-col>
                      <i-col>
                        <iui-container-new type="table" theme="bullet" headerWidth="80px">
                          <i-row>
                            <i-col-header>현설장소</i-col-header>
                            <i-col>
                              <iui-text :value="rfqInfo.spotDscrLoc" :enable="false" />
                            </i-col>
                          </i-row>
                        </iui-container-new>
                      </i-col>
                      <i-spacer />
                      <i-col>&nbsp;</i-col>
                      <i-col>&nbsp;</i-col>
                    </i-row>
                  </template>
                  <i-row>
                    <i-col-header>지급조건</i-col-header>
                    <i-col>
                      <iui-text
                        prefix="현금"
                        suffix="%"
                        type="rate"
                        :value="rfqInfo.payCashRatio"
                        :enable="false"
                        width="100px"
                      />
                      <iui-text
                        prefix="어음"
                        suffix="%"
                        type="rate"
                        class="ml10"
                        :value="rfqInfo.payCheckRatio"
                        :enable="false"
                        width="100px"
                      />
                      <iui-text
                        suffix="일 이내"
                        type="number"
                        class="ml10"
                        :value="rfqInfo.payTerm"
                        :enable="false"
                        width="100px"
                      />
                    </i-col>
                    <i-spacer />
                    <i-col-header>과세유형</i-col-header>
                    <i-col>
                      <iui-text :value="rfqInfo.taxTypenm" :enable="false" width="130px" />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>공사기간</i-col-header>
                    <i-col>
                      <iui-datepicker
                        :value="rfqInfo.constrFrDt"
                        @change="setRfqInfo({constrFrDt: $event.target.value})"
                        :enable="rfqStatus.isEnable"
                      />
                      <label>~</label>
                      <iui-datepicker
                        :value="rfqInfo.constrToDt"
                        @change="setRfqInfo({constrToDt: $event.target.value})"
                        :enable="rfqStatus.isEnable"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>공사명</i-col-header>
                    <i-col colspan="4">
                      <iui-text
                        :value="rfqInfo.itemNm"
                        @change="setRfqInfo({itemNm: $event.target.value})"
                        :enable="rfqStatus.isEnable"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>비고</i-col-header>
                    <i-col colspan="4">
                      <iui-text
                        :value="rfqInfo.itemRmrk"
                        @change="setRfqInfo({itemRmrk: $event.target.value})"
                        :enable="rfqStatus.isEnable"
                      />
                    </i-col>
                  </i-row>
                </iui-container-new>
              </pmis-content-box>
            </i-col>
          </i-row>
          <i-spacer />
          <i-row>
            <i-col>
              <!-- 공사내역 -->
              <RfqItemList />
            </i-col>
          </i-row>
          <i-spacer />
          <i-row min-height="200px">
            <i-col>
              <!-- 협력사목록 -->
              <RfqCusList />
            </i-col>
          </i-row>
          <i-spacer />
          <i-row min-height="200px">
            <i-col>
              <pmis-file-list
                id="subRfqFile_BeforeOpen"
                title-visible
                title="첨부파일"
                :toolbar-visible="false"
                :file-number="rfqInfo.flNo"
              />
            </i-col>
          </i-row>
        </iui-container-new>
      </form>

      <iui-modal name="bidPlanAmtModal" title="입찰예정가 변경" :btns="bidPlanAmtModalBtn" width="600px" height="300px">
        <BidPlanAmt :bidType="bidType" :rfqNo="rfqInfo.rfqNo" />
      </iui-modal>

      <iui-modal name="extendModal" title="마감시간 연장" :btns="extendModalBtn" size-type="size5">
        <Extend :bidType="bidType" :rfqNo="rfqInfo.rfqNo" />
      </iui-modal>

      <iui-modal name="degreeModal" title="차수조회" :btns="degreeModalBtn" size-type="size6">
        <Degree />
      </iui-modal>

      <!-- 테스트용 -->
      <iui-modal
        name="extendSpotDscrDtModal"
        title="현설일자 변경"
        :btns="[{name: '변경', callback: onExtendSpotDscrDt}]"
        width="220px"
        height="130px"
      >
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col>
              <iui-datepicker :value.sync="extendSpotDscr.spotDscrYmd" />
              <iui-datepicker type="time" :value.sync="extendSpotDscr.spotDscrHhMm" />
            </i-col>
          </i-row>
        </iui-container-new>
      </iui-modal>
    </pmis-tab-box>
  </div>
</template>

<script>
import BeforeOpenMixin from '@/view/bidding/rfq/common/mixins/beforeOpenMixin.js';

import RfqItemList from '@/view/bidding/rfq/sub/common/components/RfqItemList.vue';
import Degree from '@/view/bidding/rfq/common/components/Degree.vue';
import IuiTimepicker from '@/components/common/IuiTimepicker.vue';

export default {
  name: 'SubBeforeOpen',
  components: {IuiTimepicker, RfqItemList, Degree},
  mixins: [BeforeOpenMixin],
  methods: {
    getTime(hour, minute) {
      hour = hour ? hour : '00';
      minute = minute ? minute : '00';
      return `${hour}:${minute}`;
    },
  },
};
</script>

<style scoped>
.testContext {
  position: fixed;
  z-index: 9999;
}

.testContext ul {
  background-color: yellow;
  max-height: 200px;
  overflow: auto;
}

.testContext ul li {
  height: 30px;
  line-height: 30px;
}

.testContext ul li a {
  width: 100%;
  display: block;
}

.testContext ul li:not(:last-child) ::after {
  content: '';
  display: block;
  border-bottom: dotted #aaa 2px;
}
</style>
