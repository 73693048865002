<template>
  <pmis-tab-box>
    <template #title>상세정보</template>
    <template #title-right>
      <iui-button value="신규" @click="onAddStamp" />
      <iui-button value="삭제" @click="onDeleteStamp" />
    </template>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <pmis-content-box>
            <template #title>인지세납부현황</template>
            <ib-sheet :uid="_uid" :options="sheetOpt" :loadSearchData="stampPaymentList" @loadSheet="sheet = $event" />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal name="stampRegistModal" title="인지세" :btns="modalBtns" width="1000px" height="360px">
      <StampRegistPopup ref="stampRegistPopup" />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/stampDetailInfo.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import {selectStampTaxInfos, deleteOfflineStampInfo} from '@/view/bidding/po/mat/api/po.js';
import {
  selectStampTaxInfos as selectStampTaxInfos_sub,
  deleteOfflineStampInfo as deleteOfflineStampInfo_sub,
} from '@/view/bidding/po/sub/api/subPo.js';

import StampRegistPopup from './StampRegistPopup.vue';
export default {
  components: {StampRegistPopup},
  props: {
    poNo: {
      type: String,
    },
    contSeq: {
      type: String,
    },
  },
  data() {
    return {
      sheetOpt: $addColCheckbox(sheetOpt(this), 1, 'chk', {
        CanEditFormula: fr => {
          const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
          // 건설사 : 계약완료 전, 협력사: 거래처전자서명중
          const c1 =
            userInfo.frmGb === $getConstants('FRM_GB_1').code
              ? this.poInfo.poSt < BIDDING_CONSTANTS.PROGRESS_STATE.PO_COMPLETE
              : this.poStatus.isPoSend;
          const c2 = String(fr.Row['stampBsnNo']) === String(userInfo.bsnNo);
          return c1 && c2;
        },
      }),
      stampPaymentList: [],
      modalBtns: [{name: '저장', callback: this.onSaveStamp}],
    };
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'poStatus', 'bidType']);
  },
  created() {
    this.addEvent([{name: 'StampDetailInfo_onSearch', func: this.onSearch}]);
  },
  methods: {
    async onSearch() {
      const selectStampTaxInfosApi = {mat: selectStampTaxInfos, sub: selectStampTaxInfos_sub};
      const response = await selectStampTaxInfosApi[this.bidType](this.poNo, this.contSeq);
      this.stampPaymentList =
        response.data.stampList?.map(item => {
          return {
            ...item,
            paymentInfo: item.stampNo?.length === 16 ? '전자수입인지' : '국세청홈택스',
          };
        }) ?? [];
    },
    onAddStamp() {
      this.$modal.show('stampRegistModal');
    },
    onSaveStamp() {
      const callback = () => {
        this.$modal.hide('stampRegistModal');
        this.onSearch();
      };
      this.$refs.stampRegistPopup.onSaveStamp(callback);
    },
    async onDeleteStamp() {
      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (!checkedRows.length) {
        this.$alert({title: '인지세 납부정보 삭제', message: '선택된 항목이 없습니다.'});
        return;
      }
      const deleteItems = checkedRows.map(item => ({
        poNo: item.poNo,
        contSeq: item.contSeq,
        stampSeq: item.stampSeq,
        stampFlNo: item.stampFlNo,
      }));

      const confirmMsg = {title: '인지세 납부정보 삭제', message: '인지세 납부정보를 삭제하시겠습니까?'};
      if (!(await this.$confirm(confirmMsg))) {
        return;
      }

      const deleteOfflineStampInfoApi = {mat: deleteOfflineStampInfo, sub: deleteOfflineStampInfo_sub};
      deleteOfflineStampInfoApi[this.bidType](deleteItems)
        .then(response => {
          if (response.data) {
            this.onSearch();
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
  },
};
</script>

<style></style>
