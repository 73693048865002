export default that => {
  return {
    Cols: [
      {
        Header: '하위메뉴경로',
        Type: 'Text',
        Name: 'mnuPth',
        RelWidth: 1,
        Align: 'Center',
        CanEdit: that.isBase,
      },
      {
        Header: '조회',
        Type: 'Bool',
        Name: 'vwYn',
        Width: 80,
        Align: 'Center',
        CanEdit: that.isBase,
      },
      {
        Header: '신규',
        Type: 'Bool',
        Name: 'newYn',
        Width: 80,
        Align: 'Center',
        CanEdit: that.isBase,
      },
      {
        Header: '저장',
        Type: 'Bool',
        Name: 'svYn',
        Width: 80,
        Align: 'Center',
        CanEdit: that.isBase,
      },
      {
        Header: '삭제',
        Type: 'Bool',
        Name: 'delYn',
        Width: 80,
        Align: 'Center',
        CanEdit: that.isBase,
      },
      {
        Header: '출력',
        Type: 'Bool',
        Name: 'prnYn',
        Width: 80,
        Align: 'Center',
        CanEdit: that.isBase,
      },
      {Name: 'frmGb', Visible: 0},
      {Name: 'mnuCd', Visible: 0},
    ],
  };
};
