<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <label>출역일자</label>
      <iui-datepicker name="investDt" :value="searchInfo.investDt" @change="setChangeInvestDt($event)" />
      <i class="prev-arrow" v-if="cud != 1" v-on:click="onClickDate('PREV')" />
      <i class="post-arrow" v-if="cud != 1" v-on:click="onClickDate('POST')" />
      <iui-text
        label="성명"
        type="search"
        :value="searchInfo.searchNm"
        @change="setSearchInfo({searchNm: $event.target.value})"
        @enter="onSearch"
      />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <template v-slot:header-right v-if="isCopy">
      <label>복사대상일자</label>
      <iui-datepicker :value.sync="targetInvestDt" :disabledDate="disabledDate" />
      <iui-button value="복사" @click="onCopy" />
      <iui-button @click="onPopAddExcel" value="엑셀등록" />
    </template>
    <template v-slot:header-right v-else>
      <iui-button @click="onPopAddExcel" value="엑셀등록" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onAfterChange,
        onSearchFinish,
        onClick,
      }"
    />

    <!-- 출/퇴근정보 -->
    <iui-modal name="attendanceModal" :title="attendanceTitle" sizeType="size1">
      <AttendanceInfoPopup />
    </iui-modal>

    <iui-modal name="unitOperWithExecDtls" :btns="unitOpertWithExecDtlsBtns" :title="unitOpertTitle" sizeType="size3">
      <UnitOpertWithExecDtlsPopup :costType="costType" :poNoSubcList="poNoSubcList" :jkdNo="jkdNo" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import UnitOpertWithExecDtlsPopup from '@/view/Resource/components/UnitOpertWithExecDtlsPopup.vue';
import AttendanceInfoPopup from './AttendanceInfoPopup.vue';
import padStart from 'lodash/padStart';
import options from './sheetOptions/AttendanceListSheet.js';
import {selectMonthClosePossibleAt} from '@/view/closing/expitmCloseRegist/api/expitmCloseRegist.js';
import {
  copyAttendanceList,
  deleteAttendanceList,
  deleteLabtDailyTime,
  saveAttendanceList,
  selectAttendanceList,
  selectAttendanceListByPrjUser,
  selectLastInvestDate,
} from '../api/attendanceRegist.js';

export default {
  components: {AttendanceInfoPopup, UnitOpertWithExecDtlsPopup},
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this), 2, 'chk', {
        CanEditFormula: e => this.monPjtCloseAt == 'N' && (e.Row.closeYn == '' || e.Row.closeYn == null),
      }),
      loadSearchData: [],
      costType: '',
      modalNm: '',
      attendanceTitle: '',
      monPjtCloseAt: '', //마감여부
      unitOpertWithExecDtlsBtns: [{name: '확인', callback: this.onUnitOpertWithExecDtlsConfirm}],
      unitOpertTitle: '',
      poNoSubcList: [],
      jkdNo: '',
      targetInvestDt: '',
      isCopy: false,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo']);
    $mapMutations(this, ['setSearchInfo']);
  },
  created() {
    this.costType = this.userInfo.simpleExec ? '' : 'L';
    this.addFuncPrj(this.onSearchLastInvestDate);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
    this.addEvent([{name: 'AttendanceList_onSearch', func: this.onSearch}]);
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    inputAdd(e) {
      if (e.event.Button != 1 || e.row.laborDs !== $getConstants('LABOR_DS3').code) {
        return;
      }

      function reOrder(row) {
        const nextRow = row.nextSibling;
        if (nextRow && row.investDt == nextRow.investDt && row.empNo == nextRow.empNo) {
          e.sheet.setValue(nextRow, 'rowNo', row.rowNo + 1, 1);
          reOrder(nextRow);
        }
      }

      if (this.monPjtCloseAt == 'N') {
        const addRow = e.sheet.copyRow(e.row, e.row.nextSibling);
        e.sheet.setValue(addRow, 'seq', '');
        e.sheet.setValue(addRow, 'wcode', '');
        e.sheet.setValue(addRow, 'headCode', '');
        e.sheet.setValue(addRow, 'midCode', '');
        e.sheet.setValue(addRow, 'itemSeq', '');
        e.sheet.setValue(addRow, 'itemCode', '');
        e.sheet.setValue(addRow, 'itemName', '');
        e.sheet.setValue(addRow, 'midName', '');
        e.sheet.setValue(addRow, 'dailyTimeWorkInTime', '');
        e.sheet.setValue(addRow, 'dailyTimeWorkOutTime', '');
        e.sheet.setValue(addRow, 'dailyTimeWorkTime', '');
        e.sheet.setValue(addRow, 'dailyTimeInvestQty', '0');
        reOrder(e.row);
        this.sheetMerge();
      }
    },
    async inputRemove(e) {
      if (e.event.Button != 1 || e.row.laborDs !== $getConstants('LABOR_DS3').code) {
        return;
      }
      if (this.monPjtCloseAt == 'N') {
        if (!(await this.$confirm({title: '출역내역삭제', message: '선택한 출역내역을 삭제하시겠습니까?'}))) {
          return;
        }

        if (!e.row.Added) {
          let param = {
            investDt: e.row.investDt,
            empNo: e.row.empNo,
            seq: e.row.seq,
            wcode: e.row.wcode,
            headCode: e.row.headCode,
            midCode: e.row.midCode,
            itemSeq: e.row.itemSeq,
          };

          const response = await deleteLabtDailyTime(param);
          if (response.data) {
            this.onSearch();
          }
        } else {
          this.sheet.removeRow(e.row);
          this.sheetMerge();
        }
      }
    },
    sheetMerge() {
      let Rows = this.sheet.getDataRows();
      let key = '';
      let sRow;
      let eRow;
      let mergeInfo = {};
      Rows.forEach(function(r) {
        if (key != r.investDt + r.empNo) {
          key = r.investDt + r.empNo;
          sRow = r.id;
          mergeInfo[sRow] = sRow;
        } else {
          eRow = r.id;
          mergeInfo[sRow] = eRow;
        }
      });

      for (let m in mergeInfo) {
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'chk', this.sheet.getRowById(mergeInfo[m]), 'chk');
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'investDt', this.sheet.getRowById(mergeInfo[m]), 'investDt');
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'blnDepNm', this.sheet.getRowById(mergeInfo[m]), 'blnDepNm');
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'empNo', this.sheet.getRowById(mergeInfo[m]), 'empNo');
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'name', this.sheet.getRowById(mergeInfo[m]), 'name');
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'dailyWorkInTime',
          this.sheet.getRowById(mergeInfo[m]),
          'dailyWorkInTime'
        );
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'workInInfo',
          this.sheet.getRowById(mergeInfo[m]),
          'workInInfo'
        );
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'dailyWorkOutTime',
          this.sheet.getRowById(mergeInfo[m]),
          'dailyWorkOutTime'
        );
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'workOutInfo',
          this.sheet.getRowById(mergeInfo[m]),
          'workOutInfo'
        );

        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'dailyWorkTime',
          this.sheet.getRowById(mergeInfo[m]),
          'dailyWorkTime'
        );
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'deductTime',
          this.sheet.getRowById(mergeInfo[m]),
          'deductTime'
        );
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'attendanceTime',
          this.sheet.getRowById(mergeInfo[m]),
          'attendanceTime'
        );
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'dailyInvestQty',
          this.sheet.getRowById(mergeInfo[m]),
          'dailyInvestQty'
        );
      }
    },
    onUnitOpertWithExecDtlsModal() {
      this.unitOpertTitle = this.poNoSubcList?.length ? '계약내역투입등록' : '실행내역투입등록';
      this.$modal.show('unitOperWithExecDtls');
    },
    onUnitOpertWithExecDtlsConfirm() {
      let focusedRow = this.sheet.getFocusedRow();
      this.callEvent({
        name: 'getUnitOpertWithExecDtlsData',
        param: data => {
          this.sheet.setValue(focusedRow, 'wbsCd', data.wbsCd, 1);
          this.sheet.setValue(focusedRow, 'wbsId', data.wbsId, 1);
          this.sheet.setValue(focusedRow, 'wbsNm', data.wbsNm, 1);
          this.sheet.setValue(focusedRow, 'wcode', data.wcode, 1);
          this.sheet.setValue(focusedRow, 'headCode', data.headCode, 1);
          this.sheet.setValue(focusedRow, 'midCode', data.midCode, 1);
          this.sheet.setValue(focusedRow, 'mgName', data.mgName, 1);
          this.sheet.setValue(focusedRow, 'itemName', data.itemName, 1);
          this.sheet.setValue(focusedRow, 'itemSeq', data.itemSeq, 1);
          this.sheet.setValue(focusedRow, 'itemCode', data.itemCode, 1);
          this.$modal.hide('unitOperWithExecDtls');
        },
      });
    },
    /** 현장 변경시 현장별 최종 투입일자 조회 */
    async onSearchLastInvestDate() {
      //조회조건 초기화
      this.initSearchData();
      const response = await selectLastInvestDate({...this.searchInfo});
      //투입일자 저장
      if (response.data.investDt) {
        this.setSearchInfo({investDt: response.data.investDt});
      } else {
        this.setSearchInfo({investDt: $_getCurrentDate()});
      }
      this.onMonthClose(this.searchInfo.investDt); //마감여부 조회
      this.onSearch();
    },
    initSearchData() {
      this.setSearchInfo({
        investDt: '', //  출역년월
        searchNm: '',
      });

      this.monPjtCloseAt = ''; //마감여부
    },
    async onSearch() {
      const param = {...this.searchInfo};
      const response = await selectAttendanceList(param);
      const list = this.sheet.util.convertDateOfList(response.data);
      this.loadSearchData = list;
      this.isCopy = !list.length && this.searchInfo.investDt == $_getCurrentDate();
      this.cud = 0;
    },
    async onAdd() {
      if (this.monPjtCloseAt == 'Y') {
        this.$alert({title: '마감완료', message: '월마감 상태이므로 출역등록은 불가합니다.'});
        return;
      }

      if (!this.searchInfo.investDt) {
        this.$alert({title: '출역일자', message: '출역일자를 선택해야합니다.'});
        return;
      }

      if (!$_bNowdateOverCheck(this.searchInfo.investDt)) {
        this.$alert({title: '출역일자', message: '금일 이후 일자 출역은 등록이 불가능 합니다.'});
        this.setSearchInfo({
          investDt: $_getCurrentDate(), //  출역년월
        });
        return;
      }

      const response = await selectAttendanceListByPrjUser(this.searchInfo);
      const list = this.sheet.util.convertDateOfList(response.data);
      const uniqueList = [];
      list.forEach(item => {
        const poNoSubc = item.poNoSubc;
        if (!uniqueList.find(unique => unique.empNo === item.empNo)) {
          const poNoSubcList = poNoSubc ? [poNoSubc] : [];
          uniqueList.push({...item, poNoSubcList});
        } else {
          if (poNoSubc) {
            uniqueList.find(unique => unique.empNo === item.empNo).poNoSubcList.push(poNoSubc);
          }
        }
      });
      uniqueList.map(item => {
        item.poNoSubcList = JSON.stringify(item.poNoSubcList);
        item.Added = true;
        return item;
      });
      this.loadSearchData = uniqueList;
    },
    async onSave() {
      if (!this.sheet.hasChangedData()) {
        this.$alert({title: '저장', message: '수정된 내용이 없습니다.'});
        return;
      }

      let attendanceList = this.sheet.getSaveJson(2).data.filter(row => row.dailyWorkInTime != ''); //수정된 데이터

      const timeCols = ['dailyWorkInTime', 'dailyWorkOutTime', 'dailyTimeWorkInTime', 'dailyTimeWorkOutTime'];

      let message = '';
      attendanceList.some(row => {
        const yyyy = row.investDt.substr(0, 4);
        const MM = row.investDt.substr(4, 2);
        const dd = row.investDt.substr(6, 2);
        timeCols.forEach(col => {
          row[col] = row[col] ? new Date(yyyy, MM - 1, dd, row[col].substr(0, 2), row[col].substr(3, 2)) : null;
        });

        if ((row.dailyTimeWorkInTime || row.dailyTimeWorkOutTime) && !row.itemSeq) {
          message = `${row.empNo}의 내역명을 입력해주세요.`;
          return true;
        }
        row.amt = row.price ? row.price * row.dailyInvestQty : 0;
      });
      if (message) {
        this.$alert({title: '저장', message: message});
        return;
      }

      try {
        const param = {
          investDt: this.searchInfo.investDt,
          attendanceList,
        };
        const response = await saveAttendanceList(param);
        if (response.data) {
          this.onSearch();
        }
      } catch (error) {
        this.$alert({title: '저장', message: error.message});
      }
    },
    async onDelete() {
      let rows = this.sheet.getRowsByChecked('chk');
      if (0 == rows.length) {
        this.$alert({title: '삭제', message: '삭제할 출역내역을 선택해 주세요.'});
        return;
      }

      if (!(await this.$confirm({title: '삭제', message: '선택한 출역정보를 삭제 하시겠습니까?'}))) return;

      let attendanceList = [];
      rows.forEach(e => {
        let param = {
          investDt: e.investDt,
          empNo: e.empNo,
        };
        attendanceList.push(param);
      });

      if (attendanceList.length > 0) {
        const param = {investDt: this.searchInfo.investDt, attendanceList};
        const response = await deleteAttendanceList(param);
        if (response.data) {
          this.onSearch();
        }
      }
    },
    onAfterChange(e) {
      if (e.col == 'dailyWorkInTime' || e.col == 'dailyWorkOutTime') {
        const timeInfo = this.getRowTimeInfo(e);
        /* 근무시간, 출역정보 SET - START */
        if (!!String(timeInfo.dailyTimeWorkInTime) && timeInfo.dateTime1 > timeInfo.dateTime3) {
          this.$alert({title: '출역정보', message: '출근시간은 투입정보의 시작시간 이후로 설정할 수 없습니다.'});
          this.sheet.setValue(e.row, 'dailyWorkTime', 0, 1);
          this.sheet.setValue(e.row, 'dailyWorkInTime', '', 1);
          return;
        }

        if (!(!!String(timeInfo.dailyWorkInTime) && !!String(timeInfo.dailyWorkOutTime))) {
          this.sheet.setValue(e.row, 'dailyWorkTime', 0, 1);
          return;
        }

        if (timeInfo.dateTime1 > timeInfo.dateTime2) {
          this.$alert({title: '출역정보', message: '퇴근시간은 출근시간 이후로 입력해주세요'});
          this.sheet.setValue(e.row, 'dailyWorkTime', 0, 1);
          this.sheet.setValue(e.row, 'dailyWorkOutTime', '', 1);
          return;
        }

        if (!!String(timeInfo.dailyTimeWorkOutTime) && timeInfo.dateTime2 < timeInfo.dateTime4) {
          this.$alert({title: '출역정보', message: '퇴근시간은 투입정보의 종료시간 전으로 설정할 수 없습니다.'});
          this.sheet.setValue(e.row, 'dailyWorkTime', 0, 1);
          this.sheet.setValue(e.row, 'dailyWorkOutTime', '', 1);
          return;
        }

        let dailyWorkTime = (timeInfo.dateTime2 - timeInfo.dateTime1) / 1000 / 60 / 60; // 근무시간 SET (퇴근시간 - 출근시간)
        dailyWorkTime = dailyWorkTime > 0 ? dailyWorkTime : 0;

        this.sheet.setValue(e.row, 'dailyWorkTime', dailyWorkTime, 1);
        /* 근무시간, 출역정보 SET - END */
      } else if (e.col == 'dailyTimeWorkInTime' || e.col == 'dailyTimeWorkOutTime') {
        const timeInfo = this.getRowTimeInfo(e);
        /* 근무시간, 투입정보 SET - START */
        if (timeInfo.dateTime1 > timeInfo.dateTime3) {
          this.$alert({title: '투입정보', message: '시작시간은 출근시간 이후로 입력해주세요'});
          this.sheet.setValue(e.row, 'dailyTimeWorkTime', 0, 1);
          this.sheet.setValue(e.row, 'dailyTimeWorkInTime', '', 1);
          return;
        }
        if (!(!!String(timeInfo.dailyTimeWorkInTime) && !!String(timeInfo.dailyTimeWorkOutTime))) {
          this.sheet.setValue(e.row, 'dailyTimeWorkTime', 0, 1);
          return;
        }

        if (timeInfo.dateTime3 > timeInfo.dateTime4) {
          this.$alert({title: '투입정보', message: '종료시간은 시간시간 이후로 입력해주세요'});
          this.sheet.setValue(e.row, 'dailyTimeWorkTime', 0, 1);
          this.sheet.setValue(e.row, 'dailyTimeWorkOutTime', '', 1);
          return;
        }
        if (!!String(timeInfo.dailyWorkOutTime) && timeInfo.dateTime2 < timeInfo.dateTime4) {
          this.$alert({title: '투입정보', message: '퇴근시간 이후에 종료할 수 없습니다.'});
          this.sheet.setValue(e.row, 'dailyTimeWorkTime', 0, 1);
          this.sheet.setValue(e.row, 'dailyTimeWorkOutTime', '', 1);
          return;
        }

        let dailyTimeWorkTime = (timeInfo.dateTime4 - timeInfo.dateTime3) / 1000 / 60 / 60; // 근무시간 SET (퇴근시간 - 출근시간)
        dailyTimeWorkTime = dailyTimeWorkTime > 0 ? dailyTimeWorkTime : 0;

        if (!Number.isInteger(dailyTimeWorkTime)) {
          dailyTimeWorkTime = parseFloat(dailyTimeWorkTime.toFixed(1));
        }

        this.sheet.setValue(e.row, 'dailyTimeWorkTime', dailyTimeWorkTime, 1);

        let dailyTimeInvestQty = 0;
        const rowsQty = e.sheet.getDataRows().filter(row => row.empNo === e.row.empNo);

        if (dailyTimeWorkTime === 0) {
          return;
        }
        let sumQty = 0;
        rowsQty.forEach((row, index) => {
          dailyTimeInvestQty = dailyTimeWorkTime / 8;
          if (rowsQty.length - 1 > index) {
            sumQty += dailyTimeInvestQty;
          } else {
            dailyTimeInvestQty = index === 0 ? 1 : 1 - sumQty;
          }
          this.sheet.setValue(row, 'dailyTimeInvestQty', dailyTimeInvestQty, 1);
        });

        /* 근무시간, 투입정보 SET - END */
      }
    },
    getRowTimeInfo(e) {
      let dailyWorkInTime = this.sheet.getString(e.row, 'dailyWorkInTime');
      let dailyWorkOutTime = this.sheet.getString(e.row, 'dailyWorkOutTime');
      let dailyTimeWorkInTime = this.sheet.getString(e.row, 'dailyTimeWorkInTime');
      let dailyTimeWorkOutTime = this.sheet.getString(e.row, 'dailyTimeWorkOutTime');

      if (e.row.Block) {
        this.sheet.getDataRows().forEach(row => {
          if (e.row.empNo === row.empNo) {
            this.sheet.setValue(row, 'dailyWorkInTime', e.row.dailyWorkInTime);
            this.sheet.setValue(row, 'dailyWorkOutTime', e.row.dailyWorkOutTime);
            if (e.col == 'dailyWorkInTime' || e.col == 'dailyWorkOutTime') {
              if (
                Number(dailyTimeWorkInTime.replace(':', '') ?? 0) <
                Number(this.sheet.getString(row, 'dailyTimeWorkInTime').replace(':', '') ?? 0)
              ) {
                dailyTimeWorkInTime = this.sheet.getString(row, 'dailyTimeWorkInTime');
              }
              if (
                Number(dailyTimeWorkOutTime.replace(':', '') ?? 0) <
                Number(this.sheet.getString(row, 'dailyTimeWorkOutTime').replace(':', '') ?? 0)
              ) {
                dailyTimeWorkOutTime = this.sheet.getString(row, 'dailyTimeWorkOutTime');
              }
            }
          }
        });
      }

      const inHours = dailyWorkInTime ? padStart(dailyWorkInTime.split(':')[0], 2, '0') : 0;
      const inMinutes = dailyWorkInTime ? padStart(dailyWorkInTime.split(':')[1], 2, '0') : 0;
      const outHours = dailyWorkOutTime ? padStart(dailyWorkOutTime.split(':')[0], 2, '0') : 0;
      const outMinutes = dailyWorkOutTime ? padStart(dailyWorkOutTime.split(':')[1], 2, '0') : 0;
      const timeInHours = dailyTimeWorkInTime ? padStart(dailyTimeWorkInTime.split(':')[0], 2, '0') : 0;
      const timeInMinutes = dailyTimeWorkInTime ? padStart(dailyTimeWorkInTime.split(':')[1], 2, '0') : 0;
      const timeOutHours = dailyTimeWorkOutTime ? padStart(dailyTimeWorkOutTime.split(':')[0], 2, '0') : 0;
      const timeOutMinutes = dailyTimeWorkOutTime ? padStart(dailyTimeWorkOutTime.split(':')[1], 2, '0') : 0;

      let investDt = String(e.row.investDt); // 출역일자
      let year = Number(investDt.slice(0, 4));
      let month = Number(investDt.slice(4, 6));
      let day = Number(investDt.slice(6, 8));
      let dateTime1 = new Date(year, month, day, inHours, inMinutes, '00').getTime();
      let dateTime2 = new Date(year, month, day, outHours, outMinutes, '00').getTime();
      let dateTime3 = new Date(year, month, day, timeInHours, timeInMinutes, '00').getTime();
      let dateTime4 = new Date(year, month, day, timeOutHours, timeOutMinutes, '00').getTime();
      return {
        dailyWorkInTime,
        dailyWorkOutTime,
        dailyTimeWorkInTime,
        dailyTimeWorkOutTime,
        dateTime1,
        dateTime2,
        dateTime3,
        dateTime4,
      };
    },
    onSearchFinish(e) {
      this.poNoSubcList = e.sheet.getFirstRow()?.poNoSubcList
        ? JSON.parse(e.sheet.getFirstRow()?.poNoSubcList)
        : e.sheet.getFirstRow()?.poNoSubc
        ? [e.sheet.getFirstRow()?.poNoSubc]
        : [];
      this.jkdNo = e.sheet.getFirstRow()?.jkdNo;
      this.sheetMerge();
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.poNoSubcList = e.row.poNoSubcList
          ? JSON.parse(e.row.poNoSubcList)
          : e.row.poNoSubc
          ? [e.row.poNoSubc]
          : [];
        this.jkdNo = e.row.jkdNo;
      }
    },
    async setChangeInvestDt(v) {
      if (!v) {
        v = $_getCurrentDate();
      }
      if (String(v).length !== 8 || this.searchInfo.investDt === v) {
        return;
      }
      this.setSearchInfo({investDt: v});
      if (this.cud == 1) {
        // 월마감 체크
        await this.onMonthClose(v);
        this.onAdd();
      } else {
        // 재조회
        this.onSearch();
      }
    },
    disabledDate(date) {
      return $_getCurrentDate() <= $_dateToStringYYMMDD(date);
    },
    async onCopy() {
      if (!this.targetInvestDt) {
        this.$alert({title: '출역내역복사', message: '복사대상일자를 선택해주세요.'});
        return;
      }
      const message = `[${dateFormat(this.targetInvestDt)}]출역내역을<br>
      [${dateFormat(this.searchInfo.investDt)}]출역내역으로 복사하시겠습니까?`;
      if (!(await this.$confirm({title: '출역내역복사', message: message}))) {
        return;
      }
      const param = {
        investDt: this.searchInfo.investDt,
        targetInvestDt: this.targetInvestDt,
      };
      const response = await copyAttendanceList(param);
      if (response.data) {
        this.onSearch();
      }
    },
    onShowModal(type, row) {
      let param = {};

      if (type == 'workInInfo' || type == 'workOutInfo') {
        param.investDt = row.investDt;
        param.empNo = row.empNo;
        param.rgprId = row.rgprId;
        param.workTimeSe = type == 'workInInfo' ? 'IN' : 'OUT';
        this.attendanceTitle = type == 'workInInfo' ? '출근정보' : '퇴근정보';
      }
      this.modalNm = 'attendanceModal';
      this.$store.commit('setModalParam', param);
      this.$modal.show(this.modalNm);
    },
    async onMonthClose(date) {
      if (!date) return;
      const param = {closeMm: String(date).substring(0, 6)};
      const response = await selectMonthClosePossibleAt(param);
      if (response.data.CHECK1 == 'N') {
        this.monPjtCloseAt = 'Y';
      } else {
        this.monPjtCloseAt = 'N';
      }
      this.sheet.calculate(1);
    },
    async onClickDate(searchDateType) {
      const param = {...this.searchInfo, searchDateType};
      const response = await selectLastInvestDate(param);
      if (response.data) {
        this.onMonthClose(String(response.data));
        this.setSearchInfo({investDt: String(response.data)});
        this.onSearch();
      } else {
        this.$alert({title: '출역일자', message: `${searchDateType == 'PREV' ? '이전' : '다음'} 출역일이 없습니다.`});
      }
    },
    onPopAddExcel() {
      this.$modal.show('excelUploadPop');
    },
  },
};
</script>

<style></style>
