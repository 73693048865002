/** 회사주간일정 */
export const weeklySchedule = param => axios.post('/portletData/weeklySchedule', param);
/** 현장현황목록 */
export const selectProjectStatusList = param => axios.post('/portletData/selectProjectStatusList', param);
/** 회원가입요청 */
export const selectJoinRequestList = () => axios.post('/portletData/selectJoinRequestList', {});
/** 구매상담신청 */
export const selectPurchsCnsltRequestList = () => axios.post('/portletData/selectPurchsCnsltRequestList', {});
/** 질의응답 */
export const selectQnaList = () => axios.post('/portletData/selectQnaList', {});

/** 인력투입정보 */
export const selectLaborInput = param => axios.post('/portletData/selectLaborInput', param);
/** 장비투입정보 */
export const selectEquipInput = param => axios.post('/portletData/selectEquipInput', param);
/** 기성정보 */
export const selectPxcondInfo = param => axios.post('/portletData/selectPxcondInfo', param);
/** 수금정보 */
export const selectCollectInfo = param => axios.post('/portletData/selectCollectInfo', param);

/** 공지사항 */
export const selectNoticeList = () => axios.post('/portletData/selectNoticeList', {});
/** 자재입찰공고 */
export const selectMatRfqList = () => axios.post('/portletData/selectMatRfqList', {});
/** 외주입찰공고 */
export const selectSubRfqList = () => axios.post('/portletData/selectSubRfqList', {});

/** 자재입찰공고 (협력사) */
export const selectMatRfqListCustomer = () => axios.post('/portletData/selectMatRfqListCustomer', {});
/** 외주입찰공고 (협력사) */
export const selectSubRfqListCustomer = () => axios.post('/portletData/selectSubRfqListCustomer', {});

/** 결재대기 */
export const selectStandbyDoc = () => axios.post('/portletData/selectStandbyDoc', {});
/** 발송대기 */
export const selectSendStandbyDoc = () => axios.post('/portletData/selectSendStandbyDoc', {});
/** 접수대기 */
export const selectReceiptStandbyDoc = () => axios.post('/portletData/selectReceiptStandbyDoc', {});
/** 공람대기 */
export const selectPublicDoc = () => axios.post('/portletData/selectPublicDoc', {});

/** 공정사진 */
export const selectPrjPhoto = param => axios.post('/portletData/selectPrjPhoto', param);
/** 작업현황 */
export const selectWorkSummary = param => axios.post('/workdaily/selectWorkSummary', param);
/** 작업내역 */
export const selectWorkList = param => axios.post('/portletData/selectWorkList', param);
/** 자재투입 */
export const selectMatInput = param => axios.post('/portletData/selectMatInput', param);
/** 노무투입 */
export const selectLaborInput1 = param => axios.post('/portletData/selectLaborInput1', param);
/** 장비투입 */
export const selectEquipInput1 = param => axios.post('/portletData/selectEquipInput1', param);
/** 경비투입 */
export const selectExptInput = param => axios.post('/portletData/selectExptInput', param);
/** 외주투입 */
export const selectSubcInput = param => axios.post('/portletData/selectSubcInput', param);
