import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';

export default that => {
  const preset = that.$ibsheetHelper.preset;

  const orderSt = {
    [BIDDING_CONSTANTS.PROGRESS_STATE.PO_READY]: '작성중',
    [BIDDING_CONSTANTS.PROGRESS_STATE.PO_SIGN_READY]: '발주서송부',
    [BIDDING_CONSTANTS.PROGRESS_STATE.PO_COMPLETE]: '발주서접수',
    returnSupplier: '협력사반송',
  };

  return {
    Cfg: {CanEdit: 0, SpaceForDefaultValue: 1},
    Def: {Row: {NoColor: 1}},
    Cols: [
      {Header: '발주일자', Name: 'poDt', Type: 'Text', Align: 'center', Width: 120, Extend: preset.date},
      {
        Header: '발주번호',
        Name: 'poNo',
        Type: 'Text',
        Align: 'center',
        Cursor: 'pointer',
        TextStyle: 5,
        TextColor: '#FF0000',
        Width: 120,
        CustomFormat: v => COMMON_FUNCTION.getMakeFormat(v),
      },
      {Header: '발주명', Name: 'ttl', Type: 'Text', RelWidth: 1, MinWidth: 140},
      {Header: '발주금액(VAT포함)', Name: 'totAmt', Type: 'Text', RelWidth: 0.8, MinWidth: 120, Extend: preset.amount},
      {Header: '납품기일', Name: 'dlvReqDt', Width: 120, Extend: preset.date},
      {Header: '송부일시', Name: 'sendDt', Align: 'center', Width: 120, Extend: preset.date},
      {Header: '접수일시', Name: 'cusUsrSignDt', Align: 'center', Width: 120, Extend: preset.date},
      {
        Header: '진행상태',
        Name: 'poSt',
        Align: 'center',
        Width: 120,
        CustomFormat: v => orderSt[v],
        Formula: fr => {
          const poSt = fr.Row['poSt'];
          const isReturnY = fr.Row['returnYn'] === BIDDING_CONSTANTS.CODES.RETURN_Y;
          const isReturnTypeSupplier = fr.Row['returnType'] === BIDDING_CONSTANTS.CODES.RETURN_TYPE_ORDER;
          return isReturnY && isReturnTypeSupplier ? 'returnSupplier' : poSt;
        },
      },
    ],
  };
};
