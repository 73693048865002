export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 1},
    Def: {Row: {NoColor: 2, CanFocus: 0}},
    Cols: [
      {Header: '공종명', Name: 'srvNm', Type: 'Text', RelWidth: 1, MinWidth: 80, CanEdit: 0},
      {Header: '내역코드', Name: 'itemSeq', Type: 'Text', Align: 'center', Width: 70, CanEdit: 0},
      {Header: '내역명', Name: 'itemNm', Type: 'Text', RelWidth: 1, MinWidth: 80, CanEdit: 0},
      {Header: '규격', Name: 'sizeNm', Type: 'Text', RelWidth: 1, MinWidth: 80, CanEdit: 0},
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        Width: 50,
        Format: $getConstants('UNIT').code,
        CanEdit: 0,
      },
      {Header: '실행수량', Name: 'totQty', Width: 100, Extend: preset.quantity, CanEdit: 0},
      {Header: '금회수량', Name: 'qty', Width: 80, Color: '#E0E0F8', Extend: preset.quantity, CanEdit: 0},
      {Header: '단가', Name: 'unitAmt', Type: 'Text', Width: 100, Extend: preset.unitAmount, CanEdit: 0},
      {
        Header: '금액',
        Name: 'amt',
        Width: 140,
        Extend: preset.amount,
        CanEdit: 0,
        Formula: fr => fr.Row['qty'] * fr.Row['unitAmt'],
      },
      {Header: '비고', Name: 'srvRmrk', Type: 'Text', Width: 100, CanEdit: 0},

      {Name: 'rfqSeq', Visible: 0},
      {Name: 'prNo', Visible: 0},
      {Name: 'prSeq', Visible: 0},
      {Name: 'wcode', Visible: 0},
      {Name: 'headCode', Visible: 0},
      {Name: 'midCode', Visible: 0},
      {Name: 'itemCode', Visible: 0},
      {Name: 'costType', Visible: 0},
      {Name: 'planMatUprc', Visible: 0},
      {Name: 'planLabUprc', Visible: 0},
      {Name: 'planEquipUprc', Visible: 0},
      {Name: 'planCostUprc', Visible: 0},
    ],
  };
};
