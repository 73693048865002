export default that => {
  return {
    Cfg: {
      CanEdit: 0,
    },
    Cols: [
      {
        Header: '순번',
        Name: 'seq',
        Type: 'Text',
        Align: 'Center',
        Width: 60,
      },
      {
        Header: '수신처',
        Name: 'blnDepNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
      },
      {
        Header: '상태',
        Name: 'rcvSsDs',
        Type: 'Text',
        Align: 'Center',
        Format: $getConstants('RCV_SS_DS').code,
        Width: 100,
      },
      {
        Header: '수신자',
        Name: 'name',
        Type: 'Text',
        Align: 'Center',
        Width: 90,
      },
      {
        Header: '수신일시',
        Name: 'rcvDttm',
        Extend: that.$ibsheetHelper.preset.date,
        Width: 100,
      },
      {
        Header: '의견',
        Name: 'rmk',
        Type: 'Text',
        Width: 100,
      },
    ],
  };
};
