<template>
  <iui-content-box :loading="loading">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="300px">
          <pmis-content-box>
            <template v-slot:title>조직도</template>
            <ib-sheet
              :uid="_uid"
              :options="Options"
              :loadSearchData="loadSearchData"
              :events="{
                onSearchFinish,
                onClick,
              }"
              @loadSheet="loadSheet"
            />
          </pmis-content-box>
        </i-col>
        <i-spacer />
        <i-col minWidth="700px">
          <pmis-content-box>
            <template v-slot:title>{{ title }}</template>
            <component v-bind:is="currentView" page-type="orgCht" />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </iui-content-box>
</template>

<script>
/**
 * 계약정보등록(tab2) > 조직도
 * controls :
 *   OrgFlag1.vue 조직정보(발주처, 원도급사, 외주)
 *   OrgFlag2.vue 자사정보(건설사)
 *   basicInfo.vue 노무팀정보
 *   WorkerInfo.vue 구성원정보
 * */
import OrgFlag1 from './OrgFlag1.vue';
import LbrcTeamInfo from './LabconInfo.vue';
import WorkerInfo from './WorkerInfo.vue';
import options from './sheetOption/OrgchtSheet.js';
import {selectPjtOrgList} from '../api/cntrctInfoRegist.js';
import IuiContentBox from '@/components/Iui/components/IuiContentBox/IuiContentBox.vue';

export default {
  components: {IuiContentBox},
  data() {
    return {
      loadSearchData: [],
      Options: options(this),

      title: '',
      currentView: null,
      orgFlag: 1,
      orgFlagDtl: 1,
      loading: false,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'custInfo', 'cmpInfo', 'lbrcTeamInfo', 'workerInfo']);
    $mapMutations(this, [
      'setCustInfo',
      'setLbrcTeamInfo',
      'setWorkerInfo',
      'initCustInfo',
      'initLbrcTeamInfo',
      'initWorkerInfo',
    ]);
  },
  created() {
    this.addEvent([{name: 'selectPjtOrgList', func: this.selectPjtOrgList}]);
  },
  mounted() {
    this.callEvent({name: 'onFocusedRowData', param: '3'});
  },
  activated: function() {
    this.callEvent({name: 'onFocusedRowData', param: '3'});
  },
  methods: {
    async selectPjtOrgList(param) {
      this.loading = true;

      try {
        const response = await selectPjtOrgList(param);

        const tree = [];
        const rootOrgFlag = 0;
        const pjtOrgList = response.data.pjtOrgList; // 거래처 조직도 (발주처, 원도급사, 외주)

        // 1. 발주처
        const orderList = pjtOrgList.filter(obj => obj.orgFlagDtl == 1);
        if (orderList.length > 0) {
          for await (const x of orderList) {
            x.email2 = await $getCodeNm($getConstants('EMAIL_CD').code, x.email2);
          }

          tree.push({
            name: '발주처',
            Items: orderList,
            orgFlag: orderList[0].orgFlag,
            orgFlagDtl: rootOrgFlag,
          });
        }

        // 2. 원도급사
        const contractList = pjtOrgList.filter(obj => obj.orgFlagDtl == 2);
        if (contractList.length > 0) {
          for await (const x of contractList) {
            x.email2 = await $getCodeNm($getConstants('EMAIL_CD').code, x.email2);
          }

          tree.push({
            name: '원도급사',
            Items: contractList,
            orgFlag: contractList[0].orgFlag,
            orgFlagDtl: rootOrgFlag,
          });
        }

        // 3. 회사 인력정보
        const cmpInfo = response.data.cmpInfo;

        for await (const x of cmpInfo.items) {
          x.email2 = await $getCodeNm($getConstants('EMAIL_CD').code, x.email2);
        }

        tree.push({
          name: cmpInfo.name,
          Items: cmpInfo.items,
          orgFlag: cmpInfo.orgFlag,
          orgFlagDtl: rootOrgFlag,
        });

        // 4. 외주 인력정보
        const pjtSubconList = pjtOrgList.filter(obj => obj.orgFlagDtl == 4);
        if (0 < pjtSubconList.length) {
          pjtSubconList.forEach(obj => {
            Object.defineProperty(obj, 'Items', Object.getOwnPropertyDescriptor(obj, 'items'));
            delete obj['items'];
          });

          for await (const x of pjtSubconList) {
            x.email2 = await $getCodeNm($getConstants('EMAIL_CD').code, x.email2);
          }

          tree.push({
            name: '외주',
            Items: pjtSubconList,
            orgFlag: pjtSubconList[0].orgFlag,
            orgFlagDtl: rootOrgFlag,
          });
        }

        // 5. 노무팀 인력정보
        const lbrcTeamList = response.data.lbrcTeamList.filter(obj => obj.orgFlagDtl == 5);
        if (0 < lbrcTeamList.length) {
          lbrcTeamList.forEach(obj => {
            Object.defineProperty(obj, 'Items', Object.getOwnPropertyDescriptor(obj, 'items'));
            delete obj['items'];
          });

          for await (const x of lbrcTeamList) {
            x.email2 = await $getCodeNm($getConstants('EMAIL_CD').code, x.email2);
          }

          tree.push({
            name: '노무팀',
            Items: lbrcTeamList,
            orgFlag: lbrcTeamList[0].orgFlag,
            orgFlagDtl: rootOrgFlag,
          });
        }

        this.sheet.loadSearchData(tree);

        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    setInfo(row) {
      if (this.orgFlagDtl == 0) {
        const title = '조직도';
        const message = '하위조직을 선택하십시요';
        let callbackFunc = undefined;

        if (this.orgFlag == 1) callbackFunc = this.initCustInfo;
        if (this.orgFlag == 2) callbackFunc = this.initWorkerInfo;
        if (this.orgFlag == 3) callbackFunc = this.initWorkerInfo;
        if (this.orgFlag == 4) callbackFunc = this.initLbrcTeamInfo;
        this.$alert({title, message}, callbackFunc());
      } else {
        if (this.orgFlag == 1) this.setCustInfo(row);
        if (this.orgFlag == 2) this.setWorkerInfo(row);
        if (this.orgFlag == 3) this.setWorkerInfo(row);
        if (this.orgFlag == 4) this.setLbrcTeamInfo(row);
      }
      this.setPage();
    },
    setPage() {
      if (this.orgFlag == 1) {
        this.title = '조직정보';
        this.currentView = OrgFlag1;
      }
      if (this.orgFlag == 2 || this.orgFlag == 3) {
        this.title = '구성원정보';
        this.currentView = WorkerInfo;
      }
      if (this.orgFlag == 4) {
        this.title = '노무팀정보';
        this.currentView = LbrcTeamInfo;
      }
    },
    onSearchFinish(e) {
      const focusRow = e.sheet.getDataRows().find(row => row.orgFlag == 1 && row.orgFlagDtl != 0);
      if (focusRow !== undefined) {
        this.orgFlag = focusRow.orgFlag;
        this.orgFlagDtl = focusRow.orgFlagDtl;
        e.sheet.focus(focusRow);
        this.setInfo(focusRow);
      }
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.orgFlag = e.row.orgFlag;
        this.orgFlagDtl = e.row.orgFlagDtl;
        this.setInfo(e.row);
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped>
.view .box_w {
  overflow-x: hidden;
  padding-right: 10px;
}
</style>
