<template>
  <pmis-page ref="page">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList :isAll="true" />
        </i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row height="70px">
              <i-col>
                <Calendar />
              </i-col>
            </i-row>
            <i-spacer />
            <i-row>
              <i-col>
                <pmis-content-box v-show="!prjCd">
                  <iui-amount-unit :amount-unit="amountUnit" @change="onAmountChange" />
                  <WorkSummaryAll />
                </pmis-content-box>

                <iui-tab
                  v-show="prjCd"
                  :comps="tabList"
                  :keepAlive="true"
                  :isAuto="false"
                  @click="tabClick"
                  @after-active-tab="afterActiveTab"
                >
                  <template v-slot:tab-right>
                    <iui-button v-if="currentTab === 0 && !summary.sncNo && summary" @click="onApproval">
                      결재품의
                    </iui-button>
                    <iui-amount-unit :amount-unit="amountUnit" @change="onAmountChange" />
                  </template>
                </iui-tab>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
/**
 * 작업일보조회
 * components :
 *   PrjList 현장목록
 *   calendar 년월 목차
 *   탭 : 작업현황/작업내역/노무내역/장비내역/경비내역/외주내역
 *
 * */
import store from './store/index.js';
import Calendar from '@/view/Project/Process/WorkDaily/components/calendar.vue';
import PrjList from '@/view/Project/components/PrjList.vue';
import WorkSummaryAll from '@/view/Project/Process/WorkDaily/components/WorkSummaryAll.vue';
import {selectPjtContWcls} from '@/view/unitOpertManage/api/unitOpertRegist.js';

export default {
  components: {PrjList, Calendar, WorkSummaryAll},
  data() {
    return {
      tabCompNms: ['WorkSummary', 'Work', 'Material', 'Labor', 'Equipment', 'Expense', 'WorkGroup'],
      tabList: [
        {
          tabNm: '작업현황',
          compNm: 'WorkSummary',
          path: '@/view/Project/Process/WorkDaily/components/WorkSummary.vue',
        },
        {
          tabNm: '작업내역',
          compNm: 'Work',
          path: '@/view/Project/Process/WorkDaily/components/Work.vue',
        },
        {
          tabNm: '자재내역',
          compNm: 'Material',
          path: '@/view/Project/Process/WorkDaily/components/Material.vue',
        },
        {
          tabNm: '노무내역',
          compNm: 'Labor',
          path: '@/view/Project/Process/WorkDaily/components/Labor.vue',
        },
        {
          tabNm: '장비내역',
          compNm: 'Equipment',
          path: '@/view/Project/Process/WorkDaily/components/Equipment.vue',
        },
        {
          tabNm: '경비내역',
          compNm: 'Expense',
          path: '@/view/Project/Process/WorkDaily/components/Expense.vue',
        },
        {
          tabNm: '외주내역',
          compNm: 'WorkGroup',
          path: '@/view/Project/Process/WorkDaily/components/WorkGroup.vue',
        },
      ],
      currentTab: 0,
      amountUnit: '',
    };
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['searchInfo', 'activeResource', 'summary']);
    $mapActions(this, ['setSearchInfo', 'setActiveResource', 'tabSearch']);
  },
  created() {
    this.amountUnit = this.userInfo?.amountUnit
      ? this.userInfo.amountUnit
      : this.$store.state.code2.codeAlias.AMOUNT_UNIT_ONE_THOUSAND_WON.code;

    this.addEvent([{name: 'onSearch', func: this.onSearch}]);
    this.addFuncPrj(this.onSearch);
  },
  methods: {
    onSearch() {
      this.prjCd
        ? this.tabClick(this.currentTab)
        : this.callEvent({
            name: 'WorkSummaryAll_onSearch',
            param: this.amountUnit,
          });
    },

    tabClick(e) {
      this.currentTab = e;
      this.callEvent({name: 'setActiveTab', param: this.currentTab});
      this.setActiveResource(this.tabCompNms[e] ?? '');
    },
    async afterActiveTab(e) {
      if (this.activeResource != '') {
        //Tab 조회
        this.callEvent({name: `${this.activeResource}_onSearch`, param: this.amountUnit});
      }
    },
    async onApproval() {
      if (this.currentTab === 0) {
        const response = await selectPjtContWcls({});
        if (!response.data) {
          this.$alert({title: '작업내역', message: '현재 현장에 작업내역이 확정되지 않았습니다'});
          return;
        }
        this.callEvent({name: 'WorkSummary_onApproval'});
      }
    },
    onAmountChange(value) {
      this.amountUnit = value;
      if (this.prjCd) {
        this.callEvent({name: `${this.activeResource}_onAmountChange`, param: value});
      } else {
        this.callEvent({name: `WorkSummaryAll_onAmountChange`, param: value});
      }
    },
  },
};
</script>
