const state = {
  searchInfo: {
    runDt: '', //가동일자
    wbsIdList: [],
    wcode: '',
    clCode: '',
    searchNm: '',
  },
  focusKey: '', //장비코드
};

const getters = {
  searchInfo: state => state.searchInfo,
  focusKey: state => state.focusKey,
};

const actions = {};

const mutations = {
  setSearchInfo(state, payload) {
    for (let key in state.searchInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        if (key === 'wcode') {
          state.searchInfo['clCode'] = '';
          state.searchInfo['headCode'] = '';
          state.searchInfo['midCode'] = '';
        }
        state.searchInfo[key] = payload[key];
      }
    }
  },
  setFocusKey(state, payload) {
    state.focusKey = payload;
  },
  initFocusKey(state) {
    state.focusKey = '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
