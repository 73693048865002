<template>
  <iui-container-new type="css-flex">
    <i-row height="80px">
      <i-col>
        <iui-content-box>
          <template #title>계약서 반송</template>
          <div>
            º 계약서를 협력사에 반송합니다. (계약번호 : {{ poInfo.poNo2 }})
            <br />
            º 반송된 계약서는 협력사 담당자 확인후 처리됩니다.
          </div>
        </iui-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <iui-content-box>
          <template #title>반송사유 입력</template>
          <iui-text
            ref="reason"
            type="multi"
            :value.sync="reason"
            required
            :error-message="{title: '반송사유', message: '반송사유를 입력해야합니다.'}"
          />
        </iui-content-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import {rejectBuyer as rejectMat} from '@/view/bidding/po/mat/api/po.js';
import {rejectBuyer as rejectSub} from '@/view/bidding/po/sub/api/subPo.js';
export default {
  data() {
    return {reason: ''};
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'bidType']);
  },
  created() {
    this.addEvent({name: 'PoRejectBuyer_Reject', func: this.onReject});
  },
  mounted() {
    this.$refs.reason.$refs.textarea.focus();
  },
  methods: {
    async onReject(callback) {
      this.reason = this.reason.trim();

      const comfirmMsg = {title: '계약서 반송', message: '계약서를 반송하시겠습니까?'};
      if (!(await this.$confirm(comfirmMsg))) {
        return;
      }

      const saveApi = {mat: rejectMat, sub: rejectSub};
      saveApi[this.bidType](this.poInfo.poNo, this.poInfo.contSeq, this.reason)
        .then(response => {
          if (response.status === 200) {
            callback(response.data.poNo);
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
  },
};
</script>

<style></style>
