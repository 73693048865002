import Vue from 'vue';

import './param';
import './homepage/plugins/axios';
import '@/assets/homepage/css/style.scss';
import elementClosest from 'element-closest';
import Homepage from '@/Homepage.vue';
import Login from '@/Login.vue';
import router from '@/config/router';

elementClosest(window);

Vue.config.errorHandler = function(err, vm, info) {
  // 에러 핸들링
  // err;
  // vm;
  // info;
  // `type`은 Vue의 에러 타입입니다. 예: 라이프사이클 훅
  // 2.2.0+ 이상에서 사용할 수 있습니다
  console.group(`Vue Error Handler`);
  console.error(err);
  console.error(vm);
  console.error(info);
  console.groupEnd();
  alert(`에러가 발생했습니다. \n개발자도구에서 에러를 확인하여 주세요. \n${err}`);
};

Vue.config.warnHandler = function(msg, vm, trace) {
  // trace는 컴포넌트 계층 구조를 추적합니다.
  console.group(`Vue Warn Handler`);
  console.warn(msg);
  console.warn(vm);
  console.warn(trace);
  console.groupEnd();
  alert(`경고가 발생했습니다. \n개발자도구에서 경고를 확인하여 주세요. \n${msg}`);
};
let params;
window.$externalData = async data => {
  params = data;
  root.setParams(params);
};

const deepChildren = component => {
  if (component.$children.length) {
    component.$children.forEach(el => {
      if (window.openUid === el._uid) {
        el.setParams(params);
        return;
      }
      deepChildren(el);
    });
  }
};

Vue.mixin({
  methods: {
    setParams(params) {
      this.params = params;
    },
  },
  data() {
    return {
      params: {},
    };
  },
  watch: {
    params() {
      deepChildren(root);
    },
  },
});

const root = new Vue({
  router,
  render: h => h(location.pathname.indexOf('/login') === 0 ? Login : Homepage),
}).$mount('#app');
