<template>
  <ib-sheet
    :uid="_uid"
    :options="Options"
    :loadSearchData="loadSearchData"
    @loadSheet="loadSheet"
    :events="{onSearchFinish, onClick}"
  />
</template>

<script>
import options from './sheetOptions/UnitOpertClSheet.js';
import {selectContWclsMList} from '../api/unitOpertRegist.js';
export default {
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      focusKey: undefined,
    };
  },
  beforeCreate() {
    $mapMutations(this, ['setSumPlnWgh']);
  },
  created() {
    this.addEvent([
      {name: 'selectContWclsMList', func: this.selectContWclsMList},
      {name: 'getFocusedRow', func: this.getFocusedRow},
    ]);
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onSearchFinish(e) {
      this.deepChildren(e.sheet.getFirstRow());
      if (this.focusKey) {
        let focusRow;
        for (let row of this.sheet.getDataRows()) {
          if (this.focusKey === String(row.fldrNo)) {
            focusRow = row;
            break;
          }
        }
        e.sheet.focus(focusRow);
        this.callback(focusRow);
        this.focusKey = undefined;
      } else {
        e.sheet.focus(e.sheet.getFirstRow());
        this.callback(e.sheet.getFirstRow());
      }
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.callback(e.row);
      }
    },

    deepChildren(row) {
      if (!row || !row.childNodes.length) return;

      let deep2 = function(sheet, row) {
        if (row.childNodes) {
          let subChildRow;
          let sumPlnWgh = 0;
          for (let i = 0; i < row.childNodes.length; i++) {
            if (i == 0) subChildRow = row.firstChild;
            else subChildRow = subChildRow.nextSibling;

            let _row = {};
            if (subChildRow) {
              _row = deep2(sheet, subChildRow);
              sumPlnWgh += _row.plnWgh;
              row['isPlnWgh'] = false;
            } else {
              sumPlnWgh += subChildRow.plnWgh;
              row['isPlnWgh'] = true;
            }
            sheet.setValue(row, 'plnWgh', sumPlnWgh);
          }
        }
        return row;
      };

      let childRow;
      let sumPlnWgh = 0;

      for (let i = 0; i < row.childNodes.length; i++) {
        if (i == 0) childRow = row.firstChild;
        else childRow = childRow.nextSibling;

        if (childRow) {
          deep2(this.sheet, childRow);
          sumPlnWgh += childRow.plnWgh || 0;
          row['isPlnWgh'] = false;
        } else {
          sumPlnWgh += row.plnWgh || 0;
          row['isPlnWgh'] = true;
        }
      }
      this.sheet.setValue(row, 'plnWgh', sumPlnWgh);
      this.sheet.acceptChangedData();
    },

    async selectContWclsMList(focusKey) {
      const response = await selectContWclsMList({});
      this.focusKey = focusKey;

      let sumPlnWgh = 0;
      response.data.classificationList.forEach(obj => {
        sumPlnWgh += obj.plnWgh || 0;
        obj.isPlnWgh = true;
      });
      this.setSumPlnWgh(sumPlnWgh);

      if (sumPlnWgh != 100) {
        this.callEvent({
          name: 'setActiveTab',
          param: 0,
        });
      }

      let items = $_treeModel(
        response.data.classificationList,
        $getConstants('UNIT_OPERT_CL').code,
        'fldrNo',
        'uppFldrNo'
      );
      const stdClList = sessionStorage.getItem('stdCl');
      const stdCl = JSON.parse(stdClList).find(item => item.csNo === Number($getConstants('UNIT_OPERT_CL').code));
      stdCl.fldrNo = stdCl.csNo;
      stdCl.fldrNm = stdCl.csNm;
      stdCl.rmk = '';
      stdCl.isRoot = true;
      stdCl.Items = items;
      this.loadSearchData = [stdCl];
    },
    getFocusedRow(callback) {
      if (!callback) return;
      let row = this.sheet.getFocusedRow();
      if (!row) row = this.sheet.getRowById('AR1');
      callback(row);
    },
  },
};
</script>
