export default that => {
  return {
    Cfg: {
      SearchMode: 0, // 0 : FastLoad 모드 (대용량 처리 방식)
      CanEdit: 0,
      IgnoreFocused: true,
    },
    Cols: [
      {
        Header: '장비코드',
        Name: 'equipCode',
        Type: 'Text',
        Align: 'center',
        Width: 140,
      },
      {
        Header: '명칭',
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
      },
      {
        Header: '규격',
        Name: 'size',
        Type: 'Text',
        RelWidth: 1,
      },
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        RelWidth: 1,
        Format: $getConstants('UNIT').code,
      },
      {Name: 'flNo', Visible: false},
    ],
  };
};
