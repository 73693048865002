<template>
  <form onsubmit="return false;">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col colspan="2" minWidth="600px">
          <iui-container-new type="table" theme="bullet">
            <i-row>
              <i-col-header width="70px">성명</i-col-header>
              <i-col>
                <iui-text :value="laborInfo.name" :enable="false" width="120px" />
              </i-col>
              <i-spacer />
              <i-col width="70px">&nbsp;</i-col>
              <i-col>&nbsp;</i-col>
            </i-row>
            <!--------------------------------------------------------------------->
            <!------------------------- 일용직 정보 START -------------------------->
            <!--------------------------------------------------------------------->
            <template v-if="isDaily">
              <i-row>
                <i-col-header required>계약기간</i-col-header>
                <i-col colspan="2">
                  <iui-datepicker
                    :value="laborInfo.contStDate"
                    @change="v => setLaborInfo({contStDate: v})"
                    :enable="isEnable"
                    required
                    max-length="2"
                  />
                  <label>~</label>
                  <iui-datepicker
                    :value="laborInfo.contEdDate"
                    @change="v => setLaborInfo({contEdDate: v})"
                    :enable="isEnable"
                    required
                    max-length="2"
                  />
                </i-col>
              </i-row>
              <i-row minWidth="1000px">
                <i-col-header required>평일</i-col-header>
                <i-col>
                  <iui-timepicker
                    :group="{grpNm: 'ndayTime', seq: 1}"
                    label="근무시간"
                    :errorMessage="{title: '평일 근무시간', message: '필수입력입니다.'}"
                    :value="timeInfo.ndayStTime"
                    @change="v => setLaborTime({ndayStTime: v})"
                    required
                    suffix="~"
                    :enable="isEnable"
                  />
                  <iui-timepicker
                    :group="{grpNm: 'ndayTime', seq: 2}"
                    :errorMessage="{title: '평일 근무시간', message: '필수입력입니다.'}"
                    :value="timeInfo.ndayEnTime"
                    @change="v => setLaborTime({ndayEnTime: v})"
                    required
                    :enable="isEnable"
                  />
                </i-col>
                <i-spacer />
                <i-col-header>
                  토요일
                </i-col-header>
                <i-col>
                  <iui-timepicker
                    :group="{grpNm: 'sdayTime', seq: 1}"
                    label="근무시간"
                    :value="timeInfo.sdayStTime"
                    @change="v => setLaborTime({sdayStTime: v})"
                    suffix="~"
                    :enable="isEnable"
                  />
                  <iui-timepicker
                    :group="{grpNm: 'sdayTime', seq: 2}"
                    :value="timeInfo.sdayEnTime"
                    @change="v => setLaborTime({sdayEnTime: v})"
                    :enable="isEnable"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col>&nbsp;</i-col>
                <i-col>
                  <iui-timepicker
                    :group="{grpNm: 'nrst1Time1', seq: 1}"
                    label="휴게시간"
                    :value="timeInfo.nrst1StTime"
                    @change="v => setLaborTime({nrst1StTime: v})"
                    suffix="~"
                    :enable="isEnable"
                  />
                  <iui-timepicker
                    :group="{grpNm: 'nrst1Time1', seq: 2}"
                    :value="timeInfo.nrst1EdTime"
                    @change="v => setLaborTime({nrst1EdTime: v})"
                    :enable="isEnable"
                  />
                </i-col>
                <i-spacer />
                <i-col>&nbsp;</i-col>
                <i-col>
                  <iui-timepicker
                    :group="{grpNm: 'srst1Time2', seq: 1}"
                    label="휴게시간"
                    :value="timeInfo.srst1StTime"
                    @change="v => setLaborTime({srst1StTime: v})"
                    suffix="~"
                    :enable="isEnable"
                  />
                  <iui-timepicker
                    :group="{grpNm: 'srst1Time2', seq: 2}"
                    :value="timeInfo.srst1EdTime"
                    @change="v => setLaborTime({srst1EdTime: v})"
                    :enable="isEnable"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col>&nbsp;</i-col>
                <i-col>
                  <iui-timepicker
                    :group="{grpNm: 'nrst2Time', seq: 1}"
                    label="휴게시간"
                    :value="timeInfo.nrst2StTime"
                    @change="v => setLaborTime({nrst2StTime: v})"
                    suffix="~"
                    :enable="isEnable"
                  />
                  <iui-timepicker
                    :group="{grpNm: 'nrst2Time', seq: 2}"
                    :value="timeInfo.nrst2EdTime"
                    @change="v => setLaborTime({nrst2EdTime: v})"
                    :enable="isEnable"
                  />
                </i-col>
                <i-spacer />
                <i-col>&nbsp;</i-col>
                <i-col>
                  <iui-timepicker
                    :group="{grpNm: 'srst2Time', seq: 1}"
                    label="휴게시간"
                    :value="timeInfo.srst2StTime"
                    @change="v => setLaborTime({srst2StTime: v})"
                    suffix="~"
                    :enable="isEnable"
                  />
                  <iui-timepicker
                    :group="{grpNm: 'srst2Time', seq: 2}"
                    :value="timeInfo.srst2EdTime"
                    @change="v => setLaborTime({srst2EdTime: v})"
                    :enable="isEnable"
                  />
                </i-col>
              </i-row>
            </template>
            <!--------------------------------------------------------------------->
            <!------------------------- 일용직 정보 END ---------------------------->
            <!--------------------------------------------------------------------->

            <!--------------------------------------------------------------------->
            <!------------------------- 상용직 정보 START -------------------------->
            <!--------------------------------------------------------------------->
            <template v-else>
              <i-row>
                <i-col-header required>
                  계약일자
                </i-col-header>
                <i-col>
                  <iui-datepicker
                    :value="laborInfo.contStDate"
                    @change="setLaborInfo({contStDate: $event})"
                    :enable="isEnable"
                    required
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>
                  업무내용
                </i-col-header>
                <i-col colspan="4">
                  <iui-text
                    :value="laborInfo.detailWork"
                    @change="setLaborInfo({detailWork: $event.target.value})"
                    :enable="isEnable"
                    max-length="200"
                    width="500px"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>근무시간</i-col-header>
                <i-col colspan="2">
                  <iui-timepicker
                    :group="{grpNm: 'ndayTime', seq: 1}"
                    :value="timeInfo.ndayStTime"
                    @change="v => setLaborTime({ndayStTime: v})"
                    suffix="~"
                    :enable="isEnable"
                    required
                    :errorMessage="{title: '근무시간', message: '필수입력입니다.'}"
                  />
                  <iui-timepicker
                    :group="{grpNm: 'ndayTime', seq: 2}"
                    :value="timeInfo.ndayEnTime"
                    @change="v => setLaborTime({ndayEnTime: v})"
                    :enable="isEnable"
                    required
                    :errorMessage="{title: '근무시간', message: '필수입력입니다.'}"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>휴게시간</i-col-header>
                <i-col colspan="2">
                  <iui-timepicker
                    :group="{grpNm: 'nrst1Time', seq: 1}"
                    :value="timeInfo.nrst1StTime"
                    @change="v => setLaborTime({nrst1StTime: v})"
                    suffix="~"
                    :enable="isEnable"
                  />
                  <iui-timepicker
                    :group="{grpNm: 'nrst1Time', seq: 2}"
                    :value="timeInfo.nrst1EdTime"
                    @change="v => setLaborTime({nrst1EdTime: v})"
                    :enable="isEnable"
                  />
                </i-col>
              </i-row>
            </template>
            <!--------------------------------------------------------------------->
            <!------------------------- 상용직 정보 END ---------------------------->
            <!--------------------------------------------------------------------->
            <i-row>
              <i-col-header>근무일</i-col-header>
              <i-col>
                <label>매주</label>
                <iui-text
                  type="number"
                  width="40px"
                  :value="laborInfo.wworkDay"
                  @change="setLaborInfo({wworkDay: $event.target.value})"
                  max-length="2"
                  :enable="isEnable"
                />
                <label>일</label>
              </i-col>
              <i-spacer />
              <i-col-header>휴무일</i-col-header>
              <i-col>
                <iui-text
                  width="25px"
                  align="center"
                  max-length="1"
                  :value="laborInfo.wrstWeekday"
                  @change="setLaborInfo({wrstWeekday: $event.target.value})"
                  :enable="isEnable"
                />
                <label>요일</label>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>임금({{ isDaily ? '일' : '월' }})</i-col-header>
              <i-col colspan="4">
                <iui-text
                  type="amount"
                  width="120px"
                  :value="laborInfo.salary"
                  @change="onChangeSalary"
                  :enable="false"
                  :errorMessage="{title: '임금', message: '필수입력입니다.'}"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>상여금</i-col-header>
              <i-col colspan="4">
                <iui-checkbox-group
                  :p-code="$getConstants('BONUS_GB').code"
                  :value="laborInfo.bonusGb"
                  @change="onChangeBonusGb"
                  :enable="isEnable"
                />
                <iui-text
                  type="amount"
                  width="120px"
                  :value="laborInfo.bonus"
                  @change="setLaborInfo({bonus: $event.target.value})"
                  :enable="isEnable && laborInfo.bonusGb === $getConstants('BONUS_GB_Y').code"
                  :required="laborInfo.bonusGb === $getConstants('BONUS_GB_Y').code"
                />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
        <i-spacer />
        <i-col colspan="4">
          <iui-container-new type="table" theme="bullet">
            <!--------------------------------------------------------------------->
            <!------------------------- 일용직 정보 START -------------------------->
            <!--------------------------------------------------------------------->
            <template v-if="isDaily">
              <i-row>
                <i-col colspan="4">
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header>직종</i-col-header>
                      <i-col>
                        <iui-text :value="laborInfo.jkdNm" :enable="false" width="120px" />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>제수당</i-col-header>
                      <i-col>
                        <iui-text
                          type="amount"
                          :value="laborInfo.totPay"
                          @change="setLaborInfo({totPay: $event.target.value})"
                          :enable="false"
                        />
                        <span>(% 통상임금 기준)</span>
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
              <i-row>
                <i-col>&nbsp;</i-col>
                <i-col colspan="4">
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col width="50px">시간외</i-col>
                      <i-col>
                        <iui-text type="rate" :value="laborInfo.overTimeRt" :enable="false" width="100px" />
                        <iui-text type="amount" :value="laborInfo.overTimePay" :enable="false" width="130px" />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col width="50px">야간</i-col>
                      <i-col>
                        <iui-text type="rate" :value="laborInfo.nightTimeRt" :enable="false" width="100px" />
                        <iui-text type="amount" :value="laborInfo.nightTimePay" :enable="false" width="130px" />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col width="50px">휴일</i-col>
                      <i-col>
                        <iui-text type="rate" :value="laborInfo.holidayTimeRt" :enable="false" width="100px" />
                        <iui-text type="amount" :value="laborInfo.holidayTimePay" :enable="false" width="130px" />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
              <i-row>
                <i-col colspan="4">
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header required>지급일</i-col-header>
                      <i-col>
                        <label>매월</label>
                        <iui-text
                          type="number"
                          max-length="2"
                          :value="laborInfo.salaryDate"
                          @change="setLaborInfo({salaryDate: $event.target.value})"
                          :enable="isEnable"
                          required
                          width="40px"
                          :errorMessage="{title: '지급일', message: '필수입력입니다.'}"
                        />
                        <label>일</label>
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>지급방법</i-col-header>
                      <i-col>
                        <iui-radio-group
                          :p-code="$getConstants('SALARY_METHOD').code"
                          :value="laborInfo.salaryMethod"
                          @change="setLaborInfo({salaryMethod: $event.target.value})"
                          :enable="isEnable"
                        />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
            </template>
            <!--------------------------------------------------------------------->
            <!------------------------- 일용직 정보 END ---------------------------->
            <!--------------------------------------------------------------------->

            <!--------------------------------------------------------------------->
            <!------------------------- 상용직 정보 START -------------------------->
            <!--------------------------------------------------------------------->
            <template v-else>
              <i-row>
                <i-col>&nbsp;</i-col>
              </i-row>
              <i-row>
                <i-col-header>기타급여(제수당)</i-col-header>
                <i-col colspan="1">
                  <iui-checkbox-group
                    :p-code="$getConstants('ETC_GB').code"
                    :value="laborInfo.etcGb"
                    :enable="isEnable"
                    @change="onChangeEtcGb"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col colspan="4">
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-spacer />
                      <i-col>
                        <iui-text
                          width="150px"
                          :value="laborInfo.etcPay1Nm"
                          @change="setLaborInfo({etcPay1Nm: $event.target.value})"
                          :enable="isEnable && isEtc"
                          :required="isEtc"
                          max-length="200"
                          :errorMessage="{title: '기타급여명', message: '필수입력입니다.'}"
                        />
                        <iui-text
                          width="200px"
                          type="amount"
                          :value="laborInfo.etcPay1"
                          @change="setLaborInfo({etcPay1: $event.target.value})"
                          :enable="isEnable && isEtc"
                          :required="isEtc"
                          :errorMessage="{title: '기타급여명', message: '필수입력입니다.'}"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-spacer />
                      <i-col>
                        <iui-text
                          width="150px"
                          :value="laborInfo.etcPay2Nm"
                          @change="setLaborInfo({etcPay2Nm: $event.target.value})"
                          :enable="isEnable && isEtc"
                          max-length="200"
                        />
                        <iui-text
                          width="200px"
                          type="amount"
                          :value="laborInfo.etcPay2"
                          @change="setLaborInfo({etcPay2: $event.target.value})"
                          :enable="isEnable && isEtc"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-spacer />
                      <i-col>
                        <iui-text
                          width="150px"
                          :value="laborInfo.etcPay3Nm"
                          @change="setLaborInfo({etcPay3Nm: $event.target.value})"
                          :enable="isEnable && isEtc"
                          max-length="200"
                        />
                        <iui-text
                          width="200px"
                          type="amount"
                          :value="laborInfo.etcPay3"
                          @change="setLaborInfo({etcPay3: $event.target.value})"
                          :enable="isEnable && isEtc"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-spacer />
                      <i-col>
                        <iui-text
                          width="150px"
                          :value="laborInfo.etcPay4Nm"
                          @change="setLaborInfo({etcPay4Nm: $event.target.value})"
                          :enable="isEnable && isEtc"
                          max-length="200"
                        />
                        <iui-text
                          width="200px"
                          type="amount"
                          :value="laborInfo.etcPay4"
                          @change="setLaborInfo({etcPay4: $event.target.value})"
                          :enable="isEnable && isEtc"
                        />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
              <i-row>
                <i-col colspan="4">
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header required>지급일</i-col-header>
                      <i-col>
                        <label>매월</label>
                        <iui-text
                          type="number"
                          max-length="2"
                          :value="laborInfo.salaryDate"
                          @change="setLaborInfo({salaryDate: $event.target.value})"
                          :enable="isEnable"
                          required
                          width="40px"
                          :errorMessage="{title: '지급일', message: '필수입력입니다.'}"
                        />
                        <label>일</label>
                      </i-col>
                      <i-spacer />
                      <i-col-header>지급방법</i-col-header>
                      <i-col>
                        <iui-radio-group
                          :p-code="$getConstants('SALARY_METHOD').code"
                          :value="laborInfo.salaryMethod"
                          @change="setLaborInfo({salaryMethod: $event.target.value})"
                          :enable="isEnable"
                        />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
            </template>
            <!--------------------------------------------------------------------->
            <!------------------------- 상용직 정보 END ---------------------------->
            <!--------------------------------------------------------------------->
            <i-row>
              <i-col colspan="5">
                <iui-container-new type="table" theme="bullet" header-width="120px">
                  <i-row>
                    <i-col-header width="120px">
                      사회보험적용여부
                    </i-col-header>
                    <i-col>
                      <iui-checkbox-group
                        :items="checkboxItems1"
                        :value="laborInfo.employInsurDs"
                        @change="onChangeSocialInsurDs('employInsurDs', $event)"
                        :enable="isEnable"
                      />
                      <iui-checkbox-group
                        :items="checkboxItems2"
                        :value="laborInfo.disasterInsurDs"
                        @change="onChangeSocialInsurDs('disasterInsurDs', $event)"
                        :enable="isEnable"
                      />
                      <iui-checkbox-group
                        :items="checkboxItems3"
                        :value="laborInfo.pensionDs"
                        @change="onChangeSocialInsurDs('pensionDs', $event)"
                        :enable="isEnable"
                      />
                      <iui-checkbox-group
                        :items="checkboxItems4"
                        :value="laborInfo.healthInsurDs"
                        @change="onChangeSocialInsurDs('healthInsurDs', $event)"
                        :enable="isEnable"
                      />
                    </i-col>
                  </i-row>
                </iui-container-new>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </form>
</template>

<script>
const OVER_TIME_RT = '150';
const NIGHT_TIME_RT = '200';
const HOLIDAY_TIME_RT = '100';

import {
  selectLaborInfo,
  createDaily,
  createFixedTerm,
  updateDaily,
  updateFixedTerm,
} from '@/view/sysManage/userManage/laborContract/api/laborContract.js';

export default {
  data() {
    return {
      checkboxItems1: [{label: '고용보험', value: ''}], // 0025
      checkboxItems2: [{label: '산재보험', value: $getConstants('HEALTH_INSUR_DS').code}], // 0111
      checkboxItems3: [{label: '국민연금', value: ''}], // 0035
      checkboxItems4: [{label: '건강보험', value: ''}], // 0020
    };
  },
  computed: {
    isEnable() {
      return this.laborInfo.empNo !== '' && (this.isNew || this.isProcessWritten); // 신규 OR 작성중
    },
    isEtc() {
      return this.laborInfo.etcGb === $getConstants('ETC_GB_Y').code;
    },
  },
  beforeCreate() {
    $mapGetters(this, ['rowInfo', 'laborInfo', 'timeInfo', 'isFixedTerm', 'isDaily', 'isNew', 'isProcessWritten']);
    $mapMutations(this, ['setLaborInfo', 'setTimeInfo', 'initLaborInfo', 'initTimeInfo']);
    $mapActions(this, ['setLaborTime']);
  },
  created() {
    this.addEvent([
      {name: 'ContractInfo_Init', func: this.onInit},
      {name: 'ContractInfo_Search', func: this.onSearch},
    ]);
    this.addFuncSave(this.onSave);
  },
  methods: {
    onSearch() {
      this.initLaborInfo();
      this.initTimeInfo();

      let param = {
        empNo: this.rowInfo.empNo,
        laborDs: this.rowInfo.laborDs,
      };
      selectLaborInfo(param).then(response => {
        const laborInfo = response.data.laborInfo;

        if (laborInfo) {
          let laborInfoPayload = {};
          let timeInfoPayload = {};

          for (let key in laborInfo) {
            if (key.endsWith('Time')) {
              if (laborInfo[key]) {
                timeInfoPayload[key] = laborInfo[key];
              }
            }

            if (key == 'pensionDs' || key == 'healthInsurDs' || key == 'employInsurDs') {
              if (laborInfo[key]) {
                if (key == 'pensionDs') {
                  laborInfoPayload[key] = $getConstants('PENSION_DS').code;
                } else if (key == 'healthInsurDs') {
                  laborInfoPayload[key] = $getConstants('HEALTH_INSUR_DS').code;
                } else if (key == 'employInsurDs') {
                  laborInfoPayload[key] = $getConstants('EMPLOY_INSUR_DS').code;
                }
              } else {
                laborInfoPayload[key] = '';
              }
            } else {
              laborInfoPayload[key] = laborInfo[key];
            }
          }

          laborInfoPayload['salaryMethod'] =
            laborInfoPayload['salaryMethod'] || $getConstants('SALARY_METHOD_BANK').code;
          laborInfoPayload['overTimeRt'] = laborInfo.overTimeRt || OVER_TIME_RT;
          laborInfoPayload['nightTimeRt'] = laborInfo.nightTimeRt || NIGHT_TIME_RT;
          laborInfoPayload['holidayTimeRt'] = laborInfo.holidayTimeRt || HOLIDAY_TIME_RT;

          this.setLaborInfo(laborInfoPayload);
          this.setTimeInfo(timeInfoPayload);
          if (this.isDaily) {
            this.onChangeSalary();
          }
        }
      });
    },
    onSave() {
      if (!this.onValidateSave()) return;
      const saveApis = {createDaily, createFixedTerm, updateDaily, updateFixedTerm};
      const url1 = this.isNew ? 'create' : 'update';
      const url2 = this.isDaily ? 'Daily' : 'FixedTerm';

      const saveApi = saveApis[`${url1 + url2}`];

      saveApi(this.laborInfo)
        .then(response => {
          if (response.status == 200) {
            this.callEvent({
              name: 'LaborList_Search',
              param: {empNo: this.laborInfo.empNo, laborDs: this.laborInfo.laborDs},
            });
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex.message);
          }
        });
    },
    onValidateSave() {
      let timeHh;
      let timeMm;

      if (!this.rowInfo.empNo) {
        this.$alert({title: '근로계약정보 저장', message: '저장할 정보가 존재하지 않습니다.'});
        return;
      }

      if (this.isAfterWritten) {
        this.$alert({title: '근로계약정보 저장', message: '해당 계약정보는 수정할 수 없습니다'});
        return;
      }

      if (this.isDaily && !(this.laborInfo.contStDate && this.laborInfo.contEdDate)) {
        this.$alert({title: '계약기간', message: '필수입력입니다.'});
        return;
      }

      if (this.isFixedTerm && !this.laborInfo.contStDate) {
        this.$alert({title: '계약일자', message: '필수입력입니다.'});
        return;
      }

      for (let key in this.timeInfo) {
        key.endsWith('Hh') ? (timeHh = Number(this.timeInfo[key])) : (timeMm = Number(this.timeInfo[key]));
        if (timeHh < 0 || timeHh > 23 || timeMm < 0 || timeMm > 59) {
          this.$alert({title: '시간', message: '시간 입력이 잘못되었습니다. <br/>다시 한 번 확인하시기 바랍니다.'});
          return;
        }
      }

      if (this.laborInfo.salaryDate && !$_isNumberBetween(this.laborInfo.salaryDate, 1, 31, true, true)) {
        this.$alert({title: '지급일', message: '지급일 입력이 잘못되었습니다. <br/>다시 한 번 확인하시기 바랍니다.'});
        return;
      }

      return true;
    },
    onChangeSalary() {
      let salary = this.laborInfo.salary || 0;
      let overTimePay = (salary * this.laborInfo.overTimeRt) / 100;
      let nightTimePay = (salary * this.laborInfo.nightTimeRt) / 100;
      let holidayTimePay = (salary * this.laborInfo.holidayTimeRt) / 100;
      let totPay = parseInt(overTimePay || 0) + parseInt(nightTimePay || 0) + parseInt(holidayTimePay || 0);

      this.setLaborInfo({salary, overTimePay, nightTimePay, holidayTimePay, totPay});
    },
    onChangeBonusGb(e) {
      const checked = e.target.checked;
      this.setLaborInfo({bonusGb: checked ? e.target.value : ''});
      if (!checked) {
        this.setLaborInfo({bonus: ''});
      }
    },
    onChangeEtcGb(e) {
      const checked = e.target.checked;
      this.setLaborInfo({etcGb: checked ? e.target.value : ''});
      if (!checked) {
        this.setLaborInfo({
          etcPay1Nm: '',
          etcPay1: '',
          etcPay2Nm: '',
          etcPay2: '',
          etcPay3Nm: '',
          etcPay3: '',
          etcPay4Nm: '',
          etcPay4: '',
        });
      }
    },
    onChangeSocialInsurDs(key, event) {
      this.setLaborInfo({[key]: event.target.checked ? '' : $getConstants('Y').code});
      if (key === 'disasterInsurDs') {
        this.setLaborInfo({[key]: event.target.checked ? $getConstants('DISASTER_INSUR_DS').code : ''});
      }
    },
  },
};
</script>

<style></style>
