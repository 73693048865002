const state = {
  searchInfo: {
    pgmCd: '',
    frmNm: '',
  },
  detailInfo: {
    pgmCd: '', //회사코드
    natNo: 0, //게시물번호
    natDs: '', //게시물구분
    rngDs: '', //열람대상(범위구분)
    tl: '', //제목
    cts: '', //내용
    inqCnt: 0, //조회수
    qid: '', //질의등록자
    qnm: '', //질의등록자명
    qdttm: '', //질의등록일시
    flNo: 0, //파일번호
    tskDs: '', //업무구분
    tskDsNm: '',
    natStDate: '', //게시시작일
    natEnDate: '', //게시종료일
    rfqNo: '', //입찰공고번호
    rqCts: '', //답변내용
    rqId: '', //답변자ID
    rqNm: '', //답변자명
    rqDttm: '', //답변일시
    flNo2: 0, //답변파일번호
    rgprId: '', //등록자ID
    rgprNm: '', //등록자명
    rgsDttm: '', //등록일시
  },
};
const getters = {
  searchInfo: state => state.searchInfo,
  detailInfo: state => state.detailInfo,
};
const mutations = {
  setSearchInfo(state, payload) {
    for (let key in state.searchInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = payload[key];
      }
    }
  },
  initDetailInfo(state) {
    for (let key in state.detailInfo) {
      if (key == 'natNo' || key == 'inqCnt' || key == 'flNo' || key == 'flNo2') {
        state.detailInfo[key] = 0;
      } else {
        state.detailInfo[key] = '';
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
