<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text type="search" :value.sync="searchNm" @enter="onSearch" />
      <iui-checkbox-group
        name="poCloseYn"
        :items="[{value: 'Y', label: '종료계약포함'}]"
        :checkedValues="[poCloseYn]"
        @change="
          e => {
            poCloseYn = e.target.checked ? 'Y' : 'N';
            onSearch();
          }
        "
      />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <iui-container-new type="css-flex">
      <i-row height="200px">
        <i-col>
          <ib-sheet
            :uid="_uid"
            :options="Options1"
            :loadSearchData="loadSearchData1"
            @loadSheet="loadSheet1"
            :events="{
              onSearchFinish: onSearchFinish1,
              onClick: onClick1,
              onDblClick: onDblClick1,
            }"
          />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col>
          <pmis-tab-box no-border-box no-padding-box>
            <template v-slot:title>계약내역</template>
            <ib-sheet
              :uid="_uid"
              :options="Options2"
              :loadSearchData="loadSearchData2"
              @loadSheet="loadSheet2"
              :events="{
                onDblClick: onDblClick2,
              }"
            />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>
<script>
import options1 from './sheetOptions/SubCustListPopupSheet1.js';
import options2 from './sheetOptions/SubCustListPopupSheet2.js';
import {selectSubPoCompleteList, selectSubPoItemCompleteList} from '@/view/bidding/po/sub/api/subPo.js';
export default {
  /**
   * 외주 거래처 목록 조회 팝업
   * 사용
   * - 자재투입등록
   * - 지급자재투입등록
   * - 가동일보등록
   * - 집행경비등록
   * */
  props: {
    callbackType: {
      type: [String, Number],
      default: '1', // 1 : 외주 선택, 2 : 외주내역 선택
    },
    costType: {
      type: String,
    },
    itemSeq: {
      type: String,
    },
    midCode: {
      type: String,
    },
    itemCode: {
      type: String,
    },
  },
  created() {
    this.addEvent([{name: 'SubCustListPopup_callbackData', func: this.onCallback}]);
    this.onSearch();
  },
  data() {
    return {
      sheet1: undefined,
      sheet2: undefined,
      Options1: options1(this),
      Options2: options2(this),
      loadSearchData1: [],
      loadSearchData2: [],
      searchNm: '',
      poCloseYn: 'N',
    };
  },
  methods: {
    async onSearch() {
      let param = {
        orgCd: this.pgmCd,
        spotDeptcd: this.prjCd,
        costType: this.costType,
        poCloseYn: this.poCloseYn,
        searchNm: this.searchNm,
        itemSeq: this.itemSeq,
        midCode: this.midCode,
        itemCode: this.itemCode,
      };

      const response = await selectSubPoCompleteList(param);
      this.loadSearchData1 = response.data;
    },
    loadSheet1(sheet) {
      this.sheet1 = sheet;
    },
    loadSheet2(sheet) {
      this.sheet2 = sheet;
    },
    onSearchFinish1(e) {
      this.onSearchDetail(e.sheet.getFirstRow());
    },
    onClick1(e) {
      if (e.row.Kind == 'Data') {
        this.onSearchDetail(e.row);
      }
    },
    onDblClick1(e) {
      if (e.row.Kind == 'Data' && this.callbackType == '1') {
        this.$modalConfirm();
      }
    },
    onDblClick2(e) {
      if (e.row.Kind == 'Data') {
        this.$modalConfirm();
      }
    },
    async onSearchDetail(row) {
      if (row === null) {
        this.sheet2.removeAll();
        return;
      }

      const param = {
        poNo: row.poNo,
        costType: this.costType,
        itemSeq: this.itemSeq,
        midCode: this.midCode,
      };
      const response = await selectSubPoItemCompleteList(param);
      this.loadSearchData2 = response.data;
    },
    onCallback(callback) {
      const focusedRow1 = this.sheet1.getFocusedRow();
      const focusedRow2 = this.sheet2.getFocusedRow();

      if ((this.callbackType == 1 && focusedRow1 == null) || (this.callbackType == 2 && focusedRow2 == null)) {
        this.$alert({title: '외주 선택', message: '선택된 외주이 없습니다.'});
        return;
      }

      if (this.callbackType == 1) {
        callback({
          poNo: focusedRow1.poNo,
          cusCd: focusedRow1.cusCd,
          cusNm: focusedRow1.cusNm,
        });
      } else {
        callback({
          poNo: focusedRow2.poNo,
          cusNm: focusedRow2.cusNm,
          cusCd: focusedRow2.cusCd,
          wcode: focusedRow2.wcode,
          headCode: focusedRow2.headCode,
          headName: focusedRow2.headName,
          midCode: focusedRow2.midCode,
          midName: focusedRow2.srvNm,
          itemSeq: focusedRow2.itemSeq,
          itemName: focusedRow2.itemNm,
          itemCode: focusedRow2.itemCode,
          ssize: focusedRow2.sizeNm,
          unitNm: focusedRow2.unit,
          amt: focusedRow2.amt,
        });
      }
    },
    grid_onDblClick(e) {
      if (e.row.Kind == 'Data' && this.callbackType == '1') {
        this.$modalConfirm(); // => onCallback()
      }
    },
    grid2_onDblClick(e) {
      if (e.row.Kind == 'Data') {
        this.$modalConfirm(); // => onCallback()
      }
    },
  },
};
</script>
