<template>
  <pmis-content-box>
    <template #title>협력사목록</template>
    <template #title-right>
      <iui-button value="협력사선택" @click="openCustomerChoicePopup" v-if="rfqStatus.isEnable" />
      <iui-button value="삭제" @click="deleteCustomer" v-if="rfqStatus.isEnable" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="rfqCusList"
      @loadSheet="sheet = $event"
      :events="{
        onSearchFinish: sheet_onSearchFinish,
        onClick: sheet_onClick,
      }"
    />

    <iui-modal name="customerChoiceModal" title="협력사정보" :btns="modalConfirm" size-type="size3">
      <CustomerChoice :frmDs="bidType" />
    </iui-modal>

    <iui-modal name="customerInfoModal" title="협력사정보" size-type="size2">
      <CustomerInfo :propsPgmCd="propsPgmCd" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/NoticeRfqCusList.js';

import CustomerChoice from '@/view/bidding/rfq/common/components/CustomerChoice.vue';
import CustomerInfo from '@/view/bidding/rfq/common/components/CustomerInfo.vue';

export default {
  components: {CustomerChoice, CustomerInfo},
  data() {
    return {
      sheetOpt: $addColCheckbox(sheetOpt(this)),
      modalConfirm: [{name: '확인', callback: this.onConfirmPopup}],
      propsPgmCd: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, ['rfqCusList', 'rfqStatus', 'bidType']);
  },
  created() {
    this.addEvent([
      {name: 'RfqCusList_OpenCustomerChoicePopup', func: this.openCustomerChoicePopup},
      {name: 'RfqCusList_GetSaveJson', func: this.getSaveJson},
    ]);
  },
  methods: {
    openCustomerChoicePopup() {
      this.$modal.show('customerChoiceModal');
    },
    onConfirmPopup() {
      const curCustomerList = this.sheet.getSaveJson(0).data;
      const curCusCdList = curCustomerList.map(item => item.cusCd);

      this.callEvent({
        name: 'CustomerChoice_Confirm',
        param: data => {
          if (!data.length) {
            this.$alert({title: '협력사 선택', message: '협력사를 선택해주세요.'});
            return;
          }

          data
            .filter(cus => !curCusCdList.includes(String(cus.bsnNo)))
            .forEach(cus => {
              const addRow = this.sheet.addRow();

              this.sheet.setValue(addRow, 'cusCd', cus.bsnNo, true);
              this.sheet.setValue(addRow, 'cusPgmCd', cus.cusPgmCd, true);
              this.sheet.setValue(addRow, 'frmNm', cus.frmNm, true);
              this.sheet.setValue(addRow, 'icprNm', cus.icprNm, true);
              this.sheet.setValue(addRow, 'icprPhone', cus.icprPhone, true);
              this.sheet.setValue(addRow, 'email', cus.email1 ? `${cus.email1}@${cus.email2}` : '', true);
            });

          this.$modal.hide('customerChoiceModal');
        },
      });
    },
    deleteCustomer() {
      const checkedRow = this.sheet.getRowsByChecked('chk');
      this.sheet.removeRows(checkedRow);
    },
    getSaveJson(callback) {
      if (typeof callback === 'function') {
        callback(this.sheet.getSaveJson(0).data);
      }
    },
    sheet_onSearchFinish(e) {
      e.sheet.setAttribute(null, 'chk', 'Visible', this.rfqStatus.isEnable, 1);
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data' && e.col === 'frmNm') {
        this.propsPgmCd = e.row.cusPgmCd;
        this.$modal.show('customerInfoModal');
      }
    },
  },
};
</script>

<style></style>
