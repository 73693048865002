<template>
  <pmis-content-box>
    <template v-slot:header-left v-if="isBase">
      <label>회사구분</label
      ><iui-select
        :p-code="$getConstants('FRM_GB').code"
        :exclude-items="$getConstants('FRM_GB_4').code"
        :value.sync="frmGb"
        @change="onFrmGbChange"
      />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onStartDrag,
        onEndDrag,
        onFocus,
        onSearchFinish,
      }"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/progHedofcMenuSheet.js';
import {
  selectMenuList as baseSelectMenuList,
  updateMenuAsSort as baseUpdateMenuAsSort,
  deleteMenuList as baseDeleteMenuList,
} from '../api/baseMenu.js';
import {selectMenuList, updateMenuAsSort, deleteMenuList} from '../api/menu.js';
export default {
  props: {
    isBase: {
      type: Boolean,
      default: false,
    },
    isCont: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sheet: undefined,
      sheetUtil: undefined,
      Options: options(this),
      loadSearchData: [],
      frmGb: $getConstants('FRM_GB_0').code,
      mnuCd: undefined,
    };
  },

  beforeCreate() {
    $mapGetters(this, ['detailInfo']);
    $mapMutations(this, ['setDetailInfo']);
  },

  created() {
    this.addEvent([
      {name: 'onSearch', func: this.onSearch},
      {name: 'onDelete', func: this.onDelete},
      {name: 'onDuplicate', func: this.onDuplicate},
    ]);
    this.onSearch();
  },

  methods: {
    onDuplicate(callback) {
      if (typeof callback == 'function') {
        let duplicate = this.sheet.getDataRows().filter(row => this.detailInfo.mnuCd == row.mnuCd);
        let isDuplicate = true;
        if (duplicate.length == 0) {
          isDuplicate = false;
        }
        callback(isDuplicate);
      }
    },

    async onSearch(mnuCd) {
      let response;
      if (this.isBase) {
        response = await baseSelectMenuList({frmGb: this.frmGb});
      } else {
        response = await selectMenuList({});
      }
      this.mnuCd = mnuCd;
      let items = $_treeModel(response.data, '!0!', 'mnuCd', 'uppMnuCd');
      this.loadSearchData = items;
    },

    onFrmGbChange() {
      this.onSearch();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
    onStartDrag(e) {
      e.row.beforeLevel = e.row.Level;
    },
    onEndDrag(e) {
      if (!e.type) return 0;
      if (e.row.mnuCd === $getConstants('MENU_SETTING').code) return 0;
      if (e.type == 2) {
        if (e.row.beforeLevel !== e.torow.Level + 1) {
          this.$alert({
            title: '프로그램이동',
            message: `${e.row.beforeLevel - 1}레벨 하위로만 이동 가능합니다.`,
          });
          return 0;
        }
      } else {
        if (e.row.beforeLevel !== e.torow.Level) {
          this.$alert({title: '프로그램이동', message: `${e.row.beforeLevel}레벨로만 이동 가능합니다.`});
          return 0;
        }
      }
      this.$confirm(
        {title: '프로그램', message: '수정하시겠습니까?'},
        () => {
          setTimeout(async () => {
            if (e.type == 2) {
              e.sheet.setValue(e.row, 'uppMnuCd', e.torow.mnuCd, 1);
              let childRow = e.torow.firstChild;
              for (let i = 0; i < e.torow.childNodes.length; i++) {
                e.sheet.setValue(childRow, 'mnuSqn', i + 1, 1);
                childRow = childRow.nextSibling;
              }
            } else {
              e.sheet.setValue(e.row, 'uppMnuCd', e.torow.uppMnuCd, 1);
              let childRow = e.torow.parentNode.firstChild;
              for (let i = 0; i < e.torow.parentNode.childNodes.length; i++) {
                e.sheet.setValue(childRow, 'mnuSqn', i + 1, 1);
                childRow = childRow.nextSibling;
              }
            }
            let rows = e.sheet.getSaveJson().data;
            rows.forEach(el => {
              el.beforeMnuCd = el.mnuCd;
              if (this.isBase) el.frmGb = this.frmGb;
            });
            let response;
            const param = {
              items: rows,
            };
            if (this.isBase) {
              response = await baseUpdateMenuAsSort(param);
            } else {
              response = await updateMenuAsSort(param);
            }
            if (response.data) {
              this.onSearch(e.row.mnuCd);
            }
          }, 10);
        },
        () => {
          this.onSearch(e.row.mnuCd);
        }
      );
    },
    onFocus(e) {
      this.setDetailInfo({
        ...e.row,
        uppMnuCd: String(e.row.parentNode.mnuCd || e.uppMnuCd || ''),
        uppMnuNm: String(e.row.parentNode.mnuNm || e.uppMnuNm || ''),
        beforeMnuCd: String(e.row.mnuCd),
        beforeUppMnuCd: String(e.row.parentNode.mnuCd || e.uppMnuCd || ''),
      });

      if (e.row.lvl === 4) {
        this.callEvent({name: 'selectMenuAuth'});
      }
      this.cud = 0;
    },
    onSearchFinish(e) {
      e.sheet.showTreeLevel(2);

      if (this.mnuCd) {
        this.sheetUtil.treeFocus('mnuCd', this.mnuCd);
        this.mnuCd = undefined;
      }
    },
    async onDelete() {
      if (!(await this.$confirm({title: '삭제', message: '삭제 하시겠습니까?'}))) {
        return;
      }
      const items = [];
      let row = this.sheet.getFocusedRow();
      let nextRow = this.sheet.getNextRow(row);
      items.push({
        mnuCd: row.mnuCd,
        ...(this.isBase ? {frmGb: this.frmGb} : {pgmCd: this.pgmCd}),
      });

      while (nextRow) {
        if (row.Level < nextRow.Level) {
          items.push({
            mnuCd: nextRow.mnuCd,
            ...(this.isBase ? {frmGb: this.frmGb} : {pgmCd: this.pgmCd}),
          });
          nextRow = this.sheet.getNextRow(nextRow);
        } else {
          break;
        }
      }
      let response;
      let param = {items};
      if (this.isBase) {
        response = await baseDeleteMenuList(param);
      } else {
        response = await deleteMenuList(param);
      }

      if (response.data) {
        this.onSearch(row.uppMnuCd);
      }
    },
  },
};
</script>
