<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col max-width="650px" min-width="400px">
        <iui-container-new type="table" theme="bullet" header-width="110px">
          <i-row>
            <i-col-header>성명</i-col-header>
            <i-col>
              <iui-text :value="workerInfo.name" :enable="false" width="150px" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>부서</i-col-header>
            <i-col>
              <iui-text :value="workerInfo.blnDepNm" :enable="false" width="150px" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>직책</i-col-header>
            <i-col>
              <iui-text :value="workerInfo.dtsNo" :enable="false" width="150px" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>직급</i-col-header>
            <i-col>
              <iui-text :value="workerInfo.rollNm" :enable="false" width="150px" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>전화번호</i-col-header>
            <i-col>
              <iui-text type="phone" :value="workerInfo.tlno" :enable="false" width="150px" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>팩스번호</i-col-header>
            <i-col>
              <iui-text type="phone" :value="workerInfo.rpsvFaxno" :enable="false" width="150px" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>휴대폰번호</i-col-header>
            <i-col>
              <iui-text type="phone" :value="workerInfo.mobileTlno" :enable="false" width="150px" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>주소</i-col-header>
            <i-col>
              <iui-text :value="workerInfo.postNo" :enable="false" width="90px" />
              <iui-text :value="workerInfo.adr1" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col></i-col>
            <i-col>
              <iui-text :value="workerInfo.adr2" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>이메일</i-col-header>
            <i-col>
              <iui-text type="email1" :value="workerInfo.email1" :enable="false" width="140px" />
              <iui-text type="email2" :value="workerInfo.email2" :enable="false" width="185px" prefix="@" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>근무종료여부</i-col-header>
            <i-col>
              <iui-checkbox-group
                name="labtClose"
                :p-code="$getConstants('LABT_CLOSE').code"
                :checkedValues="[workerInfo.labtClose]"
                :enable="false"
              />
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
      <i-spacer />
      <i-col width="200px">
        <pmis-image-file
          id="img1"
          title="사진"
          :file-number.sync="workerInfo.phoFlNo"
          :allowAdd="false"
          :allowDelete="false"
        />
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
/**
 * 계약정보등록(tab2) > 조직도 > 구성원정보(인적정보)
 *
 * */
export default {
  beforeCreate() {
    $mapGetters(this, ['workerInfo']);
  },
};
</script>

<style></style>
