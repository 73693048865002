export default async that => {
  const getLgCode = fr => {
    return that.codeObj[fr.Row.wcode];
  };
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      CanEdit: 1, // 1: 전체 편집 가능 (default)
      ReqStatusName: 'status', // 서버로 전송될 상태 명 (default:STATUS)
      IgnoreFocused: true,
    },

    Cols: [
      {
        Header: '순번',
        Name: 'seq',
        Type: 'Text',
        Width: 50,
        CanEdit: false,
      },
      {
        Header: '전문공종',
        Name: 'wcode',
        Type: 'Enum',
        RelWidth: 1,
        EnumKeys: $getConstants('W_CODE').code,
        Enum: $getConstants('W_CODE').code,
        CanEdit: that.enable,
      },
      {
        Header: '전문세부공종',
        Name: 'lgCode',
        Type: 'Enum',
        RelWidth: 1,
        EnumKeysFormula: fr => {
          if (fr.Row['wcode']) {
            let sEnumKey = getLgCode(fr);
            const result = `|${sEnumKey.map(code => String(code.code)).join('|')}`;
            return result;
          }
        },
        EnumFormula: fr => {
          if (fr.Row['wcode']) {
            let sEnum = getLgCode(fr);
            return `|${sEnum.map(code => String(code.codeNm)).join('|')}`;
          }
        },
        CanEdit: that.enable,
      },
      {
        Header: '비고',
        Name: 'rmk',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: that.enable,
      },
    ],
  };
};
