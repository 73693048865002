<template>
  <iui-portlet-container type="multi" :comps="comps" :activeTab.sync="tabIndex">
    <template #title>{{ titleNm }}</template>
    <template #link>
      <a href="#self" class="goto_top_link" @click="goShortCut">
        <span class="blind">바로가기</span>
      </a>
    </template>
    <template #content>
      <join-request v-show="tabIndex == 0" :unchkedCnt.sync="comps[0].unchkedCnt" />
      <purchs-cnslt-request v-show="tabIndex == 1" :unchkedCnt.sync="comps[1].unchkedCnt" />
    </template>
  </iui-portlet-container>
</template>

<script>
import PortletMainMixin from '@/components/portlet/mixin/portletMainMixin.js';
export default {
  mixins: [PortletMainMixin],
  data() {
    return {
      comps: [
        {tabNm: '회원가입요청', compNm: 'join-request', shortCut: 'JOIN_APPROVAL', unchkedCnt: 0},
        {tabNm: '구매상담신청', compNm: 'purchs-cnslt-request', shortCut: 'PURCHS_CNSLT_RECEIPT', unchkedCnt: 0},
      ],
    };
  },
  methods: {
    goShortCut() {
      this.goMenu(this.comps[this.tabIndex].shortCut);
    },
  },
};
</script>

<style></style>
