<template>
  <iui-content-box
    :title-type="titleType"
    :border-box="borderBox"
    :no-border-box="noBorderBox"
    :no-padding-box="noPaddingBox"
    :loading="loading"
  >
    <template v-slot:title>
      <div class="df" v-if="$slots.title">
        <slot name="title" />
        <iui-button v-if="titleBtn" style="margin-left: 10px;" @click="$emit('title-btn')">
          {{ btnValue }}
        </iui-button>
      </div>
    </template>
    <template v-slot:title-left>
      <slot name="title-left" />
    </template>
    <template v-slot:title-right>
      <slot name="title-right" />
    </template>
    <template v-slot:header-left>
      <slot name="header-left" />
    </template>
    <template v-slot:header-center>
      <slot name="header-center" />
    </template>
    <template v-slot:header-right>
      <slot name="header-right" />
    </template>
    <template #header-custom>
      <slot name="header-custom" />
    </template>
    <slot />
  </iui-content-box>
</template>

<script>
export default {
  props: {
    borderBox: {
      type: Boolean,
      default: true,
    },
    noBorderBox: {
      type: Boolean,
      default: false,
    },
    titleType: {
      type: String,
      default: 'tab',
    },
    noPaddingBox: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    titleBtn: {
      // 탭 제목 내부버튼
      type: Boolean,
      default: false,
    },
    btnValue: {
      // 탭 제목 내부버튼Text
      type: String,
      default: '',
    },
  },
};
</script>

<style></style>
