export default that => {
  return {
    Cfg: {
      CanEdit: 1,
    },
    Cols: [
      {
        Header: '코드',
        Name: 'itemCode',
        Type: 'Text',
        Align: 'Center',
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: '명칭',
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: '규격',
        Name: 'ssize',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Width: 120,
        CanEdit: 0,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: '합계',
        Name: 'amt',
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: '재료비',
        Name: 'matAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: '노무비',
        Name: 'labAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: '경비',
        Name: 'equipAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: '사용여부',
        Name: 'useDs',
        type: 'Text',
        Align: 'center',
        Format: $getConstants('USE_YN').code,
        Width: 80,
        CanEdit: 0,
      },
    ],
  };
};
