export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      CanEdit: false,
    },
    Cols: [
      {
        Header: '자재코드',
        Name: 'matCode',
        Type: 'Text',
        Align: 'center',
        Width: 140,
      },
      {
        Header: '자재명',
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 180,
      },
      {
        Header: '규격',
        Name: 'size',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 180,
      },
      {
        Header: '단위',
        Name: 'unit',
        Width: 60,
        Type: 'Text',
        Align: 'Center',
        Format: $getConstants('UNIT').code,
      },
      {
        Header: '예산수량',
        Name: 'moqty',
        Extend: preset.quantity,
        Width: 140,
        Visible: !that.isSimpleExec,
      },
      {
        Header: '입고수량',
        Name: 'curInputQty',
        Extend: preset.quantity,
        Width: 140,
      },
      {
        Header: '출고수량',
        Name: 'curOutputQty',
        Extend: preset.quantity,
        Width: 140,
      },
      {
        Header: '재고수량',
        Name: 'curRemQty',
        Extend: preset.quantity,
        Width: 140,
      },
      {Name: 'pgmCd', Visible: false},
      {Name: 'prjCd', Visible: false},
      {Name: 'ioGb', Visible: false},
    ],
  };
};
