export default that => {
  return {
    Cfg: {
      SearchMode: 2,
      CanEdit: false,
    },
    Cols: [
      {
        Header: '장비코드',
        Name: 'equipCode',
        Type: 'Text',
        MinWidth: 140,
        Align: 'center',
      },
      {
        Header: '장비명',
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
      },
      {
        Header: '규격',
        Name: 'size',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 130,
      },
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        Width: 60,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: '차량번호',
        Name: 'carNo',
        Type: 'Text',
        Width: 120,
        CanEdit: 0,
        Visible: that.useGb == $getConstants('USE_GB_PRIVATE').code,
      },
      {Name: 'pgmCd', Visible: false},
      {Name: 'useGb', Visible: false},
      {Name: 'useOil', Visible: false},
    ],
  };
};
