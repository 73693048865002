<template>
  <pmis-tab-box>
    <template v-slot:title>포틀릿목록</template>
    <pmis-content-box>
      <template v-slot:header-left>
        <label>포틀릿그룹</label>
        <iui-select
          :p-code="$getConstants('PORTLET_GROUP').code"
          defaultCd="A"
          :value.sync="portletGroup"
          @change="selectPortletList"
        />
        <iui-text type="search" width="200px" max-length="50" :value.sync="portletName" @enter="selectPortletList" />
        <iui-button value="검색" @click="selectPortletList" />
      </template>
      <iui-container-new type="ibsheet" :id="elId" />

      <!-- <iui-modal name="searchMnu" :title="'메뉴검색'" :width="'800px'">
        <searchMnuPopup :frmGb="frmGb" />
      </iui-modal> -->
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
// import {mapGetters} from 'vuex';
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      portletGroup: '',
      portletName: '',
      // frmGb: '000000',
    };
  },
  created() {
    this.addEvent([
      {name: 'portletRegist_selectPortletList', func: this.selectPortletList},
      {name: 'portletRegist_addRow', func: this.addRow},
      {name: 'portletRegist_getSaveJson', func: this.getSaveJson},
      {name: 'portletRegist_doDelete', func: this.deleteRow},
    ]);
  },
  mounted() {
    this.initSheet();
  },
  // computed: {
  // 	...mapGetters('menu', {
  // 		system: 'system',
  //   }),
  //   selectedMnuNm: function() {
  //     return this.system.mnuNm;
  //   },
  //   selectedMnuCd: function() {
  //     return this.system.mnuCd;
  //   }
  // },
  // watch: {
  //   selectedMnuNm(data) {
  //     this.$modal.hide('searchMnu');
  //     this.sheet.setValue(this.sheet.getFocusedRow(), 'mnuNm', data);
  //   },
  //   selectedMnuCd(data) {
  //     this.sheet.setValue(this.sheet.getFocusedRow(), 'mnuCd', data);
  //   }
  // },
  methods: {
    async initSheet() {
      const OPT = {
        Cfg: {
          SearchMode: 2,
        },
        Cols: [
          {
            Header: '선택',
            Name: 'chk',
            Type: 'Bool',
            BoolIcon: 0,
            Align: 'center',
            Width: 50,
            CanEdit: true,
          },
          {
            Header: '포틀릿그룹',
            Name: 'portletGroup',
            Type: 'Enum',
            Width: 220,
            RelWidth: 1,
            CanEdit: false,
            EnumKeys: await $getCodeIBSheetEnum('KEY', $getConstants('PORTLET_GROUP').code),
            Enum: await $getCodeIBSheetEnum('VALUE', $getConstants('PORTLET_GROUP').code),
          },
          {
            Header: '포틀릿ID',
            Name: 'portletId',
            Type: 'Text',
            Width: 180,
            CanEdit: false,
          },
          {
            Header: '포틀릿명',
            Name: 'portletName',
            Type: 'Text',
            Width: 250,
            CanEdit: true,
          },
          {
            Header: '포틀릿사이즈',
            Name: 'size',
            Type: 'Enum',
            Width: 100,
            EnumKeys: await $getCodeIBSheetEnum('KEY', $getConstants('PORTLET_SIZE').code),
            Enum: await $getCodeIBSheetEnum('VALUE', $getConstants('PORTLET_SIZE').code),
            CanEdit: true,
          },
          {
            Header: '메뉴코드',
            Name: 'mnuCd',
            Type: 'Text',
            Width: 200,
            // WidthPad: 50,
            CanEdit: true,
          },
          // {
          //   Header: '메뉴코드',
          //   Name: 'mnuNm',
          //   Type: 'Text',
          //   Width: 200,
          //   WidthPad: 50,
          //   CanEdit: false,
          //   Button: "Button",
          //   ButtonText: '검색',
          //   OnClickSide: (e) => this.onShowModal(e),
          // },
          {
            Header: '포틀릿설명',
            Name: 'programDetail',
            Type: 'Text',
            Width: 300,
            CanEdit: true,
          },
          // {
          //   Name: 'mnuCd',
          //   Visible: false,
          // },
        ],
        Events: {
          onRowAdd: function(e) {
            e.sheet.setAttribute(e.row, 'portletGroup', 'CanEdit', true);
            e.sheet.setAttribute(e.row, 'portletId', 'CanEdit', true);
          },
        },
      };

      PMISSheet.createSheet({el: this.elId, options: OPT}).then(sheet => {
        this.sheet = sheet;
        this.selectPortletList();
      });
    },
    async selectPortletList() {
      const response = await axios.post('/portlet/selectPortletList', {
        portletGroup: this.portletGroup,
        portletName: this.portletName,
      });
      this.sheet.loadSearchData(response.data, 0);
    },
    addRow() {
      this.sheet.addRow({next: this.sheet.getFirstRow(), init: {CanEdit: true}});
    },
    getSaveJson(callback) {
      if (typeof callback == 'function') callback($_statusToCud(this.sheet.getSaveJson().data));
    },
    deleteRow() {
      var rows = this.sheet.getRowsByChecked('chk');
      for (var i = 0; i < rows.length; i++) {
        this.sheet.deleteRow(rows[i], 1);
      }
    },
    // onShowModal(e) {
    //   this.frmGb = e.row.portletGroup;
    //   this.$modal.show('searchMnu');
    //   setTimeout(() => {
    //     this.callEvent({name: 'progMenu_onSearch'});
    //   }, 100);
    // },
  },
};
</script>

<style></style>
