<template>
  <div style="height:100%">
    <div v-if="isPrjPortlet">
      <project-search-bar />
    </div>

    <div
      ref="portletAllBox"
      class="portlet_all_box"
      :style="`height: calc(100vh - ${isPrjPortlet ? '190px' : '150px'});`"
    >
      <div class="portlet_group">
        <section :class="`portlet_area size${items.sizeChar}`" v-for="(items, index) in portletInfos" :key="index">
          <component :is="items.mnuCd" :portletGroup="portletGroup" />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import store from '@/view/bsnsManage/Process/Photo/store/photo';

export default {
  data() {
    return {
      portletInfos: [],
      portletPage1: [],
      portletPage2: [],

      portletGroup: '',
    };
  },
  computed: {
    ...mapGetters(['subFrmGb']),
    isPrjPortlet() {
      return this.portletGroup === $getConstants('PORTLET_GROUP_CONSTRUCTION_SITE').code;
    },
  },
  watch: {
    subFrmGb(newVal, oldVal) {
      if (newVal != oldVal) {
        this.initMain();
      }
    },
  },
  beforeCreate() {
    $init(this, store);
  },
  created() {
    this.addEvent([{name: 'MainHome_turnPage', func: this.turnPage}]);
  },
  mounted() {
    this.initMain();
  },
  methods: {
    setPortletGroup() {
      let portletGroup = '';
      switch (this.subFrmGb) {
        case $getConstants('FRM_GB_0').code:
          portletGroup = $getConstants('PORTLET_GROUP_OPERATION').code;
          break;
        case $getConstants('FRM_GB_1').code:
          portletGroup = $getConstants('PORTLET_GROUP_HEAD_OFFICE').code;
          break;
        case $getConstants('FRM_GB_2').code:
          portletGroup = $getConstants('PORTLET_GROUP_CONSTRUCTION_SERVICE').code;
          break;
        case $getConstants('FRM_GB_3').code:
          portletGroup = $getConstants('PORTLET_GROUP_MATERIAL').code;
          break;
      }
      this.portletGroup = portletGroup;
    },
    async initMain(portletGroup = null) {
      if (portletGroup) {
        this.portletGroup = portletGroup;
      } else {
        this.setPortletGroup();
      }
      const response = await axios.post('/home/mainHome', {portletGroup: this.portletGroup});
      if (response.status === 200) {
        this.portletInfos = response.data;
      }
    },
    turnPage() {
      this.$refs.portletAllBox.scrollTo(0, 0);
      this.$store.dispatch('portlet/clearRunFunc');
      if (this.portletGroup === $getConstants('PORTLET_GROUP_HEAD_OFFICE').code) {
        this.initMain($getConstants('PORTLET_GROUP_CONSTRUCTION_SITE').code);
      }
    },
  },
};
</script>

<style scoped>
.sizeA {
  height: 250px;
}

.sizeB {
  height: 385px;
}

.sizeC {
  height: 520px;
}

.sizeD {
  height: 790px;
}
</style>
