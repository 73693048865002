/** 기성목록 */
export const selectPxcondList = param => axios.post('/pxcondBilclctRegist/selectPxcondList', param);
/** 공제내역 */
export const selectDeductList = param => axios.post('/pxcondBilclctRegist/selectDeductList', param);
/** 직불내역 */
export const selectDirectList = param => axios.post('/pxcondBilclctRegist/selectDirectList', param);
/** 수금내역 */
export const selectPxcondCollectList = param => axios.post('/pxcondBilclctRegist/selectPxcondCollectList', param);
/** 수금정보 */
export const selectPxcondCollectInfo = param => axios.post('/pxcondBilclctRegist/selectPxcondCollectInfo', param);
/** 공제내역 저장 */
export const saveDeductList = param => axios.post('/pxcondBilclctRegist/saveDeductList', param);
/** 직불내역 저장 */
export const saveDirectList = param => axios.post('/pxcondBilclctRegist/saveDirectList', param);
/** 수금정보 저장 */
export const savePxcondCollectInfo = param => $save('/pxcondBilclctRegist/savePxcondCollectInfo', param);
/** 공제내역 삭제 */
export const deleteDeductList = param => axios.post('/pxcondBilclctRegist/deleteDeductList', param);
/** 직불내역 삭제 */
export const deleteDirectList = param => axios.post('/pxcondBilclctRegist/deleteDirectList', param);
/** 수금정보 삭제 */
export const deletePxcondCollectInfo = param => axios.post('/pxcondBilclctRegist/deletePxcondCollectInfo', param);
