export default that => {
  return {
    Cfg: {
      CanEdit: 0, // 0: 전체 편집 불가능
      MainCol: 'fldrNm', //트리구조로 만들 필드
    },
    Cols: [
      {
        Header: '부서',
        Name: 'fldrNm',
        Type: 'Text',
        Align: 'Left',
        RelWidth: 1,
      },
      {
        Header: '코드',
        Name: 'fldrCd',
        Type: 'Text',
        Align: 'Left',
        Width: 150,
      },
    ],
  };
};
