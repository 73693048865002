import store from '@/store/index.js';
const SheetUtil = class {
  #beforeSearchText;
  #searchIndex = 0;
  #focusRows = [];
  #beforeRowCount = 0;
  #rowHeight = 30;
  #initPaging = true;
  #scrollPosMode = 1; // 1: scroll %로 paging처리(70%), or row개수로 paging처리(20개)
  paging = false;
  afterClickColNm;
  afterClickMode;
  callback = () => {};

  constructor(sheet, pageSize = 100) {
    this.sheet = sheet;
    this.pageSize = pageSize;
  }

  search(col, searchText, strict = false) {
    if (typeof searchText != 'string') searchText = String(searchText);
    // 이전 검색어와 상이하다면 검색 list 초기화.
    if (this.#beforeSearchText != searchText) {
      this.#beforeSearchText = searchText;
      this.#searchIndex = 0;
      if (typeof col == 'string') {
        this.#focusRows = this.sheet.getDataRows().filter(row => {
          let rowStr = String(row[col]).toUpperCase();
          if (strict) {
            return rowStr === searchText.toUpperCase();
          } else if (rowStr.indexOf(searchText.toUpperCase()) != -1) {
            return searchText;
          }
        });
      } else if (typeof col == 'object') {
        this.#focusRows = this.sheet.getDataRows().filter(row => {
          let value;
          col.some(str => {
            let rowStr = String(row[str]).toUpperCase();
            if (strict) {
              value = rowStr === searchText.toUpperCase();
            } else if (rowStr.indexOf(searchText.toUpperCase()) != -1) {
              value = searchText;
            }
            if (value) {
              return true;
            }
          });
          return value;
        });
      }
    }
  }

  /**
   * tree조회
   * tree의 col name이 검색어에 매칭되는 row에 focus.
   * @param {*} col
   * @param {*} searchText
   */
  listSearch(col, searchText, strict = false) {
    return new Promise(resolve => {
      let focusRow;
      this.search(col, searchText, strict);
      const focusScroll = () => {
        let count = 0;
        let beforeFocusScroll = this.sheet.getScrollTop();
        const interval = setInterval(() => {
          let afterFocusScroll = this.sheet.getScrollTop();
          if (beforeFocusScroll < afterFocusScroll) {
            clearInterval(interval);
            this.sheet.setScrollTop(this.sheet.getRowTop(focusRow));
          } else if (beforeFocusScroll > afterFocusScroll) {
            clearInterval(interval);
            this.sheet.setScrollTop(this.sheet.getRowTop(focusRow) - this.#rowHeight);
          }
          if (count >= 20) {
            clearInterval(interval);
          }
          count++;
        }, 50);
      };

      if (this.#focusRows.length) {
        focusRow = this.#focusRows[this.#searchIndex++];
        if (!focusRow) {
          this.#searchIndex = 0;
          focusRow = this.#focusRows[this.#searchIndex++];
        }

        let count = 0;
        const focusInterval = setInterval(() => {
          const row = this.sheet.getRowById(focusRow.id);
          if (this.sheet.focus(row) != null) {
            focusScroll();
            resolve(row);
            clearInterval(focusInterval);
          }
          if (count >= 300) {
            resolve(null);
            clearInterval(focusInterval);
          }
          count++;
        }, 1);
      } else {
        resolve(null);
      }
    });
  }

  /**
   * tree조회
   * tree의 col name이 검색어에 매칭되는 row에 focus.
   * @param {*} col
   * @param {*} searchText
   */
  treeSearch(col, searchText, strict = false) {
    this.search(col, searchText, strict);
    if (this.#focusRows.length) {
      let beforeFocusScroll = this.sheet.getScrollTop();

      let focusRow = this.#focusRows[this.#searchIndex++];
      if (!focusRow) {
        this.#searchIndex = 0;
        focusRow = this.#focusRows[this.#searchIndex++];
      }

      this.setExpandParentRow(focusRow);

      this.sheet.focus(this.sheet.getRowById(focusRow.id));
      let count = 0;
      const interval = setInterval(() => {
        let afterFocusScroll = this.sheet.getScrollTop();
        if (beforeFocusScroll < afterFocusScroll) {
          clearInterval(interval);
          this.sheet.setScrollTop(this.sheet.getRowTop(focusRow));
        } else if (beforeFocusScroll > afterFocusScroll) {
          clearInterval(interval);
          this.sheet.setScrollTop(this.sheet.getRowTop(focusRow) - this.#rowHeight);
        }
        if (count >= 20) {
          clearInterval(interval);
        }
        count++;
      }, 50);
    }
  }

  /**
   * tree focus
   * 컬럼명에서 검색어에 포함된 단어로 포커스를 이동한다.
   * @param {*} col
   * @param {*} searchText
   * @returns
   */
  treeFocus(col, searchText) {
    let focusRow = this.sheet.getDataRows().find(row => row[col] == searchText);
    if (focusRow) {
      let beforeFocusScroll = this.sheet.getScrollTop();
      this.setExpandParentRow(focusRow);
      let count = 0;
      this.sheet.focus(focusRow);
      const interval = setInterval(() => {
        let afterFocusScroll = this.sheet.getScrollTop();
        if (beforeFocusScroll < afterFocusScroll) {
          clearInterval(interval);
          this.sheet.setScrollTop(this.sheet.getRowTop(focusRow));
        } else if (beforeFocusScroll > afterFocusScroll) {
          clearInterval(interval);
          this.sheet.setScrollTop(this.sheet.getRowTop(focusRow) - this.#rowHeight);
        }
        if (count >= 20) {
          clearInterval(interval);
        }
        count++;
      }, 50);
    }
    return focusRow;
  }

  /**
   * 스크롤페이징
   * #scrollPosMode에 따라서 스크롤 비율 또는 row개수가 될때마다 callback을 실행한다.
   * callbackd에서 조회하여 loadSearchData append로 추가해야한다.
   * @param {*} callback
   */
  scrollPaging(callback) {
    if (!callback) return;
    this.paging = true;
    this.callback = callback;
  }

  /**
   * 스크롤페이징시 searchFinish event
   * @param {*} e
   */
  searchFinishInScrollPaging(e) {
    if (e.sheet.getTotalRowCount() < this.pageSize) {
      this.#beforeRowCount = 0;
    }
    const offset = e.sheet.getScrollTop();
    e.sheet.setScrollTop(offset);
  }

  /**
   * 스크롤페이징시 onScroll event
   * @param {*} e
   * @returns
   */
  onScrollInScrollPaging(e) {
    if (e.sheet.getTotalRowCount() === 0) {
      return;
    }
    if (this.#scrollPosMode === 1) {
      const lastIndex = e.sheet.getLastRow().HasIndex;
      const id = 'AR' + Math.floor(lastIndex * 0.7);
      const lastRowPos = e.sheet.getRowTop(e.sheet.getRowById(id));
      const triggerPos = e.vpos + e.sheet.getBodyHeight();
      if (
        triggerPos > lastRowPos &&
        this.#beforeRowCount != this.sheet.getTotalRowCount() &&
        this.pageSize <= this.sheet.getTotalRowCount()
      ) {
        this.#beforeRowCount = this.sheet.getTotalRowCount();
        this.#initPaging = false;
        this.callback();

        setTimeout(() => {
          this.#initPaging = true;
        }, 100);
      }
    } else {
      const lastRowPos = e.sheet.getRowTop(e.sheet.getLastRow());
      const rowLimitCount = 20;
      const triggerPos = e.vpos + this.#rowHeight * rowLimitCount + e.sheet.getBodyHeight();
      if (triggerPos > lastRowPos && this.#beforeRowCount != this.sheet.getTotalRowCount()) {
        this.#beforeRowCount = this.sheet.getTotalRowCount();
        this.#initPaging = false;
        this.callback();
        setTimeout(() => {
          this.#initPaging = true;
        }, 100);
      }
    }
  }

  /**
   * paging을 사용하기 위한 data
   * @returns pagindData
   */
  pagingData() {
    return {
      pageNo: this.getPageNo(),
      pageSize: this.pageSize,
    };
  }

  getPageNo() {
    return this.#initPaging ? 1 : Math.floor(this.sheet.getTotalRowCount() / this.pageSize) + 1;
  }

  // tree의 focus된 row의 상위 row들을 모두 펼침
  setExpandParentRow(row) {
    if (!row || !row.Level) return;
    this.sheet.setExpandRow(this.sheet.getRowById(row.parentNode?.id), null, true);
    this.setExpandParentRow(row.parentNode);
  }

  /**
   * AfterClick 이벤트 재정의
   * 트리체크를 afterclick 에서 호출하기 위해 기존에 afterClick이 있다면 재가공한다.   *
   */
  onAfterClick(e) {
    const setTreeCheck =
      this.afterClickMode === 'all'
        ? this.onTreeParentAndChildrenCheck.bind(this)
        : this.afterClickMode === 'parent'
        ? this.onTreeParentCheck
        : this.onTreeChildrenCheck;

    if (e.col === this.afterClickColNm && e.kind === 'Data') {
      setTreeCheck(e);
    }
  }

  /**
   * tree check
   * tree check 이벤트를 설정하기 위한 메소드
   * onAfterClick event가 있을경우 event bind후 호출해야한다.
   *
   * @param {*} colNm
   * @param {*} mode(all, parent, child)
   */
  onTreeCheck(colNm, mode = 'all') {
    this.afterClickColNm = colNm;
    this.afterClickMode = mode;
    this.onAfterClick(colNm, mode);
  }

  /**
   * 트리 체크
   * 트리의 체크박스 체크/해지시 최상위 부모와 자식들을 같이 체크한다.
   * @param {*} e
   */
  onTreeParentAndChildrenCheck(e) {
    //1. 하위 모든항목 체크
    this.onTreeChildrenCheck(e);

    //2. 자식노드 체크시 부모노드 체크
    this.onTreeParentCheck(e);
  }

  onTreeChildrenCheck(e) {
    let nextRow = e.sheet.getNextRow(e.row);
    while (nextRow) {
      if (e.row.Level < nextRow.Level) {
        e.sheet.setCheck(nextRow, e.col, e.row[e.col]);
        nextRow = e.sheet.getNextRow(nextRow);
      } else {
        break;
      }
    }
  }

  /**
   * 트리 체크
   * 트리의 체크박스 체크/해지시 자식들을 같이 체크한다.
   * @param {*} e
   */
  onTreeParentCheck(e) {
    if (e.row.Level > 0) {
      if (e.row[e.col]) {
        //Check
        let parentNode = e.row.parentNode;
        while (parentNode) {
          if (parentNode.Level < 0) break;

          e.sheet.setCheck(parentNode, e.col, 1);
          parentNode = parentNode.parentNode;
        }
      } else {
        //UnCheck 보무노드 체크 해지하기전 다른 자식노드 체크여부 확인후 UnCheck한다.
        let nextNode = e.row.nextSibling;
        let isChecked = false;
        while (nextNode) {
          if (nextNode[e.col]) {
            isChecked = true;
            break;
          }
          nextNode = nextNode.nextSibling;
        }

        let prevNode = e.row.previousSibling;
        while (prevNode) {
          if (prevNode[e.col]) {
            isChecked = true;
            break;
          }
          prevNode = prevNode.previousSibling;
        }

        if (!isChecked) {
          let parentNode = e.row.parentNode;
          while (parentNode) {
            if (parentNode.Level < 0) break;

            e.sheet.setCheck(parentNode, e.col, 0);
            let nextNodeInParent = parentNode.nextSibling;
            let isCheckedInParent = false;
            while (nextNodeInParent) {
              if (nextNodeInParent[e.col]) {
                isCheckedInParent = true;
                break;
              }
              nextNodeInParent = nextNodeInParent.nextSibling;
            }

            let prevNodeInParent = parentNode.previousSibling;
            while (prevNodeInParent) {
              if (prevNodeInParent[e.col]) {
                isCheckedInParent = true;
                break;
              }
              prevNodeInParent = prevNodeInParent.previousSibling;
            }

            if (isCheckedInParent) break;

            parentNode = parentNode.parentNode;
          }
        }
      }
    }
  }

  async down2Excel(sheetOption, customOption) {
    if (customOption) {
      const fileName = sheetOption.fileName || new Date().getTime() + '.xlsx';
      const extention = fileName.split('.')[1];
      const uuid = $_uuidv4() + '.' + extention;
      const _customOption = {};
      _customOption[uuid] = {...customOption};
      _customOption[uuid]['fileName'] = sheetOption.fileName;
      await axios.post('/setDataMap', _customOption, {baseURL: process.env.VUE_APP_FILE_URL});
      sheetOption['fileName'] = uuid;
      sheetOption['reqHeader'] = {Authorization: store.getters['jwt/accessToken']};
    }
    this.sheet.down2Excel(sheetOption);
  }

  convertDateOfList(list, cols = []) {
    const regExp = new RegExp('\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}');
    return list.map(item => {
      if (cols.length) {
        cols.forEach(key => {
          const value = item[key];
          if (regExp.test(value)) {
            item[key] = new Date(value).getTime();
          }
        });
      } else {
        for (let key in item) {
          const value = item[key];
          if (regExp.test(value)) {
            item[key] = new Date(value).getTime();
          }
        }
      }

      return item;
    });
  }
};

export default SheetUtil;
