<template>
  <pmis-content-box>
    <template #header-left>
      <label>현장</label>
      <iui-text type="search" :value.sync="searchPrjNm" @enter="onSearch" />

      <label class="ml10">낙찰일</label>
      <iui-datepicker :value.sync="searchFrDt" @change="onSearch" :group="searchDateGroup.seq1" />
      <label>~</label>
      <iui-datepicker :value.sync="searchToDt" @change="onSearch" :group="searchDateGroup.seq2" />

      <iui-button value="검색" @click="onSearch" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="rfqList"
      @loadSheet="sheet = $event"
      :events="{
        onRenderFirstFinish: onSearch,
        onDblClick: e => $modalConfirm(e, 1),
        onClick: sheet_onClick,
      }"
    />

    <iui-modal :name="`rfqInfoModal_${_uid}`" title="입찰정보" size-type="size6">
      <RfqInfoPop v-if="bidType === 'mat'" :rfqNo="propsRfqNo" />
      <SubRfqInfoPop v-if="bidType === 'sub'" :rfqNo="propsRfqNo" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/poTargetRfqList.js';
import {selectCompletedRfqList, selectPoByRfqInfo} from '@/view/bidding/po/mat/api/po.js';
import {selectCompletedSubRfqList, selectSubPoByRfqInfo} from '@/view/bidding/po/sub/api/subPo.js';
import RfqInfoPop from '@/view/bidding/po/mat/components/RfqInfo.vue';
import SubRfqInfoPop from '@/view/bidding/po/sub/components/RfqInfo.vue';

export default {
  components: {RfqInfoPop, SubRfqInfoPop},
  props: {
    bidType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
      rfqList: [],
      searchPrjNm: '',
      searchFrDt: '',
      searchToDt: '',
      searchDateGroup: {
        seq1: {grpNm: 'searchDate', seq: 1},
        seq2: {grpNm: 'searchDate', seq: 2},
      },
      propsRfqNo: '',
    };
  },
  created() {
    this.addEvent([{name: 'PoTargetRfqList_Confirm', func: this.onConfirm}]);
  },
  methods: {
    async onSearch() {
      const param = {
        orgCd: this.pgmCd,
        searchPrjNm: this.searchPrjNm,
        searchFrDt: this.searchFrDt,
        searchToDt: this.searchToDt,
      };
      const selectPoByRfqListApi = {mat: selectCompletedRfqList, sub: selectCompletedSubRfqList};
      this.rfqList = (await selectPoByRfqListApi[this.bidType](param)).data;
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data' && e.col === 'rfqNo') {
        this.propsRfqNo = e.row.rfqNo;
        this.$modal.show(`rfqInfoModal_${this._uid}`);
      }
    },
    onConfirm(callback) {
      const selectPoByRfqInfoApi = {mat: selectPoByRfqInfo, sub: selectSubPoByRfqInfo};
      selectPoByRfqInfoApi[this.bidType](this.sheet.getFocusedRow().rfqNo).then(response => {
        const {rfqInfo, quotItem} = response.data;
        const poInfo = {
          poAmt: rfqInfo.lastAmt,
          vatAmt: rfqInfo.lastVatAmt,
          totAmt: rfqInfo.lastTotAmt,
        };
        callback({rfqInfo, quotItem, poInfo});
      });
    },
  },
};
</script>

<style></style>
