<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col>
        <ib-sheet
          :uid="_uid"
          :init.sync="initSheet"
          :autoLoad="false"
          :options="sheetOpt"
          @loadSheet="loadSheet"
          :events="{onClick: sheet_onClick}"
        />
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import sheetOpt from './sheetOptions/compareSheet.js';
import {selectRfqCompareInfos} from '@/view/bidding/rfq/mat/api/rfq.js';

export default {
  props: {
    rfqNo: {
      type: String,
      required: true,
    },
    bidRnd: {
      type: String,
    },
  },
  data() {
    return {
      initSheet: false,
      sheetOpt: {},
    };
  },
  watch: {
    rfqNo(v) {
      if (v) {
        this.initCompareSheet();
      }
    },
  },
  mounted() {
    this.initCompareSheet();
  },
  methods: {
    initCompareSheet() {
      this.sheetOpt = sheetOpt(this);
      this.initSheet = true;
    },
    loadSheet(sheet) {
      selectRfqCompareInfos(this.rfqNo, this.bidRnd).then(response => {
        sheet.loadSearchData(response.data.compareQuotList || []);
      });
    },
    sheet_onClick(e) {
      if (e.row.Kind == 'Data' && e.col == 'itemNm') {
        this.$store.commit('setModalParam', {matCode: e.row.itemCd});
        this.$modal.show('MatCodeInfoModal');
      }
    },
  },
};
</script>

<style></style>
