export default that => {
  return {
    Cfg: {
      CanEdit: false,
    },
    Cols: [
      {
        Header: 'ID',
        Name: 'empNo',
        Type: 'Text',
        RelWidth: 1,
        Align: 'left',
      },
      {
        Header: '성명',
        Name: 'name',
        Type: 'Text',
        RelWidth: 1,
        Align: 'left',
      },
      {
        Header: '부서명',
        Name: 'blnDepNm',
        Type: 'Text',
        RelWidth: 1,
        Align: 'left',
      },
      {Name: 'dtsNm', Visible: 0},
      {Name: 'jkdNm', Visible: 0},
      {Name: 'depNm', Visible: 0},
      {Name: 'tlno', Visible: 0},
      {Name: 'mobileTlno', Visible: 0},
      {Name: 'pgmCd', Visible: 0},
      {Name: 'dtsNo', Visible: 0},
      {Name: 'jkdNo', Visible: 0},
      {Name: 'depNo', Visible: 0},
      {Name: 'postNo', Visible: 0},
      {Name: 'adr1', Visible: 0},
      {Name: 'adr2', Visible: 0},
    ],
  };
};
