<template>
  <pmis-page>
    <pmis-tab-box>
      <template v-slot:title>상세정보</template>
      <form onsubmit="return false;">
        <iui-container-new type="css-flex">
          <i-row height="170px">
            <i-col>
              <iui-container-new type="table" theme="bullet" width="800px">
                <i-row>
                  <i-col-header>
                    구분
                  </i-col-header>
                  <i-col>
                    <iui-text :value="'질의응답'" :enable="false" width="100px" />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header required>
                    업무구분
                  </i-col-header>
                  <i-col>
                    <iui-text v-if="isAnswer" :enable="false" width="100px" :value="detailInfo.tskDsNm" />
                    <iui-select
                      v-else
                      :value="detailInfo.tskDs"
                      @change="value => setDetailInfo({tskDs: value})"
                      :items="menuItems"
                      defaultCd="S"
                      :enable="!answered"
                      required
                      :error-message="{title: '업무구분', message: '업무구분을 선택해주세요.'}"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header required>
                    제목
                  </i-col-header>
                  <i-col colspan="3">
                    <iui-text
                      :value="detailInfo.tl"
                      @input="setDetailInfo({tl: $event.target.value})"
                      :enable="isNew || isEdit"
                      max-length="100"
                      width="410px"
                      required
                      :error-message="{title: '제목', message: '제목을 입력해주세요.'}"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>
                    첨부파일
                  </i-col-header>
                  <i-col>
                    <!-- 답변상태 : 첨부파일 미존재시 클릭불가 -->
                    <pmis-file-list
                      :modal="true"
                      :companyCode="params.pgmCd"
                      :fileNumber="detailInfo.flNo"
                      :allowAdd="isNew || isEdit"
                      :id="'fileComp_Question' + _uid"
                      :modalBtnEnable="!((isAnswer || answered) && detailInfo.flNo == 0)"
                      :saveAuth="isRegistrant"
                      @delete-complete="onDeleteComplete"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col colspan="3">
                    <iui-container-new type="table" theme="bullet">
                      <i-row>
                        <i-col-header>
                          등록자
                        </i-col-header>
                        <i-col>
                          <iui-text width="100px" :value="isNew ? userInfo.name : detailInfo.qnm" :enable="false" />
                        </i-col>
                        <i-col-header>
                          등록일시
                        </i-col-header>
                        <i-col>
                          <iui-text width="130px" :value="detailInfo.qdttm" :enable="false" />
                        </i-col>
                      </i-row>
                    </iui-container-new>
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>질의내용</i-col-header>
                </i-row>
              </iui-container-new>
            </i-col>
          </i-row>
          <i-row :height="isNew || isEdit ? '500px' : '200px'">
            <i-col width="1200px">
              <Editor v-if="isNew || isEdit" height="500px" initialEditType="wysiwyg" ref="Editor" />
              <Viewer v-else ref="Viewer" @load="onViewerLoad" />
            </i-col>
          </i-row>
          <i-row height="326px" v-if="isView || isAnswer">
            <i-col width="1200px">
              <BoardReply :boardStatus="boardStatus" />
            </i-col>
          </i-row>
        </iui-container-new>
      </form>
    </pmis-tab-box>
  </pmis-page>
</template>

<script>
import {mapGetters} from 'vuex';
import store from '@/view/board/store/board.js';

import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import {Editor, Viewer} from '@toast-ui/vue-editor';
import {deleteQnaInfo, selectQnaDetailInfo, saveQnaDetailInfo} from '../api/qna.js';

import BoardReply from '../answer/components/BoardReply';
export default {
  components: {
    Editor,
    Viewer,
    BoardReply,
  },
  data() {
    return {
      keyInfo: {},
      menuItems: [],
      boardStatus: '',
    };
  },
  computed: {
    ...mapGetters('menu', {menu: 'menu'}),
    isNew() {
      return this.boardStatus == 'I';
    },
    isEdit() {
      return this.boardStatus == 'E' && this.isRegistrant && !this.answered;
    },
    isView() {
      return this.answered || (!this.answered && !this.isRegistrant && !this.isAnswer);
    },
    isAnswer() {
      return this.boardStatus == 'A';
    },
    isRegistrant() {
      return this.params.empNo == this.userInfo.empNo && this.params.pgmCd == this.userInfo.pgmCd;
    },
    answered() {
      return this.params.rqId ? true : false;
    },
  },
  watch: {
    $route() {},
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['detailInfo']);
    $mapMutations(this, ['setDetailInfo', 'initDetailInfo']);
  },
  created() {
    this.addEvent([{name: 'QnaBorderView_onSearch', func: this.onSearch}]);

    this.params = {...this.$route.params};
    this.initData();
    this.boardStatus = this.params.boardStatus;
    this.canPrint = false;
    this.canNew = false;
    if (this.isEdit) {
      this.canSave = true;
      this.canDelete = true;
    } else if (this.isNew) {
      this.canSave = true;
      this.canDelete = false;
    } else if (this.isView) {
      this.canSave = false;
      this.canDelete = false;
    }
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
    if (this.isEdit || this.isView || this.isAnswer) {
      this.onSearch();
    }
  },
  methods: {
    onViewerLoad(e) {
      e.options.el.style = 'height:100%; padding: 10px 25px 0px 25px; border: 1px solid #e5e5e5; overflow:auto;';
    },
    initData() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.keyInfo.pgmCd = this.params.pgmCd || this.pgmCd;
      this.keyInfo.natNo = this.params.natNo;
      this.keyInfo.natDs = this.params.natDs;

      this.initDetailInfo();
      this.setDetailInfo({
        pgmCd: this.params.pgmCd,
        natNo: this.params.natNo || 0,
        natDs: this.params.natDs,
        qid: this.params.empNo,
        rgprId: this.params.empNo,
      });

      this.menuItems = [];
      this.menu.forEach(el => {
        this.menuItems.push({
          text: el.mnuNm,
          value: el.mnuCd,
        });
      });
    },
    async onSearch() {
      const response = await selectQnaDetailInfo(this.keyInfo);
      this.setDetailInfo(response.data);

      if (this.isEdit) {
        this.$refs.Editor.invoke('setHtml', this.detailInfo.cts);
      } else {
        this.$refs.Viewer.invoke('setMarkdown', this.detailInfo.cts);
        if (this.isView || this.isAnswer) {
          this.callEvent({name: 'Qna_Answer_onAnswerLoad'});
        }
      }
    },
    async onSave() {
      if (this.isAnswer) {
        this.callEvent({name: 'Qna_Answer_onSave'});
        return;
      }
      this.setDetailInfo({cts: this.$refs.Editor.invoke('getHtml').trim()});

      let param = {...this.detailInfo, cud: this.isEdit ? 2 : 1};
      const response = await saveQnaDetailInfo(param);
      if (response.data) {
        this.onGoList();
      }
    },
    async onDelete() {
      if (!(await this.$confirm({title: '질문삭제', message: '질문을 삭제하시겠습니까?'}))) {
        return;
      }
      const response = await deleteQnaInfo(this.detailInfo);
      if (response.data) {
        this.onGoList();
      }
    },
    onGoList() {
      this.location.go(-1, {search: true});
    },
    onDeleteComplete(flNo) {
      if (flNo == 0) {
        this.setDetailInfo({flNo: flNo});
        saveQnaDetailInfo({...this.detailInfo}).then(response => {
          this.onSearch();
        });
      }
    },
  },
};
</script>

<style></style>
