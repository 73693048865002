export default that => {
  return {
    Cfg: {
      CanEdit: 0,
      DataMerge: 1,
    },
    Cols: [
      {
        Header: ['NO'],
        Name: 'rownum',
        Type: 'Text',
        Align: 'center',
        Width: '50',
      },
      {
        Header: ['대분류코드'],
        Name: 'lpCode',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['대분류 명칭'],
        Name: 'lpName',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['중분류코드'],
        Name: 'mpCode',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['중분류 명칭'],
        Name: 'mpName',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['경비 코드'],
        Name: 'oprCode',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['명칭'],
        Name: 'itemName',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['규격'],
        Name: 'size',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['단위'],
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['사용여부'],
        Name: 'useDs',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
    ],
  };
};
