<template>
  <pmis-tab-box>
    <template v-slot:title>접속이력</template>
    <pmis-content-box>
      <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" @loadSheet="loadSheet" />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
import options from './sheetOption/LogSheet.js';
import {selectLoginLogList} from '../api/LoginLog.js';
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      loadSearchData: [],
      Options: options(this),
      searchSe: 1,
      searchNm: '',
      searchSeList: [
        {value: 1, text: '성명'},
        {value: 2, text: '아이디'},
      ],
    };
  },

  beforeCreate() {
    $mapMutations(this, ['setSearchInfo']);
  },

  created() {
    this.addEvent([
      {name: 'log_selectLoginLogList', func: this.selectLoginLogList},
      {name: 'log_onClearGrid', func: this.onClearGrid},
    ]);
  },

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    async selectLoginLogList(param) {
      if (!param)
        param = {
          pgmCd: this.userInfo.pgmCd,
          searchSe: this.searchSe,
          searchNm: this.searchNm,
        };
      const response = await selectLoginLogList(param);
      this.loadSearchData = response.data;
    },
    onClearGrid() {
      this.loadSearchData = [];
    },
  },
};
</script>

<style scoped></style>
