export const selectBreakdownCostClList = param => axios.post('/breakdownCost/selectBreakdownCostClList', param);
export const selectBreakdownCostList = param => axios.post('/breakdownCost/selectBreakdownCostList', param);
export const saveSystemSctm = param => $save('/breakdownCost/saveSystemSctm', param);
export const selectSystemSctmD = param => axios.post('/breakdownCost/selectSystemSctmD', param);
export const deleteSystemComcode = param => axios.post('/breakdownCost/deleteSystemComcode', param);
export const deleteSystemSctm = param => axios.post('/breakdownCost/deleteSystemSctm', param);
export const saveSystemSctmD = param => $save('/breakdownCost/saveSystemSctmD', param);
export const updateSystemSctmByUcstApply = param => axios.post('/breakdownCost/updateSystemSctmByUcstApply', param);
export const copySystemSctmD = param => axios.post('/breakdownCost/copySystemSctmD', param);
export const deleteSystemSctmDList = param => axios.post('/breakdownCost/deleteSystemSctmDList', param);
