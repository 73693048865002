<template>
  <pmis-tab-box>
    <template #title>양식목록</template>
    <template #header-left>
      <iui-text type="search" label="제목" :value.sync="searchText" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="loadSearchData"
      :events="{
        onRenderFirstFinish: () => onSearch(),
        onSearchFinish: sheet_onSearchFinish,
        onClick: sheet_onClick,
      }"
    />
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/TemplateListSheet.js';
import {selectBaseTemplateList} from '@/view/sysManage/baseSysManage/StandardTemplateRegist/apis/baseTemplate.js';
import {selectTemplateList} from '@/view/sysManage/baseSysManage/StandardTemplateRegist/apis/template.js';

export default {
  props: {
    isBasePage: {
      type: Boolean,
      default: false,
    },
    docTp: {
      // 문서유형 ("000001": 기안양식, "000002": 표준양식)
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],
      focusKey: '',
      searchText: '',
    };
  },
  created() {
    this.addEvent([{name: 'TemplateList_Search', func: this.onSearch}]);
    this.addFuncSearch(this.onSearch);
  },
  methods: {
    async onSearch(focusKey = '') {
      this.focusKey = focusKey;
      const param = {
        searchText: this.searchText,
        pgmCd: this.pgmCd,
        docTp: this.docTp,
      };

      const searchApi = this.isBasePage ? selectBaseTemplateList : selectTemplateList;
      const response = await searchApi(param);
      this.loadSearchData = response.data;
    },
    onSearchDetail(row) {
      if (row) {
        this.callEvent({name: 'TemplateDetail_Search', param: row});
      } else {
        this.callEvent({name: 'TemplateDetail_Init'});
      }
    },
    sheet_onSearchFinish(e) {
      if (this.focusKey) {
        const focusRow = e.sheet.getDataRows().find(row => row.fomNo === this.focusKey);
        if (focusRow !== undefined) {
          e.sheet.focus(focusRow);
          this.onSearchDetail(focusRow);
        } else {
          this.onSearchDetail(e.sheet.getFirstRow());
        }
      } else {
        this.onSearchDetail(e.sheet.getFirstRow());
      }
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data') {
        this.onSearchDetail(e.row);
      }
    },
  },
};
</script>

<style></style>
