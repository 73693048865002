<template>
  <pmis-content-box :loading="loading">
    <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" @loadSheet="loadSheet" />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/UserGroupListSheet.js';
import {selectUserGroupRList} from '../api/userAuth.js';
export default {
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this), 1, 'auth'),
      loadSearchData: [],
      loading: false,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['empNo']);
  },

  created() {
    this.addEvent([
      {name: 'UserGroupList_selectUserGroupRList', func: this.onSearch},
      {name: 'UserGroupList_getSaveJson', func: this.getSaveJson},
    ]);
  },

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    async onSearch() {
      this.loading = true;
      const response = await selectUserGroupRList({empNo: this.empNo});
      this.loadSearchData = response.data;
      this.loading = false;
    },
    getSaveJson(callback) {
      if (callback) {
        callback(this.sheet.getSaveJson(0).data);
      }
    },
  },
};
</script>

<style></style>
