export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {HeaderMerge: 3},
    Def: {
      Row: {NoColor: 2, VAlign: 'TOP'},
    },
    LeftCols: [
      {Name: 'total', Visible: false},
      {
        Header: [{Value: '공종명'}, {Value: '공종명'}],
        Name: 'mgName',
        Type: 'Text',
        Width: 140,
        CanEdit: false,
      },
      {
        Header: [{Value: '도급코드'}, {Value: '도급코드'}],
        Name: 'itemCode',
        Type: 'Text',
        Width: 130,
        CanEdit: false,
        Align: 'Center',
      },
      {
        Header: [{Value: '내역명'}, {Value: '내역명'}],
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 160,
        CanEdit: false,
      },
      {
        Header: [{Value: '규격'}, {Value: '규격'}],
        Name: 'ssize',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 160,
        CanEdit: false,
      },
      {
        Header: [{Value: '단위'}, {Value: '단위'}],
        Name: 'unit',
        Type: 'Text',
        Width: 60,
        CanEdit: false,
        Align: 'Center',
        Format: $getConstants('UNIT').code,
      },
    ],
    Cols: [
      {
        // TB_CONT_WPLAN_CONS_D	단위작업시공물량정보	RESULT_QTY	실적수량
        Header: [{Value: '금회기성실적'}, {Value: '수량', Color: '#dff9fb'}],
        Name: 'actQty',
        Width: 80,
        CanEdit: false,
        Extend: preset.quantityFormula,
      },
      {
        Header: [{Value: '금회기성실적'}, {Value: '금액', Color: '#dff9fb'}],
        Name: 'actAmt',
        Width: 120,
        CanEdit: false,
        Extend: preset.amountFormula,
        Formula: fr => fr.Row['actQty'] * fr.Row['contPlanPrice'],
      },
      {
        Header: [{Value: '전회유보기성'}, {Value: '수량', Color: '#dff9fb'}],
        Name: 'lastReqQty',
        Width: 80,
        CanEdit: false,
        Extend: preset.quantityFormula,
      },
      {
        Header: [{Value: '전회유보기성'}, {Value: '금액', Color: '#dff9fb'}],
        Name: 'lastReqAmt',
        Width: 120,
        CanEdit: false,
        Extend: preset.amountFormula,
        Formula: fr => fr.Row['lastReqQty'] * fr.Row['contPlanPrice'],
      },
      {
        Header: [{Value: '금회기성'}, {Value: '수량', Color: '#dff9fb'}],
        Name: 'curFixCnt',
        Width: 80,
        CanEdit: false,
        Extend: preset.quantity,
      },
      {
        Header: [{Value: '금회기성'}, {Value: '금액', Color: '#dff9fb'}],
        Name: 'curFixAmt',
        Width: 120,
        CanEdit: false,
        Extend: preset.amountFormula,
        Formula: fr => fr.Row['curFixCnt'] * fr.Row['contPlanPrice'],
      },
      {
        Header: [{Value: '금회유보기성'}, {Value: '수량', Color: '#dff9fb'}],
        Name: 'reqQty',
        Width: 80,
        CanEdit: false,
        Extend: preset.quantityFormula,
        Formula: fr => fr.Row['actQty'] - fr.Row['curFixCnt'],
      },
      {
        Header: [{Value: '금회유보기성'}, {Value: '금액', Color: '#dff9fb'}],
        Name: 'reqAmt',
        Width: 120,
        CanEdit: false,
        Extend: preset.amountFormula,
        Formula: fr => fr.Row['reqQty'] * fr.Row['contPlanPrice'],
      },

      {Name: 'wcode', Visible: false},
      {Name: 'headCode', Visible: false},
      {Name: 'midCode', Visible: false},
      {Name: 'itemSeq', Visible: false},
      {Name: 'billNo', Visible: false},
      {Name: 'taxexeNo', Visible: false},
      {Name: 'contPlanPrice', Visible: false}, // 도급단가
      {Name: 'contPlanQty', Visible: false}, // 도급수량
      {Name: 'totoalFixQty', Visible: false}, // 기성수량
    ],
  };
};
