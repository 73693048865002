const FILE_CONSTATNS = {
  // default 정규식 - 실행파일
  DEFAULT_REGEXP: /(exe|app|bat|sh|jar|py|pl|cgi|php|class|dll|so|com|cmd|gadget|pif|vbs|wsf|out|run|applescript|workflow|command|osx|ksh|csh|deb|rpm)$/i,
  // image 정규식
  IMAGE_REGEXP: /(jpg|jpeg|png|gif|bmp|svg|tiff|webp|ico|jfif|pjpeg|pjp|avif)$/i,
  // logo 정규식
  LOGO_IMAGE_REGEXP: /(png)&/i,
};

export default FILE_CONSTATNS;
