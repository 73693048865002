<template>
  <div class="header-left">
    <iui-datepicker type="month" label="정산년월" :value="investMm" @change="onChangeAtInvestMm" />
    <i class="prev-arrow" @click.prevent.self @click="prev" />
    <i class="post-arrow" @click.prevent.self @click="next" />
    <span class="ml5"></span>
    <iui-text type="search" label="거래처명" :value.sync="custName" @keyup.enter="onSearch" />
    <iui-button value="검색" @click="onSearch" />
  </div>
</template>

<script>
import {selectFixedTimeMatSearchData} from '../api/fixedtimeMaterial.js';
export default {
  props: {},
  data() {
    return {
      investMm: $_getYearMonth(),
      searchIndex: -1,
      custName: '',
      searchDatas: [],
    };
  },
  beforeCreate() {
    $mapGetters(this, ['closeYn']);
    $mapMutations(this, ['setSearchInfo']);
  },
  created() {
    this.addFuncPrj(this.onSearchData);
  },
  methods: {
    onSearchData() {
      this.selectFixedTimeMatSearchData();
    },
    prev() {
      if (this.searchIndex < 1) {
        return;
      }
      this.searchIndex--;
      this.setSearchData();
    },
    next() {
      if (this.searchIndex >= this.searchDatas.length - 1) {
        return;
      }
      this.searchIndex++;
      this.setSearchData();
    },
    setSearchData() {
      const searchData = this.searchDatas[this.searchIndex];
      this.investMm = searchData?.investMm || '';
      this.reqDegree = searchData?.reqDegree || '';
      this.onSearch();
    },
    onChangeAtInvestMm(investMm) {
      this.investMm = investMm;
      this.initSearchIndex();
    },
    initSearchIndex() {
      const searchDatalength = this.searchDatas.length;
      if (!searchDatalength) {
        return;
      }
      if (this.investMm < this.searchDatas[0].investMm) {
        this.searchIndex = -1;
      } else if (this.investMm > this.searchDatas[searchDatalength - 1].investMm) {
        this.searchIndex = searchDatalength;
      } else {
        this.searchIndex = this.searchDatas.indexOf(this.searchDatas.find(data => data.investMm === this.investMm));
      }
    },
    async selectFixedTimeMatSearchData() {
      const response = await selectFixedTimeMatSearchData();
      this.searchDatas = response.data;
      this.searchIndex = this.searchDatas.length - 1;
      this.initSearchData();
      this.setSearchData();
    },
    initSearchData() {
      this.investMm = '';
      this.reqDegree = '';
      this.custName = '';
    },
    onSearch() {
      this.setSearchInfo({
        investMm: this.investMm,
        reqDegree: this.reqDegree,
        custName: this.custName,
      });
      this.$emit('search');
    },
  },
};
</script>

<style scoped></style>
