<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="450px">
          <pmis-tab-box no-border-box no-padding-box>
            <template v-slot:title>장비분류</template>
            <template v-slot:title-right>
              <iui-button value="장비분류삭제" @click="onDeleteEqutType" />
            </template>
            <EquipCl
              :callback="onCallbackEquipCl"
              :equipClInfo="equipClInfo"
              @searchByItemNm="searchByItemNm"
              :base="isBase"
              :focusKey.sync="equipClFocusKey"
              ref="equipCl"
            />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row height="350px">
              <i-col minWidth="780px">
                <pmis-tab-box>
                  <template v-slot:title>상세정보</template>
                  <template v-slot:title-right>
                    <iui-button @click="onPopAddExcel" value="엑셀등록" v-if="!isBase" />
                    <iui-button value="표준코드복제" v-if="!isBase" @click="onStandardCodePopup" />
                  </template>
                  <DetailInfo workSe="Untpc" ref="equipCodeDetail" :base="isBase" :equipClInfo="equipClInfo" />
                </pmis-tab-box>
              </i-col>
              <i-spacer v-if="!isBase" />
              <i-col v-if="!isBase" width="400px">
                <UntpcList :costType="costType" ref="untpcList" />
              </i-col>
            </i-row>
            <i-spacer />
            <i-row>
              <i-col>
                <DetailList
                  ref="equipCodeList"
                  :base="isBase"
                  @equipCodeInfo="setEquipCodeInfo"
                  @onEquipClSearch="onEquipClSearch"
                  :paramObj="{isMultiSelect: true}"
                  :focusKey.sync="equipCodeFocusKey"
                />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
    <iui-modal name="standardCodeModal" title="표준코드 복제" width="1200px" height="700px" :btns="modalBtns">
      <StandardCodePopup type="equip" />
    </iui-modal>
    <iui-modal name="excelUploadPop" title="엑셀등록" :btns="excelBtns" sizeType="size1">
      <ExcelUpload
        uri="/equtCodeRegist/excelUpload"
        title="장비코드등록"
        :rows="excelRows"
        @error="onExcelUploadError"
      />
    </iui-modal>
    <iui-modal name="ExcelUploadResultPop" title="엑셀 업로드 실패 사유" sizeType="size6" @closed="resultList = []">
      <ExcelUploadResult :sheet-opt="excelSheetOpt" :list="resultList" />
    </iui-modal>
  </pmis-page>
</template>

<script>
import EquipCl from './components/EquipCl.vue';
import DetailInfo from './components/DetailInfo.vue';
import DetailList from './components/DetailList.vue';
import UntpcList from '@/view/sysManage/stdCodeManage/components/UntpcList.vue';
import StandardCodePopup from '@/view/sysManage/stdCodeManage/components/StandardCodePopup.vue';
import cloneDeep from 'lodash/cloneDeep';
import {saveStandardCodeList} from './api/EquipCodeRegist.js';
import {deleteUcstPri} from '../api/untpcRegist.js';
import ExcelUploadResult from '@/components/popup/ExcelUploadResult.vue';
import ExcelUpload from '@/components/popup/ExcelUpload.vue';
import excelOptions from '@/view/sysManage/stdCodeManage/equipCodeRegist/components/sheetOption/ExcelUploadResultSheet.js';
import EXCEL_CONSTANTS from '@/constants/excelConstants.js';

export default {
  components: {
    ExcelUpload,
    ExcelUploadResult,
    EquipCl,
    DetailInfo,
    DetailList,
    UntpcList,
    StandardCodePopup,
  },
  data() {
    return {
      costType: $getConstants('COST_TYPE_E').code,
      modalBtns: [{name: '확인', callback: this.onPopupConfirm}],
      equipClInfo: {},
      searchNm: '',
      useDs: $getConstants('USE_YN_Y').code,
      equipClFocusKey: undefined,
      equipCodeFocusKey: undefined,
      isBase: this.$store.getters['mdi/currentUrls'].para === 'base',
      excelBtns: [{name: '확인', callback: this.onPopConfirmExcel}],
      resultList: [],
      excelRows: [
        {
          value: [
            {value: '대분류 코드', style: {type: 'TEXT'}},
            {value: '대분류 명칭'},
            {value: '중분류 코드', style: {type: 'TEXT'}},
            {value: '중분류 명칭'},
            {value: '장비 코드', style: {type: 'TEXT'}},
            {value: '명칭'},
            {value: '규격'},
            {
              value: '단위코드',
              validate: {
                excelEnum: EXCEL_CONSTANTS.COMMON_CODE,
                pCode: $getConstants('UNIT').code,
                withDropdown: false,
                checksName: false,
              },
            },
            {
              value: '사용여부',
              validate: {
                checkList: [
                  {name: 'Y', value: $getConstants('Y').code},
                  {name: '', value: ''},
                ],
              },
            },
          ],
        },
      ],
      excelSheetOpt: excelOptions(this),
    };
  },

  created() {
    this.canSearch = true;
    this.canNew = true;
    this.canSave = true;
    this.canDelete = true;
    this.addFuncSearch(this.onSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },

  methods: {
    onStandardCodePopup() {
      this.$modal.show('standardCodeModal');
    },
    onPopupConfirm() {
      this.callEvent({
        name: 'StandardCodePopup_confirm',
        param: data => {
          this.onSaveStandardCodeList(data);
          this.$modal.hide('standardCodeModal');
        },
      });
    },
    async onSaveStandardCodeList(data) {
      let copyList = [];
      data.forEach(row => {
        copyList.push({
          pgmCd: this.pgmCd,
          level: row.level,
          leCode: row.leCode,
          meCode: row.meCode,
          equipCode: row.equipCode,
          itemName: row.itemName,
          size: row.size,
          unit: row.unit,
          flNo: row.flNo,
          useDs: row.useDs,
        });
      });
      const response = await saveStandardCodeList({copyList});
      if (response.data) {
        this.equipClFocusKey = String(copyList[0].meCode);
        this.equipCodeFocusKey = String(copyList[0].equipCode);
        this.onSearch();
      }
    },
    onCallbackEquipCl(row) {
      this.equipClInfo = row;
      this.$refs.equipCodeList.equipClInfo = this.equipClInfo;
      this.setUntcpListCode();
      this.initSearchNm();
      this.initEquipCodeInfo();
      this.onSearchEquipCode();
    },
    setUntcpListCode() {
      if (!this.isBase) {
        this.$refs.untpcList.code = this.equipClInfo.level > 1 ? this.equipClInfo.code : '';
      }
    },
    setEquipCodeInfo(equipCodeInfo) {
      this.$refs.equipCodeDetail.equipCodeInfo = cloneDeep(equipCodeInfo);
      this.onUntpcListSearch();
    },
    onUntpcListSearch() {
      if (!this.isBase) {
        this.$refs.untpcList.itemCode = this.$refs.equipCodeDetail.equipCodeInfo.equipCode;
        this.$refs.untpcList.onSearch();
      }
    },
    onEquipClSearch(code) {
      this.$refs.equipCl.searchInTree(code);
    },
    onAdd() {
      this.initUntpcList();
      this.initEquipCodeInfo();
    },
    onSearch() {
      this.$refs.equipCl.onSearch();
    },

    async onSave() {
      const result = await this.$refs.equipCodeDetail.onSave();
      if (result) {
        this.equipClInfo = result.equipClInfo;
        this.equipCodeReload(result.equipCodeInfo);
        this.equipClReload();
        this.setCud('0');
      }
    },
    equipCodeReload(equipCodeInfo) {
      this.$refs.equipCodeList.equipCodeInfo = equipCodeInfo;
      if (equipCodeInfo.equipCode) {
        this.$refs.equipCodeList.onReloadSheet();
      }
    },
    equipClReload() {
      this.$refs.equipCl.onReloadSheet();
    },
    async onDelete() {
      if (this.cud === 1) {
        this.$alert({title: '삭제', message: '신규 입력 중에는 삭제할 수 없습니다.'});
        return;
      }
      const deletedList = await this.$refs.equipCodeList.onDelete();
      if (!deletedList) {
        return;
      }
      deletedList.forEach(item => {
        item.pgmCd = this.pgmCd;
        item.itemCode = item.equipCode;
        item.costType = this.costType;
      });

      const response = await deleteUcstPri(deletedList);
      if (response.data) {
        this.initUntpcList();
      }
    },
    async onDeleteEqutType() {
      if (this.cud === 1) {
        this.$alert({title: '삭제', message: '신규 입력 중에는 삭제할 수 없습니다.'});
        return;
      }
      const result = await this.$refs.equipCl.onDelete();
      if (result) {
        this.untpcDeleteUcstPriAsChildren(result);
      }
    },
    untpcDeleteUcstPriAsChildren(param) {
      if (!this.isBase) {
        this.$refs.untpcList.deleteUcstPriAsChildren({costType: this.costType, itemCode: param.code});
      }
    },
    onSearchEquipCode() {
      this.initUntpcList();
      this.initEquipCodeList();
      this.$refs.equipCodeList.onSearch();
    },
    initEquipCodeList() {
      this.$refs.equipCodeList.removeAll();
    },
    initSearchNm() {
      this.$refs.equipCodeList.searchNm = '';
    },
    initEquipCodeInfo() {
      this.$refs.equipCodeDetail.equipCodeInfo = {
        useDs: this.cud === 1 && this.equipClInfo.level === 3 ? this.useDs : '',
      };
    },
    initUntpcList() {
      if (!this.isBase) {
        this.$refs.untpcList.removeAll();
      }
    },
    searchByItemNm(searchNm) {
      this.initEquipCodeInfo();
      this.$refs.equipCodeList.searchNm = searchNm;
      this.onSearchEquipCode();
    },
    onPopAddExcel() {
      this.$modal.show('excelUploadPop');
    },
    onPopConfirmExcel() {
      this.callEvent({
        name: 'excelUpload',
        param: () => {
          this.onSearch();
          this.$modal.hide('excelUploadPop');
        },
      });
    },
    onExcelUploadError(error, list) {
      if (error === 'server') {
        this.$alert({title: '엑셀업로드', message: '자재코드 엑셀파일을 확인하십시오.'});
      } else {
        this.$alert({title: '엑셀업로드', message: error});
        if (list && list.length > 0) {
          this.resultList = list;
          this.$modal.show('ExcelUploadResultPop');
        }
      }
    },
  },
};
</script>
