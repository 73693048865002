export default that => {
  let preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      CanEdit: 0,
    },
    Def: {
      Row: {
        CanFormula: 1,
      },
    },
    LeftCols: [
      {
        Header: '정산년월',
        Name: 'investMm',
        Width: 100,
        Align: 'center',
        CustomFormat: '####-##',
      },
      {
        Header: '정산구분',
        Name: 'investKind',
        Width: 100,
        Align: 'center',
        Format: $getConstants('INVEST_KIND').code,
        CustomFormat: v => {
          const obj = {};
          obj[$getConstants('INVEST_KIND_ROUTINE').code] = '정기';
          obj[$getConstants('INVEST_KIND_NOT_ROUTINE').code] = '수시';
          return obj[v];
        },
      },
      {
        Header: '차수',
        Name: 'reqDegree',
        Width: 50,
        Align: 'center',
        Formula: fr => (fr.Row.closeYn == $getConstants('Y').code ? '' : fr.Row.reqDegree),
      },
      {Header: '소속명', Name: 'blnDepNm', RelWidth: 1, MinWidth: 100, Align: 'left'},
      {Header: 'ID', Name: 'empNo', Width: 100, Align: 'center'},
      {Header: '성명', Name: 'name', Width: 100, Align: 'center'},
    ],
    Cols: [
      {Header: '월출역일수', Name: 'monthlyDay', Width: 90, Align: 'center'},
      {
        Header: '월투입공수',
        Name: 'monthlyQty',
        Extend: preset.quantity,
        RelWidth: 1,
        Visible: 0,
      },
      {Header: '월투입금액', Name: 'monthlyAmt', Extend: preset.amount, Width: 90},
      {
        Header: '건강보험',
        Name: 'healthInsurAmt',
        Extend: preset.amount,
        Width: 90,
        Visible: 0,
      },
      {Header: '장기요양', Name: 'careAmt', Extend: preset.amount, Width: 90},
      {
        Header: '국민연금',
        Name: 'pensionAmt',
        Extend: preset.amount,
        Width: 90,
        Visible: 0,
      },
      {
        Header: '고용보험',
        Name: 'employInsurAmt',
        Extend: preset.amount,
        Width: 90,
        Visible: 0,
      },
      {Header: '소득세', Name: 'incometax', Extend: preset.amount, Width: 90},
      {Header: '지방소득세', Name: 'resitax', Extend: preset.amount, Width: 90},
      {Header: '공제계', Name: 'deductSum', Extend: preset.amount, Width: 90},
      {Header: '실지급금액', Name: 'actPayAmt', Extend: preset.amount, Width: 90},
    ],
  };
};
