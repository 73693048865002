<template>
  <iui-container-new type="table" theme="bullet" height="70%">
    <i-row height="10%">
      <i-col width="90px"> </i-col>
      <i-col> </i-col>
    </i-row>
    <i-row>
      <i-col-header>복사단가</i-col-header>
      <i-col>
        <iui-select
          :items="copyUcstCodeItems"
          :value="copyUcstCode"
          :defaultCd="'S'"
          @change="copyUcstCodeChange"
          width="100%"
        />
      </i-col>
    </i-row>
    <i-row>
      <i-col-header>복사대상단가</i-col-header>
      <i-col>
        <iui-select
          :items="copyTargetUcstCodeItems"
          :value="copyTargetUcstCode"
          @change="value => (copyTargetUcstCode = value)"
          :defaultCd="'S'"
          width="100%"
        />
      </i-col>
    </i-row>
    <i-row>
      <i-col-header>할증</i-col-header>
      <i-col>
        <iui-text type="rate" :value="rate" @change="rate = $event.target.value" max-length="3" />
      </i-col>
    </i-row>
    <i-row>
      <i-col-header>적용대상</i-col-header>
      <i-col>
        <iui-radio-group :items="applyTargetItems" :value="applyTarget" @change="applyTarget = $event.target.value" />
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import {selectUcstCodeMList} from '../api/untpcRegist.js';
export default {
  props: {
    costType: {
      type: String,
    },
    base: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      copyUcstCode: '',
      copyTargetUcstCode: '',
      rate: '100',
      applyTarget: '',
      copyUcstCodeItems: [],
      copyTargetUcstCodeItems: [],
      applyTargetItems: [
        {value: '', label: '전체'},
        {value: '1', label: '0인 단가만적용'},
      ],
    };
  },

  created() {
    this.onSearch();
    this.addEvent([{name: 'getUntpcCopyData', func: this.getUntpcCopyData}]);
  },
  mounted() {},

  methods: {
    copyUcstCodeChange(value) {
      this.copyUcstCode = value;
      this.copyTargetUcstCodeItems = [];
      this.copyUcstCodeItems.forEach(item => {
        if (item.value !== value) {
          this.copyTargetUcstCodeItems.push(item);
        }
      });
      setTimeout(() => {
        this.copyTargetUcstCode = '';
      }, 10);
    },
    async onSearch() {
      this.copyUcstCodeItems = [];
      const param = {costType: this.costType, base: this.base};
      const response = await selectUcstCodeMList(param);
      if (response.data) {
        response.data.forEach(item => {
          this.copyUcstCodeItems.push({
            value: item.ucstCode,
            text: item.ucstName,
          });
        });
      }
    },
    getUntpcCopyData() {
      return {
        copyUcstCode: this.copyUcstCode,
        copyTargetUcstCode: this.copyTargetUcstCode,
        rate: this.rate,
        applyTarget: this.applyTarget,
      };
    },
  },
};
</script>

<style scoped></style>
