<template>
  <pmis-page ref="page">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col>
          <iui-tab ref="tab" :comps="tabList" :keep-alive="true" @after-active-tab="onAfterActiveTab">
            <template v-slot:tab-right>
              <iui-amount-unit :amount-unit="amountUnit" @change="onAmountChange" />
            </template>
          </iui-tab>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import PrjList from '@/view/Project/components/PrjList.vue';

export default {
  components: {
    PrjList,
  },
  data() {
    return {
      tabList: [
        {
          tabNm: '도급기성실적',
          compNm: 'DtlsPxCondResult',
          path: '@/view/Project/pxcondStatus/result/component/DtlsPxCondResult.vue',
        },
        {
          tabNm: '실행기성실적',
          compNm: 'ExecutePxCondResult',
          path: '@/view/Project/pxcondStatus/result/component/ExecutePxCondResult.vue',
        },
      ],
      tabIndex: 0,
      amountUnit: '',
    };
  },
  created() {
    this.amountUnit = this.userInfo?.amountUnit
      ? this.userInfo.amountUnit
      : this.$store.state.code2.codeAlias.AMOUNT_UNIT_ONE_THOUSAND_WON.code;
    this.addFuncPrj(this.onSearch);
    this.addFuncSearch(this.onSearch);
    this.onSearch();
  },
  methods: {
    onAfterActiveTab(index) {
      this.tabIndex = index;
      this.onSearch();
    },
    async onSearch() {
      const prefix = this.tabIndex === 0 ? 'DTLS' : 'EXECUTE';
      this.callEvent({name: prefix + '_getYmList', param: this.amountUnit});
    },
    onAmountChange(value) {
      this.amountUnit = value;
      const prefix = this.tabIndex === 0 ? 'DTLS' : 'EXECUTE';
      this.callEvent({name: `${prefix}_onAmountChange`, param: value});
    },
  },
};
</script>
