<template>
  <pmis-tab-box ref="prjList">
    <div class="prj_nav_wrap">
      <a href="#" class="btn_big_close" @click.prevent.self @click="openToggle" />
      <span class="blind">열고/닫기</span>
    </div>
    <template v-slot:title>현장목록</template>
    <pmis-content-box>
      <template v-slot:header-left>
        <iui-text type="search" width="200px" :value.sync="searchNm" @enter="onSearch" /><iui-button
          value="검색"
          @click="onSearch"
        />
      </template>
      <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" :events="{onSearchFinish, onClick}" />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
import {selectPrjEstMList} from '../api/estmtPrjRegist.js';
import options from './sheetOption/PrjEstListSheet.js';
export default {
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      Options: options(this),
      loadSearchData: [],
      searchNm: '',
      bidStat: '',
      bidStatItems: [],
      focusKey: '',
      defaultColCssText: '',
    };
  },

  async created() {
    this.addEvent([{name: 'prjList_onSearch', func: this.onSearch}]);
    await this.$nextTick();
    this.onSearch();
  },

  mounted() {
    if (this.$refs.prjList.$parent.$parent.$el.className == 'col') {
      this.defaultColCssText = this.$refs.prjList.$parent.$parent.$el.style.cssText;
    }
  },
  methods: {
    async onSearch() {
      const param = {
        searchNm: this.searchNm,
        bidStat: this.bidStat,
      };
      const response = await selectPrjEstMList(param);
      if (!response.data.length) {
        this.$store.commit('setPrjCd', undefined);
      }
      this.loadSearchData = response.data;
    },
    onSearchFinish(e) {
      const prjCd = this.$store.getters['prjCd'];
      let focusRow;
      if (prjCd) {
        focusRow = e.sheet.getDataRows().find(row => row.prjEstCd == prjCd);
      } else {
        focusRow = e.sheet.getFirstRow();
      }

      e.sheet.focus(focusRow);
      this.$store.commit('setPrjCd', focusRow?.prjEstCd ? String(focusRow.prjEstCd) : undefined);
      this.$store.dispatch('project/setLoadPrj', focusRow);
      this.$store.dispatch('project/runFunc');
      this.callback(e.sheet.getFirstRow());
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.callEvent({name: 'setCud', param: 0});
        this.$store.commit('setPrjCd', e.row.prjEstCd ? String(e.row.prjEstCd) : undefined);
        this.$store.dispatch('project/setLoadPrj', e.row);
        this.$store.dispatch('project/runFunc');
        this.callback(e.row);
      }
    },

    openToggle(e) {
      if (e.target.classList == 'btn_big_close') {
        e.target.classList = 'btn_big_open';
        e.target.closest('.col').style['flex-grow'] = '0';
        e.target.closest('.col').style['flex-shrink'] = '0';
        e.target.closest('.col').style['flex-basis'] = '0px';
        e.target.closest('.col').style.transition = 'all 0.5s ease';

        if (e.target.closest('.col').nextElementSibling.className == 'spacer') {
          e.target.closest('.col').nextElementSibling.style = 'flex: 0 0 0px;';
        }
      } else {
        e.target.classList = 'btn_big_close';
        e.target.closest('.col').style = this.defaultColCssText + ' transition: all 0.5s ease';
        if (e.target.closest('.col').nextElementSibling.className == 'spacer') {
          e.target.closest('.col').nextElementSibling.style = '';
        }
      }
    },
  },
};
</script>

<style scoped>
.prj_nav_wrap {
  position: absolute;
  top: 229px;
  right: -15px;
  z-index: 100;
  transition: all 0.5s ease;
}

.prj_nav_wrap .btn_big_close {
  display: block;
  width: 39px;
  height: 39px;
  background: url('../../../../assets/img/btn_nav_close.png') no-repeat;
}

.prj_nav_wrap .btn_big_open {
  display: block;
  width: 39px;
  height: 39px;
  background: url('../../../../assets/img/btn_nav_open.png') no-repeat;
}
</style>
