<template>
  <form onsubmit="return false;">
    <pmis-content-box>
      <template #title>기본정보</template>
      <iui-container-new type="table" theme="bullet">
        <colgroup>
          <col width="90px" />
          <col width="100%" />
          <col width="10px" />
          <col width="80px" />
          <col width="60%" />
          <col width="10px" />
          <col width="80px" />
          <col width="30%" />
        </colgroup>
        <i-row>
          <template v-if="poIsNew && !poStatus.isExistRfq">
            <i-col-header required>현장</i-col-header>
            <i-col>
              <iui-searchbox
                ref="prjSearchbox"
                type="prj"
                :idValue="poInfo.spotDeptcd"
                :nameValue="poInfo.spotDeptnm"
                :callback="onCallbackPrjCd"
                :required="poIsNew"
                :errorMessage="{title: '현장', message: '필수입력입니다.'}"
              />
            </i-col>
            <i-spacer />
            <i-col-header required>계약자</i-col-header>
            <i-col>
              <iui-searchbox
                type="customer"
                :paramObj="{frmDs: $getConstants('FRM_DS_MAT').code}"
                :callback="e => setPoInfo({cusCd: e.bsnNo})"
                :required="poIsNew"
                :errorMessage="{title: '계약자', message: '필수입력입니다.'}"
              />
            </i-col>
            <i-spacer />
            <i-col-header required>계약구분</i-col-header>
            <i-col>
              <iui-select
                width="90px"
                :p-code="$getConstants('PO_TYPE_CD').code"
                :excludeItems="[$getConstants('PO_TYPE_CD_ORDER').code, $getConstants('PO_TYPE_CD_SCPAY').code]"
                :value="poInfo.poTypecd"
                @change="value => onChange_poTypecd(value)"
              />
            </i-col>
          </template>
          <template v-else>
            <i-col-header required>현장</i-col-header>
            <i-col>
              <iui-text :value="poInfo.spotDeptcd" :enable="false" width="100px" />
              <iui-text :value="poInfo.spotDeptnm" :enable="false" />
            </i-col>
            <i-spacer />
            <i-col-header required>계약자</i-col-header>
            <i-col>
              <iui-text :value="poInfo.cusCd" :enable="false" width="80px" />
              <iui-text :value="poInfo.cusNm" :enable="false" />
            </i-col>
            <i-spacer />
            <i-col-header required>계약구분</i-col-header>
            <i-col>
              <iui-text :value="poInfo.poTypenm" :enable="false" width="80px" />
            </i-col>
          </template>
        </i-row>
        <i-row>
          <i-col-header required>계약번호</i-col-header>
          <i-col>
            <iui-text :value="poInfo.poNo2" :enable="false" width="100px" />
            <iui-text
              ref="ttl"
              :key="`ttlKey_${ttlKey}`"
              :value="poInfo.ttl"
              @input="onInput_ttl"
              :enable="poStatus.isEnable && !!poInfo.spotDeptcd && poStatus.isFirstContSeq"
              max-length="500"
              :required="poStatus.isEnable"
              :errorMessage="{title: '계약명', message: '필수입력입니다.'}"
              :placeholder="poIsNew && !poInfo.spotDeptcd ? '현장을 선택해주세요' : ''"
            />
          </i-col>
          <i-spacer />
          <i-col-header required>납품장소</i-col-header>
          <i-col colspan="4">
            <iui-text
              :value="poInfo.dlvLoc"
              @change="setPoInfo({dlvLoc: $event.target.value})"
              max-length="100"
              :enable="poStatus.isEnable"
              :required="poStatus.isEnable"
              :errorMessage="{title: '납품장소', message: '필수입력입니다.'}"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>계약내역</i-col-header>
          <i-col :rowspan="poItemListRowspan" colspan="7">
            <PoItemList />
          </i-col>
        </i-row>
        <i-row v-for="i in poItemListRowspan - 1" :key="i">
          <i-col />
        </i-row>
        <i-row>
          <i-col-header required>계약일자</i-col-header>
          <i-col>
            <iui-datepicker
              :value="poInfo.poDt"
              :enable="poStatus.isEnable"
              :required="poStatus.isEnable"
              :errorMessage="{title: '계약일자', message: '필수입력입니다.'}"
              @change="setPoInfo({poDt: $event})"
            />
          </i-col>
        </i-row>
      </iui-container-new>

      <!-- 당초계약 -->
      <PoFirstContSeq v-if="!poStatus.isChangeContSeq" />
      <!-- 변경계약 -->
      <PoChangeContSeq v-else />

      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header>계약이행</i-col-header>
          <i-col>
            <iui-text
              type="rate"
              prefix="보증율 계약금액의"
              suffix="%"
              width="185px"
              :value="poInfo.prfmInsrRate"
              :enable="poStatus.isEnable"
              @change="onChange_insrRate('prfmInsr', $event.target.value)"
            />
            <iui-text type="amount" prefix="보증금" width="160px" :value="poInfo.prfmInsrAmt" :enable="false" />
            <pmis-file-list
              id="prfmInsrFlNo"
              flNoCol="prfmInsrFlNo"
              title="첨부파일"
              title-visible
              :modal="true"
              :toolbar-visible="true"
              :modalBtnEnable="true"
              :company-code="poInfo.cusPgmCd"
              :fileNumber="poInfo.prfmInsrFlNo"
              :allow-add="false"
              :hidden="
                !(
                  poStatus.isPoSend ||
                  poStatus.isPoSignComplete ||
                  poStatus.isAfterPoSignComplete ||
                  poStatus.isPoComplete
                )
              "
            />
          </i-col>
          <i-spacer />
          <i-col-header>지체상금율</i-col-header>
          <i-col>
            <iui-checkbox-group
              :checkedValues="[poInfo.delayRateYn]"
              :items="[{label: $getConstants('EXTERNAL_Y').name, value: $getConstants('EXTERNAL_Y').code}]"
              @change="onChange_delayRateYn"
              :enable="poStatus.isEnable"
            />
            <iui-text
              v-if="poStatus.isDelayRate"
              type="rate"
              suffix="%"
              width="80px"
              :value="poInfo.delayRate"
              :enable="poStatus.isEnable && poStatus.isDelayRate"
              @change="setPoInfo({delayRate: $event.target.value})"
            />
            <pmis-file-list
              id="delayFlNo"
              flNoCol="delayFlNo"
              title="첨부파일"
              title-visible
              :modal="true"
              :toolbar-visible="true"
              :modalBtnEnable="true"
              :fileNumber="poInfo.delayFlNo"
              :company-code="poInfo.cusPgmCd"
              :allow-add="false"
              :hidden="
                !(
                  poStatus.isPoSend ||
                  poStatus.isPoSignComplete ||
                  poStatus.isAfterPoSignComplete ||
                  poStatus.isPoComplete
                )
              "
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>하자보수</i-col-header>
          <i-col colspan="4">
            <iui-text
              type="rate"
              prefix="보증율 계약금액의"
              suffix="%"
              width="185px"
              :value="poInfo.flawInsrRate"
              :enable="poStatus.isEnable"
              @change="onChange_insrRate('flawInsr', $event.target.value)"
            />
            <iui-text type="amount" prefix="보증금" width="160px" :value="poInfo.flawInsrAmt" :enable="false" />
            <label class="ml10 mr10">책임기간</label>
            <iui-radio-group
              :items="flawInsrItems1"
              :value="poInfo.flawInsrYn"
              :enable="poStatus.isEnable"
              @change="setPoInfo({flawInsrYn: $event.target.value, flawInsrFrDt: '', flawInsrToDt: ''})"
            />
            <iui-text
              v-if="poStatus.isFlawInsrYear"
              type="number"
              width="40px"
              :value="poInfo.flawInsrYear"
              @change="setPoInfo({flawInsrYear: $event.target.value})"
              max-length="3"
              :enable="poStatus.isEnable && poStatus.isFlawInsrYear"
              :errorMessage="{title: '책임기간(년)', message: '필수입력입니다.'}"
            />
            <iui-radio-group
              class="ml20"
              :items="flawInsrItems2"
              :value="poInfo.flawInsrYn"
              :enable="poStatus.isEnable"
              @change="setPoInfo({flawInsrYn: $event.target.value, flawInsrYear: ''})"
            />
            <template v-if="poStatus.isFlawInsrMonth">
              <iui-datepicker
                :value="poInfo.flawInsrFrDt"
                @change="setPoInfo({flawInsrFrDt: $event})"
                :group="{grpNm: 'flawInsrDt', seq: 1}"
                :enable="poStatus.isEnable && !poStatus.isFlawInsrYear"
                :errorMessage="{title: '책임기간(월)', message: '필수입력입니다.'}"
              />
              <label>~</label>
              <iui-datepicker
                :value="poInfo.flawInsrToDt"
                @change="setPoInfo({flawInsrToDt: $event})"
                :group="{grpNm: 'flawInsrDt', seq: 2}"
                :disabledDate="disabledFlawInsrToDt"
                :enable="poStatus.isEnable && !poStatus.isFlawInsrYear"
                :errorMessage="{title: '책임기간(월)', message: '필수입력입니다.'}"
              />
            </template>
            <pmis-file-list
              id="scpayFlNo"
              flNoCol="scpayFlNo"
              title="첨부파일"
              title-visible
              :modal="true"
              :allow-add="false"
              :hidden="
                !(
                  poStatus.isPoSend ||
                  poStatus.isPoSignComplete ||
                  poStatus.isAfterPoSignComplete ||
                  poStatus.isPoComplete
                )
              "
              :toolbar-visible="true"
              :modal-btn-enable="true"
              :file-number="poInfo.scpayFlNo"
              :company-code="poInfo.cusPgmCd"
            />
          </i-col>
        </i-row>
      </iui-container-new>

      <iui-container-new type="css-flex" height="200px">
        <i-spacer />
        <i-row>
          <i-col :width="poStatus.isPoSignComplete ? '60%' : '50%'">
            <!-- 제출서류목록 -->
            <PoSubmitDocList />
          </i-col>
          <i-spacer />
          <i-col>
            <pmis-file-list
              id="poContractFile"
              title="전자서명 파일"
              title-visible
              :toolbar-visible="poStatus.isEnable"
              :company-code="poInfo.orgCd"
              :project-code="poInfo.spotDeptcd"
              :fbsNo="FBS_NO"
              :fileNumber="poInfo.flNo"
              :searchData="{flDsOrderList: [$getConstants('FL_DS_CONTRACT_PDF').code]}"
              @delete-complete="onDeleteCompleteFile"
            />
          </i-col>
        </i-row>
        <i-spacer v-if="poStatus.isExistRfq" />
      </iui-container-new>

      <pmis-content-box v-if="poStatus.isExistRfq" style="height: 100px;">
        <template #title>입찰정보</template>
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col-header>입찰공고번호</i-col-header>
            <i-col width="30%">
              <iui-text
                width="120px"
                :value="rfqInfo.rfqNo2"
                @click="showPopup_RfqInfo"
                :css="clickable_inputStyle"
                readOnly
              />
              <iui-text :value="rfqInfo.ttl" :enable="false" />
            </i-col>
            <i-spacer />
            <i-col-header>입찰차수</i-col-header>
            <i-col>
              <iui-text
                align="center"
                width="80px"
                :value="rfqInfo.bidRnd ? rfqInfo.bidRnd + '차' : ''"
                :enable="false"
              />
            </i-col>
            <i-spacer />
            <i-col-header>공고품의서</i-col-header>
            <i-col>
              <iui-text
                width="80px"
                :value="rfqInfo.rfqSncNo"
                @click="openCompletedRfqApproval"
                :css="clickable_inputStyle"
                readOnly
              />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>낙찰일자</i-col-header>
            <i-col>
              <iui-text align="center" width="100px" :value="rfqInfo.endDt" :enable="false" />
            </i-col>
            <i-spacer />
            <i-col-header>낙찰금액</i-col-header>
            <i-col>
              <iui-text type="amount" width="150px" :value="rfqInfo.lastTotAmt" :enable="false" />
              <label class="font8pt">(부가세포함)</label>
            </i-col>
          </i-row>
        </iui-container-new>
      </pmis-content-box>

      <iui-modal name="rfqInfoModal" title="입찰정보" size-type="size6">
        <RfqInfo :rfqNo="rfqInfo.rfqNo" />
      </iui-modal>
      <ApprovalPop :parentId="_uid" />
    </pmis-content-box>
  </form>
</template>

<script>
import PoInfoMixin from '@/view/bidding/po/common/mixins/poInfoMixin.js';
import PoInputMixin from '@/view/bidding/js/poInputMixin.js';
import EDMS_CONSTANTS from '@/view/edms/common/EdmsConstatns.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';

import PoItemList from './PoItemList.vue';
import PoFirstContSeq from './PoFirstContSeq.vue';
import PoChangeContSeq from './PoChangeContSeq.vue';
import RfqInfo from './RfqInfo.vue';

export default {
  components: {PoItemList, PoFirstContSeq, PoChangeContSeq, RfqInfo},
  mixins: [PoInfoMixin, PoInputMixin],
  data() {
    return {
      FBS_NO: EDMS_CONSTANTS.MAT_PO.FBS_NO,
      poItemListRowspan: 6,
      file: {
        fileNumber: 0,
        search: false,
      },
    };
  },
  methods: {
    disabledFlawInsrToDt(date) {
      return dateFormat(this.poInfo.flawInsrFrDt).replace(/-/gi, '') > $_dateToStringYYMMDD(date);
    },
    onChange_delayRateYn(e) {
      this.setPoInfo({
        delayRateYn: $_externalCheckboxValue(e.target.checked),
        delayRate: '',
      });
    },
    onChange_poTypecd(value) {
      const dlvReqDt = '';
      const dlvFrDt = '';
      const dlvToDt = '';
      let dlvYn;

      if (value === BIDDING_CONSTANTS.CODES.POTYPE_NORMAL) {
        dlvYn = BIDDING_CONSTANTS.CODES.DLV_DATE;
      } else {
        dlvYn = BIDDING_CONSTANTS.CODES.DLV_PERIOD;
      }

      this.setPoInfo({poTypecd: value, dlvYn, dlvReqDt, dlvFrDt, dlvToDt});
    },
    onValidateSave(poItemList = []) {
      // 계약내역
      if (!poItemList.length) {
        this.$alert({title: '계약내역', message: '계약내역이 존재하지 않습니다.'}, () => {
          this.callEvent({name: 'PoItemList_OpenTargetMatModal'});
        });
        return false;
      }
      if (poItemList.some(item => !Number(item.amt))) {
        this.$alert({title: '계약내역', message: '계약내역 금액이 입력되지 않았습니다.'});
        this.callEvent({name: 'PoItemList_MoveTopInValidRow'});
        return false;
      }

      // 대금지급방법
      if (this.poStatus.isPayCash) {
        const r1 = parseFloat(this.poInfo.payCashRatio);
        const r2 = parseFloat(this.poInfo.payCheckRatio);
        const r3 = parseFloat(this.poInfo.payCheck2Ratio);
        const ratio = r1 + r2 + r3;
        if (ratio != 100) {
          this.$alert({
            title: '대금지급방법',
            message: '대금지급방법(현금, 어음, 어음대체수단)의 비율의 합은 100% 이어야 합니다.',
          });
          return false;
        }
      }

      // 담보기간
      if (this.poStatus.isFlawInsrMonth) {
        if (this.poInfo.flawInsrFrDt > this.poInfo.flawInsrToDt) {
          this.$alert({title: '책임기간', message: '책임기간을 다시 한 번 확인하시기 바랍니다.'});
          return false;
        }
      }

      return true;
    },
  },
};
</script>

<style></style>
