<template>
  <pmis-tab-box>
    <template #title v-if="expected === '000001'">기성계획</template>
    <template #title v-else>기성실적</template>
    <template #header-left v-if="expected === '000001'">
      <label>계획년월</label>
      <div class="ml5"></div>
      <iui-datepicker name="reqYm" type="month" :value="reqYm" @change="reqYm = $event" />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <template #header-left v-else>
      <label>실적기간</label>
      <iui-datepicker :value="reqStrDate" @change="onChangeDate('reqStrDate', $event)" />
      <span>~</span>
      <iui-datepicker :value="reqEndDate" @change="onChangeDate('reqEndDate', $event)" />
      <iui-button value="검색" @click="onSearch" />
    </template>

    <ib-sheet :uid="_uid" :options="sheetOpt" :loadSearchData="loadSearchData" @loadSheet="sheet = $event" />
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/PxcondTargetListSheet.js';
import {selectPxcondDetailDtlsList} from '@/view/pxcondRqestManage/pxcondDtlsRegist/api/pxcondDtlsRegist.js';

export default {
  props: {
    reqStrDate: {
      Type: String,
      default: '',
    },
    reqEndDate: {
      Type: String,
      default: '',
    },
    expected: {
      Type: String,
      default: '',
    },
  },
  data() {
    return {
      sheetOpt: $addColCheckbox(sheetOpt(this)),
      loadSearchData: [],
      reqYm: '',
    };
  },
  created() {
    this.reqYm = this.reqStrDate.slice(0, 6);
    this.onSearch();
  },
  methods: {
    onSearch() {
      selectPxcondDetailDtlsList({
        reqStrDate: this.reqStrDate,
        reqEndDate: this.reqEndDate,
        expected: this.expected,
        reqYm: this.reqYm,
      }).then(response => {
        this.loadSearchData = response.data;
      });
    },
    onChangeDate(type, value) {
      // this[type] = value;
      this.$emit(type, value);

      setTimeout(() => this.onSearch(), 300);
    },
  },
};
</script>

<style></style>
