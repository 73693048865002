export default that => {
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      CanEdit: 0, // 1: 전체 편집 가능 (default)
    },
    Cols: [
      {
        Header: '자재코드',
        Name: 'matCode',
        Type: 'Text',
        Width: 140,
        Align: 'center',
      },
      {
        Header: '자재명',
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 140,
      },
      {
        Header: '규격',
        Name: 'size',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 180,
      },
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Width: 60,
        Align: 'center',
        Format: $getConstants('UNIT').code,
      },
      {
        Header: '입고수량',
        Name: 'curInputQty',
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 110,
      },
      {
        Header: '입고금액',
        Name: 'curInputAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 110,
      },
      {
        Header: '출고수량',
        Name: 'curOutputQty',
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 110,
      },
      {
        Header: '출고금액',
        Name: 'curOutputAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 110,
      },
      {
        Header: '재고수량',
        Name: 'curRemQty',
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 110,
      },
      {
        Header: '재고금액',
        Name: 'curRemAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 110,
      },
    ],
  };
};
