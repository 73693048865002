import Vue from 'vue';
import Router from 'vue-router';
import MainHome from '@/view/home/MainHome.vue';
import SearchIdPw from '@/view/login/SearchIdPw.vue';
import LoginHome from '@/LoginHome.vue';
import Join from '@/view/member/join/index.vue';

Vue.use(Router);
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {path: '/Home', component: MainHome},
    {path: '/searchIdPw', component: SearchIdPw, name: 'searchIdPw'},
    {path: '/loginHome', component: LoginHome},
    {path: '/join', component: Join},
  ],
});

export default router;
