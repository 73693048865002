<template>
  <pmis-content-box>
    <Search slot="header-custom" @search="onSearch" />
    <ib-sheet
      :uid="_uid"
      :options="options"
      :loadSearchData="equtAnmonList"
      @loadSheet="loadSheet"
      :events="{
        onSearchFinish: onSearchFinish,
        onClick: onClick,
      }"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/EqutAnmonMListMSheet.js';
import Search from './EqutAnmonMListSubSearch.vue';
export default {
  components: {
    Search,
  },
  props: {
    registerData: {
      type: Array,
    },
    delete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sheet: undefined,
      options: options(this),
    };
  },
  watch: {
    delete(val) {
      if (val) {
        this.deleteEqutAnmon();
      }
    },
  },
  beforeCreate() {
    $mapGetters(this, 'equipment', ['equtAnmonList', 'anyTimeEqutSaveDataReqDto']);
    $mapMutations(this, 'equipment', ['setDetailInfo', 'setSearchInfo', 'initDetailInfo', 'initCalculateList']);
    $mapActions(this, 'equipment', ['selectRegisterEqutDetail', 'selectEqutAnmonList', 'deleteEqutAnmon']);
  },
  activated() {
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },

  methods: {
    async onSave() {
      if (!this.anyTimeEqutSaveDataReqDto.amt) {
        return;
      }

      try {
        const response = await this.$save({
          url: '/closeAnyTimeEqut/saveEqutAnmon',
          param: this.anyTimeEqutSaveDataReqDto,
        });
        if (response.data > 0) {
          this.cud = '0';
          this.onAfterSave(this.anyTimeEqutSaveDataReqDto);
        }
      } catch (error) {
        console.error(error);
      }
    },
    onAfterSave(data) {
      const row = this.sheet.getFocusedRow();
      for (let key in data) {
        this.sheet.setValue(row, key, data[key]);
      }

      this.callEvent({name: 'anyTimeEqut_onSearchData'});
    },
    async onDelete() {
      if (!(await this.$confirm({title: '장비비삭제', message: '삭제하시겠습니까?'}))) {
        return;
      }
      await this.deleteEqutAnmon();
      this.callEvent({name: 'anyTimeEqut_onSearchData'});
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    async onSearch() {
      this.selectEqutAnmonList();
    },
    onSearchFinish(e) {
      this.selectCalculateList(e.sheet.getFirstRow());
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.selectCalculateList(e.row);
      }
    },
    async selectCalculateList(row) {
      this.initDetailInfo();
      this.initCalculateList();
      if (!row) {
        return;
      }
      this.cud = row.cud;
      this.setDetailInfo(row);
      await this.selectRegisterEqutDetail();
    },
  },
};
</script>

<style scoped></style>
