export const selectUcstPriMList = param => axios.post('/untpcRegist/selectUcstPriMList', param);
export const saveUcstPri = param => axios.post('/untpcRegist/saveUcstPri', param);
export const deleteUcstPriAsChildren = param => axios.post('/untpcRegist/deleteUcstPriAsChildren', param);
export const updateUcstPriceCopy = param => axios.post('/untpcRegist/updateUcstPriceCopy', param);
export const selectUcstCodeMList = param => axios.post('/untpcRegist/selectUcstCodeMList', param);
export const saveUcstCode = param => axios.post('/untpcRegist/saveUcstCode', param);
export const deleteUcstPri = param => axios.post('/untpcRegist/deleteUcstPri', param);
export const selectStandardUntpc = () => axios.post('/untpcRegist/selectStandardUntpc');
export const saveStandardUntpc = param => axios.post('/untpcRegist/saveStandardUntpc', param);
export const selectMatUntpc = param => axios.post('/untpcRegist/selectMatUntpc', param);
