export default that => {
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      CanEdit: 0, // 1: 전체 편집 가능 (default)
    },
    Cols: [
      {
        Header: '현장명',
        Name: 'prjNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
      },
      {
        Header: '투입일자',
        Name: 'rlseDate',
        Extend: that.$ibsheetHelper.preset.date,
      },
      {
        Header: '철수(예정)일자',
        Name: 'outDate',
        Extend: that.$ibsheetHelper.preset.date,
      },
    ],
  };
};
