/** 로그인한 회사(협력사)를 입찰지명한 회사리스트 */
export const selectRfqCompanyList = process => axios.post('/rfqCustomer/selectRfqCompanyList', {process});
/** 공고목록조회 */
export const selectRfqList = searchOptions => axios.post('/rfqCustomer/selectRfqList', searchOptions);
/** 입찰정보 조회 */
export const selectRfqInfos = (rfqNo, bidRnd, cusCd, cusPgmCd) =>
  axios.post('/rfqCustomer/selectRfqInfos', {rfqNo, bidRnd, cusCd, cusPgmCd});
/** 현장설명 참가신청 */
export const applySpotDscr = (rfqNo, lastBidRnd, cusCd, isApply) =>
  axios.post('/rfqCustomer/applySpotDscr', {rfqNo, lastBidRnd, cusCd, isApply});
/** 견적서 제출 전 상태체크 */
export const selectCheckStateBeforeQuotSubmit = rfqNo =>
  axios.post('/rfqCustomer/selectCheckStateBeforeQuotSubmit', {rfqNo});
/** 견적서 제출 */
export const submitQuot = param => $save('/rfqCustomer/submitQuot', param);
/** 입찰결과 조회 */
export const selectRfqResultInfosCustomer = rfqNo => axios.post('/rfqCustomer/selectRfqResultInfos', {rfqNo});
