var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pmis-tab-box',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("노무팀목록")]},proxy:true}])},[_c('pmis-content-box',{scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_c('iui-text',{attrs:{"type":"search","label":"노무팀명","name":"searchLbrcTeam","value":_vm.searchLbrcTeam},on:{"update:value":function($event){_vm.searchLbrcTeam=$event},"enter":_vm.onSearch}}),_c('iui-button',{attrs:{"value":"검색"},on:{"click":_vm.onSearch}}),_c('iui-checkbox-group',{attrs:{"name":"inclsDelTeam","items":_vm.checkboxItems},on:{"change":function (event) {
            _vm.inclsDelTeam = event.target.checked ? 'Y' : 'N';
            _vm.onSearch();
          }}})]},proxy:true}])},[_c('ib-sheet',{attrs:{"uid":_vm._uid,"options":_vm.Options,"loadSearchData":_vm.loadSearchData,"events":{
        onRowLoad: _vm.onRowLoad,
        onSearchFinish: _vm.onSearchFinish,
        onClick: _vm.onClick,
        onDblClick: _vm.onDblClick,
      }},on:{"loadSheet":_vm.loadSheet}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }