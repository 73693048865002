export default that => {
  const formatFunc = v => (v.includes('%') ? v : $_numberFormat(v, true));
  return {
    Cfg: {CanEdit: 0, HeaderMerge: 6, DataMerge: 1},
    Def: {
      Row: {
        CanFormula: 1,
      },
    },
    LeftCols: [
      {
        Header: ['구분'],
        Type: 'Text',
        Name: 'gb1',
        Align: 'Center',
        RelWidth: 1,
      },
      {
        Header: ['구분'],
        Type: 'Text',
        Name: 'gb2',
        Align: 'Center',
        RelWidth: 1,
      },
    ],
    Cols: [
      {
        Header: ['전년까지'],
        Type: 'Text',
        Name: 'beforeYear',
        Extend: that.$ibsheetHelper.preset.amount,
        ColMerge: false,
        RelWidth: 1,
        CustomFormat: v => formatFunc(v),
      },
      {
        Header: ['1월'],
        Type: 'Text',
        Name: 'jan',
        Extend: that.$ibsheetHelper.preset.amount,
        ColMerge: false,
        RelWidth: 1,
        CustomFormat: v => formatFunc(v),
      },
      {
        Header: ['2월'],
        Type: 'Text',
        Name: 'feb',
        Extend: that.$ibsheetHelper.preset.amount,
        ColMerge: false,
        RelWidth: 1,
        CustomFormat: v => formatFunc(v),
        Formula: '',
      },
      {
        Header: ['3월'],
        Type: 'Text',
        Name: 'mar',
        Extend: that.$ibsheetHelper.preset.amount,
        ColMerge: false,
        RelWidth: 1,
        CustomFormat: v => formatFunc(v),
      },
      {
        Header: ['4월'],
        Type: 'Text',
        Name: 'apr',
        Extend: that.$ibsheetHelper.preset.amount,
        ColMerge: false,
        RelWidth: 1,
        CustomFormat: v => formatFunc(v),
      },
      {
        Header: ['5월'],
        Type: 'Text',
        Name: 'may',
        Extend: that.$ibsheetHelper.preset.amount,
        ColMerge: false,
        RelWidth: 1,
        CustomFormat: v => formatFunc(v),
      },
      {
        Header: ['6월'],
        Type: 'Text',
        Name: 'jun',
        Extend: that.$ibsheetHelper.preset.amount,
        ColMerge: false,
        RelWidth: 1,
        CustomFormat: v => formatFunc(v),
      },
      {
        Header: ['7월'],
        Type: 'Text',
        Name: 'jul',
        Extend: that.$ibsheetHelper.preset.amount,
        ColMerge: false,
        RelWidth: 1,
        CustomFormat: v => formatFunc(v),
      },
      {
        Header: ['8월'],
        Type: 'Text',
        Name: 'aug',
        Extend: that.$ibsheetHelper.preset.amount,
        ColMerge: false,
        RelWidth: 1,
        CustomFormat: v => formatFunc(v),
      },
      {
        Header: ['9월'],
        Type: 'Text',
        Name: 'sep',
        Extend: that.$ibsheetHelper.preset.amount,
        ColMerge: false,
        RelWidth: 1,
        CustomFormat: v => formatFunc(v),
      },
      {
        Header: ['10월'],
        Type: 'Text',
        Name: 'oct',
        Extend: that.$ibsheetHelper.preset.amount,
        ColMerge: false,
        RelWidth: 1,
        CustomFormat: v => formatFunc(v),
      },
      {
        Header: ['11월'],
        Type: 'Text',
        Name: 'nov',
        Extend: that.$ibsheetHelper.preset.amount,
        ColMerge: false,
        RelWidth: 1,
        CustomFormat: v => formatFunc(v),
      },
      {
        Header: ['12월'],
        Type: 'Text',
        Name: 'dec',
        Extend: that.$ibsheetHelper.preset.amount,
        ColMerge: false,
        RelWidth: 1,
        CustomFormat: v => formatFunc(v),
      },
      {
        Header: ['소계'],
        Type: 'Text',
        Name: 'subTotal',
        Extend: that.$ibsheetHelper.preset.amount,
        ColMerge: false,
        RelWidth: 1,
        CustomFormat: v => formatFunc(v),
      },
      {
        Header: ['누계'],
        Type: 'Text',
        Name: 'totRate',
        Extend: that.$ibsheetHelper.preset.amount,
        ColMerge: false,
        RelWidth: 1,
        CustomFormat: v => formatFunc(v),
      },
    ],
  };
};
