<!-- 외주 입찰공고 포틀릿 (협력사) -->
<template>
  <iui-portlet-container>
    <template #title>입찰공고</template>
    <template #link>
      <a href="#" class="goto_top_link" @click="goShortCut()">
        <span class="blind">바로가기</span>
      </a>
    </template>

    <div>
      <table class="tbl_list">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col width="*" />
        </colgroup>
        <thead>
          <tr height="30px">
            <th>건설사명</th>
            <th>현장명</th>
            <th>입찰제목</th>
            <th>공고일</th>
            <th>마감일</th>
            <th>입찰담당</th>
          </tr>
        </thead>
      </table>
      <div class="inner" style="height:200px">
        <table class="tbl_list bdt_n">
          <colgroup>
            <col :style="`width:calc(${tblWidth}px/${cnt})`" />
            <col :style="`width:calc(${tblWidth}px/${cnt})`" />
            <col :style="`width:calc(${tblWidth}px/${cnt})`" />
            <col :style="`width:calc(${tblWidth}px/${cnt})`" />
            <col :style="`width:calc(${tblWidth}px/${cnt})`" />
            <col width="*" />
          </colgroup>
          <tbody>
            <tr v-for="(subRfq, index) in subRfqList" :key="index">
              <td style="text-align:left;">{{ subRfq.orgNm }}</td>
              <td style="text-align:left;">{{ subRfq.spotDeptnm }}</td>
              <td style="text-align:left;">
                <a @click="goShortCut(subRfq)">
                  {{ subRfq.ttl }}
                </a>
              </td>
              <td>{{ subRfq.bidStartDt }}</td>
              <td>{{ subRfq.bidExpireDt }}</td>
              <td class="p_last">{{ subRfq.chrgUsrnm }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </iui-portlet-container>
</template>

<script>
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
import {selectSubRfqListCustomer} from '@/components/portlet/api/portletData.js';
export default {
  mixins: [PortletMixin],
  data() {
    return {
      cnt: 6,
      subRfqList: [],
    };
  },
  methods: {
    setWidth() {
      this.tblWidth = this.$el.offsetWidth - 28;
    },
    onSearch() {
      selectSubRfqListCustomer().then(response => (this.subRfqList = response.data));
    },
    goShortCut(subRfq) {
      this.goMenu('RFQ_SUB_CUSTOMER', subRfq);
    },
  },
};
</script>

<style></style>
