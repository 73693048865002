<template>
  <pmis-content-box>
    <iui-container-new type="css-flex">
      <i-row height="200px">
        <i-col>
          <iui-container-new type="table" theme="bullet" width="470px">
            <i-row>
              <i-col-header :required="detailInfo.lvl !== 1">상위메뉴</i-col-header>
              <i-col>
                <iui-text
                  name="uppMnuCd"
                  :value="detailInfo.uppMnuCd"
                  @change="setDetailInfo({uppMnuCd: $event.target.value})"
                  max-length="50"
                  type="code"
                  :required="detailInfo.lvl !== 1"
                  :enable="detailInfo.lvl !== 1 && isBase"
                  v-if="!isCont"
                />
                <iui-text
                  name="uppMnuNm"
                  :value="detailInfo.uppMnuNm"
                  @change="setDetailInfo({uppMnuNm: $event.target.value})"
                  max-length="100"
                  :enable="detailInfo.lvl !== 1 && isBase"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header required>메뉴</i-col-header>
              <i-col>
                <iui-text
                  name="mnuCd"
                  :value="detailInfo.mnuCd"
                  @change="setDetailInfo({mnuCd: $event.target.value})"
                  max-length="50"
                  type="code"
                  required
                  v-if="!isCont"
                  :enable="isBase"
                />
                <iui-text
                  name="mnuNm"
                  :value="detailInfo.mnuNm"
                  @change="setDetailInfo({mnuNm: $event.target.value})"
                  max-length="50"
                  required
                  :enable="isBase || isCont"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>영문메뉴명</i-col-header>
              <i-col>
                <iui-text
                  name="mnuNmEng"
                  :value="detailInfo.mnuNmEng"
                  @change="setDetailInfo({mnuNmEng: $event.target.value})"
                  max-length="50"
                  :enable="isBase || isCont"
                />
              </i-col>
            </i-row>
            <i-row v-if="detailInfo.lvl === 4 && !isCont">
              <i-col-header required>메뉴경로</i-col-header>
              <i-col>
                <iui-text
                  name="mnuPth"
                  id="mnuPth"
                  v-if="detailInfo.lvl === 4"
                  :value="detailInfo.mnuPth"
                  @change="setDetailInfo({mnuPth: $event.target.value})"
                  max-length="100"
                  required
                  :enable="isBase"
                />
              </i-col>
            </i-row>
            <i-row v-if="detailInfo.lvl === 4 && !isCont">
              <i-col-header>파라미터</i-col-header>
              <i-col>
                <iui-text
                  name="para"
                  :value="detailInfo.para"
                  @change="setDetailInfo({para: $event.target.value})"
                  max-length="100"
                  :enable="isBase"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header required>정렬순서</i-col-header>
              <i-col>
                <iui-text
                  name="mnuSqn"
                  :value="detailInfo.mnuSqn"
                  @change="setDetailInfo({mnuSqn: $event.target.value})"
                  max-length="18"
                  required
                  :enable="isBase"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header required>사용여부</i-col-header>
              <i-col>
                <iui-checkbox-group
                  name="vwYn"
                  :items="checkboxItem1"
                  :enable="
                    (isCont &&
                      this.detailInfo.mnuCd !== $getConstants('SYSTEM_MANAGEMENT').code &&
                      this.detailInfo.mnuCd !== $getConstants('OPERATION_MANAGEMENT').code &&
                      this.detailInfo.mnuCd !== $getConstants('MENU_SETTING').code) ||
                      isBase
                  "
                  :checkedValues="[detailInfo.vwYn]"
                  @change="
                    setDetailInfo({vwYn: $event.target.checked ? $event.target.value : $getConstants('USE_YN_N').code})
                  "
                />
                <iui-checkbox-group
                  v-if="!isCont"
                  name="svYn"
                  :enable="isBase"
                  :items="checkboxItem2"
                  :checkedValues="[detailInfo.svYn]"
                  @change="
                    setDetailInfo({svYn: $event.target.checked ? $event.target.value : $getConstants('USE_YN_N').code})
                  "
                />
                <iui-checkbox-group
                  v-if="!isCont"
                  name="delYn"
                  :enable="isBase"
                  :items="checkboxItem3"
                  :checkedValues="[detailInfo.delYn]"
                  @change="
                    setDetailInfo({delYn: $event.target.checked ? $event.target.value : $getConstants('USE_YN_N').code})
                  "
                />
                <iui-checkbox-group
                  v-if="!isCont"
                  name="prnYn"
                  :enable="isBase"
                  :items="checkboxItem4"
                  :checkedValues="[detailInfo.prnYn]"
                  @change="
                    setDetailInfo({prnYn: $event.target.checked ? $event.target.value : $getConstants('USE_YN_N').code})
                  "
                />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
      <i-row height="400px" v-if="detailInfo.lvl === 4 && !isCont">
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              화면별 권한
            </template>
            <template v-slot:title-right>
              <iui-button value="추가" @click="onAddrow" v-if="isBase" />
              <iui-button value="삭제" @click="onDelRow" v-if="isBase" />
            </template>
            <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" @loadSheet="loadSheet" />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/progDetailInfoSheet.js';
import {selectMenuAuthList as BaseSelectMenuAuthList} from '../api/baseMenu.js';
import {selectMenuAuthList} from '../api/menu.js';

export default {
  props: {
    isBase: {
      type: Boolean,
      default: false,
    },
    isCont: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkboxItem1: [{label: '조회', value: $getConstants('USE_YN_Y').code}],
      checkboxItem2: [{label: '저장', value: $getConstants('USE_YN_Y').code}],
      checkboxItem3: [{label: '삭제', value: $getConstants('USE_YN_Y').code}],
      checkboxItem4: [{label: '출력', value: $getConstants('USE_YN_Y').code}],
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
    };
  },
  beforeCreate() {
    $mapGetters(this, ['detailInfo']);
    $mapMutations(this, ['setDetailInfo']);
  },
  created() {
    this.addEvent([
      {name: 'getAuthList', func: this.getAuthList},
      {name: 'selectMenuAuth', func: this.selectMenuAuth},
      {name: 'removeAll', func: this.removeAll},
    ]);
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onAddrow() {
      this.sheet.addRow({init: {frmGb: this.detailInfo.frmGb}});
    },
    onDelRow() {
      this.sheet.removeRow(this.sheet.getFocusedRow());
    },
    removeAll() {
      this.sheet?.removeAll();
    },
    getAuthList(callback) {
      let authList = [];
      if (this.isCont && typeof callback == 'function') {
        callback(authList);
        return;
      }
      this.sheet.getSaveJson(0).data.forEach(item => {
        authList.push({
          pgmCd: this.isBase ? undefined : this.pgmCd,
          frmGb: item.frmGb,
          mnuCd: this.detailInfo.mnuCd,
          mnuPth: item.mnuPth,
          vwYn: this.getAuthState(item.vwYn),
          newYn: this.getAuthState(item.newYn),
          svYn: this.getAuthState(item.svYn),
          delYn: this.getAuthState(item.delYn),
          prnYn: this.getAuthState(item.prnYn),
          STATUS: item.STATUS,
        });
      });
      if (typeof callback == 'function') callback($_statusToCud(authList));
    },
    getAuthState(auth) {
      if (auth.length == 1) {
        if (auth == '1') {
          return $getConstants('USE_YN_Y').code;
        } else {
          return $getConstants('USE_YN_N').code;
        }
      } else {
        if (auth == $getConstants('USE_YN_Y').code) {
          return '1';
        } else {
          return '0';
        }
      }
    },
    async selectMenuAuth() {
      let response;
      if (this.isBase) {
        const param = {frmGb: this.detailInfo.frmGb, mnuCd: this.detailInfo.mnuCd};
        response = await BaseSelectMenuAuthList(param);
      } else {
        const param = {mnuCd: this.detailInfo.mnuCd};
        response = await selectMenuAuthList(param);
      }

      response.data.forEach(item => {
        item.vwYn = this.getAuthState(item.vwYn);
        item.newYn = this.getAuthState(item.newYn);
        item.svYn = this.getAuthState(item.svYn);
        item.delYn = this.getAuthState(item.delYn);
        item.prnYn = this.getAuthState(item.prnYn);
      });
      this.loadSearchData = response.data;
    },
  },
};
</script>
