<template>
  <pmis-content-box>
    <template #header-left>
      <label>정산년월</label>
      <iui-datepicker type="month" :value.sync="searchInvestMm" @change="onSearch" :isIconClear="false" />
      <i class="prev-arrow" @click.prevent.self @click="prev" />
      <i class="post-arrow" @click.prevent.self @click="next" />

      <label class="ml30">거래처</label>
      <iui-select defaultCd="A" :items="customerList" :value.sync="searchCusCd" @change="onSearch" />
      <iui-button value="검색" @click="onSearch" />

      <label class="ml30 mr15">구분</label>
      <iui-radio-group defaultCd="A" :items="radioItems" :value.sync="searchState" @change="onSearch" />
    </template>

    <template #header-right>
      <iui-button
        value="생성"
        @click="onCreate"
        v-if="visibleBtn && searchState === $getConstants('CUSTOM_BILL_SEARCH_ITEM1').code"
      />
      <iui-button
        value="생성취소"
        @click="onCancel"
        v-if="visibleBtn && searchState === $getConstants('CUSTOM_BILL_SEARCH_ITEM2').code"
      />
      <iui-button
        value="전자서명"
        @click="onSign"
        v-if="visibleBtn && searchState === $getConstants('CUSTOM_BILL_SEARCH_ITEM2').code"
      />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="loadSearchData"
      @loadSheet="sheet = $event"
      :events="{
        onRenderFirstFinish: sheet_onRenderFirstFinish,
        onSearchFinish: sheet_onSearchFinish,
        onClick: sheet_onClick,
      }"
    />

    <tax-invoice-pop :billNo="propsBillNo" :open.sync="propsOpen" pageGubun="sub" />

    <iui-modal name="createConfirmModal" title="세금계산서 생성" :btns="createConfirmbtn" width="470px" height="190px">
      <div class="createConfirm">
        <div class="inputContainer">
          <label class="mr10">작성일자</label>
          <iui-datepicker :value.sync="regDt" />
        </div>
        <p>선택한 항목들의 세금계산서 정보를 생성하시겠습니까?</p>
      </div>
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/customerBillList.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import {SECUKIT_SERVICE_TYPE} from '@inogard-dev/vue-secukitnx-helper';
import {selectCustomerList} from '@/view/customer/api/customer.js';
import {getServerKmCertPerm} from '@/view/bidding/rfq/common/api/pkiToolkit.js';
import {
  cancel,
  selectInvestMmList,
  selectSubBillTargetList,
  selectSubBillXmlList,
} from '@/view/subBidding/billManage/api/subBill.js';
import {createBill, signBill} from '@/view/bidding/billManage/api/ntsCommon.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';

import TaxInvoicePop from '@/view/bidding/components/TaxInvoicePop.vue';

export default {
  components: {TaxInvoicePop},
  data() {
    return {
      sheetOpt: $addColCheckbox(sheetOpt(this)),
      loadSearchData: [],

      propsBillNo: '',
      propsOpen: false,

      searchInvestMm: '',
      searchCusCd: '',
      searchState: '',

      investMmIndex: 0,
      investMmList: [],
      customerList: [],
      radioItems: [
        {
          label: $getConstants('CUSTOM_BILL_SEARCH_ITEM1').name,
          value: $getConstants('CUSTOM_BILL_SEARCH_ITEM1').code,
        },
        {
          label: $getConstants('CUSTOM_BILL_SEARCH_ITEM2').name,
          value: $getConstants('CUSTOM_BILL_SEARCH_ITEM2').code,
        },
        {
          label: $getConstants('CUSTOM_BILL_SEARCH_ITEM3').name,
          value: $getConstants('CUSTOM_BILL_SEARCH_ITEM3').code,
        },
        {
          label: $getConstants('CUSTOM_BILL_SEARCH_ITEM4').name,
          value: $getConstants('CUSTOM_BILL_SEARCH_ITEM4').code,
        },
      ],

      createConfirmbtn: [{name: '확인', callback: this.onCreateConfirm}],
      regDt: '',
    };
  },
  computed: {
    visibleBtn() {
      return this.loadSearchData.length;
    },
  },
  watch: {
    searchState(v) {
      this.sheet.setAttribute(null, 'chk', 'Visible', !!v, 1);
    },
  },
  created() {
    this.addFuncSearch(this.onInit);

    this.onSearchInvestMmList();
    this.onSearchCustomerList();
  },
  methods: {
    onInit() {
      this.sheet.removeAll();
      this.searchInvestMm = '';
      this.searchCusCd = '';
      this.searchState = '';

      this.onSearchInvestMmList();
    },
    onSearchInvestMmList() {
      selectInvestMmList().then(response => {
        if (response.data) {
          this.investMmList = response.data;
          if (this.investMmList.length) {
            this.investMmIndex = this.investMmList.length - 1;
            this.searchInvestMm = this.investMmList[this.investMmIndex];
            this.onSearch();
          }
        }
      });
    },
    onSearchCustomerList() {
      selectCustomerList({pgmCd: this.pgmCd, frmDs: $getConstants('FRM_DS_CONSTRUCTION').code}).then(response => {
        response.data.forEach(item => this.customerList.push({text: item.frmNm, value: item.cusPgmCd}));
      });
    },
    prev() {
      if (this.investMmList.length) {
        if (this.investMmIndex < 1) {
          return;
        }
        this.searchInvestMm = this.investMmList[--this.investMmIndex];
        this.onSearch();
      }
    },
    next() {
      if (this.investMmList.length) {
        if (this.investMmIndex >= this.investMmList.length - 1) {
          return;
        }
        this.searchInvestMm = this.investMmList[++this.investMmIndex];
        this.onSearch();
      }
    },
    onSearch() {
      this.loadSearchData = [];

      const param = {
        cusPgmCd: this.pgmCd,
        investMm: this.searchInvestMm,
        cusCd: this.searchCusCd,
        searchState: this.searchState,
      };
      selectSubBillTargetList(param).then(response => (this.loadSearchData = response.data));
    },
    onSetRow(row) {
      if (row) {
        this.callEvent({name: 'CustomerBillDetail_SetData', param: row});
      } else {
        this.callEvent({name: 'CustomerBillDetail_Init'});
      }
    },
    onCreate() {
      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (!checkedRows.length) {
        this.$alert({title: '세금계산서 생성', message: '생성할 항목을 선택해야 합니다.'});
        return;
      }

      this.regDt = $_getCurrentDate();
      this.$modal.show('createConfirmModal');
    },
    async onCreateConfirm() {
      this.$modal.hide('createConfirmModal');

      const checkedRows = this.sheet.getRowsByChecked('chk');
      const billList = checkedRows
        .filter(row => !row.billNo && !row.billSt)
        .map(row => {
          return {
            poNo: row.poNo,
            contSeq: row.contSeq,
            pgmCd: row.pgmCd,
            prjCd: row.prjCd,
            investMm: row.investMm,
            investKind: row.investKind,
            reqDegree: row.reqDegree,
            custCode: row.custCode,
            suppAmt: row.suppAmt,
            vatAmt: row.vatAmt,
            totAmt: row.totAmt,
            taxexe: row.taxexe,
            taxRate: row.taxRate,
            regDt: this.regDt,
          };
        });

      let param = {
        subBillList: billList,
        billType: BIDDING_CONSTANTS.BILL_TYPE.SUB,
      };
      createBill(param)
        .then(response => {
          if (response.status === 200) {
            this.onSearch();
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            const title = ex.title;
            const message = ex.description ? `${ex.message}<br/>- ${ex.description}` : ex.message;
            this.$alert({title, message});
          }
        });
    },
    async onCancel() {
      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (!checkedRows.length) {
        this.$alert({title: '세금계산서 생성취소', message: '생성취소할 항목을 선택해야 합니다.'});
        return;
      }

      const billList = checkedRows
        .filter(row => row.billNo && row.billSt == $getConstants('STATE_BILL_READY').code)
        .map(row => {
          return {
            pgmCd: row.pgmCd,
            prjCd: row.prjCd,
            investMm: row.investMm,
            investKind: row.investKind,
            custCode: row.custCode,
            poNo: row.poNo,
          };
        });

      const title = '세금계산서 생성취소';
      const message = '선택한 항목들의 세금계산서 정보를 생성취소 하시겠습니까?';

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      cancel(billList)
        .then(response => {
          if (response.status == 200) {
            this.onSearch();
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            const title = ex.title;
            const message = ex.description ? `${ex.message}<br/>- ${ex.description}` : ex.message;
            this.$alert({title, message});
          }
        });
    },
    async onSign() {
      const signList = this.sheet.getRowsByChecked('chk');
      if (0 === signList.length) {
        this.$alert({title: '세금계산서 전자서명', message: '전자서명할 항목을 선택해야 합니다.'});
        return;
      }

      if (signList.some(row => !$_bNowdateOverCheck(dateFormat(row.regDt).replace(/-/gi, '')))) {
        this.$alert({title: '세금계산서 전자서명', message: '작성일자 이후에 전자서명이 가능합니다.'});
        return;
      }

      const billTaxNos = [];
      signList.forEach(row => {
        if (row.chkRowSpan === 2) {
          billTaxNos.push(row.nextSibling.billNo);
        }
      });

      if (!(await COMMON_FUNCTION.checkCertdnRegistered(this, 'sub'))) {
        return;
      }

      const response1 = await getServerKmCertPerm();
      const svrKmCertPerm = response1.data.svrKmCertPerm;

      try {
        await this.$secuKit.checkReady();
        await this.$secuKit.showDialog(SECUKIT_SERVICE_TYPE.SECUTAX);

        // 전자서명할 xml 생성
        const billNos = Array.from(new Set(signList.map(row => row.billNo).concat(billTaxNos)));
        const response2 = await selectSubBillXmlList(billNos);
        const xmlList = response2.data;

        let signedXmlList = [];
        for (const item of xmlList) {
          const r1 = await this.$secuKit.signXMLDocumentForTax({
            originalText: item.origCntn,
            certID: this.$secuKit.certListInfo.getCertID(),
          });

          const billNo = item.billNo;
          const origCntn = item.origCntn;
          const signCntn = r1.signXMLDocumentForTax;
          const isBillTax = billTaxNos.includes(billNo);
          signedXmlList.push({billNo, origCntn, signCntn, isBillTax});
        }

        const r2 = await this.$secuKit.getKeyRandom({
          certString: removePEMHeader(removeCRLF(svrKmCertPerm)),
          certID: this.$secuKit.certListInfo.getCertID(),
        });
        const encRvalue = r2.getKeyRandom;

        let param = {
          signedXmlList: signedXmlList,
          encRvalue: encRvalue,
          billType: BIDDING_CONSTANTS.BILL_TYPE.SUB,
        };

        signBill(param)
          .then(response => {
            if (response.status == 200) {
              this.onSearch();
            }
          })
          .catch(ex => {
            if (ex.pmisError) {
              const title = ex.title;
              const message = ex.description ? `${ex.message}<br/>- ${ex.description}` : ex.message;
              this.$alert({title, message});
            }
          });
      } catch (e) {
        console.error(e);
      }
    },
    sheetMerge() {
      let key = '';
      let sRow;
      let eRow;
      let mergeInfo = {};
      this.sheet.getDataRows().forEach(row => {
        if (key !== row.pgmCd + row.prjCd + row.poNo + row.investMm + row.reqDegree) {
          key = row.pgmCd + row.prjCd + row.poNo + row.investMm + row.reqDegree;
          sRow = row.id;
          mergeInfo[sRow] = sRow;
        } else {
          eRow = row.id;
          mergeInfo[sRow] = eRow;
        }

        for (const m in mergeInfo) {
          this.sheet.setMergeRange(this.sheet.getRowById(m), 'chk', this.sheet.getRowById(mergeInfo[m]), 'chk');
          this.sheet.setMergeRange(this.sheet.getRowById(m), 'frmNm', this.sheet.getRowById(mergeInfo[m]), 'frmNm');
          this.sheet.setMergeRange(
            this.sheet.getRowById(m),
            'spotDeptnm',
            this.sheet.getRowById(mergeInfo[m]),
            'spotDeptnm'
          );
          this.sheet.setMergeRange(this.sheet.getRowById(m), 'poNo', this.sheet.getRowById(mergeInfo[m]), 'poNo');
          this.sheet.setMergeRange(this.sheet.getRowById(m), 'poTtl', this.sheet.getRowById(mergeInfo[m]), 'poTtl');
          this.sheet.setMergeRange(
            this.sheet.getRowById(m),
            'investMm',
            this.sheet.getRowById(mergeInfo[m]),
            'investMm'
          );
          this.sheet.setMergeRange(
            this.sheet.getRowById(m),
            'investKind',
            this.sheet.getRowById(mergeInfo[m]),
            'investKind'
          );
          this.sheet.setMergeRange(
            this.sheet.getRowById(m),
            'reqDegree',
            this.sheet.getRowById(mergeInfo[m]),
            'reqDegree'
          );
          this.sheet.setMergeRange(this.sheet.getRowById(m), 'regDt', this.sheet.getRowById(mergeInfo[m]), 'regDt');
        }
      });
    },
    sheet_onRenderFirstFinish(e) {
      e.sheet.setAttribute(null, 'frmNm', 'Visible', 1, 1);
    },
    sheet_onSearchFinish(e) {
      this.sheetMerge();
      this.onSetRow(e.sheet.getTotalRowCount() === 0 ? undefined : e.sheet.getFirstRow());
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data') {
        this.onSetRow(e.row);

        if (e.col == 'billNo' && e.row.billNo) {
          this.propsBillNo = e.row.billNo;
          this.propsOpen = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.createConfirm > .inputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
}

.createConfirm > p {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
}
</style>
