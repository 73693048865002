<template>
  <pmis-content-box :loading="loading">
    <template v-slot:header-left>
      <label>작업년월</label>
      <div class="ml5"></div>
      <iui-datepicker name="reqYm" type="month" :value="reqYm" @update:value="onReqYmChange" />
      <i class="prev-arrow" @click.prevent.self @click="onClickArrow('PREVIOUS')" />
      <i class="post-arrow" @click.prevent.self @click="onClickArrow('NEXT')" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="sheet = $event"
      :events="{onSearchFinish}"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOptions/DtlsPxCondResult.js';
import {selectDtlsPxcondResultList, selectYmList} from '@/view/Project/pxcondStatus/result/api/result';

export default {
  data() {
    return {
      loading: false,
      workType: '',
      Options: options(this),
      loadSearchData: [],
      reqYm: '',
      reqYmList: [],
      sheet: undefined,
      amountUnit: '',
      responseData: {
        sheet: [],
      },
    };
  },
  created() {
    this.addEvent([
      {name: 'DTLS_getYmList', func: this.getYmList},
      {name: 'DTLS_onAmountChange', func: this.convertPriceUnit},
    ]);
  },
  methods: {
    async onSearch() {
      this.loading = true;
      try {
        const response = await selectDtlsPxcondResultList({reqYm: this.reqYm, prjCd: this.prjCd});
        this.responseData.sheet = response.data;
        this.convertPriceUnit(this.amountUnit);
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    convertPriceUnit(value) {
      this.amountUnit = value;
      this.loadSearchData = $_priceUnitFormat(value, this.responseData.sheet);
    },
    onSearchFinish() {
      this.sheet.makeSubTotal([
        {
          stdCol: 'mgName',
          sumCols:
            'price|amt|beforePlanContAmt|planContAmt|totalPlanContAmt|beforeFixAmt|curFixAmt|totalFixAmt|leftPlanContAmt|preContAmt|curContAmt|totalContAmt|leftContAmt|leftFixAmt|leftContAmt',
          captionCol: [{col: 'mgName', val: '중공종계', span: 6}],
        },
        {
          stdCol: 'lgName',
          sumCols:
            'price|amt|beforePlanContAmt|planContAmt|totalPlanContAmt|beforeFixAmt|curFixAmt|totalFixAmt|leftPlanContAmt|preContAmt|curContAmt|totalContAmt|leftContAmt|leftFixAmt|leftContAmt',
          captionCol: [{col: 'lgName', val: '대공종계', span: 6}],
        },
        {
          stdCol: 'total',
          sumCols:
            'price|amt|beforePlanContAmt|planContAmt|totalPlanContAmt|beforeFixAmt|curFixAmt|totalFixAmt|leftPlanContAmt|preContAmt|curContAmt|totalContAmt|leftContAmt|leftFixAmt|leftContAmt',
          captionCol: [{col: 'total', val: '현장계', span: 7}],
        },
      ]);
    },
    onReqYmChange(reqYm) {
      this.reqYm = reqYm;
      if (reqYm) {
        this.onSearch();
      }
    },
    async getYmList(amountUnit) {
      this.amountUnit = amountUnit;
      const response = await selectYmList({prjCd: this.prjCd, type: 'DTLS'});

      const array = [];
      response.data.forEach(x => {
        array.push(x.reqYm);
      });
      this.reqYmList = array;

      if (array.length > 0) {
        this.reqYm = array[0];
        this.onSearch();
      } else {
        this.reqYm = '';
        this.loadSearchData = [];
      }
    },
    onClickArrow(type) {
      const index = this.reqYmList.indexOf(this.reqYm);

      if (index === -1) {
        this.reqYm = this.reqYmList[this.reqYmList.length - 1];
        this.onSearch();
      } else {
        if ((type === 'PREVIOUS' && index === 0) || (type === 'NEXT' && index + 1 === this.reqYmList.length)) {
          const message = type === 'PREVIOUS' ? '이전 작업년월이 없습니다.' : '다음 작업년월이 없습니다.';
          this.$alert({title: '작업년월', message: message});
          return false;
        }

        this.reqYm = type === 'PREVIOUS' ? this.reqYmList[index - 1] : this.reqYmList[index + 1];
        this.onSearch();
      }
    },
  },
};
</script>
