export default async that => {
  return {
    Cfg: {
      SearchMode: 2,
      CanEdit: false,
    },
    Cols: [
      {
        Header: '사업자번호',
        Name: 'bsnNo',
        Type: 'Text',
        Align: 'center',
        Width: 100,
        CustomFormat: 'SaupNo',
      },
      {
        Header: '업체명',
        Name: 'frmNm',
        Type: 'Text',
        Align: 'left',
        RelWidth: 1,
        MinWidth: 120,
      },
      {
        Header: '대표자명',
        Name: 'rpsvNm',
        Type: 'Text',
        Align: 'center',
        Width: 90,
      },
      {
        Header: '거래처유형',
        Name: 'frmDs',
        Type: 'Text',
        Align: 'center',
        Width: 120,
        Format: await $getCodeIBSheetFormat($getConstants('FRM_DS').code),
        Visible: that.frmGb1,
      },
      {
        Header: '거래종료',
        Name: 'useCloseDs',
        Type: 'Text',
        Align: 'center',
        Width: 70,
        Format: await $getCodeIBSheetFormat($getConstants('USE_CLOSE_DS').code),
      },
      {Name: 'vndrPgmCd', Visible: false},
    ],
  };
};
