<template>
  <div>
    <div class="join_step01 mb30"><span class="blind">step01 이용약관 동의</span></div>

    <!-- 개인정보 취급방침 동의 start -->
    <div class="agree_area">
      <div class="title">
        <label for="s1"><span></span>개인정보 취급방침 동의</label>
      </div>
    </div>
    <div class="terms_box">
      <p
        style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:10.0pt;text-align:justify;line-height:115%;'
      >
        &nbsp;
      </p>

      <h2
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;text-align:center;line-height:115%;font-size:13px;font-family:"맑은 고딕";font-weight:normal;background:white;'
      >
        <strong
          ><span style="font-size:18px;line-height:115%;font-family:굴림;color:#776F67;font-weight:normal;"
            >개인정보 처리방침</span
          ></strong
        >
      </h2>

      <p
        style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:10.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:12px;line-height:115%;font-family:굴림;color:#776F67;"><br />&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:13px;color:red;">TOPERP&nbsp;</span><span style="font-size:13px;color:red;">서비스</span
        ><span style="font-size:13px;color:#776F67;"
          >&nbsp;는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을
          원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:10.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:12px;line-height:115%;font-family:굴림;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">○&nbsp;</span
        ><span style="font-size:14px;color:#776F67;">본 방침은&nbsp;2023년&nbsp;08월 09일부터 시행됩니다.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;background:white;'
      >
        <p
          style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:7.5pt;margin-bottom:7.5pt;text-align:justify;line-height:22.5pt;background:white;border:none;padding:0cm;'
        >
          <span style="font-size:14px;font-family:굴림;color:black;">1.&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:black;">개인정보의 처리 목적</span>
        </p>
      </div>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:10.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:13px;color:red;">TOPERP&nbsp;</span><span style="font-size:13px;color:red;">서비스</span
        ><span style="font-size:13px;color:#776F67;">&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >는 개인정보를 다음의 목적을 위해 처리합니다.&nbsp;처리한 개인정보는 다음의 목적 이외의 용도로는 사용되지
          않으며 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할
          예정입니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:10.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(1)&nbsp;</span></strong
        ><strong
          ><span style="font-size:14px;font-family:굴림;color:#776F67;">서비스신청 및 신청결과 확인</span></strong
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:13px;color:red;">TOPERP&nbsp;</span><span style="font-size:13px;color:red;">서비스</span
        ><span style="font-size:13px;color:#776F67;">&nbsp;회원</span
        ><span style="font-size:14px;color:#776F67;"
          >&nbsp;가입의사 확인,&nbsp;회원제 서비스 제공에 따른 본인 식별&middot;인증,&nbsp;회원자격
          유지&middot;관리,&nbsp;제한적 본인확인 제 시행에 따른 본인확인,&nbsp;서비스 부정이용 방지,&nbsp;만14세 미만
          아동 개인정보 수집 시 법정대리인 동의 여부 확인 등을 목적으로 개인정보를 처리합니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:12px;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(2)&nbsp;</span></strong
        ><strong
          ><span style="font-size:14px;font-family:굴림;color:#776F67;">서비스의 지속적인 이용 및 관리</span></strong
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:13px;color:red;">TOPERP&nbsp;</span
        ><span style="font-size:13px;color:red;">서비스&nbsp;</span><span style="font-size:14px;color:#776F67;">(</span
        ><span style="font-size:14px;color:#776F67;"
          >건설사업관리솔루션 및 전자조달솔루션)를 통하여 이용자들에게 맞춤 식 서비스를 제공하기 위한 목적으로 최소한의
          개인정보를 처리합니다.</span
        >
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;background:white;'
      >
        <p
          style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:7.5pt;margin-bottom:7.5pt;text-align:justify;line-height:22.5pt;background:white;border:none;padding:0cm;'
        >
          <span style="font-size:14px;font-family:굴림;color:black;">2.&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:black;">개인정보 파일 현황</span>
        </p>
      </div>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(1)&nbsp;</span></strong
        ><strong><span style="font-size:14px;font-family:굴림;color:#776F67;">회원가입 및 회원관리정보</span></strong>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >개인정보
          항목:&nbsp;회사명,사업자번호,주민/법인번호,이름,전화번호,주소,홈페이지,계좌번호,ID,휴대폰번호,팩스번호,&nbsp;이메일</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;">수집방법:&nbsp;홈페이지</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;">보유근거:&nbsp;</span
        ><span style="font-size:13px;color:red;">TOPERP&nbsp;</span><span style="font-size:13px;color:red;">서비스</span
        ><span style="font-size:13px;color:#776F67;">&nbsp;</span
        ><span style="font-size:14px;color:#776F67;">이용에 따른 본인식별</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:12.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:12px;line-height:115%;font-family:굴림;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(2)&nbsp;</span></strong
        ><strong><span style="font-size:14px;font-family:굴림;color:#776F67;">사원정보</span></strong>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >개인정보 항목:
          ID,비밀번호,이름,주민번호,소속,담당업무,직책,직급,전화번호,팩스번호,휴대폰번호,이메일,주소,</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">계좌번호</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;">수집방법:&nbsp;홈페이지</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;">보유근거:&nbsp;근로자노임대장 서비스제공</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:12.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:12px;line-height:115%;font-family:굴림;color:#776F67;">&nbsp;</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;background:white;'
      >
        <p
          style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:7.5pt;margin-bottom:7.5pt;text-align:justify;line-height:22.5pt;background:white;border:none;padding:0cm;'
        >
          <span style="font-size:14px;font-family:굴림;color:black;">3.&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:black;">개인정보의 처리 및 보유 기간</span>
        </p>
      </div>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:13px;color:red;">TOPERP&nbsp;</span><span style="font-size:13px;color:red;">서비스</span
        ><span style="font-size:13px;color:#776F67;">&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >는 법령에 따른 개인정보 보유&middot;이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보
          보유,&nbsp;이용기간 내에서 개인정보를 처리,&nbsp;보유합니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">&nbsp;</span></strong>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(1)&nbsp;</span></strong
        ><strong><span style="font-size:14px;font-family:굴림;color:#776F67;">회원가입 및 회원관리정보</span></strong>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-</span
        ><span style="font-size:14px;color:#776F67;">보유근거:&nbsp;서비스이용에 따른 본인식별</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-</span
        ><span style="font-size:14px;color:#776F67;"
          >관련법령:&nbsp;계약 또는 청약철회 등에 관한 기록5년(전자상거래 등에서의 소비자보호에 관한 법률)</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:10.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:12px;line-height:115%;font-family:굴림;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(2)&nbsp;</span></strong
        ><strong><span style="font-size:14px;font-family:굴림;color:#776F67;">사원정보</span></strong>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-</span
        ><span style="font-size:14px;color:#776F67;">보유근거:&nbsp;서비스제공</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-</span
        ><span style="font-size:14px;color:#776F67;"
          >관련법령:&nbsp;대금결재 및 재화 등의 공급에 관한 기록5년(전자상거래 등에서의 소비자보호에 관한 법률)</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-</span
        ><span style="font-size:14px;color:#776F67;"
          >관련법령:&nbsp;소비자의 불만 또는 분쟁처리에 관한 기록3년(전자상거래 등에서의 소비자보호에 관한 법률)</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-</span
        ><span style="font-size:14px;color:#776F67;"
          >관련법령:&nbsp;본인확인에 관한 기록1년(정보통신망 이용족진 및 정보보호 등에 관한 법률)</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-</span
        ><span style="font-size:14px;color:#776F67;">관련법령:&nbsp;웹사이트 방문기록 3개월(통신보호비밀 법)</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;background:white;'
      >
        <p
          style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:7.5pt;margin-bottom:7.5pt;text-align:justify;line-height:22.5pt;background:white;border:none;padding:0cm;'
        >
          <span style="font-size:14px;font-family:굴림;color:black;">4.&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:black;">개인정보의 제3자 제공에 관한 사항</span>
        </p>
      </div>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:13px;color:red;">TOPERP&nbsp;</span><span style="font-size:13px;color:red;">서비스</span
        ><span style="font-size:13px;color:#776F67;">&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >는 정보주체의 동의,&nbsp;법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만
          개인정보를 제3자에게 제공합니다.</span
        >
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;background:white;'
      >
        <p
          style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:7.5pt;margin-bottom:7.5pt;text-align:justify;line-height:22.5pt;background:white;border:none;padding:0cm;'
        >
          <span style="font-size:14px;font-family:굴림;color:black;">5.&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:black;">개인정보처리 위탁</span>
        </p>
      </div>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:13px;color:red;">TOPERP&nbsp;</span><span style="font-size:13px;color:red;">서비스</span
        ><span style="font-size:13px;color:#776F67;">&nbsp;는 개인정보를 외주업체에 위탁처리하고 있지 않습니다.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;background:white;'
      >
        <p
          style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:7.5pt;margin-bottom:7.5pt;text-align:justify;line-height:22.5pt;background:white;border:none;padding:0cm;'
        >
          <span style="font-size:14px;font-family:굴림;color:black;">6.&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:black;"
            >정보주체의 권리,의무 및 그 행사방법 이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.</span
          >
        </p>
      </div>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">정보주체는&nbsp;</span
        ><span style="font-size:14px;color:red;">TOPERP</span><span style="font-size:13px;color:red;">&nbsp;</span
        ><span style="font-size:13px;color:red;">서비스&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:10.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:12px;line-height:115%;font-family:굴림;color:#776F67;">&nbsp;</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";'
      >
        <ol style="margin-bottom:0cm;list-style-type: undefined;margin-left:44px;">
          <li
            style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";'
          >
            <strong
              ><span style="font-family:굴림;color:#776F67;font-size:10.5pt;color:#776F67;"
                >개인정보 열람요구</span
              ></strong
            >
          </li>
        </ol>
      </div>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:13px;color:black;"
          >개인정보보호법」 제35조(개인정보의 열람)에 따라 열람을 요구할 수 있습니다.&nbsp;다만 개인정보 열람 요구는
          「개인정보보호법」 제35조제5항에 의하여 다음과 같이 제한될 수 있습니다&nbsp;</span
        >
      </p>

      <ul style="list-style-type: undefined;margin-left:44px;">
        <li>
          <span style="font-size:13px;color:black;color:black;">법률에 따라 열람이 금지되거나 제한되는 경우</span>
        </li>
        <li>
          <span style="font-size:13px;color:black;color:black;"
            >다른 사람의 생명,&nbsp;신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가
            있는 경우&nbsp;</span
          >
        </li>
        <li>
          <span style="font-size:13px;color:black;color:black;"
            >다른 법률에 따라 진행 중인 감사 및 조사에 관한 업무를 수행할 때 중대한 지장을 초래하는 경우&nbsp;</span
          >
        </li>
      </ul>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">&nbsp;</span></strong>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(2)&nbsp;</span></strong
        ><strong
          ><span style="font-size:14px;font-family:굴림;color:#776F67;">오류 등이 있을 경우 정정 요구</span></strong
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:34.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:13px;color:red;">TOPERP&nbsp;</span><span style="font-size:13px;color:red;">서비스</span
        ><span style="font-size:13px;color:#776F67;">&nbsp;에&nbsp;</span
        ><span style="font-size:13px;color:black;"
          >보유하고 있는 개인정보파일에 대해서는 「개인정보보호법」 제36조(개인정보의 정정&bull;삭제)에 따라&nbsp;</span
        ><span style="font-size:13px;color:red;">TOPERP&nbsp;</span><span style="font-size:13px;color:red;">서비스</span
        ><span style="font-size:13px;color:#776F67;">&nbsp;</span
        ><span style="font-size:13px;color:black;"
          >에 개인정보의 정정&bull;삭제를 요구할 수 있습니다.&nbsp;다만,&nbsp;다른 법령에서 그 개인정보가 수집 대상으로
          명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다&nbsp;</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:10.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:12px;line-height:115%;font-family:굴림;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(3)&nbsp;</span></strong
        ><strong><span style="font-size:14px;font-family:굴림;color:#776F67;">처리정지 요구</span></strong>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:13px;color:red;">TOPERP&nbsp;</span><span style="font-size:13px;color:red;">서비스</span
        ><span style="font-size:13px;color:#776F67;">&nbsp;에&nbsp;</span
        ><span style="font-size:13px;color:black;"
          >보유하고 있는 개인정보파일에 대해서는 「개인정보보호법」 제37조(개인정보의 처리정지 등)에 따라&nbsp;</span
        ><span style="font-size:13px;color:red;">TOPERP&nbsp;</span><span style="font-size:13px;color:red;">서비스</span
        ><span style="font-size:13px;color:#776F67;">&nbsp;</span
        ><span style="font-size:13px;color:black;"
          >에 개인정보의 처리정지를 요구할 수 있습니다.&nbsp;다만,&nbsp;개인정보 처리정지 요구 시 「개인정보보호법」
          제37조제2항에 의하여 처리정지 요구가 거절될 수 있습니다 &nbsp;</span
        >
      </p>

      <ul style="list-style-type: undefined;margin-left:42px;">
        <li>
          <span style="font-size:13px;color:black;"
            >법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우 &nbsp;</span
          >
        </li>
        <li>
          <span style="font-size:13px;color:black;"
            >다른 사람의 생명,&nbsp;신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가
            있는 경우&nbsp;</span
          >
        </li>
        <li>
          <span style="font-size:13px;color:black;"
            >공공기관이 개인정보를 처리하지 아니하며 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우&nbsp;</span
          >
        </li>
        <li>
          <span style="font-size:13px;color:black;"
            >개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서
            정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우&nbsp;</span
          >
        </li>
      </ul>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">&nbsp;</span></strong>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(4)&nbsp;</span></strong
        ><strong><span style="font-size:14px;font-family:굴림;color:#776F67;">기타</span></strong>
      </p>

      <ul style="list-style-type: undefined;margin-left:42px;">
        <li>
          <span style="font-size:13px;color:red;">TOPERP&nbsp;</span
          ><span style="font-size:13px;color:red;">서비스</span
          ><span style="font-size:13px;color:#776F67;">&nbsp;는&nbsp;</span
          ><span style="font-size:13px;color:black;"
            >법령의 규정과 정보주체의 동의에 의해서만 개인정보를 수집하여,&nbsp;정보주체 이외로부터 수집한 정보를 처리한
            때에는 정보주체의 요구가 있는 경우 즉시 수집 출처와 처리 목적을 알리겠습니다.</span
          >
        </li>
        <li>
          <span style="font-size:13px;color:black;"
            >정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는&nbsp;</span
          ><span style="font-size:13px;color:red;">TOPERP&nbsp;</span
          ><span style="font-size:13px;color:red;">서비스</span
          ><span style="font-size:13px;color:#776F67;">&nbsp;는&nbsp;</span
          ><span style="font-size:13px;color:black;"
            >정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.&nbsp;</span
          >
        </li>
      </ul>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">&nbsp;</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;background:white;'
      >
        <p
          style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:7.5pt;margin-bottom:7.5pt;text-align:justify;line-height:22.5pt;background:white;border:none;padding:0cm;'
        >
          <span style="font-size:14px;font-family:굴림;color:black;">7.&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:black;">개인정보의 파기</span>
        </p>
      </div>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:13px;color:red;">TOPERP&nbsp;</span
        ><span style="font-size:13px;color:red;">서비스&nbsp;</span><span style="font-size:13px;color:black;">는</span
        ><span style="font-size:14px;color:#776F67;"
          >&nbsp;원칙적으로 개인정보 처리목적이 달성된 경우에는 지체 없이 해당 개인정보를 파기합니다.&nbsp;파기의
          절차,&nbsp;기한 및 방법은 다음과 같습니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:12.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:12px;line-height:115%;font-family:굴림;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(1)&nbsp;</span></strong
        ><strong><span style="font-size:14px;font-family:굴림;color:#776F67;">파기절차</span></strong>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >이용자가 입력한 정보는 목적 달성 후 별도의&nbsp;DB에 옮겨져(종이의 경우 별도의 서류)&nbsp;내부 방침 및 기타
          관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다.&nbsp;이 때, DB로 옮겨진 개인정보는 법률에 의한
          경우가 아니고서는 다른 목적으로 이용되지 않습니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >파기기한 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터&nbsp;5일
          이내에,&nbsp;개인정보의 처리 목적 달성,&nbsp;해당 서비스의 폐지,&nbsp;사업의 종료 등 그 개인정보가 불필요하게
          되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터&nbsp;5일 이내에 그 개인정보를
          파기합니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:10.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:12px;line-height:115%;font-family:굴림;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(2)&nbsp;</span></strong
        ><strong><span style="font-size:14px;font-family:굴림;color:#776F67;">파기방법</span></strong>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</span
        >
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;background:white;'
      >
        <p
          style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:7.5pt;margin-bottom:7.5pt;text-align:justify;line-height:22.5pt;background:white;border:none;padding:0cm;'
        >
          <span style="font-size:14px;font-family:굴림;color:black;">8.&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:black;">개인정보의 안전성 확보 조치</span>
        </p>
      </div>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:13px;color:red;">TOPERP&nbsp;</span
        ><span style="font-size:13px;color:red;">서비스&nbsp;</span><span style="font-size:13px;color:black;">는</span
        ><span style="font-size:13px;color:red;">&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고
          있습니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:12.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:12px;line-height:115%;font-family:굴림;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(1)&nbsp;</span></strong
        ><strong><span style="font-size:14px;font-family:굴림;color:#776F67;">정기적인 자체감사 실시</span></strong>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >개인정보 취급 관련 안정성 확보를 위해 정기적(분기&nbsp;1회)으로 자체 감사를 실시하고 있습니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:10.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:12px;line-height:115%;font-family:굴림;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(2)&nbsp;</span></strong
        ><strong
          ><span style="font-size:14px;font-family:굴림;color:#776F67;"
            >개인정보 취급직원의 최소화 및 교육</span
          ></strong
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고
          있습니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:10.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:12px;line-height:115%;font-family:굴림;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(3)&nbsp;</span></strong
        ><strong
          ><span style="font-size:14px;font-family:굴림;color:#776F67;">내부관리계획의 수립 및 시행</span></strong
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(4)&nbsp;</span></strong
        ><strong
          ><span style="font-size:14px;font-family:굴림;color:#776F67;"
            >해킹 등에 대비한 기술적 대책&nbsp;</span
          ></strong
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:10.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:13px;color:red;">TOPERP&nbsp;</span
        ><span style="font-size:13px;color:red;">서비스&nbsp;</span><span style="font-size:13px;color:black;">는</span
        ><span style="font-size:13px;color:red;">&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인
          갱신&bull;점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적,&nbsp;물리적으로 감시 및
          차단하고 있습니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(5)&nbsp;</span></strong
        ><strong><span style="font-size:14px;font-family:굴림;color:#776F67;">개인정보의 암호화&nbsp;</span></strong>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >이용자의 개인정보 는 암호화 되어 저장 및 관리되고 있어,&nbsp;본인만이 알 수 있으며 중요한 데이터는 파일 및
          전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.</span
        >
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;background:white;'
      >
        <p
          style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:7.5pt;margin-bottom:7.5pt;text-align:justify;line-height:22.5pt;background:white;border:none;padding:0cm;'
        >
          <span style="font-size:14px;font-family:굴림;color:black;">9.&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:black;">개인정보 보호책임자 지정</span>
        </p>
      </div>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:13px;color:red;">TOPERP&nbsp;</span
        ><span style="font-size:13px;color:red;">서비스&nbsp;</span
        ><span style="font-size:13px;color:black;">는&nbsp;</span
        ><span style="font-size:14px;color:black;">개인정보</span
        ><span style="font-size:14px;color:#776F67;"
          >&nbsp;처리에 관한 업무를 총괄해서 책임지고,&nbsp;개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을
          위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(1)&nbsp;</span></strong
        ><strong><span style="font-size:14px;font-family:굴림;color:#776F67;">개인정보 보호 책임자</span></strong>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">성명: 정홍민</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">직책: 상무</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">연락처: </span
        ><span style="font-size:14px;color:red;">hmjung@inogard.co.kr</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(2)&nbsp;</span></strong
        ><strong><span style="font-size:14px;font-family:굴림;color:#776F67;">개인정보 보호 담당부서</span></strong>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">부서명: 관리팀</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">담당자: 정홍민</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">연락처: </span
        ><span style="font-size:14px;color:red;">hmjung@inogard.co.kr</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">▶&nbsp;</span
        ><span style="font-size:14px;color:#776F67;">정보주체께서는&nbsp;</span
        ><span style="font-size:13px;color:red;">TOPERP&nbsp;</span
        ><span style="font-size:13px;color:red;">서비스&nbsp;</span><span style="font-size:13px;color:black;">를</span
        ><span style="font-size:13px;color:red;">&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >이용하시면서 발생한 모든 개인정보 보호 관련 문의,&nbsp;불만처리,&nbsp;피해구제 등에 관한 사항을 개인정보
          보호책임자 및 담당부서로 문의하실 수 있습니다.&nbsp;</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">▶ &nbsp;</span><span style="font-size:13px;color:red;">TOPERP</span
        ><span style="font-size:13px;color:red;">서비스&nbsp;</span><span style="font-size:13px;color:black;">는</span
        ><span style="font-size:13px;color:red;">&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</span
        >
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;background:white;'
      >
        <p
          style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:7.5pt;margin-bottom:7.5pt;text-align:justify;line-height:22.5pt;background:white;border:none;padding:0cm;'
        >
          <span style="font-size:14px;font-family:굴림;color:black;">10.&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:black;">기타기관안내</span>
        </p>
      </div>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">아래의 기관은&nbsp;</span
        ><span style="font-size:13px;color:red;">TOPERP&nbsp;</span
        ><span style="font-size:13px;color:red;">서비스&nbsp;</span
        ><span style="font-size:13px;color:black;">와&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >별개의 기관으로서,&nbsp;자체적인 개인정보 불만처리,&nbsp;피해구제 결과에 만족하지 못하시거나 보다 자세한
          도움이 필요하시면 문의하여 주시기 바랍니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:10.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:12px;line-height:115%;font-family:굴림;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(1)&nbsp;</span></strong
        ><strong
          ><span style="font-size:14px;font-family:굴림;color:#776F67;"
            >개인정보 침해 신고센터(한국인터넷진흥원)</span
          ></strong
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;">소관업무:&nbsp;개인정보 침해사실 신고,&nbsp;상담 신청</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;">홈페이지: privacy.kisa.or.kr</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;">전화: (국번 없이) 118</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >주소: (138-950)&nbsp;서울시 송파구 중대로&nbsp;135&nbsp;한국인터넷진흥원 개인정보침해신고센터</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(2)&nbsp;</span></strong
        ><strong
          ><span style="font-size:14px;font-family:굴림;color:#776F67;"
            >개인정보 분쟁조정위원회(한국인터넷진흥원)</span
          ></strong
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >소관업무:&nbsp;개인정보 분쟁조정신청,&nbsp;집단분쟁조정&nbsp;(민사적 해결)</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;">홈페이지: privacy.kisa.or.kr</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;">전화: (국번 없이) 118</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">-&nbsp;</span
        ><span style="font-size:14px;color:#776F67;"
          >주소: (138-950)&nbsp;서울시 송파구 중대로&nbsp;135&nbsp;한국인터넷진흥원 개인정보침해신고센터</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(3)&nbsp;</span></strong
        ><strong
          ><span style="font-size:14px;font-family:굴림;color:#776F67;"
            >대검찰청 사이버범죄수사 단: 02-3480-3573 (www.spo.go.kr)</span
          ></strong
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <strong><span style="font-size:14px;font-family:굴림;color:#776F67;">(4)&nbsp;</span></strong
        ><strong
          ><span style="font-size:14px;font-family:굴림;color:#776F67;"
            >경찰청 사이버범죄수사 단: 1566-0112 (www.netan.go.kr)</span
          ></strong
        >
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;background:white;'
      >
        <p
          style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:7.5pt;margin-bottom:7.5pt;text-align:justify;line-height:22.5pt;background:white;border:none;padding:0cm;'
        >
          <span style="font-size:14px;font-family:굴림;color:black;">11.&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:black;">개인정보 처리방침 변경</span>
        </p>
      </div>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">본</span
        ><span style="font-size:14px;color:#776F67;"
          >&nbsp;개인정보처리방침은 시행일로부터 적용되며,&nbsp;법령 및 방침에 따른 변경내용의 추가,&nbsp;삭제 및 정정이
          있는 경우에는 변경사항의 시행&nbsp;7일 전부터 공지사항을 통하여 고지할 것입니다.</span
        >
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:12.0pt;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:12px;line-height:115%;font-family:굴림;color:#776F67;">&nbsp;</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">▶</span><span style="font-size:14px;color:#776F67;">&nbsp;</span
        ><span style="font-size:14px;color:#776F67;">공고일자: 2023년 08월 09일</span>
      </p>

      <p
        style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:0cm;text-align:justify;line-height:115%;background:white;'
      >
        <span style="font-size:14px;color:#776F67;">▶</span><span style="font-size:14px;color:#776F67;">&nbsp;</span
        ><span style="font-size:14px;color:#776F67;">시행일자: 2023년 08월 09일</span>
      </p>
    </div>
    <div class="agree_area">
      <div class="content">
        <input type="checkbox" id="c1" v-model="agree1" @change="onAgree" v-if="type !== 'mobile'" />
        <label for="c1">
          <span></span>
          TOPERP 서비스 이용약관 및 활용에 대해 동의합니다.
        </label>
      </div>
    </div>
    <!-- 개인정보 취급방침 동의 end -->
    <!-- 서비스 이용약관 동의 start -->
    <div class="agree_area">
      <div class="title">
        <label for="s2"><span></span>서비스 이용약관 동의</label>
      </div>
    </div>
    <div class="terms_box">
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:14px;font-family:굴림;color:#246AD1;">제</span
          ><span style='font-size:14px;font-family:"Nanum Gothic",serif;color:#246AD1;'>&nbsp;1</span
          ><span style="font-size:14px;font-family:굴림;color:#246AD1;">장</span
          ><span style='font-size:14px;font-family:"Nanum Gothic",serif;color:#246AD1;'>&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:#246AD1;">총칙</span>
        </p>
      </div>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>1</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">목적</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">(</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이하</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;)</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;(</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">주</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">)</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이노가드</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">(</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이하</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&quot;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&quot;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">라</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">한다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">)</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">건설사업관리솔루션</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전자조달솔루션을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&quot;</span
        ><span style="font-size:12px;font-family:돋움;color:red;">TOPERP&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:red;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&quot;(</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이하</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&quot;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&quot;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">라</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">한다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">)</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용조건</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">절차에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사항</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기타</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">필요한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사항을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">규정함을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">목적으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>2</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">약관의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">효력</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">및</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">변경</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자에게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공시함으로써</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">효력을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;2. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">합리적인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사유가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관계법령에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">위배되지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">않는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">범위에서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있으며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경된</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전항과</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">같은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">방법으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">효력을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">3.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">동의하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">것은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정기적으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:돋움;color:red;">&nbsp;TOPERP&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:red;">사이트</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&rsquo;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">방문하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경사항을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">확인하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">것에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">동의함을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의미한다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경된</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">알지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">못해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">피해는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">에서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">책임지지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">않는다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>3</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">약관의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">공시</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">및</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">준용</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내용은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&quot;&nbsp;</span
        ><span style="font-size:12px;font-family:돋움;color:red;">TOPERP&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:red;">사이트</span
        ><span style="font-size:12px;font-family:돋움;color:red;">&quot;(https://toperp.co.kr</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이하</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사이트</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;)</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">온라인으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공시</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">게시하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경될</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지체</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp; &nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공시합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;2.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용조건에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">중대한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">영향을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">미치는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사항에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대해서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경전이라도</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공지사항을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공지할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있으며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp; &nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일부로서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">유효합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>4</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">약관</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">이외</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">준칙</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">본</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">명시되지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">않은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사항은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전기통신기본법</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전기통신사업법</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보통신윤리위원회심의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">규정</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보통신</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">윤리강령</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">프로그램보호법</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기타</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관련</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">법령</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">상</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관례에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">따릅니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;2.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용하기</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">위해서는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">와</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">별도의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:red;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">계약을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">체결해야</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>5</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">용어의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">정의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관에서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사용하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">용어의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정의는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">다음과</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">같습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;1.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">:&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">본</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">따라</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">당</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사이트가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">받는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;2.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가입</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">:&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">본</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">동의하고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원종별로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">선택한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">후</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원정보를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등록하고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&lsquo;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가입</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&rsquo;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">버튼을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">누름으로써</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용계약을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">완료시키는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">행위</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;3.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">:&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:red;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">계약을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">체결하고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">본</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">따라</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">당</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사이트에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">개인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등록을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자로서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">당</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사이트의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공받으며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">당</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사이트가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">주체</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;4.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">건설사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">:&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;">서비스를</span
        ><span style="font-size:12px;font-family:돋움;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;">이용하고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사용에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">따른</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">월</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정액</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">요금을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">납부하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사용주체로서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">건설사업관리시스템</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전자조달업무를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수행하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">주체를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">말합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">. <br />&nbsp;5.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">협력회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">:&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;">서비스를</span
        ><span style="font-size:12px;font-family:돋움;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;">이용하고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">온라인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">입찰</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">계약</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">건</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">별</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수수료를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전자조달업무에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">참여하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">주체를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">말합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">. <br />&nbsp;6.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">비밀번호</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">:&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자와</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">ID</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일치하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">확인하고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통신상의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자신의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">비밀보호를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">위하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자신이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">선정한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">문자와</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">숫자의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">조합</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;7.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">탈퇴</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">:&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:red;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용계약을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해지하고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용계약을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">종료</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">시키는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">행위</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;8.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">본</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관에서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정의하지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">않은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">용어는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">개별서비스에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">별도</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용규정에서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정의합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:14px;font-family:굴림;color:#246AD1;">제</span
          ><span style='font-size:14px;font-family:"Nanum Gothic",serif;color:#246AD1;'>&nbsp;2</span
          ><span style="font-size:14px;font-family:굴림;color:#246AD1;">장</span
          ><span style='font-size:14px;font-family:"Nanum Gothic",serif;color:#246AD1;'>&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:#246AD1;">이용계약의</span
          ><span style='font-size:14px;font-family:"Nanum Gothic",serif;color:#246AD1;'>&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:#246AD1;">체결</span>
        </p>
      </div>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>6</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">이용계약의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">성립</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용계약은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">신청자가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">오프라인으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:red;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용계약</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">후</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">온라인으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">당</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사이트에서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">소정의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가입신청</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">양식에서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">요구하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사항을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기록하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가입을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">완료하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">것으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">성립됩니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp; &nbsp;(</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">단</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">협력업체</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용계약은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">본</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">동의와</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용신청에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용승낙을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">함으로써</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">성립합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.)</span>
      </p>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>7</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">이용신청</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:굴림;color:#776F67;">이용신청은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지시하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">신청</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">화면상의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">양식에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">신청인이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자신의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">입력하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">신청함으로써</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">성립됩니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>8</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">이용신청의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">승낙</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용신청에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">필요사항을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">확인한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">후</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용신청을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">승낙합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;2. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">다음과</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">같은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용신청자의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가입</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">신청</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">승낙을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제한할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사유가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해소될</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">때까지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">승낙을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">유보</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">거절할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"
          >.<br />&nbsp;<br />&nbsp; &nbsp; &nbsp;2-1.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이미</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가입된</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">법인등록번호</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사업자등록번호</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">주민등록번호와</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">동일한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp; &nbsp; &nbsp;2-2.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관련</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">설비</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">용량이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부족한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp; &nbsp; &nbsp;2-3.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기술상</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">문제가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp; &nbsp; &nbsp;2-4.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용신청자가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부정확하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">현재의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사실과</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일치하지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">않는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp; &nbsp; &nbsp;2-5.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사회의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">안녕질서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">미풍양속을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">저해할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">목적으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">신청한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp; &nbsp; &nbsp;2-6.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기타</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">운영을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">방해할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의도로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">신청한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp; &nbsp; &nbsp;2-7.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기타</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부득의한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사정이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;<br />&nbsp;3.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용신청자는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">다음</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사항을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">준수하여야</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"
          >.<br />&nbsp;<br />&nbsp; &nbsp; &nbsp;3-1.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가입신청</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">양식에는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">실명을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사용할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">것</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp; &nbsp; &nbsp;3-2.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가입신청</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">양식의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내용은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">현재의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사실과</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일치할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">것</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp; &nbsp; &nbsp;3-3.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사회의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">안녕</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">질서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">미풍양속을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">저해할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">목적으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">신청하지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">않을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">것</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp; &nbsp; &nbsp;3-4.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등록</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">양식</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">그</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내용에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">생긴</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">현재의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사실과</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일치하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">완전한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">갱신할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">것</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;<br />&nbsp;4.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가입은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">단위로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등록하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">것을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">원칙으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.&nbsp;</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:14px;font-family:굴림;color:#246AD1;">제</span
          ><span style='font-size:14px;font-family:"Nanum Gothic",serif;color:#246AD1;'>&nbsp;3&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:#246AD1;">장</span
          ><span style='font-size:14px;font-family:"Nanum Gothic",serif;color:#246AD1;'>&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:#246AD1;">서비스</span
          ><span style='font-size:14px;font-family:"Nanum Gothic",serif;color:#246AD1;'>&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:#246AD1;">이용</span>
        </p>
      </div>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>9</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">서비스</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">이용</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">본</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">규정된</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사항을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">준수하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;2.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">본</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">명시되지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">않은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사항은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">안내에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">게시하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">별도로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공지하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내용에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">따릅니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>10</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">서비스의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">제공</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">및</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">변경</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">에게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">아래와</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">같은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"
          >.<br />&nbsp;<br />&nbsp; &nbsp; &nbsp;1-1.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">건설사업관리시스템</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">(</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">건설사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">)<br />&nbsp; &nbsp; &nbsp;1-2.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전자조달시스템</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">(</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">건설사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">협력업체</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">)<br />&nbsp; &nbsp; &nbsp;1-3.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기타</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자체</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">개발하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">다른</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">협력</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">계약을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원들에게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일체의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;<br />&nbsp;2. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경될</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내용</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공일자를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사이트</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공지하고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>11</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">서비스</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">이용</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">시간</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">업무상</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기술상</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">특별한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지장이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">연중무휴</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">, 1</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;24</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">시간</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">운영을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">원칙으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">단</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">, &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사전에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공지하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp; &nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정기점검</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">필요할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우에는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일시</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">중단</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">예정된</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">작업으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">인한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일시</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">중단은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사이트</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사전에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공지합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;2. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일정부분에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">각</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부분별로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용가능시간을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있으며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">그</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내용을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">안내에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">게시하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">별도로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp; &nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사전에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공지하여야</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;3. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">긴급한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">시스템</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">점검</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부득이한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사유가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우에는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">예고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일시</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">중단할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있으며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기존</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">새로운</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp; &nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">교체할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">필요가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우에는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공되는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">완전히</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">중단할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;4. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">국가비상사태</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정전</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">설비의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">장애</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">폭주</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">인하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정상적인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공하기</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">곤란할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전부</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp; &nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일부를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제한하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">중단할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">다만</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">그</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사유</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기간</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">에게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사전</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사후에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공지합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;5. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통제할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사유로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">인한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">중단</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">시스템다운</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">시스템</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관리자의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">고의나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">과실이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">디스크</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">장애</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">타인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">(PC</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통신회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기간통신사업</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">)</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">시스템</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">중단</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사전통지가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">곤란한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통지를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">생략할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>12</span
          ><span style="font-size:12px;font-family:굴림;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;">서비스</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;">이용</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;">정보</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;">제공</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">운영함에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있어</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">각종</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">화면에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">게재하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전자우편이나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서신우편</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">, SMS&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">방법으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">에게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;2. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">화면</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전자우편</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">, SMS&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">광고를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">게재할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;3. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자에게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">다른</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">웹사이트</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자료에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">링크를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">외부사이트</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자료에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통제권이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없으므로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">그로부터</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공받는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자료의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">유용성에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">책임이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>13</span
          ><span style="font-size:12px;font-family:굴림;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;">회원정보의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;">수집</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">에게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;">서비스를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공하는데</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">필요한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">최소한의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수집하며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">외</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">추가적으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">필요한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수집할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&lsquo;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&rsquo;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부터</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수집에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">동의를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">받습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;2. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">언제든지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자신에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">열람할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">, &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보관리</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">책임자에게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">잘못된</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정정을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">요청할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.&nbsp;</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>14</span
          ><span style="font-size:12px;font-family:굴림;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;">회원정보의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;">변경</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1</span>
        <span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용신청</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">시</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기재한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사항이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경되었을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">즉시</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해당</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사항을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수정해야</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">하며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">필요할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내역을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">확인할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">별도의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">증빙</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제출을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">에게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">요구할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;2.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경되었음에도</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해당</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사항을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수정하지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">않음으로써</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">각종</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">손해와</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">잘못된</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수정으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">인하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">손해는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">당해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부담해야</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">하며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">, &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">아무런</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">책임을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">않습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.&nbsp;</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>15</span
          ><span style="font-size:12px;font-family:굴림;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;">아이디</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>(ID)&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;">및</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;">비밀번호의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;">관리</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1</span>
        <span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">아이디</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">(ID)&#39;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">비밀번호에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관리책임은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">에게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있으며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">, &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">아이디</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">(ID)&#39;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">비밀번호를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">타인에게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">양도</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대여할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;2 &nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">행위에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의하지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">아니한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">아이디</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">(ID)&#39;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">비밀번호의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">유출</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">양도</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대여로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">인한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">손실이나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">손해에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">아무런</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">책임을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">않습니다</span>
      </p>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">3</span>
        <span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">아이디</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">(ID)&#39;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">비밀번호를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">도난</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">당하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">3</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사용하고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있음을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">인지한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">즉시</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통보하여야</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">하고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">, &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">안내가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우에는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">그에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">따라야</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;<br />&nbsp;4.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">1</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">항</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">2</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">항</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">3</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">항은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">소속</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">종사자에게도</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">동일하게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">준용되며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">그</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">종사자에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관리책임을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부담하게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">됩니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>16</span
          ><span style="font-size:12px;font-family:굴림;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;">회원</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;">정보의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;">보호</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등록</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기타</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">다른</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보통신부에서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제정한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&quot;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통신망</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">촉진에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">법률</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&quot;, &quot;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">개인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">보호</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지침</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&quot;, &quot;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">신용정보의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">보호에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">법률</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&quot;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">준수합니다</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>17</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">서비스</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">내용의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">추가변경</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내용이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">추가되거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경됨으로써</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">필요한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사항이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경될</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">안내에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">게시하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">별도로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공지하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내용에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">따릅니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>18</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">서비스</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">이용책임</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">당사자가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">아닌</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">거래로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">분쟁은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">당사자간의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해결을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">원칙으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">하며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">중재할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의무가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>19</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;">게시물</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;">관리</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">건전한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통신문화정착과</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">효율적인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">운영을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">위하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">게시하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자료가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">다음</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">각</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">호의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;1</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해당한다고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">판단되는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">임의로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">삭제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자료</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이동</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등록거부를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"
          >.<br />&nbsp;<br />&nbsp; &nbsp; &nbsp;1-1.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;3</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">명예</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">신용을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">훼손하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">모욕하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내용</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">영업을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">방해하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내용인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp; &nbsp; &nbsp;1-2.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">위해할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">소지가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">바이러스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">포함된</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자료인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp; &nbsp; &nbsp;1-3.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">범죄적</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">행위와</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">결부된다고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">객관적으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">인정되는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내용인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp; &nbsp; &nbsp;1-4.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;3</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지적재산권</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">권리를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">침해하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내용인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp; &nbsp; &nbsp;1-5.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기타</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관계법령</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">게시물</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관리규정에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">위배된다고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">판단되는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;<br />&nbsp;2. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;1</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">항에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자료를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">삭제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자료이동</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등록</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">거부하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우에는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">그</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사유를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해당</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자에게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통지합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">단</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부득이한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우에는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">그렇지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">않을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&nbsp;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;3.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">운영</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">시스템과</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관련하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">개발한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">프로그램</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">그에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수반된</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">애플리케이션</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">운영솔루션</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">그리고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">디자인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">배타적</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지적</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">재산권에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">속하므로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">, &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사전</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">허락</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">무단</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사용</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">무단</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">복제할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;4.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">운영을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수집</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">/</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">분석한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">거래</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관련</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통계</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">직접</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수집</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가공한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기타</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">배타적이고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">독점적인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">권리를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">보유</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>20</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">서비스</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">이용의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">제한</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경중판단에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">따라</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전부</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">혹은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일부의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용제한을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;1.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공공질서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">미풍양속에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">반하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;2.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">범죄적</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">행위에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관련되는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;3.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">타인의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">명예를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">손상시키거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">타인의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">현저히</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">저해하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;4.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">위</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">바이러스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">감염자료</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">게재</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">건전한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">운영을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">저해하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;5.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">다른</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">3</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지적재산권을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">침해하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지적재산권자가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지적재산권의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">침해를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">주장할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있다고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">판단되는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;6.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">타인의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자번호</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">비밀번호를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">도용한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;7.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관계법령에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">위배되는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;8.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기타</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자로서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부적당하다고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">판단하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>21</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">서비스</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">제공의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">중지</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">다음</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">각</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">호의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;1</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해당하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전부</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일부의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">중지할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;1.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전기통신사업법상에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">규정된</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기간</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통신사업자가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전기통신서비스를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">중지했을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;2.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정전으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">불가능한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;3.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스용</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">설비의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이전</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">보수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공사로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">인하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부득이한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;4.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">설비의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">장애</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">폭주</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정상적인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">어려운</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>22</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;">서비스</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;">이용</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;">자료의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;">보관</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스와</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관련하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;">서비스<span style="color:#776F67;">에</span></span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">저장한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자료는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원탈퇴</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">후</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;1</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">개월까지만</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">보관되며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">, &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;1</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">개월</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이후</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">임의로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해당</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자료를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">삭제할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;2. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">탈퇴신청</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이전에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">본</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">보관한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자료들을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">다운로드</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">받아</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">보관하여야</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:14px;font-family:굴림;color:#246AD1;">제</span
          ><span style='font-size:14px;font-family:"Nanum Gothic",serif;color:#246AD1;'>&nbsp;4&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:#246AD1;">장</span
          ><span style='font-size:14px;font-family:"Nanum Gothic",serif;color:#246AD1;'>&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:#246AD1;">이용요금</span>
        </p>
      </div>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>23</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">이용요금</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용요금은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원에게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부과되며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">그</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">방식은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사이트에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공지된</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">요금제에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의하게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">됩니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;(</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">단</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">협력회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">입찰</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">계약</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">건</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">별</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">요금제에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정해집니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.)<br />&nbsp;<br />&nbsp;2. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">필요한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용료를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">신설</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있으며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">신설</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">변경</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사항은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사이트</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공지합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.&nbsp;</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>24</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">요금의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">청구</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">건설사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">요금은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">분에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">요금을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기준으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">매월</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">말</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">세금계산서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;(</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전자세금계산서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">포함</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">)&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">납입</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">청구서를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발행하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">선납</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">방법으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">청구</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">하며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">, &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">결제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">계좌</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기타</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">결제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수단을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">송금될</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있도록</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">하며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">청구</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">익월</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;10</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일까지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">납부하여야</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">. (</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">단</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">년</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">납을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약정하고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해당</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">금액을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일시</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">납부할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">할인율을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">적용</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">받을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.)</span>
      </p>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;2.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">협력회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우에는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전자입찰</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">참여시</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사이트에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공지된</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">요금제에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">시점에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제시한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">결제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수단을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용료를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">납부해야</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.&nbsp;</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>25</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">요금의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">연체</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>,&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">미납</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:굴림;color:#776F67;">정당한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사유</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용요금의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">납부를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;1</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">개월</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이상</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">체납할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">시에는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">직권에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해당</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">/</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:14px;font-family:굴림;color:#246AD1;">제</span
          ><span style='font-size:14px;font-family:"Nanum Gothic",serif;color:#246AD1;'>&nbsp;5&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:#246AD1;">장</span
          ><span style='font-size:14px;font-family:"Nanum Gothic",serif;color:#246AD1;'>&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:#246AD1;">계약의</span
          ><span style='font-size:14px;font-family:"Nanum Gothic",serif;color:#246AD1;'>&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:#246AD1;">해지</span
          ><span style='font-size:14px;font-family:"Nanum Gothic",serif;color:#246AD1;'>&nbsp;</span
          ><span style="font-size:14px;font-family:굴림;color:#246AD1;">기타</span>
        </p>
      </div>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>26</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">서비스</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">이용</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">계약의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">해지</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용계약은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">언제나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해지될</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있으며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해지신청과</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해지처리로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">계약은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해지됩니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;2.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스이용</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">계약을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해지하고자</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">때에는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">본인이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">직접</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원탈퇴를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">신청하여야</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">탈퇴</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">신청</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">시</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">연체중인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용요금이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">확인한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">후</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">소정의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">확인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">절차를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">거쳐</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">최종적으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원탈퇴</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">처리함을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">원칙으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;3.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용계약이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">중도</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해지될</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해지일</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">까지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">미</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">청구된</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용요금에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">계산하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해지일을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기준으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">청구합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;4.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원탈퇴</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">신청의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">처리여부는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사이트의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">접속가능여부로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">확인할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>27</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">계약의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">일방</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">해지</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">본</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관에서</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">규정한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의무를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">다하지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">아니하였을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">, &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">소정의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기간</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">동안</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">그</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이행을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">최고하고</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">따른</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이행이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사전</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통지나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">동의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용계약을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;2. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">파산</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">화의신청</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부도처리</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사정리절차개시</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사유로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">채무의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이행이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">곤란하다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">인정되는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">즉시</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">본</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">계약을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">해지할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">있습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>28</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">양도</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">등의</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">금지</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">과</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">본</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용계약과</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관련하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">권리</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의무의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전부</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">일부를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">3</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자에게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">양도하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">담보로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>29</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">기밀유지</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">과</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">본</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">계약과</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관련하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">취득한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">상대방의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">영업상</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기술상의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">비밀이나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정보를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">3</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자에게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">누출하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">유출할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style="font-size:12px;font-family:굴림;color:black;">제</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>30</span
          ><span style="font-size:12px;font-family:굴림;color:black;">조</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;(</span
          ><span style="font-size:12px;font-family:굴림;color:black;">손해배상</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">및</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>&nbsp;</span
          ><span style="font-size:12px;font-family:굴림;color:black;">면책조항</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>)</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">규정을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">위반함으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">인하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">손해를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">입게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">되는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">그로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">인해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">다른</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">3</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">책임을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부담하게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">되는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">, &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">모든</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">손해를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">배상하여야</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">합니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;2.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">천재지변</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">준하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">불가항력으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">인하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우에는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">책임이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">면제됩니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;3. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자들의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">귀책사유로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">인한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">장애에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">책임을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">않습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;4. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기간통신</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사업자가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전기통신</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">중지하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정상적으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공하지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">아니하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">손해가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">책임이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">면제됩니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;5. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스용</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">설비의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">보수</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">교체</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정기점검</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">공사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부득이한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">사유로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">손해에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">책임이</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">면제됩니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;6. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">컴퓨터</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">오류에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">손해가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">책임을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">않습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;7.&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공으로부터</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">기대되는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이익을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">얻지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">못할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자료에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">취사선택</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">손익에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp; &nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">책임을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">않습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;8. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">3</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부가서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공사업자가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;">서비스를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">통해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제공하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자료의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">정확성</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">신뢰성</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내용에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">관하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">어떠한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">보증도</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">하지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">아니하며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자료에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">취사선택</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">또는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용으로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생하는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">손해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">등에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">책임을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">않습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;9. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이용자인</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">상호간</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">및</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">과</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">제</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">3</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">자</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">상호</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">간에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">서비스를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">매개로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">분쟁에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">개입할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의무가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없으며</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">인한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">손해를</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">배상할</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">책임도</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">없습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.<br />&nbsp;<br />&nbsp;10. &#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">과실로</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">전자</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">문서의</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">내용을</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">잘못</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">작성하거나</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">,&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">확인하여</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회원</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">에게</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">손해가</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">발생한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">경우</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">회사</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&#39;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">는</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">이에</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">대해</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;"><br />&nbsp;&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">어떠한</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">책임도</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">지지</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">않습니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
      <div
        style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:36.0pt;text-align:justify;line-height:115%;font-size:13px;font-family:"맑은 고딕";border:none;border-bottom:dotted #CCCCCC 1.0pt;padding:0cm 0cm 0cm 0cm;'
      >
        <p
          style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:22.5pt;font-size:13px;font-family:"맑은 고딕";border:none;padding:0cm;'
        >
          <span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>[</span
          ><span style="font-size:12px;font-family:굴림;color:black;">부칙</span
          ><span style='font-size:12px;font-family:"Nanum Gothic",serif;color:black;'>]</span>
        </p>
      </div>

      <p
        style='margin-top:7.5pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:36.0pt;text-align:left;line-height:normal;font-size:13px;font-family:"맑은 고딕";'
      >
        <span style="font-size:12px;font-family:돋움;color:#776F67;">1. (</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">시행일</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">)&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">본</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">약관은</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;2023</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">년</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;08</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">월</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;09일</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">부터</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">&nbsp;</span
        ><span style="font-size:12px;font-family:굴림;color:#776F67;">시행됩니다</span
        ><span style="font-size:12px;font-family:돋움;color:#776F67;">.</span>
      </p>
    </div>
    <div class="agree_area">
      <div class="content" v-if="type !== 'mobile'">
        <input type="checkbox" id="c2" v-model="agree2" @change="onAgree" />
        <label for="c2">
          <span></span>
          TOPERP 서비스 개인 정보 수집 및 이용에 대해 동의합니다.
        </label>
      </div>
    </div>
    <!-- 서비스 이용약관 동의 end -->
    <div class="agree_area" v-if="type !== 'mobile'">
      <div class="title_all">
        <input type="checkbox" id="c3" v-model="agreeAll" @change="onAgreeAll" /><label for="c3"
          ><span></span>모두 동의합니다.</label
        >
      </div>
    </div>
    <div class="btn_area" v-if="type !== 'mobile'">
      <button type="button" class="btn_default" @click="goLogin">취소</button>
      <button type="button" class="btn_confirm ml10" @click="onNextStep">확인</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      agree1: false,
      agree2: false,
      agreeAll: false,
    };
  },
  methods: {
    onAgree() {
      if (this.agree1 == true && this.agree2 == true) {
        this.agreeAll = true;
      } else {
        this.agreeAll = false;
      }
    },
    onAgreeAll() {
      if (this.agreeAll) {
        this.agree1 = true;
        this.agree2 = true;
      } else {
        this.agree1 = false;
        this.agree2 = false;
      }
    },
    onNextStep() {
      if (!this.agree1) {
        alert('개인정보 취급방침에 동의해주세요.');
        return;
      }
      if (this.agree1 && !this.agree2) {
        alert('서비스 이용약관에 동의해주세요.');
        return;
      }
      this.$parent.nextStep('02');
    },
    goLogin() {
      this.$router.push({path: '/loginHome', params: {}});
    },
  },
};
</script>

<style scoped>
div.terms_box {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
</style>
