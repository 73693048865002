<template>
  <iui-container-new type="css-flex" flex-direction="row">
    <i-col>
      <i-row>
        <pmis-content-box>
          <template v-slot:title>복사일위대가</template>
          <template v-slot:header-left
            ><iui-text :value.sync="searchText1" @enter="onSearch1"/><iui-button value="검색" @click="onSearch1"
          /></template>
          <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData1" @loadSheet="loadSheet1" />
        </pmis-content-box>
      </i-row>
      <i-spacer />
      <i-row>
        <pmis-content-box>
          <template v-slot:title>복사대상일위대가</template>
          <template v-slot:header-left
            ><iui-text :value.sync="searchText2" @enter="onSearch2"/><iui-button value="검색" @click="onSearch2"
          /></template>
          <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData2" @loadSheet="loadSheet2" />
        </pmis-content-box>
      </i-row>
    </i-col>
  </iui-container-new>
</template>

<script>
import options from './sheetOption/BreakDownCostCopyPopSheet.js';
import {selectResourceCopyList} from './api/ResrceManagePop.js';
export default {
  props: {
    prj: {
      Type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sheet1: undefined,
      sheet2: undefined,
      Options: options(this),
      loadSearchData1: [],
      loadSearchData2: [],
      searchText1: '',
      searchText2: '',
      searchTarget: '',
    };
  },

  beforeCreate() {
    $mapGetters(this, {detailInfo: 'detailInfo'});
  },

  created() {
    this.addEvent({name: 'BreakDownCostCopyPop_popConfirm', func: this.onPopConfirm});
    if (this.isFormula) {
      for (let key in this.inputData) {
        if (this.detailInfoDtls && this.detailInfoDtls.hasOwnProperty(key)) {
          this.inputData[key] = this.detailInfoDtls[key];
        }
      }
    }
    this.onSearch();
  },

  mounted() {},
  methods: {
    loadSheet1(sheet) {
      this.sheet1 = sheet;
    },
    loadSheet2(sheet) {
      this.sheet2 = sheet;
    },

    async onSearch() {
      let param = {
        costType: $getConstants('COST_TYPE_I').code,
        searchText: this.searchTarget === '1' ? this.searchText1 : this.searchText2,
      };
      const response = await selectResourceCopyList(param);
      response.data.forEach(item => {
        item.itemCode = item.itemCode2;
      });
      if (this.searchTarget === '1') {
        this.loadSearchData1 = response.data;
        return;
      }
      if (this.searchTarget === '2') {
        this.loadSearchData2 = response.data;
        return;
      }
      this.loadSearchData1 = response.data;
      this.loadSearchData2 = response.data;
    },
    onSearch1() {
      this.searchTarget = '1';
      this.onSearch();
    },
    onSearch2() {
      this.searchTarget = '2';
      this.onSearch();
    },
    onPopConfirm(param) {
      const data = {
        copyCode: this.sheet1.getFocusedRow().itemCode,
        targetCode: this.sheet2.getFocusedRow().itemCode,
      };

      param(data);
    },
  },
};
</script>

<style></style>
