<template>
  <div :style="!modal ? 'height:100%' : ''">
    <div v-if="modal">
      <iui-button @click="openModal" :value="modalBtnNm" v-if="!hidden" :enable="modalBtnEnable"></iui-button>
      <iui-modal
        :name="`fileModal${_uid}`"
        :title="modalTitle"
        width="700px"
        height="400px"
        @opened="beforeOpen"
        @beforeClose="beforeClose"
        @closed="closed"
      >
        <iui-content-box :loading="internalAction.search" :isHeader="titleVisible || toolbarVisible">
          <template #[dynamicSlotTitleName] v-if="titleVisible && title">{{ title }}</template>
          <template #title-left v-if="titleVisible && title"></template>
          <template #[dynamicSlotTitleOrHeaderName]>
            <template v-if="toolbarVisible">
              <iui-button v-if="allowAdd && isAuth" :enable="buttonEnable" @click="btnAdd_click">
                {{ addButtonText }}
              </iui-button>
              <iui-button
                v-if="allowDelete && isAuth && (fileNumber != 0 || loadSearchData.length > 0)"
                :enable="buttonEnable"
                @click="btnDelete_click"
              >
                {{ deleteButtonText }}
              </iui-button>
              <template v-if="fileNumber != 0">
                <iui-button @click="onDownload">다운로드</iui-button>
              </template>
              <template v-if="allowAttachDoc && isAuth">
                <iui-button @click="showAttachDocPop">문서첨부</iui-button>
              </template>
            </template>
          </template>

          <ib-sheet
            :uid="_uid"
            :options="sheetOpt"
            :loadSearchData="loadSearchData"
            @loadSheet="sheet_onLoadSheet"
            :events="{
              onSearchFinish: sheet_onSearchFinish,
              onMouseOver: sheet_onMouseOver,
              onMouseMove: sheet_onMouseMove,
            }"
          />
        </iui-content-box>
      </iui-modal>
    </div>
    <iui-content-box :loading="internalAction.search" v-else>
      <template #[dynamicSlotTitleName] v-if="titleVisible && title">{{ title }}</template>
      <template #title-left v-if="titleVisible && title"></template>
      <template #[dynamicSlotTitleOrHeaderName]>
        <template v-if="toolbarVisible">
          <iui-button v-if="allowAdd && isAuth" :enable="buttonEnable" @click="btnAdd_click"
            >{{ addButtonText }}
          </iui-button>
          <iui-button v-if="allowDelete && isAuth && fileNumber != 0" :enable="buttonEnable" @click="btnDelete_click">
            {{ deleteButtonText }}
          </iui-button>
        </template>
        <template v-if="fileNumber != 0">
          <iui-button @click="onDownload">다운로드</iui-button>
        </template>
      </template>

      <ib-sheet
        :uid="_uid"
        :options="sheetOpt"
        :loadSearchData="loadSearchData"
        @loadSheet="sheet_onLoadSheet"
        :events="{
          onSearchFinish: sheet_onSearchFinish,
          onMouseOver: sheet_onMouseOver,
          onMouseMove: sheet_onMouseMove,
        }"
      />
    </iui-content-box>

    <viewer :images="imageInfo.images" :options="viewerOptions" @inited="inited" v-show="false">
      <img v-for="(image, index) in imageInfo.images" :src="image" :key="index" v-show="false" />
    </viewer>
    <file-upload
      :headers="postHeader"
      :post-action="internalPostAction"
      ref="upload"
      v-model="internalFiles"
      name="files"
      :drop="allowAdd && allowDrop"
      :multiple="multiple"
      @input="fileUpload_input"
      @input-filter="fileUpload_inputFilter"
      @input-file="fileUpload_inputFile"
      v-show="false"
    />
    <iui-modal name="AttachDocPop" title="문서선택" :btns="attachDocPopBtns" size-type="size2">
      <AttachDocPop />
    </iui-modal>
  </div>
</template>

<script>
// https://www.npmjs.com/package/vue-upload-component
import FileUpload from 'vue-upload-component';
import AttachDocPop from '@/view/approval/draft/components/AttachDocPop.vue';
import {getRefreshToken} from '@/common';
import {mapGetters} from 'vuex';

import sheetOpt from './sheetOptions/pmisFileListSheet.js';

import FILE_CONSTATNS from '@/components/js/fileConstatns.js';

// 최대파일크기
const MAX_FILE_SIZE_MB = 50; // 50MB
const MAX_FILE_SIZE_BYTE = MAX_FILE_SIZE_MB * 2 ** 20;
export const PMIS_FILE_LIST_DEFAULT_COLUMNS = [
  {
    Name: 'lclFlNmTemp',
    Header: '파일명',
    Type: 'Html',
    CanEdit: false,
    RelWidth: 1,
  },
  {
    Name: 'lclFlNm',
    Header: '파일명',
    Type: 'Text',
    CanEdit: false,
    Visible: 0,
    RelWidth: 1,
  },
  {
    Name: 'flSz',
    Header: '크기(byte)',
    Type: 'Text',
    CanEdit: false,
    Width: 100,
    Align: 'right',
    CustomFormat: v => (isNaN(v) ? v : v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')),
  },
];
export default {
  name: 'pmis-file-list',
  components: {
    FileUpload,
    AttachDocPop,
  },
  model: {
    prop: 'files',
    event: 'update:files',
  },
  props: {
    companyCode: {
      type: [String, Number],
    },
    fileNumber: {
      required: true,
      type: Number,
      default: 0,
    },
    fbsNo: {
      type: [String, Number],
      default: undefined,
    },
    projectCode: {
      type: [String, Number],
      default: undefined,
    },
    files: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: undefined,
    },
    titleVisible: {
      type: Boolean,
      default: true,
    },
    toolbarVisible: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: undefined,
    },
    required: {
      type: [Boolean, String, Number],
      default: undefined,
    },
    allowAdd: {
      type: Boolean,
      default: true,
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
    allowDrop: {
      type: Boolean,
      default: true,
    },
    // button
    addButtonText: {
      type: String,
      default: '추가',
    },
    deleteButtonText: {
      type: String,
      default: '삭제',
    },
    saveButtonText: {
      type: String,
      default: '저장',
    },
    saveButtonVisible: {
      type: Boolean,
      default: true,
    },
    // action
    changed: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    add: {
      type: Boolean,
      default: false,
    },
    delete: {
      type: Boolean,
      default: false,
    },
    save: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => {
        return PMIS_FILE_LIST_DEFAULT_COLUMNS;
      },
    },
    searchData: {
      type: [Array, Object],
      default() {
        return {
          notFlDsList: [
            $getConstants('FL_DS_DOC').code,
            $getConstants('FL_DS_TMP').code,
            $getConstants('FL_DS_APP').code,
            $getConstants('FL_DS_THUMBNAIL').code,
          ],
        };
      },
    },
    temp: {
      type: Boolean,
      default: true,
    },
    saveAuth: {
      type: Boolean,
      default: undefined,
    },
    id: {
      required: true,
      type: [String, Number],
    },
    modal: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: '첨부파일',
    },
    modalBtnNm: {
      type: String,
      default: '첨부파일',
    },
    flNoCol: {
      type: String,
      default: 'flNo',
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    modalBtnEnable: {
      type: Boolean,
      default: true,
    },
    copyFiles: {
      type: Array,
      default: () => [],
    },
    regExpType: {
      type: String,
      default: undefined,
    },
    allowAttachDoc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FILE_URL:
        location.protocol.indexOf('https') !== -1 ? process.env.VUE_APP_FILE_URLS : process.env.VUE_APP_FILE_URL,
      sheetOpt: sheetOpt(this),
      loadSearchData: [],
      token: this.$store.getters['jwt/accessToken'],
      internalFileNumber: 0,
      internalFiles: [],
      originFlNoSize: 0, // 원래 파일크기 합
      internalAction: {
        search: false,
        add: false,
        delete: false,
        save: false,
        clear: false,
      },
      dynamicSlotTitleName: '',
      dynamicSlotTitleOrHeaderName: '',
      viewerOptions: {
        title: true,
        movable: false,
        loop: false,
        initialViewIndex: 0,
        fullscreen: false,
        // backdrop: false
      },
      imageInfo: {
        images: [],
        titles: [],
        indexs: {},
      },
      interface: {},
      modalOpen: false,
      updateInfo: {},
      multiple: true,
      savingStatus: false,
      regExp: undefined,

      attachDocPopBtns: [{name: '확인', callback: this.closeAttachDocPop}],
    };
  },
  computed: {
    ...mapGetters('mdi', ['currentUrls']),
    internalPgmCd() {
      return this.companyCode || this.pgmCd;
    },
    internalPostAction() {
      return `${this.FILE_URL}/file/saveFile`;
    },
    buttonEnable() {
      return (
        !this.internalAction.search &&
        !this.internalAction.add &&
        !this.internalAction.delete &&
        !this.internalAction.save &&
        !this.internalAction.clear
      );
    },
    postHeader: {
      get: function() {
        return {Authorization: this.token};
      },
      set: function(token) {
        this.token = token;
      },
    },
    headerBoxVisible() {
      return this.allowAdd || this.allowDelete || this.saveButtonVisible;
    },
    isAuth() {
      return this.saveAuth !== undefined ? this.saveAuth : this.currentUrls.userSvYn == $getConstants('Y').code;
    },
    isUpdate() {
      return this.internalFileNumber && Object.keys(this.updateInfo).length ? true : false;
    },
  },
  watch: {
    fileNumber(newValue, oldValue) {
      let nValue = newValue || 0;
      if (nValue !== oldValue) {
        this.internalFileNumber = nValue || 0;

        if (this.modal) {
          this.loadSearchData = [];
        } else {
          this._search();
        }
      }
    },
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalAction.search = newValue;
        if (newValue) {
          this._search();
        }
      }
    },
    add(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalAction.add = newValue;
        if (newValue) {
          this.btnAdd_click();
        }
      }
    },
    delete(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalAction.delete = newValue;
        if (newValue) {
          this.btnDelete_click();
        }
      }
    },
    save(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalAction.save = newValue;
        if (newValue) {
          this.btnSave_click();
        }
      }
    },
    clear(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalAction.clear = newValue;
        if (newValue) {
          this._clear();
        }
      }
    },
    internalAction: {
      handler(newValue, oldValue) {
        if (newValue.save != oldValue.save) {
          this.$emit('update:save', newValue.save);
        }
      },
      deep: true,
    },
    copyFiles: {
      handler(v) {
        if (v.length > 0) {
          this.loadSearchData = v;
        }
      },
      deep: true,
    },
  },
  created() {
    this.$addFileComponent(this);
    this.initInterface();
    this.internalFileNumber = this.fileNumber;
    this.$on('update:search', e => {
      this.internalAction.search = e;
    });
    this.$on('update:add', e => {
      this.internalAction.add = e;
    });
    this.$on('update:delete', e => {
      this.internalAction.delete = e;
    });
    this.$on('update:save', e => {
      this.internalAction.save = e;
    });
    this.$on('update:clear', e => {
      this.internalAction.clear = e;
    });
    this.dynamicSlotTitleName = this.theme == 'bullet' ? `title-bullet${this.required ? '-required' : ''}` : 'title';
    this.dynamicSlotTitleOrHeaderName = this.titleVisible ? 'title-right' : this.headerBoxVisible ? 'header-right' : '';
    this.fileType();
  },
  destroyed() {
    this.$removeFileComponent(this);
  },
  methods: {
    initInterface() {
      this.interface.files = [];
      this.interface.flNo = 0;
      this.interface.error = '';
      this.interface.progressInfo = {};
      this.interface.currentId = '';
      this.interface.save = this.saveProc;
    },
    openModal() {
      this.$modal.show(`fileModal${this._uid}`);
    },
    beforeOpen() {
      this.modalOpen = true;
    },
    beforeClose() {
      const tmpSheetRows = [];
      const cols = [...this.sheet.getCols(), 'Added', 'id', 'file'];
      this.sheet.getDataRows().forEach(row => {
        const tmpRow = {};
        cols.forEach(col => {
          if (row[col] !== undefined) {
            tmpRow[col] = row[col];
          }
        });
        tmpSheetRows.push(tmpRow);
      });
      this.modalOpen = false;
      this.loadSearchData = tmpSheetRows;
    },
    closed() {
      this.$emit('fileModal_closed');
    },
    getImageUrl(flNo, revId) {
      return `${this.FILE_URL}/file/getImage?pgmCd=${this.internalPgmCd}&flNo=${flNo}&revId=${revId}`;
    },
    getFileDownUrl(flNo, revId) {
      return `${this.FILE_URL}/file/download?pgmCd=${this.internalPgmCd}&flNo=${flNo}&revId=${revId}`;
    },
    async _search(visibleTemp) {
      await this.$nextTick();
      this.$refs.upload.clear();
      if (this.internalFileNumber === 0) {
        this._clear();
        return;
      }
      const data = {
        pgmCd: this.internalPgmCd,
        flNo: this.internalFileNumber,
        ...this.searchData,
        visibleTemp: visibleTemp,
      };
      try {
        this.internalAction.search = true;
        const response = await axios.post('/file/selectFileList', data);
        let searchFiles = Array.from(response.data);
        if (!searchFiles.length) {
          this.internalFileNumber = 0;
          this.$emit('update:fileNumber', 0);
        }
        searchFiles.forEach(row => {
          if (row.svrFlPth) row.uploadStatus = '1';
        });
        this.loadSearchData = searchFiles;
        this.sheet.acceptChangedData();
      } catch (ex) {
        console.error(ex);
      }
      this.$emit('update:changed', false);
      this.$emit('update:files', this.sheet.getSaveJson(0).data);
      this.$emit('update:search', false);
      this.initInterface();
    },
    btnAdd_click(e) {
      this.sheet.setAllCheck('cbx', 0);
      if (!this.isAuth) false;
      if (e !== undefined) {
        this.internalAction.add = true;
      }
      this.multiple = true;
      setTimeout(() => {
        this.$refs.upload.$children[0].$el.click();
        this.$emit('update:add', false);
      }, 10);
    },
    async btnDelete_click(e) {
      if (!this.isAuth) false;
      if (e !== undefined) {
        this.internalAction.delete = true;
      }
      let rows = this.sheet.getRowsByChecked('cbx');
      if (rows.length == 0) {
        await this.$alert({title: '삭제', message: '선택된 항목이 없습니다.'});
        this.$emit('update:delete', false);
        return;
      }
      if (!(await this.$confirm({title: '삭제', message: '선택한 파일을 삭제하시겠습니까?'}))) {
        this.$emit('update:delete', false);
        return;
      }
      const deleteRows = [];
      rows.forEach(row => {
        if (row.Added || !row.flNo) {
          this.sheet.removeRow(row);
        } else {
          deleteRows.push({
            pgmCd: row.pgmCd,
            flNo: row.flNo,
            grpNo: row.grpNo,
          });
        }
        delete this.interface.progressInfo[`${this._uid}_${row.id}`];
      });
      if (deleteRows.length) await this.deleteFile(deleteRows);
      this._delete();
    },
    async onDelete(row) {
      if (!(await this.$confirm({title: '삭제', message: '선택한 파일을 삭제하시겠습니까?'}))) {
        this.$emit('update:delete', false);
        return;
      }
      if (row !== undefined) {
        this.internalAction.delete = true;
      }
      const deleteRows = [];
      deleteRows.push({
        pgmCd: row.pgmCd,
        flNo: row.flNo,
        grpNo: row.grpNo,
      });
      if (deleteRows.length) await this.deleteFile(deleteRows);
      this._delete();
    },
    saveProc() {
      if (!this.interface.state) this.interface.state = () => {};
      if (!this.sheet) {
        this.interface.state(0);
        return;
      }
      if (!this.isAuth) return;
      this.$emit('save-start');
      let newRows = [];
      if (this.modal && !!this.loadSearchData.length) {
        newRows = this.loadSearchData.filter(row => row.Added && !row.attachDocFlag);
      }
      if ((this.modal && this.modalOpen) || !this.modal) {
        newRows = [...newRows, ...Array.from(this.sheet.getRowsByStatus('Added'))];
      }
      if (newRows.length > 0) {
        this.interface.currentId = `${this._uid}_${newRows[0].id}`;
        this.$emit('upload-start', newRows[0].file.name);
        this.$refs.upload.update(newRows[0].file, {active: true, error: '', progress: '0.00'});
        this.$emit('update:save', false);
        this.$emit('update:changed', false);
        this.$emit('update:files', this.sheet.getSaveJson(0).data);
        this.$emit('save-complete');
      } else {
        this.interface.state(0);
        this.$emit('update:save', false);
      }
    },
    btnSave_click(e) {
      if (e !== undefined) {
        this.internalAction.save = true;
      }
      this.saveProc();
    },
    async deleteFile(deleteRows) {
      if (!this.isAuth) false;
      let r = true;
      if (deleteRows.length > 0) {
        this.$emit('delete-start');
        try {
          await axios.post('/file/deleteFile', deleteRows);
          await this._search();
          if (this.internalAction.delete) {
            this.$emit('delete-complete', this.internalFileNumber);
          }
        } catch (ex) {
          this.$emit('delete-error');
          r = false;
        }
      }
      return r;
    },
    _delete() {
      this.$emit('update:delete', false);
    },
    _clear() {
      this.loadSearchData = this.copyFiles ? this.copyFiles : [];
      this.$emit('update:files', []);
      this.$emit('update:fileNumber', 0);
      this.$emit('update:clear', false);
    },
    sheet_onLoadSheet(sheet) {
      this.sheet = sheet;
      if (!this.loadSearchData.length) {
        this._search();
      } else {
        this.internalAction.search = false;
      }
    },
    sheet_onSearchFinish(e) {
      this.imageInfo = {
        images: [],
        titles: [],
        indexs: {},
      };
      let index = 0;
      this.originFlNoSize = 0;
      e.sheet.getDataRows().forEach(row => {
        this.originFlNoSize += row.flSz;
        e.sheet.setValue(row, 'allowAdd', row.allowAdd === undefined ? 1 : row.allowAdd, 1);
        e.sheet.setValue(row, 'allowDelete', row.allowDelete === undefined ? 1 : row.allowDelete, 1);
        if (row.flNo) {
          const ext = row.lclFlNm.substr(row.lclFlNm.lastIndexOf('.') + 1);
          const mineType = $getMineType(ext);
          if (mineType.indexOf('image') > -1) {
            this.imageInfo.images.push(this.getImageUrl(row.flNo, row.revId));
            this.imageInfo.titles.push(row.lclFlNm);
            this.imageInfo.indexs[row.id] = index++;
            this.sheet.setAttribute(row, 'lclFlNmTemp', 'Cursor', 'pointer');
          } else if (mineType.indexOf('pdf') > -1) {
            this.sheet.setAttribute(row, 'lclFlNmTemp', 'Cursor', 'pointer');
          }
          e.sheet.setValue(row, 'lclFlNmTemp', this.fileTemplate(row), 1);
        }
      });
      this.sheet.acceptChangedData();
      document.querySelectorAll('._file_view').forEach(el => {
        el.addEventListener(
          'click',
          e => {
            e.preventDefault();
            this.fileViewer(this.sheet.getRowById(e.target.dataset.id));
          },
          false
        );
      });
    },
    sheet_onMouseOver(e) {
      if (e.kind === 'Data' && e.col === 'lclFlNmTemp') {
        if (document.querySelector(`#file_${e.row.flNo}_${e.row.grpNo} > ._file_btns`)) return;
        if (document.querySelector(`#file_${e.row.flNo}_${e.row.grpNo}`)) {
          const downloadTemplate = `<button class="_down" data-id="${e.row.id}" data-fl-no="${e.row.flNo}" data-grp-no="${e.row.grpNo}">다운로드</button>`;
          const updateTemplate = `<button class="_update" data-id="${e.row.id}" data-fl-no="${e.row.flNo}" data-grp-no="${e.row.grpNo}">수정</button>`;
          const deleteTemplate = `<button class="_delete" data-id="${e.row.id}" data-fl-no="${e.row.flNo}" data-grp-no="${e.row.grpNo}">삭제</button>`;
          let template = `${downloadTemplate}`;
          if (this.toolbarVisible && this.isAuth) {
            if (this.allowAdd) template += updateTemplate;
            if (this.allowDelete) template += deleteTemplate;
          }
          const fileBtns = document.createElement('div');
          fileBtns.setAttribute('style', 'position:absolute;top:0;right:0');
          fileBtns.setAttribute('class', `_file_btns file_${e.row.flNo}_${e.row.grpNo}`);
          fileBtns.innerHTML = template;
          document.querySelector(`#file_${e.row.flNo}_${e.row.grpNo}`).appendChild(fileBtns);
        }
        if (document.querySelector(`._file_btns:not(.file_${e.row.flNo}_${e.row.grpNo})`)) {
          document.querySelector(`._file_btns:not(.file_${e.row.flNo}_${e.row.grpNo})`).remove();
        }
        if (document.querySelector('._file_btns > ._update')) {
          document.querySelector('._file_btns > ._update').addEventListener(
            'click',
            e => {
              e.stopPropagation();
              this.updateInfo = {
                id: e.target.dataset.id,
                flNo: e.target.dataset.flNo,
                grpNo: e.target.dataset.grpNo,
              };
              this.multiple = false;
              setTimeout(() => {
                this.$refs.upload.$children[0].$el.click();
              }, 10);
            },
            false
          );
          document.querySelector('._file_btns > ._down').addEventListener(
            'click',
            e => {
              e.stopPropagation();
              this.fileDownload(this.sheet.getRowById(e.target.dataset.id));
            },
            false
          );
          document.querySelector('._file_btns > ._delete').addEventListener(
            'click',
            e => {
              e.stopPropagation();
              this.onDelete(this.sheet.getRowById(e.target.dataset.id));
            },
            false
          );
        }
      } else {
        if (document.querySelector('._file_btns')) {
          document.querySelector('._file_btns').remove();
        }
      }
    },
    sheet_onMouseMove(e) {
      if (!(e.kind === 'Data' && e.col === 'lclFlNmTemp')) {
        if (document.querySelector('._file_btns')) {
          document.querySelector('._file_btns').remove();
        }
      }
    },
    /**
     * Event 순서 : inputFilter > input > inputFile
     *           ex) 파일 3건 추가 시
     *               - inputFilter 3회
     *               - input 1회
     *               - inputFile 3회
     */
    fileUpload_inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // type check
        if (this.fileExtensionVaild(newFile)) return;

        // Add file
        let preAddedFileSize = this.internalFiles.reduce((total, file) => total + file.size, 0);

        if (this.isUpdate) {
          preAddedFileSize -= this.sheet.getRowById(this.updateInfo.id).flSz;
        }

        if (this.originFlNoSize + preAddedFileSize + newFile.size > MAX_FILE_SIZE_BYTE) {
          this.$alert({title: '파일크기 제한', message: `총 ${MAX_FILE_SIZE_MB}MB 이하로 등록가능합니다.`});
          return prevent();
        }

        const data = {
          pgmCd: this.internalPgmCd,
          flNo: this.internalFileNumber,
          grpNo: this.isUpdate ? this.updateInfo.grpNo : 0,
          flDs: this.$store.state.code2.codeAlias.FLDS_NORMAL.code,
          fbsNo: this.fbsNo,
          prjCd: this.projectCode, // EDMS 관리를 위한 현장코드
          lclFlNm: newFile.name,
          lclFlNmTemp: newFile.name,
          flSz: newFile.size,
          allowAdd: 1,
          allowDelete: 1,
          temp: this.isUpdate ? false : this.temp,
          tempFile: this.isUpdate ? '' : null,
          updateStatus: this.isUpdate,
        };
        newFile.data = data;

        const focusedRow = this.isUpdate ? this.sheet.getRowById(this.updateInfo.id) : null;

        if (focusedRow) this.sheet.setAttribute(focusedRow, null, 'Visible', 0);

        let row = this.sheet.addRow({
          next: focusedRow,
          init: data,
        });

        row.file = newFile;
        newFile.rowIndex = this.sheet.getRowIndex(row);
        newFile.rowId = row.id;
        newFile.data.seq = this.sheet.getRowsByStatus('Added,Changed').length;
        if (this.allowAttachDoc) {
          newFile.data.grpNo = parseInt(row.id.replace('AR', ''));
        }
        this.interface.progressInfo[`${this._uid}_${newFile.rowId}`] = {
          progress: 0.0,
          name: newFile.name,
          size: newFile.size,
          speed: newFile.speed,
        };

        this.updateInfo = {};
      }
      if (newFile && oldFile) {
        // Update file
      }
      if (!newFile && oldFile) {
        // Remove file
        // Refused to remove the file
        // return prevent()
      }
    },
    fileUpload_input() {
      this.$emit('update:changed', true);
      this.$emit('update:files', this.sheet.getSaveJson(0).data);
    },
    async fileUpload_inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // Add file

        // 파일 단건 변경 시, 자동 업로드
        if (this.internalFiles.length === 1 && this.internalFiles[0].data.updateStatus) {
          this.savingStatus = true;

          this.$fileSave()
            .then(() => {
              this.savingStatus = false;
            })
            .catch(ex => {
              this.savingStatus = false;
              if (ex.indexOf('"pmisError":true') > -1) {
                let exObj = JSON.parse(ex);
                if (exObj.code == 'FILE_OVER_CAPACITY') {
                  // 서버에서 단일 파일 용량초과 확인 결과
                  this.$alert({title: '파일저장 실패', message: exObj.description});
                  this.sheet.removeRows(this.sheet.getRowsByStatus('Added'));
                }
              }
            });
        }
      }
      if (newFile && oldFile) {
        // Update file
        if (newFile.xhr) {
          if (!(newFile.xhr.status == 401 && newFile.xhr.response.code.indexOf('ExpiredJwtException') != -1)) {
            if (
              (String(newFile.xhr.status).split(0, 1) != '2' ||
                newFile.xhr.response.indexOf('"pmisError":true') > -1) &&
              newFile.xhr.response
            ) {
              this.interface.error = newFile.xhr.response;
              this.interface.state(-1);
              this.$emit('update:save', false);
              this.$emit('upload-error', newFile.name);
              this.$emit('save-error');
            }
          }
        }
        if (newFile.active && !oldFile.active) {
          // beforeSend
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
          this.interface.progressInfo[`${this._uid}_${newFile.rowId}`].progress = newFile.progress;
          this.interface.progressInfo[`${this._uid}_${newFile.rowId}`].speed = newFile.speed;
          this.interface.state(1);
          if (!this.modal) {
            let row = this.sheet.getRowById(newFile.rowId);
            this.sheet.setValue(row, 'uploadStatus', '0', false); // 진행중
            this.sheet.refreshRow(row);
          }
        }
        if (newFile.error && !oldFile.error) {
          if (newFile.error == 'denied' && newFile.response.code.indexOf('ExpiredJwtException') != -1) {
            this.postHeader = await getRefreshToken();
            newFile.headers.Authorization = this.postHeader.Authorization;
            this.$refs.upload.update(newFile, {active: true, error: '', progress: '0.00'});
          } else {
            // error
            this.savingStatus = false;
            this.interface.state(-1);
            this.interface.error = newFile.error;
            if (!this.modal) {
              let row = this.sheet.getRowById(newFile.rowId);
              this.sheet.setValue(row, 'uploadStatus', '9', false); // 실패
              this.sheet.refreshRow(row);
            }
            this.$emit('update:save', false);
            this.$emit('upload-error', newFile.name);
            this.$emit('update:fileNumber', this.internalFileNumber);
            this.$emit('save-error');
          }
        }
        if (newFile.success && !oldFile.success) {
          // success
          if ((this.modal && this.modalOpen) || !this.modal) {
            let row = this.sheet.getRowById(newFile.rowId);
            let oldFileNumber = this.sheet.getValue(row, 'flNo');
            if (oldFileNumber === 0) {
              this.sheet.setValue(row, 'flNo', newFile.response.flNo, false);
            }
            this.sheet.setValue(row, 'grpNo', newFile.response.grpNo, false);
            this.sheet.setValue(row, 'flDs', newFile.response.flDs, false);
            this.sheet.setValue(row, 'revId', newFile.response.revId, false);
            this.sheet.setValue(row, 'verNo', newFile.response.verNo, false);
            this.sheet.setValue(row, 'uploadStatus', '1', false); // 완료
            this.sheet.acceptChangedData(row);
            this.sheet.refreshRow(row);
          }
          let newRows;
          if (this.modal && !!this.loadSearchData.length) {
            this.loadSearchData.forEach(row => {
              if (row.id == newFile.rowId) {
                if (this.internalFileNumber === 0) {
                  this.internalFileNumber = newFile.response.flNo;
                }
                row.flNo = this.internalFileNumber;
                delete row.Added;
              }
              if (row.file) {
                row.file.data.flNo = this.internalFileNumber;
              }
            });
            newRows = this.loadSearchData.filter(row => row.Added && !row.attachDocFlag);
          } else {
            newRows = this.sheet.getRowsByStatus('Added').filter(row => !row.attachDocFlag);
          }
          if ((this.modal && this.modalOpen) || !this.modal) {
            // 파일번호가 초기값이면 업로드 후 생성된 파일번호를 신규 데이터에 설정한다.
            if (this.internalFileNumber === 0) {
              this.internalFileNumber = newFile.response.flNo;
              newRows.forEach(row => {
                this.sheet.setValue(row, 'flNo', this.internalFileNumber, false);
                row.file.data.flNo = this.internalFileNumber;
                this.sheet.refreshRow(row);
              });
            }
          }
          this.$emit('upload-complete', newFile.name);
          this.$refs.upload.remove(newFile);
          this.interface.state(2);
          if (newRows.length > 0) {
            this.$emit('upload-start', newRows[0].file.name);
            this.$refs.upload.update(newRows[0].file, {active: true, error: '', progress: '0.00'});
            this.interface.currentId = `${this._uid}_${newRows[0].id}`;
          } else {
            if (this.modal) {
              this.interface.files = this.loadSearchData;
            } else {
              this.interface.files = this.sheet.getSaveJson(0).data;
            }
            this.interface.flNo = this.internalFileNumber;
            this.savingStatus = false;
            this.interface.state(3);
            this.$emit('upload-all-complete');
            this.$emit('update:save', false);
            this.$emit('update:changed', false);
            this.$emit('update:files', this.sheet.getSaveJson(0).data);
            this.$emit('update:fileNumber', this.internalFileNumber);
            this.$emit('save-complete', this.internalFileNumber || 0);
            this._search(true);
          }
        }
      }
      if (!newFile && oldFile) {
        // Remove file
      }
    },
    async fileViewer(row) {
      if (row.flNo) {
        const ext = row.lclFlNm.substr(row.lclFlNm.lastIndexOf('.') + 1);
        if ($getMineType(ext).indexOf('image') > -1) {
          this.$viewer.view(this.imageInfo.indexs[row.id]);
        } else if ($getMineType(ext).indexOf('pdf') > -1) {
          this.callEvent({
            name: 'showPdf',
            param: {src: this.getImageUrl(row.flNo, row.revId), fileName: row.lclFlNm},
            isDefault: true,
          });
        }
      }
    },
    async fileDownload(row) {
      const ext = row.lclFlNm.substr(row.lclFlNm.lastIndexOf('.') + 1);
      let response = await axios.get(this.getFileDownUrl(row.flNo, row.revId), {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data], {type: $getMineType(ext)}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', row.lclFlNm);
      document.body.appendChild(link);
      link.click();
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    async onDownload() {
      let rows = this.sheet.getRowsByChecked('cbx');
      if (rows.length == 0) {
        await this.$alert({title: '다운로드', message: '선택된 항목이 없습니다.'});
        return;
      }
      if (rows.length == 1) {
        this.fileDownload(rows[0]);
      } else {
        let flNo;
        let param = {};
        let revIdList = [];
        const zipName = (this.title ? this.title + '_' : '') + new Date().getTime() + '.zip';
        rows.forEach(row => {
          if (row.uploadStatus == '1') {
            flNo = row.flNo;
            revIdList.push(String(row.revId));
          }
        });
        param.pgmCd = this.internalPgmCd;
        param.flNo = flNo;
        param.revIdList = revIdList;
        param.zipName = zipName;
        let response = await axios.post(`/file/zipDownload`, param, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data], {type: $getMineType('zip')}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', zipName);
        document.body.appendChild(link);
        link.click();
        this.sheet.setAllCheck('cbx', 0);
      }
    },
    showAttachDocPop() {
      this.$modal.show('AttachDocPop');
    },
    closeAttachDocPop() {
      this.callEvent({
        name: 'attachDocList',
        param: param => {
          param.forEach(attachDoc => {
            if (!this.files.some(file => file.revId == attachDoc.revId)) {
              let data = {
                pgmCd: this.internalPgmCd,
                flNo: this.internalFileNumber,
                grpNo: this.isUpdate ? this.updateInfo.grpNo : 0,
                flDs: this.$store.state.code2.codeAlias.FLDS_REF.code,
                revId: attachDoc.revId,
                fbsNo: this.fbsNo,
                prjCd: this.projectCode, // EDMS 관리를 위한 현장코드
                lclFlNm: attachDoc.lclFlNm,
                lclFlNmTemp: attachDoc.lclFlNm,
                svrFlNm: attachDoc.svrFlNm,
                svrFlPth: attachDoc.svrFlPth,
                flSz: attachDoc.flSz,
                allowAdd: 1,
                allowDelete: 1,
                temp: this.temp,
                updateStatus: this.isUpdate,
                attachDocFlag: $getConstants('Y').code,
              };

              this.sheet.addRow({
                init: data,
              });

              this.updateInfo = {};
            } else {
              this.$alert({title: '문서첨부', message: '해당 문서는 첨부되어있습니다.'});
            }
          });
        },
      });
      this.$emit('update:files', this.sheet.getSaveJson(0).data);
      this.$modal.hide('AttachDocPop');
    },
    fileTemplate(row) {
      return `
            <div id="file_${row.flNo}_${row.grpNo}" class="_file_view" data-id="${row.id}" style="position:relative;">
              ${row.lclFlNm}
            </div>
            `;
    },
    fileType() {
      if (this.regExpType == 'image') {
        this.regExp = FILE_CONSTATNS.IMAGE_REGEXP;
      } else {
        this.regExp = undefined;
      }
    },
    fileExtensionVaild(newFile) {
      let fileLength = newFile.name.length;
      let fileDot = newFile.name.lastIndexOf('.');
      let fileExtension = newFile.name.substr(fileDot + 1, fileLength).toLowerCase();

      if (FILE_CONSTATNS.DEFAULT_REGEXP.test(fileExtension)) {
        this.$alert({title: '파일등록', message: '실행파일을 등록할 수 없습니다.'});
        return true;
      } else if (this.regExp && !this.regExp.test(fileExtension)) {
        this.$alert({title: '파일등록', message: `${fileExtension} 확장자는 등록할 수 없습니다.`});
        return true;
      }

      return false;
    },
  },
};
</script>
