export default that => {
  return {
    Cfg: {CanEdit: 0},
    Cols: [
      {Header: '자재코드', Name: 'itemCode', Align: 'center', Width: 160},
      {Header: '명칭', Name: 'itemName', RelWidth: 1},
      {Header: '규격', Name: 'size', RelWidth: 1},
      {Header: '단위', Name: 'unit', Align: 'center', Width: 80, Format: $getConstants('UNIT').code},
      {Header: '예산수량', Name: 'qty', Align: 'center', Extend: that.$ibsheetHelper.preset.quantity, Width: 120},
      {Header: '단가', Name: 'price', Align: 'center', Extend: that.$ibsheetHelper.preset.unitAmount, Width: 120},
      {Header: '금액', Name: 'amt', Align: 'center', Extend: that.$ibsheetHelper.preset.amount, Width: 120},

      {Name: 'chgDegree', Visible: false},
      {Name: 'prePrQty', Visible: false},
      {Name: 'preRfqQty', Visible: false},
      {Name: 'prePoQty', Visible: false},
    ],
  };
};
