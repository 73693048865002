<template>
  <ib-sheet
    :uid="_uid"
    :options="Options"
    :loadSearchData="listLoadSearchData"
    :events="{
      onClick: onClick,
      onSearchFinish: onSearchFinish,
    }"
    @loadSheet="loadSheet"
  />
</template>

<script>
import options from './sheetOptions/DetailListSheet.js';

export default {
  data() {
    return {
      sheet: undefined,
      sheetUtil: undefined,
      Options: $addColCheckbox(options(this)),
    };
  },

  beforeCreate() {
    $mapGetters(this, ['listLoadSearchData', 'focusItemCodeKey']);
    $mapMutations(this, ['setDetailInfo', 'initFocusItemCodeKey']);
  },

  created() {
    this.addEvent([{name: 'getRowsByCheckedFromBreakDown', func: this.getRowsByChecked}]);
    this.addEvent([{name: 'getRowsByCheckedReqFromBreakDown', func: this.getRowsByCheckedReq}]);
  },

  mounted() {},

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
    onClick(e) {
      if (e.kind !== 'Data') {
        return;
      }

      if (e.col !== 'chk') {
        this.sheet.setAllCheck('chk', 0, 1);
        this.sheet.setCheck(e.row, 'chk', 1);
      }
      this.cud = 0;
      const row = e.row;
      this.setDetailInfo(row);
      const code = row.sctmScode ?? row.sctmMcode;
      this.callEvent({name: 'searchInTree', param: code});
      this.callEvent({name: 'selectSystemSctmD'});
    },
    onSearchFinish(e) {
      let row;
      if (this.focusItemCodeKey) {
        this.sheetUtil.listSearch('itemCode', this.focusItemCodeKey, true);
        row = e.sheet.getDataRows().find(row => row.itemCode === this.focusItemCodeKey);
        this.initFocusItemCodeKey();
      } else {
        row = e.sheet.getFirstRow();
      }
      if (row) {
        this.setDetailInfo(row ?? {});
        this.callEvent({name: 'selectSystemSctmD'});
      }
      this.callEvent({name: 'initForm', param: 'inq'});
    },
    getRowsByChecked(callback) {
      callback(this.sheet.getRowsByChecked('chk'));
    },
    getRowsByCheckedReq(callback) {
      const rows = this.sheet.getRowsByChecked('chk');
      if (!rows.length) {
        this.sheet.setCheck(this.sheet.getFocusedRow(), 'chk', 1);
        rows.push(this.sheet.getFocusedRow());
      }
      callback(rows);
    },
  },
};
</script>

<style scoped></style>
