export default that => {
  return {
    Cfg: {CanEdit: false},
    Cols: [
      {Header: '현장명', Name: 'spotDeptnm', Type: 'Text', Width: 230},
      {
        Header: '요청번호',
        Name: 'prNo',
        Type: 'Text',
        Align: 'center',
        Width: 130,
        CustomFormat: v => `${v.substr(8, 8)}-${v.substr(16)}`,
        TextColor: '#FF0000',
        TextStyle: 5,
        Cursor: 'pointer',
      },
      {Header: '요청제목', Name: 'ttl', Type: 'Text', RelWidth: 1, MinWidth: 150},
      {Header: '납품요구일자', Name: 'dlvReqDt', Type: 'Text', Align: 'center', Width: 100},
      {Header: '요청일자', Name: 'prDate', Type: 'Text', Align: 'center', Width: 100},
      {Header: '구매요청자', Name: 'chrgUsrnm', Type: 'Text', Align: 'center', Width: 100},
    ],
  };
};
