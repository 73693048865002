<template>
  <iui-container-new type="ibsheet" :id="elId" />
</template>

<script>
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
    };
  },

  beforeCreate() {},

  created() {
    this.addEvent([{name: 'onFocusedRowDataWcode', func: this.onFocusedRowData}]);
  },

  mounted() {
    this.initSheet();
    this.selectWorkTypeClList();
  },

  methods: {
    initSheet() {
      const OPT = {
        //각 컬럼에 대한 정의 (컬럼의 이름,유형(Type),포맷(Format)등을 설정)
        //컬럼의 "Type"과 "Name" 속성은 반드시 설정되어야 합니다.
        Cols: [
          {
            Header: '코드',
            Name: 'wcode',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: 0,
          },
          {
            Header: '명칭',
            Name: 'wname',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: 0,
          },
        ],
        Cfg: {
          CanEdit: 1,
        },
        Events: {
          onDblClick: e => {
            if (e.row.Kind == 'Data') {
              this.callEvent({name: `onFocusedRowDataWcodeSearchOnConfirm`});
            }
          },
        },
      };

      PMISSheet.createSheet({
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      }).then(sheet => (this.sheet = sheet));
    },
    selectWorkTypeClList() {
      axios.post('/stdWorkTypeRegist/selectWorkTypeClList', {pgmCd: this.pgmCd, level: 2}).then(response => {
        this.sheet.loadSearchData(response.data, 0);
      });
    },
    onFocusedRowData(param) {
      if (typeof param == 'function') {
        param(this.sheet.getFocusedRow());
      }
    },
  },
};
</script>

<style></style>
