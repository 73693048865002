export default that => {
  return {
    Cfg: {
      CanEdit: 1,
    },
    Cols: [
      {
        Header: '코드',
        Name: 'itemCode',
        Type: 'Text',
        Align: 'Center',
        Width: 120,
        Size: 12,
        CanEditFormula: fr => (fr.Row.Added ? 1 : 0),
        Formula: fr => {
          return String(fr.Value).replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '');
        },
      },
      {
        Header: '명칭',
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
        CanEditFormula: fr => (fr.Row.Added ? 1 : that.cud !== 1),
      },
      {
        Header: '규격',
        Name: 'ssize',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
        CanEditFormula: fr => (fr.Row.Added ? 1 : that.cud !== 1),
      },
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Width: 80,
        CanEdit: 0,
        Button: 'Html',
        ButtonText: '<button type="button" class="i_search"></button>',
        WidthPad: 26,
        OnClickSide: () => that.$modal.show('prjBreakdownCost_unitModal'),
        Format: $getConstants('UNIT').code,
      },
      {
        Header: '합계',
        Name: 'amt',
        Extend: that.$ibsheetHelper.preset.amount,
        Type: 'Int',
        Width: 100,
        CanEdit: 0,
        Formula: e => e.Row['matAmt'] + e.Row['labAmt'] + e.Row['equipAmt'],
      },
      {
        Header: '재료비',
        Name: 'matAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Type: 'Int',
        Width: 100,
        CanEditFormula: fr => !fr.Row.existDtls,
      },
      {
        Header: '노무비',
        Name: 'labAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Type: 'Int',
        Width: 100,
        CanEditFormula: fr => !fr.Row.existDtls,
      },
      {
        Header: '경비',
        Name: 'equipAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Type: 'Int',
        Width: 100,
        CanEditFormula: fr => !fr.Row.existDtls,
      },
    ],
  };
};
