export default async that => {
  return {
    Cfg: {
      CanEdit: 0,
      IgnoreFocused: 1,
    },
    Cols: [
      {
        Name: 'sn',
        Header: '순번',
        Width: 70,
      },
      {
        Name: 'receiptInfo',
        Header: '수신자',
        RelWidth: 1,
      },
    ],
  };
};
