<template>
  <div class="pmis-tab-box">
    <!-- title-box -->
    <!-- <div class="tab_box"> -->
    <div class="title-box">
      <ul class="title-tab-box">
        <li class="selected">
          <slot name="title" />
        </li>
      </ul>
      <div class="title-spacer"></div>
      <div v-if="titleLeftVisible" class="title-left">
        <slot name="title-left" />
      </div>
      <div class="title-center">
        <slot name="title-center" />
      </div>
      <div v-if="titleRightVisible" class="title-right">
        <slot name="title-right" />
      </div>
    </div>
    <!-- title-box -->

    <!-- content-box -->
    <div class="grid_box grid_inner_scroll in_h">
      <slot />
    </div>
    <!-- content-box -->
  </div>
</template>

<script>
export default {
  computed: {
    titleLeftVisible() {
      return this.$slots['title-left'] !== undefined;
    },
    titleRightVisible() {
      return this.$slots['title-right'] !== undefined;
    },
  },
};
</script>

<style lang="scss">
@mixin box {
  display: flex;
  align-items: center;
}

@mixin box-spacer {
  padding-left: 8px;
}

@mixin box-left {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  font-size: 14px;

  > * {
    margin-right: 5px;
  }
}

@mixin box-center {
  align-items: center;
  display: inline-flex;
  flex: 1;
  font-size: 14px;

  > * {
    margin-right: 5px;
  }
}

@mixin box-right {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  font-size: 14px;

  > * {
    margin-left: 5px;
  }
}

@mixin box-items {
  a {
    display: inline-block;
    padding: 3px 6px 3px 8px;
    color: #666;
    font-weight: bold;
    font-family: 'Malgun Gothic', '맑은 고딕', dotum, '돋움', sans-serif;
    font-size: 12px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  button {
    display: inline-block;
    padding: 3px 6px 3px 8px;
    color: #666;
    font-weight: bold;
    font-family: 'Malgun Gothic', '맑은 고딕', dotum, '돋움', sans-serif;
    font-size: 12px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
}

.pmis-tab-box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  * {
    box-sizing: border-box;
  }

  .title-box {
    @include box;
    height: 36px;

    .title-tab-box {
      margin-left: 1px;
      display: flex;

      li {
        display: inline-block;
        margin-left: -1px;
        padding: 0 15px 0 15px;
        height: 36px;
        font-size: 14px;
        line-height: 36px;
        border-right: 1px solid #ccc;
        border-top: 1px solid #ccc;
        border-bottom: none;
        border-radius: 5px 5px 0 0;
      }

      li.first-child {
        border-left: 1px solid #ccc;
      }

      li.selected {
        color: #fff;
        background: linear-gradient(#4394d1, #3378c1);
        border: 1px solid #3378c1;

        a {
          all: unset;
          color: #fff;
        }
      }
    }

    .title-spacer {
      @include box-spacer;
    }

    .title-left {
      @include box-left;
    }

    .title-center {
      @include box-center;
    }

    .title-right {
      @include box-right;
    }

    @include box-items;
  }

  .debug {
    border: 1px dashed #aaa;
    padding: 5px;
  }

  .debug > * {
    border: 1px dashed #aaa;
    padding: 5px;
  }
}
</style>
