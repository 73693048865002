<template>
  <pmis-tab-box>
    <template #title>상세정보</template>
    <form onsubmit="return false;">
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header :required="isNew">코드</i-col-header>
          <i-col>
            <iui-text
              width="100px"
              :value="detailInfo.fldrCd"
              @change="setDetailInfo({fldrCd: $event.target.value})"
              max-length="30"
              :enable="isNew"
              :required="isNew"
              :error-message="{title: '코드', message: '코드를 입력해야 합니다.'}"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header required>명칭</i-col-header>
          <i-col>
            <iui-text
              width="300px"
              :value="detailInfo.fldrNm"
              @change="setDetailInfo({fldrNm: $event.target.value})"
              max-length="100"
              :enable="isEditable"
              :required="isEditable"
              :error-message="{title: '명칭', message: '명칭을 입력해야 합니다.'}"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>상위코드</i-col-header>
          <i-col>
            <iui-text width="100px" :value="detailInfo.uppFldrCd" :enable="false" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>상위명칭</i-col-header>
          <i-col>
            <iui-text width="300px" :value="detailInfo.uppFldrNm" :enable="false" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>메모</i-col-header>
          <i-col>
            <iui-text
              type="multi"
              :value="detailInfo.rmk"
              @change="setDetailInfo({rmk: $event.target.value})"
              max-length="400"
              :enable="isEditable"
            />
          </i-col>
        </i-row>
      </iui-container-new>
    </form>
  </pmis-tab-box>
</template>

<script>
export default {
  computed: {
    isEditable() {
      return this.isNew || !this.isStandard;
    },
  },
  beforeCreate() {
    $mapGetters(this, ['detailInfo', 'isNew', 'isStandard']);
    $mapMutations(this, ['setDetailInfo']);
  },
};
</script>

<style></style>
