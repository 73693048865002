<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text
        type="search"
        name="cmnDNm"
        label="코드명"
        :value="searchInfo.cmnDNm"
        @change="onChange"
        @enter="onSearch"
      ></iui-text>
      <iui-button value="검색" class="btn" @click="onSearch" />
    </template>
    <iui-container-new type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
export default {
  beforeCreate() {
    $mapGetters(this, ['upperDetailInfo']);
  },
  created() {
    this.addEvent([
      {name: 'lowerCode_search', func: this.onSearch},
      {name: 'lowerCode_add', func: this.onAdd},
      {name: 'lowerCode_save', func: this.onSave},
      {name: 'lowerCode_delete', func: this.onDelete},
      {name: 'lowerCode_onRemoveAll', func: this.onRemoveAll},
    ]);
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      searchInfo: {cmnDNm: ''},
    };
  },
  async mounted() {
    const OPT = {
      Cfg: {
        ReqStatusName: 'status',
      },
      Cols: [
        {
          Header: {Value: '선택', TextColor: '#444', HeaderCheck: 1},
          Name: 'cbx',
          Type: 'Bool',
          BoolIcon: 0,
          Align: 'center',
          Width: 80,
        },
        {
          Header: '코드',
          Name: 'cmnDCd',
          Type: 'Text',
          ResultMask: '^[A-Za-z0-9]{0,6}$',
          ResultMessage: '영문, 숫자 조합으로 6자리까지만 입력가능합니다.',
          Align: 'center',
          Width: 100,
          CanEdit: 0,
          Size: 6,
        },
        {
          Header: '코드명',
          Name: 'cmnDNm',
          Type: 'Text',
          Align: 'left',
          RelWidth: 1,
        },
        {
          Header: '사용여부',
          Name: 'useDs',
          Type: 'Enum',
          Align: 'left',
          Width: 100,
          EnumKeys: await $getCodeIBSheetEnum('KEY', $getConstants('USE_YN').code),
          Enum: await $getCodeIBSheetEnum('VALUE', $getConstants('USE_YN').code),
        },
        {
          Header: '비고',
          Name: 'rmk',
          Type: 'Text',
          Align: 'left',
          RelWidth: 1,
        },
        {Name: 'cmnUCd', Visible: false},
      ],
    };

    this.sheet = await PMISSheet.createSheet({el: this.elId, options: OPT});
    this.$ibsheetHelper.bindDefaultDeleteEvent(this.sheet); // 삭제 이벤트
  },
  methods: {
    async onSearch() {
      let param = {...this.upperDetailInfo, ...this.searchInfo};
      let response = await axios.post('/baseCmmnCodeRegist/selectLowerCodeList', param);
      this.sheet.loadSearchData(response.data);
    },
    onAdd() {
      if (this.upperDetailInfo.cmnUCd == '') {
        this.$alert({title: '상위코드 없음', message: '선택된 상위코드가 없습니다.'});
        return;
      } else {
        let addRow = this.sheet.addRow();
        this.sheet.setValue(addRow, 'cmnUCd', this.upperDetailInfo.cmnUCd, 1);
        this.sheet.setValue(addRow, 'useDs', $getConstants('USE_YN_Y').code, 1);
        this.sheet.setAttribute(addRow, 'cmnDCd', 'CanEdit', 1, 1);
      }
    },
    async onSave() {
      if (!this.sheet.hasChangedData()) {
        this.$alert({title: '수정사항 없음', message: '수정된 내용이 없습니다.'});
        return;
      }
      for (let row of this.sheet.getRowsByStatus('Added,Changed')) {
        if (String(row.cmnDCd).trim() == '') {
          this.$alert({title: '필수입력', message: '하위코드는 필수입력 항목입니다.'});
          return;
        }
        if (String(row.cmnDNm).trim() == '') {
          this.$alert({title: '필수입력', message: '하위코드명은 필수입력 항목입니다.'});
          return;
        }
      }

      let param = {cmmLowerCodeList: $_statusToCud(this.sheet.getSaveJson().data)};
      let response = await axios.post('/baseCmmnCodeRegist/selectLowerCodeDuplicationCheck', param);
      let duplicationList = Array.from(response.data);
      if (0 < duplicationList.length) {
        this.$alert({title: '하위코드 중복', message: `중복되는 하위 공통코드가 있습니다.<br/>[${duplicationList}]`});
        return;
      }

      if (await this.$confirm({title: '하위코드 저장', message: '저장하시겠습니까?'})) {
        response = await axios
          .post('/baseCmmnCodeRegist/updateLowerCodeInfo', param)
          .catch(e => console.error('저장을 실패했습니다. ', e));

        if (response.status == 200) {
          this.sheet.acceptChangedData();
        } else {
          this.$alert({title: '저장 실패', message: '저장을 실패하였습니다.'});
        }
      }
    },
    async onDelete() {
      let checkedRow = this.sheet.getRowsByChecked('cbx');
      if (0 == checkedRow.length) {
        this.$alert({title: '선택된 항목 없음', message: '선택된 항목이 없습니다.'});
        return;
      }

      if (!(await this.$confirm({title: '하위코드 삭제', message: '삭제하시겠습니까?'}))) {
        return;
      }
      let cmmLowerCodeList = [];
      checkedRow.map(row => {
        if (row.cmnUCd) {
          cmmLowerCodeList.push({cmnUCd: row.cmnUCd, cmnDCd: row.cmnDCd});
        }
      });
      let response = await axios.post('/baseCmmnCodeRegist/deleteLowerCodeInfo', {cmmLowerCodeList: cmmLowerCodeList});

      if (response.status == 200) {
        checkedRow.map(row => {
          this.sheet.removeRow(row);
        });
      } else {
        this.$alert({title: '저장 실패', message: '저장을 실패하였습니다.'});
      }
    },
    onChange(e) {
      this.searchInfo.cmnDNm = e.target.value;
    },
    onRemoveAll() {
      this.sheet.removeAll();
    },
  },
};
</script>

<style scoped></style>
