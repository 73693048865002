<template>
  <pmis-tab-box>
    <template #title>공고목록</template>
    <pmis-content-box>
      <template #header-left>
        <i-header-multi-box>
          <div>
            <label>공고회사</label>
            <iui-select
              defaultCd="A"
              :items="searchCompanyList"
              :value.sync="searchOption.searchOrgCd"
              @change="onSearch"
            />
            <label class="ml10">공고명</label>
            <iui-text type="search" width="300px" :value.sync="searchOption.searchTtl" @enter="onSearch" />
            <iui-button value="검색" @click="onSearch" />
          </div>
          <div>
            <label class="mr10">진행상태</label>
            <iui-radio-group
              label="진행상태"
              defaultCd="A"
              :value.sync="searchOption.searchRfqSt"
              :items="rfqStItem"
              @change="onSearch"
            />
          </div>
        </i-header-multi-box>
      </template>
      <ib-sheet
        :uid="_uid"
        :options="sheetOpt"
        :loadSearchData="loadSearchData"
        @loadSheet="sheet = $event"
        :events="{
          onRenderFirstFinish: onSearchCustomerList,
          onSearchFinish: sheet_onSearchFinish,
          onClick: sheet_onClick,
        }"
      />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/rfqListCustomer.js';
import {selectRfqCompanyList, selectRfqList} from '@/view/bidding/rfqCustomer/mat/api/rfqCustomer.js';
import {
  selectRfqCompanyList as selectSubRfqCompanyList,
  selectRfqList as selectSubRfqList,
} from '@/view/bidding/rfqCustomer/sub/api/subRfqCustomer.js';

export default {
  props: {
    bidType: {
      type: String,
      required: true,
    },
    process: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],

      searchOption: {
        searchOrgCd: '',
        searchTtl: '',
        searchRfqSt: '',
      },

      searchCompanyList: [],

      rfqStItem: [],
      focusKey: '',
    };
  },
  beforeCreate() {
    $mapActions(this, ['initInfo']);
  },
  created() {
    this.addFuncSearch(this.onSearchCustomerList);
    this.addEvent([{name: 'RfqListCustomer_Search', func: this.onSearch}]);

    if (this.process === 'progress') {
      this.rfqStItem = [
        {label: '현설통보', value: 'RFQ_SPOT_DSCR'},
        {label: '입찰중', value: 'RFQ_BIDDING'},
        {label: '입찰마감', value: 'RFQ_BIDDING_EXPIRED'},
        {label: '낙찰사선정중', value: 'RFQ_CHOICE'},
      ];
    }
    if (this.process === 'complete') {
      this.rfqStItem = [
        {label: '유찰', value: 'RFQ_FAIL'},
        {label: '입찰완료', value: 'RFQ_COMPLETE'},
      ];
    }
  },
  activated() {
    const externalData = this.$store.getters.externalData;
    this.$store.dispatch('setExternalData', {});

    this.focusKey = externalData.rfqNo ?? '';

    if (this.sheet) {
      this.setFocus();
    }
  },
  methods: {
    async onSearchCustomerList() {
      const api = {mat: selectRfqCompanyList, sub: selectSubRfqCompanyList};
      const response = await api[this.bidType](this.process);

      this.searchCompanyList = response.data.map(item => ({text: item.frmNm, value: item.pgmCd}));

      this.onSearch();
    },
    async onSearch() {
      const searchApi = {mat: selectRfqList, sub: selectSubRfqList};
      const response = await searchApi[this.bidType]({orgCd: this.pgmCd, process: this.process, ...this.searchOption});
      this.loadSearchData = response.data;
    },
    setRowData(row) {
      if (row) {
        const param = {rfqNo: row.rfqNo, bidRnd: row.lastBidRnd, cusCd: row.cusCd};
        this.callEvent({name: 'RfqInfoCustomer_onSearch', param});
      } else {
        this.initInfo();
      }
    },
    sheet_onSearchFinish(e) {
      e.sheet.setAttribute(null, 'stateDt', 'Visible', 0, 0);
      e.sheet.setAttribute(null, 'spotDscrDt', 'Visible', 0, 0);
      e.sheet.setAttribute(null, 'bidExpireDt', 'Visible', 0, 0);

      switch (this.searchOption.searchRfqSt) {
        case 'RFQ_SPOT_DSCR':
          e.sheet.setAttribute(null, 'spotDscrDt', 'Visible', 1, 0);
          break;
        case 'RFQ_BIDDING':
          e.sheet.setAttribute(null, 'bidExpireDt', 'Visible', 1, 0);
          break;
        default:
          e.sheet.setAttribute(null, 'stateDt', 'Visible', 1, 0);
          break;
      }
      e.sheet.rerender();

      this.setFocus();
    },
    setFocus() {
      const focusRow = this.sheet.getDataRows().find(row => row.rfqNo === this.focusKey) ?? this.sheet.getFirstRow();

      this.sheet.focus(focusRow);
      this.setRowData(focusRow);
    },
    sheet_onClick(e) {
      if (e.kind === 'Data') {
        this.setRowData(e.row);
      }
    },
  },
};
</script>

<style></style>
