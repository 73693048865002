<template>
  <article id="contents">
    <component v-bind:is="currentView"></component>
  </article>
</template>

<script>
export default {
  data() {
    return {
      currentView: null,
      option: null,
    };
  },
  created() {
    this.option = opener.popupInterface[window.name];
    this.init();
    this.setProject();
    this.setMenu();
    this.setToken();
  },
  methods: {
    init() {
      const components = require.context('@', true, /\.vue$/);
      const array = components.keys().filter(x => x === this.option.param.mnuPth + '/index.vue');

      if (array.length > 0) {
        const componentConfig = components(array[0]);
        this.currentView = componentConfig.default || componentConfig;
      } else {
        this.onClose();
      }
    },
    setMenu() {
      this.$store.dispatch('mdi/setUrl', this.option.param);
      this.$store.dispatch('mdi/addMDI', this.option.param);
    },
    setProject() {
      this.$store.commit('project/setPrjList', this.option.prjList);

      this.option.prjList.forEach(x => {
        if (x.prjCd === this.option.prjCd) {
          this.$store.commit('setPrjCd', x.prjCd);
          this.$store.dispatch('project/setLoadPrj', x);
        }
      });
    },
    setToken() {
      this.$store.commit('jwt/setAccessToken', this.option.token.accessToken);
      this.$store.commit('jwt/setRefreshToken', this.option.token.refreshToken);
    },
    onClose() {
      window.close();
    },
  },
};
</script>

<style lang="scss" scoped>
#contents {
  top: 0;
  left: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
</style>
