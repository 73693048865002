var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pmis-content-box',{scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_c('iui-text',{attrs:{"type":"search","value":_vm.searchNm},on:{"update:value":function($event){_vm.searchNm=$event},"enter":_vm.onSearch}}),_c('iui-checkbox-group',{attrs:{"name":"poCloseYn","items":[{value: 'Y', label: '종료계약포함'}],"checkedValues":[_vm.poCloseYn]},on:{"change":function (e) {
          _vm.poCloseYn = e.target.checked ? 'Y' : 'N';
          _vm.onSearch();
        }}}),_c('iui-button',{attrs:{"value":"검색"},on:{"click":_vm.onSearch}})]},proxy:true}])},[_c('iui-container-new',{attrs:{"type":"css-flex"}},[_c('i-row',{attrs:{"height":"200px"}},[_c('i-col',[_c('ib-sheet',{attrs:{"uid":_vm._uid,"options":_vm.Options1,"loadSearchData":_vm.loadSearchData1,"events":{
            onSearchFinish: _vm.onSearchFinish1,
            onClick: _vm.onClick1,
            onDblClick: _vm.onDblClick1,
          }},on:{"loadSheet":_vm.loadSheet1}})],1)],1),_c('i-spacer'),_c('i-row',[_c('i-col',[_c('pmis-tab-box',{attrs:{"no-border-box":"","no-padding-box":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("계약내역")]},proxy:true}])},[_c('ib-sheet',{attrs:{"uid":_vm._uid,"options":_vm.Options2,"loadSearchData":_vm.loadSearchData2,"events":{
              onDblClick: _vm.onDblClick2,
            }},on:{"loadSheet":_vm.loadSheet2}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }