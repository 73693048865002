<template>
  <pmis-page ref="page" :search-project-visible="false">
    <div class="cert_box">
      <div class="title">
        공동인증서(구 공인인증서) 등록 안내
      </div>
      <div class="info1">
        <div>
          - PMIS를 이용하기 위해서는 발급받은 인증서 및 보유하고 있는 공동인증서(구 공인인증서)를 등록해야 합니다.
        </div>
        <div>
          - 인증서 등록방법은 <span>[인증서등록]</span>버튼을 클릭하여 해당 인증서를 선택한 후, 인증서의 암호를 입력하여
          일치 여부를 확인하시기 바랍니다.
        </div>
      </div>
      <div class="info2">
        <div>
          <div class="left">- 등록가능한 인증서 :</div>
          <div class="right">
            <span>범용 사업자 인증서 (전자계약, 전자입찰, 전자구매, 전자세금계산서 발급)</span>
          </div>
        </div>
        <div>
          <div class="left"></div>
          <div class="right">
            <span>※ 사업자 범용 인증서만 가능 (제한용도, 개인 인증서는 등록되지 않습니다.)</span>
          </div>
        </div>
        <div>
          - 공동인증서(구 공인인증서)가 없는 경우? 저렴한 금액으로 인증서를 발급받으세요.
          <a href="https://inogard.signra.com:4433/web-signra/main.sg" target="_blank">
            공동인증서(구 공인인증서) 신청하기
          </a>
        </div>
        <div>
          - 공동인증서(구 공인인증서) 재발급/갱신되었을 경우. 다시 한번 등록해주시기 바랍니다.
        </div>
      </div>
      <div class="info3">
        <div class="left">{{ frmNm }}</div>
        <div class="center">
          <template v-if="isExistCertdn">{{ certdn }}</template>
          <template v-else>등록한 인증서가 없습니다.</template>
        </div>
        <div class="right">
          <iui-button @click="onRegistCertificate">인증서 등록</iui-button>
        </div>
      </div>
      <div class="title">
        공동인증서(구 공인인증서) 등록 시 오류 안내
      </div>
      <div class="info4">
        <div>
          <div class="header">상호연동 대상 인증서 정책에 포함되지 않는 OID 입니다.</div>
          <div class="sub">
            - 인증서의 정책에 위배된 인증서를 사용하고 있습니다. PMIS에서는 범용인증서를 사용합니다.
          </div>
          <div class="sub">- 범용 인증서 및 특별등급인증서를 발급받아 주시기 바랍니다.</div>
          <div class="sub">- 개인인증서는 사용할 수 없습니다.</div>
        </div>
        <div>
          <div class="header">상호연동 신원확인용 인증서 검증 오류입니다.</div>
          <div class="sub">
            -인증서 발급 당시 신청한 귀사의 사업자 번호와 PMIS에 등록되어 있는 사업자 번호가 일치 하지 않을 경우
            나타나는 메시지 입니다.
          </div>
          <div class="sub">-협력업체 회사의 명의로 발급받은 인증서인지 확인해 주시기 바랍니다.</div>
        </div>
        <div>
          <div class="header">
            인증서 등록에 필요한 정보가 없습니다. 해당 인증기관에서 인증서 갱신을 하시기 바랍니다.
          </div>
          <div class="sub">-인증서 내에 인증서 등록에 필요한 정보가 들어있지 않을 경우에 나타나는 메시지 입니다.</div>
          <div class="sub">-발급받은 공인인증기관에서 신원확인용 인증서 갱신을 하여 주시기 바랍니다.</div>
        </div>
      </div>
    </div>
  </pmis-page>
</template>

<script>
export default {
  data() {
    return {
      frmNm: '',
      certdn: '',
      svrKmCertPerm: '',
    };
  },
  mounted() {
    this.onInit();
  },
  computed: {
    isExistCertdn() {
      return this.certdn !== undefined && this.certdn != '';
    },
  },
  methods: {
    onInit() {
      this.frmNm = this.userInfo.frmNm;
      this.certdn = this.userInfo.certdn;

      axios.post('/pkiToolkit/getServerKmCertPerm', {}).then(response => {
        this.svrKmCertPerm = response.data.svrKmCertPerm;
      });
    },
    async onRegistCertificate() {
      try {
        await this.$secuKit.checkReady();
        await this.$secuKit.showDialog();

        let response = {};
        let bsnNo = this.userInfo.bsnNo;
        let certID = this.$secuKit.certListInfo.getCertID();

        let userSignCert = null; // 사용자 인증서 정보
        let vidRandom = null; // VID Random 값(신원확인 정보)
        let encryptedUserRandomNumber = null; // 암호화 된 VID(신원확인 값)
        let encryptedSessionKeyByServer = null; // 서버인증서로 RSA 암호화된 암호의 키 정보

        response = await this.$secuKit.viewCertInfomationWithVID({
          certType: 'SignCert',
          certID: certID,
          isViewVID: '1',
        });

        userSignCert = response.certPEM;
        vidRandom = response.vidRandom;

        // 대칭키 암호화
        response = await this.$secuKit.symmetricEncryptData({
          algorithm: 'SEED/CBC/PADDING',
          keyName: certID,
          sourceString: vidRandom,
        });

        encryptedUserRandomNumber = response.symmetricEncryptData;

        // 대칭키 암호화에 사용된 Key + 초기벡터(IV)를 RSA암호화 한다.
        // RSA로 암호화 된 대칭 Key (Base64 Encoded)
        response = await this.$secuKit.getSymmetricKeyIV({
          serverCert: removePEMHeader(removeCRLF(this.svrKmCertPerm)),
          rsaVersion: 'V15',
          keyName: certID,
        });
        encryptedSessionKeyByServer = response.getSymmetricKeyIV;

        this.$save({
          url: '/certificateRegist/register',
          param: {
            pgmCd: this.pgmCd,
            bsnNo,
            userSignCert,
            encryptedUserRandomNumber,
            encryptedSessionKeyByServer,
          },
        })
          .then(response => {
            if (response.status == 200) {
              this.certdn = response.data;
              sessionStorage.setItem('userInfo', JSON.stringify({...this.userInfo, certdn: this.certdn}));
              this.deepChildren(this.$parent);
            }
          })
          .catch(ex => {
            if (ex.pmisError) {
              let msg = ex.message;
              if (ex.description) msg += `<br/>- ${ex.description}`;
              this.$alert({title: ex.title, message: msg});
            }
          });
      } catch (e) {
        console.error(e);
      }
    },
    deepChildren(component) {
      if (component.$children.length) {
        component.$children.forEach(el => {
          el.$recompute('userInfo');
          this.deepChildren(el);
        });
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.cert_box {
  margin: 0;
  padding: 10px;
  width: 920px;
}

.cert_box .title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 15px;
}

.cert_box .info1 {
  margin-bottom: 20px;
}

.cert_box .info1 div {
  padding-bottom: 5px;
}

.cert_box .info1 span {
  color: red;
  font-weight: bold;
}

.cert_box .info2 {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.cert_box .info2 div {
  height: 20px;
  display: flex;
  align-items: center;
  padding: 5px 0 5px 0;
}

.cert_box .info2 div .left {
  flex: 0 0 130px;
}

.cert_box .info2 div .right {
  flex: 1;
}

.cert_box .info2 span {
  color: red;
}

.cert_box .info2 a {
  border-radius: 5px;
  background: #055bca;
  color: #fff;
  text-align: center;
  padding: 5px 7px;
  margin-left: 5px;
  font-size: 12px;
}

.cert_box .info3 {
  margin-bottom: 30px;
  display: flex;
  border: 2px solid #666;
  border-radius: 3px;
  background: #f6f6f6;
}

.cert_box .info3 .left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 180px;
  height: 35px;

  background: #666;
  color: #fff;
}

.cert_box .info3 .center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.cert_box .info3 .right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 130px;
}

::v-deep .cert_box .info3 .right button {
  border-radius: 5px;
  background: #055bca;
  color: #fff;
  padding: 6px 25px;
  font-weight: bold;
  border: none;
}

.cert_box .info4 > div {
  margin-bottom: 10px;
}

.cert_box .info4 .header {
  background: #dadada;
  color: #222;
  height: 20px;
  padding: 5px;
  font-weight: bold;
  letter-spacing: -1px;
}

.cert_box .info4 .sub {
  background: #f6f6f6;
  color: #222;
  height: 20px;
  padding: 5px;
  letter-spacing: -1px;
}
</style>
