<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text type="search" name="cmnDNm" label="코드명" :value.sync="searchInfo.cmnDNm" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onRenderFirstFinish,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/LowerCodeSheet.js';
import {
  selectLowerCodeList,
  selectLowerCodeDuplicationCheck,
  updateLowerCodeInfo,
  deleteLowerCodeInfo,
} from '@/view/sysManage/stdCodeManage/cmmnCodeRegist/api/cmmnCodeRegist.js';
export default {
  data() {
    return {
      Options: $addColCheckbox(options(this)),
      loadSearchData: [],
      searchInfo: {cmnDNm: ''},
    };
  },
  beforeCreate() {
    $mapGetters(this, ['upperDetailInfo']);
  },
  created() {
    this.addEvent([
      {name: 'lowerCode_search', func: this.onSearch},
      {name: 'lowerCode_add', func: this.onAdd},
      {name: 'lowerCode_save', func: this.onSave},
      {name: 'lowerCode_delete', func: this.onDelete},
    ]);
  },
  methods: {
    async onSearch() {
      let param = {...this.upperDetailInfo, ...this.searchInfo};
      let response = await selectLowerCodeList(param);
      this.loadSearchData = response.data;
    },
    onAdd() {
      let addRow = this.sheet.addRow();
      this.sheet.setValue(addRow, 'pgmCd', this.upperDetailInfo.pgmCd, 1);
      this.sheet.setValue(addRow, 'cmnUCd', this.upperDetailInfo.cmnUCd, 1);
      this.sheet.setValue(addRow, 'useDs', $getConstants('USE_YN_Y').code, 1);
      this.sheet.setAttribute(addRow, 'cmnDCd', 'CanEdit', 1, 1);
    },
    async onSave() {
      for (let row of this.sheet.getRowsByStatus('Added,Changed')) {
        if (String(row.cmnDCd).trim() == '') {
          this.$alert({title: '코드 저장', message: '코드는 필수입력 항목입니다.'});
          return;
        }
        if (String(row.cmnDNm).trim() == '') {
          this.$alert({title: '코드 저장', message: '코드명은 필수입력 항목입니다.'});
          return;
        }
      }

      let param = {saveList: $_statusToCud(this.sheet.getSaveJson().data)};
      let response = await selectLowerCodeDuplicationCheck(param);
      let duplicationList = response.data;
      if (0 < duplicationList.length) {
        this.$alert({
          title: '코드 중복',
          message: `중복되는 하위 공통코드가 있습니다.<br/>[${duplicationList}]`,
        });
        return;
      }

      response = await updateLowerCodeInfo(param);

      this.sheet.acceptChangedData();
    },
    async onDelete() {
      let deleteList = [];
      this.sheet.getRowsByChecked('chk').forEach((row, index) => {
        deleteList[index] = {pgmCd: row.pgmCd, cmnUCd: row.cmnUCd, cmnDCd: row.cmnDCd};
      });

      if (0 == deleteList.length) {
        this.$alert({title: '하위코드 삭제', message: '선택된 항목이 없습니다.'});
        return;
      }

      if (!(await this.$confirm({title: '하위코드 삭제', message: '코드를 삭제하시겠습니까?'}))) return;
      const response = await deleteLowerCodeInfo({deleteList});
      this.onSearch();
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped></style>
