export default that => {
  return {
    Cfg: {CanEdit: false},
    Cols: [
      {Header: '순번', Name: 'no', Type: 'Text', Align: 'center', Width: 80},
      {Header: '양식제목', Name: 'tl', Type: 'Text', RelWidth: 1, Indent: 2},
      {Name: 'fomNo', Visible: false},
      {Name: 'pgmCd', Visible: false},
    ],
  };
};
