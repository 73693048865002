<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <label>마감년월</label>
      <iui-datepicker name="closeMm" type="month" :value.sync="searchInfo.closeMm" :enable="status.canDatePick">
        <i slot="icon-calendar" v-if="!status.canDatePick"></i>
      </iui-datepicker>
    </template>
    <template v-slot:header-right>
      <iui-button value="월마감" @click="onMonthClose" v-if="status.isClosing" />
      <iui-button value="취소" @click="onCancel" v-if="status.isCancel" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onClick}"
    />
  </pmis-content-box>
</template>

<script>
import {
  selectMonthClosePossibleAt,
  saveExpitmMonthClosing,
  selectMonthCloseCancelPossibleAt,
  saveExpitmMonthClosingCancel,
  selectMonCloseList,
} from '../api/expitmCloseRegist.js';
import options from './sheetOptions/CloseListSheet.js';
import moment from 'moment';
export default {
  beforeCreate() {
    $mapGetters(this, ['searchInfo']);
    $mapMutations(this, ['setSearchInfo']);
  },
  created() {
    this.addEvent([{name: 'CloseList_onSearch', func: this.onSearch}]);
    this.addEvent([{name: 'CloseList_onAdd', func: this.onAdd}]);
    this.addFuncSearch(this.onSearch);
    this.addFuncNew(this.onAdd);
    this.onSearch();
  },
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      lastCloseYM: '', //최종 마감년월
      status: {
        isClosing: false,
        isCancel: false,
        canDatePick: false,
      },
    };
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        if (this.lastCloseYM == e.row.closeMm) {
          this.status.isCancel = true;
        } else {
          this.status.isCancel = false;
        }
        this.status.isClosing = false;
        this.setSearchInfo({closeMm: String(e.row.closeMm)});
        this.callEvent({name: 'DetailList_onSearch'});
      }
    },
    async onSearch() {
      this.status.canDatePick = false;
      this.status.isClosing = false;

      const param = {pgmCd: this.pgmCd};
      const response = await selectMonCloseList(param);
      const monCloseList = response.data.sort((a, b) => b.closeMm - a.closeMm);
      this.sheet.loadSearchData(monCloseList);

      if (monCloseList.length > 0) {
        this.setSearchInfo({closeMm: monCloseList[0].closeMm});
        this.setSearchInfo(monCloseList[0]);
        this.status.isCancel = true;
        this.lastCloseYM = monCloseList[0].closeMm;
      } else {
        this.setSearchInfo(param);
        this.status.isCancel = false;
        this.lastCloseYM = '';
      }

      setTimeout(() => this.callEvent({name: 'DetailList_onSearch'}), 100);
    },
    onAdd() {
      if (this.lastCloseYM) {
        this.setSearchInfo({
          closeMm: moment(this.lastCloseYM)
            .add(1, 'M')
            .format('YYYYMM'),
        });
      } else {
        this.status.canDatePick = true;
      }
      this.status.isClosing = true;
      this.status.isCancel = false;
    },
    async onMonthClose() {
      if (!this.searchInfo.closeMm) {
        this.$alert({title: '월마감', message: '마감년월을 선택해야 합니다.'});
        return;
      }

      const checkResponse = await selectMonthClosePossibleAt(this.searchInfo);
      if (checkResponse.data.CHECK1 == 'N') {
        this.$alert({title: '월마감', message: '해당 마감년월은 이미 월마감이 된 마감년월 입니다.'});
        return;
      } else if (checkResponse.data.CHECK2 == 'N') {
        this.$alert({
          title: '월마감',
          message: '해당 마감년월 이후에 진행된 월마감이 존재합니다.<br/>월마감 처리를 할 수 없습니다.',
        });
        return;
      } else if (checkResponse.data.CHECK3 == 'N') {
        this.$alert({
          title: '월마감',
          message: '월마감은 월별로 순차적으로 진행되어야 합니다.<br/>해당 마감년월은 월마감 처리를 할 수 없습니다.',
        });
        return;
      }

      let checkDate = this.searchInfo.closeMm.substr(0, 4) + '-' + this.searchInfo.closeMm.substr(4, 2);
      if (
        !(await this.$confirm({
          title: '월마감',
          message: `월마감 처리를 진행 하시겠습니까? [${checkDate}]`,
        }))
      ) {
        return;
      }
      let response = await saveExpitmMonthClosing(this.searchInfo);
      if (response.status == 200) {
        this.setSearchInfo({closeMm: ''});
        this.onSearch();
      } else {
        this.$alert({title: '월마감', message: '월마감 처리를 실패하였습니다.'});
      }
    },
    async onCancel() {
      let row = this.sheet.getFocusedRow();
      let param = {closeMm: row.closeMm};
      const checkResponse = await selectMonthCloseCancelPossibleAt(param);
      if (checkResponse.data.CHECK1 == 'N') {
        this.$alert({
          title: '월마감',
          message: '해당 마감년월은 월마감 처리가 되지 않은 마감년월입니다.<br/>취소할 수 없습니다.',
        });
        return;
      } else if (checkResponse.data.CHECK2 == 'N') {
        this.$alert({
          title: '월마감',
          message: '해당 마감년월 이후에 진행된 마감이 존재합니다.<br/>취소할 수 없습니다.',
        });
        return;
      } else if (checkResponse.data.CHECK3 == 'N') {
        this.$alert({
          title: '월마감',
          message: '해당 마감정보 중 협력사에서 세금계산서가 생성된 내역이 존재합니다.<br/>취소할 수 없습니다.',
        });
        return;
      }

      let checkDate = row.closeMm.toString().substr(0, 4) + '-' + row.closeMm.toString().substr(4, 2);
      if (await this.$confirm({title: '월마감', message: `월마감 취소를 하시겠습니까? [${checkDate}]`})) {
        let response = await saveExpitmMonthClosingCancel(param);
        if (response.status == 200) {
          this.setSearchInfo({closeMm: ''});
          this.onSearch();
        } else {
          this.$alert({title: '월마감', message: '월마감 처리를 실패하였습니다.'});
        }
      }
    },
  },
};
</script>

<style scoped>
.box_w {
  height: calc(100vh - 314px) !important;
}
</style>
