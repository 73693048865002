import {PR_COLUMNS} from '@/view/bidding/js/BiddingColumns.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import {selectPrList, selectPrInfo, selectPrItemList} from '../api/pr.js';

const BIDDING_CODES = BIDDING_CONSTANTS.CODES;

const state = {
  prList: [],
  prInfo: {...PR_COLUMNS},
  prItemList: [],

  prIsNew: false,
};

const getters = {
  prList: state => state.prList,
  prInfo: state => state.prInfo,
  prItemList: state => state.prItemList,
  prIsNew: state => state.prIsNew,
  prStatus: state => getPrStatus(state),
};

const mutations = {
  setPrList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.prList = payload;
    }
  },
  setPrInfo(state, payload) {
    for (const key in state.prInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.prInfo[key] = payload[key];
      }
    }

    state.prInfo.prNo2 = state.prIsNew ? BIDDING_CODES.AUTO : COMMON_FUNCTION.getMakeFormat(state.prInfo.prNo);

    if (state.prInfo.spotDscrYn === BIDDING_CODES.SPOT_DSCR_N) {
      // 현설정보 초기화
      state.prInfo.spotDscrDt = '';
      state.prInfo.spotDscrYmd = '';
      state.prInfo.spotDscrHh = '';
      state.prInfo.spotDscrMm = '';
      state.prInfo.spotDscrUsrcd = '';
      state.prInfo.spotDscrUsrnm = '';
      state.prInfo.spotDscrLoc = '';
    }
  },
  initPrInfo(state) {
    state.prInfo = {...PR_COLUMNS};
  },
  setPrItemList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.prItemList = payload;
    }
  },
  setPrIsNew(state, payload) {
    if (typeof payload === 'boolean') {
      state.prIsNew = payload;
    }
  },
};

const actions = {
  async searchPrList(context, param) {
    const prList = (await selectPrList(param)).data;
    context.commit('setPrList', prList);
  },
  async searchPrInfo(context, prNo) {
    context.commit('setPrIsNew', false);
    context.commit('setPrInfo', (await selectPrInfo(prNo)).data);
    context.dispatch('searchPrItemList', prNo);
  },
  async searchPrItemList(context, prNo) {
    context.commit('setPrItemList', (await selectPrItemList(prNo)).data);
  },
};

function getPrStatus(state) {
  const status = {
    isPrReady: state.prInfo.prSt === BIDDING_CONSTANTS.PROGRESS_STATE.PR_READY,
    isTaxDirect: state.prInfo.taxTypecd === BIDDING_CODES.TAX_DIRECT, // 과세유형 - 직접 입력
    isTaxDirectPercent: state.prInfo.taxDirectDs === BIDDING_CODES.TAX_DIRECT_PERCENT, // 과세유형 - 직접 입력(%)
    isTaxDirectAmount: state.prInfo.taxDirectDs === BIDDING_CODES.TAX_DIRECT_AMOUNT, // 과세유형 - 직접 입력(원)
    isExistSpotDscr: state.prInfo.spotDscrYn === BIDDING_CODES.SPOT_DSCR_Y, // 현설유무 - 있음
  };
  status['isEnable'] = state.prIsNew || status.isPrReady;

  return status;
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
