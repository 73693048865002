<template>
  <pmis-content-box>
    <Search slot="header-custom" @search="onSearch" />
    <ib-sheet
      :uid="_uid"
      :options="options"
      :loadSearchData="labAnmonList"
      @loadSheet="loadSheet"
      :events="{
        onClick: onClick,
        onSearchFinish: onSearchFinish,
      }"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/LabtAnmonMListMSheet.js';
import Search from './LabtAnmonMListSubSearch.vue';
export default {
  components: {
    Search,
  },
  props: {
    registerData: {
      type: Array,
    },
    delete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: options(this),
      sheet: undefined,
    };
  },
  watch: {
    delete(val) {
      //   if (val) {
      //     this.deleteMatAnmon();
      //   }
    },
  },
  beforeCreate() {
    $mapGetters(this, 'labor', ['labAnmonList', 'anyTimeLabSaveDataReqDto']);
    $mapMutations(this, 'labor', [
      'setDetailInfo',
      'setSearchInfo',
      'setEmpInfo',
      'initEmpInfo',
      'initDetailInfo',
      'initCalculateList',
    ]);
    $mapActions(this, 'labor', ['selectLabAnmonList', 'selectRegisterLabDetail', 'deleteLabAnmon']);
  },
  activated() {
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },
  methods: {
    async onSave() {
      if (!this.anyTimeLabSaveDataReqDto.monthlyAmt) {
        return;
      }

      try {
        const response = await this.$save({
          url: '/closeAnyTimeLab/saveLabAnmon',
          param: this.anyTimeLabSaveDataReqDto,
        });
        if (response.data > 0) {
          this.cud = '0';
          this.onAfterSave(this.anyTimeLabSaveDataReqDto);
        }
      } catch (error) {
        console.error(error);
      }
    },
    onAfterSave(data) {
      const row = this.sheet.getFocusedRow();
      for (let key in data) {
        this.sheet.setValue(row, key, data[key]);
      }

      this.sheet.setValue(row, 'cud', '0');
      this.sheet.acceptChangedData(row);
      this.selectCalculateList(row);
    },
    async onDelete() {
      if (!(await this.$confirm({title: '노무비삭제', message: '삭제하시겠습니까?'}))) {
        return;
      }
      this.deleteLabAnmon();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    async onSearch() {
      this.selectLabAnmonList();
    },
    onSearchFinish(e) {
      this.selectCalculateList(e.sheet.getFirstRow());
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.selectCalculateList(e.row);
      }
    },
    async selectCalculateList(row) {
      this.initEmpInfo();
      this.initDetailInfo();
      this.initCalculateList();
      if (!row) {
        return;
      }
      this.cud = row.cud;
      this.setEmpInfo(row);
      this.setDetailInfo(row);

      await this.selectRegisterLabDetail();
    },
  },
};
</script>

<style></style>
