<template>
  <pmis-page>
    <BoardList />
  </pmis-page>
</template>

<script>
import BoardList from '../components/BoardList.vue';
export default {
  components: {BoardList},
  activated() {
    this.canNew = true;
    this.canSave = false;
  },
  created() {
    this.addFuncSearch(this.onSearch);
    this.addFuncNew(this.onAdd);
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'BoardList_search'});
    },
    onAdd() {
      this.callEvent({name: 'BoardList_create'});
    },
  },
};
</script>

<style></style>
