<template>
  <pmis-tab-box>
    <template #title>자재예산</template>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <pmis-content-box>
            <template #header-left>
              <label class="mr10">대분류</label>
              <iui-select :items="lmItems" :value.sync="lmCode" defaultCd="A" @change="onChangeLm" />
              <label class="ml10 mr10">중분류</label>
              <iui-select :items="mmItems" :value.sync="mmCode" defaultCd="A" @change="onChangeMm" />
              <label class="ml10 mr10">소분류</label>
              <iui-select :items="smItems" :value.sync="smCode" defaultCd="A" />
              <label class="ml10 mr10">명칭</label>
              <iui-text
                type="search"
                :value="itemName"
                @change="itemName = $event.target.value"
                @enter="onSearchMatBudgetList"
              />
              <iui-button value="검색" @click="onSearchMatBudgetList" />
            </template>

            <ib-sheet
              :uid="_uid"
              :options="sheetOpt"
              :loadSearchData="matBudgetList"
              @loadSheet="sheet = $event"
              :events="{onRenderFirstFinish: onSearchClassificationList}"
            />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/matBudgetPopup.js';
export default {
  props: {
    propsPrjCd: {
      type: [String, Number],
      default: undefined,
    },
    isMultiSelect: {
      type: Boolean,
      default: true,
    },
    visiblePrice: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.addEvent([{name: 'MatBudgetPopup_GetCheckedRows', func: this.getCheckedRows}]);
  },
  data() {
    return {
      sheetOpt: $addColCheckbox(sheetOpt(this)),
      matBudgetList: [],

      clItems: [],
      lmItems: [],
      mmItems: [],
      smItems: [],

      lmCode: '',
      mmCode: '',
      smCode: '',
      itemName: '',
    };
  },
  methods: {
    onSearchClassificationList() {
      axios
        .post('/matBudget/selectClList', {
          pgmCd: this.pgmCd,
          prjCd: this.propsPrjCd,
        })
        .then(response => {
          this.clItems = response.data;
          this.lmItems = this.clItems.lmCodes.map(item => ({text: item.lmName, value: item.lmCode}));
          this.onSearchMatBudgetList();
        });
    },
    onSearchMatBudgetList() {
      axios
        .post('/matBudget/selectMatBudgetList', {
          pgmCd: this.pgmCd,
          prjCd: this.propsPrjCd,
          lmCode: this.lmCode,
          mmCode: this.mmCode,
          smCode: this.smCode,
          itemName: this.itemName,
        })
        .then(response => {
          this.matBudgetList = response.data;
        });
    },

    onChangeLm(e) {
      this.mmCode = '';
      this.mmItems = this.clItems.mmCodes
        .filter(item => this.lmCode == item.lmCode)
        .map(item => ({text: item.mmName, value: item.mmCode}));
      this.smCode = '';
      this.smItems = [];
    },
    onChangeMm(e) {
      this.smCode = '';
      this.smItems = this.clItems.smCodes
        .filter(item => this.mmCode == item.mmCode)
        .map(item => ({text: item.smName, value: item.smCode}));
    },

    getCheckedRows(callback) {
      if (typeof callback === 'function') {
        callback(this.sheet.getRowsByChecked('chk'));
      }
    },
  },
};
</script>

<style></style>
