export default that => {
  const preset = that.$ibsheetHelper.preset;
  const sheetOption = {
    Cfg: {CanEdit: 1, CanSort: 0},
    Def: {Row: {NoColor: 1}},
    Cols: [
      {Header: '선택', Name: 'radio', Align: 'center', Type: 'Radio', HRadio: 0, Visible: !that.isMultiSelect},
      {Header: '자재코드', Name: 'itemCode', Align: 'center', Width: 160, CanEdit: false},
      {Header: '명칭', Name: 'itemName', RelWidth: 1, CanEdit: false},
      {Header: '규격', Name: 'size', RelWidth: 1, CanEdit: false},
      {Header: '단위', Name: 'unit', Format: $getConstants('UNIT').code, Align: 'center', Width: 80, CanEdit: false},
      {Header: '예산수량', Name: 'qty', Align: 'center', Extend: preset.quantity, Width: 120, CanEdit: false},
      {Header: '단가', Name: 'price', Extend: preset.unitAmount, Width: 120, CanEdit: false},
      {Header: '금액', Name: 'amt', Extend: preset.amount, Width: 120, CanEdit: false},
      {
        Header: '기청구수량',
        Name: 'preQty',
        Align: 'center',
        Extend: preset.quantity,
        Width: 120,
        CanEdit: false,
        Visible: false,
      },
      {
        Header: '잔여수량',
        Name: 'remQty',
        Align: 'center',
        Extend: preset.quantity,
        Width: 120,
        CanEdit: false,
        Visible: false,
      },

      {Name: 'chgDegree', Visible: false},
      {Name: 'prePrQty', Visible: false},
      {Name: 'preRfqQty', Visible: false},
      {Name: 'prePoQty', Visible: false},
    ],
  };

  if (!that.visiblePrice) {
    const excludeCols = ['price', 'amt'];
    sheetOption.Cols.forEach(col => {
      if (excludeCols.includes(col.Name)) {
        col.Visible = false;
      }
    });
  }

  return sheetOption;
};
