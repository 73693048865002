const state = {
  searchInfo: {
    investDt: '', //  출역년월
    copyInvestDt: '',
    searchNm: '',
    isCopy: false,
  },
};

const getters = {
  searchInfo: state => state.searchInfo,
};

const actions = {};

const mutations = {
  setSearchInfo(state, payload) {
    for (let o in payload) {
      if (payload && payload.hasOwnProperty(o)) {
        state.searchInfo[o] = payload[o];
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
