<template>
  <ib-sheet
    :uid="_uid"
    :options="sheetOpt"
    :loadSearchData="needDocItems"
    :events="{
      onSearchFinish: sheet_onSearchFinish,
      onAfterClick: sheet_onAfterClick,
    }"
  />
</template>

<script>
import sheetOpt from './sheetOptions/poNeedDocument.js';

export default {
  data() {
    return {
      sheetOpt: sheetOpt(this),

      needDocItems: [
        {needDocNm: '사업자등록증', needDocType: 'default'},
        {needDocNm: '법인등기부등본', needDocType: 'default'},
        {needDocNm: '건설업등록증', needDocType: 'default'},
        {needDocNm: '건설업등록수첩', needDocType: 'default'},
        {needDocNm: '사용인감계', needDocType: 'default'},
        {needDocNm: '법인인감증명서', needDocType: 'default'},
        {needDocNm: '지방세완납증명서', needDocType: 'default'},
        {needDocNm: '국세완납증명서', needDocType: 'default'},
        {needDocNm: '근재보험기업증권(근로자재해보험)', needDocType: 'default'},
        {needDocNm: '직불합의서', needDocType: 'default'},
        {needDocNm: '통장사본', needDocType: 'default'},
        {needDocNm: '자격증사본', needDocType: 'default'},
        {needDocNm: '경력증명서', needDocType: 'default'},
        {needDocNm: '재직증명서', needDocType: 'default'},
        {needDocNm: '현장대리인계', needDocType: 'default'},
        {needDocNm: '공사예정공정표', needDocType: 'default'},
        {needDocNm: '착공계', needDocType: 'default'},
        {needDocNm: '', needDocType: 'other'}, // 직접입력
      ],
    };
  },
  beforeCreate() {
    $mapGetters(this, ['poSubmitDocList']);
    $mapMutations(this, ['setPoSubmitDocList']);
  },
  methods: {
    sheet_onSearchFinish(e) {
      const addedList = this.poSubmitDocList.map(item => String(item.fileNm));
      e.sheet.getDataRows().forEach(row => {
        const needDocNm = row.needDocNm;
        if (addedList.includes(needDocNm)) {
          e.sheet.setValue(row, 'added', true);
          addedList.splice(
            addedList.findIndex(item => item === needDocNm),
            1
          );
        }
      });

      addedList.forEach(needDocNm => {
        e.sheet.addRow({
          next: e.sheet.getLastRow(),
          focus: false,
          init: {needDocNm, needDocType: 'other', added: true},
        });
      });
    },
    sheet_onAfterClick(e) {
      if (e.row.Kind == 'Data' && e.col == 'addOrRemove') {
        const added = e.row.added;
        const value = String(e.row.needDocNm).trim();
        const type = e.row.needDocType;
        e.sheet.setValue(e.row, 'added', !added);

        if (type === 'other') {
          if (!added) {
            if (!value) {
              this.$alert({title: '서류명', message: '추가할 서류명을 입력해야 합니다.'});
              return;
            }
            e.sheet.addRow({init: {needDocNm: '', needDocType: 'other'}});
          } else {
            e.sheet.removeRow(e.row);
          }
        }

        const needFileList = e.sheet.getRowsByChecked('added').map(item => ({
          fileNm: item.needDocNm,
        }));
        this.setPoSubmitDocList(needFileList);
      }
    },
  },
};
</script>

<style scoped></style>
