export default that => {
  return {
    Cfg: {
      CanEdit: false,
      FitWidth: true,
      HeaderMerge: 6,
    },
    LeftCols: [
      {
        Name: 'subCodeName',
        Header: ['소속', '소속'],
        Width: 100,
        Type: 'Text',
        Align: 'Center',
      },
      {Name: 'empName', Header: ['이름', '이름'], Width: 100, Type: 'Text', Align: 'Center'},
      {Name: 'jkdNoName', Header: ['직종', '직종'], Width: 100, Type: 'Text', FormulaRow: '합계', Align: 'Center'},
    ],
    Cols: [
      {
        Name: 'dayQty',
        Header: ['금일투입', '공수'],
        Extend: that.$ibsheetHelper.preset.quantity,
        RelWidth: 1,
        MinWidth: 90,
        FormulaRow: 'Sum',
      },
      {
        Name: 'dayAmt',
        Header: ['금일투입', '금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
        MinWidth: 90,
        FormulaRow: 'Sum',
      },
      {
        Name: 'monthQty',
        Header: ['금월투입', '공수'],
        Extend: that.$ibsheetHelper.preset.quantity,
        RelWidth: 1,
        MinWidth: 90,
        FormulaRow: 'Sum',
      },
      {
        Name: 'monthAmt',
        Header: ['금월투입', '금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
        MinWidth: 90,
        FormulaRow: 'Sum',
      },
      {
        Name: 'totQty',
        Header: ['누계투입', '공수'],
        Extend: that.$ibsheetHelper.preset.quantity,
        RelWidth: 1,
        MinWidth: 90,
        FormulaRow: 'Sum',
      },
      {
        Name: 'totAmt',
        Header: ['누계투입', '금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
        MinWidth: 90,
        FormulaRow: 'Sum',
      },
    ],
  };
};
