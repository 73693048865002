<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <label class="ml5 mr10">단위작업명</label>
      <iui-text type="search" :value.sync="searchText" @enter="onSearchGrid" />
      <iui-button value="검색" @click="onSearchGrid" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onSearchFinish,
        onClick,
      }"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOptions/UnitOpertListPopupSheet.js';
import {selectUnitOpertListAsInput} from '../api/unitOpert.js';
export default {
  props: {
    costType: {
      type: String,
    },
    matGiveYn: {
      type: String,
    },
    planStrdate: {
      type: String,
    },
    planEnddate: {
      type: String,
    },
    inputDate: {
      type: String,
    },
    monPjtCloseAt: {
      type: String,
    },
    isCheck: {
      type: Boolean,
    },
  },
  data() {
    return {
      sheet: undefined,
      sheetUtil: undefined,
      Options: this.isCheck ? $addColCheckbox(options(this)) : options(this),
      loadSearchData: [],
      searchText: '',
    };
  },
  created() {
    this.addEvent({name: 'getRowsByCheckedAndInputDate', func: this.getRowsByCheckedAndInputDate});
    this.onSearch();
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = this.sheet.util;
      if (this.isCheck) {
        sheet.util.onTreeCheck('chk');
      }
    },
    onSearchFinish(e) {
      this.$emit('onRow', e.sheet.getFirstRow());
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.$emit('onRow', e.row);
      }
    },
    async onSearch() {
      const param = {
        resultDate: this.inputDate,
        matGiveYn: this.matGiveYn,
        planStrdate: this.planStrdate,
        planEnddate: this.planEnddate,
      };
      if (!this.isSimpleExec) {
        param.costType = this.costType;
      }
      const response = await selectUnitOpertListAsInput(param);
      const unitOpertTreeList = response.data.unitOpertTreeList;
      this.$emit('onPlanStrdate', response.data.planStrdate);
      this.$emit('onPlanEnddate', response.data.planEnddate);
      let tree = $_treeModelStdCl(unitOpertTreeList, '999901', 'fldrNo', 'uppFldrNo', undefined, undefined, 'fldrNm');
      this.loadSearchData = tree({folder: true, fldrNo: undefined});
    },
    getRowsByCheckedAndInputDate(callback) {
      const rows = this.sheet.getRowsByChecked('chk');
      if (this.monPjtCloseAt !== 'Y' && callback instanceof Function) {
        callback({inputDate: this.inputDate, rows: rows.filter(row => !row.folder)});
      }
    },
    onSearchGrid() {
      this.sheetUtil.treeSearch('fldrNm', this.searchText);
    },
  },
};
</script>

<style></style>
