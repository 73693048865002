<template>
  <pmis-tab-box>
    <template #title>자료목록</template>
    <template #header-left>
      <!-- <iui-select :items="searchItems" :value.sync="searchSe" /> -->
      <label>자료명칭</label>
      <iui-text type="search" :value.sync="searchNm" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="loadSearchData"
      @loadSheet="sheet = $event"
      :events="{
        onSearchFinish: sheet_onSearchFinish,
        onFocus: sheet_onFocus,
      }"
    />
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/DocListSheet.js';
import {selectDocList} from '@/view/edms/api/edms.js';

export default {
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],
      isLoad: false,

      searchItems: [
        {text: $getConstants('DOC_SEARCH_ITEM1').name, value: $getConstants('DOC_SEARCH_ITEM1').code},
        {text: $getConstants('DOC_SEARCH_ITEM2').name, value: $getConstants('DOC_SEARCH_ITEM2').code},
      ],
      searchSe: $getConstants('DOC_SEARCH_SE').code,
      searchNm: '',
    };
  },
  computed: {
    focusKey() {
      return this.focusKeys.docNo;
    },
  },
  beforeCreate() {
    $mapGetters(this, ['sheetData', 'focusKeys']);
    $mapMutations(this, ['onSetDetailInfo', 'onInitDetailInfo', 'setFocusKeys']);
  },
  created() {
    this.addEvent([{name: 'DocList_Search', func: this.onSearch}]);
  },
  methods: {
    onSearch(includeClosedPrj) {
      includeClosedPrj = typeof includeClosedPrj === 'boolean' ? includeClosedPrj : false;
      this.isLoad = false;
      selectDocList({
        pgmCd: this.sheetData.pgmCd,
        prjCd: this.sheetData.prjCd,
        fldrNo: this.sheetData.fldrNo,
        fldrCd2: this.sheetData.fldrCd2,
        searchSe: this.searchSe,
        searchNm: this.searchNm,
        includeClosedPrj,
      }).then(response => {
        this.loadSearchData = response.data;
      });
    },
    onSetData(row) {
      this.callEvent({name: 'DetailInfo_InitCud'});

      if (row === undefined) {
        this.onInitDetailInfo();
      } else {
        this.onSetDetailInfo(row);
      }
    },
    sheet_onSearchFinish(e) {
      this.isLoad = true;

      if (e.sheet.getTotalRowCount()) {
        const focusedRow = e.sheet.getDataRows().find(row => row.docNo === this.focusKey);
        if (focusedRow) {
          e.sheet.focus(focusedRow);
          this.setFocusKeys({docNo: 0});
        } else {
          this.onSetData(e.sheet.getFirstRow());
        }
      } else {
        this.onSetData(undefined);
      }
    },
    sheet_onFocus(e) {
      if (this.isLoad) this.onSetData(e.row);
    },
  },
};
</script>

<style></style>
