<template>
  <pmis-content-box>
    <iui-container-new type="css-flex">
      <i-row height="50px">
        <iui-container-new type="table" theme="bullet">
          <i-row height="50px">
            <i-col-header>답변내용</i-col-header>
          </i-row>
        </iui-container-new>
      </i-row>
      <i-row height="245px">
        <i-col>
          <Editor v-if="boardStatus == 'A'" height="245px" ref="Editor" initialEditType="wysiwyg" />
          <Viewer v-else ref="Viewer" @load="onViewerLoad" />
        </i-col>
      </i-row>
      <i-row height="30px">
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col-header>답변자</i-col-header>
            <i-col>
              <iui-text width="120px" :value="detailInfo.rqNm" :enable="false" />
            </i-col>
            <i-col-header>답변일시</i-col-header>
            <i-col>
              <iui-text width="150px" :value="detailInfo.rqDttm" :enable="false" />
            </i-col>
            <i-col-header>첨부파일</i-col-header>
            <i-col>
              <!-- 답변상태 : 첨부파일 미존재시 클릭불가 -->
              <pmis-file-list
                :modal="true"
                :companyCode="'000000'"
                :fileNumber="detailInfo.flNo2"
                :id="'fileComp_Answer' + _uid"
                flNoCol="flNo2"
                :modalBtnEnable="!(detailInfo.rqNm && detailInfo.flNo2 == 0) || boardStatus == 'A'"
                :saveAuth="userInfo.frmGb == $getConstants('FRM_GB_0').code"
                @delete-complete="onDeleteComplete"
              />
            </i-col>
          </i-row>
        </iui-container-new>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import {Editor, Viewer} from '@toast-ui/vue-editor';
import {saveQnaAnswerInfo} from '@/view/board/qna/api/qna.js';
export default {
  components: {
    Editor,
    Viewer,
  },
  props: {
    boardStatus: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      modalBtnsFile: [
        {name: '확인', callback: this.onConfirmFilePopup},
        {name: '닫기', callback: this.onCloseFilePopup},
      ],
      files: 0,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['detailInfo']);
    $mapMutations(this, ['setDetailInfo']);
  },
  created() {
    this.addEvent([
      {name: 'Qna_Answer_onSave', func: this.onSave},
      {name: 'Qna_Answer_onAnswerLoad', func: this.onAnswerLoad},
    ]);
  },
  methods: {
    onViewerLoad(e) {
      e.options.el.style = 'height:95%; padding: 10px 25px 0px 25px; border: 1px solid #e5e5e5; overflow:auto;';
    },
    onAnswerLoad() {
      if (this.boardStatus == 'A') {
        // 답변등록 화면
        this.$refs.Editor.invoke('setHtml', this.detailInfo.rqCts);
        this.setDetailInfo({rqId: this.userInfo.empNo, rqNm: this.userInfo.name});
      } else {
        // 답변조회 화면(질문자)
        this.$refs.Viewer.invoke('setMarkdown', this.detailInfo.rqCts);
      }
    },
    async onSave() {
      this.setDetailInfo({rqCts: this.$refs.Editor.invoke('getHtml').trim()});

      let args = {
        url: '/qna/saveQnaAnswerInfo',
        param: {...this.detailInfo, cud: 2},
        mode: 'simple',
        title: '답변 저장',
        message: '답변을 저장하시겠습니까?',
        validator: () => {
          if (!this.detailInfo.rqCts) {
            this.$alert({title: '내용 없음', message: '입력된 내용이 없습니다.'});
            return false;
          }
          return true;
        },
      };
      this.$save(args).then(response => {
        if (response.status == 200) {
          this.location.go(-1);
        }
      });
    },
    async onDeleteComplete(flNo2) {
      if (flNo2 == 0) {
        this.detailInfo.flNo2 = flNo2;
        await saveQnaAnswerInfo({...this.detailInfo}).then(response => {});
        this.callEvent({name: 'QnaBorderView_onSearch'});
      }
    },
  },
};
</script>

<style></style>
