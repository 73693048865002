<template>
  <pmis-content-box>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onSearchFinish, onClick}"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOptions/UnitOpertListSheet.js';
import {selectContWplanMList} from '../api/task.js';

export default {
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this), undefined, undefined, {
        CanEditFormula: () => this.prjFlag != $getConstants('PRJ_FLAG1').code,
      }),
      loadSearchData: [],
    };
  },

  watch: {
    prjFlag(val) {
      this.sheet.getDataRows().forEach(row => {
        this.sheet.refreshRow(row);
        this.sheet.setAttribute(row, 'chk', 'CanEdit', val != $getConstants('Y').code);
        this.sheet.setAttribute(row, 'wbsNm', 'CanEdit', val != $getConstants('Y').code && this.detailInfo.isPlnWgh);
        this.sheet.setAttribute(row, 'plnWgh', 'CanEdit', val != $getConstants('Y').code && this.detailInfo.isPlnWgh);
        this.sheet.setAttribute(
          row,
          'planStrdate',
          'CanEdit',
          val != $getConstants('Y').code && this.detailInfo.isPlnWgh
        );
        this.sheet.setAttribute(
          row,
          'planEnddate',
          'CanEdit',
          val != $getConstants('Y').code && this.detailInfo.isPlnWgh
        );
        this.sheet.setAttribute(row, 'risk', 'CanEdit', val != $getConstants('Y').code && this.detailInfo.isPlnWgh);
      });
    },
  },

  beforeCreate() {
    $mapGetters(this, ['isRoot', 'detailInfo', 'prjFlag']);
  },

  created() {
    this.addEvent([
      {name: 'unitOperListAddRow', func: this.addRow},
      {name: 'unitOpertListGetJsonSave', func: this.getJsonSave},
      {name: 'selectContWplanMList', func: this.onSearch},
      {name: 'unitOpertListGetFocusedRow', func: this.getFocusedRow},
      {name: 'unitOpertListGetRowsByChecked', func: this.getRowsByChecked},
    ]);
    this.onSearch();
  },

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onSearchFinish(e) {
      const row = e.sheet.getFirstRow();
      if (!row) return;
      this.callEvent({
        name: 'selectContWcontplanMList',
        param: {
          wbsCd: row.wbsCd,
          wbsId: row.wbsId,
        },
      });
    },
    onClick(e) {
      if (e.kind === 'Data') {
        this.callEvent({
          name: 'selectContWcontplanMList',
          param: {
            wbsCd: e.row.wbsCd,
            wbsId: e.row.wbsId,
          },
        });
      }
    },
    addRow() {
      if (!this.detailInfo.isPlnWgh) {
        this.$alert({title: '신규', message: '물량분개비율을 입력한 하위작업분류를 선택해주세요.'});
        return;
      }
      this.sheet.addRow();
    },
    getJsonSave(callback) {
      if (callback) {
        callback(this.sheet.getSaveJson(0).data);
      }
    },
    getRowsByChecked(callback) {
      if (callback) {
        callback(this.sheet.getRowsByChecked('chk'));
      }
    },
    async onSearch() {
      let param = {
        wbsCd: this.detailInfo.fldrNo,
      };
      const response = await selectContWplanMList(param);
      this.loadSearchData = response.data;
    },
    getFocusedRow(callback) {
      if (!callback) return;
      let row = this.sheet.getFocusedRow();
      callback(row);
    },
  },
};
</script>