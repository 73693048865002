<template>
  <div id="app">
    <component v-bind:is="currentView" type="mobile"></component>
  </div>
</template>

<script>
import JoinStep01 from '@/view/member/components/step/JoinStep01';
import HomepageMain from '@/homepage/HomepageMain';

export default {
  data() {
    return {
      currentView: null,
    };
  },
  created() {},
  async mounted() {
    switch (params.page) {
      case '1':
        this.currentView = JoinStep01;
        this.mobilePrivacy();

        break;
      default:
        this.currentView = HomepageMain;
        break;
    }
  },
  methods: {
    mobilePrivacy() {
      if (document.querySelector('#privacyPolicy')) {
        const privacy = document.querySelector('#privacyPolicy').cloneNode(true);
        const contents = document.querySelector('#privacyPolicy').closest('.contents');

        contents.className = contents.classList + ' mobile';
        contents.innerHTML = '';
        contents.appendChild(privacy);
      }
    },
  },
};
</script>

<style>
.mobile #privacyPolicy > p {
  margin-left: 10pt !important;
  margin-right: 5pt !important;
}

.mobile #privacyPolicy > ul {
  margin-left: 0pt !important;
  margin-right: 5pt !important;
}
</style>
