/** 회사정보등록 index */
export const saveCompanyInfo = (param, callback) => $save('/companyRegist/saveCompanyInfo', param, callback);
/** 회사정보 CompanyDetail */
export const selectCompanyInfo = param => axios.post('/companyRegist/selectCompanyInfo', param);
/** 발신명의 DocManageInfo */
export const saveDocnmList = (param, callback) => $save('/companyRegist/saveDocnmList', param, callback);
export const deleteDocnmList = param => axios.post('/companyRegist/deleteDocnmList', param);
export const saveDjFlNo = param => axios.post('/companyRegist/saveDjFlNo', param);
export const selectDocnmList = param => axios.post('/companyRegist/selectDocnmList', param);
export const updateFlNo = param => $save('/companyRegist/updateFlNo', param);
/** 보유면허정보 HoldLcnsInfo*/
export const selectCompanyLicenseList = param => axios.post('/companyRegist/selectCompanyLicenseList', param);
export const deleteLicenseList = param => axios.post('/companyRegist/deleteLicenseList', param);
export const saveLicenseList = param => axios.post('/companyRegist/saveLicenseList', param);
/** 납품공사지역정보 AreaInfo */
export const selectAreaList = param => axios.post('/companyRegist/selectAreaList', param);
export const saveAreaList = param => $save('/companyRegist/saveAreaList', param);
export const deleteAreaList = param => axios.post('/companyRegist/deleteAreaList', param);
/** 공급자재정보 MtrilInfo*/
export const selectMatList = param => axios.post('/companyRegist/selectMatList', param);
export const saveMatList = param => $save('/companyRegist/saveMatList', param);
export const deleteMatList = param => axios.post('/companyRegist/deleteMatList', param);
/** 보유면허정보(세부공종) WorkTypeInfo */
export const selectGcodeList = param => axios.post('/companyRegist/selectGcodeList', param);
export const saveGcodeList = param => $save('/companyRegist/saveGcodeList', param);
export const deleteGcodeList = param => axios.post('/companyRegist/deleteGcodeList', param);
/** 회사목록 CompanyList*/
export const selectCompanyNotConfirmList = param => axios.post('/companyRegist/selectCompanyNotConfirmList', param);
export const selectCompanyList = param => axios.post('/companyRegist/selectCompanyList', param);
/** 대표정보 CeoInfo*/
export const selectCeoList = param => axios.post('/companyRegist/selectCeoList', param);
export const saveCeoList = param => $save('/companyRegist/saveCeoList', param);
export const deleteCeoList = param => axios.post('/companyRegist/deleteCeoList', param);
/** 기초정보 BaseInfo*/
export const selectBaseInfoList = param => axios.post('/baseCompanyRegist/selectBaseInfoList', param);
export const selectMenuListCount = param => axios.post('/baseCompanyRegist/selectMenuListCount', param);
export const saveCopyBaseInfoList = param => axios.post('/baseCompanyRegist/saveCopyBaseInfoList', param);
/** 공급자재정보 MatList */
export const selectMaterialClList = param => axios.post('/matCodeRegist/selectMaterialClList', param);
/** 회원가입등록 Index */
export const updateCompanyUseFlag = param => axios.post('/companyRegist/updateCompanyUseFlag', param);
/** 회사정보 JoinInfo */
export const selectCompanyExistBsnNo = param => axios.post('/companyRegist/selectCompanyExistBsnNo', param);
export const selectPgmCd = param => axios.post('/companyRegist/selectPgmCd', param);
export const noAuthSaveCompanyInfo = param => $save('/companyRegist/noAuthSaveCompanyInfo', param);
