import workType from '@/view/estmtManage/prjWorkTypeRegist/store/ContWorkTypeRegist.js';
const state = {
  degreeList: [], //차수리스트
  masterInfo: {
    chgDegree: 0, //변경차수
    bldRmk: '', // 변경사유
    prjCd: '', // 현장코드
    codeGb: '', // 내역구분
    prjFlag: '', //확정Flag
  },
  currentDegree: '', //선택차수
};

const getters = {
  degreeList: state => state.degreeList, //차수정보
  degreeListAsSelect: state =>
    state.degreeList.map(x => {
      return {text: x + '차', value: x};
    }),
  maxDgree: state => {
    if (state.degreeList.slice(-1).pop()) {
      return String(state.degreeList.slice(-1).pop());
    } else {
      return '';
    }
  },
  masterInfo: (state, getters) => {
    state.masterInfo.chgDegree = String(getters.maxDgree) || '0';
    return state.masterInfo;
  },
  currentDegree: state => state.currentDegree,
};

const mutations = {
  setDegreeList(state, degreeList) {
    state.degreeList = degreeList;
  },
  setMasterInfo(state, payload) {
    for (let key in state.masterInfo) {
      if (payload && typeof payload[key] != 'undefined') {
        state.masterInfo[key] = payload[key];
      }
    }
  },
  setCurrentDegree(state, payload) {
    state.currentDegree = payload;
  },
};

const modules = {
  workType,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  modules,
};
