import {numToKr} from 'vue-number-to-kor';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import {PO_COLUMNS} from '@/view/bidding/js/BiddingColumns.js';
import {selectPoInfos} from '@/view/bidding/po/mat/api/po.js';
import {selectPoList, selectPoSeqList} from '@/view/bidding/poCustomer/mat/api/poCustomer.js';

const state = {
  poInfo: {...PO_COLUMNS},
  poList: [],
  poContSeqList: [],
  poItemList: [],
  poNeedFileList: [],
};

const getters = {
  bidType: () => 'mat',
  poInfo: state => state.poInfo,
  poList: state => state.poList,
  poContSeqList: state => state.poContSeqList,
  poItemList: state => state.poItemList,
  poNeedFileList: state => state.poNeedFileList,

  poStatus: state => getPoStatus(state),
};

const mutations = {
  setPoInfo(state, payload) {
    const poInfo = state.poInfo;

    for (const key in poInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        poInfo[key] = payload[key];
      }
    }

    poInfo.poNo2 = COMMON_FUNCTION.getMakeFormat(poInfo.poNo);
    poInfo.amtKor = poInfo.poAmt ? numToKr(poInfo.poAmt.toFixed(0)) : numToKr(0);
    poInfo.vatAmtKor = poInfo.vatAmt ? numToKr(poInfo.vatAmt.toFixed(0)) : numToKr(0);
    poInfo.totAmtKor = poInfo.totAmt ? numToKr(poInfo.totAmt.toFixed(0)) : numToKr(0);
    poInfo.beforePoAmtKor = poInfo.beforePoAmt ? numToKr(poInfo.beforePoAmt.toFixed(0)) : numToKr(0);
    poInfo.beforeVatAmtKor = poInfo.beforeVatAmt ? numToKr(poInfo.beforeVatAmt.toFixed(0)) : numToKr(0);
    poInfo.beforeTotAmtKor = poInfo.beforeTotAmt ? numToKr(poInfo.beforeTotAmt.toFixed(0)) : numToKr(0);
  },
  initPoInfo(state) {
    state.poInfo = {...PO_COLUMNS};
  },
  setPoList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.poList = payload;
    }
  },
  setPoContSeqList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.poContSeqList = payload;
    }
  },
  setPoItemList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.poItemList = payload;
    }
  },
  setPoNeedFileList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.poNeedFileList = payload;
    }
  },
};

const actions = {
  clearAll({commit}) {
    commit('initPoInfo');
    commit('setPoContSeqList', []);
    commit('setPoItemList', []);
    commit('setPoNeedFileList', []);
  },
  /**
   * 계약목록
   */
  async searchPoList({commit, dispatch}, searchOptions) {
    const poList = (await selectPoList(searchOptions)).data;
    commit('setPoList', poList);
    if (!poList?.length) {
      dispatch('clearAll');
    }
  },
  /**
   * 계약차수목록 조회
   *
   * @param {*} poNo
   */
  async searchPoContSeqList({commit}, poNo) {
    const poContSeqList = (await selectPoSeqList(poNo)).data;
    commit('setPoContSeqList', poContSeqList);
  },
  async searchPoInfos({commit}, param) {
    const {poNo, contSeq} = param;

    const response = await selectPoInfos(poNo, contSeq);
    const poInfo = response.data.poInfo;
    const poItemList = response.data.poItemList;
    const poNeedFileList = response.data.poNeedFileList;

    commit('setPoInfo', poInfo);
    commit('setPoItemList', poItemList);
    commit('setPoNeedFileList', poNeedFileList);
  },
};

function getPoStatus(state) {
  const poInfo = state.poInfo;
  const poStatus = {
    isChangeContSeq: poInfo.contSeq > BIDDING_CONSTANTS.CODES.FIRST_CONT_SEQ, // 변경
    isLastContSeq: poInfo.contSeq === poInfo.lastContSeq,

    isPoSend: poInfo.poSt === BIDDING_CONSTANTS.PROGRESS_STATE.PO_SIGN_READY, //거래처전자서명중 (발주서송부)
    isPoSignComplete: poInfo.poSt === BIDDING_CONSTANTS.PROGRESS_STATE.PO_SIGN_COMPLETE, // 거래처전자서명
    isPoComplete: poInfo.poSt === BIDDING_CONSTANTS.PROGRESS_STATE.PO_COMPLETE, // 계약완료 (발주서접수)

    isPotypeUnit: poInfo.poTypecd === BIDDING_CONSTANTS.CODES.POTYPE_UNIT, // 계약구분 - 단가계약
    isPayDirect: poInfo.payCashYn === BIDDING_CONSTANTS.CODES.PAY_DIRECT,
    isPayCash: poInfo.payCashYn === BIDDING_CONSTANTS.CODES.PAY_CASH,
    isPayDefer: poInfo.payCashYn === BIDDING_CONSTANTS.CODES.PAY_DEFER,
    isTaxDirect: poInfo.taxTypecd === BIDDING_CONSTANTS.CODES.TAX_DIRECT,
    isDelayRateY: poInfo.delayRateYn === BIDDING_CONSTANTS.CODES.DELAY_RATE_Y,
    isFlawInsrYear: poInfo.flawInsrYn === BIDDING_CONSTANTS.CODES.FLAW_INSR_YEAR,
    isFlawInsrMonth: poInfo.flawInsrYn === BIDDING_CONSTANTS.CODES.FLAW_INSR_MONTH,
    isStamp: poInfo.stampTaxYn === BIDDING_CONSTANTS.CODES.STAMP_TAX_Y, // 인지세 - 있음
  };

  return poStatus;
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
