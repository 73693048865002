<template>
  <pmis-content-box
    >{{ rgprId }}
    <pmis-file-list
      id="contFiles"
      :company-code="pgmCd"
      :file-number.sync="flNo"
      :toolbar-visible="isVisible"
      :changed.sync="fileChanged"
      v-model="files"
      @save-complete="file_save_complete"
      @save-error="file_save_error"
      @delete-complete="file_delete_complete"
      @delete-error="file_delete_error"
    />
  </pmis-content-box>
</template>

<script>
export default {
  data() {
    return {
      flNo: '',
      isVisible: true,
      fileChanged: false,
      files: [],
      rgprId: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, {ctrctInfo: 'ctrctInfo'});
  },
  created() {
    this.flNo = this.ctrctInfo.flNo;
    this.rgprId = this.ctrctInfo.rgprId;
    this.isVisible = this.ctrctInfo.prjFlag != $getConstants('PRJ_FLAG1').code;
    this.addEvent({name: 'AttachFilePop_callbackData', func: this.onConfirm});
  },
  methods: {
    file_save_complete() {},
    file_save_error() {
      this.$alert({title: '저장', message: '파일 저장을 실패했습니다.'});
    },
    file_delete_complete() {
      setTimeout(() => {
        if (0 == this.files.length) {
          this.flNo = 0;
        }
      }, 300);
    },
    file_delete_error() {
      this.$alert({title: '삭제', message: '파일 저장을 실패했습니다.'});
    },
    onConfirm(callback) {
      // if (this.fileChanged) {
      //   this.$alert('파일정보가 변경되었습니다.<br/>파일정보를 저장하시기 바랍니다.');
      //   return;
      // }
      callback(this.flNo);
    },
  },
};
</script>

<style></style>
