export default that => {
  return {
    Cfg: {
      CanEdit: false,
      HeaderMerge: 6,
    },
    Def: {
      Row: {
        CanFocus: 0,
        CanFormula: 1,
      },
      Col: {
        ColMerge: 0,
      },
    },
    Cols: [
      {
        Name: 'mgName',
        Header: ['공종명', '공종명'],
        Width: 150,
        ColMerge: 1,
        VAlign: 'Top',
      },
      {
        Name: 'gb',
        Header: ['구분', '구분'],
        Width: 80,
        Format: {PRE: '전월누계', CUR: '당월', TOT: '당월누계', REM: '잔여추정', SUM: '합계'},
        Align: 'Center',
      },
      {
        Name: 'contBudgetAmt',
        Header: ['도급금액', '도급금액'],
        MinWidth: 90,
        RelWidth: 1,
        ColMerge: 1,
        VAlign: 'Top',
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'execBudgetAmt',
        Header: ['실행예산', {Value: '금액', Color: '#dff9fb'}],
        MinWidth: 90,
        RelWidth: 1,
        ColMerge: 1,
        VAlign: 'Top',
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'execBudgetRate',
        Header: ['실행예산', {Value: '%', Color: '#dff9fb'}],
        MinWidth: 90,
        RelWidth: 1,
        ColMerge: 1,
        VAlign: 'Top',
        Extend: that.$ibsheetHelper.preset.rate,
      },
      {
        Name: 'contAmt',
        Header: ['도급기성', {Value: '금액', Color: '#dff9fb'}],
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'contRate',
        Header: ['도급기성', {Value: '%', Color: '#dff9fb'}],
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.rate,
      },
      {
        Name: 'execAmt',
        Header: ['실행기성', {Value: '금액', Color: '#dff9fb'}],
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'execRate',
        Header: ['실행기성', {Value: '%', Color: '#dff9fb'}],
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.rate,
      },
      {
        Name: 'conCostAmt',
        Header: ['공사원가', {Value: '금액', Color: '#dff9fb'}],
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'conCostRate',
        Header: ['공사원가', {Value: '%', Color: '#dff9fb'}],
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.rate,
      },
      {
        Name: 'contProfitAmt',
        Header: ['도급손익', '도급손익'],
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'execProfitAmt',
        Header: ['실행손익', '실행손익'],
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
    ],
  };
};
