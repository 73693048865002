import {numToKr} from 'vue-number-to-kor';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import {SUB_PO_COLUMNS} from '@/view/bidding/js/BiddingColumns.js';
import {selectPoContSeqList, selectPoList} from '@/view/bidding/poCustomer/sub/api/subPoCustomer.js';
import {selectSubPoInfos} from '@/view/bidding/po/sub/api/subPo.js';

const state = {
  poInfo: {...SUB_PO_COLUMNS},
  poList: [],
  poContSeqList: [],
  poItemList: [],
  poNeedFileList: [],
};

const getters = {
  bidType: () => 'sub',
  poInfo: state => state.poInfo,
  poList: state => state.poList,
  poContSeqList: state => state.poContSeqList,
  poItemList: state => state.poItemList,
  poNeedFileList: state => state.poNeedFileList,

  poStatus: state => getPoStatus(state),
};

const mutations = {
  setPoInfo(state, payload) {
    const poInfo = state.poInfo;

    for (const key in poInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        poInfo[key] = payload[key];
      }
    }

    poInfo.poNo2 = COMMON_FUNCTION.getMakeFormat(poInfo.poNo);
    poInfo.amtKor = numToKr(poInfo.poAmt || 0);
    poInfo.vatAmtKor = numToKr(poInfo.vatAmt || 0);
    poInfo.totAmtKor = numToKr(poInfo.totAmt || 0);
    poInfo.beforePoAmtKor = numToKr(poInfo.beforePoAmt || 0);
    poInfo.beforeVatAmtKor = numToKr(poInfo.beforeVatAmt || 0);
    poInfo.beforeTotAmtKor = numToKr(poInfo.beforeTotAmt || 0);
    poInfo.delayAmt = poInfo.totAmt && poInfo.delayRate ? (poInfo.totAmt * poInfo.delayRate) / 100 : 0;
  },
  initPoInfo(state) {
    state.poInfo = {...SUB_PO_COLUMNS};
  },
  setPoList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.poList = payload;
    }
  },
  setPoContSeqList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.poContSeqList = payload;
    }
  },
  setPoItemList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.poItemList = payload;
    }
  },
  setPoNeedFileList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.poNeedFileList = payload;
    }
  },
};

const actions = {
  clearAll({commit}) {
    commit('initPoInfo');
    commit('setPoContSeqList', []);
    commit('setPoItemList', []);
    commit('setPoNeedFileList', []);
  },
  /**
   * 계약목록
   */
  async searchPoList({commit, dispatch}, searchOptions) {
    const poList = (await selectPoList(searchOptions)).data;
    commit('setPoList', poList);
    if (!poList?.length) {
      dispatch('clearAll');
    }
  },

  /**
   * 계약차수목록
   */
  async searchPoContSeqList({commit}, poNo) {
    const poContSeqList = (await selectPoContSeqList(poNo)).data;
    commit('setPoContSeqList', poContSeqList);
  },

  async searchPoInfos({commit}, param) {
    commit('initPoInfo');
    commit('setPoItemList', []);
    commit('setPoNeedFileList', []);

    const {poNo, contSeq} = param;

    // TODO : ETS_INTERFACE_EXCEPTION : 전자수입인지 연계 오류 - 발생하는 경우 처리해야함
    const response = await selectSubPoInfos(poNo, contSeq);
    const {poInfo, poItemList, poNeedFileList} = response.data;

    commit('setPoInfo', poInfo);
    commit('setPoItemList', poItemList);
    commit('setPoNeedFileList', poNeedFileList);
  },
};

function getPoStatus(state) {
  const poInfo = state.poInfo;
  const poStatus = {
    isChangeContSeq: poInfo.contSeq > BIDDING_CONSTANTS.CODES.FIRST_CONT_SEQ, // 변경
    isLastContSeq: poInfo.contSeq === poInfo.lastContSeq,

    isPoSend: poInfo.poSt === BIDDING_CONSTANTS.PROGRESS_STATE.PO_SIGN_READY, //거래처전자서명중 (발주서송부)
    isPoSignComplete: poInfo.poSt === BIDDING_CONSTANTS.PROGRESS_STATE.PO_SIGN_COMPLETE, // 거래처전자서명
    isPoComplete: poInfo.poSt === BIDDING_CONSTANTS.PROGRESS_STATE.PO_COMPLETE, // 계약완료 (발주서접수)

    isPayDirect: poInfo.payCashYn === BIDDING_CONSTANTS.CODES.PAY_DIRECT,
    isPayCash: poInfo.payCashYn === BIDDING_CONSTANTS.CODES.PAY_CASH,
    isPayDefer: poInfo.payCashYn === BIDDING_CONSTANTS.CODES.PAY_DEFER,
    isTaxDirect: poInfo.taxTypecd === BIDDING_CONSTANTS.CODES.TAX_DIRECT,
    isDelayRateY: poInfo.delayRateYn === BIDDING_CONSTANTS.CODES.DELAY_RATE_Y,
    isFlawInsrYear: poInfo.flawInsrYn === BIDDING_CONSTANTS.CODES.FLAW_INSR_YEAR,
    isFlawInsrMonth: poInfo.flawInsrYn === BIDDING_CONSTANTS.CODES.FLAW_INSR_MONTH,
    isStamp: poInfo.stampTaxYn === BIDDING_CONSTANTS.CODES.STAMP_TAX_Y, // 인지세 - 있음
  };

  return poStatus;
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
