<template>
  <pmis-page>
    <iui-button @click="this.ebiz4u">ebiz4u 연결</iui-button>
    <iui-modal name="phonePopup" title="추가정보" :btns="modalBtn" width="500px" height="200px">
      <PhonePopup :uid="_uid" :tlno="tlno" :email="email" />
    </iui-modal>
  </pmis-page>
</template>

<script>
import PhonePopup from '@/view/bidding/pr/ebiz4u/components/PhonePopup.vue';
import {selectWorkerInfo} from '@/view/user/Worker/api/worker.js';
export default {
  components: {PhonePopup},
  data() {
    return {
      email: undefined,
      tlno: undefined,
      modalBtn: [{name: '확인', callback: this.onHideModal}],
    };
  },
  created() {},
  methods: {
    async ebiz4u() {
      try {
        let response;
        response = await ebiz4uSession({});
        if (!response.data.sessionKey) {
          const userInfo = await selectWorkerInfo({empNo: this.userInfo.empNo});
          this.email = `${userInfo.data.email1}@${userInfo.data.email2}`;
          this.tlno = userInfo.data.tlno;
          if (!userInfo.data.tlno || !userInfo.data.email1 || !userInfo.data.email2) {
            this.onShowModal();
            return;
          }
          response = await ebiz4uJoin({});
        }
        if (!response.data.sessionKey) {
          this.$alert({title: 'ebiz4u', message: 'ebiz4u 자동회원가입에 실패했습니다.'});
          return;
        }
        let url = response.data.result.split('?')[0];
        let userId = `${this.userInfo.pgmCd}_${this.userInfo.empNo}`;
        let sessionKey = response.data.sessionKey;
        $_openWindow(url, {
          cmd: 'apiLogin',
          subcmd: 'login',
          user_id: userId,
          sessionKey: sessionKey,
        });
      } catch (error) {
        console.log(error);
      }
    },
    onShowModal() {
      this.$modal.show('phonePopup');
    },
    onHideModal() {
      this.callEvent({name: 'updateTlno'});
      this.$modal.hide('phonePopup');
    },
  },
};
</script>

<style></style>
