export default that => {
  return {
    Def: {
      Row: {
        VAlign: 'TOP',
      },
    },
    Cfg: {
      CanEdit: false,
      HeaderMerge: 3,
    },
    LeftCols: [
      {Name: 'total', Visible: false},
      {
        Name: 'midCode',
        Visible: false,
      },
      {
        Name: 'midName',
        Header: [{Value: '계약내역', Color: '#7ed6df'}, '공종명'],
        Type: 'Text',
        Width: 140,
      },
      {
        Name: 'itemCode',
        Header: [{Value: '계약내역', Color: '#7ed6df'}, '도급코드'],
        Type: 'Text',
        Align: 'Center',
        Width: 130,
        Visible: false,
      },
      {
        Name: 'itemName',
        Header: [{Value: '계약내역', Color: '#7ed6df'}, '내역명'],
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 140,
      },
      {
        Name: 'ssize',
        Header: [{Value: '계약내역', Color: '#7ed6df'}, '규격'],
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 140,
      },
      {
        Name: 'unit',
        Header: [{Value: '계약내역', Color: '#7ed6df'}, '단위'],
        Type: 'Text',
        Align: 'Center',
        Width: 65,
        Format: $getConstants('UNIT').code,
      },
      {
        Name: 'contPrice',
        Header: [{Value: '계약내역', Color: '#7ed6df'}, '단가'],
        Width: 100,
        Extend: that.$ibsheetHelper.preset.amount,
      },
      {
        Name: 'contQty',
        Header: [{Value: '계약내역', Color: '#7ed6df'}, '수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 70,
      },
      {
        Name: 'contAmt',
        Header: [{Value: '계약내역', Color: '#7ed6df'}, '금액'],
        Extend: that.$ibsheetHelper.preset.amountFormula,
        Width: 120,
      },
      {
        Name: 'preFixCnt',
        Header: [{Value: '전회누계기성', Color: '#7ed6df'}, '수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 70,
      },
      {
        Name: 'preFixAmt',
        Header: [{Value: '전회누계기성', Color: '#7ed6df'}, '금액'],
        Extend: that.$ibsheetHelper.preset.amountFormula,
        Width: 120,
      },
      {
        Name: 'curFixCnt',
        Header: [{Value: '금회기성', Color: '#7ed6df'}, '수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 70,
      },
      {
        Name: 'curFixAmt',
        Header: [{Value: '금회기성', Color: '#7ed6df'}, '금액'],
        Extend: that.$ibsheetHelper.preset.amountFormula,
        Width: 120,
      },
      {
        Name: 'totFixCnt',
        Header: [{Value: '누계기성', Color: '#7ed6df'}, '수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 70,
      },
      {
        Name: 'totFixAmt',
        Header: [{Value: '누계기성', Color: '#7ed6df'}, '금액'],
        Extend: that.$ibsheetHelper.preset.amountFormula,
        Width: 120,
      },
      {
        Name: 'fixRate',
        Header: [{Value: '누계', Color: '#7ed6df'}, '기성율'],
        Extend: that.$ibsheetHelper.preset.rate,
        Width: 80,
        Visible: false,
      },
    ],
  };
};
