export default that => {
  return {
    Cfg: {
      SearchMode: 2,
      CanEdit: 0,
    },
    Def: {
      Row: {
        NoColor: 1,
      },
    },
    Cols: [
      {
        Header: '현장코드',
        Name: 'prjEstCd',
        Width: 80,
        Align: 'center',
      },
      {
        Header: '현장명',
        Name: 'prjEstNm',
        RelWidth: 1,
        MinWidth: 120,
      },
      {
        Header: '원도급사',
        Name: 'contcompNm',
        Align: 'center',
        Width: 150,
      },
      {
        Header: '발주처',
        Name: 'orderNm',
        Align: 'center',
        Width: 150,
      },
      {Name: 'orderGroup', Visible: false}, // 발주처구분
      {Name: 'orderCode', Visible: false}, // 발주처코드
      {Name: 'contcompCode', Visible: false}, // 원도급사
      {Name: 'workKind', Visible: false}, // 도급종류
    ],
  };
};
