<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col width="370px">
        <FormList />
      </i-col>
      <i-spacer />
      <i-col>
        <Form />
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import FormList from './FormList.vue';
import Form from './Form.vue';
export default {
  components: {
    FormList,
    Form,
  },
};
</script>

<style scoped></style>
