export default that => {
  return {
    Cfg: {
      CanEdit: 0,
    },
    Cols: [
      {
        Header: '마감년월',
        Name: 'closeMm',
        Align: 'center',
        Type: 'Text',
        Width: 80,
        CustomFormat: v => v.substr(0, 4) + '-' + v.substr(4),
      },
      {
        Header: '담당자',
        Name: 'name',
        Align: 'center',
        Type: 'Text',
        RelWidth: 1,
      },
      {
        Header: '마감일시',
        Name: 'rgsDttm',
        Align: 'center',
        Type: 'Text',
        RelWidth: 2,
      },
    ],
  };
};
