<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <div>가동일자</div>
      <iui-datepicker
        name="runDt"
        :value="searchInfo.runDt"
        @change="onChangeRunDt($event)"
        :open.sync="searchRunDtOpen"
      />
      <i class="prev-arrow" v-on:click="onChangeDate('PREV')" />
      <i class="post-arrow" v-on:click="onChangeDate('POST')" />
    </template>
    <template v-slot:header-right>
      <div>유종</div>
      <iui-select
        :p-code="$getConstants('USE_OIL').code"
        :value="oilInfo.useOil"
        @change="value => (oilInfo.useOil = value)"
      />
      <div>단가</div>
      <iui-text type="unitAmount" :value="oilInfo.oilPrice" @change="oilInfo.oilPrice = $event.target.value" />
      <iui-button value="유류단가일괄적용" @click="onSetOilPrice" class="btns" />

      <div class="mr25"></div>

      <div>주유업체</div>
      <iui-text :value="oilInfo.oilFrmNm" :enable="false" />
      <iui-button btn-class="i_search" @click="onShowCustomerModal(1)" />
      <iui-button value="주유업체일괄적용" @click="onSetOilCustomer" />
      <iui-button @click="onPopAddExcel" value="엑셀등록" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onSearchFinish,
        onAfterChange,
      }"
    />

    <iui-modal name="customerListModal" title="주유업체" :btns="modalBtns">
      <CustomerCodeList :frmDs="$getConstants('FRM_DS_OPR').code" />
    </iui-modal>
    <iui-modal name="OilExcelUploadPop" title="엑셀등록" :btns="excelBtns" sizeType="size1">
      <ExcelUpload
        uri="/operationJournal/oilExcelUpload"
        title="유류정보"
        :rows="excelRows"
        @error="onExcelUploadError"
      />
    </iui-modal>
    <iui-modal name="OilExcelUploadResultPop" title="엑셀 업로드 실패 사유" sizeType="size6" @closed="resultList = []">
      <ExcelUploadResult :sheet-opt="excelSheetOpt" :list="resultList" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import CustomerCodeList from '@/view/Resource/components/CustomerListPopup.vue';
import {
  deleteEqutOilReport,
  saveEqutOilReport,
  selectEqutOilReportList,
  selectLastRunDate,
} from '../api/operationJournal.js';
import {selectMonthClosePossibleAt} from '@/view/closing/expitmCloseRegist/api/expitmCloseRegist.js';
import options from './sheetOptions/EqutOilSheet.js';
import ExcelUpload from '@/components/popup/ExcelUpload.vue';
import ExcelUploadResult from '@/components/popup/ExcelUploadResult.vue';
import excelOptions from '@/view/Resource/Equipment/OperationJournal/components/sheetOptions/OilExcelUploadResultSheet';
import {selectCustomerListPopup} from '@/view/customer/api/customer';
import EXCEL_CONSTANTS from '@/constants/excelConstants.js';

export default {
  components: {
    ExcelUploadResult,
    ExcelUpload,
    CustomerCodeList: CustomerCodeList,
  },
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this), 2, 'chk', {CanEditFormula: e => !e.Row.closeYn}),
      loadSearchData: [],

      modalBtns: [{name: '확인', callback: this.onHideCustomerModal}],
      modalNo: '',

      oilInfo: {
        useOil: $getConstants('USE_OIL_GASOLINE').code,
        oilPrice: '',
        oilFrmNo: '',
        oilFrmNm: '',
      },
      gridData: {
        oilEnumKeys: '',
        oilEnumValues: '',
      },
      monPjtCloseAt: '',
      searchRunDtOpen: false,
      sheetLeftScrollOffset: 0,
      excelBtns: [{name: '확인', callback: this.onPopConfirmExcel}],
      resultList: [],
      excelRows: [],
      excelSheetOpt: excelOptions(this),
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo']);
    $mapMutations(this, ['setSearchInfo']);
  },
  created() {
    this.addEvent([
      {name: 'EqutOil_onSearch', func: this.onSearch},
      {name: 'EqutOil_onSave', func: this.onSave},
      {name: 'EqutOil_onDelete', func: this.onDelete},
    ]);

    $getCode($getConstants('USE_OIL').code).then(data => {
      let codes = data.filter(e => !e.code.startsWith('0'));
      this.gridData.oilEnumKeys = codes.reduce((prev, curr) => prev + '|' + curr.code, '');
      this.gridData.oilEnumValues = codes.reduce((prev, curr) => prev + '|' + curr.codeNm, '');
    });

    this.setExcelRows();
  },
  methods: {
    async setExcelRows() {
      const checkList = [];
      const response = await selectCustomerListPopup({pgmCd: this.pgmCd});
      if (response.data) {
        response.data.forEach(x => {
          checkList.push({name: x.frmNm, value: x.bsnNo});
        });
      }

      this.excelRows = [
        {
          value: [
            {value: '가동일자', style: {type: 'TEXT'}},
            {value: '실행중공종코드'},
            {value: '실행내역코드', style: {type: 'TEXT'}},
            {value: '장비순번'},
            {value: '장비코드', style: {type: 'TEXT'}},
            {value: '차량번호', style: {type: 'TEXT'}},
            {value: '유종', validate: {excelEnum: EXCEL_CONSTANTS.COMMON_CODE, pCode: $getConstants('USE_OIL').code}},
            {value: '유량'},
            {value: '단가'},
            {value: '주유업체', validate: {withDropdown: false, checksName: false, checkList: checkList}},
          ],
        },
      ];
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onSearchFinish() {
      if (this.sheetLeftScrollOffset) {
        this.sheet.setScrollLeft(this.sheetLeftScrollOffset, 1);
        this.sheetLeftScrollOffset = 0;
      }
      this.sheetMerge();
    },
    onAfterChange(e) {
      if (e.col == 'oilQty') {
        const oilQty = this.sheet.getValue(e.row, 'oilQty') || 0;
        const oilPrice = this.sheet.getValue(e.row, 'oilPrice') || 0;
        this.sheet.setValue(e.row, 'oilAmt', oilQty * oilPrice, 1);
      }

      if (e.col == 'oilPrice') {
        const oilQty = this.sheet.getValue(e.row, 'oilQty') || 0;
        const oilPrice = this.sheet.getValue(e.row, 'oilPrice') || 0;
        this.sheet.setValue(e.row, 'oilAmt', oilQty * oilPrice, 1);
      }

      if (e.col == 'oilAmt') {
        const oilQty = this.sheet.getValue(e.row, 'oilQty') || 0;
        const oilAmt = this.sheet.getValue(e.row, 'oilAmt') || 0;
        this.sheet.setValue(e.row, 'oilPrice', oilAmt / oilQty, 1);
      }
    },
    async onSearch() {
      if (!this.searchInfo.runDt) {
        this.$alert({title: '가동일자', message: '가동일자를 선택해 주세요.'});
        return;
      }
      const param = {...this.searchInfo};
      const response = await selectEqutOilReportList(param);
      this.loadSearchData = response.data;
    },
    onChangeRunDt(v) {
      this.onSearchMonthCloseAt(v);
      this.setSearchInfo({runDt: v});
      if (v) {
        this.onSearch();
      } else {
        this.searchRunDtOpen = true;
      }
    },
    async onSearchMonthCloseAt(runDt) {
      if (!runDt) return;

      const param = {closeMm: String(runDt).substring(0, 6)};
      const response = await selectMonthClosePossibleAt(param);

      if (response.data.CHECK1 == 'N') {
        this.monPjtCloseAt = 'Y'; // 월마감 O
      } else {
        this.monPjtCloseAt = 'N'; // 월마감 X
      }
    },
    async onChangeDate(searchDateType) {
      const param = {...this.searchInfo, searchDateType};
      const response = await selectLastRunDate(param);

      const lastRunDt = response.data;
      if (lastRunDt) {
        this.onSearchMonthCloseAt(lastRunDt);
        this.setSearchInfo({runDt: lastRunDt});
        this.onSearch();
      } else {
        const title = '가동일자';
        const message = `${searchDateType == 'PREV' ? '이전' : '다음'} 가동일이 없습니다.`;
        this.$alert({title, message}, () => {
          if (!this.searchInfo.runDt) {
            this.setSearchInfo({runDt: $_getCurrentDate()});
          }
        });
      }
    },
    onSetOilPrice() {
      const useOil = this.oilInfo.useOil;
      const oilPrice = this.oilInfo.oilPrice;
      if (!oilPrice) {
        this.$alert({title: '유류단가 일괄적용', message: '유류단가를 입력해야 합니다.'});
        return;
      }

      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({title: '유류단가 일괄적용', message: '유류단가를 적용할 장비를 선택해야 합니다.'});
        return;
      }
      checkedRows.filter(row => row.useOil == useOil).forEach(row => this.sheet.setValue(row, 'oilPrice', oilPrice));
    },
    onSetOilCustomer() {
      const oilFrmNm = this.oilInfo.oilFrmNm;
      const oilFrmNo = this.oilInfo.oilFrmNo;
      if (!oilFrmNo) {
        this.$alert({title: '주유업체 일괄적용', message: '주유업체를 선택해야 합니다.'});
        return;
      }

      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({title: '주유업체 일괄적용', message: '주유업체를 적용할 장비를 선택해야 합니다.'});
        return;
      }
      checkedRows.forEach(row => {
        this.sheet.setValue(row, 'oilFrmNm', oilFrmNm);
        this.sheet.setValue(row, 'oilFrmNo', oilFrmNo);
      });
    },
    onShowCustomerModal(modalNo) {
      this.modalNo = modalNo;
      this.$store.commit('setModalParam', this.searchInfo);
      this.$modal.show('customerListModal');
    },
    onHideCustomerModal() {
      this.callEvent({
        name: 'CustomerListPopup_callbackData',
        param: data => {
          if (this.modalNo == 1) {
            this.oilInfo.oilFrmNo = data.bsnNo;
            this.oilInfo.oilFrmNm = data.frmNm;
          }
          if (this.modalNo == 2) {
            const fucusRow = this.sheet.getFocusedRow();
            this.sheet.setValue(fucusRow, 'oilFrmNo', data.bsnNo, 1);
            this.sheet.setValue(fucusRow, 'oilFrmNm', data.frmNm, 1);
          }
        },
      });
      this.$modal.hide('customerListModal');
    },
    sheetMerge() {
      let Rows = this.sheet.getDataRows();
      let key = '';
      let sRow;
      let eRow;
      let mergeInfo = {};
      Rows.forEach(function(r) {
        if (key != r.runDt + r.midCode + r.itemSeq + r.equipCode + r.carNo) {
          key = r.runDt + r.midCode + r.itemSeq + r.equipCode + r.carNo;
          sRow = r.id;
          mergeInfo[sRow] = sRow;
        } else {
          eRow = r.id;
          mergeInfo[sRow] = eRow;
        }
      });

      for (let m in mergeInfo) {
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'runDt', this.sheet.getRowById(mergeInfo[m]), 'runDt');
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'seq', this.sheet.getRowById(mergeInfo[m]), 'seq');
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'equipCode',
          this.sheet.getRowById(mergeInfo[m]),
          'equipCode'
        );
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'equipName',
          this.sheet.getRowById(mergeInfo[m]),
          'equipName'
        );
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'carNo', this.sheet.getRowById(mergeInfo[m]), 'carNo');
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'useGb', this.sheet.getRowById(mergeInfo[m]), 'useGb');
      }
    },
    oilAdd(e) {
      function reOrder(row) {
        const nextRow = row.nextSibling;
        if (nextRow && row.midCode == nextRow.midCode && row.itemSeq == nextRow.itemSeq) {
          e.sheet.setValue(nextRow, 'seqNo', row.seqNo + 1, 1);
          reOrder(nextRow);
        }
      }

      if (!e.row.closeYn) {
        const addRow = e.sheet.copyRow(e.row, e.row.nextSibling);
        // DATA
        e.sheet.setValue(addRow, 'cud', 1, 1);
        e.sheet.setValue(addRow, 'addYn', 'Y', 1);
        e.sheet.setValue(addRow, 'useOil', 'Y', 1);
        e.sheet.setValue(addRow, 'oilQty', '', 1);
        e.sheet.setValue(addRow, 'oilPrice', '', 1);
        e.sheet.setValue(addRow, 'oilAmt', '', 1);
        e.sheet.setValue(addRow, 'oilFrmNm', '', 1);
        e.sheet.setValue(addRow, 'oilFrmNo', '', 1);

        reOrder(e.row);
        this.sheetMerge();
      }
    },
    async oilRemove(e) {
      function reOrder(row) {
        const nextRow = row.nextSibling;
        if (nextRow && row.midCode == nextRow.midCode && row.itemSeq == nextRow.itemSeq) {
          e.sheet.setValue(nextRow, 'seqNo', nextRow.seqNo - 1);
          reOrder(nextRow);
        }
      }

      const title = '유류정보삭제';
      const message = '선택한 유류정보를 삭제 하시겠습니까?';
      if (!(await this.$confirm({title: title, message: message}))) {
        return;
      }

      if (!e.row.closeYn && !e.row.Added) {
        const equtOilReportList = [
          {
            equipCode: e.row.equipCode,
            carNo: e.row.carNo,
            seq: e.row.seq,
            seqNo: e.row.seqNo,
          },
        ];

        try {
          const param = {runDt: this.searchInfo.runDt, equtOilReportList};
          const response = await deleteEqutOilReport(param);
          if (response.data) {
            this.onSearch();
          }
        } catch (error) {
          this.$alert({title: title, message: error.message});
        }
      }
      reOrder(e.row);
      if (e.row.seqNo > 1) {
        e.sheet.removeRow(e.row, null);
      }

      this.sheetLeftScrollOffset = e.sheet.getScrollLeft();
      this.onSearch();
    },
    async onSave() {
      if (this.isMonthClose) {
        this.$alert({title: '마감완료', message: '월마감 상태이므로 유류정보 수정은 불가합니다.'});
        return;
      }
      if (!this.sheet.hasChangedData()) {
        this.$alert({title: '유류정보저장', message: '변경된 내용이 없습니다.'});
        return;
      }

      const equtOilReportList = this.sheet.getSaveJson().data;
      const validRow = equtOilReportList.find(row => {
        return !row.oilQty || !row.oilPrice || !row.oilFrmNm || (row.addYn == 'Y' && !row.useOil);
      });

      if (validRow !== undefined) {
        const headers = this.sheet.getHeaderRows()[1];
        const row = this.sheet.getRowById(validRow);

        let col = '';
        if (!validRow.oilFrmNm) col = 'oilFrmNm';
        if (!validRow.oilPrice) col = 'oilPrice';
        if (!validRow.oilQty) col = 'oilQty';
        if (!validRow.useOil) col = 'useOil';

        const colNm = headers[col];
        this.$alert({title: '유류정보저장', message: `${colNm} 정보를 등록하십시요.`}, () => {
          this.sheet.focus(row, col);
        });
        return;
      }

      try {
        const param = {runDt: this.searchInfo.runDt, equtOilReportList};
        const response = await saveEqutOilReport(param);
        if (response.data) {
          this.onSearch();
        }
      } catch (error) {
        this.$alert({title: '유류정보저장', message: error.message});
      }
    },
    async onDelete() {
      if (this.isMonthClose) {
        this.$alert({title: '마감완료', message: '월마감 상태이므로 유류정보 삭제는 불가합니다.'});
        return;
      }

      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({title: '유류정보삭제', message: '삭제할 대상을 선택해 주세요'});
        return;
      }

      const equtOilReportList = [];
      checkedRows.forEach(row => {
        if (row.addYn == 'Y') {
          this.sheet.removeRow(row);
        } else {
          equtOilReportList.push({
            equipCode: row.equipCode,
            carNo: row.carNo,
            seq: row.seq,
            seqNo: row.seqNo,
          });
        }
      });

      if (0 == equtOilReportList.length) return;

      const title = '유류정보삭제';
      const message = '선택한 유류정보를 삭제 하시겠습니까?';
      if (!(await this.$confirm({title: title, message: message}))) {
        return;
      }
      try {
        const param = {runDt: this.searchInfo.runDt, equtOilReportList};
        const response = await deleteEqutOilReport(param);
        if (response.data) {
          this.onSearch();
        }
      } catch (error) {
        this.$alert({title: title, message: error.message});
      }
    },
    onPopAddExcel() {
      this.$modal.show('OilExcelUploadPop');
    },
    onPopConfirmExcel() {
      this.callEvent({
        name: 'excelUpload',
        param: () => {
          this.onSearch();
          this.$modal.hide('OilExcelUploadPop');
        },
      });
    },
    onExcelUploadError(error, list) {
      if (error === 'server') {
        this.$alert({title: '엑셀업로드', message: '유류정보 엑셀파일을 확인하십시오.'});
      } else {
        this.$alert({title: '엑셀업로드', message: error});
        if (list && list.length > 0) {
          this.resultList = list;
          this.$modal.show('OilExcelUploadResultPop');
        }
      }
    },
  },
  computed: {
    isMonthClose() {
      return this.monPjtCloseAt == 'Y'; // 월마감 가능여부
    },
  },
};
</script>

<style scoped></style>
