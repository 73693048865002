<template>
  <pmis-page
    :search-project-visible="false"
    :search-company-visible="true"
    @search-company-changed="searchCompanyChanged"
    @search-click="onSearch"
    @new-click="onAdd"
    @save-click="onSave"
    @delete-click="onDelete"
  >
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="350px">
          <pmis-tab-box>
            <template v-slot:title>사원목록</template>
            <WorkerList />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>상세정보</template>
            <WorkerInfo />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from './store/worker2.js';
import WorkerInfo from './components/WorkerInfo.vue';
import WorkerList from '@/view/user/Worker/components/WorkerList.vue';

export default {
  components: {
    WorkerList,
    WorkerInfo,
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['searchInfo', 'workerInfo']);
    $mapMutations(this, ['setSearchInfo', 'setWorkerInfo']);
  },
  created() {
    this.canPrint = false;
  },

  methods: {
    searchCompanyChanged(e) {
      this.setSearchInfo(e);
    },
    onSearch() {
      this.setSearchInfo({empNo: ''});
      this.callEvent({name: 'WorkerList_onSearch'});
    },
    onAdd() {
      this.callEvent({name: 'WorkerInfo_onAdd'});
      this.callEvent({name: 'InputPjt_initSheet'});
    },
    async onSave() {
      this.callEvent({name: 'WorkerInfo_onSave'});
    },
    onDelete() {
      this.callEvent({name: 'WorkerInfo_onDelete'});
    },
  },
};
</script>

<style scoped></style>
