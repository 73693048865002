<template>
  <iui-container-new type="css-flex">
    <i-row height="310px">
      <i-col>
        <pmis-content-box>
          <template #title>요청정보</template>
          <iui-container-new type="table" theme="bullet">
            <colgroup>
              <col width="90px" />
              <col />
              <col width="10px" />
              <col width="90px" />
              <col />
            </colgroup>
            <i-row>
              <i-col-header>현장</i-col-header>
              <i-col colspan="4">
                <iui-text :value="pr.spotDeptcd" :enable="false" width="100px" />
                <iui-text :value="pr.spotDeptnm" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>요청제목</i-col-header>
              <i-col colspan="4">
                <iui-text :value="pr.prNo2" :enable="false" width="100px" />
                <iui-text :value="pr.ttl" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>과세유형</i-col-header>
              <i-col>
                <iui-text :value="pr.taxTypenm" :enable="false" />
              </i-col>
              <i-spacer />
              <i-col colspan="2">
                <iui-container-new type="table" theme="bullet">
                  <i-row>
                    <i-col-header>요청자</i-col-header>
                    <i-col width="260px">
                      <iui-text :value="pr.chrgUsrcd" :enable="false" width="100px" />
                      <iui-text :value="pr.chrgUsrnm" :enable="false" />
                    </i-col>
                    <i-spacer />
                    <i-col-header>요청일자</i-col-header>
                    <i-col>
                      <iui-datepicker :value="pr.prDate" :enable="false" />
                    </i-col>
                  </i-row>
                </iui-container-new>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>현장설명유무</i-col-header>
              <i-col colspan="3">
                <iui-radio-group :p-code="$getConstants('SPOT_DSCR_YN').code" :value="pr.spotDscrYn" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col>&nbsp;</i-col>
              <i-col>
                <iui-container-new type="table" theme="bullet" v-if="isSpotDscrY">
                  <i-row>
                    <i-col-header>현설일자</i-col-header>
                    <i-col>
                      <iui-text :value="pr.spotDscrDt" :enable="false" />
                    </i-col>
                  </i-row>
                </iui-container-new>
              </i-col>
              <i-spacer />
              <i-col-header v-if="isSpotDscrY">현설담당</i-col-header>
              <i-col>
                <iui-text v-if="isSpotDscrY" :value="pr.spotDscrUsrnm" :enable="false" width="100px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col>&nbsp;</i-col>
              <i-col colspan="4">
                <iui-container-new type="table" theme="bullet" v-if="isSpotDscrY">
                  <i-row>
                    <i-col-header>현설장소</i-col-header>
                    <i-col>
                      <iui-text :value="pr.spotDscrLoc" :enable="false" />
                    </i-col>
                  </i-row>
                </iui-container-new>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>공사명</i-col-header>
              <i-col colspan="4">
                <iui-text :value="pr.itemNm" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>공사기간</i-col-header>
              <i-col colspan="4">
                <iui-datepicker :value="pr.constrFrDt" :enable="false" />
                <span>~</span>
                <iui-datepicker :value="pr.constrToDt" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>입찰기타사항</i-col-header>
              <i-col colspan="4">
                <iui-text :value="pr.prRmrk" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>비고</i-col-header>
              <i-col colspan="4">
                <iui-text :value="pr.itemRmrk" :enable="false" />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-file-list
          id="prInfoFile"
          title-visible
          :toolbar-visible="false"
          title="첨부파일"
          theme="bullet"
          :company-code="pr.orgCd"
          :file-number="pr.flNo"
        />
      </i-col>
    </i-row>
    <i-spacer />
    <i-row height="220px">
      <i-col>
        <pmis-content-box>
          <template #title>요청내역</template>
          <template #title-left
            ><div>계약금액</div>
            <iui-text type="amount" :value="pr.totAmt" :enable="false" />
            <div>공급가액</div>
            <iui-text type="amount" :value="pr.amt" :enable="false" />
            <div>(노무비</div>
            <iui-text type="amount" :value="pr.labAmt" :enable="false" />
            <div>건설산업기본법시행령 제84조 규정에 의한 노무비)</div>
            <div class="ml10">부가세액</div>
            <iui-text type="amount" :value="pr.vatAmt" :enable="false" />
          </template>
          <ib-sheet
            :uid="_uid"
            :options="sheetOpt"
            :loadSearchData="loadSearchData"
            :events="{
              onRenderFirstFinish: () => onSearch(),
              onSearchFinish: sheet_onSearchFinish,
            }"
          />
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import sheetOpt from './sheetOptions/prInfoSheet.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import {SUB_PR_COLUMNS} from '@/view/bidding/js/BiddingColumns.js';
import {selectSubPrInfo, selectSubPrItemList} from '@/view/bidding/pr/sub/api/subPr.js';

export default {
  props: {
    propsPrNo: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],

      pr: {...SUB_PR_COLUMNS},
    };
  },
  computed: {
    isSpotDscrY() {
      return this.pr['spotDscrYn'] === BIDDING_CONSTANTS.CODES.SPOT_DSCR_Y;
    },
  },
  methods: {
    async onSearch() {
      const response1 = await selectSubPrInfo(this.propsPrNo);
      const response2 = await selectSubPrItemList(this.propsPrNo);

      for (const key in this.pr) {
        this.pr[key] = response1.data[key];
      }
      this.pr.prNo2 = COMMON_FUNCTION.getMakeFormat(this.pr.prNo);

      this.loadSearchData = response2.data;
    },
    sheet_onSearchFinish(e) {
      const items = e.sheet.getDataRows();
      this.pr.amt = items.reduce((accumulator, currentValue) => accumulator + currentValue.amt, 0) || 0;
      this.pr.labAmt = items.reduce((accumulator, currentValue) => accumulator + currentValue['labAmt'], 0);
      COMMON_FUNCTION.onCalculateTaxAndTotAmt(this.pr);
    },
  },
};
</script>

<style scoped></style>
