const SIGN_COUNT = 3;

const reIndex = (num, cnt = SIGN_COUNT) => {
  const value = num % cnt;
  if (!value) {
    return cnt;
  }
  return value;
};
export const initLine = (list, signCnt = SIGN_COUNT, isMulti = true, signedLine, key = 'line') => {
  let lineMax = 0;
  let _lineList = [];
  if (signedLine) {
    signedLine[key].forEach(el => {
      for (let [key, value] of Object.entries(el)) {
        if (key.startsWith('sign') && value) {
          lineMax++;
        }
      }
      if (lineMax > 0) {
        _lineList.push(el);
      }
    });
  }

  let lineItem = {};
  let _index = 0;
  let isIncrement = false;

  list.some((item, idx) => {
    _index = idx + 1 + lineMax;
    let _reIndex = reIndex(_index, signCnt);
    lineItem = setLineItem(lineItem, _reIndex, isMulti, {tpNm: item.rollNm});
    if (idx === 0 && _reIndex === 1) {
      isIncrement = true;
    }
    if (lineMax && idx < signCnt && _reIndex !== 1 && !isIncrement) {
      _lineList[_lineList.length - 1] = {..._lineList[_lineList.length - 1], ...lineItem};
      if (!isMulti || _index % signCnt === 0) {
        lineItem = {};
        isIncrement = true;
      }
      return false;
    }
    if (!isMulti || _index % signCnt === 0) {
      _lineList.push(lineItem);
      lineItem = {};
    }
    if (!isMulti && signCnt === idx + 1) {
      return true;
    }
  });

  // 빈칸 채워줌
  if ((isMulti && _index % signCnt !== 0) || (_index === 0 && key === 'collaboline')) {
    for (let i = reIndex(_index + 1, signCnt); i <= signCnt; i++) {
      lineItem = setLineItem(lineItem, reIndex(i, signCnt), isMulti, {});
    }
    _lineList.push(lineItem);
  }

  const obj = {};
  obj[key] = _lineList;
  return {lineMax, strLine: JSON.stringify(obj)};
};

const setLineItem = (lineItem, index, isMulti, {tpNm = '', sign = '', dt = ''}) => {
  lineItem[`tpNm${isMulti ? `${index}` : ''}`] = tpNm;
  lineItem[`sign${isMulti ? `${index}` : ''}`] = sign;
  lineItem[`dt${isMulti ? `${index}` : ''}`] = dt;
  return lineItem;
};

export const setSign = ({
  pgmCd,
  userInfo,
  lineList,
  key = 'line',
  approver,
  isDecide = false,
  isMulti = true,
  signCnt = SIGN_COUNT,
  lineMax = 0,
}) => {
  let order = (approver?.signIdx ?? 0) + lineMax;
  let orderIndex = reIndex(key === 'line' ? order + 1 : order, signCnt);
  let index = isMulti ? parseInt((key !== 'line' ? order - 1 : order) / signCnt) : order;
  const line = typeof lineList === 'object' ? lineList : JSON.parse(lineList);

  line[key][index][`sign${isMulti ? `${orderIndex}` : ''}`] = isDecide
    ? '전결'
    : userInfo.sgntFlNo > 0
    ? getImageUrl(pgmCd, userInfo.sgntFlNo)
    : userInfo.name;

  line[key][index][`dt${isMulti ? `${orderIndex}` : ''}`] = setSignStamp(isMulti);

  if (isDecide) {
    const appCount = approver?.appCount + lineMax;
    for (let i = order ?? 0; i < appCount; i++) {
      order = i + 1;
      orderIndex = reIndex(order + 1, signCnt);
      index = isMulti ? parseInt(order / signCnt) : order;

      line[key][index][`sign${isMulti ? `${orderIndex}` : ''}`] =
        0 < userInfo.sgntFlNo ? getImageUrl(pgmCd, userInfo.sgntFlNo) : userInfo.name;

      line[key][index][`dt${isMulti ? `${orderIndex}` : ''}`] = setSignStamp(isMulti);
    }
  }

  return JSON.stringify(line);
};

const setSignStamp = isMulti => {
  let date = $_getCurrentDate();
  let time = $_getNowTime();

  return `${date.substring(0, 4)}.${date.substring(4, 6)}.${date.substring(6, 8)}${
    isMulti ? ' ' : '\n'
  }${time.substring(0, 5)}`;
};

const getImageUrl = (pgmCd, flNo) => {
  return `${
    location.protocol.indexOf('https') !== -1 ? process.env.VUE_APP_FILE_URLS : process.env.VUE_APP_FILE_URL
  }/file/getImage?pgmCd=${pgmCd}&flNo=${flNo}`;
};
