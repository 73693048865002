<template>
  <pmis-tab-box>
    <template #title>요청내역</template>
    <template #title-right>
      <iui-button value="실행내역선택" @click="openExecutionListPopup" v-if="subPrStatus.isEnable" />
      <iui-button value="삭제" @click="onRemoveSubPrItem" v-if="subPrStatus.isEnable" />
    </template>
    <pmis-content-box>
      <template #header-left>
        <label>계약금액</label>
        <iui-text type="amount" :value="subPrInfo.totAmt" :enable="false" width="150px" />

        <label>공급가액</label>
        <iui-text type="amount" :value="subPrInfo.amt" :enable="false" width="150px" />

        <label>(노무비</label>
        <iui-text type="amount" :value="subPrInfo.labAmt" :enable="false" width="150px" />
        <label>건설산업기본법시행령 제84조 규정에 의한 노무비)</label>

        <label class="ml10">부가세액</label>
        <iui-text type="amount" :value="subPrInfo.vatAmt" :enable="false" width="150px" />
      </template>

      <ib-sheet
        :uid="_uid"
        :options="sheetOpt"
        :loadSearchData="subPrItemList"
        @loadSheet="sheet = $event"
        :events="{
          onSearchFinish: sheet_onSearchFinish,
          onBeforeChange: sheet_onBeforeChange,
          onAfterChange: sheet_onAfterChange,
        }"
      />
    </pmis-content-box>

    <ExecutionList :visiblePrice="false" @executionList="onConfirm_executionList" />
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/subPrItemList.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';

import ExecutionList from '@/view/subBidding/components/ExecutionList.vue';
export default {
  components: {ExecutionList},
  data() {
    return {sheetOpt: $addColCheckbox(sheetOpt(this))};
  },
  beforeCreate() {
    $mapGetters(this, ['subPrInfo', 'subPrStatus', 'subPrItemList']);
    $mapMutations(this, ['setSubPrInfo']);
    $mapActions(this, ['searchSubPrItemList']);
  },
  created() {
    this.addEvent([
      {name: 'SubPrItemList_CalculateAmt', func: this.calculateAmt},
      {name: 'SubPrItemList_GetSaveJson', func: this.getSaveJson},
      {name: 'SubPrItemList_OpenExecutionListModal', func: this.openExecutionListPopup},
    ]);
  },
  methods: {
    openExecutionListPopup() {
      const param = {
        pgmCd: this.pgmCd,
        prjCd: this.subPrInfo.spotDeptcd,
        validSheet: this.sheet.getDataRows(),
      };
      this.callEvent({name: 'ExecutionList_ShowModal', param: param});
    },
    onConfirm_executionList(executionList) {
      executionList.forEach(item => {
        const addRow = this.sheet.addRow();

        this.sheet.setValue(addRow, 'srvNm', item.mgName, true);
        this.sheet.setValue(addRow, 'itemNm', item.itemName, true);
        this.sheet.setValue(addRow, 'sizeNm', item.ssize, true);
        this.sheet.setValue(addRow, 'unit', item.unit, true);

        this.sheet.setValue(addRow, 'unitAmt', item.unitAmt, true);
        this.sheet.setValue(addRow, 'planMatUprc', item.planMatUprc, true);
        this.sheet.setValue(addRow, 'planLabUprc', item.planLabUprc, true);
        this.sheet.setValue(addRow, 'planEquipUprc', item.planEquipUprc, true);
        this.sheet.setValue(addRow, 'planCostUprc', item.planCostUprc, true);

        this.sheet.setValue(addRow, 'wcode', item.wcode, true);
        this.sheet.setValue(addRow, 'headCode', item.headCode, true);
        this.sheet.setValue(addRow, 'midCode', item.midCode, true);
        this.sheet.setValue(addRow, 'itemSeq', item.itemSeq, true);
        this.sheet.setValue(addRow, 'itemCode', item.itemCode, true);
        this.sheet.setValue(addRow, 'costType', item.costType, true);

        this.sheet.setValue(addRow, 'totQty', item.totQty, true); // 실행수량
        this.sheet.setValue(addRow, 'preQty', item.preQty, true); // 기시행수량
        this.sheet.setValue(addRow, 'remQty', item.remQty, true); // 잔여수량
        this.sheet.setValue(addRow, 'qty', 0, true); // 금회요청수량
      });
      this.calculateAmt();
    },
    calculateAmt() {
      const itemList = this.sheet.getDataRows();
      const {amt, labAmt} = itemList.reduce(
        (acc, curr) => ({
          amt: acc['amt'] + curr['amt'],
          labAmt: acc['labAmt'] + curr['labAmt'],
        }),
        {amt: 0, labAmt: 0}
      );

      const {taxTypecd, taxDirectDs, taxRate, taxTypeDirectPercent, taxTypeDirectAmt} = this.subPrInfo;
      this.setSubPrInfo({
        ...COMMON_FUNCTION.onCalculateTaxAndTotAmt({
          amt,
          taxTypecd,
          taxDirectDs,
          taxRate,
          taxTypeDirectPercent,
          taxTypeDirectAmt,
        }),
        labAmt,
      });
    },
    async onRemoveSubPrItem() {
      if (!this.sheet.getTotalRowCount()) {
        this.$alert({title: '시행요청내역 삭제', message: '요청내역이 존재하지 않습니다.'});
        return;
      }

      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (!checkedRows.length) {
        this.$alert({title: '시행요청내역 삭제', message: '선택된 항목이 없습니다.'});
        return;
      }

      this.sheet.removeRows(checkedRows);
      this.calculateAmt();
    },
    getSaveJson(callback) {
      const saveData = this.sheet.getSaveJson(0).data.sort((a, b) => a.itemSeq - b.itemSeq);

      if (typeof callback === 'function') {
        callback(saveData);
      }
    },
    setEnableSheet() {
      const isEnable = this.subPrStatus.isEnable;

      this.sheet.setAttribute(null, 'chk', 'Visible', isEnable, 1);

      this.sheet.setAttribute(null, 'qty', 'CanEdit', isEnable, 1);
      this.sheet.setAttribute(null, 'srvRmrk', 'CanEdit', isEnable, 1);

      this.sheet.setAttribute(null, 'qty', 'Color', isEnable ? '#E0E0F8' : '', 1);
      this.sheet.setAttribute(null, 'srvRmrk', 'Color', isEnable ? '#E0E0F8' : '', 1);

      this.sheet.Def.Row.CanFocus = isEnable;
    },
    sheet_onSearchFinish() {
      this.setEnableSheet();
      this.calculateAmt();
    },
    sheet_onBeforeChange(e) {
      if (e.col === 'qty') {
        if (Number.isNaN(Number(e.val))) {
          return e.oldval;
        }

        const decimal = e.val.split('.')[1];
        if (5 < decimal?.length) {
          this.$alert({title: '금회요청수량', message: '소수점 이하 5자리까지 입력가능합니다.'});
          return e.oldval;
        }

        if (0 > e.val) {
          this.$alert({title: '금회요청수량', message: '금회요청수량은 0 이상이어야 합니다.'});
          return e.oldval;
        }
        return e.val;
      }
    },
    sheet_onAfterChange(e) {
      if (e.col === 'qty') {
        this.calculateAmt();
      }
    },
  },
};
</script>

<style scoped></style>
