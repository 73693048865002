export default that => {
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      CanEdit: 1, // 1: 전체 편집 가능 (default)
      ReqStatusName: 'status', // 서버로 전송될 상태 명 (default:STATUS)
      IgnoreFocused: true,
    },
    Cols: [
      {
        Header: '순번',
        Name: 'seq',
        Type: 'Text',
        Width: 50,
        CanEdit: false,
      },
      {
        Header: '대분류',
        Name: 'lmName',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: false,
      },
      {
        Header: '중분류',
        Name: 'mmName',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: false,
      },
      {
        Header: '소분류',
        Name: 'smName',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: false,
      },
      {
        Header: '비고',
        Name: 'rmk',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: that.enable,
      },
      {
        Name: 'lmCode',
        Type: 'Text',
        Visible: 0,
      },
      {
        Name: 'mmCode',
        Type: 'Text',
        Visible: 0,
      },
      {
        Name: 'smCode',
        Type: 'Text',
        Visible: 0,
      },
    ],
  };
};
