export default that => {
  return {
    Def: {Row: {NoColor: 1, CanFocus: 0}},
    Cols: [
      {
        Header: '협력사명',
        Name: 'frmNm',
        Type: 'Text',
        Align: 'center',
        MinWidth: 150,
        RelWidth: 1,
        CanEdit: 0,
        TextStyle: 5,
        TextColor: '#FF0000',
        Cursor: 'Pointer',
      },
      {Header: '담당자명', Name: 'icprNm', Type: 'Text', Align: 'center', Width: 80, CanEdit: 0},
      {
        Header: '전화번호',
        Name: 'icprPhone',
        Type: 'Text',
        Align: 'center',
        Width: 110,
        CanEdit: 0,
        CustomFormat: 'PhoneNo',
      },
      {
        Header: '담당자명 (연락처)',
        Name: 'icprNmPhone',
        Type: 'Text',
        Align: 'center',
        Width: 150,
        CanEdit: 0,
        Visible: false,
        Formula: fr => `${fr.Row['icprNm']} (${fr.Row['icprPhone']})`,
      },
      {Header: '이메일', Name: 'email', Type: 'Text', MinWidth: 150, RelWidth: 1, CanEdit: 0},

      {Name: 'cusCd', Visible: false},
      {Name: 'cusPgmCd', Visible: false},
    ],
  };
};
