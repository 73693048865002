<template>
  <pmis-content-box>
    <template #header-left>
      <iui-text type="amount" width="180px" prefix="공급가액" :enable="false" :value="poInfo.poAmt" />
      <iui-text type="amount" width="180px" prefix="부가세액" :enable="false" :value="poInfo.vatAmt" />
      <iui-text type="amount" width="180px" prefix="합계금액" :enable="false" :value="poInfo.totAmt" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="poItemList"
      :events="{
        onRenderFirstFinish: e => e.sheet.setAutoMerge({headerMerge: 5}),
      }"
    />
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/poItemList.js';
export default {
  data() {
    return {
      sheetOpt: sheetOpt(this),
    };
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'poItemList']);
  },
};
</script>

<style></style>
