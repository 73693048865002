<template>
  <pmis-content-box>
    <template v-slot:title>정산금액</template>
    <ib-sheet :uid="_uid" :options="options" :loadSearchData="calculateList" :events="{onAfterClick: onAfterClick}" />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/SubTargetListSheet.js';

export default {
  data() {
    return {
      options: options(this),
    };
  },
  beforeCreate() {
    $mapGetters(this, 'subcon', ['calculateList']);
    $mapMutations(this, 'subcon', ['setCloseList']);
    $mapActions(this, 'subcon', ['setCalculateAmt']);
  },
  methods: {
    onAfterClick(e) {
      if (e.col === 'closeYn' && this.cud === 1) {
        const rows = e.sheet.getRowsByChecked(e.col);
        this.setCloseList(this.filterRowAsClose(rows));
        this.setCalculateAmt(rows.reduce((acc, cur) => acc + cur.amt, 0));
      }
    },
    filterRowAsClose(rows) {
      return rows.map(row => ({workDt: String(row.workDt), midCode: row.midCode, itemSeq: String(row.itemSeq)}));
    },
  },
};
</script>

<style scoped></style>
