<template>
  <pmis-content-box>
    <template v-slot:title>시스템사용정보</template>
    <iui-container-new type="table" theme="bullet">
      <i-row>
        <i-col-header width="100px" :required="enable">시작(계약)일자</i-col-header>
        <i-col width="100px">
          <iui-datepicker
            v-if="enable"
            :value="companyInfo.useStrdate"
            @change="setCompanyInfo({useStrdate: $event})"
            :required="enable"
            :errorMessage="{title: '시작(계약)일자', message: '시작(계약)일자를 입력해주세요.'}"
          />
          <iui-text v-else :value="useStrDate" :enable="false" width="100px" />
        </i-col>
        <i-col minWidth="30px"></i-col>
        <i-col-header width="100px">종료일자</i-col-header>
        <i-col width="100px">
          <iui-datepicker
            v-if="enable"
            :value="companyInfo.useEnddate"
            @change="setCompanyInfo({useEnddate: $event})"
            :enable="enable"
          />
          <iui-text v-else :value="useEndDate" :enable="false" width="100px" />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    enable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    useStrDate() {
      return dateFormat(this.companyInfo.useStrdate);
    },
    useEndDate() {
      return dateFormat(this.companyInfo.useEnddate);
    },
  },
  beforeCreate() {
    $mapGetters(this, ['companyInfo']);
    $mapMutations(this, ['setCompanyInfo']);
  },
};
</script>

<style scoped></style>
