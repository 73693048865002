export default that => {
  return {
    Cfg: {CanEdit: 0},
    Def: {Row: {NoColor: 1, CanFocus: 0}},
    Cols: [
      {Header: '순번', Name: 'seq', Type: 'Text', Align: 'center', Width: 45},
      {Header: '대분류', Name: 'lmName', Type: 'Text', RelWidth: 1},
      {Header: '중분류', Name: 'mmName', Type: 'Text', RelWidth: 1},
      {Header: '소분류', Name: 'smName', Type: 'Text', RelWidth: 1.5},
      {Header: '비고', Name: 'rmk', Type: 'Text', RelWidth: 1},
    ],
  };
};
