<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col style="padding-top: 20px;">
        <pmis-image-file
          name="flNo"
          id="file"
          flNoCol="flNo"
          :company-code="attendInfo.pgmCd"
          :file-number.sync="attendInfo.flNo"
          :allowAdd="false"
          :allowDelete="false"
          :saveButtonVisible="false"
        />
      </i-col>
    </i-row>
    <i-spacer />
    <i-row height="60px">
      <i-col>
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col-header>근로자</i-col-header>
            <i-col>{{ attendInfo.name }}</i-col>
          </i-row>
          <i-row>
            <i-col-header>{{ attendInfo.workTimeHeader }}</i-col-header>
            <i-col>{{ attendInfo.time }}</i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
export default {
  created() {
    this.onSearch();
  },
  data() {
    return {
      imageSrc: '',
      attendInfo: {
        pgmCd: '',
        flNo: '',
        name: '',
        time: '',
        workTimeHeader: '',
        rgprId: '',
      },
    };
  },
  methods: {
    onSearch() {
      let pgmCd = this.$store.getters['modalParam'].pgmCd;
      let prjCd = this.$store.getters['modalParam'].prjCd;
      let investDt = this.$store.getters['modalParam'].investDt;
      let empNo = this.$store.getters['modalParam'].empNo;
      let workTimeSe = this.$store.getters['modalParam'].workTimeSe;

      axios
        .post('/attendanceRegist/selectAttendanceImageInfo', {pgmCd, prjCd, investDt, empNo, workTimeSe})
        .then(response => {
          this.attendInfo.pgmCd = pgmCd;
          this.attendInfo.workTimeHeader = workTimeSe == 'IN' ? '출근시간' : '퇴근시간';
          if (response.data) {
            this.attendInfo.flNo = response.data.flNo;
            this.attendInfo.name = response.data.name;
            this.attendInfo.time = response.data.workInOutTime ? response.data.workInOutTime : '없음';
            this.attendInfo.rgprId = response.data.rgprId;
          }
        });
    },
  },
};
</script>

<style></style>
