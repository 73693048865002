<template>
  <pmis-content-box>
    <template v-slot:header-left v-if="!isPopup">
      <iui-text
        type="text"
        name="frmNm"
        :label="'회사명'"
        @change="setSearchInfo('frmNm', $event)"
        @enter="setSearchInfo('frmNm', $event, 'Y')"
      />
    </template>
    <template v-slot:header-right v-if="!isPopup">
      <iui-checkbox-group name="closeAt" :items="checkboxItems" @change="setSearchInfo('closeAt', $event)" />
    </template>
    <iui-container-new type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    uid: {
      type: Number,
    },
  },
  created() {
    this.addEvent([
      {name: 'CompanyList_search', func: this.onSearch},
      {name: 'CompanyList_setFocusKeyCode', func: this.setFocusKeyCode},
      {name: 'CompanyCode_search', func: this.onSearchPopup}, // 팝업
      {name: 'companyCode_setData', func: this.onSetData}, // 팝업
    ]);
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      checkboxItems: [{value: false, label: '종료포함'}],
      searchInfo: {
        frmNm: '',
        closeAt: false,
        isPopup: false,
        searchSe: '',
        searchNm: '',
      },
      focusKeyCode: '',
    };
  },
  mounted() {
    const OPT = {
      Cfg: {
        SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
        CanEdit: 0, // 1: 전체 편집 가능 (default)
      },
      Cols: [
        {
          Header: '코드',
          Name: 'pgmCd',
          Type: 'Text',
          Width: 100,
          Align: 'center',
        },
        {
          Header: '회사명',
          Name: 'frmNm',
          Type: 'Text',
          RelWidth: 1,
        },
        {
          Header: '종료여부',
          Name: 'useEnddate',
          Type: 'Text',
          Width: 100,
          Align: 'center',
          CustomFormat: v => (v == '' ? '' : '종료'),
          Visible: !this.isPopup,
        },
        {
          Header: '사업자번호',
          Name: 'bsnNo',
          Width: '120',
          Align: 'center',
          CustomFormat: 'SaupNo',
          Visible: this.isPopup,
        },
        {
          Header: '대표자명',
          Name: 'rpsvNm',
          Width: '100',
          Align: 'center',
          Visible: this.isPopup,
        },
      ],
      Events: {
        onSearchFinish: this.grid_onSearchFinish,
        onclick: this.grid_onclick,
        onDblClick: this.isPopup ? this.grid_onDblClick : undefined,
      },
    };

    PMISSheet.createSheet({el: this.elId, options: OPT}).then(sheet => {
      this.sheet = sheet;
      this.onSearch();
    });
  },
  methods: {
    async onSearch() {
      this.searchInfo.isPopup = this.isPopup;
      let response = await axios.post('/companyRegist/selectCompanyList', this.searchInfo);
      this.sheet.loadSearchData(response.data);
    },
    grid_onSearchFinish(e) {
      let focusRow = e.sheet.getFirstRow();
      if (focusRow != null) {
        if (this.focusKeyCode) {
          for (let row of e.sheet.getDataRows()) {
            if (this.focusKeyCode == row.pgmCd) {
              focusRow = row;
              break;
            }
            setTimeout(() => {
              e.sheet.focus(focusRow);
              this.onDetailSearch(focusRow);
            }, 100);
          }
        } else {
          this.onDetailSearch(focusRow);
        }
      }
    },
    grid_onclick(e) {
      this.onDetailSearch(e.row);
    },
    onDetailSearch(row) {
      this.callEvent({name: 'CompanyInfo_search', param: row.pgmCd});
    },
    setSearchInfo(key, e, searchYn) {
      let value = '';
      if (e.target.type == 'text') {
        value = e.target.value;
      } else if (e.target.type == 'checkbox') {
        value = e.target.checked;
      }
      this.searchInfo[key] = value;

      if (searchYn == 'Y') {
        this.onSearch();
      }
    },
    setFocusKeyCode(param) {
      this.focusKeyCode = param.pgmCd;
      if (param.isDelete) {
        this.searchInfo.closeAt = true;
        document.getElementsByName('closeAt')[0].checked = true;
      }
      this.onSearch();
    },
    onSearchPopup(param) {
      this.searchInfo.searchSe = param.searchSe || '';
      this.searchInfo.searchNm = param.searchNm || '';
      this.onSearch();
    },
    onSetData(callback) {
      callback(this.sheet.getFocusedRow());
    },
    grid_onDblClick() {
      this.callEvent({name: `companyCodeSearchOnConfirm${this.uid}`}); // iuiSearchbox.vue
    },
  },
};
</script>

<style></style>
