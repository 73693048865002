<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box no-border-box no-padding-box>
            <template v-slot:title>도급/실행내역</template>
            <pmis-content-box>
              <template v-slot:header-left>
                <label>도급내역차수</label>
                <iui-select
                  :items="chgDegreeDgList"
                  :defaultCd="!chgDegreeDgList.length ? 'S' : ''"
                  :value="chgDegreeDg"
                  @change="value => onChangeChgDegreeDg(value)"
                ></iui-select>
                <div class="ml10"></div>
                <label>실행내역차수</label>
                <iui-select
                  :items="chgDegreeSiList"
                  :defaultCd="!chgDegreeSiList.length ? 'S' : ''"
                  :value="chgDegreeSi"
                  @change="value => onChangeChgDegreeSi(value)"
                ></iui-select>
              </template>
              <ContExecDtls
                @chgDegreeDg="degree => (chgDegreeDg = degree)"
                @chgDegreeSi="degree => (chgDegreeSi = degree)"
              />
            </pmis-content-box>
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import PrjList from '@/view/Project/components/PrjList.vue';
import ContExecDtls from '@/view/executDtls/components/ContExecDtls.vue';
import {selectContexecChgDegree} from '../api/dtlsCntc.js';

export default {
  components: {
    PrjList,
    ContExecDtls,
  },
  data() {
    return {
      chgDegreeDgList: [],
      chgDegreeSiList: [],
      chgDegreeDg: '',
      chgDegreeSi: '',
    };
  },
  created() {
    this.addFuncPrj(this.onSearch);
  },
  methods: {
    async onSearch() {
      this.chgDegreeDgList = [];
      this.chgDegreeSiList = [];
      this.chgDegreeDg = '';
      this.chgDegreeSi = '';
      const response = await selectContexecChgDegree({});
      const chgDegreeDgList = response.data.chgDegreeDgList;
      const chgDegreeSiList = response.data.chgDegreeSiList;
      if (!chgDegreeDgList.length) {
        this.$alert({title: '도급/실행내역', message: '연계된 내역이 없습니다.'});
        this.callEvent({
          name: 'removeSheet',
        });
        return;
      }
      chgDegreeDgList.forEach(data => {
        this.chgDegreeDgList.push({text: `${data}차`, value: `${data}`});
      });
      this.chgDegreeDg = chgDegreeDgList[chgDegreeDgList.length - 1];

      chgDegreeSiList.forEach(data => {
        this.chgDegreeSiList.push({text: `${data}차`, value: `${data}`});
      });
      this.chgDegreeSi = chgDegreeSiList[chgDegreeSiList.length - 1];

      this.selectContContexecPlanD();
    },
    onChangeChgDegreeDg(value) {
      this.chgDegreeDg = value;
      this.selectContContexecPlanD();
    },
    onChangeChgDegreeSi(value) {
      this.chgDegreeSi = value;
      this.selectContContexecPlanD();
    },
    selectContContexecPlanD() {
      this.callEvent({
        name: 'selectContContexecPlanD',
        param: {chgDegreeDg: this.chgDegreeDg, chgDegreeSi: this.chgDegreeSi},
      });
    },
  },
};
</script>
