import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
export default that => {
  return {
    Cfg: {
      CanEdit: 0,
      HeaderMerge: 3,
    },
    LeftCols: [
      {
        Header: ['입고일자', '입고일자'],
        Name: 'ioDate',
        Extend: that.$ibsheetHelper.preset.date,
        Width: 100,
      },
      {
        Header: ['순번', '순번'],
        Name: 'rowNo',
        Type: 'Text',
        Align: 'center',
        Width: 50,
      },
      {
        Header: ['거래처명', '거래처명'],
        Name: 'custName',
        Type: 'Text',
        Align: 'left',
        Width: 150,
      },
      {
        Header: ['자재코드', '자재코드'],
        Name: 'matCode',
        Type: 'Text',
        Align: 'center',
        Width: 130,
      },
      {
        Header: ['자재명', '자재명'],
        Name: 'itemName',
        Type: 'Text',
        Align: 'left',
        RelWidth: 1,
        MinWidth: 150,
      },
    ],
    Cols: [
      {
        Header: ['규격', '규격'],
        Name: 'size',
        Type: 'Text',
        Align: 'left',
        RelWidth: 1,
        MinWidth: 200,
      },
      {
        Header: ['단위', '단위'],
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        Format: $getConstants('UNIT').code,
        Width: 50,
      },
      {
        Header: ['입고수량', '입고수량'],
        Name: 'qty',
        Align: 'right',
        Width: 90,
        Extend: that.$ibsheetHelper.preset.quantityFormula,
      },
      {
        Header: ['단가', '단가'],
        Name: 'price',
        Align: 'right',
        Width: 100,
        Extend: that.$ibsheetHelper.preset.unitAmountFormula,
      },
      {
        Header: ['입고금액', '입고금액'],
        Name: 'amt',
        Align: 'right',
        Width: 140,
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: [{Value: '계약(발주)내역'}, '계약번호'],
        Name: 'poNo',
        Type: 'Text',
        Align: 'center',
        Width: 160,
        CustomFormat: v => COMMON_FUNCTION.getMakeFormat(v),
      },
      {
        Header: [{Value: '계약(발주)내역'}, '계약명칭'],
        Name: 'ttl',
        Type: 'Text',
        Align: 'center',
      },
      {
        Header: [{Value: '계약(발주)내역'}, '계약수량'],
        Name: 'contQty',
        Type: 'Text',
        Extend: that.$ibsheetHelper.preset.quantityFormula,
      },
      {
        Header: [{Value: '계약(발주)내역'}, '계약단가'],
        Name: 'contPrice',
        Type: 'Text',
        Extend: that.$ibsheetHelper.preset.unitAmountFormula,
      },
      {
        Header: [{Value: '계약(발주)내역'}, '계약금액'],
        Name: 'contAmt',
        Type: 'Text',
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: [{Value: '계약(발주)내역'}, '기입고수량'],
        Name: 'contInputQty',
        Type: 'Text',
        Extend: that.$ibsheetHelper.preset.quantityFormula,
      },
      {
        Header: [{Value: '계약(발주)내역'}, '잔여수량'],
        Name: 'contRemainingQty',
        Type: 'Text',
        Extend: that.$ibsheetHelper.preset.quantityFormula,
      },
      {Name: 'seq', Visible: false},
    ],
  };
};
