<template>
  <pmis-tab-box no-border-box no-padding-box>
    <template #header-left>
      <label>입고일자</label>
      <iui-datepicker :value.sync="searchInfo.searchIoDate" @change="onChangeDate" :disabledDate="disabledDate" />
    </template>
    <iui-container-new type="css-flex">
      <i-row height="40px">
        <i-col>
          <iui-container-new type="table" theme="bullet">
            <i-row>
              <i-col>
                <label>거래처명</label>
                <iui-text width="100px" :value.sync="searchInfo.custCode" :enable="false" />
                <input type="button" class="i_search" @click="selectCustomerPopup" />
                <iui-text :value.sync="searchInfo.custName" :enable="false" />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>계약(발주)목록</template>
            <template #header-left>
              <i-header-multi-box>
                <div>
                  <label>검색구분</label>
                  <iui-select defaultCd="S" :items="searchItems" :value.sync="searchInfo.searchSelect" />
                  <iui-text type="search" width="150px" :value.sync="searchInfo.searchText" @enter="onSearch" />
                  <label>계약(발주)일</label>
                  <iui-datepicker
                    :value.sync="searchInfo.searchFrDt"
                    @change="onSearch"
                    :group="searchDateGroup.seq1"
                  />
                  <label>~</label>
                  <iui-datepicker
                    :value.sync="searchInfo.searchToDt"
                    @change="onSearch"
                    :group="searchDateGroup.seq2"
                  />
                </div>
              </i-header-multi-box>
            </template>
            <template #header-right>
              <iui-button value="검색" @click="onSearch" />
            </template>
            <ib-sheet
              :uid="_uid"
              :options="Options"
              :loadSearchData="loadSearchData"
              :events="{
                onRenderFirstFinish,
                onClick,
                onDblClick,
              }"
              @loadSheet="loadSheet"
            />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
    <iui-modal name="customerPopup" title="거래처선택" :btns="customerPopupBtns" size-type="size2">
      <CustomerPopup />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import options from './sheetOptions/WarehousingPopupSheet.js';
import {selectMonthClosePossibleAt} from '@/view/closing/expitmCloseRegist/api/expitmCloseRegist.js';
import {selectPoCompleteList} from '../api/warehousing.js';
import CustomerPopup from './CustomerPopup.vue';

export default {
  components: {
    CustomerPopup,
  },
  data() {
    return {
      loadSearchData: [],
      Options: options(this),
      bindArray: ['setSearchInfo'],

      customerPopupBtns: [{name: '확인', callback: this.customerPopup}],

      searchItems: [
        {text: $getConstants('WAREHOUSING_SEARCH_ITEM1').name, value: $getConstants('WAREHOUSING_SEARCH_ITEM1').code},
        {text: $getConstants('WAREHOUSING_SEARCH_ITEM2').name, value: $getConstants('WAREHOUSING_SEARCH_ITEM2').code},
        {text: $getConstants('WAREHOUSING_SEARCH_ITEM3').name, value: $getConstants('WAREHOUSING_SEARCH_ITEM3').code},
      ],

      searchDateGroup: {
        seq1: {grpNm: 'searchDate', seq: 1},
        seq2: {grpNm: 'searchDate', seq: 2},
      },
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'monPjtCloseAt']);
    $mapMutations(this, ['setSearchInfo', 'setMomPjtCloseAt']);
  },
  created() {
    this.initDate();
    this.onChangeDate($_getCurrentDate());
    this.addEvent([
      {name: 'getWarehousingPopUp', func: this.getWarehousingPopUp},
      {name: 'customerPopup', func: this.customerPopup},
    ]);
  },
  methods: {
    disabledDate(date) {
      return $_getCurrentDate() < $_dateToStringYYMMDD(date);
    },
    selectCustomerPopup() {
      this.$modal.show('customerPopup');
    },
    customerPopup() {
      this.callEvent({
        name: 'unOrder',
        param: data => {
          this.setSearchInfo(data);
        },
      });
      if (this.searchInfo.custCode == '') {
        this.$alert({tilte: '거래처 선택', message: '거래처를 선택해주세요.'});
        return;
      }

      this.$modal.hide('customerPopup');
    },
    initDate() {
      this.setSearchInfo({searchIoDate: $_getCurrentDate()});
    },
    onChangeDate(ioDate) {
      if (!ioDate) return;

      const param = {pgmCd: this.pgmCd, closeMm: String(ioDate).substring(0, 6)};
      selectMonthClosePossibleAt(param).then(response => {
        if (response.data.CHECK1 == 'N') {
          this.setMomPjtCloseAt('Y');
        } else {
          this.setMomPjtCloseAt('N');
        }

        if (ioDate > $_getCurrentDate() || this.monPjtCloseAt == 'Y') {
          this.$alert({title: '입고일자', message: '금일 이후 또는 월 마감상태 입니다.'});
          this.initDate();
        }
      });
    },
    unordered(e) {
      let param = {
        poNo: '',
        contSeq: '',
      };
      this.setSearchInfo(param);
      let isOrder = false;
      this.loadSearchData.forEach(el => {
        if (el.custCode == e.bsnNo) {
          isOrder = true;
        }
      });
      if (isOrder) {
        this.$alert({title: '거래처', message: '선택한 거래처는 계약(발주)되어있습니다.'});
        return;
      }
    },
    getWarehousingPopUp(callback) {
      if (typeof callback == 'function') {
        let param = {
          ...this.searchInfo,
          monPjtCloseAt: this.monPjtCloseAt,
        };
        callback(param);
      }
    },
    onSearch() {
      let param = {...this.searchInfo};
      selectPoCompleteList(param).then(response => {
        this.loadSearchData = response.data;
      });
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.setSearchInfo(e.row);
      }
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    onDblClick(e) {
      if (e.row.Kind == 'Data') {
        this.callEvent({name: 'onHideWarehousingPopup'});
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
  },
};
</script>

<style></style>
