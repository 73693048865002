<template>
  <div id="app" class="h_100">
    <component v-bind:is="currentView"></component>
    <iui-alert />
    <iui-confirm />
    <iui-pdf-viewer />
    <pmis-file-progress />
  </div>
</template>

<script>
import OzReportMobile from '@/mobile/components/OzReportMobile';
import {modalClose} from '@/modalEvent';
export default {
  name: 'app',
  data() {
    return {
      currentView: null,
    };
  },
  watch: {
    page() {
      this.doPageChange();
    },
  },
  beforeCreate() {
    window.addEventListener('keyup', event => {
      if (event.keyCode === 27) {
        modalClose(this);
      }
    });
  },
  created() {
    window.init = this.init;
  },
  mounted() {
    this.doPageChange();
    // params.mobilePath;
  },
  methods: {
    doPageChange() {
      if (params && params.isPopup) return;

      switch (params.mobilePath) {
        case 'OzReportMobile':
          this.currentView = OzReportMobile;
          break;
        default:
          this.currentView = null;
      }
    },
    init(param) {
      sessionStorage.setItem('id', param.empNo);
      sessionStorage.setItem('userInfo', Base64.decode(param.accessToken.split('.')[1]));
    },
  },
};
</script>

<style></style>
