<template>
  <modal
    :name="internalName"
    :width="internalWidth"
    :height="internalHeight"
    :scrollable="false"
    :clickToClose="false"
    :draggable="'.popup_header'"
    style="z-index: 100;"
    classes="__modal__"
    @before-open="beforeOpen"
    @before-close="beforeClose"
    @opened="opened"
    @closed="closed"
  >
    <div class="popup_modal">
      <div class="fg" :style="{width: '100%', height: '100%'}">
        <div class="popup_header">
          <div>{{ internalTitle }}</div>
          <iui-button btn-class="btn_pop_close" @click="onClose" v-if="enableCloseBtn">
            <span class="blind">닫기</span>
          </iui-button>
        </div>
        <div class="popup_contents">
          <slot></slot>
        </div>
        <div class="popup_footer">
          <div class="footer-left"></div>
          <div class="footer-center"></div>
          <div class="footer-right">
            <iui-button
              v-for="btn in btns2"
              v-bind:key="btn.name"
              :color="btn.name == '닫기' ? 'gray' : undefined"
              normal
              @click="btn.callback"
              >{{ btn.name }}
            </iui-button>
            <iui-button normal color="gray" @click="onClose" v-if="enableCloseBtn">닫기</iui-button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import {getModalName, modalOpen, modalClose, afterModalClose} from '@/modalEvent';

const MODAL_SIZE = {
  size1: {width: '600px', height: '500px'}, //공종선택
  size2: {width: '700px', height: '600px'}, //회사선택
  size3: {width: '1000px', height: '680px'}, //대가내역복사
  size4: {width: '800px', height: '350px'},
  size5: {width: '600px', height: '250px'},
  size6: {width: '1200px', height: '800px'},
};

export default {
  props: {
    name: {
      type: String,
    },
    title: {
      type: String,
      default: undefined,
    },
    btns: {
      type: Array,
      default: function() {
        return [];
      },
    },
    width: {
      type: String,
      default: '700px',
    },
    height: {
      type: String,
      default: '680px', // 70%
    },
    enableCloseBtn: {
      type: Boolean,
      default: true,
    },
    isEscape: {
      type: Boolean,
      default: true,
    },
    sizeType: {
      type: String,
      default: 'default',
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalTitle: '',
      btns2: [],

      internalWidth: '',
      internalHeight: '',
      internalName: '',
    };
  },
  created() {
    this.internalTitle = this.title;
    this.internalName = this.isDefault ? this.name : getModalName(this);
    if (this.btns.length) {
      this.btns.forEach(obj => {
        if (!obj.callback) obj.callback = () => {};
      });
      this.btns2 = this.btns.filter(item => (item.mapKey && item.mapKey == item.key) || item.mapKey === undefined);
    }

    if (this.sizeType == 'default' || MODAL_SIZE[this.sizeType] === undefined) {
      this.internalWidth = this.width;
      this.internalHeight = this.height;
    } else {
      this.internalWidth = MODAL_SIZE[this.sizeType].width;
      this.internalHeight = MODAL_SIZE[this.sizeType].height;
    }
  },
  methods: {
    onClose() {
      modalClose(this);
    },
    beforeOpen(event) {
      this.$emit('beforeOpen', event);
      if (event.params && event.params.title) {
        this.internalTitle = event.params.title;
      }
      modalOpen(this);
    },
    opened() {
      this.$emit('opened');
    },
    beforeClose(e) {
      this.$emit('beforeClose', e);
    },
    closed() {
      this.$emit('closed');
      afterModalClose(this);
    },
  },
  watch: {
    title: function(newValue) {
      this.internalTitle = newValue;
    },
    btns: {
      handler(value) {
        this.btns2 = value.filter(item => (item.mapKey && item.mapKey == item.key) || item.mapKey === undefined);
      },
      deep: true,
    },
    name() {
      this.internalName = getModalName(this);
    },
  },
};
</script>

<style>
.v--modal {
  border-radius: 10px;
}

.v--modal-overlay .v--modal-box {
  overflow: visible;
}
</style>
