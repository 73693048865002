export default that => {
  return {
    Cols: [
      {Header: '코드', Name: 'itemCode', Width: 130, CanEdit: false, Align: 'center'},
      {Header: '명칭', Name: 'itemName', RelWidth: 3, CanEdit: false},
      {Header: '규격', Name: 'ssize', RelWidth: 2, CanEdit: false, Visible: that.costType != 'L'},
      {Header: '단위', Name: 'unit', RelWidth: 1, Format: $getConstants('UNIT').code, CanEdit: false},
    ],
  };
};
