export default that => {
  let preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      CanEdit: 1,
    },
    Def: {
      Row: {
        CanFormula: 1,
        CalcOrder: 'closeYn1CanEdit,closeYn2CanEdit',
      },
    },
    LeftCols: [
      {
        Header: '일자',
        Name: 'investDt1',
        Type: 'Text',
        Width: 70,
        Align: 'Center',
        CustomFormat: dt => dt.substring(6, 8),
        CanEdit: 0,
      },
      {Header: '공수', Name: 'investQty1', Extend: preset.quantity, Width: 60, Align: 'Center', CanEdit: 0},
      {Header: '단가', Name: 'price1', Extend: preset.unitAmount, RelWidth: 1, MinWidth: 150, CanEdit: 0},
      {Header: '금액', Name: 'amt1', Extend: preset.amount, RelWidth: 1, MinWidth: 150, CanEdit: 0},
      {
        Header: {Value: '정산', TextColor: '#444', HeaderCheck: 1},
        Name: 'closeYn1',
        Type: 'Bool',
        Width: 60,
        CustomFormat: v => {
          const obj = {};
          obj[$getConstants('Y').code] = 1;
          return obj[v];
        },
        CanEditFormula: fr => fr.Row.amt1 > 0 && that.cud === 1,
      },
    ],
    Cols: [
      {
        Name: ' ',
        Type: 'Text',
        Width: 5,
        Align: 'Center',
        CanEdit: 0,
      },
    ],
    RightCols: [
      {
        Header: '일자',
        Name: 'investDt2',
        Type: 'Text',
        Width: 70,
        Align: 'Center',
        CustomFormat: dt => dt.substring(6, 8),
        CanEdit: 0,
      },
      {Header: '공수', Name: 'investQty2', Extend: preset.quantity, Width: 60, Align: 'Center', CanEdit: 0},
      {Header: '단가', Name: 'price2', Extend: preset.unitAmount, RelWidth: 1, MinWidth: 150, CanEdit: 0},
      {Header: '금액', Name: 'amt2', Extend: preset.amount, RelWidth: 1, MinWidth: 150, CanEdit: 0},
      {
        Header: {Value: '정산', TextColor: '#444', HeaderCheck: 1},
        Name: 'closeYn2',
        Type: 'Bool',
        Width: 60,
        CustomFormat: v => {
          const obj = {};
          obj[$getConstants('Y').code] = 1;
          return obj[v];
        },
        CanEditFormula: fr => fr.Row.amt2 > 0 && that.cud === 1,
      },
    ],
  };
};
