<template>
  <iui-container-new type="css-flex">
    <pmis-tab-box :loading="loading">
      <template #title>기성투입현황</template>
      <template v-slot:header-left>
        <label>작업년도</label>
        <iui-datepicker type="year" :value="reqYm" @change="onReqYmChange($event)"></iui-datepicker>
      </template>
      <template v-slot:header-center> {{ reqYm }}년</template>
      <template v-slot:header-right>
        <iui-amount-unit :amount-unit="amountUnit" @change="onAmountChange" />
      </template>
      <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" @loadSheet="sheet = $event" />
    </pmis-tab-box>
    <iui-modal name="PxcondInputModal" title="기성대투입조회 출력" size-type="size6">
      <report-viewer :fomNo="fomNo" :ozContent="ozContent" />
    </iui-modal>
  </iui-container-new>
</template>

<script>
import ReportViewer from '@/view/bidding/components/ReportViewer.vue';
import options from './sheetOptions/PxcondInputSheet.js';
import {selectPxcondInputList} from '@/view/Project/pxcondStatus/input/api/input.js';
import IuiAmountUnit from '@/components/common/IuiAmountUnit.vue';
import FOM_NO from '@/constants/fomNoConstants.js';

export default {
  components: {
    ReportViewer,
    IuiAmountUnit,
  },
  data() {
    return {
      fomNo: FOM_NO.PXCOND_INPUT,
      loading: false,
      Options: null,
      loadSearchData: [],
      ozContent: {},
      sheet: undefined,
      code: $getConstants('AMOUNT_UNIT').code,

      planPxcondList: {}, // 계획 > 기성
      planInputList: {}, // 계획 > 투입
      planProfitList: {}, // 계획 > 손익
      planContrastList: {}, // 계획 > 대비

      resultPxcondList: {}, // 실적 > 기성
      resultInputList: {}, // 실적 > 투입
      resultProfitList: {}, // 실적 > 손익
      resultContrastList: {}, // 실적 > 대비

      compareProfitList: {}, // 대비 > 손익

      reqYm: new Date().getFullYear(),
      responseData: {
        sheet: [],
      },
      amountUnit: '',
    };
  },
  created() {
    this.amountUnit = this.userInfo?.amountUnit
      ? this.userInfo.amountUnit
      : this.$store.state.code2.codeAlias.AMOUNT_UNIT_ONE_THOUSAND_WON.code;

    this.addFuncPrint(this.onReportViewer);
    this.addFuncPrj(this.onSearch);
    this.Options = options(this);
  },
  methods: {
    onReqYmChange(reqYm) {
      this.reqYm = reqYm;
      this.onSearch();
    },
    async onSearch() {
      this.loading = true;
      try {
        const response = await selectPxcondInputList({reqYm: this.reqYm});
        this.calculate(response.data);

        let gb1 = {PLAN: '계획', RESULT: '실적', CONT: '대비'};
        let gb2 = {PXC: '기성', INP: '투입', PRO: '손익', CONT: '대비'};

        this.planPxcondList['gb1'] = gb1.PLAN;
        this.planPxcondList['gb2'] = gb2.PXC;

        this.planInputList['gb1'] = gb1.PLAN;
        this.planInputList['gb2'] = gb2.INP;

        this.planProfitList['gb1'] = gb1.PLAN;
        this.planProfitList['gb2'] = gb2.PRO;

        this.planContrastList['gb1'] = gb1.PLAN;
        this.planContrastList['gb2'] = gb2.CONT;

        this.resultPxcondList['gb1'] = gb1.RESULT;
        this.resultPxcondList['gb2'] = gb2.PXC;

        this.resultInputList['gb1'] = gb1.RESULT;
        this.resultInputList['gb2'] = gb2.INP;

        this.resultProfitList['gb1'] = gb1.RESULT;
        this.resultProfitList['gb2'] = gb2.PRO;

        this.resultContrastList['gb1'] = gb1.RESULT;
        this.resultContrastList['gb2'] = gb1.CONT;

        this.compareProfitList['gb1'] = gb1.CONT;
        this.compareProfitList['gb2'] = gb2.PRO;

        this.responseData.sheet = [
          {...this.planPxcondList},
          {...this.planInputList},
          {...this.planProfitList},
          {...this.planContrastList},
          {...this.resultPxcondList},
          {...this.resultInputList},
          {...this.resultProfitList},
          {...this.resultContrastList},
          {...this.compareProfitList},
        ];
        this.convertPriceUnit();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    calculate(items) {
      let months = {
        jan: '01',
        feb: '02',
        mar: '03',
        apr: '04',
        may: '05',
        jun: '06',
        jul: '07',
        aug: '08',
        sep: '09',
        oct: '10',
        nov: '11',
        dec: '12',
      };

      const list = [
        'planPxcondList',
        'planInputList',
        'planProfitList',
        'planContrastList',
        'resultPxcondList',
        'resultInputList',
        'resultProfitList',
        'resultContrastList',
        'compareProfitList',
      ];

      list.forEach(item => {
        this[item]['subTotal'] = 0;
        this[item]['totRate'] = 0;
      });

      for (let month in months) {
        const planPxcond = items.planPxcondList.find(item => item.reqYm.substring(4, 6) == months[month]);
        const planInput = items.planInputList.find(item => item.reqYm.substring(4, 6) == months[month]);
        const resultPxcond = items.resultPxcondList.find(item => item.reqYm.substring(4, 6) == months[month]);
        const resultInput = items.resultInputList.find(item => item.reqYm.substring(4, 6) == months[month]);

        // 계획
        this.planPxcondList[month] = planPxcond ? Math.floor(planPxcond.amt) : 0;
        this.planInputList[month] = planInput ? Math.floor(planInput.amt) : 0;
        this.planProfitList[month] = Math.floor(this.planPxcondList[month] - this.planInputList[month]);
        this.planContrastList[month] =
          this.planPxcondList[month] == 0 || this.planInputList[month] == 0
            ? '0%'
            : Math.floor((this.planInputList[month] / this.planPxcondList[month]) * 100) + '%';

        this.planPxcondList['subTotal'] += this.planPxcondList[month];
        this.planInputList['subTotal'] += this.planInputList[month];
        this.planProfitList['subTotal'] += this.planProfitList[month];
        this.planContrastList['subTotal'] += this.planContrastList[month];

        //실적
        this.resultPxcondList[month] = resultPxcond ? Math.floor(resultPxcond.amt) : 0;
        this.resultInputList[month] = resultInput ? Math.floor(resultInput.amt) : 0;
        this.resultProfitList[month] = Math.floor(this.resultPxcondList[month] - this.resultInputList[month]);
        this.resultContrastList[month] =
          this.resultPxcondList[month] == 0 || this.resultInputList == 0
            ? '0%'
            : Math.floor((this.resultInputList[month] / this.resultPxcondList[month]) * 100) + '%';

        this.compareProfitList[month] =
          this.resultPxcondList[month] == 0 || this.planPxcondList[month] == 0
            ? '0%'
            : Math.floor((this.resultPxcondList[month] / this.planPxcondList[month]) * 100) + '%';

        this.resultPxcondList['subTotal'] += this.resultPxcondList[month];
        this.resultInputList['subTotal'] += this.resultInputList[month];
        this.resultProfitList['subTotal'] += this.resultProfitList[month];
        this.resultContrastList['subTotal'] += this.resultContrastList[month];
      }

      //계획 > 전년까지
      this.planPxcondList['beforeYear'] = Math.floor(items.beforePlanPxcondList[0].amt);
      this.planInputList['beforeYear'] = Math.floor(items.beforePlanInputList[0].amt);
      this.planProfitList['beforeYear'] = Math.floor(
        items.beforePlanPxcondList[0].amt - items.beforePlanInputList[0].amt
      );
      this.planContrastList['beforeYear'] =
        items.beforePlanPxcondList[0].amt == 0 || items.beforePlanInputList[0].amt == 0
          ? '0%'
          : Math.floor((items.beforePlanInputList[0].amt / items.beforePlanPxcondList[0].amt) * 100) + '%';

      //계획 > 소계
      this.planContrastList['subTotal'] =
        this.planInputList['subTotal'] == 0 || this.planPxcondList['beforeYear'] == 0
          ? '0%'
          : Math.floor((this.planInputList['subTotal'] / this.planPxcondList['beforeYear']) * 100) + '%';

      //계획 > 누계
      this.planPxcondList['totRate'] = this.planPxcondList['beforeYear'] + this.planPxcondList['subTotal'];
      this.planInputList['totRate'] = this.planInputList['beforeYear'] + this.planInputList['subTotal'];
      this.planProfitList['totRate'] = this.planProfitList['beforeYear'] + this.planProfitList['subTotal'];

      this.planContrastList['totRate'] =
        this.planInputList['totRate'] == 0 || this.planPxcondList['totRate'] == 0
          ? '0%'
          : Math.floor((this.planInputList['totRate'] / this.planPxcondList['totRate']) * 100) + '%';

      //실적 > 전년까지
      this.resultPxcondList['beforeYear'] = Math.floor(items.beforeResultPxcondList[0].amt);
      this.resultInputList['beforeYear'] = Math.floor(items.beforeResultInputList[0].amt);
      this.resultProfitList['beforeYear'] = Math.floor(
        items.beforeResultPxcondList[0].amt - items.beforeResultInputList[0].amt
      );
      this.resultContrastList['beforeYear'] =
        items.beforeResultPxcondList[0].amt == 0 || items.beforeResultInputList[0].amt == 0
          ? '0%'
          : Math.floor((items.beforeResultInputList[0].amt / items.beforeResultPxcondList[0].amt) * 100) + '%';

      //실적 > 소계
      this.resultContrastList['subTotal'] =
        this.resultInputList['subTotal'] == 0 || this.resultPxcondList['subTotal'] == 0
          ? '0%'
          : Math.floor((this.resultInputList['subTotal'] / this.resultPxcondList['subTotal']) * 100) + '%';

      //실적 > 누계
      this.resultPxcondList['totRate'] = this.resultPxcondList['beforeYear'] + this.resultPxcondList['subTotal'];
      this.resultInputList['totRate'] = this.resultInputList['beforeYear'] + this.resultInputList['subTotal'];
      this.resultProfitList['totRate'] = this.resultProfitList['beforeYear'] + this.resultProfitList['subTotal'];
      this.resultContrastList['totRate'] =
        this.resultInputList['totRate'] == 0 || this.resultPxcondList['totRate'] == 0
          ? '0%'
          : Math.floor((this.resultInputList['totRate'] / this.resultPxcondList['totRate']) * 100) + '%';

      //대비 > 전년까지
      this.compareProfitList['beforeYear'] =
        items.beforeResultPxcondList[0].amt == 0 || items.beforePlanPxcondList[0].amt == 0
          ? '0%'
          : Math.floor((items.beforeResultPxcondList[0].amt / items.beforePlanPxcondList[0].amt) * 100);

      // 대비 > 소계
      this.compareProfitList['subTotal'] =
        this.resultPxcondList['subTotal'] == 0 || this.planPxcondList['subTotal'] == 0
          ? '0%'
          : Math.floor((this.resultPxcondList['subTotal'] / this.planPxcondList['subTotal']) * 100) + '%';

      // 대비 > 누계
      this.compareProfitList['totRate'] =
        this.resultPxcondList['totRate'] == 0 || this.planPxcondList['totRate'] == 0
          ? '0%'
          : Math.floor((this.resultPxcondList['totRate'] / this.planPxcondList['totRate']) * 100) + '%';
    },
    async onReportViewer() {
      const checkKeyArray = [
        'year',
        'rate',
        'total',
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec',
      ];

      this.ozContent = {
        year: this.reqYm,
        amountUnit: await $getCodeNm(this.code, this.amountUnit),
        planPxcondList: JSON.stringify({
          planPxcondList: $_priceUnitFormat(this.amountUnit, [this.planPxcondList], checkKeyArray)[0],
        }),
        planInputList: JSON.stringify({
          planInputList: $_priceUnitFormat(this.amountUnit, [this.planInputList], checkKeyArray),
        }),
        planProfitList: JSON.stringify({
          planProfitList: $_priceUnitFormat(this.amountUnit, [this.planProfitList], checkKeyArray),
        }),
        planContrastList: JSON.stringify({planContrastList: {...this.planContrastList}}),
        resultPxcondList: JSON.stringify({
          resultPxcondList: $_priceUnitFormat(this.amountUnit, [this.resultPxcondList], checkKeyArray),
        }),
        resultInputList: JSON.stringify({
          resultInputList: $_priceUnitFormat(this.amountUnit, [this.resultInputList], checkKeyArray),
        }),
        resultProfitList: JSON.stringify({
          resultProfitList: $_priceUnitFormat(this.amountUnit, [this.resultProfitList], checkKeyArray),
        }),
        resultContrastList: JSON.stringify({resultContrastList: {...this.resultContrastList}}),
        compareProfitList: JSON.stringify({compareProfitList: {...this.compareProfitList}}),
      };
      this.$modal.show('PxcondInputModal');
    },
    async onAmountChange(value) {
      this.amountUnit = value;
      this.convertPriceUnit();
    },
    convertPriceUnit() {
      const checkKeyArray = [
        'year',
        'rate',
        'total',
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec',
      ];
      const excludeIndexArray = [3, 7, 8];

      this.loadSearchData = $_priceUnitFormat(
        this.amountUnit,
        this.responseData.sheet,
        checkKeyArray,
        excludeIndexArray
      );
    },
  },
};
</script>

<style></style>
