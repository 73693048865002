export default that => {
  return {
    Def: {
      Row: {
        CanFormula: 1,
      },
    },
    Cfg: {
      HeaderMerge: 3,
      CanEdit: 0,
    },
    Cols: [
      {Name: 'total', Visible: 1, Width: 1, CanFocus: 0, ShowHint: 0, Class: '_total'},
      {Name: 'lgName', Visible: 1, Width: 1, CanFocus: 0, ShowHint: 0, Class: '_total'},
      {
        Header: ['실행내역', {Value: '공종명', Color: '#dff9fb'}],
        Name: 'mgName',
        Type: 'Text',
        Width: 200,
      },
      {
        Header: ['실행내역', {Value: '내역명', Color: '#dff9fb'}],
        Name: 'itemName',
        Type: 'Text',
        Width: 200,
      },
      {
        Header: ['실행내역', {Value: '규격', Color: '#dff9fb'}],
        Name: 'ssize',
        Type: 'Text',
        Width: 120,
        Align: 'Center',
      },
      {
        Header: ['실행내역', {Value: '단위', Color: '#dff9fb'}],
        Name: 'unit',
        Type: 'Text',
        Width: 100,
        Align: 'Center',
        Format: $getConstants('UNIT').code,
      },
      {
        Header: ['실행내역', {Value: '수량', Color: '#dff9fb'}],
        Name: 'moqty',
        Extend: that.$ibsheetHelper.preset.quantity,
      },
      {
        Header: ['실행내역', {Value: '단가', Color: '#dff9fb'}],
        Name: 'price',
        Extend: that.$ibsheetHelper.preset.unitAmountFormula,
      },
      {
        Header: ['실행내역', {Value: '금액', Color: '#dff9fb'}],
        Name: 'amt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: ['실행기성계획', {Value: '전월누계', Color: '#dff9fb'}],
        Name: 'beforePlanExecAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: ['실행기성계획', {Value: '금월', Color: '#dff9fb'}],
        Name: 'planExecAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: ['실행기성계획', {Value: '누계', Color: '#dff9fb'}],
        Name: 'totalPlanExecAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: ['실행기성계획', {Value: '잔여', Color: '#dff9fb'}],
        Name: 'leftPlanExecAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: ['실행기성실적', {Value: '전회누계', Color: '#dff9fb'}],
        Name: 'preExecAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: ['실행기성실적', {Value: '금회', Color: '#dff9fb'}],
        Name: 'curExecAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: ['실행기성실적', {Value: '누계', Color: '#dff9fb'}],
        Name: 'totalExecAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: ['실행기성실적', {Value: '잔여', Color: '#dff9fb'}],
        Name: 'leftExecAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: ['실행기성실적', {Value: '기성율', Color: '#dff9fb'}],
        Name: 'execContRate',
        Extend: that.$ibsheetHelper.preset.rateFormula,
      },
      {
        Header: ['원가투입실적', {Value: '전회누계', Color: '#dff9fb'}],
        Name: 'preForceAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: ['원가투입실적', {Value: '금회', Color: '#dff9fb'}],
        Name: 'curForceAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: ['원가투입실적', {Value: '누계', Color: '#dff9fb'}],
        Name: 'totalForceAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: ['원가투입실적', {Value: '잔여', Color: '#dff9fb'}],
        Name: 'leftForceAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
      },
      {
        Header: ['원가투입실적', {Value: '기성율', Color: '#dff9fb'}],
        Name: 'forceContRate',
        Extend: that.$ibsheetHelper.preset.rateFormula,
      },
      {Name: 'headCode', Visible: 0},
      {Name: 'midCode', Visible: 0},
    ],
  };
};
