<template>
  <iui-container-new type="css-flex">
    <i-row height="240px">
      <i-col>
        <pmis-content-box>
          <template #title>협력사목록</template>
          <template #header-left>
            <label>검색구분</label>
            <iui-select defaultCd="S" :items="searchItems" :value.sync="searchSelect" />
            <iui-text type="search" :value.sync="searchText" @enter="onSearch" />
            <label>지역</label>
            <iui-select p-code="0117" defaultCd="A" :value.sync="searchArea" />
            <iui-button value="검색" @click="onSearch" />
          </template>
          <ib-sheet
            :uid="_uid"
            :options="sheetOpt1"
            :loadSearchData="customerList"
            @loadSheet="sheet1 = $event"
            :events="{
              onSearchFinish: sheet1_onGridDataLoad,
              onClick: sheet1_onGridClick,
            }"
          />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box>
          <template #title>회사기본정보</template>
          <iui-container-new type="table" theme="bullet">
            <colgroup>
              <col width="70px" />
              <col />
              <col width="10px" />
              <col width="90px" />
              <col />
              <col width="10px" />
              <col width="95px" />
              <col />
              <col width="10px" />
              <col width="100px" />
              <col />
            </colgroup>
            <i-row>
              <i-col-header>회사코드</i-col-header>
              <i-col colspan="4"><iui-text :value="company.pgmCd" :enable="false" width="60px" align="center"/></i-col>
              <i-spacer />
              <i-col-header width="100px">회사구분</i-col-header>
              <i-col><iui-text :value="company.frmGbNm" :enable="false"/></i-col>
            </i-row>
            <i-row>
              <i-col-header>회사명</i-col-header>
              <i-col colspan="4"><iui-text :value="company.frmNm" :enable="false"/></i-col>
              <i-spacer />
              <i-col-header>사업자번호</i-col-header>
              <i-col>
                <iui-bsn-no
                  :value1="company.bsnNo.substr(0, 3)"
                  :value2="company.bsnNo.substr(3, 2)"
                  :value3="company.bsnNo.substr(5, 5)"
                  :enable="false"
                />
              </i-col>
              <i-spacer />
              <i-col-header>주민/법인번호</i-col-header>
              <i-col><iui-text :value="company.regiNo" :enable="false"/></i-col>
            </i-row>
            <i-row>
              <i-col-header>대표자명</i-col-header>
              <i-col colspan="4">
                <iui-text :value="company.rpsvNm" :enable="false" width="100px" />
                <iui-button value="공동대표" @click="onClickCeo" v-if="isExistCeo" />
              </i-col>
              <i-spacer />
              <i-col-header>업태</i-col-header>
              <i-col><iui-text :value="company.bcoNm" :enable="false"/></i-col>
              <i-spacer />
              <i-col-header>종목</i-col-header>
              <i-col><iui-text :value="company.itemNm" :enable="false"/></i-col>
            </i-row>
            <i-row>
              <i-col-header>대표전화</i-col-header>
              <i-col colspan="4"
                ><iui-text type="phone" :value="company.rpsvPhone" :enable="false" width="100px"
              /></i-col>
              <i-spacer />
              <i-col-header>{{ isMat ? '대표납품지역' : '대표공사지역' }}</i-col-header>
              <i-col colspan="4"><iui-text :value="company.areas" :enable="false"/></i-col>
            </i-row>
            <i-row>
              <i-col-header>주소</i-col-header>
              <i-col colspan="6">
                <iui-text width="50px" :value="company.postNo" :enable="false" align="center" />
                <iui-text :value="company.adr1" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col>&nbsp;</i-col>
              <i-col colspan="6"><iui-text :value="company.adr2" :enable="false"/></i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row height="140px">
      <i-col>
        <pmis-content-box>
          <template #title>
            {{ isMat ? '공급물품(자재)정보' : '보유면허(세부공종)정보' }}
          </template>
          <ib-sheet
            :uid="_uid"
            :options="isMat ? sheetOpt21 : sheetOpt22"
            :loadSearchData="dealItemList"
            @loadSheet="sheet2 = $event"
          />
        </pmis-content-box>
      </i-col>
    </i-row>

    <iui-modal name="ceoModal" title="공동대표" size-type="size1">
      <CustomerCeoInfo :propsPgmCd="propsPgmCd" />
    </iui-modal>
  </iui-container-new>
</template>

<script>
import sheetOpt1 from './sheetOptions/customerChoice1.js';
import sheetOpt21 from './sheetOptions/customerChoiceSheet21.js';
import sheetOpt22 from './sheetOptions/customerChoiceSheet22.js';
import {selectCustomerListForRfq} from '@/view/bidding/rfq/mat/api/rfq.js';
import {selectCompanyInfo} from '@/view/member/api/member.js';

import CustomerCeoInfo from './CustomerCeoInfo.vue';

export default {
  components: {CustomerCeoInfo},
  props: {
    frmDs: {
      type: String,
      default: 'mat',
    },
  },
  data() {
    return {
      sheetOpt1: sheetOpt1(this),
      sheetOpt21: sheetOpt21(this),
      sheetOpt22: sheetOpt22(this),

      customerList: [],
      dealItemList: [],

      searchItems: [
        {
          text: $getConstants('CUSTOMER_CHOICE_SEARCH_ITEM1').name,
          value: $getConstants('CUSTOMER_CHOICE_SEARCH_ITEM1').code,
        },
        {
          text: $getConstants('CUSTOMER_CHOICE_SEARCH_ITEM2').name,
          value: $getConstants('CUSTOMER_CHOICE_SEARCH_ITEM2').code,
        },
        {
          text: $getConstants('CUSTOMER_CHOICE_SEARCH_ITEM3').name,
          value: $getConstants('CUSTOMER_CHOICE_SEARCH_ITEM3').code,
        },
        {
          text: $getConstants('CUSTOMER_CHOICE_SEARCH_ITEM4').name,
          value: $getConstants('CUSTOMER_CHOICE_SEARCH_ITEM4').code,
        },
      ],
      searchSelect: '',
      searchText: '',
      searchArea: '',

      areaCodes: {},

      company: {
        pgmCd: '', // 회사코드
        frmGb: '', // 회사구분
        frmGbNm: '', // 회사구분명
        frmNm: '', // 회사명
        frmEnNm: '', // 회사명(영문)
        bsnNo: '', // 사업자번호
        regiNo: '', // 주민/법인번호
        rpsvNm: '', // 대표자명
        rpsvEnNm: '', // 대표자명(영문)
        rpsvPhone: '', // 대표전화번호
        postNo: '', // 우편번호
        adr1: '', // 주소
        adr2: '', // 상세주소
        homepage: '', // 홈페이지
        bcoNm: '', // 업태
        itemNm: '', // 종목
        areas: '', // 납품/공사지역
        coCeoCount: 0,
      },
      isExistCeo: false,
      propsPgmCd: '',
    };
  },
  computed: {
    isMat() {
      return this.frmDs === 'mat';
    },
  },
  created() {
    this.addEvent({name: 'CustomerChoice_Confirm', func: this.onConfirm});

    // 공사지역(시도)
    $getCode($getConstants('SITE_AREA1_CD').code).then(codes =>
      codes.forEach(item => (this.areaCodes[item.code] = item.codeNm))
    );
    this.onSearch();
  },
  methods: {
    onSearch() {
      const param = {
        pgmCd: this.userInfo.pgmCd,
        frmDs: {mat: $getConstants('FRM_DS_MAT').code, sub: $getConstants('FRM_DS_WORK_GROUP').code}[this.frmDs],
        searchSelect: this.searchSelect,
        searchText: this.searchText,
        searchArea: this.searchArea,
      };
      selectCustomerListForRfq(param).then(response => {
        this.customerList = response.data;
      });
    },
    sheet1_onGridDataLoad() {
      this.onSetRow(this.sheet1.getFirstRow());
    },
    sheet1_onGridClick(e) {
      if (e.row.Kind == 'Data') {
        this.onSetRow(e.row);
      }
    },
    onSetRow(row) {
      if (row) {
        this.onSearchDetail(row);
      } else {
        this.onInit();
      }
    },
    async onSearchDetail(row) {
      const response = await selectCompanyInfo({pgmCd: row.cusPgmCd});

      for (let key in this.company) {
        this.company[key] = response.data[key];
      }

      const areaList = response.data.areaList ?? [];
      areaList.forEach((item, index) => {
        const areaNm = this.areaCodes[item.siteArea2];
        const areaFDs = item.areaFDs == $getConstants('AREA_F_DS_Y').code ? '(대표지역)' : '';
        if (0 == index) {
          this.company.areas = `${areaNm}${areaFDs}`;
        } else {
          this.company.areas += `, ${areaNm}${areaFDs}`;
        }
      });

      this.dealItemList = response.data[this.isMat ? 'matList' : 'gcodeList'];

      this.isExistCeo = !!response.data.ceoList.length;
      this.propsPgmCd = response.data.pgmCd;
    },
    onInit() {
      for (let key in this.company) {
        this.company[key] = '';
      }
      this.sheet2.removeAll();
      this.propsPgmCd = '';
    },
    onClickCeo() {
      this.$modal.show('ceoModal');
    },
    onConfirm(callback) {
      if (typeof callback === 'function') {
        callback(this.sheet1.getRowsByChecked('cbx'));
      }
    },
  },
};
</script>

<style></style>
