const state = {
  searchInfo: {
    ctrtStrdate: '', // 착공일자
    ctrtEnddate: '', // 준공예정일자
  },
  detailInfo: {
    fldrNm: '', // 작업명
    planStrdate: '', // 시작예정일
    planEnddate: '', // 종료예정일
    strResultDate: '', // 실제시작일
    endResultDate: '', // 실제종료일
    workCnt: '', // 작업일수
    rt: '', // 공정율
    remndrCnt: '', // 잔여일수
    delayCnt: '', // 지연일수
    planCnt: '', // 예정수량
    resultQty: '', //실적수량
  },
};

const getters = {
  searchInfo: state => state.searchInfo,
  detailInfo: state => state.detailInfo,
};

const actions = {};

const mutations = {
  setSearchInfo(state, payload) {
    for (let key in state.searchInfo) {
      if (payload && typeof payload[key] != 'undefined') {
        state.searchInfo[key] = payload[key];
      }
    }
  },

  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && typeof payload[key] != 'undefined') {
        state.detailInfo[key] = payload[key];
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
