<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col>
        <pmis-content-box :loading="lineLoading">
          <template v-slot:header-left>
            <iui-select
              v-model="lineFilterType"
              :items="[
                {key: 'tl', text: '결재선명', value: 'tl'},
                {key: 'approver', text: '결재대상자', value: 'approver'},
              ]"
            />
            <iui-text type="search" :value.sync="lineFilterValue" @enter="btnLineSetFilter_click" />
            <iui-button @click="btnLineSetFilter_click">검색</iui-button>
          </template>

          <iui-container-new type="ibsheet" :id="elId" />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-spacer />
    <i-row>
      <i-col>
        <pmis-tab-box>
          <template v-slot:title>대상지정</template>

          <iui-container-new type="css-flex">
            <i-row>
              <i-col width="400px">
                <pmis-content-box :loading="obsLoading">
                  <template v-slot:header-left>
                    <!-- <iui-select /> -->
                    <iui-text :value.sync="obsFilterValue" @enter="btnOBSSetFilter_click" />
                    <iui-button @click="btnOBSSetFilter_click">검색</iui-button>
                  </template>

                  <iui-container-new type="ibsheet" :id="elId2" />
                </pmis-content-box>
              </i-col>
              <i-col width="30px" style="display: flex; justify-content: center; align-items: center;">
                <iui-button @click="btnAddApprover_click" :enable="canAddApprover">→</iui-button>
                <i-spacer />
                <iui-button @click="btnDeleteApprover_click" :enable="canDeleteApprover">←</iui-button>
              </i-col>
              <i-col>
                <pmis-content-box :loading="approverLoading">
                  <template v-slot:header-left>
                    <iui-text ref="txtTl" label="결재선명" :value.sync="line.tl" :enable="line.canEdit" width="350px" />
                  </template>
                  <template #header-right>
                    ※ 기안자를 제외한 대상을 등록합니다.
                  </template>

                  <iui-container-new type="ibsheet" :id="elId3" />
                </pmis-content-box>
              </i-col>
              <i-col width="30px" style="display: flex; justify-content: center; align-items: center;">
                <iui-button @click="btnUpApprover_click" :enable="canUpApprover">↑</iui-button>
                <i-spacer />
                <iui-button @click="btnDownApprover_click" :enable="canDownApprover">↓</iui-button>
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-tab-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
export default {
  data() {
    return {
      elId: 'sheetDiv1_' + this._uid,
      elId2: 'sheetDiv2_' + this._uid,
      elId3: 'sheetDiv3_' + this._uid,
      snclDs: 0,
      empNo: JSON.parse(sessionStorage.getItem('userInfo')).empNo,
      search: false,
      new: false,
      save: false,
      delete: false,

      lineLoading: false,
      obsLoading: false,
      approverLoading: false,

      canAddApprover: true,
      canDeleteApprover: true,
      canUpApprover: true,
      canDownApprover: true,

      line: {
        snclNo: undefined,
        snclDs: undefined,
        vwDs: undefined,
        pgmCd: undefined,
        grpCd: undefined,
        tl: undefined,
        canEdit: false,
        canDelete: false,
        approverList: [],
      },
      lineFilterType: 'tl',
      lineFilterValue: '',
      obsFilterValue: '',
    };
  },
  watch: {
    search(newValue) {
      if (newValue) {
        this._search();
        this.search = false;
      }
    },
    new(newValue) {
      if (newValue) {
        this._new();
        this.new = false;
      }
    },
    save(newValue) {
      if (newValue) {
        this._save();
        this.save = false;
      }
    },
    delete(newValue) {
      if (newValue) {
        this._delete();
        this.delete = false;
      }
    },
  },
  async mounted() {
    this.initGrdLine();
    this.initOBS();
    this.initGrdApprover();

    await this.$nextTick();

    this.selectOBS();
  },
  methods: {
    initGrdLine() {
      const vm = this;

      const sheetOptions = {
        Cfg: {
          CanEdit: 3,
        },
        Cols: [
          {
            Name: 'snclNo',
            Header: 'snclNo',
            Visible: false,
          },
          {
            Name: 'tl',
            Header: '결재선명',
            Width: 300,
          },
          {
            Name: 'lineDListDs',
            Header: '결재대상자',
            RelWidth: 1,
          },
          {
            Name: 'canDeleteYn',
            Header: 'canDeleteYn',
            Visible: false,
          },
        ],
        Events: {
          onAfterClick: function(e) {
            vm.line.snclNo = e.row.snclNo;
            vm.line.snclDs = e.row.snclDs;
            vm.line.vwDs = e.row.vwDs;
            vm.line.pgmCd = e.row.pgmCd;
            vm.line.grpCd = e.row.grpCd;
            vm.line.tl = e.row.tl;
            vm.line.canEdit = e.row.canDeleteYn === 'Y';
            vm.line.canDelete = vm.line.canEdit;
            vm.selectApproverList();
          },
          onDataLoad: function(e) {
            if (vm.line.snclNo === undefined || vm.line.snclNo === null || vm.line.snclNo === 0) {
              const firstRow = e.sheet.getFirstRow();

              if (firstRow !== undefined && firstRow !== null) {
                vm.line.snclNo = firstRow.snclNo;
                vm.line.snclDs = firstRow.snclDs;
                vm.line.vwDs = firstRow.vwDs;
                vm.line.pgmCd = firstRow.pgmCd;
                vm.line.grpCd = firstRow.grpCd;
                vm.line.tl = firstRow.tl;
                vm.line.canEdit = firstRow.canDeleteYn === 'Y';
                vm.line.canDelete = vm.line.canEdit;
                vm.selectApproverList();
              }
            }
          },
          onSearchFinish: function(e) {
            if (vm.line.snclNo !== undefined && vm.line.snclNo !== null && vm.line.snclNo !== 0) {
              const rows = e.sheet.getDataRows();

              for (let i = 0; i < rows.length; i++) {
                if (rows[i].snclNo === vm.line.snclNo) {
                  e.sheet.focus(rows[i]);
                  break;
                }
              }
            }
            vm.lineLoading = false;
          },
        },
      };

      PMISSheet.createSheet({
        el: this.elId,
        options: sheetOptions,
      }).then(sheet => (this.sheet = sheet));
    },
    initOBS() {
      const vm = this;
      const sheetOptions = {
        Cfg: {
          CanEdit: 3,
        },
        Cols: [
          {
            Name: 'pgmCd',
            Header: 'pgmCd',
            Visible: false,
          },
          {
            Name: 'depNo',
            Header: 'depNo',
            Visible: false,
          },
          {
            Name: 'depNm',
            Header: '부서명',
            Width: 150,
          },
          {
            Name: 'empNo',
            Header: 'empNo',
            Visible: false,
          },
          {
            Name: 'empNm',
            Header: '성명',
            RelWidth: 1,
          },
        ],
        Events: {
          onDblClick: function() {
            vm.btnAddApprover_click();
          },
          onSearchFinish: function() {
            vm.obsLoading = false;
          },
        },
      };

      PMISSheet.createSheet({
        el: this.elId2,
        options: sheetOptions,
      }).then(sheet => (this.sheet2 = sheet));
    },
    initGrdApprover() {
      const vm = this;
      const sheetOptions = {
        // Cfg: {
        //   CanEdit: 3,
        // },
        Cols: [
          {
            Name: 'snclNo',
            Header: 'snclNo',
            Visible: false,
          },
          {
            Name: 'snclSn',
            Header: '결재순번',
            Type: 'Number',
            CanEdit: false,
            Width: 70,
          },
          {
            Name: 'pgmCd',
            Header: 'pgmCd',
            Visible: false,
          },
          {
            Name: 'sncTp',
            Header: '결재유형',
            Type: 'Enum',
            Enum: '|순차결재|결재|합의협조|전결',
            EnumKeys: '|AAPP|LAST|AASS|AAUT',
            CanEdit: true,
            Width: 100,
          },
          {
            Name: 'depNo',
            Header: 'depNo',
            Visible: false,
          },
          {
            Name: 'depNm',
            Header: '부서명',
            CanEdit: false,
            Width: 150,
          },
          {
            Name: 'empNo',
            Header: 'empNo',
            Visible: false,
          },
          {
            Name: 'empNm',
            Header: '결재자명',
            CanEdit: false,
            RelWidth: 1,
          },
        ],
        Events: {
          onAfterChange: function(e) {
            if (e.col === 'sncTp') {
              // 전결
              if (e.val === 'AAUT') {
                let rowIndex = e.sheet.getRowIndex(e.row);

                for (let i = rowIndex - 1; i > 0; i--) {
                  e.sheet.removeRow(e.sheet.getRowByIndex(i));
                }
              }
            }
          },
          onDataLoad: function() {
            vm.setGrdApproverEditMode();
          },
          onDblClick: function() {
            vm.btnDeleteApprover_click();
          },
          onSearchFinish: function() {
            vm.approverLoading = false;
          },
        },
      };

      PMISSheet.createSheet({
        el: this.elId3,
        options: sheetOptions,
      }).then(sheet => (this.sheet3 = sheet));
    },
    btnLineSetFilter_click() {
      const vm = this;
      this.sheet.setFilter(
        'LINE_FILTER',
        function(e) {
          if (vm.lineFilterType === '' || vm.lineFilterType === 'tl') {
            return e.Row.tl.indexOf(vm.lineFilterValue) !== -1;
          } else {
            return e.Row.lineDListDs.indexOf(vm.lineFilterValue) !== -1;
          }
        },
        1
      );
    },
    btnOBSSetFilter_click() {
      const vm = this;
      this.sheet2.setFilter(
        'OBS_FILTER',
        function(e) {
          return (
            (e.Row.depNm !== null && e.Row.depNm.indexOf(vm.obsFilterValue) !== -1) ||
            (e.Row.empNm !== null && e.Row.empNm.indexOf(vm.obsFilterValue) !== -1)
          );
        },
        1
      );
    },
    async btnAddApprover_click() {
      if (!this.line.canEdit || !this.canAddApprover) {
        return;
      }

      const obsRow = this.sheet2.getFocusedRow();

      if (obsRow !== undefined && obsRow !== null) {
        const rows = this.sheet3.getDataRows();

        if (rows.length > 0) {
          // 전결 확인
          if (this.isAAUT(rows[0])) {
            await this.$alert("'전결' 결재자 이후에는 결재자를 추가할 수 없습니다.");
            return;
          }

          // 중복 확인
          let dup = false;

          for (let i = 0; i < rows.length; i++) {
            if (rows[i].empNo === obsRow.empNo) {
              dup = true;
              break;
            }
          }

          if (dup) {
            await this.$alert('이미 등록된 결재자입니다.');
            return;
          }
        }

        const data = {
          snclNo: this.line.snclNo,
          snclSn: 0,
          pgmCd: obsRow.pgmCd,
          empNo: obsRow.empNo,
          empNm: obsRow.empNm,
          sncYn: '1',
          sncTp: 'LAST',
          depNo: obsRow.depNo,
          depNm: obsRow.depNm,
        };

        this.sheet3.addRow({next: this.sheet3.getFirstRow(), init: data});
        this.setApproverSeq();
      }
    },
    btnDeleteApprover_click() {
      if (!this.line.canEdit || !this.canDeleteApprover) {
        return;
      }

      const row = this.shee3.getFocusedRow();

      if (row !== undefined && row !== null) {
        this.sheet3.removeRow(row);
        this.setApproverSeq();
      }
    },
    async btnUpApprover_click() {
      if (!this.line.canEdit) {
        return;
      }

      const row = this.sheet3.getFocusedRow();
      const rowIndex = this.sheet3.getRowIndex(row);

      if (rowIndex > 1) {
        const previousRow = this.sheet3.getRowByIndex(rowIndex - 1);

        if (this.isAAUT(previousRow)) {
          await this.$alert("'전결' 결재자 이후로 결재자를 이동할 수 없습니다.");
          return;
        }

        this.sheet3.moveRow(row, this.sheet3.getRowByIndex(rowIndex - 1));
        this.setApproverSeq();
      }
    },
    async btnDownApprover_click() {
      if (!this.line.canEdit) {
        return;
      }

      const row = this.sheet3.getFocusedRow();

      // 전결 확인
      if (this.isAAUT(row)) {
        await this.$alert("'전결' 결재자는 아래로 이동할 수 없습니다.");
        return;
      }

      const rowIndex = this.sheet3.getRowIndex(row);
      const rowCount = this.sheet3.getDataRows().length;

      if (rowIndex < rowCount) {
        this.sheet3.moveRow(row, this.sheet3.getRowByIndex(rowIndex + 2));
        this.setApproverSeq();
      }
    },
    async _search(keepLine) {
      if (!keepLine) {
        this.line.snclNo = 0;
        this.line.vwDs = undefined;
        this.line.pgmCd = this.pgmCd;
        this.line.grpCd = undefined;
        this.line.tl = '';
        this.line.canEdit = false;
        this.line.canDelete = false;
        this.approverList = [];
        this.sheet3.removeAll();
      }

      this.sheet.removeAll();

      this.lineLoading = true;

      const data = {
        snclDs: this.snclDs,
        pgmCd: this.pgmCd,
        rgprId: this.empNo,
      };

      try {
        const response = await axios.post('/approval/line/selectList', data);
        this.sheet.loadSearchData(response.data);
      } catch (ex) {
        this.lineLoading = false;
      }
    },
    _new() {
      this.line.snclNo = 0;
      // this.snclDs = undefined;
      this.line.vwDs = undefined;
      this.line.pgmCd = this.pgmCd;
      this.line.grpCd = undefined;
      this.line.tl = '';
      this.line.canEdit = true;
      this.line.canDelete = true;
      this.approverList = [];

      this.sheet3.removeAll();
      this.setGrdApproverEditMode();
      this.$refs.txtTl.$refs.input.focus();
    },
    async _save() {
      const rows = this.sheet3.getDataRows();

      if (this.line.tl === undefined || this.line.tl === null || this.line.tl.length === 0) {
        await this.$alert('결재선명을 반드시 입력해 주십시오.');
        return;
      }

      if (rows.length === 1 && rows[0].sncTp !== 'AAUT') {
        await this.$alert('결재대상자를 최소한 1인 이상 등록해주십시오.');
        return;
      }

      let sncYnCount = 0;
      rows.forEach(row => {
        if (row.sncYn) {
          sncYnCount++;
        }
      });

      if (sncYnCount === 0) {
        await this.$alert('결재대상자는 최소 1인 이상 체크되어 있어야 합니다.');
        return;
      }

      if (!rows[0].sncYn) {
        await this.$alert('최종 결재대상자는 반드시 결재대상자로 체크되어 있어야 합니다.');
        return;
      }

      this.lineLoading = true;
      this.approverLoading = true;

      const data = {
        ...this.line,
        snclDs: this.snclDs,
        approverList: this.sheet3.getSaveJson(0).data,
        rgprId: this.empNo,
      };

      try {
        const response = await axios.post('/approval/line/save', data);

        this.line.snclNo = response.data.snclNo;

        this.selectApproverList();
        this._search(true);
      } catch (ex) {
        this.lineLoading = false;
        this.approverLoading = false;
        await this.$alert(ex);
      }
    },
    async _delete() {
      let row = this.sheet.getFocusedRow();

      if (row === undefined || row === null) {
        await this.$alert('삭제할 결재선을 선택해 주십시오.');
        return;
      }

      if (row.canDeleteYn === 'N') {
        await this.$alert('사용 중인 결재선은 삭제할 수 없습니다.');
        return;
      }

      this.lineLoading = true;
      this.approverLoading = true;

      const data = {
        snclNo: row.snclNo,
      };

      try {
        await axios.post('/approval/line/delete', data);
        this._search();
      } catch (ex) {
        this.lineLoading = false;
        this.approverLoading = false;
        await this.$alert(ex);
      }
    },
    async selectOBS() {
      this.sheet2.removeAll();

      this.obsLoading = true;

      const data = {
        pgmCd: this.pgmCd,
      };

      try {
        const response = await axios.post('/approval/line/selectOBS', data);
        this.sheet2.loadSearchData(response.data);
      } catch (ex) {
        this.obsLoading = false;
      }
    },
    async selectApproverList() {
      this.sheet3.removeAll();

      this.approverLoading = true;

      const data = {
        snclNo: this.line.snclNo,
      };

      try {
        const response = await axios.post('/approval/line/selectApproverList', data);
        this.sheet3.loadSearchData(response.data);
      } catch (ex) {
        this.approverLoading = false;
      }
    },
    setGrdApproverEditMode() {
      let editMode = this.line.canEdit ? 1 : 0;
      this.sheet3.setAttribute(null, 'sncTp', 'CanEdit', editMode, true);
      this.sheet3.setAttribute(null, 'sncYn', 'CanEdit', editMode, true);
    },
    setApproverSeq() {
      const vm = this;
      const rows = this.sheet3.getDataRows();
      let seq = rows.length;
      rows.forEach(row => {
        vm.sheet3.setValue(row, 'snclSn', seq);
        seq--;
      });
    },
    isAAUT(row) {
      return row.sncTp === 'AAUT';
    },
  },
};
</script>

<style></style>
