export default that => {
  return {
    Cfg: {
      ReqStatusName: 'status',
      HeaderCheck: 1,
    },
    Def: {
      Row: {
        CanFormula: 1,
        CalcOrder: 'chkCanEdit,matCodeButton,qtyCanEdit',
      },
    },
    Cols: [
      {
        Name: 'matCode',
        Header: ['자재코드'],
        Width: 180,
        Type: 'Text',
        Align: 'Center',
        CanEdit: false,
        WidthPad: 40,
        ButtonFormula: fr => (fr.Row.cud == '1' ? 'Html' : ''),
        ButtonText: '<input type="button" class="i_search" />',
        OnClickSide: () => that.onShowModal('GMaterialModal'),
      },
      {
        Name: 'itemName',
        Header: ['품명'],
        RelWidth: 2,
        MinWidth: 140,
        Type: 'Text',
        CanEdit: false,
      },
      {
        Name: 'size',
        Header: ['규격'],
        RelWidth: 2,
        MinWidth: 140,
        Type: 'Text',
        CanEdit: false,
      },
      {
        Name: 'unit',
        Header: ['단위'],
        Width: 120,
        Type: 'Text',
        CanEdit: false,
        Format: $getConstants('UNIT').code,
      },
      {
        Name: 'planQty',
        Header: ['예산수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Visible: !that.isSimpleExec,
      },
      {
        Name: 'contQty',
        Header: ['기입고수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Visible: !that.isSimpleExec,
      },
      {
        Name: 'qty',
        Header: ['입고수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        RelWidth: 1,
        MinWidth: 120,
        CanEditFormula: () => that.monPjtCloseAt == 'N', //마감 && 정산시 수정불가
        OnChange: e => {
          that.sheet.setValue(e.row, 'amt', e.row.qty * e.row.price, 1);
        },
      },
      {Name: 'pgmCd', Visible: false},
      {Name: 'prjCd', Visible: false},
      {Name: 'ioGb', Visible: false},
      {Name: 'ioDate', Visible: false},
      {Name: 'seq', Visible: false},
      {Name: 'cud', Visible: false},
    ],
  };
};
