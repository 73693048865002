<template>
  <pmis-tab-box>
    <template #title>계약목록</template>
    <pmis-content-box>
      <template #header-left>
        <i-header-multi-box>
          <div>
            <label>검색구분</label>
            <iui-select defaultCd="S" :items="searchItems" :value.sync="searchOptions.searchGb" />
            <iui-text type="search" width="280px" :value.sync="searchOptions.searchText" @enter="onSearch" />
          </div>
          <div>
            <label>계약(발주)일</label>
            <iui-datepicker :value.sync="searchOptions.searchFrDt" @change="onSearch" :group="searchDateGroup.seq1" />
            <label>~</label>
            <iui-datepicker :value.sync="searchOptions.searchToDt" @change="onSearch" :group="searchDateGroup.seq2" />
          </div>
        </i-header-multi-box>
      </template>

      <template #header-right>
        <iui-button value="검색" @click="onSearch" />
      </template>

      <ib-sheet
        :uid="_uid"
        :options="sheetOpt"
        :loadSearchData="unitContractList"
        @loadSheet="sheet = $event"
        :events="{
          onRenderFirstFinish: () => onSearch(),
          onSearchFinish: sheet_onSearchFinish,
          onClick: sheet_onClick,
        }"
      />
    </pmis-content-box>

    <iui-modal name="poInfoModal" title="계약정보" size-type="size6" @closed="propsPoNo = ''">
      <PoInfoPopup :poNo="propsPoNo" />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/unitContractList.js';

import PoInfoPopup from './popup/poInfo/PoInfoPopup.vue';
export default {
  components: {PoInfoPopup},
  data() {
    return {
      sheetOpt: sheetOpt(this),

      searchItems: [
        {text: '계약명', value: 'TTL'},
        {text: '협력사명', value: 'CUS_NM'},
        {text: '현장명', value: 'SPOT_DEPTNM'},
      ],

      searchOptions: {
        searchGb: '',
        searchText: '',
        searchFrDt: '',
        searchToDt: '',
      },

      searchDateGroup: {
        seq1: {grpNm: 'searchDate', seq: 1},
        seq2: {grpNm: 'searchDate', seq: 2},
      },

      focusKey: '',
      propsPoNo: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, ['unitContractList', 'poInfo']);
    $mapActions(this, ['searchUnitContractList', 'searchUnitContractInfo', 'searchOrderList']);
  },
  created() {
    this.addFuncSearch(this.onClickCommonSearch);
    this.addEvent({name: 'UnitContractList_onSearch', func: this.onSearch});
  },
  methods: {
    onClickCommonSearch() {
      for (const key in this.searchOptions) {
        this.searchOptions[key] = '';
      }
      this.onSearch();
    },
    onSearch(focusKey = '') {
      this.focusKey = focusKey;
      this.searchUnitContractList({orgCd: this.pgmCd, ...this.searchOptions});
    },
    async setRowData(row) {
      if (row) {
        await this.searchUnitContractInfo({poNo: row.poNo, contSeq: row.contSeq});
        this.canNew = !this.poInfo.isOrderComplete;
        this.callEvent({name: 'OrderList_onSearch'});
      }
    },
    sheet_onSearchFinish(e) {
      if (e.sheet.getTotalRowCount()) {
        const focusRow = e.sheet.getDataRows().find(row => row.poNo === this.focusKey);
        if (!focusRow) {
          this.setRowData(e.sheet.getFirstRow());
        } else {
          e.sheet.focus(focusRow);
          this.setRowData(focusRow);
        }
      }
      this.focusKey = '';
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data') {
        this.setRowData(e.row);

        if (e.col === 'poNo') {
          this.propsPoNo = e.row.poNo;
          this.$modal.show('poInfoModal');
        }
      }
    },
  },
};
</script>

<style></style>
