export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Def: {
      Row: {NoColor: 2, CanFocus: 0, CanEdit: 0},
    },
    LeftCols: [
      {
        Header: ['공종명', '공종명'],
        Name: 'srvNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 140,
      },
      {
        Header: ['내역명', '내역명'],
        Name: 'itemNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 160,
      },
      {
        Header: ['규격', '규격'],
        Name: 'sizeNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 160,
      },
      {
        Header: ['단위', '단위'],
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        Width: 50,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: ['수량', '수량'],
        Name: 'qty',
        Type: 'Text',
        Width: 70,
        Extend: preset.quantity,
      },
    ],
    Cols: [
      {
        Header: ['재료비', '단가'],
        Name: 'matUprc',
        Type: 'Text',
        Width: 90,
        Extend: preset.unitAmount,
      },
      {
        Header: ['재료비', '금액'],
        Name: 'matAmt',
        Type: 'Text',
        Width: 100,
        Extend: preset.amount,
        Formula: fr => fr.Row['qty'] * fr.Row['matUprc'],
      },
      {
        Header: ['노무비', '단가'],
        Name: 'labUprc',
        Type: 'Text',
        Width: 90,
        Extend: preset.unitAmount,
      },
      {
        Header: ['노무비', '금액'],
        Name: 'labAmt',
        Type: 'Text',
        Width: 100,
        Extend: preset.amount,
        Formula: fr => fr.Row['qty'] * fr.Row['labUprc'],
      },
      {
        Header: ['장비비', '단가'],
        Name: 'equipUprc',
        Type: 'Text',
        Width: 90,
        Extend: preset.unitAmount,
      },
      {
        Header: ['장비비', '금액'],
        Name: 'equipAmt',
        Type: 'Text',
        Width: 100,
        Extend: preset.amount,
        Formula: fr => fr.Row['qty'] * fr.Row['equipUprc'],
      },
      {
        Header: ['경비', '단가'],
        Name: 'costUprc',
        Type: 'Text',
        Width: 90,
        Extend: preset.unitAmount,
      },
      {
        Header: ['경비', '금액'],
        Name: 'costAmt',
        Type: 'Text',
        Width: 100,
        Extend: preset.amount,
        Formula: fr => fr.Row['qty'] * fr.Row['costUprc'],
      },
      {
        Header: ['합계', '단가'],
        Name: 'sumUprc',
        Type: 'Text',
        Width: 90,
        Extend: preset.unitAmount,
        Formula: fr => fr.Row['matUprc'] + fr.Row['labUprc'] + fr.Row['equipUprc'] + fr.Row['costUprc'],
      },
      {
        Header: ['합계', '금액'],
        Name: 'sumAmt',
        Type: 'Text',
        Width: 120,
        Extend: preset.amount,
        Formula: fr => fr.Row['matAmt'] + fr.Row['labAmt'] + fr.Row['equipAmt'] + fr.Row['costAmt'],
      },
      {
        Header: ['비고', '비고'],
        Name: 'srvRmrk',
        Type: 'Text',
        MinWidth: 120,
        RelWidth: 1,
      },
      {Name: 'wcode', Visible: false},
      {Name: 'headCode', Visible: false},
      {Name: 'midCode', Visible: false},
      {Name: 'itemSeq', Visible: false},
      {Name: 'itemCode', Visible: false},
      {Name: 'costType', Visible: false},
      {Name: 'qtyBefore', Visible: false, NoChanged: 1},
      {Name: 'totQty', Visible: false},
    ],
  };
};
