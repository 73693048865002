const state = {
  sheetData: {
    pgmCd: '', // 회사코드
    prjCd: '', // 현장코드
    level: 0, // 트리레벨
    code: '', // 코드
    name: '', // 코드명
    upCode: '', // 상위코드
    upName: '', // 상위코드명
    wcode: '', // 공사구분
    wname: '', // 공사구분명
    lgCode: '', // 대공종코드
    lgName: '', // 대공종명
    mgCode: '', // 중공종코드
    mgName: '', // 중공종명
    cud: 0,
  },
  focusKeyCode: '',
  status: {
    isNew: false,
    isEditableW: false, // 공사코드 편집가능 여부
    isEditableLG: false, // 대공종 편집가능 여부
    isEditableMG: false, // 중공종 편집가능 여부
  },
};

const getters = {
  sheetData: state => state.sheetData,
  focusKeyCode: state => state.focusKeyCode,
  status: state => state.status,
};

const actions = {};

const mutations = {
  setSheetData(state, payload) {
    for (let key in state.sheetData) {
      if (payload && payload.hasOwnProperty(key)) {
        state.sheetData[key] = payload[key];
      }
    }
  },
  initSheetData(state) {
    for (let key in state.sheetData) {
      if (key == 'level' || key == 'cud') {
        state.sheetData[key] = 0;
      } else {
        state.sheetData[key] = '';
      }
    }
  },
  initFocusKeyCode(state) {
    state.focusKeyCode = '';
  },
  setFocusKeyCode(state, payload) {
    state.focusKeyCode = payload;
  },
  setStatus(state, payload) {
    for (let key in payload) {
      state.status[key] = payload[key];
    }
  },
  initStatus(state) {
    for (let key in state.status) {
      state.status[key] = false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
