export default that => {
  return {
    Cfg: {
      CanEdit: false,
    },
    Def: {
      Row: {
        CanFocus: false,
        CanFormula: 1,
        CalcOrder: 'flViewType,flViewButton,flViewButtonText',
      },
    },
    Cols: [
      {
        Header: ['집행일자', '집행일자'],
        Name: 'workDt',
        Extend: that.$ibsheetHelper.preset.date,
        Width: 100,
        Align: 'center',
      },
      {
        Header: ['순번', '순번'],
        Name: 'seq',
        Type: 'Text',
        Align: 'center',
        Width: 60,
      },
      {
        Header: ['거래\n구분', '거래\n구분'],
        Name: 'accountGbNm',
        Type: 'Text',
        Align: 'center',
        Width: 120,
      },
      {
        Header: ['거래처', '거래처'],
        Name: 'custName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 140,
      },
      {
        Header: ['공급가', '공급가'],
        Name: 'amt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        RelWidth: 1,
        MinWidth: 100,
      },
      {
        Header: ['부가세', '부가세'],
        Name: 'vatAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        RelWidth: 1,
        MinWidth: 100,
      },
      {
        Header: ['합계\n금액', '합계\n금액'],
        Name: 'sumAmt',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        RelWidth: 1,
        MinWidth: 100,
      },
      {
        Header: ['집행\n외주', '집행\n외주'],
        Name: 'subCodeName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 140,
      },
      {
        Header: ['실행내역', '공종명'],
        Type: 'Text',
        Align: 'center',
        Name: 'mgName',
        RelWidth: 1,
        MinWidth: 150,
        ColMerge: false,
      },
      {
        Header: ['실행내역', '내역코드'],
        Type: 'Text',
        Align: 'center',
        Name: 'itemSeq',
        Width: 80,
        ColMerge: false,
      },
      {
        Header: ['실행내역', '내역명'],
        Type: 'Text',
        Align: 'center',
        Name: 'itemName',
        RelWidth: 1,
        MinWidth: 150,
        ColMerge: false,
      },
      {
        Header: ['실행내역', '규격'],
        Name: 'ssize',
        Type: 'Text',
        Align: 'left',
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: ['실행내역', '단위'],
        Name: 'unitNm',
        Type: 'Text',
        Align: 'center',
        Width: 50,
        CanEdit: 0,
      },
      {
        Header: ['실행내역', '수량'],
        Name: 'moqty',
        Extend: that.$ibsheetHelper.preset.quantityFormula,
        Width: 100,
        CanEdit: 0,
      },
      {
        Header: ['실행내역', '단가'],
        Name: 'price',
        Extend: that.$ibsheetHelper.preset.unitAmountFormula,
        Width: 100,
        CanEdit: 0,
      },
      {
        Header: ['실행내역', '금액'],
        Type: 'Text',
        Align: 'center',
        Name: 'amount',
        Extend: that.$ibsheetHelper.preset.amountFormula,
        Width: 100,
        ColMerge: false,
      },
      {
        Header: ['증빙', '증빙'],
        Name: 'flView',
        Type: 'Button',
        Align: 'center',
        Width: 50,
        CanEdit: 0,
        ButtonFormula: fr => (fr.Row.flNo ? 'Html' : ''),
        ButtonTextFormula: fr => (fr.Row.flNo ? '<input type="button" class="i_search2" />' : ''),
      },
      {Name: 'flNo', Visible: 0},
    ],
  };
};
