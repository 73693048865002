<template>
  <pmis-tab-box>
    <template #title>{{ sncSsTitle }}</template>
    <template #title-left>
      <iui-button
        :value="`${offline ? '전자결재' : '오프라인'}`"
        @click="toggleOffline"
        v-if="param.canToggle && (isWait || isReceiptStandby)"
      />
      <iui-button
        value="결재선지정"
        @click="showLineSelect"
        v-if="!offline && (isWait || isReDraft || isReceiptStandby)"
      />
      <iui-button value="결재현황" @click="showApprovalStatus" v-if="!offline && !isWait && !isReceiptStandby" />
      <iui-button value="수신지정" @click="showReceiptSelect" v-if="hasProcess && isReceipt" />
      <iui-button
        value="공람지정"
        @click="showPublic"
        v-if="!offline && isInformat && (isWait || isReDraft || isReceiptStandby)"
      />
      <!-- 첨부파일 -->
      <pmis-file-list
        v-if="!offline"
        :file-number.sync="flNo"
        :files.sync="attachDocList"
        id="draftFile"
        :modal="true"
        :saveAuth="isWait || isReceiptStandby || !!param.fileSaveAuth"
        :clear.sync="fileClear"
        :allowAttachDoc="true"
        :copy-files="param.fileList ? param.fileList : []"
      />
    </template>
    <template #title-right>
      <iui-button value="저장" @click="onSave" v-if="offline && isWait" />
      <iui-button value="회수" @click="onAppRollback" v-if="hasProcess && showBtnRollback" />
      <iui-button value="반려" @click="onReject" v-if="hasProcess && showBtnReject" />
      <iui-button value="결재승인" @click="onApproval" v-if="hasProcess && showBtnApproval" />
      <iui-button value="재기안" @click="onRedraft" v-if="hasProcess && showBtnReDraft && isInformat" />
      <iui-button value="삭제" @click="onAppDelete" v-if="hasProcess && showBtnDel" />

      <iui-button value="결재상신" @click="onDraft" v-if="hasProcess && showBtnDraft" />
      <iui-button value="임시저장" @click="onTempSave" v-if="hasProcess && showBtnTemp" />

      <iui-button value="발송" @click="onDocSend" v-if="hasProcess && param.appSendStandby" />

      <iui-button value="접수" @click="onReceipt" v-if="!param.offline && isReceiptStandby" />
      <iui-button value="배부" @click="onDistribute" v-if="!param.offline && isReceiptStandby" />
      <iui-button value="반송" @click="onSendReturn" v-if="!param.offline && isReceiptStandby" />
    </template>
    <iui-container-new type="table" theme="bullet" header-width="110px" v-if="!offline">
      <colgroup>
        <col width="100px" />
        <col />
        <col width="100px" />
        <col />
      </colgroup>
      <i-row>
        <i-col-header>발송구분</i-col-header>
        <i-col>
          <iui-radio-group
            :p-code="$getConstants('DOC_CS_DS').code"
            :exclude-items="excludeItems"
            :value="docCsDs"
            @change="onChangeDocCsDs"
            :enable="isWait || isReDraft"
          />
        </i-col>
        <i-col-header>문서번호</i-col-header>
        <i-col>
          <iui-text name="docNo1" width="150px" :value="docNo1" max-length="20" :readonly="true" />
          -&nbsp;
          <iui-text name="docNo2" :value="docNo2" max-length="20" width="40px" :readonly="true" :enable="!!docNo2" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>문서제목</i-col-header>
        <i-col>
          <iui-text
            name="tl"
            :value="form.tl"
            :enable="isWait"
            max-length="100"
            maxWidth="400px"
            @blur="setFormValue"
            @change="form.tl = $event.target.value"
            :errorMessage="{title: '문서제목', message: '문서제목을 입력하세요.'}"
            required
          />
        </i-col>
        <i-col-header>기안일자</i-col-header>
        <i-col>
          <iui-text name="draftDate" width="80px" :value="draftDate" :readonly="true" />
        </i-col>
      </i-row>
    </iui-container-new>
    <div style="width:100%; height: 100%" id="OZViewerDiv" v-if="!offline">
      <div id="OZViewer" />
    </div>

    <iui-container-new type="table" theme="bullet" v-if="offline">
      <colgroup>
        <col width="90px" />
        <col />
        <col width="90px" />
        <col width="200px" />
      </colgroup>
      <i-row>
        <i-col-header required>
          문서번호
        </i-col-header>
        <i-col>
          <iui-text
            :value="form.docNo1"
            required
            width="250px"
            :enable="isWait"
            @change="onChangeFormData('docNo1', $event.target.value)"
          />
        </i-col>
        <i-col-header required>
          시행일자
        </i-col-header>
        <i-col>
          <iui-datepicker :value="form.enfcDt" required :enable="isWait" @change="v => onChangeFormData('enfcDt', v)" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>
          제목
        </i-col-header>
        <i-col colspan="3">
          <iui-text :value="form.tl" :enable="isWait" required @change="onChangeFormData('tl', $event.target.value)" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>
          발신처명
        </i-col-header>
        <i-col colspan="3">
          <iui-text
            :value="form.sndNmn"
            required
            :enable="isWait"
            @change="onChangeFormData('sndNmn', $event.target.value)"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>
          기안부서
        </i-col-header>
        <i-col>
          <iui-text
            :value="form.draftDepNm"
            required
            :enable="isWait"
            width="250px"
            @change="onChangeFormData('draftDepNm', $event.target.value)"
          />
        </i-col>
        <i-col-header required>
          기안자
        </i-col-header>
        <i-col>
          <iui-text
            :value="form.draftNm"
            required
            :enable="isWait"
            @change="onChangeFormData('draftNm', $event.target.value)"
          />
        </i-col>
      </i-row>
      <i-row height="300px">
        <i-col colspan="4">
          <pmis-content-box>
            <pmis-file-list
              id="offlineFile"
              :fileNumber="form.flNo"
              title="첨부파일"
              theme="bullet"
              :saveAuth="isWait"
              required
            />
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row height="45px">
        <i-col-header required>수신일자</i-col-header>
        <i-col>
          <iui-datepicker
            :value="form.rcvDttm"
            required
            :enable="isWait"
            @change="v => onChangeFormData('rcvDttm', v)"
          />
        </i-col>
        <i-col-header required>시행종류</i-col-header>
        <i-col>
          <iui-select
            :p-code="$getConstants('DOC_CS_DS').code"
            :enable="isWait"
            :value="form.docCsDs"
            :exclude-items="`${$getConstants('DOC_CS_DS_IN_DEP').code}`"
            @change="v => onChangeFormData('docCsDs', v)"
          />
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal
      name="LineSelectModal"
      width="1000px"
      title="결재선지정"
      :btns="[
        {name: '지정', callback: setLineSelect},
        {name: '해제', callback: unsetLineSelect},
      ]"
    >
      <LineSelect
        :line.sync="lineObject"
        :set.sync="setLine"
        :appReceiptStandby="isReceiptStandby"
        :isInformat="isInformat"
        :unset.sync="unsetLine"
        @set-complete="confirmLine"
      />
    </iui-modal>

    <iui-modal
      name="ReceiverSelectModal"
      width="1000px"
      title="수신지정"
      :btns="[
        {name: '지정', callback: setReceiptSelect},
        {name: '해제', callback: unsetReceiptSelect},
      ]"
    >
      <ReceiptSelect
        :receipt.sync="receiptObject"
        :docCsDs="docCsDs"
        :set.sync="setReceipt"
        :unset.sync="unSetReceipt"
        :sndNmnList="sndNmnList"
        :sndNmn.sync="sndNmn"
        :djFlNo.sync="djFlNo"
        :isDistribute="isDistribute"
        @set-complete="confirmReceipt"
      />
    </iui-modal>

    <iui-modal
      name="PublicSelectModal"
      width="1000px"
      title="공람지정"
      :btns="[
        {name: '지정', callback: setPublicSelect},
        {name: '해제', callback: unsetPublicSelect},
      ]"
    >
      <PublicSelect
        :publicList.sync="publicList"
        :set.sync="setPublic"
        :unset.sync="unsetPublic"
        :exclude="publicExclude"
        @set-complete="confirmPublic"
      />
    </iui-modal>

    <iui-modal
      name="appRequest"
      sizeType="size1"
      :title="appRequestTitle"
      :btns="[{name: '확인', callback: appRequestConfirm}]"
    >
      <DocumentAlarm :title="appRequestTitle" :subTitle="appRequestSubTitle" :alert="appRequestAlert" />
    </iui-modal>

    <iui-modal name="appReject" sizeType="size1" :title="'문서반려'" :btns="[{name: '확인', callback: rejectConfirm}]">
      <RejectOpinion />
    </iui-modal>
    <iui-modal
      name="sendReturn"
      sizeType="size1"
      :title="'반송요청'"
      :btns="[{name: '확인', callback: sendReturnConfirm}]"
    >
      <RecorderReturn />
    </iui-modal>
    <iui-modal name="DocumentApprovalStatusPop" :title="'결재현황'" sizeType="size4">
      <ApprovalStatusPop :sncNo="form.sncNo" />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import LineSelect from '@/view/approval/line/components/LineSelect.vue';
import ReceiptSelect from '@/view/approval/line/components/ReceiptSelect.vue';
import PublicSelect from '@/view/approval/line/components/PublicSelect.vue';
import DocumentAlarm from './DocumentAlarm.vue';
import RejectOpinion from './RejectOpinion.vue';
import RecorderReturn from './RecorderReturn.vue';
import ApprovalStatusPop from '@/view/approval/draft/popup/ApprovalStatusPop.vue';
import {initLine, setSign} from '../lineUtil.js';

import {
  deleteAppDoc,
  getDocNo2,
  saveAppDocSend,
  saveApproval,
  saveDraft,
  saveOffline,
  savePublicConfirm,
  saveReject,
  saveRollback,
  saveTemp,
  selectAppDocInfo,
  selectAppDocInitInfo,
} from '../../api/appDoc.js';
import {saveDistribute, saveReturnSend} from '../../line/api/receipt.js';
import APPROVAL from '../../js/approvalConstants.js';
import FOM_NO from '@/constants/fomNoConstants.js';
import cloneDeep from 'lodash/cloneDeep';
import IuiButton from '@/components/Iui/components/IuiButton/IuiButton.vue';
import moment from 'moment';

export default {
  components: {
    IuiButton,
    LineSelect,
    ReceiptSelect,
    PublicSelect,
    DocumentAlarm,
    RejectOpinion,
    RecorderReturn,
    ApprovalStatusPop,
  },

  props: {
    param: {
      type: Object,
      default: () => {},
    },
    parentId: {
      type: [String, Number],
    },
    excuteExternal: {
      type: Object,
      default: () => {},
    },
    isModal: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Boolean,
      default: false,
    },
    approvalStatus: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      fileClear: false,
      sncSsTitle: '',
      refNo: undefined,
      docNo1: '',
      docNo2: '',
      docCsDs: APPROVAL.DOC_CS_DS_IN_DEP,
      nowSncSs: '',
      nowSncKd: '',
      tl: '',
      draftDate: dateFormat($_getCurrentDate()),
      sndNmn: '',
      ozSndNmn: '',
      djFlNo: '',
      sndNmnList: [],
      signList: [],
      collaboratorList: [],
      lineObject: {},
      receiptObject: {},
      receiptCnt: 0,
      publicList: [],
      setLine: false,
      unsetLine: false,
      setReceipt: false,
      unSetReceipt: false,
      setPublic: false,
      unsetPublic: false,
      appRequestTitle: '',
      appRequestSubTitle: '',
      appRequestAlert: '',
      receiptName: '',
      receiptList: [],
      form: {},
      showBtnRollback: false,
      showBtnReject: false,
      showBtnApproval: false,
      showBtnDel: false,
      showBtnReDraft: false,
      showBtnDraft: false,
      showBtnTemp: false,
      alarmFlag: 0,
      empInfo: undefined,
      isTemp: false,
      isReDraft: false,
      publicExclude: [],
      sncType1: {ADRT: '기안', AAPP: '검토', LAST: '결재', AAUT: '전결'},
      isOfficialSeal: true,
      isDistribute: false,
      offline: this.param.offline,
      ozEnableTaskCds: ['MAT_RFQ_CHOICE', 'SUB_RFQ_CHOICE'],
      signedLine: {},
      signedColLine: {},
      lineMax: 0,
      colLineMax: 0,
      attachDocList: [], //문서첨부 리스트
      grpNo: 0,
    };
  },

  computed: {
    isInformat() {
      // 비정형 여부
      return [this.param.fomNo, this.form.fomNo].indexOf(FOM_NO.DRAFT) !== -1 || !!this.offline;
    },
    excludeItems() {
      return !this.isInformat
        ? [$getConstants('DOC_CS_DS_IN_CMP').code, $getConstants('DOC_CS_DS_IN_EXTERNAL').code]
        : [];
    },
    isReceipt() {
      return this.docCsDs !== APPROVAL.DOC_CS_DS_IN_DEP && this.isInformat && (this.isWait || this.isReDraft);
    },
    isReceiptStandby() {
      return this.param.appReceiptStandby;
    },
    isDraft() {
      return !this.param.sncNo;
    },
    isWait() {
      return this.form.nowSncSs === APPROVAL.SNC_SS_WAIT;
    },
    hasProcess() {
      return !this.offline || (this.offline && !this.param.withoutLine);
    },
    currentSncSqn() {
      return this.form.approver?.currentSncSqn;
    },
    lastSncSqn() {
      return this.form.approver?.lastSncSqn;
    },
    sncTp() {
      return this.form.approver?.sncTp;
    },
    sgntFlNo() {
      return this.form.approver?.sgntFlNo ?? 0;
    },
    finalApproval() {
      return (
        !this.isWait &&
        this.userInfo.empNo === this.approver &&
        (this.currentSncSqn === this.lastSncSqn || this.sncTp === APPROVAL.SNC_TP_DECIDE)
      );
    },
    approver() {
      return this.form.approver?.empNo;
    },
    flNo: {
      set(no) {
        this.form.flNo = no;
      },
      get() {
        return this.form.flNo ?? 0;
      },
    },
  },
  watch: {
    search(value) {
      if (value) {
        this.onSearch();
      }
    },
  },
  created() {
    this._id = 'Document';
    window.addEventListener('resize', this.resize);
    this.addEvent([
      {name: 'onDraft', func: this.onDraft},
      {name: 'onTempSave', func: this.onTempSave},
    ]);
  },
  mounted() {
    if (this.isModal) {
      this.resize();
    }
  },
  methods: {
    async onSearch() {
      const appDocInfo = this.isDraft
        ? (await selectAppDocInitInfo(this.param)).data
        : (await selectAppDocInfo(this.param)).data;
      this.setFormData(appDocInfo);
      this.$emit('update:search', false);
    },
    async toggleOffline() {
      this.offline = !this.offline;
      await this.$nextTick();
      if (!this.offline) {
        this.resize();
        this.onSearch();
      } else {
        this.form.docNo1 = this.docNo1;
        this.form.rcvDttm = moment().format('YYYY-MM-DD');
        this.form.docCsDs = APPROVAL.DOC_CS_DS_IN_CMP;
      }
    },
    resize() {
      if (this.offline) {
        return;
      }
      const popupContent = document.getElementsByClassName('popup_contents')[0];
      if (!popupContent) {
        return;
      }
      let mainHeight = popupContent.offsetHeight;
      let tabInfo = popupContent.getElementsByClassName('title-box')[0].offsetHeight;
      let infoHeight = popupContent.getElementsByClassName('iui-container')[0].offsetHeight;

      document.getElementById('OZViewerDiv').style.height = mainHeight - tabInfo - infoHeight - 125 + 'px';
    },
    onChangeDocCsDs(e) {
      this.docCsDs = e.target.value;
      this.receiptObject.obsList = [];
      this.setDocNo1();
      this.initOzSndNmn();
      this.setReceiptName();
      this.setOzReceiptName();
    },
    setInitForReDraft(param) {
      this.commonParam = {};
      this.showBtnRollback = false;
      this.showBtnReject = false;
      this.showBtnApproval = false;
      this.showBtnDel = false;
      this.showBtnReDraft = false;
      this.showBtnDraft = true;
      this.showBtnTemp = false;
      this.isReDraft = true;
      this.form.sncSs = this.form.nowSncSs;
      this.setFormLineNm(true);

      OZViewer.Script('enable_input_all');
      if (!param) param = this.param;
    },
    setFormData(data) {
      this.form = data;
      this.offline = data.offline;
      if (this.isDraft) {
        this.commonParam = {};
        this.form.fbsNo = this.param.fbsNo;
        this.form.fomNo = this.param.fomNo;
        this.form.tl = this.param.tl ?? this.form.tl;
        this.flNo = this.param.flNo ?? 0;
        this.refNo = this.param.refNo ?? undefined;
        if (this.flNo === 0) {
          this.clearFile();
        }
        this.sndNmn = '';
        this.form.ozSndNmn = '';
      }

      this.sncSsTitle = this.form.sncSsTitle;
      this.docCsDs = this.form.docCsDs ?? APPROVAL.DOC_CS_DS_IN_DEP;

      // 발신처명의리스트 설정
      this.setSndNmList();
      // 문서번호1 설정
      this.setDocNo1();
      // 문서번호2 설정
      this.setDocNo2();
      // 결재선 설정
      this.setAppLine();
      // 수신처설정
      this.setReceiptList();
      // 공람자설정
      this.setPublicList();

      if (!this.offline) {
        this.ozInit();
      }

      // 버튼 설정
      this.setButton();
      this.$emit('update:approval-status', this.sncSsTitle);
      if (this.param.appPublicState && !this.param.rcvDttm) {
        this.onPublicConfirm();
      }
    },
    setButton() {
      if (this.isWait) {
        this.showBtnDraft = true; // 결재상신
        this.showBtnTemp = true; // 임시저장
      }

      if (this.form.nowSncSs === APPROVAL.SNC_SS_APPROVAL) {
        this.showBtnRollback = this.form.empNo === this.userInfo.empNo;
        this.showBtnApproval = this.approver === this.userInfo.empNo;
        this.showBtnReject =
          this.approver === this.userInfo.empNo && this.form.docAcpDs !== APPROVAL.DOC_ACP_DS_RECEIPT;
      }
      if (
        [APPROVAL.SNC_SS_REJECT, APPROVAL.SNC_SS_ROLLBACK, APPROVAL.SNC_SS_RETURN_SEND].indexOf(this.form.nowSncSs) !==
        -1
      ) {
        this.showBtnDel = this.form.empNo === this.userInfo.empNo; // 삭제
        this.showBtnReDraft = this.form.empNo === this.userInfo.empNo; // 재기안
      }
    },
    setAppLine() {
      this.lineObject.snclNo = this.form.appLine?.snclNo ?? 0;
      this.lineObject.snclDs = this.form.appLine?.snclDs ?? APPROVAL.SNCL_DS_FIX;
      this.lineObject.lineList = this.form.appLine?.appLineDList ?? [];
    },
    setReceiptList() {
      this.receiptObject.inoDs = this.docCsDs === APPROVAL.DOC_CS_DS_IN_CMP ? APPROVAL.INO_DS_IN : APPROVAL.INO_DS_OUT;
      this.receiptObject.receiptList = this.form.receiptList ?? [];
      this.setReceiptName();
    },
    setPublicList() {
      this.publicList = this.form.publicList;
    },
    ozInit() {
      // let ozServer = 'http://rd.apterp.co.kr/oz80/server'; //대상 오즈서버
      let ozSap = ';OZ;'; //뷰어패러미터 구분자
      // this.ozParamStr = '';

      window.SetOZParamters_OZViewer = () => {
        //뷰어패러미터
        this.setOzParam();
        this.setInputJson();

        let ozParamA = this.ozParamStr.split(ozSap);

        let oz;
        oz = document.getElementById('OZViewer');

        for (let key in ozParamA) {
          let paramStr = ozParamA[key];
          if (paramStr.indexOf('=') > -1) {
            let paramK = paramStr.substring(0, paramStr.indexOf('='));
            let paramV = paramStr.substring(paramStr.indexOf('=') + 1);
            oz.sendToActionScript(paramK, paramV);
          }
        }
        return true;
      };

      window.OZProgressCommand_OZViewer = (step, state) => {
        if (step == 4 && state == 2) {
          // 보고서바인딩이 완료된 시점
          if (this.form.nowSncSs !== APPROVAL.SNC_SS_WAIT) {
            // 재기안시 결재선 초기화를 위해 Rebind후 disable처리를 막음.
            if (this.isReDraft) {
              return;
            }
            // 모든 보고서의 모든 입력 컴포넌트를 비활성화 상태로 변경(e-form에서만 지원됨)
            // => '낙찰사선정품의' 결재를 제외한 경우만 비활성화
            if (this.finalApproval && this.ozEnableTaskCds.includes(this.form.taskCd)) {
              if (this.form.nowSncSs === APPROVAL.SNC_SS_COMPLETE) {
                OZViewer.Script('disable_input_all');
              }
            } else {
              OZViewer.Script('disable_input_all');
            }
          }
        }
      };
      if (OZViewer && OZViewer.Script) {
        OZViewer.Script('closeall');
        document.querySelector('#OZViewer').innerHTML = '';
      }
      start_ozjs('OZViewer', 'https://rd.apterp.co.kr/oz80/ozhviewer/');
    },
    setFormValue() {
      this.setOzParam();
      OZViewer.ReBind(0, 'data', this.ozParamStr, ';OZ;');
    },
    initOzTextBox() {
      if (this.form.content) {
        this.content = this.form.content instanceof Object ? this.form.content : JSON.parse(this.form.content);
        for (let [key, value] of Object.entries(this.content)) {
          if (key === 'receiptName') {
            this.receiptName = value;
          }
          if (key === 'receiptStr') {
            this.form.receiptList = this.receiptList = value.split(',');
          }
          if (key === 'receiptCnt') {
            this.receiptCnt = value;
          }
          if (key === 'receiptObject') {
            this.receiptObject.receiptList = value;
          }
        }
      }
    },
    setOzParam() {
      this.ozParamStr = '';
      let ozSap = ';OZ;'; //뷰어패러미터 구분자
      let ozServer = 'https://rd.apterp.co.kr/oz80/server'; //대상 오즈서버
      let pcount = 0;

      if (this.isOzParamInit()) {
        this.initOzTextBox();

        let line;
        let collaboline;
        if (this.commonParam?.line) {
          line = this.commonParam.line;
        }
        if (this.commonParam?.collaboline) {
          collaboline = this.commonParam.collaboline;
        }

        this.commonParam = {
          slgNm: this.form.appDocCompanyInfo.slogan || '',
          postNo: this.form.appDocCompanyInfo.postNo || '',
          adr: this.form.appDocCompanyInfo.adr || '',
          rpsvPhone: this.form.appDocCompanyInfo.rpsvPhone || '',
          rpsvFaxno: this.userInfo.rpsvFaxno || '',
          homepage: this.form.appDocCompanyInfo.homepage || '',
          email: this.userInfo.email || '',
          tl: this.form.tl,
          sndNmn: this.form.sndNmn,
          ozSndNmn: this.form.ozSndNmn,
          receiptName: this.receiptName,
          receiptStr: this.receiptList,
          receiptCnt: this.receiptCnt,
          title: this.userInfo.frmNm,
        };

        if (line) {
          this.commonParam.line = line;
        }

        if (collaboline) {
          this.commonParam.collaboline = collaboline;
        }

        this.setFormLineNm(false);

        if (this.form.appDocCompanyInfo.lgFlNo) {
          this.commonParam['logo'] = this.getImageUrl(this.form.appDocCompanyInfo.lgFlNo);
        }
        if (this.form.appDocCompanyInfo.smFlNo) {
          this.commonParam['symbol'] = this.getImageUrl(this.form.appDocCompanyInfo.smFlNo);
        }

        let commonkeys = Object.keys(this.commonParam);
        for (let i in commonkeys) {
          this.ozParamStr += `${ozSap}connection.args${Number(i) + 1}=${commonkeys[i]}=${
            this.commonParam[commonkeys[i]]
          }`;
        }

        let externalKeys = Object.keys(this.excuteExternal);
        if (externalKeys) {
          for (let i in externalKeys) {
            this.ozParamStr += `${ozSap}connection.args${Number(i) + 1 + commonkeys.length}=${externalKeys[i]}=${
              this.excuteExternal[externalKeys[i]]
            }`;
          }
        }
        this.content = {...this.commonParam, ...this.excuteExternal};

        pcount = commonkeys.length + externalKeys.length;
      }

      if (this.form.content) {
        this.content = this.form.content instanceof Object ? this.form.content : JSON.parse(this.form.content);
        let contentKeys = Object.keys(this.content);
        for (let i in contentKeys) {
          if (contentKeys[i] === 'tl') {
            this.content[contentKeys[i]] = this.form.tl;
          }
          this.ozParamStr += `${ozSap}connection.args${Number(i) + 1}=${contentKeys[i]}=${
            this.content[contentKeys[i]]
          }`;
          if (contentKeys[i] === 'signFlno') {
            this.djFlNo = this.content[contentKeys[i]];
          }
          if (contentKeys[i] === 'sndNmn') {
            this.sndNmn = this.content[contentKeys[i]];
          }
          if (contentKeys[i] === 'ozSndNmn') {
            this.ozSndNmn = this.content[contentKeys[i]];
          }
          if (contentKeys[i] === 'receiptName') {
            this.receiptName = this.content[contentKeys[i]];
          }
          if (contentKeys[i] === 'receiptCnt') {
            this.receiptCnt = this.content[contentKeys[i]];
          }
          if (contentKeys[i] === 'receiptStr') {
            this.receiptList = this.content[contentKeys[i]];
          }
        }
        pcount = contentKeys.length;
      }

      this.ozParamStr += ozSap + 'export.mode=false';
      this.ozParamStr += ozSap + 'connection.servlet=' + ozServer;
      this.ozParamStr += ozSap + `connection.reportname=${this.form.rptNm}`;
      this.ozParamStr += ozSap + 'connection.pcount=' + pcount; //ozr의 입력컴포넌트에 값을 세팅

      this.ozParamStr += ozSap + 'viewer.progresscommand=true';
      this.ozParamStr += ozSap + 'viewer.ignore_disable_color_inputcomponent=true';
      this.ozParamStr += ozSap + 'viewer.closeframe=true';

      this.ozParamStr += ozSap + 'toolbar.save=false';
      this.ozParamStr += ozSap + 'toolbar.savedm=false';
      this.ozParamStr += ozSap + 'toolbar.addmemo=false';
      this.ozParamStr += ozSap + 'toolbar.inversepaper=false';
      this.ozParamStr += ozSap + 'toolbar.singlepagecontinuous_fittowidth=false';
      this.ozParamStr += ozSap + 'toolbar.singlepage_fittoframe=false';
      this.ozParamStr += ozSap + 'toolbar.find=false';
      this.ozParamStr += ozSap + 'toolbar.etc=false';
    },
    isOzParamInit() {
      if (
        ([APPROVAL.SNC_SS_ROLLBACK, APPROVAL.SNC_SS_REJECT].indexOf(this.form.nowSncSs) !== -1 &&
          this.param.taskCd &&
          this.param.sncNo) ||
        this.form.nowSncSs === APPROVAL.SNC_SS_WAIT
      ) {
        this.form.nowSncSs = APPROVAL.SNC_SS_WAIT;
        return true;
      } else {
        return false;
      }
    },
    setInputJson() {
      if (this.form.content) {
        this.ozParamStr += ozSap + 'connection.inputjson=' + JSON.stringify(JSON.parse(this.form.content).inputJson);
      }
    },
    async onDraft() {
      if (this.isValidation()) {
        return;
      }
      this.appRequestTitle = '결재요청';
      this.appRequestSubTitle = '다음 결재대상자에게 결재요청을 알립니다.';
      this.appRequestAlert = '현재 문서를 결재요청 하시겠습니까?';
      this.alarmFlag = 1;
      this.appRequest();
    },
    onTempSave() {
      this.saveTemp();
    },
    appRequest() {
      // 결재요청팝업
      this.$modal.show('appRequest');
    },
    appRequestConfirm() {
      this.callEvent({
        name: 'alarm_onConfirm',
        param: data => {
          this.form.rmk = data;
          if (this.alarmFlag == 1) {
            this.draft();
          } else if (this.alarmFlag == 2) {
            this.approval();
          }
          this.alarmFlag = 0;
        },
      });
      this.$modal.hide('appRequest');
    },
    async draft() {
      //결재상신
      const param = this.setDraftParam();
      param.attachDocList = this.param.fileList ? this.param.fileList : [];
      this.attachDocList.forEach(row => {
        if (row.attachDocFlag) {
          param.attachDocList.push({
            temp: true,
            lclFlNm: row.lclFlNm,
            svrFlPth: row.svrFlPth,
            svrFlNm: row.svrFlNm,
            flDs: row.flDs,
            fbsNo: row.fbsNo,
            grpNo: parseInt(row.id.replace('AR', '')),
          });
        }
      });

      if (!this.isReDraft) {
        param.sncSs = APPROVAL.SNC_SS_APPROVAL;
      }

      try {
        const response = await saveDraft(param);
        if (response.data) {
          this.isReDraft = false;
          this.complete();
        }
      } catch (error) {
        this.$alert({title: '기안', message: error.message});
      }
    },
    setFormLineNm(lineConfirm) {
      let args = '';
      this.signList = [];
      this.collaboratorList = [];
      if (this.isReceiptStandby) {
        this.signedLine = JSON.parse(this.content.line);
        this.signedColLine = JSON.parse(this.content.collaboline);
      }
      const lineList = [...this.lineObject.lineList].reverse();
      this.signList = [...lineList.filter(item => ![APPROVAL.SNC_TP_COOPERATION].includes(item.sncTp))];
      this.collaboratorList = [...lineList.filter(item => [APPROVAL.SNC_TP_COOPERATION].includes(item.sncTp))];

      if (!lineConfirm) {
        return;
      }

      if (this.isReceiptStandby) {
        this.commonParam = {};
      }
      this.commonParam['line'] = '';
      this.commonParam['collaboline'] = '';

      const LINE = initLine(
        this.signList,
        this.param.signCnt ?? this.form.content?.signCnt,
        this.param.isMulti ?? this.form.content?.isMulti,
        this.isReceiptStandby ? this.signedLine : ''
      );

      const COLLABOLINE = initLine(
        this.collaboratorList,
        this.param.signCnt,
        this.param.isMulti,
        this.isReceiptStandby ? this.signedColLine : '',
        'collaboline'
      );

      this.lineMax = LINE.lineMax;
      this.colLineMax = COLLABOLINE.lineMax;

      this.commonParam['line'] = LINE.strLine;
      this.commonParam['collaboline'] = COLLABOLINE.strLine;

      args = `connection.pcount=2;connection.args1=line=${this.commonParam.line};connection.args2=collaboline=${this.commonParam.collaboline}`;
      OZViewer.ReBind(0, 'data', args, ';');
    },
    async saveTemp() {
      if (!(await this.$confirm({title: '임시저장', message: '임시저장하시겠습니까?'}))) {
        return;
      }
      this.isTemp = true;
      const param = this.setDraftParam();
      param.attachDocList = this.param.fileList ? this.param.fileList : [];
      this.attachDocList.forEach(row => {
        if (row.attachDocFlag) {
          param.attachDocList.push({
            temp: true,
            lclFlNm: row.lclFlNm,
            svrFlPth: row.svrFlPth,
            svrFlNm: row.svrFlNm,
            flDs: row.flDs,
            fbsNo: row.fbsNo,
          });
        }
      });

      param.sncSs = APPROVAL.SNC_SS_WAIT;
      try {
        const response = await saveTemp(param);
        if (response.data) {
          this.isTemp = false;
          this.complete();
        }
      } catch (error) {
        this.$alert({title: '임시저장', message: error.message});
      }
    },
    isValidation() {
      let result = false;
      if (this.lineObject.lineList.length == 0) {
        this.$alert(
          {title: '결재선지정', message: '결재선이 지정되어있지 않습니다.<br/>결재선은 반드시 지정해 주십시오.'},
          () => this.showLineSelect()
        );
        return true;
      }
      if (
        !this.isReceiptStandby &&
        this.docCsDs !== APPROVAL.DOC_CS_DS_IN_DEP &&
        this.receiptObject.receiptList.length === 0
      ) {
        this.$alert(
          {title: '수신지정', message: '수신자가 지정되어있지 않습니다.</br>수신자를 반드시 지정해 주십시오.'},
          () => this.showReceiptSelect()
        );
        return true;
      }
      if (this.publicList?.length) {
        this.lineObject.lineList.some(line => {
          if (this.publicList?.find(item => String(line.empNo) === item.empNo)) {
            result = true;
            return true;
          }
        });
      }

      if (result) {
        this.$alert({title: '공람자', message: '결재선에 포함된 사용자를 공람으로 지정할 수 없습니다.'});
      }
      return result;
    },
    setDraftParam() {
      if (!this.offline) {
        this.isTemp
          ? (this.content['line'] = this.commonParam['line'] ?? this.content?.line)
          : (this.content['line'] = setSign({
              pgmCd: this.pgmCd,
              userInfo: this.userInfo,
              lineList: this.commonParam.line ?? this.content.line,
              signCnt: this.param.signCnt,
              isMulti: this.param.isMulti,
              lineMax: this.lineMax,
            }));
        this.content['collaboline'] = this.commonParam['collaboline'] ?? this.content?.collaboline;

        this.content['signCnt'] = this.param.signCnt;
        this.content['isMulti'] = this.param.isMulti;

        this.content['sndNmn'] = this.sndNmn;
        this.content['ozSndNmn'] = this.ozSndNmn;
        this.content['signFlno'] = this.djFlNo;

        this.content['receiptName'] = this.receiptName;
        this.content['receiptCnt'] = this.receiptCnt;
        this.content['receiptObject'] = this.receiptObject.receiptList;

        if (this.docCsDs !== APPROVAL.DOC_CS_DS_IN_DEP) {
          this.content['receiptStr'] = this.receiptList.toString();
        } else {
          this.content['receiptStr'] = '';
        }
      }

      this.setPgmCd();
      this.setParamContent();
      this.setParamLine();
      this.setParamReceipt();
      this.setParamPublic();
      this.setTask();
      this.setParamDocCsDs();
      this.setParamOffline();

      if (!this.offline) {
        this.form.refNo = this.refNo;
      }
      this.form.docNo1 = this.docNo1;
      this.form.sndNmn = this.offline ? this.form.sndNmn : this.sndNmn;
      this.form.ozSndNmn = this.ozSndNmn;
      this.form.sncKd = APPROVAL.SNC_KD_WAIT;

      return this.form;
    },

    setPgmCd() {
      if (this.offline) {
        return;
      }
      if (this.docCsDs === APPROVAL.DOC_CS_DS_EXTERNAL) {
        this.form.sendPgmCd = this.form.pgmCd;
        this.form.pgmCd = this.pgmCd;
      }
    },
    setParamContent() {
      if (this.offline) {
        return;
      }
      let inputJson = OZViewer.GetInformation('INPUT_JSON');
      if (inputJson) {
        inputJson = JSON.parse(inputJson);
      }
      this.form.appInterfaceData = inputJson || {}; // 타업무 연계용 데이터
      this.form.content = JSON.stringify({...this.content, inputJson: {...inputJson}});
    },
    setParamLine() {
      this.form.appLine = cloneDeep(this.lineObject);
      let appLineDSaveList = this.lineObject.lineList;
      this.form.appLine.pgmCd = this.pgmCd;
      this.form.appLine.appLineDSaveList = appLineDSaveList;

      delete this.form.appLine.approverList;
      delete this.form.appLine.approverFixList;
      delete this.form.appLine.lineList;
    },
    setParamReceipt() {
      this.receiptObject.isOffline ? this.setParamOfflineReceiptList() : this.setParamReceiptList();
    },
    setParamReceiptList() {
      this.form.receiptList = this.receiptObject.receiptList?.map(item => ({
        depCd: item.blnDepNo,
        rcprDepNm: item.blnDepNm,
        empNo: item.empNo,
        rcprNm: item.name,
      }));
    },
    setParamOfflineReceiptList() {
      this.form.offlineReceiptList = this.receiptObject.receiptList;
    },
    setParamPublic() {
      this.form.publicList = this.publicList?.map(item => ({
        depCd: item.blnDepNo,
        empNo: item.empNo,
      }));
    },
    setTask() {
      if (this.param.taskCd) {
        this.form.taskCd = this.param.taskCd;
        this.form.taskNo = this.param.taskNo;
      }
    },
    setParamDocCsDs() {
      this.form.docCsDs = this.docCsDs;
    },
    setParamOffline() {
      if (!this.offline) {
        return;
      }
      this.form.offlineYn = APPROVAL.OFFLINE_Y;
      this.form.enfcDt = dateFormat(this.form.enfcDt);
      this.form.rcvDttm = dateFormat(this.form.rcvDttm);
    },
    setOzSndNmn() {
      if (this.docCsDs !== APPROVAL.DOC_CS_DS_IN_DEP) {
        this.ozSndNmn =
          this.sndNmn === '부서장' && this.sndNmn
            ? `${this.userInfo.blnDepNm} ${this.sndNmn}`
            : `${this.userInfo.frmNm} ${this.sndNmn}`;
        let param = `connection.pcount=1;connection.args1=ozSndNmn=${this.ozSndNmn}`;
        OZViewer.ReBind(0, 'data', param, ';');
      } else {
        this.initOzSndNmn();
      }
    },
    initOzSndNmn() {
      let param = `connection.pcount=1;connection.args1=ozSndNmn=`;
      OZViewer.ReBind(0, 'data', param, ';');
    },
    setSndNmList() {
      const companyInfo = this.form.appDocCompanyInfo;
      this.sndNmnList =
        companyInfo?.sndNmList?.map(item => ({text: item.docNm, value: item.seq, djFlNo: item.djFlNo})) ?? [];
    },
    setDocNo1() {
      if (this.isWait) {
        if (this.docCsDs === APPROVAL.DOC_CS_DS_IN_DEP) {
          this.docNo1 = `${this.userInfo.blnDepNm ?? this.userInfo.frmNm}-${new Date().getFullYear()}`;
        } else {
          this.docNo1 = `${this.userInfo.frmNm}-${new Date().getFullYear()}`;
        }
        return;
      }
      this.docNo1 = this.form.docNo1;
    },
    setDocNo2() {
      if (this.form.nowSncSs === APPROVAL.SNC_SS_COMPLETE) {
        this.docNo2 = this.form.docNo2;
      }
    },
    complete() {
      if (this.isModal) {
        this.$modal.hide('pop' + this.parentId);
      }
      const data = this.form.content
        ? (typeof this.form.content === 'string' ? JSON.parse(this.form.content) : this.form.content).inputJson
        : '';
      this.$emit('approvalEmit', data);
    },

    // 결재선지정 팝업
    showLineSelect() {
      this.lineObject.approverList = [];
      this.collaboratorList = [];
      this.signList = [];

      if (this.lineObject.snclDs === APPROVAL.SNCL_DS_OBS) {
        this.lineObject.lineList.forEach(approver => {
          this.lineObject.approverList.push({
            name: approver.name,
            empNo: approver.empNo,
            snclSn: approver.snclSn,
            sncTp: approver.sncTp,
            dtsNo: approver.dtsNo,
            rollNo: approver.rollNo,
          });
        });
      }
      this.$modal.show('LineSelectModal');
    },
    showApprovalStatus() {
      this.$modal.show('DocumentApprovalStatusPop');
    },
    setLineSelect() {
      this.setLine = true;
    },
    unsetLineSelect() {
      this.unsetLine = true;
    },
    confirmLine(modalHidden) {
      if (modalHidden) {
        this.$modal.hide('LineSelectModal');
      }
      if (this.offline) {
        return;
      }
      this.setFormLineNm(true);
    },
    // 수신지정 팝업
    showReceiptSelect() {
      this.$modal.show('ReceiverSelectModal');
    },
    setReceiptSelect() {
      this.setReceipt = true;
    },
    unsetReceiptSelect() {
      this.unSetReceipt = true;
    },
    confirmReceipt(modalHidden) {
      if (modalHidden) {
        this.$modal.hide('ReceiverSelectModal');
      }
      if (this.isDistribute) {
        this.isDistribute = false;
        this.setDistribute();
        return;
      }
      this.setReceiptName();
      this.setOzReceiptName();
      this.sndNmn ? this.setOzSndNmn() : this.initOzSndNmn();
    },
    async setDistribute() {
      let distributeList = this.receiptObject?.receiptList?.map(item => ({
        depCd: item.blnDepNo,
        rcprDepNm: item.blnDepNm,
        empNo: item.empNo,
        rcprNm: item.name,
      }));
      const param = {
        sncNo: this.form.sncNo,
        distributeList: distributeList,
        inoDs: this.docCsDs === APPROVAL.DOC_CS_DS_IN_CMP ? APPROVAL.INO_DS_IN : APPROVAL.INO_DS_OUT,
        rcvDttm: dateFormat($_getCurrentDate()),
      };
      try {
        const response = await saveDistribute(param);
        if (response.data) {
          this.complete();
        }
      } catch (error) {
        this.$alert({title: '배부', message: error.description});
      }
    },
    setReceiptName() {
      this.receiptCnt = 0;
      this.receiptList = [];

      this.receiptCnt = this.receiptObject?.receiptList?.length;
      if (this.docCsDs !== APPROVAL.DOC_CS_DS_IN_DEP) {
        this.receiptName = '';
        if (this.receiptObject.receiptList) {
          //수신처 표시 ex) 법인명(수신자)
          if (this.receiptCnt === 1) {
            const receipt = this.receiptObject.receiptList[0];
            this.receiptName = this.receiptObject.isOffline
              ? receipt.receiptInfo
              : `${receipt.blnDepNm} (${receipt.name})`;
          } else if (this.receiptCnt > 1) {
            this.receiptName = '수신자참조';
          } else {
            this.receiptObject.receiptList = [];
            this.receiptName = '';
          }
        }
      } else {
        this.receiptName = '내부문서';
        this.receiptCnt = 0;
        this.receiptList = [];
      }

      if (this.receiptCnt > 1) {
        this.receiptObject.receiptList.forEach(item => {
          this.receiptObject.isOffline ? this.receiptList.push(item.receiptInfo) : this.receiptList.push(item.blnDepNm);
        });
      }
    },
    setOzReceiptName() {
      let param = `connection.pcount=3;connection.args1=receiptName=${this.receiptName};connection.args2=receiptCnt=${this.receiptCnt};connection.args3=receiptStr=${this.receiptList}`;
      OZViewer.ReBind(0, 'data', param, ';');
    },
    // 공람지정 팝업
    showPublic() {
      this.publicExclude = this.lineObject.lineList.map(line => line.empNo);
      this.$modal.show('PublicSelectModal');
    },
    setPublicSelect() {
      this.setPublic = true;
    },
    unsetPublicSelect() {
      this.unsetPublic = true;
    },
    confirmPublic() {
      this.$modal.hide('PublicSelectModal');
    },
    onApproval() {
      this.alarmFlag = 2;
      this.appRequestTitle = '결재승인';
      this.appRequestSubTitle = '문서를 결재하시겠습니까?';
      this.appRequestAlert = `현재 문서를 <b>${this.finalApproval ? '최종 결재승인' : '결재승인'}</b> 하시겠습니까?`;

      this.appRequest();
    },

    async approval() {
      await this.setAppSign();
      this.setParamContent();

      this.nowSncSs = this.finalApproval ? APPROVAL.SNC_SS_COMPLETE : APPROVAL.SNC_SS_APPROVAL;
      this.nowSncKd = APPROVAL.SNC_KD_APPROVAL;

      if ((this.finalApproval && !this.refNo) || this.offline || (!this.finalApproval && this.refNo)) {
        this.form.enfcDt = new Date(this.form.enfcDt);
      } else {
        this.form.enfcDt = '';
      }

      const param = {
        sncNo: this.form.sncNo,
        docNo: this.form.docNo,
        empNo: this.form.empNo,
        tl: this.form.tl,
        fbsNo: this.form.fbsNo,
        docNo1: this.form.docNo1,
        docNo2: this.form.docNo2,
        content: this.form.content,
        rptNm: this.form.rptNm,
        sncSs: this.nowSncSs,
        sncKd: this.nowSncKd,
        sncTp: this.sncTp,
        sncSqn: this.currentSncSqn,
        rmk: this.form.rmk,
        taskCd: this.form.taskCd,
        taskNo: this.form.taskNo,
        enfcDt: this.form.enfcDt,
        offline: this.offline,
        appInterfaceData: this.form.appInterfaceData,
        flNo: this.flNo,
      };
      try {
        const response = await saveApproval(param);
        if (response.data) {
          this.complete();
        }
      } catch (error) {
        this.$alert({title: '결재승인', message: error.message});
      }
    },
    onReject() {
      this.$modal.show('appReject');
    },
    rejectConfirm() {
      this.callEvent({
        name: 'reject_onConfirm',
        param: data => {
          this.$modal.hide('appReject');
          this.form.rmk = data;
          this.appReject();
        },
      });
    },
    async appReject() {
      const param = {
        sncNo: this.form.sncNo,
        sncSs: APPROVAL.SNC_SS_REJECT,
        sncKd: APPROVAL.SNC_KD_REJECT,
        sncSqn: this.currentSncSqn,
        rmk: this.form.rmk,
        taskCd: this.form.taskCd,
        taskNo: this.form.taskNo,
      };
      try {
        const response = await saveReject(param);
        if (response.data) {
          this.complete();
        }
      } catch (error) {
        this.$alert({title: '결재반려', message: error.message});
      }
    },
    onRedraft() {
      this.$confirm({title: '재기안', message: '재기안 하시겠습니까?'}, () => {
        this.setInitForReDraft(this.form);
      });
    },
    async onAppDelete() {
      if (
        !(await this.$confirm({
          title: '문서삭제',
          message: '현재 문서를 영구적으로 삭제 하시겠습니까?<br><br>관련된 모든 정보가 삭제 됩니다.',
        }))
      ) {
        return;
      }

      const deleteList = [];
      const param = {
        sncNo: this.form.sncNo,
        snclNo: this.form.appLine?.snclNo ?? 0,
        docNo: this.form.docNo,
        taskCd: this.form.taskCd,
        taskNo: this.form.taskNo,
      };
      deleteList.push(param);

      const response = await deleteAppDoc({deleteList});
      if (response.data) {
        this.complete();
      }
    },
    async onAppRollback() {
      const title = '회수';
      const message = `결재문서를 최종 상신/승인 이전 단계로 되돌립니다.
      <br/><br/>- 최종결재정보(싸인/이름/직급/결재일)이 삭제됩니다.
      <br/>- 결재 상신/취소에 따른 별도의 통보는 이루어지지 않습니다.
      <br/><br/>회수를 실행하시겠습니까?`;

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      const param = {
        sncNo: this.form.sncNo,
        taskCd: this.form.taskCd,
        taskNo: this.form.taskNo,
      };
      const response = await saveRollback(param);
      if (response.data) {
        this.complete();
      }
    },
    onAddOfficialSeal() {
      let signUrl = this.getImageUrl(this.djFlNo);
      this.content['signMaster'] = signUrl;

      let param = `connection.pcount=1;connection.args1=signMaster=${signUrl}`;
      OZViewer.ReBind(0, 'data', param, ';');
    },
    onDelOfficialSeal() {
      this.content['signMaster'] = `${process.env.VUE_APP_API_URL}/common/image/groupware/skip_sign.png`;
      let param = `connection.pcount=1;connection.args1=signMaster=${process.env.VUE_APP_API_URL}/common/image/groupware/skip_sign.png`;

      OZViewer.ReBind(0, 'data', param, ';');
    },
    async onDocSend() {
      window.setOfficialSeal = chk => {
        this.isOfficialSeal = !chk.checked;
      };
      let message = `문서를 수신자(처)에게 발송하시겠습니까?<br/>
      관인생략 <input type="checkbox" id="delOfficialSeal" onchange="setOfficialSeal(this)" /><label for="delOfficialSeal"><span></span> (체크 시 관인을 생략하여 발송합니다.) </label>`;
      if (!(await this.$confirm({title: '발송확인', message: message}))) {
        this.isOfficialSeal = true;
        return;
      }

      this.isOfficialSeal ? this.onAddOfficialSeal() : this.onDelOfficialSeal(); //관인 or 관인생략

      this.setReceiptDept();

      this.setParamContent();
      const param = {
        sncNo: this.form.sncNo,
        sndNo: this.form.sndNo,
        content: this.form.content,
        sndNmn: this.form.sndNmn,
      };
      const response = await saveAppDocSend(param);
      if (response.data) {
        this.complete();
      }
    },
    setReceiptDept() {
      let date = $_getCurrentDate();
      let time = $_getNowTime();

      let receiptTime = ` ${this.form.docNo}      ( ${date.substring(0, 4)}.${date.substring(4, 6)}.${date.substring(
        6,
        8
      )} ${time.substring(0, 5)} )`;

      let param = `connection.pcount=1;connection.args1=receiptDept=${receiptTime}`;
      OZViewer.ReBind(0, 'data', param, ';');

      this.content['receiptDept'] = this.form.docNo;
    },
    onReceipt() {
      if (!this.offline) {
        const lineObj = {
          line: this.content.line,
          collaboline: this.content.collaboline,
        };
        this.content = {...this.content, lineObj};
        this.refNo = this.form.docNo;
      }
      this.docNo1 = `${this.userInfo.blnDepNm}-${new Date().getFullYear()}`;
      this.docNo2 = '';
      this.onDraft();
    },
    onDistribute() {
      this.isDistribute = true;
      this.showReceiptSelect();
    },
    async onSendReturn() {
      this.$modal.show('sendReturn');
    },
    async onPublicConfirm() {
      const param = {
        publicSaveList: [
          {
            sncNo: this.form.sncNo,
            empNo: this.param.publicEmpNo,
            depCd: this.param.publicDepCd,
          },
        ],
      };
      const response = await savePublicConfirm(param);
      if (response.data) {
        this.$emit('approvalEmit');
      }
    },
    sendReturnConfirm() {
      this.callEvent({
        name: 'sendReturn_onConfirm',
        param: async data => {
          this.$modal.hide('sendReturn');
          const inoDs = this.docCsDs === APPROVAL.DOC_CS_DS_IN_CMP ? APPROVAL.INO_DS_IN : APPROVAL.INO_DS_OUT;
          const param = {
            pgmCd: this.form.pgmCd,
            sncNo: this.form.sncNo,
            reSndgRsn: data,
            inoDs,
          };
          const response = await saveReturnSend(param);
          if (response.data) {
            this.form.nowSncSs = APPROVAL.SNC_SS_RETURN_SEND;
            this.complete();
          }
        },
      });
    },
    async setAppSign() {
      if (this.offline) {
        return;
      }
      const finalSncSqn = this.form?.finalSncSqn?.finalSncSqn ?? 0;
      const LINE = setSign({
        pgmCd: this.pgmCd,
        userInfo: this.userInfo,
        lineList: this.sncTp === APPROVAL.SNC_TP_COOPERATION ? this.content.collaboline : this.content.line,
        key: this.sncTp === APPROVAL.SNC_TP_COOPERATION ? 'collaboline' : 'line',
        approver: this.form.approver,
        isDecide: this.sncTp === APPROVAL.SNC_TP_DECIDE,
        isMulti: this.content.isMulti,
        signCnt: this.content.signCnt,
        lineMax: finalSncSqn,
      });
      this.content[this.sncTp === APPROVAL.SNC_TP_COOPERATION ? 'collaboline' : 'line'] = LINE;

      let date = dateFormat($_getCurrentDate()).replace(/-/g, '.');
      let time = $_getNowTime();
      if (this.finalApproval) {
        //시행
        const response = await getDocNo2({docNo1: this.form.docNo1});
        this.form.docNo2 = response.data;

        let receiptTime = ` ${this.form.docNo1}-${this.form.docNo2}    ( ${date} ${time.substring(0, 5)} )`;
        this.content['enfc'] = `${receiptTime}`;

        this.form.enfcDt = new Date();
      }
      this.form.content = this.content;
      this.setFormValue();
    },
    getImageUrl(flNo) {
      return `${
        location.protocol.indexOf('https') !== -1 ? process.env.VUE_APP_FILE_URLS : process.env.VUE_APP_FILE_URL
      }/file/getImage?pgmCd=${this.pgmCd}&flNo=${flNo}`;
    },
    clearFile() {
      this.fileClear = true;
    },
    async onSave() {
      const callback = (fileInfo, param) => {
        if (fileInfo.offlineFile) {
          param[fileInfo.offlineFile.flNoCol] = fileInfo.offlineFile.flNo;
          return param;
        }
        this.$alert({title: '첨부파일', message: '파일을 첨부해주세요.'});
        return false;
      };
      const param = {
        docNo1: this.form.docNo1,
        enfcDt: dateFormat(this.form.enfcDt),
        tl: this.form.tl,
        sndNmn: this.form.sndNmn,
        draftDepNm: this.form.draftDepNm,
        draftNm: this.form.draftNm,
        rcvDttm: dateFormat(this.form.rcvDttm),
        docCsDs: this.form.docCsDs,
        taskCd: this.param.taskCd,
        taskNo: this.param.taskNo,
      };
      const response = await saveOffline(param, {callback});
      if (response.data) {
        this.complete();
      }
    },
    onChangeFormData(key, value) {
      const form = {...this.form};
      form[key] = value;
      this.form = form;
    },
  },
};
</script>

<style>
.oz_except_node {
  height: 24px !important;
}
</style>
