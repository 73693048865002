export default that => {
  const preset = that.$ibsheetHelper.preset;

  return {
    Cfg: {CanEdit: 1, CanSort: 0, HeaderMerge: 5},
    Def: {Row: {NoColor: 2, CanFocus: 1}},
    Cols: [
      {Header: ['명칭', '명칭'], Name: 'itemNm', RelWidth: 1, MinWidth: 100, CanEdit: false},
      {Header: ['규격', '규격'], Name: 'sizeNm', RelWidth: 1, MinWidth: 100, CanEdit: false},
      {
        Header: ['단위', '단위'],
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        Width: 60,
        CanEdit: false,
        Format: $getConstants('UNIT').code,
      },
      {Header: ['계약수량', '계약수량'], Name: 'contQty', MinWidth: 90, Extend: preset.quantity, CanEdit: false},
      {Header: ['기발주수량', '기발주수량'], Name: 'preQty', MinWidth: 90, Extend: preset.quantity, CanEdit: false},
      {Header: ['잔여수량', '잔여수량'], Name: 'remQty', MinWidth: 90, Extend: preset.quantity, CanEdit: false},
      {Header: ['금회발주', '수량'], Name: 'qty', MinWidth: 90, Extend: preset.quantity, CanEdit: true},
      {Header: ['금회발주', '단가'], Name: 'unitPrc', MinWidth: 90, Extend: preset.unitAmount, CanEdit: false},
      {
        Header: ['금회발주', '금액'],
        Name: 'amt',
        Width: 140,
        CanEdit: false,
        Extend: preset.amount,
        Formula: fr => fr.Row['qty'] * fr.Row['unitPrc'] || 0,
      },
      {Name: 'poNo', Visible: false},
      {Name: 'itemCd', Visible: false},
    ],
  };
};
