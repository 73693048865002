export default that => {
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      CanEdit: 0, // 1: 전체 편집 가능 (default)
    },
    Cols: [
      {
        Header: '노무팀코드',
        Name: 'labCode',
        Type: 'Text',
        Align: 'Center',
        Width: 100,
      },
      {
        Header: '노무팀명',
        Name: 'labName',
        Type: 'Text',
        Align: 'left',
        RelWidth: 1,
      },
      {
        Header: '팀장명',
        Name: 'labMNm',
        Type: 'Text',
        Align: 'left',
        Width: 100,
      },
    ],
  };
};
