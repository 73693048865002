<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col>
          <PxcondDtlsRegist />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from '@/view/pxcondRqestManage/store/PxcondDtlsRegist.js';
import PrjList from '@/view/Project/components/PrjList.vue';
import PxcondDtlsRegist from '../components/PxcondDtlsRegist.vue';
export default {
  components: {PrjList, PxcondDtlsRegist},
  beforeCreate() {
    $init(this, store);
  },
};
</script>

<style scoped></style>
