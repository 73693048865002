export default that => {
  const preset = that.$ibsheetHelper.preset;

  return {
    Cfg: {CanEdit: 1, CanSort: 0},
    Def: {
      Row: {NoColor: 2, CanFocus: 0},
    },
    Cols: [
      {Header: '공종명', Name: 'srvNm', Type: 'Text', RelWidth: 1, MinWidth: 80, CanEdit: false},
      {Header: '내역코드', Name: 'itemSeq', Type: 'Text', Align: 'center', Width: 70, CanEdit: false},
      {Header: '자원구분', Name: 'costType', Type: 'Text', Align: 'center', Width: 70, CanEdit: false},
      {Header: '내역명', Name: 'itemNm', Type: 'Text', RelWidth: 1, MinWidth: 80, CanEdit: false},
      {Header: '규격', Name: 'sizeNm', Type: 'Text', RelWidth: 1, MinWidth: 80, CanEdit: false},
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        Width: 50,
        CanEdit: false,
        Format: $getConstants('UNIT').code,
      },
      {Header: '실행수량', Name: 'totQty', Width: 100, CanEdit: false, Extend: preset.quantity},
      {Header: '기시행수량', Name: 'preQty', Width: 100, CanEdit: false, Extend: preset.quantity},
      {Header: '잔여수량', Name: 'remQty', Width: 100, CanEdit: false, Extend: preset.quantity},
      {Header: '금회요청수량', Name: 'qty', Width: 100, CanEdit: true, Color: '#E0E0F8', Extend: preset.quantity},
      {Header: '비고', Name: 'srvRmrk', Type: 'Text', Width: 100, CanEdit: true, Color: '#E0E0F8'},

      {Name: 'prSrvSeq', Visible: false},
      {Name: 'wcode', Visible: false},
      {Name: 'headCode', Visible: false},
      {Name: 'midCode', Visible: false},
      {Name: 'itemCode', Visible: false},
      {Name: 'planMatUprc', Visible: false},
      {Name: 'planLabUprc', Visible: false},
      {Name: 'planEquipUprc', Visible: false},
      {Name: 'planCostUprc', Visible: false},
      {Name: 'labAmt', Formula: fr => fr.Row['qty'] * fr.Row['planLabUprc'], Visible: false},
      {Header: '단가', Name: 'unitAmt', Visible: false},
      {Header: '금액', Name: 'amt', Visible: false, Formula: fr => fr.Row['qty'] * fr.Row['unitAmt']},
    ],
  };
};
