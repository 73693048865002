import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';

export const spotDscrChkYnRadioCol = {
  Header: '현설참석여부',
  Name: 'spotDscrChkYn',
  Type: 'Radio',
  Width: 150,
  Enum: '|참여|미참여',
  EnumKeys: `|${BIDDING_CONSTANTS.CODES.SPOT_DSCR_CHK_Y}|`,
  DefaultValue: '',
  Visible: 0,
};

export const spotDscrChkYnTextCol = {
  Header: '현설참석여부',
  Name: 'spotDscrChkYn',
  Type: 'Text',
  Align: 'center',
  Width: 100,
  Visible: 0,
  CustomFormat: v => (v === BIDDING_CONSTANTS.CODES.SPOT_DSCR_CHK_Y ? 'O' : 'X'),
};

export default that => {
  return {
    Cfg: {CanEdit: 1, SpaceForDefaultValue: true},
    Def: {Row: {NoColor: 1, CanFocus: 0}},
    Cols: [
      {
        Header: '협력사명',
        Name: 'frmNm',
        Type: 'Text',
        MinWidth: 130,
        RelWidth: 1.5,
        CanEdit: 0,
        TextStyle: 5,
        TextColor: '#FF0000',
        Cursor: 'Pointer',
      },
      {
        Header: '담당자명',
        Name: 'icprNm',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: '전화번호',
        Name: 'icprPhone',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CanEdit: 0,
        CustomFormat: 'PhoneNo',
      },
      {
        Header: '이메일',
        Name: 'email',
        Type: 'Text',
        MinWidth: 100,
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: '현설참가신청',
        Name: 'spotDscrApplyYn',
        Type: 'Text',
        Align: 'center',
        Width: 100,
        CanEdit: 0,
        Visible: 1,
        DefaultValue: '-',
        CustomFormat: v =>
          ({
            [BIDDING_CONSTANTS.CODES.SPOT_DSCR_APPLY_Y]: 'O',
            [BIDDING_CONSTANTS.CODES.SPOT_DSCR_APPLY_N]: 'X',
            ['-']: '미응답',
          }[v]),
      },
      {
        Header: '입찰참가신청',
        Name: 'bidPrtcpApplyYn',
        Type: 'Text',
        Align: 'Center',
        Width: 100,
        CanEdit: 0,
        DefaultValue: '-',
      },
      {
        Header: '응찰여부',
        Name: 'quotSbmtYn',
        Type: 'Text',
        Align: 'Center',
        Width: 100,
        CanEdit: 0,

        DefaultValue: '-',
        CustomFormat: v => (v === BIDDING_CONSTANTS.CODES.QUOT_SBMT_Y ? 'O' : 'X'),
      },

      {Name: 'cusCd', Visible: false},
      {Name: 'cusPgmCd', Visible: false},
    ],
  };
};
