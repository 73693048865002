export default that => {
  return {
    Def: {
      Row: {
        CanFocus: 0,
      },
    },
    Cols: [
      {
        Header: '현장명',
        Name: 'prjNm',
        Type: 'Text',
        RelWidth: 2,
        MinWidth: 180,
      },
      {
        Header: '소속명',
        Name: 'blnDepPartNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
      },
      {
        Header: '직종',
        Name: 'jkdNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
      },
      {
        Header: '계약시작일',
        Name: 'contStDate',
        Type: 'Text',
        Width: 120,
        Extend: that.$ibsheetHelper.preset.date,
      },
      {
        Header: '계약종료일',
        Name: 'contEdDate',
        Type: 'Text',
        Width: 120,
        Extend: that.$ibsheetHelper.preset.date,
      },
      {
        Header: '계약서',
        Name: 'contract',
        Type: 'Button',
        Align: 'center',
        Width: 100,
        Button: 'Button',
        ButtonText: '계약서보기',
        ButtonWidth: 80,
        OnClick: that.onClickShowContButton,
      },
    ],
  };
};
