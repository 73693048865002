export default that => {
  return {
    Cfg: {
      MainCol: 'fldrNm', // 트리가 표시될 열이름
      SearchMode: 0,
      CanEdit: 1,
      NoTreeLines: 0, // 트리를 사용하는 시트 생성시 노드와 노드 사이의 연결선을 표시할 지 여부를 설정,
      HeaderCheck: 1,
    },
    Cols: [
      {
        Header: '단위작업내역',
        Name: 'fldrNm',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
      },
    ],
  };
};
