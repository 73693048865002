<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row height="180px">
        <i-col>
          <pmis-content-box>
            <template #title>서버인증서 등록</template>
            <iui-container-new type="css-flex">
              <i-row>
                <i-col width="600px">
                  <iui-container-new type="table" theme="bullet" header-width="220px">
                    <i-row>
                      <i-col-header>암호화 공개키 (kmCert.der 파일)</i-col-header>
                      <i-col>
                        <iui-file
                          accept=".der"
                          :save.sync="save"
                          :changed.sync="changed"
                          :clear.sync="clear"
                          @save-complete="onSaveFileComplete"
                        />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template #title>서버인증서 목록</template>
            <ib-sheet
              :uid="_uid"
              :options="sheetOpt"
              :loadSearchData="loadSearchData"
              @loadSheet="sheet = $event"
              :events="{onRenderFirstFinish}"
            />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import {
  selectGovCertificateList,
  insertGovCertificateList,
  deleteGovCertificateInfo,
} from '@/view/sysManage/baseSysManage/govCertificateRegist/api/govCertificate.js';
import sheetOpt from '@/view/sysManage/baseSysManage/govCertificateRegist/sheetOption/govCertificate.js';
export default {
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],
      save: false,
      changed: false,
      clear: false,
      flNo: 0,
    };
  },
  created() {
    this.addFuncSearch(this.onSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onPreSave);
    this.addFuncDelete(this.onDelete);
  },
  methods: {
    onSearch() {
      this.onInit();
      selectGovCertificateList().then(response => this.sheet.loadSearchData(response.data));
    },
    onAdd() {
      this.onInit();
    },
    async onPreSave() {
      if (!this.changed) {
        this.$alert({
          title: '인증서 저장',
          message: '암호화 공개키 파일을 등록해야 합니다.</span>',
        });
        return;
      }
      if (await this.$confirm({title: '인증서 저장', message: '서버인증서를 저장하시겠습니까?'})) {
        this.callEvent({name: 'showLoading'});
        this.onSaveFile();
      }
    },
    onSaveFile() {
      if (this.changed) {
        this.save = true;
      } else {
        this.onSaveFile();
      }
    },
    onSaveFileComplete(flNo) {
      this.flNo = flNo;
      this.onSave();
    },
    async onSave() {
      const param = {
        flNo: this.flNo,
      };

      insertGovCertificateList(param)
        .then(response => {
          this.callEvent({name: 'hideLoading'});
          if (response.status == 200) {
            this.onInit();
            this.onSearch();
          }
        })
        .catch(ex => {
          this.callEvent({name: 'hideLoading'});
          this.$alert({title: '서버인증서 저장', message: '인증서 저장을 실패했습니다.<br/>' + ex});
        });
    },
    onInit() {
      this.clear = true;
      this.flNo = 0;
    },
    async onDelete() {
      const title = '서버인증서 삭제';

      if (0 == this.sheet.getTotalRowCount()) {
        this.$alert({title, message: '삭제할 서버인증서 정보가 존재하지 않습니다.'});
        return;
      }

      const checkedRows = this.sheet.getRowsByChecked('cbx');
      if (0 == checkedRows.length) {
        this.$alert({title, message: '삭제할 서버인증서를 선택해야 합니다.'});
        return;
      }

      const certNo = this.sheet.getValue(checkedRows[0], 'certNo');
      const message = `서버인증서를 삭제하시겠습니까?<br/><span style="font-size:10pt;">[인증서 번호 : ${certNo}]</span>`;

      if (!(await this.$confirm(title, message))) {
        return;
      }

      deleteGovCertificateInfo(certNo)
        .then(response => {
          if (response.status == 200) {
            this.onSearch();
          }
        })
        .catch(ex => this.$alert({title, message: '인증서 삭제를 실패했습니다.<br/>' + ex}));
    },
    onRenderFirstFinish(e) {
      e.sheet.setAutoMerge({headerMerge: 5});
      this.onSearch();
    },
  },
};
</script>

<style scoped></style>
