<template>
  <pmis-content-box>
    <template #title>계약차수</template>
    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="poContSeqList"
      :events="{
        onSearchFinish: sheet_onSearchFinish,
        onClick: sheet_onClick,
      }"
    />
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/poContSeqList.js';
export default {
  data() {
    return {
      sheetOpt: sheetOpt(this),
    };
  },
  beforeCreate() {
    $mapGetters(this, ['poContSeqList']);
    $mapActions(this, ['searchPoInfos']);
  },
  methods: {
    setContSeqData(row) {
      if (row) {
        const {poNo, contSeq} = row;
        this.searchPoInfos({poNo, contSeq});
      }
    },
    sheet_onSearchFinish(e) {
      if (!e.sheet.getTotalRowCount()) {
        return;
      }

      this.setContSeqData(e.sheet.getFirstRow());
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data') {
        this.setContSeqData(e.row);
      }
    },
  },
};
</script>

<style></style>
