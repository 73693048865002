import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 0},
    Def: {Row: {NoColor: 1, CanFormula: 1, CanFocus: 0}},
    Cols: [
      {Header: '순번', Name: 'seq', Type: 'Text', Align: 'Center', Width: 80},
      {Header: '현장명', Name: 'spotDeptnm', Type: 'Text', RelWidth: 1, MinWidth: 150},
      {Header: '계약일자', Name: 'regDt', Type: 'Text', Align: 'Center', Width: 120},
      {
        Header: '계약번호',
        Name: 'poNo',
        Type: 'Text',
        Align: 'Center',
        Width: 140,
        CustomFormat: v => COMMON_FUNCTION.getMakeFormat(v),
      },
      {Header: '계약명', Name: 'ttl', Type: 'Text', RelWidth: 1, MinWidth: 150},
      {Header: '고유식별번호', Name: 'stampNo', Type: 'Text', Align: 'Center', Width: 170},
      {Header: '상호', Name: 'frmNm', Type: 'Text', RelWidth: 1, MinWidth: 130},
      {Header: '사업자번호', Name: 'stampBsnNo', Type: 'Text', Align: 'Center', CustomFormat: 'SaupNo', Width: 120},
      {Header: '납부세액', Name: 'stampPaymentAmt', Type: 'Text', Extend: preset.amount, Width: 140},
      {Header: '인지세액', Name: 'stampTaxAmt', Type: 'Text', Extend: preset.amount, Width: 140},
    ],
  };
};
