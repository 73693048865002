<template>
  <iui-portlet-container>
    <template #title>현장정보</template>

    <project-status-list />
  </iui-portlet-container>
</template>

<script>
export default {};
</script>

<style></style>
