<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col>
        <iui-container-new type="table" theme="bullet" header-width="70px">
          <i-row>
            <i-col-header>자재명칭</i-col-header>
            <i-col>
              <iui-text :value="itemName" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>규격</i-col-header>
            <i-col>
              <iui-text :value="size" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>형식</i-col-header>
            <i-col>
              <iui-text :value="matStyle" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col colspan="2">
              <iui-container-new type="table" theme="bullet" header-width="70px">
                <i-row>
                  <i-col-header>단위</i-col-header>
                  <i-col>
                    <iui-text width="65px" :value="unitName" :enable="false" />
                  </i-col>
                  <template v-if="useDsVisible">
                    <i-spacer />
                    <i-col-header>사용여부</i-col-header>
                    <i-col>
                      <iui-checkbox-group
                        name="useDs"
                        width="120px"
                        :value="useDsName"
                        :enable="false"
                        :items="[{label: '미사용'}]"
                      />
                    </i-col>
                  </template>
                </i-row>
              </iui-container-new>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
      <i-spacer />
      <i-col width="230px">
        <pmis-image-file
          id="matImageFile"
          :allowAdd="false"
          :allowDelete="false"
          :saveButtonVisible="false"
          :company-code="companyCode"
          :file-number.sync="flNo"
        />
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
export default {
  props: {
    propsMatCode: {
      type: String,
      default: undefined,
    },
    propsPgmCd: {
      type: String,
      default: undefined,
    },
    useDsVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      companyCode: '',
      lmCode: '',
      mmCode: '',
      smCode: '',
      matCode: '',
      itemName: '',
      size: '',
      matStyle: '',
      unit: '',
      unitName: '',
      matGubun: '',
      flNo: '',
      useDs: '',
      useDsName: '',
      lmName: '',
      mmName: '',
      smName: '',
      rgprId: '',
    };
  },
  async mounted() {
    await this.$nextTick();
    this.onSearch();
  },
  methods: {
    async onSearch() {
      let matCodeModal = '';
      if (this.$store.getters['modalParam']) {
        matCodeModal = this.$store.getters['modalParam'].matCode;
      }

      let searchPgmCd = this.propsPgmCd || this.pgmCd;
      let searchMatCode = matCodeModal || this.propsMatCode;

      let response = await axios.post('/matCodeRegist/selectMaterialCodeInfo', {
        pgmCd: searchPgmCd,
        matCode: searchMatCode,
      });

      this.companyCode = response.data.pgmCd;
      this.lmCode = response.data.lmCode;
      this.mmCode = response.data.mmCode;
      this.smCode = response.data.smCode;
      this.matCode = response.data.matCode;
      this.itemName = response.data.itemName;
      this.size = response.data.size;
      this.matStyle = response.data.matStyle;
      this.unit = response.data.unit;
      this.unitName = response.data.unitName;
      this.matGubun = response.data.matGubun;
      this.flNo = response.data.flNo;
      this.useDs = response.data.useDs;
      this.useDsName = response.data.useDsName;
      this.lmName = response.data.lmName;
      this.mmName = response.data.mmName;
      this.smName = response.data.smName;
      this.rgprId = response.data.rgprId;
    },
  },
};
</script>

<style></style>
