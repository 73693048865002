export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 0},
    Def: {
      Row: {
        NoColor: 2,
        CanFocus: 0,
        CanFormula: 1,
        CalcOrder: 'amt,vatAmt,totAmt',
      },
    },
    Cols: [
      {
        Header: '명칭',
        Name: 'itemNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 200,
        TextStyle: 5,
        TextColor: '#FF0000',
        Cursor: 'Pointer',
      },
      {Header: '규격', Name: 'sizeNm', Type: 'Text', RelWidth: 1, MinWidth: 200},
      {Header: '단위', Name: 'unitNm', Type: 'Text', Align: 'center', Width: 80},
      {Header: '수량', Name: 'qty', Width: 120, Extend: preset.quantity},
      {Header: '단가', Name: 'unitPrc', Width: 120, Extend: preset.unitAmount},
      {Header: '금액', Name: 'amt', Width: 120, Extend: preset.amount, Formula: fr => fr.Row.qty * fr.Row.unitPrc},

      {Name: 'vatAmt', Visible: false, Formula: fr => fr.Row.amt * (this.rfq.taxRate / 100)},
      {Name: 'totAmt', Visible: false, Formula: fr => fr.Row.amt + fr.Row.vatAmt},
      {Name: 'rfqSeq', Visible: false},
      {Name: 'itemCd', Visible: false},
      {Name: 'unit', Visible: false},
      {Name: 'itemRmrk', Visible: false},
    ],
  };
};
