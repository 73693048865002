<template>
  <pmis-content-box>
    <template v-slot:title>문서관리정보</template>
    <iui-container-new type="table" theme="bullet">
      <i-row>
        <i-col-header>슬로건</i-col-header>
        <i-col>
          <iui-text
            max-length="200"
            :value="companyInfo.slogan"
            @change="setCompanyInfo({slogan: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>발신명의</i-col-header>
        <i-col align="right">
          <div style="width: 100%; display: flex;justify-content: flex-end;">
            <iui-button class="btn" value="신규" @click="onAdd" v-show="enable" />
            <iui-button class="btn" value="저장" @click="onSave" v-show="enable" v-if="companyInfo.pgmCd" />
            <iui-button class="btn" value="삭제" @click="onDelete" v-show="enable" />
          </div>
        </i-col>
      </i-row>
      <i-row :height="enable ? '250px' : '125px'">
        <i-col colspan="2">
          <iui-container-new type="css-flex" :height="enable ? '245px' : '120px'">
            <i-row>
              <i-col>
                <ib-sheet
                  :uid="_uid"
                  :options="Options"
                  :loadSearchData="docnmList"
                  :events="{
                    onSearchFinish,
                    onFocus,
                    onRenderFirstFinish,
                  }"
                  @loadSheet="loadSheet"
                />
              </i-col>
              <i-col v-if="enable" width="240px">
                <pmis-image-file
                  :company-code="companyInfo.pgmCd"
                  title="관인"
                  id="file2"
                  flNoCol="djFlNo"
                  :file-number.sync="djFlNo"
                  :saveAuth="isEditableDjFlNo"
                  theme="bullet"
                  @delete-complete="image_delete_complete"
                  :clear.sync="clearDjFlNo"
                />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col-header colspan="2" v-if="enable">
          로고: 크기 (가로: 120px, 세로: 70px), 배경이 없는 png
        </i-col-header>
      </i-row>
      <i-row :height="enable ? '220px' : '200px'">
        <i-col colspan="2">
          <iui-container-new type="css-flex">
            <i-row>
              <i-col v-if="!enable" flex="4">
                <pmis-image-file
                  :company-code="companyInfo.pgmCd"
                  title="관인"
                  id="file"
                  flNoCol="djFlNo"
                  :file-number.sync="djFlNo"
                  :saveAuth="isEditableDjFlNo"
                  theme="bullet"
                  @delete-complete="image_delete_complete"
                />
              </i-col>
              <i-col v-if="!enable" flex="1"></i-col>
              <i-col flex="4">
                <pmis-image-file
                  :company-code="companyInfo.pgmCd"
                  is-logo
                  title="로고"
                  :isLogo="true"
                  id="file3"
                  flNoCol="lgFlNo"
                  :file-number.sync="companyInfo.lgFlNo"
                  theme="bullet"
                  @delete-complete="onDeleteComplete('lgFlNo', $event)"
                  :saveAuth="enable ? true : false"
                />
              </i-col>
              <i-col flex="1"></i-col>
              <i-col flex="4">
                <pmis-image-file
                  :company-code="companyInfo.pgmCd"
                  title="심볼"
                  id="file4"
                  flNoCol="smFlNo"
                  :file-number.sync="companyInfo.smFlNo"
                  theme="bullet"
                  @delete-complete="onDeleteComplete('smFlNo', $event)"
                  :saveAuth="enable ? true : false"
                />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/DocManageInfoSheet.js';
import cloneDeep from 'lodash/cloneDeep';
import {deleteDocnmList, saveDjFlNo, saveDocnmList, selectDocnmList, updateFlNo} from '../api/member.js';

export default {
  components: {},
  props: {
    enable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sheet: undefined,
      Options: this.enable ? $addColCheckbox(options(this)) : options(this),
      docManageList: {},
      lgFlNo: 0,
      smFlNo: 0,
      clearDjFlNo: false,
    };
  },
  computed: {
    djFlNo: {
      set(value) {
        this.docManageList.djFlNo = value;
      },
      get() {
        return this.docManageList.djFlNo || 0;
      },
    },
    isEditableDjFlNo() {
      if (this.enable) {
        return !!this.docManageList.seq;
      } else {
        return false;
      }
    },
  },
  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'worker', 'docnmList']);
    $mapMutations(this, ['setCompanyInfo', 'setFileFlag']);
  },
  created() {
    this.addEvent([{name: 'docManageInfoGetSaveJson', func: this.getSaveJson}]);
    this.addEvent([{name: 'docManageInfoClearImage', func: this.clearImage}]);
  },
  mounted() {},
  methods: {
    clearImage() {
      this.docManageList = '';
    },
    onAdd() {
      this.clearDjFlNo = true;
      this.sheet.addRow();
      let focusedRow = this.sheet.getFocusedRow();
      this.docManageList = cloneDeep(focusedRow);
    },
    async onSave() {
      let saveJson;
      const focusedRow = this.sheet.getFocusedRow();
      if (this.sheet.getTotalRowCount() == 0) {
        this.$alert({title: '발신명의 저장', message: '저장할 발신명의가 존재하지 않습니다.'});
        return;
      }

      saveJson = this.makeSaveJson();

      const validItem = saveJson.find(item => !item.docNm);
      if (validItem !== undefined) {
        this.$alert({title: '발신명의 저장', message: '발신명의를 입력해야 합니다.'});
        return;
      }

      const docnmList = $_statusToCud(saveJson).map(item => {
        return {
          pgmCd: item.pgmCd ? item.pgmCd : this.companyInfo.pgmCd,
          seq: item.seq,
          docNm: item.docNm,
          cud: item.cud,
        };
      });

      const callback = (fileInfo, param) => {
        if (fileInfo.file2) {
          param.docnmList.some(item => {
            if (Number(item.seq) === Number(this.sheet.getFocusedRow().seq)) {
              item[fileInfo.file2.flNoCol] = fileInfo.file2.flNo;
              return true;
            }
          });
        }
        return param;
      };

      const response = await saveDocnmList({docnmList}, {callback});
      if (response.status == 200) {
        this.onSearchDocnmList();
      }
    },
    async onDelete() {
      if (this.sheet.getTotalRowCount() == 0) {
        this.$alert({title: '발신명의 삭제', message: '삭제할 발신명의가 존재하지 않습니다.'});
        return;
      }

      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({title: '발신명의 삭제', message: '선택된 항목이 없습니다.'});
        return;
      }
      const docnmList = checkedRows.map(item => {
        return item.seq;
      });
      if (await this.$confirm({title: '발신명의 삭제', message: '삭제하시겠습니까?'})) {
        const response = await deleteDocnmList({docnmList});
        if (response.status == 200) {
          this.clearDjFlNo = true;
          await this.clearDjFlNo;
          this.onSearchDocnmList();
        }
      }
    },
    setDocManageList(data) {
      this.docManageList = {...this.docManageList, ...data};
    },
    getSaveJson(callback) {
      if (typeof callback == 'function') {
        callback(this.makeSaveJson(), this.sheet.getFocusedRow().seq);
      }
    },
    makeSaveJson() {
      let saveJson;
      const focusedRow = this.sheet.getFocusedRow();
      if (!this.sheet.hasChangedData()) {
        const row = this.sheet.getSaveJson(0).data.find(row => Number(row.seq) === Number(focusedRow.seq));
        row.cud = 2;
        saveJson = [row];
      } else {
        saveJson = this.sheet.getSaveJson(0).data.filter(row => {
          if (row.status) {
            return true;
          }
          if (Number(row.seq) === Number(focusedRow.seq)) {
            if (!row.status) {
              row.cud = 2;
            }
            return true;
          }
        });
      }
      return saveJson;
    },
    image_delete_complete() {
      this.setDocManageList({djFlNo: 0});
      this.onSaveImageFileNo();
    },
    async onSaveImageFileNo() {
      let param = {
        rgprId: this.userInfo.empNo,
        seq: this.docManageList.seq,
        djFlNo: this.docManageList.djFlNo,
      };
      let response = await saveDjFlNo(param);
      if (response.data) {
        this.changeDjFlNo(this.docManageList.djFlNo);
      }
    },
    async onSearchDocnmList() {
      const param = {pgmCd: this.companyInfo.pgmCd};
      const response = await selectDocnmList(param);
      if (response.status == 200) {
        this.sheet.loadSearchData(response.data);
      }
    },
    async onDeleteComplete(targetName, flNo) {
      if (flNo) return;

      let param = {saveFileTargetName: targetName, [targetName]: flNo};
      const response = await updateFlNo(param);
      if (response.status == 200) {
        this.setCompanyInfo({[targetName]: flNo});
      }
    },
    async changeImage(e) {
      this.clearDjFlNo = true;
      await this.clearDjFlNo;
      this.setDocManageList(e.row);
    },
    onFocus(e) {
      if (e.row.Kind == 'Data') {
        this.changeImage(e);
      }
    },
    onSearchFinish(e) {
      this.defaultDocNm();
      const row = e.sheet.getFirstRow();
      e.sheet.focus(row);
    },
    changeDjFlNo(no) {
      let focusedRow = this.sheet.getFocusedRow();
      this.sheet.setValue(focusedRow, 'djFlNo', no, 1);
      this.sheet.acceptChangedData(focusedRow);
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
    defaultDocNm() {
      let defaultRows = this.sheet.getDataRows();
      defaultRows.forEach(row => {
        if (row.seq < 4) {
          row['CanEdit'] = false;
          this.sheet.refreshRow(row);
        }
      });
    },
    onRenderFirstFinish() {
      this.defaultDocNm();
    },
  }, //methods

  beforeDestroy() {
    this.sheet.dispose();
  },
};
</script>

<style></style>
