<template>
  <pmis-tab-box>
    <template v-slot:title>정산금액</template>
    <form onsubmit="return false;">
      <p class="bu_title">금월정산금액</p>
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header>
            가동일수
          </i-col-header>
          <i-col>
            <iui-text type="number" width="120px" :enable="false" :value="detailInfo.monthlyDay" />
          </i-col>
          <i-col-header>
            총가동시간
          </i-col-header>
          <i-col>
            <iui-text type="number" width="120px" :enable="false" :value="detailInfo.monthlyQty" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            총가동금액
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.monthlyAmt" />
          </i-col>
          <i-col-header>
            유류대
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.oilAmt" />
          </i-col>
        </i-row>
      </iui-container-new>
      <hr />
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header>
            유류대공제
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :value="detailInfo.oilDeductAmt" :enable="false" />
          </i-col>
          <i-col-header>
            기타공제
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :value="detailInfo.etcDeductAmt"
              :enable="cud === 1"
              @change="setEtcDeductAmt(Number($event.target.value))"
            />
          </i-col>
        </i-row>
      </iui-container-new>
      <hr />

      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header>
            공급가
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.amt" />
          </i-col>
          <i-col-header>
            부가세
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :enable="cud === 1"
              :value="detailInfo.vatAmt"
              @change="setVatAmt(Number($event.target.value))"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            비과세
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :enable="cud === 1"
              :value="detailInfo.taxexe"
              @change="setTaxexe(Number($event.target.value))"
            />
          </i-col>
          <i-col-header>
            합계
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.sumAmt" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            세금계산서
          </i-col-header>
          <i-col>
            <pmis-file-list
              title="세금계산서"
              id="flNo"
              :modal="true"
              :fileNumber="detailInfo.flNo"
              :saveAuth="false"
            />
          </i-col>
          <i-col> </i-col>
          <i-col> </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            계산서
          </i-col-header>
          <i-col
            ><pmis-file-list
              title="계산서"
              id="flTaxNo"
              :modal="true"
              :fileNumber="detailInfo.flTaxNo"
              :saveAuth="false"
            />
          </i-col>
          <i-col> </i-col>
          <i-col> </i-col>
        </i-row>
      </iui-container-new>
    </form>
  </pmis-tab-box>
</template>

<script>
export default {
  beforeCreate() {
    $mapGetters(this, 'equipment', ['detailInfo']);
    $mapActions(this, 'equipment', ['setEtcDeductAmt', 'setVatAmt', 'setTaxexe']);
  },
};
</script>

<style scoped>
hr {
  height: 1px;
  border: 0;
  background-color: #ccc;
  margin: 10px 5px;
}
</style>
