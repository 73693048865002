export const selectAnyTimeEqutSearchData = async () =>
  await axios.post('/closeAnyTimeEqut/selectAnyTimeEqutSearchData', {});

export const selectEqutAnmonList = async param => await axios.post('/closeAnyTimeEqut/selectEqutAnmonList', param);

export const selectRegisterEqutDetail = async param =>
  await axios.post('/closeAnyTimeEqut/selectRegisterEqutDetail', param);

export const selectRegisterEqutInfo = async param =>
  await axios.post('/closeAnyTimeEqut/selectRegisterEqutInfo', param);

export const deleteEqutAnmon = async param => await axios.post('/closeAnyTimeEqut/deleteEqutAnmon', param);
