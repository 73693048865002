export default that => {
  return {
    Cfg: {
      HeaderCheck: 1,
    },
    Cols: [
      {
        Name: 'bsnNo',
        Header: '사업자번호',
        Width: 100,
        Type: 'text',
        Align: 'Center',
        CanEdit: false,
      },
      {
        Name: 'frmNm',
        Header: '업체명',
        RelWidth: 1,
        Type: 'Text',
        CanEdit: false,
      },
      {
        Name: 'rpsvNm',
        Header: '대표자명',
        Width: 100,
        Type: 'Text',
        Align: 'Center',
        CanEdit: false,
      },
      {
        Name: 'rpsvPhone',
        Header: '대표전화번호',
        Width: 100,
        Type: 'Text',
        Align: 'Center',
        CanEdit: false,
      },
      {
        Name: 'frmDsNm',
        Header: '거래처유형',
        Width: 100,
        Type: 'Text',
        Align: 'Center',
        CanEdit: false,
      },
    ],
  };
};
