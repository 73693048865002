import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import {PO_COLUMNS, PO_ORDER_COLUMNS} from '@/view/bidding/js/BiddingColumns';
import {selectPoInfos} from '@/view/bidding/po/mat/api/po.js';
import {
  getOrderInfo,
  getOrderList,
  getPoItemListForOrder,
  selectUnitContractList,
} from '@/view/bidding/po/mat/api/order.js';

const state = {
  unitContractList: [],
  poInfo: {...PO_COLUMNS},

  orderList: [],
  orderInfo: {...PO_ORDER_COLUMNS},
  orderItemList: [],

  isNewOrder: false,
};

const getters = {
  bidType: () => 'mat',
  unitContractList: state => state.unitContractList,
  poInfo: state => state.poInfo,

  orderList: state => state.orderList,
  orderInfo: state => state.orderInfo,
  orderItemList: state => state.orderItemList,

  isNewOrder: state => state.isNewOrder,
  canEditOrder: state => !state.orderInfo.sendDt && !state.orderInfo.cusUsrSignDt,
  isOrderSent: state => state.orderInfo.sendDt && !state.orderInfo.cusUsrSignDt,
};

const mutations = {
  setUnitContractList(state, payload) {
    if (Array.isArray(payload)) {
      state.unitContractList = payload;
    }
  },
  setPoInfo(state, payload) {
    for (const key in state.poInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.poInfo[key] = payload[key];
      }
    }
  },
  initPoInfo(state) {
    state.poInfo = {...PO_COLUMNS};
  },
  setOrderList(state, payload) {
    if (Array.isArray(payload)) {
      state.orderList = payload;
    }
  },
  setOrderInfo(state, payload) {
    for (const key in state.orderInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.orderInfo[key] = payload[key];
      }
    }

    state.orderInfo.poNo2 = state.isNewOrder
      ? BIDDING_CONSTANTS.CODES.AUTO
      : COMMON_FUNCTION.getMakeFormat(state.orderInfo.poNo);
  },
  initOrderInfo(state) {
    state.orderInfo = {...PO_ORDER_COLUMNS};
  },
  setOrderItemList(state, payload) {
    if (Array.isArray(payload)) {
      state.orderItemList = payload;
    }
  },
  setIsNewOrder(state, payload) {
    if (typeof payload === 'boolean') {
      state.isNewOrder = payload;
    }
  },
};

const actions = {
  async searchUnitContractList({commit}, searchOptions) {
    const unitContractList = (await selectUnitContractList(searchOptions)).data;
    commit('setUnitContractList', unitContractList);
  },
  async searchUnitContractInfo({commit}, param) {
    commit('initPoInfo');
    commit('initOrderInfo');
    commit('setOrderItemList', []);

    const responseData = (await selectPoInfos(param.poNo, param.contSeq)).data;
    const poInfo = responseData.poInfo;

    commit('setPoInfo', poInfo);
  },
  async searchOrderList({commit}, poNo) {
    commit('setIsNewOrder', false);

    const orderList = (await getOrderList(poNo)).data;
    commit('setOrderList', orderList);
  },
  async searchOrderInfo({commit}, poNo) {
    commit('setIsNewOrder', false);
    commit('initOrderInfo');

    const responseData = (await getOrderInfo(poNo)).data;
    const orderInfo = responseData.orderInfo;
    const orderItemList = responseData.orderItemList ?? [];
    commit('setOrderInfo', orderInfo);
    commit('setOrderItemList', orderItemList);
  },
  async onAddOrder({getters, commit}) {
    const poInfo = getters.poInfo;

    if (!poInfo.poNo) {
      return;
    }

    let orderItemList;

    try {
      orderItemList = (await getPoItemListForOrder(poInfo.poNo)).data;
    } catch (error) {
      return error;
    }

    commit('initOrderInfo');

    const orderInfo = {
      grpPoNo: poInfo.poNo,
      cusCd: poInfo.cusCd,
      spotDeptcd: poInfo.spotDeptcd,
      spotDeptnm: poInfo.spotDeptnm,
      poDt: $_getCurrentDate(),
      payCondRmrk: poInfo.payCondRmrk,
      dlvCondRmrk: poInfo.dlvCondRmrk,
      dlvLoc: poInfo.dlvLoc,
      delayRateYn: poInfo.delayRateYn,
      delayRate: poInfo.delayRate,
    };

    commit('setIsNewOrder', true);
    commit('setOrderInfo', orderInfo);
    commit('setOrderItemList', orderItemList);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
