<template>
  <Execution :isPopup="true" :costType="costType" :eventNms="{onFocusedRowData: 'onFocusedRowDataExcute'}" />
</template>

<script>
import Execution from '@/view/executDtls/components/Execution';
export default {
  components: {
    Execution: Execution,
  },

  beforeCreate() {
    $mapGetters(this, {
      costType: 'costType',
    });
  },
  created() {
    if (!this.costType) this.costType = $getConstants('COST_TYPE_M').code;
  },
};
</script>

<style></style>
