<template>
  <pmis-page ref="page" :search-company-visible="false" :search-project-visible="false">
    <iui-tab :comps="tabList" @after-active-tab="afterActiveTab" style="height: unset;" />
    <pmis-content-box border-box :loading="loading" style="height: calc(100% - 30px)">
      <template #header-left>
        <iui-datepicker
          label="검색기준일"
          :value.sync="searchDt"
          @change="onChangeSearchDt(searchDt)"
          :isIconClear="false"
        />
      </template>
      <template #header-center>
        <i class="prev-arrow" @click="btnMoveCalendar_click('Previous')" />
        <span>
          <div class="headerDate" :style="headerDateStyle">
            <div class="_text" @click="onHeaderTextClick">
              <template v-if="calendarView === 'month'">{{ headerDateMonth }}</template>
              <template v-else-if="calendarView === 'week'">{{ headerDateWeek }}</template>
              <template v-else>{{ headerDateDetail }}</template>
            </div>
            <div class="_calendar">
              <iui-datepicker type="month" @change="onChangeMonth" :open.sync="openDatePicker" />
            </div>
          </div>
        </span>
        <i class="post-arrow" @click="btnMoveCalendar_click('Next')" />
      </template>
      <template #header-right><span /></template>

      <iui-container-new type="css-flex">
        <i-row>
          <i-col>
            <Calendar
              :view="calendarView"
              :date.sync="calDate"
              :schedules="scheduleList"
              @clickSchedule="onClickSchedule"
              @clickDetailButton="onClickDetailButton"
              @afterRenderSchedule="onAfterRenderSchedule"
            />
          </i-col>
          <template v-if="tabIndex === 2">
            <i-spacer />
            <i-col width="550px">
              <ScheduleDetail />
            </i-col>
          </template>
        </i-row>
      </iui-container-new>
    </pmis-content-box>
  </pmis-page>
</template>

<script>
import Calendar from './components/CalendarWrapper.vue';
import ScheduleDetail from './components/DetailSchedule.vue';

import store from './store/Schedule.js';

export default {
  components: {
    Calendar,
    ScheduleDetail,
  },
  data() {
    return {
      tabList: [
        {tabNm: '월간', compNm: 'Monthly'},
        {tabNm: '주간', compNm: 'Weekly'},
        {tabNm: '세부', compNm: 'Detail'},
      ],
      loading: false,
      openDatePicker: false, // datepicker
      tabIndex: 0,

      searchDt: '', // 검색기준일
      calDate: '', // 달력 날짜

      focusParam: {
        scdNo: 0,
        date: '',
      },

      renderedSchedules: [],
    };
  },

  computed: {
    calendarView() {
      return ['month', 'week', 'day'][this.tabIndex];
    },
    headerDateMonth() {
      let date = this.calDate.replace(/-/g, '') || '';
      return `${date.substring(0, 4)}년 ${Number(date.substring(4, 6))}월`;
    },
    headerDateWeek() {
      let date = new Date(dateFormat(this.calDate));
      let stDtOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
      let endDtOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 6);
      return `${stDtOfWeek.getFullYear()}-${stDtOfWeek.getMonth() +
        1}-${stDtOfWeek.getDate()} ~ ${endDtOfWeek.getFullYear()}-${endDtOfWeek.getMonth() +
        1}-${endDtOfWeek.getDate()}`;
    },
    headerDateDetail() {
      let date = this.calDate || $_getCurrentDate();
      var dayChineseChar = ['日', '月', '火', '水', '木', '金', '土'][new Date(dateFormat(date)).getDay()];
      date = date.replace(/-/g, '');
      return `${date.substring(0, 4)}년 ${date.substring(4, 6)}월 ${date.substring(6)}일 (${dayChineseChar}) 세부일정`;
    },
    headerDateStyle() {
      return {
        width: this.calendarView === 'month' ? '97px' : this.calendarView === 'week' ? '170px' : '200px',
        cursor: this.calendarView === 'month' ? 'pointer' : 'default',
      };
    },
  },

  watch: {
    calDate(newDt, oldDt) {
      this.initSchedule();
      let newMonth = new Date(dateFormat(newDt)).getMonth();
      let oldMonth = new Date(dateFormat(oldDt)).getMonth();
      if (newMonth !== oldMonth) {
        this.onSearch();
      }
    },
    isRegistrant() {
      if (this.tabIndex === 2) {
        this.setCommonButton();
      }
    },
  },

  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['scheduleList', 'scheduleInfo', 'isRegistrant']);
    $mapMutations(this, ['setScheduleList', 'setScheduleInfo', 'initSchedule']);
  },
  created() {
    this.searchDt = $_getCurrentDate();

    this.addFuncSearch(this.onChangeSearchDt);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);

    this.addEvent({name: 'ScheduleIndex_onSearch', func: this.onSearch});
  },
  activated() {
    if (Object.keys(this.$store.getters['externalData']).length) {
      this.focusParam = {...this.$store.getters['externalData']};
      this.$store.dispatch('setExternalData', {});
      this.onChangeSearchDt(dateFormat(this.focusParam.date));

      if (this.focusParam.date.length === 6) {
        this.callEvent({name: 'setActiveTab', param: 0});
      } else {
        this.callEvent({name: 'setActiveTab', param: 2});
      }
    }
  },

  methods: {
    onChangeSearchDt(date = '') {
      this.renderedSchedules = [];
      this.calDate = dateFormat(date ? date : this.searchDt);
      if (this.tabIndex === 2) {
        this.focusSchedule();
      }
    },
    async onSearch() {
      this.loading = true;
      let date = new Date(this.calDate);
      let param = {
        pgmCd: this.pgmCd,
        fromDt: new Date(date.getFullYear(), date.getMonth(), 1),
        toDt: new Date(date.getFullYear(), date.getMonth() + 1, 0),
      };
      let response = await axios.post('/collaboration/schedule/selectScheduleList', param);
      let schedules = new Array();
      response.data.forEach(item => {
        if (item.rpttDs == '3' || item.rpttDs == '4') {
          item.rpttSetDs1 = item.rpttSetDs.substring(0, 2);
          item.rpttSetDs2 = item.rpttSetDs.substring(2);
        }

        const schedule = {
          id: item.scdNo,
          calendarId: this.pgmCd,
          category: 'time',
          bgColor: 'white',
          customStyle: 'width: 100px',
          title: item.tl,
          location: item.plc,
          body: item.cts,
          start: item.startDt,
          end: item.endDt,
          isAllDay: item.odaDs === '1',
          raw: item,
        };

        schedules.push(schedule);
      });
      this.setScheduleList(schedules);
      this.loading = false;
    },
    onAdd() {
      this.callEvent({name: 'DetailSchedule_onAdd'});
    },
    onSave() {
      this.callEvent({name: 'DetailSchedule_onSave'});
    },
    onDelete() {
      this.callEvent({name: 'DetailSchedule_onDelete'});
    },
    btnMoveCalendar_click(directive) {
      this.renderedSchedules = [];
      this.callEvent({name: `CalendarWrapper_MoveCalendar${directive}`});
      // 이 시점에 달력의 날짜와 calDate가 변경되었고
      // onAfterRenderSchedule 이벤트가 발생되면서 렌더링된 스케쥴이
      // this.renderedSchedule에 담겨있음.
      if (this.tabIndex === 2) {
        this.focusSchedule();
      }
    },
    onHeaderTextClick() {
      if (this.tabIndex === 0) {
        this.openDatePicker = true;
      }
    },
    onChangeMonth(e) {
      this.onChangeSearchDt(`${e.substring(0, 4)}-${e.substring(4, 6)}-01`);
    },
    focusSchedule() {
      // 포커싱처리 하여 상세정보 세팅
      setTimeout(() => {
        if (this.renderedSchedules.length) {
          const focusSchedule =
            this.renderedSchedules.find(item => item.id === this.focusParam.scdNo) || this.renderedSchedules[0];
          this.setScheduleInfo(focusSchedule.raw);
          this.focusParam.scdNo = 0;
          this.focusParam.date = '';
        }
      }, 100);
    },
    afterActiveTab(index) {
      this.tabIndex = index;
      let useDetailPopup;
      if (index === 2) {
        useDetailPopup = false;
        this.focusSchedule();
      } else {
        useDetailPopup = true;
        this.initSchedule();
      }
      this.callEvent({name: 'CalendarWrapper_ChangeConfig', param: {useDetailPopup}});
      this.setCommonButton();
    },
    setCommonButton() {
      if (this.tabIndex === 2) {
        this.canNew = true;

        if (this.isRegistrant) {
          this.canSave = true;
          this.canDelete = true;
        } else {
          this.canSave = false;
          this.canDelete = false;
        }
      } else {
        this.canNew = false;
        this.canSave = false;
        this.canDelete = false;
      }
    },
    onClickSchedule(e) {
      document.querySelector('.tui-full-calendar-popup').style.display = this.tabIndex === 2 ? 'none' : '';
      if (this.tabIndex === 2) {
        this.setScheduleInfo(e.schedule.raw);
      } else {
        this.renderedSchedules = [];
      }
    },
    onClickDetailButton(e) {
      this.onChangeSearchDt(dateFormat(e.schedule.start));
      this.focusParam['scdNo'] = e.schedule.id;
      this.callEvent({name: 'setActiveTab', param: 2});
    },
    onAfterRenderSchedule(e) {
      if (this.tabIndex === 2) {
        // 상세 탭일때, 포커싱 처리를 하기위해 렌더링된 스케쥴을 담아둠.
        this.renderedSchedules.push(e.schedule);
      }
    },
  },
};
</script>

<style>
.tui-full-calendar-section-button > .tui-full-calendar-popup-edit {
  width: 100%;
}
.tui-full-calendar-section-button > .tui-full-calendar-popup-delete {
  display: none;
}
.tui-full-calendar-section-button > .tui-full-calendar-popup-vertical-line {
  display: none;
}

.headerDate {
  position: relative;
  height: 30px;
  width: '80px';
  cursor: pointer;
}
.headerDate ._text {
  position: absolute;
  top: 6px;
  left: 15px;
  z-index: 99;
}
.headerDate ._calendar {
  position: absolute;
  top: 0px;
  left: 80px;
  z-index: -99;
}
</style>
