<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row height="350px">
              <i-col minWidth="500px">
                <pmis-tab-box>
                  <template v-slot:title>도급내역</template>
                  <Contract
                    @loadChgDegree="loadChgDegreeDg"
                    @maxDegree="setMaxDegreeDg"
                    @chgDegree="setChgDegreeDg"
                    @changeChgDegree="selectContContexecPlanD"
                  />
                </pmis-tab-box>
              </i-col>
              <i-spacer />
              <i-col minWidth="500px">
                <pmis-tab-box>
                  <template v-slot:title>실행내역</template>
                  <template v-slot:title-right>
                    <iui-button value="연계확인" @click="onConfirm" v-if="isMaxDegree" />
                  </template>
                  <Execution
                    @loadChgDegree="loadChgDegreeSi"
                    @maxDegree="setMaxDegreeSi"
                    @chgDegree="setChgDegreeSi"
                    @changeChgDegree="selectContContexecPlanD"
                  />
                </pmis-tab-box>
              </i-col>
            </i-row>
            <i-spacer />
            <i-row>
              <i-col>
                <pmis-tab-box no-border-box no-padding-box>
                  <template v-slot:title>도급/실행연계내역</template>
                  <template v-slot:title-right>
                    <iui-button value="행삭제" @click="onDel" v-if="isMaxDegree && !linked"></iui-button>
                    <iui-button
                      value="배분취소"
                      @click="cancelExecDtlsQtyDstb"
                      v-if="isMaxDegree && linked"
                    ></iui-button>
                    <iui-button
                      value="실행배분"
                      @click="updateExecDtlsQtyDstb"
                      v-if="isMaxDegree && !linked"
                    ></iui-button>
                  </template>
                  <ContExecContact />
                </pmis-tab-box>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from '@/view/executDtls/store/dtlsCntc.js';
import PrjList from '@/view/Project/components/PrjList.vue';
import Contract from '@/view/executDtls/components/Contract.vue';
import Execution from '@/view/executDtls/components/Execution.vue';
import ContExecContact from '@/view/executDtls/components/ContExecContact.vue';
import {
  saveOutsourceLink,
  deleteOutsourceLink,
  updateCancelExecDtlsQtyDstb,
  saveExecDtlsQtyDstb,
} from '../api/dtlsCntc.js';
export default {
  components: {
    PrjList,
    Contract,
    Execution,
    ContExecContact,
  },
  beforeCreate() {
    $init(this, store);

    $mapGetters(this, [
      'contContexecPlanDList',
      'contExeccontPlanDList',
      'deleteContContexecPlanDList',
      'deleteContExeccontPlanDList',
      'contExecContactMap',
      'dgDtlsLinkList',
      'linked',
    ]);

    $mapMutations(this, ['setContExecContactMap']);
  },
  data() {
    return {
      contData: {},
      execData: [],
      loadChgDegreeObj: {},
      wcodeList: [],
      lgCodeList: [],
      mgCodeList: [],
      isMaxDegreeDg: false,
      isMaxDegreeSi: false,
      chgDegreeDg: '',
      chgDegreeSi: '',
      isLinked: false,
    };
  },
  computed: {
    isMaxDegree() {
      return this.isMaxDegreeDg && this.isMaxDegreeSi;
    },
  },

  watch: {
    loadChgDegreeObj: {
      handler(value) {
        if (Object.keys(value).filter(key => String(key).indexOf(this.prjCd) != -1).length === 2) {
          const arrayMsg = [];
          if (!value[`cont_${this.prjCd}`]) {
            arrayMsg.push('도급내역');
          }
          if (!value[`exec_${this.prjCd}`]) {
            arrayMsg.push('실행내역');
          }

          if (arrayMsg.length) {
            this.$alert({title: '내역차수', message: `${arrayMsg.join(',')} 에 확정된 내역차수가 없습니다.`});
            this.initContContexecPlanD();
          } else {
            this.selectContContexecPlanD();
          }
          this.loadChgDegreeObj = {};
        }
      },
      deep: true,
    },
  },

  methods: {
    loadChgDegreeDg(chgDegree) {
      const obj = {};
      obj[`cont_${this.prjCd}`] = chgDegree;
      this.loadChgDegreeObj = {...this.loadChgDegreeObj, ...obj};
    },
    loadChgDegreeSi(chgDegree) {
      const obj = {};
      obj[`exec_${this.prjCd}`] = chgDegree;
      this.loadChgDegreeObj = {...this.loadChgDegreeObj, ...obj};
    },
    setMaxDegreeDg(isMaxDegree) {
      this.isMaxDegreeDg = isMaxDegree;
    },
    setMaxDegreeSi(isMaxDegree) {
      this.isMaxDegreeSi = isMaxDegree;
    },
    setChgDegreeDg(chgDegree) {
      this.chgDegreeDg = chgDegree;
    },
    setChgDegreeSi(chgDegree) {
      this.chgDegreeSi = chgDegree;
    },
    selectContContexecPlanD() {
      const param = {chgDegreeDg: this.chgDegreeDg, chgDegreeSi: this.chgDegreeSi};
      this.callEvent({name: 'selectContContexecPlanD', param: param});
    },
    initContContexecPlanD() {
      this.callEvent({name: 'initContContexecPlanD'});
    },
    async onSave() {
      const response = await saveExecDtlsQtyDstb({chgDegreeDg: this.chgDegreeDg, chgDegreeSi: this.chgDegreeSi});
      if (response.data) {
        this.selectContContexecPlanD();
      }
    },

    async onSaveOutsourceLink() {
      let regArray = [];
      let regDgArray = [];
      let regSiArray = [];
      for (let key in this.contExecContactMap) {
        for (let obj of this.contExecContactMap[key]) {
          regArray.push({
            pgmCd: this.pgmCd,
            prjCd: this.prjCd,
            chgDegreeDg: obj.chgDegreeDg,
            wcodeDg: obj.wcodeDg,
            headCodeDg: obj.headCodeDg,
            midCodeDg: obj.midCodeDg,
            itemSeqDg: obj.itemSeqDg,
            itemCodeDg: obj.itemCodeDg,
            ssizeDg: obj.ssizeDg,
            unitDg: obj.unitDg,
            amtDg: obj.amtDg,
            matGiveYn: obj.matGiveYn,

            wcodeSi: obj.wcodeSi,
            headCodeSi: obj.headCodeSi,
            midCodeSi: obj.midCodeSi,
            itemSeqSi: obj.itemSeqSi,
            chgDegreeSi: obj.chgDegreeSi,
            lgNameSi: obj.lgNameSi,
            itemCodeSi: obj.itemCodeSi,
            itemNameSi: obj.itemNameSi,
            ssizeSi: obj.ssizeSi,
            unitSi: obj.unitSi,
            costType: obj.costType,
          });

          const regItem = regArray[regArray.length - 1];

          regDgArray.push({...regItem, execPrice: obj.execPrice, execQty: obj.execQty, execAmt: obj.execAmt});
          regSiArray.push({...regItem, execPrice: obj.priceDg, execQty: obj.qtyDg, execAmt: obj.amtDg});
        }
      }

      let param = {
        contexecPlanDList: regDgArray,
        execcontPlanDList: regSiArray,
      };

      const response = await saveOutsourceLink(param);
      if (response.data) {
        this.setContExecContactMap({});
        this.selectContContexecPlanD();
      }
    },
    async onDeleteOutsourceLink() {
      let deleteArray = [];
      for (let key in this.contExecContactMap) {
        for (let obj of this.contExecContactMap[key]) {
          let delObj = {};
          delObj['pgmCd'] = this.pgmCd;
          delObj['prjCd'] = this.prjCd;
          delObj['chgDegreeDg'] = obj.chgDegreeDg;
          delObj['chgDegreeSi'] = obj.chgDegreeSi;
          delObj['wcodeDg'] = obj.wcodeDg;
          delObj['wcodeSi'] = obj.wcodeSi;
          delObj['headCodeDg'] = obj.headCodeDg;
          delObj['headCodeSi'] = obj.headCodeSi;
          delObj['midCodeDg'] = obj.midCodeDg;
          delObj['midCodeSi'] = obj.midCodeSi;
          delObj['itemSeqDg'] = obj.itemSeqDg;
          delObj['itemSeqSi'] = obj.itemSeqSi;
          deleteArray.push(delObj);
        }
      }
      let param = {
        dtlsLinkDeleteList: deleteArray,
      };
      const response = await deleteOutsourceLink(param);
      if (response.data) {
        this.setContExecContactMap({});
        this.selectContContexecPlanD();
      }
    },
    onDel() {
      if (!Object.keys(this.contExecContactMap).length) {
        this.$alert({title: '삭제', message: '삭제할 항목을 선택해 주세요.'});
        return;
      }
      this.$confirm({title: '삭제', message: '연계내역을 삭제하시겠습니까?'}, () => {
        this.onDeleteOutsourceLink();
      });
    },
    onPrint() {},
    onConfirm() {
      this.callEvent({
        name: 'getContFocusedRow',
        param: this.setContData,
      });
      this.callEvent({
        name: 'getExecRowsByChecked',
        param: this.setExecData,
      });
      if (!this.contData) {
        this.$alert({title: '연계내역확인', message: '도급내역을 선택해주세요.'});
        return;
      }
      if (!Array.from(this.execData).length) {
        this.$alert({title: '연계내역확인', message: '실행내역을 체크해주세요.'});
        return;
      }

      let param = {contData: this.contData, execData: this.execData, callback: this.onSaveOutsourceLink};
      this.callEvent({name: 'contExecContactAddrow', param: param});
      return;
    },
    setContData(data) {
      this.contData = data;
    },
    setExecData(data) {
      this.execData = data;
    },
    async updateExecDtlsQtyDstb() {
      this.onSave();
    },
    async cancelExecDtlsQtyDstb() {
      const response = await updateCancelExecDtlsQtyDstb({
        chgDegreeDg: this.chgDegreeDg,
        chgDegreeSi: this.chgDegreeSi,
      });
      if (response.data) {
        this.selectContContexecPlanD();
      }
    },
  },
};
</script>

<style scoped></style>
