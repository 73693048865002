<!--
전체 style을 적용하려면 cellStyle을 설정. @/components/Iui/const.js 참조
row의 style을 지정하는 방법 rows [{value: [], style: {}}]
col의 style을 지정하는 방법 rows [{value: [{value: '값', style: {}}], style: {}}]
ex)
rows: [
    {
        value: [
        {value: '공사코드'},
        {value: '대공종코드'},
        {value: '중공종코드'},
        {value: '내역코드'},
        {value: '도급코드'},
        {value: '내역명', style: {align: 'CENTER', width: 7000}},
        {value: '규격'},
        {value: '단위코드'},
        {value: '수량'},
        {value: '재료비단가'},
        {value: '노무비단가'},
        {value: '경비단가'},
        {value: '지급자재여부'},
        ],
        style : style
    },
]
 -->
<template>
  <pmis-content-box :loading.sync="loading">
    <template v-slot:title>
      {{ title }}
    </template>
    <iui-container-new type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>
<script>
import {cellStyle} from '@/components/Iui/const.js';

export default {
  props: {
    title: {
      type: String,
      default: '예제파일',
    },
    fileName: {
      type: String,
      required: true,
    },
    cellStyle: {
      type: Object,
      default: () => {
        return {...cellStyle, init: true};
      },
    },
    rows: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    isRowStyle: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      elId: `sheetDiv${this._uid}`,
      save: false,
      postAction: `${process.env.VUE_APP_API_URL}/excel/upload`,
      internalCellStyle: {},
      internalRows: [],
      link: null,
      size: 0,
      loading: false,
    };
  },

  created() {
    this.addEvent([{name: 'excelDown', func: this.onDown}]);
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.internalCellStyle = {...cellStyle, ...this.cellStyle};
      this.rows.forEach(row => {
        if (Array.isArray(row)) {
          const temp = {value: []};
          row.forEach(col => {
            if (col.style?.type) {
              this.internalCellStyle.type = 'TEXT';
            }

            temp.value.push({value: col, style: this.internalCellStyle});
          });
          if (this.isRowStyle) {
            temp.style = this.internalCellStyle;
          }
          this.internalRows.push(temp);
        } else {
          row.value.forEach(col => {
            if (col.style?.type) {
              this.internalCellStyle.type = 'TEXT';
            }

            col.style = {...this.internalCellStyle, ...(col.style || {})};
          });
          if (this.isRowStyle) {
            row.style = {...this.internalCellStyle, ...(row.style || {})};
          }
          this.internalRows.push(row);
        }
      });
      this.loading = true;
      try {
        const response = await axios.post(
          `/excel/download`,
          {
            rows: this.internalRows,
            dataList: this.data,
          },
          {
            responseType: 'blob',
          }
        );

        this.size = $formatBytes(new Blob([response.data]).size);
        const url = window.URL.createObjectURL(new Blob([response.data], {type: $getMineType('xlsx')}));
        this.link = document.createElement('a');
        this.link.href = url;
        this.link.setAttribute('download', String(this.fileName || new Date().getTime()).split('.')[0] + '.xlsx');
        document.body.appendChild(this.link);
        this.initSheet();
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },
    async initSheet() {
      const OPT = {
        //각 컬럼에 대한 정의 (컬럼의 이름,유형(Type),포맷(Format)등을 설정)
        //컬럼의 "Type"과 "Name" 속성은 반드시 설정되어야 합니다.
        Cols: [
          {
            Header: '파일명',
            Name: 'fileName',
            Type: 'Text',
            RelWidth: 1,
            Visible: 1,
            Cursor: 'pointer',
          },
          {
            Header: '크기',
            Name: 'size',
            Type: 'Text',
            Width: 100,
            Visible: 1,
            Cursor: 'pointer',
          },
        ],
        Cfg: {
          CanEdit: 0,
        },
        Events: {
          onDblClick: e => {
            if (e.row.Kind == 'Data') this.link.click();
          },
          onRenderFirstFinish: () => {
            this.loading = false;
          },
        },
      };

      this.sheet = await PMISSheet.createSheet({
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
        data: [{fileName: this.fileName, size: this.size}],
      });
    },
    onDown() {
      this.link.click();
    },
  },

  beforeDestroy() {
    if (this.link) this.link.remove();
  },
};
</script>

<style></style>
