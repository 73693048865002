import {selectExptMonDetailList, selectExptMonList} from '../api/fixedtimeExpense.js';
const state = {
  searchInfo: {
    investMm: '',
    custName: '',
  },
  detailInfo: {
    custCode: '',
    poNo: '',
    oprOamt: 0,
    oprVat: 0,
    oprFreeAmt: 0,
    sumAmt: 0,
  },
  exptMonList: [],
  calculateList: [],
  closeYn: '',
};

const getters = {
  searchInfo: state => state.searchInfo,
  detailInfo: state => state.detailInfo,
  exptMonList: state => state.exptMonList,
  calculateList: state => state.calculateList,
  closeYn: state => state.closeYn,
  fixedTimeExptSaveDataReqDto: state => {
    const keys = ['investMm', 'custCode', 'poNo', 'oprOamt', 'oprVat', 'oprFreeAmt', 'sumAmt'];
    let param = {};
    for (let key in state.searchInfo) {
      if (keys.findIndex(item => item === key) !== -1) {
        param[key] = state.searchInfo[key];
      }
    }
    for (let key in state.detailInfo) {
      if (keys.findIndex(item => item === key) !== -1) {
        param[key] = state.detailInfo[key];
      }
    }
    return param;
  },
};

const mutations = {
  setSearchInfo(state, payload) {
    for (let key in payload) {
      if (state.searchInfo.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  initDetailInfo(state) {
    let NumberKeys = ['amt', 'vatAmt', 'taxexe', 'sumAmt', 'flNo', 'flTaxNo'];
    for (let key in state.detailInfo) {
      state.detailInfo[key] = NumberKeys.indexOf(key) !== -1 ? 0 : '';
    }
  },

  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = payload[key];
      }
    }
  },
  setExptMonList(state, payload) {
    state.exptMonList = payload;
  },
  initCalculateList(state) {
    state.calculateList = [];
  },
  setCalculateList(state, payload) {
    state.calculateList = payload;
  },
  setSumAmt(state) {
    state.detailInfo.sumAmt = $_getSumAmt(state.detailInfo.oprOamt, state.detailInfo.oprVat);
  },
  setCloseYn(state, closeYn) {
    state.closeYn = closeYn;
  },
};

const actions = {
  async selectExptMonDetailList({state, commit}) {
    const param = {
      investMm: state.searchInfo.investMm,
      custCode: state.detailInfo.custCode,
      poNo: state.detailInfo.poNo,
    };
    const response = await selectExptMonDetailList(param);
    commit('setCalculateList', response.data);
  },

  async selectExptMonList({state, commit}) {
    commit('initDetailInfo');
    commit('initCalculateList');
    const response = await selectExptMonList(state.searchInfo);
    const exptMonList = response.data;
    commit('setExptMonList', exptMonList);
  },

  setCalculateVatAmt({commit}, oprVat) {
    oprVat = Number(oprVat || 0);
    commit('setDetailInfo', {oprVat});
    commit('setSumAmt');
  },
  setCalculateTaxexe({state, commit}, oprFreeAmt) {
    oprFreeAmt = Number(oprFreeAmt || 0);
    let oprVat = $_getVatAmt(state.detailInfo.oprOamt, oprFreeAmt);
    commit('setDetailInfo', {oprVat, oprFreeAmt});
    commit('setSumAmt');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
