export default that => {
  return {
    Cfg: {
      CanEdit: 0,
    },
    Cols: [
      {
        Header: '현장명',
        Name: 'spotDeptnm',
        Type: 'Text',
        Width: 230,
      },
      {
        Header: '입찰공고번호',
        Name: 'rfqNo',
        Type: 'Text',
        Align: 'center',
        Width: 130,
        CustomFormat: v => `${v.substr(8, 8)}-${v.substr(16)}`,
        TextColor: '#FF0000',
        TextStyle: 5,
        Cursor: 'pointer',
      },
      {
        Header: '입찰제목',
        Name: 'ttl',
        Type: 'Text',
        RelWidth: 1,
      },
      {
        Header: '낙찰업체',
        Name: 'cusNm',
        Type: 'Text',
        RelWidth: 1,
      },
      {
        Header: '낙찰일',
        Name: 'endDt',
        Type: 'Text',
        Align: 'center',
        Width: 100,
      },
    ],
  };
};
