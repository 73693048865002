const state = {
  /* 조회정보 */
  searchInfo: {
    pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
    prjCd: '', // 현장코드
    prjNm: '', // 현장명
    ctrtChgTo: 0, // 계약변경차수
    searchNm: '', // 필터명
    endCntrInc: 'N', // 종료포함필터
  },
  /* 계약정보 */
  ctrctInfo: {
    pgmCd: '', //회사코드
    prjCd: '', //현장코드
    prjNm: '', // 현장명
    ctrtChgTo: '', //계약변경차수
    lastChgTo: '', // 최종변경차수
    postNo: '', //우편번호
    adr1: '', //주소
    adr2: '', //상세주소

    contcompCode: '', //원도급사코드
    contcompNm: '', //도급사명
    orderGroup: '', //발주처구분
    orderCode: '', //발주처코드
    orderNm: '', // 발주처명

    workKind: '', //도급종류
    ctrtDate: '', //계약일자
    ctrtAmt: '', //계약금액_공급가
    ctrtVat: '', //계약금액_부가세
    ctrtTaxexe: '', //계약금액_비과세
    ctrtSumAmt: '', //현차수계약금액 합계
    lstSumAmt: '', //최종계약금액 합계
    ctrtStrdate: '', //착공일자
    ctrtEnddate: '', //준공예정일
    conCmplDate: '', //준공일자

    taxRt: '', //비과세비율
    acptCnt: '', //대금지급횟수
    acptDay1: '', //대금지급조건
    acptDay2: '', //준공금지급조건

    prjLocLong: '', //현장위치_위도
    prjLocLati: '', //현장위치_경도
    prjLocAppro: '', //현장위치_승인거리
    empNo1: '', //현장대리인
    empNo1Name: '', //현장대리인명
    empNo2: '', //안전관리자
    empNo2Name: '', //안전관리자명
    empNo3: '', //품질관리자
    empNo3Name: '', //품질관리자명
    flNo: 0, //파일번호
    delYn: '', //삭제여부
    prjFlag: '', //확정Flag
    rmk: '', //기타사항

    prepayDay1: '', // 선급금 지급일1
    prepayDay2: '', // 선급금 지급일2
    prepayAmt: '', // 선급금액
    gisungMonth: '', // 기성금액(월)
    gisungCount: '', // 기성금액(회)
    gisungDay: '', // 기성금액(일)
    payCashYn: '', // 발주처 직접지급 여부
    payCashRatio: '', // 현금지급비율
    payCheckRatio: '', // 어음지급비율
    payCheck2Ratio: '', // 어음대체수단비율
    negoDay1: '', // 대금조정 지급일1
    negoDay2: '', // 대금조정 지급일2
    prfmInsrRate: '', // 계약보증금 비율
    prfmInsrAmt: '', // 계약보증금액
    delayRate: '', // 지체상금율
    delayAmt: '', // 지체상금액
    flawInsrRate: '', // 하자보수보증금율
    flawInsrAmt: '', // 하자보수보증금액
    flawInsrYn: '', // 하자담보책임여부
    flawInsrYear: '', // 하자담보책임 년수
    flawInsrFrDt: '', // 하자담보책임 시작일
    flawInsrToDt: '', // 하자담보책임 종료일
    stampTaxYn: '', // 인지세여부
    stampTaxOwnRate: '', // 인지세비율(당사)
    stampTaxAmt: '', // 인지세액

    rgprId: '', //등록자아이디
    rgsDttm: '', //등록일시
    cud: 0,
  },

  canEditPrjNm: false, // 현장명 수정가능 여부

  /* 보증내역정보 */
  guarantyInfo: {
    pgmCd: '', // 회사코드
    prjCd: '', // 현장코드
    prjNm: '',
    issueSeq: '', // 발급순번
    issueDs: '', // 발급구분
    guarKind: '', // 보증종류
    billNo: '', // 보증서번호
    guarAmt: '', // 보증금액
    guarRt: '', // 보증금율
    issueDate: '', // 발급일자
    guarStrdate: '', // 보증기간_시작일
    guarEnddate: '', // 보증기간_종료일
    guarName: '', // 보증채권자
    guarNameNm: '', // 보증채권자명
    issueOrg: '', // 보증기관
    issueOrgNm: '', // 발급기관명
    rgprId: '', // 등록자아이디
    rgsDttm: '', // 등록일시
    cud: 0,
  },
  custInfo: {
    pgmCd: '',
    prjCd: '',
    bsnNo: '', // 사업자번호
    name: '', // 조직명
    enName: '', // 조직명
    regiNo: '', //주민/법인번호
    rpsvNm: '', // 대표자명
    rpsvEnNm: '', // 대표자명
    rpsvPhone: '', // 대표자 전화번호
    icprNm: '', // 담당자명
    icprPhone: '', // 담당자 전화번호
    email1: '', // 담당자 이메일
    email2: '', // 담당자 이메일
    icprFaxno: '', // 담당자 팩스번호
    postNo: '', // 우편번호
    adr1: '', // 주소
    adr2: '', // 상세주소
    homepage: '',
    bcoNm: '', // 업태
    itemNm: '', // 종목
    frmDs: '', // 거래처유형
    bankName: '', // 은행코드
    depositNo: '', // 계좌번호
    depositor: '', // 예금주
    nationCd: '', // 국가코드
    useCloseDs: '', // 거래종료여부
    ceoCount: '',
  },
  cmpInfo: {
    pgmCd: '', // 회사코드
    name: '', // 회사명(frmNm)
    frmEnNm: '', // 회사명(영문)
    bsnNo: '', // 사업자번호
    frmGb: '', // 회사구분
    regiNo: '', // 주민/법인번호
    rpsvNm: '', // 대표자명
    rpsvEnNm: '', // 대표자명(영문)
    rpsvPhone: '', // 대표전화번호
    postNo: '', // 우편번호
    adr1: '', // 주소
    adr2: '', // 상세주소
    homepage: '', // 홈페이지
    bcoNm: '', // 업태
    itemNm: '', // 종목
    bankCode: '', // 은행코드
    depositNo: '', // 계좌번호
    depositor: '', // 예금주
    lgFlNo: 0, // 회사로고파일번호
    djFlNo: 0, // 관인파일번호
    smFlNo: 0, // 회사심볼파일번호
    flNo: 0, // 파일번호
    slogan: '', // 회사슬로건
    femployInsurDs: '', // 외국인고용보험산출여부
    decimalDs: '', // 일노무비소숫점관리방법
    taxDs: '', // 소득세산출방식
    useFlag: '', // 회사정보승인Flag
    useStrdate: '', // 시작(계약)일자
    useEnddate: '', // 종료일자
  },
  /* 노무팀정보 */
  lbrcTeamInfo: {
    cud: '',
    labCode: '', //노무팀코드
    name: '', //노무팀명(labName)
    bsnNo: '', //사업자번호
    labMNm: '', //팀장명
    labMRegiNo: '', //팀장주민번호
    labMPhone: '', //전화번호
    email1: '', //이메일1
    email2: '', //이메일2
    useCloseDs: '', //삭제flag = 000001 : 삭제상태
    bsnNo1: '',
    bsnNo2: '',
    bsnNo3: '',
    labMRegiNo1: '',
    labMRegiNo2: '',
  },
  /* 사원정보 */
  workerInfo: {
    pgmCd: '', // 회사코드
    empNo: '', // 아이디(사번)
    name: '', // 성명
    regiNo: '', // 주민번호
    regiNo1: '', // 주민번호1
    regiNo2: '', // 주민번호2
    tlno: '', // 전화번호
    mobileTlno: '', // 휴대폰번호
    rpsvFaxno: '', // 팩스번호
    postNo: '', // 우편번호
    adr1: '', // 주소
    adr2: '', // 상세주소
    email1: '', // 이메일1
    email2: '', // 이메일2
    phoFlNo: 0, // 사진파일번호
    laborDs: '', // 근로자구분
    laborDsOrg: '', //근로자구분(변경 이전)
    laborLoc: '', // 근무처
    blnDepNo: '', // 소속
    blnDepNm: '', //소속명
    dtsNo: '', // 직책
    wbusiness: '', // 담당업무
    rollNo: '', // 직급
    rollNm: '', // 직급
    useDs: '', // 시스템사용여부
    useMst: '', // 시스템관리자여부
    useSms: '', // SMS수신동의여부
    pwd: '', // 비밀번호
    pwd2: '', // 비밀번호확인
    sgntFlNo: 0, // 사인파일번호
    labtClose: '', // 근무종료
    priceLGb: '', // 노무단가구분
    priceLAmt: '', // 노무단가
    jkdNo: '', // 노무직종
    pensionDs: '', // 국민연금적용제외여부
    healthInsurDs: '', // 건강보험적용제외여부
    employInsurDs: '', // 고용보험적용제외여부
    healthInsurMDs: '', // 장기요양30%경감대상여부
    bankCode: '', // 은행코드
    depositNo: '', // 계좌번호
    depositor: '', // 예금주
    handicapKind: '', // 장애유형
    handicapGrade: '', // 장애등급
    handicapDate: '', // 장애판정일
    nationCd: '', // 국적
    passportNo: '', // 여권번호
    visaKind: '', // 체류자격
    entryDate: '', // 입국일자
    stayDuration: '', // 체류기간
    cud: 0,
  },
};

const getters = {
  searchInfo: state => state.searchInfo,
  ctrctInfo: state => state.ctrctInfo,
  canEditPrjNm: state => state.canEditPrjNm,
  guarantyInfo: state => state.guarantyInfo,
  cud: state => state.cud,
  custInfo: state => state.custInfo,
  cmpInfo: state => state.cmpInfo,
  lbrcTeamInfo: state => state.lbrcTeamInfo,
  workerInfo: state => state.workerInfo,
};

const actions = {
  //조회정보
  setSearchInfo(context, payload) {
    context.commit('SET_SEARCH_INFO', payload);
    context.commit('SET_CTRCT_INFO', payload);
  },
  //계약정보 초기화
  initCtrctInfo(context) {
    context.commit('INIT_CTRCT_INFO');
    context.commit('SET_CAN_EDIT_PRJ_NM', false);
  },
  //계약정보
  setCtrctInfo(context, payload) {
    context.commit('SET_CTRCT_INFO', payload);
  },
  setCanEditPrjNm(context, payload) {
    context.commit('SET_CAN_EDIT_PRJ_NM', payload);
  },
  //계약정보 현재상태값
  setCtrctCud(context, payload) {
    context.commit('SET_CTRCT_CUD', payload);
  },
  //보증정보 초기화
  initGuarantyInfo(context) {
    context.commit('INIT_GUARANTY_INFO');
  },
  setGuarantyInfo(context, payload) {
    context.commit('SET_GUARANTY_INFO', payload);
  },
};

const mutations = {
  //계약정보 초기화
  INIT_CTRCT_INFO(state) {
    for (let e in state.ctrctInfo) {
      if (e == 'flNo') {
        state.ctrctInfo[e] = 0;
      } else {
        state.ctrctInfo[e] = '';
      }
    }
  },
  //계약정보
  SET_CTRCT_INFO(state, payload) {
    for (let [key, value] of Object.entries(payload)) {
      if (key === 'ctrctWorkKind') {
        state.ctrctInfo.workKind = payload.ctrctWorkKind;
      } else if (key === 'ctrctOrderGroup') {
        state.ctrctInfo.orderGroup = payload.ctrctOrderGroup;
      }

      if (state.ctrctInfo.hasOwnProperty(key)) {
        state.ctrctInfo[key] = payload[key];

        if (key == 'payCashYn') {
          state.ctrctInfo.payCashRatio = '';
          state.ctrctInfo.payCheckRatio = '';
          state.ctrctInfo.payCheck2Ratio = '';
        }
        if (key == 'stampTaxYn') {
          state.ctrctInfo.stampTaxOwnRate = '';
        }
        if (key == 'flawInsrYn') {
          state.ctrctInfo.flawInsrYear = '';
          state.ctrctInfo.flawInsrFrDt = '';
          state.ctrctInfo.flawInsrToDt = '';
        }
      }
    }
  },
  SET_CAN_EDIT_PRJ_NM(state, payload) {
    // 현장명 수정가능 여부 (@/view/bsnsManage/cntrctManage/CntrctInfoRegist/components/DetailInfo.vue)
    state.canEditPrjNm = payload;
  },
  //계약정보 현재상태값
  SET_CTRCT_CUD(state, payload) {
    state.ctrctInfo.cud = payload;
  },
  //보증정보 초기화
  INIT_GUARANTY_INFO(state) {
    for (let e in state.guarantyInfo) {
      state.guarantyInfo[e] = '';
    }
  },
  SET_GUARANTY_INFO(state, payload) {
    for (let key in state.guarantyInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.guarantyInfo[key] = payload[key];
      }
    }
  },
  SET_SEARCH_INFO(state, payload) {
    for (let key in state.searchInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key] ? String(payload[key]) : '';
      }
    }
  },
  setCustInfo(state, payload) {
    for (let key in state.custInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.custInfo[key] = payload[key];
      }
    }
  },
  initCustInfo(state) {
    for (let key in state.custInfo) {
      state.custInfo[key] = '';
    }
  },
  setCmpInfo(state, payload) {
    for (let key in state.cmpInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.cmpInfo[key] = payload[key];
      }
    }
  },
  setLbrcTeamInfo(state, payload) {
    for (let key in state.lbrcTeamInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        if (key == 'labName') {
          state.lbrcTeamInfo[key] = payload['name'];
        } else {
          state.lbrcTeamInfo[key] = payload[key];
        }
      }
    }
  },
  initLbrcTeamInfo(state) {
    for (let key in state.lbrcTeamInfo) {
      state.lbrcTeamInfo[key] = '';
    }
  },
  setWorkerInfo(state, payload) {
    for (let key in state.workerInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.workerInfo[key] = payload[key];
      }
    }
  },
  initWorkerInfo(state) {
    for (let key in state.workerInfo) {
      if (['phoFlNo', 'sgntFlNo', 'cud'].indexOf(key) != -1) {
        state.workerInfo[key] = 0;
      } else {
        state.workerInfo[key] = '';
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
