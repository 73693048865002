<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjAllList />
        </i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <pmis-tab-box no-border-box no-padding-box>
                  <template #title>현장일위대가</template>
                  <template #title-left></template>
                  <template #title-right>
                    <iui-button @click="onPop('breakdownCostCopyPop')">표준대가복사</iui-button>
                    <iui-button @click="onPop('OtherPrjCopyPop')">타현장대가복사</iui-button>
                    <iui-button @click="onCalcBreakdownCost">재계산</iui-button>
                  </template>
                  <DetailList />
                </pmis-tab-box>
              </i-col>
            </i-row>
            <i-spacer />
            <i-row>
              <i-col>
                <pmis-content-box>
                  <template #title>일위대가내역</template>
                  <template #title-left><b>자원구분 [M:재료 L:노무 E:장비, A:경비, I:일위대가]</b></template>
                  <template #title-right>
                    <iui-button :enable="isEnableBtn" @click="onDelByitemDtls">행삭제</iui-button>
                    <iui-button :enable="isEnableBtn" @click="onDtlsPop('breakdownCostCopyDtlsPop')"
                      >대가내역복사
                    </iui-button>
                    <iui-button :enable="isEnableBtn" @click="onDtlsPop('resourceCopyDtlsPop')">자원복사</iui-button>
                    <iui-button :enable="isEnableBtn" @click="onDtlsPop('untpcApplcDtlsPop')">단가적용</iui-button>
                    <iui-button :enable="isEnableBtn" @click="onDtlsPop('arithmeticDtlsPop')" v-if="isFormula"
                      >산식변경
                    </iui-button>
                    <iui-button :enable="isEnableBtn" @click="onDtlsPop('arithmeticDtlsPop')" v-else
                      >산식작성
                    </iui-button>
                  </template>
                  <DetailListDtls />
                </pmis-content-box>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal :name="'breakdownCostCopyPop' + _uid" :btns="modalBtns" sizeType="size3">
      <BreakDownCostCopyPop />
    </iui-modal>

    <iui-modal :name="'OtherPrjCopyPop' + _uid" :btns="modalBtns" sizeType="size1">
      <OtherPrjCopyPop :isCopyOpt="false" />
    </iui-modal>
  </pmis-page>
</template>

<script>
import store from './store/prjBreakdownCost';
import DetailList from './components/DetailList';
import DetailListDtls from './components/DetailListDtls';
import BreakDownCostCopyPop from '@/view/estmtManage/estmtDtlsManage/popup/BreakDownCostCopyPop.vue';
import OtherPrjCopyPop from '@/view/estmtManage/estmtDtlsManage/popup/OtherPrjCopyAsBreakDownPop.vue';
import PrjAllList from '@/view/estmtManage/prjWorkTypeRegist/components/PrjAllList.vue';
import {copyContSctm, copyPrjContSctm} from './api/prjBreakdownCost.js';

export default {
  components: {
    DetailList,
    BreakDownCostCopyPop,
    OtherPrjCopyPop,
    DetailListDtls,
    PrjAllList,
  },

  data() {
    return {
      modalBtns: [{name: '확인', callback: this.onPopConfirm}],
    };
  },

  computed: {
    isEnableBtn() {
      return !!(
        this.detailInfo?.itemCode &&
        String(this.detailInfo.itemCode).length === 12 &&
        this.detailInfo?.itemName
      );
    },
  },

  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['searchInfo', 'isFormula', 'detailInfo']);
  },

  created() {
    this.addFuncPrj(this.initSearch);
  },

  methods: {
    initSearch() {
      this.callEvent({name: 'initSearchNm'});
      this.onSearch();
    },
    onSearch(focusKey) {
      this.callEvent({
        name: 'selectPrjBreakdownCostList',
        param: {focusKey: focusKey},
      });
    },

    onPop(modalNm) {
      this.modalNm = modalNm + this._uid;
      const title =
        this.modalNm == `breakdownCostCopyPop${this._uid}`
          ? '표준대가복사'
          : this.modalNm == `OtherPrjCopyPop${this._uid}`
          ? '타현장대가복사'
          : '';
      this.modalNm = modalNm + this._uid;
      this.$modal.show(this.modalNm, {
        title: title,
      });
    },

    onDtlsPop(modalNm) {
      this.callEvent({name: 'onDtlsPop', param: modalNm});
    },

    onPopConfirm() {
      if (this.modalNm.indexOf('breakdownCostCopyPop') != -1) {
        this.copyContSctm();
      } else if (this.modalNm.indexOf('OtherPrjCopyPop') != -1) {
        this.copyPrjContSctm();
      }
    },

    copyContSctm() {
      this.callEvent({
        name: 'BreakdownCostListPop_popConfirm',
        param: async data => {
          if (0 == data.length) {
            await this.$alert({
              title: `표준대가복사`,
              message: `일위대가가 선택되지 않았습니다.`,
            });
            return;
          }
          let itemCodeList = [];

          data.forEach(item => {
            itemCodeList.push(item.itemCode);
          });
          const param = {
            itemCodeList,
          };
          const response = await copyContSctm(param);
          if (response.data) {
            this.onSearch();
          }
        },
      });
    },

    copyPrjContSctm() {
      this.callEvent({
        name: 'getFocusedRowByOtherPrjCopyPop',
        param: async row => {
          const response = await copyPrjContSctm({targetPrjCd: row.prjCd});
          if (response.data) {
            this.onSearch();
          }
        },
      });
      this.$modal.hide(this.modalNm);
    },

    onDelByitemDtls() {
      this.callEvent({name: 'deleteRowAsPrjBreakDownDtl'});
    },

    onCalcBreakdownCost() {
      this.callEvent({name: 'calcBreakdownCost'});
    },
  },
};
</script>

<style scoped>
.box_w1 {
  height: calc(100vh - 567px) !important;
}
</style>
