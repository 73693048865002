<template>
  <pmis-content-box :loading="loading">
    <template v-slot:header-left>
      <iui-text type="text" :value="searchNm" @change="onChangeSearch" @enter="searchByItemNm" />
      <iui-button value="검색" @click="searchByItemNm" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onSearchFinish: onSearchFinish,
        onClick: onClick,
        onDblClick: onDblClick,
      }"
    />
  </pmis-content-box>
</template>

<script>
import options from '../../stdWorkTypeRegist/components/sheetOption/WorkTypeClSheet.js';
import {selectOprClList, deleteOprCl} from '../api/OprCodeRegist.js';
export default {
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
    base: {
      type: Boolean,
      default: false,
    },
    confirm: {
      type: Boolean,
      default: false,
    },
    oprClInfo: {
      type: Object,
      default: () => ({}),
    },
    focusKey: {
      type: String,
    },
  },
  data() {
    return {
      sheet: undefined,
      sheetUtil: undefined,
      Options: options(this),
      loadSearchData: [],
      loading: false,
      searchNm: '',
      returnCols: ['code', 'name', 'lpCode', 'lpName', 'mpCode', 'mpName', 'level'],
    };
  },
  watch: {
    confirm(value) {
      if (value) {
        this.callback(this.getRowsByChecked());
        this.$emit('update:confirm', false);
      }
    },
  },
  created() {
    this.onSearch();
  },

  methods: {
    async onSearch() {
      const response = await selectOprClList({base: this.base});
      let tree = $_treeModelStdCl(
        response.data,
        $getConstants('OPR_CL').code,
        'code',
        'upCode',
        undefined,
        undefined,
        'name'
      );
      this.loadSearchData = tree({level: 1});
      this.$emit('update:search', false);
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
      if (this.base) {
        this.sheetUtil.onTreeCheck('chk');
      }
    },
    searchInTree(lowestCode) {
      this.sheetUtil.treeSearch('code', lowestCode);
    },
    onChangeSearch(e) {
      this.searchNm = e.target.value;
    },
    onSearchFinish(e) {
      this.loading = false;
      if (0 < e.sheet.getTotalRowCount()) {
        e.sheet.showTreeLevel(3, 0, 1);
      }
      let row;
      if (this.focusKey) {
        this.sheetUtil.treeFocus('mpCode', this.focusKey);
        row = e.sheet.getFocusedRow();
        this.$emit('update:focusKey', undefined);
      } else {
        row = e.sheet.getFirstRow();
      }
      this.onCallback(this.rowsFilter(row));
    },
    rowsFilter(row) {
      let rowObj = {};
      for (let key in row) {
        if (this.returnCols.indexOf(key) != -1) {
          rowObj[key] = row[key];
        }
      }
      return rowObj;
    },
    onClick(e) {
      this.setCud('0');
      this.initSearchNm();
      this.onCallback(this.rowsFilter(e.row));
    },
    onDblClick() {
      this.$modalConfirm();
    },
    async onDelete() {
      let row = this.sheet.getFocusedRow();
      if (row === null) {
        this.$alert({title: '삭제', message: '삭제대상이 존재하지 않습니다.'});
        return;
      }

      let name = String(row.name);
      let level = Number(row.level);
      if (level === 1) {
        this.$alert({title: '삭제', message: '최상위 항목은 삭제할 수 없습니다.'});
        return;
      }

      let workLevel = level == 2 ? '대분류' : '중분류';
      let message = `* 삭제대상 : ${name} [${workLevel}]<br/>해당 경비 삭제 시 하위 경비들도 모두 삭제됩니다.<br/>삭제하시겠습니까?`;
      if (
        !(await this.$confirm({
          title: '삭제',
          message: message,
        }))
      ) {
        return;
      }

      let param = {
        level: row.level,
        code: row.code,
        lpCode: row.lpCode,
        mpCode: row.mpCode,
        base: this.base,
      };

      try {
        const response = await deleteOprCl(param);
        if (response.data) {
          let focusedRow = this.sheet.getFocusedRow();
          this.sheet.removeRow(focusedRow);
          let upRow = this.sheet.getDataRows().find(row => row.code == focusedRow.upCode);
          if (upRow) {
            this.sheet.focus(upRow);
            this.onCallback(this.rowsFilter(upRow));
          }
          return param;
        }
      } catch (error) {
        this.$alert({title: '삭제실패', message: error.message});
      }
    },
    onReloadSheet() {
      const row = this.sheet.getFocusedRow();
      if (this.cud === 1 && row.level < 3) {
        let addRow = this.sheet.addRow({
          parent: row,
          init: {
            ...this.oprClInfo,
            pgmCd: row.pgmCd,
            level: row.level + 1,
            code: this.oprClInfo.code,
            name: this.oprClInfo.name,
            upCode: row.code,
            upName: row.name,
          },
        });
        this.sheet.acceptChangedData(addRow);
        this.onCallback(this.rowsFilter(addRow));
      } else if (this.cud === 2) {
        if (row.level === 2) {
          this.sheet.setValue(row, 'name', this.oprClInfo['lpName'], 1);
        } else if (row.level === 3) {
          this.sheet.setValue(row, 'name', this.oprClInfo['mpName'], 1);
        }
        this.sheet.acceptChangedData(row);
      }
      this.setCud('0');
    },
    getRowsByChecked() {
      return this.sheet.getRowsByChecked('chk').map(row => this.rowsFilter(row));
    },
    searchByItemNm() {
      this.$emit('searchByItemNm', this.searchNm);
    },
    initSearchNm() {
      this.searchNm = '';
    },
    onCallback(data) {
      this.callback(data);
    },
  },
};
</script>

<style scoped></style>
