<template>
  <pmis-content-box>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onSearchFinish, onClick, onBeforeChange, onAfterChange}"
    />
  </pmis-content-box>
</template>

<script>
import padStart from 'lodash/padStart';
import options from './sheetOption/DetailListSheet.js';
import {
  deleteContExecPlanList,
  deleteContExecPlanValidList,
  saveContContPlan,
  selectContContexecPlanOfDegreeList,
  selectContExecPlanListAndInfo,
  selectNextItemSeq,
} from '../api/executDtls.js';
import {
  getDuplicateRow,
  getNextRow,
  getScopeInRows,
  incrementValue,
  nextItemSeq,
  onCalculator,
  onFormula,
  updatePriceAsSameItem,
} from '@/util/dtlsUtil.js';
import {selectCountPrjEst} from '@/view/estmtManage/estmtPrjManage/api/estmtPrjRegist.js';

export default {
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this), 2, 'chk', {
        CanEditFormula: () => this.confirm,
      }),
      loadSearchData: [],
      code0265: '',
      focusKey: undefined,
    };
  },

  computed: {
    confirm: function() {
      return this.masterInfo.prjFlag != this.code0265 && this.currentDegree == this.maxDgree;
    },
  },

  beforeCreate() {
    $mapGetters(this, ['detailInfo', 'isFormula', 'currentDegree', 'maxDgree', 'masterInfo']);
    $mapMutations(this, [
      'setDetailInfo',
      'setFormula',
      'setMasterInfo',
      'setDegreeList',
      'setCurrentDegree',
      'setIsEstmtPrj',
    ]);
    $getCode($getConstants('CONFIRM_FLAG').code).then(response => (this.code0265 = response[0].code));
  },

  created() {
    this.addEvent([{name: 'findBreakdown', func: this.findBreakdown}]);
    this.addFuncPrj(this.onInitSearch);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onSearchFinish() {
      let row;
      if (this.focusKey) {
        row = this.sheet.getDataRows().find(row => String(row.midCode) + String(row.originItemSeq) === this.focusKey);
        this.sheet.focus(row);
        this.setData(row);
        if (String(row.itemCode).startsWith('S')) {
          this.callEvent({name: 'ArithmeticPop_setData', param: {itemCode: row.itemCode}});
        }
        this.focusKey = undefined;
      } else {
        row = this.sheet.getFirstRow();
        this.setDetailInfo(row);
      }
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.setData(e.row);
      }
    },
    setData(row) {
      this.setDetailInfo(row);
      this.setFormula(String(row.itemCode).startsWith('S'));
      const param = {
        costType: row.costType,
        itemCode: row.itemCode,
        itemName: row.itemName,
        ssize: row.ssize,
        unit: row.unit,
        qty: row.qty,
        matPrice: row.matPrice,
        matAmt: row.matAmt,
        labPrice: row.labPrice,
        labAmt: row.labAmt,
        equipPrice: row.equipPrice,
        equipAmt: row.equipAmt,
        totalPrice: row.totalPrice,
        totalAmt: row.totalAmt,
      };
      this.callEvent({name: 'setBreakDownData', param: param});
    },
    onBeforeChange(e) {
      if (e.col === 'itemSeq') {
        const val = padStart(e.val, 5, '0');
        if (e.oldval === val) {
          return val;
        }

        let isDup = getDuplicateRow(e.sheet, 'itemSeq', val);
        if (isDup) {
          this.$alert({title: '내역번호', message: '내역번호는 중복을 허용할 수 없습니다.'});
          return e.oldval;
        } else {
          return val;
        }
      }
      if (e.col.toLowerCase().includes('price')) {
        updatePriceAsSameItem(e);
      }
    },
    onAfterChange(e) {
      if (e.col === 'chk' && e.row.workType) {
        if (String(e.row.itemCode) === this.prjCd) {
          e.sheet.getDataRows().forEach(row => this.sheet.setCheck(row, 'chk', e.val, 0));
          return;
        }
        let clCode;
        if (e.row.itemCode.length === 1) {
          clCode = 'wcode';
        }
        if (e.row.itemCode.length === 2) {
          clCode = 'headCode';
        }
        if (e.row.itemCode.length === 3) {
          clCode = 'midCode';
        }

        getScopeInRows(this.sheet, clCode, e.row).forEach(row => this.sheet.setCheck(row, 'chk', e.val, 0));
      }
    },
    findBreakdown(param) {
      const row = this.sheet
        .getDataRows()
        .find(
          row => String(row.itemCode) === String(param.itemCode) && row.costType === $getConstants('COST_TYPE_I').code
        );
      if (!row) {
        param.callback(undefined);
        return;
      }
      this.sheet.util.listSearch('itemCode', String(param.itemCode), true);
      const data = {
        costType: row.costType,
        itemCode: row.itemCode,
        itemName: row.itemName,
        ssize: row.ssize,
        unit: row.unit,
        qty: row.qty,
        matPrice: row.matPrice,
        matAmt: row.matAmt,
        labPrice: row.labPrice,
        labAmt: row.labAmt,
        equipPrice: row.equipPrice,
        equipAmt: row.equipAmt,
        totalPrice: row.totalPrice,
        totalAmt: row.totalAmt,
      };
      param.callback(data);
    },
    async onInitSearch() {
      const responsePrjEst = await selectCountPrjEst({prjEstCd: this.prjCd});
      this.setIsEstmtPrj(responsePrjEst.data > 0);
      const responseDegree = await selectContContexecPlanOfDegreeList({});
      this.setDegreeList(responseDegree.data);
      this.setCurrentDegree(this.maxDgree);
      this.onSearch();
      this.callEvent({name: 'initBreakDownData'});
    },
    async onSearch() {
      const param = {
        chgDegree: this.currentDegree,
      };
      const response = await selectContExecPlanListAndInfo(param);
      this.loadSearchData = response.data.dtlsList ?? [];
      this.setMasterInfo(response.data.contexecPlanMResDto);
    },
    async onSave() {
      const rows = this.sheet.getSaveJson().data.filter(row => !row.workType || row.workType == '0');
      if (rows.length) {
        let isValid = true;
        let itemName;
        rows.forEach(item => {
          if (!item.itemSeq) {
            itemName = item.itemName;
            isValid = false;
          }
          if (item.matGiveYn == 1) {
            item.matGiveYn = $getConstants('MAT_GIVE_Y').code;
          } else {
            item.matGiveYn = '';
          }
          item.chgDegree = this.currentDegree;
        });

        if (!isValid) {
          this.$alert({title: '저장', message: `${itemName}의 내역번호를 입력해주세요.`});
          return;
        }

        const focusedRow = this.sheet.getFocusedRow();
        this.focusKey = String(focusedRow.midCode) + String(focusedRow.originItemSeq);

        let contexecPlanDtlsList = $_statusToCud(rows);
        const response = await saveContContPlan({contexecPlanDtlsList});
        if (response.data) {
          this.onSearch();
        }
      }
    },
    async onDelete() {
      const checkedRows = this.sheet.getRowsByChecked('chk').filter(row => !row.workType);
      if (!checkedRows.length) {
        this.$alert({title: '삭제', message: '삭제할 내역을 선택해 주세요'});
        return;
      }

      if (checkedRows.filter(x => !x.Added).length > 0 && this.sheet.getDataRows().filter(x => x.Added).length > 0) {
        this.$alert({title: '삭제', message: '저장하지 않은 데이터가 있어 삭제할 수 없습니다.'});
        return;
      }

      if (checkedRows.length === checkedRows.filter(x => x.Added).length) {
        checkedRows.forEach(row => this.sheet.removeRow(row));
      } else {
        const param = {
          chgDegree: this.maxDgree,
          contexecPlanDtlsList: checkedRows.map(row => ({
            wcode: row.wcode,
            headCode: row.headCode,
            midCode: row.midCode,
            itemSeq: row.originItemSeq,
          })),
        };

        const validResponse = await deleteContExecPlanValidList(param);
        if (validResponse.data.length) {
          const validKeyList = validResponse.data.map(item => String(item.midCode).concat(String(item.itemSeq)));
          const validList = this.sheet
            .getDataRows()
            .filter(row => validKeyList.indexOf(String(row.midCode).concat(String(row.itemSeq))) !== -1);

          param.contexecPlanDtlsList = param.contexecPlanDtlsList.filter(
            row => validKeyList.indexOf(String(row.midCode).concat(String(row.itemSeq))) === -1
          );

          if (param.contexecPlanDtlsList.length) {
            const message = `${validList
              .map(row => row.itemName)
              .join(
                ','
              )} 내역의 이미 투입된 수량이 존재하여 삭제할 수 없습니다.<br> 해당 항목을 제외하고 삭제하시겠습니까?`;
            if (!(await this.$confirm({title: '삭제', message: message}))) {
              return;
            }
          } else {
            const message = `${validList
              .map(row => row.itemName)
              .join(',')} 내역의 이미 투입된 수량이 존재하여 삭제할 수 없습니다.`;
            this.$alert({title: '삭제', message: message});
            return;
          }
          validList.forEach(row => this.sheet.setCheck(row, 'chk', 0, 0));
        } else {
          if (!(await this.$confirm({title: '삭제', message: '삭제하시겠습니까?'}))) {
            return;
          }
        }

        const response = await deleteContExecPlanList(param);
        if (response.data) {
          this.onSearch();
        }
      }
    },
    resourceCopy(costType) {
      this.callEvent({
        name: `ResourceListPop_popConfirm_${costType}`,
        param: async data => {
          let _itemSeq = await this.nextItemSeq(this.sheet);
          let nextRow = getNextRow(this.sheet);
          let index = 0;
          data.forEach(item => {
            let isDup = getDuplicateRow(this.sheet, 'itemCode', item.itemCode2);
            if (isDup) {
              return;
            }
            const itemSeq = padStart(Number(_itemSeq) + index * incrementValue, 5, '0');
            const initData = {
              itemSeq: itemSeq,
              originItemSeq: itemSeq,
              wcode: this.detailInfo.wcode,
              headCode: this.detailInfo.headCode,
              midCode: this.detailInfo.midCode,
              chgDegree: this.maxDgree,
              itemCode: item.itemCode2,
              itemName: item.itemName,
              costType: item.costType,
              ssize: item.ssize,
              unit: item.unit,
              matPrice: item.matAmt,
              labPrice: item.labAmt,
              equipPrice: item.equipAmt,
              oprPrice: item.oprAmt,
              price: item.amt,
            };
            this.sheet.addRow({next: nextRow, init: initData, focus: 0});
            index++;
          });
        },
      });
    },
    airithmetic() {
      this.callEvent({
        name: 'ArithmeticPop_popConfirm',
        param: async data => {
          const amtObj = {}; // 자원구분별 금액
          let formula; // 산식
          const typeMap = {
            [$getConstants('COST_TYPE_M').code]: 1,
            [$getConstants('COST_TYPE_L').code]: 2,
            [$getConstants('COST_TYPE_E').code]: 3,
            [$getConstants('COST_TYPE_A').code]: 4,
            [$getConstants('COST_TYPE_T').code]: 5,
          };
          this.sheet
            .getDataRows()
            .filter(row => this.detailInfo.midCode === row.midCode && !!row.itemSeq && row.costType !== 'I')
            .some(row => {
              if (!row.itemCode || !!row.formula) {
                return false;
              }

              let formatArray = [];
              let format;
              formula = data.formula.replace(/ /g, '');
              do {
                const pattern = `[${$getConstants('COST_TYPE_M').code}|${$getConstants('COST_TYPE_L').code}|${
                  $getConstants('COST_TYPE_E').code
                }|${$getConstants('COST_TYPE_A').code}|${$getConstants('COST_TYPE_T').code}]`;
                format = RegExp(`${pattern}"([^"])*"|${pattern}`).exec(formula);
                if (format) {
                  formatArray.push(format[0]);
                  formula = formula.replace(formatArray[formatArray.length - 1], `{${typeMap[format[0].slice(0, 1)]}}`);
                }
              } while (format);
              formatArray.forEach(e1 => {
                if (e1.indexOf('"') != -1) {
                  let costType = e1.slice(0, 1);
                  let costFormat = e1.slice(1).replace(/"/g, '');
                  if (row.costType == costType) {
                    costFormat.split(',').forEach(e2 => {
                      if (e2.indexOf('-') != -1) {
                        if (
                          Number(row.itemSeq) >= Number(e2.split('-')[0]) &&
                          Number(row.itemSeq) <= Number(e2.split('-')[1])
                        ) {
                          if (costType == $getConstants('COST_TYPE_M').code) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0);
                          } else if (costType == $getConstants('COST_TYPE_L').code) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.labAmt || 0);
                          } else if (
                            costType == $getConstants('COST_TYPE_E').code ||
                            costType == $getConstants('COST_TYPE_A').code
                          ) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.equipAmt || 0);
                          } else {
                            amtObj[e1] =
                              (amtObj[e1] || 0) + (row.matAmt || 0) + (row.labAmt || 0) + (row.equipAmt || 0);
                          }
                        }
                      } else {
                        if (row.itemSeq == e2) {
                          if (costType == $getConstants('COST_TYPE_M').code) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0);
                          } else if (costType == $getConstants('COST_TYPE_L').code) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.labAmt || 0);
                          } else if (
                            costType == $getConstants('COST_TYPE_E').code ||
                            costType == $getConstants('COST_TYPE_A').code
                          ) {
                            amtObj[e1] = (amtObj[e1] || 0) + (row.equipAmt || 0);
                          } else {
                            amtObj[e1] =
                              (amtObj[e1] || 0) + (row.matAmt || 0) + (row.labAmt || 0) + (row.equipAmt || 0);
                          }
                        }
                      }
                    });
                  }
                } else {
                  let costType = e1;
                  if (row.costType == e1 || e1 == $getConstants('COST_TYPE_T').code) {
                    if (costType == $getConstants('COST_TYPE_M').code) {
                      amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0);
                    } else if (costType == $getConstants('COST_TYPE_L').code) {
                      amtObj[e1] = (amtObj[e1] || 0) + (row.labAmt || 0);
                    } else if (
                      costType == $getConstants('COST_TYPE_E').code ||
                      costType == $getConstants('COST_TYPE_A').code
                    ) {
                      amtObj[e1] = (amtObj[e1] || 0) + (row.equipAmt || 0);
                    } else {
                      amtObj[e1] = (amtObj[e1] || 0) + (row.matAmt || 0) + (row.labAmt || 0) + (row.equipAmt || 0);
                    }
                  }
                }
              });
            });
          let addRow;
          let itemSeq;
          let wcode = this.detailInfo.wcode;
          let headCode = this.detailInfo.headCode;
          let midCode = this.detailInfo.midCode;
          if (this.isFormula) {
            addRow = this.sheet.getFocusedRow();
            itemSeq = addRow.originItemSeq;
          } else {
            itemSeq = await this.nextItemSeq(this.sheet);
            let nextRow = getNextRow(this.sheet);
            addRow = this.sheet.addRow(nextRow);
          }

          this.sheet.setValue(addRow, 'itemSeq', itemSeq, 1);
          this.sheet.setValue(addRow, 'originItemSeq', itemSeq, 1);
          this.sheet.setValue(addRow, 'wcode', wcode, 1);
          this.sheet.setValue(addRow, 'headCode', headCode, 1);
          this.sheet.setValue(addRow, 'midCode', midCode, 1);
          this.sheet.setValue(addRow, 'chgDegree', this.maxDgree, 1);
          this.sheet.setValue(addRow, 'costType', data.costType, 1);
          this.sheet.setValue(addRow, 'itemName', data.itemName, 1);
          this.sheet.setValue(addRow, 'ssize', data.ssize, 1);
          this.sheet.setValue(addRow, 'unit', data.unit, 1);
          this.sheet.setValue(addRow, 'qty', data.qty, 1);
          this.sheet.setValue(addRow, 'formula', data.formula, 1);

          let formatArray = [];
          let format;
          formula = data.formula.replace(/ /g, '');
          do {
            const pattern = `[${$getConstants('COST_TYPE_M').code}|${$getConstants('COST_TYPE_L').code}|${
              $getConstants('COST_TYPE_E').code
            }|${$getConstants('COST_TYPE_A').code}|${$getConstants('COST_TYPE_T').code}]`;
            format = RegExp(`${pattern}"([^"])*"|${pattern}`).exec(formula);
            if (format) {
              formatArray.push(format[0]);
              formula = formula.replace(formatArray[formatArray.length - 1], amtObj[format[0]]);
            }
          } while (format);

          let price;
          if (!formula) {
            price = 0;
          } else {
            price = Number($_strToCalculator(formula).toFixed(3));
          }

          this.sheet.setValue(addRow, 'matPrice', 0, 1);
          this.sheet.setValue(addRow, 'matAmt', 0, 1);
          this.sheet.setValue(addRow, 'labPrice', 0, 1);
          this.sheet.setValue(addRow, 'labAmt', 0, 1);
          this.sheet.setValue(addRow, 'equipPrice', 0, 1);
          this.sheet.setValue(addRow, 'equipAmt', 0, 1);

          if (data.costType == $getConstants('COST_TYPE_M').code) {
            this.sheet.setValue(addRow, 'matPrice', price, 1);
            this.sheet.setValue(addRow, 'matAmt', data.qty * price, 1);
          } else if (data.costType == $getConstants('COST_TYPE_L').code) {
            this.sheet.setValue(addRow, 'labPrice', price, 1);
            this.sheet.setValue(addRow, 'labAmt', data.qty * price, 1);
          } else if (
            data.costType == $getConstants('COST_TYPE_E').code ||
            data.costType == $getConstants('COST_TYPE_A').code
          ) {
            this.sheet.setValue(addRow, 'equipPrice', price, 1);
            this.sheet.setValue(addRow, 'equipAmt', data.qty * price, 1);
          }

          if (this.isFormula) {
            this.setDetailInfo(addRow);
          }

          if (addRow) {
            this.focusKey = String(addRow.midCode) + String(addRow.originItemSeq);
          }
        },
      });
    },
    calculator() {
      onFormula(this.sheet, false);
      onCalculator(this.sheet, this.prjCd);
    },
    async nextItemSeq() {
      let itemSeq;
      if (getScopeInRows(this.sheet).filter(row => row.Added).length) {
        itemSeq = padStart(nextItemSeq(this.sheet), 5, '0');
      } else {
        const response = await selectNextItemSeq({
          chgDegree: this.maxDgree,
          midCode: this.sheet.getFocusedRow().midCode,
        });
        itemSeq = response.data;
      }

      return itemSeq;
    },
    validation() {
      if (this.sheet.hasChangedData()) {
        this.$alert({title: '내역확정', message: '변경된 내역이 존재합니다. <br> 저장을 먼저 해 주세요.'});
        return false;
      }
      const noAmtRow = this.sheet.getDataRows().filter(row => row.itemSeq && !row.amt && !row.matGiveYn);
      if (noAmtRow.length) {
        this.$alert({title: '내역확정', message: '금액이 없는 내역이 존재합니다.'});
        this.sheet.focus(noAmtRow[0]);
        return false;
      }
      return true;
    },
  },
};
</script>
