export default that => {
  return {
    Cfg: {
      CanEdit: 1,
      SearchMode: 0,
    },
    Cols: [
      {
        Header: '문서번호',
        Name: 'dpDocNo',
        Type: 'Text',
        Width: 160,
        CanEdit: 0,
      },
      {
        Header: '문서제목',
        Name: 'tl',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 200,
        CanEdit: 0,
      },
      {
        Header: '기안(접수)자',
        Name: 'name',
        Type: 'Text',
        Align: 'Center',
        Width: 90,
        CanEdit: 0,
      },
      {
        Header: '보낸일자',
        Name: 'enfcDt',
        Extend: that.$ibsheetHelper.preset.date,
        Width: 100,
        CanEdit: 0,
      },
    ],
  };
};
