<template>
  <pmis-tab-box>
    <template v-slot:title>집행목록</template>
    <pmis-content-box>
      <template v-slot:header-left>
        <div>집행일자</div>
        <iui-datepicker :value="searchFrDt" @change="searchFrDt = $event" :group="{grpNm: 'searchDt', seq: 1}" />
        <div>~</div>
        <iui-datepicker :value="searchToDt" @change="searchToDt = $event" :group="{grpNm: 'searchDt', seq: 2}" />
        <iui-button value="검색" @click="onSearch" />
      </template>
      <template v-slot:header-right>
        <iui-button @click="onPopAddExcel" value="엑셀등록" />
      </template>
      <ib-sheet
        :uid="_uid"
        :options="Options"
        :loadSearchData="loadSearchData"
        :events="{
          onSearchFinish,
          onClick,
        }"
      />
    </pmis-content-box>

    <iui-modal name="excelUploadPop" title="엑셀등록" :btns="excelBtns" sizeType="size1">
      <ExcelUpload
        uri="/expenseRegist/excelUpload"
        title="경비투입등록"
        :rows="excelRows"
        @error="onExcelUploadError"
      />
    </iui-modal>
    <iui-modal name="ExcelUploadResultPop" title="엑셀 업로드 실패 사유" sizeType="size6" @closed="resultList = []">
      <ExcelUploadResult :sheet-opt="excelSheetOpt" :list="resultList" />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import {selectExpenseList} from '../api/expenseRegist.js';
import options from './sheetOptions/ExpenseListSheet.js';
import ExcelUploadResult from '@/components/popup/ExcelUploadResult.vue';
import ExcelUpload from '@/components/popup/ExcelUpload.vue';
import excelOptions from '@/view/Resource/Expense/ExpenseRegist/components/sheetOptions/ExcelUploadResultSheet';
import {selectCustomerListPopup} from '@/view/customer/api/customer';
import EXCEL_CONSTANTS from '@/constants/excelConstants.js';

export default {
  components: {ExcelUpload, ExcelUploadResult},
  created() {
    this.addEvent([{name: 'ExpenseList_ReSearch', func: this.onReSearch}]);
    this.addFuncPrj(this.onSearch);
    this.setExcelRows();
  },
  data() {
    return {
      Options: options(this),
      loadSearchData: [],

      searchFrDt: '',
      searchToDt: '',

      focusKey1: '',
      focusKey2: '',
      excelBtns: [{name: '확인', callback: this.onPopConfirmExcel}],
      resultList: [],
      excelRows: [],
      excelSheetOpt: excelOptions(this),
    };
  },
  methods: {
    async setExcelRows() {
      const checkList = [];
      const response = await selectCustomerListPopup({pgmCd: this.pgmCd});
      if (response.data) {
        response.data.forEach(x => {
          checkList.push({name: x.frmNm, value: x.bsnNo});
        });
      }

      this.excelRows = [
        {
          value: [
            {value: '집행일자', style: {type: 'TEXT'}},
            {value: '실행중공종코드'},
            {value: '실행내역코드', style: {type: 'TEXT'}},
            {value: '자원코드', style: {type: 'TEXT'}},
            {
              value: '거래구분',
              validate: {excelEnum: EXCEL_CONSTANTS.COMMON_CODE, pCode: $getConstants('ACCOUNT_GB').code},
            },
            {
              value: '거래처',
              validate: {
                withDropdown: false,
                checksName: false,
                checkList: checkList,
              },
            },
            {value: '외주 계약번호'},
            {value: '공급가'},
            {value: '부가세액'},
            {value: '비과세액'},
            {value: '적요'},
          ],
        },
      ];
    },
    onSearchFinish(e) {
      if (0 < e.sheet.getTotalRowCount()) {
        let focusedRow = e.sheet.getDataRows().find(row => {
          return row.workDt == this.focusKey1 && row.seq == this.focusKey2;
        });
        if (focusedRow !== undefined) {
          e.sheet.focus(focusedRow);
          this.onSetDetail(focusedRow);
          this.focusKey1 = '';
          this.focusKey2 = '';
        } else {
          this.onSetDetail(e.sheet.getFirstRow());
        }
      } else {
        this.onSetDetail(undefined);
      }
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.onSetDetail(e.row);
      }
    },
    async onSearch() {
      const param = {
        searchFrDt: this.searchFrDt,
        searchToDt: this.searchToDt,
      };
      const response = await selectExpenseList(param);
      this.loadSearchData = response.data;
    },
    onReSearch(focusObj) {
      this.focusKey1 = focusObj === undefined ? '' : focusObj.workDt;
      this.focusKey2 = focusObj === undefined ? '' : focusObj.seq;
      this.onSearch();
    },
    onSetDetail(row) {
      if (row) {
        this.callEvent({
          name: 'DetailInfo_Search',
          param: {workDt: row.workDt, seq: row.seq},
        });
        this.cud = 0;
      } else {
        this.callEvent({
          name: 'DetailInfo_Init',
        });
      }
    },
    onPopAddExcel() {
      this.$modal.show('excelUploadPop');
    },
    onPopConfirmExcel() {
      this.callEvent({
        name: 'excelUpload',
        param: () => {
          this.onSearch();
          this.$modal.hide('excelUploadPop');
        },
      });
    },
    onExcelUploadError(error, list) {
      if (error === 'server') {
        this.$alert({title: '엑셀업로드', message: '경비투입등록 엑셀파일을 확인하십시오.'});
      } else {
        this.$alert({title: '엑셀업로드', message: error});
        if (list && list.length > 0) {
          this.resultList = list;
          this.$modal.show('ExcelUploadResultPop');
        }
      }
    },
  },
};
</script>
