<template>
  <pmis-page ref="page" :search-project-visible="false">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col>
          <EquipInputList />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import PrjList from '@/view/Project/components/PrjList.vue';
import EquipInputList from './components/EquipInputList.vue';

export default {
  components: {PrjList, EquipInputList},
};
</script>

<style></style>
