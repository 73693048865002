/** 세금계산서 목록 */
export const selectBillList = param => axios.post('/bill/selectBillList', param);
/** 세금계산서 뷰어 정보 */
export const selectTaxInvoiceViewerInfos = param => axios.post('/bill/selectTaxInvoiceViewerInfos', param);

export const selectInvestMmList = () => axios.post('/bill/selectInvestMmList', {});

export const selectBillTargetList = param => axios.post('/bill/selectBillTargetList', param);

export const selectBillXmlList = billNos => axios.post('/bill/selectBillXmlList', {billNos});

export const selectBillInfos = param => axios.post('/bill/selectBillInfos', param);

export const cancel = billList => axios.post('/bill/cancel', {billList});
