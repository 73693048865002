<template>
  <!-- 현장정보-포틀릿-->
  <div style="height:100%">
    <div class="work_box name_site" style="backgroundColor: #ffffff;">
      <span class="pr10">진행구분</span>
      <iui-select width="100px" :items="selectOptions" v-model="searchOptions.status" @change="onChangeSearchStatus" />
      <span class="pl20 pr10">현장명</span>
      <iui-text type="search" width="200px" :value.sync="searchOptions.prjNm" @enter="onSearch" />
      <iui-button class="ml5" small value="검색" @click="onSearch" />
    </div>

    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr>
          <th rowspan="2">현장명</th>
          <th>계약일자</th>
          <th>착공일자</th>
          <th>준공(예정)일자</th>
          <th class="p_last">공사기간(일)</th>
        </tr>
        <tr>
          <th>진행상태</th>
          <th>계약금액</th>
          <th>기성금액</th>
          <th class="p_last">기성률(%)</th>
        </tr>
      </thead>
    </table>
    <div class="inner" style="height: calc(100% - 100px);">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <template v-for="prj in prjSttsList">
            <tr :key="`${prj.prjCd}_1`">
              <td rowspan="2" style="text-align:left;">
                <a @click="onClickPrj(prj)">{{ prj.prjNm }}</a>
              </td>
              <td>{{ dateFormat(prj.ctrtDate) }}</td>
              <td>{{ dateFormat(prj.ctrtStrdate) }}</td>
              <td>{{ dateFormat(prj.ctrtEnddate) }}</td>
              <td class="p_last num">{{ $_numberFormat(prj.constrPeriod, true) }} 일</td>
            </tr>
            <tr :key="`${prj.prjCd}_2`">
              <td>{{ prj.conCmplYn === 'Y' ? '완료' : '진행' }}</td>
              <td class="num">{{ $_numberFormat(prj.ctrtSumAmt, true) }} 원</td>
              <td class="num">{{ $_numberFormat(prj.pxcondSumAmt, true) }} 원</td>
              <td class="p_last num">{{ $_numberFormat(prj.pxcondRate) }} %</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {selectProjectStatusList} from '@/components/portlet/api/portletData.js';
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
export default {
  mixins: [PortletMixin],
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cnt: 5,
      selectOptions: [
        {value: '', text: '전체'},
        {
          value: $getConstants('PROJECT_PROGRESS_GB_IN_PROGRESS').code,
          text: $getConstants('PROJECT_PROGRESS_GB_IN_PROGRESS').name,
        },
        {value: $getConstants('PROJECT_PROGRESS_GB_DONE').code, text: $getConstants('PROJECT_PROGRESS_GB_DONE').name},
      ],
      searchOptions: {status: '', prjNm: ''},
      prjSttsList: [],
    };
  },
  methods: {
    dateFormat,
    $_numberFormat,
    setWidth() {
      this.tblWidth = this.$el.offsetWidth - 28;
    },
    onChangeSearchStatus() {
      this.searchOptions.prjNm = '';
      this.onSearch();
    },
    async onSearch() {
      const response = await selectProjectStatusList(this.searchOptions);
      this.prjSttsList = response.data;
    },
    onClickPrj(prjInfo) {
      this.$store.commit('setPrjCd', prjInfo.prjCd);
      this.$store.dispatch('project/setLoadPrj', prjInfo);
      if (this.isModal) {
        this.callEvent({name: 'ProjectSearchBar_ChangePrj', param: {prjCd: prjInfo.prjCd, prjNm: prjInfo.prjNm}});
      } else {
        this.callEvent({name: 'MainHome_turnPage'});
      }
    },
  },
};
</script>

<style></style>
