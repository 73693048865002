export default that => {
  return {
    Cfg: {
      CanEdit: 1,
      HeaderMerge: 6,
      HeaderCheck: 1,
    },
    LeftCols: [
      {
        Header: ['가동일자', '가동일자'],
        Name: 'runDt',
        Type: 'Text',
        Width: 100,
        CanEdit: false,
        CustomFormat: '####-##-##',
        Align: 'center',
      },
      {
        Header: ['작업분류', '작업분류'],
        Name: 'wbsPath',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
        CanEdit: false,
      },
      {
        Header: ['실행예산내역', '공종명'],
        Name: 'mgName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
        CanEdit: false,
      },
      {
        Header: ['실행예산내역', '내역코드'],
        Name: 'itemSeq',
        Type: 'Text',
        CanEdit: false,
      },
      {
        Header: ['실행예산내역', '내역명'],
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
        CanEdit: false,
      },
      {
        Header: ['실행예산내역', '규격'],
        Name: 'ssize',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
        CanEdit: false,
      },
      {
        Header: ['실행예산내역', '단위'],
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        MinWidth: 60,
        CanEdit: false,
        Format: $getConstants('UNIT').code,
      },
    ],
    Cols: [
      {
        Header: ['장비투입정보', '순번'],
        Name: 'rowNo',
        Type: 'Int',
        Align: 'center',
        Width: 45,
        CanEdit: false,
      },
      {
        Header: ['장비투입정보', '장비코드'],
        Name: 'equipCode',
        Type: 'Text',
        Width: 140,
        CanEdit: false,
        Align: 'center',
        ButtonFormula: e =>
          that.isSimpleExec || (!e.Row['equipCode'] && e.Row['costType'] == 'I' && that.monPjtCloseAt === 'N')
            ? 'Html'
            : '',
        ButtonText: '<input type="button" class="i_search" />',
        WidthPad: 37,
        OnClickSide: that.onShowEquipCodeModal,
      },
      {
        Header: ['장비투입정보', '장비명'],
        Name: 'equipName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
        CanEdit: false,
      },
      {
        Header: ['장비투입정보', '차량번호'],
        Name: 'carNo',
        Type: 'Text',
        Align: 'center',
        Width: 100,
        CanEdit: false,
      },
      {
        Header: ['장비투입정보', '사용구분'],
        Name: 'useGb',
        Type: 'Text',
        Align: 'center',
        Width: 80,
        CanEdit: false,
        Formula: e => (e.Row.useGb == $getConstants('USE_GB_PRIVATE').code ? e.Row.useGbNm : e.Row.rentFrmNm),
        Format: $getConstants('USE_GB').code,
      },
      {
        Header: ['장비투입정보', '시간'],
        Name: 'runQty',
        Type: 'Text',
        Width: 60,
        Align: 'right',
        Extend: that.$ibsheetHelper.preset.quantity,
        ColorFormula: e => (!e.Row.closeYn ? '#E0E0F8' : ''), // 마감시 수정불가
        CanEditFormula: e => !e.Row.closeYn, // 마감시 수정불가
        OnChange: e => {
          e.sheet.setValue(e.row, 'runAmt', (e.row.runQty ?? 0) * (e.row.runPrice ?? 0), 1);
        },
      },
      {
        Header: ['장비투입정보', '단가'],
        Extend: that.$ibsheetHelper.preset.unitAmount,
        Name: 'runPrice',
        Width: 100,
        CanEdit: false,
      },
      {
        Header: ['장비투입정보', '금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        Name: 'runAmt',
        Width: 120,
        CanEdit: false,
      },
      {
        Header: ['장비투입정보', '외주'],
        Name: 'frmNm',
        Type: 'Text',
        Width: 140,
        CanEdit: false,
        ButtonFormula: e => (that.monPjtCloseAt === 'N' ? 'Html' : ''),
        ButtonText: '<input type="button" class="i_search" />',
        WidthPad: 37,
        OnClickSide: that.onShowPoCompleteList,
      },
      {
        Header: ['장비투입정보', '추가/삭제'],
        Name: 'add',
        Type: 'Html',
        Align: 'center',
        Width: 40,
        CanEdit: false,
        OnClick: that.equipAdd,
      },
      {
        Header: ['장비투입정보', '추가/삭제'],
        Name: 'remove',
        Type: 'Html',
        Align: 'center',
        Width: 40,
        CanEdit: false,
        OnClick: that.equipRemove,
      },
      {Name: 'seq', Visible: false},
      {Name: 'wcode', Visible: false},
      {Name: 'headCode', Visible: false},
      {Name: 'midCode', Visible: false},
      {Name: 'itemCode', Visible: false},
      {Name: 'poNoSubc', Visible: false},
      {Name: 'custCode', Visible: false},
      {Name: 'pgmCd', Visible: false},
      {Name: 'prjCd', Visible: false},
      {Name: 'rentFrmNm', Visible: false},
      {Name: 'closeYn', Visible: false},
      {Name: 'costType', Visible: false},
      {Name: 'wbsCd', Visible: false},
      {Name: 'wbsId', Visible: false},
    ],
  };
};
