<template>
  <pmis-content-box :loading="loading">
    <template v-slot:header-left>
      <iui-text type="search" :value="searchText" @change="onChangeSearchText" @enter="searchByItem" />
      <iui-button value="검색" @click="searchByItem" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onSearchFinish,
        onClick,
      }"
    />
  </pmis-content-box>
</template>

<script>
import {selectBreakdownCostClList} from '../api/BreakdownCost.js';
import options from './sheetOptions/BreakdownCostClSheet.js';
export default {
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  beforeCreate() {
    $mapGetters(this, ['focusClKey', 'searchText', 'detailInfo', 'breakdownCostCl']);
    $mapMutations(this, ['initFocusClKey', 'setSearchText', 'setBreakdownCostCl']);
    $mapActions(this, ['onSearchList']);
  },
  created() {
    this.addEvent([
      {name: 'search', func: this.onSearch},
      {name: 'searchInTree', func: this.searchInTree},
      {name: 'reloadSheet', func: this.onReloadSheet},
    ]);
    this.onSearch();
  },
  data() {
    return {
      loading: false,
      sheet: undefined,
      sheetUtil: undefined,
      Options: options(this),
      loadSearchData: [],
      updateUpName: {2: 'sctmLcodeNm', 3: 'sctmMcodeNm', 4: 'sctmScodeNm'},
      returnCols: [
        'code',
        'name',
        'upCode',
        'upName',
        'sctmLcode',
        'sctmLcodeNm',
        'sctmMcode',
        'sctmMcodeNm',
        'sctmScode',
        'sctmScodeNm',
        'sctmSlcodeYn',
        'level',
      ],
    };
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
    searchInTree(lowestCode) {
      this.sheetUtil.treeSearch('code', lowestCode);
    },
    onReloadSheet() {
      let row = this.sheet.getFocusedRow();
      if (this.cud === 1) {
        const detailInfo = {};
        for (let [key, value] of Object.entries(this.detailInfo)) {
          if (value) {
            detailInfo[key] = value;
          }
        }
        const initData = {...this.breakdownCostCl, ...detailInfo};
        initData['code'] = initData.sctmScode || initData.sctmMcode || initData.sctmLcode;
        initData['name'] = initData.sctmScodeNm || initData.sctmMcodeNm || initData.sctmLcodeNm;
        if (this.updateUpName[initData.level]) {
          const key = this.updateUpName[initData.level];
          this.sheet.setValue(row, key, initData[key], 1);
          this.sheet.setValue(row, 'name', initData[key], 1);
          this.sheet.acceptChangedData(row);
        }
        if (!initData.itemCode) {
          let addRow = this.sheet.addRow({
            parent: row,
            init: {
              ...initData,
              pgmCd: row.pgmCd,
              level: row.level + 1,
              upCode: row.code,
              upName: row.name,
            },
          });
          this.sheet.acceptChangedData(addRow);
          this.onCallBack(this.rowsFilter(addRow));
        }
      } else if (this.cud === 2) {
        if (row.level === 2) {
          this.sheet.setValue(row, 'name', this.detailInfo['sctmLcodeNm'], 1);
        } else if (row.level === 3) {
          this.sheet.setValue(row, 'name', this.detailInfo['sctmMcodeNm'], 1);
        } else if (row.level === 4) {
          this.sheet.setValue(row, 'name', this.detailInfo['sctmScodeNm'], 1);
        }
        this.sheet.acceptChangedData(row);
      } else if (this.cud === 3) {
        const upCode = row.upCode;
        this.sheet.removeRow(row);
        let upRow = this.sheet.getDataRows().find(row => row.code == upCode);
        if (upRow) {
          this.sheet.focus(upRow);
          this.onCallBack(this.rowsFilter(upRow));
        }
      }
      this.cud = 0;
    },
    async onSearch() {
      this.loading = true;

      let response = await selectBreakdownCostClList({});
      let tree = $_treeModelStdCl(
        response.data,
        $getConstants('BREAKDOWN_CL').code,
        'code',
        'upCode',
        undefined,
        undefined,
        'name'
      );
      this.loadSearchData = tree({level: 1, name: '일위대가분류'});
    },
    searchByItem() {
      this.onSearchList();
    },
    onChangeSearchText(e) {
      this.setSearchText(e.target.value);
    },
    onSearchFinish() {
      this.loading = false;
      this.sheet.setAttribute(this.sheet.getFirstRow(), 'code', 'Visible', 0, 1);
      if (0 < this.sheet.getTotalRowCount()) {
        this.sheet.showTreeLevel(2, 0, 1);
      }
      let row;
      if (this.focusClKey) {
        this.sheetUtil.treeFocus('code', this.focusClKey);
        row = this.sheet.getFocusedRow();
        this.initFocusClKey();
      } else {
        row = this.sheet.getFirstRow();
      }
      this.onCallBack(this.rowsFilter(row));
    },
    rowsFilter(row) {
      let rowObj = {};
      for (let key in row) {
        if (this.returnCols.indexOf(key) != -1) {
          rowObj[key] = row[key];
        }
      }
      return rowObj;
    },
    onClick(e) {
      this.onCallBack(this.rowsFilter(e.row));
    },
    onCallBack(row) {
      this.setBreakdownCostCl(row);
      this.callback(row);
    },
  },
};
</script>
