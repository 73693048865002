<template>
  <div class="join_blank_pg">
    <div class="bg"></div>
    <div class="fg">
      <span class="txt">{{ aaa }} 없습니다.</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabNm: {
      type: String,
    },
  },
  computed: {
    aaa() {
      return `${this.tabNm}${$_isFinalConsonants(this.tabNm) ? '이' : '가'}`;
    },
  },
};
</script>

<style></style>
