export default that => {
  const addBtnText = '<button class="btn_04">추가</button>';
  const deleteBtnText = '<button class="btn_07">삭제</button>';

  return {
    Cfg: {
      SearchMode: 2,
      CanEdit: 1,
      CanSort: 0,
      CanColMove: 0,
    },
    Def: {
      Row: {
        NoColor: 1,
        CanFormula: 1,
        CalcOrder: 'addOrRemoveButtonText,needDocNmCanEdit',
      },
    },
    Cols: [
      {
        Header: '서류명',
        Name: 'needDocNm',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        EmptyValue: '(직접입력)',
        CanEditFormula: fr => fr.Row.needDocType == 'other',
      },
      {
        Header: '선택',
        Name: 'addOrRemove',
        Type: 'Button',
        Button: 'Html',
        ButtonTextFormula: fr => (fr.Row.added ? deleteBtnText : addBtnText),
      },
      {
        Name: 'needDocType',
        Visible: false,
      },
      {
        Name: 'added',
        Type: 'Bool',
        DefaultValue: false,
        Visible: false,
      },
    ],
  };
};
