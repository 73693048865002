/* 보유장비 등록 - 상세정보 */

<template>
  <iui-container-new type="css-flex">
    <form onsubmit="return false;">
      <iui-container-new type="css-flex">
        <i-row height="320px" style="overflow: visible">
          <i-col>
            <i-row min-height="225px">
              <i-col>
                <pmis-content-box>
                  <template v-slot:title>장비정보</template>
                  <iui-container-new
                    type="table"
                    theme="bullet"
                    header-width="80px"
                    style="position: absolute; overflow: visible"
                  >
                    <i-row>
                      <i-col-header>장비코드</i-col-header>
                      <i-col colspan="3">
                        <iui-text
                          type="code"
                          name="equipCode"
                          :value="detailInfo.equipCode"
                          :bindArray="bindArray"
                          :enable="false"
                          width="120px"
                        />
                        <iui-text
                          type="text"
                          name="itemName"
                          :value="detailInfo.itemName"
                          :bindArray="bindArray"
                          :enable="false"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>규격</i-col-header>
                      <i-col>
                        <iui-text
                          type="text"
                          name="size"
                          :value="detailInfo.size"
                          :bindArray="bindArray"
                          :enable="false"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>단위</i-col-header>
                      <i-col>
                        <iui-text :value="unitNm" :enable="false" width="80px" />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header required>차량번호</i-col-header>
                      <i-col>
                        <iui-text
                          type="text"
                          name="carNo"
                          :value="detailInfo.carNo"
                          :bindArray="bindArray"
                          max-length="20"
                          required
                          :error-message="{title: '차량번호', message: '차량번호를 입력해주세요.'}"
                          :enable="detailInfo.cud == '1' && isEnable"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>등록번호</i-col-header>
                      <i-col>
                        <iui-text
                          type="text"
                          name="registerNo"
                          :value="detailInfo.registerNo"
                          max-length="20"
                          :bindArray="bindArray"
                          :enable="isEnable"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>제조회사</i-col-header>
                      <i-col>
                        <iui-text
                          type="text"
                          name="makeFrmNo"
                          :value="detailInfo.makeFrmNo"
                          max-length="100"
                          :bindArray="bindArray"
                          :enable="isEnable"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header required>사용유류</i-col-header>
                      <i-col>
                        <iui-select
                          name="useOil"
                          width="90px"
                          :items="oilItems"
                          :value="detailInfo.useOil"
                          @change="value => (detailInfo.useOil = value)"
                          :defaultCd="'S'"
                          :enable="isEnable"
                          required
                          :error-message="{title: '사용유류', message: '사용유류를 선택해주세요.'}"
                        />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </pmis-content-box>
              </i-col>
            </i-row>
            <i-spacer />
            <i-row min-height="60px">
              <i-col>
                <pmis-content-box>
                  <template v-slot:title>구입정보</template>
                  <iui-container-new type="table" theme="bullet" header-width="80px">
                    <i-row>
                      <i-col-header>구입처</i-col-header>
                      <i-col>
                        <iui-text
                          type="text"
                          name="buyCompany"
                          :value="detailInfo.buyCompany"
                          max-length="100"
                          :bindArray="bindArray"
                          :enable="isEnable"
                        />
                      </i-col>
                      <i-spacer />
                      <i-col-header>구입일자</i-col-header>
                      <i-col>
                        <iui-datepicker
                          name="buyDate"
                          :value="detailInfo.buyDate"
                          :bindArray="bindArray"
                          :enable="isEnable"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>전화번호</i-col-header>
                      <i-col>
                        <iui-text
                          type="phone"
                          name="buyTel"
                          :value="detailInfo.buyTel"
                          max-length="20"
                          :bindArray="bindArray"
                          :enable="isEnable"
                        />
                      </i-col>
                      <i-spacer />
                      <i-col-header>구입금액</i-col-header>
                      <i-col>
                        <iui-text
                          type="amount"
                          name="buyAmt"
                          :value="detailInfo.buyAmt"
                          :bindArray="bindArray"
                          :enable="isEnable"
                        />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </pmis-content-box>
              </i-col>
            </i-row>
          </i-col>
        </i-row>
        <i-row min-height="200px">
          <i-col>
            <pmis-content-box>
              <template v-slot:title>투입이력</template>
              <InptHist />
            </pmis-content-box>
          </i-col>
        </i-row>
      </iui-container-new>
    </form>
  </iui-container-new>
</template>

<script>
import InptHist from './InptHist.vue';

export default {
  components: {InptHist},
  data() {
    return {
      bindArray: ['setDetailInfo'],
      unitNm: '', // 단위명
      oilItems: [],
      internalEquipCode: '',
    };
  },
  watch: {
    detailInfo: {
      handler(obj) {
        $getCodeNm($getConstants('UNIT').code, obj.unit).then(response => {
          this.unitNm = response;
        });
        this.internalEquipCode = obj.equipCode;
      },
      deep: true,
    },
  },
  computed: {
    isEnable() {
      return this.internalEquipCode != '' && this.internalEquipCode !== undefined;
    },
  },
  created() {
    $getCode($getConstants('USE_OIL').code).then(codes => {
      this.oilItems = codes.filter(e => e.code.startsWith('0')).map(e => ({text: e.codeNm, value: e.code}));
    });
  },
  beforeCreate() {
    $mapGetters(this, ['detailInfo']);
  },
};
</script>

<style scoped></style>
