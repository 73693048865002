<template>
  <pmis-tab-box>
    <template v-slot:title>포틀릿목록</template>
    <pmis-content-box>
      <template v-slot:header-left>
        <label>포틀릿그룹</label>
        <iui-select
          :p-code="$getConstants('PORTLET_GROUP').code"
          defaultCd="A"
          :value.sync="portletGroup"
          @change="onSelectPortletList"
        />
        <iui-text type="search" width="200px" max-length="50" :value.sync="portletName" @enter="onSelectPortletList" />
        <iui-button value="검색" @click="onSelectPortletList" />
      </template>
      <ib-sheet
        :uid="_uid"
        :options="Options"
        :loadSearchData="loadSearchData"
        :events="{
          onRenderFirstFinish,
        }"
        @loadSheet="loadSheet"
        :autoRender="false"
        :init="initSheet"
      />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
import {selectPortletList} from '../api/portletManage.js';
import options from './sheetOption/portletListSheet.js';
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      portletGroup: '',
      portletName: '',
      Options: {},
      loadSearchData: [],
      initSheet: false,
    };
  },
  beforeCreate() {
    $mapMutations(this, ['setPortletData', 'setDragTrue', 'setDragFalse']);
  },
  created() {
    this.addEvent([{name: 'portletList_selectPortletList', func: this.onSelectPortletList}]);
    this.createSheet();
  },
  methods: {
    async createSheet() {
      this.Options = await options(this);
      this.initSheet = true;
    },
    async onSelectPortletList() {
      const response = await selectPortletList({
        portletGroup: this.portletGroup,
        portletName: this.portletName,
      });
      this.sheet.loadSearchData(response.data, 0);
    },
    onRenderFirstFinish() {
      this.onSelectPortletList();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style></style>
