<template>
  <pmis-page ref="page" :search-project-visible="false" @search-click="onSearch">
    <iui-container-new type="css-flex" flex-direction="row">
      <i-col width="345px"><PrjList /></i-col>
      <i-spacer />
      <i-col>
        <i-row>
          <pmis-tab-box>
            <template v-slot:title>집행목록</template>
            <pmis-content-box>
              <template v-slot:header-left>
                <div>집행일자</div>
                <iui-datepicker
                  name="searchFrDt"
                  :value="searchFrDt"
                  @change="
                    event => {
                      searchFrDt = event;
                      onSearch();
                    }
                  "
                />
                <div>~</div>
                <iui-datepicker
                  name="searchToDt"
                  :value="searchToDt"
                  @change="
                    event => {
                      searchToDt = event;
                      onSearch();
                    }
                  "
                />
                <div>거래처</div>
                <iui-text
                  type="search"
                  :value="searchText"
                  @change="searchText = $event.target.value"
                  @enter="onSearch()"
                />
                <iui-button value="검색" @click="onSearch" />
              </template>
              <template v-slot:header-right></template>
              <ib-sheet
                :uid="_uid"
                :options="Options"
                :loadSearchData="loadSearchData"
                :events="{
                  onSearchFinish,
                  onClick,
                }"
                @loadSheet="loadSheet"
              />
            </pmis-content-box>
          </pmis-tab-box>
        </i-row>
      </i-col>
      <pmis-file-list
        name="flNo"
        id="file0"
        flNoCol="flNo"
        title="첨부파일"
        title-visible
        :toolbar-visible="false"
        :file-number.sync="flNo"
        :modal="true"
        :hidden="true"
        ref="file"
      />
    </iui-container-new>
  </pmis-page>
</template>

<script>
/**
 * 집행경비조회
 * components :
 *   PrjList 현장목록
 *
 * */
import PrjList from '@/view/Project/components/PrjList.vue';
import options from '@/view/Resource/Expense/ExpenseSearch/components/sheetOptions/ExpenseSearchSheet.js';
import {selectExpenseList} from '@/view/Resource/Expense/ExpenseSearch/api/expenseSearch.js';
export default {
  components: {PrjList},
  data: function() {
    return {
      Options: options(this),
      loadSearchData: [],

      searchPrjCd: '',
      searchFrDt: '',
      searchToDt: '',
      searchText: '',
      flNo: 0,
    };
  },
  created() {
    this.addEvent([{name: 'set_searchInfo', func: this.onSetPrjCd}]);
  },
  methods: {
    onSearchFinish() {
      this.sheet.setAutoMerge(null, 6);
      if (0 < this.sheet.getTotalRowCount()) {
        this.sheet.makeSubTotal([
          {
            stdCol: 'workDt',
            sumCols: 'amt|vatAmt|sumAmt',
            captionCol: [{col: 'workDt', val: '소계'}],
          },
        ]);
      }
    },
    onClick() {
      if (e.row.Kind == 'Data' && e.col == 'flView' && e.cellType == 'Button') {
        if (e.row.flNo) {
          this.flNo = e.row.flNo;
          this.$refs.file.openModal();
        }
      }
    },
    onSetPrjCd(e) {
      this.searchPrjCd = e.prjCd;
      this.onInitSearchDate();
      this.onSearch();
    },
    async onSearch() {
      if (!this.searchPrjCd) {
        this.$alert({title: '현장선택', message: '현장을 선택해 주세요.'});
        return;
      }

      let param = {
        searchFrDt: this.searchFrDt,
        searchToDt: this.searchToDt,
        searchText: this.searchText,
      };
      const response = await selectExpenseList(param);
      this.loadSearchData = response.data;
    },
    onInitSearchDate() {
      const currDate = $_getCurrentDate();
      const frDate = new Date(currDate.substr(0, 4), currDate.substr(4, 2) - 1, currDate.substr(6, 2));
      const toDate = new Date(currDate.substr(0, 4), currDate.substr(4, 2) - 1, currDate.substr(6, 2));
      frDate.setMonth(frDate.getMonth() - 1);

      this.searchFrDt = $_dateToStringYYMMDD(frDate);
      this.searchToDt = $_dateToStringYYMMDD(toDate);
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
  },
};
</script>

<style scoped></style>
