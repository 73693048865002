const state = {
  detailInfo: {
    prjEstCd: '', // 견적현장코드
    prjEstNm: '', // 견적현장명
    siteArea1: '', // 공사지역코드(시도)
    siteArea1Nm: '', // 공사지역명(시도)
    siteArea2: '', // 공사지역코드(시도구)
    siteArea2Nm: '', // 공사지역명(시도구)
    orderGroup: $getConstants('ORDER_GROUP_PRIVATE').code, // 발주구분
    workKind: $getConstants('WORK_KIND_SCPAY').code, // 도급종류
    contcompCode: '', // 원도급사코드
    contcompNm: '', // 원도급사명
    orderCode: '', // 발주처코드
    orderNm: '', // 발주처명
    workDay: '', // 공기
    rmk: '', // 공사개요
    coYn: $getConstants('CO_YN_Y').code, // 공동도급여부
    coCompany: '', // 공동도급사
    prDate: '', // 현설일자
    prTime: '', // 현설일시
    prTimeHh: '00',
    prTimeMm: '00',
    duDate: '', // 등록(마감)일자
    biDate: '', // 입찰일자
    biTime: '', // 입찰일시
    biTimeHh: '',
    biTimeMm: '',
    costAmt: '', // 추정가격
    vatAmt: '', // 부가세액
    bidStat: $getConstants('BID_STAT_SUCCESS').code, //입찰결과
    ctrtDate: '', // 계약일자
  },
};

const getters = {
  detailInfo: state => state.detailInfo,
};

const mutations = {
  initDetailInfo(state) {
    for (let detail in state.detailInfo) {
      if (detail == 'coYn' || detail == 'bidStat') {
        state.detailInfo[detail] =
          detail == 'coYn' ? $getConstants('CO_YN_Y').code : $getConstants('BID_STAT_SUCCESS').code;
      } else if (detail == 'workKind' || detail == 'orderGroup') {
        state.detailInfo[detail] =
          detail == 'workKind' ? $getConstants('WORK_KIND_SCPAY').code : $getConstants('ORDER_GROUP_PRIVATE').code;
      } else state.detailInfo[detail] = '';
    }
  },

  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = payload[key];

        if (key === 'workKind' && payload[key] === $getConstants('WORK_KIND_CONTCOMP').code) {
          state.detailInfo.contcompCode = '';
          state.detailInfo.contcompNm = '';
        }
        if (key.startsWith('prTime')) {
          state.detailInfo.prTime = state.detailInfo.prTimeHh + ':' + (state.detailInfo.prTimeMm || '');
        }
        if (key.startsWith('biTime')) {
          state.detailInfo.biTime = state.detailInfo.biTimeHh + ':' + (state.detailInfo.biTimeMm || '');
        }
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
