var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"iui-content-box"},[(
      _vm.$slots['title'] !== undefined ||
        _vm.$slots['title-bullet'] !== undefined ||
        _vm.$slots['title-bullet-required'] !== undefined ||
        _vm.$slots['title-left'] !== undefined ||
        _vm.$slots['title-center'] !== undefined ||
        _vm.$slots['title-right'] !== undefined ||
        _vm.isHeader
    )?_c('iui-content-box-title-box',{attrs:{"title-type":_vm.titleType},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._t("title")]},proxy:true},{key:"title-bullet",fn:function(){return [_vm._t("title-bullet")]},proxy:true},{key:"title-bullet-required",fn:function(){return [_vm._t("title-bullet-required")]},proxy:true},{key:"title-left",fn:function(){return [_vm._t("title-left")]},proxy:true},{key:"title-center",fn:function(){return [_vm._t("title-center")]},proxy:true},{key:"title-right",fn:function(){return [_vm._t("title-right")]},proxy:true}],null,true)}):_vm._e(),_c('div',{class:{'outer-content-box': true, grid_box: _vm.borderBox && !_vm.noBorderBox, 'iui-loading-overlay-parent': true},style:({
      height: _vm.titleBoxVisible || _vm.isHeader ? 'calc(100% - 30px)' : '100%',
      'border-top': _vm.noBorderBox ? '2px solid #3378c1' : '',
      padding: _vm.noPaddingBox ? '0px' : '',
    })},[_c('iui-loading-overlay',{attrs:{"visible":_vm.loading}}),(
        _vm.$slots['header-left'] !== undefined ||
          _vm.$slots['header-center'] !== undefined ||
          _vm.$slots['header-right'] !== undefined ||
          _vm.$slots['header-custom'] !== undefined
      )?_c('iui-content-box-header-box',{scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_vm._t("header-left")]},proxy:true},{key:"header-center",fn:function(){return [_vm._t("header-center")]},proxy:true},{key:"header-right",fn:function(){return [_vm._t("header-right")]},proxy:true},{key:"header-custom",fn:function(){return [_vm._t("header-custom")]},proxy:true}],null,true)}):_vm._e(),_c('div',{class:{grid_inner_scroll: true},style:(_vm.contentBoxStyle)},[_vm._t("default")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }