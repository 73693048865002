export default that => {
  const preset = that.$ibsheetHelper.preset;

  return {
    Cfg: {CanEdit: 0},
    Def: {Row: {NoColor: 2, CanFocus: 0}},
    Cols: [
      {Header: '공종명', Name: 'srvNm', Type: 'Text', RelWidth: 1, MinWidth: 80, CanEdit: false},
      {Header: '내역명', Name: 'itemNm', Type: 'Text', RelWidth: 1, MinWidth: 140, CanEdit: false},
      {Header: '규격', Name: 'sizeNm', Type: 'Text', RelWidth: 1, MinWidth: 140, CanEdit: false},
      {Header: '단위', Name: 'unitNm', Type: 'Text', Align: 'center', Width: 50, CanEdit: false},
      {Header: '실행수량', Name: 'totQty', Type: 'Text', Width: 100, CanEdit: false, Visible: false},
      {Header: '기시행수량', Name: 'preQty', Type: 'Text', Width: 100, CanEdit: false, Visible: false},
      {Header: '잔여수량', Name: 'remQty', Type: 'Text', Width: 100, CanEdit: false, Visible: false},
      {Header: '금회요청수량', Name: 'qty', Type: 'Text', Width: 100, CanEdit: true, Extend: preset.quantity},
      {Header: '단가', Name: 'unitAmt', Type: 'Text', Width: 100, CanEdit: false, Extend: preset.unitAmount},
      {
        Header: '금액',
        Name: 'amt',
        Type: 'Text',
        Width: 140,
        CanEdit: false,
        Extend: preset.amount,
        Formula: fr => fr.Row['qty'] * fr.Row['unitAmt'],
      },
      {Header: '비고', Name: 'srvRmrk', Type: 'Text', Width: 100, CanEdit: true},

      {Name: 'wcode', Visible: false},
      {Name: 'headCode', Visible: false},
      {Name: 'midCode', Visible: false},
      {Name: 'itemCode', Visible: false},
      {Name: 'planMatUprc', Visible: false},
      {Name: 'planLabUprc', Visible: false},
      {Name: 'planEquipUprc', Visible: false},
      {Name: 'planCostUprc', Visible: false},
      {Name: 'labAmt', Formula: fr => fr.Row['qty'] * fr.Row['planLabUprc'], Visible: false},
      {Name: 'unit', Visible: false},
    ],
  };
};
