<template>
  <pmis-page ref="page" :search-company-visible="false" :search-project-visible="false" @search-click="onSearch">
    <BoardList />
  </pmis-page>
</template>

<script>
import BoardList from './components/qnaAnswerBoardList.vue';
export default {
  components: {BoardList},
  methods: {
    onSearch() {
      this.callEvent({name: 'QnA_Answer_BoardList_Search'});
    },
  },
};
</script>

<style></style>
