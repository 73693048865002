<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text type="search" ref="searchText" :value.sync="searchText" @enter="onFocusByNm" />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onRenderFirstFinish,
        onDblClick,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/LbrcTeamListPopupSheet.js';
import {selectLbrcTeam} from '@/view/sysManage/userManage/lbrcTeamRegist/api/lbrcTeamRegist.js';
export default {
  props: {
    uid: {
      type: Number,
    },
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      searchText: '',
      Options: options(this),
      loadSearchData: [],
    };
  },
  created() {
    this.addEvent([{name: 'LbrcTeamListPopup_callbackData', func: this.callbackData}]);
  },
  methods: {
    onFocusByNm() {
      if (this.sheet.getTotalRowCount() > 0) {
        this.sheet.SearchExpression = this.searchText;
        this.sheet.findRows('Find');
        this.$refs.searchText.$refs.input.focus();

        let searchCnt = this.sheetUtil.listSearch(['labName'], this.searchNm);
        if (searchCnt == 0) {
          this.$alert({title: '노무팀', message: '해당 노무팀 정보가 존재하지 않습니다'});
        }
      }
    },
    async onSearch() {
      const response = await selectLbrcTeam({labName: this.labName});
      if (response.status == 200) {
        this.sheet.loadSearchData(response.data);
      }
    },
    callbackData(callback) {
      if (typeof callback == 'function') {
        callback(this.sheet.getFocusedRow());
      }
    },
    onDblClick(e) {
      if (e.row.Kind == 'Data') {
        this.callEvent({name: 'onHideModal_' + this.uid});
      }
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style></style>
