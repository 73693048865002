<template>
  <div>
    <modal
      :name="`pdfViewer${$store.getters['currentUrl']}`"
      width="100%"
      height="100%"
      :scrollable="false"
      :clickToClose="true"
      style="z-index: 100;"
    >
      <vue-pdf-app :pdf="pdf" :pageNumber="pageNumber" :fileName="fileName">
        <template #toolbar-right-append>
          <iui-button btn-class="toolbarButton vue-pdf-app-icon" @click="hidePdf">X</iui-button>
        </template>
      </vue-pdf-app>
    </modal>
  </div>
</template>

<!-- https://www.npmjs.com/package/vue-pdf-app -->
<script>
import VuePdfApp from 'vue-pdf-app';
import 'vue-pdf-app/dist/icons/main.css';

export default {
  components: {VuePdfApp},
  data() {
    return {
      pdf: '',
      pageNumber: 1,
      fileName: '',
    };
  },
  created() {
    this.addEvent([
      {name: 'showPdf', func: this.showPdf, isDefault: true},
      {name: 'hidePdf', func: this.hidePdf, isDefault: true},
    ]);
  },
  methods: {
    showPdf(param) {
      if (param) {
        this.pdf = param.src;
        this.fileName = param.fileName;
        this.$modal.show('pdfViewer');
      }
    },
    hidePdf() {
      this.$modal.hide('pdfViewer');
    },
  },
};
</script>

<style scoped></style>
