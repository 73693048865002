window.popupInterface = {};
window.$open = (params, that) => {
  const opt = {
    path: '/popup',
    name: params.name,
    param: params.param,
    prjCd: that.prjCd,
    prjList: that.$store.getters['project/prjList'],
    token: {
      accessToken: that.$store.getters['jwt/accessToken'],
      refreshToken: that.$store.getters['jwt/refreshToken'],
    },
  };

  const _window = window.open(opt.path, opt.name, 'width=1236,height=700,left=200,top=200');
  const timer = setInterval(function() {
    if (_window.closed) {
      clearInterval(timer);
      delete window.popupInterface[opt.name];
    }
  }, 1000);

  popupInterface[opt.name] = opt;

  return {...opt, window: _window};
};
