<template>
  <pmis-content-box>
    <template v-slot:header-left v-if="isEnableSearchbox">
      <iui-select
        v-if="type != 'prj' && type != 'prjEst' && type != 'workGroupByPgmCd'"
        :id="selectId"
        :items="searchSe"
        :defaultCd="'S'"
        :value="searchInfo.searchSe"
        @change="onChange('searchSe', $event)"
      />
      <div v-if="type == 'workGroupByPgmCd'">외주명</div>
      <iui-text :id="textId" :value="searchInfo.searchNm" @change="onChange('searchNm', $event)" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
      <iui-checkbox-group
        :items="checkboxItem"
        v-if="type == 'prj' && isEndCntrInc"
        :checkedValues="[searchInfo.endCntrInc]"
        @change="
          event => {
            searchInfo.endCntrInc = event.target.checked ? 'Y' : 'N';
            onSearch();
          }
        "
      ></iui-checkbox-group>
    </template>

    <ContractInfo
      v-if="type == 'prj'"
      :isPopup="true"
      :eventNms="{
        selectCntrctInfoList: 'selectCntrctInfoListOnSearch',
        onFocusedRowData: 'onFocusedRowDataOnContract',
        onSelectedRowData: 'onSelectedRowDataOnContract',
      }"
      :uid="uid"
      :visibleCols="paramObj ? paramObj.visibleCols : []"
    />
    <PrjEstCdPopup
      v-if="type == 'prjEst'"
      :eventNms="{selectPrjEstMList: 'selectPrjEstMListOnSearch', onFocusedRowData: 'onFocusedRowDataOnPrjEst'}"
      :uid="uid"
    />
    <CustomerListPopup v-else-if="type == 'customer'" :isPopup="true" :uid="uid" :paramObj="paramObj" />
    <WorkerList
      v-else-if="type == 'worker'"
      :isPopup="true"
      :uid="uid"
      :isExtend="isExtend"
      :eventNms="{onFocusedRowData: 'onFocusedRowDataOnWorker'}"
      :paramObj="paramObj"
    />
    <WorkGroupList v-else-if="type == 'workGroup'" :uid="uid" by-prjcd />
    <WorkGroupList v-else-if="type == 'workGroupByPgmCd'" :uid="uid" by-pgmcd />
    <WorkGroupList v-else-if="type == 'workGroupByCusCd'" :uid="uid" by-cuscd />
    <AccountGb v-else-if="type == 'accountGb'" :uid="uid" />
    <CommonCode v-else-if="type == 'commonCode'" :uid="uid" :paramObj="paramObj" />
    <CompanyCode v-else-if="type == 'companyCode'" :uid="uid" :isPopup="true" />
    <DataCl v-else-if="type == 'dataCl'" :uid="uid" :isPopup="true" :isBasePage="paramObj.isBasePage" />
  </pmis-content-box>
</template>

<script>
import ContractInfo from '@/view/bsnsManage/cntrctManage/cntrctInfoRegist/components/ContractInfo';
import PrjEstCdPopup from '@/view/estmtManage/estmtPrjManage/components/popup/prjEstCdPopup';
import CustomerListPopup from '@/view/Resource/components/CustomerListPopup.vue';
import WorkerList from '@/view/user/Worker/components/WorkerList';
import WorkGroupList from '@/view/subBidding/poManage/components/popup/PoCompleteList.vue';
import AccountGb from '@/view/common/AccountGb';
import CommonCode from '@/view/sysManage/stdCodeManage/cmmnCodeRegist/components/CommonCodePop.vue';
import CompanyCode from '@/view/sysManage/userManage/companyRegist/components/CompanyList.vue';
import DataCl from '@/view/DataClassificationRegist/components/DataClassificationList.vue';
export default {
  components: {
    ContractInfo,
    PrjEstCdPopup,
    WorkGroupList,
    CustomerListPopup,
    WorkerList,
    AccountGb,
    CommonCode,
    CompanyCode,
    DataCl,
  },

  props: {
    type: {
      type: String,
    },
    uid: {
      type: Number,
    },
    isExtend: {
      type: Boolean,
      default: false,
    },
    paramObj: {
      type: Object,
      default: () => {},
    },
    isEndCntrInc: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      selectId: 'searchSe' + this._uid,
      textId: 'searchNm' + this._uid,
      checkboxItem: [{label: '종료계약포함', value: 'checkValues', isChecked: false}],
      searchInfo: {
        searchSe: '',
        searchNm: '',
        endCntrInc: '',
      },
      isEnableSearchbox: true,
    };
  },

  beforeCreate: function() {
    $mapGetters(this, {
      sheetData: 'sheetData',
    });
  },

  created() {
    this.setSearchData();
    this.isEnableSearchbox = this.type != 'dataCl' && this.type != 'customer' ? true : false;
  },

  mounted() {
    // this.setHeight();

    if (this.type == 'prjEst' || this.type == 'mat' || this.type == 'customer' || this.type == 'worker') {
      setTimeout(() => this.onSearch(), 100);
    }
  },

  methods: {
    onSearch() {
      let name;
      let param;
      switch (this.type) {
        case 'prj':
          name = 'selectCntrctInfoListOnSearch';
          param = {
            searchSe: this.type == 'prj' ? '' : this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
            pgmCd: this.pgmCd,
            endCntrInc: this.searchInfo.endCntrInc, //document.getElementsByName('endCntrInc')[0].checked ? 'Y' : 'N',
          };
          break;
        case 'prjEst':
          name = 'selectPrjEstMListOnSearch';
          param = {
            searchNm: this.searchInfo.searchNm,
            pgmCd: this.pgmCd,
          };
          break;
        case 'matCode':
          name = 'MatCode_DetailList_search';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
            pgmCd: this.pgmCd,
          };
          break;
        case 'workGroup':
        case 'workGroupByCusCd':
          name = 'PoCompleteList_Search';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
          };
          break;
        case 'workGroupByPgmCd':
          name = 'PoCompleteList_Search';
          param = {
            searchNm: this.searchInfo.searchNm,
          };
          break;
        case 'customer':
          name = 'CustomerList_search';
          param = {
            searchSe: 'codeName',
            searchNm: this.searchInfo.searchNm,
            pgmCd: this.pgmCd,
            frmDs: this.searchInfo.searchSe || (this.paramObj ? this.paramObj.frmDs : null),
          };
          break;
        case 'worker':
          name = 'selectWorkerList';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
            pgmCd: this.pgmCd,
          };
          break;
        case 'accountGb':
          name = 'AccountGbFilter';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
          };
          break;
        case 'commonCode':
          name = 'CommonCode_search';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
          };
          break;
        case 'companyCode':
          name = 'CompanyCode_search';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
          };
          break;
        case 'user':
          name = 'User_search';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
          };
          break;
        default:
          break;
      }

      this.callEvent({name: name, param: param});
    },
    setSearchData() {
      this.searchSe = [];
      switch (this.type) {
        case 'prj':
          this.searchSe.push({value: 'prjCd', text: '현장코드'}, {value: 'prjNm', text: '현장명'});
          break;
        case 'matCode':
          this.searchSe.push({value: 'matName', text: '자재명'}, {value: 'matCode', text: '자재코드'});
          break;
        case 'workGroup':
          this.searchSe.push(
            {value: 'poNo', text: '계약번호'},
            {value: 'ttl', text: '계약명'},
            {value: 'cusNm', text: '거래처명'}
          );
          break;
        case 'workGroupByCusCd':
          this.searchSe.push({value: 'poNo', text: '계약번호'}, {value: 'ttl', text: '계약명'});
          break;
        case 'customer':
          $getCode('0015').then(response => {
            response.forEach(item => {
              this.searchSe.push({value: item.code, text: item.codeNm});
            });
            if (this.paramObj && this.paramObj.frmDs) {
              this.searchInfo.searchSe = this.paramObj.frmDs;
            }
          });

          break;
        case 'worker':
          this.searchSe.push({value: 'empNo', text: 'ID'}, {value: 'name', text: '근로자'});
          break;
        case 'accountGb':
          this.searchSe.push({value: 'accountGb', text: '거래구분코드'}, {value: 'accountGbName', text: '거래구분명'});
          break;
        case 'commonCode':
          this.searchSe.push({value: 'code', text: '코드'}, {value: 'codeNm', text: '코드명'});
          break;
        case 'companyCode':
          this.searchSe.push({value: 'pgmCd', text: '회사코드'}, {value: 'frmNm', text: '회사명'});
          break;
        case 'user':
          this.searchSe.push({value: 'name', text: '성명'}, {value: 'depNm', text: '부서명'});
          break;
        default:
          break;
      }
    },

    setHeight() {
      let base = this.$el.parentNode.offsetHeight;
      let minus = 0;

      //팝업 header
      let styles = getComputedStyle(this.$el.parentNode.querySelector('.poph'));
      let margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
      minus += this.$el.parentNode.querySelector('.poph').offsetHeight + margin;

      //팝업 버튼박스
      styles = getComputedStyle(this.$el.parentNode.querySelector('.popbtn'));
      margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
      minus += this.$el.parentNode.querySelector('.popbtn').offsetHeight + margin;

      //팝업 검색박스
      styles = getComputedStyle(this.$el.querySelector('.search_box'));
      margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
      minus += this.$el.querySelector('.search_box').offsetHeight + margin;

      //팝업 보더값
      styles = getComputedStyle(this.$el);
      let boader = parseFloat(styles['borderTop']) + parseFloat(styles['borderBottom']);

      let height = Math.ceil(base - minus - boader);
      this.$el.querySelector('.popcon').style.height = `${height}px`;
    },
    onChange: function(key, event) {
      if (key == 'searchSe' && !event.target.value) {
        this.searchInfo.searchSe = '';
        this.searchInfo.searchNm = '';
      } else {
        this.searchInfo[key] = event.target.value;
      }
    },
  },
};
</script>

<style scoped></style>
