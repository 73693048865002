<template>
  <pmis-page :search-project-visible="false" :search-company-visible="false">
    <pmis-content-box title-type="tab" no-border-box no-padding-box>
      <template v-slot:title>표준세율</template>
      <ib-sheet
        :uid="_uid"
        :options="sheetOpt"
        :loadSearchData="loadSearchData"
        @loadSheet="loadSheet"
        :events="{onRenderFirstFinish: sheet_onRenderFirstFinish, onBeforeChange: sheet_onBeforeChange}"
      />
    </pmis-content-box>
  </pmis-page>
</template>

<script>
import sheetOpt from './components/sheetOption/standardTaxRate.js';
import {selectTaxRateList, saveTaxRate, deleteTaxRate} from './api/standardTaxRate.js';

export default {
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],
    };
  },
  created() {
    this.addFuncSearch(this.onSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },
  mounted() {
    this.loadSheet();
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
      this.onSearch();
    },
    sheet_onBeforeChange(e) {
      if (e.col != 'entryDt') return;

      const val = e.val;
      const oldval = e.oldval;
      const regex = new RegExp('^([0-9])$|^([0-2])([0-9])$|^(3)([0-1]){0,2}$');

      if (val && !regex.test(val)) {
        this.$alert({title: '가입기준(일)', message: '가입기준(일) 1 ~ 31일까지만 입력가능합니다'});
        return oldval;
      }
      return isNaN(parseInt(val)) ? '' : parseInt(val);
    },
    sheet_onRenderFirstFinish(e) {
      e.sheet.setAutoMerge({headerMerge: 6});
    },
    onSearch() {
      selectTaxRateList().then(response => (this.loadSearchData = response.data));
    },
    onAdd() {
      this.sheet.addRow();
    },
    async onSave() {
      if (!this.sheet.hasChangedData()) {
        this.$alert({title: '표준세율 저장', message: '변경된 내용이 없습니다.'});
        return;
      }
      let duplicateRows = this.sheet.getRowsByDup('applyDt', false, false, false);
      if (duplicateRows.length > 0) {
        this.$alert({title: '표준세율 저장', message: '적용일자는 중복될 수 없습니다.'});
        return;
      }

      let requiredObj = undefined;
      const headers = this.sheet.getHeaderRows()[1];
      const rows = this.sheet.getSaveJson().data;
      const cols = this.sheet.getShownCols();

      cols.shift();
      rows.every(row => {
        cols.every(col => {
          if (row[col] === '') {
            requiredObj = {row, col};
            return false;
          }
          return true;
        });
        return requiredObj === undefined;
      });

      if (requiredObj !== undefined) {
        const row = this.sheet.getRowById(requiredObj.row.id);
        const rowIndex = this.sheet.getRowIndex(row);
        const col = requiredObj.col;
        const colName = headers[col];

        const title = '표준세율 저장';
        const message = `${rowIndex} 행의 ${colName} 열은 필수 입력 항목입니다.`;
        this.$alert({title, message}, () => {
          this.sheet.focus(row, col);
        });
        return;
      }

      if (rows.length) {
        if (!(await this.$confirm({title: '표준세율등록', message: '표준세율을 저장하시겠습니까?'}))) {
          return;
        }
      }

      const param = {list: $_statusToCud(rows)};
      const response = await saveTaxRate(param);

      if (response.status === 200) {
        this.onSearch();
      }
    },
    async onDelete() {
      let checkedRows = this.sheet.getRowsByChecked('cbx');
      if (0 == checkedRows.length) {
        this.$alert({title: '표준세율 삭제', message: '삭제할 대상을 선택해야합니다.'});
        return;
      }

      let deleteList = [];
      checkedRows.forEach(row => {
        if (row.Added) {
          this.sheet.removeRow(row);
        } else {
          deleteList.push({applyDt: IBSheet.dateToString(row.applyDt, 'yyyyMMdd')});
        }
      });

      let length = deleteList.length;
      let title = '표준세율 삭제';
      let message = '표준세율정보를 삭제하시겠습니까?';

      if (0 === length) return;
      if (1 === length) {
        const date = deleteList[0].applyDt.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
        message = `[${date}] ${message}`;
      } else {
        message = `총 ${length}개의 ${message}`;
      }

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      const param = {list: deleteList};
      const response = await deleteTaxRate(param);

      if (response.status === 200) {
        this.onSearch();
      }
    },
  },
};
</script>

<style></style>
