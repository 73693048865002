export default that => {
  return {
    Cfg: {
      CanEdit: false,
    },
    Def: {
      Row: {
        CanFocus: false,
      },
    },
    Cols: [
      {
        Header: '현장코드',
        Name: 'prjCd',
        Type: 'Text',
        Width: 100,
        Align: 'center',
      },
      {
        Header: '현장명',
        Name: 'prjNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
      },
      {
        Header: '투입일자',
        Name: 'rlseDate',
        Extend: that.$ibsheetHelper.preset.date,
      },
      {
        Header: '철수일자',
        Name: 'outDate',
        Extend: that.$ibsheetHelper.preset.date,
      },
    ],
  };
};
