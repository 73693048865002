<template>
  <iui-container-new type="table" theme="bullet">
    <i-row>
      <i-col-header>현장</i-col-header>
      <i-col>
        <iui-text type="code" name="prjCd" :value="prjInfo.prjCd" :enable="false"></iui-text>
        <iui-text type="text" name="prjNm" :value="prjInfo.prjNm" :enable="false"></iui-text>
      </i-col>
    </i-row>
    <i-row>
      <i-col colspan="2">
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col-header>
              최종차수
            </i-col-header>
            <i-col width="190px">
              <iui-text
                type="number"
                name="maxDgree"
                suffix="차"
                width="107px"
                :value="chgDegree"
                :enable="false"
              ></iui-text>
            </i-col>
            <i-col-header>변경차수</i-col-header>
            <i-col>
              <iui-text
                type="number"
                name="chgDegree"
                suffix="차"
                width="107px"
                :value="String(Number(chgDegree || 0) + 1)"
                :enable="false"
              ></iui-text>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
    <i-row>
      <i-col-header>변경사유</i-col-header>
      <i-col>
        <iui-text type="text" name="bldRmk" :value.sync="bldRmk"></iui-text>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
export default {
  props: {
    chgDegree: {
      type: Number,
    },
  },
  data() {
    return {
      prjInfo: {},
      bldRmk: '',
    };
  },
  created() {
    this.prjInfo = this.getPrjInfo();
  },
  methods: {
    getData() {
      return {
        chgDegree: this.chgDegree ?? 0,
        bldRmk: this.bldRmk,
      };
    },
  },
};
</script>

<style scoped>
.bbs_view {
  margin: 5px;
}
</style>
