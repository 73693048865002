<template>
  <pmis-content-box>
    <template #title>계약차수</template>
    <ib-sheet
      @loadSheet="sheet = $event"
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="poContSeqList"
      :events="{
        onRenderFirstFinish: sheet_onRenderFirstFinish,
        onSearchFinish: sheet_onSearchFinish,
        onClick: sheet_onClick,
      }"
    />

    <iui-modal name="poRejectInfoModal" title="협력사반송" size-type="size4">
      <PoRejectInfo />
    </iui-modal>

    <ApprovalPop :parentId="_uid" />
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/poContSeqList.js';
import BiddingApprovalMixin from '@/view/bidding/js/biddingApprovalMixin.js';

import PoRejectInfo from './PoRejectInfo.vue';

export default {
  components: {PoRejectInfo},
  mixins: [BiddingApprovalMixin],
  data() {
    return {sheetOpt: sheetOpt(this)};
  },
  beforeCreate() {
    $mapGetters(this, ['bidType', 'poContSeqList', 'poInfo', 'poStatus', 'rfqInfo']);
    $mapActions(this, ['searchPoContSeqList', 'searchPoInfos']);
  },
  created() {
    this.addEvent([
      {name: 'PoContSeqList_onSearch', func: this.onSearch},
      {name: 'PoContSeqList_FocusContSeq', func: this.focusContSeq},
    ]);
  },
  methods: {
    onSearch(poNo) {
      this.searchPoContSeqList(poNo);
    },
    focusContSeq() {
      if (this.sheet) {
        const focusRow = this.sheet.getDataRows().find(row => row.contSeq === this.poInfo.contSeq);
        this.sheet.focus(focusRow);
      }
    },
    sheet_onRenderFirstFinish(e) {
      const periodCol = {mat: 'dlvPeriod', sub: 'contPeriod'};
      e.sheet.setAttribute(null, periodCol[this.bidType], 'Visible', 1, 1);
    },
    sheet_onSearchFinish(e) {
      if (!e.sheet.getTotalRowCount()) {
        return;
      }

      this.setContSeqData(e.sheet.getFirstRow());

      e.sheet.getDataRows().forEach(row => {
        if (row.poSt === '협력사반송') {
          e.sheet.setAttribute(row, 'poSt', 'TextStyle', 4);
          e.sheet.setAttribute(row, 'poSt', 'Cursor', 'pointer');
        }
      });
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data') {
        this.setContSeqData(e.row).then(() => {
          if (e.col === 'poSt' && e.row.poSt === '협력사반송') {
            this.$modal.show('poRejectInfoModal');
          }

          if (e.col === 'poSncNo' && e.row.poSncNo) {
            const openPoApproval = {mat: 'openPoApproval', sub: 'openSubPoApproval'};
            this[openPoApproval[this.bidType]]();
          }
        });
      }
    },
    async setContSeqData(row) {
      if (row) {
        await this.searchPoInfos(row);
        this.canSave = this.poStatus.isPoReady;
        this.canDelete = this.poStatus.isPoReady;
      }
    },
  },
};
</script>

<style></style>
