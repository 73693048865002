<template>
  <pmis-tab-box>
    <template v-slot:title>정산금액</template>
    <p class="bu_title">금월정산금액</p>
    <form onsubmit="return false;">
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header required width="110px">공급가</i-col-header>
          <i-col>
            <iui-text name="amt" type="amount" :value="detailInfo.amt" width="145px" :enable="false" required />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>부가세</i-col-header>
          <i-col>
            <iui-text
              name="vatAmt"
              type="amount"
              :value="detailInfo.vatAmt"
              @change="setCalculateVatAmt(Number($event.target.value) || 0)"
              width="145px"
              :enable="!detailInfo.billNo && !detailInfo.billTaxNo"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>비과세</i-col-header>
          <i-col>
            <iui-text
              name="taxexe"
              type="amount"
              :value="detailInfo.taxexe"
              @change="setCalculateTaxexe(Number($event.target.value) || 0)"
              width="145px"
              :enable="!detailInfo.billNo && !detailInfo.billTaxNo"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header required>합계</i-col-header>
          <i-col>
            <iui-text name="sumAmt" type="amount" :value="detailInfo.sumAmt" width="145px" :enable="false" readonly />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>세금계산서번호</i-col-header>
          <i-col>
            <iui-text
              type="text"
              name="billNo"
              :value="detailInfo.billNo"
              width="150px"
              max-length="20"
              :readonly="true"
              :css="{cursor: 'pointer', 'text-decoration': 'underline', 'text-align': 'center'}"
              @click="onClick_billNo"
            />
            <pmis-file-list
              name="flNo"
              id="file0"
              flNoCol="flNo"
              title="첨부파일"
              title-visible
              :modal="true"
              :hidden="!!detailInfo.billNo"
              :toolbar-visible="true"
              :file-number.sync="detailInfo.flNo"
              @delete-complete="() => onFileDelete('flNo')"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>계산서번호</i-col-header>
          <i-col>
            <iui-text
              type="text"
              name="billTaxNo"
              :value="detailInfo.billTaxNo"
              width="150px"
              max-length="20"
              :readonly="true"
              :css="{cursor: 'pointer', 'text-decoration': 'underline', 'text-align': 'center'}"
              @click="onClick_billNo"
            />
            <pmis-file-list
              name="flTaxNo"
              id="file1"
              flNoCol="flTaxNo"
              title="첨부파일"
              title-visible
              :modal="true"
              :hidden="!!detailInfo.billTaxNo"
              :toolbar-visible="true"
              :file-number.sync="detailInfo.flTaxNo"
              @delete-complete="() => onFileDelete('flTaxNo')"
            />
          </i-col>
        </i-row>
      </iui-container-new>
    </form>

    <tax-invoice-pop :billNo="billNo" :open.sync="propsOpen" pageGubun="mat" />

    <iui-modal name="PrintModal" title="출력" size-type="size6">
      <report-viewer :fomNo="fomNo" :ozContent="ozContent" />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import {deleteFile, selectReportData} from '@/view/closing/fixedTime/material/api/fixedtimeMaterial.js';
import FOM_NO from '@/constants/fomNoConstants.js';

import TaxInvoicePop from '@/view/bidding/components/TaxInvoicePop.vue';
import ReportViewer from '@/view/bidding/components/ReportViewer.vue';

export default {
  components: {TaxInvoicePop, ReportViewer},
  data: function() {
    return {
      propsOpen: false,
      fileNumber: 0,
      billNo: '',
      fomNo: FOM_NO.SETTLEMENT_FIXED_TIME_MAT,
      ozContent: {},
    };
  },
  watch: {
    detailInfo: {
      handler(value) {
        this.canSave = !value.billNo;
      },
      deep: true,
    },
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'detailInfo', 'closeYn']);
    $mapActions(this, ['setCalculateVatAmt', 'setCalculateTaxexe']);
  },
  created() {
    this.addFuncPrint(this.onPrint);
  },
  methods: {
    async onPrint() {
      const investMm = this.searchInfo.investMm;
      const reportData = (await selectReportData({investMm})).data;

      this.ozContent = {
        investMm: `${investMm.substring(0, 4)}년 ${investMm.substring(4)}월`,
        prjNm: this.getPrjInfo().prjNm,
        settlementList: JSON.stringify({settlementList: reportData}),
      };
      this.$modal.show('PrintModal');
    },
    onClick_billNo(e) {
      console.log(this.detailInfo);
      if (e.target.value) {
        this.billNo = e.target.value;
        this.propsOpen = true;
      }
    },
    async onFileDelete(name) {
      await deleteFile({
        poNo: this.detailInfo.poNo,
        custCode: this.detailInfo.custCode,
        investMm: this.searchInfo.investMm,
        name: name,
      });
    },
  },
};
</script>

<style scoped></style>
