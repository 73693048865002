<template>
  <iui-container-new type="css-flex">
    <i-row height="200px">
      <i-col>
        <pmis-content-box>
          <template #title>계약차수</template>
          <ib-sheet
            :uid="_uid"
            :options="sheetOpt"
            :loadSearchData="poContSeqList"
            @loadSheet="sheet = $event"
            :events="{onClick: sheet_onClick, onSearchFinish: sheet_onSearchFinish}"
          />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-spacer />
    <i-row>
      <i-col>
        <StampDetailInfo :poNo="poInfo.poNo" :contSeq="poInfo.contSeq" />
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import sheetOpt from './sheetOptions/contractStampInfo.js';
import StampDetailInfo from './StampDetailInfo.vue';
export default {
  components: {StampDetailInfo},
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'poContSeqList']);
    $mapActions(this, ['searchPoInfos']);
  },
  activated() {
    if (this.sheet) {
      this.focusContSeq();
    }
  },
  data() {
    return {sheetOpt: sheetOpt(this)};
  },
  methods: {
    async sheet_onClick(e) {
      if (e.row.Kind == 'Data') {
        await this.searchPoInfos(e.row);
        this.callEvent({name: 'StampDetailInfo_onSearch'});
      }
    },
    sheet_onSearchFinish() {
      if (!this._inactive) {
        this.focusContSeq();
      }
    },
    focusContSeq() {
      if (this.sheet.getTotalRowCount()) {
        const focusRow = this.sheet.getDataRows().find(row => row.contSeq === this.poInfo.contSeq);
        this.sheet.focus(focusRow);
        this.callEvent({name: 'StampDetailInfo_onSearch'});
      }
    },
  },
};
</script>

<style></style>
