export default that => {
  return {
    Cfg: {
      MainCol: 'fldrNm', // 트리가 표시될 열이름
      CanEdit: false,
    },
    Def: {Header: {Hidden: true}},
    Cols: [
      {Header: '명칭', Name: 'fldrNm', Type: 'Text', RelWidth: 1, MinWidth: 200},
      {Header: '코드', Name: 'fldrCd', Type: 'Text', Width: 200, Align: 'center'},

      {Name: 'uppFldrNo', Visible: false},
      {Name: 'uppFldrCd', Visible: false},
      {Name: 'stYn', Visible: false},
      {Name: 'fldrNo', Visible: false},
    ],
  };
};
