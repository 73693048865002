export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      ReqStatusName: 'status',
      HeaderMerge: 3,
      HeaderCheck: 1,
    },
    Cols: [
      {
        Name: 'cbx',
        Header: [{Value: '선택', TextColor: '#000000', Align: 'center', HeaderCheck: 1}, '선택'],
        Align: 'center',
        Width: 70,
        Type: 'Bool',
      },
      {
        Name: 'applyDt',
        Header: ['적용일자', '적용일자'],
        Extend: preset.date,
        RelWidth: 1,
        CanEdit: true,
      },
      {
        Name: 'entryDt',
        Header: ['4대보험', '가입기준일'],
        Align: 'center',
        Type: 'Text',
        RelWidth: 1,
      },
      {
        Name: 'hisRt',
        Header: ['4대보험', '건강보험요율(%)'],
        Extend: preset.rate3,
        RelWidth: 1,
      },
      {
        Name: 'careRt',
        Header: ['4대보험', '장기요양보험요율(%)'],
        Extend: preset.rate3,
        RelWidth: 1,
      },
      {
        Name: 'psRt',
        Header: ['4대보험', '국민연금요율(%)'],
        Extend: preset.rate3,
        RelWidth: 1,
      },
      {
        Name: 'employInsurRt',
        Header: ['4대보험', '고용보험요율(%)'],
        Extend: preset.rate3,
        RelWidth: 1,
      },
      {
        Name: 'incomedcAmt',
        Header: ['갑근세', '근로소득공제(원)'],
        Extend: preset.amount,
        RelWidth: 1,
      },
      {
        Name: 'incometaxRt',
        Header: ['갑근세', '근로소득세율(%)'],
        Extend: preset.rate3,
        RelWidth: 1,
      },
      {
        Name: 'incometaxDcrt',
        Header: ['갑근세', '근로소득세액공제(%)'],
        Extend: preset.rate3,
        RelWidth: 1,
      },
      {
        Name: 'regiRt',
        Header: ['지방소득세율(%)', '지방소득세율(%)'],
        Extend: preset.rate3,
        RelWidth: 1,
      },
    ],
  };
};
