export default that => {
  return {
    Cfg: {
      CanEdit: 0,
      DataMerge: 1,
    },
    Cols: [
      {
        Header: ['NO'],
        Name: 'rownum',
        Type: 'Text',
        Align: 'center',
        Width: '50',
      },
      {
        Header: ['가동일자'],
        Name: 'runDt',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['실행중공종코드'],
        Name: 'midCode',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['실행내역코드'],
        Name: 'itemSeq',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['장비순번'],
        Name: 'seq',
        Type: 'Text',
        Align: 'center',
        Width: 85,
        ColMerge: 0,
      },
      {
        Header: ['장비코드'],
        Name: 'equipCode',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['차량번호'],
        Name: 'carNo',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['유종'],
        Name: 'useOil',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['유량'],
        Name: 'oilQty',
        Type: 'Text',
        Align: 'Right',
        Width: 85,
        ColMerge: 0,
      },
      {
        Header: ['단가'],
        Name: 'oilPrice',
        Type: 'Text',
        Align: 'Right',
        RelWidth: 1,
        ColMerge: 0,
      },
      {
        Header: ['주유업체'],
        Name: 'oilFrmNo',
        Type: 'Text',
        Align: 'Right',
        RelWidth: 1,
        ColMerge: 0,
      },
    ],
  };
};
