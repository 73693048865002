<template>
  <!-- 경비투입-포틀릿 -->
  <div>
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr>
          <th>거래처</th>
          <th>공급가</th>
          <th>부가세</th>
          <th>비과세</th>
          <th>합계</th>
        </tr>
      </thead>
    </table>
    <div class="inner" style="height:58px">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="(data, index) in expenseInputList" :key="index">
            <td>{{ data.custName }}</td>
            <td>{{ data.amt }}</td>
            <td>{{ data.vatAmt }}</td>
            <td>{{ data.taxexe }}</td>
            <td class="p_last">{{ data.sumAmt }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
import {selectExptInput} from '@/components/portlet/api/portletData.js';
export default {
  mixins: [PortletMixin],
  props: {
    curDate: {
      Type: String,
    },
  },
  data() {
    return {
      cnt: 5,
      expenseInputList: [],
    };
  },
  watch: {
    curDate() {
      this.onSearch();
    },
  },
  methods: {
    setWidth() {
      this.tblWidth = this.$el.offsetWidth - 28;
    },
    onSearch() {
      selectExptInput({prjCd: this.prjCd, workDt: this.curDate}).then(
        response => (this.expenseInputList = response.data)
      );
    },
  },
};
</script>

<style></style>
