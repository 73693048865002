<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>출역내역</template>
            <AttendanceList />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal name="excelUploadPop" title="엑셀등록" :btns="excelBtns" sizeType="size1">
      <ExcelUpload
        uri="/attendanceRegist/excelUpload"
        title="일일출역등록"
        :rows="excelRows"
        @error="onExcelUploadError"
      />
    </iui-modal>
    <iui-modal name="ExcelUploadResultPop" title="엑셀 업로드 실패 사유" sizeType="size6" @closed="resultList = []">
      <ExcelUploadResult :sheet-opt="excelSheetOpt" :list="resultList" />
    </iui-modal>
  </pmis-page>
</template>

<script>
/**
 * 일일출역등록
 * components :
 *   PrjList 현장목록
 *   AttendanceList : 출역내역
 *
 * */
import store from './store/AttendanceRegist.js';
import AttendanceList from './components/AttendanceList.vue';
import PrjList from '@/view/Project/components/PrjList.vue';
import excelOptions from '@/view/Resource/Labor/AttendanceRegist/components/sheetOptions/ExcelUploadResultSheet';
import ExcelUploadResult from '@/components/popup/ExcelUploadResult.vue';
import ExcelUpload from '@/components/popup/ExcelUpload.vue';

export default {
  components: {ExcelUploadResult, ExcelUpload, PrjList, AttendanceList},
  data() {
    return {
      excelBtns: [{name: '확인', callback: this.onPopConfirmExcel}],
      resultList: [],
      excelRows: [
        {
          value: [
            {value: '출역일자', style: {type: 'TEXT'}},
            {value: 'ID', style: {type: 'TEXT'}},
            {value: '출근시간', style: {type: 'TEXT'}},
            {value: '퇴근시간', style: {type: 'TEXT'}},
            {value: '공제시간'},
            {value: '일공수'},
            {value: '실행중공종코드', style: {type: 'TEXT'}},
            {value: '내역코드', style: {type: 'TEXT'}},
            {value: '실행코드', style: {type: 'TEXT'}},
            {value: '단위작업분류', style: {type: 'TEXT'}},
            {value: '단위작업코드', style: {type: 'TEXT'}},
            {value: '시작시간', style: {type: 'TEXT'}},
            {value: '종료시간', style: {type: 'TEXT'}},
            {value: '투입공수'},
            {value: '외주 계약번호', style: {type: 'TEXT'}},
          ],
        },
      ],
      excelSheetOpt: excelOptions(this),
    };
  },
  beforeCreate() {
    $init(this, store);
  },
  methods: {
    onPopConfirmExcel() {
      this.callEvent({
        name: 'excelUpload',
        param: () => {
          this.callEvent({name: 'AttendanceList_onSearch'});
          this.$modal.hide('excelUploadPop');
        },
      });
    },
    onExcelUploadError(error, list) {
      if (error === 'server') {
        this.$alert({title: '엑셀업로드', message: '일일출역등록 엑셀파일을 확인하십시오.'});
      } else {
        this.$alert({title: '엑셀업로드', message: error});
        if (list && list.length > 0) {
          this.resultList = list;
          this.$modal.show('ExcelUploadResultPop');
        }
      }
    },
  },
};
</script>

<style scoped></style>
