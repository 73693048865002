const state = {
  grpAuthInfo: {
    seq: '', // 그룹권한
    originSeq: '', // 기존그룹권한
  },
};

const getters = {
  grpAuthInfo: state => state.grpAuthInfo,
};

const actions = {};

const mutations = {
  setGrpAuthInfo(state, payload) {
    for (let key in state.grpAuthInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.grpAuthInfo[key] = payload[key];
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
