export default that => {
  return {
    Cfg: {
      CanEdit: false,
    },
    Cols: [
      {
        Header: '아이디(사번)',
        Name: 'empNo',
        Type: 'Text',
        Align: 'Center',
        Width: 120,
      },
      {
        Header: '이름',
        Name: 'name',
        Type: 'Text',
        Align: 'Center',
        Width: 120,
      },
      {
        Header: '부서명',
        Name: 'blnDepNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
      },
      {Name: 'labtClose', Visible: false},
    ],
  };
};
