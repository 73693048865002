var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(_vm.theme != 'bullet' ? 'border: 1px solid #ccc' : '')},[_c('file-upload',{ref:"upload",staticStyle:{"display":"none"},attrs:{"extensions":"gif,jpg,jpeg,png,webp,jfif","accept":"image/png,image/gif,image/jpeg,image/webp","headers":_vm.postHeader,"post-action":_vm.internalPostAction,"name":"files","drop":_vm.allowAdd,"multiple":false},on:{"input":_vm.fileUpload_input,"input-filter":_vm.fileUpload_inputFilter,"input-file":_vm.fileUpload_inputFile},model:{value:(_vm.internalFiles),callback:function ($$v) {_vm.internalFiles=$$v},expression:"internalFiles"}}),(_vm.theme == 'bullet')?_c('div',[_c('iui-container-new',{attrs:{"type":"table","theme":"bullet"}},[_c('i-row',[_c('i-col-header',{attrs:{"width":"30%"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('i-col',[_c('div',{staticStyle:{"width":"calc(100% - 7px)","display":"flex","justify-content":"flex-end"}},[(_vm.allowAdd && _vm.isAuth)?_c('iui-button',{attrs:{"enable":_vm.buttonEnable},on:{"click":_vm.btnAdd_click}},[_vm._v(_vm._s(_vm.addButtonText === '추가' && _vm.fileNumber ? _vm.updateButtonText : _vm.addButtonText)+" ")]):_vm._e(),(_vm.allowDelete && _vm.isAuth && _vm.fileNumber)?_c('iui-button',{attrs:{"enable":_vm.buttonEnable},on:{"click":_vm.btnDelete_click}},[_vm._v(_vm._s(_vm.deleteButtonText)+" ")]):_vm._e(),(_vm.fileNumber != 0)?[_c('iui-button',{on:{"click":_vm.onDownload}},[_vm._v("다운로드")])]:_vm._e()],2)])],1),_c('i-row',[_c('i-col',{attrs:{"colspan":"2"}},[(!_vm.internalImageSource && (_vm.internalFileNumber == 0 || !_vm.internalFileNumber))?_c('div',{ref:"img",staticClass:"no_image",style:({
              display: 'block',
              margin: '1px auto',
              width: _vm.imageWidth,
              height: _vm.calcHeight,
              maxWidth: _vm.imageMaxWidth,
              maxHeight: _vm.imageMaxHeight,
              overflow: 'hidden',
              border: '1px solid #ccc',
            })}):_c('img',{ref:"img",style:({
              display: 'block',
              margin: '1px auto',
              width: _vm.imageWidth,
              height: _vm.calcHeight,
              maxWidth: _vm.imageMaxWidth,
              maxHeight: _vm.imageMaxHeight,
              overflow: 'hidden',
              border: '1px solid #ccc',
            }),attrs:{"src":_vm.imageSource,"onerror":_vm.imageError}})])],1)],1)],1):_c('div',[_c('iui-content-box',{scopedSlots:_vm._u([(_vm.title)?{key:"header-left",fn:function(){return [_c('label',[_vm._v(_vm._s(_vm.title))])]},proxy:true}:null,(_vm.allowAdd || _vm.allowDelete || _vm.saveButtonVisible)?{key:"header-right",fn:function(){return [(_vm.allowAdd && _vm.isAuth)?_c('iui-button',{attrs:{"enable":_vm.buttonEnable},on:{"click":_vm.btnAdd_click}},[_vm._v(_vm._s(_vm.addButtonText === '추가' && _vm.fileNumber ? _vm.updateButtonText : _vm.addButtonText)+" ")]):_vm._e(),(_vm.allowDelete && _vm.isAuth && _vm.fileNumber)?_c('iui-button',{attrs:{"enable":_vm.buttonEnable},on:{"click":_vm.btnDelete_click}},[_vm._v(_vm._s(_vm.deleteButtonText)+" ")]):_vm._e(),(_vm.fileNumber != 0)?[_c('iui-button',{on:{"click":_vm.onDownload}},[_vm._v("다운로드")])]:_vm._e()]},proxy:true}:null],null,true)},[(!_vm.internalImageSource && (_vm.internalFileNumber == 0 || !_vm.internalFileNumber))?_c('div',{ref:"img",staticClass:"no_image",style:({
          display: 'block',
          margin: '1px auto',
          width: _vm.imageWidth,
          height: _vm.calcHeight,
          //maxWidth: imageMaxWidth,
          maxHeight: _vm.imageMaxHeight,
          overflow: 'hidden',
        })}):_c('img',{ref:"img",style:({
          display: 'block',
          margin: '1px auto',
          width: _vm.imageWidth,
          height: _vm.calcHeight,
          //maxWidth: imageMaxWidth,
          maxHeight: _vm.imageMaxHeight,
          overflow: 'hidden',
        }),attrs:{"src":_vm.imageSource,"onerror":_vm.imageError}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }