export default that => {
  return {
    Cfg: {
      Hover: 1,
      CanEdit: 0,
    },
    Def: {
      Row: {
        CanFocus: 0,
      },
    },
    Cols: [
      {
        Header: '번호',
        Name: 'no',
        Type: 'Text',
        Width: 80,
        Align: 'center',
      },
      {
        Header: '업무구분',
        Name: 'tskDsNm',
        Type: 'Text',
        Width: 150,
        Align: 'Center',
      },
      {
        Header: '제목',
        Name: 'tl',
        Type: 'Html',
        RelWidth: 1,
        TextStyle: 4,
        Align: 'Left',
        ColMerge: 0,
        Cursor: 'pointer',
      },
      {
        Header: '등록자',
        Name: 'rgprNm',
        Type: 'Text',
        Width: 140,
        Align: 'Center',
      },
      {
        Header: '등록일',
        Name: 'rgsDttm',
        Type: 'Text',
        Width: 140,
        Align: 'Center',
      },
      {
        Header: '조회',
        Name: 'inqCnt',
        Type: 'Text',
        Width: 140,
        Align: 'Center',
        Visible: true,
      },
    ],
  };
};
