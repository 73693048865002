const state = {
  sideOpen: true,
};

const getters = {
  isSideOpen: state => state.sideOpen,
};

const actions = {
  sideToggle({state}) {
    state.sideOpen = !state.sideOpen;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
};
