<template>
  <pmis-content-box>
    <ib-sheet :uid="_uid" :options="sheetOpt" :loadSearchData="list" :events="{onSearchFinish: onSearchFinish}" />
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
    },
    sheetOpt: {
      type: Object,
    },
  },
  methods: {
    onSearchFinish(e) {
      e.sheet
        .getDataRows()
        .filter(x => x.type === 'MESSAGE')
        .forEach(row => {
          e.sheet.getCols().forEach(col => {
            if (row[col] != '') {
              row[col + 'Color'] = '#ADADAD';
              row[col + 'TextColor'] = '#FF0000FF';
              e.sheet.refreshCell({row: row, col: col});
            }
          });
        });
    },
  },
};
</script>

<style></style>
