export default that => {
  return {
    Cfg: {
      CanEdit: false,
      DataMerge: 1,
    },
    Def: {
      Col: {
        ColMerge: false,
      },
    },
    LeftCols: [
      {
        Name: 'subCodeName',
        Header: ['외주명', '외주명', '외주명'],
        Width: 150,
        Type: 'Text',
        ColMerge: true,
        VAlign: 'Top',
      },
      {
        Name: 'midName',
        Header: ['계약내역', '공종명', '공종명'],
        Width: 150,
        Type: 'Text',
        ColMerge: true,
        VAlign: 'Top',
      },
      {
        Name: 'itemName',
        Header: ['계약내역', '내역명', '내역명'],
        Width: 150,
        Type: 'Text',
      },
    ],
    Cols: [
      {
        Name: 'qty',
        Header: ['계약내역', '수량', '수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 80,
        FormulaRow: 'Sum',
      },
      {
        Name: 'amt',
        Header: ['계약내역', '금액', '금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 90,
        FormulaRow: 'Sum',
      },

      {
        Name: 'dayQty',
        Header: ['투입', '금일투입', '수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 80,
        FormulaRow: 'Sum',
      },
      {
        Name: 'dayAmt',
        Header: ['투입', '금일투입', '금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 90,
        FormulaRow: 'Sum',
      },
      {
        Name: 'monthQty',
        Header: ['투입', '금월투입', '수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 80,
        FormulaRow: 'Sum',
      },
      {
        Name: 'monthAmt',
        Header: ['투입', '금월투입', '금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 90,
        FormulaRow: 'Sum',
      },
      {
        Name: 'totQty',
        Header: ['투입', '누계투입', '수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 80,
        FormulaRow: 'Sum',
      },
      {
        Name: 'totAmt',
        Header: ['투입', '누계투입', '금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 90,
        FormulaRow: 'Sum',
      },
      {
        Name: 'prgRt',
        Header: ['투입', '누계투입', '집행률'],
        Extend: that.$ibsheetHelper.preset.rate,
        Width: 80,
      },
      {
        Name: 'remainQty',
        Header: ['잔여', '수량', '수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 80,
        FormulaRow: 'Sum',
      },
      {
        Name: 'remainAmt',
        Header: ['잔여', '금액', '금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 90,
        FormulaRow: 'Sum',
      },
    ],
  };
};
