<template>
  <pmis-page ref="page" :search-project-visible="false" @search-click="onSearch">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-content-box title-type="tab" border-box>
            <template v-slot:title>투입목록</template>
            <template v-slot:header-left>
              <div>출역일자</div>
              <iui-datepicker
                name="searchFrDt"
                :value="searchFrDt"
                @change="
                  event => {
                    searchFrDt = event;
                    onSearch();
                  }
                "
              />
              <div>~</div>
              <iui-datepicker
                name="searchToDt"
                :value="searchToDt"
                @change="
                  event => {
                    searchToDt = event;
                    onSearch();
                  }
                "
              />
              <div>성명</div>
              <iui-text
                type="search"
                :value="searchText"
                @change="searchText = $event.target.value"
                @enter="onSearch"
              />
              <iui-button value="검색" @click="onSearch" />
            </template>
            <ib-sheet
              :uid="_uid"
              :options="sheetOpt"
              :loadSearchData="loadSearchData"
              @loadSheet="sheet_loadSheet"
              :events="{onSearchFinish: sheet_onSearchFinish, onRenderFirstFinish: sheet_onRenderFirstFinish}"
            />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>

    <!-- 출/퇴근정보 -->
    <!-- <iui-modal name="attendanceModal" :title="modalTitle" sizeType="size1">
      <AttendanceInfoPopup />
    </iui-modal> -->
  </pmis-page>
</template>

<script>
/**
 * 노무투입조회
 * components :
 *   PrjList 현장목록
 *   AttendanceInfoPopup : 투입내역
 *
 * */
import PrjList from '@/view/Project/components/PrjList.vue';
import sheetOpt from './components/sheetOption/LaborInputInfo.js';
import {selectLaborInputList} from './api/LaborInputInfo.js';
//import AttendanceInfoPopup from '@/view/Resource/Labor/AttendanceRegist/components/AttendanceInfoPopup.vue';

export default {
  components: {PrjList},
  data() {
    return {
      loadSearchData: [],
      sheetOpt: sheetOpt(this),
      sheet: {},

      searchFrDt: '',
      searchToDt: '',
      searchText: '',
    };
  },
  created() {
    this.addEvent([{name: 'set_searchInfo', func: this.onPrjChange}]);
  },
  methods: {
    sheet_loadSheet(sheet) {
      this.sheet = sheet;
    },
    sheet_onSearchFinish() {
      this.callEvent({name: 'hideLoading'});
    },
    sheet_onRenderFirstFinish(e) {
      e.sheet.setAutoMerge({dataMerge: 1, headerMerge: 4, prevColumnMerge: 1, fixPrevColumnMerge: 'investDt'});
    },
    onPrjChange() {
      this.onInitSearch();
      this.onSearch();
    },
    onSearch() {
      const searchInfo = {searchFrDt: this.searchFrDt, searchToDt: this.searchToDt, searchText: this.searchText};
      selectLaborInputList(searchInfo).then(response => {
        response.data.forEach(row => {
          row.labPrice = row.price;
        });
        this.loadSearchData = response.data;
      });
    },
    onInitSearch() {
      this.searchText = '';

      const currDate = $_getCurrentDate();
      const frDate = new Date(currDate.substr(0, 4), currDate.substr(4, 2) - 1, currDate.substr(6, 2));
      const toDate = new Date(currDate.substr(0, 4), currDate.substr(4, 2) - 1, currDate.substr(6, 2));
      frDate.setMonth(frDate.getMonth() - 1);

      this.searchFrDt = $_dateToStringYYMMDD(frDate);
      this.searchToDt = $_dateToStringYYMMDD(toDate);
    },
  },
};
</script>

<style scoped></style>
