<template>
  <pmis-content-box>
    <template v-slot:header-left>
      출역일자 <iui-datepicker :value="inputDate" :enable="false" />
      <div class="mr10"></div>
      <div>예정기간</div>
      <iui-datepicker
        :group="{grpNm: 'planDate', seq: 1}"
        :value="displayPlanStrdate"
        @change="setData('displayPlanStrdate', $event)"
      />
      <div>~</div>
      <iui-datepicker
        :group="{grpNm: 'planDate', seq: 2}"
        :value="displayPlanEnddate"
        @change="setData('displayPlanEnddate', $event)"
      />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="300px">
          <pmis-tab-box>
            <template v-slot:title>단위작업목록</template>
            <UnitOpertList
              ref="unitOpertList"
              :costType="costType"
              :inputDate="inputDate"
              :planStrdate="planStrdate"
              :planEnddate="planEnddate"
              @onPlanStrdate="onPlanStrdate"
              @onPlanEnddate="onPlanEnddate"
              @onRow="onRow"
            />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>{{ title }}</template>
            <PoSubList
              v-if="poNoSubcList.length"
              ref="poSubList"
              :poNoSubcList="poNoSubcList"
              :wbsCd="wbsCd"
              :wbsId="wbsId"
              :costType="costType"
              :jkdNo="jkdNo"
              @onRow="onRowExec"
            />
            <ExecDtlsList
              v-else
              ref="execDtlsList"
              :wbsCd="wbsCd"
              :wbsId="wbsId"
              :costType="costType"
              @onRow="onRowExec"
            />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import UnitOpertList from './UnitOpertList.vue';
import ExecDtlsList from './ExecDtlsList.vue';
import PoSubList from './SubPoList.vue';
export default {
  components: {
    UnitOpertList,
    ExecDtlsList,
    PoSubList,
  },
  props: {
    costType: {
      type: String,
    },
    poNoSubcList: {
      type: Array,
      default: () => [],
    },
    jkdNo: {
      type: String,
    },
  },
  data() {
    return {
      inputDate: $_getCurrentDate(),
      displayPlanStrdate: '',
      displayPlanEnddate: '',
      planStrdate: '',
      planEnddate: '',
      wbsCd: undefined,
      wbsId: undefined,
      title: this.poNoSubcList.length ? '계약내역' : '실행내역',
      result: {},
    };
  },
  watch: {
    displayPlanStrdate: function() {
      this.planStrdate = this.displayPlanStrdate;
      this.planEnddate = this.displayPlanEnddate;
    },
    displayPlanEnddate: function() {
      this.planStrdate = this.displayPlanStrdate;
      this.planEnddate = this.displayPlanEnddate;
    },
  },
  created() {
    this.addEvent({name: 'getUnitOpertWithExecDtlsData', func: this.getUnitOpertWithExecDtlsData});
  },
  methods: {
    setData(key, value) {
      this[key] = value;
    },
    onSearch() {
      this.$refs.unitOpertList.onSearch();
    },
    onPlanStrdate(planStrdate) {
      this.displayPlanStrdate = planStrdate;
    },
    onPlanEnddate(planEnddate) {
      this.displayPlanEnddate = planEnddate;
    },
    onRow(row) {
      if (!row.childNodes.length && !row.fldrNo) {
        return;
      }
      this.wbsCd = row.fldrNo;
      this.wbsId = !row.folder ? row.fldrCd : undefined;
      setTimeout(() => {
        this.$refs[this.poNoSubcList.length ? 'poSubList' : 'execDtlsList'].onSearch();
      }, 10);
    },
    onRowExec(row) {
      this.result = {
        wcode: row.wcode,
        headCode: row.headCode,
        midCode: row.midCode,
        mgName: row.mgName ?? row.srvNm,
        itemSeq: row.itemSeq,
        itemCode: row.itemCode,
        itemName: row.itemName ?? row.itemNm,
        wbsNm: row.wbsNm,
        wbsCd: row.wbsCd,
        wbsId: row.wbsId,
      };
    },
    getUnitOpertWithExecDtlsData(callback) {
      callback(this.result);
    },
  },
};
</script>
