<template>
  <form onsubmit="return false;">
    <iui-container-new type="css-flex">
      <i-row height="30px">
        <i-col min-width="700px">
          <iui-container-new type="table" theme="bullet">
            <colgroup>
              <col style="width: 100px;" />
              <col />
              <col style="width: 90px;" />
              <col />
            </colgroup>
            <i-row>
              <i-col-header required>
                사용구분
              </i-col-header>
              <i-col>
                <iui-select
                  defaultCd="S"
                  :p-code="$getConstants('USE_GB').code"
                  name="useGb"
                  :value="detailInfo.useGb"
                  :enable="isNew"
                  @change="value => onUseGbChange(value)"
                  required
                  :error-message="{title: '필수입력', message: '사용구분을 선택해야 합니다.'}"
                />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
      <i-row>
        <i-col min-width="700px">
          <iui-container-new type="css-flex">
            <i-row height="170px">
              <i-col>
                <pmis-content-box>
                  <template v-slot:title>
                    장비정보
                  </template>
                  <iui-container-new type="table" theme="bullet">
                    <colgroup>
                      <col style="width: 100px;" />
                      <col />
                      <col style="width: 90px;" />
                      <col />
                    </colgroup>
                    <i-row>
                      <i-col-header required>
                        장비코드
                      </i-col-header>
                      <i-col colspan="3">
                        <div class="iui-searchbox">
                          <iui-text
                            name="equipCode"
                            width="150px"
                            :value="detailInfo.equipCode"
                            :enable="false"
                            required
                            :error-message="{title: '필수입력', message: '장비코드를 선택해야 합니다.'}"
                          />
                          <iui-button
                            type="button"
                            class="flex-0"
                            btn-class="search-button"
                            @click="onShowModal('equipCode')"
                            v-if="isNew && (isHoldEquipment || isRentEquipment)"
                          ></iui-button>
                          <iui-text
                            name="itemName"
                            :value="detailInfo.itemName"
                            :enable="false"
                            required
                            :error-message="{title: '필수입력', message: '장비코드를 선택해야 합니다.'}"
                          />
                        </div>
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>
                        규격
                      </i-col-header>
                      <i-col>
                        <iui-text name="size" width="150px" :value="detailInfo.size" :enable="false" />
                      </i-col>
                      <i-col-header>단위</i-col-header>
                      <i-col>
                        <iui-text :value="unitNm" :enable="false" width="90px" />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header required>
                        차량번호
                      </i-col-header>
                      <i-col>
                        <iui-text
                          name="carNo"
                          width="150px"
                          :value="detailInfo.carNo"
                          @change="setDetailInfo({carNo: $event.target.value})"
                          max-length="20"
                          :enable="isRentEquipment && isNew"
                          :required="isRentEquipment"
                          :error-message="{title: '필수입력', message: '차량번호를 입력해야 합니다.'}"
                        />
                      </i-col>
                      <i-col-header required>
                        사용유류
                      </i-col-header>
                      <i-col>
                        <iui-text v-if="isHoldEquipment" :value="useOilNm" :enable="false" width="90px" />
                        <iui-select
                          v-else
                          name="useOil"
                          width="90px"
                          :value="detailInfo.useOil"
                          @change="value => setDetailInfo({useOil: value})"
                          :items="oilItems"
                          :defaultCd="'S'"
                          :enable="isRentEquipment"
                          :required="isRentEquipment"
                          :error-message="{title: '필수입력', message: '사용유류를 선택해야 합니다.'}"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header required>
                        시간당가동단가
                      </i-col-header>
                      <i-col>
                        <iui-text
                          type="unitAmount"
                          name="runPrice"
                          width="150px"
                          :value="detailInfo.runPrice"
                          @change="setDetailInfo({runPrice: $event.target.value})"
                          :enable="isHoldEquipment || isRentEquipment"
                          :required="isHoldEquipment || isRentEquipment"
                          :error-message="{title: '필수입력', message: '시간당 가동단가를 입력해야 합니다.'}"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header required>
                        투입일자
                      </i-col-header>
                      <i-col>
                        <iui-datepicker
                          name="rlseDate"
                          :group="{grpNm: 'rlseOutDate', seq: 1}"
                          :value="detailInfo.rlseDate"
                          @change="setDetailInfo({rlseDate: $event})"
                          :enable="isHoldEquipment || isRentEquipment"
                          :required="isHoldEquipment || isRentEquipment"
                          :error-message="{title: '필수입력', message: '투입일자를 선택해야 합니다.'}"
                        />
                      </i-col>
                      <i-col-header>
                        철수일자
                      </i-col-header>
                      <i-col>
                        <iui-datepicker
                          name="outDate"
                          :group="{grpNm: 'rlseOutDate', seq: 2}"
                          :value="detailInfo.outDate"
                          @change="setDetailInfo({outDate: $event})"
                          :enable="isHoldEquipment || isRentEquipment"
                        />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </pmis-content-box>
              </i-col>
            </i-row>
            <i-row height="90px">
              <i-col>
                <pmis-content-box>
                  <template v-slot:title>운전자</template>
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header width="100px" :required="isRentEquipment">
                        기사성명
                      </i-col-header>
                      <i-col>
                        <template v-if="isRentEquipment || isNotSelectEquipment">
                          <iui-text
                            name="name"
                            width="90px"
                            :value="detailInfo.name"
                            @change="setDetailInfo({name: $event.target.value})"
                            max-length="50"
                            :enable="isRentEquipment"
                            :required="isRentEquipment"
                            :error-message="{title: '필수입력', message: '기사성명을 입력해야 합니다.'}"
                          />
                        </template>
                        <template v-if="isHoldEquipment">
                          <div class="iui-searchbox">
                            <iui-text
                              name="mainOperatorId"
                              width="90px"
                              :value="detailInfo.mainOperatorId"
                              :enable="false"
                            />
                            <iui-button class="flex-0" btn-class="search-button" @click="onShowModal('WorkerList')" />
                            <iui-text name="name" width="115px" :value="detailInfo.name" :enable="false" />
                          </div>
                        </template>
                      </i-col>
                      <i-col-header :required="isRentEquipment">
                        휴대폰
                      </i-col-header>
                      <i-col>
                        <iui-text
                          type="phone"
                          name="mobileTlno"
                          width="150px"
                          :value="detailInfo.mobileTlno"
                          @change="setDetailInfo({mobileTlno: $event.target.value})"
                          :enable="isRentEquipment"
                          :required="isRentEquipment"
                          :error-message="{title: '필수입력', message: '휴대폰 번호를 입력해야 합니다.'}"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>
                        면허번호
                      </i-col-header>
                      <i-col>
                        <iui-text
                          name="mainOperatorNo"
                          width="230px"
                          :value="detailInfo.mainOperatorNo"
                          @change="setDetailInfo({mainOperatorNo: $event.target.value})"
                          max-length="30"
                          :enable="isHoldEquipment || isRentEquipment"
                        />
                      </i-col>
                      <i-col-header>
                        면허유효기간
                      </i-col-header>
                      <i-col>
                        <iui-datepicker
                          name="mainOperatorStrdate"
                          :group="{grpNm: 'mainOperatorDate', seq: 1}"
                          :value="detailInfo.mainOperatorStrdate"
                          @change="setDetailInfo({mainOperatorStrdate: $event})"
                          :enable="isHoldEquipment || isRentEquipment"
                        />
                        <div>~</div>
                        <iui-datepicker
                          name="mainOperatorEnddate"
                          :group="{grpNm: 'mainOperatorDate', seq: 2}"
                          :value="detailInfo.mainOperatorEnddate"
                          @change="setDetailInfo({mainOperatorEnddate: $event})"
                          :enable="isHoldEquipment || isRentEquipment"
                        />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </pmis-content-box>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex" min-height="200px" min-width="420px" v-if="isRentEquipment">
            <i-row>
              <i-col>
                <pmis-content-box min-height="200px">
                  <template v-slot:title>
                    임차정보
                  </template>
                  <iui-container-new type="table" theme="bullet">
                    <colgroup>
                      <col style="width: 100px;" />
                      <col />
                      <col style="width: 90px;" />
                      <col />
                    </colgroup>
                    <i-row>
                      <i-col-header required>거래처</i-col-header>
                      <i-col colspan="3">
                        <iui-searchbox
                          type="customer"
                          :idValue="detailInfo.custCode"
                          :nameValue="detailInfo.frmNm"
                          name="custCode"
                          nameEleName="frmNm"
                          :paramObj="{frmDs: $getConstants('FRM_DS_EQUIP').code}"
                          :required="isRentEquipment"
                          :error-message="{title: '거래처', message: '거래처를 선택해야합니다.'}"
                          :callback="onCallback_CustomerPopup"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>대표자</i-col-header>
                      <i-col>
                        <iui-text name="rpsvNm" width="150px" :value="detailInfo.rpsvNm" :enable="false" />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>전화번호</i-col-header>
                      <i-col>
                        <iui-text
                          type="phone"
                          name="rpsvPhone"
                          width="150px"
                          :value="detailInfo.rpsvPhone"
                          :enable="false"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>임차일자</i-col-header>
                      <i-col>
                        <iui-datepicker
                          name="lentDate"
                          :value="detailInfo.lentDate"
                          @change="setDetailInfo({lentDate: $event})"
                          :enable="isRentEquipment"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>유류대공제여부</i-col-header>
                      <i-col>
                        <iui-checkbox-group
                          name="oilDeductDs"
                          :p-code="$getConstants('OIL_DEDUCT_DS').code"
                          :checkedValues="[detailInfo.oilDeductDs]"
                          :enable="isRentEquipment"
                          @change="onChangeOilDeductDs"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>보험기간</i-col-header>
                      <i-col colspan="3">
                        <iui-datepicker
                          name="insuStDt"
                          :group="{grpNm: 'insuDt', seq: 1}"
                          :value="detailInfo.insuStDt"
                          @change="setDetailInfo({insuStDt: $event})"
                          :enable="isRentEquipment"
                        />
                        <div>~</div>
                        <iui-datepicker
                          name="insuEtDt"
                          :group="{grpNm: 'insuDt', seq: 2}"
                          :value="detailInfo.insuEtDt"
                          @change="setDetailInfo({insuEtDt: $event})"
                          :enable="isRentEquipment"
                        />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </pmis-content-box>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
      <iui-modal name="equipCodeModal" :btns="modalBtns" :title="modalTitle" sizeType="size2">
        <HoldEqupmnListPop :uid="_uid" />
      </iui-modal>

      <iui-modal name="WorkerListModal" :btns="modalBtns" :title="'사원정보'" sizeType="size1">
        <UserListPopup :uid="_uid" />
      </iui-modal>
    </iui-container-new>
  </form>
</template>

<script>
/*
 * 가동장비등록 > 상세정보
 *   components :
 *     HoldEquipmentListPopup.vue 장비코드 조회 팝업
 *     WorkerListPopup.vue 운전자 조회 팝업
 *
 * */
import HoldEqupmnListPop from './HoldEquipmentListPopup.vue';
import UserListPopup from '@/view/Resource/components/WorkerListPopup.vue';

export default {
  components: {HoldEqupmnListPop, UserListPopup},
  watch: {
    detailInfo: {
      handler(obj) {
        $getCodeNm($getConstants('UNIT').code, obj.unit).then(response => {
          //단위
          this.unitNm = response;
        });
        $getCodeNm($getConstants('USE_OIL').code, obj.useOil).then(response => {
          // 사용유류
          this.useOilNm = response;
        });
      },
      deep: true,
    },
  },
  computed: {
    isNew() {
      return this.cud == '1'; // 신규
    },
    isNotSelectEquipment() {
      return this.detailInfo.useGb == '';
    },
    isHoldEquipment() {
      return this.detailInfo.useGb == $getConstants('USE_GB_PRIVATE').code; // 사유장비
    },
    isRentEquipment() {
      return this.detailInfo.useGb == $getConstants('USE_GB_RENT').code; // 임차장비
    },
  },
  data() {
    return {
      modalBtns: [{name: '확인', callback: this.onHideModal}],
      unitNm: '', // 단위명
      useOilNm: '', // 유류명
      modalNm: '',
      modalTitle: '',
      oilItems: [],
    };
  },
  beforeCreate() {
    $mapGetters(this, ['detailInfo', 'searchInfo']);
    $mapMutations(this, ['initDetailInfo', 'setDetailInfo']);
  },
  created() {
    $getCode($getConstants('USE_OIL').code).then(codes => {
      this.oilItems = codes.filter(e => e.code.startsWith('0')).map(e => ({text: e.codeNm, value: e.code}));
    });

    this.addEvent([{name: `onHideModal`, func: this.onHideModal}]);
  },
  methods: {
    onChangeOilDeductDs(e) {
      this.setDetailInfo({oilDeductDs: e.target.checked ? e.target.value : ''});
    },
    onShowModal(type) {
      let param = {};
      if (type == 'equipCode') {
        if (this.detailInfo.useGb == '') {
          this.$alert({title: '사용구분', message: '사용구분을 선택해 주세요.'});
          return;
        } else if (this.isHoldEquipment) {
          this.modalTitle = '사유장비선택';
        } else if (this.isRentEquipment) {
          this.modalTitle = '장비선택';
        }

        param.useGb = this.detailInfo.useGb;
      }

      this.modalNm = type + 'Modal';
      this.$store.commit('setModalParam', param);
      this.$modal.show(this.modalNm);
    },
    onHideModal() {
      if (this.modalNm == 'equipCodeModal') {
        this.callEvent({
          name: 'HoldEquipmentListPopup_callbackData',
          param: data => {
            let payload = {};
            payload['equipCode'] = data.equipCode;
            payload['itemName'] = data.itemName;
            payload['size'] = data.size;
            payload['unit'] = data.unit;
            payload['carNo'] = data.carNo;
            payload['useGb'] = data.useGb;
            payload['useOil'] = data.useOil;

            this.setDetailInfo(payload);
          },
        });
      } else if (this.modalNm == 'WorkerListModal') {
        this.callEvent({
          name: 'WorkerListPopup_callbackData',
          param: data => {
            let payload = {};

            payload['mainOperatorId'] = data.empNo;
            payload['name'] = data.name;
            payload['mobileTlno'] = data.mobileTlno;
            payload['postNo'] = data.postNo;
            payload['adr1'] = data.adr1;
            payload['adr2'] = data.adr2;

            this.setDetailInfo(payload);
          },
        });
      }

      this.$modal.hide(this.modalNm);
    },
    onCallback_CustomerPopup(customerInfo) {
      this.setDetailInfo({
        custCode: customerInfo.bsnNo,
        frmNm: customerInfo.frmNm,
        rpsvNm: customerInfo.rpsvNm,
        rpsvPhone: customerInfo.rpsvPhone,
      });
    },
    onUseGbChange(v) {
      this.initDetailInfo();
      this.cud = 1;
      this.setDetailInfo({useGb: v});
      if (v) {
        setTimeout(() => {
          this.onShowModal('equipCode');
        }, 5);
      }
    },
  },
};
</script>

<style scoped></style>
