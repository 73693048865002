export default that => {
  return {
    Cfg: {
      MainCol: 'mnuNm',
      CanDrag: 1,
    },
    Cols: [
      {
        Header: '프로그램명',
        Type: 'Text',
        Name: 'mnuNm',
        MinWidth: '200',
        Align: 'Left',
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: '조회',
        Type: 'Text',
        Name: 'vwYn',
        Width: '60',
        Align: 'Center',
        CanEdit: 0,
        Format: $getConstants('USE_YN').code,
      },
      {
        Header: '저장',
        Type: 'Text',
        Name: 'svYn',
        Width: '60',
        Align: 'Center',
        CanEdit: 0,
        Visible: !that.isCont,
        Format: $getConstants('USE_YN').code,
      },
      {
        Header: '삭제',
        Type: 'Text',
        Name: 'delYn',
        Width: '60',
        Align: 'Center',
        CanEdit: 0,
        Visible: !that.isCont,
        Format: $getConstants('USE_YN').code,
      },
      {
        Header: '출력',
        Type: 'Text',
        Name: 'prnYn',
        Width: '60',
        Align: 'Center',
        CanEdit: 0,
        Visible: !that.isCont,
        Format: $getConstants('USE_YN').code,
      },
      {
        Name: 'mnuCd',
        Visible: false,
      },
      {
        Name: 'uppMnuCd',
        Visible: false,
      },
      {
        Name: 'mnuSqn',
        Visible: false,
      },
    ],
  };
};
