export default that => {
  return {
    Cfg: {
      CanEdit: 0,
    },
    Cols: [
      {Header: '거래처명', Name: 'custName', Type: 'Text', RelWidth: 1, MinWidth: 200},
      {Header: '장비명', Name: 'equipName', Type: 'Text', Width: 150},
      {Header: '차량번호', Name: 'carNo', Type: 'Text', Width: 150},
      {Name: 'reqDegree', Type: 'Text', Visible: 0},
      {Name: 'custCode', Type: 'Text', Visible: 0},
      {Name: 'equipCode', Type: 'Text', Visible: 0},
      {Name: 'repayfrDt', Type: 'Text', Visible: 0},
      {Name: 'repaytoDt', Type: 'Text', Visible: 0},
      {Name: 'monthlyDay', Type: 'Text', Visible: 0},
      {Name: 'monthlyQty', Type: 'Text', Visible: 0},
      {Name: 'monthlyAmt', Type: 'Text', Visible: 0},
      {Name: 'oilAmt', Type: 'Text', Visible: 0},
      {Name: 'oilDeductAmt', Type: 'Text', Visible: 0},
      {Name: 'etcDeductAmt', Type: 'Text', Visible: 0},
      {Name: 'amt', Type: 'Text', Visible: 0},
      {Name: 'vatAmt', Type: 'Text', Visible: 0},
      {Name: 'taxexe', Type: 'Text', Visible: 0},
      {Name: 'sumAmt', Type: 'Text', Visible: 0},
      {Name: 'billNo', Type: 'Text', Visible: 0},
      {Name: 'flTaxNo', Type: 'Text', Visible: 0},
      {Name: 'billTaxNo', Type: 'Text', Visible: 0},
      {Name: 'flTaxNo', Type: 'Text', Visible: 0},
      {Name: 'cud', Type: 'Text', Visible: 0},
    ],
  };
};
