<template>
  <pop :parentId="_uid" :isModal="false" @approvalEmit="approvalEmit" />
</template>

<script>
import pop from '@/view/approval/draft/popup/pop.vue';
export default {
  components: {
    pop,
  },
  data() {
    return {
      searchNm: '',
      formRow: {},
    };
  },

  beforeCreate() {
    $mapGetters(this, {
      searchInfo: 'searchInfo',
    });
  },

  created() {
    this.addEvent([
      {
        name: 'form_selectForm',
        func: this.pop,
      },
    ]);
  },

  methods: {
    pop(row) {
      this.formRow = row;
      this.callEvent({
        name: 'draft_pop' + this._uid,
        param: {
          fbsNo: row.fbsNo,
          fbsCd: row.fbsCd,
          fomNo: row.fomNo,
        },
      });
    },
    approvalEmit() {
      this.pop(this.formRow);
    },
  },
};
</script>

<style scoped></style>
