const state = {
  scheduleList: [],

  scheduleInfo: {
    pgmCd: '', // 회사코드
    scdNo: '', // 일정번호
    scdTpNo: '', // 일정유형
    rpttDs: '0', // 반복구분
    rpttSetDs: '', // 반복설정구분
    rpttSetDs1: '',
    rpttSetDs2: '',
    stDt: '', // 시작일자
    edDt: '', // 종료일자
    stHr: '', // 시작시간
    stMi: '', // 시작분
    edHr: '', // 종료시간
    edMi: '', // 종료분
    odaDs: '', // 온종일구분
    plc: '', // 장소
    tl: '', // 제목
    cts: '', // 내용
    rgprId: '', // 등록자
    rgsDttm: '', // 등록일시
  },
};

const getters = {
  scheduleList: state => state.scheduleList,
  scheduleInfo: state => state.scheduleInfo,
  isRegistrant: state => state.scheduleInfo.rgprId === JSON.parse(sessionStorage.getItem('userInfo')).empNo,
};

const mutations = {
  setScheduleList(state, payload) {
    if (Array.isArray(payload) && payload.length) {
      state.scheduleList = payload;
    }
  },
  initScheduleList(state) {
    state.scheduleList = [];
  },
  setScheduleInfo(state, payload) {
    for (let key in state.scheduleInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.scheduleInfo[key] = payload[key];
      }
    }
    if (state.scheduleInfo.rpttDs === '3' || state.scheduleInfo.rpttDs === '4') {
      state.scheduleInfo.rpttSetDs = `${state.scheduleInfo.rpttSetDs1}${state.scheduleInfo.rpttSetDs2}`;
    }
    if (state.scheduleInfo.rpttDs === '0') {
      state.scheduleInfo.edDt = state.scheduleInfo.stDt;
    }
    if (state.scheduleInfo.odaDs === '1') {
      state.scheduleInfo.stHr = '';
      state.scheduleInfo.stMi = '';
      state.scheduleInfo.edHr = '';
      state.scheduleInfo.edMi = '';
    }
  },
  initSchedule(state, payload) {
    for (let key in state.scheduleInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.scheduleInfo[key] = payload[key];
      } else {
        let value = '';
        switch (key) {
          case 'rpttDs':
            value = '0';
            break;
        }
        state.scheduleInfo[key] = value;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
