<template>
  <iui-container-new type="table" theme="bullet">
    <i-row>
      <i-col-header required>변경 후 금액</i-col-header>
      <i-col rowspan="3">
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col width="60px">공급가액</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.poAmt" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.amtKor }} 원 정)</label>
            </i-col>
          </i-row>
          <i-row>
            <i-col>부가세</i-col>
            <i-col>
              <iui-text
                type="amount"
                prefix="\"
                width="130px"
                :value="poInfo.vatAmt"
                :enable="poStatus.isEnable"
                @change="onChange_taxType('taxTypeDirectAmt', $event.target.value)"
              />
              <label class="font8pt">(일금 {{ poInfo.vatAmtKor }} 원 정)</label>
            </i-col>
          </i-row>
          <i-row>
            <i-col>합계</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.totAmt" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.totAmtKor }} 원 정)</label>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
      <i-spacer />
      <i-col-header>변경 전 금액</i-col-header>
      <i-col rowspan="3">
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col width="60px">공급가액</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.beforePoAmt" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.beforePoAmtKor }} 원 정)</label>
            </i-col>
          </i-row>
          <i-row>
            <i-col>부가세</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.beforeVatAmt" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.beforeVatAmtKor }} 원 정)</label>
            </i-col>
          </i-row>
          <i-row>
            <i-col>합계</i-col>
            <i-col>
              <iui-text type="amount" prefix="\" width="130px" :value="poInfo.beforeTotAmt" :enable="false" />
              <label class="font8pt">(일금 {{ poInfo.beforeTotAmtKor }} 원 정)</label>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
    <i-row v-for="i in 2" :key="i">
      <i-col />
      <i-spacer />
      <i-col />
    </i-row>
    <i-row>
      <i-col-header required>과세유형</i-col-header>
      <i-col colspan="3">
        <iui-radio-group
          :p-code="$getConstants('TAX_TYPE_CD').code"
          :value="poInfo.taxTypecd"
          :enable="poStatus.isEnable"
          @change="onChange_taxType('taxTypecd', $event.target.value)"
        />
        <iui-select
          v-show="poStatus.isTaxDirect"
          :p-code="$getConstants('TAX_DIRECT_DS').code"
          :value="poInfo.taxDirectDs"
          :enable="poStatus.isEnable && poStatus.isTaxDirect"
          @change="value => onChange_taxType('taxDirectDs', value)"
        />
        <iui-text
          v-show="poStatus.isTaxDirect"
          type="rate"
          suffix="%"
          width="80px"
          :value="poInfo.taxTypeDirectPercent"
          :enable="poStatus.isEnable && poStatus.isTaxDirect && poStatus.isTaxDirectPercent"
          :required="poStatus.isEnable && poStatus.isTaxDirect && poStatus.isTaxDirectPercent"
          :errorMessage="{title: '과세율', message: '필수입력입니다.'}"
          @change="onChange_taxType('taxTypeDirectPercent', $event.target.value)"
        />
        <iui-text
          v-show="poStatus.isTaxDirect"
          type="amount"
          suffix="원"
          width="120px"
          :value="poInfo.taxTypeDirectAmt"
          :enable="poStatus.isEnable && poStatus.isTaxDirect && !poStatus.isTaxDirectPercent"
          :required="poStatus.isEnable && poStatus.isTaxDirect && !poStatus.isTaxDirectPercent"
          :errorMessage="{title: '과세금액', message: '필수입력입니다.'}"
          @change="onChange_taxType('taxTypeDirectAmt', $event.target.value)"
        />
      </i-col>
      <i-spacer />
    </i-row>
    <i-row>
      <i-col-header required>인지세유무</i-col-header>
      <i-col>
        <iui-checkbox-group
          :checkedValues="[poInfo.stampTaxYn]"
          :items="[{label: $getConstants('EXTERNAL_Y').name, value: $getConstants('EXTERNAL_Y').code}]"
          @change="onChange_stampTaxYn"
          :enable="poStatus.isEnable && poStatus.isStampTarget"
        />
        <template v-if="poStatus.isStamp">
          <iui-text class="mr10" suffix="원" width="100px" :value="poInfo.stampTaxAmt" :enable="false" />
          <iui-text
            type="rate"
            prefix="당사부담"
            suffix="%"
            width="110px"
            :value="poInfo.stampTaxOwnRate"
            :enable="poStatus.isEnable && poStatus.isStamp"
            :required="poStatus.isEnable && poStatus.isStamp"
            :errorMessage="{title: '인지세율', message: '필수입력입니다.'}"
            @change="onChange_stampTaxOwnRate($event.target.value)"
          />
        </template>
      </i-col>
      <i-spacer />
      <i-col-header required>대금지급방법</i-col-header>
      <i-col :rowspan="poStatus.isPayCash ? 5 : 3">
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col width="100px">
              <iui-radio-group
                :items="payCashItem1"
                :value="poInfo.payCashYn"
                :enable="poStatus.isEnable"
                @change="onChange_payCashYn($event.target.value)"
              />
            </i-col>
            <i-col />
          </i-row>
          <i-row>
            <i-col>
              <iui-radio-group
                :items="payCashItem2"
                :value="poInfo.payCashYn"
                :enable="poStatus.isEnable"
                @change="onChange_payCashYn($event.target.value)"
              />
            </i-col>
            <i-col v-show="poStatus.isPayCash">
              <label>물품수령 후</label>
              <iui-text
                type="number"
                width="30px"
                suffix=""
                :value="poInfo.payTerm"
                :enable="poStatus.isEnable && poStatus.isPayCash"
                max-length="3"
                :required="poStatus.isEnable && poStatus.isPayCash"
                :errorMessage="{title: '대금지급방법 (현금, 어음) - 일', message: '필수입력입니다.'}"
                @change="setPoInfo({payTerm: $event.target.value})"
              />
              <label>일 이내</label>
            </i-col>
          </i-row>
          <i-row v-show="poStatus.isPayCash">
            <i-col />
            <i-col>
              <label>현금 :</label>
              <iui-text
                type="rate"
                suffix="%"
                width="60px"
                :value="poInfo.payCashRatio"
                :enable="poStatus.isEnable && poStatus.isPayCash"
                :required="poStatus.isEnable && poStatus.isPayCash"
                :errorMessage="{title: '대금지급방법 (현금, 어음) - 현금', message: '필수입력입니다.'}"
                @change="onChange_payRatio('payCashRatio', $event.target.value)"
              />
              <label>어음 :</label>
              <iui-text
                type="rate"
                suffix="%"
                width="60px"
                :value="poInfo.payCheckRatio"
                :enable="poStatus.isEnable && poStatus.isPayCash"
                :required="poStatus.isEnable && poStatus.isPayCash"
                :errorMessage="{title: '대금지급방법 (현금, 어음) - 어음', message: '필수입력입니다.'}"
                @change="onChange_payRatio('payCheckRatio', $event.target.value)"
              />
              <label>어음대체수단</label>
              <iui-text
                type="rate"
                suffix="%"
                width="60px"
                :value="poInfo.payCheck2Ratio"
                :enable="poStatus.isEnable && poStatus.isPayCash"
                :required="poStatus.isEnable && poStatus.isPayCash"
                :errorMessage="{title: '대금지급방법 (현금, 어음) - 어음대체수단', message: '필수입력입니다.'}"
                @change="onChange_payRatio('payCheck2Ratio', $event.target.value)"
              />
            </i-col>
          </i-row>
          <i-row v-show="poStatus.isPayCash">
            <i-col />
            <i-col>
              <label>비고 :</label>
              <iui-text
                :value="poInfo.payCashRmrk"
                :enable="poStatus.isEnable && poStatus.isPayCash"
                @change="setPoInfo({payCashRmrk: $event.target.value})"
                max-length="100"
              />
            </i-col>
          </i-row>
          <i-row>
            <i-col>
              <iui-radio-group
                :items="payCashItem3"
                :value="poInfo.payCashYn"
                :enable="poStatus.isEnable"
                @change="onChange_payCashYn($event.target.value)"
              />
            </i-col>
            <i-col v-show="poStatus.isPayDefer">
              <iui-text
                :value="poInfo.payDefer"
                :enable="poStatus.isEnable && poStatus.isPayDefer"
                :required="poStatus.isEnable && poStatus.isPayDefer"
                max-length="100"
                :errorMessage="{title: '대금지급방법 (유보)', message: '필수입력입니다.'}"
                @change="setPoInfo({payDefer: $event.target.value})"
              />
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
    <i-row>
      <i-col-header>납품방법</i-col-header>
      <i-col>
        <template v-if="poStatus.isEnable">
          <iui-select
            :p-code="$getConstants('DLV_COND_RMRK_CD').code"
            defaultCd="S"
            :excludeItems="[
              $getConstants('DLV_COND_RMRK_CD_CONSTRUCTION').code,
              $getConstants('DLV_COND_RMRK_CD_SERVICE').code,
            ]"
            :value="poInfo.dlvCondRmrk"
            @change="value => setPoInfo({dlvCondRmrk: value})"
          />
        </template>
        <template v-else>
          <iui-text :value="poInfo.dlvCondRmrkNm" :enable="false" />
        </template>
      </i-col>
      <i-spacer />
      <i-col />
    </i-row>
    <i-row>
      <i-col-header required>{{ !poStatus.isPotypeUnit ? '납품일자' : '납품기간' }}</i-col-header>
      <i-col>
        <template v-if="!poStatus.isPotypeUnit">
          <iui-datepicker
            v-show="!poStatus.isPotypeUnit"
            :value="poInfo.dlvReqDt"
            :enable="poStatus.isEnable && !poStatus.isPotypeUnit"
            :required="poStatus.isEnable && !poStatus.isPotypeUnit"
            :errorMessage="{title: '납품일자', message: '필수입력입니다.'}"
            @change="setPoInfo({dlvReqDt: $event})"
          />
        </template>
        <template v-if="poStatus.isPotypeUnit">
          <iui-datepicker
            :value="poInfo.dlvFrDt"
            :group="dlvPeriod.frDt"
            :enable="poStatus.isEnable && poStatus.isPotypeUnit"
            :required="poStatus.isEnable && poStatus.isPotypeUnit"
            :errorMessage="{title: '납품기간', message: '필수입력입니다.'}"
            @change="setPoInfo({dlvFrDt: $event})"
          />
          <label>~</label>
          <iui-datepicker
            :value="poInfo.dlvToDt"
            :group="dlvPeriod.toDt"
            :enable="poStatus.isEnable && poStatus.isPotypeUnit"
            :required="poStatus.isEnable && poStatus.isPotypeUnit"
            :errorMessage="{title: '납품기간', message: '필수입력입니다.'}"
            @change="setPoInfo({dlvToDt: $event})"
          />
        </template>
      </i-col>
      <i-spacer />
      <i-col />
    </i-row>
    <i-row>
      <i-col-header required>변경사유</i-col-header>
      <i-col>
        <iui-text
          :value="poInfo.chresonRmrk"
          :enable="poStatus.isEnable && poStatus.isChangeContSeq"
          :required="poStatus.isEnable && poStatus.isChangeContSeq"
          :errorMessage="{title: '변경사유', message: '필수입력입니다.'}"
          @change="setPoInfo({chresonRmrk: $event.target.value})"
          max-length="50"
        />
      </i-col>
      <i-spacer />
      <i-col />
    </i-row>
    <i-row>
      <i-col-header>특약사항</i-col-header>
      <i-col>
        <iui-text
          :value="poInfo.poRmrk"
          :enable="poStatus.isEnable"
          @change="setPoInfo({poRmrk: $event.target.value})"
          max-length="2500"
        />
      </i-col>
      <i-spacer />
      <i-col />
    </i-row>
  </iui-container-new>
</template>

<script>
import PoInputMixin from '@/view/bidding/js/poInputMixin.js';
export default {
  mixins: [PoInputMixin],
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'poStatus']);
    $mapMutations(this, ['setPoInfo']);
  },
};
</script>

<style scoped>
.font8pt {
  font-size: 8pt;
}
</style>
