<template>
  <pmis-content-box>
    <template #header-left>
      <iui-text type="amount" width="180px" prefix="공급가액" :enable="false" :value="poInfo.poAmt" />
      <iui-text type="amount" width="180px" prefix="부가세액" :enable="false" :value="poInfo.vatAmt" />
      <iui-text type="amount" width="180px" prefix="합계금액" :enable="false" :value="poInfo.totAmt" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="poItemList"
      :events="{
        onClick: sheet_onClick,
      }"
    />

    <iui-modal name="matCodeInfoModal" title="자재코드" size-type="size4">
      <MatCodeInfo :propsMatCode="propsMatCode" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/poItemList.js';
import MatCodeInfo from '@/view/sysManage/stdCodeManage/matCodeRegist/popup/MatCodeInfo.vue';
export default {
  components: {MatCodeInfo},
  props: {
    poInfo: {
      type: Object,
      default: () => ({}),
    },
    poItemList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),

      propsMatCode: '',
    };
  },
  methods: {
    sheet_onClick(e) {
      if (e.row.Kind === 'Data' && e.col === 'itemNm') {
        this.propsMatCode = e.row.itemCd.toString();
        this.$modal.show('matCodeInfoModal');
      }
    },
  },
};
</script>

<style></style>
