export default that => {
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      CanEdit: 1, // 1: 전체 편집 가능 (default)
      ReqStatusName: 'status', // 서버로 전송될 상태 명 (default:STATUS)
      IgnoreFocused: 1,
    },
    Cols: [
      {
        Header: '순번',
        Name: 'rowNum',
        Type: 'Text',
        Width: 50,
        Align: 'Center',
        CanEdit: false,
      },
      {
        Header: '발신명의',
        Name: 'docNm',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: that.enable,
      },
      {Name: 'seq', Visible: false},
      {Name: 'djFlNo', Visible: false},
    ],
  };
};
