<template>
  <pmis-content-box>
    <template #header-left>
      <iui-text type="amount" width="180px" prefix="공급가액" :enable="false" :value="poInfo.poAmt" />
      <iui-text type="amount" width="180px" prefix="부가세액" :enable="false" :value="poInfo.vatAmt" />
      <iui-text type="amount" width="180px" prefix="합계금액" :enable="false" :value="poInfo.totAmt" />
    </template>
    <template #header-right v-if="enable">
      <iui-button value="실행내역선택" @click="openExecutionModal" />
      <iui-button value="삭제" @click="onDeleteRfqItem" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="poItemList"
      @loadSheet="sheet = $event"
      :events="{
        onRenderFirstFinish: e => e.sheet.setAutoMerge({headerMerge: 5}),
        onSearchFinish: sheet_onSearchFinish,
        onBeforeChange: sheet_onBeforeChange,
        onAfterChange: sheet_onAfterChange,
      }"
    />

    <ExecutionList @executionList="onCheckedExecutionList" />
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/poItemList.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';

import ExecutionList from '@/view/subBidding/components/ExecutionList.vue';

export default {
  components: {ExecutionList},
  data() {
    return {
      sheetOpt: sheetOpt(this),
    };
  },
  computed: {
    enable() {
      // (신규 && 수기계약) || (계약작성중 && (수기계약 || 변경계약??))
      return (
        (this.poIsNew && this.poStatus.isExistOnlyPo) ||
        (this.poStatus.isPoReady && (this.poStatus.isExistOnlyPo || this.poStatus.isChangeContSeq))
      );
    },
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'poItemList', 'poIsNew', 'poStatus']);
    $mapMutations(this, ['setPoItemList', 'setPoInfo']);
  },
  created() {
    this.addEvent([
      {name: 'PoItemList_OpenExecutionModal', func: this.openExecutionModal},
      {name: 'PoItemList_CalculateAmt', func: this.calculateAmt},
      {name: 'PoItemList_GetSaveJson', func: this.getSaveJson},
    ]);
  },
  methods: {
    async openExecutionModal() {
      if (!this.poInfo.spotDeptcd) {
        await this.$alert({title: '현장', message: '현장을 선택해주세요.'});
        this.callEvent({name: 'PoInfo_OpenPrjModal'});
        return;
      }

      const param = {
        pgmCd: this.pgmCd,
        prjCd: this.poInfo.spotDeptcd,
        validSheet: this.sheet.getDataRows(),
      };
      this.callEvent({name: 'ExecutionList_ShowModal', param: param});
    },
    onCheckedExecutionList(executionList) {
      executionList.forEach(item => {
        const addRow = this.sheet.addRow();

        this.sheet.setValue(addRow, 'srvNm', item.mgName, true);
        this.sheet.setValue(addRow, 'itemSeq', item.itemSeq, true);
        this.sheet.setValue(addRow, 'itemNm', item.itemName, true);
        this.sheet.setValue(addRow, 'sizeNm', item.ssize, true);
        this.sheet.setValue(addRow, 'unit', item.unit, true);

        this.sheet.setValue(addRow, 'totQty', item.totQty, true);
        this.sheet.setValue(addRow, 'qty', 0, true);
        this.sheet.setValue(addRow, 'unitAmt', item.unitAmt, true);

        this.sheet.setValue(addRow, 'wcode', item.wcode, true);
        this.sheet.setValue(addRow, 'headCode', item.headCode, true);
        this.sheet.setValue(addRow, 'midCode', item.midCode, true);
        this.sheet.setValue(addRow, 'itemCode', item.itemCode, true);
        this.sheet.setValue(addRow, 'costType', item.costType, true);

        this.sheet.setValue(addRow, 'matUprc', item.planMatUprc, true);
        this.sheet.setValue(addRow, 'labUprc', item.planLabUprc, true);
        this.sheet.setValue(addRow, 'equipUprc', item.planEquipUprc, true);
        this.sheet.setValue(addRow, 'costUprc', item.planCostUprc, true);
      });
    },
    onDeleteRfqItem() {
      const title = '계약내역 삭제';
      if (!this.sheet.getTotalRowCount()) {
        this.$alert({title, message: '계약내역이 존재하지 않습니다.'});
        return;
      }

      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (!checkedRows.length) {
        this.$alert({title, message: '선택된 항목이 없습니다.'});
        return;
      }

      this.sheet.removeRows(checkedRows);
      this.calculateAmt();
    },

    setEnableSheet() {
      const color = this.enable ? '#E0E0F8' : undefined;

      this.sheet.setAttribute(null, 'chk', 'Visible', this.enable, 0);

      this.sheet.setAttribute(null, 'qty', 'CanEdit', this.enable, 0);
      this.sheet.setAttribute(null, 'matUprc', 'CanEdit', this.enable, 0);
      this.sheet.setAttribute(null, 'labUprc', 'CanEdit', this.enable, 0);
      this.sheet.setAttribute(null, 'equipUprc', 'CanEdit', this.enable, 0);
      this.sheet.setAttribute(null, 'costUprc', 'CanEdit', this.enable, 0);
      this.sheet.setAttribute(null, 'srvRmrk', 'CanEdit', this.enable, 0);

      this.sheet.setAttribute(null, 'qty', 'Color', color, 0);
      this.sheet.setAttribute(null, 'matUprc', 'Color', color, 0);
      this.sheet.setAttribute(null, 'labUprc', 'Color', color, 0);
      this.sheet.setAttribute(null, 'equipUprc', 'Color', color, 0);
      this.sheet.setAttribute(null, 'costUprc', 'Color', color, 0);
      this.sheet.setAttribute(null, 'srvRmrk', 'Color', color, 0);

      this.sheet.Def.Row.CanFocus = this.enable || this.poIsNew;

      this.sheet.rerender();
    },
    calculateAmt() {
      const itemList = this.sheet.getDataRows();
      const amt = itemList.reduce((acc, curr) => acc + curr['sumAmt'], 0);
      const labAmt = itemList.reduce((acc, curr) => acc + curr['labAmt'], 0);

      const {taxTypecd, taxDirectDs, taxRate, taxTypeDirectPercent, taxTypeDirectAmt} = this.poInfo;
      const calResult = COMMON_FUNCTION.onCalculateTaxAndTotAmt({
        amt,
        taxTypecd,
        taxDirectDs,
        taxRate,
        taxTypeDirectPercent,
        taxTypeDirectAmt,
      });

      const prfmInsrAmt = (calResult.totAmt * this.poInfo.prfmInsrRate) / 100;
      const delayAmt = (calResult.totAmt * this.poInfo.delayRate) / 100;
      const flawInsrAmt = (calResult.totAmt * this.poInfo.flawInsrRate) / 100;

      this.setPoInfo({...calResult, poAmt: calResult.amt, labAmt, prfmInsrAmt, delayAmt, flawInsrAmt});
    },
    getSaveJson(callback) {
      if (typeof callback === 'function') {
        callback(this.sheet.getSaveJson(0).data);
      }
    },
    sheet_onSearchFinish(e) {
      e.sheet.getDataRows().forEach(row => {
        // e.sheet.setValue(row, 'qtyBefore', row.qty, true); // 입력하기전 수량(원본)
        // e.sheet.acceptChangedData(row);
      });

      this.setEnableSheet();
    },
    sheet_onBeforeChange(e) {
      if (e.col === 'qty') {
        if (Number.isNaN(Number(e.val))) {
          return e.oldval;
        }

        const decimal = e.val.split('.')[1];
        if (5 < decimal?.length) {
          this.$alert({title: '금회요청수량', message: '소수점 이하 5자리까지 입력가능합니다.'});
          return e.oldval;
        }

        const qty = e.val; // 입력한 수량
        const qtyBefore = e.row.qtyBefore || 0; // 변경전 수량(원본)

        if (0 > qty) {
          this.$alert({title: '수량', message: '수량은 0 이상이어야 합니다.'});
          return qtyBefore;
        }
        return qty;
      }
    },
    sheet_onAfterChange() {
      this.calculateAmt();
    },
  },
};
</script>

<style></style>
