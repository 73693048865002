import cloneDeep from 'lodash/cloneDeep';

//
export function childViewAuthSetup(path) {
  if (!path) {
    return;
  }
  const menu = this.$store.getters['mdi/currentUrls'];
  let childView;
  if (menu.authList) {
    childView = menu.authList.filter(item => item.mnuPth.indexOf(path.replace(/(.\/)|(@\/)/, '')) != -1)[0];
    if (childView) {
      this.menuInfo = menu;
      const fakeMenu = cloneDeep(menu);

      fakeMenu.vwYn = childView.vwYn;
      fakeMenu.svYn = childView.svYn;
      fakeMenu.newYn = childView.newYn;
      fakeMenu.delYn = childView.delYn;
      fakeMenu.prnYn = childView.prnYn;

      const rootUrl = this.$store.getters['mdi/urls'].filter(url => url.isSelected)[0].url;
      if (rootUrl != this.$store.getters.currentUrl) {
        const menuAuthCache = {};
        menuAuthCache[rootUrl] = menu;
        menuAuthCache[this.$store.getters.currentUrl] = fakeMenu;

        this.$store.commit('setMenuAuthCache', menuAuthCache);
      }

      this.$store.commit('mdi/setCurrentUrls', fakeMenu);
    }
  }
}

export function childViewAuthDestroy() {
  const menu = this.$store.getters['mdi/currentUrls'];
  if (this.menuInfo && menu.mnuCd && this.menuInfo.mnuCd == menu.mnuCd)
    this.$store.commit('mdi/setCurrentUrls', this.menuInfo);
  delete this.menuInfo;
}
