const COMMON_COLUMNS = {
  ttl: '', // 요청제목
  orgCd: '', // 회사코드
  orgNm: '', // 회사명
  spotDeptcd: '', // 현장코드
  spotDeptnm: '', // 현장명

  taxTypecd: '', // 과세유형
  taxTypenm: '', // 과세유형명
  taxDirectDs: '', // 부가가치세 직접입력 유형
  taxTypeDirect: '', // 과세유형 > 직접입력
  taxTypeDirectPercent: '', // 과세유형 > 직접입력 > 퍼센트
  taxTypeDirectAmt: '', // 과세유형 > 직접입력 > 금액

  amt: '', // 공급가
  vatAmt: '', // 부가가치세
  taxRate: '', // 부가가치세율
  totAmt: '', // 합계금액

  flNo: 0, // 첨부파일번호
  rgprId: '', // 등록자
};

const SPOT_DSCR_COLUMNS = {
  spotDscrYn: '', // 현설여부
  spotDscrLoc: '', // 현설장소

  spotDscrDt: '', // 현설일자
  spotDscrYmd: '', // 현설일자(날짜)
  spotDscrHh: '00', // 현설일자(시간)
  spotDscrMm: '00', // 현설일자(분)

  spotDscrUsrcd: '', // 현설담당자
  spotDscrUsrnm: '', // 현설담당자명
  spotDscrUsrTlno: '', // 현설담당자 전화번호

  spotDscrFlNo: 0, // 현설결과증빙파일
};

const PR_COMMON_COLUMNS = {
  ...COMMON_COLUMNS,
  ...SPOT_DSCR_COLUMNS,

  prNo: '', // 요청번호
  prNo2: '', // 요청번호(조회용)

  chrgUsrcd: '', // 구매요청자
  chrgUsrnm: '', // 구매요청자명

  prRmrk: '', // 입찰요청 비고
  prDate: '', // 요청일자
  prSt: '', // 입찰요청 상태

  returnYn: '', // 반송여부
  returnDt: '', // 반송일시
  returnRmrk: '', // 반송사유

  rfqNo: '', // 공고번호
  docNo: '', // 구매요청 || 시행요청 문서번호
  sncNo: '', // 구매요청 || 시행요청 결재번호
};

const RFQ_COMMON_COLUMNS = {
  ...COMMON_COLUMNS,
  ...SPOT_DSCR_COLUMNS,

  prNo: '', // 구매 || 시행 요청번호
  rfqNo: '', // 입찰공고번호
  rfqNo2: '',

  bidRnd: '', // 공고차수
  lastBidRnd: '',

  poTypecd: '', // 계약구분 (일반계약, 단가계약)
  poTypenm: '',

  bidStartDt: '', // 공고일시
  bidStartYmd: '',
  bidStartHh: '',
  bidStartMm: '',

  bidExpireDt: '', // 입찰마감일시
  bidExpireYmd: '',
  bidExpireHh: '00',
  bidExpireMm: '00',

  bidOpenDt: '', // 개봉일시
  bidOpenYmd: '',
  bidOpenHh: '',
  bidOpenMm: '',

  payCashRatio: '', // 현금지급비율
  payCheckRatio: '', // 어음지급비율
  payTerm: '', // 지급주기

  dlvCondRmrk: '', // 납품조건
  dlvCondRmrkNm: '',

  chrgUsrcd: '', // 입찰담당자
  chrgUsrnm: '',
  chrgUsrTlno: '',

  rfqSt: '', // 공고 진행상태
  rfqRmrk: '', // 공고 비고

  isCloseSpot: '',

  spotDscrApplyYn: '', // 현설참가신청여부 ( TB - 공고 참여업체 정보 )
  spotDscrChkYn: '', // 현설참여여부      ( TB - 공고 참여업체 정보 )
  quotSbmtYn: '', // 투찰여부            ( TB - 공고 참여업체 정보 )
  quotSbmtYnNm: '',
  bidPlanAmt: '', // 입찰예정가
  totCusCount: '',
  quotCusCount: '',
  cusCount: '',

  choiceNotiFlag: '', // 정보공개여부
  choiceRsltRmrk: '', // 낙찰사유

  docNo: 0, // 입찰공고 문서번호
  docNo2: 0, // 낙찰사선정 문서번호
  rfqSncNo: '', // 입찰공고 결재번호
  rfqSncNo2: '', // 낙찰사선정 결재번호

  lastTotAmt: '', // 낙찰금액
  endDt: '', // 공고 완료일

  cancelYn: '', // 공고 취소여부
  rgsDate: '', // 등록일자
};

const RFQCUS_COMMON_COLUMNS = {
  rfqNo: '', // 공고번호
  lastBidRnd: '', // 공고차수
  cusCd: '', // 업체코드

  spotDscrApplyYn: '', // 현설참가신청여부
  spotDscrChkYn: '', // 현설참여여부

  quotSbmtYn: '', // 투찰여부
  quotSbmtYnNm: '',
  quotSbmtDt: '', // 투찰일시

  resultYn: '', // 낙찰여부

  poNo: '', // 계약번호
  poDt: '', // 계약일

  lastAmt: '', // 공급가
  lastVatAmt: '', // 부가세
  taxTypecd: '', // 과세유형
};

const PO_COMMON_COLUMNS = {
  ...COMMON_COLUMNS,

  poNo: '', // 발주 || 계약번호
  poNo2: '',
  contSeq: '', // 계약차수
  lastContSeq: '',

  prNo: '', // 구매 || 시행요청번호
  rfqNo: '', // 공고번호
  bidRnd: '', // 공고차수

  cusCd: '', // 계약업체코드
  cusNm: '', // 계약업체명
  poTypecd: '', // 계약구분
  poTypenm: '',
  directYn: '', // 수기계약 여부
  poDocKind: '', // 계약파일 구분

  poSt: '', // 계약상태
  poDt: '', // 계약일
  poRmrk: '', // 특약사항

  amtKor: '',
  vatAmtKor: '',
  totAmtKor: '',

  stampTaxYn: '', // 인지세여부
  stampTaxOwnRate: '', // 원청사 인지세 부담비율
  stampTaxOwnRate2: '', // 공급사 인지세 부담비율
  stampTaxAmt: '', // 인지금액 (TB: 인지세 등록정보)
  isFullPaymentStampTax: '', // 완납여부
  isExistStamp: '', // 전체 차수기준 인지세 존재여부

  prfmInsrRate: '', // 계약보증금 비율
  prfmInsrAmt: '', // 계약보증금액
  delayRate: '', // 지체상금율

  flawInsrYn: '', // 하자담보책임 여부
  flawInsrRate: '', // 하자보수보증금율
  flawInsrAmt: '', // 하자보수보증금액
  flawInsrYear: '', // 하자담보책임년수
  flawInsrFrDt: '', // 하자담보책임 시작일
  flawInsrToDt: '', // 하자담보책임 종료일

  payCondRmrk: '',
  payCashYn: '', // 대금지급방법 || 발주처 직접지급 여부
  payCashRatio: '', // 현금지급비율
  payCheckRatio: '', // 어음지급비율
  payCheck2Ratio: '', // 어음대체수단비율

  poSncNo: '', // 계약결재번호

  returnYn: '', // 반송여부
  returnReason: '', // 반송사유
  returnType: '', // 반송구분 (협력사반송 / 원청사반송)

  needFileCnt: '',
  submitFileCnt: '',

  origFileHash: '', // 계약서 원본 해쉬
  signedData: '', // 서명된 데이터

  chresonRmrk: '', // 변경사유
  beforePoAmt: '',
  beforeVatAmt: '',
  beforeTotAmt: '',
  beforePoAmtKor: '',
  beforeVatAmtKor: '',
  beforeTotAmtKor: '',
};

const PR_COLUMNS = {
  ...PR_COMMON_COLUMNS,

  dlvReqDt: '', // 납품요구일
  dlvLoc: '', // 납품장소
};

const RFQ_COLUMNS = {
  ...RFQ_COMMON_COLUMNS,

  dlvReqDt: '', // 납품요구일
  dlvLoc: '', // 납품장소

  contFrDt: '', // 계약기간 시작일자 (단가계약)
  contToDt: '', // 계약기간 종료일자 (단가계약)
  contCondRmrk: '', // 계약조건 (단가계약)

  cusCd: '', // 낙찰업체코드
  cusNm: '', // 낙찰업체명
};

const RFQCUS_COLUMNS = {...RFQCUS_COMMON_COLUMNS};

const QUOT_COLUMNS = {
  rfqNo: '',
  cusCd: '',
  bidRnd: '',
  amt: 0,
  vatAmt: 0,
  quotRmrk: '',
  quotSbmtDt: '',
  quotSbmtUsrcd: '',
  signCntn: '',
  signEstiFlNo: 0,
  estiFlNo: 0,
  etcFlNo: 0,
  quotCertdn: '',
  rgprId: '',
  rgsDttm: '',
};

const PO_COLUMNS = {
  ...PO_COMMON_COLUMNS,

  poAmt: '', // 공급가

  payCashYnNm: '',
  payCashCount: '', // 현금어음지급기한

  payTerm: '', // 월지급주기
  payCashRmrk: '', // 현금어음비고
  payDefer: '', // 대금지급방법 유보 내용

  dlvLoc: '', // 납품장소
  dlvCondRmrk: '', // 납품방법
  dlvCondRmrkNm: '', // 납품방법
  dlvReqDt: '', // 납품일
  dlvYn: '', // 기간납품 여부
  dlvFrDt: '', // 기간납품 시작일
  dlvToDt: '', // 기간납품 종료일

  delayRateYn: '', // 지체상금율 여부
  unitOrderYn: '', // 단가계약 발주여부

  isOrderComplete: false, // 발주완료여부
  orderCount: 0, // 발주건수
  beforeFlNo: 0,
  delayFlNo: 0,
  prfmInsrFlNo: 0,
  scpayFlNo: 0,
  prepayFlNo: 0,
  cusPgmCd: '',
};

const PO_ORDER_COLUMNS = {
  poNo: '', // 발주번호
  poNo2: '', // 발주번호
  contSeq: '', // 차수
  grpPoNo: '', // 상위계약번호
  ttl: '', // 발주명

  orgCd: '', // 회사코드
  spotDeptcd: '', // 현장코드
  spotDeptnm: '', // 현장명
  cusCd: '', // 거래처코드
  cusNm: '', // 거래처명

  dlvLoc: '', // 납품장소
  poAmt: '', // 공급가
  vatAmt: '', // 부가세
  totAmt: '', // 합계금액

  dlvReqDt: '', // 납품일자
  poDt: '', // 발주일자
  poSt: '', // 진행상태
  sendDt: '', // 송부일시
  flNo: 0,
  chrgUsrSignDt: '', // 송부일시 (원청사 서명일)
  cusUsrSignDt: '', // 접수일시 (협력사 서명일)
  unitOrderYn: '', // 단가계약 발주여부
};

const SUB_PR_COLUMNS = {
  ...PR_COMMON_COLUMNS,

  itemNm: '', // 공사용역명
  constrFlag: '', // 공사/용역 구분
  constrLoc: '', // 공사/용역 장소
  constrFrDt: '', // 공사/용역 시작일
  constrToDt: '', // 공사/용역 종료일
  itemRmrk: '', // 공사용역비고
  labAmt: '',
};

const SUB_RFQ_COLUMNS = {
  ...RFQ_COMMON_COLUMNS,

  constrFlag: '', //공사/용역 구분
  constrFlagNm: '', //공사/용역 구분명
  itemNm: '', //공사용역명
  constrFrDt: '', //공사/용역 시작일
  constrToDt: '', //공사/용역 종료일
  constrLoc: '', //공사/용역 장소
  itemRmrk: '', //공사용역비고

  labAmt: '',
};

const SUB_RFQCUS_COLUMNS = {...RFQCUS_COMMON_COLUMNS};

const SUB_PO_COLUMNS = {
  ...PO_COMMON_COLUMNS,

  poAmt: '', // 공급가
  labAmt: '', // 노무비
  labYn: '', // 노무비 표시여부

  constrFlag: '', // 공사/용역 구분
  itemNm: '', // 공사용역명
  constrFrDt: '', // 공사/용역 시작일
  constrToDt: '', // 공사/용역 종료일
  constrLoc: '', // 공사/용역 장소
  itemRmrk: '', // 공사용역비고

  contFrDt: '', // 공사/용역 시작일
  contToDt: '', // 공사/용역 마감일

  payPurchase: '', // 지급자재 품목 및 수량

  delayText: '', // 지체상금율 비고
  delayAmt: '',

  scpayInsrYn: '', // 하도급대금지급보증금 여부
  scpayInsrAmt: '', // 하도급대금지급보증금액
  scpayInsrIncYn: '', // 하도급대금지급보증금 계약서 표시여부

  prepayDay1: '', // 선급금 지급일1
  prepayDay2: '', // 선급금 지급일2
  prepayAmt: '', // 선급금액

  gisungMonth: '', // 기성금액(월)
  gisungCount: '', // 기성금액(회)
  gisungDay: '', // 기성금액(일)

  negoDay1: '', // 대금조정 지급일1
  negoDay2: '', // 대금조정 지급일2

  beforeLabAmt: '',
  beforeLabYn: '',
  beforeFlNo: 0,
  prfmInsrFlNo: 0,
  scpayFlNo: 0,
  prepayFlNo: 0,
  cusPgmCd: '',
};

const OFFLINE_STAMP_COLUMNS = {
  // stampTaxOwnRate: '', // 당사부담률
  // stampTaxOwnRate2: '', // 협력사부담률
  // isOnlineStamp: false,
  // isOfflineStamp: false,

  poNo: '', // 계약번호
  poNo2: '',
  contSeq: '', // 계약차수
  stampSeq: '', // 인지등록차수
  stampContNo: '', // 인지계약번호
  stampContTtl: '', // 계약명
  stampBsnNo: '', // 사업자번호
  payer: '',
  stampContAmt: '', // 계약금액
  stampTaxAmt: '', // 인지금액
  stampPaymentAmt: '', // 납부금액
  stampNo: '', // 인지발급번호
  stampFlNo: 0, // 인지 관련 파일번호
  issueFlag: '', // 발행자 구분코드
  delYn: '', // 삭제여부
  rgprId: '', // 등록자

  // bsnNo: '', // 납부당사자
  // payer: '', // 납부당사자
  // uniqueId: '', // 인지세 고유번호
  // flNo: 0,
  // lastFlNo: '',

  // ttl: '',
  // totAmt: '',
  // paymentTotAmt: '', // 납부한 인지세 총액
  // rgprId: '',
};

export {
  PR_COLUMNS,
  RFQ_COLUMNS,
  RFQCUS_COLUMNS,
  QUOT_COLUMNS,
  PO_COLUMNS,
  PO_ORDER_COLUMNS,
  SUB_PR_COLUMNS,
  SUB_RFQ_COLUMNS,
  SUB_RFQCUS_COLUMNS,
  SUB_PO_COLUMNS,
  OFFLINE_STAMP_COLUMNS,
};
