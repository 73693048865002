import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
const spotDscrN = BIDDING_CONSTANTS.CODES.SPOT_DSCR_N;
const spotDscrApplyY = BIDDING_CONSTANTS.CODES.SPOT_DSCR_APPLY_Y;
const spotDscrChkY = BIDDING_CONSTANTS.CODES.SPOT_DSCR_CHK_Y;
const quotSbmtY = BIDDING_CONSTANTS.CODES.QUOT_SBMT_Y;
export default that => {
  return {
    Cfg: {CanEdit: 1, SpaceForDefaultValue: 1},
    Def: {
      Row: {NoColor: 1, CanFocus: 0},
    },
    Cols: [
      {
        Header: '협력사명',
        Name: 'frmNm',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        MinWidth: 200,
        CanEdit: 0,
        TextStyle: 5,
        TextColor: '#FF0000',
        Cursor: 'Pointer',
      },
      {
        Header: '대표자',
        Name: 'rpsvNm',
        Type: 'Text',
        Align: 'center',
        Width: 140,
        CanEdit: 0,
      },
      {
        Header: '현설참가신청여부',
        Name: 'spotDscrCheck1',
        Type: 'Text',
        Align: 'center',
        Width: 140,
        CanEdit: 0,
        Formula: fr =>
          fr.Row['spotDscrYn'] === spotDscrN ? '-' : fr.Row['spotDscrApplyYn'] === spotDscrApplyY ? 'O' : 'X',
      },
      {
        Header: '현설참여여부',
        Name: 'spotDscrCheck2',
        Type: 'Text',
        Align: 'center',
        Width: 140,
        CanEdit: 0,
        Formula: fr =>
          fr.Row['spotDscrYn'] === spotDscrN ? '-' : fr.Row['spotDscrChkYn'] === spotDscrChkY ? 'O' : 'X',
      },
      {
        Header: '응찰여부',
        Name: 'quotSbmtYn',
        Type: 'Text',
        Align: 'center',
        Width: 140,
        CanEdit: 0,
        DefaultValue: '-',
        CustomFormat: v => (v === quotSbmtY ? 'O' : 'X'),
      },
      {Name: 'cusCd', Visible: false},
      {Name: 'cusPgmCd', Visible: false},
      {Name: 'spotDscrYn', Visible: false},
      {Name: 'spotDscrApplyYn', Visible: false},
      {Name: 'spotDscrChkYn', Visible: false},
    ],
  };
};
