<template>
  <iui-container-new type="css-flex">
    <i-row height="120px">
      <i-col>
        <iui-excel-template :rows="rows" :data="data" :fileName="title + ' 예제파일.xlxs'" />
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box>
          <template v-slot:title> {{ title }}</template>
          <iui-excel-upload accept=".xls,.xlsx" :postAction="postAction" :param="param" @error="error" />
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    uri: {
      type: String,
    },
    rows: {
      type: Array,
    },
    sheetOpt: {
      type: Object,
    },
    data: {
      type: Array,
    },
  },
  data() {
    return {
      save: false,
      postAction: this.getUrl(),
      param: {},
    };
  },
  beforeCreate() {
    $mapGetters(this, ['maxDgree']);
  },
  created() {
    this.param = {prjCd: this.prjCd, chgDegree: this.maxDgree};
  },
  methods: {
    getUrl() {
      const regex = /^(\/pr|\/po|\/order|\/bill|\/rfq|\/cert|\/sub|\/quot|\/pkiToolkit).*/;
      const baseURL =
        regex.test(this.uri) && this.uri.indexOf('portletData') === -1
          ? process.env.VUE_APP_EXTERNAL_URL
          : process.env.VUE_APP_API_URL;
      return `${baseURL}${this.uri}`;
    },
    error(error, list) {
      this.$emit('error', error, list);
    },
  },
};
</script>

<style></style>
