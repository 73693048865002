<template>
  <pmis-content-box>
    <form onsubmit="return false;">
      <iui-container-new type="css-flex">
        <i-row height="260px">
          <i-col min-width="530px">
            <iui-container-new type="table" theme="bullet">
              <i-row>
                <i-col-header :required="isEditableLL">대분류</i-col-header>
                <i-col>
                  <iui-text
                    name="llCode"
                    type="code"
                    :value="llCode"
                    :enable="isNew && isEditableLL"
                    @change="onChangeLlCode"
                    @input="onInputLlCode"
                    @keyup="onKeyupLlCode"
                    min-length="6"
                    max-length="6"
                    width="60px"
                    :required="isNew && isEditableLL"
                    :errorMessage="{title: '대분류', message: '대분류를 입력해주세요.'}"
                  />
                  <span style="width:115px" />
                  <iui-text
                    name="llName"
                    :value.sync="llName"
                    :enable="isEditableLL"
                    :required="isEditableLL"
                    :errorMessage="{title: '대분류', message: '대분류명을 입력해주세요.'}"
                    max-length="100"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header :required="isEditableML">중분류</i-col-header>
                <i-col colspan="">
                  <iui-text
                    name="llCode"
                    type="smallcode"
                    :value="level > 2 ? llCode : ''"
                    :enable="false"
                    max-length="6"
                    width="60px"
                  />
                  <iui-text
                    regExp="[^0-9]"
                    name="mlCode"
                    type="smallcode"
                    :value.sync="mlCode"
                    :enable="isNew && isEditableML"
                    min-length="2"
                    max-length="2"
                    width="30px"
                    :required="isNew && isEditableML"
                    :errorMessage="{title: '중분류', message: '중분류를 입력해주세요.'}"
                  />
                  <span style="width:80px" />
                  <iui-text
                    name="mlName"
                    :value.sync="mlName"
                    :enable="isEditableML"
                    :required="isEditableML"
                    max-length="100"
                  />
                </i-col>
              </i-row>
              <i-row><i-col></i-col></i-row>
              <i-row>
                <i-col-header :required="isEditableLbrc">노무코드</i-col-header>
                <i-col>
                  <iui-text
                    name="llCode"
                    type="smallcode"
                    :value="llCodeInLbrcCode"
                    :enable="false"
                    max-length="6"
                    width="60px"
                  />
                  <iui-text
                    name="mlCode"
                    type="smallcode"
                    :value="mlCodeInLbrcCode"
                    :enable="false"
                    max-length="2"
                    width="30px"
                  />
                  <iui-text
                    regExp="[^0-9]"
                    type="largecode"
                    :value="String(this.lbrcCodeInfo.laborCode || '').slice(8)"
                    @change="
                      setLbrcCodeInfo({laborCode: String(internalLbrcClInfo.mlCode).concat($event.target.value)})
                    "
                    :enable="isNew && isEditableLbrc"
                    max-length="8"
                    min-length="8"
                    width="80px"
                    :required="isNew && isEditableLbrc"
                    :errorMessage="{title: '노무코드', message: '노무코드를 입력해주세요.'}"
                  />
                  <iui-text
                    name="itemName"
                    :value="lbrcCodeInfo.itemName"
                    @change="setLbrcCodeInfo({itemName: $event.target.value})"
                    :enable="isEditableLbrc"
                    max-length="100"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>규격</i-col-header>
                <i-col>
                  <iui-text
                    name="size"
                    :value="lbrcCodeInfo.size"
                    @change="setLbrcCodeInfo({size: $event.target.value})"
                    :enable="isEditableLbrc"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col colspan="2">
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header>단위</i-col-header>
                      <i-col>
                        <iui-text name="unitName" type="smallcode" :value="unitName" :enable="false" />
                        <iui-button value="검색" @click="onUnitPopup" :enable="isEditableLbrc" />
                      </i-col>
                      <i-col></i-col>
                      <i-col></i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
              <i-row>
                <i-col colspan="2">
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header>사용여부</i-col-header>
                      <i-col>
                        <iui-checkbox-group
                          name="useDs"
                          :items="[{label: '사용', value: $getConstants('Y').code}]"
                          width="120px"
                          :checkedValues="[lbrcCodeInfo.useDs]"
                          :enable="isEditableLbrc"
                          @change="setLbrcCodeInfo({useDs: $event.target.checked ? $event.target.value : ''})"
                        />
                      </i-col>
                      <i-col></i-col>
                      <i-col></i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
            </iui-container-new>
          </i-col>
          <i-col></i-col>
          <i-spacer />
          <i-col width="220px" height="300px"> </i-col>
        </i-row>
      </iui-container-new>
    </form>
    <iui-modal name="unitModal" title="단위 검색" width="1000px" height="500px" :btns="modalBtns">
      <UnitPopup />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import UnitPopup from '@/view/sysManage/stdCodeManage/components/UnitPopup.vue';
import {saveLbrcInfo} from '../api/LbrcCodeRegist.js';

export default {
  components: {UnitPopup},
  props: {
    base: {
      type: Boolean,
      default: false,
    },
    lbrcClInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      modalBtns: [{name: '확인', callback: this.onUnitPopupConfirm}],
      internalLbrcClInfo: {},
      lbrcCodeInfo: {},
      unitCodeList: [],
    };
  },
  computed: {
    isEditableLL() {
      return this.internalLbrcClInfo?.level === (this.isNew ? 1 : 2);
    },
    isEditableML() {
      return this.internalLbrcClInfo?.level === (this.isNew ? 2 : 3);
    },
    isEditableLbrc() {
      return !!this.lbrcCodeInfo.laborCode || this.internalLbrcClInfo?.level === 3;
    },
    isNew() {
      return this.cud === 1;
    },
    level() {
      return this.lbrcCodeInfo.level ?? this.internalLbrcClInfo?.level + (this.isNew ? 1 : 0);
    },
    llCode() {
      return this.level > (this.isNew ? 2 : 1)
        ? this.lbrcCodeInfo.llCode ?? this.internalLbrcClInfo?.llCode
        : this.isNew
        ? $getConstants('COST_TYPE_L').code
        : '';
    },
    llName: {
      set(value) {
        this.internalLbrcClInfo.llName = value;
        this.internalLbrcClInfo.name = value;
      },
      get() {
        return this.level > (this.isNew ? 2 : 1) ? this.lbrcCodeInfo.llName ?? this.internalLbrcClInfo?.llName : '';
      },
    },
    mlCode: {
      set(value) {
        if (!this.internalLbrcClInfo.llCode) {
          return;
        }
        const fullValue = String(this.internalLbrcClInfo.llCode).concat(value);
        this.internalLbrcClInfo.code = fullValue;
        this.internalLbrcClInfo.mlCode = fullValue;
      },
      get() {
        return String(
          this.level > (this.isNew ? 3 : 2) ? this.lbrcCodeInfo.mlCode ?? this.internalLbrcClInfo?.mlCode ?? '' : ''
        ).slice(6);
      },
    },
    mlName: {
      set(value) {
        if (!this.internalLbrcClInfo.llCode) {
          return;
        }
        this.internalLbrcClInfo.mlName = value;
        this.internalLbrcClInfo.name = value;
      },
      get() {
        return this.level > (this.isNew ? 3 : 2) ? this.lbrcCodeInfo.mlName ?? this.internalLbrcClInfo?.mlName : '';
      },
    },
    llCodeInLbrcCode() {
      return String(
        this.isNew && this.internalLbrcClInfo?.level === 3
          ? this.internalLbrcClInfo?.llCode
          : this.lbrcCodeInfo.llCode ?? ''
      );
    },
    mlCodeInLbrcCode() {
      return String(
        this.isNew && this.internalLbrcClInfo?.level === 3
          ? this.internalLbrcClInfo?.mlCode
          : this.lbrcCodeInfo.mlCode ?? ''
      ).slice(6);
    },
    unitName() {
      const codeInfo = this.unitCodeList.find(item => item.code === String(this.lbrcCodeInfo.unit));
      return codeInfo?.codeNm;
    },
  },
  watch: {
    flNo(val) {
      this.setLbrcCodeInfo({flNo: val});
    },
    lbrcClInfo: {
      handler(value) {
        this.internalLbrcClInfo = value;
      },
      deep: true,
    },
  },
  created() {
    this.addEvent([{name: 'onUnitPopupConfirm', func: this.onUnitPopupConfirm}]);
    this.internalLbrcClInfo = this.lbrcClInfo;
    this.setUnitCodeList();
  },
  methods: {
    async setUnitCodeList() {
      this.unitCodeList = await $getCode($getConstants('UNIT').code);
    },
    async onSave() {
      try {
        const param = {...this.lbrcCodeInfo, ...this.internalLbrcClInfo, base: this.base};
        const response = await saveLbrcInfo(param);
        if (response.data) {
          if (this.internalLbrcClInfo.llCode) {
            this.lbrcCodeInfo['llCode'] = this.internalLbrcClInfo.llCode;
          }
          if (this.internalLbrcClInfo.mlCode) {
            this.lbrcCodeInfo['mlCode'] = this.internalLbrcClInfo.mlCode;
          }
          return {lbrcCodeInfo: this.lbrcCodeInfo, lbrcClInfo: this.internalLbrcClInfo};
        }
      } catch (error) {
        this.$alert({title: '저장', message: error.message});
      }
    },
    setLbrcCodeInfo(data) {
      this.lbrcCodeInfo = {...this.lbrcCodeInfo, ...data};
    },
    onUnitPopup() {
      this.$modal.show('unitModal');
    },
    onUnitPopupConfirm() {
      this.callEvent({
        name: 'UnitPopupp_popConfirm',
        param: data => {
          this.setLbrcCodeInfo({unit: data.unitCode});
          this.$modal.hide('unitModal');
        },
      });
    },
    onChangeLlCode(e) {
      e.target.value = this.setPrefixLlCode(e.target.value);
    },
    onInputLlCode(e) {
      e.target.value = this.setPrefixLlCode(e.target.value);
    },
    onKeyupLlCode(e) {
      let value = this.setPrefixLlCode(e.target.value);
      if (value.length > 6) {
        value = value.substring(0, 6);
      }
      e.target.value = value;
      this.internalLbrcClInfo.code = e.target.value;
      this.internalLbrcClInfo.llCode = e.target.value;
    },
    setPrefixLlCode(code) {
      const re = RegExp('[^0-9]', 'g');
      code = code.toString().replace(re, '');
      if (!code.startsWith($getConstants('COST_TYPE_L').code)) {
        code = `${$getConstants('COST_TYPE_L').code}${code}`;
      }
      return code;
    },
  },
};
</script>
