export default that => {
  return {
    Cfg: {
      CanEdit: false,
    },
    Def: {Row: {CanFocus: 0}},
    Cols: [
      {
        Name: 'rowTypeName',
        Header: '구분',
        Color: '#f1f8ff',
        Width: 150,
        Align: 'Center',
        Type: 'Text',
      },
      {
        Name: 'materialAmt',
        Header: '재료비',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
      },
      {
        Name: 'laborAmt',
        Header: '노무비',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
      },
      {
        Name: 'equipmentAmt',
        Header: '장비비(유류대포함)',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
      },
      {
        Name: 'expenseAmt',
        Header: '경비',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
      },
      {
        Name: 'sumAmt',
        Header: '합계',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
      },
      {
        Name: 'sumRatePerCont',
        Header: '도급대비(%)',
        Extend: that.$ibsheetHelper.preset.rate,
        RelWidth: 1,
      },
      {
        Name: 'sumRatePerExec',
        Header: '실행대비(%)',
        Extend: that.$ibsheetHelper.preset.rate,
        RelWidth: 1,
      },
    ],
  };
};
