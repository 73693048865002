const state = {
  pxcondInfo: {
    pgmCd: '',
    prjCd: '',
    reqCnt: '',
    reqYm: '',
    rsumAmt: '',
    deductAmt: '',
    directAmt: '',
    actIncomAmt: '',
    repSumAmt: '',
    uncollectAmt: '',
  },
  pxcondCollectInfo: {
    pgmCd: '',
    prjCd: '',
    reqCnt: '',
    repCnt: '',
    collectDate: '',
    sumAmt: '',
    collectBankNo: '',
    collectBankNm: '',
    collectAcctNo: '',
    cashAmt: '',
    billAmt: '',
    billNo: '',
    billStrdate: '',
    billEnddate: '',
  },
};

const getters = {
  pxcondInfo: state => state.pxcondInfo,
  pxcondCollectInfo: state => state.pxcondCollectInfo,
};

const mutations = {
  setPxcondInfo(state, payload) {
    for (let key in state.pxcondInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.pxcondInfo[key] = payload[key];
      }
    }
  },
  initPxcondInfo(state) {
    state.pxcondInfo.pgmCd = '';
    state.pxcondInfo.prjCd = '';
    state.pxcondInfo.reqCnt = '';
    state.pxcondInfo.reqYm = '';
    state.pxcondInfo.rsumAmt = '';
  },

  initPxcondCollectInfo(state) {
    for (let key in state.pxcondCollectInfo) {
      state.pxcondCollectInfo[key] = '';
    }
  },
  setPxcondCollectInfo(state, payload) {
    for (let key in state.pxcondCollectInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.pxcondCollectInfo[key] = payload[key];
      }
    }

    state.pxcondCollectInfo.sumAmt =
      Number(state.pxcondCollectInfo.cashAmt) + Number(state.pxcondCollectInfo.billAmt) || '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
