<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col width="400px">
        <pmis-tab-box>
          <template v-slot:title>일위대가분류</template>
          <BreakdownCostCl :callback="onSearchDetail"></BreakdownCostCl>
        </pmis-tab-box>
      </i-col>
      <i-spacer />
      <i-col>
        <iui-container-new type="css-flex">
          <i-row>
            <pmis-tab-box>
              <template v-slot:title>일위대가</template>
              <template v-slot:title-right><iui-button value="선택" @click="onSelect"/></template>
              <pmis-content-box>
                <template v-slot:header-left>
                  <iui-text type="search" :value.sync="searchText" @enter="onSearch" />
                  <iui-button value="검색" @click="onSearch" />
                </template>
                <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData1" @loadSheet="loadSheet1" />
              </pmis-content-box>
            </pmis-tab-box>
          </i-row>
          <i-spacer />
          <i-row>
            <pmis-tab-box>
              <template v-slot:title>복사일위대가</template>
              <template v-slot:title-right>
                <iui-button value="행삭제" @click="onDelRow" />
              </template>
              <pmis-content-box>
                <ib-sheet
                  :uid="_uid"
                  :options="Options"
                  :loadSearchData="loadSearchData2"
                  :append="true"
                  @loadSheet="loadSheet2"
                />
              </pmis-content-box>
            </pmis-tab-box>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import BreakdownCostCl from './BreakDownCostCl.vue';
import {selectBreakdownCostList} from '@/view/sysManage/stdCodeManage/breakdownCost/api/BreakdownCost.js';
import options from './sheetOption/BreakDownCostCopyPopSheet.js';
export default {
  components: {
    BreakdownCostCl,
  },
  props: {
    paramObj: {
      type: Object,
    },
  },
  created() {
    this.addEvent({name: 'BreakdownCostListPop_popConfirm', func: this.onPopConfirm});
  },
  data() {
    return {
      sheet1: undefined,
      sheet2: undefined,
      Options: $addColCheckbox(options(this)),
      loadSearchData1: [],
      loadSearchData2: [],
      searchText: '',
      costType: '',
      costTypeNm: '',
      code: '',
    };
  },
  methods: {
    loadSheet1(sheet) {
      this.sheet1 = sheet;
    },
    loadSheet2(sheet) {
      this.sheet2 = sheet;
    },
    onSearchDetail(row) {
      if (row.level == 1) {
        this.code = '';
      } else {
        this.code = row.code;
      }
      this.onSearch();
    },
    async onSearch() {
      let param = {
        code: this.code,
        searchText: this.searchText,
      };
      const response = await selectBreakdownCostList(param);
      this.loadSearchData1 = response.data;
    },
    onPopConfirm(param) {
      let checkedRows = this.sheet2.getDataRows();
      param(checkedRows);
    },

    onSelect() {
      let checkedRows = this.sheet1.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({
          title: `일위대가`,
          message: `일위대가가 선택되지 않았습니다.`,
        });
        return;
      }

      let isDup = false;
      let dupRmCheckedRows = [];
      checkedRows.forEach(checkRow => {
        this.sheet2.getDataRows().some(row => {
          if (checkRow.itemCode === row.itemCode) {
            isDup = true;
            return true;
          }
        });
        if (isDup) {
          isDup = false;
          return false;
        } else {
          const row = {};
          row['itemCode'] = checkRow.itemCode;
          row['itemName'] = checkRow.itemName;
          row['ssize'] = checkRow.ssize;
          row['unitName'] = checkRow.unitName;
          row['unit'] = checkRow.unit;

          dupRmCheckedRows.push(row);
        }
      });
      if (dupRmCheckedRows.length) {
        this.loadSearchData2 = dupRmCheckedRows;
      }
    },
    async onDelRow() {
      let checkedRows = this.sheet2.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.$alert({title: `행삭제`, message: `삭제할 행이 선택되지 않았습니다.`});
        return;
      }

      this.sheet2.removeRows(checkedRows);
    },
  },
};
</script>

<style></style>
