<template>
  <div class="joinInfo">
    <iui-container-new type="css-flex">
      <i-row :height="enable ? '350px' : '370px'">
        <i-col>
          <pmis-content-box>
            <template v-slot:title>회사기본정보</template>
            <iui-container-new type="table" theme="bullet" :headerWidth="'90px'">
              <colgroup>
                <col width="90px" />
                <col width="41%" />
                <col width="90px" />
                <col width="41%" />
              </colgroup>
              <i-row v-if="!enable">
                <i-col-header>회사코드</i-col-header>
                <i-col>
                  <iui-text width="20%" name="pgmCd" :value="companyInfo.pgmCd" :enable="false" />
                </i-col>
                <i-col></i-col>
                <i-col></i-col>
              </i-row>
              <i-row>
                <i-col-header>회원사구분</i-col-header>
                <i-col>
                  <iui-text width="20%" name="frmGb" :value="frmGbNm" :enable="false" />
                </i-col>
                <i-col></i-col>
                <i-col></i-col>
              </i-row>
              <i-row>
                <i-col-header :required="enable">사업자번호</i-col-header>
                <i-col>
                  <iui-bsn-no
                    :value1.sync="bsnNo1"
                    :value2.sync="bsnNo2"
                    :value3.sync="bsnNo3"
                    @change1="setBsnNo"
                    @change2="setBsnNo"
                    @change3="setBsnNo"
                    :enable="enable"
                    :required="enable"
                  />
                  <iui-button v-if="enable" value="중복확인" @click="onDupBsnNo" />
                </i-col>
                <i-col></i-col>
                <i-col></i-col>
              </i-row>
              <i-row>
                <i-col-header :required="enable">회사명</i-col-header>
                <i-col>
                  <iui-text
                    max-length="100"
                    width="95%"
                    :value="companyInfo.frmNm"
                    :placeholder="enable ? '(사업자등록증 상호 등록)' : ''"
                    @change="setCompanyInfo({frmNm: $event.target.value})"
                    :enable="enable"
                    :required="enable"
                    :errorMessage="{title: '회사명', message: '회사명을 입력해주세요.'}"
                  />
                </i-col>
                <i-col></i-col>
                <i-col></i-col>
              </i-row>
              <i-row>
                <i-col-header>회사명(영문)</i-col-header>
                <i-col>
                  <iui-text
                    max-length="100"
                    width="95%"
                    :value="companyInfo.frmEnNm"
                    @change="setCompanyInfo({frmEnNm: $event.target.value})"
                    :enable="enable"
                  />
                </i-col>
                <i-col></i-col>
                <i-col></i-col>
              </i-row>
              <i-row>
                <i-col-header :required="enable">대표자명</i-col-header>
                <i-col>
                  <iui-text
                    width="40%"
                    max-length="50"
                    :value="companyInfo.rpsvNm"
                    @change="setCompanyInfo({rpsvNm: $event.target.value})"
                    :enable="enable"
                    :required="enable"
                    :errorMessage="{title: '대표자명', message: '대표자명을 입력해주세요.'}"
                  />
                </i-col>
                <i-col></i-col>
                <i-col></i-col>
              </i-row>
              <i-row>
                <i-col-header>
                  대표자영문명
                </i-col-header>
                <i-col>
                  <iui-text
                    width="95%"
                    max-length="50"
                    :value="companyInfo.rpsvEnNm"
                    @change="setCompanyInfo({rpsvEnNm: $event.target.value})"
                    :enable="enable"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header :required="enable">대표전화</i-col-header>
                <i-col>
                  <iui-text
                    type="number"
                    :numberFormat="false"
                    width="40%"
                    max-length="20"
                    :value="companyInfo.rpsvPhone"
                    :placeholder="enable ? '(숫자만 입력)' : ''"
                    @change="setCompanyInfo({rpsvPhone: $event.target.value})"
                    :enable="enable"
                    :required="enable"
                    :errorMessage="{title: '대표전화', message: '대표전화를 입력해주세요.'}"
                  />
                </i-col>
                <i-col></i-col>
                <i-col></i-col>
              </i-row>
              <i-row rowspan="2">
                <i-col-header :required="enable">주소</i-col-header>
                <i-col colspan="3">
                  <iui-text
                    width="70px"
                    :required="enable"
                    :enable="false"
                    :errorMessage="{title: '주소', message: '주소를 입력해주세요.'}"
                    :value="companyInfo.postNo"
                  />
                  <iui-button value="검색" @click="onAddr" v-if="enable" />
                  <iui-text
                    :width="enable ? '63%' /* 회원가입 창 */ : '78%' /* 회원가입등록 창 */"
                    :enable="false"
                    :required="enable"
                    :errorMessage="{title: '주소', message: '주소를 입력해주세요.'}"
                    :value="companyInfo.adr1"
                    :placeholder="enable ? '(사업자등록증 주소 등록)' : ''"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col></i-col>
                <i-col colspan="3">
                  <iui-text
                    :style="`margin-left: ${enable ? '120px' /* 회원가입 창 */ : '75px' /* 회원가입등록 창 */}`"
                    :width="enable ? '63%' : '78%'"
                    :value="companyInfo.adr2"
                    max-length="100"
                    @change="setCompanyInfo({adr2: $event.target.value})"
                    :placeholder="enable ? '(상세주소)' : ''"
                    :errorMessage="{title: '주소', message: '상세주소를 입력해주세요.'}"
                    :enable="enable"
                    :required="enable"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>홈페이지</i-col-header>
                <i-col>
                  <iui-text
                    max-length="300"
                    width="95%"
                    :value="companyInfo.homepage"
                    @change="setCompanyInfo({homepage: $event.target.value})"
                    :enable="enable"
                  />
                </i-col>
                <i-col></i-col>
                <i-col></i-col>
              </i-row>
              <i-row>
                <i-col-header :required="enable">업태</i-col-header>
                <i-col>
                  <iui-text
                    width="95%"
                    max-length="100"
                    :value="companyInfo.bcoNm"
                    @change="setCompanyInfo({bcoNm: $event.target.value})"
                    :enable="enable"
                    :required="enable"
                    :errorMessage="{title: '업태', message: '업태를 입력해주세요.'}"
                  />
                </i-col>

                <i-col-header :required="enable">종목</i-col-header>
                <i-col>
                  <iui-text
                    width="95%"
                    max-length="100"
                    :value="companyInfo.itemNm"
                    @change="setCompanyInfo({itemNm: $event.target.value})"
                    :enable="enable"
                    :required="enable"
                    :errorMessage="{title: '종목', message: '종목을 입력해주세요.'}"
                  />
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>관리자 정보</template>
            <iui-container-new type="table" theme="bullet" :headerWidth="'90px'">
              <colgroup>
                <col width="90px" />
                <col width="41%" />
                <col width="90px" />
                <col width="41%" />
              </colgroup>
              <i-row>
                <i-col-header :required="enable">아이디(사번)</i-col-header>
                <i-col>
                  <iui-text
                    width="30%"
                    max-length="8"
                    :value="worker.empNo"
                    @change="setWorker({empNo: $event.target.value})"
                    :enable="enable"
                    :required="enable"
                    :errorMessage="{title: '아이디(사번)', message: '아이디(사번)을 입력해주세요.'}"
                  />
                </i-col>
                <i-col></i-col>
                <i-col></i-col>
              </i-row>
              <i-row>
                <i-col-header :required="enable">이름</i-col-header>
                <i-col>
                  <iui-text
                    width="30%"
                    max-length="50"
                    :value="worker.name"
                    @change="setWorker({name: $event.target.value})"
                    :enable="enable"
                    :required="enable"
                    :errorMessage="{title: '이름', message: '이름을 입력해주세요.'}"
                  />
                </i-col>
                <i-col-header :required="enable">휴대폰번호</i-col-header>
                <i-col>
                  <iui-text
                    type="phone"
                    width="50%"
                    max-length="20"
                    :value="worker.mobileTlno"
                    :placeholder="enable ? '(숫자만 입력)' : ''"
                    @change="setWorker({mobileTlno: $event.target.value})"
                    :enable="enable"
                    :required="enable"
                    :errorMessage="{title: '휴대폰번호', message: '휴대폰번호를 입력해주세요.'}"
                  />
                  <iui-checkbox-group
                    :items="[{label: 'SMS수신동의', value: $getConstants('Y').code}]"
                    @change="setWorker({useSms: $event.target.value})"
                    :checkedValues="[worker.useSms]"
                    :enable="enable"
                    :required="enable"
                    :error-message="{title: 'SMS수신동의', message: 'SMS수신동의를 선택해주세요.'}"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>전화번호</i-col-header>
                <i-col>
                  <iui-text
                    type="phone"
                    width="30%"
                    :value="worker.tlno"
                    @change="setWorker({tlno: $event.target.value})"
                    :enable="enable"
                  />
                </i-col>
                <i-col-header>팩스번호</i-col-header>
                <i-col>
                  <iui-text
                    type="phone"
                    width="50%"
                    :value="worker.rpsvFaxno"
                    @change="setWorker({rpsvFaxno: $event.target.value})"
                    :enable="enable"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header :required="enable">이메일</i-col-header>
                <i-col colspan="3">
                  <iui-text
                    type="email1"
                    width="15%"
                    max-length="50"
                    :value="worker.email1"
                    @change="setWorker({email1: $event.target.value})"
                    :enable="enable"
                    :required="enable"
                    :error-message="{title: '이메일', message: '이메일를 입력해주세요.'}"
                  />
                  <div class="mr5">@</div>
                  <iui-select
                    :p-code="$getConstants('EMAIL_CD').code"
                    :value="worker.email2"
                    defaultCd="직접입력"
                    @change="setWorker({email2: $event.target.value})"
                    :enable="enable"
                    :required="enable"
                    :error-message="{title: '이메일', message: '이메일를 입력해주세요.'}"
                    v-if="enable"
                  />
                  <iui-text
                    type="email2"
                    width="20%"
                    max-length="50"
                    :value="worker.email2"
                    v-if="worker.email2.indexOf('0000') == -1 && enable"
                    @change="setWorker({email2: $event.target.value})"
                    :enable="enable"
                    :required="enable"
                    :error-message="{title: '이메일', message: '이메일를 입력해주세요.'}"
                  />
                  <iui-text width="20%" max-length="50" :value="email2" v-if="!enable" :enable="false" />
                </i-col>
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </div>
</template>

<script>
import {selectCompanyInfo} from '../api/member.js';

export default {
  props: {
    enable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focusKey: {},

      bsnNo1: '',
      bsnNo2: '',
      bsnNo3: '',
      email2: '',
      frmGbNm: '',
    };
  },
  watch: {
    companyInfo: {
      handler(obj) {
        if (obj.bsnNo) {
          this.bsnNo1 = obj.bsnNo.substr(0, 3);
          this.bsnNo2 = obj.bsnNo.substr(3, 2);
          this.bsnNo3 = obj.bsnNo.substr(5, 5);
        }
        if (!obj.pgmCd && !this.enable) {
          this.bsnNo1 = '';
          this.bsnNo2 = '';
          this.bsnNo3 = '';
        }
      },
      deep: true,
    },
    worker: {
      handler(obj) {
        $getCodeNm($getConstants('EMAIL_CD').code, obj.email2).then(response => {
          this.email2 = response;
        });
      },
      deep: true,
    },
  },
  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'ceoList', 'worker', 'searchInfo']);
    $mapMutations(this, ['setCompanyInfo', 'setDupBsnNoStatus', 'setCeoList', 'setWorker']);
  },
  created() {
    this.addEvent([
      {
        name: 'JoinInfo_selectCompanyInfo',
        func: this.selectCompanyInfo,
      },
    ]);
    if (this.enable) this.setFrmGbNm();
  },
  updated() {
    if (!this.enable) {
      this.setFrmGbNm();
    }
  },
  methods: {
    setBsnNo() {
      this.setDupBsnNoStatus(0);
      this.setCompanyInfo({bsnNo: this.bsnNo1 + this.bsnNo2 + this.bsnNo3});
    },
    setFrmGbNm() {
      $getCodeNm($getConstants('FRM_GB').code, this.companyInfo.frmGb).then(response => {
        this.frmGbNm = response;
      });
    },
    async selectCompanyInfo() {
      if (this.searchInfo.pgmCd) {
        this.callEvent({name: 'companyRegistOnDetailLoading', param: true});
        const response = await selectCompanyInfo({
          pgmCd: this.searchInfo.pgmCd,
        });
        this.setCompanyInfo(response.data);
        this.setWorker(response.data.worker);
        this.setCeoList(response.data.ceoList);
        this.callEvent({name: 'companyRegistOnDetailLoading', param: false});
      }
    },
  },
};
</script>

<style></style>
