<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-select
        name="llCode"
        :value="searchInfo.llCode"
        :items="llCode"
        defaultCd="S"
        width="150px"
        @change="onSetValue('llCode', $event)"
      />
      <iui-select
        name="mlCode"
        :value="searchInfo.mlCode"
        :items="mlCode"
        defaultCd="S"
        width="150px"
        @change="onSetValue('mlCode', $event)"
      />
      <iui-text type="search" @change="onSetValue('searchNm', $event)" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        ondblclick: this.grid_onDblClick,
        onRenderFirstFinish,
      }"
      @loadSheet="loadSheet"
      :autoRender="false"
      :init="initSheet"
    />
  </pmis-content-box>
</template>

<script>
/**
 * 사용처 : 사원등록 - 사원정보(@/view/user/Worker/components/WorkerInfo.vue)
 */
import options from './sheetOption/LbrcCodeListPopupSheet.js';
import {
  selectLbrcCodeList,
  selectLbrcCl1List,
  selectLbrcCl2List,
} from '@/view/sysManage/stdCodeManage/lbrcCodeRegist/api/LbrcCodeRegist.js';
export default {
  props: {
    uid: {
      type: Number,
    },
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      searchInfo: {
        llCode: $getConstants('LABOR_LARGE_CODE_JOB').code,
        mlCode: $getConstants('LABOR_MID_CODE_NORMAL').code,
        searchNm: '',
      },
      llCode: [],
      mlCode: [],
      lbrcCodeList: [],
      loadSearchData: [],
      Options: {},
      initSheet: false,
    };
  },
  created() {
    this.addEvent([{name: 'LbrcCodeList_callbackData', func: this.callbackData}]);
    this.createSheet();
    this.onCodeListSearch();
  },
  methods: {
    async createSheet() {
      this.Options = await options(this);
      this.initSheet = true;
    },
    onSetValue(key, obj) {
      let value = typeof obj == 'string' ? obj : obj?.target.value;
      this.searchInfo[key] = value;

      if (key == 'llCode') {
        this.mlCode = this.lbrcCodeList.filter(r => r.llCode == value);
        this.searchInfo['mlCode'] = '';
      }
      this.onSearch();
    },
    async onCodeListSearch() {
      const response = await selectLbrcCl1List({});
      if (response.status == 200) {
        response.data.forEach(element => {
          this.llCode.push({
            text: element.llName,
            value: element.llCode,
          });
        });
        const response2 = await selectLbrcCl2List({});
        if (response2.status == 200) {
          response2.data.forEach(element => {
            this.lbrcCodeList.push({
              llCode: element.llCode,
              llName: element.llName,
              value: element.mlCode,
              text: element.mlName,
            });
          });
          this.mlCode = this.lbrcCodeList.filter(r => r.llCode.includes($getConstants('LABOR_LARGE_CODE_JOB').code));
        }
        this.onSearch();
      }
    },
    async onSearch() {
      let param = {
        pgmCd: this.pgmCd,
        llCode: this.searchInfo.llCode,
        mlCode: this.searchInfo.mlCode,
        searchNm: this.searchInfo.searchNm,
      };
      const response = await selectLbrcCodeList(param);
      if (response.status == 200) {
        this.sheet.loadSearchData(response.data);
      }
    },
    grid_onDblClick(e) {
      if (e.row.Kind == 'Data') {
        this.callEvent({name: 'onHideModal_' + this.uid});
      }
    },
    callbackData(callback) {
      let row = this.sheet.getFocusedRow();
      if (row) {
        if (typeof callback === 'function') {
          callback(row);
        }
      } else {
        this.$alert({title: '노무직종선택', message: '선택된 노무직종이 없습니다.'});
      }
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped></style>
