export default that => {
  return {
    Cfg: {
      CanEdit: 0,
      HeaderMerge: 6,
      IgnoreFocused: 1,
    },
    Cols: [
      {
        Header: ['내역\n순번', '내역\n순번'],
        Name: 'itemSeq',
        Type: 'Text',
        Align: 'Center',
        Width: 60,
      },
      {
        Header: ['자원\n구분', '자원\n구분'],
        Name: 'costType',
        Type: 'Text',
        Align: 'Center',
        Width: 60,
      },
      {
        Header: ['코드', '코드'],
        Name: 'itemCode2',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 130,
      },
      {
        Header: ['명칭', '명칭'],
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
      },
      {
        Header: ['규격', '규격'],
        Name: 'ssize',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 120,
      },
      {
        Header: ['단위', '단위'],
        Name: 'unit',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 80,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: ['수량', '수량'],
        Name: 'qty',
        Extend: that.$ibsheetHelper.preset.quantity,
        RelWidth: 1,
        MinWidth: 100,
      },
      {
        Header: ['재료비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'matPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        Type: 'Int',
        RelWidth: 1,
        MinWidth: 100,
      },
      {
        Header: ['재료비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'matAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Type: 'Int',
        RelWidth: 1,
        MinWidth: 100,
      },
      {
        Header: ['노무비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'labPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        Type: 'Int',
        RelWidth: 1,
        MinWidth: 100,
      },
      {
        Header: ['노무비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'labAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Type: 'Int',
        RelWidth: 1,
        MinWidth: 100,
      },
      {
        Header: ['경비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'equipPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        Type: 'Int',
        RelWidth: 1,
        MinWidth: 100,
      },
      {
        Header: ['경비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'equipAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Type: 'Int',
        RelWidth: 1,
        MinWidth: 100,
      },
      {
        Header: ['합계', {Value: '단가', Color: '#dff9fb'}],
        Name: 'totalPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        Type: 'Int',
        RelWidth: 1,
        MinWidth: 100,
      },
      {
        Header: ['합계', {Value: '금액', Color: '#dff9fb'}],
        Name: 'totalAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        Type: 'Int',
        RelWidth: 1,
        MinWidth: 100,
      },
      {
        Name: 'itemCode1',
        Visible: 0,
      },
      {
        Name: 'formula',
        Visible: 0,
      },
    ],
  };
};
