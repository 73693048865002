export default that => {
  return {
    Def: {
      Row: {
        CanFormula: 1,
        // CalcOrder: 'prjCdButton',
      },
    },
    Cols: [
      {
        Header: '현장코드',
        Name: 'prjCd',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CanEdit: 0,
        ButtonFormula: e => (e.Row['Added'] ? 'Html' : ''),
        ButtonText: '<input type="button" class="i_search"/>',
        WidthPad: 30,
        OnClickSide: () => that.onShowModal(),
      },
      {
        Header: '현장명',
        Name: 'prjNm',
        Type: 'Text',
        RelWidth: 2,
        CanEdit: 0,
      },
      {
        Header: '계약번호',
        Name: 'poNoSubc',
        Type: 'Text',
        RelWidth: 1.5,
        Visible: false,
        CanEdit: 0,
      },
      {
        Header: '계약명',
        Name: 'ttl',
        Type: 'Text',
        RelWidth: 1.5,
        Visible: false,
        CanEdit: 0,
      },
      {
        Header: '투입일자',
        Name: 'rlseDate',
        Extend: that.$ibsheetHelper.preset.date,
        RelWidth: 1,
        CanEdit: true,
      },
      {
        Header: '철수(예정)일자',
        Name: 'outDate',
        Extend: that.$ibsheetHelper.preset.date,
        RelWidth: 1,
        CanEdit: true,
      },
      {
        Header: '교육이수일자',
        Name: 'eduDate',
        Extend: that.$ibsheetHelper.preset.date,
        RelWidth: 1,
        CanEdit: true,
      },
      {
        Header: '안전장구수령여부',
        Name: 'safetyGearYn',
        Type: 'Enum',
        EnumKeys: '|수령|미수령',
        Enum: '|수령|미수령', // 첫 번째 글자를 구분자로 하는 문자열(ex:'#Y#N')
        RelWidth: 1,
        CanEdit: true,
        // EnumKeys: await $getCodeIBSheetEnum('KEY', '0661'),
        //   Enum: await $getCodeIBSheetEnum('VALUE', '0661'),
      },
      {Name: 'pgmCd', Visible: 0},
      {Name: 'empNo', Visible: 0},
      {Name: 'blnDepPart', Visible: 0},
      {Name: 'subCode', Visible: 0},
    ],
  };
};
