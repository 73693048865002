<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-col>
          <iui-container-new type="css-flex">
            <i-row minHeight="500px">
              <i-col>
                <pmis-tab-box>
                  <template #title><b>실행내역</b></template>
                  <template #title-left>
                    <span class="ellipsis">
                      (자원구분[M:재료비 L:노무비 E:장비비 A:경비 I:일위대가])
                    </span>
                    <span class="mr15"></span>
                    <iui-select
                      name="degree"
                      :items="degreeListAsSelect"
                      defaultCd="S"
                      :value.sync="degree"
                      @change="onChangeDegree"
                      label="차수"
                      width="85px"
                      required
                    ></iui-select>
                    <iui-button value="내역확정" @click="dtlsDcns" v-if="confirm && maxDgree"></iui-button>
                    <iui-button value="차수삭제" @click="onDeleteChgDegree" v-if="confirm && maxDgree"></iui-button>
                    <iui-button
                      value="확정취소"
                      @click="dcnsCancl"
                      v-if="!confirm && maxDgree && currentDegree == maxDgree"
                    ></iui-button>
                    <iui-button
                      value="차수생성"
                      @click="onPopChgDegree"
                      v-if="$store.getters['prjCd'] && (!confirm || masterInfo.chgDegree == 0)"
                    />
                  </template>
                  <template #title-right>
                    <iui-button @click="onPopAddPrjWork" v-if="confirm && maxDgree">공종추가</iui-button>
                    <iui-button
                      value="타현장실행내역복사"
                      @click="onPopOtherPrjCopy"
                      v-if="confirm && maxDgree && maxDgree == 1"
                    />
                    <iui-button
                      value="견적내역복사"
                      @click="onPopEstmtDtlsCopyPop"
                      v-if="isEstmtPrj && confirm && maxDgree == 1"
                    />
                    <iui-button value="단가적용" @click="onPopUntpcApplc" v-if="confirm && maxDgree" />
                    <iui-button value="단가조정" @click="onPopUntpcMdac" v-if="confirm && maxDgree" />
                    <iui-button @click="onPopResourceCopyPop" v-if="confirm && maxDgree">자원복사</iui-button>
                    <iui-button @click="onPopArithmeticPop" v-if="isFormula && confirm && maxDgree"
                      >산식변경</iui-button
                    >
                    <iui-button @click="onPopArithmeticPop" v-if="!isFormula && confirm && maxDgree"
                      >산식작성</iui-button
                    >
                    <iui-button value="재계산" @click="onCalculator" v-if="confirm && maxDgree" />
                  </template>
                  <DetailList ref="execDtls" />
                </pmis-tab-box>
              </i-col>
            </i-row>
            <i-row height="250px">
              <i-col>
                <iui-container-new type="css-flex">
                  <i-row height="500px">
                    <i-col><BreakdownDtls /></i-col>
                  </i-row>
                </iui-container-new>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
    <iui-modal name="prjWorkTypePop" title="현장공종추가" sizeType="size3" :btns="prjWorkTypeBtns">
      <PrjWorkTypePop />
    </iui-modal>
    <iui-modal name="chgDegree" title="차수생성" :btns="chgDegreeBtns" width="500px" height="200px">
      <ChgDegree :chgDegree="maxDgree ? Number(maxDgree) : undefined" ref="chgDegree" />
    </iui-modal>

    <iui-modal name="OtherPrjCopyPop" title="타현장실행내역복사" :btns="otherPrjCopyBtns" sizeType="size1">
      <OtherPrjCopyPop :isCopyOpt="true" />
    </iui-modal>

    <iui-modal name="EstmtDtlsCopyPop" title="견적내역복사" :btns="estmtDtlsCopyBtns" sizeType="size1">
      <EstmtDtlsCopyPop />
    </iui-modal>

    <iui-modal name="UntpcApplcPop" title="단가적용" :btns="untpcApplcBtns" sizeType="size1">
      <UntpcApplcPop />
    </iui-modal>

    <iui-modal name="UntpcMdacPop" title="단가조정" :btns="untpcMdacBtns" sizeType="size1">
      <UntpcMdacPop />
    </iui-modal>

    <iui-modal name="resourceCopyPop" title="자원복사" :btns="resourceCopyBtns" sizeType="size3">
      <ResourceCopyPop @costType="setCostType" />
    </iui-modal>

    <iui-modal name="arithmeticPop" title="산식작성" :btns="arithmeticBtns" sizeType="size1">
      <ArithmeticPop :param="isFormula ? detailInfo : {}" :costTypeItems="costTypeItems" />
    </iui-modal>
  </pmis-page>
</template>

<script>
import store from '@/view/executDtls/store/executDtls.js';
import PrjList from '@/view/Project/components/PrjList.vue';
import DetailList from '@/view/executDtls/components/DetailList.vue';
import PrjWorkTypePop from '@/view/estmtManage/resrceManage/popup/PrjWorkTypePop.vue';
import ChgDegree from '@/view/common/popup/ChgDegreePop.vue';
import OtherPrjCopyPop from '@/view/executDtls/components/popup/OtherPrjCopyPop.vue';
import EstmtDtlsCopyPop from '@/view/executDtls/components/popup/EstmtDtlsCopyPop.vue';
import UntpcApplcPop from '@/view/estmtManage/estmtDtlsManage/popup/UntpcApplcPop.vue';
import UntpcMdacPop from '@/view/executDtls/components/popup/UntpcMdacPop.vue';
import ArithmeticPop from '@/view/estmtManage/resrceManage/popup/ArithmeticPop.vue';
import ResourceCopyPop from '@/view/estmtManage/resrceManage/popup/ResourceCopyPop.vue';
import BreakdownDtls from '@/view/common/BreakdownDtls.vue';
import {
  dtlsDcns,
  dcnsCancl,
  insertContContexecPlan,
  deleteContContexecPlan,
  copyOtherPjtCont,
  otherCopyContEmPlanD,
  updateContExecPlanByUcstApply,
  updateContExecPlanByUcstMdac,
} from '../api/executDtls.js';
export default {
  components: {
    PrjList,
    DetailList,
    PrjWorkTypePop,
    ChgDegree,
    OtherPrjCopyPop,
    EstmtDtlsCopyPop,
    UntpcApplcPop,
    UntpcMdacPop,
    ResourceCopyPop,
    ArithmeticPop,
    BreakdownDtls,
  },

  data() {
    return {
      code0265: '',
      prjWorkTypeBtns: [{name: '확인', callback: this.onConfirmPrjWorkType}],
      chgDegreeBtns: [{name: '확인', callback: this.onPopConfirmChgDegree}],
      otherPrjCopyBtns: [{name: '확인', callback: this.onPopConfirmOtherPrjCopyPop}],
      estmtDtlsCopyBtns: [{name: '확인', callback: this.onPopConfirmEstmtDtls}],
      untpcApplcBtns: [{name: '확인', callback: this.onPopConfirmUntpcApplcPop}],
      untpcMdacBtns: [{name: '확인', callback: this.onPopConfirmUntpcMdacPop}],
      resourceCopyBtns: [{name: '확인', callback: this.onPopConfirmResourceCopy}],
      arithmeticBtns: [{name: '확인', callback: this.onPopConfirmArithmetic}],
      costType: '',
      costTypeItems: [
        {text: '재료비', value: $getConstants('COST_TYPE_M').code},
        {text: '노무비', value: $getConstants('COST_TYPE_L').code},
        {text: '장비비', value: $getConstants('COST_TYPE_E').code},
        {text: '경비', value: $getConstants('COST_TYPE_A').code},
      ],
    };
  },

  computed: {
    confirm() {
      return this.masterInfo.prjFlag != this.code0265 && this.currentDegree == this.maxDgree;
    },
    degree: {
      set(value) {
        this.setCurrentDegree(value);
      },
      get() {
        return this.currentDegree;
      },
    },
    isMidCode() {
      return !!this.detailInfo.midCode;
    },
  },

  beforeCreate() {
    $init(this, store);
    $mapGetters(this, [
      'detailInfo',
      'isFormula',
      'currentDegree',
      'masterInfo',
      'maxDgree',
      'degreeListAsSelect',
      'isEstmtPrj',
    ]);
    $mapMutations(this, ['setCurrentDegree']);
    $getCode($getConstants('CONFIRM_FLAG').code).then(response => (this.code0265 = response[0].code));
  },
  created() {
    this.canNew = false;
  },
  methods: {
    setCommonBtn() {
      this.canSave = this.confirm;
      this.canDelete = this.confirm;
    },
    onPopAddPrjWork() {
      this.$modal.show('prjWorkTypePop');
    },
    onConfirmPrjWorkType() {
      this.$modal.hide('prjWorkTypePop');
      this.$refs.execDtls.onSearch();
    },
    onCalculator() {
      this.$refs.execDtls.calculator();
    },
    onChangeDegree() {
      this.$refs.execDtls.onSearch();
    },
    async dtlsDcns() {
      if (!this.currentDegree) {
        this.$alert({title: '내역확정', message: '최종차수를 선택해주세요.'});
        return;
      }
      if (!this.$refs.execDtls.validation()) {
        return;
      }
      if (
        !(await this.$confirm({title: '내역확정', message: `${this.currentDegree}차 실행내역을 확정하시겠습니까?`}))
      ) {
        return;
      }

      try {
        const param = {
          chgDegree: this.masterInfo.chgDegree,
          prjFlag: this.code0265,
        };
        const response = await dtlsDcns(param);
        if (response.data) {
          this.$refs.execDtls.onSearch();
        }
      } catch (error) {
        this.$alert({title: '내역확정', message: error.message});
      }
    },
    async dcnsCancl() {
      if (!(await this.$confirm({title: '확정취소', message: '확정취소 하시겠습니까?'}))) {
        return;
      }
      const param = {
        chgDegree: this.masterInfo.chgDegree,
        prjFlag1: '',
      };
      const response = await dcnsCancl(param);
      if (response.data) {
        this.$refs.execDtls.onSearch();
      }
    },
    async onDeleteChgDegree() {
      if (this.maxDgree) {
        if (
          !(await this.$confirm({title: '차수삭제', message: `${this.currentDegree}차 실행내역을 삭제하시겠습니까?`}))
        ) {
          return;
        }

        try {
          const param = {chgDegree: this.masterInfo.chgDegree};
          const response = await deleteContContexecPlan(param);
          if (response.data) {
            this.$refs.execDtls.onInitSearch();
          }
        } catch (ex) {
          this.$alert({title: '차수삭제', message: ex.message});
        }
      }
    },
    onPopChgDegree() {
      this.$modal.show('chgDegree');
    },
    async onPopConfirmChgDegree() {
      const param = {
        ...this.$refs.chgDegree.getData(),
      };
      const response = await insertContContexecPlan(param);
      if (response.data) {
        this.$refs.execDtls.onInitSearch();
        this.$modal.hide('chgDegree');
      }
    },
    onPopOtherPrjCopy() {
      this.$modal.show('OtherPrjCopyPop');
    },

    onPopConfirmOtherPrjCopyPop() {
      this.callEvent({
        name: 'getFocusedRowByOtherPrjCopyPop',
        param: async row => {
          const param = {
            targetPrjCd: row.prjCd,
            targetChgDegree: row.chgDegree,
            matPriceRt: row.matPriceRt,
            labPriceRt: row.labPriceRt,
            equipPriceRt: row.equipPriceRt,
            oprPriceRt: row.oprPriceRt,
            thousandDn: row.thousandDn,
            tenThousandDn: row.tenThousandDn,
            tenThousandUp: row.tenThousandUp,
            chgDegree: this.maxDgree,
          };
          try {
            const response = await copyOtherPjtCont(param);
            if (response.data) {
              this.$modal.hide('OtherPrjCopyPop');
              this.$refs.execDtls.onSearch();
            }
          } catch (error) {
            this.$alert({title: '타현장실행내역복사', message: error.message});
          }
        },
      });
    },
    onPopEstmtDtlsCopyPop() {
      this.$modal.show('EstmtDtlsCopyPop');
    },
    onPopConfirmEstmtDtls() {
      this.callEvent({
        name: 'EstmtDtlsCopyPop_popConfirm',
        param: async data => {
          if (!data.targetChgDegree) {
            return;
          }
          const param = {
            targetPrjCd: data.prjCd,
            targetChgDegree: data.targetChgDegree,
            matPriceRt: data.matPriceRt,
            labPriceRt: data.labPriceRt,
            equipPriceRt: data.equipPriceRt,
            thousandDn: data.thousandDn,
            tenThousandDn: data.tenThousandDn,
            tenThousandUp: data.tenThousandUp,
            chgDegree: this.maxDgree,
          };
          const response = await otherCopyContEmPlanD(param);
          if (response.data) {
            this.$modal.hide('EstmtDtlsCopyPop');
            this.$refs.execDtls.onSearch();
          }
        },
      });
    },
    onPopUntpcApplc() {
      this.$modal.show('UntpcApplcPop');
    },
    onPopConfirmUntpcApplcPop() {
      this.callEvent({
        name: 'UntpcApplcPop_popConfirm',
        param: async data => {
          let param = {
            costType: data.costType,
            matUcstCode: data.matUcstCode,
            labUcstCode: data.labUcstCode,
            equtUcstCode: data.equtUcstCode,
            oprUcstCode: data.oprUcstCode,
            wcode: data.wcode,
            headCode: data.headCode,
            applyOpt: data.applyOpt,
          };
          const response = await updateContExecPlanByUcstApply(param);
          if (response.data) {
            this.$refs.execDtls.onSearch();
          }
        },
      });
    },
    onPopUntpcMdac() {
      this.$modal.show('UntpcMdacPop');
    },
    onPopConfirmUntpcMdacPop() {
      this.callEvent({
        name: 'UntpcMdacPop_popConfirm',
        param: async data => {
          let param = {
            wcode: data.wcode,
            headCode: data.lgCode,
            midCode: data.mgCode,
            matPriceRt: data.matPriceRt,
            labPriceRt: data.labPriceRt,
            equipPriceRt: data.equipPriceRt,
            oprPriceRt: data.oprPriceRt,
            thousandDn: data.thousandDn,
            tenThousandDn: data.tenThousandDn,
            tenThousandUp: data.tenThousandUp,
            chgDegree: this.maxDgree,
          };
          const response = await updateContExecPlanByUcstMdac(param);
          if (response.data) {
            this.$refs.execDtls.onSearch();
          }
        },
      });
    },
    onPopResourceCopyPop() {
      if (!this.isMidCode) {
        this.$alert({title: '자원복사', message: '최하위 공종을 선택하십시오'});
        return;
      }
      this.$modal.show('resourceCopyPop');
    },
    onPopConfirmResourceCopy() {
      this.$refs.execDtls.resourceCopy(this.costType);
    },
    onPopArithmeticPop() {
      if (!this.isMidCode) {
        this.$alert({title: '산식작성', message: '최하위 공종을 선택하십시오'});
        return;
      }
      this.$modal.show('arithmeticPop');
    },
    onPopConfirmArithmetic() {
      this.$refs.execDtls.airithmetic();
    },
    setCostType(costType) {
      this.costType = costType;
    },
    onSave() {
      this.$refs.execDtls.save();
    },
    onPopDtls(modalNm) {
      this.callEvent({name: 'onPop', param: modalNm});
    },

    onPop(modalNm) {
      this.modalNm = modalNm;
      const title = '타현장견적복사';
      this.modalNm = modalNm;
      this.$modal.show(this.modalNm, {
        title: title,
      });
    },
  },
};
</script>

<style scoped></style>
