const state = function() {
  return {
    searchInfo: {
      searchNm: '', // 외주코드
      workDtFrom: '', //  계약기간 시작
      workDtTo: '', // 계약기간 종료
      includeCmplCtrtYN: '', // 만료계약포함여부
    },
  };
};

const getters = {
  searchInfo: state => state.searchInfo,
};

const mutations = {
  setSearchInfo(state, payload) {
    for (let o in payload) {
      state.searchInfo[o] = String(payload[o]);
    }
  },
};

const actions = {
  setSearchInfo(context, payload) {
    context.commit('setSearchInfo', payload);
  },
};

export default {
  // strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
