<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col>
        <ib-sheet :uid="_uid" :init.sync="initSheet" :autoLoad="false" :options="sheetOpt" @loadSheet="loadSheet" />
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import sheetOpt from './sheetOptions/compareSheet.js';
import {selectSubRfqCompareInfos} from '@/view/bidding/rfq/sub/api/subRfq.js';

export default {
  props: {
    rfqNo: {
      type: String,
      required: true,
    },
    bidRnd: {
      type: String,
    },
  },
  data() {
    return {
      initSheet: false,
      sheetOpt: {},
    };
  },
  watch: {
    rfqNo(v) {
      if (v) {
        this.initCompareSheet();
      }
    },
  },
  mounted() {
    this.initCompareSheet();
  },
  methods: {
    initCompareSheet() {
      this.sheetOpt = sheetOpt(this);
      this.initSheet = true;
    },
    loadSheet(sheet) {
      selectSubRfqCompareInfos(this.rfqNo, this.bidRnd).then(response => {
        sheet.loadSearchData(response.data.compareQuotList || []);
      });
    },
  },
};
</script>

<style></style>
