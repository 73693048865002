<template>
  <pmis-content-box>
    <template #header-left>
      <iui-radio-group p-code="0425" :value="snclDs" @change="onChangeSnclDs" />
    </template>

    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="400px">
          <pmis-content-box>
            <template v-slot:header-left>
              <iui-text :value.sync="searchNm" @enter="onSearch" />
              <iui-button @click="onSearch">검색</iui-button>
            </template>
            <ib-sheet
              v-show="snclDs === SNCL_DS_FIX"
              :uid="_uid"
              :options="Options1"
              :loadSearchData="loadSearchData1"
              :events="{
                onSearchFinish: onSearchFinish1,
                onClick: onClick1,
              }"
              @loadSheet="loadSheet1"
            />
            <ib-sheet
              v-show="snclDs === SNCL_DS_OBS"
              :uid="_uid"
              :options="Options2"
              :loadSearchData="loadSearchData2"
              @loadSheet="loadSheet2"
              :events="{
                onDblClick: onDblClick2,
              }"
            />
          </pmis-content-box>
        </i-col>
        <i-col
          width="30px"
          v-if="snclDs === SNCL_DS_OBS"
          style="display: flex; justify-content: center; align-items: center;"
        >
          <iui-button @click="addApprover">→</iui-button>
          <i-spacer />
          <iui-button @click="removeApprover">←</iui-button>
        </i-col>
        <i-spacer v-else />
        <i-col>
          <pmis-content-box>
            <template v-slot:header-left>
              <label>그룹명</label>
              <iui-text width="430px" :value.sync="tl" :enable="!isFixLine" />
              <iui-button value="삭제" v-if="isFixLine" @click="onDelete" />
              <iui-button value="저장" v-if="!isFixLine" @click="onSave" :enable="!!tl" />
            </template>
            <ib-sheet
              :uid="_uid"
              :options="Options3"
              :loadSearchData="loadSearchData3"
              @loadSheet="loadSheet3"
              :events="{
                onSearchFinish: onSearchFinish3,
                onDblClick: onDblClick3,
                onAfterChange: onAfterChange3,
              }"
            />
          </pmis-content-box>
        </i-col>
        <i-col
          width="30px"
          v-if="snclDs === SNCL_DS_OBS"
          style="display: flex; justify-content: center; align-items: center;"
        >
          <iui-button @click="setOrderUpApprover">↑</iui-button>
          <i-spacer />
          <iui-button @click="setOrderDownApprover">↓</iui-button>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import options1 from './sheetOption/LineSelectSheet1.js';
import options2 from './sheetOption/LineSelectSheet2.js';
import options3 from './sheetOption/LineSelectSheet3.js';
import {selectWokerWithDeptList} from '../api/obs.js';
import {deleteAppLine, saveAppLine, selectApprovalLineDList, selectApprovalLineMList} from '../api/appLine.js';
import APPROVAL from '../../js/approvalConstants.js';

const initObjSearch = {};
initObjSearch[APPROVAL.SNCL_DS_FIX] = '';
initObjSearch[APPROVAL.SNCL_DS_OBS] = '';

export default {
  props: {
    line: {
      type: Object,
      default: undefined,
    },
    appReceiptStandby: {
      type: Boolean,
      default: false,
    },
    set: {
      type: Boolean,
      default: false,
    },
    unset: {
      type: Boolean,
      default: false,
    },
    isInformat: {
      type: Boolean,
    },
  },
  data() {
    return {
      isInit: true,
      sheet1: undefined,
      Options1: options1(this),
      loadSearchData1: [],
      sheet2: undefined,
      Options2: options2(this),
      loadSearchData2: [],
      sheet3: undefined,
      Options3: options3(this),
      loadSearchData3: [],
      snclDs: '',
      tl: '',
      focusSnclNo: 0,
      onSearchNm: {},
      internalLine: {
        snclNo: undefined,
        snclDs: undefined,
        tl: undefined,
        approverFixList: [],
        approverList: [],
        checkLine: undefined,
        orderTitle: undefined,
        lineList: [],
        formAppMaxCnt: 0,
        formAssMaxCnt: 0,
      },
      objSearch: initObjSearch,
    };
  },
  computed: {
    isFixLine() {
      return this.snclDs === APPROVAL.SNCL_DS_FIX;
    },
    searchNm: {
      set(value) {
        this.objSearch[this.snclDs] = value;
      },
      get() {
        return this.objSearch[this.snclDs ?? APPROVAL.SNCL_DS_FIX] ?? '';
      },
    },
    SNCL_DS_FIX() {
      return APPROVAL.SNCL_DS_FIX;
    },
    SNCL_DS_OBS() {
      return APPROVAL.SNCL_DS_OBS;
    },
  },
  watch: {
    set(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this._set();
      }
    },
    unset(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this._unset();
      }
    },
  },
  created() {
    this.initLine();
  },
  methods: {
    searchFixLine(focusSnclNo) {
      this.focusSnclNo = focusSnclNo;
      this.internalLine.approverList = [];
      this.addDrafter();
      this.selectApprovalLineMList();
    },
    async initLine() {
      await this.selectApprovalLineMList();
      await this.selectWokerWithDeptList();
      if (this.line !== undefined) {
        this.internalLine = this.line;
      }

      this.snclDs = this.internalLine.snclDs ?? APPROVAL.SNCL_DS_FIX;
      if (this.snclDs === APPROVAL.SNCL_DS_OBS) {
        this.loadSearchData3 = this.internalLine.approverList;
      } else {
        this.focusSnclNo = this.internalLine.snclNo ?? 0;
      }
      this.setSearchNm();
      this.addDrafter();
    },
    setSearchNm() {
      this.onSearchNm[APPROVAL.SNCL_DS_FIX] = this.searchLineNm;
      this.onSearchNm[APPROVAL.SNCL_DS_OBS] = this.searchDeptNm;
    },
    addDrafter() {
      if (this.internalLine.approverList.length === 0) {
        // 기안자 추가
        this.internalLine.approverList.push({
          snclSn: 0,
          sn: 1,
          sncTp: this.appReceiptStandby ? APPROVAL.SNC_TP_RECEIPT : APPROVAL.SNC_TP_DRAFT,
          empNo: this.userInfo.empNo,
          name: this.userInfo.name,
          dtsNo: this.userInfo.dtsNo,
          rollNo: this.userInfo.rollNo,
        });
      }
    },
    onChangeSnclDs(e) {
      this.snclDs = e.target.value;
      if (this.snclDs === APPROVAL.SNCL_DS_FIX) {
        this.setTl();
        this.loadSearchData3 = this.internalLine.approverFixList || [];
      }
      if (this.snclDs === APPROVAL.SNCL_DS_OBS) {
        this.tl = '';
        this.loadSearchData3 = this.internalLine.approverList || [];
        if (this.isInit) {
          this.isInit = false;
          this.obsFocus();
        }
      }
    },
    loadSheet1(sheet) {
      this.sheet1 = sheet;
    },
    async selectApprovalLineMList() {
      const param = {
        snclDs: APPROVAL.SNCL_DS_FIX,
        tl: this.searchNm,
      };
      const response = await selectApprovalLineMList(param);
      this.loadSearchData1 = response.data;
    },
    loadSheet2(sheet) {
      this.sheet2 = sheet;
    },
    loadSheet3(sheet) {
      this.sheet3 = sheet;
    },
    onSearchFinish1(e) {
      let row;
      if (this.focusSnclNo) {
        e.sheet.getDataRows().some(_row => {
          if (_row.snclNo === this.focusSnclNo) {
            row = _row;
            return true;
          }
        });
        this.snclDs = APPROVAL.SNCL_DS_FIX;
      } else {
        row = e.sheet.getFirstRow();
      }
      e.sheet.focus(row);
      this.focusSnclNo = 0;
      this.selectApprovalLineDList(row);
    },
    obsFocus() {
      this.sheet2.util.treeSearch('blnDepNo', this.userInfo.blnDepNo, true);
    },
    onClick1(e) {
      this.selectApprovalLineDList(e.row);
    },
    async selectApprovalLineDList(row) {
      if (!row) {
        row = this.sheet1.getFocusedRow();
      }

      const param = {
        snclNo: row.snclNo,
      };
      const response = await selectApprovalLineDList(param);
      response.data.map(data => {
        if (data.sncTp === APPROVAL.SNC_TP_COOPERATION && !this.isInformat) {
          data.sncTp = '선택';
        }
      });
      this.internalLine.approverFixList = response.data;
      if (this.snclDs === APPROVAL.SNCL_DS_FIX && this.internalLine.approverFixList.length) {
        this.loadSearchData3 = this.internalLine.approverFixList;
        this.setTl();
      }
    },
    setTl() {
      const row = this.sheet1.getFocusedRow();
      this.tl = row?.tl ?? '';
    },
    onSearchFinish3(e) {
      this.setApproverSeq();
      e.sheet.acceptChangedData();
    },
    async selectWokerWithDeptList() {
      const response = await selectWokerWithDeptList();
      this.loadSearchData2 = await this.setObsTree(response.data);
    },
    setObsTree(data) {
      if (!data.length) {
        return [];
      }
      const items = data.map(item => {
        item.obsName = item.blnDepNm;
        item.Items = item.obsWokerTreeList?.filter(woker => {
          if (woker.empNo !== this.userInfo.empNo) {
            woker.blnDepNm = item.blnDepNm;
            woker.blnDepNo = item.blnDepNo;
            woker.blnDepCd = woker.empNo;
            woker.obsName = woker.name;
            return woker;
          }
        });
        delete item.obsWokerTreeList;
        return item;
      });

      const Items = $_treeModel(items, 'OBS', 'blnDepCd', 'uppBlnDepCd', 'blnDepNo', 'uppBlnDepNo');
      return [{blnDepCd: 'OBS', obsName: this.userInfo.frmNm, blnDepNo: '0', Items: Items}];
    },
    onSearch() {
      this.onSearchNm[this.snclDs]();
    },
    searchDeptNm() {
      this.sheet2.util.treeSearch('blnDepNm', this.searchNm);
    },
    searchLineNm() {
      this.selectApprovalLineMList();
    },
    onDblClick2() {
      this.addApprover();
    },
    onDblClick3(e) {
      if (e.kind === 'Data') {
        this.removeApprover();
      }
    },
    async onAfterChange3(e) {
      this.internalLine.approverList = this.sheet3.getSaveJson(0).data;
    },
    async addApprover() {
      if (!this.isInformat && this.sheet3.getTotalRowCount() === APPROVAL.FORMATTED_LINE_CNT) {
        this.$alert({
          title: '결재선지정',
          message: `정형문서는 최대 ${APPROVAL.FORMATTED_LINE_CNT}명까지 결재선 지정가능합니다.`,
        });
        return;
      }

      const obsRow = this.sheet2.getFocusedRow();
      if (obsRow !== undefined && obsRow.empNo) {
        const rows = this.sheet3.getDataRows();

        if (rows.length > 0) {
          // 중복 확인
          let dup = false;
          rows.some(row => {
            if (row.empNo === obsRow.empNo) {
              dup = true;
              return true;
            }
          });

          if (dup) {
            return;
          }
        }

        const data = {
          snclNo: this.snclNo,
          snclSn: 0,
          sn: 0,
          empNo: obsRow.empNo,
          name: obsRow.name,
          sncTp: APPROVAL.SNC_TP_APPROVAL,
          depNo: obsRow.uppBlnDepNo,
          depNm: obsRow.uppBlnDepNm,
          dtsNo: obsRow.dtsNo,
          rollNo: obsRow.rollNo,
        };

        this.sheet3.addRow({next: this.sheet3.getFirstRow(), init: data});
        this.setApproverSeq();
        this.internalLine.approverList = this.sheet3.getSaveJson(0).data;
      }
    },
    async removeApprover() {
      const row = this.sheet3.getFocusedRow();
      if (row !== undefined && row !== null) {
        if (this.isDrafter(row)) {
          await this.$alert({title: '결재선지정', message: '삭제할 수 없습니다.'});
          return;
        }
        this.sheet3.removeRow(row);
        this.setApproverSeq();
      }
      if (this.snclDs === APPROVAL.SNCL_DS_FIX) {
        this.internalLine.approverFixList = this.sheet3.getSaveJson(0).data;
      } else {
        this.internalLine.approverList = this.sheet3.getSaveJson(0).data;
      }
    },
    async setOrderUpApprover() {
      const row = this.sheet3.getFocusedRow();
      const rowIndex = this.sheet3.getRowIndex(row);

      if (rowIndex > 1) {
        if (this.isDrafter(row)) {
          await this.$alert({title: '결재선지정', message: '상위로 이동할 수 없습니다.'});
          return;
        }

        this.sheet3.moveRow(row, this.sheet3.getRowByIndex(rowIndex - 1));
        this.setApproverSeq();
      }
    },
    async setOrderDownApprover() {
      const row = this.sheet3.getFocusedRow();
      const rowIndex = this.sheet3.getRowIndex(row);
      const rowCount = this.sheet3.getDataRows().length;

      if (rowIndex < rowCount) {
        if (row.sn === 2 && !this.isDrafter(row)) {
          await this.$alert({title: '결재선지정', message: '하위로 이동할 수 없습니다.'});
          return;
        }
        this.sheet3.moveRow(row, this.sheet3.getRowByIndex(rowIndex + 2));
        this.setApproverSeq();
      }
    },
    setApproverSeq() {
      const rows = this.sheet3.getDataRows();

      if (rows.length > 0) {
        let snclSn = rows.length - 1;
        let sn = rows.length;
        rows.forEach(row => {
          this.sheet3.setValue(row, 'snclSn', snclSn);
          this.sheet3.setValue(row, 'sn', sn);
          snclSn--;
          sn--;
        });
      }
    },
    async onSave() {
      let appLineDSaveList = this.sheet3
        .getSaveJson(0)
        .data.map(item => ({empNo: item.empNo, sncTp: item.sncTp, snclSn: item.snclSn}));
      const param = {
        tl: this.tl,
        snclDs: APPROVAL.SNCL_DS_FIX,
        appLineDSaveList: appLineDSaveList,
      };
      const response = await saveAppLine(param);
      this.searchFixLine(response.data);
    },
    async onDelete() {
      if (!(await this.$confirm({title: '삭제', message: '삭제하시겠습니까?'}))) return;
      const param = {
        snclNo: this.sheet1.getFocusedRow()?.snclNo,
      };
      const response = await deleteAppLine(param);
      this.searchFixLine(response.data);
    },
    // 기안
    isDrafter(row) {
      return [APPROVAL.SNC_TP_DRAFT, APPROVAL.SNC_TP_RECEIPT].indexOf(row.sncTp) !== -1;
    },
    // 결재
    isApproval(row) {
      return row.sncTp === APPROVAL.SNC_TP_APPROVAL;
    },
    // 전결
    isDecide(row) {
      return row.sncTp === APPROVAL.SNC_TP_DECIDE;
    },
    // 협조
    isCooperation(row) {
      return row.sncTp === APPROVAL.SNC_TP_COOPERATION;
    },
    isChoice(row) {
      return row.sncTp === '선택';
    },
    async _set() {
      const rows = this.sheet3.getDataRows();
      if (rows.findIndex(row => this.isApproval(row) || this.isDecide(row)) === -1) {
        await this.$alert({title: '결재선지정', message: '결재대상자를 최소한 1인 이상 등록해주십시오.'});
        this.$emit('update:set', false);
        return;
      }
      if (!this.isInformat && rows.length > APPROVAL.FORMATTED_LINE_CNT) {
        this.$alert({
          title: '결재선지정',
          message: `정형문서는 최대 ${APPROVAL.FORMATTED_LINE_CNT}명까지 결재선 지정 가능합니다.`,
        });
        this.$emit('update:set', false);
        return;
      }
      const choiceIndex = rows.findIndex(row => this.isChoice(row));
      if (choiceIndex !== -1) {
        await this.$alert({title: '결재선지정', message: '선택되지 않은 결재 유형이 존재합니다.'});
        this.$emit('update:set', false);
        return;
      }
      if (rows[0].sncTp !== APPROVAL.SNC_TP_APPROVAL) {
        await this.$alert({title: '결재선지정', message: '결재선의 마지막 결재 유형은 결재만 지정 가능합니다.'});
        this.$emit('update:set', false);
        return;
      }
      const cooperatorIndex = rows.findIndex(row => this.isCooperation(row));
      if (cooperatorIndex !== -1) {
        for (let i = cooperatorIndex; i < rows.length; i++) {
          if (rows[i].sncTp === APPROVAL.SNC_TP_DECIDE) {
            await this.$alert({title: '결재선지정', message: '전결권자 이후 협조자 지정이 불가능합니다.'});
            this.$emit('update:set', false);
            return;
          }
        }
      }

      this.internalLine.snclNo = this.snclDs === APPROVAL.SNCL_DS_FIX ? this.sheet1.getFocusedRow()?.snclNo ?? 0 : 0;
      this.internalLine.snclDs = this.snclDs;
      this.internalLine.lineList = [];

      if (this.snclDs === APPROVAL.SNCL_DS_FIX) {
        this.internalLine.approverFixList = rows.map(row => ({
          dtsNo: row.dtsNo,
          empNo: row.empNo,
          name: row.name,
          rollNo: row.rollNo,
          sncTp: row.sncTp,
          snclSn: row.snclSn,
        }));
      } else {
        this.internalLine.approverList = rows.map(row => ({
          dtsNo: row.dtsNo,
          empNo: row.empNo,
          name: row.name,
          rollNo: row.rollNo,
          sn: row.sn,
          sncTp: row.sncTp,
          snclSn: row.snclSn,
        }));
      }

      const approverList =
        this.snclDs === APPROVAL.SNCL_DS_FIX ? this.internalLine.approverFixList : this.internalLine.approverList;
      approverList.forEach(approver => {
        this.internalLine.lineList.push({
          name: approver.name,
          empNo: approver.empNo,
          snclSn: approver.snclSn,
          sncTp: approver.sncTp,
          dtsNo: approver.dtsNo,
          rollNo: approver.rollNo,
          rollNm: this.sheet3.codeFormatObj[$getConstants('ROLL_NO').code][approver.rollNo],
        });
      });
      this.$emit('update:line', this.internalLine);
      this.$emit('update:set', false);
      this.$emit('set-complete', true);
    },
    _unset() {
      this.internalLine.snclNo = undefined;
      this.internalLine.snclDs = undefined;
      this.internalLine.approverList = [];
      this.internalLine.lineList = [];

      this.sheet3.getDataRows().forEach(row => {
        if (row.sn != 1) {
          this.sheet3.removeRow(row);
        }
      });

      this.$emit('update:line', this.internalLine);
      this.$emit('update:unset', false);
      this.$emit('set-complete', false);
    },
    async getCodeIBSheetEnum(type, code, options) {
      let enumKey = await $getCodeIBSheetEnum(type, code, options);
      return `${enumKey}${type === 'KEY' && !this.isInformat ? '' : '|선택'}`;
    },
  },
};
</script>

<style></style>
