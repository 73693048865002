<template>
  <div>
    <table border="1">
      <tr>
        <td>구매상담문의<br />(02)-869-2729</td>
      </tr>
      <tr>
        <td>구매상담신청하기</td>
      </tr>
      <tr>
        <td>회사소개<br />다운로드</td>
      </tr>
      <tr>
        <td>마이페이지</td>
      </tr>
      <tr>
        <td>원격지원</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
