<template>
  <div v-show="visible" :class="rootClass" :style="rootStyle">
    <label v-if="label" :id="labelId" :for="comboBoxId" class="label" :style="labelStyle">{{ label }}</label>
    <span v-if="prefix" :id="prefixId" class="prefix" :style="prefixStyle">{{ prefix }}</span>
    <v-select
      ref="vSelect"
      :id="comboBoxId"
      v-model="internalValue"
      :reduce="item => item.code"
      :multiple="multiple"
      :class="`iui__select ${comboBoxClass}`"
      :style="comboBoxStyle"
      :disabled="!enable"
      :clearable="false"
      @option:selected="change"
      :name="name"
      :required="required"
      :data-error-title="errTitle"
      :data-error-message="errMessage"
      :data-value="dataValue"
      :options="options"
      :selectable="checkSelectable"
      :placeholder="placeholder"
      :searchable="!readonly"
      @search="(v, loading) => onSearch(v)"
      :dropdown-should-open="
        ({noDrop, open, loading}) => {
          if (readonly) {
            return !readonly;
          }

          return noDrop ? false : open && !loading;
        }
      "
      :append-to-body="true"
      :calculate-position="calculate"
      :tabindex="3"
    >
      <template #no-options>
        {{ noOptionsText }}
      </template>
    </v-select>
    <span v-if="suffix" :id="suffixId" class="suffix" :style="suffixStyle">{{ suffix }}</span>
  </div>
</template>

<script>
import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import IuiLabelPrefixSuffixMixin from '@/components/Iui/mixins/IuiLabelPrefixSuffixMixin';
import IuiItemsMixin from '@/components/Iui/mixins/IuiItemsMixin';
import {IuiValidatorMixin} from '@/plugins/IuiValidator';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'iui-select',
  mixins: [IuiBaseMixin, IuiLayoutMixin, IuiLabelPrefixSuffixMixin, IuiItemsMixin, IuiValidatorMixin],
  model: {
    prop: 'value',
    event: 'update:value',
  },
  props: {
    size: {
      type: [Number, String],
      default: undefined,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Array],
      default: undefined,
    },
    placeholder: {
      type: [String, Object],
      default: undefined,
    },
    defaultCd: {
      type: String,
    },
  },
  components: {
    vSelect,
  },
  data() {
    return {
      internalValue: undefined,
      internalPlaceHolder: undefined,
      defaultCdNm: {S: '선택', A: '전체'},
      labelOffsetWidth: 0,
      options: [],
      dataValue: '',
      originalValue: null,
      isUser: false,
      noOptionsText: '일치하는 정보가 없습니다.',
    };
  },
  computed: {
    errTitle() {
      return this.errorMessage instanceof Object ? this.errorMessage.title : 'Confirm';
    },
    errMessage() {
      return this.errorMessage instanceof Object ? this.errorMessage.message : this.errorMessage;
    },
    // id
    comboBoxId: {
      get() {
        return this.getId();
      },
    },

    // class
    rootClass: {
      get() {
        let obj = {
          'iui-combobox': true,
          'iui-layout-debug': this.layoutDebug,
        };

        return obj;
      },
    },
    comboBoxClass: {
      get() {
        let obj = {
          _target_: true,
          combobox: true,
          required: this.required,
          'is-valid-fail': !this.isValid,
        };

        return obj;
      },
    },

    // style
    rootStyle: {
      get() {
        let obj = {
          width: undefined,
          height: undefined,
          flex: undefined,
        };

        if (this.width !== undefined) {
          obj.width = this.width;
          obj.flex = `0 0 this.width`;
        }
        if (this.height !== undefined) {
          obj.height = this.height;
        }

        return obj;
      },
    },
    comboBoxStyle: {
      get() {
        let obj = {
          'min-width': undefined,
          height: undefined,
        };
        if (this.internalWidth !== undefined) {
          obj.width = this.label ? `calc(100% - ${this.labelOffsetWidth}px)` : this.internalWidth;
          obj['flex-grow'] = '0 !important';
          obj['flex-shrink'] = '0 !important';
          obj['flex-basis'] = `${
            this.label ? `calc(100% - ${this.labelOffsetWidth}px)` : this.internalWidth
          } !important`;
        }

        if (this.width !== undefined) {
          obj['min-width'] = 'unset';
        }

        if (this.size !== undefined) {
          obj['height'] = 'unset';
        }

        return obj;
      },
      set(labelOffsetWidth) {
        this.labelOffsetWidth = labelOffsetWidth;
      },
    },

    // computed
    hasPlaceHolder: {
      get() {
        return this.internalPlaceHolder !== undefined;
      },
    },
  },
  watch: {
    value(newValue) {
      if (this.multiple) {
        if (Array.isArray(newValue)) {
          this.internalValue = newValue;
        } else {
          this.internalValue = [newValue];
        }
      } else {
        if (Array.isArray(newValue)) {
          if (newValue.length > 0) {
            this.internalValue = newValue[0] ?? '';
          }
        } else {
          this.internalValue = newValue ?? '';
        }
      }
    },
    internalValue() {
      this.dataValue = this.value;
    },
    internalItems() {
      this.setOption();
    },
  },
  created() {
    this.initSelect();

    vSelect.props.components.default = () => ({
      OpenIndicator: {
        render: createElement => createElement('div'),
      },
    });
  },
  mounted() {
    this.comboBoxStyle = document.querySelector(`#${this.labelId}`)?.offsetWidth + 5 ?? 0;
  },
  updated() {
    this.initLayout();
    this.initSelect();
    this.initValidator();
    this.checkParameter();
  },
  methods: {
    onSearch(value) {
      const dropdown = this.$refs.vSelect.$refs.dropdownMenu;
      if (value && this.options.filter(x => x.label === value).length === 0) {
        dropdown.style.width = this.getOptionWidth(this.noOptionsText);
        dropdown.style.minWidth = this.getOptionWidth(this.noOptionsText);
      } else {
        dropdown.style.width = this.getOptionWidth();
        dropdown.style.minWidth = this.getOptionWidth();
      }
    },
    initSelect() {
      if (this.placeholder !== undefined) {
        if (typeof this.placeholder === 'string') {
          this.internalPlaceHolder = {
            [this.internalItemTextAlias]: this.placeholder,
            [this.internalItemValueAlias]: undefined,
          };
        } else {
          this.internalPlaceHolder = this.placeholder;
        }
      }

      // validator 설정
      this.validatorTargetDataProp = 'internalValue';
      this.validationTriggerEventName = ['valid'];
    },
    change(e) {
      this.isUser = true;
      this.emitUpdateValue();
      this.$emit('change', e?.code);
      this.$emit('valid', e?.code);
    },
    calculate(dropdownList, component, {width, top, left}) {
      dropdownList.style.top = top;
      dropdownList.style.left = left;

      const selectWidth = Number(width.replace('px', ''));
      const optionWidth = this.getOptionWidth().replace('px', '');
      dropdownList.style.width = selectWidth > optionWidth ? selectWidth + 'px' : this.getOptionWidth();
      dropdownList.style.minWidth = selectWidth > optionWidth ? selectWidth + 'px' : this.getOptionWidth();

      setTimeout(() => {
        dropdownList.querySelectorAll('li').forEach(x => x.classList.remove('vs__dropdown-option--highlight'));
        dropdownList.querySelectorAll('li').forEach(x => {
          if (x.innerText.trim() === this.options.filter(x => x.code === this.value)[0]?.label) {
            x.classList.add('vs__dropdown-option--highlight');
          }
        });
        if (!dropdownList.querySelector('li.vs__dropdown-option--highlight')) {
          dropdownList.querySelectorAll('li')[0].classList.add('vs__dropdown-option--highlight');
        }
      }, 1);

      return dropdownList;
    },
    getBytes(str) {
      let character;
      let charBytes = 0;

      for (let i = 0; i < str.length; i += 1) {
        character = str.charAt(i);

        if (escape(character).length > 4) charBytes += 2;
        else charBytes += 1;
      }

      return charBytes;
    },
    getOptionWidth(text = null) {
      let byte = 0;

      if (!text) {
        this.options.forEach(x => {
          if (byte < this.getBytes(x.label)) {
            byte = this.getBytes(x.label);
          }
        });
      } else {
        byte = this.getBytes(text);
      }
      return byte * 5 + 60 + 'px';
    },
    emitUpdateValue() {
      this.$emit('update:value', this.internalValue);
    },
    setValue() {
      let internalValue;
      if (this.multiple) {
        if (Array.isArray(this.value)) {
          internalValue = this.value;
        } else {
          internalValue = [this.value];
        }
      } else {
        if (Array.isArray(this.value)) {
          if (this.value.length > 0) {
            internalValue = this.value[0] ?? '';
          }
        } else {
          internalValue = this.value ?? '';
        }
      }

      const array = this.options.filter(x => String(x.code) === String(internalValue));
      if (array.length > 0) {
        this.internalValue = array[0].label;
      } else {
        this.internalValue = this.options[0]?.label;
        this.change(this.options[0]?.code);
      }
    },
    setOption() {
      const array = [];

      if (this.defaultCd) {
        array.push({label: this.defaultCdNm[this.defaultCd] || this.defaultCd, code: ''});
      }

      this.internalItems?.forEach(x => {
        array.push({label: x[this.internalItemTextAlias], code: x[this.internalItemValueAlias]});
      });

      this.options = array;
      this.setValue();
    },
    checkSelectable(option) {
      const array = this.internalItems.filter(x => option.code === x[this.internalItemValueAlias]);
      if (array.length === 0) {
        return true;
      }
      return array[0][this.internalItemEnableAlias] === undefined || array[0][this.internalItemEnableAlias];
    },
    checkParameter() {
      if (!this.isUser) {
        this.originalValue = this.options.filter(
          x => x.label === this.internalValue || x.code === this.internalValue
        )[0]?.code;
      }

      if (
        this.originalValue !==
        this.options.filter(x => x.label === this.internalValue || x.code === this.internalValue)[0]?.code
      ) {
        this.$refs.vSelect.$el.setAttribute('data-changed', '');
      } else {
        this.$refs.vSelect.$el.removeAttribute('data-changed');
      }
      this.isUser = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select {
  min-width: 60px;
  margin-right: 0;
  display: inline-block;
  background-color: #ffffff;

  ::v-deep .vs__search {
    padding: 0;
    margin: 0;
    border: 0;
    height: 24px;
  }

  ::v-deep .vs__dropdown-toggle {
    padding-bottom: 0;
  }

  ::v-deep .vs__actions {
    padding-top: 0;
    padding-left: 0;
  }

  ::v-deep .vs__selected {
    margin: 0;
  }
}

::v-deep .vs--single.vs--open .vs__selected {
  position: static;
}

::v-deep .vs--disabled .vs__clear,
::v-deep .vs--disabled .vs__dropdown-toggle,
::v-deep .vs--disabled .vs__search,
::v-deep .vs--disabled .vs__selected {
  background-color: #f0f0f0;
}

::v-deep .is-valid-fail .vs__dropdown-toggle {
  border-color: red;
}

::v-deep .vs--disabled .vs__open-indicator {
  width: 11px;
  height: 6px;
  background: url('../../assets/img/img_select.png') no-repeat 50% -30px;
}

::v-deep .vs__open-indicator {
  width: 11px;
  height: 6px;
  background: url('../../assets/img/img_select.png') no-repeat 50% 0px;
}

::v-deep .vs__selected {
  overflow-x: hidden;
  white-space: nowrap;
  max-width: 91px;
}
</style>
