<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col>
        <pmis-content-box>
          <template #title>요청정보</template>
          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <iui-container-new type="table" theme="bullet">
                  <i-row>
                    <i-col-header>현장</i-col-header>
                    <i-col>
                      <iui-text :value="pr.spotDeptcd" :enable="false" width="100px" />
                      <iui-text :value="pr.spotDeptnm" :enable="false" />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>요청제목</i-col-header>
                    <i-col>
                      <iui-text :value="pr.prNo2" :enable="false" width="100px" />
                      <iui-text :value="pr.ttl" :enable="false" />
                    </i-col>
                  </i-row>
                </iui-container-new>
                <iui-container-new type="table" theme="bullet">
                  <i-row>
                    <i-col-header>과세유형</i-col-header>
                    <i-col>
                      <iui-text :value="pr.taxTypenm" :enable="false" />
                    </i-col>
                    <i-spacer />
                    <i-col-header>구매요청자</i-col-header>
                    <i-col>
                      <iui-text :value="pr.chrgUsrnm" :enable="false" width="100px" />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>현장설명유무</i-col-header>
                    <i-col>
                      <iui-radio-group
                        :p-code="$getConstants('SPOT_DSCR_YN').code"
                        :value="pr.spotDscrYn"
                        :enable="false"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col>&nbsp;</i-col>
                    <i-col>
                      <iui-container-new type="table" theme="bullet">
                        <i-row>
                          <i-col-header>현설일자</i-col-header>
                          <i-col>
                            <iui-text :value="pr.spotDscrDt" :enable="false" />
                          </i-col>
                        </i-row>
                      </iui-container-new>
                    </i-col>
                    <i-spacer />
                    <i-col-header>현설담당</i-col-header>
                    <i-col>
                      <iui-text :value="pr.spotDscrUsrnm" :enable="false" width="100px" />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col>&nbsp;</i-col>
                    <i-col colspan="4">
                      <iui-container-new type="table" theme="bullet">
                        <i-row>
                          <i-col-header>현설장소</i-col-header>
                          <i-col>
                            <iui-text :value="pr.spotDscrLoc" :enable="false" />
                          </i-col>
                        </i-row>
                      </iui-container-new>
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>납품요구일</i-col-header>
                    <i-col>
                      <iui-text :value="pr.dlvReqDt" :enable="false" width="100px" />
                    </i-col>
                    <i-spacer />
                    <i-col-header>납품장소</i-col-header>
                    <i-col>
                      <iui-text :value="pr.dlvLoc" :enable="false" />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>요청일자</i-col-header>
                    <i-col>
                      <iui-text :value="pr.prDate" :enable="false" width="100px" />
                    </i-col>
                    <i-spacer />
                    <i-col-header>비고</i-col-header>
                    <i-col>
                      <iui-text :value="pr.prRmrk" :enable="false" />
                    </i-col>
                  </i-row>
                </iui-container-new>
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row height="145px">
      <i-col>
        <pmis-file-list
          id="prInfoFile"
          title="첨부파일"
          theme="bullet"
          :toolbar-visible="false"
          :company-code="pr.orgCd"
          :file-number="pr.flNo"
        />
      </i-col>
    </i-row>
    <i-row height="180px">
      <i-col>
        <pmis-content-box>
          <template #title>요청내역</template>
          <template #title-left>
            <div>(</div>
            <iui-text type="amount" prefix="공급가액" :value="pr.amt" width="200px" height="20px" :enable="false" />
            <iui-text type="amount" prefix="부가세액" :value="pr.vatAmt" width="200px" height="20px" :enable="false" />
            <iui-text type="amount" prefix="합계금액" :value="pr.totAmt" width="200px" height="20px" :enable="false" />
            <div>)</div>
          </template>
          <ib-sheet
            :uid="_uid"
            :options="sheetOpt"
            :loadSearchData="loadSearchData"
            :events="{
              onRenderFirstFinish: () => onSearch(),
              onSearchFinish: sheet_onSearchFinish,
              onAfterClick: sheet_onAfterClick,
            }"
          />
        </pmis-content-box>
      </i-col>
    </i-row>

    <iui-modal name="pr_matCodeInfoModal" title="자재코드" size-type="size4">
      <mat-code-info :propsMatCode="propsMatCode" />
    </iui-modal>
  </iui-container-new>
</template>

<script>
import sheetOpt from './sheetOptions/prInfoSheet.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import {PR_COLUMNS} from '@/view/bidding/js/BiddingColumns.js';
import {selectPrInfo, selectPrItemList} from '@/view/bidding/pr/mat/api/pr.js';

import MatCodeInfo from '@/view/sysManage/stdCodeManage/matCodeRegist/popup/MatCodeInfo.vue';

export default {
  components: {MatCodeInfo},
  props: {
    prNo: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      pr: {...PR_COLUMNS},
      sheetOpt: sheetOpt(this),
      loadSearchData: [],
      propsMatCode: '',
    };
  },
  methods: {
    async onSearch() {
      const response1 = await selectPrInfo(this.prNo);
      const response2 = await selectPrItemList(this.prNo);

      for (let key in this.pr) {
        this.pr[key] = response1.data[key];
      }
      this.pr.prNo2 = COMMON_FUNCTION.getMakeFormat(this.pr.prNo);
      this.pr.prDate = `${this.pr.prDate.substr(0, 4)}-${this.pr.prDate.substr(4, 2)}-${this.pr.prDate.substr(6)}`;

      this.loadSearchData = response2.data;
    },
    sheet_onSearchFinish(e) {
      const items = e.sheet.getDataRows();
      this.pr.amt = items.reduce((accumulator, currentValue) => accumulator + currentValue.amt, 0) || 0;
      this.pr.totAmt = COMMON_FUNCTION.onCalculateTaxAndTotAmt(this.pr).totAmt;
    },
    sheet_onAfterClick(e) {
      if (e.row.Kind == 'Data' && e.col == 'itemCd') {
        this.propsMatCode = e.row.itemCd.toString();
        this.$modal.show('pr_matCodeInfoModal');
      }
    },
  },
};
</script>

<style></style>
