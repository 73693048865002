<template>
  <pmis-content-box>
    <Search slot="header-custom" @search="onSearch" />
    <ib-sheet
      :uid="_uid"
      :options="options"
      :loadSearchData="subMonList"
      :events="{
        onSearchFinish: onSearchFinish,
        onClick: onClick,
      }"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/SubMonMListMSheet.js';
import Search from './SubMonMListSubSearch.vue';
export default {
  components: {
    Search,
  },
  props: {
    registerData: {
      type: Array,
    },
    delete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: options(this),
    };
  },
  beforeCreate() {
    $mapGetters(this, ['subMonList', 'fixedTimeSubSaveDataReqDto']);
    $mapMutations(this, ['setDetailInfo', 'setSearchInfo', 'initDetailInfo', 'initCalculateList', 'setCloseYn']);
    $mapActions(this, ['selectPrePayable', 'selectSubMonDetailList', 'selectSubMonList']);
  },
  created() {
    this.addFuncSave(this.onSave);
  },

  methods: {
    async onSave() {
      if (!this.fixedTimeSubSaveDataReqDto.amt) {
        return;
      }

      try {
        const response = await this.$save({
          url: '/closeFixedTimeSub/saveSubMon',
          param: this.fixedTimeSubSaveDataReqDto,
        });
      } catch (error) {
        console.error(error);
      }
    },
    async onSearch() {
      this.selectSubMonList();
    },
    onSearchFinish(e) {
      this.selectCalculateList(e.sheet.getFirstRow());
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.selectCalculateList(e.row);
      }
    },
    async selectCalculateList(row) {
      this.initDetailInfo();
      this.initCalculateList();
      if (!row) {
        return;
      }
      this.cud = row.cud;
      this.setDetailInfo(row);
      this.setCloseYn(row.closeYn === $getConstants('Y').code);
      this.selectPrePayable();
      await this.selectSubMonDetailList();
    },
  },
};
</script>

<style scoped></style>
