export default that => {
  return {
    Cfg: {
      SearchMode: 2,
      CanEdit: 0,
      CanSort: 0,
    },
    Def: {
      Row: {
        NoColor: 1,
        CanFocus: 0,
      },
    },
    Cols: [
      {
        Header: '순번',
        Name: 'seq',
        Type: 'Text',
        Align: 'center',
        Width: 55,
      },
      {
        Header: '대표자명',
        Name: 'rpsvNm',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
      },
      {
        Header: '대표자명(영문)',
        Name: 'rpsvEnNm',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
      },
    ],
  };
};
