<template>
  <pmis-content-box>
    <iui-container-new type="css-flex">
      <i-row height="280px">
        <i-col>
          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <pmis-content-box>
                  <template #title>공고정보</template>
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header>입찰명</i-col-header>
                      <i-col>
                        <iui-text :value="rfq.ttl" :enable="false" />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>공고번호</i-col-header>
                      <i-col>
                        <iui-text :value="rfq.rfqNo2" width="100px" :enable="false" />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>지급조건</i-col-header>
                      <i-col>
                        <label>현금</label>
                        <iui-text type="rate" width="50px" suffix="" :value="rfq.payCashRatio" :enable="false" />
                        <label>% 어음</label>
                        <iui-text type="rate" width="50px" suffix="" :value="rfq.payCheckRatio" :enable="false" />
                        <label>%</label>
                        <iui-text type="number" width="50px" suffix="" :value="rfq.payTerm" :enable="false" />
                        <label>일 이내</label>
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>납품조건</i-col-header>
                      <i-col>
                        <iui-text :value="rfq.dlvCondRmrkNm" width="100px" :enable="false" />
                      </i-col>
                    </i-row>

                    <template v-if="isPotypeUnit">
                      <i-row>
                        <i-col-header>계약조건</i-col-header>
                        <i-col>
                          <iui-text :value="rfq.contCondRmrk" :enable="false" />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>계약기간</i-col-header>
                        <i-col>
                          <iui-text :value="rfq.contFrDt" :enable="false" width="75px" />
                          <label>~</label>
                          <iui-text :value="rfq.contToDt" :enable="false" width="75px" />
                        </i-col>
                      </i-row>
                    </template>
                    <template v-else>
                      <i-row>
                        <i-col-header>납품장소</i-col-header>
                        <i-col>
                          <iui-text :value="rfq.dlvLoc" :enable="false" />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>납품요구일</i-col-header>
                        <i-col>
                          <iui-text :value="rfq.dlvReqDt" :enable="false" width="75px" />
                        </i-col>
                      </i-row>
                    </template>
                    <i-row>
                      <i-col-header>특이사항</i-col-header>
                      <i-col rowspan="2">
                        <iui-text type="multi" :value="quotRmrk" :enable="false" />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col>&nbsp;</i-col>
                    </i-row>
                  </iui-container-new>
                </pmis-content-box>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-file-list
            id="quotFile"
            title-visible
            title="견적서 파일"
            :toolbar-visible="false"
            :company-code="rfq.orgCd"
            :file-number="quotFileNo"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template #title>견적정보</template>
            <template #title-left>
              <label>(</label>
              <iui-text type="amount" prefix="공급가액" :value="quotAmt" width="200px" height="20px" :enable="false" />
              <iui-text
                type="amount"
                prefix="부가세액"
                :value="quotVatAmt"
                width="200px"
                height="20px"
                :enable="false"
              />
              <iui-text
                type="amount"
                prefix="합계금액"
                :value="quotTotAmt"
                width="200px"
                height="20px"
                :enable="false"
              />
              <label>)</label>
            </template>
            <ib-sheet
              :uid="_uid"
              :options="sheetOpt"
              :loadSearchData="quotItemList"
              :events="{
                onSearchFinish: sheet_onSearchFinish,
                onAfterClick: sheet_onAfterClick,
              }"
            />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal name="Quot_matCodeInfoModal" title="자재코드" size-type="size4">
      <mat-code-info :propsPgmCd="rfq.orgCd" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/quotInfo.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import {RFQ_COLUMNS} from '@/view/bidding/js/BiddingColumns.js';
import {selectQuotInfos} from '@/view/bidding/rfq/mat/api/rfq.js';
export default {
  props: {
    propsRfqNo: {
      type: String,
      default: undefined,
    },
    propsBidRnd: {
      type: String,
      default: undefined,
    },
    propsCusPgmCd: {
      type: [String, Number],
      default: undefined,
    },
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
      quotItemList: [],

      rfq: {...RFQ_COLUMNS},

      quotAmt: 0,
      quotVatAmt: 0,
      quotTotAmt: 0,
      quotRmrk: '',
      quotFileNo: 0,
    };
  },
  computed: {
    isPotypeUnit() {
      return this.rfq.poTypecd == BIDDING_CONSTANTS.CODES.POTYPE_UNIT;
    },
  },
  mounted() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      selectQuotInfos(this.propsRfqNo, this.propsBidRnd, this.propsCusPgmCd).then(response => {
        const rfqInfo = response.data.rfqInfo;
        const quotInfo = response.data.quotInfo;
        const quotItemList = response.data.quotItemList;

        // 공고정보
        for (const key in this.rfq) {
          this.rfq[key] = rfqInfo[key];
        }
        this.rfq.rfqNo2 = COMMON_FUNCTION.getMakeFormat(rfqInfo.rfqNo);

        // 견적정보
        if (quotInfo) {
          this.quotRmrk = quotInfo.quotRmrk;
          this.quotFileNo = quotInfo.estiFlNo;
        }
        this.quotItemList = quotItemList;
      });
    },
    sheet_onSearchFinish(e) {
      // 공급가액, 부가세액, 합계금액 계산
      const quotList = e.sheet.getDataRows();
      this.quotAmt = quotList.reduce((accumulator, currentValue) => accumulator + currentValue['amt'], 0);
      this.quotVatAmt = quotList.reduce((accumulator, currentValue) => accumulator + currentValue['vatAmt'], 0);
      this.quotTotAmt = quotList.reduce((accumulator, currentValue) => accumulator + currentValue['totAmt'], 0);
    },
    sheet_onAfterClick() {
      if (e.row.Kind == 'Data' && e.col == 'itemNm') {
        this.$store.commit('setModalParam', {matCode: e.row.itemCd});
        this.$modal.show('Quot_matCodeInfoModal');
      }
    },
  },
};
</script>

<style></style>
