<template>
  <iui-container-new type="css-flex">
    <i-row height="155px">
      <i-col>
        <PoContSeqList />
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box>
          <template #title>기본정보</template>
          <iui-container-new type="table" theme="bullet">
            <colgroup>
              <col width="90px" />
              <col width="100%" />
              <col width="10px" />
              <col width="80px" />
              <col width="60%" />
              <col width="10px" />
              <col width="80px" />
              <col width="60%" />
            </colgroup>
            <i-row>
              <i-col-header required>현장</i-col-header>
              <i-col>
                <iui-text :value="poInfo.spotDeptcd" :enable="false" width="100px" />
                <iui-text :value="poInfo.spotDeptnm" :enable="false" />
              </i-col>
              <i-spacer />
              <i-col-header required>계약자</i-col-header>
              <i-col colspan="4">
                <iui-text :value="poInfo.cusCd" :enable="false" width="80px" />
                <iui-text :value="poInfo.cusNm" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header required>계약번호</i-col-header>
              <i-col>
                <iui-text :value="poInfo.poNo2" :enable="false" width="100px" />
                <iui-text :value="poInfo.ttl" :enable="false" />
              </i-col>
              <i-spacer />
              <i-col-header required>계약일자</i-col-header>
              <i-col>
                <iui-datepicker :value="poInfo.poDt" :enable="false" />
              </i-col>
              <i-spacer />
              <i-col-header required>공사기간</i-col-header>
              <i-col>
                <iui-datepicker :value="poInfo.contFrDt" :enable="false" />
                <span>~</span>
                <iui-datepicker :value="poInfo.contToDt" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header required>계약내역</i-col-header>
              <i-col :rowspan="poItemListRowspan" colspan="7">
                <PoItemList />
              </i-col>
            </i-row>
            <i-row v-for="i in poItemListRowspan - 1" :key="i">
              <i-col />
            </i-row>
          </iui-container-new>
          <!-- 당초계약 -->
          <PoFirstContSeq v-if="!poStatus.isChangeContSeq" />
          <!-- 변경계약 -->
          <PoChangeContSeq v-else />

          <iui-container-new type="css-flex" height="200px">
            <i-row>
              <i-col>
                <PoSubmitDocList />
              </i-col>
              <i-spacer />
              <i-col>
                <pmis-file-list
                  id="poContractFile"
                  title="전자서명 파일"
                  title-visible
                  :toolbar-visible="false"
                  :companyCode="poInfo.orgCd"
                  :fileNumber="poInfo.flNo"
                />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import PoContSeqList from './PoContSeqList.vue';
import PoItemList from './PoItemList.vue';
import PoFirstContSeq from './PoFirstContSeq.vue';
import PoChangeContSeq from './PoChangeContSeq.vue';
import PoSubmitDocList from '@/view/bidding/poCustomer/common/components/PoSubmitDocList.vue';

export default {
  components: {PoContSeqList, PoItemList, PoFirstContSeq, PoChangeContSeq, PoSubmitDocList},
  data() {
    return {poItemListRowspan: 8};
  },
  beforeCreate() {
    $mapGetters(this, ['poInfo', 'poStatus']);
  },
};
</script>

<style></style>
