export default that => {
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      CanEdit: 1,
    },
    Cols: [
      {
        Header: '현장코드',
        Name: 'prjCd',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: '현장명',
        Name: 'prjNm',
        Type: 'Text',
        Align: 'left',
        RelWidth: 2,
        CanEdit: 0,
      },
      {
        Header: '현장대리인',
        Name: 'empNm',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: '등록사진',
        Name: 'potoCnt',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        CanEdit: 0,
      },
    ],
  };
};
