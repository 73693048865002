<template>
  <iui-container-new type="css-flex">
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onClick,
      }"
      @loadSheet="loadSheet"
    />
  </iui-container-new>
</template>

<script>
import options from './sheetOptions/OperationEquipment.js';
import {selectEqutPrjCodeList} from '../api/OperationEquipment.js';
export default {
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loadSearchData: [],
      Options: $addColCheckbox(options(this)),
      elId: 'sheetDiv' + this._uid,
    };
  },

  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'detailInfo']);
    $mapMutations(this, ['setDetailInfo', 'initDetailInfo']);
  },

  created() {
    this.addEvent([
      {name: 'OperationEquipmentList_onSearch', func: this.onSearch},
      {name: 'onFilter', func: this.onFilter},
      {name: 'onFocusedRowData', func: this.onFocusedRowData},
      {name: 'OperationEquipmentList_getCheckedRows', func: this.getCheckedRows},
      {name: 'duplicateCarNo', func: this.duplicateCarNo},
    ]);
  },
  methods: {
    onSearch(param) {
      selectEqutPrjCodeList({})
        .then(response => {
          if (response.status == 200) {
            this.sheet.loadSearchData(response.data);

            if (!this.isPopup) {
              let focusRow;
              if (this.sheet.getDataRows().length > 0) {
                if (param) {
                  setTimeout(() => {
                    for (let row of this.sheet.getDataRows()) {
                      if (row.equipCode == param.equipCode && row.carNo == param.carNo) {
                        focusRow = row;
                        break;
                      }
                    }
                    this.sheet.focus(focusRow);
                    this.setDetailInfo(focusRow);
                  }, 100);
                } else {
                  focusRow = this.sheet.getRowById('AR1');
                  this.setDetailInfo(focusRow);
                }
              } else {
                this.initDetailInfo();
              }
            }
          }
        })
        .catch(ex => {
          console.error('error!!!!! : ' + ex);
        });
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        if (!this.isPopup) {
          this.cud = 0;
          this.setDetailInfo(e.row);
        }
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
    onFilter(filter) {
      if (filter) {
        this.sheet.setFilter({name: 'equipCode', filter: "equipCode=='" + filter + "'?1:0", render: 1});
      } else {
        this.sheet.clearFilter();
      }
    },
    onFocusedRowData(param) {
      let row = this.sheet.getFocusedRow();
      if (typeof param == 'function') {
        param(row);
      }
    },
    getCheckedRows(callback) {
      const checkedRows = this.sheet.getRowsByChecked('chk');
      callback(checkedRows);
    },
    duplicateCarNo(callback) {
      let isDuplicate = false;
      let duplicateCarNo = this.sheet.getDataRows().find(row => row.carNo == this.detailInfo.carNo);
      if (duplicateCarNo !== undefined) {
        isDuplicate = true;
      }

      if (typeof callback === 'function') {
        callback(isDuplicate);
      }
    },
  },
};
</script>

<style scoped></style>
