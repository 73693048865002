var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_container",class:{last: _vm.isLast}},_vm._l((_vm.attr),function(atr,i){return _c('div',{key:i,staticClass:"item",class:[
          atr.direction=='column' || (atr.sRow&&atr.sRow.length) ? 'flex-direction-column':'flex-direction-row',
          (!atr.sRow || !atr.sRow.length) && atr.direction=='column'&&atr.verticalAlign=='top' ? 'justify-content-start':'',
          (!atr.sRow || !atr.sRow.length) && atr.direction=='column'&&atr.verticalAlign=='middel' ? 'justify-content-center':'',
          (!atr.sRow || !atr.sRow.length) && atr.direction=='column'&&atr.verticalAlign=='bottom' ? 'justify-content-end':'',
          (!atr.sRow || !atr.sRow.length) && atr.direction=='column'&&atr.align=='left' ? 'align-items-start':'',
          (!atr.sRow || !atr.sRow.length) && atr.direction=='column'&&atr.align=='center' ? 'align-items-center':'',
          (!atr.sRow || !atr.sRow.length) && atr.direction=='column'&atr.align=='right' ? 'align-items-end':'',
          (!atr.sRow || !atr.sRow.length) && atr.direction=='row'&&atr.verticalAlign=='top' ? 'align-items-start':'',
          (!atr.sRow || !atr.sRow.length) && atr.direction=='row'&&atr.verticalAlign=='middel' ? 'align-items-center':'',
          (!atr.sRow || !atr.sRow.length) && atr.direction=='row'&&atr.verticalAlign=='bottom' ? 'align-items-end':'',
          (!atr.sRow || !atr.sRow.length) && atr.direction=='row'&&atr.align=='left' ? 'justify-content-start':'',
          (!atr.sRow || !atr.sRow.length) && atr.direction=='row'&&atr.align=='center' ? 'justify-content-center':'',
          (!atr.sRow || !atr.sRow.length) && atr.direction=='row'&&atr.align=='right' ? 'justify-content-end':'',                    
          atr.name ? 'tit':'slot' ],style:({'flex': atr.flex ? atr.flex : 1, background: atr.label ? '#666':'', color: atr.label ? '#fff':'', padding: atr.label ? '5px 0':''})},[(atr.required && atr.name)?_c('span',{staticClass:"req",attrs:{"title":"필수항목"}},[_vm._v("＊")]):(!atr.required && atr.name)?_c('span',{staticClass:"nonReq",attrs:{"title":""}}):_vm._e(),(atr.name)?_c('span',{domProps:{"innerHTML":_vm._s(atr.name)}}):_vm._e(),(!atr.name && (!atr.sRow || !atr.sRow.length))?_vm._t(i):_vm._e(),_vm._l((atr.sRow),function(row,j){return _c('div',{key:j,staticClass:"item",staticStyle:{"flex-direction":"row"}},[_c('div',{staticClass:"item tit",class:[
                  atr.titVerticalAlign=='top' ? 'align-items-start':'',
                  atr.titVerticalAlign=='middel' ? 'align-items-center':'',
                  atr.titVerticalAlign=='bottom' ? 'align-items-end':'',
                  !atr.titVerticalAlign ? 'align-items-center':'',
                  atr.titAlign=='left' ? 'justify-content-start':'',
                  atr.titAlign=='center' ? 'justify-content-center':'',
                  atr.titAlign=='right' ? 'justify-content-end':'',
                  !atr.titAlign ? 'justify-content-start':''
                  
              ],style:({'flex': row.titFlex ? row.titFlex : 1})},[(row.required && row.name)?_c('span',{staticClass:"req",attrs:{"title":"필수항목"}},[_vm._v("＊")]):(!row.required && row.name)?_c('span',{staticClass:"nonReq",attrs:{"title":""}}):_vm._e(),(row.name)?_c('span',{domProps:{"innerHTML":_vm._s(row.name)}}):_vm._e(),(!row.name && (!row.sRow || !row.sRow.length))?_vm._t(row.slot):_vm._e()],2),_c('div',{staticClass:"item slot",class:[
                  atr.direction=='column' ? 'flex-direction-column':'flex-direction-row',
                  atr.direction=='column'&&atr.verticalAlign=='top' ? 'justify-content-start':'',
                  atr.direction=='column'&&atr.verticalAlign=='middel' ? 'justify-content-center':'',
                  atr.direction=='column'&&atr.verticalAlign=='bottom' ? 'justify-content-end':'',
                  atr.direction=='column'&&atr.align=='left' ? 'align-items-start':'',
                  atr.direction=='column'&&atr.align=='center' ? 'align-items-center':'',
                  atr.direction=='column'&atr.align=='right' ? 'align-items-end':'',
                  atr.verticalAlign=='top' ? 'align-items-start':'',
                  atr.direction=='row'&&atr.verticalAlign=='middel' ? 'align-items-center':'',
                  atr.direction=='row'&&atr.verticalAlign=='bottom' ? 'align-items-end':'',
                  atr.direction=='row'&&atr.align=='left' ? 'justify-content-start':'',
                  atr.direction=='row'&&atr.align=='center' ? 'justify-content-center':'',
                  atr.direction=='row'&&atr.align=='right' ? 'justify-content-end':'' ],style:({'flex': row.flex ? row.flex : 1})},[_vm._t(row.slot)],2)])})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }