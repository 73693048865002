export default that => {
  let preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      CanEdit: 0,
    },
    Def: {
      Row: {
        CanFormula: 1,
      },
    },
    LeftCols: [
      {
        Header: '정산년월',
        Name: 'investMm',
        Width: 100,
        Align: 'center',
        CustomFormat: '####-##',
      },
      {
        Header: '정산구분',
        Name: 'investKind',
        Width: 100,
        Align: 'center',
        Format: $getConstants('INVEST_KIND').code,
        CustomFormat: v => {
          const obj = {};
          obj[$getConstants('INVEST_KIND_ROUTINE').code] = '정기';
          obj[$getConstants('INVEST_KIND_NOT_ROUTINE').code] = '수시';
          return obj[v];
        },
      },
      {
        Header: '차수',
        Name: 'reqDegree',
        width: 80,
        Align: 'center',
        Formula: fr => (fr.Row.closeYn == $getConstants('Y').code ? '' : fr.Row.reqDegree),
      },
      {Header: '거래처', Name: 'custName', Width: 150, Align: 'left'},
    ],
    Cols: [
      {Header: '거래처코드', Name: 'custCode', Visible: 0},
      {Header: '계약/발주번호', Name: 'poNo', Width: 160, Align: 'center'},
      {Header: '계약/발주명', Name: 'ttl', RelWidth: 1, Align: 'left'},
      {Header: '공급가', Name: 'amt', Extend: preset.amount, RelWidth: 1},
      {Header: '부가세', Name: 'vatAmt', Extend: preset.amount, RelWidth: 1},
      {Header: '비과세', Name: 'taxexe', Extend: preset.amount, RelWidth: 1},
      {Header: '합계', Name: 'sumAmt', Extend: preset.amount, RelWidth: 1},
      {
        Header: '세금계산서',
        Name: 'billNo',
        Align: 'center',
        RelWidth: 1,
        TextStyle: 4, // 밑줄
        Cursor: 'pointer',
      },
      {
        Header: '계산서',
        Name: 'billTaxNo',
        Align: 'center',
        RelWidth: 1,
        TextStyle: 4, // 밑줄
        Cursor: 'pointer',
      },
    ],
    Events: {
      onClick: that.openBillPopup,
    },
  };
};
