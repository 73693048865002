<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text type="search" :value.sync="searchNm" @keyup.enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onSearchFinish,
        onClick,
        onDblClick,
        onBeforeChange,
      }"
    />
    <iui-modal name="prjBreakdownCost_unitModal" title="단위" width="1000px" height="500px" :btns="modalBtns">
      <UnitPopup />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import UnitPopup from '@/view/sysManage/stdCodeManage/components/UnitPopup.vue';
import {deleteContSctm, saveContSctm, selectPrjBreakdownCostList, updateCalcContSctm} from '../api/prjBreakdownCost.js';
import options from './sheetOption/DetailListSheet.js';

export default {
  components: {
    UnitPopup,
  },
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this)),
      loadSearchData: [],
      modalBtns: [{name: '확인', callback: this.onPopConfirm}],
      focusKey: '',
      searchNm: '',
    };
  },

  beforeCreate() {
    $mapMutations(this, ['setDetailInfo', 'initDetailInfo']);
  },

  created() {
    this.addEvent([
      {name: 'selectPrjBreakdownCostList', func: this.onSearch},
      {name: 'getRowsByCheckedFromPrjBreakDown', func: this.getRowsByChecked},
      {name: 'initSearchNm', func: this.initSearchNm},
      {name: 'calcBreakdownCost', func: this.onCalcBreakdownCost},
      {name: 'setSumAmt', func: this.setSumAmt},
    ]);

    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },

  methods: {
    async onSearch() {
      this.cud = 0;
      this.callEvent({name: 'initDtlsGrid'});
      const param = {
        searchNm: this.searchNm,
      };
      const response = await selectPrjBreakdownCostList(param);
      this.loadSearchData = response.data;
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onSearchFinish(e) {
      let focusedRow;
      if (this.focusKey) {
        e.sheet.getDataRows().some(row => {
          if (row.itemCode == this.focusKey) {
            focusedRow = row;
            e.sheet.focus(row);
            return true;
          }
        });
        this.focusKey = '';
      } else {
        focusedRow = this.sheet.getFirstRow();
      }
      this.selectPrjBreakdownCostListDtls(focusedRow);
    },
    getWorkingList() {
      let prjBreakdownCostDtlsList = [];
      this.callEvent({
        name: 'getSaveJsonAsBreakDownDtl',
        param: data => {
          prjBreakdownCostDtlsList = $_statusToCud(data);
        },
      });
      return prjBreakdownCostDtlsList;
    },
    onClick(e) {
      e.row.CanFocus = 0;
      if (e.row.Kind == 'Data') {
        const workingList = this.getWorkingList();
        if (workingList.length === 0) {
          e.row.CanFocus = 1;
          e.sheet.focus(e.row);
          this.selectPrjBreakdownCostListDtls(e.row);
        } else {
          if (String(e.row.itemCode) !== workingList[0].itemCode1) {
            this.$confirm({
              title: '일위대가',
              message: '수정 중인 내역이 있습니다. <br> 다른 내역을 조회하시겠습니까?',
            }).then(isConfirm => {
              if (isConfirm) {
                e.row.CanFocus = 1;
                e.sheet.focus(e.row);
                this.selectPrjBreakdownCostListDtls(e.row);
              }
            });
          }
        }
      }
    },
    onDblClick(e) {
      if (this.cud !== 1 || e.row.Added) {
        return;
      }
      e.sheet.focus(this.sheet.getRowsByStatus('Added')[0]);
    },
    selectPrjBreakdownCostListDtls(row) {
      this.setDetailInfo(row);
      this.callEvent({
        name: 'selectPrjBreakdownCostListDtls',
        param: this.cud === 1 && row.Added,
      });
    },
    onBeforeChange(e) {
      if (e.col === 'itemCode') {
        let isDup = false;
        e.sheet.getDataRows().some(row => {
          if (row.id != e.row.id && row.itemCode == e.val) {
            isDup = true;
            return true;
          }
        });
        if (isDup) {
          this.$alert({title: '현장일위대가', message: '이미 등록된 코드가 있습니다.'});
          return e.oldval;
        } else {
          this.setDetailInfo({itemCode: e.val});
          return e.val;
        }
      }
      if (e.col === 'itemName') {
        this.setDetailInfo({itemName: e.val});
      }
    },
    getRowsByChecked(callback) {
      callback(this.sheet.getRowsByChecked('chk'));
    },
    getFocusedRow(callback) {
      callback(this.sheet.getFocusedRow());
    },
    onAdd() {
      if (this.sheet.getRowsByStatus('Added').length) {
        return;
      }

      const workingList = this.getWorkingList();
      if (workingList.length === 0) {
        this.initDetailInfo();
        this.callEvent({name: 'initDtlsGrid'});
        const addRow = this.sheet.addRow({
          init: {
            unit: this.$store.state.code2.codeAlias.UNIT_EXPRESSION.code,
          },
        });
        this.sheet.calculate(1);
        this.sheet.focus(addRow, 'itemCode');
      } else {
        this.$confirm({
          title: '일위대가',
          message: '수정 중인 내역이 있습니다. <br> 신규 내역을 생성하시겠습니까?',
        }).then(isConfirm => {
          if (isConfirm) {
            this.initDetailInfo();
            this.callEvent({name: 'initDtlsGrid'});
            const addRow = this.sheet.addRow({
              init: {
                unit: this.$store.state.code2.codeAlias.UNIT_EXPRESSION.code,
              },
            });
            this.sheet.calculate(1);
            this.sheet.focus(addRow, 'itemCode');
          }
        });
      }
    },
    async onSave() {
      const workingList = this.getWorkingList();

      const row =
        this.sheet.getSaveJson().data.length > 0 ? this.sheet.getSaveJson().data : [this.sheet.getFocusedRow()];

      let message = '';
      let isValid = true;
      row.forEach(x => {
        const itemCode = String(x.itemCode);
        if (!itemCode) {
          message = '코드를 입력해주세요.';
          isValid = false;
        } else if (itemCode.length !== 12) {
          message = '코드는 12자로 입력해주세요.';
          isValid = false;
        } else if (!x.itemName) {
          message = '명칭을 입력해주세요.';
          isValid = false;
        }

        if (!isValid) {
          return false;
        }
      });

      if (!isValid) {
        this.$alert({title: '저장', message: message});
        return false;
      }

      try {
        const array = [];
        row.forEach(x => {
          const obj = {
            itemCode: x.itemCode,
            itemName: x.itemName,
            ssize: x.ssize,
            unit: x.unit,
            amt: x.amt,
            matAmt: x.matAmt,
            labAmt: x.labAmt,
            equipAmt: x.equipAmt,
            pgmCd: this.pgmCd,
            prjCd: this.prjCd,
          };

          if (String(x.itemCode) === String(this.sheet.getFocusedRow().itemCode)) {
            obj.prjBreakdownCostDtlsList = workingList;
          }

          array.push(obj);
        });

        const response = await saveContSctm(array);
        if (response.data) {
          this.focusKey = this.sheet.getFocusedRow().itemCode;
          this.onSearch();
        }
      } catch (error) {
        this.$alert({title: '저장', message: error.message});
        this.selectPrjBreakdownCostListDtls(row);
      }
    },
    async onDelete() {
      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (!checkedRows.length) {
        this.$alert({title: '현장일위대가삭제', message: '삭제할 일위대가를 선택해주세요'});
        return;
      }
      if (!(await this.$confirm({title: '현장일위대가삭제', message: '선택한 일위대가를 삭제하시겠습니까?'}))) {
        return;
      }

      if (checkedRows.filter(x => !x.Added).length > 0 && this.sheet.getDataRows().filter(x => x.Added).length > 0) {
        this.$alert({title: '삭제', message: '저장하지 않은 데이터가 있어 삭제할 수 없습니다.'});
        return;
      }

      const array = checkedRows.filter(x => !x.Added);
      if (array.length > 0) {
        try {
          const param = {
            itemCodeList: checkedRows.map(row => row.itemCode),
          };
          const response = await deleteContSctm(param);
          if (response.data) {
            this.onSearch();
          }
        } catch (error) {
          this.$alert({title: '현장일위대가삭제', message: error.message});
        }
      } else {
        checkedRows
          .filter(x => x.Added === 1)
          .forEach(x => {
            this.sheet.removeRow(x);
          });
      }
    },
    onPopConfirm() {
      this.callEvent({
        name: 'UnitPopupp_popConfirm',
        param: data => {
          const row = this.sheet.getFocusedRow();
          this.sheet.setValue(row, 'unit', data.unitCode, 1);
          this.$modal.hide('prjBreakdownCost_unitModal');
        },
      });
    },
    initSearchNm() {
      this.searchNm = '';
    },
    async onCalcBreakdownCost() {
      let prjBreakdownCostDtlsList;
      this.callEvent({
        name: 'getSaveJsonAsBreakDownDtl',
        param: data => {
          if (data.length) {
            data.forEach(item => {
              item['pgmCd'] = this.pgmCd;
              item['prjCd'] = this.prjCd;
            });
            prjBreakdownCostDtlsList = $_statusToCud(data);
          }
        },
      });

      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (!checkedRows.length && 0 < this.sheet.getTotalRowCount()) {
        this.sheet.setCheck(this.sheet.getFocusedRow(), 'chk', 1);
        checkedRows.push(this.sheet.getFocusedRow());
      }
      if (!checkedRows.length) {
        this.$alert({title: '재계산', message: '재계산할 일위대가를 선택해주세요.'});
        return;
      }

      const param = {
        itemCodeList: checkedRows.map(row => row?.itemCode),
        prjBreakdownCostDtlsList: prjBreakdownCostDtlsList,
      };
      const response = await updateCalcContSctm(param);
      if (response.data) {
        this.onSearch();
      }
    },
    setSumAmt(param) {
      const focusedRow = this.sheet.getFocusedRow();
      this.sheet.setValue(focusedRow, 'matAmt', param.matAmt, 1);
      this.sheet.setValue(focusedRow, 'labAmt', param.labAmt, 1);
      this.sheet.setValue(focusedRow, 'equipAmt', param.equipAmt, 1);
    },
  },
};
</script>

<style scoped></style>
