import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
export default that => {
  return {
    Cfg: {CanEdit: false},
    Cols: [
      {Header: '현장명', Name: 'spotDeptnm', Type: 'Text', RelWidth: 1, MinWidth: 200},
      {
        Header: '요청번호',
        Name: 'prNo',
        Type: 'Text',
        Align: 'center',
        Width: 130,
        CustomFormat: v => COMMON_FUNCTION.getMakeFormat(v),
        TextColor: '#FF0000',
        TextStyle: 5,
        Cursor: 'pointer',
      },
      {Header: '요청제목', Name: 'ttl', Type: 'Text', RelWidth: 1, MinWidth: 200},
      {Header: '공사기간', Name: 'constrPeriod', Type: 'Text', Width: 180, Align: 'Center'},
      {Header: '요청일자', Name: 'prDate', Type: 'Text', Align: 'center', Width: 100},
      {Header: '구매요청자', Name: 'chrgUsrnm', Type: 'Text', Align: 'center', Width: 100},
    ],
  };
};
