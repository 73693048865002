<template>
  <pmis-tab-box>
    <template #title>기성목록</template>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col min-width="1200px">
          <pmis-content-box>
            <template #title-left>
              <iui-container-new type="css-flex">
                <i-row>
                  <i-col>
                    <iui-container-new type="table" theme="bullet">
                      <colgroup>
                        <col style="width: 70px" />
                        <col />
                        <col style="width: 90px" />
                        <col />
                        <col style="width: 90px" />
                        <col />
                      </colgroup>
                      <i-row>
                        <i-col-header>계약금액</i-col-header>
                        <i-col>
                          <iui-text type="amount" :value="ctrtSumAmt" :enable="false" width="120px" align="right" />
                        </i-col>
                        <i-col-header>청구누계금액</i-col-header>
                        <i-col>
                          <iui-text type="amount" :value="reqSumAmt" :enable="false" width="120px" align="right" />
                        </i-col>
                        <i-col-header>수금누계금액</i-col-header>
                        <i-col>
                          <iui-text type="amount" :value="totalAmt" :enable="false" width="120px" align="right" />
                        </i-col>
                      </i-row>
                    </iui-container-new>
                  </i-col>
                  <i-col width="50px" />
                  <i-col>
                    <iui-container-new type="table" theme="bullet">
                      <i-row>
                        <i-col-header width="60px">거래처</i-col-header>
                        <i-col>
                          <iui-text :value="orderNm" :enable="false" />
                        </i-col>
                      </i-row>
                    </iui-container-new>
                  </i-col>
                </i-row>
              </iui-container-new>
            </template>
            <ib-sheet
              :uid="_uid"
              :options="sheetOpt1"
              :loadSearchData="loadSearchData1"
              @loadSheet="sheet1 = $event"
              :events="{
                onSearchFinish: sheet1_onSearchFinish,
                onClick: sheet1_onClick,
              }"
            />
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col min-width="595px">
          <pmis-content-box>
            <template #title-bullet>공제정보</template>
            <template #title-right>
              <template v-if="isExistPxcond">
                <iui-button value="추가" @click="onAddRow(2)" />
                <iui-button value="저장" @click="onSaveRow(2)" />
                <iui-button value="삭제" @click="onDeleteRow(2)" />
              </template>
            </template>
            <ib-sheet
              :uid="_uid"
              :options="sheetOpt2"
              :loadSearchData="loadSearchData2"
              @loadSheet="sheet2 = $event"
              :events="{
                onSearchFinish: sheet2_onSearchFinish,
                onAfterChange: sheet2_onAfterChange,
              }"
            />
          </pmis-content-box>
        </i-col>
        <i-spacer />
        <i-col min-width="595px">
          <pmis-content-box>
            <template #title-bullet>직불정보</template>
            <template #title-right>
              <template v-if="isExistPxcond">
                <iui-button value="추가" @click="onAddRow(3)" />
                <iui-button value="저장" @click="onSaveRow(3)" />
                <iui-button value="삭제" @click="onDeleteRow(3)" />
              </template>
            </template>
            <ib-sheet
              :uid="_uid"
              :options="sheetOpt3"
              :loadSearchData="loadSearchData3"
              @loadSheet="sheet3 = $event"
              :events="{
                onSearchFinish: sheet3_onSearchFinish,
                onAfterChange: sheet3_onAfterChange,
              }"
            />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal name="PoCompleteListModal" title="거래처" :btns="modalBtns" sizeType="size2">
      <SubCustListPopup :uid="_uid" />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import sheetOpt1 from './sheetOptions/PxcondListSheet1.js';
import sheetOpt2 from './sheetOptions/PxcondListSheet2.js';
import sheetOpt3 from './sheetOptions/PxcondListSheet3.js';
import {
  selectPxcondList,
  selectDeductList,
  selectDirectList,
  saveDeductList,
  saveDirectList,
  deleteDeductList,
  deleteDirectList,
} from '@/view/pxcondBilclctRegist/api/pxcondBilclctRegist.js';

import SubCustListPopup from '@/view/Resource/components/SubCustListPopup.vue';

export default {
  components: {SubCustListPopup},
  data() {
    return {
      sheetOpt1: sheetOpt1(this),
      sheetOpt2: sheetOpt2(this),
      sheetOpt3: sheetOpt3(this),
      loadSearchData1: [],
      loadSearchData2: [],
      loadSearchData3: [],
      focusKey: undefined,
      modalBtns: [{name: '확인', callback: this.onConfirm}],

      ctrtSumAmt: '', // 계약금액
      reqSumAmt: '', // 기성누계금액
      repSumAmt: '', // 수금총액

      orderNm: '', // 거래처
      deductSumAmt: '', // 공제금액
      directSumAmt: '', // 직불금액

      totalAmt: '', // 수금누계금액 = 수금총액 + 공제금액 + 직불금액
    };
  },
  computed: {
    isExistPxcond() {
      return 0 < Number(this.pxcondInfo.reqCnt);
    },
  },
  beforeCreate() {
    $mapGetters(this, ['pxcondInfo', 'pxcondCollectInfo']);
    $mapMutations(this, ['setPxcondInfo', 'initPxcondInfo']);
  },
  created() {
    this.addEvent([{name: 'PxcondList_Search', func: this.onSearch}]);
    this.addFuncPrj(this.onSearch);
  },
  methods: {
    onCheckAmt() {
      const repSumAmt = Number(this.repSumAmt); // 수금총액
      const deductSumAmt = Number(this.deductSumAmt); // 공제금액
      const directSumAmt = Number(this.directSumAmt); // 직불금액
      const rsumAmt = Number(this.pxcondInfo.rsumAmt); // 기성금액
      return rsumAmt < repSumAmt + deductSumAmt + directSumAmt;
    },
    onInit() {
      this.initPxcondInfo();

      this.orderNm = '';
      this.ctrtSumAmt = '';
      this.reqSumAmt = '';
      this.repSumAmt = '';

      this.deductSumAmt = '';
      this.directSumAmt = '';
      this.totalAmt = '';

      this.loadSearchData2 = [];
      this.loadSearchData3 = [];

      this.callEvent({name: 'PxcondCollectList_init'});
    },
    onSearch(focusKey = this.pxcondInfo.reqCnt) {
      this.focusKey = focusKey;
      selectPxcondList({pgmCd: this.pgmCd, prjCd: this.prjCd}).then(response => (this.loadSearchData1 = response.data));
    },
    onSearchDetail(row) {
      const {orderNm, ctrtSumAmt} = this.getPrjInfo();
      this.orderNm = orderNm;
      this.ctrtSumAmt = $_numberFormat(ctrtSumAmt);
      this.reqSumAmt = $_numberFormat(this.sheet1.getDataRows().reduce((ramt, row) => ramt + row.rsumAmt, 0));
      this.repSumAmt = row.repSumAmt;

      this.setPxcondInfo(row);
      this.onSearchDeductList();
      this.onSearchDirectList();
      this.onSearchPxcondCollectList();
    },
    onSearchDeductList() {
      selectDeductList(this.pxcondInfo).then(response => (this.loadSearchData2 = response.data));
    },
    onSearchDirectList() {
      selectDirectList(this.pxcondInfo).then(response => (this.loadSearchData3 = response.data));
    },
    onSearchPxcondCollectList() {
      this.callEvent({name: 'PxcondCollectList_search'});
    },

    onCalcAmt(type) {
      // 공제금액, 직불금액
      const sheetObj = this[`sheet${type}`];
      const target = type == 2 ? 'deduct' : 'direct';
      this[`${target}SumAmt`] = sheetObj.getDataRows().reduce((amt, row) => amt + row[`${target}Amt`], 0);

      // 수금누계금액 = 수금총액 + 공제금액 + 직불금액
      const repSumAmt = Number(this.repSumAmt);
      this.totalAmt = $_numberFormat(repSumAmt + Number(this.deductSumAmt) + Number(this.directSumAmt));
    },
    onAddRow(type) {
      const sheetObj = this[`sheet${type}`];
      const addRow = sheetObj.addRow();
      sheetObj.setValue(addRow, 'pgmCd', this.pxcondInfo.pgmCd);
      sheetObj.setValue(addRow, 'prjCd', this.pxcondInfo.prjCd);
      sheetObj.setValue(addRow, 'reqCnt', this.pxcondInfo.reqCnt);
    },
    onSaveRow(type) {
      const sheetObj = this[`sheet${type}`];
      const saveJsonList = sheetObj.getSaveJson(0).data;

      if (0 == saveJsonList.length) {
        this.$alert({title: '저장', message: '저장할 항목이 존재하지 않습니다.'});
        return;
      }
      if (!sheetObj.hasChangedData()) {
        this.$alert({title: '저장', message: '수정된 내용이 없습니다.'});
        return;
      }

      let saveList = new Array();
      if (2 == type) {
        if (saveJsonList.some(row => !Number(row.deductAmt))) {
          this.$alert({title: '공제정보', message: '공제정보 입력시 금액은 필수입니다.'});
          return false;
        }

        saveList = saveJsonList.map(row => ({
          pgmCd: row.pgmCd,
          prjCd: row.prjCd,
          reqCnt: row.reqCnt,
          deductAmt: row.deductAmt,
          deductRmk: row.deductRmk,
        }));
      }
      if (3 == type) {
        if (saveJsonList.some(row => !row.bsnNo || !Number(row.directAmt))) {
          this.$alert({title: '직불정보', message: '직불정보 입력시 업체정보와 금액은 필수입니다.'});
          return false;
        }

        saveList = saveJsonList.map(row => ({
          pgmCd: row.pgmCd,
          prjCd: row.prjCd,
          reqCnt: row.reqCnt,
          bsnNo: row.bsnNo,
          directAmt: row.directAmt,
          directRmk: row.directRmk,
        }));
      }

      const saveApi = type === 2 ? saveDeductList : saveDirectList;
      saveApi({
        pgmCd: this.pxcondInfo.pgmCd,
        prjCd: this.pxcondInfo.prjCd,
        reqCnt: this.pxcondInfo.reqCnt,
        saveList,
      })
        .then(response => {
          if (0 < response.data) {
            this.onSearch();
          }
        })
        .catch(ex => {
          this.$alert({title: '저장', message: `저장을 실패했습니다.<br/>${ex}`});
        });
    },
    async onDeleteRow(type) {
      const sheetObj = this[`sheet${type}`];
      const checkedRows = sheetObj.getRowsByChecked('cbx');
      const title = `${type == 2 ? '공제정보' : '직불정보'}`;

      if (0 === checkedRows.length) {
        this.$alert({title, message: '삭제할 항목이 존재하지 않습니다.'});
        return;
      }

      if (!(await this.$confirm({title, message: '삭제하시겠습니까?'}))) {
        return;
      }

      const deleteList = checkedRows.map(row => {
        return {
          pgmCd: row.pgmCd,
          prjCd: row.prjCd,
          reqCnt: row.reqCnt,
          seq: row.seq,
        };
      });

      const deleteApi = type == 2 ? deleteDeductList : deleteDirectList;
      deleteApi({
        pgmCd: this.pxcondInfo.pgmCd,
        prjCd: this.pxcondInfo.prjCd,
        reqCnt: this.pxcondInfo.reqCnt,
        deleteList,
      })
        .then(response => {
          if (0 < response.data) {
            this.onSearch();
          }
        })
        .catch(ex => {
          this.$alert({title: '저장', message: `저장을 실패했습니다.<br/>${ex}`});
        });
    },
    onShowModal() {
      this.$store.commit('setModalParam', {pgmCd: this.pxcondInfo.pgmCd, prjCd: this.pxcondInfo.prjCd});
      this.$modal.show('PoCompleteListModal');
    },
    onConfirm() {
      this.callEvent({
        name: 'SubCustListPopup_callbackData',
        param: data => {
          const focusedRow = this.sheet3.getFocusedRow();
          this.sheet3.setValue(focusedRow, 'bsnNo', data.cusCd, 1);
          this.sheet3.setValue(focusedRow, 'frmNm', data.cusNm, 1);

          const duplicateRows = this.sheet3.getRowsByDup('bsnNo', false, false, false);
          if (duplicateRows.length > 0) {
            this.sheet3.setValue(focusedRow, 'bsnNo', '', 1);
            this.sheet3.setValue(focusedRow, 'frmNm', '', 1);
            this.$alert({title: '직불정보', message: '이미 존재하는 업체입니다.'});
            return;
          }
          this.$modal.hide('PoCompleteListModal');
        },
      });
    },
    sheet1_onSearchFinish(e) {
      if (0 === e.sheet.getTotalRowCount()) {
        this.onInit();
      } else {
        const focusRow = e.sheet.getDataRows().find(row => row.reqCnt == this.focusKey);
        this.focusKey = undefined;

        if (focusRow !== undefined) {
          e.sheet.focus(focusRow);
          this.onSearchDetail(focusRow);
        } else {
          this.onSearchDetail(e.sheet.getFirstRow());
        }
      }
    },
    sheet1_onClick(e) {
      if (e.row.Kind === 'Data') this.onSearchDetail(e.row);
    },
    sheet2_onSearchFinish(e) {
      e.sheet.getDataRows().forEach(row => e.sheet.setValue(row, 'orgDeductAmt', row.deductAmt));
      this.onCalcAmt(2);
    },
    sheet2_onAfterChange(e) {
      if (e.col == 'deductAmt') {
        this.onCalcAmt(2);

        if (this.onCheckAmt()) {
          this.$alert('수금금액이 기성금액보다 많을 수 없습니다.', () => {
            e.sheet.setValue(e.row, e.col, e.row.orgDeductAmt);
            this.onCalcAmt(2);
          });
          return;
        }
      }
    },
    sheet3_onSearchFinish(e) {
      e.sheet.getDataRows().forEach(row => e.sheet.setValue(row, 'orgDirectAmt', row.directAmt));
      this.onCalcAmt(3);
    },
    sheet3_onAfterChange(e) {
      if (e.col == 'directAmt') {
        this.onCalcAmt(3);

        if (this.onCheckAmt()) {
          this.$alert('수금금액이 기성금액보다 많을 수 없습니다.', () => {
            e.sheet.setValue(e.row, e.col, e.row.orgDirectAmt);
            this.onCalcAmt(3);
          });
          return;
        }
      }
    },
  },
};
</script>

<style></style>
