<template>
  <pmis-tab-box>
    <template #title>상세정보</template>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <form onsubmit="return false;">
            <iui-container-new type="table" theme="bullet">
              <i-row>
                <i-col>
                  <pmis-content-box>
                    <template #title>공사정보</template>
                    <template #title-left></template>
                    <iui-container-new type="table" theme="bullet" header-width="120px" style="table-layout: auto;">
                      <i-row>
                        <i-col-header required>현장코드</i-col-header>
                        <i-col>
                          <iui-text
                            type="text"
                            name="prjEstCd"
                            :value="detailInfo.prjEstCd"
                            :bindArray="bindArray"
                            :enable="false"
                            width="90px"
                          />
                          <div class="m10"></div>
                          <iui-text
                            type="text"
                            name="prjEstNm"
                            :value="detailInfo.prjEstNm"
                            :bindArray="bindArray"
                            required
                            :errorMessage="{title: '현장명', message: '현장명을 입력해주세요.'}"
                            max-length="100"
                            width="400px"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>공사지역(시도)</i-col-header>
                        <i-col>
                          <div class="iui-searchbox">
                            <iui-text width="90px" :value="detailInfo.siteArea1" :enable="false" />
                            <iui-button
                              class="flex-0"
                              btn-class="search-button"
                              @click="onPop('siteArea1')"
                            ></iui-button>
                            <iui-text width="180px" :value="detailInfo.siteArea1Nm" :enable="false" />
                          </div>
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>공사지역(시군구)</i-col-header>
                        <i-col>
                          <div class="iui-searchbox">
                            <iui-text width="90px" :value="detailInfo.siteArea2" :enable="false" />
                            <iui-button
                              class="flex-0"
                              btn-class="search-button"
                              @click="onPop('siteArea2')"
                            ></iui-button>
                            <iui-text width="180px" :value="detailInfo.siteArea2Nm" :enable="false" />
                          </div>
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>발주처구분</i-col-header>
                        <i-col>
                          <iui-radio-group
                            name="orderGroup"
                            :items="orderGroupItems"
                            :value="detailInfo.orderGroup"
                            :bindArray="bindArray"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>도급종류</i-col-header>
                        <i-col>
                          <iui-radio-group
                            name="workKind"
                            :bindArray="bindArray"
                            :value="detailInfo.workKind"
                            :items="workKindItems"
                          />
                        </i-col>
                      </i-row>
                      <i-row v-if="detailInfo.workKind === $getConstants('WORK_KIND_SCPAY').code">
                        <!-- 도급종류 : 하도급 -->
                        <i-col-header>원도급사</i-col-header>
                        <i-col>
                          <iui-searchbox
                            type="customer"
                            name="contcompCode"
                            nameEleName="contcompNm"
                            :idValue="detailInfo.contcompCode"
                            :nameValue="detailInfo.contcompNm"
                            :idBindArray="bindArray"
                            :nameBindArray="bindArray"
                            :paramObj="{frmDs: $getConstants('FRM_DS_CONT_COMP').code}"
                            width="515px"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>발주처</i-col-header>
                        <i-col>
                          <iui-searchbox
                            type="customer"
                            name="orderCode"
                            nameEleName="orderNm"
                            :idValue="detailInfo.orderCode"
                            :nameValue="detailInfo.orderNm"
                            :idBindArray="bindArray"
                            :nameBindArray="bindArray"
                            :paramObj="{frmDs: $getConstants('FRM_DS_ORDER').code}"
                            width="515px"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>공기</i-col-header>
                        <i-col>
                          <iui-text
                            type="number"
                            name="workDay"
                            :value="detailInfo.workDay"
                            :bindArray="bindArray"
                            suffix="일"
                            max-length="4"
                            width="110px"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>공사개요</i-col-header>
                        <i-col>
                          <iui-text
                            type="multi"
                            name="rmk"
                            :value="detailInfo.rmk"
                            max-length="2000"
                            :bindArray="bindArray"
                            width="800px"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>공동도급</i-col-header>
                        <i-col>
                          <iui-radio-group
                            name="coYn"
                            :value="detailInfo.coYn"
                            :bindArray="bindArray"
                            :p-code="$getConstants('CO_YN').code"
                          />
                        </i-col>
                      </i-row>
                      <i-row v-if="detailInfo.coYn != $getConstants('CO_YN_Y').code">
                        <i-col-header>공동도급사</i-col-header>
                        <i-col>
                          <iui-text
                            type="text"
                            name="coCompany"
                            :value="detailInfo.coCompany"
                            :bindArray="bindArray"
                            width="800px"
                          />
                        </i-col>
                      </i-row>
                    </iui-container-new>
                  </pmis-content-box>
                </i-col>
              </i-row>
              <i-row>
                <i-col>
                  <pmis-content-box>
                    <template #title>입찰정보</template>
                    <template #title-left></template>
                    <iui-container-new type="table" theme="bullet" header-width="120px" style="table-layout: auto;">
                      <i-row>
                        <i-col-header>현설일시</i-col-header>
                        <i-col>
                          <iui-datepicker name="prDate" :value="String(detailInfo.prDate)" :bindArray="bindArray" />
                          <iui-timepicker
                            width="30px"
                            @change="onChangeTime('prTime', $event)"
                            :value="getTime(detailInfo.prTimeHh, detailInfo.prTimeMm)"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>등록(마감)일자</i-col-header>
                        <i-col>
                          <iui-datepicker name="duDate" :value="String(detailInfo.duDate)" :bindArray="bindArray" />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>입찰일시</i-col-header>
                        <i-col>
                          <iui-datepicker name="biDate" :value="String(detailInfo.biDate)" :bindArray="bindArray" />
                          <iui-timepicker
                            width="30px"
                            @change="onChangeTime('biTime', $event)"
                            :value="getTime(detailInfo.biTimeHh, detailInfo.biTimeMm)"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>추정가격</i-col-header>
                        <i-col>
                          <iui-text
                            type="amount"
                            name="costAmt"
                            :value="detailInfo.costAmt"
                            :bindArray="bindArray"
                            width="120px"
                            @change="setVatAmt"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>부가세</i-col-header>
                        <i-col>
                          <iui-text
                            type="amount"
                            name="vatAmt"
                            :value="detailInfo.vatAmt"
                            :bindArray="bindArray"
                            width="120px"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>설계금액</i-col-header>
                        <i-col>
                          <iui-text
                            type="amount"
                            :value="
                              detailInfo.costAmt || detailInfo.vatAmt
                                ? Number(detailInfo.costAmt || 0) + Number(detailInfo.vatAmt || 0)
                                : ''
                            "
                            :bindArray="bindArray"
                            width="120px"
                            :enable="false"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>입찰결과</i-col-header>
                        <i-col>
                          <iui-radio-group
                            name="bidStat"
                            :p-code="$getConstants('BID_STAT').code"
                            :value="detailInfo.bidStat"
                            :bindArray="bindArray"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>계약일자</i-col-header>
                        <i-col>
                          <iui-text
                            type="text"
                            name="ctrtDate"
                            :value="detailInfo.ctrtDate"
                            :bindArray="bindArray"
                            width="90px"
                            :enable="false"
                          />
                        </i-col>
                      </i-row>
                    </iui-container-new>
                  </pmis-content-box>
                </i-col>
              </i-row>
            </iui-container-new>
          </form>
        </i-col>
      </i-row>
    </iui-container-new>
    <iui-modal name="commonCode" sizeType="size1" :btns="modalBtns" @beforeClose="beforeClose">
      <pmis-content-box>
        <template #header-left>
          <iui-text width="120px" type="search" :value.sync="commonSearchNm" @enter="onCommonSearch" />
          <iui-button value="검색" @click="onCommonSearch" />
        </template>

        <CommonCode
          :paramObj="{code: commonCode}"
          :CanEdit="false"
          @dblClick="onCommonCodeConfirm"
          :alertMessage="alertMessage"
          :filter="commonCodeFilter"
        />
      </pmis-content-box>
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import CommonCode from '@/view/sysManage/stdCodeManage/cmmnCodeRegist/components/CommonCodePop.vue';
import {savePrjEstM} from '@/view/estmtManage/estmtPrjManage/api/estmtPrjRegist.js';
import IuiTimepicker from '@/components/common/IuiTimepicker.vue';

export default {
  components: {IuiTimepicker, CommonCode},
  data() {
    return {
      bindArray: ['setDetailInfo'],

      code0265: '',
      orderGroupItems: [],
      workKindItems: [],
      modalBtns: [{name: '확인', callback: this.onCommonCodeConfirm}],
      modalNm: '',
      commonCode: '',
      commonSearchNm: '',
      alertMessage: {},

      commonCodeFilter: undefined,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'detailInfo']);
    $mapMutations(this, ['setDetailInfo', 'initDetailInfo']);
    $getCode($getConstants('CONFIRM_FLAG').code).then(response => (this.code0265 = response[0].code));
  },
  created() {
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);

    $getCode($getConstants('ORDER_GROUP').code).then(response => {
      response.reverse().forEach(code => {
        this.orderGroupItems.push({label: code.codeNm.replace(/기관/g, ''), value: code.code});
      });
    });
    $getCode($getConstants('WORK_KIND').code).then(response => {
      response.reverse().forEach(code => {
        this.workKindItems.push({label: code.codeNm, value: code.code});
      });
    });
  },
  methods: {
    timeValidation() {
      let timeHh;
      let timeMm;
      let result = false;

      for (let key in this.detailInfo) {
        if (key.endsWith('TimeHh')) {
          timeHh = Number(this.detailInfo[key]);
        }
        if (key.endsWith('TimeMm')) {
          timeMm = Number(this.detailInfo[key]);
        }

        if (timeHh < 0 || timeHh > 23 || timeMm < 0 || timeMm > 59) {
          this.$alert({title: '시간', message: '시간 입력이 잘못되었습니다. <br/>다시 한 번 확인하시기 바랍니다.'});
          result = true;
          break;
        }
      }
      return !result;
    },
    onChangeTime(key, value) {
      const hour = value.split(':')[0];
      const minute = value.split(':')[1];
      this.setDetailInfo({[key + 'Hh']: hour});
      this.setDetailInfo({[key + 'Mm']: minute});
    },
    onAdd() {
      this.initDetailInfo();
    },
    async onSave() {
      if (!this.timeValidation()) return;

      const response = await savePrjEstM(this.detailInfo);
      if (response.data) {
        this.callEvent({name: 'PrjEstCdList_onSearch', param: response.data});
      }
    },
    async onDelete() {
      if (this.detailInfo.ctrtDate) {
        this.$alert({title: '삭제', message: '계약된 견적은 삭제할 수 없습니다.'});
        return;
      }

      if (!(await this.$confirm({title: '삭제', message: '삭제하시겠습니까?'}))) {
        return;
      }

      savePrjEstM({prjEstCd: this.detailInfo.prjEstCd})
        .then(response => {
          if (0 < response.data) {
            this.initDetailInfo();
            this.callEvent({name: 'PrjEstCdList_onSearch'});
          }
        })
        .catch(ex => console.error('ERR!!!!! : ', ex));
    },
    setVatAmt(e) {
      if (e.target.value) {
        this.setDetailInfo({vatAmt: Math.floor(e.target.value / 10)});
      }
    },
    onPop(modalNm) {
      this.modalNm = modalNm;
      if (this.modalNm == 'siteArea1') {
        this.commonCode = $getConstants('SITE_AREA1_CD').code;
        this.commonCodeFilter = undefined;
        this.$modal.show('commonCode', {title: '공사지역(시도)'});
        this.alertMessage = {title: '공사지역(시도)', message: '공사지역(시도)이 선택되지 않았습니다.'};
      } else if (this.modalNm == 'siteArea2') {
        this.commonCode = $getConstants('SITE_AREA2_CD').code;
        this.commonCodeFilter = code => code.code.substring(0, 2) == this.detailInfo.siteArea1;
        this.$modal.show('commonCode', {title: '공사지역(시군구)'});
        this.alertMessage = {title: '공사지역(시군구)', message: '공사지역(시군구)이 선택되지 않았습니다.'};
      }
    },
    onCommonCodeConfirm() {
      if (this.modalNm == 'siteArea1') {
        this.callEvent({
          name: 'CommonCode_setData',
          param: code => {
            this.setDetailInfo({siteArea1: code.code, siteArea1Nm: code.codeNm});
          },
        });
        this.detailInfo.siteArea2 = '';
        this.detailInfo.siteArea2Nm = '';
      } else if (this.modalNm == 'siteArea2') {
        this.callEvent({
          name: 'CommonCode_setData',
          param: code => {
            this.setDetailInfo({siteArea2: code.code, siteArea2Nm: code.codeNm});
          },
        });
      }

      this.$modal.hide('commonCode');
    },
    onCommonSearch() {
      this.callEvent({name: 'CommonCode_search', param: {searchNm: this.commonSearchNm}});
    },
    beforeClose() {
      this.commonSearchNm = '';
    },
    getTime(hour, minute) {
      hour = hour ? hour : '00';
      minute = minute ? minute : '00';
      return `${hour}:${minute}`;
    },
  },
};
</script>
