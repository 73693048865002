<template>
  <pmis-tab-box>
    <template #title>납부목록</template>
    <template #title-right>
      <iui-button value="엑셀출력" @click="onDownloadExcel" />
    </template>
    <template #header-left>
      <label>검색구분</label>
      <iui-select defaultCd="S" :items="searchItems" :value.sync="searchInfo.searchSelect" @change="onSearch" />
      <iui-text type="search" :value.sync="searchInfo.searchText" @enter="onSearch" />

      <label>계약일</label>
      <iui-datepicker :value.sync="searchInfo.searchFrDt" @change="onSearch" :group="searchDateGroup.seq1" />
      <span>~</span>
      <iui-datepicker :value.sync="searchInfo.searchToDt" @change="onSearch" :group="searchDateGroup.seq2" />

      <iui-button value="검색" @click="onSearch" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="loadSearchData"
      @loadSheet="sheet = $event"
      :events="{onRenderFirstFinish: () => onSearch()}"
    />
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/StampPaymentListSheet.js';
import {selectStampTaxRegistList as matApi} from '@/view/bidding/po/mat/api/po.js';
import {selectStampTaxRegistList as subApi} from '@/view/bidding/po/sub/api/subPo.js';
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],
      searchItems: [
        {text: '현장명', value: 'SPOT_DEPTNM'},
        {text: '업체명', value: 'FRM_NM'},
        {text: '계약명', value: 'TTL'},
      ],
      searchInfo: {
        searchSelect: '',
        searchText: '',
        searchFrDt: '',
        searchToDt: '',
      },
      searchDateGroup: {
        seq1: {grpNm: 'searchDate', seq: 1},
        seq2: {grpNm: 'searchDate', seq: 2},
      },
    };
  },
  methods: {
    async onSearch() {
      const selectStampTaxRegistListApi = {mat: matApi, sub: subApi}[this.type];
      const param = {orgCd: this.pgmCd, ...this.searchInfo};

      const response = await selectStampTaxRegistListApi(param);
      this.loadSearchData = response.data;
    },

    onDownloadExcel() {
      const printMan = this.userInfo.name;
      const printDate = dateFormat($_getCurrentDate());

      const defaultOption = {
        fileName: '인지세_납부관리대장.xlsx',
        sheetName: '인지세_납부관리대장',
        titleText: `인지세납부관리대장||||||||Printed by: ${printMan}\nDate: ${printDate}`,
        userMerge: '0,0,1,8 0,8,1,2',
      };
      const customOption = {sheet_TitleRowHeight: '1000'};

      this.sheet.util.down2Excel(defaultOption, customOption);
    },
  },
};
</script>

<style></style>
