<template>
  <pmis-tab-box>
    <template #title>투입목록</template>
    <pmis-content-box>
      <template #header-left>
        <label>가동일자</label>
        <iui-datepicker :value.sync="searchFrDt" @change="onSearch" />
        <label>~</label>
        <iui-datepicker :value.sync="searchToDt" @change="onSearch" />
        <label class="ml20">장비명</label>
        <iui-text :value.sync="searchText" @enter="onSearch" />
        <iui-button value="검색" @click="onSearch" />
      </template>
      <ib-sheet
        :uid="_uid"
        :options="sheetOpt"
        :loadSearchData="loadSearchData"
        :events="{
          onRenderFirstFinish: sheet_onRenderFirstFinish,
          onSearchFinish: sheet_onSearchFinish,
        }"
        @loadSheet="loadSheet"
      />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/equipInputList.js';
import {selectOperationStateList} from '../api/operationState.js';
export default {
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],
      searchFrDt: $_getCalDate($_getCurrentDate(), -30),
      searchToDt: $_getCurrentDate(),
      searchText: '',
    };
  },
  created() {
    this.addFuncPrj(this.onChangePrj);
  },
  methods: {
    onChangePrj() {
      this.searchFrDt = $_getCalDate($_getCurrentDate(), -30);
      this.searchToDt = $_getCurrentDate();
      this.onSearch();
    },
    async onSearch() {
      const searchOption = {
        pgmCd: this.pgmCd,
        prjCd: await this.getPrjCd(),
        searchFrDt: this.searchFrDt,
        searchToDt: this.searchToDt,
        searchText: this.searchText,
      };

      selectOperationStateList(searchOption).then(response => (this.loadSearchData = response.data));
    },
    sheetMerge() {
      let rows = this.sheet.getDataRows();
      let key = null;
      let mergeInfo = {};
      let sRow;
      let eRow;
      rows.forEach(r => {
        if (key != r.subCode + r.subCodeName) {
          key = r.subCode + r.subCodeName;
          sRow = r.id;
          mergeInfo[sRow] = sRow;
        } else {
          eRow = r.id;
          mergeInfo[sRow] = eRow;
        }
      });

      for (let m in mergeInfo) {
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'subCodeName',
          this.sheet.getRowById(mergeInfo[m]),
          'subCodeName'
        );
      }
    },
    sheet_onRenderFirstFinish(e) {
      e.sheet.setAutoMerge({headerMerge: 6});
    },
    sheet_onSearchFinish(e) {
      if (!e.sheet.getTotalRowCount) return;
      // 소계
      e.sheet.makeSubTotal([
        {
          stdCol: 'subCode',
          sumCols: 'runAmt|oilAmt',
          captionCol: [{col: 'subCodeName', val: '소계'}],
        },
      ]);
      this.sheetMerge();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style></style>
