export default that => {
  return {
    Cfg: {
      CanEdit: false,
      FitWidth: true,
      HeaderMerge: 6,
    },
    LeftCols: [
      {Name: 'midCode', Header: ['도급내역', '공종명'], Width: 50, Type: 'Text'},
      {Name: 'midName', Header: ['도급내역', '공종명'], Width: 150, Type: 'Text'},
      {Name: 'itemSeq', Header: ['도급내역', '내역코드'], Width: 70, Type: 'Text'},
      {Name: 'itemName', Header: ['도급내역', '내역명'], Width: 150, Type: 'Text'},
      {Name: 'ssize', Header: ['도급내역', '규격'], Width: 100, Type: 'Text'},
      {
        Name: 'unit',
        Header: ['도급내역', '단위'],
        Width: 90,
        Type: 'Text',
        Format: $getConstants('UNIT').code,
      },
      {
        Name: 'contQty',
        Header: ['도급내역', '수량 '],
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 90,
        FormulaRow: '합계',
      },
      {
        Name: 'contAmt',
        Header: ['도급내역', '금액 '],
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 90,
        FormulaRow: 'Sum',
      },
    ],
    Cols: [
      {
        Name: 'dayQty',
        Header: ['금일기성실적', '수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 90,
      },
      {
        Name: 'dayAmt',
        Header: ['금일기성실적', '금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 90,
        FormulaRow: 'Sum',
      },
      {
        Name: 'dayRate',
        Header: ['금일기성실적', '기성율'],
        Extend: that.$ibsheetHelper.preset.rate,
        Width: 90,
        FormulaRow: fr => {
          return fr.FormulaRow.dayAmt ? (fr.FormulaRow.dayAmt / fr.FormulaRow.contAmt) * 90 : 0;
        },
      },

      {
        Name: 'monthQty',
        Header: ['금월기성실적', '수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 90,
      },
      {
        Name: 'monthAmt',
        Header: ['금월기성실적', '금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 90,
        FormulaRow: 'Sum',
      },
      {
        Name: 'monthRate',
        Header: ['금월기성실적', '기성율'],
        Extend: that.$ibsheetHelper.preset.rate,
        Width: 90,
        FormulaRow: fr => {
          return fr.FormulaRow.monthAmt ? (fr.FormulaRow.monthAmt / fr.FormulaRow.contAmt) * 90 : 0;
        },
      },

      {
        Name: 'totQty',
        Header: ['누계기성실적', '수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 80,
      },
      {
        Name: 'totAmt',
        Header: ['누계기성실적', '금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        Width: 90,
        FormulaRow: 'Sum',
      },
      {
        Name: 'totRate',
        Header: ['누계기성실적', '기성율'],
        Extend: that.$ibsheetHelper.preset.rate,
        Width: 80,
        FormulaRow: fr => {
          return fr.FormulaRow.totAmt ? (fr.FormulaRow.totAmt / fr.FormulaRow.contAmt) * 90 : 0;
        },
      },
    ],
  };
};
