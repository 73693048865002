export default that => {
  let preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      CanEdit: 1,
    },
    Def: {
      Row: {
        CanFormula: 1,
        CalcOrder: 'closeYnCanEdit',
      },
    },
    Cols: [
      {
        Header: '입고일자',
        Name: 'ioDate',
        Type: 'Text',
        Width: 90,
        Align: 'Center',
        CustomFormat: '####-##-##',
        CanEdit: 0,
      },
      {Header: '순번', Name: 'seq', Type: 'Text', Width: 60, Align: 'Center', CanEdit: 0},
      {Header: '자재코드', Name: 'matCode', Type: 'Text', Width: 130, CanEdit: 0},
      {Header: '명칭', Name: 'itemName', Type: 'Text', RelWidth: 1, MinWidth: 150, CanEdit: 0},
      {Header: '규격', Name: 'size', Type: 'Text', RelWidth: 1, MinWidth: 150, CanEdit: 0},
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Width: 100,
        Align: 'Center',
        Format: $getConstants('UNIT').code,
        CanEdit: 0,
      },
      {Header: '수량', Name: 'qty', Extend: preset.quantity, Width: 100, CanEdit: 0},
      {Header: '단가', Name: 'price', Extend: preset.unitAmount, Width: 100, CanEdit: 0},
      {Header: '금액', Name: 'amt', Extend: preset.amount, Width: 100, CanEdit: 0},
      {
        Header: {Value: '정산', TextColor: '#444', HeaderCheck: 1},
        Name: 'closeYn',
        Type: 'Bool',
        Width: 60,
        CustomFormat: v => {
          const obj = {};
          obj[$getConstants('Y').code] = 1;
          return obj[v];
        },
        CanEditFormula: () => that.cud === 1,
      },
    ],
  };
};
