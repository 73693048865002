export const BUTTON_TYPE = {
  XSMALL: {className: 'x-small', colorClassName: 'gray'},
  SMALL: {className: 'small', colorClassName: 'gray'},
  NORMAL: {className: 'normal', colorClassName: 'blue'},
  LARGE: {className: 'large', colorClassName: 'blue'},
  XLARGE: {className: 'x-large', colorClassName: 'blue'},
};

export const BorderStyle = {
  NONE: 0,
  THIN: 1,
  MEDIUM: 2,
  DASHED: 3,
  DOTTED: 4,
  THICK: 5,
  DOUBLE: 6,
  HAIR: 7,
  MEDIUM_DASHED: 8,
  DASH_DOT: 9,
  MEDIUM_DASH_DOT: 10,
  DASH_DOT_DOT: 11,
  MEDIUM_DASH_DOT_DOT: 12,
  SLANTED_DASH_DOT: 13,
};

export const align = {
  GENERAL: 'GENERAL',
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT',
  FILL: 'FILL',
  JUSTIFY: 'JUSTIFY',
  CENTER_SELECTION: 'CENTER_SELECTION',
  DISTRIBUTED: 'DISTRIBUTED',
};
export const verticalAlign = {
  TOP: 'TOP',
  CENTER: 'CENTER',
  BOTTOM: 'BOTTOM',
  JUSTIFY: 'JUSTIFY',
  DISTRIBUTED: 'DISTRIBUTED',
};
export const underline = {
  NONE: 'NONE',
  SINGLE: 'SINGLE',
  DOUBLE: 'DOUBLE',
  SINGLE_ACCOUNTING: 'SINGLE_ACCOUNTING',
  DOUBLE_ACCOUNTING: 'DOUBLE_ACCOUNTING',
};
export const fontColor = {
  BLACK1: 0,
  WHITE1: 1,
  RED1: 2,
  BRIGHT_GREEN1: 3,
  BLUE1: 4,
  YELLOW1: 5,
  PINK1: 6,
  TURQUOISE1: 7,
  BLACK: 8,
  WHITE: 9,
  RED: 10,
  BRIGHT_GREEN: 11,
  BLUE: 12,
  YELLOW: 13,
  PINK: 14,
  TURQUOISE: 15,
  DARK_RED: 16,
  GREEN: 17,
  DARK_BLUE: 18,
  DARK_YELLOW: 19,
  VIOLET: 20,
  TEAL: 21,
  GREY_25_PERCENT: 22,
  GREY_50_PERCENT: 23,
  CORNFLOWER_BLUE: 24,
  MAROON: 25,
  LEMON_CHIFFON: 26,
  LIGHT_TURQUOISE1: 27,
  ORCHID: 28,
  CORAL: 29,
  ROYAL_BLUE: 30,
  LIGHT_CORNFLOWER_BLUE: 31,
  SKY_BLUE: 40,
  LIGHT_TURQUOISE: 41,
  LIGHT_GREEN: 42,
  LIGHT_YELLOW: 43,
  PALE_BLUE: 44,
  ROSE: 45,
  LAVENDER: 46,
  TAN: 47,
  LIGHT_BLUE: 48,
  AQUA: 49,
  LIME: 50,
  GOLD: 51,
  LIGHT_ORANGE: 52,
  ORANGE: 53,
  BLUE_GREY: 54,
  GREY_40_PERCENT: 55,
  DARK_TEAL: 56,
  SEA_GREEN: 57,
  DARK_GREEN: 58,
  OLIVE_GREEN: 59,
  BROWN: 60,
  PLUM: 61,
  INDIGO: 62,
  GREY_80_PERCENT: 63,
  AUTOMATIC: 64,
};

export const fontStyle = {
  bold: false,
  italic: false,
  underline: underline.NONE,
  size: 12,
  color: fontColor.WHITE1,
};

export const cellStyle = {
  color: '#3378c1',
  borderStyleTop: BorderStyle.NONE,
  borderStyleBottom: BorderStyle.NONE,
  borderStyleLeft: BorderStyle.NONE,
  borderStyleRight: BorderStyle.NONE,
  borderColorTop: '#000000',
  borderColorBottom: '#000000',
  borderColorLeft: '#000000',
  borderColorRight: '#000000',

  align: align.CENTER,
  verticalAlign: verticalAlign.CENTER,
  fontStyle: fontStyle,
};
