import {selectMatMonDetailList, selectMatMonList} from '../api/fixedtimeMaterial.js';

const state = {
  searchInfo: {
    investMm: '',
    custName: '',
  },
  detailInfo: {
    custCode: '',
    poNo: '',
    amt: 0,
    vatAmt: 0,
    taxexe: 0,
    sumAmt: 0,
    billNo: '',
    billTaxNo: '',
    flNo: 0,
    flTaxNo: 0,
  },
  matMonList: [],
  calculateList: [],
  closeYn: '',
};

const getters = {
  searchInfo: state => state.searchInfo,
  detailInfo: state => state.detailInfo,
  matMonList: state => state.matMonList,
  calculateList: state => state.calculateList,
  closeYn: state => state.closeYn,
  fixedTimeMatSaveDataReqDto: state => {
    const keys = ['investMm', 'custCode', 'poNo', 'amt', 'vatAmt', 'taxexe', 'sumAmt'];
    let param = {};
    for (let key in state.searchInfo) {
      if (keys.findIndex(item => item === key) !== -1) {
        param[key] = state.searchInfo[key];
      }
    }
    for (let key in state.detailInfo) {
      if (keys.findIndex(item => item === key) !== -1) {
        param[key] = state.detailInfo[key];
      }
    }
    return param;
  },
};

const mutations = {
  setSearchInfo(state, payload) {
    for (let key in payload) {
      if (state.searchInfo.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  initDetailInfo(state) {
    let NumberKeys = ['amt', 'vatAmt', 'taxexe', 'sumAmt', 'flNo', 'flTaxNo'];
    for (let key in state.detailInfo) {
      state.detailInfo[key] = NumberKeys.indexOf(key) !== -1 ? 0 : '';
    }
  },

  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = payload[key];
      }
    }
  },
  setMatMonList(state, payload) {
    state.matMonList = payload;
  },
  initCalculateList(state) {
    state.calculateList = [];
  },
  setCalculateList(state, payload) {
    state.calculateList = payload;
  },
  setSumAmt(state) {
    state.detailInfo.sumAmt = $_getSumAmt(state.detailInfo.amt, state.detailInfo.vatAmt);
  },
  setCloseYn(state, closeYn) {
    state.closeYn = closeYn;
  },
};

const actions = {
  async selectMatMonDetailList({state, commit}) {
    const param = {
      investMm: state.searchInfo.investMm,
      custCode: state.detailInfo.custCode,
      poNo: state.detailInfo.poNo,
    };
    const response = await selectMatMonDetailList(param);
    commit('setCalculateList', response.data);
  },

  async selectMatMonList({state, commit}) {
    commit('initDetailInfo');
    commit('initCalculateList');
    const response = await selectMatMonList(state.searchInfo);
    const matMonList = response.data;
    commit('setMatMonList', matMonList);
  },

  setCalculateVatAmt({commit}, vatAmt) {
    vatAmt = Number(vatAmt || 0);
    commit('setDetailInfo', {vatAmt});
    commit('setSumAmt');
  },
  setCalculateTaxexe({state, commit}, taxexe) {
    taxexe = Number(taxexe || 0);
    if (state.detailInfo.amt < taxexe) {
      taxexe = state.detailInfo.amt;
    }
    const vatAmt = $_getVatAmt(state.detailInfo.amt, taxexe);
    commit('setDetailInfo', {vatAmt, taxexe});
    commit('setSumAmt');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
