<template>
  <JoinStepMain />
</template>

<script>
import JoinStepMain from '@/view/member/components/step/JoinStepMain.vue';
export default {
  components: {JoinStepMain},
};
</script>

<style></style>
