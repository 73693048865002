<template>
  <pmis-tab-box>
    <template #title>계약목록</template>
    <template #header-left>
      <i-header-multi-box>
        <div>
          <label>검색구분</label>
          <iui-select :items="searchTextTypeItems" :value.sync="searchOptions.searchTextType" />
          <iui-text type="search" :value.sync="searchOptions.searchText" @enter="onSearch" />
          <iui-button value="검색" @click="onSearch" />
        </div>
        <div>
          <label>계약일자</label>
          <iui-datepicker :value.sync="searchOptions.poFrDt" @change="onSearch" :group="{grpNm: 'poDt', seq: 1}" />
          <span>~</span>
          <iui-datepicker :value.sync="searchOptions.poToDt" @change="onSearch" :group="{grpNm: 'poDt', seq: 2}" />
        </div>
      </i-header-multi-box>
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="poList"
      :events="{
        onRenderFirstFinish: () => onSearch(),
        onSearchFinish: sheet_onSearchFinish,
        onClick: sheet_onClick,
      }"
    />
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/poList.js';
export default {
  props: {
    process: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
      searchTextTypeItems: [
        {text: '거래처명', value: 'CustomerNm'},
        {text: '계약명', value: 'ContractNm'},
      ],
      searchOptions: {
        searchTextType: 'CustomerNm',
        searchText: '',
        poFrDt: '',
        poToDt: '',
      },
      focusKey: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, ['poList']);
    $mapActions(this, ['searchPoList', 'searchPoContSeqList']);
  },
  created() {
    this.addFuncSearch(this.onClick_cmnSearch);
    this.addEvent({name: 'PoList_onSearch', func: this.onSearch});
  },
  methods: {
    onClick_cmnSearch() {
      for (const key in this.searchOptions) {
        this.searchOptions[key] = '';
      }
      this.searchOptions['searchTextType'] = this.searchTextTypeItems[0]['value'];
      this.onSearch();
    },
    onSearch(focusKey = '') {
      this.focusKey = typeof focusKey === 'string' ? focusKey : '';
      this.searchPoList({...this.searchOptions, process: this.process});
    },
    setRowData(row) {
      if (row) {
        this.searchPoContSeqList(row.poNo);
      }
    },
    sheet_onSearchFinish(e) {
      if (e.sheet.getTotalRowCount()) {
        const focusRow = this.focusKey
          ? e.sheet.getDataRows().find(row => row.poNo === this.focusKey)
          : e.sheet.getFirstRow();

        e.sheet.focus(focusRow);
        this.setRowData(focusRow);
      }
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data') {
        this.setRowData(e.row);
      }
    },
  },
};
</script>

<style></style>
