<template>
  <pmis-content-box :loading="loading">
    <DtlsSearch
      slot="header-custom"
      :codeGb="$getConstants('CODE_GB1').code"
      @loadChgDegree="loadChgDegree"
      :workTypeCl="workTypeCl"
      @filter="filter"
      @search="onSearch"
      :isPopup="isPopup"
      @maxDegree="setMaxDegree"
      @chgDegree="setChgDegree"
      @changeChgDegree="$emit('changeChgDegree')"
    />
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onClick, onDblClick, onSearchFinish}"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/ContractSheet.js';
import {selectContContPlanList} from '@/view/outsrcDtls/api/outsrcDtls.js';
import DtlsSearch from './DtlsSearch.vue';
export default {
  components: {
    DtlsSearch,
  },
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sheet: undefined,
      Options: this.isPopup ? $addColCheckbox(options(this)) : options(this),
      loadSearchData: [],
      chgDegree: '',
      wcode: '',
      lgCode: '',
      mgCode: '',
      contChgDegreeList: [],
      contSearchMap: {},
      contWSearchList: [],
      contLgSearchList: [],
      contMgSearchList: [],
      workTypeCl: {},
      isLoadSheet: false,
      loading: false,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['dgDtlsLinkList']);
  },
  created() {
    let event = [
      {name: 'getContFocusedRow', func: this.getContFocusedRow},
      {name: 'getContRowsByChecked', func: this.getContRowsByChecked},
      {name: 'contract_getSaveJson', func: this.getSaveJson},
    ];
    this.addEvent(event);
    if (!this.isPopup) {
      this.$watch('dgDtlsLinkList', this.setContLinkedList);
    }
  },

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onClick(e) {
      this.callEvent({name: 'excution_dgLinkCheck', param: e.row});
      this.callEvent({name: 'contexecContactFocus', param: e.row});
    },
    onDblClick() {
      this.$modalConfirm();
    },
    onSearchFinish() {
      this.setContLinkedList();
      this.callEvent({name: 'excution_dgLinkCheck', param: this.sheet.getFocusedRow()});
    },
    loadChgDegree(chgDegree) {
      this.$emit('loadChgDegree', chgDegree);
    },
    filter(obj) {
      this.sheet.clearFilter();
      this.loading = true;
      setTimeout(() => {
        if (obj.itemName) {
          this.sheet.setFilter({
            name: 'itemName',
            filter: "String(itemName).indexOf('" + obj.itemName + "') !== -1 ?1:0",
            render: 1,
          });
        }
        if (obj.mgCode) {
          this.sheet.setFilter({
            name: 'midCode',
            filter: "midCode=='" + obj.mgCode + "'?1:0",
            render: 1,
          });
          this.callEvent({name: 'excution_setMgCode', param: obj.mgCode});
          this.loading = false;
          return;
        }
        if (obj.lgCode) {
          this.sheet.setFilter({
            name: 'headCode',
            filter: "headCode=='" + obj.lgCode + "'?1:0",
            render: 1,
          });
          this.callEvent({name: 'excution_setLgCode', param: obj.lgCode});
          this.loading = false;
          return;
        }
        if (obj.wcode) {
          this.sheet.setFilter({
            name: 'wcode',
            filter: "wcode=='" + obj.wcode + "'?1:0",
            render: 1,
          });

          this.callEvent({name: 'excution_setWcode', param: obj.wcode});
          this.loading = false;
          return;
        }
        this.loading = false;
      }, 100);
    },

    async onSearch(chgDegree) {
      if (!chgDegree) {
        this.sheet.removeAll();
        return;
      }
      try {
        this.loading = true;
        const response = await selectContContPlanList({chgDegree});
        this.sheet.clearFilter();
        const wcodeList = [];
        const lgCodeList = [];
        const mgCodeList = [];
        response.data.forEach(item => {
          //공종
          if (wcodeList.findIndex(obj => obj.value === item.wcode) === -1) {
            wcodeList.push({
              text: item.wname,
              value: item.wcode,
            });
          }
          //대분류
          if (lgCodeList.findIndex(obj => obj.value === item.headCode) === -1) {
            lgCodeList.push({
              text: item.lgName,
              value: item.headCode,
            });
          }
          //중분류
          if (mgCodeList.findIndex(obj => obj.value === item.midCode) === -1) {
            mgCodeList.push({
              text: item.mgName,
              value: item.midCode,
            });
          }
        });
        const obj = {};
        obj['wcodeList'] = wcodeList;
        obj['lgCodeList'] = lgCodeList;
        obj['mgCodeList'] = mgCodeList;

        this.workTypeCl = obj;
        this.loadSearchData = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    getContFocusedRow(callback) {
      callback(this.sheet.getFocusedRow());
    },
    getContRowsByChecked(callback) {
      if (this.sheet.getRowsByChecked('chk').length) {
        callback(this.sheet.getRowsByChecked('chk'));
      } else {
        callback([this.sheet.getFocusedRow()]);
      }
    },
    getSaveJson(callback) {
      callback(this.sheet.getSaveJson(0).data);
    },
    setMaxDegree(isMaxDegree) {
      this.$emit('maxDegree', isMaxDegree);
    },
    setChgDegree(chgDegree) {
      this.$emit('chgDegree', chgDegree);
    },
    setContLinkedList() {
      let rowList = [];
      this.sheet.getDataRows().forEach(row => {
        this.sheet.setAttribute({row: row, attr: 'Color', val: '', render: 1});
        this.sheet.setAttribute({row: row, attr: 'TextColor', val: '', render: 1});
      });

      this.callEvent({name: 'excution_dgLinkCheck', param: this.sheet.getFocusedRow()});

      if (!this.dgDtlsLinkList || !this.dgDtlsLinkList.length) {
        return;
      }

      this.dgDtlsLinkList.forEach(obj => {
        this.sheet.getDataRows().forEach(row => {
          if (obj.midCodeDg == row.midCode && obj.itemSeqDg == row.itemSeq) {
            rowList.push(row);
          }
        });
      });
      rowList.forEach(row => {
        this.sheet.setAttribute({row: row, attr: 'Color', val: '#bdc3c7', render: 1});
        this.sheet.setAttribute({row: row, attr: 'TextColor', val: '#FF0000', render: 1});
      });
    },
  },
};
</script>
