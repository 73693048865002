<template>
  <pmis-page ref="page" :search-project-visible="false" @search-click="onSearch">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>상위코드</template>
            <template v-slot:title-right>
              <iui-button value="신규" @click="onAdd('upper')" />
              <iui-button value="저장" @click="onSave('upper')" />
              <iui-button value="삭제" @click="onDelete('upper')" />
            </template>
            <upperCode @emitUpperCode="getUpperCode" />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>하위코드</template>
            <template v-slot:title-right>
              <iui-button value="신규" @click="onAdd('lower')" />
              <iui-button value="저장" @click="onSave('lower')" />
              <iui-button value="삭제" @click="onDelete('lower')" />
            </template>
            <lowerCode />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import upperCode from './components/UpperCode.vue';
import lowerCode from './components/LowerCode.vue';
import store from './store/CmmnCodeRegist.js';
export default {
  components: {upperCode, lowerCode},
  beforeCreate() {
    $init(this, store);
    $mapMutations(this, ['setUpperDetailInfo']);
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'upperCode_search'});
    },
    onAdd(type) {
      this.callEvent({name: type == 'upper' ? 'upperCode_add' : 'lowerCode_add'});
    },
    onSave(type) {
      this.callEvent({name: type == 'upper' ? 'upperCode_save' : 'lowerCode_save'});
    },
    onDelete(type) {
      this.callEvent({name: type == 'upper' ? 'upperCode_delete' : 'lowerCode_delete'});
    },
    getUpperCode(row) {
      this.setUpperDetailInfo(row);
      this.callEvent({name: 'lowerCode_search'});
    },
  },
};
</script>

<style scoped></style>
