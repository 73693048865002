<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col width="500px">
        <iui-container-new type="table" theme="bullet" header-width="120px">
          <i-row>
            <i-col-header :required="status.isEditableW">공사코드</i-col-header>
            <i-col width="100px">
              <iui-text
                type="code"
                :value="sheetData.wcode"
                @input="
                  setSheetData({
                    code: $event.target.value,
                    wcode: $event.target.value,
                  })
                "
                :enable="false"
                max-length="1"
                width="20px"
              />
            </i-col>
            <i-col>
              <iui-text
                name="wname"
                :value="sheetData.wname"
                @input="
                  setSheetData({
                    name: $event.target.value,
                    wname: $event.target.value,
                  })
                "
                :enable="false"
                max-length="50"
              />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>대공종</i-col-header>
            <i-col>
              <iui-text
                type="code"
                :value="status.isEditableLG || status.isEditableMG ? sheetData.wcode : ''"
                :enable="false"
                max-length="1"
                width="20px"
              />
              <iui-text
                type="code"
                name="lgCode"
                :value="status.isEditableLG || status.isEditableMG ? sheetData.lgCode.slice(1) : ''"
                :enable="false"
                @input="
                  setSheetData({
                    code: String(sheetData.wcode).concat($event.target.value),
                    lgCode: String(sheetData.wcode).concat($event.target.value),
                  })
                "
                max-length="1"
                width="20px"
              />
            </i-col>
            <i-col>
              <iui-text
                name="lgName"
                :value="sheetData.lgName"
                @input="setSheetData({name: $event.target.value, lgName: $event.target.value})"
                :enable="false"
                max-length="50"
              />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>중공종</i-col-header>
            <i-col>
              <iui-text
                type="code"
                name="wcode"
                :value="status.isEditableMG ? sheetData.wcode : ''"
                :enable="false"
                max-length="1"
                width="20px"
              />
              <iui-text
                type="code"
                name="lgCode"
                :value="status.isEditableMG ? sheetData.lgCode.slice(1) : ''"
                :enable="false"
                max-length="1"
                width="20px"
              />
              <iui-text
                type="code"
                name="mgCode"
                :value="sheetData.mgCode.slice(2)"
                @input="
                  setSheetData({
                    code: String(sheetData.lgCode).concat($event.target.value),
                    mgCode: String(sheetData.lgCode).concat($event.target.value),
                  })
                "
                :enable="false"
                max-length="1"
                width="20px"
              />
            </i-col>
            <i-col>
              <iui-text
                name="mgName"
                :value="sheetData.mgName"
                @input="setSheetData({name: $event.target.value, mgName: $event.target.value})"
                :enable="false"
                max-length="50"
              />
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
export default {
  beforeCreate() {
    $mapGetters(this, 'workType', ['sheetData', 'status']);
    $mapMutations(this, 'workType', ['setSheetData']);
  },
};
</script>

<style scoped></style>
