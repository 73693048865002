<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px"><PrjList /></i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>입고목록</template>
            <pmis-content-box>
              <template v-slot:header-left>
                <label>입고기간</label>
                <iui-datepicker
                  name="searchIoDateFrom"
                  :value="searchInfo.searchIoDateFrom"
                  @change="onChange('searchIoDateFrom', $event)"
                  :open.sync="searchIoDateFromOpen"
                />
                <div>~</div>
                <iui-datepicker
                  name="searchIoDateTo"
                  :value="searchInfo.searchIoDateTo"
                  @change="onChange('searchIoDateTo', $event)"
                  :open.sync="searchIoDateToOpen"
                />
                <iui-text
                  label="자재명"
                  type="search"
                  :value="searchInfo.searchNm"
                  @change="onChange('searchNm', $event)"
                  @enter="onSearch"
                />
                <iui-button value="검색" @click="onSearch" />
              </template>
              <ib-sheet
                :uid="_uid"
                :options="sheetOpt"
                :loadSearchData="loadSearchData"
                @loadSheet="loadSheet"
                :events="{onSearchFinish}"
              />
            </pmis-content-box>
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
/**
 * 지급자재입고조회
 *   components :
 *     PrjList : 현장목록
 *
 * */
import PrjList from '@/view/Project/components/PrjList.vue';
import sheetOpt from './components/sheetOption/PymntMtril.js';
import {selectPymntMtrilDetailList} from '@/view/Resource/GMaterial/PymntMtril/api/pymntMtril.js';
export default {
  components: {
    PrjList,
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],

      searchInfo: {
        pgmCd: '',
        prjCd: '',
        searchIoDateFrom: '',
        searchIoDateTo: '',
        searchNm: '',
      },
      searchIoDateFromOpen: false,
      searchIoDateToOpen: false,
    };
  },
  created() {
    this.addFuncPrj(this.onSetPrjCd);
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onSetPrjCd() {
      this.searchInfo.pgmCd = this.pgmCd;
      this.searchInfo.prjCd = this.prjCd;

      if (this.searchInfo.prjCd) {
        this.onInitSearch();
        this.onSearch();
      } else {
        this.sheet.removeAll();
      }
    },
    async onSearch() {
      const param = {...this.searchInfo};
      const response = await selectPymntMtrilDetailList(param);
      this.loadSearchData = response.data;
    },
    onChange(key, event) {
      const value = typeof event == 'string' ? event : event.target.value;
      this.searchInfo[key] = value;
      if (key != 'searchNm') {
        this.onSearch();
      }
    },
    onSearchFinish() {
      this.sheetMerge();
    },
    sheetMerge() {
      let rows = this.sheet.getDataRows();
      let key = null;
      let mergeInfo = {};
      let sRow;
      let eRow;
      rows.forEach(r => {
        if (key != r.ioDate) {
          key = r.ioDate;
          sRow = r.id;
          mergeInfo[sRow] = sRow;
        } else {
          eRow = r.id;
          mergeInfo[sRow] = eRow;
        }
      });

      for (let m in mergeInfo) {
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'ioDate', this.sheet.getRowById(mergeInfo[m]), 'ioDate');
      }
    },
    onInitSearch() {
      this.searchInfo.searchNm = '';

      const currDate = $_getCurrentDate();
      const frDate = new Date(currDate.substr(0, 4), currDate.substr(4, 2) - 1, currDate.substr(6, 2));
      const toDate = new Date(currDate.substr(0, 4), currDate.substr(4, 2) - 1, currDate.substr(6, 2));
      frDate.setMonth(frDate.getMonth() - 1);

      this.searchInfo.searchIoDateFrom = $_dateToStringYYMMDD(frDate);
      this.searchInfo.searchIoDateTo = $_dateToStringYYMMDD(toDate);
    },
  },
};
</script>

<style scoped></style>
