<template>
  <pmis-tab-box>
    <template v-slot:title>페이지포틀릿</template>
    <pmis-content-box>
      <template v-slot:header-left>
        <label>포틀릿그룹</label>
        <iui-select :p-code="$getConstants('PORTLET_GROUP').code" :value.sync="portletGroup" />
        <label>페이지ID</label>
        <iui-select :items="pageIdList" :value.sync="pageId" :enable="false" />
        <iui-text :value.sync="pageName" :readOnly="true" />
      </template>
      <iui-container-new type="table">
        <i-row v-for="pageInfo in pageInfos" :key="pageInfo.index">
          <i-col v-for="info in pageInfo.infos" :key="info.seq" :rowspan="info.rowspan">
            <iui-container-new type="table">
              <i-row>
                <i-col :class="info.className">
                  <div @mouseup="onDropPortlet" class="contentDiv" :value="info.seq" :className="info.className">
                    {{ info.portletName }}
                  </div>
                </i-col>
              </i-row>
              <i-row>
                <i-col align="right">
                  <iui-button @click="onPreview" value="미리보기" />
                  <iui-button @click="onDeletePagePortlet" value="삭제" />
                </i-col>
              </i-row>
            </iui-container-new>
          </i-col>
        </i-row>
      </iui-container-new>
      <iui-modal name="detail" title="포틀릿 미리보기" :width="'1000px'">
        <component v-bind:is="currentView" />
      </iui-modal>
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
import {selectPageIdList, selectPagePortlet, selectWebPageInfo} from '../api/portletManage';

export default {
  data() {
    return {
      pageId: '',
      pageName: '',
      portletGroup: $getConstants('PORTLET_GROUP_OPERATION').code,
      pageInfos: [],
      pageInfo: [],
      pageIdList: [],
      currentView: null,
    };
  },
  watch: {
    portletGroup(val) {
      if (val == $getConstants('PORTLET_GROUP_HEAD_OFFICE').code) {
        this.pageId = '2';
        this.pageIdList[0].enable = false;
      } else if (val == $getConstants('PORTLET_GROUP_CONSTRUCTION_SITE').code) {
        this.pageId = '3';
        this.selectPageInfo();
      } else {
        this.pageId = '1';
        this.selectPageInfo();
      }
    },
    pageId(val) {
      this.pageName = val ? this.pageIdList.find(item => item.value == val)?.name : '';
      if (val && this.portletGroup) {
        this.selectPageInfo();
      }
    },
  },
  beforeCreate() {
    $mapGetters(this, ['portletData', 'dragCheck', 'pageConfig', 'pagePortletParam']);
    $mapMutations(this, ['setDragFalse', 'setPagePortletParam', 'changePagePortletParam', 'clearPagePortletParam']);
  },
  async created() {
    this.addEvent([{name: 'pagePortletRegist_selectPageInfo', func: this.selectPageInfo}]);
    await this.selectPageId();
    this.portletGroup = $getConstants('PORTLET_GROUP_OPERATION').code;
  },
  methods: {
    onDropPortlet(event) {
      if (this.dragCheck) {
        if (this.portletData.portletGroup != this.portletGroup) {
          this.$alert({message: '포틀릿 그룹이 맞지 않습니다.'});
          return;
        }
        const target = event.target;
        target.innerHTML = this.portletData.portletName;
        this.setDragFalse();
        this.changePagePortletParam({
          seq: target.attributes.value.value,
          portletId: this.portletData.portletId,
          mnuCd: this.portletData.mnuCd,
        });
      }
    },
    async selectPageInfo() {
      this.pageInfos = [];
      this.pageInfo = [];
      this.clearPagePortletParam();

      if (!this.portletGroup) {
        this.$alert({message: '포틀릿 그룹을 선택해주세요.'});
        return;
      } else if (!this.pageId) {
        this.$alert({message: '페이지ID를 선택해주세요.'});
      }

      const webPageRes = await selectWebPageInfo({pageId: this.pageId});
      const pagePortletRes = await selectPagePortlet({
        pageId: this.pageId,
        portletGroup: this.portletGroup,
      });

      if (webPageRes.status !== 200 || pagePortletRes.status !== 200) {
        return;
      }

      const array = [];
      pagePortletRes.data.forEach(pagePortlet => {
        const webPageArray = webPageRes.data.filter(x => x.seq === pagePortlet.seq);
        if (webPageArray.length > 0) {
          this.setPagePortletParam(pagePortlet);

          const webPage = webPageArray[0];
          const location = webPageArray[0].webLocation.split(',')[0];

          if (array.length === 0 || array.filter(x => x.index === location).length === 0) {
            array.push({
              index: location,
              infos: [],
            });
          }

          array
            .filter(x => x.index === location)
            .forEach(y => {
              const infoArray = [...y.infos];
              infoArray.push({
                className: this.pageConfig[webPage.size].className,
                portletName: pagePortlet.portletName
                  ? pagePortlet.portletName
                  : `${webPage.webName}[${this.pageConfig[webPage.size].className}]`,
                rowspan: this.pageConfig[webPage.size].rowspan,
                seq: pagePortlet.seq,
                size: webPage.size,
              });
              y.infos = infoArray;
            });
        }
      });

      this.pageInfos = array;
    },
    async selectPageId() {
      const response = await selectPageIdList({});
      if (response.status == 200 && response.data.length != 0) {
        response.data.forEach(item => {
          this.pageIdList.push({
            text: item.pageId,
            value: item.pageId,
            name: item.pageName,
            enable: true,
          });
        });
      }
    },
    onDeletePagePortlet(event) {
      const targetRow = event.target.parentNode.parentNode.parentNode.parentNode.previousSibling;
      const target = targetRow.getElementsByClassName('contentDiv')[0];

      this.changePagePortletParam({
        seq: target.attributes.value.value,
        portletId: null,
      });

      target.innerHTML = target.attributes.className.value;
    },
    async onPreview(event) {
      const targetRow = event.target.parentNode.parentNode.parentNode.parentNode.previousSibling;
      const target = targetRow.getElementsByClassName('contentDiv')[0];
      const seq = target.attributes.value.value;

      const row = this.pagePortletParam.find(element => {
        return element.seq == seq;
      });
      this.currentView = row.mnuCd;

      if (this.currentView == null || this.currentView == '') {
        this.$alert({message: '페이지 경로가 없습니다.'});
        return;
      }
      this.$modal.show('detail');
    },
  },
};
</script>

<style>
.A {
  height: 100px;
}

.B {
  height: 150px;
}

.C {
  height: 200px;
}

.D {
  height: 470px;
}

.E {
  height: 30px;
}

.contentDiv {
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
</style>
