<template>
  <div style="height: 100%">
    <Calendar
      ref="calendar"
      style="height: 100%"
      :view="view"
      :schedules="schedules"
      :month="calendarConfig.month"
      :week="calendarConfig.week"
      :taskView="calendarConfig.taskView"
      :disableDblClick="calendarConfig.disableDblClick"
      :disableClick="calendarConfig.disableClick"
      :isReadOnly="calendarConfig.isReadOnly"
      :useCreationPopup="calendarConfig.useCreationPopup"
      :useDetailPopup="calendarConfig.useDetailPopup"
      :template="calendarConfig.template"
      @clickSchedule="onClickSchedule"
      @beforeUpdateSchedule="onClickDetailButton"
      @afterRenderSchedule="onAfterRenderSchedule"
    />
  </div>
</template>

<script>
// https://ui.toast.com/tui-calendar
import 'tui-calendar/dist/tui-calendar.css';
import Calendar from '@toast-ui/vue-calendar/src/Calendar.vue';

export default {
  components: {
    Calendar,
  },
  props: {
    view: {
      type: String,
      default: 'month',
    },
    date: {
      type: [Date, String],
    },
    schedules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      calendarConfig: {
        schedules: [],
        taskView: false,
        scheduleView: ['time'],
        week: {
          daynames: ['일', '월', '화', '수', '목', '금', '토'],
          hourStart: 5,
          hourEnd: 24,
        },
        month: {
          daynames: ['일', '월', '화', '수', '목', '금', '토'],
        },
        timezones: [
          {
            timezoneOffset: 540,
            displayLabel: 'GMT+09:00',
            tooltip: 'Seoul',
          },
        ],
        disableDblClick: true,
        disableClick: true,
        isReadOnly: false,
        template: {
          popupDetailBody: function(schedule) {
            return schedule.body;
          },
          popupEdit: function() {
            return '상세';
          },
        },
        useCreationPopup: false,
        useDetailPopup: true,
      },
    };
  },
  watch: {
    date(date) {
      if (this.calendar) {
        this.calendar.invoke('setDate', typeof date === String ? dateFormat(date) : date);
      }
    },
  },
  created() {
    this.addEvent([
      {name: 'CalendarWrapper_ChangeConfig', func: this.changeConfig},
      {name: 'CalendarWrapper_MoveCalendarPrevious', func: this.moveCalendarPrevious},
      {name: 'CalendarWrapper_MoveCalendarNext', func: this.moveCalendarCalNext},
    ]);
  },
  mounted() {
    this.calendar = this.$refs.calendar;
    this.emitDate();
  },
  methods: {
    changeConfig(config) {
      for (const key in this.calendarConfig) {
        if (config && config.hasOwnProperty(key)) {
          this.calendarConfig[key] = config[key];
        }
      }
    },
    emitDate() {
      this.$emit('update:date', dateFormat(this.calendar.invoke('getDate')));
    },
    moveCalendarPrevious() {
      this.calendar.invoke('prev');
      this.emitDate();
    },
    moveCalendarCalNext() {
      this.calendar.invoke('next');
      this.emitDate();
    },
    onClickSchedule(e) {
      this.$emit('clickSchedule', e);
    },
    onClickDetailButton(e) {
      // 캘린더 팝업 상세버튼 이벤트
      // https://nhn.github.io/tui.calendar/latest/Calendar#:~:text=is%20removed.%27%2C%20schedule)%3B%0A%7D)%3B-,beforeUpdateSchedule,-calendar.js
      this.$emit('clickDetailButton', e);
    },
    onAfterRenderSchedule(e) {
      /**
       * Fire this event by every single schedule after rendering whole calendar.
       * 캘린더 렌더링 후 모든 싱글 스케줄 렌더링마다 이벤트 발생
       * https://nhn.github.io/tui.calendar/latest/Calendar#:~:text=Fire%20this%20event%20by%20every%20single%20schedule%20after%20rendering%20whole%20calendar.
       */
      this.$emit('afterRenderSchedule', e);
    },
  },
};
</script>

<style></style>
