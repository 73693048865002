export default that => {
  return {
    //각 컬럼에 대한 정의 (컬럼의 이름,유형(Type),포맷(Format)등을 설정)
    //컬럼의 "Type"과 "Name" 속성은 반드시 설정되어야 합니다.
    Cols: [
      {
        Header: '단가구분',
        Name: 'ucstName',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: '단가',
        Name: 'price',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        Width: 120,
        CanEdit: 1,
      },
      {
        Name: 'ucstCode',
        Visible: 0,
      },
    ],
    Cfg: {
      CanEdit: 1,
    },
  };
};
