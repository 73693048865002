<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text ref="searchNm" type="search" :label="'거래구분'" :value.sync="searchNm" @enter="onFocusByNm" />
      <iui-button value="검색" @click="onFocusByNm" />
    </template>
    <iui-container-new type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    uid: {
      type: Number,
    },
  },

  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      searchNm: '',
      sheetUtil: null,
    };
  },

  created() {
    this.addEvent([{name: 'AccountGbPopup_callbackData', func: this.onFocusedRowData}]);
  },

  mounted() {
    this.initSheet();
  },

  methods: {
    async initSheet() {
      const OPT = {
        //각 컬럼에 대한 정의 (컬럼의 이름,유형(Type),포맷(Format)등을 설정)
        //컬럼의 "Type"과 "Name" 속성은 반드시 설정되어야 합니다.
        Cols: [
          {
            Header: '거래구분코드',
            Name: 'cmnDCd',
            Type: 'Text',
            Width: 100,
            CanEdit: 0,
          },
          {
            Header: '거래구분명',
            Name: 'cmnDNm',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: 0,
          },
        ],
        Cfg: {
          CanEdit: 0,
        },
        Events: {
          onDblClick: this.grid_onDblClick,
          onRenderFirstFinish: this.onSearch,
        },
      };

      this.sheet = await PMISSheet.createSheet({
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });

      this.sheetUtil = this.sheet.util;
    },
    onFocusByNm() {
      if (this.sheet.getTotalRowCount() > 0) {
        let searchCnt = this.sheetUtil.listSearch(['cmnDNm'], this.searchNm);
        if (searchCnt == 0) {
          this.$alert({title: '거래구분', message: '해당 거래구분 정보가 존재하지 않습니다'});
        }
        this.$refs.searchNm.$refs.input.focus();
      }
    },
    async onSearch() {
      let response = await axios.post('/cmmnCodeRegist/selectLowerCodeList', {
        pgmCd: this.pgmCd,
        cmnUCd: $getConstants('ACCOUNT_GB').code,
        //cmnDNm: this.cmnDNm,
      });

      if (response.status == 200) {
        this.sheet.loadSearchData(response.data);
      }
    },
    onFocusedRowData(callbackFunction) {
      if (typeof callbackFunction == 'function') {
        let row = this.sheet.getFocusedRow();
        if (row == null) {
          this.$alert({title: '거래구분선택', message: '선택된 거래구분이 없습니다.'});

          return;
        }
        callbackFunction(this.sheet.getFocusedRow());
      }
    },
    grid_onDblClick(e) {
      if (e.row.Kind == 'Data') {
        this.callEvent({name: 'onHideModal'});
      }
    },
  },
};
</script>

<style></style>
