<template>
  <div :class="rootClass" :style="rootStyle">
    <label v-if="label">{{ label }}</label>
    <input
      type="text"
      ref="search"
      v-model="idVdata"
      :name="name"
      :class="keyTextBoxClass"
      :disabled="!(idEditable != idEnable)"
      :readonly="idReadonly"
      :style="idCss"
      :required="required"
      :data-error-title="errTitle"
      :data-error-message="errMessage"
    />
    <!-- style="width: 90px;" -->
    <iui-button
      ref="searchButton"
      class="flex-0"
      v-if="isSearchBtn"
      btn-class="search-button"
      @click="onSearch"
    ></iui-button>
    <input
      type="text"
      :width="width ? width : ''"
      v-model="nameVdata"
      :name="nameEleName"
      :class="valueTextBoxClass"
      :disabled="!(!nameEditable != !nameEnable)"
      :readonly="nameReadonly"
      :required="required"
    />
    <iui-button v-if="isInitBtn" @click="initSearch" value="x" />

    <iui-modal
      :name="searchBoxNm"
      v-if="!isExternal"
      :title="internalTitle"
      :btns="modalBtns"
      width="700px"
      height="600px"
    >
      <iui-searchmodal :type="type" :uid="_uid" :paramObj="paramObj"></iui-searchmodal>
    </iui-modal>
    <iui-modal v-else :name="comp.compNm" :title="comp.title" :btns="modalBtns" :sizeType="sizeType">
      <component :is="comp.compNm" :paramObj="comp.paramObj"></component>
    </iui-modal>
  </div>
</template>

<script>
import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import IuiLabelPrefixSuffixMixin from '@/components/Iui/mixins/IuiLabelPrefixSuffixMixin';
import {IuiValidatorMixin} from '@/plugins/IuiValidator';
import rootStore from '@/store';

export default {
  mixins: [IuiBaseMixin, IuiLayoutMixin, IuiLabelPrefixSuffixMixin, IuiValidatorMixin],
  props: {
    type: {
      type: String,
    },
    idEvent: {
      type: Array,
      default: () => [],
    },
    // idCss: {
    //   type: Object,
    // },
    nameEvent: {
      type: Array,
      default: () => [],
    },
    nameCss: {
      type: Object,
    },
    value: {
      type: [String, Number],
    },
    idValue: {
      type: [String, Number],
    },
    nameValue: {
      type: [String, Number],
    },
    idEditable: {
      type: Boolean,
      default: false,
    },
    nameEditable: {
      type: Boolean,
      default: false,
    },
    idEnable: {
      type: Boolean,
      default: false,
    },
    nameEnable: {
      type: Boolean,
      default: false,
    },
    idReadonly: {
      type: Boolean,
      default: false,
    },
    nameReadonly: {
      type: Boolean,
      default: false,
    },
    nameEleId: {
      type: String,
    },
    nameEleName: {
      type: String,
    },
    idBindArray: {
      type: Array,
      default: () => [],
    },
    nameBindArray: {
      type: Array,
      default: () => [],
    },
    comp: {
      type: Object,
    },
    callback: {
      type: Function,
      default: () => {},
    },
    isSearchBtn: {
      type: Boolean,
      default: true,
    },
    isInitBtn: {
      type: Boolean,
      default: false,
    },
    initCallback: {
      type: Function,
      default: () => {},
    },
    confirmEventNm: {
      type: String,
    },
    paramBind: {
      type: String,
    },
    paramObj: {
      type: Object,
      default: () => {},
    },
    init: {
      type: Boolean,
      default: false,
    },
    sizeType: {
      type: String,
      default: 'size1',
    },
  },
  data() {
    return {
      idVdata: this.value || this.idValue || '',
      nameVdata: this.nameValue || '',
      searchBoxNm: 'searchBox' + this._uid,
      isExternal: false,
      modalBtns: [{name: '확인', callback: this.onConfirm}],
      mappingKeys: [],
      internalTitle: undefined,
    };
  },
  computed: {
    // class
    rootClass: {
      get() {
        let obj = {
          'iui-searchbox': true,
          'iui-layout-debug': this.layoutDebug,
        };

        return obj;
      },
    },
    keyTextBoxClass: {
      get() {
        let obj = {
          'key-textbox': true,
          _idTarget_: true,
          required: this.required,
          'is-valid-fail': !this.isValid,
        };

        return obj;
      },
    },
    valueTextBoxClass: {
      get() {
        let obj = {
          'value-textbox': true,
          _nameTarget_: true,
          required: this.required,
          'is-valid-fail': !this.isValid,
        };

        return obj;
      },
    },

    // style
    rootStyle: {
      get() {
        let obj = {
          width: undefined,
          height: undefined,
          flex: undefined,
        };

        if (this.internalWidth !== undefined) {
          obj.width = this.internalWidth;
          obj.flex = `0 0 ${this.internalWidth}`;
        }
        if (this.internalHeight !== undefined) {
          obj.height = this.internalHeight;
        }

        return obj;
      },
    },

    idCss: {
      get() {
        return 'width: 90px;';
      },
    },
    errTitle() {
      return this.errorMessage instanceof Object ? this.errorMessage.title : 'Confirm';
    },
    errMessage() {
      return this.errorMessage instanceof Object ? this.errorMessage.message : this.errorMessage;
    },
  },
  created() {
    this.initSearchbox();
  },
  updated() {
    this.initLayout();
    this.initSearchbox();
    this.initValidator();
  },
  mounted() {
    //속성 주입
    for (let k in this.$attrs) {
      this.$el.querySelector('._idTarget_').setAttribute(k, this.$attrs[k]);
      if (k == 'id') {
        if (this.$el.querySelector('label')) {
          this.$el.querySelector('label').setAttribute('for', this.$attrs[k]);
        }
        if (!this.nameEleName) {
          this.$el.querySelector('._nameTarget_').setAttribute(k, this.$attrs[k] + 'Name');
        }
      }
      if (k == 'name' && !this.nameEleName) {
        this.$el.querySelector('._nameTarget_').setAttribute(k, this.$attrs[k] + 'Name');
      }
      this.$el.removeAttribute(k);
    }
    if (this.nameEleId) {
      this.$el.querySelector('._nameTarget_').setAttribute('id', this.nameEleId);
    }
    if (this.nameEleName) {
      this.$el.querySelector('._nameTarget_').setAttribute('name', this.nameEleName);
    }
    //ID 이벤트 주입
    for (let e of this.idEvent) {
      this.$el.querySelector('._idTarget_').addEventListener(e.name, e.callback);
    }
    // //ID 스타일 주입
    // for (let k in this.idCss) {
    //   this.$el.querySelector('._idTarget_').style[k] = this.idCss[k];
    // }
    //NAME 이벤트 주입
    for (let e of this.nameEvent) {
      this.$el.querySelector('._nameTarget_').addEventListener(e.name, e.callback);
    }
    // //NAME 스타일 주입
    // for (let k in this.nameCss) {
    //   this.$el.querySelector('._nameTarget_').style[k] = this.nameCss[k];
    // }
    // //기본요소 height와 라벨 height 맞춤
    // if (this.$el.querySelector('label')) {
    //   this.$el.querySelector('label').style.height = this.$el.querySelector('._idTarget_').offsetHeight + 'px';
    //   this.$el.querySelector('label').style.lineHeight = this.$el.querySelector('._idTarget_').offsetHeight + 'px';
    // }
    this.idName = this.$el.querySelector('._idTarget_').name;
    this.nmName = this.$el.querySelector('._nameTarget_').name;
    //기본요소 구분하기 위한 class명 삭제
    // this.$el.querySelector("._idTarget_").classList.remove("_idTarget_");
    // this.$el.querySelector("._nameTarget_").classList.remove("_nameTarget_");
  },
  methods: {
    initSearchbox() {
      this.addEvent([
        {
          name: `${this.type}SearchOnConfirm${this._uid}`,
          func: this.onConfirm,
        },
        {
          name: `${this.confirmEventNm}SearchOnConfirm`,
          func: this.onConfirm,
        },
      ]);
      this.setIdBindArray();
      this.setNameBindArray();
      if (this.comp) {
        this.isExternal = true;
        this.addComponent(this.comp.compNm, this.comp.path);
        if (this.comp.mappingKeys) {
          this.mappingKeys = this.comp.mappingKeys;
        }
      } else {
        // sheet에서 받을 id/name값 지정
        switch (this.type) {
          case 'prj':
            this.mappingKeys = ['prjCd', 'prjNm'];
            this.internalTitle = '현장';
            break;
          case 'prjEst':
            this.mappingKeys = ['prjEstCd', 'prjEstNm'];
            this.internalTitle = '현장';
            break;
          case 'workGroup':
            this.mappingKeys = ['cusCd', 'cusNm'];
            this.internalTitle = '외주 계약내역';
            break;
          case 'customer':
            this.mappingKeys = ['bsnNo', 'frmNm'];
            this.internalTitle = '업체정보';
            break;
          case 'worker':
            this.mappingKeys = ['empNo', 'name'];
            this.internalTitle = '사원정보';
            break;
          case 'accountGb':
            this.mappingKeys = ['accountGb', 'accountGbName'];
            this.internalTitle = '거래구분';
            break;
          case 'commonCode':
            this.mappingKeys = ['code', 'codeNm'];
            this.internalTitle = '공통코드';
            break;
          case 'companyCode':
            this.mappingKeys = ['pgmCd', 'frmNm'];
            this.internalTitle = '회사';
            break;
          case 'dataCl':
            this.mappingKeys = ['code', 'name'];
            this.internalTitle = '자료분류';
            break;
          case 'user':
            this.mappingKeys = ['empNo', 'name'];
            this.internalTitle = '사용자';
            break;
        }
      }

      // validator 설정
      this.validatorTargetDataProp = 'idVdata';
      this.validationTriggerEventName = ['vaild'];
    },
    onSearch() {
      if (!this.isExternal) {
        switch (this.type) {
          //주소검색
          case 'addr': {
            if (!this._activated) {
              return;
            }
            const baseUrl =
              location.protocol.indexOf('https') !== -1 ? process.env.VUE_APP_API_URLS : process.env.VUE_APP_API_URL;
            let win = window.open(
              `${baseUrl}/address/jusoPopup?frontendUrl=${location.origin}`,
              'jusoPop',
              'width=570,height=420, scrollbars=yes, resizable=yes'
            );
            var interval = window.setInterval(() => {
              try {
                if (win == null || win.closed) {
                  window.clearInterval(interval);
                  if (this.callback && Object.keys(this.$store.getters.externalData).length) {
                    this.callback(this.$store.getters.externalData);
                    this.$store.dispatch('setExternalData', {});
                  }
                }
              } catch (e) {
                console.error(e);
              }
            }, 500);
            break;
          }
          default: {
            this.$store.commit(
              'setModalParam',
              this.$store.getters[this.$store.getters.currentUrl + '/' + this.paramBind]
            );
            this.$modal.show(this.searchBoxNm);
            break;
          }
        }
      } else {
        // this.$store.getters["modalParam"] = this.$store.getters[this.$store.getters.currentUrl + '/' + this.paramBind];
        this.$store.commit('setModalParam', this.$store.getters[this.$store.getters.currentUrl + '/' + this.paramBind]);
        this.$modal.show(this.comp.compNm);
      }
    },
    setIdBindArray(value) {
      if (typeof value != 'undefined') {
        this.idBindArray.forEach(o => {
          let payload = {};
          payload[this.idName] = value;
          rootStore.commit(rootStore.getters.currentUrl + '/' + o, payload);
        });
      }
    },
    setNameBindArray(value) {
      if (typeof value != 'undefined') {
        this.nameBindArray.forEach(o => {
          let payload = {};
          payload[this.nmName] = value;
          rootStore.commit(rootStore.getters.currentUrl + '/' + o, payload);
        });
      }
    },
    onConfirm() {
      let name;
      switch (this.type) {
        case 'prj':
          name = 'onFocusedRowDataOnContract';
          break;
        case 'prjEst':
          name = 'onFocusedRowDataOnPrjEst';
          break;
        case 'workGroup':
          name = 'PoCompleteList_Select';
          break;
        case 'customer':
          name = 'CustomerListPopup_callbackData';
          break;
        case 'worker':
          name = 'onFocusedRowDataOnWorker';
          break;
        case 'accountGb':
          name = 'AccountGb_data';
          break;
        case 'commonCode':
          name = 'CommonCode_setData';
          break;
        case 'companyCode':
          name = 'companyCode_setData';
          break;
        case 'dataCl':
          name = 'dataCl_setData';
          break;
        case 'user':
          name = 'User_setData';
          break;
        default:
          name = this.confirmEventNm;
          break;
      }
      this.callEvent({
        name: name,
        param: data => {
          if (this.mappingKeys.length) {
            for (const [i, v] of this.mappingKeys.entries()) {
              if (i == 0) {
                this.idVdata = String(data[v]);
                let evt = document.createEvent('HTMLEvents');
                evt.initEvent('onChange', false, true);
                this.$el.querySelector('._idTarget_').dispatchEvent(evt);
              } else {
                this.nameVdata = String(data[v]);
              }
            }
          }
          if (this.callback) {
            this.callback(data);
          }
          if (this.isExternal) {
            this.$modal.hide(this.comp.compNm);
          } else {
            this.$modal.hide(this.searchBoxNm);
          }
        },
      });
    },
    initSearch() {
      for (let el of this.$el.childNodes) {
        if (el.localName == 'input') el.value = '';
      }
      this.idVdata = '';
      this.nameVdata = '';
      this.initCallback();
    },
  },
  watch: {
    value(value) {
      this.idVdata = value || '';
    },
    idVdata(value) {
      this.setIdBindArray(value);

      this.$emit('change', value);
      this.$emit('valid', value);
    },
    idValue(value) {
      this.idVdata = value || '';

      this.$emit('change', value);
      this.$emit('valid', value);
    },
    nameVdata(value) {
      this.setNameBindArray(value);
    },
    nameValue(value) {
      this.nameVdata = value;
    },
    init(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.initSearch();
        this.$emit('update:init', false);
      }
    },
  },
};
</script>

<style scoped>
.value-textbox {
  min-width: 140px;
}
</style>
