<template>
  <!--구매상담신청-포틀릿-->
  <div style="height:100%">
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr height="30px">
          <th>회사명</th>
          <th>대표자명</th>
          <th>요청일시</th>
        </tr>
      </thead>
    </table>
    <div class="inner" style="height:calc(100% - 40px);">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="(purchs, index) in tableData" :key="index">
            <td style="text-align:left;">
              <a @click="goShortCut(purchs)">{{ purchs.frmNm }}</a>
            </td>
            <td>{{ purchs.name }}</td>
            <td class="p_last">{{ dateFormat(purchs.reqDt) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
import {selectPurchsCnsltRequestList} from '@/components/portlet/api/portletData.js';
export default {
  mixins: [PortletMixin],
  data() {
    return {
      cnt: 3,
      tableData: [],
    };
  },
  computed: {
    isDataExist() {
      return this.tableData.length;
    },
  },
  methods: {
    dateFormat,
    setWidth() {
      this.tblWidth = this.$parent.$el.offsetWidth - 28;
    },
    onSearch() {
      selectPurchsCnsltRequestList().then(response => {
        this.tableData = response.data;
        this.$emit('update:unchkedCnt', response.data.length);
      });
    },
    goShortCut(data) {
      this.goMenu('PURCHS_CNSLT_RECEIPT', {reqDt: data.reqDt, seq: data.seq});
    },
  },
};
</script>

<style></style>
