<template>
  <pmis-tab-box>
    <template v-slot:title>정산금액</template>
    <form onsubmit="return false;">
      <p class="bu_title">금월정산금액</p>
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header width="110px">
            당월투입금액
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.curInputAmt" />
          </i-col>
          <i-col width="90px"></i-col>
          <i-col></i-col>
        </i-row>
        <i-row>
          <i-col-header>
            전월미지급액
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.prePayable" />
          </i-col>
          <i-col-header>
            기타지급액
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :enable="!closeYn"
              :value="detailInfo.curEtcinputAmt"
              @change="setCurEtcinputAmt(Number($event.target.value))"
            />
          </i-col>
        </i-row>
      </iui-container-new>
      <hr />
      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header width="110px">
            당월공제금액
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :value="detailInfo.curDeductAmt"
              @change="setCurDeductAmt(Number($event.target.value))"
              :enable="!closeYn"
            />
          </i-col>
          <i-col-header>
            당월유보금액
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :value="detailInfo.curReserveAmt"
              :enable="!closeYn"
              @change="setCurReserveAmt(Number($event.target.value))"
            />
          </i-col>
        </i-row>
      </iui-container-new>
      <hr />

      <iui-container-new type="table" theme="bullet">
        <i-row>
          <i-col-header width="110px">
            공급가액
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.amt" />
          </i-col>
          <i-col-header>
            부가세
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :enable="!detailInfo.billNo && !detailInfo.billTaxNo"
              :value="detailInfo.vatAmt"
              @change="setVatAmt(Number($event.target.value))"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            비과세
          </i-col-header>
          <i-col>
            <iui-text
              type="amount"
              width="145px"
              :enable="!detailInfo.billNo && !detailInfo.billTaxNo"
              :value="detailInfo.taxexe"
              @change="setTaxexe(Number($event.target.value))"
            />
          </i-col>
          <i-col-header>
            합계
          </i-col-header>
          <i-col>
            <iui-text type="amount" width="145px" :enable="false" :value="detailInfo.sumAmt" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header width="110px">
            세금계산서번호
          </i-col-header>
          <i-col>
            <iui-text
              type="text"
              :value="detailInfo.billNo"
              width="150px"
              max-length="20"
              :readonly="true"
              :css="{cursor: 'pointer', 'text-decoration': 'underline', 'text-align': 'center'}"
              @click="onClick_billNo"
            />
            <pmis-file-list
              name="flNo"
              id="file0"
              flNoCol="flNo"
              title="첨부파일"
              title-visible
              :modal="true"
              :hidden="!!detailInfo.billNo"
              :toolbar-visible="true"
              :file-number.sync="detailInfo.flNo"
              @delete-complete="() => onFileDelete('flNo')"
            />
          </i-col>
          <i-col></i-col>
          <i-col></i-col>
        </i-row>
        <i-row>
          <i-col-header>
            계산서번호
          </i-col-header>
          <i-col>
            <iui-text
              type="text"
              :value="detailInfo.billTaxNo"
              width="150px"
              max-length="20"
              :readonly="true"
              :css="{cursor: 'pointer', 'text-decoration': 'underline', 'text-align': 'center'}"
              @click="onClick_billNo"
            />
            <pmis-file-list
              name="flTaxNo"
              id="file1"
              flNoCol="flTaxNo"
              title="첨부파일"
              title-visible
              :modal="true"
              :hidden="!!detailInfo.billTaxNo"
              :toolbar-visible="true"
              :file-number.sync="detailInfo.flTaxNo"
              @delete-complete="() => onFileDelete('flTaxNo')"
            />
          </i-col>
          <i-col></i-col>
          <i-col></i-col>
        </i-row>
      </iui-container-new>
      <tax-invoice-pop :billNo="billNo" :open.sync="propsOpen" pageGubun="sub" />

      <iui-modal name="PrintModal" title="출력" size-type="size6">
        <report-viewer :fomNo="fomNo" :ozContent="ozContent" />
      </iui-modal>
    </form>
  </pmis-tab-box>
</template>

<script>
import {deleteFile, selectReportData} from '@/view/closing/fixedTime/subcon/api/fixedTimeSubcon.js';
import FOM_NO from '@/constants/fomNoConstants.js';

import TaxInvoicePop from '@/view/bidding/components/TaxInvoicePop.vue';
import ReportViewer from '@/view/bidding/components/ReportViewer.vue';

export default {
  components: {TaxInvoicePop, ReportViewer},
  data() {
    return {
      propsOpen: false,
      billNo: '',
      fomNo: FOM_NO.SETTLEMENT_FIXED_TIME_SUBCON,
      ozContent: {},
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'detailInfo', 'closeYn']);
    $mapActions(this, ['setCurEtcinputAmt', 'setCurDeductAmt', 'setCurReserveAmt', 'setVatAmt', 'setTaxexe']);
  },
  created() {
    this.addFuncPrint(this.onPrint);
  },
  methods: {
    async onPrint() {
      const investMm = this.searchInfo.investMm;
      const reportData = (await selectReportData({investMm})).data;

      this.ozContent = {
        investMm: `${investMm.substring(0, 4)}년 ${investMm.substring(4)}월`,
        prjNm: this.getPrjInfo().prjNm,
        settlementList: JSON.stringify({settlementList: reportData}),
      };
      this.$modal.show('PrintModal');
    },
    onClick_billNo(e) {
      if (e.target.value) {
        this.billNo = e.target.value;
        this.propsOpen = true;
      }
    },
    async onFileDelete(name) {
      await deleteFile({
        poNoSubc: this.detailInfo.poNoSubc,
        custCode: this.detailInfo.custCode,
        investMm: this.searchInfo.investMm,
        name: name,
      });
    },
  },
};
</script>

<style scoped>
hr {
  height: 1px;
  border: 0;
  background-color: #ccc;
  margin: 10px 5px;
}
</style>
