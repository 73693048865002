<template>
  <pmis-content-box>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="400px">
          <pmis-content-box>
            <template v-slot:header-left>
              <iui-text :value.sync="searchNm" @enter="onSearch" />
              <iui-button @click="onSearch">검색</iui-button>
            </template>

            <ib-sheet
              :uid="_uid"
              :options="Options1"
              :loadSearchData="loadSearchData1"
              @loadSheet="loadSheet1"
              :events="{
                onDblClick: onDblClick1,
                onSearchFinish: onSearchFinish1,
              }"
            />
          </pmis-content-box>
        </i-col>
        <i-col width="30px" style="display: flex; justify-content: center; align-items: center;">
          <iui-button @click="addPublic">→</iui-button>
          <i-spacer />
          <iui-button @click="removePublic">←</iui-button>
        </i-col>
        <i-col>
          <ib-sheet
            :uid="_uid"
            :options="Options2"
            :loadSearchData="loadSearchData2"
            @loadSheet="loadSheet2"
            :events="{
              onSearchFinish: onSearchFinish2,
              onDblClick: onDblClick2,
            }"
          />
        </i-col>
        <i-col width="30px" style="display: flex; justify-content: center; align-items: center;">
          <iui-button @click="setOrderUp">↑</iui-button>
          <i-spacer />
          <iui-button @click="setOrderDownd">↓</iui-button>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import options1 from './sheetOption/LineSelectSheet2.js';
import options2 from './sheetOption/PublicSelect.js';
import {selectWokerWithDeptList} from '../api/obs.js';
export default {
  props: {
    publicList: {
      type: Array,
      default: undefined,
    },
    set: {
      type: Boolean,
      default: false,
    },
    unset: {
      type: Boolean,
      default: false,
    },
    exclude: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sheet1: undefined,
      Options1: options1(this),
      loadSearchData1: [],
      sheet2: undefined,
      Options2: options2(this),
      loadSearchData2: [],
      internalPublicList: [],
      searchNm: '',
    };
  },
  watch: {
    set(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this._set();
      }
    },
    unset(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this._unset();
      }
    },
  },
  created() {
    this.initPublic();
  },
  methods: {
    onSearch() {
      this.sheet1.util.treeSearch('blnDepNm', this.searchNm);
    },
    initPublic() {
      if (this.publicList) {
        this.internalPublicList = this.publicList;
        this.loadSearchData2 = this.internalPublicList;
      }
    },
    loadSheet1(sheet) {
      this.sheet1 = sheet;
      this.selectWokerWithDeptList();
    },

    onSearchFinish1() {
      this.sheet1.util.treeSearch('blnDepNo', this.userInfo.blnDepNo, true);
    },
    onDblClick1() {
      this.addPublic();
    },
    loadSheet2(sheet) {
      this.sheet2 = sheet;
    },
    onSearchFinish2(e) {
      this.setPublicSeq();
      e.sheet.acceptChangedData();
    },
    onDblClick2(e) {
      if (e.kind === 'Data') {
        this.removePublic();
      }
    },
    async selectWokerWithDeptList() {
      const response = await selectWokerWithDeptList();
      this.loadSearchData1 = this.setObsTree(response.data);
    },
    setObsTree(data) {
      if (!data.length) {
        return;
      }
      const items = data.map(item => {
        item.obsName = item.blnDepNm;
        item.Items = item.obsWokerTreeList
          ?.map(woker => {
            woker.blnDepNm = item.blnDepNm;
            woker.blnDepNo = item.blnDepNo;
            woker.blnDepCd = woker.empNo;
            woker.obsName = woker.name;
            return woker;
          })
          .filter(woker => this.userInfo.empNo !== woker.empNo && this.exclude.indexOf(woker.empNo) === -1);
        return item;
      });

      const Items = $_treeModel(items, 'OBS', 'blnDepCd', 'uppBlnDepCd', 'blnDepNo', 'uppBlnDepNo');
      return [{blnDepCd: 'OBS', obsName: this.userInfo.frmNm, blnDepNo: '0', Items: Items}];
    },
    async addPublic() {
      const obsRow = this.sheet1.getFocusedRow();

      if (obsRow !== undefined && obsRow.empNo) {
        const rows = this.sheet2.getDataRows();

        if (rows.length > 0) {
          // 중복 확인
          let dup = false;

          for (let i = 0; i < rows.length; i++) {
            if (rows[i].empNo === obsRow.empNo) {
              dup = true;
              break;
            }
          }

          if (dup) {
            return;
          }
        }

        const data = {
          sn: 0,
          empNo: obsRow.empNo,
          name: obsRow.name,
          blnDepNo: obsRow.blnDepNo,
          blnDepNm: obsRow.blnDepNm,
        };

        this.sheet2.addRow({next: this.sheet2.getFirstRow(), init: data});
        this.setPublicSeq();
      }
    },
    async removePublic() {
      const row = this.sheet2.getFocusedRow();

      if (row !== undefined && row !== null) {
        if (row.rcvDttm !== undefined && row.rcvDttm !== null && row.rcvDttm !== '') {
          await this.$alert(`공람대상자 '${row.empNm}'는(은) 이미 문서를 확인한 공람대상자이므로 제외할 수 없습니다.`);
          return;
        }

        this.sheet2.removeRow(row);
        this.setPublicSeq();
      }
    },
    async setOrderUp() {
      const row = this.sheet2.getFocusedRow();
      const rowIndex = this.sheet2.getRowIndex(row);

      if (rowIndex > 1) {
        this.sheet2.moveRow(row, this.sheet2.getRowByIndex(rowIndex - 1));
        this.setPublicSeq();
      }
    },
    async setOrderDownd() {
      const row = this.sheet2.getFocusedRow();
      const rowIndex = this.sheet2.getRowIndex(row);
      const rowCount = this.sheet2.getDataRows().length;

      if (rowIndex < rowCount) {
        this.sheet2.moveRow(row, this.sheet2.getRowByIndex(rowIndex + 2));
        this.setPublicSeq();
      }
    },
    setPublicSeq() {
      const rows = this.sheet2.getDataRows();
      let seq = 1;
      rows.forEach(row => {
        this.sheet2.setValue(row, 'sn', seq);
        seq++;
      });
    },
    async _set() {
      this.internalPublicList = this.sheet2.getSaveJson(0).data.map(item => ({
        sn: item.sn,
        blnDepNo: item.blnDepNo,
        blnDepNm: item.blnDepNm,
        empNo: item.empNo,
        name: item.name,
      }));
      this.$emit('update:publicList', this.internalPublicList);
      this.$emit('update:set', false);
      this.$emit('set-complete');
    },
    _unset() {
      this.internalPublicList = [];
      this.sheet2.removeAll();

      this.$emit('update:publicList', this.internalPublicList);
      this.$emit('update:unset', false);
    },
  },
};
</script>

<style></style>
