import {numToKr} from 'vue-number-to-kor';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import {SUB_PO_COLUMNS, SUB_RFQ_COLUMNS} from '@/view/bidding/js/BiddingColumns.js';
import {selectSubPoInfos, selectSubPoListBySpotDeptcd, selectSubPoSeqList} from '@/view/bidding/po/sub/api/subPo.js';

const BIDDING_STATE = BIDDING_CONSTANTS.PROGRESS_STATE;
const BIDDING_CODES = BIDDING_CONSTANTS.CODES;

const state = {
  poList: [],
  poContSeqList: [],

  rfqInfo: {...SUB_RFQ_COLUMNS},
  poInfo: {...SUB_PO_COLUMNS},

  poItemList: [],
  poSubmitDocList: [],

  poIsNew: false,
};

const getters = {
  bidType: () => 'sub',
  poList: state => state.poList,
  poContSeqList: state => state.poContSeqList,

  rfqInfo: state => state.rfqInfo,
  poInfo: state => state.poInfo,
  poStatus: state => getPoStatus(state),

  poItemList: state => state.poItemList,
  poSubmitDocList: state => state.poSubmitDocList,

  poIsNew: state => state.poIsNew,
};

const mutations = {
  setPoList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.poList = payload;
    }
  },
  setPoContSeqList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.poContSeqList = payload;
    }
  },
  initPoContSeqList(state) {
    state.poContSeqList = [];
  },
  setPoInfo(state, payload) {
    const poInfo = state.poInfo;

    for (const key in poInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        poInfo[key] = payload[key];
      }
    }

    poInfo.poNo2 = state.poIsNew ? BIDDING_CODES.AUTO : COMMON_FUNCTION.getMakeFormat(poInfo.poNo);

    poInfo.amtKor = poInfo.poAmt ? numToKr(poInfo.poAmt.toFixed(0)) : numToKr(0);
    poInfo.vatAmtKor = poInfo.vatAmt ? numToKr(poInfo.vatAmt.toFixed(0)) : numToKr(0);
    poInfo.totAmtKor = poInfo.totAmt ? numToKr(poInfo.totAmt.toFixed(0)) : numToKr(0);
    poInfo.beforePoAmtKor = poInfo.beforePoAmt ? numToKr(poInfo.beforePoAmt.toFixed(0)) : numToKr(0);
    poInfo.beforeVatAmtKor = poInfo.beforeVatAmt ? numToKr(poInfo.beforeVatAmt.toFixed(0)) : numToKr(0);
    poInfo.beforeTotAmtKor = poInfo.beforeTotAmt ? numToKr(poInfo.beforeTotAmt.toFixed(0)) : numToKr(0);
    poInfo.delayAmt = poInfo.totAmt && poInfo.delayRate ? (poInfo.totAmt * poInfo.delayRate) / 100 : 0;
  },
  initPoInfo(state) {
    state.poInfo = {...SUB_PO_COLUMNS};
  },

  setRfqInfo(state, payload) {
    for (const key in state.rfqInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.rfqInfo[key] = payload[key];
      }
    }

    state.rfqInfo.rfqNo2 = COMMON_FUNCTION.getMakeFormat(state.rfqInfo.rfqNo);
  },
  initRfqInfo(state) {
    state.rfqInfo = {...SUB_RFQ_COLUMNS};
  },
  setPoItemList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.poItemList = payload;
    }
  },
  initPoItemList(state) {
    state.poItemList = [];
  },
  setPoSubmitDocList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.poSubmitDocList = payload;
    }
  },
  initPoSubmitDocList(state) {
    state.poSubmitDocList = [];
  },

  setPoIsNew(state, payload) {
    if (typeof payload === 'boolean') {
      state.poIsNew = payload;
    }
  },
};

const actions = {
  initPo({commit}) {
    commit('setPoIsNew', false);
    commit('initPoContSeqList');
    commit('initRfqInfo');
    commit('initPoInfo');
    commit('initPoItemList');
    commit('initPoSubmitDocList');
  },

  /**
   * 계약목록 조회
   *
   * @param {*} searchOptions
   */
  async searchPoList({commit}, searchOptions) {
    const poList = (await selectSubPoListBySpotDeptcd(searchOptions)).data;
    commit('setPoList', poList);
  },

  /**
   * 계약차수목록 조회
   *
   * @param {String} poNo
   */
  async searchPoContSeqList({commit, dispatch}, poNo) {
    dispatch('initPo');

    const poContSeqList = (await selectSubPoSeqList(poNo)).data;
    commit('setPoContSeqList', poContSeqList);
  },

  /**
   * 계약정보 조회
   *
   * @param {Object} param {poNo, contSeq}
   */
  async searchPoInfos({commit}, param) {
    commit('setPoIsNew', false);

    const {poNo, contSeq} = param;

    const response = await selectSubPoInfos(poNo, contSeq);
    const poInfo = response.data.poInfo;
    const rfqInfo = response.data.rfqInfo;
    const poItemList = response.data.poItemList;
    const poSubmitDocList = response.data.poNeedFileList;

    commit('setPoInfo', poInfo);
    rfqInfo ? commit('setRfqInfo', rfqInfo) : commit('initRfqInfo');
    commit('setPoItemList', poItemList);
    commit('setPoSubmitDocList', poSubmitDocList);
  },
};

function getPoStatus(state) {
  const poInfo = state.poInfo;
  const poSt = poInfo.poSt;

  const status = {
    isExistRfq: !!poInfo.rfqNo,
    isExistOnlyRfq: poInfo.rfqNo && !poInfo.poNo,
    isExistOnlyPo: !poInfo.rfqNo && !!(poInfo.poNo || poInfo.poNo2), // 수기계약
    isLastContSeq: poInfo.contSeq === poInfo.lastContSeq,
    isFirstContSeq: poInfo.contSeq === BIDDING_CODES.FIRST_CONT_SEQ, // 당초
    isChangeContSeq: poInfo.contSeq > BIDDING_CODES.FIRST_CONT_SEQ, // 변경
    isExistPo: poInfo.poNo != '',
    isStampTarget: 10000000 < poInfo.totAmt,

    /* 진행상태 */
    isPoReady: poSt === BIDDING_STATE.PO_READY, // 계약작성중
    isPoApprovalRequest: poSt === BIDDING_STATE.PO_APPROVAL_REQUEST, // 계약품의중
    isPoApprovalComplete: poSt === BIDDING_STATE.PO_APPROVAL_COMPLETE, // 계약품의완료
    isPoSend: poSt === BIDDING_STATE.PO_SIGN_READY, //거래처전자서명중
    isPoSignComplete: poSt === BIDDING_STATE.PO_SIGN_COMPLETE, // 거래처전자서명
    isAfterPoSignComplete: !!poSt && poSt >= BIDDING_STATE.PO_SIGN_COMPLETE, // 거래처전자서명 이후
    isPoComplete: poSt === BIDDING_STATE.PO_COMPLETE, // 계약완료

    /* 코드 */
    isStamp: poInfo.stampTaxYn === BIDDING_CODES.STAMP_TAX_Y, // 인지세 - 있음
    isPayCash: poInfo.payCashYn === BIDDING_CODES.PAY_CASH, // 대금지급방법 - 현금,어음
    isPayDefer: poInfo.payCashYn === BIDDING_CODES.PAY_DEFER, // 대금지급방법 - 유보
    isTaxDirect: poInfo.taxTypecd === BIDDING_CODES.TAX_DIRECT, // 과세유형 - 직접 입력
    isTaxDirectPercent: poInfo.taxDirectDs === BIDDING_CODES.TAX_DIRECT_PERCENT, // 과세유형 - 직접 입력(%)
    isPotypeUnit: poInfo.poTypecd === BIDDING_CODES.POTYPE_UNIT, // 계약구분 - 단가계약
    isDelayRate: poInfo.delayRateYn === BIDDING_CODES.DELAY_RATE_Y, // 지체상금율 - 있음
    isScpayInsrY: poInfo.scpayInsrYn === BIDDING_CODES.SCPAY_INSR_Y, // 하도급대금 지급보증금 - 해당
    isScpayInsrN: poInfo.scpayInsrYn === BIDDING_CODES.SCPAY_INSR_N, // 하도급대금 지급보증금 - 해당없음
    isFlawInsrYear: poInfo.flawInsrYn === BIDDING_CODES.FLAW_INSR_YEAR, // 하자보수 담보기간 - 년
    isFlawInsrMonth: poInfo.flawInsrYn === BIDDING_CODES.FLAW_INSR_MONTH, // 하자보수 담보기간 - 월
    isReturnY: poInfo.returnYn === BIDDING_CODES.RETURN_Y, // 반송
    isReturnTypeSupplier: poInfo.returnType === BIDDING_CODES.RETURN_TYPE_SUPPLIER, // 협력사반송
    isSuFileAllPass:
      !state.poSubmitDocList.length ||
      state.poSubmitDocList.every(item => item.approvalYn === BIDDING_CODES.PO_SUFILE_APPROVAL_Y),
  };
  status['isReturnSupplier'] = status.isPoApprovalComplete && status.isReturnY && status.isReturnTypeSupplier;
  status['isEnable'] = state.poIsNew || status.isExistOnlyRfq || status.isPoReady || status.isReturnSupplier;

  return status;
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
