export default that => {
  let preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      CanEdit: 0,
    },
    Def: {
      Row: {
        CanFormula: 1,
      },
    },
    LeftCols: [
      {
        Header: '정산년월',
        Name: 'investMm',
        Width: 100,
        Align: 'center',
        CustomFormat: '####-##',
      },
      {
        Header: '정산구분',
        Name: 'investKind',
        Width: 100,
        Align: 'center',
        Format: $getConstants('INVEST_KIND').code,
        CustomFormat: v => {
          const obj = {};
          obj[$getConstants('INVEST_KIND_ROUTINE').code] = '정기';
          obj[$getConstants('INVEST_KIND_NOT_ROUTINE').code] = '수시';
          return obj[v];
        },
      },
      {
        Header: '차수',
        Name: 'reqDegree',
        Width: 50,
        Align: 'center',
        Formula: fr => (fr.Row.closeYn == $getConstants('Y').code ? '' : fr.Row.reqDegree),
      },
      {Header: '거래처명', Name: 'custName', Width: 90},
      {Header: '장비명', Name: 'equipName', Width: 110},
      {Header: '차량번호', Name: 'carNo', Width: 110},
    ],
    Cols: [
      {
        Header: '가동일수',
        Name: 'monthlyDay',
        Extend: preset.quantity,
        Width: 90,
      },
      {
        Header: '총가동시간',
        Name: 'monthlyQty',
        Extend: preset.quantity,
        Width: 90,
      },
      {
        Header: '총가동금액',
        Name: 'monthlyAmt',
        Extend: preset.amount,
        Width: 90,
      },
      {Header: '유류대', Name: 'oilAmt', Extend: preset.amount, Width: 90},
      {Header: '유류대공제', Name: 'oilDeductAmt', Extend: preset.amount, Width: 90},
      {
        Header: '기타공제',
        Name: 'etcDeductAmt',
        Extend: preset.amount,
        Width: 90,
      },
      {
        Header: '정산금액',
        Name: 'calcAmt',
        Extend: preset.amount,
        Width: 90,
        Formula: fr => fr.Row.monthlyAmt + fr.Row.oilAmt - fr.Row.oilDeductAmt - fr.Row.etcDeductAmt,
      },
      {Header: '공급가', Name: 'amt', Extend: preset.amount, Width: 90},
      {Header: '부가세', Name: 'vatAmt', Extend: preset.amount, Width: 90},
      {Header: '비과세', Name: 'taxexe', Extend: preset.amount, Width: 90},
      {Header: '합계', Name: 'sumAmt', Extend: preset.amount, Width: 90},
    ],
  };
};
