export default that => {
  let preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      CanEdit: 1,
    },
    Def: {
      Row: {
        CanFormula: 1,
        CalcOrder: 'closeYn1CanEdit,closeYn2CanEdit',
      },
    },
    LeftCols: [
      {
        Header: '일자',
        Name: 'runDt1',
        Type: 'Text',
        Width: 70,
        Align: 'Center',
        CustomFormat: dt => dt.substring(6, 8),
        CanEdit: 0,
      },
      {Header: '가동시간', Name: 'runQty1', Extend: preset.quantity, Width: 70, Align: 'Center', CanEdit: 0},
      {Header: '단가', Name: 'runPrice1', Extend: preset.unitAmount, RelWidth: 1, MinWidth: 150, CanEdit: 0},
      {Header: '금액', Name: 'runAmt1', Extend: preset.amount, RelWidth: 1, MinWidth: 150, CanEdit: 0},
      {
        Header: {Value: '정산', TextColor: '#444', HeaderCheck: 1},
        Name: 'closeYn1',
        Type: 'Bool',
        Width: 60,
        CustomFormat: v => {
          const obj = {};
          obj[$getConstants('Y').code] = 1;
          return obj[v];
        },
        CanEditFormula: fr => fr.Row.runAmt1 > 0 && that.cud === 1,
      },
      {Name: 'oilAmt1', Extend: preset.amount, CanEdit: 0, Visible: 0},
      {Name: 'oilDeductAmt1', Extend: preset.amount, CanEdit: 0, Visible: 0},
    ],
    Cols: [
      {
        Name: ' ',
        Type: 'Text',
        Width: 5,
        Align: 'Center',
        CanEdit: 0,
      },
    ],
    RightCols: [
      {
        Header: '일자',
        Name: 'runDt2',
        Type: 'Text',
        Width: 70,
        Align: 'Center',
        CustomFormat: dt => dt.substring(6, 8),
        CanEdit: 0,
      },
      {Header: '가동시간', Name: 'runQty2', Extend: preset.quantity, Width: 70, Align: 'Center', CanEdit: 0},
      {Header: '단가', Name: 'runPrice2', Extend: preset.unitAmount, RelWidth: 1, MinWidth: 150, CanEdit: 0},
      {Header: '금액', Name: 'runAmt2', Extend: preset.amount, RelWidth: 1, MinWidth: 150, CanEdit: 0},
      {
        Header: {Value: '정산', TextColor: '#444', HeaderCheck: 1},
        Name: 'closeYn2',
        Type: 'Bool',
        Width: 60,
        CustomFormat: v => {
          const obj = {};
          obj[$getConstants('Y').code] = 1;
          return obj[v];
        },
        CanEditFormula: fr => fr.Row.runAmt2 > 0 && that.cud === 1,
      },
      {Name: 'oilAmt2', Extend: preset.amount, CanEdit: 0, Visible: 0},
      {Name: 'oilDeductAmt2', Extend: preset.amount, CanEdit: 0, Visible: 0},
    ],
  };
};
