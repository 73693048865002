export default that => {
  return {
    Cfg: {ReqStatusName: 'status', CanSort: false},
    Def: {Row: {CanFocus: 0}},
    Cols: [
      {
        Name: 'cbx',
        Header: {Value: '선택', TextColor: '#000000', HeaderCheck: 1},
        Align: 'center',
        Width: 60,
        Type: 'Bool',
        CanEditFormula: fr => fr.Row['allowAdd'],
      },
      {Name: 'allowAdd', Visible: false, CanEdit: false},
      {Name: 'allowDelete', Visible: false, CanEdit: false},
      {
        Name: 'pgmCd',
        Header: 'pgmCd',
        Type: 'Text',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'flNo',
        Header: 'flNo',
        Type: 'Int',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'grpNo',
        Header: 'grpNo',
        Type: 'Text',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'flDs',
        Header: 'flDs',
        Type: 'Text',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'revId',
        Header: 'revId',
        Type: 'Text',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'verNo',
        Header: 'verNo',
        Type: 'Text',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'fbsNo',
        Header: 'fbsNo',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'prjCd',
        Header: 'prjCd',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'rmk',
        Header: 'rmk',
        Type: 'Text',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'verNo',
        Header: 'verNo',
        Type: 'Text',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'svrFlPth',
        Header: 'svrFlPth',
        Type: 'Text',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'svrFlNm',
        Header: 'svrFlNm',
        Type: 'Text',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'flTp',
        Header: 'flTp',
        Type: 'Text',
        CanEdit: false,
        Visible: false,
      },
      ...that.columns,
      {
        Name: 'uploadStatus',
        Type: 'Text',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'cud',
        Type: 'Int',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'file',
        Type: 'Text',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'attachDocFlag',
        Type: 'Text',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'svrFlNm',
        CanEdit: false,
        Visible: false,
      },
      {
        Name: 'svrFlPth',
        CanEdit: false,
        Visible: false,
      },
    ],
  };
};
