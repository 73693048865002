export default that => {
  return {
    Cols: [
      {
        Header: '현장코드',
        Name: 'prjCd',
        Type: 'Text',
        Width: 70,
        Align: 'Center',
        Visible: 1,
      },
      {
        Header: '현장명',
        Name: 'prjNm',
        Type: 'Text',
        RelWidth: 1,
        MaxWidth: 300,
        Visible: 1,
      },
      {
        Header: '종료여부',
        Name: 'conCmplYn',
        Width: 70,
        CanEdit: 0,
        Align: 'center',
      },
    ],
    Cfg: {
      CanEdit: 0,
    },
  };
};
