<template>
  <pmis-page>
    <BoardList />
  </pmis-page>
</template>

<script>
import BoardList from './components/QnaBoardList.vue';
export default {
  components: {BoardList},
  created() {
    this.canDelete = false;
    this.canPrint = false;
    this.canSave = false;
  },
};
</script>

<style></style>
