<template>
  <article id="contents">
    <iui-mdi></iui-mdi>
    <div
      class="gnb_location h1"
      style="display: none;"
      v-if="$store.getters.currentUrl == '/Home' || $store.getters.currentUrl == '/'"
    />
    <!-- 메뉴 밑에 라인 -->
    <pmis-keep-alive>
      <router-view></router-view>
    </pmis-keep-alive>
    <!-- 메뉴 밑에 라인 -->
  </article>
</template>

<script>
import IuiMdi from './IuiMdi.vue';

export default {
  name: 'iui-main',
  components: {
    'iui-mdi': IuiMdi,
  },
};
</script>

<style></style>
