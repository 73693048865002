<template>
  <!-- 수금정보-포틀릿 -->
  <iui-portlet-container>
    <template #title>수금정보</template>

    <div class="p_tab" style="float: right;">
      <span class="pr10">기성년월</span>
      <iui-datepicker
        type="month"
        :value.sync="date"
        @change="onSearch"
        :isIconClear="false"
        :disabledDate="disabledDate"
      />
      <iui-button btn-class="btn_p_pre ml5" @click="changeDate('prev')"><span class="blind">이전</span></iui-button>
      <iui-button btn-class="btn_p_next ml5" @click="changeDate('next')"><span class="blind">다음</span> </iui-button>
    </div>
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr height="40px">
          <th>구분</th>
          <th>기성청구금액</th>
          <th>공제금액</th>
          <th>직불금액</th>
          <th>수금예정액</th>
          <th>수금금액</th>
        </tr>
      </thead>
    </table>

    <div class="inner" style="height:90px">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="data in collectInfo" :key="data.rowNo">
            <td>{{ data.ds }}</td>
            <td class="num">{{ $_numberFormat(data.rsumAmt, true) }} 원</td>
            <td class="num">{{ $_numberFormat(data.deductAmt, true) }} 원</td>
            <td class="num">{{ $_numberFormat(data.directAmt, true) }} 원</td>
            <td class="num">{{ $_numberFormat(data.actIncomAmt, true) }} 원</td>
            <td class="p_last num">{{ $_numberFormat(data.sumAmt, true) }} 원</td>
          </tr>
        </tbody>
      </table>
    </div>
  </iui-portlet-container>
</template>

<script>
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
import {selectCollectInfo} from '@/components/portlet/api/portletData.js';

export default {
  mixins: [PortletMixin],
  data() {
    return {
      cnt: 6,
      date: $_getYearMonth(),
      collectInfo: [],
    };
  },
  watch: {
    date() {
      this.onSearch();
    },
  },
  created() {
    this.$store.dispatch('portlet/addFuncInit', this.init);
  },
  methods: {
    $_numberFormat,
    init() {
      if (this.date < $_getYearMonth()) {
        this.date = $_getYearMonth();
      } else {
        this.onSearch();
      }
    },
    disabledDate(date) {
      return $_getCurrentDate() < $_dateToStringYYMMDD(date);
    },
    setWidth() {
      this.tblWidth = this.$el.offsetWidth - 28;
    },
    changeDate(direction) {
      const obj = {
        prev: {date: this.date + '01', addDate: -1},
        next: {date: $_getYearMonthLastDay(this.date), addDate: 1},
      };
      this.date = $_nowdateOverCheck($_getCalDate(obj[direction].date, obj[direction].addDate)).substring(0, 6);
    },
    onSearch() {
      selectCollectInfo({
        prjCd: this.prjCd,
        reqYm: this.date,
      }).then(response => (this.collectInfo = response.data));
    },
  },
};
</script>

<style></style>
