<template>
  <pmis-content-box>
    <template v-slot:header-left v-if="!isPopup">
      <iui-text @update:value="setSearchNm" width="150px" />
      <div class="ml5"></div>
      <iui-button value="검색" @click="listSearch" />
      <div class="ml15"></div>
      <iui-checkbox-group
        name="endCntrInc"
        :items="checkboxItem"
        :checkedValues="[endCntrInc]"
        @change="endCntrInc = $event.target.checked ? 'Y' : 'N'"
      ></iui-checkbox-group>
    </template>

    <iui-container-new type="ibsheet" :id="elId" />
  </pmis-content-box>

  <!-- <div style="width:100%; height:100%;">
    <div class="search_box" v-if="!isPopup">
      <iui-text @update:value="setSearchNm" />

      <iui-button value="검색" class="btn" @click="listSearch" />
      <iui-checkbox-group name="endCntrInc" :items="checkboxItem"></iui-checkbox-group>
    </div>
    <div :id="elId" style="width:100%; height:100%;" v-if="isPopup"></div>
    <div :id="elId" style="width:100%; height:calc(100% - 47px);" v-else></div>
  </div>-->
</template>

<script>
/**
 * ? : IuiModalSearch에서 사용되는것으로 추정
 * view/bsnsManage/cntrctManage/CntrctInfoRegist/components/ContractInfo.vue
 * */
export default {
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    visibleCols: {
      type: Array,
      default: () => ['prjCd', 'prjNm'],
    },
    uid: {
      type: Number,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      checkboxItem: [{label: '종료계약포함', value: 'checkValues', isChecked: false}],
      searchNm: '',
      endCntrInc: '',
    };
  },

  beforeCreate() {
    $mapGetters(this, {
      searchInfo: 'searchInfo',
    });

    $mapActions(this, ['onCtrctInfo']);
    $mapMutations(this, ['onSearchInfo']);
  },

  created() {
    this.addEvent([
      {name: 'selectCntrctInfoList', func: this.selectCntrctInfoList},
      {name: 'onFocusedRowData', func: this.onFocusedRowData},
      {name: 'onSelectedRowData', func: this.onSelectedRowData},
    ]);
  },

  mounted() {
    this.initSheet();
  },
  methods: {
    initSheet() {
      const OPT = {
        //각 컬럼에 대한 정의 (컬럼의 이름,유형(Type),포맷(Format)등을 설정)
        //컬럼의 "Type"과 "Name" 속성은 반드시 설정되어야 합니다.

        Def: {
          Row: {
            CanFormula: 1,
            CalcOrder: 'TextColor',
            TextColorFormula: param => (param.Row['conCmplDate'] ? '#FF0000' : ''),
          },
        },
        Cols: [
          {Header: '선택', Name: 'cbx', Type: 'Bool', Width: 50, CanEdit: 1, Visible: 0},
          {
            Header: '현장코드',
            Name: 'prjCd',
            Type: 'Text',
            Width: 70,
            Align: 'Center',
            CanEdit: 0,
            Visible: 1,
          },
          {
            Header: '현장명',
            Name: 'prjNm',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: 0,
            Visible: 1,
          },
          {
            Header: '계약일자',
            Name: 'lstCtrtDate',
            Extend: this.$ibsheetHelper.preset.date,
            RelWidth: 1,
            Visible: 0,
          },
          {
            Header: '착공일자',
            Name: 'lstStrdate',
            Extend: this.$ibsheetHelper.preset.date,
            RelWidth: 1,
            Visible: 0,
          },
          {
            Header: '준공예정일',
            Name: 'lstEnddate',
            Extend: this.$ibsheetHelper.preset.date,
            RelWidth: 1,
            Visible: 0,
          },
          {
            Header: '준공일자',
            Name: 'conCmplDate',
            Extend: this.$ibsheetHelper.preset.date,
            RelWidth: 1,
            Visible: 0,
          },
          {
            Header: '계약금액',
            Name: 'lstCtrtAmt',
            Extend: this.$ibsheetHelper.preset.amount,
            RelWidth: 1,
            Visible: 0,
          },
          {Name: 'conCmplDate', Visible: false},
        ],
        Events: {
          onDataLoad: e => {
            this.$emit('selectedRow', e.sheet.getFirstRow());
          },
          onClick: e => {
            if (e.row.Kind == 'Data') {
              if (!this.isPopup) {
                this.onCtrctInfo(e.row);
                this.onFocusedRowData();
                if (this.callback) {
                  this.callback(e.row);
                }
                this.$emit('selectedRow', e.row);
              }
            }
          },
          onDblClick: e => {
            if (e.row.Kind == 'Data' && this.isPopup) {
              this.callEvent({name: `prjSearchOnConfirm${this.uid}`});
            }
          },
        },
      };

      PMISSheet.createSheet({
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      }).then(sheet => {
        this.sheet = sheet;
        for (let col of this.visibleCols) {
          this.sheet.setAttribute(null, col, 'Visible', 1, 1);
        }

        this.listSearch();
      });
    },

    selectCntrctInfoList(obj) {
      let param = {
        pgmCd: this.pgmCd,
        searchNm: this.searchNm,
        endCntrInc: this.isPopup ? 'N' : this.endCntrInc,
      };

      let prjCd;
      if (typeof obj == 'object') param = obj;
      else prjCd = obj;

      axios
        .post('/cntrctInfoRegist/selectCntrctInfoList', {...param, isPopup: this.isPopup})
        .then(response => {
          this.cntrctInfoList = Array.from(response.data);
          this.sheet.loadSearchData(this.cntrctInfoList, 0);
          if (!this.isPopup) {
            let focusRow;
            if (prjCd) {
              for (let row of this.sheet.getDataRows()) {
                if (row.prjCd == prjCd) {
                  focusRow = row;
                  break;
                }
              }
              setTimeout(() => {
                this.sheet.focus(focusRow);
              }, 100);
            } else {
              focusRow = this.sheet.getRowById('AR1');
            }

            if (focusRow) {
              this.onSearchInfo({prjCd: focusRow.prjCd});
              this.onCtrctInfo(focusRow);
              setTimeout(() => {
                if (this.callback) {
                  this.callback(focusRow);
                }
              }, 50);
            }
          }
        })
        .catch(ex => {
          console.error('ERR!!!!! : ', ex);
        });
    },

    onFocusedRowData(param) {
      let row = this.sheet.getFocusedRow();
      this.onSearchInfo({prjCd: row.prjCd});

      if (typeof param == 'function') {
        param(row);
      }
    },
    onSelectedRowData(callback) {
      if (typeof callback == 'function') {
        var rows = this.sheet.getRowsByChecked('cbx');
        if (rows.length == 0) {
          this.$alert({title: '현장선택', message: '선택된 현장이 없습니다.'});
          return;
        } else if (1 < rows.length) {
          this.$alert({title: '현장선택', message: '하나의 현장만 선택 가능합니다.'});
          return;
        }

        callback(rows);
      }
    },
    setSearchNm(value) {
      this.searchNm = value;
    },
    listSearch() {
      this.selectCntrctInfoList({
        pgmCd: this.pgmCd,
        searchNm: this.searchNm,
        endCntrInc: this.endCntrInc,
      });
    },
  },
};
</script>

<style></style>
