<template>
  <div id="app" class="h_100">
    <Home />
    <iui-alert />
    <iui-confirm />
    <iui-pdf-viewer />
    <pmis-file-progress />
  </div>
</template>

<script>
import Home from '@/view/Home.vue';
import {modalClose} from '@/modalEvent';
export default {
  components: {
    Home,
  },
  name: 'app',
  beforeCreate() {
    history.pushState(null, null, '/Home');
    window.addEventListener('keyup', event => {
      if (event.keyCode === 27) {
        modalClose(this);
      }
    });
  },
};
</script>

<style></style>
