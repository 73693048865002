<template>
  <pmis-content-box>
    <template v-slot:header-right>
      <iui-button @click="goLogin">로그인</iui-button>
    </template>
    <center>
      <iui-container-new type="table" theme="data" width="600px" height="600px" header-width="150px">
        <i-row>
          <i-col-header>
            상담을 원하는<br />
            제품/서비스
          </i-col-header>
          <i-col>
            <iui-container-new type="css-flex">
              <i-row>
                <i-col>
                  <iui-checkbox-group
                    class="product_list"
                    :items="[{label: '전체', value: $getConstants('Y').code}]"
                    :checkedValues="[allProduct]"
                    @change="cbxClick('allProduct', $event)"
                  />
                </i-col>
                <i-col>
                  <iui-checkbox-group
                    class="product_list"
                    :items="[{label: '원가관리', value: $getConstants('Y').code}]"
                    :checkedValues="[consultInfo.costYn]"
                    @change="cbxClick('costYn', $event)"
                  />
                </i-col>
                <i-col>
                  <iui-checkbox-group
                    class="product_list"
                    :items="[{label: '노무관리', value: $getConstants('Y').code}]"
                    :checkedValues="[consultInfo.laboYn]"
                    @change="cbxClick('laboYn', $event)"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col></i-col>
                <i-col>
                  <iui-checkbox-group
                    class="product_list"
                    :items="[{label: '그룹웨어', value: $getConstants('Y').code}]"
                    :checkedValues="[consultInfo.grouYn]"
                    @change="cbxClick('grouYn', $event)"
                  />
                </i-col>
                <i-col>
                  <iui-checkbox-group
                    class="product_list"
                    :items="[{label: '전자조달', value: $getConstants('Y').code}]"
                    :checkedValues="[consultInfo.eproYn]"
                    @change="cbxClick('eproYn', $event)"
                  />
                </i-col>
              </i-row>
            </iui-container-new>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            문의내용
          </i-col-header>
          <i-col>
            <iui-text
              type="multi"
              height="150px"
              @change="setConsultInfo({askDetail: $event.target.value})"
              required
              :error-message="{title: '문의내용', message: '문의내용을 입력해주세요.'}"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            회사명
          </i-col-header>
          <i-col>
            <iui-text
              @input="setConsultInfo({frmNm: $event.target.value})"
              required
              :error-message="{title: '회사명', message: '회사명을 입력해주세요.'}"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            사업자번호
          </i-col-header>
          <i-col>
            <iui-bsn-no :value1.sync="bsnNo1" :value2.sync="bsnNo2" :value3.sync="bsnNo3" required />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            회사 주소(소재지)
          </i-col-header>
          <i-col>
            <iui-text
              @input="setConsultInfo({adr: $event.target.value})"
              required
              :error-message="{title: '회사 주소(소재지)', message: '회사 주소(소재지)를 입력해주세요.'}"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            성명
          </i-col-header>
          <i-col>
            <iui-text
              @input="setConsultInfo({name: $event.target.value})"
              required
              :error-message="{title: '성명', message: '성명을 입력해주세요.'}"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            연락처
          </i-col-header>
          <i-col width="200px">
            <iui-text
              type="number"
              :numberFormat="false"
              :value.sync="phone1"
              width="40px"
              max-length="3"
              required
              :error-message="{title: '연락처', message: '연락처를 입력해주세요.'}"
            />
            <div class="mr5">-</div>
            <iui-text
              type="number"
              :numberFormat="false"
              :value.sync="phone2"
              width="35px"
              max-length="4"
              required
              :error-message="{title: '연락처', message: '연락처를 입력해주세요.'}"
            />
            <div class="mr5">-</div>
            <iui-text
              type="number"
              :numberFormat="false"
              :value.sync="phone3"
              width="45px"
              max-length="4"
              required
              :error-message="{title: '연락처', message: '연락처를 입력해주세요.'}"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            이메일
          </i-col-header>
          <i-col>
            <iui-text
              @input="setConsultInfo({email: $event.target.value})"
              required
              :error-message="{title: '이메일', message: '이메일을 입력해주세요.'}"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            개인정보 수집 및 이용
          </i-col-header>
          <i-col>
            - 수집항목 : 성명, 연락처, 이메일<br />
            - 이용목적 : 상담처리<br />
            - 보유기간 : 상담신청 후 12개월간
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>
            마케팅 활동
          </i-col-header>
          <i-col>
            - 수집항목 : 성명, 연락처, 이메일<br />
            - 이용목적 : 수집된 정보를 활용하여 ?????? 제품/서비스 정보 및 이벤트, 교육을 전화, 문자, 이메일로 안내<br />
            - 보유기간 : 마케팅 활동 동의일로부터 12개월간
          </i-col>
        </i-row>
      </iui-container-new>
      <iui-checkbox-group
        :items="[{label: '개인정보 수집 및 이용 동의', value: $getConstants('Y').code}]"
        @change="cbxClick('persYn', $event)"
        required
        :error-message="{
          title: '개인정보 수집 및 이용 동의',
          message: '개인정보 수집 및 이용을 동의해주시기 바랍니다.',
        }"
      />
      <iui-checkbox-group
        :items="[{label: '마케팅 활동 동의', value: $getConstants('Y').code}]"
        @change="cbxClick('maktYn', $event)"
        required
        :error-message="{
          title: '마케팅 활동 동의',
          message: '마케팅 활동을 동의해주시기 바랍니다.',
        }"
      />
    </center>
    <br />
    <div class="requestBtn">
      <iui-button value="신청하기>" @click="onRequest" />
    </div>
  </pmis-content-box>
</template>

<script>
import {mapMutations} from 'vuex';
import store from '../store/PurchsCnslt.js';

export default {
  data() {
    return {
      allProduct: '',
      bsnNo1: '',
      bsnNo2: '',
      bsnNo3: '',
      phone1: '',
      phone2: '',
      phone3: '',
    };
  },
  computed: {
    bsnNo() {
      return this.bsnNo1 + this.bsnNo2 + this.bsnNo3;
    },
    phone() {
      return this.phone1 + this.phone2 + this.phone3;
    },
  },
  watch: {
    consultInfo: {
      handler(val) {
        if (val.costYn && val.laboYn && val.grouYn && val.eproYn) this.allProduct = $getConstants('Y').code;
        else this.allProduct = '';
      },
      deep: true,
    },
  },
  beforeCreate() {
    this.$store.commit('setCurrentUrl', {path: 'purchsCnsltReqst'});

    $init(this, store);
    $mapGetters(this, ['consultInfo']);
    $mapMutations(this, ['setConsultInfo', 'initConsultInfo']);
  },
  created() {
    this.initConsultInfo();
    this.setConsultInfo({
      costYn: $getConstants('Y').code,
      laboYn: $getConstants('Y').code,
      grouYn: $getConstants('Y').code,
      eproYn: $getConstants('Y').code,
    });
  },
  methods: {
    cbxClick(item, e) {
      let value = e.target.checked ? e.target.value : '';
      let param = {};
      if (item == 'allProduct') {
        this.allProduct = value;
        param['costYn'] = value;
        param['laboYn'] = value;
        param['grouYn'] = value;
        param['eproYn'] = value;
      } else {
        param[item] = value;
      }
      this.setConsultInfo(param);

      document.querySelectorAll('.product_list').forEach(item => {
        item.style =
          !this.consultInfo.costYn && !this.consultInfo.laboYn && !this.consultInfo.grouYn && !this.consultInfo.eproYn
            ? 'border-bottom: 1px solid red;'
            : 'border-bottom: unset';
      });
    },
    async onRequest() {
      const me = this; // 필수
      this.setConsultInfo({reqDt: $_getCurrentDate(), bsnNo: this.bsnNo, phone: this.phone});

      let args = {
        url: '/purchsCnslt/reqst',
        param: {...this.consultInfo},
        title: '구매상담 신청',
        message: '구매상담을 신청하시겠습니까?',
        beforeValidator: function() {
          if (!this.param.costYn && !this.param.laboYn && !this.param.grouYn && !this.param.eproYn) {
            document.querySelectorAll('.product_list').forEach(item => {
              item.style = 'border-bottom: 1px solid red;';
            });
            me.$alert({title: '제품/서비스 선택', message: '상담을 원하는 제품/서비스를 선택해주세요.'});
            return false;
          }
          return true;
        },
        validator: function() {
          // me : VueComponent
          // this : args
          if (this.param.bsnNo.length != 10) {
            me.$alert({title: '사업자번호', message: '사업자번호 10자리를 정확히 입력해주세요.'});
            return false;
          }
          return true;
        },
      };

      this.$save(args).then(response => {
        if (response.status == 200) {
          this.$alert({title: '구매상담 신청완료', message: '구매상담이 신청되었습니다.'});
          this.goLogin();
        }
      });

      // await axios.post('/purchsCnslt/reqst', this.consultInfo).then(response => {
      //   if (response.status == 200) {
      //     this.$alert('구매상담이 신청되었습니다.');
      //     this.goLogin();
      //   }
      // });
    },
  },
};
</script>

<style>
.requestBtn {
  display: flex;
  justify-content: center;
}

.requestBtn > *:last-child {
  margin-right: unset;
}
</style>
