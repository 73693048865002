export default that => {
  return {
    Def: {
      Row: {
        NoColor: 1,
      },
    },
    Cols: [
      {Header: '자원구분', Name: 'costType', Width: 80, CanEdit: false, Align: 'center'},
      {Header: '코드', Name: 'itemCode2', Width: 150, CanEdit: false, Align: 'center'},
      {Header: '명칭', Name: 'itemName', RelWidth: 1, CanEdit: false},
      {Header: '규격', Name: 'ssize', Align: 'center', Width: 150, CanEdit: false},
      {
        Header: '단위',
        Name: 'unit',
        Align: 'center',
        Width: 100,
        CanEdit: false,
        Format: $getConstants('UNIT').code,
      },
      {Name: 'itemCode1', Visible: false},
      {Name: 'itemSeq', Visible: false},
      {Name: 'qty', Visible: false},
      {Name: 'matPrice', Visible: false},
      {Name: 'matAmt', Visible: false},
      {Name: 'labPrice', Visible: false},
      {Name: 'labAmt', Visible: false},
      {Name: 'equipPrice', Visible: false},
      {Name: 'equipAmt', Visible: false},
    ],
  };
};
