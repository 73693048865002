<template>
  <pmis-tab-box>
    <template v-slot:title>상세정보</template>
    <form onsubmit="return false;">
      <iui-container-new type="css-flex">
        <i-row>
          <i-col>
            <iui-container-new type="table" theme="bullet">
              <i-row>
                <i-col-header required>집행일자</i-col-header>
                <i-col>
                  <iui-datepicker
                    :value="detailInfo.workDt"
                    @change="onChange('workDt', $event)"
                    :enable="isNew"
                    required
                    :error-message="{title: '집행일자', message: '집행일자를 선택해야합니다.'}"
                  />
                </i-col>
                <i-spacer />
                <i-col-header>순번</i-col-header>
                <i-col>
                  <iui-text type="smallcode" :value="detailInfo.seq" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>공급가</i-col-header>
                <i-col>
                  <iui-text
                    type="amount"
                    :value="detailInfo.amt"
                    @change="onChange('amt', $event)"
                    :enable="isEnable"
                    required
                    :error-message="{title: '공급가', message: '공급가를 입력해야합니다.'}"
                  />
                </i-col>
                <i-spacer />
                <i-col-header>부가세</i-col-header>
                <i-col>
                  <iui-text
                    type="amount"
                    :value="detailInfo.vatAmt"
                    @change="onChange('vatAmt', $event)"
                    :enable="isEnable"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>비과세</i-col-header>
                <i-col>
                  <iui-text
                    type="amount"
                    :value="detailInfo.taxexe"
                    @change="onChange('taxexe', $event)"
                    :enable="isEnable"
                  />
                </i-col>
                <i-spacer />
                <i-col-header>합계</i-col-header>
                <i-col>
                  <iui-text type="amount" :value="detailInfo.sumAmt" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>거래구분</i-col-header>
                <i-col>
                  <div class="iui-searchbox">
                    <iui-text
                      width="55px"
                      :value="detailInfo.accountGb"
                      :enable="false"
                      required
                      :error-message="{title: '거래구분', message: '거래구분을 선택해야합니다.'}"
                    />
                    <iui-button
                      class="flex-0"
                      btn-class="search-button"
                      @click="onShowModal('accountGbModal')"
                      v-if="isEnable"
                    />
                    <iui-text
                      minWidth="80px"
                      :value="detailInfo.accountGbNm"
                      :enable="false"
                      required
                      :error-message="{title: '거래구분', message: '거래구분을 선택해야합니다.'}"
                    />
                  </div>
                </i-col>
                <i-spacer />
                <i-col-header>증빙</i-col-header>
                <i-col>
                  <template v-if="isHideFileComponent">
                    없음
                  </template>
                  <template v-else>
                    <pmis-file-list
                      id="expenseFile"
                      :file-number="detailInfo.flNo"
                      :modal="true"
                      title="첨부파일"
                      modalTitle="파일선택"
                      modalBtnNm="파일선택"
                      :toolbarVisible="isEnable"
                      @delete-complete="onDeleteComplete"
                    />
                  </template>
                </i-col>
              </i-row>
              <i-row>
                <i-col-header required>거래처</i-col-header>
                <i-col colspan="4">
                  <iui-searchbox
                    type="customer"
                    :idValue="detailInfo.custCode"
                    :nameValue="detailInfo.custName"
                    :callback="onConfirm_Customer"
                    :paramObj="{frmDs: $getConstants('FRM_DS_OPR').code}"
                    :isSearchBtn="isEnable"
                    required
                    :error-message="{title: '거래처', message: '거래처를 선택해야합니다.'}"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>적요</i-col-header>
                <i-col colspan="4" rowspan="2">
                  <iui-text
                    type="multi"
                    :value="detailInfo.rmk"
                    @change="onChange('rmk', $event)"
                    :enable="isEnable"
                    max-length="2000"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col>&nbsp;</i-col>
              </i-row>
            </iui-container-new>
          </i-col>
          <i-spacer />
          <i-col>
            <pmis-content-box>
              <template v-slot:title>
                투입공종
              </template>
              <iui-container-new type="table" theme="bullet">
                <colgroup>
                  <col width="70px" />
                  <col />
                  <col width="5px" />
                  <col width="50px" />
                  <col />
                </colgroup>
                <i-row>
                  <i-col-header>외주</i-col-header>
                  <i-col colspan="4">
                    <div class="iui-searchbox">
                      <iui-text width="100px" :value="detailInfo.subCode" :enable="false" />
                      <iui-button
                        class="flex-0"
                        btn-class="search-button"
                        @click="onShowModal('subCodeModal')"
                        v-if="isEnable"
                      />
                      <iui-text :value="detailInfo.subCodeName" :enable="false" />
                    </div>
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header required>대공종</i-col-header>
                  <i-col colspan="4">
                    <div class="iui-searchbox">
                      <iui-text
                        width="100px"
                        :value="detailInfo.headCode"
                        :enable="false"
                        required
                        :error-message="{title: '대공종', message: '대공종을 선택해야합니다.'}"
                      />
                      <iui-button
                        class="flex-0"
                        btn-class="search-button"
                        @click="onShowModal('executionModal')"
                        v-if="isEnable"
                      />
                      <iui-text
                        :value="detailInfo.lgName"
                        :enable="false"
                        required
                        :error-message="{title: '대공종', message: '대공종을 선택해야합니다.'}"
                      />
                    </div>
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>중공종</i-col-header>
                  <i-col colspan="4">
                    <iui-text width="100px" :value="detailInfo.midCode" :enable="false" />
                    <iui-text :value="detailInfo.mgName" :enable="false" />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>내역</i-col-header>
                  <i-col colspan="4">
                    <iui-text :value="detailInfo.itemName" :enable="false" />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>규격</i-col-header>
                  <i-col>
                    <iui-text :value="detailInfo.ssize" :enable="false" />
                  </i-col>
                  <i-spacer />
                  <i-col-header>단위</i-col-header>
                  <i-col>
                    <iui-text :value="detailInfo.unitNm" :enable="false" />
                  </i-col>
                </i-row>
              </iui-container-new>
            </pmis-content-box>
          </i-col>
        </i-row>
      </iui-container-new>
    </form>

    <iui-modal name="accountGbModal" title="거래구분" :btns="modalBtns" sizeType="size1">
      <AccountGbPopup :uid="_uid" />
    </iui-modal>

    <iui-modal name="executionModal" title="실행내역" sizeType="size3" :btns="modalBtns">
      <Execution :uid="_uid" />
    </iui-modal>

    <iui-modal name="subCodeModal" title="외주선택" :btns="modalBtns" sizeType="size3">
      <SubCustListPopup callback-type="2" />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import AccountGbPopup from './popup/AccountGbPopup.vue';
import Execution from '@/view/executDtls/components/popup/ExecutionDetailPopup.vue';
import SubCustListPopup from '@/view/Resource/components/SubCustListPopup.vue';
import {selectExpenseInfo, saveExptDailyReport, deleteExptDailyReport} from '../api/expenseRegist.js';
import {selectMonthClosePossibleAt} from '@/view/closing/expitmCloseRegist/api/expitmCloseRegist.js';

export default {
  components: {
    Execution,
    AccountGbPopup,
    SubCustListPopup,
  },
  created() {
    this.addEvent([
      {name: 'DetailInfo_Init', func: this.onInit},
      {name: 'DetailInfo_Search', func: this.onSearch},
      {name: 'onHideModal', func: this.onConfirmModal},
    ]);

    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },
  data() {
    return {
      isNew: false,

      detailInfo: {
        workDt: '',
        seq: '',
        amt: '',
        vatAmt: '',
        taxexe: '',
        sumAmt: '',
        accountGb: '',
        accountGbNm: '',
        custCode: '',
        custName: '',
        flNo: 0,
        rmk: '',

        subCode: '',
        subCodeName: '',
        poNoSubc: '',

        wcode: '',
        headCode: '',
        midCode: '',
        itemSeq: '',
        itemCode: '',

        lgName: '',
        mgName: '',
        itemName: '',

        ssize: '',
        unit: '',
        unitNm: '',

        closeYn: '',
        rgprId: '',
      },

      modalBtns: [{name: '확인', callback: this.onConfirmModal}],
      modalNm: '',
    };
  },
  methods: {
    async onSearch(param) {
      const response = await selectExpenseInfo(param);
      for (let key in this.detailInfo) {
        this.detailInfo[key] = response.data[key];
      }
      $getCodeNm($getConstants('ACCOUNT_GB').code, this.detailInfo.accountGb).then(response => {
        this.detailInfo['accountGbNm'] = response;
      });
      $getCodeNm($getConstants('UNIT').code, this.detailInfo.unit).then(response => {
        this.detailInfo['unitNm'] = response;
      });
    },
    onInit() {
      for (let key in this.detailInfo) {
        this.detailInfo[key] = '';
      }
      this.detailInfo.flNo = 0;
      this.detailInfo.workDt = $_getCurrentDate();
      this.isNew = false;
    },
    onAdd(param) {
      this.onInit(param);
      this.isNew = true;
    },
    async onChange(key, event) {
      const value = key == 'workDt' ? event : event.target.value;
      this.detailInfo[key] = value;

      let amt = Number(this.detailInfo.amt || 0);
      let vatAmt = Number(this.detailInfo.vatAmt || 0);
      let taxexe = Number(this.detailInfo.taxexe || 0);

      if (key == 'workDt') {
        if (!$_bNowdateOverCheck(value)) {
          this.$alert({title: '집행일자', message: '금일 이후 일자 경비집행정보 등록은 불가능 합니다.'}, () => {
            this.detailInfo.workDt = '';
          });
          return;
        }

        const param = {closeMm: this.detailInfo.workDt.substring(0, 6)};
        const response = await selectMonthClosePossibleAt(param);
        if (response.data.CHECK1 == 'N') {
          this.$alert({title: '월마감', message: '월마감 상태이므로 경비집행정보 등록은 불가합니다.'}, () => {
            this.detailInfo.workDt = '';
          });
          return;
        }
      } else if (key == 'amt') {
        vatAmt = $_getVatAmt(value, taxexe);
      } else if (key == 'taxexe') {
        vatAmt = $_getVatAmt(amt, value);
      }

      this.detailInfo.vatAmt = vatAmt;
      const sumAmt = $_getSumAmt(amt, vatAmt) || '';
      this.detailInfo.sumAmt = sumAmt;
    },
    onShowModal(modalNm) {
      this.$store.commit('setModalParam', {
        costType: 'A',
      });
      this.modalNm = modalNm;
      this.$modal.show(this.modalNm);
    },
    onConfirm_Customer(e) {
      this.detailInfo.custCode = e.bsnNo;
      this.detailInfo.custName = e.frmNm;
    },
    onConfirmModal() {
      if (this.modalNm == 'accountGbModal') {
        // 거래구분
        this.callEvent({
          name: 'AccountGbPopup_callbackData',
          param: data => {
            this.detailInfo.accountGb = data.cmnDCd;
            this.detailInfo.accountGbNm = data.cmnDNm;
            this.$modal.hide(this.modalNm);
          },
        });
      } else if (this.modalNm == 'subCodeModal') {
        // 외주
        this.callEvent({
          name: 'SubCustListPopup_callbackData',
          param: data => {
            this.detailInfo.subCode = data.cusCd;
            this.detailInfo.subCodeName = data.cusNm;
            this.detailInfo.poNoSubc = data.poNo;

            this.detailInfo.wcode = data.wcode;
            this.detailInfo.headCode = data.headCode;
            this.detailInfo.lgName = data.headName;
            this.detailInfo.midCode = data.midCode;
            this.detailInfo.mgName = data.midName;
            this.detailInfo.itemSeq = data.itemSeq;
            this.detailInfo.itemName = data.itemName;
            this.detailInfo.itemCode = data.itemCode;
            this.detailInfo.ssize = data.ssize;
            this.detailInfo.unitNm = data.unitNm;
            this.$modal.hide(this.modalNm);
          },
        });
      } else if (this.modalNm == 'executionModal') {
        // 대공종
        this.callEvent({
          name: 'executionDetailPopup_callbackData',
          param: data => {
            this.detailInfo.subCode = '';
            this.detailInfo.subCodeName = '';
            this.detailInfo.poNoSubc = '';

            this.detailInfo.wcode = data.wcode;
            this.detailInfo.headCode = data.headCode;
            this.detailInfo.lgName = data.headName;
            this.detailInfo.midCode = data.midCode;
            this.detailInfo.mgName = data.midName;
            this.detailInfo.itemSeq = data.itemSeq;
            this.detailInfo.itemName = data.itemName;
            this.detailInfo.itemCode = data.itemCode;
            this.detailInfo.ssize = data.ssize;
            this.detailInfo.unitNm = data.unit;
            this.$modal.hide(this.modalNm);
          },
        });
      }
    },
    async onSave() {
      try {
        if (!this.onValidate(1)) {
          return true;
        }
        const param = {...this.detailInfo};
        const response = await saveExptDailyReport(param);
        if (response.status !== 999) {
          this.callEvent({name: 'ExpenseList_ReSearch', param: response.data});
        }
      } catch (error) {
        this.$alert({title: '저장', message: error.message});
      }
    },
    async onDelete() {
      if (!this.onValidate(2)) {
        return true;
      }
      const title = '경비내역삭제';
      const message = '경비내역을 삭제하시겠습니까?';
      if (!(await this.$confirm({title: title, message: message}))) {
        return;
      }
      try {
        const param = {...this.detailInfo};
        const response = await deleteExptDailyReport(param);
        if (response.data) {
          this.callEvent({name: 'ExpenseList_ReSearch', param: undefined});
        }
      } catch (error) {
        this.$alert({title: '삭제', message: error.message});
      }
    },
    onValidate(gubun) {
      if (this.isClose) {
        this.$alert({title: '월마감', message: `월 마감상태입니다. ${gubun == 1 ? '저장' : '삭제'}할 수 없습니다.`});
        return false;
      }
      return true;
    },
    onDeleteComplete(flNo) {
      if (flNo) return;

      this.$save({
        url: '/expenseRegist/updateFlNo',
        param: {
          workDt: this.detailInfo.workDt,
          seq: this.detailInfo.seq,
          flNo,
        },
      })
        .then(response => {
          if (response.status == 200) {
            this.detailInfo.flNo = flNo;
          }
        })
        .catch(e => {
          this.$alert({title: '파일저장', message: '파일저장을 실패했습니다.'});
        });
    },
  },
  computed: {
    isClose() {
      return this.detailInfo.closeYn != '';
    },
    isNotClose() {
      return !this.isClose;
    },
    isEnable() {
      return (this.isNew || (!this.isNew && this.detailInfo.workDt != '')) && this.isNotClose;
    },
    isHideFileComponent() {
      return !this.detailInfo.flNo && this.isClose;
    },
  },
};
</script>

<style></style>
