<template>
  <pmis-page :searchProjectVisible="false" :search-company-visible="false" @save-click="onSave">
    <CompanyDetail :enable="true" />
  </pmis-page>
</template>

<script>
import CompanyDetail from '@/view/member/components/CompanyDetail.vue';
import store from '@/view/member/store/member.js';
import {saveCompanyInfo} from '../api/member.js';
export default {
  components: {
    CompanyDetail,
  },

  data() {
    return {
      loading: false,
      param: {},
    };
  },

  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['companyInfo', 'worker', 'dupBsnNoStatus', 'ceoList']);
    $mapMutations(this, [
      'initCompanyInfo',
      'setCompanyInfo',
      'setWorker',
      'setAreaList',
      'setMatList',
      'setGcodeList',
      'setLicenseList',
      'setDocnmList',
      'setCeoList',
    ]);
  },
  mounted() {
    this.callEvent({name: 'selectCompanyInfo'});
    this.canNew = false;
  },

  methods: {
    onSave() {
      this.onSaveCompany();
    },

    onDetailLoading(isLoading) {
      this.loading = isLoading;
    },

    onFileSaveComplete() {
      this.setCompanyInfo({flNo: this.fileList_fileNumber});
    },

    async onSaveCompany() {
      this.beforeSaveCompany();
    },

    async beforeSaveCompany() {
      // 관인/로고/심볼 파일 업로드

      if (this.companyInfo.frmGb == $getConstants('FRM_GB_3').code) {
        // 자재
        //납품지역
        this.callEvent({
          name: 'areaInfo_getSaveJson',
          param: data => {
            data.forEach(el => (el.pgmCd = this.pgmCd));
            this.param['areaList'] = $_statusToCud(data);
          },
        });
        let validItem = this.param['areaList'].find(item => !item.siteArea2);
        if (validItem !== undefined) {
          this.$alert({title: `납품지역정보 저장`, message: `납품지역을 선택 해야합니다.`});
          return;
        }
        // 공급물품정보
        this.callEvent({
          name: 'mtrilInfo_getSaveJson',
          param: data => {
            data.forEach(el => (el.pgmCd = this.pgmCd));
            this.param['matList'] = $_statusToCud(data);
          },
        });
      } else if (this.companyInfo.frmGb == $getConstants('FRM_GB_2').code) {
        // 외주
        // 공사지역
        this.callEvent({
          name: 'areaInfo_getSaveJson',
          param: data => {
            data.forEach(el => (el.pgmCd = this.pgmCd));
            this.param['areaList'] = $_statusToCud(data);
          },
        });
        let validItem = this.param['areaList'].find(item => !item.siteArea2);
        if (validItem !== undefined) {
          this.$alert({title: `공사지역정보 저장`, message: `공사지역을 선택 해야합니다.`});
          return;
        }
        // 면허(세부)정보
        this.callEvent({
          name: 'workTypeInfo_getSaveJson',
          param: data => {
            data.forEach(el => (el.pgmCd = this.pgmCd));
            this.param['gcodeList'] = $_statusToCud(data);
          },
        });
        validItem = this.param['gcodeList'].find(item => !item.wcode);
        if (validItem !== undefined) {
          this.$alert({title: '보유면허정보(세부공종) 삭제', message: '전문공종을 선택 해야합니다.'});
          return;
        }
      } else {
        // 건설사
        //보유면허정보
        this.callEvent({
          name: 'holdLcnsInfo_getSaveJson',
          param: data => {
            data.forEach(el => (el.pgmCd = this.pgmCd));
            this.param['licenseList'] = $_statusToCud(data);
          },
        });
        const title = '보유면허정보 저장';
        let validItem = this.param['licenseList'].find(item => !item.conSpDs);
        if (validItem !== undefined) {
          this.$alert({title, message: '구분을 선택 해야합니다.'});
          return;
        }

        validItem = this.param['licenseList'].find(item => !item.wcode);
        if (validItem !== undefined) {
          this.$alert({title, message: '업종을 선택 해야합니다.'});
          return;
        }

        validItem = this.param['licenseList'].find(item => !item.licenseNo);
        if (validItem !== undefined) {
          this.$alert({title, message: '등록번호를 입력 해야합니다.'});
          return;
        }

        validItem = this.param['licenseList'].find(item => !item.licenseOrg);
        if (validItem !== undefined) {
          this.$alert({title, message: '발급기관을 입력 해야합니다.'});
          return;
        }

        validItem = this.param['licenseList'].find(item => !item.licenseDate);
        if (validItem !== undefined) {
          this.$alert({title, message: '취득일자를 입력 해야합니다.'});
          return;
        }
      }
      // 발신명의정보
      let callback;
      this.callEvent({
        name: 'docManageInfoGetSaveJson',
        param: (data, seq) => {
          const docnmList = $_statusToCud(data).map(item => {
            return {
              pgmCd: item.pgmCd ? item.pgmCd : this.companyInfo.pgmCd,
              seq: item.seq,
              docNm: item.docNm,
              cud: item.cud,
            };
          });

          callback = (fileInfo, param) => {
            if (fileInfo.file0) {
              param.flNo = fileInfo.file0.flNo;
            }
            if (fileInfo.file2) {
              param.docnmList.some(item => {
                if (Number(item.seq) === Number(seq)) {
                  item[fileInfo.file2.flNoCol] = fileInfo.file2.flNo;
                  return true;
                }
              });
            }
            if (fileInfo.file3) {
              param.lgFlNo = fileInfo.file3.flNo;
            }
            if (fileInfo.file4) {
              param.smFlNo = fileInfo.file4.flNo;
            }
            return param;
          };
          this.param['docnmList'] = docnmList;
        },
      });
      const validItem = this.param['docnmList'].find(item => !item.docNm);
      if (validItem !== undefined) {
        this.$alert({title: '발신명의 저장', message: '발신명의를 입력해야 합니다.'});
        return;
      }

      // 공동대표정보
      this.param['ceoList'] = this.ceoList;
      this.saveCompany(callback);
    },
    async saveCompany(callback) {
      this.param = {...this.param, ...this.companyInfo, worker: this.worker, pgmCd: this.pgmCd, cud: this.cud};
      const response = await saveCompanyInfo(this.param, {callback});
      if (response.status == 200) this.callEvent({name: 'selectCompanyInfo'});
    },
  },
};
</script>

<style></style>
