export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 0},
    Def: {Row: {NoColor: 1, CanFocus: 0}},
    Cols: [
      {Header: '일자', Name: 'workDt', Width: 90, Align: 'center'},
      {Header: '명칭', Name: 'itemNm', RelWidth: 1},
      {Header: '규격', Name: 'sizeNm', RelWidth: 1},
      {Header: '수량', Name: 'qty', Width: 90, Extend: preset.quantity},
      {Header: '단가', Name: 'unitPrc', RelWidth: 1, Extend: preset.amount},
      {Header: '금액', Name: 'amt', RelWidth: 1, Extend: preset.amount},
    ],
  };
};
