<template>
  <pmis-content-box>
    <template v-slot:title>
      일위대가내역
    </template>
    <template v-slot:header-left>
      <iui-text type="search" :value.sync="originItemCode" @enter="breakdownSearch" />
      <iui-button value="검색" @click="breakdownSearch" />
      <span class="mr10"></span>
      <a
        href="#"
        @click.prevent.self
        v-for="(item, index) in itemQueue"
        :key="index"
        @click="setItemIndex(index)"
        v-html="itemTag(item, index)"
      ></a>
      <a href="#" @click.prevent.self @click="clearSearch" v-if="itemQueue.length">X</a>
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onClick}"
    />
  </pmis-content-box>
</template>

<script>
import options from '@/view/common/sheetOption/BreakdownDtlsSheet.js';
import {
  selectPrjBreakdownCostList,
  selectPrjBreakdownCostListDtls,
} from '@/view/estmtManage/estmtDtlsManage/prjBreakdownCost/api/prjBreakdownCost.js';
export default {
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      searchText: '',
      originItemCode: '',
      originItemName: '',
      originSsize: '',
      originQty: 0,
      originMatPrice: 0,
      originMatAmt: 0,
      originLabPrice: 0,
      originLabAmt: 0,
      originEquipPrice: 0,
      originEquipAmt: 0,
      originTotalPrice: 0,
      originTotalAmt: 0,
      childItemCode: '',
      childItemName: '',
      childSsize: '',
      childQty: 0,
      childMatPrice: 0,
      childMatAmt: 0,
      childLabPrice: 0,
      childLabAmt: 0,
      childEquipPrice: 0,
      childEquipAmt: 0,
      childTotalPrice: 0,
      childTotalAmt: 0,
      itemQueue: [],
      itemIndex: 0,
    };
  },
  computed: {
    itemCode() {
      return this.isChild ? this.childItemCode : this.originItemCode;
    },
    itemName() {
      return this.isChild ? this.childItemName : this.originItemName;
    },
    ssize() {
      return this.isChild ? this.childSsize : this.originSsize;
    },
    unit() {
      return this.isChild ? this.childUnit : this.originUnit;
    },
    qty() {
      return this.isChild ? this.childQty : this.originQty;
    },
    matPrice() {
      return this.isChild ? this.childMatPrice : this.originMatPrice;
    },
    matAmt() {
      return this.isChild ? this.childMatAmt : this.originMatAmt;
    },
    labPrice() {
      return this.isChild ? this.childLabPrice : this.originLabPrice;
    },
    labAmt() {
      return this.isChild ? this.childLabAmt : this.originLabAmt;
    },
    equipPrice() {
      return this.isChild ? this.childEquipPrice : this.originEquipPrice;
    },
    equipAmt() {
      return this.isChild ? this.childEquipAmt : this.originEquipAmt;
    },
    totalPrice() {
      return this.isChild ? this.childTotalPrice : this.originTotalPrice;
    },
    totalAmt() {
      return this.isChild ? this.childTotalAmt : this.originTotalAmt;
    },
    isChild() {
      return this.itemQueue.length > 0;
    },
  },
  created() {
    this.addEvent([
      {name: 'setBreakDownData', func: this.setData},
      {name: 'initBreakDownData', func: this.init},
    ]);
  },
  methods: {
    itemTag(item, index) {
      let separator = index !== 0 ? '>' : '';
      let selected = index === this.itemIndex;
      return `${separator} ${selected ? '<b>' : ''}${item.itemCode2}${selected ? '</b>' : ''}`;
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    setData(param) {
      this.clearItem();
      if (param.costType === 'I') {
        this.originItemCode = param.itemCode;
        this.originItemName = param.itemName;
        this.originSsize = param.ssize;
        this.originUnit = param.unit;
        this.originQty = param.qty;
        this.originMatPrice = param.matPrice;
        this.originMatAmt = param.matAmt;
        this.originLabPrice = param.labPrice;
        this.originLabAmt = param.labAmt;
        this.originEquipPrice = param.equipPrice;
        this.originEquipAmt = param.equipAmt;
        this.originTotalPrice = param.totalPrice;
        this.originTotalAmt = param.totalAmt;
        this.onSearch();
      } else {
        this.originItemCode = '';
        this.originItemName = '';
        this.originSsize = '';
        this.originUnit = '';
        this.originQty = 0;
        this.originMatPrice = 0;
        this.originMatAmt = 0;
        this.originLabPrice = 0;
        this.originLabAmt = 0;
        this.originEquipPrice = 0;
        this.originEquipAmt = 0;
        this.originTotalPrice = 0;
        this.originTotalAmt = 0;
        this.sheet?.removeAll();
      }
    },
    pushItem(row) {
      if (this.itemQueue.findIndex(item => String(item.itemCode2) === String(row.itemCode2)) !== -1) {
        return;
      }
      this.itemQueue.push({
        itemCode2: row.itemCode2,
        itemName: row.itemName,
        ssize: row.ssize,
        unit: row.unit,
        qty: row.qty,
        matPrice: row.matPrice,
        matAmt: row.matAmt,
        labPrice: row.labPrice,
        labAmt: row.labAmt,
        equipPrice: row.equipPrice,
        equipAmt: row.equipAmt,
        totalPrice: row.totalPrice,
        totalAmt: row.totalAmt,
      });
      this.itemIndex = this.itemQueue.length - 1;
      this.setChildData();
    },
    setItemIndex(index) {
      this.itemIndex = index;
      this.setChildData();
    },
    init() {
      const originData = this.$options.data.call(this);
      for (let key in originData) {
        if (key === 'sheet') {
          continue;
        }
        this.$data[key] = originData[key];
      }
    },
    clearItem() {
      this.itemQueue = [];
      this.setChildData();
    },
    clearSearch() {
      this.clearItem();
      this.onSearch();
    },
    breakdownSearch() {
      this.clearItem();
      const callback = async param => {
        if (!param) {
          this.selectPrjBreakdownCostList();
        } else {
          this.setData(param);
        }
      };

      this.callEvent({name: 'findBreakdown', param: {itemCode: this.originItemCode, callback: callback}});
    },
    async selectPrjBreakdownCostList() {
      const response = await selectPrjBreakdownCostList({itemCode: this.originItemCode});
      if (response.data?.length) {
        const data = response.data[0];
        this.originItemName = data.itemName;
        this.originSsize = data.ssize;
        this.originUnit = data.unit;
        this.originQty = 1;
        this.originMatPrice = data.matAmt;
        this.originMatAmt = data.matAmt;
        this.originLabPrice = data.labAmt;
        this.originLabAmt = data.labAmt;
        this.originEquipPrice = data.equipAmt;
        this.originEquipAmt = data.equipAmt;
        this.originTotalPrice = data.totalAmt;
        this.originTotalAmt = data.totalAmt;
        this.onSearch();
        return;
      }
      this.sheet.removeAll();
    },
    setChildData() {
      const item = this.itemQueue[this.itemIndex];
      if (item) {
        this.childItemCode = item.itemCode2;
        this.childItemName = item.itemName;
        this.childSsize = item.ssize;
        this.childUnit = item.unit;
        this.childQty = item.qty;
        this.childMatPrice = item.matPrice;
        this.childMatAmt = item.matAmt;
        this.childLabPrice = item.labPrice;
        this.childLabAmt = item.labAmt;
        this.childEquipPrice = item.equipPrice;
        this.childEquipAmt = item.equipAmt;
        this.childTotalPrice = item.totalPrice;
        this.childTotalAmt = item.totalAmt;
        this.onSearch();
      } else {
        this.childItemCode = '';
        this.childItemName = '';
        this.childSsize = '';
        this.childUnit = '';
        this.childQty = 0;
        this.childMatPrice = 0;
        this.childMatAmt = 0;
        this.childLabPrice = 0;
        this.childLabAmt = 0;
        this.childEquipPrice = 0;
        this.childEquipAmt = 0;
        this.childTotalPrice = 0;
        this.childTotalAmt = 0;
      }
    },

    async onSearch() {
      const response = await selectPrjBreakdownCostListDtls({itemCode1: this.itemCode});
      const list = [];
      if (response.data.length) {
        list.push({
          Color: '#28719f',
          TextColor: '#ddd',
          itemCode2: this.itemCode,
          itemName: this.itemName,
          ssize: this.ssize,
          unit: this.unit,
          qty: this.qty,
          matPrice: this.matPrice,
          matAmt: this.matAmt,
          labPrice: this.labPrice,
          labAmt: this.labAmt,
          equipPrice: this.equipPrice,
          equipAmt: this.equipAmt,
          totalPrice: this.totalPrice,
          totalAmt: this.totalAmt,
        });
      }

      this.loadSearchData = list.concat(response.data);
    },
    onClick(e) {
      if (e.kind !== 'Data') {
        return;
      }
      if (e.row.costType !== $getConstants('COST_TYPE_I').code) {
        return;
      }
      this.pushItem(e.row);
    },
  },
};
</script>

<style></style>
