<template>
  <pmis-page
    ref="page"
    :search-project-visible="false"
    :search-company-visible="false"
    @search-click="search_click"
    @new-click="new_click"
    @save-click="save_click"
    @delete-click="delete_click"
  >
    <iui-tab
      ref="tab"
      :comps="tabList"
      :keep-alive="true"
      @click="tab_click"
      @after-active-tab="tab_afterActive"
    ></iui-tab>
  </pmis-page>
</template>

<script>
export default {
  data() {
    return {
      tabList: [
        {tabNm: '공통결재선', compNm: 'CommonLine', path: '@/view/approval/line/components/Line.vue'},
        {tabNm: '개인결재선', compNm: 'PrivateLine', path: '@/view/approval/line/components/Line.vue'},
      ],
      activeTabIndex: 0,
    };
  },
  methods: {
    async search_click() {
      this.$refs.tab.$children[this.activeTabIndex].snclDs = this.activeTabIndex;
      this.$refs.tab.$children[this.activeTabIndex].search = true;
    },
    new_click() {
      this.$refs.tab.$children[this.activeTabIndex].new = true;
    },
    save_click() {
      this.$refs.tab.$children[this.activeTabIndex].save = true;
    },
    delete_click() {
      this.$refs.tab.$children[this.activeTabIndex].delete = true;
    },
    tab_click(e) {
      this.activeTabIndex = e;
    },
    async tab_afterActive() {
      await this.$nextTick();
      this.search_click();
    },
  },
};
</script>

<style></style>
