<template>
  <pmis-content-box>
    <form onsubmit="return false;">
      <iui-container-new type="table" theme="bullet">        
        <i-row>
          <i-col-header>코드</i-col-header>
          <i-col>
            <iui-text
              type="smallcode"
              name="fldrCd"
              :bindArray="bindArray"
              :value="detailInfo.fldrCd"
              max-length="30"
              :enable="!isRoot"
            ></iui-text>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>명칭</i-col-header>
          <i-col>
            <iui-text
              type="text"
              name="fldrNm"
              :bindArray="bindArray"
              :value="detailInfo.fldrNm"
              max-length="100"
              width="300px"
              :enable="!isRoot"
            ></iui-text>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>상위코드</i-col-header>
          <i-col>
            <iui-text
              type="smallcode"
              name="uppFldrCd"
              :bindArray="bindArray"
              :value="detailInfo.uppFldrCd"
              :enable="false"
            ></iui-text>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>상위명칭</i-col-header>
          <i-col>
            <iui-text
              type="text"
              name="uppFldrNm"
              :bindArray="bindArray"
              :value="detailInfo.uppFldrNm"
              :enable="false"
              width="300px"
            ></iui-text>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>물량분개비율</i-col-header>
          <i-col>
            <iui-text
              type="rate"
              name="plnWgh"
              :bindArray="bindArray"
              :value="detailInfo.plnWgh"
              :suffix="'%'"
              :enable="detailInfo.isPlnWgh ? true : false"
            ></iui-text>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>메모</i-col-header>
          <i-col>
            <iui-text
              type="multi"
              name="rmk"
              :bindArray="bindArray"
              :value="detailInfo.rmk"
              :enable="!isRoot"
              max-length="2000"
            ></iui-text>
          </i-col>
        </i-row>
      </iui-container-new>
    </form>
  </pmis-content-box>
</template>

<script>
export default {
  data() {
    return {
      bindArray: ['setDetailInfo'],
    };
  },
  beforeCreate() {
    $mapGetters(this, ['detailInfo', 'isRoot']);
  },
};
</script>

<style scoped></style>
