<template>
  <pmis-content-box :loading="loading">
    <template v-slot:header-left>
      <iui-text type="search" :value.sync="searchNm" @enter="searchByItemNm" />
      <iui-button value="검색" @click="searchByItemNm" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onSearchFinish: onSearchFinish,
        onClick: onClick,
      }"
    />
  </pmis-content-box>
</template>

<script>
import {selectContEmCodeList} from '../api/prjWorkTypeRegist.js';
import options from './sheetOption/ContEmCodeListSheet.js';
export default {
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
    search: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      Options: options(this),
      loadSearchData: [],
      searchNm: '',
    };
  },
  watch: {
    search(value) {
      if (value) {
        this.onSearch();
      }
    },
  },
  created() {
    this.addFuncPrj(this.onSearch);
  },
  methods: {
    async onSearch() {
      this.loading = true;
      this.sheet?.removeAll();
      const prjCd = await this.getPrjCd();
      const param = {
        prjCd: prjCd,
      };
      const response = await selectContEmCodeList(param);
      let treeList = response.data.find(item => item.code === prjCd);
      if (treeList) {
        treeList.Items = Array.from(response.data).filter((e, i) => i != 0);
        treeList.Items = await $_treeModel(
          Array.from(response.data).filter((e, i) => i != 0),
          prjCd,
          'code',
          'upCode'
        );
        this.loadSearchData = new Array(treeList);
      }
      this.loading = false;
      this.$emit('search', false);
    },
    loadSheet(sheet) {
      this.$emit('loadSheet', sheet);
      this.sheet = sheet;
      sheet.util.onTreeCheck('chk');
    },
    onSearchFinish(e) {
      this.loading = false;
      if (0 < e.sheet.getTotalRowCount()) {
        e.sheet.showTreeLevel(3, 0, 1);
      }
      this.callback(e.sheet.getFirstRow(), true);
      this.$emit('getDataRows', e.sheet?.getDataRows());
    },
    onClick(e) {
      this.setCud('0');
      this.initSearchNm();
      this.callback(e.row, false);
    },
    initSearchNm() {
      this.searchNm = '';
    },
    searchByItemNm() {
      this.$emit('searchByItemNm', this.searchNm);
      this.sheet.util.treeSearch(['name', 'code'], this.searchNm);
      setTimeout(() => {
        this.callback(this.sheet.getFocusedRow(), false);
      }, 100);
    },
  },
};
</script>

<style></style>
