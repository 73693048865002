<template>
  <!-- 장비투입(현장)-포틀릿 -->
  <div>
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr>
          <th>장비명</th>
          <th>차량번호</th>
          <th>거래처명</th>
          <th>가동시간</th>
          <th>가동금액</th>
        </tr>
      </thead>
    </table>
    <div class="inner" style="height:58px">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="(data, index) in equipInputList" :key="index">
            <td>{{ data.equipNm }}</td>
            <td>{{ data.carNo }}</td>
            <td>{{ data.custNm }}</td>
            <td>{{ data.mdRunQty }}</td>
            <td class="p_last">{{ data.mdRunAmt }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
import {selectEquipInput1} from '@/components/portlet/api/portletData.js';
export default {
  mixins: [PortletMixin],
  props: {
    curDate: {
      Type: String,
    },
  },
  data() {
    return {
      cnt: 5,
      equipInputList: [],
    };
  },
  watch: {
    curDate() {
      this.onSearch();
    },
  },
  methods: {
    setWidth() {
      this.tblWidth = this.$el.offsetWidth - 28;
    },
    onSearch() {
      selectEquipInput1({prjCd: this.prjCd, runDt: this.curDate}).then(
        response => (this.equipInputList = response.data)
      );
    },
  },
};
</script>

<style></style>
