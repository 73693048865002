<template>
  <pmis-content-box>
    <ib-sheet :uid="_uid" :options="options" :loadSearchData="calculateList" :events="{onClick: onClick}" />
    <pmis-file-list
      ref="file"
      id="file"
      title="증빙(첨부파일)"
      :modal="true"
      :hidden="true"
      :saveAuth="false"
      :file-number.sync="flNo"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/ExptTargetListSheet.js';

export default {
  data() {
    return {
      options: options(this),
      flNo: 0,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['calculateList']);
  },
  methods: {
    onClick(e) {
      if (e.col == 'filePopup' && e.cellType == 'Button') {
        this.flNo = e.row.flNo;
        this.$refs.file.openModal();
      }
    },
  },
};
</script>

<style scoped></style>
