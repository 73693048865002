<template>
  <ib-sheet
    :uid="_uid"
    :options="Options"
    :loadSearchData="loadSearchData"
    :events="{
      onRenderFirstFinish,
    }"
  />
</template>

<script>
import options from './sheetOptions/WorkGroupSheet.js';
import {selectWorkGroupList} from '../api/workdaily.js';

export default {
  data() {
    return {
      Options: options(this),
      loadSearchData: [],
      responseData: {
        sheet: [],
      },
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo']);
  },
  created() {
    this.addEvent([
      {name: 'WorkGroup_onSearch', func: this.onSearch},
      {
        name: 'WorkGroup_onAmountChange',
        func: this.convertPriceUnit,
      },
    ]);
  },
  methods: {
    onRenderFirstFinish(e) {
      e.sheet.setAutoMerge(1, 5);
    },
    async onSearch(amountUnit) {
      const response = await selectWorkGroupList({...this.searchInfo});
      this.responseData.sheet = response.data;
      this.convertPriceUnit(amountUnit);
    },
    convertPriceUnit(value) {
      this.loadSearchData = $_priceUnitFormat(value, this.responseData.sheet);
    },
  },
};
</script>
