<template>
  <pmis-content-box>
    <template v-slot:title>정산금액</template>
    <template v-slot:title-right>
      <label v-if="investMmList.length != 0">투입년월</label>
      <span class="ml5"></span>
      <iui-select v-if="investMmList.length > 1" :items="investMmList" @change="onChangeInvestMm" />
      <label v-if="investMmList.length == 1">{{ investMmList[0].value }}</label>
      <span v-if="investMmList.length == 1" class="ml5"></span>
      <span class="ml5"></span>
    </template>
    <ib-sheet :uid="_uid" :options="options" :loadSearchData="calculateList" :events="{onAfterClick: onAfterClick}" />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/LabTargetListSheet.js';
export default {
  data() {
    return {
      options: options(this),
    };
  },
  beforeCreate() {
    $mapGetters(this, 'labor', ['searchInfo', 'calculateList', 'taxRtInfo', 'investMmList']);
    $mapMutations(this, 'labor', ['setCloseList']);
    $mapActions(this, 'labor', ['setCalculateAmt', 'changeCalculateList']);
  },
  methods: {
    onAfterClick(e) {
      if (['closeYn1', 'closeYn2'].indexOf(e.col) !== -1 && this.cud === 1) {
        const rows1 = e.sheet.getRowsByChecked('closeYn1');
        const rows2 = e.sheet.getRowsByChecked('closeYn2');

        let rows = this.filterRowAsClose(rows1, '1').concat(this.filterRowAsClose(rows2, '2'));
        let closeList = rows.map(row => ({investDt: String(row.investDt)}));
        this.setCloseList(closeList);
        let sumList = rows.reduce(
          (acc, row) => ({
            monthlyAmt: acc.monthlyAmt + row.amt,
            monthlyQty: acc.monthlyQty + row.investQty,
            incomedcTax:
              acc.incomedcTax + (row.amt > this.taxRtInfo.incomedcAmt ? row.amt - this.taxRtInfo.incomedcAmt : 0),
          }),
          {
            monthlyAmt: 0,
            monthlyQty: 0,
            incomedcTax: 0,
          }
        );
        this.setCalculateAmt({
          ...sumList,
          monthlyDay: rows.length,
        });
      }
    },
    filterRowAsClose(rows, no) {
      const filterWord = [`investDt${no}`, `investQty${no}`, `amt${no}`];
      return rows.map(row => {
        let map = {};
        for (let [key, value] of Object.entries(row)) {
          if (filterWord.indexOf(key) !== -1) {
            map[key.substring(0, key.length - 1)] = value;
          }
        }
        return map;
      });
    },
    onChangeInvestMm(e) {
      if (e !== undefined) {
        this.searchInfo.investMm = e;
        this.changeCalculateList(e);
      }
    },
  },
};
</script>

<style></style>
