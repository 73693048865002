export default that => {
  let preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      CanEdit: 0,
    },
    Def: {
      Row: {
        CanFormula: 1,
      },
    },
    Cols: [
      {
        Header: '정산년월',
        Name: 'investMm',
        Width: 100,
        Align: 'center',
        CustomFormat: '####-##',
      },
      {
        Header: '정산구분',
        Name: 'investKind',
        Width: 100,
        Align: 'center',
        Format: $getConstants('INVEST_KIND').code,
        CustomFormat: v => {
          const obj = {};
          obj[$getConstants('INVEST_KIND_ROUTINE').code] = '정기';
          obj[$getConstants('INVEST_KIND_NOT_ROUTINE').code] = '수시';
          return obj[v];
        },
      },
      {
        Header: '차수',
        Name: 'reqDegree',
        Width: 50,
        Align: 'center',
        Formula: fr => (fr.Row.closeYn == $getConstants('Y').code ? '' : fr.Row.reqDegree),
      },
      {Header: '거래처', Name: 'custName', Width: 90, RelWidth: 1},
      {Header: '공급가', Name: 'oprOamt', Extend: preset.amount, RelWidth: 1},
      {Header: '부가세', Name: 'oprVat', Extend: preset.amount, RelWidth: 1},
      {Header: '비과세', Name: 'oprFreeAmt', Extend: preset.amount, RelWidth: 1},
      {Header: '합계', Name: 'sumAmt', Extend: preset.amount, RelWidth: 1},
    ],
  };
};
