<template>
  <pmis-content-box :loading="loading">
    <template v-slot:header-left>
      <iui-text type="search" :value.sync="searchText" @enter="searchInTree" />
      <iui-button value="검색" @click="searchInTree" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{
        onSearchFinish,
        onClick,
      }"
    />
  </pmis-content-box>
</template>

<script>
import {selectBreakdownCostClList} from '@/view/sysManage/stdCodeManage/breakdownCost/api/BreakdownCost.js';
import options from '@/view/sysManage/stdCodeManage/breakdownCost/components/sheetOptions/BreakdownCostClSheet.js';
export default {
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  created() {
    this.onSearch();
  },
  data() {
    return {
      loading: false,
      sheet: undefined,
      sheetUtil: undefined,
      Options: options(this),
      loadSearchData: [],
      returnCols: [
        'code',
        'name',
        'upCode',
        'upName',
        'sctmLcode',
        'sctmLcodeNm',
        'sctmMcode',
        'sctmMcodeNm',
        'sctmScode',
        'sctmScodeNm',
        'sctmSlcodeYn',
        'level',
      ],
      searchText: '',
    };
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
    searchInTree() {
      this.sheetUtil.treeSearch(['name', 'code'], this.searchText);
    },
    async onSearch() {
      this.loading = true;

      let response = await selectBreakdownCostClList({});
      let tree = $_treeModelStdCl(
        response.data,
        $getConstants('BREAKDOWN_CL').code,
        'code',
        'upCode',
        undefined,
        undefined,
        'name'
      );
      this.loadSearchData = tree({level: 1, name: '일위대가분류'});
    },
    searchByItem() {
      this.onSearchList();
    },
    onSearchFinish() {
      this.loading = false;
      if (0 < this.sheet.getTotalRowCount()) {
        this.sheet.showTreeLevel(2, 0, 1);
      }
      this.onCallBack(this.rowsFilter(this.sheet.getFirstRow()));
    },
    rowsFilter(row) {
      let rowObj = {};
      for (let key in row) {
        if (this.returnCols.indexOf(key) != -1) {
          rowObj[key] = row[key];
        }
      }
      return rowObj;
    },
    onClick(e) {
      this.onCallBack(this.rowsFilter(e.row));
    },
    onCallBack(row) {
      this.callback(row);
    },
  },
};
</script>
