export default that => {
  return {
    Cfg: {
      SearchMode: 2,
    },
    Cols: [
      {
        Header: '접속시간',
        Name: 'lgnDttm',
        Type: 'Text',
        Align: 'Center',
        MinWidth: 150,
        RelWidth: 1,
        Visible: 1,
        CanEdit: 0,
      },
      {
        Header: '종료시간',
        Name: 'lgtDttm',
        Type: 'Text',
        Align: 'Center',
        MinWidth: 150,
        RelWidth: 1,
        Visible: 1,
        CanEdit: 0,
      },
      {
        Header: '접속IP',
        Name: 'lgnIp',
        Type: 'Text',
        Align: 'Center',
        Width: 110,
        Visible: 1,
        CanEdit: 0,
      },
    ],
  };
};
