<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="500px">
          <DataCl />
        </i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row min-height="400px">
              <i-col>
                <DocList />
              </i-col>
            </i-row>
            <i-spacer />
            <i-row height="211px">
              <i-col>
                <DetailInfo />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from '../store/edms.js';
import DataCl from '../components/DataClassificationList.vue';
import DocList from '../components/DocList.vue';
import DetailInfo from '../components/DetailInfo.vue';
export default {
  components: {DataCl, DocList, DetailInfo},
  beforeCreate() {
    $init(this, store);
  },
};
</script>

<style></style>
