<template>
  <pmis-content-box>
    <template v-slot:title>단가목록</template>
    <iui-container-new type="css-flex" flex-direction="row">
      <i-col>
        <i-row>
          <ib-sheet
            :uid="_uid"
            :options="Options"
            :loadSearchData="loadSearchData"
            :events="{
              onDataLoad: onDataLoad,
              onClick: onClick,
              onAfterChange: onAfterChange,
            }"
          />
        </i-row>
        <i-spacer />
      </i-col>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/UntpcApplcPopSheet.js';
import {selectUnitCostApplyList} from './api/ResrceManagePop.js';
export default {
  created() {
    this.addEvent({name: 'UntpcApplcPop_popConfirm', func: this.onPopConfirm});
    this.onSearch();
  },
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      ucstObj: {
        matUcstCode: '',
        labUcstCode: '',
        equtUcstCode: '',
      },
    };
  },
  methods: {
    async onSearch() {
      const response = await selectUnitCostApplyList({});
      this.loadSearchData = response.data;
    },
    onDataLoad(e) {
      for (let row of e.sheet.getDataRows()) {
        row.matUcstName ? '' : e.sheet.setAttribute(row, 'matRadio', 'Type', '', 1);
        row.labUcstName ? '' : e.sheet.setAttribute(row, 'labRadio', 'Type', '', 1);
        row.equtUcstName ? '' : e.sheet.setAttribute(row, 'equtRadio', 'Type', '', 1);
        row.oprUcstName ? '' : e.sheet.setAttribute(row, 'oprRadio', 'Type', '', 1);
      }
    },
    onClick(e) {
      if (e.kind == 'Data') {
        let target = String(e.col).substring(0, String(e.col).indexOf('UcstName')); // mat, lab, equt
        if (e.row[target + 'UcstCode']) {
          if (this.ucstObj[target + 'UcstCode'] === e.row[target + 'UcstCode']) {
            delete this.ucstObj[target + 'UcstCode'];
            delete this.ucstObj[target + 'UcstName'];
            e.sheet.setAttribute(e.row, `${target}UcstName`, 'Color', '', 1);
          } else {
            this.ucstObj[target + 'UcstCode'] = e.row[target + 'UcstCode'];
            this.ucstObj[target + 'UcstName'] = e.row[target + 'UcstName'];
            e.sheet.setAttribute(e.row, `${target}UcstName`, 'Color', '#adadad', 1);
            for (let row of e.sheet.getDataRows()) {
              if (e.row.id != row.id) {
                e.sheet.setAttribute(row, `${target}UcstName`, 'Color', '', 1);
              }
            }
          }
        }
      }
    },
    onAfterChange(e) {
      let target = String(e.col).substring(0, String(e.col).indexOf('Radio')); // mat, lab, equt
      this.ucstObj[target + 'UcstCode'] = e.row[target + 'UcstCode'];
      this.ucstObj[target + 'UcstName'] = e.row[target + 'UcstName'];
    },
    onPopConfirm(param) {
      param(this.ucstObj);
    },
  },
};
</script>

<style></style>
