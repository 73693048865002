<template>
  <pmis-content-box :loading="loading">
    <template v-slot:header-left>
      <label>제목</label>
      <iui-text type="search" :value.sync="searchText" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onClick: grid_onClick}"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/BoardList.js';
import {selectBoardList} from '@/view/board/api/board.js';
export default {
  data() {
    return {
      Options: options(this),
      loadSearchData: [],

      loading: false,
      searchText: '',
      subVue: [
        {
          name: `boardView${this._uid}`,
          url: `/boardView${this._uid}`,
          path: './view/board/components/BoardView.vue',
        },
      ],
      externalParam: {},
    };
  },
  created() {
    this.addEvent([
      {name: 'BoardList_search', func: this.onSearch},
      {name: 'BoardList_create', func: this.onCreate},
    ]);
    this.canDelete = false;
  },
  activated() {
    if (this.sheet) {
      this.setExternalData();
    }
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
      this.setExternalData();
    },
    async onSearch() {
      this.loading = true;
      let param = {
        empNo: this.userInfo.empNo,
        searchText: this.searchText,
      };
      const response = await selectBoardList(param);
      this.loadSearchData = response.data;
      this.loading = false;
    },
    onCreate() {
      this.onGoVue({
        pgmCd: this.pgmCd,
        empNo: this.userInfo.empNo,
        natDs: $getConstants('NAT_DS_NOTICE').code,
        boardStatus: 'I',
      });
    },
    grid_onClick(e) {
      if (e.row.Kind == 'Data' && e.col == 'tl') {
        this.onGoVue({
          pgmCd: e.row.pgmCd,
          empNo: e.row.rgprId,
          natNo: e.row.natNo,
          natDs: e.row.natDs,
          boardStatus: 'E',
        });
      }
    },
    onGoVue(param) {
      let routeName = this.$route.name;
      this.goVue(`/boardView${this._uid}`, {...param, routeName});
    },
    setExternalData() {
      if (this.$store.getters['externalData'].natNo) {
        this.externalParam = this.$store.getters['externalData'];
        this.$store.dispatch('setExternalData', {});
        this.onGoVue(this.externalParam);
      } else {
        this.onSearch();
      }
    },
  },
};
</script>

<style></style>
