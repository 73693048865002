import router from '@/config/router';
import {getComponent} from '../../common';
import store from '@/store';
const state = {
  urls: [],
  urlHistory: {},
  router: [],
  currentUrls: {},
};

const getters = {
  urls: state => state.urls,
  history: state => state.urlHistory,
  currentUrls: state => state.currentUrls,
  router: state => state.router,
};

const mutations = {
  setCurrentUrls(state, urls) {
    state.currentUrls = urls;
  },

  ADD_URL(state, payload) {
    state.urls.forEach(url => (url.isSelected = false));
    state.urls.push(payload);
  },

  ADD_ROUTER(state, payload) {
    state.router.push(payload);
  },
  CHANGE_ORDER(state, payload) {
    state.urls = payload;
  },
};

const actions = {
  addMDI(context, tab) {
    tab.mnuPth = tab.mnuPth.indexOf('.') != 0 ? '.' + tab.mnuPth : tab.mnuPth;
    tab.url = '/' + tab.mnuCd;

    if (context.state.urls.filter(e => e.url === tab.url).length > 0) {
      document.querySelector('[href*="#' + tab.url + '"]').click();
    } else {
      if (!context.state.router.filter(e => e.url === tab.url).length) {
        const component = getComponent(tab.mnuPth);
        component._url = tab.url;
        router.matcher.addRoutes([
          {
            name: tab.url.replace(/\//g, ''),
            path: tab.url,
            component: component,
            props: true,
          },
        ]);
      }

      context.commit('ADD_ROUTER', tab);
      context.commit('ADD_URL', tab);

      context.state.urlHistory[tab.url] = [];
      setTimeout(() => {
        document.querySelector('[href*="#' + tab.url + '"]').click();
      }, 10);
    }
  },
  setUrl({commit, state}, urls) {
    commit('setCurrentUrls', urls);
    state.urls.forEach(url => (url.isSelected = urls.url == url.url));
    store.commit('menu/selectedMenu');
  },
  setLocation({state}, url) {
    const rootUrl = state.urls.find(url => url.isSelected).url;
    !url.idx ? (url.idx = 0) : url.idx++;
    state.urlHistory[rootUrl].push(url);
  },
  popHistory({state}, url) {
    state.urlHistory[url].splice(-1);
  },
  removeMdi({state, dispatch, getters}, url) {
    let _url;
    let isClose = false;
    state.urls.forEach(el => {
      if (el.isSelected) {
        _url = el.url;
      }
      if (url === el.url) {
        isClose = true;
      }
    });
    if (_url !== url) {
      //TODO main 의 go(-1)과 동일한 코드이므로 정리필요!!
      //  모듈화하여 main과 여기서 같이 사용하도록!!
      if (window.subVue && window.subVue[_url]) {
        for (let i = -1; i < 0; i++) {
          let length = window.subVue[_url].length;
          length--;
          window.subVue[_url][length].$destroy();
          window.subVue[_url].splice(length, 1);

          if (!length) {
            delete window.subVue[_url];
          }
          const historyLength = getters['history'][_url].length;

          if (historyLength > 1) {
            const url = history[_url][historyLength - 1].url;
            router.replace({name: url.replace(/\//g, ''), path: url, params: {}}, () => {
              dispatch('popHistory', _url);
            });
          } else {
            router.replace({name: _url.replace(/\//g, ''), path: _url, params: {}}, () => {
              dispatch('popHistory', _url);
            });
          }
        }
      }
    }

    if (!isClose) {
      return;
    }

    let idx = state.urls.findIndex(function(u) {
      return u.url === url;
    });

    if (idx < 0) {
      url = Object.keys(state.urlHistory)[Object.keys(state.urlHistory).length - 1];
      idx = state.urls.findIndex(function(u) {
        return u.url === url;
      });
    }

    delete state.urlHistory[url];
    if (idx > -1) state.urls.splice(idx, 1);

    let openUrl = 'HOME';
    if (state.urls[idx - 1]) {
      openUrl = state.urls[idx - 1].url;
    } else if (0 < state.urls.length) {
      openUrl = state.urls[idx].url;
    }
    document.querySelector(`[href*="#${openUrl}"]`).click();
  },
  allRemoveMdi({state}) {
    state.urls = [];
    state.urlHistory = {};
    document.querySelector('[href*="#HOME"]').click();
  },
  changeOrder(context, urls) {
    context.commit('CHANGE_ORDER', urls);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
