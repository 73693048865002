export default that => {
  return {
    Cfg: {
      SearchMode: 2, // 0 : FastLoad 모드 (대용량 처리 방식)
      CanEdit: 1,
      ReqStatusName: 'status', // 서버로 전송될 상태 명 (default:STATUS)
    },
    Cols: [
      {
        Header: '자재코드',
        Name: 'matCode',
        Type: 'Text',
        Align: 'center',
        Width: 140,
        CanEdit: 0,
      },
      {
        Header: '명칭',
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 4,
        CanEdit: 0,
      },
      {
        Header: '규격',
        Name: 'size',
        Type: 'Text',
        RelWidth: 2,
        CanEdit: 0,
      },
      {
        Header: '형식',
        Name: 'matStyle',
        Type: 'Text',
        RelWidth: 2,
        CanEdit: 0,
      },
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: '사용여부',
        Name: 'useDs',
        Type: 'Text',
        Align: 'center',
        Width: 100,
        CanEdit: 0,
        Format: $getConstants('USE_YN').code,
        // Visible: this.isPopup ? false : true,
      },
      {Name: 'flNo', Visible: false},
      {Name: 'lmName', Visible: false},
      {Name: 'mmName', Visible: false},
      {Name: 'smName', Visible: false},
    ],
  };
};
