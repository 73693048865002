import {selectOrderList, selectOrderInfos} from '@/view/bidding/poCustomer/mat/order/api/orderCustomer.js';
import {getOrderInfo} from '@/view/bidding/po/mat/api/order.js';
import {PO_COLUMNS} from '@/view/bidding/js/BiddingColumns.js';

const state = {
  orderList: [],
  orderInfo: {...PO_COLUMNS},
  orderItemList: [],
};

const getters = {
  orderList: state => state.orderList,
  orderInfo: state => state.orderInfo,
  orderItemList: state => state.orderItemList,
};

const mutations = {
  setOrderList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.orderList = payload;
    }
  },
  setOrderInfo(state, payload) {
    for (const key in state.orderInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.orderInfo[key] = payload[key];
      }
    }
  },
  initOrderInfo(state) {
    state.orderInfo = {...PO_COLUMNS};
  },
  setOrderItemList(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.orderItemList = payload;
    }
  },
};

const actions = {
  clearAll({commit}) {
    commit('setOrderList', []);
  },
  async searchOrderList({commit, dispatch}, searchOptions) {
    const orderList = (await selectOrderList(searchOptions)).data;
    commit('setOrderList', orderList);
    if (!orderList?.length) {
      dispatch('clearAll');
    }
  },
  async searchOrderInfos({commit}, poNo) {
    commit('initOrderInfo');

    const response = await selectOrderInfos({poNo});
    const orderInfo = response.data;
    const orderItemList = response.data.orderItemList ?? [];
    commit('setOrderInfo', orderInfo);
    commit('setOrderItemList', orderItemList);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
