<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text type="search" :value.sync="searchNm" width="200px" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
    </template>

    <iui-container-new type="css-flex" flex-direction="row">
      <i-col>
        <i-row>
          <pmis-content-box>
            <template v-slot:title>현장정보</template>

            <ib-sheet
              :uid="_uid"
              :options="Options"
              :loadSearchData="loadSearchData"
              @loadSheet="loadSheet"
              :events="{
                onDblClick,
              }"
            />
          </pmis-content-box>
        </i-row>
      </i-col>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import {selectOtherPrjBreakDown} from '@/view/estmtManage/estmtDtlsManage/prjBreakdownCost/api/prjBreakdownCost.js';
import options from './sheetOption/OtherPrjCopyAsBreakDownPopSheet.js';
export default {
  props: {
    isCopyOpt: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      searchNm: '',
    };
  },

  beforeCreate: function() {
    $mapGetters(this, {
      searchInfo: 'searchInfo',
    });
  },

  created() {
    this.addEvent([{name: 'getFocusedRowByOtherPrjCopyPop', func: this.getFocusedRow}]);
    this.onSearch();
  },

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onDblClick() {
      this.$modalConfirm();
    },
    async onSearch() {
      const response = await selectOtherPrjBreakDown({searchNm: this.searchNm});
      this.loadSearchData = response.data;
    },

    getFocusedRow(callback) {
      if (typeof callback == 'function') {
        callback(this.sheet.getFocusedRow());
      }
    },
    setSearchNm(value) {
      this.searchNm = value;
    },
  },
};
</script>

<style scoped>
.box_w {
  height: 230px !important;
}
.inlineCss {
  display: inline-block;
}
.search_box {
  margin: 0px 20px;
  border: none;
}
.popcon {
  padding: 0px 20px 20px 20px;
}
</style>
