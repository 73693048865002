export const selectMonthMaterialList = async param =>
  await axios.post('/excclcStatusInquiry/selectMonthMaterialList', param);

export const selectMonthLaborList = async param => await axios.post('/excclcStatusInquiry/selectMonthLaborList', param);

export const selectMonthSubconList = async param =>
  await axios.post('/excclcStatusInquiry/selectMonthSubconList', param);

export const selectMonthEquipmentList = async param =>
  await axios.post('/excclcStatusInquiry/selectMonthEquipmentList', param);

export const selectMonthExpenseList = async param =>
  await axios.post('/excclcStatusInquiry/selectMonthExpenseList', param);

export const selectYmList = async param =>
  await axios.post('/excclcStatusInquiry/selectYmList', param);