<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col>
        <pmis-content-box>
          <template #title>입찰정보</template>
          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <iui-container-new type="table" theme="bullet">
                  <colgroup>
                    <col width="100px" />
                    <col />
                    <col width="10px" />
                    <col width="110px" />
                    <col />
                  </colgroup>
                  <i-row>
                    <i-col-header>현장</i-col-header>
                    <i-col colspan="4">
                      <iui-text :value="rfq.spotDeptcd" :enable="false" width="100px" />
                      <iui-text :value="rfq.spotDeptnm" :enable="false" />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header required>입찰공고번호</i-col-header>
                    <i-col>
                      <iui-text :value="rfq.rfqNo2" :enable="false" width="100px" />
                      <iui-text :value="rfq.ttl" :enable="false" />
                    </i-col>
                    <i-spacer />
                    <i-col-header>입찰차수</i-col-header>
                    <i-col>
                      <iui-text
                        :value="rfq.lastBidRnd.length ? `${rfq.lastBidRnd}차` : ''"
                        width="80px"
                        :enable="false"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>공고일시</i-col-header>
                    <i-col>
                      <iui-text :value="rfq.bidStartYmd" width="80px" :enable="false" align="center" />
                      <iui-timepicker width="30px" :value="getTime(rfq.bidStartHh, rfq.bidStartMm)" :enable="false" />
                    </i-col>
                    <i-spacer />
                    <i-col-header required>입찰마감일시</i-col-header>
                    <i-col>
                      <iui-text :value="rfq.bidExpireYmd" width="80px" :enable="false" align="center" />
                      <iui-timepicker width="30px" :value="getTime(rfq.bidExpireHh, rfq.bidExpireMm)" :enable="false" />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>개봉일시</i-col-header>
                    <i-col>
                      <iui-text :value="rfq.bidOpenYmd" width="80px" :enable="false" align="center" />
                      <iui-timepicker width="30px" :value="getTime(rfq.bidOpenHh, rfq.bidOpenMm)" :enable="false" />
                    </i-col>
                    <i-spacer />
                    <i-col-header>응찰/공고업체수</i-col-header>
                    <i-col>
                      <iui-text :value="rfq.cusCount" width="80px" :enable="false" />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>입찰예정가</i-col-header>
                    <i-col>
                      <iui-text type="amount" :value="rfq.bidPlanAmt" width="100px" :enable="false" suffix="" />
                      <label>(원) 부가세포함</label>
                    </i-col>
                    <i-spacer />
                    <i-col-header required>과세유형</i-col-header>
                    <i-col>
                      <iui-text :value="rfq.taxTypenm" width="120px" :enable="false" />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header required>입찰담당</i-col-header>
                    <i-col>
                      <iui-text :value="rfq.chrgUsrnm" :enable="false" width="80px" />
                    </i-col>
                    <i-spacer />
                    <i-col-header>상태</i-col-header>
                    <i-col>
                      <iui-text :value="rfqStNm" width="120px" :enable="false" />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>정보공개여부</i-col-header>
                    <i-col>
                      <iui-checkbox-group
                        :items="choiceNotiFlagItems"
                        :checkedValues="choiceNotiFlag"
                        :enable="false"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>낙찰사유</i-col-header>
                    <i-col rowspan="3" colspan="4">
                      <iui-text type="multi" :value="rfq.choiceRsltRmrk" :enable="false" />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col colspan="5">&nbsp;</i-col>
                  </i-row>
                  <i-row>
                    <i-col colspan="5">&nbsp;</i-col>
                  </i-row>
                </iui-container-new>
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row height="200px">
      <i-col>
        <pmis-content-box>
          <template #title>공사내역</template>
          <template #title-left></template>

          <ib-sheet
            :uid="_uid"
            :options="sheetOpt1"
            :loadSearchData="rfqItemList"
            :events="{onAfterClick: sheet1_onAfterClick}"
          />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row height="180px">
      <i-col>
        <iui-container-new type="css-flex">
          <i-row>
            <i-col width="700px">
              <pmis-content-box>
                <template #title>협력사목록</template>
                <ib-sheet
                  :uid="_uid"
                  :options="sheetOpt2"
                  :loadSearchData="rfqCusList"
                  :events="{
                    onAfterClick: sheet2_onAfterClick,
                    onSearchFinish: sheet2_onSearchFinish,
                  }"
                />
              </pmis-content-box>
            </i-col>
            <i-spacer />
            <i-col>
              <pmis-file-list
                id="rfqInfoFile"
                title="첨부파일"
                title-visible
                :toolbar-visible="false"
                :company-code="rfq.orgCd"
                :file-number="rfq.flNo"
              />
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import sheetOpt1 from './sheetOptions/rfqInfo1.js';
import sheetOpt2 from './sheetOptions/rfqInfo2.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import {SUB_RFQ_COLUMNS} from '@/view/bidding/js/BiddingColumns.js';
import {selectSubRfqInfos} from '@/view/bidding/rfq/sub/api/subRfq.js';
import IuiTimepicker from '@/components/common/IuiTimepicker.vue';

export default {
  components: {IuiTimepicker},
  props: {
    rfqNo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sheetOpt1: sheetOpt1(this),
      sheetOpt2: sheetOpt2(this),

      rfq: {...SUB_RFQ_COLUMNS},
      rfqItemList: [],
      rfqCusList: [],
      rfqStNm: '',

      choiceNotiFlag: [],
      choiceNotiFlagItems: [
        {label: '낙찰업체', value: '1'},
        {label: '낙찰금액', value: '2'},
        {label: '순위공개', value: '3'},
      ],
    };
  },
  computed: {
    isExistSpotDscr() {
      return this.rfq['spotDscrYn'] === BIDDING_CONSTANTS.CODES.SPOT_DSCR_Y;
    },
    isBeforeRfqBidding() {
      return this.rfq['rfqSt'] !== '' && this.rfq['rfqSt'] < BIDDING_CONSTANTS.PROGRESS_STATE.RFQ_BIDDING;
    },
  },
  mounted() {
    this.onSearch();
  },
  methods: {
    async onSearch() {
      const response = await selectSubRfqInfos(this.rfqNo);
      const {subRfqInfo, subRfqItemList, subRfqCusList} = response.data;

      for (const key in this.rfq) {
        this.rfq[key] = subRfqInfo[key];
      }
      this.rfq.rfqNo2 = COMMON_FUNCTION.getMakeFormat(this.rfq.rfqNo);
      this.rfq.taxDirectDs = BIDDING_CONSTANTS.CODES.TAX_DIRECT_PERCENT;
      this.rfq.cusCount = `${this.rfq.totCusCount} / ${this.rfq.quotCusCount}`;
      this.choiceNotiFlag = this.rfq.choiceNotiFlag ? this.rfq.choiceNotiFlag.split(',') : [];
      this.rfqStNm = await $getCodeNm($getConstants('BIDDING_ST').code, this.rfq.rfqSt);

      this.rfqItemList = subRfqItemList;
      this.rfqCusList = subRfqCusList;
    },

    sheet1_onAfterClick(e) {},
    sheet2_onAfterClick(e) {},
    sheet2_onSearchFinish(e) {
      e.sheet.getDataRows().forEach(row => {
        if (row.quotSbmtYn === BIDDING_CONSTANTS.CODES.QUOT_SBMT_Y) {
          e.sheet.setAttribute(row, 'quotAmtText', 'Type', 'Float', 1);
          e.sheet.setAttribute(row, 'quotAmtText', 'Align', 'right', 1);
          e.sheet.setAttribute(row, 'quotAmtText', 'Format', '#,##0.###', 1);
          e.sheet.setValue(row, 'quotAmtText', row.totAmt, 1);
        } else {
          e.sheet.setAttribute(row, 'quotAmtText', 'Type', 'Text', 1);
          e.sheet.setAttribute(row, 'quotAmtText', 'Align', 'right', 1);
          e.sheet.setValue(row, 'quotAmtText', '-', 1);
        }

        if (row.resultYn == BIDDING_CONSTANTS.CODES.RESULT_Y) {
          e.sheet.setAttribute(row, 'resultYn', 'TextStyle', 1, 1);
          e.sheet.setAttribute(row, 'resultYn', 'TextColor', '#FF0000', 1);
        }
      });
    },
    getTime(hour, minute) {
      hour = hour ? hour : '00';
      minute = minute ? minute : '00';
      return `${hour}:${minute}`;
    },
  },
};
</script>

<style></style>
