<template>
  <pmis-tab-box>
    <template #title>{{ isWrite ? '작성목록' : '입찰목록' }}</template>
    <pmis-content-box>
      <template #header-left>
        <i-header-multi-box>
          <div>
            <label class="ml5 mr10">현장</label>
            <iui-text type="search" :value.sync="searchOption.searchPrjNm" @enter="onSearch" width="200px" />
            <label class="ml10 mr10">입찰명</label>
            <iui-text type="search" :value.sync="searchOption.searchText" @enter="onSearch" />
            <iui-button value="검색" @click="onSearch" />
          </div>
          <div>
            <label>{{ isWrite ? '작성일' : '공고일' }}</label>
            <iui-datepicker :value.sync="searchOption.searchFrDt" @change="onSearch" :group="searchDateGroup.seq1" />
            <label>~</label>
            <iui-datepicker :value.sync="searchOption.searchToDt" @change="onSearch" :group="searchDateGroup.seq2" />
            <label class="ml5">진행상태</label>
            <iui-select
              :value.sync="searchOption.searchRfqSt"
              :items="rfqStItems"
              defaultCd="A"
              @change="onSearch"
              :width="isComplete ? '150px' : '260px'"
            />
            <iui-checkbox-group
              v-if="isComplete"
              :items="includeCancelItem"
              :value.sync="searchOption.searchIncludeCancel"
              @change="onSearch"
            />
          </div>
        </i-header-multi-box>
      </template>
      <ib-sheet
        :uid="_uid"
        :options="sheetOpt"
        :loadSearchData="rfqList"
        @loadSheet="sheet = $event"
        :events="{
          onRenderFirstFinish: () => onSearch(focusKey),
          onSearchFinish: sheet_onSearchFinish,
          onClick: sheet_onClick,
        }"
      />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/rfqList.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';

export default {
  props: {
    process: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
      searchDateGroup: {
        seq1: {grpNm: 'searchDate', seq: 1},
        seq2: {grpNm: 'searchDate', seq: 2},
      },
      rfqStItems: [],
      includeCancelItem: [{label: '입찰취소포함', value: BIDDING_CONSTANTS.CODES.RFQ_CANCEL_Y}],

      searchOption: {
        searchPrjNm: '',
        searchText: '',
        searchFrDt: '',
        searchToDt: '',
        searchRfqSt: '',
        searchIncludeCancel: '',
      },
      focusKey: '',
    };
  },
  computed: {
    isWrite() {
      return this.process === 'write';
    },
    isComplete() {
      return this.process === 'complete';
    },
  },
  beforeCreate() {
    $mapGetters(this, ['rfqList']);
    $mapActions(this, ['searchRfqList', 'searchRfqInfos']);
  },
  async created() {
    this.addFuncSearch(this.onClick_cmnSearch);
    this.addEvent([{name: 'RfqList_Search', func: this.onSearch}]);

    const STATE = BIDDING_CONSTANTS.PROGRESS_STATE;
    const rfqStScope = {
      write: {from: STATE.RFQ_READY, to: STATE.RFQ_APPROVAL_COMPLETE},
      progress: {from: STATE.RFQ_SPOT_DSCR, to: STATE.RFQ_APPROVAL_CHOICE},
      complete: {from: STATE.RFQ_FAIL, to: STATE.PO_COMPLETE},
    };
    const from = rfqStScope[this.process].from;
    const to = rfqStScope[this.process].to;
    this.rfqStItems = (await $getCode($getConstants('BIDDING_ST').code))
      .filter(item => from <= item.code && item.code <= to)
      .map(item => ({text: item.codeNm, value: item.code}));
  },
  activated() {
    // 포틀릿 연계
    this.focusKey = this.$store.getters['externalData'].rfqNo ?? this.focusKey;
    this.$store.dispatch('setExternalData', {});
    if (this.sheet && this.focusKey) {
      this.setFocus();
    }
  },
  methods: {
    onClick_cmnSearch() {
      for (const key in this.searchOption) {
        this.searchOption[key] = '';
      }
      this.onSearch();
    },
    onSearch(focusKey = '') {
      this.focusKey = typeof focusKey === 'string' ? focusKey : '';

      const searchOption = {orgCd: this.pgmCd, process: this.process, ...this.searchOption};
      this.searchRfqList(searchOption);
    },
    async setRowData(row) {
      this.callEvent({name: 'RfqInfo_onBeforeSetRowData'});

      await this.searchRfqInfos(row.rfqNo);

      this.callEvent({name: 'RfqInfo_onSetRowData'});
    },
    async setFocus() {
      const focusRow = this.focusKey
        ? await this.sheet.util.listSearch('rfqNo', this.focusKey, true)
        : this.sheet.getFirstRow();

      this.setRowData(focusRow);
      this.focusKey = '';
    },
    sheet_onSearchFinish(e) {
      if (e.sheet.getTotalRowCount()) {
        this.setFocus();
      }
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data') {
        this.setRowData(e.row);
      }
    },
  },
};
</script>

<style></style>
