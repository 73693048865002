var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('iui-container-new',{attrs:{"type":"css-flex","flex-direction":"row"}},[_c('i-col',[_c('i-row',{attrs:{"height":"100px"}},[_c('pmis-content-box',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("견적정보")]},proxy:true}])},[_c('iui-container-new',{attrs:{"type":"table","theme":"bullet"}},[_c('i-row',[_c('i-col-header',[_vm._v("현장")]),_c('i-col',[_c('iui-text',{attrs:{"type":"code","value":_vm.prjCd,"enable":false}}),_c('iui-text',{attrs:{"value":_vm.getPrjInfo().prjNm,"enable":false}})],1)],1),_c('i-row',[_c('i-col-header',[_vm._v("견적차수")]),_c('i-col',[_c('iui-select',{attrs:{"items":_vm.chgDegreeItems,"value":_vm.targetChgDegree,"width":"90px"},on:{"change":function (value) { return (_vm.targetChgDegree = value); }}})],1)],1)],1)],1)],1),_c('i-spacer'),_c('i-row',{attrs:{"height":"150px"}},[_c('iui-container-new',{attrs:{"type":"css-flex"}},[_c('i-row',[_c('i-col',[_c('pmis-content-box',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("복사옵션")]},proxy:true}])},[_c('iui-container-new',{attrs:{"type":"table","theme":"bullet","header-width":"60px"}},[_c('i-row',[_c('i-col-header',[_vm._v("재료비")]),_c('i-col',[_c('iui-text',{attrs:{"type":"rate","value":_vm.matPriceRt},on:{"update:value":function (value) {
                          _vm.matPriceRt = value;
                        }}})],1)],1),_c('i-row',[_c('i-col-header',[_vm._v("노무비")]),_c('i-col',[_c('iui-text',{attrs:{"type":"rate","value":_vm.labPriceRt},on:{"update:value":function (value) {
                          _vm.labPriceRt = value;
                        }}})],1)],1),_c('i-row',[_c('i-col-header',[_vm._v("장비비")]),_c('i-col',[_c('iui-text',{attrs:{"type":"rate","value":_vm.equipPriceRt},on:{"update:value":function (value) {
                          _vm.equipPriceRt = value;
                        }}})],1)],1),_c('i-row',[_c('i-col-header',[_vm._v("경비")]),_c('i-col',[_c('iui-text',{attrs:{"type":"rate","value":_vm.oprPriceRt},on:{"update:value":function (value) {
                          _vm.oprPriceRt = value;
                        }}})],1)],1)],1)],1)],1),_c('i-col',{attrs:{"width":"280px"}},[_c('pmis-content-box',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("절삭옵션")]},proxy:true}])},[_c('iui-container-new',{attrs:{"type":"table","theme":"bullet","header-width":"100%"}},[_c('i-row',[_c('i-col-header',[_c('iui-text',{attrs:{"type":"number","label":"100원 이상 ~ 1,000원 미만","suffix":"자리 절삭","label-width":"160px","width":"260px","value":_vm.thousandDn,"max-length":"1"},on:{"update:value":function (value) {
                          _vm.thousandDn = value;
                        }}})],1)],1),_c('i-row',[_c('i-col-header',[_c('iui-text',{attrs:{"type":"number","label":"1,000원 이상 ~ 10,000원 미만","suffix":"자리 절삭","label-width":"160px","width":"260px","value":_vm.tenThousandDn,"max-length":"1"},on:{"update:value":function (value) {
                          _vm.tenThousandDn = value;
                        }}})],1)],1),_c('i-row',[_c('i-col-header',[_c('iui-text',{attrs:{"type":"number","label":"10,000원 이상","suffix":"자리 절삭","label-width":"160px","width":"260px","value":_vm.tenThousandUp,"max-length":"1"},on:{"update:value":function($event){_vm.tenThousandUp=$event}}})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }