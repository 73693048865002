<template>
  <pmis-page>
    <pmis-tab-box>
      <template v-slot:title>상세정보</template>
      <form onsubmit="return false;">
        <iui-container-new type="css-flex">
          <i-row :height="detailInfoHeight">
            <i-col>
              <iui-container-new type="table" theme="bullet" width="623px">
                <i-row>
                  <i-col-header> 구분</i-col-header>
                  <i-col v-if="isNew || isEdit">
                    <iui-radio-group
                      :p-code="$getConstants('NAT_DS').code"
                      :exclude-items="`${$getConstants('NAT_DS_QNA').code},${$getConstants('NAT_DS_FAQ').code}`"
                      :value="this.detailInfo.natDs"
                      @change="setDetailInfo({natDs: $event.target.value})"
                    />
                  </i-col>
                  <i-col v-else>
                    <iui-text :value="natDsNm" width="130px" :enable="false" />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header required>
                    제목
                  </i-col-header>
                  <i-col colspan="3">
                    <iui-text
                      title="제목"
                      :value="detailInfo.tl"
                      @input="setDetailInfo({tl: $event.target.value})"
                      :enable="isNew || isEdit"
                      max-length="100"
                      required
                      :error-message="{title: '제목', message: '제목을 입력해주세요.'}"
                    />
                  </i-col>
                </i-row>
                <i-row rowspan="3">
                  <i-col colspan="3">
                    <iui-container-new type="table" theme="bullet">
                      <i-row v-if="isNew || isEdit">
                        <i-col-header required>
                          열람대상
                        </i-col-header>
                        <i-col width="250px">
                          <iui-radio-group
                            :items="rngDsItems"
                            :value="detailInfo.rngDs"
                            @change="
                              e => {
                                setDetailInfo({rngDs: e.target.value});
                                e.target.value == $getConstants('RNG_DS_IN_COMPANY').code
                                  ? this.initRngDsList()
                                  : (this.rngDsList = []);
                              }
                            "
                            @click="
                              $event.target.value == $getConstants('RNG_DS_CHOOSE_COMPANY').code ? onRngDsPopup() : ''
                            "
                            :enable="isNew || isEdit"
                          />
                        </i-col>
                        <i-col-header required>
                          게시기간
                        </i-col-header>
                        <i-col width="300px">
                          <iui-datepicker
                            :value="detailInfo.natStDate"
                            @change="setPeriod('natStDate', $event)"
                            :group="{grpNm: 'natDate', seq: 1}"
                            :enable="isNew || isEdit"
                            :isIconClear="false"
                            required
                            :error-message="{title: '게시 시작일', message: '게시 시작일을 입력해주세요.'}"
                          />
                          <iui-datepicker
                            :value="detailInfo.natEnDate"
                            @change="setPeriod('natEnDate', $event)"
                            :group="{grpNm: 'natDate', seq: 2}"
                            :prefix="'-'"
                            :enable="isNew || isEdit"
                            :isIconClear="false"
                            required
                            :error-message="{title: '게시 종료일', message: '게시 종료일을 입력해주세요.'}"
                          />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>
                          첨부파일
                        </i-col-header>
                        <i-col>
                          <pmis-file-list
                            :modal="true"
                            :companyCode="params.pgmCd"
                            :saveAuth="params.empNo == userInfo.empNo"
                            :fileNumber="detailInfo.flNo"
                            :allowAdd="isNew || isEdit"
                            :id="'fileComp' + _uid"
                            @delete-complete="file_deleteComplete"
                          />
                        </i-col>
                        <i-col-header>
                          조회수
                        </i-col-header>
                        <i-col>{{ detailInfo.inqCnt }}</i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>
                          등록자
                        </i-col-header>
                        <i-col>
                          <iui-text width="100px" :value="isNew ? userInfo.name : detailInfo.rgprNm" :enable="false" />
                        </i-col>
                        <i-col-header>
                          등록일시
                        </i-col-header>
                        <i-col>
                          <iui-text width="130px" :value="detailInfo.rgsDttm" :enable="false" />
                        </i-col>
                      </i-row>
                    </iui-container-new>
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header required>공지내용</i-col-header>
                </i-row>
              </iui-container-new>
            </i-col>
          </i-row>
          <i-row height="500px">
            <i-col width="1200px">
              <template>
                <Editor v-if="isNew || isEdit" height="500px" initialEditType="wysiwyg" ref="Editor" />
                <Viewer v-else-if="isView" ref="Viewer" height="100%" @load="onViewerLoad" />
              </template>
            </i-col>
          </i-row>
        </iui-container-new>
      </form>
    </pmis-tab-box>
    <iui-modal
      name="RngDsPopup"
      width="500px"
      height="600px"
      title="열람대상"
      :btns="modalBtns"
      @beforeClose="onRngDsPopup_beforeClosed"
    >
      <RngDsPopup :rngDsList.sync="rngDsList" />
    </iui-modal>
  </pmis-page>
</template>

<script>
import {mapGetters} from 'vuex';
import store from '@/view/board/store/board.js';

import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import {Editor, Viewer} from '@toast-ui/vue-editor';

import RngDsPopup from './popup/RngDsPopup.vue';
import {selectBoardDetailInfo, deleteBoardDetailInfo, updateFlNo, saveBoardDetailInfo} from '../api/board.js';

export default {
  components: {
    Editor,
    RngDsPopup,
    Viewer,
  },
  data() {
    return {
      rngDsList: [],
      keyInfo: {},
      boardStatus: '',
      rngDsItems: [
        {label: $getConstants('RNG_DS_IN_COMPANY').name, value: $getConstants('RNG_DS_IN_COMPANY').code},
        {
          label: $getConstants('RNG_DS_CHOOSE_COMPANY').name,
          value: $getConstants('RNG_DS_CHOOSE_COMPANY').code,
        },
      ],
      modalBtns: [{name: '확인', callback: this.onConfirmRngDsPopup}],
    };
  },
  computed: {
    ...mapGetters('menu', {menu: 'menu'}),
    detailInfoHeight() {
      return this.isView ? '140px' : '170px';
    },
    isNew() {
      return this.boardStatus == 'I';
    },
    isEdit() {
      return this.boardStatus == 'E' && this.isRegistrant;
    },
    isView() {
      return !this.isNew && !this.isEdit;
    },
    isRegistrant() {
      return this.params.empNo == this.userInfo.empNo && this.params.pgmCd == this.userInfo.pgmCd;
    },
    natDsNm() {
      let value;
      switch (this.detailInfo.natDs) {
        case $getConstants('NAT_DS_NOTICE').code:
          value = '일반공지';
          break;
        case $getConstants('NAT_DS_RFQ').code:
          value = '입찰공지';
          break;
      }
      return value;
    },
  },
  watch: {
    $route() {},
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['detailInfo']);
    $mapMutations(this, ['setDetailInfo', 'initDetailInfo']);
  },
  created() {
    this.canSave = true;
    this.canDelete = false;
    this.canNew = false;
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);

    this.params = {...this.$route.params};
  },
  activated() {
    this.initData();
    if (this.isNew || !this.isRegistrant) {
      this.canDelete = false;
      if (this.isNew) {
        this.setDetailInfo({
          rngDs: $getConstants('RNG_DS_IN_COMPANY').code,
          natStDate: $_getCurrentDate(),
          natEnDate: $_getCalDate($_getCurrentDate(), 30),
        });
      } else if (!this.isRegistrant) {
        this.canSave = false;
      }
    }
    if (this.isRegistrant && this.isNew) {
      this.canSave = true;
      this.canDelete = false;
    } else if (this.isRegistrant) {
      this.canSave = true;
      this.canDelete = true;
    }

    if (!this.isNew) {
      this.onSearch();
    }
  },
  methods: {
    onViewerLoad(e) {
      e.options.el.style = 'height:100%; padding: 10px 25px 0px 25px; border: 1px solid #e5e5e5; overflow:auto;';
    },
    initData() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.keyInfo.pgmCd = this.params.pgmCd || this.pgmCd;
      this.keyInfo.natNo = this.params.natNo;
      this.keyInfo.natDs = this.params.natDs;
      this.boardStatus = this.params.boardStatus;

      this.initDetailInfo();
      this.setDetailInfo({
        pgmCd: this.params.pgmCd,
        natNo: this.params.natNo || 0,
        natDs: this.params.natDs,
        rgprId: this.params.empNo,
      });
      if (this.isNew) {
        this.initRngDsList();
      }
    },
    initRngDsList() {
      this.rngDsList = [`${this.userInfo.pgmCd}`];
    },
    setPeriod(key, e) {
      this.setDetailInfo({[key]: e});
    },
    onRngDsPopup() {
      this.$modal.show('RngDsPopup');
    },
    onConfirmRngDsPopup() {
      // 열람대상 팝업 - 확인버튼
      this.callEvent({
        name: 'RngDsPopup_getRowsByChecked',
        param: {
          callback: data => {
            this.rngDsList = [];
            data.forEach(el => {
              if (el.pgmCd) {
                this.rngDsList.push(String(el.pgmCd));
              }
            });
            this.$modal.hide('RngDsPopup');
          },
        },
      });
    },
    onRngDsPopup_beforeClosed() {
      this.callEvent({
        name: 'RngDsPopup_getRowsByChecked',
        param: {
          callback: data => {
            if (
              (!data.length && !this.rngDsList.length) ||
              (this.rngDsList.length == 1 && this.rngDsList[0] === this.userInfo.pgmCd) ||
              this.rngDsList.length == 0
            ) {
              this.setDetailInfo({rngDs: $getConstants('RNG_DS_IN_COMPANY').code});
            }
          },
          noAlert: true,
        },
      });
    },
    async onSearch() {
      //게시판 상세조회
      let param = this.keyInfo;
      const response = await selectBoardDetailInfo(param);
      this.setDetailInfo(response.data.boardInfo);
      if (this.isEdit) {
        this.$refs.Editor.invoke('setHtml', this.detailInfo.cts);
      } else if (this.isView) {
        this.$refs.Viewer.invoke('setMarkdown', this.detailInfo.cts);
      }
      if (response.data.boardCountDtoList.length) {
        response.data.boardCountDtoList.forEach(el => {
          this.rngDsList.push(el.pgmCdDs);
        });
      }
    },
    async onSave() {
      if (!this.isRegistrant) return;
      this.setDetailInfo({cts: this.$refs.Editor.invoke('getHtml').trim()});
      if (this.detailInfo.tl == '') {
        this.$alert({title: '제목', message: '제목을 입력해주세요.'});
        return;
      } else if (this.detailInfo.rngDs == $getConstants('RNG_DS_CHOOSE_COMPANY').code && this.rngDsList.length == 0) {
        this.$alert({title: '열람대상', message: '열람대상을 선택해주세요.'});
        return;
      } else if (!this.detailInfo.cts) {
        this.$alert({title: '공지내용', message: '입력된 내용이 없습니다.'});
        return;
      } else {
        let param = {
          ...this.detailInfo,
          rngDsList: this.rngDsList,
          cud: this.isEdit ? 2 : 1,
        };
        const response = await saveBoardDetailInfo(param);
        this.onGoList();
      }
    },
    async onDelete() {
      if (!this.isRegistrant) return;
      if (!(await this.$confirm({title: '공지사항 삭제', message: '삭제하시겠습니까?'}))) return;
      let param = {
        ...this.detailInfo,
        rngDsList: this.rngDsList,
      };
      const response = await deleteBoardDetailInfo(param);
      this.onGoList();
    },
    async file_deleteComplete(flNo) {
      if (flNo === 0) {
        this.setDetailInfo({flNo: flNo});
        let param = this.detailInfo;
        const response = await updateFlNo(param);
      }
    },
    onGoList() {
      this.location.go(-1);
    },
  },
};
</script>

<style></style>
