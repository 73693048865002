<template>
  <pmis-content-box>
    <template #title>공사내역</template>
    <template #title-left>
      <small>※ 노무비 : 건설산업기본법시행령 제84조 규정에 의한 노무비</small>
    </template>
    <template #title-right>
      <iui-button v-if="!isModal" value="크게보기" @click="openExpandedModal" />
      <iui-button
        value="실행내역선택"
        @click="onShowExecutionList"
        v-if="rfqIsNew || (rfqStatus.isExistOnlyRfq && rfqStatus.isRfqReady)"
      />
      <iui-button
        value="삭제"
        @click="onDeleteRfqItem"
        v-if="rfqIsNew || (rfqStatus.isExistOnlyRfq && rfqStatus.isRfqReady)"
      />
    </template>
    <template #header-left>
      <label><b>계약금액</b></label>
      <iui-text type="amount" :value="rfqInfo.totAmt" :enable="false" width="150px" />
      <label><b>공급가액</b></label>
      <iui-text type="amount" :value="rfqInfo.amt" :enable="false" width="150px" />
      <label>( ※ 노무비</label>
      <iui-text type="amount" :value="rfqInfo.labAmt" :enable="false" width="150px" />
      <label>)</label>
      <label class="ml5"><b>부가세액</b></label>
      <iui-text type="amount" :value="rfqInfo.vatAmt" :enable="false" width="150px" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      @loadSheet="sheet = $event"
      :loadSearchData="rfqItemList"
      :events="{
        onSearchFinish: sheet_onSearchFinish,
        onBeforeChange: sheet_onBeforeChange,
        onAfterChange: sheet_onAfterChange,
      }"
    />

    <ExecutionList @executionList="onCheckedExecutionList" />

    <iui-modal name="expandedModal" title="공사내역" size-type="size6" @closed="onClosedExpandedModal">
      <RfqItemList isModal :cloneData.sync="cloneData" />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import sheetOpt from './sheetOptions/rfqItemList.js';
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';

import ExecutionList from '@/view/subBidding/components/ExecutionList.vue';

export default {
  name: 'RfqItemList', // 재귀 컴포넌트 사용 시, name 옵션 필수임
  components: {ExecutionList},
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sheetOpt: $addColCheckbox(sheetOpt(this)),
      cloneData: null,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['rfqInfo', 'rfqStatus', 'rfqItemList', 'rfqIsNew']);
    $mapMutations(this, ['setRfqInfo', 'setRfqItemList']);
  },
  created() {
    this.addEvent([
      {name: 'RfqItemList_GetSaveJson', func: this.getSaveJson},
      {name: 'RfqItemList_CalculateAmt', func: this.calculateAmt},
    ]);
  },
  methods: {
    async onShowExecutionList() {
      if (!this.rfqInfo.spotDeptcd) {
        await this.$alert({title: '현장', message: '현장을 선택해주세요.'});
        this.callEvent({name: 'RfqInfo_openPrjSearchbox'});
        return;
      }
      const param = {
        pgmCd: this.pgmCd,
        prjCd: this.rfqInfo.spotDeptcd,
        validSheet: this.sheet.getDataRows(),
      };
      this.callEvent({name: 'ExecutionList_ShowModal', param: param});
    },
    onCheckedExecutionList(executionList) {
      executionList.forEach(item => {
        const addRow = this.sheet.addRow();

        this.sheet.setValue(addRow, 'srvNm', item.mgName, true);
        this.sheet.setValue(addRow, 'itemSeq', item.itemSeq, true);
        this.sheet.setValue(addRow, 'itemNm', item.itemName, true);
        this.sheet.setValue(addRow, 'sizeNm', item.ssize, true);
        this.sheet.setValue(addRow, 'unit', item.unit, true);

        this.sheet.setValue(addRow, 'totQty', item.totQty, true);
        this.sheet.setValue(addRow, 'qty', 0, true);
        this.sheet.setValue(addRow, 'unitAmt', item.unitAmt, true);

        this.sheet.setValue(addRow, 'wcode', item.wcode, true);
        this.sheet.setValue(addRow, 'headCode', item.headCode, true);
        this.sheet.setValue(addRow, 'midCode', item.midCode, true);
        this.sheet.setValue(addRow, 'itemCode', item.itemCode, true);
        this.sheet.setValue(addRow, 'costType', item.costType, true);

        this.sheet.setValue(addRow, 'planMatUprc', item.planMatUprc, true);
        this.sheet.setValue(addRow, 'planLabUprc', item.planLabUprc, true);
        this.sheet.setValue(addRow, 'planEquipUprc', item.planEquipUprc, true);
        this.sheet.setValue(addRow, 'planCostUprc', item.planCostUprc, true);
      });
    },
    setEnableSheet() {
      const canFocus = this.rfqIsNew || (this.rfqStatus.isExistOnlyRfq && this.rfqStatus.isRfqReady);
      const canEdit = this.rfqIsNew || (this.rfqStatus.isExistOnlyRfq && this.rfqStatus.isRfqReady);
      const color =
        this.rfqIsNew || (this.rfqStatus.isExistOnlyRfq && this.rfqStatus.isRfqReady) ? '#E0E0F8' : undefined;
      const showOrHide = this.rfqIsNew || (this.rfqStatus.isExistOnlyRfq && this.rfqStatus.isRfqReady);

      this.sheet.Def.Row.CanFocus = canFocus;
      this.sheet.setAttribute(null, 'chk', 'Visible', showOrHide, 1);
      this.sheet.setAttribute(null, 'qty', 'CanEdit', canEdit, 1);
      this.sheet.setAttribute(null, 'srvRmrk', 'CanEdit', canEdit, 1);

      this.sheet.setAttribute(null, 'qty', 'Color', color, 1);
      this.sheet.setAttribute(null, 'srvRmrk', 'Color', color, 1);
    },
    onDeleteRfqItem() {
      const title = '공사내역 삭제';
      if (!this.sheet.getTotalRowCount()) {
        this.$alert({title, message: '공사내역이 존재하지 않습니다.'});
        return;
      }

      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (!checkedRows.length) {
        this.$alert({title, message: '선택된 항목이 없습니다.'});
        return;
      }

      this.sheet.removeRows(checkedRows);
      this.calculateAmt();

      // 크게보기 모달에서 수정한 데이터와 동기화
      this.cloneData = this.sheet.getSaveJson(0).data;
      this.$emit('update:cloneData', this.cloneData);
    },
    getSaveJson(callback) {
      if (typeof callback === 'function') {
        const saveJson = this.sheet.getSaveJson(0).data.sort((a, b) => a.itemCd - b.itemCd);
        callback(saveJson);
      }
    },
    calculateAmt() {
      const itemList = this.sheet.getDataRows();
      const amt = itemList.reduce((acc, curr) => acc + curr['amt'], 0);
      const labAmt = itemList.reduce((acc, curr) => acc + curr['qty'] * curr['planLabUprc'], 0);

      const {taxTypecd, taxDirectDs, taxRate, taxTypeDirectPercent, taxTypeDirectAmt} = this.rfqInfo;
      const calResult = COMMON_FUNCTION.onCalculateTaxAndTotAmt({
        amt,
        taxTypecd,
        taxDirectDs,
        taxRate,
        taxTypeDirectPercent,
        taxTypeDirectAmt,
      });

      this.setRfqInfo({...calResult, labAmt});
    },
    sheet_onSearchFinish() {
      this.setEnableSheet();
      this.calculateAmt();
    },
    sheet_onBeforeChange(e) {
      if (e.col === 'qty') {
        if (Number.isNaN(Number(e.val))) {
          return e.oldval;
        }

        const decimal = e.val.split('.')[1];
        if (5 < decimal?.length) {
          this.$alert({title: '금회요청수량', message: '소수점 이하 5자리까지 입력가능합니다.'});
          return e.oldval;
        }

        if (0 > e.val) {
          this.$alert({title: '금회수량', message: '금회수량은 0 이상이어야 합니다.'});
          return e.oldval;
        }
        return e.val;
      }
    },
    sheet_onAfterChange(e) {
      if (e.col === 'qty') {
        this.calculateAmt();
      }

      // 크게보기 모달에서 수정한 데이터와 동기화
      this.cloneData = this.sheet.getSaveJson(0).data;
      this.$emit('update:cloneData', this.cloneData);
    },
    async openExpandedModal() {
      if (!this.rfqInfo.spotDeptcd) {
        await this.$alert({title: '현장', message: '현장을 선택해주세요.'});
        this.callEvent({name: 'RfqInfo_openPrjSearchbox'});
        return;
      }

      // 모달 open 시, 현재 목록과 동기화
      this.cloneData = this.sheet.getSaveJson(0).data;
      this.setRfqItemList(this.cloneData);

      this.$modal.show('expandedModal');
    },
    onClosedExpandedModal() {
      const rfqItemList = this.cloneData.map(row => ({
        srvNm: row.srvNm,
        itemSeq: row.itemSeq,
        itemNm: row.itemNm,
        sizeNm: row.sizeNm,
        unit: row.unit,
        totQty: row.totQty,
        qty: row.qty,
        unitAmt: row.unitAmt,
        amt: row.amt,
        srvRmrk: row.srvRmrk,
        rfqSeq: row.rfqSeq,
        prNo: row.prNo,
        prSeq: row.prSeq,
        wcode: row.wcode,
        headCode: row.headCode,
        midCode: row.midCode,
        itemCode: row.itemCode,
        costType: row.costType,
        planMatUprc: row.planMatUprc,
        planLabUprc: row.planLabUprc,
        planEquipUprc: row.planEquipUprc,
        planCostUprc: row.planCostUprc,
      }));

      this.setRfqItemList(rfqItemList);
    },
  },
};
</script>

<style></style>
