<template>
  <pmis-tab-box>
    <template v-slot:title>근로자목록</template>
    <Search slot="header-custom" @search="onSearch" />
    <ib-sheet
      :uid="_uid"
      :options="options"
      :loadSearchData="labMonList"
      :events="{
        onSearchFinish: onSearchFinish,
        onClick: onClick,
      }"
    />
  </pmis-tab-box>
</template>

<script>
import options from './sheetOption/LabtMonMListMSheet.js';
import Search from './LabtMonMListSubSearch.vue';
export default {
  components: {
    Search,
  },
  props: {
    registerData: {
      type: Array,
    },
  },
  data() {
    return {
      options: options(this),
    };
  },
  watch: {
    labMonList(value) {
      this.canPrint = value.length;
    },
  },
  beforeCreate() {
    $mapGetters(this, ['labMonList', 'fixedTimeLabSaveDataReqDto']);
    $mapMutations(this, ['setDetailInfo', 'setSearchInfo', 'initDetailInfo', 'initCalculateList', 'setCloseYn']);
    $mapActions(this, ['selectLabMonList', 'selectLabMonDetailList']);
  },
  created() {
    this.addFuncSave(this.onSave);
  },
  methods: {
    async onSave() {
      if (!this.fixedTimeLabSaveDataReqDto.actPayAmt) {
        return;
      }

      try {
        const response = await this.$save({
          url: '/closeFixedTimeLab/saveLabMon',
          param: this.fixedTimeLabSaveDataReqDto,
        });
      } catch (error) {
        console.error(error);
      }
    },
    async onSearch() {
      this.selectLabMonList();
    },
    onSearchFinish(e) {
      this.selectCalculateList(e.sheet.getFirstRow());
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.selectCalculateList(e.row);
      }
    },
    async selectCalculateList(row) {
      this.initDetailInfo();
      this.initCalculateList();
      if (!row) {
        return;
      }
      this.setDetailInfo(row);
      this.setCloseYn(row.closeYn === $getConstants('Y').code);

      await this.selectLabMonDetailList();
    },
  },
};
</script>

<style></style>
