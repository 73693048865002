export default that => {
  return {
    Cfg: {
      CanEdit: 1,
      CanSort: false,
    },
    Cols: [
      {
        Header: '분류번호',
        Name: 'csNo',
        Type: 'Text',
        Align: 'center',
        Width: 100,
        CanEdit: false,
      },
      {
        Header: '명칭',
        Name: 'csNm',
        Type: 'Text',
        Align: 'left',
        RelWidth: 1,
        CanEdit: true,
        Required: 1,
      },
      {Name: 'basicDataYn', Visible: false},
    ],
  };
};
