<template>
  <pmis-page>
    <StampPaymentList type="mat" />
  </pmis-page>
</template>

<script>
import StampPaymentList from '@/view/bidding/po/common/components/StampPaymentList.vue';
export default {
  components: {StampPaymentList},
};
</script>

<style></style>
