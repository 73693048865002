<template>
  <pmis-tab-box>
    <template #title>발주정보</template>
    <template #title-right>
      <template v-if="isOrderSend">
        <iui-button value="발주서반송" @click="onCheckPoState('rejectOrder')" />
        <iui-button value="발주서접수" @click="onCheckPoState('confirmOrder')" />
      </template>
    </template>

    <iui-container-new type="css-flex">
      <i-row height="300px">
        <i-col>
          <pmis-content-box>
            <template #title>기본정보</template>
            <iui-container-new type="table" theme="bullet">
              <i-row>
                <i-col-header>발주자</i-col-header>
                <i-col>
                  <iui-text :value="orderInfo.orgNm" :enable="false" />
                </i-col>
                <i-spacer />
                <i-col-header>현장</i-col-header>
                <i-col>
                  <iui-text :value="orderInfo.spotDeptnm" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>발주번호</i-col-header>
                <i-col>
                  <iui-text :value="getMakeFormat(orderInfo.poNo)" width="100px" :enable="false" />
                  <iui-text :value="orderInfo.ttl" :enable="false" />
                </i-col>
                <i-spacer />
                <i-col />
                <i-col />
              </i-row>
              <i-row>
                <i-col-header>발주일자</i-col-header>
                <i-col>
                  <iui-text :value="dateFormat(orderInfo.poDt)" width="100px" :enable="false" />
                </i-col>
                <i-spacer />
                <i-col-header>납품기일</i-col-header>
                <i-col>
                  <iui-text :value="dateFormat(orderInfo.dlvReqDt)" width="100px" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>납품장소</i-col-header>
                <i-col>
                  <iui-text :value="orderInfo.dlvLoc" :enable="false" />
                </i-col>
                <i-spacer />
                <i-col-header>납품방법</i-col-header>
                <i-col>
                  <iui-text :value="orderInfo.dlvCondRmrk" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>대금지급방법</i-col-header>
                <i-col>
                  <iui-text :value="orderInfo.payCondRmrk" :enable="false" />
                </i-col>
                <i-spacer />
                <i-col rowspan="5" colspan="2">
                  <iui-container-new type="css-flex">
                    <i-row>
                      <i-col>
                        <pmis-file-list
                          id="file"
                          theme="bullet"
                          title-visible
                          title="첨부파일"
                          :toolbar-visible="false"
                          :companyCode="orderInfo.orgCd"
                          :fileNumber="orderInfo.flNo"
                        />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>지체상금율</i-col-header>
                <i-col>
                  <iui-text type="rate" :value="orderInfo.delayRate" width="100px" :enable="false" />
                </i-col>
                <i-spacer />
              </i-row>
              <i-row>
                <i-col-header>특약사항</i-col-header>
                <i-col rowspan="3">
                  <iui-text :value="orderInfo.poRmrk" :enable="false" type="multi" />
                </i-col>
                <i-spacer />
              </i-row>
              <i-row v-for="i in 2" :key="i">
                <i-col v-for="j in 2" :key="j" />
              </i-row>
            </iui-container-new>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <OrderItemList />
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal name="poRejectOrderModal" :title="'발주서 반송'" :btns="poRejectOrderModalBtns" size-type="size4">
      <PoRejectOrder />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import {selectCheckPoState} from '@/view/bidding/po/mat/api/po.js';
import {confirmOrder} from '@/view/bidding/po/mat/api/order.js';

import OrderItemList from '@/view/bidding/poCustomer/mat/order/components/OrderItemList.vue';
import PoRejectOrder from '@/view/bidding/poCustomer/mat/components/popup/PoRejectOrder.vue';

export default {
  components: {OrderItemList, PoRejectOrder},
  data() {
    return {
      poRejectOrderModalBtns: [{name: '반송', callback: this.onRejectOrder}],
    };
  },
  computed: {
    isOrderSend() {
      return this.orderInfo.poSt === BIDDING_CONSTANTS.PROGRESS_STATE.PO_SIGN_READY;
    },
  },
  beforeCreate() {
    $mapGetters(this, ['orderInfo']);
  },
  methods: {
    getMakeFormat: poNo => COMMON_FUNCTION.getMakeFormat(poNo),
    dateFormat: date => dateFormat(date),
    async onCheckPoState(processCode) {
      const processType = {
        rejectOrder: ['발주서반송', this.openRejectOrderPopup],
        confirmOrder: ['발주서접수', this.onConfirmOrder],
      };
      const [title, processFunc] = processType[processCode];

      const param = {
        poNo: this.orderInfo.poNo,
        contSeq: this.orderInfo.contSeq,
        isSupplierPage: true,
        processCode,
      };

      const response = await selectCheckPoState(param);
      const checkMessage = response.data;
      if (checkMessage) {
        await this.$alert({title, message: `${checkMessage}`});
        this.callEvent({name: 'OrderList_onSearch'});
      } else {
        processFunc();
      }
    },
    openRejectOrderPopup() {
      this.$modal.show('poRejectOrderModal');
    },
    onRejectOrder() {
      this.callEvent({
        name: 'PoRejectOrder_Reject',
        param: () => {
          this.$modal.hide('poRejectOrderModal');

          this.callEvent({name: 'OrderList_onSearch'});
        },
      });
    },
    async onConfirmOrder() {
      const title = '발주서 접수';
      const message = '발주서를 접수하시겠습니까?';

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      confirmOrder(this.orderInfo.poNo, this.orderInfo.contSeq)
        .then(response => {
          if (response.data) {
            this.callEvent({name: 'OrderList_onSearch', param: this.orderInfo.poNo});
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            let msg = ex.message;
            if (ex.description) {
              msg += `<br/>- ${ex.description}`;
            }
            this.$alert({title: ex.title, message: msg});
          }
        });
    },
  },
};
</script>

<style></style>
