<template>
  <pmis-content-box :loading="loading">
    <template #header-left>
      <label>계획년월</label>
      <div class="ml5"></div>
      <iui-datepicker name="reqYm" type="month" :value="reqYm" @change="onReqYmChange($event)" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="sheet = $event"
      :events="{onSearchFinish}"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOptions/ExecutPxcondPlanSheet.js';
import {selectContExecPlanList} from '../api/workPlanRegist.js';
export default {
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      reqYm: '',
      loading: false,
    };
  },
  created() {
    this.addEvent({
      name: 'selectContExecPlanList',
      func: this.onSearch,
    });
  },
  methods: {
    onSearchFinish() {
      this.makeSubTotal();
    },
    async onSearch() {
      this.loading = true;
      try {
        const response = await selectContExecPlanList({reqYm: this.reqYm});
        const contExecPlanList = response.data;
        const reqYm =
          (contExecPlanList.length && contExecPlanList[0].reqYm) || this.reqYm || $_getCurrentDate().slice(0, 6);
        this.reqYm = reqYm;
        this.loadSearchData = contExecPlanList;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    makeSubTotal() {
      this.sheet.makeSubTotal(
        [
          {
            stdCol: 'mgName',
            sumCols: 'execAmt|planExecAmt',
            captionCol: [{col: 'mgName', val: '중공종계', span: 6}],
          },
          {
            stdCol: 'lgName',
            sumCols: 'execAmt|planExecAmt',
            captionCol: [{col: 'lgName', val: '대공종계', span: 6}],
          },
          {
            stdCol: 'total',
            sumCols: 'execAmt|planExecAmt',
            captionCol: [{col: 'total', val: '현장계', span: 7}],
          },
        ],
        0
      );
      this.sheet.getSubTotalRows().subTotal.forEach(subTotal =>
        subTotal.forEach(total => {
          this.sheet.setAttribute(total, 'mgName', 'Align', 'Left');
          this.sheet.setAttribute(total, 'lgName', 'Align', 'Left');
        })
      );
    },
    onReqYmChange(reqYm) {
      this.reqYm = reqYm;
      this.onSearch();
    },
  },
};
</script>

<style>
._total {
  border-left: none;
  border-right: none;
  padding: 0px;
}
</style>
