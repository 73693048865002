<template>
  <pmis-content-box>
    <iui-container-new type="table" theme="bullet" header-width="110px">
      <i-row>
        <i-col-header>현장</i-col-header>
        <i-col>
          <iui-text :value="spotDeptcd" :enable="false" width="120px" />
          <iui-text :value="spotDeptnm" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>입찰공고번호</i-col-header>
        <i-col>
          <iui-text :value="rfqNo2" :enable="false" width="120px" />
          <iui-text :value="ttl" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>변경전마감시간</i-col-header>
        <i-col>
          <iui-text :value="bidExpireYmd" width="90px" align="center" :enable="false" />
          <iui-timepicker width="30px" :value="getTime(bidExpireHh, bidExpireMm)" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>변경후마감시간</i-col-header>
        <i-col>
          <iui-datepicker :value.sync="extendYmd" />
          <iui-timepicker width="30px" :value="getTime(extendHh, extendMm)" @change="onChangeTime('extend', $event)" />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import COMMON_FUNCTION from '@/view/bidding/js/BiddingUtils.js';
import {selectRfqInfo, extend} from '@/view/bidding/rfq/mat/api/rfq.js';
import {selectSubRfqInfo, extend as subExtend} from '@/view/bidding/rfq/sub/api/subRfq.js';
import IuiTimepicker from '@/components/common/IuiTimepicker.vue';

const selectRfqInfoApi = {mat: selectRfqInfo, sub: selectSubRfqInfo};
const extendApi = {mat: extend, sub: subExtend};

export default {
  components: {IuiTimepicker},
  props: {
    bidType: {
      type: String,
      required: true,
    },
    rfqNo: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      spotDeptcd: '',
      spotDeptnm: '',
      rfqNo2: '',
      ttl: '',
      bidExpireYmd: '',
      bidExpireHh: '00',
      bidExpireMm: '00',
      extendYmd: $_getCurrentDate(),
      extendHh: '00',
      extendMm: '00',
    };
  },
  created() {
    this.addEvent({name: 'Extend_Save', func: this.onSave});
  },
  mounted() {
    this.onSearch();
  },
  methods: {
    async onSearch() {
      const response = await selectRfqInfoApi[this.bidType](this.rfqNo);

      const rfqInfo = response.data;
      this.spotDeptcd = rfqInfo.spotDeptcd;
      this.spotDeptnm = rfqInfo.spotDeptnm;
      this.rfqNo2 = COMMON_FUNCTION.getMakeFormat(rfqInfo.rfqNo);
      this.ttl = rfqInfo.ttl;

      this.bidExpireYmd = rfqInfo.bidExpireYmd;
      this.bidExpireHh = rfqInfo.bidExpireHh;
      this.bidExpireMm = rfqInfo.bidExpireMm;
    },
    onChangeTime(key, value) {
      const hour = value.split(':')[0];
      const minute = value.split(':')[1];
      this[key + 'Hh'] = hour;
      this[key + 'Mm'] = minute;
    },
    getTime(hour, minute) {
      hour = hour ? hour : '00';
      minute = minute ? minute : '00';
      return `${hour}:${minute}`;
    },
    async onSave(callback) {
      if (!this.extendYmd.length || !this.extendHh.length || !this.extendMm.length) {
        this.$alert({title: '마감시간연장', message: '연장될 마감시간을 입력해야 합니다.'});
        return;
      }
      if (
        this.extendHh.length > 2 ||
        this.extendHh < 0 ||
        this.extendHh > 23 ||
        this.extendMm.length > 2 ||
        this.extendMm < 0 ||
        this.extendMm > 59
      ) {
        this.$alert({title: '마감시간연장', message: '연장될 마감시간을 다시 한 번 확인하시기 바랍니다.'});
        return;
      }

      const [year, month, day] = dateFormat(this.extendYmd).split('-');
      const hour = this.extendHh;
      const minute = this.extendMm;

      const date1 = new Date(year, month - 1, day, hour, minute);
      const date2 = new Date();

      if (date1.getTime() <= date2.getTime()) {
        this.$alert({title: '마감시간연장', message: '연장될 마감시간은 현재시간 이후로만 가능합니다.'});
        return;
      }

      const title = '마감시간연장';
      const message = '입찰 마감시간을 연장하시겠습니까?';

      if (!(await this.$confirm({title, message}))) {
        return;
      }

      extendApi[this.bidType](this.rfqNo, this.extendYmd, this.extendHh, this.extendMm)
        .then(response => {
          if (response.status == 200) {
            callback(this.rfqNo);
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            this.$alert(ex);
          }
        });
    },
  },
};
</script>

<style></style>
