export default that => {
  return {
    Cfg: {
      CanEdit: 0,
      HeaderMerge: 4,
      IgnoreFocused: 1,
    },
    Def: {
      Row: {
        CanFocus: false,
      },
    },
    Cols: [
      {
        Header: ['장비명', '장비명'],
        Name: 'equipNm',
        FormulaRow: '합계',
        Align: 'Center',
        RelWidth: 1,
      },
      {
        Header: ['규격', '규격'],
        Name: 'size',
        Align: 'Center',
        FormulaRow: '합계',
        RelWidth: 1,
      },
      {
        Header: ['금일', {Value: '가동시간', Color: '#dff9fb'}],
        Name: 'mdRunQty',
        FormulaRow: 'Sum',
        Extend: that.$ibsheetHelper.preset.number,
        RelWidth: 1,
      },
      {
        Header: ['금일', {Value: '단가', Color: '#dff9fb'}],
        Name: 'mdRunPrice',
        FormulaRow: 'Sum',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        RelWidth: 1,
      },
      {
        Header: ['금일', {Value: '장비비', Color: '#dff9fb'}],
        Name: 'mdRunAmt',
        FormulaRow: 'Sum',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
      },
      {
        Header: ['금월', {Value: '가동시간', Color: '#dff9fb'}],
        Name: 'mmRunQty',
        FormulaRow: 'Sum',
        Extend: that.$ibsheetHelper.preset.number,
        RelWidth: 1,
      },
      {
        Header: ['금월', {Value: '단가', Color: '#dff9fb'}],
        Name: 'mmRunPrice',
        FormulaRow: 'Sum',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        RelWidth: 1,
      },
      {
        Header: ['금월', {Value: '장비비', Color: '#dff9fb'}],
        Name: 'mmRunAmt',
        FormulaRow: 'Sum',
        Extend: that.$ibsheetHelper.preset.amount,
        RelWidth: 1,
      },
    ],
  };
};
