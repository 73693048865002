<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col min-width="630px">
        <form onsubmit="return false;">
          <iui-container-new type="table" theme="bullet">
            <colgroup>
              <col width="90px" />
              <col />
              <col width="90px" />
              <col />
              <col width="90px" />
              <col />
            </colgroup>
            <i-row>
              <i-col-header>거래처</i-col-header>
              <i-col colspan="5">
                <iui-text :value="orderNm" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>기성횟수</i-col-header>
              <i-col>
                <iui-text :value="pxcondInfo.reqCntTemp" :enable="false" width="90px" />
              </i-col>
              <i-col-header required>기성구분</i-col-header>
              <i-col>
                <iui-select
                  width="90px"
                  :p-code="$getConstants('REQ_DS').code"
                  :value="pxcondInfo.reqDs"
                  :enable="status.isNew"
                  @change="value => onChange('reqDs', value)"
                  required
                  :errorMessage="{title: '기성구분', message: '기성구분을 선택해야합니다.'}"
                />
              </i-col>
              <template v-if="pxcondInfo.reqDs == '000001' && status.isNew">
                <i-col-header required>기성실적구분</i-col-header>
                <i-col>
                  <iui-select
                    width="90px"
                    :items="expectedOptions"
                    :value="pxcondInfo.expected || $getConstants('EXPECTED_N').code"
                    :enable="status.isNew"
                    @change="value => onChange('expected', value)"
                    required
                    :errorMessage="{title: '기성실적구분', message: '기성실적구분을 선택해야합니다.'}"
                  />
                </i-col>
              </template>
            </i-row>
            <i-row>
              <i-col-header required>기성년월</i-col-header>
              <i-col>
                <iui-datepicker
                  type="month"
                  :isIconClear="false"
                  :value="pxcondInfo.reqYm"
                  :enable="status.isNew"
                  @change="onChange('reqYm', $event)"
                  required
                  :errorMessage="{title: '기성년월', message: '기성년월을 입력해주세요.'}"
                />
              </i-col>
              <i-col-header>검사년월일</i-col-header>
              <i-col>
                <iui-datepicker
                  :value="pxcondInfo.reqInspectDate"
                  :enable="status.isEnable"
                  @change="onChange('reqInspectDate', $event)"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col colspan="2">
                <pmis-content-box>
                  <template #title>기성금액</template>
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header required>공급가</i-col-header>
                      <i-col>
                        <iui-text
                          type="amount"
                          name="wamt"
                          :value="pxcondInfo.wamt"
                          :enable="status.isEnable"
                          :readonly="status.isPxcond"
                          required
                          :errorMessage="{title: '기성금액', message: '기성금액을 입력해주세요.'}"
                          @change="onChangeAmt('w', 'wamt', $event)"
                          @click="onClickAmt"
                          width="120px"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>부가세</i-col-header>
                      <i-col>
                        <iui-text
                          type="amount"
                          name="wvatAmt"
                          :value="pxcondInfo.wvatAmt"
                          :enable="false"
                          width="120px"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>비과세</i-col-header>
                      <i-col>
                        <iui-text
                          type="amount"
                          name="wtaxexe"
                          :value="pxcondInfo.wtaxexe"
                          :enable="status.isEnable"
                          @change="onChangeAmt('w', 'wtaxexe', $event)"
                          width="120px"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>기성금액</i-col-header>
                      <i-col>
                        <iui-text
                          type="amount"
                          name="wsumAmt"
                          :value="pxcondInfo.wsumAmt"
                          :enable="false"
                          width="120px"
                        />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </pmis-content-box>
              </i-col>
              <i-col colspan="2">
                <pmis-content-box>
                  <template #title>선급금 정산금액</template>
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header>공급가</i-col-header>
                      <i-col>
                        <iui-text
                          type="amount"
                          name="pamt"
                          :value="pxcondInfo.pamt"
                          :enable="status.isPxcond && !!pxcondInfo.wamt && isRemainPamt && status.isEnable"
                          :bindArray="bindArray"
                          @change="onChangeAmt('p', 'pamt', $event)"
                          width="120px"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>부가세</i-col-header>
                      <i-col>
                        <iui-text
                          type="amount"
                          name="pvatAmt"
                          :value="pxcondInfo.pvatAmt"
                          :enable="false"
                          width="120px"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>비과세</i-col-header>
                      <i-col>
                        <iui-text
                          type="amount"
                          name="ptaxexe"
                          :value="pxcondInfo.ptaxexe"
                          :enable="status.isPxcond && !!pxcondInfo.wamt && isRemainPamt && status.isEnable"
                          :bindArray="bindArray"
                          @change="onChangeAmt('p', 'ptaxexe', $event)"
                          width="120px"
                        />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>정산금액</i-col-header>
                      <i-col>
                        <iui-text
                          type="amount"
                          name="psumAmt"
                          :value="pxcondInfo.psumAmt"
                          :enable="false"
                          width="120px"
                        />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </pmis-content-box>
              </i-col>
              <i-col colspan="2">
                <pmis-content-box>
                  <template #title>기성청구금액</template>
                  <iui-container-new type="table" theme="bullet">
                    <i-row>
                      <i-col-header>공급가</i-col-header>
                      <i-col>
                        <iui-text type="amount" :value="pxcondInfo.ramt" :enable="false" width="120px" />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>부가세</i-col-header>
                      <i-col>
                        <iui-text type="amount" :value="pxcondInfo.rvatAmt" :enable="false" width="120px" />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>비과세</i-col-header>
                      <i-col>
                        <iui-text type="amount" :value="pxcondInfo.rtaxexe" :enable="false" width="120px" />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>청구금액</i-col-header>
                      <i-col>
                        <iui-text type="amount" :value="pxcondInfo.rsumAmt" :enable="false" width="120px" />
                      </i-col>
                    </i-row>
                  </iui-container-new>
                </pmis-content-box>
              </i-col>
            </i-row>
            <i-row>
              <i-col colspan="5">
                <iui-container-new type="table" theme="bullet">
                  <i-row>
                    <i-col-header>세금계산서</i-col-header>
                    <i-col>
                      <iui-text
                        width="155px"
                        :value="pxcondInfo.billNo"
                        :css="clickable_inputStyle"
                        :enable="!!pxcondInfo.billNo"
                        readOnly
                        @click="onShowTaxInvoice(1)"
                      />
                      <iui-button
                        v-if="!status.isNew && !status.isExistBill"
                        value="발행"
                        @click="onShowTaxInvoice(1)"
                      />
                      <pmis-file-list
                        v-if="!status.isNew && !status.isExistBill"
                        :modal="true"
                        modalTitle="세금계산서첨부"
                        modalBtnNm="첨부"
                        :fileNumber="pxcondInfo.flNo1"
                        flNoCol="flNo1"
                        :id="'fileComp1' + _uid"
                        @delete-complete="onDeleteComplete('flNo1', $event)"
                        :toolbarVisible="status.isLastReqCnt"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>계산서</i-col-header>
                    <i-col>
                      <iui-text
                        width="155px"
                        :value="pxcondInfo.taxexeNo"
                        :css="clickable_inputStyle"
                        :enable="!!pxcondInfo.taxexeNo"
                        readOnly
                        @click="onShowTaxInvoice(2)"
                      />
                      <template v-if="!!pxcondInfo.rtaxexe">
                        <iui-button
                          v-if="!status.isNew && !status.isExistTaxexe"
                          value="발행"
                          @click="onShowTaxInvoice(2)"
                        />
                        <pmis-file-list
                          v-if="!status.isNew && !status.isExistTaxexe"
                          :modal="true"
                          modalTitle="세금계산서첨부"
                          modalBtnNm="첨부"
                          :fileNumber="pxcondInfo.flNo2"
                          flNoCol="flNo2"
                          :id="'fileComp2' + _uid"
                          @delete-complete="onDeleteComplete('flNo2', $event)"
                          :toolbarVisible="status.isLastReqCnt"
                        />
                      </template>
                    </i-col>
                  </i-row>
                </iui-container-new>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>비고</i-col-header>
              <i-col colspan="5" rowspan="5">
                <iui-text
                  type="multi"
                  :value="pxcondInfo.rmk"
                  max-length="2000"
                  :enable="status.isEnable"
                  @change="onChange('rmk', $event)"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col></i-col>
            </i-row>
            <i-row>
              <i-col></i-col>
            </i-row>
            <i-row>
              <i-col></i-col>
            </i-row>
            <i-row>
              <i-col></i-col>
            </i-row>
          </iui-container-new>
        </form>
      </i-col>
      <i-spacer />
      <i-col width="620px">
        <iui-container-new type="css-flex">
          <i-row min-height="200px">
            <i-col>
              <pmis-content-box>
                <template #title>누계기성정보</template>
                <ib-sheet
                  :uid="_uid"
                  :options="sheetOpt1"
                  :loadSearchData="cumulativePrepaymentInfo"
                  @loadSheet="sheet1 = $event"
                />
              </pmis-content-box>
            </i-col>
          </i-row>
          <i-spacer />
          <i-row min-height="200px">
            <i-col>
              <pmis-content-box>
                <template #title>선급금정산정보</template>
                <ib-sheet
                  :uid="_uid"
                  :options="sheetOpt2"
                  :loadSearchData="prepaidPaymentsSettlementInfo"
                  @loadSheet="sheet2 = $event"
                />
              </pmis-content-box>
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
    </i-row>

    <iui-modal name="PxcondTaxInvoiceModal" title="전자세금계산서" width="665px" height="700px" @closed="onCloseModal">
      <PxcondTaxInvoice @changeState="onChangeState" />
    </iui-modal>
  </iui-container-new>
</template>

<script>
import sheetOpt1 from './sheetOptions/PxcondInfoSheet1.js';
import sheetOpt2 from './sheetOptions/PxcondInfoSheet2.js';
import {
  updateFlNo,
  selectPxcondDetailInfo,
  selectPrepaymentInfo,
  savePxcondDetailInfo,
  deletePxcondDetailInfos,
} from '@/view/pxcondRqestManage/pxcondDtlsRegist/api/pxcondDtlsRegist.js';

import PxcondTaxInvoice from './popup/PxcondTaxInvoice.vue';

export default {
  components: {PxcondTaxInvoice},
  data() {
    return {
      sheetOpt1: sheetOpt1(this),
      sheetOpt2: sheetOpt2(this),
      loadSearchData1: [],
      loadSearchData2: [],
      bindArray: ['setPxcondInfo'],
      modalChangeState: false,

      clickable_inputStyle: {
        cursor: 'pointer',
        color: '#FF0000',
        'text-align': 'center',
        'text-decoration': 'underline',
        'font-weight': 'bold',
        'background-color': '#F0F0F0',
      },
      expectedOptions: [
        {
          value: $getConstants('EXPECTED_Y').code,
          text: $getConstants('EXPECTED_Y').name,
        },
        {value: $getConstants('EXPECTED_N').code, text: $getConstants('EXPECTED_N').name},
      ],
    };
  },
  computed: {
    internalRamt() {
      return this.pxcondInfo.ramt || 0;
    },
    internalRtaxexe() {
      return this.pxcondInfo.rtaxexe || 0;
    },
    isRemainPamt() {
      return this.prepaymentInfo.remainPamt > 0;
    },
  },
  beforeCreate() {
    $mapGetters(this, [
      'orderNm',
      'maxReqYm',
      'maxReqCnt',
      'pxcondInfo',
      'cumulativePrepaymentInfo',
      'prepaidPaymentsSettlementInfo',
      'prepaymentInfo',
      'status',
    ]);
    $mapMutations(this, [
      'setPxcondInfo',
      'initPxcondInfo',
      'initAllPxcondAmt',
      'setIsNew',
      'setPrepaymentInfo',
      'setCumulativePrepaymentInfo',
      'setPrepaidPaymentsSettlementInfo',
    ]);
    $mapActions(this, ['init']);
  },
  created() {
    this.addEvent([
      {name: 'PxcondInfo_save', func: this.onSave},
      {name: 'PxcondInfo_calcPxcondAndPrepayAmt', func: this.onCalcAmt},
    ]);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },
  methods: {
    onAdd() {
      this.init();
      this.setIsNew(true);
      const {prjCd, orderNm} = this.getPrjInfo();
      this.setPxcondInfo({
        pgmCd: this.pgmCd,
        prjCd,
        reqCnt: 0,
        reqYm: $_getYearMonth(),
        reqDs: $getConstants('REQ_DS_PXCOND').code,
        orderNm,
      });

      selectPxcondDetailInfo({reqCnt: this.maxReqCnt}).then(response => {
        this.setCumulativePrepaymentInfo(response.data.pxcondDetailList);
        this.setPrepaidPaymentsSettlementInfo(response.data.pxcondPrepaymentList);
      });

      selectPrepaymentInfo().then(response => {
        this.setPrepaymentInfo(response.data);
      });
    },
    onClickAmt() {
      if (this.status.isPxcond) {
        this.callEvent({name: 'PxcondDtlsRegist_moveTab', param: 1});
      }
    },
    async onChange(target, event) {
      this.setPxcondInfo({[target]: typeof event === 'object' ? event.target.value : event});

      if (target == 'reqYm') {
        const reqYm = Number(this.pxcondInfo.reqYm);
        const maxReqYm = Number(this.maxReqYm);
        if (reqYm && reqYm < maxReqYm) {
          const y = String(maxReqYm).substring(0, 4);
          const m = String(maxReqYm).substring(4);
          await this.$alert({
            title: '기성년월',
            message: `현재 기성진행년월은 ${y}년 ${m}월입니다.<br/>기성년월을 확인하시기 바랍니다.`,
          });
          this.setPxcondInfo({reqYm: ''});
        }
      }

      if (target == 'reqYm') {
        this.initAllPxcondAmt();
      }

      if (target == 'reqDs') {
        this.setPxcondInfo({reqStrDate: '', reqEndDate: ''});
        this.initAllPxcondAmt();
      }
    },
    onChangeAmt(type, target, event) {
      const value = event.target.value;

      // 선급금 체크
      if (type == 'p') {
        const regex = /\B(?=(\d{3})+(?!\d))/g;
        const remainPamt = String(this.prepaymentInfo.remainPamt);
        const remainPtaxexe = String(this.prepaymentInfo.remainPtaxexe);

        const title = '선급금 정산금액';
        const message = `선급금 잔여정산금액 한도 내에서만 정산금액 등록이 가능합니다.`;

        if (target == 'pamt') {
          if (value > Number(remainPamt)) {
            this.$alert(
              {title, message: `${message}<br/>- 잔여정산금액 공급가 : ${remainPamt.replace(regex, ',')}원`},
              () => {
                setTimeout(() => {
                  this.setPxcondInfo({[target]: ''});
                  this.onCalcAmt(type);
                }, 100);
              }
            );
            return;
          }
        }
        if (target == 'ptaxexe') {
          if (value > Number(remainPtaxexe)) {
            this.$alert(
              {title, message: `${message}<br/>- 잔여정산금액 비과세 : ${remainPtaxexe.replace(regex, ',')}원`},
              () => {
                setTimeout(() => {
                  this.setPxcondInfo({[target]: ''});
                  this.onCalcAmt(type);
                }, 100);
              }
            );
            return;
          }
        }
      }

      // 금액계산
      this.setPxcondInfo({[target]: value});
      this.onCalcAmt(type);
    },
    onCalcAmt(type) {
      // 기성금액, 선급금 정산금액 계산
      this.onCalcPxcondAndPrepayAmt(type);

      // 청구금액 계산
      this.onCalcRequestAmt();
    },
    onCalcPxcondAndPrepayAmt(type) {
      const amt = Number(this.pxcondInfo[`${type}amt`]) || 0;
      const taxexe = Number(this.pxcondInfo[`${type}taxexe`]) || 0;
      const vatAmt = $_getVatAmt(amt, taxexe);
      const sumAmt = $_getSumAmt(amt, vatAmt);
      this.setPxcondInfo({
        [`${type}amt`]: amt,
        [`${type}vatAmt`]: vatAmt,
        [`${type}taxexe`]: taxexe,
        [`${type}sumAmt`]: sumAmt,
      });
    },
    onCalcRequestAmt() {
      this.setPxcondInfo({
        ramt: Number(this.pxcondInfo.wamt) - Number(this.pxcondInfo.pamt),
        rvatAmt: Number(this.pxcondInfo.wvatAmt) - Number(this.pxcondInfo.pvatAmt),
        rtaxexe: Number(this.pxcondInfo.wtaxexe) - Number(this.pxcondInfo.ptaxexe),
        rsumAmt: Number(this.pxcondInfo.wsumAmt) - Number(this.pxcondInfo.psumAmt),
      });
    },
    async onSave() {
      let saveList = new Array();

      if (this.status.isPxcond) {
        this.callEvent({name: 'PxcondDetailInfo_getSaveList', param: data => (saveList = data)});

        if (0 === saveList.length) {
          this.$alert({title: '저장', message: '저장할 기성내역이 존재하지 않습니다.'});
          return;
        }
      }

      savePxcondDetailInfo({...this.pxcondInfo, cud: this.status.isNew ? 1 : 2, saveList})
        .then(() => {
          this.callEvent({name: 'PxcondDtlsRegist_search'});
        })
        .catch(ex => {
          this.$alert({title: '저장', message: `저장을 실패했습니다.<br/>${ex}`});
        });
    },
    async onDelete() {
      if (!this.pxcondInfo.reqCnt) {
        this.$alert({title: '삭제', message: '삭제할 정보가 없습니다.'});
        return;
      }
      if (this.status.isNew) {
        this.$alert({title: '삭제', message: '신규작성 정보는 삭제할 수 없습니다.'});
        return;
      }
      if (this.status.isExistCollect) {
        this.$alert({title: '삭제', message: '수금내역정보가 존재하는 기성내역입니다.<br/>삭제할 수 없습니다.'});
        return;
      }

      if (!(await this.$confirm({title: '삭제', message: '삭제하시겠습니까?'}))) {
        return;
      }

      deletePxcondDetailInfos({
        pgmCd: this.pxcondInfo.pgmCd,
        prjCd: this.pxcondInfo.prjCd,
        reqCnt: this.pxcondInfo.reqCnt,
        reqYm: this.pxcondInfo.reqYm,
        reqDs: this.pxcondInfo.reqDs,
      })
        .then(response => {
          if (response.status == 200) {
            this.callEvent({name: 'PxcondDtlsRegist_search'});
          }
        })
        .catch(ex => {
          this.$alert({title: '삭제', message: `삭제를 실패하였습니다.<br/>${ex}`});
        });
    },
    async onShowTaxInvoice(taxType) {
      const internalRamt = Number(this.internalRamt);
      const internalRtaxexe = Number(this.internalRtaxexe);

      const ramt = Number(this.pxcondInfo.ramt);
      const rtaxexe = Number(this.pxcondInfo.rtaxexe);

      if (1 == taxType) {
        if (!internalRamt) {
          this.$alert({
            title: '세금계산서',
            message: '청구 공급가가 존재하지 않습니다.',
          });
          return;
        } else if (internalRamt !== ramt) {
          this.$alert({
            title: '세금계산서',
            message: '청구 공급가가 변경되었습니다.<br/>변경된 정보를 저장해야 합니다.',
          });
          return;
        }
      }
      if (2 == taxType) {
        if (!rtaxexe) {
          this.$alert({
            title: '계산서',
            message: '청구 비과세가 존재하지 않습니다.',
          });
          return;
        } else if (internalRtaxexe !== rtaxexe) {
          this.$alert({
            title: '계산서',
            message: '청구 비과세가 변경되었습니다.<br/>변경된 정보를 저장해야 합니다.',
          });
          return;
        }
      }

      const modalParam = {
        pgmCd: this.pxcondInfo.pgmCd,
        prjCd: this.pxcondInfo.prjCd,
        reqCnt: this.pxcondInfo.reqCnt,
        orderCode: this.getPrjInfo().orderCode,
        taxType: taxType,
        enable: this.status.isLastReqCnt,
      };
      this.$store.commit('setModalParam', modalParam);
      this.$modal.show('PxcondTaxInvoiceModal');
    },
    onChangeState() {
      this.modalChangeState = true;
    },
    onCloseModal() {
      if (this.modalChangeState) {
        this.modalChangeState = false;
        this.callEvent({name: 'PxcondDtlsRegist_search'});
      }
    },
    onDeleteComplete(target, flNo) {
      updateFlNo({
        pgmCd: this.pxcondInfo.pgmCd,
        prjCd: this.pxcondInfo.prjCd,
        reqCnt: this.pxcondInfo.reqCnt,
        updateType: target,
        [target]: flNo,
      }).then(response => {
        if (response.status == 200) {
          this.callEvent({name: 'PxcondDtlsRegist_search'});
        }
      });
    },
  },
};
</script>

<style></style>
