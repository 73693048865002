<template>
  <pmis-tab-box>
    <template #title>구매요청목록</template>
    <pmis-content-box>
      <template #header-left>
        <iui-text prefix="요청제목" type="search" width="185px" :value.sync="searchOption.ttl" @enter="onSearch" />
        <iui-datepicker
          prefix="요청기간"
          :value.sync="searchOption.frDt"
          @change="onSearch"
          :group="{grpNm: 'searchOptionDt', seq: 1}"
        />
        <iui-datepicker
          prefix="~"
          :value.sync="searchOption.toDt"
          @change="onSearch"
          :group="{grpNm: 'searchOptionDt', seq: 2}"
        />
        <iui-select
          prefix="진행상태"
          :p-code="$getConstants('BIDDING_ST').code"
          defaultCd="A"
          :value.sync="searchOption.prSt"
          @change="onSearch"
        />
        <iui-button value="검색" @click="onSearch" />
      </template>

      <ib-sheet
        :uid="_uid"
        :options="sheetOpt"
        :loadSearchData="prList"
        :events="{
          onSearchFinish: sheet_onSearchFinish,
          onClick: sheet_onClick,
        }"
      />
    </pmis-content-box>

    <ApprovalPop :parentId="_uid" @approvalEmit="onApprovalEmit" />
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/prList.js';
import BiddingApprovalMixin from '@/view/bidding/js/biddingApprovalMixin.js';

export default {
  mixins: [BiddingApprovalMixin],
  data() {
    return {
      sheetOpt: sheetOpt(this),
      focusKey: '',

      searchOption: {
        ttl: '',
        frDt: '',
        toDt: '',
        prSt: '',
      },
    };
  },
  beforeCreate() {
    $mapGetters(this, ['prList', 'prInfo', 'prStatus']);
    $mapMutations(this, ['initPrInfo', 'setPrItemList']);
    $mapActions(this, ['searchPrList', 'searchPrInfo']);
  },
  created() {
    this.addFuncPrj(this.onSearch);
    this.addEvent([{name: 'PrList_Search', func: this.onSearch}]);
  },
  methods: {
    async onSearch(focusKey = '') {
      this.focusKey = typeof focusKey === 'string' ? focusKey : '';

      this.initPrInfo();
      this.setPrItemList([]);
      this.callEvent({name: 'PrDetailInfo_ClearFile'});

      const param = {
        orgCd: this.pgmCd,
        spotDeptcd: this.prjCd,
        ...this.searchOption,
      };
      this.searchPrList(param);
    },
    async onSetRow(row, openPrApproval = undefined) {
      await this.searchPrInfo(row.prNo);

      if (!this.prInfo.flNo) {
        this.callEvent({name: 'PrDetailInfo_ClearFile'});
      }

      this.canSave = this.prStatus.isPrReady;
      this.canDelete = this.prStatus.isPrReady;

      if (openPrApproval) {
        this.openPrApproval();
      }
    },
    sheet_onSearchFinish(e) {
      if (e.sheet.getTotalRowCount()) {
        const focusRow = this.focusKey
          ? e.sheet.getDataRows().find(row => row.prNo === this.focusKey)
          : e.sheet.getFirstRow();

        e.sheet.focus(focusRow);
        this.onSetRow(focusRow);
      } else {
        this.canSave = false;
        this.canDelete = false;
      }
      this.focusKey = '';
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data') {
        this.onSetRow(e.row, e.col === 'sncNo' && e.row.sncNo);
      }
    },
    onApprovalEmit() {
      this.onSearch(this.prInfo.prNo);
    },
  },
};
</script>

<style></style>
