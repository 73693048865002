export default that => {
  return {
    Cfg: {
      CanEdit: 0,
    },
    Cols: [
      {Header: '코드', Name: 'code', Type: 'Text', Align: 'center', Width: 120},
      {Header: '코드명', Name: 'codeNm', Type: 'Text', Align: 'left', RelWidth: 1},
    ],
  };
};
