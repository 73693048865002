<template>
  <pmis-content-box>
    <template #title>계약차수</template>
    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="contSeqList"
      :events="{
        onSearchFinish: sheet_onSearchFinish,
        onClick: sheet_onClick,
      }"
    />

    <ApprovalPop :parentId="_uid" />
  </pmis-content-box>
</template>

<script>
import BiddingApprovalMixin from '@/view/bidding/js/biddingApprovalMixin.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';
import sheetOpt from './sheetOptions/poContSeqList.js';
import {selectPoSeqList} from '@/view/bidding/po/mat/api/po.js';

export default {
  mixins: [BiddingApprovalMixin],
  props: {
    poNo: {
      type: String,
    },
    contSeq: {
      type: String,
    },
  },
  data() {
    return {
      sheetOpt: sheetOpt(this),
      contSeqList: [],
    };
  },
  computed: {
    isFirstContSeq() {
      return this.contSeq === BIDDING_CONSTANTS.CODES.FIRST_CONT_SEQ;
    },
  },
  created() {
    this.searchContSeqList();
  },
  methods: {
    async searchContSeqList() {
      if (this.poNo) {
        this.contSeqList = (await selectPoSeqList(this.poNo)).data;
      }
    },
    sheet_onSearchFinish(e) {
      if (e.sheet.getTotalRowCount()) {
        this.$emit('update:contSeq', e.sheet.getFirstRow().contSeq);
      }
    },
    sheet_onClick(e) {
      if (e.row.Kind === 'Data') {
        this.$emit('update:contSeq', e.row.contSeq);

        if (e.col === 'poSncNo' && e.row.poSncNo) {
          this.edmsInfo.edmsType = this.isFirstContSeq ? 'MAT_PO' : 'MAT_PO_CHANGE';

          this.edmsInfo.taskNo = `${this.poNo}-${this.contSeq}`;
          this.edmsInfo.sncNo = e.row.poSncNo;

          this.onCallApproval();
        }
      }
    },
  },
};
</script>

<style></style>
