<template>
  <Contract :isPopup="true" :eventNms="{onFocusedRowData: 'onFocusedRowDataContract'}" />
</template>

<script>
import Contract from '@/view/executDtls/components/Contract.vue';
export default {
  components: {
    Contract,
  },

  beforeCreate() {},
  created() {},
};
</script>

<style></style>
