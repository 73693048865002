export default that => {
  return {
    Cfg: {
      CanEdit: 0,
      SearchMode: 0,
    },
    Cols: [
      {
        Header: '문서제목',
        Name: 'tl',
        Type: 'Text',
        RelWidth: 1,
      },
      {
        Header: '첨부',
        Name: 'flCnt',
        Type: 'Text',
        Align: 'Center',
        Width: 60,
      },
      {
        Name: 'rgsDttm',
        Header: '기안일',
        Extend: that.$ibsheetHelper.preset.date,
        Width: 100,
      },
      {
        Name: 'sncNo',
        Type: 'Text',
        Visible: 0,
      },
    ],
  };
};
