export default that => {
  return {
    Cfg: {
      CanEdit: 1, // 1 : 전체 편집 가능 (default)
      HeaderCheck: 1,
      HeaderMerge: 6,
    },
    LeftCols: [
      {
        Header: ['투입일자', '투입일자'],
        Name: 'ioDate',
        Type: 'Text',
        Align: 'center',
        CanEdit: false,
        Width: 90,
        CustomFormat: '####-##-##',
      },
      {
        Header: ['작업분류', '작업분류'],
        Name: 'wbsPath',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
        CanEdit: false,
      },
      {
        Header: ['실행예산내역', '공종명'],
        Name: 'mgName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
        CanEdit: false,
      },
      {
        Header: ['실행예산내역', '내역코드'],
        Name: 'itemSeq',
        Type: 'Text',
        Align: 'center',
        Width: 70,
        CanEdit: false,
      },
      {
        Header: ['실행예산내역', '내역명'],
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
        CanEdit: false,
      },
      {
        Header: ['실행예산내역', '규격'],
        Name: 'ssize',
        Type: 'Text',
        Width: 200,
        CanEdit: false,
      },
      {
        Header: ['실행예산내역', '단위'],
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        Width: 50,
        CanEdit: false,
        Format: $getConstants('UNIT').code,
      },
    ],
    Cols: [
      {
        Header: ['투입자재정보', '순번'],
        Name: 'rowNo',
        Type: 'Int',
        Align: 'center',
        Width: 45,
        CanEdit: false,
      },
      {
        Header: ['투입자재정보', '자재코드'],
        Name: 'matCode',
        Type: 'Text',
        Align: 'center',
        Width: 170,
        CanEdit: false,
        ButtonFormula: e =>
          that.isSimpleExec ||
          (e.Row['costType'] == $getConstants('COST_TYPE_I').code && (e.Row['cud'] == '0' || e.Row['cud'] == '1'))
            ? 'Html'
            : '',
        ButtonText: '<input type="button" class="i_search" />',
        WidthPad: 50,
        OnClickSide: that.onShowInventory,
      },
      {
        Header: ['투입자재정보', '자재명'],
        Name: 'matName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
        CanEdit: false,
      },
      {
        Header: ['투입자재정보', '규격'],
        Name: 'matSize',
        Type: 'Text',
        Width: 200,
        CanEdit: false,
      },
      {
        Header: ['투입자재정보', '단위'],
        Name: 'matUnit',
        Type: 'Text',
        Align: 'center',
        Width: 50,
        CanEdit: false,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: ['투입자재정보', '수량'],
        Extend: that.$ibsheetHelper.preset.quantity,
        Name: 'qty',
        MinWidth: 90,
        CanEditFormula: () => that.monPjtCloseAt == 'N', //마감시 수정불가
      },
      {
        Header: ['투입자재정보', '단가'],
        Extend: that.$ibsheetHelper.preset.unitAmount,
        Name: 'price',
        Width: 100,
        CanEdit: false,
      },
      {
        Header: ['투입자재정보', '금액'],
        Extend: that.$ibsheetHelper.preset.amount,
        Name: 'amt',
        Width: 120,
        CanEdit: false,
        Formula: fr => fr.Row['qty'] * (fr.Row['price'] || 0),
      },
      {
        Header: ['투입자재정보', '외주'],
        Name: 'custName',
        Type: 'Text',
        Align: 'center',
        Width: 150,
        CanEdit: false,
        ButtonFormula: e => ((e.Row['cud'] == '0' || e.Row['cud'] == '1') && that.monPjtCloseAt == 'N' ? 'Html' : ''),
        ButtonText: '<input type="button" class="i_search" />',
        WidthPad: 50,
        OnClickSide: that.onShowPoCompleteList,
      },
      {
        Header: ['투입자재정보', '추가/삭제'],
        Name: 'add',
        TypeFormula: e => (that.monPjtCloseAt == 'N' ? 'Button' : 'Text'),
        Align: 'center',
        Width: 40,
        CanEdit: false,
        ButtonText: '<input type="button" class="i_plus" />',
        OnClick: that.matAdd,
      },
      {
        Header: ['투입자재정보', '추가/삭제'],
        Name: 'remove',
        TypeFormula: e => (that.monPjtCloseAt == 'N' ? 'Button' : 'Text'),
        Align: 'center',
        Width: 40,
        CanEdit: false,
        ButtonText: '<input type="button" class="i_minus" />',
        OnClick: that.matRemove,
      },
      {Name: 'ioGb', Visible: false},
      {Name: 'seq', Visible: false},
      {Name: 'wcode', Visible: false},
      {Name: 'headCode', Visible: false},
      {Name: 'midCode', Visible: false},
      {Name: 'itemCode', Visible: false},
      {Name: 'remQty', Visible: false, NoChanged: 1}, // 재고수량은 계산으로 값만 변경되게 처리
      {Name: 'remQtyBefore', Visible: false}, // 재고수량 원본
      {Name: 'poNoSubc', Visible: false},
      {Name: 'custCode', Visible: false},
      {Name: 'costType', Visible: false},
      {Name: 'wbsCd', Visible: false},
      {Name: 'wbsId', Visible: false},
    ],
  };
};
