<template>
  <pmis-content-box :loading="dateList.length == 0">
    <iui-container-new style="height:100%;">
      <i-row style="height: 29px;">
        <i-col style="color:#ed3648;">일</i-col>
        <i-col>월</i-col>
        <i-col>화</i-col>
        <i-col>수</i-col>
        <i-col>목</i-col>
        <i-col>금</i-col>
        <i-col style="color: #055bca">토</i-col>
      </i-row>
      <template v-if="dateList.length === 0">
        <i-row v-for="i in 5" :key="`blankRow_${i}`">
          <i-col v-for="j in 7" :key="`blankRow_${j}`" />
        </i-row>
      </template>
      <template v-else>
        <i-row v-for="(week, index) in dateList" :key="index" :style="`height: calc(100% / ${dateList.length});`">
          <i-col v-for="(day, index) in week" :key="index">
            <div style="display: flex; flex-direction: column; width: 100%; height: 100%;">
              <div style="flex: 0 0 20px; display: flex; width: 100%;">
                <div :style="`flex: 0 0 20px; font-weight: bold; color:${getTextColor(day)};`">
                  {{ day.phoDay.startsWith('0') ? day.phoDay.substring(1) : day.phoDay }}
                </div>
                <div v-if="day.holidayInfo" style="flex: 1;">{{ day.holidayInfo.dateName }}</div>
              </div>
              <div style="flex: 1;">
                <img
                  v-if="day.imgUrl"
                  :src="day.imgUrl"
                  style="width: 100%; cursor: pointer;"
                  @click="imageClick(day)"
                />
              </div>
            </div>
          </i-col>
        </i-row>
      </template>
    </iui-container-new>

    <iui-modal name="photoModal" title="사진상세정보">
      <pjt-photo-popup />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
import {selectPrjPhoto} from '@/components/portlet/api/portletData.js';
export default {
  mixins: [PortletMixin],
  props: {
    curDate: {
      Type: String,
    },
  },
  data() {
    return {
      dateList: [],
      holidays: [],
      FILE_URL:
        location.protocol.indexOf('https') !== -1 ? process.env.VUE_APP_FILE_URLS : process.env.VUE_APP_FILE_URL,
    };
  },
  watch: {
    curDate() {
      this.onSearch();
    },
  },
  created() {
    this.addEvent([{name: 'PjtPhoto_onSearch', func: this.onSearch}]);
  },
  methods: {
    onSearch() {
      selectPrjPhoto({prjCd: this.prjCd, phoDtYm: this.curDate.substring(0, 6)}).then(async response => {
        this.holidays = await $_getPublicHolidays(this.curDate.substring(0, 4), this.curDate.substring(4, 6));
        this.setCalendar(response.data);
      });
    },
    getImageUrl(flNo, grpNo) {
      return `${this.FILE_URL}/file/getImage?pgmCd=${this.pgmCd}&flNo=${flNo}&grpNo=${grpNo}&flDs=${
        $getConstants('FL_DS_THUMBNAIL').code
      }`;
    },
    setCalendar(data) {
      this.dateList = [];

      const firstDay = data[0];
      const lastDay = data.at(-1);

      let arr = [];
      for (let i = -Number(firstDay.weekday); i < data.length - Number(lastDay.weekday) + 6; i++) {
        let temp = {};
        let holidayInfo;
        if (data[i]) {
          let phoDay = data[i].phoDt.substring(6);
          if (this.holidays.length) {
            holidayInfo = this.holidays.find(holiday => String(holiday.locdate).substring(6) == phoDay);
          }
          temp = {...data[i], phoDay, holidayInfo};
          if (data[i].pgmCd) {
            temp.imgUrl = this.getImageUrl(data[i].flNo, data[i].grpNo);
          }
        } else {
          temp.phoDay = '';
        }

        arr.push(temp);
        if (i % 7 == 6 - Number(firstDay.weekday)) {
          this.dateList.push(arr);
          arr = [];
        }
      }
    },
    imageClick(day) {
      this.$modal.show('photoModal');
      setTimeout(() => {
        this.callEvent({name: 'PjtPhotoPopup_setPhotoList', param: day.phoDt});
      }, 10);
    },
    getTextColor(day) {
      let color = '';
      if (day.weekday == 0 || (day.holidayInfo && day.holidayInfo.isHoliday == 'Y')) {
        color = '#ed3648';
      }
      if (day.weekday == 6) {
        color = '#055bca';
      }
      return color;
    },
  },
};
</script>

<style scoped></style>
