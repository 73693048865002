export default that => {
  return {
    Cfg: {
      CanEdit: false,
    },
    Def: {
      Row: {
        CanFocus: 0,
        CanFormula: 1,
      },
    },
    Cols: [
      {
        Name: 'gb',
        Header: '구분',
        Width: 220,
        Format: {BUD: '실행예산금액', PRE: '전월누계', CUR: '당월', TOT: '당월누계', REM: '실행예산잔여금액'},
        Align: 'Center',
      },
      {
        Name: 'matAmt',
        Header: '재료비',
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'labAmt',
        Header: '노무비',
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'equipAmt',
        Header: '장비비(유류대포함)',
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'oprAmt',
        Header: '경비',
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'poAmt',
        Header: '외주비',
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'sumAmt',
        Header: '합계',
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(
            fr.Row['matAmt'] + fr.Row['labAmt'] + fr.Row['equipAmt'] + fr.Row['oprAmt'] + fr.Row['poAmt']
          );
        },
      },
    ],
  };
};
