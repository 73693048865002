class CodeData {
  constructor() {
    // 일정유형
    this.scdTpNo = [
      {text: '회의', value: '1'},
      {text: '출장', value: '2'},
      {text: '보고', value: '3'},
      {text: '행사', value: '4'},
      {text: '교육', value: '5'},
      {text: '일정', value: '6'},
      {text: '기타', value: '99'},
    ];

    // 반복설정
    this.rpttDs = [
      {label: '반복없음', value: '0'},
      {label: '매일', value: '1'},
      {label: '매주', value: '2'},
      {label: '매월', value: '3'},
      {label: '매년', value: '4'},
    ];

    this.weekDay = [
      {label: '일', value: '1'},
      {label: '월', value: '2'},
      {label: '화', value: '3'},
      {label: '수', value: '4'},
      {label: '목', value: '5'},
      {label: '금', value: '6'},
      {label: '토', value: '7'},
    ];

    this.odaDs = [{label: '하루종일', value: '1'}];

    this.hrList = this.setHrList();

    this.miList = this.setMiList();

    this.monthList = this.setMonthList();

    this.dayList = this.setDayList();
  }

  setHrList() {
    let hrCode = new Array();
    for (let i = 0; i < 24; i++) {
      hrCode.push({
        text: i < 10 ? `0${i}` : `${i}`,
        value: i < 10 ? `0${i}` : `${i}`,
      });
    }
    return hrCode;
  }

  setMiList() {
    let miCode = new Array();
    for (let i = 0; i < 60; i = i + 5) {
      miCode.push({
        text: i < 10 ? `0${i}` : `${i}`,
        value: i < 10 ? `0${i}` : `${i}`,
      });
    }
    return miCode;
  }

  setMonthList() {
    let monthCode = new Array();
    for (let i = 1; i <= 12; i++) {
      monthCode.push({
        text: i < 10 ? `0${i}` : `${i}`,
        value: i < 10 ? `0${i}` : `${i}`,
      });
    }
    return monthCode;
  }

  setDayList() {
    let dayCode = new Array();
    for (let i = 1; i <= 31; i++) {
      dayCode.push({
        text: i < 10 ? `0${i}` : `${i}`,
        value: i < 10 ? `0${i}` : `${i}`,
      });
    }
    return dayCode;
  }
}
export default CodeData;
