<template>
  <!-- 노무투입-포틀릿 -->
  <div>
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr>
          <th>소속명</th>
          <th>성명</th>
          <th>직종</th>
          <th>공수</th>
          <th>단가</th>
          <th>금액</th>
        </tr>
      </thead>
    </table>
    <div class="inner">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="(item, index) in laborInputList" :key="index">
            <td>{{ item.blnDepNm }}</td>
            <td>{{ item.empNm }}</td>
            <td>{{ item.jkdNm }}</td>
            <td>{{ item.mdInvestQty }}</td>
            <td>{{ item.mdInvestPrice }}</td>
            <td class="p_last">{{ item.mdAmt }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
import {selectLaborInput1} from '@/components/portlet/api/portletData.js';
export default {
  mixins: [PortletMixin],
  props: {
    curDate: {
      Type: String,
    },
  },
  data() {
    return {
      cnt: 6,
      laborInputList: [],
    };
  },
  watch: {
    curDate() {
      this.onSearch();
    },
  },
  methods: {
    setWidth() {
      this.tblWidth = this.$el.offsetWidth - 28;
    },
    onSearch() {
      selectLaborInput1({prjCd: this.prjCd, investDt: this.curDate}).then(
        response => (this.laborInputList = response.data)
      );
    },
  },
};
</script>

<style></style>
