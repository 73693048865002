import {selectInputCount} from '../../api/executDtls.js';
export default that => {
  return {
    Cfg: {
      CanEdit: 1,
      HeaderMerge: 6,
      SearchMode: 0,
    },
    Cols: [
      {
        Header: ['내역\n번호', '내역\n번호'],
        Name: 'itemSeq',
        Type: 'Text',
        Align: 'Center',
        Width: 60,
        CanEditFormula: e => that.confirm && e.Row.Added == 1,
      },
      {
        Header: ['자원\n구분', '자원\n구분'],
        Name: 'costType',
        Type: 'Text',
        Align: 'Center',
        Width: 60,
        CanEdit: 0,
      },
      {
        Header: ['실행\n코드', '실행\n코드'],
        Name: 'itemCode',
        Type: 'Text',
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: ['품명', '품명'],
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 200,
        CanEdit: 0,
      },
      {
        Header: ['규격', '규격'],
        Name: 'ssize',
        Type: 'Text',
        Width: 120,
        CanEdit: 0,
      },
      {
        Header: ['단위', '단위'],
        Name: 'unit',
        Type: 'Text',
        Align: 'Center',
        Width: 100,
        CanEdit: 0,
        Format: $getConstants('UNIT').code,
      },
      {
        Header: ['수량', '수량'],
        Name: 'qty',
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 85,
        CanEditFormula: fr => {
          return fr.Row['formula'] ? 0 : fr.Row['costType'] ? that.confirm : 0;
        },
        OnChange: async e => {
          if (e.row.itemSeq) {
            const param = {
              chgDegree: that.maxDgree,
              wcode: e.row.wcode,
              headCode: e.row.headCode,
              midCode: e.row.midCode,
              itemSeq: e.row.itemSeq,
              qty: e.row.qty,
            };
            const response = await selectInputCount(param);
            const inputCntGap = response.data.inputCntGap || 0;
            let chgGb = response.data.chgGb;
            if (inputCntGap < 0) {
              if (
                await that.$confirm({
                  title: '수량변경',
                  message: `${e.row.itemName}의 이미 투입된 수량이 존재하여 최소수량 ${-inputCntGap +
                    e.row.qty}으로 변경하시겠습니까?.`,
                })
              ) {
                e.row.qty = -inputCntGap + e.row.qty;
              } else {
                e.row.qty = e.row.qtyBeforeVal;
                chgGb = '';
              }
              e.sheet.refreshCell(e.row, e.col);
            }
            if (chgGb) {
              that.sheet.setValue(e.row, 'chgGb', '1', 1);
            }
          }

          if ([$getConstants('COST_TYPE_M').code, $getConstants('COST_TYPE_I').code].indexOf(e.row.costType) !== -1) {
            that.sheet.setValue(e.row, 'matAmt', e.row.qty * e.row.matPrice, 1);
          }
          if ([$getConstants('COST_TYPE_L').code, $getConstants('COST_TYPE_I').code].indexOf(e.row.costType) !== -1) {
            that.sheet.setValue(e.row, 'labAmt', e.row.qty * e.row.labPrice, 1);
          }
          if ([$getConstants('COST_TYPE_E').code, $getConstants('COST_TYPE_I').code].indexOf(e.row.costType) !== -1) {
            that.sheet.setValue(e.row, 'equipAmt', e.row.qty * e.row.equipPrice, 1);
          }
          if ([$getConstants('COST_TYPE_A').code, $getConstants('COST_TYPE_I').code].indexOf(e.row.costType) !== -1) {
            that.sheet.setValue(e.row, 'oprAmt', e.row.qty * e.row.oprPrice, 1);
          }
          that.sheet.setValue(e.row, 'amt', e.row.qty * e.row.price, 1);
        },
      },
      {
        Header: ['재료비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'matPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEditFormula: e =>
          e.Row['formula']
            ? 0
            : e.Row['matGiveYn']
            ? 0
            : that.confirm && e.Row['costType'] === $getConstants('COST_TYPE_M').code,

        OnChange: e => {
          e.row[e.col] = Math.floor(e.row[e.col]);
          that.sheet.setValue(e.row, 'matAmt', Math.floor(e.row.qty * e.row.matPrice), 1);
          that.sheet.setValue(e.row, 'price', e.row.matPrice + e.row.labPrice + e.row.equipPrice + e.row.oprPrice, 1);
          that.sheet.setValue(e.row, 'amt', Math.floor(e.row.qty * e.row.price), 1);
        },
      },
      {
        Header: ['재료비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'matAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: ['노무비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'labPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEditFormula: e =>
          e.Row['formula']
            ? 0
            : e.Row['matGiveYn']
            ? 0
            : that.confirm && e.Row['costType'] === $getConstants('COST_TYPE_L').code,
        OnChange: e => {
          e.row[e.col] = Math.floor(e.row[e.col]);
          that.sheet.setValue(e.row, 'labAmt', e.row.qty * e.row.labPrice, 1);
          that.sheet.setValue(e.row, 'price', e.row.matPrice + e.row.labPrice + e.row.equipPrice + e.row.oprPrice, 1);
          that.sheet.setValue(e.row, 'amt', e.row.qty * e.row.price, 1);
        },
      },
      {
        Header: ['노무비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'labAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: ['장비비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'equipPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEditFormula: e =>
          e.Row['formula']
            ? 0
            : e.Row['matGiveYn']
            ? 0
            : that.confirm && e.Row['costType'] === $getConstants('COST_TYPE_E').code,
        OnChange: e => {
          e.row[e.col] = Math.floor(e.row[e.col]);
          that.sheet.setValue(e.row, 'equipAmt', e.row.qty * e.row.equipPrice, 1);
          that.sheet.setValue(e.row, 'price', e.row.matPrice + e.row.labPrice + e.row.equipPrice + e.row.oprPrice, 1);
          that.sheet.setValue(e.row, 'amt', e.row.qty * e.row.price, 1);
        },
      },
      {
        Header: ['장비비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'equipAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: ['경비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'oprPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEditFormula: e =>
          e.Row['formula']
            ? 0
            : e.Row['matGiveYn']
            ? 0
            : that.confirm && e.Row['costType'] === $getConstants('COST_TYPE_A').code,
        OnChange: e => {
          e.row[e.col] = Math.floor(e.row[e.col]);
          that.sheet.setValue(e.row, 'oprAmt', e.row.qty * e.row.oprPrice, 1);
          that.sheet.setValue(e.row, 'price', e.row.matPrice + e.row.labPrice + e.row.equipPrice + e.row.oprPrice, 1);
          that.sheet.setValue(e.row, 'amt', e.row.qty * e.row.price, 1);
        },
      },
      {
        Header: ['경비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'oprAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: ['합계', {Value: '단가', Color: '#dff9fb'}],
        Name: 'price',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEdit: 0,
      },
      {
        Header: ['합계', {Value: '금액', Color: '#dff9fb'}],
        Name: 'amt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {
        Header: ['지급자재', '지급자재'],
        Name: 'matGiveYn',
        Type: 'Bool',
        Width: 80,
        CanEdit: 1,
        Visible: 1,
        Format: {'000001': 1},
        CanEditFormula: e =>
          e.Row['formula'] ? 0 : that.confirm && e.Row['costType'] == $getConstants('COST_TYPE_M').code,
        OnChange: e => {
          if (e.row[e.col]) {
            e.row['matPriceOrigin'] = e.row.matPrice;
            e.row['labPriceOrigin'] = e.row.labPrice;
            e.row['equipPriceOrigin'] = e.row.equipPrice;
            e.row['oprPriceOrigin'] = e.row.oprPrice;
            that.sheet.setValue(e.row, 'matPrice', 0, 1);
            that.sheet.setValue(e.row, 'labPrice', 0, 1);
            that.sheet.setValue(e.row, 'equipPrice', 0, 1);
            that.sheet.setValue(e.row, 'oprPrice', 0, 1);
          } else {
            that.sheet.setValue(e.row, 'matPrice', e.row['matPriceOrigin'] || 0, 1);
            that.sheet.setValue(e.row, 'labPrice', e.row['labPriceOrigin'] || 0, 1);
            that.sheet.setValue(e.row, 'equipPrice', e.row['equipPriceOrigin'] || 0, 1);
            that.sheet.setValue(e.row, 'oprPrice', e.row['oprPriceOrigin'] || 0, 1);
          }
        },
      },
      {
        Header: ['변경내역', '변경내역'],
        Name: 'chgGb',
        Type: 'Bool',
        Width: 80,
        Align: 'Center',
        CanEdit: 0,
      },
      {
        Name: 'wcode',
        Visible: 0,
      },
      {
        Name: 'headCode',
        Visible: 0,
      },
      {
        Name: 'midCode',
        Visible: 0,
      },
      {
        Name: 'formula',
        Visible: 0,
      },
      {
        Name: 'chgDegree',
        Visible: 0,
      },
      {
        Name: 'originItemSeq',
        Visible: 0,
      },
      {
        Name: 'workType',
        Visible: 0,
      },
    ],
  };
};
