<template>
  <pmis-page>
    <iui-tab v-if="comps.length" :comps="comps" @click="e => (currentTabIndex = e)" />
  </pmis-page>
</template>

<script>
import {mapGetters} from 'vuex';
import {selectTskDsList} from './api/faq.js';
export default {
  data() {
    return {
      currentTabIndex: 0,
      comps: [],
    };
  },
  computed: {
    ...mapGetters('menu', {menu: 'menu'}),
  },
  async created() {
    let menuItems = [];
    if (this.userInfo.frmGb == $getConstants('FRM_GB_0').code) {
      let response = await selectTskDsList({frmGb: $getConstants('FRM_GB_0').code});
      response.data.forEach(el => {
        menuItems.push({
          mnuNm: el.mnuNm,
          mnuCd: el.mnuCd,
        });
      });
    } else {
      menuItems = this.menu;
    }
    menuItems.forEach(el => {
      this.comps.push({
        tabNm: el.mnuNm,
        compNm: `${el.mnuCd}_FaqList`,
        path: '@/view/board/faq/components/FaqBoardList.vue',
        paramObj: {tskDs: el.mnuCd},
        tskDs: el.mnuCd,
      });
    });
  },
  activated() {
    this.canNew = false;
    this.canSave = false;
    if (this.comps.length) this.onSearch();
  },
  methods: {
    onSearch() {
      this.callEvent({name: `FAQ_BoardList_${this.comps[this.currentTabIndex].tskDs}_onSearch`});
    },
    onAdd() {
      this.callEvent({name: 'FAQ_BoardList_onAdd'});
    },
  },
};
</script>

<style></style>
