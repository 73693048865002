<template>
  <pmis-content-box>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onSearchFinish, onClick, onBeforeChange, onAfterChange}"
    />
    <iui-modal name="simpleExec_unitModal" title="단위" width="1000px" height="500px" :btns="modalBtns">
      <UnitPopup />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import UnitPopup from '@/view/sysManage/stdCodeManage/components/UnitPopup.vue';
import options from './sheetOption/SimpleExecList.js';
import {getScopeInRows, getDuplicateRow, updatePriceAsSameItem, nextItemSeq, getNextRow} from '@/util/dtlsUtil.js';
import {
  selectContContexecPlanOfDegreeList,
  selectContExecPlanListAndInfo,
  saveContContPlan,
  deleteContExecPlanValidList,
  deleteContExecPlanList,
  selectNextItemSeq,
} from '../api/executDtls.js';
import padStart from 'lodash/padStart';
export default {
  components: {UnitPopup},
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this), 2, 'chk', {
        CanEditFormula: () => this.confirm,
      }),
      loadSearchData: [],
      code0265: '',
      focusKey: undefined,
      modalBtns: [{name: '확인', callback: this.onPopConfirm}],
    };
  },

  computed: {
    confirm: function() {
      return this.masterInfo.prjFlag != this.code0265 && this.currentDegree == this.maxDgree;
    },
  },

  beforeCreate() {
    $mapGetters(this, ['detailInfo', 'sheetData', 'currentDegree', 'maxDgree', 'masterInfo']);
    $mapMutations(this, ['setDetailInfo', 'setMasterInfo', 'setDegreeList', 'setCurrentDegree']);
    $getCode($getConstants('CONFIRM_FLAG').code).then(response => (this.code0265 = response[0].code));
  },
  created() {
    let event = [
      {name: 'onInitSearch', func: this.onInitSearch},
      {name: 'onSearch', func: this.onSearch},
    ];
    this.addEvent(event);
    this.addFuncPrj(this.onInitSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onSearchFinish() {
      let row;
      if (this.focusKey) {
        row = this.sheet.getDataRows().find(row => String(row.midCode) + String(row.originItemSeq) === this.focusKey);
        this.sheet.focus(row);
        this.setData(row);
        if (String(row.itemCode).startsWith('S')) {
          this.callEvent({name: 'ArithmeticPop_setData', param: {itemCode: row.itemCode}});
        }
        this.focusKey = undefined;
      } else {
        row = this.sheet.getFirstRow();
        this.setDetailInfo(row);
      }
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.setData(e.row);
      }
    },
    setData(row) {
      this.setDetailInfo(row);
    },
    onBeforeChange(e) {
      if (e.col === 'itemSeq') {
        const val = padStart(e.val, 5, '0');
        if (e.oldval === val) {
          return val;
        }

        let isDup = getDuplicateRow(e.sheet, 'itemSeq', val);
        if (isDup) {
          this.$alert({title: '내역번호', message: '내역번호는 중복을 허용할 수 없습니다.'});
          return e.oldval;
        } else {
          return val;
        }
      }
      if (e.col.toLowerCase().includes('price')) {
        updatePriceAsSameItem(e);
      }
    },
    onAfterChange(e) {
      if (e.col === 'chk' && e.row.workType) {
        if (String(e.row.itemCode) === this.prjCd) {
          e.sheet.getDataRows().forEach(row => this.sheet.setCheck(row, 'chk', e.val, 0));
          return;
        }
        let clCode;
        if (e.row.itemCode.length === 1) {
          clCode = 'wcode';
        }
        if (e.row.itemCode.length === 2) {
          clCode = 'headCode';
        }
        if (e.row.itemCode.length === 3) {
          clCode = 'midCode';
        }

        getScopeInRows(this.sheet, clCode, e.row).forEach(row => this.sheet.setCheck(row, 'chk', e.val, 0));
      }
    },

    async onInitSearch() {
      const responseDegree = await selectContContexecPlanOfDegreeList({});
      this.setDegreeList(responseDegree.data);
      this.setCurrentDegree(this.maxDgree);
      this.onSearch();
    },
    async onSearch() {
      const param = {
        chgDegree: this.currentDegree,
      };
      const response = await selectContExecPlanListAndInfo(param);
      this.loadSearchData = response.data.dtlsList ?? [];
      this.setMasterInfo(response.data.contexecPlanMResDto);
    },
    async onAdd() {
      let row = this.sheet.getFocusedRow();
      if (!this.maxDgree) {
        this.$alert({title: '신규', message: '실행내역차수를 생성하십시오.'});
        return;
      }
      if (!row.midCode) {
        this.$alert({title: '신규', message: '최하위 공종을 선택하십시오'});
        return;
      }
      const itemSeq = await this.nextItemSeq();
      const itemCode = row.midCode + padStart(itemSeq, 13, '0');
      let nextRow = getNextRow(this.sheet);
      this.sheet.addRow({
        next: nextRow,
        init: {
          itemSeq: itemSeq,
          originItemSeq: itemSeq,
          itemCode: itemCode,
          wcode: row.wcode,
          headCode: row.headCode,
          midCode: row.midCode,
          chgGb: this.maxDgree > 1 ? 1 : 0,
        },
      });
    },
    async onSave() {
      const rows = this.sheet.getSaveJson().data;
      let valid = true;
      let message = '';
      const contexecPlanDtlsList = $_statusToCud(rows);
      contexecPlanDtlsList.some((item, index) => {
        if (!item.itemSeq) {
          valid = false;
          message = `내역코드를 입력해주세요.`;
          this.sheet.focus(this.sheet.getRowById(item.id), 'itemSeq');
          return true;
        }
        if (!/^[0-9]+$/.test(item.itemSeq)) {
          valid = false;
          message = `내역코드를 숫자로 입력해주세요.`;
          this.sheet.focus(this.sheet.getRowById(item.id), 'itemSeq');
          return true;
        }
        if (!item.itemCode || item.itemCode.length != 16) {
          valid = false;
          message = `실행코드를 16자리로 입력해주세요.`;
          this.sheet.focus(this.sheet.getRowById(item.id), 'itemCode');
          return true;
        }
        if (!item.itemName) {
          valid = false;
          message = `품명을 입력해주세요.`;
          this.sheet.focus(this.sheet.getRowById(item.id), 'itemName');
          return true;
        }
        item.chgDegree = this.maxDgree;
        item.matGiveYn = item.matGiveYn == 1 ? $getConstants('MAT_GIVE_Y').code : '';
        item.chgGb = item.chgGb == 1 ? $getConstants('CHG_GB_CHANGE').code : '';
      });
      if (!valid) {
        this.$alert({title: '저장', message: message});
        return;
      }

      this.focusKey = String(this.sheet.getFocusedRow().midCode) + String(this.sheet.getFocusedRow().itemSeq);
      const response = await saveContContPlan({contexecPlanDtlsList});
      if (response.data) {
        this.onSearch();
      }
    },
    async onDelete() {
      const checkedRows = this.sheet.getRowsByChecked('chk').filter(row => !row.workType);
      if (!checkedRows.length) {
        this.$alert({title: '삭제', message: '삭제할 내역을 선택해 주세요'});
        return;
      }

      const param = {
        chgDegree: this.maxDgree,
        contexecPlanDtlsList: checkedRows.map(row => ({
          wcode: row.wcode,
          headCode: row.headCode,
          midCode: row.midCode,
          itemSeq: row.originItemSeq,
        })),
      };

      const validResponse = await deleteContExecPlanValidList(param);
      if (validResponse.data.length) {
        const validKeyList = validResponse.data.map(item => String(item.midCode).concat(String(item.itemSeq)));
        const validList = this.sheet
          .getDataRows()
          .filter(row => validKeyList.indexOf(String(row.midCode).concat(String(row.itemSeq))) !== -1);

        param.contexecPlanDtlsList = param.contexecPlanDtlsList.filter(
          row => validKeyList.indexOf(String(row.midCode).concat(String(row.itemSeq))) === -1
        );

        if (param.contexecPlanDtlsList.length) {
          const message = `${validList
            .map(row => row.itemName)
            .join(
              ','
            )} 내역의 이미 투입된 수량이 존재하여 삭제할 수 없습니다.<br> 해당 항목을 제외하고 삭제하시겠습니까?`;
          if (!(await this.$confirm({title: '삭제', message: message}))) {
            return;
          }
        } else {
          const message = `${validList
            .map(row => row.itemName)
            .join(',')} 내역의 이미 투입된 수량이 존재하여 삭제할 수 없습니다.`;
          this.$alert({title: '삭제', message: message});
          return;
        }
        validList.forEach(row => this.sheet.setCheck(row, 'chk', 0, 0));
      } else {
        if (!(await this.$confirm({title: '삭제', message: '삭제하시겠습니까?'}))) {
          return;
        }
      }

      const response = await deleteContExecPlanList(param);
      if (response.data) {
        this.onSearch();
      }
    },
    onPopConfirm() {
      this.callEvent({
        name: 'UnitPopupp_popConfirm',
        param: data => {
          const row = this.sheet.getFocusedRow();
          this.sheet.setValue(row, 'unit', data.unitCode, 1);
          this.$modal.hide('simpleExec_unitModal');
        },
      });
    },
    async nextItemSeq() {
      let itemSeq;
      if (getScopeInRows(this.sheet).filter(row => row.Added).length) {
        itemSeq = padStart(nextItemSeq(this.sheet), 5, '0');
      } else {
        const response = await selectNextItemSeq({
          chgDegree: this.maxDgree,
          midCode: this.sheet.getFocusedRow().midCode,
        });
        itemSeq = response.data;
      }

      return itemSeq;
    },
    validation() {
      if (this.sheet.hasChangedData()) {
        this.$alert({title: '내역확정', message: '변경된 내역이 존재합니다. <br> 저장을 먼저 해 주세요.'});
        return false;
      }
      const noAmtRow = this.sheet.getDataRows().filter(row => row.itemSeq && !row.amt && !row.matGiveYn);
      if (noAmtRow.length) {
        this.$alert({title: '내역확정', message: '금액이 없는 내역이 존재합니다.'});
        this.sheet.focus(noAmtRow[0]);
        return false;
      }
      return true;
    },
  },
};
</script>
