<template>
  <div class="work_schedule">
    <div class="calendar_header">
      <div class="date left">
        <span class="date_text">
          {{ `${currentYear}년 ${currentMonth}월 ${currentDay}일 ${currentWeek}요일` }}
        </span>
      </div>
      <div class="date center">
        <a href="#" class="last" @click="onClickPrevMonth()">
          <span class="blind">지난달</span>
        </a>
        <span class="date_text">
          {{ `${currentYear}년 ${currentMonth}월` }}
        </span>
        <a href="#" class="next" @click="onClickNextMonth()">
          <span class="blind">다음달</span>
        </a>
      </div>
      <div class="date right"></div>
    </div>

    <div class="calendar_main">
      <div class="date">
        <a href="#" class="last" @click="onClickPrevDay()">
          <span class="blind">전일</span>
        </a>
      </div>
      <table class="work_list">
        <tbody>
          <tr>
            <td v-for="(date, index) in currentCalendarMatrix" :key="index" @click="onSelectDay(date)">
              <a href="#" :class="getDayClass(date)">{{ date }}</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="date">
        <a href="#" class="next" @click="onClickNextDay()">
          <span class="blind">익일</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Calendar',
  data() {
    return {
      weekNames: ['일', '월', '화', '수', '목', '금', '토'],
      rootYear: 1904,
      rootDayOfWeekIndex: 4, // 2000년 1월 1일은 토요일
      currentYear: '',
      currentMonth: '',
      currentDay: '',
      currentWeek: '',
      currentCalendarMatrix: [],
      endOfDay: null,
      memoDatas: [],
      holidays: [],
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo']);
    $mapActions(this, ['setSearchInfo']);
  },
  created() {},
  mounted() {
    let date = new Date();
    this.currentYear = date.getFullYear();
    this.currentMonth = date.getMonth() + 1;
    this.currentDay = date.getDate();

    this.setToday();
  },
  methods: {
    setToday() {
      let date = new Date();
      this.currentYear = date.getFullYear();
      this.currentMonth = date.getMonth() + 1;
      this.currentDay = date.getDate();
      this.init();
    },
    init() {
      this.endOfDay = this.getEndOfDay(this.currentYear, this.currentMonth);
      this.initCalendar();
      this.getHolidays();
      this.setSearchDate();
    },
    getEndOfDay(year, month) {
      if (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12) {
        return 31;
      } else if (month == 4 || month == 6 || month == 9 || month == 11) {
        return 30;
      } else {
        if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
          return 29;
        } else {
          return 28;
        }
      }
    },
    initCalendar() {
      this.currentCalendarMatrix = [];
      let day = 1;
      let days = [];
      for (let i = 0; i < this.endOfDay; i++) {
        days.push(day++);
      }

      this.currentCalendarMatrix = JSON.parse(JSON.stringify(days));
      this.getCurrentWeek();
    },
    getHolidays() {
      $_getPublicHolidays(this.currentYear, this.currentMonth).then(response => {
        this.holidays = [];

        response.forEach(holiday => {
          let date = String(holiday.locdate).substring(6);
          date = date.startsWith('0') ? date.substring(1) : date;
          this.holidays.push(date);
        });
      });
    },
    getCurrentWeek() {
      let year = this.currentYear;
      let month = this.currentMonth;
      let day = this.currentDay;

      let date = new Date(year, month - 1, day);
      this.currentWeek = this.weekNames[date.getDay()];
    },
    // 이전 월
    onClickPrevMonth() {
      if (--this.currentMonth <= 0) {
        this.currentMonth = 12;
        this.currentYear -= 1;
      }
      this.init();
    },
    // 다음 월
    onClickNextMonth() {
      if (++this.currentMonth > 12) {
        this.currentMonth = 1;
        this.currentYear += 1;
      }
      this.init();
    },
    // 이전 일
    onClickPrevDay() {
      if (--this.currentDay < 1) {
        this.onClickPrevMonth();
        this.currentDay = this.endOfDay;
      }
      this.init();
    },
    // 다음 일
    onClickNextDay() {
      if (++this.currentDay > this.endOfDay) {
        this.onClickNextMonth();
        this.currentDay = 1;
      }
      this.init();
    },
    getDayClass(date) {
      if (date == this.currentDay) {
        return 'today';
      } else if (this.holidays.includes(String(date))) {
        return 'sun';
      } else {
        let day = new Date(this.currentYear, this.currentMonth - 1, date).getDay();
        return day == 0 ? 'sun' : day == 6 ? 'sat' : '';
      }
    },
    isToday(year, month, day) {
      let date = new Date();

      if (year == date.getFullYear() && month == date.getMonth() + 1 && day == date.getDate()) {
        return true;
      } else {
        return false;
      }
    },
    onSelectDay(day) {
      this.currentDay = day;
      this.getCurrentWeek();
      this.setSearchDate();
      this.callEvent({name: 'onSearch'});
    },
    setSearchDate() {
      let yyyy = this.currentYear.toString();
      let mm = this.currentMonth.toString();
      let dd = this.currentDay.toString();
      let searchDate = yyyy.concat(mm[1] ? mm : '0' + mm[0], dd[1] ? dd : '0' + dd[0]);

      this.setSearchInfo({
        ymd: searchDate,
      });
    },
  },
};
</script>

<style scoped>
.date_text {
  text-align: center;
  width: 190px;
}
.calendar_header {
  display: flex;
}
.calendar_header > div {
  flex: 1;
}
.calendar_header > div.left {
  justify-content: flex-start;
}
.calendar_header > div.center {
  justify-content: center;
}
.calendar_main {
  display: flex;
}
</style>
