<template>
  <iui-portlet-container type="multi" :comps="comps" :activeTab.sync="tabIndex">
    <template #title>{{ titleNm }}</template>
    <template #tab>
      <span class="pr10">기준일자</span>
      <iui-datepicker :value.sync="date" :isIconClear="false" :disabledDate="disabledDate" />
      <iui-button btn-class="btn_p_pre ml5" @click="changeDate('prev')"><span class="blind">이전</span></iui-button>
      <iui-button btn-class="btn_p_next ml5" @click="changeDate('next')"><span class="blind">다음</span> </iui-button>
    </template>
    <template #content>
      <pmis-content-box>
        <labor-input-info v-show="tabIndex == 0" :portletGroup="portletGroup" :date="date" :search.sync="search1" />
        <equip-input-info v-show="tabIndex == 1" :portletGroup="portletGroup" :date="date" :search.sync="search2" />
      </pmis-content-box>
    </template>
  </iui-portlet-container>
</template>

<script>
import PortletMainMixin from '@/components/portlet/mixin/portletMainMixin.js';

export default {
  mixins: [PortletMainMixin],
  props: {
    portletGroup: {
      type: String,
    },
  },
  data() {
    return {
      date: $_getCurrentDate(),
      comps: [
        {tabNm: '인력투입정보', compNm: 'labor-input-info'},
        {tabNm: '장비투입정보', compNm: 'equip-input-info'},
      ],
      search1: false,
      search2: false,
    };
  },
  methods: {
    disabledDate(date) {
      return $_getCurrentDate() < $_dateToStringYYMMDD(date);
    },
    init() {
      this.tabIndex = 0;

      this.search1 = true;
      this.search2 = true;
    },
    changeDate(direction) {
      this.date = $_nowdateOverCheck($_getCalDate(this.date, {prev: -1, next: 1}[direction]));
    },
  },
};
</script>

<style></style>
