<template>
  <pmis-tab-box>
    <template #title>사원목록</template>
    <template #title-right>
      <iui-button
        value="근무기준등록"
        v-if="isDaily || searchInfo.searchGubun2 === $getConstants('LABOR_SEARCH_GB3').code"
        @click="onOpenStandard"
      />
      <template v-if="isProcessWritten">
        <iui-button value="계약서송부" @click="onSend" />
      </template>
      <template v-if="isProcessSended">
        <iui-button value="계약서송부취소" @click="onCancel" />
      </template>
      <template v-if="isProcessWorkerSigned">
        <iui-button value="계약서서명" @click="onPreSign" />
      </template>
    </template>
    <pmis-content-box>
      <template #header-left>
        <iui-text
          label="성명"
          type="search"
          :value="searchInfo.searchText"
          @change="setSearchInfo({searchText: $event.target.value})"
          @enter="onSearch"
        />
        <iui-button value="검색" @click="onSearch" />
        <iui-checkbox-group
          :items="[{value: 'Y', label: '근무종료자포함'}]"
          :value.sync="searchInfo.labtClose"
          @change="onChangeSearchInfo('labtClose', $event.target.checked ? $event.target.value : '')"
        />
        <iui-select
          class="ml20"
          label="근로자구분"
          defaultCd="S"
          :p-code="$getConstants('LABOR_DS_CD').code"
          :excludeItems="$getConstants('LABOR_DS1').code"
          :value="searchInfo.searchGubun2"
          @change="value => onChangeSearchInfo('searchGubun2', value)"
        />
        <label class="ml20 mr10">진행구분</label>
        <iui-radio-group
          defaultCd="A"
          :items="processStatItems"
          :value="searchInfo.searchGubun3"
          @change="onChangeSearchInfo('searchGubun3', $event.target.value)"
        />
      </template>
      <ib-sheet
        :uid="_uid"
        :options="sheetOpt"
        :loadSearchData="loadSearchData"
        @loadSheet="sheet_loadSheet"
        :events="{onSearchFinish: sheet_onSearchFinish, onClick: sheet_onClick}"
      />
    </pmis-content-box>

    <iui-modal name="LaborContractSignViewerModal" title="근로계약서" size-type="size3" :btns="modalSignViewer">
      <labor-contract-viewer :fomNo="fomNo" :ozContent="ozContent" :ozInputJsonStr="ozInputJsonStr" />
    </iui-modal>

    <iui-modal name="DailyStandardRegistModal" title="근무기준등록" size-type="size4" :btns="modalStandard">
      <daily-standard-regist />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import LaborContractViewer from './popup/LaborContractViewer.vue';
import DailyStandardRegist from './popup/DailyStandardRegist.vue';
import sheetOpt from './sheetOption/laborContract.js';
import {updateCompSign, selectLaborList, send, cancel, selectLaborInfoForViewer} from '../api/laborContract.js';

export default {
  components: {LaborContractViewer, DailyStandardRegist},
  data() {
    return {
      loadSearchData: [],
      sheetOpt: sheetOpt(this),

      processStatItems: [],
      ozContent: {},
      ozInputJsonStr: {},
      modalSignViewer: [{name: '계약서 서명', callback: this.onSignModal}],
      modalStandard: [{name: '저장', callback: this.onSaveStandard}],

      focusKey: null,
      signList: [],
    };
  },
  computed: {
    isAllSearch() {
      //진행구분 - 전체
      return !this.searchInfo.searchGubun3;
    },
    fomNo() {
      return this.isDaily ? 31 : 32; //근로계약서 (일용직) : 근로계약서 (상용직)
    },
  },
  watch: {
    isAllSearch(v) {
      this.sheet.setAttribute(null, 'cbx', 'CanEdit', !v);
    },
  },
  beforeCreate() {
    $mapGetters(this, [
      'searchInfo',
      'rowInfo',
      'laborInfo',
      'isDaily',
      'isNew',
      'isProcessWritten',
      'isProcessSended',
      'isProcessWorkerSigned',
    ]);
    $mapMutations(this, ['setSearchInfo', 'setRowInfo', 'initRowInfo', 'initLaborInfo', 'initTimeInfo']);
  },
  created() {
    this.addEvent([
      {name: 'LaborList_Search', func: this.onSearch},
      {name: 'LaborList_GetDeleteRows', func: this.getDeleteRows},
    ]);

    $getCode($getConstants('PROCESS_GB').code).then(codes => {
      this.processStatItems.push({
        label: $getConstants('PROCESS_GB_NONE').name,
        value: $getConstants('PROCESS_GB_NONE').code,
      });
      codes.forEach(code => {
        this.processStatItems.push({label: code.codeNm, value: code.code});
      });
    });
  },
  methods: {
    sheet_onClick(e) {
      if (e.row.Kind == 'Data' && e.col != 'cbx') {
        this.setRowData(e.row);
      }
    },
    sheet_onSearchFinish(e) {
      if (0 < e.sheet.getTotalRowCount()) {
        let focusRow = e.sheet
          .getDataRows()
          .find(row => row.empNo == this.focusKey?.empNo && row.laborDs == this.focusKey?.laborDs);
        if (focusRow === undefined) {
          this.setRowData(e.sheet.getFirstRow());
        } else {
          this.sheet.focus(focusRow);
          this.setRowData(focusRow);
        }
      } else {
        this.initRowInfo();
        this.initLaborInfo();
        this.initTimeInfo();

        this.callEvent({name: 'ContractHistory_Search'});
      }
    },
    sheet_loadSheet(sheet) {
      this.sheet = sheet;
    },
    onChangeSearchInfo(key, value) {
      this.setSearchInfo({[key]: value});
      this.onSearch();
    },
    setRowData(row) {
      this.setRowInfo(row);
      this.$emit('onClickRow');
    },
    onSearch(param) {
      if (param) {
        this.focusKey = {empNo: param.empNo, laborDs: param.laborDs};
      }
      selectLaborList(this.searchInfo).then(response => {
        if (response.status === 200 && response.data.length > 0) {
          response.data.forEach(
            row => (row.processStat = row.processStat ? row.processStat : $getConstants('PROCESS_GB_NONE').code)
          );
        }
        this.loadSearchData = response.data;
      });
    },
    onOpenStandard() {
      this.$modal.show('DailyStandardRegistModal');
    },
    onSaveStandard() {
      this.callEvent({
        name: 'DailyStandardRegist_Save',
        param: () => {
          this.$modal.hide('DailyStandardRegistModal');
          this.callEvent({name: 'ContractInfo_Search'});
        },
      });
    },
    async onSend() {
      let sendList = [];
      if (this.isAllSearch) {
        sendList.push({
          empNo: this.rowInfo.empNo,
          laborDs: this.rowInfo.laborDs,
        });
      } else {
        let checkedRows = this.sheet.getRowsByChecked('cbx');
        if (checkedRows.length == 0) {
          this.$alert({title: '계약서 송부', message: '선택된 항목이 없습니다.'});
          return;
        }

        sendList = checkedRows.map(item => {
          return {empNo: item.empNo, laborDs: item.laborDs};
        });
      }
      if (!(await this.$confirm({title: '계약서 송부', message: '계약서를 송부하시겠습니까?'}))) return;

      this.focusKey = sendList[0];

      send(sendList)
        .then(response => {
          if (response.status == 200) {
            this.setSearchInfo({searchGubun3: $getConstants('LABOR_SEARCH_GB2').code});
            this.onSearch();
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            let msg = ex.message;
            if (ex.description) {
              msg += `<br/>- ${ex.description}`;
            }
            this.$alert({title: ex.title, message: msg});
          }
        });
    },
    async onCancel() {
      let cancelList = [];
      if (this.isAllSearch) {
        cancelList.push({
          empNo: this.rowInfo.empNo,
          laborDs: this.rowInfo.laborDs,
        });
      } else {
        let checkedRows = this.sheet.getRowsByChecked('cbx');
        if (checkedRows.length == 0) {
          this.$alert({title: '계약서 송부취소', message: '선택된 항목이 없습니다.'});
          return;
        }

        cancelList = checkedRows.map(item => {
          return {empNo: item.empNo, laborDs: item.laborDs};
        });
      }

      if (!(await this.$confirm({title: '계약서 송부취소', message: '계약서 송부취소를 하시겠습니까?'}))) return;

      this.focusKey = cancelList[0];

      cancel(cancelList)
        .then(response => {
          if (response.status == 200) {
            this.setSearchInfo({searchGubun3: $getConstants('LABOR_SEARCH_GB1').code});
            this.onSearch();
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            let msg = ex.message;
            if (ex.description) {
              msg += `<br/>- ${ex.description}`;
            }
            this.$alert({title: ex.title, message: msg});
          }
        });
    },
    onPreSign() {
      this.signList.length = 0;

      if (this.isAllSearch) {
        this.signList.push({
          empNo: this.rowInfo.empNo,
          laborDs: this.rowInfo.laborDs,
        });
      } else {
        let checkedRows = this.sheet.getRowsByChecked('cbx');
        if (checkedRows.length == 0) {
          this.$alert({title: '계약서 서명', message: '선택된 항목이 없습니다.'});
          return;
        }

        this.signList = checkedRows.map(item => {
          return {empNo: item.empNo, laborDs: item.laborDs};
        });
      }

      if (this.signList.length == 1) {
        selectLaborInfoForViewer({...this.signList[0]}).then(response => {
          this.ozContent = JSON.parse(response.data.contents);
          this.ozInputJsonStr = JSON.parse(response.data.inputContents);
          this.$modal.show('LaborContractSignViewerModal');
        });
      } else {
        this.onSign();
      }
    },
    onSignModal() {
      this.callEvent({
        name: 'LaborContractViewer_GetSignData',
        param: () => {
          this.signList = [
            {
              empNo: this.rowInfo.empNo,
              laborDs: this.rowInfo.laborDs,
            },
          ];
          this.onSign();
        },
      });
    },
    async onSign() {
      let message = '계약서 서명을 하시겠습니까?';
      if (!(await this.$confirm({title: '계약서 서명', message: message}))) return;

      this.focusKey = this.signList[0];

      updateCompSign(this.signList)
        .then(response => {
          if (response.status == 200) {
            this.$modal.hide('LaborContractSignViewerModal');
            this.setSearchInfo({searchGubun3: $getConstants('LABOR_SEARCH_GB4').code});
            this.onSearch();
          }
        })
        .catch(ex => {
          if (ex.pmisError) {
            let msg = ex.message;
            if (ex.description) {
              msg += `<br/>- ${ex.escription}`;
            }
            this.$alert({title: ex.title, message: msg});
          }
        });
    },

    getDeleteRows(callback) {
      if (typeof callback === 'function') {
        callback(this.sheet.getRowsByChecked('cbx').map(item => ({empNo: item.empNo, laborDs: item.laborDs})));
      }
    },
  },
};
</script>

<style></style>
