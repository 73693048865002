<template>
  <div class="section_con">
    <div class="tab_box">
      <ul class="tab_con">
        <li
          v-for="(c, i) in internalComps"
          :key="i"
          @click="tabClick(i)"
          :class="[activetab === i ? 'selected_con df' : '']"
          :ref="c.compNm"
          v-show="isVislble(c.compNm)"
        >
          <a href="#" style="all: unset; cursor: pointer;">{{ c.tabNm }}</a>
          <!-- 탭 내부버튼 - 컴포넌트 obj 내부에 tabBtn: true 설정하여 사용 -->
          <a href="#" style="margin-left: 10px; cursor: pointer;" v-if="c.tabBtn && activetab === i" class="df">
            <!-- 버튼value - 컴포넌트 obj 내부에 btnValue: 'Text' 설정하여 사용 -->
            <iui-button @click="tabBtn_click(c)">{{ c.btnValue }}</iui-button>
          </a>
        </li>
      </ul>
      <div class="add_work_end">
        <slot name="tab-right" />
      </div>
    </div>

    <div v-if="lazeLoad && tabItemCount > 0" class="grid_box grid_inner_scroll" style="height: calc(100% - 30px)">
      <keep-alive v-if="keepAlive">
        <component :is="comp" :paramObj="paramObj" ref="refComponent"></component>
      </keep-alive>
      <component :is="comp" :paramObj="paramObj" ref="refComponent" v-else></component>
    </div>
    <div v-if="!lazeLoad && tabItemCount > 0" class="grid_box">
      <div v-for="(c, i) in internalComps" :key="i" :class="{show: activetab === i}">
        <component :is="c.name"></component>
      </div>
    </div>
    <div v-if="!lazeLoad && tabItemCount === 0" class="grid_box grid_inner_scroll" style="height: calc(100% - 30px)">
      <slot name="tab-content" />
    </div>
  </div>
</template>

<script>
import {childViewAuthSetup, childViewAuthDestroy} from '@/menuAuth';

export default {
  props: {
    comps: {
      type: Array,
    },
    visibles: {
      type: Object,
    },
    lazeLoad: {
      type: Boolean,
      default: true,
    },
    keepAlive: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: '1',
    },
    isAuto: {
      type: Boolean,
      default: true,
    },
    defaultIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activetab: 0,
      comp: '',
      activeCls: this.mode == '1' ? 'on ' : 'open',
      paramObj: {},
      tabItemCount: 0,
      internalComps: [],
    };
  },
  watch: {
    activetab(active) {
      this.comp = this.internalComps[active].compNm;
      this.paramObj = this.internalComps[active].paramObj;
    },
    visibles: {
      handler() {
        this.setVisibles();
      },
      deep: true,
    },
  },
  beforeCreate() {},
  created() {
    this.internalComps = this.comps.map(item => {
      if (item.compNm) {
        item.compNm = item.compNm.concat(this.$store.getters.currentUrl.replace('/', ''));
      }
      return item;
    });
    this.tabItemCount = this.internalComps.reduce((previousValue, currentValue) => {
      return previousValue + (currentValue.path === undefined || currentValue.path === '' ? 0 : 1);
    }, 0);
    this.comp = this.internalComps[0].compNm;
    this.paramObj = this.internalComps[0].paramObj;
    for (let c of this.internalComps) {
      if (c.path !== undefined) {
        this.addComponent(c.compNm, c.path);
      }
    }

    this.addEvent([
      {name: 'currentIndex', func: this.currentIndex},
      {name: 'setActiveTab', func: this.setActiveTab},
    ]);
  },
  activated() {
    childViewAuthSetup.call(this, this.internalComps[this.activetab].path);
  },
  mounted() {
    this.tabClick(this.defaultIndex);
  },
  methods: {
    tabBtn_click(comp) {
      // iui-tab 옵션 @tab-btn="함수명" 으로 이벤트 받아서 사용
      this.$emit('tab-btn', comp);
    },
    currentIndex(param) {
      let index = Array.from(this.$el.querySelectorAll('.tab > a')).findIndex(a => a.classList.contains('on'));

      if (typeof param == 'function') {
        param(index);
      }
    },
    async tabClick(index) {
      this.internalComps[index].compNm;
      if (this.isAuto && this.isVislble(this.internalComps[index].compNm)) {
        await this.setActiveTab(index);
      }
      this.$emit('click', index);
    },
    async setActiveTab(index) {
      this.$emit('before-active-tab', index, this.activetab);
      childViewAuthDestroy.call(this);
      this.activetab = index;
      await this.$nextTick();
      if (this.$refs.refComponent) {
        await this.$refs.refComponent.$nextTick();
        if (this.$refs.refComponent.$el.querySelector('.__sheet__')) {
          let load = setInterval(() => {
            if (this.$refs.refComponent.$el.querySelector('.__sheet__').children.length) {
              clearInterval(load);
              this.$emit('after-active-tab', index);
            }
          }, 10);
        } else {
          this.$emit('after-active-tab', index);
        }
      } else {
        this.$emit('after-active-tab', index);
      }

      childViewAuthSetup.call(this, this.internalComps[index].path);
    },
    setVisibles() {
      if (this.visibles) {
        for (let [key, value] of Object.entries(this.visibles)) {
          const el = this.$refs[key.concat(this.$store.getters.currentUrl.replace('/', ''))];
          if (el) {
            el[0].style.display = value ? '' : 'none';
          }
        }
      }
      this.visibles;
    },
    isVislble(compNm) {
      let result = true;
      compNm = compNm.replace(this.$store.getters.currentUrl.replace('/', ''), '');
      if (this.visibles) {
        result = this.visibles[compNm] === undefined ? true : this.visibles[compNm];
      }
      return result;
    },
  },
};
</script>

<style scoped>
.tab_container {
  width: 100%;
}

.cursor_pointer {
  cursor: pointer;
}
</style>
