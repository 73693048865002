<template>
  <iui-modal :name="`taxInvoiceViewerModal${_uid}`" title="세금계산서 조회" width="665px" height="700px">
    <tax-invoice-viewer :billNo="billNo" :pageGubun="pageGubun" />
  </iui-modal>
</template>

<script>
import TaxInvoiceViewer from './TaxInvoiceViewer.vue';
export default {
  props: {
    billNo: {
      type: String,
      default: undefined,
    },
    open: {
      type: Boolean,
      default: false,
    },
    pageGubun: {
      type: String,
      default: 'mat',
      // mat : 자재
      // sub : 외주
    },
  },
  components: {TaxInvoiceViewer},
  watch: {
    open() {
      if (this.billNo) {
        this.$modal.show(`taxInvoiceViewerModal${this._uid}`);
        this.$emit('update:open', false);
      } else {
        this.$alert({title: '세금계산서 조회', message: '세금계산서 번호가 없습니다.'});
      }
    },
  },
};
</script>

<style></style>
