<template>
  <pmis-content-box>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onSearchFinish, onBeforeChange, onAfterChange}"
    />
    <iui-modal name="outsrcDtls_unitModal" title="단위" width="1000px" height="500px" :btns="modalBtns">
      <UnitPopup />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import UnitPopup from '@/view/sysManage/stdCodeManage/components/UnitPopup.vue';
import options from './sheetOption/DetailListSheet.js';
import {getDuplicateRow, getNextRow, getScopeInRows, nextItemSeq, updatePriceAsSameItem} from '@/util/dtlsUtil.js';
import {
  deleteContContPlanList,
  saveContContPlan,
  selectContContexecPlanOfDegreeList,
  selectContContPlanListAndInfo,
  selectNextItemSeq,
} from '../api/outsrcDtls.js';
import padStart from 'lodash/padStart';

export default {
  components: {UnitPopup},
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this), 2, 'chk', {
        CanEditFormula: () => this.confirm,
      }),
      loadSearchData: [],
      code0265: '',
      focusKey: undefined,
      modalBtns: [{name: '확인', callback: this.onPopConfirm}],
    };
  },

  computed: {
    confirm: function() {
      return this.masterInfo.prjFlag != this.code0265 && this.currentDegree == this.maxDgree;
    },
  },

  beforeCreate() {
    $mapGetters(this, ['sheetData', 'currentDegree', 'maxDgree', 'masterInfo']);
    $mapMutations(this, ['setMasterInfo', 'setDegreeList', 'setCurrentDegree']);
    $getCode($getConstants('CONFIRM_FLAG').code).then(response => (this.code0265 = response[0].code));
  },
  created() {
    let event = [
      {name: 'onInitSearch', func: this.onInitSearch},
      {name: 'onSearch', func: this.onSearch},
    ];
    this.addEvent(event);
    this.addFuncPrj(this.onInitSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },

  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onSearchFinish() {
      let row;
      if (this.focusKey) {
        row = this.sheet.getDataRows().find(row => String(row.midCode) + String(row.itemSeq) === this.focusKey);
        this.sheet.focus(row);
        this.focusKey = undefined;
      }
    },
    onBeforeChange(e) {
      if (e.col === 'itemSeq') {
        const val = padStart(e.val, 5, '0');
        if (e.oldval === val) {
          return val;
        }

        let isDup = getDuplicateRow(e.sheet, 'itemSeq', val);
        if (isDup) {
          this.$alert({title: '내역번호', message: '내역번호는 중복을 허용할 수 없습니다.'});
          return e.oldval;
        } else {
          return val;
        }
      }
      if (e.col.toLowerCase().includes('price')) {
        updatePriceAsSameItem(e);
      }
    },
    onAfterChange(e) {
      if (e.col === 'chk' && e.row.workType) {
        if (String(e.row.itemCode) === this.prjCd) {
          e.sheet.getDataRows().forEach(row => this.sheet.setCheck(row, 'chk', e.val, 0));
          return;
        }
        let clCode;
        if (e.row.itemCode.length === 1) {
          clCode = 'wcode';
        }
        if (e.row.itemCode.length === 2) {
          clCode = 'headCode';
        }
        if (e.row.itemCode.length === 3) {
          clCode = 'midCode';
        }

        getScopeInRows(this.sheet, clCode, e.row).forEach(row => this.sheet.setCheck(row, 'chk', e.val, 0));
      }
    },

    async onInitSearch() {
      const responseDegree = await selectContContexecPlanOfDegreeList({});
      this.setDegreeList(responseDegree.data);
      this.setCurrentDegree(this.maxDgree);
      this.onSearch();
    },
    async onSearch() {
      const param = {
        chgDegree: this.currentDegree,
      };
      const response = await selectContContPlanListAndInfo(param);
      this.loadSearchData = response.data.dtlsList ?? [];
      this.setMasterInfo(response.data.contexecPlanMResDto);
    },
    async onAdd() {
      let row = this.sheet.getFocusedRow();
      if (!this.maxDgree) {
        this.$alert({title: '신규', message: '도급내역차수를 생성하십시오.'});
        return;
      }
      if (!row.midCode) {
        this.$alert({title: '신규', message: '최하위 공종을 선택하십시오'});
        return;
      }
      const itemSeq = await this.nextItemSeq();
      const itemCode = row.midCode + padStart(itemSeq, 13, '0');
      let nextRow = getNextRow(this.sheet);
      this.sheet.addRow({
        next: nextRow,
        init: {
          itemSeq: itemSeq,
          originItemSeq: itemSeq,
          itemCode: itemCode,
          wcode: row.wcode,
          headCode: row.headCode,
          midCode: row.midCode,
          chgGb: this.maxDgree > 1 ? 1 : 0,
        },
      });
    },
    async onSave() {
      const rows = this.sheet.getSaveJson().data;
      let valid = true;
      let message = '';
      const contcontPlanDtlsList = $_statusToCud(rows);
      contcontPlanDtlsList.some((item, index) => {
        if (!item.itemSeq) {
          valid = false;
          message = `내역코드를 입력해주세요.`;
          this.sheet.focus(this.sheet.getRowById(item.id), 'itemSeq');
          return true;
        }
        if (!/^[0-9]+$/.test(item.itemSeq)) {
          valid = false;
          message = `내역코드를 숫자로 입력해주세요.`;
          this.sheet.focus(this.sheet.getRowById(item.id), 'itemSeq');
          return true;
        }
        if (!item.itemCode || item.itemCode.length != 16) {
          valid = false;
          message = `도급코드를 16자리로 입력해주세요.`;
          this.sheet.focus(this.sheet.getRowById(item.id), 'itemCode');
          return true;
        }
        if (!item.itemName) {
          valid = false;
          message = `품명을 입력해주세요.`;
          this.sheet.focus(this.sheet.getRowById(item.id), 'itemName');
          return true;
        }
        item.chgDegree = this.maxDgree;
        item.matGiveYn = item.matGiveYn == 1 ? $getConstants('MAT_GIVE_Y').code : '';
        item.chgGb = item.chgGb == 1 ? $getConstants('CHG_GB_CHANGE').code : '';
      });
      if (!valid) {
        this.$alert({title: '저장', message: message});
        return;
      }

      this.focusKey = String(this.sheet.getFocusedRow().midCode) + String(this.sheet.getFocusedRow().itemSeq);
      const response = await saveContContPlan({contcontPlanDtlsList});
      if (response.data) {
        this.onSearch();
      }
    },
    async onDelete() {
      const rows = this.sheet.getRowsByChecked('chk').filter(row => !row.workType);
      if (!rows.length) {
        this.$alert({title: '삭제', message: '삭제할 내역을 선택해주세요'});
        return;
      }

      if (!(await this.$confirm({title: '삭제', message: '삭제하시겠습니까?'}))) {
        return;
      }

      if (rows.filter(x => !x.Added).length > 0 && this.sheet.getDataRows().filter(x => x.Added).length > 0) {
        this.$alert({title: '삭제', message: '저장하지 않은 데이터가 있어 삭제할 수 없습니다.'});
        return;
      }

      let contcontPlanDtlsList = [];
      rows.forEach(row => {
        if (row.Added) {
          this.sheet.removeRow(row);
        } else {
          contcontPlanDtlsList.push({
            itemName: row.itemName,
            wcode: row.wcode,
            headCode: row.headCode,
            midCode: row.midCode,
            itemSeq: row.originItemSeq,
          });
        }
      });

      if (contcontPlanDtlsList.length) {
        try {
          const response = await deleteContContPlanList({chgDegree: this.maxDgree, contcontPlanDtlsList});
          if (response.data) {
            this.onSearch();
          }
        } catch (error) {
          if (['CONT_INPUT_DELETE', 'CONT_EXEC_DELETE', 'CONT_WCONT_DELETE'].indexOf(error.code) !== -1) {
            this.$alert({
              title: '도급내역삭제',
              message: `${error.description}`,
            });
          } else {
            console.error(error);
          }
        }
      }
    },
    onPopConfirm() {
      this.callEvent({
        name: 'UnitPopupp_popConfirm',
        param: data => {
          const row = this.sheet.getFocusedRow();
          this.sheet.setValue(row, 'unit', data.unitCode, 1);
          this.$modal.hide('outsrcDtls_unitModal');
        },
      });
    },
    async nextItemSeq() {
      let itemSeq;
      if (getScopeInRows(this.sheet).filter(row => row.Added).length) {
        itemSeq = padStart(nextItemSeq(this.sheet), 5, '0');
      } else {
        const response = await selectNextItemSeq({
          chgDegree: this.maxDgree,
          midCode: this.sheet.getFocusedRow().midCode,
        });
        itemSeq = response.data;
      }

      return itemSeq;
    },
  },
};
</script>
