export const selectBaseDataClassificationList = () =>
  axios.post('/baseDataClassificationRegist/selectBaseDataClassificationList', {});

export const checkDuplicate = param => axios.post('/baseDataClassificationRegist/checkDuplicate', param);

export const saveBaseDataClassificationDetailInfo = param =>
  $save('/baseDataClassificationRegist/saveBaseDataClassificationDetailInfo', param);

export const deleteBaseDataClassificationDetailInfo = param =>
  axios.post('/baseDataClassificationRegist/deleteBaseDataClassificationDetailInfo', param);
