<template>
  <div class="header-left">
    <label v-if="!isPopup">내역차수</label>
    <iui-select
      v-if="!isPopup"
      :items="chgDegreeList"
      :defaultCd="!chgDegreeList.length ? 'S' : ''"
      :value="chgDegree"
      @change="value => onChangeChgDegree(value)"
    ></iui-select>
    <div class="ml5"></div>
    <label>공사구분</label>
    <iui-select defaultCd="A" :items="wcodeList" @change="onChangeWcode" :value.sync="wcode"></iui-select>
    <div class="ml5"></div>
    <label>대공종</label>
    <iui-select defaultCd="A" :items="lgCodeListFilter" @change="onChangeLgCode" :value.sync="lgCode"></iui-select>
    <div class="ml5"></div>
    <label>중공종</label>
    <iui-select defaultCd="A" :items="mgCodeListFilter" @change="onChangeMgCode" :value.sync="mgCode"></iui-select>
    <label v-if="isPopup">내역명</label>
    <iui-text type="search" :value.sync="itemName" @enter="onChangeItemName" v-if="isPopup" />
    <iui-button value="검색" @click="onChangeItemName" v-if="isPopup" />
    <div class="ml-auto" v-if="isPopup"></div>
    <label v-if="isPopup">내역차수</label>
    <iui-text :value="dpChgDegree" :enable="false" width="40px" v-if="isPopup" />
  </div>
</template>

<script>
import {selectContContexecPlanOfDegreeList as contDegreeList} from '@/view/outsrcDtls/api/outsrcDtls.js';
import {selectContContexecPlanOfDegreeList as execDegreeList} from '@/view/executDtls/api/executDtls.js';
export default {
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    codeGb: {
      type: String,
    },
    workTypeCl: {
      type: Object,
    },
    propsWcode: {
      type: String,
    },
    propsLgCode: {
      type: String,
    },
    propsMgCode: {
      type: String,
    },
    param: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chgDegreeList: [],
      chgDegree: '',
      wcode: '',
      lgCode: '',
      mgCode: '',
      itemName: '',
      wcodeList: [],
      lgCodeList: [],
      mgCodeList: [],
    };
  },
  computed: {
    lgCodeListFilter() {
      return this.wcode ? this.lgCodeList.filter(item => item.value.startsWith(this.wcode)) : [];
    },
    mgCodeListFilter() {
      return this.lgCode ? this.mgCodeList.filter(item => item.value.startsWith(this.lgCode)) : [];
    },
    isMaxDegree() {
      return this.chgDegree && this.chgDegreeList[this.chgDegreeList.length - 1]?.value === this.chgDegree;
    },
    dpChgDegree() {
      return this.chgDegree ? `${this.chgDegree}차` : ``;
    },
  },
  watch: {
    workTypeCl: {
      handler(obj) {
        this.wcodeList = obj.wcodeList;
        this.lgCodeList = obj.lgCodeList;
        this.mgCodeList = obj.mgCodeList;
      },
      deep: true,
    },
    propsWcode(value) {
      if (value) {
        if (this.wcodeList.filter(el => el.value == value).length) {
          this.wcode = value;
          this.onChangeWcode();
        } else {
          this.wcode = '';
          this.lgCode = '';
          this.$emit('update:propsLgCode', '');
          this.mgCode = '';
          this.$emit('update:propsMgCode', '');
        }
      }
    },
    propsLgCode(value) {
      if (value) {
        if (this.lgCodeList.filter(el => el.value == value).length) {
          this.lgCode = value;
          this.onChangeLgCode();
        } else {
          this.lgCode = '';
          this.mgCode = '';
          this.$emit('update:propsMgCode', '');
        }
      }
    },
    propsMgCode(value) {
      if (value) {
        if (this.mgCodeList.filter(el => el.value == value).length) {
          this.mgCode = value;
          this.onChangeMgCode();
        } else {
          this.mgCode = '';
        }
      }
    },
  },
  created() {
    if (this.isPopup) {
      this.codeGb === $getConstants('CODE_GB1').code ? this.onContSearch() : this.onExecSearch();
    } else {
      this.addFuncPrj(this.codeGb === $getConstants('CODE_GB1').code ? this.onContSearch : this.onExecSearch);
    }
  },
  methods: {
    init() {
      this.wcode = '';
      this.lgCode = '';
      this.mgCode = '';
      this.wcodeList = [];
      this.lgCodeList = [];
      this.mgCodeList = [];
    },
    async onContSearch() {
      this.init();
      this.chgDegreeList = [];
      const param = {
        ...this.param,
        prjFlag: $getConstants('PRJ_FLAG1').code,
      };
      const response = await contDegreeList(param);
      response.data.forEach(data => {
        this.chgDegreeList.push({text: `${data}차`, value: `${data}`});
      });
      this.chgDegree = this.chgDegreeList[this.chgDegreeList.length - 1]?.value ?? '';
      this.$emit('loadChgDegree', this.chgDegree);
      this.search();
      this.$emit('maxDegree', this.isMaxDegree);
      this.$emit('chgDegree', this.chgDegree);
    },
    async onExecSearch() {
      this.init();
      this.chgDegreeList = [];
      const param = {
        ...this.param,
        prjFlag: $getConstants('PRJ_FLAG1').code,
      };
      const response = await execDegreeList(param);
      response.data.forEach(data => {
        this.chgDegreeList.push({text: `${data}차`, value: `${data}`});
      });
      this.chgDegree = this.chgDegreeList[this.chgDegreeList.length - 1]?.value ?? '';
      this.$emit('loadChgDegree', this.chgDegree);
      this.search();
      this.$emit('maxDegree', this.isMaxDegree);
      this.$emit('chgDegree', this.chgDegree);
    },
    onChangeChgDegree(value) {
      this.chgDegree = value;
      this.$emit('maxDegree', this.isMaxDegree);
      this.$emit('chgDegree', this.chgDegree);
      this.$emit('changeChgDegree');
      this.search();
    },
    search() {
      this.$emit('search', this.chgDegree);
    },
    onChangeWcode(e) {
      this.lgCode = '';
      this.mgCode = '';
      this.setFilter();
    },
    onChangeLgCode() {
      this.mgCode = '';
      this.setFilter();
    },
    onChangeMgCode() {
      this.setFilter();
    },
    onChangeItemName() {
      this.setFilter();
    },
    setFilter() {
      this.$emit('filter', {wcode: this.wcode, lgCode: this.lgCode, mgCode: this.mgCode, itemName: this.itemName});
    },
  },
};
</script>

<style scoped>
.ml-auto {
  margin-left: auto;
}
</style>
