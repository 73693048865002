export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      CanEdit: 0, // 1: 전체 편집 가능 (default)
      IgnoreFocused: true,
    },
    Cols: [
      {
        Header: '현장코드',
        Name: 'prjCd',
        Visible: 0,
      },
      {
        Header: '현장명',
        Name: 'prjNm',
        Type: 'Text',
        RelWidth: 1.5,
        Align: 'left',
      },
      {
        Header: '투입일자',
        Name: 'rlseDate',
        Extend: preset.date,
        RelWidth: 1,
        Align: 'center',
      },
      {
        Header: '철수(예정)일자',
        Name: 'outDate',
        Extend: preset.date,
        RelWidth: 1,
        Align: 'center',
      },
    ],
  };
};
