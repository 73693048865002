export default async that => {
  return {
    Cfg: {
      SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
      CanEdit: 1, // 1: 전체 편집 가능 (default)
      ReqStatusName: 'status',
    },
    Cols: [
      {
        Header: '구분',
        Name: 'conSpDs',
        Type: 'Enum',
        Width: 60,
        Align: 'center',
        EnumKeys: $getConstants('CON_SP_DS').code,
        Enum: $getConstants('CON_SP_DS').code,
        CanEdit: that.enable,
      },
      {
        Header: '업종',
        Name: 'wcode',
        Type: 'Enum',
        MinWidth: 150,
        RelWidth: 1,
        EnumKeys: $getConstants('W_CODE').code,
        Enum: $getConstants('W_CODE').code,
        CanEdit: that.enable,
        // Required: 1,
        // EmptyValue: '필수 입력항목입니다.',
      },
      {
        Header: '등록번호',
        Name: 'licenseNo',
        Type: 'Text',
        Width: 130,
        CanEdit: that.enable,
        // Required: 1,
      },
      {
        Header: '발급기관',
        Name: 'licenseOrg',
        Type: 'Text',
        Width: 150,
        CanEdit: that.enable,
        // Required: 1,
      },
      {
        Header: '취득일자',
        Name: 'licenseDate',
        Extend: that.$ibsheetHelper.preset.date,
        CanEdit: that.enable,
      },
      {
        Header: '갱신일자',
        Name: 'licenseChgDate',
        Extend: that.$ibsheetHelper.preset.date,
        CanEdit: that.enable,
      },
      {Name: 'pgmCd', Visible: false},
      {Name: 'licenseSeq', Visible: false},
    ],
  };
};
