<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="450px">
          <pmis-tab-box no-border-box no-padding-box>
            <template v-slot:title>자재분류</template>
            <template v-slot:title-right>
              <iui-button value="자재분류삭제" @click="onDeleteMatType" />
            </template>
            <MatCl
              :callback="onCallbackMatCl"
              :matClInfo="matClInfo"
              @searchByItemNm="searchByItemNm"
              :base="isBase"
              :focusKey.sync="matClFocusKey"
              ref="matCl"
            />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <iui-container-new type="css-flex">
            <i-row height="350px">
              <i-col minWidth="780px">
                <pmis-tab-box>
                  <template v-slot:title>상세정보</template>
                  <template v-slot:title-right>
                    <iui-button @click="onPopAddExcel" value="엑셀등록" v-if="!isBase" />
                    <iui-button value="표준코드복제" v-if="!isBase" @click="onStandardCodePopup" />
                  </template>
                  <DetailInfo workSe="Untpc" ref="matCodeDetail" :base="isBase" :matClInfo.sync="matClInfo" />
                </pmis-tab-box>
              </i-col>
              <i-spacer v-if="!isBase" />
              <i-col v-if="!isBase" width="400px">
                <UntpcList :costType="costType" ref="untpcList" />
              </i-col>
            </i-row>
            <i-spacer />
            <i-row>
              <i-col>
                <DetailList
                  ref="matCodeList"
                  :base="isBase"
                  @matCodeInfo="setMatCodeInfo"
                  @onMatClSearch="onMatClSearch"
                  :paramObj="{isMultiSelect: true}"
                  :focusKey.sync="matCodeFocusKey"
                />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
    <iui-modal name="standardCodeModal" title="표준코드 복제" width="1200px" height="700px" :btns="modalBtns">
      <StandardCodePopup />
    </iui-modal>
    <iui-modal name="excelUploadPop" title="엑셀등록" :btns="excelBtns" sizeType="size1">
      <ExcelUpload
        uri="/matCodeRegist/excelUpload"
        title="자재코드등록"
        :rows="excelRows"
        @error="onExcelUploadError"
      />
    </iui-modal>
    <iui-modal name="ExcelUploadResultPop" title="엑셀 업로드 실패 사유" sizeType="size6" @closed="resultList = []">
      <ExcelUploadResult :sheet-opt="excelSheetOpt" :list="resultList" />
    </iui-modal>
  </pmis-page>
</template>

<script>
import MatCl from '@/view/sysManage/stdCodeManage/matCodeRegist/components/MatCl.vue';
import DetailInfo from '@/view/sysManage/stdCodeManage/matCodeRegist/components/DetailInfo.vue';
import DetailList from '@/view/sysManage/stdCodeManage/matCodeRegist/components/DetailList.vue';
import UntpcList from '@/view/sysManage/stdCodeManage/components/UntpcList.vue';
import StandardCodePopup from '@/view/sysManage/stdCodeManage/components/StandardCodePopup.vue';
import cloneDeep from 'lodash/cloneDeep';
import {saveStandardCodeList} from './api/MatCodeRegist.js';
import {deleteUcstPri} from '../api/untpcRegist.js';
import ExcelUpload from '@/components/popup/ExcelUpload.vue';
import excelOptions from '@/view/sysManage/stdCodeManage/matCodeRegist/components/sheetOption/ExcelUploadResultSheet.js';
import ExcelUploadResult from '@/components/popup/ExcelUploadResult.vue';
import EXCEL_CONSTANTS from '@/constants/excelConstants.js';

export default {
  components: {
    ExcelUploadResult,
    ExcelUpload,
    MatCl,
    DetailInfo,
    DetailList,
    UntpcList,
    StandardCodePopup,
  },
  data() {
    return {
      costType: $getConstants('COST_TYPE_M').code,
      modalBtns: [{name: '확인', callback: this.onPopupConfirm}],
      matClInfo: {},
      searchNm: '',
      useDs: $getConstants('USE_YN_Y').code,
      matClFocusKey: undefined,
      matCodeFocusKey: undefined,
      isBase: this.$store.getters['mdi/currentUrls'].para === 'base',
      excelBtns: [{name: '확인', callback: this.onPopConfirmExcel}],
      resultList: [],
      excelRows: [
        {
          value: [
            {value: '대분류 코드', style: {type: 'TEXT'}},
            {value: '대분류 명칭'},
            {value: '중분류 코드', style: {type: 'TEXT'}},
            {value: '중분류 명칭'},
            {value: '소분류 코드', style: {type: 'TEXT'}},
            {value: '소분류 명칭'},
            {value: '자재 코드', style: {type: 'TEXT'}},
            {value: '명칭'},
            {value: '규격'},
            {value: '형식'},
            {
              value: '단위코드',
              validate: {
                excelEnum: EXCEL_CONSTANTS.COMMON_CODE,
                pCode: $getConstants('UNIT').code,
                withDropdown: false,
                checksName: false,
              },
            },
            {
              value: '사용여부',
              validate: {
                checkList: [
                  {name: 'Y', value: $getConstants('Y').code},
                  {name: '', value: ''},
                ],
              },
            },
          ],
        },
      ],
      excelSheetOpt: excelOptions(this),
    };
  },
  created() {
    this.addFuncSearch(this.onSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
  },
  methods: {
    onStandardCodePopup() {
      this.$modal.show('standardCodeModal');
    },
    onPopupConfirm() {
      this.callEvent({
        name: 'StandardCodePopup_confirm',
        param: data => {
          this.onSaveStandardCodeList(data);
          this.$modal.hide('standardCodeModal');
        },
      });
    },
    async onSaveStandardCodeList(data) {
      let copyList = [];
      data.forEach(row => {
        copyList.push({
          pgmCd: this.pgmCd,
          level: row.level,
          lmCode: row.lmCode,
          mmCode: row.mmCode,
          smCode: row.smCode,
          matCode: row.matCode,
          itemName: row.itemName,
          size: row.size,
          matStyle: row.matStyle,
          unit: row.unit,
          matGubun: row.matGubun,
          flNo: row.flNo,
          useDs: row.useDs,
        });
      });
      const response = await saveStandardCodeList({copyList});
      if (response.data) {
        this.matClFocusKey = String(copyList[0].smCode);
        this.matCodeFocusKey = String(copyList[0].matCode);
        this.onSearch();
      }
    },
    onCallbackMatCl(row) {
      this.matClInfo = row;
      this.$refs.matCodeList.matClInfo = this.matClInfo;
      this.setUntcpListCode();
      this.initSearchNm();
      this.initMatCodeInfo();
      this.onSearchMatCode();
    },
    setUntcpListCode() {
      if (!this.isBase) {
        this.$refs.untpcList.code = this.matClInfo.level > 1 ? this.matClInfo.code : '';
      }
    },
    setMatCodeInfo(matCodeInfo) {
      this.$refs.matCodeDetail.matCodeInfo = cloneDeep(matCodeInfo);
      this.onUntpcListSearch();
    },
    onUntpcListSearch() {
      if (!this.isBase) {
        this.$refs.untpcList.itemCode = this.$refs.matCodeDetail.matCodeInfo.matCode;
        this.$refs.untpcList.onSearch();
      }
    },
    onMatClSearch(code) {
      this.$refs.matCl.searchInTree(code);
    },
    onAdd() {
      this.initUntpcList();
      this.initMatCodeInfo();
    },
    onSearch() {
      this.$refs.matCl.onSearch();
    },

    async onSave() {
      const result = await this.$refs.matCodeDetail.onSave();
      if (result) {
        this.matClInfo = result.matClInfo;
        this.matCodeReload(result.matCodeInfo);
        this.matClReload();
        this.setCud('0');
      }
    },
    matCodeReload(matCodeInfo) {
      this.$refs.matCodeList.matCodeInfo = matCodeInfo;
      if (matCodeInfo.matCode) {
        this.$refs.matCodeList.onReloadSheet();
      }
    },
    matClReload() {
      this.$refs.matCl.onReloadSheet();
    },
    async onDelete() {
      if (this.cud == 1) {
        this.$alert({title: '삭제', message: '신규 입력 중에는 삭제할 수 없습니다.'});
        return;
      }
      const deletedList = await this.$refs.matCodeList.onDelete();
      if (!deletedList) {
        return;
      }
      deletedList.forEach(item => {
        item.itemCode = item.matCode;
        item.costType = this.costType;
      });

      const response = await deleteUcstPri(deletedList);
      if (response.data) {
        this.initUntpcList();
      }
    },
    async onDeleteMatType() {
      if (this.cud === 1) {
        this.$alert({title: '삭제', message: '신규 입력 중에는 삭제할 수 없습니다.'});
        return;
      }
      const result = await this.$refs.matCl.onDelete();
      if (result) {
        this.untpcDeleteUcstPriAsChildren(result);
      }
    },
    untpcDeleteUcstPriAsChildren(param) {
      if (!this.isBase) {
        this.$refs.untpcList.deleteUcstPriAsChildren({costType: this.costType, itemCode: param.code});
      }
    },
    onSearchMatCode() {
      this.initUntpcList();
      this.initMatCodeList();
      this.$refs.matCodeList.onSearch();
    },
    initMatCodeList() {
      this.$refs.matCodeList.removeAll();
    },
    initSearchNm() {
      this.$refs.matCodeList.searchNm = '';
    },
    initMatCodeInfo() {
      this.$refs.matCodeDetail.matCodeInfo = {useDs: this.cud === 1 && this.matClInfo.level === 4 ? this.useDs : ''};
    },
    initUntpcList() {
      if (!this.isBase) {
        this.$refs.untpcList.removeAll();
      }
    },
    searchByItemNm(searchNm) {
      this.initMatCodeInfo();
      this.$refs.matCodeList.searchNm = searchNm;
      this.onSearchMatCode();
    },
    onPopAddExcel() {
      this.$modal.show('excelUploadPop');
    },
    onPopConfirmExcel() {
      this.callEvent({
        name: 'excelUpload',
        param: () => {
          this.onSearch();
          this.$modal.hide('excelUploadPop');
        },
      });
    },
    onExcelUploadError(error, list) {
      if (error === 'server') {
        this.$alert({title: '엑셀업로드', message: '자재코드 엑셀파일을 확인하십시오.'});
      } else {
        this.$alert({title: '엑셀업로드', message: error});
        if (list && list.length > 0) {
          this.resultList = list;
          this.$modal.show('ExcelUploadResultPop');
        }
      }
    },
  },
};
</script>
