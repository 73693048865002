<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <CustomerBillList />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col>
          <CustomerBillDetail />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import CustomerBillList from '../components/CustomerBillList.vue';
import CustomerBillDetail from '../components/CustomerBillDetail.vue';
export default {
  components: {CustomerBillList, CustomerBillDetail},
};
</script>

<style></style>
