export default that => {
  return {
    Cfg: {
      MainCol: 'fldrNm', // 트리가 표시될 열이름
      NoTreeLines: false, // 트리를 사용하는 시트 생성시 노드와 노드 사이의 연결선을 표시할 지 여부를 설정
      CanEdit: false,
    },
    Def: {
      Row: {
        CanFormula: true,
        CalcOrder: 'fldrCdIndent',
      },
    },
    Cols: [
      {
        Header: '명칭',
        Name: 'fldrNm',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 150,
      },
      {
        Header: '자료개수',
        Name: 'dataCount',
        Extend: that.$ibsheetHelper.preset.number,
        Align: 'center',
        Width: 120,
      },
      {Name: 'csNo', Visible: false},
      {Name: 'pgmCd', Visible: false},
      {Name: 'prjCd', Visible: false},
      {Name: 'fldrCd', Visible: false},
      {Name: 'fldrCd2', Visible: false},
      {Name: 'fldrNo', Visible: false},
      {Name: 'uppFldrNo', Visible: false},
      {Name: 'uppFldrCd', Visible: false},
      {Name: 'uppFldrCd2', Visible: false},
      {Name: 'stYn', Visible: false},
      {Name: 'Level', Visible: false},
    ],
  };
};
