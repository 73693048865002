<template>
  <pmis-content-box :loading="loading">
    <template v-slot:title>계약차수</template>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <ib-sheet
            :uid="_uid"
            :options="Options"
            :loadSearchData="loadSearchData"
            :events="{
              onClick,
            }"
            @loadSheet="loadSheet"
          />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/CntrctChangeDtlsSheet.js';
import {selectCntrctInfoList} from '../api/cntrctInfoRegist.js';

/** 계약정보등록(tab0) > 상세정보 > 계약차수 */
export default {
  data() {
    return {
      elId: 'sheet',
      loadSearchData: [],
      Options: options(this),
      loading: false,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo']);
    $mapActions(this, ['setCtrctInfo', 'setLastCtrtSumAmt']);
  },
  created() {
    this.addEvent([
      {name: 'cntrctChangeDtls_onSearch', func: this.onSearch},
      {name: 'cntrctChangeDtls_init', func: this.initCntrctChangeDtlsList},
    ]);
  },
  methods: {
    async onSearch() {
      if (this.prjCd) {
        this.loading = true;

        this.sheet?.removeAll();
        let param = {
          prjCd: this.prjCd,
          chgListYn: 'Y',
          endCntrInc: this.searchInfo.endCntrInc,
        };
        try {
          const response = await selectCntrctInfoList(param);
          this.loadSearchData = response.data;
          if (response.data.length > 0) {
            this.setCtrctInfo(response.data[0]);
            this.setLastCtrtSumAmt(response.data[0].ctrtSumAmt);
          }
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      }
    },
    initCntrctChangeDtlsList() {
      this.sheet.removeAll();
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.setCtrctInfo(e.row);
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style scoped></style>
