<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text type="search" ref="searchNm" :value.sync="searchNm" @enter="onFocusByNm" />
      <iui-button value="검색" @click="onFocusByNm" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      @loadSheet="loadSheet"
      :events="{onDblClick: onDblClick, onRenderFirstFinish: onSearch}"
    />
  </pmis-content-box>
</template>

<script>
import options from './sheetOptions/InputMatListPopupSheet.js';
import CODE from '@/constants/codeConstants.js';
export default {
  /**
   * 사용 : 자재투입등록
   */
  props: {
    ioDate: {
      type: String,
    },
    itemCode: {
      type: String,
    },
    matGiveYn: {
      type: String,
    },
  },
  created() {
    this.addEvent([{name: 'InputMatListPopup_callbackData', func: this.onCallback}]);
  },
  data() {
    return {
      sheet: undefined,
      sheetUtil: undefined,
      Options: $addColCheckbox(options(this)),
      loadSearchData: [],
      searchNm: '',
    };
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
    async onSearch() {
      const url =
        this.matGiveYn === CODE.Y
          ? '/pymntMtrilInventory/selectInventoryListInSimpleExec'
          : '/delivery/selectInventoryList';
      const param = {
        ioDate: this.ioDate,
        itemCode: this.itemCode,
      };
      const response = await axios.post(url, param);
      this.loadSearchData = response.data;
    },
    onFocusByNm() {
      if (this.sheet.getTotalRowCount() > 0) {
        const searchCnt = this.sheetUtil.listSearch(['matName'], this.searchNm);
        if (searchCnt == 0) {
          this.$alert({title: '자재조회', message: '해당 자재 정보가 존재하지 않습니다'});
        }
        this.$refs.searchNm.$refs.input.focus();
      }
    },
    onCallback(callback) {
      const checkedRows = this.sheet.getRowsByChecked('chk');
      if (0 == checkedRows.length) {
        this.focusedRowCallback(callback);
      } else {
        this.checkRowCallback(checkedRows, callback);
      }
    },
    focusedRowCallback(callback) {
      const focusedRow = this.sheet.getFocusedRow();
      if (focusedRow == null) {
        this.$alert({title: '투입자재선택', message: '선택된 자재가 없습니다.'});
        return;
      }
      if (focusedRow.remQty <= 0) {
        this.$alert({title: '투입자재선택', message: '재고수량이 존재하지 않습니다.'});
        return;
      }
      callback(focusedRow);
    },
    checkRowCallback(checkedRows, callback) {
      let valid = true;
      checkedRows.some(row => {
        if (row.remQty <= 0) {
          valid = false;
          this.$alert({title: '투입자재선택', message: `${row.matName}의 재고수량이 존재하지 않습니다.`});
          this.sheet.setCheck(row, 'chk', false);
          return true;
        }
      });
      if (valid) {
        callback(checkedRows);
      }
    },

    onDblClick(e) {
      if (e.row.Kind == 'Data') {
        this.$modalConfirm(); // => onCallback()
      }
    },
  },
};
</script>

<style></style>
