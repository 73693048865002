<template>
  <iui-portlet-container>
    <template #title>공지사항</template>
    <template #link>
      <a href="#" class="goto_top_link" @click="goShortCut">
        <span class="blind">바로가기</span>
      </a>
    </template>
    <NoticeBoard />
  </iui-portlet-container>
</template>

<script>
import NoticeBoard from './components/noticeBoard.vue';
export default {
  components: {NoticeBoard},
  methods: {
    goShortCut() {
      this.goMenu('NOTICE');
    },
  },
};
</script>

<style></style>
