export default that => {
  return {
    Cfg: {
      SearchMode: 0,
      CanEdit: 1,
    },
    Cols: [
      {
        Header: '권한그룹명',
        Name: 'grpNm',
        Type: 'Text',
        Width: 200,
        CanEditFormula: fr => fr.Row.seq !== 1,
      },
      {
        Header: '비고',
        Name: 'rmk',
        Type: 'Text',
        RelWidth: 1,
        MinWidth: 200,
        CanEditFormula: fr => fr.Row.seq !== 1,
      },
      {Name: 'seq', Visible: false},
    ],
  };
};
