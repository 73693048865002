export default that => {
  const isDailyWorker = laborDs => laborDs === $getConstants('LABOR_DS3').code;
  return {
    Cfg: {
      CanEdit: 1,
      HeaderCheck: 1,
      SpaceForDefaultValue: 1,
      HeaderMerge: 6,
    },
    Cols: [
      {
        Header: ['출역일자', '출역일자'],
        Type: 'Text',
        Align: 'center',
        VAlign: 'Top',
        Name: 'investDt',
        Width: 100,
        CanEdit: 0,
        CustomFormat: v => v.substr(0, 4) + '-' + v.substr(4, 2) + '-' + v.substr(6),
      },
      {
        Header: ['소속명', '소속명'],
        Name: 'blnDepNm',
        Type: 'Text',
        Align: 'left',
        VAlign: 'Top',
        Width: 110,
        CanEdit: 0,
      },
      {
        Header: ['ID', 'ID'],
        Name: 'empNo',
        Type: 'Text',
        Align: 'left',
        VAlign: 'Top',
        Width: 110,
        CanEdit: 0,
        WidthPad: 40,
      },
      {
        Header: ['이름', '이름'],
        Name: 'name',
        Type: 'Text',
        Align: 'left',
        VAlign: 'Top',
        Width: 80,
        CanEdit: 0,
      },
      {
        Header: ['출역정보', '출근시간'],
        Name: 'dailyWorkInTime',
        Type: 'Date',
        Align: 'center',
        VAlign: 'Top',
        Width: 80,
        Size: 4,
        Format: 'HH:mm',
        EditFormat: 'HH:mm',
        Color: '#E0E0F8',
        Button: '',
        CanEditFormula: e => that.monPjtCloseAt == 'N' && (e.Row.closeYn == '' || e.Row.closeYn == null), //마감 && 정산시 수정불가
      },
      {
        Header: ['출역정보', '출근사진'],
        Name: 'workInInfo',
        Type: 'Button',
        Align: 'center',
        VAlign: 'Top',
        Width: 80,
        CanEdit: 0,
        Button: 'Html',
        ButtonText: '<input type="button" class="i_search2" />',
        OnClick: e => that.onShowModal(e.col, e.row),
      },
      {
        Header: ['출역정보', '퇴근시간'],
        Name: 'dailyWorkOutTime',
        Type: 'Date',
        Align: 'center',
        VAlign: 'Top',
        Width: 80,
        Size: 4,
        Format: 'HH:mm',
        EditFormat: 'HH:mm',
        Button: '',
        Color: '#E0E0F8',
        CanEditFormula: e => that.monPjtCloseAt == 'N' && (e.Row.closeYn == '' || e.Row.closeYn == null), //마감 && 정산시 수정불가
      },
      {
        Header: ['출역정보', '퇴근사진'],
        Name: 'workOutInfo',
        Type: 'Button',
        Align: 'center',
        VAlign: 'Top',
        Width: 80,
        CanEdit: 0,
        Button: 'Html',
        ButtonText: '<input type="button" class="i_search2" />',
        OnClick: e => that.onShowModal(e.col, e.row),
      },
      {
        Header: ['출역정보', '근로시간'],
        Name: 'dailyWorkTime',
        Type: 'Text',
        Align: 'center',
        VAlign: 'Top',
        Width: 80,
        CanEdit: 0,
        CustomFormat: function(value) {
          const decemalCnt = value.split('.')[1]?.length;
          return (Math.floor(value * 10) / 10).toFixed(decemalCnt ? 1 : 0);
        },
      },
      {
        Header: ['출역정보', '공제시간'],
        Name: 'deductTime',
        Type: 'Text',
        Align: 'center',
        VAlign: 'Top',
        Width: 80,
        Color: '#E0E0F8',
        CanEditFormula: e => that.monPjtCloseAt == 'N' && (e.Row.closeYn == '' || e.Row.closeYn == null), //마감 && 정산시 수정불가
      },
      {
        Header: ['출역정보', '출역시간'],
        Name: 'attendanceTime',
        Type: 'Text',
        Align: 'center',
        VAlign: 'Top',
        Width: 80,
        CanEdit: 0,
        Formula: fr => {
          let dailyWorkTime = fr.Row['dailyWorkTime'] || 0;
          let deductTime = fr.Row['deductTime'] || 0;
          let _attendanceTime = dailyWorkTime - deductTime;
          if (!Number.isInteger(_attendanceTime)) {
            _attendanceTime = (Math.floor(_attendanceTime * 10) / 10).toFixed(1);
          }
          return _attendanceTime;
        },
      },
      {
        Header: ['출역정보', '일공수'],
        Name: 'dailyInvestQty',
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 80,
        VAlign: 'Top',
        Color: '#E0E0F8',
        CanEditFormula: e => that.monPjtCloseAt == 'N' && (e.Row.closeYn == '' || e.Row.closeYn == null), //마감 && 정산시 수정불가
      },
      {
        Header: ['투입정보', '순번'],
        Name: 'rowNo',
        Type: 'Text',
        Align: 'center',
        Width: 80,
      },
      {
        Header: ['투입정보', '작업분류'],
        Name: 'wbsNm',
        Type: 'Text',
        Align: 'center',
        Width: 100,
        CanEdit: false,
      },
      {
        Header: ['투입정보', '공종명'],
        Name: 'mgName',
        Type: 'Text',
        Align: 'center',
        Width: 100,
        CanEdit: false,
      },
      {
        Header: ['투입정보', '내역명'],
        Name: 'itemName',
        Type: 'Text',
        Align: 'center',
        Width: 120,
        CanEdit: 0,
        WidthPad: 30,
        ButtonText: '<input type="button" class="i_search" style="cursor: pointer;"/>',
        OnClickSide: that.onUnitOpertWithExecDtlsModal,
        ColorFormula: e => (String(e.Row.dailyWorkInTime) != '' && isDailyWorker(e.Row.laborDs) ? '#E0E0F8' : ''),
        ButtonFormula: e => (String(e.Row.dailyWorkInTime) != '' && isDailyWorker(e.Row.laborDs) ? 'Html' : ''),
      },
      {
        Header: ['투입정보', '시작시간'],
        Name: 'dailyTimeWorkInTime',
        Type: 'Date',
        Align: 'center',
        Width: 80,
        Size: 4,
        Format: 'HH:mm',
        EditFormat: 'HH:mm',
        Button: '',
        ColorFormula: e => (String(e.Row.dailyWorkInTime) != '' && isDailyWorker(e.Row.laborDs) ? '#E0E0F8' : ''),
        CanEditFormula: e =>
          isDailyWorker(e.Row.laborDs) &&
          String(e.Row.dailyWorkInTime) != '' &&
          that.monPjtCloseAt == 'N' &&
          (e.Row.closeYn == '' || e.Row.closeYn == null), //마감 && 정산시 수정불가
      },
      {
        Header: ['투입정보', '종료시간'],
        Name: 'dailyTimeWorkOutTime',
        Type: 'Date',
        Align: 'center',
        Width: 80,
        Size: 4,
        Format: 'HH:mm',
        EditFormat: 'HH:mm',
        Button: '',
        ColorFormula: e => (String(e.Row.dailyWorkInTime) != '' ? '#E0E0F8' : ''),
        CanEditFormula: e =>
          isDailyWorker(e.Row.laborDs) &&
          String(e.Row.dailyWorkInTime) != '' &&
          that.monPjtCloseAt == 'N' &&
          (e.Row.closeYn == '' || e.Row.closeYn == null), //마감 && 정산시 수정불가
      },
      {
        Header: ['투입정보', '투입시간'],
        Name: 'dailyTimeWorkTime',
        Type: 'Text',
        Align: 'center',
        Width: 80,
        Size: 5,
        CanEdit: false,
      },
      {
        Header: ['투입정보', '투입공수'],
        Name: 'dailyTimeInvestQty',
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 80,
        Size: 5,
        CanEditFormula: e =>
          isDailyWorker(e.Row.laborDs) &&
          String(e.Row.dailyTimeWorkTime) != '' &&
          that.monPjtCloseAt == 'N' &&
          (e.Row.closeYn == '' || e.Row.closeYn == null), //마감 && 정산시 수정불가
      },
      {
        Header: ['투입정보', '추가/삭제'],
        Name: 'add',
        TypeFormula: e =>
          String(e.Row.dailyWorkInTime) != '' &&
          that.monPjtCloseAt == 'N' &&
          (e.Row.closeYn == '' || e.Row.closeYn == null) &&
          isDailyWorker(e.Row.laborDs)
            ? 'Button'
            : 'Text',
        Align: 'center',
        Width: 40,
        CanEdit: false,
        ButtonText: '<input type="button" class="i_plus" />',
        OnClick: that.inputAdd,
      },
      {
        Header: ['투입정보', '추가/삭제'],
        Name: 'remove',
        TypeFormula: e =>
          that.monPjtCloseAt == 'N' && (e.Row.closeYn == '' || e.Row.closeYn == null) && isDailyWorker(e.Row.laborDs)
            ? 'Button'
            : 'Text',
        Align: 'center',
        Width: 40,
        CanEdit: false,
        ButtonText: '<input type="button" class="i_minus" />',
        OnClick: that.inputRemove,
      },
      {Name: 'seq', Visible: false},
      {Name: 'price', Visible: false},
      {Name: 'amt', Visible: false},
      {Name: 'custCode', Visible: false},
      {Name: 'flNoInPoto', Visible: false},
      {Name: 'flNoOutPoto', Visible: false},
      {Name: 'wcode', Visible: false},
      {Name: 'headCode', Visible: false},
      {Name: 'midCode', Visible: false},
      {Name: 'itemSeq', Visible: false},
      {Name: 'itemCode', Visible: false},
      {Name: 'cud', Visible: false},
      {Name: 'closeYn', Visible: false},
      {Name: 'laborDs', Visible: false},
      {Name: 'poNoSubc', Visible: false},
      {Name: 'jkdNo', Visible: false},
      {Name: 'wbsCd', Visible: false},
      {Name: 'wbsId', Visible: false},
      {Name: 'poNoSubcList', Visible: false},
    ],
  };
};
