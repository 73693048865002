export default that => {
  return {
    Cfg: {
      CanEdit: 1,
    },
    Cols: [
      {
        Header: '공종명',
        Name: 'mgName',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: '내역코드',
        Name: 'itemSeq',
        Type: 'Text',
        Width: '70',
        CanEdit: 0,
      },
      {
        Header: '내역명',
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: '규격',
        Name: 'ssize',
        Type: 'Text',
        RelWidth: 1,
        CanEdit: 0,
      },
      {
        Header: '수량',
        Name: 'qty',
        Extend: that.$ibsheetHelper.preset.quantity,
        CanEdit: 0,
      },
      {
        Header: '금액',
        Name: 'amt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
      },
      {Name: 'wcode', Visible: 0},
      {Name: 'headCode', Visible: 0},
      {Name: 'midCode', Visible: 0},
    ],
  };
};
