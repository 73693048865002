export const createDaily = laborInfo => $save('/laborContract/createDaily', laborInfo);
export const createFixedTerm = laborInfo => $save('/laborContract/createFixedTerm', laborInfo);
export const updateDaily = laborInfo => $save('/laborContract/updateDaily', laborInfo);
export const updateFixedTerm = laborInfo => $save('/laborContract/updateFixedTerm', laborInfo);
export const updateCompSign = signList => $save('/laborContract/updateCompSign', {signList});
export const selectLaborList = searchInfo => axios.post('/laborContract/selectLaborList', searchInfo);
export const send = sendList => $save('/laborContract/send', {sendList});
export const cancel = cancelList => $save('/laborContract/cancel', {cancelList});
export const selectLaborInfo = laborInfo => axios.post('/laborContract/selectLaborInfo', laborInfo);
export const selectLaborInfoForViewer = signList => axios.post('/laborContract/selectLaborInfoForViewer', signList);
export const deleteDaily = rowInfo => $save('/laborContract/deleteDaily', rowInfo);
export const deleteFixedTerm = rowInfo => $save('/laborContract/deleteFixedTerm', rowInfo);
export const deleteLaborContract = deleteList => axios.post('/laborContract/deleteLaborContract', deleteList);
export const selectLaborHistoryByProject = param => axios.post('/laborContract/selectLaborHistoryByProject', param);
