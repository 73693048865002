const IuiLabelPrefixSuffixMixin = {
  props: {
    label: {
      type: String,
      default: undefined,
    },
    prefix: {
      type: String,
      default: undefined,
    },
    suffix: {
      type: String,
      default: undefined,
    },

    labelWidth: {
      type: String,
      default: undefined,
    },
    prefixWidth: {
      type: String,
      default: undefined,
    },
    suffixWidth: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      internalLabel: undefined,
      internalPrefix: undefined,
      internalSuffix: undefined,
      internalLabelWidth: undefined,
      internalPrefixWidth: undefined,
      internalSuffixWidth: undefined,
    };
  },

  computed: {
    // id
    labelId: {
      get() {
        return this.getId('label');
      },
    },
    prefixId: {
      get() {
        return this.getId('prefix');
      },
    },
    suffixId: {
      get() {
        return this.getId('suffix');
      },
    },

    labelStyle: {
      get() {
        let obj = {
          width: undefined,
          flex: undefined,
        };

        if (this.internalLabelWidth !== undefined) {
          obj.width = this.internalLabelWidth;
          obj.flex = `0 0 ${this.internalLabelWidth}`;
        }

        return obj;
      },
    },
    prefixStyle: {
      get() {
        let obj = {
          width: undefined,
          flex: undefined,
        };

        if (this.internalPrefixWidth !== undefined) {
          obj.width = this.internalPrefixWidth;
          obj.flex = `0 0 ${this.internalPrefixWidth}`;
        }

        return obj;
      },
    },
    suffixStyle: {
      get() {
        let obj = {
          width: undefined,
          flex: undefined,
        };

        if (this.internalSuffixWidth !== undefined) {
          obj.width = this.internalSuffixWidth;
          obj.flex = `0 0 ${this.internalSuffixWidth}`;
        }

        return obj;
      },
    },
  },

  created() {
    this.internalLabel = this.label;
    this.internalPrefix = this.prefix;
    this.internalSuffix = this.suffix;

    this.internalLabelWidth = this.labelWidth;
    this.internalPrefixWidth = this.prefixWidth;
    this.internalSuffixWidth = this.suffixWidth;
  },
};

export default IuiLabelPrefixSuffixMixin;
