export default that => {
  return {
    Cfg: {
      CanEdit: true,
      CanSort: false,
      SearchMode: 2,
    },
    Cols: [
      {
        Header: `${that.title[that.type]}코드`,
        Name: that.code[that.type]['code'],
        Type: 'Text',
        CanEdit: false,
        Width: 140,
        Align: 'center',
      },
      {
        Header: `${that.title[that.type]}명칭`,
        Name: 'itemName',
        Type: 'Text',
        CanEdit: false,
        RelWidth: 1,
      },
      {
        Header: '규격',
        Name: 'size',
        Type: 'Text',
        CanEdit: false,
        Width: that.isMat ? 120 : 150,
        Align: 'center',
      },
      {
        Header: '형식',
        Name: 'matStyle',
        Type: 'Text',
        CanEdit: false,
        Width: 120,
        Align: 'center',
        Visible: that.isMat,
      },
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        CanEdit: false,
        Width: that.isMat ? 70 : 100,
        Align: 'center',
        Format: $getConstants('UNIT').code,
      },
    ],
  };
};
