<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="385px"><PrjList /></i-col>
        <i-spacer />
        <i-col min-width="1100px">
          <iui-tab :comps="comps1" @click="setTabIndex" @after-active-tab="afterActiveTab" :isAuto="false">
            <template v-slot:tab-right>
              <iui-button v-if="tabIndex == 0 && isLastChgTo" value="차수복사" @click="onCopy" />
              <iui-button v-if="tabIndex == 1 || tabIndex == 3" value="추가" @click="onAdd" />
              <iui-button v-if="tabIndex == 1 || tabIndex == 3" value="삭제" @click="onDelete" />
              <iui-button v-if="tabIndex == 1 || tabIndex == 3" value="저장" @click="onSave" />
            </template>
          </iui-tab>
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal name="prjRegistPop" sizeType="size2" :btns="modalBtns">
      <PrjRegistPop />
    </iui-modal>
  </pmis-page>
</template>

<script>
/**
 * 계약정보등록
 *  components :
 *    DetailInfo : 상세정보
 *    GuarantyDtls : 보증내역
 *    Orgcht : 조직도
 *    PrjJoint : 공동도급사
 *    PrjRegistPop : 현장생성 팝업
 *    PrjList : 현장 목록
 * */
import store from './store/cntrctInfoRegist.js';
import PrjRegistPop from './popup/PrjRegistPop.vue';
import PrjList from '@/view/Project/components/PrjList.vue';
import {insertNewCntrctInfo} from './api/cntrctInfoRegist.js';
export default {
  components: {PrjRegistPop, PrjList},
  data() {
    return {
      comps1: [
        {
          tabNm: '상세정보',
          compNm: 'DetailInfo',
          path: '@/view/bsnsManage/cntrctManage/cntrctInfoRegist/components/DetailInfo.vue',
        },
        {
          tabNm: '보증내역',
          compNm: 'GuarantyDtls',
          path: '@/view/bsnsManage/cntrctManage/cntrctInfoRegist/components/GuarantyDtls.vue',
        },
        {
          tabNm: '조직도',
          compNm: 'Orgcht',
          path: '@/view/bsnsManage/cntrctManage/cntrctInfoRegist/components/Orgcht.vue',
        },
        {
          tabNm: '공동도급사',
          compNm: 'PrjJoint',
          path: '@/view/bsnsManage/cntrctManage/cntrctInfoRegist/components/PrjJoint.vue',
        },
      ],
      bindArray: ['setSearchInfo'],
      tabIndex: '',
      prjFlag: '',
      modalBtns: [{name: '확인', callback: this.onPopConfirm}],
      modalNm: '',
    };
  },
  computed: {
    isLastChgTo() {
      return this.ctrctInfo.ctrtChgTo == this.ctrctInfo.lastChgTo;
    },
  },
  watch: {
    ctrctInfo: {
      deep: true,
      handler() {
        if (this.ctrctInfo.ctrtChgTo && this.ctrctInfo.lastChgTo) {
          this.onSetCommonButton();
        }
      },
    },
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['ctrctInfo', 'searchInfo']);
    $mapActions(this, ['setCtrctInfo', 'setSearchInfo', 'initCtrctInfo', 'setCanEditPrjNm', 'setCtrctCud']);
  },
  created() {
    this.canSearch = true;
    this.canNew = true;
    this.canSave = true;
    this.canDelete = true;

    this.addFuncNew(this.onAdd);
    this.addFuncSave(this.onSave);
    this.addFuncDelete(this.onDelete);
    this.addFuncPrj(this.onPrjChange);
    this.addEvent([{name: 'prjAddConfirm', func: this.onPopConfirm}]);
  },
  methods: {
    setTabIndex(index) {
      this.tabIndex = index;
      if (this.prjCd) {
        this.callEvent({name: 'setActiveTab', param: index});
        return;
      }
    },
    onPrjChange() {
      this.afterActiveTab(this.tabIndex);
    },
    afterActiveTab(index) {
      this.tabIndex = index;
      this.onSearch();
    },
    onSearch() {
      const param = {pgmCd: this.pgmCd, prjCd: this.searchInfo.prjCd};
      if (this.tabIndex == 0 && this.ctrctInfo.cud == 1) this.callEvent({name: 'prjList_onSearch'}); // 계약목록
      if (this.tabIndex == 0 && this.ctrctInfo.cud != 1) this.callEvent({name: 'cntrctChangeDtls_onSearch'}); // 상세정보
      if (this.tabIndex == 1) this.callEvent({name: 'GuarantyDtls_onSearch', param: param}); // 보증내역
      if (this.tabIndex == 2) this.callEvent({name: 'selectPjtOrgList', param: param}); //조직도
      if (this.tabIndex == 3) this.callEvent({name: 'PrjJoint_onSearch', param: param}); // 공동도급사
      this.setCtrctCud(0);
      this.setCanEditPrjNm(false);
    },
    onAdd() {
      if (this.tabIndex == 0) this.onAddNewPrj();
      if (this.tabIndex == 1) this.callEvent({name: 'GuarantyDtls_onAdd'});
      if (this.tabIndex == 3) this.callEvent({name: 'PrjJoint_onAdd'});
    },
    onSave() {
      if (this.tabIndex == 0) this.callEvent({name: 'DetailInfo_onSave'});
      if (this.tabIndex == 1) this.callEvent({name: 'GuarantyDtls_onSave'});
      if (this.tabIndex == 3) this.callEvent({name: 'PrjJoint_onSave'});
    },
    onDelete() {
      if (this.tabIndex == 0) this.callEvent({name: 'DetailInfo_onDelete'});
      if (this.tabIndex == 1) this.callEvent({name: 'GuarantyDtls_onDelete'});
      if (this.tabIndex == 3) this.callEvent({name: 'PrjJoint_onDelete'});
    },
    onAddNewPrj() {
      // 신규 계약현장 생성
      this.initCtrctInfo();
      this.callEvent({name: 'cntrctChangeDtls_init'});
      this.modalNm = 'prjRegistPop';
      this.$modal.show(this.modalNm, {title: '현장코드등록', callbackFunction: this.onPopConfirm});
    },
    onPopConfirm() {
      // 견적현장 선택
      this.callEvent({
        name: 'prjRegistPopConfirm',
        param: data => {
          this.callEvent({name: 'cntrctChangeDtls_init'});

          let payload = {
            cud: 1,
            rgprId: this.userInfo.empNo,
            flNo: 0,
            workKind: $getConstants('WORK_KIND_SCPAY').code,
            orderGroup: $getConstants('ORDER_GROUP_PRIVATE').code,
            payCashYn: $getConstants('Y').code,
            flawInsrYn: $getConstants('FLAW_INSR_YEAR').code,
            stampTaxYn: $getConstants('STAMP_TAX_ITEM1').code,
          };
          for (let key in data) {
            payload[key] = data[key];
          }
          this.setCtrctInfo(payload);
          this.setSearchInfo({pgmCd: this.pgmCd, prjCd: data.prjCd, prjNm: data.prjNm});
          this.$modal.hide(this.modalNm);
        },
      });
    },
    async onCopy() {
      if (
        await this.$confirm({
          title: '차수복사',
          message: `계약변경 작업을 진행하시겠습니까?<br/>- ${this.ctrctInfo.prjNm}`,
        })
      ) {
        insertNewCntrctInfo({...this.ctrctInfo}).then(response => this.callEvent({name: 'prjList_onSearch'}));
      }
    },
    onSetCommonButton() {
      if (this.isLastChgTo) {
        if (this.saveBtn) this.saveBtn.style = '';
        if (this.deleteBtn) this.deleteBtn.style = '';
      } else {
        if (this.saveBtn) this.saveBtn.style = 'display: none';
        if (this.deleteBtn) this.deleteBtn.style = 'display: none';
      }
    },
  },
};
</script>

<style scoped></style>
