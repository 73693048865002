<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <label>정산년월</label>
      <div class="ml5"></div>
      <iui-datepicker type="month" :value="investMm" @update:value="investMm_update" />
      <i class="prev-arrow" @click.prevent.self @click="onClickArrow('PREVIOUS')" />
      <i class="post-arrow" @click.prevent.self @click="onClickArrow('NEXT')" />
      <div class="ml15"></div>
      <iui-radio-group name="costType" :items="radioItems" :value="costType" @change="onChangedRadio" />
      <span class="mr15" />
      <label>{{ costType !== '2' ? '거래처명' : '근로자명' }}</label>
      <iui-text type="search" :value.sync="searchName" @keyup.enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="options[costType]"
      :init.sync="initSheet"
      :loadSearchData="loadSearchData"
      @loadSheet="sheet = $event"
    />

    <tax-invoice-pop :billNo="billNo" :open.sync="billPopup_open" :pageGubun="pageGubun" />

    <pmis-file-list
      v-if="costType == 4"
      ref="file"
      id="file"
      title="첨부파일"
      title-visible
      :modal="true"
      :hidden="true"
      :file-number="equtFlNo"
      rgprId=""
      @fileModal_closed="equtFlNo = 0"
    />
  </pmis-content-box>
</template>

<script>
import TaxInvoicePop from '@/view/bidding/components/TaxInvoicePop.vue';
import optionsMat from './sheetOption/DetailInfoMatSheet.js';
import optionsLab from './sheetOption/DetailInfoLabSheet.js';
import optionsEqut from './sheetOption/DetailInfoEqutSheet.js';
import optionsExpt from './sheetOption/DetailInfoExptSheet.js';
import optionsSub from './sheetOption/DetailInfoSubSheet.js';
import {
  selectMonthMaterialList,
  selectMonthLaborList,
  selectMonthSubconList,
  selectMonthEquipmentList,
  selectMonthExpenseList,
  selectYmList,
} from '../api/excclcStatusInquiry.js';

export default {
  components: {
    TaxInvoicePop,
  },
  data() {
    return {
      sheet: undefined,
      costType: '1',
      investMm: '',
      searchName: '',
      searchLables: {1: '거래처명', 2: '근로자명', 3: '거래처명', 4: '거래처명', 5: '거래처명'},
      radioItems: [
        {label: '재료비', value: '1'},
        {label: '노무비', value: '2'},
        {label: '장비비', value: '3'},
        {label: '경비', value: '4'},
        {label: '외주비', value: '5'},
      ],
      billNo: '',
      billPopup_open: false,
      equtFlNo: 0,
      initSheet: false,
      loadSearchData: [],
      apis: {
        1: selectMonthMaterialList,
        2: selectMonthLaborList,
        3: selectMonthEquipmentList,
        4: selectMonthExpenseList,
        5: selectMonthSubconList,
      },
      options: {
        1: optionsMat(this),
        2: optionsLab(this),
        3: optionsEqut(this),
        4: optionsExpt(this),
        5: optionsSub(this),
      },
      investMms: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
      },
    };
  },
  created() {
    this.canNew = false;
    this.canSave = false;
    this.canDelete = false;
    this.canPrint = false;
    this.addFuncPrj(this.getYmList);
  },
  methods: {
    investMm_update(e) {
      this.investMm = e;
      if (e) {
        this.onSearch();
      }
    },
    onChangedRadio(e) {
      this.costType = e.target.value;
      this.searchName = '';
      this.loadSearchData = [];
      this.initSheet = true;
      this.getYmList();
    },
    openBillPopup(e) {
      if (e.row.Kind == 'Data') {
        if ((e.col == 'billNo' && e.row.billNo) || (e.col == 'billTaxNo' && e.row.billTaxNo)) {
          this.billNo = e.row[e.col];
          this.billPopup_open = true;
        }
      }
    },
    async onSearch() {
      const param = {investMm: this.investMm, searchName: this.searchName};
      const response = await this.apis[this.costType](param);
      setTimeout(() => (this.loadSearchData = response.data), 300);
    },
    onClickArrow(type) {
      const index = this.investMms[this.costType].indexOf(this.investMm);

      if (index === -1) {
        this.investMm = this.investMms[this.costType][this.investMms[this.costType].length - 1];
        this.onSearch();
      } else {
        if (
          (type === 'PREVIOUS' && index === 0) ||
          (type === 'NEXT' && index + 1 === this.investMms[this.costType].length)
        ) {
          const message = type === 'PREVIOUS' ? '이전 정산년월이 없습니다.' : '다음 정산년월이 없습니다.';
          this.$alert({title: '정산년월', message: message});
          return false;
        }

        this.investMm =
          type === 'PREVIOUS' ? this.investMms[this.costType][index - 1] : this.investMms[this.costType][index + 1];
        this.onSearch();
      }
    },
    async getYmList() {
      const response = await selectYmList({costType: this.costType, prjCd: this.prjCd});

      const array = [];
      response.data.forEach(x => {
        array.push(x.investMm);
      });
      this.investMms[this.costType] = array;

      if (array.length > 0) {
        this.investMm = array[array.length - 1];
        this.onSearch();
      } else {
        this.investMm = '';
        this.loadSearchData = [];
      }
    },
  },
  computed: {
    pageGubun() {
      return this.costType == 3 ? 'sub' : 'mat';
    },
  },
};
</script>

<style></style>
