<template>
  <form onsubmit="return false;">
    <iui-container-new type="css-flex">
      <i-row height="200px">
        <i-col>
          <iui-container-new type="table" theme="bullet">
            <i-row>
              <i-col-header required>일정유형</i-col-header>
              <i-col>
                <iui-select
                  defaultCd="S"
                  :items="codeData.scdTpNo"
                  :value="scheduleInfo.scdTpNo"
                  @change="setSchedule('scdTpNo', $event)"
                  :enable="this.isRegistrant"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>반복설정</i-col-header>
              <i-col>
                <iui-radio-group
                  :items="codeData.rpttDs"
                  :value="scheduleInfo.rpttDs"
                  @change="setSchedule('rpttDs', $event.target.value)"
                  :enable="this.isRegistrant"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header required>적용일자</i-col-header>
              <i-col>
                <iui-datepicker
                  :value="scheduleInfo.stDt"
                  @change="setSchedule('stDt', $event)"
                  :enable="this.isRegistrant"
                  required
                  :error-message="{title: '적용일자', message: '적용 시작일자를 지정해주세요'}"
                />
                <iui-datepicker
                  v-if="scheduleInfo.rpttDs !== '0'"
                  prefix="~"
                  :value="scheduleInfo.edDt"
                  @change="setSchedule('edDt', $event)"
                  :enable="this.isRegistrant"
                  :required="scheduleInfo.rpttDs !== '0'"
                  :error-message="{title: '적용일자', message: '적용 종료일자를 지정해주세요'}"
                />
                <iui-checkbox-group
                  :items="codeData.odaDs"
                  :value="scheduleInfo.odaDs"
                  @change="setSchedule('odaDs', $event.target.checked ? '1' : '')"
                  :enable="this.isRegistrant"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>일정시간</i-col-header>
              <i-col>
                <iui-select
                  suffix="시"
                  :items="codeData.hrList"
                  :value="scheduleInfo.stHr"
                  @change="setSchedule('stHr', $event)"
                  :enable="this.isRegistrant && !isAllDay"
                />
                <iui-select
                  suffix="분부터"
                  :items="codeData.miList"
                  :value="scheduleInfo.stMi"
                  @change="setSchedule('stMi', $event)"
                  :enable="this.isRegistrant && !isAllDay"
                />
                <iui-select
                  suffix="시"
                  :items="codeData.hrList"
                  :value="scheduleInfo.edHr"
                  @change="setSchedule('edHr', $event)"
                  :enable="this.isRegistrant && !isAllDay"
                />
                <iui-select
                  suffix="분까지"
                  :items="codeData.miList"
                  :value="scheduleInfo.edMi"
                  @change="setSchedule('edMi', $event)"
                  :enable="this.isRegistrant && !isAllDay"
                />
              </i-col>
            </i-row>
            <i-row v-if="scheduleInfo.rpttDs && scheduleInfo.rpttDs !== '0'">
              <i-col-header required>
                반복방법
              </i-col-header>

              <!-- 매일 -->
              <i-col v-if="scheduleInfo.rpttDs === '1'">
                <iui-text
                  prefix="매"
                  suffix="일 마다"
                  type="number"
                  width="104px"
                  :value="scheduleInfo.rpttSetDs"
                  @change="setSchedule('rpttSetDs', $event.target.value)"
                  max-length="7"
                  :enable="this.isRegistrant"
                  :required="scheduleInfo.rpttDs === '1'"
                  :error-message="{title: '반복방법', message: '반복방법을 설정해주세요'}"
                />
              </i-col>
              <!-- 매주 -->
              <i-col v-else-if="scheduleInfo.rpttDs === '2'">
                <iui-checkbox-group
                  :items="codeData.weekDay"
                  separator=""
                  :value="scheduleInfo.rpttSetDs"
                  @change="setSchedule('rpttSetDs', $event.values)"
                  :enable="this.isRegistrant"
                  :required="scheduleInfo.rpttDs === '2'"
                  :error-message="{title: '반복방법', message: '반복방법을 설정해주세요'}"
                />
              </i-col>
              <!-- 매월 -->
              <i-col v-else-if="scheduleInfo.rpttDs === '3'">
                <iui-select
                  prefix="매"
                  suffix="개월"
                  :items="codeData.monthList"
                  :value="scheduleInfo.rpttSetDs1"
                  @change="setSchedule('rpttSetDs1', $event)"
                  :enable="this.isRegistrant"
                  :required="scheduleInfo.rpttDs === '3'"
                  :error-message="{title: '반복방법', message: '반복방법을 설정해주세요'}"
                />
                <iui-select
                  suffix="일마다 반복"
                  :items="codeData.dayList"
                  :value="scheduleInfo.rpttSetDs2"
                  @change="setSchedule('rpttSetDs2', $event)"
                  :enable="this.isRegistrant"
                  :required="scheduleInfo.rpttDs === '3'"
                  :error-message="{title: '반복방법', message: '반복방법을 설정해주세요'}"
                />
              </i-col>

              <!-- 매년 -->
              <i-col v-else-if="scheduleInfo.rpttDs === '4'">
                <iui-select
                  prefix="매년"
                  suffix="월"
                  :items="codeData.monthList"
                  :value="scheduleInfo.rpttSetDs1"
                  @change="setSchedule('rpttSetDs1', $event)"
                  :enable="this.isRegistrant"
                  :required="scheduleInfo.rpttDs === '4'"
                  :error-message="{title: '반복방법', message: '반복방법을 설정해주세요'}"
                />
                <iui-select
                  suffix="일마다 반복"
                  :items="codeData.dayList"
                  :value="scheduleInfo.rpttSetDs2"
                  @change="setSchedule('rpttSetDs2', $event)"
                  :enable="this.isRegistrant"
                  :required="scheduleInfo.rpttDs === '4'"
                  :error-message="{title: '반복방법', message: '반복방법을 설정해주세요'}"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col />
              <i-col v-show="scheduleInfo.rpttDs === '0'"> </i-col>
              <i-col v-show="scheduleInfo.rpttDs === '1'">
                ※ 일(日)주기로 반복되는 일정을 설정합니다.
              </i-col>
              <i-col v-show="scheduleInfo.rpttDs === '2'">
                ※ 주(週) 주기로 반복되는 일정을 설정합니다. 매주간 체크된 요일마다 반복됩니다.
              </i-col>
              <i-col v-show="scheduleInfo.rpttDs === '3'">
                ※ 월(月) 주기로 반복되는 일정을 설정합니다.
              </i-col>
              <i-col v-show="scheduleInfo.rpttDs === '4'">
                ※ 년(年) 주기로 반복되는 일정을 설정합니다.
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col>
          <iui-container-new type="table" theme="bullet">
            <i-row>
              <i-col-header required>제목</i-col-header>
              <i-col>
                <iui-text
                  :value="scheduleInfo.tl"
                  @change="setSchedule('tl', $event.target.value)"
                  :enable="this.isRegistrant"
                  max-length="200"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>장소</i-col-header>
              <i-col>
                <iui-text
                  :value="scheduleInfo.plc"
                  @change="setSchedule('plc', $event.target.value)"
                  :enable="this.isRegistrant"
                  max-length="200"
                />
              </i-col>
            </i-row>
            <i-row height="100px">
              <i-col-header>내용</i-col-header>
              <i-col>
                <iui-text
                  type="multi"
                  :value="scheduleInfo.cts"
                  @change="setSchedule('cts', $event.target.value)"
                  :enable="this.isRegistrant"
                  max-length="1000"
                />
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>
  </form>
</template>

<script>
import CodeData from './ScheduleCode.js';

export default {
  data() {
    return {
      codeData: new CodeData(),

      rpttSetDsWeek: [],
    };
  },
  computed: {
    isAllDay() {
      return this.scheduleInfo.odaDs === '1';
    },
  },
  beforeCreate() {
    $mapGetters(this, ['scheduleList', 'scheduleInfo', 'isRegistrant']);
    $mapMutations(this, ['setScheduleInfo', 'initSchedule']);
  },
  created() {
    this.addEvent([
      {name: 'DetailSchedule_onAdd', func: this.onAdd},
      {name: 'DetailSchedule_onSave', func: this.onSave},
      {name: 'DetailSchedule_onDelete', func: this.onDelete},
    ]);
  },
  methods: {
    setSchedule(key, value) {
      let payload = {[key]: value};

      if (key == 'rpttDs') {
        payload = {rpttDs: value, rpttSetDs: '', rpttSetDs1: '', rpttSetDs2: ''};
      }
      this.setScheduleInfo(payload);
    },
    onAdd() {
      this.initSchedule({rgprId: this.userInfo.empNo});
    },
    onSave() {
      this.setScheduleInfo({pgmCd: this.pgmCd});

      let strDate = $_getCurrentDate();
      let currentTime = new Date();

      let strTime =
        (currentTime.getHours() < 10 ? '0' : '') +
        String(currentTime.getHours()) +
        (currentTime.getMinutes() < 10 ? '0' : '') +
        String(currentTime.getMinutes()); //ex) 0930

      if (!this.scheduleInfo.scdTpNo) {
        this.$alert({title: '입력', message: '일정 유형을 선택해주세요.'});
        return;
      }
      if (this.scheduleInfo.stDt < strDate || this.scheduleInfo.edDt < strDate) {
        this.$alert({title: '입력', message: '금일 이전 일자는 등록 불가합니다.'});
        return;
      }
      if (this.scheduleInfo.stDt > this.scheduleInfo.edDt) {
        this.$alert({title: '입력', message: '적용일자 입력이 잘못되었습니다.'});
        return;
      }
      if (
        this.scheduleInfo.stDt === strDate &&
        (String(`${this.scheduleInfo.stHr}${this.scheduleInfo.stMi}`) < strTime ||
          String(`${this.scheduleInfo.edHr}${this.scheduleInfo.edMi}`) < strTime)
      ) {
        //적용일자 오늘일 때 일정시간 비교
        this.$alert({title: '입력', message: '금일 이전 시간은 등록 불가합니다.'});
        return;
      }

      let args = {
        url: '/collaboration/schedule/saveSchedule',
        param: this.scheduleInfo,
        mode: 'simple',
        title: '일정 등록',
        message: '일정을 등록하시겠습니까?',
      };

      this.$save(args).then(response => {
        if (response.status === 200) {
          this.callEvent({name: 'ScheduleIndex_onSearch'});
        }
      });
    },
    async onDelete() {
      if (!this.scheduleInfo.scdNo) {
        this.$alert({title: '일정 삭제', message: '삭제할 일정을 선택해주세요.'});
        return;
      }

      if (!(await this.$confirm({title: '일정 삭제', message: '일정을 삭제하시겠습니까?'}))) return;

      let response = await axios.post('/collaboration/schedule/deleteSchedule', this.scheduleInfo);

      if (response.status === 200) {
        this.initSchedule();
        this.callEvent({name: 'ScheduleIndex_onSearch'});
      }
    },
  },
};
</script>

<style></style>
