import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
export default {
  name: 'ib-sheet',
  props: {
    uid: {
      type: Number,
      required: true,
    },
    options: {
      type: [Object, Promise],
      required: true,
    },
    loadSearchData: {
      type: Array,
      default: () => [],
    },
    append: {
      type: [String, Number, Boolean],
      default: false,
    },
    autoLoad: {
      type: Boolean,
      default: true,
    },
    autoRender: {
      type: Boolean,
      default: true,
    },
    makeSubTotal: {
      type: Object,
    },
    init: {
      type: Boolean,
      default: false,
    },
    onBeforeChange: {
      type: Function,
    },
    events: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      id: `sheetDiv${this._uid}`,
      sheetUtil: undefined,
      Options: undefined,
      loaded: false,
      sheet: undefined,
      queueList: [],
      load: true,
    };
  },
  watch: {
    loadSearchData: {
      handler(value, oldValue) {
        if (this.loaded) {
          this.pushQueueList(value);
        }
      },
      deep: true,
    },
    init(value) {
      if (value) {
        this.initSheet();
      }
    },
  },
  async mounted() {
    if (this.autoRender) {
      this.initSheet();
    }
  },
  methods: {
    pushQueueList(data) {
      this.queueList.push(cloneDeep(data));
      this.loadData();
    },
    popQueueList() {
      this.queueList.splice(0, 1);
      this.loadData();
    },
    loadData() {
      if (this.queueList.length) {
        this.sheet.loadSearchData(this.queueList[0], this.append);
      }
    },
    getParentComponent(parent) {
      if (!parent) {
        return;
      }
      if (this.uid === parent._uid) {
        parent._sheet = this.sheet;
        return;
      }
      this.getParentComponent(parent.$parent);
    },
    async initSheet() {
      if (this.sheet) {
        this.sheet.dispose();
        this.sheet = undefined;
      }
      this.Options = cloneDeep(this.options instanceof Promise ? await this.options : this.options);
      this.addEventsInOptions();
      const codeFormatObj = await this.setFormatInOptions();
      await this.setEnumInOptions();
      this.addFormulaInOptions();
      if (this.Options instanceof Promise) {
        await this.Options;
      }
      this.sheet = await PMISSheet.createSheet({
        el: this.id,
        options: this.Options,
      });

      this.sheetUtil = this.sheet.util;
      this.sheet.codeFormatObj = codeFormatObj;
      this.$emit('loadSheet', this.sheet);
      this.$emit('update:init', false);
      this.getParentComponent(this.$parent);
    },

    addEventsInOptions() {
      this.Options = {
        ...this.Options,
        Events: this.customEventFilter(),
      };
    },
    customEventFilter() {
      const events = {};
      for (let [key, value] of Object.entries(this.events)) {
        if (value instanceof Function) {
          if (key === 'onClick' && this.Options.Cfg?.MainCol) {
            key = 'propsOnClick';
          }
          if (key === 'onSearchFinish') {
            key = 'propsOnSearchFinish';
          }
          if (key === 'onRenderFirstFinish') {
            key = 'propsOnRenderFirstFinish';
          }
          if (key === 'onScroll') {
            key = 'propsOnScroll';
          }
          if (key === 'onAfterClick') {
            key = 'propsOnAfterClick';
          }

          events[key] = value;
        }
      }

      if (this.Options.Events instanceof Object) {
        for (let key in this.Options.Events) {
          if (events[key]) {
            events[key] = this.Options.Events[key];
          }
        }
      }

      if (events['propsOnClick']) {
        const isTreeBtnClick = e => {
          // 트리 버튼 클릭 시 하위항목 확장만 되게 하기 위한 계산
          let x = Number(e.x);
          let y = Number(e.y);
          let LEVEL = Number(e.row.Level); // IBSheet Tree LEVEL
          let leftStart = 20 * LEVEL + 5;
          let leftEnd = 20 * (LEVEL + 1);
          let topStart = 7;
          let topEnd = 22;

          return !(leftStart <= x && x <= leftEnd && topStart <= y && y <= topEnd);
        };
        const deepChildren = row => {
          if (!row || !row.childNodes.length) return row;

          let item = {
            code: row.code,
            name: row.name,
          };

          let deep2 = function(sheet, row, item) {
            if (row.childNodes) {
              let subChildRow;
              for (let i = 0; i < row.childNodes.length; i++) {
                if (i == 0) {
                  subChildRow = row.firstChild;
                } else {
                  subChildRow = subChildRow.nextSibling;
                }
                if (subChildRow) {
                  if (!item.Items) item.Items = [];
                  sheet.setExpandRow(subChildRow, null, 0);
                  item.Items.push({
                    code: subChildRow.code,
                    name: subChildRow.name,
                  });
                  deep2(sheet, subChildRow, item.Items[i]);
                }
              }
            }
            return item;
          };

          let childRow;
          for (let i = 0; i < row.childNodes.length; i++) {
            if (i == 0) childRow = row.firstChild;
            else childRow = childRow.nextSibling;

            if (childRow) {
              if (!item.Items) item.Items = [];
              this.sheet.setExpandRow(childRow, null, 0);
              item.Items.push({
                code: childRow.code,
                name: childRow.name,
              });
              deep2(this.sheet, childRow, item.Items[i]);
            }
          }
          return item;
        };
        const onClick = e => {
          if (isTreeBtnClick(e)) {
            events['propsOnClick'](e);
            return;
          }
          if (e.row.Level === 0 && e.row.Expanded === 1) {
            e.sheet.showTreeLevel(2, 0, 1);
            this.$emit('tree_ExpandCollapse');
          } else {
            deepChildren(e.row);
          }
        };
        events['onClick'] = onClick;
      }

      const onRenderFirstFinish = e => {
        if (this.autoLoad && this.loadSearchData.length) {
          this.pushQueueList(this.loadSearchData);
        }

        if (e.sheet.DataMerge !== null || e.sheet.HeaderMerge !== null) {
          e.sheet.setAutoMerge(e.sheet.DataMerge, e.sheet.HeaderMerge);
        }

        if (events['propsOnRenderFirstFinish']) {
          events['propsOnRenderFirstFinish'](e);
        }
        this.loaded = true;
      };
      events['onRenderFirstFinish'] = onRenderFirstFinish;

      const onSearchFinish = e => {
        if (events['propsOnSearchFinish']) {
          events['propsOnSearchFinish'](e);
        }
        if (this.sheetUtil.paging) {
          this.sheetUtil.searchFinishInScrollPaging(e);
        }
        this.popQueueList();
      };
      events['onSearchFinish'] = onSearchFinish;

      const onScroll = e => {
        if (events['propsOnScroll']) {
          events['propsOnScroll'](e);
        }
        if (this.sheetUtil.paging) {
          this.sheetUtil.onScrollInScrollPaging(e);
        }
      };
      events['onScroll'] = onScroll;

      const onAfterClick = e => {
        if (events['propsOnAfterClick']) {
          events['propsOnAfterClick'](e);
        }
        if (this.sheetUtil.afterClickColNm) {
          this.sheetUtil.onAfterClick(e);
        }
      };
      events['onAfterClick'] = onAfterClick;

      return events;
    },

    setFormatInOptions() {
      return new Promise((resolve, reject) => {
        let codeFormatArray = [];
        let codeFormatObj = {};
        for (let col of this.Options.LeftCols ?? []) {
          if (/^\d{4}$/.test(col.Format)) {
            codeFormatArray.push(col.Format);
          }
        }
        for (let col of this.Options.Cols ?? []) {
          if (/^\d{4}$/.test(col.Format)) {
            codeFormatArray.push(col.Format);
          }
        }
        for (let col of this.Options.RightCols ?? []) {
          if (/^\d{4}$/.test(col.Format)) {
            codeFormatArray.push(col.Format);
          }
        }

        let codeFormatPromises = codeFormatArray.map(async code => {
          codeFormatObj[code] = await $getCodeIBSheetFormat(code);
        });

        Promise.all(codeFormatPromises ?? []).then(() => {
          for (let col of this.Options.LeftCols ?? []) {
            if (codeFormatObj[col.Format]) {
              col.Format = codeFormatObj[col.Format];
            }
          }
          for (let col of this.Options.Cols ?? []) {
            if (codeFormatObj[col.Format]) {
              col.Format = codeFormatObj[col.Format];
            }
          }
          for (let col of this.Options.RightCols ?? []) {
            if (codeFormatObj[col.Format]) {
              col.Format = codeFormatObj[col.Format];
            }
          }
          resolve(codeFormatObj);
        });
      });
    },
    setEnumInOptions() {
      return new Promise((resolve, reject) => {
        let enumKeysArray = [];
        let enumArray = [];
        let enumKeysObj = {};
        let enumObj = {};

        for (let col of this.Options.LeftCols ?? []) {
          if (/^\d{4}$/.test(col.EnumKeys)) {
            enumKeysArray.push(col.EnumKeys);
          }
          if (/^\d{4}$/.test(col.Enum)) {
            enumArray.push(col.Enum);
          }
        }
        for (let col of this.Options.Cols ?? []) {
          if (/^\d{4}$/.test(col.EnumKeys)) {
            enumKeysArray.push(col.EnumKeys);
          }
          if (/^\d{4}$/.test(col.Enum)) {
            enumArray.push(col.Enum);
          }
        }
        for (let col of this.Options.RightCols ?? []) {
          if (/^\d{4}$/.test(col.EnumKeys)) {
            enumKeysArray.push(col.EnumKeys);
          }
          if (/^\d{4}$/.test(col.Enum)) {
            enumArray.push(col.Enum);
          }
        }

        let enumKeysPromises = enumKeysArray.map(async code => {
          enumKeysObj[code] = await $getCodeIBSheetEnum('KEY', code, {defaultKey: ''});
        });
        let enumPromises = enumArray.map(async code => {
          enumObj[code] = await $getCodeIBSheetEnum('VALUE', code, {defaultKey: '선택'});
        });

        let arrayPromise = [...enumKeysPromises, ...enumPromises];

        Promise.all(arrayPromise ?? []).then(() => {
          for (let col of this.Options.LeftCols ?? []) {
            if (enumKeysObj[col.EnumKeys]) {
              col.EnumKeys = enumKeysObj[col.EnumKeys];
            }
            if (enumObj[col.Enum]) {
              col.Enum = enumObj[col.Enum];
            }
          }
          for (let col of this.Options.Cols ?? []) {
            if (enumKeysObj[col.EnumKeys]) {
              col.EnumKeys = enumKeysObj[col.EnumKeys];
            }
            if (enumObj[col.Enum]) {
              col.Enum = enumObj[col.Enum];
            }
          }
          for (let col of this.Options.RightCols ?? []) {
            if (enumKeysObj[col.EnumKeys]) {
              col.EnumKeys = enumKeysObj[col.EnumKeys];
            }
            if (enumObj[col.Enum]) {
              col.Enum = enumObj[col.Enum];
            }
          }
          resolve(enumKeysObj);
        });
      });
    },
    addFormulaInOptions() {
      let CalcOrders = [];
      this.Options.LeftCols?.forEach(col => {
        let formulas = Object.keys(col).filter(key => /Formula/.test(key));
        formulas.forEach(formula => {
          let calcOrder = `${col.Name}${formula.replace(/Formula/, '')}`;
          let ifCalcOrder = this.Options.Def?.Row?.CalcOrder?.indexOf(calcOrder);
          if (ifCalcOrder === undefined || ifCalcOrder === -1) {
            CalcOrders.push(`${col.Name}${formula.replace(/Formula/, '')}`);
          }
        });
      });
      this.Options.Cols?.forEach(col => {
        let formulas = Object.keys(col).filter(key => /Formula/.test(key));
        formulas.forEach(formula => {
          let calcOrder = `${col.Name}${formula.replace(/Formula/, '')}`;
          let ifCalcOrder = this.Options.Def?.Row?.CalcOrder?.indexOf(calcOrder);
          if (ifCalcOrder === undefined || ifCalcOrder === -1) {
            CalcOrders.push(`${col.Name}${formula.replace(/Formula/, '')}`);
          }
        });
      });
      this.Options.RightCols?.forEach(col => {
        let formulas = Object.keys(col).filter(key => /Formula/.test(key));
        formulas.forEach(formula => {
          let calcOrder = `${col.Name}${formula.replace(/Formula/, '')}`;
          let ifCalcOrder = this.Options.Def?.Row?.CalcOrder?.indexOf(calcOrder);
          if (ifCalcOrder === undefined || ifCalcOrder === -1) {
            CalcOrders.push(`${col.Name}${formula.replace(/Formula/, '')}`);
          }
        });
      });
      if (this.Options.Def?.Row?.CalcOrder) {
        CalcOrders.unshift(this.Options.Def.Row.CalcOrder);
      }
      this.Options = merge({}, this.Options, {Def: {Row: {CanFormula: 1, CalcOrder: CalcOrders.join(',')}}});
    },

    calculate() {
      this.sheet.calculate(1);
    },
  },
  beforeDestroy() {
    IBSheet.some(sheet => {
      if (sheet && this.id == sheet.MainTag.id) {
        IBSheetLoader.removeSheet(sheet.id);
        return true;
      }
    });
  },
  render(createElement) {
    const div = 'div';
    let classList = [{'iui-loading-overlay-parent': true}];
    let style = {
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'relative',
    };
    let attrs = {
      id: this.id,
    };
    const data = {
      class: classList,
      style: style,
      attrs: attrs,
    };
    return createElement(div, data);
  },
};
