import Validator from './Validator.js';
import ValidatorRule from './ValidatorRule.js';
import IuiValidatorMixin from './IuiValidatorMixin.js';
import IuiValidatorPlugin from './IuiValidatorPlugin.js';

// Vue.use(IuiValidatorPlugin);

export {Validator, ValidatorRule, IuiValidatorPlugin, IuiValidatorMixin};

export default IuiValidatorPlugin;
