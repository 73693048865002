const state = {
  sheetData: {
    pgmCd: '',
    prjCd: '',
    csNo: '',
    fldrNo: '',
    fldrNm: '',
    fldrCd: '',
    fldrCd2: '',
    uppFldrNo: '',
    uppFldrCd: '',
    stYn: '',
    Level: '',
    currentLevel: '',
    nextLevel: '',
  },
  detailInfo: {
    pgmCd: '',
    prjCd: '',
    docNo: '',
    revNo: '',
    sncNo: '',
    tl: '',
    docNoTl: '',
    rgprId: '',
    rgprNm: '',
    rgsDttm: '',
    fileCount: '',
    fbsNo: '',
    fbsCd: '',
    fbsNm: '',
    rmk: '',
    flNo: 0,
    stYn: '',
    stnDocNo: '',
  },
  focusKeys: {
    fbsNo: 0,
    docNo: 0,
  },
};

const getters = {
  sheetData: state => state.sheetData,
  detailInfo: state => state.detailInfo,
  focusKeys: state => state.focusKeys,
};

const mutations = {
  onSetSheetData(state, payload) {
    for (let key in state.sheetData) {
      if (payload && payload.hasOwnProperty(key)) {
        state.sheetData[key] = payload[key];
      }
    }
  },
  onSetDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        const value = key == 'flNo' ? parseInt(payload[key]) : payload[key];
        state.detailInfo[key] = value;
      }
    }
  },
  onInitDetailInfo(state) {
    for (let key in state.detailInfo) {
      const initValue = key == 'flNo' ? 0 : '';
      state.detailInfo[key] = initValue;
    }
  },
  setFocusKeys(state, payload) {
    for (const key in state.focusKeys) {
      if (payload && payload.hasOwnProperty(key)) {
        state.focusKeys[key] = payload[key];
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
