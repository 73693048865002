export default that => {
  return {
    Cfg: {
      CanEdit: false,
    },
    Cols: [
      {
        Header: '순번',
        Name: 'snclSn',
        Type: 'Text',
        Width: 70,
        Align: 'center',
      },
      {
        Header: '결재유형',
        Name: 'sncTpNm',
        Type: 'Text',
        Width: 100,
        Align: 'center',
        Format: $getConstants('SNC_TP').code,
      },
      {
        Header: '결재상태',
        Name: 'sncKdNm',
        Type: 'Text',
        Width: 100,
        Align: 'center',
      },
      {
        Header: '부서',
        Name: 'blnDepNm',
        Type: 'Text',
        RelWidth: 1,
        Align: 'center',
      },
      {
        Header: '직급',
        Name: 'rollNm',
        Type: 'Text',
        Width: 100,
        Align: 'center',
      },
      {
        Header: '결재자',
        Name: 'name',
        Type: 'Text',
        Width: 100,
        Align: 'center',
      },
      {
        Header: '결재일시',
        Name: 'sncDttm',
        Type: 'Text',
        Width: 140,
        Align: 'center',
      },
    ],
  };
};
