<template>
  <pmis-page :search-project-visible="false" @search-click="onSearch" @delete-click="onDelete" :loading="loading">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="350px">
          <prj-list />
        </i-col>
        <i-spacer />
        <i-col minWidth="1100px">
          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <labor-list @onClickRow="onClickTab" />
              </i-col>
            </i-row>
            <i-spacer />
            <i-row>
              <i-col>
                <iui-tab :comps="tabComps" @click="onClickTab">
                  <template #tab-right>
                    <iui-button
                      value="계약서보기"
                      @click="onShowLaborContract()"
                      v-if="tabIndex === 0 && isProcessCompanySigned"
                    />
                  </template>
                </iui-tab>
              </i-col>
            </i-row>
          </iui-container-new>
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal name="LaborContractViewerModal" size-type="size6" title="근로계약서">
      <labor-contract-viewer :fomNo="fomNo" :ozContent="ozContent" :ozInputJsonStr="ozInputJsonStr" />
    </iui-modal>
  </pmis-page>
</template>

<script>
import PrjList from '@/view/Project/components/PrjList.vue';
import LaborList from './components/LaborList.vue';
import LaborContractViewer from './components/popup/LaborContractViewer.vue';
import {deleteLaborContract, selectLaborInfoForViewer} from './api/laborContract.js';
import FOM_NO from '@/constants/fomNoConstants.js';

import store from './store/LaborContract.js';
export default {
  components: {PrjList, LaborList, LaborContractViewer},
  data() {
    return {
      loading: false,
      tabIndex: 0,
      tabComps: [
        {
          tabNm: '계약정보',
          compNm: 'ContractInfo',
          path: '@/view/sysManage/userManage/laborContract/components/tab/ContractInfo.vue',
        },
        {
          tabNm: '현장별계약이력',
          compNm: 'ContractHistory',
          path: '@/view/sysManage/userManage/laborContract/components/tab/ContractHistory.vue',
        },
      ],

      fomNo: 0,
      ozContent: {},
      ozInputJsonStr: {},
    };
  },
  watch: {
    isAfterWritten(v) {
      if (!this.rowInfo.empNo || v) {
        this.canSave = false;
        this.canDelete = false;
      } else {
        this.canSave = true;
        this.canDelete = true;
      }
    },
    isBeforeWrite(v) {
      if (!this.rowInfo.empNo || v) {
        this.canDelete = false;
      } else {
        this.canDelete = true;
      }
    },
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['rowInfo', 'laborInfo', 'isBeforeWrite', 'isAfterWritten', 'isProcessCompanySigned', 'isDaily']);
    $mapMutations(this, ['setSearchInfo', 'initSearchInfo', 'initTimeInfo']);
  },
  created() {
    this.canNew = false;
    this.addEvent([
      {name: 'set_searchInfo', func: this.onClickProject},
      {name: 'index_onShowLaborContract', func: this.onShowLaborContract},
    ]);
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'prjList_onSearch'});
    },
    onClickProject(row) {
      if (row) {
        this.initSearchInfo();
        this.setSearchInfo({searchGubun3: $getConstants('LABOR_SEARCH_GB1').code, searchText: ''});
        this.callEvent({name: 'LaborList_Search'});
        this.initTimeInfo();
      } else {
        this.onInit();
        this.callEvent({name: 'LaborList_Init'});
      }
    },
    onDelete() {
      this.callEvent({
        name: 'LaborList_GetDeleteRows',
        param: async chkedRows => {
          let deleteFixedTermList = new Array();
          let deleteDailyList = new Array();

          if (chkedRows.length) {
            deleteFixedTermList = chkedRows.filter(item => item.laborDs === $getConstants('LABOR_DS2').code);
            deleteDailyList = chkedRows.filter(item => item.laborDs === $getConstants('LABOR_DS3').code);
          } else {
            this.rowInfo.laborDs === $getConstants('LABOR_DS2').code
              ? deleteFixedTermList.push(this.laborInfo)
              : deleteDailyList.push(this.laborInfo);
          }

          if (!(await this.$confirm({title: '근로계약정보 삭제', message: '삭제하시겠습니까?'}))) return;

          deleteLaborContract({deleteFixedTermList, deleteDailyList}).then(response => {
            if (response.status === 200) {
              this.setSearchInfo({searchGubun3: $getConstants('LABOR_SEARCH_GB0').code});
              this.callEvent({name: 'LaborList_Search'});
            }
          });
        },
      });
    },
    onClickTab(index) {
      this.tabIndex = index ?? this.tabIndex;

      if (0 == this.tabIndex) this.callEvent({name: 'ContractInfo_Search'});
      if (1 == this.tabIndex) this.callEvent({name: 'ContractHistory_Search'});
    },
    async onShowLaborContract() {
      if (this.isDaily) {
        this.fomNo = FOM_NO.LABOR_CONTRACT_DAILY;
      } else {
        this.fomNo = FOM_NO.LABOR_CONTRACT_FIXED_TERM;
      }

      let param = {
        empNo: this.rowInfo.empNo,
        laborDs: this.rowInfo.laborDs,
      };

      let response = await selectLaborInfoForViewer(param);
      this.ozContent = JSON.parse(response.data.contents);
      this.ozInputJsonStr = JSON.parse(response.data.inputContents);
      this.$modal.show('LaborContractViewerModal');
    },
  },
};
</script>

<style></style>
