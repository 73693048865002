export default that => {
  return {
    Cfg: {
      CanEdit: false,
      HeaderMerge: 6,
    },
    Def: {Row: {CanFocus: 0}},
    Cols: [
      {
        Name: 'wbsNm',
        Header: ['명일작업내용', '단위작업'],
        RelWidth: 1,
        MinWidth: 200,
        Type: 'Text',
      },
      {
        Name: 'planCnt',
        Header: ['명일작업내용', '예정수량'],
        Width: 100,
        Extend: that.$ibsheetHelper.preset.quantity,
      },
    ],
  };
};
