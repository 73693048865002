<template>
  <pmis-content-box>
    <pmis-file-list
      :toolbar-visible="true"
      :company-code="String(paramObj.pgmCd)"
      :file-number.sync="flNo"
      :toolbarVisible="toolbarVisible"
      :changed.sync="fileChanged"
      v-model="files"
      :searchData="{flDsList: [$getConstants('FL_DS_NORMAL').code, $getConstants('FL_DS_IMAGE').code]}"
      @save-complete="file_save_complete"
      @save-error="file_save_error"
    />
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    paramObj: {
      type: Object,
    },
  },
  data() {
    return {
      flNo: 0,
      toolbarVisible: false,
      fileSave: false,
      fileChanged: false,
      files: [],
    };
  },
  created() {
    this.flNo = this.paramObj.flNo || 0;
    this.toolbarVisible = this.paramObj.toolbarVisible;
    this.addEvent([
      {name: 'BoardFilePopup_confirm', func: this.onConfirm},
      {name: 'BoardFilePopup_close', func: this.onClose},
    ]);
  },

  methods: {
    async onConfirm(callback) {
      if (this.fileChanged) {
        this.$alert('파일정보가 변경되었습니다.<br/>변경된 파일정보를 저장해야합니다.');
        return;
      }
      callback(this.flNo, this.files.length);
    },
    onClose(callback) {
      callback(this.flNo, this.files.length);
    },
    file_save_complete() {},
    file_save_error() {
      this.$alert('파일 저장을 실패했습니다.');
    },
  },
};
</script>

<style></style>
