<template>
  <pmis-tab-box>
    <template #title>수금정보</template>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col min-width="600px">
          <pmis-content-box>
            <template #title>기성횟수별 수금내역</template>
            <ib-sheet
              :uid="_uid"
              :options="sheetOpt"
              :loadSearchData="loadSearchData"
              @loadSheet="sheet = $event"
              :events="{
                onFocus: sheet_onFocus,
                onSearchFinish: sheet_onSearchFinish,
              }"
            />
          </pmis-content-box>
        </i-col>
        <i-spacer />
        <i-col min-width="600px">
          <pmis-content-box>
            <template #title>수금정보</template>
            <template #title-right>
              <template v-if="isExistPxcond">
                <iui-button value="추가" @click="onAdd" />
                <iui-button value="저장" @click="onSave" />
                <iui-button value="삭제" @click="onDelete" />
              </template>
            </template>
            <form onsubmit="return false;">
              <iui-container-new type="table" theme="bullet">
                <colgroup>
                  <col width="90px" />
                  <col width="150px" />
                  <col width="20px" />
                  <col width="90px" />
                  <col />
                </colgroup>
                <i-row>
                  <i-col-header>수금횟수</i-col-header>
                  <i-col>
                    <iui-text :value="pxcondCollectInfo.repCnt" width="90px" :enable="false" />
                  </i-col>
                  <i-spacer />
                  <i-col-header required>수금일자</i-col-header>
                  <i-col>
                    <iui-datepicker
                      :value="pxcondCollectInfo.collectDate"
                      @change="setPxcondCollectInfo({collectDate: $event})"
                      :enable="isEnable"
                      :required="isEnable"
                      :errorMessage="{title: '수금일자', message: '수금일자를 선택해주세요.'}"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header required>수금금액</i-col-header>
                  <i-col>
                    <iui-text
                      type="amount"
                      :value="pxcondCollectInfo.sumAmt"
                      :enable="false"
                      :required="true"
                      :errorMessage="{title: '수금금액', message: '수금금액을 입력해주세요.'}"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>현금</i-col-header>
                  <i-col>
                    <iui-text
                      type="amount"
                      :value="pxcondCollectInfo.cashAmt"
                      @change="setPxcondCollectInfo({cashAmt: $event.target.value})"
                    />
                  </i-col>
                  <i-spacer />
                  <i-col>수금계좌번호</i-col>
                  <i-col>
                    <iui-text
                      type="largecode"
                      :value="pxcondCollectInfo.collectBankNm"
                      :enable="false"
                      :required="!!pxcondCollectInfo.cashAmt"
                      :errorMessage="{title: '수금은행', message: '수금은행을 선택해주세요.'}"
                    />
                    <iui-button btn-class="button_s" @click="onShowModal" v-if="isEnable"></iui-button>
                    <iui-text
                      type="largecode"
                      max-length="20"
                      :value="pxcondCollectInfo.collectAcctNo"
                      :enable="isEnable"
                      @change="setPxcondCollectInfo({collectAcctNo: $event.target.value})"
                      :required="!!pxcondCollectInfo.cashAmt"
                      :errorMessage="{title: '수금계좌번호', message: '수금계좌번호를 입력해주세요.'}"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>어음</i-col-header>
                  <i-col>
                    <iui-text
                      type="amount"
                      :value="pxcondCollectInfo.billAmt"
                      @change="setPxcondCollectInfo({billAmt: $event.target.value})"
                      :required="false"
                    />
                  </i-col>
                  <i-spacer />
                  <i-col>어음번호</i-col>
                  <i-col>
                    <iui-text
                      width="120px"
                      max-length="50"
                      :value="pxcondCollectInfo.billNo"
                      @change="setPxcondCollectInfo({billNo: $event.target.value})"
                      :required="!!pxcondCollectInfo.billAmt"
                      :errorMessage="{title: '어음번호', message: '어음번호를 입력해주세요.'}"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col></i-col>
                  <i-col></i-col>
                  <i-col></i-col>
                  <i-col colspan="2">
                    <iui-container-new type="table" theme="bullet">
                      <colgroup>
                        <col style="width: 90px" />
                        <col style="min-width: 95px" />
                        <col style="width: 90px" />
                        <col style="min-width: 95px" />
                      </colgroup>
                      <i-row>
                        <i-col>
                          <label>어음발행일자</label>
                        </i-col>
                        <i-col>
                          <iui-datepicker
                            :value="pxcondCollectInfo.billStrdate"
                            @change="setPxcondCollectInfo({billStrdate: $event})"
                            :required="!!pxcondCollectInfo.billAmt"
                            :errorMessage="{title: '어음발행일자', message: '어음발행일자를 입력해주세요.'}"
                          />
                        </i-col>
                        <i-col>
                          <label>어음만기일자</label>
                        </i-col>
                        <i-col>
                          <iui-datepicker
                            :value="pxcondCollectInfo.billEnddate"
                            @change="setPxcondCollectInfo({billEnddate: $event})"
                            :required="!!pxcondCollectInfo.billAmt"
                            :errorMessage="{title: '어음만기일자', message: '어음만기일자를 입력해주세요.'}"
                          />
                        </i-col>
                      </i-row>
                    </iui-container-new>
                  </i-col>
                </i-row>
              </iui-container-new>
            </form>
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>

    <iui-modal name="bankModal" title="은행코드" :btns="modalBtns">
      <iui-bank-code />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/PxcondCollectListSheet.js';
import {
  selectPxcondCollectList,
  selectPxcondCollectInfo,
  savePxcondCollectInfo,
  deletePxcondCollectInfo,
} from '@/view/pxcondBilclctRegist/api/pxcondBilclctRegist.js';

export default {
  data() {
    return {
      sheetOpt: sheetOpt(this),
      loadSearchData: [],
      isNew: false,
      modalBtns: [{name: '확인', callback: this.onConfirm}],
    };
  },
  computed: {
    isExistPxcond() {
      return 0 < Number(this.pxcondInfo.reqCnt); // 기성정보
    },
    isExistPxcondCollect() {
      return 0 < Number(this.pxcondCollectInfo.repCnt); // 수금정보
    },
    isEnable() {
      return this.isNew || this.isExistPxcondCollect;
    },
  },
  beforeCreate() {
    $mapGetters(this, ['pxcondInfo', 'pxcondCollectInfo']);
    $mapMutations(this, ['setPxcondCollectInfo', 'initPxcondCollectInfo']);
  },
  created() {
    this.addEvent([
      {name: 'PxcondCollectList_init', func: this.onInit},
      {name: 'PxcondCollectList_search', func: this.onSearch},
    ]);
  },
  methods: {
    onInit() {
      this.isNew = false;
      this.sheet.removeAll();
      this.initPxcondCollectInfo();
    },
    onSearch() {
      this.isNew = false;
      selectPxcondCollectList(this.pxcondInfo).then(response => (this.loadSearchData = response.data));
    },
    onSearchDetail(row) {
      selectPxcondCollectInfo({reqCnt: row.reqCnt, repCnt: row.repCnt}).then(response => {
        this.setPxcondCollectInfo(response.data);
      });
    },
    onChange(target, event) {
      const value = typeof event === 'string' ? event : event.target.value;
      this.setPxcondCollectInfo({[target]: value});
    },
    onAdd() {
      this.isNew = true;
      this.initPxcondCollectInfo();
      this.setPxcondCollectInfo({
        pgmCd: this.pxcondInfo.pgmCd,
        prjCd: this.pxcondInfo.prjCd,
        reqCnt: this.pxcondInfo.reqCnt,
        repCnt: '자동',
      });
    },
    onSave() {
      const title = '수금정보 저장';
      if (!this.isNew && !this.isExistPxcondCollect) {
        this.$alert({title, message: '저장할 수금정보가 없습니다.'});
        return;
      }

      if (this.pxcondCollectInfo.sumAmt > this.pxcondInfo.uncollectAmt) {
        this.$alert({title, message: '수금금액을 확인하세요.<br/>(미수금액을 초과하였습니다.)'});
        return;
      }

      const param = {...this.pxcondCollectInfo, cud: this.isNew ? 1 : 2};
      param.repCnt = this.isNew ? 0 : param.reqCnt;

      savePxcondCollectInfo(param)
        .then(response => {
          if (0 < response.data) {
            this.callEvent({name: 'PxcondList_Search', param: this.pxcondInfo.reqCnt});
          }
        })
        .catch(ex => {
          this.$alert({title: '저장', message: `저장을 실패했습니다.<br/>${ex}`});
        });
    },
    async onDelete() {
      const focusedRow = this.sheet.getFocusedRow();
      if (focusedRow == null) {
        this.$alert({title: '삭제', message: '삭제할 수금정보가 없습니다.'});
        return false;
      }

      if (!(await this.$confirm({title: '삭제', message: '삭제하시겠습니까?'}))) {
        return;
      }

      const param = {
        pgmCd: focusedRow.pgmCd,
        prjCd: focusedRow.prjCd,
        reqCnt: focusedRow.reqCnt,
        repCnt: focusedRow.repCnt,
      };

      deletePxcondCollectInfo(param)
        .then(response => {
          if (0 < response.data) {
            this.callEvent({name: 'PxcondList_Search', param: this.pxcondInfo.reqCnt});
          }
        })
        .catch(ex => {
          this.$alert({title: '저장', message: `저장을 실패했습니다.<br/>${ex}`});
        });
    },
    onShowModal() {
      this.$modal.show('bankModal');
    },
    onConfirm() {
      this.callEvent({
        name: 'getBankCode',
        param: data => {
          this.setPxcondCollectInfo({collectBankNo: data.code, collectBankNm: data.codeNm});
          this.$modal.hide('bankModal');
        },
      });
    },
    sheet_onFocus(e) {
      if (e.ocol) {
        this.onSearchDetail(e.row);
      }
    },
    sheet_onSearchFinish(e) {
      if (0 == e.sheet.getTotalRowCount()) {
        this.onInit();
      }

      e.sheet.makeSubTotal(
        [
          {
            stdCol: 'total',
            sumCols: 'sumAmt|uncolectAmt',
            captionCol: [{col: 'repCnt', val: '합계', span: 2}],
          },
        ],
        0
      );
    },
  },
};
</script>

<style></style>
