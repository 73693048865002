export default async that => {
  return {
    Cfg: {
      SearchMode: 0, // 0 : FastLoad 모드 (대용량 처리 방식)
      CanEdit: 0,
    },
    Cols: [
      {
        Header: '노무코드',
        Name: 'laborCode',
        Type: 'Text',
        Align: 'center',
        Width: 140,
      },
      {
        Header: '명칭',
        Name: 'itemName',
        Type: 'Text',
        RelWidth: 2,
      },
      {
        Header: '규격',
        Name: 'size',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
      },
      {
        Header: '단위',
        Name: 'unit',
        Type: 'Text',
        Align: 'center',
        RelWidth: 1,
        Format: await $getCodeIBSheetFormat($getConstants('UNIT').code),
      },
    ],
  };
};
