<template>
  <iui-modal
    :name="'pop' + parentId"
    :title="popupTitle"
    :width="width"
    :height="height"
    @opened="opened"
    v-if="isModal"
  >
    <Document
      :param="param"
      :parentId="parentId"
      :isModal="isModal"
      :excuteExternal="excuteExternal"
      :fileSaveAuth="fileSaveAuth"
      @approvalEmit="onApprovalEmit"
      :search.sync="search"
      :approval-status.sync="approvalStatus"
    />
  </iui-modal>
  <Document
    :param="param"
    :parentId="parentId"
    :isModal="isModal"
    :excuteExternal="excuteExternal"
    :fileSaveAuth="fileSaveAuth"
    @approvalEmit="onApprovalEmit"
    :search.sync="search"
    :approval-status.sync="approvalStatus"
    v-else
  />
</template>

<script>
import Document from '@/view/approval/draft/popup/Document.vue';

export default {
  components: {
    Document,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    parentId: {
      type: [String, Number],
    },
    isModal: {
      type: Boolean,
      default: true,
    },
    offline: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      param: {},
      excuteExternal: {},
      fileSaveAuth: false,
      search: false,
      width: !this.offline ? '1200px' : '700px',
      height: !this.offline ? '100%' : '600px',
      approvalStatus: undefined,
    };
  },
  watch: {
    offline(value) {
      this.width = !value ? '1200px' : '700px';
      this.height = !value ? '100%' : '600px';
    },
  },
  computed: {
    popupTitle() {
      return this.title || this.approvalStatus;
    },
  },
  created() {
    window.addEventListener('resize', this.resize);
    this.resize();
    let _id = this.parentId ?? '';

    this.addEvent([
      {name: 'draft_pop' + _id, func: this.pop},
      {name: 'list_search', func: this.listSearch},
    ]);
  },

  methods: {
    resize() {
      const width = window.innerWidth > 1200 ? 1200 : window.innerWidth - 100;
      this.width = !this.offline ? `${width}px` : '700px';
    },
    async pop(param) {
      if (param.excuteExternal) this.excuteExternal = param.excuteExternal;
      this.param = param;
      if (this.isModal) {
        this.onPopupShow();
      } else {
        this.search = true;
      }
    },
    opened() {
      this.search = true;
    },
    onPopupShow() {
      this.$modal.show('pop' + this.parentId);
    },
    listSearch() {
      if (this.$parent.$parent._search !== undefined) {
        this.$parent.$parent._search();
      }
    },
    onApprovalEmit(inputJson) {
      this.$emit('approvalEmit', inputJson);
    },
  },
};
</script>

<style></style>
