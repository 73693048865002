<template>
  <div style="width:100%; height: 100%" id="OZViewerDiv">
    <div id="OZViewer"></div>
  </div>
</template>

<script>
import {selectTemplateInfo} from '@/view/sysManage/baseSysManage/StandardTemplateRegist/apis/template.js';
export default {
  props: {
    propsPgmCd: {
      type: String,
    },
    fomNo: {
      type: [Number, String],
      default: undefined,
    },
    ozContent: {
      type: Object,
      default: undefined,
    },
  },
  mounted() {
    this.onSearch();
  },
  methods: {
    async onSearch() {
      const param = {pgmCd: this.propsPgmCd || this.pgmCd, fomNo: this.fomNo};
      const response = await selectTemplateInfo(param);
      this.onInitOZ(response.data.rptNm);
    },
    onInitOZ(rptNm) {
      window.SetOZParamters_OZViewer = () => {
        let ozParamStr = '';
        let ozSap = ';OZ;';
        let ozServer = '/eform/server';

        let content = this.ozContent ? this.ozContent : {};
        let contentKeys = Object.keys(content);

        for (let i in contentKeys) {
          ozParamStr += `${ozSap}connection.args${Number(i) + 1}=${contentKeys[i]}=${content[contentKeys[i]]}`;
        }

        ozParamStr += `${ozSap}export.mode=false`;
        ozParamStr += `${ozSap}connection.servlet=${ozServer}`;
        ozParamStr += `${ozSap}connection.reportname=${rptNm}`;
        ozParamStr += `${ozSap}connection.pcount=${contentKeys.length}`; //ozr의 입력컴포넌트에 값을 세팅

        ozParamStr += `${ozSap}viewer.progresscommand=true`;
        ozParamStr += `${ozSap}viewer.ignore_disable_color_inputcomponent=true`;

        let ozParams = ozParamStr.split(ozSap);
        let oz = document.getElementById('OZViewer');

        for (let key in ozParams) {
          let paramStr = ozParams[key];
          if (paramStr.indexOf('=') > -1) {
            let paramK = paramStr.substring(0, paramStr.indexOf('='));
            let paramV = paramStr.substring(paramStr.indexOf('=') + 1);
            oz.sendToActionScript(paramK, paramV);
          }
        }
        return true;
      };

      start_ozjs('OZViewer', '/eform/ozhviewer/');
    },
  },
};
</script>

<style></style>
