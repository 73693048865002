<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>단위작업실적물량</template>
            <UnitOpertExecCnt />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import store from '@/view/workAcmsltVolmRegist/store/workAcmsltVolmRegist.js';
import PrjList from '@/view/Project/components/PrjList.vue';
import UnitOpertExecCnt from '@/view/workAcmsltVolmRegist/components/UnitOpertExecCnt.vue';
import {updateContWplanExecAsResultQty} from './api/acmsltVolmRegist.js';
export default {
  components: {
    PrjList,
    UnitOpertExecCnt,
  },

  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['searchInfo']);
    $mapMutations(this, ['setSearchInfo']);
  },

  created() {
    this.canNew = false;
    this.addFuncPrj(this.onSearch);
    this.addFuncSave(this.onSave);
  },

  methods: {
    onSearch() {
      this.callEvent({name: 'unitOpertExecCntSheetInq'});
    },

    async onSave() {
      if (!this.searchInfo.resultDate) {
        this.$alert({title: '실적일자', message: '실적일자를 입력해주세요.'});
        return;
      }
      let contWplanExecDList;
      this.callEvent({
        name: 'getChangedDatas',
        param: datas => {
          if (datas.length) {
            contWplanExecDList = datas;
          }
        },
      });
      if (!contWplanExecDList) {
        return;
      }
      try {
        const param = {
          resultDate: this.searchInfo.resultDate.replace(/-/g, ''),
          contWplanExecDList,
        };
        const response = await updateContWplanExecAsResultQty(param);
        if (response.data) {
          this.callEvent({name: 'getResultDate'});
          this.onSearch();
        }
      } catch (error) {
        if (error.code == 'ACMSLT_RESULT_QTY') {
          this.$alert({
            title: '실적물량저장',
            message: `도급내역의 수량보다 실적수량이 과투입 되었습니다. <br /><br /> ${error.description}`,
          });
        }
      }
    },
  },
};
</script>
