<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="500px">
          <pmis-tab-box>
            <template v-slot:title>사용자목록</template>
            <UserList @click="onClickUserList" />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-content-box>
            <iui-tab :comps="comps" :isAuto="false" @click="onSetTabIndex" @after-active-tab="onAfterActiveTab">
              <template v-slot:tab-right>
                <iui-button value="저장" @click="onSave" />
              </template>
            </iui-tab>
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import UserList from './components/UserList.vue';
import store from './store/userAuthRegist.js';
import {saveUserGroupR, saveUserMenuAuth} from './api/userAuth.js';

export default {
  components: {UserList},

  data() {
    return {
      comps: [
        {
          tabNm: '권한그룹',
          compNm: 'authGrp',
          path: '@/view/sysManage/authManage/userAuthRegist/components/UserGroupList.vue',
        },
        {
          tabNm: '메뉴정보',
          compNm: 'menuInfo',
          path: '@/view/sysManage/authManage/userAuthRegist/components/GrpMenuAuthList.vue',
        },
      ],
      tabIndex: 0,
    };
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['grpAuthInfo', 'empNo']);
    $mapMutations(this, ['setEmpNo']);
  },
  created() {
    this.canSearch = true;
    this.addFuncSearch(this.onSearch);
  },
  methods: {
    onCompanyChanged() {
      this.onSearch();
    },
    onSearch() {
      this.callEvent({name: 'UserList_selectUserList'});
    },
    onClickUserList(empNo) {
      this.setEmpNo(empNo);
      this.onSetTabIndex(this.tabIndex);
    },
    onSetTabIndex(index) {
      this.tabIndex = index;
      this.callEvent({name: 'setActiveTab', param: this.tabIndex});
    },
    onAfterActiveTab() {
      if (this.tabIndex == 0) this.callEvent({name: 'UserGroupList_selectUserGroupRList'});
      if (this.tabIndex == 1) this.callEvent({name: 'GrpMenuAuthList_selectUserMenuAuthList'});
    },
    onSave() {
      if (this.tabIndex == 0) {
        this.callEvent({
          name: 'UserGroupList_getSaveJson',
          param: data => {
            data.forEach(el => {
              el.auth = el.auth == 1 ? true : false;
              el.empNo = this.empNo;
            });
            saveUserGroupR({userAuthList: data}).then(response => {
              this.callEvent({name: 'UserGroupList_selectUserGroupRList', param: this.empNo});
            });
          },
        });
      } else {
        this.callEvent({
          name: 'GrpMenuAuthList_grpMenuAuthSaveJson',
          param: data => {
            data.forEach(el => {
              (el.pgmCd = this.pgmCd), (el.empNo = this.empNo);
            });
            saveUserMenuAuth({userAuthList: data}).then(response => {
              this.callEvent({name: 'GrpMenuAuthList_selectUserMenuAuthList', param: this.empNo});
            });
          },
        });
      }
    },
  },
};
</script>

<style></style>
