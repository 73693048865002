<template>
  <pmis-tab-box>
    <template v-slot:title>팀원현황</template>
    <pmis-content-box>
      <template v-slot:header-left>
        <div class="ml5">성명</div>
        <iui-text
          class="ml5"
          name="name"
          type="search"
          @change="setSearchInfo({searchNm: $event.target.value.replace(/^ /gi, '')})"
          @enter="onSearch"
        />
        <iui-button value="검색" @click="onSearch" />
      </template>
      <iui-container-new type="css-flex">
        <i-row>
          <i-col width="400px">
            <ib-sheet
              :uid="_uid"
              :options="Options1"
              :loadSearchData="loadSearchData1"
              :events="{
                onFocus,
              }"
              @loadSheet="loadSheet1"
            />
          </i-col>
          <i-spacer />
          <i-col>
            <iui-container-new type="css-flex">
              <i-row min-height="210px">
                <i-col min-width="400px">
                  <pmis-content-box>
                    <template v-slot:title>근로자정보</template>
                    <iui-container-new type="table" theme="bullet">
                      <i-row>
                        <i-col-header>성명</i-col-header>
                        <i-col><iui-text :value="workerInfo.name" :enable="false" width="120px"/></i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>전화번호</i-col-header>
                        <i-col><iui-text type="phone" :value="workerInfo.tlno" :enable="false" width="120px"/></i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>휴대폰번호</i-col-header>
                        <i-col>
                          <iui-text type="phone" :value="workerInfo.mobileTlno" :enable="false" width="120px" />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>주소</i-col-header>
                        <i-col>
                          <iui-text
                            :enable="false"
                            :value="workerInfo.postNo"
                            width="70px"
                            :css="{'text-align': 'center'}"
                          />
                          <iui-text :value="workerInfo.adr1" :enable="false" />
                        </i-col>
                      </i-row>
                      <i-row>
                        <i-col></i-col>
                        <i-col><iui-text :value="workerInfo.adr2" :enable="false"/></i-col>
                      </i-row>
                      <i-row>
                        <i-col-header>이메일</i-col-header>
                        <i-col>
                          <iui-text type="email1" :value="workerInfo.email1" :enable="false" width="120px" />
                          <iui-text type="email2" :value="workerInfo.email2" :enable="false" prefix="@" />
                        </i-col>
                      </i-row>
                    </iui-container-new>
                  </pmis-content-box>
                </i-col>
              </i-row>
              <i-row minHeight="200px">
                <i-col>
                  <pmis-content-box>
                    <template v-slot:title>투입현장정보</template>
                    <ib-sheet
                      :uid="_uid"
                      :options="Options2"
                      :loadSearchData="loadSearchData2"
                      @loadSheet="loadSheet2"
                    />
                  </pmis-content-box>
                </i-col>
              </i-row>
            </iui-container-new>
          </i-col>
        </i-row>
      </iui-container-new>
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
import options1 from './sheetOption/teamMemberStatusSheet1';
import options2 from './sheetOption/teamMemberStatusSheet2';
import {selectWorkerInfo, selectInputPrjList} from '@/view/user/Worker/api/worker.js';
import {selectTeamMbrSttsList} from '../api/lbrcTeamRegist.js';
export default {
  data: function() {
    return {
      loadSearchData1: [],
      loadSearchData2: [],
      Options1: options1(this),
      Options2: options2(this),

      workerInfo: {
        name: '', //성명
        tlno: '', //전화번호
        mobileTlno: '', //휴대폰번호
        postNo: '', //우편번호
        adr1: '', //주소
        adr2: '', //상세주소
        email1: '', //이메일1
        email2: '', //이메일2
      },
    };
  },
  beforeCreate() {
    $mapGetters(this, {lbrcTeamInfo: 'lbrcTeamInfo', searchInfo: 'searchInfo'});
    $mapMutations(this, ['setLbrcTeamInfo', 'setSearchInfo']);
  },
  created() {
    this.addEvent([
      {name: 'teamMemberStatus_onSearch', func: this.onSearch},
      {name: 'teamMemberStatus_onInit', func: this.onInit},
    ]);
  },
  methods: {
    onFocus(e) {
      if (e.row.Kind == 'Data') {
        let param = {pgmCd: this.pgmCd, empNo: e.row.empNo};

        selectWorkerInfo(param).then(response => {
          let data = response.data;
          this.workerInfo.name = data.name;
          this.workerInfo.tlno = data.tlno;
          this.workerInfo.mobileTlno = data.mobileTlno;
          this.workerInfo.postNo = data.postNo;
          this.workerInfo.adr1 = data.adr1;
          this.workerInfo.adr2 = data.adr2;
          this.workerInfo.email1 = data.email1;
          this.workerInfo.email2 = data.email2;
          selectInputPrjList(param).then(response => (this.loadSearchData2 = response.data));
        });
      }
    },
    async onSearch() {
      this.onInit();
      let param = {
        pgmCd: this.pgmCd,
        blnDepNo: this.lbrcTeamInfo.labCode,
        searchNm: this.searchInfo.searchNm,
      };
      let response = await selectTeamMbrSttsList(param);
      this.loadSearchData1 = response.data;
    },
    onInit() {
      this.sheet1.removeAll();
      this.sheet2.removeAll();

      for (let key in this.workerInfo) {
        this.workerInfo[key] = '';
      }
    },
    loadSheet1(sheet1) {
      this.sheet1 = sheet1;
    },
    loadSheet2(sheet2) {
      this.sheet2 = sheet2;
    },
  },
};
</script>

<style></style>
