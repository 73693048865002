<template>
  <iui-container-new type="css-flex">
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onSearchFinish,
        onFocus,
      }"
      @loadSheet="loadSheet"
    />
  </iui-container-new>
</template>

<script>
/**
 * 보유장비등록 > 보유장비 목록
 *
 * */
import options from './sheetOptions/HoldEqupmnListSheet.js';
import {selectEqutCompanyCodeList} from '../api/HoldEqpmnRegist.js';
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      loadSearchData: [],
      Options: options(this),
    };
  },
  beforeCreate() {
    $mapGetters(this, ['detailInfo', 'focusKey']);
    $mapMutations(this, ['initDetailInfo', 'setDetailInfo', 'initFocusKey']);
  },
  created() {
    this.addEvent([
      {name: 'HoldEqupmnList_onSearch', func: this.onSearch},
      {name: 'HoldEqupmnList_getCheckedRows', func: this.getCheckedRows},
    ]);
  },
  methods: {
    onSearchFinish(e) {
      if (0 == e.sheet.getTotalRowCount()) {
        this.initDetailInfo();
        this.callEvent({name: 'InputHist_Init'});
      } else if (this.focusKey) {
        let focusedRow = e.sheet.getDataRows().find(row => row.carNo == this.focusKey);
        e.sheet.focus(focusedRow);
        this.initFocusKey();
      }
    },
    onFocus(e) {
      if (e.row.Kind == 'Data') {
        this.setDetailInfo(e.row);
        //투입이력 조회
        this.callEvent({name: 'InputHist_onSearch'});
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    async onSearch(equipCode) {
      let param = {pgmCd: this.pgmCd, equipCode: equipCode};
      const response = await selectEqutCompanyCodeList(param);
      this.loadSearchData = response.data;
      if (this.callback) {
        let errRow = this.sheet.getRowById('AR1');
        this.callback(errRow);
      }
    },
    getCheckedRows(callback) {
      const checkedRows = this.sheet.getRowsByChecked('cbx');
      callback(checkedRows);
    },
  },
};
</script>

<style scoped></style>
