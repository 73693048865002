export default that => {
  return {
    Cfg: {
      CanEdit: false,
      HeaderMerge: 6,
    },
    Def: {
      Row: {
        CanFocus: 0,
        CanFormula: 1,
      },
    },
    Cols: [
      {
        Name: 'gb',
        Header: ['구분', '구분'],
        Width: 80,
        Format: {PRE: '전월누계', CUR: '당월', TOT: '당월누계'},
        Align: 'Center',
      },
      {
        Name: 'rsumAmt1',
        Header: ['매출실적', {Value: '매출액', Color: '#dff9fb'}],
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'forceAmt',
        Header: ['매출실적', {Value: '매출원가', Color: '#dff9fb'}],
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'sumAmtRate',
        Header: ['매출실적', {Value: '원가율(%)', Color: '#dff9fb'}],
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.rate,
      },
      {
        Name: 'sumProfitAmt',
        Header: ['매출실적', {Value: '매출이익', Color: '#dff9fb'}],
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'remainderPreAmt',
        Header: ['수금현황', {Value: '선급금잔액', Color: '#dff9fb'}],
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'psumAmt',
        Header: ['수금현황', {Value: '선급금정산금', Color: '#dff9fb'}],
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'contAmt',
        Header: ['수금현황', {Value: '기성청구금액', Color: '#dff9fb'}],
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
      {
        Name: 'uncollectSumAmt',
        Header: ['수금현황', {Value: '미수금', Color: '#dff9fb'}],
        MinWidth: 90,
        RelWidth: 1,
        Extend: that.$ibsheetHelper.preset.amount,
        Formula: fr => {
          return Math.floor(fr.Row[fr.Col]);
        },
      },
    ],
  };
};
