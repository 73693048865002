import {selectLabMonDetailList, selectLabMonList} from '../api/fixedTimeLabor.js';
const state = {
  searchInfo: {
    investMm: '',
    empName: '',
  },
  detailInfo: {
    empNo: '',
    monthlyDay: 0,
    monthlyQty: 0,
    monthlyAmt: 0,
    incometax: 0,
    resitax: 0,
    employInsurAmt: 0,
    pensionAmt: 0,
    healthInsurAmt: 0,
    careAmt: 0,
    deductSum: 0,
    actPayAmt: 0,
    poNo: '',
    custCode: '',
  },
  labMonList: [],
  calculateList: [],
  closeYn: '',
};
const getters = {
  searchInfo: state => state.searchInfo,
  taxRtInfo: state => state.taxRtInfo,
  detailInfo: state => state.detailInfo,
  labMonList: state => state.labMonList,
  calculateList: state => state.calculateList,
  closeYn: state => state.closeYn,
  fixedTimeLabSaveDataReqDto: state => {
    const keys = [
      'investMm',
      'empNo',
      'custCode',
      'employInsurAmt',
      'pensionAmt',
      'healthInsurAmt',
      'careAmt',
      'deductSum',
      'actPayAmt',
    ];
    let param = {};
    for (let key in state.searchInfo) {
      if (keys.findIndex(item => item === key) !== -1) {
        param[key] = state.searchInfo[key];
      }
    }
    for (let key in state.detailInfo) {
      if (keys.findIndex(item => item === key) !== -1) {
        param[key] = state.detailInfo[key];
      }
    }
    return param;
  },
};
const mutations = {
  setSearchInfo(state, payload) {
    for (let key in payload) {
      if (state.searchInfo.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  initDetailInfo(state) {
    let stringKeys = ['empNo', 'poNo', 'custCode'];
    for (let key in state.detailInfo) {
      state.detailInfo[key] = stringKeys.indexOf(key) === -1 ? 0 : '';
    }
  },

  setDetailInfo(state, payload) {
    let stringKeys = ['empNo', 'poNo', 'custCode'];
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = stringKeys.indexOf(key) === -1 ? Number(payload[key]) : payload[key];
      }
    }
  },
  setLabMonList(state, payload) {
    state.labMonList = payload;
  },
  initCalculateList(state) {
    state.calculateList = [];
  },
  setCalculateList(state, payload) {
    state.calculateList = payload;
  },
  setCloseYn(state, closeYn) {
    state.closeYn = closeYn;
  },
};
const actions = {
  async selectLabMonList({state, commit}) {
    commit('initDetailInfo');
    commit('initCalculateList');
    const response = await selectLabMonList(state.searchInfo);
    const labMonList = response.data;
    commit('setLabMonList', labMonList);
  },
  async selectLabMonDetailList({state, commit}) {
    const param = {
      investMm: state.searchInfo.investMm,
      empNo: state.detailInfo.empNo,
    };
    const response = await selectLabMonDetailList(param);
    let arrayDate = $_getArrayDate(state.searchInfo.investMm);
    let data = response.data;
    let list = [];
    arrayDate.forEach((date, index) => {
      if (index % 2 === 0) {
        let map = {};
        map['investDt1'] = date;
        map['price1'] = 0;
        map['amt1'] = 0;
        list.push(map);
      } else {
        let lastList = list[list.length - 1];
        lastList['investDt2'] = date;
        lastList['price2'] = 0;
        lastList['amt2'] = 0;
      }

      const validData = data.find(item => item.investDt === date);
      for (const [key, value] of Object.entries(validData ?? {})) {
        let no = Number(validData.investDt.substr(-2)) % 2 !== 0 ? '1' : '2';
        list[list.length - 1][`${key}${no}`] = value;
      }
    });
    commit('setCalculateList', list);
  },
  setCalculateAmt({state, commit}, payload) {
    const isInsurance = payload.monthlyDay > state.taxRtInfo.entryDt;
    const age = $_getAge(String(state.empInfo.regiNo));
    let employInsurAmt = 0;
    let pensionAmt = 0;
    let healthInsurAmt = 0;
    let careAmt = 0;
    let incometax = 0;
    let resitax = 0;
    let deductSum = 0;
    let actPayAmt = 0;
    const monthlyAmt = payload.monthlyAmt;

    const getCalcDecimal = amt =>
      state.taxRtInfo.decimalDs === $getConstants('DECIMAL_DS1').code ? Math.ceil(amt) : Math.floor(amt);
    if (isInsurance && state.empInfo.employInsurDs !== $getConstants('EMPLOY_INSUR_DS').code && age < 60) {
      if (
        state.empInfo.nationCd === $getConstants('NATION_CD_KR').code ||
        (state.empInfo.nationCd !== $getConstants('NATION_CD_KR').code &&
          state.taxRtInfo.femployInsurDs == $getConstants('F_EMPLOY_INSUR_DS').code &&
          [$getConstants('VISA_F2').code, $getConstants('VISA_F5').code].indexOf(state.empInfo.visaKind) !== -1)
      ) {
        employInsurAmt = (monthlyAmt * state.taxRtInfo.employInsurRt) / 100;
      }
    }
    if (isInsurance && state.empInfo.pensionDs !== $getConstants('PENSION_DS').code && age < 65) {
      pensionAmt = (monthlyAmt * state.taxRtInfo.psRt) / 100;
    }
    if (isInsurance && state.empInfo.healthInsurDs !== $getConstants('HEALTH_INSUR_DS').code) {
      healthInsurAmt = (monthlyAmt * state.taxRtInfo.hisRt) / 100;
    }
    if (isInsurance && state.empInfo.healthInsurMDs === $getConstants('HEALTH_INSUR_M_DS').code) {
      careAmt = ((monthlyAmt * state.taxRtInfo.careRt) / 100) * 0.7;
    } else {
      careAmt = (monthlyAmt * state.taxRtInfo.careRt) / 100;
    }

    incometax = payload.incomedcTax * (state.taxRtInfo.incometaxRt / 100) * (state.taxRtInfo.incometaxDcrt / 100);
    resitax = incometax * 0.1;
    deductSum = incometax + resitax + employInsurAmt + pensionAmt + healthInsurAmt + careAmt;
    actPayAmt = monthlyAmt - deductSum;

    employInsurAmt = getCalcDecimal(employInsurAmt);
    pensionAmt = getCalcDecimal(pensionAmt);
    healthInsurAmt = getCalcDecimal(healthInsurAmt);
    careAmt = getCalcDecimal(careAmt);
    incometax = getCalcDecimal(incometax);
    resitax = getCalcDecimal(resitax);
    deductSum = getCalcDecimal(deductSum);
    actPayAmt = getCalcDecimal(actPayAmt);

    commit('setDetailInfo', {
      ...payload,
      employInsurAmt,
      pensionAmt,
      healthInsurAmt,
      careAmt,
      incometax,
      resitax,
      deductSum,
      actPayAmt,
    });
  },
  async selectLabTaxRt({state, commit}) {
    if (Number(state.taxRtInfo.entryDt) !== 0) {
      return;
    }
    const response = await selectLabTaxRt();
    commit('setTaxRtInfo', response.data);
  },
  async deleteLabMon({state, dispatch}) {
    const param = {
      investMm: state.searchInfo.investMm,
      reqDegree: state.searchInfo.reqDegree,
      empNo: state.detailInfo.empNo,
    };
    const response = await deleteLabMon(param);
    if (response.data > 0) {
      dispatch('selectLabMonList');
    }
  },
  setEmployInsurAmt({state, commit}, employInsurAmt) {
    let deductSum =
      state.detailInfo.incometax +
      state.detailInfo.resitax +
      employInsurAmt +
      state.detailInfo.pensionAmt +
      state.detailInfo.healthInsurAmt +
      state.detailInfo.careAmt;
    let actPayAmt = state.detailInfo.monthlyAmt - deductSum;
    commit('setDetailInfo', {employInsurAmt, deductSum, actPayAmt});
  },
  setPensionAmt({state, commit}, pensionAmt) {
    let deductSum =
      state.detailInfo.incometax +
      state.detailInfo.resitax +
      state.detailInfo.employInsurAmt +
      pensionAmt +
      state.detailInfo.healthInsurAmt +
      state.detailInfo.careAmt;
    let actPayAmt = state.detailInfo.monthlyAmt - deductSum;
    commit('setDetailInfo', {pensionAmt, deductSum, actPayAmt});
  },
  setHealthInsurAmt({state, commit}, healthInsurAmt) {
    let deductSum =
      state.detailInfo.incometax +
      state.detailInfo.resitax +
      state.detailInfo.employInsurAmt +
      state.detailInfo.pensionAmt +
      healthInsurAmt +
      state.detailInfo.careAmt;
    let actPayAmt = state.detailInfo.monthlyAmt - deductSum;
    commit('setDetailInfo', {healthInsurAmt, deductSum, actPayAmt});
  },
  setCareAmt({state, commit}, careAmt) {
    let deductSum =
      state.detailInfo.incometax +
      state.detailInfo.resitax +
      state.detailInfo.employInsurAmt +
      state.detailInfo.pensionAmt +
      state.detailInfo.healthInsurAmt +
      careAmt;
    let actPayAmt = state.detailInfo.monthlyAmt - deductSum;
    commit('setDetailInfo', {careAmt, deductSum, actPayAmt});
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
