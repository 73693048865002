<template>
  <pmis-tab-box>
    <template #title>입찰정보</template>
    <template #title-right>
      <!-- 개봉 -->
      <template v-if="rfqStatus.isRfqOpen">
        <iui-button value="재입찰" @click="openReBidModal" />
        <iui-button value="유찰" @click="openFailModal" />
        <iui-button value="차수조회" @click="openDegreeModal" v-if="rfqStatus.isRebid" />
        <iui-button value="견적대비표" @click="openCompareViewer" />
      </template>

      <!-- 낙찰사등록 -->
      <template v-else-if="rfqStatus.isRfqChoice">
        <iui-button value="낙찰사선정품의" @click="openSubRfqApproval" />
        <iui-button value="차수조회" @click="openDegreeModal" v-if="rfqStatus.isRebid" />
      </template>

      <!-- 낙찰사선정품의 -->
      <template v-else-if="rfqStatus.isRfqApprovalChoice">
        <iui-button value="결재문서보기" @click="openRfqApproval" />
      </template>

      <!-- 입찰완료 -->
      <template v-else-if="rfqStatus.isRfqComplete">
        <iui-button value="결재문서보기" @click="openRfqApproval" />
      </template>
    </template>

    <form onsubmit="return false;">
      <iui-container-new type="css-flex">
        <i-row height="285px">
          <i-col>
            <pmis-content-box>
              <template #title>입찰정보</template>
              <iui-container-new type="table" theme="bullet">
                <colgroup>
                  <col width="100px" />
                  <col width="100%" />
                  <col width="10px" />
                  <col width="90px" />
                  <col width="50%" />
                </colgroup>
                <i-row>
                  <i-col-header>현장</i-col-header>
                  <i-col>
                    <iui-text :value="rfqInfo.spotDeptcd" :enable="false" width="130px" />
                    <iui-text :value="rfqInfo.spotDeptnm" :enable="false" />
                  </i-col>
                  <i-spacer />
                  <i-col-header>차수</i-col-header>
                  <i-col>
                    <iui-text
                      :value="rfqInfo.lastBidRnd.length ? `${rfqInfo.lastBidRnd}차` : ''"
                      :enable="false"
                      width="130px"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>입찰공고번호</i-col-header>
                  <i-col>
                    <iui-text :value="rfqInfo.rfqNo2" :enable="false" width="130px" />
                    <iui-text :value="rfqInfo.ttl" :enable="false" />
                  </i-col>
                  <i-spacer />
                  <i-col-header>입찰예정가</i-col-header>
                  <i-col>
                    <iui-text
                      :type="rfqInfo.bidOpenDt ? 'amount' : 'text'"
                      :align="rfqInfo.bidOpenDt ? '' : 'right'"
                      :value="rfqInfo.bidOpenDt ? rfqInfo.bidPlanAmt : '***,***,***'"
                      width="155px"
                      :enable="false"
                    />
                    <label>(부가세포함)</label>
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>공고일시</i-col-header>
                  <i-col>
                    <iui-text :value="rfqInfo.bidStartDt" :enable="false" width="130px" />
                  </i-col>
                  <template v-if="rfqStatus.isExistSpotDscr">
                    <i-spacer />
                    <i-col-header>현설일시</i-col-header>
                    <i-col>
                      <iui-text :value="rfqInfo.spotDscrDt" :enable="false" width="130px" />
                    </i-col>
                  </template>
                  <template v-else>
                    <i-col colspan="3" />
                  </template>
                </i-row>
                <i-row>
                  <i-col-header>마감일시</i-col-header>
                  <i-col>
                    <iui-text :value="rfqInfo.bidExpireDt" :enable="false" width="130px" />
                  </i-col>
                  <i-spacer />
                  <i-col-header>개봉일시</i-col-header>
                  <i-col>
                    <iui-text :value="rfqInfo.bidOpenDt" :enable="false" width="130px" />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>입찰담당</i-col-header>
                  <i-col>
                    <iui-text :value="rfqInfo.chrgUsrnm" :enable="false" width="130px" />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>지급조건</i-col-header>
                  <i-col>
                    <iui-text prefix="현금" suffix="%" :value="rfqInfo.payCashRatio" :enable="false" width="100px" />
                    <iui-text
                      class="ml10"
                      prefix="어음"
                      suffix="%"
                      :value="rfqInfo.payCheckRatio"
                      :enable="false"
                      width="100px"
                    />
                    <iui-text class="ml10" suffix="일 이내" :value="rfqInfo.payTerm" :enable="false" width="100px" />
                  </i-col>
                  <i-spacer />
                  <i-col-header>과세유형</i-col-header>
                  <i-col>
                    <iui-text :value="rfqInfo.taxTypenm" :enable="false" width="130px" />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>공사기간</i-col-header>
                  <i-col>
                    <iui-datepicker :value="rfqInfo.constrFrDt" :enable="false" />
                    <label>~</label>
                    <iui-datepicker :value="rfqInfo.constrToDt" :enable="false" />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>공사명</i-col-header>
                  <i-col colspan="4">
                    <iui-text :value="rfqInfo.itemNm" :enable="false" />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>비고</i-col-header>
                  <i-col colspan="4">
                    <iui-text :value="rfqInfo.itemRmrk" :enable="false" />
                  </i-col>
                </i-row>
              </iui-container-new>
            </pmis-content-box>
          </i-col>
        </i-row>
        <i-spacer />
        <i-row height="600px">
          <i-col>
            <pmis-content-box>
              <template #title>낙찰정보</template>
              <iui-container-new type="css-flex">
                <iui-container-new type="table" theme="bullet">
                  <i-row>
                    <i-col-header>참여업체</i-col-header>
                    <i-col>
                      <label>응찰/공고</label>
                      <iui-text :value="quotTotRate" align="center" :enable="false" width="50px" />
                    </i-col>
                  </i-row>
                </iui-container-new>
                <i-spacer />
                <i-row>
                  <i-col colspan="2">
                    <RfqCusResultList @sheet="rfqCusResultListSheet = $event" />
                  </i-col>
                </i-row>
                <i-spacer />
                <iui-container-new type="table" theme="bullet">
                  <i-row>
                    <i-col-header>정보공개여부</i-col-header>
                    <i-col>
                      <iui-checkbox-group
                        separator=","
                        :items="choiceNotiFlagItems"
                        :value="rfqInfo.choiceNotiFlag"
                        @change="setRfqInfo({choiceNotiFlag: $event.values})"
                        :enable="rfqStatus.isEnableChoice"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>낙찰사유</i-col-header>
                    <i-col>
                      <iui-text
                        :value="rfqInfo.choiceRsltRmrk"
                        @change="setRfqInfo({choiceRsltRmrk: $event.target.value})"
                        :enable="rfqStatus.isEnableChoice"
                        max-length="500"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>견적대비표</i-col-header>
                  </i-row>
                </iui-container-new>
                <i-spacer />
                <i-row height="250px">
                  <i-col colspan="2">
                    <CompareSheet :rfqNo="rfqInfo.rfqNo" :bidRnd="rfqInfo.bidRnd" />
                  </i-col>
                </i-row>
              </iui-container-new>
            </pmis-content-box>
          </i-col>
        </i-row>
      </iui-container-new>
    </form>

    <iui-modal name="reBidModal" title="재입찰" :btns="reBidModalBtn" size-type="size3">
      <ReBid :bidType="bidType" />
    </iui-modal>

    <iui-modal name="failModal" title="유찰" :btns="failModalBtn" size-type="size5">
      <Fail :bidType="bidType" />
    </iui-modal>

    <iui-modal name="degreeModal" title="차수조회" :btns="degreeModalBtn" size-type="size6">
      <Degree />
    </iui-modal>

    <ApprovalPop :parentId="_uid" @approvalEmit="onApprovalEmit" />

    <iui-modal name="Compare_ReportViewerModal" title="견적대비표" size-type="size6">
      <report-viewer :fomNo="compareFomNo" :ozContent="ozContent" />
    </iui-modal>
  </pmis-tab-box>
</template>

<script>
import AfterOpenMixin from '@/view/bidding/rfq/common/mixins/afterOpenMixin.js';

import CompareSheet from './CompareSheet.vue';
export default {
  components: {CompareSheet},
  mixins: [AfterOpenMixin],
};
</script>

<style></style>
