<template>
  <!-- 작업내역-포틀릿 -->
  <div>
    <table class="tbl_list">
      <colgroup>
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col :style="`width:calc(${tblWidth}px/${cnt})`" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr>
          <th>공종명</th>
          <th>내역명</th>
          <th>규격</th>
          <th>단위</th>
          <th>수량</th>
          <th>금액</th>
        </tr>
      </thead>
    </table>
    <div class="inner">
      <table class="tbl_list bdt_n">
        <colgroup>
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col :style="`width:calc(${tblWidth}px/${cnt})`" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr v-for="(data, index) in workList" :key="index">
            <td>{{ data.mgName }}</td>
            <td>{{ data.itemName }}</td>
            <td>{{ data.ssize }}</td>
            <td>{{ data.unit }}</td>
            <td>{{ data.contQty }}</td>
            <td class="p_last">{{ data.mdAmt }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PortletMixin from '@/components/portlet/mixin/portletMixin.js';
import {selectWorkList} from '@/components/portlet/api/portletData.js';
export default {
  mixins: [PortletMixin],
  props: {
    curDate: {
      Type: String,
    },
  },
  data() {
    return {
      cnt: 6,
      workList: [],
    };
  },
  watch: {
    curDate() {
      this.onSearch();
    },
  },
  methods: {
    setWidth() {
      this.tblWidth = this.$parent.$el.offsetWidth - 28;
    },
    onSearch() {
      selectWorkList({prjCd: this.prjCd, reslulDt: this.curDate}).then(response => {
        this.workList = response.data;
      });
    },
  },
};
</script>

<style></style>
