<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text type="search" :value.sync="searchText" @enter="onSearchGrid" />
      <iui-button value="검색" @click="onSearchGrid" />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onDblClick,
        onRenderFirstFinish,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
/**
 * 사용처 : 사원등록(@/view/user/Worker/components/WorkerInfo.vue)
 */
import options from './sheetOption/DeptListPopupSheet.js';
import {selectDeptList} from '@/view/sysManage/userManage/deptRegist/api/deptRegist.js';
export default {
  props: {
    uid: {
      type: Number,
    },
    frmNm: {
      type: String,
    },
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      searchText: '',
      sheetUtil: null,
      loadSearchData: [],
      Options: options(this),
    };
  },
  created() {
    this.addEvent([{name: 'DeptListPopup_callbackData', func: this.callbackData}]);
  },
  methods: {
    async onSearch() {
      const response = await selectDeptList({});
      if (response.status == 200) {
        let items = $_treeModel(response.data, 'OBS', 'fldrCd', 'uppFldrCd', 'fldrNo', 'uppFldrNo');
        let data = [{fldrCd: 'OBS', fldrNm: this.frmNm, fldrNo: '0', Items: items}];
        this.sheet.loadSearchData(data);
        this.loadSearchData = data;
      }
    },
    onSearchGrid() {
      this.sheetUtil.treeSearch('fldrNm', this.searchText);
    },
    callbackData(callback) {
      //팝업창 확인버튼 or Grid 더블 클릭
      let row = this.sheet.getFocusedRow();
      if (this.sheet.getChildRows(row).length) {
        this.$alert({title: '부서선택', message: '최하위 그룹을 선택해주세요.'});
        return;
      }
      if (typeof callback == 'function') {
        callback(row);
      }
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    onDblClick(e) {
      if (e.row.Kind == 'Data') {
        this.callEvent({name: 'onHideModal_' + this.uid});
      }
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style></style>
