export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cfg: {CanEdit: 0},
    Def: {
      Row: {NoColor: 2, CanFocus: 0},
    },
    Cols: [
      {
        Header: '자재코드',
        Name: 'itemCd',
        Type: 'Text',
        Align: 'center',
        Width: 160,
        TextStyle: 5,
        TextColor: '#FF0000',
        Cursor: 'Pointer',
      },
      {Header: '명칭', Name: 'itemNm', Type: 'Text', RelWidth: 1, MinWidth: 150},
      {Header: '규격', Name: 'sizeNm', Type: 'Text', RelWidth: 1, MinWidth: 150},
      {Header: '단위', Name: 'unit', Type: 'Text', Align: 'center', Width: 80, Format: $getConstants('UNIT').code},
      {Header: '수량', Name: 'qty', Width: 100, Extend: preset.quantity},
      {Header: '단가', Name: 'planUnitPrc', Width: 100, Extend: preset.unitAmount},
      {
        Header: '금액',
        Name: 'amt',
        Width: 120,
        Extend: preset.amount,
        Formula: fr => fr.Row['qty'] * fr.Row['planUnitPrc'],
      },
      {Header: '비고', Name: 'itemRmrk', Type: 'Text', Width: 100},
    ],
  };
};
