<template>
  <pmis-content-box>
    <template v-slot:title>{{ title }}</template>
    <template v-slot:title-right>
      <iui-button class="btn" value="신규" @click="onAdd" v-show="enable" />
      <iui-button class="btn" value="저장" @click="onSave" v-if="companyInfo.pgmCd" v-show="enable" />
      <iui-button class="btn" value="삭제" @click="onDelete" v-show="enable" />
    </template>
    <iui-container-new type="css-flex">
      <i-row :height="enable ? '150px' : `${height}px`">
        <i-col>
          <ib-sheet
            :uid="_uid"
            :options="Options"
            :loadSearchData="licenseList"
            :events="{onRenderFirstFinish}"
            @loadSheet="loadSheet"
            :autoRender="false"
            :init="initSheet"
          />
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/HoldLcnsInfoSheet.js';
import {selectCompanyLicenseList, saveLicenseList, deleteLicenseList} from '../api/member.js';
export default {
  props: {
    title: {
      type: String,
      default: '보유면허정보',
    },
    enable: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '100',
    },
  },

  data() {
    return {
      sheet: undefined,
      Options: {},
      saveJson: '',
      initSheet: false,
    };
  },

  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'licenses', 'licenseList']);
    $mapMutations(this, ['setLicenseList']);
  },

  created() {
    this.addEvent([{name: 'holdLcnsInfo_getSaveJson', func: this.getSaveJson}]);
    this.createSheet();
  },

  methods: {
    async createSheet() {
      this.Options = this.enable ? $addColCheckbox(await options(this)) : await options(this);
      this.initSheet = true;
    },

    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },

    onRenderFirstFinish() {
      this.onSearchLicenseList();
    },

    onAdd() {
      this.sheet.addRow();
    },

    async onSave() {
      const title = '보유면허정보 저장';
      if (this.sheet.getTotalRowCount() == 0) {
        this.$alert({title, message: '저장할 보유면허정보가 존재하지 않습니다.'});
        return;
      }
      if (!this.sheet.hasChangedData()) {
        this.$alert({title, message: '수정된 내용이 없습니다.'});
        return;
      }
      let saveJson = this.sheet.getSaveJson().data;

      let validItem = saveJson.find(item => !item.conSpDs);
      if (validItem !== undefined) {
        this.$alert({title, message: '구분을 선택 해야합니다.'});
        return;
      }

      validItem = saveJson.find(item => !item.wcode);
      if (validItem !== undefined) {
        this.$alert({title, message: '업종을 선택 해야합니다.'});
        return;
      }

      validItem = saveJson.find(item => !item.licenseNo);
      if (validItem !== undefined) {
        this.$alert({title, message: '등록번호를 입력 해야합니다.'});
        return;
      }

      validItem = saveJson.find(item => !item.licenseOrg);
      if (validItem !== undefined) {
        this.$alert({title, message: '발급기관을 입력 해야합니다.'});
        return;
      }

      validItem = saveJson.find(item => !item.licenseDate);
      if (validItem !== undefined) {
        this.$alert({title, message: '취득일자를 입력 해야합니다.'});
        return;
      }

      //save
      saveJson.forEach(el => {
        if (!el.pgmCd) el.pgmCd = this.companyInfo.pgmCd;
      });
      const param = {licenseList: $_statusToCud(saveJson)};
      const response = await saveLicenseList(param);
      if (response.status == 200) {
        this.onSearchLicenseList();
        this.focusKeyCode = response.data;
      }
    },
    async onDelete() {
      let checkedRows = this.sheet.getRowsByChecked('chk');

      if (0 == checkedRows.length) {
        this.$alert({title: '보유면허정보 삭제', message: '선택된 항목이 없습니다.'});
        return;
      }
      if (await this.$confirm({title: '보유면허정보 삭제', message: '삭제하시겠습니까?'})) {
        const licenseList = checkedRows.map(item => {
          return item.licenseNo;
        });
        const response = await deleteLicenseList({licenseList});
        if (response.status == 200) {
          this.onSearchLicenseList();
          this.focusKeyCode = response.data;
        }
      }
    },

    getSaveJson(callback) {
      if (typeof callback == 'function') callback(this.sheet.getSaveJson().data);
    },

    async onSearchLicenseList() {
      const param = {pgmCd: this.companyInfo.pgmCd};
      let response = await selectCompanyLicenseList(param);
      if (response.status == 200) {
        this.setLicenseList(response.data);
      }
    },
  }, //methods

  beforeDestroy() {
    this.sheet.dispose();
  },
};
</script>

<style></style>
