<template>
  <iui-container-new type="css-flex">
    <i-row>
      <i-col>
        <ib-sheet
          :uid="_uid"
          :options="sheetOpt"
          :loadSearchData="rfqCusList"
          @loadSheet="sheet_loadSheet"
          :events="{
            onSearchFinish: sheet_onSearchFinish,
            onClick: sheet_onClick,
          }"
        />
      </i-col>
    </i-row>

    <iui-modal name="customerInfoModal" title="협력사정보" size-type="size2">
      <CustomerInfo :propsPgmCd="propsPgmCd" />
    </iui-modal>
  </iui-container-new>
</template>

<script>
import sheetOpt from './sheetOptions/rfqCusResultList.js';
import BIDDING_CONSTANTS from '@/view/bidding/js/BiddingConstants.js';

import CustomerInfo from '@/view/bidding/rfq/common/components/CustomerInfo.vue';
export default {
  components: {CustomerInfo},
  data() {
    return {
      sheetOpt: sheetOpt(this),
      propsPgmCd: '',
    };
  },
  beforeCreate() {
    $mapGetters(this, ['rfqStatus', 'rfqCusList']);
  },
  methods: {
    sheet_loadSheet(sheet) {
      this.sheet = sheet;
      this.$emit('sheet', sheet);
    },
    sheet_onSearchFinish(e) {
      e.sheet.setAttribute(null, 'spotDscrAt', 'Visible', this.rfqStatus.isExistSpotDscr, 0);

      e.sheet.setAttribute(null, 'resultYn', 'Visible', 0, 0);
      e.sheet.setAttribute(null, 'rdo', 'Visible', 0, 0);

      if (this.rfqStatus.isEnableChoice) {
        e.sheet.setAttribute(null, 'rdo', 'Visible', 1, 0);

        e.sheet.getDataRows().forEach(row => {
          if (row.quotSbmtYn == BIDDING_CONSTANTS.CODES.QUOT_SBMT_Y) {
            // 응찰
            e.sheet.setAttribute(row, 'rdo', 'CanEdit', '1', 0);
          } else {
            // 미응찰
            e.sheet.setAttribute(row, 'bidPlanAmtRate', 'Type', 'Text', 0);
            e.sheet.setAttribute(row, 'bidPlanAmtRate', 'Format', '', 0);
            e.sheet.setValue(row, 'bidPlanAmtRate', '-', 0);
            e.sheet.setAttribute(row, 'minTotAmtRate', 'Type', 'Text', 0);
            e.sheet.setAttribute(row, 'minTotAmtRate', 'Format', '', 0);
            e.sheet.setValue(row, 'minTotAmtRate', '-', 0);
            e.sheet.setAttribute(row, 'totAmt', 'Type', 'Text', 0);
            e.sheet.setAttribute(row, 'totAmt', 'Format', '', 0);
            e.sheet.setValue(row, 'totAmt', '-', 0);
          }
          if (row.resultYn === BIDDING_CONSTANTS.CODES.RESULT_Y) {
            e.sheet.setValue(row, 'rdo', true, 0);
            e.sheet.setAttribute(row, 'resultYn', 'TextStyle', 1, 0);
            e.sheet.setAttribute(row, 'resultYn', 'TextColor', '#FF0000', 0);
          }
        });
      } else {
        e.sheet.setAttribute(null, 'resultYn', 'Visible', 1, 0);
      }
      e.sheet.rerender();
    },
    sheet_onClick(e) {
      if (e.row.Kind == 'Data' && e.col == 'frmNm') {
        this.propsPgmCd = e.row.cusPgmCd;
        this.$modal.show('customerInfoModal');
      }
    },
  },
};
</script>

<style></style>
