<template>
  <iui-container-new type="css-flex">
    <i-row :height="isSimpleExec && isMat ? '60px' : '100px'" style="overflow: visible">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>
            {{ isSimpleExec && isMat ? '적용범위' : '공사(공종)선택' }}
          </template>
          <iui-container-new type="css-flex" style="position: absolute; overflow: visible">
            <i-row v-if="!(isSimpleExec && isMat)">
              <i-col>
                <iui-select :items="wcodeItems" :value="wcode" defaultCd="A" @change="value => (wcode = value)" />
                &nbsp;
                <iui-select
                  :items="headCodeItems"
                  :value="headCode"
                  defaultCd="A"
                  @change="value => (headCode = value)"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col>
                <iui-radio-group
                  name="applyOpt"
                  :items="applyOptItems"
                  :value="applyOptVal"
                  @change="applyOptVal = $event.target.value"
                ></iui-radio-group>
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box>
          <template v-slot:title>
            적용단가
          </template>
          <iui-container-new type="css-flex">
            <i-row>
              <i-col>
                <ib-sheet
                  :uid="_uid"
                  :options="Options"
                  :loadSearchData="loadSearchData"
                  @loadSheet="loadSheet"
                  :events="{
                    onDataLoad,
                    onClick,
                    onAfterChange,
                  }"
                />
              </i-col>
            </i-row>
          </iui-container-new>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container-new>
</template>

<script>
import CODE from '@/constants/codeConstants.js';
import {selectWorkTypeClList} from '@/view/estmtManage/prjWorkTypeRegist/api/prjWorkTypeRegist.js';
import {selectUnitCostApplyList} from '@/view/estmtManage/resrceManage/popup/api/ResrceManagePop.js';
import options from './sheetOption/UntpcApplcPopSheet.js';

export default {
  props: {
    isMat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      applyOptItems: [
        {label: '기존단가 삭제 후 단가적용', value: '1'},
        {label: '기존단가가 없는 자원만 단가적용', value: '2'},
      ],

      matUcstList: [],
      labUcstList: [],
      equipUcstList: [],
      oprUcstList: [],
      wcode: '',
      wcodeItems: [],
      lgCodeObj: {},
      headCodeItems: [],
      headCode: '',
      applyOptVal: '1',
      ucstObj: {
        workTypeCl: '',
        costType: '',
        applyOpt: '',
        matUcstCode: '',
        labUcstCode: '',
        equtUcstCode: '',
        oprUcstCode: '',
      },
    };
  },
  watch: {
    wcode: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.headCode = '';
        }
        this.headCodeItems = this.lgCodeObj[val];
      },
      deep: true,
    },
  },
  created() {
    this.addEvent({name: 'UntpcApplcPop_popConfirm', func: this.onPopConfirm});
    this.onSearchWorkTypeCl();
    this.onSearch();
  },
  methods: {
    async onSearchWorkTypeCl() {
      const response = await selectWorkTypeClList({});
      response.data.forEach(item => {
        if (item.level == 2) {
          this.wcodeItems.push({value: item.wcode, text: item.wname});
        }
        if (item.level == 3) {
          if (!this.lgCodeObj[item.wcode]) this.lgCodeObj[item.wcode] = [];
          this.lgCodeObj[item.wcode].push({value: item.lgCode, text: item.lgName});
        }
      });
    },
    async onSearch() {
      const response = await selectUnitCostApplyList({});
      this.loadSearchData = response.data;
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    onDataLoad() {
      for (let row of this.sheet.getDataRows()) {
        row.matUcstName ? '' : this.sheet.setAttribute(row, 'matRadio', 'Type', '', 1);
        row.labUcstName ? '' : this.sheet.setAttribute(row, 'labRadio', 'Type', '', 1);
        row.equtUcstName ? '' : this.sheet.setAttribute(row, 'equtRadio', 'Type', '', 1);
        row.oprUcstName ? '' : this.sheet.setAttribute(row, 'oprRadio', 'Type', '', 1);
      }

      if (this.isMat && this.isSimpleExec) {
        this.sheet.setAttribute(null, 'labUcstName', 'Visible', 0, 1);
        this.sheet.setAttribute(null, 'equtUcstName', 'Visible', 0, 1);
        this.sheet.setAttribute(null, 'oprUcstName', 'Visible', 0, 1);
      }
    },
    onClick(e) {
      if (e.kind == 'Data') {
        let target = String(e.col).substring(0, String(e.col).indexOf('UcstName')); // mat, lab, equt, opr
        if (e.row[target + 'UcstCode']) {
          if (this.ucstObj[target + 'UcstCode'] === e.row[target + 'UcstCode']) {
            delete this.ucstObj[target + 'UcstCode'];
            delete this.ucstObj[target + 'UcstName'];
            this.sheet.setAttribute(e.row, `${target}UcstName`, 'Color', '', 1);
          } else {
            this.ucstObj[target + 'UcstCode'] = e.row[target + 'UcstCode'];
            this.ucstObj[target + 'UcstName'] = e.row[target + 'UcstName'];
            this.sheet.setAttribute(e.row, `${target}UcstName`, 'Color', '#adadad', 1);
            for (let row of this.sheet.getDataRows()) {
              if (e.row.id != row.id) {
                this.sheet.setAttribute(row, `${target}UcstName`, 'Color', '', 1);
              }
            }
          }
        }
      }
    },
    onAfterChange(e) {
      let target = String(e.col).substring(0, String(e.col).indexOf('Radio')); // mat, lab, equt, opr
      this.ucstObj[target + 'UcstCode'] = e.row[target + 'UcstCode'];
      this.ucstObj[target + 'UcstName'] = e.row[target + 'UcstName'];
    },
    onPopConfirm(param) {
      this.ucstObj.wcode = this.wcode;
      this.ucstObj.headCode = this.headCode;
      this.ucstObj.applyOpt = this.applyOptVal;
      param(this.ucstObj);
    },
  },
};
</script>

<style></style>
