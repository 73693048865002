<template>
  <iui-container-new type="css-flex">
    <i-row height="25px">
      <i-col>
        <div class="fr">
          <iui-button :value="'개인설정'" @click="onClickUserConfigPopup()" />
        </div>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <iui-container-new type="table" theme="bullet">
          <i-row>
            <i-col-header>성명</i-col-header>
            <i-col>
              <iui-text :value="myInfo.name" :enable="false" width="112px" />
              <div class="mr5" />
              <iui-button :value="'비밀번호변경'" @click="onClickPwdPopup()" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>휴대폰번호</i-col-header>
            <i-col>
              <iui-text :value.sync="mobileTlno1" width="60px" type="number" :numberFormat="false" max-length="3" />
              <iui-text
                :value.sync="mobileTlno2"
                width="70px"
                :prefix="'-'"
                type="number"
                :numberFormat="false"
                max-length="4"
              />
              <iui-text
                :value.sync="mobileTlno3"
                width="70px"
                :prefix="'-'"
                type="number"
                :numberFormat="false"
                max-length="4"
              />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>전화번호</i-col-header>
            <i-col>
              <iui-text :value.sync="tlno1" width="60px" type="number" :numberFormat="false" max-length="3" />
              <iui-text
                :value.sync="tlno2"
                width="70px"
                :prefix="'-'"
                type="number"
                :numberFormat="false"
                max-length="4"
              />
              <iui-text
                :value.sync="tlno3"
                width="70px"
                :prefix="'-'"
                type="number"
                :numberFormat="false"
                max-length="4"
              />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>팩스번호</i-col-header>
            <i-col>
              <iui-text :value.sync="rpsvFaxno1" width="60px" type="number" :numberFormat="false" max-length="3" />
              <iui-text
                :value.sync="rpsvFaxno2"
                width="70px"
                :prefix="'-'"
                type="number"
                :numberFormat="false"
                max-length="4"
              />
              <iui-text
                :value.sync="rpsvFaxno3"
                width="70px"
                :prefix="'-'"
                type="number"
                :numberFormat="false"
                max-length="4"
              />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>이메일</i-col-header>
            <i-col>
              <iui-text type="email1" :suffix="'@'" :value.sync="myInfo.email1" width="105px" max-length="50" />
              <iui-select
                :p-code="$getConstants('EMAIL_CD').code"
                :defaultCd="'직접입력'"
                :value="myInfo.email2.indexOf('0000') == -1 ? '' : myInfo.email2"
                @change="value => (myInfo.email2 = value)"
              />
              <iui-text
                type="email2"
                v-if="myInfo.email2.indexOf('0000') == -1"
                width="100px"
                :value="myInfo.email2"
                @change="myInfo.email2 = $event.target.value"
                max-length="50"
              />
            </i-col>
          </i-row>
          <i-row rowspan="3">
            <i-col-header>주소</i-col-header>
            <i-col>
              <iui-text :value.sync="myInfo.postNo" :enable="false" width="100px" />
              <iui-button btn-class="i_search" style="height: 16px;" @click="searchAdr" />
            </i-col>
          </i-row>
          <i-row>
            <i-col></i-col>
            <i-col>
              <iui-text :value.sync="myInfo.adr1" :enable="false" max-length="100" />
            </i-col>
          </i-row>
          <i-row>
            <i-col></i-col>
            <i-col>
              <iui-text :value.sync="myInfo.adr2" :placeholder="'상세주소 입력'" max-length="100" />
            </i-col>
          </i-row>
        </iui-container-new>
      </i-col>
      <i-spacer />
      <i-col width="200px" height="100px">
        <iui-container-new type="table" theme="bullet">
          <iui-carousel>
            <pmis-image-file
              title="사진"
              id="myPagePhotoFile"
              :file-number.sync="myInfo.phoFlNo"
              :saveAuth="true"
              @delete-complete="afterImageDelete('phoFlNo')"
            />
            <pmis-image-file
              title="싸인"
              id="myPageSignFile"
              :file-number.sync="myInfo.sgntFlNo"
              :saveAuth="true"
              @delete-complete="afterImageDelete('sgntFlNo')"
            />
          </iui-carousel>
        </iui-container-new>
      </i-col>
    </i-row>
    <iui-modal name="ChangePwdPopup" title="비밀번호변경" sizeType="size5" :btns="modalBtns">
      <ChangePwdPopup :paramObj="paramObj" />
    </iui-modal>
    <iui-modal name="UserConfigPopup" title="사용자 개인 설정" sizeType="size5" :btns="userConfigModalBtns">
      <UserConfigPopup />
    </iui-modal>
  </iui-container-new>
</template>

<script>
import {selectMyInfo, updateMyInfo} from '../api/myPage.js';
import {mapGetters} from 'vuex';
import ChangePwdPopup from './ChangePwdPopup.vue';
import UserConfigPopup from '@/view/sysManage/userManage/myPage/components/UserConfigPopup.vue';

export default {
  components: {
    UserConfigPopup,
    ChangePwdPopup,
  },
  data() {
    return {
      myInfo: {
        pgmCd: '',
        empNo: '',
        name: '',
        tlno: '',
        mobileTlno: '',
        rpsvFaxno: '',
        email1: '',
        email2: '',
        postNo: '',
        adr1: '',
        adr2: '',
        phoFlNo: '',
        sgntFlNo: '',
      },
      tlno1: '',
      tlno2: '',
      tlno3: '',
      mobileTlno1: '',
      mobileTlno2: '',
      mobileTlno3: '',
      rpsvFaxno1: '',
      rpsvFaxno2: '',
      rpsvFaxno3: '',

      paramObj: {},
      modalBtns: [{name: '확인', callback: this.changePwdPopupConfirm}],
      userConfigModalBtns: [{name: '확인', callback: this.onConfirmUserConfig}],
    };
  },
  computed: {
    ...mapGetters(['externalData']),
    tlno() {
      if (this.tlno1 && this.tlno2 && this.tlno3) {
        return this.tlno1.trim() + this.tlno2.trim() + this.tlno3;
      }
      return '';
    },
    mobileTlno() {
      if (this.mobileTlno1 && this.mobileTlno2 && this.mobileTlno3) {
        return this.mobileTlno1 + this.mobileTlno2 + this.mobileTlno3;
      }
      return '';
    },
    rpsvFaxno() {
      if (this.rpsvFaxno1 && this.rpsvFaxno2 && this.rpsvFaxno3) {
        return this.rpsvFaxno1.trim() + this.rpsvFaxno2.trim() + this.rpsvFaxno3;
      }
      return '';
    },
  },
  watch: {
    externalData: {
      handler(val) {
        if (Object.keys(val).length == 0) return;
        this.myInfo.postNo = val.zipNo;
        this.myInfo.adr1 = `${val.roadAddrPart1} ${val.roadAddrPart2}`;
        this.myInfo.adr2 = val.addrDetail;
      },
      deep: true,
    },
  },
  async created() {
    this.addEvent({name: 'MyPage_confirm', func: this.onConfirm});
    this.onSearch();
  },
  methods: {
    searchAdr() {
      window.open(
        process.env.VUE_APP_API_URL + '/address/jusoPopup',
        'jusoPop',
        'width=570,height=420, scrollbars=yes, resizable=yes'
      );
    },
    async onSearch() {
      let pgmCd = this.userInfo.pgmCd;
      let empNo = this.userInfo.empNo;

      let response = await selectMyInfo({pgmCd, empNo});

      for (let key in this.myInfo) {
        this.myInfo[key] = response.data[key];
      }

      [this.mobileTlno1, this.mobileTlno2, this.mobileTlno3] = await $_mobileFormat(this.myInfo.mobileTlno);
      [this.tlno1, this.tlno2, this.tlno3] = await $_phoneFormat(this.myInfo.tlno);
      [this.rpsvFaxno1, this.rpsvFaxno2, this.rpsvFaxno3] = await $_phoneFormat(this.myInfo.rpsvFaxno);
    },
    async onConfirm(callback) {
      if (typeof callback !== 'function') return;

      this.onSave(callback);
    },
    async onSave(callback) {
      this.myInfo.tlno = this.tlno;
      this.myInfo.mobileTlno = this.mobileTlno;
      this.myInfo.rpsvFaxno = this.rpsvFaxno;

      if (!this.myInfo.mobileTlno && (this.mobileTlno1 || this.mobileTlno2 || this.mobileTlno3)) {
        this.$alert({title: '휴대폰번호', message: '휴대폰번호가 모두 입력되지 않았습니다.'});
        return;
      }
      if (!this.myInfo.tlno && (this.tlno1 || this.tlno2 || this.tlno3)) {
        this.$alert({title: '전화번호', message: '전화번호가 모두 입력되지 않았습니다.'});
        return;
      }
      if (!this.myInfo.rpsvFaxno && (this.rpsvFaxno1 || this.rpsvFaxno2 || this.rpsvFaxno3)) {
        this.$alert({title: '팩스번호', message: '팩스번호가 모두 입력되지 않았습니다.'});
        return;
      }

      try {
        const response = await updateMyInfo(this.myInfo);

        const userInfo = this.userInfo;
        for (let key in this.myInfo) {
          if (userInfo[key] !== undefined) {
            userInfo[key] = this.myInfo[key];
          }
        }
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo));

        if (callback != undefined) {
          callback(response.data);
        }
      } catch (error) {
        callback(0);
      }
    },
    afterImageDelete(target) {
      this.myInfo[target] = 0;
      this.onSave();
    },
    onClickPwdPopup() {
      this.paramObj = {
        pgmCd: this.myInfo.pgmCd,
        empNo: this.myInfo.empNo,
      };
      this.$modal.show('ChangePwdPopup');
    },
    changePwdPopupConfirm() {
      this.callEvent({
        name: 'ChangePwd_confirm',
        param: hidePopup => {
          if (hidePopup) {
            this.$modal.hide('ChangePwdPopup');
          }
        },
      });
    },
    onClickUserConfigPopup() {
      this.$modal.show('UserConfigPopup');
    },
    onConfirmUserConfig() {
      this.callEvent({
        name: 'UserConfig_confirm',
        param: () => {
          this.$modal.hide('UserConfigPopup');
        },
      });
    },
  },
};
</script>

<style></style>
